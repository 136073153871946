import * as Operation from './actionTypes';

export const getOperationListData = (params = {}) => ({
    type: Operation.GET_OPERATION_LISTDATA,
    payload: params
});

export const getCompanyListData = (params = {}) => ({
    type: Operation.GET_COMPANY_LISTDATA,
    payload: params
});

export const getOperationStatus = () => ({
    type: Operation.GET_OPERATION_STATUS,
    payload: {}
});

export const getOperationDetail = (id) => ({
    type: Operation.GET_OPERATION_DETAIL,
    payload: { id }
});

export const getOperationCenterSele = (payload = {}) => ({
    type: Operation.GET_OPERATIONCENTER_SELE,
    payload
});

export const getSettleUnitTypeSele = () => ({
    type: Operation.GET_SETTLEUNITTYPE_SELE,
    payload: {}
});

export const getSettleUnitSele = () => ({
    type: Operation.GET_SETTLEUNIT_SELE,
    payload: {}
});

export const getOperationProvince = () => ({
    type: Operation.GET_OPERATION_PROVINCE,
    payload: {}
});

export const getOperationCity = (parentId) => ({
    type: Operation.GET_OPERATION_CITY,
    payload: { parentId }
});

export const getOperationArea = (parentId) => ({
    type: Operation.GET_OPERATION_AREA,
    payload: { parentId }
});

export const getProvinceSele = () => ({
    type: Operation.GET_PROVINCE_SELE,
    payload: {}
});

export const getCitySele = (parentId) => ({
    type: Operation.GET_CITY_SELE,
    payload: { grade: 1, parentId }
});

export const resetCitySele = () => ({
    type: Operation.RESET_CITY_SELE,
});

export const getTypeEnum = () => ({
    type: Operation.GET_TYPE_ENUM,
});