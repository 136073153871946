/**
 * 开城设置
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import TableContent from './components/TableContent';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import model from '@/reducers/storemanage/cityOpen/model';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XOKButton, XCancelButton, XSelect, XDatePicker, XTabNav } from '@/components/xqxc_ui';
import OpenOrClose from './modal/OpenOrClose';
import { setSessionStorage, getSessionStorage, removeSessionStorage } from '@/assets/js/storage';

class CityOpen extends PureComponent {

    static propTypes = {
        history: PropTypes.object, // router history
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
        getOpenCityData: PropTypes.func,//获取房源列表
        openCityData: PropTypes.object,//房源列表
        getProvinceSele: PropTypes.func,//获取省份下拉
        provinceSele: PropTypes.array,//省份下啦
        getCitySele: PropTypes.func,//获取城市
        citySele: PropTypes.array,//城市下啦
        getStatusEnum: PropTypes.func,//获取状态枚举
        statusEnum: PropTypes.array,//状态枚举
    }

    static defaultProps = {
        openCityData: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
        provinceSele: [],
        citySele: [],
        statusEnum: []
    }

    state = {
        renderModal: false,
        modalParams: '',
        provinceItem: {},
        cityItem: {},
        stateItem: {},
        startDate: undefined,
        endDate: undefined,
        batchKeys: [],
        pageNum: 1,
        pageSize: 30,
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getProvinceSele, getStatusEnum } = this.props;
        getProvinceSele();
        keepSecondNavBreadcrumb();
        getStatusEnum();
        this._searchHandle('useCache');
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const {
            provinceItem, cityItem, stateItem, startDate, endDate, pageNum, pageSize,
        } = this.state;
        this.searchCache = {
            cityOpenType: 'SHOP',
            provinceId: provinceItem.id,
            cityId: cityItem.id,
            state: stateItem.code,
            startOpenTime: startDate,
            endOpenTime: endDate,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getOpenCityData(this.searchCache);
        KeepAlive.saveCache(this.searchCache);
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            provinceItem: {}, cityItem: {}, stateItem: {}, startDate: undefined, endDate: undefined,
        }, () => {
            this.props.getCitySele({ parentId: '1' });
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    //页面跳转
    _jumpPage = (title, url, params) => {
        const { addOneBreadcrumbPath, history } = this.props;
        const baseUrl = '/home/storemanage/cityOpen';
        addOneBreadcrumbPath({ title, path: `${baseUrl}${url}` });
        history.push({ pathname: `${baseUrl}${url}`, query: params });
    }

    // 表格操作
    _tableOperate = (type, item) => {
        switch (type) {
            case 'record':
                this._jumpPage('历史操作记录', `/record/${item.cityName}/${item.cityId}`);
                break;
            case 'entered':
                this._jumpPage('已入驻门店', '/storeList', {
                    provinceItem: { id: item.provinceId, areaName: item.provinceName },
                    cityItem: { id: item.cityId, areaName: item.cityName },
                    type: item.cityOpenType
                });
                break;
            case 'state':
                this.setState({
                    renderModal: true,
                    modalParams: {
                        title: item.state == '1' ? '关闭原因：' : '开启说明：',
                        cityIds: item.cityId ? [item.cityId] : [],
                        cityOpenType: 'SHOP',
                        url: item.state == '1' ? '/cityOpen/closeCity' : '/cityOpen/openCity',
                        isClose: item.state == '1' ? true : false
                    }
                });
                break;
            case 'batch':
                this.setState({ batchKeys: item });
                break;
        }
    }

    // 分页发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize: pageSize, pageNum: pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    //关闭弹窗
    _closeMode = (update) => {
        this.setState({ renderModal: false, modalParams: '', batchKeys: [] }, () => {
            update && this._searchHandle('useCache');
        });
    }

    //批量操作
    _betchOperate = (type) => {
        const { batchKeys } = this.state;
        if (!batchKeys.length) {
            message.warn('请勾选城市');
            return;
        }
        this.setState({
            renderModal: true,
            modalParams: {
                title: type == 'open' ? '开启说明：' : '关闭原因：',
                cityIds: batchKeys,
                cityOpenType: 'SHOP',
                url: type == 'open' ? '/cityOpen/openCity' : '/cityOpen/closeCity',
                isClose: type == 'open' ? false : true
            }
        });
    }

    //省份改变
    _areaChange = ({ id = '' }) => {
        this.setState({ cityItem: {} });
        this.props.getCitySele({ parentId: id });
    }

    render() {
        const { openCityData, provinceSele, citySele, statusEnum } = this.props;
        const {
            renderModal, modalParams,
            provinceItem, cityItem, stateItem, startDate, endDate, batchKeys,
        } = this.state;
        return <KeepAlive render={(state) => {
            if (!this.state.__aliveInit) {
                this.setState({ ...state, __aliveInit: true }, () => {
                    this._searchHandle('useCache');
                });
            }
        }}>
            <div className={styles.flexBoxContainer}>
                <SearchBox style={{ marginTop: '10px' }}>
                    <SearchItem>
                        <XSelect
                            label='省份'
                            placeholder='请选择省份'
                            renderData={provinceSele}
                            bindName='provinceItem'
                            bindThis={this}
                            dataIndex='areaName'
                            keyIndex='id'
                            onChange={this._areaChange}
                            value={provinceItem.areaName}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='城市'
                            placeholder='请选择城市'
                            renderData={citySele}
                            dataIndex='areaName'
                            keyIndex='id'
                            bindName='cityItem'
                            bindThis={this}
                            value={cityItem.areaName}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='状态'
                            placeholder='请选择状态'
                            renderData={statusEnum}
                            dataIndex='value'
                            keyIndex='code'
                            bindName='stateItem'
                            bindThis={this}
                            value={stateItem.value}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XDatePicker
                            label='开通时间'
                            placeholder='请选择开始日期'
                            bindThis={this}
                            bindName='startDate'
                            value={startDate}
                        />
                        <XDatePicker
                            style={{ marginRight: '20px' }}
                            label=''
                            placeholder='请选择结束日期'
                            bindThis={this}
                            bindName='endDate'
                            value={endDate}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </SearchItem>
                </SearchBox>
                <div className={styles.operateBox}>
                    <XOKButton
                        style={{ width: '90px', marginRight: '20px' }}
                        label='批量开通'
                        onClick={() => this._betchOperate('open')}
                    />
                    <XOKButton
                        style={{ width: '90px' }}
                        label='批量关闭'
                        onClick={() => this._betchOperate('close')}
                    />
                </div>
                <TableContent
                    paginationChange={this._paginationChange}
                    renderData={openCityData}
                    tableAction={this._tableOperate}
                    batchKeys={batchKeys}
                />
                {
                    renderModal &&
                    <OpenOrClose data={modalParams} closeMode={this._closeMode} />
                }
            </div>
        </KeepAlive>;
    }
}
export default connect(state => ({
    openCityData: state.cityOpen.openCityData,
    provinceSele: state.cityOpen.provinceSele,
    citySele: state.cityOpen.citySele,
    statusEnum: state.cityOpen.statusEnum.map(item => {
        if (item.code == '1') {
            return { ...item, value: '已开通' };
        }
        return { ...item, value: '未开通' };
    }),
}), {
    keepSecondNavBreadcrumb,
    addOneBreadcrumbPath,
    ...model.actions
})(CityOpen);