import * as T from './actiontypes';

export const musicClassSelect=(state = [], {type,payload})=>{
    switch(type){
        case T.SET_MUSICCLASS_SELECTSOURCE:
            return (payload||{dataList:[]}).dataList.filter(v=>Number(v.state)===1);
        default:
            return state;
    }
};

export const musicTypeTable = (state = {}, {type,payload,requestProps={}}) => {
    switch(type){
        case T.SET_MUSICTYPE_TABELSOURCE:
            return {...requestProps,...payload};
        default:
            return state;
    }
};

export const musicListTable = (state = {}, {type,payload}) => {
    switch(type){
        case T.SET_MUSICLIST_TABELSOURCE:
            return payload;
        default:
            return state;
    }
};

