/**
 * 通道切换
 */
import React from 'react';
import { Modal, Radio, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './PayModal.module.scss';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import model from '@/reducers/systemmanage/paymentChannel/model';

const RadioGroup = Radio.Group;
class SwitchModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
            radioValue: props.dataType ? (props.dataType.accessType ? props.dataType.accessType : 'TONLY_PAY') : 'TONLY_PAY', // 支付通道（初始默认通联）
        };
    }

    componentDidMount() {
        const { getPaywayList, } = this.props;
        getPaywayList();//支付通道(通联、银联)
    }

    _handleOk = () => {
        const { data } = this.props;
        const { radioValue } = this.state;

        let submitData = {
            accessType: radioValue,
            operaType: 'SHOP',
            merchantIds: data,
        };

        this.setState({ confirmLoading: true }, () => {
            http('/payment/config/setPaymentAccessTypeConfig', submitData, 'POST').then((res) => {
                const { result: { isSetError, merchantNames = [] } = {} } = res;
                if (isSetError == '1') {
                    message.error(`设置失败的商户名称有：${Array.isArray(merchantNames) ? merchantNames.join() : ''}`);
                    this.setState({ confirmLoading: false });
                } else {
                    message.success('设置成功');
                    this.setState({
                        visible: false
                    });
                    this.props.closeMode(true);
                }
            }).catch((res = {}) => {
                this.setState({ confirmLoading: false });
                message.error(res.message);
            });
        });

    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // Radio状态改变时触发
    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }

    render() {
        const { visible, confirmLoading, title, radioValue } = this.state;
        let paywayList = this.props.paywayList.filter(v=>(['ADA_PAY', 'LL_PAY'].includes(v.code)));
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.radio}>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={radioValue}>
                            {paywayList.map(v => <Radio key={v.code} value={v.code}>{v.value}</Radio>)}
                        </RadioGroup>
                    </div>
                </div>
            </Modal>
        );

    }
}

SwitchModal.propTypes = {
    data: PropTypes.array, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
    paywayList: PropTypes.array,
    getPaywayList: PropTypes.func,//支付通道(通联、银联)(action)
    dataType: PropTypes.object,//是否批量（undefined）和单个（{...data}）
};

const mapStateToProps = (state) => ({
    paywayList: state.paymentChannel.paywayList, // 支付通道(通联、银联)
});

export default connect(mapStateToProps, { ...model.actions })(SwitchModal);