/**
 * [表格数据列表]
 * @author: Fu Xiaochun
 * @date: 2021-11-09 
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table, Tooltip } from 'antd';
import XPagination from '@/components/Pagination';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell, priceDecimal2Cell, priceDecimal3Cell } from '@/components/TableCell';
import { XRangePicker, XMediaFn } from '@/components/xqxc_ui';

const renderImg = (key, item)=>{
    return <XMediaFn style={{width: '85px', height: '85px'}} dataType='image' dataSource={item.mainImgUrl} />;
};

const columns = (TableAction, actionClickHandle) => [
    {
        title: '订单编号',
        dataIndex: 'orderId',
        key: 'orderId',
        align: 'left',
        width: '130px',
    },
    {
        title: '会员账号',
        dataIndex: 'tel',
        key: 'tel',
        align: 'left',
        width: '150px'
    },
    {
        title: '推荐人账号',
        dataIndex: 'sponsorTel',
        key: 'sponsorTel',
        align: 'left',
        width: '150px'
    },
    {
        title: '所属服务商',
        dataIndex: 'realName',
        key: 'realName',
        align: 'left',
        width: '200px'
    },
    {
        title: '服务商账号',
        dataIndex: 'mobilePhone',
        key: 'mobilePhone',
        align: 'left',
        width: '150px',
        render: tooltip
    },
    {
        title: '所属区域公司',
        dataIndex: 'areaCompanyName',
        key: 'areaCompanyName',
        align: 'left',
        width: '150px',
        render: tooltip
    },
    {
        title: '支付时间',
        dataIndex: 'payTime',
        key: 'payTime',
        align: 'left',
        width: '150px',
        render: tooltip
    },
    {
        title: '订单状态',
        dataIndex: 'orderStatus',
        key: 'orderStatus',
        align: 'left',
        width: '100px',
        render: (key, item)=>(item.orderStatus.value)
    },
];

// 表格操作
const TableAction = (item, actionClickHandle) => {
    return (
        <div className={styles.tableActionCell}>
            <div className={styles.item} onClick={() => actionClickHandle(item.orderId, 'detail', item)}>详情</div>
        </div>
    );
};

class TableData extends Component {
    constructor(props) {
        super(props);
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { renderData } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowKey='orderId'
                    columns={columns(TableAction, this._actionHandle)}
                    pagination={false}
                    dataSource={renderData.dataList}
                    scroll={{ x: true, y: true }}
                />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={this.props.paginationChange}
                />
            </div>
        );
    }
}

TableData.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
    selectedRowKeys: PropTypes.array
};

export default TableData;