/**
 * 修改库存
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message } from 'antd';
import styles from './Inventory.module.scss';
import { XInput } from '@/components/xqxc_ui';


class Inventory extends React.Component {


    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        inventory: ''

    }
    componentDidMount() {
        console.log(this.props);
    }
    // 点击确认
    _handleOk = () => {
        const { data } = this.props;
        const { inventory, } = this.state;
        let inventoryReg = /^(0|[1-9]\d*)$/;
        if (inventory == '' || !inventoryReg.test(inventory)) {
            message.warn('请输入库存（整数）。');
        } else {
            http('/starmall/goods/updateInventory', { id: data.id, inventoryQuanity:inventory}, 'POST').then(() => {
                message.success('库存更新成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }).catch(() => {
                message.error('库存更新失败');
                this.setState({
                    visible: false,
                });
                this.props.closeMode();
            });
        }
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _inventoryChange = (e) => {
        this.setState({ inventory: e });
    }

    render() {
        const { visible, confirmLoading, title, inventory } = this.state;
        const { data } = this.props;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.name}>
                        <XInput
                            style={{ width: '400px' }}
                            inputStyle={{ width: '320px', marginLeft: '14px' }}
                            label='库存数量'
                            placeholder={`当前剩余库存${data.inventoryQuanity}件`}
                            onChange={this._inventoryChange}
                            value={inventory}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}


Inventory.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

export default Inventory;