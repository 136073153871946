/**
 *  推荐规则
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import DeleteModal from '../../modal/DeleteModal';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, XDatePicker, showConfirm, } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import { getrecommendList, getrecommendSelect, } from '@/reducers/systemmanage/showset/actions';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Recommend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderStartTime: null, // 下单开始时间
            startPickerDisabled: false, // 下单开始时间不可选
            orderEndTime: null, // 下单结束时间
            endPickerDisabled: false, // 下单结束时间不可选
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参

            rulesNum: '',//规则名称
            priority: '',//优先级
            fullState: {}, // 频道分类

        };
    }

    componentDidMount() {
        const { getrecommendSelect, } = this.props;
        getrecommendSelect();//下拉
    }

    //分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getrecommendList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 查询触发
    _fullSearchHandle = (useCache) => {
        const { rulesNum, orderStartTime, orderEndTime, pageNum, pageSize, fullState } = this.state;
        let startTime = orderStartTime != null ? orderStartTime.format('YYYY-MM-DD HH:mm:ss') : null;
        let endTime = orderEndTime != null ? orderEndTime.format('YYYY-MM-DD HH:mm:ss') : null;
        this.searchCache = {
            ruleName: rulesNum,
            startTime,
            endTime,
            classTypeCode: fullState.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getrecommendList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _fullresetHandle = () => {
        this.setState({ rulesNum: '', orderEndTime: null, orderStartTime: null, fullState: {} }, () => {
            KeepAlive.saveResetFunc(this._fullSearchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作功能回调
    _actionClickHandle = (item, type, value) => {
        const { updateContent, } = this.props;
        switch (type) {
            case 'editor'://编辑
                updateContent('reditor', item);
                break;
            case 'delete':
                showConfirm('您是否要删除内容', '', () => {
                    http('/system/promotion/delete', { id: item }, 'POST').then(() => {
                        message.success('删除成功');
                        this.props.getrecommendList(this.searchCache);
                    }).catch(() => {
                    });
                }, () => { });
                break;
            case 'switch':
                http('/system/promotion/updateState', {
                    id: item.id,
                    state: (value === false) ? 0 : 1,
                }, 'POST').then(() => {
                    message.success('状态修改成功');
                    this.props.getrecommendList(this.searchCache);
                }).catch((reject) => {
                    if (reject.code == '500')
                        message.error(reject.message);
                });
                break;
        }

    }
    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'delete':
                return <DeleteModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

    // 创建规则
    _createRules = () => {
        this.props.updateContent('rcreateRules');
    }

    render() {
        const { tableSource, paginations, selectData, } = this.props;
        const { rulesNum, fullState, orderStartTime, startPickerDisabled, orderEndTime,
            endPickerDisabled, } = this.state;
        return (
            <KeepAlive id='6' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._fullSearchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.pro_commont}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='规则名称'
                                    placeholder='请输入'
                                    value={rulesNum}
                                    bindThis={this}
                                    bindName='rulesNum'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XDatePicker
                                    label='下单时间'
                                    value={orderStartTime}
                                    disabled={startPickerDisabled}
                                    bindThis={this}
                                    bindName='orderStartTime'
                                />
                                <XDatePicker
                                    style={{ marginRight: '20px' }}
                                    value={orderEndTime}
                                    disabled={endPickerDisabled}
                                    bindThis={this}
                                    bindName='orderEndTime'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='频道分类'
                                    placeholder='请选择'
                                    renderData={selectData || []}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={fullState.value}
                                    showSearch={true}
                                    bindThis={this}
                                    bindName='fullState'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._fullSearchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._fullresetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }} label='创建推荐规则' onClick={this._createRules} />
                    </div>
                    <div className={`${styles.tableContainer}`}>
                        <Table
                            // bordered
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            // rowSelection={this._rowSelection} 
                            rowKey={(record, index) => record.id}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(tableSource)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={paginations.resultTotal}
                            pageSize={paginations.pageSize}
                            pageNum={paginations.pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    <div>{this._renderMode()}</div>
                </div>
            </KeepAlive>
        );
    }
}

Recommend.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,

    getrecommendList: PropTypes.func,// 通过Saga异步请求列表查询方法Action
    tableSource: PropTypes.array,
    paginations: PropTypes.object,
    selectData: PropTypes.array, // 分类状态下拉选项
    getrecommendSelect: PropTypes.func,// 通过Saga异步请求下拉选项数据方法Action
};

const mapStateToProps = (state) => {
    return {
        tableSource: state.showset.showsetDate.recommendtableSource,
        paginations: state.showset.showsetDate.recommendpaginations,
        selectData: state.showset.showsetDate.recommendselectData,
    };
};

export default connect(mapStateToProps, { getrecommendList, getrecommendSelect, })(Recommend);
