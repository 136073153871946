import React, { Component } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableBox from './components/TableBox';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/usermanage/org/model';

class org extends Component {

    static propTypes = {
        history: PropTypes.object,
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getOrgList: PropTypes.func,
        orgList: PropTypes.object,
    }

    static defaultProps = {
        orgList: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }
    }

    constructor(props) {
        super(props);
        this.state = {
            companyName: '',
            realName: '',
        };
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const {
            companyName,
            realName,
            pageNum,
            pageSize
        } = this.state;
        const query = {
            companyName,
            realName,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getOrgList(query);
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ companyName: '', realName: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { orgList } = this.props;
        const { companyName, realName } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='机构名称'
                                placeholder='请输入机构名称'
                                bindThis={this}
                                bindName='companyName'
                                value={companyName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='负责人'
                                placeholder='请输入负责人'
                                bindThis={this}
                                bindName='realName'
                                value={realName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableBox
                        paginationChange={this._paginationChange}
                        renderData={orgList}
                    />
                </div>
            </KeepAlive>
        );
    }
}

export default connect(state => ({
    orgList: state.org.getOrgList,
}), {
    keepSecondNavBreadcrumb,
    ...model.actions
})(org);