/**
 * 【日结账单】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { XSelect, XOKButton, XCancelButton, XInput, XDatePicker } from '@/components/xqxc_ui';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getMerchantSelectSource } from '@/reducers/storehousefinancialmanage/merchantreconciliation/actions';
import AdvertTable from './components/AdvertTable';
import KeepAlive from '@/routes/KeepAlive';
import http from '@/assets/api/http';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { message } from 'antd';
import model from '@/reducers/storehousefinancialmanage/transactionreconciliation/model';

class Main extends React.Component {

    state = {
        merchant: {},
        settIds: [],
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getMerchantSelectSource();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { beginDate, endDate, id, pageNum, pageSize, merchant } = this.state;
        const query = {
            beginDate,
            endDate,
            id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            merchantId: merchant.merchantId,
            payChannel: 'ADA_PAY'
        };
        this.props.getHuifuPayTableData(query);
        KeepAlive.saveCache({ ...query, merchant }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ beginDate: undefined, endDate: undefined, id: '', merchant: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    _tableAction = (id, type, item) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '对账详情',
                    path: { pathname: `/home/storehousefinancialmanage/dailystatement/huifudetail/${item.id}/${item.merchantId}/${item.gmtAccounting}` }
                });
                history.push({ pathname: `/home/storehousefinancialmanage/dailystatement/huifudetail/${item.id}/${item.merchantId}/${item.gmtAccounting}` });
                break;
            case 'selection':
                this.setState({ settIds: id });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => this._searchHandle('useCache'));
    }

    _generateReport = () => {
        if (this.state.settIds.length) {
            this.setState({ generating: true });
            http('/report/template/info', { id: 130 }).then(res => {
                if (res.code === 200) {
                    const queryCriteriaList = res.result.queryCriteriaList || [];
                    const purpose = '日结对账单';
                    const values = {
                        recIds: this.state.settIds.join(','),
                        merchantId: this.state.merchant.merchantId,
                        beginDate: this.state.beginDate,
                        endDate: this.state.endDate,
                        accessType: 'ADA_PAY'
                    };
                    queryCriteriaList.forEach(r => r.value = values[r.conditionFiled]);
                    http('/report/record/addRecord', { templateId: 130, queryCriteriaList, purpose }).then(res => {
                        if (res.code === 200) {
                            message.success('生成报表成功');
                        }
                    }).catch((res = {}) => message.error(res.message || '生成报表异常'));
                }
                this.setState({ generating: false });
            }).catch((res = {}) => {
                this.setState({ generating: false });
                message.error(res.message || '获取模板数据异常');
            });
        } else {
            message.warning('请选择一条记录');
        }
    }

    render() {
        const { id, beginDate, endDate, merchant = {} } = this.state;
        const { merchantSelectSource, huifuPayTableData } = this.props;
        return (
            <KeepAlive id='lianlina' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='对账单号'
                                placeholder='请输入'
                                value={id}
                                bindThis={this}
                                bindName='id'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户'
                                showSearch
                                placeholder='请选择商户'
                                renderData={merchantSelectSource}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                value={merchant.merchantName}
                                bindThis={this}
                                bindName='merchant'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                placeholder='请选择开始时间'
                                label='对账日期'
                                value={beginDate}
                                bindThis={this}
                                bindName='beginDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                placeholder='请选择结束时间'
                                style={{ marginRight: 20 }}
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.row2}>
                        <XOKButton
                            label='生成报表'
                            style={{ width: 120 }}
                            onClick={this._generateReport}
                        />
                    </div>
                    <AdvertTable renderData={huifuPayTableData} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getMerchantSelectSource: PropTypes.func,
    merchantSelectSource: PropTypes.array,
    getHuifuPayTableData: PropTypes.func,
    huifuPayTableData: PropTypes.object
};

const mapStateToProps = (state) => ({
    merchantSelectSource: state.merchantreconciliation.merchantSelectSource,
    huifuPayTableData: state.transactionreconciliation.huifuPayTableData
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath, keepSecondNavBreadcrumb,
    getMerchantSelectSource,
    ...model.actions
})(Main);