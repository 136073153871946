import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell, cellWidth } from '@/components/TableCell';
import ButtonPerssion from '@/routes/ButtonPerssion';

class TableContent extends Component {

	_columns = () => [
		{
			title: '申请单号',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '会员',
			dataIndex: 'ownerName',
			key: 'ownerName',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '手机号码',
			dataIndex: 'ownerPhone',
			key: 'ownerPhone',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '申请金额(元)',
			dataIndex: 'amount',
			key: 'amount',
			align: 'center',
			width: cellWidth.normal,
			render: priceFenToYuanCell
		},
		{
			title: '个税(元)',
			dataIndex: 'taxAmount',
			key: 'taxAmount',
			width: cellWidth.normal,
			align: 'center',
			render: priceFenToYuanCell
		},
		{
			title: '实际金额(元)',
			dataIndex: 'finalMoney',
			key: 'finalMoney',
			width: cellWidth.normal,
			align: 'center',
			render: priceFenToYuanCell
		},
		{
			title: '已付款金额(元)',
			dataIndex: 'paymentAmount',
			key: 'paymentAmount',
			align: 'center',
			width: cellWidth.normal,
			render: priceFenToYuanCell
		},
		{
			title: '账户类型',
			dataIndex: 'bindAccountType.value',
			key: 'bindAccountType.value',
			align: 'center',
			width: cellWidth.normal,
			render: tooltip
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			align: 'center',
			width: cellWidth.normal,
			render: (text) => this._stateCell(text)
		},
		{
			title: '申请时间',
			dataIndex: 'gmtCreate',
			key: 'gmtCreate',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'key',
			key: 'key',
			align: 'center',
			width: cellWidth.normal,
			fixed: 'right',
			render: (key, record) => this._tableAction(record.id, record)
		},
	];

	_stateCell = (text) => {
		return tooltip(text ? text.value : '');
	}

	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			this.props.tableAction(null, 'batch', selectedRowKeys);
			this.props.tableAction(null, 'batchRows', selectedRows);
		},
		getCheckboxProps: ({ status = {}, paymentId }) => {
			return { disabled: (status.code != 'COMPLETE' || paymentId) && status.code !== 'COMMIT' };
		},
	};
	// 清空表格复选框
	_cleanAllSelected = () => {
		this.props.tableAction(null, 'batch', []);
		this.props.tableAction(null, 'batchRows', []);
	}
	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		if (record.status.code === 'COMPLETE' && !record.paymentId) {//!record.paymentId
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
					{/* <div className={styles.item} onClick={() => tableAction(key, 'firAudit', record)}>初审</div> */}
					{/* <div className={styles.item} onClick={() => this.props.tableAction(key, 'secAudit',record)}>财务复审</div> */}
					<ButtonPerssion code='auditmanage/memberWithdrawal/xjfk'>
						<div className={styles.item} onClick={() => tableAction(key, 'createPay')}>新建付款</div>
					</ButtonPerssion>
				</div>
			);
		} else if (record.status.code === 'COMMIT') {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
					<ButtonPerssion code='auditmanage/memberWithdrawal/cs'>
						<div className={styles.item} onClick={() => tableAction(key, 'firAudit', record)}>初审</div>
					</ButtonPerssion>
				</div>
			);
		} else {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
				</div>
			);
		}
	};
	_tableData = (data) => {
		return data;
	}
	render() {
		const { renderData, withdrawalIds } = this.props;
		this._rowSelection.selectedRowKeys = withdrawalIds;
		return (
			<div className={styles.tableContainer} style={{ height: '100%' }}>
				<XTableSelectionCount selectedNum={withdrawalIds.length} onClean={this._cleanAllSelected} />
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowSelection={this._rowSelection}
					rowKey={record => record.id}
					columns={this._columns()}
					pagination={false}
					dataSource={this._tableData(renderData.dataList)}
					scroll={{ x: 'max-content', y: this.props.scrollY }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={this.props.paginationChange} />
			</div>
		);
	}
}
TableContent.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
	scrollY: PropTypes.string,
	withdrawalIds: PropTypes.array
};
export default XTableHOC(TableContent, 40);