/**
 * 批量签约
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './DetailModal.module.scss';
import http from '@/assets/api/http';
import { regFenToYuan } from '@/util/money';
import { XMediaFn } from '@/components/xqxc_ui';

class DetailModal extends React.Component {
    state = {
        confirmLoading: false,
        detail: {}
    };

    componentDidMount() {
        const { recordId } = this.props.param;
        http('/youfuOut/signDetail', { recordId }).then((response) => {
            console.log(response);
            this.setState({ detail: response.result });
        }).catch((error) => {
            message.error(error.message);
        });
    }

    _handleOk = () => {
        this.props.close();
    }

    _handleCancel = () => {
        this.props.close();
    }

    render() {
        const { confirmLoading, detail } = this.state;

        return (
            <Modal
                width={600}
                centered
                title='签约详情'
                visible={true}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.item}>
                        <div className={styles.label}>签约通道：</div><div>{detail.platCompanyName}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>签约对象：</div><div>{detail.levyName}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>当月剩余额度：</div><div>{regFenToYuan(detail.balance)}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>手机号码：</div><div>{detail.holderTel}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>姓名：</div><div>{detail.holderName}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>身份证号：</div><div>{detail.holderId}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>银行卡号：</div><div>{detail.bankCardNo}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>签约方式：</div><div>{detail.bindType}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>签约状态：</div><div>{detail.auditStatusText}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>签约时间：</div><div>{detail.gmtModified}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>签约备注：</div><div>{detail.auditStatusDesc}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>证件照：</div>
                    </div>
                    <div className={styles.media}>
                        <XMediaFn
                            style={{ width: '237px', height: '150px', marginRight: '20' }}
                            key={detail.idFrontUrl}
                            dataType='image'
                            dataSource={detail.idFrontUrl}
                        />
                        <XMediaFn
                            style={{ width: '237px', height: '150px', marginRight: '0' }}
                            key={detail.idBackUrl}
                            dataType='image'
                            dataSource={detail.idBackUrl}
                        />
                    </div>
                </div>
            </Modal>
        );

    }
}

DetailModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    close: PropTypes.func.isRequired, // 关闭弹框的回调函数
    param: PropTypes.object
};

export default DetailModal;