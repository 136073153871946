/**
 * 企业主播列表
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { message } from 'antd';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XDatePicker, showConfirm } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import AddPeople from './modal/addPeople';
import model from '@/reducers/usermanage/corporateanchor/model';
import TableContent from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { isPlat } from '@/assets/js/authType';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            curMode: '',
            businessCompanyName: '',
            mainTel: '',
            applyStartTime: null,
            applyEndTime: null,
        };
    }
    componentDidMount() {
        this.setState({ curMode: '' });
    }
    // 分页
    _paginationChange = (pageSize, pageNum) => {
        const pageSearch = { ...this.searchCache, pageSize, pageNum };
        this.props.getlistBusinieeAnchor(pageSearch);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【！！！！！！页面缓存第3步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (id, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'edit'://编辑
                addOneBreadcrumbPath({
                    title: '编辑申请',
                    path: {
                        pathname: '/home/usermanage/corporateanchor/add',
                        search: `?id=${id}`
                    }
                });
                history.push({
                    pathname: '/home/usermanage/corporateanchor/add',
                    search: `?id=${id}`
                });
                break;
            case 'detail'://详情
                addOneBreadcrumbPath({
                    title: '企业主播详情',
                    path: `/home/usermanage/corporateanchor/detail/${id}`,
                });
                history.push({
                    pathname: `/home/usermanage/corporateanchor/detail/${id}`
                });
                break;
            case 'add': //新增
                this.setState({ curMode: 'add', modeParam: { id } });
                break;
            case 'delete': //删除
                showConfirm('您确定删除该申请？', '', () => {
                    http(`/businessanchor/deleteBusinessAnchor/${id}`, {}, 'POST')
                        .then(res => {
                            message.success(res.message);
                            this._searchHandle('useCache');
                        })
                        .catch(error => {
                            message.error(error.message);
                        });
                });
                break;
        }
    }


    // 渲染弹框
    _renderMode = () => {
        const { modeParam } = this.state;
        switch (this.state.curMode) {
            case 'add':
                return <AddPeople title={'增加主播'} history={this.props.history} visible={true} refsh={() => this._searchHandle('useCache')} data={modeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ curMode: '' });
    }

    // 查询操作
    _searchHandle = (useCache) => {
        const { businessCompanyName, applyEndTime, applyStartTime, mainTel, pageNum, pageSize } = this.state;
        this.searchCache = {
            auditState: isPlat() ? 'ALREADY_OPENED' : null,
            businessCompanyName,
            applyEndTime: applyEndTime != null ? applyEndTime.format('YYYY-MM-DD') : null,
            applyStartTime: applyStartTime != null ? applyStartTime.format('YYYY-MM-DD') : null,
            mainTel,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,  // 【！！！！！！查询加上这个属性判断】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【！！！！！！查询加上这个属性判断】
        };
        this.props.getlistBusinieeAnchor(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【！！！！！！页面缓存第2步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ businessCompanyName: '', applyEndTime: null, applyStartTime: null, mainTel: '', pageNum: 1, pageSize: 10 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【！！！！！！页面缓存第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 新建申请
    _addMode = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建申请',
            path: '/home/usermanage/corporateanchor/add',
        });
        history.push({
            pathname: '/home/usermanage/corporateanchor/add'
        });
    }

    render() {
        const { businessCompanyName, mainTel, applyStartTime, applyEndTime } = this.state;
        const { listBusinieeAnchor } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【！！！！！！页面缓存第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                style={{ marginRight: 20 }}
                                label='企业名称'
                                placeholder='请输入企业名称'
                                value={businessCompanyName}
                                bindThis={this}
                                bindName='businessCompanyName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                style={{ marginRight: 20 }}
                                label='主账号手机'
                                placeholder='请输入主账号手机'
                                value={mainTel}
                                bindThis={this}
                                bindName='mainTel'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                allowClear
                                showTime={false}
                                label='申请时间'
                                value={applyStartTime}
                                bindThis={this}
                                bindName='applyStartTime'
                            // isFormat
                            />
                            <XDatePicker
                                allowClear
                                showTime={false}
                                style={{ marginRight: 20 }}
                                value={applyEndTime}
                                bindThis={this}
                                bindName='applyEndTime'
                            // isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                style={{ marginRight: 20 }}
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <ButtonPerssion code='usermanage/corporateanchor/xjsq'>
                        <XOKButton style={{ marginTop: '10px', width: 90 }} label='新建申请' onClick={() => this._addMode()} />
                    </ButtonPerssion>
                    <TableContent
                        renderData={listBusinieeAnchor}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // router history
    addOneBreadcrumbPath: PropTypes.func,
    getlistBusinieeAnchor: PropTypes.func,
    listBusinieeAnchor: PropTypes.object,
};
const mapStateToProps = (state) => ({
    listBusinieeAnchor: state.corporateanchor.listBusinieeAnchor,
});

export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath })(Main);