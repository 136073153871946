import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { SearchBox, SearchItem } from '@/components/Layout';
import { getWaitReceiveList } from '@/reducers/receiveordermanage/receiveorderlist/actions';
import { Mode } from './_utils';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends Component {
    state = {
        userPhone: undefined,
        startTime: undefined,
        endTime: undefined,
        pageSize: autopageSize,
        pageNum: autoPageNum
    }

    // 查询
    _searchHandle = (useCache) => {

        const { userPhone, startTime, endTime, pageNum, pageSize } = this.state;

        this.searchCache = {
            userPhone,
            startTime,
            endTime,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getWaitReceiveList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            userPhone: undefined,
            startTime: undefined,
            endTime: undefined,
            pageSize: autopageSize,
            pageNum: autoPageNum
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    paginationChange = (pageSize, pageNum) => {
        const { userPhone, startTime, endTime } = this.state;
        this.setState({ pageNum, pageSize });
        this.searchCache = {
            userPhone,
            startTime,
            endTime,
            pageNum,
            pageSize,
        };
        this.props.getWaitReceiveList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case Mode.Detail:
                addOneBreadcrumbPath({
                    title: '接单详情',
                    path: '/home/receiveordermanage/receiveorderlist/waitreceive/detail/' + id
                });
                history.push({
                    pathname: '/home/receiveordermanage/receiveorderlist/waitreceive/detail/' + id
                });
                break;
            // case Mode.Receive:
            //     console.log(id, type, params);
            //     this.props.updateContent('detail', params.id);
            //     break;
        }
    }

    render() {
        const { waitReceiveList } = this.props;
        const { startTime, endTime, userPhone } = this.state;
        return (
            <KeepAlive id='waitreceive' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='会员账号'
                                placeholder='请输入'
                                value={userPhone}
                                bindThis={this}
                                bindName='userPhone'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='发布时间'
                                value={startTime}
                                bindThis={this}
                                bindName='startTime'
                                isFormat
                            />&nbsp;
                        -
                        <XDatePicker
                                style={{ marginRight: '20px' }}
                                label=''
                                value={endTime}
                                bindThis={this}
                                bindName='endTime'
                                isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent paginationChange={this.paginationChange} renderData={waitReceiveList} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }

}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func,
    getWaitReceiveList: PropTypes.func,
    waitReceiveList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    waitReceiveList: state.receiveorderlist.waitReceiveList,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, getWaitReceiveList })(Main);