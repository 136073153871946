/**
 * [未寄售列表|爆品在售列表]
 * @author: Fu Xiaochun
 * @date: 2023-02-21 
 */

import React, { useRef, useState, useEffect } from 'react';
import styles from '../index.module.scss';
import PropTypes from 'prop-types';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XDatePicker, XOKButton, XCancelButton, XSelect, XRangePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import http from '@/assets/api/http';
import { Tabs, Icon, Tooltip, message  } from 'antd';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { useHistory } from 'react-router-dom';
import { regFenToYuan } from '@/util/money';

function Unconsignments(props){

    const history = useHistory();

    const [result, setResult] = useState({});
    const page = useRef({pageNum: 1, pageSize: 20});

    const [orderId, setOrderId] = useState();
    const [userTel, setUserTel] = useState();
    const [selfStatus, setSelfStatus] = useState({});
    const [completeTime, setCompleteTime] = useState([]);
    const [consignmentsValue, setConsignmentsValue] = useState({});
    
    const [resetCode, setResetCode] = useState(0);
    const [valuesEnum, setValuesEnum] = useState([]);  // 寄售（爆品）券价值列表
    const [selfStatusEnum, setSelfStatusEnum] = useState([]);  // 自提状态

    useEffect(()=>{
        getConsignmentsValues();
        getSelfStatusEnum();
        onSearch();
    }, []);

    // 重置后请求
    useEffect(() => {
        if (resetCode > 0) {
            onSearch();
        }
    }, [resetCode]);

    // 获取寄售（爆品）券价值列表
    const getConsignmentsValues = ()=>{
        let params = {};
        http('/marketing/orderConsign/findConsignValues', params, 'POST').then(res=>{
            let list = res.result || [];
            let _list = list.map(v=>({value: regFenToYuan(v), code: v}));
            
            setValuesEnum(_list);
        }).catch(err=>{
            message.error(err.message);
        });
    };

    // 获取自提状态列表
    const getSelfStatusEnum = ()=>{
        let params = {};
        http('/enum/ActivityConsignCompletedTypeEnum', params, 'POST').then(res=>{
            setSelfStatusEnum(res.result);
        }).catch(err=>{
            message.error(err.message);
        });
    };

    // 查询
    const onSearch = (usecache) => {
        if (usecache !== 'usecache') {
            page.current = { pageNum: 1, pageSize: 20 };
        }
        let searchCache = {
            consignState: 'COMPLETED',
            orderId,
            userTel,
            completedType:selfStatus.code,
            consignValue: consignmentsValue.code,
            consignCompleteTimeStart: completeTime[0] ? completeTime[0] : undefined,
            consignCompleteTimeEnd: completeTime[1] ? completeTime[1] : undefined,
            pageNum: page.current.pageNum,
            pageSize: page.current.pageSize,
        };

        http('/marketing/orderConsign/findPage', searchCache, 'POST').then(res=>{
            setResult(res.result);
        }).catch(err=>{
            message.error(err.message);
        });
    };

    // 重置
    const onReset = () => {
        setOrderId();
        setUserTel();
        setCompleteTime([]);
        setConsignmentsValue({});
        setSelfStatus({});
        setResetCode(resetCode + 1);
        page.current = { pageNum: 1, pageSize: 20 };
    };

    const onPageChange = (size, num) => {
        page.current = { pageSize: size, pageNum: num };
        onSearch('usecache');
    };

    const tableAction = (type, record)=>{
        let { buyerId, orderId } = record;
        switch (type) {
            case 'detail'://订单详情
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: {
                        pathname: '/home/ordermanage/productorder/orderdetail',
                        search: `?buyerId=${buyerId ? buyerId : null}&&orderId=${orderId}`,
                    }
                });
                history.push({
                    pathname: '/home/ordermanage/productorder/orderdetail',
                    search: `?buyerId=${buyerId ? buyerId : null}&&orderId=${orderId}`,
                });
                break;
        }
    };

    return (
        <div className={styles.flexBoxContainer}>
            <SearchBox>
                <SearchItem>
                    <XInput
                        label='订单编号'
                        placeholder='请输入'
                        value={orderId}
                        bindThis={setOrderId}
                        bindName='order'
                    />
                </SearchItem>
                <SearchItem>
                    <XInput
                        label='会员账号'
                        placeholder='请输入'
                        value={userTel}
                        bindThis={setUserTel}
                        bindName='userTel'
                    />
                </SearchItem>
                <SearchItem>
                    <XRangePicker
                        style={{marginRight: '20px'}}
                        inputStyle={{width: '320px'}}
                        label='完成时间'
                        placeholder='请选择'
                        bindThis={setCompleteTime}
                        bindName='completeTime'
                        value={completeTime}
                        format="YYYY-MM-DD HH:mm:ss"
                        showTime={{format: 'HH:mm:ss'}}
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        label='爆品券价值'
                        placeholder='请选择'
                        renderData={valuesEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={consignmentsValue.value}
                        showSearch={true}
                        bindThis={setConsignmentsValue}
                        bindName='consignmentsValue'
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        label='自提状态'
                        placeholder='请选择'
                        renderData={selfStatusEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={selfStatus.value}
                        showSearch={true}
                        bindThis={setSelfStatus}
                        bindName='selfStatus'
                    />
                </SearchItem>
                <SearchItem>
                    <XOKButton
                        label='查询'
                        onClick={onSearch}
                    />
                    <XCancelButton
                        label='重置'
                        onClick={onReset}
                    />
                </SearchItem>
            </SearchBox>
            <TableContent renderData={result} paginationChange={onPageChange} tableAction={tableAction} />
        </div>
    );
}

export default Unconsignments;