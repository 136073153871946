import { createModel } from '@/store/tools';

const model = {
    namespace: 'facilitymanage',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getHotelLevelList': T('getHotelFacilityList'),
        },
        actions: {
            'getHotelFacilityList': A('getHotelFacilityList'),
        },
        sagas: {
            'getHotelFacilityList': S('getHotelFacilityList', '/hotel/installation/hotelFacilityList'),
        },
        reducers: {
            'hotelFacilityList': R('getHotelFacilityList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
        }
    })
};
export default createModel(model);