import * as T from './actiontypes';

//初始数据
const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    resultTotal: 0
};

// 接单记录
export const receiveRecordList = (state = initData, action) => {
    switch (action.type) {
        case T.SET_RECEIVE_RECORD_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

// 接单记录详情
export const receiveRecordDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_RECEIVE_RECORD_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

// 待接单记录
export const waitReceiveList = (state = initData, action) => {
    switch (action.type) {
        case T.SET_WAIT_RECEIVE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

// 待接单记录详情
export const waitReceiveDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_WAIT_RECEIVE_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

// 待接单记录状态
export const receiveRecordState = (state = [], action) => {
    switch (action.type) {
        case T.SET_RECEIVE_RECORD_STATE:
            return action.payload.result;
        default:
            return state;
    }
};
