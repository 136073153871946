/**
 *  审核管理 - 会员提现申请审核
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { Tabs } from 'antd';
import styles from './index.module.scss';
import { setLocalStorage, removeLocalStorage, getLocalStorage } from '@/assets/js/storage';

const { TabPane } = Tabs;

class MemberWithdrawal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: '',
        };
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
        const memberWithdrawalTabId =  getLocalStorage('memberWithdrawalTabId');
        if (memberWithdrawalTabId) {
            this.setState({tabIndex: memberWithdrawalTabId});
        }
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case 'FIRAUDIT':
                addOneBreadcrumbPath({
                    title: '会员提现申请初审',
                    path: '/home/auditmanage/memberWithdrawal/firstAudit/'
                });
                history.push({
                    pathname: '/home/auditmanage/memberWithdrawal/firstAudit/' + value
                });
                break;
            case 'SECAUDIT':
                addOneBreadcrumbPath({
                    title: '会员提现申请复审',
                    path: '/home/auditmanage/memberWithdrawal/secondAudit/'
                });
                history.push({
                    pathname: '/home/auditmanage/memberWithdrawal/secondAudit/' + value
                });
                break;
            case 'DETAIL':
                addOneBreadcrumbPath({
                    title: '会员提现申请详情',
                    path: '/home/auditmanage/memberWithdrawal/detail'
                });
                history.push({
                    pathname: '/home/auditmanage/memberWithdrawal/detail/' + value
                });
                break;
        }
    }

    tabChange = (key) => {
        this.setState({
            tabIndex: key
        });
        setLocalStorage('memberWithdrawalTabId', key);
    }

    render() {
        const tabIndex = this.state.tabIndex;
        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.tabBox}>
                    <Tabs activeKey={tabIndex} onChange={this.tabChange}>
                        <TabPane tab="收益账户" key="income"></TabPane>
                        <TabPane tab="爆品收益账户" key="consignmentIncome"></TabPane>
                        <TabPane tab="礼屋收益账户" key="liwuIncome"></TabPane>
                    </Tabs>
                </div>
                {tabIndex == 'income' && <Main updateContent={this._updateContent} key='income' curTab='income' history={this.props.history} />}
                {tabIndex == 'consignmentIncome' && <Main updateContent={this._updateContent} key='consignmentIncome' curTab='consignmentIncome' history={this.props.history} />}
                {tabIndex == 'liwuIncome' && <Main updateContent={this._updateContent} key='liwuIncome' curTab='liwuIncome' history={this.props.history} />}
            </div>
        );
    }
}

MemberWithdrawal.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(MemberWithdrawal);