/**
 * 商品管理 - 品牌异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const getProductBrand = function* () {
    yield takeEvery(T.GET_PRODUCTBRAND_LIST, function* requestData(action) {
        try {
            let result = yield http('/goods/brand/listBrands', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCTBRAND_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductBrandDetail = function* () {
    yield takeEvery(T.GET_PRODUCTBRAND_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/goods/brand/infoBrand', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCTBRAND_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getThirdPartyProductBrand = function* () {
    yield takeEvery(T.GET_THIRDPARTY_PRODUCTBRAND_LIST, function* requestData(action) {
        try {
            let result = yield http('/goods/baseProduct/findBaseGoodsBrandPage', action.payload, 'POST');
            yield put({ type: T.SET_THIRDPARTY_PRODUCTBRAND_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};