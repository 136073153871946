import http from '@/assets/api/http';

export default {
    image: {
        getToken: () => http('/qiniu/uploadImgToken'),
        getFile: ({ key }) => http('/qiniu/getUrl/' + key),
    },
    file: {
        //getToken: ({ fileName }) => http('/qiniu/uploadVideoToken/' + fileName),
        getToken: ({ fileName }) => http('/qiniu/uploadVideoToken'),
        getFile: ({ fileName, key }) => http('/qiniu/getUrlAudioVideo/', { fileName: key })
    }
};