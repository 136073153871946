import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import http from '@/assets/api/http';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { message } from 'antd';
import { XTabNav } from '@/components/xqxc_ui';
import Content from './components/Content';
import model from '@/reducers/systemmanage/decoratemanage/model';
import PreviewModal from '@/vcomps/CustomPreview';

const tabs = [
    {
        category: 'SHOP',
        title: '首页',
    },
    {
        category: 'SUBJECT_MT',
        title: '专题',
    },
];

class ShowDecorate extends Component {

    static propTypes = {
        history: PropTypes.object.isRequired,//路由对象
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getDecorateManageList: PropTypes.func,
        decorateManageList: PropTypes.object,
        getDecorateClass: PropTypes.func,
        decorateClass: PropTypes.array,
    }

    static defaultProps = {
        decorateManageList: { dataList: [], pageNum: 1, pageSize: 20, resultTotle: 0 },
        decorateClass: [],
    }

    state = {
        customTemplateClassId: 0,
        category: 'SHOP',
        pageNum: 1,
        pageSize: 20,
        renderModal: false,
        modalParam: null
    }

    componentDidUpdate(preProps, preState) {
        const { getDecorateManageList: { dataList = [] } = {} } = this.props;
        let newPageSize = preState.pageSize + 20;
        if (preState.pageSize < dataList.length) {
            this.setState({ pageSize: newPageSize });
        }
    }

    componentDidMount() {
        const { customTemplateClassId, category, pageNum, pageSize } = this.state;
        const { getDecorateClass, keepSecondNavBreadcrumb, getDecorateManageList, history } = this.props;
        if (history.location.category) {
            this.setState({ category: history.location.category });
            getDecorateManageList({ customTemplateClassId: customTemplateClassId ? customTemplateClassId : null, category: history.location.category, pageNum, pageSize });
        } else {
            getDecorateManageList({ customTemplateClassId: customTemplateClassId ? customTemplateClassId : null, category, pageNum, pageSize });
        }
        keepSecondNavBreadcrumb(); // 面包屑导航只保留到2级
        getDecorateClass();
    }


    //请求函数
    onRequest = (url, data) => {
        const { customTemplateClassId, category, pageNum, pageSize } = this.state;
        http(url, data, 'POST')
            .then(res => {
                message.success(res.message);
                this.props.getDecorateManageList({ customTemplateClassId: customTemplateClassId ? customTemplateClassId : null, category, pageNum, pageSize });
            })
            .catch(e => message.error(e.message));
    }

    // Tab页切换
    onTabChange = (category) => {
        const { pageNum, pageSize } = this.state;
        this.setState({ category, customTemplateClassId: 0 }, () => {
            this.props.getDecorateManageList({ customTemplateClassId: null, category, pageNum, pageSize });
        });
    }

    //页面跳转
    onPageJumps = (title, url) => {
        const { history, addOneBreadcrumbPath } = this.props;
        const baseUrl = '/home/systemmanage/shopdecorate';
        addOneBreadcrumbPath({
            title,
            path: baseUrl
        });
        history.push({
            pathname: `${baseUrl}${url}`,
        });
    }

    //分类改变触发
    onClassChange = ({ target: { value } }) => {
        const { category, pageNum, pageSize } = this.state;
        this.setState({ customTemplateClassId: value }, () => {
            this.props.getDecorateManageList({ customTemplateClassId: value ? value : null, category, pageNum, pageSize });
        });
    }

    //滚动加载
    onScrollLoad = (value) => {
        if (value) {
            const { customTemplateClassId, category, pageNum, pageSize } = this.state;
            let newPageSize = pageSize + 20;
            this.props.getDecorateManageList({
                customTemplateClassId: customTemplateClassId ? customTemplateClassId : null,
                category, pageNum, pageSize: newPageSize
            });
        }
    }

    // 打开弹窗（创建/编辑）
    onRenderMode = () => {
        const { renderModal, modalParam, category } = this.state;
        if (renderModal) {
            return <PreviewModal
                templateId={modalParam}
                category={category}
                modalProps={{
                    visible: true,
                    onCancel: () => this.setState({ renderModal: false, modalParam: null })
                }} />;
        }
    }

    render() {
        const { category, customTemplateClassId } = this.state;
        const { decorateClass, decorateManageList } = this.props;

        return <div className={styles.container}>
            <XTabNav
                selfKey='category'
                selfValue='title'
                renderData={tabs}
                onChange={this.onTabChange}
                activedID={category} />
            <Content
                category={category}
                onRemove={this.onRequest}
                onScrollLoad={this.onScrollLoad}
                onClassChange={this.onClassChange}
                onPageJumps={this.onPageJumps}
                customTemplateClassId={customTemplateClassId}
                onPreTemple={value => this.setState({ modalParam: value, renderModal: true })}
                dataList={decorateManageList.dataList}
                decorateClass={decorateClass} />
            {this.onRenderMode()}
        </div>;
    }
}

export default connect(state => ({
    decorateClass: state.decorateManage.decorateClass,
    decorateManageList: state.decorateManage.decorateManageList,
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(ShowDecorate);