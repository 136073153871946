/**
 *  会员管理弹框集合
 * */ 
export { default as CharmModal } from './CharmModal'; // 修改会员魅力值弹框
export { default as StarlightModal } from './StarlightModal'; // 修改会员魅力值弹框
export { default as StarScoreModal } from './StarScoreModal'; // 修改会员人气值弹框
export { default as CouponModal } from './CouponModal'; // 赠送优惠券弹框
export { default as LevelModal } from './LevelModal'; // 赠送会员等级弹框
export { default as ReleaseProhibitModal } from './ReleaseProhibitModal'; // 会员解禁弹框
export { default as ServeceModal } from './ServeceModal'; // 修改服务商弹框
export { default as AreaModal } from './AreaModal'; // 修改区域弹框
export { default as SetcontrolModal } from './SetcontrolModal'; // 设为自控


