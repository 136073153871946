/**
 * 知识付费设置
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message, Switch } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import PayTable from './components/PayTable';
import PayModal from './modal/PayModal';
import { XInput, XOKButton, XCancelButton, XSelect, showConfirm } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/systemmanage/showset/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: '',//会员账号
            nickName: '',//会员昵称
            status: {},//权限状态
            type: {},//权限类型
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            whiteList: false,//是否开启白名单
            selectedRowKeys: [],//对应的key数据
            selectedRows: [],//对应的key行数据
        };
    }

    componentDidMount() {
        this.props.getPayState();//状态
        this.props.getPayType();//类型
        const code = 'IS_CLOSE_WHOLE_KNOWLEDGE_PAYMENT';
        http(`/admin/param/getParamValueByCode/${code}`, {}, 'POST')//是否开启白名单默认值
            .then((res) => {
                this.setState({ whiteList: res.result == '1' ? true : false });
            }).catch((reject={}) => {
                message.error(reject.message);
            });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, account, nickName, status, type } = this.state;
        this.searchCache = {
            accountName: account,//会员账号
            nickName: nickName,//会员昵称
            state: status.code,//权限状态
            type: type.code,//权限类型
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getPayList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ account: '', nickName: '', status: {}, type: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建编辑
    _newHandle = () => {
        this.setState({
            currentOpenMode: 'CREAT'
        });
    }

    // 批量删除F
    _batchHandle = () => {
        const { selectedRowKeys } = this.state;
        if (selectedRowKeys.length == 0) {
            message.warn('至少选中一条白名单信息。');
        } else {
            showConfirm('您是否删除，当前选中会员?', '', () => {
                let data = {
                    ids: selectedRowKeys
                };
                this.httpSwitchSum('/user/userChargeShow/deleteUserChargeShowByIds', data, '删除成功。');
            });
        }
    }
    //获取选中数据
    selectedRowsHandle = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getPayList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    //表格switch请求
    httpSwitchSum = (url, data, tooltip, callback) => {
        http(url, data, 'POST')
            .then(() => {
                if (typeof callback === 'function') callback();
                this.props.getPayList(this.searchCache);
                message.success(tooltip);
            }).catch((reject={}) => {
                message.error(reject.message);
            });
    }

    // 表格操作 
    _tableAction = (item, type, value) => {
        switch (type) {
            case 'delete'://删除
                showConfirm('您是否要删除，当前选中的数据。', '', () => {
                    let data = {
                        ids: [item]
                    };
                    this.httpSwitchSum('/user/userChargeShow/deleteUserChargeShowByIds', data, '删除成功。');
                });
                break;
            case 'live'://直播权限
                {
                    let texts = item.isOpenLiveCharge == '1' ? '您是否要关闭该直播权限。' : '您是否要开启该直播权限。';
                    showConfirm(texts, '', () => {
                        let data = {
                            id: item.id,
                            type: 'LIVE',
                            state: value === false ? 0 : 1
                        };
                        this.httpSwitchSum('/user/userChargeShow/updatePermission', data, '修改成功。');
                    });
                }

                break;
            case 'video'://视频权限
                {
                    let texts = item.isOpenVideoCharge == '1' ? '您是否要关闭该视频权限。' : '您是否要开启该视频权限。';
                    showConfirm(texts, '', () => {
                        let data = {
                            id: item.id,
                            type: 'VIDEO',
                            state: value === false ? 0 : 1
                        };
                        this.httpSwitchSum('/user/userChargeShow/updatePermission', data, '修改成功。');
                    });
                }

                break;
            case 'community'://社区权限
                {
                    let texts = item.isOpenGraphicsCharge == '1' ? '您是否要关闭该社区权限。' : '您是否要开启该社区权限。';
                    showConfirm(texts, '', () => {
                        let data = {
                            id: item.id,
                            type: 'GRAPHICS',
                            state: value === false ? 0 : 1
                        };
                        this.httpSwitchSum('/user/userChargeShow/updatePermission', data, '修改成功。');
                    });
                }
                break;
        }
    }

    renderModal = () => {
        switch (this.state.currentOpenMode) {
            case 'CREAT':
                return <PayModal title='批量新建' visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }
    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getPayList(this.searchCache);
        }
    }

    //是否关闭白名单
    whiteList = (value) => {
        showConfirm(`本操作涉及系统敏感操作，您确定是否${value === false ? '关闭' : '开启'}白名单?`, '当前操作会影响到 “秀场APP” 中会员是否拥开通知识付费功能，请慎重使用', () => {
            http('/user/userChargeShow/updateWhitelist', { paramValue: value === false ? 0 : 1 }, 'POST')
                .then(() => {
                    this.setState({ whiteList: value });
                    value === false ? message.success('修改成功！每日说APP已关闭全员知识付费功能。') : message.success('修改成功！每日说APP已开启全员知识付费功能。');
                }).catch((reject={}) => {
                    message.error(reject.message);
                });
        });
    }

    render() {
        const { account, nickName, whiteList, status, type } = this.state;
        const { tableList, states, payType } = this.props;

        return (
            <KeepAlive id='7' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.payTop}>
                        <div className={styles.top_title}>
                            <span>基础设置</span>
                        </div>
                        <div className={styles.riskcontent}>
                            <div className={styles.item}>
                                <h4>是否关闭白名单：</h4>
                                <p>控制白名单是否生效（如关闭将全员拥有知识付费功能）</p>
                                <div>
                                    <Switch
                                        checkedChildren='NO'
                                        unCheckedChildren='OFF'
                                        checked={whiteList}
                                        onChange={this.whiteList} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.top_title}>
                            <span>白名单设置</span>
                        </div>
                    </div>
                    <div className={styles.searchBox}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='会员账号'
                                    placeholder='请输入会员账号'
                                    value={account}
                                    bindThis={this}
                                    bindName='account'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='会员昵称'
                                    placeholder='请输入会员昵称'
                                    value={nickName}
                                    bindThis={this}
                                    bindName='nickName'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='权限类型'
                                    placeholder='请选择'
                                    renderData={payType}
                                    dataIndex='value'
                                    keyIndex='code'
                                    showSearch
                                    value={type.value}
                                    bindThis={this}
                                    bindName='type'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='权限状态'
                                    placeholder='请选择'
                                    renderData={states}
                                    dataIndex='value'
                                    keyIndex='code'
                                    showSearch
                                    value={status.value}
                                    bindThis={this}
                                    bindName='status'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: '90px' }}
                            label='新建名单'
                            onClick={this._newHandle}
                        />
                        <XOKButton
                            style={{ width: '90px', marginLeft: '20px' }}
                            label='批量删除'
                            onClick={this._batchHandle}
                        />
                    </div>
                    <PayTable
                        renderData={tableList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                        selectedRowsHandle={this.selectedRowsHandle}
                    />
                    {this.renderModal()}
                </div>
            </KeepAlive>
        );
    }
}


Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    tableList: PropTypes.object,
    getPayList: PropTypes.func,//表格数据(action)
    states: PropTypes.array,
    getPayState: PropTypes.func,//状态数据(action)
    payType: PropTypes.array,
    getPayType: PropTypes.func,//类型数据(action)
};

const mapStateToProps = (state) => ({
    tableList: state.showset.paymentList, // 表格数据
    states: state.showset.payState, // 状态
    payType: state.showset.payType, // 类型
});

export default connect(mapStateToProps, { ...model.actions })(Main);
