import { createModel } from '@/store/tools';

const model = {
    namespace: 'channelaudit',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getPositiveAuditData': T('getPositiveAuditData'),
            'getStateEnum': T('getStateEnum'),
            'getPositiveAuditInfo': T('getPositiveAuditInfo'),
            'getClassList': T('getClassList'),
            'getCreditAuditData': T('getCreditAuditData'),
            'getStatusEnum': T('getStatusEnum'),
        },
        actions: {
            'getPositiveAuditData': A('getPositiveAuditData'),
            'getStateEnum': A('getStateEnum'),
            'getPositiveAuditInfo': A('getPositiveAuditInfo'),
            'getClassList': A('getClassList'),
            'getCreditAuditData': A('getCreditAuditData'),
            'getStatusEnum': A('getStatusEnum'),
        },
        sagas: {
            'getPositiveAuditData': S('getPositiveAuditData', '/user/supplierAccountInfo/supplierAccountAuditList'),
            'getStateEnum': S('getStateEnum', '/enum/AuditStatusEnum'),
            'getPositiveAuditInfo': S('getPositiveAuditInfo', '/user/supplierAccountInfo/supplierAccountAuditInfo', 'POST', 'id'),
            'getClassList': S('getClassList', '/supplier/config/category/list'),
            'getCreditAuditData': S('getCreditAuditData', '/settle/creditLimitReview/queryCreditLimitRecordsList'),
            'getStatusEnum': S('getStatusEnum', '/enum/CreditsAuditStatusEnum'),
        },
        reducers: {
            'positiveAuditData': R('getPositiveAuditData', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'stateEnum': R('getStateEnum', []),
            'positiveAuditInfo': R('getPositiveAuditInfo', {}),
            'classList': R('getClassList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'creditAuditData': R('getCreditAuditData', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'statusEnum': R('getStatusEnum', []),
        }
    })
};
export default createModel(model);