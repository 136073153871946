/**
 * 直播间列表
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, XInput, XTableAdapter } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { setSessionStorage } from '@/assets/js/storage';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/knowledgepay/channellist/model';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import OnlinePlayModal from './modal/OnlinePlayModal';
import { SearchBox, SearchItem } from '@/components/Layout';


class LiveList extends Component {

    state = {
        renderMode: '',
        modeParams: {},
        tel: '', // 手机号码
        nickname: '', // 首页推荐
        classifies: {},
        homeRec: {},
        channelRec: {},
        scrollY: 0,
        violationType: {},//违规状态
    }

    componentDidMount() {
        this.props.getKClassifySele({ classTypeCode: 'CHARGE_LIVE_BORADCAS_CLASS' });
        this.props.keepSecondNavBreadcrumb();
        this.props.getViolationType();//违规状态
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case 'liveHistory': // 直播间列表 - 直播间历史记录
                addOneBreadcrumbPath({
                    title: '直播间历史记录',
                    path: '/home/knowledgepay/channellist/livehistory/'
                });
                history.push({
                    pathname: '/home/knowledgepay/channellist/livehistory/'
                });
                break;
            case 'recommendList': // 直播间列表 - 直播间历史记录
                addOneBreadcrumbPath({
                    title: '推荐列表',
                    path: '/home/knowledgepay/channellist/liverecommend/'
                });
                history.push({
                    pathname: '/home/knowledgepay/channellist/liverecommend/'
                });
                break;
            case 'prohibit': // 直播间列表 - 直播间历史记录
                addOneBreadcrumbPath({
                    title: '直播间封禁',
                    path: '/home/knowledgepay/channellist/liveprohibit/'
                });
                history.push({
                    pathname: '/home/knowledgepay/channellist/liveprohibit/' + value
                });
                break;
            case 'beans': // 直播间列表 - 直播间历史记录
                addOneBreadcrumbPath({
                    title: '魅力值收入',
                    path: '/home/knowledgepay/channellist/livebeans/'
                });
                history.push({
                    pathname: '/home/knowledgepay/channellist/livebeans/' + value
                });
                break;
            case 'sell': // 直播间列表 - 直播间历史记录
                addOneBreadcrumbPath({
                    title: '销售商品分红',
                    path: '/home/knowledgepay/channellist/livesell/'
                });
                history.push({
                    pathname: '/home/knowledgepay/channellist/livesell/' + value
                });
                break;
            case 'knowledge': // 直播间列表 - 直播间历史记录
                addOneBreadcrumbPath({
                    title: '知识付费收益',
                    path: '/home/knowledgepay/channellist/liveknowledge/'
                });
                history.push({
                    pathname: '/home/knowledgepay/channellist/liveknowledge/' + value
                });
                break;
            case 'violation': // 直播间-本场违规
                addOneBreadcrumbPath({
                    title: '本场违规',
                    path: {
                        pathname: '/home/knowledgepay/channellist/violation',
                        search: `broadcasTitle=${value.broadcasTitle}&&nickname=${value.nickname}&&liveId=${value.liveChargeId}&&accountId=${value.accountId}`,
                    }
                });
                history.push({
                    pathname: '/home/knowledgepay/channellist/violation',
                    search: `broadcasTitle=${value.broadcasTitle}&&nickname=${value.nickname}&&liveId=${value.liveChargeId}&&accountId=${value.accountId}`,
                });
                break;
        }
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, homeRec, channelRec, nickname, classifies, tel, violationType } = this.state;
        this.searchCache = {
            nickname, tel, chargeClassId: classifies.id,
            isAllReco: homeRec.code, isChannelReco: channelRec.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
            isDetectRead: violationType.code,//违规状态
        };
        this.props.getKLiveList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ tel: '', nickname: '', classifies: {}, homeRec: {}, channelRec: {}, violationType: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 分页发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { modeParams, renderMode } = this.state;
        switch (renderMode) {
            case 'playback':
                return <OnlinePlayModal visible={true} closeMode={this._closeMode} modeParams={modeParams} />;
            case 'live':
                return <OnlinePlayModal visible={true} closeMode={this._closeMode} modeParams={modeParams} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ renderMode: '' }, () => {
            update && this._searchHandle('useCache');
        });
    }

    //请求函数
    _reqFunc = (url, data, tip) => {
        http(url, data, 'POST')
            .then(() => {
                message.success(tip);
                this._searchHandle('useCache');
            })
            .catch(error => {
                message.error(error.message);
            });
    }

    //表格操作
    _tableAction = (id, active, value) => {
        switch (active) {
            case 'beans':
                setSessionStorage('klbeansinfo', { telephone: value.tel, nickName: value.nickname, liveTitle: value.broadcasTitle, classify: value.chargeClassName });
                this._updateContent('beans', id);
                break;
            case 'sell':
                setSessionStorage('klsellinfo', { telephone: value.tel, nickName: value.nickname, liveTitle: value.broadcasTitle, classify: value.chargeClassName });
                this._updateContent('sell', id);
                break;
            case 'knowledge':
                setSessionStorage('klknowledgeinfo', { telephone: value.tel, nickName: value.nickname, liveTitle: value.feedTitle, classify: value.chargeClassName });
                this._updateContent('knowledge', id);
                break;
            case 'playback':
                this.setState({ renderMode: 'playback', modeParams: { playbackUrl: value.playbackUrl } });
                break;
            case 'live':
                this.setState({ renderMode: 'live', modeParams: { id: value.liveChargeId } });
                break;
            case 'prohibit':
                this._updateContent('prohibit', value.accountId);
                break;
            case 'home':
                if (value.state) {
                    this._reqFunc('/charge/live/recoAnchor', { accountId: value.accountId, recoType: 'CHARGE_ALL' }, '首页推荐成功');
                } else {
                    this._reqFunc('/charge/live/unRecoAnchor', { accountId: value.accountId, recoType: 'CHARGE_ALL' }, '取消首页推荐成功');
                }
                break;
            case 'channel':
                if (value.state) {
                    this._reqFunc('/charge/live/recoAnchor', { accountId: value.accountId, broadcasClassId: value.chargeClassId, recoType: 'CHARGE_CHANNEL' }, '频道推荐成功');
                } else {
                    this._reqFunc('/charge/live/unRecoAnchor', { accountId: value.accountId, broadcasClassId: value.chargeClassId, recoType: 'CHARGE_CHANNEL' }, '取消频道推荐成功');
                }
                break;
            case 'violation'://本场违规
                this._updateContent('violation', value);
                break;
            default:
                break;
        }
    }

    render() {
        const { homeRec, channelRec, classifies, tel, nickname, scrollY, violationType } = this.state;
        const { recEnum, kClassifySele, kLiveList,violationStatus } = this.props;

        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='手机号码'
                                    placeholder='请输入手机号码'
                                    bindName='tel'
                                    bindThis={this}
                                    value={tel}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='主播昵称'
                                    placeholder='请输入主播昵称'
                                    bindName='nickname'
                                    bindThis={this}
                                    value={nickname}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='所属分类'
                                    placeholder='请选择'
                                    renderData={kClassifySele}
                                    bindName='classifies'
                                    bindThis={this}
                                    dataIndex='className'
                                    keyIndex='id'
                                    value={classifies.className}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='首页推荐'
                                    placeholder='请选择'
                                    renderData={recEnum}
                                    dataIndex='value'
                                    keyIndex='code'
                                    bindName='homeRec'
                                    bindThis={this}
                                    value={homeRec.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='频道推荐'
                                    placeholder='请选择'
                                    renderData={recEnum}
                                    dataIndex='value'
                                    keyIndex='code'
                                    bindName='channelRec'
                                    bindThis={this}
                                    value={channelRec.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='违规状态'
                                    placeholder='请选择'
                                    renderData={violationStatus}
                                    bindName='violationType'
                                    bindThis={this}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={violationType.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '150px' }} label='直播间历史记录' onClick={() => this._updateContent('liveHistory')} />
                        <XOKButton style={{ width: '120px', marginLeft: '30px' }} label='推荐列表' onClick={() => this._updateContent('recommendList')} />
                    </div>
                    <XTableAdapter render={scrollY => this.setState({ scrollY })}>
                        <TableContent scrollY={scrollY} paginationChange={this._paginationChange} renderData={kLiveList} tableAction={this._tableAction} />
                    </XTableAdapter>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

LiveList.propTypes = {
    history: PropTypes.object, // 路由history对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息
    getKLiveList: PropTypes.func,
    kLiveList: PropTypes.object,
    getKClassifySele: PropTypes.func,
    kClassifySele: PropTypes.array,
    recEnum: PropTypes.array,

    getViolationType: PropTypes.func,//违规状态action
    violationStatus: PropTypes.array,//违规状态数据
};

const mapStateToProps = (state) => ({
    kLiveList: state.klist.kLiveList,
    kClassifySele: state.klist.kClassifySele,
    scrollY: PropTypes.string,
    violationStatus: state.klist.violationStatus,//违规状态
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(LiveList);