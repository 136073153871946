//获取保存违规的店铺列表
export const GET_ILLEGAL_SHOPLIST = 'ILLEGALLIST/GET_ILLEGAL_SHOPLIST';
export const SET_ILLEGAL_SHOPLIST = 'ILLEGALLIST/SET_ILLEGAL_SHOPLIST';

//获取保存违规的列表状态下拉
export const GET_ILLEGAL_SELE = 'ILLEGALLIST/GET_ILLEGAL_SELE';
export const SET_ILLEGAL_SELE = 'ILLEGALLIST/SET_ILLEGAL_SELE';

//获取保存违规的店铺列表详情
export const GET_ILLEGAL_SHOPDETAIL = 'ILLEGALLIST/GET_ILLEGAL_SHOPDETAIL';
export const SET_ILLEGAL_SHOPDETAIL = 'ILLEGALLIST/SET_ILLEGAL_SHOPDETAIL';

//获取保存违规的商品列表
export const GET_ILLAGEL_PRODUCTLIST = 'ILLEGALLIST/GET_ILLAGEL_PRODUCTLIST';
export const SET_ILLAGEL_PRODUCTLIST = 'ILLEGALLIST/SET_ILLAGEL_PRODUCTLIST';

//获取保存违规的商品列表详情
export const GET_ILLEGAL_PRODUCTDETAIL = 'ILLEGALLIST/GET_ILLEGAL_PRODUCTDETAIL';
export const SET_ILLEGAL_PRODUCTDETAIL = 'ILLEGALLIST/SET_ILLEGAL_PRODUCTDETAIL';

//获取保存违规商品的列表状态下拉
export const GET_ILLEGAL_PRODUCT = 'ILLEGALLIST/GET_ILLEGAL_PRODUCT';
export const SET_ILLEGAL_PRODUCT = 'ILLEGALLIST/SET_ILLEGAL_PRODUCT';