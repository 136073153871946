/**
 * 【广告投放审核】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getAdvertPlacementList } from '@/reducers/advertmanage/advertPlacement/actions';
import AdvertTable from './components/AdvertTable';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {

    state = {
        schedule: '',
    }

    // 查询
    _searchHandle = (useCache) => {
        const { schedule, pageSize, pageNum } = this.state;
        this.searchCache = {
            adName: schedule,
            state: 'COMMIT',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAdvertPlacementList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ schedule: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.props.getAdvertPlacementList({ ...this.searchCache, pageSize, pageNum });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (id, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'audit':
                addOneBreadcrumbPath({
                    title: '广告投放审核',
                    path: `/home/auditmanage/advertPlacementAudit/audit/${id}`
                });
                history.push({
                    pathname: `/home/auditmanage/advertPlacementAudit/audit/${id}`
                });
                break;
        }
    }

    render() {
        const { advertPlacementList } = this.props;
        const { schedule } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='档期'
                                placeholder='请输入'
                                value={schedule}
                                bindThis={this}
                                bindName='schedule'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <AdvertTable renderData={advertPlacementList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getAdvertPlacementList: PropTypes.func,
    advertPlacementList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    advertPlacementList: state.advertPlacement.advertPlacementList,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getAdvertPlacementList })(Main);