/**
 * 临时税务登记证管理
 */
import React, { Fragment } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import Personal from './Personal';
import Merchant from './Merchant';

class Main extends React.Component {
    state = {
        tab: [
            { id: 1, label: '个人' },
            { id: 2, label: '商户' },
        ],
        curTabID: 1,  // 默认显示的tab，1：直播间列表 2：视频列表 3：社区列表
    }

    componentDidMount() {
        const { history } = this.props;
        if (history.location.state && history.location.state.tabID) {
            debugger;
            this.setState({ curTabID: history.location.state.tabID }); // 如果路由过来的state参数带tabID值，则渲染该tabID值下的内容
        }
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        switch (this.state.curTabID) {
            case 1:
                return <Personal curTabID={this.state.curTabID} history={this.props.history} />;
            case 2:
                return <Merchant curTabID={this.state.curTabID} history={this.props.history} />;
            case 3:
                return <Personal curTabID={this.state.curTabID} history={this.props.history} />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={`${styles.page} ${styles.flexBoxContainer}`}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
};

export default Main;