/**
 * 区域选择 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, Checkbox, Row, Col } from 'antd';
import styles from './Select.module.scss';

class Select extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        indeterminate: true,
        checkAll: false,
        provinceNames: [],
        provinceIds: [],
        cityIds: [],
        cityNames: []
    }

    componentDidMount() {
        http('/user/area/listProvince', {}, 'POST').then((resole) => {
            this.setState({
                provinces: resole.result
            });
        }).catch(() => {
            console.log('请求错误');
        });
    }

    _provenceChange = (checkedValues) => {
        const { provinceNames, provinces } = this.state;
        const provinceName = [...provinceNames];
        provinces.map(p => {
            checkedValues.map(pId => {
                if (pId == p.id) {
                    provinceName.push(p.areaName);
                }
            });
        });
        this.setState({
            provinceNames: provinceName,
            provinceIds: [...checkedValues],
        });
        http('/merchant/area/listAreaByParentId/' + checkedValues.pop(), {}, 'POST').then((resole) => {
            this.setState({
                cities: [...resole.result],
            });
        }).catch(() => {
            console.log('请求错误');
        });
    };

    _cityChange = (checkedValues) => {
        const { cities, cityIds, provinces, cityNames, provinceNames, provinceIds } = this.state;
        const cityName = [...cityNames];
        let provinceName = [];
        let provinceId = [];
        cities.map(c => {
            checkedValues.map(cId => {
                if (c.id == cId) {
                    cityName.push(c.areaName);
                }
            });
        });
        cities.map(c => {
            provinces.map(p => {
                if (c.parentId == p.id) {
                    provinceName = provinceNames.filter(item => item !== p.areaName);
                    provinceId = provinceIds.filter(item => item !== p.id);
                }
            });
        });
        this.setState({
            cityIds: [...cityIds.concat(checkedValues)],
            cityNames: [...new Set(cityName)],
            provinceNames: [...new Set(provinceName)],
            provinceIds: [...new Set(provinceId)]
        });
    };


    // 点击确认
    _handleOk = () => {
        const { provinceIds, cityIds, provinceNames, cityNames } = this.state;
        const areaIds = [...new Set(provinceIds.concat([...cityIds]))].join(',');
        const areaNames = [...new Set(provinceNames.concat([...cityNames]))].join(',');
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _renderProvince = (data) => {
        return data.map(item => {
            return (
                <Col key={item.id} span={6} style={{ padding: '5px 0 ', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Checkbox value={item.id}>{item.areaName}</Checkbox>
                </Col>
            );
        });
    }

    render() {
        const { visible, confirmLoading, provinces, cities } = this.state;
        return (
            <Modal
                width={660}
                centered
                title='选择区域'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.wrapper}>
                    <div className={styles.label}>请选择区域</div>
                    <div className={styles.firArea}>
                        <p>
                            <span className={styles.sublabel}>省份</span>
                            {/* <Checkbox
                                style={{ float: 'right' }}
                                indeterminate={this.state.indeterminate}
                                onChange={this._checkAllChange}
                                checked={this.state.checkAll}
                            >全选</Checkbox> */}
                        </p>
                        <div className={styles.content}>
                            <Checkbox.Group style={{ width: '100%' }} onChange={(checkedValues) => this._provenceChange(checkedValues)}>
                                <Row>
                                    {
                                        this._renderProvince(provinces)
                                    }
                                </Row>
                            </Checkbox.Group>
                        </div>
                    </div>
                    <div className={styles.secArea}>
                        <p>
                            <span className={styles.sublabel}>城市</span>
                        </p>
                        <div className={styles.content}>
                            <Checkbox.Group style={{ width: '100%' }} onChange={(checkedValues) => this._cityChange(checkedValues)}>
                                <Row>
                                    {
                                        this._renderProvince(cities)
                                    }
                                </Row>
                            </Checkbox.Group>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}


Select.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    areeChange: PropTypes.func
};

export default Select;