/**
 * 审核
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message, Radio } from 'antd';
import styles from './Delivery.module.scss';
import { XTextArea, XInfoLabel } from '@/components/xqxc_ui';
import { regFenToYuan } from '@/util/money';
import BigNumber from 'bignumber.js';

const Delivery = ({
    modeParams: {
        id = 0,
        exchangeShares = '',
        proportionRmb = 0,
        closingPrice = 0,
        premiumPrice = 0,
        currClosingPrice = 0,
        procedureRatio = 0,
        procedureFee = 0
    },
    closeMode
}) => {

    const [loading, setLoading] = useState(false);
    const [auditState, setAuditState] = useState(true);
    const [remark, setRemark] = useState('');

    //确定取消
    const onSumbit = () => {
        setLoading(true);
        http('/integralExchangeVoucher/audit', { id, voucherStatus: auditState, remark }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            }).catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    return (
        <Modal
            width={700}
            centered
            title='系统审核'
            visible={true}
            confirmLoading={loading}
            onOk={onSumbit}
            onCancel={() => closeMode(false)}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'>
            <div className={styles.container}>
                <div className={styles.info}>
                    <div>
                        <XInfoLabel
                            style={{ marginBottom: 20 }}
                            label='兑换凭证：'
                            value={id}
                        />
                        <XInfoLabel
                            style={{ marginBottom: 20 }}
                            label='兑换收盘价（元）：'
                            value={regFenToYuan(closingPrice)}
                        />
                        <XInfoLabel
                            style={{ marginBottom: 20 }}
                            label='兑换比例：'
                            value={`${currClosingPrice / 100}:${proportionRmb}`}
                        />
                        <XInfoLabel
                            style={{ marginBottom: 20 }}
                            label='兑换股数：'
                            value={exchangeShares}
                        />
                    </div>
                    <div>
                        <XInfoLabel
                            style={{ marginBottom: 20 }}
                            label='股权溢价收益：'
                            value={regFenToYuan(premiumPrice)}
                        />
                        <XInfoLabel
                            style={{ marginBottom: 20 }}
                            label='手续费比例：'
                            value={`${new BigNumber(procedureRatio).dividedBy(100).toFixed(2)} %`}
                        />
                        <XInfoLabel
                            style={{ marginBottom: 20 }}
                            label='手续费：'
                            value={`${new BigNumber(procedureFee).dividedBy(100).toFixed(2)} (元)`}
                        />
                    </div>
                </div>
                <div>
                    <div style={{ marginBottom: 15 }}>
                        <label style={{ marginRight: 15 }}>审核意见</label>
                        <Radio.Group value={auditState} onChange={(e) => setAuditState(e.target.value)}>
                            <Radio value='AUDIT_SUCCESS'>通过</Radio>
                            <Radio value='AUDIT_FAIL'>不通过</Radio>
                        </Radio.Group>
                    </div>
                    <XTextArea
                        inputStyle={{ width: 320 }}
                        label='备注：'
                        bindThis={setRemark}
                        maxLength={100}
                        bindName='remark'
                        value={remark}
                    />
                </div>
            </div>
        </Modal>
    );
};
Delivery.propTypes = {
    modeParams: PropTypes.object.isRequired,
    closeMode: PropTypes.func.isRequired
};
export default Delivery;