import React from 'react';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import StickyType from '../stickytype';
import StickyList from '../stickylist';

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '贴纸分类' },
            { id: 2, label: '贴纸列表' },
        ],
        curTabID: 1
    }
    isReload = new Set();

    componentDidMount() {
        this.isReload.add(1);
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
        setTimeout(() => this.isReload.add(tabID), 200);
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        const { curTabID } = this.state;
        const isRequest = !this.isReload.has(curTabID);
        switch (curTabID) {
            case 1:
                return <StickyType curTabID={curTabID} isRequestPageData={isRequest} initConditions={this.state.StickyTypeConditions} updateConditionCallBack={(StickyTypeConditions) => this.setState({ StickyTypeConditions })} />;
            case 2:
                return <StickyList curTabID={curTabID} isRequestPageData={isRequest} initConditions={this.state.StickyListConditions} updateConditionCallBack={(StickyListConditions) => this.setState({ StickyListConditions })} />;
            default:
                return <StickyType curTabID={curTabID} isRequestPageData={isRequest} initConditions={this.state.StickyTypeConditions} updateConditionCallBack={(StickyTypeConditions) => this.setState({ StickyTypeConditions })} />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={`${styles.page} ${styles.flexBoxContainer}`}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

export default Main;