import { combineReducers } from 'redux';
import {
    saleList,
    saleStatus,
    saleType,
    saleOnlyMoneyDetail,
    saleGoodsandmoneyDetail,
    saleChangeGoodsDetail,
    processDetail
} from './reducer';

export default combineReducers({
    saleList,
    saleStatus,
    saleType,
    saleOnlyMoneyDetail,
    saleGoodsandmoneyDetail,
    saleChangeGoodsDetail,
    processDetail,
});