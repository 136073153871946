import * as Audit from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

const initDetail = {
    invoices:[],
    auditLogs:[],
    invoiceBuyer:{},
    invoiceSeller:{},
    invoiceDelivery:{},
    goodsList:[]
};

const payInfo={

};

export const companyInvoiceList = (state = initData, action) => {
    switch (action.type) {
        case Audit.SET_COMPANYINVOICE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

export const companyInvoiceStatus = (state = [], action) => {
    switch (action.type) {
        case Audit.SET_COMPANYINVOICE_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};

export const companyInvoiceDetail = (state = initDetail, action) => {
    switch (action.type) {
        case Audit.SET_COMPANYINVOICE_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

export const companyInvoiceName = (state = [], action) => {
    switch (action.type) {
        case Audit.SET_COMPANYINVOICE_NAME:
            return action.payload.result;
        default:
            return state;
    }
};

export const companyInvoicePay = (state = payInfo, action) => {
    switch (action.type) {
        case Audit.SET_COMPANYINVOICE_PAY:
            return action.payload;
        default:
            return state;
    }
};