//异步获取数据
export const GET_BRANDAUDIT_LIST = 'BRANDAUDIT/GET_BRANDAUDIT_LIST'; 
export const GET_BRANDAUDIT_USERNAME = 'BRANDAUDIT/GET_BRANDAUDIT_USERNAME'; 
export const GET_BRANDAUDIT_STATE = 'BRANDAUDIT/GET_BRANDAUDIT_STATE'; 
export const GET_BRANDAUDIT_DETAIL = 'BRANDAUDIT/GET_BRANDAUDIT_DETAIL'; 
export const GET_BRANDAUDIT_BRAND = 'BRANDAUDIT/GET_BRANDAUDIT_BRAND'; 

// //保存数据到store.state
export const SET_BRANDAUDIT_LIST = 'BRANDAUDIT/SET_BRANDAUDIT_LIST';
export const SET_BRANDAUDIT_USERNAME = 'BRANDAUDIT/SET_BRANDAUDIT_USERNAME'; 
export const SET_BRANDAUDIT_STATE = 'BRANDAUDIT/SET_BRANDAUDIT_STATE'; 
export const SET_BRANDAUDIT_DETAIL = 'BRANDAUDIT/SET_BRANDAUDIT_DETAIL'; 
export const SET_BRANDAUDIT_BRAND = 'BRANDAUDIT/SET_BRANDAUDIT_BRAND'; 
