import { createModel } from '@/store/tools';

const model = {
    namespace: 'stockDividends',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getStateList': T('getStateList'),
            'getStockStateList': T('getStockStateList'),
            'getStockDividendsList': T('getStockDividendsList'),
            // 'getStockDividendsDetail': T('getStockDividendsDetail'),
        },
        actions: {
            'getStateList': A('getStateList'),
            'getStockStateList': A('getStockStateList'),
            'getStockDividendsList': A('getStockDividendsList'),
            // 'getStockDividendsDetail': A('getStockDividendsDetail'),
        },
        sagas: {
            'getStateList': S('getStateList', '/enum/XiudouEarningsStateEnum'),
            'getStockStateList': S('getStockStateList', '/enum/StateEnum'),
            'getStockDividendsList': S('getStockDividendsList', '/xiudou/earnings/list'),
            // 'getStockDividendsDetail': S('getStockDividendsDetail', '/xiudou/earnings/details'),
        },
        reducers: {
            'stateList': R('getStateList', []),
            'stockStateList': R('getStockStateList', []),
            'stockDividendsList': R('getStockDividendsList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            // 'stockDividendsDetail': R('getStockDividendsDetail', {}),
        }
    })
};
export default createModel(model);