import { combineReducers } from 'redux';
import {
    accountList,
    serviceList,
    merchantList,
    regionalCompanyList,
    operationCenterList,
    platFormList,
    costDetaiList,
    costNameList,
    costDetaiLists,
    orderCostDetaiList,
    serviceOrderList,
    serviceManageFeeList,
    activeCompanyState,
} from './reducer';

export default combineReducers({
    accountList,
    serviceList,
    merchantList,
    regionalCompanyList,
    operationCenterList,
    platFormList,
    costDetaiList,
    costNameList,
    costDetaiLists,
    orderCostDetaiList,
    serviceOrderList,
    serviceManageFeeList,
    activeCompanyState,
});