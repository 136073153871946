import { combineReducers } from 'redux';
import model from './hotelReconciliationModel';
import {
	merchantSelectSource,
	merchantSelectSourceV1,
	merchantReconciliationTableSource,
	stateSelectSource,
	detail,
	detailTableSource,
	merchantDetail,
	merchantTradeDetail
} from './reducer';

export default combineReducers({
	merchantSelectSource,
	merchantSelectSourceV1,
	merchantReconciliationTableSource,
	stateSelectSource,
	detail,
	detailTableSource,
	merchantDetail,
	merchantTradeDetail,
	...model.reducers
});