import * as Order from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

//免单列表数据
export const freeOrderList = (state = initData, action) => {
    switch (action.type) {
        case Order.SET_FREEORDER_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
//免单自动打款列表数据
export const autoFreeOrderList = (state = initData, action) => {
    switch (action.type) {
        case Order.SET_AUTOFREEORDER_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
//免单列表数据
export const freeOrderStatus = (state = [], action) => {
    switch (action.type) {
        case Order.SET_FREEORDER_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};
//免单自动打款状态
export const autoFreeOrderStatus = (state = [], action) => {
    switch (action.type) {
        case Order.SET_AUTOFREEORDER_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};
//免单商品列表数据
export const freeProductList = (state = initData, action) => {
    switch (action.type) {
        case Order.SET_FREEPRODUCT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
//免单看板列表数据
export const freeOrderKanBanList = (state = initData, action) => {
    switch (action.type) {
        case Order.SET_FREEORDERKANBAN_LIST:
            return action.payload.result;
        case 'SAGA_ERROR':
            return initData;
        default:
            return state;
    }
};