import { createModel } from '@/store/tools';

const model = {
    namespace: 'stockearOrder',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getMerchantList': T('getMerchantList'),
            'getStockTableList': T('getStockTableList'),
            'getStockState': T('getStockState'),
            'getUserAccountType': T('getUserAccountType'),
        },
        actions: {
            'getMerchantList': A('getMerchantList'), // 商户
            'getStockTableList': A('getStockTableList'), // 股票收益列表数据
            'getStockState': A('getStockState'), // 状态
            'getUserAccountType': A('getUserAccountType'), // 用户类型
        },
        sagas: {
            'getMerchantList': S('getMerchantList', '/merchant/info/listMerchantIdAndName'),
            'getStockTableList': S('getStockTableList', '/boss/xiudou/order/listXiudouOrder'),
            'getStockState': S('getStockState', '/enum/StockOrderStatusEnum'),
            'getUserAccountType': S('getUserAccountType', '/enum/AccTypeEnum'),
        },
        reducers: {
            'merchantList': R('getMerchantList', { merchantIdAndNames: [] }),
            'stockTableList': R('getStockTableList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'stockState': R('getStockState', []),
            'acountType': R('getUserAccountType', []),
        }
    })
};
export default createModel(model);