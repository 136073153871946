import React from 'react';
import styles from './AdvertTable.module.scss';
import { XTableSelectionCount, XPagination } from '@/components/xqxc_ui';
import { Table, Badge } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';

class AdvertTable extends React.Component {
    state = {
        account: '',
        selectedRowKeys: [],
    }

    _takeColumns = () => ([
        {
            title: '开票单号',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '商户',
            dataIndex: 'companyName',
            key: 'companyName',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '联系人',
            dataIndex: 'contactName',
            key: 'contactName',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '手机号码',
            dataIndex: 'contactTel',
            key: 'contactTel',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '开票金额(元)',
            dataIndex: 'settleAmount',
            key: 'settleAmount',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '状态',
            dataIndex: 'statusDescript',
            key: 'statusDescript',
            width: '10%',
            align: 'center',
            render: (text, item) => (this._renderStatusCell(text, item.status))
        },
        {
            title: '新建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '寄送快递单号',
            dataIndex: 'expressNumber',
            key: 'expressNumber',
            width: '15%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: '15%',
            align: 'center',
            render: (text, item) => this._renderActionCell(item.id, item)
        }
    ]);
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderStatusCell = (text, status) => {
        const { settlementStatus } = this.props;
        // const statusArr = ['INIT','FIRST_CONFIRM_SUCCESS','FIRST_CONFIRM_FAIL','SECOND_CONFIRM_SUCCESS','SECOND_CONFIRM_FAIL'];
        var statusBadge = ['default', 'processing', 'error', 'success', 'error'];
        if (settlementStatus.findIndex(i => i.code === status) !== -1) {
            var index = settlementStatus.findIndex(i => i.code === status);
            return (
                <Badge status={statusBadge[index]} text={text} />
            );
        } else {
            return null;
        }
    }
    _renderActionCell = (key, item) => {
        // status 0 未开始 1 审核中 2 审核成功 3 审核失败
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'detail', item)}>详情</div>
                { item.delivery === false && <div className={styles.item} onClick={() => tableAction(key, 'send', item)}>寄送发票</div>}
                { (item.status !== 'FIRST_CONFIRM_SUCCESS' && item.status !== 'SECOND_CONFIRM_SUCCESS') && <div className={styles.item} onClick={() => tableAction(key, 'edit', item)}>编辑</div>}
                { (item.status !== 'FIRST_CONFIRM_SUCCESS' && item.status !== 'SECOND_CONFIRM_SUCCESS') && <div className={styles.item} onClick={() => tableAction(key, 'delete', item)}>删除</div>}
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    onChange = (pagination, filters, sorter) => {
        console.log('params', pagination, filters, sorter);
    };
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }
    render() {
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    // onChange={this.onChange}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    settlementStatus: PropTypes.array,
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default AdvertTable;