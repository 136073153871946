import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { addOneBreadcrumbPath, keepThirdNavBreadcrumb } from '@/reducers/home/actions';
import { XOKButton, XCancelButton, XSelect, showConfirm } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import TableBox from './components/TableBox';
import { SearchBox, SearchItem } from '@/components/Layout';
import { Switch, message, Radio } from 'antd';
import Delete from './modal/Delete';
import ChooseModal from '../goodsAudit/modal/ChooseModal';
import http from '@/assets/api/http';
import SetFee from './modal/SetFee';
import Operatelog from './modal/operatelog';
import model from '@/reducers/systemmanage/merchantpermission/model';

const selectEnum = [{ code: 1, value: '开启' }, { code: 0, value: '关闭' }];
class Invitation extends Component {

    static propTypes = {
        proceedsData: PropTypes.object,
    }

    static defaultProps = {
        merchantListData: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
        merchants: { merchantIdAndNames: [] }
    }

    state = {
        isOpen: '1',
        renderMode: '',
        modeParams: null,
        telephone: '',
        userItem: {},
        orderNum: '',
        batchKeys: [],
        shlxItem: {},
        sdjsItem: {},
        pfspmshItem: {},
        hdspmshItem: {},
        shmsfwfItem: {},
    }

    //props校验
    static propTypes = {
        history: PropTypes.object,//路由对象
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepThirdNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getMerchantListData: PropTypes.func,//获取白名单列表
        merchantListData: PropTypes.object,//白名单列表
        getMerchants: PropTypes.func, // 获取商户下拉列
        merchants: PropTypes.object, // 商户下拉列
        getMerchantTypes: PropTypes.func,//获取商户类型枚举
        merchantTypes: PropTypes.array,//商户类型枚举
    }

    _topReq = () => {
        http('/admin/param/getParamValueByCode/IS_OPEN_WHOLESALE_GOODS_REVIEW', {}, 'POST')//是否关闭批发商品免审核
            .then((res = {}) => {
                this.setState({ isOpen: res.result });
            }).catch((reject = {}) => {
                message.error(reject.message);
            });
    }

    //挂载后
    componentDidMount() {
        const { getMerchants, keepThirdNavBreadcrumb, getMerchantTypes } = this.props;
        this._topReq();
        getMerchants();
        getMerchantTypes();
        keepThirdNavBreadcrumb(); // 面包屑导航保留为三级
    }

    // 查询
    _searchHandle = (useCache) => {
        const { shlxItem, sdjsItem, pfspmshItem, hdspmshItem, shmsfwfItem, pageNum, pageSize, userItem } = this.state;
        this.searchCache = {
            merchantType: shlxItem.code,
            settleManualState: sdjsItem.code,
            wholesaleGoodsAuditState: pfspmshItem.code,
            activeGoodsAuditState: hdspmshItem.code,
            relieveFeeState: shmsfwfItem.code,
            merchantId: userItem.merchantId,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getMerchantListData(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            userItem: {},
            shlxItem: {},
            sdjsItem: {},
            pfspmshItem: {},
            hdspmshItem: {},
            shmsfwfItem: {},
        }, () => KeepAlive.saveResetFunc(this._searchHandle)); // 【第4步：将是否调用重置交给KeepAlive控制】
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => this._searchHandle('useCache'));
    }

    //收益明细
    _tableOperate = (id, type, params) => {
        const reqPublic = (code, value) => {
            let firTip = '确认禁用权限吗？';
            let secTip = '';
            if (id == 1) {
                firTip = '确认启用权限吗？';
                secTip = <span style={{ color: '#f00' }}>商户名称：{params.merchantName}启用（{value}）</span>;
            }
            showConfirm(firTip, secTip, () => {
                this._request('/merchant/merchantSetting/editMerchantSetting', {
                    itemList: [
                        {
                            settingItem: code,
                            settingValue: id
                        }
                    ],
                    merchantId: params.merchantId
                });
            });
        };
        const req = (code) => {
            if (id == 1) {
                this.setState({ renderMode: 'feeInfo', modeParams: { merchantId: params.merchantId } });
            } else {
                showConfirm('确认禁用权限吗？', '', () => {
                    this._request('/merchant/merchantSetting/editMerchantSetting', {
                        itemList: [
                            {
                                settingItem: code,
                                settingValue: '0'
                            }
                        ],
                        merchantId: params.merchantId
                    });
                });
            }
        };
        switch (type) {
            case 'active':
                // 任务2070，酒店商户暂时不开放开关
                if (params.merchantType == 'HOTEL') {
                    message.warning('当前商户类型暂未开通此功能，敬请期待');
                    return;
                }
                reqPublic('ACTIVE_GOODS_AUDIT_STATE', '活动商品免审核');
                break;
            case 'balance':
                // 任务2070，酒店商户暂时不开放开关
                if (params.merchantType == 'HOTEL') {
                    message.warning('当前商户类型暂未开通此功能，敬请期待');
                    return;
                }
                reqPublic('SETTLE_MANUAL_STATE', '手动结算');
                break;
            case 'batch':
                // 任务2070，酒店商户暂时不开放开关
                if (params.merchantType == 'HOTEL') {
                    message.warning('当前商户类型暂未开通此功能，敬请期待');
                    return;
                }
                reqPublic('WHOLESALE_GOODS_AUDIT_STATE', '批发商品免审核');
                break;
            case 'fee':
                req('RELIEVE_FEE_STATE');
                break;
            case 'feeInfo':
                this.setState({ renderMode: 'feeInfo', modeParams: { merchantId: params.merchantId } });
                break;
            case 'delete':
                this.setState({ renderMode: 'delete', modeParams: { merchantIds: [params.merchantId], merchantName: params.merchantName } });
                break;
            case 'log':
                this.setState({ renderMode: 'log', modeParams: { merchantId: params.merchantId } });
                break;
            case 'batchKeys':
                this.setState({ batchKeys: params });
                break;
        }
    }

    // 打开弹窗
    _renderMode = () => {
        const { renderMode, modeParams } = this.state;
        switch (renderMode) {
            case 'delete'://删除
                return <Delete modeParams={modeParams} closeMode={this._closeMode} />;
            case 'chose'://选择商户
                return <ChooseModal title='选择商户' visible={true} closeMode={this._closeMode} />;
            case 'feeInfo'://费用信息
                return <SetFee modeParams={modeParams} closeMode={this._closeMode} />;
            case 'log'://操作记录
                return <Operatelog modeParams={modeParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ renderMode: '', batchKeys: [] }, () => {
            update && this._searchHandle('useCache');
        });
    }

    //请求
    _request = (url, data, reqtop) => {
        http(url, data, 'POST')
            .then(res => {
                message.success(res.message);
                this._searchHandle('useCache');
                reqtop && this._topReq();
            })
            .catch(err => {
                message.error(err.message);
            });
    }

    //删除
    _batchDeleta = () => {
        const { batchKeys } = this.state;
        if (!batchKeys.length) {
            message.warning('请勾选商户');
            return;
        }
        this.setState({ renderMode: 'delete', modeParams: { merchantIds: batchKeys } });
    }

    //渲染组件
    render() {
        const { merchantListData, merchants: { merchantIdAndNames = [] } = {}, merchantTypes = [] } = this.props;
        const { userItem, isOpen, batchKeys, shlxItem, sdjsItem, pfspmshItem, hdspmshItem, shmsfwfItem } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.title}>基础设置</div>
                    <div className={styles.switch}>
                        <p className={styles.left}>是否启动批发商品免审核</p>
                        <div className={styles.middle}>
                            <Radio.Group
                                onChange={e => {
                                    showConfirm('重要信息！关闭则部分商户批发商品免审核，启动则全部商户批发商品免审核', '请再次确认后完成操作。', () => {
                                        this._request('/admin/param/batchUpdateParam',
                                            [{
                                                paramValue: e.target.value,
                                                paramCode: 'IS_OPEN_WHOLESALE_GOODS_REVIEW'
                                            }], 'reqtop');
                                    });
                                }}
                                value={isOpen}>
                                <Radio value='0'>是</Radio>
                                <Radio value='1'>否</Radio>
                            </Radio.Group>
                        </div>
                        <p className={styles.right}>控制商户批发商品免审核（如启动则全部商户批发商品免审核）</p>
                    </div>
                    <div className={styles.title}>商户权限设置</div>
                    <SearchBox>
                        <SearchItem>
                            <XOKButton
                                style={{ width: 100, marginRight: 20 }}
                                label='选择商户'
                                onClick={() => this.setState({
                                    renderMode: 'chose',
                                })}
                            />
                            <XOKButton
                                style={{ width: 100, marginRight: 20 }}
                                label='批量删除'
                                onClick={this._batchDeleta}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户名称'
                                placeholder='请选择'
                                renderData={merchantIdAndNames}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                bindThis={this}
                                bindName='userItem'
                                value={userItem.merchantName}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户类型'
                                placeholder='请选择'
                                renderData={merchantTypes}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='shlxItem'
                                value={shlxItem.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='手动结算'
                                placeholder='请选择'
                                renderData={selectEnum}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='sdjsItem'
                                value={sdjsItem.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='批发商品免审核'
                                placeholder='请选择'
                                renderData={selectEnum}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='pfspmshItem'
                                value={pfspmshItem.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='活动商品免审核'
                                placeholder='请选择'
                                renderData={selectEnum}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='hdspmshItem'
                                value={hdspmshItem.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户免收服务费'
                                placeholder='请选择'
                                renderData={selectEnum}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='shmsfwfItem'
                                value={shmsfwfItem.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableBox
                        batchKeys={batchKeys}
                        tableOperate={this._tableOperate}
                        renderData={merchantListData}
                        paginationChange={this._paginationChange}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive >
        );
    }
}
//暴露组件
export default connect(state => ({
    merchantListData: state.merchantpermission.merchantListData,
    merchants: state.merchantpermission.merchants,
    merchantTypes: state.merchantpermission.merchantTypes,
}), {
    addOneBreadcrumbPath,
    keepThirdNavBreadcrumb,
    ...model.actions
})(Invitation);
