/**
 *  权限管理 - 员工列表
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const Staffmanage = ({ history }) => {
    return <Main history={history} />;
};

Staffmanage.propTypes = {
    history: PropTypes.object
};

export default Staffmanage;