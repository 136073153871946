// 会员等级设置列表
export const GET_MEMBERGRADE_LIST = 'SYSTEMMANAGE/GET_MEMBERGRADE_LIST';
export const SET_MEMBERGRADE_LIST = 'SYSTEMMANAGE/SET_MEMBERGRADE_LIST';

// 会员等级设置详情
export const GET_MEMBERGRADE_DETAIL= 'SYSTEMMANAGE/GET_MEMBERGRADE_DETAIL';
export const SET_MEMBERGRADE_DETAIL = 'SYSTEMMANAGE/SET_MEMBERGRADE_DETAIL';

//会员等级设置优惠券下拉
export const GET_MEMBERGRADE_COUPONSELE= 'SYSTEMMANAGE/GET_MEMBERGRADE_COUPONSELE';
export const SET_MEMBERGRADE_COUPONSELE= 'SYSTEMMANAGE/SET_MEMBERGRADE_COUPONSELE';

//会员等级设置单选框
export const GET_MEMBERGRADE_RADIO= 'SYSTEMMANAGE/GET_MEMBERGRADE_RADIO';
export const SET_MEMBERGRADE_RADIO= 'SYSTEMMANAGE/SET_MEMBERGRADE_RADIO';