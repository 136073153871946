import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination, } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

//模拟表格数据   
const tableDataSource = [
    {
        c1: '每日说APP',
        c2: '商城实物商品购买支付',
        c3: '【通联：微信小程序、支付宝小程序】【银联：微信小程序、支付宝小程序】',
    },
    {
        c1: '每日说APP',
        c2: '同城商品购买支付',
        c3: '【通联：微信小程序、支付宝小程序】【银联：微信小程序、支付宝小程序】',
    },
    {
        c1: '每日说APP',
        c2: '酒店支付',
        c3: '【通联：微信小程序、支付宝小程序】【银联：微信小程序、支付宝小程序】',
    },
    {
        c1: '每日说APP',
        c2: '人气值商城兑换商品支付',
        c3: '【原生微信支付】',
    },
    {
        c1: '每日说APP',
        c2: '申请开通主播',
        c3: '【通联：微信小程序、支付宝小程序】【银联：微信小程序、支付宝小程序】',
    },
    {
        c1: '每日说APP',
        c2: '购买魅力值',
        c3: '【原生微信】【原生支付宝】',
    },
    {
        c1: '服务版',
        c2: '分销自购支付',
        c3: '【通联：微信小程序、支付宝小程序】【银联：微信小程序、支付宝小程序】',
    },
    {
        c1: '服务版',
        c2: '集采自购支付',
        c3: '【通联：微信小程序、支付宝小程序】【银联：微信小程序、支付宝小程序】',
    },
    {
        c1: 'H5',
        c2: 'H5商城商品购买支付',
        c3: '【通联：H5收银台】【银联：支付宝、云闪付】',
    },
    {
        c1: 'H5',
        c2: 'H5申请成为智仓店主',
        c3: '【原生农行银联】',
    },
    {
        c1: 'H5',
        c2: 'H5申请成为企业主播',
        c3: '【原生农行银联】',
    },
    {
        c1: 'H5',
        c2: 'H5申请成为服务商',
        c3: '【原生农行银联】【线下转账】',
    },
    {
        c1: '商家版',
        c2: '商家配送单支付',
        c3: '【通联：微信小程序、支付宝小程序】【银联：微信小程序、支付宝小程序】',
    },
    {
        c1: '智仓优选小程序',
        c2: '智仓店主自购支付',
        c3: '【通联：微信小程序】【银联：微信小程序】',
    },
    {
        c1: '每日说优品小程序',
        c2: '每日说用户商品购买支付',
        c3: '【通联：微信小程序】【银联：微信小程序】',
    },
    {
        c1: '总控后台',
        c2: '会员收益提现',
        c3: '【通联：代付】',
    },
    {
        c1: '总控后台',
        c2: '会员魅力值提现',
        c3: '【银联：薪人】',
    },
    {
        c1: '总控后台',
        c2: '服务商收益提现',
        c3: '【通联：代付】',
    },
    {
        c1: '总控后台',
        c2: '区域公司收益提现',
        c3: '【通联：代付】',
    },
    {
        c1: '总控后台',
        c2: '运营中心收益提现',
        c3: '【通联：代付】',
    },
];

const columns = () => [
    {
        title: '端口',
        dataIndex: 'c1',
        key: 'c1',
        width: '33.3%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '业务场景',
        dataIndex: 'c2',
        key: 'c2',
        width: '33.3%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '支付通道',
        dataIndex: 'c3',
        key: 'c3',
        width: '33.3%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    }
];


class SceneTable extends React.Component {

    state = {

    }

    render() {
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={columns()}
                    dataSource={tableDataSource}
                    pagination={false}
                    scroll={{ y: true }}
                />
            </div>
        );
    }
}

export default SceneTable;