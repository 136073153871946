/**
 * [支付凭证审核列表]
 * @author: Fu Xiaochun
 * @date: 2023-05-22 
 */

import React, { useState, useEffect, useRef } from 'react';
import styles from './Client.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, message, Modal, Button } from 'antd';
import { XInput, XOKButton, XCancelButton, XSelect, XRangePicker } from '@/components/xqxc_ui';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import TableData from './components/ClientTableData';
import http from '@/assets/api/http';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { useHistory } from 'react-router-dom';

function Partner(props) {

    const history = useHistory();

    const [result, setResult] = useState({ dataList: [] });
    const [aliveInit, setAliveInit] = useState(false);

    const [orderId, setOrderId] = useState(); // 订单编号
    const [storeAccount, setStoreAccount] = useState();  // 买家
    const [transferAccount, setTransferAccount] = useState();  // 转账账号
    const [applyTime, setApplyTime] = useState([]);  // 审核时间
    const [auditStatus, setAuditStatus] = useState({});  // 审核状态
    const [auditStatusEnum, setAuditStatusEnum] = useState([]); // 审核状态枚举

    const [orderType, setOrderType] = useState({}); // 订单类型
    const [orderTypeEnum, setOrderTypeEnum] = useState([]); // 订单类型枚举

    const [resetCode, setResetCode] = useState(0);
    const page = useRef({ pageNum: autoPageNum, pageSize: autopageSize });


    useEffect(() => {
        props.keepSecondNavBreadcrumb();
        getAuditStatusEnum();
        getOrderTypeEnum();
    }, []);

    useEffect(() => {
        aliveInit && onSearch();
    }, [aliveInit]);

    // 重置后请求
    useEffect(() => {
        if (resetCode > 0) {
            onSearch();
        }
    }, [resetCode]);

    const getAuditStatusEnum = () => {
        http('/enum/AuditStatusEnum').then(res => {
            setAuditStatusEnum(res.result);
        }).catch(err => {
            message.error(err.message);
        });
    };

    const getOrderTypeEnum = () => {
        http('/earningsServicePayVoucher/payVoucherOrderType').then(res => {
            setOrderTypeEnum(res.result);
        }).catch(err => {
            message.error(err.message);
        });
    };

    const initData = (data) => {
        setStoreAccount(data.storeAccount);
        setTransferAccount(data.transferAccount);
        setApplyTime(data.applyTime || []);
        setAuditStatus({ code: data.auditStatus ? data.auditStatus.code : undefined, value: data.auditStatus ? data.auditStatus.value : undefined });
        page.current = { pageNum: data.pageNum, pageSize: data.pageSize };
    };


    // 查询
    const onSearch = (usecache) => {
        if (usecache !== 'usecache') {
            page.current = { pageNum: autoPageNum, pageSize: autopageSize };
        }
        let { pageNum, pageSize } = page.current;
        let params = {
            orderId: orderId,
            mobile: storeAccount,
            payVoucherNo: transferAccount,
            auditStartDate: applyTime[0] ? applyTime[0] + ' 00:00:00' : undefined,
            auditEndDate: applyTime[1] ? applyTime[1] + ' 23:59:59' : undefined,
            auditStatus: auditStatus.code,
            businessType: orderType.code,
            pageNum,
            pageSize,
            providerRole: 'USER'
        };

        http('/earningsServicePayVoucher/list', params).then(res => {
            setResult(res.result);
        }).catch(err => {
            message.error(err.message);
        });

        KeepAlive.saveCache({ storeAccount, transferAccount, applyTime, auditStatus, pageNum, pageSize });
    };

    // 重置
    const onReset = () => {
        setOrderId();
        setStoreAccount();
        setTransferAccount();
        setApplyTime([]);
        setAuditStatus({});
        setOrderType({});
        setResetCode(resetCode + 1);
        page.current = { pageNum: autoPageNum, pageSize: autopageSize };
    };

    const onPaginationChange = (size, num) => {
        page.current = { pageSize: size, pageNum: num };
        onSearch('usecache');
    };

    const _tableAction = (id, type, data) => {
        switch (type) {
            case 'detail':
                props.addOneBreadcrumbPath({
                    title: '详情',
                    path: '/home/auditmanage/payorder/clientDetail/' + data.id
                });
                history.push('/home/auditmanage/payorder/clientDetail/' + data.id);
                break;
            case 'audit':
                props.addOneBreadcrumbPath({
                    title: '审核',
                    path: '/home/auditmanage/payorder/clientAudit/' + data.id
                });
                history.push('/home/auditmanage/payorder/clientAudit/' + data.id);
                break;
            default:
            // nothing to do
        }
    };

    return (
        <KeepAlive id='1' render={(state) => {
            if (!state.aliveInit) {
                initData(state);
                setAliveInit(true);
            }
        }}>
            <div className={styles.flexBoxContainer}>
                <SearchBox>
                    <SearchItem>
                        <XInput
                            style={{ marginRight: '30px' }}
                            label='订单编号'
                            placeholder='请输入订单编号'
                            bindName='orderId'
                            value={orderId}
                            bindThis={setOrderId}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            style={{ marginRight: '30px' }}
                            label='买家'
                            placeholder='请输入买家'
                            bindName='storeAccount'
                            value={storeAccount}
                            bindThis={setStoreAccount}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            style={{ marginRight: '30px' }}
                            label='转账账号'
                            placeholder='请输入转账账号'
                            bindName='transferAccount'
                            value={transferAccount}
                            bindThis={setTransferAccount}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            selectStyle={{ width: '150px' }}
                            style={{ marginRight: '30px' }}
                            label='订单类型'
                            placeholder='请选择'
                            renderData={orderTypeEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={orderType.value}
                            bindName='orderType'
                            bindThis={setOrderType}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            selectStyle={{ width: '150px' }}
                            style={{ marginRight: '30px' }}
                            label='审核状态'
                            placeholder='请选择'
                            renderData={auditStatusEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={auditStatus.value}
                            bindName='auditStatus'
                            bindThis={setAuditStatus}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XRangePicker
                            inputStyle={{ width: '230px' }}
                            style={{ marginRight: '30px' }}
                            label='审核时间'
                            bindThis={setApplyTime}
                            bindName='applyTime'
                            value={applyTime}
                            format="YYYY-MM-DD"
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            style={{ marginRight: '20px' }}
                            label='查询'
                            onClick={onSearch}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={onReset}
                        />
                    </SearchItem>
                </SearchBox>
                <div className={styles.tableContainer}>
                    <TableData renderData={result} paginationChange={onPaginationChange} tableAction={_tableAction} />
                </div>
            </div>
        </KeepAlive>
    );
}

Partner.propTypes = {
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Partner);