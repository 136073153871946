/**
 * 新建/编辑图文分类
 */
import React from 'react';
import { Modal, } from 'antd';
import { XInput, } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './CategoryModal.module.scss';
import { message } from 'antd';
import http from '@/assets/api/http';

class Category extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        noticeName: this.props.data !== undefined ? this.props.data.className : '',//分类名称
    }

    _handleOk = () => {
        const { noticeName } = this.state;
        const { data } = this.props;
        if (noticeName == '') return message.warn('请输入分类名称。');

        this.setState({ confirmLoading: true }, () => {
            http('/show/platFeedInfo/saveShowClass', {
                id: data && data.id,
                className: noticeName
            }, 'POST')
                .then(() => {
                    data === undefined ? message.success('新建成功。') : message.success('更新成功。');
                    this.setState({
                        visible: false,
                        confirmLoading: false
                    });
                    this.props.closeMode(true);
                }).catch((reject = {}) => {
                    message.error(reject.message);
                    this.setState({ confirmLoading: false });
                });
        });
    }

    _handleCancel = () => {
        this.setState({ visible: false });
        this.props.closeMode();
    }

    //验证-分类名称
    noticeChange = (value) => {
        if (value.length > 12) {
            this.setState({ noticeName: value.substring(0, 12) });
        } else {
            this.setState({ noticeName: value });
        }
    }

    render() {
        const { visible, title, confirmLoading, noticeName } = this.state;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                onOk={this._handleOk}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                okText='确定'
                cancelText='取消'
            >

                <div className={styles.container}>
                    <div className={`${styles.layout} ${styles.dataTop}`}>
                        <XInput
                            inputStyle={{ width: '270px' }}
                            label='分类名称'
                            placeholder='请输入分类名称'
                            value={noticeName}
                            isRequired={true}
                            onChange={this.noticeChange}
                            suffix={noticeName.length < '13' && `(${noticeName.length}/12)`}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

Category.propTypes = {
    title: PropTypes.string,// 弹框标题
    data: PropTypes.object, //数据
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default Category;