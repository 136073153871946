/**
 * 消息中心管理
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as MESSAGE from './actionTypes';

// 未读
export const getMessageCenterUnReadList = function* () {
    yield takeEvery(MESSAGE.GET_MESSAGECENTERUNREAD_LIST, function* requestData(action) {
        try {
            let result = yield http('/user/messageinfomis/listUnReadMessage', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: MESSAGE.SET_MESSAGECENTERUNREAD_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 已读
export const getMessageCenterReadList = function* () {
    yield takeEvery(MESSAGE.GET_MESSAGECENTERREAD_LIST, function* requestData(action) {
        try {
            let result = yield http('/user/messageinfomis/listReadMessage', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: MESSAGE.SET_MESSAGECENTERREAD_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};