import * as Task from './actionTypes';

//任务分类列表
export const getTaskClassifyList = ({ categoryTitle, state, pageNum, pageSize }) => ({
    type: Task.GET_TASKMANAGE_TASKCLASSIFYLIST,
    payload: {
        categoryTitle, state, pageNum, pageSize
    }
});

//任务分类下拉
export const getTaskClassifyStateSele = () => ({
    type: Task.GET_TASKMANAGE_TASKCLASSIFYSTATESELE,
    payload: {}
});