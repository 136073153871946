/**
 *  店铺管理 - 酒店店铺列表
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, } from 'antd';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import ReleaseProhibitModal from '../../modal/ReleaseProhibitModal';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import hotelShopModel from '@/reducers/storemanage/hotelShop/model';


class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            saleState: {}, // 封禁状态
            saleNames: '', // 店铺名称
            salemerchants: '', // 归属商户
        };
    }

    componentDidMount() {
        const { getStateDisable, } = this.props;
        getStateDisable();//封禁状态
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getHotelShopList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 查询触发
    _saleSearchHandle = (useCache) => {
        const { saleNames, saleState, salemerchants, pageSize, pageNum, } = this.state;
        this.searchCache = {
            shopType: 'HOTEL',//酒店店铺(默认)
            shopName: saleNames,//店铺名称  
            merchantName: salemerchants,//归属商户  
            shopState: saleState.code,//封禁状态  
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getHotelShopList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _saleresetHandle = () => {
        this.setState({ saleState: {}, saleNames: '', salemerchants: '' }, () => {
            KeepAlive.saveResetFunc(this._saleSearchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作功能回调
    _actionClickHandle = (id, item, type) => {
        const { updateContent } = this.props;
        switch (type) {
            case 'DELETE': //详情
                updateContent('MER_DELETE', id);
                break;
            case 'BANNED': //封禁 
                updateContent('MER_BANNED', id);
                break;
            case 'LIFTING': //解禁  
                this.setState({
                    currentOpenMode: 'LIFTING',
                    openModeParam: item,
                });
                break;
        }
    }

    // 打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'LIFTING':
                return <ReleaseProhibitModal title='解封' data={this.state.openModeParam} visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getHotelShopList(this.searchCache);// 重新渲染更新列表
        }
    }


    render() {
        const { stateDisable, hotelShopList } = this.props;
        const { saleState, saleNames, salemerchants } = this.state;
        const { pageNum, pageSize, resultTotal, dataList } = hotelShopList;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._saleSearchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.pro_commont}>
                        <XInput
                            inputStyle={{ width: '150px' }}
                            label='店铺名称'
                            placeholder='请输入'
                            value={saleNames}
                            bindThis={this}
                            bindName='saleNames'
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '150px' }}
                            label='归属商户'
                            placeholder='请输入'
                            value={salemerchants}
                            bindThis={this}
                            bindName='salemerchants'
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '150px' }}
                            label='封禁状态'
                            placeholder='请选择'
                            renderData={stateDisable}
                            dataIndex='value'
                            keyIndex='code'
                            value={saleState.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='saleState'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._saleSearchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._saleresetHandle}
                        />
                    </div>
                    <div className={styles.tableContainer}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record) => record.id}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    getStateDisable: PropTypes.func,// 封禁状态Action
    stateDisable: PropTypes.array,
    hotelShopList: PropTypes.object,//店铺列表数据
    getHotelShopList: PropTypes.func, // 店铺列表Action
};

const mapStateToProps = (state) => {
    return {
        hotelShopList: state.hotelShop.hotelShopList, // 表格数据源
        stateDisable: state.hotelShop.stateDisable, // 封禁状态
    };
};

export default connect(mapStateToProps, { ...hotelShopModel.actions })(Main);