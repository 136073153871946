/**
 * 设置商户免收服务费
 */
import React, { useEffect, useState } from 'react';
import { Modal, message, Row, Col, Alert, Radio, Checkbox, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import styles from './SetFee.module.scss';
import http from '@/assets/api/http';
import moment from 'moment';
import { connect } from 'react-redux';
import model from '@/reducers/systemmanage/merchantpermission/model';

const dateFormat = 'YYYY-MM-DD';

const SetFee = ({
    closeMode,
    getMerchantInfo,
    merchantInfo,
    modeParams: { merchantId = '' },
    getEditEnum,
    editEnum
}) => {

    const [loading, setLoading] = useState(false);
    const [relieveFeeState, setRelieveFeeState] = useState('');
    const [enumList, setEnumList] = useState([]);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);


    useEffect(() => {
        getEditEnum();
        getMerchantInfo({ merchantId });
    }, []);

    useEffect(() => {
        setEnumList(editEnum.map(item => {
            let ele = {};
            ele.label = item.value;
            ele.value = item.code;
            return ele;
        }));
    }, [editEnum]);

    useEffect(() => {
        const { relieveFeeState, relieveFeeStarTime, relieveFeeEndTime, relieveFeeItem = '' } = merchantInfo;
        setStartTime(relieveFeeStarTime);
        setEndTime(relieveFeeEndTime);
        setRelieveFeeState(relieveFeeState);
        setCheckedList(relieveFeeItem ? relieveFeeItem.split(',') : []);
        setIndeterminate(relieveFeeItem && relieveFeeItem.split(',').length < enumList.length);
        setCheckAll(relieveFeeItem.split(',').length === enumList.length);
    }, [merchantInfo]);

    const sumbit = () => {

        let itemList = [
            {
                settingItem: 'RELIEVE_FEE_STATE',
                settingValue: relieveFeeState
            }
        ];

        if (relieveFeeState == '1') {
            if (!startTime) {
                message.warning('请选择生效开始时间');
                return;
            }
            if (!endTime) {
                message.warning('请选择生效结束时间');
                return;
            }
            if (startTime > endTime) {
                message.warning('生效开始时间必须小于或者等于生效结束时间');
                return;
            }
            if (!checkedList.length) {
                message.warning('请勾选向商户收取费用类型');
                return;
            }
            itemList = [...itemList, {
                settingItem: 'RELIEVE_FEE_STATE_ITEM',
                settingValue: checkedList.join(',')
            }, {
                settingItem: 'RELIEVE_FEE_STATE_STAR_TIME',
                settingValue: startTime
            }, {
                settingItem: 'RELIEVE_FEE_STATE_END_TIME',
                settingValue: endTime
            }];
        }
        setLoading(true);
        http('/merchant/merchantSetting/editMerchantSetting', {
            itemList,
            merchantId
        }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            }).catch(res => {
                message.error(res.message);
                setLoading(false);
            });
    };

    const onChange = checkeds => {
        setCheckedList(checkeds);
        setIndeterminate(checkeds.length && checkeds.length < enumList.length);
        setCheckAll(checkeds.length === enumList.length);
    };

    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? enumList.map(item => { return item.value; }) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const disabledDate = (current) => {
        return current && current < moment().subtract(1, 'days').endOf('day');
    };

    return (
        <Modal
            width={800}
            centered
            visible={true}
            confirmLoading={loading}
            onOk={sumbit}
            onCancel={() => closeMode(false)}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确定'
        >
            <div className={styles.container}>
                <Row>
                    <Col span={24}>
                        <div>
                            <Alert message='设置商户免收服务费（指定商户指定时段免收指定费用）' banner />
                        </div>
                    </Col>
                    <Col span={3} style={{ marginTop: 20 }}>
                        <div><span style={{ color: '#f00', fontWeight: 'bold' }}>*</span>开启免收：</div>
                    </Col>
                    <Col span={21} style={{ marginTop: 20 }}>
                        <div>
                            <Radio.Group
                                onChange={e => setRelieveFeeState(e.target.value)}
                                value={relieveFeeState}>
                                <Radio value='1'>是</Radio>
                                <Radio value='0'>否</Radio>
                            </Radio.Group>
                        </div>
                    </Col>
                    <Col span={4} style={{ marginTop: 20 }}>
                        <div>{relieveFeeState == '1' && <span style={{ color: '#f00', fontWeight: 'bold' }}>*</span>}向商户收取费用：</div>
                    </Col>
                    <Col span={20} style={{ marginTop: 20 }}>
                        <div>
                            <Checkbox
                                indeterminate={indeterminate}
                                checked={checkAll}
                                onChange={onCheckAllChange}
                            >全选</Checkbox>
                            <Checkbox.Group
                                value={checkedList}
                                onChange={onChange}
                                options={enumList} />
                            <br />
                            <div style={{ marginTop: 10 }}>
                                <p>说明：</p>
                                <p>
                                    1：勾选后平台向商户收取对应费用；不勾选不收取对应费用；</p>
                                <p>
                                    2：平台服务费、技术服务费、秀场服务费和营销服务费在服务清单中设置；对应商品上架是否显示设置项目，按《计费分配比例设置》中平台计费参数为准。</p>
                                <p>
                                    3：需要校验当前设置项，按《计费分配比例设置》中平台计费参数为准。如没有设置，默认显示但控制不可以勾选。
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col span={3} style={{ marginTop: 20 }}>
                        <div>{relieveFeeState == '1' && <span style={{ color: '#f00', fontWeight: 'bold' }}>*</span>}生效日期：</div>
                    </Col>
                    <Col span={21} style={{ marginTop: 20 }}>
                        <div>
                            <DatePicker
                                format={dateFormat}
                                disabledDate={disabledDate}
                                value={startTime ? moment(startTime, dateFormat) : null}
                                onChange={(e, date) => setStartTime(date)}
                            />
                            <DatePicker
                                style={{ marginLeft: 20 }}
                                disabledDate={disabledDate}
                                format={dateFormat}
                                value={endTime ? moment(endTime, dateFormat) : null}
                                onChange={(e, date) => setEndTime(date)}
                            />
                        </div>
                    </Col>
                    <Col offset={3} span={21} >
                        <div>按开始日期00:00:00 到 结束日期23:59:59</div>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};
SetFee.propTypes = {
    modeParams: PropTypes.object, // 弹框入参
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getMerchantInfo: PropTypes.func,
    merchantInfo: PropTypes.object,
    getEditEnum: PropTypes.func,
    editEnum: PropTypes.array,
};
SetFee.defaultProps = {
    merchantInfo: {},
    editEnum: []
};
export default connect(state => ({
    merchantInfo: state.merchantpermission.merchantInfo,
    editEnum: state.merchantpermission.editEnum,
}), {
    ...model.actions
})(SetFee);
