import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker } from '@/components/xqxc_ui';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/auditmanage/businessCategory/model';
import TableContent from './components/TableContent';


const Main = ({
    getProductUsername,
    productUsername,
    goodsAuditProcessEnum,
    getGoodsAuditProcessEnum,
    getCategoryList,
    categoryList,
    addOneBreadcrumbPath,
    history
}) => {

    const [resetCode, setResetCode] = useState(0);
    const [cacheData, setCacheData] = useState({});
    const [aliveInit, setAliveInit] = useState(false);
    const [auditEnum, setAuditEnum] = useState({});
    const [merchantItem, setMerchantItem] = useState({});
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const page = useRef({ pageNum: 1, pageSize: 30 });

    useEffect(() => {
        getProductUsername();
        getGoodsAuditProcessEnum();
    }, []);

    useEffect(() => {
        aliveInit && searchHandle('usecache');
    }, [aliveInit]);

    // 重置后请求
    useEffect(() => {
        if (resetCode > 0) {
            searchHandle();
        }
    }, [resetCode]);

    const initData = (data) => {
        setCacheData({ ...data });
        setMerchantItem(data.merchantItem || {});
        setAuditEnum(data.auditEnum || {});
        setStartTime(data.startTime);
        setEndTime(data.endTime);
        page.current = { pageNum: data.pageNum, pageSize: data.pageSize };
    };

    const searchHandle = (usecache) => {
        if (usecache !== 'usecache') {
            page.current = { pageNum: 1, pageSize: 30 };
        }
        let { pageNum, pageSize } = page.current;
        let searchCache = {
            merchantId: merchantItem.merchantId,
            auditState: auditEnum.code,
            gmtCreateRange: {
                min: startTime,
                max: endTime
            },
            pageNum: usecache == 'usecache' ? pageNum : 1,
            pageSize: usecache == 'usecache' ? pageSize : 30,
        };
        getCategoryList(searchCache);
        KeepAlive.saveCache({ ...cacheData, merchantItem, auditEnum, startTime, endTime, pageNum, pageSize });
    };

    // 重置
    const onReset = () => {
        setCacheData({});
        setMerchantItem({});
        setAuditEnum({});
        setStartTime();
        setEndTime();
        setResetCode(resetCode + 1);
        page.current = { pageNum: 1, pageSize: 30 };
    };

    const tableAction = (id, type, record, req) => {
        addOneBreadcrumbPath({
            title: req,
            path: '/home/auditmanage/businessCategory/applyDetail/'
        });
        history.push({
            pathname: '/home/auditmanage/businessCategory/applyDetail/' + type + '/' + id + '/' + record.merchantId
        });
    };

    const paginationChange = (pageSize,pageNum) => {
        page.current = { pageNum: pageNum, pageSize: pageSize };
        searchHandle('usecache');
    };


    return <KeepAlive id='businessCategory' render={(state) => {
        if (!state.aliveInit) {
            initData(state);
            setAliveInit(true);
        }
    }}>
        <div className={styles.flexBoxContainer}>
            <SearchBox>
                <SearchItem>
                    <XSelect
                        label='商户名称'
                        placeholder='请选择'
                        renderData={productUsername}
                        bindThis={setMerchantItem}
                        bindName='merchantItem'
                        dataIndex='merchantName'
                        keyIndex='merchantId'
                        showSearch={true}
                        value={merchantItem.merchantName}
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        label='审核状态'
                        placeholder='请选择'
                        renderData={goodsAuditProcessEnum}
                        bindThis={setAuditEnum}
                        bindName='auditEnum'
                        dataIndex='value'
                        keyIndex='code'
                        value={auditEnum.value}
                    />
                </SearchItem>
                <SearchItem>
                    <XDatePicker
                        label='提交时间'
                        bindThis={setStartTime}
                        bindName='startTime'
                        value={startTime}
                        isFormat={true}
                    />
                    <XDatePicker
                        style={{ marginRight: '20px' }}
                        bindThis={setEndTime}
                        bindName='endTime'
                        value={endTime}
                        isFormat={true}
                    />
                </SearchItem>
                <SearchItem>
                    <XOKButton
                        label='查询'
                        onClick={() => searchHandle()}
                    />
                    <XCancelButton
                        label='重置'
                        onClick={onReset}
                    />
                </SearchItem>
            </SearchBox>
            <TableContent renderData={categoryList} tableAction={tableAction} paginationChange={paginationChange} page={page.current}></TableContent>
        </div>
    </KeepAlive>;
};

Main.propTypes = {
    getProductUsername: PropTypes.func,
    productUsername: PropTypes.array,
    getGoodsAuditProcessEnum: PropTypes.func,
    goodsAuditProcessEnum: PropTypes.array,
    getCategoryList: PropTypes.func,
    categoryList: PropTypes.object,

    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};
const mapStateToProps = (state) => ({
    productUsername: state.businessCategory.productUsername.merchantIdAndNames || [],
    goodsAuditProcessEnum: state.businessCategory.goodsAuditProcessEnum || [],
    categoryList: state.businessCategory.categoryList,
});

export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);