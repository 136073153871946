import * as T from './actiontypes';

/**
 * 存储'直播间分类表格'数据源
 * @param {*} state 
 * @param {*} action 
 */

const anchorCoverListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
 };

 const videoListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
 };

 const communityListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
 };

export const studioRoomTable = (state = anchorCoverListInital, action) => {
    let dataList = state.dataList;
    switch(action.type){
        case T.SET_STUDIOROOM_TABELSOURCE:
            return action.payload;
        case T.UPDATE_STUDIOROOM_TABELSOURCE_STATE:
            dataList = state.dataList.map((item)=>{
                if(item.id === action.payload.id){
                    item.state = action.payload.state;
                }
                return item;
            });
            return {...state, dataList};
        default:
            return state;
    }
};

export const classifyStatus = (state = [], action) => {
    switch(action.type){
        case T.SET_LIVE_CLASSIFYSTATUS:
            return action.payload;
        default:
            return state;
    }
};



export const videoData = (state = videoListInital, action) => {
    let dataList = state.dataList;
    switch(action.type){
        case T.SET_VIDEO_DATA:
            return action.payload;
        case T.UPDATE_VIDEO_TABELSOURCE_STATE:
            dataList = state.dataList.map((item)=>{
                if(item.id === action.payload.id){
                    item.state = action.payload.state;
                }
                return item;
            });
            return {...state, dataList};
        default:
            return state;
    }
};


export const communityData = (state = communityListInital, action) => {
    let dataList = state.dataList;
    switch(action.type){
        case T.SET_COMMUNITY_DATA:
            return action.payload;
        case T.UPDATE_COMMUNITY_TABELSOURCE_STATE:
            dataList = state.dataList.map((item)=>{
                if(item.id === action.payload.id){
                    item.state = action.payload.state;
                }
                return item;
            });
            return {...state, dataList};
        default:
            return state;
    }
};


export const updateLiveStatus = (state = {status: null, message:''}, action) => {
    switch(action.type){
        case T.UPDATE_LIVE_STATUS_SUCCESS:
            return {...state, status: 1, msg:'状态更新成功'};
        case T.UPDATE_LIVE_STATUS_FAIL:
            return {...state, status: 0, msg:'状态更新失败'};
        case T.INIT_LIVE_STATUS:
            return {status: null, msg:''};
        default:
            return state;
    }
};