import React from 'react';
import { regFenToYuan } from '@/util/money';
import styles from './TableData.module.scss';
import { Switch, Tooltip } from 'antd';
/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
	{
		title: '设置项名称',
		dataIndex: 'settingName',
		key: 'settingName',
		width: '15%',
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '参数值',
		dataIndex: 'ratio',
		key: 'ratio',
		width: '8%',
		align: 'center',
		render: (text) => (_renderTextMoneyCell(text))
	},
	{
		title: '执行时间',
		dataIndex: 'startDate',
		key: 'startDate',
		width: '10%',
		align: 'center',
		render: (text, item) => {
			return <div>
				<p>{text}</p>
				<p>{item.endDate}</p>
			</div>;
		}
	},
	{
		title: '说明',
		dataIndex: 'remark',
		key: 'remark',
		width: '15%',
		align: 'center',
		onCell: () => {
			return {
				style: {
					maxWidth: 70,
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					cursor: 'pointer'
				}
			};
		},
		render: (text) => (TableTipCell(text))
	},
	{
		title: '默认项',
		dataIndex: 'default',
		key: 'default',
		width: '9%',
		align: 'center',
		render: (text) => {
			return <span>{text ? '是' : '否'}</span>;
		}
	},
	{
		title: '更新时间',
		dataIndex: 'gmtModify',
		key: 'gmtModify',
		width: '12%',
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '状态',
		dataIndex: 'statusDescript',
		key: 'statusDescript',
		width: '10%',
		align: 'center',
		render: (text, record) => (TableCellState(text, record))
	},
	{
		title: '可用',
		dataIndex: 'enabled',
		key: 'enabled',
		width: '10%',
		align: 'center',
		render: (text, item) => (SwitchCell(text, item, actionClickHandle))
	},
	{
		title: '操作',
		dataIndex: 'id',
		key: 'id',
		align: 'center',
		width: '15%',
		render: (id, record) => TableAction(id, record, actionClickHandle)
	},
];
const _renderTextMoneyCell = (text) => {
	return (
		<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
			{regFenToYuan(text)} %
      </div>
	);
};
export const takeDataSource = (data) => {
	// 解析表格数据，使符合columns格式要求
	return data && data.map((item) => {
		let key = 0;
		item.merchantKey = key++;//操作key
		return item;
	});
};

// 表格项内容为文本
const TableTipCell = (text) => {
	return (
		<Tooltip placement='topLeft' title={text}>{text}</Tooltip>
	);
};
// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
	let checked = true;
	if (sw == 0) {
		checked = false;
	}
	return (
		<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
			<Switch
				checkedChildren="开"
				unCheckedChildren="关"
				disabled={item.status !== 'CONFIRM_SUCCESS'}
				checked={checked}
				key={item.id}
				onChange={(value) => {
					actionClickHandle(item.id, 'switch', value);
				}}
			/>
		</div>
	);
};


export const takeColumns = (actionClickHandle) => {
	return columns(actionClickHandle);
};

const TableCell = (text) => {
	return (
		<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
			{text}
		</div>
	);
};
const TableCellState = (text) => {
	return (
		<div className={styles.cellstate} style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
			<b>▪</b>{text}
		</div>
	);
};

const TableAction = (id, item, actionClickHandle) => {
	let itemText = item.statusDescript;
	if (itemText == '新建') {
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => actionClickHandle(id, 'details')}>详情</div>
				<div className={styles.item} onClick={() => actionClickHandle(id, 'edit')}>编辑</div>
				<div className={styles.item} onClick={() => actionClickHandle(id, 'submitAudit')}>提交审核</div>
			</div>
		);
	} else if (itemText == '审核中') {
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => actionClickHandle(id, 'details')}>详情</div>
				{/* <div className={styles.item} onClick={() => actionClickHandle(id, 'audit')}>审核</div> */}
				<div style={{ width: '48px' }}></div>
			</div>
		);
	} else if (itemText == '审核通过') {
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => actionClickHandle(id, 'details')}>详情</div>
				{/* <div style={{ width: '24px' }}></div>
        <div style={{ width: '48px' }}></div> */}
			</div>
		);
	} else if (itemText == '审核不通过') {
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => actionClickHandle(id, 'details')}>详情</div>
				<div className={styles.item} onClick={() => actionClickHandle(id, 'edit')}>编辑</div>
				<div className={styles.item} onClick={() => actionClickHandle(id, 'submitAudit')}>提交审核</div>
			</div>
		);
	}
};