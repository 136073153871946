import { createModel } from '@/store/tools';

const model = {
    namespace: 'xiubei',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getXiuBeiList': T('getXiuBeiList'),
            'getXiuBeiInfo': T('getXiuBeiInfo'),
            'getXiuBeiState': T('getXiuBeiState'),
        },
        actions: {
            'getXiuBeiList': A('getXiuBeiList'),
            'getXiuBeiInfo': A('getXiuBeiInfo'),
            'getXiuBeiState': A('getXiuBeiState'),
        },
        sagas: {
            'getXiuBeiList': S('getXiuBeiList', '/boss/xiubei/listXiubei'),
            'getXiuBeiInfo': S('getXiuBeiInfo', '/boss/xiubei/getXiubeiDetail'),
            'getXiuBeiState': S('getXiuBeiState', '/boss/xiubei/xiubeiStateSelect'),
        },
        reducers: {
            'xiubeiList': R('getXiuBeiList', { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 }),
            'xiubeiInfo': R('getXiuBeiInfo', {}),
            'xiubeiState': R('getXiuBeiState', []),
        }
    })
};
export default createModel(model);