/**
 *  股票溢价收益
 * */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import Premium from './premium';
import Rmbcash from './rmbcash';

const tabList = [
    { id: 1, label: '股票溢价收益权' },
    { id: 2, label: 'RMB兑换港元设置' },
];
export default class Premiumyield extends React.Component {

    static propTypes = {
        history: PropTypes.object, // 路由history对象
    }

    state = {
        curTabID: 1,  // 默认显示的tab  
    }

    componentDidMount() { }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }


    render() {
        const { curTabID } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={tabList} onChange={curTabID => this.setState({ curTabID })} activedID={curTabID} />
                {
                    curTabID == 1 ?
                        <Premium history={history} curTabID={curTabID} /> :
                        <Rmbcash history={history} curTabID={curTabID} />
                }
            </div>
        );
    }
}