export const GET_INVOICELIST = 'INVOICELIST/GET_INVOICELIST';
export const SET_INVOICELIST = 'INVOICELIST/SET_INVOICELIST';

// 发票管理列表
// export const GET_INVOICEMANAGE

export const GET_INVOICEMANAGE_TABLESOURCE = 'INVOICEMANAGE/GET_INVOICEMANAGE_TABLESOURCE';
export const SET_INVOICEMANAGE_TABLESOURCE = 'INVOICEMANAGE/SET_INVOICEMANAGE_TABLESOURCE';

export const GET_INVOICEMANAGE_UNIT_SELECTSOURCE = 'INVOICEMANAGE/GET_INVOICEMANAGE_UNIT_SELECTSOURCE';
export const SET_INVOICEMANAGE_UNIT_SELECTSOURCE = 'INVOICEMANAGE/SET_INVOICEMANAGE_UNIT_SELECTSOURCE';