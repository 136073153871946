import { createModel } from '@/store/tools';

const model = {
    namespace: 'noticelist',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getNoticeTableList': T('getNoticeTableList'),
            'getProblemTableList': T('getProblemTableList'),
            'getProblemDetail': T('getProblemDetail'),
        },
        actions: {
            'getNoticeTableList': A('getNoticeTableList'), // 商户须知列表数据
            'getProblemTableList': A('getProblemTableList'), // 问题管理列表数据
            'getProblemDetail': A('getProblemDetail'), // 问题管理-获取详情数据 
            'getCleanDetail': C('getProblemDetail'), // 问题管理-清除详情数据
        },
        sagas: {
            'getNoticeTableList': S('getNoticeTableList', '/instructionTitle/findInstructionTitleList'),
            'getProblemTableList': S('getProblemTableList', '/instructionQuestionList/findInstructionQuestionList'),
            'getProblemDetail': S('getProblemDetail', '/instructionQuestionList/getInstructionQuestion'),
        },
        reducers: {
            'noticeTableList': R('getNoticeTableList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'problemTableList': R('getProblemTableList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'problemDetail': R('getProblemDetail', {}),
        }
    })
};
export default createModel(model);