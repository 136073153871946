//获取/保存订单列表
export const GET_STARORDER_LIST = 'ORDERMANAGE/GET_STARORDER_LIST';
export const SET_STARORDER_LIST = 'ORDERMANAGE/SET_STARORDER_LIST';
// 支付失败订单
export const GET_FAILORDER_LIST = 'ORDERMANAGE/GET_FAILORDER_LIST';
export const SET_FAILORDER_LIST = 'ORDERMANAGE/SET_FAILORDER_LIST';
// 支付方式
export const GET_PAY_WAYS = 'ORDERMANAGE/GET_PAY_WAYS';
export const SET_PAY_WAYS = 'ORDERMANAGE/SET_PAY_WAYS';
// 异常类型
export const GET_RISK_TYPE = 'ORDERMANAGE/GET_RISK_TYPE';
export const SET_RISK_TYPE = 'ORDERMANAGE/SET_RISK_TYPE';
// 状态
export const GET_STATUS_LIST = 'ORDERMANAGE/GET_STATUS_LIST';
export const SET_STATUS_LIST = 'ORDERMANAGE/SET_STATUS_LIST';
// 预警用户
export const GET_RISKUSER_LIST = 'ORDERMANAGE/GET_RISKUSER_LIST';
export const SET_RISKUSER_LIST = 'ORDERMANAGE/SET_RISKUSER_LIST';
// 预警等级
export const GET_RISKLEVEL_LIST = 'ORDERMANAGE/GET_RISKLEVEL_LIST';
export const SET_RISKLEVEL_LIST = 'ORDERMANAGE/SET_RISKLEVEL_LIST';
// 充值来源
export const GET_DEVTYPE_LIST = 'ORDERMANAGE/GET_DEVTYPE_LIST';
export const SET_DEVTYPE_LIST = 'ORDERMANAGE/SET_DEVTYPE_LIST';

