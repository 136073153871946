import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Tooltip, } from 'antd';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';
import { regFenToYuan, } from '@/util/money';

const columns = (TableAction, actionClickHandle) => [
    {
        title: '卡券名称',
        dataIndex: 'couponName',
        key: 'couponName',
        width: cellWidth.normal,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '卡券类型',
        dataIndex: 'couponTypeName',
        key: 'couponTypeName',
        width: cellWidth.small,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '领取状态',
        dataIndex: 'stateName',
        key: 'stateName',
        align: 'center',
        width: cellWidth.small,
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '面额(元/折扣)',
        dataIndex: 'amountDiscount',
        key: 'amountDiscount',
        width: cellWidth.small,
        align: 'center',
        onCell: tooltipStyle,
        render: (text, record) => (TablePriceCell(text, record))
    },
    {
        title: '门槛(元)',
        dataIndex: 'threshold',
        key: 'threshold',
        width: cellWidth.small,
        align: 'center',
        onCell: tooltipStyle,
        render: (text, record) => (TablethresholdCell(text, record))
    },
    {
        title: '发放时间',
        dataIndex: 'enrollment',
        key: 'enrollment',
        width: cellWidth.big,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '有效期',
        dataIndex: 'validity',
        key: 'validity',
        width: cellWidth.big,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '限领',
        dataIndex: 'receiveNum',
        key: 'receiveNum',
        width: cellWidth.small,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '发行量',
        dataIndex: 'totalNum',
        key: 'totalNum',
        width: cellWidth.small,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '已领取',
        dataIndex: 'receivedNum',
        key: 'receivedNum',
        width: cellWidth.small,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '创建人',
        dataIndex: 'createUserName',
        key: 'createUserName',
        width: cellWidth.normal,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 290,
        fixed: 'right',
        render: (id, record) => TableAction(id, record, actionClickHandle)
    }
];

const TableAction = (id, record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'detail')}>详情</div>
            <div className={`${(record.stateCode == 'NOT_STARTED' || record.stateCode == 'STOPPED') ? null : styles.hide} ${styles.item}`} onClick={() => actionClickHandle(id, 'edit')}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'stateType', record)}>{record.state == '1' ? '暂停' : '开启'}</div>
            {record.couponType == 'DELIVERY' && <div className={styles.item} onClick={() => actionClickHandle(id, 'qrcode', record)}>下载二维码</div>}
            {(record.couponType == 'EXCHANGE_COUPON' || record.couponType == 'DELIVERY') && <div className={styles.item} onClick={() => actionClickHandle(id, 'getDetail', record)}>数据</div>}
        </div >
    );
};

const TablePriceCell = (text, record) => {
    return (
        <Tooltip placement='topLeft' title={(record.couponType == 'COUPON' || record.couponType == 'DELIVERY') ? (regFenToYuan(text)) : `${text}折`}> {(record.couponType == 'COUPON' || record.couponType == 'DELIVERY') ? (regFenToYuan(text)) : `${text}折`}</Tooltip>
    );
};
const TablethresholdCell = (text, record) => {
    return (
        <Tooltip placement='topLeft' title={record.threshold == '0' ? '无门槛' : regFenToYuan(text)}> {record.threshold == '0' ? '无门槛' : (regFenToYuan(text))}</Tooltip>
    );
};

class TypeTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data.map(item => {
            item.enrollment = `${item.startTime ? item.startTime : ''}—${item.endTime ? item.endTime : ''}`;//发放时间
            //面额折扣数据切换
            item.amountDiscount = (item.couponType == 'COUPON' || item.couponType == 'DELIVERY') ? item.denomination : item.discount;
            if (item.termValidity == 'FIXED_DURATION') {
                item.validity = item.duration == '0' ? '永久有效' : `${item.duration}天`;//有效期---"FIXED_DATE"="固定日期"
            } else {
                item.validity = `${item.effectiveTime ? item.effectiveTime : ''}—${item.overdueTime ? item.overdueTime : ''}`;//有效期---"FIXED_DURATION"="固定时长"
            }
            return item;
        });
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { renderData, pagiNation, paginationChange } = this.props;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={columns(TableAction, this._actionHandle)}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={pagiNation.resultTotal}
                    pageSize={pagiNation.pageSize}
                    pageNum={pagiNation.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    pagiNation: PropTypes.object, // 分页数据
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string
};

export default XTableHOC(TypeTable);