/**
 *  服务天使设置 
 * */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Checkbox, InputNumber, Button, message } from 'antd';
import http from '@/assets/api/http';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class Main extends Component {

	state = {
		GIFT_GENERAL_MEMBER_DIRECT_MEMBER: { state: 0, value: '', id: '' },
		GIFT_GENERAL_MEMBER_SALE_AMOUNT: { state: 0, value: '', id: '' },
		GIFT_GENERAL_MEMBER_MYSELF_AMOUNT: { state: 0, value: '', id: '' },
		GIFT_GENERAL_MEMBER_ADMIN_AUDIT: { state: 0, value: '', id: '' },
		REGION_COMPANY_GIFT_DIRECT_MEMBER: { state: 0, value: '', id: '' },
		REGION_COMPANY_GIFT_GROUP_MEMBER: { state: 0, value: '', id: '' },
		REGION_COMPANY_GIFT_SALE_AMOUNT: { state: 0, value: '', id: '' },
		REGION_COMPANY_GIFT_MYSELF_AMOUNT: { state: 0, value: '', id: '' },
		MANAGEMENT_ALLOWANCE_GIFT_DIRECT_MEMBER: { state: 0, value: '', id: '' },
		MANAGEMENT_ALLOWANCE_GIFT_GROUP_MEMBER: { state: 0, value: '', id: '' },
		MANAGEMENT_ALLOWANCE_GIFT_SALE_AMOUNT: { state: 0, value: '', id: '' },
		MANAGEMENT_ALLOWANCE_GIFT_MYSELF_AMOUNT: { state: 0, value: '', id: '' },
	}

	componentDidMount() {
		http('/user/level/listGiftAccountLevel', {}).then(res => {
			if (res.code === 200) {
				let data = res.result || [], values = {};
				data.forEach((v) => {
					let value = v.value;
					if (v.code === 'SALE_AMOUNT' || v.code === 'MYSELF_AMOUNT') {
						value = value / 100;
					}
					values[`${v.levelType}_${v.accountType}_${v.code}`] = {
						...v,
						value,
						state: v.state === 1
					};
				});
				this.setState(values);
			}
		});
	}

	onItemStateChange = (key) => {
		const value = this.state[key] || {};
		this.setState({
			[key]: { ...value, state: !value.state }
		});
	}

	onItemValueChange = (key, value) => {
		const old = this.state[key] || {};
		this.setState({
			[key]: { ...old, value }
		});
	}

	updateValue = (keys) => {
		let list = keys.map(v => {
			let { code, id, value, state } = this.state[v] || {};
			if (code === 'SALE_AMOUNT' || code === 'MYSELF_AMOUNT') {
				value = value * 100;
			}
			state = state ? 1 : 0;
			return { id, value, state };
		});
		http('/user/level/updateGiftAccountLevel', { list }).then(res => {
			if (res.code === 200) {
				message.success(res.message);
			}
		});
	}

	render() {

		const {
			GIFT_GENERAL_MEMBER_DIRECT_MEMBER,
			GIFT_GENERAL_MEMBER_SALE_AMOUNT,
			GIFT_GENERAL_MEMBER_MYSELF_AMOUNT,
			GIFT_GENERAL_MEMBER_ADMIN_AUDIT,
			REGION_COMPANY_GIFT_DIRECT_MEMBER,
			REGION_COMPANY_GIFT_GROUP_MEMBER,
			REGION_COMPANY_GIFT_SALE_AMOUNT,
			REGION_COMPANY_GIFT_MYSELF_AMOUNT,
			MANAGEMENT_ALLOWANCE_GIFT_DIRECT_MEMBER,
			MANAGEMENT_ALLOWANCE_GIFT_GROUP_MEMBER,
			MANAGEMENT_ALLOWANCE_GIFT_SALE_AMOUNT,
			MANAGEMENT_ALLOWANCE_GIFT_MYSELF_AMOUNT,
		} = this.state;

		return (
			<div className={styles.flexBoxContainer}>
				<div className={styles.growth}>
					<div className={styles.top_title}>
						<span>升级为服务天使</span>
						<span className={styles.subTitle}>（普通会员升级为服务天使）</span>
					</div>
					<div className={styles.form}>
						<div className={styles.itemTitle}>升级条件：</div>
						<div className={styles.formItem}>
							<Checkbox checked={GIFT_GENERAL_MEMBER_DIRECT_MEMBER.state} onChange={() => this.onItemStateChange('GIFT_GENERAL_MEMBER_DIRECT_MEMBER')}>直推人数达到</Checkbox>
							<InputNumber value={GIFT_GENERAL_MEMBER_DIRECT_MEMBER.value} style={{ width: 100 }} onChange={(e) => this.onItemValueChange('GIFT_GENERAL_MEMBER_DIRECT_MEMBER', e)} />
							<span>（人）</span>
							<span className={styles.subTitle}>（会员直接推荐的会员数）</span>
						</div>
						<div className={styles.formItem}>
							<Checkbox checked={GIFT_GENERAL_MEMBER_SALE_AMOUNT.state} onChange={() => this.onItemStateChange('GIFT_GENERAL_MEMBER_SALE_AMOUNT')}>销售金额达到</Checkbox>
							<InputNumber value={GIFT_GENERAL_MEMBER_SALE_AMOUNT.value} style={{ width: 100 }} onChange={(e) => this.onItemValueChange('GIFT_GENERAL_MEMBER_SALE_AMOUNT', e)} />
							<span>（元）</span>
							<span className={styles.subTitle}>（所有直接推荐的会员购买商品的实付金额）</span>
						</div>
						<div className={styles.formItem}>
							<Checkbox checked={GIFT_GENERAL_MEMBER_MYSELF_AMOUNT.state} onChange={() => this.onItemStateChange('GIFT_GENERAL_MEMBER_MYSELF_AMOUNT')}>自购金额达到</Checkbox>
							<InputNumber value={GIFT_GENERAL_MEMBER_MYSELF_AMOUNT.value} style={{ width: 100 }} onChange={(e) => this.onItemValueChange('GIFT_GENERAL_MEMBER_MYSELF_AMOUNT', e)} />
							<span>（元）</span>
							<span className={styles.subTitle}>（会员自己一次性购买商品的实付金额）</span>
						</div>
						<div className={styles.formItem} style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
							<Checkbox checked={GIFT_GENERAL_MEMBER_ADMIN_AUDIT.state} onChange={() => this.onItemStateChange('GIFT_GENERAL_MEMBER_ADMIN_AUDIT')}>是否开启管理员审核</Checkbox>
							<div className={styles.subTitle} style={{ marginLeft: 25, marginTop: 10 }}>
								<p>1、开启审核后，需要由管理员审核通过以后，会员才能升级为服务天使</p>
								<p>2、关闭审核，即会员达到升级条件后，系统自动升级为服务天使</p>
							</div>
						</div>
						<div className={styles.formItem}>
							<Button type="primary" style={{ width: 120, marginLeft: 25 }} onClick={() =>
								this.updateValue(
									[
										'GIFT_GENERAL_MEMBER_DIRECT_MEMBER',
										'GIFT_GENERAL_MEMBER_SALE_AMOUNT',
										'GIFT_GENERAL_MEMBER_MYSELF_AMOUNT',
										'GIFT_GENERAL_MEMBER_ADMIN_AUDIT'
									]
								)}
							>保存</Button>
						</div>
					</div>
				</div>

				<div className={styles.growth}>
					<div className={styles.top_title}>
						<span>升级为区域公司</span>
						<span className={styles.subTitle}>（服务天使升级为区域公司）</span>
					</div>
					<div className={styles.form}>
						<div className={styles.itemTitle}>获得条件：</div>
						<div className={styles.formItem}>
							<Checkbox checked={REGION_COMPANY_GIFT_DIRECT_MEMBER.state} onChange={() => this.onItemStateChange('REGION_COMPANY_GIFT_DIRECT_MEMBER')}>直推人数达到</Checkbox>
							<InputNumber value={REGION_COMPANY_GIFT_DIRECT_MEMBER.value} style={{ width: 100 }} onChange={(e) => this.onItemValueChange('REGION_COMPANY_GIFT_DIRECT_MEMBER', e)} />
							<span>（人）</span>
							<span className={styles.subTitle}>（服务天使直接推荐的会员数）</span>
						</div>
						<div className={styles.formItem}>
							<Checkbox checked={REGION_COMPANY_GIFT_GROUP_MEMBER.state} onChange={() => this.onItemStateChange('REGION_COMPANY_GIFT_GROUP_MEMBER')}>团队人数达到</Checkbox>
							<InputNumber value={REGION_COMPANY_GIFT_GROUP_MEMBER.value} style={{ width: 100 }} onChange={(e) => this.onItemValueChange('REGION_COMPANY_GIFT_GROUP_MEMBER', e)} />
							<span>（人）</span>
							<span className={styles.subTitle}>（服务天使辖下所有会员数）</span>
						</div>
						<div className={styles.formItem}>
							<Checkbox checked={REGION_COMPANY_GIFT_SALE_AMOUNT.state} onChange={() => this.onItemStateChange('REGION_COMPANY_GIFT_SALE_AMOUNT')}>销售金额达到</Checkbox>
							<InputNumber value={REGION_COMPANY_GIFT_SALE_AMOUNT.value} style={{ width: 100 }} onChange={(e) => this.onItemValueChange('REGION_COMPANY_GIFT_SALE_AMOUNT', e)} />
							<span>（元）</span>
							<span className={styles.subTitle}>（服务天使辖下所有会员购买商品的实付金额）</span>
						</div>
						<div className={styles.formItem}>
							<Checkbox checked={REGION_COMPANY_GIFT_MYSELF_AMOUNT.state} onChange={() => this.onItemStateChange('REGION_COMPANY_GIFT_MYSELF_AMOUNT')}>自购金额达到</Checkbox>
							<InputNumber value={REGION_COMPANY_GIFT_MYSELF_AMOUNT.value} style={{ width: 100 }} onChange={(e) => this.onItemValueChange('REGION_COMPANY_GIFT_MYSELF_AMOUNT', e)} />
							<span>（元）</span>
							<span className={styles.subTitle}>（服务天使自己一次性购买商品的实付金额）</span>
						</div>
						<div className={styles.formItem}>
							<Button type="primary" style={{ width: 120, marginLeft: 25 }} onClick={() =>
								this.updateValue(
									[
										'REGION_COMPANY_GIFT_DIRECT_MEMBER',
										'REGION_COMPANY_GIFT_GROUP_MEMBER',
										'REGION_COMPANY_GIFT_SALE_AMOUNT',
										'REGION_COMPANY_GIFT_MYSELF_AMOUNT'
									]
								)}
							>保存</Button>
						</div>
					</div>
				</div>

				<div className={styles.growth}>
					<div className={styles.top_title}>
						<span>服务天使管理津贴</span>
						<span className={styles.subTitle}>（服务天使获得管理津贴规则）</span>
					</div>
					<div className={styles.form}>
						<div className={styles.itemTitle}>获得条件：</div>
						<div className={styles.formItem}>
							<Checkbox checked={MANAGEMENT_ALLOWANCE_GIFT_DIRECT_MEMBER.state} onChange={() => this.onItemStateChange('MANAGEMENT_ALLOWANCE_GIFT_DIRECT_MEMBER')}>直推人数达到</Checkbox>
							<InputNumber value={MANAGEMENT_ALLOWANCE_GIFT_DIRECT_MEMBER.value} style={{ width: 100 }} onChange={(e) => this.onItemValueChange('MANAGEMENT_ALLOWANCE_GIFT_DIRECT_MEMBER', e)} />
							<span>（人）</span>
							<span className={styles.subTitle}>（服务天使直接推荐的会员数）</span>
						</div>
						<div className={styles.formItem}>
							<Checkbox checked={MANAGEMENT_ALLOWANCE_GIFT_GROUP_MEMBER.state} onChange={() => this.onItemStateChange('MANAGEMENT_ALLOWANCE_GIFT_GROUP_MEMBER')}>团队人数达到</Checkbox>
							<InputNumber value={MANAGEMENT_ALLOWANCE_GIFT_GROUP_MEMBER.value} style={{ width: 100 }} onChange={(e) => this.onItemValueChange('MANAGEMENT_ALLOWANCE_GIFT_GROUP_MEMBER', e)} />
							<span>（人）</span>
							<span className={styles.subTitle}>（服务天使辖下所有会员数）</span>
						</div>
						<div className={styles.formItem}>
							<Checkbox checked={MANAGEMENT_ALLOWANCE_GIFT_SALE_AMOUNT.state} onChange={() => this.onItemStateChange('MANAGEMENT_ALLOWANCE_GIFT_SALE_AMOUNT')}>销售金额达到</Checkbox>
							<InputNumber value={MANAGEMENT_ALLOWANCE_GIFT_SALE_AMOUNT.value} style={{ width: 100 }} onChange={(e) => this.onItemValueChange('MANAGEMENT_ALLOWANCE_GIFT_SALE_AMOUNT', e)} />
							<span>（元）</span>
							<span className={styles.subTitle}>（服务天使辖下所有会员购买商品的实付金额）</span>
						</div>
						<div className={styles.formItem}>
							<Checkbox checked={MANAGEMENT_ALLOWANCE_GIFT_MYSELF_AMOUNT.state} onChange={() => this.onItemStateChange('MANAGEMENT_ALLOWANCE_GIFT_MYSELF_AMOUNT')}>自购金额达到</Checkbox>
							<InputNumber value={MANAGEMENT_ALLOWANCE_GIFT_MYSELF_AMOUNT.value} style={{ width: 100 }} onChange={(e) => this.onItemValueChange('MANAGEMENT_ALLOWANCE_GIFT_MYSELF_AMOUNT', e)} />
							<span>（元）</span>
							<span className={styles.subTitle}>（服务天使自己一次性购买商品的实付金额）</span>
						</div>
						<div className={styles.formItem}>
							<Button type="primary" style={{ width: 120, marginLeft: 25 }} onClick={() =>
								this.updateValue(
									[
										'MANAGEMENT_ALLOWANCE_GIFT_DIRECT_MEMBER',
										'MANAGEMENT_ALLOWANCE_GIFT_GROUP_MEMBER',
										'MANAGEMENT_ALLOWANCE_GIFT_SALE_AMOUNT',
										'MANAGEMENT_ALLOWANCE_GIFT_MYSELF_AMOUNT'
									]
								)}
							>保存</Button>
						</div>
					</div>
				</div>

				<div className={styles.growth} style={{ padding: 15 }}>
					<p>计算规则说明：</p>
					<p>1、销售金额，自购金额，是按订单完成后进行统计</p>
					<p>2、每天凌晨1点执行前一天的升级条件的统计 ，若满足条件的会员，会在第二天升级</p>
				</div>
			</div>
		);
	}
}

Main.propTypes = {
	addOneBreadcrumbPath: PropTypes.func,
	keepSecondNavBreadcrumb: PropTypes.func,
	match: PropTypes.object, // 路由match对象
	history: PropTypes.object, // 路由history
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);