/**
 *  人气值商城设置
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, message, Select, Icon } from 'antd';
import http from '@/assets/api/http';
// import ChooseModal from '../../modal/ChooseModal';
import { XOKButton, showConfirm, XCancelButton, XSearchcascade } from '@/components/xqxc_ui';
// import { takeDataSource, takeColumns } from './components/TableData';
import { getUserNameSeleList, getUserNameDefault } from '@/reducers/systemmanage/starShopSet/actions';
const { Option } = Select;
class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            selectedRowKeys: [],
            merchant: {},
        };
    }

    componentDidMount() {
        this.props.getUserNameSeleList();
        this.props.getUserNameDefault();
    }


    UNSAFE_componentWillReceiveProps(newProps) {
        const { userNameDefault } = newProps;
        this.setState({ merchant: userNameDefault });
    }

    // // 表格操作功能回调  
    // _actionClickHandle = (id, type) => {
    // 	console.log(id, type);
    // 	switch (type) {
    // 		case 'START_SERVICE'://启用
    // 			console.log('启用');
    // 			showConfirm('您是否要启用', '', () => {

    // 			}, () => { });

    // 			break;
    // 		case 'STOP_SERVICE'://禁用
    // 			console.log('禁用');
    // 			showConfirm('您是否要禁用', '', () => {
    // 			}, () => { });
    // 			break;
    // 		case 'DELETE'://删除
    // 			console.log('删除');
    // 			showConfirm('您是否要删除该商品', '', () => {

    // 			}, () => { });
    // 			break;
    // 		default: return null;
    // 	}

    // }

    // // 打开弹窗
    // _renderMode = () => {
    // 	switch (this.state.currentOpenMode) {
    // 		case 'choose'://启用
    // 			return <ChooseModal title='选择商户' visible={true} closeMode={this._closeMode} />;
    // 		default:
    // 			return null;
    // 	}
    // }

    // // 关闭弹框
    // _closeMode = (status) => {
    // 	this.setState({ currentOpenMode: '' });
    // }

    submithandle = () => {
        // this.props.history.push({
        //     pathname: '/home/systemmanage/financial'
        // });
        const { merchant } = this.state;
        http('/starmall/goods/updateMerchant', { merchantId: merchant.id }, 'POST')
            .then(() => {
                message.success('修改发货商户成功。');
            })
            .catch((error) => {
                message.error(error.message);
            });
    }
    //选择商户 
    choosehandle = () => {
        this.setState({
            currentOpenMode: 'choose',
        });
    }
    // // 表格复选输入
    // _rowSelection = {
    // 	onChange: (selectedRowKeys, selectedRows) => {
    // 		console.log(selectedRows);
    // 		this.setState({ selectedRowKeys });
    // 		// this._actionHandle(null, 'batch', selectedRowKeys);
    // 	}
    // };

    // // 清空表格复选框
    // _cleanAllSelected = () => {
    // 	this.setState({ selectedRowKeys: [] });
    // }

    _selectChange = (data, item) => {
        console.log(item);

        this.setState({
            merchant: {
                id: item.key,
                merchantName: item.props.value
            }
        });
    }

    _select = (data) => {
        const { merchant } = this.state;
        return <Select
            value={merchant.merchantName}
            style={{ width: 600, height: 40 }}
            size='large'
            showSearch={true}
            suffixIcon={<Icon type="arrow-right" style={{ fontSize: '16px' }} />}
            onChange={this._selectChange}>
            {
                data.dataList.map((item, index) => {
                    if (index == 0) {
                        return <Option key={item.id} value={item.merchantName}>
                            {/* {item.merchantName} */}
                            <div>
                                <p className={styles.optionLabel}>{item.merchantName}</p>
                            </div>
                        </Option>;
                    } else {
                        return <Option key={item.id} value={item.merchantName}>
                            {/* {item.merchantName} */}
                            <div>
                                <p className={styles.optionLabel}>{item.merchantName}</p>
                                <p className={styles.text}>{item.merchantNo}</p>
                            </div>
                        </Option>;
                    }
                })
            }
        </Select>;
    }

    render() {
        const { userNameSeleList, userNameDefault } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.tableContainer} style={{ overflow: 'auto', overflowX: 'hidden' }}>
                    <div className={styles.marketing}>
                        <div className={styles.top_title}>
                            <span>人气值商城发货设置</span>
                        </div>
                        <p className={styles.marketop}>选择商户发货商品</p>
                        <div className={styles.layout}>
                            <div className={styles.select}>
                                {
                                    this._select(userNameSeleList, userNameDefault)
                                }
                            </div>
                            <XOKButton onClick={this.submithandle} style={{ width: '100px', marginLeft: '30px' }} label='保存' />
                        </div>
                        {/* <XSearchcascade
								style={{ margin: '20px' }}
								selectStyle={{ width: '500px' }}
								size='large'
								placeholder='请选择商户'
								SearchDataSource={this._seleDataDeal(userNameSeleList)}
								dataIndex='merchantName'
								defaultValue={merchant.merchantName}
								keyIndex='id'
								value={merchant.merchantName}
								onSearchChange={this._selectChange}
							></XSearchcascade> */}
                        {/* <XOKButton onClick={this.choosehandle} style={{ width: '100px', margin: '10px 0 30px 0' }} label='选择商户' /> */}
                        {/* <div className={`${styles.refundtable}`}>
								<div className={styles.flex}>
									<XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
									<Table
										rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
										rowKey={(record, index) => index}
										rowSelection={this._rowSelection}
										columns={takeColumns(this._actionClickHandle)}
										dataSource={takeDataSource([])}
										pagination={false}
									// scroll={{ y: true }}
									/>
								</div>
							</div> */}
                    </div>
                    {/* {this._renderMode()} */}
                </div>
            </div>
        );
    }
}
Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getUserNameSeleList: PropTypes.func,
    getUserNameDefault: PropTypes.func,

    userNameSeleList: PropTypes.object,
    userNameDefault: PropTypes.object
};

const mapStateToProps = (state) => ({
    userNameSeleList: state.starShopSet.userNameSeleList,
    userNameDefault: state.starShopSet.userNameDefault
});

export default connect(mapStateToProps, { getUserNameSeleList, getUserNameDefault })(Main);