//异步获取数据
export const GET_PORNOGRAPHY_LIST = 'PORNOGRAPHY/GET_PORNOGRAPHY_LIST';
export const GET_PORNOGRAPHY_ONLINE = 'PORNOGRAPHY/GET_PORNOGRAPHY_ONLINE'; 
export const GET_PORNOGRAPHY_EXCEL = 'PORNOGRAPHY/GET_PORNOGRAPHY_EXCEL'; 
export const GET_PORNOGRAPHY_TYPE = 'PORNOGRAPHY/GET_PORNOGRAPHY_TYPE'; 


//保存数据到store.state
export const SET_PORNOGRAPHY_LIST = 'PORNOGRAPHY/SET_PORNOGRAPHY_LIST'; 
export const SET_PORNOGRAPHY_ONLINE = 'PORNOGRAPHY/SET_PORNOGRAPHY_ONLINE'; 
export const SET_PORNOGRAPHY_EXCEL = 'PORNOGRAPHY/SET_PORNOGRAPHY_EXCEL';
export const SET_PORNOGRAPHY_TYPE = 'PORNOGRAPHY/SET_PORNOGRAPHY_TYPE'; 
