import { createModel } from '@/store/tools';

const model = {
    namespace: 'liwu',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getStockReconList': T('getStockReconList'),
            'getStockReconStatusEnum': T('getStockReconStatusEnum'),
            'getStockReconItemList': T('getStockReconItemList'),
            'getStockRecon': T('getStockRecon'),
            'getStockReconOrder': T('getStockReconOrder'),
            'getStockConfig': T('getStockConfig'),
            'getStockReconSourceTypeEnum': T('getStockReconSourceTypeEnum'),
        },
        actions: {
            'getStockReconList': A('getStockReconList'),
            'getStockReconStatusEnum': A('getStockReconStatusEnum'),
            'getStockReconItemList': A('getStockReconItemList'),
            'getStockRecon': A('getStockRecon'),
            'getStockReconOrder': A('getStockReconOrder'),
            'getStockConfig': A('getStockConfig'),
            'getStockReconSourceTypeEnum': A('getStockReconSourceTypeEnum'),
        },
        sagas: {
            'getStockReconList': S('getStockReconList', '/settle/stock/getStockReconList'), // 列表：礼屋业绩
            'getStockReconStatusEnum': S('getStockReconStatusEnum', '/enum/StockReconStatusEnum'), // 枚举：业绩状态
            'getStockReconItemList': S('getStockReconItemList', '/settle/stock/getStockReconItemList'), // 列表：核验单
            'getStockRecon': S('getStockRecon', '/settle/stock/getStockRecon'), // 详情：核验单
            'getStockReconOrder': S('getStockReconOrder', '/settle/stock/getStockReconOrder'), // 列表：订单明细
            'getStockConfig': S('getStockConfig', '/settle/stock/getStockConfig'), // 获取配置参数
            'getStockReconSourceTypeEnum': S('getStockReconSourceTypeEnum', '/enum/StockReconSourceTypeEnum'), // 枚举：业绩状态
        },
        reducers: {
            'getStockReconList': R('getStockReconList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getStockReconStatusEnum': R('getStockReconStatusEnum', []),
            'getStockReconItemList': R('getStockReconItemList', []),
            'getStockRecon': R('getStockRecon', {}),
            'getStockReconOrder': R('getStockReconOrder', []),
            'getStockConfig': R('getStockConfig', []),
            'getStockReconSourceTypeEnum': R('getStockReconSourceTypeEnum', []),
        }
    })
};
export default createModel(model);