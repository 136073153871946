import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Modal, Alert, InputNumber, Radio, Row, Col, Typography, message } from 'antd';
import { BigNumber } from 'bignumber.js';

const { Text } = Typography;

const costTypeEnum = [{ code: 'RATE', value: '按比例设置' }, { code: 'AMOUNT', value: '按金额设置' }];

//初始化显示比例
const showRate = (v) => {
    if (!v) return null;
    const baseRate = parseInt(new BigNumber(v).multipliedBy(10000).toNumber());
    return new BigNumber(baseRate).dividedBy(100).toNumber();
};

//初始化显示金额
const showFee = (v) => {
    if (!v) return null;
    return new BigNumber(parseInt(v)).dividedBy(100).toNumber();
};

//初始化营销服务费
const showMarketFee = (v) => {
    if (!v) return null;
    return new BigNumber(parseInt(v)).dividedBy(100).toNumber();
};

const MarketPointModal = ({ visible = false, operates: { setRenderModal, setModalParams, onFormChang } = {},
    data = {}, data: { skuId = '', attributeKey = '', totalAmount = 0, } = {}
}) => {

    const [settingMode, setSettingMode] = useState('RATE');

    const [params, setParams] = useState([
        { id: 'plat', title: '平台服务费', platRate: null, platAmount: null },
        { id: 'referrer', title: '推荐人营销服务费', referrerRate: null, referrerAmount: null },
        { id: 'buyerServicer', title: '购买人服务商营销服务费', buyerServicerRate: null, buyerServicerAmount: null },
        { id: 'buyerRegion', title: '购买人区域公司营销服务费', buyerRegionRate: null, buyerRegionAmount: null },
        { id: 'buyerCenter', title: '购买人运营中心营销服务费', buyerCenterRate: null, buyerCenterAmount: null },
        { id: 'merchantServicer', title: '商户所属的服务商营销服务费', merchantServicerRate: null, merchantServicerAmount: null }
    ]);

    useEffect(() => {
        if (!visible) return;
        const { serviceCharge: { settingMode = 'RATE',
            serviceChargeAmount: { platAmount = null, referrerAmount = null, buyerServicerAmount = null, buyerRegionAmount = null, buyerCenterAmount = null, merchantServicerAmount = null } = {},
            serviceChargeRate: { platRate = null, referrerRate = null, buyerServicerRate = null, buyerRegionRate = null, buyerCenterRate = null, merchantServicerRate = null } = {}
        } = {} } = data;
        setSettingMode(settingMode);
        setParams([
            { id: 'plat', title: '平台服务费', platRate: showRate(platRate), platAmount: showFee(platAmount) },
            { id: 'referrer', title: '推荐人营销服务费', referrerRate: showRate(referrerRate), referrerAmount: showFee(referrerAmount) },
            { id: 'buyerServicer', title: '购买人服务商营销服务费', buyerServicerRate: showRate(buyerServicerRate), buyerServicerAmount: showFee(buyerServicerAmount) },
            { id: 'buyerRegion', title: '购买人区域公司营销服务费', buyerRegionRate: showRate(buyerRegionRate), buyerRegionAmount: showFee(buyerRegionAmount) },
            { id: 'buyerCenter', title: '购买人运营中心营销服务费', buyerCenterRate: showRate(buyerCenterRate), buyerCenterAmount: showFee(buyerCenterAmount) },
            { id: 'merchantServicer', title: '商户所属的服务商营销服务费', merchantServicerRate: showRate(merchantServicerRate), merchantServicerAmount: showFee(merchantServicerAmount) }
        ]);
    }, [JSON.stringify(data)]);

    const typeUnit1 = { RATE: '(%)', AMOUNT: '(元)' };
    const typeUnit2 = { RATE: '(元)', AMOUNT: '(%)' };
    const value1 = { RATE: 'Rate', AMOUNT: 'Amount' };
    const value2 = { RATE: 'Amount', AMOUNT: 'Rate' };

    const onSubmit = () => {
        let totalFee = 0;
        let totalRate = 0;
        for (let i of params) {
            totalFee += new BigNumber(i[`${i.id}Amount`] || 0).multipliedBy(100).toNumber();
            totalRate += new BigNumber(i[`${i.id}Rate`] || 0).dividedBy(100).toNumber();
        }
        if (settingMode === 'AMOUNT' && totalFee > totalAmount) {
            return message.warning('平台服务费、推荐人、购买人服务商、购买人区域公司、购买人运营中心、商户所属的服务商营销服务费之和不超过营销服务费');
        }
        if (settingMode === 'RATE' && totalRate > 1) {
            return message.warning('平台服务费、推荐人、购买人服务商、购买人区域公司、购买人运营中心、商户所属的服务商营销服务费比例之和不超过100%');
        }
        let serviceChargeAmount = {};
        let serviceChargeRate = {};
        const countAmount = (v) => {
            if (!v) return 0;
            return new BigNumber(v).multipliedBy(100).toNumber();
        };
        const countRate = (v) => {
            if (!v) return 0;
            return new BigNumber(v).dividedBy(100).toNumber();
        };
        params.forEach(i => {
            serviceChargeAmount = { ...serviceChargeAmount, [`${i.id}Amount`]: countAmount(i[`${i.id}Amount`]) };
            serviceChargeRate = { ...serviceChargeRate, [`${i.id}Rate`]: countRate(i[`${i.id}Rate`]) };
        });
        onFormChang({ skuId, attributeKey, attributeValue: { serviceChargeAmount, serviceChargeRate, settingMode } });
        onCancel();
    };

    const onCancel = () => {
        setRenderModal(); setModalParams({});
    };

    const onTypeChange = e => {
        const list = params.map(i => {
            return { ...i, [`${i.id}${value1[e.target.value]}`]: null, [`${i.id}${value2[e.target.value]}`]: null };
        });
        setParams(list); setSettingMode(e.target.value);
    };

    const countFeeOrRate = (v) => {
        if (!v) return null;
        //已知比例算钱
        const amount = parseInt(new BigNumber(v).dividedBy(100).multipliedBy(new BigNumber(totalAmount)).toNumber());
        //已知钱算比例
        const rate = parseInt(new BigNumber(v).multipliedBy(100).dividedBy(new BigNumber(totalAmount)).multipliedBy(10000).toNumber());
        const values = {
            RATE: new BigNumber(amount).dividedBy(100).toNumber(),
            AMOUNT: new BigNumber(rate).dividedBy(100).toNumber(),
        };
        return values[settingMode];
    };

    const onInputChange = (id, v) => {
        const list = params.map(i => {
            if (i.id === id) {
                return {
                    ...i, [`${i.id}${value1[settingMode]}`]: v,
                    [`${i.id}${value2[settingMode]}`]: countFeeOrRate(v)
                };
            }
            return i;
        });
        setParams(list);
    };

    return <Modal width={660} centered title='营销服务费设置' visible={visible}
        confirmLoading={false} destroyOnClose={true} onOk={onSubmit} onCancel={onCancel}
        keyboard={true} maskClosable={false} okText='确定' cancelText='取消'
    >
        <div className={styles.container}>
            <Alert style={{ marginBottom: '20px' }} message='营销服务费' type='info' showIcon description={<Row>
                <Col span={24}><Text>按（商品实付金额-手续费）- 结算价 = 营销服务费（营销收益）</Text></Col>
                <Col span={24}><Text>营销服务费 * 平台服务费比例 = 平台服务费</Text></Col>
                <Col span={24}><Text>营销服务费 * 推荐人服务费比例 = 推荐人服务费</Text></Col>
                <Col span={24}><Text>营销服务费 * 购买人服务商比例 = 购买人服务费</Text></Col>
                <Col span={24}><Text>营销服务费 * 购买人区域公司比例 = 购买人区域公司</Text></Col>
                <Col span={24}><Text>营销服务费 * 购买人运营中心比例 = 购买人运营中心</Text></Col>
                <Col span={24}><Text>营销服务费 * 商户所属的服务商比例 = 商户所属的服务商</Text></Col>
                <Col span={24}><Text>所有设置的费用比例相加不能大于100%</Text></Col>
            </Row>} />
            <Row gutter={[0, 16]}>
                <Col span={12}><Radio.Group value={settingMode} onChange={onTypeChange}>
                    {costTypeEnum.map(i => {
                        return <Radio key={i.code} value={i.code}>{i.value}</Radio>;
                    })}
                </Radio.Group></Col>
                <Col span={12}><Text>可分配营销服务费：{showMarketFee(totalAmount)}</Text></Col>
                {params.map(i => {
                    return <Col key={i.id} span={24}>
                        <Text>{`${i.title}${typeUnit1[settingMode]}`}</Text>
                        <InputNumber style={{ margin: '0 10px', width: '150px' }} placeholder='请输入'
                            min={0} precision={2} value={i[`${i.id}${value1[settingMode]}`]} onChange={v => onInputChange(i.id, v)} />
                        <Text>{`${i[`${i.id}${value2[settingMode]}`] || '0.00'}${typeUnit2[settingMode]}`}</Text>
                    </Col>;
                })}
            </Row>
        </div>
    </Modal>;
};
MarketPointModal.propTypes = {
    operates: PropTypes.object,
    data: PropTypes.object,
    visible: PropTypes.bool
};
export default MarketPointModal;