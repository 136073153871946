/**
 * 新建核对单
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './NewCheckModal.module.scss';
import http from '@/assets/api/http';
import { XDatePicker } from '@/components/xqxc_ui';

class NewCheckModal extends React.Component {
    state = {
        date: null,
        confirmLoading: false
    };

    _handleOk = () => {
        this.setState({ confirmLoading: true });
        const { date } = this.state;
        if (!date) {
            message.error('请选择日期');
            return;
        }

        http('/settle/stock/createStockRecon', { reconDate: date }).then((response) => {
            this.setState({ confirmLoading: false });
            this.props.close('update');
        }).catch((error) => {
            message.error(error.message);
            this.setState({ confirmLoading: false });
        });
    }

    _handleCancel = () => {
        this.props.close();
    }

    render() {
        const { confirmLoading, date } = this.state;

        return (
            <Modal
                width={470}
                centered
                title='统计生成'
                visible={true}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XDatePicker
                        style={{ marginRight: 20 }}
                        label='截止日期'
                        placeholder='请选择截止日期'
                        value={date}
                        bindThis={this}
                        bindName='date'
                        isFormat={true}
                        showTime={false}
                        defaultTime='00:00:00'
                        onChange={this._onChange}
                    />
                    {
                        date ? <div className={styles.time}>
                            统计{date}之前所有未生成核对单的礼屋订单!
                        </div> : null
                    }


                </div>
            </Modal>
        );

    }
}

NewCheckModal.propTypes = {
    close: PropTypes.func.isRequired, // 关闭弹框的回调函数
    param: PropTypes.object
};

export default NewCheckModal;