import * as T from './actiontypes';

export const customListTable = (state = [], { type, payload, requestProps = {} }) => {
    switch (type) {
        case T.SET_CUSTOMLIST_TABLESOURCE:
            return { ...requestProps, ...payload };
        default:
            return state;
    }
};

// export const customListHomeTable = (state = [], { type, payload, requestProps = {} }) => {
//     switch (type) {
//         case T.SET_CUSTOMLIST_HOME_TABLESOURCE:
//             return { ...requestProps, ...payload };
//         default:
//             return state;
//     }
// };

// export const customListMallTable = (state = [], { type, payload, requestProps = {} }) => {
//     switch (type) {
//         case T.SET_CUSTOMLIST_MALL_TABLESOURCE:
//             return { ...requestProps, ...payload };
//         default:
//             return state;
//     }
// };

// export const customListSubjectTable = (state = [], { type, payload, requestProps = {} }) => {
//     switch (type) {
//         case T.SET_CUSTOMLIST_SUBJECT_TABLESOURCE:
//             return { ...requestProps, ...payload };
//         default:
//             return state;
//     }
// };

export const customListAdvertTable = (state = [], { type, payload, requestProps = {} }) => {
    switch (type) {
        case T.SET_CUSTOMLIST_ADVERT_TABLESOURCE:
            return { ...requestProps, ...payload };
        default:
            return state;
    }
};