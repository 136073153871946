import { createModel } from '@/store/tools';

const initTableData = { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 };

const model = {
    namespace: 'financechannel',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getAdvanceTypeEnum': T('getAdvanceTypeEnum'),
            'getAdvanceTableData': T('getAdvanceTableData'),
            'getAdvanceDetailData': T('getAdvanceDetailData'),
            'getCreditTypeEnum': T('getCreditTypeEnum'),
            'getCreditTableData': T('getCreditTableData'),
            'getCreditDetailData': T('getCreditDetailData'),
        },
        actions: {
            'getAdvanceTypeEnum': A('getAdvanceTypeEnum'),
            'getAdvanceTableData': A('getAdvanceTableData'),
            'getAdvanceDetailData': A('getAdvanceDetailData'),
            'getCreditTypeEnum': A('getCreditTypeEnum'),
            'getCreditTableData': A('getCreditTableData'),
            'getCreditDetailData': A('getCreditDetailData'),
        },
        sagas: {
            'getAdvanceTypeEnum': S('getAdvanceTypeEnum', '/supplier/advances/operateType'),
            'getAdvanceTableData': S('getAdvanceTableData', '/supplier/advances/findPage'),
            'getAdvanceDetailData': S('getAdvanceDetailData', '/supplier/advances/item/findPage'),
            'getCreditTypeEnum': S('getCreditTypeEnum', '/supplier/credit/operateType'),
            'getCreditTableData': S('getCreditTableData', '/supplier/credit/findPage'),
            'getCreditDetailData': S('getCreditDetailData', '/supplier/credit/item/findPage'),
        },
        reducers: {
            'advanceTypeEnum': R('getAdvanceTypeEnum', []),
            'advanceTableData': R('getAdvanceTableData', initTableData),
            'advanceDetailData': R('getAdvanceDetailData', initTableData),
            'creditTypeEnum': R('getCreditTypeEnum', []),
            'creditTableData': R('getCreditTableData', initTableData),
            'creditDetailData': R('getCreditDetailData', initTableData),
        }
    })
};
export default createModel(model);