// 计费分配比例列表管理
export const GET_ALLOCATION_LIST = 'ALLOCATION/GET_ALLOCATION_LIST';
export const SET_ALLOCATION_LIST = 'ALLOCATION/SET_ALLOCATION_LIST';
// 计费分配比例详情
export const GET_ALLOCATION_DETAIL = 'ALLOCATION/GET_ALLOCATION_DETAIL';
export const SET_ALLOCATION_DETAIL = 'ALLOCATION/SET_ALLOCATION_DETAIL';
// 获取设置项
export const GET_SETTING_CODE = 'ALLOCATION/GET_SETTING_CODE';
export const SET_SETTING_CODE = 'ALLOCATION/SET_SETTING_CODE';

