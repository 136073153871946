/**
 * 礼物分类创建或编辑
 */
import React from 'react';
import { Modal, Radio, Icon, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './GiftModal.module.scss';
import { XInput, XUpload } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;
class GiftModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,

            typeInput: this.props.data === undefined ? '' : this.props.data.className, // 分类名称
            radioValue: this.props.data === undefined ? 1 : this.props.data.state, // 状态
            singleImage: this.props.data === undefined ? '' : this.props.data.img, // 分类图标,判断图片是否有值 赋值url

            id: this.props.data === undefined ? '' : this.props.data.id,
            classTypeCode: this.props.data === undefined ? this.props.modetext : this.props.data.classTypeCode,
            singleImages: [],//图片上传
            inputstate: this.props.data === undefined ? '' : this.props.data.sortNo,//排序
        };

        const { singleImage } = this.state;

        if (singleImage) {//在编辑状态中提换default图标
            this.state.singleImages = [{
                uid: '0',
                name: 'default',
                status: 'done',
                url: singleImage,
            }];
        }
    }

    _handleOk = () => {

        const { data } = this.props;
        const { radioValue, typeInput, id, classTypeCode, singleImage, inputstate, } = this.state;


        const method = data === undefined ? 'insert' : 'update';
        let starInputReg = /^[1-9]\d*$/;
        if (typeInput == '') {
            message.warn('请输入分类名称。');
        } else if (data === undefined ? singleImage === undefined || singleImage == '' : singleImage === undefined) {
            message.warn('请输入分类图标。');
        }
        // else if (inputstate == '' || !starInputReg.test(inputstate)) {
        //     message.warn('请输入排序（整数）。');
        // } 
        else {
            this.setState({ confirmLoading: true }, () => {
                http(`/show/showClassData/${method}`, {
                    className: typeInput,
                    state: radioValue,
                    img: singleImage,
                    // sortNo: inputstate,
                    id,
                    classTypeCode,
                }, 'POST').then(() => {
                    data === undefined ? message.success('创建成功。') : message.success('更新成功。');
                    this.setState({
                        visible: false,
                        confirmLoading: false
                    });
                    this.props.closeMode(true);
                }).catch((res) => {
                    this.setState({ confirmLoading: false });
                    if (res.code == '500') {
                        message.warn(res.message);
                    }
                });
            });
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // Radio状态改变时触发
    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }

    // 分类名称输出框改变时触发
    _inputChangeHandle = (value) => {
        this.setState({ typeInput: value });
    }


    render() {
        const { visible, confirmLoading, title, radioValue, typeInput, singleImages, inputstate } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '408px' }}
                        inputStyle={{ width: '340px' }}
                        label='分类名称'
                        placeholder='请输入分类名称'
                        onChange={this._inputChangeHandle}
                        value={typeInput}
                    />
                    <div className={styles.radio}>
                        <div className={styles.label}>状态</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={radioValue}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.upload}>
                        <div className={styles.label}>分类图标</div>
                        <div className={styles.icon}>
                            <XUpload
                                uploadButton="singleImg"
                                handleChange={(file) => {
                                    if (file.status === 'done') {
                                        this.setState({ singleImage: file.qiniuInfo.key });
                                    }
                                    if (file.status === 'removed') {
                                        this.setState({
                                            singleImage: undefined,
                                            singleImages: [],
                                        });
                                    }
                                }}
                                antdUploadProps={{
                                    listType: 'picture-card',
                                    showUploadList: false,
                                    defaultFileList: singleImages
                                }}
                            />
                        </div>
                    </div>
                    {/* <XInput
                        style={{ width: '382px', marginLeft: '26px' }}
                        inputStyle={{ width: '340px' }}
                        label='排序'
                        placeholder='请输入序号'
                        value={inputstate}
                        bindThis={this}
                        bindName='inputstate'
                    /> */}
                </div>
            </Modal>
        );

    }
}

GiftModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
    modetext: PropTypes.string,//区分魅力值与人气值 分类编码
};

export default GiftModal;