import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

class AllDataToTable extends React.Component {

    state = {
        pageNum: 1
    }

    render() {

        const { pageSize, dataSource, columns, ...otherProps } = this.props;

        return (
            <Table
                {...otherProps}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    current: this.state.pageNum,
                    pageSize,
                    total: dataSource.length,
                    onChange: (pageNum) => this.setState({ pageNum })
                }}
            />
        );
    }
}

AllDataToTable.propTypes = {
    columns: PropTypes.array,
    pageSize: PropTypes.number,
    dataSource: PropTypes.array
};

export default AllDataToTable;