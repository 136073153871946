/**
 * [小票类型]
 * @author: Fu Xiaochun
 * @date: 2022-08-12 
 */

import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Table, message, Switch, Button, } from 'antd';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import http from '@/assets/api/http';
import model from '@/reducers/systemmanage/printersetup/model';

function ReceiptType(props){
    
    useEffect(()=>{
        getList();
    }, []);

    const getList = ()=>{
        props.getReceiptTypes();
    };

    const switchCheck=(item)=>{
        let params = {
            id: item.id,
            status: item.status === 'ON' ? 'OFF': 'ON'
        };
        http('/printer/switchTicketTypeStatus', params).then(res=>{
            getList();
        }).catch(err=>{
            message.error(err.message);
        });
    };

    const renderStatus = (val, item)=>{
        return <Switch checked={item.status === 'ON'} onChange={()=>{switchCheck(item);}} />;
    };

    const columns = [
        {
            title: '小票类型',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'left',
            render: renderStatus,
        }
    ];

    return(
        <div className={styles.tableList}>
            <Table
                rowKey='id'
                columns={columns}
                dataSource={props.receiptTypes}
                pagination={false}
                scroll={{ y: true }}
                />
        </div>
    );
}

ReceiptType.propTypes = {
    history: PropTypes.object, // 路由history对象
    receiptTypes: PropTypes.array,
    getReceiptTypes: PropTypes.func
};

const mapStateToProps = (state) => ({
    receiptTypes: state.printersetup.cloudPrinterReceiptTypes,
});

export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(ReceiptType);