/**
 *  区域公司订单收入统计
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './main';

const CompanyIncome = ({ history, match }) => {
    return <Main history={history} match={match} />;
};

CompanyIncome.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object
};

export default CompanyIncome;