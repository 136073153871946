/**
 * 银联入网查询
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import model from '@/reducers/businessmanage/merchantunion/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import DownloadList from './modal/downloadList';
import { message } from 'antd';
import http from '@/assets/api/http';
import PreAPP from '../detail/modal/PreAPP';

class Main extends React.Component {

    state = {
        merchantName: '', // 商户名称
        merchantLoginName: '', // 商户账号
        mType: {}, // 商户类型
        mStatus: {}, // 状态
        startDate: undefined, // 开始时间
        endDate: undefined, // 结束时间
        selectedRowKeys: [], // 复选框勾选
        currentOpenMode: '', // 当前打开的弹窗
        exportInfoBtnLoading: false, // 导出信息按钮加载状态
        exportPDFBtnLoading: false, // 导出PDF按钮加载状态
        resetBtnLoading: false, // 批量驳回按钮加载状态   
        modeParams: {}
    }

    componentDidMount() {
        this.props.getMerchantType();
        this.props.getMerchantState();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, merchantName, merchantLoginName, mType, mStatus, startDate, endDate } = this.state;
        const query = {
            merchantName,
            merchantLoginName,
            merchantType: mType.code ? mType.code : undefined,
            status: mStatus.code ? mStatus.code : undefined,
            startDate: startDate ? startDate : undefined,
            endDate: endDate ? endDate : undefined, subjectType: 'MERCHANT',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getUnionUserList(query);
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ merchantName: '', merchantLoginName: '', mType: {}, mStatus: {}, startDate: undefined, endDate: undefined }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (id, type, item) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: `/home/businessmanage/merchantUnion/detail/${id}/${item.accessType}`
                });
                history.push({
                    pathname: `/home/businessmanage/merchantUnion/detail/${id}/${item.accessType}`,
                });
                break;
            case 'batchData':
                this.setState({ selectedRowKeys: id }); // 选中复选框
                break;
            case 'reset':
                this._resetUnion([id]); // 单个驳回
                break;
            case 'APPImg':
                this.setState({ currentOpenMode: 'preAPP', modeParams: { companyName: item.merchantName, id: item.merchantId } });
                break;
            case 'update':
                http('/unionUserInfoQuery/applyStatus', { merchantId: id }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('刷新成功');
                        this._searchHandle('useCache');
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
        }
    }

    // 每页pageSize条，当前第pageNum页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    //打开弹窗
    _renderMode = () => {
        const { currentOpenMode, modeParams } = this.state;
        switch (currentOpenMode) {
            case 'downloadList':
                return <DownloadList closeMode={this._closeMode} />;
            case 'preAPP':
                return <PreAPP modeParams={modeParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', modeParams: {} }, () => {
            this._searchHandle('useCache');
        });
    }

    // 导出信息
    _exportInfo = () => {
        const { selectedRowKeys, pageNum, pageSize, merchantName, merchantLoginName, mType, mStatus, startDate, endDate } = this.state;
        const query = {
            merchantIdList: selectedRowKeys,
            merchantName,
            merchantLoginName,
            merchantType: mType.code ? mType.code : undefined,
            status: mStatus.code ? mStatus.code : undefined,
            startDate: startDate ? startDate : undefined,
            endDate: endDate ? endDate : undefined, subjectType: 'MERCHANT',
            pageNum: pageNum ? pageNum : 30, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: pageSize ? pageSize : 1, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.setState({ exportInfoBtnLoading: true });
        http('/unionUserInfoQuery/exportExcel', query, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('导出成功');
                try {
                    window.open(response.result);
                } catch (e) {
                    message.error('弹出窗口被拦截，请点击地址栏下”允许阻止的内容“设置');
                }
            }
            this.setState({ exportInfoBtnLoading: false });
        }).catch((e) => {
            message.error(e.message);
            this.setState({ exportInfoBtnLoading: false });
        });
    }

    // 导出协议
    _exportPDF = () => {
        const { selectedRowKeys } = this.state;
        let query = {
            merchantIdList: []
        };
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            query.merchantIdList = selectedRowKeys;
        }
        if (query.merchantIdList.length <= 0) {
            message.warning('请至少勾选一个商户');
            return;
        }

        this.setState({ exportPDFBtnLoading: true });
        http('/unionUserInfoQuery/exportAgreement', query, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('操作成功，正在导出中');
            }
            this.setState({ exportPDFBtnLoading: false });
        }).catch((e) => {
            message.error(e.message);
            this.setState({ exportPDFBtnLoading: false });
        });
    }

    // 驳回
    _resetUnion = (ids) => {
        if (ids.length <= 0) {
            message.warning('请至少勾选一个商户');
            return;
        }

        this.setState({ resetBtnLoading: true });
        http('/unionUserInfoQuery/reset', { subjectIds: ids }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('操作成功');
                this._searchHandle('useCache');
            }
            this.setState({ resetBtnLoading: false });
        }).catch((e) => {
            message.error(e.message);
            this.setState({ resetBtnLoading: false });
        });
    }

    render() {
        const { unionUserList, merchantType, merchantState } = this.props;
        const { merchantName, merchantLoginName, mType, mStatus, startDate, endDate } = this.state;
        console.log(unionUserList);

        return (
            <KeepAlive id='unionpay' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商户名称'
                                placeholder='请输入商户名称'
                                value={merchantName}
                                bindThis={this}
                                bindName='merchantName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='商户账号'
                                placeholder='请输入商户账号'
                                value={merchantLoginName}
                                bindThis={this}
                                bindName='merchantLoginName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户类型'
                                placeholder='请选择商户类型'
                                renderData={merchantType}
                                dataIndex='value'
                                keyIndex='code'
                                value={mType.value}
                                bindThis={this}
                                bindName='mType'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={merchantState}
                                dataIndex='value'
                                keyIndex='code'
                                value={mStatus.value}
                                bindThis={this}
                                bindName='mStatus'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='提交时间'
                                placeholder='请选择开始日期'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                label=''
                                placeholder='请选择结束日期'
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton loading={this.state.exportInfoBtnLoading} style={{ marginLeft: '0px', marginRight: '20px', width: '110px' }} label='导出信息' onClick={this._exportInfo} />
                        <XOKButton loading={this.state.exportPDFBtnLoading} style={{ marginRight: '20px', width: '110px' }} label='导出协议' onClick={this._exportPDF} />
                        <XOKButton style={{ marginLeft: '0px', marginRight: '20px', width: '110px' }} label='批量驳回' onClick={() => { this._resetUnion(this.state.selectedRowKeys); }} />
                        <a onClick={() => { this.setState({ currentOpenMode: 'downloadList' }); }}>协议下载列表</a>
                    </div>
                    <TableContent
                        renderData={unionUserList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getUnionUserList: PropTypes.func, // 银联用户入网列表
    unionUserList: PropTypes.object,
    getMerchantType: PropTypes.func, // 获取商户类型
    merchantType: PropTypes.array,
    getMerchantState: PropTypes.func, // 获取商户状态
    merchantState: PropTypes.array
};

const mapStateToProps = (state) => ({
    unionUserList: state.merchantUnion.getUnionUserList,
    merchantType: state.merchantUnion.getMerchantType,
    merchantState: state.merchantUnion.getMerchantState,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);