/**
 * 【服务订单页】
 */
import React from 'react';
import TableContent from './components/TableContent';
import PropTypes from 'prop-types';
import styles from './main.module.scss';
import { connect } from 'react-redux';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/ordermanage/serviceOrder/model';
import { getPaymentAccessTypeEnum, getPaymentTypeEnum } from '@/reducers/ordermanage/productOrder/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { XInput, XOKButton, XCancelButton, XDatePicker, XInputNum, XSelect } from '@/components/xqxc_ui';

class Main extends React.Component {
    state = {
        accountTel: '',
        orderId: '',
        gmtCreateF: undefined,
        gmtCreateT: undefined,
        payType: {}, // 支付方式
        payAccessType: {}, // 支付通道
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
         // 支付通道
         this.props.getPaymentAccessTypeEnum();
         // 支付方式
         this.props.getPaymentTypeEnum();
    }
    // 表格操作
    _tableAction = (key, type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            // 查看详情
            case 'info':
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: {
                        pathname: '/home/ordermanage/valueserviceorder/serviceorder/orderdetail',
                        search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}&&curTab=2`,
                    }
                });
                history.push({
                    pathname: '/home/ordermanage/valueserviceorder/serviceorder/orderdetail',
                    search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}&&curTab=2`,
                });
                break;
            // 操作记录
            case 'history':
                addOneBreadcrumbPath({
                    title: '历史操作记录',
                    path: {
                        pathname: '/home/ordermanage/valueserviceorder/orderhistory',
                        search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}&&curTab=2`,
                    }
                });
                history.push({
                    pathname: '/home/ordermanage/valueserviceorder/orderhistory',
                    search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}&&curTab=2`,
                });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { payType, payAccessType, pageNum, pageSize, accountTel, orderId, gmtCreateF, gmtCreateT } = this.state;
        const query = {
            accountTel,
            orderId,
            gmtCreateF,
            gmtCreateT,
            payAccessType: payAccessType.code,
            payType: payType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getOrderList(query);
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({  payType: {},  payAccessType: {}, gmtCreateF: undefined, gmtCreateT: undefined, accountTel: '', orderId: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    render() {
        const { orderList, paymentAccessTypeEnum, paymentTypeEnum } = this.props;
        const { accountTel, orderId, gmtCreateF, gmtCreateT, payType, payAccessType } = this.state;

        return (
            <KeepAlive id='3' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='会员手机'
                                placeholder='请输入手机号码'
                                value={accountTel}
                                bindThis={this}
                                bindName='accountTel'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInputNum
                                label='订单编号'
                                placeholder='请输入订单编号'
                                value={orderId}
                                bindThis={this}
                                bindName='orderId'
                                min={0}
                                precision={0}
                                maxLength={16}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='支付通道'
                                placeholder='请选择支付通道'
                                renderData={paymentAccessTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={payAccessType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='payAccessType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='支付方式'
                                placeholder='请选择支付方式'
                                renderData={paymentTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={payType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='payType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='下单时间'
                                value={gmtCreateF}
                                bindThis={this}
                                bindName='gmtCreateF'
                                isFormat
                            />
                            <div className={styles.timeLine} />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                label=''
                                value={gmtCreateT}
                                bindThis={this}
                                bindName='gmtCreateT'
                                isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent renderData={orderList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive >
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getOrderList: PropTypes.func,
    orderList: PropTypes.object,
    getPaymentAccessTypeEnum: PropTypes.func,
    paymentAccessTypeEnum: PropTypes.array,
    getPaymentTypeEnum: PropTypes.func,
    paymentTypeEnum: PropTypes.array
};

const mapStateToProps = (state) => ({
    orderList: state.serviceOrder.getOrderList,
    paymentAccessTypeEnum: state.productOrder.paymentAccessTypeEnum,
    paymentTypeEnum: state.productOrder.paymentTypeEnum
});

export default connect(mapStateToProps, { ...model.actions, getPaymentAccessTypeEnum, getPaymentTypeEnum, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);
