import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { message } from 'antd';
import styles from './index.module.scss';
import { XOKButton, showConfirm } from '@/components/xqxc_ui';
import TableData from './components/TableData';
import CreateEdit from '../../modal/CreateEdit';
import model from '@/reducers/productmanage/consumerknows/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends Component {

    static propTypes = {
        getlistGoodsComsumerRights: PropTypes.func,
        listGoodsComsumerRights: PropTypes.array
    }

    state = {
        currentOpenMode: false, // 当前需要打开的弹窗
        explainItem: {}, // 当前需要打开的弹窗的入参
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize } = this.state;
        const query = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getlistGoodsComsumerRights(query);
    }

    // 表格操作
    _tableAction = (id, type) => {
        switch (type) {
            case 'edit':
                this.setState({ currentOpenMode: true, explainItem: { url: '/goods/comsumerRights/updateGoodsComsumerRights', id } });
                break;
            case 'delete':
                showConfirm('确定要删除此信息吗？', '您还要继续吗？', () => {
                    http('/goods/comsumerRights/deleteGoodsComsumerRights', { id }, 'POST')
                        .then(res => {
                            message.success(res.message);
                            this._searchHandle('useCache');
                        }).catch(err => { message.error(err.message); });
                });
                break;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: false, explainItem: {} }, () => {
            if (update) this._searchHandle('useCache');
        });
    }

    render() {
        const { listGoodsComsumerRights } = this.props;
        const { currentOpenMode, explainItem } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '180px' }}
                            label='创建消费者权益说明'
                            onClick={() => this.setState({ currentOpenMode: true, explainItem: { url: '/goods/comsumerRights/createGoodsComsumerRights' } })} />
                    </div>
                    <TableData renderData={listGoodsComsumerRights} tableAction={this._tableAction} />
                    {
                        currentOpenMode && <CreateEdit explainItem={explainItem} closeMode={this._closeMode} />
                    }
                </div>
            </KeepAlive>
        );
    }
}

export default connect(state => ({
    listGoodsComsumerRights: state.consumerknows.getlistGoodsComsumerRights
}), { ...model.actions })(Main);