import React from 'react';
import styles from './TableData.module.scss';
import { Switch, Icon, Input } from 'antd';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
  {
    title: '原因标题',
    dataIndex: 'reasonTitle',
    key: 'reasonTitle',
    width: '18%',
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '操作人',
    dataIndex: 'operateUserName',
    key: 'operateUserName',
    width: '18%',
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '状态',
    dataIndex: 'state',
    key: 'state',
    width: '18%',
    align: 'center',
    render: (text, item) => (SwitchCell(text, item, actionClickHandle))
  },
  {
    title: '排序',
    dataIndex: 'sortNo',
    key: 'sortNo',
    width: '18%',
    align: 'center',
    render: (text, item) => (SortCell(text, item, actionClickHandle))
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    width: '10%',
    render: (key, item) => TableAction(item.id, actionClickHandle)
  },
];

export const takeDataSource = (data) => {
  return data;
};

export const takeColumns = (actionClickHandle, reasonType) => {
  let data = columns(actionClickHandle);
  let cancelData = data.filter(i => i.key !== 'responsibilityName');
  return reasonType !== 'CANCEL_REASON' ? columns(actionClickHandle) : cancelData;
};

// 表格项内容为可编辑
const SortCell = (value, item, actionClickHandle) => {
  const label = React.createRef();
  const box = React.createRef();
  const input = React.createRef();

  return (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
      <div className={styles.sort_box}>
        <div className={styles.label} ref={label}>{value}</div>
        <div className={styles.input} ref={box}>
          <Input
            style={{ width: '50px' }}
            ref={input}
            key={item.id}
            defaultValue={value}
            onBlur={(e) => {
              box.current.style.display = 'none';
              label.current.style.display = 'block';
              if (value != e.target.value) {
                actionClickHandle(item.id, 'sort', e.target.value);
              }
            }}
          />
        </div>
        <div
          className={styles.icon}
          onClick={() => {
            label.current.style.display = 'none';
            box.current.style.display = 'block';
            input.current.focus();
          }}
        >
          <Icon type="edit" style={{ fontSize: '18px' }} />
        </div>
      </div>
    </div>
  );
};

// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
  let checked = true;
  if (sw == 0) {
    checked = false;
  }
  return (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
      <Switch
        checkedChildren="ON"
        unCheckedChildren="OFF"
        key={item.id + item['reasonState']}
        checked={checked}
        onChange={(value) => {
          actionClickHandle(item.id, 'switch', value);
        }}
      />
    </div>
  );
};

const TableCell = (text) => {
  return (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
      {text}
    </div>
  );
};

const TableAction = (id, actionClickHandle) => {
  return (
    <div className={styles.action}>
      <div className={styles.item} onClick={() => actionClickHandle(id, 'edit')}>编辑</div>
      <div className={styles.item} onClick={() => actionClickHandle(id, 'del')}>删除</div>
    </div>
  );
};