/**
 * 秀场管理 -氛围管理-氛围分类列表 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';


/**
 * 氛围管理-氛围分类列表 Saga
 */

export const atmosphereSource = function* (){//获取'秀场管理 -氛围管理-氛围分类
    yield takeEvery(T.GET_ATMOSPG_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象
        try{
            let result = yield http('/show/showClass/listAtmosphereClass', action.payload,'POST');
            yield put({type:T.SET_ATMOSPG_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};
/**
 * 氛围管理-氛围分类列表- 分类状态 Saga
 */

export const atmosphereState = function* (){//获取'秀场管理 -氛围管理-氛围分类
    yield takeEvery(T.GET_GIFTSTATE_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象
        try{
            let result = yield http('/enum/StateEnum', action.payload,'POST');
            yield put({type:T.SET_GIFTSTATE_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

/**
 * 氛围管理-氛围列表 Saga
 */

export const atmoslistSource = function* (){//获取'秀场管理 -氛围管理-氛围列表
    yield takeEvery(T.GET_MOSLIST_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象
        try{
            let result = yield http('/show/atmosphere/listAtmosphere', action.payload,'POST');
            yield put({type:T.SET_MOSLIST_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};


/**
 * 氛围管理-氛围-购买记录 Saga 
 */
export const atmosbuySource = function* (){//获取'秀场管理 -氛围管理-购买记录
    yield takeEvery(T.GET_ATMOBUY_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象
        try{
            let result = yield http('/show/rewardOrder/listRewardOrder4Atmosphere', action.payload,'POST');
            yield put({type:T.SET_ATMOBUY_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

 