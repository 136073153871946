import * as T from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const contractListTableSource = (state = { ...initData }, action) => {
    switch (action.type) {
        case T.SET_CONTRACTLIST_TABLESOURCE:
            return action.payload.result;
        default:
            return state;
    }
};

export const contractStates = (state = [], action) => {
    switch (action.type) {
        case T.SET_CONTRACTSTATES:
            return action.payload.result;
        default:
            return state;
    }
};

export const contractNames = (state = [], action) => {
    switch (action.type) {
        case T.SET_CONTRACTNAMES:
            return action.payload.result;
        default:
            return state;
    }
};