/**
 * [列表数据]
 * @author: Fu Xiaochun
 * @date: 2023-04-21 
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ClientTableData.module.scss';
import { Button, Table, Tooltip } from 'antd';
import XPagination from '@/components/Pagination';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell, priceDecimal2Cell, priceDecimal3Cell } from '@/components/TableCell';

const columns = (TableAction, actionClickHandle) => [
    {
        title: '订单编号',
        dataIndex: 'orderInfoIds',
        key: 'orderInfoIds',
        align: 'left',
        width: '120px',
        render: tooltip
    },
    {
        title: '订单类型',
        dataIndex: 'businessTypeText',
        key: 'businessTypeText',
        align: 'left',
        width: '120px',
        render: tooltip
    },
    {
        title: '买家',
        dataIndex: 'mobile',
        key: 'mobile',
        align: 'left',
        width: '120px',
        render: tooltip
    },
    {
        title: '订单金额',
        dataIndex: 'orderAmount',
        key: 'orderAmount',
        align: 'left',
        width: '120px',
        render: priceFenToYuanCell
    },
    {
        title: '转账账号',
        dataIndex: 'payVoucherNo',
        key: 'payVoucherNo',
        align: 'left',
        width: '120px',
        render: tooltip
    },
    {
        title: '订单时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        align: 'left',
        width: '150px',
        render: tooltip
    },
    {
        title: '状态',
        dataIndex: 'auditStatus',
        key: 'auditStatus',
        align: 'left',
        width: '120px',
        render: (status) => (status.value)
    },
    {
        title: '审核时间',
        dataIndex: 'auditTime',
        key: 'auditTime',
        align: 'left',
        width: '150px',
        render: tooltip
    },
    {
        title: '操作人',
        dataIndex: 'auditUserName',
        key: 'auditUserName',
        align: 'left',
        width: '150px',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        align: 'left',
        width: '100px',
        render: (id, item) => TableAction(item, actionClickHandle)
    }
];


// 表格操作
const TableAction = (item, actionClickHandle) => {
    return (
        <div className={styles.tableActionCell}>
            <div className={styles.item} onClick={() => actionClickHandle(item.goodsId, 'detail', item)}>详情</div>
            {item.auditStatus.code == 'NOT_AUDIT' && <div className={styles.item} onClick={() => actionClickHandle(item.goodsId, 'audit', item)}>审核</div>}
        </div>
    );
};

class TableData extends Component {
    constructor(props) {
        super(props);
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { renderData } = this.props;
        renderData.dataList = renderData.dataList.map((item) => {
            item.orderInfoIds = Array.isArray(item.orderInfoIds) ? item.orderInfoIds.join(',') : '';
            return item;
        });

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowKey='id'
                    columns={columns(TableAction, this._actionHandle)}
                    pagination={false}
                    dataSource={renderData.dataList}
                    scroll={{ x: 1000, y: true }}
                />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={this.props.paginationChange}
                />
            </div>
        );
    }
}

TableData.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
};

export default TableData;