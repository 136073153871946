import { createModel } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };

const model = {
    namespace: 'exchangemanage',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getExchangeOrderList': T('getExchangeOrderList'),
            'getExchangeOrderDetail': T('getExchangeOrderDetail'),
            'getStatusEnum': T('getStatusEnum'),

            'getProfitStatusEnum': T('getProfitStatusEnum'),
            'getVoucherSourceEnum': T('getVoucherSourceEnum'),
            'getExchangeProfitList': T('getExchangeProfitList'),
            'getExchangeProfitDetail': T('getExchangeProfitDetail'),
        },
        actions: {
            'getExchangeOrderList': A('getExchangeOrderList'),
            'getExchangeOrderDetail': A('getExchangeOrderDetail'),
            'getStatusEnum': A('getStatusEnum'),

            'getProfitStatusEnum': A('getProfitStatusEnum'),
            'getVoucherSourceEnum': A('getVoucherSourceEnum'),
            'getExchangeProfitList': A('getExchangeProfitList'),
            'getExchangeProfitDetail': A('getExchangeProfitDetail'),
        },
        sagas: {
            'getExchangeOrderList': S('getExchangeOrderList', '/exchangeOrder/list'),
            'getExchangeOrderDetail': S('getExchangeOrderDetail', '/exchangeOrder/detail'),
            'getStatusEnum': S('getStatusEnum', '/enum/ExchangeStateEnum'),

            'getProfitStatusEnum': S('getProfitStatusEnum', '/enum/VoucherStatusEnum'),
            'getVoucherSourceEnum': S('getVoucherSourceEnum', '/enum/VoucherSourceEnum'),
            'getExchangeProfitList': S('getExchangeProfitList', '/integralExchangeVoucher/list'),
            'getExchangeProfitDetail': S('getExchangeProfitDetail', '/integralExchangeVoucher/details'),
        },
        reducers: {
            'ExchangeOrderList': R('getExchangeOrderList', dataList),
            'ExchangeOrderDetail': R('getExchangeOrderDetail', { orderState: {}, payType: {}, exchangeState: {} }),
            'statusEnum': R('getStatusEnum', []),

            'profitStatusEnum': R('getProfitStatusEnum', []),
            'voucherSourceEnum': R('getVoucherSourceEnum', []),
            'exchangeProfitList': R('getExchangeProfitList', dataList),
            'exchangeProfitDetail': R('getExchangeProfitDetail', { exchangeState: {} }),
        }
    })
};
export default createModel(model);