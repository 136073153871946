/**
 * 取消打款 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, Input, Form, message } from 'antd';
import styles from './ConfirBill.module.scss';

const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    }
};
class NoLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false
        };
    }

    state = {}

    // 点击确认
    _handleOk = () => {
        const { form: { validateFields } = {}, closeMode, data } = this.props;
        validateFields((err, fieldsValue) => {
            if (!err) {
                this.setState({ confirmLoading: true });
                http('/settle/paymentApplication/cancelUnitPayment', {
                    ...fieldsValue,
                    paymentApplicationIds: Array.isArray(data) ? data.map(item => item.id) : [data]
                }, 'POST')
                    .then((res) => {
                        if (res.code === '200') {
                            message.success('取消成功');
                        }
                    })
                    .catch((res = {}) => message.error(res.message || '操作异常'))
                    .finally(() => {
                        this.setState({ confirmLoading: false });
                        closeMode(true);
                    });
            }
        });

    };

    // 点击取消
    _handleCancel = () => {
        this.setState({ visible: false });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={800}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div style={{ fontSize: 18, marginBottom: 15 }}>确认取消付款，对应发票申请需要重新申请付款</div>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit}>
                        <Form.Item label='备注'>
                            {getFieldDecorator('remark')(<TextArea rows={4} placeholder='请输入' />)}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

NoLogin.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]), // 弹框入参
    form: PropTypes.object.isRequired,
    getListAreaByParentId: PropTypes.func, // 请求地区信息
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

const ForgotProducDetails = Form.create({ name: 'create-form' })(NoLogin);// 校验
export default connect(null, null)(ForgotProducDetails);