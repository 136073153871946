/**
 *  计费分配比例设置
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, message, } from 'antd';
import http from '@/assets/api/http';
import { SearchBox, SearchItem } from '@/components/Layout';
import CreateModal from '../../modal/CreateModal';
import EditModal from '../../modal/EditModal';
import PlatModal from '../../modal/PlatModal';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, showConfirm, } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import { getAllocationList, getSettingCode } from '@/reducers/systemmanage/allocation/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

const statusArr = [{
    id: 'INIT',
    value: '新建 ',
}, {
    id: 'CONFIRM_ING',
    value: '审核中',
}, {
    id: 'CONFIRM_SUCCESS',
    value: '审核通过',
}, {
    id: 'CONFIRM_FAIL',
    value: '审核不通过',
}];
class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectDataSource: [],//模拟下拉列数据
            goodsDataSource: [],// 模拟表格数据
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            setting: {},
            status: {},
            pageSize: 10,//默认
            pageNum: 1,//默认
        };
    }

    componentDidMount() {
        // this.props.getAllocationList();
        this.props.getSettingCode();
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum });
        const { status, setting } = this.state;
        this.searchCache = {
            status: status.id,
            name: setting.name,
            pageSize,
            pageNum,
        };
        this.props.getAllocationList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum, pageSize }); // 【第3步，缓存查询状态】
    }


    // 查询触发
    _searchHandle = (useCache) => {
        const { status, setting, pageSize, pageNum } = this.state;
        this.searchCache = {
            status: status.id,
            name: setting.name,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAllocationList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发  
    _saleresetHandle = () => {
        this.setState({ status: {}, setting: {}, pageSize: 10, pageNum: 1 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        // this.props.getAllocationList();
    }

    // 新建按钮被点击
    _newCreateHandle = () => {
        this.setState({
            currentOpenMode: 'create',
        });
    }

    //设置平台计费参数
    _setPlatParam = () => {
        this.setState({
            currentOpenMode: 'plat',
        });
    }

    // 表格操作功能回调
    _actionClickHandle = (id, type, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'edit'://编辑
                this.setState({
                    currentOpenMode: 'edit',
                    currentOpenId: id,
                    openModeParam: {
                        id,
                    }
                });
                break;
            case 'submitAudit'://提交审核
                showConfirm('您是否要提交审核', '', () => {
                    http('/settle/ratioSetting/start', { ratioSettingId: id }, 'POST').then(() => {
                        this._searchHandle('useCache');
                        message.success('提交成功');
                    }).catch((reject) => {
                        message.error(reject.message);
                    });
                }, () => { });
                break;
            case 'audit'://审核
                addOneBreadcrumbPath({
                    title: '计费比列审核',
                    path: `/home/systemmanage/allocation/auditInfo/${id}`
                });
                history.push({
                    pathname: `/home/systemmanage/allocation/auditInfo/${id}`
                });
                break;
            case 'details'://详情
                addOneBreadcrumbPath({
                    title: '计费比列详情',
                    path: `/home/systemmanage/allocation/detailInfo/${id}`
                });
                history.push({
                    pathname: `/home/systemmanage/allocation/detailInfo/${id}`
                });
                break;
            case 'switch'://状态修改
                http('/settle/ratioSetting/disableOrEnable', { ratioSettingId: id, enabled: value ? 1 : 0 }, 'POST').then(() => {
                    this._searchHandle('useCache');
                    message.success('状态修改成功');
                }).catch((reject) => {
                    message.error(reject.message);
                });
                break;
            default: return null;
        }

    }


    // 打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'create':
                return <CreateModal title='新建计费比例设置' refresh={this._searchHandle} visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'edit':
                return <EditModal title='编辑计费比例设置' refresh={this._searchHandle} visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'plat':
                return <PlatModal title='开启平台收费设置' refresh={this._searchHandle} visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }



    render() {
        const { status, setting } = this.state;
        const { settingCode: { ratioSettingCodes = [] }, allocationList: { dataList, resultTotal, pageSize, pageNum } } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XSelect
                                label='设置项'
                                placeholder='请选择'
                                renderData={ratioSettingCodes}
                                dataIndex='name'
                                keyIndex='code'
                                value={setting.name}
                                showSearch={true}
                                bindThis={this}
                                bindName='setting'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={statusArr}
                                dataIndex='value'
                                keyIndex='id'
                                value={status.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='status'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._saleresetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '90px', }} label='新建' onClick={this._newCreateHandle} />
                        <XOKButton style={{ width: '145px', marginLeft: '20px' }} label='设置平台计费参数' onClick={this._setPlatParam} />
                    </div>
                    <div className={`${styles.tableContainer}`}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowSelection={this._rowSelection}
                            rowKey={(record, index) => index}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}
Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getAllocationList: PropTypes.func,
    allocationList: PropTypes.object,
    getSettingCode: PropTypes.func,
    settingCode: PropTypes.object,
};


const mapStateToProps = (state) => {
    return {
        allocationList: state.Allocation.allocationList,
        settingCode: state.Allocation.settingCode,
    };
};

export default connect(mapStateToProps, { getAllocationList, addOneBreadcrumbPath, getSettingCode })(Main);