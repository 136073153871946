/**
 * 广告策略
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Advert from './actiontypes';

export const getAdvertStrategList = function* (){
    yield takeEvery(Advert.GET_STRATEG_LIST, function* requestData(action){
        try{
            let result = yield http('/adStrategy/listByPage', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Advert.SET_STRATEG_LIST, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

export const getAdvertStrategStartAndStop = function* (){
    yield takeEvery(Advert.GET_STRATEG_STARTANDSTOP, function* requestData(action){
        try{
            let result = yield http('/adStrategy/updateState', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Advert.SET_STRATEG_STARTANDSTOP, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

export const getAdvertStrategDetail = function* (){
    yield takeEvery(Advert.GET_STRATEG_DETAIL, function* requestData(action){
        try{
             if (action.payload.adStrategyId) {
                let result = yield http('/adStrategy/getStrategyDetail', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Advert.SET_STRATEG_DETAIL, payload: result});
             } else {
                yield put({type:Advert.SET_STRATEG_DETAIL, payload: {}});
             }
             
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

export const getAdvertStrategDelete = function* (){
    yield takeEvery(Advert.GET_STRATEG_DELETE, function* requestData(action){
        try{
            let result = yield http('/adStrategy/delete', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Advert.SET_STRATEG_DELETE, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};
export const getAdvertStrategAdd = function* (){
    yield takeEvery(Advert.GET_STRATEG_ADD, function* requestData(action){
        try{
            let result = yield http('/adStrategy/add', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Advert.SET_STRATEG_ADD, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};
export const getAdvertStrategEdit = function* (){
    yield takeEvery(Advert.GET_STRATEG_EDIT, function* requestData(action){
        try{
            let result = yield http('/adStrategy/update', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Advert.SET_STRATEG_EDIT, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};
export const getAdvertStrategAdsList = function* (){
    yield takeEvery(Advert.GET_STRATEG_ADSLIST, function* requestData(action){
        try{
            let result = yield http('/adspace/listMap', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Advert.SET_STRATEG_ADSLIST, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};
