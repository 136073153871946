/**
 * 【商户对账列表】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XSelect, XOKButton, XCancelButton, showConfirm, XDatePicker, XTabNav } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import {
    getMerchantSelectSource,
    getStateSelectSource,
    getMerchantReconciliationTableSource,
    getMerchantSelectSourceV1
} from '@/reducers/storehousefinancialmanage/merchantreconciliation/actions';
import model from '@/reducers/storehousefinancialmanage/merchantreconciliation/hotelReconciliationModel';
import AdvertTable from './components/AdvertTable';
import ConfirBill from './modal/ConfirBill';
import GenerateStatement from './modal/GenerateStatement';
import { message } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import http from '@/assets/api/http';

import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
class Main extends React.Component {

    state = {
        chargeValue: [],
        status: {},
        merchant: {},
        selectedRowKeys: [],
        startDate: undefined,
        endDate: undefined,
        reconciliationId: undefined
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getStateSelectSource();
        // this.props.getMerchantSelectSource();
        this.props.getMerchantSelectSourceV1({ merchantTypeList: ['HOTEL'] });
    }
    // 查询
    _searchHandle = (useCache) => {
        const { reconciliationId, status, merchant, startDate, endDate, pageNum, pageSize } = this.state;
        this.searchCache = {
            startDate,
            endDate,
            status: status.code,
            reconciliationId,
            targetId: merchant.merchantId,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getHotelReconciliationList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ chargeValue: [], startDate: undefined, endDate: undefined, reconciliationId: undefined, status: {}, merchant: {}, pageNum: 1, pageSize: 10 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        });
        const { reconciliationId, status, merchant, startDate, endDate } = this.state;
        this.searchCache = {
            startDate,
            endDate,
            status: status.code,
            reconciliationId,
            targetId: merchant.merchantId,

            pageNum,
            pageSize
        };
        this.props.getHotelReconciliationList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    // 表格操作
    _tableAction = (id, type, item) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                addOneBreadcrumbPath({
                    title: '对账详情',
                    path: {
                        pathname: `/home/storehousefinancialmanage/merchantreconciliation/hotel/detail/${id}`,
                        state: {
                            startDate: item.startDate,
                            endDate: item.endDate,
                            targetType: item.targetType,
                            targetId: item.targetId,
                        }
                    }
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/merchantreconciliation/hotel/detail/${id}`,
                    state: {
                        startDate: item.startDate,
                        endDate: item.endDate,
                        targetType: item.targetType,
                        targetId: item.targetId,
                    }
                });
                break;
            case 'delete':
                showConfirm('是否确定删除？', '', () => {
                    http('/settle/reconciliationInfo/delReconciliation', { reconciliationIds: [id] }, 'POST')
                        .then(() => {
                            message.success('删除成功!');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'confir':
                showConfirm('是否确定发起对账？', '', () => {
                    http('/settle/reconciliationInfo/hotelReconciliationLaunch', { reconciliationId: id }, 'POST')
                        .then(() => {
                            message.success('发起成功!');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'createinvoice':
                if (this.state.selectedRowKeys.length) {
                    if (this.state.selectedRows.filter(v => v.status !== 'CONFIRM_SUCCESS').length === 0) {
                        addOneBreadcrumbPath({
                            title: '新建发票',
                            path: {
                                pathname: `/home/storehousefinancialmanage/merchantreconciliation/createinvoice/${id}`,
                                state: {
                                    selectedRowKeys: this.state.selectedRowKeys,
                                    selectedRows: this.state.selectedRows
                                }
                            }
                        });
                        history.push({
                            pathname: `/home/storehousefinancialmanage/merchantreconciliation/createinvoice/${id}`,
                            state: {
                                selectedRowKeys: this.state.selectedRowKeys,
                                selectedRows: this.state.selectedRows
                            }
                        });
                    } else {
                        message.warning('存在未确认通过的记录');
                    }
                } else {
                    message.warning('请选择一条清单记录');
                }
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'confir':
                return <ConfirBill title='确认对账单' visible={true} closeMode={this._closeMode} data={this.state.openModeParam} />;
            case 'generate':
                return <GenerateStatement refsh={this._searchHandle} title='生成对账' merchantList={this.props.merchantSelectSourceV1} visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' });
        if (update) {
            this._searchHandle();
        }
    }

    _generate = () => {
        this.setState({ currentOpenMode: 'generate', openModeParam: {} });
    }
    onSelectionChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
    }
    _tabChangeHandle = (curTabID) => {
        this.setState({ curTabID });
    }

    render() {
        const { startDate, endDate, status, merchant, reconciliationId } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div style={{ height: 10 }}></div>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='对账单号'
                                placeholder='请输入'
                                value={reconciliationId}
                                bindThis={this}
                                bindName='reconciliationId'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户'
                                showSearch
                                placeholder='请选择商户'
                                renderData={this.props.merchantSelectSourceV1}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                value={merchant.merchantName}
                                bindThis={this}
                                bindName='merchant'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={this.props.stateSelectSource}
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                                bindThis={this}
                                bindName='status'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='对账周期'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat={true}
                            />
                            <XDatePicker
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.row2}>
                        <XOKButton
                            style={{ width: '90px', marginRight: '20px' }}
                            label='生成对账'
                            onClick={this._generate}
                        />
                    </div>
                    <AdvertTable onSelectionChange={this.onSelectionChange} renderData={this.props.hotelReconciliationList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
                {this._renderMode()}
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    getMerchantSelectSource: PropTypes.func,
    merchantSelectSource: PropTypes.array,

    getMerchantSelectSourceV1: PropTypes.func,
    merchantSelectSourceV1: PropTypes.array,

    stateSelectSource: PropTypes.array,
    getStateSelectSource: PropTypes.func,

    hotelReconciliationList: PropTypes.object,
    getHotelReconciliationList: PropTypes.func
};

const mapStateToProps = (state) => ({
    stateSelectSource: state.merchantreconciliation.stateSelectSource,
    merchantSelectSource: state.merchantreconciliation.merchantSelectSource,
    hotelReconciliationList: state.merchantreconciliation.hotelReconciliationList,
    merchantSelectSourceV1: state.merchantreconciliation.merchantSelectSourceV1,
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getMerchantSelectSource,
    getStateSelectSource,
    getMerchantSelectSourceV1,
    getMerchantReconciliationTableSource,
    ...model.actions
})(Main);