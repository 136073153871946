/**
 * 用户举报封禁弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, Input, message, Radio, Form, Checkbox, DatePicker } from 'antd';
import styles from './Close.module.scss';

import { LocaleProvider } from 'antd';
import moment from 'moment';
moment.locale('zh-cn');
import zhCN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';

const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
    // labelAlign: 'left',
};
const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
    // labelAlign: 'left',
};
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { RangePicker } = DatePicker;

class Close extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        status: '',
        reason: '',
        deadline: 'fixdate',//禁止时间
    }
    // 点击确认
    _handleOk = () => {
        this._handleSubmit();
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    _handleSubmit = e => {//表单校验 
        if (e) e.preventDefault();
        const { data, } = this.props;
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const fixdateValue = fieldsValue['fixdate']; //固定时长
                let fixdateTime = new Array();

                if (fixdateValue instanceof Array) {
                    fixdateTime = [
                        fixdateValue[0].format('YYYY-MM-DD HH:mm:ss'),
                        fixdateValue[1].format('YYYY-MM-DD HH:mm:ss'),
                    ];
                } else {
                    fixdateTime = null;
                }
                const values = {
                    ...fieldsValue,
                    'fixdate': fixdateTime,
                };
                console.log('提交', values);
                http('/user/userReport/bannedUserReport', {
                    blackTypes: values.video.join(),
                    startTime: values.fixdate != null ? fixdateTime[0] : '',
                    expectedEndTime: values.fixdate != null ? fixdateTime[1] : '',
                    blackState: values.deadline == 'fixedlength' ? 'fixedlength' : '',
                    reportTypeId: values.reason,
                    replyContent: values.statement,
                    accountId: data.record.accountId,
                    reportAccountId: data.record.reportAccountId,
                    reason: values.statement,
                    id: data.record.id,
                }, 'POST').then(() => {
                    message.success('封禁成功');
                    this.setState({
                        visible: false,
                    });
                     this.props.closeMode(true);
                }).catch((reject) => {
                    this.setState({
                        visible: false,
                    });
                    if (reject.code == '500') {
                        message.warn(reject.message);
                    }
                    this.props.closeMode();
                });
            }
        });
    };
    // 禁止时间 类型切换
    _deadline = (val) => {
        this.setState({
            deadline: val.target.value,
        });
    }

    render() {
        const { visible, confirmLoading, title, deadline, } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { data, } = this.props;
        return (
            <Modal
                width={900}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <FormItem
                            colon={false}
                            htmlFor='video'
                            label={<span></span>}
                            style={{ textAlign: 'left' }}
                            {...formItemLayout2}
                        >
                            {getFieldDecorator('video', {
                                rules: [{ required: true, message: '请输入' }],
                            })(
                                <Checkbox.Group style={{ marginLeft: 8 }}>
                                    {data.banned.result.map((item) => {
                                        return (<Checkbox key={item.code} value={item.code}>{item.value}</Checkbox>);
                                    })}
                                </Checkbox.Group>
                            )}
                        </FormItem>
                        <FormItem
                            colon={false}
                            htmlFor='deadline'
                            style={{ textAlign: 'left' }}
                            label={<span>禁止时间</span>}
                        >
                            {getFieldDecorator('deadline', {
                                rules: [{ required: true, message: '请输入有限期', whitespace: true }],
                                initialValue: deadline,
                            })(
                                <RadioGroup onChange={this._deadline}>
                                    <Radio value="fixdate">时间段禁止</Radio>
                                    <Radio value="fixedlength">永久禁止</Radio>
                                </RadioGroup>
                            )}
                        </FormItem>
                        {deadline == 'fixdate' && <div>
                            <LocaleProvider locale={zhCN}>
                                <FormItem
                                    colon={false}
                                    htmlFor='fixdate'
                                    label={<span></span>}
                                    style={{ textAlign: 'left' }}
                                >
                                    {getFieldDecorator('fixdate', {
                                        // validateTrigger: 'onSubmit',
                                        initialValue: null,
                                        rules: [{ type: 'array', required: true, message: '请输入时间段禁止' }],
                                    })(
                                        <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                                    )}
                                </FormItem>
                            </LocaleProvider>
                        </div>}
                        {/* <FormItem
                            colon={false}
                            htmlFor='forbTime'
                            label={<span>禁止时间</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('forbTime', {
                                // rules: [{ required: true, message: '请输入策略名称' }],
                            })(
                                <DatePicker showTime placeholder="禁止时间" />
                            )}
                            <span style={{ marginLeft: 6, marginRight: 6 }}>解禁时间</span>
                            {getFieldDecorator('releaseTime', {
                                // rules: [{ required: true, message: '请输入策略名称' }],
                            })(
                                <DatePicker showTime placeholder="解禁时间" />
                            )}
                            {getFieldDecorator('forb', {
                                // rules: [{ required: true, message: '请输入策略名称' }],
                            })(
                                <Radio style={{ marginLeft: 6 }}>永久禁止</Radio>
                            )}
                        </FormItem> */}
                        <FormItem
                            colon={false}
                            htmlFor='reason'
                            label={<span>封禁理由</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('reason', {
                                rules: [{ required: true, message: '请输入封禁理由' }],
                            })(
                                <Radio.Group style={{ marginLeft: 6 }}>
                                    {data.banned.bannedReason.map((item) => {
                                        return (<Radio key={item.id} value={item.id}>{item.reportTypeName}</Radio>);
                                    })}
                                </Radio.Group>
                            )}
                        </FormItem>
                        <FormItem
                            colon={false}
                            htmlFor='statement'
                            style={{ textAlign: 'left' }}
                            label={<span>封禁说明</span>}
                        >
                            {getFieldDecorator('statement', {
                                rules: [{ required: true, message: '请输入封禁说明' }],
                            })(
                                <TextArea rows={4} />
                            )}
                        </FormItem>
                    </Form>
                </div>
            </Modal>
        );
    }
}

Close.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(Close);// 校验
export default connect(null, null)(ForgotProducDetails);