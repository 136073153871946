/**
 * 消息管理 Saga集合
 */
import { getMessageCenterUnReadList, getMessageCenterReadList} from './messageCenter/saga';
import { getMessageClassifyList, getMessageClassifyDetail, getMessageClassifyLists, getMessagePushPort } from './messageClassify/saga';
import { getMessageMoveList, getMessageMoveDetail, getMessageStatus, getMessageWays, getJumpWays, getPushPeople } from './messageMove/saga';
export default [
    getMessageCenterUnReadList,
    getMessageCenterReadList,
    getMessageClassifyList,
    getMessageClassifyDetail,
    getMessageMoveList,
    getMessageMoveDetail,
    getMessageClassifyLists,
    getMessagePushPort,
    getMessageStatus,
    getMessageWays,
    getJumpWays,
    getPushPeople,
];