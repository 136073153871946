import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import styles from './index.module.scss';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { connect } from 'react-redux';

class Category extends PureComponent {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <Main />
            </div>
        );
    }
}

Category.propTypes = {
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};


export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Category);