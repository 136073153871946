import React from 'react';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import MaterialList from '../materiallist';

class Main extends React.Component {

    state = {
        tab: [
            { id: 'icon', label: '图标列表' },
            { id: 'pic', label: '图片列表' },
            { id: 'video', label: '视频列表' }
        ],
        curTabID: 'icon',
        title: '图标列表',
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID, item) => {
        this.setState({ curTabID: tabID, title: item.label });
    }

    render() {
        const { curTabID, title } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={`${styles.page} ${styles.flexBoxContainer}`}>
                    <MaterialList key={curTabID} curTabID={curTabID} title={title} />
                </div>
            </div>
        );
    }
}

export default Main;