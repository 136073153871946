/**
 * 连连支付
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import KeepAlive from '@/routes/KeepAlive';
import { message } from 'antd';
import http from '@/assets/api/http';
import TableContent from './components/TableContent';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import model from '@/reducers/storemanage/storeUnion/model';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker, XTableAdapter } from '@/components/xqxc_ui';
import DownloadList from './modal/downloadList';

class LianlianPay extends Component {

    static propTypes = {
        history: PropTypes.object,
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getHuiFuPayList: PropTypes.func,
        huiFuPayList: PropTypes.object,
        getPayStatusList: PropTypes.func,
        merchantType: PropTypes.array,
        getLianlianStatusEnum: PropTypes.func,
        payStatusList: PropTypes.array
    }

    state = {
        renderModal: false,
        merchantName: '',
        merchantLoginName: '',
        payStatus: {},
        startDate: undefined,
        endDate: undefined,
        scrollY: '',
        exportInfoBtnLoading: false, // 导出信息按钮加载状态
        exportPDFBtnLoading: false, // 导出PDF按钮加载状态
        selectedRowKeys: [],
        isImportItem: {}
    }

    componentDidMount() {
        this.props.getPayStatusList();
        this.props.getLianlianStatusEnum();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { merchantName, merchantLoginName, payStatus, startDate, endDate, pageNum, pageSize, isImportItem } = this.state;
        const query = {
            startDate, endDate,
            merchantName, merchantLoginName,
            status: payStatus.code,
            isImport: isImportItem.key,
            accessType: 'LL_PAY', subjectType: 'SHOP',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getHuiFuPayList(query);
        KeepAlive.saveCache({ ...query, payStatus, isImportItem }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ merchantName: '', merchantLoginName: '', payStatus: {}, startDate: undefined, endDate: undefined, isImportItem: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (type, item) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: `/home/storemanage/storeUnion/lianlianpaydetail/${item.merchantId}/${item.accessType}`
                });
                history.push({
                    pathname: `/home/storemanage/storeUnion/lianlianpaydetail/${item.merchantId}/${item.accessType}`,
                });
                break;
            case 'batch':
                this.setState({ selectedRowKeys: item }); // 选中复选框
                break;
            default:
                break;
        }
    }

    // 每页pageSize条，当前第pageNum页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 导出信息
    _exportInfo = () => {
        const { merchantName, selectedRowKeys, merchantLoginName, payStatus, startDate, endDate, pageNum, pageSize, isImportItem } = this.state;
        const query = {
            merchantIdList: selectedRowKeys,
            merchantName,
            merchantLoginName,
            status: payStatus.code ? payStatus.code : undefined,
            startDate: startDate ? startDate : undefined,
            endDate: endDate ? endDate : undefined,
            isImport: isImportItem.key,
            accessType: 'ADA_PAY', subjectType: 'SHOP',
            pageNum: pageNum ? pageNum : 30, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: pageSize ? pageSize : 1, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.setState({ exportInfoBtnLoading: true });
        http('/outPayaccountQuery/exportExcel', query, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('导出成功');
                try {
                    window.open(response.result);
                } catch (e) {
                    message.error('弹出窗口被拦截，请点击地址栏下”允许阻止的内容“设置');
                }
            }
            this.setState({ exportInfoBtnLoading: false, selectedRowKeys: [] });
        }).catch((e) => {
            message.error(e.message);
            this.setState({ exportInfoBtnLoading: false });
        });
    }

    // 导出协议
    _exportPDF = () => {
        const { selectedRowKeys } = this.state;
        let query = {
            merchantIdList: []
        };
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            query.merchantIdList = selectedRowKeys;
        }
        if (query.merchantIdList.length <= 0) {
            message.warning('请至少勾选一个门店');
            return;
        }

        this.setState({ exportPDFBtnLoading: true });
        http('/unionUserInfoQuery/exportAgreement', query, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('操作成功，正在导出中');
            }
            this.setState({ exportPDFBtnLoading: false, selectedRowKeys: [] });
        }).catch((e) => {
            message.error(e.message);
            this.setState({ exportPDFBtnLoading: false });
        });
    }

    _closeMode = () => {
        this.setState({ renderModal: false, selectedRowKeys: [] }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { huiFuPayList, merchantType, payStatusList } = this.props;
        const { renderModal, merchantName, merchantLoginName, merchantTypeItem, payStatus, startDate, endDate, selectedRowKeys, scrollY, isImportItem } = this.state;

        return (
            <KeepAlive id='lianlianpay' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='门店名称'
                                placeholder='请输入门店名称'
                                value={merchantName}
                                bindThis={this}
                                bindName='merchantName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='门店账号'
                                placeholder='请输入门店账号'
                                value={merchantLoginName}
                                bindThis={this}
                                bindName='merchantLoginName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={payStatusList}
                                dataIndex='value'
                                keyIndex='code'
                                value={payStatus.value}
                                bindThis={this}
                                bindName='payStatus'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='提交时间'
                                placeholder='请选择开始日期'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                label=''
                                placeholder='请选择结束日期'
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton loading={this.state.exportInfoBtnLoading} style={{ marginRight: '20px', width: '110px' }} label='导出信息' onClick={this._exportInfo} />
                        <XOKButton loading={this.state.exportPDFBtnLoading} style={{ marginRight: '20px', width: '110px' }} label='导出协议' onClick={this._exportPDF} />
                        <a onClick={() => this.setState({ renderModal: true })}>协议下载列表</a>
                    </div>
                    <XTableAdapter style={{height: '100%'}} offsetY={40} render={scrollY => this.setState({ scrollY })}>
                        <TableContent
                            renderData={huiFuPayList}
                            tableAction={this._tableAction}
                            paginationChange={this._paginationChange}
                            scrollY={scrollY}
                            batchKeys={selectedRowKeys}
                        />
                    </XTableAdapter>
                    {renderModal && <DownloadList closeMode={this._closeMode} />}
                </div>
            </KeepAlive>
        );
    }
}

export default connect(state => ({
    huiFuPayList: state.merchantUnion.huiFuPayList,
    merchantType: state.merchantUnion.getMerchantType,
    payStatusList: state.merchantUnion.payStatusList,
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(LianlianPay);