import * as T from './actiontypes';
/**
 * 封禁记录数据源
 */
const defaultState = {
    tableSource: [],// 社区、视频分类列表数据源
    selectData: {},// 封禁类型下拉选项
    paginations: {}, // 社区、视频分页对象
    userDetail: {},//详情

    livetableSource: [],// 直播列表分类列表
    livepaginations: {}, // 直播分页对象
};

/**
 * @param {*} state 
 * @param {*} action 
 */
export const forbitrecordDate = (state = defaultState, action) => {
    let newTableSource, newSelectData, newPaginations;
    switch (action.type) {
        case T.SET_GRAPHICVIDEOLIST_DATA: //社区、视频列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_LIVELIST_DATA: //直播列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, livetableSource: newTableSource, livepaginations: newPaginations };
        case T.SET_BANNEDTYPE_DATA: //封禁类型下拉框
            newSelectData = action.payload.result;
            return { ...state, selectData: newSelectData, };
        default:
            return state;
    }
};