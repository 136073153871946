import React, { Component } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { Typography, message, Spin, Progress } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { GoodsClassify } from '@/vcomps';
import { XInput, XSelect, XOKButton, XCancelButton, XTextArea, XInputNum, XUploadExcel, XScrollSelect } from '@/components/xqxc_ui';
import TableBox from './components/TableBox';
import SetPrice from './modal/SetPrice';
import Ban from './modal/Ban';
import Fail from './modal/Fail';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/productmanage/wholesalegoods/model';
import Putaway from './modal/Putaway';
import SetGoods from './modal/SetGoods';

const { Text } = Typography;

class TrailerGoods extends Component {

    static propTypes = {
        history: PropTypes.object,
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getUserSele: PropTypes.func,
        userSele: PropTypes.array,
        getProductBusinessType: PropTypes.func,
        productBusinessType: PropTypes.array,
        getWholesaleGoodsList: PropTypes.func,
        wholesaleGoodsList: PropTypes.object,
        getTypeSele: PropTypes.func,
        typeSele: PropTypes.array,
        getChannelEnum: PropTypes.func,
        channelEnum: PropTypes.array,
    }

    static defaultProps = {
        userSele: [],
        typeSele: [],
        wholesaleGoodsList: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
        channelEnum: []
    }

    constructor(props) {
        super(props);
        this.state = {
            renderModal: '', // 当前需要打开的弹窗
            modalParams: null, // 当前需要打开的弹窗的入参
            goodsName: '',
            goodsNos: '',
            distribution: {},
            merchant: {},
            categoryId: '',
            business: {},
            batchKeys: [],
            percent: 0,
            exportState: 'normal',
            importing: false,
            categorySubTypeItem: {},
            channelItem: {}
        };
        this.retailPriceRate = 1;
        this.cleanRef = null;
        this.timer = null;
    }

    componentDidMount() {
        const {
            keepSecondNavBreadcrumb,
            getUserSele,
            getTypeSele,
            getChannelEnum
        } = this.props;
        keepSecondNavBreadcrumb();
        getUserSele();
        getTypeSele();
        getChannelEnum();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const {
            goodsName,
            goodsNos,
            distribution,
            merchant,
            categoryId,
            business,
            pageNum,
            pageSize,
            categorySubTypeItem,
            channelItem
        } = this.state;
        this.searchCache = {
            goodsName,
            goodsNos: goodsNos && goodsNos.replace(/，/g, ','),
            isReco: distribution.code,
            merchantId: merchant.merchantId,
            categoryId,
            status: 'RESERVE',
            goodsType: business.code,
            categorySubType: categorySubTypeItem.code,
            onChannel: channelItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getWholesaleGoodsList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize, status: 'RESERVE', }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            goodsName: '',
            goodsNos: '',
            distribution: {},
            merchant: {},
            categoryId: '',
            business: {},
            categorySubTypeItem: {},
            channelItem: {}
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
        this.cleanRef.clean();
    }

    //请求函数
    _request = (url, data) => {
        http(url, data, 'POST')
            .then(res => {
                message.success(res.message);
                this.setState({ batchKeys: [] }, () => {
                    this._searchHandle('useCache');
                });
            })
            .catch(err => {
                message.error(err.message);
            });
    }

    // 渲染弹窗
    _renderMode = () => {
        const { renderModal, modalParams } = this.state;
        const that = this;
        switch (renderModal) {
            case 'setPrice':
                return <SetPrice
                    modalParams={modalParams}
                    closeMode={that._closeMode} />;
            case 'ban':
                return <Ban
                    modalParams={modalParams}
                    closeMode={that._closeMode} />;
            case 'fail':
                return <Fail
                    modalParams={modalParams}
                    closeMode={that._closeMode} />;
            case 'putaway':
                return <Putaway
                    modalParams={modalParams}
                    closeMode={that._closeMode} />;
            case 'SetGoods':
                return <SetGoods
                    modalParams={modalParams}
                    closeMode={that._closeMode} />;
            default:
                return null;
        }
    }
    // 关闭弹窗
    _closeMode = (update) => {
        if (update) this.setState({ batchKeys: [] });
        this.setState({ renderModal: '', modalParams: null }, () => {
            if (update) this._searchHandle('useCache');
        });
    }

    //一键导出
    _quickExport = () => {
        const {
            goodsName,
            goodsNos,
            distribution,
            merchant,
            categoryId,
            business,
            pageNum,
            pageSize
        } = this.state;
        this.setState({ percent: 0 }, () => {
            const data = {
                goodsName,
                goodsNos: goodsNos && goodsNos.replace(/，/g, ','),
                isReco: distribution.code,
                merchantId: merchant.merchantId,
                categoryId,
                status: 'RESERVE',
                goodsType: business.code,
                searchType: 1,
                pageNum,
                pageSize
            };
            http('/goods/wholesale/wholesaleGoodsExportExcel', data, 'POST')
                .then(res => {
                    if (res.status == 200) {
                        this.setState({ importing: true }, () => {
                            this.timer = setInterval(() => {
                                http('/goods/wholesale/wholesaleGoodsExportExcel', { searchType: 3, downloadCode: res.result }, 'POST')
                                    .then((nextRes) => {
                                        if (nextRes.result < 0) {
                                            message.error('导出数据为空');
                                            clearTimeout(this.timer);
                                            this.setState({ exportState: 'exception' }, () => {
                                                this.setState({ importing: false });
                                            });
                                        } else if (nextRes.result < 100 && nextRes.result > 0) {
                                            const percent = Number(nextRes.result);
                                            this.setState({ percent });
                                        } else {
                                            clearTimeout(this.timer);
                                            this.setState({ exportState: 'success' }, () => {
                                                http('/goods/wholesale/wholesaleGoodsExportExcel', { searchType: 2, downloadCode: res.result }, 'POST')
                                                    .then((info) => {
                                                        this.setState({ importing: false, percent: 0, exportState: 'normal' }, () => {
                                                            if (info.result) {
                                                                window.location.href = info.result;
                                                            } else {
                                                                message.error('导出失败');
                                                            }
                                                        });
                                                    }).catch((e) => {
                                                        message.error(e.message);
                                                    });
                                            });
                                        }
                                    }).catch((e) => {
                                        this.setState({ importing: false, percent: 0, exportState: 'normal' });
                                        clearTimeout(this.timer);
                                        message.error(e.message);
                                    });
                            }, 1000);
                        });
                    }
                })
                .catch(err => {
                    message.error(err.message);
                });
        });

    }

    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'detail':
                this._routeJump('商品详情', `/goodsinfo/${id}`);
                break;
            case 'putaway':
                this.setState({ renderModal: 'putaway', modalParams: { wholesaleGoodsIds: [id] } });
                break;
            case 'soldOut':
                this._request('/goods/wholesale/onOffWholesaleGoodsStatus',
                    { wholesaleGoodsIds: [id], status: 'PULL_OFF_SHELVES' });
                break;
            case 'setPrice':
                this.setState({ renderModal: 'setPrice', modalParams: params });
                break;
            case 'ban':
                this.setState({ renderModal: 'ban', modalParams: { id } });
                break;
            case 'isRec':
                this._request('/goods/wholesale/addRecommendList', params);
                break;
            case 'batchKeys':
                this.setState({ batchKeys: params });
                break;
            case 'SetGoods':
                this.setState({ renderModal: 'SetGoods', modalParams: id });
                break;
            default:
                break;
        }
    }
    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    //路由跳转
    _routeJump = (title, url) => {
        const { addOneBreadcrumbPath, history } = this.props;
        const baseURL = '/home/productmanage/wholesalegoods';
        addOneBreadcrumbPath({ title, path: baseURL });
        history.push({ pathname: `${baseURL}${url}` });
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const {
            typeSele,
            userSele,
            wholesaleGoodsList,
            channelEnum
        } = this.props;
        const {
            goodsName,
            goodsNos,
            distribution,
            merchant,
            business,
            batchKeys,
            importing,
            percent,
            exportState,
            categorySubTypeItem,
            channelItem,
        } = this.state;
        return (
            <KeepAlive id='trailerGoods' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <Spin spinning={importing} indicator={<Progress type="circle" percent={percent} status={exportState} />}>
                    <div className={styles.flexBoxContainer}>
                        <SearchBox>
                            <SearchItem>
                                <XTextArea
                                    label='货号'
                                    style={{ marginRight: 20 }}
                                    inputStyle={{ width: 250 }}
                                    placeholder='请输入货号，多个用逗号隔开，最多100条'
                                    value={goodsNos}
                                    rows={3}
                                    onChange={value => {
                                        if (value.replace(/，/g, ',').split(',').length < 100) {
                                            this.setState({ goodsNos: value });
                                        } else {
                                            message.warning('商品货号最多100条');
                                        }
                                    }}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='商品名称'
                                    placeholder='请输入商品名称'
                                    bindThis={this}
                                    bindName='goodsName'
                                    value={goodsName}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='分销推荐'
                                    placeholder='请选择分销推荐'
                                    renderData={[
                                        { code: 1, value: '是' },
                                        { code: 0, value: '否' }
                                    ]}
                                    bindThis={this}
                                    bindName='distribution'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={distribution.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <div style={{ marginRight: 20 }}>
                                    <span>商户</span>
                                    <XScrollSelect
                                        style={{ width: 150, marginLeft: 10 }}
                                        url='/merchant/info/listMerchantIdAndNamePage'
                                        placeholder='请选择商户'
                                        showSearch
                                        mainKey='merchantName'
                                        selectID='merchantId'
                                        selectName='merchantName'
                                        value={merchant}
                                        listLabel='dataList'
                                        isOptionBindID={true}
                                        onChange={(value, item) => this.setState({ merchant: item.props['data-option'] })}
                                    />
                                </div>
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='业务类型'
                                    placeholder='请选择业务类型'
                                    renderData={typeSele}
                                    bindThis={this}
                                    bindName='business'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={business.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='上架渠道'
                                    placeholder='请选择上架渠道'
                                    renderData={channelEnum}
                                    bindThis={this}
                                    bindName='channelItem'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={channelItem.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='商品分类'
                                    style={{ marginRight: '10px' }}
                                    placeholder='请选择分类类型'
                                    renderData={[
                                        { code: 'COMMON', value: '通用分类' },
                                        { code: 'ONLINE', value: '线上分类' },
                                        { code: 'CITY', value: '同城分类' }
                                    ]}
                                    bindThis={this}
                                    bindName='categorySubTypeItem'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={categorySubTypeItem.value}
                                />
                                <GoodsClassify
                                    style={{ marginRight: '20px' }}
                                    ref={ele => this.cleanRef = ele}
                                    categorySubType={categorySubTypeItem.code}
                                    onClassifyChange={value => this.setState({ categoryId: value })}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                        <div className={styles.operateBox}>
                            <div style={{ marginRight: '20px' }}>
                                <XUploadExcel
                                    style={{ width: '120px' }}
                                    label='分销设置导入'
                                    apiurl='goods/wholesale/wholesaleGoodsImportExcel'
                                    failHandle={(info) => {
                                        this.setState({ renderModal: 'fail', modalParams: info.file.response.result });
                                    }}
                                    successHandle={() => {
                                        message.success('导入操作成功');
                                        this._searchHandle('useCache');
                                    }}
                                />
                            </div>
                            <XOKButton
                                style={{ width: '120px', marginRight: '20px' }}
                                label='一键导出'
                                onClick={this._quickExport} />
                            <XOKButton
                                label='分销推荐列表'
                                style={{ width: '120px', marginRight: '20px' }}
                                onClick={() => this._routeJump('分销推荐列表', '/recommend')}
                            />
                            <XOKButton
                                style={{ width: '120px', marginRight: '20px' }}
                                label='批量上架分销'
                                onClick={() => {
                                    if (!batchKeys.length) {
                                        message.warning('请勾选商品');
                                        return;
                                    }
                                    this.setState({ renderModal: 'putaway', modalParams: { wholesaleGoodsIds: batchKeys } });
                                }} />
                            <XOKButton
                                style={{ width: '120px' }}
                                label='批量下架分销'
                                onClick={() => {
                                    if (!batchKeys.length) {
                                        message.warning('请勾选商品');
                                        return;
                                    }
                                    this._request('/goods/wholesale/onOffWholesaleGoodsStatus',
                                        { wholesaleGoodsIds: batchKeys, status: 'PULL_OFF_SHELVES' });
                                }} />
                            {/* <div className={styles.setBox}>
                                <div className={styles.setContent}>
                                    <XInputNum
                                        label='分销比率'
                                        inputStyle={{ width: 150 }}
                                        placeholder='请输入分销比率'
                                        min={1}
                                        onChange={value => {
                                            this.retailPriceRate = value;
                                        }}
                                        defaultValue={1}
                                    />
                                    <XOKButton
                                        style={{ width: 150, marginLeft: 20 }}
                                        label='批量设置分销价'
                                        onClick={() => {
                                            if (!batchKeys.length) {
                                                message.warning('请勾选商品');
                                                return;
                                            }
                                            if (!this.retailPriceRate) {
                                                message.warning('请输入分销比率');
                                                return;
                                            }
                                            this._request('/goods/wholesale/batchUpdatePlatPrice',
                                                {
                                                    retailPriceRate: this.retailPriceRate, wholesaleGoodsIds: batchKeys
                                                });
                                        }} />
                                </div>
                                <Text type='secondary'>批发价*(1+比率)=分销价，计算结果四舍五入保留2位小数。</Text>
                            </div> */}
                        </div>
                        <TableBox
                            paginationChange={this._paginationChange}
                            renderData={wholesaleGoodsList}
                            tableAction={this._tableAction}
                            batchKeys={batchKeys}
                        />
                        {this._renderMode()}
                    </div>
                </Spin>
            </KeepAlive>
        );
    }
}

export default connect(state => ({
    typeSele: state.wholesaleGoods.typeSele,
    userSele: state.wholesaleGoods.userSele.merchantIdAndNames,
    wholesaleGoodsList: state.wholesaleGoods.wholesaleGoodsList,
    channelEnum: state.wholesaleGoods.channelEnum,
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(TrailerGoods);