import { createModel } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };

const model = {
    namespace: 'previewcollectionmanage',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getPreviewCollectionList': T('getPreviewCollectionList'),
            'getPreviewCollectionDetailList': T('getPreviewCollectionDetailList'),

            'getMerchantTypeEnum': T('getMerchantTypeEnum'),
            'getIncomeTypeEnum': T('getIncomeTypeEnum'),
        },
        actions: {
            'getPreviewCollectionList': A('getPreviewCollectionList'),
            'getPreviewCollectionDetailList': A('getPreviewCollectionDetailList'),
            'getMerchantTypeEnum': A('getMerchantTypeEnum'),
            'getIncomeTypeEnum': A('getIncomeTypeEnum'),
        },
        sagas: {
            'getPreviewCollectionList': S('getPreviewCollectionList', '/settle/advances/received/item/findMerchantAccount'),
            'getPreviewCollectionDetailList': S('getPreviewCollectionDetailList', '/settle/advances/received/item/findPage'),
            'getMerchantTypeEnum': S('getMerchantTypeEnum', '/enum/MerchantTypeEnum'),
            'getIncomeTypeEnum': S('getIncomeTypeEnum', '/enum/AdvancesReceivedItemTypeEnum'),
        },
        reducers: {
            'previewCollectionList': R('getPreviewCollectionList', dataList),
            'previewCollectionDetailList': R('getPreviewCollectionDetailList', dataList),
            'merchantTypeEnum': R('getMerchantTypeEnum', []),
            'incomeTypeEnum': R('getIncomeTypeEnum', []),
        }
    })
};
export default createModel(model);