export const GET_INVOICELIST = 'SUPPLIERSETTLEMENT/GET_INVOICELIST';
export const SET_INVOICELIST = 'INVOICELIST/SET_INVOICELIST';

// 发货清单
export const GET_DELIVERLIST_TABLESOURCE = 'SUPPLIERSETTLEMENT/GET_DELIVERLIST_TABLESOURCE';
export const SET_DELIVERLIST_TABLESOURCE = 'SUPPLIERSETTLEMENT/SET_DELIVERLIST_TABLESOURCE';

// 对账单
export const GET_VALIDATESTATEMENT_TABLESOURCE = 'SUPPLIERSETTLEMENT/GET_VALIDATESTATEMENT_TABLESOURCE';
export const SET_VALIDATESTATEMENT_TABLESOURCE = 'SUPPLIERSETTLEMENT/SET_VALIDATESTATEMENT_TABLESOURCE';

// 合同管理
export const GET_CONTRACTMANAGE_TABLESOURCE = 'SUPPLIERSETTLEMENT/GET_CONTRACTMANAGE_TABLESOURCE';
export const SET_CONTRACTMANAGE_TABLESOURCE = 'SUPPLIERSETTLEMENT/SET_CONTRACTMANAGE_TABLESOURCE';

// 合同详情
export const GET_CONTRACTDETAIL = 'SUPPLIERSETTLEMENT/GET_CONTRACTDETAIL';
export const SET_CONTRACTDETAIL = 'SUPPLIERSETTLEMENT/SET_CONTRACTDETAIL';

// 合同状态
export const GET_CONTRACTSTATES = 'SUPPLIERSETTLEMENT/GET_CONTRACTSTATES';
export const SET_CONTRACTSTATES = 'SUPPLIERSETTLEMENT/SET_CONTRACTSTATES';