/**
 *  【供应商】入口页
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const SupplierList = ({ history }) => {
    return <Main history={history} />;
};

SupplierList.propTypes = {
    history: PropTypes.object
};

export default SupplierList;