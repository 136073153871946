/**
 * 【商品分类页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import TableData from './components/TableData';
import { getProductClassify, addNullCell, deleteNullCell } from '@/reducers/productmanage/classify/actions';
import { message } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import CreateClassify from './modal/CreateClassify';
import EditClassify from './modal/EditClassify';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class ProductClassify extends React.Component {
    state = {
        categoryName: '', // 分类名称
        currentOpenMode: '', // 当前打开的弹框
        openModeParam: null, // 弹框传参
        expandedKeys: [],
        keys: []

    }

    // 查询
    _searchHandle = (useCache) => {
        const { categoryName, pageNum, pageSize } = this.state;
        this.searchCache = {
            categoryName, categorySubType: 'ONLINE',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getProductClassify(this.searchCache); // 获取商品分类
        KeepAlive.saveCache(this.searchCache); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ categoryName: '', }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    //打开弹窗
    _renderMode = () => {
        const { currentOpenMode, openModeParam } = this.state;
        switch (currentOpenMode) {
            case 'create':
                return <CreateClassify closeMode={this._closeMode} openModeParam={openModeParam} />;
            case 'edit':
                return <EditClassify closeMode={this._closeMode} openModeParam={openModeParam} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', openModeParam: null }, () => {
            if (update) {
                this._searchHandle('useCache');
            }
        });
    }

    // 添加一级分类
    _createNumOne = () => {
        this.setState({ currentOpenMode: 'create', openModeParam: { levelNum: 1, id: 0 } });
    }

    //展开所有
    _expandAll = () => {
        const { tableSource: { dataList = [] } } = this.props;
        let expandedKeys = [];
        for (let item of dataList) {
            expandedKeys.push(item.id);
            if (item.childCategory) {
                for (let cc of item.childCategory) {
                    expandedKeys.push(cc.id);
                }
            }
        }
        this.setState({ expandedKeys });
    }

    //关闭所有
    _closeAll = () => {
        this.setState({ expandedKeys: [] });
    }

    _handleOnExpand = (expanded, record) => {
        console.log(expanded);
        const { expandedKeys } = this.state;
        if (expanded) {
            expandedKeys.push(record.id);
            this.setState({ expandedKeys });
        } else {
            this.setState({ expandedKeys: expandedKeys.filter(item => item != record.id) });
        }
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'addSublevel':
                this.setState({ currentOpenMode: 'create', openModeParam: { id: params.id, levelNum: params.categoryGrade } });
                break;
            case 'edit':
                this.setState({ currentOpenMode: 'edit', openModeParam: { ...params } });
                break;

            case 'delete': // 删除一个分类gmtCreate
                showConfirm('您确定删除该分类？', '', () => {
                    this._sendReq('/goods/category/delCategory', { id, grade: params.categoryGrade, categorySubType: 'ONLINE', categoryType: 'MALL' }, '删除成功！');
                });
                break;
            case 'sort': // 分类排序
                var fromItem = params.from;
                var toItem = params.to;
                var sortType = params.type;
                if (!Number(fromItem.id)) {
                    message.warning('请更新分类信息');
                    return;
                }
                if (!toItem) {
                    if (sortType == 'up') {
                        message.info('已经在第一级了');
                    } else {
                        message.info('已经在最后一级了');
                    }
                } else {
                    this._sendReq('/goods/category/sort', {
                        sortList: [{
                            categoryGradeNum: toItem.sortNo,
                            categoryId: fromItem.id
                        },
                        {
                            categoryGradeNum: fromItem.sortNo,
                            categoryId: toItem.id
                        }]
                    }, '排序操作成功');
                }
                break;
            case 'status-switch':  // 启用禁用状态开关
                {
                    let item = params.item;
                    let state = item.state == 1 ? 0 : 1;
                    this._sendReq('/goods/category/updateStateById', { id: item.id, state }, '状态修改成功！');
                }
                break;
        }
    }

    _sendReq = (url, data, tip) => {
        http(url, data, 'POST')
            .then(() => {
                message.success(tip);
                this._searchHandle('useCache');
            });
    };

    render() {
        const { categoryName, expandedKeys } = this.state;
        const { tableSource } = this.props;

        return (
            <KeepAlive id='ProductClassify' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='一级分类名称'
                                placeholder='请输入分类名称'
                                value={categoryName}
                                bindThis={this}
                                bindName='categoryName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px', marginRight: '20px' }} label='添加一级分类' onClick={this._createNumOne} />
                        <XOKButton style={{ width: '90px', marginRight: '20px' }} label='全部展开' onClick={this._expandAll} />
                        <XOKButton style={{ width: '90px' }} label='全部收起' onClick={this._closeAll} />
                    </div>
                    <TableData
                        paginationChange={this._paginationChange}
                        renderData={tableSource}
                        tableAction={this._tableAction}
                        handleOnExpand={this._handleOnExpand}
                        expandedKeys={expandedKeys} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

ProductClassify.propTypes = {
    history: PropTypes.object,
    tableSource: PropTypes.object,
    getProductClassify: PropTypes.func,
    addNullCell: PropTypes.func,
    deleteNullCell: PropTypes.func,
};

const mapStateToProps = (state) => ({
    tableSource: state.classify.productClassifyList, // 商品分类
});

export default connect(mapStateToProps, { getProductClassify, addNullCell, deleteNullCell })(ProductClassify);