// 贴纸分类 下拉
export const GET_STICKYPAPERCLASS_SELECTSOURCE = 'STICKYPAPER/GET_STICKYPAPERCLASS_SELECTSOURCE';
export const SET_STICKYPAPERCLASS_SELECTSOURCE = 'STICKYPAPER/SET_STICKYPAPERCLASS_SELECTSOURCE';

// 贴纸分类 表格
export const GET_STICKYPAPERTYPE_TABELSOURCE = 'STICKYPAPER/GET_STICKYPAPERTYPE_TABELSOURCE';
export const SET_STICKYPAPERTYPE_TABELSOURCE = 'STICKYPAPER/SET_STICKYPAPERTYPE_TABELSOURCE';

// 贴纸列表 表格
export const GET_STICKYPAPERLIST_TABELSOURCE = 'STICKYPAPER/GET_STICKYPAPERLIST_TABELSOURCE';
export const SET_STICKYPAPERLIST_TABELSOURCE = 'STICKYPAPER/SET_STICKYPAPERLIST_TABELSOURCE';

