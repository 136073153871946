/**
 * 启用与禁用
 */
import React from 'react';
import { Modal, message, Icon, } from 'antd';
import PropTypes from 'prop-types';
import styles from './PopconfirmModal.module.scss';
import http from '@/assets/api/http';
import { XTextArea } from '@/components/xqxc_ui';

class PopconfirmModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
            reason: '',//禁用原因
        };
    }
    _handleOk = () => {
        const { data } = this.props;
        const { reason } = this.state;
        if (data.state == '1' && reason == '') {
            return message.warn('请填写禁用原因。');
        }
        this.setState({ confirmLoading: true }, () => {
            http('/spokesmantask/config/openOrStopSpokesmanTaskConfig', {
                id: data.id,
                state: data.state == '1' ? '0' : '1',
                disableReasons: data.state == '1' ? this.state.reason : undefined,
            }, 'POST').then(() => {
                message.success('提交成功');
                this.setState({
                    visible: false,
                    confirmLoading: false,
                });
                this.props.closeMode(true);
            }).catch((reject = {}) => {
                message.error(reject.message || '提交异常');
                this.setState({
                    visible: false,
                    confirmLoading: false,
                });
                this.props.closeMode();
            });
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, reason } = this.state;
        const { data, } = this.props;
        return (
            <Modal
                width={339}
                centered
                // title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='继续'
            >
                <div className={styles.container}>
                    <div className={styles.title}>
                        <Icon type="exclamation-circle" style={{ fontSize: '23px' }} />
                        <h4>确定要{data.state == '1' ? '禁用' : '开启'}此任务吗？</h4>
                    </div>
                    {data.state == '1' ? <XTextArea
                        inputStyle={{ width: '268px', marginTop: '20px' }}
                        placeholder='输入禁用原因'
                        value={reason}
                        bindThis={this}
                        bindName='reason'
                        rows={4}
                    /> : <span>你还要继续吗？</span>}
                </div>
            </Modal>
        );

    }
}

PopconfirmModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default PopconfirmModal;