import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import { isPlat } from '@/assets/js/authType';
import { tooltipStyle, tooltip, priceFenToYuanCell, cellWidth } from '@/components/TableCell';
import ButtonPerssion from '@/routes/ButtonPerssion';

class TableContent extends React.Component {

    state = {
    };

    _columns = () => [
        {
            title: '企业名称',
            dataIndex: 'businessCompanyName',
            key: 'businessCompanyName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所在地',
            dataIndex: 'address',
            key: 'address',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '公司地址',
            dataIndex: 'contactAddress',
            key: 'contactAddress',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '推荐人(类型)',
            dataIndex: 'referrerName',
            key: 'referrerName',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, record) => {
                return <span title={`${text} ${record.referrerRole && record.referrerRole.value && `(${record.referrerRole && record.referrerRole.value})`}`}>{`${text} ${record.referrerRole && record.referrerRole.value && `(${record.referrerRole && record.referrerRole.value})`}`}</span>;
            }
        },
        {
            title: '主账号手机',
            dataIndex: 'mainTel',
            key: 'mainTel',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '开通数量',
            dataIndex: 'childAccountNum',
            key: 'childAccountNum',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '联系人',
            dataIndex: 'contactName',
            key: 'contactName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '联系电话',
            dataIndex: 'contactTel',
            key: 'contactTel',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请时间',
            dataIndex: 'applyTime',
            key: 'applyTime',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'auditState',
            key: 'auditState',
            width: cellWidth.small,
            align: 'center',
            render: (auditState) => {
                return <span>{auditState && auditState.value}</span>;
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: cellWidth.normal,
			fixed: 'right',
            render: (id, item) => this._TableAction(item.id, item)
        },
    ];

    _takeDataSource = (data) => {
        return data;
    }


    _MoneyCell = (text) => {
        const money = regFenToYuan(parseInt(text));
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {money}
            </div>
        );
    };
    _columnsIsPlat = () => {
        let arr = this._columns();
        if (isPlat()) {
            return arr.filter((i, index) => index !== 8);
        } else {
            return arr;
        }

    }
    _TableAction = (key, item) => {
        const { tableAction } = this.props;
        // NEW 新建
        // SUBMIT_REVIEW  提交审核
        // ALREADY_OPENED 已开通
        // AUDIT_NOT_PASSED 审核不通过
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'detail')}>详情</div>
                <ButtonPerssion code='usermanage/corporateanchor/bj'>
                    {((item.auditState && item.auditState.code == 'NEW') || (item.auditState && item.auditState.code == 'AUDIT_NOT_PASSED')) &&
                        <div className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>}
                </ButtonPerssion>
                <ButtonPerssion code='usermanage/corporateanchor/zjzb'>
                    {(item.auditState && item.auditState.code == 'ALREADY_OPENED') &&
                        <div className={styles.item} onClick={() => tableAction(key, 'add')}>增加主播</div>}
                </ButtonPerssion>
                {(item.auditState && item.auditState.code == 'NEW') &&
                    <div className={styles.item} onClick={() => tableAction(key, 'delete')}>删除</div>}
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.setState({ selectedRowKeys });
                this.props.selectedRowKeysChange(selectedRowKeys);
            }
        };
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] }, () => {
            this.props.selectedRowKeysChange([]);
        });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                {/* <XTableSelectionCount selectedNum={this.state.selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection()}
                    rowKey='id'
                    columns={this._columnsIsPlat()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    selectedRowKeys: PropTypes.array,
    selectedRowKeysChange: PropTypes.func,
    scrollY: PropTypes.string
};

export default XTableHOC(TableContent);