import { createModel } from '@/store/tools';

const listData = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };
const model = {
    namespace: 'citybeautyopen',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getOpenCityData': T('getOpenCityData'),
            'getRecordData': T('getRecordData'),
            'getProvinceSele': T('getProvinceSele'),
            'getCitySele': T('getCitySele'),
            'getStatusEnum': T('getStatusEnum'),
            'getClassEnum': T('getClassEnum'),
            'getClassNameEnum': T('getClassNameEnum'),
        },
        actions: {
            'getOpenCityData': A('getOpenCityData'),
            'getRecordData': A('getRecordData'),
            'getProvinceSele': A('getProvinceSele'),
            'getCitySele': A('getCitySele'),
            'getStatusEnum': A('getStatusEnum'),
            'getClassEnum': A('getClassEnum'),
            'getClassNameEnum': A('getClassNameEnum'),
        },
        sagas: {
            'getOpenCityData': S('getOpenCityData', '/cityOpen/listCityOpenInfo'),
            'getRecordData': S('getRecordData', '/cityOpen/listCityOpenLog'),
            'getProvinceSele': S('getProvinceSele', '/user/area/listProvince'),
            'getCitySele': S('getCitySele', '/user/area/listAreaByParentId/', 'POST', 'parentId'),
            'getStatusEnum': S('getStatusEnum', '/enum/FlagEnum'),
            'getClassEnum': S('getClassEnum', '/enum/'),
            'getClassNameEnum': S('getClassNameEnum', '/enum/'),
        },
        reducers: {
            'openCityData': R('getOpenCityData', listData),
            'recordData': R('getRecordData', []),
            'provinceSele': R('getProvinceSele', []),
            'citySele': R('getCitySele', []),
            'statusEnum': R('getStatusEnum', []),
            'classEnum': R('getClassEnum', []),
            'classNameEnum': R('getClassNameEnum', []),
        }
    })
};
export default createModel(model);