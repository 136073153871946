/**
 * 服务商支取体现新建付款弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, Input, message } from 'antd';
import styles from './CreatePay.module.scss';
import { XOKButton, XCancelButton } from '@/components/xqxc_ui';
import numSwitch from '@/util/numSwitch';
import { getPayDetail } from '@/reducers/auditmanage/withdraw/actions';
import { regFenToYuan } from '@/util/money';

const { TextArea } = Input;

class CreatePay extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        subLoad: false,
        audLoad: false,
        paymentId: 0,
        businessNumber: 0,
        pendingPayAmount: 0,
        remark: '',
        toPaymentAmount: 0
    }

    componentDidMount() {
        const { withdrawalId, withdrawalIds, type } = this.props.data;
        switch (type) {
            case 'single':
                this.props.getPayDetail([withdrawalId]);
                break;
            case 'batch':
                this.props.getPayDetail(withdrawalIds);
                break;
        }

    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { payDetail } = newProps;
        this.setState(
            {
                paymentId: payDetail.paymentId,
                businessNumber: payDetail.businessNumber,
                pendingPayAmount: payDetail.pendingPayAmount
            });
    }

    _sendReq = (data, winText) => {
        http('/settle/withdrawalApplication/batchInsertDetermine', data, 'POST').then(() => {
            message.success(winText);
            this.setState({
                visible: false,
                audLoad: false,
                subLoad: false
            });
            this.props.closeMode(true);
        }).catch((error) => {
            message.error(error.message);
            this.setState({
                audLoad: false,
                subLoad: false
            });
        });
    }

    // 点击确认
    _confirmPayAudit = () => {
        const { payDetail, data } = this.props;
        this.setState({
            audLoad: true,
        }, () => {
            const { remark } = this.state;
            switch (data.type) {
                case 'single':
                    this._sendReq({
                        type: 'MERCHANT_WITHDRAW',
                        paymentId: payDetail.paymentId,
                        withdrawalIds: [data.withdrawalId],
                        paymentAmount: payDetail.toPaymentAmount,
                        toPaymentAmount: payDetail.toPaymentAmount,
                        remark: remark,
                        status: 'AUDIT'
                    }, '新建付款单并提交成功。');
                    break;
                case 'batch':
                    this._sendReq({
                        type: 'MERCHANT_WITHDRAW',
                        paymentId: payDetail.paymentId,
                        withdrawalIds: data.withdrawalIds,
                        paymentAmount: payDetail.toPaymentAmount,
                        toPaymentAmount: payDetail.toPaymentAmount,
                        remark: remark,
                        status: 'AUDIT'
                    }, '批量新建付款单并提交成功。');
                    break;
            }
        });
    }

    // 点击取消
    _confirmPay = () => {
        const { payDetail, data } = this.props;
        const { remark } = this.state;
        this.setState({
            subLoad: true,
        }, () => {
            switch (data.type) {
                case 'single':
                    this._sendReq({
                        type: 'MERCHANT_WITHDRAW',
                        paymentId: payDetail.paymentId,
                        withdrawalIds: [data.withdrawalId],
                        paymentAmount: payDetail.toPaymentAmount,
                        toPaymentAmount: payDetail.toPaymentAmount,
                        remark: remark,
                        status: 'INIT'
                    }, '新建付款单成功。');
                    break;
                case 'batch':
                    this._sendReq({
                        type: 'MERCHANT_WITHDRAW',
                        paymentId: payDetail.paymentId,
                        withdrawalIds: data.withdrawalIds,
                        paymentAmount: payDetail.toPaymentAmount,
                        toPaymentAmount: payDetail.toPaymentAmount,
                        remark: remark,
                        status: 'INIT'
                    }, '批量新建付款单成功。');
                    break;
            }
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
            audLoad: false,
            subLoad: false
        });
        this.props.closeMode(false);
    }

    render() {
        const { payDetail } = this.props;
        const { visible, subLoad, audLoad, title, remark } = this.state;
        return (
            <Modal
                width={580}
                centered
                title={title}
                visible={visible}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                footer={null}
                maskClosable={false}
            >
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <div className={styles.contentItem}>
                            <span className={styles.label}><b>*</b>付款单号</span>
                            <Input disabled={true} value={payDetail.paymentId} />
                        </div>
                        <div className={styles.contentItem}>
                            <span className={styles.label}><b>*</b>业务单号数量</span>
                            <Input disabled={true} value={payDetail.businessNumber} />
                        </div>
                        <div className={styles.contentItem}>
                            <span className={styles.label}><b>*</b>对账单数量</span>
                            <Input disabled={true} value={payDetail.businessNumber} />
                        </div>
                        <div className={styles.contentItem}>
                            <span className={styles.label}>待付款金额</span>
                            <Input disabled={true} style={{ width: '150px' }}
                                value={regFenToYuan(payDetail.toPaymentAmount)} />
                            <span className={styles.switch}>{numSwitch(regFenToYuan(payDetail.toPaymentAmount))}</span>
                        </div>
                        <div className={styles.contentItem}>
                            <span className={styles.label}><b>*</b>付款金额</span>
                            <Input disabled={true} style={{ width: '150px' }}
                                value={regFenToYuan(payDetail.toPaymentAmount)} />
                            <span className={styles.switch}>{numSwitch(regFenToYuan(payDetail.toPaymentAmount))}</span>
                        </div>
                        <div className={styles.explain}>
                            <span className={styles.label}>付款说明：</span>
                            <TextArea placeholder='请输入' autosize={{ minRows: 2, maxRows: 6 }}
                                value={remark} onChange={e => this.setState({ remark: e.target.value })} />
                        </div>
                        <div className={styles.btn}>
                            <XCancelButton
                                style={{ width: '90px', display: 'inline-block' }}
                                onClick={this._handleCancel}
                                label='取消' />
                            <XOKButton
                                style={{ width: '90px', marginLeft: '20px', display: 'inline-block' }}
                                loading={subLoad}
                                disabled={audLoad}
                                onClick={this._confirmPay}
                                label='确定' />
                            <XOKButton
                                style={{ width: '150px', marginLeft: '20px', display: 'inline-block' }}
                                loading={audLoad}
                                disabled={subLoad}
                                onClick={this._confirmPayAudit}
                                label='确定并提交审核' />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

CreatePay.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    getPayDetail: PropTypes.func,
    payDetail: PropTypes.object
};

const mapStateToProps = (state) => ({
    payDetail: state.withdrawAudit.payDetail,
});

export default connect(mapStateToProps, { getPayDetail })(CreatePay);