/**
 * 店铺商品详情
 */
import React from 'react';
import { Carousel, message, Icon } from 'antd';
import PropTypes from 'prop-types';
import styles from './goodsdetail.module.scss';
import http from '@/assets/api/http';
import { regFenToYuan } from '@/util/money';

class GoodsDetail extends React.Component {

    state = {
        goodsImageList: [],
        goodsName: '',
        goodsSubtitle: '',
        salePrice: '',
        marketPrice: '',
        goodsDesc: ''
    }

    componentDidMount() {
        http('/shop/goodsBaseInfo', {
            shopId: this.props.shopId,
            goodsId: this.props.goodsId,
        }, 'POST').then((response) => {
            if (response.status == 200) {
                const { goodsImageList, goodsName, goodsSubtitle, salePrice, marketPrice, goodsDesc } = response.result;
                this.setState({ goodsImageList, goodsName, goodsSubtitle, salePrice, marketPrice, goodsDesc });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _renderImg = (url, index) => {
        return (
            <div key={index} className={styles.banner}>
                <img className={styles.img} src={url} />
            </div>
        );
    }

    // 字数转换
    _wordNum = (txt, wordLength) => {
        let result = txt.toString();
        if (txt.toString().length > wordLength) {
            result = txt.toString().substring(0, wordLength) + '...';
        }
        return result;
    }

    render() {
        const { goodsImageList, salePrice, marketPrice, goodsName, goodsSubtitle, goodsDesc } = this.state;



        return (
            <div className={styles.container}>
                <div className={styles.carousel}>
                    <Carousel
                        autoplay={true}
                        dots={true}
                        lazyLoad={true}
                    >
                        {goodsImageList.map((item, index) => {
                            if (item.fileType == 'IMG' && item.imgUrl) {
                                return this._renderImg(item.imgUrl, index);
                            } else {
                                return null;
                            }
                        })}
                    </Carousel>
                </div>
                <div className={styles.price}>
                    <span className={styles.label}><span className={styles.rmb}>¥</span><span className={styles.money}>{regFenToYuan(salePrice)}</span></span>
                    {marketPrice ? <span className={styles.delete}><span className={styles.rmb}>¥</span><span className={styles.money}>{regFenToYuan(marketPrice)}</span></span> : null}
                    <div className={styles.line}></div>
                </div>
                <div className={styles.good}>
                    {/* 40字 */}
                    <div className={styles.title}>{this._wordNum(goodsName, 40)}</div>
                    {/* 50字 */}
                    <div className={styles.desc}>{this._wordNum(goodsSubtitle, 50)}</div>
                </div>
                <div className={styles.htmldesc}>
                    <div className={styles.html} dangerouslySetInnerHTML={{ __html: goodsDesc }} />
                </div>
                <div className={styles.footer}>
                    <div className={styles.shop}>
                        <img className={styles.icon} src={require('@/assets/image/shop.png')} />
                        <div className={styles.label}>店铺</div>
                    </div>
                    <div className={styles.customer}>
                        <img className={styles.icon} src={require('@/assets/image/customer.png')} />
                        <div className={styles.label}>客服</div>
                    </div>
                </div>
                <div className={styles.back} onClick={() => this.props.onGoback()}>
                    <Icon style={{ fontSize: 22, color: '#ffffff', marginLeft: 4, marginTop: 5 }} type="left" />
                </div>
            </div>
        );
    }
}

GoodsDetail.propTypes = {
    shopId: PropTypes.number,
    goodsId: PropTypes.number,
    onGoback: PropTypes.func,
};

export default GoodsDetail;