import { createModel } from '@/store/tools';

const model = {
    namespace: 'consumerknows',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getlistGoodsComsumerRights': T('getlistGoodsComsumerRights'),
            'getqueryGoodsComsumerRights': T('getqueryGoodsComsumerRights'),
            'getgoodsQueryComsumerRights': T('getgoodsQueryComsumerRights'),
        },
        actions: {
            'getlistGoodsComsumerRights': A('getlistGoodsComsumerRights'),
            'getqueryGoodsComsumerRights': A('getqueryGoodsComsumerRights'),
            'getgoodsQueryComsumerRights': A('getgoodsQueryComsumerRights'),
        },
        sagas: {
            'getlistGoodsComsumerRights': S('getlistGoodsComsumerRights', '/goods/comsumerRights/listGoodsComsumerRights'),
            'getqueryGoodsComsumerRights': S('getqueryGoodsComsumerRights', '/goods/comsumerRights/queryGoodsComsumerRights'),
            'getgoodsQueryComsumerRights': S('getgoodsQueryComsumerRights', '/goods/comsumerRights/goodsQueryComsumerRights'),
        },
        reducers: {
            'getlistGoodsComsumerRights': R('getlistGoodsComsumerRights', []),
            'getqueryGoodsComsumerRights': R('getqueryGoodsComsumerRights', {}),
            'getgoodsQueryComsumerRights': R('getgoodsQueryComsumerRights', []),
        }
    })
};
export default createModel(model);