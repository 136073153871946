/**
 * 入网查询
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XTabNav } from '@/components/xqxc_ui';
import LianlianPay from './lianlianpay';
import HuiFuPay from './huifupay';
import { setSessionStorage, getSessionStorage, removeSessionStorage } from '@/assets/js/storage';

const tabList = [
    { id: '2', label: '连连支付' },
    { id: '3', label: '汇付入网' },
];
class StoreUnion extends React.Component {

    state = {
        currentTab: '2',
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航只保留到2级
        if (getSessionStorage('dpglrwcxlbtabId')) {
            this.setState({ currentTab: getSessionStorage('dpglrwcxlbtabId') });
            removeSessionStorage('dpglrwcxlbtabId');
        }
    }

    render() {
        const { currentTab } = this.state;
        const { history } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.tabBox}>
                    <XTabNav
                        activedID={currentTab}
                        renderData={tabList}
                        onChange={teb => {
                            setSessionStorage('dpglrwcxlbtabId', teb);
                            this.setState({ currentTab: teb });
                        }} />
                </div>
                {currentTab == 2 && <LianlianPay history={history} />}
                {currentTab == 3 && <HuiFuPay history={history} />}
            </div>
        );
    }
}

StoreUnion.propTypes = {
    history: PropTypes.object, // router history
    keepSecondNavBreadcrumb: PropTypes.func // 保持两级面包屑导航
};

export default connect(null, { keepSecondNavBreadcrumb })(StoreUnion);