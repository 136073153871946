import * as C from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
export const hotelCommentList = (state = initData, action) => {
    switch(action.type){
        case C.SET_HOTELCOMMENT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const hotelCommentDetail = (state = {}, action) => {
    switch(action.type){
        case C.SET_HOTELCOMMENT_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};