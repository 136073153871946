/**
 * [打印机设置]
 * @author: Fu Xiaochun
 * @date: 2022-08-12 
 */

import React, { useState } from 'react';
import styles from './index.module.scss';
import { Tabs } from 'antd';
import CloudPrinter from './cloudPrinter';

const { TabPane } = Tabs;

function PrinterSetup(){

    const [tabIndex, setTabIndex] = useState('1');

    return (
        <div className={styles.flexBoxContainer}>
            <div className={styles.tabBox}>
                <Tabs activeKey={tabIndex} onChange={setTabIndex}>
                    <TabPane tab='云打印机' key='1' />
                </Tabs>
            </div>
            {tabIndex == '1' && <CloudPrinter />}
        </div>
    );
}

export default PrinterSetup;