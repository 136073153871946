import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { tooltipStyle, tooltip, ImgCell, priceFenToYuanCell } from '@/components/TableCell';

const columns = () => [
    {
        title: '商品图片',
        dataIndex: 'mainImgUrl',
        key: 'mainImgUrl',
        width: '13%',
        align: 'center',
        render: ImgCell
    },
    {
        title: '商品名称',
        dataIndex: 'goodsName',
        key: 'goodsName',
        width: '13%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '商品品牌',
        dataIndex: 'brandName',
        key: 'brandName',
        width: '13%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '货号',
        dataIndex: 'goodsNo',
        key: 'goodsNo',
        align: 'center',
        width: '13%',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '零售价(元)',
        dataIndex: 'salePrice',
        key: 'salePrice',
        width: '13%',
        align: 'center',
        render: priceFenToYuanCell
    },
    {
        title: '商品分类',
        dataIndex: 'categoryName',
        key: 'categoryName',
        align: 'center',
        width: '13%',
        onCell: tooltipStyle,
        render: tooltip
    },
];

class TableContent extends React.Component {

    render() {
        const { renderData } = this.props;
        return <Table
            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
            columns={columns()}
            dataSource={renderData}
            rowKey='id'
            pagination={false}
        />;
    }
}

TableContent.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
};

export default TableContent;