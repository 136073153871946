/**
 * 新建员工 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { XTitle } from '@/components/xqxc_ui';
import { Modal, message, Form, Checkbox, Select } from 'antd';
import styles from './AddStaff.module.scss';
import { getRoleSelect, getDownEmpls } from '@/reducers/permissionsmanage/assignauthority/actions';

const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    labelAlign: 'left',
};

class AddStaff extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
    }

    componentDidMount() {
        this.props.getRoleSelect();
        this.props.getDownEmpls();
    }

    // 点击确认
    _handleOk = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const data = {
                    emplIds: fieldsValue.emplIds.toString(),
                    roleIds: fieldsValue.roleIds.toString()
                };
                http('/admin/empl/addRoleEmpl', data, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('新建成功！');
                        this.setState({
                            visible: false,
                        });
                        this.props.closeMode(true);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
            }
        });
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // 下拉选择项
    _renderOption = (list) => {
        return list.map((item) => {
            return <Option key={item.id} value={item.id}>{item.emplName}</Option>;
        });
    }

    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { downEmpls, roleSelect } = this.props;
        const adminRoleOption = roleSelect.map((item) => {
            return {
                key: item.id,
                value: item.id,
                label: item.roleName,
            };
        });

        return (
            <Modal
                width={900}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon
                            htmlFor='emplIds'
                            label={<span style={{ color: 'rgba(127, 127, 127, 0.847058823529412)' }}>说明：新添加的角色会与员工的原有角色进行合并</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('emplIds', {
                                rules: [{ required: true, message: '请选择员工' }],
                            })(
                                <Select
                                    mode="multiple"
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    optionFilterProp="children"
                                >
                                    {this._renderOption(downEmpls)}
                                </Select>
                            )}
                        </Form.Item>
                        <XTitle label='员工角色配置' />
                        <div className={styles.line}></div>
                        <Form.Item
                            colon
                            htmlFor='roleIds'
                            label={<span>管理角色</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('roleIds', {
                                rules: [{ required: true, message: '请选择角色' }],
                            })(
                                <Checkbox.Group
                                    options={adminRoleOption}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

AddStaff.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    getRoleSelect: PropTypes.func, // 获取角色选择
    getDownEmpls: PropTypes.func, // 获取员工选择
    roleSelect: PropTypes.array, // 角色选择
    downEmpls: PropTypes.array, // 员工选择
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(AddStaff);// 校验

const mapStateToProps = (state) => ({
    roleSelect: state.assignauthority.getRoleSelect, // 角色选择
    downEmpls: state.assignauthority.getDownEmpls, // 员工选择
});

export default connect(mapStateToProps, { getRoleSelect, getDownEmpls })(ForgotProducDetails);