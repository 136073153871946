import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { Modal, message, Typography, Table, InputNumber, Input } from 'antd';
import { tooltipStyle, tooltip, } from '@/components/TableCell';
import model from '@/reducers/accruedService/serviceList/model';
import BigNumber from 'bignumber.js';

const { Text } = Typography;
const borderDiv = {
    display: 'inline-block',
    width: '60px',
    height: '32px',
    lineHeight: '32px',
    border: '1px solid #dddddd',
    textAlign: 'center',
    margin: '0 10px'
};

class FeeRateSet extends Component {

    static propTypes = {
        data: PropTypes.object,
        closeMode: PropTypes.func,
        getFeeRateSetList: PropTypes.func,
        feeRateSetList: PropTypes.object,
        setFeeRate: PropTypes.func,
        setGiveQuota: PropTypes.func
    }

    state = {
        loading: false,
        firstParam: '',
        secondParam: ''
    }

    _request = (code, labelName) => {
        http(`/admin/param/getParamValueByCode/${code}`, {}, 'POST')
            .then(res => {
                this.setState({ [labelName]: res.result });
            })
            .catch(() => { message.error('获取系统参数失败'); });
    }

    componentDidMount() {
        const { getFeeRateSetList, data: { earningsServiceId = '' } = {} } = this.props;
        getFeeRateSetList({ earningsServiceId });
        this._request('SERVICE_PROFIT_GIVE_NUMBER_PROMOTE_TALKER', 'firstParam');
        this._request('TAKER_PROFIT_GIVE_NUMBER_PROMOTE_TALKER', 'secondParam');
    }

    //合并行
    _renderType = (index, type) => {
        if (type === 'SERVICE_APP') {
            let serviceApp = {
                children: '每日说服务商推广',
                props: { rowSpan: 0 },
            };
            if (index === 0) serviceApp.props.rowSpan = 3;
            return serviceApp;
        } else if (type === 'TALKER') {
            let opCenter = {
                children: '每日说客推广',
                props: { rowSpan: 0 },
            };
            if (index === 0) opCenter.props.rowSpan = 3;
            return opCenter;
        }
    }

    //设置费用
    _renderRate = (item) => {
        return <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <InputNumber
                key={item.id}
                value={item.earningsRatio}
                precision={1}
                placeholder='请输入'
                min={0}
                max={100}
                onChange={value => this.props.setFeeRate({ id: item.id, earningsRatio: value })}
            />
        </div>;
    }

    //表头
    _columns = (type) => {
        return [
            {
                title: '业务类型',
                dataIndex: 'recommendTypeName',
                key: 'recommendTypeName',
                width: '40%',
                align: 'center',
                onCell: tooltipStyle,
                render: (value, row, index) => this._renderType(index, type)
            },
            {
                title: '收益对象',
                dataIndex: 'earningsObject',
                key: 'earningsObject',
                width: '20%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '费用名称',
                dataIndex: 'costName',
                key: 'costName',
                width: '20%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '比率(%)',
                dataIndex: 'earningsRatioValue',
                key: 'earningsRatioValue',
                width: '20%',
                align: 'center',
                render: (value, row) => this._renderRate(row, type)
            }
        ];
    }

    //提交
    _saveData = () => {
        const { feeRateSetList: { earningsServiceItemList = [], giveSalesmanByServiceProvider, giveSalesmanByTalker } = {}, data: { earningsServiceId = '' } = {} } = this.props;
        let addUp1 = 0;
        let addUp2 = 0;
        earningsServiceItemList.forEach(element => {
            if (element.recommendType === 'SERVICE_APP') {
                addUp1 = new BigNumber(addUp1 || 0).plus(element.earningsRatio || 0).toNumber();
            } else if (element.recommendType === 'TALKER') {
                addUp2 = new BigNumber(addUp2 || 0).plus(element.earningsRatio || 0).toNumber();
            }
        });
        if (addUp1 > 100) return message.warn('每日说服务商推广比例之和不能超过100%');
        if (addUp2 > 100) return message.warn('每日说客推广比例之和不能超过100%');
        this.setState({ loading: true }, () => {
            const updateEarningsServiceItemReqs = earningsServiceItemList.map(item => {
                return { earningsRatio: item.earningsRatio * 10, id: item.id };
            });
            http('/earningsServiceItem/batchUpdate', { giveSalesmanByServiceProvider, giveSalesmanByTalker, earningsServiceId, updateEarningsServiceItemReqs }, 'POST')
                .then(() => {
                    message.success('费用比例设置成功。');
                    this.setState({ loading: false });
                    this.props.closeMode(true);
                })
                .catch(err => {
                    this.setState({ loading: false });
                    message.error(err.message);
                });
        });
    }

    _serviceFooter = (field, text, value) => {
        const { setGiveQuota, feeRateSetList } = this.props;
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <Typography.Text>免费赠送前</Typography.Text>
                <InputNumber
                    style={{ margin: '0 10px' }}
                    placeholder='请输入'
                    value={value}
                    disabled={true}
                />
                <Typography.Text>{text}</Typography.Text>
            </div> <div>
                <Typography.Text>免费赠送开通</Typography.Text>
                <InputNumber
                    style={{ margin: '0 10px' }}
                    placeholder='请输入'
                    value={feeRateSetList[field]}
                    onChange={value => {
                        let nv = value;
                        if (value < 0) nv = 0;
                        if (value > 99) nv = 99;
                        setGiveQuota({ [field]: nv });
                    }}
                />
                <Typography.Text>个优仓名额</Typography.Text>
            </div>
        </div>;
    }

    render() {
        const {
            closeMode,
            feeRateSetList: {
                earningsServiceItemList = []
            } = {},
            data: {
                serviceName = '',
            } = {},
        } = this.props;
        const { loading, firstParam, secondParam } = this.state;
        return <Modal
            width={1000}
            centered
            title='费用比例设置'
            visible={true}
            confirmLoading={loading}
            onOk={this._saveData}
            onCancel={() => closeMode()}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <div>
                <div><Text>服务名称：{serviceName}</Text></div>
                <div>
                    <Text type='secondary'>说明：服务商、区域公司、运营中心推广服务系统收益；收益金额=服务商品销售价*比率（%）</Text>
                </div>
                <Table
                    style={{ marginTop: '20px' }}
                    rowKey={(record) => record.id}
                    columns={this._columns('SERVICE_APP')}
                    dataSource={earningsServiceItemList.slice(0, 3)}
                    pagination={false}
                    footer={() => this._serviceFooter('giveSalesmanByServiceProvider', '名技术推广收益给服务商推广人', firstParam)}
                />
                <Table
                    showHeader={false}
                    rowKey={(record) => record.id}
                    columns={this._columns('TALKER')}
                    dataSource={earningsServiceItemList.slice(3, 6)}
                    pagination={false}
                    footer={() => this._serviceFooter('giveSalesmanByTalker', '名技术推广收益给每日说客推广人', secondParam)}
                />
            </div>
        </Modal>;
    }
}
export default connect(state => ({
    feeRateSetList: state.serviceAccrued.feeRateSetList,
}), { ...model.actions })(FeeRateSet);