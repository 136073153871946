/**
 * 【支付失败订单页】
 */
import React from 'react';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import { message } from 'antd';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker, showConfirm } from '@/components/xqxc_ui';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getFailStarOrderList, getHandleStatus } from '@/reducers/ordermanage/starOrder/actions';

import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
class Main extends React.Component {
    state = {
        outPayId: '', // 交易号
        buyerPhone: '', // 会员手机
        handleStatus: {}, // 状态
        startDate: null, // 充值开始日期
        endDate: null, // 充值结束日期
        pageNum: 1,
        pageSize: 50,
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getStarOrderList(this.state.parm);
        this.props.getHandleStatus();
    }
    _searchHandle = (useCache) => {
        const { outPayId, buyerPhone, handleStatus, startDate, endDate, pageNum, pageSize } = this.state;
        this.searchCache = {
            outPayId,
            buyerPhone,
            handleStatus: handleStatus.code,
            startDate,
            endDate,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getFailStarOrderList(this.searchCache);
    }
    // 重置
    _resetHandle = () => {
        this.setState({
            buyerPhone: '',
            outPayId: '',
            handleStatus: {},
            startDate: null,
            endDate: null,
            pageNum: 1,
            pageSize: 50,
        }, () => {
            this._searchHandle('useCache');
        });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageSize,
            pageNum,
        }, () => {
            this._searchHandle('useCache');
        });

    }
    // 表格操作
    _tableAction = (key, type, record) => {
        switch (type) {
            // 处理
            case 'deal':
                showConfirm('是否确定处理？', '', () => {
                    http('/settle/rechargeRisk/rebuildFailCharge', { checkIds: [record.id] }, 'POST')
                        .then(() => {
                            message.success('处理成功。');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
        }
    }
    render() {
        const { failStarOrderList, handleStatusList } = this.props;
        const { buyerPhone, outPayId, handleStatus, startDate, endDate } = this.state;
        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='交易凭证号'
                                placeholder='请输入交易凭证号'
                                value={outPayId}
                                bindThis={this}
                                bindName='outPayId'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='会员手机'
                                placeholder='请输入手机号码'
                                value={buyerPhone}
                                bindThis={this}
                                bindName='buyerPhone'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={handleStatusList}
                                dataIndex='value'
                                keyIndex='code'
                                value={handleStatus.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='handleStatus'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='充值时间'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat
                            />
                            <div className={styles.timeLine} />
                            <XDatePicker
                                style={{marginRight: 20 }}
                                label=''
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <OrderTable tableAction={this._tableAction} renderData={failStarOrderList} paginationChange={this._paginationChange} />
                </>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getFailStarOrderList: PropTypes.func,
    failStarOrderList: PropTypes.object,
    getHandleStatus: PropTypes.func,
    handleStatusList: PropTypes.array,
};
const mapStateToProps = (state) => ({
    failStarOrderList: state.StarOrder.failStarOrderList,
    handleStatusList: state.StarOrder.handleStatusList
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getFailStarOrderList, getHandleStatus })(Main);
