/**
 *  评论管理 - 社区评论
 * */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './tableContents/TableData';
import { getCommunitySource, getCommunityselectDataSource, getCommunitySelectSource } from '@/reducers/commentmanage/communitycomment/actions';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class ProductComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paginationCache: {}, // 分页缓存对象，实现的功能是点击分页操作时不带条件搜索
      community: {}, // 社区分类
      communityNames: '', // 主题名称,
      communityPerson: '', // 评论人
      communityContent: '', // 评论内容
      feedType: 'GRAPHICS', // 固定为图文标识字符串 
    };
  }

  componentDidMount() {
    const { history, keepSecondNavBreadcrumb, getCommunityselectDataSource, getCommunitySource } = this.props;
    if (history.location.state && history.location.state.keepAlive) {
      console.log('使用缓存记录');
    } else {
      keepSecondNavBreadcrumb();//面包屑
      // getCommunitySource(20, 1, 'GRAPHICS');// 列表数据方法
      getCommunityselectDataSource();// 下拉选项数据方法
    }
  }

  // 分页
  _paginationChange = (pageSize, pageNum) => {
    this.setState({ pageSize, pageNum });
    this.searchCache = { ...this.state.paginationCache, pageSize, pageNum, feedType: 'GRAPHICS' };
    this.props.getCommunitySelectSource(this.searchCache);
    KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
  }

  // 社区分类
  _communityChangeHandle = (value) => {
    this.setState({ community: value });
  }
  // 主题名称
  _communityItemChangeHandle = (value) => {
    this.setState({ communityNames: value });
  }
  // 评论人
  _communityPersonalChangeHandle = (value) => {
    this.setState({ communityPerson: value });
  }
  // 评论内容
  _communityContentChangeHandle = (value) => {
    this.setState({ communityContent: value });
  }
  // 查询触发
  _searchHandle = (useCache) => {
    const { community, communityNames, communityContent, communityPerson, pageNum, pageSize } = this.state;
    const paginationCache = {
      pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
      pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
      feedClassId: community.id,
      feedType: 'GRAPHICS',
      feedTitle: communityNames,
      watchAccountName: communityPerson,
      content: communityContent,
    };
    this.searchCache = paginationCache;
    this.setState({ paginationCache });
    this.props.getCommunitySelectSource(this.searchCache);
    KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】

  }

  // 重置触发
  _communitySresetHandle = () => {
    this.setState({ paginationCache: {}, community: {}, communityNames: '', communityPerson: '', communityContent: '' }, () => {
      KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
    });
  }

  // 表格操作功能回调
  _actionClickHandle = (id, type) => {
    const { history, addOneBreadcrumbPath } = this.props;
    if (type === 'DETAILS_INFO') {
      addOneBreadcrumbPath({
        title: '社区评论详情',
        path: '/home/commentmanage/communitycomment/contentInfo/' + id,
      });
      history.push({
        pathname: '/home/commentmanage/communitycomment/contentInfo/' + id,
      });
    }
  }

  render() {
    const { tableSource, SelectData, paginations } = this.props;
    const { community, communityNames, communityContent, communityPerson } = this.state;
    return (
      <KeepAlive render={(state) => {
        if (!this.state.__aliveInit) {
          this.setState({ ...state, __aliveInit: true }, () => {
            this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
          });
        }
      }}>
        <div className={styles.flexBoxContainer}>
          <SearchBox>
            <SearchItem>
              <XSelect
                label='社区分类'
                placeholder='请选择'
                renderData={SelectData}
                onChange={this._communityChangeHandle}
                dataIndex='className'
                keyIndex='id'
                value={community.className}
                showSearch={true}
              />
            </SearchItem>
            <SearchItem>
              <XInput
                label='主题名称'
                placeholder='主题名称'
                onChange={this._communityItemChangeHandle}
                value={communityNames}
              />
            </SearchItem>
            <SearchItem>
              <XInput
                label='评论人'
                placeholder='评论人'
                onChange={this._communityPersonalChangeHandle}
                value={communityPerson}
              />
            </SearchItem>
            <SearchItem>
              <XInput
                label='评论内容'
                placeholder='评论内容'
                onChange={this._communityContentChangeHandle}
                value={communityContent}
              />
            </SearchItem>
            <SearchItem>
              <XOKButton
                label='查询'
                onClick={this._communitySearchHandle}
              />
              <XCancelButton
                label='重置'
                onClick={this._communitySresetHandle}
              />
            </SearchItem>
          </SearchBox>
          <div className={styles.tableContainer}>
            <Table
              rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
              rowKey={(record, index) => index}
              columns={takeColumns(this._actionClickHandle)}
              dataSource={takeDataSource(tableSource)}
              pagination={false}
              scroll={{ y: true }}
            />
            <XPagination
              resultTotal={paginations.resultTotal}
              pageSize={paginations.pageSize}
              pageNum={paginations.pageNum}
              onChange={this._paginationChange}
            />
          </div>
        </div>
      </KeepAlive>
    );
  }
}

ProductComment.propTypes = {
  history: PropTypes.object, // 路由history
  addOneBreadcrumbPath: PropTypes.func,
  keepSecondNavBreadcrumb: PropTypes.func,
  getCommunitySource: PropTypes.func, // 通过Saga异步请求表格数据源的Action
  getCommunityselectDataSource: PropTypes.func,// 通过Saga异步请求下拉选项数据方法Action
  getCommunitySelectSource: PropTypes.func,// 通过Saga异步请求列表查询方法Action
  tableSource: PropTypes.array,
  SelectData: PropTypes.array,
  paginations: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    tableSource: state.communitycomment.communitySourceDate.tableSource, // 表格数据源
    SelectData: state.communitycomment.communitySourceDate.SelectData, // 社区评论下拉选项
    paginations: state.communitycomment.communitySourceDate.paginations, // 分页数据
  };
};


export default connect(mapStateToProps, { getCommunitySource, getCommunityselectDataSource, getCommunitySelectSource, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(ProductComment);