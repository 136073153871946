/**
 * [同步平台商品分类弹窗]
 * @author: Fu Xiaochun
 * @date: 2021-06-02 
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, Select, message, Form } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';

const { Option } = Select;
 
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
    }
};

const SyncClassify = (props) => {
    const categoryType = 'MALL';

    const [categoryId, setCategoryId] = useState(0);
    const [levelOne, setLevelOne] = useState([]);
    const [levelTwo, setLevelTwo] = useState([]);
    const [levelOneVal, setLevelOneVal] = useState();
    const [levelTwoVal, setLevelTwoVal] = useState();

    const [confirmLoading, setConfirmLoading] = useState(false);

    // 获取分类数据
    const getCategory = (categoryGrade, parentId, setFunc)=>{
        http('/goods/category/listItem', { categoryGrade, parentId, categoryType }, 'POST')
            .then(res => {
                if (res.status == 200) {
                    setFunc(res.result);
                } else {
                    console.log('请求商品状态失败');
                }
            })
            .catch(() => {
                console.log('请求商品状态失败');
            });
    };

    useEffect(()=>{
        getCategory(1, 0, setLevelOne);
    }, []);
    
    const _onLevelZeroSelected = ()=>{
        setCategoryId(0);
        setLevelOneVal();
        setLevelTwoVal();
    };
    
    const _onLevelOneSelected = (val)=>{
        setCategoryId(val);
        setLevelOneVal(val);
        setLevelTwoVal();
        getCategory(2, val, setLevelTwo);
    };
    
    const _onLevelTwoSelected = (val)=>{
        setCategoryId(val);
        setLevelTwoVal(val);
    };

    // 取消/关闭
    const _handleCancel = () => {
        props.onClose();
    };

    // 确认
    const _handleOk = ()=>{
        props.onOk(categoryId);
    };

    return (
        <Modal
            width={650}
            centered
            title='商品分类'
            visible={true}
            confirmLoading={confirmLoading}
            onOk={_handleOk}
            onCancel={_handleCancel}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            okText='确认'
        >
            <Form id='syncClassify' style={{height: '350px'}} {...formItemLayout}>
                <Form.Item>
                    <div>请选择同步到平台的所属分类：</div>
                </Form.Item>
                <SearchBox dropdownStyle={{maxHeight: '100px'}}>
                    <SearchItem>
                        <Select defaultValue="0" style={{ width: '150px', marginRight: '15px' }} onSelect={_onLevelZeroSelected}>
                            <Option value="0">顶级分类</Option>
                        </Select>
                    </SearchItem>
                    <SearchItem>
                        <Select style={{ width: '150px', marginRight: '15px' }} 
                            value={levelOneVal}
                            onChange={_onLevelOneSelected} 
                            placeholder="一级分类">
                            {levelOne.map((v)=>{
                                return <Option key={v.id} value={v.id}>{v.categoryName}</Option>;
                            })}
                        </Select>
                    </SearchItem>
                    <SearchItem>
                        <Select style={{ width: '150px', marginRight: '15px' }} value={levelTwoVal} onChange={_onLevelTwoSelected} placeholder="二级分类">
                            {levelTwo.map((v)=>{
                                return <Option key={v.id} value={v.id}>{v.categoryName}</Option>;
                            })}
                        </Select>
                    </SearchItem>
                </SearchBox>
            </Form>
        </Modal>
    );
};

SyncClassify.propTypes = {
    onClose: PropTypes.func.isRequired, // 关闭弹框的回调函数
    onOk: PropTypes.func.isRequired
};

export default Form.create({ name: 'syncClassify' })(SyncClassify);