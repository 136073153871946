import React from 'react';
import styles from './TableData.module.scss';
import { Switch, } from 'antd';
import { regFenToYuan, } from '@/util/money';
import { tooltipStyle, tooltip } from '@/components/TableCell';


/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
    {
        title: '操作平台',
        dataIndex: 'deviceType',
        key: 'deviceType',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '名称',
        dataIndex: 'showMsg',
        key: 'showMsg',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '金额(元)',
        dataIndex: 'stardealPrice',
        key: 'stardealPrice',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '魅力值',
        dataIndex: 'starLightNum',
        key: 'starLightNum',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作人',
        dataIndex: 'createUserId',
        key: 'createUserId',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        width: '12%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    // {
    //     title: '充值次数',
    //     dataIndex: 'gmtCreate',
    //     key: 'gmtCreate',
    //     width: '12%',
    //     align: 'center',
    //     render: (text) => (TableCell(text))
    // },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '9%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle))
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: '13%',
        render: (id, record) => TableAction(record, actionClickHandle)
    },
];

export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    if (data) {
        return data.map((item) => {
            item.stardealPrice = regFenToYuan(item.starLightPrice);
            return item;
        });
    }
};
// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
    let checked = true;
    if (sw == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                key={item.id + '_' + checked}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={(value) => {
                    actionClickHandle(item, 'switch', value);
                }}
            />
        </div>
    );
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};


const TableAction = (record, actionClickHandle) => {
    if (record.deviceType == 'IOS') {
        return (
            <div className={styles.action}>无操作</div>
        );
    } else {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => actionClickHandle(record, 'EDIT')}>编辑</div>
                <div className={styles.item} onClick={() => actionClickHandle(record, 'DELETE')}>删除</div>
            </div>
        );
    }
};