/**
 * 运营订单销售统计
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import Offline from './Offline';
import Online from './Online';

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '线上订单' },
            { id: 2, label: '实体订单' },
        ],
        curTabID: 1,  // 默认显示的tab，1：线上订单 2：实体订单
    };

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        const { history } = this.props;
        switch (this.state.curTabID) {
            case 1:
                return <Online history={history} />;
            case 2:
                return <Offline history={history} />;
            default:
                return <Online history={history} />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={`${styles.page} ${styles.flexBoxContainer}`}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
};

export default connect(null, { keepSecondNavBreadcrumb })(Main);