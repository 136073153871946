/**
 * 新建与编辑计费比例设置
 */
import React from 'react';
import { Modal, Input, DatePicker, Form, Radio, message, InputNumber, Select } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './CreateModal.module.scss';
import http from '@/assets/api/http';
import moment from 'moment';
import { regFenToYuan, regYuanToFen } from '@/util/money';
import { getSettingCode, getAllocationDetail } from '@/reducers/systemmanage/allocation/actions';


const { TextArea } = Input;
const RadioGroup = Radio.Group;
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};
class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
            settingCode: {},
            ratio: null,
            allocationDetail: {},
        };
    }
    componentDidMount() {
        this.props.getSettingCode();
        const { id } = this.props.data;
        // this.props.getAllocationDetail({ratioSettingId: id});
        this._getDetailInfo(id);
    }
    // 获取详情
    _getDetailInfo = (id) => {
        http(`/settle/ratioSetting/${id}`, {}, 'POST').then((res) => {
            var allocationDetail = res.result || {};
            this.setState({
                allocationDetail,
            });
        }).catch((reject) => {
            message.error(reject.message);
        });
    }
    _handleOk = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const { settingCode: { ratioSettingCodes = [] } } = this.props;
                var settingName = ratioSettingCodes.find(i => i.code === fieldsValue.settingCode);
                const { id } = this.props.data;
                const param = {
                    id,
                    settingCode: fieldsValue.settingCode,
                    settingName: settingName.name,
                    ratio: regYuanToFen(fieldsValue.ratio),
                    default: fieldsValue.default,
                    remark: fieldsValue.remark,
                    startDate: moment(moment(fieldsValue.dateArr[0]).format()).format('YYYY-MM-DD HH:mm:ss'),
                    endDate: moment(moment(fieldsValue.dateArr[1]).format()).format('YYYY-MM-DD HH:mm:ss'),
                };
                this.setState({ confirmLoading: true }, () => {
                    http('/settle/ratioSetting/updRatioSetting', param, 'POST').then(() => {
                        message.success('编辑成功');
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                        this.props.closeMode();
                        this.props.refresh('useCache');
                    }).catch((reject) => {
                        message.error(reject.message);
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                        this.props.closeMode();
                    });
                });
            }
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    _rateChange = (rule, value, callback) => {

        if (value && /^(\d{1,9}(\.\d{1,1})?|60)$/.test(value)) {
            callback();
        } else {
            callback('请输入1-60的数字，支持一位小数！');
        }

    }
    render() {
        const { visible, confirmLoading, title, allocationDetail } = this.state;
        const { settingCode: { ratioSettingCodes = [] } } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={600}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon={false}
                            htmlFor='settingCode'
                            label={<span>设置项</span>}
                        >
                            {getFieldDecorator('settingCode', {
                                rules: [{ required: true, message: '请选择' }],
                                initialValue: allocationDetail.settingCode,
                            })(
                                <Select
                                    showSearch
                                    style={{ width: 400 }}
                                    placeholder='请选择'
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {ratioSettingCodes.map(i => {
                                        return <Option value={i.code} key={i.code}>{i.name}</Option>;
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='ratio'
                            label={<span>参数值 %</span>}
                        >
                            {getFieldDecorator('ratio', {
                                initialValue: Number(regFenToYuan(allocationDetail.ratio)),
                                rules: [{ required: true, message: '请输入' }]
                                // {
                                //     validator: this._rateChange,
                                // }],

                            })(
                                <InputNumber precision={1} min={1} max={60} placeholder="请输入1~60" style={{ width: 400 }} />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='dateArr'
                            label={<span>执行时间</span>}
                        >
                            {getFieldDecorator('dateArr', {
                                initialValue: [moment(allocationDetail.startDate || null, dateFormat), moment(allocationDetail.endDate || null, dateFormat)],
                                rules: [{ required: true, message: '请输入' }],
                            })(
                                <RangePicker
                                    style={{ width: '400px' }}
                                    // showTime={{ format: 'HH:mm:ss' }}
                                    format="YYYY-MM-DD"
                                    isRequired={true}
                                    placeholder={['开始时间', '结束时间']}
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='default'
                            label={<span>默认项</span>}
                        >
                            {getFieldDecorator('default', {
                                rules: [{ required: true, message: '请选择' }],
                                initialValue: allocationDetail.default ? 1 : 0,
                            })(
                                <RadioGroup style={{ marginLeft: '25px' }}>
                                    <Radio value={1}>是</Radio>
                                    <Radio value={0}>否</Radio>
                                </RadioGroup>
                            )}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='remark'
                            label={<span>说明</span>}
                        >
                            {getFieldDecorator('remark', {
                                rules: [{ required: true, message: '请输入' }],
                                initialValue: allocationDetail.remark,
                            })(
                                <TextArea
                                    placeholder="请输入"
                                    autosize={{ minRows: 4, maxRows: 16 }}
                                    style={{ width: 400 }}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

CreateModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    getSettingCode: PropTypes.func,
    getAllocationDetail: PropTypes.func,
    allocationDetail: PropTypes.object,
    settingCode: PropTypes.object,
    refresh: PropTypes.func,
    form: PropTypes.object.isRequired, // 校验
};
const mapStateToProps = (state) => {
    return {
        settingCode: state.Allocation.settingCode,
        allocationDetail: state.Allocation.allocationDetail,
    };
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(CreateModal);// 校验
export default connect(mapStateToProps, { getSettingCode, getAllocationDetail })(ForgotProducDetails);
