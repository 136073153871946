import React from 'react';
import PropTypes from 'prop-types';
import styles from './Table.module.scss';
import { Table } from 'antd';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

export default function PayAmountTable({ renderData }) {

    //表头
    const _columns = () => {
        return [
            {
                title: '商品名称',
                dataIndex: 'goodsName',
                key: 'goodsName',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '商品分类',
                dataIndex: 'goodsFullCategoryName',
                key: 'goodsFullCategoryName',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '营销活动',
                dataIndex: 'activityTitle',
                key: 'activityTitle',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '活动销量',
                dataIndex: 'saleNumMarketing',
                key: 'saleNumMarketing',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '活动销售金额（元）',
                dataIndex: 'saleMoneyMarketing',
                key: 'saleMoneyMarketing',
                width: '13%',
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '活动占比（%）',
                dataIndex: 'marketingRatio',
                key: 'marketingRatio',
                width: '12%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '成功退款金额（元）',
                dataIndex: 'refundMoney',
                key: 'refundMoney',
                width: '13%',
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '售后占比（%）',
                dataIndex: 'saleAfterRatio',
                key: 'saleAfterRatio',
                width: '12%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            }
        ];
    };

    //渲染
    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                columns={_columns()}
                dataSource={renderData}
                pagination={false}
                scroll={{ y: true }}
            />
        </div>
    );
}

//props校验
PayAmountTable.propTypes = {
    renderData: PropTypes.array.isRequired,//表格数据元
};