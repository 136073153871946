/**
 * 判断用户身份（平台、运营中心、区域公司）
 */
import { getLocalStorage } from '@/assets/js/storage';

// 判断用户是否为平台用户
export const isPlat = () => {
    let boolean = false;
    if (getLocalStorage('auth')) {
        const type = JSON.parse(getLocalStorage('auth')).userInfo.accountType;
        if (type == 'PLAT') {
            boolean = true;
        }
    }
    return boolean;
};

// 判断用户是否为运营中心用户
export const isOC = () => {
    let boolean = false;
    if (getLocalStorage('auth')) {
        const type = JSON.parse(getLocalStorage('auth')).userInfo.accountType;
        if (type == 'OC') {
            boolean = true;
        }
    }
    return boolean;
};

// 判断用户是否为区域公司用户
export const isRE = () => {
    let boolean = false;
    if (getLocalStorage('auth')) {
        const type = JSON.parse(getLocalStorage('auth')).userInfo.accountType;
        if (type == 'RE') {
            boolean = true;
        }
    }
    return boolean;
};

// 判断用户类型是领舞
export const isDance = () => {
    let boolean = false;
    if (getLocalStorage('auth')) {
        const type = JSON.parse(getLocalStorage('auth')).userInfo.type;
        if (type == 'DANCE') {
            boolean = true;
        }
    }
    return boolean;
};