import * as T from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const financeArgDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_FINANCEARG_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

export const financeInfoList = (state = initData, action) => {
    switch (action.type) {
        case T.SET_FINANCEINFO_LIST:
            return action.payload.result;
        default:
            return state;
    }
};