import 'react-app-polyfill/ie9'; // This must be the first line in src/index.js
import 'core-js/es/map';
import 'core-js/es/set';
import React from 'react';
import ReactDOM from 'react-dom';
import store from '@/store';
import App from '@/App';
import ajaxConfig from '@/assets/api/ajaxConfig';
import antdConfig from '@/assets/js/antdConfig';
import { Provider } from 'react-redux';
import '@/assets/css/base.scss';
import 'ant-design-pro/dist/ant-design-pro.css'; // 统一引入样式
import { LocaleProvider } from 'antd';
import zh_CN from 'antd/es/locale-provider/zh_CN';

antdConfig(); // 配置AntDesign
ajaxConfig(); // 配置Ajax

ReactDOM.render(
    <Provider store={store}>
        <LocaleProvider locale={zh_CN}>
            <App />
        </LocaleProvider>
    </Provider>, document.getElementById('root')
);
