import * as T from './actiontypes';

// 获取'贴纸分类'下拉数据
export const getStickypaperClassSelectSource=(prop)=>({
    type:T.GET_STICKYPAPERCLASS_SELECTSOURCE,
    payload:{}
});

// 获取'贴纸分类'表格
 export const getStickypaperTypeTableSource=(requestProps)=>({
    type:T.GET_STICKYPAPERTYPE_TABELSOURCE,
    payload:{
        requestProps
    }
 });

// 获取'贴纸列表'表格
 export const getStickypaperListTableSource=(requestProps)=>({
    type:T.GET_STICKYPAPERLIST_TABELSOURCE,
    payload:{
        requestProps
    }
 });

