import { createModel } from '@/store/tools';

const model = {
    namespace: 'businessCategory',
    build: (T, A, S, R) => ({
        actiontypes: {
            // 'getPositiveAuditData': T('getPositiveAuditData'),
            'getProductUsername': T('getProductUsername'),
            'getGoodsAuditProcessEnum': T('getGoodsAuditProcessEnum'),
            'getCategoryList': T('getCategoryList'),
            // 'getPositiveAuditInfo': T('getPositiveAuditInfo'),
            // 'getClassList': T('getClassList'),
            // 'getCreditAuditData': T('getCreditAuditData'),
            // 'getStatusEnum': T('getStatusEnum'),
        },
        actions: {
            // 'getPositiveAuditData': A('getPositiveAuditData'),
            'getProductUsername': A('getProductUsername'),
            'getGoodsAuditProcessEnum': A('getGoodsAuditProcessEnum'),
            'getCategoryList': A('getCategoryList'),
            // 'getPositiveAuditInfo': A('getPositiveAuditInfo'),
            // 'getClassList': A('getClassList'),
            // 'getCreditAuditData': A('getCreditAuditData'),
            // 'getStatusEnum': A('getStatusEnum'),
        },
        sagas: {
            // 'getPositiveAuditData': S('getPositiveAuditData', '/user/supplierAccountInfo/supplierAccountAuditList'),
            'getProductUsername': S('getProductUsername', '/merchant/info/listMerchantIdAndName'),
            'getGoodsAuditProcessEnum': S('getGoodsAuditProcessEnum', '/enum/GoodsAuditProcessEnum'),
            'getCategoryList': S('getCategoryList', '/goods/category/list'),
            // 'getPositiveAuditInfo': S('getPositiveAuditInfo', '/user/supplierAccountInfo/supplierAccountAuditInfo', 'POST', 'id'),
            // 'getClassList': S('getClassList', '/supplier/config/category/list'),
            // 'getCreditAuditData': S('getCreditAuditData', '/settle/creditLimitReview/queryCreditLimitRecordsList'),
            // 'getStatusEnum': S('getStatusEnum', '/enum/CreditsAuditStatusEnum'),
        },
        reducers: {
            // 'positiveAuditData': R('getPositiveAuditData', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'productUsername': R('getProductUsername', {}),
            'goodsAuditProcessEnum': R('getGoodsAuditProcessEnum', []),
            // 'positiveAuditInfo': R('getPositiveAuditInfo', {}),
            'categoryList': R('getCategoryList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            // 'creditAuditData': R('getCreditAuditData', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            // 'statusEnum': R('getStatusEnum', []),
        }
    })
};
export default createModel(model);