import * as T from './actiontypes';

//敏感词库表
export const getSensitiveWordInfo = () => ({
    type: T.GET_SENSITIVEWORD_INFO,
    payload: {}
});
//敏感词库表 19/11/12
export const getSensitiveWordInfos = () => ({
    type: T.GET_SENSITIVEWORD_INFOS,
    payload: {}
});
