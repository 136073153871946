import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

// 获取角色列表
export const getRolesList = function* () {
    yield takeEvery(T.GET_ROLES_LIST, function* requestData(action) {
        try {
            let result = yield http('/admin/role/listRoles', action.payload, 'POST');
            yield put({ type: T.SET_ROLES_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取角色详情
export const getRolesDetail = function* () {
    yield takeEvery(T.GET_ROLES_DETAIL, function* requestData(action) {
        try {
            const id = action.payload.id;
            let result = yield http('/admin/role/getRoleInfoById/' + id, action.payload, 'POST');
            yield put({ type: T.SET_ROLES_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取本公司超级管理员
export const getAdminEmpl = function* () {
    yield takeEvery(T.GET_ADMIN_EMPL, function* requestData(action) {
        try {
            let result = yield http('/admin/empl/listAdminEmpl', action.payload, 'POST');
            yield put({ type: T.SET_ADMIN_EMPL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取平台、运营中心、区域中心、商户某个角色下面得所有员工
export const getEmplsByRole = function* () {
    yield takeEvery(T.GET_EMPL_BY_ROLE, function* requestData(action) {
        try {
            let result = yield http('/admin/empl/listEmplsByRole', action.payload, 'POST');
            yield put({ type: T.SET_EMPL_BY_ROLE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取未分类角色员工
export const getUnEmpl = function* () {
    yield takeEvery(T.GET_UN_EMPL, function* requestData(action) {
        try {
            let result = yield http('/admin/empl/listUnassortedEmpl', action.payload, 'POST');
            yield put({ type: T.SET_UN_EMPL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取角色下拉列表(复制角色权限以及权限分配时均可用)
export const getRoleSelect = function* () {
    yield takeEvery(T.GET_ROLE_SELECT, function* requestData(action) {
        try {
            let result = yield http('/admin/role/listRole', action.payload, 'POST');
            yield put({ type: T.SET_ROLE_SELECT, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取某角色权限的菜单
export const getMenuByRole = function* () {
    yield takeEvery(T.GET_MENU_BY_ROLE, function* requestData(action) {
        try {
            let result = yield http('/admin/menu/listMenuByRole', action.payload, 'POST');
            yield put({ type: T.SET_MENU_BY_ROLE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取员工下拉列表（在新建员工角色时用到）
export const getDownEmpls = function* () {
    yield takeEvery(T.GET_DOWNEMPLS, function* requestData(action) {
        try {
            let result = yield http('/admin/empl/listDownEmpls', action.payload, 'POST');
            yield put({ type: T.SET_DOWNEMPLS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 根据员工获取角色列表（对已关联得角色做记号）
export const getListRoleByEmpl = function* () {
    yield takeEvery(T.GET_ROLE_BY_EMPL, function* requestData(action) {
        try {
            let result = yield http('/admin/role/listRoleByEmpl', action.payload, 'POST');
            yield put({ type: T.SET_ROLE_BY_EMPL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

