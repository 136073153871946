/**
 * 售后管理
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as SALE from './actionTypes';

// 售后列表
export const getSaleList = function* () {
    yield takeEvery(SALE.GET_SALELIST_LIST, function* requestData(action) {
        try {
            let result = yield http('/boss/order/getAfterSaleOrderList', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_SALELIST_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 受理详情
export const getProcessedDetail = function* () {
    yield takeEvery(SALE.GET_PROCESS_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/boss/order/processedDetail', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_PROCESS_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 售后详情仅退款
export const getSaleOnlyMoneyDetail = function* () {
    yield takeEvery(SALE.GET_SALELIST_DETAIL_ONLYMONEY, function* requestData(action) {
        try {
            let result = yield http('/boss/order/getAfterSaleOrderDetailsOnlyMoney', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_SALELIST_DETAIL_ONLYMONEY, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 售后详情退货退款
export const getSaleGoodsandmoneyDetail = function* () {
    yield takeEvery(SALE.GET_SALELIST_DETAIL_GOODSMONEY, function* requestData(action) {
        try {
            let result = yield http('/boss/order/getAfterSaleOrderDetailsreturnGoodsandmoney', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_SALELIST_DETAIL_GOODSMONEY, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 售后详情换货
export const getSaleChangeGoodsDetail = function* () {
    yield takeEvery(SALE.GET_SALELIST_DETAIL_CHANGEGOODS, function* requestData(action) {
        try {
            let result = yield http('/boss/order/getAfterSaleOrderDetailsChangeGoods', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_SALELIST_DETAIL_CHANGEGOODS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 售后状态
export const getSaleStatus = function* () {
    yield takeEvery(SALE.GET_SALESTATUS, function* requestData(action) {
        try {
            let result = yield http('/enum/SalesProssStatusEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_SALESTATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 售后类型
export const getSaleType = function* () {
    yield takeEvery(SALE.GET_SALETYPE, function* requestData(action) {
        try {
            let result = yield http('/enum/SalesAfterTypeEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_SALETYPE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


