//异步获取数据
export const GET_VIDEOAUDIT_LIST = 'VIDEOAUDIT/GET_VIDEOAUDIT_LIST';
export const GET_VIDEOAUDIT_DETAIL = 'VIDEOAUDIT/GET_VIDEOAUDIT_DETAIL';
export const GET_VIDEOAUDIT_SELE = 'VIDEOAUDIT/GET_VIDEOAUDIT_SELE';
export const GET_ENUM_SYSTEMTYPE = 'GET_ENUM_SYSTEMTYPE';

//保存数据到store.state
export const SET_VIDEOAUDIT_LIST = 'VIDEOAUDIT/SET_VIDEOAUDIT_LIST';
export const SET_VIDEOAUDIT_DETAIL = 'VIDEOAUDIT/SET_VIDEOAUDIT_DETAIL';
export const SET_VIDEOAUDIT_SELE = 'VIDEOAUDIT/SET_VIDEOAUDIT_SELE';
export const SET_ENUM_SYSTEMTYPE = 'SET_ENUM_SYSTEMTYPE';

