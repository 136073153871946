/**
 * 直播间列表
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm, XTableAdapter } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { setSessionStorage } from '@/assets/js/storage';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/knowledgepay/channellist/model';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import OnlinePlayModal from './modal/OnlinePlayModal';
import BanModal from './modal/BanModal';
import { SearchBox, SearchItem } from '@/components/Layout';

class VideoList extends Component {

    state = {
        renderMode: '',
        modeParams: {},

        feedTitle: '',
        tel: '',
        classifies: {},
        homeRec: {},
        channelRec: {},
        scrollY: ''
    }

    componentDidMount() {
        this.props.getKClassifySele({ classTypeCode: 'CHARGE_SHORT_VIDEO_CLASS' });
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, homeRec, channelRec, feedTitle, classifies, tel } = this.state;
        this.searchCache = {
            feedTitle, tel, chargeFeedClassId: classifies.id,
            isAllReco: homeRec.code, isChannelReco: channelRec.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getKVideoList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ homeRec: {}, channelRec: {}, feedTitle: '', classifies: {}, tel: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 分页发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { modeParams, renderMode } = this.state;
        switch (renderMode) {
            case 'player':
                return <OnlinePlayModal visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
            case 'ban':
                return <BanModal visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ renderMode: '' }, () => {
            update && this._searchHandle('useCache');
        });
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case 'createVideo':
                addOneBreadcrumbPath({
                    title: '创建视频',
                    path: '/home/knowledgepay/channellist/createvideo/'
                });
                history.push({
                    pathname: '/home/knowledgepay/channellist/createvideo/'
                });
                break;
            case 'editVideo':
                addOneBreadcrumbPath({
                    title: '编辑视频',
                    path: '/home/knowledgepay/channellist/editvideo/'
                });
                history.push({
                    pathname: '/home/knowledgepay/channellist/editvideo/' + value
                });
                break;
            case 'detail':
                addOneBreadcrumbPath({
                    title: '视频详情',
                    path: '/home/knowledgepay/channellist/videodetail/'
                });
                history.push({
                    pathname: '/home/knowledgepay/channellist/videodetail/' + value
                });
                break;
            case 'recommendList':
                addOneBreadcrumbPath({
                    title: '推荐列表',
                    path: '/home/knowledgepay/channellist/videorecommend/'
                });
                history.push({
                    pathname: '/home/knowledgepay/channellist/videorecommend/'
                });
                break;
            case 'beans':
                addOneBreadcrumbPath({
                    title: '魅力值收入',
                    path: '/home/knowledgepay/channellist/videobeans/'
                });
                history.push({
                    pathname: '/home/knowledgepay/channellist/videobeans/' + value
                });
                break;
            case 'sell':
                addOneBreadcrumbPath({
                    title: '销售商品分红',
                    path: '/home/knowledgepay/channellist/videosell/'
                });
                history.push({
                    pathname: '/home/knowledgepay/channellist/videosell/' + value
                });
                break;
            case 'knowledge':
                addOneBreadcrumbPath({
                    title: '知识付费收益',
                    path: '/home/knowledgepay/channellist/videoknowledge/'
                });
                history.push({
                    pathname: '/home/knowledgepay/channellist/videoknowledge/' + value
                });
                break;
        }
    }

    _reqFunc = (url, data, tip) => {
        http(url, data, 'POST')
            .then(() => {
                message.success(tip);
                this._searchHandle('useCache');
            }).catch(error => {
                message.error(error.mssage);
            });
    }

    //表格操作
    _tableAction = (id, active, value) => {
        switch (active) {
            case 'beans':
                setSessionStorage('kvbeansinfo', { telephone: value.tel, nickName: value.nickname, videoTitle: value.feedTitle, classify: value.chargeFeedClassName });
                this._updateContent('beans', id);
                break;
            case 'sell':
                setSessionStorage('kvsellinfo', { telephone: value.tel, nickName: value.nickname, videoTitle: value.feedTitle, classify: value.chargeFeedClassName });
                this._updateContent('sell', id);
                break;
            case 'knowledge':
                setSessionStorage('kvknowledgeinfo', { telephone: value.tel, nickName: value.nickname, videoTitle: value.feedTitle, classify: value.chargeFeedClassName });
                this._updateContent('knowledge', id);
                break;
            case 'home':
                console.log(value);
                this._reqFunc('/show/chargefeed/updateChargeFeedAllReco', { id, isAllReco: value.isAllReco ? 1 : 0 }, '全部列表推荐操作成功');
                break;
            case 'channel':
                this._reqFunc('/show/chargefeed/updateChargeFeedChannelReco', { id, isChannelReco: value.isChannelReco ? 1 : 0 }, '频道列表推荐操作成功');
                break;
            case 'detail':
                this._updateContent('detail', id);
                break;
            case 'edit':
                this._updateContent('editVideo', id);
                break;
            case 'preview':
                this.setState({ renderMode: 'player', modeParams: { playUrl: value.playUrl, coverPage: value.coverPage } });
                break;
            case 'ban':
                this.setState({ renderMode: 'ban', modeParams: { id } });
                break;
            case 'delete':
                showConfirm('您确定删除当前数据？', '', () => {
                    this._reqFunc('/show/chargefeed/deleteChargeFeedVideo', { id }, '删除成功');
                });
                break;
            default:
                break;
        }
    }

    render() {
        const { homeRec, channelRec, feedTitle, classifies, tel, scrollY } = this.state;
        const { recEnum, kClassifySele, kVideoList } = this.props;
        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='视频主题'
                                    placeholder='请输入视频主题'
                                    bindName='feedTitle'
                                    bindThis={this}
                                    value={feedTitle}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='会员账号'
                                    placeholder='请输入会员账号'
                                    bindName='tel'
                                    bindThis={this}
                                    value={tel}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='视频分类'
                                    placeholder='请选择视频分类'
                                    renderData={kClassifySele}
                                    bindName='classifies'
                                    bindThis={this}
                                    dataIndex='className'
                                    keyIndex='id'
                                    value={classifies.className}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='全部列表推荐'
                                    placeholder='请选择'
                                    renderData={recEnum}
                                    dataIndex='value'
                                    keyIndex='code'
                                    bindName='homeRec'
                                    bindThis={this}
                                    value={homeRec.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='频道推荐'
                                    placeholder='请选择'
                                    renderData={recEnum}
                                    dataIndex='value'
                                    keyIndex='code'
                                    bindName='channelRec'
                                    bindThis={this}
                                    value={channelRec.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }} label='创建视频' onClick={() => this._updateContent('createVideo')} />
                        <XOKButton style={{ width: '120px', marginLeft: '20px' }} label='推荐列表' onClick={() => this._updateContent('recommendList')} />
                    </div>
                    <XTableAdapter render={scrollY => {
                        this.setState({ scrollY });
                    }}>
                        <TableContent paginationChange={this._paginationChange} scrollY={scrollY} renderData={kVideoList} tableAction={this._tableAction} />
                    </XTableAdapter>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

VideoList.propTypes = {
    history: PropTypes.object, // 路由history对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息
    getKVideoList: PropTypes.func,
    kVideoList: PropTypes.object,
    getKClassifySele: PropTypes.func,
    kClassifySele: PropTypes.array,
    recEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
    kVideoList: state.klist.kVideoList,
    kClassifySele: state.klist.kClassifySele,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(VideoList);