/*
*【商品信息TOP50秀场销售】
*/
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { Tag } from 'antd';
import { GoodsClassify } from '@/vcomps';
import { XDatePicker, XOKButton, XCancelButton, XSelect, XInput, XExpandOrContract } from '@/components/xqxc_ui';
import TableContent from './components/Table';
import model from '@/reducers/statistical/goodstotalcase/model';
import { SearchBox, SearchItem } from '@/components/Layout';

const tags = [
    { id: 1, color: '#f50', value: '本周' },
    { id: 2, color: '#2db7f5', value: '本月' },
    { id: 3, color: '#87d068', value: '本季' },
    { id: 4, color: '#108ee9', value: '全年' }];

const Show = (props) => {

    const { getShowSalesList, showSalesList, brands, merchants } = props;

    const [isShowMore, setIsShowMore] = useState(false);
    const [bizDateStart, setBizDateStart] = useState(null);
    const [bizDateEnd, setBizDateEnd] = useState(null);
    const [goodsName, setGoodsName] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [brand, setBrand] = useState({});
    const [merchant, setMerchant] = useState({});
    const [num] = useState(50);
    const [curTagID, setCurTagID] = useState(2);
    const [period, setPeriod] = useState('month');

    let cleanRef = useRef(null);

    useEffect(() => {
        getShowSalesList({ goodsName, categoryId, brandId: brand.id, merchantId: merchant.merchantId, num, period });
    }, []);

    //查询
    const _searchHandle = () => {
        if (bizDateStart && bizDateEnd) {
            setPeriod('');
            setCurTagID(0);
            getShowSalesList({ goodsName, categoryId, brandId: brand.id, merchantId: merchant.merchantId, bizDateStart, bizDateEnd, num });
        } else {
            getShowSalesList({ goodsName, categoryId, brandId: brand.id, merchantId: merchant.merchantId, num, period });
        }
    };

    // 重置
    const _resetHandle = () => {
        setBizDateStart(null);
        setBizDateEnd(null);
        setGoodsName('');
        setCategoryId('');
        setBrand({});
        setMerchant({});
        setCurTagID(2);
        setPeriod('month');
        cleanRef.clean();
    };

    // 点击Tags
    const _tagClick = (id) => {
        setBizDateStart(null);
        setBizDateEnd(null);
        setCurTagID(id);
        switch (id) {
            case 1:
                setPeriod('week');
                getShowSalesList({ goodsName, categoryId, brandId: brand.id, merchantId: merchant.merchantId, num, period: 'week' });
                break;
            case 2:
                setPeriod('month');
                getShowSalesList({ goodsName, categoryId, brandId: brand.id, merchantId: merchant.merchantId, num, period: 'month' });
                break;
            case 3:
                setPeriod('quarter');
                getShowSalesList({ goodsName, categoryId, brandId: brand.id, merchantId: merchant.merchantId, num, period: 'quarter' });
                break;
            case 4:
                setPeriod('year');
                getShowSalesList({ goodsName, categoryId, brandId: brand.id, merchantId: merchant.merchantId, num, period: 'year' });
                break;
        }
    };

    //渲染tags
    const _renderTags = () => {
        return (
            <div style={{ marginLeft: '20px' }}>
                {tags.map((item, index) => {
                    let color = curTagID == item.id ? item.color : '';
                    return <Tag style={{ width: '50px', textAlign: 'center', cursor: 'pointer' }}
                        key={index} color={color} onClick={() => { _tagClick(item.id); }}>
                        {item.value}</Tag>;
                })}
            </div>
        );
    };

    return (
        <div className={styles.flexBoxContainer}>
            <SearchBox>
                <SearchItem>
                    <XDatePicker
                        label='业务日期'
                        value={bizDateStart}
                        bindThis={setBizDateStart}
                        bindName='bizDateStart'
                        isFormat={true}
                        showTime={false}
                        defaultTime='00:00:00'
                    />
                    <XDatePicker
                        label=''
                        style={{ marginRight: '20px' }}
                        value={bizDateEnd}
                        bindThis={setBizDateEnd}
                        bindName='bizDateEnd'
                        isFormat={true}
                        showTime={false}
                        defaultTime='23:59:59'
                    />
                </SearchItem>
                <SearchItem>
                    <XInput
                        label='商品名称'
                        placeholder='请输入商品名称'
                        bindThis={setGoodsName}
                        bindName='goodsName'
                        value={goodsName}
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        label='商品品牌'
                        placeholder='请选择商品品牌'
                        renderData={brands}
                        dataIndex='brandName'
                        keyIndex='id'
                        value={brand.brandName}
                        bindThis={setBrand}
                        bindName='brand'
                        showSearch={true}
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        label='商户'
                        placeholder='请选择商户'
                        renderData={merchants}
                        dataIndex='merchantName'
                        keyIndex='merchantId'
                        value={merchant.merchantName}
                        bindThis={setMerchant}
                        bindName='merchant'
                        showSearch={true}
                    />
                </SearchItem>
                <SearchItem>
                    <GoodsClassify
                        style={{ marginRight: '20px' }}
                        label='商品分类'
                        ref={ele => cleanRef = ele}
                        onClassifyChange={value => setCategoryId(value)}
                    />
                </SearchItem>
                <SearchItem>
                    {_renderTags()}
                </SearchItem>
                <SearchItem>
                    <XOKButton
                        label='查询'
                        onClick={_searchHandle}
                    />
                    <XCancelButton
                        label='重置'
                        onClick={_resetHandle}
                    />
                </SearchItem>
            </SearchBox>
            <TableContent renderData={showSalesList} />
        </div>);
};

Show.propTypes = {
    history: PropTypes.object.isRequired,//路由对象
    getShowSalesList: PropTypes.func,
    showSalesList: PropTypes.array,
    brands: PropTypes.array,
    merchants: PropTypes.array
};

const mapStateToProps = (state) => ({
    showSalesList: state.goodstotalcase.showSalesList,
});

export default connect(mapStateToProps, { ...model.actions })(Show);