import React from 'react';
import styles from './TableMain.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class TableMain extends React.Component {

    _columns = () => [
        {
            title: '手机号码',
            dataIndex: 'tel',
            key: 'tel',
            align: 'center',
            width: '12%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            align: 'center',
            width: '12%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '图文标题',
            dataIndex: 'feedTitle',
            key: 'feedTitle',
            align: 'center',
            width: '12%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '图文类型',
            dataIndex: 'taskType',
            key: 'taskType',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '图文分类',
            dataIndex: 'className',
            key: 'className',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '审核状态',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            width: '8%',
            render: (state) => (this._stateCell(state))
        },
        {
            title: '提交时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: '12%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '审核人',
            dataIndex: 'approvalUserName',
            key: 'approvalUserName',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '审核时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            align: 'center',
            width: '12%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '10%',
            render: (id, record) => this._tableAction(id, record)
        }
    ];

    _stateCell = (state) => {
        return state.value;
    }

    _tableAction = (id, record) => {
        const { tableAction } = this.props;
        if (record.state.code == 'APPLY') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'audit', record)}>审核</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'detail', record)}>查看详情</div>
                </div>
            );
        } else {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'detail', record)}>查看详情</div>
                </div>
            );
        }

    };

    _tableData = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data;
    }

    render() {
        const { renderData, paginationChange } = this.props;
        console.log(renderData);

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._columns()}
                    pagination={false}
                    dataSource={this._tableData(renderData.dataList)}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableMain.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
};

export default TableMain;