/**
 * Saga集合
 */
import operationorder from './operationorder/model';
import companyorder from './companyorder/model';
import operationmember from './operationmember/model';
import merchantbusiness from './merchantbusiness/model';
import payment from './payment/model';
import platservicefee from './platservicefee/model';
import techservicefee from './techservicefee/model';
import showmonthly from './showmonthly/model';
import activitycost from './activitycost/model';
import showstarlight from './showstarlight/model';
import salereasion from './salereasion/model';
import goodscategory from './goodscategory/model';
import companycanvas from './companycanvas/model';
import companyincome from './companyincome/model';
import goodstotalcase from './goodstotalcase/model';
import statistishow from './statistishow/model';
import servicegrade from './servicegrade/model';

export default [
    ...Object.values(operationorder.sagas),
    ...Object.values(companyorder.sagas),
    ...Object.values(operationmember.sagas),
    ...Object.values(merchantbusiness.sagas),
    ...Object.values(payment.sagas),
    ...Object.values(platservicefee.sagas),
    ...Object.values(techservicefee.sagas),
    ...Object.values(showmonthly.sagas),
    ...Object.values(activitycost.sagas),
    ...Object.values(showstarlight.sagas),
    ...Object.values(salereasion.sagas),
    ...Object.values(goodscategory.sagas),
    ...Object.values(companycanvas.sagas),
    ...Object.values(companyincome.sagas),
    ...Object.values(goodstotalcase.sagas),
    ...Object.values(statistishow.sagas),
    ...Object.values(servicegrade.sagas)
];
