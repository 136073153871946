import React from 'react';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import Marketing from '../marketing';
import ManualWithdrawal from '../manualwithdrawal';
import SaleServiceFee from '../saleservicefee';
import GoodsAudit from '../goodsAudit';
import MerchantPermission from '../merchantpermission';

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '营销服务费' },
            // { id: 2, label: '营销活动审核设置' },
            // { id: 3, label: '手动结算权限设置' },
            // { id: 4, label: '商户批发商品免审核' },
            { id: 5, label: '商户业务权限设置' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
    };

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        switch (this.state.curTabID) {
            case 1:
                return <SaleServiceFee curTabID={this.state.curTabID} />;
            case 2:
                return <Marketing curTabID={this.state.curTabID} />;
            case 3:
                return <ManualWithdrawal curTabID={this.state.curTabID} {...this.props} />;
            case 4:
                return <GoodsAudit curTabID={this.state.curTabID} />;
            case 5:
                return <MerchantPermission curTabID={this.state.curTabID} />;
            default:
                return <SaleServiceFee curTabID={this.state.curTabID} />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={`${styles.flexBoxContainer}`}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

export default Main;