/**
 *  计费分配比例设置审核
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class AllocationAudit extends React.Component {

	componentDidMount() {
		const { keepSecondNavBreadcrumb } = this.props;
		keepSecondNavBreadcrumb();
	}

	// 更新界面
	_updateContent = (mode, value) => {
		const { history, addOneBreadcrumbPath } = this.props;
		switch (mode) {
			case 'audit':  //审核
				addOneBreadcrumbPath({
					title: '审核',
					path: '/home/auditmanage/allocationaudit/auditInfo/',
				});
				history.push({
					pathname: '/home/auditmanage/allocationaudit/auditInfo/' + value,
				});
				break;
		}
	}

	render() {
		return (
			<Main updateContent={this._updateContent} history={this.props.history} />
		);
	}
}

AllocationAudit.propTypes = {
	history: PropTypes.object, // 路由history对象
	match: PropTypes.object, // 路由match对象
	addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
	keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(AllocationAudit);