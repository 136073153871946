import * as T from './actiontypes';

export const getInvoiceList = (value) => ({
    type: T.GET_INVOICELIST,
    payload: value
});

export const getRegionalCompanyReconciliationTableSource = (value) => ({
    type: T.GET_REGIONALCOMPANYRECONCILIATION_TABLESOURCE,
    payload: value
});
export const getRegionalCompanyLists = (value = {}) => ({
    type: T.GET_REGIONALCOMPANY_LIST,
    payload: value
});
export const getRegionalCompanyDetail = (value) => ({
    type: T.GET_REGIONALCOMPANY_DETAIL,
    payload: value
});

