import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { XSelect, XOKButton, XCancelButton, XTabNav, XInput, XDatePicker } from '@/components/xqxc_ui';
import WidthDrawal from '../modal/withdrawal';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getMerchantSelectSource } from '@/reducers/storehousefinancialmanage/merchantreconciliation/actions';
import model from '@/reducers/storehousefinancialmanage/freeorderrefund/model';
import AdvertTable from './components/AdvertTable';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { message, Button } from 'antd';
import http from '@/assets/api/http';
import { regFenToYuan } from '@/util/money';

class Main extends React.Component {

    state = {
        orderId: undefined,
        merchant: {},
        currentOpenMode: '',
        batchWithdrawal: [],
        freeOrderStartTime: undefined,
        freeOrderEndTime: undefined,

        countAmount: 0,

        tab: [
            { id: 'error', label: '打款失败' },
            { id: 'success', label: '已打款' }
        ],
        curTabID: 'error'
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
        this.props.getMerchantSelectSource();
        http('/pay/transfer/countFreeOrderTransferAmount').then(res => {
            if (res.code === 200) {
                this.setState({ countAmount: regFenToYuan(res.result) });
            }
        });
    }
    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, merchant, orderId, freeOrderStartTime, freeOrderEndTime, curTabID } = this.state;
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            merchantId: merchant.merchantId,
            orderId,
            freeOrderStartTime,
            freeOrderEndTime,
            status: curTabID
        };
        this.props.getFreeOrderRefundList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ freeOrderStartTime: undefined, freeOrderEndTime: undefined, orderId: undefined, pageNum: 1, pageSize: 10, merchant: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        }, () => {
            this._searchHandle('useCache');
        });
    }
    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'open':
                return <WidthDrawal title='商户打款' visible={true} closeMode={this._closeMode} data={this.state.openModeParam} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (reload) => {
        this.setState({ currentOpenMode: '' });
        reload && this._searchHandle();
    }

    WidthDrawalHandle = (ids) => {
        ids = ids || this.state.batchWithdrawal;
        if (ids && ids.length > 0) {
            this.setState({ currentOpenMode: 'open', openModeParam: ids });
        } else {
            message.warning('请选择商户');
        }
    }

    _tableAction = (id, type, params) => {
        switch (type) {
            case 'withdrawal':
                this.WidthDrawalHandle([id]);
                break;
            case 'batch':
                this.setState({ batchWithdrawal: params });
                break;
        }
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID }, () => {
            this._searchHandle();
        });
    }

    render() {
        const { merchant = {}, orderId, freeOrderStartTime, freeOrderEndTime, countAmount } = this.state;
        const { merchantSelectSource, freeOrderRefundList } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <div style={{ height: 10 }}></div>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='订单编号'
                                placeholder='请输入'
                                value={orderId}
                                bindThis={this}
                                bindName='orderId'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户'
                                showSearch
                                placeholder='请选择商户'
                                renderData={merchantSelectSource}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                value={merchant.merchantName}
                                bindThis={this}
                                bindName='merchant'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                placeholder='请选择开始时间'
                                label='免单时间'
                                value={freeOrderStartTime}
                                bindThis={this}
                                bindName='freeOrderStartTime'
                                isFormat
                            />
                            <XDatePicker
                                placeholder='请选择结束时间'
                                style={{ marginRight: 20 }}
                                value={freeOrderEndTime}
                                bindThis={this}
                                bindName='freeOrderEndTime'
                                isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                style={{ marginLeft: 10 }}
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.shortcutBox}>
                        <XOKButton
                            style={{ width: 140 }}
                            label='批量打款'
                            onClick={() => this.WidthDrawalHandle()}
                        />
                        <span style={{ marginLeft: 10 }}>待打款金额：￥ {countAmount}</span>
                    </div>
                    <AdvertTable renderData={freeOrderRefundList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                    <div style={{ marginTop: 10 }}>
                        <Button type='primary' onClick={this.props.history.goBack}>返回</Button>
                    </div>
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getMerchantSelectSource: PropTypes.func,
    merchantSelectSource: PropTypes.array,
    getFreeOrderRefundList: PropTypes.func,
    freeOrderRefundList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    merchantSelectSource: state.merchantreconciliation.merchantSelectSource,
    freeOrderRefundList: state.freeorderrefund.freeOrderRefundList,
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath, keepSecondNavBreadcrumb,
    getMerchantSelectSource, ...model.actions
})(Main);