//任务管理
import { 
    getTaskList, 
    getTaskDetail, 
    getTaskClassifySele, 
    getTaskTypeSele, 
    getTaskPlatSele,
    getTaskInfoSele,
    getTaskFactorSele,
    getTaskAwardSele,
    getTaskGrade
} from './taskList/saga';
import { getTaskClassifyList, getTaskClassifyStateSele } from './taskClassify/saga';

export default [
    getTaskList,
    getTaskDetail,
    getTaskClassifySele,
    getTaskTypeSele,
    getTaskPlatSele,
    getTaskInfoSele,
    getTaskFactorSele,
    getTaskAwardSele,
    getTaskGrade,

    getTaskClassifyList,
    getTaskClassifyStateSele
];
