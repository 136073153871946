/**
 * 员工删除
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './DeleteModal.module.scss';
import http from '@/assets/api/http';

class DeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
        };
    }

    _handleOk = () => {
        const { id } = this.props;
        http('/admin/empl/delEmplInfoById/' + id, { id }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('删除成功！');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, } = this.state;

        return (
            <Modal
                width={470}
                centered
                title='系统提示'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    被删除的帐号在帐号密码正确的
                情况下也无法正常登录系统，确定删除用户？
                </div>
            </Modal>
        );

    }
}

DeleteModal.propTypes = {
    id: PropTypes.number, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default DeleteModal;