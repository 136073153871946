/**
 *  【商户提现记录】入口页
 * */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { XTabNav } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import Supplier from './Supplier';
import { getSessionStorage, removeSessionStorage, setSessionStorage } from '@/assets/js/storage';

const tabList = [{ id: 'MERCHANT', label: '入驻商户' }, { id: 'MERCHANT_SUPPLIER', label: '供应商' }];

const storehouselist = ({ history }) => {

    const [tabId, setTabId] = useState('MERCHANT');

    const cprops = { history };
    const components = {
        MERCHANT: <Main {...cprops} />,
        MERCHANT_SUPPLIER: <Supplier {...cprops} />
    };

    useEffect(() => {
        const tabName = getSessionStorage('merchantwithdrawalrecords');
        if (tabName == 'Supplier') {
            setTabId('MERCHANT_SUPPLIER');
            removeSessionStorage('merchantwithdrawalrecords');
        }
    }, []);

    return <div className={styles.flexBoxContainer}>
        <XTabNav renderData={tabList} onChange={v => setTabId(v)} activedID={tabId} />
        <div style={{ marginTop: '10px' }}></div>
        {components[tabId]}
    </div>;
};

storehouselist.propTypes = {
    history: PropTypes.object
};

export default storehouselist;