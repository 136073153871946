import * as T from './actiontypes';
const defaultState = {
    tableSource: [],//氛围分类列表数据源
    SelectData: [],// 氛围分类状态下拉选项
    paginations: {}, //氛围分类分页对象

    listTableSource: [],// 氛围列表数据源
    listPaginations: {}, // 氛围列表分页对象

    atmosBuytableSource: [],//购买记录数据源
    atmosBuypaginations: {}, //购买记录分页对象

};


/**
 * 存储'秀场管理 -道具管理-'数据源
 * @param {*} state 
 * @param {*} action 
 */
export const atmospDate = (state = defaultState, action) => {
    let newTableSource, atmosbuy, newbuyPaginations, newSelectData, newPaginations;
    switch (action.type) {
        case T.SET_ATMOSPG_DATA: //获取'氛围管理-氛围分类列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_MOSLIST_DATA: //获取'氛围管理-氛围列表数据
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, listTableSource: newTableSource, listPaginations: newPaginations };

        case T.SET_GIFTSTATE_DATA: //获取'氛围管理-氛围分类状态下拉
            newSelectData = action.payload.result;
            return { ...state, SelectData: newSelectData };

        case T.SET_ATMOBUY_DATA: //存储'氛围管理-氛围-购买记录
            atmosbuy = action.payload.result.dataList;
            newbuyPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, atmosBuytableSource: atmosbuy, atmosBuypaginations: newbuyPaginations };
        default:
            return state;
    }
};
