/**
 *  商户管理 - 商户列表
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, XTableAdapter } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import { merchantListTableSource, getmerchantselectData, getStore, getmerchantradius } from '@/reducers/businessmanage/merchantlist/actions';
import { getEnumCustomerSource, } from '@/reducers/common/enum/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { CompanyList } from '@/vcomps';
import model from '@/reducers/businessmanage/citybeautyopen/model';
import SetPrivilegeRate from './modal/SetPrivilegeRate';
import http from '@/assets/api/http';
import SetSettleRate from './modal/SetSettleRate';
import SetBusinessCategory from './modal/SetBusinessCategory';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const { getProvinceSele, getCitySele } = model.actions;

const Mode = {
    MER_EDITOR: 'MER_EDITOR',//编辑
    MER_DETAIL: 'MER_DETAIL',//详情
    MER_CLOSED: 'MER_CLOSED',//停业
    MER_STOR: 'MER_STOR',//店铺
};

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            saleState: {}, // 状态
            saleNames: '', // 商户名称,
            pageSize: 50,//默认
            pageNum: 1,//默认
            merchantId: '',//停业商户ID
            openCustomerServiceState: {},
            scrollY: '',
            merchantype: {},//商户类型
            loginName: '',//商户账号
            company: {},
            provinceItem: {}, //省份
            cityItem: {}, //城市
            cooperationModes: [], modeItem: {},//合作模式枚举数据，合作模式搜索条件
        };
    }

    componentDidMount() {
        const { getmerchantselectData, getEnumCustomerSource, getmerchantradius, getProvinceSele, getCitySele } = this.props;
        getmerchantselectData();
        getEnumCustomerSource();
        getmerchantradius();
        getProvinceSele();
        const { query } = this.props.history.location;
        if (query) {
            this.setState({
                provinceItem: query.provinceItem ? query.provinceItem : {},
                cityItem: query.cityItem ? query.cityItem : {},
                merchantype: query.type ? { code: query.type } : {},
                saleState: query.state ? { code: query.state } : {},
                modeItem: query.mode ? { code: query.mode } : {}
            }, () => {
                if (query.provinceItem) {
                    getCitySele({ parentId: query.provinceItem.id });
                }
            });
        }
        http('/enum/MerchantCooperationModelEnum', {}, 'POST')
            .then(res => this.setState({ cooperationModes: res.result || [] }));
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.merchantListTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 状态
    _saleChangeHandle = (value) => {
        this.setState({ saleState: value });
    }
    // 商户名称
    _saleItemChangeHandle = (value) => {
        this.setState({ saleNames: value });
    }

    // 查询触发  
    _saleSearchHandle = (useCache) => {
        const { saleNames, saleState, pageSize, pageNum, openCustomerServiceState, merchantype, loginName, company, provinceItem, cityItem, modeItem } = this.state;
        this.searchCache = {
            merchantName: saleNames,//商户名称  
            merchantState: saleState.code,//状态
            merchantType: merchantype.code,//商户类型
            loginName,
            areaCompanyId: company.id,
            provinceId: provinceItem.id,
            cityId: cityItem.id,
            openCustomerServiceState: openCustomerServiceState.code, // 客服坐席
            cooperationMode: modeItem.code,// 合作模式
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.merchantListTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _saleresetHandle = () => {
        this.setState({
            saleState: {}, saleNames: '', openCustomerServiceState: {}, merchantype: {}, loginName: '', company: {}, provinceItem: {}, cityItem: {},
            modeItem: {}
        }, () => {
            this.props.getCitySele({ parentId: '1' });
            KeepAlive.saveResetFunc(this._saleSearchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建按钮被点击
    _newCreateHandle = () => {
        this.props.updateContent('NEWCREATE');
    }

    // 表格操作功能回调
    _actionClickHandle = (id, type, params) => {
        const { updateContent, } = this.props;
        const that = this;
        const operates = {
            MER_DETAIL: () => updateContent('MER_DETAIL', id),
            MER_CLOSED: () => {
                const { pageSize, pageNum } = this.state;
                this.props.getStore(pageSize, pageNum, id);
                this.setState({
                    currentOpenMode: Mode.MER_CLOSED,
                    merchantId: id,
                });
            },
            MER_EDITOR: () => updateContent('MER_EDITOR', id),
            MER_STOR: () => updateContent('MER_STOR', id),
            MEMBER: () => updateContent('MEMBER', id, params),
            EARNING: () => updateContent('EARNING', id, params),
            setPrivilegeRate: () => {
                that.setState({
                    currentOpenMode: type,
                    openModeParam: {
                        privilegaRate: params.integralRate,
                        id: params.id
                    }
                });
            },
            SetSettleRate: () => {
                that.setState({
                    currentOpenMode: 'SetSettleRate',
                    openModeParam: params
                });
            },
            setBusinessCategory: () => {
                that.setState({
                    currentOpenMode: 'SetBusinessCategory',
                    openModeParam: params
                });
            }
        };
        operates[type]();
    }


    // 打开弹窗
    _renderMode = () => {
        const { currentOpenMode, openModeParam } = this.state;
        const that = this;
        const modals = {
            setPrivilegeRate: <SetPrivilegeRate openModeParam={openModeParam} closeMode={that._closeMode} />,
            SetSettleRate: <SetSettleRate openModeParam={openModeParam} closeMode={that._closeMode} />,
            SetBusinessCategory: <SetBusinessCategory openModeParam={openModeParam} closeMode={that._closeMode} />,
        };
        return modals[currentOpenMode];
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.merchantListTableSource(this.searchCache);// 渲染列表数据方法
        }
    }

    //省份改变
    _areaChange = ({ id = '' }) => {
        this.setState({ cityItem: {} });
        this.props.getCitySele({ parentId: id });
    }

    // 跳转到商户结算修改记录
    jumpSettlementChangeLog = ()=>{
        this.props.history.push('/home/businessmanage/merchantlist/settlementChangeLogs');
        this.props.addOneBreadcrumbPath({
            title: '商户结算修改记录',
            path: '/home/businessmanage/merchantlist/settlementChangeLogs'
        });
    }

    render() {
        const { tableSource, paginations, selectData, enumCustomerSource, radiusData, provinceSele, citySele } = this.props;
        const { saleState, saleNames, openCustomerServiceState, merchantype, loginName, company, provinceItem, cityItem, cooperationModes, modeItem } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._saleSearchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商户账号'
                                placeholder='请输入商户账号'
                                bindName='loginName'
                                bindThis={this}
                                value={loginName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='商户名称'
                                placeholder='请输入商户名称'
                                onChange={this._saleItemChangeHandle}
                                value={saleNames}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={selectData}
                                onChange={this._saleChangeHandle}
                                dataIndex='value'
                                keyIndex='code'
                                value={saleState.code}
                                showSearch={true}
                                isOptionBindID={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='客服座席'
                                placeholder='请选择'
                                renderData={enumCustomerSource}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='openCustomerServiceState'
                                value={openCustomerServiceState.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect label='合作模式' placeholder='请选择' renderData={cooperationModes} dataIndex='value' keyIndex='code'
                                bindThis={this} bindName='modeItem' value={modeItem.value} showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户类型'
                                placeholder='请选择'
                                renderData={radiusData ? radiusData : []}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='merchantype'
                                value={merchantype.code}
                                showSearch={true}
                                isOptionBindID={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <CompanyList
                                style={{ width: 240, marginRight: 20 }}
                                label='所属区域公司'
                                placeholder='请选择区域公司'
                                bindThis={this}
                                bindName='company'
                                value={company}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='省份：'
                                placeholder='请选择省份'
                                renderData={provinceSele}
                                bindName='provinceItem'
                                bindThis={this}
                                dataIndex='areaName'
                                keyIndex='id'
                                value={provinceItem.areaName}
                                onChange={this._areaChange}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='城市：'
                                placeholder='请选择城市'
                                renderData={citySele}
                                dataIndex='areaName'
                                keyIndex='id'
                                bindName='cityItem'
                                bindThis={this}
                                value={cityItem.areaName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._saleSearchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._saleresetHandle}
                            />
                            <XOKButton
                                style={{width: '200px', marginLeft: '20px'}}
                                label='商户结算修改记录'
                                onClick={this.jumpSettlementChangeLog}
                            />
                        </SearchItem>
                    </SearchBox>
                    {/* 任务2492 */}
                    {/* <div className={styles.operate_box}>
                        <XOKButton style={{ width: '100px' }} label='创建商户' onClick={this._newCreateHandle} />
                    </div> */}
                    <XTableAdapter render={scrollY => {
                        this.setState({ scrollY });
                    }}>
                        <div className={styles.tableContainer} style={{ height: '100%' }}>
                            <Table
                                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                                rowKey={(record, index) => record.id}
                                columns={takeColumns(this._actionClickHandle)}
                                dataSource={takeDataSource(tableSource)}
                                pagination={false}
                                scroll={{ x: 'max-content', y: this.state.scrollY }}
                            />
                            <XPagination
                                resultTotal={paginations.resultTotal}
                                pageSize={paginations.pageSize}
                                pageNum={paginations.pageNum}
                                onChange={this._paginationChange}
                            />
                        </div>
                    </XTableAdapter>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    merchantListTableSource: PropTypes.func, // 通过Saga异步请求表格数据源的Action
    getmerchantselectData: PropTypes.func,// 通过Saga异步请求下拉选项数据方法Action
    tableSource: PropTypes.array,
    selectData: PropTypes.array,
    paginations: PropTypes.object,
    storeListTable: PropTypes.object,//店铺数据和停业公用
    getStore: PropTypes.func, // 店铺数据和停业公用数据源的Action
    getEnumCustomerSource: PropTypes.func,
    enumCustomerSource: PropTypes.array,
    getmerchantradius: PropTypes.func,
    radiusData: PropTypes.array,
    getProvinceSele: PropTypes.func,
    provinceSele: PropTypes.array,
    getCitySele: PropTypes.func,
    citySele: PropTypes.array,
};

const mapStateToProps = (state) => {
    return {
        tableSource: state.merchantlist.merchantDate.tableSource, // 表格数据源
        selectData: state.merchantlist.merchantDate.SelectData, // 状态下拉选项
        paginations: state.merchantlist.merchantDate.paginations, // 分页数据
        storeListTable: state.merchantlist.merchantDate.storeListTable, // 店铺数据和停业公用
        enumCustomerSource: state.common_enum.enumCustomerSource, // 客服坐席
        radiusData: state.merchantlist.merchantDate.radiusData, // 商户类型
        provinceSele: state.citybeautyopen.provinceSele,
        citySele: state.citybeautyopen.citySele,
    };
};


export default connect(mapStateToProps, {
    addOneBreadcrumbPath,
    merchantListTableSource, getmerchantselectData, getEnumCustomerSource, getStore, getmerchantradius, getProvinceSele, getCitySele
})(Main);