import * as T from './actiontypes';

//获取商品品牌列表
export const getProductBrand = (params = {}) => ({
    type: T.GET_PRODUCTBRAND_LIST,
    payload: params
});
//获取商品品牌申请
export const getProductBrandDetail = (id) => ({
    type: T.GET_PRODUCTBRAND_DETAIL,
    payload: { id }
});

//获取第三方商品品牌列表
export const getThirdPartyProductBrand = (params = {}) => ({
    type: T.GET_THIRDPARTY_PRODUCTBRAND_LIST,
    payload: params
});