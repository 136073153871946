import * as Task from './actionTypes';

//出事表格数据
const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
const detailData = {
    eventConfigDetailResps: [{
        eventRewardList: [],
        eventConfig: {}
    }],
};

//任务列表
export const taskList = (state = initData, { payload, type }) => {
    switch (type) {
        case Task.SET_TASKMANAGE_TASKLIST:
            return payload.result;
        default:
            return state;
    }
};

//任务详情
export const taskDetail = (state = detailData, { payload, type }) => {
    switch (type) {
        case Task.SET_TASKMANAGE_TASKDETAIL:
            return payload.result;
        case Task.CLEAN_TASKDETAIL:
            return detailData;
        default:
            return state;
    }
};

//任务分类下拉
export const taskClassifySele = (state = [], { payload, type }) => {
    switch (type) {
        case Task.SET_TASKMANAGE_TASKCLASSIFYSELE:
            return payload.result;
        default:
            return state;
    }
};

//任务类型下拉
export const taskTypeSele = (state = [], { payload, type }) => {
    switch (type) {
        case Task.SET_TASKMANAGE_TASKTYPESELE:
            return payload.result;
        default:
            return state;
    }
};

//任务详情
export const taskPlatSele = (state = [], { payload, type }) => {
    switch (type) {
        case Task.SET_TASKMANAGE_TASKPLATSELE:
            return payload.result;
        default:
            return state;
    }
};
//任务信息下拉
export const taskInfoSele = (state = [], { payload, type }) => {
    switch (type) {
        case Task.SET_TASKMANAGE_TASKINFOSELE:
            return payload.result;
        default:
            return state;
    }
};
//任务要素下拉
export const taskFactorSele = (state = [], { payload, type }) => {
    switch (type) {
        case Task.SET_TASKMANAGE_TASKFACTORSELE:
            return payload.result;
        default:
            return state;
    }
};
//任务奖励下拉
export const taskAwardSele = (state = [], { payload, type }) => {
    switch (type) {
        case Task.SET_TASKMANAGE_TASKAWARDSELE:
            return payload.result;
        default:
            return state;
    }
};
//任务奖励会员等级
export const taskGradeSele = (state = [], { payload, type }) => {
    switch (type) {
        case Task.SET_TASKMANAGE_TASKGRADE:
            return payload.result;
        default:
            return state;
    }
};