/**
 *  【费用名称】入口页
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const CostName = ({ history }) => {
    return <Main history={history} />;
};

CostName.propTypes = {
    history: PropTypes.object
};

export default CostName;