/**
 * 编辑员工 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { XTitle } from '@/components/xqxc_ui';
import { Modal, Input, message, Form, Checkbox, Select } from 'antd';
import styles from './EditStaff.module.scss';
import { getListRoleByEmpl } from '@/reducers/permissionsmanage/assignauthority/actions';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    labelAlign: 'left',
};

class EditStaff extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
    }

    componentDidMount() {
        this.props.getListRoleByEmpl({ emplId: this.props.data.id });
    }

    // 点击确认
    _handleOk = () => {
        const { data } = this.props;
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const param = {
                    emplIds: data.id,
                    roleIds: fieldsValue.roleIds.toString()
                };
                http('/admin/empl/updateRoleEmpl', param, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('编辑成功！');
                        this.setState({
                            visible: false,
                        });
                        this.props.closeMode(true);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
            }
        });
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { roleSelect } = this.props;
        let checkList = [];
        const adminRoleOption = roleSelect.map((item) => {
            if (item.isChecked == 1) {
                checkList.push(item.id);
            }
            return {
                key: item.id,
                value: item.id,
                label: item.roleName,
            };
        });
        console.log(roleSelect);
        return (
            <Modal
                width={900}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <XTitle label='员工角色配置' />
                        <div className={styles.line}></div>
                        <Form.Item
                            colon
                            htmlFor='roleIds'
                            label={<span>管理角色</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('roleIds', {
                                rules: [{ required: true, message: '请选择角色' }],
                                initialValue: checkList
                            })(
                                <Checkbox.Group
                                    options={adminRoleOption}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

EditStaff.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    getListRoleByEmpl: PropTypes.func, // 获取角色选择
    roleSelect: PropTypes.array, // 角色选择
};

const ForgotProducDetails = Form.create({ name: 'create-form' })(EditStaff);// 校验

const mapStateToProps = (state) => ({
    roleSelect: state.assignauthority.getListRoleByEmpl, // 角色选择
});

export default connect(mapStateToProps, { getListRoleByEmpl })(ForgotProducDetails);