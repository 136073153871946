//获取/保存订单列表
export const GET_FREEORDER_LIST = 'ORDERMANAGE/GET_FREEORDER_LIST';
export const SET_FREEORDER_LIST = 'ORDERMANAGE/SET_FREEORDER_LIST';
//获取/保存免单自动打款规则列表
export const GET_AUTOFREEORDER_LIST = 'ORDERMANAGE/GET_AUTOFREEORDER_LIST';
export const SET_AUTOFREEORDER_LIST = 'ORDERMANAGE/SET_AUTOFREEORDER_LIST';
// 状态
export const GET_AUTOFREEORDER_STATUS = 'ORDERMANAGE/GET_AUTOFREEORDER_STATUS';
export const SET_AUTOFREEORDER_STATUS = 'ORDERMANAGE/SET_AUTOFREEORDER_STATUS';
// 打款状态
export const GET_FREEORDER_STATUS = 'ORDERMANAGE/GET_FREEORDER_STATUS';
export const SET_FREEORDER_STATUS = 'ORDERMANAGE/SET_FREEORDER_STATUS';
//获取/保存订单列表
export const GET_FREEPRODUCT_LIST = 'ORDERMANAGE/GET_FREEPRODUCT_LIST';
export const SET_FREEPRODUCT_LIST = 'ORDERMANAGE/SET_FREEPRODUCT_LIST';
// 免单看板详情
export const GET_FREEORDERKANBAN_LIST = 'ORDERMANAGE/GET_FREEORDERKANBAN_LIST';
export const SET_FREEORDERKANBAN_LIST = 'ORDERMANAGE/SET_FREEORDERKANBAN_LIST';
