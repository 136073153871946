import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

const columns = (TableAction, actionClickHandle) => [
	{
		title: '品牌LOGO',
		dataIndex: 'logoUrl',
		key: 'logoUrl',
		align: 'center',
		width: '14%',
		render: ImgCell
	},
	{
		title: '品牌名称',
		dataIndex: 'brandName',
		key: 'brandName',
		align: 'center',
		width: '14%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '品牌首字母',
		dataIndex: 'firstLetter',
		key: 'firstLetter',
		align: 'center',
		width: '14%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '品牌代码',
		dataIndex: 'brandCode',
		key: 'brandCode',
		align: 'center',
		width: '14%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '品牌故事',
		dataIndex: 'brandDesc',
		key: 'brandDesc',
		align: 'center',
		width: '14%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '创建时间',
		dataIndex: 'gmtCreate',
		key: 'gmtCreate',
		align: 'center',
		width: '14%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '操作',
		dataIndex: 'id',
		key: 'id',
		align: 'center',
		width: '14%',
		render: (id) => TableAction(id, actionClickHandle)
	},
];

// 表格操作
const TableAction = (key, actionClickHandle) => {
	return (
		<div className={styles.action}>
			<div className={styles.item} onClick={() => actionClickHandle(key, 'edit')}>编辑</div>
			<div className={styles.item} onClick={() => actionClickHandle(key, 'delete')}>删除</div>
		</div>
	);
};

class TableData extends Component {
	state = {
		selectedRowKeys: [],
	};

	// 操作表格
	_actionHandle = (id, type, value) => {
		const { tableAction } = this.props;
		tableAction(id, type, value);
	}

	//处理表格数据
	_processingData = (data) => {
		return data;
	}

	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log(selectedRows);
			this.setState({ selectedRowKeys });
			// this._actionHandle(null,'batchDelete',selectedRowKeys);
		}
	};

	// 清空表格复选框
	_cleanAllSelected = () => {
		this.setState({ selectedRowKeys: [] });
	}

	render() {
		const { renderData } = this.props;
		const { selectedRowKeys } = this.state;
		this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
			<div className={styles.tableContainer}>
				{/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey='id'
					// rowSelection={this._rowSelection}
					columns={columns(TableAction, this._actionHandle)}
					pagination={false}
					dataSource={this._processingData(renderData.dataList)}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={this.props.paginationChange} />
			</div>
		);
	}
}


TableData.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object
};



export default TableData;