import * as C from './actiontypes';

// 评论列表
export const getHotelCommentList = (param) => ({
    type: C.GET_HOTELCOMMENT_LIST,
    payload: param
});
// 评论详情
export const getHotelCommentDetail = (param) => ({
    type: C.GET_HOTELCOMMENT_DETAIL,
    payload: param
});
