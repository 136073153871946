import * as Advert from './actiontypes';

/**
 * 存储'广告投放'数据源
 * @param {*} state 
 * @param {*} action 
 */

 const init = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
 };

export const advertPlacementList = (state = init, action) => {
    switch(action.type){
        case Advert.SET_PLACEMENT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const advertPlacementAudit = (state = {}, action) => {
    switch(action.type){
        case Advert.SET_PLACEMENT_AUDIT:
            return action.payload.result;
        default:
            return state;
    }
};
export const advertPlacementStartAndStop = (state = [], action) => {
    switch(action.type){
        case Advert.SET_PLACEMENT_STARTANDSTOP:
            return action.payload.result;
        default:
            return state;
    }
};
export const advertPlacementDetail = (state = {}, action) => {
    switch(action.type){
        case Advert.SET_PLACEMENT_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};
export const advertPlacementDelete = (state = [], action) => {
    switch(action.type){
        case Advert.SET_PLACEMENT_DELETE:
            return action.payload.result;
        default:
            return state;
    }
};
export const advertPlacementAdd = (state = [], action) => {
    switch(action.type){
        case Advert.SET_PLACEMENT_ADD:
            return action.payload.result;
        default:
            return state;
    }
};
export const advertPlacementEdit = (state = [], action) => {
    switch(action.type){
        case Advert.SET_PLACEMENT_EDIT:
            return action.payload.result;
        default:
            return state;
    }
};
export const advertList = (state = [], action) => {
    switch(action.type){
        case Advert.SET_PLACEMENT_ADVERTLIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const advertStrategyList = (state = [], action) => {
    switch(action.type){
        case Advert.SET_PLACEMENT_ADVERTSTRATEGYLIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const advertPlacementStatus = (state = [], action) => {
    switch(action.type){
        case Advert.SET_PLACEMENT_ADVERTSTATUS:
            return action.payload.result;
        default:
            return state;
    }
};