/**
 * 商品管理 - 标签异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';


export const getProductAuditData = function* () {
    yield takeEvery(T.GET_PRODUCT_PRODUCTAUDITDATA, function* requestData(action) {
        try {
            let result = yield http('/goods/goods/auditGoodsList', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_PRODUCTAUDITDATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductAuditRateList = function* () {
    yield takeEvery(T.GET_PRODUCTAUDIT_RATELIST, function* requestData(action) {
        try {
            let result = yield http('/goods/audit/findAuditRateGoodsList', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCTAUDIT_RATELIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductStatus = function* () {
    yield takeEvery(T.GET_PRODUCT_STATUS, function* requestData(action) {
        try {
            let result = yield http('/enum/GoodsStateEnum', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_STATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductUsername = function* () {
    yield takeEvery(T.GET_PRODUCT_USERNAME, function* requestData(action) {
        try {
            let result = yield http('/merchant/info/listMerchantIdAndName', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_USERNAME, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductBusinessType = function* () {
    yield takeEvery(T.GET_PRODUCT_BUSINESSTYPE, function* requestData(action) {
        try {
            let result = yield http('/enum/GoodsTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_BUSINESSTYPE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductLabelSele = function* () {
    yield takeEvery(T.GET_PRODUCT_LABELSELE, function* requestData(action) {
        try {
            let result = yield http('/goods/tag/listTagItem', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_LABELSELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductClassifySele = function* () {
    yield takeEvery(T.GET_PRODUCT_CLASSIFYSELE, function* requestData(action) {
        try {
            let result = yield http('/goods/category/linkage', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_CLASSIFYSELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductAuditDetail = function* () {
    yield takeEvery(T.GET_PRODUCTAUDIT_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/goods/goods/infoGoods', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCTAUDIT_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductAuditRateDetail = function* () {
    yield takeEvery(T.GET_PRODUCTAUDIT_RATEDETAIL, function* requestData(action) {
        try {
            let result = yield http('/goods/audit/findAuditRateGoodsInfo', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCTAUDIT_RATEDETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductAuditLog = function* () {
    yield takeEvery(T.GET_PRODUCT_AUDITLOG, function* requestData(action) {
        try {
            let result = yield http('/goods/audit/auditGoodsRecord', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_AUDITLOG, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductRateAuditLog = function* () {
    yield takeEvery(T.GET_PRODUCT_RATEAUDITLOG, function* requestData(action) {
        try {
            let result = yield http('/goods/audit/findAuditRateRecord', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_RATEAUDITLOG, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};




