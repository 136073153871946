import React, { Component } from 'react';
import { Table, Switch, } from 'antd';
import PropTypes from 'prop-types';
import styles from './PackTable.module.scss';
import { tooltipStyle, tooltip } from '@/components/TableCell';


export default class PackTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    _columns = () => [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '应用端口',
            dataIndex: 'application',
            key: 'application',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            align: 'center',
            render: (text, item) => (this.SwitchCell(text, item))
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '10%',
            render: (id, item) => this._tableAction(id, item)
        },
    ];

    // 解析表格数据，使符合columns格式要求
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                return item;
            });
        }
    }

    // 表格项内容为开关
    SwitchCell = (sw, item) => {
        let checked = true;
        if (sw == 0) {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checkedChildren='开'
                    unCheckedChildren='关'
                    checked={checked}
                    onChange={(value) => {
                        this.props.tableAction(item, 'switch', value);
                    }}
                />
            </div>
        );
    };

    //表格操作
    _tableAction = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'edite', item)}>编辑</div>
            </div>
        );
    };

    //渲染组件
    render() {
        const { renderData } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
            </div>
        );
    }
}

PackTable.propTypes = {
    renderData: PropTypes.array, // 表格数据源
    tableAction: PropTypes.func, // 表格
};