/**
 * 热门城市设置
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import HotTable from './components/HotTable';
import HotModal from './modal/HotModal';
import { XOKButton, showConfirm } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/systemmanage/hotCity/model';


class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: [], // 当前需要打开的弹窗的入参
        };
    }

    componentDidMount() {
        this.props.getHotCitySelect();//城市下拉
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, } = this.state;
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getHotCityList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    
    // 新建编辑
    _newHandle = () => {
        this.setState({
            currentOpenMode: 'CREAT',
            openModeParam: this.props.citySelect
        });
    }


    // 表格操作 
    _tableAction = (item, type, value) => {
        switch (type) {
            case 'delete'://删除
                showConfirm(`确定${value.name}删除？`, '', () => {
                    http(`/hotel/hotCity/delHotCity/${item}`, {}, 'POST')
                        .then(() => {
                            message.success('删除成功。');
                            this.props.getHotCityList(this.searchCache);
                        }).catch((reject = {}) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'sort'://排序
                http('/hotel/hotCity/editHotCitySort', {
                    hotCityId: item.hotCityId,
                    sortNo: value
                }, 'POST')
                    .then(() => {
                        message.success('排序修改成功。');
                        this.props.getHotCityList(this.searchCache);
                    }).catch((reject = {}) => {
                        message.error(reject.message);
                    });
                break;
        }
    }

    renderModal = () => {
        switch (this.state.currentOpenMode) {
            case 'CREAT':
                return <HotModal title='新建热门城市' visible={true} closeMode={this._closeMode} data={this.state.openModeParam} />;
            default:
                return null;
        }
    }
    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '', });
        if (status) {
            this.props.getHotCityList(this.searchCache);
        }
    }

    render() {
        const { tableList } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XOKButton
                        style={{ width: '90px' }}
                        label='新建'
                        onClick={this._newHandle}
                    />
                    <HotTable renderData={tableList} tableAction={this._tableAction} />
                    {this.renderModal()}
                </div>
            </KeepAlive>
        );
    }
}


Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象

    tableList: PropTypes.array,
    getHotCityList: PropTypes.func,//表格数据(action)
    citySelect: PropTypes.array,
    getHotCitySelect: PropTypes.func,//城市下拉数据(action)
};

const mapStateToProps = (state) => ({
    tableList: state.hotCity.hotCityList, // 表格数据
    citySelect: state.hotCity.citySelect, // 城市下拉数据
});

export default connect(mapStateToProps, { ...model.actions })(Main);
