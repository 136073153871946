/**
 * 新建免单自动打款
 */
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Modal, Radio, Form, Input, Select, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { getDownEmpls } from '@/reducers/permissionsmanage/assignauthority/actions';
import { message } from 'antd';
import styles from './index.module.scss';

const RadioGroup = Radio.Group;
const { TextArea } = Input;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 6 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
    },
};
class NewModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
            reasonLength: 0,
            startValue: moment(),
            endValue: moment().add(360, 'days'),
            endTimeFlag: false,
            helpError: null,
        };
    }
    componentDidMount() {
        // const { form } = this.props;
        // form.setFieldsValue({ reasonState2: moment().add(360, 'days') });
        this.props.getDownEmpls();
    }
    // 点击确认
    // _handleOk = () => {
    //     this._handleSubmit();
    // }
    _handleOk = () => {
        // if (e) e.preventDefault();
        this.props.form.validateFields((err, formDatas) => {
            if (!err) {
                const param = {
                    ...formDatas,
                    freeOrderAmount: Number(formDatas.freeOrderAmount) * 100,
                    effectiveStartTime: formDatas.effectiveStartTime ? moment(moment(formDatas.effectiveStartTime).format()).format('YYYY-MM-DD HH:mm:ss') : null,
                    effectiveEndTime: formDatas.effectiveEndTime ? moment(moment(formDatas.effectiveEndTime).format()).format('YYYY-MM-DD HH:mm:ss') : null,
                };
                this.setState({ confirmLoading: true }, () => {
                http('/marketing/activityInfo/addFreeOrderRule', param, 'POST')
                    .then(() => {
                        message.success('创建成功!');
                        this.props.refresh();
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                        this.props.closeMode();
                    }).catch((reject) => {
                        message.error(reject.message);
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                        this.props.closeMode();
                    });
                });
            }
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    };
    setMsg = ({ target: { value } }) => {
        this.setState({ reasonLength: value.length });
    };
    disabledStartDate = startValue => {
        return startValue < moment().startOf('day');
    };

    disabledEndDate = endValue => {
        const { startValue } = this.state;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    };
    //
    onStartChange = startValue => {
        this.setState({ startValue });
        const { form } = this.props;
        form.setFieldsValue({ effectiveEndTime: null });
        this.setState({ endValue: null, endTimeFlag: true, helpError: '请选择开始日期' });
        if (startValue) {
            let effectiveEndTime = form.getFieldValue('effectiveEndTime');
            if(!effectiveEndTime) {
                this.setState({ helpError: '请选择结束日期' });
            } else {
                this.setState({ helpError: null });
            }
            this.setState({ endTimeFlag: false });
        }
    };
    onEndChange = endValue => {
        if (!endValue) {
            this.setState({ helpError: '请选择结束日期' });
        } else {
            this.setState({ endValue, helpError: null });
        }
    };
    // 下拉选择项
    _renderOption = (list) => {
        return list.map((item) => {
            return <Option key={item.id} value={item.id}>{item.emplName}</Option>;
        });
    };
    render() {
        const { visible, confirmLoading, title, reasonLength, helpError } = this.state;
        const { downEmpls } = this.props;
        const { getFieldDecorator } = this.props.form;
        const _lengthVerify = (rule, value, callback) => {
            if (value && value.length > 50) {
                callback('规则不超过50个字符！');
            } else {
                callback();
            }
        };
        return (
            <Modal
                width={700}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} autoComplete='off' onSubmit={this._handleSubmit}>
                        <Form.Item
                            colon={false}
                            htmlFor='ruleName'
                            label={<span>规则名称</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('ruleName', {
                                rules: [
                                    { required: true, message: '请输入规则名称' },
                                    {
                                        validator: _lengthVerify
                                    }
                                ],
                            })(
                                <Input placeholder='请输入' style={{ width: 300 }} onChange={this.setMsg} />
                            )}
                            <span style={{ marginLeft: 10 }}>{reasonLength}/50</span>
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='operatorId'
                            label={<span>指定打款用户</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('operatorId', {
                                rules: [{ required: true, message: '请选择指定打款用户' }],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: 300 }}
                                    placeholder="请选择"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this._renderOption(downEmpls)}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='freeOrderAmount'
                            label={<span>免单金额</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('freeOrderAmount', {
                                // initialValue: 1,
                                rules: [
                                    { required: true, message: '请输入免单金额' },
                                    { pattern: /^\+?[1-9]\d*$/, message: '只能输入大于0的正整数' }
                                    ],
                            })(
                                <Input placeholder="请输入" style={{ width: 300 }} />
                            )}
                            <span style={{ color: '#b3abab', marginLeft: 10 }}>元以下</span>
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='reasonState'
                            label={<span>有效期</span>}
                            style={{ textAlign: 'left' }}
                            validateStatus={ helpError ? 'error' : ''} help={helpError ? helpError : ''}
                        >
                            {getFieldDecorator('effectiveStartTime', {
                                initialValue: this.state.startValue,
                                rules: [{ required: true, message: '请选择开始日期' }],
                            })(
                                    <DatePicker
                                        disabledDate={this.disabledStartDate}
                                        style={{ width: 140 }}
                                        format="YYYY-MM-DD"
                                        placeholder="开始日期"
                                        onChange={this.onStartChange}
                                    />
                            )}
                            <span style={{ color: '#b3abab', marginLeft: 5, marginRight: 5 }}>--</span>
                            {getFieldDecorator('effectiveEndTime', {
                                initialValue: this.state.endValue,
                                rules: [{ required: true, message: '请选择结束日期' }],
                            })(
                                    <DatePicker
                                        disabled={this.state.endTimeFlag}
                                        style={{ width: 140 }}
                                        format="YYYY-MM-DD"
                                        disabledDate={this.disabledEndDate}
                                        placeholder="结束日期"
                                        onChange={this.onEndChange}
                                    />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='remarks'
                            label={<span>备注</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('remarks', {
                            })(
                                <TextArea
                                    placeholder="请输入"
                                    style={{ width: 300 }}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

NewModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    refresh: PropTypes.func,
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    getDownEmpls: PropTypes.func, // 获取员工选择
    downEmpls: PropTypes.array, // 员工选择
};
const ForgotProducDetails = Form.create({})(NewModal);// 校验
const mapStateToProps = (state) => ({
    downEmpls: state.assignauthority.getDownEmpls, // 员工选择
});
export default connect(mapStateToProps, {getDownEmpls})(ForgotProducDetails);