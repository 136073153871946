/**
 *  会员管理弹框集合
 * */
export { default as CharmModal } from './CharmModal'; // 修改会员魅力值弹框
export { default as StarlightModal } from './StarlightModal'; // 修改会员魅力值弹框
export { default as StarScoreModal } from './StarScoreModal'; // 修改会员人气值弹框   
export { default as StarRice } from './StarRice'; // 修改会员信用值弹框
export { default as CouponModal } from './CouponModal'; // 赠送优惠券弹框
export { default as LevelModal } from './LevelModal'; // 赠送会员等级弹框
export { default as ReleaseProhibitModal } from './ReleaseProhibitModal'; // 会员解禁弹框
export { default as ServeceModal } from './ServeceModal'; // 修改服务商弹框
export { default as AreaModal } from './AreaModal'; // 修改区域弹框
export { default as LoginChangeModal } from './LoginChangeModal'; // 修改登录账号弹框
export { default as SetIntegral } from './SetIntegral'; // 批量设置赠送特权值
export { default as GoldMemberArea } from './GoldMemberArea'; // 添加黄金会员
export { default as GoldMemberChangeArea } from './GoldMemberChangeArea'; // 黄金会员修改区域



