import * as Audit from './actiontypes';

export const getWithdrawList = (params) => ({
    type: Audit.GET_WITHDRAW_LIST,
    payload: { ...params }
});
export const getWithdrawDetail = (id) => ({
    type: Audit.GET_WITHDRAW_DETAIL,
    payload: { id }
});
export const getWithdrawSele = () => ({
    type: Audit.GET_WITHDRAW_SELE,
    payload: {}
});
export const getPayDetail = (withdrawalIds) => ({
    type: Audit.GET_PAY_DETAIL,
    payload: { withdrawalIds }
});
export const getServiceTypeEnum = () => ({
    type: Audit.GET_SERVICE_TYPE_ENUM,
    payload: {}
});