/**
 * 全局参数
 */
import uploadObjectConfig from '@/components/NewUpload/config'; // 上传配置
import * as qiniu from 'qiniu-js'; // 七牛云

export const autoPageNum = 1; // 默认分页起始页
export const autopageSize = 30; // 默认分页每页条数

// 富文本上传函数
const uploadFn = async (param) => {
    let fileName = param.file.name;
    let cf = uploadObjectConfig['image'];
    let token = (await cf.getToken({ fileName }) || {}).result;
    if (token) {
        const observer = {
            next(res) {
                param.progress(res.total.percent);
            },
            error(err) {
                param.error({
                    msg: err.message
                });
            },
            complete({ key }) {
                cf.getFile({ key, fileName }).then(({ result } = {}) => {
                    param.success({ url: result });
                });
            }
        };
        const UUID = uuid();
        var dfile = new File([param.file], UUID, { type: param.file.type });
        qiniu.upload(dfile, UUID, token).subscribe(observer);
    }
};
// 生成UUID唯一值
const uuid = () => {
    var s = [];
    var hexDigits = '0123456789abcdef';
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-';
    var uuid = s.join('');
    return uuid;
};
export const braftEditorMedia = {
    uploadFn: uploadFn,
    accepts: {
        image: 'image/png,image/jpeg,image/gif',
        video: 'video/mp4',
        audio: 'audio/mp3'
    },
    pasteImage: true,
    image: true, // 开启图片插入功能
    video: true, // 开启视频插入功能
    audio: true, // 开启音频插入功能
};
