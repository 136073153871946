//批量商品审核弹窗
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './BatchAudit.module.scss';
import http from '@/assets/api/http';
import { Modal, Input, Radio, message, Typography, DatePicker, Checkbox, Tooltip, Icon } from 'antd';
import { XInputNum, XSelect } from '@/components/xqxc_ui';
import moment from 'moment';

const { TextArea } = Input;

const BatchAudit = (props) => {

    const [visible, setVisible] = useState(props.visible);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [auditState, setAuditState] = useState('SUCCESS');
    const [opinion, setOpinion] = useState('');
    const [limitItem, setLimitItem] = useState({ code: 0, value: '否' });
    const [limitNumber, setLimitNumber] = useState(1);
    const [typeItem, setTypeItem] = useState({ code: 0, value: '暂不上架' });
    const [autoOnTime, setAutoOnTime] = useState(null);
    const [onChannels, setOnChannels] = useState([]);
    const [isTip, setIsTip] = useState(false);

    const _handleOk = () => {
        const { batch, } = props;
        if (opinion == '') return message.warning('请输入审核意见');
        if (auditState === 'SUCCESS' && limitItem.code && !limitNumber) {
            message.warning('请设置商品限购数');
            return;
        }
        let submitData = {
            wholesaleGoodsIds: batch, status: auditState, auditDesc: opinion, onChannels,
            limitNumber: (auditState === 'SUCCESS' && limitItem.code) ? limitNumber : 0
        };
        if (auditState === 'SUCCESS' && typeItem.code === '1' && !autoOnTime) {
            message.warning('请选择上架时间');
            return;
        }
        if (auditState === 'SUCCESS') {
            submitData.isTimer = typeItem.code;
        }
        if (autoOnTime) {
            submitData.autoOnTime = autoOnTime.format('YYYY-MM-DD HH:mm') + ':00';
        }
        setConfirmLoading(true);
        http('/goods/wholesale/auditWholesaleGoods', submitData, 'POST')
            .then(() => {
                message.success('批量审核操作成功');
                setVisible(false);
                setConfirmLoading(false);
                props.closeMode(true);
            })
            .catch((e = {}) => {
                message.error(e.message);
                setConfirmLoading(false);
            });
    };

    const _handleCancel = () => {
        setVisible(false);
        props.closeMode();
    };

    return <Modal
        width={720}
        centered
        title='批量商品批量审核'
        visible={visible}
        confirmLoading={confirmLoading}
        bodyStyle={{ padding: '20px' }}
        onOk={_handleOk}
        onCancel={_handleCancel}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div className={styles.container}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ color: '#252525' }}>设置发布端口</div>
                <div className={styles.portStyle}>
                    <Checkbox.Group
                        style={{ margin: '0 15px' }}
                        value={onChannels}
                        onChange={onChannels => setOnChannels(onChannels)}>
                        {
                            props.channelEnum.map(i => {
                                return <Checkbox key={i.code} value={i.code}>{i.value}</Checkbox>;
                            })
                        }
                    </Checkbox.Group>
                    <Tooltip
                        placement='topLeft'
                        title='设置当前商品在不同端口上架勾选则允许销售，不勾选则不允许销售；'
                        trigger='click'
                        visible={isTip}
                        onVisibleChange={() => setIsTip(!isTip)}
                    >
                        <Icon style={{ fontSize: '20px' }} type='question-circle' theme='twoTone' />
                    </Tooltip>
                </div>
            </div>
            <div className={styles.layout}>
                <label className={styles.label}>审核意见</label>
                <Radio.Group onChange={(e) => setAuditState(e.target.value)} value={auditState}>
                    <Radio value='SUCCESS'>审核通过</Radio>
                    <Radio value='FAIL'>审核不通过</Radio>
                </Radio.Group>
            </div>
            <div className={styles.layout}>
                <TextArea
                    placeholder="请输入审核意见"
                    maxLength={200}
                    autosize={{ minRows: 2, maxRows: 6 }}
                    onChange={(e) => setOpinion(e.target.value)}
                    value={opinion}
                />
            </div>
            {
                auditState === 'SUCCESS' &&
                <Fragment>
                    <div className={styles.layout}>
                        <XSelect
                            selectStyle={{ width: 60 }}
                            label='是否限购'
                            placeholder='请选择'
                            renderData={[{ code: 0, value: '否' }, { code: 1, value: '是' }]}
                            dataIndex='value'
                            keyIndex='code'
                            onChange={value => setLimitItem(value)}
                            value={limitItem.value}
                        />
                        {
                            !!limitItem.code &&
                            <Fragment>
                                <XInputNum
                                    inputStyle={{ width: 80 }}
                                    placeholder='请输入限购数量'
                                    min={1}
                                    max={100}
                                    value={limitNumber}
                                    onChange={value => setLimitNumber(value)}
                                />
                                <Typography.Text strong >件</Typography.Text>
                                <Typography.Text
                                    type='secondary'
                                    style={{ paddingLeft: 20 }
                                    }>每件商品用户在每个销售渠道的可购买数量，提交后不可修改</Typography.Text>
                            </Fragment>
                        }
                    </div>
                    <div className={styles.layout}>
                        <XSelect
                            selectStyle={{ width: '120px' }}
                            label='上架时间'
                            placeholder='请选择'
                            renderData={[{ code: '0', value: '暂不上架' }, { code: '1', value: '自定义时间' }]}
                            dataIndex='value'
                            keyIndex='code'
                            onChange={value => setTypeItem(value)}
                            value={typeItem.value}
                        />
                        {
                            typeItem.code === '1' &&
                            <DatePicker
                                value={autoOnTime}
                                placeholder='请选择时间'
                                allowClear={false}
                                onChange={value => setAutoOnTime(value)}
                                format='YYYY-MM-DD HH:mm'
                                showTime={{ format: 'HH:mm' }}
                                disabledDate={current => {
                                    if (current && current < moment()) {
                                        return true;
                                    }
                                }}
                                disabledTime={current => {
                                    if (current && current < moment()) {
                                        return true;
                                    }
                                }}
                            />
                        }
                    </div>
                </Fragment>
            }
        </div>
    </Modal>;
};

BatchAudit.propTypes = {
    batch: PropTypes.array,//弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    channelEnum: PropTypes.array
};

export default BatchAudit;