/**
 * 充值规则创建与编辑
 */
import React from 'react';
import { Modal, Radio, Icon, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './DurationRulesModal.module.scss';
import { XInput } from '@/components/xqxc_ui';
import { formValidFns } from '@/assets/js/utils';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;
class DurationRulesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,

            id: this.props.data.id,
            showMsg: this.props.data.showMsg,
            state: this.props.data.state,
            starValue: this.props.data.starValue,
            seconds: this.props.data.seconds !== undefined ? (this.props.data.seconds) / 60 : '',

        };
    }

    _handleOk = () => {

        const { data } = this.props;
        const { showMsg, state, starValue, seconds } = this.state;

        const method = data.id === undefined ? 'insert' : 'update';

        if (formValidFns({ showMsg, state, starValue, seconds }, {
            showMsg: '请输入规则名称',
            starValue: { validator: v => /^[1-9]\d*$/.test(v), message: '请输入魅力值，只能为数字' },
            seconds: { validator: v => /^[1-9]\d*$/.test(v), message: '请输入时长，只能为数字' }
        })) {
            this.setState({ confirmLoading: true }, () => {
                http(`/show/timesConfig/${method}`, {
                    showMsg, state, starValue, seconds: (seconds * 60), id: this.props.data.id
                }, 'POST').then((res = {}) => {
                    if (res.code === 500) {
                        message.error(res.message);
                    } else {
                        data === undefined ? message.success('创建成功。') : message.success('更新成功。');
                        this.setState({ visible: false });
                        this.props.closeMode(true);
                    }
                }).catch((res = {}) => {
                    this.setState({ confirmLoading: false });
                    message.warn(res.message || '请求错误');
                });
            });
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // Radio状态改变时触发
    _radioValueChangeHandle = (e) => {
        this.setState({ state: e.target.value });
    }

    render() {
        const { visible, confirmLoading, title, showMsg, state, starValue, seconds } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '408px' }}
                        inputStyle={{ width: '340px' }}
                        label='规则名称'
                        placeholder='请输入规则名称'
                        value={showMsg}
                        bindThis={this}
                        bindName='showMsg'
                    />
                    <div className={styles.radio}>
                        <div className={styles.label}>状态</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={state}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <XInput
                        style={{ width: '408px', margin: '26px 0 0 0' }}
                        inputStyle={{ width: '340px' }}
                        label='所需人气值'
                        placeholder='输入所需人气值，整形数字'
                        value={starValue}
                        bindThis={this}
                        bindName='starValue'
                    />
                    <XInput
                        style={{ width: '434px', margin: '26px 0 0 0', marginLeft: '-22px' }}
                        inputStyle={{ width: '340px' }}
                        label='直播时长(分)'
                        placeholder='输入直播时长，整型数字，按分钟为单位'
                        value={seconds}
                        bindThis={this}
                        bindName='seconds'
                    />
                </div>
            </Modal>
        );

    }
}

DurationRulesModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
    modetext: PropTypes.string,//区分魅力值与人气值 分类编码
};

export default DurationRulesModal;