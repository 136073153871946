import React from 'react';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './OrderTable.module.scss';

const changeType = {
    INCOME: '+',
    EXPEND: '-'
};

class OrderTable extends React.Component {
    state = {
        selectedRowKeys: [],
    }

    _takeColumns = () => ([
        {
            title: '完成时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '特权值来源',
            dataIndex: 'sourceType',
            key: 'sourceType',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '使用特权值',
            dataIndex: 'changeBalance',
            key: 'changeBalance',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => tooltip(changeType[item.changeType] ? changeType[item.changeType] + text : '-')
        },
        {
            title: '剩余特权值',
            dataIndex: 'remainBalance',
            key: 'remainBalance',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'info')}>查看详情</div>
                {
                    item.exchangeState.code == 'UNCHANGED' &&
                    <div className={styles.item} onClick={() => tableAction(key, 'delivery', item)}>交割</div>
                }
                {
                    item.exchangeState.code == 'UNCHANGED' &&
                    <div className={styles.item} onClick={() => tableAction(key, 'cancellation', item)}>作废</div>
                }
            </div>
        );
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default OrderTable;
