/**
 * 服务商业绩统计
 */
import React, { Component } from 'react';
import { Tag, message } from 'antd';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XOKButton, XCancelButton, XSelect, XInput } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import TableContent from './components/TableContent';
import model from '@/reducers/statistical/servicegrade/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class ServiceGrade extends Component {

    static propTypes = {
        history: PropTypes.object.isRequired,//路由对象
        keepSecondNavBreadcrumb: PropTypes.func.isRequired, // 保持两级面包屑导航
        getOperationCenters: PropTypes.func,
        operationCenters: PropTypes.array,
        getAreaCompanys: PropTypes.func,
        cleanAreaCompanys: PropTypes.func,
        areaCompanys: PropTypes.array,
        getServicerNames: PropTypes.func,
        servicerNames: PropTypes.array,
        cleanServicerNames: PropTypes.func,
        getServicegradeList: PropTypes.func,
        tableDataOrigin: PropTypes.object,

    }

    static defaultProps = {
        tableDataOrigin: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
        servicerNames: [],
        operationCenters: [],
        areaCompanys: []
    }

    state = {
        telephone: '',
        xiubeiSubtitle: '',
        servicerName: {},
        operateCenter: {},
        areaCompany: {},
        tags: [{
            id: 20,
            name: '平台Top20',
            active: false
        }, {
            id: 50,
            name: '平台Top50',
            active: false
        }],
        topNum: 0
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, cleanServicerNames, getOperationCenters, cleanAreaCompanys } = this.props;
        keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        cleanServicerNames();
        cleanAreaCompanys();
        getOperationCenters();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { telephone, xiubeiSubtitle, servicerName, operateCenter, areaCompany, pageNum, pageSize, tags } = this.state;
        let reqParams = {
            telephone, xiubeiSubtitle, topNum: 0,
            serviceProviderId: servicerName.id,
            operatingCenterId: operateCenter.id,
            regionalCompanyId: areaCompany.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        let newTags = tags.map(item => {
            return { ...item, active: false };
        });
        this.setState({ topNum: 0, tags: newTags }, () => {
            this.props.getServicegradeList(reqParams);
        });
        KeepAlive.saveCache({ ...this.state, pageNum: reqParams.pageNum, pageSize: reqParams.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        const { tags } = this.state;
        const { cleanServicerNames, cleanAreaCompanys } = this.props;
        cleanServicerNames();
        cleanAreaCompanys();
        this.setState({ telephone: '', xiubeiSubtitle: '', servicerName: {}, operateCenter: {}, areaCompany: {}, topNum: 0 }, () => {
            let newTags = tags.map(item => {
                return { ...item, active: false };
            });
            this.setState({ tags: newTags });
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            const { telephone, xiubeiSubtitle, servicerName, topNum, operateCenter, areaCompany, pageNum, pageSize, tags } = this.state;
            let reqParams = {
                telephone, xiubeiSubtitle, topNum,
                serviceProviderName: servicerName.id,
                operatingCenterId: operateCenter.id,
                regionalCompanyId: areaCompany.id,
                pageNum, pageSize
            };
            this.props.getServicegradeList(reqParams);
        });
    }

    //最新排名
    _onNewest = () => {
        const { tags } = this.state;
        const { cleanServicerNames, cleanAreaCompanys } = this.props;
        cleanServicerNames();
        cleanAreaCompanys();
        http('/boss/xiuBeiPerformanceStatistics/rank', {}, 'POST')
            .then(result => {
                if (result.code == 200) {
                    this.setState({ telephone: '', xiubeiSubtitle: '', servicerName: {}, operateCenter: {}, areaCompany: {}, topNum: 0 }, () => {
                        let newTags = tags.map(item => {
                            return { ...item, active: false };
                        });
                        this.setState({ tags: newTags });
                        this._searchHandle('useCache');
                        message.success('排名更新成功');
                    });
                }
            })
            .catch(e => {
                message.error(e.message);
            });
    }

    //标签点击事件
    _onTagClick = ({ id, active }) => {
        const { cleanServicerNames, cleanAreaCompanys, getServicegradeList } = this.props;
        const oldTags = [...this.state.tags];
        const newTags = oldTags.map(tag => {
            if (tag.id === id) {
                if (!active) return { ...tag, active: true };
                return { ...tag };
            } else {
                if (!active) return { ...tag, active: false };
                return { ...tag };
            }
        });
        this.setState({ tags: newTags, topNum: id, telephone: '', xiubeiSubtitle: '', servicerName: {}, operateCenter: {}, areaCompany: {}, pageNum: 1, pageSize: 30 }, () => {
            const { pageNum, pageSize } = this.state;
            cleanServicerNames();
            cleanAreaCompanys();
            getServicegradeList({
                topNum: id, pageNum, pageSize
            });
        });
    }

    render() {
        const { telephone, servicerName, xiubeiSubtitle, operateCenter, areaCompany, tags } = this.state;
        const { getAreaCompanys,
            getServicerNames,
            servicerNames,
            operationCenters,
            areaCompanys,
            tableDataOrigin,
            cleanServicerNames,
            cleanAreaCompanys } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='手机号'
                                placeholder='请输入手机号'
                                value={telephone}
                                bindThis={this}
                                bindName='telephone'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='运营中心'
                                placeholder='请选择'
                                renderData={operationCenters}
                                dataIndex='companyName'
                                keyIndex='id'
                                value={operateCenter.companyName}
                                bindThis={this}
                                bindName='operateCenter'
                                showSearch={true}
                                onChange={item => {
                                    cleanServicerNames();
                                    cleanAreaCompanys();
                                    this.setState({ areaCompany: {}, servicerName: {} });
                                    getAreaCompanys({ operationsId: item.id });
                                }}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='区域公司'
                                placeholder='请选择'
                                renderData={areaCompanys}
                                dataIndex='companyName'
                                keyIndex='id'
                                value={areaCompany.companyName}
                                bindThis={this}
                                bindName='areaCompany'
                                showSearch={true}
                                onChange={item => {
                                    cleanServicerNames();
                                    this.setState({ servicerName: {} });
                                    getServicerNames({ operationsId: operateCenter.id, areaCompanyId: item.id });
                                }}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='服务商名称'
                                placeholder='请选择'
                                renderData={servicerNames}
                                dataIndex='companyName'
                                keyIndex='id'
                                isOptionBindID={true}
                                value={servicerName.companyName}
                                bindThis={this}
                                bindName='servicerName'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='贡献值副标题'
                                placeholder='请输入贡献值副标题'
                                value={xiubeiSubtitle}
                                bindThis={this}
                                bindName='xiubeiSubtitle'
                            />
                        </SearchItem>
                        <SearchItem>
                            <div className={styles.tag_group}>
                                {
                                    tags.map(item => {
                                        return <Tag
                                            key={item.id}
                                            style={{ width: '80px', textAlign: 'center', cursor: 'pointer' }}
                                            color={item.active ? '#108ee9' : ''}
                                            onClick={() => this._onTagClick(item)}
                                        >{item.name}</Tag>;
                                    })
                                }
                            </div>
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton
                            style={{ width: '100px' }}
                            label='最新排名'
                            onClick={this._onNewest}
                        />
                    </div>
                    <TableContent renderData={tableDataOrigin} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive >
        );
    }
}

const mapStateToProps = (state) => ({
    tableDataOrigin: state.servicegrade.servicegradeList,
    servicerNames: state.servicegrade.servicerNames,
    operationCenters: state.servicegrade.operationCenters,
    areaCompanys: state.servicegrade.areaCompanys,
});
export default connect(mapStateToProps, { keepSecondNavBreadcrumb, ...model.actions })(ServiceGrade);