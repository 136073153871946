/**
 * 禁播弹框
 */
import React from 'react';
import { Modal, Radio, Input, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './BanPlayModal.module.scss';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;
const { TextArea } = Input;

class BanPlayModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        status: '',
        outerLabel: '',
        reason: ''
    }

    _handleOk = () => {
        const { outerLabel, reason } = this.state;
        const { accountId, broadcasId } = this.props.audit;
        console.log(accountId, broadcasId);
        this.setState({ confirmLoading: true }, () => {
            http('/show/law/banned', { broadcasId, accountId, outerLabel, reason }, 'POST').then(() => {
                message.success('请求成功！');
                this.setState({
                    visible: false,
                    confirmLoading: false
                });
                this.props.closeMode(true);
            }).catch((error) => {
                message.error(error.message);
                this.setState({
                    confirmLoading: false
                });
                this.props.closeMode(false);
            });
        });
    }


    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    onChange = e => {
        this.setState({
            outerLabel: e.target.value
        });
    };

    _reason = (e) => {
        this.setState({
            reason: e.target.value
        });
    }

    _renderRadio = (list) => {
        return list.map((item) => {
            return (<Radio key={item.code} value={item.code}>{item.value}</Radio>);
        });
    }

    render() {
        const { visible, confirmLoading, reason } = this.state;
        const { typeList } = this.props;

        return (
            <Modal
                width={470}
                centered
                title='禁播'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.ban}>
                        <span>禁播理由：</span>
                        <span>
                            <RadioGroup onChange={this.onChange} value={this.state.outerLabel}>
                                {this._renderRadio(typeList)}
                            </RadioGroup>
                        </span>
                        <span>
                            <TextArea
                                onChange={this._reason}
                                value={reason}
                                placeholder="请输入禁播原因"
                                autosize={{ minRows: 3, maxRows: 6 }}
                            />
                        </span>
                    </div>
                </div>
            </Modal>
        );
    }
}

BanPlayModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    SinglePatch: PropTypes.bool, // 权限：单个修改还是批量修改，当为true时表示单个修改
    data: PropTypes.number, // 需要审批的用户ID
    audit: PropTypes.object,
    typeList: PropTypes.array,
};

export default BanPlayModal;