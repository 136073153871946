import React, { Component } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './RecordsTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

export default class RecordsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    _columns = () => [
        {
            title: '生成序号',
            dataIndex: 'recordId',
            key: 'recordId',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '报表名称',
            dataIndex: 'templateName',
            key: 'templateName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '用途',
            dataIndex: 'purpose',
            key: 'purpose',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '使用条件',
            dataIndex: 'query',
            key: 'query',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作人',
            dataIndex: 'createUserName',
            key: 'createUserName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '生成时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '10%',
            render: (id, item) => this._tableAction(id, item)
        },
    ];

    // 解析表格数据，使符合columns格式要求
    _takeDataSource = (data = []) => {
        return data.map(item => {
            item.recordId = item.id;
            return item;
        });
    }

    //表格操作
    _tableAction = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'down', item)}>下载报表</div>
                <div className={styles.item} onClick={() => tableAction(key, 'regenerate', item)}>重新生成</div>
            </div>
        );
    };

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

RecordsTable.propTypes = {
    renderData: PropTypes.object, // 表格数据源
    tableAction: PropTypes.func, // 表格
    paginationChange: PropTypes.func, // 分页
};