/**
 * 会员管理 Saga集合
 */
import * as blacklistSage from './blacklist/saga';
import member from './member/model';
import membermlx from './membermlx/model';
import * as tvanchorSage from './tvanchor/saga';
import * as serviceSage from './service/saga';
import recordconversion from './recordconversion/model';
import corporateanchor from './corporateanchor/model';
import saler from './saler/model';
import blacklist from './blacklist/model';
import org from './org/model';

export default [
    ...Object.values(member.sagas),
    ...Object.values(membermlx.sagas),
    ...Object.values(tvanchorSage),
    ...Object.values(serviceSage),
    ...Object.values(blacklistSage),
    ...Object.values(recordconversion.sagas),
    ...Object.values(corporateanchor.sagas),
    ...Object.values(saler.sagas),
    ...Object.values(blacklist.sagas),
    ...Object.values(org.sagas),
];
