import { createModel } from '@/store/tools';

const model = {
    namespace: 'wholesale',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getDistributionAfterSaleOrderList': T('getDistributionAfterSaleOrderList'),
            'getlistMerchantAddress': T('getlistMerchantAddress'),
        },
        actions: {
            'getDistributionAfterSaleOrderList': A('getDistributionAfterSaleOrderList'),
            'getlistMerchantAddress': A('getlistMerchantAddress'),
        },
        sagas: {
            'getDistributionAfterSaleOrderList': S('getDistributionAfterSaleOrderList', '/boss/order/getDistributionAfterSaleOrderList'),
            'getlistMerchantAddress': S('getlistMerchantAddress', '/boss/order/after/sales/listMerchantDetails'),
        },
        reducers: {
            'DistributionAfterSaleOrderList': R('getDistributionAfterSaleOrderList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'listMerchantAddress': R('getlistMerchantAddress', []),
        }
    })
};
export default createModel(model);