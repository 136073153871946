import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell, ImgCell, cellWidth } from '@/components/TableCell';

class TableContent extends Component {

	_columns = () => [
		{
			title: '封面主图',
			dataIndex: 'mainImgUrl',
			key: 'mainImgUrl',
			align: 'center',
			width: cellWidth.small,
			onCell: tooltipStyle,
			render: ImgCell
		},
		{
			title: '服务编号',
			dataIndex: 'goodsNo',
			key: 'goodsNo',
			align: 'center',
			width: cellWidth.time,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '服务名称',
			dataIndex: 'goodsName',
			key: 'goodsName',
			align: 'center',
			width: cellWidth.time,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '服务分类',
			dataIndex: 'categoryFullName',
			key: 'categoryFullName',
			align: 'center',
			width: cellWidth.time,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '原价',
			dataIndex: 'marketPrice',
			key: 'marketPrice',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: priceFenToYuanCell
		},
		{
			title: '销售价格',
			dataIndex: 'salePrice',
			key: 'salePrice',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: priceFenToYuanCell
		},
		{
			title: '最高抵用人气值',
			dataIndex: 'starValue',
			key: 'starValue',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: priceFenToYuanCell
		},
		// {
		// 	title: '技术服务费',
		// 	dataIndex: 'platRate',
		// 	key: 'platRate',
		// 	align: 'center',
		// 	width: cellWidth.small,
		// 	onCell: tooltipStyle,
		// 	render: (text, record) => {
		// 		return <span>{text}%</span>;

		// 	}
		// },
		// {
		// 	title: '平台服务费',
		// 	dataIndex: 'agentRate',
		// 	key: 'agentRate',
		// 	align: 'center',
		// 	width: cellWidth.small,
		// 	onCell: tooltipStyle,
		// 	render: (text, record) => {
		// 		return <span>{text}%</span>;

		// 	}
		// },
		{
			title: '状态',
			dataIndex: 'goodsState',
			key: 'goodsState',
			align: 'center',
			width: cellWidth.time,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '更新时间',
			dataIndex: 'gmtModified',
			key: 'gmtModified',
			align: 'center',
			width: cellWidth.time,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'goodsId',
			key: 'goodsId',
			align: 'center',
			width: cellWidth.time,
			fixed: 'right',
			render: (key, record) => this._tableAction(key, record)
		},
	];

	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'detail')}>详情</div>
					<div className={styles.item} onClick={() => tableAction(key, 'edit', record)}>编辑</div>
					{ record.goodsStateCode === 'PUT_ON_SALE' ?
					<div className={styles.item} onClick={() => tableAction(key, 'off', record)}>下架</div>
						: record.goodsStateCode === 'PULL_OFF_SHELVES' || record.goodsStateCode ==='SUCCESS' ?
							<div className={styles.item} onClick={() => tableAction(key, 'on')}>上架</div>
							: null }
					{record.goodsStateCode === 'PULL_OFF_SHELVES' && <div className={styles.item} onClick={() => tableAction(key, 'delete')}>删除</div>}
					{record.goodsStateCode === 'PUT_ON_SALE' && <div className={styles.item} style={{ color: '#AAAAAA'}}>删除</div>}
				</div>
			);
	};

	_tableData = (dataList) => {
		if (dataList) {
			return dataList;
		} else {
			return [];
		}
	};

	render() {
		const { renderData } = this.props;
		return (
			<div className={styles.tableContainer} style={{ height: '100%' }}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey={record => record.goodsId}
					columns={this._columns()}
					pagination={false}
					dataSource={this._tableData(renderData.dataList)}
					scroll={{ x: 'max-content', y: this.props.scrollY }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={this.props.paginationChange} />
			</div>
		);
	}
}

TableContent.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
	scrollY: PropTypes.string
};

export default XTableHOC(TableContent);