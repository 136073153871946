/**
 * 礼物列表创建或编辑
 */
import React from 'react';
import { Modal, Radio, Icon, message, Button } from 'antd';
import PropTypes from 'prop-types';
import styles from './GlistModal.module.scss';
import { XInput, XSelect, XUpload } from '@/components/xqxc_ui';
import CusotmUpload, { generateFileList, generateDuration } from '@/components/NewUpload';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;

class GlistModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,

            type: {},//分类
            nameInput: this.props.data === undefined ? '' : this.props.data.giftName, // 名称
            stateRadioValue: this.props.data === undefined ? 1 : this.props.data.state, // 状态（0：禁止 1：启用）


            giftCostType: this.props.data === undefined ? this.props.stratInit : this.props.data.giftCostType,
            id: this.props.data === undefined ? '' : this.props.data.id,//主键ID
            starInput: this.props.data === undefined ? '' : this.props.data.giftCost,// 魅力值数
            recommendRadioValue: this.props.data === undefined ? 1 : this.props.data.isReco, // 是否推荐(0:否 1是)
            singleImage: this.props.data === undefined ? '' : this.props.data.giftImg, // 分类图标,判断图片是否有值 赋值url

            singleImages: [],//图片上传
            fileName: this.props.data === undefined ? '' : generateFileList(this.props.data.fileUrl),//资源包文件名称
            musicFileNames: this.props.data === undefined ? [] : generateFileList(this.props.data.fileUrl),//资源包

            startText: this.props.modetext == 'startLight' ? '魅力值' : '人气值',//判断魅力值与人气值输入lable startLight  startValue
        };

        const { singleImage, } = this.state;

        if (singleImage) {//在编辑状态中提换default图标
            this.state.singleImages = [{
                uid: '0',
                name: 'default',
                status: 'done',
                url: singleImage,
            }];
        }
    }


    componentDidMount() {
        const { selectData, data } = this.props;
        let newSource = selectData;
        let editegiftClassIdItem = {};
        if (data !== undefined) {
            for (let i = 0; i < newSource.length; i++) {
                if (newSource[i].id == data.giftClassId) {
                    editegiftClassIdItem = newSource[i];
                    break;
                }
            }
            this.setState({ type: editegiftClassIdItem });
        }
    }

    // 确认
    _handleOk = () => {

        const { data } = this.props;
        const {
            nameInput,
            stateRadioValue,
            type,
            starInput,
            recommendRadioValue,
            id,
            singleImage,
            // fileName,
            startText,
            giftCostType,
            musicFileNames,
        } = this.state;

        const method = data === undefined ? 'insert' : 'update';
        let starInputReg = /^[1-9]\d*$/;
        let fileDataUrl;//区分新建和编辑的资源包url
        let fileNames;//区分新建和编辑的资源包name
        if (nameInput == '') {
            message.warn('请输入礼物名称。');
        } else if (Object.keys(type).length == 0) {
            message.warn('请输入礼物分类。');
        } else if (starInput == '' || !starInputReg.test(starInput)) {//startText
            message.warn(`请输入所需${startText}（整数）。`);
        } else if (data === undefined ? singleImage === undefined || singleImage == '' : singleImage === undefined) {
            message.warn('请输入分类图标。');
        } else {
            if (starInput > 20000) {
                message.warn('单个礼物最高限制20000个魅力值，请重新设置！');
                return;
            }
            if (data === undefined) {
                fileDataUrl = musicFileNames[0] ? musicFileNames[0].response.key : '';
                fileNames = musicFileNames[0] ? musicFileNames[0].name : '';
            } else {
                fileDataUrl = musicFileNames.length ? (musicFileNames[0].response ? musicFileNames[0].response.key : musicFileNames[0].url) : '';
                fileNames = musicFileNames.length ? (musicFileNames[0].response ? musicFileNames[0].name : musicFileNames[0].name) : '';
            }
            this.setState({ confirmLoading: true }, () => {
                http(`/show/giftInfo/${method}`, {
                    giftName: nameInput,
                    state: stateRadioValue,
                    giftClassId: type.id,//下拉主键id
                    giftCost: starInput,
                    isReco: recommendRadioValue,
                    sortNo: recommendRadioValue,
                    giftImg: singleImage,
                    id,
                    giftCostType,
                    fileUrl: fileDataUrl,
                    fileName: fileNames,
                }, 'POST').then(() => {
                    data === undefined ? message.success('创建成功。') : message.success('更新成功。');
                    this.setState({
                        visible: false,
                        confirmLoading: false
                    });
                    this.props.closeMode(true);
                }).catch((res) => {
                    this.setState({ confirmLoading: false });
                    if (res.code == '500') {
                        message.error(res.message);
                    }
                });
            });
        }

    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    //状态
    _stateRadioValueValueChangeHandle = (e) => {
        this.setState({ stateRadioValue: e.target.value });
    }

    // 礼物分类下拉列改变时触发
    _typeChangeHandle = (selectedItem) => {
        this.setState({ type: selectedItem });
    }

    // 礼物名称输入改变时触发
    _nameInputChangeHandle = (value) => {
        this.setState({ nameInput: value });
    }

    // 所需魅力值改变时触发
    _starInputChangeHandle = (value) => {
        this.setState({ starInput: value });
    }

    // 推荐发生改变时触发
    _recommendRadioValueChangeHandle = (e) => {
        this.setState({ recommendRadioValue: e.target.value });
    }

    // 下拉选项数据处理渲染
    _giftSelectData(data) {
        return data && data.map(item => ({
            id: item.id,
            className: item.className,
        }));
    }

    render() {
        const { selectData, } = this.props;
        const {
            visible,
            confirmLoading,
            title,
            stateRadioValue,
            type,
            recommendRadioValue,
            singleImages,
            musicFileNames,
            startText,
        } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '408px' }}
                        inputStyle={{ width: '340px' }}
                        label='礼物名称'
                        placeholder='请输入礼物名称'
                        onChange={this._nameInputChangeHandle}
                        value={this.state.nameInput}
                    />
                    <div className={styles.radio}>
                        <div className={styles.label}>状态</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._stateRadioValueValueChangeHandle} value={stateRadioValue}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <XSelect
                        style={{ marginTop: '30px', width: '410px' }}
                        selectStyle={{ width: '342px' }}
                        label='礼物分类'
                        placeholder='请选择礼物分类'
                        renderData={this._giftSelectData(selectData)}
                        onChange={this._typeChangeHandle}
                        dataIndex='className'
                        keyIndex='id'
                        value={type.className}
                    />
                    <XInput
                        style={{ marginTop: '30px', width: '408px' }}//startText  请输入魅力值
                        inputStyle={{ width: '340px' }}
                        label={`所需${startText}`}
                        placeholder={`请输入${startText}`}
                        onChange={this._starInputChangeHandle}
                        value={this.state.starInput}
                    />
                    <div className={styles.radio}>
                        <div className={styles.label}>推荐</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._recommendRadioValueChangeHandle} value={recommendRadioValue}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.upload}>
                        <div className={styles.label}>礼物图片</div>
                        <div className={styles.icon}>
                            <XUpload
                                uploadButton="singleImg"
                                handleChange={(file) => {
                                    if (file.status === 'done') {
                                        this.setState({ singleImage: file.qiniuInfo.key });
                                    }
                                    if (file.status === 'removed') {
                                        this.setState({
                                            singleImage: undefined,
                                            singleImages: [],
                                        });
                                    }
                                }}
                                antdUploadProps={{
                                    accept: '.png, .jpg, .jpeg',
                                    listType: 'picture-card',
                                    showUploadList: false,
                                    defaultFileList: singleImages
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.zip}>
                        <div className={styles.label}>
                            资源包
                        </div>
                        <div className={styles.btn}>
                            {/* <XUpload
                                // type='media'
                                type='image'
                                uploadButton="button"
                                // maxFileList={1}
                                // hasAudioDuration
                                // removeOverMax
                                handleChange={(file) => {
                                    if (file.status === 'done') {
                                        this.setState({
                                            attachUrl: file.qiniuInfo.key,
                                            fileName: file.name,
                                        });
                                    }
                                    if (file.status === 'removed') {
                                        this.setState({
                                            attachUrl: undefined,
                                            fileName: '',
                                            musicFileNames: [],
                                        });
                                    }
                                }}
                                antdUploadProps={{
                                    accept: '.svga, .png, .jpg',
                                    defaultFileList: musicFileNames
                                }}
                            /> */}
                            <CusotmUpload
                                accept='.svga, .png, .jpg'
                                fileList={musicFileNames}
                                returnAudioDuration
                                qiniuUploadProps={{
                                    uploadType: 'image'
                                }}
                                onChange={(info) => {
                                    const musicFileNames = info.fileList;
                                    this.setState({ musicFileNames });
                                }}
                            >
                                <Button icon="upload">上传</Button>
                            </CusotmUpload>
                        </div>
                    </div>
                    <p className={styles.media}>资源包为svga, png, jpg格式。</p>
                </div>
            </Modal >
        );
    }
}


GlistModal.propTypes = {
    data: PropTypes.object, // 对应主键ID的信息
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    selectData: PropTypes.array,//分类下拉数据  
    modetext: PropTypes.string,//区分魅力值与人气值  
    stratInit: PropTypes.string,//区分魅力值与人气值 分类编码
};

export default GlistModal;