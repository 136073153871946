import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import Virtual from '../../modal/Virtual';
import AddCirculation from '../../modal/AddCirculation';
import Settlement from '../../modal/Settlement';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/starvalueshop/stockDividends/model';
import { Mode } from '../_utils';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';
import { SearchBox, SearchItem } from '@/components/Layout';
import http from '@/assets/api/http';


class Main extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
        stockName: '',//股票名称
        id: '',//股票代码
        supplier: {},//供应商
        state: {},
        stockState: {},
    }
    goodsCategory = React.createRef();

    componentDidMount() {
        this.props.getStateList();
        this.props.getStockStateList();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, stockName, state, stockState, id } = this.state;
        this.searchCache = {
            stockName,//股票名称  
            id,//股票代码  
            state: state.code,
            stockState: stockState.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getStockDividendsList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ stockName: undefined, id: undefined, state: {}, stockState: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //创建股票收益
    _createStockDividends = () => {
        addOneBreadcrumbPath({
            title: '创建股票收益',
            path: '/home/showbeanmanage/stockDividends/create/'
        });
        this.props.history.push({
            pathname: '/home/showbeanmanage/stockDividends/create/',
            state: { keepAlive: true }
        });
    }


    // 表格操作
    _tableAction = (key, type, params) => {
        const { history, addOneBreadcrumbPath } = this.props;

        switch (type) {
            case Mode.Detail:
            case Mode.Edit:
                addOneBreadcrumbPath({
                    title: '股票收益详情',
                    path: `/home/showbeanmanage/stockDividends/${type}/` + key
                });
                history.push({
                    pathname: `/home/showbeanmanage/stockDividends/${type}/` + key
                });
                break;
            case Mode.Virtual:
                this.setState({
                    currentOpenMode: Mode.Virtual,
                    openModeParam: params,
                });
                break;
            case Mode.AddCirculation:
                this.setState({
                    currentOpenMode: Mode.AddCirculation,
                    openModeParam: params,
                });
                break;
            case Mode.Settlement:
                this.setState({
                    currentOpenMode: Mode.Settlement,
                    openModeParam: params,
                });
                break;
            case Mode.StockState:
                http('/xiudou/earnings/updateStart', {
                    id: key.id,
                    stockState: params
                }, 'POST').then(() => {
                    message.success('状态修改成功');
                    this._searchHandle();
                }).catch((reject) => {
                    message.error(reject.message);
                });
                break;
            case Mode.Delete:
                showConfirm('是否确定删除？', '', () => {
                    http('/xiudou/earnings/delete', { id: key }, 'POST').then(() => {
                        message.success('删除成功');
                        this._searchHandle('useCache');
                    }).catch((reject) => {
                        message.error(reject.message);
                    });
                });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.Virtual:
                return <Virtual title='设置虚拟销量' visible={true}
                    data={this.state.openModeParam}
                    closeMode={this._closeMode} />;
            case Mode.AddCirculation:
                return <AddCirculation title='增加发行量' visible={true}
                    data={this.state.openModeParam}
                    closeMode={this._closeMode} />;
            case Mode.Settlement:
                return <Settlement title='设置结算比例' visible={true}
                    data={this.state.openModeParam}
                    closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) this.props.getStockDividendsList(this.searchCache);// 重新渲染更新列表;// 重新渲染更新列表
    }


    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getStockDividendsList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }


    render() {
        const { stateList, stockStateList, stockDividendsList } = this.props;
        const { stockName, id, state, stockState } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                inputStyle={{ width: '150px' }}
                                label='股票名称'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='stockName'
                                value={stockName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                style={{ marginLeft: '20px' }}
                                inputStyle={{ width: '150px' }}
                                label='股票代码'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='id'
                                value={id}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                style={{ marginLeft: '20px', }}
                                selectStyle={{ width: '150px' }}
                                label='状态'
                                placeholder='请选择'
                                renderData={stateList}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='state'
                                value={state.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                style={{ marginLeft: '20px', }}
                                selectStyle={{ width: '150px' }}
                                label='是否启用'
                                placeholder='请选择'
                                renderData={stockStateList}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='stockState'
                                value={stockState.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '116px' }} label='新建股票收益' onClick={this._createStockDividends} />
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={stockDividendsList} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }

}

Main.propTypes = {
    history: PropTypes.object,
    updateContent: PropTypes.func,

    getStateList: PropTypes.func,
    stateList: PropTypes.object,

    getStockStateList: PropTypes.func,
    stockStateList: PropTypes.object,

    getStockDividendsList: PropTypes.func,
    stockDividendsList: PropTypes.array,
    addOneBreadcrumbPath: PropTypes.func
};

const mapStateToProps = (state) => ({
    stateList: state.stockDividends.stateList,
    stockStateList: state.stockDividends.stockStateList,
    stockDividendsList: state.stockDividends.stockDividendsList,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, ...model.actions })(Main);