/**
 * 区域公司审核
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Audit from './actiontypes';

//审核列表
export const getOperationInvoiceList = function* () {
    yield takeEvery(Audit.GET_OPERATIONINVOICE_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/settle/queryOpCenterSettles', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_OPERATIONINVOICE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//状态
export const getOperationInvoiceStatus = function* () {
    yield takeEvery(Audit.GET_OPERATIONINVOICE_STATUS, function* requestData(action) {
        try {
            let result = yield http('/settle/settleEnum/getSettlementStatus', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_OPERATIONINVOICE_STATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//详情
export const getOperationInvoiceDetail = function* () {
    yield takeEvery(Audit.GET_OPERATIONINVOICE_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/settle/settle/getAreaAndOpCenterSettle', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_OPERATIONINVOICE_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//公司名称
export const getOperationInvoiceName = function* () {
    yield takeEvery(Audit.GET_OPERATIONINVOICE_NAME, function* requestData(action) {
        try {
            let result = yield http('/admin/company/listOperationsIdAndName', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_OPERATIONINVOICE_NAME, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//付款单详情
export const getOperationInvoicePay = function* () {
    yield takeEvery(Audit.GET_OPERATIONINVOICE_PAY, function* requestData(action) {
        try {
            let result = yield http('/settle/settle/getUnpaidInfo', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_OPERATIONINVOICE_PAY, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};