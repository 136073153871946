import { createModel, createSaga, createReducer } from '@/store/tools';

const model = {
    namespace: 'advertService',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getAdvertServiceList': T('getAdvertServiceList'),
            'getAdvertState': T('getAdvertState'), // 状态
            'getAdvertInfo': T('getAdvertInfo'), // 详情
            'getProductList': T('getProductList'), // 分销商品列表
            'getUserScene': T('getUserScene'),
            'getPositionEnum': T('getPositionEnum'),
            'getUserProductList': T('getUserProductList'), // 用户提货券商品列表
        },
        actions: {
            'getAdvertServiceList': A('getAdvertServiceList'),
            'getAdvertState': A('getAdvertState'),
            'getAdvertInfo': A('getAdvertInfo'),
            'getCleanDetail': C('getAdvertInfo'), // 清除缓存详情
            'getProductList': A('getProductList'),
            'getUserScene': A('getUserScene'),
            'getPositionEnum': A('getPositionEnum'),
            'getUserProductList': A('getUserProductList'),
        },
        sagas: {
            'getAdvertServiceList': S('getAdvertServiceList', '/ad/merchant/list'),
            'getAdvertState': S('getAdvertState', '/enum/StateEnum'),
            'getAdvertInfo': S('getAdvertInfo', '/ad/merchant/detail'),
            'getProductList': S('getProductList', '/goods/query/queryDistributionGoods'),
            'getUserScene': S('getUserScene', '/enum/AdMerchantUseSceneEnum'),
            'getPositionEnum': S('getPositionEnum', '/enum/AdMerchantBannerPositionEnum'),
            'getUserProductList': S('getUserProductList', 'goods/query/queryGoodsItem'),
        },
        reducers: {
            'AdvertServiceList': R('getAdvertServiceList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'AdvertState': R('getAdvertState', []),
            'AdvertInfo': R('getAdvertInfo', {}),
            'ProductList': R('getProductList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'userScene': R('getUserScene', []),
            'positionEnum': R('getPositionEnum', []),
            'userProductList': R('getUserProductList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
        }
    })
};
export default createModel(model);