/**
 * 创建原因-(反馈类型)
 */
import React from 'react';
import { Modal, Input, Form, Radio, message } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './CreateModal.module.scss';
import http from '@/assets/api/http';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};
class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
        };
    }

    // className  img  state 
    _handleOk = () => {
        this._handleSubmit();
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    _handleSubmit = e => {//表单校验提交
        if (e) e.preventDefault();
        this.props.form.validateFields({ force: true }, (err, formDatas) => {
            if (!err) {
                this.setState({ confirmLoading: true }, () => {
                    http('/ideaBack/editIdeaBackType', {
                        ideaBackName: formDatas.ideaBackName,
                        state: formDatas.state,
                    }, 'POST').then(() => {
                        message.success('创建成功');
                        this.props.refsh();
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                        this.props.closeMode();
                    }).catch((reject) => {
                        message.error(reject.message);
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                        this.props.closeMode();
                    });
                });
            }
        });
    };

    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon={false}
                            htmlFor='ideaBackName'
                            label={<span>原因名称</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('ideaBackName', {
                                rules: [{ required: true, message: '请输入原因' }],
                            })(<Input placeholder='请输入' style={{ width: 220 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='state'
                            label={<span>状态</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('state', {
                                rules: [{ required: true, message: '请选择状态' }],
                            })(
                                <Radio.Group style={{ marginLeft: 10 }}>
                                    <Radio value={1}>启用</Radio>
                                    <Radio value={0}>禁用</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );

    }
}

CreateModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
    refsh: PropTypes.func,
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(CreateModal);// 校验

export default connect(null, null)(ForgotProducDetails);
