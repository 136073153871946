/**
 * 平台批发商品审核
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton, XInput, XScrollSelect } from '@/components/xqxc_ui';
import BatchAudit from '../../modal/BatchAudit';
import TableContent from '../../components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/auditmanage/palatGoodsAudit/model';
import { message } from 'antd';
import { GoodsClassify } from '@/vcomps';
import { SearchBox, SearchItem } from '@/components/Layout';
import commonmodel from '@/reducers/productmanage/wholesalegoods/model';

const { getChannelEnum } = commonmodel.actions;

//分销推荐
const recommendList = [
    { code: '1', value: '是' },
    { code: '0', value: '否' },
];

class AllProduct extends React.Component {

    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
        activityName: '', // 商品名称
        recommend: {}, // 分销推荐
        activeType: {}, // 业务类型
        productNo: '',//商品货号
        batch: [],//批量数据
        shopObj: {},//商户
        categoryId: '', // 商品联级分类
        cleanKeys: false,//清空表格复选框
        categorySubTypeItem: {}
    }

    componentDidMount() {
        const { getMerchantName, getActiveTypeEnum, getChannelEnum } = this.props;
        getMerchantName();
        getActiveTypeEnum();
        getChannelEnum();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { activityName, activeType, productNo, shopObj, pageNum, pageSize, recommend, categoryId, categorySubTypeItem } = this.state;
        this.searchCache = {
            goodsName: activityName,
            goodsType: activeType.code,
            goodsNos: productNo,
            isReco: recommend.code,
            merchantId: shopObj.merchantId,
            categoryId,
            categorySubType: categorySubTypeItem.code,
            status: 'WAIT_AUDIT',//待审核状态
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.palatGoodsAuditList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize, status: 'WAIT_AUDIT', }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ recommend: {}, activeType: {}, activityName: '', productNo: '', shopObj: {}, categoryId: '', categorySubTypeItem: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        this.cleanRef.clean();
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'detail':
                this.props.updateContent('AUDIT_DETAIL', id);
                break;
            case 'audit':
                this.props.updateContent('AUDIT_CONTENT', id);
                break;
            case 'batch':
                this.setState({ batch: value });
                break;
        }
    }

    // 打开弹窗
    _renderMode = () => {
        const { currentOpenMode, batch } = this.state;
        switch (currentOpenMode) {
            case 'audit':
                return <BatchAudit channelEnum={this.props.channelEnum} batch={batch} visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '', openModeParam: {} });
        if (status) {
            this._searchHandle('useCache');
            // 清空表格复选框
            this.setState({
                cleanKeys: !this.state.cleanKeys,
            });
        }
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    //批量审核
    _batchAudit = () => {
        if (this.state.batch.length === 0) return message.warning('请勾选商品');
        this.setState({ currentOpenMode: 'audit' });
    }

    render() {
        const { activeType, activityName, productNo, shopObj, recommend, cleanKeys, categorySubTypeItem } = this.state;
        const { goodsList, merchantName: { merchantIdAndNames = [] }, activeTypeEnum } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商品名称'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='activityName'
                                value={activityName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='商品货号'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='productNo'
                                value={productNo}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='分销推荐'
                                placeholder='请选择'
                                renderData={recommendList}
                                bindThis={this}
                                bindName='recommend'
                                dataIndex='value'
                                keyIndex='code'
                                value={recommend.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <div style={{ marginRight: 20 }}>
                                <span>商户</span>
                                <XScrollSelect
                                    style={{ width: 150, marginLeft: 10 }}
                                    url='/merchant/info/listMerchantIdAndNamePage'
                                    placeholder='请选择'
                                    showSearch
                                    mainKey='merchantName'
                                    selectID='merchantId'
                                    selectName='merchantName'
                                    value={shopObj}
                                    listLabel='dataList'
                                    isOptionBindID={true}
                                    onChange={(value, item) => this.setState({ shopObj: item.props['data-option'] })}
                                />
                            </div>
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='业务类型'
                                placeholder='请选择'
                                renderData={activeTypeEnum}
                                bindThis={this}
                                bindName='activeType'
                                dataIndex='value'
                                keyIndex='code'
                                value={activeType.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商品分类'
                                style={{ marginRight: '10px' }}
                                placeholder='请选择分类类型'
                                renderData={[
                                    { code: 'COMMON', value: '通用分类' },
                                    { code: 'ONLINE', value: '线上分类' },
                                    { code: 'CITY', value: '同城分类' }
                                ]}
                                bindThis={this}
                                bindName='categorySubTypeItem'
                                dataIndex='value'
                                keyIndex='code'
                                value={categorySubTypeItem.value}
                            />
                            <GoodsClassify
                                style={{ marginRight: '20px' }}
                                ref={ele => this.cleanRef = ele}
                                categorySubType={categorySubTypeItem.code}
                                onClassifyChange={value => this.setState({ categoryId: value })}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton
                            style={{ width: '100px' }}
                            label='批量审核'
                            onClick={this._batchAudit}
                        />
                    </div>
                    <TableContent
                        cleanKeys={cleanKeys}
                        paginationChange={this._paginationChange}
                        renderData={goodsList}
                        tableAction={this._tableAction}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

AllProduct.propTypes = {
    updateContent: PropTypes.func,
    palatGoodsAuditList: PropTypes.func,//列表数据(action)
    goodsList: PropTypes.object,//列表数据
    getMerchantName: PropTypes.func,//商户下拉数据(action)
    merchantName: PropTypes.object,//商户下拉数据
    getActiveTypeEnum: PropTypes.func,//业务类型下拉数据(action)
    activeTypeEnum: PropTypes.array,//业务类型拉数据
    getChannelEnum: PropTypes.func,
    channelEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
    goodsList: state.palatGoodsAudit.goodsList,
    merchantName: state.palatGoodsAudit.merchantName,
    activeTypeEnum: state.palatGoodsAudit.activeTypeEnum,
    channelEnum: state.wholesaleGoods.channelEnum,
});

export default connect(mapStateToProps, { ...model.actions, getChannelEnum })(AllProduct);