import * as Order from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

//订单列表数据
export const hotelOrderList = (state = initData, action) => {
    switch (action.type) {
        case Order.SET_HOTELORDER_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
// 支付方式
export const payWaysList = (state = [], action) => {
    switch (action.type) {
        case Order.SET_HOTELORDER_PAYWAYS:
            return action.payload.result;
        default:
            return state;
    }
};