import React from 'react';
import styles from './TableData.module.scss';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { XMediaFn, } from '@/components/xqxc_ui';
import ButtonPerssion from '@/routes/ButtonPerssion';

const columns = (actionClickHandle) => [
    {
        title: '服务商等级',
        dataIndex: 'levelValue',
        key: 'levelValue',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '身份称谓',
        dataIndex: 'levelName',
        key: 'levelName',
        width: '12%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '等级logo',
        dataIndex: 'levelLogo',
        key: 'levelLogo',
        width: '15%',
        align: 'center',
        render: (text, item) => (ImgCell(text, item))
    },
    {
        title: '所需能量值',
        dataIndex: 'needBeans',
        key: 'needBeans',
        width: '15%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '15%',
        render: (id, record) => TableAction(id, record, actionClickHandle)
    },
];

export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    return data && data.map((item) => {
        return item;
    });
};

// 表格项内容为图片
const ImgCell = (url, item) => {
    return (
        <div style={{ display: 'inline-flex' }}>
            <XMediaFn
                style={{ width: '50px', height: '50px', marginRight: '0' }}
                dataType='image'
                dataSource={url}
                key={item.id}
            />
        </div>
    );
};


export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};


const TableAction = (id, record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <ButtonPerssion code='systemmanage/servicesetting/bj'>
                <div className={styles.item} onClick={() => actionClickHandle(id, 'EDIT', record)}>编辑</div>
            </ButtonPerssion>
            {record.isInit == '0' && <ButtonPerssion code='systemmanage/servicesetting/sc'>
                <div className={styles.item} onClick={() => actionClickHandle(id, 'DISABLE', record)}>删除</div>
            </ButtonPerssion>}
        </div>
    );
};