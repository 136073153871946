import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const getListLiveData = function* () {
    yield takeEvery(T.GET_LIST_LIVEDATA, function* requestData(action) {
        try {
            let result = yield http('/show/live/listLiveBroadcasInfo', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_LIST_LIVEDATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getListLiveStatus = function* () {
    yield takeEvery(T.GET_LIST_LIVESTATUS, function* requestData(action) {
        try {
            let result = yield http('/enum/LiveStateEnum ', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_LIST_LIVESTATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getListLiveSelect = function* () {
    yield takeEvery(T.GET_LIST_LIVESELECT, function* requestData(action) {
        try {
            let result = yield http('/show/showClass/listLiveBoradcasClass', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_LIST_LIVESELECT, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


export const getListVideoData = function* () {
    yield takeEvery(T.GET_LIST_VIDEODATA, function* requestData(action) {
        try {
            let result = yield http('/show/feed/listFeedVideo', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_LIST_VIDEODATA, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getListVideoSelect = function* () {
    yield takeEvery(T.GET_LIST_VIDEOSELECT, function* requestData(action) {
        try {
            let result = yield http('/show/showClass/listShortVideoClass', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_LIST_VIDEOSELECT, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getListVideoUsername = function* () {
    yield takeEvery(T.GET_LIST_VIDEOUSERNAME, function* requestData(action) {
        try {
            let result = yield http('/user/account/listFeedAccount', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_LIST_VIDEOUSERNAME, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getListVideoProduct = function* () {
    yield takeEvery(T.GET_LIST_VIDEOPRODUCT, function* requestData(action) {
        try {
            let result = yield http('/goods/goods/listShowGoods', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_LIST_VIDEOPRODUCT, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getListVideoDetail = function* () {
    yield takeEvery(T.GET_LIST_VIDEODETAIL, function* requestData(action) {
        try {
            let result = yield http('/show/feed/getFeedVideo', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_LIST_VIDEODETAIL, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getListLiveHistory = function* () {
    yield takeEvery(T.GET_LIST_LIVEHISTORY, function* requestData(action) {
        try {
            let result = yield http('/show/live/listLivePlayBack', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_LIST_LIVEHISTORY, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getListLiveRecommend = function* () {
    yield takeEvery(T.GET_LIST_LIVERECOMMEND, function* requestData(action) {
        try {
            let result = yield http('/show/live/listLiveReco', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_LIST_LIVERECOMMEND, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getListLiveStar = function* () {
    yield takeEvery(T.GET_LIST_LIVESTAR, function* requestData(action) {
        try {
            let result = yield http('/show/feed/starLightListInfo', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_LIST_LIVESTAR, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getListLiveSell = function* () {
    yield takeEvery(T.GET_LIST_LIVESELL, function* requestData(action) {
        try {
            let result = yield http('/show/feed/queryLiveIncomeLogs', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_LIST_LIVESELL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getListVCRecommend = function* () {
    yield takeEvery(T.GET_LIST_VCRECOMMEND, function* requestData(action) {
        try {
            let result = yield http('/show/feed/listFeedReco', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_LIST_VCRECOMMEND, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 社区列表 Saga  getcommunityTableSource
 */

export const communityTableSource = function* () {//获取'秀场管理 -道具管理- 社区列表
    yield takeEvery(T.GET_TABLE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/show/feed/listFeedImageText', action.payload, 'POST');
            yield put({ type: T.SET_TABLE_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 社区分类下拉 Saga  
 */

export const communitySelectSource = function* () {//获取'秀场管理 -道具管理- 社区分类下拉
    yield takeEvery(T.GET_COMSTATE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/show/showClass/listFeedClass', action.payload, 'POST');
            yield put({ type: T.SET_COMSTATE_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/** 
 * 首页与频道公用分类下拉 Saga  
 */

export const communitySelectRecoSource = function* () {//获取'秀场管理 -道具管理- 首页与频道公用分类下拉
    yield takeEvery(T.GET_COMRECO_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/enum/FlagEnum', action.payload, 'POST');
            yield put({ type: T.SET_COMRECO_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


/**
 * 社区分类查看详情 Saga  
 */

export const communityDetailSource = function* () {//获取'秀场管理 -道具管理- 社区分类查看详情
    yield takeEvery(T.GET_COMDETAIL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/show/feed/getFeedImageText', action.payload, 'POST');
            yield put({ type: T.SET_COMDETAIL_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 视频分类查看详情 Saga  
 */

export const videoDetailSource = function* () {//获取'秀场管理 -道具管理- 视频查看详情
    yield takeEvery(T.GET_VIDEODETAIL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/show/feed/getFeedVideo', action.payload, 'POST');
            yield put({ type: T.SET_VIDEODETAIL_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//主播类型枚举
export const getAnchorTypeEnum = function* () {
    yield takeEvery(T.GET_ANCHOR_TYPE_ENUM, function* requestData(action) {
        try {
            let result = yield http('/enum/AnchorTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_ANCHOR_TYPE_ENUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};