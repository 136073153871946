import * as T from './actiontypes';
import { autopageSize } from '@/assets/config';

/**
 * 获取服务商审核状态
 */
export const getServiceState = (state = [], action) => {
    switch (action.type) {
        case T.SET_SERVICE_STATE:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取区域公司下拉列
 */
export const getAreaCompany = (state = [], action) => {
    switch (action.type) {
        case T.SET_AREA_COMPANY:
            return action.payload.result.result;
        default:
            return state;
    }
};

/**
 * 获取区域公司下拉列
 */
export const getAssoTypeList = (state = [], action) => {
    switch (action.type) {
        case T.SET_ASSOTYPE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取服务商列表
 */
const serviceListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: autopageSize,
    resultTotal: 0
};

export const getServiceList = (state = serviceListInital, action) => {
    switch (action.type) {
        case T.SET_SERVICE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取服务商详情
 */
export const getServiceDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_SERVICE_DETAIL:
            return action.payload.result;
        case T.CLEAN_SERVICE_DETAIL:
            return {};
        default:
            return state;
    }
};

/**
 * 获取推荐会员列表
 */
const saccountList = {
    dataList: [],
    pageNum: 1,
    pageSize: autopageSize,
    resultTotal: 0
};

export const getSAccountList = (state = saccountList, action) => {
    switch (action.type) {
        case T.SET_SACCOUNT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取心选推荐列表
 */
const srecommendList = {
    dataList: [],
    pageNum: 1,
    pageSize: autopageSize,
    resultTotal: 0
};

export const getSRecommendList = (state = srecommendList, action) => {
    switch (action.type) {
        case T.SET_SRECOMMEND_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取商户推荐列表
 */
const smerchantList = {
    dataList: [],
    pageNum: 1,
    pageSize: autopageSize,
    resultTotal: 0
};

export const getSMerchantList = (state = smerchantList, action) => {
    switch (action.type) {
        case T.SET_SMERCHANT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取分销商品列表
 */
const goodsList = {
    dataList: [],
    pageNum: 1,
    pageSize: autopageSize,
    resultTotal: 0
};

export const getGoodsList = (state = goodsList, action) => {
    switch (action.type) {
        case T.SET_GOODS_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取分销商品列表
 */
const recommendedList = {
    dataList: [],
    pageNum: 1,
    pageSize: autopageSize,
    resultTotal: 0
};

export const getRecommendedList = (state = recommendedList, action) => {
    switch (action.type) {
        case T.SET_RECOMMENDED_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取服务商收益汇总
 */
export const getServiceIcomeTotal = (state = {}, action) => {
    switch (action.type) {
        case T.SET_SERVICE_ICOME_TOTAL:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取服务商收益明细列表
 */
const serviceIcomeTable = {
    dataList: [],
    pageNum: 1,
    pageSize: autopageSize,
    resultTotal: 0
};

export const getServiceIcomeTable = (state = serviceIcomeTable, action) => {
    switch (action.type) {
        case T.SET_SERVICE_ICOME_TABLE:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取收支明细中费用名称下拉列
 */
export const getCostNameSelect = (state = [], action) => {
    switch (action.type) {
        case T.SET_COST_NAME:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取能量值信息
 */
export const getJibeanInfo = (state = {}, action) => {
    switch (action.type) {
        case T.SET_JIBEAN_INFO:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取服务商收益明细列表
 */
const jibeanListTable = {
    dataList: [],
    pageNum: 1,
    pageSize: autopageSize,
    resultTotal: 0
};

export const getJibeanList = (state = jibeanListTable, action) => {
    switch (action.type) {
        case T.SET_JIBEAN_LIST:
            return action.payload.result;
        default:
            return state;
    }
};


/**
 * 获取贡献值信息
 */
export const getXiubeiInfo = (state = {}, action) => {
    switch (action.type) {
        case T.SET_XIUBEI_INFO:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取贡献值列表
 */
const xiubeiListTable = {
    dataList: [],
    pageNum: 1,
    pageSize: autopageSize,
    resultTotal: 0
};

export const getXiubeiList = (state = xiubeiListTable, action) => {
    switch (action.type) {
        case T.SET_XIUBEI_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取服务商推荐赠送贡献值信息列表
 */
export const getServiceXiubeiCodeList = (state = [], action) => {
    switch (action.type) {
        case T.SET_SERVICE_XIUBEI_CODELIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 推荐人来源枚举
 */
export const recommentTypeEnum = (state = [], action) => {
    switch (action.type) {
        case T.SET_RECOMMENT_TYPE_ENUM:
            return action.payload.result;
        default:
            return state;
    }
};
/**
 * 获取推荐小B列表
 */
const salersListTable = {
    dataList: [],
    pageNum: 1,
    pageSize: autopageSize,
    resultTotal: 0
};

/**
 * 账号状态枚举
 */
export const stateEnum = (state = [], action) => {
    switch (action.type) {
        case T.SET_STATE_ENUM:
            return action.payload.result;
        default:
            return state;
    }
};

export const getSalersList = (state = salersListTable, action) => {
    switch (action.type) {
        case T.SET_SALERS_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取特权值明细
 */
export const franchiseList = (state = salersListTable, action) => {
    switch (action.type) {
        case T.SET_FRANCHISE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};




