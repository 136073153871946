import React, { Component } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './ConversionTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { regFenToYuan, } from '@/util/money';

export default class RecordsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    _columns = () => [
        {
            title: '日期',
            dataIndex: 'activeDate',
            key: 'activeDate',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '兑换比率',
            dataIndex: 'exchangeRatioDesc',
            key: 'exchangeRatioDesc',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '手续费(%)',
            dataIndex: 'chargeRatioPercent',
            key: 'chargeRatioPercent',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '昨日销售环比(%)',
            dataIndex: 'cycleRatePercent',
            key: 'cycleRatePercent',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '昨日销售额(元)',
            dataIndex: 'yesterdaySaleValue',
            key: 'yesterdaySaleValue',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '前天销售额(元)',
            dataIndex: 'beforeYesterdaySaleValue',
            key: 'beforeYesterdaySaleValue',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '魅力值兑换规则',
            dataIndex: 'ruleName',
            key: 'ruleName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '发布时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    // 解析表格数据，使符合columns格式要求 
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                item.yesterdaySaleValue = regFenToYuan(item.yesterdaySaleAmount);
                item.beforeYesterdaySaleValue = regFenToYuan(item.beforeYesterdaySaleAmount);
                return item;
            });
        }
    }

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

RecordsTable.propTypes = {
    renderData: PropTypes.object, // 表格数据源
    paginationChange: PropTypes.func, // 分页
};