/**
 * 启用服务商弹框
 */
import React from 'react';
import { Modal, Table, Alert } from 'antd';
import PropTypes from 'prop-types';
import { message } from 'antd';
import http from '@/assets/api/http';
import './ConnectMemberModal.module.scss';

const takeColumns = () => ([
    {
        title: '服务商名称',
        dataIndex: 'realName',
        key: 'realName',
        width: '50%',
        align: 'center'
    },
    {
        title: '账号',
        dataIndex: 'mobilePhone',
        key: 'mobilePhone',
        width: '50%',
        align: 'center'
    }
]);

class ConnectMemberModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        dataList: [],
        connectSuccess: undefined
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    componentDidMount() {
        http('/service/provider/numberPercentageList', { serviceIdList: this.props.data.serviceIdList }).then((res) => {
            if (res.code === 200 && res.result instanceof Array) {
                if (res.result.length > 0) {
                    this.setState({ dataList: res.result, connectSuccess: false });
                } else {
                    this.setState({ dataList: res.result, connectSuccess: true });
                }
            }
        });
    }

    render() {
        const { visible, confirmLoading } = this.state;
        return (
            <Modal
                width={590}
                centered
                className='connect-member-modal'
                title='服务商关联提示'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                footer={null}
            >
                {
                    this.state.connectSuccess === undefined
                        ? '加载中...'
                        : this.state.connectSuccess
                            ? '已全部关联成功'
                            : <>
                                <Alert message={this.state.dataList.length + ' 个服务商关联会员账号失败，服务商未注册每日说APP会员账号'} type="warning" showIcon style={{ marginBottom: 10 }} />
                                <Table bordered columns={takeColumns()} dataSource={this.state.dataList} pagination={false} scroll={{ y: 400 }} />
                            </>

                }
            </Modal>
        );
    }
}

ConnectMemberModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    data: PropTypes.string, // 封禁或解封的ID
};

export default ConnectMemberModal;