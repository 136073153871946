// 合同列表
export const GET_CONTRACTLIST_TABLESOURCE = 'CONTRACTLIST/GET_CONTRACTLIST_TABLESOURCE';
export const SET_CONTRACTLIST_TABLESOURCE = 'CONTRACTLIST/SET_CONTRACTLIST_TABLESOURCE';

// 合同名下拉列表
export const GET_CONTRACTSTATES = 'CONTRACTLIST/GET_CONTRACTSTATES';
export const SET_CONTRACTSTATES = 'CONTRACTLIST/SET_CONTRACTSTATES';

// 合同状态下拉列表
export const GET_CONTRACTNAMES = 'CONTRACTLIST/GET_CONTRACTNAMES';
export const SET_CONTRACTNAMES = 'CONTRACTLIST/SET_CONTRACTNAMES';