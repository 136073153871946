/**
 * 审核管理 - 实名认证审核异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Audit from './actiontypes';

export const getAutonymList = function* (){
    yield takeEvery(Audit.GET_AUTONYM_LIST, function* requestData(action){
        try{
            let result = yield http('/verify/IDCard/listAuthIDCard', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Audit.SET_AUTONYM_LIST, payload: result.result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};


export const getAutonymDetail = function* (){
    yield takeEvery(Audit.GET_AUTONYM_DETAIL, function* requestData(action){
        try{
            let result = yield http('/verify/IDCard/getInfoAuthIDCard', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Audit.SET_AUTONYM_DETAIL, payload: result.result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};