/**
 * 充值规则创建与编辑
 */
import React from 'react';
import { Modal, Radio, Icon, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './RulesModal.module.scss';
import { XInput } from '@/components/xqxc_ui';
import { formValidFns } from '@/assets/js/utils';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;
class RulesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,

            id: this.props.data.id,
            showMsg: this.props.data.showMsg,
            state: this.props.data.state,
            starLightPrice: this.props.data.stardealPrice !== undefined ? (this.props.data.stardealPrice) : '',
            starLightNum: this.props.data.starLightNum
        };
    }

    _handleOk = () => {

        const { data } = this.props;
        const { showMsg, state, starLightPrice, starLightNum } = this.state;

        const method = data.id === undefined ? 'insert' : 'update';

        if (formValidFns({ showMsg, state, starLightPrice, starLightNum }, {
            showMsg: '请输入规则名称',
            starLightPrice: { validator: v => /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/.test(v), message: '请输入金额，只能为数字' },
            starLightNum: { validator: v => /^[1-9]\d*$/.test(v), message: '请输入魅力值，只能为数字' }
        })) {
            this.setState({ confirmLoading: true }, () => {
                http(`/show/starLightConfig/${method}`, {
                    showMsg, state, starLightPrice: (starLightPrice) * 100, starLightNum, id: this.props.data.id
                }, 'POST').then(() => {
                    data === undefined ? message.success('创建成功。') : message.success('更新成功。');
                    this.setState({
                        visible: false,
                        confirmLoading: false
                    });
                    this.props.closeMode(true);
                }).catch(() => {
                    message.error('请求错误');
                    this.setState({ confirmLoading: false });
                });
            });
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // Radio状态改变时触发
    _radioValueChangeHandle = (e) => {
        this.setState({ state: e.target.value });
    }

    render() {
        const { visible, confirmLoading, title, showMsg, state, starLightNum, starLightPrice } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '408px' }}
                        inputStyle={{ width: '340px' }}
                        label='规则名称'
                        placeholder='请输入规则名称'
                        value={showMsg}
                        bindThis={this}
                        bindName='showMsg'
                    />
                    <div className={styles.radio}>
                        <div className={styles.label}>状态</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={state}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <XInput
                        style={{ width: '408px', margin: '26px 0 0 26px' }}
                        inputStyle={{ width: '340px' }}
                        label='金额'
                        placeholder='输入金额'
                        value={starLightPrice}
                        bindThis={this}
                        bindName='starLightPrice'
                    />
                    <XInput
                        style={{ width: '408px', margin: '26px 0 0 26px' }}
                        inputStyle={{ width: '340px' }}
                        label='魅力值'
                        placeholder='输入魅力值，自动换算等额魅力值'
                        value={starLightNum}
                        bindThis={this}
                        bindName='starLightNum'
                    />
                </div>
            </Modal>
        );

    }
}

RulesModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
    modetext: PropTypes.string,//区分魅力值与人气值 分类编码
};

export default RulesModal;