import { createModel } from '@/store/tools';

const model = {
    namespace: 'graphicslist',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getNoticeState': T('getNoticeState'),
            'getTableList': T('getTableList'),
            'getStatisticsTableList': T('getStatisticsTableList'),
            'getCategoryList': T('getCategoryList'),
            'getDetail': T('getDetail'),
        },
        actions: {
            'getNoticeState': A('getNoticeState'), // 状态-启禁用
            'getTableList': A('getTableList'), // 分类列表数据
            'getStatisticsTableList': A('getStatisticsTableList'),
            'getCategoryList': A('getCategoryList'), // 分类列表数据
            'getDetail': A('getDetail'),
        },
        sagas: {
            'getNoticeState': S('getNoticeState', '/enum/StateEnum'),
            'getTableList': S('getTableList', '/show/platFeedInfo/listPlatFeed'),
            'getStatisticsTableList': S('getStatisticsTableList', '/show/platFeedInfo/listPlatFeedTotal'),
            'getCategoryList': S('getCategoryList', '/show/platFeedInfo/showClassList'),
            'getDetail': S('getDetail', '/show/platFeedInfo/platFeedInfo'),
        },
        reducers: {
            'noticeState': R('getNoticeState', []),
            'tableList': R('getTableList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'categoryList': R('getCategoryList', []),
            'statisticsTableList': R('getStatisticsTableList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'getDetail': R('getDetail', {}),
        }
    })
};
export default createModel(model);