/**
 * 审核管理页
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import TableMain from './components/TableMain';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getAutonymList } from '@/reducers/auditmanage/autonym/actions';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';
import model from '@/reducers/auditmanage/autonym/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tel: '',
            nickname: '',
            typeItem: {},
        };
    }

    componentDidMount() {
        const { getApproveTypeEnum } = this.props;
        getApproveTypeEnum();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { tel, nickname, typeItem, pageNum, pageSize } = this.state;
        this.searchCache = {
            tel, nickname, auditType: typeItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAutonymList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ tel: '', nickname: '', typeItem: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 路由跳转
    _updateContent = (title, url, id) => {
        const { addOneBreadcrumbPath, history } = this.props;
        addOneBreadcrumbPath({
            title,
            path: `/home/auditmanage/nameauthentication${url}`
        });
        history.push({
            pathname: `/home/auditmanage/nameauthentication${url}${id}/${1}`
        });
    }

    //表格操作
    _tableAction = (id) => {
        const { addOneBreadcrumbPath, history } = this.props;
        addOneBreadcrumbPath({
            title: '认证详情',
            path: '/home/auditmanage/nameauthentication/detail/'
        });
        history.push({
            pathname: `/home/auditmanage/nameauthentication/detail/${id}`
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    //认证状态搜索条件已删除
    render() {
        const { tel, nickname, typeItem } = this.state;
        const { autonymData, approveTypeEnum, history } = this.props;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='手机号码'
                                placeholder='请输入手机号码'
                                bindThis={this}
                                bindName='tel'
                                value={tel}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='会员昵称'
                                placeholder='请输入会员昵称'
                                bindThis={this}
                                bindName='nickname'
                                value={nickname}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='认证类型'
                                placeholder='请选择认证类型'
                                renderData={approveTypeEnum}
                                bindThis={this}
                                bindName='typeItem'
                                dataIndex='value'
                                keyIndex='code'
                                value={typeItem.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableMain
                        paginationChange={this._paginationChange}
                        history={history}
                        renderData={autonymData}
                        tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    getAutonymList: PropTypes.func,
    autonymData: PropTypes.object,// 页面所有数据
    getApproveTypeEnum: PropTypes.func,
    approveTypeEnum: PropTypes.array
};

const mapStateToProps = (state) => ({
    autonymData: state.autonym.autonymList,
    approveTypeEnum: state.autonym.approveTypeEnum
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, getAutonymList, ...model.actions })(Main);