import * as MESSAGE from './actionTypes';

//获取消息中心未读列表
export const getMessageCenterUnReadList = (param) => ({
    type: MESSAGE.GET_MESSAGECENTERUNREAD_LIST,
    payload: param
});
//获取消息中心已读列表
export const getMessageCenterReadList = (param) => ({
    type: MESSAGE.GET_MESSAGECENTERREAD_LIST,
    payload: param
});