import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import KeepAlive from '@/routes/KeepAlive';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { message, Table } from 'antd';
import model from '@/reducers/businessmanage/classlist/model';
import CEModal from './CEModal';

const { getTableData, getClassEnum } = model.actions;

const ClassList = ({
    getTableData, tableData: { dataList = [], pageNum = 1, pageSize = 30, resultTotal = 0 } = {},
    getClassEnum, classEnum = []
}) => {

    const [openModal, setOpenModal] = useState(false);
    const [modalQuery, setModalQuery] = useState({});
    const [className, setClassName] = useState();
    const [classItem, setClassItem] = useState({});
    const [cpageNum, setCpageNum] = useState(autoPageNum);
    const [cpageSize, setCpageSize] = useState(autopageSize);

    useEffect(() => { getClassEnum(); }, []);

    const onSearch = (params = {}) => {
        getTableData({ name: className, type: classItem.code, pageNum: cpageNum, pageSize: cpageSize, ...params });
    };

    const onReset = () => { setClassName(); setClassItem({}); };

    const operateFunc = (type, params) => {
        const functions = {
            MODAL: p => { setOpenModal(true); setModalQuery(p); },
            DELETE: p => {
                showConfirm('删除类目', `类目：${params.type}-${params.name}; 确认删除该类目吗？`, () => {
                    http(`/merchant/industryCategory/remove/${params.id}`, {}, 'POST')
                        .then(res => { message.success(res.message); onSearch({}); });
                }, () => { });
            },
        };
        return functions[type](params);
    };

    const operateCell = (t, r) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => operateFunc('MODAL', r)}>编辑</div>
            {/* <div className={styles.item} onClick={() => operateFunc('DELETE', r)}>删除</div> */}
        </div>;
    };

    const columns = [
        { title: '类目分类', dataIndex: 'type.value', key: 'type.value', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '类目名称', dataIndex: 'name', key: 'name', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '创建时间', dataIndex: 'gmtCreate', key: 'gmtCreate', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '更新时间', dataIndex: 'gmtModified', key: 'gmtModified', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '操作', dataIndex: 'id', key: 'id', align: 'center', onCell: tooltipStyle, render: operateCell },
    ];

    const onInitSearch = ({ className, classItem = {} } = {}) => {
        setClassName(className); setClassItem(classItem); getTableData({ className, class: classItem.code });
    };

    const closeModal = (update) => {
        setOpenModal(false);
        if (update) onSearch({});
    };

    return <KeepAlive render={onInitSearch}>
        <div className={styles.flexBoxContainer}>
            <SearchBox>
                <SearchItem>
                    <XInput label='类目名称' placeholder='请输入类目名称' value={className} bindThis={setClassName} bindName='className' />
                </SearchItem>
                <SearchItem>
                    <XSelect label='类目分类' showSearch placeholder='请选择类目分类' renderData={classEnum} dataIndex='value'
                        keyIndex='code' value={classItem.value} bindThis={setClassItem} bindName='classItem' />
                </SearchItem>
                <SearchItem>
                    <XOKButton label='查询' onClick={() => onSearch({})} />
                    <XCancelButton label='重置' onClick={onReset} />
                </SearchItem>
            </SearchBox>
            {/* <div className={styles.operateBox}>
                <XOKButton label='新建类目' style={{ width: '100px' }} onClick={() => operateFunc('MODAL')} />
            </div> */}
            <Table rowClassName={(r, i) => i % 2 === 0 ? '' : styles.tdcolor} rowKey='id'
                columns={columns} dataSource={dataList} pagination={false} scroll={{ y: true }} />
            <XPagination resultTotal={resultTotal} pageSize={pageSize} pageNum={pageNum}
                onChange={(pageSize, pageNum) => { setCpageNum(pageNum); setCpageSize(pageSize); onSearch({ pageNum, pageSize }); }} />
            <CEModal closeModal={closeModal} data={{ ...modalQuery, visible: openModal, classEnum }} />
        </div>
    </KeepAlive>;
};
ClassList.propTypes = {
    getTableData: PropTypes.func, tableData: PropTypes.object,
    getClassEnum: PropTypes.func, classEnum: PropTypes.array
};
export default connect(state => ({
    tableData: state.businessclasslist.tableData,
    classEnum: state.businessclasslist.classEnum
}), { getTableData, getClassEnum })(ClassList);