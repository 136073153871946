import React from 'react';
import styles from './ServiceTable.module.scss';
import { XPagination, XTableSelectionCount, XTableHOC } from '@/components/xqxc_ui';
import { Icon, Table, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, tooltipClick, cellWidth } from '@/components/TableCell';
import { isPlat } from '@/assets/js/authType';
import ButtonPerssion from '@/routes/ButtonPerssion';

class ServiceTable extends React.Component {

    state = {
        selectedRowKeys: []
    };

    static getDerivedStateFromProps(props, state) {
        if (props.selectedRowKeys !== state.selectedRowKeys) {
            return {
                selectedRowKeys: props.selectedRowKeys
            };
        }
        return null;
    }

    componentDidMount() {
        if (!isPlat()) {
            document.addEventListener('copy', this._copyEvent);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('copy', this._copyEvent);
    }

    _copyEvent = (e) => {
        e.preventDefault();
        e.stopPropagation();
        alert('本页面禁止复制操作！');
    }

    _takeColumns = () => {
        let cols = [
            {
                title: '服务商账号',
                dataIndex: 'mobilePhone',
                key: 'mobilePhone',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: (text, item) => tooltipClick(text, () => {
                    this.props.tableAction(item.id, 'info', item.providerRole.code);
                })
            },
            {
                title: '服务商姓名',
                dataIndex: 'realName',
                key: 'realName',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '推荐人账号',
                dataIndex: 'refereesAccount',
                key: 'refereesAccount',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '推荐人',
                dataIndex: 'referees',
                key: 'referees',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '会员关联',
                dataIndex: 'assoType',
                key: 'assoType',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: (text) => tooltip(text == 1 ? '已关联' : '未关联')
            },
            {
                title: '所属区域公司',
                dataIndex: 'areaCompanyName',
                key: 'areaCompanyName',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '所属运营中心',
                dataIndex: 'operationsName',
                key: 'operationsName',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },

            {
                title: '账号类型',
                dataIndex: 'providerTypeValue',
                key: 'providerTypeValue',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '等级',
                dataIndex: 'levelName',
                key: 'levelName',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '能量值',
                dataIndex: 'beansSummary',
                key: 'beansSummary',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '贡献值',
                dataIndex: 'shellSummary',
                key: 'shellSummary',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '会员数量',
                dataIndex: 'accountNum',
                key: 'accountNum',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '心选推荐',
                dataIndex: 'recommendedNum',
                key: 'recommendedNum',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '推荐商户',
                dataIndex: 'merchantNum',
                key: 'merchantNum',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '是否注销',
                dataIndex: 'isDestory.value',
                key: 'isDestory.value',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '推荐小B',
                dataIndex: 'salesManNum',
                key: 'salesManNum',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: (text, item) => tooltipClick(text, () => {
                    this.props.tableAction(item.id, 'suggest');
                })
            },
            {
                title: '账号状态',
                dataIndex: 'providerState',
                key: 'providerState',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: text => tooltip(text ? text.value : '')
            },
            {
                title: '更新时间',
                dataIndex: 'gmtModified',
                key: 'gmtModified',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '服务商类型',
                dataIndex: 'providerRole.value',
                key: 'serviceType.value',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: <soan>是否开通权益
                    <Tooltip placement="top" title={`
                    此处的权益是指：开通【每日抢拍】小程序 - 每日补贴活动，服务商获得的补贴权益
                    开通后，该服务商才能获得服务商补贴。
                    未开通，服务商不能获得服务商补贴
                    `}>
                        <Icon type="question-circle" style={{ cursor: 'pointer', fontSize: '16px', marginLeft: '5px' }} />
                    </Tooltip></soan>,
                dataIndex: 'isOpenRights.value', key: 'isOpenRights.value',
                width: cellWidth.normal, align: 'center', onCell: tooltipStyle, render: tooltip
            }
        ];
        if (this.props.isOffLine) {
            cols.splice(8, 0, {
                title: '所在地区',
                dataIndex: 'inTheArea',
                key: 'inTheArea',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            });
        }

        if (this.props.tabKey === 'a') {
            cols.splice(7, 0, {
                title: '可使用特权值',
                dataIndex: 'availableIntegral',
                key: 'availableIntegral',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            });
        }

        if (isPlat()) {
            cols.push({
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: cellWidth.big,
                fixed: 'right',
                render: (text, item) => this._renderActionCell(item.id, item)
            });
        }

        return cols;
    };

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList.map((item) => {
                item.auditTypeValue = item.auditType.value;
                item.providerTypeValue = item.providerType.value;
                return item;
            });
        } else {
            return [];
        }
    }

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        let prohibitEle = null;
        if (item.providerState && item.providerState.code == 0) {
            prohibitEle = <div className={styles.item} onClick={() => tableAction(key, 'able')}>启用</div>;
        } else if (item.providerState && item.providerState.code == 1) {
            prohibitEle = <div className={styles.item} onClick={() => tableAction(key, 'unable')}>禁用</div>;
        } else {
            prohibitEle = null;
        }
        const { isOpenRights = {}, id, mobilePhone } = item;
        return (
            <div className={styles.action}>
                {/* <ButtonPerssion code='usermanage/service/ckxq'>
                    <div className={styles.item} onClick={() => tableAction(key, 'info')}>查看详情</div>
                </ButtonPerssion> */}
                <ButtonPerssion code='usermanage/service/bj'>
                    <div className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>
                </ButtonPerssion>
                <ButtonPerssion code='usermanage/service/qyjy'>
                    {prohibitEle}
                </ButtonPerssion>
                <ButtonPerssion code='usermanage/service/szmx'>
                    <div className={styles.item} onClick={() => tableAction(key, 'randp')}>收支明细</div>
                </ButtonPerssion>
                <ButtonPerssion code='usermanage/service/tjmx'>
                    <div className={styles.item} onClick={() => tableAction(key, 'suggest')}>推荐明细</div>
                </ButtonPerssion>
                <div className={styles.item} onClick={() => tableAction(key, 'isOpenRights', {
                    requrl: isOpenRights.code == '1' ? 'delOpenRights' : 'addOpenRights',
                    subjects: isOpenRights.code != '1' ? [mobilePhone] : undefined,
                    tip: isOpenRights.code == '1' ? '关闭' : '开通',
                    subjectIds: isOpenRights.code == '1' ? [id] : undefined,
                    subjectSystem: 'SERVICE_RIGHTS', isSendSms: 1
                })}>{isOpenRights.code == '1' ? '关闭权益' : '开通权益'}</div>
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys });
                this.props.selectedRowKeysChange &&
                    this.props.selectedRowKeysChange(selectedRowKeys, selectedRows);
            }
        };
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] }, () => {
            this.props.selectedRowKeysChange([]);
        });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <XTableSelectionCount selectedNum={this.state.selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    rowSelection={this._rowSelection()}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    currentPageNum={this.props.currentPageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

ServiceTable.propTypes = {
    isOffLine: PropTypes.bool,
    selectedRowKeys: PropTypes.array,
    selectedRowKeysChange: PropTypes.func,
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string,
    currentPageNum: PropTypes.number,
    tabKey: PropTypes.string
};

export default XTableHOC(ServiceTable, 40);