/**
 * [会员渠道等级设置]
 * @author: Fu Xiaochun
 * @date: 2023-03-06 
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Table, message } from 'antd';
import { tooltipStyle, tooltip, tooltipClick, priceFenToYuanCell } from '@/components/TableCell';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { useHistory } from 'react-router-dom';

function LevelSetting(props){

    const history = useHistory();
    const [result, setResult] = useState([]);

    useEffect(()=>{
        props.keepSecondNavBreadcrumb();
        getLevels();
    }, []);

    const getLevels = ()=>{
        http('/user/supplierAccountLevel/listSupplierAccountLevel').then(res=>{
            setResult(res.result);
        }).catch(err=>{
            message.error(err.message);
        });
    };

    // 表格操作
    const tableActionCell = (item, actionClickHandle) => {
        return (
            <div className={styles.tableActionCell}>
                <div className={styles.item} onClick={() => actionClickHandle(item.id, 'edit', item)}>编辑</div>
            </div>
        );
    };

    const renderIcon = (key, item)=>{
        return <img src={item.levelImg} style={{width: '60px', border: 'none'}} />;
    };

    const columns = (TableAction, actionClickHandle) => [
        {
            title: '会员等级',
            dataIndex: 'id',
            key: 'id',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '身份称谓',
            dataIndex: 'levelName',
            key: 'levelName',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员等级图标',
            dataIndex: 'levelImg',
            key: 'levelImg',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: renderIcon
        },
        {
            title: '会员权益',
            dataIndex: 'accountRights',
            key: 'accountRights',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            key: 'action',
            align: 'left',
            width: '120px',
            fixed: 'right',
            render: (id, item) => tableActionCell(item, actionClickHandle)
        }
    ];

    const _tableAction = (val, type, data) => {
        switch (type) {
            case 'edit':
                props.addOneBreadcrumbPath({
                    title: '会员等级编辑',
                    path: '/home/channelmanage/levelsetting/edit/'+val
                });
                history.push('/home/channelmanage/levelsetting/edit/'+val);
                break;
            default:
            // nothing to do
        }
    };

    return <>
        <div className={styles.flexBoxContainer}>
            <h2>会员等级设置</h2>
            <div className={styles.tableContainer}>
                <Table
                    rowKey='id'
                    columns={columns(tableActionCell, _tableAction)}
                    pagination={false}
                    dataSource={result}
                    scroll={{ y: true }}
                />
            </div>
        </div>
    </>;
}

LevelSetting.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, 
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb})(LevelSetting);