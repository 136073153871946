/**
 * 【菜鸟物流】
 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XSelect, XOKButton, XCancelButton, XPagination, XScrollSelect } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import model from '@/reducers/systemmanage/logistics/model';

const { getCainiaoTableData, getWareHouseSele } = model.actions;

const CaiNiaoPage = ({
    getCainiaoTableData,
    cainiaoTableData: {
        dataList = [],
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0
    } = {},
    getWareHouseSele,
    wareHouseSele = [],
}) => {

    const [merchantItem, setMerchantItem] = useState({});
    const [warehouseItem, setWarehouseItem] = useState({});
    const [currentNum, setCurrentNum] = useState(1);
    const [currentSiza, setCurrentSiza] = useState(30);

    const searchList = (params = {}) => {
        getCainiaoTableData({ merchantName: merchantItem.merchantName, warehouseId: warehouseItem.code, pageNum: currentNum, pageSize: currentSiza, ...params });
    };

    const resetList = () => {
        setMerchantItem({});
        setWarehouseItem({});
    };

    const columns = [
        {
            title: '仓库名称',
            dataIndex: 'warehouseName',
            key: 'warehouseName',
            width: '25%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            width: '25%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '仓库状态',
            dataIndex: 'status.value',
            key: 'status.code',
            width: '25%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '开通时间',
            dataIndex: 'gmtAuth',
            key: 'gmtAuth',
            width: '25%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    useEffect(() => {
        getWareHouseSele();
    }, []);

    useEffect(() => {
        searchList({ pageNum: currentNum, pageSize: currentSiza });
    }, [currentNum, currentSiza]);

    const pageChange = (ps, pn) => {
        setCurrentNum(pn);
        setCurrentSiza(ps);
    };

    return <div className={styles.flexBoxContainer}>
        <SearchBox>
            <SearchItem>

                <div style={{ marginRight: 20 }}>
                    <span>商户名称</span>
                    <XScrollSelect
                        style={{ width: '200px', marginLeft: '10px' }}
                        url='/merchant/info/listMerchantIdAndNamePage'
                        placeholder='请选择仓库名称'
                        showSearch
                        mainKey='merchantName'
                        selectID='merchantId'
                        selectName='merchantName'
                        value={merchantItem}
                        listLabel='dataList'
                        isOptionBindID={true}
                        onChange={(value, item) => setMerchantItem(item.props['data-option'])}
                    />
                </div>
            </SearchItem>
            <SearchItem>
                <XSelect
                    label='仓库名称'
                    placeholder='请选择仓库名称'
                    renderData={wareHouseSele}
                    dataIndex='value'
                    keyIndex='code'
                    value={warehouseItem.value}
                    showSearch={true}
                    bindThis={setWarehouseItem}
                    bindName='warehouseItem'
                />
            </SearchItem>
            <SearchItem>
                <XOKButton
                    label='查询'
                    onClick={() => searchList()}
                />
                <XCancelButton
                    label='重置'
                    onClick={resetList}
                />
            </SearchItem>
        </SearchBox>
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(r, i) => i % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(r, i) => i}
                columns={columns}
                dataSource={dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={pageChange}
            />
        </div>
    </div>;

};
CaiNiaoPage.propTypes = {
    getCainiaoTableData: PropTypes.func,
    cainiaoTableData: PropTypes.object,
    getWareHouseSele: PropTypes.func,
    wareHouseSele: PropTypes.array,
};
export default connect(state => ({
    cainiaoTableData: state.logistics.cainiaoTableData,
    wareHouseSele: state.logistics.wareHouseSele,
}), {
    getCainiaoTableData,
    getWareHouseSele
})(CaiNiaoPage);