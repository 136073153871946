import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, tooltipClick } from '@/components/TableCell';

class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '商品类别',
            dataIndex: 'categoryName',
            key: 'categoryName',
            width: 120,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: 120,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '分销自购销售成交情况',
            children: [
                {
                    title: '订单数',
                    dataIndex: 'buyOrderCount',
                    key: 'buyOrderCount',
                    width: 120,
                    align: 'center',
                    onCell: tooltipStyle,
                    render: tooltip
                },
                {
                    title: '商品销量',
                    dataIndex: 'buyGoodsCount',
                    key: 'buyGoodsCount',
                    width: 120,
                    align: 'center',
                    onCell: tooltipStyle,
                    render: tooltip
                },
                {
                    title: '订单金额(元)',
                    dataIndex: 'buyTotalOrderPayFee',
                    key: 'buyTotalOrderPayFee',
                    width: 120,
                    align: 'center',
                    render: tooltip
                },
                {
                    title: '客单价(元)',
                    dataIndex: 'buyTotalOrderPayFeeUnitPrice',
                    key: 'buyTotalOrderPayFeeUnitPrice',
                    width: 120,
                    align: 'center',
                    onCell: tooltipStyle,
                    render: tooltip
                },
                {
                    title: '参与服务商数',
                    dataIndex: 'buyServiceProviderCount',
                    key: 'buyServiceProviderCount',
                    width: 120,
                    align: 'center',
                    onCell: tooltipStyle,
                    render: (text, record) => tooltipClick(text, () => {
                        this.props.tableAction(record.id, 'buyservice');
                    })
                },
                {
                    title: '参与区域公司',
                    dataIndex: 'buyAreaCompanyCount',
                    key: 'buyAreaCompanyCount',
                    width: 120,
                    align: 'center',
                    render: (text, record) => tooltipClick(text, () => {
                        this.props.tableAction(record.id, 'buycompany');
                    })
                },
                {
                    title: '参与运营中心',
                    dataIndex: 'buyCompanyCount',
                    key: 'buyCompanyCount',
                    width: 120,
                    align: 'center',
                    onCell: tooltipStyle,
                    render: (text, record) => tooltipClick(text, () => {
                        this.props.tableAction(record.id, 'buyoperation');
                    })
                },
            ]
        },
        {
            title: '分销分享销售成交情况',
            children: [
                {
                    title: '订单数',
                    dataIndex: 'shareOrderCount',
                    key: 'shareOrderCount',
                    width: 120,
                    align: 'center',
                    onCell: tooltipStyle,
                    render: tooltip
                },
                {
                    title: '商品销量',
                    dataIndex: 'shareGoodsCount',
                    key: 'shareGoodsCount',
                    width: 120,
                    align: 'center',
                    onCell: tooltipStyle,
                    render: tooltip
                },
                {
                    title: '订单金额(元)',
                    dataIndex: 'shareTotalOrderPayFee',
                    key: 'shareTotalOrderPayFee',
                    width: 120,
                    align: 'center',
                    render: tooltip
                },
                {
                    title: '客单价(元)',
                    dataIndex: 'shareTotalOrderPayFeeUnitPrice',
                    key: 'shareTotalOrderPayFeeUnitPrice',
                    width: 120,
                    align: 'center',
                    onCell: tooltipStyle,
                    render: tooltip
                },
                {
                    title: '参与服务商数',
                    dataIndex: 'shareServiceProviderCount',
                    key: 'shareServiceProviderCount',
                    width: 120,
                    align: 'center',
                    onCell: tooltipStyle,
                    render: (text, record) => tooltipClick(text, () => {
                        this.props.tableAction(record.id, 'service');
                    })
                },
                {
                    title: '参与区域公司',
                    dataIndex: 'shareAreaCompanyCount',
                    key: 'shareAreaCompanyCount',
                    width: 120,
                    align: 'center',
                    render: (text, record) => tooltipClick(text, () => {
                        this.props.tableAction(record.id, 'company');
                    })
                },
                {
                    title: '参与运营中心',
                    dataIndex: 'shareCompanyCount',
                    key: 'shareCompanyCount',
                    width: 120,
                    align: 'center',
                    onCell: tooltipStyle,
                    render: (text, record) => tooltipClick(text, () => {
                        this.props.tableAction(record.id, 'operation');
                    })
                },
                {
                    title: '分销收益(元)',
                    dataIndex: 'shareTotalOrderIncome',
                    key: 'shareTotalOrderIncome',
                    width: 120,
                    align: 'center',
                    onCell: tooltipStyle,
                    render: tooltip
                },
                {
                    title: '客单收益(元)',
                    dataIndex: 'shareTotalOrderIncomeUnitPrice',
                    key: 'shareTotalOrderIncomeUnitPrice',
                    width: 120,
                    align: 'center',
                    onCell: tooltipStyle,
                    render: tooltip
                }
            ]
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList.map(item => {
                return item;
            });
        }
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 2160, y: true }}
                    bordered={true}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    tableAction: PropTypes.func, // 对表格的操作
};

export default TableContent;