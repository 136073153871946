/**
 * 抢拍记录
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import FightTable from './components/FightTable';
import { XInput, XDatePicker, XOKButton, XCancelButton, XSelect, XInputNum } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/marketingmanage/fightrecord/model';
import { SearchBox, SearchItem } from '@/components/Layout';
import { parse } from 'qs';
import { Statistic, Card, Row, Col, Icon, Modal } from 'antd';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { regFenToYuan } from '@/util/money';

const cardStyle = {
    color: 'rgba(0, 0, 0, 0.85)',
};

class FightRecord extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            telNumber: '',//手机号
            showTitle: '',//活动副标题
            orderId: '',//订单号
            rewardType: {},//抢拍状态
            startTime: null,//开始时间
            endTime: null,//结束时间
            titleName: undefined,//营销活动页面跳转带参(活动名称)
            goodsNo: '',//货号
            goodsName: '',//商品名称
            text1: '总销售额：选定条件下，抢拍活动中产生的已成功支付订单，在扣除优惠减免金额及优惠抵扣金额后，客户实际支付的金额，包含运费、税费（统计场景包含商品订单和批发订单）（按支付成功时间）不扣除售后。',//总销售额
            text2: '总成交量：选定条件下，活动中产生的已成功支付订单单数（按支付成功时间）不扣除售后。',//总成交量
            text3: '中拍人数：选定条件下，在活动中有过中拍行为的去重人数（按中拍成功时间）',//总中拍数
            text4: '支付人数：选定条件下，在活动中有过支付行为的去重人数（按支付成功时间）',//总支付人数
            text5: '客单价：选定条件下，平均每个客户支付的金额（总销售额/支付人数）',//客单价
        };
    }

    componentDidMount() {
        const { location, getState, keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        getState();//抢拍状态
        let { titleName } = parse(location.search.substr(1));
        if (titleName) {
            this.setState({
                showTitle: titleName,
                titleName
            });
        }
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, telNumber, showTitle, rewardType, startTime, endTime, orderId, goodsNo, goodsName } = this.state;
        this.searchCache = {
            userTel: telNumber,//手机号
            activitySubtitle: showTitle,//活动副标题
            orderId: orderId,//订单号
            state: rewardType.code,//抢拍状态
            goodsNo,//货号
            goodsName,//商品名称
            startTime: startTime != null ? startTime.format('YYYY-MM-DD HH:mm:ss') : null, //开始时间
            endTime: endTime != null ? endTime.format('YYYY-MM-DD HH:mm:ss') : null, // 结束时间
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getFightrecordList(this.searchCache);//列表
        this.props.getFightData(this.searchCache);//抢拍记录统计
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        const { titleName } = this.state;
        this.setState({ telNumber: '', orderId: '', showTitle: titleName === undefined ? '' : titleName, rewardType: {}, startTime: null, endTime: null, goodsNo: '', goodsName: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getFightrecordList(this.searchCache);//列表
        this.props.getFightData(this.searchCache);//抢拍记录统计
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    //info信息
    cardHandle = (text) => {
        Modal.info({
            title: '帮助提示',
            content: <div>{text}</div>,
        });
    }

    // 表格操作
    _tableAction = (type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        let { buyerId, orderId } = record;
        switch (type) {
            case 'detail'://订单详情
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: {
                        pathname: '/home/ordermanage/productorder/orderdetail',
                        search: `?buyerId=${buyerId ? buyerId : null}&&orderId=${orderId}`,
                    }
                });
                history.push({
                    pathname: '/home/ordermanage/productorder/orderdetail',
                    search: `?buyerId=${buyerId ? buyerId : null}&&orderId=${orderId}`,
                });
                break;
        }
    }

    render() {
        const { telNumber, showTitle, rewardType, startTime, endTime, text1, text2, text3, text4, text5, orderId, goodsNo, goodsName } = this.state;
        const { fightrecordList, stateList, fightData: { totalAmount, totalCount, totalWinners, totalPayUsers, userPrice } = {} } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.card}>
                        <Row gutter={{ xs: 16, sm: 16 }}>
                            <Col span={4}>
                                <Card>
                                    <Statistic
                                        title={<div className={styles.laycard}>
                                            <span>总销售额</span>
                                            <Icon
                                                style={{ fontSize: '24px', }}
                                                onClick={() => this.cardHandle(text1)}
                                                type="info-circle"
                                            />
                                        </div>}
                                        value={regFenToYuan(totalAmount)}
                                        precision={2}
                                        valueStyle={cardStyle}
                                    />
                                </Card>
                            </Col>
                            <Col span={4}>
                                <Card>
                                    <Statistic
                                        title={<div className={styles.laycard}>
                                            <span>总成交量</span>
                                            <Icon
                                                style={{ fontSize: '24px', }}
                                                onClick={() => this.cardHandle(text2)}
                                                type="info-circle"
                                            />
                                        </div>}
                                        value={totalCount}
                                        precision={0}
                                        valueStyle={cardStyle}
                                    />
                                </Card>
                            </Col>
                            <Col span={4}>
                                <Card>
                                    <Statistic
                                        title={<div className={styles.laycard}>
                                            <span>总中拍数</span>
                                            <Icon
                                                style={{ fontSize: '24px', }}
                                                onClick={() => this.cardHandle(text3)}
                                                type="info-circle"
                                            />
                                        </div>}
                                        value={totalWinners}
                                        precision={0}
                                        valueStyle={cardStyle}
                                    />
                                </Card>
                            </Col>
                            <Col span={4}>
                                <Card>
                                    <Statistic
                                        title={<div className={styles.laycard}>
                                            <span>总支付人数</span>
                                            <Icon
                                                style={{ fontSize: '24px', }}
                                                onClick={() => this.cardHandle(text4)}
                                                type="info-circle"
                                            />
                                        </div>}
                                        value={totalPayUsers}
                                        precision={0}
                                        valueStyle={cardStyle}
                                    />
                                </Card>
                            </Col>
                            <Col span={4}>
                                <Card>
                                    <Statistic
                                        title={<div className={styles.laycard}>
                                            <span>客单价</span>
                                            <Icon
                                                style={{ fontSize: '24px', }}
                                                onClick={() => this.cardHandle(text5)}
                                                type="info-circle"
                                            />
                                        </div>}
                                        value={regFenToYuan(userPrice)}
                                        precision={2}
                                        valueStyle={cardStyle}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='活动副标题'
                                placeholder='请输入'
                                value={showTitle}
                                bindThis={this}
                                bindName='showTitle'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='会员手机号'
                                placeholder='请输入'
                                value={telNumber}
                                bindThis={this}
                                bindName='telNumber'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInputNum
                                label='订单号'
                                placeholder='请输入订单编号'
                                value={orderId}
                                bindThis={this}
                                bindName='orderId'
                                min={0}
                                precision={0}
                                maxLength={16}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='抢拍状态'
                                placeholder='请选择'
                                renderData={stateList}
                                dataIndex='value'
                                keyIndex='code'
                                value={rewardType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='rewardType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='成团日期'
                                value={startTime}
                                bindThis={this}
                                bindName='startTime'
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                // label=''
                                value={endTime}
                                bindThis={this}
                                bindName='endTime'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='商品名称'
                                placeholder='请输入商品名称'
                                value={goodsName}
                                bindThis={this}
                                bindName='goodsName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='商品货号'
                                placeholder='请输入商品货号'
                                value={goodsNo}
                                bindThis={this}
                                bindName='goodsNo'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <FightTable renderData={fightrecordList} paginationChange={this._paginationChange} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}

FightRecord.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    location: PropTypes.object,// 路由location对象
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,

    fightrecordList: PropTypes.object,
    getFightrecordList: PropTypes.func,//表格数据(action)
    stateList: PropTypes.array,
    getState: PropTypes.func,//类型(action)

    fightData: PropTypes.object,
    getFightData: PropTypes.func,//抢拍记录统计(action)
};

const mapStateToProps = (state) => ({
    fightrecordList: state.fightrecord.fightrecordList,
    stateList: state.fightrecord.stateList,
    fightData: state.fightrecord.fightData,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(FightRecord);
