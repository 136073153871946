/**
 * 编辑商品名称 
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message, Modal } from 'antd';
import { XTextArea } from '@/components/xqxc_ui';

const EditName = ({
    closeMode,
    data: {
        id = '',
        goodsName = ''
    } = {}
}) => {

    const [loading, setLoading] = useState(false);
    const [goodsTitle, setGoodsTitle] = useState(goodsName);

    const onSave = () => {
        setLoading(true);
        http('/goods/goods/updateGoodsName', { goodsId: id, goodsName: goodsTitle }, 'POST')
            .then(() => {
                message.success('编辑商品标题操作成功');
                setLoading(false);
                closeMode(true);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    return <Modal
        width={360}
        centered
        title='编辑商品标题'
        visible={true}
        confirmLoading={loading}
        onOk={onSave}
        onCancel={() => closeMode()}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <XTextArea
            inputStyle={{ width: '220px' }}
            label='商品标题'
            placeholder='请输入商品标题'
            value={goodsTitle}
            onChange={value => setGoodsTitle(value)}
        />
    </Modal>;
};

EditName.propTypes = {
    data: PropTypes.number, // 弹框入参
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default EditName;