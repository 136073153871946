import { combineReducers } from 'redux';
import { advertStrategAdsList, advertStrategEdit, advertStrategDelete, advertStrategDetail, advertStrategStartAndStop, advertStrategList, advertStrategAdd } from './reducer';

export default combineReducers({
	advertStrategList,
	advertStrategStartAndStop,
	advertStrategDetail,
	advertStrategDelete,
	advertStrategAdd,
	advertStrategEdit,
	advertStrategAdsList
});