/**
 * 财务信息
 */
import React, { PureComponent } from 'react';
import styles from './CreateModal.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message, Modal, Radio } from 'antd';
import { connect } from 'react-redux';
import * as actions from '@/reducers/businessmanage/merchantlist/actions';
import {
    Form,
    Input
} from 'antd';

const FormItem = Form.Item;//表单校验

class CreateModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            defaultValue: {},
            isEdit: false,
            confirmLoading: false
        };
    }

    componentDidMount() {
        const { data } = this.props;
        if (data) {
            this.setState({ defaultValue: data, isEdit: true });
        }
    }

    _sendReq = (url, data, winText) => {
        http(url, data, 'POST').then(() => {
            message.success(winText);
            this.setState({
                visible: false,
                confirmLoading: false
            });
            this.props.closeMode(true);
            this.props.form.resetFields();//重置Form表单的内容
        }).catch((error) => {
            message.warn(error.message);
            this.setState({
                confirmLoading: false
            });
        });
    }

    _handleOk = () => {
        this.handleSubmit();
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
            confirmLoading: false
        });
        this.props.closeMode(false);
        this.props.form.resetFields();//重置Form表单的内容
    }

    handleSubmit = e => {//表单校验
        const { defaultValue, isEdit } = this.state;
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                console.log('提交', fieldsValue);
                const { danwei, naishuiren, zhucedizhi, zhucedianhua, kaihu, yinhang, faren, farenshouji, shenfenzheng, email, receivertel, shoupiaorentle, address, platDefault } = fieldsValue;
                if (!err) {
                    console.log('提交', fieldsValue);
                    if (isEdit) {
                        this.setState({ confirmLoading: true }, () => {
                            this._sendReq('/plat/financeAccount/update', {
                                financeCompany: danwei,
                                taxpayerNo: naishuiren,
                                registrationAddr: zhucedizhi,
                                registrationPhone: zhucedianhua,
                                bankName: kaihu,
                                bankAccount: yinhang,
                                legalName: faren,
                                legalTel: farenshouji,
                                legalIdentity: shenfenzheng,
                                contactsMail: email,
                                contactsName: receivertel,
                                contactsPhone: shoupiaorentle,
                                contactsAddr: address,
                                platDefault,
                                id: defaultValue.id
                            }, '编辑成功！');
                        });
                    } else {
                        this.setState({ confirmLoading: true }, () => {
                            this._sendReq('/plat/financeAccount/insert', {
                                financeCompany: danwei,
                                taxpayerNo: naishuiren,
                                registrationAddr: zhucedizhi,
                                registrationPhone: zhucedianhua,
                                bankName: kaihu,
                                bankAccount: yinhang,
                                legalName: faren,
                                legalTel: farenshouji,
                                legalIdentity: shenfenzheng,
                                contactsMail: email,
                                contactsName: receivertel,
                                contactsPhone: shoupiaorentle,
                                contactsAddr: address,
                                platDefault,
                            }, '创建成功！');
                        });
                    }
                }
            }
        });
    };

    //重置
    _isResetChange(value) {
        this.props.form.resetFields();
    }

    render() {
        const { visible, confirmLoading, title, defaultValue } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form onSubmit={this.handleSubmit} className="create-form">
                        <div className={styles.orderSecInfo} >
                            <div className={styles.items}>
                                <FormItem
                                    colon={false}
                                    htmlFor='danwei'
                                    label={<span>单位名称</span>}
                                >
                                    {getFieldDecorator('danwei', {
                                        rules: [{ required: true, message: '请输入单位名称', whitespace: true }],
                                        initialValue: defaultValue.financeCompany
                                    })(<Input placeholder='请输入单位名称' />)}
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    htmlFor='naishuiren'
                                    label={<span>纳税人识别码</span>}
                                >
                                    {getFieldDecorator('naishuiren', {
                                        rules: [{ required: true, message: '请输入纳税人识别码', whitespace: true }],
                                        initialValue: defaultValue.taxpayerNo
                                    })(<Input placeholder='请输入纳税人识别码' />)}
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    htmlFor='zhucedizhi'
                                    label={<span>注册地址</span>}
                                >
                                    {getFieldDecorator('zhucedizhi', {
                                        rules: [{ required: true, message: '请输入注册地址', whitespace: true }],
                                        initialValue: defaultValue.registrationAddr
                                    })(<Input placeholder='请输入注册地址' />)}
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    htmlFor='zhucedianhua'
                                    label={<span>注册电话</span>}
                                >
                                    {getFieldDecorator('zhucedianhua', {
                                        rules: [{ required: true, message: '请输入注册电话', whitespace: true },
                                        {
                                            // message: '联系电话格式错误',
                                            // pattern: /(^1[3|4|5|7|8]\d{9}$)|(^09\d{8}$)/,
                                        }],
                                        initialValue: defaultValue.registrationPhone
                                    })(<Input placeholder='请输入注册电话' />)}
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    htmlFor='kaihu'
                                    label={<span>开户银行</span>}
                                >
                                    {getFieldDecorator('kaihu', {
                                        rules: [{ required: true, message: '请输入开户银行', whitespace: true }],
                                        initialValue: defaultValue.bankName
                                    })(<Input placeholder='请输入开户银行' />)}
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    htmlFor='yinhang'
                                    label={<span>银行账号</span>}
                                >
                                    {getFieldDecorator('yinhang', {
                                        rules: [{ required: true, message: '请输入银行账号', whitespace: true }],
                                        initialValue: defaultValue.bankAccount
                                    })(<Input placeholder='请输入银行账号' />)}
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    htmlFor='faren'
                                    label={<span>法人姓名</span>}
                                >
                                    {getFieldDecorator('faren', {
                                        rules: [{ required: true, message: '请输入法人姓名', whitespace: true }],
                                        initialValue: defaultValue.legalName
                                    })(<Input placeholder='请输入法人姓名' />)}
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    htmlFor='farenshouji'
                                    label={<span>法人手机</span>}
                                >
                                    {getFieldDecorator('farenshouji', {
                                        rules: [{ required: true, message: '请输入法人手机', whitespace: true },
                                        {
                                            message: '联系电话格式错误',
                                            pattern: /(^1[3|4|5|7|8]\d{9}$)|(^09\d{8}$)/,
                                        }],
                                        initialValue: defaultValue.legalTel
                                    })(<Input placeholder='请输入法人手机' />)}
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    htmlFor='shenfenzheng'
                                    label={<span>法人身份证</span>}
                                >
                                    {getFieldDecorator('shenfenzheng', {
                                        rules: [{ required: true, message: '请输入法人身份证', whitespace: true }],
                                        initialValue: defaultValue.legalIdentity
                                    })(<Input placeholder='请输入法人身份证' />)}
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    htmlFor='email'
                                    label={<span>收件人邮箱地址</span>}
                                >
                                    {getFieldDecorator('email', {
                                        rules: [{ required: true, message: '请输入收件人邮箱地址', whitespace: true }],
                                        initialValue: defaultValue.contactsMail
                                    })(<Input placeholder='请输入收件人邮箱地址' />)}
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    htmlFor='receivertel'
                                    label={<span>收票人姓名</span>}
                                >
                                    {getFieldDecorator('receivertel', {
                                        rules: [{ required: true, message: '请输入收票人姓名', whitespace: true }],
                                        initialValue: defaultValue.contactsName
                                    })(<Input placeholder='请输入收票人姓名' />)}
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    htmlFor='shoupiaorentle'
                                    label={<span>收票人手机</span>}
                                >
                                    {getFieldDecorator('shoupiaorentle', {
                                        rules: [{ required: true, message: '请输入收票人手机', whitespace: true },
                                        {
                                            message: '联系电话格式错误',
                                            pattern: /(^1[3|4|5|7|8]\d{9}$)|(^09\d{8}$)/,
                                        }],
                                        initialValue: defaultValue.contactsPhone
                                    })(<Input placeholder='请输入收票人手机' />)}
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    htmlFor='address'
                                    label={<span>收件地址</span>}
                                >
                                    {getFieldDecorator('address', {
                                        rules: [{ required: true, message: '请输入收件地址', whitespace: true }],
                                        initialValue: defaultValue.contactsAddr
                                    })(<Input placeholder='请输入收件地址' />)}
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    htmlFor='platDefault'
                                    label={<span>是否默认</span>}
                                >
                                    {getFieldDecorator('platDefault', {
                                        rules: [{ required: true, message: '请选择是否默认', whitespace: true }],
                                        initialValue: defaultValue.isDefault
                                    })(
                                        <Radio.Group>
                                            <Radio value='1'>是</Radio>
                                            <Radio value='0'>否</Radio>
                                        </Radio.Group>
                                    )}
                                </FormItem>
                            </div>
                        </div>
                        {/* <div className={styles.bottom_line}></div>

                        <div className={styles.layout}>
                            <FormItem>
                                <Button style={{ marginRight: '50px' }} type="primary" htmlType="submit">立即提交</Button>
                            </FormItem>
                            <FormItem>
                                <Button style={{ marginRight: '50px' }} type="primary" onClick={() => this._isResetChange()}>重置</Button>
                            </FormItem>
                        </div> */}
                    </Form>
                </div>
            </Modal>
        );
    }
}

CreateModal.propTypes = {

    form: PropTypes.object.isRequired, // 校验
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string // 弹框标题
};

const mapStateToProps = (state) => {
    return {
        storeData: state.merchantlist.merchantDate.storeData, // 新建店铺下拉选项
    };

};

const ForgotCreateModal = Form.create({ name: 'create-form' })(CreateModal);// 校验

export default connect(mapStateToProps, actions)(ForgotCreateModal);
