import { createModel } from '@/store/tools';

const model = {
    namespace: 'graphicscategory',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getNoticeState': T('getNoticeState'),
            'getCategoryTableList': T('getCategoryTableList'),
        },
        actions: {
            'getNoticeState': A('getNoticeState'), // 状态-启禁用
            'getCategoryTableList': A('getCategoryTableList'), // 分类列表数据
        },
        sagas: {
            'getNoticeState': S('getNoticeState', '/enum/StateEnum'),
            'getCategoryTableList': S('getCategoryTableList', '/show/platFeedInfo/showClassList'),
        },
        reducers: {
            'noticeState': R('getNoticeState', []),
            'categoryTableList': R('getCategoryTableList', []),
        }
    })
};
export default createModel(model);