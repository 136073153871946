import * as T from './actiontypes';
const defaultState = {
    tableSource: [],//魅力值、人气值礼物分类列表数据源
    SelectData: [],// 魅力值、人气值礼物分类状态下拉选项
    paginations: {}, //魅力值、人气值礼物分类分页对象

    listTableSource: [],// 魅力值、人气值礼物列表数据源
    listPaginations: {}, // 魅力值、人气值礼物列表分页对象

    starsBuytableSource: [],//魅力值购买记录数据源
    startBuypaginations: {}, //魅力值购买记录分页对象

    starsvalueBuytableSource: [],//人气值购买记录数据源
    starsvaluepaginations: {}, //人气值购买记录分页对象
};


/**
 * 存储'礼物管理-'数据源
 * @param {*} state 
 * @param {*} action 
 */
export const presrntDate = (state = defaultState, action) => {
    let newTableSource, newSelectData, newSelectQuery, newPaginations, atmosbuy, newbuyPaginations;
    switch (action.type) {
        case T.SET_STARTGIFT_DATA: //获取'魅力值、人气值礼物分类列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };

        case T.SET_GIFTSTATE_DATA: //魅力值、人气值礼物分类列表态下拉
            newSelectData = action.payload.result;
            return { ...state, SelectData: newSelectData };

        case T.SET_STARTSGLIST_DATA: //星魅力值、人气值礼物列表
            newSelectQuery = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, listTableSource: newSelectQuery, listPaginations: newPaginations };
        case T.SET_STARTBUY_DATA: //存储'魅力值购买记录
            atmosbuy = action.payload.result.dataList;
            newbuyPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, starsBuytableSource: atmosbuy, startBuypaginations: newbuyPaginations };
        case T.SET_STARTVALUEBUY_DATA: //存储'人气值购买记录
            atmosbuy = action.payload.result.dataList;
            newbuyPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, starsvalueBuytableSource: atmosbuy, starsvaluepaginations: newbuyPaginations };
        default:
            return state;
    }
};
