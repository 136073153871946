import React from 'react';
import PropTypes from 'prop-types';
import { getLocalStorage } from '@/assets/js/storage';
import http from '@/assets/api/http';
import { message } from 'antd';

class ButtonPerssion extends React.Component {

    state = {
        hasPerssion: false,
        C: null
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.render) {
            this.setState({ C: props.render(this.state.hasPerssion) });
        }
    }

    componentDidMount() {
        let btnPremission = JSON.parse(getLocalStorage('btnPremission')) || [];
        if (btnPremission.length == 0) {
            http('/admin/menu/listMenus', {}, 'POST').then((response) => {
                if (response.status == 200) {
                    btnPremission = this._saveButtonPermission(response.result);
                    this._check(btnPremission);
                }
            }).catch((e) => {
                message.error(e.message);
            });
        } else {
            this._check(btnPremission);
        }
    }

    _check = (btnPremission) => {
        const { code } = this.props;
        let hasPerssion = false;
        for (let i = 0; i < btnPremission.length; i++) {
            if (btnPremission[i] == code) {
                hasPerssion = true;
                break;
            }
        }
        if (hasPerssion) {
            this.setState({ hasPerssion });
        }
        if (this.props.render) {
            this.setState({ C: this.props.render(hasPerssion) });
        }
    }

    _saveButtonPermission = (menuList) => {
        const premission = this._getButtonUrlForChildren(menuList);
        return premission;
    }

    _getButtonUrlForChildren = (treeData) => {
        let childrenEventKeys = [];
        for (let i = 0; i < treeData.length; i++) {
            if (treeData[i].menuType == '2' || treeData[i].menuType == '3') {
                childrenEventKeys.push(treeData[i].menuUrl);
            }
            if (treeData[i].childrens && treeData[i].childrens.length > 0) {
                const array = this._getButtonUrlForChildren(treeData[i].childrens);
                childrenEventKeys = Array.from(new Set([...childrenEventKeys, ...array]));
            }
        }
        return childrenEventKeys;
    }

    render() {
        const { hasPerssion, C } = this.state;
        return (
            <>
                {
                    C ? C :
                        <span style={hasPerssion ? null : { display: 'none' }}>
                            {this.props.children}
                        </span>
                }
            </>
        );
    }
}

ButtonPerssion.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
    code: PropTypes.string,
    render: PropTypes.func,
};

export default ButtonPerssion;