import * as Audit from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

const initDetail = {
    auditRecords: []
};

const initPayDetail = {
    paymentId: 0,
    businessNumber: 0,
    toPaymentAmount: 0
};

export const withdrawList = (state = initData, action) => {
    switch (action.type) {
        case Audit.SET_WITHDRAW_LIST:
            return action.payload;
        default:
            return state;
    }
};
export const withdrawDetail = (state = initDetail, action) => {
    switch (action.type) {
        case Audit.SET_WITHDRAW_DETAIL:
            return action.payload;
        default:
            return state;
    }
};
export const withdrawSele = (state = [], action) => {
    switch (action.type) {
        case Audit.SET_WITHDRAW_SELE:
            return action.payload;
        default:
            return state;
    }
};
export const payDetail = (state = initPayDetail, action) => {
    switch (action.type) {
        case Audit.SET_PAY_DETAIL:
            return action.payload;
        default:
            return state;
    }
};
export const serviceTypeEnum = (state = [], action) => {
    switch (action.type) {
        case Audit.SET_SERVICE_TYPE_ENUM:
            return action.payload;
        default:
            return state;
    }
};