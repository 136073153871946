import * as Operation from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 30,
    resultTotal: 0
};

const detailIntiData = {
    companyInfo: {
        companyState: {

        }
    },
    financialInfo: {

    },
    attachList: [],
    auditInfos: []
};

export const operationList = (state = initData, action) => {
    switch (action.type) {
        case Operation.SET_OPERATION_LISTDATA:
            return action.payload.result;
        default:
            return state;
    }
};

export const companyList = (state = initData, action) => {
    switch (action.type) {
        case Operation.SET_COMPANY_LISTDATA:
            return action.payload.result;
        default:
            return state;
    }
};

export const operationStatus = (state = [], action) => {
    switch (action.type) {
        case Operation.SET_OPERATION_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};

export const operationDetail = (state = detailIntiData, action) => {
    switch (action.type) {
        case Operation.SET_OPERATION_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

export const operationCenterSele = (state = [], action) => {
    switch (action.type) {
        case Operation.SET_OPERATIONCENTER_SELE:
            return action.payload.result;
        default:
            return state;
    }
};

export const settleUnitTypeSele = (state = [], action) => {
    switch (action.type) {
        case Operation.SET_SETTLEUNITTYPE_SELE:
            return action.payload.result;
        default:
            return state;
    }
};

export const settleUnitSele = (state = [], action) => {
    switch (action.type) {
        case Operation.SET_SETTLEUNIT_SELE:
            return action.payload.result;
        default:
            return state;
    }
};

export const operationProvince = (state = [], action) => {
    switch (action.type) {
        case Operation.SET_OPERATION_PROVINCE:
            return action.payload.result;
        default:
            return state;
    }
};

export const operationCity = (state = [], action) => {
    switch (action.type) {
        case Operation.SET_OPERATION_CITY:
            return action.payload.result;
        default:
            return state;
    }
};

export const operationArea = (state = [], action) => {
    switch (action.type) {
        case Operation.SET_OPERATION_AREA:
            return action.payload.result;
        default:
            return state;
    }
};

export const provinceSele = (state = [], action) => {
    switch (action.type) {
        case Operation.SET_PROVINCE_SELE:
            return action.payload.result;
        default:
            return state;
    }
};

export const citySele = (state = [], action) => {
    switch (action.type) {
        case Operation.SET_CITY_SELE:
            return action.payload.result;
        case Operation.RESET_CITY_SELE:
            return [];
        default:
            return state;
    }
};

export const typeEnum = (state = [], action) => {
    switch (action.type) {
        case Operation.SET_TYPE_ENUM:
            return action.payload.result;
        default:
            return state;
    }
};

