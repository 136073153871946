/**
 * 修改能量值
 */
import React from 'react';
import { Modal, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './UpdateJiDouModal.module.scss';
import { XInputNum, XSelect } from '@/components/xqxc_ui';
import { message } from 'antd';
import http from '@/assets/api/http';

const { TextArea } = Input;
const dataSource = [].concat([{ id: 'INCREASE', name: '增加' }, { id: 'DECREASE', name: '减少' }]);

class UpdateJiDouModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        selectItem: {}, // 增加、减少
        numberBean: 1, // 数量
        remark: '', // 系统消息 
        wordLength: 0, // 文本框字数
    }

    _request = (url, data) => {
        this.setState({ confirmLoading: true });
        http(url, data, 'POST').then((response) => {
            if (response.status == 200) {
                this.setState({ confirmLoading: false });
                message.success('修改成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode('clear');
            }
        }).catch((e) => {
            this.setState({ confirmLoading: false });
            message.error(e.message);
        });
    }

    _handleOk = () => {
        const { selectItem, numberBean, remark } = this.state;
        const data = {
            serviceIdList: this.props.data,
            increaseDecrease: selectItem.id,
            numberBean,
            remark,
        };
        console.log(data);
        if (!selectItem.id) {
            message.warn('请选择修改操作');
            return;
        }
        this._request('/service/level/increaseDecreaseBatch', data); // 增加
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _areaChangeHandle = (e) => {
        this.setState({ remark: e.target.value, wordLength: e.target.value.length });
    }

    render() {
        const { visible, confirmLoading, remark, selectItem, numberBean, wordLength } = this.state;

        return (
            <Modal
                width={466}
                centered
                title='修改能量值'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.up}>
                        <XSelect
                            style={{ width: '184px' }}
                            selectStyle={{ width: '100px' }}
                            label='修改能量值'
                            placeholder='请选择'
                            renderData={dataSource}
                            dataIndex='name'
                            keyIndex='id'
                            value={selectItem.name}
                            bindThis={this}
                            bindName='selectItem'
                        />
                        <XInputNum
                            style={{ width: '260px' }}
                            inputStyle={{ width: '204px' }}
                            label=''
                            placeholder='请输入能量值'
                            value={numberBean}
                            bindThis={this}
                            bindName='numberBean'
                            precision={0}
                            min={1}
                            max={99999}
                        />
                    </div>
                    <div className={styles.down}>
                        <div className={styles.downlabel}>系统消息</div>
                        <TextArea
                            className={styles.textArea}
                            rows={4}
                            onChange={this._areaChangeHandle}
                            value={remark}
                            maxLength={200}
                        />
                    </div>
                    <div style={{ float: 'right', marginRight: '22px' }}>字数：{wordLength}/200</div>
                </div>
            </Modal>
        );
    }
}

UpdateJiDouModal.propTypes = {
    curValue: PropTypes.number, //  当前能量值
    data: PropTypes.array.isRequired, // 需要修改魅力值的会员对象数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default UpdateJiDouModal;