/**
 * 审核详情弹框
 */
import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import styles from './DetailModal.module.scss';
import { XMediaFn } from '@/components/xqxc_ui';


class DetailModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        status: '',
    }
    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }


    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading } = this.state;
        const { audit } = this.props;
        return (
            <Modal
                width={470}
                centered
                title='主播封面审核详情'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.memberID}>
                        <span>会员账号：</span>
                        <span>{audit.accountName}</span>
                    </div>
                    <div className={styles.cover}>
                        <p className={styles.label}>提交封面：</p>
                        <XMediaFn dataSource={audit.coverPagePath} />
                    </div>
                    <div className={styles.status}>
                        <span>状&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;态：</span>
                        <span>{audit.state}</span>
                    </div>
                    <div className={styles.reason}>
                        <span>原&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;因：</span>
                        <span>{audit.remark}</span>
                    </div>
                </div>
            </Modal>
        );
    }
}

DetailModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    SinglePatch: PropTypes.bool, // 权限：单个修改还是批量修改，当为true时表示单个修改
    audit: PropTypes.object
};

export default DetailModal;