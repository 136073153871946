/**
 * 贡献值列表
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/marketingmanage/xiubei/model';
import ViewModal from './modal/ViewModal';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            xiubeiSubtitle: '', // 副标题
            state: {}, // 状态
        };
    }

    componentDidMount() {
        this.props.getXiuBeiState();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { xiubeiSubtitle, state, pageNum, pageSize } = this.state;
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
            xiubeiSubtitle,
            state: state.code,
        };
        this.props.getXiuBeiList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置
    _resetHandle = () => {
        this.setState({ xiubeiSubtitle: '', state: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格操作
    _tableAction = (id, type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '贡献值详情',
                    path: `/home/marketingmanage/xiubei/detail/${id}`,
                });
                history.push({
                    pathname: `/home/marketingmanage/xiubei/detail/${id}`,
                });
                break;
            case 'edit':
                addOneBreadcrumbPath({
                    title: '编辑贡献值',
                    path: `/home/marketingmanage/xiubei/edit/${id}`,
                });
                history.push({
                    pathname: `/home/marketingmanage/xiubei/edit/${id}`,
                });
                break;
            case 'view':
                this.setState({ currentOpenMode: 'view', openModeParam: record });
                break;
            case 'state':
                http('/boss/xiubei/updateXiubeiState', { id: record.id, state: id == 1 ? 0 : 1 }, 'POST').then(() => {
                    message.success('更新成功！');
                    this._searchHandle('useCache');
                }).catch((reject) => {
                    message.error(reject.message);
                });
                break;
        }
    }

    // 新建
    createBtn = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建贡献值',
            path: '/home/marketingmanage/xiubei/create'
        });
        history.push({
            pathname: '/home/marketingmanage/xiubei/create',
        });
    }
    // 打开弹窗  
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'view':
                return <ViewModal title='预览' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }
    // 关闭弹框
    _closeMode = (status, data) => {
        this.setState({ currentOpenMode: '' });
    }
    render() {
        const { xiubeiSubtitle, state } = this.state;
        const { xiubeiList, xiubeiState } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='贡献值副标题'
                                placeholder='请输入贡献值副标题'
                                value={xiubeiSubtitle}
                                bindThis={this}
                                bindName='xiubeiSubtitle'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={xiubeiState}
                                dataIndex='value'
                                keyIndex='code'
                                value={state.value}
                                bindThis={this}
                                bindName='state'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: 90 }} label='新建' onClick={this.createBtn} />
                    </div>
                    <TableContent
                        renderData={xiubeiList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    getXiuBeiState: PropTypes.func, // 获取状态
    xiubeiState: PropTypes.array, // 状态
    getXiuBeiList: PropTypes.func, // 获取贡献值列表
    xiubeiList: PropTypes.object, // 贡献值列表
};
const mapStateToProps = (state) => ({
    xiubeiState: state.xiubei.xiubeiState,
    xiubeiList: state.xiubei.xiubeiList,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, ...model.actions })(Main);