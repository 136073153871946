import * as T from './actiontypes';
const defaultState = {
    tableSource: [],// 用户/秀场举报列表
    paginations: {}, //用户/秀场举报列表分页对象

    tableSourceShop: [],// 商品举报列表
    paginationsShop: {}, //商品举报分页对象
};


/**
 * 存储'仓库商品结算价-'数据源
 * @param {*} state 
 * @param {*} action 
 */
export const reportReasonsListDate = (state = defaultState, action) => {
    let newTableSource, newPaginations;
    switch (action.type) {
        case T.SET_REPORTREASONSLIST_DATA: // 用户/秀场举报列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_REPORTSHOPLIST_DATA: // 商品举报列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSourceShop: newTableSource, paginationsShop: newPaginations };
        default:
            return state;
    }
};
