import * as Advert from './actiontypes';

/**
 * 获取'广告评论'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 */
export const getAdvertCommentList = ({adCommentName, adCommentState, pageSize, pageNum}) => {
    return ({
        type: Advert.GET_COMMENT_LIST,
        payload: {
            adCommentName, adCommentState, pageSize, pageNum
        }
    });
};
    
