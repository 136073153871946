import { createModel } from '@/store/tools';

const initTableData = { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 };

const model = {
    namespace: 'channelmanage',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getChannelList': T('getChannelList'),
            'getStateEnum': T('getStateEnum'),
            'getClassList': T('getClassList'),
            'getChannelBaseInfo': T('getChannelBaseInfo'),
            'getChannelBalance': T('getChannelBalance'),
            'getChannelAdvanceInfo': T('getChannelAdvanceInfo'),
            'getChannelCreditInfo': T('getChannelCreditInfo'),
        },
        actions: {
            'getChannelList': A('getChannelList'),
            'getStateEnum': A('getStateEnum'),
            'getClassList': A('getClassList'),
            'getChannelBaseInfo': A('getChannelBaseInfo'),
            'getChannelBalance': A('getChannelBalance'),
            'getChannelAdvanceInfo': A('getChannelAdvanceInfo'),
            'getChannelCreditInfo': A('getChannelCreditInfo'),
        },
        sagas: {
            'getChannelList': S('getChannelList', '/user/supplierAccountInfo/supplierAccountInfoList'),
            'getStateEnum': S('getStateEnum', '/enum/SupplierAccountStateEnum'),
            'getClassList': S('getClassList', '/supplier/config/category/dropDown'),
            'getChannelBaseInfo': S('getChannelBaseInfo', '/user/supplierAccountInfo/supplierAccountInfo', 'POST', 'id'),
            'getChannelBalance': S('getChannelBalance', '/supplier/ll/item/findPage'),
            'getChannelAdvanceInfo': S('getChannelAdvanceInfo', '/supplier/advances/item/findPage'),
            'getChannelCreditInfo': S('getChannelCreditInfo', '/supplier/credit/item/findPage'),
        },
        reducers: {
            'channelList': R('getChannelList', initTableData),
            'stateEnum': R('getStateEnum', []),
            'classList': R('getClassList', []),
            'channelBaseInfo': R('getChannelBaseInfo', { bizScopeName: [] }),
            'channelBalance': R('getChannelBalance', initTableData),
            'channelAdvanceInfo': R('getChannelAdvanceInfo', initTableData),
            'channelCreditInfo': R('getChannelCreditInfo', initTableData),
        }
    })
};
export default createModel(model);