import * as T from './actiontypes';

/**
 * 获取'直播间分类表格'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 */
export const getStudioRoomTableSource = (params = {}) => ({
    type: T.GET_STUDIOROOM_TABELSOURCE,
    payload: params
});

export const getLiveSort = ({ id, sortNo, classTypeCode }) => ({
    type: T.GET_LIVE_SORT,
    payload: {
        id, sortNo, classTypeCode
    }
});

export const getLiveStatus = ({ id, state, classTypeCode }) => ({
    type: T.GET_LIVE_STATUS,
    payload: {
        id, state, classTypeCode
    }
});

export const getLiveClassifyStatus = () => ({
    type: T.GET_LIVE_CLASSIFYSTATUS,
    payload: {}
});



export const getVideoData = (params = {}) => ({
    type: T.GET_VIDEO_DATA,
    payload: params
});



export const getCommunityData = (params = {}) => ({
    type: T.GET_COMMUNITY_DATA,
    payload: params
});



