/**
 * 通用方法 Saga集合
 */
import { getEnumSelectSource, getEnumFlagSource, getEnumCustomerSource } from './enum/saga';

export default [
    getEnumSelectSource,
    getEnumFlagSource,
    getEnumCustomerSource
];
