import React, { Component } from 'react';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import TableContent from './components/TableContent';
import { XOKButton, XCancelButton, XInput, XDatePicker, XSelect } from '@/components/xqxc_ui';
import model from '@/reducers/ordermanage/thirdorder/model';
import DeliveryModal from './modal/DeliveryModal';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import http from '@/assets/api/http';
import { message } from 'antd';

class TabContent extends Component {

    state = {
        nickName: '', // 昵称
        orderId: '', // 订单号
        gmtBeginTm: undefined, // 创建时间
        gmtEndTm: undefined, // 结束时间
        distributionStatus: {},
        source: {},
        orderType: {},

        syncBtnLoading: false,
        exportInfoBtnLoading: false,

        currentOpenMode: ''
    }

    componentDidMount() {
        this.props.getSourceList();
        this.props.getDistributionStatusList();
        this.props.getOrderTypeList();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { nickName, orderId, mobile, gmtBeginTm, gmtEndTm, distributionStatus, source, orderType, pageNum, pageSize } = this.state;
        const query = {
            nickName,
            tradeNo: orderId,
            mobile,
            startDate: gmtBeginTm,
            endDate: gmtEndTm,
            status: distributionStatus.code,
            channelSource: source.code,
            orderType: orderType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getTableData(query);
        KeepAlive.saveCache({ ...this.state });
    }
    tableAction = (id, type, record) => {
        const { history } = this.props;
        switch (type) {
            // 查看详情
            case 'detail':
                addOneBreadcrumbPath({
                    title: '订单详情',
                    path: {
                        pathname: `/home/ordermanage/thirdorder/detail/${record.paymentOrderId}`,
                    }
                });
                history.push({
                    pathname: `/home/ordermanage/thirdorder/detail/${record.paymentOrderId}`,
                });
                break;
        }
    }

    // 关闭查看物流弹框
    _closeMode = () => {
        this.setState({ renderMode: '' });
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ nickName: '', orderId: '', mobile: '', gmtBeginTm: undefined, gmtEndTm: undefined, distributionStatus: {}, source: {}, orderType: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    _syncHandle = () => {
        this.setState({ currentOpenMode: 'syncOrder' });
        // http('/shengxin/order/sync').then(res => {
        //     if (res.code === 200) {
        //         message.success('同步订单成功');
        //     }
        //     this.setState({ syncBtnLoading: false });
        // }).catch(e => {
        //     message.error(e.message);
        //     this.setState({ syncBtnLoading: false });
        // });
    }

    _exportHandle = () => {
        const { nickName, orderId, mobile, gmtBeginTm, gmtEndTm, distributionStatus, source, orderType, pageNum, pageSize } = this.state;
        const query = {
            nickName,
            tradeNo: orderId,
            mobile,
            startDate: gmtBeginTm,
            endDate: gmtEndTm,
            status: distributionStatus.code,
            channelSource: source.code,
            orderType: orderType.code,
            pageNum,
            pageSize,
        };
        this.setState({ exportInfoBtnLoading: true });
        http('/shengxin/order/export', query, 'POST').then((response) => {
            if (response.code == 200) {
                message.success('导出成功');
                try {
                    response.result && (window.location = response.result);
                } catch (e) {
                    message.error('弹出窗口被拦截，请点击地址栏下”允许阻止的内容“设置');
                }
            }
            this.setState({ exportInfoBtnLoading: false });
        }).catch((e) => {
            message.error(e.message);
            this.setState({ exportInfoBtnLoading: false });
        });
    }

    // 打开查看弹框
    _renderMode = () => {
        const { currentOpenMode } = this.state;
        switch (currentOpenMode) {
            case 'syncOrder':
                return <DeliveryModal visible={true} title='同步订单' closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    render() {
        const { nickName, orderId, mobile, gmtBeginTm, gmtEndTm, distributionStatus, source, orderType, exportInfoBtnLoading, syncBtnLoading } = this.state;
        const { tableData, distributionStatusList = [], sourceList = [], orderTypeList } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='订单编号'
                                placeholder='请输入'
                                value={orderId}
                                bindThis={this}
                                bindName='orderId'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='用户昵称'
                                placeholder='请输入'
                                value={nickName}
                                bindThis={this}
                                bindName='nickName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='手机号'
                                placeholder='请输入'
                                value={mobile}
                                bindThis={this}
                                bindName='mobile'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='创建时间'
                                value={gmtBeginTm}
                                bindThis={this}
                                bindName='gmtBeginTm'
                                isFormat
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                label=''
                                value={gmtEndTm}
                                bindThis={this}
                                bindName='gmtEndTm'
                                isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='分账状态'
                                placeholder='请选择'
                                renderData={distributionStatusList}
                                dataIndex='value'
                                keyIndex='code'
                                value={distributionStatus.value}
                                bindThis={this}
                                bindName='distributionStatus'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='来源渠道'
                                placeholder='请选择'
                                renderData={sourceList}
                                dataIndex='value'
                                keyIndex='code'
                                value={source.value}
                                bindThis={this}
                                bindName='source'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='订单类型'
                                placeholder='请选择'
                                renderData={orderTypeList}
                                dataIndex='value'
                                keyIndex='code'
                                value={orderType.value}
                                bindThis={this}
                                bindName='orderType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: 100, marginRight: 15 }} label='同步订单' loading={syncBtnLoading} onClick={this._syncHandle} />
                        <XOKButton style={{ width: 100 }} label='导出' onClick={this._exportHandle} loading={exportInfoBtnLoading} />
                    </div>
                    <TableContent
                        renderData={tableData}
                        paginationChange={this._paginationChange}
                        tableAction={this.tableAction}
                    />
                </div>
                {this._renderMode()}
            </KeepAlive>
        );
    }
}
TabContent.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func,
    getTableData: PropTypes.func,
    tableData: PropTypes.object,

    getSourceList: PropTypes.func,
    sourceList: PropTypes.array,

    getOrderTypeList: PropTypes.func,
    orderTypeList: PropTypes.array,

    getDistributionStatusList: PropTypes.func,
    distributionStatusList: PropTypes.array,
};
export default connect(state => ({ ...state.thirdOrder }), { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(TabContent);