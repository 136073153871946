import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell, tooltipClick, cellWidth } from '@/components/TableCell';

const TableContent = ({ renderData, paginationChange, tableAction, scrollY }) => {

    const textCell = {
        align: 'center',
        width: cellWidth.normal,
        onCell: tooltipStyle,
        render: tooltip
    };

    const moneyCell = {
        align: 'center',
        width: cellWidth.normal,
        onCell: tooltipStyle,
        render: priceFenToYuanCell
    };

    const columns = () => ([
        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: (text, record) => tooltipClick(text, () => tableAction(record.id, 'info', record))
        },
        {
            title: '订单明细编号',
            dataIndex: 'sourceOrderId',
            key: 'sourceOrderId',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: (text, record) => tooltipClick(text, () => tableAction(record.id, 'info', record))
        },
        // {
        //     title: '会员账号',
        //     dataIndex: 'accountId',
        //     key: 'accountId',
        //     ...textCell
        // },
        {
            title: '商户名称',
            dataIndex: 'merName',
            key: 'merName',
            ...textCell
        },
        // {
        //     title: '商户类型',
        //     dataIndex: 'merType',
        //     key: 'merType',
        //     ...textCell
        // },
        // {
        //     title: '消费类型',
        //     dataIndex: 'consumeType',
        //     key: 'consumeType',
        //     ...textCell
        // },
        {
            title: '下单时间',
            dataIndex: 'createOrderTm',
            key: 'createOrderTm',
            ...textCell
        },
        {
            title: '支付方式',
            dataIndex: 'payType',
            key: 'payType',
            ...textCell
        },
        // {
        //     title: '支付单号',
        //     dataIndex: 'outPayNo',
        //     key: 'outPayNo',
        //     ...textCell
        // },
        {
            title: '付款时间',
            dataIndex: 'payTm',
            key: 'payTm',
            ...textCell
        },
        // {
        //     title: '支付流水号',
        //     dataIndex: 'payId',
        //     key: 'payId',
        //     ...textCell
        // },
        // {
        //     title: '订单状态',
        //     dataIndex: 'orderState',
        //     key: 'orderState',
        //     ...textCell
        // },
        // {
        //     title: '人气值',
        //     dataIndex: 'showNum',
        //     key: 'showNum',
        //     ...textCell
        // },
        // {
        //     title: '收货地址',
        //     dataIndex: 'shipAddr',
        //     key: 'shipAddr',
        //     ...textCell
        // },
        {
            title: '货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            ...textCell
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            ...textCell
        },
        {
            title: '数量',
            dataIndex: 'goodsNum',
            key: 'goodsNum',
            ...textCell
        },
        // {
        //     title: '完成时间',
        //     dataIndex: 'finishTm',
        //     key: 'finishTm',
        //     ...textCell
        // },
        // {
        //     title: '仓库名称',
        //     dataIndex: 'warehouseName',
        //     key: 'warehouseName',
        //     ...textCell
        // },
        // {
        //     title: '物流单号',
        //     dataIndex: 'shipmentNumber',
        //     key: 'shipmentNumber',
        //     ...textCell
        // },
        // {
        //     title: '物流公司',
        //     dataIndex: 'shipmentName',
        //     key: 'shipmentName',
        //     ...textCell
        // },
        {
            title: '发货时间',
            dataIndex: 'deliveryTm',
            key: 'deliveryTm',
            ...textCell
        },
        // {
        //     title: '活动名称',
        //     dataIndex: 'activeTm',
        //     key: 'activeTm',
        //     ...textCell
        // },
        // {
        //     title: '销售规格',
        //     dataIndex: 'skuSpec',
        //     key: 'skuSpec',
        //     ...textCell
        // },
        {
            title: '市场价格',
            dataIndex: 'marketPrice',
            key: 'marketPrice',
            ...moneyCell
        },
        {
            title: '销售价格',
            dataIndex: 'salePrice',
            key: 'salePrice',
            ...moneyCell
        },
        {
            title: '运费',
            dataIndex: 'freight',
            key: 'freight',
            ...moneyCell
        },
        {
            title: '其它费用',
            dataIndex: 'extraCost',
            key: 'extraCost',
            ...moneyCell
        },
        {
            title: '优惠金额',
            dataIndex: 'discountedPrice',
            key: 'discountedPrice',
            ...moneyCell
        },
        {
            title: '订单金额',
            dataIndex: 'orderPrice',
            key: 'orderPrice',
            ...moneyCell
        },
        // {
        //     title: '结算价',
        //     dataIndex: 'settlePrice',
        //     key: 'settlePrice',
        //     ...moneyCell
        // },
        {
            title: '商品总金额',
            dataIndex: 'goodsAmout',
            key: 'goodsAmout',
            ...moneyCell
        },
        // {
        //     title: '品牌名称',
        //     dataIndex: 'brandName',
        //     key: 'brandName',
        //     ...textCell
        // },
        {
            title: '商品分类',
            dataIndex: 'categoryName',
            key: 'categoryName',
            ...textCell
        },
        {
            title: '分类类型',
            dataIndex: 'categorySubTypeName',
            key: 'categorySubTypeName',
            align: 'center',
            ...textCell
        },
        // {
        //     title: '付款日期',
        //     dataIndex: 'payDate',
        //     key: 'payDate',
        //     ...textCell
        // },
        // {
        //     title: '业务类型',
        //     dataIndex: 'businessType',
        //     key: 'businessType',
        //     ...textCell
        // },
        {
            title: '服务商名称',
            dataIndex: 'serviceName',
            key: 'serviceName',
            ...textCell
        },
        {
            title: '服务费金额',
            dataIndex: 'serviceFee',
            key: 'serviceFee',
            ...moneyCell
        },
        {
            title: '服务商管理费',
            dataIndex: 'serviceManagementFee',
            key: 'serviceManagementFee',
            ...moneyCell
        },
        {
            title: '机构名称',
            dataIndex: 'institutionName',
            key: 'institutionName',
            ...textCell
        },
        {
            title: '机构管理费',
            dataIndex: 'institutionManagementFee',
            key: 'institutionManagementFee',
            ...moneyCell
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'key',
            align: 'center',
            width: 150,
            fixed: 'right',
            render: (text, item) => _renderActionCell(item)
        }
    ]);
    const _renderActionCell = (item) => {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(item.id, 'logistics', item)}>查看物流</div>
                <div className={styles.item} onClick={() => tableAction(item.id, 'history', item)}>查看日志</div>
            </div>
        );
    };
    return (
        <div className={styles.tableContainer} style={{ height: '100%' }}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.id}
                columns={columns()}
                dataSource={renderData.dataList}
                pagination={false}
                scroll={{ x: 4800, y: scrollY }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    tableAction: PropTypes.func,
    scrollY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default TableContent;