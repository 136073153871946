/**
 * [标准商品库]
 * @author: Fu Xiaochun
 * @date: 2021-06-03 
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { message, Modal } from 'antd';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import TableData from './components/TableData';
import { getStandardProduct } from '@/reducers/productmanage/standardproduct/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { ThirdPartyGoodsCategory, GoodsClassify } from '@/vcomps';
import ButtonPermission from '@/routes/ButtonPerssion';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';

class StandardProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productName: '',
            brandName: '',
            categoryId1: '',
            categoryId2: '',
            categoryId3: '',
            categoryItem1: {},
            categoryItem2: {},
            categoryItem3: {},
            platCategoryId1: '',
            platCategoryId2: '',
            platCategoryId3: '',
            syncStatus: {},
            productIds: [],
            categorySubTypeItem: {}
        };
        this.syncStatusOptions = [
            { text: '未启用', value: '0' },
            { text: '已启用', value: '1' }
        ];
        this.$table = React.createRef();
        this.thirdPartyGoodsCategoryRef = React.createRef();
        this.cleanRef = null;
    }

    // 查询
    _searchHandle = (useCache) => {
        const { productName, brandName, categoryId1, categoryItem1, categoryId2, categoryItem2, categoryId3, categoryItem3, platCategoryId1, platCategoryId2, platCategoryId3, syncStatus, pageNum, pageSize, categorySubTypeItem } = this.state;
        this.searchCache = {
            productName,
            brandName,
            categoryId1,
            categoryId2,
            categoryId3,
            platCategoryId1,
            platCategoryId2,
            platCategoryId3,
            status: syncStatus.value,
            categorySubType: categorySubTypeItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };

        this.props.getStandardProduct(this.searchCache);
        KeepAlive.saveCache({ ...this.state, categoryItem1, categoryItem2, categoryItem3, platCategoryId1, platCategoryId2, platCategoryId3, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置
    _resetHandle = () => {
        this.thirdPartyGoodsCategoryRef.current.clean();
        this.cleanRef.clean();
        this.setState({
            productName: '',
            brandName: '',
            categoryId1: '',
            categoryId2: '',
            categoryId3: '',
            categoryItem1: {},
            categoryItem2: {},
            categoryItem3: {},
            platCategoryId1: '',
            platCategoryId2: '',
            platCategoryId3: '',
            syncStatus: {},
            categorySubTypeItem: {}
        }, () => {
            this._searchHandle();
        });
    }

    // 表格操作  选择，状态开关，详情，编辑
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'select':
                this.setState({
                    productIds: [...params]
                });
                break;
            case 'status-switch':
                {
                    let status = params ? '1' : '0';
                    this._editStatus(id, status);
                }
                break;
            case 'detail':

                // 增加面包屑导航
                this.props.addOneBreadcrumbPath({
                    title: '商品详情',
                    path: '/home/productmanage/standardproduct/detail/'
                });
                this.props.history.push({
                    pathname: '/home/productmanage/standardproduct/detail/' + id
                });
                break;
            case 'edit':

                // 增加面包屑导航
                this.props.addOneBreadcrumbPath({
                    title: '编辑标准商品',
                    path: '/home/productmanage/standardproduct/edit/'
                });
                this.props.history.push({
                    pathname: '/home/productmanage/standardproduct/edit/' + id
                });
                break;
        }
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 新建标准商品
    _createStandardProduct = () => {
        // 增加面包屑导航
        this.props.addOneBreadcrumbPath({
            title: '新建标准商品',
            path: '/home/productmanage/standardproduct/create'
        });
        this.props.history.push({
            pathname: '/home/productmanage/standardproduct/create'
        });
    }

    // 批量启用
    _batchEnable = () => {
        let ids = this.state.productIds;
        if (ids.length == 0) {
            Modal.error({
                title: '你还未选择要启用的商品',
                content: '请先选择要启用的商品，再执行批量启用'
            });
            return false;
        }
        showConfirm('启用后的商品才可用商户加入到店铺同城商品中', '', () => {
            http('/goods/baseProduct/batchUpdateStatus', { productIds: ids, status: '1' }, 'POST')
                .then(() => {
                    message.success('操作成功');
                    this._flushTable();
                }).catch((reject) => {
                    message.error(reject.message);
                });
        });
    };

    // 批量禁用
    _batchDisable = () => {
        let ids = this.state.productIds;
        if (ids.length == 0) {
            Modal.error({
                title: '你还未选择要禁用的商品',
                content: '请先选择要禁用的商品，再执行批量禁用'
            });
            return false;
        }
        showConfirm('确定要批量禁用吗？', '', () => {
            http('/goods/baseProduct/batchUpdateStatus', { productIds: ids, status: '0' }, 'POST')
                .then(() => {
                    message.success('操作成功');
                    this._flushTable();
                }).catch((reject) => {
                    message.error(reject.message);
                });
        });
    };

    // 单个状态修改
    _editStatus = (id, status) => {
        let params = {
            productIds: [id],
            status
        };
        http('/goods/baseProduct/batchUpdateStatus', params, 'POST')
            .then(() => {
                message.success('操作成功');
                this._searchHandle('useCache');
            }).catch((reject) => {
                message.error(reject.message);
            });
    }

    // 清除选择项后重新加载列表
    _flushTable = () => {
        this.setState({
            productIds: []
        }, () => {
            this._searchHandle('useCache');
        });
    }


    _onClassifyChange = (cid, level, item) => {
        switch (level) {
            case 'first':
                this.setState({
                    categoryId1: cid,
                    categoryItem1: item
                });
                break;
            case 'second':
                this.setState({
                    categoryId2: cid,
                    categoryItem2: item
                });
                break;
            case 'third':
                this.setState({
                    categoryId3: cid,
                    categoryItem3: item
                });
                break;
        }
    }

    _onPlatClassifyChange = (cid, level) => {
        switch (level) {
            case 'first':
                this.setState({ platCategoryId1: cid });
                break;
            case 'second':
                this.setState({ platCategoryId2: cid });
                break;
            case 'third':
                this.setState({ platCategoryId3: cid });
                break;
        }
    }

    render() {
        const { productName, brandName, syncStatus, productIds, categoryItem1, categoryItem2, categoryItem3, categorySubTypeItem } = this.state;
        const { standardProduct } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                        this.thirdPartyGoodsCategoryRef.current.init([state.categoryItem1, state.categoryItem2, state.categoryItem3]);
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商品名称'
                                placeholder='请输入商品名称'
                                bindThis={this}
                                bindName='productName'
                                value={productName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <ThirdPartyGoodsCategory
                                style={{ marginRight: '20px' }}
                                label={['一级类目', '二级类目', '三级类目']}
                                ref={this.thirdPartyGoodsCategoryRef}
                                onClassifyChange={this._onClassifyChange}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='品牌名称'
                                placeholder='请输入品牌名称'
                                bindThis={this}
                                bindName='brandName'
                                value={brandName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={this.syncStatusOptions}
                                bindThis={this}
                                bindName='syncStatus'
                                dataIndex='text'
                                keyIndex='value'
                                isOptionBindID={true}
                                value={syncStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商品分类'
                                style={{ marginRight: '10px' }}
                                placeholder='请选择分类类型'
                                renderData={[
                                    { code: 'COMMON', value: '通用分类' },
                                    { code: 'ONLINE', value: '线上分类' },
                                    { code: 'CITY', value: '同城分类' }
                                ]}
                                bindThis={this}
                                bindName='categorySubTypeItem'
                                dataIndex='value'
                                keyIndex='code'
                                value={categorySubTypeItem.value}
                            />
                            <GoodsClassify
                                style={{ marginRight: '20px' }}
                                ref={ele => this.cleanRef = ele}
                                categorySubType={categorySubTypeItem.code}
                                onClassifyChange={this._onPlatClassifyChange}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <ButtonPermission code="productmanage/standardproduct/create">
                            <XOKButton style={{ width: '120px', marginRight: '30px' }} label='新建标准商品' onClick={this._createStandardProduct} />
                        </ButtonPermission>
                        <ButtonPermission code="productmanage/standardproduct/enable">
                            <XOKButton style={{ width: '100px', marginRight: '30px' }} label='批量启用' onClick={this._batchEnable} />
                        </ButtonPermission>
                        <ButtonPermission code="productmanage/standardproduct/disable">
                            <XOKButton style={{ width: '100px', marginRight: '30px' }} label='批量禁用' onClick={this._batchDisable} />
                        </ButtonPermission>
                    </div>
                    <TableData selectedRowKeys={productIds} paginationChange={this._paginationChange} renderData={standardProduct} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}

StandardProductList.propTypes = {
    getStandardProduct: PropTypes.func,
    standardProduct: PropTypes.object,
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
};

const mapStateToProps = (state) => ({
    standardProduct: state.standardProduct.standardProduct
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, getStandardProduct })(StandardProductList);