import * as System from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    resultTotal: 0
};
const initDetail = {
    forceUpdate: {},
    appType: {},
    systemType: {},
    updateMode: {}
};
export const releaseList = (state = initData, { type, payload }) => {
    switch (type) {
        case System.SET_RELEASE_LIST:
            return payload.result;
        default:
            return state;
    }
};
export const releaseDetail = (state = initDetail, { type, payload }) => {
    switch (type) {
        case System.SET_RELEASE_DETAIL:
            return payload.result;
        default:
            return state;
    }
};
export const releaseTerminal = (state = [], { type, payload }) => {
    switch (type) {
        case System.SET_RELEASE_TERMINAL:
            return payload.result;
        default:
            return state;
    }
};
export const releaseSystem = (state = [], { type, payload }) => {
    switch (type) {
        case System.SET_RELEASE_SYSTEM:
            return payload.result;
        default:
            return state;
    }
};
export const releaseState = (state = [], { type, payload }) => {
    switch (type) {
        case System.SET_RELEASE_STATE:
            return payload.result;
        default:
            return state;
    }
};
export const releaseUpdate = (state = [], { type, payload }) => {
    switch (type) {
        case System.SET_RELEASE_UPDATE:
            return payload.result;
        default:
            return state;
    }
};
