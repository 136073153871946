import React from 'react';
import { isEmpty } from '@/assets/js/utils';
import { regFenToYuan, regYuanToFen } from '@/util/money';

const CellFenToYuan = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {isEmpty(text) ? '-' : regFenToYuan(text)}
        </div>
    );
};

const CellYuanToFen = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {isEmpty(text) ? '-' : regYuanToFen(text)}
        </div>
    );
};

export { CellFenToYuan as default, CellYuanToFen };