import { createModel } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 };

const model = {
    namespace: 'cancelreasons',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getCancelReasonsList': T('getCancelReasonsList'),
        },
        actions: {
            'getCancelReasonsList': A('getCancelReasonsList'),
        },
        sagas: {
            'getCancelReasonsList': S('getCancelReasonsList', '/hotel/reason/listHotelCancelReason'),
        },
        reducers: {
            'cancelReasonsList': R('getCancelReasonsList', dataList),
        }
    })
};
export default createModel(model);