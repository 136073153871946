import React from 'react';
import styles from './TableMain.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

class TableMain extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
        auditedID: [], // 审核（或者未审核）的ID
        currentOpenMode: '',
        openModeParam: '',
        audit: {},
    }

    _columns = () => [
        {
            title: '手机号码',
            dataIndex: 'accountName',
            key: 'taccountNameel',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '视频类型',
            dataIndex: 'taskType',
            key: 'taskType',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '视频分类',
            dataIndex: 'className',
            key: 'className',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '视频标题',
            dataIndex: 'feedTitle',
            key: 'feedTitle',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '任务归属',
            dataIndex: 'systemType',
            key: 'systemType',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '短视频封面',
            dataIndex: 'coverPage',
            key: 'coverPage',
            align: 'center',
            width: '8%',
            render: ImgCell
        },
        {
            title: '审核状态',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            width: '8%',
            render: (state) => (this._stateCell(state))
        },
        {
            title: '提交时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '审核人',
            dataIndex: 'approvalUserName',
            key: 'approvalUserName',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '审核时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render: (id, record) => this._tableAction(id, record)
        }
    ];


    _stateCell = (state) => {
        return state.value;
    }

    _tableAction = (id, record) => {
        if (record.state.code == 'APPLY') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => this.props.tableAction(id, record, 'audit')}>审核</div>
                </div>
            );
        } else {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => this.props.tableAction(id, record, 'detail')}>查看详情</div>
                </div>
            );
        }
    };

    _takeDataSource = (data) => {
        if (data && data.dataList) {
            data.dataList.map(item => {
                if (item.state == 'APPLY') {
                    item.state = '审批中';
                }
                if (item.state == 'APPROVAL') {
                    item.state = '审批通过';
                }
                if (item.state == 'REJECT') {
                    item.state = '审批不通过';
                }
                if (item.state == 'BANNED') {
                    item.state = '禁用';
                }
            });
            return data.dataList;
        } else {
            return [];
        }
    }

    render() {
        const { renderData, paginationChange } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(renderData)}
                    rowKey='id'
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={paginationChange} />
            </div>
        );
    }
}

TableMain.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

export default TableMain;


