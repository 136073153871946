/**
 *  店铺管理 - 店铺列表
 * */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, message, } from 'antd';
import http from '@/assets/api/http';
import { getEnumFlagSource } from '@/reducers/common/enum/actions';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import ReleaseProhibitModal from '../../modal/ReleaseProhibitModal';
import * as actions from '@/reducers/storemanage/list/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import Preview from './preview';
import model from '@/reducers/businessmanage/classlist/model';

const { getClassEnum } = model.actions;

const Mode = {
    MER_DELETE: 'DELETE', // 详情
    MER_BANNED: 'BANNED', // 封禁
};

const storeReco = [//店铺推荐
    {
        code: '1',
        value: '是'
    },
    {
        code: '0',
        value: '否'
    }
];
class Main extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
        saleState: {}, // 封禁状态
        saleNames: '', // 店铺名称
        saleRecommen: {}, // 店铺推荐
        salemerchants: '', // 归属商户

        selectedRowKeys: [],//对应的key数据
        selectedRows: [],//对应的key行数据
        isOpenCustomerService: {}, // 客服坐席
        shopTypeItem: {},// 店铺类型
        classItem: {}, classNameItem: {},//task-3616类目分类、类目名称
        subcategorySele: [],//task-3616类目名称下拉
    };

    componentDidMount() {
        const { getstoreselectData, getEnumFlagSource, getClassEnum } = this.props;
        getstoreselectData(); // 封禁状态
        getEnumFlagSource(); // 客服坐席
        getClassEnum();// 经营类目枚举
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getStoreListTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 状态
    _saleChangeHandle = (value) => {
        this.setState({ saleState: value });
    }

    // 商户名称
    _saleItemChangeHandle = (value) => {
        this.setState({ saleNames: value });
    }

    // 查询触发
    _saleSearchHandle = (useCache) => {
        const { saleNames, saleState, saleRecommen, salemerchants, pageSize, pageNum, isOpenCustomerService, shopTypeItem, classItem, classNameItem } = this.state;
        this.searchCache = {
            shopName: saleNames,//店铺名称  
            merchantName: salemerchants,//归属商户  
            shopState: saleState.code,//封禁状态  
            isReco: saleRecommen.code,//店铺推荐
            isOpenCustomerService: isOpenCustomerService.code,
            shopType: shopTypeItem.code,
            category: classItem.code, subcategory: classNameItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getStoreListTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _saleresetHandle = () => {
        this.setState({
            saleState: {}, saleRecommen: {}, saleNames: '', salemerchants: '', isOpenCustomerService: {}, shopTypeItem: {},
            classItem: {}, classNameItem: {}
        }, () => {
            KeepAlive.saveResetFunc(this._saleSearchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建按钮被点击
    _newCreateHandle = () => {
        this.props.updateContent('NEWCREATE');
    }

    //批量推荐
    _recommendedHandle = () => {
        const { selectedRowKeys } = this.state;
        if (selectedRowKeys.length == 0) {
            message.warn('至少选中一条店铺信息');
        } else {
            http('/shop/whetherRecommendStatusList', {
                isReco: '1',
                shopIdList: selectedRowKeys,
            }, 'POST').then(() => {
                message.success('批量推荐修改成功');
                this.setState({ selectedRowKeys: [] });
                this.props.getStoreListTableSource(this.searchCache);// 重新渲染更新列表
            }).catch((reject = {}) => {
                message.error(reject.message);
            });
        }
    }

    // 表格操作功能回调
    _actionClickHandle = (id, item, type) => {
        const { updateContent, getStoreListTableSource } = this.props;
        switch (type) {
            case Mode.MER_DELETE: // 详情
                updateContent('MER_DELETE', id);
                break;
            case Mode.MER_BANNED: // 封禁 
                updateContent('MER_BANNED', id);
                break;
            case 'LIFTING': // 解禁  
                this.setState({ currentOpenMode: 'LIFTING', openModeParam: item });
                break;
            case 'switch': // 推荐
                http('/shop/whetherRecommendStatus', {
                    isReco: id === false ? '0' : '1',
                    shopId: item.id,
                }, 'POST').then(() => {
                    message.success('推荐修改成功');
                    getStoreListTableSource(this.searchCache);// 重新渲染更新列表
                }).catch((reject = {}) => {
                    message.error(reject.message);
                });
                break;
            case 'PREVIEW': // 预览
                this.setState({ currentOpenMode: 'PREVIEW', openModeParam: item });
                break;
        }
    }

    // 打开弹窗
    _renderMode = () => {
        const { currentOpenMode, openModeParam } = this.state;
        switch (currentOpenMode) {
            case 'LIFTING':
                return <ReleaseProhibitModal title='解封' data={openModeParam} visible={true} closeMode={this._closeMode} />;
            case 'PREVIEW':
                return <Preview data={openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getStoreListTableSource(this.searchCache); // 重新渲染更新列表
        }
    }

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [], selectedRows: [] });
    }

    // 表格单选输入 --批量加入推荐
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
    };

    //根据类目分类枚举获得类目名称
    _getSubcategorySele = ({ code = '' } = {}) => {
        http('/shop/industryCategoryList', { type: code }, 'POST')
            .then(res => this.setState({ subcategorySele: res.result, classNameItem: {} }));
    };

    render() {
        const { tableSource, paginations, selectData, enumFlagSource, classEnum } = this.props;
        const { saleState, saleNames, saleRecommen, salemerchants, selectedRowKeys, isOpenCustomerService, classItem, classNameItem, subcategorySele } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._saleSearchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='店铺名称'
                                placeholder='请输入'
                                value={saleNames}
                                bindThis={this}
                                bindName='saleNames'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='店铺推荐'
                                placeholder='请选择'
                                renderData={storeReco}
                                dataIndex='value'
                                keyIndex='code'
                                value={saleRecommen.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='saleRecommen'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='归属商户'
                                placeholder='请输入'
                                value={salemerchants}
                                bindThis={this}
                                bindName='salemerchants'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='封禁状态'
                                placeholder='请选择'
                                renderData={selectData}
                                dataIndex='value'
                                keyIndex='code'
                                value={saleState.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='saleState'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='客服座席'
                                placeholder='请选择'
                                renderData={enumFlagSource}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='isOpenCustomerService'
                                value={isOpenCustomerService.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect label='类目分类：' placeholder='请选择类目分类' renderData={classEnum} dataIndex='value'
                                keyIndex='code' bindName='classItem' bindThis={this} value={classItem.value} onChange={this._getSubcategorySele}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect label='类目名称：' placeholder='请选择类目名称' renderData={subcategorySele} dataIndex='name'
                                keyIndex='code' bindName='classNameItem' bindThis={this} value={classNameItem.name}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._saleSearchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._saleresetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '115px' }} label='店铺推荐列表' onClick={this._newCreateHandle} />
                        <XOKButton style={{ width: '115px', marginLeft: '20px', }} label='批量加入推荐' onClick={this._recommendedHandle} />
                    </div>
                    <div className={`${styles.tableContainer}`}>
                        <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record) => record.id}
                            rowSelection={rowSelection}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(tableSource)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={paginations.resultTotal}
                            pageSize={paginations.pageSize}
                            pageNum={paginations.pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,

    getStoreListTableSource: PropTypes.func, // 通过Saga异步请求表格数据源的Action
    getstoreselectData: PropTypes.func,// 通过Saga异步请求下拉选项数据方法Action
    selectData: PropTypes.array,
    paginations: PropTypes.object,//fenye 
    tableSource: PropTypes.array,//店铺列表数据
    getEnumFlagSource: PropTypes.func,
    enumFlagSource: PropTypes.array,
    getClassEnum: PropTypes.func, classEnum: PropTypes.array
};


const mapStateToProps = (state) => {
    return {
        tableSource: state.stores.storeDate.tableSource, // 表格数据源
        selectData: state.stores.storeDate.selectData, // 状态下拉选项
        paginations: state.stores.storeDate.paginations, // 分页数据
        enumFlagSource: state.common_enum.enumFlagSource, // 客服坐席
        classEnum: state.businessclasslist.classEnum,//经营类目枚举
    };
};

export default connect(mapStateToProps, { ...actions, getEnumFlagSource, getClassEnum })(Main);