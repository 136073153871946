import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Task from './actionTypes';

//任务列表
export const getTaskList = function* () {
    yield takeEvery(Task.GET_TASKMANAGE_TASKLIST, function* requestData({ payload }) {
        try {
            let result = yield http('/taskInfo/listByPage', payload, 'POST');
            yield put({ type: Task.SET_TASKMANAGE_TASKLIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//任务详情
export const getTaskDetail = function* () {
    yield takeEvery(Task.GET_TASKMANAGE_TASKDETAIL, function* requestData({ payload }) {
        try {
            let result = yield http('/taskInfo/getTaskDetail', payload, 'POST');
            yield put({ type: Task.SET_TASKMANAGE_TASKDETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//任务分类下拉
export const getTaskClassifySele = function* () {
    yield takeEvery(Task.GET_TASKMANAGE_TASKCLASSIFYSELE, function* requestData({ payload }) {
        try {
            let result = yield http('/marketing/activityInfo/selectTaskCategory', payload, 'POST');
            yield put({ type: Task.SET_TASKMANAGE_TASKCLASSIFYSELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//任务类型下拉
export const getTaskTypeSele = function* () {
    yield takeEvery(Task.GET_TASKMANAGE_TASKTYPESELE, function* requestData({ payload }) {
        try {
            let result = yield http('/marketing/activityInfo/selectTaskType', payload, 'POST');
            yield put({ type: Task.SET_TASKMANAGE_TASKTYPESELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//任务推送平台下拉
export const getTaskPlatSele = function* () {
    yield takeEvery(Task.GET_TASKMANAGE_TASKPLATSELE, function* requestData({ payload }) {
        try {
            let result = yield http('/marketing/activityInfo/selectTargetPlat', payload, 'POST');
            yield put({ type: Task.SET_TASKMANAGE_TASKPLATSELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//任务信息下拉
export const getTaskInfoSele = function* () {
    yield takeEvery(Task.GET_TASKMANAGE_TASKINFOSELE, function* requestData({ payload }) {
        try {
            let result = yield http('/taskInfo/listEvents', payload, 'POST');
            yield put({ type: Task.SET_TASKMANAGE_TASKINFOSELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//任务要素下拉
export const getTaskFactorSele = function* () {
    yield takeEvery(Task.GET_TASKMANAGE_TASKFACTORSELE, function* requestData({ payload }) {
        try {
            let result = yield http('/taskInfo/listEventConfigs', payload, 'POST');
            yield put({ type: Task.SET_TASKMANAGE_TASKFACTORSELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//任务奖励下拉
export const getTaskAwardSele = function* () {
    yield takeEvery(Task.GET_TASKMANAGE_TASKAWARDSELE, function* requestData({ payload }) {
        try {
            let result = yield http('/taskInfo/recordType', payload, 'POST');
            yield put({ type: Task.SET_TASKMANAGE_TASKAWARDSELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//任务奖励会员等级
export const getTaskGrade = function* () {
    yield takeEvery(Task.GET_TASKMANAGE_TASKGRADE, function* requestData({ payload }) {
        try {
            let result = yield http('/user/account/listAccountLevel', payload, 'POST');
            yield put({ type: Task.SET_TASKMANAGE_TASKGRADE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};