import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import * as actions from '@/reducers/auditmanage/companyAudit/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: [], // 当前需要打开的弹窗的入参
            companyName: '',
            companyStatus: {}
        };
    }
    static propTypes = {
        updateContent: PropTypes.func,
        getCompanyAuditList: PropTypes.func,
        getCompanyAuditStatus: PropTypes.func,
        companyAuditList: PropTypes.object,
        companyAuditStatus: PropTypes.array,
    };
    componentDidMount() {
        this.props.getCompanyAuditStatus();
    }
    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, companyName, companyStatus } = this.state;
        this.searchCache = {
            companyType: 'RE', companyName, companyState: companyStatus.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getCompanyAuditList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置
    _resetHandle = () => {
        this.setState({ companyName: '', companyStatus: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'detail':
                console.log(id, type, params);
                this.props.updateContent('DETAIL', id);
                break;
            case 'audit':
                console.log(id, type, params);
                this.props.updateContent('AUDIT', id);
                break;
        }
    }
    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }
    render() {
        const { companyName } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='公司简称'
                                placeholder='请输入公司简称'
                                bindThis={this}
                                bindName='companyName'
                                value={companyName}
                            />
                        </SearchItem>
                        <SearchItem>
                            {/* <XSelect
                        style={{ marginLeft: '30px', width: '300px' }}
                        selectStyle={{ width: '200px' }}
                        label='公司状态'
                        placeholder='请选择'
                        renderData={this.props.companyAuditStatus}
                        bindThis={this}
                        bindName='companyStatus'
                        dataIndex='value'
                        keyIndex='id'
                        value={companyStatus.merchantName}
                    /> */}
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    {/* <div className={styles.operate_box}>
                    <XOKButton style={{ width: '90px' }} label='导出Excel' />
                </div> */}
                    <TableContent paginationChange={this._paginationChange} renderData={this.props.companyAuditList} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}
const mapStateToProps = (state) => ({
    companyAuditList: state.companyAudit.companyAuditList,
    companyAuditStatus: state.companyAudit.companyAuditStatus,
});
export default connect(mapStateToProps, actions)(Main);