import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';


const columns = (TableAction, actionClickHandle) => [
	{
		title: '标签图标',
		dataIndex: 'imgUrl',
		key: 'imgUrl',
		align: 'center',
		width: '15%',
		render: ImgCell
	},
	{
		title: '商品标签',
		dataIndex: 'tagName',
		key: 'tagName',
		align: 'center',
		width: '30%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '描述',
		dataIndex: 'tagDesc',
		key: 'tagDesc',
		align: 'center',
		width: '40%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '操作',
		dataIndex: 'id',
		key: 'id',
		align: 'center',
		width: '15%',
		render: (id) => TableAction(id, actionClickHandle)
	},
];

// 表格操作
const TableAction = (key, actionClickHandle) => {
	return (
		<div className={styles.action}>
			<div className={styles.item} onClick={() => actionClickHandle(key, 'edit')}>编辑</div>
			<div className={styles.item} onClick={() => actionClickHandle(key, 'delete')}>删除</div>
		</div>
	);
};

class TableData extends Component {
	// 操作表格
	_actionHandle = (id, type, value) => {
		const { tableAction } = this.props;
		tableAction(id, type, value);
	};

	_takeDataSource = (data) => {
		return data;
	}

	render() {
		const { renderData } = this.props;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey='id'
					columns={columns(TableAction, this._actionHandle)}
					dataSource={this._takeDataSource(renderData.dataList)}
					pagination={false}
					scroll={{ y: true }}
				/>
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={this.props.paginationChange}
				/>
			</div>
		);
	}
}

TableData.propTypes = {
	tableAction: PropTypes.func, // 对表格的操作
	paginationChange: PropTypes.func,
	renderData: PropTypes.object
};


export default TableData;