/**
 * 视频分类
 */
import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm } from '@/components/xqxc_ui';
import TableContent from '../../components/TableContent';
import ChannelActionModal from '../../modal/ChannelActionModal';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { getVideoData, getLiveClassifyStatus, getLiveSort, getLiveStatus } from '@/reducers/showmanage/channelclassification/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

const Mode = {
    CREAT: 'CREAT',
    EDITE: 'EDITE',
};

class Video extends React.Component {

    state = {
        selectDataSource: [], // 下拉列数据源
        type: {}, // 分类状态
        typeName: '', // 分类名称
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
        tableItem: {}
    }

    componentDidMount() {
        this.props.getLiveClassifyStatus();
    }

    // 创建直播间分类
    _createStudioRoom = () => {
        this.setState({ currentOpenMode: Mode.CREAT });
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { type, typeName, pageNum, pageSize } = this.state;
        this.searchCache = {
            state: type.code || '',
            className: typeName,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getVideoData(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ type: {}, typeName: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    //发送请求
    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST')
            .then(() => {
                this._searchHandle('useCache');
                message.success(winTxt);
            }).catch((reject) => {
                message.error(reject.message);
            });
    }

    // 表格操作
    _tableAction = (id, activeType, params) => {
        switch (activeType) {
            case 'edit':
                http('/show/showClass/getShowClassInfo/' + id, {}, 'POST')
                    .then((resole) => {
                        this.setState({ currentOpenMode: Mode.EDITE, tableItem: resole.result || {} });
                    }).catch(() => { message.error('请求失败！'); });
                break;
            case 'switch':
                this.props.getLiveStatus({ id, state: params.state, classTypeCode: params.classTypeCode });
                break;
            case 'delete':
                showConfirm('您确定要删除该分类？', '', () => {
                    this._sendReq('/show/showClass/deleteVideoClass/' + id, {}, '删除成功。', '删除失败！');
                });
                break;
            case 'sort':
                this._sendReq('/show/showClass/updateShowClassSortNo', { id: params.id, sortNo: params.sortNo, classTypeCode: params.classTypeCode }, '排序成功。', '排序失败！');
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { tableItem } = this.state;
        switch (this.state.currentOpenMode) {
            case Mode.CREAT:
                return <ChannelActionModal title='创建视频分类' visible={true}
                    data={{ action: 'create', url: '/show/showClass/createShortVideoClass', tableItem: {} }}
                    closeMode={this._closeMode} />;
            case Mode.EDITE:
                return <ChannelActionModal title='编辑视频分类' visible={true}
                    data={{ action: 'edit', url: '/show/showClass/updateShowClass', tableItem: tableItem }} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (updata) => {
        this.setState({ currentOpenMode: '', tableItem: {} }, () => {
            if (updata) this._searchHandle('useCache');
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    _renderUpdateLiveStatusMsg = () => {
        const { updateLiveStatus } = this.props;
        if (updateLiveStatus.status === 1) {
            message.success(this.props.updateLiveStatus.msg);
        } else if (updateLiveStatus.error === 0) {
            message.error(this.props.updateLiveStatus.msg);
        }
    }


    render() {
        const { type, typeName } = this.state;
        const { tableSource, classifyStatus } = this.props;

        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XSelect
                                label='分类状态'
                                placeholder='请选择分类状态'
                                renderData={classifyStatus}
                                bindThis={this}
                                bindName='type'
                                dataIndex='value'
                                keyIndex='code'
                                value={type.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='分类名称'
                                placeholder='请输入分类名称'
                                bindThis={this}
                                bindName='typeName'
                                value={typeName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }} label='创建视频分类' onClick={this._createStudioRoom} />
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={tableSource} tableAction={this._tableAction} />
                    {this._renderMode()}
                    {this._renderUpdateLiveStatusMsg()}
                </div>
            </KeepAlive>
        );
    }
}

Video.propTypes = {
    getVideoData: PropTypes.func,
    getLiveClassifyStatus: PropTypes.func,
    getLiveSort: PropTypes.func, // 通过Saga异步请求表格数据源的Action
    getLiveStatus: PropTypes.func,
    tableSource: PropTypes.object, // 表格数据源
    classifyStatus: PropTypes.array,
    updateLiveStatus: PropTypes.object,
};

const mapStateToProps = (state) => ({
    tableSource: state.channelclassification.videoData,
    classifyStatus: state.channelclassification.classifyStatus,
    updateLiveStatus: state.channelclassification.updateLiveStatus,
});

export default connect(mapStateToProps, { getVideoData, getLiveClassifyStatus, getLiveSort, getLiveStatus })(Video);