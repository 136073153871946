import React from 'react';
import styles from './TableData.module.scss';
import { tooltipStyle, tooltip } from '@/components/TableCell';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => ([
  {
    title: '商户账号',
    dataIndex: 'loginName',
    key: 'loginName',
    width: '10%',
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '流水号',
    dataIndex: 'id',
    key: 'id',
    width: '10%',
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '商户名称',
    dataIndex: 'merchantName',
    key: 'merchantName',
    width: '15%',
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '商户类型',
    dataIndex: 'merchantTypeValue',
    key: 'merchantTypeValue',
    width: '15%',
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '申请类型',
    dataIndex: 'merchantSourceValue',
    key: 'merchantSourceValue',
    width: '10%',
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '状态',
    dataIndex: 'merchantStateValue',
    key: 'merchantStateValue',
    width: '10%',
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '更新时间',
    dataIndex: 'gmtModified',
    key: 'gmtModified',
    width: '15%',
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '操作',
    dataIndex: 'id',
    key: 'merchantKey',
    align: 'center',
    width: '15%',
    render: (id, item) => TableAction(id, actionClickHandle, item)
  },
]);

export const takeDataSource = (data) => {
  // 解析表格数据，使符合columns格式要求
  return data.map((item) => {//
    let key = 0;
    item.merchantStateCode = item.merchantState.code;
    item.merchantStateValue = item.merchantState.value;
    item.merchanTypeCode = item.merchantType.code;
    item.merchantTypeValue = item.merchantType.value;
    item.merchantSourceCode = item.sourceType.code;
    item.merchantSourceValue = item.sourceType.value;
    item.merchantKey = key++;//操作key
    return item;
  });
};

export const takeColumns = (actionClickHandle) => {
  return columns(actionClickHandle);
};

const TableAction = (id, actionClickHandle, item) => {
  return (
    <div className={styles.action}>
      <div className={styles.item} onClick={() => actionClickHandle(id, 'MER_DETAIL', item)}>详情</div>
      {/* <div className={styles.item} onClick={() => actionClickHandle(id, 'MER_STOR')}>店铺</div> */}
      {item.accessType == 'THIRD_UNION_PAY' && <div className={styles.item} onClick={() => actionClickHandle(id, 'MER_AUDIT')}>审核</div>}
    </div >
  );
};