import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import ButtonPerssion from '@/routes/ButtonPerssion';

export default class TableContent extends Component {
	state = {
		selectedRowKeys: [],
	};
	static propTypes = {
		tableAction: PropTypes.func,
		paginationChange: PropTypes.func,
		renderData: PropTypes.object,
	};
	_columns = () => [
		{
			title: '公司编号',
			dataIndex: 'OCA1',
			key: 'OCA1',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '公司简称',
			dataIndex: 'OCA2',
			key: 'OCA2',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '联系人',
			dataIndex: 'OCA3',
			key: 'OCA3',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '联系电话',
			dataIndex: 'OCA4',
			key: 'OCA4',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '类型',
			dataIndex: 'OCA8.value',
			key: 'OCA8.value',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '有效期',
			dataIndex: 'OCA5',
			key: 'OCA5',
			align: 'center',
			width: '20%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '状态',
			dataIndex: 'OCA6',
			key: 'OCA6',
			align: 'center',
			width: '10%',
			render: (text) => (this._stateCell(text))
		},
		{
			title: '操作人',
			dataIndex: 'OCA7',
			key: 'OCA7',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'key',
			key: 'key',
			align: 'center',
			width: '10%',
			render: (key, record) => this._tableAction(key, record)
		},
	];
	_stateCell = (text) => {
		return tooltip(text ? text.value : '');
	}
	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		const { OCA6: { code = '' } = {} } = record;

		if (code === 'AUDIT') {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
					<ButtonPerssion code='auditmanage/operationCenter/cs'>
						<div className={styles.item} onClick={() => tableAction(key, 'audit', record)}>初审</div>
					</ButtonPerssion>
				</div>
			);
		} else if (code === 'FIRST_APPROVAL') {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
					<ButtonPerssion code='auditmanage/operationCenter/fs'>
						<div className={styles.item} onClick={() => tableAction(key, 'audit', record)}>复审</div>
					</ButtonPerssion>
				</div>
			);
		} else {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
				</div>
			);
		}

	};
	_tableData = (data) => {
		let newData = [];
		data.map(item => {
			let tableItem = {};
			tableItem.key = item.id;
			tableItem.OCA1 = item.companyCode;
			tableItem.OCA2 = item.companyName;
			tableItem.OCA3 = item.contactName;
			tableItem.OCA4 = item.contactTel;
			tableItem.OCA5 = `${item.agrtStartTime} ~ ${item.agrtEndTime}`;
			tableItem.OCA6 = item.companyState;
			tableItem.OCA7 = item.operation;
			tableItem.OCA8 = item.type;
			return newData.push(tableItem);
		});
		return newData;
	}
	render() {
		const { renderData, paginationChange } = this.props;
		const { selectedRowKeys } = this.state;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey={record => record.key}
					columns={this._columns()}
					pagination={false}
					dataSource={this._tableData(renderData.dataList)}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}