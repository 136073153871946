import React, { Component } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import TableBox from './components/TableBox';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/productmanage/operaterecord/model';
import { message } from 'antd';

class OperateRecord extends Component {

    static propTypes = {
        history: PropTypes.object,
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getOperateRecordList: PropTypes.func,
        operateRecordList: PropTypes.object,
    }

    static defaultProps = {
        operateRecordList: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }
    }

    constructor(props) {
        super(props);
        this.state = {
            goodsNoOrName: '',
            createUserName: '',
            gmtStartCreate: null,
            gmtEndCreate: null,
            scrollY: '',
        };
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const {
            goodsNoOrName,
            createUserName,
            gmtStartCreate,
            gmtEndCreate,
            pageNum,
            pageSize
        } = this.state;
        this.searchCache = {
            goodsNoOrName,
            createUserName,
            gmtStartCreate,
            gmtEndCreate,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getOperateRecordList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            goodsNoOrName: '',
            createUserName: '',
            gmtStartCreate: null,
            gmtEndCreate: null,
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { operateRecordList } = this.props;
        const {
            goodsNoOrName,
            createUserName,
            gmtStartCreate,
            gmtEndCreate,
            pageNum,
            pageSize
        } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商品信息'
                                placeholder='请输入货号或商品名称'
                                bindThis={this}
                                bindName='goodsNoOrName'
                                value={goodsNoOrName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='操作人'
                                placeholder='请输入操作人'
                                bindThis={this}
                                bindName='createUserName'
                                value={createUserName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='操作日期'
                                value={gmtStartCreate}
                                bindThis={this}
                                bindName='gmtStartCreate'
                                isFormat={true}
                            />
                            <XDatePicker
                                label=''
                                style={{ marginRight: '20px' }}
                                value={gmtEndCreate}
                                bindThis={this}
                                bindName='gmtEndCreate'
                                isFormat={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: 120 }}
                            label='导出EXCEL'
                            onClick={() => {
                                const data = {
                                    goodsNoOrName,
                                    createUserName,
                                    gmtStartCreate,
                                    gmtEndCreate,
                                    pageNum,
                                    pageSize
                                };
                                http('/wholesalegoods/operatelog/wholesaleGoodsOperateLogExportExcel', data, 'POST')
                                    .then(res => {
                                        window.open(res.result);
                                    })
                                    .catch(err => {
                                        message.error(err.message);
                                    });
                            }} />
                    </div>
                    <TableBox
                        paginationChange={this._paginationChange}
                        renderData={operateRecordList}
                    />
                </div>
            </KeepAlive>
        );
    }
}

export default connect(state => ({
    operateRecordList: state.operateRecord.operateRecordList,
}), {
    keepSecondNavBreadcrumb,
    ...model.actions
})(OperateRecord);