/**
 *  区域公司列表
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class CompanyList extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value, name) => {
        const { history, addOneBreadcrumbPath } = this.props;
        const jumpage = (title, url) => {
            const path = `/home/operationcenter/companyList${url}`;
            addOneBreadcrumbPath({ title, path });
            history.push({ pathname: path });
        };
        const urls = {
            CREATE: () => jumpage('新建区域公司', '/create'),
            EDIT: () => jumpage('编辑区域公司', `/edit/${value}`),
            DETAIL: () => jumpage('区域公司详情', `/detail/${value}`),
            budget: () => jumpage(`${name}的收支明细`, `/budget/${value}`),
        };
        urls[mode]();
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

CompanyList.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 新增一级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保留两级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(CompanyList);