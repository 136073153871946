import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/auditmanage/channelaudit/model';

const { getPositiveAuditData, getStateEnum } = model.actions;

const PositiveAudit = ({
    history,
    getPositiveAuditData,
    tableData: {
        dataList = [],
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0
    } = {},
    getStateEnum,
    stateEnum,
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb
}) => {

    const jumpPage = (path, title) => {
        const baseUrl = '/home/auditmanage/channelaudit';
        addOneBreadcrumbPath({ title, path: `${baseUrl}${path}` });
        history.push({ pathname: `${baseUrl}${path}` });
    };

    const tableOperate = (id, { auditState: { code } = {} }) => {
        return <div className={styles.action}>
            {code === 'NOT_AUDIT' ?
                <div className={styles.item} onClick={() => jumpPage(`/positiveaudit/${id}`, '审核')}>审核</div> :
                <div className={styles.item} onClick={() => jumpPage(`/positivedetail/${id}`, '详情')}>详情</div>}
        </div>;
    };

    const columns = [
        {
            title: '渠道账号',
            dataIndex: 'accountName',
            key: 'accountName',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '联系邮箱',
            dataIndex: 'contactMail',
            key: 'contactMail',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '联系人',
            dataIndex: 'contact',
            key: 'contact',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '营业执照',
            dataIndex: 'bizLicenseUrl',
            key: 'bizLicenseUrl',
            align: 'center',
            onCell: tooltipStyle,
            render: ImgCell
        },
        {
            title: '审核人',
            dataIndex: 'auditor',
            key: 'auditor',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '审核时间',
            dataIndex: 'gmtAudit',
            key: 'gmtAudit',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'auditState.value',
            key: 'auditState.value',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            onCell: tooltipStyle,
            render: tableOperate
        }
    ];

    const [accountName, setAccountName] = useState();
    const [contact, setContact] = useState();
    const [stateItem, setStateItem] = useState({});
    const [cPageNum, setCPageNum] = useState(autoPageNum);
    const [cPageSize, setCPageSize] = useState(autopageSize);

    useEffect(() => {
        keepSecondNavBreadcrumb();
        getStateEnum();
    }, []);

    const getTableData = (params = {}) => {
        getPositiveAuditData({
            accountName: params.accountName,
            contact: params.contact,
            auditState: params.stateItem && params.stateItem.code,
            pageNum: params.pageNum, pageSize: params.pageSize
        });
        KeepAlive.saveCache(params);
    };

    const resetSearch = () => {
        setAccountName();
        setContact();
        setStateItem({});
        KeepAlive.saveResetFunc(() => getTableData());
    };


    const paginationChange = (ps, pn) => {
        setCPageNum(pn);
        setCPageSize(ps);
        getTableData({ accountName, contact, stateItem, pageNum: pn, pageSize: ps });
    };

    const onChange = (p = {}) => {
        if (p.accountName) setAccountName(p.accountName);
        if (p.contact) setContact(p.contact);
        if (p.stateItem) setStateItem(p.stateItem);
        if (p.pageNum != autoPageNum) setCPageNum(p.pageNum);
        if (p.pageSize != autopageSize) setCPageSize(p.pageSize);
        getTableData(p);
    };

    return <KeepAlive id='positiveAudit' render={onChange}>
        <div className={styles.flexBoxContainer}>
            <SearchBox>
                <SearchItem>
                    <XInput
                        label='渠道账号'
                        placeholder='请输入渠道账号'
                        bindThis={setAccountName}
                        bindName='accountName'
                        value={accountName}
                    />
                </SearchItem>
                <SearchItem>
                    <XInput
                        label='联系人'
                        placeholder='请输入联系人'
                        bindThis={setContact}
                        bindName='contact'
                        value={contact}
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        label='状态'
                        placeholder='请选择状态'
                        renderData={stateEnum}
                        bindThis={setStateItem}
                        bindName='stateItem'
                        dataIndex='value'
                        keyIndex='code'
                        value={stateItem.value}
                    />
                </SearchItem>
                <SearchItem>
                    <XOKButton
                        label='查询'
                        onClick={() => getTableData({ accountName, contact, stateItem, pageNum: cPageNum, pageSize: cPageSize })}
                    />
                    <XCancelButton
                        label='重置'
                        onClick={resetSearch}
                    />
                </SearchItem>
            </SearchBox>
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey='id'
                    columns={columns}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        </div>
    </KeepAlive>;
};

PositiveAudit.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getPositiveAuditData: PropTypes.func,
    tableData: PropTypes.object,
    getStateEnum: PropTypes.func,
    stateEnum: PropTypes.array
};

export default connect(state => ({
    tableData: state.channelaudit.positiveAuditData,
    stateEnum: state.channelaudit.stateEnum
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getPositiveAuditData,
    getStateEnum
})(PositiveAudit);