// 音乐分类 下拉
export const GET_MUSICCLASS_SELECTSOURCE = 'MUSIC/GET_MUSICCLASS_SELECTSOURCE';
export const SET_MUSICCLASS_SELECTSOURCE = 'MUSIC/SET_MUSICCLASS_SELECTSOURCE';

// 音乐分类 表格
export const GET_MUSICTYPE_TABELSOURCE = 'MUSIC/GET_MUSICTYPE_TABELSOURCE';
export const SET_MUSICTYPE_TABELSOURCE = 'MUSIC/SET_MUSICTYPE_TABELSOURCE';

// 音乐列表 表格
export const GET_MUSICLIST_TABELSOURCE = 'MUSIC/GET_MUSICLIST_TABELSOURCE';
export const SET_MUSICLIST_TABELSOURCE = 'MUSIC/SET_MUSICLIST_TABELSOURCE';

