/**
 * 查看协议
 */
import React from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

const Licenses = ({ data = '', closeMode }) => {

    const footerStyle = {
        width: '100%',
    };

    const FooterNode = <div style={footerStyle}>
        <Button type='primary' onClick={closeMode}>关闭</Button>
    </div>;

    return <Modal
        width={1200}
        bodyStyle={{ padding: '20px' }}
        centered
        title='查看协议'
        visible={true}
        confirmLoading={false}
        onCancel={closeMode}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        footer={FooterNode}
    >
        <div style={{ width: '100%', height: '600px' }}>
            <iframe src={data} width='100%' height='100%'>
                您的浏览器暂不支持iframe标签，请升级浏览器或者使用谷歌浏览器
            </iframe>
        </div>
    </Modal>;
};
Licenses.propTypes = {
    data: PropTypes.string,
    closeMode: PropTypes.func
};
export default Licenses;