/**
 *  营销活动商品分组
 * */ 
import React from 'react';
import PropTypes from 'prop-types'; 
import Main from './pages/main';

class ProductGroup extends React.Component {

    render(){
        return(
            <Main /> 
        );
    }
}

ProductGroup.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
};

export default ProductGroup;