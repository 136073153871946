/**
 * 设置兑换条件
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message, Radio, InputNumber } from 'antd';
import styles from './Conditions.module.scss';
import { XSelect } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import model from '@/reducers/starvalueshop/starshop/model';
import { regYuanToFen, regFenToYuan } from '@/util/money';

const RadioGroup = Radio.Group;

// 兑换限购
const selectDataSource = [
    { id: '0', name: '不限' },
    { id: '1', name: '每人限兑' },
    { id: '2', name: '每人每天限兑' },
];

// 兑换方式
const convertWayList = [
    { code: 'SMAORAGE_YOUXUAN_INTEGRAL', value: '云仓积分' },
    { code: 'SMAORAGE_YOUXUAN_INTEGRAL_CASH', value: '云仓积分+现金' },
    { code: 'STAR', value: '人气值兑换' },
    { code: 'STAR_CASH', value: '人气值+现金' },
];

// 兑换券
const cardList = [
    { code: 1, value: '支持' },
    { code: 0, value: '不支持' },
];


class Conditions extends React.PureComponent {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,

        convertWay: (this.props.data.convertMode || {}).code ? this.props.data.convertMode.code : 'SMAORAGE_YOUXUAN_INTEGRAL', // 兑换方式
        starNumber: this.props.data.starNumber != '0' ? this.props.data.starNumber : undefined, // 兑换数值
        cash: regFenToYuan(this.props.data.cash) ? regFenToYuan(this.props.data.cash) : undefined, // 金额数值

        astrict: this.props.data.convertLimit == '0' ? { id: '0', name: '不限' } : (this.props.data.convertLimit == '1' ? { id: '1', name: '每人限兑' } : { id: '2', name: '每人每天限兑' }),
        astrictNum: this.props.data.limitQuanity != '0' ? this.props.data.limitQuanity : undefined, // 兑换限制件数
        conditions: this.props.data.convertScope.toString() ? this.props.data.convertScope : 0, // 兑换条件

        cardVoucher: this.props.data.supportVouchers,// 支持兑换券兑换
    }
    componentDidMount() {
        const { getAccountLevel } = this.props;
        getAccountLevel();//会员等级
    }
    // 点击确认
    _handleOk = () => {
        const { conditions, astrict, astrictNum, convertWay, starNumber, cash, cardVoucher } = this.state;
        const { ctrId, goodsId, id } = this.props.data;

        if (astrict.id != '0') {//兑换限制
            if (!astrictNum) return message.warn('请输入兑换限制件数。');
        }

        if (!starNumber) return message.warn('请输入兑换数值');

        if (!cash) return message.warn('请输入现金金额');
        
        let haveCash = true;
        if(convertWay == 'SMAORAGE_YOUXUAN_INTEGRAL' || convertWay == 'STAR'){
            haveCash = false;
        }

        let submitData = {
            ctrId,
            goodsId,
            id,
            convertMode: convertWay, // 兑换方式
            starNumber: starNumber, // 兑换数值
            cash: haveCash ? regYuanToFen(cash) : undefined, // 金额数值

            convertLimit: astrict.id, // 兑换限制
            limitQuanity: astrict.id != '0' ? astrictNum : 0, // 兑换限制件数

            convertScope: conditions, // 兑换条件
            supportVouchers: cardVoucher, // 支持兑换券兑换
        };

        this.setState({ confirmLoading: true }, () => {
            http('/star/good/convert/set', submitData, 'POST').then(() => {
                message.success('兑换条件设置成功。');
                this.setState({
                    visible: false,
                    confirmLoading: false
                });
                this.props.closeMode(true);
            }).catch((reject = {}) => {
                message.error(reject.message);
                this.setState({
                    confirmLoading: false
                });
            });
        });
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    //兑换条件  
    _conditionsChange = e => {
        this.setState({
            conditions: e.target.value,
        });
    };

    //兑换限制  
    _astrict = e => {
        this.setState({
            astrict: e
        });
    };

    //兑换限制件数  
    _astrictNum = (value) => {
        this.setState({ astrictNum: value });
    }

    //兑换方式
    _convertWayChange = e => {
        this.setState({
            convertWay: e.target.value
        });

        if (e.target.value == 'SMAORAGE_YOUXUAN_INTEGRAL' || e.target.value == 'SMAORAGE_YOUXUAN_INTEGRAL_CASH') {
            this.setState({
                conditions: '',
                cardVoucher: ''
            });
        }
    };

    // 兑换数值  
    _starNumber = (value) => {
        this.setState({ starNumber: value });
    }

    // 金额数值  
    _cash = (value) => {
        this.setState({ cash: value });
    }

    // 兑换券兑换
    _cardListChange = e => {
        this.setState({
            cardVoucher: e.target.value,
        });
    };

    render() {
        const { visible, confirmLoading, title, conditions, astrict, astrictNum, convertWay,
            starNumber, cash, cardVoucher,
        } = this.state;
        const { memberLevel } = this.props;
        return (
            <Modal
                width={800}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.conditionsLay}>
                        <div className={styles.condition}>
                            <span className={styles.label}>兑换方式：</span>
                            <RadioGroup onChange={this._convertWayChange} value={convertWay}>
                                {convertWayList.map((items) => (<Radio key={items.code} value={items.code}>{items.value}</Radio>))}
                            </RadioGroup>
                        </div>
                        <div className={styles.convertWay}>
                            <span>兑换数值：</span>
                            <InputNumber
                                style={{ width: '150px' }}
                                precision={0}
                                min={1}
                                step={1}
                                value={starNumber}
                                placeholder='请输入兑换数值'
                                onChange={this._starNumber}
                            />
                        </div>
                        {
                            (convertWay == 'SMAORAGE_YOUXUAN_INTEGRAL_CASH' || convertWay == 'STAR_CASH') &&
                            <div className={`${styles.convertWay} ${styles.rection}`}>
                                <div className={styles.convertWaylout}>
                                    <span>现金金额：</span>
                                    <InputNumber
                                        style={{ width: '150px' }}
                                        precision={2}
                                        min={0}
                                        step={1}
                                        value={cash}
                                        placeholder='请输入现金金额(元)'
                                        onChange={this._cash}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div className={styles.conditionsLay}>
                        <div className={styles.condition}>
                            <span className={styles.label}>兑换限制：</span>
                            <XSelect
                                style={{ marginLeft: '-10px' }}
                                selectStyle={{ width: '150px' }}
                                placeholder='请选择'
                                renderData={selectDataSource}
                                dataIndex='name'
                                keyIndex='id'
                                value={astrict.name}
                                showSearch={true}
                                onChange={this._astrict}
                            />
                            {
                                astrict.id != '0' &&
                                <div className={styles.astrictlay}>
                                    <InputNumber
                                        style={{ margin: '0 5px 0 12px' }}
                                        precision={0}
                                        min={1}
                                        step={1}
                                        value={astrictNum}
                                        onChange={this._astrictNum}
                                        placeholder='请输入'
                                    />
                                    <span>件/人</span>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        (convertWay == 'STAR' || convertWay == 'STAR_CASH') ?
                            <>
                                <div className={styles.conditionsLay}>
                                    <div className={styles.condition}>
                                        <span className={styles.label}>兑换条件：</span>
                                        <RadioGroup onChange={this._conditionsChange} value={conditions}>
                                            {memberLevel.length > 0 && memberLevel.map((items) => (<Radio key={items.levelValue} value={items.levelValue}>{items.levelName}</Radio>))}
                                        </RadioGroup>
                                    </div>
                                    <p className={styles.Plabel}>注：向上兼容，选中的会员等级及以上会员等级均可兑换</p>
                                </div>
                                <div className={styles.conditionsLay}>
                                    <div className={styles.condition}>
                                        <span className={styles.label}>支持兑换券兑换：</span>
                                        <RadioGroup onChange={this._cardListChange} value={cardVoucher}>
                                            {cardList.map((items) => (<Radio key={items.code} value={items.code}>{items.value}</Radio>))}
                                        </RadioGroup>
                                    </div>
                                </div>
                            </>
                            : null
                    }
                </div>
            </Modal>
        );
    }
}


Conditions.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    memberLevel: PropTypes.array, // 会员等级
    getAccountLevel: PropTypes.func,//会员等级action
};

const mapStateToProps = (state) => ({
    memberLevel: state.starShop.memberLevel,
});

export default connect(mapStateToProps, { ...model.actions })(Conditions);