/**
 * 服务列表
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { message } from 'antd';
import styles from './index.module.scss';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import ProductClassifyModal from '../create/modal/ProductClassifyModal';
import Fail from '../modal/Fail';
// import { setLocalStorage, setSessionStorage } from '@/assets/js/storage';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/servicemanage/servicelist/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { removeSessionStorage } from '@/assets/js/storage';

class Main extends Component {
    state = {
        currentOpenMode: '', // 当前弹窗
        openModeParam: {}, // 当前弹窗入参
        goodsNo: '',
        goodsName: '',
    };

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { goodsNo, goodsName, pageNum, pageSize } = this.state;
        this.searchCache = {
            goodsNo,
            goodsName,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getServiceGoodsPage(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    };

    // 重置
    _resetHandle = () => {
        this.setState({ goodsNo: '', goodsName: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    };

    // 创建
    _createService = () => {
        removeSessionStorage('recordServiceInfolist');
        removeSessionStorage('recordServiceInfo');
        this.setState({ currentOpenMode: 'CREATE' });
    };

    // 表格操作
    _tableAction = (id, type, params) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '服务详情',
                    path: `/home/operationcenter/servicelist/detail/${id}`,
                });
                history.push({
                    pathname: `/home/operationcenter/servicelist/detail/${id}`,
                });
                break;
            case 'edit':
                removeSessionStorage('recordServiceInfolist');
                removeSessionStorage('recordServiceInfo');
                if (params.goodsStateCode === 'PULL_OFF_SHELVES') {
                    addOneBreadcrumbPath({
                        title: '编辑服务',
                        path: `/home/operationcenter/servicelist/edit/${id}`,
                    });
                    history.push({
                        pathname: `/home/operationcenter/servicelist/edit/${id}`,
                    });
                } else {
                    message.warn('请下架后再编辑');
                }
                break;
            case 'delete':
                showConfirm('您确定要删除当前记录么？', '', () => {
                    http('/goods/goods/delGoods', { goodsId: [id] }, 'POST')
                        .then(() => {
                            this._searchHandle('useCache');
                            message.success('删除成功。');
                        }).catch((error) => {
                            message.error(error.message);
                        });
                });
                break;
            case 'on':
                showConfirm('亲，确认上架此商品吗？', ' 确认就上架', () => {
                    http('/goods/goods/upGoodsState', { action: 'PUT_ON_SALE', sceneType: 'SCENE_TYPE_SERVICE', goodsIds: [id] }, 'POST')
                        .then(res => {
                            this._searchHandle('useCache');
                            message.success(res.message);
                        }).catch((error) => {
                            message.error(error.message);
                        });
                });
                // setSessionStorage('goodsIds', [id]);
                // http('/goods/goods/checkSettlePrice', { goodsIds: [id] }, 'POST').then((response) => {
                //     if (response.status == 200) {
                //         addOneBreadcrumbPath({
                //             title: '上架确认服务清单',
                //             path: '/home/operationcenter/servicelist/ratemodification/'
                //         });
                //         history.push({
                //             pathname: '/home/operationcenter/servicelist/ratemodification/'
                //         });
                //     } else if (response.status == 201) {
                //         this.setState({ currentOpenMode: 'FAIL_TABLE', modeParams: response.result });
                //     } else {
                //         message.error(response.message);
                //     }
                // }).catch((e) => {
                //     message.error(e.message);
                // });
                break;
            case 'off':
                var toast = '';
                if ((params.goodsType && params.goodsType === 'LIVE_OPEN') || (params.goodsType && params.goodsType === 'CUSTOMER_SEAT')) {
                    toast = '您确定要下架操作么？下架后客户端将不再显示该服务，请慎重操作！';
                } else {
                    toast = '您确定要下架操作么？';
                }
                showConfirm(toast, '', () => {
                    http('/goods/goods/upGoodsState', { action: 'PULL_OFF_SHELVES', sceneType: 'SCENE_TYPE_SERVICE', goodsIds: [id] }, 'POST')
                        .then(() => {
                            message.success('下架成功');
                            this._searchHandle('useCache');
                        }).catch((e) => {
                            message.error(e.message);
                        });
                });
                // this._sendRequest('/goods/goods/upGoodsState', { action: 'PULL_OFF_SHELVES', sceneType: 'SCENE_TYPE_SERVICE', goodsIds: [id] }, '下架成功。', '下架失败！');
                break;
        }
    };
    _sendRequest = (url, data, winTxt) => {
        http(url, data, 'POST')
            .then(() => {
                message.success(winTxt);
                this._searchHandle('useCache');
            }).catch((e) => {
                message.error(e.message);
            });
    };

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    };
    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { modeParams } = this.state;
        switch (this.state.currentOpenMode) {
            case 'CREATE':
                return <ProductClassifyModal visible={true} closeMode={this._closeMode} />;
            case 'FAIL_TABLE':
                return <Fail visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    };
    // 关闭弹窗
    _closeMode = (type, prodCascadeDetail) => {
        const { history, addOneBreadcrumbPath } = this.props;
        if (type === 'ok') {
            this.setState({ productClassifyDetail: prodCascadeDetail });
            const name_one = prodCascadeDetail[0].categoryName;
            const name_two = prodCascadeDetail[1].categoryName;
            const name_three = prodCascadeDetail[2].categoryName;
            const temple_id = prodCascadeDetail[2].id;
            addOneBreadcrumbPath({
                title: '新建服务',
                path: {
                    pathname: '/home/operationcenter/servicelist/create',
                    search: `?name_one=${name_one}&&name_two=${name_two}&&name_three=${name_three}&&id=${temple_id}`
                }
            });
            history.push({
                pathname: '/home/operationcenter/servicelist/create',
                search: `?name_one=${name_one}&&name_two=${name_two}&&name_three=${name_three}&&id=${temple_id}`
            });
        }


        this.setState({ currentOpenMode: '' });
    };
    render() {
        const { serviceList } = this.props;
        const { goodsNo, goodsName } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='服务编号'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='goodsNo'
                                value={goodsNo}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='服务名称'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='goodsName'
                                value={goodsName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <XOKButton style={{ width: 100, marginTop: 10 }} label='新建服务' onClick={this._createService} />
                    <TableContent paginationChange={this._paginationChange} renderData={serviceList} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }

}
Main.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    getServiceGoodsPage: PropTypes.func,
    serviceList: PropTypes.object,

};
const mapStateToProps = (state) => ({
    serviceList: state.serviceProduct.serviceList,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);