/**
 * 核对完结
 */
import React from 'react';
import { Modal, message, Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './CheckFinishModal.module.scss';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import liwu_model from '@/reducers/operationcenter/liwu/model';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell } from '@/components/TableCell';

class CheckFinishModal extends React.Component {
    state = {
        confirmLoading: false
    };

    componentDidMount() {
        const { id } = this.props.param;
        this.props.getStockReconItemList({ stockReconId: id });
        this.props.getStockRecon({ id });
    }

    _handleOk = () => {
        this.setState({ confirmLoading: true });
        const { id } = this.props.param;
        http('/settle/stock/completedStockRecon', { id }).then((response) => {
            debugger;
            this.setState({ confirmLoading: false });
            this.props.close('update');
        }).catch((error) => {
            message.error(error.message);
            this.setState({ confirmLoading: false });
        });
    }

    _handleCancel = () => {
        this.props.close();
    }

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _takeColumns = () => ([
        {
            title: '业务员',
            dataIndex: 'realName',
            key: 'realName',
            width: '10%',
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '手机号码',
            dataIndex: 'mobilePhone',
            key: 'mobilePhone',
            width: '10%',
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '身份证号',
            dataIndex: 'idCard',
            key: 'idCard',
            width: '15%',
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '业务账号',
            dataIndex: 'accountNo',
            key: 'accountNo',
            width: '10%',
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '已核定业绩(元)',
            dataIndex: 'orderAmountTotal',
            key: 'orderAmountTotal',
            width: '10%',
            align: 'left',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '已核定金额(元)',
            dataIndex: 'checkAmountTotal',
            key: 'checkAmountTotal',
            width: '10%',
            align: 'left',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
    ]);

    render() {
        const { confirmLoading } = this.state;
        const { stockReconItemList, stockRecon } = this.props;

        return (
            <Modal
                width={900}
                centered
                title='核对完结'
                visible={true}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认完结'
            >
                <div className={styles.container}>
                    <div className={styles.box}>
                        <div className={styles.col}>
                            <div className={styles.item}>
                                <div className={styles.label}>已核定订单数</div><div className={styles.value}>{stockRecon.checkOrderQty}</div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.label}>已核定业绩</div><div className={styles.value}>{regFenToYuan(stockRecon.checkOrderAmount)}</div>
                            </div>
                        </div>
                        <div className={styles.col}>
                            <div className={styles.item}>
                                <div className={styles.label}>已核定业务员</div><div className={styles.value}>{stockRecon.checkBuyerQty}</div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.label}>已核定总额</div><div className={styles.value}>{regFenToYuan(stockRecon.checkAmount)}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.tableContainer} style={{ height: '100%' }}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record) => record.id}
                            columns={this._takeColumns()}
                            dataSource={this._takeDataSource(stockReconItemList)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                    </div>
                </div>
            </Modal>
        );

    }
}

CheckFinishModal.propTypes = {
    close: PropTypes.func.isRequired, // 关闭弹框的回调函数
    param: PropTypes.object,
    getStockReconItemList: PropTypes.func, // 获取业绩列表
    stockReconItemList: PropTypes.object, // 业绩列表
    getStockRecon: PropTypes.func,
    stockRecon: PropTypes.object,
};

const mapStateToProps = (state) => ({
    stockReconItemList: state.liwu.getStockReconItemList,
    stockRecon: state.liwu.getStockRecon,
});

export default connect(mapStateToProps, { ...liwu_model.actions })(CheckFinishModal);
