/**
 *  商户批发商品免审核 
 * */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, message, Switch } from 'antd';
import http from '@/assets/api/http';
import ChooseModal from './modal/ChooseModal';
import { XOKButton, XPagination, XTableSelectionCount, showConfirm, XSelect, XCancelButton } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import PopconfirmModal from './modal/PopconfirmModal';
import DeletefirmModal from './modal/DeletefirmModal';
import model from '@/reducers/systemmanage/merchantpermission/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class GoodsAudit extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],//表格选中数据的key
            selectedRows: [],//选中数据
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            whiteList: false,//是否开启白名单
            merchant: {}, // 商户

            pageNum: 1,
            pageSize: 30,
        };
    }

    componentDidMount() {
        this._searchHandle();
        http('/admin/param/getParamValueByCode/IS_OPEN_WHOLESALE_GOODS_REVIEW', {}, 'POST')//是否关闭批发商品免审核
            .then((res = {}) => {
                this.setState({ whiteList: res.result == '1' ? true : false });
            }).catch((reject = {}) => {
                message.error(reject.message);
            });
        this.props.getMerchants();
    }

    //查询
    _searchHandle = () => {
        const { pageNum, pageSize, merchant } = this.state;
        this.searchCache = {
            pageNum,
            pageSize,
            merchantId: merchant.merchantId,
        };
        this.props.getGoodsAuditList(this.searchCache);
    }

    // 重置
    _resetHandle = () => {
        this.setState({ merchant: {} }, () => {
            this._searchHandle();
        });
    }

    // 表格操作功能回调  
    _actionClickHandle = (id, type, item) => {
        const { status = {} } = item;
        switch (type) {
            case 'START_SERVICE'://启用
            case 'STOP_SERVICE'://禁用
                this.setState({
                    currentOpenMode: 'batchdisOrendis',
                    openModeParam: {
                        merchantNames: [item.merchantName],
                        ids: [id],
                        state: status.code
                    },
                });
                break;
            case 'DELETE'://删除
                this.setState({
                    currentOpenMode: 'batchdel',
                    openModeParam: {
                        merchantNames: [item.merchantName],
                        ids: [id]
                    },
                });
                break;
            default: return null;
        }
    }

    // 打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'choose'://选择商户
                return <ChooseModal title='选择商户' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'batchdisOrendis'://批量禁用、批量启用
                return <PopconfirmModal data={this.state.openModeParam} visible={true} closeMode={this._closeMode} />;
            case 'batchdel'://批量删除
                return <DeletefirmModal data={this.state.openModeParam} visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        if (update) {
            this.props.getGoodsAuditList(this.searchCache);
            this._cleanAllSelected();
        }
        this.setState({ currentOpenMode: '' });
    }

    //选择商户 
    chooseHandle = () => {
        this.setState({
            currentOpenMode: 'choose',
        });
    }
    //批量禁用 
    batchdisHandle = () => {
        if (this.validationBatch()) {
            let merchants = this.state.selectedRows.map((v) => v.merchantName);
            this.setState({
                currentOpenMode: 'batchdisOrendis',
                openModeParam: {
                    merchantNames: merchants,
                    ids: this.state.selectedRowKeys,
                    state: 1
                },
            });
        }
    }
    //批量启用 
    batchenadisHandle = () => {
        if (this.validationBatch()) {
            let merchants = this.state.selectedRows.map((v) => v.merchantName);
            this.setState({
                currentOpenMode: 'batchdisOrendis',
                openModeParam: {
                    merchantNames: merchants,
                    ids: this.state.selectedRowKeys,
                    state: 0
                },
            });
        }
    }
    //批量删除 
    batchdelHandle = () => {
        if (this.validationBatch()) {
            let merchants = this.state.selectedRows.map((v) => v.merchantName);
            this.setState({
                currentOpenMode: 'batchdel',
                openModeParam: {
                    merchantNames: merchants,
                    ids: this.state.selectedRowKeys,
                },
            });
        }
    }

    //批量操作验证
    validationBatch = () => {
        const { selectedRowKeys } = this.state;
        if (selectedRowKeys.length === 0) {
            message.warning('请先勾选数据');
            return false;
        }
        return true;
    }


    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            //处理分页问题Rows无法同步
            let rows = this.state.selectedRows.concat(selectedRows);
            let filterRows = [...new Set(rows)].filter((v) => selectedRowKeys.includes(v.id));
            this.setState({ selectedRowKeys, selectedRows: filterRows });
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [], selectedRows: [] });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.props.getGoodsAuditList({ ...this.searchCache, pageSize, pageNum });
    }

    //是否关闭白名单
    whiteList = (value) => {
        showConfirm(`重要信息！${value === false ? '关闭部分商户批发商品免审核，启动全部商户批发商品免审核。' : '开启部分商户批发商品免审核清单需要配置指定商户。'}`, '请再次确认后完成操作', () => {
            http('/admin/param/batchUpdateParam', [{
                paramValue: value === false ? 0 : 1,
                paramCode: 'IS_OPEN_WHOLESALE_GOODS_REVIEW'
            }], 'POST')
                .then(() => {
                    this.setState({ whiteList: value });
                    message.success('修改成功');
                }).catch((reject = {}) => {
                    message.error(reject.message);
                });
        });
    }

    render() {
        const { dataList, pageNum, pageSize, resultTotal } = this.props.goodsAuditList;
        const { merchantIdAndNames } = this.props.merchants;

        const { selectedRowKeys, whiteList, merchant, } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;

        return (
            <div id="autoheight" className={styles.flexBoxContainer}>
                <div className={styles.payTop}>
                    <div className={styles.top_title}>
                        <span>基础设置</span>
                    </div>
                    <div className={styles.riskcontent}>
                        <div className={styles.item}>
                            <h4>开启部分商户批发商品免审核</h4>
                            <p>控制部分商户批发商品免审核是否生效（如关闭将全部商户批发商品免审核）</p>
                            <div>
                                <Switch
                                    checkedChildren='NO'
                                    unCheckedChildren='OFF'
                                    checked={whiteList}
                                    onChange={this.whiteList} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.top_title}>
                    <span>批发商品审核设置</span>
                </div>
                <div style={{ marginTop: '10px' }}>
                    <p>选择商户上传批发商品不需要平台审核，默认自动上架</p>
                    <div className={styles.auditFlex} style={{ margin: '10px 0 5px 0' }}>
                        <SearchBox>
                            <SearchItem>
                                <XOKButton onClick={this.chooseHandle} style={{ width: '100px', marginRight: '20px' }} label='选择商户' />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton onClick={this.batchdisHandle} style={{ width: '100px', marginRight: '20px' }} label='批量禁用' />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton onClick={this.batchenadisHandle} style={{ width: '100px', marginRight: '20px' }} label='批量启用' />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton onClick={this.batchdelHandle} style={{ width: '100px', marginRight: '50px' }} label='批量删除' />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='商户名称'
                                    placeholder='请选择'
                                    renderData={merchantIdAndNames}
                                    dataIndex='merchantName'
                                    keyIndex='merchantId'
                                    value={merchant.merchantName}
                                    bindThis={this}
                                    bindName='merchant'
                                    showSearch={true}
                                    isOptionBindID={true}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                </div>
                <div className={styles.tableContainer}>
                    <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        rowKey={record => record.id}
                        rowSelection={this._rowSelection}
                        columns={takeColumns(this._actionClickHandle)}
                        dataSource={takeDataSource(dataList)}
                        pagination={false}
                        scroll={{ y: true }}
                    />
                    <XPagination
                        resultTotal={resultTotal}
                        pageSize={pageSize}
                        pageNum={pageNum}
                        onChange={this._paginationChange} />
                </div>
                {this._renderMode()}
            </div>
        );
    }
}
GoodsAudit.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    getGoodsAuditList: PropTypes.func,//列表
    goodsAuditList: PropTypes.object,//列表
    getMerchants: PropTypes.func, // 获取商户下拉列
    merchants: PropTypes.object, // 商户下拉列
};

const mapStateToProps = (state) => {
    return {
        goodsAuditList: state.merchantpermission.goodsAuditList,
        merchants: state.merchantpermission.merchants,
    };
};

export default connect(mapStateToProps, { ...model.actions })(GoodsAudit);