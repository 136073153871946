/**
 *  【服务列表】入口页
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const FreeSheet = ({ history }) => {
    return <Main history={history} />;
};

FreeSheet.propTypes = {
    history: PropTypes.object
};

export default FreeSheet;