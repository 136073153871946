import React from 'react';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import StarsGift from '../starsgift';
import StarsGlist from '../starsglist';
import StarsBuy from '../starsbuy';
import ValueGift from '../starsvaluegift';
import ValueGlist from '../starsvalueglist';
import ValueBuy from '../starsvaluebuy';
import RewardConfig from '../rewardconfig';

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '魅力值礼物分类' },
            { id: 2, label: '魅力值礼物列表' },
            { id: 3, label: '人气值礼物分类' },
            { id: 4, label: '人气值礼物列表' },
            { id: 5, label: '魅力值购买记录' },
            { id: 6, label: '人气值购买记录' },
            { id: 7, label: '礼物打赏配置' },
        ],
        curTabID: 1
    }

    // Tab导航切换操作回调 
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        switch (this.state.curTabID) {
            case 1:
                return <StarsGift curTabID={this.state.curTabID} />; // 魅力值礼物分类
            case 2:
                return <StarsGlist curTabID={this.state.curTabID} />; // 魅力值礼物列表
            case 3:
                return <ValueGift curTabID={this.state.curTabID} />; // 人气值礼物分类
            case 4:
                return <ValueGlist curTabID={this.state.curTabID} />; // 人气值礼物列表
            case 5:
                return <StarsBuy curTabID={this.state.curTabID} />; // 魅力值购买记录
            case 6:
                return <ValueBuy curTabID={this.state.curTabID} />; // 人气值购买记录
            case 7:
                return <RewardConfig curTabID={this.state.curTabID} />; // 礼物打赏配置
            default:
                return <StarsGift curTabID={this.state.curTabID} />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={`${styles.flexBoxContainer} ${styles.page}`}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

export default Main;