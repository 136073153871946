import React from 'react';
import styles from './AddProductTable.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { priceFenToYuanCell, ImgCell } from '@/components/TableCell';

const columns = () => [
    {
        title: '商品图片',
        dataIndex: 'img',
        key: 'img',
        width: '13%',
        align: 'center',
        render: ImgCell
    },
    {
        title: '商品名称',
        dataIndex: 'name',
        key: 'name ',
        width: '13%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '商品品牌',
        dataIndex: 'brand',
        key: 'brand',
        width: '13%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '货号',
        dataIndex: 'number',
        key: 'number',
        align: 'center',
        width: '13%',
        render: (text) => (TableCell(text))
    },
    {
        title: '零售价',
        dataIndex: 'price',
        key: 'price',
        width: '13%',
        align: 'center',
        render: priceFenToYuanCell
    },
    {
        title: '商品分红',
        dataIndex: 'bonus',
        key: 'bonus',
        width: '13%',
        align: 'center',
        render: priceFenToYuanCell
    },
    {
        title: '商品分类',
        dataIndex: 'category',
        key: 'category',
        align: 'center',
        width: '13%',
        render: (text) => (TableCell(text))
    }
];

// 表格项内容为文本
const TableCell = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text}
        </div>
    );
};

class AddProductTable extends React.Component {

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        if (data && data.dataList) {
            const goods = [];
            data.dataList.map(item => {
                let good = {};
                good.img = item.mainImgUrl;
                good.name = item.goodsName;
                good.brand = item.brandName;
                good.number = item.goodsNo;
                good.price = item.salePrice;
                good.bonus = item.saleBonus;
                good.category = item.categoryName;
                good.key = item.goodsId + ',' + item.shopId;
                good.goodsId = item.goodsId;
                good.shopId = item.shopId;
                good.merchantId = item.merchantId;
                goods.push(good);
            });
            return goods;
        }
    }

    // 表格复选框操作回调
    _rowSelection = {
        onChange: (selectedkeys, selectedRows) => {
            this.props.tableAction(selectedRows);
        }
    };

    render() {
        const { renderData, paginationChange } = this.props;

        return (
            <div className={styles.container}>
                <div className={styles.table_box}>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        rowSelection={this._rowSelection}
                        rowKey={record => record.key}
                        columns={columns()}
                        dataSource={this._takeDataSource(renderData)}
                        pagination={false}
                        scroll={{ y: 350 }}
                    />
                </div>
                <div className={styles.pagination_box}>
                    <XPagination
                        resultTotal={renderData.resultTotal}
                        pageSize={renderData.pageSize}
                        pageNum={renderData.pageNum}
                        onChange={paginationChange} />
                </div>
            </div>
        );
    }
}

AddProductTable.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

export default AddProductTable;