import { createModel } from '@/store/tools';


const model = {
    namespace: 'starShop',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getRecordList': T('getRecordList'),
            'getAccountLevel': T('getAccountLevel'),
            'getstarvalueList': T('getstarvalueList'),
            'getSupplierList': T('getSupplierList'),
            'getstarvalueDtails': T('getstarvalueDtails'),
            'getConvertWays': T('getConvertWays'),

            'getStarGoodsConvertModeEnum': T('getStarGoodsConvertModeEnum'),
            'getOrderStatusEnum': T('getOrderStatusEnum'),
        },
        actions: {
            'getRecordList': A('getRecordList'), // 兑换记录列表数据  
            'getAccountLevel': A('getAccountLevel'), // 人气值商品列表-设置兑换条件会员等级
            'getstarvalueList': A('getstarvalueList'), // 人气值商品列表数据
            'getSupplierList': A('getSupplierList'), // 人气值商品列表-供应商下拉
            'getstarvalueDtails': A('getstarvalueDtails'), // 人气值商品列表-详情
            'getConvertWays': A('getConvertWays'), // 人气值商品兑换记录-兑换方式

            'getStarGoodsConvertModeEnum': A('getStarGoodsConvertModeEnum'), // 人气值商品兑换记录-兑换方式
            'getOrderStatusEnum': A('getOrderStatusEnum'), // 人气值商品兑换记录-兑换方式
        },
        sagas: {
            'getRecordList': S('getRecordList', '/star/good/convert/record'),
            'getAccountLevel': S('getAccountLevel', '/user/account/listAccountLevel'),
            'getstarvalueList': S('getstarvalueList', '/star/good/list'),
            'getSupplierList': S('getSupplierList', '/operating/supplier/supplierDropDown'),
            'getstarvalueDtails': S('getstarvalueDtails', '/star/good', 'POST', ['ctrId', 'goodsId']),
            'getConvertWays': S('getConvertWays', '/enum/StarGoodsConvertModeEnum'),

            'getStarGoodsConvertModeEnum': S('getStarGoodsConvertModeEnum', '/enum/StarGoodsConvertModeEnum'), // 兑换条件
            'getOrderStatusEnum': S('getOrderStatusEnum', '/enum/OrderStatusEnum'), // 兑换条件
        },
        reducers: {
            'recordList': R('getRecordList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'memberLevel': R('getAccountLevel', []),
            'starvalueList': R('getstarvalueList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'supplierList': R('getSupplierList', []),
            'starDtails': R('getstarvalueDtails', {}),
            'convertWays': R('getConvertWays', []),

            'getStarGoodsConvertModeEnum': R('getStarGoodsConvertModeEnum', []),
            'getOrderStatusEnum': R('getOrderStatusEnum', []),
        }
    })
};
export default createModel(model);