/**
 * 敏感词 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

//敏感词库表
export const getSensitiveWordInfo = function* (){
    yield takeEvery(T.GET_SENSITIVEWORD_INFO, function* requestData(action){
        try{
            let result = yield http('/sysConfig/sensitiveWord/findSensitiveWord', action.payload,'POST');
            yield put({type:T.SET_SENSITIVEWORD_INFO, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};
//敏感词库表19/11/12
export const getSensitiveWordInfos = function* (){
    yield takeEvery(T.GET_SENSITIVEWORD_INFOS, function* requestData(action){
        try{
            let result = yield http('/sysConfig/sensitiveWord/findSensitiveWordList', action.payload,'POST');
            yield put({type:T.SET_SENSITIVEWORD_INFOS, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};