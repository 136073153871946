import { createModel } from '@/store/tools';

const model = {
    namespace: 'memberupgradearea',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getMemberUpgradeareaTable': T('getMemberUpgradeareaTable'),
            'getMemberUpgradeareaDetail': T('getMemberUpgradeareaDetail'),
            'getStateEnum': T('getStateEnum'),
        },
        actions: {
            'getMemberUpgradeareaTable': A('getMemberUpgradeareaTable'),
            'getMemberUpgradeareaDetail': A('getMemberUpgradeareaDetail'),
            'getStateEnum': A('getStateEnum'),
        },
        sagas: {
            'getMemberUpgradeareaTable': S('getMemberUpgradeareaTable', '/user/giftAudit/auditList'),
            'getMemberUpgradeareaDetail': S('getMemberUpgradeareaDetail', '/user/giftAudit/auditDetail'),
            'getStateEnum': S('getStateEnum', '/enum/ApprovalState'),
        },
        reducers: {
            'memberUpgradeareaTable': R('getMemberUpgradeareaTable', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'memberUpgradeareaDetail': R('getMemberUpgradeareaDetail', { auditState: {} }),
            'stateEnum': R('getStateEnum', []),
        }
    })
};
export default createModel(model);