/**
 * [云打印机]
 * @author: Fu Xiaochun
 * @date: 2022-08-12 
 */

import React, { useState } from 'react';
import styles from './index.module.scss';
import ModelManage from './modelsManage';
import ReceiptType from './receiptType';

function CloudPrinter(){

    const [tabIndex, setTabIndex] = useState('1');

    return (
        <div className={styles.cloudPrinter}>
            <ul className={styles.leftSider}>
                <li className={tabIndex == '1' ? styles.active : null} onClick={()=>{setTabIndex('1');}}>机型管理</li>
                <li className={tabIndex == '2' ? styles.active : null} onClick={()=>{setTabIndex('2');}}>小票类型</li>
            </ul>
            <div className={styles.rightBody}>
                {tabIndex == '1' && <ModelManage />}
                {tabIndex == '2' && <ReceiptType />}
            </div>
        </div>
    );
}

export default CloudPrinter;