/**
 * 带标题的textare输入框组件
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Input } from 'antd';

const { TextArea } = Input;
class TextAreaModule extends React.Component {
    state = {
        textNum: 0,
        isInit: true,
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isInit } = this.state;
        if (nextProps.textLength && isInit) {
            this.setState({ textNum: nextProps.textLength, isInit: false });
        }
    }
    _onChange = (e) => {
        const { bindThis, bindName, onChange, maxLength } = this.props;
        this.setState({ textNum: e.target.value.length });
        if (onChange) {
            onChange(e.target.value);
        }
        if (bindThis && bindName) {
            if (typeof bindThis === 'function') {
                if (e.target.value.length > maxLength) {
                    bindThis(e.target.defaultValue);
                    this.setState({ textNum: e.target.defaultValue.length });
                } else {
                    bindThis(e.target.value);
                }
            } else {
                if (e.target.value.length > maxLength) {
                    bindThis.setState({ [bindName]: e.target.defaultValue });
                    this.setState({ textNum: e.target.defaultValue.length });
                } else {
                    bindThis.setState({ [bindName]: e.target.value });
                }
            }
        }
    }

    render() {
        const { maxLength, label, placeholder, disabled, style, inputStyle, value, rows, isRequired } = this.props;
        const { textNum } = this.state;
        return (
            <div style={style} className={styles.container}>
                <span className={styles.star} style={isRequired ? { display: 'block' } : { display: 'none' }}>*</span>
                <div className={styles.label}>{label}</div>
                {/* <div className={styles.inputbox}> */}
                    <TextArea
                        style={inputStyle}
                        className={styles.input}
                        rows={rows}
                        placeholder={placeholder}
                        disabled={disabled}
                        onChange={this._onChange}
                        value={value}
                    />
               {/*</div>*/}
                {maxLength ? <span style={{ marginLeft: 2 }}>（{textNum}/{maxLength}）</span> : null}
            </div>
        );
    }
}

TextAreaModule.defaultProps = {
    disabled: false,
    rows: 5,
    isRequired: false,
};

TextAreaModule.propTypes = {
    bindThis: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ]), // 父级this
    bindName: PropTypes.string, // 双向绑定的属性名称
    style: PropTypes.object, // 总样式
    inputStyle: PropTypes.object, // 输入框样式
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]), // 标题名称
    placeholder: PropTypes.string, // 提示信息
    isRequired: PropTypes.bool, // 是否显示红星
    disabled: PropTypes.bool, // 是否为禁用状态，默认为 false
    onChange: PropTypes.func, // 输入框内容变化时的回调
    // 输入框内容，为字符串或数字(双向绑定)
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    rows: PropTypes.number, // 文本区域中显示的输入行数
    maxLength: PropTypes.number, // 输入的最大长度
    textLength: PropTypes.number,
};

export default TextAreaModule;