import { createModel } from '@/store/tools';

const model = {
    namespace: 'groupmanage',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getGroupList': T('getGroupList'),
            'getStudentList': T('getStudentList'),
            'getUndistributed': T('getUndistributed'),
            'getTraineeTypeEnum': T('getTraineeTypeEnum'),
            'getAllocatedList': T('getAllocatedList'),
            'getFlagEnum': T('getFlagEnum'),
            'getOperationCenter': T('getOperationCenter'),
            'getAreaCompany': T('getAreaCompany'),
        },
        actions: {
            'getGroupList': A('getGroupList'),
            'getStudentList': A('getStudentList'),
            'getUndistributed': A('getUndistributed'),
            'getTraineeTypeEnum': A('getTraineeTypeEnum'),
            'getAllocatedList': A('getAllocatedList'),
            'getFlagEnum': A('getFlagEnum'),
            'getOperationCenter': A('getOperationCenter'),
            'getAreaCompany': A('getAreaCompany'),
            'cleanAreaCompany': C('getAreaCompany'),
        },
        sagas: {
            'getGroupList': S('getGroupList', '/show/traineegroup/listTraineeGroup'),
            'getStudentList': S('getStudentList', '/show/traineegroup/listAccountTraineeGroup'),
            'getUndistributed': S('getUndistributed', '/show/traineegroup/listUnAccountTraineeGroup'),
            'getTraineeTypeEnum': S('getTraineeTypeEnum', '/enum/TraineeTypeEnum'),
            'getAllocatedList': S('getAllocatedList', '/show/traineegroup/listAccountTraineeGroup'),
            'getFlagEnum': S('getFlagEnum', '/enum/FlagEnum'),
            'getOperationCenter': S('getOperationCenter', '/admin/company/listOperationsIdAndName'),
            'getAreaCompany': S('getAreaCompany', '/admin/company/listAreaCompanyIdAndName'),
        },
        reducers: {
            'groupList': R('getGroupList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'studentList': R('getStudentList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'undistributed': R('getUndistributed', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'traineeTypeEnum': R('getTraineeTypeEnum', []),
            'allocatedList': R('getAllocatedList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'flagEnum': R('getFlagEnum', []),
            'operationCenter': R('getOperationCenter', []),
            'areaCompany': R('getAreaCompany', []),
        }
    })
};
export default createModel(model);