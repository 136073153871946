/**
 * 批量签约
 */
import React from 'react';
import { Modal, message, Icon, Divider } from 'antd';
import PropTypes from 'prop-types';
import styles from './SignModal.module.scss';
import http from '@/assets/api/http';
import CusotmUpload from '@/components/NewUpload';

class SignModal extends React.Component {
    state = {
        excelUrl: '',
        excelList: [],
        zipUrl: '',
        zipList: [],
        confirmLoading: false
    };

    _handleOk = () => {
        http('/youfuOut/batchSignInfo', { nameListUrl: this.state.excelUrl, imgZipUrl: this.state.zipUrl }, 'POST').then((response) => {
            this.props.close('update');
        }).catch((err = {}) => {
            message.error(err.message);
        });
    }

    _handleCancel = () => {
        this.props.close();
    }

    render() {
        const { confirmLoading, excelList, zipList } = this.state;

        return (
            <Modal
                width={470}
                centered
                title='批量签约'
                visible={true}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.title}>
                        批量名单：<a href="https://online-avatar.daily-talk.com/%E6%96%87%E4%BB%B6%E4%B8%8A%E4%BC%A0%E6%A8%A1%E7%89%88.zip">下载模板</a>
                    </div>
                    <div className={styles.upload}>
                        <CusotmUpload
                            accept='.xlsx'
                            fileList={excelList}
                            listType="picture-card"
                            wallMode="single"
                            qiniuUploadProps={{
                                uploadType: 'image'
                            }}
                            onChange={(info) => {
                                this.setState({ excelList: info.fileList });
                            }}
                            onFileListChange={(info, customFile) => {
                                if (customFile && customFile.status === 'success') {
                                    const excelUrl = info.fileList[0] ? info.fileList[0].url : '';
                                    this.setState({ excelUrl });
                                    if (excelUrl == '') {
                                        this.setState({ excelList: [] });
                                        message.error('上传失败，请稍后重新上传');
                                        return;
                                    }
                                }
                            }}
                        >
                            <div style={{ color: '#999', fontSize: '12px' }}>
                                <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                <p>上传.xlsx文件</p>
                            </div>
                        </CusotmUpload>
                    </div>
                    <Divider />
                    {/* <div className={styles.title}>
                        批量图片：<a>下载模板</a>
                    </div> */}
                    <div className={styles.upload}>
                        <CusotmUpload
                            accept='.zip'
                            fileList={zipList}
                            listType="picture-card"
                            wallMode="single"
                            qiniuUploadProps={{
                                uploadType: 'image'
                            }}
                            onChange={(info) => {
                                this.setState({ zipList: info.fileList });
                            }}
                            onFileListChange={(info, customFile) => {
                                if (customFile && customFile.status === 'success') {
                                    const zipUrl = info.fileList[0] ? info.fileList[0].url : '';
                                    this.setState({ zipUrl });
                                    if (zipUrl == '') {
                                        this.setState({ zipList: [] });
                                        message.error('上传失败，请稍后重新上传');
                                        return;
                                    }
                                }
                            }}
                        >
                            <div style={{ color: '#999', fontSize: '12px' }}>
                                <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                <p>上传.zip文件</p>
                            </div>
                        </CusotmUpload>
                    </div>
                </div>
            </Modal>
        );

    }
}

SignModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    close: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default SignModal;