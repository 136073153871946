import { createModel } from '@/store/tools';

const model = {
    namespace: 'knowledgeclassify',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getAdvocacyTaskList': T('getAdvocacyTaskList'),
            'getAdvocacyTaskInfo': T('getAdvocacyTaskInfo'),
            'getAdvocacyTaskState': T('getAdvocacyTaskState'),
            'getAdvocacyTaskUser': T('getAdvocacyTaskUser'),
        },
        actions: {
            'getAdvocacyTaskList': A('getAdvocacyTaskList'),
            'getAdvocacyTaskInfo': A('getAdvocacyTaskInfo'),
            'getAdvocacyTaskState': A('getAdvocacyTaskState'),
            'getAdvocacyTaskUser': A('getAdvocacyTaskUser'),
        },
        sagas: {
            'getAdvocacyTaskList': S('getAdvocacyTaskList', '/spokesmanTaskAudit/listSpokesmanTaskAudit'),
            'getAdvocacyTaskInfo': S('getAdvocacyTaskInfo', '/spokesmanTaskAudit/spokesmanTaskAuditDetail'),
            'getAdvocacyTaskState': S('getAdvocacyTaskState', '/spokesmanTaskAudit/auditState'),
            'getAdvocacyTaskUser': S('getAdvocacyTaskUser', '/merchant/info/listMerchantIdAndName'),
        },
        reducers: {
            'advocacyTaskList': R('getAdvocacyTaskList', { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 }),
            'advocacyTaskInfo': R('getAdvocacyTaskInfo', {}),
            'advocacyTaskState': R('getAdvocacyTaskState', []),
            'advocacyTaskUser': R('getAdvocacyTaskUser', { merchantIdAndNames: [] }),
        }
    })
};
export default createModel(model);