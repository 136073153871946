import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table, Switch, } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { Mode } from '../../_utils';

class TableContent extends Component {

    _columns = () => [
        {
            title: '股票名称',
            dataIndex: 'stockName',
            key: 'stockName',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '股票代码',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '销售时间',
            dataIndex: 'saleStartTime',
            key: 'saleStartTime',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: (text, record) => tooltip(
                <>
                    <div>{text}</div>
                    <div>{record.saleEndTime}</div>
                </>
            )
        },
        {
            title: '预计结算时间',
            dataIndex: 'settlingStartTime',
            key: 'settlingStartTime',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: (text, record) => tooltip(
                <>
                    <div>{text}</div>
                    <div>{record.settlingEndTime}</div>
                </>
            )
        },
        {
            title: '预结算收益',
            dataIndex: 'incomeRangeStart',
            key: 'incomeRangeStart',
            align: 'center',
            width: '7%',
            onCell: tooltipStyle,
            render: (text, record) => tooltip(
                <>{Number(text) / 100}% ~ {Number(record.incomeRangeEnd) / 100}%</>
            )
        },
        {
            title: '股票总数/股',
            dataIndex: 'stockTotal',
            key: 'stockTotal',
            align: 'center',
            width: '7%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '认购信用值/股',
            dataIndex: 'price',
            key: 'price',
            align: 'center',
            width: '7%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '已售/股',
            dataIndex: 'soldShares',
            key: 'soldShares',
            align: 'center',
            width: '5%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '虚拟销量',
            dataIndex: 'fictitiousSale',
            key: 'fictitiousSale',
            align: 'center',
            width: '5%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '发行端口',
            dataIndex: 'issuePort.value',
            key: 'issuePort.value',
            align: 'center',
            width: '5%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'stockSaleType.value',
            key: 'stockSaleType.value',
            align: 'center',
            width: '5%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '启用/禁用',
            dataIndex: 'stockState.code',
            key: 'stockState.code',
            align: 'center',
            width: '5%',
            render: (text, item) => (this._switchCell(text, item))
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '15%',
            render: (id, record) => this._tableAction(id, record)
        },
    ];

    //表格项内容为开关 
    _switchCell = (key, item) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    key={`${item.id}_${key}`}
                    checked={key == '1'}
                    onChange={(value) => {
                        this._actionHandle(item, Mode.StockState, value ? '1' : '0');
                    }}
                />
            </div>
        );
    };

    // 表格操作
    _tableAction = (key, record) => {

        const items = {
            [Mode.Detail]: <div key={Mode.Detail} className={styles.item} onClick={() => this._actionHandle(key, Mode.Detail, record)}>详情</div>,
            [Mode.Virtual]: <div key={Mode.Virtual} className={styles.item} onClick={() => this._actionHandle(key, Mode.Virtual, record)}>设置虚拟销量</div>,
            [Mode.AddCirculation]: <div key={Mode.AddCirculation} className={styles.item} onClick={() => this._actionHandle(key, Mode.AddCirculation, record)}>增加发行量</div>,
            [Mode.Settlement]: <div key={Mode.Settlement} className={styles.item} onClick={() => this._actionHandle(key, Mode.Settlement, record)}>结算</div>,
            [Mode.Edit]: <div key={Mode.Edit} className={styles.item} onClick={() => this._actionHandle(key, Mode.Edit, record)}>编辑</div>,
            [Mode.Delete]: <div key={Mode.Delete} className={styles.item} onClick={() => this._actionHandle(key, Mode.Delete, record)}>删除</div>
        };

        let renderItems = {
            NOT_START: [Mode.Detail, Mode.Virtual, Mode.AddCirculation, Mode.Edit, Mode.Delete],
            IN_PREHEATING: [Mode.Detail, Mode.Virtual, Mode.AddCirculation, Mode.Edit],
            ON_SALE: [Mode.Detail, Mode.Virtual, Mode.AddCirculation],
            SOLD_OUT: [Mode.Detail, Mode.Virtual, Mode.AddCirculation],
            IN_SETTLEMENT: [Mode.Detail, Mode.Settlement],
            HAVE_FINISHED: [Mode.Detail]
        }[(record.stockSaleType || {}).code] || [Mode.Detail];

        return (
            <div className={styles.action}>
                {renderItems.map(key => items[key])}
            </div>
        );
    };

    // 操作表格
    _actionHandle = (id, type, value) => {
        this.props.tableAction(id, type, value);
    }

    render() {
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    columns={this._columns()}
                    pagination={false}
                    dataSource={dataList}
                    scroll={{ y: true }} />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={this.props.paginationChange} />
            </div>
        );
    }
}


TableContent.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
};



export default TableContent;