/**
 * 秀场举报封禁弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, Input, message, Radio, Form } from 'antd';
import styles from './Close.module.scss';

const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};
class Close extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        status: '',
        reason: ''
    }
    // 点击确认
    _handleOk = () => {
        const { data } = this.props;
        const goodsIds = [];
        goodsIds.push(data);
        http('/goods/goods/disableGoods', { goodsIds }, 'POST').then(() => {
            message.success('停用成功');
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch(() => {
            message.error('停用失败');
            this.setState({
                visible: false,
            });
            this.props.closeMode();
        });
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _statusChange = (e) => {
        this.setState({ status: e.target.value });
    }

    _reasonChange = (e) => {
        this.setState({ reason: e.target.value });
    }

    render() {
        const { visible, confirmLoading, title, reason } = this.state;
        const { getFieldDecorator } = this.props.form;
        const options = [
            { label: '色情', value: '色情' },
            { label: '暴力', value: '暴力' },
            { label: '反动', value: '反动' },
            { label: '违规操作', value: '违规操作' },
            { label: '其他', value: '其他' },
        ];
        return (
            <Modal
                width={600}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon={false}
                            htmlFor='reason'
                            label={<span>封禁理由</span>}
                        >
                            {getFieldDecorator('reason', {
                                // rules: [{ required: true, message: '请输入策略名称' }],
                            })(
                                <Radio.Group
                                    options={options}
                                    disabled
                                    // onChange={this.onChange3}
                                    // value={this.state.value3}
                                />
                            )}
                            {getFieldDecorator('reasons', {
                                // rules: [{ required: true, message: '请输入策略名称' }],
                            })(
                                <TextArea rows={4} />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

Close.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(Close);// 校验
export default connect(null, null)(ForgotProducDetails);