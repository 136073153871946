import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip, priceFenToYuanCell, MoreCell, cellWidth } from '@/components/TableCell';
import ButtonPerssion from '@/routes/ButtonPerssion';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [],
    };

    static getDerivedStateFromProps(props, state) {
        if (props.selectedRowKeys !== state.selectedRowKeys) {
            return {
                selectedRowKeys: props.selectedRowKeys
            };
        }
        return null;
    }

    _columns = () => [
        {
            title: '会员账号',
            dataIndex: 'tel',
            key: 'tel',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所属服务商',
            dataIndex: 'serviceName',
            key: 'serviceName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '主播类型',
            dataIndex: 'anchorTypeName',
            key: 'anchorTypeName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员等级',
            dataIndex: 'levelName',
            key: 'levelName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '总魅力值',
            dataIndex: 'starlightNumber',
            key: 'starlightNumber',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '总人气值',
            dataIndex: 'starNumber',
            key: 'starNumber',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '关注',
            dataIndex: 'fansNumber',
            key: 'fansNumber',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '打赏魅力值',
            dataIndex: 'starLight',
            key: 'starLight',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品佣金(元)',
            dataIndex: 'saleBouns',
            key: 'saleBouns',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '成为主播时间',
            dataIndex: 'auditTime',
            key: 'auditTime',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: cellWidth.big,
            fixed: 'right',
            render: (id, item) => this._TableAction(item.accountId, item)
        },
    ];

    _takeDataSource = (data) => {
        return data.map((item) => {
            item.address = '' + this._resolveAreaName(item.provinceName) + this._resolveAreaName(item.cityName) + this._resolveAreaName(item.areaName);
            item.status = item.state == 1 ? '正常' : '禁止';
            return item;
        });
    }

    _resolveAreaName = (areaName) => {
        if (areaName) {
            return areaName;
        } else {
            return '';
        }
    }

    _MoneyCell = (text) => {
        const money = regFenToYuan(parseInt(text));
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {money}
            </div>
        );
    };

    _TableAction = (key, item) => {
        const { tableAction } = this.props;
        let textName = item.isAutoBind == '0' ? '设为自控' : '移除自控';
        const arr = [
            { name: '修改区域', onClick: () => tableAction(key, 'CHANGE_AREA'), perssionCode: 'usermanage/tvanchor/xgqy' },
            { name: '封禁/解封', onClick: () => tableAction(key, 'TVANCHOR_PROHIBIT'), perssionCode: 'usermanage/tvanchor/fjjf' },
            { name: textName, onClick: () => tableAction(key, 'SET_CONTROL', item) }
        ];
        return (
            <div className={styles.action}>
                <ButtonPerssion code='usermanage/tvanchor/hyxx'>
                    <div className={styles.item} onClick={() => tableAction(key, 'TVANCHOR_INFO')}>主播信息</div>
                </ButtonPerssion>
                <ButtonPerssion code='usermanage/tvanchor/szmx'>
                    <div className={styles.item} onClick={() => tableAction(key, 'TVANCHOR_RANDP')}>收支明细</div>
                </ButtonPerssion>
                <ButtonPerssion code='usermanage/tvanchor/kqgl'>
                    <div className={styles.item} onClick={() => tableAction(key, 'TVANCHOR_TICKET')}>卡券管理</div>
                </ButtonPerssion>
                {MoreCell(arr)}
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.setState({ selectedRowKeys });
                this.props.selectedRowKeysChange(selectedRowKeys);
            }
        };
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] }, () => {
            this.props.selectedRowKeysChange([]);
        });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey='accountId'
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    selectedRowKeys: PropTypes.array,
    selectedRowKeysChange: PropTypes.func,
    scrollY: PropTypes.string
};

export default XTableHOC(TableContent);