export const GET_CUSTOMLIST_TABLESOURCE = 'APPCUSTOM/GET_CUSTOMLIST_TABLESOURCE';
export const SET_CUSTOMLIST_TABLESOURCE = 'APPCUSTOM/SET_CUSTOMLIST_TABLESOURCE';

// export const GET_CUSTOMLIST_HOME_TABLESOURCE = 'APPCUSTOM/GET_CUSTOMLIST_HOME_TABLESOURCE';
// export const SET_CUSTOMLIST_HOME_TABLESOURCE = 'APPCUSTOM/SET_CUSTOMLIST_HOME_TABLESOURCE';

// export const GET_CUSTOMLIST_MALL_TABLESOURCE = 'APPCUSTOM/GET_CUSTOMLIST_MALL_TABLESOURCE';
// export const SET_CUSTOMLIST_MALL_TABLESOURCE = 'APPCUSTOM/SET_CUSTOMLIST_MALL_TABLESOURCE';

// export const GET_CUSTOMLIST_SUBJECT_TABLESOURCE = 'APPCUSTOM/GET_CUSTOMLIST_SUBJECT_TABLESOURCE';
// export const SET_CUSTOMLIST_SUBJECT_TABLESOURCE = 'APPCUSTOM/SET_CUSTOMLIST_SUBJECT_TABLESOURCE';

export const GET_CUSTOMLIST_ADVERT_TABLESOURCE = 'APPCUSTOM/GET_CUSTOMLIST_ADVERT_TABLESOURCE';
export const SET_CUSTOMLIST_ADVERT_TABLESOURCE = 'APPCUSTOM/SET_CUSTOMLIST_ADVERT_TABLESOURCE';
