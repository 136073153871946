import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TableBox.module.scss';
import { Table } from 'antd';
import { XPagination, XTableAdapter } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';

const TableBox = ({
	renderData: {
		dataList,
		pageNum,
		pageSize,
		resultTotal
	},
	paginationChange,
}) => {

	const [scrollY, setScrollY] = useState('');

	const columns = () => {
		return [
			{
				title: '机构名称',
				dataIndex: 'companyName',
				key: 'companyName',
				align: 'center',
				width: '10%',
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '负责人',
				dataIndex: 'realName',
				key: 'realName',
				align: 'center',
				width: '10%',
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '身份证',
				dataIndex: 'idCard',
				key: 'idCard',
				width: '10%',
				align: 'center',
				render: tooltip
			}];
	};

	return (
		<XTableAdapter render={scrollY => { setScrollY(scrollY); }}>
			<div className={styles.tableContainer} style={{ height: '100%' }}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey={record => record.id}
					columns={columns()}
					pagination={false}
					dataSource={dataList}
					scroll={{ x: 'max-content', y: scrollY }} />
				<XPagination
					resultTotal={resultTotal}
					pageSize={pageSize}
					pageNum={pageNum}
					onChange={paginationChange} />
			</div>
		</XTableAdapter>
	);
};

TableBox.propTypes = {
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
	batchKeys: PropTypes.array,
	tableAction: PropTypes.func,
	scrollY: PropTypes.string,
};

export default TableBox;