import { createModel } from '@/store/tools';

const model = {
    namespace: 'Allocation',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getFromWholesaleList': T('getFromWholesaleList'),
            'getFromMerchantList': T('getFromMerchantList'),
            'getPlatDetails': T('getPlatDetails'),
            
        },
        actions: {
            'getFromWholesaleList': A('getFromWholesaleList'), // 批发服务费收取数据
            'getFromMerchantList': A('getFromMerchantList'), // 商户收取费用数据
            'getPlatDetails': A('getPlatDetails'), // 获取初始化详情数据
            'getCleanPlatDetails': C('getPlatDetails'), // 清除获取初始化详情数据
        },
        sagas: {
            'getFromWholesaleList': S('getFromWholesaleList', '/settle/param/setting/charge/config', 'GET'),
            'getFromMerchantList': S('getFromMerchantList', '/settle/param/setting/plat/config', 'GET'),
            'getPlatDetails': S('getPlatDetails', '/settle/param/setting/config', 'GET'),
        },
        reducers: {
            'fromWholesaleList': R('getFromWholesaleList', []),
            'fromMerchantList': R('getFromMerchantList', []),
            'platDetails': R('getPlatDetails', {}),
        }
    })
};
export default createModel(model);