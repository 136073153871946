import * as Audit from './actiontypes';

/**
 * 获取'主播封面审核表格'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 */
export const getBrandAuditList = (params) => ({
    type: Audit.GET_BRANDAUDIT_LIST,
    payload: params
});
export const getBrandAuditUsername = () => ({
    type: Audit.GET_BRANDAUDIT_USERNAME,
    payload: {}
});
export const getBrandAuditState = () => ({
    type: Audit.GET_BRANDAUDIT_STATE,
    payload: {}
});

export const getBrandAuditDetail = ({ auditId, merchantId }) => ({
    type: Audit.GET_BRANDAUDIT_DETAIL,
    payload: {
        auditId, merchantId
    }
});

export const getBrandAuditBrand = (brandName) => ({
    type: Audit.GET_BRANDAUDIT_BRAND,
    payload: {
        brandName
    }
});