import * as Order from './actionTypes';

//魅力值订单列表
export const getStarOrderList = (param) => ({
    type: Order.GET_STARORDER_LIST,
    payload: param,
});
// 魅力值失败订单
export const getFailStarOrderList = (param) => ({
    type: Order.GET_FAILORDER_LIST,
    payload: param,
});
// 支付方式
export const getPayWays = (param) => ({
    type: Order.GET_PAY_WAYS,
    payload: param,
});
// 异常类型
export const getRiskType = (param) => ({
    type: Order.GET_RISK_TYPE,
    payload: param,
});
// 状态
export const getHandleStatus = (param) => ({
    type: Order.GET_STATUS_LIST,
    payload: param,
});
// 预警用户
export const getRiskUserList = (param) => ({
    type: Order.GET_RISKUSER_LIST,
    payload: param,
});
// 预警等级
export const getRiskLevel = (param) => ({
    type: Order.GET_RISKLEVEL_LIST,
    payload: param,
});
// 充值来源
export const getDeviceTypeList = (param) => ({
    type: Order.GET_DEVTYPE_LIST,
    payload: param,
});




