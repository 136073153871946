/**
 * 修改服务商弹框
 */
import React from 'react';
import { Modal, Table, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './ServeceModal.module.scss';
import { XInput, XOKButton, XPagination } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import model from '@/reducers/usermanage/member/model';
import http from '@/assets/api/http';

const columns = [
    {
        title: '服务商名称',
        dataIndex: 'realName',
        key: 'realName',
        width: '30%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '手机号码',
        dataIndex: 'mobilePhone',
        key: 'mobilePhone ',
        width: '30%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '所属区域',
        dataIndex: 'areaName',
        key: 'areaName',
        width: '30%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
];

const TableCell = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text}
        </div>
    );
};

class ServeceModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        serviceName: '', // 服务商姓名
        phone: '', // 手机号码
        selectedRows: [], // 单选
        searchCache: { pageNum: 1, pageSize: 10 }, // 分页
    }

    componentDidMount() {
        this.props.getServiceList(this.state.searchCache);
    }

    // 确认操作
    _handleOk = () => {
        const { selectedRows } = this.state;
        const { id } = this.props.data;
        if (selectedRows && selectedRows.length == 0) {
            message.error('请选择一个服务商');
            return;
        }
        const data = {
            accountId: id,
            serviceId: selectedRows[0].id,
        };
        this.setState({ confirmLoading: true });
        http('/user/account/updateAccountServiceId', data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('修改成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        }).finally(() => {
            this.setState({ confirmLoading: false });
        });
    }

    // 取消操作
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // 表格复选框操作回调
    _rowSelection = {
        type: 'radio',
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRows });
        }
    };

    // 查询操作
    _searchHandle = () => {
        const { serviceName, phone, searchCache } = this.state;
        this.props.getServiceList({ ...searchCache, serviceName, phone });
    }

    // 分页操作
    _paginationChange = (pageSize, pageNum) => {
        this.props.getServiceList({ pageNum, pageSize });
    }

    render() {
        const { visible, confirmLoading, serviceName, phone } = this.state;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.serviceList;
        console.log(dataList);

        return (
            <Modal
                width={1000}
                centered
                title='更换服务商'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.remind}>提示：请在以下列表选择会员要更换的服务商</div>
                    <div className={styles.search}>
                        <XInput
                            style={{ width: '266px' }}
                            inputStyle={{ width: '200px' }}
                            label='服务商'
                            placeholder='请输入服务商名称'
                            value={serviceName}
                            bindThis={this}
                            bindName='serviceName'
                        />
                        <XInput
                            style={{ width: '266px' }}
                            inputStyle={{ width: '200px' }}
                            label='手机号码'
                            placeholder='请输入手机号码'
                            value={phone}
                            bindThis={this}
                            bindName='phone'
                        />
                        <XOKButton style={{ marginLeft: '20px', width: '90px' }} label='查询' onClick={this._searchHandle} />
                    </div>
                    <div className={styles.table}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowSelection={this._rowSelection}
                            rowKey={(record) => record.id}
                            columns={columns}
                            dataSource={dataList}
                            pagination={false}
                            scroll={{ y: 455 }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

ServeceModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getServiceList: PropTypes.func,
    serviceList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    serviceList: state.member.getServiceList
});

export default connect(mapStateToProps, { ...model.actions })(ServeceModal);