/**
 * 停用
 */
import React, { useState } from 'react';
import { Modal, message, Input } from 'antd';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import styles from './BlockUp.module.scss';
const { TextArea } = Input;

const BlockUp = ({ closeMode, data: { id = '' } = {}, curTabID }) => {

    const [loading, setLoading] = useState(false);
    const [disableReason, setDisableReason] = useState('');

    const onSubmit = () => {
        if (!disableReason) {
            message.warn('请输入停用原因');
            return;
        }
        let url = curTabID == 1 ? '/admin/stockPremium/update' : '/admin/exchangeRate/update';
        setLoading(true);
        http(url, { state: false, disableReason, id }, 'POST')
            .then((res = {}) => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    return <Modal
        width={360}
        bodyStyle={{ paddingTop: '10px' }}
        centered
        title='停用设置'
        visible={true}
        onOk={onSubmit}
        onCancel={() => closeMode()}
        confirmLoading={loading}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div className={styles.container}>
            <div className={styles.item}>您是否停用当前设置，停用后前端无法兑换收益</div>
            <div className={styles.item}>
                <div className={styles.label}>原因： </div>
                <TextArea
                    value={disableReason}
                    onChange={e => setDisableReason(e.target.value)}
                    placeholder="请输入停用原因"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    maxLength={50}
                />
            </div>
        </div>
    </Modal>;
};

BlockUp.propTypes = {
    data: PropTypes.object, // 需要修改魅力值的会员对象数组
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    curTabID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
};

export default BlockUp;