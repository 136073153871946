/**
 * 商城参数
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { getshowpareme, } from '@/reducers/systemmanage/mallsetting/actions';
import http from '@/assets/api/http';
import { message, Input } from 'antd';

class Showpareme extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.getshowpareme();
    }

    updateChangeReco = (record, value) => {
        const { id, gmtModified, recoNumber, recoTimes } = record;
        http('/goods/recoconfig/update', { list: [{ id, gmtModifiedOld: gmtModified, recoNumber, recoTimes, ...value, }] }).then((res = {}) => {
            if (res.code == '200') {
                message.success('修改成功');
            }
        }).catch((reject) => {
            message.warn(`${reject.message},请刷新页面。`);
        });
    }

    render() {
        const { paramelist = [], } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
                <div className={`${styles.tableContainer}`}>
                    <div className={styles.tableContainer} style={{ overflow: 'auto' }}>
                        <div className={styles.paramers}>
                            <div className={styles.top_title}>
                                <span>商城推荐时长</span>
                            </div>
                            {
                                paramelist.map((record) => {
                                    let texts = record.remark.split(/{.+?}/);
                                    return (
                                        <div key={record.id} className={`${styles.layout} ${styles.paramersTiems}`}>
                                            <span style={{ width: 'max-content' }}>{texts[0]}</span>&nbsp;
                                            <Input key={record.recoNumber} style={{ width: '90px' }} defaultValue={record.recoNumber} onBlur={(e) => this.updateChangeReco(record, { recoNumber: e.target.value })} />&nbsp;

                                            <span style={{ width: 'max-content' }}>{texts[1]}</span>&nbsp;
                                            <Input key={record.recoTimes} style={{ width: '90px' }} defaultValue={record.recoTimes} onBlur={(e) => this.updateChangeReco(record, { recoTimes: e.target.value })} />&nbsp;
                                            <b>{`秒${texts[2]}`}</b>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

Showpareme.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    paramelist: PropTypes.array,//商城参数
    getshowpareme: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        paramelist: state.mallSetting.mallsettingDate.paramelist, //商城参数
    };
};


export default connect(mapStateToProps, { getshowpareme, })(Showpareme);