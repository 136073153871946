import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Task from './actionTypes';

//任务列表
export const getTaskClassifyList = function* () {
    yield takeEvery(Task.GET_TASKMANAGE_TASKCLASSIFYLIST, function* requestData({ payload }) {
        try {
            let result = yield http('/taskcategory/listByPage', payload, 'POST');
            yield put({ type: Task.SET_TASKMANAGE_TASKCLASSIFYLIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//任务状态下拉
export const getTaskClassifyStateSele = function* () {
    yield takeEvery(Task.GET_TASKMANAGE_TASKCLASSIFYSTATESELE, function* requestData({ payload }) {
        try {
            let result = yield http('/taskInfo/getTaskDetail', payload, 'POST');
            yield put({ type: Task.SET_TASKMANAGE_TASKCLASSIFYSTATESELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};