import React from 'react';
import PropTypes from 'prop-types';
import { Table, Switch } from 'antd';
import { connect } from 'react-redux';
import { XSelect, XOKButton, XCancelButton, XInput, XPagination } from '@/components/xqxc_ui';
import TypeModal from '../../modal/TypeModal';
import DeleteModal from '../../modal/DeleteModal';
import { getMaterialTableSource } from '@/reducers/systemmanage/materiallist/actions';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { SearchBox, SearchItem } from '@/components/Layout';
import { autoPageNum, autopageSize } from '@/assets/config';
import styles from './index.module.scss';

const Mode = {
    CREAT: 'CREAT',
    EDITE: 'EDITE',
    DELETE: 'DELETE'
};

const typeName = { icon: '图标', pic: '图片', video: '视频' };

const columns = (TableAction, actionClickHandle, type) => {
    let cols = [{
        title: typeName[type] + '名称',
        dataIndex: type + 'Name',
        key: type + 'Name ',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '分类名称',
        dataIndex: type + 'CategoryName',
        key: type + 'CategoryName ',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: typeName[type],
        dataIndex: type + 'Url',
        key: type + 'Url',
        align: 'center',
        width: '10%',
        render: (text) => (ImgCell(text))
    },
    {
        title: '推荐',
        dataIndex: 'recommend',
        key: 'recommend',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle, type, 'recommend'))
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle, type, 'state'))
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: '10%',
        render: (id, item) => TableAction(item[type + 'Id'], actionClickHandle, item)
    }];

    type === 'video' && cols.splice(2, 1);

    return cols;
};

const TableAction = (id, actionClickHandle, record) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'edit', record)}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'delete')}>删除</div>
        </div>
    );
};

// 表格项内容为文本
const TableCell = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text}
        </div>
    );
};

// 表格项内容为图片
const ImgCell = (url) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <div className={styles.imgbox}>
                <img className={styles.img} src={url} />
            </div>
        </div>
    );
};

// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle, type, field) => {
    let checked = sw == 0 ? false : true;
    const id = item[type + 'Id'];
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                key={id + item[field]}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                defaultChecked={checked}
                onChange={(value) => actionClickHandle(id, 'switch', value, field)}
            />
        </div>
    );
};


class MaterialList extends React.PureComponent {

    state = {
        iconCategory: {},
        picCategory: {},
        videoCategory: {},

        iconName: undefined,
        picName: undefined,
        videoName: undefined,

        recommend: {},

        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
        selectDataSource: []
    }

    componentDidMount() {
        http(`/custom/${this.props.curTabID}Category/getPageList`, { pageSize: 9999, pageNum: 1, state: 1, isExcludeLocalUpload: '1' }).then(({ result } = {}) => {
            this.setState({ selectDataSource: result.dataList || [] });
        });
    }

    _searchHandle = (useCache) => {

        let categoryId = this.props.curTabID + 'CategoryId';
        let categoryKey = this.props.curTabID + 'Category';
        let classNameKey = this.props.curTabID + 'Name';
        const { pageSize, pageNum, recommend } = this.state;

        this.searchCache = {
            [categoryId]: this.state[categoryKey][categoryId],
            [classNameKey]: this.state[classNameKey],
            type: this.props.curTabID,
            recommend: this.state.recommend.code,
            isExcludeLocalUpload: '1',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getMaterialTableSource(this.searchCache); // 发起请求
        KeepAlive.saveCache({ recommend, [categoryKey]: this.state[categoryKey], [classNameKey]: this.state[classNameKey], pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        let categoryKey = this.props.curTabID + 'Category';
        let classNameKey = this.props.curTabID + 'Name';
        this.setState({
            [categoryKey]: {},
            recommend: {},
            [classNameKey]: undefined,
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格操作
    _tableAction = (id, type, value, field) => {
        switch (type) {
            case 'edit':
                this.setState({
                    currentOpenMode: Mode.EDITE,
                    openModeParam: { type: this.props.curTabID, ...value }
                });
                break;
            case 'delete':
                this.setState({
                    currentOpenMode: Mode.DELETE,
                    openModeParam: { id, type: this.props.curTabID }
                });
                break;
            case 'switch':
                this.updateVals(id, { [field]: value === true ? 1 : 0 });
                break;
            case 'sort':
                this.updateVals(id, { sort: value });
                break;
        }
    }

    updateVals = (id, updateVals) => {
        const { curTabID } = this.props;
        http(`/custom/${curTabID}List/save`, { [curTabID + 'Id']: id, ...updateVals })
            .then(response => this._searchHandle('useCache'));
    }

    // 操作表格
    _actionHandle = (id, type, value, item) => {
        this._tableAction(id, type, value, item);
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.CREAT:
                return <TypeModal title={'创建' + this.props.title} visible={true} data={{ recommend: '1', state: '1', type: this.props.curTabID }} selectDataSource={this.state.selectDataSource} closeMode={this._closeMode} />;
            case Mode.EDITE:
                return <TypeModal title={'编辑' + this.props.title} visible={true} data={this.state.openModeParam} selectDataSource={this.state.selectDataSource} closeMode={this._closeMode} />;
            case Mode.DELETE:
                return <DeleteModal title="删除提示" visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    _closeMode = (reload) => {
        this.setState({ currentOpenMode: '' });
        reload && this._searchHandle();
    }

    render() {
        const { dataList, pageNum, pageSize, resultTotal } = this.props.materialTable;
        const { recommend } = this.state;
        const selectSource = [{ value: '启用', code: '1' }, { value: '禁用', code: 0 }];

        const categoryName = this.props.curTabID + 'CategoryName',
            categoryId = this.props.curTabID + 'CategoryId';
        let categoryKey = this.props.curTabID + 'Category';
        let category = this.state[categoryKey];
        let classNameKey = this.props.curTabID + 'Name';
        let className = this.state[classNameKey];

        return (
            <KeepAlive id={this.props.curTabID} key={this.props.curTabID} render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XSelect
                                key={categoryKey}
                                style={{ marginLeft: '0px', width: '216px' }}
                                selectStyle={{ width: '150px' }}
                                label='所属分类'
                                placeholder='请选择所属分类'
                                renderData={this.state.selectDataSource}
                                dataIndex={categoryName}
                                keyIndex={categoryId}
                                value={category[categoryName]}
                                bindName={categoryKey}
                                bindThis={this}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                style={{ marginLeft: '30px', width: '216px' }}
                                selectStyle={{ width: '150px' }}
                                label='推荐'
                                placeholder='请选择推荐状态'
                                renderData={selectSource}
                                dataIndex='value'
                                keyIndex='code'
                                value={recommend.value}
                                bindName='recommend'
                                bindThis={this}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                key={classNameKey}
                                style={{ width: '216px' }}
                                inputStyle={{ width: '150px' }}
                                label={typeName[this.props.curTabID] + '名称'}
                                placeholder='请输入图标名称'
                                value={className}
                                bindName={classNameKey}
                                bindThis={this}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }} label={'创建' + typeName[this.props.curTabID]} onClick={() => this.setState({ currentOpenMode: Mode.CREAT })} />
                    </div>
                    <div className={styles.tableContainer}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowSelection={this._rowSelection}
                            columns={columns(TableAction, this._tableAction, this.props.curTabID)}
                            dataSource={dataList}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

MaterialList.propTypes = {
    curTabID: PropTypes.string,
    title: PropTypes.string,
    tableAction: PropTypes.func,

    getMaterialTableSource: PropTypes.func,
    materialTable: PropTypes.object
};

const mapStateToProps = (state) => ({
    materialTable: state.materiallist.materialTable
});

export default connect(mapStateToProps, {
    getMaterialTableSource
})(MaterialList);
