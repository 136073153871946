/**
 * 电子协议失败
 */
import React from 'react';
import styles from './index.module.scss';
import { Divider } from 'antd';

class PayFail extends React.Component {

    state = {
    }


    render() {
        return (
            <div className={styles.payContainer}>
                <div className={styles.flexConten}>
                    <div className={styles.mainTitle}>
                        <img src={require('@/assets/image/fail.png')} className={styles.iconStyles} />
                        <div className={styles.right}>
                            <div className={styles.title}>签约失败</div>
                            <div className={styles.subTitle}>请核对信息后再重新提交。</div>
                        </div>
                    </div>
                    {/* <Divider dashed className={styles.dividerStyle}></Divider>
                    <div className={styles.desc}>
                        <p className={styles.error}>您提交的内容有如下错误：</p>
                        <p>
                            1.XXXXXXXXXXX
                        </p>
                    </div> */}
                </div>
            </div>

        );
    }
}

export default PayFail;

