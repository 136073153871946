//获取/保存区域公司发票列表
export const GET_OPERATIONINVOICE_LIST = 'OPERATIONINVOICE/GET_OPERATIONINVOICE_LIST';
export const SET_OPERATIONINVOICE_LIST = 'OPERATIONINVOICE/SET_OPERATIONINVOICE_LIST';

//获取/保存状态下拉
export const GET_OPERATIONINVOICE_STATUS = 'OPERATIONINVOICE/GET_OPERATIONINVOICE_STATUS';
export const SET_OPERATIONINVOICE_STATUS = 'OPERATIONINVOICE/SET_OPERATIONINVOICE_STATUS';

//获取/保存公司发票详情
export const GET_OPERATIONINVOICE_DETAIL = 'OPERATIONINVOICE/GET_OPERATIONINVOICE_DETAIL';
export const SET_OPERATIONINVOICE_DETAIL = 'OPERATIONINVOICE/SET_OPERATIONINVOICE_DETAIL';

//获取/保存公司下拉菜单
export const GET_OPERATIONINVOICE_NAME = 'OPERATIONINVOICE/GET_OPERATIONINVOICE_NAME';
export const SET_OPERATIONINVOICE_NAME = 'OPERATIONINVOICE/SET_OPERATIONINVOICE_NAME';

//新建付款金额详情
export const GET_OPERATIONINVOICE_PAY = 'OPERATIONINVOICE/GET_OPERATIONINVOICE_PAY';
export const SET_OPERATIONINVOICE_PAY = 'OPERATIONINVOICE/SET_OPERATIONINVOICE_PAY'; 