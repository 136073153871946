
/**
 * 消费者权益说明创建或编辑弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { Modal, message, Input, Form } from 'antd';
import styles from './CreateEdit.module.scss';
import model from '@/reducers/productmanage/consumerknows/model';

class CreateEdit extends React.Component {

    static propTypes = {
        form: PropTypes.object,
        explainItem: PropTypes.object,
        closeMode: PropTypes.func,
        getqueryGoodsComsumerRights: PropTypes.func,
        queryGoodsComsumerRights: PropTypes.object,
    }

    state = {
        confirmLoading: false,
    }

    componentDidMount() {
        const { explainItem: { id = '' } = {}, getqueryGoodsComsumerRights } = this.props;
        if (id) getqueryGoodsComsumerRights({ id });
    }


    // 点击确认
    _handleOk = () => {
        const { closeMode, explainItem: { url = '', id = '' } = {}, form: { validateFieldsAndScroll } = {} } = this.props;
        validateFieldsAndScroll((err, values) => {
            const params = { id, ...values };
            if (!err) {
                this.setState({ confirmLoading: true });
                http(url, params, 'POST').then(res => {
                    message.success(res.message);
                    this.setState({ confirmLoading: false });
                    closeMode(true);
                }).catch(err => {
                    message.error(err.message);
                    this.setState({ confirmLoading: false });
                    closeMode();
                });
            }
        });
    }

    render() {
        const { confirmLoading } = this.state;
        const {
            form: { getFieldDecorator } = {},
            explainItem: { id = '' } = {},
            closeMode,
            queryGoodsComsumerRights: {
                name,
                desc
            } = {},
        } = this.props;

        return (
            <Modal
                width={480}
                centered
                title={id ? '编辑消费者权益' : '创建消费者权益说明'}
                visible={true}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={() => closeMode()}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                        <Form.Item label='服务说明'>
                            {getFieldDecorator('name', {
                                initialValue: id && name,
                                rules: [
                                    { required: true, message: '请输入服务说明' },
                                    { type: 'string', max: 22, mix: 2, message: '服务说明长度最小2，最大22' }
                                ],
                            })(<Input placeholder='请输入服务说明' />)}
                            <span>请输入服务说明:最少2个汉字，最多22个汉字</span>
                        </Form.Item>
                        <Form.Item label='说明描述'>
                            {getFieldDecorator('desc', {
                                initialValue: id && desc,
                                rules: [
                                    { required: true, message: '请输入说明描述' },
                                    { type: 'string', max: 500, message: '说明描述长度最大500' }
                                ],
                            })(<Input.TextArea placeholder='请输入说明描述' autoSize={{ minRows: 3, maxRows: 5 }} />)}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}
export default connect(state => ({
    queryGoodsComsumerRights: state.consumerknows.getqueryGoodsComsumerRights,
}), { ...model.actions })(Form.create({})(CreateEdit));