import { createModel } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 };

const model = {
    namespace: 'freeorderrefund',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getFreeOrderRefundList': T('getFreeOrderRefundList'),
            'getMerchantList': T('getMerchantList'),
        },
        actions: {
            'getFreeOrderRefundList': A('getFreeOrderRefundList'),
            'getMerchantList': A('getMerchantList'),
        },
        sagas: {
            'getFreeOrderRefundList': S('getFreeOrderRefundList', '/pay/transfer/listFreeOrderTransferRecord'),
            'getMerchantList': S('getMerchantList', '/enum/TyRechargeStatus'),
        },
        reducers: {
            'freeOrderRefundList': R('getFreeOrderRefundList', dataList),
            'merchantList': R('getMerchantList', []),
        }
    })
};
export default createModel(model);