/**
 * 【每日说界面管理页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Form, Tabs } from 'antd';
import { getCustomListTableSource } from '@/reducers/marketingmanage/appcustom/actions';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import http from '@/assets/api/http';
import './index.module.scss';

const { Meta } = Card;

const customCategories = {
    USER_APP: [
        {
            category: 'MALL',
            img: require('@/assets/image/appcustom/star-store.png'),
            title: '首页',
            description: '针对首页进行自定义页面编辑'
        },
        {
            category: 'HOME',
            img: require('@/assets/image/appcustom/home-page.png'),
            title: '秀场',
            description: '针对秀场-推荐页面进行自定义页面编辑'
        },
        {
            category: 'STORE',
            img: require('@/assets/image/appcustom/subject-page.png'),
            title: '商场',
            description: '针对商场进行自定义页面编辑'
        },
        // {
        //     category: 'GDC',
        //     img: require('@/assets/image/appcustom/start-page.png'),
        //     title: 'GDC专区',
        //     description: '对GDC专场进行自定义<br/>（针对文化艺术）'
        // },
        // {
        //     category: 'CHARGE',
        //     img: require('@/assets/image/appcustom/start-page.png'),
        //     title: '知识付费',
        //     description: '对知识付费进行自定义'
        // },
        // {
        //     category: 'START',
        //     img: require('@/assets/image/appcustom/start-page.png'),
        //     title: '启动页',
        //     description: '针对启动页进行页自定义'
        // },
        {
            category: 'STARTADVERT',
            img: require('@/assets/image/appcustom/adver-page.png'),
            title: '启动广告页',
            description: '对发现进行页面自定义<br/>（针对线下商户）'
        },
        // {
        //     category: 'GUIDE',
        //     img: require('@/assets/image/appcustom/guide-page.png'),
        //     title: '引导页',
        //     description: '针对引导页进行自定义'
        // }
    ],
    SALE_APPLETS: [
        {
            category: 'APPLET_SALE_HOME',
            img: require('@/assets/image/appcustom/star-store.png'),
            title: '首页',
            description: '首页是整个小程序的门面，通过此功能可快速管理小程序的首页板式'
        },
    ],
    GIFT_APPLETS: [
        {
            category: 'APPLET_GIFT_HOME',
            img: require('@/assets/image/appcustom/star-store.png'),
            title: '首页',
            description: '首页是整个小程序的门面，通过此功能可快速管理小程序的首页板式'
        },
    ],
    Common: [
        {
            category: 'CUSTOM',
            img: require('@/assets/image/appcustom/subject-page.png'),
            title: '自定义页面',
            description: '针对各种特殊场景进行页面编辑与管理'
        },
        {
            category: 'POPUP',
            img: require('@/assets/image/appcustom/star-store.png'),
            title: '活动弹框页面',
            description: '首页弹框页面编辑，支持各种图片弹窗'
        }
    ],
    ACTIVITY_APPLETS: [
        {
            category: 'APPLET_SUBSIDY_HOME',
            img: require('@/assets/image/appcustom/star-store.png'),
            title: '首页',
            description: '首页是整个小程序的门面，通过此功能可快速管理小程序的首页板式'
        },
    ],
    MULAOXIAN_APPLETS: [
        {
            category: 'MULAOXIAN_APPLETS_HOME',
            img: require('@/assets/image/appcustom/star-store.png'),
            title: '首页',
            description: '首页是整个小程序的门面，通过此功能可快速管理小程序的首页板式'
        },
    ],
};

class Custom extends React.Component {

    state = {
        activeKey: 'USER_APP'
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    onClick = async (card) => {

        var formMode = ['HOME', 'MALL', 'GDC', 'CHARGE', 'STORE'].includes(card.category) ? 'normal' : card.category.toLowerCase();
        switch (card.category) {
            case 'HOME':
            case 'MALL':
            case 'SUBJECT':
            case 'GDC':
            case 'CHARGE':
            case 'STORE':
            case 'APPLET_SALE_HOME':
            case 'APPLET_GIFT_HOME':
            case 'APPLET_SUBSIDY_HOME':
            case 'MULAOXIAN_APPLETS_HOME':
                var result = await http('/custom/appHomePage/getList', { pageSize: 10, pageNum: 1, category: card.category })
                    .then(res => res || { result: { dataList: [] } })
                    .catch(() => ({ result: { dataList: [] } }));
                var hadData = result.result.dataList.length > 0;

                var stateParams = { category: card.category, mode: 'control', formMode, sourceType: this.state.activeKey };

                if (card.category === 'SUBJECT') {
                    hadData = true;
                    delete stateParams.sourceType;
                }

                var target = hadData ? 'customlist' : 'customentrance';

                this.props.addOneBreadcrumbPath({
                    title: card.title + (hadData ? '列表' : ''),
                    path: '/home/marketingmanage/appcustom/' + target
                });
                this.props.history.push({
                    pathname: '/home/marketingmanage/appcustom/' + target,
                    state: { category: card.category, mode: 'control', formMode, sourceType: this.state.activeKey }
                });
                break;

            case 'ADVERT':
                hadData = true;
                this.props.addOneBreadcrumbPath({
                    title: card.title + (hadData ? '列表' : ''),
                    path: '/home/marketingmanage/appcustom/' + (hadData ? 'customlist/advert/' : 'customentrance')
                });
                this.props.history.push({
                    pathname: '/home/marketingmanage/appcustom/' + (hadData ? 'customlist/advert/' : 'customentrance'),
                    state: { category: card.category, mode: 'control', formMode }
                });
                break;
            case 'START':
            case 'GUIDE':
            case 'STARTADVERT':
                this.props.addOneBreadcrumbPath({
                    title: card.title,
                    path: '/home/marketingmanage/appcustom/customlanding/'
                });
                this.props.history.push({
                    pathname: '/home/marketingmanage/appcustom/customlanding/',
                    state: { category: card.category, mode: 'normal', componentKey: card.category, sourceType: this.state.activeKey }
                });
                break;
            case 'CUSTOM':
                hadData = true;
                target = hadData ? 'customlist/custom/CUSTOM' : 'customentrance';

                this.props.addOneBreadcrumbPath({
                    title: card.title + (hadData ? '列表' : ''),
                    path: '/home/marketingmanage/appcustom/' + target
                });
                this.props.history.push({
                    pathname: '/home/marketingmanage/appcustom/' + target,
                    state: { category: card.category, mode: 'control', formMode }
                });
                break;
            case 'POPUP':
                this.props.addOneBreadcrumbPath({
                    title: '活动弹框页面',
                    path: '/home/marketingmanage/appcustom/popupcustom'
                });
                this.props.history.push({
                    pathname: '/home/marketingmanage/appcustom/popupcustom',
                });
                break;
            default:
                break;
        }
    }

    render() {

        return (
            <div style={{ maxWidth: 1400 }}>
                <Tabs activeKey={this.state.activeKey} onChange={activeKey => this.setState({ activeKey })}>
                    <Tabs.TabPane tab="每日说App" key="USER_APP">
                        {
                            customCategories.USER_APP.map((card, index) => (
                                <Card
                                    key={index.toString()}
                                    className="appcustom-main-card"
                                    hoverable
                                    cover={
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ width: 51, height: 51, marginTop: 61 }} src={card.img} />
                                        </div>
                                    }
                                    onClick={() => this.onClick(card)}
                                >
                                    <Meta title={card.title} description={<div dangerouslySetInnerHTML={{ __html: card.description }}></div>} />
                                </Card>
                            ))
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="每日大本营（小程序）" key="SALE_APPLETS">
                        {
                            customCategories.SALE_APPLETS.map((card, index) => (
                                <Card
                                    key={index.toString()}
                                    className="appcustom-main-card"
                                    hoverable
                                    cover={
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ width: 51, height: 51, marginTop: 61 }} src={card.img} />
                                        </div>
                                    }
                                    onClick={() => this.onClick(card)}
                                >
                                    <Meta title={card.title} description={<div dangerouslySetInnerHTML={{ __html: card.description }}></div>} />
                                </Card>
                            ))
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="每日礼屋（小程序）" key="GIFT_APPLETS">
                        {
                            customCategories.GIFT_APPLETS.map((card, index) => (
                                <Card
                                    key={index.toString()}
                                    className="appcustom-main-card"
                                    hoverable
                                    cover={
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ width: 51, height: 51, marginTop: 61 }} src={card.img} />
                                        </div>
                                    }
                                    onClick={() => this.onClick(card)}
                                >
                                    <Meta title={card.title} description={<div dangerouslySetInnerHTML={{ __html: card.description }}></div>} />
                                </Card>
                            ))
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="每日补贴（小程序）" key="ACTIVITY_APPLETS">
                        {
                            customCategories.ACTIVITY_APPLETS.map((card, index) => (
                                <Card
                                    key={index.toString()}
                                    className="appcustom-main-card"
                                    hoverable
                                    cover={
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ width: 51, height: 51, marginTop: 61 }} src={card.img} />
                                        </div>
                                    }
                                    onClick={() => this.onClick(card)}
                                >
                                    <Meta title={card.title} description={<div dangerouslySetInnerHTML={{ __html: card.description }}></div>} />
                                </Card>
                            ))
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="牧老鲜（小程序）" key="MULAOXIAN_APPLETS">
                        {
                            customCategories.MULAOXIAN_APPLETS.map((card, index) => (
                                <Card
                                    key={index.toString()}
                                    className="appcustom-main-card"
                                    hoverable
                                    cover={
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ width: 51, height: 51, marginTop: 61 }} src={card.img} />
                                        </div>
                                    }
                                    onClick={() => this.onClick(card)}
                                >
                                    <Meta title={card.title} description={<div dangerouslySetInnerHTML={{ __html: card.description }}></div>} />
                                </Card>
                            ))
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="通用模块" key="Common">
                        {
                            customCategories.Common.map((card, index) => (
                                <Card
                                    key={index.toString()}
                                    className="appcustom-main-card"
                                    hoverable
                                    cover={
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ width: 51, height: 51, marginTop: 61 }} src={card.img} />
                                        </div>
                                    }
                                    onClick={() => this.onClick(card)}
                                >
                                    <Meta title={card.title} description={<div dangerouslySetInnerHTML={{ __html: card.description }}></div>} />
                                </Card>
                            ))
                        }
                    </Tabs.TabPane>
                </Tabs>
            </div>
        );
    }
}

Custom.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getCustomListTableSource: PropTypes.func,
    form: PropTypes.object
};

const WrappedTimeRelatedForm = Form.create({ name: 'time_related_controls' })(Custom);

export default connect(state => ({
    customListTable: state.appcustom.customListTable
}), { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getCustomListTableSource })(WrappedTimeRelatedForm);