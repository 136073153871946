/**
 * 区域公司分销自购销售情况
 */
import React from 'react';
import { Tag, message } from 'antd';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XDatePicker, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import TableContent from './components/TableContent';
import model from '@/reducers/statisticsofdistributor/regionalcompanydistributionsalepurchase/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import SelectScroll from './components/selectScroll';
import { SearchBox, SearchItem } from '@/components/Layout';
import http from '@/assets/api/http';

const tags = [
    { id: 0, color: '#87d068', value: '今日' },
    { id: 1, color: '#f50', value: '昨日' },
    { id: 2, color: '#2db7f5', value: '本周' },
    { id: 3, color: '#87d068', value: '本月' },
    { id: 4, color: '#33ccc9', value: '本季' },
    { id: 5, color: '#108ee9', value: '本年' },
];

const timeKeys = {
    0: 'today',
    1: 'yesterday',
    2: 'week',
    3: 'month',
    4: 'quarter',
    5: 'year'
};

class Main extends React.Component {

    state = {
        startDate: undefined,
        endDate: undefined,
        curTagID: 0,
        period: 'today',
        company: {},
        shareLoading: false
    };

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, period, startDate, endDate, company } = this.state;
        let query = {};
        if (startDate && endDate) {
            query = {
                areaCompanyId: company.id,
                startDate,
                endDate,
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            this.setState({ period: undefined, curTagID: 0 });
        } else if (period) {
            query = {
                areaCompanyId: company.id,
                period,
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            this.setState({ startDate: undefined, endDate: undefined });
        } else {
            message.error('请选择付款日期范围');
        }
        this.props.getUserReport(query);
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ startDate: undefined, endDate: undefined, curTagID: 0, period: 'today', company: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 渲染Tags
    _renderTags = () => {
        const { curTagID } = this.state;
        return (
            <div style={{ marginLeft: '20px' }}>
                {tags.map((item, index) => {
                    let color = curTagID == item.id ? item.color : '';
                    return <Tag style={{ width: '50px', textAlign: 'center', cursor: 'pointer' }} key={index} color={color} onClick={() => { this._tagClick(item.id); }}>{item.value}</Tag>;
                })}
            </div>
        );
    }

    // 点击Tags
    _tagClick = (id) => {
        this.setState({ curTagID: id, startDate: undefined, endDate: undefined }, () => {
            this.setState({ period: timeKeys[id] }, () => this._searchHandle('useCache'));
        });
    }

    generateReport = () => {
        this.setState({ shareLoading: true }, () => {
            const { pageNum, pageSize, period, startDate, endDate, company } = this.state;
            let createQuery = {};
            if (startDate && endDate) {
                createQuery = {
                    areaCompanyId: company.id,
                    startDate,
                    endDate,
                    pageNum,
                    pageSize,
                };
                this.setState({ period: undefined, curTagID: 0 });
            } else if (period) {
                createQuery = {
                    period,
                    areaCompanyId: company.id,
                    pageNum,
                    pageSize,
                };
                this.setState({ startDate: undefined, endDate: undefined });
            }
            http('/boss/order/distribution/distriButionSelfPurchaseOrderForAreaCompanyExportExcel', { ...createQuery }, 'POST')
                .then((res = {}) => {
                    this.setState({ shareLoading: false });
                    if (res.status == '200' && res.result) {
                        window.location.href = res.result;
                        message.success('导出成功');
                    } else if (res.status == '200' && res.result == '') {
                        message.warning('暂无数据');
                    } else {
                        message.warning('后台数据生成中,请稍后点击下载');
                    }
                }).catch((e = {}) => {
                    this.setState({ shareLoading: false });
                    message.error(e.message);
                });
        });
    }

    render() {
        const { startDate, endDate, company, shareLoading } = this.state;
        const { userReport } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <SelectScroll
                                selectStyle={{ width: '150px', marginRight: 15 }}
                                label='区域公司'
                                placeholder='请选择'
                                bindThis={this}
                                bindName='company'
                                value={company.companyName}
                                dataIndex='companyName'
                                keyIndex='id'
                                apiUrl='/admin/company/listAreaCompanyIdAndName'
                                scrollParams={{
                                    companyName: '',
                                    pageNum: 1,
                                    pageSize: 50
                                }}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='选择日期'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                label=''
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            {this._renderTags()}
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div>
                        <XOKButton style={{ marginTop: 10, width: 100 }} label='生成报表' onClick={this.generateReport} loading={shareLoading} />
                    </div>
                    <TableContent renderData={userReport} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getUserReport: PropTypes.func,
    userReport: PropTypes.object
};
const mapStateToProps = (state) => ({
    userReport: state.regionalcompanydistributionsalepurchase.getUserReport
});
export default connect(mapStateToProps, { ...model.actions, keepSecondNavBreadcrumb })(Main);