import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const columns = (TableAction, actionClickHandle) => [
	{
		title: '品牌名称',
		dataIndex: 'brandName',
		key: 'brandName',
		align: 'center',
		width: '30%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '状态',
		dataIndex: 'isSync',
		key: 'isSync',
		align: 'center',
		width: '20%',
		onCell: tooltipStyle,
		render: (val)=>('未已'.charAt(val)+'同步')
	},
	{
		title: '同步时间',
		dataIndex: 'updateTime',
		key: 'updateTime',
		align: 'center',
		width: '20%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '操作',
		dataIndex: 'id',
		key: 'id',
		align: 'center',
		width: '20%',
		render: (id) => TableAction(id, actionClickHandle)
	},
];

// 表格操作
const TableAction = (key, actionClickHandle) => {
	
	return (
		<div className={styles.action}>
			<div className={styles.item} onClick={() => actionClickHandle(key, 'delete')}>删除</div>
		</div>
	);
};

class TableData extends Component {

	// 操作表格
	_actionHandle = (id, type, value) => {
		const { tableAction } = this.props;
		tableAction(id, type, value);
	}

	//处理表格数据
	_processingData = (data) => {
		return data;
	}

	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys) => {
			this._actionHandle(null, 'select', selectedRowKeys);
		}
	};

	render() {
		const { renderData, selectedRowKeys } = this.props;
		this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey='id'
					rowSelection={this._rowSelection}
					columns={columns(TableAction, this._actionHandle)}
					pagination={false}
					dataSource={this._processingData(renderData.dataList)}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={this.props.paginationChange} />
			</div>
		);
	}
}


TableData.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
	selectedRowKeys: PropTypes.array
};



export default TableData;