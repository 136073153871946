/**
 * 业务员新建/编辑
 */
import React from 'react';
import { Modal, message, Divider } from 'antd';
import { XSelect, XInput } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './EditModal.module.scss';
import http from '@/assets/api/http';
import member_model from '@/reducers/usermanage/member/model';
import { connect } from 'react-redux';

class EditModal extends React.Component {
    state = {
        OCSelect: {}, // 运营中心
        RESelect: {}, // 区域公司
        realName: '', // 姓名
        accountNo: '', // 账号
        mobilePhone: '', // 手机号
        idCard: '', // 身份证
        confirmLoading: false
    };

    componentDidMount() {
        const { param } = this.props;
        if (param.type == 'edit' && param.id) {
            http('/settle/stock/getStockBuyerInfo', { id: param.id }, 'POST').then((response) => {
                const result = response.result;
                const { operationsId, operationsName, areaCompanyId, areaCompanyName, realName, accountNo, mobilePhone, idCard } = result;
                this.setState({
                    OCSelect: {
                        id: operationsId,
                        companyName: operationsName
                    },
                    RESelect: {
                        id: areaCompanyId,
                        companyName: areaCompanyName
                    },
                    realName,
                    accountNo,
                    mobilePhone,
                    idCard
                });
            }).catch((err = {}) => {
                message.error(err.message);
            });
        }
    }

    _handleOk = () => {
        const { param } = this.props;
        const { RESelect, realName, accountNo, mobilePhone, idCard } = this.state;
        let id = undefined;
        if (param.type == 'edit' && param.id) {
            id = param.id;
        }

        const query = {
            id,
            areaCompanyId: RESelect.id,
            realName,
            accountNo,
            mobilePhone,
            idCard
        };

        this.setState({ confirmLoading: true });
        http('/settle/stock/saveStockBuyer', { ...query }, 'POST').then((response) => {
            this.props.close('update');
            this.setState({ confirmLoading: false });
        }).catch((err = {}) => {
            message.error(err.message);
            this.setState({ confirmLoading: false });
        });
    }

    _handleCancel = () => {
        this.props.close();
    }

    // 渲染运营中心下拉列
    _renderOCSelect = () => {
        return (
            <XSelect
                style={{ marginBottom: '10px' }}
                selectStyle={{ width: '250px' }}
                label='归属运营中心'
                placeholder='请选择运营中心'
                renderData={this.props.OCSelectList}
                dataIndex='companyName'
                keyIndex='id'
                value={this.state.OCSelect.companyName}
                onChange={this._OCSelectChangeHandle}
                bindThis={this}
                bindName='OCSelect'
                showSearch={true}
            />
        );
    }

    // 运营中心发生变化
    _OCSelectChangeHandle = (ocItem) => {
        this.props.getAreaCompany({ operationsId: ocItem.id });
    }

    // 渲染区域公司下拉列
    _renderRESelect = () => {
        return (
            <XSelect
                style={{ marginBottom: '10px' }}
                selectStyle={{ width: '250px' }}
                label='归属区域公司'
                placeholder='请选择区域公司'
                renderData={this.props.RESelectList}
                dataIndex='companyName'
                keyIndex='id'
                value={this.state.RESelect.companyName}
                bindThis={this}
                bindName='RESelect'
                showSearch={true}
            />
        );
    }

    render() {
        const { param } = this.props;
        const { confirmLoading, realName, accountNo, mobilePhone, idCard, } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={param.type == 'new' ? '新建业务员' : '编辑'}
                visible={true}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    {this._renderOCSelect()}
                    {this._renderRESelect()}
                    <XInput
                        style={{ marginBottom: '10px', marginLeft: '56px' }}
                        inputStyle={{ width: '250px' }}
                        label='姓名'
                        placeholder='请输入'
                        value={realName}
                        bindThis={this}
                        bindName='realName'
                        maxLength={30}
                    />
                    <XInput
                        style={{ marginBottom: '10px', marginLeft: '56px' }}
                        inputStyle={{ width: '250px' }}
                        label='账号'
                        placeholder='请输入'
                        value={accountNo}
                        bindThis={this}
                        bindName='accountNo'
                        maxLength={8}
                    />
                    <XInput
                        style={{ marginBottom: '10px', marginLeft: '42px' }}
                        inputStyle={{ width: '250px' }}
                        label='手机号'
                        placeholder='请输入'
                        value={mobilePhone}
                        bindThis={this}
                        bindName='mobilePhone'
                        maxLength={11}
                    />
                    <XInput
                        style={{ marginBottom: '10px', marginLeft: '42px' }}
                        inputStyle={{ width: '250px' }}
                        label='身份证'
                        placeholder='请输入'
                        value={idCard}
                        bindThis={this}
                        bindName='idCard'
                        maxLength={18}
                    />
                </div>
            </Modal>
        );

    }
}

EditModal.propTypes = {
    param: PropTypes.object, // 弹框入参
    close: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getOperationCenter: PropTypes.func, // 获取归属运营中心
    OCSelectList: PropTypes.array, // 归属运营中心下拉列
    getAreaCompany: PropTypes.func, // 获取归属区域公司
    RESelectList: PropTypes.array, // 归属区域公司下拉列
};

const mapStateToProps = (state) => ({
    OCSelectList: state.member.getOperationCenter,
    RESelectList: state.member.getAreaCompany,
});

export default connect(mapStateToProps, { ...member_model.actions })(EditModal);
