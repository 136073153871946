/**
 * 查看物流弹框
 */
import React, { useState, useEffect } from 'react';
import { Modal, Form, message, Input } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';

function LeaveMsg(props) {

    const [visible, setVisible] = useState(props.visible);
    const [msg, setMsg] = useState(0);

    const _handleSubmit = e => {
        const { form, modeParams } = props;
        if (e) e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                http('/boss/order/insertSellerRemark', { buyerId: modeParams.buyerId, orderId: modeParams.orderId, content: values.sellerRemark }, 'POST')
                    .then(() => {
                        message.success('留言成功！');
                        _handleCancel();
                    }).catch((reject) => {
                        message.error(reject.message);
                    });
            }
        });
    };

    const _handleOk = () => {
        _handleSubmit();
    };

    const _handleCancel = () => {
        setVisible(false);
        props.closeMode();
    };

    const formItemLayout = {
        labelCol: { xs: { span: 24 }, sm: { span: 5 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 19 } }
    };

    const _lengthVerify = (rule, value, callback) => {
        if (value.length > 250) {
            callback('留言不超过250个字符！');
        } else {
            callback();
        }
    };

    const { getFieldDecorator } = props.form;
    const { TextArea } = Input;

    return (
        <Modal
            width={500}
            centered
            title='卖家留言'
            visible={visible}
            confirmLoading={false}
            onOk={_handleOk}
            onCancel={_handleCancel}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <Form id='LeaveMsg' {...formItemLayout} colon={false} onSubmit={_handleSubmit}>
                <Form.Item label="卖家留言">
                    {getFieldDecorator('sellerRemark', {
                        rules: [
                            {
                                required: true,
                                message: '请输入卖家留言！'
                            },
                            {
                                validator: _lengthVerify
                            }
                        ],
                    })(<div style={{ textAlign: 'right' }}>
                        <TextArea placeholder='请输入卖家留言' maxLength={250} autoSize={{ minRows: 12, maxRows: 12 }} onChange={(e) => setMsg(e.target.value.length)} />
                        <span style={{ height: 0, paddingRight: '10px', position: 'relative', bottom: '40px' }}>{msg}/250</span>
                    </div>
                    )}
                </Form.Item>
            </Form>
        </Modal>
    );
}

LeaveMsg.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    modeParams: PropTypes.object.isRequired,//弹框入参
    form: PropTypes.object.isRequired//form表单
};

export default Form.create({ name: 'LeaveMsg' })(LeaveMsg);