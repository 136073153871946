/**
 *  审核管理 - 商户审核
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const Mode = {
    MER_AUDIT: 'MER_AUDIT',//审核
    MER_DETAIL: 'MER_DETAIL',//详情
    MER_STOR: 'MER_STOR',//店铺
};

class businessaudit extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value, value2) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case Mode.MER_STOR:  //店铺列表
                addOneBreadcrumbPath({
                    title: '店铺',
                    path: '/home/auditmanage/businessaudit/shopInfo/',
                });
                history.push({
                    pathname: '/home/auditmanage/businessaudit/shopInfo/' + value,
                });
                break;
            case Mode.MER_DETAIL: //商户详情
                addOneBreadcrumbPath({
                    title: '详情',
                    path: '/home/auditmanage/businessaudit/detailInfo/',
                });
                history.push({
                    pathname: '/home/auditmanage/businessaudit/detailInfo/' + value + '/' + value2,
                });
                break;
            case Mode.MER_AUDIT:
                addOneBreadcrumbPath({
                    title: '审核',
                    path: '/home/auditmanage/businessaudit/auditInfo/'
                });
                history.push({
                    pathname: '/home/auditmanage/businessaudit/auditInfo/' + value,
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

businessaudit.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(businessaudit);