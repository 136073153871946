import * as T from './actiontypes';

//获取违规的店铺列表
export const getIllegalShopList = ({ violationState, merchantName, pageNum, pageSize }) => ({
    type: T.GET_ILLEGAL_SHOPLIST,
    payload: {
        violationState, merchantName, pageNum, pageSize
    }
});

//获取违规的列表的下拉
export const getIllegalSele = () => ({
    type: T.GET_ILLEGAL_SELE,
    payload: {}
});

//获取违规的店铺列表详情
export const getIllegalShopDetail = (id) => ({
    type: T.GET_ILLEGAL_SHOPDETAIL,
    payload: { id }
});

//获取违规的商品列表
export const getIllegalProductList = ({ goodsName, goodsNo, violationState, pageNum, pageSize }) => ({
    type: T.GET_ILLAGEL_PRODUCTLIST,
    payload: {
        goodsName, goodsNo, violationState, pageNum, pageSize
    }
});

//获取违规的商品列表详情
export const getIllegalProductDetail = (violationId) => ({
    type: T.GET_ILLEGAL_PRODUCTDETAIL,
    payload: { violationId }
});

//获取违规的商品列表的下拉
export const getIllegalProduct = () => ({
    type: T.GET_ILLEGAL_PRODUCT,
    payload: {}
});