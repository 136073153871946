import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

// 获取服务商审核状态
export const getServiceState = function* () {
    yield takeEvery(T.GET_SERVICE_STATE, function* requestData(action) {
        try {
            let result = yield http('/enum/ServiceApplyTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_SERVICE_STATE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取区域公司下拉列
export const getAreaCompany = function* () {
    yield takeEvery(T.GET_AREA_COMPANY, function* requestData(action) {
        try {
            let result = yield http('/admin/company/listAreaCompanyIdAndName', action.payload, 'POST');
            yield put({ type: T.SET_AREA_COMPANY, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取关联状态下拉列
export const getAssoTypeList = function* () {
    yield takeEvery(T.GET_ASSOTYPE_LIST, function* requestData(action) {
        try {
            let result = yield http('/enum/StateEnum', action.payload, 'POST');
            yield put({ type: T.SET_ASSOTYPE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取服务商列表
export const getServiceList = function* () {
    yield takeEvery(T.GET_SERVICE_LIST, function* requestData(action) {
        try {
            let result = yield http('/service/provider/infoList', action.payload, 'POST');
            yield put({ type: T.SET_SERVICE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取服务商详情
export const getServiceDetail = function* () {
    yield takeEvery(T.GET_SERVICE_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/service/provider/info', action.payload, 'POST');
            yield put({ type: T.SET_SERVICE_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取服务商列表
export const getSAccountList = function* () {
    yield takeEvery(T.GET_SACCOUNT_LIST, function* requestData(action) {
        try {
            let result = yield http('/admin/service/listAccount', action.payload, 'POST');
            yield put({ type: T.SET_SACCOUNT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 心选推荐列表
export const getSRecommendList = function* () {
    yield takeEvery(T.GET_SRECOMMEND_LIST, function* requestData(action) {
        try {
            let result = yield http('/admin/service/listSupplier', action.payload, 'POST');
            yield put({ type: T.SET_SRECOMMEND_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 推荐商户列表
export const getSMerchantList = function* () {
    yield takeEvery(T.GET_SMERCHANT_LIST, function* requestData(action) {
        try {
            let result = yield http('/admin/service/listMerchant', action.payload, 'POST');
            yield put({ type: T.SET_SMERCHANT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取分销商品列表
export const getGoodsList = function* () {
    yield takeEvery(T.GET_GOODS_LIST, function* requestData(action) {
        try {
            let result = yield http('/goods/smallGoods/findSmallGoodsOprLogPage', action.payload, 'POST');
            yield put({ type: T.SET_GOODS_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取服务商推荐列表
export const getRecommendedList = function* () {
    yield takeEvery(T.GET_RECOMMENDED_LIST, function* requestData(action) {
        try {
            let result = yield http('/service/provider/listServiceProviderByRecommendId', action.payload, 'POST');
            yield put({ type: T.SET_RECOMMENDED_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取服务商收益汇总
export const getServiceIcomeTotal = function* () {
    yield takeEvery(T.GET_SERVICE_ICOME_TOTAL, function* requestData(action) {
        try {
            let result = yield http('/service/provider/getServiceProviderEarningsSummary', action.payload, 'POST');
            yield put({ type: T.SET_SERVICE_ICOME_TOTAL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取服务商收益明细列表
export const getServiceIcomeTable = function* () {
    yield takeEvery(T.GET_SERVICE_ICOME_TABLE, function* requestData(action) {
        try {
            let result = yield http('/service/provider/getServiceProviderEarningsList', action.payload, 'POST');
            yield put({ type: T.SET_SERVICE_ICOME_TABLE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取收支明细中费用名称下拉列
export const getCostNameSelect = function* () {
    yield takeEvery(T.GET_COST_NAME, function* requestData(action) {
        try {
            let result = yield http('/service/provider/serviceProviderCostNameDropDownList', action.payload, 'POST');
            yield put({ type: T.SET_COST_NAME, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 获取能量值信息
 */
export const getJibeanInfo = function* () {
    yield takeEvery(T.GET_JIBEAN_INFO, function* requestData(action) {
        try {
            let result = yield http('/gatherBeans/service/' + action.payload.serviceId, action.payload, 'POST');
            yield put({ type: T.SET_JIBEAN_INFO, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 获取能量值列表
 */
export const getJibeanList = function* () {
    yield takeEvery(T.GET_JIBEAN_LIST, function* requestData(action) {
        try {
            let result = yield http('/gatherBeans/service/show', action.payload, 'POST');
            yield put({ type: T.SET_JIBEAN_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 获取贡献值信息
 */
export const getXiubeiInfo = function* () {
    yield takeEvery(T.GET_XIUBEI_INFO, function* requestData(action) {
        try {
            let result = yield http('/boss/xiubei/' + action.payload.serviceId, action.payload, 'POST');
            yield put({ type: T.SET_XIUBEI_INFO, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 获取贡献值列表
 */
export const getXiubeiList = function* () {
    yield takeEvery(T.GET_XIUBEI_LIST, function* requestData(action) {
        try {
            let result = yield http('/boss/xiubei/listDetail', action.payload, 'POST');
            yield put({ type: T.SET_XIUBEI_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 获取服务商推荐赠送贡献值信息列表
 */
export const getServiceXiubeiCodeList = function* () {
    yield takeEvery(T.GET_SERVICE_XIUBEI_CODELIST, function* requestData(action) {
        try {
            let result = yield http('/gatherbeans/serviceShowShellCodeList', action.payload, 'POST');
            yield put({ type: T.SET_SERVICE_XIUBEI_CODELIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 推荐人来源枚举
 */
export const getRecommentTypeEnum = function* () {
    yield takeEvery(T.GET_RECOMMENT_TYPE_ENUM, function* requestData(action) {
        try {
            let result = yield http('/enum/RecommendTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_RECOMMENT_TYPE_ENUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 账号状态枚举
 */
export const getStateEnum = function* () {
    yield takeEvery(T.GET_STATE_ENUM, function* requestData(action) {
        try {
            let result = yield http('/enum/StateEnum', action.payload, 'POST');
            yield put({ type: T.SET_STATE_ENUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 获取推荐小B列表
 */
export const getSalersList = function* () {
    yield takeEvery(T.GET_SALERS_LIST, function* requestData(action) {
        try {
            let result = yield http('/user/account/listRecoSalesman', action.payload, 'POST');
            yield put({ type: T.SET_SALERS_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 获取特权值明细
 */
export const getFranchiseList = function* () {
    yield takeEvery(T.GET_FRANCHISE_LIST, function* requestData(action) {
        try {
            let result = yield http('/service/provider/listIntegralDetail', action.payload, 'POST');
            yield put({ type: T.SET_FRANCHISE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};