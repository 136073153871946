/**
 * 查看物流弹框
 */
import React from 'react';
import { Modal, Steps, Divider, message } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './LogisticsModal.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import { XInfoLabel } from '@/components/xqxc_ui';
import copy from 'copy-to-clipboard';

const { Step } = Steps;
const customDot = (dot, { status }) => {
    if (status === 'process') {
        return (<div className={styles.dot}></div>);
    } else {
        return (<div className={styles.dotFinish}></div>);
    }
};
const description = (text, time, actived) => {
    if (actived) {
        return (
            <div className={styles.description}>
                <div>{text}</div>
                <div>{time}</div>
            </div>
        );
    } else {
        return (
            <div className={styles.descriptionFinish}>
                <div>{text}</div>
                <div>{time}</div>
            </div>
        );
    }
};

class LogisticsModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        current: 0,
        curTabID: 1,
        tab: [
            { id: 1, label: '包裹 1' },
        ],
        orderDetail: [],
    }
    componentDidMount() {
        const { data } = this.props;
        http('/boss/order/getOrderShipmentList', { orderId: data }, 'POST')
            .then((res) => {
                var tabData = res.result.map((i, index) => {
                    return { id: index + 1, label: `包裹 ${index + 1}` };
                });
                this.setState({
                    orderDetail: res.result || [],
                    tab: tabData,
                });
            }).catch((reject) => {
                message.error(reject.message);
            });

    }
    // 复制快递单号
    _copyHandle = (expressNo) => {
        if (copy(expressNo)) {
            message.info('复制成功');
        } else {
            message.info('复制失败');
        }
    }
    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }
    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, current, orderDetail = [], curTabID } = this.state;
        return (
            <Modal
                width={470}
                centered
                title='查看物流'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <div className={styles.productStyle}>
                        {orderDetail[curTabID - 1] && orderDetail[curTabID - 1].shipmentItemRespsList.map((j, index) => {
                                return <div key={index}>
                                    <p>{j.goodsName}</p>
                                    <p className={styles.productDetailStyle}>
                                        {j.goodsSpecList && j.goodsSpecList.map((k, indexx) => {
                                            return <span key={indexx} className={styles.productAttrStyle}>{k.specValue}</span>;
                                        })};
                                       <span className={styles.productNumStyle}>x {j.number}</span>
                                    </p>
                                </div>;
                        })}
                    </div>
                    { orderDetail.length > 0 ? (<div className={styles.baseInfo}>
                        <XInfoLabel labelWith={70} label="配送方式：" value={orderDetail[curTabID - 1] && orderDetail[curTabID - 1].deliveryMethod} />
                        <XInfoLabel style={{ marginTop: '10px' }} labelWith={70} label="快递公司：" value={orderDetail[curTabID - 1] && orderDetail[curTabID - 1].logisticsCompany} />
                        <div className={styles.order}>
                            <XInfoLabel labelWith={70} label="快递单号：" value={orderDetail[curTabID - 1] && orderDetail[curTabID - 1].expressNo} />
                            <div className={styles.copy} onClick={() => this._copyHandle(orderDetail[curTabID - 1] && orderDetail[curTabID - 1].expressNo)}>复制</div>
                        </div>
                    </div>) : (<div style={{ textAlign: 'center' }}>暂无物流信息</div>)}
                    <Divider />
                    <Steps className={styles.steps} current={current} progressDot={customDot} direction="vertical">
                        { orderDetail[curTabID - 1] && orderDetail[curTabID - 1].expressList.map((i, index) => {
                            return <Step key={index} className={styles.step} title="" description={description(i.context, i.ftime, true)} />;
                        })}
                    </Steps>,
                </div>
            </Modal>
        );
    }
}

LogisticsModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    data: PropTypes.number,
};

export default LogisticsModal;