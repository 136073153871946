/**
 * 营销活动规则设置商户选择
 */
import React from 'react';
import { Modal, message } from 'antd';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './ChooseModal.module.scss';

import ChooseCreate from '../pages/main/components/ChooseCreate';
import { getMarketingCampaignUser } from '@/reducers/systemmanage/marketingCampaign/actions';

class ChooseModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        pageNum: 1,
        pageSize: 20,
        merchantName: '',//商户名称
        batch: []
    }

    componentDidMount() {
        const { pageNum, pageSize } = this.state;
        this.props.getMarketingCampaignUser({ pageNum, pageSize });
    }
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }
    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        const { merchantName, isCheck } = this.state;
        if (!isCheck) {
            this.props.getMarketingCampaignUser({ pageNum, pageSize });
        } else {
            this.props.getMarketingCampaignUser({ merchantName, pageNum, pageSize });
        }
    }
    //营销数据与 订单数据切换
    TabsCallback = (activeKey) => {
        console.log(activeKey);
    }

    // 查询触发
    _searchHandle = () => {
        const { pageNum, pageSize, merchantName } = this.state;
        this.props.getMarketingCampaignUser({ merchantName, pageNum, pageSize });
    }
    // // 全选
    // _checkAllHandle = () => {

    // }

    _sendReq = (data) => {
        http('/merchantAuditSetting/add', data, 'POST')
            .then(res => {
                message.success(res.message);
                this.setState({
                    visible: false,
                    confirmLoading: false
                });
                this.props.closeMode(true);
            }).catch((error) => {
                message.warn(error.message);
                this.setState({
                    confirmLoading: false
                });
            });
    }
    // 确定
    _OKHandle = () => {
        const { batch } = this.state;
        if (!batch.length) {
            message.warning('请选择商户');
            return;
        }
        this.setState({ confirmLoading: true }, () => {
            this._sendReq(batch);
        });
    }

    _resetHandle = () => {
        this.setState({ merchantName: '', pageNum: 1, pageSize: 20 });
        this.props.getMarketingCampaignUser({ pageNum: 1, pageSize: 20 });
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        console.log(id, type, value);
        switch (type) {
            case 'operation':
                console.log('操作');
                break;
            case 'batch':
                console.log(value);
                this.setState({ batch: value });
                break;
        }
    }

    render() {
        const { marketingCampaignUser } = this.props;
        const { visible, title, confirmLoading, merchantName, } = this.state;
        return (
            <Modal
                width={860}
                bodyStyle={{ paddingBottom: 0 }}
                centered
                title={title}
                visible={visible}
                onOk={this._OKHandle}
                onCancel={this._handleCancel}
                confirmLoading={confirmLoading}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={`${styles.layout} ${styles.dataTop}`}>
                        <XInput
                            style={{ width: '216px' }}
                            inputStyle={{ width: '150px' }}
                            label='商户名称'
                            placeholder='请输入商户名称'
                            value={merchantName}
                            bindThis={this}
                            bindName='merchantName'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '100px' }}
                            label='确定'
                            loading={confirmLoading}
                            onClick={this._OKHandle}
                        />
                    </div>
                    <ChooseCreate tableAction={this._tableAction} paginationChange={this._paginationChange} renderData={marketingCampaignUser} />
                </div>
            </Modal>
        );
    }
}

ChooseModal.propTypes = {
    title: PropTypes.string,// 弹框标题
    data: PropTypes.object, // 需要修改魅力值的会员对象数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getMarketingCampaignUser: PropTypes.func,

    marketingCampaignUser: PropTypes.object
};

const mapStateToProps = (state) => ({
    marketingCampaignUser: state.marketingCampaign.marketingCampaignUser
});

export default connect(mapStateToProps, { getMarketingCampaignUser })(ChooseModal);