import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table, Badge } from 'antd';
import PropTypes from 'prop-types';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { tooltipStyle, tooltip, priceFenToYuanCell, cellWidth, tooltipClick } from '@/components/TableCell';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [],
        selectedRows: []
    }

    componentDidUpdate(preProps) {
        const { batchData } = this.props;
        if (batchData.length !== preProps.batchData.length) {
            const selectedRowKeys = batchData.map(item => {
                return item.id;
            });
            this.setState({
                selectedRowKeys, selectedRows: batchData
            });
        }
    }

    _takeColumns = () => {
        const cols = [
            {
                title: '付款单号',
                dataIndex: 'id',
                key: 'id',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '付款金额(元)',
                dataIndex: 'paymentAmount',
                key: 'paymentAmount',
                width: cellWidth.normal,
                align: 'center',
                render: priceFenToYuanCell,
            },
            {
                title: '收款方数量',
                dataIndex: 'payeeQty',
                key: 'payeeQty',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '付款状态',
                dataIndex: 'payStatusDesc',
                key: 'payStatusDesc',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '支付账户',
                dataIndex: 'paymentChannelType',
                key: 'paymentChannelType',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '付款说明',
                dataIndex: 'remark',
                key: 'remark',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '新建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: cellWidth.normal,
                // fixed: 'right',
                render: (text, item) => this._renderActionCell(item.id, item)
            },
        ];
        // 当为个人时，增加来源、手机号两列
        if (this.props.curTabID == 'PERSONAL') {
            const subjectType = {
                title: '来源',
                dataIndex: 'subjectType',
                key: 'subjectType',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            };
            const subjectPhone = {
                title: '电话',
                dataIndex: 'subjectPhone',
                key: 'subjectPhone',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            };
            cols.splice(3, 0, subjectType, subjectPhone);
        }
        // 当为魅力值时，增加批次编号列
        if (this.props.curTabID == 'STARLIGHT') {
            const subjectType = {
                title: '来源',
                dataIndex: 'subjectType',
                key: 'subjectType',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            };
            // const status = {
            //     title: '状态',
            //     dataIndex: 'status',
            //     key: 'status',
            //     width: cellWidth.normal,
            //     align: 'center',
            //     onCell: tooltipStyle,
            //     render: tooltip
            // };
            const batchNumber = {
                title: '批次编号',
                dataIndex: 'dispatchId',
                key: 'dispatchId',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: (text, item) => tooltipClick(text, () => this.props.tableAction(item.dispatchId, 'dispatchId'))
            };
            cols.splice(3, 0, subjectType);
        }
        // 当为魅力值时，增加批次编号列
        if (this.props.curTabID == 'SUPPLIER') {
            const typeEnum = {
                'SUPPLIER_LL_BALANCE_WITHDRAW': '连连预付款', 'SUPPLIER_ADVANCES_RECEIVED_WITHDRAW': '线下预付款'
            };
            cols.splice(3, 0, {
                title: '业务类型',
                dataIndex: 'type',
                key: 'type',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: t => tooltip(typeEnum[t])
            });
        }
        return cols;
    };

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderStatusCell = (text) => {
        var index = ['INIT', 'AUDIT', 'FIRST_SUCCESS', 'FIRST_FAIL', 'FINAL_SUCCESS', 'FINAL_FAIL'].indexOf(text);
        var statusText = ['新建', '提交审核', '初审通过', '初审不通过', '复审通过', '复审不通过'];
        var statusBadge = ['processing', 'processing', 'success', 'error', 'success', 'error'];
        return index === -1 ? null : <Badge status={statusBadge[index]} text={statusText[index]} />;
    }

    _renderActionCell = (key, item) => {
        const { status, payStatus } = item;
        const { tableAction, curTabID } = this.props;

        const payAction = { 'UNIT': 'unitPay' }[curTabID] || 'pay';

        const buttonElements = [
            <div key="manageAudit" className={styles.item} onClick={() => tableAction(key, 'manageAudit')}>经理审核</div>,
            <div key="directorReview" className={styles.item} onClick={() => tableAction(key, 'directorReview')}>总监复审</div>,
            <div key="edit" className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>,
            <div key="auditSubmit" className={styles.item} onClick={() => tableAction(key, 'auditSubmit')}>提交审核</div>,
            payStatus === 'TODO' ?
                <ButtonPerssion key="storehousefinancialmanage/paymanage/fk" code='storehousefinancialmanage/paymanage/fk'>
                    <div key="pay" className={styles.item} onClick={() => tableAction(key, payAction, item)}>付款</div>
                </ButtonPerssion> : null,
            curTabID != 'MERCHANT' && curTabID != 'SUPPLIER' ?
                <div key="del" className={styles.item} onClick={() => tableAction(key, 'del', item)}>删除</div> :
                null
        ];

        // 审核不通过只有详情按钮
        const buttons = {
            INIT: [3, 5],
            AUDIT: [0],
            FIRST_SUCCESS: [1],
            // FIRST_FAIL: [3],
            FINAL_SUCCESS: [4],
            // FINAL_FAIL: [3]
        }[status] || [];

        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'info', item)}>详情</div>
                {buttons.map(k => buttonElements[k])}
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            let arr = [...this.state.selectedRows, ...selectedRows];
            let tempData = arr.filter(item => selectedRowKeys.includes(item.id));
            let DataNew = this.duplicateFun(tempData);
            this.setState({ selectedRowKeys, selectedRows: DataNew }, () => {
                this.props.tableAction(selectedRowKeys, 'batchData', DataNew);
            });
        },
    };
    // 数组对象去重
    duplicateFun = (buZhouList) => {
        let object = {};
        let open = buZhouList;
        return buZhouList = open.reduce((cur, next) => {
            object[next.id] ? '' : object[next.id] = true && cur.push(next);
            return cur;
        }, []);
    }
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
        this.props.tableAction([], 'batchData', []);
    }

    render() {
        const { selectedRowKeys } = this.state;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    rowSelection={this._rowSelection}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                // scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    curTabID: PropTypes.string,
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string,
    batchData: PropTypes.array
};

export default TableContent;