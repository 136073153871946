/**
 * 审核列表 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const merchantListSource = function* () {
    yield takeEvery(T.GET_MERCHANT_DATAS, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/merchant/info/listMerchant', action.payload, 'POST');
            yield put({ type: T.SET_MERCHANT_DATAS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 审核列表店铺 Saga
 */
export const merchantstoreSource = function* () {
    yield takeEvery(T.GET_STORE_DATAS, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/merchant/info/listMerchantShopInfo', action.payload, 'POST');
            yield put({ type: T.SET_STORE_DATAS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 审核详情 Saga
 */
export const storeDetails = function* () {
    yield takeEvery(T.GET_STOREDETAIL_DATAS, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象 merchantId
        try {
            let response = yield http('/merchant/info/' + action.payload.merchantId, action.payload, 'GET');
            let service = {};
            // 请求服务相关ID
            const { operationCenterId, areaCompanyId, serviceId } = response.result.merchantInfo;
            // 请求服务列表
        
            const operationCenterList = yield http('/admin/company/listOperationsIdAndName', {}, 'POST');
            const areaCompanyList = yield http('/admin/company/listAreaCompanyIdAndName', { operationsId: operationCenterId }, 'POST');
            const serviceList = yield http('/service/provider/listServiceProviderIdAndName', { operationsId: operationCenterId, areaCompanyId: areaCompanyId }, 'POST');

            service.operationCenterList = operationCenterList.result ? operationCenterList.result.result : [];
            service.areaCompanyList = areaCompanyList.result ? areaCompanyList.result.result : [];
            service.serviceList = serviceList.result ? serviceList.result.result : [];

            response.result.servicecommone = service;

             //图文描述视频，音频数据处理
             let videos, music;
             let methodPath = '/qiniu/getUrlAudioVideo';//请求视频、音频完整URL转化
             let merchantAttachs = response.result.merchantAttachs;
             for (var i = 0; i < merchantAttachs.length; i++) {
                 if (merchantAttachs[i].fileType.code == 'VIDEO') {//视频
                     videos = merchantAttachs[i].fileUrl;
                 } else if (merchantAttachs[i].fileType.code == 'MUSIC') {//音乐   
                     music = merchantAttachs[i].fileUrl;
                 }
             }
             const videoPath = yield http(methodPath, { fileName: videos }, 'POST');
             const musicPath = yield http(methodPath, { fileName: music }, 'POST');
             response.result.videoPath = videoPath.result;
             response.result.musicPath = musicPath.result;
            
            yield put({ type: T.SET_STOREDETAIL_DATAS, payload: response });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
