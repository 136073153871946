import { createModel } from '@/store/tools';

const dataSource = {
    dataList: [],
    pageNum: 1,
    pageSize: 30,
    resultTotal: 0
};

const model = {
    namespace: 'marketingmanage',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getModeGoodsList': T('getModeGoodsList'),
        },
        actions: {
            'getModeGoodsList': A('getModeGoodsList'),
        },
        sagas: {
            'getModeGoodsList': S('getModeGoodsList', '/goods/query/queryGoodsItem'),
        },
        reducers: {
            'modeGoodsList': R('getModeGoodsList', dataSource),
        }
    })
};
export default createModel(model);