import React from 'react';
import styles from './ActivityDateTable.module.scss';
import PropTypes from 'prop-types';
import { Table, Tooltip } from 'antd';
import { XPagination } from '@/components/xqxc_ui';

const columns = () => ([
    {
        title: '序号',
        dataIndex: 'c1',
        key: 'c1',
        width: '8%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '商品货号',
        dataIndex: 'c2',
        key: 'c2',
        align: 'center',
        width: '10%',
        render: (text) => (TableCell(text))
    },
    {
        title: '商品名称',
        dataIndex: 'c4',
        key: 'c4',
        width: '12%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
        render: (text) => (TableTipCell(text)),
    },
    {
        title: '所属店铺',
        dataIndex: 'c3',
        key: 'c3',
        width: '12%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
        render: (text) => (TableTipCell(text)),
    },
    {
        title: '商品价格',
        dataIndex: 'c5',
        key: 'c5',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '订单总数/笔',
        dataIndex: 'c6',
        key: 'c6',
        align: 'center',
        width: '12%',
        render: (text) => (TableCell(text))
    },
    {
        title: '订单总额',
        dataIndex: 'c7',
        key: 'c7',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '已成交/笔',
        dataIndex: 'c8',
        key: 'c8',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '成交金额',
        dataIndex: 'c9',
        key: 'c9',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
]);

// 表格项内容为文本
const TableCell = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text}
        </div>
    );
};
// 表格项内容为文本
const TableTipCell = (text) => {
    return (
        <Tooltip placement='topLeft' title={text}>{text}</Tooltip>
    );
};

class ActivityDateTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data;
    }

    // 表格复选框操作回调
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.props.tableAction(selectedRowKeys, selectedRows);
        }
    };

    render() {
        const { renderData, paginationChange } = this.props;

        return (
            <div className={styles.container}>
                <div className={styles.table_box}>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        // rowSelection={this._rowSelection}
                        rowKey={(record, index) => index}
                        columns={columns()}
                        dataSource={this._takeDataSource(renderData)}
                        pagination={false}
                        scroll={{ y: 350 }}
                    />
                </div>
                <div className={styles.pagination_box}>
                    {/* <XPagination
                        resultTotal={renderData.resultTotal}
                        pageSize={renderData.pageSize}
                        pageNum={renderData.pageNum}
                        onChange={paginationChange} /> */
                        <XPagination/>}
                </div>
            </div>
        );
    }
}

ActivityDateTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

export default ActivityDateTable;