/**
 * 报表生成记录
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import RecordsTable from './components/RecordsTable';
import { XInput, XDatePicker, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/statementmanage/records/model';
import http from '@/assets/api/http';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {

    state = {
        name: '', //报表名称
        startTime: null, //开始时间
        endTime: null, //结束时间
    };

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, name, startTime, endTime } = this.state;
        const query = {
            templateName: name,//报表名称
            gmtCreateStart: startTime != null ? startTime.format('YYYY-MM-DD HH:mm:ss') : null, //开始时间
            gmtCreateEnd: endTime != null ? endTime.format('YYYY-MM-DD HH:mm:ss') : null, // 结束时间
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getRecordList(query);
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ name: '', startTime: null, endTime: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建组别
    _newHandle = () => {
        this.props.updateContent('regenerate');
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格操作
    _tableAction = (item, type) => {
        switch (type) {
            case 'regenerate': // 重新生成
                this.props.updateContent('regenerate', item);
                break;
            case 'down': // 下载报表
                http('/report/record/info', { id: item }, 'POST')
                    .then((res = {}) => {
                        const results = res.result;
                        if (results.downloadState == '1') {
                            if (results.downloadUrl) {
                                const regular = /(http|https):\/\/([\w.]+\/?)\S*/;
                                regular.test(results.downloadUrl) ? (location.href = results.downloadUrl, message.success('导出成功')) : message.warning('模板路径无效');
                            } else {
                                message.warning('暂无数据');
                            }
                        } else if (results.downloadState == '0') {
                            message.warning('后台数据生成中,请稍后点击下载');
                        } else {
                            message.error('导出失败');
                        }
                    }).catch((e = {}) => {
                        message.error(e.message);
                    });
                break;
        }
    }

    render() {
        const { name, startTime, endTime, } = this.state;
        const { tableList } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='报表名称'
                                placeholder='请输入报表名称'
                                value={name}
                                bindThis={this}
                                bindName='name'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='生成日期'
                                value={startTime}
                                bindThis={this}
                                bindName='startTime'
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                label=''
                                value={endTime}
                                bindThis={this}
                                bindName='endTime'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: 'auto' }}
                            label='生成报表'
                            onClick={this._newHandle}
                        />
                    </div>
                    <RecordsTable renderData={tableList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}


Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    updateContent: PropTypes.func, // 路由跳转
    tableList: PropTypes.object,
    getRecordList: PropTypes.func,//表格数据(action)
};

const mapStateToProps = (state) => ({
    tableList: state.records.recordList, // 表格数据
});

export default connect(mapStateToProps, { ...model.actions })(Main);
