/**
 * 审核管理 Saga集合
 */
import { getAutonymList, getAutonymDetail } from './autonym/saga';
import { getVideoAuditList, getVideoAuditDetail, getVideoAuditSele, getEnumSystemType } from './videoAudit/saga';
import { getCommodityReviewList, getCommodityReviewDetail, getCommodityReviewSele, getCommodityReviewType } from './commodityReview/saga';
import { getPornographyList, getPornographyType } from './pornography/saga';
import { getAnchorCoverList, getAnchorCoverSelect } from './anchorCover/saga';
import {
    getProductAuditData,
    getProductAuditRateList,
    getProductAuditLog,
    getProductAuditDetail,
    getProductStatus,
    getProductBusinessType,
    getProductUsername,
    getProductClassifySele,
    getProductLabelSele,
    getProductAuditRateDetail,
    getProductRateAuditLog
} from './product/saga';
import {
    merchantListSource,
    storeDetails,
    merchantstoreSource,
} from './businessAudit/saga'; // 商户审核
import businessAudit from './businessAudit/model'; // 商户审核
import businessCategory from './businessCategory/model'; // 经营品类审核
import { getBrandAuditList, getBrandAuditUsername, getBrandAuditState, getBrandAuditDetail, getBrandAuditBrand } from './brandAudit/saga';
import { getOperationAuditList, getOperationAuditStatus, getOperationAuditDetail } from './operationAudit/saga'; // 运营中心审核
import { getCompanyAuditList, getCompanyAuditStatus, getCompanyAuditDetail } from './companyAudit/saga'; // 区域公司审核
import {
    getActiveProductAuditList, getActiveProductAuditStatus,
    geActiveProductAuditDetail, geActiveProductAuditInfo,
    getActiveProductAuditShopSele, getActiveProductAuditExitList,
    getActiveProductAuditExitInfo
} from './activeProductAudit/saga'; // 活动商品审核
import { getFacilitatorAuditList, getFacilitatorAuditStatus, getFacilitatorAuditDetail, getFacilitatorAuditLog } from './facilitatorAudit/saga'; // 服务商审核
import { getCommunityAuditList, getCommunityAuditDetail } from './community/saga'; // 社区审核
import { getIllegalShopList, getIllegalProductList, getIllegalSele, getIllegalProductDetail, getIllegalShopDetail, getIllegalProduct } from './illegalAudit/saga'; // 违规审核
import { getWithdrawList, getWithdrawDetail, getWithdrawSele, getPayDetail, getServiceTypeEnum } from './withdraw/saga'; // 会员服务商魅力值提现审核
import { getSupplierAuditList, getSupplierAuditSele, getSupplierAuditDetail, getSupplierAuditStatus } from './supplierAudit/saga'; // 供应商审核
import { getCompanyInvoiceList, getCompanyInvoiceStatus, getCompanyInvoiceName, getCompanyInvoiceDetail, getCompanyInvoicePay } from './companyInvoice/saga';
import { getOperationInvoiceList, getOperationInvoiceStatus, getOperationInvoiceName, getOperationInvoiceDetail, getOperationInvoicePay } from './operationInvoice/saga';
import advocacytask from './advocacytask/model'; // 代言任务审核
import autonym from './autonym/model';
import payorder from './payorder/model'; // 支付凭证
import enterpriseAnchor from './enterpriseAnchor/model'; // 企业主播
import palatGoodsAudit from './palatGoodsAudit/model'; // 平台批发商品审核
import stockright from './stockright/model'; // 股权凭证审核
import previewincomeaudit from './previewincomeaudit/model'; // 股权凭证审核
import memberupgrade from './memberupgrade/model'; // 会员升级
import channelaudit from './channelaudit/model'; // 渠道审核

export default [
    getAutonymList,
    getAutonymDetail,

    getVideoAuditList,
    getVideoAuditDetail,
    getVideoAuditSele,
    getEnumSystemType,

    getCommodityReviewList,
    getCommodityReviewDetail,
    getCommodityReviewSele,
    getCommodityReviewType,

    getPornographyList,
    getPornographyType,

    getAnchorCoverList,
    getAnchorCoverSelect,

    getProductAuditData,
    getProductAuditLog,
    getProductAuditRateList,
    getProductAuditDetail,

    merchantListSource,//商户审核列表
    storeDetails,//审核详情
    merchantstoreSource,//审核列表店铺
    ...Object.values(businessAudit.sagas),//商户审核model
    ...Object.values(businessCategory.sagas),//经营品类审核model
    getProductAuditDetail,
    getProductAuditRateDetail,
    getProductRateAuditLog,

    getBrandAuditList,
    getBrandAuditUsername,
    getBrandAuditState,
    getBrandAuditDetail,
    getBrandAuditBrand,

    getProductStatus,
    getProductBusinessType,
    getProductUsername,
    getProductClassifySele,
    getProductLabelSele,

    //运营中心审核
    getOperationAuditList,
    getOperationAuditStatus,
    getOperationAuditDetail,

    //区域公司审核
    getCompanyAuditList,
    getCompanyAuditStatus,
    getCompanyAuditDetail,

    //活动商品审核
    getActiveProductAuditList,
    getActiveProductAuditStatus,
    geActiveProductAuditDetail,
    geActiveProductAuditInfo,
    getActiveProductAuditShopSele,
    getActiveProductAuditExitList,
    getActiveProductAuditExitInfo,

    //服务商审核
    getFacilitatorAuditList,
    getFacilitatorAuditStatus,
    getFacilitatorAuditDetail,
    getFacilitatorAuditLog,

    //社区审核
    getCommunityAuditList,
    getCommunityAuditDetail,

    //违规审核
    getIllegalShopList,
    getIllegalProductList,
    getIllegalSele,
    getIllegalProductDetail,
    getIllegalShopDetail,
    getIllegalProduct,

    // 供应商审核
    getSupplierAuditList,
    getSupplierAuditSele,
    getSupplierAuditDetail,
    getSupplierAuditStatus,

    //会员服务商魅力值提现审核
    getWithdrawList,
    getWithdrawDetail,
    getWithdrawSele,
    getPayDetail,
    getServiceTypeEnum,

    //区域公司发票
    getCompanyInvoiceList,
    getCompanyInvoiceStatus,
    getCompanyInvoiceName,
    getCompanyInvoiceDetail,
    getCompanyInvoicePay,

    // 运营中心发票审核
    getOperationInvoiceList,
    getOperationInvoiceStatus,
    getOperationInvoiceName,
    getOperationInvoiceDetail,
    getOperationInvoicePay,

    // 代言任务
    ...Object.values(advocacytask.sagas),
    // 实名认证
    ...Object.values(autonym.sagas),
    // 支付凭证
    ...Object.values(payorder.sagas),
    // 企业主播审核
    ...Object.values(enterpriseAnchor.sagas),
    // 平台批发商品审核
    ...Object.values(palatGoodsAudit.sagas),
    // 股权凭证审核
    ...Object.values(stockright.sagas),
    // 预收益管理
    ...Object.values(previewincomeaudit.sagas),
    // 会员升级审核
    ...Object.values(memberupgrade.sagas),
    //渠道审核
    ...Object.values(channelaudit.sagas)
];
