import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getListAreaByParentId = (T) => createSaga(T('getListAreaByParentId'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/user/area/listAreaByParentId/' + action.payload.id, {}, 'POST');
                switch (action.payload.type) {
                    case 'province':
                        yield put({ type: T('province'), payload: result });
                        break;
                    case 'city':
                        yield put({ type: T('city'), payload: result });
                        break;
                    case 'street':
                        yield put({ type: T('street'), payload: result });
                        break;
                }
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const address = (T) => createReducer((autostate) => {
    return (state = autostate, action) => {
        switch (action.type) {
            case T('province'):
                return { ...state, province: action.payload.result };
            case T('city'):
                return { ...state, city: action.payload.result };
            case T('street'):
                return { ...state, street: action.payload.result };
            default:
                return state;
        }
    };
}, { province: [], city: [], street: [] });

const model = {
    namespace: 'merchantsHotel',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getMerchantsHotelList': T('getMerchantsHotelList'),
            'getMerchantState': T('getMerchantState'),
            'getListAreaByParentId': T('getListAreaByParentId'),
            'getHotelType': T('getHotelType'),
            'getHotelNature': T('getHotelNature'),
            'getHotelFees': T('getHotelFees'),
            'getOperationCenter': T('getOperationCenter'),
            'getAreaCompany': T('getAreaCompany'),
            'getServiceProvider': T('getServiceProvider'),
            'getHotelStar': T('getHotelStar'),
            'getHotelDetail': T('getHotelDetail'),
            'getHotelStoreList': T('getHotelStoreList'),
            'getHotelStoreStop': T('getHotelStoreStop'),
        },
        actions: {
            'getMerchantsHotelList': A('getMerchantsHotelList'), // 酒店商户列表
            'getMerchantState': A('getMerchantState'), //状态
            'getListAreaByParentId': A('getListAreaByParentId'), // 省市区
            'getHotelType': A('getHotelType'), // 酒店商户类型
            'getHotelNature': A('getHotelNature'), // 商户性质
            'getHotelFees': A('getHotelFees'), // 费用模式
            'getOperationCenter': A('getOperationCenter'), // 运营中心
            'getAreaCompany': A('getAreaCompany'), // 区域公司
            'getServiceProvider': A('getServiceProvider'), // 服务商
            'getCleanServiceProvider': C('getServiceProvider'), // 清除服务商
            'getHotelStar': A('getHotelStar'), // 酒店星级
            'getHotelDetail': A('getHotelDetail'), // 酒店详情
            'getCleanDetail': C('getHotelDetail'), // 清除酒店详情
            'getHotelStoreList': A('getHotelStoreList'), // 酒店店铺
            'getHotelStoreStop': A('getHotelStoreStop'), // 酒店店铺-停业
        },
        sagas: {
            'getMerchantsHotelList': S('getMerchantsHotelList', '/merchant/info/listMerchant'),
            'getMerchantState': S('getMerchantState', '/enum/MerchantStateEnum'),
            'getListAreaByParentId': getListAreaByParentId(T), // 自定义saga写法，使用依赖注入
            'getHotelType': S('getHotelType', '/enum/HotelShopTypeEnum'),
            'getHotelNature': S('getHotelNature', '/enum/FinancialAccountTypeEnum'),
            'getHotelFees': S('getHotelFees', '/enum/MerHotelModelsEnum'),
            'getOperationCenter': S('getOperationCenter', '/admin/company/listOperationsIdAndName'),
            'getAreaCompany': S('getAreaCompany', '/admin/company/listAreaCompanyIdAndName'),
            'getServiceProvider': S('getServiceProvider', '/service/provider/listServiceProviderIdAndName'),
            'getHotelStar': S('getHotelStar', '/hotel/hotelStarLevel/levelItem'),
            'getHotelDetail': S('getHotelDetail', '/merchant/info', 'GET', 'merchantId'),
            'getHotelStoreList': S('getHotelStoreList', '/merchant/info/listMerchantShopInfo'),
            'getHotelStoreStop': S('getHotelStoreStop', '/enum/ViolantionBehaviorEnum'),
        },
        reducers: {
            'merchantsHotelList': R('getMerchantsHotelList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'merchantState': R('getMerchantState', []),
            'address': address(T), // 自定义reducer写法，使用依赖注入
            'hotelType': R('getHotelType', []),
            'hotelNature': R('getHotelNature', []),
            'hotelFees': R('getHotelFees', []),
            'operationCenter': R('getOperationCenter', []),
            'areaCompany': R('getAreaCompany', []),
            'serviceProvider': R('getServiceProvider', []),
            'hotelStar': R('getHotelStar', []),
            'hotelDetails': R('getHotelDetail', {}),
            'hotelStoreList': R('getHotelStoreList', {}),
            'hotelStoreStop': R('getHotelStoreStop', []),
        }
    })
};
export default createModel(model);