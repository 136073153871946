/**
 * 快速回复-创建模版或编辑
 */
import React from 'react';
import { Modal, Radio, Icon, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './GiftModal.module.scss';
import { XInput, XUpload } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;
class GiftModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,

            typeInput: this.props.data === undefined ? '' : this.props.data.content, // 分类名称
            radioValue: this.props.data === undefined ? 1 : this.props.data.state, // 状态

            id: this.props.data === undefined ? '' : this.props.data.id,
            inputstate: this.props.data === undefined ? '' : this.props.data.sortNo,//排序
        };
    }

    _handleOk = () => {
        const { data } = this.props;
        const { radioValue, typeInput, id, inputstate, } = this.state;

        const method = data === undefined ? 'saveSnippetsInfo' : 'editSnippetsInfo';
        let starInputReg = /^(0|[1-9]\d*)$/;
        if (typeInput == '') {
            message.warn('请输入分类名称。');
        } else if (inputstate == '' || !starInputReg.test(inputstate)) {
            message.warn('请输入排序（整数）。');
        } else {
            this.setState({ confirmLoading: true }, () => {
                http(`/sysConfig/snippetsInfo/${method}`, {
                    content: typeInput,
                    state: radioValue,
                    sortNo: inputstate,
                    id,
                }, 'POST').then(() => {
                    data === undefined ? message.success('创建成功。') : message.success('更新成功。');
                    this.setState({
                        visible: false,
                        confirmLoading: false
                    });
                    this.props.closeMode(true);
                }).catch((e = {}) => {
                    data === undefined ? message.error(e.message || '创建异常。') : message.error(e.message || '更新异常。');
                    this.setState({ confirmLoading: false });
                });
            });
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // Radio状态改变时触发
    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }

    // 分类名称输出框改变时触发
    _inputChangeHandle = (value) => {
        this.setState({ typeInput: value });
    }


    render() {
        const { visible, confirmLoading, title, radioValue, typeInput, inputstate } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '408px' }}
                        inputStyle={{ width: '340px' }}
                        label='回复内容'
                        placeholder='请输入回复内容'
                        onChange={this._inputChangeHandle}
                        value={typeInput}
                    />
                    <div className={styles.radio}>
                        <div className={styles.label}>状态</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={radioValue}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <XInput
                        style={{ width: '382px', margin: '30px 0 0 26px' }}
                        inputStyle={{ width: '340px' }}
                        label='排序'
                        placeholder='请输入序号'
                        value={inputstate}
                        bindThis={this}
                        bindName='inputstate'
                    />
                </div>
            </Modal>
        );

    }
}

GiftModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
    modetext: PropTypes.string,//区分魅力值与人气值 分类编码
};

export default GiftModal;