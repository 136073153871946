import React from 'react';
import styles from './TableData.module.scss';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const columns = (actionClickHandle) => ([
    {
        title: '商户编号',
        dataIndex: 'merchantNo',
        key: 'merchantNo',
        width: '15%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '商户名称',
        dataIndex: 'merchantName',
        key: 'merchantName',
        width: '15%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '商户类型',
        dataIndex: 'merchantType.value',
        key: 'merchantType.value',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '商户地址',
        dataIndex: 'addrDetail',
        key: 'addrDetail',
        width: '20%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '状态',
        dataIndex: 'status.value',
        key: 'status.value',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '更新时间',
        dataIndex: 'gmtModified',
        key: 'gmtModified',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作人',
        dataIndex: 'modifyUserName',
        key: 'modifyUserName',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '10%',
        render: (id, item) => TableAction(id, item, actionClickHandle)
    },
]);

export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    return data;
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};

const TableAction = (id, item, actionClickHandle) => {
    const { status = {} } = item;
    return (
        <div className={styles.action}>
            {status.code === '0' ? <div className={styles.item} onClick={() => actionClickHandle(id, 'START_SERVICE', item)}>启用</div> :
                <div className={styles.item} onClick={() => actionClickHandle(id, 'STOP_SERVICE', item)}>禁用</div>}
            <div className={styles.item} onClick={() => actionClickHandle(id, 'DELETE', item)}>删除</div>
        </div>
    );
};