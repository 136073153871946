import * as T from './actiontypes';

/**
 * 获取费用名称列表
 */

//人气值商城设置商户列表
export const getUserNameSeleList = () => ({
    type: T.GET_USERNAME_SELELIST,
    payload: {}
});

//获取默认商户
export const getUserNameDefault = () => ({
    type: T.GET_USERNAME_DEFAULT,
    payload: {}
});

