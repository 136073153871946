/**
 * 通道切换
 */
import React from 'react';
import { Modal, Radio, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './PayModal.module.scss';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import model from '@/reducers/systemmanage/paymentChannel/model';

const RadioGroup = Radio.Group;
class SwitchModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            confirmLoading: false,
        };
    }

    componentDidMount() {
        const { getPaywayList, } = this.props;
        getPaywayList();//支付通道(通联、银联)
    }

    _handleOk = () => {
        const { radioValue } = this.state;

        this.setState({ confirmLoading: true }, () => {
            http('/payment/config/setPaymentAccessTypeConfig', {}, 'POST')
                .then((res) => {

                }).catch((res = {}) => {
                    message.error(res.message);
                });
        });

    }

    // Radio状态改变时触发
    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }

    render() {
        const { confirmLoading, radioValue } = this.state;
        return (
            <Modal
                width={470}
                centered
                title='通道切换'
                visible={true}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={() => this.props.closeMode()}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.radio}>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={radioValue}>
                            {[].map(v => <Radio key={v.code} value={v.code}>{v.value}</Radio>)}
                        </RadioGroup>
                    </div>
                </div>
            </Modal>
        );

    }
}

SwitchModal.propTypes = {
    data: PropTypes.array, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
    paywayList: PropTypes.array,
    getPaywayList: PropTypes.func,//支付通道(通联、银联)(action)
    dataType: PropTypes.object,//是否批量（undefined）和单个（{...data}）
};

const mapStateToProps = (state) => ({
    paywayList: state.paymentChannel.paywayList, // 支付通道(通联、银联)
});

export default connect(mapStateToProps, { ...model.actions })(SwitchModal);