import * as T from './actiontypes';

/**
 * 出库单列表
 */
const plistInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
// 已分配出库单
export const invoiceList = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_INVOICELIST:
            return payload.result;
        default:
            return state;
    }
};

// export const dealingUnitSelectSource = (state = [], { type, payload }) => {
//     switch (type) {
//         case T.SET_DEALING_UNIT_SELECTSOURCE:
//             return payload.result;
//         default:
//             return state;
//     }
// };

export const stateSelectSource = (
    state = [
        { code: 'INIT', value: '新建' },
        { code: 'TO_CONFIRM', value: '待确认' },
        { code: 'CONFIRM_SUCCESS', value: '确认成功' },
        { code: 'CONFIRM_FAIL', value: '确认失败' }
    ], { type, payload }) => {
    switch (type) {
        case T.SET_STATE_SELECTSOURCE:
            return payload.result;
        default:
            return state;
    }
};

export const platformReconciliationTableSource = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_PLATFORMRECONCILIATION_TABLESOURCE:
            payload.result.dataList = [
                {
                    'id': 1920046251155637,
                    'targetId': 1,
                    'targetName': '往来对象名称',
                    'startDate': '2019-07-17 00:00:00',
                    'endDate': '2019-07-20 00:00:00',
                    'serviceFee': 0,
                    'distributionFee': 0,
                    'showServiceFee': 0,
                    'heartSelectionRecommendationIncome': 0,
                    'freeOfCharge': 0,
                    'otherFee': 1,
                    'finalAmount': 1,
                    'settleAmount': 1,
                    'unSettleAmount': 0,
                    'gmtCreate': '2019-07-19 20:50:51',
                    'status': 'INIT'
                },
                {
                    'id': 1920046251155637,
                    'targetId': 1,
                    'targetName': '往来对象名称',
                    'startDate': '2019-07-17 00:00:00',
                    'endDate': '2019-07-20 00:00:00',
                    'serviceFee': 0,
                    'distributionFee': 0,
                    'showServiceFee': 0,
                    'heartSelectionRecommendationIncome': 0,
                    'freeOfCharge': 0,
                    'otherFee': 1,
                    'finalAmount': 1,
                    'settleAmount': 1,
                    'unSettleAmount': 0,
                    'gmtCreate': '2019-07-19 20:50:51',
                    'status': 'TO_CONFIRM'
                },
                {
                    'id': 1920046251155637,
                    'targetId': 1,
                    'targetName': '往来对象名称',
                    'startDate': '2019-07-17 00:00:00',
                    'endDate': '2019-07-20 00:00:00',
                    'serviceFee': 0,
                    'distributionFee': 0,
                    'showServiceFee': 0,
                    'heartSelectionRecommendationIncome': 0,
                    'freeOfCharge': 0,
                    'otherFee': 1,
                    'finalAmount': 1,
                    'settleAmount': 1,
                    'unSettleAmount': 0,
                    'gmtCreate': '2019-07-19 20:50:51',
                    'status': 'CONFIRM_SUCCESS'
                },
                {
                    'id': 1920046251155637,
                    'targetId': 1,
                    'targetName': '往来对象名称',
                    'startDate': '2019-07-17 00:00:00',
                    'endDate': '2019-07-20 00:00:00',
                    'serviceFee': 0,
                    'distributionFee': 0,
                    'showServiceFee': 0,
                    'heartSelectionRecommendationIncome': 0,
                    'freeOfCharge': 0,
                    'otherFee': 1,
                    'finalAmount': 1,
                    'settleAmount': 1,
                    'unSettleAmount': 0,
                    'gmtCreate': '2019-07-19 20:50:51',
                    'status': 'CONFIRM_FAIL'
                },
            ];
            return payload.result;
        default:
            return state;
    }
};

export const detail = (state = {
    'id': 1920046251155637,
    'ownerType': 'PLATFORM',
    'ownerId': 0,
    'targetType': 'OP_CENTER',
    'targetId': 1,
    'targetName': '往来对象名称',
    'startDate': '2019-07-17 00:00:00',
    'endDate': '2019-07-20 00:00:00',
    'triggerType': '',
    'revenue': 5,
    'expense': 0,
    'amount': 5,
    'settleAmount': 1,
    'status': 'CONFIRM_SUCCESS',
    'remark': '生成对账测试',
    'createUserId': 0,
    'createUserName': '',
    'gmtCreate': '2019-07-19 20:50:51',
    'auditLogs': [
        {
            'id': 1920046301666884,
            'targetType': 'RECONCILIATION',
            'targetId': 1920046251155637,
            'preStatus': 'TO_CONFIRM',
            'postStatus': 'CONFIRM_SUCCESS',
            'auditUserId': 0,
            'auditUserName': '',
            'comment': '未知',
            'gmtCreate': '2019-07-19 20:51:41',
            'createUserName': '',
            'createUserId': 0
        }
    ],
    'uesdType': null,
    'toBeAmount': 4
}, { type, payload }) => {
    switch (type) {
        case T.SET_DETAIL:
            return payload.result;
        default:
            return state;
    }
};

export const detailTableSource = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_DETAIL_TABLESOURCE:
            return payload.result;
        default:
            return state;
    }
};
