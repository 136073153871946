/**
 * 基本设置
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Avatar, Row, Col, message, Button, Tabs } from 'antd';
import { XTitle, XInput, XTextArea, XOKButton } from '@/components/xqxc_ui';
import CustomUpload from '@/components/NewUpload';
import { getLocalStorage, setLocalStorage } from '@/assets/js/storage';
import http from '@/assets/api/http';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import QRCode from 'qrcode.react';
class BaseSetting extends React.Component {

    state = {
        uploadFiles: [], // 头像上传
        avatar: '', // 头像路径
        emplName: '', // 用户名称
        remark: '', // 用户简介
        tel: '', // 用户电话
        loginData: '', // 用户登录信息
        headUrl: '',
        serviceProviderInvitationNum: 0,
        serviceProviderUrl: '',
        regionalAddress: '',
        ewmGenerating: false,
        tabList: [
            { key: '0', title: '服务商系统二维码', remark: '已推广业绩：' },
            { key: '1', title: '商户邀请二维码', remark: '已推广商户：' },
            { key: '2', title: '用户邀请二维码', remark: '' },
        ],
        mobilePhone: ''
    }

    componentDidMount() {
        const { tabList } = this.state;
        const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
        const loginData = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')) : null;
        const { emplName, remark, tel, avatar } = userInfo;

        if (userInfo.accountType === 'RE' && userInfo.type === 'AGENCY') {
            http('/user/account/getOpenSalesmanUrl/0', {}).then(res => {
                if (res.code == 200) {
                    // this.setState({ serviceProviderUrl: res.result, tabList: [{ key: '3', title: '云仓邀请二维码', remark: '' }] });
                    this.setState({ serviceProviderUrl: res.result, tabList: [] });
                }
            });
        } else {
            http('/admin/company/getCompanyIsOpenConnectedAccount').then(res => {
                if (res.result) {
                    this.setState({ emplName, remark, tel, avatar, loginData, tabList: tabList.filter(i => i.key === '2') });
                    this._getQRCodeInfo('2');
                } else {
                    this._getQRCodeInfo('0');
                }
            });
        }
    }

    _getQRCodeInfo = (isShareMerchant) => {
        if (isShareMerchant !== '2') {
            http('/admin/company/promotionAndSharing', {
                isShareMerchant
            }, 'POST').then((response) => {
                if (response.status == 200) {
                    this.setState(response.result || {});
                }
            }).catch((e) => {
                message.error(e.message || '获取二维码数据异常');
            });
        } else {
            http('/admin/company/invitationQrcode', {}, 'POST').then(({ status, result }) => {
                if (status == 200) {
                    this.setState({ headUrl: result.headUrl, serviceProviderUrl: result.accountUrl, regionalAddress: result.nickName, mobilePhone: result.mobilePhone });
                }
            }).catch((e) => {
                message.error(e.message || '获取二维码数据异常');
            });
        }
    }

    _clickHandle = () => {
        const { uploadFiles, emplName, remark, tel, avatar, loginData } = this.state;
        let avatarURL = avatar;
        if (uploadFiles.length > 0) {
            avatarURL = uploadFiles[0].response.key;
        }
        const data = {
            avatar: avatarURL,
            emplName,
            remark,
            tel
        };

        http('/admin/empl/updateEmplBaseInfo', data, 'POST').then((response) => {
            if (response.status == 200) {
                if (loginData) {
                    let auth = { ...loginData };
                    auth.userInfo = response.result;
                    setLocalStorage('auth', auth);
                }
                message.success('更新成功！');
                location.reload();
            }
        }).catch((e) => {
            message.error(e.message || '操作异常');
        });
    }

    saveQrCode = () => {
        this.setState({ ewmGenerating: true });
        domtoimage.toBlob(document.getElementById('qrcodeImg'))
            .then(function (blob) {
                saveAs(blob, '推广二维码.png');
            }).finally(() => {
                this.setState({ ewmGenerating: false });
            });
    }

    render() {
        let { uploadFiles, avatar, emplName, remark, tel, headUrl, serviceProviderInvitationNum, serviceProviderUrl, regionalAddress, ewmGenerating, tabList, mobilePhone } = this.state;
        const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
        return (
            <div className={styles.flexBoxContainer}>
                <Row>
                    <Col span={12}>
                        <XTitle label='基本设置' />
                        <div className={styles.uploadAvatar}>
                            <CustomUpload
                                accept='.png, .jpg, .jpeg'
                                wallMode="single"
                                showUploadList={false}
                                fileList={uploadFiles}
                                qiniuUploadProps={{
                                    uploadType: 'image'
                                }}
                                onFileListChange={(info) => {
                                    if (info.fileList.length > 0 && info.fileList[(info.fileList.length - 1)].status == 'success') {
                                        this.setState({ uploadFiles: info.fileList, avatar: info.fileList[(info.fileList.length - 1)].url });
                                    }
                                }}
                            >
                                <Avatar style={{ minWidth: '120px', minHeight: '120px', cursor: 'pointer', backgroundColor: '#00284D' }} size={120} icon="user" src={avatar}></Avatar>
                            </CustomUpload>
                            <div className={styles.layer}></div>
                            <div className={styles.avatarText}>修改头像</div>
                        </div>
                        <XInput
                            style={{ marginTop: '30px' }}
                            inputStyle={{ width: '500px' }}
                            label='用户姓名'
                            placeholder='请输入用户姓名'
                            value={emplName}
                            bindThis={this}
                            bindName='emplName'
                        />
                        <XTextArea
                            style={{ marginTop: '30px' }}
                            inputStyle={{ width: '500px' }}
                            label='用户简介'
                            placeholder='请输入用户简介'
                            value={remark}
                            bindThis={this}
                            bindName='remark'
                        />
                        <XInput
                            style={{ marginTop: '30px' }}
                            inputStyle={{ width: '500px' }}
                            label='联系电话'
                            placeholder='请输入联系电话'
                            value={tel}
                            bindThis={this}
                            bindName='tel'
                        />
                        <XOKButton style={{ width: '100px', marginTop: '50px' }} label='更新信息' onClick={this._clickHandle} />
                    </Col>
                    <Col span={12}>
                        <Tabs style={{ width: 400 }} onChange={(key) => this._getQRCodeInfo(key)}>
                            {
                                tabList.map(item => {
                                    return <Tabs.TabPane tab={item.title} key={item.key}>
                                        {!!item.remark && <div className={styles.ewmimg}>
                                            {/* <span><XTitle label='服务商系统二维码' /></span> */}
                                            <span style={{ marginLeft: 15 }}>
                                                {item.remark}<span style={{ color: '#ff0000', fontSize: 20 }}>{serviceProviderInvitationNum}</span> 人
                                            </span>
                                        </div>}
                                        {!!serviceProviderUrl && <Fragment>
                                            <div className={styles.ewmbg}>
                                                <div id='qrcodeImg'>
                                                    <img src={require('@/assets/image/ewmbg.png')} />
                                                    <div className={styles.ewmdata}>
                                                        <Avatar size={100} icon="user" src={headUrl}></Avatar>
                                                        <div className={styles.ewmaddress} style={{ marginTop: 15 }}>{regionalAddress}</div>
                                                        {!!mobilePhone && <div className={styles.ewmaddress} style={{ marginTop: 15 }}>{mobilePhone}</div>}
                                                        {userInfo.accountType === 'RE' && userInfo.type === 'AGENCY' &&
                                                            <div className={styles.ewmuser}>{userInfo.companyName}</div>}
                                                        <div className={styles.ewmwith} style={{ marginTop: 25 }}>让优秀的人在一起</div>
                                                        <div className={styles.ewmcontent} style={{ marginTop: 35 }}>
                                                            <img className={styles.border} src={require('@/assets/image/ewm-border.png')} />
                                                            <div className={styles.qrcodeWrap}>
                                                                <QRCode
                                                                    className={styles.qrcode}
                                                                    value={serviceProviderUrl}
                                                                    fgColor='#da1c38'
                                                                    level='Q'
                                                                    imageSettings={{
                                                                        src: require('@/assets/image/service-logo.png'),
                                                                        x: null,
                                                                        y: null,
                                                                        height: 40,
                                                                        width: 40,
                                                                        excavate: true,
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={styles.ewmconnect} style={{ marginTop: 25 }}>识别二维码加入我们</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ width: 375, textAlign: 'center' }}>
                                                <Button type='primary' style={{ marginTop: 15 }} icon='cloud-download' onClick={this.saveQrCode} loading={ewmGenerating}>保存图片到本地</Button>
                                                <div style={{ marginTop: 15 }}>将图片二维码保存于本地再使用分享工具分享</div>
                                            </div>
                                        </Fragment>
                                        }
                                    </Tabs.TabPane>;
                                })
                            }

                        </Tabs>
                    </Col>
                </Row>
            </div>
        );
    }
}

BaseSetting.propTypes = {
    history: PropTypes.object
};

export default BaseSetting;