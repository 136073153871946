import React from 'react';
import styles from './SelectedProductTable.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { priceFenToYuanCell, ImgCell, tooltipStyle, tooltip } from '@/components/TableCell';

const columns = () => [
    {
        title: '商品图片',
        dataIndex: 'mainImgUrl',
        key: 'mainImgUrl',
        width: '25%',
        align: 'center',
        render: ImgCell
    },
    {
        title: '商品名称',
        dataIndex: 'goodsName',
        key: 'goodsName ',
        width: '45%',
        align: 'center',
        onCell: tooltipStyle,
        render: (text, record) => tooltip(
            <>{record.isExposure == 1 && <span className={styles.cellRec}>推荐</span>}{text}</>
        )
    },
    {
        title: '货号',
        dataIndex: 'goodsNo',
        key: 'goodsNo',
        align: 'center',
        width: '15%',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '零售价',
        dataIndex: 'salePrice',
        key: 'salePrice',
        width: '15%',
        align: 'center',
        render: priceFenToYuanCell
    },
];

class SelectedProductTable extends React.Component {

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.table_box}>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        rowSelection={this._rowSelection}
                        rowKey={record => record.id}
                        columns={columns()}
                        dataSource={this.props.renderData}
                        pagination={false}
                        scroll={{ y: 350 }}
                    />
                </div>
            </div>
        );
    }
}

SelectedProductTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
};

export default SelectedProductTable;