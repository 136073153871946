import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Switch, Tooltip } from 'antd';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { priceFenToYuanCell, ImgCell, tooltipStyle, tooltip, cellWidth, tooltipClick } from '@/components/TableCell';
import { isEmpty } from '@/assets/js/utils';
import http from '@/assets/api/http';

class TypeTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    _columns = () => [
        {
            title: '主播账号',
            dataIndex: 'tel',
            key: 'tel',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '主播昵称',
            dataIndex: 'nickname',
            key: 'nickname ',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '主播类型',
            dataIndex: 'anchorTypeName',
            key: 'anchorTypeName ',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '封面',
            dataIndex: 'coverPage',
            key: 'coverPage',
            align: 'left',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: ImgCell
        },
        {
            title: '直播间标题',
            dataIndex: 'broadcasTitle',
            key: 'broadcasTitle',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所属分类',
            dataIndex: 'broadcasClass',
            key: 'broadcasClass',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '直播间状态',
            dataIndex: 'liveState',
            key: 'liveState',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '实时人数',
            dataIndex: 'realOnlineNumber',
            key: 'realOnlineNumber',
            width: cellWidth.normal,
            align: 'left',
            sorter: true,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '总观看数',
            dataIndex: 'realOnlineViewNumber',
            key: 'realOnlineViewNumber',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '魅力值收入',
            dataIndex: 'starlightNumber',
            key: 'starlightNumber',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            align: 'left',
            render: (text, item) => tooltipClick(text, () => this.props.tableAction(item.id, 'starLight', item))
        },
        // {
        //     title: '人气值收入',
        //     dataIndex: 'starValueNumber',
        //     key: 'starValueNumber',
        //     width: cellWidth.normal,
        //     onCell: tooltipStyle,
        //     align: 'left',
        //     render: (text, item) => tooltipClick(priceFenToYuanCell(text), () => this.props.tableAction(item.id, 'starValue', item))
        // },
        {
            title: '秀场收益',
            dataIndex: 'saleBonus',
            key: 'saleBonus',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            align: 'left',
            render: (text, item) => tooltipClick(priceFenToYuanCell(text), () => this.props.tableAction(item.id, 'sell', item))
        },
        {
            title: '总销售额',
            dataIndex: 'totalSales',
            key: 'totalSales',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            sorter: true,
            render: priceFenToYuanCell
        },
        {
            title: '本场违规',
            dataIndex: 'detectNum',
            key: 'detectNum',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: (text, record) => this._violation(text, record)
        },
        {
            title: '直播时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '首页推荐',
            dataIndex: 'isHomeReco',
            key: 'isHomeReco',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            align: 'left',
            render: (text, item) => (this._switchCell(text, item, 'home'))
        },
        {
            title: '频道推荐',
            dataIndex: 'isChannelReco',
            key: 'isChannelReco',
            align: 'left',
            onCell: tooltipStyle,
            width: cellWidth.normal,
            render: (text, item) => (this._switchCell(text, item, 'channel'))
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'left',
            width: 200,
            onCell: tooltipStyle,
            fixed: 'right',
            render: (id, item) => this._tableAction(item.id, item)
        }
    ];

    _tableAction = (id, item) => {
        const { tableAction } = this.props;
        if (item.liveState === '禁止观看') {
            return (
                <div className={styles.action}>
                    <div className={styles.item}>已禁播</div>
                </div>
            );
        } else if (item.liveState == '未开播' && item.isBoss == 1) {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'able', item)}>播放</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'unable', item)}>禁播</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'delete', item)}>删除</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'start', item)}>开启直播</div>
                </div>
            );
        } else if (item.liveState == '直播中' && item.isBoss == 1) {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'able', item)}>播放</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'unable', item)}>禁播</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'delete', item)}>删除</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'stop', item)}>停止直播</div>
                </div>
            );
        } else if (item.liveState == '直播中') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'able', item)}>观看</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'unable', item)}>禁播</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'virtual', item)}>虚拟人气</div>
                </div>
            );
        } else if (item.liveState == '未开播' || item.liveState == '回放') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'able', item)}>播放</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'unable', item)}>禁播</div>
                </div>
            );
        }
    };

    //本场违规
    _violation = (text, record = {}) => {
        return (
            <div className={styles.violation}>
                <div className={styles.item} onClick={() => { this.ViolationClickHandle(record); }}>
                    {this._vtooltip(text, record)}
                </div>
            </div>
        );
    };

    _vtooltip = (value, record) => {
        return <Tooltip placement="top" title={<pre className={styles.VTitleStyles}>{value}</pre>}>
            <div className={styles.layout}>
                <pre className={styles.VPreStyles}>
                    {isEmpty(value) ? '-' : value}
                </pre>
                {record.isDetectRead == '0' && <span className={styles.curt}>未读</span>}
            </div>
        </Tooltip>;
    };

    // 更新直播间为已读违规记录
    ViolationClickHandle = (record) => {
        this.props.tableAction(record.id, 'violation', record);
        if (record.isDetectRead == '0') {
            http('/show/live/readLiveDetectRecord', {
                liveType: 'LIVE_BORADCAS_CLASS', // 普通直播
                liveId: record.id,
            }, 'POST').then(res => { });
        }
    }


    // 表格项内容为开关
    _switchCell = (switchStatus, item, type) => {
        const { tableAction } = this.props;

        let checked = true;
        if (switchStatus == 0) {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={checked}
                    onChange={() => {
                        if (type == 'home') {
                            tableAction(item.id, 'switch_home', item); // 首页推荐 
                        } else {
                            tableAction(item.id, 'switch_channel', item); // 频道推荐
                        }
                    }}
                />
            </div>
        );
    };

    // 解析表格数据
    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _sortChange = (pagination, filters, sorter) => {
        this.props.tableAction(null, 'sortNo', sorter);
    }

    render() {
        const { renderData, paginationChange, scrollY } = this.props;
        const { resultTotal, pageSize, pageNum, dataList } = renderData || {};

        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    onChange={this._sortChange}
                    scroll={{ x: 'max-content', y: scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange} />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func,
    scrollY: PropTypes.string
};

export default XTableHOC(TypeTable);