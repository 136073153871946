import { createModel } from '@/store/tools';

const model = {
    namespace: 'hotelShop',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getHotelShopList': T('getHotelShopList'),
            'getStateDisable': T('getStateDisable'),
            'getBannedinfomation': T('getBannedinfomation'),
            'getStoreDetails': T('getStoreDetails'),
            'getBannedReason': T('getBannedReason'),
            'getstoreTakelog': T('getstoreTakelog'),
        },
        actions: {
            'getHotelShopList': A('getHotelShopList'), // 酒店店铺列表
            'getStateDisable': A('getStateDisable'), // 封禁状态
            'getBannedinfomation': A('getBannedinfomation'), // 解封弹框数据
            'getStoreDetails': A('getStoreDetails'), // 酒店店铺详情
            'getCleanStoreDetails': C('getStoreDetails'), // 清除酒店店铺详情
            'getBannedReason': A('getBannedReason'), // 封禁理由
            'getstoreTakelog': A('getstoreTakelog'), // 黑名单历史记录
        },
        sagas: {
            'getHotelShopList': S('getHotelShopList', '/shop/recommList/page'),
            'getStateDisable': S('getStateDisable', '/enum/StateEnum'),
            'getBannedinfomation': S('getBannedinfomation', '/shop/shopBannedInfo'),
            'getStoreDetails': S('getStoreDetails', '/shop/getShopDetails'),
            'getBannedReason': S('getBannedReason', '/shop/shopBannedType'),
            'getstoreTakelog': S('getstoreTakelog', '/shop/shopBannedInfoList'),
        },
        reducers: {
            'hotelShopList': R('getHotelShopList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'stateDisable': R('getStateDisable', []),
            'infomation': R('getBannedinfomation', {}),
            'storeDetails': R('getStoreDetails', {}),
            'bannedReason': R('getBannedReason', []),
            'accountBlack': R('getstoreTakelog', []),
        }
    })
};
export default createModel(model);