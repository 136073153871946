/**
 * 分组名称编辑 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message } from 'antd';
import styles from './CreateOrEdit.module.scss';
import { XInput } from '@/components/xqxc_ui';

class CreateOrEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmLoading: false,
            categoryTitle: ''
        };
    }
    componentDidMount() {
        const { data, title, visible } = this.props;
        this.setState({
            title,
            visible,
            categoryTitle: data.categoryTitle,
        });
    }

    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST').then(() => {
            message.success(winTxt);
            this.setState({
                visible: false,
                confirmLoading: false
            });
            this.props.closeMode(true);
        }).catch((error) => {
            message.error(error.message);
            this.setState({
                confirmLoading: false
            });
        });
    }
    // 点击确认
    _handleOk = () => {
        const { url, action, id } = this.props.data;
        const { categoryTitle } = this.state;
        switch (action) {
            case 'create':
                this.setState({ confirmLoading: true }, () => {
                    this._sendReq(url, { groupingName: categoryTitle }, '创建分组成功。');
                });
                break;
            case 'edit':
                this.setState({ confirmLoading: true }, () => {
                    this._sendReq(url, { groupingName: categoryTitle, id }, '编辑分组成功。');
                });
                break;
        }
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    // 状态启用或禁用单选框操作回调
    _stateChange = (e) => {
        this.setState({ state: e.target.value });
    }

    _inputChange = (value, type) => {
        switch (type) {
            case 'categoryTitle':
                this.setState({ categoryTitle: value });
                break;
            default:
                break;
        }
    }

    render() {
        const { visible, confirmLoading, title, categoryTitle } = this.state;

        return (
            <Modal
                width={500}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '408px' }}
                        inputStyle={{ width: '340px' }}
                        label='分组名称'
                        placeholder='请输入'
                        onChange={(value) => this._inputChange(value, 'categoryTitle')}
                        value={categoryTitle}
                    />
                </div>
            </Modal>
        );
    }
}

CreateOrEdit.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

export default CreateOrEdit;