/**
 * 代言任务违规机制
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message, Switch, InputNumber, Checkbox, Select } from 'antd';
import { XOKButton } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/systemmanage/showset/model';
const { Option } = Select;

const Main = ({
    violationType,
    getViolationType,
    violationTime,
    getViolationTime,
    updateContent,
    getViolationData,
    violationData
}) => {
    const [violation, setViolation] = useState({
        count: null,//违约次数
        deductType: 'NO_ROBBING',//扣除类型(默认-禁止抢单)
        dateType: 'DAY',//时间类型(默认-天)
        dateTimes: null,//数量
    });
    const [viLoading, setViLoading] = useState(false);//避免多次提交
    const { count, deductType, dateType, dateTimes, } = violation;
    const [whiteList, setWhiteList] = useState(false);//是否开启违约机制

    useEffect(() => {
        getViolationType();//违约执行类型
        getViolationTime();//时间单位类型
        getViolationData();//初始化默认数据
        // 获取开关状态
        http('/defaultSetting/getState', {}, 'POST')
            .then((res) => {
                setWhiteList(res.result == '1' ? true : false);
            }).catch((reject = {}) => {
                message.error(reject.message);
            });
    }, []);

    useEffect(() => {
        renderData();
    }, [violationData]);

    // 周期记录表
    const _recordHandle = () => {
        updateContent('vionlation');
    };

    //初始化默认数据
    const renderData = () => {
        const { defaultCount, actionTypeValue, actionNum, timeUnit } = violationData;
        setViolation({
            ...violation,
            count: defaultCount == '' ? null : defaultCount,//违约次数
            deductType: actionTypeValue == '' ? 'NO_ROBBING' : actionTypeValue,//扣除类型(默认-禁止抢单)
            dateTimes: actionNum == '' ? null : actionNum,//数量
            dateType: actionTypeValue == 'NO_ROBBING' ? timeUnit : 'DAY',//时间类型(默认-天)
        });
    };

    //提交数据
    const submitHandle = () => {
        if (count === null) return message.warn('请输入累计违约次数。');
        if (dateTimes === null) return message.warn('请输入执行数量。');
        setViLoading(true);

        http('/defaultSetting/save', {
            defaultCount: count,
            actionType: deductType,
            actionNum: dateTimes,
            timeUnit: dateType,
            state: whiteList ? '1' : '0'
        }, 'POST')
            .then(() => {
                setViLoading(false);
                message.success('保存成功。');
            }).catch((reject = {}) => {
                message.error(reject.message);
                setViLoading(false);
            });
    };

    //是否开启违约机制
    const _whiteListHandle = (value) => {
        http('/defaultSetting/switchDefaultSetting', {
            state: value ? '1' : '0'
        }, 'POST')
            .then(() => {
                setWhiteList(value);
            }).catch((reject = {}) => {
                message.error(reject.message);
            });
    };

    //下拉切换
    const _renderSelectData = () => {
        const { deductType } = violation;
        const dateObj = {
            'NO_ROBBING': 'method',
            'DEDUCTION_STAR_VALUE': '人气值',
            'DEDUCTION_STARLIGHT': '魅力值',
            'DEDUCTION_XIU_MI': '信用值',
            'DEDUCTION_CHARM_VALUE': '魅力值',
        };
        if (dateObj[deductType] == 'method') {
            return (
                <>
                    <Select
                        showSearch
                        style={{ width: 150 }}
                        placeholder='请选择'
                        optionFilterProp='children'
                        onChange={(value) => {
                            setViolation({
                                ...violation,
                                dateType: value
                            });
                        }}
                        value={dateType}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {violationTime.map(item => <Option key={item.code} value={item.code}>{item.name}</Option>)}
                    </Select>
                    <span className={styles.curts}>请输入整数值不支持小数点。</span>
                </>
            );
        } else {
            return (<span>{dateObj[deductType]}</span>);
        }
    };

    return (
        <div className={styles.flexBoxContainer}>
            <div className={styles.payTop}>
                <div className={styles.top_title}>
                    <span>代言任务违规机制</span>
                </div>
                <div className={styles.operateBox}>
                    <XOKButton
                        style={{ width: '102px' }}
                        label='周期记录表'
                        onClick={_recordHandle}
                    />
                </div>
                <div className={styles.riskcontent}>
                    <div className={styles.item}>
                        <div className={`${styles.switchBox} ${styles.left}`}>
                            <span>累计违约</span>
                            <InputNumber
                                style={{ width: '150px', margin: '0 10px' }}
                                placeholder='请输入次数'
                                value={count}
                                precision={0}
                                min={1}
                                max={9999999}
                                onChange={(value) => {
                                    setViolation({
                                        ...violation,
                                        count: value
                                    });
                                }}
                            />
                            <span>次&nbsp;，&nbsp;</span>
                            <Select
                                showSearch
                                style={{ width: 150 }}
                                placeholder='请选择'
                                optionFilterProp='children'
                                value={deductType}
                                onChange={(value) => {
                                    //请求接口，没有时间单位下拉，不传参数
                                    let type = {};
                                    if (value !== 'NO_ROBBING') {
                                        type = {
                                            deductType: value,
                                            dateType: undefined, //请求接口，没有时间单位下拉，不传参数
                                        };
                                    } else {
                                        type = {
                                            deductType: value,
                                            dateType: 'DAY',//默认
                                        };
                                    }
                                    setViolation({
                                        ...violation,
                                        ...type
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {violationType.map(item => <Option key={item.code} value={item.code}>{item.name}</Option>)}
                            </Select>
                            <InputNumber
                                style={{ width: '150px', margin: '0 10px' }}
                                placeholder='请输入'
                                value={dateTimes}
                                precision={0}
                                min={1}
                                max={9999999}
                                onChange={(value) => {
                                    setViolation({
                                        ...violation,
                                        dateTimes: value
                                    });
                                }}
                            />
                            {Object.keys(violationData).length > 0 && _renderSelectData()}
                        </div>
                        <div className={styles.switch}>
                            <Switch
                                checkedChildren='NO'
                                unCheckedChildren='OFF'
                                checked={whiteList}
                                onChange={_whiteListHandle} />
                        </div>
                    </div>
                </div>
                <h4 className={styles.tips}>提示：修改违约机制后记得同步更新“任务违规说明”（星秀攻略&gt;攻略列表）</h4>
            </div>
            {/* <div className={styles.rules}>
                <h5 className={styles.tops}>规则：</h5>
                <span>● 只能填写数值，整数不含小数点，不能输入表情，特殊符号；</span>
                <span>● 最小数值1，最大数值9999999；小于1时自动变为1，大与9999999自动变为 9999999；</span>
                <span className={styles.mart}>● 勾选“每次违约”，前面的配置“置灰”并清空数值；</span>
                <div className={styles.martLine}>
                    <span>累计违约<i />次&nbsp;/&nbsp;</span>
                    <Checkbox
                        readOnly
                        style={{ marginLeft: '10px' }}
                        checked={true}
                    >
                        每次违约，
                    </Checkbox>
                    <Select
                        readOnly
                        style={{ width: 150 }}
                        value={'0'}
                    >
                        <Option value='0'>禁止抢单</Option>
                    </Select>
                    <i />天
                </div>
                <span>● 用户每触发一次惩罚后，用户的违约次数则清0,从新计算；</span>
                <span>● 如1号设置的是累计违约10次后触发惩罚，用户已累计违约9次，5号改为“每次违约”，用户需执行完1<br />号设置10次机制，再执行5号设置的“每次违约”机制，
                    如在执行5号设置的之前又设置了一条则执行最新的这一条；</span>
            </div> */}
            <XOKButton loading={viLoading} onClick={submitHandle} style={{ width: '100px', marginTop: '50px' }} label='保存' />
        </div>
    );
};


Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    updateContent: PropTypes.func,//路由

    violationType: PropTypes.array,
    getViolationType: PropTypes.func,//违约执行类型(action)
    violationTime: PropTypes.array,
    getViolationTime: PropTypes.func,//时间单位类型(action)
    violationData: PropTypes.object,
    getViolationData: PropTypes.func,//违约数据初始化数据(action)
};

const mapStateToProps = (state) => ({
    violationTime: state.showset.violationTime, // 时间单位类型
    violationType: state.showset.violationType, // 违约执行类型
    violationData: state.showset.violationData, // 违约数据初始化数据
});

export default connect(mapStateToProps, { ...model.actions })(Main);
