/**
 * 【消息分类】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton, XSelect, showConfirm } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getMessageClassifyList } from '@/reducers/messagemanage/messageClassify/actions';
import AdvertTable from './components/AdvertTable';
import DeleteModal from '../../../modal/DeleteModal';
import CreateModal from './modal/CreateModal';
import EditModal from './modal/EditModal';
import http from '@/assets/api/http';
import { message } from 'antd';

const statusArr = [{ id: 0, value: '禁用', }, { id: 1, value: '启用' }];

class Main extends React.Component {

    state = {
        companyStatus: {},
        messageTypeName: '',
        key: false,
        img: {},
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getMessageClassifyList();
    }

    // 状态转换
    _companyStatusChange = e => {
        this.setState({
            companyStatus: e,
        });
    }

    // 查询
    _searchHandle = () => {
        const { key } = this.state;
        this._refsh();
        this.setState({
            key: !key,
        });
    }

    _refsh = () => {
        const { messageTypeName, companyStatus } = this.state;
        const param = {
            // pageNum,
            // pageSize,
            messageTypeName,
            state: companyStatus.id,
        };
        this.props.getMessageClassifyList(param);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            companyStatus: {},
            messageTypeName: '',
            // pageNum: 1,
            // pageSize: 10,
        });
        this.props.getMessageClassifyList();
    }

    // 新建用户
    _newHandle = () => {
        this.setState({
            currentOpenMode: 'create',
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        });
        const { messageTypeName, companyStatus } = this.state;
        const param = {
            pageNum,
            pageSize,
            messageTypeName,
            state: companyStatus.id,
        };
        this.props.getMessageClassifyList(param);
    }

    // 状态切换，是否前端显示
    _actionHandle = (key, type, value) => {
        var param = {};
        var url = '';
        if (type === 'state') {
            param = {
                messageTypeCode: key,
                state: value,
            };
            url = '/user/messageType/updateState';
        } else if (type === 'isStation') {
            param = {
                messageTypeCode: key,
                isStation: value,
            };
            url = '/user/messageType/updateStation';
        }

        http(url, param, 'POST')
            .then(() => {
                message.success('修改成功!');
                this._refsh();
            }).catch((reject) => {
                message.error(reject.message);
            });
    }

    // 表格操作
    _tableAction = (messageTypeCode, type, img) => {
        switch (type) {
            case 'edit':
                this.setState({ currentOpenMode: 'edit', openModeParam: messageTypeCode, img });
                break;
            case 'del':
                showConfirm('是否确定删除？', '', () => {
                    http('/user/messageType/deleteMessageType', { messageTypeCode }, 'POST')
                        .then(() => {
                            message.success('删除成功!');
                            this._refsh();
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        const { openModeParam, img } = this.state;
        var obj = { messageTypeCode: openModeParam, img };
        switch (this.state.currentOpenMode) {
            case 'del':
                return <DeleteModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            case 'create':
                return <CreateModal refsh={this._refsh} title='新建消息分类' visible={true} closeMode={this._closeMode} />;
            case 'edit':
                return <EditModal refsh={this._refsh} title='编辑消息分类' data={obj} visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

    render() {
        const { messageTypeName, companyStatus, key } = this.state;
        const { messageClassifyList } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
                <SearchBox>
                    <SearchItem>
                        <XInput
                            label='分类名称'
                            placeholder='请输入'
                            value={messageTypeName}
                            bindThis={this}
                            bindName='messageTypeName'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='状态'
                            placeholder='请选择'
                            renderData={statusArr}
                            onChange={this._companyStatusChange}
                            dataIndex='value'
                            keyIndex='id'
                            value={companyStatus.value}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </SearchItem>
                </SearchBox>
                <div className={styles.row2}>
                    <XOKButton
                        style={{ width: '120px' }}
                        label='创建消息分类'
                        onClick={this._newHandle}
                    />
                </div>
                <AdvertTable key={key} renderData={messageClassifyList} actionHandle={this._actionHandle} tableAction={this._tableAction} />
                {this._renderMode()}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getMessageClassifyList: PropTypes.func,
    messageClassifyList: PropTypes.array,
};
const mapStateToProps = (state) => ({
    messageClassifyList: state.MessageClassify.messageClassifyList,
    messagePushPort: state.MessageClassify.messagePushPort,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getMessageClassifyList })(Main);