/**
 * 店铺预览
 */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { message, Modal, Button, List, Card, Typography } from 'antd';
import { regFenToYuan } from '@/util/money';
import model from '@/reducers/auditmanage/businessAudit/model';
import { toPng } from 'dom-to-image';
import useQiniuUpload from '@/components/NewUpload/QiniuUpload';

const { qiniuUpload } = useQiniuUpload({ uploadType: 'image' }, { next: () => { }, error: () => { }, complete() { } });
const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

const { Meta } = Card;
const { Text } = Typography;

const Ciassify = ({
    closeMode,
    modeParams: {
        companyName = '',
        id = ''
    } = {},
    getBusinessGoods,
    businessGoods = []
}) => {

    const [selfLoad, setSelfLoad] = useState(false);
    const [pageNum, setPageNum] = useState(1);

    useEffect(() => {
        getBusinessGoods({ merchantId: id, pageNum, pageSize: 4 });
    }, [pageNum]);



    const downloadPage = () => {
        setSelfLoad(true);
        toPng(document.getElementById('screenshot'))
            .then(dataUrl => {
                qiniuUpload(dataURLtoFile(dataUrl, Math.random().toString()), ({ fileUrl }) => {
                    http('/merchantEntering/info/saveAuditGoodsImg', { merchantId: id, goodsImgUrl: fileUrl, goodsIdList: businessGoods.map(item => (item.id)) }, 'POST')
                        .then(res => {
                            message.success(res.message);
                            setSelfLoad(false);
                            closeMode(true);
                        })
                        .catch(err => {
                            message.error(err.message);
                            setSelfLoad(false);
                        });
                }, () => {
                    message.error('图片上传失败，请重新上传');
                    setSelfLoad(false);
                });
            })
            .catch(error => {
                message.error('截图失败，由于商品列表中某个商品图片模糊无法抓取内容，请尝试点击刷新按钮刷新列表');
                setSelfLoad(false);
            });
    };

    return <Modal
        width={472}
        centered
        title='APP店铺预览页'
        bodyStyle={{ padding: '10px 20px' }}
        visible={true}
        confirmLoading={false}
        onCancel={() => closeMode(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        footer={false}
    >
        <div className={styles.container}>
            <div className={styles.infoBox} id='screenshot'>
                <div className={styles.header}>
                    <div className={styles.title}>{companyName}</div>
                    <div className={styles.label}>营业执照</div>
                </div>
                <div className={styles.list}>
                    <List
                        grid={{ column: 2 }}
                        dataSource={businessGoods.slice(0, 4)}
                        renderItem={item => (
                            <List.Item>
                                <Card
                                    hoverable
                                    cover={<img height='200px' alt='' src={item.mainImgUrl} />}
                                >
                                    <Meta
                                        title={item.goodsName}
                                        description={
                                            <Fragment>
                                                <Text type='danger'>￥{regFenToYuan(item.salePrice)} </Text>
                                                <Text delete>￥{regFenToYuan(item.marketPrice)}</Text>
                                            </Fragment>
                                        } />
                                </Card>
                            </List.Item>
                        )}
                    />
                </div>
            </div>
            <div className={styles.buttonBox}>
                <Button
                    style={{ marginRight: '20px' }}
                    disabled={selfLoad}
                    onClick={() => setPageNum(pageNum + 1)}
                >刷新</Button>
                <Button
                    type='primary'
                    loading={selfLoad}
                    onClick={downloadPage}
                >保存</Button>
            </div>
        </div>
    </Modal>;
};
Ciassify.propTypes = {
    closeMode: PropTypes.func,
    modeParams: PropTypes.object,
    getBusinessGoods: PropTypes.func,
    businessGoods: PropTypes.array
};
export default connect(state => ({
    businessGoods: state.businessAudit.businessGoods,
}), { ...model.actions })(Ciassify);