import { createModel } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };

const model = {
    namespace: 'dailystatement2',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getFinancialTypeEnum': T('getFinancialTypeEnum'),
            'getYunstPayTypeEnum': T('getYunstPayTypeEnum'),
            'getReconciliationDailyData': T('getReconciliationDailyData'),
            'getReconciliationDailyDetail': T('getReconciliationDailyDetail'),
            'getReconciliationDailyDetailTable': T('getReconciliationDailyDetailTable'),
        },
        actions: {
            'getFinancialTypeEnum': A('getFinancialTypeEnum'),
            'getYunstPayTypeEnum': A('getYunstPayTypeEnum'),
            'getReconciliationDailyData': A('getReconciliationDailyData'),
            'getReconciliationDailyDetail': A('getReconciliationDailyDetail'),
            'getReconciliationDailyDetailTable': A('getReconciliationDailyDetailTable'),
        },
        sagas: {
            'getFinancialTypeEnum': S('getFinancialTypeEnum', '/enum/UnionFinancialTypeEnum'), //财务类型枚举
            'getYunstPayTypeEnum': S('getYunstPayTypeEnum', '/enum/YunstPayTypeEnum'),
            'getReconciliationDailyData': S('getReconciliationDailyData', '/unionreconciliation/list/settlement'),
            'getReconciliationDailyDetail': S('getReconciliationDailyDetail', '/unionreconciliation/detail/detail'),
            'getReconciliationDailyDetailTable': S('getReconciliationDailyDetailTable', '/unionreconciliation/detail/datalist'),
        },
        reducers: {
            'FinancialTypeEnum': R('getFinancialTypeEnum', []),
            'YunstPayTypeEnum': R('getYunstPayTypeEnum', []),
            'ReconciliationDailyData': R('getReconciliationDailyData', dataList),
            'ReconciliationDailyDetail': R('getReconciliationDailyDetail', {}),
            'ReconciliationDailyDetailTable': R('getReconciliationDailyDetailTable', dataList),
        }
    })
};
export default createModel(model);