import React, { useRef, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Cascader, Checkbox } from 'antd';
import { nonEmptyCall } from '@/util/utils';
import { LoopTree, LoopTreeLeaf, WrapArray } from '@/util/DataStructure/Tree/NTree';
const CCascader = props => {
    const { selectMode = 'default', fieldNames = { label: 'label', children: 'children' }, renderLabel, mutipleLabelField, options, onCheck, children, ...otherProps } = props;
    const wrapIdRef = useRef('lz-ccascadedr-' + Math.random().toString().replace('0.', ''));
    const CCOptions = WrapArray(options || [], fieldNames.children);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    function onCheckNode(event, selectedOption) {

        if (selectMode === 'multiple') {
            const checkState = !selectedOption.checked;
            LoopTree(selectedOption, (current) => {
                current.checked = checkState;
                if (current[fieldNames.children])
                    current.halfChecked = false;
            }, fieldNames.children);
            LoopTreeLeaf(CCOptions, (current, result) => {
                if (current[fieldNames.children] && current[fieldNames.children].length) {
                    const uncheckCount = result.filter(c => c.checked !== true);
                    const halfCheckCount = result.filter(c => c.halfChecked === true);
                    current.halfChecked = false;
                    if (uncheckCount.length === 0) {
                        current.checked = true;
                    }
                    else if (uncheckCount.length === result.length) {
                        current.checked = false;
                    }
                    else {
                        current.halfChecked = true;
                    }
                    if (halfCheckCount.length > 0) {
                        current.halfChecked = true;
                    }
                }
                return {
                    checked: current.checked,
                    halfChecked: current.halfChecked
                };
            }, fieldNames.children);
        }
        else {
            LoopTree(CCOptions, (current) => current.checked = false, fieldNames.children);
            selectedOption.checked = true;
        }
        nonEmptyCall(onCheck, selectedOption);
        forceUpdate(ignored);
        event.stopPropagation();
    }
    if (props.renderLabel || selectMode === 'multiple') {

        LoopTreeLeaf(CCOptions, (current, result) => {
            if (current[fieldNames.children] && current[fieldNames.children].length) {
                const uncheckCount = result.filter(c => c.checked !== true);
                const halfCheckCount = result.filter(c => c.halfChecked === true);
                current.halfChecked = false;
                if (uncheckCount.length === 0) {
                    current.checked = true;
                }
                else if (uncheckCount.length === result.length) {
                    current.checked = false;
                }
                else {
                    current.halfChecked = true;
                }
                if (halfCheckCount.length > 0) {
                    current.halfChecked = true;
                }
            }
            return {
                checked: current.checked,
                halfChecked: current.halfChecked
            };
        }, fieldNames.children);

        LoopTree(CCOptions, (current) => {
            if (selectMode === 'multiple') {
                current[fieldNames.label] = (React.createElement('span', null,
                    React.createElement(Checkbox, { indeterminate: current.halfChecked, checked: current.checked, onClick: (e) => onCheckNode(e, current) }),
                    React.createElement('span', null, nonEmptyCall(renderLabel, current) || current[(mutipleLabelField || fieldNames.value)])));
            }
            else {
                current[fieldNames.label] = nonEmptyCall(renderLabel, current, onCheckNode) || current[fieldNames.label];
            }
        }, fieldNames.children);
    }
    return (React.createElement('div', { id: wrapIdRef.current, className: 'lz-ccascader' },
        React.createElement(Cascader, Object.assign({ changeOnSelect: true }, otherProps, { value: otherProps.value, fieldNames: fieldNames, getPopupContainer: () => document.getElementById(wrapIdRef.current), options: options }), children)));
};

CCascader.propTypes = {
    options: PropTypes.array,

    /** selectMode 为 multiple 时，指定用来展示标题的字段不能为 fieldNames.label 指定的 key，否则会造成死循环，
     * 应该通过 mutipleLabelField 重新指定 */
    selectMode: PropTypes.string,
    mutipleLabelField: PropTypes.string,

    /** 不能 renderLabel 里使用 fieldNames.label 指定的字段，否则会造成死循环 */
    renderLabel: PropTypes.func,

    onCheck: PropTypes.func,
    fieldNames: PropTypes.object,
    children: PropTypes.children
};

export default CCascader;