import React from 'react';
import styles from './AdvertTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table, Tooltip, } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class AdvertTable extends React.Component {

    _takeColumns = () => ([
        {
            title: '频道分类',
            dataIndex: 'reportClassValue',
            key: 'reportClassValue',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '发布人手机号',
            dataIndex: 'accountTel',
            key: 'accountTel',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '被举报标题',
            dataIndex: 'contentTitle',
            key: 'contentTitle',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '举报人手机号',
            dataIndex: 'reportAccountTel',
            key: 'reportAccountTel',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '举报理由',
            dataIndex: 'reportTypeName',
            key: 'reportTypeName',
            width: '18%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'reportStateValue',
            key: 'reportStateValue',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '举报时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '20%',
            render: (text, item) => this._renderActionCell(text, item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList.map((item) => {
                item.reportStateValue = item.reportState ? item.reportState.value : '';//状态
                item.reportClassValue = item.reportClass ? item.reportClass.value : '';//频道分类
                item.reportClassCode = item.reportClass ? item.reportClass.code : '';//频道分类
                return item;
            });
        } else {
            return [];
        }
    }


    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        const { reportState = {} } = item;
        switch (reportState.code) {
            case 'UNPROCESSED':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, item, 'info')}>查看详情</div>
                        {item.reportClassCode != 'GRAPHICS_CLASS' && <div className={styles.item} onClick={() => tableAction(key, item, 'play')}>播放</div>}
                        <div className={styles.item} onClick={() => tableAction(key, item, 'deal')}>去处理</div>
                        {item.reportClassCode == 'GRAPHICS_CLASS' && <div className={styles.item} onClick={() => tableAction(item.contentId, item, 'graphic')}>图文详情</div>}
                    </div>
                );
            case 'REJECT':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, item, 'info')}>查看详情</div>
                        {item.reportClassCode != 'GRAPHICS_CLASS' && <div className={styles.item} onClick={() => tableAction(key, item, 'play')}>播放</div>}
                        {item.reportClassCode == 'GRAPHICS_CLASS' && <div className={styles.item} onClick={() => tableAction(item.contentId, item, 'graphic')}>图文详情</div>}
                    </div>
                );
            case 'BANNED':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, item, 'info')}>查看详情</div>
                        {item.reportClassCode != 'GRAPHICS_CLASS' && <div className={styles.item} onClick={() => tableAction(key, item, 'play')}>播放</div>}
                        {item.reportClassCode == 'GRAPHICS_CLASS' && <div className={styles.item} onClick={() => tableAction(item.contentId, item, 'graphic')}>图文详情</div>}
                    </div>
                );
            default: return null;
        }
    }

    render() {
        const { paginationChange, pagiNation, renderData, } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={pagiNation.resultTotal}
                    pageSize={pagiNation.pageSize}
                    pageNum={pagiNation.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    pagiNation: PropTypes.object, // 分页数据
    renderData: PropTypes.array.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default AdvertTable;