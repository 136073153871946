/**
 * 新建部门
 */
import React from 'react';
import { Modal } from 'antd';
import { XInput, XTextArea } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './CreateModal.module.scss';
import http from '@/assets/api/http';
import { message } from 'antd';

class CreateModal extends React.Component {
    state = {
        visible: this.props.visible,
        confirmLoading: false,
        deptName: '', // 部门名称
        principal: '', // 负责人
        mobile: '', // 手机号
        remark: '', // 备注
    }

    _handleOk = () => {
        const { deptName, principal, mobile, remark } = this.state;
        http('/admin/dept/createDept', { deptName, principal, mobile, remark }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('创建成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, deptName, principal, mobile, remark } = this.state;

        return (
            <Modal
                width={500}
                centered
                title='新建部门'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '440px' }}
                        inputStyle={{ width: '360px' }}
                        label='部门名称'
                        placeholder='请输入部门名称'
                        value={deptName}
                        bindThis={this}
                        bindName='deptName'
                    />
                    <XInput
                        style={{ width: '440px', marginTop: '20px', marginLeft: '14px' }}
                        inputStyle={{ width: '360px' }}
                        label='负责人'
                        placeholder='请输入负责人'
                        value={principal}
                        bindThis={this}
                        bindName='principal'
                    />
                    <XInput
                        style={{ width: '440px', marginTop: '20px', marginLeft: '14px' }}
                        inputStyle={{ width: '360px' }}
                        label='手机号'
                        placeholder='请输入手机号'
                        value={mobile}
                        bindThis={this}
                        bindName='mobile'
                    />
                    <XTextArea
                        style={{ width: '410px', marginLeft: '28px', marginTop: '20px' }}
                        inputStyle={{ width: '360px', resize: 'none' }}
                        label='备注'
                        placeholder='请输入备注'
                        value={remark}
                        bindThis={this}
                        bindName='remark'
                    />
                </div>
            </Modal>
        );

    }
}

CreateModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default CreateModal;
