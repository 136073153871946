//获取/保存运营中心列表
export const GET_OPERATION_LISTDATA = 'OPERATIONMANAGE/GET_OPERATION_LISTDATA';
export const SET_OPERATION_LISTDATA = 'OPERATIONMANAGE/SET_OPERATION_LISTDATA';

//获取/保存区域公司列表
export const GET_COMPANY_LISTDATA = 'OPERATIONMANAGE/GET_COMPANY_LISTDATA';
export const SET_COMPANY_LISTDATA = 'OPERATIONMANAGE/SET_COMPANY_LISTDATA';

//获取/保存状态下拉
export const GET_OPERATION_STATUS = 'OPERATIONMANAGE/GET_OPERATION_STATUS';
export const SET_OPERATION_STATUS = 'OPERATIONMANAGE/SET_OPERATION_STATUS';

//获取/保存公司详情
export const GET_OPERATION_DETAIL = 'OPERATIONMANAGE/GET_OPERATION_DETAIL';
export const SET_OPERATION_DETAIL = 'OPERATIONMANAGE/SET_OPERATION_DETAIL';

//获取/保存运营公司下拉
export const GET_OPERATIONCENTER_SELE = 'OPERATIONMANAGE/GET_OPERATIONCENTER_SELE';
export const SET_OPERATIONCENTER_SELE = 'OPERATIONMANAGE/SET_OPERATIONCENTER_SELE';

//获取/保存结算类型下拉
export const GET_SETTLEUNITTYPE_SELE = 'OPERATIONMANAGE/GET_SETTLEUNITTYPE_SELE';
export const SET_SETTLEUNITTYPE_SELE = 'OPERATIONMANAGE/SET_SETTLEUNITTYPE_SELE';

//获取/保存结算单位下拉
export const GET_SETTLEUNIT_SELE = 'OPERATIONMANAGE/GET_SETTLEUNIT_SELE';
export const SET_SETTLEUNIT_SELE = 'OPERATIONMANAGE/SET_SETTLEUNIT_SELE';

//获取/保存省
export const GET_OPERATION_PROVINCE = 'OPERATIONMANAGE/GET_OPERATION_PROVINCE';
export const SET_OPERATION_PROVINCE = 'OPERATIONMANAGE/SET_OPERATION_PROVINCE';

//获取/保存市
export const GET_OPERATION_CITY = 'OPERATIONMANAGE/GET_OPERATION_CITY';
export const SET_OPERATION_CITY = 'OPERATIONMANAGE/SET_OPERATION_CITY';

//获取/保存区
export const GET_OPERATION_AREA = 'OPERATIONMANAGE/GET_OPERATION_AREA';
export const SET_OPERATION_AREA = 'OPERATIONMANAGE/SET_OPERATION_AREA';

//获取省份
export const GET_PROVINCE_SELE = 'OPERATIONMANAGE/GET_PROVINCE_SELE';
export const SET_PROVINCE_SELE = 'OPERATIONMANAGE/SET_PROVINCE_SELE';

//获取城市
export const GET_CITY_SELE = 'OPERATIONMANAGE/GET_CITY_SELE';
export const SET_CITY_SELE = 'OPERATIONMANAGE/SET_CITY_SELE';
export const RESET_CITY_SELE = 'OPERATIONMANAGE/RESET_CITY_SELE';

//获取类型
export const GET_TYPE_ENUM = 'OPERATIONMANAGE/GET_TYPE_ENUM';
export const SET_TYPE_ENUM = 'OPERATIONMANAGE/SET_TYPE_ENUM';
