import { createModel } from '@/store/tools';

const model = {
    namespace: 'companyshare',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getCompanyshareList': T('getCompanyshareList'),
        },
        actions: {
            'getCompanyshareList': A('getCompanyshareList'),
        },
        sagas: {
            'getCompanyshareList': S('getCompanyshareList', '/boss/order/distribution/distributionOrderForAreaCompanyExportList'),//区域公司分销分享销售情况
        },
        reducers: {
            'companyshareList': R('getCompanyshareList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);