/**
 * [会员头像昵称审核]
 * @author: Fu Xiaochun
 * @date: 2023-03-27 
 */

import React, { useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, message, Modal, Button } from 'antd';
import { XInput, XOKButton, XCancelButton, XSelect, XRangePicker } from '@/components/xqxc_ui';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import TableData from './components/TableData';
import http from '@/assets/api/http';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { useHistory } from 'react-router-dom';
import InfoModal from './components/InfoModal';

function MainList(props){

    const history = useHistory();

    const [result, setResult] = useState({dataList:[]});
    const [aliveInit, setAliveInit] = useState(false);

    const [account, setAccount] = useState();  // 会员账号
    const [name, setName] = useState();    // 会员昵称
    const [origin, setOrigin] = useState({});  // 数据来源
    const [group, setGroup] = useState();  // 数据分组
    const [status, setStatus] = useState({});  // 审核状态
    const [resetCode, setResetCode] = useState(0);
    const page = useRef({ pageNum: autoPageNum, pageSize: autopageSize });

    const [originEnum, setOriginEnum] = useState([]);
    const [statusEnum, setStatusEnum] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [curItem, setCurItem] = useState();
    const [type, setType] = useState();

    const [rowSelections, setRowSelections] = useState([]);  // 选择批量处理的数据
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);


    useEffect(() => {
        props.keepSecondNavBreadcrumb();
        getOriginEnum();
        getStateEnum();
    }, []);

    useEffect(() => {
        aliveInit && onSearch();
    }, [aliveInit]);

    // 重置后请求
    useEffect(() => {
        if (resetCode > 0) {
            onSearch();
        }
    }, [resetCode]);

    // 数据来源枚举
    const getOriginEnum = ()=>{
        http('/enum/AvatarNicknameAuditSourceEnum').then(res=>{
            setOriginEnum(res.result);
        }).catch(err=>{
            message.error(err.message);
        });
    };
    // 审核状态枚举
    const getStateEnum = ()=>{
        http('/enum/AvatarNicknameAuditStatusEnum').then(res=>{
            setStatusEnum(res.result);
        }).catch(err=>{
            message.error(err.message);
        });
    };

    const initData = (data) => {
        page.current = { pageNum: data.pageNum, pageSize: data.pageSize };
    };


    // 查询
    const onSearch = (usecache) => {
        if (usecache !== 'usecache') {
            page.current = { pageNum: autoPageNum, pageSize: autopageSize };
        }
        let { pageNum, pageSize } = page.current;
        let searchCache = {
            accountName: account,
            nickName: name,
            isHistory: origin.code,
            group: group,
            auditState: status.code,
            pageNum,
            pageSize,
        };

        http('/user/userAvatarNicknameAudit/list', searchCache).then(res=>{
            setResult(res.result);
        }).catch(err=>{
            message.error(err.message);
        });
        
        KeepAlive.saveCache({ account, name, origin, group, status, pageNum, pageSize });
    };

    // 重置
    const onReset = () => {
        setAccount();
        setName();
        setOrigin({});
        setGroup();
        setStatus({});
        setResetCode(resetCode + 1);
        page.current = { pageNum: autoPageNum, pageSize: autopageSize };
    };

    const onPaginationChange = (size, num) => {
        page.current = { pageSize: size, pageNum: num };
        onSearch('usecache');
    };

    const _tableAction = (val, type, data) => {
        switch (type) {
            case 'detail':
                setCurItem(data);
                setType('detail');
                setShowModal(true);
                break;
            case 'audit':
                setCurItem(data);
                setType('audit');
                setShowModal(true);
                break;
            case 'fastpass':
                {
                    let formData = {
                        id: data.id,
                        avatarAuditState: 'PASS_AUDIT',
                        nicknameAuditState: 'PASS_AUDIT',
                        nickname: data.nickname,
                        avatar: data.avatar
                    };
                    http('/user/userAvatarNicknameAudit/audit', formData).then(res=>{
                        message.success('操作成功！');
                        onSearch('usecache');
                    }).catch(err=>{
                        message.error(err.message);
                    });
                }
                break;
            case 'pass':
                // 直接通过
                Modal.confirm({
                    title: '确定通过审核？',
                    onOk: ()=>{
                        let formData = {
                            id: data.id,
                            avatarAuditState: 'PASS_AUDIT',
                            nicknameAuditState: 'PASS_AUDIT',
                            nickname: data.nickname,
                            avatar: data.avatar
                        };
                        http('/user/userAvatarNicknameAudit/audit', formData).then(res=>{
                            message.success('操作成功！');
                            onSearch('usecache');
                        }).catch(err=>{
                            message.error(err.message);
                        });
                    },
                });
                break;
            default:
            // nothing to do
        }
    };

    const onBatchPass = ()=>{
        if(!rowSelections.length){
            message.error('请先选择要审核的数据！');
            return false;
        }
        // let formData = {
        //     id: data.id,
        //     avatarAuditState: 'PASS_AUDIT',
        //     nicknameAuditState: 'PASS_AUDIT',
        //     nickname: data.nickname,
        //     avatar: data.avatar
        // };
        http('/user/userAvatarNicknameAudit/batchAudit', rowSelections).then(res=>{
            setRowSelections([]);
            setSelectedRowKeys([]);
            message.success('操作成功！');
            onSearch('usecache');
        }).catch(err=>{
            message.error(err.message);
        });
    };

    const onModalOk = (data)=>{
        setShowModal(false);
        onSearch();
    };

    const onRowSelectionChange = (arr)=>{
        setRowSelections(arr);
        let keys = arr.map(v=>v.id);
        setSelectedRowKeys(keys);
    };

    return (
        <KeepAlive render={(state) => {
            if (!state.aliveInit) {
                initData(state);
                setAliveInit(true);
            }
        }}>
            <div className={styles.flexBoxContainer}>
                <SearchBox>
                    <SearchItem>
                        <XInput
                            style={{marginRight: '30px'}}
                            label='会员账号'
                            placeholder='请输入会员账号'
                            bindName='account'
                            value={account}
                            bindThis={setAccount}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            style={{marginRight: '30px'}}
                            label='会员昵称'
                            placeholder='请输入会员昵称'
                            bindName='name'
                            value={name}
                            bindThis={setName}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            selectStyle={{ width: '150px' }}
                            style={{marginRight: '30px'}}
                            label='数据来源'
                            placeholder='请选择'
                            renderData={originEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={origin.value}
                            bindName='origin'
                            bindThis={setOrigin}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            style={{marginRight: '30px'}}
                            label='数据分组'
                            placeholder='请输入分组'
                            bindName='group'
                            value={group}
                            bindThis={setGroup}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            selectStyle={{ width: '150px' }}
                            style={{marginRight: '30px'}}
                            label='审核状态'
                            placeholder='请选择'
                            renderData={statusEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={status.value}
                            bindName='status'
                            bindThis={setStatus}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            onClick={onSearch}
                        />
                        <XCancelButton
                            style={{marginRight: '20px'}}
                            label='重置'
                            onClick={onReset}
                        />
                        <XOKButton
                            style={{width: '100px'}}
                            label='批量通过'
                            onClick={onBatchPass}
                        />
                    </SearchItem>
                </SearchBox>
                <div className={styles.tableContainer}>
                    <TableData selectedRowKeys={selectedRowKeys} setRowSelections={onRowSelectionChange} renderData={result} paginationChange={onPaginationChange} tableAction={_tableAction} />
                </div>
                {showModal && <InfoModal type={type} data={curItem} onOk={onModalOk} onClose={()=>{setShowModal(false);}} />}
            </div>
        </KeepAlive>
    );
}

MainList.propTypes = {
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb})(MainList);