import React from 'react';
import { Modal, Form, Select, Input, message, DatePicker, Button, Row, Col } from 'antd';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import moment from 'moment';
import CustomUpload from '@/components/NewUpload';

class CreateLive extends React.PureComponent {

    state = {
        liveBroadClasses: [], // 直播分类
        currentClassTypeName: '', // 当前直播分类名称
        courseList: [], // 课程分类
    }

    componentDidMount() {
        http('/liveBroad/liveBroadClasses', {}, 'POST').then((response) => {
            let list = response.result || [];
            list = list.filter((item) => {
                return item.classTypeCode != 'BUNIESS_LIVE_BORADCAS_CLASS';
            });
            this.setState({ liveBroadClasses: list });
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _handleOk = (e) => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (err) return;
            const { title, expectedPlayTime, broadcastClassId, tel, roomKey, poster, video, courseId } = fieldsValue;
            const coverPage = (Array.isArray(poster) && poster.length > 0) ? poster[0].url : '';
            const videoUrl = (Array.isArray(video) && video.length > 0) ? video[0].url : '';

            let param = {
                title,
                startTime: moment(expectedPlayTime).format('YYYY-MM-DD HH:mm:ss'),
                broadcastClassId,
                broadcastClass: this._getBroadcastClass(broadcastClassId),
                tel,
                roomKey,
                coverPage,
                videoUrl,
            };

            http('/liveBroad/createLiveBroad', param, 'POST').then((response) => {
                const { id, broadcastClassId, broadcastClass } = response.result;
                this.props.closeAndOpenNewMode('previewLive', { id, broadcastClassId, broadcastClass });
            }).catch((e) => {
                message.error(e.message);
            });
        });
    }

    _getBroadcastClass = (broadcastClassId) => {
        const { liveBroadClasses } = this.state;
        let classTypeCode = '';
        for (let i = 0; i < liveBroadClasses.length; i++) {
            if (liveBroadClasses[i].id == broadcastClassId) {
                classTypeCode = liveBroadClasses[i].classTypeCode;
                break;
            }
        }
        return classTypeCode;
    }

    _handleCancel = () => {
        this.props.close();
    }

    _normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    }

    // 直播间分类切换时，获取当前选中的分类Code
    _liveBroadClassesChange = (value) => {
        const { liveBroadClasses } = this.state;
        let classTypeName = '';
        for (let i = 0; i < liveBroadClasses.length; i++) {
            if (liveBroadClasses[i].id == value) {
                classTypeName = liveBroadClasses[i].className;
                break;
            }
        }
        this.setState({ currentClassTypeName: classTypeName, courseList: [] });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { liveBroadClasses, currentClassTypeName, courseList } = this.state;

        return (
            <Modal
                width={700}
                centered
                title='发起直播'
                visible={true}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='创建直播间'
            >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                    <Form.Item label='直播间标题'>
                        {getFieldDecorator('title', {
                            rules: [{ required: true, message: '请填写直播间标题' }],
                        })(<Input allowClear placeholder='请填写直播间标题' />)}
                    </Form.Item>
                    <Form.Item label='预计开播时间'>
                        {getFieldDecorator('expectedPlayTime', {
                            rules: [{ required: true, message: '请填写预计开播时间' }],
                        })(<DatePicker format='YYYY-MM-DD HH:mm' showTime allowClear placeholder='请填写预计开播时间' style={{ width: '100%' }} />)}
                    </Form.Item>
                    <Form.Item label='直播间分类'>
                        {getFieldDecorator('broadcastClassId', {
                            rules: [{ required: true, message: '请选择直播间分类' }],
                        })(
                            <Select placeholder='请选择直播间分类' allowClear onChange={this._liveBroadClassesChange}>
                                {liveBroadClasses.map(item => <Select.Option key={String(item.id)} value={String(item.id)}>{item.className}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label='主播账号'>
                        {getFieldDecorator('tel', {
                            rules: [{ required: true, message: '请填写主播账号' }],
                        })(<Input disabled={courseList.length > 0} allowClear placeholder='请填写主播账号' />)}
                    </Form.Item>
                    {
                        currentClassTypeName == '在线课堂' ? <Form.Item label='房间口令'>
                            {getFieldDecorator('roomKey', {
                                rules: [
                                    {
                                        validator: (rule, value = '', callback) => {
                                            var regPos = /^\d{9}$/; //判断是否是数字。
                                            if (value.length > 0 && !regPos.test(value)) {
                                                callback('请填写9位数字组成的房间口令');
                                            } else {
                                                callback();
                                            }
                                        }
                                    }
                                ],
                            })(<Input allowClear placeholder='请填写9位数字组成的房间口令' />)}
                        </Form.Item> : null
                    }
                    <Form.Item label="选择海报">
                        {getFieldDecorator('poster', {
                            valuePropName: 'fileList',
                            getValueFromEvent: this._normFile,
                            rules: [
                                { required: true, type: 'array', message: '请上传海报' },
                            ],
                        })(
                            <CustomUpload
                                accept='.png, .jpg, .jpeg'
                                listType='picture-card'
                                wallMode='single'
                                removeError={true}
                                qiniuUploadProps={{ uploadType: 'image' }}
                            // beforeUpload={file => {
                            //     const isType = ['image/png', 'image/jpeg', 'image/jpg'];
                            //     const isLt5M = file.size / 1024 / 1024 > 5;
                            //     if (!isType.includes(file.type)) {
                            //         message.error('只能上传png、jpg、jpeg格式的图片!');
                            //         file.status = 'error';
                            //         return false;
                            //     } else if (isLt5M) {
                            //         message.error('图片须小于 5M');
                            //         file.status = 'error';
                            //         return false;
                            //     }
                            // }}
                            >
                                <div style={{ color: '#999999', fontSize: '12px' }}>上传图片</div>
                            </CustomUpload>)}
                        <div>海报在每日说app端，是以封面的形式展示</div>
                    </Form.Item>
                    <Form.Item label="选择视频">
                        {getFieldDecorator('video', {
                            valuePropName: 'fileList',
                            getValueFromEvent: this._normFile,
                            rules: [],
                        })(
                            <CustomUpload
                                accept='.mp4'
                                wallMode='single'
                                removeError={true}
                                qiniuUploadProps={{ uploadType: 'image' }}
                            // beforeUpload={(file) => {
                            //     const isLt5M = file.size / 1024 / 1024 > 500;
                            //     if (isLt5M) {
                            //         message.error('视频须小于 500M');
                            //         file.status = 'error';
                            //         return false;
                            //     }
                            // }}
                            >
                                <Button type='primary'>添加上传视频</Button>
                            </CustomUpload>)}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

CreateLive.propTypes = {
    form: PropTypes.object.isRequired,
    params: PropTypes.object,
    close: PropTypes.func,
    closeAndOpenNewMode: PropTypes.func,
};

const CreateLiveForm = Form.create({ name: 'CreateLive' })(CreateLive);
export default CreateLiveForm;