/**
 *  营销活动规则设置 
 * */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, message } from 'antd';
import http from '@/assets/api/http';
import ChooseModal from '../../modal/ChooseModal';
import { XOKButton, XPagination, XTableSelectionCount, showConfirm, } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import { getWalletMerchantList } from '@/reducers/systemmanage/marketingCampaign/actions';

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedRowKeys: [],//表格选择和操作
			currentOpenMode: '', // 当前需要打开的弹窗
			openModeParam: {}, // 当前需要打开的弹窗的入参
			pageSize: 30,//默认
			pageNum: 1,//默认
			isCheck: false
		};
	}

	componentDidMount() {
		const { pageNum, pageSize } = this.state;
		this.props.getWalletMerchantList({ pageNum, pageSize });
	}

	_sendReq = (url, data, winText, tipText) => {
		const { pageNum, pageSize } = this.state;
		showConfirm(tipText, '', () => {
			http(url, data, 'GET').then(() => {
				message.success(winText);
				this.props.getWalletMerchantList({ pageNum, pageSize });
			}).catch((error) => {
				message.warn(error.message);
			});
		});
	}

	// 表格操作功能回调  
	_actionClickHandle = (id, type) => {
		switch (type) {
			case 'START_SERVICE'://启用
				this._sendReq('/merchant/settleManualSetting/updateSettingStatus', { settringId: id, status: '0' }, '启用成功', '您是否要启用该商户');
				break;
			case 'STOP_SERVICE'://禁用
				this._sendReq('/merchant/settleManualSetting/updateSettingStatus', { settringId: id, status: '1' }, '禁用成功', '您是否要禁用改商户');
				break;
			case 'DELETE'://删除
				this._sendReq('/merchant/settleManualSetting/delete/' + id, null, '删除成功', '您是否要删除该商户？');
				break;
			default: return null;
		}

	}

	// 打开弹窗
	_renderMode = () => {
		switch (this.state.currentOpenMode) {
			case 'choose'://启用
				return <ChooseModal title='选择商户' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
			default:
				return null;
		}
	}

	// 关闭弹框
	_closeMode = (update) => {
		const { pageNum, pageSize } = this.state;
		update ? this.props.getWalletMerchantList({ pageNum, pageSize }) : null;
		this.setState({ currentOpenMode: '' });
	}

	//选择商户 
	choosehandle = () => {
		this.setState({
			currentOpenMode: 'choose',
		});
	}

	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log(selectedRows);
			this.setState({ selectedRowKeys });
		}
	};

	// 清空表格复选框
	_cleanAllSelected = () => {
		this.setState({ selectedRowKeys: [] });
	}

	// 页面发生变化
	_paginationChange = (pageSize, pageNum) => {
		const { isCheck } = this.state;
		if (!isCheck) {
			this.props.getWalletMerchantList({ pageNum, pageSize });
		} else {
			this.props.getWalletMerchantList({ pageNum, pageSize });
		}
	}

	render() {
		const { walletMerchantList } = this.props;
		const { selectedRowKeys } = this.state;
		this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
			<div id="autoheight" className={styles.flexBoxContainer}>
				<div className={styles.top_title}>
					<span>商户收到结算权限设置</span>
				</div>
				<div style={{ marginTop: '10px' }}>
					<p>添加到本列表的商户，状态为启用的。在商户钱包的未结算显示【结算】</p>
					<XOKButton onClick={this.choosehandle} style={{ width: '100px', margin: '10px 0 10px 0' }} label='选择商户' />
				</div>
				<div className={styles.tableContainer}>
					<XTableSelectionCount selectedNum={this.state.selectedRowKeys.length} onClean={this._cleanAllSelected} />
					<Table
						rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
						rowKey='accountId'
						columns={takeColumns(this._actionClickHandle)}
						dataSource={takeDataSource(walletMerchantList)}
						pagination={false}
						scroll={{ y: true }}
					/>
					<XPagination
						resultTotal={walletMerchantList.resultTotal}
						pageSize={walletMerchantList.pageSize}
						pageNum={walletMerchantList.pageNum}
						onChange={this._paginationChange} />
				</div>
				{this._renderMode()}
			</div>
		);
	}
}
Main.propTypes = {
	updateContent: PropTypes.func, // 路由跳转
	history: PropTypes.object, // 路由history
	addOneBreadcrumbPath: PropTypes.func,
	keepSecondNavBreadcrumb: PropTypes.func,
	getWalletMerchantList: PropTypes.func,
	walletMerchantList: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		walletMerchantList: state.marketingCampaign.walletMerchantList
	};
};

export default connect(mapStateToProps, { getWalletMerchantList })(Main);