//获取并保存标准商品列表
export const GET_STANDARDPRODUCT_LIST = 'STANDARD/GET_STANDARDPRODUCT_LIST';
export const SET_STANDARDPRODUCT_LIST = 'STANDARD/SET_STANDARDPRODUCT_LIST';
//获取并保存标准商品详情
export const GET_STANDARDPRODUCT_DETAIL = 'STANDARD/GET_STANDARDPRODUCT_DETAIL';
export const SET_STANDARDPRODUCT_DETAIL = 'STANDARD/SET_STANDARDPRODUCT_DETAIL';

//标准商品增删改
export const ADD_STANDARDPRODUCT = 'STANDARD/ADD_STANDARDPRODUCT';
export const DEL_STANDARDPRODUCT = 'STANDARD/DEL_STANDARDPRODUCT';
export const UPDATE_STANDARDPRODUCT = 'STANDARD/UPDATE_STANDARDPRODUCT';