/**
 * [爆品（寄售）券管理]
 * @author: Fu Xiaochun
 * @date: 2023-02-20 
 */

import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Tabs, Icon, Tooltip, message } from 'antd';
import Unconsignments from './unconsignments';
import Consigned from './consigned';
import ConsignedFinish from './consignedFinish';
import http from '@/assets/api/http';


const { TabPane } = Tabs;

function Consignments() {

    const [tabIndex, setTabIndex] = useState(0);
    const consignmentsTitles = [
        '显示当前未关联的爆品券数量',
        '显示当前已被订单关联的爆品券数量',
        '显示当前已完成的爆品券数量'
    ];
    const [consignmentsCount, setConsignmentsCount] = useState();

    useEffect(() => {
        getConsignmentsCount();
    }, []);

    const getConsignmentsCount = () => {
        let params = {};
        http('/marketing/orderConsign/findConsignStats', params, 'POST').then(res => {
            let temp = {
                0: res.result.onSaleNum,
                1: res.result.soldNum,
                2: res.result.completedNum,
                3: res.result.completedSelfNum || 0,
            };
            setConsignmentsCount(temp);
        }).catch(err => {
            message.error(err.message);
        });
    };

    return (
        <div className={styles.flexBoxContainer}>
            <div className={styles.tabBox}>
                <Tabs defaultActiveKey='0' onChange={setTabIndex}>
                    <TabPane tab="在售" key={0} />
                    <TabPane tab="已售" key={1} />
                    <TabPane tab="已完成" key={2} />
                </Tabs>
            </div>
            {consignmentsCount && <>
                <div className={styles.statisticsBox}>
                    <dl className={styles.item}>
                        <dt>爆品券总数 <Tooltip title={consignmentsTitles[tabIndex]}><Icon type="question-circle" style={{ marginLeft: '5px', cursor: 'pointer' }} /></Tooltip></dt>
                        <dd>{consignmentsCount[tabIndex]}</dd>
                    </dl>
                    {
                        tabIndex == 2 ? <dl className={`${styles.item} ${styles.itemSelf}`}>
                            <dt>已自提寄售券总数 <Tooltip title='显示用户将寄售券转自提的寄售券总数量'><Icon type="question-circle" style={{ marginLeft: '5px', cursor: 'pointer' }} /></Tooltip></dt>
                            <dd>{consignmentsCount[3]}</dd>
                        </dl> : null
                    }
                </div>
            </>}
            {tabIndex == 0 && <Unconsignments />}
            {tabIndex == 1 && <Consigned />}
            {tabIndex == 2 && <ConsignedFinish />}
        </div>
    );
}

export default Consignments;