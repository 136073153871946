import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import PositiveAudit from './PositiveAudit';
import CreditAudit from './CreditAudit';
import { getLocalStorage, removeLocalStorage } from '@/assets/js/storage';

const ChannelAudit = ({ history }) => {

    const tabList = [
        { id: 'positiveAudit', label: '渠道转正审核' },
        { id: 'creditAudit', label: '渠道信用额度审核' },
        // { id: 'discountAudit', label: '渠道折扣审核' }
    ];

    const [currentId, setCurrentId] = useState('positiveAudit');

    useEffect(() => {
        if (getLocalStorage('channelauditcurrentId')) {
            setCurrentId(getLocalStorage('channelauditcurrentId'));
            removeLocalStorage('channelauditcurrentId');
        }
    }, []);

    const components = {
        positiveAudit: <PositiveAudit history={history} />,
        creditAudit: <CreditAudit history={history} />,
        // discountAudit: <DiscountAudit />,
    };

    return <div className={styles.flexBoxContainer}>
        <XTabNav renderData={tabList} onChange={tabId => setCurrentId(tabId)} activedID={currentId} />
        <div className={styles.flexBoxContainer} style={{ marginTop: '10px' }}>{components[currentId]}</div>
    </div>;
};
ChannelAudit.propTypes = {
    history: PropTypes.object
};
export default ChannelAudit;