//获取/保存订单列表
export const GET_PRODUCTORDER_LIST = 'ORDERMANAGE/GET_PRODUCTORDER_LIST';
export const SET_PRODUCTORDER_LIST = 'ORDERMANAGE/SET_PRODUCTORDER_LIST';

//获取/保存订单类型
export const GET_PRODUCTORDER_TYPE = 'ORDERMANAGE/GET_PRODUCTORDER_TYPE';
export const SET_PRODUCTORDER_TYPE = 'ORDERMANAGE/SET_PRODUCTORDER_TYPE';

//获取/保存订单状态
export const GET_PRODUCTORDER_STATUS = 'ORDERMANAGE/GET_PRODUCTORDER_STATUS';
export const SET_PRODUCTORDER_STATUS = 'ORDERMANAGE/SET_PRODUCTORDER_STATUS';
// 获取/保存认购订单状态
export const GET_PRODUCTSUBORDER_STATUS = 'ORDERMANAGE/GET_PRODUCTSUBORDER_STATUS';
export const SET_PRODUCTSUBORDER_STATUS = 'ORDERMANAGE/SET_PRODUCTSUBORDER_STATUS';
//获取/保存是否开票
export const GET_PRODUCTORDER_FLAGENUM = 'ORDERMANAGE/GET_PRODUCTORDER_FLAGENUM';
export const SET_PRODUCTORDER_FLAGENUM = 'ORDERMANAGE/SET_PRODUCTORDER_FLAGENUM';

//兑换方式 
export const GET_PRODUCTORDER_CONVERWAYA = 'ORDERMANAGE/GET_PRODUCTORDER_CONVERWAYA';
export const SET_PRODUCTORDER_CONVERWAYA = 'ORDERMANAGE/SET_PRODUCTORDER_CONVERWAYA';
//获取/保存是否批发
export const GET_PRODUCTPIFA_FLAGENUM = 'ORDERMANAGE/GET_PRODUCTPIFA_FLAGENUM';
export const SET_PRODUCTPIFA_FLAGENUM = 'ORDERMANAGE/SET_PRODUCTPIFA_FLAGENUM';

// 支付通道
export const GET_PAYMENTACCESSTYPEENUM = 'ORDERMANAGE/GET_PAYMENTACCESSTYPEENUM';
export const SET_PAYMENTACCESSTYPEENUM = 'ORDERMANAGE/SET_PAYMENTACCESSTYPEENUM';

// 支付方式
export const GET_PAYMENTTYPEENUM = 'ORDERMANAGE/GET_PAYMENTTYPEENUM';
export const SET_PAYMENTTYPEENUM = 'ORDERMANAGE/SET_PAYMENTTYPEENUM';

// 订单来源
export const GET_ORDER_SOURCE_ENUM = 'ORDERMANAGE/GET_ORDER_SOURCE_ENUM';
export const SET_ORDER_SOURCE_ENUM = 'ORDERMANAGE/SET_ORDER_SOURCE_ENUM';