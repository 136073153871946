import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, showConfirm } from '@/components/xqxc_ui';
import { Table, Typography, Icon, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const { Text } = Typography;

const TableContent = ({
    renderData: {
        dataList = [],
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0
    } = {},
    paginationChange,
    request,
    discernTypes = []
}) => {

    const tableOperate = (text, item) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => {
                showConfirm('您确定要解除拦截吗？', '', () => {
                    request('/user/account/removeWoolCheckRecord/' + text, {}, '解除拦截操作成功');
                });
            }}>解除拦截</div>
        </div>;
    };

    const tipNode = <div>
        {
            discernTypes.map(item => {
                return <p key={item.code}>{`${item.code}：${item.value}`}</p>;
            })
        }
    </div>;

    const titleCell = <div>
        <Text style={{ paddingRight: '5px' }}>识别类型</Text>
        <Tooltip placement='right' title={tipNode}>
            <Icon
                style={{
                    fontSize: '16px',
                    cursor: 'pointer',
                    verticalAlign: 'text-top',
                }}
                type='question-circle'
                theme='twoTone'
            />
        </Tooltip>
    </div>;

    const columns = [
        {
            title: '手机号码',
            dataIndex: 'tel',
            key: 'tel',
            align: 'center',
            width: '25%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: titleCell,
            dataIndex: 'result',
            key: 'result',
            align: 'center',
            width: '25%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '拦截时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: '25%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '25%',
            onCell: tooltipStyle,
            render: tableOperate
        }
    ];

    return <div className={styles.tableContainer}>
        <Table
            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={dataList}
            pagination={false}
            scroll={{ y: true }}
        />
        <XPagination
            resultTotal={resultTotal}
            pageSize={pageSize}
            pageNum={pageNum}
            onChange={paginationChange}
        />
    </div>;
};
TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    request: PropTypes.func,
    discernTypes: PropTypes.array
};
export default TableContent;