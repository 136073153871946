import { combineReducers } from 'redux';
import { getLogisticsState, getLogisticsList } from './reducer';
import model from './model';

export default combineReducers({
	getLogisticsState, // 获取物流公司状态
	getLogisticsList, // 获取物流公司列表
	...model.reducers
});

