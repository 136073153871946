import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import TableContent from './components/TableContent';
import model from '@/reducers/auditmanage/advocacytask/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {

    state = {
        spokesmanTaskConfigId: '',//任务单号
        spokesmanTaskName: '',//任务名称
        user: {},//商户
        stateObj: {}, //状态
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getAdvocacyTaskState, getAdvocacyTaskUser } = this.props;
        keepSecondNavBreadcrumb();
        getAdvocacyTaskState();
        getAdvocacyTaskUser();
    }

    static propTypes = {
        history: PropTypes.object,//路由对象
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getAdvocacyTaskList: PropTypes.func,//代言任务审核列表
        advocacyTaskList: PropTypes.object,//代言任务列表列表数据源
        getAdvocacyTaskUser: PropTypes.func,//商户下拉
        advocacyTaskUser: PropTypes.object,//商户下拉数据
        getAdvocacyTaskState: PropTypes.func,//状态下拉
        advocacyTaskState: PropTypes.array//状态下拉数据
    };

    // 查询
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, spokesmanTaskConfigId, spokesmanTaskName, user, stateObj } = this.state;
        this.searchCache = {
            spokesmanTaskConfigId, spokesmanTaskName, merchantId: user.merchantId, auditState: stateObj.key,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAdvocacyTaskList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ spokesmanTaskConfigId: '', spokesmanTaskName: '', user: {}, stateObj: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //表格操作
    _tableOperate = (id, type, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '代言任务审核详情',
                    path: '/home/auditmanage/advocacytask/detail'
                });
                history.push({
                    pathname: '/home/auditmanage/advocacytask/detail/' + id,
                });
                break;
            case 'audit':
                addOneBreadcrumbPath({
                    title: '代言任务审核操作',
                    path: '/home/auditmanage/advocacytask/audit'
                });
                history.push({
                    pathname: '/home/auditmanage/advocacytask/audit/' + id,
                });
                break;
            default:
                break;
        }
    };

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { spokesmanTaskConfigId, spokesmanTaskName, user, stateObj } = this.state;
        const { advocacyTaskList, advocacyTaskUser, advocacyTaskState } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='任务单号'
                                placeholder='请输入任务单号'
                                value={spokesmanTaskConfigId}
                                bindThis={this}
                                bindName='spokesmanTaskConfigId'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='任务名称'
                                placeholder='请输入任务名称'
                                value={spokesmanTaskName}
                                bindThis={this}
                                bindName='spokesmanTaskName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户名称'
                                placeholder='请选择'
                                renderData={advocacyTaskUser.merchantIdAndNames}
                                bindThis={this}
                                bindName='user'
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                value={user.merchantName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={advocacyTaskState}
                                bindThis={this}
                                bindName='stateObj'
                                dataIndex='value'
                                keyIndex='key'
                                value={stateObj.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent
                        paginationChange={this._paginationChange}
                        tableOperate={this._tableOperate}
                        tableData={advocacyTaskList}
                    />
                </div>
            </KeepAlive>
        );
    }
}

//映射数据源
const mapStateToProps = (state) => ({
    advocacyTaskList: state.advocacyTaskAudit.advocacyTaskList,
    advocacyTaskUser: state.advocacyTaskAudit.advocacyTaskUser,
    advocacyTaskState: state.advocacyTaskAudit.advocacyTaskState
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);