import * as ACCOUNT from './actionTypes';


const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
export const accountList = (state = initData, action) => {
    switch (action.type) {
        case ACCOUNT.SET_ACCOUNT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const serviceList = (state = initData, action) => {
    switch (action.type) {
        case ACCOUNT.SET_SERVICE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const merchantList = (state = initData, action) => {
    switch (action.type) {
        case ACCOUNT.SET_MERCHANT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const regionalCompanyList = (state = initData, action) => {
    switch (action.type) {
        case ACCOUNT.SET_COMPANY_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const operationCenterList = (state = initData, action) => {
    switch (action.type) {
        case ACCOUNT.SET_OPERATIONCENTER_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const platFormList = (state = initData, action) => {
    switch (action.type) {
        case ACCOUNT.SET_PLATFORM_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const costDetaiList = (state = initData, action) => {
    switch (action.type) {
        case ACCOUNT.SET_COSTDETAIL_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const costDetaiLists = (state = initData, action) => {
    switch (action.type) {
        case ACCOUNT.SET_COSTDETAIL_LISTS:
            return action.payload.result;
        default:
            return state;
    }
};
export const orderCostDetaiList = (state = initData, action) => {
    switch (action.type) {
        case ACCOUNT.SET_ORDERCOSTDETAIL_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const serviceOrderList = (state = initData, action) => {
    switch (action.type) {
        case ACCOUNT.SET_SERVICEORDER_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const costNameList = (state = initData, action) => {
    switch (action.type) {
        case ACCOUNT.SET_COSTNAME_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const serviceManageFeeList = (state = initData, action) => {
    switch (action.type) {
        case ACCOUNT.SET_SERVICEMANAGEFEE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const activeCompanyState = (state = [], action) => {
    switch (action.type) {
        case ACCOUNT.SET_COMPANY_ACTIVE:
            return action.payload.result;
        default:
            return state;
    }
};