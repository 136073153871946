/**
 * 滚动下拉列
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message, Select } from 'antd';
import styles from './index.module.scss';

const Option = Select.Option;
class ScrollSelect extends React.Component {

    state = {
        renderData: [], // 数据源
        pageNum: 1, // 默认第1页
        pageSize: 50, // 默认每页100条
        loading: false, // 加载中
    }

    componentDidUpdate() {
        const { mainKey, param } = this.props;
        let updateBoolean = false;
        for (let i in param) {
            if (this.state[i] != param[i]) {
                updateBoolean = true;
                break;
            }
        }
        if (updateBoolean) {
            this.setState({ ...param, [mainKey]: '', pageNum: 1, renderData: [] }, () => {
                this._getHttpList('SearchType');
            });
        }
    }

    componentDidMount() {
        const { mainKey, param } = this.props;
        this.setState({ ...param, [mainKey]: '', pageNum: 1, renderData: [] }, () => {
            this._getHttpList('SearchType');
        });
    }

    _getHttpList = (type) => {
        const { renderData } = this.state;
        const { listLabel } = this.props;

        // 如果是搜索状态，只搜索第一条
        let pageNum = this.state.pageNum;
        if (type == 'SearchType') {
            pageNum = 1;
        }
        const query = { ...this.props.reqParams, ...this.state, pageNum };
        this.setState({ loading: true }, () => {
            http(this.props.url, query, 'POST').then((response) => {
                if (response.status == 200) {
                    if (response.result[listLabel] && response.result[listLabel].length == 0) {
                        this.setState({ loading: true }); // 当已经没有数据，则不再下拉加载
                    } else {
                        this.setState({ loading: false });
                    }
                    const list = this.unique([...renderData, ...response.result[listLabel]]);
                    this.setState({ renderData: list });
                }
            }).catch((e) => {
                message.error(e.message);
                this.setState({ loading: false });
            });
        });
    }

    // 数组去重
    unique = (list) => {
        const { selectID } = this.props;
        let result = {};
        let finalResult = [];
        for (let i = 0; i < list.length; i++) {
            result[list[i][selectID]] = list[i];
        }
        for (let item in result) {
            finalResult.push(result[item]);
        }
        return finalResult;
    }

    _handleScroll = (e) => {
        e.persist();
        const { target } = e;
        // scrollHeight：代表包括当前不可见部分的元素的高度
        // scrollTop：代表当有滚动条时滚动条向下滚动的距离，也就是元素顶部被遮住的高度
        // clientHeight：包括padding但不包括border、水平滚动条、margin的元素的高度
        const rmHeight = target.scrollHeight - target.scrollTop;
        const clHeight = target.clientHeight;
        // 当下拉框失焦的时候，也就是不下拉的时候

        // 当下拉框下拉并且滚动条到达底部的时候
        // 可以看成是分页，当滚动到底部的时候就翻到下一页
        const { loading, pageNum } = this.state;
        if (rmHeight < clHeight + 5 && !loading) {
            this.setState({ pageNum: pageNum + 1 }, () => {
                this._getHttpList();
            });
        }
    }

    _onSearch = (value) => {
        const { mainKey } = this.props;
        const object = {};
        object[mainKey] = value;
        this.setState({ ...this.state, ...object }, () => {
            this._getHttpList('SearchType');
        });
    }

    render() {
        const { renderData } = this.state;
        const { value, mainKey, selectID, selectName, ...otherProps } = this.props;

        let name = undefined;
        let haveName = false;
        for (let i = 0; i < renderData.length; i++) {
            if (renderData[i][mainKey] == value[mainKey]) {
                name = renderData[i][selectName];
                haveName = true;
                break;
            }
        }
        if (!name && !haveName && value[selectName]) {
            name = value[selectName];
        }
        return (
            <Select
                {...otherProps}
                value={name}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onPopupScroll={this._handleScroll}
                onSearch={this._onSearch}
            >
                {renderData && renderData.map((item) => {
                    return <Option key={item[selectID]} value={item[selectName]} data-option={item}>{item[selectName]}</Option>;
                })}
            </Select>
        );
    }
}
ScrollSelect.defaultProps = {
    listLabel: 'dataList'
};
ScrollSelect.propTypes = {
    reqParams: PropTypes.object,
    url: PropTypes.string.isRequired,  // 请求地址
    selectID: PropTypes.string.isRequired, // 选项ID
    selectName: PropTypes.string.isRequired, // 选项名称
    mainKey: PropTypes.string.isRequired, // 关键字
    value: PropTypes.object, // 双向绑定 { selectID:'', selectName:''}
    param: PropTypes.object, // 其它入参
    onChange: PropTypes.func, // 选择事件监听
    listLabel: PropTypes.string
};

export default ScrollSelect;