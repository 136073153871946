import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

const TableContent = ({ renderData, paginationChange }) => {

    const columns = () => ([
        {
            title: '订单号',
            dataIndex: 'orderId',
            key: 'orderId',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '服务商名称',
            dataIndex: 'realName',
            key: 'realName ',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '账号',
            dataIndex: 'mobilePhone',
            key: 'mobilePhone ',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '身份证号码',
            dataIndex: 'idCard',
            key: 'idCard',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '金额',
            dataIndex: 'totalFee',
            key: 'totalFee',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '协议签署时间',
            dataIndex: 'signTm',
            key: 'signTm',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '账号审核通过日期',
            dataIndex: 'passTm',
            key: 'passTm',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        //     title: '协议编号',
        //     dataIndex: 'contractNumber',
        //     key: 'contractNumber',
        //     align: 'center',
        //     width: '10%',
        //     onCell: tooltipStyle,
        //     render: tooltip
        // }
    ]);

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.id}
                columns={columns()}
                dataSource={renderData.dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;