/**
 * 登录页
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import BackGround from './components/BackGround';
import LoginBox from './components/LoginBox';

class Login extends React.Component {
    render(){
        return(
            <div className={styles.container}>
                <BackGround />
                <LoginBox history={this.props.history}/>
            </div>
        );
    }
}

Login.propTypes = {
    history: PropTypes.object
};

export default Login;