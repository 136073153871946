import { createModel } from '@/store/tools';

const model = {
    namespace: 'goodstotalcase',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getSalesNumList': T('getSalesNumList'),
            'getSalesAmountList': T('getSalesAmountList'),
            'getShowSalesList': T('getShowSalesList'),
            'getMarketingSalesList': T('getMarketingSalesList'),
            'getStatisticsBrands': T('getStatisticsBrands'),
            'getStatisticsMerchants': T('getStatisticsMerchants')
        },
        actions: {
            'getSalesNumList': A('getSalesNumList'),
            'getSalesAmountList': A('getSalesAmountList'),
            'getShowSalesList': A('getShowSalesList'),
            'getMarketingSalesList': A('getMarketingSalesList'),
            'getStatisticsBrands': A('getStatisticsBrands'),
            'getStatisticsMerchants': A('getStatisticsMerchants')
        },
        sagas: {
            'getSalesNumList': S('getSalesNumList', '/report/goodsSaleAmountReport'),
            'getSalesAmountList': S('getSalesAmountList', '/report/goodsSaleMoneyReport'),
            'getShowSalesList': S('getShowSalesList', '/report/goodsSaleMoneyWithShowReport'),
            'getMarketingSalesList': S('getMarketingSalesList', '/report/goodsSaleMoneyWithMarketingReport'),
            'getStatisticsBrands': S('getStatisticsBrands', '/goods/brand/listBrandItem'),
            'getStatisticsMerchants': S('getStatisticsMerchants', '/merchant/info/listMerchantIdAndName')
        },
        reducers: {
            'salesNumList': R('getSalesNumList', []),
            'salesAmountList': R('getSalesAmountList', []),
            'showSalesList': R('getShowSalesList', []),
            'marketingSalesList': R('getMarketingSalesList', []),
            'statisticsBrands': R('getStatisticsBrands', []),
            'statisticsMerchants': R('getStatisticsMerchants', [{ merchantIdAndNames: [] }])
        }
    })
};
export default createModel(model);