import * as A from './actiontypes';

const initData = {
        dataList: [],
        pageNum: 1,
        pageSize: 10,
        resultTotal: 0
};
export const allocationList = (state = initData, action) => {
    switch (action.type) {
        case A.SET_ALLOCATION_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const allocationDetail = (state = {}, action) => {
    switch (action.type) {
        case A.SET_ALLOCATION_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};
export const settingCode = (state = {}, action) => {
    switch (action.type) {
        case A.SET_SETTING_CODE:
            return action.payload.result;
        default:
            return state;
    }
};
