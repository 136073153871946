export const GET_STORELIST_DATA = 'STORELIST/GET_STORELIST_DATA'; // 异步获取'店铺列表表格'数据源
export const SET_STORELIST_DATA = 'STORELIST/SET_STORELIST_DATA'; // 将'店铺列表表格'存储至store.state中

export const GET_STOSELECT_DATA = 'STORELIST/GET_STOSELECT_DATA'; // 异步获取'店铺列表封禁状态'数据源
export const SET_STOSELECT_DATA = 'STORELIST/SET_STOSELECT_DATA'; // 将'封禁状态'存储至store.state中
export const SET_RADUIS_DATA = 'STORELIST/SET_RADUIS_DATA'; // 封禁----封禁理由 单选框
export const SET_DETAILTYPE_DATA = 'STORELIST/SET_DETAILTYPE_DATA'; // 详情----店铺类型


export const GET_INFOMATION_DATA = 'STORELIST/GET_INFOMATION_DATA'; // 封禁信息
export const SET_INFOMATION_DATA = 'STORELIST/SET_INFOMATION_DATA'; // 封禁信息

export const GET_STOREDETAILS_DATA = 'STORELIST/GET_STOREDETAILS_DATA'; // 店铺详情
export const SET_STOREDETAILS_DATA = 'STORELIST/SET_STOREDETAILS_DATA'; // 店铺详情

export const GET_STORETAKELOG_DATA = 'STORELIST/GET_STORETAKELOG_DATA'; // 店铺封禁日志
export const SET_STORETAKELOG_DATA = 'STORELIST/SET_STORETAKELOG_DATA'; // 店铺封禁日志

export const GET_RECOMMENDATION_DATA = 'STORELIST/GET_RECOMMENDATION_DATA'; // 店铺推荐列表
export const SET_RECOMMENDATION_DATA = 'STORELIST/SET_RECOMMENDATION_DATA'; // 店铺推荐列表



