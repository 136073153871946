import React from 'react';
import { Modal, DatePicker, message } from 'antd';
import PropTypes from 'prop-types';
import { XTextArea, XScrollSelect } from '@/components/xqxc_ui';
// import CompanyList from '@/vcomps/CompanyList';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { formValids } from '@/assets/js/utils';
import http from '@/assets/api/http';
import styles from './GenerateStatement.module.scss';

class AcceptModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,

        remark: '',
        merchant: {}
    }

    _handleOk = () => {

        const { merchant, startDate, endDate, remark } = this.state;
        const saveProps = {
            targetId: merchant.id,
            targetName: merchant.companyName,
            targetType: 'REGION_COMPANY',
            startDate: startDate ? startDate.format('YYYY-MM-DD 00:00:00') : undefined,
            endDate: endDate ? endDate.format('YYYY-MM-DD 23:59:59') : undefined,
            remark
        };

        if (formValids(saveProps, {
            targetId: '请选择区域公司',
            startDate: '请选择对账周期的时间范围',
            endDate: '请选择对账周期的时间范围'
        })) {
            this.setState({ confirmLoading: true });
            http('/settle/reconciliationInfo/addReconciliation', saveProps).then((res) => {
                this.setState({ visible: false, confirmLoading: false });
                this.props.closeMode();
                this.props.refsh();
            }).catch((res) => {
                this.setState({ confirmLoading: false });
                message.error(res.message);
            });
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }

    _areaChangeHandle = (e) => {
        this.setState({ areaValue: e.target.value });
    }

    render() {
        const { visible, confirmLoading, title, remark, merchant } = this.state;

        return (
            <Modal
                width={520}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.top}>
                        <span>区域公司：</span>
                        <XScrollSelect
                            style={{ width: 380, height: 30, marginLeft: 10 }}
                            url='/admin/company/listAreaCompanyName'
                            placeholder='请选择'
                            showSearch
                            mainKey='companyName'
                            selectID='id'
                            selectName='companyName'
                            value={merchant}
                            listLabel='result'
                            onChange={(value, item) => this.setState({ merchant: item.props['data-option'] })}
                        />
                    </div>
                    <div className={styles.top}>
                        <span>对账周期：</span>
                        <DatePicker.RangePicker locale={locale} style={{ width: '380px', marginLeft: 10 }} format='YYYY-MM-DD'
                            onChange={(e) => {
                                if (e.length) this.setState({ startDate: e[0], endDate: e[1] });
                                else this.setState({ startDate: undefined, endDate: undefined });
                            }}
                        />
                    </div>
                    <div className={styles.top}>
                        <span>备注：</span>
                        <XTextArea
                            style={{ width: '100%' }}
                            inputStyle={{ width: '380px' }}
                            value={remark}
                            bindThis={this}
                            bindName='remark'
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

AcceptModal.propTypes = {
    refsh: PropTypes.func,
    merchantList: PropTypes.array,
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string // 弹框标题
};

export default AcceptModal;