import { combineReducers } from 'redux';
import { starOrderList, payWaysList, riskTypeList, failStarOrderList, handleStatusList, riskUserList, riskLevelList, deviceTypeList } from './reducer';

export default combineReducers({
    starOrderList,
    payWaysList,
    riskTypeList,
    failStarOrderList,
    handleStatusList,
    riskUserList,
    riskLevelList,
    deviceTypeList
});