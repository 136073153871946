import * as T from './actiontypes';

export const getInvoiceList = (value) => ({
    type: T.GET_INVOICELIST,
    payload: value
});

export const getInvoiceManageTableSource = (value) => ({
    type: T.GET_INVOICEMANAGE_TABLESOURCE,
    payload: value
});

export const getInvoiceManageUnitSelectSource = (value) => ({
    type: T.GET_INVOICEMANAGE_UNIT_SELECTSOURCE,
    payload: value
});

