import React, { Component } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './PayTable.module.scss';
import { XPagination, XOKButton } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

export default class PayTable extends Component {

    _columns = () => [
        {
            title: '服务编号',
            dataIndex: 'serviceNo',
            key: 'serviceNo',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '服务名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付通道',
            dataIndex: 'accessType',
            key: 'accessType',
            width: '12%',
            align: 'center',
            render: (text, item) => this.switchCell(text, item)
        },
        {
            title: '最后操作时间',
            dataIndex: 'gmtModify',
            key: 'gmtModify',
            width: '12%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
    ];

    // 通道切换
    switchCell = (text, item) => {
        return (
            <div className={styles.switchCell}>
                <div className={styles.text}></div>
                <XOKButton
                    type="dashed"
                    style={{ width: 'auto' }}
                    label='切换'
                    onClick={() => this.props.tableAction()}
                />
            </div>
        );
    };

    //过滤支付通道
    paywaylFilter = (show) => {
        // const { paywayList } = this.props;
        // if (paywayList.length === 0) {
        //     return tooltip('');
        // }
        // let data = paywayList.filter(v => v.code == show);
        // return data[0] ? tooltip(data[0].value) : tooltip('');
    }


    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.merchantId}
                    columns={this._columns()}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

PayTable.propTypes = {
    renderData: PropTypes.object, // 表格数据源
    tableAction: PropTypes.func, // 表格
    paginationChange: PropTypes.func, // 分页
};