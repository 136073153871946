import React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import styles from './TableData.module.scss';

const columns = (actionClickHandle) => [
  {
    title: '社区分类',
    dataIndex: 'feedClassName',
    key: 'feedClassName',
    width: 100,
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '主题名称',
    dataIndex: 'feedTitle',
    key: 'feedTitle ',
    width: 100,
    align: 'center',
    // eslint-disable-next-line react/display-name
    render: (text) => {
      return <Ellipsis tooltip={text} lines={1}>{text}</Ellipsis>;
    }
  },
  {
    title: '评论人',
    dataIndex: 'watchAccountName',
    key: 'watchAccountName',
    width: 100,
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '评论内容',
    dataIndex: 'content',
    key: 'content',
    width: 220,
    align: 'center',
    // onCell: () => {
    //     return {
    //       style: {
    //         maxWidth: 0,
    //         overflow: 'hidden',
    //         whiteSpace: 'nowrap',
    //         textOverflow:'ellipsis',
    //         cursor:'pointer'
    //       }
    //     };
    //   },
    // eslint-disable-next-line react/display-name
    render: (text) => {
      return <Ellipsis tooltip={text} lines={1}>{text}</Ellipsis>;
    }
  },
  {
    title: '评论时间',
    dataIndex: 'gmtCreate',
    key: 'gmtCreate',
    width: 120,
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '操作',
    dataIndex: 'communityaction',
    key: 'communityaction',
    align: 'center',
    width: 100,
    render: (id)=> TableAction(id, actionClickHandle)
  },
];
 
export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    let newTableSource;
    newTableSource = data.map(item => ({
          feedClassName: item.feedClassName,
          feedTitle: item.feedTitle,
          watchAccountName: item.nickname,
          content: item.content,
          gmtCreate: item.gmtCreate,
          communityaction: item.id
      }));
    return newTableSource;
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};

const TableCell = (text) => {
  return(
    <span style={{wordWrap:'break-word',wordBreak:'break-all'}}>
      {text}
    </span>
  );
};

const TableAction = (id, actionClickHandle) => {
  return(
    <div className={styles.action}>
        <div className={styles.item} onClick={()=>actionClickHandle(id, 'DETAILS_INFO')}>查看</div>
    </div>
  );
};