/**
 * [订单明细]
 * @author: Fu Xiaochun
 * @date: 2023-05-19 
 */

import React, { useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, message, Modal, Button } from 'antd';
import { XInput, XOKButton, XCancelButton, XSelect, XRangePicker } from '@/components/xqxc_ui';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import TableData from './components/TableData';
import http from '@/assets/api/http';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

function ServiceProvider(props){

    const history = useHistory();

    const today = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    const [result, setResult] = useState({dataList:[]});
    const [aliveInit, setAliveInit] = useState(false);

    const [orderId, setOrderId] = useState();  // 订单编号
    const [member, setMember] = useState();  // 会员账号
    const [recommend, setRecommend] = useState();  // 推荐人账号
    const [service, setService] = useState();  // 所属服务商账号
    const [companyName, setCompanyName] = useState({});  // 所属区域公司
    const [time, setTime] = useState(today);  // 时间
    const [status, setStatus] = useState({});  // 订单状态

    const [companyNameEnum, setCompanyNameEnum] = useState([]);  // 区域公司列表
    const [statusEnum, setStatusEnum] = useState([]);  // 订单状态枚举

    const [resetCode, setResetCode] = useState(0);
    const page = useRef({ pageNum: autoPageNum, pageSize: autopageSize });

    useEffect(() => {
        props.keepSecondNavBreadcrumb();
        getAreaCompanyEnum();
        getOrderStatusEnum();
    }, []);

    useEffect(() => {
        aliveInit && onSearch();
    }, [aliveInit]);

    // 重置后请求
    useEffect(() => {
        if (resetCode > 0) {
            onSearch();
        }
    }, [resetCode]);

    const getAreaCompanyEnum = ()=>{
        http('/admin/company/listAreaCompanyIdAndName').then(res=>{
            setCompanyNameEnum(res.result.result);
        }).catch(err=>{
            message.error(err.message);
        });
    };
    
    const getOrderStatusEnum = ()=>{
        http('/enum/OrderStatusEnum').then(res=>{
            setStatusEnum(res.result);
        }).catch(err=>{
            message.error(err.message);
        });
    };

    // 查询
    const onSearch = (usecache) => {
        if (usecache !== 'usecache') {
            page.current = { pageNum: autoPageNum, pageSize: autopageSize };
        }
        let { pageNum, pageSize } = page.current;
        let searchCache = {
            orderId: orderId,
            tel: member,
            sponsorTel: recommend,
            mobilePhone: service,
            areaCompanyId: companyName.id,
            payStartTime: time[0] ? time[0] + ' 00:00:00' : undefined,
            payEndTime: time[1] ? time[1] + ' 23:59:59' : undefined,
            orderStatus: status.code,
            pageNum,
            pageSize,
        };

        http('/statistics/order/orderItem', searchCache).then(res=>{
            setResult(res.result);
        }).catch(err=>{
            message.error(err.message);
        });
    };

    // 重置
    const onReset = () => {
        setOrderId();
        setMember();
        setRecommend();
        setService();
        setCompanyName({});
        setStatus({});
        setTime(today);
        setResetCode(resetCode + 1);
        page.current = { pageNum: autoPageNum, pageSize: autopageSize };
    };

    const onPaginationChange = (size, num) => {
        page.current = { pageSize: size, pageNum: num };
        onSearch('usecache');
    };

    const _tableAction = (val, type, data) => {
        switch (type) {
            default:
            // nothing to do
        }
    };

    return (
        <KeepAlive render={(state) => {
            if (!state.aliveInit) {
                setAliveInit(true);
            }
        }}>
            <div className={styles.flexBoxContainer}>
                <SearchBox>
                    <SearchItem>
                        <XInput
                            style={{marginRight: '30px'}}
                            label='订单编号'
                            placeholder='输入订单编号'
                            bindName='orderId'
                            value={orderId}
                            bindThis={setOrderId}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            style={{marginRight: '30px'}}
                            label='会员账号'
                            placeholder='输入会员账号'
                            bindName='member'
                            value={member}
                            bindThis={setMember}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            style={{marginRight: '30px'}}
                            label='推荐人账号'
                            placeholder='输入推荐人账号'
                            bindName='recommend'
                            value={recommend}
                            bindThis={setRecommend}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            style={{marginRight: '30px'}}
                            label='所属服务商账号'
                            placeholder='输入所属服务商账号'
                            bindName='service'
                            value={service}
                            bindThis={setService}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            style={{marginRight: '30px'}}
                            selectStyle={{width: '150px'}}
                            label='所属区域公司'
                            placeholder='请选择'
                            renderData={companyNameEnum}
                            dataIndex='companyName'
                            keyIndex='id'
                            value={companyName.companyName}
                            bindName='companyName'
                            bindThis={setCompanyName}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XRangePicker
                            style={{marginRight: '30px'}}
                            inputStyle={{width: '230px'}}
                            label='支付时间'
                            bindThis={setTime}
                            bindName='time'
                            value={time}
                            format="YYYY-MM-DD"
                            allowClear={false}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            style={{marginRight: '30px'}}
                            selectStyle={{width: '150px'}}
                            label='订单状态'
                            placeholder='请选择'
                            renderData={statusEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={status.value}
                            bindThis={setStatus}
                            bindName="status"
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            style={{marginRight: '20px'}}
                            onClick={onSearch}
                        />
                        <XCancelButton
                            label='重置'
                            style={{marginRight: '30px'}}
                            onClick={onReset}
                        />
                    </SearchItem>
                </SearchBox>
                <div className={styles.tableContainer} style={{paddingTop: '0'}}>
                    <TableData renderData={result} paginationChange={onPaginationChange} tableAction={_tableAction} />
                </div>
            </div>
        </KeepAlive>
    );
}

ServiceProvider.propTypes = {
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb})(ServiceProvider);