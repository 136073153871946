import * as Company from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

const initDetail = {
    companyInfo: {
        companyState: {

        }
    },
    financialInfo: {

    },
    attachList: [],
    auditInfos: []
};

export const companyAuditList = (state = initData, action) => {
    switch (action.type) {
        case Company.SET_COMPANYAUDIT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

export const companyAuditStatus = (state = [], action) => {
    switch (action.type) {
        case Company.SET_COMPANYAUDIT_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};

export const companyAuditDetail = (state = initDetail, action) => {
    switch (action.type) {
        case Company.SET_COMPANYAUDIT_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};