/**
 * 带标题的输入框组件
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Input } from 'antd';

class InputModule extends React.Component {
    state = {
        textNum: 0,
        isInit: true,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isInit } = this.state;
        if (nextProps.textLength && isInit) {
            this.setState({ textNum: nextProps.textLength, isInit: false });
        }
    }

    _onChange = (e) => {
        const { bindThis, bindName, onChange, maxLength, mode } = this.props;

        if (mode) {
            let reg;
            mode === 'number' && (reg = /^-?[0-9]*([0-9]*)?$/);
            mode === 'digit' && (reg = /^-?[0-9]*(\.[0-9]*)?$/);
            let value = e.target.value;
            if (!((!isNaN(value) && reg && reg.test(value)) || value === '' || value === '-')) {
                return;
            }
        }

        if (onChange) {
            onChange(e.target.value);
        }
        if (bindThis && bindName) {
            if (typeof bindThis === 'function') {
                if (e.target.value.length > maxLength) {
                    bindThis(e.target.defaultValue);
                    this.setState({ textNum: e.target.defaultValue.length });
                } else {
                    this.setState({ textNum: e.target.value.length });
                    bindThis(e.target.value);
                }
            } else {
                if (e.target.value.length > maxLength) {
                    bindThis.setState({ [bindName]: e.target.defaultValue });
                    this.setState({ textNum: e.target.defaultValue.length });
                } else {
                    this.setState({ textNum: e.target.value.length });
                    bindThis.setState({ [bindName]: e.target.value });
                }
            }
        }
    }

    render() {
        const { label, style, inputStyle, labelStyle, isRequired, bindThis, bindName, showLength, ...otherProps } = this.props;
        const { textNum } = this.state;
        return (
            <div style={style} className={styles.container}>
                <div className={styles.label} style={labelStyle}><span className={styles.star} style={isRequired ? { display: 'inline' } : { display: 'none' }}>*</span>{label}</div>
                <div className={styles.inputbox}>
                    <Input
                        {...otherProps}
                        style={inputStyle}
                        className={styles.input}
                        onChange={this._onChange}
                    />
                </div>
                {showLength && this.props.maxLength ? <span style={{ marginLeft: 2 }}>（{textNum}/{this.props.maxLength}）</span> : null}
            </div>
        );
    }
}

InputModule.defaultProps = {
    isRequired: false,
    showLength: true,
    style: { marginRight: '20px' },
    inputStyle: { width: '150px' }
};

InputModule.propTypes = {
    label: PropTypes.string, // 标题名称
    labelStyle: PropTypes.object, // 标题容器样式
    style: PropTypes.object, // 组件样式
    inputStyle: PropTypes.object, // 输入框样式
    isRequired: PropTypes.bool, // 当为true时样式添加红星
    bindThis: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ]), // 父级this
    bindName: PropTypes.string, // 双向绑定的属性名称
    onChange: PropTypes.func, // 输入框内容变化时的回调函数
    maxLength: PropTypes.number, // 输入的最大长度
    textLength: PropTypes.number,
    showLength: PropTypes.bool,
    mode: PropTypes.string,
};

export default InputModule;