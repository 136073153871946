import React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import styles from './TableData.module.scss';

/* eslint-disable react/display-name */


const columns = (actionClickHandle) => [
  {
    title: '商品分类',
    dataIndex: 'goodscation',
    key: 'goodscation',
    width: 100,
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '商品名称',
    dataIndex: 'goodsaccount',
    key: 'goodsaccount ',
    width: 100,
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '内容评论',
    dataIndex: 'goodscomments',
    key: 'goodscomments',
    width: 220,
    align: 'center',
    render: (text) => {
      return  <Ellipsis tooltip={text} lines={1}>{text}</Ellipsis>;
  }
  },
  {
    title: '关联订单号',
    dataIndex: 'goodsorder ',
    key: 'goodsorder',
    width: 120,
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '评论人',
    dataIndex: 'goodscritics',
    key: 'goodscritics',
    width: 100,
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '评论时间',
    dataIndex: 'goodstime',
    key: 'goodstime',
    width: 120,
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    align: 'center',
    width: 100,
    render: (text, item)=> TableAction(item, actionClickHandle)
  },
];
 
export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    return data;
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};

const TableCell = (text) => {
  return(
    <div style={{wordWrap:'break-word',wordBreak:'break-all'}}>
      {text}
    </div>
  );
};

const TableAction = (item, actionClickHandle) => {
  return(
    <div className={styles.action}>
        <div className={styles.item} onClick={()=>actionClickHandle(item, 'DETAILS_INFO')}>查看</div>
    </div>
  );
};