/**
 * 新建白名单列表组件
 */
import React from 'react';
import { Checkbox, Table } from 'antd';
import { XTableSelectionCount, XSmallPagination } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './PayLists.module.scss';
import { connect } from 'react-redux';
import model from '@/reducers/systemmanage/showset/model';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const CheckboxGroup = Checkbox.Group;

class PayLists extends React.PureComponent {

    state = {
        selectedRowKeys: [],//对应的key数据
        selectedRows: [],//对应的key行数据
        checkedList: [],//全选操作
        indeterminate: true,
        checkAll: false,
    }

    _columns = () => [
        {
            title: '会员账号',
            dataIndex: 'accountName',
            key: 'accountName',
            width: '30%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员昵称',
            dataIndex: 'nickName',
            key: 'nickName',
            width: '30%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否是主播',
            dataIndex: 'anchorName',
            key: 'anchorName',
            width: '30%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    // 解析表格数据，使符合columns格式要求
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                item.anchorName = item.isAnchor == '1' ? '是' : '否';
                return item;
            });
        }
    }

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [], selectedRows: [] }, () => {
            this.props.selectedRowsHandle(this.state.selectedRowKeys);
        });
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows }, () => {
            this.props.selectedRowsHandle(selectedRowKeys);
        });
    };

    //全选操作
    onRaiseChange = list => {
        const { levelList, } = this.props;
        this.setState({
            checkedList: list,
            indeterminate: !!list.length && list.length < this._checkboxFuture(levelList).length,
            checkAll: list.length === this._checkboxFuture(levelList).length,
        }, () => {
            if (list.length > 0) this.props.checkboxes(list);
        });
    };
    onCheckAllChange = e => {
        const { levelList, } = this.props;
        this.setState({
            checkedList: e.target.checked ? this._checkboxFuture(levelList) : [],
            indeterminate: false,
            checkAll: e.target.checked,
        }, () => {
            if (e.target.checked) this.props.checkboxes(this._checkboxFuture(levelList));
        });
    };
    //全选回调数据处理
    _checkboxFuture = (data = []) => {
        let datadeal = [];
        for (let i = 0; i < data.length; i++) {
            datadeal.push(data[i].id.toString());
        }
        return datadeal;
    }

    componentDidUpdate(prevProps) {
        if (this.props.cleanselected !== prevProps.cleanselected) {
            this._cleanAllSelected();//左右提交请求后清除选中selectedkey
        }
    }

    render() {
        const { checkedList, checkAll, indeterminate, } = this.state;
        const { paginationChange, levelList, loading } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return (
            <div className={styles.container}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <div className={`${styles.layout}`}>
                    <div className={styles.left}>
                        <Checkbox
                            className={styles.allcheck}
                            indeterminate={indeterminate}
                            onChange={this.onCheckAllChange}
                            checked={checkAll}
                        >全部会员</Checkbox>
                        <CheckboxGroup
                            className={styles.levelCheck}
                            value={checkedList}
                            onChange={this.onRaiseChange}
                        >
                            {levelList.map((item) => {
                                return (<Checkbox key={item.id} value={item.id.toString()}>{item.levelName}</Checkbox>);
                            })}
                        </CheckboxGroup>
                    </div>
                    <div className={styles.right}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record) => record.id}
                            columns={this._columns()}
                            dataSource={this._takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ y: 375 }}
                            rowSelection={rowSelection}
                            loading={loading}
                        />
                        <XSmallPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={paginationChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

PayLists.propTypes = {
    loading: PropTypes.bool,//loading效果
    renderData: PropTypes.object, // 表格数据源
    tableAction: PropTypes.func, // 表格
    paginationChange: PropTypes.func, // 分页
    levelList: PropTypes.array,//会员等级
    checkboxes: PropTypes.func,//会员等级全选/单选数据
    selectedRowsHandle: PropTypes.func,//选中的数据
    cleanselected: PropTypes.bool,//清除选中selectedkey
};


export default connect(null, { ...model.actions })(PayLists);