/**
 * 活动商品审核
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Audit from './actionTypes';

//审核列表
export const getActiveProductAuditList = function* () {
    yield takeEvery(Audit.GET_ACTIVEPRODUCTAUDIT_LIST, function* requestData(action) {
        try {
            let result = yield http('/goodsAudit/listGoodsAudit', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_ACTIVEPRODUCTAUDIT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//状态
export const getActiveProductAuditStatus = function* () {
    yield takeEvery(Audit.GET_ACTIVEPRODUCTAUDIT_STATUS, function* requestData(action) {
        try {
            let result = yield http('/marketing/activityInfo/selectMerchantActivityType', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_ACTIVEPRODUCTAUDIT_STATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//详情
export const geActiveProductAuditDetail = function* () {
    yield takeEvery(Audit.GET_ACTIVEPRODUCTAUDIT_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/goodsAudit/activityGoodsAuditDetail', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_ACTIVEPRODUCTAUDIT_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//信息
export const geActiveProductAuditInfo = function* () {
    yield takeEvery(Audit.GET_ACTIVEPRODUCTAUDIT_INFO, function* requestData(action) {
        try {
            let result = yield http('/goodsAudit/activityGoodsAuditInfo', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_ACTIVEPRODUCTAUDIT_INFO, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//店铺下拉
export const getActiveProductAuditShopSele = function* () {
    yield takeEvery(Audit.GET_ACTIVEPRODUCTAUDIT_SHOPSELE, function* requestData(action) {
        try {
            let result = yield http('/shop/listAll', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_ACTIVEPRODUCTAUDIT_SHOPSELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//退出申请列表
export const getActiveProductAuditExitList = function* () {
    yield takeEvery(Audit.GET_ACTIVEPRODUCTAUDIT_EXITLIST, function* requestData(action) {
        try {
            let result = yield http('/withdrawActivityAudit/listWithdraw', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_ACTIVEPRODUCTAUDIT_EXITLIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//退出申请信息
export const getActiveProductAuditExitInfo = function* () {
    yield takeEvery(Audit.GET_ACTIVEPRODUCTAUDIT_EXITINFO, function* requestData(action) {
        try {
            let result = yield http('/withdrawActivityAudit/getWithdrawDetail', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_ACTIVEPRODUCTAUDIT_EXITINFO, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};