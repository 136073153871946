import * as Order from './actionTypes';

//免单看板订单列表
export const getFreeOrderList = (param) => ({
    type: Order.GET_FREEORDER_LIST,
    payload: param,
});
//免单自动打款规则
export const getAutoFreeOrderList = (param) => ({
    type: Order.GET_AUTOFREEORDER_LIST,
    payload: param,
});
// 状态
export const getAutoFreeOrderStatus = (param) => ({
    type: Order.GET_AUTOFREEORDER_STATUS,
    payload: param,
});
// 打款状态
export const getFreeOrderStatus = (param) => ({
    type: Order.GET_FREEORDER_STATUS,
    payload: param,
});
// 免单商品
export const getFreeProductList = (param) => ({
    type: Order.GET_FREEPRODUCT_LIST,
    payload: param,
});
// 免单看板详情
export const getFreeOrderKanBanList = (param) => ({
    type: Order.GET_FREEORDERKANBAN_LIST,
    payload: param,
});
