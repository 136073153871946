import React from 'react';
import styles from './OrderTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';

class OrderTable extends React.Component {
    _takeColumns = () => ([
        {
            title: '交易凭证号',
            dataIndex: 'outPayId',
            key: 'outPayId',
            width: '8%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '会员手机号',
            dataIndex: 'buyerPhone',
            key: 'buyerPhone',
            width: '8%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '充值金额(元)',
            dataIndex: 'totalFee',
            key: 'totalFee',
            width: '8%',
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '状态',
            dataIndex: 'handleStatusDesc',
            key: 'handleStatusDesc',
            width: '8%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '充值时间',
            dataIndex: 'rechargeTime',
            key: 'rechargeTime',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            width: '10%',
            render: (text, item) => this._renderActionCell(text, item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    _renderActionCell = (key, record) => {
        const { tableAction } = this.props;
        return (
            record.handleStatus === 'UNHANDLE' && 
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'deal', record)}>处理</div>
            </div>
        );
    }
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    tableAction: PropTypes.func.isRequired, // 表格操作
};

export default OrderTable;