import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Input, Icon } from 'antd';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';

class TypeTable extends React.Component {
    columns = () => {
        let arrList = [
            {
                title: '任务单号',
                dataIndex: 'taskId',
                key: 'taskId',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '代言任务',
                dataIndex: 'taskName',
                key: 'taskName',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '代言类型',
                dataIndex: 'taskTypeName',
                key: 'taskTypeName',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '状态',
                dataIndex: 'stateName',
                key: 'stateName',
                align: 'center',
                width: cellWidth.small,
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '任务归属',
                dataIndex: 'hostName',
                key: 'host',
                align: 'center',
                width: cellWidth.small,
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '发布时间',
                dataIndex: 'startTime',
                key: 'startTime',
                width: cellWidth.time,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '结束时间',
                dataIndex: 'endTime',
                key: 'endTime',
                width: cellWidth.time,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '发行量(单)',
                dataIndex: 'publishNum',
                key: 'publishNum',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '已领取(单)',
                dataIndex: 'receivedNum',
                key: 'receivedNum',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '奖励总量',
                dataIndex: 'totalRewardNum',
                key: 'totalRewardNum',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '创建人',
                dataIndex: 'createUserName',
                key: 'createUserName',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 240,
                fixed: 'right',
                render: (id, record) => this._TableAction(id, record)
            }];
        if (this.props.tabType == '2') {
            arrList.splice(-2, 0,
                {
                    title: '排序',
                    dataIndex: 'sortNo',
                    key: 'sortNo',
                    width: cellWidth.small,
                    align: 'center',
                    render: (text, item) => (this.SortCell(text, item))
                });
        }
        return arrList;
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        if (data) {
            return data.map(item => {
                item.taskId = item.id;
                return item;
            });
        }
    }

    _TableAction = (id, record) => {
        const { tableAction } = this.props;
        const { spokesmanTaskStatus, host } = record;
        if (spokesmanTaskStatus == 'NOT_STARTED') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'detail')}>详情</div>
                    {host == 'PLAT' && <div className={styles.item} onClick={() => tableAction(id, 'delete', record)}>删除</div>}
                </div >
            );
        } else if (spokesmanTaskStatus == 'WORK') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'detail')}>详情</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'getDetail', record)}>数据统计</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'stateType', record)}>{record.state == '1' ? '禁用' : '开启'}</div>
                </div >
            );
        } else if (spokesmanTaskStatus == 'ENDED') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'detail')}>详情</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'getDetail', record)}>数据统计</div>
                    {host == 'PLAT' && <div className={styles.item} onClick={() => tableAction(id, 'delete', record)}>删除</div>}
                </div >
            );
        } else if (spokesmanTaskStatus == 'DISABLED') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'detail')}>详情</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'getDetail', record)}>数据统计</div>
                    {host == 'PLAT' && <div className={styles.item} onClick={() => tableAction(id, 'edit')}>编辑</div>}
                    <div className={styles.item} onClick={() => tableAction(id, 'stateType', record)}>{record.state == '1' ? '禁用' : '开启'}</div>
                </div >
            );
        }
    };

    // 表格项内容为可编辑
    SortCell = (value, item) => {
        const label = React.createRef();
        const box = React.createRef();
        const input = React.createRef();

        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.sort_box}>
                    <div className={styles.label} ref={label}>{value}</div>
                    <div className={styles.input} ref={box}>
                        <Input
                            key={item.id}
                            style={{ width: '50px' }}
                            ref={input}
                            defaultValue={value}
                            onBlur={(e) => {
                                box.current.style.display = 'none';
                                label.current.style.display = 'block';
                                if (value != e.target.value) {
                                    this.props.tableAction(item.id, 'sort', e.target.value);
                                }
                            }}
                        />
                    </div>
                    <div
                        className={styles.icon}
                        onClick={() => {
                            label.current.style.display = 'none';
                            box.current.style.display = 'block';
                            input.current.focus();
                        }}
                    >
                        <Icon type="edit" style={{ fontSize: '18px' }} />
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record) => record.id}
                    columns={this.columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    tabType: PropTypes.number,//tab进行中有排序
    scrollY: PropTypes.string
};

export default XTableHOC(TypeTable);