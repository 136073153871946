/**
 *  【通联财务设置】创建
 * */
import React from 'react';
import styles from './index.module.scss';
import { Steps } from 'antd';
import PropTypes from 'prop-types';
import { Step1, Step2 } from './step';

const { Step } = Steps;
class AllPayCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            curStep: props.curStep,
        };
    }

    _renderStepContent = () => {
        const { curStep } = this.state;
        switch (curStep) {
            case 1:
                return <Step1 history={this.props.history} next={() => { this.setState({ curStep: 2 }); }} onChange={(utype) => this.setState({ unitType: utype })} />;
            case 2:
                return <Step2 history={this.props.history} signSuccess={this.props.signSuccess} previous={() => { this.setState({ curStep: 1 }); }} next={() => { this.setState({ curStep: 3 }); }} />;
        }
    }

    render() {
        const { curStep } = this.state;
        return (
            <div className={styles.container}>
                <div className={styles.spinContent}>
                    <Steps current={(curStep - 1)}>
                        <Step title="填写基础信息" />
                        <Step title="绑定手机" />
                        <Step title="签订电子协议" />
                    </Steps>
                </div>
                <div className={styles.stepContent}>
                    {this._renderStepContent()}
                </div>
            </div>
        );
    }
}

AllPayCreate.propTypes = {
    history: PropTypes.object,
    curStep: PropTypes.number,
    signSuccess: PropTypes.func
};

export default AllPayCreate;