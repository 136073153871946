/**
 * 【财务设置创建页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, Form, Button, message } from 'antd';
import styles from './index.module.scss';
import { XCancelButton } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};


class addFinancialContent extends React.Component {
    state = {
        isNew: true, // 是否为新建
        id: '', // 配置ID
        unitName: '', // 单位名称
        taxNum: '', // 纳税人识别码
        registerAddr: '', // 注册地址
        registerTel: '', // 注册电话
        openBank: '', // 开户银行
        bankAccount: '', // 银行账号
        legalName: '', // 法人姓名
        legalTel: '', // 法人手机号
        legalIdentity: '', // 法人身份证
        receiveMail: '', // 收件人邮箱地址
        receiveAddr: '', // 收件地址
        receiveName: '', // 收票人姓名
        receivePhone: '', // 收票人手机号
    }

    componentDidMount() {
        this._init();
    }

    // 初始化
    _init = () => {
        http('/company/info/getFinancial', {}, 'GET').then((response) => {
            if (response.status == 200) {
                if (response.result && response.result.id != '') {
                    const { id, unitName, taxNum, registerAddr, registerTel, openBank, bankAccount, legalName, legalTel, legalIdentity, receiveMail, receiveAddr, receiveName, receivePhone } = response.result;
                    this.setState({
                        isNew: false,
                        id,
                        unitName,
                        taxNum,
                        registerAddr,
                        registerTel,
                        openBank,
                        bankAccount,
                        legalName,
                        legalTel,
                        legalIdentity,
                        receiveMail,
                        receiveAddr,
                        receiveName,
                        receivePhone
                    });
                } else {
                    this.setState({ isNew: true });
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 提交
    _handleSubmit = e => {
        const { isNew, id } = this.state;
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                console.log('提交', fieldsValue);
                if (isNew) {
                    http('/company/info/saveFinancial', fieldsValue, 'POST').then((response) => {
                        if (response.status == 200) {
                            message.success('创建成功');
                            this._init();
                            // window.open(response.result.url, 'blank');
                        }
                    }).catch((e) => {
                        message.error(e.message);
                    });
                } else {
                    http('/company/info/updateFinancial', { ...fieldsValue, id }, 'POST').then((response) => {
                        if (response.status == 200) {
                            message.success('编辑成功');
                            this._init();
                            // window.open(response.result.url, 'blank');
                        }
                    }).catch((e) => {
                        message.error(e.message);
                    });
                }
            }
        });
    }

    // 重置
    _resetHandle = () => {
        this.props.form.resetFields();
        this.setState({
            unitName: '', // 单位名称
            taxNum: '', // 纳税人识别码
            registerAddr: '', // 注册地址
            registerTel: '', // 注册电话
            openBank: '', // 开户银行
            bankAccount: '', // 银行账号
            legalName: '', // 法人姓名
            legalTel: '', // 法人手机号
            legalIdentity: '', // 法人身份证
            receiveMail: '', // 收件人邮箱地址
            receiveAddr: '', // 收件地址
            receiveName: '', // 收票人姓名
            receivePhone: '', // 收票人手机号
        });
    }

    _okHandle = () => {
        http('/company/info/acvite', {}, 'GET').then((response) => {
            if (response.status == 200) {
                message.success(response.message);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    render() {
        const { isNew, unitName, taxNum, registerAddr, registerTel, openBank, bankAccount, legalName, legalTel, legalIdentity, receiveMail, receiveAddr, receiveName, receivePhone } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <div className={styles.container} >
                <div className={styles.title}>财务信息</div>
                <div className={styles.line}></div>
                <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                    <Form.Item
                        colon={false}
                        htmlFor='unitName'
                        label={<span>单位名称</span>}
                    >
                        {getFieldDecorator('unitName', {
                            rules: [{ required: true, message: '请输入单位名称' }],
                            initialValue: unitName
                        })(<Input placeholder='' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='taxNum'
                        label={<span>统一社会信用证编号</span>}
                    >
                        {getFieldDecorator('taxNum', {
                            rules: [
                                { required: true, message: '请输入统一社会信用证编号' },
                                { pattern: /[A-Z0-9]{18}/g, message: '请输入18位数字加大写字母的统一社会信用证编号' }
                            ],
                            initialValue: taxNum
                        })(<Input placeholder='请输入18位数字加大写字母的统一社会信用证编号' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='registerAddr'
                        label={<span>注册地址</span>}
                    >
                        {getFieldDecorator('registerAddr', {
                            rules: [{ required: true, message: '请输入注册地址' }],
                            initialValue: registerAddr
                        })(<Input placeholder='' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='registerTel'
                        label={<span>注册电话</span>}
                    >
                        {getFieldDecorator('registerTel', {
                            rules: [
                                { required: true, message: '请输入固话(区号+号码，如010-1234567)或手机号码' },
                                {
                                    validator: (rule, value = '', callback) => {
                                        var RegExp = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/;
                                        if (value == '') {
                                            callback();
                                        } else if (RegExp.test(value) == false) {
                                            callback('请输入正确的固话(区号+号码，如010-1234567)或手机号码');
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ],
                            initialValue: registerTel
                        })(<Input placeholder='请输入固话(区号+号码，如010-1234567)或手机号码' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='openBank'
                        label={<span>开户银行</span>}
                    >
                        {getFieldDecorator('openBank', {
                            rules: [{ required: true, message: '请输入开户银行' }],
                            initialValue: openBank
                        })(<Input placeholder='' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='bankAccount'
                        label={<span>银行账号</span>}
                    >
                        {getFieldDecorator('bankAccount', {
                            rules: [{ required: true, message: '请输入银行账号' }],
                            initialValue: bankAccount
                        })(<Input placeholder='' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='legalName'
                        label={<span>法人姓名</span>}
                    >
                        {getFieldDecorator('legalName', {
                            rules: [
                                { required: true, message: '请输入2-6位姓名(仅支持中文)' },
                                { pattern: /^[\u4e00-\u9fa5]{2,6}$/, message: '请输入正确的2-6位姓名(仅支持中文)' },
                            ],
                            initialValue: legalName
                        })(<Input placeholder='请输入2-6位姓名(仅支持中文)' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='legalTel'
                        label={<span>法人手机</span>}
                    >
                        {getFieldDecorator('legalTel', {
                            rules: [
                                { required: true, message: '请输入11位法人手机号码' },
                                { pattern: /^[1-9]\d{10}$/, message: '请输入正确的手机号码' },
                            ],
                            initialValue: legalTel
                        })(<Input placeholder='请输入11位法人手机号码' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='legalIdentity'
                        label={<span>法人身份证</span>}
                    >
                        {getFieldDecorator('legalIdentity', {
                            rules: [
                                { required: true, message: '请输入法人身份证号' },
                                { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证号' },
                            ],
                            initialValue: legalIdentity
                        })(<Input placeholder='请输入法人身份证号' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='receiveMail'
                        label={<span>收件人邮箱地址</span>}
                    >
                        {getFieldDecorator('receiveMail', {
                            rules: [
                                { required: true, message: '请输入收件人邮箱地址' },
                                { pattern: /^[0-9a-zA-Z._-]+[@][0-9a-zA-Z._-]+([.][a-zA-Z]+){1,2}$/, message: '请输入正确的邮箱地址' },
                            ],
                            initialValue: receiveMail
                        })(<Input placeholder='请输入收件人邮箱地址' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='receiveAddr'
                        label={<span>收件人地址</span>}
                    >
                        {getFieldDecorator('receiveAddr', {
                            rules: [
                                { required: true, message: '请输入收件人地址' },
                                { pattern: /^[^\s]*$/, message: '禁止输入空格' }
                            ],
                            initialValue: receiveAddr
                        })(<Input placeholder='请输入收件人地址' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='receiveName'
                        label={<span>收票人姓名</span>}
                    >
                        {getFieldDecorator('receiveName', {
                            rules: [
                                { required: true, message: '请输入2-6位姓名(仅支持中文)' },
                                { pattern: /^[\u4e00-\u9fa5]{2,6}$/, message: '请输入正确的2-6位姓名(仅支持中文)' },
                            ],
                            initialValue: receiveName
                        })(<Input placeholder='请输入2-6位姓名(仅支持中文)' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='receivePhone'
                        label={<span>收票人手机</span>}
                    >
                        {getFieldDecorator('receivePhone', {
                            rules: [
                                { required: true, message: '请输入11位收票人手机号码' },
                                { pattern: /^[1-9]\d{10}$/, message: '请输入正确的手机号码' },
                            ],
                            initialValue: receivePhone
                        })(<Input placeholder='请输入11位收票人手机号码' style={{ width: 400 }} />)}
                    </Form.Item>
                    <FormItem>
                        <Button style={{ marginLeft: '24%', marginRight: '20px', width: '120px', display: 'inline-block' }} type="primary" htmlType="submit">提交</Button>
                        <XCancelButton style={{ width: '120px', display: 'inline-block' }} label='重置' onClick={this._resetHandle} />
                        {/* <Button style={{ marginLeft: '20px', width: '220px', display: 'inline-block' }} disabled={isNew} type="primary" onClick={this._okHandle}>确认已按要求打款后的激活</Button> */}
                    </FormItem>
                </Form>
            </div >
        );
    }
}
addFinancialContent.propTypes = {
    history: PropTypes.object, // 路由history对象
    form: PropTypes.object.isRequired, // 校验
    match: PropTypes.object, // 路由match对象
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(addFinancialContent);// 校验

export default connect(null, null)(ForgotProducDetails);