/**
 * 付款管理
 */
import React, { Fragment } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav, XInput, XSelect, XOKButton, XCancelButton, XDatePicker, XInputNum } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getPayManageTableSource, getPayChannelEnum } from '@/reducers/storehousefinancialmanage/paymanage/actions';
import merchantModel from '@/reducers/storehousefinancialmanage/paymanage/merchantModel';
import TableContent from './components/TableContent';
import DeleteModal from './modal/DeleteModal';
import SubmitAuditModal from './modal/SubmitAuditModal';
import ConfirBill from './modal/ConfirBill';
import PayConfirmate from './modal/PayConfirmate';
import UnitPayConfirmate from './modal/UnitPayConfirmate';
import DispatchConfirmate from './modal/DispatchConfirmate';
import CancelPay from './modal/CancelPay';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';
import { SearchBox, SearchItem } from '@/components/Layout';
import { regFenToYuan } from '@/util/money';
import { message } from 'antd';
import { setLocalStorage, removeLocalStorage, getLocalStorage } from '@/assets/js/storage';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.batchData = [];
    }

    state = {
        tab: [
            { id: 'PERSONAL', label: '个人' },
            { id: 'UNIT', label: '单位' },
            { id: 'MERCHANT', label: '商户' },
            { id: 'STARLIGHT', label: '魅力值' },
            { id: 'SUPPLIER', label: '渠道' },
            { id: 'CONSIGNMENT', label: '爆品' },
            { id: 'GIFT_USER', label: '礼屋' },
        ],
        curTabID: 'PERSONAL',  // 当前显示的Tab页ID
        payStatus: [], // 状态枚举下拉列
        openModeParam: null, // 弹框入参
        orderId: undefined, // 付款单号
        status: {}, // 状态选择
        targetName: undefined, // 收款方
        tel: undefined, // 手机号
        startDate: undefined, // 新建开始日期
        endDate: undefined, // 新建开始日期
        batchData: [],//批量数据
        currentOpenMode: '',
        selectedRowKeys: [],
        remitStatusList: [],
        remitStatus: {},
        payChannel: { code: 'ALI_PAY', value: '支付宝' },
        levyItem: {},
        serviceList: []
    }

    componentDidMount() {
        const { getPayChannelEnum, keepSecondNavBreadcrumb, getKungeekBalance, history: { location = {} } = {} } = this.props;
        const { payChannel = {}, levyItem = {} } = this.state;
        keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        getPayChannelEnum();
        // 获取状态枚举下拉列
        http('/enum/PaymentApplicationStatus').then((res = {}) => {
            if (res.code === 200) {
                this.setState({ payStatus: res.result });
            }
        });
        http('/enum/RemitStatus').then((res = {}) => {
            if (res.code === 200) {
                this.setState({ remitStatusList: res.result });
            }
        });

        this.setState(location.state);
        // 查询余额
        getKungeekBalance({ accessType: payChannel.code, levyId: levyItem.code });

        const paymanageTabId =  getLocalStorage('paymanageTabId');
        if (paymanageTabId) {
            this.setState({curTabID: paymanageTabId});
        }
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID, batchData: [] }, () => {
            this._searchHandle('useCache');
        });
        setLocalStorage('paymanageTabId', tabID);
    }
    // 查询
    _searchHandle = (useCache) => {
        const { orderId, targetName, tel, startDate, endDate, status, curTabID, pageNum, pageSize, remitStatus } = this.state;
        this.searchCache = {
            paymentId: orderId,
            queryPaymentType: curTabID,
            searchName: targetName,
            tel,
            status: status.code,
            payStatus: remitStatus.code,
            startDate,
            endDate,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };

        this.props.getPayManageTableSource(this.searchCache); // 发起请求
        KeepAlive.saveCache({ orderId, targetName, tel, startDate, endDate, status, curTabID, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ orderId: undefined, targetName: undefined, tel: undefined, status: {}, startDate: undefined, endDate: undefined, remitStatus: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格操作
    _tableAction = (id, type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                this.setState({ currentOpenMode: 'info', openModeParam: id });
                addOneBreadcrumbPath({
                    title: '付款详情',
                    pathname: `/home/storehousefinancialmanage/paymanage/detail/${id}/${record.payStatus || null}`,
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/paymanage/detail/${id}/${record.payStatus || null}`,
                    state: { curTabID: this.state.curTabID }
                });
                break;
            case 'dispatchId':
                addOneBreadcrumbPath({
                    title: '派单查询',
                    pathname: `/home/storehousefinancialmanage/paymanage/dispatchquery/${id ? id : -1}`,
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/paymanage/dispatchquery/${id ? id : -1}`,
                });
                break;
            case 'manageAudit':
                this.setState({ currentOpenMode: 'manageAudit', openModeParam: id });
                break;
            case 'directorReview':
                this.setState({ currentOpenMode: 'manageAudit', openModeParam: id });
                break;
            case 'auditSubmit':
                this.setState({ currentOpenMode: 'auditSubmit', openModeParam: id });
                break;
            case 'pay':
                this.setState({ currentOpenMode: 'pay', openModeParam: record });
                break;
            case 'unitPay':
                this.setState({ currentOpenMode: 'unitPay', openModeParam: record });
                break;
            case 'del':
                this.setState({ currentOpenMode: 'del', openModeParam: id });
                break;
            case 'batchData':
                this.setState({ batchData: record, selectedRowKeys: id });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'del':
                return <DeleteModal data={this.state.openModeParam} title='系统信息' visible={true} closeMode={this._closeMode} />;
            case 'auditSubmit':
                return <SubmitAuditModal data={this.state.openModeParam} title='提交审核' visible={true} closeMode={this._closeMode} />;
            case 'manageAudit':
                return <ConfirBill data={this.state.openModeParam} title='通过审核' visible={true} closeMode={this._closeMode} />;
            case 'pay':
                return <PayConfirmate data={this.state.openModeParam} title='付款确认' visible={true} closeMode={this._closeMode} />;
            case 'dispatch':
                return <DispatchConfirmate data={this.state.openModeParam} title='派单确认' visible={true} closeMode={this._closeMode} />;
            case 'unitPay':
                return <UnitPayConfirmate data={this.state.openModeParam} title='付款确认' visible={true} closeMode={this._closeMode} />;
            case 'unitCancelPay':
                return <CancelPay data={this.state.openModeParam} title='取消打款' visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }
    dispatchTotal = () => {
        const { selectedRowKeys } = this.state;
        this.setState({ currentOpenMode: 'dispatch', openModeParam: selectedRowKeys });
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', openModeParam: null, batchData: [] }, () => {
            if (update) {
                this.setState({ batchData: [] }, () => {
                    this._searchHandle('useCache');
                });
            }
        });
    }

    //判断勾选数据是否统一状态
    _isUnitedState = (type, batchData) => {
        let statuses = [];
        batchData.forEach(item => {
            if (item.status === type) {
                statuses.push(item);
            }
        });
        if (type === 'FINAL_SUCCESS') {
            statuses = statuses.filter(item => item.payStatus === 'TODO');
        }
        if (statuses.length === batchData.length) return true;
        return false;
    }

    //页面操作
    _audit = (type, msg, mode) => {
        const { batchData } = this.state;
        if (!batchData.length) {
            message.warning('请勾选数据');
            return;
        }
        if (this._isUnitedState(type, batchData)) {
            this.setState({ currentOpenMode: mode, openModeParam: batchData });
        } else {
            message.warning(msg);
        }
    }

    _batchCancelPay = () => {
        const { batchData } = this.state;
        if (batchData.length === 0) {
            message.warning('请选择一条记录');
            return;
        }
        if (batchData.some(v => v.payStatus !== 'SUCCESS')) {
            message.warning('只能选择付款成功的记录');
            return;
        }
        this.setState({ currentOpenMode: 'unitCancelPay', openModeParam: batchData });
    }

    //支付通道改变
    _channelChange = e => {
        if (e.code === 'YOU_FU_PAY') {
            http('/settle/paymentApplication/getPayChannelMap', {}, 'POST').then(({ result = {} } = {}) => {
                let serviceList = [];
                const map = new Map(new Map(Object.entries(result)));
                Object.keys(result).forEach(i => {
                    serviceList.push({ code: i, value: map.get(i) });
                });
                this.setState({ serviceList });
            });
        } else { this.setState({ serviceList: [], levyItem: {} }); }
    }

    //余额查询
    _balanceEnquiry = () => {
        const { payChannel = {}, levyItem = {} } = this.state;
        if (payChannel.code === 'YOU_FU_PAY' && !levyItem.code) return message.warn('请选择身边云服务商');
        this.props.getKungeekBalance({ accessType: payChannel.code, levyId: levyItem.code });
    }


    render() {
        const { orderId, status, targetName, tel, startDate, endDate, payStatus, curTabID, batchData, remitStatusList, remitStatus, payChannel, serviceList, levyItem } = this.state;
        const { KungeekBalance, payChannelEnum = [] } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={curTabID} />
                <div style={{ height: 10 }}></div>
                <KeepAlive key={curTabID} id={curTabID} render={(state) => {
                    if (!this.state.__aliveInit) {
                        this.setState({ ...state, __aliveInit: true }, () => {
                            this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                        });
                    }
                }}>
                    <div className={styles.flexBoxContainer}>
                        <SearchBox>
                            <SearchItem>
                                <XInputNum
                                    label='付款单号'
                                    placeholder='请输入付款单号'
                                    value={orderId}
                                    bindThis={this}
                                    bindName='orderId'
                                    min={0}
                                    precision={0}
                                    maxLength={16}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='收款方'
                                    placeholder='请输入收款方名称'
                                    value={targetName}
                                    bindThis={this}
                                    bindName='targetName'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='手机号'
                                    placeholder='请输入手机号'
                                    value={tel}
                                    bindThis={this}
                                    bindName='tel'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XDatePicker
                                    label='新建时间'
                                    value={startDate}
                                    bindThis={this}
                                    bindName='startDate'
                                    isFormat
                                />
                                <XDatePicker
                                    style={{ marginRight: '15px' }}
                                    onChange={this._orderEndTimeChangeHandle}
                                    value={endDate}
                                    bindThis={this}
                                    bindName='endDate'
                                    isFormat
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='状态'
                                    placeholder='请选择'
                                    renderData={payStatus}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={status.value}
                                    bindThis={this}
                                    bindName='status'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='付款状态'
                                    placeholder='请选择'
                                    renderData={remitStatusList}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={remitStatus.value}
                                    bindThis={this}
                                    bindName='remitStatus'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                        <div className={styles.row2}>
                            <XOKButton
                                label='提交审核'
                                style={{ width: 90, marginRight: 20 }}
                                onClick={() => {
                                    this._audit('INIT', '您当前在进行提交审核时选中的项目包含了其它类型的审核/付款，请重新勾选。', 'auditSubmit');
                                }}
                            />
                            <XOKButton
                                label='经理审核'
                                style={{ width: 90, marginRight: 20 }}
                                onClick={() => {
                                    this._audit('AUDIT', '您当前在进行经理审核时选中的项目包含了其它类型的审核/付款，请重新勾选。', 'manageAudit');
                                }}
                            />
                            <XOKButton
                                label='总监审核'
                                style={{ width: 90, marginRight: 20 }}
                                onClick={() => {
                                    this._audit('FIRST_SUCCESS', '您当前在进行总监审核时选中的项目包含了其它类型的审核/付款，请重新勾选。', 'manageAudit');
                                }}
                            />
                            {!['UNIT', 'SUPPLIER'].includes(curTabID) && <XOKButton
                                label='批量打款'
                                style={{ width: 90, marginRight: 20 }}
                                onClick={() => {
                                    this._audit('FINAL_SUCCESS', '您当前在进行付款操作时选中的项目包含了待审核、汇款中或汇款成功的选项，请重新勾选。', 'pay');
                                }}
                            />}
                            {['STARLIGHT', 'PERSONAL', 'SUPPLIER'].includes(curTabID) &&
                                <Fragment>
                                    <XSelect label='付款通道' placeholder='请选择付款通道'
                                        renderData={payChannelEnum || []}
                                        dataIndex='value' keyIndex='code'
                                        value={payChannel.value} style={{ marginRight: 0 }}
                                        bindThis={this} bindName='payChannel'
                                        onChange={this._channelChange}
                                    />
                                    {payChannel.code === 'YOU_FU_PAY' && <XSelect placeholder='请选择'
                                        renderData={serviceList} style={{ marginRight: 0 }} dataIndex='value'
                                        keyIndex='code' value={levyItem.value} bindThis={this} bindName='levyItem'
                                    />}
                                    {/* <XOKButton
                                        label='批量派单'
                                        style={{ width: 90, marginRight: 20 }}
                                        onClick={() => this.dispatchTotal()}
                                    /> */}
                                    <XOKButton label='余额查询' style={{ width: '90px', margin: '0 20px' }} onClick={this._balanceEnquiry} />
                                    <span>当前余额：{regFenToYuan(KungeekBalance)}元</span>
                                </Fragment>
                            }
                            {['UNIT'].includes(curTabID) &&
                                <XOKButton
                                    label='取消打款'
                                    style={{ width: 90, marginRight: 20 }}
                                    onClick={this._batchCancelPay}
                                />
                            }
                        </div>
                        <TableContent
                            curTabID={curTabID}
                            renderData={this.props.payManageTableSource}
                            tableAction={this._tableAction}
                            paginationChange={this._paginationChange}
                            batchData={batchData}
                        />
                        {this._renderMode()}
                    </div>
                </KeepAlive>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    payManageTableSource: PropTypes.object,
    getPayManageTableSource: PropTypes.func,
    getKungeekBalance: PropTypes.func,
    KungeekBalance: PropTypes.number,
    getPayChannelEnum: PropTypes.func,
    payChannelEnum: PropTypes.array,
};
const mapStateToProps = (state) => ({
    payManageTableSource: state.paymanage.payManageTableSource,
    KungeekBalance: state.paymanage.KungeekBalance,
    payChannelEnum: state.paymanage.payChannelEnum,
});
export default connect(mapStateToProps, { getPayChannelEnum, addOneBreadcrumbPath, keepSecondNavBreadcrumb, getPayManageTableSource, ...merchantModel.actions })(Main);