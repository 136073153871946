// 活动商品列表
export const GET_ACTIVE_GOODS = 'MALL/GET_ACTIVE_GOODS';
export const SET_ACTIVE_GOODS = 'MALL/SET_ACTIVE_GOODS';
// 活动商品详情
export const GET_GOOD_DETAIL = 'MALL/GET_GOOD_DETAIL';
export const SET_GOOD_DETAIL = 'MALL/SET_GOOD_DETAIL';
// 商户下拉列
export const GET_MERCHANTS = 'MALL/GET_MERCHANTS';
export const SET_MERCHANTS = 'MALL/SET_MERCHANTS';


