import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/auditmanage/payorder/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class DailySaid extends Component {

    static propTypes = {
        history: PropTypes.object,
        addOneBreadcrumbPath: PropTypes.func,
        keepSecondNavBreadcrumb: PropTypes.func,
        getPayOrderList: PropTypes.func,
        payOrderList: PropTypes.object,
        getStateEnum: PropTypes.func,
        stateEnum: PropTypes.array
    }

    static defaultProps = {
        payOrderList: { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 },
        stateEnum: []
    }

    state = {
        mobile: '',
        payVoucherNo: '',
        startDate: null,
        endDate: null,
        stateObj: {}
    }

    componentDidMount() {
        const { getStateEnum, keepSecondNavBreadcrumb } = this.props;
        getStateEnum();
        keepSecondNavBreadcrumb();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, mobile, payVoucherNo, startDate, endDate, stateObj } = this.state;
        this.searchCache = {
            mobile, payVoucherNo, startDate, endDate, auditStatus: stateObj.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getPayOrderList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ mobile: '', payVoucherNo: '', startDate: null, endDate: null, stateObj: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 路由跳转
    _updateContent = (title, url, id) => {
        const { addOneBreadcrumbPath, history } = this.props;
        addOneBreadcrumbPath({
            title,
            path: `/home/auditmanage/payorder${url}`
        });
        history.push({
            pathname: `/home/auditmanage/payorder${url}${id}`
        });
    }

    //表格操作
    _tableAction = (id, type) => {
        switch (type) {
            case 'detail':
                this._updateContent('支付凭证详情信息', '/payorderinfo/', id);
                break;
            case 'audit':
                this._updateContent('支付凭证审核信息', /payorderaudit/, id);
                break;
            default:
                break;
        }
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { stateEnum, payOrderList } = this.props;
        const { mobile, payVoucherNo, startDate, endDate, stateObj } = this.state;
        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='手机号码'
                                placeholder='请输入手机号码'
                                bindThis={this}
                                bindName='mobile'
                                value={mobile}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='转账账号'
                                placeholder='请输入支付单号'
                                bindThis={this}
                                bindName='payVoucherNo'
                                value={payVoucherNo}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='申请时间'
                                isFormat
                                bindThis={this}
                                bindName='startDate'
                                value={startDate}
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                isFormat
                                bindThis={this}
                                bindName='endDate'
                                value={endDate}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={stateEnum}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='stateObj'
                                value={stateObj.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent
                        paginationChange={this._paginationChange}
                        renderData={payOrderList}
                        tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}

const mapStateToProps = state => ({
    payOrderList: state.payorder.payOrderList,
    stateEnum: state.payorder.stateEnum
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(DailySaid);