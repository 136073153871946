import React, { useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message, Modal, Typography } from 'antd';

const EnableDisableModal = ({
    tableAction,
    modalParmas: { active = '', key = [] } = {}
}) => {

    const [loading, setLoading] = useState(false);

    const handleOk = () => {
        setLoading(true);
        http('/user/supplierAccountInfo/updateSupplierState', {
            idList: key,
            state: (active === 'singleOpen' || active === 'batchOpen') ? '1' : '0'
        }, 'POST').then(res => {
            message.success(res.message);
            setLoading(false);
            tableAction(true, 'closeModal');
        }).catch(err => {
            setLoading(false);
            message.error(err.message);
        });
    };

    const tipStyles = {
        textAlign: 'center',
        height: '32px',
        lineHeight: '32px',
    };

    return <Modal
        width={420}
        centered
        title={(active === 'singleOpen' || active === 'batchOpen') ? '启用提示！' : '禁用提示'}
        visible={true}
        confirmLoading={loading}
        onOk={handleOk}
        onCancel={() => tableAction(false, 'closeModal')}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确定'
    >
        <div className={styles.modalContainer}>
            <div style={tipStyles}>
                {active === 'singleOpen' && <Typography.Text>您是否启用，当前用户</Typography.Text>}
                {active === 'singleBan' && <Typography.Text>您是否禁用当前用户</Typography.Text>}
                {active === 'batchOpen' && <Typography.Text>{`您是否启用选中的“${key.length}”条数据`}</Typography.Text>}
                {active === 'batchBan' && <Typography.Text>{`您是否禁用选中的“${key.length}”条数据`}</Typography.Text>}
            </div>
            <div style={tipStyles}>
                {(active === 'singleOpen' || active === 'batchOpen') && <Typography.Text>启用后用户将可以登陆，您是否确认启用</Typography.Text>}
                {(active === 'singleBan' || active === 'batchBan') && <Typography.Text>禁后用户将账号无法充值、无法下单，<br />无法提现，您是否确认禁用</Typography.Text>}
            </div>
        </div>
    </Modal >;
};
EnableDisableModal.propTypes = {
    tableAction: PropTypes.func,
    modalParmas: PropTypes.object
};
export default EnableDisableModal;