import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, Tooltip } from 'antd';
import styles from './TableContent.module.scss';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell, ImgCell, cellWidth } from '@/components/TableCell';

class TypeTable extends Component {

    //props校验
    static propTypes = {
        renderData: PropTypes.object.isRequired, // 渲染数据源(双向绑定)
        tableAction: PropTypes.func.isRequired, // 对表格的操作
        paginationChange: PropTypes.func.isRequired,//分页
        scrollY: PropTypes.string
    };
    //表头
    _columns = () => [
        {
            title: '商品图片',
            dataIndex: 'skuImg',
            key: 'skuImg',
            align: 'center',
            width: cellWidth.normal,
            render: ImgCell
        },
        {
            title: '商品货号',
            dataIndex: 'itemNo',
            key: 'itemNo',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动名称',
            dataIndex: 'activityTitle',
            key: 'activityTitle',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动标签',
            dataIndex: 'activityTypeValue',
            key: 'activityTypeValue',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所属店铺',
            dataIndex: 'shopName',
            key: 'shopName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '销售价格（元）',
            dataIndex: 'retailPrice',
            key: 'retailPrice',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '活动价格（元）',
            dataIndex: 'goodsPrice',
            key: 'goodsPrice',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '技术服务费（%）',
            dataIndex: 'serviceFee',
            key: 'serviceFee',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => this._rateCell(text)
        },
        {
            title: '平台服务费（%）',
            dataIndex: 'distributionFee',
            key: 'distributionFee',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => this._rateCell(text)
        },
        {
            title: '活动总库存',
            dataIndex: 'stock',
            key: 'stock',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '平台库存',
            dataIndex: 'platStock',
            key: 'platStock',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '服务商库存',
            dataIndex: 'sellerStock',
            key: 'sellerStock',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请退出原因',
            dataIndex: 'withDrawReason',
            key: 'withDrawReason',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请时间',
            dataIndex: 'withdrawTime',
            key: 'withdrawTime',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '审核状态',
            dataIndex: 'withdrawStateValue',
            key: 'withdrawStateValue',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'activityGoodsId',
            key: 'activityGoodsId',
            align: 'center',
            fixed: 'right',
            width: cellWidth.normal,
            render: (text, record) => this._tableAction(text, record)
        }
    ];
    //文字超出
    _tableTipCell = (text) => {
        return <Tooltip placement='topLeft' title={text}>{text}</Tooltip>;
    }

    //费率装换
    _rateCell = (text) => {
        return text / 10;
    }
    //操作表格 
    _tableAction = (key, record) => {
        const { tableAction } = this.props;
        return (<Fragment>
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
                {(record.withdrawState != 'PASS' && record.withdrawState != 'FAIL') && <div style={{ width: '48px' }} className={styles.item} onClick={() => tableAction(key, 'audit', record)}>审核</div>}
            </div>
        </Fragment>
        );
    };
    // 解析表格数据
    _takeDataSource = (data) => {
        return data;
    }


    render() {
        const { renderData, paginationChange } = this.props;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.activityGoodsId}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(renderData.dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}
export default XTableHOC(TypeTable);