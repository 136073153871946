import React, { useState, useEffect } from 'react';
import styles from './TableBox.module.scss';
import PropTypes from 'prop-types';
import { Icon, Table, Typography } from 'antd';
import { XPagination, XTableHOC, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth, switchCell } from '@/components/TableCell';

const TableBox = ({ scrollY = '', paginationChange, batchKeys = [], renderData: { dataList, resultTotal, pageSize, pageNum }, tableOperate }) => {

    const [keys, setKeys] = useState([]);

    useEffect(() => { setKeys(batchKeys); }, [batchKeys]);

    const operate = (id, item) => {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableOperate(id, 'delete', item)}>删除</div>
                <div className={styles.item} onClick={() => tableOperate(id, 'log', item)}>操作日志</div>
            </div>
        );
    };

    const feeInfo = (text, item) => {
        return <div>
            {text}<Icon style={{ marginLeft: 20 }} type='edit' theme='twoTone' onClick={() => tableOperate(item.id, 'feeInfo', item)} />
        </div>;
    };

    const validity = (text, item) => {
        return <div>{`${item.relieveFeeStarTime} - ${item.relieveFeeEndTime}`}</div>;
    };

    const columns = [
        {
            title: '商户编号',
            dataIndex: 'merchantNo',
            key: 'merchantNo',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '商户类型',
            dataIndex: 'merchantTypeName',
            key: 'merchantTypeName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '商户地址',
            dataIndex: 'addrDetail',
            key: 'addrDetail',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '手动结算',
            dataIndex: 'settleManualState',
            key: 'settleManualState',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => switchCell(text, value => tableOperate(value, 'balance', item))
        }, {
            title: '批发商品免审核',
            dataIndex: 'wholesaleGoodsAuditState',
            key: 'wholesaleGoodsAuditState',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => switchCell(text, value => tableOperate(value, 'batch', item))
        }, {
            title: '活动商品免审核',
            dataIndex: 'activeGoodsAuditState',
            key: 'activeGoodsAuditState',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => switchCell(text, value => tableOperate(value, 'active', item))
        }, {
            title: '商户免收服务费',
            dataIndex: 'relieveFeeState',
            key: 'relieveFeeState',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => switchCell(text, value => tableOperate(value, 'fee', item))
        }, {
            title: '免收服务费',
            dataIndex: 'relieveFeeStr',
            key: 'relieveFeeStr',
            width: 400,
            align: 'center',
            onCell: tooltipStyle,
            render: feeInfo
        }, {
            title: '有效期',
            dataIndex: 'validity',
            key: 'validity',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: validity
        }, {
            title: '操作',
            dataIndex: 'merchantId',
            key: 'merchantId',
            width: cellWidth.normal,
            align: 'center',
            fixed: 'right',
            onCell: tooltipStyle,
            render: operate
        }
    ];

    // 表格复选输入
    let rowSelection = {
        selectedRowKeys: keys,
        onChange: (selectedRowKeys) => {
            setKeys(selectedRowKeys);
            tableOperate(null, 'batchKeys', selectedRowKeys);
        }
    };

    // 清空表格复选框
    const cleanAllSelected = () => {
        setKeys([]);
        tableOperate(null, 'batchKeys', []);
    };

    return (
        <div className={styles.tableContainer} style={{ height: '100%' }}>
            <XTableSelectionCount selectedNum={keys.length} onClean={cleanAllSelected} />
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowSelection={rowSelection}
                rowKey={record => record.merchantId}
                columns={columns}
                dataSource={dataList}
                pagination={false}
                scroll={{ x: 'max-content', y: scrollY }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};
TableBox.propTypes = {
    scrollY: PropTypes.string,
    batchKeys: PropTypes.array,
    tableOperate: PropTypes.func,
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default XTableHOC(TableBox, 40);