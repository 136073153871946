import { createModel } from '@/store/tools';


const model = {
    namespace: 'starlevelmanage',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getHotelLevelList': T('getHotelLevelList'),
        },
        actions: {
            'getHotelLevelList': A('getHotelLevelList'), // 讲师列表数据 
        },
        sagas: {
            'getHotelLevelList': S('getHotelLevelList', '/hotel/hotelStarLevel/hotelLevelList'),
        },
        reducers: {
            'hotelLevelList': R('getHotelLevelList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
        }
    })
};
export default createModel(model);