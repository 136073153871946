/**
 * 人气值购买记录
 */
import React from 'react';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton, XInput } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getstarsgiftSource, getstarsvaluebuySource, } from '@/reducers/showmanage/present/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class ValueBuy extends React.Component {

    state = {
        type: {},
        typeName: '',
    }

    componentDidMount() {
        const { getstarsgiftSource, } = this.props;
        getstarsgiftSource({ classTypeCode: 'STAR_GIFT_CLASS' });//列表数据分类状态
    }

    // 分类状态下拉列改变时触发
    _typeChangeHandle = (selectedItem) => {
        this.setState({ type: selectedItem });
    }

    // 分类名称输出框改变时触发
    _typeNameInputChangeHandle = (value) => {
        this.setState({ typeName: value });
    }

    // 导出Excel
    _excelHandle = () => {
        console.log('导出Excel');
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { type, typeName, pageNum, pageSize } = this.state;
        this.searchCache = {
            giftName: typeName,//礼物名称  
            classId: type.id,//礼物分类
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getstarsvaluebuySource(this.searchCache);//查询方法
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ type: {}, typeName: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 下拉选项数据处理渲染
    _giftSelectData(data) {
        return data && data.map(item => ({
            id: item.id,
            name: item.className,
        }));
    }
    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getstarsvaluebuySource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { type, typeName } = this.state;
        const { selectData, tableSource, paginations, } = this.props;
        return (
            <KeepAlive id='6' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <SearchBox>
                            <SearchItem>
                                <XSelect
                                    label='礼物分类'
                                    placeholder='请选择礼物分类'
                                    renderData={this._giftSelectData(selectData)}
                                    onChange={this._typeChangeHandle}
                                    dataIndex='name'
                                    keyIndex='id'
                                    value={type.name}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='礼物名称'
                                    placeholder='请输入礼物名称'
                                    onChange={this._typeNameInputChangeHandle}
                                    value={typeName}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    {/* <div className={styles.operate_box}>
                    <XOKButton style={{ width: '120px' }} label='导出EXCEL' onClick={this._excelHandle} />
                </div> */}
                    <div className={styles.flexBoxContainer}>
                        <TableContent _paginationChange={this._paginationChange} renderData={tableSource} tableAction={this._tableAction} pagiNation={paginations} />
                    </div>
                </div>
            </KeepAlive>
        );
    }
}



ValueBuy.propTypes = {
    getstarsgiftSource: PropTypes.func,// 礼物分类列表数据数据源的Action =》分类下拉选项

    tableSource: PropTypes.array, // 表格数据源
    selectData: PropTypes.array, // 分类状态下拉选项
    paginations: PropTypes.object, // 分页数据
    getstarsvaluebuySource: PropTypes.func, // 通过Saga异步请求表格数据源的Action
};


const mapStateToProps = (state) => {
    return {
        tableSource: state.presentcomment.presrntDate.starsvalueBuytableSource,
        selectData: state.presentcomment.presrntDate.tableSource,// 礼物分类列表数据数据源的Action =》分类下拉选项
        paginations: state.presentcomment.presrntDate.starsvaluepaginations,
    };
};

export default connect(mapStateToProps, { getstarsgiftSource, getstarsvaluebuySource })(ValueBuy);