/**
 * 设置财务密码弹框
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './FinancialPwd.module.scss';
import { XInputPassword } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import md5 from 'js-md5';

class FinancialPwd extends React.Component {

    state = {
        visible: this.props.visible,
        newFinancialPwd: '',
        ensureFinancialPwd: ''
    }

    // 确认
    _handleOk = () => {
        const { newFinancialPwd, ensureFinancialPwd } = this.state;
        if (newFinancialPwd != ensureFinancialPwd) {
            message.error('两次输入的新密码不一致！');
            return;
        }
        const data = {
            newFinancialPwd: md5(newFinancialPwd).toUpperCase(),
            ensureFinancialPwd: md5(ensureFinancialPwd).toUpperCase(),
        };
        http('/admin/empl/updateFinancialPassword', data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('更新成功！');
                this._handleCancel();
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, newFinancialPwd, ensureFinancialPwd } = this.state;

        return (
            <Modal
                width={460}
                centered
                title='财务密码设置'
                visible={visible}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInputPassword
                        style={{ marginLeft: '28px' }}
                        inputStyle={{ width: '300px' }}
                        label='新密码'
                        placeholder='请输入新密码'
                        value={newFinancialPwd}
                        bindThis={this}
                        bindName='newFinancialPwd'
                        isRequired={true}
                    />
                    <XInputPassword
                        style={{ marginTop: '30px' }}
                        inputStyle={{ width: '300px' }}
                        label='确认新密码'
                        placeholder='请输入新密码'
                        value={ensureFinancialPwd}
                        bindThis={this}
                        bindName='ensureFinancialPwd'
                        isRequired={true}
                    />
                </div>
            </Modal>
        );
    }
}

FinancialPwd.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default FinancialPwd;