/**
 * 新建/编辑须知分类
 */
import React from 'react';
import { Modal, } from 'antd';
import { XInput, } from '@/components/xqxc_ui';
import CusotmUpload, { generateFileList } from '@/components/NewUpload';
import PropTypes from 'prop-types';
import styles from './CategoryModal.module.scss';
import { message, Radio, Icon } from 'antd';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;

class Category extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        noticeName: this.props.data !== undefined ? this.props.data.className : '',//分类名称
        radioValue: this.props.data !== undefined ? this.props.data.state : 0,//状态
        singleImages: this.props.data !== undefined ? generateFileList(this.props.data.classImage) : [],//图片 
    }

    componentDidMount() {

    }

    _handleOk = () => {
        const { singleImages, noticeName, radioValue } = this.state;
        const { data } = this.props;
        if (noticeName == '') return message.warn('请输入分类名称。');
        if (singleImages.length === 0) return message.warn('请上传分类图标。');
        let submitURL = data === undefined ? 'addInstructionClass' : 'updateInstructionClass';
        let submitData = {};
        if (data === undefined) {
            submitData = {
                className: noticeName,
                state: radioValue,
                classImage: singleImages[0].response ? singleImages[0].response.key : '',
            };
        } else {
            submitData = {
                id: data.id,
                className: noticeName,
                state: radioValue,
                classImage: singleImages[0].response ? singleImages[0].response.key : singleImages[0].url,
            };
        }
        this.setState({ confirmLoading: true }, () => {
            http(`/instructionClass/${submitURL}`, submitData, 'POST')
                .then(() => {
                    data === undefined ? message.success('新建成功。') : message.success('更新成功。');
                    this.setState({
                        visible: false,
                        confirmLoading: false
                    });
                    this.props.closeMode(true);
                }).catch((reject = {}) => {
                    message.error(reject.message);
                    this.setState({ confirmLoading: false });
                });
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // Radio状态改变时触发
    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }

    //验证-分类名称
    noticeChange = (value) => {
        if (value.length > 12) {
            this.setState({
                noticeName: value.substring(0, 12)
            });
        } else {
            this.setState({
                noticeName: value
            });
        }
    }

    render() {
        const { visible, title, confirmLoading, noticeName, radioValue, singleImages } = this.state;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                onOk={this._handleOk}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                okText='确定'
                cancelText='取消'
            >

                <div className={styles.container}>
                    <div className={`${styles.layout} ${styles.dataTop}`}>
                        <XInput
                            inputStyle={{ width: '270px' }}
                            label='分类名称'
                            placeholder='请输入分类名称'
                            value={noticeName}
                            isRequired={true}
                            onChange={this.noticeChange}
                            suffix={noticeName.length < '13' && `(${noticeName.length}/12)`}
                        />
                    </div>
                    <div className={`${styles.Upload} ${styles.layout}`}>
                        <span className={styles.icon}><i>*</i>分类图标</span>
                        <CusotmUpload
                            accept='.png, .jpg, .jpeg'
                            fileList={singleImages}
                            listType="picture-card"
                            wallMode="single"
                            removeError
                            qiniuUploadProps={{
                                uploadType: 'image'
                            }}
                            onChange={(info) => {
                                const singleImages = info.fileList;
                                this.setState({ singleImages });
                            }}
                        >
                            <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                        </CusotmUpload>
                    </div>
                    <div className={styles.radio}>
                        <div className={styles.label}><i>*</i>状态</div>
                        <RadioGroup style={{ marginLeft: '38px' }} onChange={this._radioValueChangeHandle} value={radioValue}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                </div>
            </Modal>
        );
    }
}

Category.propTypes = {
    title: PropTypes.string,// 弹框标题
    data: PropTypes.object, //数据
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default Category;