import { getContractListTableSource, getContractStates, getContractNames } from './contractlist/saga';//线上
import offlinelist from './offlinelist/model';//线下
import contracttemplate from './contracttemplate/model';//合同模板

export default [
    getContractListTableSource,
    getContractStates,
    getContractNames,
    ...Object.values(offlinelist.sagas),//线下
    ...Object.values(contracttemplate.sagas),//合同模板
];
