import React, { Component } from 'react';
import { Table, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import styles from './LiveTable.module.scss';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell, switchCell, cellWidth } from '@/components/TableCell';
import { isEmpty } from '@/assets/js/utils';
import http from '@/assets/api/http';

class GroupTable extends Component {

    //初始化
    constructor(props) {
        super(props);
        this.state = {};
    }

    //props校验
    static propTypes = {
        scrollY: PropTypes.string,
        renderData: PropTypes.object.isRequired, // 表格数据源
        tableAction: PropTypes.func.isRequired, // 表格操作
        paginationChange: PropTypes.func.isRequired, // 分页操作
    };

    //表头
    _columns = () => [
        {
            title: '手机号码',
            dataIndex: 'tel',
            key: 'tel',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '主播昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '封面',
            dataIndex: 'coverPage',
            key: 'coverPage',
            width: cellWidth.normal,
            align: 'left',
            render: ImgCell
        },
        {
            title: '直播间标题',
            dataIndex: 'broadcasTitle',
            key: 'broadcasTitle',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '直播课程',
            dataIndex: 'courseName',
            key: 'courseName',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '直播状态',
            dataIndex: 'liveState.value',
            key: 'liveState.value',
            width: cellWidth.normal,
            align: 'left'
        },
        {
            title: '回放状态',
            dataIndex: 'isDelete.value',
            key: 'isDelete.value',
            width: cellWidth.normal,
            align: 'left'
        },
        {
            title: '观看人数',
            dataIndex: 'viewNumber',
            key: 'viewNumber',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '魅力值收入',
            dataIndex: 'starlightNumber',
            key: 'starlightNumber',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: (text, record) => <a onClick={() => this.props.tableAction('starLight', 'goto', record)}>{text}</a>
        },
        {
            title: '人气值收入',
            dataIndex: 'starValueNumber',
            key: 'starValueNumber',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: (text, record) => <a onClick={() => this.props.tableAction('starValue', 'goto', record)}>{text}</a>
        },
        {
            title: '本场违规',
            dataIndex: 'detectNum',
            key: 'detectNum',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: (text, record) => this._violation(text, record)
        },
        {
            title: '直播时间',
            dataIndex: 'startTime',
            key: 'startTime',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: (text, record) => tooltip(<span>{record.startTime}<br />{record.endTime}</span>)
        },
        {
            title: '推荐',
            dataIndex: 'isReco',
            key: 'isReco',
            width: cellWidth.normal,
            align: 'left',
            render: (text, record) => switchCell(text, () => this.props.tableAction(record.liveId, 'reco', record))
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'left',
            fixed: 'right',
            width: 250,
            render: (id, record) => this._tableAction(record.liveId, record)
        },
    ];

    //处理返回数据
    _takeDataSource = (data) => {
        return data ? data : [];
    }

    //表格操作
    _tableAction = (id, item) => {
        const { tableAction } = this.props;

        let isLive = item.liveState.code == 'LIVE';

        if (item.liveState.value == '未开播' && item.isBoss == 1) {
            return (
                <div className={styles.action}>
                    <a onClick={() => tableAction(id, 'sign', item)}>签到查看</a>
                    <div className={styles.item} onClick={() => tableAction(id, 'delete', item)}>删除</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'start', item)}>开启直播</div>
                </div>
            );
        } else if (item.liveState.value == '直播中' && item.isBoss == 1) {
            return (
                <div className={styles.action}>
                    <a onClick={() => tableAction(id, 'play', item)}>{isLive ? '播放' : '回放'}</a>
                    <a onClick={() => tableAction(id, 'sign', item)}>签到查看</a>
                    <a onClick={() => tableAction(id, 'upperlower', item)}>{item.isDelete.code == '1' ? '上架' : '下架'}</a>
                    <div className={styles.item} onClick={() => tableAction(id, 'delete', item)}>删除</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'stop', item)}>停止直播</div>
                </div>
            );
        } else {
            return (
                <div className={styles.action}>
                    <a onClick={() => tableAction(id, 'play', item)}>{isLive ? '播放' : '回放'}</a>
                    <a onClick={() => tableAction(id, 'sign', item)}>签到查看</a>
                    {isLive ? null : <a onClick={() => tableAction(id, 'upperlower', item)}>{item.isDelete.code == '1' ? '上架' : '下架'}</a>}
                </div>
            );
        }
    };

    //本场违规
    _violation = (text, record = {}) => {
        return (
            <div className={styles.violation}>
                <div className={styles.item} onClick={() => { this.ViolationClickHandle(record); }}>
                    {this._vtooltip(text, record)}
                </div>
            </div>
        );
    };
    _vtooltip = (value, record) => {
        return <Tooltip placement="top" title={<pre className={styles.VTitleStyles}>{value}</pre>}>
            <div className={styles.layout}>
                <pre className={styles.VPreStyles}>
                    {isEmpty(value) ? '-' : value}
                </pre>
                {record.isDetectRead == '0' && <span className={styles.curt}>未读</span>}
            </div>
        </Tooltip>;
    };

    //更新直播间为已读违规记录
    ViolationClickHandle = (record) => {
        this.props.tableAction(record.liveId, 'violation', record);
        if (record.isDetectRead == '0') {
            http('/show/live/readLiveDetectRecord', {
                liveType: 'BUNIESS_LIVE_BORADCAS_CLASS',//商学院直播
                liveId: record.liveId,
            }, 'POST').then(res => { });
        }
    }

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

export default XTableHOC(GroupTable);