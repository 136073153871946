/**
 * 审核详情弹框
 */
import React from 'react';
import { connect } from 'react-redux';
import { Modal, Input, Radio, Alert } from 'antd';
import PropTypes from 'prop-types';
import { XMediaFn } from '@/components/xqxc_ui';
import styles from './AdvocacyInfo.module.scss';
import TableContent from '../conpontents/TableContent';
import { getVideoAuditDetail } from '@/reducers/auditmanage/videoAudit/actions';

const RadioGroup = Radio.Group;
const { TextArea } = Input;

class AdvocacyInfo extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        updata: true,
        videoDetail: { state: {}, getSpokesmanTaskResp: { rewardType: {} }, goodsList: [] }
    }

    componentDidMount() {
        const { audit } = this.props;
        this.props.getVideoAuditDetail({ accountId: audit.accountId, feedId: audit.id, showType: audit.showType });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.state.updata && this.setState({
            updata: false, videoDetail: nextProps.videoDetail
        });
    }

    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    render() {
        const { visible, confirmLoading, videoDetail } = this.state;
        return (
            <Modal
                width={1000}
                bodyStyle={{ paddingRight: 10 }}
                centered
                title='视频详情'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <header>
                        <Alert message="审核通过后奖金将发放给用户" banner={true} type="warning" showIcon />
                    </header>
                    <main>
                        <article className={styles.taskInfo}>
                            <div className={styles.textInfo}>
                                <div className={styles.infoBox}>
                                    <p className={styles.label}>会员账号：</p>
                                    <p className={styles.text}>{videoDetail.accountName}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.label}>任务名称：</p>
                                    <p className={styles.text}>{videoDetail.getSpokesmanTaskResp.taskName}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.label}>任务等级：</p>
                                    <p className={styles.text}>{videoDetail.getSpokesmanTaskResp.targetUsersDesc}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.label}>是否专场：</p>
                                    <p className={styles.text}>{videoDetail.getSpokesmanTaskResp.isSpecial == '1' ? '是' : '否'}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.label}>任务要求：</p>
                                    <p className={styles.text}>{videoDetail.getSpokesmanTaskResp.taskLengthDesc}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.label}>任务奖励：</p>
                                    <p className={styles.text}>{videoDetail.getSpokesmanTaskResp.rewardNum}{videoDetail.getSpokesmanTaskResp.rewardTypeDesc}</p>
                                </div>
                                <div className={styles.explan}>
                                    <p className={styles.label}>任务说明：</p>
                                    <p className={styles.remark}>{videoDetail.getSpokesmanTaskResp.taskDesc}</p>
                                </div>
                            </div>
                            <div className={styles.imgInfo}>
                                <div className={styles.image}>
                                    <XMediaFn dataSource={videoDetail.getSpokesmanTaskResp.mainImgUrl}
                                    />
                                    <p className={styles.imgText}>{videoDetail.getSpokesmanTaskResp.goodsName}</p>
                                </div>
                                {
                                    videoDetail.getSpokesmanTaskResp.demoUrl && <XMediaFn
                                        dataType='video'
                                        dataSource={videoDetail.getSpokesmanTaskResp.demoUrl}
                                    />
                                }
                            </div>
                        </article>
                        <div className={styles.line}></div>
                        <article className={styles.auditInfo}>
                            <div className={styles.imgInfo}>
                                <div style={{ display: 'flex' }}>
                                    <p className={styles.title}>提交封面：</p>
                                    <XMediaFn style={{ marginTop: '10px' }} dataSource={videoDetail.coverPage} />
                                </div>
                                <div style={{ display: 'flex', marginTop: 20 }}>
                                    <p className={styles.title}>提交视频：</p>
                                    <XMediaFn
                                        dataType='video'
                                        dataSource={videoDetail.fileName}
                                    />
                                </div>
                            </div>
                            <div className={styles.handelInfo}>
                                <div className={styles.infoBox}>
                                    <p className={styles.title}>视频标题：</p>
                                    <p className={styles.text}>{videoDetail.feedTitle}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.title}>视频分类：</p>
                                    <p className={styles.text}>{videoDetail.className}</p>
                                </div>
                                {/* <div className={styles.infoBox}>
                                    <p className={styles.title}>视频描述：</p>
                                    <p className={styles.text}>{videoDetail.content}</p>
                                </div> */}
                                <div className={styles.infoBox}>
                                    <p className={styles.title}>任务归属：</p>
                                    <p className={styles.text}>{videoDetail.systemType == '商户' ? videoDetail.systemType + '（' + videoDetail.merchantName + '）' : videoDetail.systemType}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.title}>审核状态：</p>
                                    <RadioGroup value={videoDetail.state.code} style={{ marginLeft: '10px', marginTop: '10px' }}>
                                        {/* <Radio value={'BANNED'}>禁播</Radio> */}
                                        {/* <Radio value={'APPLY'}>审批中</Radio> */}
                                        <Radio value={'APPROVAL'}>审批通过</Radio>
                                        <Radio value={'REJECT'}>不通过</Radio>
                                    </RadioGroup>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.title}>审核意见：</p>
                                    <TextArea
                                        value={videoDetail.remark}
                                        style={{ marginTop: '10px' }}
                                        placeholder="请输入不通过原因"
                                        autosize={{ minRows: 3, maxRows: 6 }}
                                    />
                                </div>
                            </div>
                        </article>
                        <article className={styles.goodsInfo}>
                            <TableContent renderData={videoDetail.goodsList} />
                        </article>
                    </main>
                </div>
            </Modal>
        );
    }
}

AdvocacyInfo.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getVideoAuditDetail: PropTypes.func.isRequired,
    videoDetail: PropTypes.object,
    audit: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    videoDetail: state.videoAudit.videoAuditDetail
});

export default connect(mapStateToProps, { getVideoAuditDetail })(AdvocacyInfo);