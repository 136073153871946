/**
 * 查看物流弹框
 */
import React, { useEffect, useState } from 'react';
import { Input, message, Modal, Radio, Select, Typography, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';

const { Text } = Typography;

const refundTypes = [{ code: 0, value: '延迟退单' }, { code: 1, value: '立即退单' }];

const Chargeback = ({ title = '', orderId = '', closeMode, autoRefundTime = '' }) => {

    const [reasons, setReasons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState({ idList: [], refundType: 0, reason: undefined });
    const [visible0, setVisible0] = useState(false);
    const [visible1, setVisible1] = useState(false);

    useEffect(() => {
        http('/enum/SystemAutoRefundReasonEnum', {}, 'POST')
            .then(res => setReasons(res.result));
    }, []);

    const paramsChange = (data = {}) => {
        const callBack = pre => {
            return { ...pre, ...data };
        };
        setParams(callBack);
    };

    const onSubmit = () => {
        if (!params.reason) return message.warn('请选择原因');
        if (!orderId && !params.idList.length) return message.warn('请输入订单编号');
        setLoading(true);
        http('/boss/order/refundWithList', { ...params, idList: orderId ? [orderId] : params.idList.replace(/，/g, ',').split(',') }, 'POST')
            .then(res => {
                message.success(res.message); setLoading(false); closeMode('update');
            })
            .catch(err => {
                message.error(err.message); setLoading(false);
            });
    };

    const itemStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px'
    };

    const labelStyle = {
        width: '100px',
        textAlign: 'right'
    };

    const otherStyle = {
        width: '400px',
    };

    return <Modal width={540} centered title={title} visible={true} confirmLoading={loading} bodyStyle={{ padding: '15px' }}
        onCancel={() => closeMode()} destroyOnClose={true} keyboard={true} maskClosable={false} okText='确定' cancelText='取消' onOk={onSubmit}
    >
        <div>
            <div style={itemStyle}>
                <div style={labelStyle}><Text type='danger' strong>*</Text><Text>退款方式：</Text></div>
                <div style={otherStyle}>
                    <Radio.Group value={params.refundType} onChange={e => paramsChange({ refundType: e.target.value })}>
                        {refundTypes.map(i => {
                            return <Radio key={i.code} value={i.code}>{i.value}</Radio>;
                        })}
                    </Radio.Group>
                </div>
            </div>
            {!orderId && <div style={itemStyle}>
                <div style={labelStyle}><Text type='danger' strong>*</Text><Text>订单编号：</Text></div>
                <Input.TextArea value={params.idList} style={otherStyle} placeholder='可输入多个订单编号，以逗号隔开' onChange={e => {
                    paramsChange({ idList: e.target.value });
                }} />
            </div>}
            <div style={itemStyle}>
                <div style={labelStyle}> <Text type='danger' strong>*</Text><Text>退单原因：</Text></div>
                {/* <div style={msgStyle}>
                      您购买的[%商品名称，取订单第一个商品名称%]，由于 */}
                <Select value={params.reason} style={{ width: '200px', }} placeholder='请选择' onChange={reason => paramsChange({ reason })}>
                    {reasons.map(i => {
                        return <Select.Option key={i.code} value={i.code}>{i.value}</Select.Option>;
                    })}
                </Select>
                {/* 无法发货，请在[%时间数值%]小时内完成退款，超过时间平台将自动退款处理，详情咨询平台客服4001-358-587。
                  </div> */}
            </div>
            {
                params.refundType == 0 ?
                    <div>
                        确定后将发送<Tooltip placement='topLeft' trigger='click' visible={visible0} onVisibleChange={() => setVisible0(pre => (!pre))}
                            title={<div>
                                短信内容：<br />
                                您购买的  [%商品名称，任取订单中某个商品名称%]，由于[%选择的原因%]无法发货，请在[%时间数值%]小时内完成退款，超过时间平台将自动退款处理，详情咨询平台客服4001-358-587。
                            </div>} arrowPointAtCenter
                        >
                            <span style={{ cursor: 'pointer', color: '#02A7F0' }}>短信</span>
                        </Tooltip>给下单用户，若用户未在{autoRefundTime}内申请退款，系统将自动执行取消订单并原路退回用户所支付的订单金额！
                    </div>
                    : <div>
                        确定后将立即发送<Tooltip placement='topLeft' trigger='click' visible={visible1} onVisibleChange={() => setVisible1(pre => (!pre))}
                            title={<div>
                                短信内容：<br />
                                您购买的  [%商品名称，任取订单中某个商品名称%]，由于[%选择的原因%]无法发货，平台已发起自，预计24小时到支付账户，如有疑问可致电平台客服4001-358-587。
                            </div>} arrowPointAtCenter
                        >
                            <span style={{ cursor: 'pointer', color: '#02A7F0' }}>短信</span>
                        </Tooltip>给下单用户，并立即执行取消订单，原路退回用户所支付的订单金额！
                    </div>
            }
        </div>
    </Modal >;
};
Chargeback.propTypes = {
    title: PropTypes.string,
    orderId: PropTypes.string,
    closeMode: PropTypes.func, // 关闭弹框的回调函数
    autoRefundTime: PropTypes.string
};
export default Chargeback; 