/**
 * 【收支明细】
 */
import React from 'react';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XOKButton } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/marketingamountmanage/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';
import ExchangeModal from './modal/ExchangeModal';
import { getLocalStorage } from '@/assets/js/storage';
import { message } from 'antd';
import { isOC, isRE } from '@/assets/js/authType';


class Main extends React.Component {
    state = {
        renderMode: '',
        modeParams: {},
        curTabID: 1,  // 当前显示的Tab页ID

        companyEarningsSummary: {
            integralBalance: 0,
            totalIntegral: 0,
            consumeIntegralBalance: 0
        },
        subjectId: '',
        subjectType: ''
    }

    constructor(props) {
        super(props);
        const { userInfo } = JSON.parse(getLocalStorage('auth'));

        this.state.subjectId = userInfo.companyId;

        if (isOC()) {
            this.state.subjectType = 'OP_CENTER';
        }

        if (isRE()) {
            this.state.subjectType = 'REGION_COMPANY';
        }
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.getCountInfo();
    }

    getCountInfo = () => {
        http('/admin/company/getCompanyEarningsSummary', {
            subjectId: this.state.subjectId,
            subjectType: this.state.subjectType
        }).then(res => {
            if (res.code === 200) {
                this.setState({
                    companyEarningsSummary: { ...res.result },
                });
            }
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, stateItem } = this.state;
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getDrawIncomeList({
            ...this.searchCache,
            subjectId: this.state.subjectId,
            subjectType: this.state.subjectType
        });
        KeepAlive.saveCache({ ...this.searchCache, stateItem }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            pageNum: 1,
            pageSize: 10
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    _exchangeKey = () => {
        if (this.state.companyEarningsSummary.integralBalance > 0) {
            this.setState({ renderMode: 'exchange', modeParams: this.state.companyEarningsSummary });
        } else {
            message.warning('暂无特权值可兑换');
        }
    }

    // 渲染弹框
    _renderMode = () => {
        const { renderMode, modeParams } = this.state;
        switch (renderMode) {
            case 'exchange':
                return <ExchangeModal modeParams={modeParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ renderMode: '', modeParams: {} }, () => {
            if (update) {
                this.getCountInfo();
            }
        });
    }


    render() {
        const { curTabID, companyEarningsSummary } = this.state;
        const { drawIncomeList } = this.props;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.count_box}>
                        <div>可用特权值：{companyEarningsSummary.integralBalance}</div>
                        <div>总获取特权值：{companyEarningsSummary.totalIntegral}</div>
                        <div>总支出特权值：{companyEarningsSummary.consumeIntegralBalance}</div>
                    </div>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: 150 }}
                            label='兑换股票收益'
                            onClick={this._exchangeKey}
                        />
                    </div>
                    <OrderTable renderData={drawIncomeList} tableAction={this._tableAction} paginationChange={this._paginationChange} curTabID={curTabID} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getDrawIncomeList: PropTypes.func,
    drawIncomeList: PropTypes.object
};
const mapStateToProps = (state) => ({
    drawIncomeList: state.marketingamountmanage.drawIncomeList
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
