import React from 'react';
import styles from './TableStyle.module.scss';
import PropTypes from 'prop-types';
import { Table, Tag, Tooltip, Typography } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { priceFenToYuanCell, tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

const { Text } = Typography;

const TableContent = ({ renderData, tableAction, paginationChange }) => {

    //表头
    const _columns = () => {
        return [
            {
                title: '房源封面',
                dataIndex: 'mainImgUrl',
                key: 'mainImgUrl',
                width: '10%',
                align: 'center',
                render: ImgCell
            },
            {
                title: '房源名称',
                dataIndex: 'goodsName',
                key: 'goodsName ',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '房源房型',
                dataIndex: 'categoryName',
                key: 'categoryName',
                width: '8%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '归属商户',
                dataIndex: 'merchantName',
                key: 'merchantName',
                align: 'center',
                width: '8%',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '基础设施',
                dataIndex: 'installationList',
                key: 'installationList',
                width: '16%',
                align: 'center',
                render: _tagCell
            },
            {
                title: '房源价格（元）',
                dataIndex: 'marketPrice',
                key: 'marketPrice',
                width: '10%',
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '房源最低平台价（元）',
                dataIndex: 'minPrice',
                key: 'minPrice',
                width: '15%',
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '状态',
                dataIndex: 'goodsState',
                key: 'goodsState',
                width: '8%',
                align: 'center',
                onCell: tooltipStyle,
                render: (text) => tooltip(text ? text.value : '')
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                width: '10%',
                align: 'center',
                render: _tableAction
            }
        ];
    };

    //标签显示
    const _tagCell = (tags) => {
        let tagList = [];
        let tagArr = [];
        for (let tag of tags) {
            const keys = Object.keys(tag);
            const values = Object.values(tag);
            let item = {};
            item.id = keys[0];
            item.value = values[0];
            tagList.push(item);
            tagArr.push(values[0]);
        }
        const newTags = tagList.length > 2 ? [...tagList.slice(0, 2), { id: 0, value: '...' }] : tagList;
        const tipInfo = tagArr.join(' ');
        return <div>
            {
                newTags.length > 2 ?
                    newTags.map(tag => {
                        if (tag.id) {
                            return <Tag color="blue" key={tag.id}>
                                {tag.value}
                            </Tag>;
                        } else {
                            return (
                                <Tooltip key={tag.id} placement="topRight" title={tipInfo} >
                                    <Tag color="blue" key={tag.id}>
                                        {tag.value}
                                    </Tag>
                                </Tooltip>
                            );
                        }
                    }) :
                    tagList.map(tag => (
                        <Tag color="blue" key={tag.id}>
                            {tag.value}
                        </Tag>
                    ))
            }
        </div>;
    };

    //表格操作
    const _tableAction = (id, item) => {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, 'detail', item)}>详情</div>
                {
                    item.goodsState.code !== 'DISABLE' && <div className={styles.item} onClick={() => tableAction(id, 'banHouse', item)}>禁用</div>
                }
            </div>
        );
    };

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.id}
                columns={_columns()}
                dataSource={renderData.dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange} />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

export default TableContent;