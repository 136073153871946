/**
 * 在线播放弹框
 */
import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import styles from './OnlinePlayModal.module.scss';


class OnlinePlayModal extends React.Component {
    constructor(props) {
        super(props);
        this.player = React.createRef(); // 创建ref, 存储 player DOM 元素
    }
    state = {
        visible: this.props.visible,
        confirmLoading: false,
        videoError: true,//请求错误
    }

    componentDidMount() {
        const { data, urlParam } = this.props;
        urlParam == '直播间' && this._playM3U8(data);
    }
    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }


    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    //请求响应错误
    _handleImageErrored = () => {
        this.setState({
            videoError: false,
        });
    }
    _playM3U8 = (url) => {
        setTimeout(() => {
            var container = this.player.current;
            // 支持MP4或M3U8格式
            // eslint-disable-next-line no-undef
            new QPlayer({
                url,
                container: container,
                autoplay: true,
            });
        }, 100);
    }

    render() {
        const { visible, confirmLoading, videoError, } = this.state;
        const { data, urlParam } = this.props;
        return (
            <Modal
                centered
                width={urlParam == '直播间' ? 800 : 520}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='关闭'
                okText='确认'
            >
                <div className={styles.container}>
                    {(data != '' && urlParam == '直播间') && <div ref={this.player} style={{ width: '740px', height: '600px' }} />}
                    {(videoError && urlParam == '短视频') && <video width="100%" height="100%" src={data} controls preload='auto' onError={this._handleImageErrored.bind(this)}></video>}
                    {(!videoError || data == '') && <div className={styles.error}><img src={require('@/assets/image/nodata/xingqiuNodataM.png')} /><p>暂无数据！</p></div>}
                </div>

            </Modal>
        );
    }
}

OnlinePlayModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    data: PropTypes.string,//入参数据
    urlParam: PropTypes.string,// 区分短视频、直播
};

export default OnlinePlayModal;