/**
 * 支付配置
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message, Radio } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import PayTable from './components/PayTable';
import PayModal from './modal/PayModal';
import { XOKButton, XCancelButton, XSelect, XInputNum, XInput } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/systemmanage/paymentChannel/model';
import { SearchBox, SearchItem } from '@/components/Layout';
import { regFenToYuan, regYuanToFen } from '@/util/money';

const RadioGroup = Radio.Group;

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: [], // 当前需要打开的弹窗的入参
            selectedRowKeys: [],//对应的key数据
            selectedRows: [],//对应的key行数据
            merchantType: {},//商户类型
            payway: {},//支付通道
            merchantName: {},//商户名称 
            paymentshow: 'THIRD_UNION_PAY', // 支付通道（初始默认通联）
            payforValue: '', // 大额交易
            saveLoading: false,
            dataType: undefined,//是否批量（undefined）和单个（{...data}）
            day: 0,
            withdrawAmount: '',//最小提现金额
        };
    }
    componentDidMount() {
        const { getSavaPayment, getPaywayList, getMerchantType, getMerchantSelect } = this.props;
        getSavaPayment();//获取支付通道，大额交易初始默认数据
        getPaywayList();//支付通道(通联、银联)
        getMerchantType();//商户类型
        getMerchantSelect();//商户名称下拉
    }

    componentDidUpdate(prevProps) {
        if (this.props.savaPayment !== prevProps.savaPayment) {
            const { savaPayment: { accessType, largeSumPay, withdrawAmount, day } = {} } = this.props;
            this.setState({
                paymentshow: accessType, // 支付通道（初始默认通联）
                payforValue: regFenToYuan(largeSumPay), // 大额交易
                withdrawAmount: regFenToYuan(withdrawAmount),//最小提现金额
                day
            });
        }
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, payway, merchantType, merchantName, tel } = this.state;
        this.searchCache = {
            tel,
            merchantId: merchantName.merchantId,//商户名称
            merchantType: merchantType.code,//商户类型
            accessType: payway.code,//支付通道
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getChannelList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ payway: {}, merchantType: {}, merchantName: {}, tel: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 切换通道
    _batchSwitchOnHandle = () => {
        const { selectedRowKeys } = this.state;
        if (selectedRowKeys.length === 0) return message.warning('请勾选信息');
        this.setState({
            currentOpenMode: 'switch',
            openModeParam: selectedRowKeys,//商户id集合[]
        });
    }

    //获取选中数据
    selectedRowsHandle = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getChannelList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    //表格switch请求
    httpSwitchSum = (url, data, tooltip, boolType = false, callback, failEvent) => {
        http(url, data, 'POST')
            .then((res = {}) => {
                if (typeof callback === 'function') callback();
                boolType && this.props.getChannelList(this.searchCache);
                message.success(tooltip, 3, () => {
                    const { result: { isSetError, merchantNames = [] } = {} } = res;
                    if (isSetError == '1') {
                        message.warning(`设置失败的商户名称有：${Array.isArray(merchantNames) ? merchantNames.join() : ''}`);
                    }
                });
            }).catch((reject = {}) => {
                typeof failEvent === 'function' && failEvent();
                message.error(reject.message);
            });
    }


    // 表格操作 
    _tableAction = (type, value) => {
        switch (type) {
            case 'tdswitch'://支付通道
                this.setState({
                    selectedRowKeys: [],
                    currentOpenMode: 'switch',
                    openModeParam: [value.merchantId],//商户id集合[]
                    dataType: value
                }, () => {
                    this.payRef.onClearSelctRows();//清除表格勾选数据
                });
                break;
            case 'openAndBan':
            case 'timeChange':
                http('/payment/config/setPaymentAccessTypeConfig', { ...value, operaType: 'MERCHANT' }, 'POST')
                    .then(() => {
                        type == 'openAndBan' ?
                            message.success(value.status == 1 ? '启用操作成功' : '禁用操作成功') :
                            message.success('修改银联入网最晚时间操作成功');
                        this._searchHandle('useCache');
                    }).catch(() => {
                        type == 'openAndBan' ?
                            message.error(value.status == 1 ? '启用操作失败' : '禁用操作失败') :
                            message.error('修改银联入网最晚时间操作失败');
                    });
                break;
        }
    }

    renderModal = () => {
        const { currentOpenMode, openModeParam, dataType } = this.state;
        switch (currentOpenMode) {
            case 'switch':
                return <PayModal title='通道切换' visible={true} closeMode={this._closeMode} data={openModeParam} dataType={dataType} />;
            default:
                return null;
        }
    }
    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' }, () => {
            this.state.selectedRowKeys.length > 0 && this.payRef.onClearSelctRows();//清除表格勾选数据
        });
        if (status) {
            this.props.getChannelList(this.searchCache);
        }
    }

    //支付通道 
    onPaymentChange = e => {
        this.setState({
            paymentshow: e.target.value,
        });
    };

    // 保存
    onSaveHandle = () => {
        const { paymentshow, payforValue, day, withdrawAmount } = this.state;
        // if (payforValue === null) {
        //     return message.warning('请输入大额交易金额');
        // }
        let submitData = {
            accessType: paymentshow,
            operaType: 'GLOBAL',
            // largeSumPay: regYuanToFen(payforValue),
            day,
            withdrawAmount: regYuanToFen(withdrawAmount)
        };
        this.setState({
            saveLoading: true
        }, () => {
            this.httpSwitchSum('/payment/config/setPaymentAccessTypeConfig', submitData, '保存成功', false, () => {
                this.props.getSavaPayment();
                this.setState({ saveLoading: false });
            }, () => {
                this.props.getSavaPayment();
                this.setState({ saveLoading: false });
            });
        });
    }

    //过滤支付通道
    paywaylFilter = (list) => {
        if (list.length === 0) {
            return;
        }
        const { paymentshow } = this.state;
        let data = list.filter(v => v.code == paymentshow);
        return data[0] ? data[0].value : '';
    }

    render() {
        const { payway, merchantType, merchantName, paymentshow, tel, saveLoading, day, withdrawAmount } = this.state;
        const { channelList, paywayList, merchantTypeList, merchantSelect: { merchantIdAndNames } } = this.props;

        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.riskcontent}>
                        <div className={styles.item}>
                            <div className={styles.inner}>
                                <label className={styles.name}>支付通道：</label>
                                <div className={styles.info}>
                                    <RadioGroup onChange={this.onPaymentChange} value={paymentshow}>
                                        {paywayList.map(v => <Radio key={v.code} value={v.code}>{v.value}</Radio>)}
                                    </RadioGroup>
                                </div>
                            </div>
                            <div className={styles.inner}>
                                <label className={styles.name}>银联入网时间：</label>
                                连连开通后
                                <XInputNum
                                    inputStyle={{ width: '110px', }}
                                    placeholder='请输入'
                                    value={day}
                                    bindThis={this}
                                    bindName='day'
                                    precision={0}
                                    min={0}
                                    max={999}
                                />
                                天内需要完成银联入网，否则关闭交易及提现
                            </div>
                        </div>
                        <div className={styles.item}>
                            {/* <div className={styles.inner}>
                                <label className={styles.name}>大额交易：</label>
                                <span className={styles.texts}>{this.paywaylFilter(paywayList)}，金额大于</span>
                                <div className={styles.info}>
                                    <XInputNum
                                        label='银联，金额大于'
                                        inputStyle={{ width: '110px', }}
                                        placeholder='请输入(元)'
                                        value={payforValue}
                                        bindThis={this}
                                        bindName='payforValue'
                                        precision={0}
                                        min={0}
                                        max={99999}
                                    />
                                </div>
                            </div> */}
                            <div className={styles.inner}>
                                <XInputNum
                                    label='结算金额大于'
                                    inputStyle={{ width: '110px', }}
                                    placeholder='请输入(元)'
                                    value={withdrawAmount}
                                    bindThis={this}
                                    bindName='withdrawAmount'
                                    precision={0}
                                    min={0}
                                    max={999999}
                                />
                                <label className={styles.name}>自动提现到商户实体账户中</label>
                            </div>
                        </div>
                        <XOKButton
                            style={{ width: '100px', marginTop: '10px' }}
                            label='保存'
                            loading={saveLoading}
                            onClick={this.onSaveHandle}
                        />
                    </div>

                    <div className={styles.searchBox}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='商户账号'
                                    placeholder='请输入'
                                    value={tel}
                                    bindThis={this}
                                    bindName='tel'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='商户名称'
                                    placeholder='请选择'
                                    renderData={merchantIdAndNames}
                                    dataIndex='merchantName'
                                    keyIndex='merchantId'
                                    showSearch
                                    value={merchantName.merchantName}
                                    bindThis={this}
                                    bindName='merchantName'
                                    isOptionBindID={true}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='商户类型'
                                    placeholder='请选择'
                                    renderData={merchantTypeList}
                                    dataIndex='value'
                                    keyIndex='code'
                                    showSearch
                                    value={merchantType.value}
                                    bindThis={this}
                                    bindName='merchantType'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='支付通道'
                                    placeholder='请选择'
                                    renderData={paywayList}
                                    dataIndex='value'
                                    keyIndex='code'
                                    showSearch
                                    value={payway.value}
                                    bindThis={this}
                                    bindName='payway'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: '100px' }}
                            label='批量切换'
                            onClick={this._batchSwitchOnHandle}
                        />
                    </div>
                    <PayTable
                        ref={(payRef) => this.payRef = payRef}
                        renderData={channelList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                        selectedRowsHandle={this.selectedRowsHandle}
                        paywayList={paywayList}
                    />
                    {this.renderModal()}
                </div>
            </KeepAlive>
        );
    }
}


Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    channelList: PropTypes.object,
    getChannelList: PropTypes.func,//支付通道列表(action)
    savaPayment: PropTypes.object,
    getSavaPayment: PropTypes.func,//获取支付通道，大额交易初始默认数据(action)
    paywayList: PropTypes.array,
    getPaywayList: PropTypes.func,//支付通道(通联、银联)(action)
    merchantTypeList: PropTypes.array,
    getMerchantType: PropTypes.func,//商户类型(action)
    merchantSelect: PropTypes.object,
    getMerchantSelect: PropTypes.func,//商户名称下拉(action)
};

const mapStateToProps = (state) => ({
    channelList: state.paymentChannel.channelList, // 支付通道列表
    savaPayment: state.paymentChannel.savaPayment, // 获取支付通道，大额交易初始默认数据
    paywayList: state.paymentChannel.paywayList, // 支付通道(通联、银联)
    merchantTypeList: state.paymentChannel.merchantTypeList, // 支付通道(通联、银联)
    merchantSelect: state.paymentChannel.merchantSelect, // 商户名称下拉
});

export default connect(mapStateToProps, { ...model.actions })(Main);
