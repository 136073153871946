import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { regFenToYuan } from '@/util/money';
import { Icon, Tooltip } from 'antd';
import SubsidiesQueue from './SubsidiesQueue';
import Settled from './Settled';
import Settling from './Settling';

const poolTypeList = [
    { id: 'SUBSIDY_MEMBER', label: '每日补贴(会员)' },
    { id: 'SUBSIDY_LARGE', label: '每日补贴(大额单)' },
    { id: 'SUBSIDY_MERCHANT', label: '商户补贴' },
    { id: 'SUBSIDY_MERCHANT_MEMBER', label: '会员补贴' },
    { id: 'DAILY_SUBSIDY_MEMBER', label: '每日补贴(自购用户)' },
    { id: 'DAILY_SUBSIDY_SERVICER', label: '每日补贴(服务商)' },
    { id: 'DAILY_SUBSIDY_REFERRAL', label: '每日补贴(推荐)' },
];
const stateList = [
    { id: 'WAITING_SUBSIDY', label: '待补贴队列' },
    { id: 'SETTLED', label: '已结算补贴' },
    { id: 'WAITING_SETTLE,REFUNDED', label: '待结算补贴' }
];

const getCardList = (poolType) => {
    return http('/marketing/orderSubsidy/statsSubsidyAmount', { poolType }, 'POST')
        .then(({ result = {} }) => {
            return {
                ...result, totalAmount: regFenToYuan(result.totalAmount),
                waitingSettleAmount: regFenToYuan(result.waitingSettleAmount),
                settledAmount: regFenToYuan(result.settledAmount)
            };
        });
};

const SubsidiesIncome = ({ history }) => {

    const [poolType, setPoolType] = useState('SUBSIDY_MEMBER');
    const [subsidyStates, setSubsidyStates] = useState('WAITING_SUBSIDY');
    const [cardList, setCardList] = useState([
        { id: 'totalAmount', title: '总补贴金额', value: 0, tip: '显示当前补贴收益剩余可发放金额', color: '#05294c' },
        { id: 'waitingSettleAmount', title: '待结算补贴', value: 0, code: 'WAITING_SETTLE,REFUNDED', tip: '会员支付完成的商品订单补贴金额总额；当订单发生退款应扣除，显示即将进入补贴池的总金额（预计补贴收益）点击金额，可查看明细列表', color: '#f96c7d' },
        { id: 'settledAmount', title: '已结算补贴', value: 0, code: 'SETTLED', tip: '已完成的订单补贴金额总额记录每日说会员获得平台补贴总金额（发放进关联会员钱包总补贴）点击金额，可查看明细列表', color: '#39afff' },
        { id: 'waitingSubsidyOrderNum', title: '补贴队列', code: 'WAITING_SUBSIDY', value: 0, tip: '记录当前会员通过“补贴活动(会员)”购买商品订单成功后（过七天售后期后，不可退款）加入队列', color: '#39afff' },
    ]);

    useEffect(() => {
        (async () => {
            const result = await getCardList(poolType);
            const callback = pre => {
                return pre.map(i => {
                    return {
                        ...i, value: result[i.id],
                        waitingSettleOrderNum: result.waitingSettleOrderNum,
                        settledOrderNum: result.settledOrderNum,
                    };
                });
            };
            setCardList(callback);
        })();
    }, [poolType]);

    const hasOrderTypes = ['waitingSettleAmount', 'settledAmount'];
    const cprops = { history, poolType, subsidyStates: subsidyStates.split(',') };
    const components = {
        'WAITING_SUBSIDY': <SubsidiesQueue {...cprops} />,
        'SETTLED': <Settled {...cprops} />,
        'WAITING_SETTLE,REFUNDED': <Settling {...cprops} />
    };

    const poolTypeChange = (v) => {
        setPoolType(v); setSubsidyStates('WAITING_SUBSIDY');
    };

    return <div className={styles.flexBoxContainer}>
        <XTabNav renderData={poolTypeList} onChange={poolTypeChange} activedID={poolType} />
        <div className={styles.header}>
            {cardList.map(i => {
                return <div style={{ backgroundColor: i.color }} key={i.id} className={styles.card}
                    onClick={() => { if (i.code) setSubsidyStates(i.code); }}>
                    <div className={styles.title}>{i.title}<Tooltip style={{ marginLeft: '10px' }} placement="right" title={i.tip}>
                        <Icon type="question-circle" theme="filled" style={{ cursor: 'pointer', marginLeft: '5px' }} />
                    </Tooltip></div>
                    <div className={styles.total}>{i.value}({i.id === 'waitingSubsidyOrderNum' ? '单' : '元'})</div>
                    {hasOrderTypes.includes(i.id) &&
                        <div className={styles.orderNumber}>订单数量：{i.id === 'waitingSettleAmount' ?
                            i.waitingSettleOrderNum : i.settledOrderNum}(单)</div>}
                </div>;
            })}
        </div>
        <XTabNav activedID={subsidyStates} renderData={stateList} onChange={v => setSubsidyStates(v)} />
        {components[subsidyStates]}
    </div>;
};
SubsidiesIncome.propTypes = {
    history: PropTypes.object
};
export default SubsidiesIncome;