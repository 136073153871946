/**
 * 【服务商管理页】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import ServiceTable from './components/ServiceTable';
import { getBlackListTableSource } from '@/reducers/usermanage/blacklist/actions';
import model from '@/reducers/usermanage/member/model';
import KeepAlive from '@/routes/KeepAlive';
import UnlockModal from '../../modal/UnlockModal';
import { autoPageNum, autopageSize } from '@/assets/config';

const Mode = {
    Unlock: 'Unlock',
    History: 'History'
};

class Main extends React.Component {
    state = {
        tels: '',
        startTime: undefined,
        expectedEndTime: undefined,
        selectedRowKeys: [],
        currentOpenMode: '',
        openModeParam: {},
    };

    componentDidMount() {
        keepSecondNavBreadcrumb();
    }

    getCache = () => ({
        tels: this.state.tels,
        startTime: this.state.startTime,
        expectedEndTime: this.state.expectedEndTime
    });

    // 查询
    _searchHandle = (useCache) => {
        const { pageSize, pageNum } = this.state;
        this.searchCache = {
            ...this.getCache(),
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getBlackListTableSource(this.searchCache); // 发起请求
        KeepAlive.saveCache({ ...this.getCache(), pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            startTime: undefined,
            expectedEndTime: undefined,
            tels: '',
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建服务商
    _newHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建服务商',
            path: '/home/usermanage/service/create'
        });
        history.push({
            pathname: '/home/usermanage/service/create'
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    _closeMode = (isReload) => {
        this.setState({ currentOpenMode: '', openModeParam: {} });
        if (isReload) {
            this._searchHandle('useCache');
        }
    }

    // 表格操作
    _actionClickHandle = (record, type) => {

        switch (type) {
            case Mode.History:
                addOneBreadcrumbPath({
                    title: '历史记录',
                    path: '/home/usermanage/blacklist/history/' + record.accountId
                });
                this.props.history.push({
                    pathname: '/home/usermanage/blacklist/history/' + record.accountId,
                });
                break;
            case Mode.Unlock:
                this.setState({
                    currentOpenMode: Mode.Unlock,
                    openModeParam: record
                });
                break;
        }
    };

    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.Unlock:
                return <UnlockModal title='解封' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    selectedRowKeysChange = (keys) => {
        this.setState({ selectedRowKeys: keys });
    }

    render() {
        const { tels, startTime, expectedEndTime, selectedRowKeys } = this.state;

        const { blackListTable } = this.props;

        return (
            <KeepAlive id='banned' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer} style={{ marginTop: '10px' }}>
                    <div className={styles.row1}>
                        <XInput
                            inputStyle={{ width: '180px' }}
                            label='会员账号'
                            placeholder='请输入会员账号'
                            value={tels}
                            bindThis={this}
                            bindName='tels'
                        />
                        <XDatePicker
                            style={{ width: '264px', marginLeft: '20px' }}
                            label='封禁时间'
                            placeholder='选择开始时间'
                            value={startTime}
                            bindThis={this}
                            bindName='startTime'
                            isFormat
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            label=''
                            placeholder='选择结束时间'
                            value={expectedEndTime}
                            bindThis={this}
                            bindName='expectedEndTime'
                            isFormat
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <ServiceTable selectedRowKeys={selectedRowKeys} selectedRowKeysChange={this.selectedRowKeysChange} renderData={blackListTable} tableAction={this._actionClickHandle} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,

    getAccountLevel: PropTypes.func,
    getBlackListTableSource: PropTypes.func,

    blackListTable: PropTypes.object,
    accountLevels: PropTypes.array,
    isRequestPageData: PropTypes.bool
};

const mapStateToProps = (state) => ({
    blackListTable: state.blacklist.blackListTable,
    accountLevels: state.member.accountLevel
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath, keepSecondNavBreadcrumb,
    getBlackListTableSource,
    getAccountLevel: model.actions.getAccountLevel
})(Main);