/**
 * 商品管理 - 品牌异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const getStandardProduct = function* () {
    yield takeEvery(T.GET_STANDARDPRODUCT_LIST, function* requestData(action) {
        try {
            let result = yield http('/goods/baseProduct/findBaseGoodsPage', action.payload, 'POST');
            yield put({ type: T.SET_STANDARDPRODUCT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getStandardProductDetail = function* () {
    yield takeEvery(T.GET_STANDARDPRODUCT_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/goods/baseProduct/baseGoodsInfo/'+action.payload.id, null, 'POST');
            yield put({ type: T.SET_STANDARDPRODUCT_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
