import React from 'react';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell } from '@/components/TableCell';
import { XPagination, XTableHOC, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './OrderTable.module.scss';
import { isPlat } from '@/assets/js/authType';

class OrderTable extends React.Component {
    state = {
        selectedRowKeys: [],
    }

    _takeColumns = () => {
        let cols = [
            {
                title: '兑换凭证',
                dataIndex: 'id',
                key: 'id',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '兑换人',
                dataIndex: 'ownerName',
                key: 'ownerName',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '服务商',
                dataIndex: 'serviceTel',
                key: 'serviceTel',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '联系电话',
                dataIndex: 'ownerTel',
                key: 'ownerTel',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '身份证',
                dataIndex: 'idCardNo',
                key: 'idCardNo',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '兑换金额（贡献值）',
                dataIndex: 'payAmount',
                key: 'payAmount',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '兑换股数',
                dataIndex: 'handsNum',
                key: 'handsNum',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '交割手数(手)',
                dataIndex: 'deliveryRealNum',
                key: 'deliveryRealNum',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '兑换比例',
                dataIndex: 'rate',
                key: 'rate',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: priceFenToYuanCell
            },
            {
                title: '订单状态',
                dataIndex: 'exchangeStateValue',
                key: 'exchangeStateValue',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '支付时间',
                dataIndex: 'payTime',
                key: 'payTime',
                width: cellWidth.time,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '股权交割时间',
                dataIndex: 'deliveryTime',
                key: 'deliveryTime',
                width: cellWidth.time,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '实际交割时间',
                dataIndex: 'handleTime',
                key: 'handleTime',
                width: cellWidth.time,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
                width: cellWidth.big,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作',
                dataIndex: 'key',
                key: 'action',
                align: 'center',
                fixed: 'right',
                width: cellWidth.time,
                render: (text, item) => this._renderActionCell(item.id, item)
            }
        ];

        if (!isPlat()) {
            cols.pop();
        }
        return cols;
    }

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'info')}>查看详情</div>
                {
                    item.exchangeState.code == 'UNCHANGED' &&
                    <div className={styles.item} onClick={() => tableAction(key, 'delivery', item)}>交割</div>
                }
                {
                    item.exchangeState.code == 'UNCHANGED' &&
                    <div className={styles.item} onClick={() => tableAction(key, 'cancellation', item)}>作废</div>
                }
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys) => {
            this.setState({
                selectedRowKeys,
            });
            this.props.tableAction(null, 'batchKeys', selectedRowKeys);
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
        this.props.tableAction(null, 'batchKeys', []);
    };

    render() {
        const { selectedRowKeys } = this.state;
        const { paginationChange, scrollY } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default XTableHOC(OrderTable, 40);
