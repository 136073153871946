/**
 * 判断是否有按钮权限
 */
import { getLocalStorage } from '@/assets/js/storage';
import http from '@/assets/api/http';
import { message } from 'antd';

export const isButtonPerssion = (code) => {
    let boolean = false;
    let btnPremission = JSON.parse(getLocalStorage('btnPremission')) || [];
    if (btnPremission.length == 0) {
        http('/admin/menu/listMenus', {}, 'POST').then((response) => {
            if (response.status == 200) {
                btnPremission = _saveButtonPermission(response.result);
                _check(btnPremission);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    } else {
        boolean = _check(btnPremission, code);
    }
    return boolean;
};
const _saveButtonPermission = (menuList) => {
    const premission = _getButtonUrlForChildren(menuList);
    return premission;
};
const _getButtonUrlForChildren = (treeData) => {
    let childrenEventKeys = [];
    for (let i = 0; i < treeData.length; i++) {
        if (treeData[i].menuType == '2' || treeData[i].menuType == '3') {
            childrenEventKeys.push(treeData[i].menuUrl);
        }
        if (treeData[i].childrens && treeData[i].childrens.length > 0) {
            const array = this._getButtonUrlForChildren(treeData[i].childrens);
            childrenEventKeys = Array.from(new Set([...childrenEventKeys, ...array]));
        }
    }
    return childrenEventKeys;
};
const _check = (btnPremission, code) => {
    let hasPerssion = false;
    for (let i = 0; i < btnPremission.length; i++) {
        if (btnPremission[i] == code) {
            hasPerssion = true;
            break;
        }
    }
    return hasPerssion;
};