import { createModel } from '@/store/tools';

const initData = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };
const initRecData = { chargeFeedRecoResp: { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 }, recoNumber: 0, recoTimes: 0 };
const initLiveRec = { recoLiveResp: { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 }, recoTimes: 0, recoNumber: 0 };
const videoInfo = {
    accountId: 0,
    accountName: '',
    chargeFeedClassName: '',
    coverPage: '',
    feedCost: 0,
    feedTitle: '',
    freeTime: 0,
    goodsList: [
        {
            brandName: '',
            categoryName: '',
            dividend: 0,
            goodsName: '',
            goodsNo: '',
            goodsSource: '',
            goodsState: '',
            id: 0,
            mainImgUrl: '',
            marketPrice: 0,
            salePrice: 0
        }
    ],
    id: 0,
    indate: 0,
    sourceFileName: '',
    state: '',
    totalAmount: 0,
    totalStarLightAmount: 0,
    videoDuration: 0,
    videoUrl: ''
};
const communityInfo = {
    accountId: 0,
    accountName: '',
    chargeFeedClassName: '',
    content: '',
    coverPage: '',
    feedCost: 0,
    feedTitle: '',
    fileNames: [],
    freeTime: 0,
    goodsList: [
        {
            brandName: '',
            categoryName: '',
            dividend: 0,
            goodsName: '',
            goodsNo: '',
            goodsSource: '',
            goodsState: '',
            id: 0,
            mainImgUrl: '',
            marketPrice: 0,
            salePrice: 0
        }
    ],
    id: 0,
    indate: 0,
    state: '',
    totalStarLightAmount: 0
};

const model = {
    namespace: 'knowledgechannel',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getKLiveList': T('getKLiveList'),
            'getKLiveHistory': T('getKLiveHistory'),
            'getKLiveRecomend': T('getKLiveRecomend'),

            'getKVideoList': T('getKVideoList'),
            'getKVMBindUser': T('getKVMBindUser'),
            'getKVMGoods': T('getKVMGoods'),
            'getKVideoDetail': T('getKVideoDetail'),

            'getKCommunityList': T('getKCommunityList'),
            'getKCommunityDetail': T('getKCommunityDetail'),

            'getKVCRecommendList': T('getKVCRecommendList'),
            'getKBeansIncomeList': T('getKBeansIncomeList'),
            'getKDividendIncomeList': T('getKDividendIncomeList'),
            'getKknowledgePay': T('getKknowledgePay'),
            'getKWhetherRecSele': T('getKWhetherRecSele'),
            'getKClassifySele': T('getKClassifySele'),
            'getKBanReason': T('getKBanReason'),

            'getViolationType': T('getViolationType'),//违规状态
            'getViolationList': T('getViolationList'),//违规记录列表
            'getVRecordType': T('getVRecordType'),//违规记录页--记录类型
            'getImageType': T('getImageType'),//违规记录页--图片违规状态
            'getAudioType': T('getAudioType'),//违规记录页--音频违规状态
        },
        actions: {
            'getKLiveList': A('getKLiveList'),
            'getKLiveHistory': A('getKLiveHistory'),
            'getKLiveRecomend': A('getKLiveRecomend'),

            'getKVideoList': A('getKVideoList'),
            'getKVMBindUser': A('getKVMBindUser'),
            'getKVMGoods': A('getKVMGoods'),
            'getKVideoDetail': A('getKVideoDetail'),

            'getKCommunityList': A('getKCommunityList'),
            'getKCommunityDetail': A('getKCommunityDetail'),

            'getKVCRecommendList': A('getKVCRecommendList'),
            'getKBeansIncomeList': A('getKBeansIncomeList'),
            'getKDividendIncomeList': A('getKDividendIncomeList'),
            'getKknowledgePay': A('getKknowledgePay'),
            'getKWhetherRecSele': A('getKWhetherRecSele'),
            'getKClassifySele': A('getKClassifySele'),
            'getKBanReason': A('getKBanReason'),

            'getViolationType': A('getViolationType'),
            'getViolationList': A('getViolationList'),
            'getVRecordType': A('getVRecordType'),
            'getImageType': A('getImageType'),
            'getAudioType': A('getAudioType'),
        },
        sagas: {
            'getKLiveList': S('getKLiveList', '/charge/live/listLiveChargeBroadcas'),
            'getKLiveHistory': S('getKLiveHistory', '/charge/live/listLiveChargeBack'),
            'getKLiveRecomend': S('getKLiveRecomend', '/show/live/listLiveReco'),

            'getKVideoList': S('getKVideoList', '/show/chargefeed/listChargeFeedVideo'),
            'getKVMBindUser': S('getKVMBindUser', '/user/account/listFeedAccount'),
            'getKVMGoods': S('getKVMGoods', '/goods/goods/listShowGoods'),
            'getKVideoDetail': S('getKVideoDetail', '/show/chargefeed/getFeedVideo'),

            'getKCommunityList': S('getKCommunityList', '/show/chargefeed/listChargeFeedGraphics'),
            'getKCommunityDetail': S('getKCommunityDetail', '/show/chargefeed/getFeedGraphics'),

            'getKVCRecommendList': S('getKVCRecommendList', '/show/chargefeed/listChargeFeedReco'),
            'getKBeansIncomeList': S('getKBeansIncomeList', '/show/chargefeed/starLightListInfo'),
            'getKDividendIncomeList': S('getKDividendIncomeList', '/show/chargefeed/queryLiveIncomeLogs'),
            'getKknowledgePay': S('getKknowledgePay', '/charge/listShowOrder'),
            'getKWhetherRecSele': S('getKWhetherRecSele', '/enum/FlagEnum'),
            'getKClassifySele': S('getKClassifySele', '/show/showClass/listPullDownShowClass'),
            'getKBanReason': S('getKBanReason', '/show/reporttype/listReportTypes'),

            'getViolationType': S('getViolationType', '/enum/ReadEnum'),
            'getViolationList': S('getViolationList', '/show/live/getLiveDetect'),
            'getVRecordType': S('getVRecordType', '/enum/ShumeiContentTypeEnum'),
            'getImageType': S('getImageType', '/enum/ImgRiskTypeEnum'),
            'getAudioType': S('getAudioType', '/enum/AudioRiskTypeEnum'),
        },
        reducers: {
            'kLiveList': R('getKLiveList', initData),
            'kLiveHistory': R('getKLiveHistory', initData),
            'kLiveRecomend': R('getKLiveRecomend', initLiveRec),

            'kVideoList': R('getKVideoList', initData),
            'kVMBindUser': R('getKVMBindUser', initData),
            'kVMGoods': R('getKVMGoods', initData),
            'kVideoDetail': R('getKVideoDetail', videoInfo),

            'kCommunityList': R('getKCommunityList', initData),
            'kCommunityDetail': R('getKCommunityDetail', communityInfo),

            'kVCRecommendList': R('getKVCRecommendList', initRecData),
            'kBeansIncomeList': R('getKBeansIncomeList', initData),
            'kDividendList': R('getKDividendIncomeList', initData),
            'kknowledgePay': R('getKknowledgePay', initData),
            'kWhetherRecSele': R('getKWhetherRecSele', []),
            'kClassifySele': R('getKClassifySele', []),
            'kBanReason': R('getKBanReason', initData),

            'violationStatus': R('getViolationType', []),
            'violationLists': R('getViolationList', { liveDetectPager: {} }),
            'vRecordType': R('getVRecordType', []),
            'imageType': R('getImageType', []),
            'audioType': R('getAudioType', []),
        }
    })
};
export default createModel(model);