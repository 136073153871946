import React, { Component } from 'react';
import { Table, } from 'antd';
import PropTypes from 'prop-types';
import styles from './BeanTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

export default class BeanTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    _columns = () => ([
        {
            title: '交易订单号',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '业务日期',
            dataIndex: 'bizTime',
            key: 'bizTime',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '用户账号',
            dataIndex: 'loginName',
            key: 'loginName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '用户类型',
            dataIndex: 'userTypeValue',
            key: 'userTypeValue',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        //     title: '商户名称',
        //     dataIndex: 'merchantName',
        //     key: 'merchantName',
        //     width: '10%',
        //     align: 'center',
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        {
            title: '业务名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '数量/粒',
            dataIndex: 'merchantStarValue',
            key: 'merchantStarValue',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'feeTypeDesc',
            key: 'feeTypeDesc',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '关联订单号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ]);

    // 解析表格数据，使符合columns格式要求
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                item.userTypeValue = item.userType && Object.keys(item.userType).length > 0 ? item.userType.value : '';
                return item;
            });
        }
    }

    //渲染组件
    render() {
        const { paginationChange, } = this.props;

        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

BeanTable.propTypes = {
    renderData: PropTypes.object, // 表格数据源
    paginationChange: PropTypes.func, // 分页
};