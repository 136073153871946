import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import * as actions from '@/reducers/auditmanage/facilitatorAudit/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { getServiceTypeEnum } from '@/reducers/auditmanage/withdraw/actions';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: [], // 当前需要打开的弹窗的入参
            serviceName: '',
            mobilePhone: '',
            companyStatus: {},
            tyoeItem: {},
        };
    }
    static propTypes = {
        updateContent: PropTypes.func,
        getFacilitatorAuditList: PropTypes.func,
        getFacilitatorAuditStatus: PropTypes.func,
        facilitatorAuditList: PropTypes.object,
        facilitatorAuditStatus: PropTypes.array,
        getServiceTypeEnum: PropTypes.func,
        serviceTypeEnum: PropTypes.array,
    };
    componentDidMount() {
        this.props.getFacilitatorAuditStatus();
        this.props.getServiceTypeEnum();
    }
    // 查询
    _searchHandle = (useCache) => {
        const { companyStatus, mobilePhone, serviceName, pageNum, pageSize, tyoeItem } = this.state;
        this.searchCache = {
            mobilePhone,
            applyType: companyStatus.code,
            serviceName,
            sourceConditions: 'EXAMINE',
            providerRole: tyoeItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getFacilitatorAuditList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ serviceName: '', companyStatus: {}, mobilePhone: '', tyoeItem: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'audit':
                this.props.updateContent('AUDIT', id);
                break;
            case 'detail':
                this.props.updateContent('DETAIL', id);
                break;
        }
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { facilitatorAuditList, facilitatorAuditStatus, serviceTypeEnum } = this.props;
        const { mobilePhone, serviceName, companyStatus, tyoeItem } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='手机号码'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='mobilePhone'
                                value={mobilePhone}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='真实姓名'
                                placeholder='请输入真实姓名'
                                bindThis={this}
                                bindName='serviceName'
                                value={serviceName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={facilitatorAuditStatus}
                                bindThis={this}
                                bindName='companyStatus'
                                dataIndex='value'
                                keyIndex='code'
                                value={companyStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='服务商类型'
                                placeholder='请选择服务商类型'
                                renderData={serviceTypeEnum}
                                bindThis={this}
                                bindName='tyoeItem'
                                dataIndex='value'
                                keyIndex='code'
                                value={tyoeItem.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    {/* <div className={styles.operate_box}>
                        <XOKButton style={{ width: '90px' }} label='导出Excel' />
                    </div> */}
                    <TableContent paginationChange={this._paginationChange} renderData={facilitatorAuditList} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}
const mapStateToProps = (state) => ({
    facilitatorAuditList: state.facilitatorAudit.facilitatorAuditList,
    facilitatorAuditStatus: state.facilitatorAudit.facilitatorAuditStatus,
    serviceTypeEnum: state.withdrawAudit.serviceTypeEnum,
});
export default connect(mapStateToProps, { ...actions, getServiceTypeEnum })(Main);