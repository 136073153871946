import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const TableContent = ({ renderData, paginationChange }) => {

    const columns = () => ([
        {
            title: '账号',
            dataIndex: 'tel',
            key: 'tel',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '昵称',
            dataIndex: 'nickname',
            key: 'nickname ',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '性别',
            dataIndex: 'sex.code',
            key: 'sex.code',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: text => tooltip(text == '1' ? '男' : '女')
        },
        {
            title: '等级',
            dataIndex: 'grand',
            key: 'grand',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'status.code',
            key: 'status.code',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: text => tooltip(text == '1' ? '有效' : '无效')
        },
        // {
        //     title: '创建时间',
        //     dataIndex: 'gmtCreate',
        //     key: 'gmtCreate',
        //     align: 'center',
        //     width: '10%',
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        {
            title: '注册时间',
            dataIndex: 'gmtRegistration',
            key: 'gmtRegistration',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        }
    ]);

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.id}
                columns={columns()}
                dataSource={renderData.dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;