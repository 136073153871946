import * as T from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    resultTotal: 0
};

//魅力等级设置列表
export const charmGradeList = (state = initData, action) => {
    switch (action.type) {
        case T.SET_CHARMGRADE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

//魅力等级设置详情
export const charmGradeDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_CHARMGRADE_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

//魅力等级设置优惠券下拉
export const charmGradeCouponSele = (state = [], action) => {
    switch (action.type) {
        case T.SET_CHARMGRADE_COUPONSELE:
            return action.payload.result;
        default:
            return state;
    }
};

//魅力等级设置单选框
export const radioValue = (state = {}, action) => {
    switch (action.type) {
        case T.SET_CHARMGRADE_RADIO:
            return action.payload;
        default:
            return state;
    }
};