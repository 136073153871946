import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { message } from 'antd';
import AddGoodsModal from './modal/AddGoodsModal';
import SkuModal from './modal/SkuModal';
import TableContent from './components/TableContent';
import { SearchBox, SearchItem } from '@/components/Layout';
import model from '@/reducers/starvalueshop/redemption/model';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XSelect, XOKButton, XCancelButton, XInput, XTextArea, showConfirm, XTableAdapter } from '@/components/xqxc_ui';

class FranchiseValue extends Component {

    static propTypes = {
        history: PropTypes.object, // 路由history对象
        addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
        keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息
        getRedemptionData: PropTypes.func,
        redemptionData: PropTypes.object,
        getGoodsMainStates: PropTypes.func,
        goodsMainStates: PropTypes.array,
        getGoodsStates: PropTypes.func,
        goodsStates: PropTypes.array,
        updateRecoSort: PropTypes.func
    }

    state = {
        renderMode: '',
        modeParams: {},
        goodsName: '',
        goodsNos: '',
        skuNos: '',
        goodsStateItem: {},
        stateItem: {},
        batchKeys: [],
        pageNum: 1,
        pageSize: 30,
        scrollY: 0
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getGoodsMainStates, getGoodsStates } = this.props;
        getGoodsMainStates();
        getGoodsStates();
        keepSecondNavBreadcrumb();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { goodsName, goodsNos, skuNos, goodsStateItem, stateItem, pageSize, pageNum } = this.state;
        this.searchCache = {
            goodsName,
            payType:'INTEGRAL',
            goodsNos: goodsNos.replace(/，/g, ','),
            skuNos: skuNos.replace(/，/g, ','),
            exchangeGoodsStatus: stateItem.code, goodsState: goodsStateItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getRedemptionData(this.searchCache);
        KeepAlive.saveCache({ ...this.searchCache, stateItem, goodsStateItem });
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ goodsName: '', goodsNos: '', skuNos: '', goodsStateItem: {}, stateItem: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 分页发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { modeParams, renderMode } = this.state;
        switch (renderMode) {
            case 'addGoods':
                return <AddGoodsModal closeMode={this._closeMode} />;
            case 'sku':
                return <SkuModal closeMode={this._closeMode} modeParams={modeParams} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ renderMode: '', modeParams: {} }, () => {
            update && this._searchHandle('useCache');
        });
    }

    //页面跳转
    _jumpPage = (title, url) => {
        const { history, addOneBreadcrumbPath } = this.props;
        const baseUrl = '/home/starValueShop/franchisevalue';
        addOneBreadcrumbPath({ title, path: `${baseUrl}${url}` });
        history.push({ pathname: `${baseUrl}${url}` });
    }

    //表格操作
    _tableOperate = (active, values) => {
        switch (active) {
            case 'detail':
                this._jumpPage('商品详情', `/detail/${values.exchangeGoodsId}`);
                break;
            case 'putaway':
                this._request('/goods/exchangeGoods/batchUpdateExchangeGoodsStatus', { exchangeGoodsIdList: values, status: 'PUT_ON_SALE', needVerify: 1 }, 'putaway');
                break;
            case 'soldOut':
                showConfirm('警告', '您确定要下架商品么？下架后兑换专区商品将不再显示', () => {
                    this._request('/goods/exchangeGoods/batchUpdateExchangeGoodsStatus', { exchangeGoodsIdList: values, status: 'PULL_OFF_SHELVES' });
                });
                break;
            case 'batch':
                this.setState({ batchKeys: values });
                break;
            case 'sku':
                this.setState({ renderMode: 'sku', modeParams: { exchangeGoodsId: values.exchangeGoodsId } });
                break;
            case 'updateReco':
                this._request('/goods/exchangeGoods/updateExchangeGoodsRecoSort', values);
                break;
            default:
                break;
        }
    }

    //请求
    _request = (url, data, type) => {
        http(url, data, 'POST')
            .then(res => {
                if (type && res.result == 0) {
                    showConfirm('警告', '当前商品有新增或删除SKU！请使用添加商品的操作方式操作，点击不添加只上架已有的SKU！', () => {
                        this.setState({ renderMode: 'addGoods' });
                    }, () => {
                        this._request('/goods/exchangeGoods/batchUpdateExchangeGoodsStatus', { ...data, needVerify: 0 });
                    }, '不添加', '添加');
                } else {
                    message.success(res.message);
                    this.setState({ batchKeys: [] }, () => {
                        this._searchHandle('useCache');
                    });
                }
            });
    }

    render() {
        const { goodsName, goodsNos, skuNos, goodsStateItem, stateItem, batchKeys, scrollY } = this.state;
        const { goodsMainStates = [], goodsStates = [], redemptionData = {}, updateRecoSort } = this.props;
        return (
            <KeepAlive id='franchisevalue' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商品名称'
                                placeholder='请输入商品名称'
                                bindName='goodsName'
                                bindThis={this}
                                value={goodsName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XTextArea
                                label='商品货号'
                                style={{ marginRight: '20px' }}
                                inputStyle={{ width: '300px' }}
                                placeholder='请输入货号，多个逗号隔开,最多100个'
                                value={goodsNos}
                                rows={1}
                                onChange={value => {
                                    if (value.replace(/，/g, ',').split(',').length < 100) {
                                        this.setState({ goodsNos: value });
                                    } else {
                                        message.warning('货号集合最多100个');
                                    }
                                }}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XTextArea
                                label='SKU编码'
                                style={{ marginRight: '20px' }}
                                inputStyle={{ width: '300px' }}
                                placeholder='请输入SKU编码，多个逗号隔开,最多100个'
                                value={skuNos}
                                rows={1}
                                onChange={value => {
                                    if (value.replace(/，/g, ',').split(',').length < 100) {
                                        this.setState({ skuNos: value });
                                    } else {
                                        message.warning('货号集合最多100个');
                                    }
                                }}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商品主状态'
                                placeholder='请选择商品主状态'
                                renderData={goodsMainStates}
                                dataIndex='value'
                                keyIndex='code'
                                bindName='goodsStateItem'
                                bindThis={this}
                                value={goodsStateItem.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={goodsStates}
                                dataIndex='value'
                                keyIndex='code'
                                bindName='stateItem'
                                bindThis={this}
                                value={stateItem.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operateBox}>
                        <XOKButton
                            label='批量下架'
                            disabled={!batchKeys.length}
                            style={{ width: '90px', marginRight: '20px' }}
                            onClick={() => this._tableOperate('soldOut', batchKeys)}
                        />
                        <XOKButton
                            label='添加商品'
                            style={{ width: '90px' }}
                            onClick={() => this.setState({ renderMode: 'addGoods' })}
                        />
                    </div>
                    <XTableAdapter render={scrollY => this.setState({ scrollY })} offsetY={40}>
                        <TableContent
                            paginationChange={this._paginationChange}
                            renderData={redemptionData}
                            tableAction={this._tableOperate}
                            batchKeys={batchKeys}
                            updateRecoSort={updateRecoSort}
                            scrollY={scrollY}
                        />
                    </XTableAdapter>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }

}

export default connect(({ redemption }) => ({
    redemptionData: redemption.redemptionData,
    goodsMainStates: redemption.goodsMainStates,
    goodsStates: redemption.goodsStates,
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(FranchiseValue);

