import * as T from './actiontypes';

// 会员等级设置列表
export const getMemberGradeListMlx = ({ pageNum, pageSize }) => ({
    type: T.GET_MEMBERGRADE_LIST,
    payload: {
        pageNum, pageSize
    }
});

// 会员等级设置详情
export const getMemberGradeDetailMlx = (id) => ({
    type: T.GET_MEMBERGRADE_DETAIL,
    payload: { id }
});

// 会员等级设置优惠券下拉
export const getMemberGradeCouponSeleMlx = (couponName) => ({
    type: T.GET_MEMBERGRADE_COUPONSELE,
    payload: {
        couponName
    }
});

// 会员等级设置单选框
export const getMemberGradeRadioMlx = () => ({
    type: T.GET_MEMBERGRADE_RADIO,
    payload: {}
});