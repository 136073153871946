import React from 'react';
import styles from './AdvertTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table, Switch, Input, Icon } from 'antd';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';
import PropTypes from 'prop-types';

class AdvertTable extends React.Component {

    _takeColumns = (actionClickHandle) => ([
        {
            title: '广告图片',
            dataIndex: 'coverUrl',
            key: 'coverUrl',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: ImgCell
        },
        {
            title: '广告名称',
            dataIndex: 'title',
            key: 'title',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '投放端口',
            dataIndex: 'useScene',
            key: 'useScene',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: (text) => tooltip(text ? text.value : '')
        },
        {
            title: '绑定商品',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '排序',
            dataIndex: 'sort',
            key: 'sort',
            width: '15%',
            align: 'center',
            render: (text, item) => (this._SortCellUpdate(text, item, actionClickHandle))
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: '10%',
            align: 'center',
            render: (text, item) => (this._SwitchCellUpdate(text, item, actionClickHandle))
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: '10%',
            align: 'center',
            render: (text, record) => (this._actionHandle(record))
        },
    ]);
    // 表格项内容为可编辑
    _SortCellUpdate = (value, item, actionClickHandle) => {
        const label = React.createRef();
        const box = React.createRef();
        const input = React.createRef();

        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.sort_box}>
                    <div className={styles.label} ref={label}>{value}</div>
                    <div className={styles.input} ref={box}>
                        <Input
                            key={item.id}
                            style={{ width: '50px' }}
                            ref={input}
                            defaultValue={value}
                            onBlur={(e) => {
                                box.current.style.display = 'none';
                                label.current.style.display = 'block';
                                if (value != e.target.value) {
                                    actionClickHandle(item, 'sort', e.target.value);
                                }
                            }}
                        />
                    </div>
                    <div
                        className={styles.icon}
                        onClick={() => {
                            label.current.style.display = 'none';
                            box.current.style.display = 'block';
                            input.current.focus();
                        }}
                    >
                        <Icon type="edit" style={{ fontSize: '18px' }} />
                    </div>
                </div>
            </div>
        );
    };

    // 表格项内容为开关
    _SwitchCellUpdate = (sw, item, actionClickHandle) => {
        let checked = true;
        if (sw == 0) {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    key={item.id + item['state']}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={checked}
                    onChange={(value) => {
                        actionClickHandle(item, 'switch', value);
                    }}
                />
            </div>
        );
    };
    // 操作表格
    _actionHandle = (record) => {
        const { tableAction } = this.props;
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => tableAction(record, 'edit')}>编辑</div>
            <div className={styles.item} onClick={() => tableAction(record, 'delete')}>删除</div>
        </div>;
    }

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns(this.props.tableAction)}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    curTabID: PropTypes.number, // 当前TabID
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default AdvertTable;