import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import KeepAlive from '@/routes/KeepAlive';
import { connect } from 'react-redux';
import { autoPageNum, autopageSize } from '@/assets/config';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XSelect, XOKButton, XCancelButton, XInput, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, tooltipClick, priceFenToYuanCell } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { message, Table } from 'antd';
import model from '@/reducers/channelmanage/list/model';
import SetModal from './SetModal';
import UpdateModal from './UpdateModal';
import EnableDisableModal from './EnableDisableModal';
import ApplyModal from './ApplyModal';
import BatchSetMemberLevel from './BatchSetMemberLevel';

const { getChannelList, getStateEnum } = model.actions;

const TableComponent = ({
    renderData: {
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0,
        dataList = []
    } = {},
    paginationChange,
    tableAction,
    keys = []
}) => {

    const tableOperate = (id, r) => {
        return <div className={styles.action}>
            {r.state !== 2 && <div className={styles.item} onClick={() => tableAction(r, 'setModal')}>渠道设置</div>}
            {r.state === 0 && <div className={styles.item} onClick={() => tableAction([id], 'enableDisableModal', 'singleOpen')}>启用</div>}
            {r.state === 1 && <div className={styles.item} onClick={() => tableAction([id], 'enableDisableModal', 'singleBan')}>禁用</div>}
            {r.state === 2 && <div className={styles.item} onClick={() => tableAction(r, 'applyModal')}>认证申请</div>}
            <div className={styles.item} onClick={() => tableAction(r, 'updateModal')}>修改密码</div>
        </div>;
    };

    const columns = [
        {
            title: '渠道账号',
            dataIndex: 'accountName',
            key: 'accountName',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: (t, r) => tooltipClick(t, () => tableAction(r.id, 'detail'))
        },
        {
            title: '可用余额(元)',
            dataIndex: 'balance',
            key: 'balance',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '信用额度(元)',
            dataIndex: 'creditBalance',
            key: 'creditBalance',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员等级',
            dataIndex: 'levelName',
            key: 'levelName',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        //     title: '折扣(折)',
        //     dataIndex: 'discount',
        //     key: 'discount',
        //     width: cellWidth.normal,
        //     align: 'center',
        //     onCell: tooltipStyle,
        //     render: (t, r) => tooltipClick(t, () => tableAction(r.incomeType, 'total', r.billableTime))
        // },
        {
            title: '状态',
            dataIndex: 'stateValue',
            key: 'stateValue',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '注册时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tableOperate
        }
    ];
    const rowSelection = {
        selectedRowKeys: keys,
        onChange: keys => {
            tableAction(keys, 'batch');
        },
        getCheckboxProps: r => ({
            disabled: r.state === 2, name: r.name,
        })
    };
    return <div className={styles.tableContainer}>
        <XTableSelectionCount selectedNum={keys.length} onClean={() => tableAction([], 'batch')} />
        <Table
            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
            rowSelection={rowSelection}
            rowKey='id'
            columns={columns}
            dataSource={dataList}
            pagination={false}
            scroll={{ y: true }}
        />
        <XPagination
            resultTotal={resultTotal}
            pageSize={pageSize}
            pageNum={pageNum}
            onChange={paginationChange}
        />
    </div>;
};
TableComponent.propTypes = {
    history: PropTypes.object,
    renderData: PropTypes.object,
    paginationChange: PropTypes.func,
    tableAction: PropTypes.func,
    keys: PropTypes.array
};

const ChannelList = ({
    history,
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getChannelList,
    tableData,
    getStateEnum,
    stateEnum,
}) => {

    const [accountName, setAccountName] = useState();
    const [stateItem, setStateItem] = useState({});
    const [pageNum, setPageNum] = useState(autoPageNum);
    const [pageSize, setPageSize] = useState(autopageSize);
    const [openModal, setOpenModal] = useState();
    const [modalParmas, setModalParmas] = useState({});
    const [keys, setKeys] = useState([]);

    useEffect(() => {
        keepSecondNavBreadcrumb();
        getStateEnum();
    }, []);

    const getTableData = (params = {}) => {
        getChannelList({
            accountName: params.accountName,
            state: params.stateItem && params.stateItem.code,
            pageNum: params.pageNum, pageSize: params.pageSize
        });
        KeepAlive.saveCache(params);
    };

    const paginationChange = (pageSize, pageNum) => {
        setPageNum(pageNum);
        setPageSize(pageSize);
        getTableData({ accountName, stateItem, pageNum, pageSize });
    };

    const resetSearch = () => {
        setAccountName();
        setStateItem({});
        KeepAlive.saveResetFunc(() => getTableData({ accountName, stateItem, pageNum, pageSize }));
    };

    const tableAction = (key, type, active) => {
        const baseUrl = '/home/channelmanage/channellist';
        const jumpPage = (path, title) => {
            addOneBreadcrumbPath({ title, path: `${baseUrl}${path}` });
            history.push({ pathname: `${baseUrl}${path}` });
        };
        const eventObject = {
            detail: () => jumpPage(`/channeldetail/${key}`, '详情'),
            setModal: () => {
                setModalParmas(key);
                setOpenModal('setModal');
            },
            updateModal: () => {
                setModalParmas(key);
                setOpenModal('updateModal');
            },
            enableDisableModal: () => {
                if (!key.length) return message.warn('请选择数据');
                setModalParmas({ key, active });
                setOpenModal('enableDisableModal');
            },
            applyModal: () => {
                setModalParmas(key);
                setOpenModal('applyModal');
            },
            closeModal: () => {
                setOpenModal();
                setModalParmas({});
                if (key) {
                    setKeys([]);
                    getTableData({ accountName, stateItem, pageNum, pageSize });
                }
            },
            batch: () => setKeys(key),
            batchSetMemberLevel: ()=>{
                // 批量设置会员等级弹窗
                setOpenModal('batchSetMemberLevel');
            }
        };
        eventObject[type]();
    };

    const onBatchSetMemberLevelOk = ()=>{
        setOpenModal();
        resetSearch();
        getTableData({ pageNum: autoPageNum, pageSize: autopageSize });
    };

    const modalList = {
        setModal: <SetModal tableAction={tableAction} modalParmas={modalParmas} />,
        updateModal: <UpdateModal tableAction={tableAction} modalParmas={modalParmas} />,
        enableDisableModal: <EnableDisableModal tableAction={tableAction} modalParmas={modalParmas} />,
        applyModal: <ApplyModal tableAction={tableAction} modalParmas={modalParmas} />,
        batchSetMemberLevel: <BatchSetMemberLevel onOk={onBatchSetMemberLevelOk} onClose={()=>{setOpenModal();}} />,
    };

    const onChange = (p = {}) => {
        if (p.accountName) setAccountName(p.accountName);
        if (p.stateItem) setStateItem(p.stateItem);
        if (p.pageNum != autoPageNum) setPageNum(p.pageNum);
        if (p.pageSize != autopageSize) setPageSize(p.pageSize);
        getTableData(p);
    };

    return <KeepAlive render={onChange}>
        <div className={styles.flexBoxContainer}>
            <SearchBox>
                <SearchItem>
                    <XInput
                        label='渠道账号'
                        placeholder='请输入渠道账号'
                        value={accountName}
                        bindThis={setAccountName}
                        bindName='accountName'
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        label='状态'
                        showSearch
                        placeholder='请选择'
                        renderData={stateEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={stateItem.value}
                        bindThis={setStateItem}
                        bindName='stateItem'
                    />
                </SearchItem>
                <SearchItem>
                    <XOKButton
                        label='查询'
                        onClick={() => getTableData({ accountName, stateItem, pageNum, pageSize })}
                    />
                    <XCancelButton
                        label='重置'
                        onClick={resetSearch}
                    />
                </SearchItem>
            </SearchBox>
            <div className={styles.operateBox}>
                <XOKButton
                    label='批量启用'
                    style={{ width: '90px' }}
                    onClick={() => tableAction(keys, 'enableDisableModal', 'batchOpen')}
                />
                <XOKButton
                    label='批量禁用'
                    style={{ width: '90px', marginLeft: '20px' }}
                    onClick={() => tableAction(keys, 'enableDisableModal', 'batchBan')}
                />
                <XOKButton
                    label='批量设置会员等级'
                    style={{ width: '150px', marginLeft: '20px' }}
                    onClick={() => tableAction(keys, 'batchSetMemberLevel')}
                />
            </div>
            <TableComponent renderData={tableData} keys={keys} tableAction={tableAction} paginationChange={paginationChange} />
            {modalList[openModal]}
        </div>
    </KeepAlive>;
};
ChannelList.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getChannelList: PropTypes.func,
    tableData: PropTypes.object,
    getStateEnum: PropTypes.func,
    stateEnum: PropTypes.array,
};
export default connect(state => ({
    tableData: state.channelmanage.channelList,
    stateEnum: state.channelmanage.stateEnum,
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getChannelList,
    getStateEnum
})(ChannelList);