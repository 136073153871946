/**
 *  初始说明列表
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

class Initdesclist extends React.Component {
    render() {
        return (<Main history={this.props.history} />);
    }
}

Initdesclist.propTypes = {
    history: PropTypes.object
};

export default Initdesclist;
