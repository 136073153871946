/**
 * 【订单交易明细页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DatePicker } from 'antd';
import moment from 'moment';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getTranscationList, getMerchantList } from '@/reducers/ordermanage/transcation/actions';
import { getProductOrderStatus } from '@/reducers/ordermanage/productOrder/actions';
import { XInput, XSelect, XOKButton, XCancelButton, XInputNum, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import http from '@/assets/api/http';
import { message } from 'antd';
import styles from './index.module.scss';


import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const isAfterArr = [{
    value: '是',
    code: 1,
}, {
    value: '否',
    code: 0,
}];
const orderStatus = [{
    code: 'SETTLED',
    value: '已结算',
}, {
    code: 'UNSETTLED',
    value: '未结算',
}];
class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expandBoolean: false, // 是否展开
            orderId: '', // 订单交易号
            orderState: {}, // 订单状态
            userState: {}, // 商户
            itemNo: '', // 货号
            goodsName: '',// 商品名称
            pageNum: 1,
            pageSize: 10,
            // isAfter: {},
            // afterSaleId: '',
            targetId: '',
            startDate: null,
            endDate: null,
            payStartDate: null,
            payEndDate: null,
            chargeValue: [],
            chargeValue2: [],
            loading: false,
        };
        this.searchCache = { pageNum: 1, pageSize: 20 }; // 查询缓存
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getTranscationList({ pageNum: 1, pageSize: 10 });
        this.props.getMerchantList();
        this.props.getProductOrderStatus();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { orderState, userState, orderId, itemNo, goodsName, pageNum, pageSize, startDate,
            endDate, payStartDate, payEndDate } = this.state;
        this.searchCache = {
            settleType: orderState.code,
            targetId: userState.merchantId,
            orderId,
            itemNo,
            goodsName,
            //   afterSaleId,
            startDate,
            endDate,
            payStartDate,
            payEndDate,
            //   isAfter: isAfter.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getTranscationList(this.searchCache);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            orderState: {},
            userState: {},
            orderId: null,
            itemNo: null,
            goodsName: null,
            pageNum: 1,
            pageSize: 10,
            targetId: '',
            // afterSaleId: '',
            startDate: null,
            endDate: null,
            payStartDate: null,
            payEndDate: null,
            // isAfter: {},
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        //   this.props.getTranscationList({ pageNum: 1, pageSize: 10 });
    }

    // 展开或收缩搜索条件
    _expandChangeHandle = (expandBoolean) => {
        this.setState({ expandBoolean });
    }
    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        }, () => {
            this._searchHandle('useCache');
        });
    }
    // 下载导入模板
    _exportHandle = () => {
        const { orderState, userState, orderId, itemNo, goodsName, startDate, payStartDate, payEndDate,
            endDate } = this.state;
        const params = {
            settleType: orderState.code,
            targetId: userState.merchantId,
            orderId,
            itemNo,
            goodsName,
            payStartDate,
            payEndDate,
            startDate,
            endDate,
        };
        this.setState({ loading: true });
        http('/settle/reconciliationSource/exportSettleOrderDetail', params, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result == '') {
                    message.error('没有订单数据');
                    this.setState({ loading: false });
                } else {
                    this.setState({ loading: false });
                    window.location.href = response.result;
                }
            }
        }).catch((e) => {
            message.error(e.message);
            this.setState({ loading: false });
        });
    }
    render() {
        const { expandBoolean, orderId, itemNo, goodsName, orderState, userState, startDate, endDate, payStartDate, payEndDate } = this.state;
        const { transcationList = {}, merchantList: { merchantIdAndNames = [] }, history } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>

                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInputNum
                                label='交易订单号'
                                placeholder='请输入交易订单号'
                                value={orderId}
                                bindThis={this}
                                bindName='orderId'
                                min={0}
                                precision={0}
                                maxLength={16}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='货号'
                                placeholder='请输入货号'
                                value={itemNo}
                                bindThis={this}
                                bindName='itemNo'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='商品名称'
                                placeholder='请输入商品名称'
                                value={goodsName}
                                bindThis={this}
                                bindName='goodsName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户'
                                placeholder='请选择商户'
                                renderData={merchantIdAndNames || []}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                value={userState.merchantName}
                                showSearch={true}
                                bindThis={this}
                                bindName='userState'
                                isOptionBindID={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='订单状态'
                                placeholder='请选择订单状态'
                                renderData={orderStatus}
                                dataIndex='value'
                                keyIndex='code'
                                value={orderState.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='orderState'
                            />
                        </SearchItem>
                        <SearchItem>
                            {/* <span className={styles.payDate}>业务日期</span>
                            <RangePicker
                                showTime
                                value={[]}
                                style={{ width: 450 }}
                                format={dateFormat}
                                placeholder={['开始日期', '结束日期']}
                                onChange={this._onChargeChange}
                            /> */}
                            <XDatePicker
                                label='业务日期'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                label=''
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='付款日期'
                                value={payStartDate}
                                bindThis={this}
                                bindName='payStartDate'
                                isFormat
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                label=''
                                value={payEndDate}
                                bindThis={this}
                                bindName='payEndDate'
                                isFormat
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton label='查询' onClick={this._searchHandle} />
                            <XCancelButton label='重置' onClick={this._resetHandle} />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.export}>
                        <XOKButton loading={this.state.loading} label='导出' style={{ width: 90 }} onClick={this._exportHandle} />
                    </div>
                    <TableContent history={history} renderData={transcationList || {}} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getTranscationList: PropTypes.func,
    getMerchantList: PropTypes.func,
    getProductOrderStatus: PropTypes.func,
    transcationList: PropTypes.object,
    merchantList: PropTypes.object,
    productOrderStatus: PropTypes.array,
};
const mapStateToProps = (state) => {
    return ({
        merchantList: state.Transcation.merchantList,
        transcationList: state.Transcation.transcationList,
        productOrderStatus: state.productOrder.productOrderStatus,
    });
};
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getTranscationList, getMerchantList, getProductOrderStatus })(Main);