import { createModel } from '@/store/tools';

const initDataList = { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 };

const model = {
    namespace: 'suppliesset',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getPrinterModels': T('getPrinterModels'),
            'getPrinterModelDetail': T('getPrinterModelDetail'),
            'getReceiptTypes': T('getReceiptTypes'),
        },
        actions: {
            'getPrinterModels': A('getPrinterModels'),
            'getPrinterModelDetail': A('getPrinterModelDetail'),
            'getReceiptTypes': A('getReceiptTypes'),
        },
        sagas: {
            'getPrinterModels': S('getPrinterModels', '/printer/listAllPrinterBrandInfo'),
            'getPrinterModelDetail': S('getPrinterModelDetail', '/printer/selectByPrimaryKey'),
            'getReceiptTypes': S('getReceiptTypes', '/printer/listAllTicketTypeInfo'),
        },
        reducers: {
            'cloudPrinterModels': R('getPrinterModels', []),
            'cloudPrinterModelDetail': R('getPrinterModelDetail', {}),
            'cloudPrinterReceiptTypes': R('getReceiptTypes', []),
        }
    })
};
export default createModel(model);