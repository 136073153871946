/**
 *  店铺管理 - 店铺列表
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const Mode = {
    MER_DELETE: 'MER_DELETE',//详情
    MER_BANNED: 'MER_BANNED',//封禁
    NEWCREATE: 'NEWCREATE'//新建
};

class storeList extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case Mode.MER_BANNED:  //封禁
                addOneBreadcrumbPath({
                    title: '店铺封禁',
                    path: '/home/storemanage/list/pages/banInfo/' + value,
                });
                history.push({
                    pathname: '/home/storemanage/list/banInfo/' + value,
                });
                break;
            case Mode.MER_DELETE: //详情
                addOneBreadcrumbPath({
                    title: '详情',
                    path: '/home/storemanage/list/pages/detailInfo/'+ value,
                });
                history.push({
                    pathname: '/home/storemanage/list/detailInfo/' + value,
                });
                break;
            case Mode.NEWCREATE:
                addOneBreadcrumbPath({
                    title: '店铺推荐列表',
                    path: '/home/storemanage/list/pages/recommenlist'
                });
                history.push({
                    pathname: '/home/storemanage/list/recommenlist',
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

storeList.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(storeList);