import React, { useState, useEffect, Fragment } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, message, Modal, Switch, Table, Typography, Button } from 'antd';
import { autoPageNum, autopageSize } from '@/assets/config';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { XTitle, XOKButton, showConfirm } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import BraftEditor from 'braft-editor'; // 引入编辑器组件与引入编辑器样式
import 'braft-editor/dist/index.css';
import { braftEditorMedia } from '@/assets/config';
import model from '@/reducers/systemmanage/suppliesset/model';

const { getAnnouncementData } = model.actions;
const { Text } = Typography;

//编辑、创建、详情弹框
const InfoOperateModal = ({
    tableAction,
    data: { title, readOnly, id, paramValue, isRecommend, text } = {},
    form: { getFieldDecorator, validateFields } = {}
}) => {
    const [loading, setLoading] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                setLoading(true);
                http(!id ? '/supplier/config/notice/add' : '/supplier/config/notice/update', {
                    id, paramType: 'SUPPLIER_NOTICE', ...values, text: values.text.toHTML(), isRecommend: values.isRecommend ? 1 : 0
                }, 'POST').then(res => {
                    message.success(res.message);
                    setLoading(false);
                    tableAction('closeModal', true);
                }).catch(err => {
                    setLoading(false);
                    message.error(err.message);
                });
            }
        });
    };

    return <Modal
        width={860}
        centered
        title={title}
        visible={true}
        bodyStyle={{ padding: '20px 20px 10px 20px' }}
        confirmLoading={loading}
        onCancel={() => tableAction('closeModal')}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        footer={false}
    >
        <Form
            onSubmit={onSubmit}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}
        >
            <Form.Item label='公告标题'>
                {getFieldDecorator('paramValue', {
                    initialValue: paramValue,
                    rules: [
                        { required: true, message: '请输入公告标题' },
                        { type: 'string', max: 10, message: '公告标题最多10个汉字' }
                    ],
                })(<Input disabled={readOnly} style={{ width: '150px' }} placeholder='请输入公告标题' />)}
                <Text style={{ marginLeft: '10px' }} type='secondary'>最多只能输入10个汉字</Text>
            </Form.Item>
            <Form.Item label='是否推荐'>
                {getFieldDecorator('isRecommend', { valuePropName: 'checked', initialValue: isRecommend == 1 })(
                    <Switch disabled={readOnly} checkedChildren='是' unCheckedChildren='否' />)}
            </Form.Item>
            <Form.Item label='公告内容'>
                {getFieldDecorator('text', {
                    initialValue: BraftEditor.createEditorState(text),
                    rules: [
                        {
                            type: 'object', required: true, validator: (rule, value, callback) => {
                                if (value === undefined || value.toHTML() == '<p></p>') {
                                    callback('请输入公告内容');
                                } else {
                                    callback();
                                }
                            }
                        },
                    ],
                })(<BraftEditor readOnly={readOnly} contentClassName={styles.contentClassName} placeholder="请输入公告内容" media={braftEditorMedia} />)}
            </Form.Item>
            <div className={styles.modalFooter}>
                {
                    readOnly ? <Button type='primary' onClick={() => tableAction('closeModal')}>确定</Button> :
                        <Fragment>
                            <Button style={{ marginRight: '20px' }} onClick={() => tableAction('closeModal')}>取消</Button>
                            <Button type='primary' htmlType='submit'>确定</Button>
                        </Fragment>
                }
            </div>
        </Form >
    </Modal>;
};
InfoOperateModal.propTypes = {
    form: PropTypes.object,
    data: PropTypes.object,
    tableAction: PropTypes.func
};
const InfoOperateFrom = Form.create()(InfoOperateModal);

const AnnouncementSet = ({
    getAnnouncementData,
    tableData: {
        dataList = [],
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0
    } = {}
}) => {

    const [openModal, setOpenModal] = useState(false);
    const [modalParams, setModalParams] = useState();
    const [cPageNum, setCPageNum] = useState(autoPageNum);
    const [cPageSize, setCPageSize] = useState(autopageSize);

    useEffect(() => {
        getAnnouncementData({ paramType: 'SUPPLIER_NOTICE', pageNum: cPageNum, pageSize: cPageSize });
    }, [cPageNum, cPageSize]);

    const tableOperate = (t, r) => {
        return <div className={styles.action}>
            {
                r.state == 1 ? <Fragment>
                    <div className={styles.item} onClick={() => operateFunc('openModal', { ...r, title: '公告详情', readOnly: true })}>详情</div>
                    <div className={styles.item}
                        onClick={() => operateFunc('openBan', { id: r.id, state: 0, content: '您是否关闭当前公告，关闭后前端无法展示' })}>禁用</div>
                </Fragment> : <Fragment>
                    <div className={styles.item} onClick={() => operateFunc('openModal', { ...r, title: '编辑公告' })}>编辑</div>
                    <div className={styles.item}
                        onClick={() => operateFunc('openBan', { id: r.id, state: 1, content: '您是否开启当前公告，开启后前端将展示此热词' })}>开启</div>
                </Fragment>
            }
        </div>;
    };

    const sortNoCell = (t, r) => {
        return <Switch disabled={r.state == 0 && t == 0}
            checkedChildren='ON' unCheckedChildren='OFF' checked={t == 1}
            onChange={checked => operateFunc('sortNo', { id: r.id, type: checked ? 1 : 0 })}
        />;
    };

    const columns = [
        {
            title: '标题',
            dataIndex: 'paramValue',
            key: 'paramValue',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '推荐',
            dataIndex: 'isRecommend',
            key: 'isRecommend',
            align: 'center',
            onCell: tooltipStyle,
            render: sortNoCell
        },
        {
            title: '发布时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            onCell: tooltipStyle,
            render: t => tooltip(t == 1 ? '正常' : '禁用')
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            onCell: tooltipStyle,
            render: tableOperate
        }
    ];

    const operateFunc = (type, params) => {
        const eventObject = {
            sortNo: p => {
                http('/supplier/config/recommend', p, 'POST')
                    .then(res => {
                        message.success(res.message);
                        getAnnouncementData({ paramType: 'SUPPLIER_NOTICE', pageNum: cPageNum, pageSize: cPageSize });
                    }).catch(err => message.error(err.message));
            },
            openBan: p => {
                showConfirm(p.content, '', () => {
                    http('/supplier/config/updateState', p, 'POST')
                        .then(res => {
                            message.success(res.message);
                            getAnnouncementData({ paramType: 'SUPPLIER_NOTICE', pageNum: cPageNum, pageSize: cPageSize });
                        })
                        .catch(err => message.error(err.message));
                }, () => { }, '否', '是');
            },
            openModal: p => {
                setOpenModal(true);
                setModalParams(p);
            },
            closeModal: p => {
                setOpenModal(false);
                setModalParams();
                p && getAnnouncementData({ paramType: 'SUPPLIER_NOTICE', pageNum: cPageNum, pageSize: cPageSize });
            }
        };
        eventObject[type](params);
    };

    const paginationChange = (ps, pn) => {
        setCPageNum(pn);
        setCPageSize(ps);
    };

    return <div className={styles.flexBoxContainer}>
        <XTitle label='公告设置' />
        <XOKButton style={{ width: '120px', margin: '10px 0 5px 0' }} label='新建公告'
            onClick={() => operateFunc('openModal', { title: '新建公告', modalType: 'infoOperate' })} />
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey='id'
                columns={columns}
                dataSource={dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
        {openModal && <InfoOperateFrom data={modalParams} tableAction={operateFunc} />}
    </div>;
};

AnnouncementSet.propTypes = {
    getAnnouncementData: PropTypes.func,
    tableData: PropTypes.object
};

export default connect(state => ({
    tableData: state.suppliesset.announcementData
}), { getAnnouncementData })(AnnouncementSet);