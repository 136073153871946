import React from 'react';
import styles from './TableBox.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const TableBox = ({ paginationChange, renderData: { dataList, pageNum, pageSize, resultTotal }, renderMode }) => {

    const renderOprate = (text, item) => {
        return <div className={styles.action}>
            <div
                className={styles.item}
                onClick={() => renderMode({ renderMode: 'update', modeParams: { ruleName: item.ruleName, ruleLimit: item.ruleLimit, ruleRate: item.ruleRate ? item.ruleRate / 100 : 0 } })}>
                复制</div>
        </div>;
    };

    const columns = [
        {
            title: '规则名称',
            dataIndex: 'ruleName',
            key: 'ruleName',
            width: '12%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '兑换比例',
            dataIndex: 'ruleRateStr',
            key: 'ruleRateStr',
            width: '14%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '使用门槛',
            dataIndex: 'ruleLimit',
            key: 'ruleLimit',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '有效期',
            dataIndex: 'timeSection',
            key: 'timeSection',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => {
                const nodeText = `${item.startTime} - ${item.endTime ? item.endTime : '至今'}`;
                return tooltip(nodeText);
            }
        }, {
            title: '状态',
            dataIndex: 'ruleState',
            key: 'ruleState',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: text => {
                const nodeText = text ? <span style={{ color: '#52c414' }}>启用</span> : <span style={{ color: '#f5222d' }}>历史</span>;
                return nodeText;
            }
        }, {
            title: '操作人',
            dataIndex: 'createUserName',
            key: 'createUserName',
            width: '12%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '12%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '操作',
            dataIndex: 'showShellRuleId',
            key: 'showShellRuleId',
            width: '12%',
            align: 'center',
            onCell: tooltipStyle,
            render: renderOprate
        }
    ];

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                columns={columns}
                dataSource={dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};
TableBox.propTypes = {
    renderMode: PropTypes.func.isRequired,
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableBox;