import React from 'react';
import { XOKButton } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { message } from 'antd';

class IncomeWallet extends React.PureComponent {
    render() {
        return (
            <XOKButton
                style={{ marginRight: '15px' }}
                label='收益钱包'
                {...this.props}
                onClick={() => {
                    var tempwin = window.open('about:blank');
                    http('/company/info/walletUrlQuery', {}, 'POST').then((response) => {
                        if (response.code === 200 && (response.result || {}).walletUrl) {
                            tempwin.location = response.result.walletUrl;
                        } else {
                            message.error('路径异常');
                            tempwin.close();
                        }
                    }).catch((e) => {
                        message.error('请完善财务信息再查看，填写财务信息请到：系统设置 -》 财务设置', 5);
                        tempwin.close();
                    });
                }}
            />
        );
    }
}

export default IncomeWallet;
