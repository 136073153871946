/**
 *  审核管理 - 活动商品审核
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class ActivityProductAudit extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    _updateContent = (title, url, params) => {
        const { history, addOneBreadcrumbPath } = this.props;
        const { activityId, activityGoodsId, withdrawId, goodsId, id } = params;
        const takeUrl = `/home/auditmanage/activityProductAudit${url}${activityId}/${activityGoodsId}`;
        addOneBreadcrumbPath({
            title,
            path: takeUrl
        });
        if (withdrawId) {
            history.push({
                pathname: `${takeUrl}/${withdrawId}`,
                curTabID: params.curTabID
            });
        } else if (goodsId) {
            history.push({
                pathname: `${takeUrl}/${goodsId}/${id}`,
                curTabID: params.curTabID
            });
        } else {
            history.push({
                pathname: takeUrl,
                curTabID: params.curTabID
            });
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

ActivityProductAudit.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(ActivityProductAudit);