/**
 * 业务员审核
 */
import React from 'react';
import { Modal, message, Divider, Radio, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './AuditModal.module.scss';
import http from '@/assets/api/http';
import member_model from '@/reducers/usermanage/member/model';
import { connect } from 'react-redux';

const { TextArea } = Input;
class AuditModal extends React.Component {
    state = {
        OCSelect: {}, // 运营中心
        RESelect: {}, // 区域公司
        realName: '', // 姓名
        accountNo: '', // 账号
        mobilePhone: '', // 手机号
        idCard: '', // 身份证
        auditType: '', // 审核状态
        remark: '', // 审核不通过理由
        confirmLoading: false
    };

    componentDidMount() {
        const { param } = this.props;
        if (param.id) {
            http('/settle/stock/getStockBuyerInfo', { id: param.id }, 'POST').then((response) => {
                const result = response.result;
                const { operationsId, operationsName, areaCompanyId, areaCompanyName, realName, accountNo, mobilePhone, idCard } = result;
                this.setState({
                    OCSelect: {
                        id: operationsId,
                        companyName: operationsName
                    },
                    RESelect: {
                        id: areaCompanyId,
                        companyName: areaCompanyName
                    },
                    realName,
                    accountNo,
                    mobilePhone,
                    idCard
                });
            }).catch((err = {}) => {
                message.error(err.message);
            });
        }
    }

    _handleOk = () => {
        const { param } = this.props;
        const { auditType, remark } = this.state;
        const query = {
            id: param.id,
            auditType,
            remark: auditType == 'FAIL_AUDIT' ? remark : undefined
        };

        this.setState({ confirmLoading: true });
        http('/settle/stock/auditStockBuyerInfo', { ...query }, 'POST').then((response) => {
            this.props.close('update');
            this.setState({ confirmLoading: false });
        }).catch((err = {}) => {
            message.error(err.message);
            this.setState({ confirmLoading: false });
        });
    }

    _handleCancel = () => {
        this.props.close();
    }

    render() {
        const { confirmLoading, OCSelect, RESelect, realName, accountNo, mobilePhone, idCard, auditType, remark } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={'审核业务员'}
                visible={true}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.item}>
                        <div className={styles.label}>归属运营中心：</div><div>{OCSelect.companyName}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>归属区域公司：</div><div>{RESelect.companyName}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>姓名：</div><div>{realName}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>账号：</div><div>{accountNo}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>手机号：</div><div>{mobilePhone}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>身份证：</div><div>{idCard}</div>
                    </div>
                    <Divider />
                    <Radio.Group onChange={(e) => {
                        this.setState({ auditType: e.target.value });
                    }}>
                        <Radio value={'PASS_AUDIT'}>审核通过</Radio>
                        <Radio value={'FAIL_AUDIT'}>审核不通过</Radio>
                    </Radio.Group>
                    {
                        auditType == 'FAIL_AUDIT' ? <TextArea
                            style={{ width: 300, marginTop: 10, resize: 'none' }}
                            rows={4}
                            placeholder='请填写拒绝理由'
                            value={remark}
                            onChange={(e) => {
                                this.setState({ remark: e.target.value });
                            }}
                        /> : null
                    }


                </div>
            </Modal>
        );

    }
}

AuditModal.propTypes = {
    param: PropTypes.object, // 弹框入参
    close: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getOperationCenter: PropTypes.func, // 获取归属运营中心
    OCSelectList: PropTypes.array, // 归属运营中心下拉列
    getAreaCompany: PropTypes.func, // 获取归属区域公司
    RESelectList: PropTypes.array, // 归属区域公司下拉列
};

const mapStateToProps = (state) => ({
    OCSelectList: state.member.getOperationCenter,
    RESelectList: state.member.getAreaCompany,
});

export default connect(mapStateToProps, { ...member_model.actions })(AuditModal);
