/**
 * 付款确认 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, Input, Alert, Form, message, DatePicker } from 'antd';
import numSwitch from '@/util/numSwitch';
import { regFenToYuan } from '@/util/money';
import Moment from 'moment';
import styles from './AddRole.module.scss';
import md5 from 'js-md5';

const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 }
    }
};
class NoLogin extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        paymentAmount: 0,//付款合计
        accountName: '',
        bankCardNo: '',
        isValidSerialNo: false
    }

    componentDidMount() {
        const { data } = this.props;
        let { id, paymentAmount } = data || {};
        this.setState({ paymentAmount: regFenToYuan(paymentAmount) });

        http('/settle/paymentApplication/unitPayment', { paymentApplicationId: id }, 'GET').then(res => {
            if (res.code === 200) {
                this.setState(res.result);
            }
        });
    }

    // 点击确认
    _handleOk = () => {
        this.props.form.validateFields((err, { paymentTime, ...fields }) => {
            if (!err) {
                if (this.state.isValidSerialNo) {
                    this.setState({ confirmLoading: true });
                    fields.password = md5(fields.password).toUpperCase();
                    http('/settle/paymentApplication/unitConfirm', {
                        ...fields,
                        'paymentApplictionId': this.props.data.id,
                        'paymentTime': paymentTime ? paymentTime.format('YYYY-MM-DD HH:mm:ss') : ''
                    }, 'POST').then((res) => {
                        if (res.code === 200) message.success('操作成功');
                        else message.error('操作失败，原因：' + res.message);
                    }).catch((res = {}) => {
                        message.error('操作失败，原因：' + res.message);
                    }).finally(() => {
                        this.setState({ visible: false, confirmLoading: false });
                        this.props.closeMode(true);
                    });
                } else {
                    message.warning('流水号不正确');
                }
            }
        });
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({ visible: false });
        this.props.closeMode();
    }

    _validSerialNo = () => {
        let serialNo = this.props.form.getFieldValue('serialNo');
        if (serialNo) {
            http('/settle/paymentApplication/checkSerialNo', { serialNo }, 'GET').then(res => {
                if (res.code === 200) {
                    if (res.result) {
                        message.warning('流水号重复，请重新填写');
                    }
                    this.setState({ isValidSerialNo: res.result !== true });
                }
            });
        } else {
            this.setState({ isValidSerialNo: false });
        }
    }

    render() {
        const { visible, confirmLoading, title, accountName, bankCardNo, paymentAmount } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                width={600}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <Alert message='确认后,付款完成' type='info' showIcon style={{ textAlign: 'left', marginBottom: 10 }} />
                <Form {...formItemLayout}>
                    <Form.Item label='收款账户'>{accountName}</Form.Item>
                    <Form.Item label='银行账号'>{bankCardNo}</Form.Item>
                    <Form.Item label='合计转账金额' >
                        &nbsp;{paymentAmount} ({paymentAmount ? numSwitch(paymentAmount) : ''})
                        </Form.Item>
                    <Form.Item label='付款流水号'>
                        {getFieldDecorator('serialNo', {
                            rules: [{ required: true, message: '请输入' }],
                        })(<Input placeholder='请输入' onBlur={this._validSerialNo} />)}
                    </Form.Item>
                    <Form.Item label='付款时间'>
                        {getFieldDecorator('paymentTime', {
                            initialValue: new Moment(),
                            rules: [{ required: true, message: '请输入' }],
                        })(<DatePicker placeholder='请选择时间' showTime />)}
                    </Form.Item>
                    <Form.Item label='付款说明'>
                        {getFieldDecorator('remark')(<TextArea placeholder='请输入' rows={4} />)}
                    </Form.Item>
                    <Form.Item label='财务密码'>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: '请输入' }],
                        })(<Input.Password placeholder='请输入' />)}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

NoLogin.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]), // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    getPayManageDetail: PropTypes.func
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(NoLogin);// 校验

export default ForgotProducDetails;

// const mapStateToProps = (state) => ({
//     payManageDetail: state.paymanage.payManageDetail
// });
// export default connect(mapStateToProps, { getPayManageDetail })(ForgotProducDetails);