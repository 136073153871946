/**
 * 【增值服务订单页】
 */
import React from 'react';
import { XTabNav } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/ordermanage/valueServiceOrder/model';
import ServiceOrder from '../../../serviceorder';
import Incrementserviceorder from '../incrementserviceorder';
import Distributionsystem from '../distributionsystem';
import { getLocalStorage } from '@/assets/js/storage';

class Main extends React.Component {
    state = {
        pageNum: 1,
        pageSize: 20,
        userState: {},
        payType: {},
        stateItem: {},
        recommendType: {},
        refereesPhone: null,
        batchData: [],
        showTab: [],
        tab: [
            { id: 1, label: '服务系统', code: 'ordermanage/valueserviceorder/FWXTTAB' },
            { id: 2, label: '主播', code: 'ordermanage/valueserviceorder/ZBTAB' },
            { id: 3, label: '分销系统', code: 'ordermanage/valueserviceorder/FXXTTAB' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
    }

    componentDidMount() {
        const { history } = this.props;
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        if (history.location.state) {
            this.setState({ curTabID: history.location.state }); // 如果路由过来的state参数带tabID值，则渲染该tabID值下的内容
        }
        setTimeout(() => { this._getShowTab(); }, 1000);
    }
    _getShowTab = () => {
        const { tab } = this.state;
        let btnPremission = JSON.parse(getLocalStorage('btnPremission')) || [];
        //setTimeout(() => { this._getShowTab(); }, 1000);
        let showTab = tab.filter(i => btnPremission.includes(i.code) || !i.code);
        this.setState({
            showTab
        }, () => {
            this.setState({
                ...this.state,
                curTabID: this.state.showTab[0] && this.state.showTab[0].id
            });
        });
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({
            curTabID: tabID,
        });
    }
    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <div style={{ marginBottom: 10 }}>
                    <XTabNav renderData={this.state.showTab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                </div>
                {this.state.curTabID == 1 ? <Incrementserviceorder location={this.props.location} history={this.props.history} /> : this.state.curTabID == 2 ?
                    <ServiceOrder history={this.props.history} /> : <Distributionsystem location={this.props.location} history={this.props.history} />}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getPayType: PropTypes.func,
    getUserType: PropTypes.func,
    payTypeArray: PropTypes.array,
    userTypeArray: PropTypes.array,
    getValueServiceOrderList: PropTypes.func,
    ValueServiceOrderList: PropTypes.object,
    getOrderStateEnum: PropTypes.func,
    orderStateEnum: PropTypes.array,
    getRecommendTypeEnum: PropTypes.func,
    RecommendTypeEnum: PropTypes.array,
};

Main.default = {
    orderStateEnum: []
};

const mapStateToProps = (state) => ({
    userTypeArray: state.valueServiceOrder.userTypeArray,
    payTypeArray: state.valueServiceOrder.payTypeArray,
    ValueServiceOrderList: state.valueServiceOrder.ValueServiceOrderList,
    orderStateEnum: state.valueServiceOrder.orderStateEnum,
    RecommendTypeEnum: state.valueServiceOrder.RecommendTypeEnum,
});

export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);
