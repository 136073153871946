import React, { Fragment, useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { autoPageNum, autopageSize } from '@/assets/config';
import { XOKButton, XTabNav } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { Divider, Table, DatePicker, Button } from 'antd';
import http from '@/assets/api/http';
import { getLocalStorage } from '@/assets/js/storage';
import moment from 'moment';

const { RangePicker } = DatePicker;

const getTableData = ({ settle, ...other } = {}) => {
    const settles = { OVER_SETTLE: true, AWAIT_SETTLE: false };
    return http('/settle/accountBalance/accountDetails', { ...other, settle: settles[settle] }, 'POST')
        .then(res => { return res.result; });
};

const tabList = [
    { id: 'OVER_SETTLE', label: '实收益收支明细' },
    { id: 'AWAIT_SETTLE', label: '预收益明细' },
];

const BudgetList = ({ history, match: { params: { id } = {} } = {} }) => {

    const [query, setQuery] = useState({
        accountId: '', subjectType: '', accountType: 'MONEY', settle: 'OVER_SETTLE',
        pageNum: autoPageNum, pageSize: autopageSize, startTime: moment(), endTime: moment()
    });
    const [tableData, setTableData] = useState({ dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 });

    useEffect(() => {
        let accountId = '';
        let subjectType = '';
        if (id) {
            accountId = id;
            const url = history.location.pathname || '';
            if (url.includes('companyList/budget')) {
                subjectType = 'REGION_COMPANY';
            } else if (url.includes('operationList/budget')) {
                subjectType = 'OP_CENTER';
            }
        } else {
            const userInfo = JSON.parse(getLocalStorage('auth') || '{}').userInfo;
            const subjectTypes = { OC: 'OP_CENTER', RE: 'REGION_COMPANY' };
            accountId = userInfo.companyId;
            subjectType = subjectTypes[userInfo.accountType];
        }
        (async () => {
            const { startTime, endTime } = query;
            let data = { ...query, accountId, subjectType };
            if (startTime || endTime) data = { ...data, startTime: startTime.format('YYYY-MM-DD 00:00:00'), endTime: endTime.format('YYYY-MM-DD 23:59:59') };
            const result = await getTableData(data);
            setTableData(result);
        })();
        setQuery(pre => { return { ...pre, accountId, subjectType }; });
    }, []);

    const getData = async (params = {}, isDate) => {
        let startTime = isDate ?
            params.startTime.format('YYYY-MM-DD 00:00:00') :
            query.startTime.format('YYYY-MM-DD 00:00:00');
        let endTime = isDate ?
            params.endTime.format('YYYY-MM-DD 23:59:59') :
            query.endTime.format('YYYY-MM-DD 23:59:59');
        setTableData({ dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 });
        let data = { ...query, ...params, startTime, endTime };
        const result = await getTableData(data);
        setTableData(result);
        setQuery(pre => { return { ...pre, ...params }; });
    };

    const columns = [
        { title: '业务时间', dataIndex: 'gmtCreate', key: 'gmtCreate', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '收益来源', dataIndex: 'source', key: 'source', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '业务单号', dataIndex: 'businessNumber', key: 'businessNumber', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '收益变更', dataIndex: 'changeBalance', key: 'changeBalance', align: 'center', onCell: tooltipStyle, render: tooltip },
        //{ title: '剩余收益', dataIndex: 'remainBalance', key: 'remainBalance', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '备注', dataIndex: 'remark', key: 'remark', align: 'center', onCell: tooltipStyle, render: tooltip }
    ];

    return <div className={styles.flexBoxContainer}>
        <div className={styles.search}> <span>业务时间：</span>
            <RangePicker value={[query.startTime || null, query.endTime || null]} allowClear={false}
                onChange={dates => getData({ startTime: dates[0], endTime: dates[1] }, true)} />
        </div>
        <XTabNav renderData={tabList} onChange={settle => getData({ settle })} activedID={query.settle} />
        <div className={styles.tableContainer}>
            <Table rowClassName={(r, i) => i % 2 === 0 ? '' : styles.tdcolor} rowKey='accountId'
                columns={columns} dataSource={tableData.dataList} pagination={false} scroll={{ y: true }} />
            <XPagination resultTotal={tableData.resultTotal} pageSize={tableData.pageSize}
                pageNum={tableData.pageNum} onChange={(pageSize, pageNum) => getData({ pageSize, pageNum })} />
        </div>
        {!!id && <Fragment>
            <Divider style={{ margin: '10px 0' }} />
            <XOKButton label='返回' style={{ width: '90px' }} onClick={history.goBack} />
        </Fragment>}
    </div>;
};
BudgetList.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
};
export default BudgetList;