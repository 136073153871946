import * as T from './actiontypes';

/**
 * 获取主播列表
 */
export const getTVAnchorList = (value={}) => ({
    type: T.GET_TVANCHOR_LIST,
    payload: value
});
/**
 * 获取主播类型
 */
export const getTVAnchorTypeList = (value={}) => ({
    type: T.GET_TVANCHORTYPE_LIST,
    payload: value
});

