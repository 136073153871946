import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '付款日期',
            dataIndex: 'statisticsTime',
            key: 'statisticsTime',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单数',
            dataIndex: 'orderNum',
            key: 'orderNum',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单销售额(元)',
            dataIndex: 'saleAmount',
            key: 'saleAmount',
            width: '8%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '会员数',
            dataIndex: 'memberNum',
            key: 'memberNum',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '售后订单数',
            dataIndex: 'afterSaleNum',
            key: 'afterSaleNum',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '售后金额(元)',
            dataIndex: 'afterSaleAmount',
            key: 'afterSaleAmount',
            width: '8%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '管理费(元)',
            dataIndex: 'companyManageFee',
            key: 'companyManageFee',
            width: '8%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '自然流量服务费(元)',
            dataIndex: 'natureFlowServiceFee',
            key: 'natureFlowServiceFee',
            width: '8%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '小计(元)',
            dataIndex: 'totalProfit',
            key: 'totalProfit',
            width: '8%',
            align: 'center',
            render: priceFenToYuanCell
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;