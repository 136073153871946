import React from 'react';
import styles from './TableData.module.scss';
import { Table, Switch, Icon, Input } from 'antd';
import ButtonPerssion from '@/routes/ButtonPerssion';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
  {
    title: '模板名称',
    dataIndex: 'name',
    key: 'name',
    width: '20%',
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '版本号',
    dataIndex: 'version',
    key: 'version',
    width: '20%',
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '提交时间',
    dataIndex: 'gmtCreate',
    key: 'gmtCreate',
    width: '20%',
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '状态',
    dataIndex: 'isDelete',
    key: 'isDelete',
    width: '20%',
    align: 'center',
    render: (text, item) => (SwitchCell(text, item, actionClickHandle))
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    width: '20%',
    render: (key, item) => TableAction(item.id, actionClickHandle, item)
  },
];

export const takeDataSource = (data) => {
  return data;
};

export const takeColumns = (actionClickHandle) => {
  let data = columns(actionClickHandle);
  return data;
};

// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
  let checked = false;
  if (sw == 0) {
    checked = true;
  }
  return (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
      <Switch
        checkedChildren="ON"
        unCheckedChildren="OFF"
        key={item.id + item['reasonState']}
        checked={checked}
        onChange={(value) => {
          actionClickHandle(item.id, 'switch', value);
        }}
      />
    </div>
  );
};

const TableCell = (text) => {
  return (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
      {text}
    </div>
  );
};

const TableAction = (id, actionClickHandle, item) => {
  return (
    <div className={styles.action}>
      <div className={styles.item} onClick={() => actionClickHandle(id, 'check', '', item)}>查看模板</div>
      {
        <ButtonPerssion code='contractmanagement/contracttemplate/htmbyqssh'>
          <div className={styles.item} onClick={() => actionClickHandle(id, 'merchant')}>已签署商户</div>
        </ButtonPerssion>
      }
    </div>
  );
};