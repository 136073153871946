/**
 * 客服列表
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import GroupTable from './components/GroupTable';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/commercialcollege/groupmanage/model';
import { XInput, XDatePicker, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import CreateGroup from '../../modal/CreateGroup';
import EditGroup from '../../modal/EditGroup';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {

    //初始化
    constructor(props) {
        super(props);
        this.state = {
            renderMode: '',//渲染弹框
            modeParams: {},//弹框入参
            groupName: '',//组别名称
            gmtStartCreate: null,//开始时间
            gmtEndCreate: null//结束时间
        };
    }

    //props校验
    static propTypes = {
        history: PropTypes.object,//路由对象
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getGroupList: PropTypes.func,//获取列表
        groupList: PropTypes.object//列表数据
    }

    //挂载后
    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, groupName, gmtStartCreate, gmtEndCreate } = this.state;
        this.searchCache = {
            groupName, gmtStartCreate, gmtEndCreate,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getGroupList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ groupName: '', gmtStartCreate: null, gmtEndCreate: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建组别
    _addGroup = () => {
        this.setState({ renderMode: 'createGroup' });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        console.log(id, type, value);
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'editInfo':
                this.setState({ renderMode: 'editGroup', modeParams: { ...value } });
                break;
            case 'viewInfo':
                addOneBreadcrumbPath({
                    title: '查看学员',
                    path: '/home/commercialcollege/groupmanage/viewstudent'
                });
                history.push({
                    pathname: '/home/commercialcollege/groupmanage/viewstudent/' + id + '/' + value.groupName
                });
                break;
            case 'deleteInfo':
                http('/show/traineegroup/isExistLiveCourse/' + id, {}, 'POST')
                    .then((resolve) => {
                        if (resolve.result) {
                            showConfirm('课程正在直播中，请等待直播结束后再删除');
                        } else {
                            showConfirm('删除组别学员将一并删除', '', () => {
                                http('/show/traineegroup/deleteTraineeGroup/' + id, {}, 'POST')
                                    .then(() => {
                                        message.success('删除组别成功!');
                                        this._searchHandle('useCache');
                                    }).catch((reject) => {
                                        message.error(reject.message);
                                    });
                            });
                        }
                    }).catch((reject) => {
                        message.error(reject.message);
                    });
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { modeParams, renderMode } = this.state;
        switch (renderMode) {
            case 'createGroup':
                return <CreateGroup
                    visible={true}
                    modeParams={modeParams}
                    closeMode={this._closeMode} />;
            case 'editGroup':
                return <EditGroup
                    visible={true}
                    modeParams={modeParams}
                    closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ renderMode: '' });
        update && this._searchHandle('useCache');
    }

    //渲染组件
    render() {
        const { groupName, gmtStartCreate, gmtEndCreate } = this.state;
        const { groupList } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }
            }>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='组别名称'
                                placeholder='请输入组别名称'
                                value={groupName}
                                bindThis={this}
                                bindName='groupName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                placeholder='选择开始时间'
                                label='创建时间'
                                value={gmtStartCreate}
                                bindThis={this}
                                bindName='gmtStartCreate'
                                isFormat
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                placeholder='选择结束时间'
                                value={gmtEndCreate}
                                bindThis={this}
                                bindName='gmtEndCreate'
                                isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: '120px' }}
                            label='新建组别'
                            onClick={this._addGroup}
                        />
                    </div>
                    <GroupTable renderData={groupList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive >
        );
    }
}

//映射数据源
const mapStateToProps = (state) => ({
    groupList: state.groupmanage.groupList,
});

//暴露组件
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
