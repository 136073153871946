import * as T from './actiontypes';

/**
 * 获取费用名称列表
 */

//营销活动列表
export const getMarketingCampaign = ({ pageNum, pageSize }) => ({
    type: T.GET_MARKETINGCAMPAIGN_List,
    payload: {
        pageNum, pageSize
    }
});

//获取商户
export const getMarketingCampaignUser = ({ merchantName, pageNum, pageSize }) => ({
    type: T.GET_MARKETINGCAMPAIGN_USERList,
    payload: {
        merchantName, pageNum, pageSize
    }
});

//获取商户
export const getWalletMerchantList = ({ pageNum, pageSize }) => ({
    type: T.GET_WALLET_MERCHANTLIST,
    payload: { pageNum, pageSize }
});

