import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

// 获取部门列表
export const getDeptList = function* () {
    yield takeEvery(T.GET_DEPT_LIST, function* requestData(action) {
        try {
            let result = yield http('/admin/dept/listDept', action.payload, 'POST');
            yield put({ type: T.SET_DEPT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取部门详情
export const getDeptDetail = function* () {
    yield takeEvery(T.GET_DEPT_DETAIL, function* requestData(action) {
        try {
            let id = action.payload.id;
            let result = yield http('/admin/dept/getDeptInfoById/' + id, action.payload, 'POST');
            yield put({ type: T.SET_DEPT_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取公司、运营中心列表
export const getCompanyList = function* () {
    yield takeEvery(T.GET_COMPANY_LIST, function* requestData(action) {
        try {
            let result = yield http('/admin/dept/listCompanyInfo', action.payload, 'POST');
            yield put({ type: T.SET_COMPANY_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取公司、运营中心详情
export const getCompanyDetail = function* () {
    yield takeEvery(T.GET_COMPANY_DETAIL, function* requestData(action) {
        try {
            let id = action.payload.id;
            let result = yield http('/admin/dept/getCompanyDetailById/' + id, action.payload, 'POST');
            yield put({ type: T.SET_COMPANY_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

