import * as FEEDBACKTYPE from './actionTypes';

export const feedbackTypeList = (state = [], action) => {
    switch (action.type) {
        case FEEDBACKTYPE.SET_FEEDBACKTYPE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const feedbackTypeDetail = (state = {}, action) => {
    switch (action.type) {
        case FEEDBACKTYPE.SET_FEEDBACKTYPE_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};