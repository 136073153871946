import * as Audit from './actiontypes';

/**
 * @param {*} state 
 * @param {*} action 
 */

const autonymListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const autonymList = (state = autonymListInital, action) => {
    switch (action.type) {
        case Audit.SET_AUTONYM_LIST:
            return action.payload;
        default:
            return state;
    }
};


export const autonymDetail = (state = {}, action) => {
    switch (action.type) {
        case Audit.SET_AUTONYM_DETAIL:
            return action.payload;
        case 'SAGA_ERROR':
            return {};
        default:
            return state;
    }
};