import * as Order from './actionTypes';

//酒店订单列表
export const getHotelOrderList = (param) => ({
    type: Order.GET_HOTELORDER_LIST,
    payload: param
});

// 支付方式
export const getHotelPayWays = (param) => ({
    type: Order.GET_HOTELORDER_PAYWAYS,
    payload: param,
});