/**
 * 【对账数据】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { message } from 'antd';
import SelectScroll from '../components/selectScroll';
import { XSelect, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getMerchantSelectSource } from '@/reducers/storehousefinancialmanage/merchantreconciliation/actions';
import model from '@/reducers/storehousefinancialmanage/reconciliationdata/model';
import AdvertTable from './components/AdvertTable';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends React.Component {

    state = {
        merchant: {},
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getMerchantSelectSource();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { beginDate, endDate, pageNum, pageSize, merchant } = this.state;
        const query = {
            beginDate,
            endDate,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            merchantId: merchant.merchantId,
        };
        this.props.getReconciliationData(query);
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ beginDate: undefined, endDate: undefined, merchant: {}, pageNum: 1, pageSize: 10 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    _tableAction = (id, type, item) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '商户对账明细查询',
                    path: '/home/storehousefinancialmanage/reconciliationdata/detail'
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/reconciliationdata/detail',
                    search: `?gmtAccounting=${item.gmtAccounting}&&merchantId=${item.merchantId}`
                });
                break;
        }
    }

    WidthDrawalHandle = () => {
        setTimeout(() => {
            http('/reconciliation/new/record', {}, 'POST')
                .then(() => {
                    this.setState({ pageNum: 1 }, () => {
                        this._searchHandle('useCache');
                        message.success('成功获取最新数据！');
                    });
                }).catch((reject) => {
                    message.error(reject.message);
                });
        }, 1000);
    }

    render() {
        const { beginDate, endDate, merchant = {} } = this.state;
        const { merchantSelectSource, ReconciliationData } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            {/* <SelectScroll
                                isServerShare={true}
                                style={{ marginRight: '20px' }}
                                selectStyle={{ width: '150px' }}
                                label='商户'
                                placeholder='请选择商户'
                                bindThis={this}
                                bindName='supplierObj'
                                value={supplierObj.id}
                                dataIndex='companyName'
                                keyIndex='id'
                                isOptionBindID={true}
                                apiUrl='/service/provider/listServiceProvider'
                                scrollParams={{
                                    keyWord: '',
                                    pageNum: 1,
                                    pageSize: 50
                                }}
                            /> */}
                            <XSelect
                                label='商户'
                                showSearch
                                placeholder='请选择商户'
                                renderData={merchantSelectSource}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                value={merchant.merchantName}
                                bindThis={this}
                                bindName='merchant'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                placeholder='请选择开始时间'
                                label='交易日期'
                                value={beginDate}
                                bindThis={this}
                                bindName='beginDate'
                                isFormat={true}
                            />
                            <XDatePicker
                                placeholder='请选择结束时间'
                                style={{ marginRight: 20 }}
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.shortcutBox}>
                        <XOKButton
                            style={{ width: 120 }}
                            label='获取最新数据'
                            onClick={() => this.WidthDrawalHandle()}
                        />
                    </div>
                    <AdvertTable renderData={ReconciliationData} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getMerchantSelectSource: PropTypes.func,
    merchantSelectSource: PropTypes.array,
    getReconciliationData: PropTypes.func,
    ReconciliationData: PropTypes.object,
    getReconciliationNewData: PropTypes.func,
    ReconciliationNewData: PropTypes.object,
};

const mapStateToProps = (state) => ({
    merchantSelectSource: state.merchantreconciliation.merchantSelectSource,
    ReconciliationData: state.reconciliationdata.ReconciliationData,
    ReconciliationNewData: state.reconciliationdata.ReconciliationNewData,
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath, keepSecondNavBreadcrumb,
    getMerchantSelectSource, ...model.actions
})(Main);