import * as T from './actiontypes';

export const getCustomListTableSource = (requestProps) => ({
    type: T.GET_CUSTOMLIST_TABLESOURCE,
    payload: {
        requestProps
    }
});

// export const getCustomListHomeTableSource = (requestProps) => ({
//     type: T.GET_CUSTOMLIST_HOME_TABLESOURCE,
//     payload: {
//         requestProps
//     }
// });

// export const getCustomListMallTableSource = (requestProps) => ({
//     type: T.GET_CUSTOMLIST_MALL_TABLESOURCE,
//     payload: {
//         requestProps
//     }
// });

// export const getCustomListSubjectTableSource = (requestProps) => ({
//     type: T.GET_CUSTOMLIST_SUBJECT_TABLESOURCE,
//     payload: {
//         requestProps
//     }
// });

export const getCustomListAdvertTableSource = (requestProps) => ({
    type: T.GET_CUSTOMLIST_ADVERT_TABLESOURCE,
    payload: {
        requestProps
    }
});
