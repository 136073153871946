import { createModel } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 };

const model = {
    namespace: 'billquery',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getTonlyAccountPayList': T('getTonlyAccountPayList'),
            'getAccountBalance': T('getAccountBalance'),
            'getStateEnum': T('getStateEnum'),
        },
        actions: {
            'getTonlyAccountPayList': A('getTonlyAccountPayList'),
            'getAccountBalance': A('getAccountBalance'),
            'getStateEnum': A('getStateEnum'),
        },
        sagas: {
            'getTonlyAccountPayList': S('getTonlyAccountPayList', '/recharge/record/list'),
            'getAccountBalance': S('getAccountBalance', '/recharge/query/balance'),
            'getStateEnum': S('getStateEnum', '/enum/TyRechargeStatus'),
        },
        reducers: {
            'tonlyAccountPayList': R('getTonlyAccountPayList', dataList),
            'accountBalance': R('getAccountBalance', 0),
            'stateEnum': R('getStateEnum', []),
        }
    })
};
export default createModel(model);