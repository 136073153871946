/**
 *  【消息推送】入口页
 * */ 
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const MessageMove = ({history}) => {
    return <Main history={history} />;
};

MessageMove.propTypes = { 
    history: PropTypes.object
};

export default MessageMove;