import * as T from './actiontypes';

export const getInvoiceList = (value) => ({
    type: T.GET_INVOICELIST,
    payload: value
});

export const getOperationCenterInvoiceTableSource = (value) => ({
    type: T.GET_OPERATINGCENTERRECONCILIATION_TABLESOURCE,
    payload: value
});

export const getInvoiceManageUnitSelectSource = (value) => ({
    type: T.GET_INVOICEMANAGE_UNIT_SELECTSOURCE,
    payload: value
});
export const getOperationCenterInvoiceDetail = (value) => ({
    type: T.GET_OPERATINGCENTERRECONCILIATION_DETAIL,
    payload: value
});


