import React, { Component } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import http from '@/assets/api/http';
import { autoPageNum, autopageSize } from '@/assets/config';
import { Tag, message } from 'antd';
import BillQueryTable from './components/BillQueryTable';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XSelect, XOKButton, XCancelButton, XDatePicker, showConfirm } from '@/components/xqxc_ui';
import model from '@/reducers/storehousefinancialmanage/billquery/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class BillQuery extends Component {

    static propTypes = {
        history: PropTypes.object,
        addOneBreadcrumbPath: PropTypes.func,
        keepSecondNavBreadcrumb: PropTypes.func,
        getBillQueryList: PropTypes.func,
        billQueryList: PropTypes.object,
        getStatusEnum: PropTypes.func,
        statusEnum: PropTypes.array,
        getTypeEnum: PropTypes.func,
        typeEnum: PropTypes.array,
        getTimeLabelEnum: PropTypes.func,
        timeLabelEnum: PropTypes.array,
        timeLabelChange: PropTypes.func,
        resetTimeLabel: PropTypes.func,
        getUsernameSele: PropTypes.func,
        usernameSele: PropTypes.object
    }

    static defaultProps = {
        billQueryList: { dataList: [{ one: 1 }], pageNum: 1, pageSize: 30, resultTotal: 0 },
        statusEnum: [],
        typeEnum: [],
        timeLabelEnum: [],
        usernameSele: { merchantIdAndNames: [] }
    }

    componentDidMount() {
        const { getStatusEnum, getTypeEnum, getTimeLabelEnum, getUsernameSele, keepSecondNavBreadcrumb } = this.props;
        getStatusEnum();
        getTypeEnum();
        getTimeLabelEnum();
        getUsernameSele();
        keepSecondNavBreadcrumb();
    }

    state = {
        startTime: null,
        endTime: null,
        statusItem: {},
        typeItem: {},
        timeLabel: '',
        userItem: {}
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { startTime, endTime, statusItem, typeItem, timeLabel, userItem, pageNum, pageSize } = this.state;
        this.searchCache = {
            startTime, endTime, timeLabel,
            status: statusItem.code,
            merId: userItem.merchantId,
            transactionType: typeItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getBillQueryList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ startTime: null, endTime: null, statusItem: {}, typeItem: {}, timeLabel: '', userItem: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        this.props.resetTimeLabel();
    }

    //跳转页面
    _updatePage = (title, url) => {
        const { history, addOneBreadcrumbPath } = this.props;
        const baseUrl = '/home/storehousefinancialmanage/billquery';
        addOneBreadcrumbPath({
            title, path: baseUrl
        });
        history.push({
            pathname: `${baseUrl}${url}`
        });
    }

    //请求函数
    _reFunc = (url, data) => {
        http(url, data, 'POST')
            .then(res => {
                message.success(res.message);
                this._searchHandle('useCache');
            })
            .catch(e => {
                message.error(e.message);
            });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    //表格操作
    _tableOperate = (id, toInfo) => {
        if (toInfo) {
            this._updatePage('钱包对账明细表', `/walletinfo/${id.recondTm}/${id.merId}`);
        } else {
            showConfirm('确认修改结算状态为结算成功。结算成功后商户可以查看到结算数据。', '', () => {
                this._reFunc('/reconciliation/updateReconciliationStatus', { id, status: 'SUCCESS' });
            });
        }
    }

    render() {
        const { billQueryList, statusEnum, typeEnum, timeLabelEnum, timeLabelChange, resetTimeLabel, usernameSele } = this.props;
        const { startTime, endTime, statusItem, typeItem, userItem } = this.state;
        return (
            <KeepAlive id='BillQuery' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XDatePicker
                                label='结算日期'
                                bindThis={this}
                                bindName='startTime'
                                onChange={() => {
                                    resetTimeLabel();
                                    this.setState({ timeLabel: '' });
                                }}
                                value={startTime}
                                showTime={false}
                                isFormat
                                isDateFormat
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                bindThis={this}
                                bindName='endTime'
                                value={endTime}
                                onChange={() => {
                                    resetTimeLabel();
                                    this.setState({ timeLabel: '' });
                                }}
                                showTime={false}
                                isFormat
                                isDateFormat
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='结算状态'
                                placeholder='请选择结算状态'
                                renderData={statusEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={statusItem.value}
                                isOptionBindID
                                bindThis={this}
                                bindName='statusItem'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='交易类型'
                                placeholder='请选择交易类型'
                                renderData={typeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                isOptionBindID
                                value={typeItem.value}
                                bindThis={this}
                                bindName='typeItem'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户名称'
                                placeholder='请选择商户名称'
                                renderData={usernameSele.merchantIdAndNames}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                value={userItem.merchantName}
                                isOptionBindID
                                bindThis={this}
                                bindName='userItem'
                            />
                        </SearchItem>
                        <SearchItem>
                            <div className={styles.other}>
                                {
                                    timeLabelEnum.map(item => {
                                        return <Tag
                                            key={item.code}
                                            style={{ width: '60px', textAlign: 'center', cursor: 'pointer' }}
                                            color={item.active ? '#108ee9' : ''}
                                            onClick={() => {
                                                this.setState({ startTime: null, endTime: null, timeLabel: item.code }, () => {
                                                    this._searchHandle('useCache');
                                                });
                                                timeLabelChange(item.code);
                                            }}
                                        >{item.value}</Tag>;
                                    })
                                }
                            </div>
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.shortcutBox}>
                        <XOKButton
                            style={{ width: '150px' }}
                            label='获取银行最新数据'
                            onClick={() => this._reFunc('/reconciliation/news/record', {})} />
                    </div>
                    <BillQueryTable
                        renderData={billQueryList}
                        paginationChange={this._paginationChange}
                        tableOperate={this._tableOperate}
                    />
                </div>
            </KeepAlive>
        );
    }
}

export default connect(state => ({
    ...state.billQuery
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(BillQuery);