/**
 *  主页 - 欢迎页
 *  npm install ant-design-pro@latest --save
 * */
import React from 'react';
import Background from '../../../assets/image/wbg.png';

class Welcome extends React.Component {
    render() {
        return (
            <div style={{ backgroundImage: `url(${Background})`, width: '100%', height: '100%', backgroundSize: 'cover'}}></div>
        );
    }
}
export default Welcome;