import { createModel } from '@/store/tools';

const model = {
    namespace: 'platformOrder',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getPlatformOrderList': T('getPlatformOrderList'),
            'getPlatCostSettingShowEnum': T('getPlatCostSettingShowEnum'),
        },
        actions: {
            'getPlatformOrderList': A('getPlatformOrderList'),
            'getPlatCostSettingShowEnum': A('getPlatCostSettingShowEnum'),
        },
        sagas: {
            'getPlatformOrderList': S('getPlatformOrderList', '/boss/order/getDistributionOrderList'),
            'getPlatCostSettingShowEnum': S('getPlatCostSettingShowEnum', '/enum/PlatCostSettingShowEnum'),
        },
        reducers: {
            'PlatformOrderList': R('getPlatformOrderList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'PlatCostSettingShowEnum': R('getPlatCostSettingShowEnum', []),
        }
    })
};
export default createModel(model);