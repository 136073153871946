/**
 * 修改人气值
 */
import React from 'react';
import { Modal, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './StarScoreModal.module.scss';
import { XInputNum, XSelect } from '@/components/xqxc_ui';
import { message } from 'antd';
import http from '@/assets/api/http';

const { TextArea } = Input;
const dataSource = [].concat([{ id: '1', name: '增加' }, { id: '2', name: '减少' }]);

class StarScoreModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        selectItem: {}, // 增加、减少
        amount: 0, // 数量
        remark: '', // 系统消息 
        wordLength: 0, // 文本框字数
        tels: ''
    }

    _request = (url, data) => {
        this.setState({ confirmLoading: true });
        http(url, data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('修改成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        }).finally(() => {
            this.setState({ confirmLoading: false });
        });
    }

    _handleOk = () => {
        const { selectItem, amount, remark, tels } = this.state;
        const data = {
            accountType: 'STAR_VALUE', // 魅力值账户
            subjectType: 'USER', // 用户
            subjectIds: this.props.data,
            amount,
            tels,
            remark: '来自-系统修改 ' + remark,
            operateClass: 'SYSTEM_MODIFY', // 一级操作类别
            operateType: 'OTHERS', // 二级操作类别
            balanceType: 'BALANCE', // 余额类型
            sourceDescription: remark // 来源说明
        };
        if (!selectItem.id) {
            message.warn('请选择修改操作');
            return;
        }

        if (selectItem.id == '1') {
            this._request('/settle/accountBalance/batchAdd', data); // 增加
        } else {
            this._request('/settle/accountBalance/batchReduce', data); // 减少
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _areaChangeHandle = (e) => {
        this.setState({ remark: e.target.value, wordLength: e.target.value.length });
    }

    _memberChangeHandle = (e) => {
        this.setState({ tels: e.target.value });
    }

    _renderSinglePatch = () => {
        const { SinglePatch, curValue } = this.props;
        if (SinglePatch) {
            return (
                <div className={styles.single_patch}>
                    <div className={styles.cur_star_score}>当前人气值：{curValue}</div>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        const { visible, confirmLoading, remark, tels, selectItem, amount, wordLength } = this.state;

        return (
            <Modal
                width={466}
                centered
                title='修改人气值'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    {this._renderSinglePatch()}
                    <div className={styles.up}>
                        <XSelect
                            style={{ marginRight: 8 }}
                            selectStyle={{ width: '100px' }}
                            label='修改人气值'
                            placeholder='请选择'
                            renderData={dataSource}
                            dataIndex='name'
                            keyIndex='id'
                            value={selectItem.name}
                            bindThis={this}
                            bindName='selectItem'
                        />
                        <XInputNum
                            inputStyle={{ width: '200px' }}
                            label=''
                            placeholder='请输入人气值'
                            value={amount}
                            bindThis={this}
                            bindName='amount'
                            precision={0}
                            min={0}
                            max={99999}
                        />
                    </div>
                    <div className={styles.down}>
                        <div className={styles.downlabel}>会员账号</div>
                        <TextArea
                            className={styles.textArea}
                            rows={4}
                            onChange={this._memberChangeHandle}
                            value={tels}
                            placeholder='最高支持200人，请输入会员账号，用英文逗号隔开'
                        />
                    </div>
                    <div className={styles.down}>
                        <div className={styles.downlabel}>系统消息</div>
                        <TextArea
                            className={styles.textArea}
                            rows={4}
                            onChange={this._areaChangeHandle}
                            value={remark}
                            maxLength={200}
                        />
                    </div>
                    <div style={{ float: 'right', marginRight: '22px' }}>字数：{wordLength}/200</div>
                </div>
            </Modal>
        );
    }
}

StarScoreModal.propTypes = {
    curValue: PropTypes.number, //  当前人气值
    data: PropTypes.array.isRequired, // 需要修改魅力值的会员对象数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    SinglePatch: PropTypes.bool // 权限：单个修改还是批量修改，当为true时表示单个修改
};

export default StarScoreModal;