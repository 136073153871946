/**
 * 合同线下
 */
import React from 'react';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message, Icon, Button } from 'antd';
import model from '@/reducers/contractmanage/offlinelist/model';
import { isPlat } from '@/assets/js/authType';
import CusotmUpload from '@/components/NewUpload';


class OfflineList extends React.Component {

    state = {
        contracts: [],//新建合同
    }

    componentDidMount() {
        this.props.getOfflineList();
    }

    //删除合同  
    _contractdelete = (key) => {
        http('/contract/updateContract/onlineState', {
            contractName: key.contractName,
            id: key.id,
            contractState: 'DISABLE'
        }, 'POST').then((response) => {
            if (response.status == 200) {
                this.props.getOfflineList();
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    //下载
    _download = (data) => {
        if (data != '') window.location.href = data;
    }

    //生成合同模版 
    _rendercontract = () => {
        const { dataList, } = this.props.offlineList;
        if (dataList.length > 0) {
            return dataList.map((item, index) => {
                return (
                    <div className={`${styles.generateoff} ${styles.right}`} key={index}>
                        <Icon className={styles.external} type="file" />
                        {/* <Icon className={styles.eye} type="eye" title='预览' /> */}
                        <Icon onClick={() => this._contractdelete(item)} className={styles.delete} type="delete" title='删除' />
                        {/* <span title='预览'>预览</span> */}
                        <p>{item.contractName}</p>
                        <div className={styles.contactbot}>
                            <a onClick={() => { this._download(item.attach); }} title='下载'>下载</a>
                            {/* <a tiltle='重新上传'>重新上传</a> */}
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const { dataList } = this.props.offlineList;
        const { contracts } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                <div className={`${styles.tableContainer}`} style={{ overflow: 'auto' }}>
                    <div className={styles.layout}>
                        {this._rendercontract()}
                        {(isPlat()) && <div className={styles.contractoff} title='新建合同'>
                            <Icon className={styles.external} type="file" />
                            <Icon className={styles.internal} type="plus" />
                            <span>新建合同</span>
                            <CusotmUpload
                                accept='.doc,.docx,.pdf,.xls,.xlsx'
                                fileList={contracts}
                                wallMode="single"
                                qiniuUploadProps={{
                                    uploadType: 'file'
                                }}
                                onFileListChange={(info) => {
                                    const length = info.fileList.length;
                                    if (info.fileList[length - 1].status == 'success') {
                                        http('/contract/save/offline', {
                                            contactFile: info.fileList[length - 1].response.key,
                                            contactName: info.fileList[length - 1].name,
                                        }, 'POST').then((response) => {
                                            if (response.status == 200) {
                                                message.success('合同上传成功！');
                                                this.props.getOfflineList();
                                            }
                                        }).catch((e) => {
                                            message.error(e.message);
                                        });
                                    }
                                }}
                            >
                                <Button></Button>
                            </CusotmUpload>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}


OfflineList.propTypes = {
    offlineList: PropTypes.object,//合同列表
    getOfflineList: PropTypes.func, // 合同列表action
};

const mapStateToProps = (state) => {
    return {
        offlineList: state.offlineList.lineList,
    };
};

export default connect(mapStateToProps, { ...model.actions })(OfflineList);