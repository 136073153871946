/**
 * 营销活动 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

/**
 * 列表 Saga
 */
export const MarketingList = function* () {
    yield takeEvery(T.GET_MARKINGLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/marketing/activityInfo/list', action.payload, 'POST');
            yield put({ type: T.SET_MARKINGLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 营销活动编辑获取新建详情 Saga
 */
export const marketingDetails = function* () {
    yield takeEvery(T.GET_MARKETINGDETAIL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/marketing/activityInfo/detail', action.payload, 'POST');
            yield put({ type: T.SET_MARKETINGDETAIL_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 营销活动下拉框 Saga
 */
export const marketingDropDown = function* () {
    yield takeEvery(T.GET_DROPDIWN_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let activity = yield http('/marketing/activityInfo/selectActivityType', action.payload, 'POST');//活动类型
            let platform = yield http('/marketing/activityInfo/selectTargetPlat', action.payload, 'POST');//推送平台
            let activeState = yield http('/marketing/activityInfo/selectActivityIsUse', action.payload, 'POST');//活动状态
            activity.platform = platform.result;
            activity.activeState = activeState.result;
            yield put({ type: T.SET_DROPDIWN_DATA, payload: activity });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 营销活动新建-分类（众筹）选择商品下拉框、全选复选 Saga
 */
export const productRaiseAll = function* () {
    yield takeEvery(T.GET_RAISEALL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/merchant/info/listMerchantIdAndName', action.payload, 'POST');//营销活动新建-分类（众筹）选择商品下拉框
            let checkall = yield http('/user/account/listAccountLevel', action.payload, 'POST');//营销活动新建-全选复选

            yield put({ type: T.SET_RAISEDATA_DATA, payload: result });
            yield put({ type: T.SET_ALL_DATA, payload: checkall });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 营销活动新建-分类（众筹）表格数据Saga
 */
export const productRaiseTable = function* () {
    yield takeEvery(T.GET_RAISETABALE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let table = yield http('/goods/goods/simpleGoodsHasStoreList', action.payload, 'POST');//营销活动新建-分类（众筹）选择商品表格数据
            yield put({ type: T.SET_RAISETABALE_DATA, payload: table });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 *  营销活动》详情》活动商品列表 Saga
 */
export const marketingProductList = function* () {
    yield takeEvery(T.GET_MARKINGPRODUCTLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/marketing/activityInfo/goodsList', action.payload, 'POST');
            yield put({ type: T.SET_MARKINGPRODUCTLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 *  营销活动》详情》活动商品列表 > 详情Saga
 */
export const marketingProductDetails = function* () {
    yield takeEvery(T.GET_MARKINGPRODUCTDETAIL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/marketing/activityGoods/activityGoodsDetail', action.payload, 'POST');
            yield put({ type: T.SET_MARKINGPRODUCTDETAIL_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
