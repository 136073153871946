import { combineReducers } from 'redux';
import {
    suggestionsList,
    suggestionsDetail,
    suggestionsType,
    suggestionsPort,
} from './reducer';

export default combineReducers({
    suggestionsList,
    suggestionsDetail,
    suggestionsType,
    suggestionsPort,
});