/**
 * 增加主播
 */
import React from 'react';
import { Modal, InputNumber, Form, message } from 'antd';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import http from '@/assets/api/http';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};
class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
        };
    }

    // className  img  state 
    _handleOk = () => {
        this._handleSubmit();
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    _handleSubmit = e => {//表单校验提交
        if (e) e.preventDefault();
        this.props.form.validateFields({ force: true }, (err, formDatas) => {
            if (!err) {
                const { data, history } = this.props;
                this.setState({ confirmLoading: true }, () => {
                    http('/boss/order/createBusinessAnchorOrder', {
                        buyerId: data.id,
                        number: formDatas.number,
                    }, 'POST').then((res) => {
                        // message.success('增加成功');
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                        this.props.closeMode();
                        addOneBreadcrumbPath({
                            title: '提交申请',
                            path: {
                                pathname: `/home/usermanage/corporateanchor/pay/${res.result.buyerId}`,
                                search: `?buyerId=${res.result.buyerId}&&orderPayId=${res.result.orderPayId}&&add=1`
                            }
                        });
                        history.push({
                            pathname: `/home/usermanage/corporateanchor/pay/${res.result.buyerId}`,
                            search: `?buyerId=${res.result.buyerId}&&orderPayId=${res.result.orderPayId}&&add=1`
                        });
                    }).catch((reject) => {
                        message.error(reject.message);
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                        this.props.closeMode();
                    });

                });
            }
        });
    };

    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon={false}
                            htmlFor='number'
                            label={<span>开通人数</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('number', {
                                rules: [
                                    { required: true, message: '请输入开通人数' },
                                ],
                            })(
                                <InputNumber min={1} max={999} style={{ width: 220 }} precision={0} />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );

    }
}

CreateModal.propTypes = {
    history: PropTypes.object, // 路由history对象
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
    refsh: PropTypes.func,
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(CreateModal);// 校验

export default connect(null, {addOneBreadcrumbPath})(ForgotProducDetails);
