import React, { useEffect, useState } from 'react';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import styles from './OnlinePlayModal.module.scss';
import { Modal, message, Row, Col, InputNumber, Icon, Typography } from 'antd';
const { Text } = Typography;

const Virtual = ({
    closeMode,
    modeParams: {
        id = 0,
        list = [],
        isAdd = true,
    }
}) => {

    const [loading, setLoading] = useState(false);
    const [dataList, setDataList] = useState([{ id: '0', afterTime: null, achieveNum: null }]);
    const [btnText, setBtnText] = useState('确定');

    useEffect(() => {
        if (isAdd) return;
        setDataList(list);
        setBtnText('终止虚拟');
    }, []);


    //提交
    const onSubmit = () => {
        setLoading(true);
        let url = '/show/popularity/setVirtualPopularity';
        let data = {
            liveId: id,
            setVirtualPopularities: dataList.map(item => {
                return { achieveNum: item.achieveNum, afterTime: item.afterTime };
            })
        };
        if (!isAdd) {
            url = `/show/popularity/terminationVirtualPopularitys/${id}`;
            data = {};
        }
        http(url, data, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            }).catch((error) => {
                message.error(error.message);
                setLoading(false);
            });
    };

    return <Modal
        width={600}
        centered
        title='虚拟人气'
        visible={true}
        confirmLoading={loading}
        onOk={onSubmit}
        onCancel={() => closeMode(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText={btnText}
    >
        <Row>
            <Col span={24} >
                <div style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    paddingBottom: 20
                }}>虚拟规则：</div>
            </Col>
            {
                dataList.map((item, index) => {
                    return <Col span={24} key={index}>
                        <div
                            className={styles.itemBox}
                            style={{ textAlign: 'center', marginBottom: 20 }}
                        >
                            <Text strong>开播</Text>
                            <InputNumber
                                value={item.afterTime}
                                style={{ margin: '0 10px' }}
                                precision={0}
                                min={1}
                                max={999}
                                disabled={!isAdd}
                                onChange={value => {
                                    setDataList(dataList.map(old => {
                                        if (old.id === item.id) {
                                            return { ...old, afterTime: value };
                                        }
                                        return { ...old };
                                    }));
                                }}
                                onBlur={e => {
                                    if (dataList[index - 1]) {
                                        if (dataList[index - 1].afterTime > e.target.value || dataList[index - 1].time == e.target.value) {
                                            message.warning('本条开播时间必须大于上一条开播时间');
                                            setDataList(dataList.map(old => {
                                                if (old.id === item.id) {
                                                    return { ...old, afterTime: null };
                                                }
                                                return { ...old };
                                            }));
                                        }
                                    }
                                }}
                            />
                            <Text strong >分钟，随机增加虚拟人数到</Text>
                            <InputNumber
                                value={item.achieveNum}
                                style={{ margin: '0 10px' }}
                                precision={0}
                                min={1}
                                max={99999}
                                disabled={!isAdd}
                                onChange={value => {
                                    setDataList(dataList.map(old => {
                                        if (old.id === item.id) {
                                            return { ...old, achieveNum: value };
                                        }
                                        return { ...old };
                                    }));
                                }}
                            />
                            <Text strong>个</Text>
                            {
                                index === dataList.length - 1 &&
                                isAdd &&
                                <Icon
                                    type='plus-circle'
                                    theme='filled'
                                    style={{
                                        color: '#1890ff',
                                        fontSize: 20,
                                        marginLeft: 10,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        if (!item.afterTime || !item.achieveNum) {
                                            message.warning('请补全规则信息');
                                            return;
                                        }
                                        const newItem = {
                                            id: `${Math.round(Math.random() * 10000)}`,
                                            afterTime: null,
                                            achieveNum: null
                                        };
                                        const newList = [...dataList];
                                        newList.push(newItem);
                                        if (newList.length > 5) {
                                            message.warning('最多只能增加五条规则');
                                            return;
                                        }
                                        setDataList(newList);
                                    }}
                                />
                            }
                            {
                                isAdd &&
                                <Icon
                                    type='minus-circle'
                                    theme='filled'
                                    style={{
                                        fontSize: 20,
                                        cursor: 'pointer',
                                        marginLeft: 10,
                                    }}
                                    onClick={() => {
                                        if (dataList.length === 1) {
                                            message.warning('规则必须要有一条才能执行');
                                            return;
                                        }
                                        const newList = dataList.filter(old => old.id !== item.id);
                                        setDataList(newList);
                                    }}
                                />
                            }
                        </div>
                    </Col>;
                })
            }
        </Row>
    </Modal>;
};

Virtual.propTypes = {
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    modeParams: PropTypes.object.isRequired,
};

export default Virtual;