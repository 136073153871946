/**
 * 【秀场举报】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import AdvertTable from './components/AdvertTable';
import OnlinePlayModal from './modal/OnlinePlayModal';
import Close from './modal/Close';
import http from '@/assets/api/http';
import { getshowReort, getshowReortState, } from '@/reducers/reportmanage/showReport/actions';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends React.Component {

    state = {
        channelClassify: {},//频道分类
        reportTel: '',//举报人手机号
        publishTel: '',//发布人手机号
        title: '',//标题
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: '', // 当前需要打开的弹窗的入参
        urlParam: '', // 区分短视频、直播
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getshowReortState, } = this.props;
        keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        getshowReortState();//状态
        // getreportStop();禁播
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, channelClassify, publishTel, reportTel, title } = this.state;
        this.searchCache = {
            reportClass: channelClassify.code,//频道分类  
            accountTel: publishTel,//发布人手机号  
            reportAccountTel: reportTel,//举报人手机号  
            contentTitle: title,//标题  
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getshowReort(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            channelClassify: {},
            reportTel: '',
            publishTel: '',
            title: '',
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getshowReort(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (id, record, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                addOneBreadcrumbPath({
                    title: '举报详情',
                    path: `/home/reportmanage/showReport/detail/${id}`
                });
                history.push({
                    pathname: `/home/reportmanage/showReport/detail/${id}`
                });
                break;
            case 'graphic':
                addOneBreadcrumbPath({
                    title: '图文详情',
                    path: `/home/reportmanage/showReport/communityinfo/${id}`
                });
                history.push({
                    pathname: `/home/reportmanage/showReport/communityinfo/${id}`
                });
                break;
            case 'deal':
                addOneBreadcrumbPath({
                    title: '举报处理',
                    path: `/home/reportmanage/showReport/deal/${id}`
                });
                history.push({
                    pathname: `/home/reportmanage/showReport/deal/${id}`
                });
                break;
            case 'play':
                if (record.reportClassValue == '短视频') {
                    http('/show/showReport/getVideoUrl', { fileName: record.fileName }, 'POST').then((res) => {
                        this.setState({
                            currentOpenMode: 'play',
                            openModeParam: res.result,
                            urlParam: record.reportClassValue,
                        });
                    }).catch((reject) => {
                        if (reject.code == '500') {
                            message.warn(reject.message);
                        }
                    });
                } else if (record.reportClassValue == '直播间') {
                    http('/show/showReport/getBroadcasUrl', {
                        liveBroadcasId: record.contentId,
                        // protocal: 'hls',
                    }, 'POST').then((res) => {
                        this.setState({
                            currentOpenMode: 'play',
                            openModeParam: res.result,
                            urlParam: record.reportClassValue,
                        });
                    }).catch((reject) => {
                        if (reject.code == '500') {
                            message.warn(reject.message);
                        }
                    });
                }
                break;
        }
    }
    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'play':
                return <OnlinePlayModal visible={true} urlParam={this.state.urlParam} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'close':
                return <Close title='封禁' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

    render() {
        const { reportTel, publishTel, title, channelClassify } = this.state;
        const { selectData, tableSource, paginations, } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XSelect
                                label='频道分类'
                                placeholder='请选择'
                                renderData={selectData}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                showSearch={true}
                                bindName='channelClassify'
                                value={channelClassify.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='发布人手机号'
                                placeholder='请输入'
                                value={publishTel}
                                bindThis={this}
                                bindName='publishTel'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='举报人手机号'
                                placeholder='请输入'
                                value={reportTel}
                                bindThis={this}
                                bindName='reportTel'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='标题'
                                placeholder='请输入'
                                value={title}
                                bindThis={this}
                                bindName='title'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    {/* <div className={styles.row2}>
                    <XOKButton
                        style={{ width: '100px' }}
                        label='新建'
                        onClick={this._newHandle}
                    />
                </div> */}
                    <AdvertTable renderData={tableSource} tableAction={this._tableAction} pagiNation={paginations} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    tableSource: PropTypes.array, // 表格数据源
    selectData: PropTypes.array, // 分类状态下拉选项
    paginations: PropTypes.object, // 分页数据
    banned: PropTypes.object,//用户举报禁播，封禁类型和封禁理由数据源(saga)
    getreportStop: PropTypes.func,//用户举报禁播，封禁类型和封禁理由数据源(saga)
    getshowReortState: PropTypes.func, // 通过Saga异步请求下拉数据源的Action
    getshowReort: PropTypes.func,//通过Saga异步请求表格数据源的Action
};

const mapStateToProps = (state) => {
    return {
        tableSource: state.showReport.showReortDate.tableSource,
        selectData: state.showReport.showReortDate.selectData,
        paginations: state.showReport.showReortDate.paginations,
        banned: state.showReport.showReortDate.banned,
    };
};
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getshowReort, getshowReortState, })(Main);