import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getRedemptionData = (T) => createSaga(T('getRedemptionData'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/goods/exchangeGoods/findExchangeGoodsPage', { ...action.payload }, 'POST');
                let newList = [];
                if (result.result) {
                    newList = (result.result.dataList || []).map(item => {
                        return { ...item, isEdit: false };
                    });
                }
                yield put({ type: T('originalData'), payload: { ...result.result, dataList: newList } });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const redemptionData = (T) => createReducer((initList) => {
    return (state = initList, { type = '', payload = {} }) => {
        let newList = [];
        switch (type) {
            case T('originalData'):
                return { ...payload };
            case T('updateRecoSort/GET'):
                return {
                    ...state,
                    dataList: state.dataList.map(item => {
                        if (item.exchangeGoodsId === payload.exchangeGoodsId) {
                            return { ...item, ...payload };
                        }
                        return { ...item, isEdit: false };
                    })
                };
            default:
                return state;
        }
    };
}, {
    dataList: [],
    pageNum: 1,
    pageSize: 30,
    resultTotal: 0
});

const model = {
    namespace: 'redemption',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getRedemptionData': T('getRedemptionData'),
            'updateRecoSort': T('updateRecoSort'),
            'getGoodsMainStates': T('getGoodsMainStates'),
            'getGoodsStates': T('getGoodsStates'),
            'getFreights': T('getFreights'),
            'getSkuInfoList': T('getSkuInfoList'),
            'getRedemptionInfo': T('getRedemptionInfo'),
            'getExchangeGoodsPayTypeEnum': T('getExchangeGoodsPayTypeEnum'),
        },
        actions: {
            'getRedemptionData': A('getRedemptionData'),
            'updateRecoSort': A('updateRecoSort'),
            'getGoodsMainStates': A('getGoodsMainStates'),
            'getGoodsStates': A('getGoodsStates'),
            'getFreights': A('getFreights'),
            'getSkuInfoList': A('getSkuInfoList'),
            'getRedemptionInfo': A('getRedemptionInfo'),
            'clearSkuInfoList': C('getSkuInfoList'),
            'getExchangeGoodsPayTypeEnum': A('getExchangeGoodsPayTypeEnum'),
        },
        sagas: {
            'getRedemptionData': getRedemptionData(T),
            'getGoodsMainStates': S('getGoodsMainStates', '/enum/GoodsStateEnum'),
            'getGoodsStates': S('getGoodsStates', '/enum/ExchangeGoodsStateEnum'),
            'getFreights': S('getFreights', '/goods/freightTemplate/listFreightTemplateItem'),
            'getSkuInfoList': S('getSkuInfoList', '/goods/exchangeGoods/findExchangeGoodSkuInfo', 'POST', 'exchangeGoodsId'),
            'getRedemptionInfo': S('getRedemptionInfo', '/goods/exchangeGoods/infoExchangeGoodsInfo'),
            'getExchangeGoodsPayTypeEnum': S('getExchangeGoodsPayTypeEnum', '/enum/ExchangeGoodsPayTypeEnum'),
        },
        reducers: {
            'redemptionData': redemptionData(T),
            'goodsMainStates': R('getGoodsMainStates', []),
            'goodsStates': R('getGoodsStates', []),
            'freights': R('getFreights', []),
            'skuInfoList': R('getSkuInfoList', {}),
            'redemptionInfo': R('getRedemptionInfo', {
                costDetailNames: [],
                exchangeGoodsSkuList: [],
                freightTemplateName: '',
                goodsName: ''
            }),
            'getExchangeGoodsPayTypeEnum': R('getExchangeGoodsPayTypeEnum', []),
        }
    })
};
export default createModel(model);