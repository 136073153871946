// 秀场参数
export const GET_SHOWSETTING_LIST = 'SHOWSET/GET_SHOWSETTING_LIST';
export const SET_SHOWSETTING_LIST = 'SHOWSET/SET_SHOWSETTING_LIST';

// 
export const GET_TOUPRULES_TABLESOURCE = 'SHOWSET/GET_TOUPRULES_TABLESOURCE';
export const SET_TOUPRULES_TABLESOURCE = 'SHOWSET/SET_TOUPRULES_TABLESOURCE';

// 直播时长充值规则列表
export const GET_LIVEDURATIONRECHARGERULES_TABLESOURCE = 'SHOWSET/GET_LIVEDURATIONRECHARGERULES_TABLESOURCE';
export const SET_LIVEDURATIONRECHARGERULES_TABLESOURCE = 'SHOWSET/SET_LIVEDURATIONRECHARGERULES_TABLESOURCE';

//快速回复
export const GET_REPLYLIST_DATA = 'SHOWSET/GET_REPLYLIST_DATA'; // 快速回复列表
export const SET_REPLYLIST_DATA = 'SHOWSET/SET_REPLYLIST_DATA'; // 快速回复列表


//兑换设置
export const GET_CHANGDATA_DATA = 'SHOWSET/GET_CHANGDATA_DATA'; // 兑换设置
export const SET_CHANGDATA_DATA = 'SHOWSET/SET_CHANGDATA_DATA'; // 兑换设置
export const GET_CHANGOTHERDATA_DATA = 'SHOWSET/GET_CHANGOTHERDATA_DATA'; // 赠送好友人气值限额等等
export const SET_CHANGOTHERDATA_DATA = 'SHOWSET/SET_CHANGOTHERDATA_DATA'; // 赠送好友人气值限额等等

//人气规则
export const GET_POPULARLIST_DATA = 'SHOWSET/GET_POPULARLIST_DATA'; // 人气规则列表
export const SET_POPULARLIST_DATA = 'SHOWSET/SET_POPULARLIST_DATA'; // 人气规则列表
export const GET_POPULARDETAIL_DATA = 'SHOWSET/GET_POPULARDETAIL_DATA'; // 人气规则详情
export const SET_POPULARDETAIL_DATA = 'SHOWSET/SET_POPULARDETAIL_DATA'; // 人气规则详情

//推荐规则
export const GET_RECOMMENDLIST_DATA = 'SHOWSET/GET_RECOMMENDLIST_DATA'; // 推荐规则列表
export const SET_RECOMMENDLIST_DATA = 'SHOWSET/SET_RECOMMENDLIST_DATA'; // 推荐规则列表
export const GET_RECOMMENDSELECT_DATA = 'SHOWSET/GET_RECOMMENDSELECT_DATA'; // 推荐规则频道分类
export const SET_RECOMMENDSELECT_DATA = 'SHOWSET/SET_RECOMMENDSELECT_DATA'; // 推荐规则频道分类
export const GET_RECOMMENDCENTENT_DATA = 'SHOWSET/GET_RECOMMENDCENTENT_DATA'; // 推荐规则下拉内容
export const SET_RECOMMENDCENTENT_DATA = 'SHOWSET/SET_RECOMMENDCENTENT_DATA'; // 推荐规则下拉内容
export const GET_RECOMMENDDETAIL_DATA = 'SHOWSET/GET_RECOMMENDDETAIL_DATA'; // 推荐规则详情
export const SET_RECOMMENDDETAIL_DATA = 'SHOWSET/SET_RECOMMENDDETAIL_DATA'; // 推荐规则详情
