/**
 * 处理结果
 */
import React from 'react';
import { Modal, Input, Icon,} from 'antd';
import PropTypes from 'prop-types';
import styles from './ClosedModal.module.scss';
import { XInput, XCheckAll} from '@/components/xqxc_ui';


const { TextArea } = Input;


class ClosedModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        typeInput: '',
        radioValue: 1,
        areaValue:'',
        checkedLists: [
            '湖里店铺','集美店铺'
        ],//默认选定
        options: [
            '湖里店铺','思明店铺','五缘湾店铺','集美店铺'
        ],//所有复选框
        typeCheck: {},//复选框
    }

    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _handleCancel  = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // 商户名称输出框改变时触发
    _inputChangeHandle = (value) => {
      this.setState({typeInput: value});
  }
  
    // 复选框选择
    _onChangecheckbox = (value) => {
        console.log(value);
        // debugger;
        this.setState({typeCheck:value});
    }

    _renderNOaccept = () => {
      const { areaValue } = this.state;
        return (
            <TextArea 
            className={styles.textArea} 
            placeholder='请输入备注'
            rows={4} 
            onChange={this._areaChangeHandle}
            value={areaValue}  
        />
        );
    }
    _areaChangeHandle = (e) => {
      this.setState({areaValue:e.target.value});
  }

    render(){
        const { visible, confirmLoading, title, typeInput,options,checkedLists } = this.state;
        
        return(
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
               <div className={styles.container}>
                  <div className={styles.top}>
                      <div>
                          <Icon type="info" className={styles.icon}/>
                          <p>商户在会员端不可以再开始新的交易，无法搜索到该商户的商品。</p>
                      </div>
                  </div>
                    <XInput 
                        style={{width:'408px'}} 
                        inputStyle={{width:'340px'}}
                        label='停业商户' 
                        placeholder='商户' 
                        onChange={this._inputChangeHandle} 
                        value={typeInput}
                    />
                    <div className={styles.checkboxs}>
                         <XCheckAll 
                              placecontent='全选' 
                              options={options} 
                              inputAllStyle={{margin:'30px 0 15px 0'}}
                              defaultCheckedList={checkedLists} 
                              onChange={this._onChangecheckbox}>
                         </XCheckAll>
                    </div>
                    <div className={styles.result}>
                      <span>停业事由</span>
                      <p>{this._renderNOaccept()}</p>
                    </div>
                </div>
            </Modal>
        );
    }
}

ClosedModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string // 弹框标题
};

export default ClosedModal;