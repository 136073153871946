import { createModel } from '@/store/tools';

const model = {
    namespace: 'productgroup',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getProductGroupList': T('getProductGroupList'),
        },
        actions: {
            'getProductGroupList': A('getProductGroupList'),
        },
        sagas: {
            'getProductGroupList': S('getProductGroupList', '/marketing/activityGoodsGrouping/find'),
        },
        reducers: {
            'productGroupList': R('getProductGroupList', []),
        }
    })
};
export default createModel(model);