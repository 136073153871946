import { combineReducers } from 'redux';
import {
	customListTable,
	// customListMallTable,
	// customListSubjectTable,
	customListAdvertTable,
} from './reduce';

export default combineReducers({
	customListTable,
	// customListMallTable,
	// customListSubjectTable,
	customListAdvertTable,
});