import * as T from './actiontypes';

export const getSupplierListTableSource = (value) => ({
    type: T.GET_SUPPLIERLIST_TABLESOURCE,
    payload: value
});

export const getShopTypes = (value) => ({
    type: T.GET_SHOPTYPES,
    payload: value
});

export const getGoodsTypes = (value) => ({
    type: T.GET_GOODSTYPES,
    payload: value
});

/**
 * 获取仓库类型
 */
export const getWarehouseType = (value = {}) => ({
    type: T.GET_WAREHOUSE_TYPE,
    payload: value
});

/**
 * 获取区域：省市区详细信息
 * @param {*} value {id, type} 
 * id: 搜索的子级区域ID
 * type：省province|市city|区street
 */
export const getListAreaByParentId = (value = {}) => ({
    type: T.GET_ACCOUNT_AREA,
    payload: value
});
