/**
 * 任务分类
 */
import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import CreateOrEdit from '../../modal/CreateOrEdit';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getTaskClassifyList } from '@/reducers/taskManage/taskClassify/actions';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: [], // 当前需要打开的弹窗的入参
            classifyState: {},
            classifyStates: [
                { key: '1', value: '启用' },
                { key: '0', value: '禁用' }
            ], // 分类状态
            categoryTitle: '', // 分类名称
            tableItem: {},
        };
    }

    // 创建分类
    _createClassify = () => {
        this.setState({ currentOpenMode: 'CREAT', tableItem: {} });
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { classifyState, categoryTitle, pageNum, pageSize } = this.state;
        this.searchCache = {
            categoryTitle, state: classifyState.key,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getTaskClassifyList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ classifyState: {}, categoryTitle: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST').then(() => {
            message.success(winTxt);
            this._searchHandle('useCache');
        }).catch((error) => {
            message.error(error.message);
        });
    }

    // 表格操作
    _tableAction = (id, activeType, params) => {
        console.log(params);
        switch (activeType) {
            case 'edit':
                this.setState({ currentOpenMode: 'EDITE', tableItem: params });
                break;
            case 'switch':
                this._sendReq('/taskcategory/updateState', { id, state: params.state }, '任务分类启用/禁用成功。');
                break;
            case 'delete':
                showConfirm('确定要删除该任务分类？', '', () => {
                    this._sendReq('/taskcategory/delete', { id }, '任务分类删除成功。');
                });
                break;
            case 'sort':
                this._sendReq('/taskcategory/order', { id, sort: params.sortNo }, '任务分类排序成功。');
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { tableItem } = this.state;
        switch (this.state.currentOpenMode) {
            case 'CREAT':
                return <CreateOrEdit title='创建任务分类' visible={true}
                    data={{ action: 'create', url: '/taskcategory/add', tableItem: {} }}
                    closeMode={this._closeMode} />;
            case 'EDITE':
                return <CreateOrEdit title='编辑任务分类' visible={true}
                    data={{ action: 'edit', url: '/taskcategory/update', tableItem: tableItem }} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', tableItem: {} }, () => {
            if (update) this._searchHandle('useCache');
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { classifyState, categoryTitle, classifyStates } = this.state;

        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XSelect
                                label='分类状态'
                                placeholder='请选择'
                                renderData={classifyStates}
                                bindThis={this}
                                bindName='classifyState'
                                dataIndex='value'
                                keyIndex='key'
                                value={classifyState.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='分类名称'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='categoryTitle'
                                value={categoryTitle}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '100px' }} label='新建分类' onClick={this._createClassify} />
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={this.props.taskClassifyList} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    getTaskClassifyList: PropTypes.func,

    taskClassifyList: PropTypes.object
};

const mapStateToProps = (state) => ({
    taskClassifyList: state.taskClassify.taskClassifyList
});

export default connect(mapStateToProps, { getTaskClassifyList })(Main);