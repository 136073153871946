import { createModel } from '@/store/tools';

const tableData = { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 };

const model = {
    namespace: 'transactionreconciliation',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getTransactionTableData': T('getTransactionTableData'),
            'getTransactionDetailData': T('getTransactionDetailData'),
            'getUnionpayTableData': T('getUnionpayTableData'),
            'getUnionpayDetailData': T('getUnionpayDetailData'),
            'getLianlianTableData': T('getLianlianTableData'),
            'getLianlianDetailData': T('getLianlianDetailData'),
            'getFinancialTypeEnum': T('getFinancialTypeEnum'),
            'getHuifuPayTableData': T('getHuifuPayTableData'),
            'getHuifuPayDetailData': T('getHuifuPayDetailData'),
            'getAdvanceTableData': T('getAdvanceTableData'),
            'getAdvanceDetailData': T('getAdvanceDetailData'),
            'getAdvanceDetailList': T('getAdvanceDetailList'),
        },
        actions: {
            'getTransactionTableData': A('getTransactionTableData'),
            'getTransactionDetailData': A('getTransactionDetailData'),
            'getUnionpayTableData': A('getUnionpayTableData'),
            'getUnionpayDetailData': A('getUnionpayDetailData'),
            'getLianlianTableData': A('getLianlianTableData'),
            'getLianlianDetailData': A('getLianlianDetailData'),
            'getFinancialTypeEnum': A('getFinancialTypeEnum'),
            'getHuifuPayTableData': A('getHuifuPayTableData'),
            'getHuifuPayDetailData': A('getHuifuPayDetailData'),
            'getAdvanceTableData': A('getAdvanceTableData'),
            'getAdvanceDetailData': A('getAdvanceDetailData'),
            'getAdvanceDetailList': A('getAdvanceDetailList'),
        },
        sagas: {
            'getTransactionTableData': S('getTransactionTableData', '/boss/merchant/reconciliation/daily/transactionFlow/list'),
            'getTransactionDetailData': S('getTransactionDetailData', '/boss/merchant/reconciliation/daily/transactionFlow/detail/orderList'),
            'getUnionpayTableData': S('getUnionpayTableData', '/boss/merchant/reconciliation/union/list/reconciliation'),
            'getUnionpayDetailData': S('getUnionpayDetailData', '/boss/merchant/reconciliation/union/detail/reconciliation/orderList'),
            'getLianlianTableData': S('getLianlianTableData', '/boss/merchant/reconciliation/lianlian/list/reconciliation'),
            'getLianlianDetailData': S('getLianlianDetailData', '/boss/merchant/reconciliation/lianlian/detail/reconciliation'),
            'getFinancialTypeEnum': S('getFinancialTypeEnum', '/enum/ViewFinancialTypeEnum'),
            'getHuifuPayTableData': S('getHuifuPayTableData', '/boss/merchant/reconciliation/adaPay/list/reconciliation'),
            'getHuifuPayDetailData': S('getHuifuPayDetailData', '/boss/merchant/reconciliation/adaPay/detail/reconciliation'),
            'getAdvanceTableData': S('getAdvanceTableData', '/settle/reconciliationInfo/prepaid/reconciliationList'),
            'getAdvanceDetailData': S('getAdvanceDetailData', '/settle/reconciliationInfo/prepaid/reconciliationDetail'),
            'getAdvanceDetailList': S('getAdvanceDetailList', '/boss/merchant/reconciliation/prepaid/detail/reconciliation/orderList'),
        },
        reducers: {
            'transactionTableData': R('getTransactionTableData', tableData),
            'transactionDetailData': R('getTransactionDetailData', tableData),
            'unionpayTableData': R('getUnionpayTableData', tableData),
            'unionpayDetailData': R('getUnionpayDetailData', tableData),
            'lianlianTableData': R('getLianlianTableData', tableData),
            'lianlianDetailData': R('getLianlianDetailData', tableData),
            'FinancialTypeEnum': R('getFinancialTypeEnum', []),
            'huifuPayTableData': R('getHuifuPayTableData', tableData),
            'huifuPayDetailData': R('getHuifuPayDetailData', tableData),
            'advanceTableData': R('getAdvanceTableData', tableData),
            'advanceDetailData': R('getAdvanceDetailData', {}),
            'advanceDetailList': R('getAdvanceDetailList', tableData),
        }
    })
};
export default createModel(model);