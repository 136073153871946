import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, } from '@/components/TableCell';

class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '付款日期',
            dataIndex: 'payTime',
            key: 'payTime',
            width: '9%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '服务商',
            dataIndex: 'serviceName',
            key: 'serviceName',
            width: '9%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '区域公司',
            dataIndex: 'areaCompanyName',
            key: 'areaCompanyName',
            width: '9%',
            align: 'center',
            render: tooltip
        },
        {
            title: '运营中心',
            dataIndex: 'companyName',
            key: 'companyName',
            width: '9%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单数',
            dataIndex: 'orderCount',
            key: 'orderCount',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品销量',
            dataIndex: 'goodsCount',
            key: 'goodsCount',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单金额(元)',
            dataIndex: 'totalOrderPayFee',
            key: 'totalOrderPayFee',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '销售占比(%)',
            dataIndex: 'salePercentages',
            key: 'salePercentages',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '客单价(元)',
            dataIndex: 'totalOrderPayFeeUnitPrice',
            key: 'totalOrderPayFeeUnitPrice',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '分销收益(元)',
            dataIndex: 'totalOrderIncome',
            key: 'totalOrderIncome',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '分销收益占比(%)',
            dataIndex: 'incomePercentages',
            key: 'incomePercentages',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '客单收益(元)',
            dataIndex: 'totalOrderIncomeUnitPrice',
            key: 'totalOrderIncomeUnitPrice',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList.map(item => {
                return item;
            });
        }
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;