/**
 * 交割
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message, InputNumber } from 'antd';
import styles from './ExchangeModal.module.scss';
import { XInfoLabel } from '@/components/xqxc_ui';
import { regFenToYuan } from '@/util/money';
import { BigNumber } from 'bignumber.js';

const Delivery = ({
    modeParams: {
        integralBalance
    },
    closeMode
}) => {

    const [loading, setLoading] = useState(false);
    const [canChange, setCanChange] = useState(true);
    const [exchangeInfo, setExchangeInfo] = useState({});
    const [exchangeValue, setExchangeValue] = useState('');
    const [canExchangeInfo, setCanExchangeInfo] = useState({ realReduceShareNum: 0, realReduceExchangeValue: 0 });

    //确定取消
    const onSumbit = () => {
        setLoading(true);
        http('/integralExchangeVoucher/input/calculate', { exchangeIntegral: exchangeValue }, 'POST')
            .then(res => {
                setLoading(false);
                Modal.confirm({
                    title: '确定要兑换吗？',
                    content: `确定兑换后，将扣除${res.result.deductionIntegral}特权值，可兑换${res.result.calculateNum}股`,
                    onOk: () => {
                        http('/integralExchangeVoucher/createVoucher', { exchangeIntegral: exchangeValue }).then(res1 => {
                            closeMode(true);
                        }).catch(res1 => {
                            message.error(res1.message);
                            setLoading(false);
                        });
                    }
                });
            }).catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    function setMaxChange() {
        setExchangeResult(integralBalance);
    }

    function onChangeValue(value) {
        if (value > integralBalance) {
            message.warning('您输入的特权值已超可用特权值，请重新输入');
            return;
        }
        setExchangeResult(value);
    }

    function setExchangeResult(changeValue = exchangeValue) {

        let realReduceShareNum = 0, realReduceExchangeValue = 0;
        if (typeof changeValue === 'number') {
            changeValue = new BigNumber(changeValue);
            let closingPrice = new BigNumber(exchangeInfo.closingPrice).div(100);
            realReduceShareNum = changeValue.div(exchangeInfo.ratioRmb).dividedToIntegerBy(closingPrice);
            if (realReduceShareNum.gte(exchangeInfo.minShares)) {
                realReduceShareNum = realReduceShareNum.minus(realReduceShareNum.modulo(exchangeInfo.multiple));
            }
            realReduceExchangeValue = realReduceShareNum.multipliedBy(exchangeInfo.ratioRmb).multipliedBy(closingPrice).integerValue(BigNumber.ROUND_CEIL);
            // if (realReduceShareNum.lt(exchangeInfo.minShares)) {
            //     message.warning('您输入的特权值已小于最低兑换特权值，请重新输入');
            //     return;
            // }
            if (realReduceShareNum.gt(exchangeInfo.maxShares)) {
                message.warning('您输入的特权值已超过最大兑换特权值，请重新输入');
                return;
            }

            changeValue = changeValue.toString();
            realReduceShareNum = realReduceShareNum.toString();
            realReduceExchangeValue = realReduceExchangeValue.toString();
        }

        setExchangeValue(changeValue);
        setCanExchangeInfo({
            realReduceShareNum,
            realReduceExchangeValue
        });
        // * 积分 / 人民币比例 / 当前股价 = 当股数(兑换股数) 
        // * 当股数>设置的最小多少起对
        // * 实际扣除股数 = 当股数 - 股数%设置的最小多少起兑
        // * 实际扣除积分 = 实际扣除股数*人民币比例*当前股价(向上取整)
    }

    useEffect(() => {
        http('/integralExchangeVoucher/init/calculate').then(res => {
            if (res.code === 200) {
                setExchangeInfo(res.result);
                setCanChange(res.result.state);
            }
        });
    }, []);

    return (
        <Modal
            width={800}
            centered
            title='兑换股票收益'
            visible={true}
            confirmLoading={loading}
            onOk={onSumbit}
            onCancel={() => closeMode(false)}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='立即兑换'
            okButtonProps={{
                disabled: !canChange
            }}
        >
            <div className={styles.container}>
                <XInfoLabel
                    style={{ marginBottom: 20 }}
                    label='可用特权值：'
                    value={integralBalance}
                />
                <div className={styles.stockInfo}>
                    <img src={exchangeInfo.sharesUrl} />
                    <div>
                        <XInfoLabel
                            style={{ marginBottom: 20 }}
                            label=''
                            value={exchangeInfo.sharesName}
                        />
                        <XInfoLabel
                            style={{ marginBottom: 20 }}
                            label='股票代码：'
                            value={exchangeInfo.sharesCode}
                        />
                        <XInfoLabel
                            style={{ marginBottom: 20 }}
                            label='当前价格：'
                            value={canChange ? `${regFenToYuan(exchangeInfo.closingPrice)}（港元）/股` : '暂无数据'}
                        />
                    </div>
                </div>
                <div className={styles.maxExchange}>
                    {canChange &&
                        <>
                            <XInfoLabel
                                style={{ marginRight: 15 }}
                                label=''
                                value={`您最高可兑换${exchangeInfo.calculateNum}股`}
                            />
                            <a onClick={setMaxChange}>全部兑换</a>
                        </>
                    }
                    {!canChange && <span>未获取到当前价格，不能进行兑换</span>}
                </div>
                <InputNumber value={exchangeValue} placeholder='请输入特权值数量' style={{ width: 300 }} min={0} max={integralBalance} disabled={!canChange} onChange={onChangeValue} />
                <div className={styles.canChange}>根据计算，你输入的特权值可兑换{canExchangeInfo.realReduceShareNum}股,实际扣除{canExchangeInfo.realReduceExchangeValue}特权值</div>
                <div className={styles.notice}>
                    <div>注意：</div>
                    <div>1、每天最高只能兑换{exchangeInfo.maxShares}股</div>
                    <div>2、每次最低可兑换{exchangeInfo.minShares}股</div>
                </div>
            </div>
        </Modal>
    );
};
Delivery.propTypes = {
    modeParams: PropTypes.object.isRequired,
    closeMode: PropTypes.func.isRequired
};
export default Delivery;