import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

const arrayChange = (result) => {
    let list = [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []];
    result.forEach(item => {
        if (item.serviceCode === 'SJ0001') {
            if (item.recommendType === 'SERVICE_APP') {
                item.regionCompanyType == 'DIRECT' ? list[0].push(item) : list[1].push(item);
            } else {
                item.regionCompanyType == 'DIRECT' ? list[8].push(item) : list[9].push(item);
            }
        } else if (item.serviceCode === 'SJ0002') {
            if (item.recommendType === 'SERVICE_APP') {
                item.regionCompanyType == 'DIRECT' ? list[2].push(item) : list[3].push(item);
            } else {
                item.regionCompanyType == 'DIRECT' ? list[10].push(item) : list[11].push(item);
            }
        } else if (item.serviceCode === 'SJ0003') {
            if (item.recommendType === 'SERVICE_APP') {
                item.regionCompanyType == 'DIRECT' ? list[4].push(item) : list[5].push(item);
            } else {
                item.regionCompanyType == 'DIRECT' ? list[12].push(item) : list[13].push(item);
            }
        } else if (item.serviceCode === 'XY04') {
            if (item.recommendType === 'SERVICE_APP') {
                item.regionCompanyType == 'DIRECT' ? list[6].push(item) : list[7].push(item);
            } else {
                item.regionCompanyType == 'DIRECT' ? list[14].push(item) : list[15].push(item);
            }
        }
    });
    return list;
};

/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getserviceReadonlyLevel = (T) => createSaga(T('getserviceReadonlyLevel'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            console.log(action, 'action');
            try {
                let result = yield http('/service/level/generateLevel', {}, 'POST');//+ action.payload
                yield put({ type: T('level'), payload: result });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const serviceReadonlyLevel = (T) => createReducer((autostate) => {
    return (state = autostate, action) => {
        switch (action.type) {
            case T('level'):
                return { ...state, level: action.payload.result };
            default:
                return state;
        }
    };
}, { level: null });

/**
 * 特权值设置
 */
const getPrivilegeParam = (T) => createSaga(T('getPrivilegeParam'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData() {
            try {
                let result = yield http('/gatherbeans/getPrivilegeParam', {}, 'GET');
                let list = arrayChange(result.result || []);
                yield put({ type: T('getPrivilegeParam'), payload: list });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 特权值设置
 */
const privilegeParam = (T) => createReducer((autostate) => {
    return (state = autostate, { type, payload }) => {
        switch (type) {
            case T('getPrivilegeParam'):
                return payload;
            case T('updateParams/GET'):
                {
                    let list = [...state];
                    list[payload.index][payload.self].value = payload.value;
                    return list;
                }
            default:
                return state;
        }
    };
}, []);



const model = {
    namespace: 'servicesetting',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getserviceLevelList': T('getserviceLevelList'),
            'getserviceLevelCode': T('getserviceLevelCode'),
            'getserviceReadonlyLevel': T('getserviceReadonlyLevel'),
            'getServiceLevelInfo': T('getServiceLevelInfo'),
            'getBeanInfo': T('getBeanInfo'),
            'getExchangeRules': T('getExchangeRules'),
            'getPrivilegeParam': T('getPrivilegeParam'),
            'updateParams': T('updateParams'),
        },
        actions: {
            'getserviceLevelList': A('getserviceLevelList'), // 服务商等级列表
            'getserviceLevelCode': A('getserviceLevelCode'), // 对应code
            'getserviceReadonlyLevel': A('getserviceReadonlyLevel'), // 新建时自动获取最新的序号（服务商等级
            'getServiceLevelInfo': A('getServiceLevelInfo'), // 获取服务商等级详情
            'getBeanInfo': A('getBeanInfo'), // 获取能量值设置默认数据
            'getCleanBeanInfo': C('getBeanInfo'), // 清除能量值设置默认数据
            'getExchangeRules': A('getExchangeRules'), // 贡献值兑换规则列表
            'getPrivilegeParam': A('getPrivilegeParam'),
            'updateParams': A('updateParams'),
        },
        sagas: {
            'getserviceLevelList': S('getserviceLevelList', '/service/level/list'),
            'getserviceLevelCode': S('getserviceLevelCode', '/enum/GatherBeansParamEnum'),
            'getserviceReadonlyLevel': getserviceReadonlyLevel(T), // 自定义saga写法，使用依赖注入
            'getServiceLevelInfo': S('getServiceLevelInfo', '/service/level/info', 'POST', 'id'),
            'getBeanInfo': S('getBeanInfo', '/gatherbeans/getCodeList', 'GET'),
            'getExchangeRules': S('getExchangeRules', '/gatherBeans/showShellRule/findShowShellRulePage'),
            'getPrivilegeParam': getPrivilegeParam(T),
        },
        reducers: {
            'serviceLevel': R('getserviceLevelList', []),
            'getLevelCode': R('getserviceLevelCode', []),
            'readonlyLevel': serviceReadonlyLevel(T), // 自定义reducer写法，使用依赖注入
            'serviceLevelInfo': R('getServiceLevelInfo', {}),
            'beanInfo': R('getBeanInfo', []),
            'exchangeRules': R('getExchangeRules', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'privilegeParam': privilegeParam(T),
        }
    })
};
export default createModel(model);

