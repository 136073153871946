/**
 * [批量设置渠道会员等级]
 * @author: Fu Xiaochun
 * @date: 2023-03-06 
 */

import React, { useEffect, useState } from 'react';
import styles from './BatchSetMemberLevel.module.scss';
import { Modal, message, Input, Button, Select } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';

function BatchSetMemberLevel(props){

    const [accounts, setAccounts] = useState('');
    const [level, setLevel] = useState();
    const [levelEnum, setLevelEnum] = useState([]);

    useEffect(()=>{
        getLevelEnum();
    }, []);

    const getLevelEnum = ()=>{
        http('/user/supplierAccountLevel/listSupplierAccountLevel').then(res=>{
            let temp = res.result.map(v=>{
                return {
                    code: v.id,
                    value: v.levelName
                };
            });
            setLevelEnum(temp);
        }).catch(err=>{
            message.error(err.message);
        });
    };

    const onAccountsChange = (e)=>{
        let val = e.target.value || '';
        setAccounts(val);
    };

    const onSubmit = ()=>{
        let _accounts = accounts.replace('，', ',').replace(/^,|,$/, ',').replace(/\s/g, '');
        let accountsList = _accounts.split(',') || [];
        accountsList = accountsList.filter(v=>!!v);

        if(accountsList.length == 0){
            message.error('请输入会员账号，最多可输入200个');
            return false;
        }
        if(accountsList.length > 20){
            message.error('输入的会员账号超过200个了，最多只支持200个');
            return false;
        }
        if(!level && level != 0){
            message.error('请选择要设置的会员等级！');
            return false;
        }

        let formData = {
            supplierAccountNames: accountsList,
            accountLevelId: level
        };

        http('/user/supplierAccountInfo/batchUpdateSupplierAccountLevelId', formData).then(res=>{
            message.success('提交成功！');
            props.onOk();
        }).catch(err=>{
            message.error(err.message);
        });
        
    };

    return <>
        <Modal title="批量设置会员等级" visible={true} width="800px" onOk={onSubmit} onCancel={props.onClose} >
            <div className={styles.formBox}>
                <div className={styles.formItem}>
                    <div className={styles.label}>会员账号</div>
                    <div className={styles.value}>
                        <Input.TextArea className={styles.txts} onChange={onAccountsChange} value={accounts} placeholder='最高支持200人，请输入会员账号，用英文“,”逗号隔开'></Input.TextArea>
                    </div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.label}>会员等级</div>
                    <div className={styles.value}>
                        <Select style={{ width: 120 }} onChange={setLevel} placeholder="请选择">
                            {levelEnum.map(v=>(<Select.Option key={v.code} value={v.code}>{v.value}</Select.Option>))}
                        </Select>
                    </div>
                </div>
            </div>
        </Modal>
    </>;
}

BatchSetMemberLevel.propTypes = {
    onOk: PropTypes.func,
    onClose: PropTypes.func,
};

export default BatchSetMemberLevel;