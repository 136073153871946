import React from 'react';
import styles from './TableData.module.scss';
import { Switch, } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => ([
    {
        title: '规则名称',
        dataIndex: 'ruleName',
        key: 'ruleName',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '商品分类',
        dataIndex: 'categoryName',
        key: 'categoryName',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '推荐规则',
        dataIndex: 'recoRule',
        key: 'recoRule',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '生效时间',
        dataIndex: 'startTime',
        key: 'startTime',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '失效时间',
        dataIndex: 'endTime',
        key: 'endTime',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作人',
        dataIndex: 'modifyUserName',
        key: 'modifyUserName',
        width: '12%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '9%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle))
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '13%',
        render: (id, record) => TableAction(id, record, actionClickHandle)
    },
]);

export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    return data;
};


// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
    let checked = true;
    if (sw == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={(value) => {
                    actionClickHandle(item, 'switch', value);
                }}
            />
        </div>
    );
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};


const TableAction = (id, record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'editor', record)}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'delete', record)}>删除</div>
        </div>
    );
};