/**
 * 酒店店铺解禁弹
 */
import React from 'react';
import { Modal, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './ReleaseProhibitModal.module.scss';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import hotelShopModel from '@/reducers/storemanage/hotelShop/model';

const { TextArea } = Input;
class ReleaseProhibitModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        areaValue: '',
        title: this.props.title,
        shopId: this.props.data.id,
    }

    componentDidMount() {
        const { shopId } = this.state;
        this.props.getBannedinfomation({ shopId });
    }

    // 确认
    _handleOk = () => {
        const { areaValue, shopId } = this.state;
        // 解封请求
        if (areaValue == '') {
            message.warn('请输入解禁说明');
        } else {
            this.setState({ confirmLoading: true }, () => {
                http('/shop/removeDisableShop', {
                    shopId,
                    openBlackReason: areaValue
                }, 'POST').then((response) => {
                    if (response.status == 200) {
                        this.setState({ visible: false });
                        this.props.closeMode(true);
                        message.success('解禁修改成功！');
                    }
                }).catch((rej = {}) => {
                    message.error(rej.message);
                    this.setState({ confirmLoading: false });
                });
            });
        }
    }

    // 取消
    _handleCancel = () => {
        this.setState({ visible: false, });
        this.props.closeMode();
    }

    // 解禁说明
    _areaChangeHandle = (e) => {
        this.setState({ areaValue: e.target.value });
    }

    render() {
        const { visible, title, confirmLoading, areaValue, } = this.state;
        const { infomation, } = this.props;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.up}>
                        <label>封禁结果</label>
                        {(infomation && infomation.bannedType == '1') && <span>永久禁止</span>}
                        {(infomation && infomation.bannedType == '0') && <span>{infomation.expectedEndTime} 到期</span>}
                    </div>
                    <div className={styles.down}>
                        <div className={styles.downlabel}>解禁说明</div>
                        <TextArea
                            className={styles.textArea}
                            rows={4}
                            onChange={this._areaChangeHandle}
                            value={areaValue}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

ReleaseProhibitModal.propTypes = {
    data: PropTypes.object, // 需要解禁的用户ID数据
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,

    getBannedinfomation: PropTypes.func, // 封禁信息
    infomation: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])

};

const mapStateToProps = (state) => {
    return {
        infomation: state.hotelShop.infomation, // 封禁信息
    };
};

export default connect(mapStateToProps, { ...hotelShopModel.actions })(ReleaseProhibitModal);