import React from 'react';
import styles from './TableData.module.scss';
import { XPagination, XMediaFn, } from '@/components/xqxc_ui';
import { Table, Tooltip, } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class ProductTable extends React.Component {
    _takeColumns = () => {
        const { curTabID, textVdieoType, } = this.props;
        let tableConent = [];
        if (curTabID == 1) {
            tableConent = [{
                title: '手机号码',
                dataIndex: 'tel',
                key: 'tel',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '用户昵称',
                dataIndex: 'accountNickname',
                key: 'accountNickname',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '主播封面',
                dataIndex: 'coverPage',
                key: 'coverPage',
                width: '10%',
                align: 'center',
                render: (text, item) => (this._renderImgCell(text, item))
            },
            {
                title: '直播间名称',
                dataIndex: 'broadcasTitle',
                key: 'broadcasTitle',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '开播时间',
                dataIndex: 'startTime',
                key: 'startTime',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '禁封类型',
                dataIndex: 'reasonTypeName',
                key: 'reasonTypeName',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '所属分类',
                dataIndex: 'broadcasClassName',
                key: 'broadcasClassName',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '禁封原因',
                dataIndex: 'reason',
                key: 'reason',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '禁封时间',
                dataIndex: 'gmtModified',
                key: 'gmtModified',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作人',
                dataIndex: 'modifyUserName',
                key: 'modifyUserName',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            }];
        } else {
            tableConent = [{
                title: '手机号码',
                dataIndex: 'tel',
                key: 'tel',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '用户昵称',
                dataIndex: 'accountNickname',
                key: 'accountNickname',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: textVdieoType == 'VIDEO' ? '视频封面' : '社区封面',
                dataIndex: 'coverPage',
                key: 'coverPage',
                width: '10%',
                align: 'center',
                render: (text, item) => (this._renderImgCell(text, item))
            },
            {
                title: textVdieoType == 'VIDEO' ? '视频名称' : '社区名称',
                dataIndex: 'feedTitle',
                key: 'feedTitle',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '发布时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '禁封类型',
                dataIndex: 'reasonTypeName',
                key: 'reasonTypeName',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '所属分类',
                dataIndex: 'feedClassName',
                key: 'feedClassName',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '禁封原因',
                dataIndex: 'reason',
                key: 'reason',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '禁封时间',
                dataIndex: 'gmtModified',
                key: 'gmtModified',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作人',
                dataIndex: 'modifyUserName',
                key: 'modifyUserName',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            }];
        }
        return tableConent;
    };
    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderImgCell = (url, item) => {
        return (
            <div style={{ display: 'inline-flex' }}>
                <XMediaFn
                    style={{ width: '50px', height: '50px', marginRight: '0' }}
                    dataType='image'
                    dataSource={url}
                    key={item.id}
                />
            </div>
        );
    };

    render() {
        const { paginationChange, paginations, renderData, } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={paginations.resultTotal}
                    pageSize={paginations.pageSize}
                    pageNum={paginations.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

ProductTable.propTypes = {
    curTabID: PropTypes.number, // 当前TabID
    renderData: PropTypes.array.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    paginations: PropTypes.object,//分页数据
    textVdieoType: PropTypes.string,//区分社区和视频表格
};

export default ProductTable;