/**
 * 视频播放弹框
 */
import React, { useState } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import styles from './OnlinePlayModal.module.scss';

const OnlinePlayModal = (props) => {

    const [visible, setVisible] = useState(props.visible);

    const _handleCancel = () => {
        setVisible(false);
        props.closeMode();
    };

    return (
        <Modal
            width={900}
            centered
            visible={visible}
            confirmLoading={false}
            onCancel={_handleCancel}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            footer={null}
        >
            <div className={styles.container}>
                <video width='800px' height='600px' src={props.modeParams.playUrl} poster={props.modeParams.coverPage} controls />
            </div>
        </Modal>
    );
};

OnlinePlayModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    modeParams: PropTypes.object
};

export default OnlinePlayModal;