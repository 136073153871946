// 供应商列表
export const GET_SUPPLIERLIST_TABLESOURCE = 'SUPPLIERLIST/GET_SUPPLIERLIST_TABLESOURCE';
export const SET_SUPPLIERLIST_TABLESOURCE = 'SUPPLIERLIST/SET_SUPPLIERLIST_TABLESOURCE';

// 店铺类型
export const GET_SHOPTYPES = 'SUPPLIERLIST/GET_SHOPTYPES';
export const SET_SHOPTYPES = 'SUPPLIERLIST/SET_SHOPTYPES';

// 仓库类型
export const GET_WAREHOUSE_TYPE = 'SUPPLIERLIST/GET_WAREHOUSE_TYPE';
export const SET_WAREHOUSE_TYPE = 'SUPPLIERLIST/SET_WAREHOUSE_TYPE';

// 获取地域：省市区
export const GET_ACCOUNT_AREA = 'SUPPLIERLIST/GET_ACCOUNT_AREA';
export const SET_PROVINCE = 'SUPPLIERLIST/SET_PROVINCE';
export const SET_CITY = 'SUPPLIERLIST/SET_CITY';
export const SET_STREET = 'SUPPLIERLIST/SET_STREET';

// 经营品类
export const GET_GOODSTYPES = 'SUPPLIERLIST/GET_GOODSTYPES';
export const SET_GOODSTYPES = 'SUPPLIERLIST/SET_GOODSTYPES';

// 开票单位
// export const GET_INVOICEUNIT_LIST = 'SUPPLIERLIST/GET_INVOICEUNIT_LIST';
// export const SET_INVOICEUNIT_LIST = 'SUPPLIERLIST/SET_INVOICEUNIT_LIST';
