import React from 'react';
import styles from './TableStyle.module.scss';
import PropTypes from 'prop-types';
import { Table, Switch, Button } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { priceFenToYuanCell, tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';

const TableContent = ({ renderData, tableAction, paginationChange, scrollY }) => {

    //表头
    const _columns = () => {
        return [
            {
                title: '手机号码',
                dataIndex: 'tel',
                key: 'tel',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '会员昵称',
                dataIndex: 'nickname',
                key: 'nickname ',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '标题',
                dataIndex: 'feedTitle',
                key: 'feedTitle',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '所属地区',
                dataIndex: 'area',
                key: 'area',
                align: 'center',
                width: cellWidth.normal,
                render: (area, item) => _areaCell(item)
            },
            {
                title: '魅力值收入',
                dataIndex: 'starlightNumber',
                key: 'starlightNumber',
                width: cellWidth.normal,
                align: 'center',
                render: (text, record) => _incomeCell(text, record, 'beans')
            },
            {
                title: '销售商品分红（元）',
                dataIndex: 'saleBonus',
                key: 'saleBonus',
                width: cellWidth.normal,
                align: 'center',
                render: (text, record) => _incomeCell(text, record, 'sell')
            },
            {
                title: '知识付费收益（元）',
                dataIndex: 'chargeBonus',
                key: 'chargeBonus',
                width: cellWidth.normal,
                align: 'center',
                render: (text, record) => _incomeCell(text, record, 'knowledge')
            },
            {
                title: '所属分类',
                dataIndex: 'chargeFeedClassName',
                key: 'chargeFeedClassName',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '发布时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '全部列表推荐',
                dataIndex: 'isAllReco',
                key: 'isAllReco',
                width: cellWidth.normal,
                align: 'center',
                render: (text, item) => _switchCell(text, item, 'home')
            },
            {
                title: '频道推荐',
                dataIndex: 'isChannelReco',
                key: 'isChannelReco',
                width: cellWidth.normal,
                align: 'center',
                render: (text, item) => _switchCell(text, item, 'channel')
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                width: cellWidth.big,
                fixed: 'right',
                align: 'center',
                render: (id, item) => _tableAction(id, item)
            }
        ];
    };

    const _areaCell = (data) => {
        return `${data.provinceName || ''}${data.cityName || ''}${data.areaName || ''}`;
    };

    const _incomeCell = (text, record, type) => {
        switch (type) {
            case 'beans':
                return <Button type='link' onClick={() => tableAction(record.id, 'beans', record)}>{text}</Button>;
            case 'sell':
                return <Button type='link' onClick={() => tableAction(record.id, 'sell', record)}>{priceFenToYuanCell(text)}</Button>;
            case 'knowledge':
                return <Button type='link' onClick={() => tableAction(record.id, 'knowledge', record)}>{priceFenToYuanCell(text)}</Button>;
        }
    };

    // 表格项内容为开关
    const _switchCell = (switchStatus, item, type) => {
        let checked = true;
        if (switchStatus == 0) {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={checked}
                    onChange={(e) => {
                        if (type == 'home') {
                            tableAction(item.id, 'home', e); // 首页推荐 
                        } else {
                            tableAction(item.id, 'channel', e); // 频道推荐
                        }
                    }}
                />
            </div>
        );
    };

    //表格操作
    const _tableAction = (id, item) => {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, 'detail', item)}>查看详情</div>
                <div className={styles.item} onClick={() => tableAction(id, 'ban', item)}>禁用</div>
                <div className={styles.item} onClick={() => tableAction(id, 'delete', item)}>删除</div>
            </div>
        );
    };

    return (
        <div className={styles.tableContainer} style={{ height: '100%' }}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                columns={_columns()}
                dataSource={renderData.dataList}
                pagination={false}
                scroll={{ x: 'max-content', y: scrollY }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange} />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func,
    scrollY: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ])
};

export default TableContent;