import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Icon, } from 'antd';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';
import ButtonPerssion from '@/routes/ButtonPerssion';

const columns = (TableAction, actionClickHandle) => {
    let arrList = [
        {
            title: '活动名称',
            dataIndex: 'activityTitle',
            key: 'activityTitle',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动副标题',
            dataIndex: 'activitySubtitle',
            key: 'activitySubtitle',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动类型',
            dataIndex: 'activityType',
            key: 'activityType',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动报名时间',
            dataIndex: 'enrollment',
            key: 'enrollment',
            align: 'center',
            width: cellWidth.big,
            onCell: tooltipStyle,
            render: (text, record) => (TableCellTime(text, 'signTime', actionClickHandle, record))
        },
        {
            title: '活动发布时间',
            dataIndex: 'released',
            key: 'released',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, record) => (TableCellTime(text, 'releaseTime', actionClickHandle, record))
        },
        {
            title: '推送平台',
            dataIndex: 'targetPlat',
            key: 'targetPlat',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '收费方式',
            dataIndex: 'chargeType',
            key: 'chargeType',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动折扣率(%)',
            dataIndex: 'discountRate',
            key: 'discountRate',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: cellWidth.big,
            fixed: 'right',
            render: (id, record) => TableAction(id, record, actionClickHandle)
        }
    ];
    return arrList;
};

const TableAction = (id, record, actionClickHandle) => {
    const hasLink = ['DIY_ACTIVITY', 'VIP_EXCLUSIVE', 'SUBSIDY', 'SUBSIDY_LARGE', 'CONSIGN_SALE', 'SUBSIDY_DEDUCT'].includes(record.activityTypeCode);
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'detail', record.activityTypeCode)}>详情</div>
            {((record.state != '已结束' && record.isOpen == '0') || record.activityTypeCode === 'POINTS_TREASURE') ?
                <div className={styles.item} onClick={() => actionClickHandle(id, 'edit', record.activityTypeCode)}>编辑</div>
                : null}
            {record.state != '已结束'
                ? <div className={styles.item} onClick={() => actionClickHandle(id, 'stateType', record)}>{record.isOpen == '1' ? '暂停' : '开启'}</div>
                : null}
            {/* <div className={styles.item} onClick={() => actionClickHandle(id, 'link',record)}>链接</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'data')}>数据</div> */}
            {hasLink && <div className={styles.item} onClick={() => actionClickHandle(id, 'custom')}>自定义页面</div>}
            {record.activityTypeCode == 'QP_ACTIVITY' && <ButtonPerssion code='marketingmanage/activities/qpjl'>
                <div className={styles.item} onClick={() => actionClickHandle(id, 'fight', record)}>抢拍记录</div>
            </ButtonPerssion>}
        </div >
    );
};

// 表格项时间内容为文本弹框  
const TableCellTime = (text, parame, actionClickHandle, record) => {
    if (record.state == '已结束' || record.activityTypeCode == 'POINTS_TREASURE') {
        return <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}> {text}</div>;
    } else {
        return record.activityTypeCode == 'CROWD_FUNDING' && parame == 'signTime' ? <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>-</div> : (
            <div onClick={() => actionClickHandle(text, parame, record)} className={styles.TableCellTime} style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
                <Icon type="edit" style={{ fontSize: '18px', marginLeft: '2%' }} />
            </div>
        );
    }
};

class TypeTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data.map(item => {
            item.enrollment = `${item.entryStartTime ? item.entryStartTime : ''}-${item.entryEndTime ? item.entryEndTime : ''}`;//活动报名
            item.released = `${item.startTime ? item.startTime : ''}-${item.endTime ? item.endTime : ''}`;//活动发布
            return item;
        });
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { renderData, pagiNation, paginationChange, } = this.props;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => record.id}
                    columns={columns(TableAction, this._actionHandle)}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={pagiNation.resultTotal}
                    pageSize={pagiNation.pageSize}
                    pageNum={pagiNation.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    pagiNation: PropTypes.object, // 分页数据
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string
};

export default XTableHOC(TypeTable);