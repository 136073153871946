import { message } from 'antd';
import { timeToMinute } from '@/assets/js/utils';

export function beforeUpload(file) {
    const isJPG = file.type === 'image/jpeg';
    if (!isJPG) {
        message.error('You can only upload JPG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJPG && isLt2M;
}

export function getAudioDuration(file) {
    return new Promise((resolve) => {
        let url = URL.createObjectURL(file);
        let audioElement = new Audio(url);
        audioElement.addEventListener('loadedmetadata', _event => {
            resolve({
                duration: audioElement.duration,
                minuteString: timeToMinute(audioElement.duration)
            });
        });
    });
}