import * as T from './actiontypes';

const defaultState = {
    tableSource: [],//列表数据源
    ListDetail: {}, //详情
    SelectData: [],// 下拉状态选项
    radiusData: [],// 新建商户商户类型
    paginations: {},//分页对象
    storeListTable: {},//店铺
    newStorePaginations: {},//店铺分页
    province: [], // 省份
    city: [], // 城市
    street: [], // 地区
    protion: [],// 新建商户商户类型
    address: {},
    servicecenter: [],//运营中心
    servicearea: [],//区域公司
    service: [],//服务商

    radiotypeOperation: [],//商户类型在实体加盟中-运营中心
    radiotypeArea: [],//商户类型在实体加盟中-区域公司
    radiotypes: {},//商户类型在实体加盟中运营中心、区域公司
    networkInfo: {}
};


/**
 * 存储'商户列表'数据源
 * @param {*} state 
 * @param {*} action 
 */
export const merchantDate = (state = defaultState, action) => {
    let newTableSource, newListDetail, newSelectData, newstoreList, newStorePaginations, newPaginations;
    switch (action.type) {
        case T.SET_MERCHANT_DATA: //存储'商户列表'数据源
            newTableSource = action.payload.result.dataList || [];
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_STOREDETAIL_DATA: //存储'商户详情与商户编辑'数据源
            newListDetail = action.payload.result;
            return { ...state, ListDetail: newListDetail };
        case T.SET_MERCHANTSELECT_DATA: //存储'商户列表下拉选项'数据源
            newSelectData = action.payload.result || [];
            return { ...state, SelectData: newSelectData };
        case T.SET_MERCHANTRADIUS_DATA: //存储'新建商户商户类型'数据源
            newSelectData = action.payload.result || [];
            return { ...state, radiusData: newSelectData };
        case T.SET_STORE_DATA: //存储'商户店铺'数据源
            newstoreList = action.payload.result;
            newStorePaginations = {
                pageNum: action.payload.result.pager.pageNum,
                pageSize: action.payload.result.pager.pageSize,
                resultTotal: action.payload.result.pager.resultTotal
            };
            return { ...state, storeListTable: newstoreList, newStorePaginations };
        case T.SET_PROVINCE://省份
            return { ...state, province: action.payload.result || [] };
        case T.SET_CITY://城市
            return { ...state, city: action.payload.result || [] };
        case T.SET_STREET://地区
            return { ...state, street: action.payload.result || [] };
        case T.SET_PROPTION_DATA: //存储'新建商户基础费用模式'数据源
            return { ...state, protion: action.payload.result || [] };
        case T.SET_MERCHANTSERVICECENTER_DATA: //运营中心
            return { ...state, servicecenter: action.payload.result.result };
        case T.SET_MERCHANTSERVICECOMPANY_DATA: //区域公司
            return { ...state, servicearea: action.payload.result.result };
        case T.SET_MERCHANTSERVICE_DATA: //服务商
            return { ...state, service: action.payload.result.result };
        //商户类型在实体加盟中运营中心、区域、服务商（不可以选）数据依据地址的市，区参数=》服务商
        case T.SET_OPERRATION_DATA://运营中心
            return { ...state, radiotypeOperation: action.payload };
        case T.SET_AREA_DATA://区域公司
            return { ...state, radiotypeArea: action.payload };
        case T.SET_RADIOTYPE_DATA://区域公司、运营中心
            return { ...state, radiotypes: action.payload.result };
        case T.SET_NETWORKINFO_DATA://入网功能
            return { ...state, networkInfo: action.payload.result };
        default:
            return state;
    }
};