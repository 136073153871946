/**
 *  【风控体系设置】入口页
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import Merchants from './pages/merchants';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import Channel from './Channel';

class RiskControl extends React.Component {

    state = {
        tab: [
            { id: 1, label: '用户体系' },
            { id: 2, label: '商户体系' },
            { id: 3, label: '渠道体系' },
        ],
        curTabID: 1,  // 默认显示的tab  
    }

    componentDidMount() {
        const { history } = this.props;
        if (history.location.state && history.location.state) {
            this.setState({ curTabID: history.location.state }); // 如果路由过来的state参数带tabID值，则渲染该tabID值下的内容
        }
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容 
    _renderPage = () => {
        const { curTabID } = this.state;
        switch (curTabID) {
            case 1:
                return <Main history={history} />;
            case 2:
                return <Merchants history={history} />;
            case 3:
                return <Channel history={history} />;
            default:
                <Main history={history} />;

        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={styles.tableContainer} style={{ overflow: 'auto' }}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

RiskControl.propTypes = {
    history: PropTypes.object, // 路由history对象
};

export default RiskControl;