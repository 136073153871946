import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Switch, Icon, Input } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

const columns = (TableAction, actionClickHandle) => [
    {
        title: '分类ID',
        dataIndex: 'c1',
        key: 'c1',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '分类名称',
        dataIndex: 'c2',
        key: 'c2 ',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '分类图标',
        dataIndex: 'c3',
        key: 'c3',
        align: 'center',
        width: '10%',
        render: ImgCell
    },
    {
        title: '排序',
        dataIndex: 'c4',
        key: 'c4',
        width: '10%',
        align: 'center',
        render: (text, item) => (SortCell(text, item, actionClickHandle))
    },
    {
        title: '状态',
        dataIndex: 'c5',
        key: 'c5',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle))
    },
    {
        title: '创建时间',
        dataIndex: 'c6',
        key: 'c6',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: '10%',
        render: (text, item) => TableAction(item.key, actionClickHandle)
    }
];

const TableAction = (key, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(key, 'edit')}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(key, 'delete')}>删除</div>
        </div>
    );
};


// 表格项内容为可编辑
const SortCell = (value, item, actionClickHandle) => {
    const label = React.createRef();
    const box = React.createRef();
    const input = React.createRef();
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <div className={styles.sort_box}>
                <div className={styles.label} ref={label}>{value}</div>
                <div className={styles.input} ref={box}>
                    <Input
                        key={item.c1}
                        style={{ width: '50px' }}
                        ref={input}
                        defaultValue={value}
                        onBlur={(e) => {
                            box.current.style.display = 'none';
                            label.current.style.display = 'block';
                            if (value != e.target.value) {
                                actionClickHandle(item.key, 'sort', { sortNo: e.target.value, classTypeCode: item.classTypeCode, id: item.c1 });
                            }
                        }}
                    />
                </div>
                <div
                    className={styles.icon}
                    onClick={() => {
                        label.current.style.display = 'none';
                        box.current.style.display = 'block';
                        input.current.focus();
                    }}
                >
                    <Icon type="edit" style={{ fontSize: '18px', color: '#1078ff' }} />
                </div>
            </div>
        </div>
    );
};

// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
    let checked = true;
    if (sw == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={(value) => {
                    console.log(value);
                    if (value == false) {
                        value = 0;
                    } else {
                        value = 1;
                    }
                    actionClickHandle(item.key, 'switch', { state: value, classTypeCode: item.classTypeCode });
                }}
            />
        </div>
    );
};

class TypeTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
        pageNum: 1,
        pageSize: 10,
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        if (data && data.dataList) {
            let tableSource = data.dataList.map((item) => {
                let tableCell = {};
                tableCell.classTypeCode = item.classTypeCode;
                tableCell.key = item.id;
                tableCell.c1 = item.id;
                tableCell.c2 = item.className;
                tableCell.c3 = item.img;
                tableCell.c4 = item.sortNo;
                tableCell.c5 = item.state;
                tableCell.c6 = item.gmtCreate;
                return tableCell;
            });
            return tableSource;
        } else {
            return [];
        }
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { renderData } = this.props;
        const { pageNum, pageSize, resultTotal } = this.props.renderData;

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={columns(TableAction, this._actionHandle)}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={this.props.paginationChange}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

export default TypeTable;