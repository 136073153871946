import React from 'react';
import styles from './TableMain.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

class TableMain extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
        auditedID: [], // 审核（或者未审核）的ID
        currentOpenMode: '',
        openModeParam: '',
        audit: {},
        selectedRowKeys: []

    }
    onSelectChange = selectedRowKeys => {
            this.props.callback(selectedRowKeys );
    };

    _columns = () => [
        // {
        //     title: '手机号码',
        //     dataIndex: 'accountName',
        //     key: 'taccountNameel',
        //     align: 'center',
        //     width: '8%',
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        {
            title: '商品图片',
            dataIndex: 'mainImgUrl',
            key: 'mainImgUrl',
            align: 'center',
            width: '8%',
            render: ImgCell
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员账号',
            dataIndex: 'accountName',
            key: 'accountName',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '评论内容',
            dataIndex: 'content',
            key: 'content',
            align: 'center',
            width: '18%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '评论图片',
            dataIndex: 'scoreImgUrls',
            key: 'scoreImgUrls',
            align: 'center',
            width: '8%',
            render:(state)=>(ImgCell(state[0]))
        },
        {
            title: '评论时间',
            dataIndex: 'scoreTime',
            key: 'scoreTime',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '赠送人气值',
            dataIndex: 'star',
            key: 'star',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '8%',
            render: (state) => (this._stateCell(state))
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render: (id, record,...param) => this._tableAction(id, record,param)
        }
    ];


    _stateCell = (state) => {
        return state.value;
    }

    _tableAction = (id, record,param) => {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => this.props.tableAction(id, record, 'detail',param)}>查看详情</div>
                </div>
            );
    };

    _takeDataSource = (data) => {
        if (data && data.dataList) {
            data.dataList.map(item => {
                if (item.state == 'APPLY') {
                    item.state = '审批中';
                }
                if (item.state == 'APPROVAL') {
                    item.state = '审批通过';
                }
                if (item.state == 'REJECT') {
                    item.state = '审批不通过';
                }
                if (item.state == 'BANNED') {
                    item.state = '禁用';
                }
            });
            return data.dataList;
        } else {
            return [];
        }
    }

    

    render() {

        const { renderData, paginationChange } = this.props;
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys:this.props.selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: record => ({
            disabled: record.status.code !== 'NOT_GIVE',
        }),
        };
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={rowSelection}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(renderData)}
                    rowKey='id'
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={paginationChange} />
            </div>
        );
    }
}

TableMain.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func,
    callback: PropTypes.func,
    selectedRowKeys:PropTypes.array
};

export default TableMain;


