/**
 * 上架类型
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './modalStyle.module.scss';
import { Modal, Radio, DatePicker, message } from 'antd';
import moment from 'moment';

const Putaway = ({
    closeMode,
    modalParams: {
        wholesaleGoodsIds = []
    } = {}
}) => {

    const [loading, setLoading] = useState(false);
    const [isTimer, setIsTimer] = useState('');
    const [autoOnTime, setAutoOnTime] = useState(null);

    const onSubmit = () => {
        let data = { wholesaleGoodsIds, status: 'PUT_ON_SALE', isTimer };
        if (!isTimer) {
            message.warning('请选择上架类型');
            return;
        }
        if (isTimer === '1' && !autoOnTime) {
            message.warning('请选择上架时间');
            return;
        }
        if (autoOnTime) {
            data.autoOnTime = autoOnTime.format('YYYY-MM-DD HH:mm') + ':00';
        }
        setLoading(true);
        http('/goods/wholesale/onOffWholesaleGoodsStatus', data, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    return <Modal
        width={400}
        centered
        title='上架分销'
        visible={true}
        confirmLoading={loading}
        onOk={onSubmit}
        onCancel={() => closeMode(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div className={styles.container}>
            <Radio.Group onChange={e => setIsTimer(e.target.value)}>
                <Radio value='0'>立即上架</Radio>
                <Radio value='1'>设置上架时间</Radio>
            </Radio.Group>
            {
                isTimer === '1' &&
                <DatePicker
                    value={autoOnTime}
                    placeholder='请选择时间'
                    style={{ marginLeft: '95px', marginTop: '10px' }}
                    allowClear={false}
                    onChange={value => setAutoOnTime(value)}
                    format='YYYY-MM-DD HH:mm'
                    showTime={{ format: 'HH:mm' }}
                    disabledDate={current => {
                        if (current && current < moment().subtract(1, 'days')) {
                            return true;
                        }
                    }}
                    disabledTime={current => {
                        if (current && current < moment()) {
                            return true;
                        }
                    }}
                />
            }
        </div>
    </Modal>;
};
Putaway.propTypes = {
    closeMode: PropTypes.func,
    modalParams: PropTypes.object
};
export default Putaway;