/**
 * 运营中心管理 Saga集合
 */
import {
    getOperationListData,
    getCompanyListData,
    getOperationStatus,
    getOperationDetail,
    getOperationCenterSele,
    getSettleUnitTypeSele,
    getSettleUnitSele,
    getOperationProvince,
    getOperationCity,
    getOperationArea,
    getProvinceSele,
    getCitySele,
    getTypeEnum
} from './saga';

export default [
    getOperationListData,
    getCompanyListData,
    getOperationStatus,
    getOperationDetail,
    getOperationCenterSele,
    getSettleUnitTypeSele,
    getSettleUnitSele,
    getOperationProvince,
    getOperationCity,
    getOperationArea,
    getProvinceSele,
    getCitySele,
    getTypeEnum
];
