/**
 * 广告位列表
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { message } from 'antd';
import http from '@/assets/api/http';
import { XInput, XOKButton, XCancelButton, XSelect, showConfirm } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import AdvertTable from './components/AdvertTable';
import DeleteModal from './modal/DeleteModal';
import { getAdvertAdsenseList } from '@/reducers/advertmanage/advertAdsense/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {

    state = {
        status: [{
            id: 1,
            value: '启用',
        }, {
            id: 0,
            value: '禁用',
        }],
        companyStatus: {},
        adSpaceCode: null,
        adSpaceName: null,
        pageNum: 1,
        pageSize: 10,
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getAdvertAdsenseList({ pageNum: 1, pageSize: 10 });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { adSpaceCode, adSpaceName, companyStatus, pageNum, pageSize } = this.state;
        this.searchCache = {
            adSpaceCode,
            adSpaceName,
            adSpaceState: companyStatus.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAdvertAdsenseList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ adSpaceCode: null, adSpaceName: null, pageNum: 1, pageSize: 10, companyStatus: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        // this.props.getAdvertAdsenseList({ pageNum: 1, pageSize: 10 });
    }
    // 状态
    _companyStatusChange = e => {
        this.setState({
            companyStatus: e,
        });
    }
    // 新建用户
    _newHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '创建广告位',
            path: '/home/advertmanage/advertList/add'
        });
        history.push({
            pathname: '/home/advertmanage/advertList/add'
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const { adSpaceCode, adSpaceName, companyStatus } = this.state;
        this.setState({
            pageNum,
            pageSize,
        });
        this.searchCache = {
            adSpaceCode,
            adSpaceName,
            adSpaceState: companyStatus.id,
            pageNum,
            pageSize,
        };
        this.props.getAdvertAdsenseList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 切换状态
    _actionHandle = (params) => {
        http('/adspace/updateState', params, 'POST')
            .then(() => {
                message.success('状态更改成功。');
                this._searchHandle('useCache');
            }).catch((reject) => {
                message.error(reject.message);
            });
    }
    // 表格操作
    _tableAction = (id, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'edit':
                addOneBreadcrumbPath({
                    title: '广告位列表编辑',
                    path: `/home/advertmanage/advertList/edit/${id}`
                });
                history.push({
                    pathname: `/home/advertmanage/advertList/edit/${id}`
                });
                break;
            case 'del':
                // this.setState({ currentOpenMode: 'del', openModeParam: id });
                showConfirm('是否确定删除？', '', () => {
                    http('/adspace/delete', { id, state: 0 }, 'POST')
                        .then(() => {
                            message.success('删除成功。');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
        }
    }
    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'del':
                return <DeleteModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }
    render() {
        const { adSpaceCode, adSpaceName, status, companyStatus } = this.state;
        const { advertAdsenseList } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='广告位编码'
                                placeholder='请输入'
                                value={adSpaceCode}
                                bindThis={this}
                                bindName='adSpaceCode'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='广告位名称'
                                placeholder='请输入'
                                value={adSpaceName}
                                bindThis={this}
                                bindName='adSpaceName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={status}
                                onChange={this._companyStatusChange}
                                dataIndex='value'
                                keyIndex='id'
                                value={companyStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.row2}>
                        <XOKButton
                            style={{ width: '120px' }}
                            label='创建广告位'
                            onClick={this._newHandle}
                        />
                    </div>
                    <AdvertTable renderData={advertAdsenseList} tableAction={this._tableAction} actionHandle={this._actionHandle} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getAdvertAdsenseList: PropTypes.func,
    advertAdsenseList: PropTypes.object,
};
const mapStateToProps = (state) => ({
    advertAdsenseList: state.advertAdsense.advertAdsenseList,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getAdvertAdsenseList })(Main);