import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

export default class TableContent extends Component {
	state = {
		selectedRowKeys: [],
	};
	static propTypes = {
		tableAction: PropTypes.func,
		paginationChange: PropTypes.func,
		renderData: PropTypes.object,
	};
	_columns = () => [
		{
			title: '供应商代码',
			dataIndex: 'supplierCode',
			key: 'supplierCode',
			align: 'center',
			width: '15%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '供应商名称',
			dataIndex: 'supplierName',
			key: 'supplierName',
			align: 'center',
			width: '15%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '经营品类',
			dataIndex: 'categoryName',
			key: 'categoryName',
			align: 'center',
			width: '14%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '联系人',
			dataIndex: 'contact',
			key: 'contact',
			align: 'center',
			width: '14%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '联系方式',
			dataIndex: 'contactPhone',
			key: 'contactPhone',
			align: 'center',
			width: '14%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '状态',
			dataIndex: 'auditState',
			key: 'auditState',
			align: 'center',
			width: '14%',
			render: (text) => this._stateCell(text)
		},
		{
			title: '操作',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			width: '10%',
			render: (id, record) => this._tableAction(id, record)
		},
	];
	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log(selectedRows);
			this.setState({ selectedRowKeys });
			this.props.tableAction(null, 'batchBan', selectedRowKeys);
		}
	};
	//状态
	_stateCell = text => {
		return text ? text.value : null;
	}
	// 清空表格复选框
	_cleanAllSelected = () => {
		this.setState({ selectedRowKeys: [] });
	}
	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		if (record.auditState.code == 'OPERATION_AUDIT_TYPE_APPROVED' ||
			record.auditState.code == 'OPERATION_AUDIT_TYPE_NOT_APPROVED' ||
			record.auditState.code == 'AUDIT_TYPE_APPROVED' ||
			record.auditState.code == 'AUDIT_TYPE_NOT_APPROVED') {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
				</div>
			);
		} else {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
					<div className={styles.item} onClick={() => tableAction(key, 'audit', record)}>审核</div>
				</div>
			);
		}
	};
	_tableData = (data) => {
		return data ? data : [];
	}
	render() {
		const { renderData, paginationChange } = this.props;
		const { selectedRowKeys } = this.state;
		this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
			<div className={styles.tableContainer}>
				{/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					// rowSelection={this._rowSelection}
					rowKey={record => record.id}
					columns={this._columns()}
					pagination={false}
					dataSource={this._tableData(renderData.dataList)}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}