import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import OperationCenter from '../operationCenter';
import AreaCompany from '../areaCompany';
import Serviceaudit from '../serviceaudit';
import { getSessionStorage, setSessionStorage, removeSessionStorage } from '@/assets/js/storage';

const MarketAudit = ({
    history,
    match
}) => {

    const [curTabID, setCurTabID] = useState('1');

    useEffect(() => {
        if (getSessionStorage('bazaarAuditCurTabID')) {
            setCurTabID(getSessionStorage('bazaarAuditCurTabID'));
            removeSessionStorage('bazaarAuditCurTabID');
        }
    });

    const tablist = [
        { id: '1', label: '运营中心' },
        { id: '2', label: '区域公司' },
        { id: '3', label: '服务商' },
    ];

    const renderPage = () => {
        switch (curTabID) {
            case '1':
                return <OperationCenter history={history} match={match} curTabID={curTabID} />;
            case '2':
                return <AreaCompany history={history} match={match} curTabID={curTabID} />;
            case '3':
                return <Serviceaudit history={history} match={match} curTabID={curTabID} />;
            default:
                return <OperationCenter history={history} match={match} curTabID={curTabID} />;
        }
    };

    return (
        <div className={styles.flexBoxContainer}>
            <XTabNav
                renderData={tablist}
                onChange={tab => setCurTabID(`${tab}`)}
                activedID={curTabID}
            />
            <div style={{ height: '10px' }}></div>
            {renderPage()}
        </div>
    );
};
MarketAudit.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object,
};
export default MarketAudit;