/**
 * 配置Ajax
 */
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { getLocalStorage } from '@/assets/js/storage';
import { createHashHistory } from 'history';
import { message, Spin } from 'antd';
import loadingImg from '../image/loading.gif';

const history = createHashHistory();
export default function () {
    let _prefix = '';
    let _token = '';
    let requestCount = 0;//请求数量
    const showLoading = () => {
        if (requestCount === 0) {
            let renderDom = document.createElement('div');
            renderDom.setAttribute('id', 'requestLoading');
            document.body.appendChild(renderDom);
            ReactDOM.render(<Spin size='large' indicator={<img style={{width:100,height:100}} src={loadingImg} />} ></Spin>, renderDom);
        }
        requestCount++;
    };//显示Spin组件

    const hideLoading = () => {
        requestCount--;
        if (requestCount === 0) {
            document.body.removeChild(document.getElementById('requestLoading'));
        }
    };//隐藏Spin组件

    // 根据不同的环境获取响应的api域名地址
    if (process.env.NODE_ENV === 'production') {
        _prefix = `${process.env.HOST}/`;
    } else {
        _prefix = '/';
    }
    axios.defaults.baseURL = _prefix;

    // 请求超时时间为10秒
    axios.defaults.timeout = 30000;

    // 请求发送拦截，并在头部加入token
    axios.interceptors.request.use(
        function (config) {
            let nextToken = getLocalStorage('auth') && JSON.parse(getLocalStorage('auth')).token;

            if (config.url == '/admin/empl/login') { // 如果为登录接口，重置登录信息
                _token = '';
            } else if (_token == '') {  // 第一次登录直接从缓存后去token
                _token = nextToken;
            } else {
                if (_token != nextToken) {
                    _token = '';  // 前后两次调用token不同，退出登录防止串token
                } else {
                    _token = nextToken;
                }
            }
            if (_token) {
                config.headers['token'] = `${_token}`; // 存在将token写入请求头
            }
            if (config.headers.isLoading !== false) showLoading();
            return config;
        },
        function (error) {
            if (error.config.headers.isLoading !== false) hideLoading();
            return Promise.reject(error);
        }
    );

    // 请求结果拦截，根据不同的错误码触发相应操作
    axios.interceptors.response.use(
        function (response) {
            try {
                if (response.data.code == '500') {
                    if (response.data.message) {
                        message.error(response.data.message);
                    } else {
                        message.error('接口500错误：【' + response.config.url + '】');
                    }
                }
            } catch (e) {
                message.error('后台500错误返回格式不正确');
            }
            if (response.config.headers.isLoading !== false) hideLoading();
            return response;
        },
        function (error) {
            if (error.config.headers.isLoading !== false) hideLoading();
            if (error.response) {
                switch (error.response.data.status) {
                    case 3003:
                        // 账号被重复登录
                        history.push({ pathname: '/', state: { pageInfo: '' } });
                        message.error('账号其它地方被重复登录');
                        break;
                    case 3004:
                        // token失效
                        history.push({ pathname: '/', state: { pageInfo: '' } });
                        message.error('由于您多点登录或长时间未操作，请重新登录');
                        break;
                    case 500:
                        // 500 服务端请求错误
                        message.error('接口500错误：【' + error.response.data.path + '】');
                        return Promise.reject(error.response);
                    case 404:
                        // 找不到资源
                        message.error('接口404错误：【' + error.response.data.path + '】');
                        return Promise.reject(error.response);
                    default:
                        return Promise.reject(error.response);
                }
            }
        }
    );
}

// 提供给第三方嵌入系统，实现跳转至登录页
export const junpToLogin = () => {
    history.push({ pathname: '/', state: { pageInfo: '' } }); // 账号被重复登录
};