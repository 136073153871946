import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Upload, message, Typography } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltipClick, tooltip } from '@/components/TableCell';
import { getLocalStorage } from '@/assets/js/storage';

const columns = (TableAction, actionClickHandle) => [
    {
        title: '合同名称',
        dataIndex: 'contractName.value',
        key: 'contractName.value',
        width: '13%',
        align: 'center',
        onCell: tooltipStyle,
        render: (text, item) => tooltipClick(text, () => actionClickHandle(text, 'contractName', item))
    },
    {
        title: '合同别名',
        dataIndex: 'alias',
        key: 'alias ',
        width: '13%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '版本号',
        dataIndex: 'version',
        key: 'version ',
        width: '13%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        align: 'center',
        width: '10%',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '状态',
        dataIndex: 'contractState.code',
        key: 'contractState.code',
        width: '10%',
        align: 'center',
        render: (text, item) => (TableStateCell(text, item))
    },
    {
        title: '生效时间',
        dataIndex: 'effectTime',
        key: 'effectTime',
        align: 'center',
        width: '10%',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '创建人',
        dataIndex: 'createUserName',
        key: 'createUserName',
        align: 'center',
        width: '10%',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: '10%',
        render: (text, record) => TableAction(text, record, actionClickHandle)
    }
];

let _prefix = '';
if (process.env.NODE_ENV === 'production') {
    _prefix = `${process.env.HOST}/`;
} else {
    _prefix = './';
}
let curtoken = '';
if (getLocalStorage('auth')) {
    curtoken = JSON.parse(getLocalStorage('auth')).token;
}

const props = {
    name: name,
    accept: '.xls, .xlsx',
    action: _prefix + '',
    showUploadList: false,
    headers: {
        authorization: 'authorization-text',
        token: curtoken
    },
    onChange(info) {
        if (info.file.status === 'done') {
            message.success(info.file.response && info.file.response.message);
        } else if (info.file.status === 'error') {
            message.error(info.file.response && info.file.response.message);
        }
    },
};

const TableAction = (id, record, actionClickHandle) => {
    switch (record.contractState.code) {
        case 'PERFORM':
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => actionClickHandle(id, 'view', record)}>查看</div>
                    {/* <Upload {...props}>
                        <Typography.Text style={{ color: '#40a9ff', fontSize: '12px', cursor: 'pointer' }}>上传模板</Typography.Text>
                    </Upload> */}
                    <div className={styles.item} onClick={() => actionClickHandle(id, 'disable', record)}>禁用</div>
                    {/* <div className={styles.item} onClick={() => actionClickHandle(id, 'preview', record)}>预览模板</div> */}
                </div>
            );
        case 'DISABLE':
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => actionClickHandle(id, 'view', record)}>查看</div>
                    {/* <Upload {...props}>
                        <Typography.Text style={{ color: '#40a9ff', fontSize: '12px', cursor: 'pointer' }}>上传模板</Typography.Text>
                    </Upload> */}
                    <div className={styles.item} onClick={() => actionClickHandle(id, 'enable', record)}>启用</div>
                    {/* <div className={styles.item} onClick={() => actionClickHandle(id, 'preview', record)}>预览模板</div> */}
                </div>
            );
        default:
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => actionClickHandle(id, 'view', record)}>查看</div>
                    {/* <Upload {...props}>
                        <Typography.Text style={{ color: '#40a9ff', fontSize: '12px', cursor: 'pointer' }}>上传模板</Typography.Text>
                    </Upload> */}
                    {/* <div className={styles.item} onClick={() => actionClickHandle(id, 'preview', record)}>预览模板</div> */}
                </div>
            );
    }
};

// 表格项内容为文本
const TableStateCell = (text, item) => {
    switch (text) {
        case 'INEFFECTIVE':
            return (
                <div className={styles.a_curt} style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                    {item.contractState.value}
                </div>
            );
        case 'PERFORM':
            return (
                <div className={styles.b_curt} style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                    {item.contractState.value}
                </div>
            );
        case 'DISABLE':
            return (
                <div className={styles.d_curt} style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                    {item.contractState.value}
                </div>
            );
        default: return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {item.contractState.value}
            </div>
        );
    }
};


class TypeTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                item.effectTime = item.gmtCreate;
                return item;
            });
        } else {
            return [];
        }
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { dataList, resultTotal, pageSize, pageNum } = this.props.renderData;
        const { paginationChange } = this.props;

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={columns(TableAction, this._actionHandle)}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TypeTable;