/**
 * 电子协议成功
 */
import React from 'react';
import styles from './index.module.scss';

class PaySucess extends React.Component {

    state = {
    }


    render() {
        return (
            <div className={styles.payContainer}>
                <div className={styles.flexConten} style={{ height: 420 }}>
                    <div className={styles.mainTitle}>
                        <img src={require('@/assets/image/sucess.png')} className={styles.iconStyles} />
                        <div className={styles.right}>
                            <div className={styles.title}>操作成功</div>
                            <div className={styles.subTitle}>尊敬的用户，恭喜您签约成功！</div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default PaySucess;

