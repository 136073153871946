export const GET_INVOICELIST = 'INVOICELIST/GET_INVOICELIST';
export const SET_INVOICELIST = 'INVOICELIST/SET_INVOICELIST';

// 区域中心对账列表
export const GET_REGIONALCOMPANYRECONCILIATION_TABLESOURCE = 'REGIONALCOMPANYRECONCILIATION/GET_REGIONALCOMPANYRECONCILIATION_TABLESOURCE';
export const SET_REGIONALCOMPANYRECONCILIATION_TABLESOURCE = 'REGIONALCOMPANYRECONCILIATION/SET_REGIONALCOMPANYRECONCILIATION_TABLESOURCE';
// 区域下拉
export const GET_REGIONALCOMPANY_LIST = 'REGIONALCOMPANYRECONCILIATION/GET_REGIONALCOMPANY_LIST';
export const SET_REGIONALCOMPANY_LIST = 'REGIONALCOMPANYRECONCILIATION/SET_REGIONALCOMPANY_LIST';
// 区域详情
export const GET_REGIONALCOMPANY_DETAIL = 'REGIONALCOMPANYRECONCILIATION/GET_REGIONALCOMPANY_DETAIL';
export const SET_REGIONALCOMPANY_DETAIL = 'REGIONALCOMPANYRECONCILIATION/SET_REGIONALCOMPANY_DETAIL';
