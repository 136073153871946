import {
    getReceiveRecordList,
	getReceiveRecordDetail,
	getWaitReceiveList,
	getWaitReceiveDetail,
	getReceiveRecordState
} from './receiveorderlist/saga';

export default [
    getReceiveRecordList,
	getReceiveRecordDetail,
	getWaitReceiveList,
	getWaitReceiveDetail,
	getReceiveRecordState
];