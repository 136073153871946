import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const gethHuseClassifyList = (T) => createSaga(T('gethHuseClassifyList'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/goods/category/tree', { ...action.payload }, 'POST');
                let newList = [];
                if (result.result) {
                    for (let oldItem of result.result.dataList) {
                        let newItem = {};
                        newItem.id = oldItem.id;
                        newItem.categoryName = oldItem.categoryName;
                        newItem.platRate = oldItem.platRate;
                        newItem.agentRate = oldItem.agentRate;
                        newItem.sortNo = oldItem.sortNo;
                        newItem.gmtModified = oldItem.gmtModified;
                        newItem.type = 'edit';
                        newList.push(newItem);
                    }
                }
                yield put({ type: T('originalData'), payload: { ...result.result, dataList: newList } });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const houseclassifyList = (T) => createReducer((initList) => {
    return (state = initList, { type = '', payload = {} }) => {
        let newList = [];
        switch (type) {
            case T('originalData'):
                return { ...payload };
            case T('addClassify/GET'):
                newList = [...state.dataList];
                newList.unshift(payload);
                return { ...state, dataList: newList };
            case T('deleteAdd/GET'):
                newList = state.dataList.filter(item => {
                    item.type !== payload[type];
                });
                return { ...state, dataList: newList };
            default:
                return state;
        }
    };
}, []);

const model = {
    namespace: 'houseclassify',
    build: (T, A) => ({
        actiontypes: {
            'gethHuseClassifyList': T('gethHuseClassifyList'),
            'addClassify': T('addClassify'),
            'deleteAdd': T('deleteAdd'),
        },
        actions: {
            'gethHuseClassifyList': A('gethHuseClassifyList'),
            'addClassify': A('addClassify'),
            'deleteAdd': A('deleteAdd'),
        },
        sagas: {
            'gethHuseClassifyList': gethHuseClassifyList(T), // 自定义saga写法，使用依赖注入
        },
        reducers: {
            'houseclassifyList': houseclassifyList(T), // 自定义reducer写法，使用依赖注入
        }
    })
};
export default createModel(model);