/**
 * 商户列表 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const merchantListSource = function* () {
    yield takeEvery(T.GET_MERCHANT_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/merchant/info/listMerchant', action.payload, 'POST');
            yield put({ type: T.SET_MERCHANT_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 商户店铺 Saga
 */
export const merchantstoreSource = function* () {
    yield takeEvery(T.GET_STORE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let response = yield http('/merchant/info/listMerchantShopInfo', action.payload, 'POST');
            let behavior = yield http('/enum/ViolantionBehaviorEnum', action.payload, 'POST');//违规行为枚举
            response.result.behavior = behavior.result;
            yield put({ type: T.SET_STORE_DATA, payload: response });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 商户详情与商户编辑 Saga
 */
export const storeDetails = function* () {
    yield takeEvery(T.GET_STOREDETAIL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象 merchantId
        try {
            let response = yield http('/merchant/info/' + action.payload.merchantId, action.payload, 'GET');
            let address = {}, service = {};
            if (action.payload.status == 'editor') {//判断是否是编辑页
                // 请求省市区ID
                const { provinceId, cityId, areaId } = response.result.merchantInfo;
                // 请求省市区列表
                const provinceList = yield http('/user/area/listAreaByParentId/100000', { id: '100000' }, 'POST');
                const cityList = yield http('/user/area/listAreaByParentId/' + provinceId, { id: provinceId }, 'POST');
                const areaList = yield http('/user/area/listAreaByParentId/' + cityId, { id: cityId }, 'POST');

                address.provinceList = provinceList.result ? provinceList.result : [];
                address.cityList = cityList.result ? cityList.result : [];
                address.areaList = areaList.result ? areaList.result : [];

                response.result.address = address;

                //商户类型在实体加盟中运营中心、区域、服务商（不可以选）数据依据地址的市，区参数=》服务商
                let data = yield http('/admin/company/regionalCompany', {
                    areaId: response.result.merchantInfo.areaId,
                    cityId: response.result.merchantInfo.cityId,
                }, 'POST');
                //数据处理
                let radiotypeOperation, radiotypeArea;
                if (Object.keys(data.result).length > 0) {
                    radiotypeOperation = [//运营中心
                        {
                            operationCenterId: data.result.operationCenterId,
                            operationCenterName: data.result.operationCenterName,
                        }
                    ];
                    radiotypeArea = [//区域公司
                        {
                            areaCompanyId: data.result.areaCompanyId,
                            areaCompanyName: data.result.areaCompanyName,
                        }
                    ];
                }
                yield put({ type: T.SET_RADIOTYPE_DATA, payload: data });
                yield put({ type: T.SET_OPERRATION_DATA, payload: radiotypeOperation || [] });
                yield put({ type: T.SET_AREA_DATA, payload: radiotypeArea || [] });
            }
            if (action.payload.status == 'detail') {//判断是否是详情页
                //图文描述视频，音频数据处理
                let videos, music;
                let methodPath = '/qiniu/getUrlAudioVideo';//请求视频、音频完整URL转化
                let merchantAttachs = response.result.merchantAttachs;
                for (var i = 0; i < merchantAttachs.length; i++) {
                    if (merchantAttachs[i].fileType.code == 'VIDEO') {//视频
                        videos = merchantAttachs[i].fileUrl;
                    } else if (merchantAttachs[i].fileType.code == 'MUSIC') {//音乐   
                        music = merchantAttachs[i].fileUrl;
                    }
                }
                const videoPath = yield http(methodPath, { fileName: videos }, 'POST');
                const musicPath = yield http(methodPath, { fileName: music }, 'POST');
                response.result.videoPath = videoPath.result;
                response.result.musicPath = musicPath.result;
            }

            // 请求服务相关ID
            const { operationCenterId, areaCompanyId, serviceId } = response.result.merchantInfo;
            // 请求服务列表
            const operationCenterList = yield http('/admin/company/listOperationsIdAndName', {}, 'POST');
            const areaCompanyList = yield http('/admin/company/listAreaCompanyIdAndName', { operationsId: operationCenterId }, 'POST');
            const serviceList = yield http('/service/provider/listServiceProviderIdAndName', { operationsId: operationCenterId, areaCompanyId: areaCompanyId }, 'POST');

            service.operationCenterList = operationCenterList.result ? operationCenterList.result.result : [];
            service.areaCompanyList = areaCompanyList.result ? areaCompanyList.result.result : [];
            service.serviceList = serviceList.result ? serviceList.result.result : [];
            response.result.servicecommone = service;

            yield put({ type: T.SET_STOREDETAIL_DATA, payload: response });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getNetworkInfo = function* () {
    yield takeEvery(T.GET_NETWORKINFO_DATA, function* requestData(action) {
        try {
            let result = yield http('/delivery/networkInfo', action.payload, 'POST');
            yield put({ type: T.SET_NETWORKINFO_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 下拉状态选项数据方法 Saga
 */
export const merchantSelectData = function* () {
    yield takeEvery(T.GET_MERCHANTSELECT_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象 
        try {
            let result = yield http('/enum/MerchantStateEnum', action.payload, 'POST');
            yield put({ type: T.SET_MERCHANTSELECT_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 所属服务商 Saga 
 */
export const merchantServicearea = function* () {
    yield takeEvery(T.GET_MERCHANTSERVICE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象 
        try {
            let response = yield http('/service/provider/listServiceProviderIdAndName', action.payload, 'POST');
            yield put({ type: T.SET_MERCHANTSERVICE_DATA, payload: response });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 区域公司 Saga 
 */
export const merchantServicecompany = function* () {
    yield takeEvery(T.GET_MERCHANTSERVICECOMPANY_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象 
        try {
            let response = yield http('/admin/company/listAreaCompanyIdAndName', action.payload, 'POST');
            yield put({ type: T.SET_MERCHANTSERVICECOMPANY_DATA, payload: response });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 运营中心 Saga 
 */
export const merchantServicecenter = function* () {
    yield takeEvery(T.GET_MERCHANTSERVICECENTER_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象 
        try {
            let result = yield http('/admin/company/listOperationsIdAndName', action.payload, 'POST');
            yield put({ type: T.SET_MERCHANTSERVICECENTER_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 新建-商户类型数据方法 Saga
 */
export const merchantradiusData = function* () {
    yield takeEvery(T.GET_MERCHANTRADIUS_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象 
        try {
            let result = yield http('/enum/MerchantTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_MERCHANTRADIUS_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取区域：省市区
export const getListAreaByParentId = function* () {
    yield takeEvery(T.GET_PROVINCE, function* requestData(action) {
        try {
            let result = yield http('/user/area/listAreaByParentId/' + action.payload.id, { id: action.payload.id }, 'POST');
            if (action.payload.type == 'province') {
                yield put({ type: T.SET_PROVINCE, payload: result });
            } else if (action.payload.type == 'city') {
                yield put({ type: T.SET_CITY, payload: result });
            } else if (action.payload.type == 'street') {
                yield put({ type: T.SET_STREET, payload: result });
            }
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 新建商户基础费用模式 Saga
 */
export const getmerchantportionSource = function* () {
    yield takeEvery(T.GET_PROPTION_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象 
        try {
            let result = yield http('/enum/MerchantSettlementRateEnum', action.payload, 'POST');
            yield put({ type: T.SET_PROPTION_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 商户类型在实体加盟中运营中心、区域、服务商（不可以选）数据依据地址的市，区参数=》服务商 Saga
 */
export const radiotype = function* () {
    yield takeEvery(T.GET_RADIOTYPE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象 
        try {
            let data = yield http('/admin/company/regionalCompany', action.payload, 'POST');
            //数据处理
            let radiotypeOperation, radiotypeArea;
            if (Object.keys(data.result).length > 0) {
                radiotypeOperation = [//运营中心
                    {
                        operationCenterId: data.result.operationCenterId,
                        operationCenterName: data.result.operationCenterName,
                    }
                ];
                radiotypeArea = [//区域公司
                    {
                        areaCompanyId: data.result.areaCompanyId,
                        areaCompanyName: data.result.areaCompanyName,
                    }
                ];
            }
            yield put({ type: T.SET_RADIOTYPE_DATA, payload: data });
            yield put({ type: T.SET_OPERRATION_DATA, payload: radiotypeOperation || [] });
            yield put({ type: T.SET_AREA_DATA, payload: radiotypeArea || [] });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
