import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, Row, message, Input, Checkbox } from 'antd';
import http from '@/assets/api/http';

const names = { SERVICE_RIGHTS: '服务商账号', OPERATIONS_CENTER_RIGHTS: '运营中心名称', AREA_COMPANY_RIGHTS: '区域公司名称' };
const title = { SERVICE_RIGHTS: '服务商', OPERATIONS_CENTER_RIGHTS: '运营中心', AREA_COMPANY_RIGHTS: '区域公司' };

const BatchOpenEquity = ({ subjectSystem, closeMode }) => {
    
    const [loading, setLoading] = useState(false);
    const [subjects, setSubjects] = useState('');
    const [isSendSms, setIsSendSms] = useState(false);

    const onSubmit = () => {
        const list = subjects.replace(/，/ig, ',').slice(',');
        if (!list.length) return message.warn(`请输入${names[subjectSystem]}`);
        if (list.length > 200) return message.warn(`${names[subjectSystem]}最大支持200条`);
        setLoading(true);

        http('/openRights/addOpenRights', { isSendSms: isSendSms ? 1 : 0, subjects: list.split(','), subjectSystem }, 'POST')
            .then(res => { message.success(res.message); setLoading(false); closeMode('Refresh'); })
            .catch(err => { message.error(err.message); setLoading(false); });
    };

    return <Modal width={460} centered title='批量开通权益' visible={true} confirmLoading={loading}
        onOk={onSubmit} onCancel={() => closeMode(false)} destroyOnClose={true}
        keyboard={true} maskClosable={false} cancelText='取消' okText='确认'
    >
        <div>
            <Row gutter={[0, 16]}>
                <Col span={6}><div>{names[subjectSystem]}：</div></Col>
                <Col span={18}>
                    <Input.TextArea placeholder={`最大支持200条，请输入${names[subjectSystem]}，用逗号隔开`} rows={4} value={subjects}
                        onChange={e => setSubjects(e.target.value)} />
                </Col>
                <Col span={6}><div>短信消息：</div></Col>
                <Col span={18}><Checkbox checked={isSendSms} onChange={e => setIsSendSms(e.target.checked)} /></Col>
            </Row>
            <div>此处的权益是指：开通【每日抢拍】小程序 - 每日补贴活动，{title[subjectSystem]}获得的补贴权益。开通后，该{title[subjectSystem]}才能获得{title[subjectSystem]}补贴。未开通，{title[subjectSystem]}不能获得{title[subjectSystem]}补贴</div>
        </div>
    </Modal>;
};
BatchOpenEquity.propTypes = {
    subjectSystem: PropTypes.string,
    closeMode: PropTypes.func
};
export default BatchOpenEquity;