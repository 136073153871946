import { createModel } from '@/store/tools';

const model = {
    namespace: 'goodscategory',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getReport': T('getReport'),
            'getMerchantSelect': T('getMerchantSelect'),
            'getGoodsCategorySelect': T('getGoodsCategorySelect'),
        },
        actions: {
            'getReport': A('getReport'),
            'getMerchantSelect': A('getMerchantSelect'),
            'getGoodsCategorySelect': A('getGoodsCategorySelect'),
        },
        sagas: {
            'getReport': S('getReport', '/report/goodsCategoryProfitAnalyze'),
            'getMerchantSelect': S('getMerchantSelect', '/merchant/info/listMerchantIdAndName'),
            'getGoodsCategorySelect': S('getGoodsCategorySelect', '/report/stairCategoryInfo'),
        },
        reducers: {
            'getReport': R('getReport', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getMerchantSelect': R('getMerchantSelect', { merchantIdAndNames: [] }),
            'getGoodsCategorySelect': R('getGoodsCategorySelect', []),
        }
    })
};
export default createModel(model);