/**
 *  【用户总账号】入口页
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const Service = ({ history }) => {
    return <Main history={history} />;
};

Service.propTypes = {
    history: PropTypes.object
};

export default Service;