import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/auditmanage/stockright/model';
import { SearchBox, SearchItem } from '@/components/Layout';
import { message } from 'antd';
import http from '@/assets/api/http';

class StockRight extends Component {

    static propTypes = {
        history: PropTypes.object,
        addOneBreadcrumbPath: PropTypes.func,
        keepSecondNavBreadcrumb: PropTypes.func,
        getStockRightList: PropTypes.func,
        stockRightList: PropTypes.object,
        getStateEnum: PropTypes.func,
        stateEnum: PropTypes.array
    }

    static defaultProps = {
        stockRightList: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
        stateEnum: []
    }

    state = {
        mobilePhone: '',
        bankNo: '',
        startTime: null,
        endTime: null,
        stateObj: {},
        loading: false,
    }

    componentDidMount() {
        const { getStateEnum, keepSecondNavBreadcrumb } = this.props;
        getStateEnum();
        keepSecondNavBreadcrumb();
    }

    // 导出
    _export = () => {
        const { pageSize, pageNum, mobilePhone, bankNo, startTime, endTime, stateObj } = this.state;
        const query = { mobilePhone, bankNo, startTime, endTime, status: stateObj.code, pageSize, pageNum };
        this.setState({ loading: true });
        http('/stakeAgreement/stakeAgreementListExport', { ...query }, 'POST').then((response) => {
            if (response.status == 200) {
                const excelUrl = response.result.toString();
                if (excelUrl.indexOf('http') != -1) {
                    window.open(response.result);
                    message.success('导出成功');
                } else {
                    message.error('无法导出，文件路径不正确');
                }
                this.setState({ loading: false });
            }
        }).catch(e => {
            message.error(e.message);
            this.setState({ loading: false });
        });
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, mobilePhone, bankNo, startTime, endTime, stateObj } = this.state;
        this.searchCache = {
            mobilePhone, bankNo, startTime, endTime, status: stateObj.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getStockRightList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ mobilePhone: '', bankNo: '', startTime: null, endTime: null, stateObj: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 路由跳转
    _updateContent = (title, url, id) => {
        const { addOneBreadcrumbPath, history } = this.props;
        addOneBreadcrumbPath({
            title,
            path: `/home/auditmanage/StockRight${url}`
        });
        history.push({
            pathname: `/home/auditmanage/StockRight${url}${id}`
        });
    }

    // 表格操作
    _tableAction = (id, type) => {
        switch (type) {
            case 'detail':
                this._updateContent('支付凭证详情信息', '/detail/', id);
                break;
            case 'audit':
                this._updateContent('支付凭证审核信息', '/audit/', id);
                break;
            default:
                break;
        }
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { stateEnum, stockRightList } = this.props;
        const { mobilePhone, bankNo, startTime, endTime, stateObj } = this.state;
        return (
            <KeepAlive id='StockRight' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='手机号码'
                                placeholder='请输入手机号码'
                                bindThis={this}
                                bindName='mobilePhone'
                                value={mobilePhone}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='转账账号'
                                placeholder='请输入支付单号'
                                bindThis={this}
                                bindName='bankNo'
                                value={bankNo}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='申请时间'
                                isFormat
                                bindThis={this}
                                bindName='startTime'
                                value={startTime}
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                isFormat
                                bindThis={this}
                                bindName='endTime'
                                value={endTime}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={stateEnum}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='stateObj'
                                value={stateObj.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div>
                        <SearchItem>
                            <XOKButton style={{ width: '80px' }} label='导出' loading={this.state.loading} onClick={this._export} />
                        </SearchItem>
                    </div>
                    <TableContent
                        paginationChange={this._paginationChange}
                        renderData={stockRightList}
                        tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}

const mapStateToProps = state => ({
    stockRightList: state.stockright.getStockRightList,
    stateEnum: state.stockright.getStateEnum
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(StockRight);