/**
 * 通联注册弹框
 */
import React from 'react';
import styles from './index.module.scss';
import { Steps } from 'antd';
import PropTypes from 'prop-types';
import { Form, Radio, Button, Input, message } from 'antd';
import { XMediaFn } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import model from '@/reducers/systemmanage/financialsetting/model';
import http from '@/assets/api/http';
import moment from 'moment';
import RegisterInfo from './RegisterInfo';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
import ButtonPerssion from '@/routes/ButtonPerssion';

const { Step } = Steps;
const RadioGroup = Radio.Group;
const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 10 },
        sm: { span: 10 },
    },
};

class AllPay extends React.Component {

    state = {
        st: '', // S1、S3、P 
        required: false,
        unitType: 'BUSINESS', // 商户类型：PERSON个人、BUSINESS企业
        authType: '', // 认证类型：2一证、1三证
        companyName: '', // 单位名称
        companyAddress: '', // 注册地址
        taxNum: '', // 统一社会信用证编号
        taxUrl: '', // 统一社会信用证编号图片url
        taxTime: '', // 统一社会信用证编号有效期
        idNo: '', // 企业营业执照号
        idNoUrl: '', // 营业执照号图片url
        idNoTime: '', // 营业执照号有效期
        taxCert: '', // 税务登记号
        taxCertUrl: '', // 税务登记号图片url
        orgCode: '', // 组织机构代码
        orgCodeUrl: '', // 组织机构代码图片url
        phone: '', // 联系电话
        corpName: '', // 法人姓名
        mobile: '', // 法人手机
        legPerId: '', // 法人身份证号
        identity: { legPerIdFrontUrl: '', legPerIdBackUrl: '' }, // 身份证正反面
        bankCardNo: '', // 银行卡账号
        parentBankName: '', // 开户银行
        bankName: '',  // 开户行支行名称 bankNo、bankName
        unionBank: '', // 开户支行行号
        email: '', // 收件人邮箱地址
        invoiceAddress: '', // 收件人地址
        invoiceName: '', // 收票人姓名
        invoicePhone: '', // 收票人手机
        showRegisterInfoModal: false, // 是否显示弹框
    }

    componentDidMount() {
        this.props.getYunstAuthTypeEnum();
        this.init();
    }

    init = () => {
        http('/company/info/getFinancial', {}, 'GET').then((response) => {
            if (response.status == 200) {
                const {
                    authType, // 企业认证
                    unitName, // 单位名称
                    registerAddr, // 注册地址
                    taxNumCode, // 统一社会信用证编号
                    taxNumCodeUrl, // 统一社会信用证编号图片url
                    taxNumCodeTime, // 统一社会信用证编号有效期
                    idNo, // 企业营业执照号
                    idNoUrl, // 营业执照号图片url
                    idNoTime, // 营业执照号有效期
                    taxCert, // 税务登记号
                    taxCertUrl, // 税务登记号图片url
                    orgCode, // 组织机构代码
                    orgCodeUrl, // 组织机构代码图片url
                    registerTel, // 联系电话
                    legalName, // 法人姓名
                    legalTel, // 法人手机
                    legalIdentity, // 法人身份证号
                    legPerIdFrontUrl, // 身份证正面
                    legPerIdBackUrl, // 身份证反面
                    bankAccount, // 银行卡账号
                    openBank, // 开户银行
                    bankName,  // 开户行支行名称 bankNo、bankName
                    unionBank, // 开户支行行号
                    receiveMail, // 收件人邮箱地址
                    receiveAddr, // 收件人地址
                    receiveName, // 收票人姓名
                    receivePhone, // 收票人手机
                } = response.result;
                this.setState({
                    authType,
                    companyName: unitName,
                    companyAddress: registerAddr,
                    taxNum: taxNumCode,
                    taxUrl: taxNumCodeUrl,
                    taxTime: taxNumCodeTime,
                    idNo,
                    idNoUrl,
                    idNoTime,
                    taxCert,
                    taxCertUrl,
                    orgCode,
                    orgCodeUrl,
                    phone: registerTel,
                    corpName: legalName,
                    mobile: legalTel,
                    legPerId: legalIdentity,
                    identity: { legPerIdFrontUrl, legPerIdBackUrl },
                    bankCardNo: bankAccount,
                    parentBankName: openBank,
                    bankName,
                    unionBank,
                    email: receiveMail,
                    invoiceAddress: receiveAddr,
                    invoiceName: receiveName,
                    invoicePhone: receivePhone
                }, () => {
                    this._showDiffIncome(this.state.unitType, this.state.authType);
                });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 下一步
    _handleSubmit = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                console.log(fieldsValue);
                const { email, invoiceAddress, invoiceName, invoicePhone } = fieldsValue;
                const query = {
                    receiveMail: email,
                    receiveName: invoiceName,
                    receivePhone: invoicePhone,
                    receiveAddr: invoiceAddress,
                };
                http('/company/info/updateMerchantFinancialReceiveInfo', query, 'POST').then((response) => {
                    if (response.status == 200) {
                        if (response.result && response.result.id != '') {
                            message.success('编辑成功');
                        } else {
                            this.setState({ isNew: true });
                        }
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
            }
        });
    }

    // 重新注册
    _register = () => {
        this.setState({ showRegisterInfoModal: true });
    }

    // 上传图片校验-返回值
    _normFile = (e) => {
        for (let i = 0; i < e.fileList.length; i++) {
            const isType = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
            const isLt1M = parseInt(e.fileList[i].size) / 1024 / 1024 < 1;
            if (!isLt1M || !isType.includes(e.fileList[i].type)) {
                e.fileList.splice(i, 1);
                i--;
            }
        }
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    // 上传图片校验-提示
    beforeUpload = (file) => {
        const isType = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
        const isLt5M = file.size / 1024 / 1024 < 1;
        if (!isType.includes(file.type)) {
            message.error('只能上传png、jpg、gif格式的图片');
            file.status = 'error';
            return false;
        } else if (!isLt5M) {
            message.error('图片必须小于1MB');
            file.status = 'error';
            return false;
        } else {
            return isType && isLt5M;
        }
    }

    _showDiffIncome = (unitType, authType) => {
        if (unitType == 'BUSINESS' && authType == '1') {
            this.setState({ st: 'S3' });
        } else {
            this.setState({ st: 'S1' });
        }
    }

    render() {
        const { st, required, authType, companyName, companyAddress, taxNum, taxUrl, taxTime,
            phone, corpName, mobile, legPerId, identity, bankCardNo, parentBankName, bankName,
            unionBank, email, invoiceAddress, invoiceName, invoicePhone,
            idNo, idNoUrl, idNoTime, taxCert, taxCertUrl, orgCode, orgCodeUrl, showRegisterInfoModal
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { yunstAuthTypeEnum } = this.props;

        return (
            <div className={styles.container}>
                <div className={styles.step}>
                    <Steps current={4}>
                        <Step title="填写基础信息" />
                        <Step title="绑定手机" />
                        <Step title="签订电子协议" />
                    </Steps>
                </div>
                <div className={styles.stepContent}>
                    <Form autoComplete='off' {...formItemLayout}>
                        {st != 'P' &&
                            <Form.Item
                                colon={false}
                                htmlFor='authType'
                                label={<span>认证类型：</span>}
                            >
                                {getFieldDecorator('authType', {
                                    initialValue: authType.toString(),
                                    rules: [{ required, message: '认证类型' }],
                                })(<RadioGroup disabled>
                                    {yunstAuthTypeEnum.map((item) => (<Radio key={item.code} value={item.code}>{item.value}</Radio>))}
                                </RadioGroup>)}
                                <div className={styles.desp}>一证：统一社会信用</div>
                                <div className={styles.desp}>三证：营业执照号、组织机构代码、税务登记证</div>
                            </Form.Item>
                        }
                        <Form.Item
                            colon={false}
                            htmlFor='companyName'
                            label={<span>单位名称：</span>}
                        >
                            {getFieldDecorator('companyName', {
                                rules: [{ required, message: '单位名称' }],
                                initialValue: companyName
                            })(<Input disabled placeholder='单位名称' />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='companyAddress'
                            label={<span>注册地址：</span>}
                        >
                            {getFieldDecorator('companyAddress', {
                                rules: [{ required, message: '注册地址' }],
                                initialValue: companyAddress
                            })(<Input disabled placeholder='注册地址' />)}
                        </Form.Item>
                        {st == 'S1' &&
                            <Form.Item
                                colon={false}
                                htmlFor='taxNum'
                                label={<span>统一社会信用证编号：</span>}
                            >
                                {getFieldDecorator('taxNum', {
                                    rules: [
                                        { required, message: '统一社会信用证编号' },
                                        { pattern: /[A-Z0-9]{18}/g, message: '18位数字加大写字母的统一社会信用证编号' },
                                    ],
                                    initialValue: taxNum
                                })(<Input disabled placeholder='18位数字加大写字母的统一社会信用证编号' />)}
                            </Form.Item>
                        }
                        {st == 'S1' &&
                            <Form.Item
                                colon={false}
                                htmlFor='taxUrl'
                                label={<span>统一社会信用证：</span>}
                            >
                                <XMediaFn
                                    dataType='image'
                                    dataSource={taxUrl}
                                />
                            </Form.Item>
                        }
                        {st == 'S1' &&
                            <Form.Item
                                colon={false}
                                htmlFor='taxTime'
                                label={<span>证照有效截止日期：</span>}
                            >
                                {getFieldDecorator('taxTime', {
                                    initialValue: taxTime
                                })(
                                    <Input disabled placeholder='证照有效截止日期' />
                                )}
                            </Form.Item>
                        }
                        {st != 'S1' &&
                            <Form.Item
                                colon={false}
                                htmlFor='idNo'
                                label={<span>营业执照号：</span>}
                            >
                                {getFieldDecorator('idNo', {
                                    rules: [
                                        { required, message: '营业执照号' },
                                        { pattern: /^[a-zA-Z0-9]{0,50}$/, message: '最大长度不能超过50位' },
                                    ],
                                    initialValue: idNo
                                })(<Input disabled placeholder='营业执照号' />)}
                            </Form.Item>
                        }
                        {st == 'S3' &&
                            <Form.Item
                                colon={false}
                                htmlFor='idNoUrl'
                                label={<span>营业执照：</span>}
                            >
                                <XMediaFn
                                    dataType='image'
                                    dataSource={idNoUrl}
                                />
                            </Form.Item>
                        }
                        {st == 'S3' &&
                            <Form.Item
                                colon={false}
                                htmlFor='idNoTime'
                                label={<span>营业执照有效截止日期：</span>}
                            >
                                {getFieldDecorator('idNoTime', {
                                    initialValue: idNoTime
                                })(
                                    <Input disabled placeholder='营业执照有效截止日期' />
                                )}
                            </Form.Item>
                        }
                        {st == 'S3' &&
                            <Form.Item
                                colon={false}
                                htmlFor='orgCode'
                                label={<span>组织机构代码：</span>}
                            >
                                {getFieldDecorator('orgCode', {
                                    rules: [
                                        { required, message: '组织机构代码' },
                                        { pattern: /^[a-zA-Z0-9]{0,50}$/, message: '最大长度不能超过50位' },
                                    ],
                                    initialValue: orgCode
                                })(<Input disabled placeholder='组织机构代码' />)}
                            </Form.Item>
                        }
                        {st == 'S3' &&
                            <Form.Item
                                colon={false}
                                htmlFor='orgCodeUrl'
                                label={<span>组织机构代码证：</span>}
                            >
                                <XMediaFn
                                    dataType='image'
                                    dataSource={orgCodeUrl}
                                />
                            </Form.Item>
                        }
                        {st == 'S3' &&
                            <Form.Item
                                colon={false}
                                htmlFor='taxCert'
                                label={<span>税务登记证号：</span>}
                            >
                                {getFieldDecorator('taxCert', {
                                    rules: [
                                        { required, message: '税务登记证号' },
                                        { pattern: /^[a-zA-Z0-9]{0,50}$/, message: '最大长度不能超过50位' },
                                    ],
                                    initialValue: taxCert
                                })(<Input disabled placeholder='税务登记证号' />)}
                            </Form.Item>
                        }
                        {st == 'S3' &&
                            <Form.Item
                                colon={false}
                                htmlFor='taxCertUrl'
                                label={<span>税务登记证：</span>}
                            >
                                <XMediaFn
                                    dataType='image'
                                    dataSource={taxCertUrl}
                                />
                            </Form.Item>
                        }
                        <Form.Item
                            colon={false}
                            htmlFor='phone'
                            label={<span>联系电话：</span>}
                        >
                            {getFieldDecorator('phone', {
                                initialValue: phone,
                                rules: [
                                    { required, message: '固话(区号+号码，如010-1234567)或手机号码' },
                                    {
                                        validator: (rule, value = '', callback) => {
                                            var RegExp = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/;
                                            if (value == '') {
                                                callback();
                                            } else if (RegExp.test(value) == false) {
                                                callback('正确的固话(区号+号码，如010-1234567)或手机号码');
                                            } else {
                                                callback();
                                            }
                                        }
                                    }
                                ]
                            })(<Input disabled placeholder='固话(区号+号码，如010-1234567)或手机号码' />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='corpName'
                            label={<span>法人姓名：</span>}
                        >
                            {getFieldDecorator('corpName', {
                                rules: [
                                    { required, message: '2-6位姓名(仅支持中文)' },
                                    { pattern: /^[\u4e00-\u9fa5]{2,6}$/, message: '正确的2-6位姓名(仅支持中文)' },
                                ],
                                initialValue: corpName
                            })(<Input disabled placeholder='2-6位姓名(仅支持中文)' />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='mobile'
                            label={<span>法人手机：</span>}
                        >
                            {getFieldDecorator('mobile', {
                                rules: [
                                    { required, message: '11位法人手机号码' },
                                    { pattern: /^[1-9]\d{10}$/, message: '正确的手机号码' },
                                ],
                                initialValue: mobile
                            })(<Input disabled placeholder='11位法人手机号码' />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='legPerId'
                            label={<span>法人身份证号：</span>}
                        >
                            {getFieldDecorator('legPerId', {
                                rules: [
                                    { required, message: '法人身份证号' },
                                    { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '正确的身份证号' },
                                ],
                                initialValue: legPerId
                            })(<Input disabled placeholder='法人身份证号' />)}
                        </Form.Item>
                        {st != 'P' &&
                            <Form.Item
                                colon={false}
                                label={<span>身份证：</span>}
                            >
                                <div className={styles.upload}>
                                    <XMediaFn
                                        dataType='image'
                                        dataSource={identity.legPerIdFrontUrl}
                                    />
                                    <XMediaFn
                                        dataType='image'
                                        dataSource={identity.legPerIdBackUrl}
                                    />
                                </div>
                            </Form.Item>
                        }
                        {st != 'P' &&
                            <Form.Item
                                colon={false}
                                htmlFor='bankCardNo'
                                label={<span>银行账号：</span>}
                            >
                                {getFieldDecorator('bankCardNo', {
                                    rules: [
                                        { required, message: '24位或以下数字组成的银行账号(企业对公账号)' },
                                        {
                                            validator: (rule, value = '', callback) => {
                                                var pattern = /^\d{0,24}$/;
                                                // 去除空格
                                                var str = value.replace(/\s+/g, '');
                                                if (pattern.test(str)) {
                                                    callback();
                                                } else {
                                                    callback('24位或以下数字组成的银行账号(企业对公账号)');
                                                }
                                            }
                                        }
                                    ],
                                    initialValue: bankCardNo
                                })(<Input disabled placeholder='24位或以下数字组成的银行账号(企业对公账号)' />)}
                            </Form.Item>
                        }
                        {st != 'P' &&
                            <Form.Item
                                colon={false}
                                htmlFor='parentBankName'
                                label={<span>开户银行：</span>}
                            >
                                {getFieldDecorator('parentBankName', {
                                    rules: [
                                        { required, message: '开户银行' },
                                    ],
                                    initialValue: parentBankName
                                })(
                                    <Input disabled placeholder='开户银行' />
                                )}
                            </Form.Item>
                        }
                        {st != 'P' &&
                            <Form.Item
                                colon={false}
                                htmlFor='bankName'
                                label={<span>开户行支行名称：</span>}
                            >
                                {getFieldDecorator('bankName', {
                                    rules: [
                                        { required, message: '开户行支行名称' },
                                    ],
                                    initialValue: bankName,
                                })(
                                    <Input disabled placeholder='开户行支行名称' />
                                )}
                            </Form.Item>
                        }
                        {st != 'P' &&
                            <Form.Item
                                colon={false}
                                htmlFor='unionBank'
                                label={<span>开户支行行号：</span>}
                            >
                                {getFieldDecorator('unionBank', {
                                    rules: [
                                        { required, message: '开户支行行号' },
                                    ],
                                    initialValue: unionBank
                                })(<Input disabled placeholder='开户支行行号' />)}
                            </Form.Item>
                        }
                        <Form.Item
                            colon={false}
                            htmlFor='email'
                            label={<span>收件人邮箱地址：</span>}
                        >
                            {getFieldDecorator('email', {
                                rules: [
                                    { required: true, message: '请输入收件人邮箱地址' },
                                    { pattern: /^[0-9a-zA-Z._-]+[@][0-9a-zA-Z._-]+([.][a-zA-Z]+){1,2}$/, message: '请输入正确的邮箱地址' },
                                ],
                                initialValue: email
                            })(<Input placeholder='请输入收件人邮箱地址' />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='invoiceAddress'
                            label={<span>收件人地址：</span>}
                        >
                            {getFieldDecorator('invoiceAddress', {
                                rules: [
                                    { required: true, message: '请输入收件人地址' },
                                    { pattern: /^[^\s]*$/, message: '禁止输入空格' }
                                ],
                                initialValue: invoiceAddress
                            })(<Input placeholder='请输入收件人地址' />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='invoiceName'
                            label={<span>收票人姓名：</span>}
                        >
                            {getFieldDecorator('invoiceName', {
                                rules: [
                                    { required: true, message: '请输入2-6位姓名(仅支持中文)' },
                                    { pattern: /^[\u4e00-\u9fa5]{2,6}$/, message: '请输入正确的2-6位姓名(仅支持中文)' },
                                ],
                                initialValue: invoiceName
                            })(<Input placeholder='请输入2-6位姓名(仅支持中文)' />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='invoicePhone'
                            label={<span>收票人手机：</span>}
                        >
                            {getFieldDecorator('invoicePhone', {
                                rules: [
                                    { required: true, message: '请输入11位收票人手机号码' },
                                    { pattern: /^[1-9]\d{10}$/, message: '请输入正确的手机号码' },
                                ],
                                initialValue: invoicePhone
                            })(<Input placeholder='请输入11位收票人手机号码' />)}
                        </Form.Item>

                    </Form>
                </div>
                <div className={styles.btnContainer}>
                    <div className={styles.line}></div>
                    <div className={styles.btn}>
                        <Button style={{ width: 100, marginRight: 20 }} type="primary" onClick={this._handleSubmit}>保存</Button>
                        <ButtonPerssion code='systemmanage/financialsetting/cxzc'>
                            <Button style={{ width: 100 }} type="primary" onClick={this._register}>重新注册</Button>
                        </ButtonPerssion>
                    </div>
                </div>
                {showRegisterInfoModal ? <RegisterInfo history={this.props.history} visible={showRegisterInfoModal} close={() => this.setState({ showRegisterInfoModal: false })} /> : null}
            </div >
        );
    }
}

AllPay.propTypes = {
    history: PropTypes.object,
    form: PropTypes.object, // 表单校验
    getYunstAuthTypeEnum: PropTypes.func,
    yunstAuthTypeEnum: PropTypes.array,
};
const allPayForm = Form.create({ name: 'allPay-form' })(AllPay);
const mapStateToProps = (state) => ({
    yunstAuthTypeEnum: state.financialsetting.getYunstAuthTypeEnum,
});

export default connect(mapStateToProps, { ...model.actions })(allPayForm);


