/**
 * 自定义预览
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { getLocalStorage } from '@/assets/js/storage';
import './PreviewModal.module.scss';

const AppCustomLandingUrl = `${process.env.AppCustomLandingUrl}`;
const platform = `${process.env.Platform}`;

class PreviewModal extends React.Component {

    onLoad = () => {
        const { category, id, templateId, componentKey } = this.props;
        const { token, userInfo } = JSON.parse(getLocalStorage('auth'));
        document.getElementById('appcustomIframe').contentWindow.postMessage({
            mode: 'preview',
            id,
            templateId,
            category,
            componentKey,
            platform,
            parentUrl: window.location.href.split('#')[0],
            token,
            userInfo
        }, AppCustomLandingUrl);
    }

    render() {
        const { modalProps } = this.props;
        return (
            <Modal title='预览' {...modalProps} style={{ textAlign: 'center' }} footer={null}>
                <iframe id="appcustomIframe" style={{ width: '375px', height: '667px', border: 'none' }}
                    src={AppCustomLandingUrl}
                    onLoad={this.onLoad}></iframe>
            </Modal>
        );
    }
}

PreviewModal.propTypes = {
    componentKey: PropTypes.string,
    category: PropTypes.string,
    id: PropTypes.string,
    templateId: PropTypes.string,
    modalProps: PropTypes.object
};

export default PreviewModal;