import { getWarehouseType, getListAreaByParentId, getSupplierListTableSources, getShopTypes, getGoodsTypes, getlistServiceProviderKetName } from './supplierlists/saga';
import purchasecontract from './purchasecontract/model';
export default [
    getSupplierListTableSources,
    getShopTypes,
    getWarehouseType,
    getListAreaByParentId,
    getGoodsTypes,
    getlistServiceProviderKetName,
    ...Object.values(purchasecontract.sagas)
];
