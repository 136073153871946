import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './TableBox.module.scss';
import { Table, Switch, Icon, Tooltip } from 'antd';
import { XPagination, XTableSelectionCount, XTableAdapter } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell, cellWidth, tooltipClick } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';

const TableBox = ({
	tableAction,
	renderData: {
		dataList,
		pageNum,
		pageSize,
		resultTotal
	},
	paginationChange,
	batchKeys = [],
}) => {
	const [scrollY, setScrollY] = useState(0);
	const switchCell = (text, item) => {
		return <Switch
			checkedChildren={<Icon type="check" />}
			unCheckedChildren={<Icon type="close" />}
			checked={text === '1' ? true : false}
			onChange={value => tableAction(item.goodsId, 'isRec', { wholesaleGoodsIds: [item.wholesaleGoodsId], isRecommend: value ? 1 : 0 })}
		/>;
	};

	const renderPrice = (min, max) => {
		if (!min && !max) {
			return tooltip('');
		} else if (min !== max) {
			return tooltip(`${regFenToYuan(min)} - ${regFenToYuan(max)}`);
		} else if (min === max) {
			return tooltip(regFenToYuan(min));
		}
	};

	const tableOperate = (id, item) => {
		const { wholesaleGoodsId, status } = item;
		return <div className={styles.action}>
			<div className={styles.item} onClick={() => tableAction(id, 'detail')}>查看详情</div>
			{
				status === 'PUT_ON_SALE' ?
					<div className={styles.item} onClick={() => tableAction(wholesaleGoodsId, 'soldOut')}>下架</div> :
					<Fragment>
						<div className={styles.item} onClick={() => tableAction(wholesaleGoodsId, 'putaway')}>上架</div>
						<div className={styles.item} onClick={() => tableAction(id, 'ban')}>禁用</div>
						<div className={styles.item} onClick={() => tableAction(id, 'setPrice', item)}>商品设置</div>
					</Fragment>
			}
		</div>;
	};

	const limitTitle = <Fragment>
		<span>限购（每人/件）</span>
		<Tooltip
			placement='topRight'
			title='每件商品用户在每个销售渠道的可购买数量'>
			<Icon
				type='question-circle'
				theme='filled'
			/>
		</Tooltip>
	</Fragment>;

	const channelCell = (t, { onChannels = [] }) => {
		return onChannels.map(i => { return i.value; }).join('，');
	};

	const columns = () => {
		return [
			{
				title: '商品图片',
				dataIndex: 'mainImgUrl',
				key: 'mainImgUrl',
				align: 'left',
				width: cellWidth.small,
				render: ImgCell
			}, {
				title: '商品标题',
				dataIndex: 'goodsName',
				key: 'goodsName',
				align: 'left',
				width: cellWidth.big,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '商品货号',
				dataIndex: 'goodsNo',
				key: 'goodsNo',
				align: 'left',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '推荐',
				dataIndex: 'isReco',
				key: 'isReco',
				width: cellWidth.small,
				align: 'left',
				render: switchCell
			}, {
				title: '状态',
				dataIndex: 'statusValue',
				key: 'statusValue',
				align: 'left',
				width: cellWidth.small,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: 'SKU',
				dataIndex: 'skuNumber',
				key: 'skuNumber',
				align: 'left',
				width: cellWidth.small,
				onCell: tooltipStyle,
				render: (text, item) => tooltipClick(text, () => tableAction(text, 'setPrice', item))
			}, {
				title: '商户',
				dataIndex: 'merchantName',
				key: 'merchantName',
				align: 'left',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '商品分类',
				dataIndex: 'categoryFullName',
				key: 'categoryFullName',
				align: 'left',
				width: cellWidth.time,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '渠道价（元）',
				dataIndex: 'distributionPrice',
				key: 'distributionPrice',
				align: 'left',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: (text, item) => renderPrice(item.minRetailPrice, item.maxRetailPrice)
			}, {
				title: '结算价（元）',
				dataIndex: 'wholesalePrice',
				key: 'wholesalePrice',
				align: 'left',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: (text, item) => renderPrice(item.minWholesalePrice, item.maxWholesalePrice)
			}, {
				title: '销售价（元）',
				dataIndex: 'salaPrice',
				key: 'salaPrice',
				align: 'left',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: (text, item) => renderPrice(item.minSalePrice, item.maxSalePrice)
			}, {
				title: limitTitle,
				dataIndex: 'limitNumberText',
				key: 'limitNumberText',
				align: 'left',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '商品库存',
				dataIndex: 'totalQuantity',
				key: 'totalQuantity',
				align: 'left',
				width: cellWidth.small,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '上架渠道',
				dataIndex: 'onChannel',
				key: 'onChannel',
				align: 'left',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: channelCell
			}, {
				title: '操作',
				dataIndex: 'goodsId',
				key: 'goodsId',
				align: 'left',
				fixed: 'right',
				width: 230,
				render: tableOperate
			}];
	};

	// 表格复选输入
	const rowSelection = {
		selectedRowKeys: batchKeys,
		onChange: (selectedRowKeys) => {
			tableAction(null, 'batchKeys', selectedRowKeys);
		}
	};

	return (
		<div className={styles.flexBoxContainer}>
			<XTableSelectionCount
				selectedNum={batchKeys.length}
				onClean={() => {
					tableAction(null, 'batchKeys', []);
				}} />
			<XTableAdapter render={scrollY => setScrollY(scrollY)}>
				<div className={styles.tableContainer} style={{ height: '100%' }}>
					<Table
						rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
						rowKey={record => record.wholesaleGoodsId}
						rowSelection={rowSelection}
						columns={columns()}
						pagination={false}
						dataSource={dataList}
						scroll={{ x: 'max-content', y: scrollY }} />
					<XPagination
						resultTotal={resultTotal}
						pageSize={pageSize}
						pageNum={pageNum}
						onChange={paginationChange} />
				</div>
			</XTableAdapter>
		</div>
	);
};

TableBox.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
	batchKeys: PropTypes.array
};

export default TableBox;