import React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import styles from './TableData.module.scss';

const columns = (actionClickHandle) => [
  {
    title: '视频分类',
    dataIndex: 'feedClassName',
    key: 'feedClassName',
    width: 100,
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '视频名称',
    dataIndex: 'feedTitle',
    key: 'feedTitle ',
    width: 100,
    align: 'center',
    // eslint-disable-next-line react/display-name
    render: (text) => {
      return <Ellipsis tooltip={text} lines={1}>{text}</Ellipsis>;
    }
  },
  {
    title: '评论内容',
    dataIndex: 'content',
    key: 'content',
    width: 220,
    align: 'center',
    // eslint-disable-next-line react/display-name
    render: (text) => {
      return <Ellipsis tooltip={text} lines={1}>{text}</Ellipsis>;
    }
  },
  {
    title: '评论人',
    dataIndex: 'nickname',
    key: 'nickname',
    width: 100,
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '评论时间',
    dataIndex: 'gmtCreate',
    key: 'gmtCreate',
    width: 120,
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '操作',
    dataIndex: 'vedeoaction',
    key: 'vedeoaction',
    align: 'center',
    width: 100,
    render: (id)=> TableAction(id, actionClickHandle)
  },
];


export const takeDataSource = (data) => {
  let newTableSource = [];
  newTableSource = data.map(item => ({
        feedClassName: item.feedClassName,
        feedTitle: item.feedTitle,
        nickname: item.nickname,
        content: item.content,
        gmtCreate: item.gmtCreate,
        vedeoaction: item.id
  }));
  return newTableSource;
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};

const TableCell = (text) => {
  return(
    <div style={{wordWrap:'break-word',wordBreak:'break-all'}}>
      {text}
    </div>
  );
};

const TableAction = (id, actionClickHandle) => {
  return(
    <div className={styles.action}>
        <div className={styles.item} onClick={()=>actionClickHandle(id, 'DETAILS_INFO')}>查看</div>
    </div>
  );
};