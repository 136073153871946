import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const TableContent = ({ renderData }) => {

    const columns = () => ([
        {
            title: '用户账号',
            dataIndex: 'tel',
            key: 'tel',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '姓名',
            dataIndex: 'userName',
            key: 'userName',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '身份证号',
            dataIndex: 'cardNum',
            key: 'cardNum',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '银行卡号',
            dataIndex: 'bindAccountNo',
            key: 'bindAccountNo',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '银行支行名称',
            dataIndex: 'bindBranchName',
            key: 'bindBranchName',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '银行预留手机号',
            dataIndex: 'telephone',
            key: 'telephone',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '绑定账户类型',
            dataIndex: 'subjectTypeDesc',
            key: 'subjectTypeDesc',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '签约渠道',
            dataIndex: 'accessTypeDesc',
            key: 'accessTypeDesc',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '签约状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '签约时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '失败原因',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
    ]);

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.id}
                columns={columns()}
                dataSource={renderData}
                pagination={false}
                scroll={{ y: true }}
            />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.array.isRequired, // 表格数据源
};

export default TableContent;