/**
 * 新建物流公司弹框
 */
import React from 'react';
import { Modal, Icon, message, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './CreateModal.module.scss';
import CustomUpload from '@/components/NewUpload';
import http from '@/assets/api/http';

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};
const { TextArea } = Input;

class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
        };
    }

    _handleOk = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('form表单数据', values);
                const expressLogo = values.expressLogo[0] ? values.expressLogo[0].response.key : '';
                http('/fastpass/save', { ...values, expressLogo }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('创建成功');
                        this.setState({
                            visible: false,
                        });
                        this.props.closeMode(true);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
            }
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _normFile = e => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    }

    render() {
        const { visible, confirmLoading, title, expressLogo } = this.state;
        const { form: { getFieldDecorator } = {} } = this.props;
        return (
            <Modal
                width={600}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} autoComplete='off' onSubmit={this.handleSubmit} colon={false}>
                        <FormItem label="物流公司名称">
                            {getFieldDecorator('expressName', {
                                rules: [
                                    { required: true, message: '请输入物流公司名称' },
                                    { type: 'string', max: 40, message: '物流公司名称最大长度为40' }
                                ],
                            })(<Input placeholder='请输入品牌名称' />)}
                        </FormItem>
                        <FormItem label="物流公司编码">
                            {getFieldDecorator('expressCode', {
                                rules: [
                                    { required: true, message: '请输入物流公司编码' },
                                    { type: 'string', max: 40, message: '物流公司编码最大长度为40' }
                                ],
                            })(<Input placeholder='请输入物流公司编码' />)}
                        </FormItem>
                        <FormItem label="菜鸟物流公司编码">
                            {getFieldDecorator('cainiaoCode', {
                                rules: [
                                    { required: true, message: '请输入菜鸟物流公司编码' },
                                    { type: 'string', max: 40, message: '菜鸟物流公司编码最大长度为40' }
                                ],
                            })(<Input placeholder='请输入菜鸟物流公司编码' />)}
                        </FormItem>
                        <FormItem label="备注">
                            {getFieldDecorator('remark', {
                                rules: [
                                    { type: 'string', max: 200, message: '备注最大长度为200' }
                                ],
                            })(<TextArea placeholder='请输入备注' />)}
                        </FormItem>
                        <FormItem label="LOGO">
                            {getFieldDecorator('expressLogo', {
                                valuePropName: 'fileList',
                                getValueFromEvent: this._normFile,
                                rules: [
                                    { required: true, type: 'array', message: '请上传logo' },
                                ],
                            })(
                                <CustomUpload
                                    accept='.png, .jpg, .jpeg,.gif'
                                    listType='picture-card'
                                    wallMode='single'
                                    removeError={true}
                                    qiniuUploadProps={{ uploadType: 'image' }}
                                >
                                    <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                </CustomUpload>)}
                        </FormItem>
                    </Form>
                </div>
            </Modal>
        );
    }
}

CreateModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    form: PropTypes.object
};

export default Form.create({ name: 'create-info' })(CreateModal);