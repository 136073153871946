import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

/**
 * 社区评论列表 Saga
 */
export const communitySource = function* (){
    yield takeEvery(T.GET_COMMUNIT_DATA, function* requestData(action){
        try{
            let result = yield http('/show/comment/listFeedComments', action.payload,'POST');
            yield put({type:T.SET_COMMUNIT_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};


/**
 * 社区评论详情 Saga
 */
export const communityDetails = function* (){
    yield takeEvery(T.GET_COMMUNITDETAILS_DATA, function* requestData(action){
        try{
            let result = yield http('/show/comment/getFeedCommentReply', action.payload,'POST');
            yield put({type:T.SET_COMMUNITDETAILS_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};


/**
 * 社区评论下拉选项数据方法 Saga
 */
export const communitySelectData = function* (){
    yield takeEvery(T.GET_COMMUNITSELECT_DATA, function* requestData(action){
        try{
            let result = yield http('/show/showClass/listFeedClass', action.payload,'POST');
            yield put({type:T.SET_COMMUNITSELECT_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

/**
 * 社区评论列表查询 Saga
 */
export const communitySelectSource = function* (){
    yield takeEvery(T.GET_COMMUNITSELECTSOURCE_DATA, function* requestData(action){
        try{
            let result = yield http('/show/comment/listFeedComments', action.payload,'POST');
            yield put({type:T.SET_COMMUNIT_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};
