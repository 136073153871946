/**
 * 新建菜单
 */
import React from 'react';
import { Modal, Input, Form, Select, Radio, message } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './CreateModal.module.scss';
import http from '@/assets/api/http';
import { getMenuSelect, getSecondMenuSelect, cleanSecondMenuSelect, getTabSelect, cleanTabSelect, getAffType } from '@/reducers/permissionsmanage/menuconfigure/actions';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};
let sortOption = [];
for (var i = 1; i < 11; i++) {
    sortOption.push(i);
}
const { Option } = Select;

class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            menuid: 0,
            secondMenuName: '',
            tabMenuName: ''
        };
    }

    componentDidMount() {
        const { getMenuSelect, systemType, cleanSecondMenuSelect, cleanTabSelect, getAffType } = this.props;
        cleanSecondMenuSelect();
        cleanTabSelect();
        getAffType();
        getMenuSelect({ systemType });
    }

    // 确认
    _handleOk = () => {
        const { systemType } = this.props;
        const { menuid } = this.state;
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                let data = { ...fieldsValue, systemType };
                if (menuid == 1) {
                    data.parentId = fieldsValue.parentId.menuId;
                } else if (menuid == 2) {
                    data.parentId = fieldsValue.parentId.secondMenuId;
                    if (data.parentId == '') {
                        message.error('请选择二级菜单');
                        return;
                    }
                } else if (menuid == 3) {
                    if (fieldsValue.parentId.tabId != '') {
                        data.parentId = fieldsValue.parentId.tabId;
                    } else {
                        data.parentId = fieldsValue.parentId.secondMenuId;
                    }
                    if (data.parentId == '') {
                        message.error('请选择二级菜单或tab标签页');
                        return;
                    }
                } else {
                    data.parentId = '';
                }
                data.menuType = menuid == 0 ? 1 : menuid;
                http('/admin/menu/createMenu', data, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('创建成功！');
                        this.setState({
                            visible: false,
                        });
                        this.props.closeMode(true);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
            }
        });
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // 渲染父级菜单下拉列
    _renderSelect = (list) => {
        return list.map((item) => {
            return <Option key={item.id} value={item.id}>{item.menuName}</Option>;
        });
    }

    // 单选发生变化
    _menuTypeChange = (e) => {
        this.setState({ menuid: e.target.value });
    }

    render() {
        const { visible, confirmLoading, menuid, secondMenuName, tabMenuName } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { menuSelect, secondMenuSelect, tabSelect, affType, systemType } = this.props;

        return (
            <Modal
                width={700}
                centered
                title='新建菜单'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon={false}
                            htmlFor='menuType'
                            label={<span>菜单类型</span>}
                        >
                            {getFieldDecorator('menuType', {
                                initialValue: 0,
                                rules: [{ required: true, message: '请选择菜单类型' }],
                            })(
                                <Radio.Group onChange={this._menuTypeChange}>
                                    <Radio key={0} value={0}>一级目录</Radio>
                                    <Radio key={1} value={1}>二级菜单</Radio>
                                    <Radio key={2} value={2}>Tab标签页</Radio>
                                    <Radio key={3} value={3}>按钮</Radio>
                                </Radio.Group>,
                            )}
                        </Form.Item>
                        {menuid != 0 ? <Form.Item
                            colon={false}
                            htmlFor='parentId'
                            label={<span>父级菜单</span>}
                        >
                            {getFieldDecorator('parentId', {
                                rules: [{ required: true, message: '请选择父级菜单' }],
                            })(<div>
                                <Select
                                    placeholder='请选择一级目录'
                                    style={{ width: 160 }}
                                    onChange={(value) => {
                                        let parentId = this.props.form.getFieldsValue().parentId;
                                        parentId = { ...parentId, menuId: value, secondMenuId: '', tabId: '' };
                                        this.props.form.setFieldsValue({ parentId });
                                        this.props.getSecondMenuSelect({ parentId: value, menuType: '1' });
                                        this.props.cleanSecondMenuSelect();
                                        this.props.cleanTabSelect();
                                        this.setState({ secondMenuName: '', tabMenuName: '' });
                                    }}
                                >
                                    {this._renderSelect(menuSelect)}
                                </Select>
                                {menuid == 2 || menuid == 3 ? <Select
                                    value={secondMenuName ? secondMenuName : undefined}
                                    placeholder='请选择二级菜单'
                                    style={{ width: 160, marginLeft: 10 }}
                                    onChange={(value, item) => {
                                        let parentId = this.props.form.getFieldsValue().parentId;
                                        parentId = { ...parentId, secondMenuId: value, tabId: '' };
                                        this.props.form.setFieldsValue({ parentId });
                                        this.props.getTabSelect({ parentId: value, menuType: '2' });
                                        this.props.cleanTabSelect();
                                        this.setState({ secondMenuName: item.props.children, tabMenuName: '' });
                                    }}
                                >
                                    {this._renderSelect(secondMenuSelect)}
                                </Select> : null}
                                {menuid == 3 ? <Select
                                    value={tabMenuName ? tabMenuName : undefined}
                                    placeholder='请选择Tab标签页'
                                    style={{ width: 160, marginLeft: 10 }}
                                    onChange={(value, item) => {
                                        let parentId = this.props.form.getFieldsValue().parentId;
                                        parentId = { ...parentId, tabId: value };
                                        this.props.form.setFieldsValue({ parentId });
                                        this.setState({ tabMenuName: item.props.children });
                                    }}
                                >
                                    {this._renderSelect(tabSelect)}
                                </Select> : null}
                            </div>)}
                        </Form.Item> : null}
                        <Form.Item
                            colon={false}
                            htmlFor='menuName'
                            label={<span>菜单名称</span>}
                        >
                            {getFieldDecorator('menuName', {
                                rules: [{ required: true, message: '请输入菜单名称' }],
                            })(<Input placeholder='请输入菜单名称' style={{ width: 320 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='menuUrl'
                            label={<span>URL</span>}
                        >
                            {getFieldDecorator('menuUrl', {
                                rules: [{ required: true, message: '请输入URL地址' }],
                            })(<Input placeholder='请输入URL地址' style={{ width: 320 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='menuCode'
                            label={<span>编号</span>}
                        >
                            {getFieldDecorator('menuCode', {
                                rules: [{ required: true, message: '请输入菜单编号' }],
                            })(<Input placeholder='请输入菜单编号' style={{ width: 320 }} />)}
                        </Form.Item>
                        {
                            systemType == 'PLAT' ? <Form.Item
                                colon={false}
                                htmlFor='affiliationType'
                                label={<span>终端</span>}
                            >
                                {getFieldDecorator('affiliationType', {
                                    rules: [{ required: true, message: '请输入终端选项' }],
                                })(
                                    <Radio.Group>
                                        {affType.map((item, index) => {
                                            return <Radio key={index} value={item.code}>{item.value}</Radio>;
                                        })}
                                    </Radio.Group>
                                )}
                            </Form.Item> : null
                        }
                    </Form>
                </div>
            </Modal>
        );

    }
}

CreateModal.propTypes = {
    systemType: PropTypes.string, // 弹框入参
    form: PropTypes.object.isRequired, // 表单验证
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getMenuSelect: PropTypes.func, // 获取一级菜单下拉列
    menuSelect: PropTypes.array, // 一级菜单下拉列
    cleanSecondMenuSelect: PropTypes.func, // 清空二级菜单下拉列
    cleanTabSelect: PropTypes.func, // 清空Tab菜单下拉列
    getSecondMenuSelect: PropTypes.func, // 获取二级菜单下拉列
    secondMenuSelect: PropTypes.array, // 二级菜单下拉列
    getTabSelect: PropTypes.func, // 获取Tab菜单下拉列
    tabSelect: PropTypes.array, // Tab菜单下拉列
    getAffType: PropTypes.func, // 获取总控菜单终端类型
    affType: PropTypes.array, // 总控菜单终端类型
};

const ForgotProducDetails = Form.create({ name: 'create-form' })(CreateModal);// 校验
const mapStateToProps = (state) => ({
    menuSelect: state.menuconfigure.getMenuSelect,
    secondMenuSelect: state.menuconfigure.getSecondMenuSelect,
    tabSelect: state.menuconfigure.getTabSelect,
    affType: state.menuconfigure.getAffType,
});

export default connect(mapStateToProps, { getMenuSelect, cleanSecondMenuSelect, cleanTabSelect, getSecondMenuSelect, getTabSelect, getAffType })(ForgotProducDetails);
