import * as Task from './actionTypes';

//任务列表
export const getTaskList = ({ taskTitle, taskCategoryId, taskType, pushPlat, startTime, endTime, state, pageNum, pageSize }) => ({
    type: Task.GET_TASKMANAGE_TASKLIST,
    payload: {
        taskTitle, taskCategoryId, taskType, pushPlat, startTime, endTime, state, pageNum, pageSize
    }
});

//任务详情
export const getTaskDetail = (taskId) => ({
    type: Task.GET_TASKMANAGE_TASKDETAIL,
    payload: {
        taskId
    }
});

//清理任务详情
export const cleanTaskDetail = () => ({
    type: Task.CLEAN_TASKDETAIL,
    payload: {}
});

//任务分类下拉
export const getTaskClassifySele = () => ({
    type: Task.GET_TASKMANAGE_TASKCLASSIFYSELE,
    payload: {}
});

//任务类型下拉
export const getTaskTypeSele = () => ({
    type: Task.GET_TASKMANAGE_TASKTYPESELE,
    payload: {}
});

//任务推送平台下拉
export const getTaskPlatSele = () => ({
    type: Task.GET_TASKMANAGE_TASKPLATSELE,
    payload: {}
});

//任务信息下拉
export const getTaskInfoSele = (categoryId) => ({
    type: Task.GET_TASKMANAGE_TASKINFOSELE,
    payload: { categoryId }
});

//任务要素下拉
export const getTaskFactorSele = (eventCode) => ({
    type: Task.GET_TASKMANAGE_TASKFACTORSELE,
    payload: { eventCode }
});

//任务奖励下拉
export const getTaskAwardSele = () => ({
    type: Task.GET_TASKMANAGE_TASKAWARDSELE,
    payload: {}
});

//任务奖励会员等级
export const getTaskGrade = () => ({
    type: Task.GET_TASKMANAGE_TASKGRADE,
    payload: {}
});



