/**
 * 商品分类三级联查
 */
import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import http from '@/assets/api/http';
import { Select } from 'antd';

const { Option } = Select;

const GoodsClassify = ({ style, label, categoryType = 'MALL', onClassifyChange, categorySubType = '' }, ref) => {

    const [firstLevel, setFirstLevel] = useState([]);
    const [secondLevel, setSecondLevel] = useState([]);
    const [thirdLevel, setThirdLevel] = useState([]);
    const [firstItem, setFirstItem] = useState({});
    const [secondItem, setSecondItem] = useState({});
    const [thirdItem, setThirdItem] = useState({});

    const onReq = ({ categoryGrade, parentId, setFunc, categorySubType, categoryType }) => {
        http('/goods/category/linkage', { categoryGrade, parentId, categoryType, categorySubType }, 'POST')
            .then(res => {
                if (res.status == 200) {
                    setFunc(res.result);
                } else {
                    console.log('请求商品状态失败');
                }
            })
            .catch(() => {
                console.log('请求商品状态失败');
            });
    };

    useEffect(() => {
        setFirstLevel([]);
        setSecondLevel([]);
        setThirdLevel([]);
        setFirstItem({});
        setSecondItem({});
        setThirdItem({});
        onReq({ categoryGrade: 1, parentId: 0, setFunc: setFirstLevel, categorySubType, categoryType });
    }, [categorySubType]);



    const onFirstChange = (value, { props = {} }) => {
        onClassifyChange(value, 'first');
        setFirstItem(props);
        setSecondItem({});
        setThirdItem({});
        onReq({ categoryGrade: 2, parentId: value, setFunc: setSecondLevel, categorySubType, categoryType });
        onReq({ categoryGrade: 3, parentId: value, setFunc: setThirdLevel, categorySubType, categoryType });
    };
    const onSecondChange = (value, { props = {} }) => {
        onClassifyChange(value, 'second');
        setSecondItem(props);
        setThirdItem({});
        onReq({ categoryGrade: 3, parentId: value, setFunc: setThirdLevel, categorySubType, categoryType });
    };
    const onthirdChange = (value, { props = {} }) => {
        onClassifyChange(value, 'third');
        setThirdItem(props);
    };

    useImperativeHandle(ref, () => ({
        clean: () => {
            setFirstItem({});
            setSecondItem({});
            setThirdItem({});
            onReq({ categoryGrade: 2, parentId: 0, setFunc: setSecondLevel, categorySubType, categoryType });
            onReq({ categoryGrade: 3, parentId: 0, setFunc: setThirdLevel, categorySubType, categoryType });
        }
    }));

    return <div className={styles.container} style={style}>
        {!!label && <div className={styles.title}>{label}</div>}
        <div className={styles.cascade}>
            <Select
                showSearch
                style={{ width: 150 }}
                placeholder='请选择一级分类'
                optionFilterProp='children'
                onChange={onFirstChange}
                value={firstItem.children}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {firstLevel.map(fl => (
                    <Option key={fl.id} value={fl.id} >{fl.categoryName}</Option>
                ))}
            </Select>
            <Select
                showSearch
                style={{ width: 150, marginLeft: 10 }}
                placeholder='请选择二级分类'
                optionFilterProp='children'
                onChange={onSecondChange}
                value={secondItem.children}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {secondLevel.map(sl => (
                    <Option key={sl.id} value={sl.id} >{sl.categoryName}</Option>
                ))}
            </Select>
            <Select
                showSearch
                style={{ width: 150, marginLeft: 10 }}
                placeholder='请选择三级分类'
                optionFilterProp='children'
                onChange={onthirdChange}
                value={thirdItem.children}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {thirdLevel.map(tl => (
                    <Option key={tl.id} value={tl.id} >{tl.categoryName}</Option>
                ))}
            </Select>
        </div>
    </div >;
};
GoodsClassify.propTypes = {
    style: PropTypes.object,
    label: PropTypes.string,
    categoryType: PropTypes.string,
    onClassifyChange: PropTypes.func,
    categorySubType: PropTypes.string
};
export default forwardRef(GoodsClassify);