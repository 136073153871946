/**
 * 订单管理 Saga集合
 */
import { getProductOrderList, getProductOrderType, getProductOrderStatus, getProductSubOrderStatus, getProductOrderFlagEnum, getProductConvertWays, getDistributionFlagEnum, getPaymentAccessTypeEnum, getPaymentTypeEnum, getOrderSourceEnum } from './productOrder/saga';
import { getStarOrderList, getPayWays, getRiskType, getFailStarOrderList, getHandleStatus, getRiskUserList, getRiskLevel, getDeviceTypeList } from './starOrder/saga';
import { getShopOrderList, getExpressCompanyList } from './shopOrder/saga';
import { getHotelOrderList, getHotelPayWays } from './hotelOrder/saga';
import { getSubOrderList } from './subscriptionOrder/saga';
import { getFreeOrderList, getFreeOrderStatus, getFreeProductList, getFreeOrderKanBanList, getAutoFreeOrderList, getAutoFreeOrderStatus } from './freeOrder/saga';
import { getTranscationList, getMerchantList, getTranscationServiceList, getCostServiceList } from './transcation/saga';
import serviceOrder from './serviceOrder/model';
import stockearOrder from './stockearorder/model';
import valueServiceOrder from './valueServiceOrder/model';
import platformOrder from './platformOrder/model';
import cityOrder from './cityOrder/model';
import thirdOrder from './thirdorder/model';
import productOrder from './productOrder/model';

export default [
    getHotelOrderList,
    getStarOrderList,
    getShopOrderList,
    getProductOrderList,
    getProductOrderType,
    getProductOrderStatus,
    getProductOrderFlagEnum,
    getProductConvertWays,
    getDistributionFlagEnum,
    getSubOrderList,
    getFreeOrderList,
    getProductSubOrderStatus,
    getExpressCompanyList,
    getTranscationList,
    getMerchantList,
    getFreeOrderStatus,
    getPayWays,
    getFreeProductList,
    getFreeOrderKanBanList,
    getRiskType,
    getFailStarOrderList,
    getHandleStatus,
    getRiskUserList,
    getRiskLevel,
    getDeviceTypeList,
    getAutoFreeOrderList,
    getAutoFreeOrderStatus,
    getTranscationServiceList,
    getCostServiceList,
    getHotelPayWays,
    ...Object.values(serviceOrder.sagas),
    ...Object.values(stockearOrder.sagas),
    ...Object.values(valueServiceOrder.sagas),
    ...Object.values(platformOrder.sagas),
    ...Object.values(cityOrder.sagas),
    ...Object.values(thirdOrder.sagas),
    getPaymentAccessTypeEnum,
    getPaymentTypeEnum,
    getOrderSourceEnum,
    ...Object.values(productOrder.sagas),
];
