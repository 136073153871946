export const GET_COMMUNIT_DATA = 'COMMUNITCOMMET/GET_COMMUNIT_DATA'; // 异步获取'社区评论列表'数据源
export const SET_COMMUNIT_DATA = 'COMMUNITCOMMET/SET_COMMUNIT_DATA'; // 将'社区评论列表'存储至store.state中


export const GET_COMMUNITDETAILS_DATA = 'COMMUNITCOMMET/GET_COMMUNITDETAILS_DATA'; // 异步获取'社区评论详情'数据源
export const SET_COMMUNITDETAILS_DATA = 'COMMUNITCOMMET/SET_COMMUNITDETAILS_DATA'; // 将'社区评论详情'存储至store.state中


export const GET_COMMUNITSELECT_DATA = 'COMMUNITCOMMET/GET_COMMUNITSELECT_DATA'; // 异步获取'社区评论下拉选项'数据源
export const SET_COMMUNITSELECT_DATA = 'COMMUNITCOMMET/SET_COMMUNITSELECT_DATA'; // 将'社区评论下拉选项'存储至store.state中


export const GET_COMMUNITSELECTSOURCE_DATA = 'COMMUNITCOMMET/GET_COMMUNITSELECTSOURCE_DATA'; // 异步获取'社区评论列表查询'数据源
export const SET_COMMUNITSELECTSOURCE_DATA = 'COMMUNITCOMMET/SET_COMMUNITSELECTSOURCE_DATA'; // 将'社区评论列表查询'存储至store.state中
