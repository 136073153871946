/**
 * 第三方商品品牌下拉搜索框（枚举类型）
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XSelect } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { Select } from 'antd';

const { Option } = Select;

class ThirdPartyGoodsBrand extends React.Component {

    state = {
        renderData: [], // 数据源
    }

    componentDidMount() {
        http('/goods/baseProduct/findBaseGoodsBrandItem', {}, 'POST').then((response) => {
            if (response.status == 200) {
                this.setState({ renderData: response.result });
            } else {
                console.log('/goods/baseProduct/findBaseGoodsBrandItem请求失败');
            }
        }).catch(() => {
            console.log('/goods/baseProduct/findBaseGoodsBrandItem请求失败');
        });
    }

    _onChange = (value) => {
        const { bindThis, bindName } = this.props;

        if (bindThis && bindName) {
            bindThis.setState({ [bindName]: value });
        }

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    onSelectChange = (code)=>{
        let list = this.state.renderData;
        let item = list.filter(v=>v.brandCode == code)[0] || {};
        this._onChange(item);
        
        
    };

    render() {
        const { renderData } = this.state;
        const { style, selectStyle, label, labelStyle, placeholder, isRequired, value } = this.props;
        let name = '';
        for (let i = 0; i < renderData.length; i++) {
            if (renderData[i].brandCode == value.brandCode) {
                name = value.brandName;
                break;
            }
        }

        return (

            <div className={styles.item} style={style}>
                <div style={labelStyle} className={styles.label}>{label}</div>
                <div className={styles.info}>
                    <Select
                        showSearch
                        style={selectStyle}
                        placeholder={placeholder}
                        optionFilterProp="children"
                        onChange={this.onSelectChange}
                        value={name}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                    >
                        {renderData.map((item, index)=>{
                            return (
                                <Option key={item.id} value={item.brandCode}>{item.brandName}</Option>
                            );
                        })}
                    </Select>
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }
}

ThirdPartyGoodsBrand.defaultProps = {
    style: { width: '218px' },
    selectStyle: { width: '150px' },
};

ThirdPartyGoodsBrand.propTypes = {
    bindThis: PropTypes.object, // 父级this
    bindName: PropTypes.string, // 双向绑定的属性名称
    value: PropTypes.object,
    style: PropTypes.object,
    selectStyle: PropTypes.object,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    defaultValue: PropTypes.object,
    isApply: PropTypes.number
};

export default ThirdPartyGoodsBrand;