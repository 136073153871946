import * as T from './actiontypes';

export const getMerchantInvoiceTableSource = (value) => ({
    type: T.GET_MERCHANTINVOICE_TABLESOURCE,
    payload: value
});
export const getMerchantInvoiceDetail = (value) => ({
    type: T.GET_MERCHANTINVOICE_DETAIL,
    payload: value
});
export const getSettlementStatus = (value) => ({
    type: T.GET_SETTLEMENT_STATUS,
    payload: value
});
