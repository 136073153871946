/**
 * 交割
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message } from 'antd';
import styles from './modeStyle.module.scss';
import { XInputNum, XTextArea, XInfoLabel } from '@/components/xqxc_ui';

const Delivery = ({
    modeParams: {
        id = 0,
        ownerName = '',
        ownerTel = '',
        idCardNo = '',
        payAmount = 0,
        shares = 0,
        rate = 0,
        deliveryRealNum = 0
    },
    closeMode
}) => {

    const [loading, setLoading] = useState(false);
    const [deliveryNum, setDeliveryNum] = useState(deliveryRealNum);
    const [remark, setRemark] = useState('');
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        setBalance(payAmount - parseInt((shares * rate / 100 * deliveryNum)));
    }, [deliveryNum]);

    //确定取消
    const onSumbit = () => {
        if (!deliveryNum) {
            message.warning('请输入交割手数');
            return;
        }
        if (balance < 0) {
            message.warning('兑换金额不足');
            return;
        }
        setLoading(true);
        http('/exchangeOrder/delivery', { deliveryRealNum: deliveryNum, id, remark }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            }).catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    return (
        <Modal
            width={500}
            centered
            title='交割信息'
            visible={true}
            confirmLoading={loading}
            onOk={onSumbit}
            onCancel={() => closeMode(false)}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'>
            <div className={styles.container}>
                <XInfoLabel
                    style={{ marginBottom: 20 }}
                    label='兑换人：'
                    value={ownerName}
                />
                <XInfoLabel
                    style={{ marginBottom: 20 }}
                    label='兑换人电话：'
                    value={ownerTel}
                />
                <XInfoLabel
                    style={{ marginBottom: 20 }}
                    label='身份证号：'
                    value={idCardNo}
                />
                <XInfoLabel
                    style={{ marginBottom: 20 }}
                    label='兑换金额（贡献值）：'
                    value={payAmount}
                />
                <XInputNum
                    style={{ marginBottom: 10 }}
                    inputStyle={{ width: 250 }}
                    label='交割手数（手）：'
                    min={0}
                    max={99999}
                    precision={0}
                    placeholder='请输入'
                    bindName='deliveryNum'
                    bindThis={setDeliveryNum}
                    value={deliveryNum}
                    isRequired
                />
                <p className={styles.tipText} style={{ marginBottom: 10 }}>交割数量必须是每手股(张)数的倍数。</p>
                <p className={styles.tipText} style={{ marginBottom: 20 }} >也就是5000股的整倍数进行委托成交</p>
                <XInfoLabel
                    label='剩余兑换金额（贡献值）：'
                    value={balance}
                />
                <p className={styles.tipText} style={{ marginBottom: 20 }}>剩余数量做退回</p>
                <XTextArea
                    inputStyle={{ width: 320 }}
                    label='备注：'
                    bindThis={setRemark}
                    maxLength={100}
                    bindName='remark'
                    value={remark}
                />
            </div>
        </Modal>
    );
};
Delivery.propTypes = {
    modeParams: PropTypes.object.isRequired,
    closeMode: PropTypes.func.isRequired
};
export default Delivery;