import { combineReducers } from 'redux';
import { getDeptList, getDeptDetail, getDeptSelect, getCompanyList, getCompanyDetail } from './reducer';

export default combineReducers({
	getDeptList, // 获取部门列表
	getDeptDetail, // 获取部门详情
	getCompanyList, // 获取公司、运营中心列表
	getCompanyDetail, // 获取公司、运营中心详情 
});

