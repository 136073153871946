import React from 'react';
import styles from './AdvertTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table, Switch, Icon, Input } from 'antd';
import PropTypes from 'prop-types';

class AdvertTable extends React.Component {

    _takeColumns = () => ([
        {
            title: '评论名称',
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '使用次数',
            dataIndex: 'usedNum',
            key: 'usedNum',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '排序',
            dataIndex: 'sortNum',
            key: 'sortNum',
            width: '30%',
            align: 'center',
            render: (text, item) => (this._SortCell(text, item))
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: '15%',
            align: 'center',
            render: (text, item) => (this._switchCell(text, item, 'recommend'))
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            width: '15%',
            render: (text, item) => this._renderActionCell(item.id, item.name)
        }
    ]);
    // 表格项内容为可编辑
 _SortCell = (value, item) => {
    const label = React.createRef();
    const box = React.createRef();
    const input = React.createRef();
    const { actionClickHandle } = this.props;
    return(
        <div style={{wordWrap:'break-word',wordBreak:'break-all'}}>
            <div className={styles.sort_box}>
                <div className={styles.label} ref={label}>{value}</div>
                <div className={styles.input} ref={box}>
                    <Input
                        style={{width: '50px'}} 
                        ref={input}
                        key={item.id}
                        defaultValue={value} 
                        onBlur={(e)=>{
                            box.current.style.display = 'none';
                            label.current.style.display = 'block';
                            if(value != e.target.value){
                                actionClickHandle(item.id, e.target.value);
                            }
                        }}
                    />
                </div>
                <div 
                    className={styles.icon} 
                    onClick={()=>{
                        label.current.style.display = 'none';
                        box.current.style.display = 'block';
                        input.current.focus();
                    }}
                >
                    <Icon type="edit" style={{fontSize: '18px'}} />
                </div>
            </div>
        </div>
    );
  };
    //表格项内容为开关
    _switchCell = (key, item, type) => {
        const { actionHandle } = this.props;
        let checked = true;
        if (key === '0') {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    key={item.id + item['state']}
                    checkedChildren="开"
                    unCheckedChildren="关"
                    checked={checked}
                    onChange={() => {
                        if (type == 'recommend') {
                            actionHandle(item.id);
                        }
                    }}
                />
            </div>
        );
    };

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    _renderActionCell = (key, name) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'edit', name)}>编辑</div>
                <div className={styles.item} onClick={() => tableAction(key, 'del')}>删除</div>
            </div>
        );
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    // rowSelection={this._rowSelection}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    actionHandle: PropTypes.func,
    actionClickHandle: PropTypes.func
};

export default AdvertTable;