/**
 * 主播管理
 */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const Mode = {
    TVANCHOR_INFO: 'TVANCHOR_INFO',
    TVANCHOR_RANDP: 'TVANCHOR_RANDP',
    TVANCHOR_TICKET: 'TVANCHOR_TICKET',
    TVANCHOR_CHARM: 'TVANCHOR_CHARM',
    TVANCHOR_PROHIBIT: 'TVANCHOR_PROHIBIT'
};

class TVAnchor extends React.Component {

    state = {
        mode: ''
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    _updateContent = (id, mode) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case Mode.TVANCHOR_INFO: // 会员信息
                addOneBreadcrumbPath({
                    title: '主播信息',
                    path: '/home/usermanage/tvanchor/info/' + id
                });
                history.push({
                    pathname: '/home/usermanage/tvanchor/info/' + id
                });
                break;
            case Mode.TVANCHOR_RANDP: // 收支明细
                addOneBreadcrumbPath({
                    title: '收支明细',
                    path: '/home/usermanage/tvanchor/randp/' + id
                });
                history.push({
                    pathname: '/home/usermanage/tvanchor/randp/' + id
                });
                break;
            case Mode.TVANCHOR_TICKET: // 卡券管理
                addOneBreadcrumbPath({
                    title: '卡券管理',
                    path: '/home/usermanage/tvanchor/ticket/' + id
                });
                history.push({
                    pathname: '/home/usermanage/tvanchor/ticket/' + id
                });
                break;
            case Mode.TVANCHOR_PROHIBIT: // 封禁、解封
                addOneBreadcrumbPath({
                    title: '封禁',
                    path: '/home/usermanage/tvanchor/prohibit/' + id
                });
                history.push({
                    pathname: '/home/usermanage/tvanchor/prohibit/' + id
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

TVAnchor.propTypes = {
    history: PropTypes.object, // 路由history
    match: PropTypes.object, // 路由match
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func // 保持两级面包屑导航
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(TVAnchor);