/**
 * 【意见反馈页】
 */
import React from 'react';
import OrderSearch from './components/OrderSearch';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import DealModal from './modal/DealModal';
import DetailModal from './modal/DetailModal';
import http from '@/assets/api/http';
import { message } from 'antd';
import OnlinePlayModal from './modal/OnlinePlayModal';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getSuggestionsList } from '@/reducers/customermanage/suggestions/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {

    state = {
        param: {},
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getSuggestionsList({});
    }
    searchParam = (param) => {
        this.setState({ param }, () => {
            this._searchHandle('useCache');
        });
    }
    _refresh = () => {
        this._searchHandle('useCache');
    }
    _searchHandle = (useCache) => {
        const { param, pageNum, pageSize } = this.state;
        this.searchCache = {
            ...param,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getSuggestionsList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageSize,
            pageNum,
        });
        const { param } = this.state;
        this.searchCache = {
            ...param,
            pageNum,
            pageSize,
        };
        this.props.getSuggestionsList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 表格操作
    _tableAction = (record, type) => {
        switch (type) {
            case 'deal':
                this.setState({ currentOpenMode: 'deal', openModeParam: record.id });
                break;
            case 'detail':
                this.setState({ currentOpenMode: 'detail', openModeParam: { id: record.id, appType: record.appType } });
                break;
            case 'play':
                this.setState({ currentOpenMode: 'play', openModeParam: record.videoUrl, urlParam: record.videoUrl });
                break;
        }
    }
    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'deal':
                return <DealModal title='处理意见' data={{ id: this.state.openModeParam }} refresh={this._refresh} visible={true} closeMode={this._closeMode} />;
            case 'detail':
                return <DetailModal title='意见反馈详情' refresh={this._refresh} data={this.state.openModeParam} visible={true} closeMode={this._closeMode} />;
            case 'play':
                return <OnlinePlayModal visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }
    render() {
        const { suggestionsList } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <OrderSearch searchParam={this.searchParam} />
                    <OrderTable renderData={suggestionsList} paginationChange={this._paginationChange} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getSuggestionsList: PropTypes.func,
    suggestionsList: PropTypes.object,
};
const mapStateToProps = (state) => ({
    suggestionsList: state.Suggestions.suggestionsList,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getSuggestionsList })(Main);
