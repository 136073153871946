/**
 * 合同协议查看
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import styles from './PDF.module.scss';

export default class PDF extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            title: this.props.title,
            confirmLoading: false,
        };
    }

    static propTypes = {
        visible: PropTypes.bool.isRequired, // 是否显示弹框
        closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
        data: PropTypes.object.isRequired,//数据
        title: PropTypes.string,
    };

    //确定取消
    _handleOk = () => {
        this.setState({ visible: false });
        this.props.closeMode(true);
    }
    _handleCancel = () => {
        this.setState({ visible: false, });
        this.props.closeMode(false);
    }


    render() {
        const { data } = this.props;
        const { visible, confirmLoading, title } = this.state;
        return (
            <Modal
                width={800}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'>
                <div className={styles.container}>
                    <object data={data.contractContent} type='application/pdf' width='100%' height='100%'>
                        <iframe src={data.contractContent} width='100%' height='100%' style={{ border: 'none' }}>
                            This browser does not support PDFs. Please download the PDF to view it: <a href={data.contractContent}>Download PDF</a>
                        </iframe>
                    </object>
                </div>
            </Modal>
        );
    }
}