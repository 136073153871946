import React from 'react';
import styles from './LeturesCreate.module.scss';
import PropTypes from 'prop-types';
import { Table, Tooltip } from 'antd';
import { XPagination, XTableSelectionCount, } from '@/components/xqxc_ui';
import { message, } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const columns = () => ([
    {
        title: '真实姓名',
        dataIndex: 'realName',
        key: 'realName',
        width: '33.3%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '主播昵称',
        dataIndex: 'nickname',
        key: 'nickname',
        width: '33.3%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '主播账号',
        dataIndex: 'tel',
        key: 'tel',
        align: 'center',
        width: '33.3%',
        onCell: tooltipStyle,
        render: tooltip
    },
]);


class Create extends React.Component {

    state = {
        selectedRowKeys: [],//对应的key数据
        selectedRows: [],//数据
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data;
    }
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [], selectedRows: [] }, () => {
            const { rowKeys } = this.props;
            const { selectedRowKeys } = this.state;
            selectedRowKeys.length === 0 ? rowKeys([]) : rowKeys(selectedRowKeys);
        });
    }
    // 表格单选输入
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
        this.props.rowKeys(selectedRowKeys);
    };


    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className={styles.container}>
                <div className={styles.table_box}>
                    <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        rowSelection={rowSelection}
                        rowKey={(record) => record.accountId}
                        columns={columns()}
                        dataSource={this._takeDataSource(dataList)}
                        pagination={false}
                        scroll={{ y: 350 }}
                    />
                </div>
                <div className={styles.pagination_box}>
                    <XPagination
                        resultTotal={resultTotal}
                        pageSize={pageSize}
                        pageNum={pageNum}
                        onChange={paginationChange} />
                </div>
            </div>
        );
    }
}

Create.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    paginationChange: PropTypes.func,
    rowKeys: PropTypes.func,//选中的keys
};

export default Create;