import React, { Component } from 'react';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import TableContent from './components/TableContent';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XOKButton, XCancelButton, XDatePicker, XInput } from '@/components/xqxc_ui';
import model from '@/reducers/storehousefinancialmanage/datacenter/model';
import { SearchBox, SearchItem } from '@/components/Layout';


class TabContent extends Component {

    static propTypes = {
        history: PropTypes.object,
        keepSecondNavBreadcrumb: PropTypes.func,
        getDataFromTab8: PropTypes.func,
        dataFromTab8: PropTypes.object
    }

    static defaultProps = {
        dataFromTab8: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
    }

    state = {
        afterGmtTmStart: undefined,
        afterGmtTmEnd: undefined,
        refundTmStart: undefined,
        refundTmEnd: undefined,
        afterId: undefined,
        orderId: undefined,
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { afterGmtTmStart, afterGmtTmEnd, refundTmStart, refundTmEnd, pageNum, pageSize, afterId, orderId } = this.state;
        const query = {
            afterId,
            orderId,
            afterGmtTmStart,
            afterGmtTmEnd,
            refundTmStart,
            refundTmEnd,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getDataFromTab8(query);
        KeepAlive.saveCache(query);
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ afterGmtTmStart: undefined, afterGmtTmEnd: undefined, refundTmStart: undefined, refundTmEnd: undefined, afterId: undefined, orderId: undefined }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { afterGmtTmStart, afterGmtTmEnd, refundTmStart, refundTmEnd, afterId, orderId } = this.state;
        return (
            <KeepAlive id='dataCenterTab8' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        {/* <SearchItem>
                            <XDatePicker
                                label='售后创建时间'
                                value={afterGmtTmStart}
                                bindThis={this}
                                bindName='afterGmtTmStart'
                                isFormat
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                label=''
                                value={afterGmtTmEnd}
                                bindThis={this}
                                bindName='afterGmtTmEnd'
                                isFormat
                            />
                        </SearchItem> */}
                        <SearchItem>
                            <XDatePicker
                                label='退款时间'
                                value={refundTmStart}
                                bindThis={this}
                                bindName='refundTmStart'
                                isFormat
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                label=''
                                value={refundTmEnd}
                                bindThis={this}
                                bindName='refundTmEnd'
                                isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='售后单号'
                                placeholder='请输入售后单号'
                                bindThis={this}
                                bindName='afterId'
                                value={afterId}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='订单号'
                                placeholder='请输入订单号'
                                bindThis={this}
                                bindName='orderId'
                                value={orderId}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent
                        renderData={this.props.dataFromTab8}
                        paginationChange={this._paginationChange}
                    />
                </div>
            </KeepAlive>
        );
    }
}

export default connect(state => ({ ...state.dataCenter }), { keepSecondNavBreadcrumb, ...model.actions })(TabContent);