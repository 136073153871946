import React, { Component } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';

class HotelTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    _columns = () => [
        {
            title: '商户账号',
            dataIndex: 'loginName',
            key: 'loginName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户号',
            dataIndex: 'merchantNo',
            key: 'merchantNo',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip,
        },
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户地址',
            dataIndex: 'addrDetail',
            key: 'addrDetail',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所属运营中心',
            dataIndex: 'operationCenterName',
            key: 'operationCenterName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所属区域公司',
            dataIndex: 'areaCompanyName',
            key: 'areaCompanyName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所属服务商',
            dataIndex: 'serviceName',
            key: 'serviceName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'merchantStateValue',
            key: 'merchantStateValue',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: cellWidth.big,
            fixed: 'right',
            render: (id, record) => this._tableAction(id, record)
        },
    ];

    // 解析表格数据，使符合columns格式要求
    _takeDataSource = (data = []) => {
        return data.map(item => {
            item.merchantStateValue = item.merchantState && Object.keys(item.merchantState).length > 0 ? item.merchantState.value : '';
            item.merchantStateCode = item.merchantState && Object.keys(item.merchantState).length > 0 ? item.merchantState.code : '';
            return item;
        });
    }

    //表格操作
    _tableAction = (key, item) => {
        const { tableAction } = this.props;
        switch (item.merchantStateCode) {
            case 'NEW':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'MER_DETAIL')}>详情</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'MER_EDITOR')}>编辑</div>
                    </div>
                );
            case 'AUDIT':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'MER_EDITOR')}>编辑</div>
                    </div>
                );
            case 'APPROVED':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'MER_DETAIL')}>详情</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'MER_STOR')}>店铺</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'MEMBER', item.merchantName)}>会员列表</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'EARNING', item.merchantName)}>收益明细</div>
                    </div>
                );
            case 'NOT_APPROVED':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'MER_DETAIL')}>详情</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'MER_EDITOR')}>编辑</div>
                    </div>
                );
            default: return null;
        }
    };

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

HotelTable.propTypes = {
    renderData: PropTypes.object, // 表格数据源
    tableAction: PropTypes.func, // 表格
    paginationChange: PropTypes.func, // 分页
    scrollY: PropTypes.string
};

export default XTableHOC(HotelTable);