export const GET_VIDEO_DATA = 'VIDEOCOMMET/GET_VIDEO_DATA'; // 异步获取'视频分类表格'数据源
export const SET_VIDEO_DATA = 'VIDEOCOMMET/SET_VIDEO_DATA'; // 将'视频表格数据源'存储至store.state中


export const GET_VIDEODETAILS_DATA = 'VIDEOCOMMET/GET_VIDEODETAILS_DATA'; // 异步获取'视频评论详情'数据源
export const SET_VIDEODETAILS_DATA = 'VIDEOCOMMET/SET_VIDEODETAILS_DATA'; // 将'视频评论详情'存储至store.state中


export const GET_VIDEOSELECT_DATA = 'VIDEOCOMMET/GET_VIDEOSELECT_DATA'; // 异步获取'视频评论下拉选项'数据源
export const SET_VIDEOSELECT_DATA = 'VIDEOCOMMET/SET_VIDEOSELECT_DATA'; // 将'视频评论下拉选项'存储至store.state中


export const GET_VIDEOSELECTSOURCE_DATA = 'VIDEOCOMMET/GET_VIDEOSELECTSOURCE_DATA'; // 异步获取'视频评论列表查询'数据源
export const SET_VIDEOSELECTSOURCE_DATA = 'VIDEOCOMMET/SET_VIDEOSELECTSOURCE_DATA'; // 将'视频评论列表查询'存储至store.state中
