/**
 * 水印管理
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Icon, message } from 'antd';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import model from '@/reducers/systemmanage/watermark/model';
import CustomUpload, { generateFileList } from '@/components/NewUpload';
import http from '@/assets/api/http';
import { XTitle } from '@/components/xqxc_ui';


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};
class Detail extends React.Component {
    state = {
        imgs: null,
        count: 1,
    }

    componentDidMount() {
        this.props.getWatermarkInfo({ code: 'WATERMARK' });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { count } = this.state;
        const { watermarkInfo } = nextProps;

        if (count === 1) {
            this.setState({
                imgs: (watermarkInfo && generateFileList(watermarkInfo)) || [],
                count: count + 1,
            });
        }

    }


    // 提交
    _handleSubmit = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            const { imgs } = this.state;
            if (!err) {
                console.log('提交', fieldsValue);
                const param = [{
                    paramCode: 'WATERMARK',
                    paramValue: imgs ? imgs[0].url.toString() : '',
                }];
                http('/admin/param/batchUpdateParam', param, 'POST')
                    .then(() => {
                        message.success('修改成功!');
                        this.props.getWatermarkInfo({ code: 'WATERMARK' });
                    }).catch((reject) => {
                        message.error(reject.message);
                    });
            }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { watermarkInfo } = this.props;


        return (
            <div className={styles.container}>
                <XTitle label='水印管理' />
                <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                    <Form.Item
                        colon={false}
                        htmlFor='img'
                        style={{ textAlign: 'left' }}
                        label={<span>上传水印图片:</span>}
                    >
                        <p style={{ color: '#bfbfbf' }}>主图大小不能超过3MB；格式为PNG；200*200 以上图片上传后，商户商品自带水印功能</p>
                        {getFieldDecorator('img', {
                            initialValue: watermarkInfo,
                            rules: [{ required: true, message: '此项必填' }],
                        })(
                            <CustomUpload
                                accept='.png'
                                fileList={this.state.imgs}
                                listType="picture-card"
                                wallMode="single"
                                qiniuUploadProps={{
                                    uploadType: 'image'
                                }}
                                onFileListChange={(info, customFile) => {
                                    const { imgs } = this.state;
                                    if (customFile && customFile.status === 'success') {
                                        this.props.form.setFieldsValue({ 'img': imgs });
                                    } else {
                                        this.props.form.setFieldsValue({ 'img': [] });
                                    }
                                }}
                                onChange={(info) => {
                                    const imgs = info.fileList.length > 1 ? [info.fileList.pop()] : info.fileList;
                                    this.setState({ imgs });
                                }}
                                beforeUpload={(file, fileList) => {
                                    const isPng = file.type === 'image/png';
                                    // const isJpg = file.type === 'image/jpeg';
                                    // const isGif = file.type === 'image/gif';
                                    if (!isPng) {
                                        message.error('只能上传png格式的图片!');
                                    }
                                    const isLt3M = file.size / 1024 / 1024 < 3;
                                    if (!isLt3M) {
                                        message.error('图片必须小于 3MB!');
                                    }
                                    return isPng && isLt3M;
                                }}
                            >
                                <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                            </CustomUpload>
                        )}

                    </Form.Item>
                    <div className={styles.line}></div>
                    <Button style={{ width: '120px', marginTop: 10, display: 'inline-block' }} type="primary" htmlType="submit">立即提交</Button>
                </Form>
            </div>
        );
    }
}

Detail.propTypes = {
    form: PropTypes.object.isRequired,
    history: PropTypes.object,
    match: PropTypes.object,
    getWatermarkInfo: PropTypes.func,
    watermarkInfo: PropTypes.string
};
const mapStateToProps = (state) => ({
    watermarkInfo: state.watermark.getWatermarkInfo || ''
});
const ForgotProducDetails = Form.create({ name: 'create-form' })(Detail);// 校验
export default connect(mapStateToProps, { ...model.actions })(ForgotProducDetails);