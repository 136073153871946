/**
 *  任务管理 - 任务列表
 * */ 
import React from 'react';
import PropTypes from 'prop-types'; 
import Main from './pages/main';

class TaskLost extends React.Component {

    render(){
        return(
            <Main /> 
        );
    }
}

TaskLost.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
};

export default TaskLost;