/**
 * 【日结账单】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, XInput, XDatePicker } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getMerchantSelectSource } from '@/reducers/storehousefinancialmanage/merchantreconciliation/actions';
import enumModel from '@/reducers/storehousefinancialmanage/reconciliationdata/model';
import AdvertTable from './components/AdvertTable';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import model from '@/reducers/storehousefinancialmanage/transactionreconciliation/model';

const { getPaymentAccessTypeEnum } = enumModel.actions;
class Main extends React.Component {

    state = {
        merchant: {},
        paymentAccessType: {},
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getMerchantSelectSource();
        this.props.getPaymentAccessTypeEnum();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { beginDate, endDate, id, pageNum, pageSize, merchant, paymentAccessType } = this.state;
        const query = {
            beginDate,
            endDate,
            id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            merchantId: merchant.merchantId,
            channelType: paymentAccessType.code
        };
        this.props.getTransactionTableData(query);
        KeepAlive.saveCache({ ...query, merchant, paymentAccessType }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ beginDate: undefined, endDate: undefined, id: '', pageNum: 1, pageSize: 10, merchant: {}, paymentAccessType: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    _tableAction = (id, type, item) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '对账详情',
                    path: {
                        pathname: `/home/storehousefinancialmanage/dailystatement/detail/${item.id}/${item.merchantId}/${item.gmtAccounting}/${item.channelType.code}`,
                    }
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/dailystatement/detail/${item.id}/${item.merchantId}/${item.gmtAccounting}/${item.channelType.code}`,
                });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { id, beginDate, endDate, merchant = {}, paymentAccessType = {} } = this.state;
        const { merchantSelectSource, transactionTableData, paymentAccessTypeEnum } = this.props;
        return (
            <KeepAlive id='transaction' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='对账单号'
                                placeholder='请输入'
                                value={id}
                                bindThis={this}
                                bindName='id'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户'
                                showSearch
                                placeholder='请选择商户'
                                renderData={merchantSelectSource}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                value={merchant.merchantName}
                                bindThis={this}
                                bindName='merchant'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='支付通道'
                                showSearch
                                placeholder='请选择支付通道'
                                renderData={paymentAccessTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={paymentAccessType.value}
                                bindThis={this}
                                bindName='paymentAccessType'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                placeholder='请选择开始时间'
                                label='对账日期'
                                value={beginDate}
                                bindThis={this}
                                bindName='beginDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                placeholder='请选择结束时间'
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <AdvertTable renderData={transactionTableData} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getMerchantSelectSource: PropTypes.func,
    merchantSelectSource: PropTypes.array,
    getTransactionTableData: PropTypes.func,
    transactionTableData: PropTypes.object,
    getPaymentAccessTypeEnum: PropTypes.func,
    paymentAccessTypeEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
    merchantSelectSource: state.merchantreconciliation.merchantSelectSource,
    transactionTableData: state.transactionreconciliation.transactionTableData,
    paymentAccessTypeEnum: state.reconciliationdata.paymentAccessTypeEnum,
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath, keepSecondNavBreadcrumb,
    getMerchantSelectSource,
    ...model.actions,
    getPaymentAccessTypeEnum
})(Main);