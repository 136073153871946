import { createModel } from '@/store/tools';

const dataSource = {
    dataList: [{
        childCategory: []
    }],
    pageNum: 1,
    pageSize: 20,
    resultTotal: 0
};

const model = {
    namespace: 'goodsvirtualclassify',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getVirtualClassifis': T('getVirtualClassifis'),
        },
        actions: {
            'getVirtualClassifis': A('getVirtualClassifis'),
        },
        sagas: {
            'getVirtualClassifis': S('getVirtualClassifis', '/goods/category/tree'),
        },
        reducers: {
            'virtualClassifis': R('getVirtualClassifis', dataSource),
        }
    })
};
export default createModel(model);