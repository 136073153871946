/**
 *  贡献值设置 
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { message, Typography } from 'antd';
import http from '@/assets/api/http';
import { XInputNum, XOKButton } from '@/components/xqxc_ui';
import ButtonPerssion from '@/routes/ButtonPerssion';
import model from '@/reducers/systemmanage/servicesetting/model';
import { FloatConversion } from '@/util/conversion';

const { Text } = Typography;

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmit: false,//防抖 
            consumptionPrice: 1,//消费赠送贡献值比例-价格
            consumptionBean: 0,//消费赠送贡献值比例-个数
            recommendBean: 0,//推荐用户贡献值
            recommendUser: 0,//推荐用户
            hostBean: 0,//推荐主播贡献值
            recommendvip: 0,//推荐Vip

            //推荐服务商
            recommendserviceOne: 0,
            recommendserviceTwo: 0,
            recommendserviceThree: 0,
            //推荐服务商H5
            recommendservicehfOne: 0,
            recommendservicehfTwo: 0,

            systemaway: 0,//系统后台创建服务商是否赠送
            exchangeStart: 0,//兑换股票起始贡献值
            stockDelivery: 0,//兑换股票交割周期

            //技术服务费
            serviceFeeOne: undefined,
            serviceFeeTwo: undefined,

            //推荐用户成为销售员
            recShow: 0,

        };

        this.floatConversion = new FloatConversion();
    }

    componentDidMount() {
        this.props.getserviceLevelCode();
        this.props.getBeanInfo();
    }

    componentDidUpdate(prevProps) {
        if (this.props.beanInfo.length !== prevProps.beanInfo.length) {
            this.props.beanInfo.map((v) => {
                const { gbParamCode: { code } = {}, gbParamValue } = v;
                switch (code) {
                    case 'CONSUMPTION_XIUBEI':
                        this.setState({ consumptionPrice: this.floatConversion.divide(gbParamValue, 100) });
                        break;
                    case 'CONSUMPTION_GIVING_XIUBEI':
                        this.setState({ consumptionBean: gbParamValue });
                        break;
                    case 'RECOMMEND_ACCOUNT_XIUBEI':
                        this.setState({ recommendBean: gbParamValue });
                        break;
                    case 'RECOMMEND_ACCOUNT_LIMIT_XIUBEI':
                        this.setState({ recommendUser: gbParamValue });
                        break;
                    case 'RECOMMEND_ANCHOR_XIUBEI':
                        this.setState({ hostBean: gbParamValue });
                        break;
                    case 'RECOMMEND_ACCOUNT_VIP_XIUBEI':
                        this.setState({ recommendvip: gbParamValue });
                        break;
                    case 'RECOMMEND_SERVICE_XIUBEI':
                        this.setState({ recommendservice: gbParamValue });
                        break;
                    case 'H5_OPEN_SERVICE_XIUBEI':
                        this.setState({ recommendservicehf: gbParamValue });
                        break;
                    case 'SYSTEM_OPEN_SERVICE_XIUBEI':
                        this.setState({ systemaway: gbParamValue });
                        break;
                    case 'STOCK_EXCHANGE_XIUBEI_START':
                        this.setState({ exchangeStart: gbParamValue });
                        break;
                    case 'STOCK_EXCHANGE_DELIVERY_DAYS':
                        this.setState({ stockDelivery: gbParamValue });
                        break;
                    case 'SJ0001':
                        this.setState({ serviceFeeTwo: this.floatConversion.divide(Number(gbParamValue), 100) });
                        break;
                    case 'SJ0002':
                        this.setState({ serviceFeeOne: this.floatConversion.divide(Number(gbParamValue), 100) });
                        break;
                    case 'RECOMMEND_SERVICE_XIUBEI_SJ0001':
                        this.setState({ recommendserviceTwo: gbParamValue });
                        break;
                    case 'RECOMMEND_SERVICE_XIUBEI_SJ0002':
                        this.setState({ recommendserviceOne: gbParamValue });
                        break;
                    case 'RECOMMEND_SERVICE_XIUBEI_SJ0003':
                        this.setState({ recommendserviceThree: gbParamValue });
                        break;
                    case 'H5_OPEN_SERVICE_XIUBEI_SJ0001':
                        this.setState({ recommendservicehfTwo: gbParamValue });
                        break;
                    case 'H5_OPEN_SERVICE_XIUBEI_SJ0002':
                        this.setState({ recommendservicehfOne: gbParamValue });
                        break;
                    case 'RECOMMEND_SALESMAN':
                        this.setState({ recShow: gbParamValue });
                        break;
                    default: return null;
                }
            });
        }
    }

    componentWillUnmount() {
        this.props.getCleanBeanInfo();//清除能量值设置默认数据
    }

    //保存
    _saveHandle = () => {
        const { consumptionPrice, consumptionBean, recommendBean, recommendUser, hostBean, recommendvip, systemaway,
            exchangeStart, stockDelivery, recommendserviceOne, recommendserviceTwo, recommendservicehfOne, recommendservicehfTwo, recommendserviceThree,
            recShow } = this.state;
        let submit = [
            { gbParamCode: 'CONSUMPTION_XIUBEI', gbParamValue: this.floatConversion.multiply(consumptionPrice, 100) },
            { gbParamCode: 'CONSUMPTION_GIVING_XIUBEI', gbParamValue: consumptionBean },
            { gbParamCode: 'RECOMMEND_ACCOUNT_XIUBEI', gbParamValue: recommendBean },
            { gbParamCode: 'RECOMMEND_ACCOUNT_LIMIT_XIUBEI', gbParamValue: recommendUser },
            { gbParamCode: 'RECOMMEND_ANCHOR_XIUBEI', gbParamValue: hostBean },
            { gbParamCode: 'RECOMMEND_ACCOUNT_VIP_XIUBEI', gbParamValue: recommendvip },
            { gbParamCode: 'SYSTEM_OPEN_SERVICE_XIUBEI', gbParamValue: systemaway },
            { gbParamCode: 'STOCK_EXCHANGE_XIUBEI_START', gbParamValue: exchangeStart },
            { gbParamCode: 'STOCK_EXCHANGE_DELIVERY_DAYS', gbParamValue: stockDelivery },
            { gbParamCode: 'RECOMMEND_SERVICE_XIUBEI_SJ0001', gbParamValue: recommendserviceTwo },
            { gbParamCode: 'RECOMMEND_SERVICE_XIUBEI_SJ0002', gbParamValue: recommendserviceOne },
            { gbParamCode: 'RECOMMEND_SERVICE_XIUBEI_SJ0003', gbParamValue: recommendserviceThree },
            { gbParamCode: 'H5_OPEN_SERVICE_XIUBEI_SJ0001', gbParamValue: recommendservicehfTwo },
            { gbParamCode: 'H5_OPEN_SERVICE_XIUBEI_SJ0002', gbParamValue: recommendservicehfOne },
            { gbParamCode: 'RECOMMEND_SALESMAN', gbParamValue: recShow },

        ];
        this.setState({ isSubmit: true }, () => {
            http('/gatherbeans/saveUpdatesList', {
                gatherBeansList: submit
            }, 'POST').then(() => {
                message.success('保存成功');
                this.setState({ isSubmit: false });
            }).catch((e = {}) => {
                message.error(e.message);
                this.setState({ isSubmit: false });
            });
        });
    }

    render() {
        const { isSubmit, consumptionPrice, consumptionBean, recommendBean, recommendUser, hostBean, recommendvip, recommendserviceOne,
            recommendserviceTwo, recommendservicehfOne, recommendservicehfTwo, systemaway, exchangeStart, stockDelivery, serviceFeeOne, serviceFeeTwo, recommendserviceThree,
            recShow } = this.state;

        return (
            <div className={`${styles.flexBoxContainer} ${styles.servergrowth}`}>
                <div style={{ overflow: 'auto', height: '100%' }}>
                    <div className={styles.top_input}>
                        <div className={`${styles.layout} ${styles.item}`}>
                            <span>消费赠送贡献值比例：每消费</span>
                            <XInputNum
                                style={{ marginRight: '8px' }}
                                inputStyle={{ width: '100px', }}
                                placeholder='请输入'
                                value={consumptionPrice === null ? 1 : consumptionPrice}
                                bindThis={this}
                                bindName='consumptionPrice'
                                precision={0}
                                min={1}
                                max={99999}
                            />
                            <span>元，赠送</span>
                            <XInputNum
                                style={{ marginRight: '8px' }}
                                inputStyle={{ width: '100px', }}
                                placeholder='请输入'
                                value={consumptionBean === null ? 0 : consumptionBean}
                                bindThis={this}
                                bindName='consumptionBean'
                                precision={0}
                                min={0}
                                max={99999}
                            />
                            <span>个贡献值，取整数值，<Text type='danger'>税费和</Text>邮费不计算在内</span>
                        </div>
                    </div>
                    <div className={styles.top_input}>
                        <div className={`${styles.layout} ${styles.item}`}>
                            <span>推荐用户：每推荐一名用户赠送</span>
                            <XInputNum
                                style={{ marginRight: '8px' }}
                                inputStyle={{ width: '100px', }}
                                placeholder='请输入'
                                value={recommendBean === null ? 0 : recommendBean}
                                bindThis={this}
                                bindName='recommendBean'
                                precision={0}
                                min={0}
                                max={99999}
                            />
                            <span>个贡献值，每天推荐上限</span>
                            <XInputNum
                                style={{ marginRight: '8px' }}
                                inputStyle={{ width: '100px', }}
                                placeholder='请输入'
                                value={recommendUser === null ? 0 : recommendUser}
                                bindThis={this}
                                bindName='recommendUser'
                                precision={0}
                                min={0}
                                max={99999}
                            />
                            <span>名用户，<Text type='danger'>超出后不再赠送</Text></span>
                        </div>
                    </div>
                    <div className={styles.top_input}>
                        <div className={`${styles.layout} ${styles.item}`}>
                            <span>推荐主播：每推荐一名用户成为主播赠送</span>
                            <XInputNum
                                style={{ marginRight: '8px' }}
                                inputStyle={{ width: '100px', }}
                                placeholder='请输入'
                                value={hostBean === null ? 0 : hostBean}
                                bindThis={this}
                                bindName='hostBean'
                                precision={0}
                                min={0}
                                max={99999}
                            />
                            <span>个贡献值</span>
                        </div>
                    </div>
                    <div className={styles.top_input}>
                        <div className={`${styles.layout} ${styles.item}`}>
                            <span>推荐Vip：每推荐一名用户成为Vip赠送</span>
                            <XInputNum
                                style={{ marginRight: '8px' }}
                                inputStyle={{ width: '100px', }}
                                placeholder='请输入'
                                value={recommendvip === null ? 0 : recommendvip}
                                bindThis={this}
                                bindName='recommendvip'
                                precision={0}
                                min={0}
                                max={99999}
                            />
                            <span>个贡献值</span>
                        </div>
                    </div>
                    <div className={styles.stock_input}>
                        <span><Text type='danger'>服务商推荐服务商：</Text></span>
                        <div className={styles.top_input}>
                            <div className={`${styles.layout} ${styles.item}`}>
                                <span className={styles.stock_span}><Text type='danger'>开通技术服务编号SJ0001服务商，推荐人赠送</Text></span>
                                <XInputNum
                                    style={{ marginRight: '8px' }}
                                    inputStyle={{ width: '100px', }}
                                    placeholder='请输入'
                                    value={recommendserviceTwo === null ? 0 : recommendserviceTwo}
                                    bindThis={this}
                                    bindName='recommendserviceTwo'
                                    precision={0}
                                    min={0}
                                    max={99999}
                                />
                                <span className={styles.stock_span}><Text>个贡献值</Text></span>
                            </div>
                        </div>
                        <div className={styles.top_input}>
                            <div className={`${styles.layout} ${styles.item}`}>
                                <span className={styles.stock_span}><Text type='danger'>开通技术服务编号SJ0002服务商，推荐人赠送</Text></span>
                                <XInputNum
                                    style={{ marginRight: '8px' }}
                                    inputStyle={{ width: '100px', }}
                                    placeholder='请输入'
                                    value={recommendserviceOne === null ? 0 : recommendserviceOne}
                                    bindThis={this}
                                    bindName='recommendserviceOne'
                                    precision={0}
                                    min={0}
                                    max={99999}
                                />
                                <span className={styles.stock_span}><Text>个贡献值</Text></span>
                            </div>
                        </div>
                        <div className={styles.top_input}>
                            <div className={`${styles.layout} ${styles.item}`}>
                                <span className={styles.stock_span}><Text type='danger'>开通技术服务编号SJ0003每日说客，推荐人赠送</Text></span>
                                <XInputNum
                                    style={{ marginRight: '8px' }}
                                    inputStyle={{ width: '100px', }}
                                    placeholder='请输入'
                                    value={recommendserviceThree === null ? 0 : recommendserviceThree}
                                    bindThis={this}
                                    bindName='recommendserviceThree'
                                    precision={0}
                                    min={0}
                                    max={99999}
                                />
                                <span className={styles.stock_span}><Text>个贡献值</Text></span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.stock_input}>
                        <span><Text type='danger'>H5开通成为服务商：</Text></span>
                        <div className={styles.top_input}>
                            <div className={`${styles.layout} ${styles.item}`}>
                                <span className={styles.stock_span}><Text type='danger'>开通技术服务编号SJ0001服务商，开通人赠送</Text></span>
                                <XInputNum
                                    style={{ marginRight: '8px' }}
                                    inputStyle={{ width: '100px', }}
                                    placeholder='请输入'
                                    value={recommendservicehfTwo === null ? 0 : recommendservicehfTwo}
                                    bindThis={this}
                                    bindName='recommendservicehfTwo'
                                    precision={0}
                                    min={0}
                                    max={99999}
                                />
                                <span className={styles.stock_span}><Text>个贡献值</Text></span>
                            </div>
                        </div>
                        <div className={styles.top_input}>
                            <div className={`${styles.layout} ${styles.item}`}>
                                <span className={styles.stock_span}><Text type='danger'>开通技术服务编号SJ0002服务商，开通人赠送</Text></span>
                                <XInputNum
                                    style={{ marginRight: '8px' }}
                                    inputStyle={{ width: '100px', }}
                                    placeholder='请输入'
                                    value={recommendservicehfOne === null ? 0 : recommendservicehfOne}
                                    bindThis={this}
                                    bindName='recommendservicehfOne'
                                    precision={0}
                                    min={0}
                                    max={99999}
                                />
                                <span className={styles.stock_span}><Text>个贡献值</Text></span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.top_input}>
                        <div className={`${styles.layout} ${styles.item}`}>
                            <span>系统后台创建服务商赠送</span>
                            <XInputNum
                                style={{ marginRight: '8px' }}
                                inputStyle={{ width: '100px', }}
                                placeholder='请输入'
                                value={systemaway === null ? 0 : systemaway}
                                bindThis={this}
                                bindName='systemaway'
                                precision={0}
                                min={0}
                                max={99999}
                            />
                            <span>个贡献值</span>
                        </div>
                    </div>
                    <div className={styles.top_input}>
                        <div className={`${styles.layout} ${styles.item}`}>
                            <span>推荐小店：每推荐一名用户成为销售员赠送</span>
                            <XInputNum
                                style={{ marginRight: '8px' }}
                                inputStyle={{ width: '100px', }}
                                placeholder='请输入'
                                value={recShow === null ? 0 : recShow}
                                bindThis={this}
                                bindName='recShow'
                                precision={0}
                                min={0}
                                max={99999}
                            />
                            <span>个贡献值</span>
                        </div>
                    </div>
                    <div className={styles.stockXiubei}>
                        {/* <div className={styles.top_input}>
                            <div className={`${styles.layout} ${styles.item}`}>
                                <span>兑换股票起始贡献值</span>
                                <XInputNum
                                    style={{ marginRight: '8px' }}
                                    inputStyle={{ width: '100px', }}
                                    placeholder='请输入'
                                    value={exchangeStart === null ? 0 : exchangeStart}
                                    bindThis={this}
                                    bindName='exchangeStart'
                                    precision={0}
                                    min={0}
                                    max={99999}
                                />
                                <span>个贡献值</span>
                            </div>
                        </div> */}
                        <div className={styles.top_input}>
                            <div className={`${styles.layout} ${styles.item}`}>
                                <span>兑换股票交割周期</span>
                                <XInputNum
                                    style={{ marginRight: '8px' }}
                                    inputStyle={{ width: '100px', }}
                                    placeholder='请输入'
                                    value={stockDelivery === null ? 0 : stockDelivery}
                                    bindThis={this}
                                    bindName='stockDelivery'
                                    precision={0}
                                    min={0}
                                    max={99999}
                                />
                                <span>天</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.layout}>
                    <ButtonPerssion code='systemmanage/servicesetting/xbbc'>
                        <XOKButton style={{ width: '90px' }} loading={isSubmit} label='保存' onClick={this._saveHandle} />
                    </ButtonPerssion>
                </div>
            </div>
        );
    }
}


Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    match: PropTypes.object, // 路由match对象
    history: PropTypes.object, // 路由history

    getLevelCode: PropTypes.array,
    getserviceLevelCode: PropTypes.func,//服务商等级action
    beanInfo: PropTypes.array,
    getBeanInfo: PropTypes.func,//获取能量值设置默认数据action
    getCleanBeanInfo: PropTypes.func,//清除能量值设置默认数据action
};

const mapStateToProps = (state) => ({
    getLevelCode: state.servicesetting.getLevelCode,
    beanInfo: state.servicesetting.beanInfo,
});

export default connect(mapStateToProps, { ...model.actions })(Main);