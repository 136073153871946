import React, { Component } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './ShowTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';
import { isEmpty } from '@/assets/js/utils';

export default class RecordsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    _columns = () => [
        {
            title: '手机号',
            dataIndex: 'telephone',
            key: 'telephone',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '服务商',
            dataIndex: 'serviceProviderName',
            key: 'serviceProviderName',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '贡献值奖励(元)',
            dataIndex: 'xiubeiRewardExchange',
            key: 'xiubeiRewardExchange',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'stateValue',
            key: 'stateValue',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '摘要',
            dataIndex: 'description',
            key: 'description',
            width: 160,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '发放时间',
            dataIndex: 'rewardTime',
            key: 'rewardTime',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '领取时间',
            dataIndex: 'drawTime',
            key: 'drawTime',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },

        {
            title: '贡献值名称',
            dataIndex: 'xiubeiName',
            key: 'xiubeiName',
            width: 160,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '周期时间',
            dataIndex: 'c9',
            key: 'c9',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, record) => (this.nowrapDate(record.periodStartTime, record.periodEndTime))
        },
        {
            title: '奖励类型',
            dataIndex: 'xiubeiRewardValue',
            key: 'xiubeiRewardValue',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '流水号',
            dataIndex: 'id',
            key: 'id',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '贡献值副标题',
            dataIndex: 'xiubeiSubtitle',
            key: 'xiubeiSubtitle',
            width: 160,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    // 解析表格数据，使符合columns格式要求
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                item.xiubeiRewardExchange = regFenToYuan(item.xiubeiReward);
                item.xiubeiRewardValue = item.xiubeiRewardType && Object.keys(item.xiubeiRewardType).length > 0 ? item.xiubeiRewardType.value : '';
                item.stateValue = item.state && Object.keys(item.state).length > 0 ? item.state.value : '';
                return item;
            });
        }
    }

    //换行时间展示
    nowrapDate = (start, end) => {
        return <div className={styles.dates}>
            <span>{isEmpty(start) ? '-' : start}</span><br />
            <span>{isEmpty(end) ? '-' : end}</span>
        </div>;
    };

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

RecordsTable.propTypes = {
    renderData: PropTypes.object, // 表格数据源
    paginationChange: PropTypes.func, // 分页
};