import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class TableContent extends Component {
	state = {
		selectedRowKeys: [],
	};

	_columns = () => [
		{
			title: '流水号',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '商户名称',
			dataIndex: 'merchantName',
			key: 'merchantName',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '店铺名称',
			dataIndex: 'shopName',
			key: 'shopName',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '行为',
			dataIndex: 'violationMode',
			key: 'violationMode',
			align: 'center',
			width: '15%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '平台意见',
			dataIndex: 'violationReason',
			key: 'violationReason',
			align: 'center',
			width: '20%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '状态',
			dataIndex: 'violationState',
			key: 'violationState',
			align: 'center',
			width: '10%',
			render: (text) => (this._statusCell(text))
		},
		{
			title: '新建时间',
			dataIndex: 'gmtModified',
			key: 'gmtModified',
			align: 'center',
			width: '15%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'keyId',
			key: 'keyId',
			align: 'center',
			width: '10%',
			render: (text, record) => this._tableAction(text, record)
		},
	];

	_statusCell = (text) => {
		return tooltip(text ? text.value : '');
	}

	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
				<div className={styles.item} onClick={() => tableAction(key, 'audit', record)}>审核</div>
			</div>
		);
	};

	_tableData = (data) => {
		return data;
	}

	render() {
		const { renderData, paginationChange } = this.props;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey={(record, index) => index}
					columns={this._columns()}
					pagination={false}
					dataSource={this._tableData(renderData.dataList)}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}


TableContent.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
};

export default TableContent;