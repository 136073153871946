import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { message } from 'antd';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import TableData from './components/TableData';
import CreateEdit from '../../modal/CreateEdit';
import { getProductLabel } from '@/reducers/productmanage/labellist/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

const Mode = {
    CREAT: 'CREAT',
    EDIT: 'EDIT',
};
class ProductLabellist extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
        labelName: '',
        labelItem: {}
    }
    // 查询
    _searchHandle = (useCache) => {
        const { labelName, pageNum, pageSize } = this.state;
        this.searchCache = {
            tagName: labelName,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getProductLabel(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ labelName: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //创建标签
    _openCreateModal = () => {
        this.setState({ currentOpenMode: Mode.CREAT });
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'edit':
                http('/goods/tag/infoTag', { id }, 'POST')
                    .then((resole) => {
                        this.setState({ currentOpenMode: Mode.EDIT, labelItem: resole.result });
                    }).catch(() => { message.error('请求失败！'); });
                break;
            case 'delete':
                showConfirm('您确定要删除该标签？', '', () => {
                    http('/goods/tag/delTag', { ids: [id] }, 'POST')
                        .then(() => {
                            message.success('删除成功。');
                            this._searchHandle('useCache');
                        }).catch(() => { message.error('删除失败！'); });
                });
                break;
            case 'sort':
                alert(params.sortNo);
                break;
        }
    }
    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { labelItem, currentOpenMode } = this.state;
        switch (currentOpenMode) {
            case Mode.CREAT:
                return <CreateEdit title='创建商品标签' visible={true}
                    data={{
                        action: 'create',
                        url: '/goods/tag/addTag',
                        labelItem: {}
                    }}
                    closeMode={this._closeMode} />;
            case Mode.EDIT:
                return <CreateEdit title='编辑商品标签' visible={true}
                    data={{ action: 'edit', url: '/goods/tag/editTag', labelItem }}
                    closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', labelItem: {} }, () => {
            if (update) this._searchHandle('useCache');
        });
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { productLabel } = this.props;
        const { labelName } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商品标签'
                                placeholder='请输入商品标签'
                                bindThis={this}
                                bindName='labelName'
                                value={labelName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '90px' }} label='创建标签' onClick={this._openCreateModal} />
                    </div>
                    <TableData paginationChange={this._paginationChange} renderData={productLabel} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }

}

ProductLabellist.propTypes = {
    getProductLabel: PropTypes.func,
    productLabel: PropTypes.object,
};

const mapStateToProps = (state) => ({
    productLabel: state.productLabel.productLabel,
});

export default connect(mapStateToProps, { getProductLabel })(ProductLabellist);