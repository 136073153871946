/**
 * 商品品牌下拉搜索框（枚举类型）
 */
import React from 'react';
import PropTypes from 'prop-types';
import { XSelect } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

class GoodsBrand extends React.Component {

    state = {
        renderData: [], // 数据源
    }

    componentDidMount() {
        http('/goods/brand/listBrandItem', { isApply: this.props.isApply }, 'POST').then((response) => {
            if (response.status == 200) {
                this.setState({ renderData: response.result });
            } else {
                console.log('/goods/brand/listBrandItem请求失败');
            }
        }).catch(() => {
            console.log('/goods/brand/listBrandItem请求失败');
        });
    }

    _onChange = (value) => {
        const { bindThis, bindName } = this.props;

        if (bindThis && bindName) {
            bindThis.setState({ [bindName]: value });
        }

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const { renderData } = this.state;
        const { style, selectStyle, label, labelStyle, placeholder, isRequired, value } = this.props;
        let name = '';
        for (let i = 0; i < renderData.length; i++) {
            if (renderData[i].id == value.id) {
                name = value.brandName;
                break;
            }
        }

        return (
            <XSelect
                style={style}
                selectStyle={selectStyle}
                label={label}
                labelStyle={labelStyle}
                placeholder={placeholder}
                renderData={renderData}
                dataIndex='brandName'
                keyIndex='id'
                value={name}
                onChange={this._onChange}
                isRequired={isRequired}
                showSearch={true}
            />
        );
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }
}

GoodsBrand.defaultProps = {
    style: { width: '218px' },
    selectStyle: { width: '150px' },
};

GoodsBrand.propTypes = {
    bindThis: PropTypes.object, // 父级this
    bindName: PropTypes.string, // 双向绑定的属性名称
    value: PropTypes.object,
    style: PropTypes.object,
    selectStyle: PropTypes.object,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    defaultValue: PropTypes.object,
    isApply: PropTypes.number
};

export default GoodsBrand;