import * as Order from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

//订单列表数据
export const starOrderList = (state = initData, action) => {
    switch (action.type) {
        case Order.SET_STARORDER_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
//订单失败列表数据
export const failStarOrderList = (state = initData, action) => {
    switch (action.type) {
        case Order.SET_FAILORDER_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
// 预警用户
export const riskUserList = (state = initData, action) => {
    switch (action.type) {
        case Order.SET_RISKUSER_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
// 预警等级
export const riskLevelList = (state = [], action) => {
    switch (action.type) {
        case Order.SET_RISKLEVEL_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
// 支付方式
export const payWaysList = (state = {}, action) => {
    switch (action.type) {
        case Order.SET_PAY_WAYS:
            return action.payload.result;
        default:
            return state;
    }
};
// 异常类型
export const riskTypeList = (state = [], action) => {
    switch (action.type) {
        case Order.SET_RISK_TYPE:
            return action.payload.result;
        default:
            return state;
    }
};
// 状态
export const handleStatusList = (state = [], action) => {
    switch (action.type) {
        case Order.SET_STATUS_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
// 充值来源
export const deviceTypeList = (state = [], action) => {
    switch (action.type) {
        case Order.SET_DEVTYPE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};