import React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { XPagination, XTableSelectionCount, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip, priceFenToYuanCell, cellWidth } from '@/components/TableCell';
import styles from './TableContent.module.scss';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
    };

    _takeColumns = () => ([
        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户',
            dataIndex: 'merchantName',
            key: 'merchantName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员手机号',
            dataIndex: 'account',
            key: 'account',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单金额(元)',
            dataIndex: 'orderAmount',
            key: 'orderAmount',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '人气值',
            dataIndex: 'starValue',
            key: 'starValue',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '税费金额(元)',
            dataIndex: 'taxation',
            key: 'taxation',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '打款状态',
            dataIndex: 'payStateValue',
            key: 'payStateValue',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '打款说明',
            dataIndex: 'payDescribe',
            key: 'payDescribe',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '自动打款规则',
            dataIndex: 'freeOrderRuleName',
            key: 'freeOrderRuleName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '免单金额(元)',
            dataIndex: 'freeOrderAmount',
            key: 'freeOrderAmount',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '免单时间',
            dataIndex: 'freeOrderTime',
            key: 'freeOrderTime',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付方式',
            dataIndex: 'paymentMode',
            key: 'paymentMode',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            width: cellWidth.normal,
            fixed: 'right',
            render: (text, item) => this._renderActionCell(text, item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _renderImgCell = (url) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.imgbox}>
                    <img className={styles.img} src={url} />
                </div>
            </div>
        );
    };
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderNumberCell = (text) => {
        const price = parseInt(text) / 100;
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {price}
            </div>
        );
    };

    _renderActionCell = (key, record) => {
        const { tableAction, payId } = this.props;
        return (
            <div className={styles.action}>
                {record.payState !== 'PAID_FREE' ? 
                <div className={payId === record.id ? styles.disabled: styles.item}  onClick={() => tableAction(key, 'pay', record)}>打款</div> :
                <div className={styles.item} onClick={() => tableAction(key, 'payDetail', record)}>打款详情</div>
            }
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    payId: PropTypes.string,
    scrollY: PropTypes.string
};
export default XTableHOC(TableContent);