import * as T from './actiontypes';

// 获取活动商品列表
export const getActiveGoods = (value) => ({
    type: T.GET_ACTIVE_GOODS,
    payload: value
});

// 获取活动商品详情
export const getProductDetail = (value) => ({
    type: T.GET_GOOD_DETAIL,
    payload: value
});

// 参与活动的商户下拉列
export const getMerchants = (value = {}) => ({
    type: T.GET_MERCHANTS,
    payload: value
});