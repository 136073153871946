/**
 * 查看绑定商品
 */
import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import styles from './AddProduct.module.scss';
import SelectedProductTable from '../pages/graphicscreate/components/SelectedProductTable';

class SelectedProductModal extends React.Component {

    state = {
        visible: this.props.visible,
        goodsList: []
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading } = this.state;
        const { data = [] } = this.props;

        return (
            <Modal
                width={860}
                centered
                title='关联商品'
                visible={visible}
                confirmLoading={confirmLoading}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                onCancel={this._handleCancel}
                footer={null}
            >
                <div className={styles.container}>
                    <SelectedProductTable tableAction={this._tableAction} renderData={data} />
                </div>
            </Modal>
        );
    }
}

SelectedProductModal.propTypes = {
    data: PropTypes.array,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default SelectedProductModal;