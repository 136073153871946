//批量审核弹窗
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './BatchAudit.module.scss';
import http from '@/assets/api/http';
import { Modal, Input, Radio, message } from 'antd';

const { TextArea } = Input;

const BatchAudit = (props) => {

    const [visible, setVisible] = useState(props.visible);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [auditState, setAuditState] = useState('PASS');
    const [opinion, setOpinion] = useState('');

    const _handleOk = () => {
        setConfirmLoading(true);
        let list = [];
        for (let i = 0; i < props.batch.length; i++) {
            list.push({
                id: props.batch[i].id,
                activityId: props.batch[i].activityId,
                activityGoodsId: props.batch[i].activityGoodsId,
                goodsId: props.batch[i].goodsId,
                shopId: props.batch[i].shopId,
            });
            if (props.batch[i].state != 'PENDING') {
                message.warning('您所勾选的商品状态必须为待审核状态');
                setConfirmLoading(false);
                return;
            }
        }
        http('/goodsAudit/batchAudit', { list, state: auditState, opinion }, 'POST')
            .then(() => {
                message.success('批量审核操作成功');
                setVisible(false);
                setConfirmLoading(false);
                props.closeMode(true);
            })
            .catch(e => {
                message.error(e.message);
                setConfirmLoading(false);
            });
    };

    const _handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false);
        props.closeMode();
    };

    return < Modal
        // {...props}
        width={500}
        centered
        title='活动商品批量审核'
        visible={visible}
        confirmLoading={confirmLoading}
        onOk={_handleOk}
        onCancel={_handleCancel}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div className={styles.container}>
            <div className={styles.layout}>
                <label className={styles.label}>审核意见</label>
                <Radio.Group onChange={(e) => setAuditState(e.target.value)} value={auditState}>
                    <Radio value='PASS'>审核通过</Radio>
                    <Radio value='FAIL'>审核不通过</Radio>
                </Radio.Group>
            </div>
            <div className={styles.layout}>
                <TextArea
                    placeholder="请输入审核意见"
                    maxLength={200}
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    onChange={(e) => setOpinion(e.target.value)}
                    value={opinion}
                />
            </div>
        </div>
    </Modal>;
};

BatchAudit.propTypes = {
    batch: PropTypes.array,//弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default BatchAudit;