/**
 *  技术服务费收入明细
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './main';

const TechServiceFee = ({ history }) => {
    return <Main history={history} />;
};

TechServiceFee.propTypes = {
    history: PropTypes.object
};

export default TechServiceFee;