/**
 * 商品列表-商品费率审核
 */
import React from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, XInput } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { GoodsClassify } from '@/vcomps';
import * as actions from '@/reducers/auditmanage/product/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class ProductRate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '',
            goodsName: '',
            username: {},
            goodsNo: '',
            status: {},
            professionType: {},
            label: {},
            categoryId: '', // 商品联级分类
            batchData: [],
            categorySubTypeItem: {}
        };
        this.cleanRef = null;
    }

    static propTypes = {
        updateContent: PropTypes.func,
        getProductAuditRateList: PropTypes.func,
        getProductStatus: PropTypes.func,
        getProductBusinessType: PropTypes.func,
        getProductUsername: PropTypes.func,
        getProductLabelSele: PropTypes.func,
        productAuditRateList: PropTypes.object,
        productStatus: PropTypes.array,
        productBusinessType: PropTypes.array,
        productUsername: PropTypes.array,
        productLabelSele: PropTypes.array
    };

    componentDidMount() {
        this.props.getProductStatus();
        this.props.getProductBusinessType();
        this.props.getProductUsername();
        this.props.getProductLabelSele();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { goodsName, goodsNo, username, professionType, categoryId, pageNum, pageSize, status, label, categorySubTypeItem } = this.state;
        const tagIds = label.id ? [label.id] : [];
        this.searchCache = {
            goodsName, goodsNo, goodsState: status.code, goodsType: professionType.code,
            merchantId: username.merchantId, categoryId, tagIds,
            categorySubType: categorySubTypeItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getProductAuditRateList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ goodsName: '', goodsNo: '', username: {}, professionType: {}, categoryId: '', label: {}, status: {}, categorySubTypeItem: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        this.cleanRef.clean();
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'detail':
                this.props.updateContent('RATE_DETAIL', id);
                break;
            case 'audit':
                this.props.updateContent('RATE_AUDIT', id);
                break;
        }
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    _batchBan = () => {
        // const { goodsIds } = this.state;
        // if(goodsIds.length===0){
        //     message.error('禁用商品不能为空');
        //     return;
        // }
        // http('/goods/goods/disableGoods',{goodsIds},'POST').then(()=>{
        //     message.success('禁用成功');
        //     this.setState({ goodsIds:[] });
        //     const { goodsName,goodsNo,goodsType,merchantId,categoryId,pageNum,pageSize,status} =this.state;
        //     this.props.getProductAuditRateList({ goodsName,goodsNo,goodsState:status.id,goodsType,merchantId,categoryId,pageNum,pageSize});
        // }).catch(()=>{
        //     message.error('禁用失败');
        // });
    }

    render() {
        const { isShowMore, goodsName, goodsNo, status, professionType, username, label, categorySubTypeItem } = this.state;
        const { productAuditRateList, productStatus, productBusinessType, productUsername, productLabelSele } = this.props;
        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商品名称'
                                placeholder='请输入商品名称'
                                bindThis={this}
                                bindName='goodsName'
                                value={goodsName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户'
                                placeholder='请选择商户'
                                renderData={productUsername}
                                bindThis={this}
                                bindName='username'
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                value={username.merchantName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='商品货号'
                                placeholder='请输入商品货号'
                                bindThis={this}
                                bindName='goodsNo'
                                value={goodsNo}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='业务类型'
                                placeholder='请选择业务类型'
                                renderData={productBusinessType}
                                bindThis={this}
                                bindName='professionType'
                                dataIndex='value'
                                keyIndex='code'
                                value={professionType.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={productStatus}
                                bindThis={this}
                                bindName='status'
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商品标签'
                                placeholder='请选择商品标签'
                                renderData={productLabelSele}
                                bindThis={this}
                                bindName='label'
                                dataIndex='tagName'
                                keyIndex='id'
                                value={label.tagName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商品分类'
                                style={{ marginRight: '10px' }}
                                placeholder='请选择分类类型'
                                renderData={[
                                    { code: 'COMMON', value: '通用分类' },
                                    { code: 'ONLINE', value: '线上分类' },
                                    { code: 'CITY', value: '同城分类' }
                                ]}
                                bindThis={this}
                                bindName='categorySubTypeItem'
                                dataIndex='value'
                                keyIndex='code'
                                value={categorySubTypeItem.value}
                            />
                            <GoodsClassify
                                style={{ marginRight: '20px' }}
                                ref={ele => this.cleanRef = ele}
                                categorySubType={categorySubTypeItem.code}
                                onClassifyChange={value => this.setState({ categoryId: value })}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    {/* <div className={styles.operate_box}>
                    <XOKButton style={{ width: '60px' }} label='导出' onClick={this._export} />
                    <XOKButton style={{width:'120px', marginLeft:'30px'}} label='批量禁用' onClick={this._batchBan} />
                </div> */}
                    <TableContent paginationChange={this._paginationChange} renderData={productAuditRateList} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}
const mapStateToProps = (state) => ({
    productAuditRateList: state.productAudit.productAuditRateList,
    productStatus: state.productAudit.productStatus,
    productBusinessType: state.productAudit.productBusinessType,
    productUsername: state.productAudit.productUsername.merchantIdAndNames || [],
    productLabelSele: state.productAudit.productLabelSele.dataList || [],
});
export default connect(mapStateToProps, actions)(ProductRate);