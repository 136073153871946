import { createModel } from '@/store/tools';

const model = {
    namespace: 'stockright',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getStockRightList': T('getStockRightList'),
            'getStockRightInfo': T('getStockRightInfo'),
            'getStateEnum': T('getStateEnum'),
        },
        actions: {
            'getStockRightList': A('getStockRightList'),
            'getStockRightInfo': A('getStockRightInfo'),
            'getStateEnum': A('getStateEnum'),
        },
        sagas: {
            'getStockRightList': S('getStockRightList', '/stakeAgreement/stakeAgreementList'),
            'getStockRightInfo': S('getStockRightInfo', '/stakeAgreement/stakeAgreementInfo'),
            'getStateEnum': S('getStateEnum', '/enum/StakeAgreementStatusEnum'),
        },
        reducers: {
            'getStockRightList': R('getStockRightList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getStockRightInfo': R('getStockRightInfo', { auditStatus: {} }),
            'getStateEnum': R('getStateEnum', []),
        }
    })
};
export default createModel(model);