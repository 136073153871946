/**
 * 人气值攻略分类
 */
import React from 'react';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm, } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import GiftModal from '../../modal/GiftModal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message } from 'antd';
import { getclassificationList, getclassificationState, getstrategyType } from '@/reducers/xqstrategy/classification/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

const Mode = {
    EDITE: 'EDITE',//编辑
    DELETE: 'DELETE', // 删除
    NEWCREATE: 'NEWCREATE',//新建分类
};

class TionGift extends React.Component {

    state = {
        type: {},//分类状态
        typeName: '',//分类名称
        strategystate: {},//攻略类型
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
        belongSystem: 'DAILY_TALK'
    }


    componentDidMount() {
        const { getclassificationState, getstrategyType } = this.props;
        getclassificationState();//分类状态
        getstrategyType();//攻略类型
    }

    // 分类状态下拉列改变时触发
    _typeChangeHandle = (selectedItem) => {
        this.setState({ type: selectedItem });

    }

    // 分类名称输出框改变时触发
    _typeNameInputChangeHandle = (value) => {
        this.setState({ typeName: value });
    }

    // 创建分类
    _createStudioRoom = () => {
        this.setState({
            currentOpenMode: Mode.NEWCREATE,
        });
    }
    // 查询触发
    _searchHandle = (useCache) => {
        const { type, typeName, pageNum, pageSize, strategystate, belongSystem } = this.state;
        this.searchCache = {
            className: typeName,//分类名称  
            state: type.code,//分类状态  
            strategyType: strategystate.code,//攻略类型
            belongSystem,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getclassificationList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ type: {}, typeName: '', strategystate: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getclassificationList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (item, typeTable, value) => {
        const { getclassificationList, } = this.props;
        switch (typeTable) {
            case 'edit':
                this.setState({
                    currentOpenMode: Mode.EDITE,
                    openModeParam: value,
                });
                break;
            case 'delete':
                showConfirm('您是否要删除该分类', '', () => {
                    http(`/show/strategyClass/deleteStrategyClass/${item}`, {}, 'POST').then(() => {
                        message.success('删除成功');
                        getclassificationList(this.searchCache);//查询方法
                    }).catch((res) => {
                        if (res.code == '500') {
                            message.warn(res.message);
                        }
                    });
                }, () => { });
                break;
            case 'isReco':
                http('/show/strategyClass/updateStrategyClassReco', {
                    id: item.id,
                    isReco: (value === false) ? 0 : 1,
                }, 'POST').then(() => {
                    message.success('推荐修改成功');
                    getclassificationList(this.searchCache);//查询方法
                }).catch((reject = {}) => {
                    message.error(reject.message);
                });
                break;
            case 'state':
                http('/show/strategyClass/updateStrategyClassState', {
                    id: item.id,
                    state: (value === false) ? 0 : 1,
                }, 'POST').then(() => {
                    message.success('状态修改成功');
                    getclassificationList(this.searchCache);//查询方法
                }).catch((reject = {}) => {
                    message.error(reject.message);
                });
                break;
            case 'isCustom':
                http('/show/strategyClass/updateStrategyClassIsCustom', {
                    id: item.id,
                    isCustom: (value === false) ? 0 : 1,
                }, 'POST').then(() => {
                    message.success('自定义修改成功');
                    getclassificationList(this.searchCache);//查询方法
                }).catch((reject = {}) => {
                    message.error(reject.message);
                });
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.NEWCREATE:
                return <GiftModal title='新建分类' visible={true} closeMode={this._closeMode} />;
            case Mode.EDITE:
                return <GiftModal title='编辑分类' data={this.state.openModeParam} visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        const { getclassificationList } = this.props;
        if (status) {
            getclassificationList(this.searchCache);//查询方法
        }
    }

    render() {
        const { type, typeName, strategystate } = this.state;
        const { tableSource, paginations, selectData, strategyTypeSelect } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='分类名称'
                                    placeholder='请输入分类名称'
                                    onChange={this._typeNameInputChangeHandle}
                                    value={typeName}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='分类状态'
                                    placeholder='请选择分类状态'
                                    renderData={selectData}
                                    onChange={this._typeChangeHandle}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={type.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='攻略类型'
                                    placeholder='请选择攻略类型'
                                    renderData={strategyTypeSelect.filter(i => i.code !== 'HELP_CENTE')}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={strategystate.value}
                                    bindThis={this}
                                    bindName='strategystate'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: 'auto' }} label='新建分类' onClick={this._createStudioRoom} />
                    </div>
                    <TableContent _paginationChange={this._paginationChange} renderData={tableSource} tableAction={this._tableAction} pagiNation={paginations} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

TionGift.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 路由跳转
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getclassificationState: PropTypes.func,// 通过Saga异步请求下拉选项数据方法Action
    tableSource: PropTypes.array, // 表格数据源
    selectData: PropTypes.array, // 分类状态下拉选项
    paginations: PropTypes.object, // 分页数据
    getclassificationList: PropTypes.func, // 通过Saga异步请求表格数据源的Action

    getstrategyType: PropTypes.func,//攻略类型
    strategyTypeSelect: PropTypes.array
};

const mapStateToProps = (state) => {
    return {
        tableSource: state.classification.classificationDate.tableSource,
        selectData: state.classification.classificationDate.selectData,
        paginations: state.classification.classificationDate.paginations,
        strategyTypeSelect: state.classification.getstrategyType,
    };
};

export default connect(mapStateToProps, { getclassificationList, getclassificationState, getstrategyType })(TionGift);
