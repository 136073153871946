/**
 * 代言任务
 */
import React from 'react';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton, XInput } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import PopconfirmModal from '../../modal/PopconfirmModal';
import ActivityDate from '../../modal/ActivityDate';
import DeletefirmModal from '../../modal/DeletefirmModal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import model from '@/reducers/marketingmanage/advocacytask/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { message } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';

class Marketing extends React.Component {

    state = {
        type: {},//代言类型
        belonging: {},//任务归属
        task: '',//代言任务

        currentOpenMode: '', // 当前需要打开的弹窗signTime
        openModeParam: {}, // 当前需要打开的弹窗的入参
        popconParam: {},//启用停用入参

        tabs: [
            { id: 1, label: '全部代言' },
            { id: 5, label: '未开始' },
            { id: 2, label: '进行中' },
            { id: 3, label: '已结束' },
            { id: 4, label: '禁用' },
        ],
        curt: 1,//tab默认
    }


    componentDidMount() {
        const { getAdvocacyType, getAdvocacyState } = this.props;
        getAdvocacyType();
        getAdvocacyState();
    }

    // 导航切换回调
    _anchorClickHandle = (id) => {
        this.setState({ type: {}, belonging: {}, task: '', curt: id, }, () => {
            this._searchHandle();// 重新渲染更新列表
        });
    }

    // 导航切换
    _anchorMoveHandle = () => {
        const { curt } = this.state;
        return (
            this.state.tabs.map((item, index) => {
                if (index == 0) {
                    return (
                        <span key={item.id} onClick={() => this._anchorClickHandle(item.id)} className={curt == item.id ? styles.curt : ''}>{item.label}</span>
                    );
                } else {
                    return (
                        <span key={item.id} onClick={() => this._anchorClickHandle(item.id)} className={curt == item.id ? styles.curt : ''}>{item.label}</span>
                    );
                }
            })
        );
    }
    // 新建攻略
    _createStudioRoom = () => {
        this.props.updateContent('CREAT');
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { task, type, belonging, pageSize, pageNum, curt, } = this.state;
        let curType;//活动区分 全部、结束，未开始等
        if (curt == '1') {
            curType = '*';
        } else if (curt == '2') {
            curType = 'WORK';
        } else if (curt == '3') {
            curType = 'ENDED';
        } else if (curt == '4') {
            curType = 'DISABLED';
        } else if (curt == '5') {
            curType = 'NOT_STARTED';
        }
        this.searchCache = {
            taskName: task,//代言任务  
            taskType: type.code,//任务类型(直播、视频、图文)	  
            host: belonging.code,//任务归属 
            spokesmanTaskStatus: curType,//区分 全部、结束，未开始等
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAdvocacyTableList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ type: {}, belonging: {}, task: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getAdvocacyTableList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'detail':
                this.props.updateContent('DETAIL', id);
                break;
            case 'stateType':// 开启-停用
                this.setState({
                    currentOpenMode: 'stateType',
                    popconParam: value,
                });
                break;
            case 'edit'://编辑
                this.props.updateContent('EDIT', id);
                break;
            case 'getDetail'://数据统计
                {
                    const { taskName, ...restStat } = value;
                    http('/spokesmantask/config/getUserSpokesmanTaskStatistics', { id }, 'POST')
                        .then((res={}) => {
                            this.setState({
                                currentOpenMode: 'data',
                                openModeParam: { ...res.result, taskName, id }
                            });
                        }).catch((reject = {}) => {
                            message.error(reject.message);
                        });
                }
                break;
            case 'sort'://tabs进行中是排序
                http('/spokesmantask/config/sortingSetting', {
                    id,
                    sortNoNew: value,
                }, 'POST').then(() => {
                    message.success('排序成功');
                    this.props.getAdvocacyTableList(this.searchCache);// 重新渲染更新列表
                }).catch((reject = {}) => {
                    message.error(reject.message || '排序异常');
                });
                break;
            case 'delete'://删除
                this.setState({
                    currentOpenMode: 'DELETE',
                    openModeParam: { id }
                });
                break;
        }
    }

    // 打开弹窗  
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'DELETE':
                return <DeletefirmModal visible={true} closeMode={this._closeMode} data={this.state.openModeParam} />;
            case 'stateType':
                return <PopconfirmModal data={this.state.popconParam} visible={true} closeMode={this._closeMode} />;
            case 'data':
                return <ActivityDate title={this.state.openModeParam.taskName} visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getAdvocacyTableList(this.searchCache);
        }
    }

    render() {
        const { type, task, belonging, curt } = this.state;
        const { advocacyList, advocacyType, advocacyState } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={`${styles.top_title} ${styles.page}`}>
                        {this._anchorMoveHandle()}
                    </div>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='代言任务'
                                placeholder='请输入'
                                value={task}
                                bindThis={this}
                                bindName='task'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='代言类型'
                                placeholder='请选择'
                                renderData={advocacyType}
                                dataIndex='name'
                                keyIndex='code'
                                value={type.name}
                                bindThis={this}
                                bindName='type'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='任务归属'
                                placeholder='请选择'
                                renderData={advocacyState}
                                dataIndex='value'
                                keyIndex='code'
                                value={belonging.value}
                                bindThis={this}
                                bindName='belonging'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: 'auto' }} label='新建代言' onClick={this._createStudioRoom} />
                    </div>
                    <TableContent renderData={advocacyList} tabType={curt} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Marketing.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 路由跳转

    getAdvocacyTableList: PropTypes.func,//列表数据(action)
    advocacyList: PropTypes.object,
    advocacyType: PropTypes.array,
    getAdvocacyType: PropTypes.func,//代言类型数据(action)
    advocacyState: PropTypes.array,
    getAdvocacyState: PropTypes.func,//代言任务归属数据(action)
};

const mapStateToProps = (state) => {
    return {
        advocacyList: state.advocacytask.advocacyTableList, // 列表数据
        advocacyType: state.advocacytask.advocacyType, // 代言类型数据
        advocacyState: state.advocacytask.advocacyState, // 代言任务归属
    };
};

export default connect(mapStateToProps, { ...model.actions })(Marketing);
