import React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { XPagination } from '@/components/xqxc_ui';
import { Table, Switch } from 'antd';
import PropTypes from 'prop-types';
import styles from './AdvertTable.module.scss';

class AdvertTable extends React.Component {

    _takeColumns = () => ([
        {
            title: '广告位编码',
            dataIndex: 'code',
            key: 'code',
            width: '15%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '广告位名称',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '描述',
            dataIndex: 'description',
            key: 'description',
            width: '30%',
            align: 'center',
            render: (text) => {
                return  <Ellipsis tooltip={text} lines={1}>{text}</Ellipsis>;
            }
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: '15%',
            align: 'center',
            render: (text, item) => (this._switchCell(text, item, 'recommend'))
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            width: '15%',
            render: (text, item) => this._renderActionCell(item.id)
        }
    ]);
    //表格项内容为开关
    _switchCell = (key, item, type) => {
        const { actionHandle } = this.props;
        let checked = true;
        if (key == 0) {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    key={item.id}
                    checkedChildren="开"
                    unCheckedChildren="关"
                    checked={checked}
                    onChange={() => {
                        if (type == 'recommend') {
                            actionHandle({ id: item.id });
                        }
                    }}
                />
            </div>
        );
    };

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    _renderActionCell = (key) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>
                <div className={styles.item} onClick={() => tableAction(key, 'del')}>删除</div>  
            </div>
        );
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    // rowSelection={this._rowSelection}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    actionHandle: PropTypes.func,
};

export default AdvertTable;