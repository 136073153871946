/**
 * 企业主播
 */
import React from 'react';
import PropTypes from 'prop-types'; 
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';


class TVAnchor extends React.Component {
    
    state = { 
    }

    componentDidMount(){
        this.props.keepSecondNavBreadcrumb();
    }
    
    render(){
        return(
            <Main updateContent={this._updateContent} history={this.props.history} /> 
        );
    }
}

TVAnchor.propTypes = {
    history: PropTypes.object, // 路由history
    match: PropTypes.object, // 路由match
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func // 保持两级面包屑导航
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(TVAnchor);