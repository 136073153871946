/**
 * 营销活动成本分析
 */
import React, { useEffect, useRef, useState } from 'react';
import { Tag, message, Select } from 'antd';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XDatePicker, XOKButton, XCancelButton, XSelect, XInput } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import TableContent from './components/TableContent';
import model from '@/reducers/statisticsinfo/allStatisticsinfo/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { hmsDay } from '../../../util/timeSwitch';

const Option = Select.Option;

const ActivityTypeStatistics = ({
  getDateFormatEnum,
  dateFormatEnum,
  getSelectActivityType,
  selectActivityType,
  getStatisOrderActivityType,
  statisOrderActivityType
}) => {

  const [aliveInit, setAliveInit] = useState(false);
  const [cacheData, setCacheData] = useState({});
  const [startDate, setStartDate] = useState();
  const [resetCode, setResetCode] = useState(0); // 重置
  const [endDate, setEndDate] = useState();
  const [reportForm, setReportForm] = useState({});
  const [activeType, setActiveType] = useState({});

  const page = useRef({ pageNum: 1, pageSize: 30 });

  useEffect(() => {
    getDateFormatEnum();
    getSelectActivityType();
  }, []);

  useEffect(() => {
    aliveInit && searchHandle('useCache');
  }, [aliveInit]);

  // 重置后请求
  useEffect(() => {
    if (resetCode > 0) {
      searchHandle();
    }
  }, [resetCode]);

  const searchHandle = (useCache) => {
    let { pageNum, pageSize } = page.current;
    let query = {
      dateFrom: startDate,
      dateTo: endDate,
      activityType:activeType.key,
      dateFormat: reportForm.code,
      pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
      pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
    };
    getStatisOrderActivityType(query);
    KeepAlive.saveCache({ ...cacheData,activeType, reportForm, startDate, endDate, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
  };

  const paginationChange = (pageSize, pageNum) => {
    page.current = { pageSize, pageNum };
    searchHandle('useCache');
  };

  const resetHandle = () => {
    setCacheData({});
    setReportForm({code: 'DAY', value: '日期呈现'});
    setStartDate(hmsDay('',30));
    setEndDate(hmsDay());
    setActiveType({});
    setResetCode(resetCode + 1);
    page.current = { pageNum: autoPageNum, pageSize: autopageSize };
  };

  const initData = (data) => {
    setCacheData({ ...data });
    setReportForm(data.reportForm || {code: 'DAY', value: '日期呈现'});
    setStartDate(data.startDate || hmsDay('',30));
    setEndDate(data.endDate ||  hmsDay());
    setActiveType(data.activeType || {});
    page.current = { pageNum: data.pageNum, pageSize: data.pageSize };
  };

  return (
    <KeepAlive render={(state) => {
      if (!aliveInit) {
        initData(state);
        setAliveInit(true);
      }
    }}>
      <div className={styles.flexBoxContainer}>
        <SearchBox>
          <SearchItem>
            <XSelect
              label='活动类型：'
              placeholder='请选择'
              renderData={selectActivityType}
              dataIndex='value'
              keyIndex='key'
              value={activeType.value}
              bindThis={setActiveType}
              bindName='reporactiveTypetForm'
              style={{ margin: '0 20px' }}
            />
          </SearchItem>
          <SearchItem className={styles.dateItem}>
            <XDatePicker
              label='选择日期：'
              placeholder='请选择开始日期'
              value={startDate}
              bindThis={setStartDate}
              bindName='startDate'
              isFormat={true}
              showTime={false}
              defaultTime='00:00:00'
            />
            <XDatePicker
              label=''
              placeholder='请选择结束日期'
              value={endDate}
              bindThis={setEndDate}
              bindName='endDate'
              isFormat={true}
              showTime={false}
              defaultTime='23:59:59'
            />
          </SearchItem>
          <SearchItem>
            <XSelect
              label='报表形式：'
              placeholder='请选择'
              renderData={dateFormatEnum}
              dataIndex='value'
              keyIndex='code'
              value={reportForm.value}
              bindThis={setReportForm}
              bindName='reportForm'
              style={{ margin: '0 20px' }}
            />
          </SearchItem>
          <SearchItem>
            <XOKButton
              label='查询'
              onClick={searchHandle}
            />
            <XCancelButton
              label='重置'
              onClick={resetHandle}
            />
          </SearchItem>
        </SearchBox>
        <TableContent renderData={statisOrderActivityType} paginationChange={paginationChange} />
      </div>
    </KeepAlive>
  );
};

ActivityTypeStatistics.propTypes = {
  history: PropTypes.object,
  keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
  getDateFormatEnum: PropTypes.func,
  dateFormatEnum: PropTypes.array,
  getSelectActivityType: PropTypes.func,
  selectActivityType: PropTypes.array,
  getStatisOrderActivityType: PropTypes.func,
  statisOrderActivityType: PropTypes.object,
};
const mapStateToProps = (state) => ({
  dateFormatEnum: state.allStatisticsinfo.dateFormatEnum,
  selectActivityType: state.allStatisticsinfo.selectActivityType,
  statisOrderActivityType: state.allStatisticsinfo.statisOrderActivityType,
});


export default connect(mapStateToProps, { ...model.actions, keepSecondNavBreadcrumb })(ActivityTypeStatistics);