/**
 * 区域公司统计
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import TableContent from './components/TableContent';
import model from '@/reducers/statistical/companycanvas/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { isPlat } from '@/assets/js/authType';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends React.Component {

    state = {
        operation: {},
        company: {},
    };

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getOperationSelect(); // 获取运营中心下拉列
        this.props.getCompanySelect(); // 获取区域公司下拉列
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, operation, company } = this.state;
        let query = {};
        query = {
            operationsCenterId: operation.id,
            areaCompanyId: company.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getReport(query);
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ operation: {}, company: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格操作
    _tableAction = (type, record) => {
        const { history } = this.props;
        const { companyId } = record;
        switch (type) {
            case 'sale':
                history.push({
                    pathname: '/home/statistical/companycanvas/income/' + companyId,
                });
                break;
            case 'detail':
                history.push({
                    pathname: '/home/operationcenter/companyList/detail/' + companyId,
                });
                break;
        }
    }

    render() {
        const { operation, company } = this.state;
        const { report, operationSelect, companySelect } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            {isPlat() ? <XSelect
                                label='归属运营中心'
                                placeholder='请选择归属运营中心'
                                renderData={operationSelect}
                                dataIndex='companyName'
                                keyIndex='id'
                                value={operation.companyName}
                                bindThis={this}
                                bindName='operation'
                                showSearch={true}
                            /> : null}
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='归属区域公司'
                                placeholder='请选择归属区域公司'
                                renderData={companySelect}
                                dataIndex='companyName'
                                keyIndex='id'
                                value={company.companyName}
                                bindThis={this}
                                bindName='company'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent renderData={report} paginationChange={this._paginationChange} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getReport: PropTypes.func,
    report: PropTypes.object,
    getOperationSelect: PropTypes.func,
    operationSelect: PropTypes.array,
    getCompanySelect: PropTypes.func,
    companySelect: PropTypes.array,
};
const mapStateToProps = (state) => ({
    report: state.companycanvas.getReport,
    operationSelect: state.companycanvas.getOperationSelect,
    companySelect: state.companycanvas.getCompanySelect,
});
export default connect(mapStateToProps, { ...model.actions, keepSecondNavBreadcrumb })(Main);