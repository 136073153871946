import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell } from '@/components/TableCell';
import moment from 'moment';

const TableContent = ({ renderData, paginationChange }) => {

    const columns = () => ([
        // {
        //     title: '序号',
        //     dataIndex: 'index',
        //     key: 'index',
        //     align: 'center',
        //     width: cellWidth.normal,
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId ',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '售后单号',
            dataIndex: 'afterId',
            key: 'afterId ',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        //     title: '会员账号',
        //     dataIndex: 'accountId',
        //     key: 'accountId',
        //     align: 'center',
        //     width: cellWidth.normal,
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        {
            title: '商户名称',
            dataIndex: 'merName',
            key: 'merName',
            align: 'center',
            width: cellWidth.big,
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        //     title: '支付单号',
        //     dataIndex: 'payId',
        //     key: 'payId',
        //     align: 'center',
        //     width: cellWidth.normal,
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        {
            title: '售后单创建时间',
            dataIndex: 'afterGmtTm',
            key: 'afterGmtTm',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: text => tooltip(text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-')
        },
        {
            title: '退款时间',
            dataIndex: 'refundTm',
            key: 'refundTm',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: text => tooltip(text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-')
        },
        {
            title: '订单状态',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        //     title: '人气值',
        //     dataIndex: 'showNum',
        //     key: 'showNum',
        //     align: 'center',
        //     width: cellWidth.normal,
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        // {
        //     title: '售后留言',
        //     dataIndex: 'gmtRegistration',
        //     key: 'gmtRegistration',
        //     align: 'center',
        //     width: cellWidth.normal,
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        {
            title: '货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '销售规格',
            dataIndex: 'skuSpec',
            key: 'skuSpec',
            align: 'center',
            width: cellWidth.big,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
            width: cellWidth.big,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '数量',
            dataIndex: 'goodsNum',
            key: 'goodsNum',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '销售价格',
            dataIndex: 'salePrice',
            key: 'salePrice',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '运费',
            dataIndex: 'freight',
            key: 'freight',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '其它费用',
            dataIndex: 'extraCost',
            key: 'extraCost',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '优惠金额',
            dataIndex: 'discountedPrice',
            key: 'discountedPrice',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '退款金额',
            dataIndex: 'refundAmout',
            key: 'refundAmout',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '商品总金额',
            dataIndex: 'goodsAmout',
            key: 'goodsAmout',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        // {
        //     title: '品牌名称',
        //     dataIndex: 'brandName',
        //     key: 'brandName',
        //     align: 'center',
        //     width: cellWidth.normal,
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        {
            title: '商品分类',
            dataIndex: 'categoryName',
            key: 'categoryName',
            align: 'center',
            width: cellWidth.big,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '分类类型',
            dataIndex: 'categorySubTypeName',
            key: 'categorySubTypeName',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '服务费金额',
            dataIndex: 'serviceFee',
            key: 'serviceFee',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '服务商名单',
            dataIndex: 'serviceName',
            key: 'serviceName',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '服务商管理费',
            dataIndex: 'serviceManagementFee',
            key: 'serviceManagementFee',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '机构名单',
            dataIndex: 'institutionName',
            key: 'institutionName',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '机构管理费',
            dataIndex: 'institutionManagementFee',
            key: 'institutionManagementFee',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
    ]);

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(r, i) => i}
                columns={columns()}
                dataSource={renderData.dataList}
                pagination={false}
                scroll={{ x: 'max-content', y: true }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;