import { createModel } from '@/store/tools';

const model = {
    namespace: 'withdrawRecord',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getWithRecordList': T('getWithRecordList'),
        },
        actions: {
            'getWithRecordList': A('getWithRecordList'),
        },
        sagas: {
            'getWithRecordList': S('getWithRecordList', 'pay/outAccount/youFuPayWithdrawalRecord'),
        },
        reducers: {
            'withRecordList': R('getWithRecordList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);