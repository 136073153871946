import * as T from './actiontypes';
//举报原因列表(saga)

/**
 *  用户/秀场举报列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} reportTypeName 举报类型名称
 */
export const getReportReasonsList = (dataload) => ({
    type: T.GET_REPORTREASONSLIST_DATA,
    payload: {
        ...dataload
    }
});

/**
 *  商品举报列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} reportTypeName 举报类型名称
 */
export const getReportShopList = (dataload) => ({
    type: T.GET_REPORTSHOPLIST_DATA,
    payload: {
        ...dataload
    }
});

