/**
 * 【增值服务订单页】
 */
import React from 'react';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker, XInputNum } from '@/components/xqxc_ui';
import OrderTable from './components/OrderTable';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import { parse } from 'qs';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getPaymentAccessTypeEnum } from '@/reducers/ordermanage/productOrder/actions';
import model from '@/reducers/ordermanage/valueServiceOrder/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { message } from 'antd';
import ButtonPerssion from '@/routes/ButtonPerssion';

class Main extends React.Component {
    state = {
        pageNum: 1,
        pageSize: 20,
        userState: {},
        payType: {},
        payAccessType: {}, // 支付通道
        stateItem: {},
        recommendType: {},
        refereesPhone: null,
        batchData: [],
    }
    // domRef = React.createRef();

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getPayType, getUserType, getOrderStateEnum, getRecommendTypeEnum, location } = this.props;
        const { recommendOnly } = parse(location.search.substr(1));
        this.setState({ recommendOnly });
        keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        getPayType();
        getUserType();
        getOrderStateEnum();
        getRecommendTypeEnum();
        // 支付通道
        this.props.getPaymentAccessTypeEnum();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location } = nextProps;
        const { recommendOnly } = parse(location.search.substr(1));
        if (recommendOnly !== this.state.recommendOnly) {
            this.setState({ recommendOnly }, () => {
                this._searchHandle('useCache');
            });
        }

    }
    UNSAFE_componentWillMount() {
        this.setState({ recommendOnly: undefined });
    }
    // 表格操作
    _tableAction = (key, type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            // 查看详情
            case 'info':
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: {
                        pathname: '/home/ordermanage/valueserviceorder/orderdetail',
                        search: `?orderId=${record.id}&&curTab=1`,
                    }
                });
                history.push({
                    pathname: '/home/ordermanage/valueserviceorder/orderdetail',
                    search: `?orderId=${record.id}&&curTab=1`,
                });
                break;
            case 'batch':
                this.setState({ batchData: record });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }
    // 重置
    _resetHandle = () => {
        this.setState({ payAccessType: {}, payTimeStart: undefined, payTimeEnd: undefined, buyAccount: '', id: '', serviceName: '', recommendLoginName: null, userState: {}, payType: {}, stateItem: {}, recommendType: {}, pageNum: 1, pageSize: 20 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    _searchHandle = (useCache) => {
        const { recommendOnly, payTimeStart, payTimeEnd, id, buyAccount, serviceName, userState, payType, payAccessType, stateItem, recommendLoginName, recommendType, pageNum, pageSize } = this.state;
        this.searchCache = {
            payTimeStart,
            payTimeEnd,
            id,
            buyAccount,
            serviceName,
            recommendLoginName,
            orderStatus: stateItem.code,
            sourceType: userState.code,
            payType: payType.code,
            payAccessType: payAccessType.code,
            recommendType: recommendType.code,
            recommendOnly,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getValueServiceOrderList(this.searchCache);
    }

    //导出操作
    _onExport = () => {
        const { payTimeStart, payTimeEnd, id, buyAccount, serviceName, userState, payType, payAccessType, stateItem, recommendLoginName, batchData, recommendType } = this.state;
        const reqParams = {
            payTimeStart,
            payTimeEnd,
            id,
            buyAccount,
            serviceName,
            recommendLoginName,
            orderStatus: stateItem.code,
            sourceType: userState.code,
            payType: payType.code,
            payAccessType: payAccessType.code,
            recommendType: recommendType.code,
            orderIdList: batchData
        };
        http('/earnings/serviceOrderListExcel', { ...reqParams }, 'POST')
            .then((res) => {
                if (res.code === 200) {
                    window.open(res.result);
                }
            })
            .catch(e => {
                message.error(e.message);
            });
    }

    _disabledStartDate = startValue => {
        const { payTimeEnd } = this.state;
        if (!startValue || !payTimeEnd) {
            return false;
        }
        return startValue.format('YYYY-MM-DD') > payTimeEnd.valueOf();
    };

    _disabledEndDate = endValue => {
        const { payTimeStart } = this.state;
        if (!endValue || !payTimeStart) {
            return false;
        }
        return endValue.format('YYYY-MM-DD') <= payTimeStart.valueOf();
    };

    render() {
        const { payTypeArray, paymentAccessTypeEnum, userTypeArray, ValueServiceOrderList, orderStateEnum, RecommendTypeEnum } = this.props;
        const { payTimeStart, payTimeEnd, id, buyAccount, serviceName, userState, payType, payAccessType, stateItem, recommendLoginName, recommendType } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='会员账号'
                                placeholder='请输入会员账号'
                                value={buyAccount}
                                bindThis={this}
                                bindName='buyAccount'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInputNum
                                label='订单编号'
                                placeholder='请输入订单编号'
                                value={id}
                                bindThis={this}
                                bindName='id'
                                min={0}
                                precision={0}
                                maxLength={16}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='订单状态'
                                placeholder='请选择'
                                renderData={orderStateEnum.filter(item => item.code != 0)}
                                dataIndex='value'
                                keyIndex='code'
                                value={stateItem.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='stateItem'
                            />
                        </SearchItem>
                        {/* <SearchItem>
                                <XSelect
                                    label='用户类型'
                                    placeholder='请选择用户类型'
                                    renderData={userTypeArray || []}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={userState.value}
                                    showSearch={true}
                                    bindThis={this}
                                    bindName='userState'
                                />
                            </SearchItem> */}
                        <SearchItem>
                            <XSelect
                                label='支付通道'
                                placeholder='请选择支付通道'
                                renderData={paymentAccessTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={payAccessType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='payAccessType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='支付方式'
                                placeholder='请选择支付方式'
                                renderData={payTypeArray}
                                dataIndex='value'
                                keyIndex='code'
                                value={payType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='payType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='服务名称'
                                placeholder='请输入服务名称'
                                value={serviceName}
                                bindThis={this}
                                bindName='serviceName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='推广人账号'
                                placeholder='请输入推广人账号'
                                value={recommendLoginName}
                                bindThis={this}
                                bindName='recommendLoginName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='推广来源'
                                placeholder='请选择推广来源'
                                renderData={RecommendTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={recommendType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='recommendType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='付款时间'
                                value={payTimeStart}
                                disabledDate={this._disabledStartDate}
                                onChange={value => this.setState({ payTimeStart: value })}
                                isFormat
                                showTime={false}
                                defaultTime='00:00:00'
                                allowClear={false}
                            />
                            <div className={styles.timeLine} />
                            <XDatePicker
                                disabledDate={this._disabledEndDate}
                                style={{ marginRight: 20 }}
                                label=''
                                value={payTimeEnd}
                                onChange={value => this.setState({ payTimeEnd: value })}
                                isFormat
                                showTime={false}
                                defaultTime='23:59:59'
                                allowClear={false}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <ButtonPerssion code='ordermanage/valueserviceorder/dc'>
                        <div className={styles.row3}>
                            <XOKButton
                                style={{ width: '80px' }}
                                label='导出'
                                onClick={this._onExport}
                            />
                        </div>
                    </ButtonPerssion>
                    <OrderTable renderData={ValueServiceOrderList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getPayType: PropTypes.func,
    getUserType: PropTypes.func,
    payTypeArray: PropTypes.array,
    userTypeArray: PropTypes.array,
    getValueServiceOrderList: PropTypes.func,
    ValueServiceOrderList: PropTypes.object,
    getOrderStateEnum: PropTypes.func,
    orderStateEnum: PropTypes.array,
    getRecommendTypeEnum: PropTypes.func,
    RecommendTypeEnum: PropTypes.array,
    getPaymentAccessTypeEnum: PropTypes.func,
    paymentAccessTypeEnum: PropTypes.array,
};

Main.default = {
    orderStateEnum: []
};

const mapStateToProps = (state) => ({
    userTypeArray: state.valueServiceOrder.userTypeArray,
    payTypeArray: state.valueServiceOrder.payTypeArray,
    ValueServiceOrderList: state.valueServiceOrder.ValueServiceOrderList,
    orderStateEnum: state.valueServiceOrder.orderStateEnum,
    RecommendTypeEnum: state.valueServiceOrder.RecommendTypeEnum,
    paymentAccessTypeEnum: state.productOrder.paymentAccessTypeEnum
});

export default connect(mapStateToProps, { ...model.actions, getPaymentAccessTypeEnum, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);
