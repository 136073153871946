/**
 * 服务商推荐人下拉搜索框（枚举类型）
 */
import React from 'react';
import PropTypes from 'prop-types';
import { XSelect } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { message, Icon } from 'antd';

class Referrer extends React.Component {

    state = {
        renderData: [], // 数据源
        pageNum: 1, // 默认第1页
        pageSize: 50, // 默认每页100条
        keyWord: '', // 用户模糊查询
        loading: false // 加载中
    }

    componentDidMount() {
        this._getHttpList();
    }

    _getHttpList = (type) => {
        const { pageSize, renderData, keyWord } = this.state;

        // 如果是搜索状态，只搜索第一条
        let pageNum = this.state.pageNum;
        if (type == 'SearchType') {
            pageNum = 1;
        }

        this.setState({ loading: true }, () => {
            http('/service/provider/listServiceProvider', { pageNum, pageSize, keyWord }, 'POST').then((response) => {
                if (response.status == 200) {
                    const dataList = [...response.result.dataList].map((item) => {
                        item.combineName = item.companyName + '(' + item.mobilePhone + ')';
                        return item;
                    });
                    const list = this.unique([...renderData, ...dataList]);
                    this.setState({ renderData: list });
                }
            }).catch((e) => {
                message.error(e.message);
            }).finally(() => {
                this.setState({ loading: false });
            });
        });
    }

    // 数组去重
    unique = (list) => {
        let result = {};
        let finalResult = [];
        for (let i = 0; i < list.length; i++) {
            result[list[i].id] = list[i]; //因为songs[i].id不能重复,达到去重效果,且这里必须知晓"id"或是其他键名
        }
        for (let item in result) {
            finalResult.push(result[item]);
        }
        return finalResult;
    }

    _onChange = (value) => {
        const { bindThis, bindName } = this.props;

        if (bindThis && bindName) {
            bindThis.setState({ [bindName]: value });
        }

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    _handleScroll = (e) => {
        e.persist();
        const { target } = e;
        // scrollHeight：代表包括当前不可见部分的元素的高度
        // scrollTop：代表当有滚动条时滚动条向下滚动的距离，也就是元素顶部被遮住的高度
        // clientHeight：包括padding但不包括border、水平滚动条、margin的元素的高度
        const rmHeight = target.scrollHeight - target.scrollTop;
        const clHeight = target.clientHeight;
        // 当下拉框失焦的时候，也就是不下拉的时候

        // 当下拉框下拉并且滚动条到达底部的时候
        // 可以看成是分页，当滚动到底部的时候就翻到下一页
        const { loading, pageNum } = this.state;
        if (rmHeight < clHeight + 5 && !loading) {
            this.setState({ pageNum: pageNum + 1 }, () => {
                this._getHttpList();
            });
        }
    }

    _onSearch = (value) => {
        this.setState({ keyWord: value }, () => {
            this._getHttpList('SearchType');
        });
    }

    render() {
        const { renderData } = this.state;
        const { style, selectStyle, label, placeholder, isRequired, value, ...otherProps } = this.props;
        let name = undefined;
        const { companyName, mobilePhone } = value;

        if (companyName && mobilePhone) {
            name = companyName + '(' + mobilePhone + ')';
        } else if (companyName) {
            name = companyName;
        }

        return (
            <div style={style}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <XSelect
                        style={{ marginRight: '20px' }}
                        {...otherProps}
                        selectStyle={selectStyle}
                        label={label}
                        placeholder={placeholder}
                        renderData={renderData}
                        dataIndex='combineName'
                        keyIndex='id'
                        value={name}
                        onChange={this._onChange}
                        isRequired={isRequired}
                        showSearch={true}
                        onPopupScroll={this._handleScroll}
                        onSearch={this._onSearch}
                        showArrow={false}
                    />
                    <Icon type="close-circle" style={{ marginLeft: '-46px', cursor: 'pointer', zIndex: '100' }} onClick={() => {
                        this._onChange('');
                    }} />
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }
}

Referrer.defaultProps = {
    style: { width: '218px' },
    selectStyle: { width: '150px' },
};

Referrer.propTypes = {
    bindThis: PropTypes.object, // 父级this
    bindName: PropTypes.string, // 双向绑定的属性名称
    value: PropTypes.object,
    style: PropTypes.object,
    selectStyle: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    defaultValue: PropTypes.object,
};

export default Referrer;