import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import Payment from '../payment';//收支明细 
import Billing from '../billing';//结算明细
import Freeze from '../freeze';//冻结明细

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '收支明细' },
            { id: 2, label: '结算明细' },
            { id: 3, label: '冻结明细' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
    }

    componentDidMount() { }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容 
    _renderPage = () => {
        const { curTabID } = this.state;
        switch (curTabID) {
            case 1:
                return <Payment curTabID={this.state.curTabID} history={this.props.history} />;
            case 2:
                return <Billing curTabID={this.state.curTabID} history={this.props.history} />;
            case 3:
                return <Freeze curTabID={this.state.curTabID} history={this.props.history} />;
            default:
                return <Payment curTabID={this.state.curTabID} history={this.props.history} />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={styles.tableContainer}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
};

export default Main;