import { createModel } from '@/store/tools';

const listData = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };
const model = {
    namespace: 'businessclasslist',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getTableData': T('getTableData'),
            'getClassEnum': T('getClassEnum'),
        },
        actions: {
            'getTableData': A('getTableData'),
            'getClassEnum': A('getClassEnum'),
        },
        sagas: {
            'getTableData': S('getTableData', '/merchant/industryCategory/list'),
            'getClassEnum': S('getClassEnum', '/enum/IndustryCategoryTypeEnum'),
        },
        reducers: {
            'tableData': R('getTableData', listData),
            'classEnum': R('getClassEnum', []),
        }
    })
};
export default createModel(model);