/**
 * 审核管理 - 品牌审核
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Audit from './actiontypes';

export const getBrandAuditList = function* (){
    yield takeEvery(Audit.GET_BRANDAUDIT_LIST, function* requestData(action){
        try{
            let result = yield http('/goods/brand/list', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Audit.SET_BRANDAUDIT_LIST, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

export const getBrandAuditUsername = function* (){
    yield takeEvery(Audit.GET_BRANDAUDIT_USERNAME, function* requestData(action){
        try{
            let result = yield http('/merchant/info/listMerchantIdAndName', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Audit.SET_BRANDAUDIT_USERNAME, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

export const getBrandAuditState = function* (){
    yield takeEvery(Audit.GET_BRANDAUDIT_STATE, function* requestData(action){
        try{
            let result = yield http('/enum/GoodsAuditProcessEnum', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Audit.SET_BRANDAUDIT_STATE, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

export const getBrandAuditDetail = function* (){
    yield takeEvery(Audit.GET_BRANDAUDIT_DETAIL, function* requestData(action){
        try{
            let result = yield http('/goods/brand/auditInfo', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Audit.SET_BRANDAUDIT_DETAIL, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

export const getBrandAuditBrand = function* (){
    yield takeEvery(Audit.GET_BRANDAUDIT_BRAND, function* requestData(action){
        try{
            let result = yield http('/goods/brand/listBrandItem', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Audit.SET_BRANDAUDIT_BRAND, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};