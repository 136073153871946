import React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import styles from './TableContent.module.scss';
import { tooltipStyle, tooltip, tooltipClick, priceFenToYuanCell } from '@/components/TableCell';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
    };

    _takeColumns = () => ([
        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: 140,
            align: 'center',
            render: (orderId, record) => tooltipClick(orderId, () => this.props.tableAction(orderId, 'detail', record))
        },
        {
            title: '会员手机号',
            dataIndex: 'accountTel',
            key: 'accountTel',
            width: 140,
            align: 'center',
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: 200,
            align: 'center',
            render: tooltip
        },
        {
            title: '认购数量',
            dataIndex: 'number',
            key: 'number',
            width: 140,
            align: 'center',
            render: tooltip
        },
        {
            title: '商品单价(元)',
            dataIndex: 'goodsPrice',
            key: 'goodsPrice',
            width: 140,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '订单金额(元)',
            dataIndex: 'totalFee',
            key: 'totalFee',
            width: 140,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '订单状态',
            dataIndex: 'status',
            key: 'status',
            width: 140,
            align: 'center',
            render: tooltip
        },
        {
            title: '订单来源',
            dataIndex: 'source.value',
            key: 'source.value',
            width: 140,
            align: 'center',
            render: tooltip
        },
        {
            title: '下单时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 160,
            align: 'center',
            render: tooltip
        },
        {
            title: '定金支付时间',
            dataIndex: 'depositPayTime',
            key: 'depositPayTime',
            width: 160,
            align: 'center',
            render: tooltip
        },
        {
            title: '尾款支付时间',
            dataIndex: 'balancePayTime',
            key: 'balancePayTime',
            width: 160,
            align: 'center',
            render: tooltip
        },
        {
            title: '结算时间',
            dataIndex: 'settleTime',
            key: 'settleTime',
            width: 160,
            align: 'center',
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            width: 140,
            render: (text, item) => this._renderActionCell(text, item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _renderImgCell = (url) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.imgbox}>
                    <img className={styles.img} src={url} />
                </div>
            </div>
        );
    };

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderNumberCell = (text) => {
        const price = parseInt(text) / 100;
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {price}
            </div>
        );
    };

    _renderActionCell = (key, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>查看详情</div>
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: '100%', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;