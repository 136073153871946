import React, { PureComponent } from 'react';
import { Table, Icon, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import styles from './HotTable.module.scss';
import { tooltipStyle, tooltip } from '@/components/TableCell';


export default class HotTable extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    _columns = () => [
        {
            title: '城市',
            dataIndex: 'name',
            key: 'name',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '排序',
            dataIndex: 'sortNo',
            key: 'sortNo',
            width: '10%',
            align: 'center',
            render: (text, item) => (this.SortCell(text, item))
        },
        {
            title: '操作',
            dataIndex: 'hotCityId',
            key: 'hotCityId',
            align: 'center',
            width: '10%',
            render: (id, item) => this._tableAction(id, item)
        },
    ];

    // 解析表格数据，使符合columns格式要求
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                return item;
            });
        }
    }

    // 表格项内容为可编辑
    SortCell = (value, item) => {
        const label = React.createRef();
        const box = React.createRef();
        const input = React.createRef();
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.sort_box}>
                    <div className={styles.label} ref={label}>{value}</div>
                    <div className={styles.input} ref={box}>
                        <InputNumber
                            key={item.id}
                            style={{ width: '100px' }}
                            ref={input}
                            placeholder='请输入'
                            defaultValue={value}
                            precision={0}
                            min={0}
                            onBlur={(e) => {
                                box.current.style.display = 'none';
                                label.current.style.display = 'block';
                                if (value != e.target.value) {
                                    this.props.tableAction(item, 'sort', e.target.value);
                                }
                            }}
                        />
                    </div>
                    <div
                        className={styles.icon}
                        onClick={() => {
                            label.current.style.display = 'none';
                            box.current.style.display = 'block';
                            input.current.focus();
                        }}
                    >
                        <Icon type="edit" style={{ fontSize: '18px' }} />
                    </div>
                </div>
            </div>
        );
    };

    //表格操作
    _tableAction = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'delete', item)}>删除</div>
            </div>
        );
    };

    //渲染组件
    render() {
        const { renderData } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.hotCityId}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
            </div>
        );
    }
}

HotTable.propTypes = {
    renderData: PropTypes.array, // 表格数据源
    tableAction: PropTypes.func, // 表格
};