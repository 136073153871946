import React from 'react';
import styles from './TableContent.module.scss';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell } from '@/components/TableCell';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import ButtonPerssion from '@/routes/ButtonPerssion';

class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '付款批次号',
            dataIndex: 'batchNo',
            key: 'batchNo',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '付款单号',
            dataIndex: 'id',
            key: 'id',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '付款公司',
            dataIndex: 'platCompanyId.value',
            key: 'platCompanyId.value',
            width: cellWidth.time,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '付款通道',
            dataIndex: 'levyName',
            key: 'levyName',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '收款人',
            dataIndex: 'receiptName',
            key: 'receiptName',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '收款人手机号',
            dataIndex: 'receiptPhone',
            key: 'receiptPhone',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '收款账号',
            dataIndex: 'receiptAccountNo',
            key: 'receiptAccountNo',
            width: cellWidth.time,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '付款金额',
            dataIndex: 'amount',
            key: 'amount',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '付款状态',
            dataIndex: 'status.value',
            key: 'status.value',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '打款校验',
            dataIndex: 'verifyStatus.value',
            key: 'verifyStatus.value',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '打款时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.time,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '完成交易时间',
            dataIndex: 'remitFinishTime',
            key: 'remitFinishTime',
            width: cellWidth.time,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'left',
            fixed: 'right',
            width: cellWidth.time,
            render: (text, item) => this._renderActionCell(item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderActionCell = (item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction('detail', item)}>详情</div>
                {item.status.code == 'TODO' ? <div className={styles.item} onClick={() => tableAction('delete', item)}>删除</div> : null}
                <ButtonPerssion code='storehousefinancialmanage/otherpay/fk'>
                    {item.status.code == 'TODO' && item.verifyStatus.code == 'SUCCESS' ? <div className={styles.item} onClick={() => tableAction('pay', item)}>打款</div> : null}
                </ButtonPerssion>
            </div>
        );
    }

    render() {
        const { paginationChange, scrollY } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default XTableHOC(TableContent, 40);