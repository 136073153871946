import { createModel } from '@/store/tools';

const model = {
    namespace: 'signRecord',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getRecordList': T('getRecordList'),
        },
        actions: {
            'getRecordList': A('getRecordList'),
        },
        sagas: {
            'getRecordList': S('getRecordList', '/pay/outAccount/youFuPaySignRecord'),
        },
        reducers: {
            'signRecordList': R('getRecordList', []),
        }
    })
};
export default createModel(model);