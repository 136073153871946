/**
 *  权限管理 - 分配权限
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const assignauthoritymanage = ({ history }) => {
    return <Main history={history} />;
};

assignauthoritymanage.propTypes = {
    history: PropTypes.object
};

export default assignauthoritymanage;