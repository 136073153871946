/**
 * 【预警用户订单页】
 */
import React from 'react';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XSelect, showConfirm } from '@/components/xqxc_ui';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getRiskUserList, getRiskType, getRiskLevel } from '@/reducers/ordermanage/starOrder/actions';

import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
class Main extends React.Component {

    state = {
        tel: '', // 会员手机
        level: {}, // 预警等级
        riskType: {}, // 异常类型
        pageSize: 10,
        pageNum: 1,
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getStarOrderList(this.state.parm);
        // 异常类型
        this.props.getRiskType();
        // 预警等级
        this.props.getRiskLevel();
    }
    _searchHandle = (useCache) => {
        const { tel, riskType, level, pageNum, pageSize } = this.state;
        this.searchCache = {
            tel,
            riskType: riskType.code,
            level: level.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getRiskUserList(this.searchCache);
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageSize,
            pageNum,
        }, () => {
            this._searchHandle('useCache');
        });
    }
    // 重置
    _resetHandle = () => {
        this.setState({
            tel: '',
            level: {},
            riskType: {},
            pageNum: 1,
            pageSize: 50,
        }, () => {
            this._searchHandle('useCache');
        });
    }
    // 表格操作
    _tableAction = (key, type, record) => {
        switch (type) {
            // 解除预警
            case 'del':
                showConfirm('是否确定解除？', '', () => {
                    http('/settle/rechargeRisk/releaseRisk', { subjectType: record.subjectType, subjectIds: [record.subjectId], }, 'POST')
                        .then(() => {
                            message.success('解除成功。');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            // 异常订单
            case 'tel':
                this.props.tabChangeHandle(3);
                this.props.telHandle(key);
                break;
        }
    }
    render() {
        const { tel, level, riskType } = this.state;
        const { riskUserList, riskLevelList, riskTypeList } = this.props;
        return (
            <KeepAlive id='4' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='会员手机'
                                placeholder='请输入手机号码'
                                value={tel}
                                bindThis={this}
                                bindName='tel'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='预警等级'
                                placeholder='请选择预警等级'
                                renderData={riskLevelList}
                                dataIndex='value'
                                keyIndex='code'
                                value={level.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='level'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='异常类型'
                                placeholder='请选择异常类型'
                                renderData={riskTypeList}
                                dataIndex='value'
                                keyIndex='code'
                                value={riskType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='riskType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <OrderTable tableAction={this._tableAction} renderData={riskUserList} paginationChange={this._paginationChange} />
                </>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getRiskUserList: PropTypes.func,
    riskUserList: PropTypes.object,
    tabChangeHandle: PropTypes.func,
    telHandle: PropTypes.func,
    getRiskType: PropTypes.func,
    riskTypeList: PropTypes.array,
    getRiskLevel: PropTypes.func,
    riskLevelList: PropTypes.array,
};
const mapStateToProps = (state) => ({
    riskUserList: state.StarOrder.riskUserList,
    riskLevelList: state.StarOrder.riskLevelList,
    riskTypeList: state.StarOrder.riskTypeList,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getRiskUserList, getRiskType, getRiskLevel })(Main);
