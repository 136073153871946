/**
 * 月度统计
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XOKButton, XCancelButton, XInput } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import model from '@/reducers/storehousefinancialmanage/otherpay/model';
import KeepAlive from '@/routes/KeepAlive';
import TableContent from './components/TableContent';


class Main extends React.Component {

    state = {
        nameList: '', // 姓名
        idNoList: '', // 身份证
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    // 字符串转数组
    _strToArr = (arg) => {
        let arr = [];
        let str = arg;
        if (Array.isArray(str)) {
            if (str.length > 0 && str[0]) {
                arr = str;
            }
        } else if (str) {
            str = str.replace('，', ',');
            arr = str.split(',');
        }
        return arr;
    }

    // 查询
    _searchHandle = (useCache) => {
        const { nameList, idNoList, pageNum, pageSize } = this.state;
        const query = {
            nameList: this._strToArr(nameList),
            idNoList: this._strToArr(idNoList),
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };

        this.props.getListStatistics(query);
        KeepAlive.saveCache({ ...query }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ nameList: '', idNoList: '', pageNum: autoPageNum, pageSize: autopageSize }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { nameList, idNoList } = this.state;
        const { listStatistics } = this.props;
        return (
            <KeepAlive id='monthly' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='姓名'
                                placeholder='最多输入20个姓名，用逗号隔开'
                                value={nameList}
                                bindThis={this}
                                bindName='nameList'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='身份证'
                                placeholder='最多输入20个身份证，用逗号隔开'
                                value={idNoList}
                                bindThis={this}
                                bindName='idNoList'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent renderData={listStatistics} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getListStatistics: PropTypes.func, // 列表：签约记录
    listStatistics: PropTypes.object,
};

const mapStateToProps = (state) => ({
    listStatistics: state.otherpay.getListStatistics,
});

export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);