import React, { Component } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './GroupTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

export default class GroupTable extends Component {

    //初始化
    constructor(props) {
        super(props);
        this.state = {};
    }

    //props校验
    static propTypes = {
        renderData: PropTypes.object.isRequired, // 表格数据源
        tableAction: PropTypes.func.isRequired, // 表格操作
        paginationChange: PropTypes.func.isRequired, // 分页操作
    };

    //表头
    _columns = () => [
        {
            title: '组别名称',
            dataIndex: 'groupName',
            key: 'groupName',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '学员数量',
            dataIndex: 'accountCount',
            key: 'accountCount',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '创建人',
            dataIndex: 'createUserName',
            key: 'createUserName',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '15%',
            render: (id, record) => this._tableAction(id, record)
        },
    ];

    //处理返回数据
    _takeDataSource = (data) => {
        return data ? data : [];
    }

    //表格操作
    _tableAction = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'editInfo', item)}>编辑</div>
                <div className={styles.item} onClick={() => tableAction(key, 'viewInfo', item)}>查看学员</div>
                <div className={styles.item} onClick={() => tableAction(key, 'deleteInfo')}>删除</div>
            </div>
        );
    };

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}