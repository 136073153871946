
import React from 'react';
import { Modal, Icon, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './ContractModal.module.scss';
import { XInput, XSelect } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import BraftEditor from 'braft-editor'; // 引入编辑器组件与引入编辑器样式
import 'braft-editor/dist/index.css';
import * as qiniu from 'qiniu-js'; // 七牛云
import { formValids } from '@/assets/js/utils';
import uploadObjectConfig from '@/components/NewUpload/config';
import { braftEditorMedia } from '@/assets/config';

class ContractModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,

            version: this.props.data.version, //版本号
            contractName: this.props.data.contractName || {}, //合同名称
            alias: this.props.data.alias || '',  // 合同别名
            editorState: BraftEditor.createEditorState(this.props.data.context), // 富文本
        };
    }

    _handleOk = () => {
        const version = this.state.version,
            contactName = this.state.contractName.constantKey,
            alias = this.state.contractName.alias || this.props.data.alias,
            context = this.editorInstance.getValue().toHTML().replace(/(\d+)ptpx/g, '$1px');
        const contextValid = context.replace('<p></p>', '');
        // 非空判断
        if (formValids({ contactName, version, contextValid }, {
            contactName: '请选择合同名',
            version: '请写版本号',
            contextValid: '请输入合同协议'
        })) {
            this.setState({ confirmLoading: true });
            http('/contract/save/online', {
                contactName: contactName,
                alias: alias,
                gmtCreate: '',
                version,
                context
            }, 'POST').then(() => {
                message.success('创建成功。');
            }).catch(() => {
                message.warn('请求错误');
            }).finally(() => {
                this.setState({ visible: false, confirmLoading: false });
                this.props.closeMode(true);
            });
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // 富文本编辑回调
    handleEditorChange = (editorState) => {
        this.setState({ editorState });
    }

    uploadFn = async (param) => {
        let isMedia = /video\/|audio.+\//.test(param.file.type);
        let fileName = param.file.name,
            cf = uploadObjectConfig[isMedia ? 'file' : 'image'];
        let token = (await cf.getToken({ fileName }) || {}).result;
        if (token) {
            const observer = {
                next(res) {
                    param.progress(res.total.percent);
                },
                error(err) {
                    param.error({
                        msg: err.message
                    });
                },
                complete({ key }) {
                    cf.getFile({ key, fileName }).then(({ result } = {}) => {
                        param.success({ url: result });
                    });
                }
            };
            const UUID = this.uuid();
            var dfile = new File([param.file], UUID, { type: param.file.type });
            qiniu.upload(dfile, UUID, token).subscribe(observer);
        }
    }

    // 生成UUID唯一值
    uuid = () => {
        var s = [];
        var hexDigits = '0123456789abcdef';
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = '-';

        var uuid = s.join('');
        return uuid;
    }

    //  校验不通过的媒体文件将不会被添加到媒体库中
    myValidateFn = (file) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                file.size < 1024 * 100 ? resolve() : reject();
            }, 2000);
        });
    }

    render() {
        const { visible, confirmLoading, title, contractName, version, editorState } = this.state;
        const { readOnly } = this.props;
        const alias = this.props.data.alias || contractName.alias;
        return (
            <Modal
                width={750}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
                {...(readOnly ? { footer: null } : {})}
            >
                <div className={styles.container}>
                    <div className={styles.top}>
                        <div>
                            <Icon type="info" className={styles.icon} />
                            <p>合同生效后不可修改！</p>
                        </div>
                    </div>
                    {
                        readOnly ?
                            <XInput
                                style={{ width: '408px', marginTop: '15px' }}
                                inputStyle={{ width: '340px' }}
                                label='合同名称'
                                placeholder='请选择合同名称'
                                value={contractName.value}
                                readOnly={readOnly}
                            /> :
                            <XSelect
                                style={{ width: '408px', marginBottom: '15px' }}
                                selectStyle={{ width: '340px' }}
                                label='合同名称'
                                placeholder='请选择合同名称'
                                renderData={this.props.contraceNames}
                                dataIndex='constantName'
                                keyIndex='constantKey'
                                value={contractName.constantName}
                                bindThis={this}
                                bindName='contractName'
                            />
                    }
                    <XInput
                        style={{ width: '408px', marginTop: '15px' }}
                        inputStyle={{ width: '340px' }}
                        label='合同别名'
                        placeholder='请输入合同别名'
                        value={alias}
                        readOnly={true}
                    />
                    <XInput
                        style={{ width: '408px', marginTop: '15px' }}
                        inputStyle={{ width: '340px', marginLeft: '14px' }}
                        label='版本号'
                        placeholder='请输入版本号'
                        value={version}
                        bindThis={this}
                        bindName='version'
                        readOnly={readOnly}
                    />
                </div>
                <div>
                    <BraftEditor
                        ref={instance => this.editorInstance = instance}
                        readOnly={readOnly}
                        placeholder="请输入合同协议"
                        value={editorState}
                        // contentClassName='contractlistxqeditor-content'
                        onChange={this.handleEditorChange}
                        media={braftEditorMedia}
                    />
                </div>
            </Modal>
        );
    }
}

ContractModal.propTypes = {
    contraceNames: PropTypes.array,
    readOnly: PropTypes.bool,
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default ContractModal;