import * as Task from './actionTypes';

//出事表格数据
const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

//任务列表
export const taskClassifyList = (state = initData, { payload, type }) => {
    switch (type) {
        case Task.SET_TASKMANAGE_TASKCLASSIFYLIST:
            return payload.result;
        default:
            return state;
    }
};

//任务详情
export const taskClassifyStateSele = (state = [], { payload, type }) => {
    switch (type) {
        case Task.SET_TASKMANAGE_TASKCLASSIFYSTATESELE:
            return payload.result;
        default:
            return state;
    }
};