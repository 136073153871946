/**
 * 预览图片功能:缩小、放大、旋转（左右、水平和垂直翻转）、鼠标滚轮缩放、窗口拖拽、键盘esc键等
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import iviewers from './modal/PreviewModal.module.scss';
import { ImageLoad } from './imageLoad';

const imageLoad = new ImageLoad();
class ViewerExtension extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imgParentStyle: {},//总样式
            rotateDeg: 0,//默认旋转的角度
            scaleDegX: 1,//默认水平翻转
            scaleDegY: 1,//默认垂直翻转
        };

        this.initStyle = {
            transformOrigin: 'initial',
            msTransformOrigin: 'initial',
            WebkitTransformOrigin: 'initial',
            transition: 'transform 0.3s ease-out,width 0.3s ease-out',
            MozTransition: 'transform 0.3s ease-out,width 0.3s ease-out',
            WebkitTransition: 'transform 0.3s ease-out,width 0.3s ease-out',
            OTransition: 'transform 0.3s ease-out,width 0.3s ease-out',
        };
    }

    // 左右旋转事件
    rotateEvent = (angleType) => {
        const { modalstyle, } = this.props;
        const iRotateDeg = this.rateImage(angleType);
        this.setState({
            rotateDeg: iRotateDeg,
        }, () => {
            modalstyle(this.transformStyle());
        });
    }

    //水平和垂直翻转事件
    filterEvent = (scale) => {
        const { modalstyle, } = this.props;
        let degree = {};
        if (scale == 'h') {//水平
            this.setState({
                scaleDegX: this.state.scaleDegX === 1 ? -1 : 1,
            }, () => {
                const { rotateDeg, scaleDegX, scaleDegY } = this.state;
                degree = {
                    WebkitTransform: `rotate(${rotateDeg}deg) scale(${scaleDegX},${scaleDegY})`,
                    MozTransform: `rotate(${rotateDeg}deg) scale(${scaleDegX},${scaleDegY})`,
                    OTransform: `rotate(${rotateDeg}deg) scale(${scaleDegX},${scaleDegY})`,
                    transform: `rotate(${rotateDeg}deg) scale(${scaleDegX},${scaleDegY})`,
                    /*兼容IE*/
                    filter: 'FlipH',
                    ...this.initStyle,
                };
                modalstyle(degree);
            });
        } else {//垂直
            this.setState({
                scaleDegY: this.state.scaleDegY === 1 ? -1 : 1,
            }, () => {
                const { rotateDeg, scaleDegX, scaleDegY } = this.state;
                degree = {
                    WebkitTransform: `rotate(${rotateDeg}deg) scale(${scaleDegX},${scaleDegY})`,
                    MozTransform: `rotate(${rotateDeg}deg) scale(${scaleDegX},${scaleDegY})`,
                    OTransform: `rotate(${rotateDeg}deg) scale(${scaleDegX},${scaleDegY})`,
                    transform: `rotate(${rotateDeg}deg) scale(${scaleDegX},${scaleDegY})`,
                    /*兼容IE*/
                    filter: 'FlipV',
                    ...this.initStyle,
                };
                modalstyle(degree);
            });
        }
    }

    //transform样式兼容
    compatible = (refObj = {}, angle) => {
        const { trident, presto, webKit, gecko, } = imageLoad.browserNavigator();
        refObj.classList.add(`${iviewers.currentImg}`);
        if (trident) {//IE
            refObj.style.msTransform = `rotate(${angle}deg)`;
        } else if (webKit) {//苹果、谷歌
            refObj.style.webkitTransform = `rotate(${angle}deg)`;
        } else if (gecko) {//火狐
            refObj.style.MozTransform = `rotate(${angle}deg)`;
        } else if (presto) {//opera
            refObj.style.OTransform = `rotate(${angle}deg)`;
        } else {
            refObj.style.transform = `rotate(${angle}deg)`;
        }
    }

    /**旋转angle度*/
    rateImage = (angleType) => {
        // return (`${this.state.rotateDeg + angle}`) % 360; 配合翻转效果不好
        if (angleType == 'left') {
            return this.state.rotateDeg - 90;
        } else if (angleType == 'right') {
            return this.state.rotateDeg + 90;
        }
    }

    //图片点击缩放事件 ZoomIn ZoomOut 这边暂不做超出屏幕边界处理和监听resize
    zoomEvent = (zoom) => {
        this.props.handleZoomEvent(zoom);
        this.props.modalstyle(this.transformStyle());
    }

    //旋转、翻转style样式
    transformStyle = () => {
        const { rotateDeg, scaleDegX, scaleDegY } = this.state;
        let transform = {
            msTransform: `rotate(${rotateDeg}deg) scale(${scaleDegX},${scaleDegY})`,
            WebkitTransform: `rotate(${rotateDeg}deg) scale(${scaleDegX},${scaleDegY})`,
            MozTransform: `rotate(${rotateDeg}deg) scale(${scaleDegX},${scaleDegY})`,
            OTransform: `rotate(${rotateDeg}deg) scale(${scaleDegX},${scaleDegY})`,
            transform: `rotate(${rotateDeg}deg) scale(${scaleDegX},${scaleDegY})`,
            ...this.initStyle,
        };
        return transform;
    }


    //重置
    resetEvent = () => {
        const { dragDropCoordinate: { left, top } = {}, modalstyle, dragDropFunc, saveWidthModalFunc, saveWidthRef } = this.props;
        this.setState({
            rotateDeg: 0,//默认旋转的角度
            scaleDegX: 1,//默认水平翻转
            scaleDegY: 1,//默认垂直翻转
        }, () => {
            modalstyle(this.transformStyle());
            //拖拽坐标为原坐标则不重置
            if (left != '0' && top != '0') {
                dragDropFunc({ left: 0, top: 0 });//设置拖拽坐标
            }
            //重置时还原初始化保存默认Modal宽度
            saveWidthModalFunc(saveWidthRef);
        });
    }


    render() {
        return (<div className={iviewers.viewerFooter}>
            <ul className={iviewers.viewerToolbar}>
                <li onClick={() => this.zoomEvent('zoomIn')} className={iviewers.viewerZoomIn} data-action="zoom-in"></li>
                <li onClick={() => this.zoomEvent('zoomOut')} className={iviewers.viewerZoomOut} data-action="zoom-out"></li>
                {/* <li className={iviewers.viewerOnetoOne} data-action="one-to-one"></li> */}
                <li onClick={this.resetEvent} className={iviewers.viewerReset} data-action="reset"></li>
                <li onClick={() => this.rotateEvent('left')} className={iviewers.viewerRotateLeft} data-action="rotate-left"></li>
                <li onClick={() => this.rotateEvent('right')} className={iviewers.viewerRotateRight} data-action="rotate-right"></li>
                <li onClick={() => this.filterEvent('h')} className={iviewers.viewerFlipHorizontal} data-action="flip-horizontal"></li>
                <li onClick={() => this.filterEvent('v')} className={iviewers.viewerFlipVertical} data-action="flip-vertical"></li>
            </ul>
        </div>);
    }
}

ViewerExtension.propTypes = {
    modalstyle: PropTypes.func, //modal总样式
    dragDropCoordinate: PropTypes.object,//拖拽的坐标位置
    dragDropFunc: PropTypes.func,//拖拽的坐标位置func
    saveWidthModalFunc: PropTypes.func,//重置时还原初始化保存默认Modal宽度func
    saveWidthRef: PropTypes.number,//重置时还原初始化保存默认Modal宽度
    handleZoomEvent: PropTypes.func,//图片点击缩放事件
};

export default ViewerExtension;