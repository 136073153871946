/**
 * 系统管理 - 计费分配比例设置
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as A from './actiontypes';

// 获取计费分配比例设置表格
export const getAllocationList = function* () {
    yield takeEvery(A.GET_ALLOCATION_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/ratioSetting/listRateSetting', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: A.SET_ALLOCATION_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 获取计费详情
export const getAllocationDetail = function* () {
    yield takeEvery(A.GET_ALLOCATION_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/settle/ratioSetting/getRatioSetting', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: A.SET_ALLOCATION_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 获取设置项
export const getSettingCode = function* () {
    yield takeEvery(A.GET_SETTING_CODE, function* requestData(action) {
        try {
            let result = yield http('/settle/ratioSetting/getSettingCode', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: A.SET_SETTING_CODE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};