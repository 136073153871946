/** 人气值商城Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

/**
 * 人气值商城分类 列表'数据源(saga)
 */
export const starmallList = function* () {
    yield takeEvery(T.GET_STARLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/starmall/category/list', action.payload, 'POST');
            yield put({ type: T.SET_STARLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 人气值商城分类状态- 分类状态 Saga
 */
export const starmallListState = function* () {
    yield takeEvery(T.GET_LISTSTATE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/enum/StateEnum', action.payload, 'POST');
            yield put({ type: T.SET_LISTSTATE_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 人气值商品 列表'数据源(saga)
 */
export const starvalueList = function* () {
    yield takeEvery(T.GET_STARVALUE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/starmall/goods/list', action.payload, 'POST');
            yield put({ type: T.SET_STARVALUE_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 人气值商品分类状态-Saga
 */
export const starvalueListState = function* () {
    yield takeEvery(T.GET_VALUESTATE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/enum/StarGoodsTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_VALUESTATE_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 人气值商品详情-Saga
 */
export const starvalueListDetail = function* () {
    yield takeEvery(T.GET_VALUEDETAIL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/starmall/goods/getById', action.payload, 'POST');
            yield put({ type: T.SET_VALUEDETAIL_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 人气值商品新建优惠劵和会员等级共用-Saga  
 */
export const starsharing = function* () {
    yield takeEvery(T.GET_VALUEDHARING_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let response = yield http('/coupon/coupon/couponAvailableList', action.payload, 'POST');
            let result = yield http('/user/account/listAccountLevel', action.payload, 'POST');
            response.result.membership = result.result;
            yield put({ type: T.SET_VALUEDHARING_DATA, payload: response });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 兑换记录数据源-Saga  
 */
export const starRecord = function* () {
    yield takeEvery(T.GET_RECORD_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let response = yield http('/boss/order/startValuexchangeRecordPage', action.payload, 'POST');
            yield put({ type: T.SET_RECORD_DATA, payload: response });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 兑换记录分类状态-Saga
 */
export const starRecordState = function* () {
    yield takeEvery(T.GET_RECORDSTATE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/enum/StarGoodsTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_RECORDSTATE_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};