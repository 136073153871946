import { createModel } from '@/store/tools';

const model = {
    namespace: 'merchantMode',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getFinanceStateList': T('getFinanceStateList'),
            'getPayStateList': T('getPayStateList'),
            'getPayMerchantList': T('getPayMerchantList'),
            'getMerchantPayList': T('getMerchantPayList'),
            'getMerchantPayDetail': T('getMerchantPayDetail'),
            'getKungeekBalance': T('getKungeekBalance'), // 魅力值tab 查询余额
            'getPreDispatchList': T('getPreDispatchList'), // 魅力值tab 获取派单列表
            'getStarlightWithdrawEpibolyServiceFee': T('getStarlightWithdrawEpibolyServiceFee'), // 魅力值tab 服务费率
            'getDispatchList': T('getDispatchList'), // 魅力值tab 派单查询
            'getDispatchItmes': T('getDispatchItmes'), // 魅力值tab 派单明细查询
            'getDispatchItemsInfo': T('getDispatchItemsInfo'), // 魅力值tab 派单明细汇总信息
        },
        actions: {
            'getFinanceStateList': A('getFinanceStateList'),
            'getPayStateList': A('getPayStateList'),
            'getPayMerchantList': A('getPayMerchantList'),
            'getMerchantPayList': A('getMerchantPayList'),
            'getMerchantPayDetail': A('getMerchantPayDetail'),
            'getKungeekBalance': A('getKungeekBalance'),
            'getPreDispatchList': A('getPreDispatchList'),
            'getStarlightWithdrawEpibolyServiceFee': A('getStarlightWithdrawEpibolyServiceFee'),
            'getDispatchList': A('getDispatchList'),
            'getDispatchItmes': A('getDispatchItmes'),
            'getDispatchItemsInfo': A('getDispatchItemsInfo'),
        },
        sagas: {
            'getFinanceStateList': S('getFinanceStateList', '/enum/FinanceStateEnum'),
            'getPayStateList': S('getPayStateList', '/enum/PaymentStateEnum'),
            'getPayMerchantList': S('getPayMerchantList', 'merchant/info/listMerchantIdAndName'),
            'getMerchantPayList': S('getMerchantPayList', '/xiudou/cash/listXiudouWithdrawAudit/'),
            'getMerchantPayDetail': S('getMerchantPayDetail', '/xiudou/cash/detailCashWithdrawAudit/', 'POST', ['id']),
            'getKungeekBalance': S('getKungeekBalance', '/settle/paymentApplication/getThirdUnionBalance'),
            'getPreDispatchList': S('getPreDispatchList', '/settle/paymentApplication/getPreDispatchList'),
            'getStarlightWithdrawEpibolyServiceFee': S('getStarlightWithdrawEpibolyServiceFee', '/settle/paymentApplication/getStarlightWithdrawEpibolyServiceFee', 'GET'),
            'getDispatchList': S('getDispatchList', '/settle/paymentApplication/getDispatchList'),
            'getDispatchItmes': S('getDispatchItmes', '/settle/paymentApplication/getDispatchItmes'),
            'getDispatchItemsInfo': S('getDispatchItemsInfo', '/settle/paymentApplication/getDispatchItemsInfo', 'GET'),
        },
        reducers: {
            'financeStateList': R('getFinanceStateList', []),
            'payStateList': R('getPayStateList', []),
            'payMerchantList': R('getPayMerchantList', { merchantIdAndNames: [] }),
            'merchantPayList': R('getMerchantPayList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'merchantPayDetail': R('getMerchantPayDetail', {}),
            'KungeekBalance': R('getKungeekBalance', 0),
            'PreDispatchList': R('getPreDispatchList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'StarlightWithdrawEpibolyServiceFee': R('getStarlightWithdrawEpibolyServiceFee', 0),
            'DispatchList': R('getDispatchList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'DispatchItmes': R('getDispatchItmes', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'DispatchItemsInfo': R('getDispatchItemsInfo', {}),
        }
    })
};
export default createModel(model);