import { createModel } from '@/store/tools';

const tableData = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };

const model = {
    namespace: 'subsidiesincome',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getSubsidiesIncome': T('getSubsidiesIncome'),
            'getStateEnum': T('getStateEnum'),
        },
        actions: {
            'getSubsidiesIncome': A('getSubsidiesIncome'),
            'getStateEnum': A('getStateEnum'),
        },
        sagas: {
            'getSubsidiesIncome': S('getSubsidiesIncome', '/marketing/orderSubsidy/findPagedActivitySubsidy'),
            'getStateEnum': S('getStateEnum', '/enum/ActivitySubsidyStateEnum'),
        },
        reducers: {
            'subsidiesIncome': R('getSubsidiesIncome', tableData),
            'stateEnum': R('getStateEnum', []),
        }
    })
};
export default createModel(model);