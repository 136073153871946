import { createModel } from '@/store/tools';

const model = {
    namespace: 'riskcontrol',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getXiuDouSetting': T('getXiuDouSetting'),
            'getAccountLevel': T('getAccountLevel'),
        },
        actions: {
            'getXiuDouSetting': A('getXiuDouSetting'), // 获取人气值赠送设置
            'getAccountLevel': A('getAccountLevel'), // 获取会员等级下拉列表
        },
        sagas: {
            'getXiuDouSetting': S('getXiuDouSetting', '/admin/starvaluePresentSetting/query'),
            'getAccountLevel': S('getAccountLevel', '/user/account/accountLevelDropDown'),
        },
        reducers: {
            'getXiuDouSetting': R('getXiuDouSetting', {}),
            'getAccountLevel': R('getAccountLevel', []),
        }
    })
};
export default createModel(model);