/**
 * 分销销售商品排行
 */
import React from 'react';
import { Tag, message } from 'antd';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XDatePicker, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import TableContent from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/statisticsofdistributor/goodshare/model';
import http from '@/assets/api/http';
import { SearchBox, SearchItem } from '@/components/Layout';

const tags = [
    { id: 1, color: '#87d068', value: '今日' },
    { id: 2, color: '#f50', value: '昨日' },
    { id: 3, color: '#2db7f5', value: '本周' },
    { id: 4, color: '#87d068', value: '本月' },
    { id: 5, color: '#33ccc9', value: '本季' },
    { id: 6, color: '#108ee9', value: '本年' },
];

const sortTags = [
    { id: 1, color: '#87d068', value: '按分销自购订单数降序', sort: '0' },
    { id: 2, color: '#f50', value: '按分销自购订单金额降序', sort: '1' },
    { id: 3, color: '#2db7f5', value: '按分销分享订单数降序', sort: '2' },
    { id: 4, color: '#87d068', value: '按分销分享订单金额降序', sort: '3' },
];

class Main extends React.Component {

    state = {
        startDate: undefined,//付款日期开始
        endDate: undefined,//付款日期结束
        curTagID: 1,//时间
        period: 'today',
        curSortTagID: undefined,//排序标识
        sort: '',//排序
        shareLoading: false
    };

    componentDidMount() {

    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, period, startDate, endDate, sort } = this.state;
        if (startDate && endDate) {
            this.query = {
                startDate,
                endDate,
                sort,
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            this.setState({ period: undefined, curTagID: 0 }, () => this.props.getGoodshareList(this.query));
        } else if (period) {
            this.query = {
                period,
                sort,
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            this.setState({ startDate: undefined, endDate: undefined }, () => this.props.getGoodshareList(this.query));
        } else {
            message.error('请选择付款日期范围');
        }
        KeepAlive.saveCache({ ...this.state, pageNum: this.query.pageNum, pageSize: this.query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ startDate: undefined, endDate: undefined, curTagID: 1, period: 'today', sort: '', curSortTagID: undefined }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 渲染Tags
    _renderTags = () => {
        const { curTagID } = this.state;
        return (
            <div style={{ marginRight: '12px' }}>
                {tags.map((item, index) => {
                    let color = curTagID == item.id ? item.color : '';
                    return <Tag style={{ width: '50px', textAlign: 'center', cursor: 'pointer' }} key={index} color={color} onClick={() => { this._tagClick(item.id); }}>{item.value}</Tag>;
                })}
            </div>
        );
    }

    // 渲染sortTags
    _renderSortTags = () => {
        const { curSortTagID } = this.state;
        return (
            <div style={{ marginRight: '12px' }}>
                {sortTags.map((item, index) => {
                    let color = curSortTagID == item.id ? item.color : '';
                    return <Tag style={{ height: '25px', lineHeight: '24px', cursor: 'pointer' }} key={index} color={color} onClick={() => { this._sortTagClick(item.id, item.sort); }}>{item.value}</Tag>;
                })}
            </div>
        );
    }

    // 点击Tags
    _tagClick = (id) => {
        this.setState({ curTagID: id, startDate: undefined, endDate: undefined }, () => {
            switch (id) {
                case 1:
                    this.setState({ period: 'today' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 2:
                    this.setState({ period: 'yesterday' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 3:
                    this.setState({ period: 'week' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 4:
                    this.setState({ period: 'month' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 5:
                    this.setState({ period: 'quarter' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 6:
                    this.setState({ period: 'year' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
            }
        });
    }

    _sortTagClick = (id, sortParam) => {
        this.setState({ curSortTagID: id }, () => {
            this.setState({ sort: sortParam }, () => {
                this._searchHandle('useCache');
            });
        });
    }

    //表格操作
    _tableAction = (id, type) => {
        this.props.updateContent(type, id);
    }

    //生成报表
    _createHandle = () => {
        this.setState({ shareLoading: true }, () => {
            const { pageNum, pageSize, period, startDate, endDate, sort } = this.state;
            let createQuery = {};
            if (startDate && endDate) {
                createQuery = {
                    startDate,
                    endDate,
                    sort,
                };
                this.setState({ period: undefined, curTagID: 0 });
            } else if (period) {
                createQuery = {
                    period,
                    sort,
                    pageNum,
                    pageSize,
                };
                this.setState({ startDate: undefined, endDate: undefined });
            }
            http('/boss/order/distribution/distributionGoodsRankExportExcel', createQuery, 'POST')
                .then((res = {}) => {
                    this.setState({ shareLoading: false });
                    if (res.status == '200' && res.result) {
                        window.location.href = res.result;
                        message.success('导出成功');
                    } else if (res.status == '200' && res.result == '') {
                        message.warning('暂无数据');
                    } else {
                        message.warning('后台数据生成中,请稍后点击下载');
                    }
                }).catch((e = {}) => {
                    this.setState({ shareLoading: false });
                    message.error(e.message);
                });
        });
    }

    render() {
        const { startDate, endDate, } = this.state;
        const { shareList } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>

                    <SearchBox>
                        <SearchItem>
                            {this._renderSortTags()}
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='付款日期'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                label=''
                                style={{ marginRight: '20px' }}
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            {this._renderTags()}
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.row2}>
                        <XOKButton
                            style={{ width: 'auto' }}
                            loading={this.state.shareLoading}
                            label='生成报表'
                            onClick={this._createHandle}
                        />
                    </div>
                    <TableContent
                        renderData={shareList}
                        paginationChange={this._paginationChange}
                        tableAction={this._tableAction}
                    />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,//路由对象
    getGoodshareList: PropTypes.func,//分销销售商品排行action
    shareList: PropTypes.object,
    updateContent: PropTypes.func,//页面跳转
};
const mapStateToProps = (state) => ({
    shareList: state.goodshare.goodshareList,
});
export default connect(mapStateToProps, { ...model.actions, })(Main);