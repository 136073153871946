/**
 * [订单统计]
 * @author: Fu Xiaochun
 * @date: 2023-05-19 
 */

import React, { useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import RegionalCompany from './regionalCompany';
import ServiceProvider from './serviceProvider';
import OrderList from './orderList';
import { isOC } from '@/assets/js/authType';

const { TabPane } = Tabs;

function OrderStatistics(){

    const [tabIndex, setTabIndex] = useState('1');

    if(!isOC()){
        return <div className={styles.noAuth}>仅运营中心账号可查看此功能</div>;
    }

    return <div className={styles.flexBoxContainer}>
        <div className="tabBox">
            <Tabs aactiveKey={tabIndex} onChange={setTabIndex}>
                <TabPane tab="区域公司统计" key="1" />
                <TabPane tab="服务商统计" key="2" />
                <TabPane tab="订单明细" key="3" />
            </Tabs>
        </div>
        {tabIndex == '1' && <RegionalCompany />}
        {tabIndex == '2' && <ServiceProvider />}
        {tabIndex == '3' && <OrderList />}
    </div>;
}

export default OrderStatistics;