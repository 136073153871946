import { createModel, } from '@/store/tools';

const model = {
    namespace: 'serviceProduct',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getServiceGoodsPage': T('getServiceGoodsPage'),// 服务商品列表
            'getServiceGoodsPageInfo': T('getServiceGoodsPageInfo'),// 服务商品详情
            'getServiceGoodsTypeEnum': T('getServiceGoodsTypeEnum'),// 服务规格
            'getProductRateListData': T('getProductRateListData'),// 上架确认服务清单
            'getAssociatedGoodsList': T('getAssociatedGoodsList'),
            'getSupplierSele': T('getSupplierSele'),
        },
        actions: {
            'getServiceGoodsPage': A('getServiceGoodsPage'), // 服务商品列表
            'getServiceGoodsPageInfo': A('getServiceGoodsPageInfo'), // 服务商品详情
            'getServiceGoodsTypeEnum': A('getServiceGoodsTypeEnum'), // 服务规格
            'getProductRateListData': A('getProductRateListData'), // 上架确认服务清单
            'getAssociatedGoodsList': A('getAssociatedGoodsList'), // 人气值列表
            'getSupplierSele': A('getSupplierSele'), // 供应商下拉
        },
        sagas: {
            'getServiceGoodsPage': S('getServiceGoodsPage', '/goods/goods/findServiceGoodsPage'),
            'getServiceGoodsPageInfo': S('getServiceGoodsPageInfo', '/goods/goods/findServiceGoodsInfo', 'POST', 'goodsId'),
            'getServiceGoodsTypeEnum': S('getServiceGoodsTypeEnum', '/enum/ServiceGoodsTypeEnum'),
            'getProductRateListData': S('getProductRateListData', '/goods/goods/findRateGoodsList'),
            'getAssociatedGoodsList': S('getAssociatedGoodsList', '/goods/query/queryGoodsItem'),
            'getSupplierSele': S('getSupplierSele', '/enum'),
        },
        reducers: {
            'serviceList': R('getServiceGoodsPage', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'serviceDetail': R('getServiceGoodsPageInfo', {}),
            'ServiceGoodsTypeEnum': R('getServiceGoodsTypeEnum', []),
            'productRateListData': R('getProductRateListData', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'associatedGoodsList': R('getAssociatedGoodsList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'supplierSele': R('getSupplierSele', []),
        }
    })
};
export default createModel(model);