import * as Audit from './actiontypes';

/**
 * 存储'鉴黄审核列表'数据源
 * @param {*} state 
 * @param {*} action 
 */

const anchorCoverListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const pornographyList = (state = anchorCoverListInital, action) => {
    switch (action.type) {
        case Audit.SET_PORNOGRAPHY_LIST:
            return action.payload;
        default:
            return state;
    }
};

const pornographyTypeInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const pornographyType = (state = pornographyTypeInital, action) => {
    switch (action.type) {
        case Audit.SET_PORNOGRAPHY_TYPE:
            return action.payload;
        default:
            return state;
    }
};


