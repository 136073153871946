/**
 * 更新贡献值规则
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message } from 'antd';
import styles from './modeStyle.module.scss';
import { XInputNum, XInput } from '@/components/xqxc_ui';

const UpdateRule = ({
    modeParams,
    closeMode
}) => {

    const [loading, setLoading] = useState(false);
    const [ruleName, setRuleName] = useState('');
    const [ruleLimit, setRuleLimit] = useState(1);
    const [ruleRate, setRuleRate] = useState(0.01);

    useEffect(() => {
        if (modeParams.ruleName) {
            setRuleName(modeParams.ruleName);
            setRuleLimit(modeParams.ruleLimit);
            setRuleRate(modeParams.ruleRate);
        }
    }, [modeParams]);

    //确定取消
    const onSumbit = () => {
        if (!ruleName) {
            message.warning('请输入规则名称');
            return;
        }
        if (!ruleLimit) {
            message.warning('请输入兑换门槛');
            return;
        }
        if (!ruleRate) {
            message.warning('请输入1股兑换');
            return;
        }
        setLoading(true);
        http('/gatherBeans/showShellRule/addShowShellRule', { ruleName, ruleLimit, ruleRate: ruleRate * 100 }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            }).catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    return (
        <Modal
            width={600}
            centered
            title='创建贡献值兑换规则'
            visible={true}
            confirmLoading={loading}
            onOk={onSumbit}
            onCancel={() => closeMode(false)}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'>
            <div className={styles.container}>
                <XInput
                    label='规则名称'
                    style={{ marginBottom: 20 }}
                    inputStyle={{ width: 300 }}
                    maxLength={265}
                    placeholder='请输入规则名称'
                    bindThis={setRuleName}
                    bindName='ruleName'
                    value={ruleName}
                    isRequired
                />
                <XInputNum
                    label='使用门槛'
                    style={{ marginBottom: 20 }}
                    inputStyle={{ width: 200 }}
                    min={1}
                    max={99999}
                    precision={0}
                    placeholder='请输入'
                    bindThis={setRuleLimit}
                    bindName='ruleLimit'
                    value={ruleLimit}
                    isRequired
                    suffix='以上贡献值（含）（范围1~~99999）'
                    suffixStyls={{ color: '#ff0000' }}
                />
                <XInputNum
                    label='1股兑换'
                    inputStyle={{ width: 200 }}
                    min={0.01}
                    max={99999.99}
                    placeholder='请输入'
                    precision={2}
                    bindThis={setRuleRate}
                    bindName='ruleRate'
                    value={ruleRate}
                    isRequired
                    suffix='贡献值 （不可以为0）'
                    suffixStyls={{ color: '#ff0000' }}
                />
            </div>
        </Modal>
    );
};
UpdateRule.propTypes = {
    modeParams: PropTypes.object,
    closeMode: PropTypes.func.isRequired
};
export default UpdateRule;