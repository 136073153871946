import * as T from './actiontypes';

// 会员等级设置列表
export const getMemberGradeList = ({ pageNum, pageSize }) => ({
    type: T.GET_MEMBERGRADE_LIST,
    payload: {
        levelType: 'ACCOUNT_LEVEL', pageNum, pageSize
    }
});

// 会员等级设置详情
export const getMemberGradeDetail = (id) => ({
    type: T.GET_MEMBERGRADE_DETAIL,
    payload: { id }
});

// 会员等级设置优惠券下拉
export const getMemberGradeCouponSele = (couponName) => ({
    type: T.GET_MEMBERGRADE_COUPONSELE,
    payload: {
        couponName
    }
});

// 会员等级设置单选框
export const getMemberGradeRadio = () => ({
    type: T.GET_MEMBERGRADE_RADIO,
    payload: {}
});