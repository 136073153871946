import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

//魅力等级设置列表
export const getCharmGradeList = function* () {
    yield takeEvery(T.GET_CHARMGRADE_LIST, function* requestData(action) {
        try {
            let result = yield http('/user/level/listAccountLevel', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_CHARMGRADE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//魅力等级设置详情
export const getCharmGradeDetail = function* () {
    yield takeEvery(T.GET_CHARMGRADE_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/user/level/getAccountLevelInfo', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: T.SET_CHARMGRADE_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//魅力等级设置优惠券下拉
export const getCharmGradeCouponSele = function* () {
    yield takeEvery(T.GET_CHARMGRADE_COUPONSELE, function* requestData(action) {
        try {
            let result = yield http('/coupon/coupon/couponAvailableListByName', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: T.SET_CHARMGRADE_COUPONSELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//魅力等级设置单选框
export const getCharmGradeRadio = function* () {
    yield takeEvery(T.GET_CHARMGRADE_RADIO, function* requestData(action) {
        try {
            let grow = yield http('/admin/param/getParamValueByCode/CHARM_ADD_SOURCE', {}, 'POST'); // action.payload为传入的参数对象
            let grade = yield http('/admin/param/getParamValueByCode/CHARM_LEVEL_PERIOD', {}, 'POST'); // action.payload为传入的参数对象
            yield put({ type: T.SET_CHARMGRADE_RADIO, payload: { growValue:grow.result, gradeValue:grade.result } });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};