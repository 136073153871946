/**
 * 【认购订单页】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker, XTabNav, XInputNum } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { getSubOrderList } from '@/reducers/ordermanage/subscriptionOrder/actions';
import { getProductSubOrderStatus } from '@/reducers/ordermanage/productOrder/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: [
                { id: 1, label: '全部订单' },
                { id: 2, label: '待付款' },
                { id: 3, label: '已付款' },
                { id: 4, label: '已结算' },
                { id: 5, label: '已取消' },
            ],
            curTabID: 1,  // 当前显示的Tab页ID
            curTabIDStatus: '',
            accountTel: '', // 会员手机号
            orderId: '', // 订单号
            orderState: {}, // 打款状态
            gmtCreateF: null, // 下单时间开始日期
            gmtCreateT: null, // 下单结束日期
            goodsName: '', // 商品名称
            itemNo: '', // 商品货号
            sourceType: {},
            sourceTypeList: [],
            pageNum: 1,
            pageSize: 10,
        };
        this.searchCache = { pageNum: 1, pageSize: 20 }; // 查询缓存
    }
    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getSubOrderList({ pageNum: 1, pageSize: 10 });
        this.props.getProductSubOrderStatus();
        this.getSourceTypeList();
    }
    getSourceTypeList = () => {
        http('/enum/BossGatherWithdrawSourceEnum').then(res => {
            this.setState({ sourceTypeList: res.result || [] });
        });
    }
    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
        const { pageNum, pageSize } = this.state;
        // PAYING：待付款

        // PAIED：已付款

        // CANCELLED：已取消

        // FINISHED：已完成
        // ------------------------------------
        // { id: 1, label: '全部订单' },
        // { id: 2, label: '待付款' },
        // { id: 3, label: '已付款' },
        // { id: 4, label: '已结算' },
        // { id: 5, label: '已取消' },
        switch (tabID) {
            case 1:
                this.setState({
                    curTabIDStatus: '',
                    pageNum: 1,
                }, () => {
                    this._searchHandle('useCache');
                });
                // this.props.getSubOrderList({ pageNum, pageSize });
                break;
            case 2:
                this.setState({
                    curTabIDStatus: 'PAYING',
                    pageNum: 1,
                }, () => {
                    this._searchHandle('useCache');
                });
                // this.props.getSubOrderList({ status: 'PAYING', pageNum, pageSize });
                break;
            case 3:
                this.setState({
                    curTabIDStatus: 'PAIED',
                    pageNum: 1,
                }, () => {
                    this._searchHandle('useCache');
                });
                // this.props.getSubOrderList({ status: 'PAIED', pageNum, pageSize });
                break;
            case 4:
                this.setState({
                    curTabIDStatus: 'FINISHED',
                    pageNum: 1,
                }, () => {
                    this._searchHandle('useCache');
                });
                // this.props.getSubOrderList({ status: 'FINISHED', pageNum, pageSize });
                break;
            case 5:
                this.setState({
                    curTabIDStatus: 'CANCELLED',
                    pageNum: 1,
                }, () => {
                    this._searchHandle('useCache');
                });
                // this.props.getSubOrderList({ status: 'CANCELLED', pageNum, pageSize });
                break;
        }
    }

    // 订单状态
    _renderOrderStateSelect = () => {
        const { orderState, curTabID } = this.state;
        const { productSubOrderStatus } = this.props;
        if (curTabID == 1) {
            return (
                <XSelect
                    label='订单状态'
                    placeholder='请选择订单状态'
                    renderData={productSubOrderStatus}
                    dataIndex='value'
                    keyIndex='code'
                    value={orderState.value}
                    showSearch={true}
                    bindThis={this}
                    bindName='orderState'
                />
            );
        } else {
            return null;
        }
    }

    // 导出
    _exportHandle = () => {

    }

    // 查询
    _searchHandle = (useCache) => {
        const { curTabID, curTabIDStatus, accountTel, orderId, orderState, gmtCreateF, gmtCreateT, goodsName, itemNo, sourceType, pageNum, pageSize } = this.state;
        this.searchCache = {
            accountTel,
            orderId,
            gmtCreateF,
            gmtCreateT,
            goodsName,
            itemNo,
            sourceList: sourceType.code && [sourceType.code],
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            status: curTabID === 1 ? orderState && orderState.code : curTabIDStatus,
        };
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getSubOrderList(this.searchCache);
    }

    // 重置
    _resetHandle = () => {
        // const { curTabIDStatus } = this.state;
        this.setState({
            accountTel: '', // 会员手机号
            orderId: '', // 订单号
            orderState: {}, // 打款状态
            gmtCreateF: null, // 下单时间开始日期
            gmtCreateT: null, // 下单结束日期
            goodsName: '', // 商品名称
            itemNo: '', // 商品货号
            sourceType: {},
            pageNum: 1,
            pageSize: 10,
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        // this.props.getSubOrderList({ pageNum: 1, pageSize: 10, status: curTabIDStatus });
    }


    // 表格操作
    _tableAction = (key, type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: {
                        pathname: '/home/ordermanage/subscriptionorder/detail',
                        search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                    }
                });
                history.push({
                    pathname: '/home/ordermanage/subscriptionorder/detail',
                    search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageSize,
            pageNum,
        });
        const { curTabID, curTabIDStatus, accountTel, orderId, orderState, gmtCreateF, gmtCreateT, goodsName, itemNo, sourceType } = this.state;
        this.searchCache = {
            accountTel,
            orderId,
            gmtCreateF,
            gmtCreateT,
            goodsName,
            itemNo,
            sourceType: sourceType.code,
            pageNum,
            pageSize,
            status: curTabID === 1 ? orderState && orderState.code : curTabIDStatus,
        };
        this.props.getSubOrderList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { subOrderList } = this.props;
        const { accountTel, orderId, gmtCreateF, gmtCreateT, goodsName, itemNo, sourceType, sourceTypeList } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <div style={{ marginTop: 10 }}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='会员手机号'
                                    placeholder='请输入会员手机号'
                                    value={accountTel}
                                    bindThis={this}
                                    bindName='accountTel'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInputNum
                                    label='订单编号'
                                    placeholder='请输入订单编号'
                                    value={orderId}
                                    bindThis={this}
                                    bindName='orderId'
                                    min={0}
                                    precision={0}
                                    maxLength={16}
                                />
                            </SearchItem>
                            <SearchItem>
                                {this._renderOrderStateSelect()}
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='商品名称'
                                    placeholder='请输入商品名称'
                                    value={goodsName}
                                    bindThis={this}
                                    bindName='goodsName'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='商品货号'
                                    placeholder='请输入商品货号'
                                    value={itemNo}
                                    bindThis={this}
                                    bindName='itemNo'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XDatePicker
                                    label='下单时间'
                                    value={gmtCreateF}
                                    bindThis={this}
                                    bindName='gmtCreateF'
                                    isFormat
                                />
                                <XDatePicker
                                    style={{ marginRight: 20 }}
                                    label=''
                                    value={gmtCreateT}
                                    bindThis={this}
                                    bindName='gmtCreateT'
                                    isFormat
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='订单来源'
                                    placeholder='请选择'
                                    renderData={sourceTypeList}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={sourceType.value}
                                    showSearch={true}
                                    bindThis={this}
                                    bindName='sourceType'
                                    isOptionBindID={true}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton label='查询' onClick={this._searchHandle} />
                                <XCancelButton label='重置' onClick={this._resetHandle} />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    {/* <div className={styles.export}>
                    <XOKButton label='导出' onClick={this._exportHandle} />
                </div> */}
                    <TableContent renderData={subOrderList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getSubOrderList: PropTypes.func,
    subOrderList: PropTypes.object,
    getProductSubOrderStatus: PropTypes.func,
    productSubOrderStatus: PropTypes.array,
};
const mapStateToProps = (state) => ({
    subOrderList: state.SubOrder.subOrderList,
    productSubOrderStatus: state.productOrder.productSubOrderStatus,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getSubOrderList, getProductSubOrderStatus })(Main);