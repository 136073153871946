import * as T from './actiontypes';

//获取商品列表
export const getProductAuditData = (params) => ({
    type: T.GET_PRODUCT_PRODUCTAUDITDATA,
    payload: params
});

//获取商品费率列表
export const getProductAuditRateList = (params) => ({
    type: T.GET_PRODUCTAUDIT_RATELIST,
    payload: params
});

export const getProductStatus = () => ({
    type: T.GET_PRODUCT_STATUS,
    payload: {}
});

export const getProductUsername = () => ({
    type: T.GET_PRODUCT_USERNAME,
    payload: {}
});

export const getProductBusinessType = () => ({
    type: T.GET_PRODUCT_BUSINESSTYPE,
    payload: {}
});

export const getProductLabelSele = () => ({
    type: T.GET_PRODUCT_LABELSELE,
    payload: {}
});

export const getProductClassifySele = ({ categoryGrade, parentId }) => ({
    type: T.GET_PRODUCT_CLASSIFYSELE,
    payload: {
        categoryGrade, parentId
    }
});

export const getProductAuditDetail = (id) => ({
    type: T.GET_PRODUCTAUDIT_DETAIL,
    payload: { id }
});

export const getProductAuditRateDetail = (goodsId) => ({
    type: T.GET_PRODUCTAUDIT_RATEDETAIL,
    payload: { goodsId }
});

export const getProductAuditLog = ({ goodsId, pageNum, pageSize }) => ({
    type: T.GET_PRODUCT_AUDITLOG,
    payload: { goodsId, pageNum, pageSize }
});

export const getProductRateAuditLog = ({ goodsId, pageNum, pageSize }) => ({
    type: T.GET_PRODUCT_RATEAUDITLOG,
    payload: { goodsId, pageNum, pageSize }
});