/**
 * 【仓库列表】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import {
    //getDealingUnitSelectSource, 
    getStateSelectSource,
    getPlatformReconciliationTableSource
} from '@/reducers/storehousefinancialmanage/platformreconciliation/actions';
import AdvertTable from './components/AdvertTable';
import ConfirBill from './modal/ConfirBill';


class Main extends React.Component {

    state = {
        status: {},
        reconciliationId: undefined,
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getStateSelectSource();
        this.props.getPlatformReconciliationTableSource({ pageSize: 1, pageNum: 15 });
    }
    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }
    // 查询
    _searchHandle = () => {
        const { reconciliationId, status } = this.state;
        this.searchCache = {

            status: status.code,
            reconciliationId,

            pageNum: 1,
            pageSize: 15
        };
        this.props.getPlatformReconciliationTableSource(this.searchCache);
    }

    // 重置
    _resetHandle = () => {
        this.setState({ reconciliationId: undefined, status: {}, pageNum: 1, pageSize: 15 });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    // 表格操作
    _tableAction = (id, type, item) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                addOneBreadcrumbPath({
                    title: '对账详情',
                    path: '/home/storehousefinancialmanage/platformreconciliation/detail/'
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/platformreconciliation/detail/${id}`
                });
                break;
            case 'confir':
                this.setState({ currentOpenMode: 'confir', openModeParam: item });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'confir':
                return <ConfirBill title='确认对账单' visible={true} closeMode={this._closeMode} data={this.state.openModeParam} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' });
        if (update) {
            this._searchHandle();
        }
    }

    render() {
        const { status, reconciliationId } = this.state;

        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.row1}>
                    <XInput
                        style={{ width: '248px' }}
                        inputStyle={{ width: '180px' }}
                        label='对账单号'
                        placeholder='请输入'
                        value={reconciliationId}
                        bindThis={this}
                        bindName='reconciliationId'
                    />
                    <XSelect
                        style={{ width: '270px', marginLeft: 10 }}
                        selectStyle={{ width: '200px' }}
                        label='状态'
                        placeholder='请选择'
                        renderData={this.props.stateSelectSource}
                        dataIndex='value'
                        keyIndex='code'
                        value={status.value}
                        bindThis={this}
                        bindName='status'
                    />
                    <XOKButton
                        style={{ width: '60px' }}
                        label='查询'
                        onClick={this._searchHandle}
                    />
                    <XCancelButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='重置'
                        onClick={this._resetHandle}
                    />
                </div>
                {/* <div className={styles.row2}>
                    <XCancelButton
                        style={{ width: '60px' }}
                        label='导出'
                    />
                </div> */}
                <AdvertTable renderData={this.props.platformReconciliationTableSource} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                {this._renderMode()}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    stateSelectSource: PropTypes.array,
    getStateSelectSource: PropTypes.object,

    platformReconciliationTableSource: PropTypes.object,
    getPlatformReconciliationTableSource: PropTypes.func
};

const mapStateToProps = (state) => ({
    stateSelectSource: state.platformreconciliation.stateSelectSource,
    platformReconciliationTableSource: state.platformreconciliation.platformReconciliationTableSource
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath, keepSecondNavBreadcrumb,
    getStateSelectSource,
    getPlatformReconciliationTableSource
})(Main);