import React from 'react';
import styles from './AdvertTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table, Badge } from 'antd';
import PropTypes from 'prop-types';

class AdvertTable extends React.Component {

    _takeColumns = () => ([
        {
            title: '档期',
            dataIndex: 'adName',
            key: 'adName',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '广告界面',
            dataIndex: 'adPageTitle',
            key: 'adPageTitle',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '推送策略',
            dataIndex: 'adStrategyName',
            key: 'adStrategyName',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '人气值总数',
            dataIndex: 'rewardTotal',
            key: 'rewardTotal',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '已送人气值',
            dataIndex: 'rewardGiven',
            key: 'rewardGiven',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '人气值余数',
            dataIndex: 'rewardLeft',
            key: 'rewardLeft',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '状态',
            dataIndex: 'stateName',
            key: 'stateName',
            width: '10%',
            align: 'left',
            render: (text) => (this._renderStatusCell(text))
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModify',
            key: 'gmtModify',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            width: '10%',
            render: (text, item) => this._renderActionCell(item.id, item.state)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    _renderStatusCell = (text) => {
        // var statusArr = ['WAITING', 'COMMIT', 'YES', 'FAIL', 'NO'];
        var statusText = ['待审核', '提交审核', '可用', '审核失败', '停用'];
        var statusBadge = ['default', 'processing', 'success', 'error', 'warning'];
        if (statusText.indexOf(text) !== -1) {
            var index = statusText.indexOf(text);
            return (
                <Badge status={statusBadge[index]} text={statusText[index]} />
            );
        } else {
            return null;
        }
    }
    _renderActionCell = (key, status) => {
        // status 'WAITING'= 待审核,  'COMMIT'=提交审核 'YES'=可用 'FAIL'=审核失败 'NO'=停用
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'info')}>详情</div>
                {status === 'WAITING' && <div className={styles.item} onClick={() => tableAction(key, 'del')}>删除</div>}
                {(status === 'WAITING' || status === 'FAIL') && <div className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>}
                {status === 'FAIL' && <div className={styles.item} onClick={() => tableAction(key, 'auditSubmit')}>提交审核</div>}
                {/* {(status === 'WAITING' || status === 'COMMIT') && <div className={styles.item} onClick={() => tableAction(key, 'audit')}>审核</div>} */}
                {status === 'YES' && <div className={styles.item} onClick={() => tableAction(key, 'stop')}>停用</div>}
                {status === 'NO' && <div className={styles.item} onClick={() => tableAction(key, 'start')}>启用</div>}
            </div>
        );
    }

    // 表格复选输入
    // _rowSelection = {
    //     onChange: (selectedRowKeys, selectedRows) => {
    //         console.log(selectedRows);
    //     }
    // };

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection} 
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default AdvertTable;