/**
 * 填写基础信息
 */
import React from 'react';
import styles from './step.module.scss';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import model from '@/reducers/systemmanage/financialsetting/model';
import { Form, Radio, Button, Input, message, Icon, DatePicker, Select } from 'antd';
import CustomUpload from '@/components/NewUpload';
import BankBranch from './../components/BankBranch';
import getMemberInfo from './../components/getMemberInfo';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

const Option = Select.Option;
const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 10 },
        sm: { span: 10 },
    },
};

const RadioGroup = Radio.Group;
class step extends React.Component {

    timeCount = 0;
    timeID = null;
    state = {
        loading: false, // 加载中
        st: '', // S1企业1证、S3企业3证、P个人 
        required: true,
        unitType: 'BUSINESS', // 商户类型：PERSON个人、BUSINESS企业
        authType: '2', // 认证类型：2一证、1三证
        companyName: '', // 单位名称
        companyAddress: '', // 注册地址
        taxNum: '', // 统一社会信用证编号
        taxUrl: [], // 统一社会信用证编号图片url
        taxTime: null, // 统一社会信用证编号有效期
        idNo: '', // 企业营业执照号
        idNoUrl: [], // 营业执照号图片url
        idNoTime: null, // 营业执照号有效期
        taxCert: '', // 税务登记号
        taxCertUrl: [], // 税务登记号图片url
        orgCode: '', // 组织机构代码
        orgCodeUrl: [], // 组织机构代码图片url
        phone: '', // 联系电话
        corpName: '', // 法人姓名
        mobile: '', // 法人手机
        legPerId: '', // 法人身份证号
        identity: { legPerIdFrontUrl: [], legPerIdBackUrl: [] }, // 身份证正反面
        bankCardNo: '', // 银行卡账号
        parentBankName: undefined, // 开户银行名称
        parentBankObj: { bankNo: '', bankName: '' }, // 开户银行  bankNo、bankName
        bankBranchObj: {},  // 开户行支行 bankNo、bankName
        unionBank: '', // 开户支行行号
        email: '', // 收件人邮箱地址
        invoiceAddress: '', // 收件人地址
        invoiceName: '', // 收票人姓名
        invoicePhone: '', // 收票人手机
        provice: undefined, // 分支银行所属城市省份
        areaName: undefined, // 分支银行所属城市名称
        branchInputBo: false, // 开户支行名称是否通过手动输入
        branchBankName: '', // 开户支行名称手动输入值
    }

    componentDidMount() {
        this.props.getYunstAuthTypeEnum();
        this.props.getBankNameList();
        this.props.getBankProviceList();
        this._showDiffIncome(this.state.unitType, this.state.authType);
    }

    componentWillUnmount() {
        clearInterval(this.timeID);
        this.timeID = null;
    }

    // 下一步
    _handleSubmit = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                if (this.state.st == 'S1') {
                    this.httpS1(fieldsValue);
                } else if (this.state.st == 'S3') {
                    this.httpS3(fieldsValue);
                } else {
                    message.error('无法识别商户类型或认证类型');
                }
            }
        });
    }

    // 一证请求
    httpS1 = (fieldsValue) => {
        const query = {};
        query.authType = fieldsValue.authType;
        query.companyName = fieldsValue.companyName;
        query.companyAddress = fieldsValue.companyAddress;
        query.taxNum = fieldsValue.taxNum;
        query.taxUrl = fieldsValue.taxUrl[0].url;
        query.taxTime = fieldsValue.taxTime ? moment(fieldsValue.taxTime).format('YYYY-MM-DD') + ' ' + '00:00:00' : null;
        query.phone = fieldsValue.phone;
        query.corpName = fieldsValue.corpName;
        query.mobile = fieldsValue.mobile;
        query.legPerId = fieldsValue.legPerId;
        query.legPerIdFrontUrl = fieldsValue.identity.legPerIdFrontUrl[0].url;
        query.legPerIdBackUrl = fieldsValue.identity.legPerIdBackUrl[0].url;
        query.bankCardNo = fieldsValue.bankCardNo.replace(/\s+/g, ''); // 去空格;
        query.parentBankName = fieldsValue.parentBankName;
        query.bankName = (this.state.branchInputBo ? fieldsValue.branchBankName : fieldsValue.bankBranchObj.bankName).replace(/\s+/g, ''); // 去空格;
        query.unionBank = fieldsValue.unionBank.replace(/\s+/g, ''); // 去空格;
        query.email = fieldsValue.email;
        query.invoiceAddress = fieldsValue.invoiceAddress;
        query.invoiceName = fieldsValue.invoiceName;
        query.invoicePhone = fieldsValue.invoicePhone;
        this.request('/company/info/yums/createCompanyAccount', query);
    }

    // 三证请求
    httpS3 = (fieldsValue) => {
        const query = {};
        query.authType = fieldsValue.authType;
        query.companyName = fieldsValue.companyName;
        query.companyAddress = fieldsValue.companyAddress;
        query.idNo = fieldsValue.idNo;
        query.idNoUrl = fieldsValue.idNoUrl[0].url;
        query.idNoTime = fieldsValue.idNoTime ? moment(fieldsValue.idNoTime).format('YYYY-MM-DD') + ' ' + '00:00:00' : null;
        query.orgCode = fieldsValue.orgCode;
        query.orgCodeUrl = fieldsValue.orgCodeUrl[0].url;
        query.taxCert = fieldsValue.taxCert;
        query.taxCertUrl = fieldsValue.taxCertUrl[0].url;
        query.phone = fieldsValue.phone;
        query.corpName = fieldsValue.corpName;
        query.mobile = fieldsValue.mobile;
        query.legPerId = fieldsValue.legPerId;
        query.legPerIdFrontUrl = fieldsValue.identity.legPerIdFrontUrl[0].url;
        query.legPerIdBackUrl = fieldsValue.identity.legPerIdBackUrl[0].url;
        query.bankCardNo = fieldsValue.bankCardNo.replace(/\s+/g, ''); // 去空格;
        query.parentBankName = fieldsValue.parentBankName;
        query.bankName = (this.state.branchInputBo ? fieldsValue.branchBankName : fieldsValue.bankBranchObj.bankName).replace(/\s+/g, ''); // 去空格;
        query.unionBank = fieldsValue.unionBank.replace(/\s+/g, ''); // 去空格;
        query.email = fieldsValue.email;
        query.invoiceAddress = fieldsValue.invoiceAddress;
        query.invoiceName = fieldsValue.invoiceName;
        query.invoicePhone = fieldsValue.invoicePhone;
        this.request('/company/info/yums/createCompanyAccount', query);
    }

    request = (url, query) => {
        this.setState({ loading: true });
        getMemberInfo().then((response) => {
            const { data } = response;
            const { status } = data;
            if (status == '1') {
                // 账号待审核
                this._checkStatus();
            } else if (status == '2') {
                // 账号已存在
                this.setState({ loading: false });
                message.success('保存成功');
                this.props.next();
            } else {
                // 创建通联账号
                http(url, query).then((response) => {
                    if (response.status == 200) {
                        this._checkStatus();
                    }
                }).catch((e) => {
                    this.setState({ loading: false });
                    message.error(e.message);
                });
            }
        });
    }

    // 检查是否有创建成功
    _checkStatus = () => {
        this.timeID = setInterval(() => {
            if (!this.timeID) return;
            getMemberInfo().then((response) => {
                const { type, data } = response;
                if (type == 'BUSINESS' && data.status == '2') {
                    this.setState({ loading: false });
                    message.success('保存成功');
                    this.props.next();
                    clearInterval(this.timeCount);
                    this.timeID = null;
                } else {
                    this.timeCount++;
                    if (this.timeCount > 5) {
                        this.setState({ loading: false });
                        this.timeCount = 0;
                        message.error('信息第三方认证超时，请稍后再试');
                        clearInterval(this.timeCount);
                        this.timeID = null;
                    }
                }
            });
        }, 2000);
    }

    // 上传图片校验-返回值
    _normFile = (e) => {
        for (let i = 0; i < e.fileList.length; i++) {
            const isType = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
            const isLt1M = parseInt(e.fileList[i].size) / 1024 / 1024 < 1;
            if (!isLt1M || !isType.includes(e.fileList[i].type)) {
                e.fileList.splice(i, 1);
                i--;
            }
        }
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    // 上传图片校验-提示
    beforeUpload = (file) => {
        const isType = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
        const isLt5M = file.size / 1024 / 1024 < 1;
        if (!isType.includes(file.type)) {
            message.error('只能上传png、jpg、gif格式的图片');
            file.status = 'error';
            return false;
        } else if (!isLt5M) {
            message.error('图片必须小于1MB');
            file.status = 'error';
            return false;
        } else {
            return isType && isLt5M;
        }
    }

    _showDiffIncome = (unitType, authType) => {
        if (unitType == 'BUSINESS' && authType == '1') {
            this.setState({ st: 'S3' });
        } else {
            this.setState({ st: 'S1' });
        }
    }

    render() {
        const { loading, st, required, unitType, authType, companyName, companyAddress, taxNum, taxUrl, taxTime,
            phone, corpName, mobile, legPerId, identity, bankCardNo, parentBankName, bankBranchObj,
            unionBank, email, invoiceAddress, invoiceName, invoicePhone,
            idNo, idNoUrl, idNoTime, taxCert, taxCertUrl, orgCode, orgCodeUrl, branchInputBo, branchBankName
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { yunstAuthTypeEnum, bankNameList, bankProviceList, bankAreaList } = this.props;

        return (
            <div className={styles.step1}>
                <Form autoComplete='off' {...formItemLayout} onSubmit={this._handleSubmit}>
                    {st != 'P' &&
                        <Form.Item
                            colon={false}
                            htmlFor='authType'
                            label={<span>认证类型：</span>}
                        >
                            {getFieldDecorator('authType', {
                                initialValue: authType,
                                rules: [{ required, message: '请选择认证类型' }],
                            })(<RadioGroup onChange={(e) => {
                                this.setState({ authType: e.target.value });
                                this._showDiffIncome(unitType, e.target.value);
                            }}>
                                {yunstAuthTypeEnum.map((item) => (<Radio key={item.code} value={item.code}>{item.value}</Radio>))}
                            </RadioGroup>)}
                            <div className={styles.desp}>一证：统一社会信用</div>
                            <div className={styles.desp}>三证：营业执照号、组织机构代码、税务登记证</div>
                        </Form.Item>
                    }
                    <Form.Item
                        colon={false}
                        htmlFor='companyName'
                        label={<span>单位名称：</span>}
                    >
                        {getFieldDecorator('companyName', {
                            rules: [{ required, message: '请输入单位名称' }],
                            initialValue: companyName
                        })(<Input placeholder='请输入单位名称' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='companyAddress'
                        label={<span>注册地址：</span>}
                    >
                        {getFieldDecorator('companyAddress', {
                            rules: [{ required, message: '请输入注册地址' }],
                            initialValue: companyAddress
                        })(<Input placeholder='请输入注册地址' />)}
                    </Form.Item>
                    {st == 'S1' &&
                        <Form.Item
                            colon={false}
                            htmlFor='taxNum'
                            label={<span>统一社会信用证编号：</span>}
                        >
                            {getFieldDecorator('taxNum', {
                                rules: [
                                    { required, message: '请输入统一社会信用证编号' },
                                    { pattern: /[A-Z0-9]{18}/g, message: '请输入18位数字加大写字母的统一社会信用证编号' },
                                ],
                                initialValue: taxNum
                            })(<Input placeholder='请输入18位数字加大写字母的统一社会信用证编号' />)}
                        </Form.Item>
                    }
                    {st == 'S1' &&
                        <Form.Item
                            colon={false}
                            htmlFor='taxUrl'
                            label={<span>统一社会信用证：</span>}
                        >
                            <div className={styles.upload}>
                                {getFieldDecorator('taxUrl', {
                                    getValueFromEvent: this._normFile,
                                    rules: [
                                        { type: 'array', required, message: '请上传统一社会信用证' },
                                    ],
                                    initialValue: taxUrl
                                })(
                                    <CustomUpload
                                        accept='.png, .jpg, .jpeg'
                                        fileList={taxUrl}
                                        listType="picture-card"
                                        wallMode="single"
                                        removeError
                                        qiniuUploadProps={{
                                            uploadType: 'image'
                                        }}
                                        onChange={(info) => {
                                            const taxUrl = info.fileList;
                                            taxUrl.length == '0' && this.props.form.resetFields('taxUrl', []);
                                            this.setState({ taxUrl });
                                        }}
                                        beforeUpload={this.beforeUpload}
                                    >
                                        <div>
                                            <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                            <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                            <div style={{ color: '#999999', fontSize: '12px' }}>(信用证)</div>
                                        </div>
                                    </CustomUpload>
                                )}
                                <span className={styles.desp}>每次仅支持上传一张图片，大小不超过1M</span>
                            </div>
                        </Form.Item>
                    }
                    {st == 'S1' &&
                        <Form.Item
                            colon={false}
                            htmlFor='taxTime'
                            label={<span>证照有效截止日期：</span>}
                        >
                            {getFieldDecorator('taxTime', {
                                rules: [
                                    {
                                        validator: (rule, value = {}, callback) => {
                                            const selectTime = moment(value).valueOf();
                                            const nowTime = new Date().getTime();
                                            const restTime = selectTime - nowTime;
                                            if (restTime != 0 && (restTime < 0 || Math.round(restTime / 1000 / 60 / 60 / 24) < 60)) {
                                                callback('证照有效截止日期需大于60天，如为长期或无可不填写');
                                            } else {
                                                callback();
                                            }
                                        }
                                    }
                                ],
                                initialValue: taxTime
                            })(
                                <DatePicker
                                    style={{ width: 392 }}
                                    placeholder='证照有效截止日期需大于60天，如为长期或无可不填写'
                                    showTime={false}
                                />
                            )}
                        </Form.Item>
                    }
                    {st != 'S1' &&
                        <Form.Item
                            colon={false}
                            htmlFor='idNo'
                            label={<span>营业执照号：</span>}
                        >
                            {getFieldDecorator('idNo', {
                                rules: [
                                    { required, message: '请输入营业执照号' },
                                    { pattern: /^[a-zA-Z0-9]{0,50}$/, message: '最大长度不能超过50位' },
                                ],
                                initialValue: idNo
                            })(<Input placeholder='请输入营业执照号' />)}
                        </Form.Item>
                    }
                    {st == 'S3' &&
                        <Form.Item
                            colon={false}
                            htmlFor='idNoUrl'
                            label={<span>营业执照：</span>}
                        >
                            <div className={styles.upload}>
                                {getFieldDecorator('idNoUrl', {
                                    getValueFromEvent: this._normFile,
                                    initialValue: idNoUrl,
                                    rules: [
                                        { type: 'array', required, message: '请上传营业执照' },
                                    ],
                                })(
                                    <CustomUpload
                                        accept='.png, .jpg, .jpeg'
                                        fileList={idNoUrl}
                                        listType="picture-card"
                                        wallMode="single"
                                        removeError
                                        qiniuUploadProps={{
                                            uploadType: 'image'
                                        }}
                                        onChange={(info) => {
                                            const idNoUrl = info.fileList;
                                            idNoUrl.length == '0' && this.props.form.resetFields('idNoUrl', []);
                                            this.setState({ idNoUrl });
                                        }}
                                        beforeUpload={this.beforeUpload}
                                    >
                                        <div>
                                            <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                            <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                            <div style={{ color: '#999999', fontSize: '12px' }}>(营业执照)</div>
                                        </div>
                                    </CustomUpload>
                                )}
                                <span className={styles.desp}>每次仅支持上传一张图片，大小不超过1M</span>
                            </div>
                        </Form.Item>
                    }
                    {st == 'S3' &&
                        <Form.Item
                            colon={false}
                            htmlFor='idNoTime'
                            label={<span>营业执照有效截止日期：</span>}
                        >
                            {getFieldDecorator('idNoTime', {
                                rules: [
                                    {
                                        validator: (rule, value = {}, callback) => {
                                            const selectTime = moment(value).valueOf();
                                            const nowTime = new Date().getTime();
                                            const restTime = selectTime - nowTime;
                                            if (restTime != 0 && (restTime < 0 || Math.round(restTime / 1000 / 60 / 60 / 24) < 60)) {
                                                callback('营业执照有效截止日期需大于60天，如为长期或无可不填');
                                            } else {
                                                callback();
                                            }
                                        }
                                    }
                                ],
                                initialValue: idNoTime
                            })(
                                <DatePicker
                                    style={{ width: 392 }}
                                    placeholder='营业执照有效截止日期需大于60天，如为长期或无可不填'
                                    showTime={false}
                                />
                            )}
                        </Form.Item>
                    }
                    {st == 'S3' &&
                        <Form.Item
                            colon={false}
                            htmlFor='orgCode'
                            label={<span>组织机构代码：</span>}
                        >
                            {getFieldDecorator('orgCode', {
                                rules: [
                                    { required, message: '请输入组织机构代码' },
                                    { pattern: /^[a-zA-Z0-9]{0,50}$/, message: '最大长度不能超过50位' },
                                ],
                                initialValue: orgCode
                            })(<Input placeholder='请输入组织机构代码' />)}
                        </Form.Item>
                    }
                    {st == 'S3' &&
                        <Form.Item
                            colon={false}
                            htmlFor='orgCodeUrl'
                            label={<span>组织机构代码证：</span>}
                        >
                            <div className={styles.upload}>
                                {getFieldDecorator('orgCodeUrl', {
                                    getValueFromEvent: this._normFile,
                                    initialValue: orgCodeUrl,
                                    rules: [
                                        { type: 'array', required, message: '请上传组织机构代码证' },
                                    ],
                                })(
                                    <CustomUpload
                                        accept='.png, .jpg, .jpeg'
                                        fileList={orgCodeUrl}
                                        listType="picture-card"
                                        wallMode="single"
                                        removeError
                                        qiniuUploadProps={{
                                            uploadType: 'image'
                                        }}
                                        onChange={(info) => {
                                            const orgCodeUrl = info.fileList;
                                            orgCodeUrl.length == '0' && this.props.form.resetFields('orgCodeUrl', []);
                                            this.setState({ orgCodeUrl });
                                        }}
                                        beforeUpload={this.beforeUpload}
                                    >
                                        <div>
                                            <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                            <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                            <div style={{ color: '#999999', fontSize: '12px' }}>(代码证)</div>
                                        </div>
                                    </CustomUpload>
                                )}
                                <span className={styles.desp}>每次仅支持上传一张图片，大小不超过1M</span>
                            </div>
                        </Form.Item>
                    }
                    {st == 'S3' &&
                        <Form.Item
                            colon={false}
                            htmlFor='taxCert'
                            label={<span>税务登记证号：</span>}
                        >
                            {getFieldDecorator('taxCert', {
                                rules: [
                                    { required, message: '请输入税务登记证号' },
                                    { pattern: /^[a-zA-Z0-9]{0,50}$/, message: '最大长度不能超过50位' },
                                ],
                                initialValue: taxCert
                            })(<Input placeholder='请输入税务登记证号' />)}
                        </Form.Item>
                    }
                    {st == 'S3' &&
                        <Form.Item
                            colon={false}
                            htmlFor='taxCertUrl'
                            label={<span>税务登记证：</span>}
                        >
                            <div className={styles.upload}>
                                {getFieldDecorator('taxCertUrl', {
                                    getValueFromEvent: this._normFile,
                                    initialValue: taxCertUrl,
                                    rules: [
                                        { type: 'array', required, message: '请上传税务登记证' },
                                    ],
                                })(
                                    <CustomUpload
                                        accept='.png, .jpg, .jpeg'
                                        fileList={taxCertUrl}
                                        listType="picture-card"
                                        wallMode="single"
                                        removeError
                                        qiniuUploadProps={{
                                            uploadType: 'image'
                                        }}
                                        onChange={(info) => {
                                            const taxCertUrl = info.fileList;
                                            taxCertUrl.length == '0' && this.props.form.resetFields('taxCertUrl', []);
                                            this.setState({ taxCertUrl });
                                        }}
                                        beforeUpload={this.beforeUpload}
                                    >
                                        <div>
                                            <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                            <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                            <div style={{ color: '#999999', fontSize: '12px' }}>(税务登记证)</div>
                                        </div>
                                    </CustomUpload>
                                )}
                                <span className={styles.desp}>每次仅支持上传一张图片，大小不超过1M</span>
                            </div>
                        </Form.Item>
                    }
                    <Form.Item
                        colon={false}
                        htmlFor='phone'
                        label={<span>联系电话：</span>}
                    >
                        {getFieldDecorator('phone', {
                            initialValue: phone,
                            rules: [
                                { required, message: '请输入固话(区号+号码，如010-1234567)或手机号码' },
                                {
                                    validator: (rule, value = '', callback) => {
                                        var RegExp = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/;
                                        if (value == '') {
                                            callback();
                                        } else if (RegExp.test(value) == false) {
                                            callback('请输入正确的固话(区号+号码，如010-1234567)或手机号码');
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ]
                        })(<Input placeholder='请输入固话(区号+号码，如010-1234567)或手机号码' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='corpName'
                        label={<span>法人姓名：</span>}
                    >
                        {getFieldDecorator('corpName', {
                            rules: [
                                { required, message: '请输入2-6位姓名(仅支持中文)' },
                                { pattern: /^[\u4e00-\u9fa5]{2,6}$/, message: '请输入正确的2-6位姓名(仅支持中文)' },
                            ],
                            initialValue: corpName
                        })(<Input placeholder='请输入2-6位姓名(仅支持中文)' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='mobile'
                        label={<span>法人手机：</span>}
                    >
                        {getFieldDecorator('mobile', {
                            rules: [
                                { required, message: '请输入11位法人手机号码' },
                                { pattern: /^[1-9]\d{10}$/, message: '请输入正确的手机号码' },
                            ],
                            initialValue: mobile
                        })(<Input placeholder='请输入11位法人手机号码' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='legPerId'
                        label={<span>法人身份证号：</span>}
                    >
                        {getFieldDecorator('legPerId', {
                            rules: [
                                { required, message: '请输入法人身份证号' },
                                { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证号' },
                            ],
                            initialValue: legPerId
                        })(<Input placeholder='请输入法人身份证号' />)}
                    </Form.Item>
                    {st != 'P' &&
                        <Form.Item
                            colon={false}
                            label={<span>身份证：</span>}
                        >
                            <div className={styles.upload}>
                                {getFieldDecorator('identity', {
                                    initialValue: identity,
                                    rules: [
                                        { required, message: '请上传身份证照片' },
                                        {
                                            validator: (rule, value = {}, callback) => {
                                                if (!required) {
                                                    callback();
                                                }
                                                let identityField = this.props.form.getFieldsValue().identity;
                                                if (identityField.legPerIdFrontUrl.length == 0) {
                                                    callback('请上传身份证人像面');
                                                } else if (identityField.legPerIdBackUrl.length == 0) {
                                                    callback('请上传身份证国徽面');
                                                } else {
                                                    callback();
                                                }
                                            }
                                        }
                                    ],
                                    getValueFromEvent: () => {
                                        return this.props.form.getFieldsValue().identity;
                                    }
                                })(
                                    <div>
                                        <CustomUpload
                                            accept='.png, .jpg, .jpeg'
                                            fileList={identity.legPerIdFrontUrl}
                                            listType="picture-card"
                                            wallMode="single"
                                            removeError
                                            qiniuUploadProps={{
                                                uploadType: 'image'
                                            }}
                                            onChange={(info) => {
                                                let identityField = this.props.form.getFieldsValue().identity;
                                                const legPerIdFrontUrl = info.fileList;
                                                if (legPerIdFrontUrl.length == '0') {
                                                    identityField = { ...identityField, legPerIdFrontUrl: [] };
                                                } else {
                                                    identityField = { ...identityField, legPerIdFrontUrl };
                                                }
                                                this.props.form.setFieldsValue({ identity: identityField });
                                                this.setState({ identity: identityField });
                                            }}
                                            beforeUpload={this.beforeUpload}
                                        >
                                            <div>
                                                <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                                <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                                <div style={{ color: '#999999', fontSize: '12px' }}>(人像面)</div>
                                            </div>
                                        </CustomUpload>
                                        <CustomUpload
                                            accept='.png, .jpg, .jpeg'
                                            fileList={identity.legPerIdBackUrl}
                                            listType="picture-card"
                                            wallMode="single"
                                            removeError
                                            qiniuUploadProps={{
                                                uploadType: 'image'
                                            }}
                                            onChange={(info) => {
                                                let identityField = this.props.form.getFieldsValue().identity;
                                                const legPerIdBackUrl = info.fileList;
                                                if (legPerIdBackUrl.length == '0') {
                                                    identityField = { ...identityField, legPerIdBackUrl: [] };
                                                } else {
                                                    identityField = { ...identityField, legPerIdBackUrl };
                                                }
                                                this.props.form.setFieldsValue({ identity: identityField });
                                                this.setState({ identity: identityField });
                                            }}
                                            beforeUpload={this.beforeUpload}
                                        >
                                            <div>
                                                <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                                <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                                <div style={{ color: '#999999', fontSize: '12px' }}>(国徽面)</div>
                                            </div>
                                        </CustomUpload>
                                        <div className={styles.desp}>每次仅支持上传一张图片，大小不超过1M</div>
                                    </div>
                                )}
                            </div>
                        </Form.Item>
                    }
                    {st != 'P' &&
                        <Form.Item
                            colon={false}
                            htmlFor='bankCardNo'
                            label={<span>银行账号：</span>}
                        >
                            {getFieldDecorator('bankCardNo', {
                                rules: [
                                    { required, message: '请输入24位或以下数字组成的银行账号(企业对公账号)' },
                                    {
                                        validator: (rule, value = '', callback) => {
                                            var pattern = /^\d{0,24}$/;
                                            // 去除空格
                                            var str = value.replace(/\s+/g, '');
                                            if (pattern.test(str)) {
                                                callback();
                                            } else {
                                                callback('请输入24位或以下数字组成的银行账号(企业对公账号)');
                                            }
                                        }
                                    }
                                ],
                                initialValue: bankCardNo
                            })(<Input placeholder='请输入24位或以下数字组成的银行账号(企业对公账号)' />)}
                        </Form.Item>
                    }
                    {st != 'P' &&
                        <Form.Item
                            colon={false}
                            htmlFor='parentBankName'
                            label={<span>开户银行：</span>}
                        >
                            {getFieldDecorator('parentBankName', {
                                rules: [
                                    { required, message: '请选择开户银行' },
                                ],
                                initialValue: parentBankName
                            })(
                                <Select
                                    placeholder="请选择开户银行"
                                    showSearch
                                    onChange={(value, e) => {
                                        const newParentBankObj = { ...this.state.parentBankObj, bankNo: e.props.bankNo, bankName: value };
                                        this.setState({ parentBankObj: newParentBankObj });
                                    }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {bankNameList && bankNameList.map((item, index) => {
                                        return <Option key={index} bankNo={item.bankNo} value={item.bankName}>{item.bankName}</Option>;
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    }
                    {st != 'P' &&
                        <Form.Item
                            colon={false}
                            label={<span>省份/城市：</span>}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Select
                                    style={{ marginRight: '10px' }}
                                    placeholder="省份"
                                    showSearch
                                    value={this.state.provice}
                                    onChange={(value) => {
                                        const newParentBankObj = { ...this.state.parentBankObj, areaCode: '' };
                                        this.setState({ parentBankObj: newParentBankObj, provice: value, areaName: '' });
                                        this.props.getBankAreaList({ provice: value });
                                    }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {bankProviceList && bankProviceList.map((item, index) => {
                                        return <Option key={index} value={item.province}>{item.province}</Option>;
                                    })}
                                </Select>
                                <Select
                                    placeholder="城市"
                                    showSearch
                                    value={this.state.areaName}
                                    onChange={(value, e) => {
                                        const newParentBankObj = { ...this.state.parentBankObj, areaCode: e.props.areaCode };
                                        this.setState({ parentBankObj: newParentBankObj, areaName: value });
                                    }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {bankAreaList && bankAreaList.map((item, index) => {
                                        return <Option key={index} areaCode={item.areaCode} value={item.areaName}>{item.areaName}</Option>;
                                    })}
                                </Select>
                            </div>
                        </Form.Item>
                    }
                    {(st != 'P' && !branchInputBo) &&
                        <Form.Item
                            colon={false}
                            htmlFor='bankBranchObj'
                            label={<span>开户行支行名称：</span>}
                        >
                            {getFieldDecorator('bankBranchObj', {
                                rules: [
                                    { required, message: '请选择开户行支行名称' },
                                    {
                                        validator: (rule, value = '', callback) => {
                                            if (value.bankName) {
                                                callback();
                                            } else {
                                                callback('请选择开户行支行名称');
                                            }
                                        }
                                    }
                                ],
                                initialValue: bankBranchObj,
                                getValueFromEvent: (value, item) => {
                                    return { bankNo: item.key, bankName: value };
                                }
                            })(
                                <BankBranch
                                    placeholder="开户行支行名称"
                                    showSearch
                                    parentBankObj={this.state.parentBankObj}
                                    onChange={(value, item) => {
                                        this.setState({ bankBranchObj: { bankNo: item.key, bankName: value }, unionBank: item.key });
                                        this.props.form.setFieldsValue({ unionBank: item.key });
                                    }}
                                />
                            )}
                            <div
                                style={{ color: '#3094FB', lineHeight: '15px', cursor: 'pointer', width: 'fit-content' }}
                                onClick={() => {
                                    this.setState({ branchInputBo: true, unionBank: '' });
                                    this.props.form.setFieldsValue({ unionBank: '' });
                                }}>手动输入支行信息</div>
                        </Form.Item>
                    }
                    {(st != 'P' && branchInputBo) &&
                        <Form.Item
                            colon={false}
                            htmlFor='branchBankName'
                            label={<span>开户行支行名称：</span>}
                        >
                            {getFieldDecorator('branchBankName', {
                                rules: [
                                    { required, message: '请输入开户行支行名称' },
                                ],
                                initialValue: branchBankName
                            })(<Input placeholder='开户行支行名称' />)}
                            <div
                                style={{ color: '#3094FB', lineHeight: '15px', cursor: 'pointer', width: 'fit-content' }}
                                onClick={() => {
                                    this.setState({ branchInputBo: false, unionBank: '', bankBranchObj: {} });
                                    this.props.form.setFieldsValue({ unionBank: '', bankBranchObj: {} });
                                }}>选择支行信息</div>
                        </Form.Item>
                    }
                    {st != 'P' &&
                        <Form.Item
                            colon={false}
                            htmlFor='unionBank'
                            label={<span>开户支行行号：</span>}
                        >
                            {getFieldDecorator('unionBank', {
                                rules: [
                                    { required, message: '请输入开户支行行号' },
                                ],
                                initialValue: unionBank
                            })(<Input disabled={!branchInputBo} placeholder='开户支行行号' />)}
                        </Form.Item>
                    }
                    <Form.Item
                        colon={false}
                        htmlFor='email'
                        label={<span>收件人邮箱地址：</span>}
                    >
                        {getFieldDecorator('email', {
                            rules: [
                                { required, message: '请输入收件人邮箱地址' },
                                { pattern: /^[0-9a-zA-Z._-]+[@][0-9a-zA-Z._-]+([.][a-zA-Z]+){1,2}$/, message: '请输入正确的邮箱地址' },
                            ],
                            initialValue: email
                        })(<Input placeholder='请输入收件人邮箱地址' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='invoiceAddress'
                        label={<span>收件人地址：</span>}
                    >
                        {getFieldDecorator('invoiceAddress', {
                            rules: [
                                { required, message: '请输入收件人地址' },
                                { pattern: /^[^\s]*$/, message: '禁止输入空格' }
                            ],
                            initialValue: invoiceAddress
                        })(<Input placeholder='请输入收件人地址' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='invoiceName'
                        label={<span>收票人姓名：</span>}
                    >
                        {getFieldDecorator('invoiceName', {
                            rules: [
                                { required, message: '请输入2-6位姓名(仅支持中文)' },
                                { pattern: /^[\u4e00-\u9fa5]{2,6}$/, message: '请输入正确的2-6位姓名(仅支持中文)' },
                            ],
                            initialValue: invoiceName
                        })(<Input placeholder='请输入2-6位姓名(仅支持中文)' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='invoicePhone'
                        label={<span>收票人手机：</span>}
                    >
                        {getFieldDecorator('invoicePhone', {
                            rules: [
                                { required, message: '请输入11位收票人手机号码' },
                                { pattern: /^[1-9]\d{10}$/, message: '请输入正确的手机号码' },
                            ],
                            initialValue: invoicePhone
                        })(<Input placeholder='请输入11位收票人手机号码' />)}
                    </Form.Item>
                    <div className={styles.btn}>
                        <Button type="primary" htmlType="submit" loading={loading}>下一步</Button>
                    </div>
                </Form>
            </div>
        );
    }
}

step.propTypes = {
    onChange: PropTypes.func, // 商户类型切换
    next: PropTypes.func, // 下一步
    form: PropTypes.object, // 表单校验
    getYunstAuthTypeEnum: PropTypes.func,
    yunstAuthTypeEnum: PropTypes.array,
    getBankNameList: PropTypes.func,
    bankNameList: PropTypes.array,
    getBankProviceList: PropTypes.func,
    bankProviceList: PropTypes.array,
    getBankAreaList: PropTypes.func,
    bankAreaList: PropTypes.array
};
const stepForm = Form.create({ name: 'step-form' })(step);
const mapStateToProps = (state) => ({
    yunstAuthTypeEnum: state.financialsetting.getYunstAuthTypeEnum,
    bankNameList: state.financialsetting.getBankNameList,
    bankProviceList: state.financialsetting.getBankProviceList,
    bankAreaList: state.financialsetting.getBankAreaList
});

export default connect(mapStateToProps, { ...model.actions })(stepForm);

