import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import DailySaid from './DailySaid';
import Channel from './Channel';
import Client from './Client';
import { removeLocalStorage, getLocalStorage } from '@/assets/js/storage';

const PayOrder = ({ history, match }) => {

    const tabList = [
        { id: 'client', label: 'C端用户' },
        { id: 'dailySaid', label: '说客/服务商' },
        { id: 'channel', label: '渠道' },
    ];

    const [currentId, setCurrentId] = useState('client');

    useEffect(() => {
        if (getLocalStorage('payOrderTabId') == 2) {
            setCurrentId('dailySaid');
        } else if (getLocalStorage('payOrderTabId') == 3) {
            setCurrentId('channel');
        } else {
            setCurrentId('client');
        }
        removeLocalStorage('payOrderTabId');
    }, []);

    const components = {
        dailySaid: <DailySaid history={history} />,
        channel: <Channel history={history} />,
        client: <Client history={history} match={match} />,
    };

    return <div className={styles.flexBoxContainer}>
        <XTabNav renderData={tabList} onChange={tabId => setCurrentId(tabId)} activedID={currentId} />
        <div className={styles.flexBoxContainer} style={{ marginTop: '10px' }}>{components[currentId]}</div>
    </div>;
};
PayOrder.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
};
export default PayOrder;