import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount, XTableHOC } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
    };

    _takeColumns = () => ([
        {
            title: '商户',
            dataIndex: 'targetName',
            key: 'targetName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '交易订单号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: cellWidth.normal,
            align: 'center',
            render: (text, item) => (this._renderTextDetail(text, item))
        },
        {
            title: '业务日期',
            dataIndex: 'finishTime',
            key: 'finishTime',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '付款日期',
            dataIndex: 'payTime',
            key: 'payTime',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '货号',
            dataIndex: 'itemNo',
            key: 'itemNo',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '数量',
            dataIndex: 'goodsNumber',
            key: 'goodsNumber',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '单价(元)',
            dataIndex: 'retailPrice',
            key: 'retailPrice',
            width: cellWidth.small,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '金额(元)',
            dataIndex: 'totalFee',
            key: 'totalFee',
            width: cellWidth.small,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '优惠金额(元)',
            dataIndex: 'discount',
            key: 'discount',
            width: cellWidth.small,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '人气值',
            dataIndex: 'starDiscount',
            key: 'starDiscount',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '实付金额(元)',
            dataIndex: 'realPrice',
            key: 'realPrice',
            width: cellWidth.small,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '付款渠道',
            dataIndex: 'payType',
            key: 'payType',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结算单价(元)',
            dataIndex: 'settlePrice',
            key: 'settlePrice',
            width: cellWidth.small,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '结算金额(元)',
            dataIndex: 'settleSumPrice',
            key: 'settleSumPrice',
            width: cellWidth.small,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        // {
        //     title: '供应商',
        //     dataIndex: 'c16',
        //     key: 'c16',
        //     width: 140,
        //     align: 'center',
        //     render: (text) => (this._renderTextCell(text))
        // },
        {
            title: '技术服务费(元)',
            dataIndex: 'serviceFee',
            key: 'serviceFee',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '平台服务费(元)',
            dataIndex: 'distributionServiceFee',
            key: 'distributionServiceFee',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '秀场服务费(元)',
            dataIndex: 'showServiceFee',
            key: 'showServiceFee',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '心选推荐费(元)',
            dataIndex: 'heartSelectionRecommendationFee',
            key: 'heartSelectionRecommendationFee',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '免单均摊金额(元)',
            dataIndex: 'freeOfCharge',
            key: 'freeOfCharge',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '营销服务费(元)',
            dataIndex: 'marketServiceFee',
            key: 'marketServiceFee',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            width: cellWidth.normal,
            // fixed: 'right',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '服务单号',
            dataIndex: 'bizId',
            key: 'bizId',
            align: 'center',
            width: cellWidth.normal,
            // fixed: 'right',
            render: (text, item) => (this._renderActionDetailCell(text, item.bizType))
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: cellWidth.normal,
            fixed: 'right',
            render: (text, item) => this._renderActionCell(item.orderId)
        }
    ]);
    // 订单详情
    _renderTextDetail = (text, item) => {
        return <a onClick={() => this.onDetail(item, history, addOneBreadcrumbPath)}>{text}</a>;
    }
    // 服务单号
    saleDetail = (id, salesType) => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '售后详情',
            path: `/home/salemanage/salelist/contentInfo/${id}/${salesType}`
        });
        history.push({
            pathname: `/home/salemanage/salelist/contentInfo/${id}/${salesType}`
        });
    }
    _renderActionDetailCell = (id, salesType) => {
        return (
            <div className={styles.action}>
                <a className={styles.item} onClick={() => this.saleDetail(id, salesType)}>{id}</a>
            </div>
        );
    }
    _renderActionCell = (orderId) => {
        return (
            <div className={styles.action}>
                <a className={styles.item} onClick={() => this.costDetail(orderId)}>费用明细</a>
            </div>
        );
    }
    costDetail = (orderId) => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '费用明细',
            path: `/home/ordermanage/transaction/costDetail/${orderId}`
        });
        history.push({
            pathname: `/home/ordermanage/transaction/costDetail/${orderId}`,
        });
    }
    onDetail = (record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '查看详情',
            path: {
                pathname: '/home/ordermanage/productorder/orderdetail',
                search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}&&transaction=true`,
            }
        });
        history.push({
            pathname: '/home/ordermanage/productorder/orderdetail',
            search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}&&transaction=true`,
        });
    }
    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderNumberCell = (text) => {
        const price = parseInt(text) / 100;
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {price}
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        // this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string
};
// export default connect(null, { addOneBreadcrumbPath })(TableContent);
export default XTableHOC(connect(null, { addOneBreadcrumbPath })(TableContent));