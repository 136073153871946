import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

const TableContent = ({ tableAction, renderData, paginationChange }) => {

    const onTableAction = (id, { auditStateCode = '' }) => {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, 'detail')}>查看详情</div>
                {
                    auditStateCode == 'SUBMIT_REVIEW' && <div className={styles.item} onClick={() => tableAction(id, 'audit')}>审核</div>
                }
            </div>
        );
    };

    const _columns = () => [
        {
            title: '申请单号',
            dataIndex: 'orderId',
            key: 'orderId',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '企业名称',
            dataIndex: 'businessCompanyName',
            key: 'businessCompanyName',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '主账号手机',
            dataIndex: 'mainTel',
            key: 'mainTel',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '开通数量',
            dataIndex: 'applyAnchorNum',
            key: 'applyAnchorNum',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '费用',
            dataIndex: 'payFee',
            key: 'payFee',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '支付方式',
            dataIndex: 'payType',
            key: 'payType',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'auditState',
            key: 'auditState',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: '12%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作人',
            dataIndex: 'auditName',
            key: 'auditName',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '10%',
            render: onTableAction
        }
    ];

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                columns={_columns()}
                dataSource={renderData.dataList}
                rowKey={record => record.id}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange} />
        </div>
    );

};

TableContent.propTypes = {
    renderData: PropTypes.object, // 渲染数据源
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func,//分页操作
};
export default TableContent;