/**
 *  合同管理 - 合同模板
 * */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, Select } from 'antd';
import http from '@/assets/api/http';
import { message } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import UploadModal from '../../modal/UploadModal';
import model from '@/reducers/contractmanage/contracttemplate/model';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';
import { XInput, XOKButton, XCancelButton, XPagination, XTabNav, showConfirm } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import ButtonPerssion from '@/routes/ButtonPerssion';

const { Option } = Select;
class Contract extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
        pageNum: 1,
        pageSize: 10,
        code: undefined,
    }
    componentDidMount() {
        this.props.getContractName();
    }
    // 查询触发
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, code } = this.state;
        this.searchCache = {
            code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getSelectContractList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _saleresetHandle = () => {
        this.setState({ pageNum: 1, pageSize: 10, code: undefined }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建按钮被点击
    _newUploadHandle = () => {
        this.setState({ currentOpenMode: 'upload' });
    }

    // 表格操作功能回调
    _actionClickHandle = (id, type, value, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        var param = {};
        switch (type) {
            case 'switch': //状态 
                param = {
                    id
                };
                this.updateContent(type, value, param);
                break;
            case 'merchant': //已签署商户 
                addOneBreadcrumbPath({
                    title: '已签署商户',
                    path: {
                        pathname: '/home/contractmanagement/contracttemplate/signedmerchant',
                        search: `?id=${id}`,
                    }
                });
                history.push({
                    pathname: '/home/contractmanagement/contracttemplate/signedmerchant',
                    search: `?id=${id}`,
                });
                break;
            case 'check': //查看模板
                history.push({
                    pathname: '/home/contractmanagement/contracttemplate/checkContract',
                    search: `?url=${record.url}`,
                });
                break;
        }

    }
    updateContent = (type, value, param) => {
        http('/contract/fadada/disable', param, 'POST')
            .then((res) => {
                message.success('操作成功!');
                this._searchHandle('useCache');
            }).catch((reject) => {
                message.error(reject.message);
            });
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'upload':
                return <UploadModal title='上传模板' visible={true} closeMode={this._closeMode} refresh={() => this._searchHandle('useCache')} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }
    onChange = e => {
        this.setState({ code: e });
    }

    render() {
        const { SelectContractList: { dataList = [], resultTotal, pageSize, pageNum } } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <div>
                                模板名称
                                <Select
                                    showSearch
                                    style={{ width: 200, marginLeft: 10, marginRight: 20 }}
                                    placeholder="请选择模板名称"
                                    optionFilterProp="children"
                                    onChange={this.onChange}
                                    value={this.state.code}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.props.ContractName.map(i => {
                                        return <Option key={i.constantKey} value={i.constantKey}>{i.constantName}</Option>;
                                    })}
                                </Select>
                            </div>
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._saleresetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    {
                        <ButtonPerssion code='contractmanagement/contracttemplate/htmbscmb'>
                            <div className={styles.operate_box}>
                                <XOKButton style={{ width: 90 }} label='上传模板' onClick={this._newUploadHandle} />
                            </div>
                        </ButtonPerssion>
                    }
                    <div className={styles.tableContainer}>
                        <Table
                            // bordered
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            // rowSelection={this._rowSelection} 
                            rowKey={(record, index) => index}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    <div>{this._renderMode()}</div>
                </div>
            </KeepAlive>
        );
    }
}

Contract.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getSelectContractList: PropTypes.func,
    SelectContractList: PropTypes.object,
    ContractName: PropTypes.array,
    getContractName: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        SelectContractList: state.contracttemplate.SelectContractList,
        ContractName: state.contracttemplate.ContractName
    };
};


export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath })(Contract);