// 角色列表
export const GET_ROLES_LIST = 'ASSIGNAUTHORITY/GET_ROLES_LIST';
export const SET_ROLES_LIST = 'ASSIGNAUTHORITY/SET_ROLES_LIST';

// 角色详情
export const GET_ROLES_DETAIL = 'ASSIGNAUTHORITY/GET_ROLES_DETAIL';
export const SET_ROLES_DETAIL = 'ASSIGNAUTHORITY/SET_ROLES_DETAIL';

// 获取本公司超级管理员
export const GET_ADMIN_EMPL = 'ASSIGNAUTHORITY/GET_ADMIN_EMPL';
export const SET_ADMIN_EMPL = 'ASSIGNAUTHORITY/SET_ADMIN_EMPL';

// 平台、运营中心、区域中心、商户某个角色下面得所有员工
export const GET_EMPL_BY_ROLE = 'ASSIGNAUTHORITY/GET_EMPL_BY_ROLE';
export const SET_EMPL_BY_ROLE = 'ASSIGNAUTHORITY/SET_EMPL_BY_ROLE';

// 未分类的员工列表
export const GET_UN_EMPL = 'ASSIGNAUTHORITY/GET_UN_EMPL';
export const SET_UN_EMPL = 'ASSIGNAUTHORITY/SET_UN_EMPL';

// 角色下拉列表(复制角色权限以及权限分配时均可用)
export const GET_ROLE_SELECT = 'ASSIGNAUTHORITY/GET_ROLE_SELECT';
export const SET_ROLE_SELECT = 'ASSIGNAUTHORITY/SET_ROLE_SELECT';

// 获取某角色权限的菜单(角色权限设置时用到)
export const GET_MENU_BY_ROLE = 'ASSIGNAUTHORITY/GET_MENU_BY_ROLE';
export const SET_MENU_BY_ROLE = 'ASSIGNAUTHORITY/SET_MENU_BY_ROLE';

// 获取员工下拉列表（在新建员工角色时用到）
export const GET_DOWNEMPLS = 'ASSIGNAUTHORITY/GET_DOWNEMPLS';
export const SET_DOWNEMPLS = 'ASSIGNAUTHORITY/SET_DOWNEMPLS';

// 根据员工获取角色列表（对已关联得角色做记号）
export const GET_ROLE_BY_EMPL = 'ASSIGNAUTHORITY/GET_ROLE_BY_EMPL';
export const SET_ROLE_BY_EMPL = 'ASSIGNAUTHORITY/SET_ROLE_BY_EMPL';