/**
 * 开启平台收费设置
 */
import React from 'react';
import { Modal, Checkbox, Form, Radio, message, InputNumber, } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './PlatModal.module.scss';
import http from '@/assets/api/http';
import model from '@/reducers/systemmanage/allocation/model';
import { FloatConversion } from '@/util/conversion';



const RadioGroup = Radio.Group;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};
class PlatModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
        };
        this.floatConversion = new FloatConversion();
    }

    componentDidMount() {
        const { getFromMerchantList, getFromWholesaleList, getPlatDetails } = this.props;
        getFromMerchantList();
        getFromWholesaleList();
        getPlatDetails();
    }

    componentWillUnmount() {
        this.props.getCleanPlatDetails();
    }

    _handleOk = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const { fromMerchant, fromWholesale, serviceRatio, proRatio, openincome, maxRatio } = fieldsValue;
                const param = {
                    codeList: fromMerchant,
                    chargeList: fromWholesale,
                    saleServiceRatio: serviceRatio ? this.floatConversion.multiply(serviceRatio, 100) : undefined,
                    serviceRatio: proRatio ? this.floatConversion.multiply(proRatio, 100) : undefined,
                    gainLimit: openincome,
                    markupRatio: maxRatio ? this.floatConversion.multiply(maxRatio, 100) : undefined,
                };

                this.setState({ confirmLoading: true }, () => {
                    http('/settle/param/setting/config', param, 'POST').then(() => {
                        message.success('保存成功');
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                        this.props.closeMode();
                        this.props.refresh('useCache');
                    }).catch((reject = {}) => {
                        message.error(reject.message);
                        this.setState({
                            confirmLoading: false,
                        });
                    });
                });
            }
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }


    //批发服务费收取对象
    fromWholesale = (event = []) => {
        //验证请先配置向商户收取费用要包含平台服务费,商户才可勾选
        const { getFieldValue } = this.props.form;
        if (event.includes('MERCHANT')) {
            if (getFieldValue('fromMerchant').indexOf('DISTRIBUTION_FEE') >= 0) {
                return event;
            } else if (getFieldValue('fromMerchant').indexOf('DISTRIBUTION_FEE') < 0) {
                message.warning('请先配置向商户收取费用要包含平台服务费。');
                let emaIndex = event.indexOf('MERCHANT');
                event.splice(emaIndex, 1);
                return event;
            }
        }
        return event;
    }
    fromMerchant = (checkedValue) => {
        const { setFieldsValue } = this.props.form;
        if (checkedValue.indexOf('DISTRIBUTION_FEE') < 0) {
            setFieldsValue({ 'fromWholesale': [] });
        }
    }

    render() {
        const { visible, confirmLoading, title, } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { fromMerchantList, fromWholesaleList, platDetails } = this.props;
        const {
            codeList = [],
            chargeList = [],
            gainLimit,
            markupRatio,
            saleServiceRatio,
            serviceRatio,
        } = platDetails;

        return (
            <Modal
                width={780}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            style={{ marginBottom: '10px' }}
                            colon={false}
                            htmlFor='fromMerchant'
                            label={<span>向商户收取费用</span>}
                        >
                            {getFieldDecorator('fromMerchant', {
                                rules: [{ type: 'array', required: true, message: '请勾择' }],
                                initialValue: codeList,
                            })(
                                <Checkbox.Group onChange={this.fromMerchant}>
                                    {fromMerchantList.map(item => <Checkbox key={item.code} value={item.code}>{item.value}</Checkbox>)}
                                </Checkbox.Group>
                            )}
                        </Form.Item>
                        <h5 className={styles.merchantNote}>说明：</h5>
                        <div className={styles.merchantSmallNote}>
                            <p>勾选后平台向商户收取对应费用；不勾选默认不显示设置项，也不收取对应费用；</p>
                            <p>1：平台服务费、技术服务费、秀场服务费、心选推荐费和营销服务费在服务清单中设置；</p>
                            <p>2：营销服务费开启在商户权限中设置；</p>
                        </div>
                        {getFieldValue('fromMerchant').includes('DISTRIBUTION_FEE') && <>
                            <Form.Item
                                style={{ marginBottom: '10px' }}
                                colon={false}
                                htmlFor='fromWholesale '
                                label={<span>批发服务费收取对象</span>}
                            >
                                {getFieldDecorator('fromWholesale', {
                                    getValueFromEvent: this.fromWholesale,
                                    rules: [{ type: 'array', required: true, message: '请勾择' }],
                                    initialValue: chargeList,
                                })(
                                    <Checkbox.Group>
                                        {fromWholesaleList.map(item => <Checkbox key={item.code} value={item.code}>{item.value}</Checkbox>)}
                                    </Checkbox.Group>
                                )}
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                htmlFor='serviceRatio'
                                label={<span>小B服务费比率</span>}
                            >
                                {getFieldDecorator('serviceRatio', {
                                    rules: [{ required: true, message: '请输入' }],
                                    initialValue: codeList.includes('DISTRIBUTION_FEE') ? this.floatConversion.divide(saleServiceRatio, 100) : undefined,
                                })(
                                    <InputNumber
                                        precision={1}
                                        min={0.1}
                                        max={100}
                                        placeholder="请输入"
                                        style={{ width: 400 }}
                                    />
                                )}
                                <span className={styles.curt}>%</span>
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                htmlFor='proRatio'
                                label={<span>服务商服务费比率</span>}
                            >
                                {getFieldDecorator('proRatio', {
                                    rules: [{ required: true, message: '请输入' }],
                                    initialValue: codeList.includes('DISTRIBUTION_FEE') ? this.floatConversion.divide(serviceRatio, 100) : undefined,
                                })(
                                    <InputNumber
                                        precision={1}
                                        min={0.1}
                                        max={100}
                                        placeholder="请输入"
                                        style={{ width: 400 }}
                                    />
                                )}
                                <span className={styles.curt}>%</span>
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                htmlFor='openincome'
                                label={<span>开启批发收益限额</span>}
                            >
                                {getFieldDecorator('openincome', {
                                    rules: [{ required: true, message: '请选择' }],
                                    initialValue: gainLimit,
                                })(
                                    <RadioGroup>
                                        <Radio value={0}>不开启</Radio>
                                        <Radio value={1}>开启</Radio>
                                    </RadioGroup>
                                )}
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                htmlFor='maxRatio'
                                label={<span>最大比率</span>}
                            >
                                {getFieldDecorator('maxRatio', {
                                    rules: [{ required: true, message: '请输入' }],
                                    initialValue: codeList.includes('DISTRIBUTION_FEE') ? this.floatConversion.divide(markupRatio, 100) : undefined,
                                })(
                                    <InputNumber
                                        precision={0}
                                        min={1}
                                        max={30}
                                        placeholder="请输入"
                                        style={{ width: 400 }}
                                    />
                                )}
                                <span className={styles.curt}>%</span>
                            </Form.Item>
                            <h5 className={styles.merchantNote}>说明：</h5>
                            <div className={styles.merchantSmallNote}>
                                <p>开启后系统对批发业务设置分润最高限制；</p>
                                <p>批发业务为订单的业务类型=集采自购，分销分享和分销自购的订单；</p>
                                <p>设置后  校验B端批发业务收费对象为商户时，控制 （平台分润金额）平台向商户收取的服务费（按最新服务清单上比率计算的平台服务费+技术服务费+秀场服务费+心选推荐费
                               +营销服务费）+平台加价=（平台分销价-商户批发价）+服务商加价金额 3个金额之和占订单商品总金额比率不可以大于最高限制（最大比率）；</p>
                                <p>注1：以批发价为基础计算；校验最大金额不可以超过销售价；</p>
                                <p>注2：免单均摊不考虑，批发订单不会有免单活动；</p>
                                <p>如限制最高30%，批发价100元；分销价105元，服务费比率10%，交易手续费千分之六；服务商加价金额最大可以加30%，以批发价计算出平台最大可分润金额，（100-（100*（10%+0.006））/(1-30%)=127.71元）,
                               向下取整127；如商品最大销售金额135元，本次服务商加价金额最高22元（127-105）；</p>
                                <p>2:设置后  校验B端批发业务收费对象为小B时，控制 （平台分润金额）平台向商户收取的服务费 ，小B加价金额+（平台分销价-商户批发价）,控制3个金额之和占订单商品总金额比率
                                不可以大于最高限制；</p>
                                <p>公式：以批发价除以（1-最大比率值）；计算结果向上取整作为总价。校验总价不可以大于商品销售价。</p>
                                <p>如如限制最高30%，批发价100元；平台分销价105元，服务商加价金额最大可以加到总销售价的30%，（100-（100*（10%+0.006））/(1-30%)=127.71元）(向下取整)，最高可以设置加价金额为27元；
                               如商品档案中销售价140元，本次服务商加价金额最高22元（127-105）；</p>
                                <p>3:设置后  校验B端批发业务收费对象为服务商和商户时，
                                控制 （平台分润金额）平台向商户收取的服务费（按最新服务清单上比率计算的平台服务费+技术服务费+秀场服务费+心选推荐费+营销服务费）+平台加价=（平台分销价-商户批发价）+服务商加价金额 3个金额
                            之和占订单商品总金额比率不可以大于最高限制（最大比率）；</p>
                                <p>4：没有设置不做控制；</p>
                            </div>
                        </>}
                    </Form>
                </div>
            </Modal>
        );
    }
}

PlatModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    refresh: PropTypes.func,
    form: PropTypes.object.isRequired, // 校验

    getFromMerchantList: PropTypes.func,//商户收取费用数据action
    fromMerchantList: PropTypes.array,//商户收取费用数据
    getFromWholesaleList: PropTypes.func,//批发服务费收取数据action
    fromWholesaleList: PropTypes.array,//批发服务费收取数据
    getPlatDetails: PropTypes.func,//获取初始化详情数据action
    platDetails: PropTypes.object,//获取初始化详情数据
    getCleanPlatDetails: PropTypes.func,//清除获取初始化详情数据action
};

const mapStateToProps = (state) => {
    return {
        fromMerchantList: state.Allocation.fromMerchantList,
        fromWholesaleList: state.Allocation.fromWholesaleList,
        platDetails: state.Allocation.platDetails,
    };
};

const PlatDetails = Form.create({ name: 'create-form' })(PlatModal);// 校验
export default connect(mapStateToProps, model.actions)(PlatDetails);
