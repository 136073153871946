import { createModel } from '@/store/tools';

const model = {
    namespace: 'operationshare',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getOperationShareList': T('getOperationShareList'),
        },
        actions: {
            'getOperationShareList': A('getOperationShareList'),
        },
        sagas: {
            'getOperationShareList': S('getOperationShareList', '/boss/order/distribution/distributionOrderExportList'),//运营中心分销分享销售列表
        },
        reducers: {
            'operationShareList': R('getOperationShareList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);