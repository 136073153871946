import { createModel } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };

const model = {
    namespace: 'marketingamountmanage',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getDrawIncomeList': T('getDrawIncomeList'),
            'getAwaitSettlementList': T('getAwaitSettlementList'),
            'getKeyExchangeList': T('getKeyExchangeList')
        },
        actions: {
            'getDrawIncomeList': A('getDrawIncomeList'),
            'getAwaitSettlementList': A('getAwaitSettlementList'),
            'getKeyExchangeList': A('getKeyExchangeList')
        },
        sagas: {
            'getDrawIncomeList': S('getDrawIncomeList', '/admin/company/listIntegralDetail'),
            'getAwaitSettlementList': S('getAwaitSettlementList', '/admin/company/getCompanyIntegralWaittingSettleList'),
            'getKeyExchangeList': S('getKeyExchangeList', '/integralExchangeVoucher/company/list')
        },
        reducers: {
            'drawIncomeList': R('getDrawIncomeList', dataList),
            'awaitSettlementList': R('getAwaitSettlementList', { orderState: {}, payType: {}, exchangeState: {} }),
            'keyExchangeList': R('getKeyExchangeList', dataList)
        }
    })
};
export default createModel(model);