/**
 * 商品列表禁用弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, Input, message } from 'antd';
import styles from './CreatePay.module.scss';
import numSwitch from '@/util/numSwitch';
import { XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { getCompanyInvoicePay } from '@/reducers/auditmanage/companyInvoice/actions';

const { TextArea } = Input;

class CreatePay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.title,
            visible: props.visible,
            subLoad: false,
            audLoad: false,
            oddNum: 0,
            unpaidAmount: 0,
            paymentAmount: 0,
            remark: ''
        };
    }
    componentDidMount() {
        const { batch, type } = this.props.data;
        switch (type) {
            case 'single':
                this.props.getCompanyInvoicePay({ ids: batch });
                break;
            case 'batch':
                this.props.getCompanyInvoicePay({ ids: batch });
                break;
        }
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        const { companyInvoicePay } = newProps;
        this.setState({
            oddNum: companyInvoicePay.num,
            paymentAmount: companyInvoicePay.unpaidAmount / 100,
            unpaidAmount: companyInvoicePay.unpaidAmount / 100
        });
    }
    _sendReq = (data, winText) => {
        http('/settle/paymentApplication/add', data, 'POST').then(() => {
            message.success(winText);
            this.setState({
                visible: false,
                audLoad: false,
                subLoad: false
            });
            this.props.closeMode(true);
        }).catch((error) => {
            message.error(error.message);
            this.setState({
                audLoad: false,
                subLoad: false
            });
        });
    }

    _confirmPay = () => {
        const { companyInvoicePay, data } = this.props;
        const { remark, paymentAmount } = this.state;
        this.setState({
            subLoad: true,
        }, () => {
            switch (data.type) {
                case 'single':
                    this._sendReq({
                        settlementIds: data.batch,
                        toBePaymentAmount: companyInvoicePay.unpaidAmount,
                        paymentAmount: paymentAmount * 100,
                        remark,
                        start: false
                    }, '新建付款单成功。');
                    break;
                case 'batch':
                    this._sendReq({
                        settlementIds: data.batch,
                        toBePaymentAmount: companyInvoicePay.unpaidAmount,
                        paymentAmount: paymentAmount * 100,
                        remark,
                        start: false
                    }, '批量新建付款单成功。');
                    break;
            }
        });
    }
    _confirmPayAudit = () => {
        const { companyInvoicePay, data } = this.props;
        const { remark, paymentAmount } = this.state;
        this.setState({
            audLoad: true,
        }, () => {
            switch (data.type) {
                case 'single':
                    this._sendReq({
                        settlementIds: data.batch,
                        toBePaymentAmount: companyInvoicePay.unpaidAmount,
                        paymentAmount: paymentAmount * 100,
                        remark,
                        start: true
                    }, '新建付款单并提交成功。');
                    break;
                case 'batch':
                    this._sendReq({
                        settlementIds: data.batch,
                        toBePaymentAmount: companyInvoicePay.unpaidAmount,
                        paymentAmount: paymentAmount * 100,
                        remark,
                        start: true
                    }, '批量新建付款单并提交成功。');
                    break;
            }
        });
    }
    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
            audLoad: false,
            subLoad: false
        });
        this.props.closeMode();
    }
    _inputChange = (e, type) => {
        switch (type) {
            case 'payment':
                this.setState({ paymentAmount: e.target.value });
                break;
            case 'payExplain':
                this.setState({ remark: e.target.value });
                break;
        }
    }
    render() {
        const { visible, subLoad, audLoad, title, oddNum, paymentAmount, unpaidAmount, remark } = this.state;

        return (
            <Modal
                width={600}
                centered
                title={title}
                visible={visible}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                footer={null}
                maskClosable={false}
            >
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        {/* <div className={styles.contentItem}>
                            <p className={styles.label}><b>*</b>付款单号：</p>
                            <Input placeholder='请输入' disabled />
                        </div> */}
                        <div className={styles.contentItem}>
                            <div className={styles.label}>业务单号数量</div>
                            <Input value={oddNum} style={{ width: '80%' }} placeholder='请输入' disabled={true} />
                        </div>
                        {/* <div className={styles.contentItem}>
                            <p className={styles.label}><b>*</b>对账单数量：</p>
                            <Input placeholder='请输入' onChange={(e) => this._inputChange(e, 'reconciliation')} />
                        </div> */}
                        <div className={styles.contentItem}>
                            <div className={styles.label}>待付款金额</div>
                            <div className={styles.itemInfo}>
                                <Input placeholder='请输入' value={unpaidAmount} disabled={true} />
                                <p className={styles.switch}>({numSwitch(unpaidAmount || 0)})</p>
                            </div>
                        </div>
                        <div className={styles.contentItem}>
                            <div className={styles.label}><b>*</b>付款金额</div>
                            <div className={styles.itemInfo}>
                                <Input placeholder='请输入' value={paymentAmount} onChange={(e) => this._inputChange(e, 'payment')} />
                                <p className={styles.switch}>({numSwitch(paymentAmount || 0)})</p>
                            </div>
                        </div>
                        <div className={styles.contentItem}>
                            <div className={styles.label}><b>*</b>付款说明</div>
                            <TextArea placeholder='请输入' style={{ width: '80%' }} autosize={{ minRows: 2, maxRows: 6 }} value={remark} onChange={(e) => this._inputChange(e, 'payExplain')} />
                        </div>
                        <div className={styles.btn}>
                            <XCancelButton
                                style={{ width: '90px', display: 'inline-block' }}
                                onClick={this._handleCancel}
                                label='取消' />
                            <XOKButton
                                style={{ width: '90px', marginLeft: '20px', display: 'inline-block' }}
                                loading={subLoad}
                                disabled={audLoad}
                                onClick={this._confirmPay}
                                label='确定' />
                            <XOKButton
                                style={{ width: '150px', marginLeft: '20px', display: 'inline-block' }}
                                loading={audLoad}
                                disabled={subLoad}
                                onClick={this._confirmPayAudit}
                                label='确定并提交审核' />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
CreatePay.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    getCompanyInvoicePay: PropTypes.func,
    companyInvoicePay: PropTypes.object
};
const mapStateToProps = (state) => ({
    companyInvoicePay: state.companyInvoice.companyInvoicePay,
});
export default connect(mapStateToProps, { getCompanyInvoicePay })(CreatePay);