import { createModel } from '@/store/tools';

const model = {
    namespace: 'languagePack',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getLanguageList': T('getLanguageList'),
        },
        actions: {
            'getLanguageList': A('getLanguageList'), // 内容数据
        },
        sagas: {
            'getLanguageList': S('getLanguageList', '/hotel/voicePacket/findVoicePacketList'),
        },
        reducers: {
            'languageList': R('getLanguageList', []),
        }
    })
};
export default createModel(model);