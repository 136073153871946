/**
 * 直播、视频、社区分类编辑弹框 
 */
import React, { useState, useEffect } from 'react';
import { Modal, Radio, message, Form, Input } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import CustomUpload, { generateFileList } from '@/components/NewUpload';
import modal from '@/reducers/knowledgepay/channelclassify/model';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    }
};
const radioData = [
    {
        code: '1',
        value: '启用'
    },
    {
        code: '0',
        value: '禁用'
    }
];

const EditClassify = (props) => {

    const [visible, setVisible] = useState(props.visible);
    const [imgs, setImgs] = useState(generateFileList(''));
    const [confirmLoading, setConfirmLoading] = useState(false);

    useEffect(() => {
        props.getKClassifyState();
        props.getKClassifyDetail({ id: props.openModeParam });
    }, []);

    useEffect(() => {
        setImgs(generateFileList(props.kClassifyDetail.img));
    }, [props.kClassifyDetail]);

    //提交
    const _handleSubmit = e => {
        e && e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setConfirmLoading(true);
                let params = {
                    id: props.openModeParam,
                    className: values.className,
                    state: values.state
                };
                if (values.imgs[0].response && values.imgs[0].response.key) {
                    params = { ...params, img: values.imgs[0].response.key };
                } else {
                    params = { ...params, img: values.imgs[0].name };
                }
                http('/show/showClass/updateShowClass', { ...params }, 'POST').then(() => {
                    message.success('编辑分类成功。');
                    setVisible(false);
                    setConfirmLoading(false);
                    props.closeMode(true);
                }).catch((error) => {
                    message.error(error.message);
                    setConfirmLoading(false);
                });
            }
        });
    };

    //确认
    const _handleOk = () => {
        _handleSubmit();
    };

    //取消
    const _handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false);
        props.closeMode(false);
    };

    //上传事件
    const _normFile = e => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };


    const { getFieldDecorator } = props.form;
    return (
        <Modal
            width={500}
            centered
            title='编辑分类'
            visible={visible}
            confirmLoading={confirmLoading}
            bodyStyle={{ paddingBottom: 0 }}
            onOk={_handleOk}
            onCancel={_handleCancel}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <Form id='editKClassify' {...formItemLayout} onSubmit={_handleSubmit}>
                <Form.Item label="分类名称">
                    {getFieldDecorator('className', {
                        initialValue: props.kClassifyDetail.className,
                        rules: [
                            {
                                required: true,
                                message: '请输入分类名称',
                            },
                            {
                                min: 2,
                                message: '分类名称最少两个字符',
                            },
                            {
                                max: 8,
                                message: '分类名称最大八个字符',
                            }
                        ],
                    })(<Input placeholder='请输入分类名称' />)}
                </Form.Item>
                <Form.Item label="分类状态">
                    {getFieldDecorator('state', {
                        initialValue: `${props.kClassifyDetail.state}`,
                        rules: [
                            {
                                required: true,
                                message: '请选择分类状态',
                            },
                        ],
                    })(<Radio.Group>
                        {
                            props.kClassifyState.map(item => {
                                return <Radio key={item.code} value={item.code}>{item.value}</Radio>;
                            })
                        }
                    </Radio.Group>)}
                </Form.Item>
                <Form.Item label="分类图标">
                    {getFieldDecorator('imgs', {
                        getValueFromEvent: _normFile,
                        initialValue: imgs,
                        rules: [
                            {
                                required: true,
                                message: '请上传分类图标',
                            },
                        ],
                    })(<CustomUpload
                        accept='.png, .jpg, .jpeg'
                        listType="picture-card"
                        wallMode="single"
                        fileList={imgs}
                        qiniuUploadProps={{
                            uploadType: 'image'
                        }}
                        onChange={(info) => {
                            setImgs(info.fileList);
                        }}
                    >
                        <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                    </CustomUpload>)}
                </Form.Item>
            </Form>
        </Modal>
    );
};

EditClassify.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    form: PropTypes.object.isRequired,//form表单
    openModeParam: PropTypes.number,
    getKClassifyDetail: PropTypes.func,//获取状态下拉
    kClassifyDetail: PropTypes.object,//下拉数据
    getKClassifyState: PropTypes.func,//分类列表
    kClassifyState: PropTypes.array,//列表数据
};

const mapStateToProps = (state) => ({
    kClassifyDetail: state.kclassify.kClassifyDetail,
    kClassifyState: state.kclassify.kClassifyState,
});

const EditClassifyForm = Form.create({ name: 'editKClassify' })(EditClassify);
export default connect(mapStateToProps, { ...modal.actions })(EditClassifyForm);