/**
 * [服务商统计]
 * @author: Fu Xiaochun
 * @date: 2023-05-19 
 */

import React, { useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, message, Modal, Button } from 'antd';
import { XInput, XOKButton, XCancelButton, XSelect, XRangePicker } from '@/components/xqxc_ui';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import TableData from './components/TableData';
import http from '@/assets/api/http';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { regFenToYuan } from '@/util/money';

function ServiceProvider(props){

    const history = useHistory();

    const today = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    const [result, setResult] = useState({dataList:[]});
    const [aliveInit, setAliveInit] = useState(false);

    const [time, setTime] = useState(today);  // 时间
    const [account, setAccount] = useState();  // 服务商账号
    const [companyName, setCompanyName] = useState({});  // 区域公司名称

    const [companyNameEnum, setCompanyNameEnum] = useState([]);  // 区域公司列表

    const [resetCode, setResetCode] = useState(0);
    const page = useRef({ pageNum: autoPageNum, pageSize: autopageSize });

    useEffect(() => {
        props.keepSecondNavBreadcrumb();
        getAreaCompanyEnum();
    }, []);

    useEffect(() => {
        aliveInit && onSearch();
    }, [aliveInit]);

    // 重置后请求
    useEffect(() => {
        if (resetCode > 0) {
            onSearch();
        }
    }, [resetCode]);

    const getAreaCompanyEnum = ()=>{
        http('/admin/company/listAreaCompanyIdAndName').then(res=>{
            setCompanyNameEnum(res.result.result);
        }).catch(err=>{
            message.error(err.message);
        });
    };

    // 查询
    const onSearch = (usecache) => {
        if (usecache !== 'usecache') {
            page.current = { pageNum: autoPageNum, pageSize: autopageSize };
        }
        let { pageNum, pageSize } = page.current;
        let searchCache = {
            payStartTime: time[0] ? time[0] + ' 00:00:00' : undefined,
            payEndTime: time[1] ? time[1] + ' 23:59:59' : undefined,
            mobilePhone: account,
            areaCompanyId: companyName.id,
            pageNum,
            pageSize,
        };

        http('/statistics/order/serviceStatistics', searchCache).then(res=>{
            setResult(res.result);
        }).catch(err=>{
            message.error(err.message);
        });
    };

    // 重置
    const onReset = () => {
        setTime(today);
        setAccount();
        setCompanyName({});
        setResetCode(resetCode + 1);
        page.current = { pageNum: autoPageNum, pageSize: autopageSize };
    };

    const onPaginationChange = (size, num) => {
        page.current = { pageSize: size, pageNum: num };
        onSearch('usecache');
    };

    const _tableAction = (val, type, data) => {
        switch (type) {
            default:
            // nothing to do
        }
    };

    return (
        <KeepAlive render={(state) => {
            if (!state.aliveInit) {
                setAliveInit(true);
            }
        }}>
            <div className={styles.flexBoxContainer}>
                <SearchBox>
                    <SearchItem>
                        <XRangePicker
                            style={{marginRight: '30px'}}
                            inputStyle={{width: '230px'}}
                            label='时间'
                            bindThis={setTime}
                            bindName='time'
                            value={time}
                            format="YYYY-MM-DD"
                            allowClear={false}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            style={{marginRight: '30px'}}
                            label='服务商账号'
                            placeholder='输入服务商账号'
                            bindName='account'
                            value={account}
                            bindThis={setAccount}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            selectStyle={{ width: '150px' }}
                            style={{marginRight: '30px'}}
                            label='区域公司名称'
                            placeholder='请选择'
                            renderData={companyNameEnum}
                            dataIndex='companyName'
                            keyIndex='id'
                            value={companyName.companyName}
                            bindName='companyName'
                            bindThis={setCompanyName}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            style={{marginRight: '20px'}}
                            onClick={onSearch}
                        />
                        <XCancelButton
                            label='重置'
                            style={{marginRight: '30px'}}
                            onClick={onReset}
                        />
                    </SearchItem>
                </SearchBox>
                <div className={styles.statistics}>
                    <div>支付数量(笔)：{result.dataMap ? result.dataMap.payNum : '--'}</div>
                    <div>支付总金额(元)：{result.dataMap ? regFenToYuan(result.dataMap.totalPayFee) : '--'}</div>
                </div>
                <div className={styles.tableContainer} style={{paddingTop: '0'}}>
                    <TableData renderData={result} paginationChange={onPaginationChange} tableAction={_tableAction} />
                </div>
            </div>
        </KeepAlive>
    );
}

ServiceProvider.propTypes = {
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb})(ServiceProvider);