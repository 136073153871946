/**
 *  酒店管理 - 取消原因
 * */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Table, message } from 'antd';
import SalerModal from '../../modal/SalerModal';
import SalerEditModal from '../../modal/SalerEditModal';
import model from '@/reducers/hotelmanage/cancelreasons/model';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XOKButton, XPagination, showConfirm } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import { autoPageNum, autopageSize } from '@/assets/config';
import KeepAlive from '@/routes/KeepAlive';

const Mode = {
    REASON: 'REASON',
};

class Reason extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
        pageNum: 1,
        pageSize: 10,
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageNum, pageSize } = this.state;
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getCancelReasonsList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _saleresetHandle = () => {
        this.setState({ pageNum: 1, pageSize: 10 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建按钮被点击
    _newreasonHandle = () => {
        this.setState({ currentOpenMode: Mode.REASON });
    }

    // 表格操作功能回调
    _actionClickHandle = (id, type, value) => {
        switch (type) {
            case 'sort': //排序
                http('/hotel/reason/setHotelCancelReasonSortNo', { id, changeSortNo: value }, 'POST')
                    .then(() => {
                        message.success('修改成功!');
                        this._searchHandle('useCache');
                    }).catch((reject) => {
                        message.error(reject.message);
                    });
                break;
            case 'switch': //状态
                http('/hotel/reason/updateHotelCancelReasonState/' + id).then(res => {
                    if (res.code === 200) {
                        this._searchHandle('useCache');
                    }
                }).catch((res = {}) => {
                    message.error(res.message || '更新异常');
                });
                break;
            case 'del': //删除  
                showConfirm('确认是否删除?', '', () => {
                    http('/hotel/reason/deleteHotelCancelReason/' + id, 'POST')
                        .then(() => {
                            message.success('删除成功!');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'edit': //编辑
                this.setState({ currentOpenMode: 'edit', openModeParam: { id } });
                break;
        }

    }
    updateContent = (type, value, param) => {
        http('/boss/order/updateAfterSaleReason', param, 'POST')
            .then(() => {
                message.success(`${type === 'switch' && value === true ? '已启用' : type === 'switch' && value === false ? '已禁用' : '修改成功!'}`);
                this._searchHandle('useCache');
            }).catch((reject) => {
                message.error(reject.message);
            });
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.REASON:
                return <SalerModal title='创建原因' visible={true} refresh={this._searchHandle} closeMode={this._closeMode} />;
            case 'edit':
                return <SalerEditModal title='编辑原因' openModeParam={this.state.openModeParam} visible={true} refresh={this._searchHandle} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize });
        this.searchCache = { pageNum, pageSize };
        this.props.getCancelReasonsList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { cancelReasonsList: { dataList = [], resultTotal, pageSize, pageNum } } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: 90 }} label='新建' onClick={this._newreasonHandle} />
                    </div>
                    <div className={styles.tableContainer}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record, index) => index}
                            columns={takeColumns(this._actionClickHandle, this.state.curTabID)}
                            dataSource={takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    <div>{this._renderMode()}</div>
                </div>
            </KeepAlive>
        );
    }
}

Reason.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getCancelReasonsList: PropTypes.func,
    cancelReasonsList: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        cancelReasonsList: state.cancelreasons.cancelReasonsList,
    };
};


export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Reason);