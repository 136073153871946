import React, { Component } from 'react';
import { Table, Switch, } from 'antd';
import PropTypes from 'prop-types';
import styles from './PayTable.module.scss';
import { XPagination, } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

export default class PayTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],//对应的key数据
            selectedRows: [],//对应的key行数据
        };
    }
    _columns = () => [
        {
            title: '会员账号',
            dataIndex: 'accountName',
            key: 'accountName',
            width: '13%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员昵称',
            dataIndex: 'nickName',
            key: 'nickName',
            width: '14%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否是主播',
            dataIndex: 'anchorName',
            key: 'anchorName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '直播权限',
            dataIndex: 'isOpenLiveCharge',
            key: 'isOpenLiveCharge',
            width: '10%',
            align: 'center',
            render: (text, item) => (this.SwitchCell(text, item, 'live'))
        },
        {
            title: '视频权限',
            dataIndex: 'isOpenVideoCharge',
            key: 'isOpenVideoCharge',
            width: '10%',
            align: 'center',
            render: (text, item) => (this.SwitchCell(text, item, 'video'))
        },
        {
            title: '社区权限',
            dataIndex: 'isOpenGraphicsCharge',
            key: 'isOpenGraphicsCharge',
            width: '10%',
            align: 'center',
            render: (text, item) => (this.SwitchCell(text, item, 'community'))
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: '13%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作人',
            dataIndex: 'createUserName',
            key: 'createUserName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '10%',
            render: (id, item) => this._tableAction(id, item)
        },
    ];

    // 解析表格数据，使符合columns格式要求
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                item.anchorName = item.isAnchor == '1' ? '是' : '否';
                return item;
            });
        }
    }

    // 表格项内容为开关
    SwitchCell = (sw, item, permissions) => {
        let checked = true;
        if (sw == 0) {
            checked = false;
        }
        return (
            <div className={styles.switchCell} style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={checked}
                    onChange={(value) => {
                        this.props.tableAction(item, permissions, value);
                    }}
                />
            </div>
        );
    };

    //表格操作
    _tableAction = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'delete', item)}>删除</div>
            </div>
        );
    };

    // 表格单选输入
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
        this.props.selectedRowsHandle(selectedRowKeys, selectedRows);
    };

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                    rowSelection={rowSelection}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

PayTable.propTypes = {
    renderData: PropTypes.object, // 表格数据源
    tableAction: PropTypes.func, // 表格
    paginationChange: PropTypes.func, // 分页
    selectedRowsHandle: PropTypes.func,//获取选中数据
};