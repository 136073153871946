/**
 * 系统管理 - 版本
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as System from './actiontypes';

// 版本列表
export const getReleaseList = function* () {
    yield takeEvery(System.GET_RELEASE_LIST, function* requestData({ payload }) {
        try {
            let result = yield http('/admin/appversionmanage/listAppVersionManage', payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: System.SET_RELEASE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 版本详情
export const getReleaseDetail = function* () {
    yield takeEvery(System.GET_RELEASE_DETAIL, function* requestData({ payload }) {
        try {
            let result = yield http('/admin/appversionmanage/selectByPrimaryKey/' + payload.id, payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: System.SET_RELEASE_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 版本终端
export const getReleaseTerminal = function* () {
    yield takeEvery(System.GET_RELEASE_TERMINAL, function* requestData({ payload }) {
        try {
            let result = yield http('/enum/AppTypeEnum', payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: System.SET_RELEASE_TERMINAL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 版本系统
export const getReleaseSystem = function* () {
    yield takeEvery(System.GET_RELEASE_SYSTEM, function* requestData({ payload }) {
        try {
            let result = yield http('/enum/AppSystemType', payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: System.SET_RELEASE_SYSTEM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 版本状态
export const getReleaseState = function* () {
    yield takeEvery(System.GET_RELEASE_STATE, function* requestData({ payload }) {
        try {
            let result = yield http('/enum/StateEnum', payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: System.SET_RELEASE_STATE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 版本更新
export const getReleaseUpdate = function* () {
    yield takeEvery(System.GET_RELEASE_UPDATE, function* requestData({ payload }) {
        try {
            let result = yield http('/enum/UpdateModeEnum', payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: System.SET_RELEASE_UPDATE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};