export const Mode = {
    /** 详情 */
    Detail: 'Detail',

    /** 取消 */
    Cancel: 'Cancel',

    /** 邀请 */
    Invitation: 'Invitation',

    /** 选择房型 */
    SelectRoomLayout: 'SelectRoomLayout'
};