export var TreeField;
(function (TreeField) {
    TreeField['ChildField'] = 'Children';
})(TreeField || (TreeField = {}));
export function LoopTree(tree, handle, childFiled = TreeField.ChildField, result) {
    let handleResult = handle(tree, result);
    const children = (tree[childFiled] || []);
    children.forEach(child => LoopTree(child, handle, childFiled, handleResult));
    return handleResult;
}
export function LoopTreeCP(tree, handle, childFiled = TreeField.ChildField, result) {
    let handleResult = handle(tree, result);
    if (!handleResult.break) {
        const children = (tree[childFiled] || []);
        for (let i = 0; i < children.length; i++) {
            const childHandleResult = LoopTreeCP(children[i], handle, childFiled, handleResult);
        }
    }
    return handleResult;
}
export function LoopTreeLeaf(tree, handle, childFiled = TreeField.ChildField) {
    const children = (tree[childFiled] || []);
    const handleResults = children.map(child => LoopTreeLeaf(child, handle, childFiled));
    return handle(tree, handleResults);
}
export function LoopTreeLeafCP(tree, handle, childFiled = TreeField.ChildField) {
    const handleResults = [], children = (tree[childFiled] || []);
    for (let i = 0; i < children.length; i++) {
        const childHandleResult = LoopTreeLeafCP(children[i], handle, childFiled);
        handleResults.push(childHandleResult);
        if (childHandleResult.break)
            break;
    }
    return handle(tree, handleResults);
}
export function WrapArray(array, childField = TreeField.ChildField) {
    return { [childField]: array };
}
