import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table, Button } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';

class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '区域公司',
            dataIndex: 'companyName',
            key: 'companyName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单数',
            dataIndex: 'orderNum',
            key: 'orderNum',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '销售金额(元)',
            dataIndex: 'saleAmount',
            key: 'saleAmount',
            width: '10%',
            align: 'center',
            render: (text, record) => this._incomeCell(text, record)
        },
        {
            title: '区域公司金额(元)',
            dataIndex: 'companyAmount',
            key: 'companyAmount',
            width: '10%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '操作',
            dataIndex: 'companyId',
            key: 'companyId',
            width: '10%',
            align: 'center',
            render: (text, record) => this._tableAction(text, record)
        },
    ]);

    _takeDataSource = (dataList = []) => {
        return dataList;
    }

    _incomeCell = (text, record) => {
        const { tableAction } = this.props;
        return <Button type='link' onClick={() => tableAction('sale', record)}>{regFenToYuan(text)}</Button>;
    }

    _tableAction = (text, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction('detail', record)}>查看详情</div>
            </div>
        );
    };

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    tableAction: PropTypes.func, // 对表格的操作
};

export default TableContent;