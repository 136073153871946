/**
 * 广告评论
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Advert from './actiontypes';

export const getAdvertCommentList = function* (){
    yield takeEvery(Advert.GET_COMMENT_LIST, function* requestData(action){
        try{
            let result = yield http('/adcomment/listByLike', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Advert.SET_COMMENT_LIST, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

