import { combineReducers } from 'redux';
import {
    messageMoveList,
    messageMoveDetail,
    messageStatus,
    messageWays,
    jumpWays,
    pushPeople
} from './reducer';

export default combineReducers({
    messageMoveList,
    messageMoveDetail,
    messageStatus,
    messageWays,
    jumpWays,
    pushPeople,
});