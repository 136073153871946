import React from 'react';
import styles from './AdvertTable.module.scss';
import { XTableSelectionCount, XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell } from '@/components/TableCell';

class AdvertTable extends React.Component {
    state = {
        account: '',
        selectedRowKeys: [],
    }

    _takeColumns = () => ([
        {
            title: '姓名',
            dataIndex: 'ownerName',
            key: 'ownerName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '证照号码',
            dataIndex: 'identification',
            key: 'identification',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所得项目',
            dataIndex: 'incomeItem',
            key: 'incomeItem',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所得期间起',
            dataIndex: 'startDate',
            key: 'startDate',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所得期间止',
            dataIndex: 'endDate',
            key: 'endDate',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '收入(元)',
            dataIndex: 'amount',
            key: 'amount',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '免税收入(元)',
            dataIndex: 'taxFeeIncome',
            key: 'taxFeeIncome',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '实际捐赠额(元)',
            dataIndex: 'actualDonation',
            key: 'actualDonation',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '捐赠方式',
            dataIndex: 'donationWay',
            key: 'donationWay',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '准予扣除的捐赠额(元)',
            dataIndex: 'allowanceForDeduction',
            key: 'allowanceForDeduction',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '扣除及减除项目合计(元)',
            dataIndex: 'totalDeductionsAndDeductions',
            key: 'totalDeductionsAndDeductions',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '应纳税所得额(元)',
            dataIndex: 'taxableAmount',
            key: 'taxableAmount',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '税率',
            dataIndex: 'ratio',
            key: 'ratio',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                    {regFenToYuan(text)} %
                </div>
            )
        },
        {
            title: '应纳税额(元)',
            dataIndex: 'taxAmount',
            key: 'taxAmount',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '减免税额(元)',
            dataIndex: 'taxDeduction',
            key: 'taxDeduction',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '应扣缴税额(元)',
            dataIndex: 'taxDeductible',
            key: 'taxDeductible',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '已扣缴税额(元)',
            dataIndex: 'withholdingTax',
            key: 'withholdingTax',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '应补（退）税额(元)',
            dataIndex: 'taxPayable',
            key: 'taxPayable',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '会员身份',
            dataIndex: 'identity',
            key: 'identity',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '新建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => this._renderTextCell(text)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows }, () => {
                this.props.getSelectedRowKeysFun(selectedRowKeys);
            });
        },
    };

    onChange = (pagination, filters, sorter) => {
        console.log('params', pagination, filters, sorter);
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] }, () => {
            this.props.getSelectedRowKeysFun([]);
        });
    }

    render() {
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record) => record.withdrawalId}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func, // 表格操作
    paginationChange: PropTypes.func, // 分页操作
    getSelectedRowKeysFun: PropTypes.func,
};

export default AdvertTable;