/**
 * 【仓库列表】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import { XTabNav, XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import AdvertTable from './components/AdvertTable';
import DeleteModal from './modal/DeleteModal';
import SendInvoice from './modal/SendInvoice';

import { getInvoiceManageTableSource } from '@/reducers/storehousefinancialmanage/invoicemanage/actions';


class Main extends React.Component {

    state = {
        companyStatus: {}
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getInvoiceManageTableSource({ pageNum: 1, pageSize: 15 });
    }
    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }
    // 查询
    _searchHandle = () => { }

    // 重置
    _resetHandle = () => {
        this.setState({ schedule: '', pageNum: 1, pageSize: 20, companyStatus: {} });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    _newHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建发票',
            path: '/home/storehousefinancialmanage/invoicemanage/createinvoice'
        });
        history.push({
            pathname: '/home/storehousefinancialmanage/invoicemanage/createinvoice'
        });
    };
    // 表格操作
    _tableAction = (id, type, provider) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'edit':
                addOneBreadcrumbPath({
                    title: '编辑发票',
                    path: '/home/storehousefinancialmanage/invoicemanage/createinvoice/' + id
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/invoicemanage/createinvoice/' + id
                });
                break;
            case 'detail':

                addOneBreadcrumbPath({
                    title: '发票详情',
                    path: '/home/storehousefinancialmanage/invoicemanage/createinvoice/' + id
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/invoicemanage/createinvoice/' + id
                });
                break;
            case 'delete':
                this.setState({ currentOpenMode: 'delete', openModeParam: id });
                break;
            case 'send':
                this.setState({ currentOpenMode: 'send', openModeParam: id });
                break;
        }
    }
    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'delete':
                return <DeleteModal title='删除发票' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'send':
                return <SendInvoice title='寄送发票' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' });
    }
    render() {
        const { companyStatus } = this.state;

        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.row1}>
                    <XInput
                        style={{ width: '248px' }}
                        inputStyle={{ width: '180px' }}
                        label='发票号码'
                        placeholder='请输入'
                        value={''}
                        bindThis={this}
                        bindName='vip'
                    />
                    <XSelect
                        style={{ marginLeft: '10px', width: '300px' }}
                        selectStyle={{ width: '200px' }}
                        label='单位'
                        placeholder='请选择单位'
                        renderData={[]}
                        onChange={this._companyStatusChange}
                        dataIndex='value'
                        keyIndex='id'
                        value={companyStatus.merchantName}
                    />
                    <XOKButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='查询'
                        onClick={this._searchHandle}
                    />
                    <XCancelButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='重置'
                        onClick={this._resetHandle}
                    />
                </div>
                <div className={styles.row2}>
                    {/* <XOKButton
                        style={{ width: '90px', marginRight: 15 }}
                        label='新建'
                        onClick={this._newHandle}
                    /> */}
                    <XOKButton
                        style={{ width: '90px' }}
                        label='导出Excel'
                        onClick={this._newHandle}
                    />
                </div>
                <AdvertTable renderData={this.props.invoiceManageTableSource} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                {this._renderMode()}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    invoiceManageTableSource: PropTypes.object,
    getInvoiceManageTableSource: PropTypes.func
};

const mapStateToProps = (state) => ({
    invoiceManageTableSource: state.invoicemanage.invoiceManageTableSource
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getInvoiceManageTableSource })(Main);