import React, { useState, useEffect, Fragment } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Button, Icon, Modal, Table, Tooltip, Typography } from 'antd';
import { isOC } from '@/assets/js/authType';
import BigNumber from 'bignumber.js';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const { Text } = Typography;

const ViewModal = ({ visible = false, closeModal, data: { id = '', saleName = '' } = {} }) => {

    const [tableData, setTableData] = useState([]);
    const [saleroom, setSaleroom] = useState(0);

    useEffect(() => {
        visible && http('/report/queryForAreaCompanySale', { id }, 'POST')
            .then(({ result: { saleFeeResps = [], salesFee = 0 } = {} } = {}) => {
                setTableData((saleFeeResps || []).map(i => ({ ...i, saleFee: new BigNumber(i.saleFee).dividedBy(100).toFixed(2) })));
                setSaleroom(new BigNumber(salesFee).dividedBy(100).toFixed(2));
            });
    }, [visible]);

    const columns = [
        {
            title: '云仓会员账号',
            dataIndex: 'saleName',
            key: 'saleName',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '销售额',
            dataIndex: 'saleFee',
            key: 'saleFee',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    return <Modal width={660} title='查看云仓会员销售额' centered
        visible={visible} destroyOnClose={true} maskClosable={false}
        keyboard={true} footer={null} onCancel={closeModal}>
        <div className={styles.modalcontainer}>
            <div className={styles.modaltotal}>{`${saleName}销售额：${saleroom}`}</div>
            <Table
                className={styles.tableBox}
                rowKey={r => r.id}
                columns={columns}
                pagination={false}
                dataSource={tableData}
            />
        </div>
    </Modal>;
};
ViewModal.propTypes = {
    visible: PropTypes.bool,
    closeModal: PropTypes.func,
    data: PropTypes.object,
};

const QuantityStatistics = () => {

    const [dataList, setDataLIst] = useState([
        { id: 'salesManCount', childId: 'salesManItem', title: '云仓数量', value: 0, message: '云仓数量包含自己', childList: [] },
        { id: 'accountCount', childId: 'accountItem', title: '用户数量', value: 0, message: '用户数量包含自己', childList: [] },
        { id: 'vipBenefitsCount', childId: 'vipBenefitsItem', title: '新人专区订单数量', value: 0, message: '已付款有效订单，不包含退款订单', childList: [] }
    ]);
    const [curnum, setCurnum] = useState(10);
    const [saleroom, setSaleroom] = useState(0);
    const [salePerList, setSalePerList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [modalParams, setModalParams] = useState({});

    useEffect(() => {
        http('/report/queryReportDataForUser', {}, 'POST')
            .then(({
                result: { accountCount, salesManCount, vipBenefitsCount, salesManItem = [],
                    accountItem = [], vipBenefitsItem = [], saleResp: { saleFeeResps = [], salesFee = 0 } = {} } = {}
            }) => {
                const dataObject = { accountCount, salesManCount, vipBenefitsCount };
                const childObject = { salesManItem, accountItem, vipBenefitsItem };
                const list = dataList.map(i => {
                    return {
                        ...i, value: dataObject[i.id],
                        childList: (childObject[i.childId] || []).map((c, index) => {
                            return { ...c, index };
                        })
                    };
                });
                setSalePerList((saleFeeResps || []).map(i => ({
                    ...i, saleFee: new BigNumber(i.saleFee || 0).dividedBy(100).toFixed(2),
                    directDriveFee: new BigNumber(i.directDriveFee || 0).dividedBy(100).toFixed(2)
                })));
                setSaleroom(new BigNumber(salesFee).dividedBy(100).toFixed(2)); setDataLIst(list);
            });
    }, []);

    const titleNode = (t, m) => {
        return <div style={{ marginBottom: '10px' }}><Text style={{ fontSize: '20px' }} strong>{t}</Text>
            <Tooltip placement='top' title={m}>
                <Icon type='question-circle' theme='twoTone' style={{ fontSize: '18px', cursor: 'pointer', marginLeft: '8px' }} />
            </Tooltip>
        </div>;
    };

    const countHeight = (list = []) => {
        let temp = curnum;
        let earn = 88;
        if (curnum < list.length) earn = 100;
        if (list.length < curnum) temp = list.length;
        return new BigNumber(temp).multipliedBy(32).plus(earn).toNumber();
    };

    return <div className={styles.container}>
        <div className={styles.pnumber} style={{ height: isOC() ? '420px' : '' }}>
            {dataList.map(i => {
                if (isOC()) {
                    return <div key={i.id} className={styles.item} style={{ height: countHeight(i.childList) }}>
                        <div className={styles.title}>{titleNode(i.title, i.message)}</div>
                        {i.childList.filter(c => c.index < curnum).map(j => {
                            return <div className={styles.ocRow} key={j.index}>
                                <Text>{j.companyName}：</Text><Text style={{ fontWeight: 'bold' }}>{j.qty}</Text>
                            </div>;
                        })}
                        {curnum < i.childList.length && <div className={styles.loadMore}>
                            <Button type='link' onClick={() => setCurnum(curnum + 10)}>查看更多</Button>
                            {curnum > 10 && <Button type='link' onClick={() => setCurnum(10)}>收起</Button>}
                        </div>}
                    </div>;
                } else {
                    return <div key={i.id} className={styles.item}>
                        <div className={styles.title}>{titleNode(i.title, i.message)}</div>
                        <div className={styles.reContent}>{i.value}</div>
                    </div>;
                }
            })}
        </div>
        <div className={styles.salenumber} style={{ height: isOC() ? '370px' : '' }}>
            <div className={styles.saleheader}>
                <div className={styles.title}>{titleNode('销售额', '统计当前区域公司下，所有团队的销售额')}</div>
                <div className={styles.saletotal}>{saleroom}</div>
            </div>
            {!isOC() && <div className={styles.company}>云仓会员销售额</div>}
            <div className={styles.saleperlist} style={{ height: isOC() ? '230px' : '' }}>
                {salePerList.map(i => {
                    return <div key={i.id} className={styles.saleperitem}>
                        <div className={styles.saleperlabel}>{isOC() ? `${i.saleName}：` : `会员${i.saleName}：`}</div>
                        <div className={styles.salepertext}>{`${i.saleFee}元`}</div>
                        {isOC() ?
                            <Button type='link' onClick={() => { setOpenModal(true); setModalParams({ saleName: i.saleName, id: i.id }); }}>查看</Button> :
                            <Fragment>
                                <div className={styles.saleperlabel} style={{ marginLeft: '20PX' }}>会员的销售额：</div>
                                <div className={styles.salepertext}>{`${i.directDriveFee}元`}</div>
                            </Fragment>}
                    </div>;
                })}
            </div>
        </div>
        <ViewModal data={modalParams} visible={openModal} closeModal={() => { setOpenModal(false); setModalParams({}); }} />
    </div>;
};
export default QuantityStatistics;

