/**
 * 人气值赠送设置弹框
 */
import React from 'react';
import { Modal, message, Radio } from 'antd';
import PropTypes from 'prop-types';
import styles from './SetXiuDouModal.module.scss';
import { XInputNum, XSelect } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import model from '@/reducers/systemmanage/riskcontrol/model';
import { connect } from 'react-redux';
import { regFenToYuan, regYuanToFen } from '@/util/money';

const RadioGroup = Radio.Group;
class SetXiuDouModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        init: false,
        id: '', // 设置ID
        isOpenAccountLevel: '',  // 是否开启VIP等级(1:是 0：否)
        leastAccountLevel: {}, // 最小会员VIP等级
        isNeedRealnameAuth: '', // 是否需要实名认证(1:是 0：否)
        starvaluePresentSingleMin: '', // 人气值转赠单笔最低额度
        roundMode: '', // 兑换结果取数（ROUND_DOWN：向下取整，ROUND_HALF_UP：四舍五入）
        starvaluePresentChargeFee: '', // 人气值赠送手续费（%）
        showLevelSelect: false, // 是否显示会员等级下拉列
        errorMsg: '', // 错误提示
    }

    componentDidMount() {
        this.props.getXiuDouSetting();
        this.props.getAccountLevel();
    }

    UNSAFE_componentWillReceiveProps(props) {
        const { xiuDouSetting } = props;
        if (xiuDouSetting.id && xiuDouSetting.roundMode && !this.state.init) {
            const { id, isOpenAccountLevel, leastAccountLevel, isNeedRealnameAuth, starvaluePresentSingleMin, roundMode, starvaluePresentChargeFee } = xiuDouSetting;
            this.setState({
                id, isOpenAccountLevel, leastAccountLevel: { levelValue: leastAccountLevel }, isNeedRealnameAuth, starvaluePresentSingleMin, roundMode, starvaluePresentChargeFee: regFenToYuan(starvaluePresentChargeFee), init: true
            });
            if (isOpenAccountLevel == 1) {
                this.setState({ showLevelSelect: true });
            }
        }
    }

    // 确认
    _handleOk = () => {
        const { id, isOpenAccountLevel, leastAccountLevel, isNeedRealnameAuth, starvaluePresentSingleMin, roundMode, starvaluePresentChargeFee } = this.state;
        console.log(id, isOpenAccountLevel, leastAccountLevel, isNeedRealnameAuth, starvaluePresentSingleMin, roundMode, starvaluePresentChargeFee);
        // 必填校验
        if (isOpenAccountLevel === '') {
            this.setState({ errorMsg: '提示：所有内容为必填项，请把信息补充完整' });
            return;
        } else if (isOpenAccountLevel === 1 && !leastAccountLevel.levelValue) {
            this.setState({ errorMsg: '提示：所有内容为必填项，请把信息补充完整' });
            return;
        } else if (isNeedRealnameAuth === '') {
            this.setState({ errorMsg: '提示：所有内容为必填项，请把信息补充完整' });
            return;
        } else if (!starvaluePresentSingleMin && starvaluePresentSingleMin != 0) {
            this.setState({ errorMsg: '提示：所有内容为必填项，请把信息补充完整' });
            return;
        } else if (roundMode === '') {
            this.setState({ errorMsg: '提示：所有内容为必填项，请把信息补充完整' });
            return;
        } else if (!starvaluePresentChargeFee && starvaluePresentChargeFee != 0) {
            this.setState({ errorMsg: '提示：所有内容为必填项，请把信息补充完整' });
            return;
        } else if (starvaluePresentChargeFee < 0 || starvaluePresentChargeFee > 100) {
            this.setState({ errorMsg: '提示：人气值赠送手续费范围为0~100%' });
            return;
        } else if (!(isOpenAccountLevel === 1 || isNeedRealnameAuth === 1)) {
            this.setState({ errorMsg: '提示：您当前未设置“开启VIP等级或实名认证”，请开启相关设置条件' });
            return;
        } else {
            this.setState({ errorMsg: '' });
        }

        // 请求设置
        const data = {
            isOpenAccountLevel,
            leastAccountLevel: leastAccountLevel.levelValue,
            isNeedRealnameAuth,
            starvaluePresentSingleMin,
            roundMode,
            starvaluePresentChargeFee: regYuanToFen(starvaluePresentChargeFee),
            id
        };
        http('/admin/starvaluePresentSetting/save', data, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result) {
                    message.success('生效成功');
                    this.props.closeMode();
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { accountLevel } = this.props;
        const { visible, confirmLoading, isOpenAccountLevel, leastAccountLevel, isNeedRealnameAuth, starvaluePresentSingleMin, roundMode, starvaluePresentChargeFee, showLevelSelect, errorMsg } = this.state;
        console.log(isOpenAccountLevel, leastAccountLevel, isNeedRealnameAuth, starvaluePresentSingleMin, roundMode, starvaluePresentChargeFee);

        return (
            <Modal
                width={550}
                centered
                title='人气值赠送设置'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.vip}>
                        <div style={{ width: '160px', paddingLeft: '35px' }}><span style={{ color: 'red' }}>*</span>是否开启VIP等级：</div>
                        <RadioGroup
                            style={{ marginLeft: '10px' }}
                            onChange={(e) => {
                                if (e.target.value == 1) {
                                    this.setState({ showLevelSelect: true });
                                } else {
                                    this.setState({ showLevelSelect: false });
                                }
                                this.setState({ isOpenAccountLevel: e.target.value });
                            }}
                            value={isOpenAccountLevel}
                        >
                            <Radio key={1} value={1}>是</Radio>
                            <Radio key={0} value={0}>否</Radio>
                        </RadioGroup>
                    </div>
                    {showLevelSelect ? <XSelect
                        style={{ marginLeft: '160px', marginTop: '10px' }}
                        selectStyle={{ width: '150px' }}
                        placeholder='请选择会员等级'
                        renderData={accountLevel}
                        dataIndex='levelName'
                        keyIndex='levelValue'
                        value={leastAccountLevel.levelValue}
                        bindThis={this}
                        bindName='leastAccountLevel'
                        showSearch={true}
                        isOptionBindID={true}
                    /> : null}
                    <div className={styles.row}>
                        <div style={{ width: '160px', paddingLeft: '27px' }}><span style={{ color: 'red' }}>*</span>是否需要实名认证：</div>
                        <RadioGroup
                            style={{ marginLeft: '10px' }}
                            onChange={(e) => { this.setState({ isNeedRealnameAuth: e.target.value }); }}
                            value={isNeedRealnameAuth}
                        >
                            <Radio key={1} value={1}>是</Radio>
                            <Radio key={0} value={0}>否</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.row}>
                        <div style={{ width: '160px' }}><span style={{ color: 'red' }}>*</span>人气值转赠单笔最低额度：</div>
                        <XInputNum
                            inputStyle={{ width: '100px' }}
                            label=''
                            min={10}
                            max={9999}
                            precision={0}
                            value={starvaluePresentSingleMin}
                            bindThis={this}
                            bindName='starvaluePresentSingleMin'
                        />
                        <div style={{ marginLeft: '10px' }}>人气值</div>
                    </div>
                    <div className={styles.row}>
                        <div style={{ width: '160px', paddingLeft: '54px' }}><span style={{ color: 'red' }}>*</span>兑换结果取数：</div>
                        <RadioGroup
                            style={{ marginLeft: '10px' }}
                            onChange={(e) => {
                                this.setState({ roundMode: e.target.value });
                            }}
                            value={roundMode}
                        >
                            <Radio key={'ROUND_DOWN'} value={'ROUND_DOWN'}>向下取整</Radio>
                            <Radio key={'ROUND_HALF_UP'} value={'ROUND_HALF_UP'}>四舍五入</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.row}>
                        <div style={{ width: '160px', paddingLeft: '38px' }}><span style={{ color: 'red' }}>*</span>人气值赠送手续费：</div>
                        <XInputNum
                            inputStyle={{ width: '100px' }}
                            label=''
                            // min={0}
                            // max={100}
                            precision={2}
                            value={starvaluePresentChargeFee}
                            bindThis={this}
                            bindName='starvaluePresentChargeFee'
                            onBlur={(e) => {
                                if (e.target.value == '') {
                                    this.setState({ starvaluePresentChargeFee: 0 });
                                }
                            }}
                        />
                        <div style={{ marginLeft: '10px' }}>%</div>
                    </div>
                    <div className={styles.error}>
                        {errorMsg}
                    </div>
                </div>
            </Modal>
        );
    }
}


SetXiuDouModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getXiuDouSetting: PropTypes.func, // 获取人气值设置请求
    xiuDouSetting: PropTypes.object, // 人气值设置详细
    getAccountLevel: PropTypes.func, // 获取会员等级请求
    accountLevel: PropTypes.array, // 会员等级详细
};

const mapStateToProps = (state) => ({
    xiuDouSetting: state.riskcontrol.getXiuDouSetting,
    accountLevel: state.riskcontrol.getAccountLevel,
});

export default connect(mapStateToProps, { ...model.actions })(SetXiuDouModal);