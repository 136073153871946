/**
 * 服务列表
 */
import React from 'react';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton, XInput, } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/accruedService/serviceList/model';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import FirstRate from './modal/FirstRate';
import SecondRate from './modal/SecondRate';
import ThirdRate from './modal/ThirdRate';

class AtmosphereGift extends React.Component {

    state = {
        services: '',//服务名称
        servicesNum: '',//服务编号
        states: {},//启用/禁用
        ports: {},//发行端口
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
    }

    componentDidMount() {
        const { getAccruedState, getAccruedPart, keepSecondNavBreadcrumb } = this.props;
        getAccruedState();
        getAccruedPart();
        keepSecondNavBreadcrumb();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, services, servicesNum, states, ports } = this.state;
        this.searchCache = {
            serviceName: services,//服务名称  
            serviceNo: servicesNum,//服务编号  
            serviceStatus: states.code && Number(states.code),//启用/禁用  
            issuePort: ports.code,//发行端口
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAccruedList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ states: {}, services: '', servicesNum: '', ports: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (item, tabletype, value) => {
        let currentOpenMode = '';
        switch (tabletype) {
            case 'edit':
                this._updateContent('EDITOR', item, value);
                break;
            case 'detail':
            case 'servicesNum':
                this._updateContent('DATAIL', item, value);
                break;
            case 'feeSetting'://费用比例设置
                if (value.serviceNo === 'SJ0003') {
                    currentOpenMode = 'second';
                } else if (value.serviceNo === 'xy04') {
                    currentOpenMode = 'third';
                } else {
                    currentOpenMode = 'first';
                }
                this.setState({
                    currentOpenMode,
                    openModeParam: { serviceName: value.serviceName, earningsServiceId: item },
                });
                break;
            case 'state':
                http('/earningsService/earningsServiceStateUpdate', { // 状态
                    id: item.id,
                    serviceStatus: value === false ? 0 : 1,
                }, 'POST').then(() => {
                    message.success('状态修改成功。');
                    this._searchHandle('useCache');
                }).catch((reject = {}) => {
                    message.error(reject.message);
                });
                break;
        }
    }

    // 打开弹窗（删除）
    _renderMode = () => {
        const { currentOpenMode, openModeParam } = this.state;
        switch (currentOpenMode) {
            case 'first':
                return <FirstRate data={openModeParam} closeMode={this._closeMode} />;
            case 'second':
                return <SecondRate data={openModeParam} closeMode={this._closeMode} />;
            case 'third':
                return <ThirdRate data={openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this._searchHandle('useCache');
        }
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        KeepAlive.saveCache({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        }); // 【第5步：缓存分页状态】
    }

    // 更新界面
    _updateContent = (mode, value, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case 'EDITOR':
                if (record.serviceNo == 'xy04') {
                    addOneBreadcrumbPath({
                        title: '编辑增值服务',
                        path: `/home/operationcenter/servicelist/editInfo/${value}`
                    });
                    history.push({
                        pathname: `/home/operationcenter/servicelist/edit/${value}`,
                    });
                } else {
                    addOneBreadcrumbPath({
                        title: '编辑增值服务',
                        path: `/home/operationcenter/servicelist/editInfo/${value}`
                    });
                    history.push({
                        pathname: `/home/operationcenter/servicelist/editInfo/${value}`,
                    });
                }
                break;
            case 'DATAIL':
                if (record.serviceNo == 'xy04') {
                    addOneBreadcrumbPath({
                        title: '增值服务详情',
                        path: `/home/operationcenter/servicelist/detail/${value}`,
                    });
                    history.push({
                        pathname: `/home/operationcenter/servicelist/detail/${value}`,
                    });
                } else {
                    addOneBreadcrumbPath({
                        title: '增值服务详情',
                        path: `/home/operationcenter/servicelist/detailInfo/${value}`
                    });
                    history.push({
                        pathname: `/home/operationcenter/servicelist/detailInfo/${value}`,
                    });
                }
                break;
        }
    }

    render() {
        const { services, servicesNum, states, ports } = this.state;
        const { accruedState, accruedPart, accruedList } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='服务名称'
                                placeholder='请输入'
                                value={services}
                                bindThis={this}
                                bindName='services'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='服务编号'
                                placeholder='请输入'
                                value={servicesNum}
                                bindThis={this}
                                bindName='servicesNum'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='启用/禁用'
                                placeholder='请选择'
                                renderData={accruedState}
                                dataIndex='value'
                                keyIndex='code'
                                value={states.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='states'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='推送端口'
                                placeholder='请选择'
                                renderData={accruedPart}
                                dataIndex='value'
                                keyIndex='code'
                                value={ports.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='ports'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent renderData={accruedList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

AtmosphereGift.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    updateContent: PropTypes.func, // 路由跳转
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息
    accruedList: PropTypes.object, // 列表
    getAccruedList: PropTypes.func,//列表action
    accruedPart: PropTypes.array, // 发行端口
    getAccruedPart: PropTypes.func, // 发行端口action
    getAccruedState: PropTypes.func,//启用/禁用action
    accruedState: PropTypes.array//启用/禁用  
};

const mapStateToProps = (state) => {
    return {
        accruedList: state.serviceAccrued.accruedList,
        accruedState: state.serviceAccrued.accruedState,
        accruedPart: state.serviceAccrued.accruedPart,
    };
};
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(AtmosphereGift);
