import { message } from 'antd';
import { timeToMinute } from '@/util/format';
import { GenerateUUID } from '@/util/utils';
export function beforeUpload(file) {
    const isJPG = file.type === 'image/jpeg';
    if (!isJPG) {
        message.error('You can only upload JPG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJPG && isLt2M;
}
export function getAudioDuration(file) {
    return new Promise((resolve) => {
        let url = URL.createObjectURL(file);
        let audioElement = new Audio(url);
        audioElement.addEventListener('loadedmetadata', _event => {
            resolve({
                duration: audioElement.duration,
                durationStr: timeToMinute(audioElement.duration)
            });
        });
    });
}
export function generateDuration(seconds) {
    let defaultDuration = { duration: 0, durationStr: '00:00' };
    return seconds ? {
        duration: seconds,
        durationStr: timeToMinute(seconds)
    } : defaultDuration;
}
export function generateFileName(fileName) {
    const fileSplits = fileName.split('.').pop();
    //return DateFormat(new Date(), `${fileSplits[0]}_yyyyMMddhhmmssS.${fileSplits[1] || ''}`);
    return `${GenerateUUID()}.${fileSplits}`;
}
export function generateFileList(paths, names) {
    let nameSplits = typeof names === 'string' ? names.split(',') : [];
    return paths ? paths.split(',').map((path, index) => ({
        uid: -index,
        name: nameSplits[index] ? nameSplits[index] : (path.split('/').pop() || 'default').replace(/_.+\./, '.'),
        status: 'done',
        url: path
    })) : [];
}
