import React from 'react';
import { Modal, Form, Select, Input, message, DatePicker, Button } from 'antd';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import moment from 'moment';
import CustomUpload from '@/components/NewUpload';
import styles from './PreviewLive.module.scss';
import copy from 'copy-to-clipboard';
import { showConfirm } from '@/components/xqxc_ui';

class PreviewLive extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            title: '直播预览',
            liveData: {},
            liveState: 'NONE',
            okText: '开始直播',
            loading: false

        };
        this.player1 = React.createRef(); // 开始直播前，播放器的容器
        this.player2 = React.createRef(); // 开始直播后，播放器的容器
    }

    componentDidMount() {
        const { id, broadcastClassId, broadcastClass } = this.props.params;

        http('/liveBroad/queryLiveBroadcast', { id, broadcastClassId, broadcastClass }, 'POST').then((response) => {
            console.log(response.result);
            const { name, previewInfo, state } = response.result;
            this.setState({ liveData: response.result || {} });
            this.setState({ title: '直播预览 ' + name + '的直播间' });
            this.setState({ liveState: state });
            state == 'NONE' && this.setState({ okText: '开始直播' });
            state == 'LIVE' && this.setState({ okText: '停止直播' });
            this._playM3U8('player1', previewInfo && previewInfo.hlsPlayURL);
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 复制推流
    _onCopy = (id) => {
        if (copy(id)) message.success('复制成功');
        else message.error('复制失败');
    };

    _playM3U8 = (player, url) => {
        if (url == '') {
            message.error('视频播放路径为空');
            return;
        }
        setTimeout(() => {
            var container = player == 'player1' ? this.player1.current : this.player2.current;
            // 支持MP4或M3U8格式
            // eslint-disable-next-line no-undef
            new QPlayer({
                url,
                // url: 'http://demo-videos.qnsdk.com/movies/qiniu.mp4',
                // url: 'http://pili-vod.broadcast.xingqiuxiuchang.cn/recordings/z1.xqxc-test-broadcast.e6tn2plrr_xqxc-test-broadcast_1924628936173988/0_1567498085.m3u8',
                container: container,
                autoplay: true,
            });
        }, 100);
    }

    _handleOk = (e) => {
        const { id, broadcastClassId, broadcastClass } = this.props.params;
        const { liveState, liveData } = this.state;
        const { previewInfo } = liveData;
        this.setState({ loading: true });
        if (liveState == 'NONE') {
            http('/liveBroad/startLiveBroadcast', { id, broadcastClassId, broadcastClass }, 'POST').then((response) => {
                this.setState({ okText: '停止直播' });
                this.setState({ loading: false });
                this.setState({ liveState: 'LIVE' }, () => {
                    this._playM3U8('player2', previewInfo && previewInfo.hlsPlayURL);
                });
            }).catch((e) => {
                message.error(e.message);
                this.setState({ loading: false });
            });
        } else {
            showConfirm('停止直播提示', '停止直播后，当前直播间将断开直播，用户不再获取直播画面，确定要停止直播吗？', () => {
                http('/liveBroad/stopLiveBroadcast', { id, broadcastClassId, broadcastClass }, 'POST').then((response) => {
                    message.success('直播已停止');
                    this.setState({ loading: false });
                    this.props.close();
                }).catch((e) => {
                    message.error(e.message);
                    this.setState({ loading: false });
                });
            });
        }
    }

    _handleCancel = () => {
        this.props.close();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { title, liveData, okText, loading, liveState } = this.state;
        const { liveUniqueNo, previewInfo, coverPage, videoUrl } = liveData;

        return (
            <Modal
                width={700}
                centered
                title={title}
                visible={true}
                confirmLoading={loading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText={okText}
            >
                <div className={styles.container}>
                    {liveUniqueNo && <div className={styles.liveNo}>房间口令：{liveUniqueNo}</div>}
                    <div className={styles.playstream}>推流地址：{previewInfo && previewInfo.rtmpPublishURL} <Button size='small' onClick={() => this._onCopy(previewInfo.rtmpPublishURL)}>复制</Button></div>
                    <div className={styles.content}>
                        {
                            liveState != 'LIVE' && (<div className={styles.cover}>
                                {!videoUrl && <img className={styles.img} src={coverPage} />}
                                {videoUrl && <video className={styles.video} src={videoUrl} controls preload='auto' loop autoPlay></video>}
                            </div>)
                        }
                        <div className={styles.live}>
                            {liveState == 'NONE' && <div className={styles.player} ref={this.player1} />}
                            {liveState == 'LIVE' && <div className={styles.player} ref={this.player2} />}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

PreviewLive.propTypes = {
    form: PropTypes.object.isRequired,
    params: PropTypes.object,
    close: PropTypes.func,
};

const PreviewLiveForm = Form.create({ name: 'PreviewLive' })(PreviewLive);
export default PreviewLiveForm;