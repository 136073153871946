/**
 * 秀场管理 - 频道分类异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as T from './actiontypes';
import http from '@/assets/api/http';

// 获取会员等级
export const getAccountLevel = function* (){
    yield takeEvery(T.GET_PRODUCT_CLASSIFY, function* requestData(action){
        try{
            // http请求，将请求结果放入下一步
            let result = yield http('/goods/category/listCategoryItem', action.payload, 'POST');
            yield put({type:T.SET_PRODUCT_CLASSIFY,payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

// 获取第三方商品分类
export const getProductThirdPartyClassify = function* () {
    yield takeEvery(T.GET_PRODUCT_THIRDPARTYCLASSIFY, function* requestData(action) {
        try {
            let result = yield http('/goods/baseProduct/findBaseGoodsCategoryPage', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_THIRDPARTYCLASSIFY, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};