import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

const columns = (TableAction, actionClickHandle) => [
	{
		title: '分类ID',
		dataIndex: 'id',
		key: 'id-id',
		align: 'center',
		width: '14%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '分类名称',
		dataIndex: 'categoryName',
		key: 'id-categoryName',
		align: 'center',
		width: '14%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '状态',
		dataIndex: 'isSync',
		key: 'isSync',
		align: 'center',
		width: '14%',
		onCell: tooltipStyle,
		render: (val) => ('未已'.charAt(val) + '同步')
	},
	{
		title: '同步时间',
		dataIndex: 'updateTime',
		key: 'updateTime',
		align: 'center',
		width: '14%',
		onCell: tooltipStyle,
		render: tooltip
	},
	//task-2772改动
	// {
	// 	title: '平台分类ID',
	// 	dataIndex: 'platCategoryId',
	// 	key: 'platCategoryId',
	// 	align: 'center',
	// 	width: '14%',
	// 	onCell: tooltipStyle,
	// 	render: id=>id == 0 ? '-' : id
	// },
	// {
	// 	title: '平台分类名称',
	// 	dataIndex: 'platCategoryName',
	// 	key: 'id-platCategoryName',
	// 	align: 'center',
	// 	width: '14%',
	// 	onCell: tooltipStyle,
	// 	render: tooltip
	// },
	{
		title: '线上商品分类',
		dataIndex: 'platCategoryName',
		key: 'platCategoryName',
		align: 'center',
		width: '14%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '同城商品分类',
		dataIndex: 'tccategoryName',
		key: 'tccategoryName',
		align: 'center',
		width: '14%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '操作',
		dataIndex: 'id',
		key: 'action-id',
		align: 'center',
		width: '14%',
		render: (id) => TableAction(id, actionClickHandle)
	},
];

// 表格操作
const TableAction = (key, actionClickHandle) => {
	return (
		<div className={styles.action}>
			<div className={styles.item} onClick={() => actionClickHandle(key, 'syncClassify')}>同步平台分类</div>
		</div>
	);
};

class TableData extends Component {

	// 操作表格
	_actionHandle = (id, type, value) => {
		const { tableAction } = this.props;
		tableAction(id, type, value);
	}

	//处理表格数据
	_processingData = (data) => {
		return data;
	}

	render() {
		const { renderData } = this.props;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey='id'
					columns={columns(TableAction, this._actionHandle)}
					pagination={false}
					dataSource={this._processingData(renderData.dataList)}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={this.props.paginationChange} />
			</div>
		);
	}
}


TableData.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object
};



export default TableData;