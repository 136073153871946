import { getWarehouseType, getListAreaByParentId, getSupplierListTableSource, getShopTypes, getGoodsTypes } from './supplierlist/saga';
import { getDeliverListTableSource, getValidateStatementTableSource, getContractManageTableSource, getContractStates } from './suppliersettlement/saga';
import { getPayManageTableSource, getPayManageDetail, getPayChannelEnum } from './paymanage/saga';
import { getInvoiceListTableSource } from './invoicelist/saga';
import { getPlatformReconciliationTableSource } from './platformreconciliation/saga';
import { getMerchantSelectSource, getMerchantReconciliationTableSource, getMerchantDetail, getMerchantTradeDetail, getMerchantSelectSourceV1, getStateSelectSource } from './merchantreconciliation/saga';
import merchantModel from './paymanage/merchantModel';
import { getMerchantInvoiceTableSource, getMerchantInvoiceDetail, getSettlementStatus } from './merchantinvoicemanage/saga';
import { getTaxDeclarationList } from './taxdeclaration/saga';
import { getRegionalCompanyReconciliationTableSource, getRegionalCompanyLists, getRegionalCompanyDetail } from './regionalcompanyreconciliation/saga';
import { getRegionalCompanyInvoiceTableSource, getRegionalCompanyInvoiceDetail } from './regionalcompanyinvoicemanage/saga';
import { getOperatingCenterReconciliationTableSource, getlistOperationsIdAndName, getOperationCenterDetail } from './operatingcenterreconciliation/saga';
import { getOperationCenterInvoiceTableSource, getOperationCenterInvoiceDetail } from './operatingcenterinvoicemanage/saga';
import { getAccountList, getServiceList, getMerchantList, getRegionalCompanyList, getOperationCenterList, getPlatFormList, getCostDetailList, getCostDetailLists, getCostNameList, getOrderCostDetailList, getServiceOrderList, getServiceManageFeeList, getActiveCompanyState } from './bankAccount/saga';
import billAuery from './billquery/model';
import exchangemanage from './exchangemanage/model';
import reconciliationdata from './reconciliationdata/model';
import tonlyaccountpay from './tonlyaccountpay/model';
import hotelreconciliation from './merchantreconciliation/hotelReconciliationModel';
import paybill from './paybill/model';
import otherpay from './otherpay/model';
import freeorderrefund from './freeorderrefund/model';
import taxcertificate from './taxcertificate/model';
import dataCenter from './datacenter/model';
import dailystatement2 from './dailystatement2/model';
import transactionreconciliation from './transactionreconciliation/model';
import thirdreconciliation from './thirdreconciliation/model';
import invoicemaintain from './invoicemaintain/model';
import cashmaintain from './cashmaintain/model';
import marketingamountmanage from './marketingamountmanage/model';
import previewcollectionmanage from './previewcollectionmanage/model';
import platdividendincome from './platdividendincome/model';
import channel from './channel/model';
import subsidiesincome from './subsidiesincome/model';
import signRecord from './signRecord/model';
import withdrawRecord from './withdrawRecord/model';

export default [
    getAccountList,
    getServiceList,
    getMerchantList,
    getOperationCenterList,
    getRegionalCompanyList,
    getPlatFormList,
    getCostDetailList,
    getCostDetailLists,
    getOrderCostDetailList,
    getCostNameList,
    getServiceManageFeeList,
    getActiveCompanyState,
    getSupplierListTableSource,
    getShopTypes,
    getWarehouseType,
    getListAreaByParentId,
    getGoodsTypes,
    getServiceOrderList,
    getDeliverListTableSource,
    getValidateStatementTableSource,
    getContractManageTableSource,
    getContractStates,
    getPayManageTableSource,
    getPayManageDetail,
    getPayChannelEnum,
    getInvoiceListTableSource,
    getMerchantInvoiceTableSource,
    getMerchantInvoiceDetail,
    getSettlementStatus,
    getMerchantSelectSource,
    getMerchantSelectSourceV1,
    getMerchantTradeDetail,
    getMerchantDetail,
    getStateSelectSource,
    getPlatformReconciliationTableSource,
    getRegionalCompanyReconciliationTableSource,
    getRegionalCompanyInvoiceTableSource,
    getRegionalCompanyLists,
    getRegionalCompanyDetail,
    getRegionalCompanyInvoiceDetail,
    getOperatingCenterReconciliationTableSource,
    getOperationCenterInvoiceTableSource,
    getOperationCenterInvoiceDetail,
    getlistOperationsIdAndName,
    getOperationCenterDetail,
    getMerchantReconciliationTableSource,
    getTaxDeclarationList,
    ...Object.values(merchantModel.sagas),
    ...Object.values(hotelreconciliation.sagas),
    ...Object.values(billAuery.sagas),
    ...Object.values(exchangemanage.sagas),
    ...Object.values(reconciliationdata.sagas),
    ...Object.values(dailystatement2.sagas),
    ...Object.values(tonlyaccountpay.sagas),
    ...Object.values(paybill.sagas),
    ...Object.values(otherpay.sagas),
    ...Object.values(freeorderrefund.sagas),
    ...Object.values(taxcertificate.sagas),
    ...Object.values(dataCenter.sagas),
    ...Object.values(transactionreconciliation.sagas),
    ...Object.values(thirdreconciliation.sagas),
    ...Object.values(invoicemaintain.sagas),
    ...Object.values(cashmaintain.sagas),
    ...Object.values(marketingamountmanage.sagas),
    ...Object.values(previewcollectionmanage.sagas),
    ...Object.values(platdividendincome.sagas),
    ...Object.values(channel.sagas),
    ...Object.values(subsidiesincome.sagas),
    ...Object.values(signRecord.sagas),
    ...Object.values(withdrawRecord.sagas),
];
