/**
 * 新建黄金/种子会员
 */
import React from 'react';
import { Modal, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './GoldMemberArea.module.scss';
import { message, Statistic } from 'antd';
import http from '@/assets/api/http';

class StarRice extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        validAccount: false,

        account: '',
        password: '',
        validCode: '',
        sendValidCode: false,

        countTime: 0
    }

    _handleOk = () => {

        if (!this.state.validAccount) {
            message.warning('该手机号已被注册或不可用，请重新输入');
            return;
        }

        const { account, password, validCode } = this.state;

        this.setState({ confirmLoading: true });
        http(this.props.data.param, {
            tel: account,
            loginPwd: password,
            telCaptcha: validCode
        }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('添加成功');
                this.setState({ visible: false });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        }).finally(() => {
            this.setState({ confirmLoading: false });
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _validAccount = (e) => {
        this.setState({ validAccount: false });
        http('/user/account/exsitAccount', { tel: this.state.account }).then(res => {
            if (res.code === 200) {
                this.setState({ validAccount: true });
                message.success('当前帐号可以使用');
            }
        });
    }

    _sendValidCode = () => {
        const { account, validAccount } = this.state;
        if (!validAccount) {
            message.warning('该手机号已被注册或不可用，请重新输入');
            return;
        }

        this.setState({ sendValidCode: true, countTime: Date.now() + 1000 * 60 });
        http('/admin/empl/getTelCaptcha', {
            tel: account,
            loginName: account,
            captchaType: 'AGENT_USER_ADD'
        }).then(res => {
            if (res.code === 200) {
                console.log(res);
            }
        });
    }

    render() {
        const { visible, confirmLoading, account, password, validCode, sendValidCode, countTime, validAccount } = this.state;

        return (
            <Modal
                width={466}
                centered
                title={this.props.data.title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.item}>
                        <labe>会员手机号：</labe>
                        <Input value={account} onChange={(e) => this.setState({ account: e.target.value })} onBlur={this._validAccount} />
                    </div>
                    <div className={styles.item}>
                        <labe>设置会员密码：</labe>
                        <Input.Password value={password} onChange={(e) => this.setState({ password: e.target.value })} />
                    </div>
                    <div className={styles.item}>
                        <labe>验证码：</labe>
                        <Input value={validCode} onChange={(e) => this.setState({ validCode: e.target.value })} addonAfter={
                            <>
                                {!validAccount && <div>发送验证码</div>}
                                {validAccount &&
                                    <div>
                                        {sendValidCode && <Statistic.Countdown style={{ width: 80 }} valueStyle={{ fontSize: 14 }} onFinish={() => this.setState({ sendValidCode: false })} value={countTime} format="s秒" />}
                                        {!sendValidCode && <a onClick={this._sendValidCode}>发送验证码</a>}
                                    </div>
                                }
                            </>
                        } />
                    </div>
                </div>
            </Modal>
        );
    }
}

StarRice.propTypes = {
    curValue: PropTypes.number, //  当前信用值
    data: PropTypes.array.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default StarRice;