import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Switch, Icon, Input } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

const columns = (TableAction, actionClickHandle) => [
    {
        title: '分类ID',
        dataIndex: 'id',
        key: 'id',
        width: '20%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '分类名称',
        dataIndex: 'categoryTitle',
        key: 'categoryTitle ',
        width: '20%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '分类图标',
        dataIndex: 'categoryImg',
        key: 'categoryImg',
        align: 'center',
        width: '15%',
        render: ImgCell
    },
    {
        title: '排序',
        dataIndex: 'sort',
        key: 'sort',
        width: '15%',
        align: 'center',
        render: (text, item) => (SortCell(text, item, actionClickHandle))
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '15%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle))
    },
    {
        title: '操作',
        dataIndex: 'key',
        key: 'key',
        align: 'center',
        width: '15%',
        render: (text, record) => TableAction(record.id, record, actionClickHandle)
    }
];

const TableAction = (key, record, actionClickHandle) => {
    if (record.id < 100) {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => actionClickHandle(key, 'edit', record)}>编辑</div>
            </div>
        );
    } else {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => actionClickHandle(key, 'edit', record)}>编辑</div>
                <div className={styles.item} onClick={() => actionClickHandle(key, 'delete')}>删除</div>
            </div>
        );
    }

};

// 表格项内容为可编辑
const SortCell = (value, item, actionClickHandle) => {
    console.log(item);
    const label = React.createRef();
    const box = React.createRef();
    const input = React.createRef();
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <div className={styles.sort_box}>
                <div className={styles.label} ref={label}>{value}</div>
                <div className={styles.input} ref={box}>
                    <Input
                        style={{ width: '50px' }}
                        ref={input}
                        defaultValue={value}
                        onBlur={(e) => {
                            box.current.style.display = 'none';
                            label.current.style.display = 'block';
                            if (value != e.target.value) {
                                actionClickHandle(item.id, 'sort', { sortNo: e.target.value });
                            }
                        }}
                    />
                </div>
                <div
                    className={styles.icon}
                    onClick={() => {
                        label.current.style.display = 'none';
                        box.current.style.display = 'block';
                        input.current.focus();
                    }}
                >
                    <Icon type="edit" style={{ fontSize: '18px', color: '#1078ff' }} />
                </div>
            </div>
        </div>
    );
};

// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
    let checked = true;
    if (sw == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={(value) => {
                    console.log(value);
                    if (value == false) {
                        value = 0;
                    } else {
                        value = 1;
                    }
                    actionClickHandle(item.id, 'switch', { state: value });
                }}
            />
        </div>
    );
};

class TypeTable extends React.Component {

    // 解析表格数据
    _takeDataSource = (data) => {
        return data;
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { renderData, paginationChange } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={record => record.id}
                    columns={columns(TableAction, this._actionHandle)}
                    dataSource={this._takeDataSource(renderData.dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

export default TypeTable;