import { createModel } from '@/store/tools';

const model = {
    namespace: 'initdesclist',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getStrategyList': T('getStrategyList'),
            'getStrategyDetail': T('getStrategyDetail'),
            'getUseSceneSelect': T('getUseSceneSelect'),
        },
        actions: {
            'getStrategyList': A('getStrategyList'), // 获取初始说明列表
            'getStrategyDetail': A('getStrategyDetail'), // 获取初始说明详情
            'getUseSceneSelect': A('getUseSceneSelect'), // 获取文章位置下拉列
        },
        sagas: {
            'getStrategyList': S('getStrategyList', '/system/strategy/list'),
            'getStrategyDetail': S('getStrategyDetail', '/system/strategy', 'POST', 'id'),
            'getUseSceneSelect': S('getUseSceneSelect', '/enum/StrategyUseEnum'),
        },
        reducers: {
            'getStrategyList': R('getStrategyList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getStrategyDetail': R('getStrategyDetail', {}),
            'getUseSceneSelect': R('getUseSceneSelect', []),
        }
    })
};
export default createModel(model);