import React from 'react';
import styles from './TableMain.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, tooltipClick } from '@/components/TableCell';

class TableMain extends React.Component {

    _columns = () => [
        {
            title: '手机号码',
            dataIndex: 'mobilePhone',
            key: 'mobilePhone',
            align: 'center',
            width: '14%',
            onCell: tooltipStyle,
            render: (text, record) => tooltipClick(text, () => {
                this.props.tableAction(record.id, 'detail');
            })
        },
        {
            title: '会员昵称',
            dataIndex: 'realName',
            key: 'realName',
            align: 'center',
            width: '14%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '认证状态',
            dataIndex: 'auditState',
            key: 'auditState',
            align: 'center',
            width: '14%',
            onCell: tooltipStyle,
            render: (text) => tooltip(text ? text.value : '')
        },
        {
            title: '提交时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: '14%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '审核时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            align: 'center',
            width: '14%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作人',
            dataIndex: 'modifyUserName',
            key: 'modifyUserName',
            align: 'center',
            width: '14%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '14%',
            render: (id, record) => this._tableAction(id, record)
        }
    ];

    //实名认证审核操作在阿里云审核，因此先注释，请勿删除文件
    _tableAction = (id, { auditState }) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, 'detail')}>查看详情</div>
                {
                    auditState.code === 'WAIT_APPROVE' && <div className={styles.item} onClick={() => tableAction(id, 'audit')}>审核</div>
                }
            </div>
        );
    };

    render() {
        const { renderData, paginationChange } = this.props;

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    columns={this._columns()}
                    dataSource={renderData.dataList}
                    rowKey={record => record.id}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={paginationChange} />
            </div>
        );
    }
}

TableMain.defaultProps = {
    renderData: { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 }
};

TableMain.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func,
};

export default TableMain;