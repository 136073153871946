/**
 * 付款确认
 */
import React from 'react';
import { Modal, message, Icon } from 'antd';
import PropTypes from 'prop-types';
import styles from './SinglePay.module.scss';
import http from '@/assets/api/http';
import { regFenToYuan } from '@/util/money';
import { XInputPassword } from '@/components/xqxc_ui';
import md5 from 'js-md5';

class DetailModal extends React.Component {
    state = {
        confirmLoading: false,
        detail: {},
        pwd: ''
    };

    componentDidMount() {
        http('/youfuOut/query', { id: this.props.param.id }).then((response) => {
            const result = response.result || {};
            this.setState({ detail: result });
        }).catch((error) => {
            message.error(error.message);
        });
    }

    _handleOk = () => {
        const { detail, pwd } = this.state;
        if (!pwd) {
            message.error('请输入密码');
            return;
        }

        const query = {
            id: detail.id,
            payPassword: md5(pwd).toUpperCase()
        };

        this.setState({ confirmLoading: true });
        http('/youfuOut/confirmPay', { ...query }).then((response) => {
            message.success('打款成功');
            this.props.close('update');
            this.setState({ confirmLoading: false });
        }).catch((error) => {
            this.setState({ confirmLoading: false });
            message.error(error.message);
        });
    }

    _handleCancel = () => {
        this.props.close();
    }

    _digitUppercase = (n) => {
        const fraction = ['角', '分'];
        const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
        const unit = [
            ['元', '万', '亿'],
            ['', '拾', '佰', '仟'],
        ];
        let num = Math.abs(n);
        let s = '';
        fraction.forEach((item, index) => {
            s += (digit[Math.floor(num * 10 * (10 ** index)) % 10] + item).replace(/零./, '');
        });
        s = s || '整';
        num = Math.floor(num);
        for (let i = 0; i < unit[0].length && num > 0; i += 1) {
            let p = '';
            for (let j = 0; j < unit[1].length && num > 0; j += 1) {
                p = digit[num % 10] + unit[1][j] + p;
                num = Math.floor(num / 10);
            }
            s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
        }

        return s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
    }

    render() {
        const { confirmLoading, detail, pwd } = this.state;

        return (
            <Modal
                width={500}
                centered
                title='付款确认'
                visible={true}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确定打款'
            >
                <div className={styles.container}>
                    <div className={styles.item}>
                        <div className={styles.label}>收款人：</div><div>{detail.receiptName}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>身份证号：</div><div>{detail.receiptIdCard}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>手机号码：</div><div>{detail.receiptPhone}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>收款账号：</div><div>{detail.receiptAccountNo}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>打款金额：</div><div>{regFenToYuan(detail.amount)}<span className={styles.moneyCN}>（{this._digitUppercase(regFenToYuan(detail.amount))}）</span></div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>服务商：</div><div>{detail.levyName}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>付款备注：</div><div>{detail.remark}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>打款校验：</div><div>{detail.verifyStatus && detail.verifyStatus.value}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>财务密码：</div>
                        <div>
                            <XInputPassword
                                style={{ marginLeft: '-10px' }}
                                inputStyle={{ width: '300px' }}
                                placeholder="请输入密码"
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                visibilityToggle={true}
                                value={pwd}
                                bindThis={this}
                                bindName='pwd'
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );

    }
}

DetailModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    close: PropTypes.func.isRequired, // 关闭弹框的回调函数
    param: PropTypes.object
};

export default DetailModal;