import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { Modal, message, Radio, Icon, Checkbox } from 'antd';
import { XInputNum, XSelect, XInput, XTextArea } from '@/components/xqxc_ui';
import model from '@/reducers/starvalueshop/redemption/model';
import { regYuanToFen } from '@/util/money';

const AddGoodsModal = ({
    closeMode,
    getFreights,
    freights = [],
    getExchangeGoodsPayTypeEnum,
    exchangeGoodsPayTypeEnum = []
}) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [toUse, setToUse] = useState(100);
    const [limitNumber, setLimitNumber] = useState(null);
    const [templateItem, setTemplateItem] = useState({});
    const [costDetailList, setCostDetailList] = useState([{ id: 'default', title: '', value: null }]);
    const [goodsNos, setGoodsNos] = useState('');
    const [skuNos, setSkuNos] = useState('');
    const [isGoodsNos, setIsGoodsNos] = useState(true);
    const [isSkuNos, setIsSkuNos] = useState(false);
    //任务2933
    // const [payTypeList, setPayTypeList] = useState([]);

    useEffect(() => {
        getFreights();
        getExchangeGoodsPayTypeEnum();
    }, []);

    // 确认
    const handleOk = () => {
        if (!goodsNos && !skuNos) {
            message.warning('请输入商品货号或者SKU编码');
            return;
        }
        if (!templateItem.id) {
            message.warning('请选择运费模板');
            return;
        }
        //任务2933
        // if (payTypeList.length < 1) {
        //     message.warning('请勾选支付类型');
        //     return;
        // }
        if ((
            costDetailList[costDetailList.length - 1].title &&
            !costDetailList[costDetailList.length - 1].value
        ) || (
                costDetailList[costDetailList.length - 1].value &&
                !costDetailList[costDetailList.length - 1].title
            )
        ) {
            message.warning('请补全自定义收费项，或者清空自定义收费项输入框');
            return;
        }
        setConfirmLoading(true);
        http('/goods/exchangeGoods/batchAddExchangeGoods', {
            //任务2933
            // payTypeList, // 接口入参为数组格式
            goodsNos,
            skuNos,
            freightTemplateId: templateItem.id,
            exchangePloy: toUse,
            limitNumber,
            payTypeList:['COUPON'],
            costDetailList: costDetailList[costDetailList.length - 1].title ? costDetailList.map(item => {
                return { title: item.title, value: regYuanToFen(item.value) };
            }) : []
        }, 'POST')
            .then(res => {
                message.success(res.result.tip);
                setConfirmLoading(false);
                closeMode(true);
            }).catch((e) => {
                setConfirmLoading(false);
                message.error(e.message);
            });
    };

    // 取消
    const handleCancel = () => {
        closeMode(false);
    };

    const addItem = () => {
        const length = costDetailList.length;
        if (length > 3) {
            message.warning('最多四条');
            return;
        }
        if (!costDetailList[length - 1].value || !costDetailList[length - 1].title) {
            message.warning('请补全自定义收费项');
            return;
        }
        const list = [...costDetailList, { id: parseInt(Math.random() * 10000).toString(), title: '', value: null }];
        setCostDetailList(list);
    };

    const subtractItem = (id) => {
        const list = costDetailList.filter(item => item.id != id);
        setCostDetailList(list);
    };

    //list改变
    const listChange = (id, values) => {
        const list = costDetailList.map(item => {
            if (item.id === id) {
                return { ...item, ...values };
            }
            return item;
        });
        setCostDetailList(list);
    };

    console.log(exchangeGoodsPayTypeEnum);

    return (
        <Modal
            width={800}
            centered
            title='添加商品'
            visible={true}
            confirmLoading={confirmLoading}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <div className={styles.container}>
                <XTextArea
                    label={<Radio
                        checked={isGoodsNos}
                        onChange={e => {
                            if (isSkuNos) {
                                setIsSkuNos(false);
                            }
                            setSkuNos('');
                            setIsGoodsNos(e.target.checked);
                        }}
                    >{isGoodsNos && <span style={{ color: '#ff0000' }}>*</span>}商品货号</Radio>}
                    style={{ marginBottom: '10px' }}
                    inputStyle={{ width: '500px' }}
                    placeholder='请输入商品货号，多个逗号隔开,最多100个'
                    value={goodsNos}
                    rows={3}
                    disabled={isSkuNos}
                    onChange={value => {
                        if (value.replace(/，/g, ',').split(',').length < 100) {
                            setGoodsNos(value);
                        } else {
                            message.warning('货号集合最多100个');
                        }
                    }}
                />
                <div className={styles.tip}>只能输入供应链商品列表的商品货号，非供应链商品无法添加</div>
                {/* <XTextArea
                    label={<Radio
                        checked={isSkuNos}
                        onChange={e => {
                            if (isGoodsNos) {
                                setIsGoodsNos(false);
                            }
                            setGoodsNos('');
                            setIsSkuNos(e.target.checked);
                        }}
                    >{isSkuNos && <span style={{ color: '#ff0000' }}>*</span>}SKU编码</Radio>}
                    style={{ marginBottom: '20px' }}
                    inputStyle={{ width: '500px' }}
                    placeholder='请输入SKU编码，多个逗号隔开,最多100个'
                    value={skuNos}
                    rows={3}
                    disabled={isGoodsNos}
                    onChange={value => {
                        if (value.replace(/，/g, ',').split(',').length < 100) {
                            setSkuNos(value);
                        } else {
                            message.warning('货号集合最多100个');
                        }
                    }}
                /> */}
                {/* 任务2933 */}
                {/* <div className={styles.component}>
                    <span className={styles.star} style={{ display: 'block' }}>*</span>
                    <div className={styles.label}>支付类型：</div>
                    <div className={styles.inputbox}>
                        <Checkbox.Group
                            onChange={list => setPayTypeList(list)}
                            value={payTypeList}
                        >
                            {
                                Array.isArray(exchangeGoodsPayTypeEnum) && exchangeGoodsPayTypeEnum.map((item, index) => {
                                    return <Checkbox value={item.code} key={item.code}>{item.value}</Checkbox>;
                                })
                            }
                        </Checkbox.Group>
                    </div>
                </div> */}
                <div className={styles.component}>
                    <span className={styles.star} style={{ display: 'block' }}>*</span>
                    <div className={styles.label}>设置提货券抵用：</div>
                    <div className={styles.inputbox}>
                        <Radio.Group
                            onChange={({ target: { value = '' } = {} }) => setToUse(value)}
                            value={toUse}
                        >
                            <Radio value={100} key={100}>抵用100%</Radio>
                            <Radio disabled={true} value={50} key={50}>抵用50%</Radio>
                            <Radio disabled={true} value={10} key={10}>抵用10%</Radio>
                            <Radio disabled={true} value={0} key={0}>自定义抵用</Radio>
                        </Radio.Group>
                    </div>
                </div>
                <XInputNum
                    label='设置每人限购数：'
                    placeholder='请输入限购数'
                    style={{ marginBottom: '20px' }}
                    max={99}
                    min={0}
                    precision={0}
                    bindName='limitNumber'
                    bindThis={setLimitNumber}
                    value={limitNumber}
                />
                <XSelect
                    label='选择运费模板：'
                    placeholder='请选择运费模板'
                    style={{ marginBottom: '20px' }}
                    renderData={freights}
                    dataIndex='templateName'
                    keyIndex='id'
                    bindName='templateItem'
                    bindThis={setTemplateItem}
                    value={templateItem.templateName}
                    isRequired={true}
                />
                <div className={styles.userDefine}>
                    <div className={styles.label}>自定义收费项：</div>
                    <div className={styles.list}>
                        {
                            costDetailList.map(item => {
                                return <div className={styles.listItem} key={item.id}>
                                    <XInput
                                        label='输入项目名称'
                                        placeholder='请输入项目名称'
                                        onChange={value => listChange(item.id, { title: value })}
                                        value={item.title}
                                    />
                                    <XInputNum
                                        label='输入收取金额'
                                        placeholder='请输入收取金额'
                                        max={9999.00}
                                        min={1.00}
                                        precision={2}
                                        onChange={value => listChange(item.id, { value })}
                                        value={item.value}
                                    />
                                    <div style={{ marginRight: '10px' }}>元</div>
                                    {
                                        item.id == 'default' ?
                                            <Icon
                                                style={{ fontSize: '20px', marginRight: '10px', cursor: 'pointer' }}
                                                type='plus-circle'
                                                theme='twoTone'
                                                onClick={addItem}
                                            /> :
                                            <Icon
                                                style={{ fontSize: '20px', cursor: 'pointer' }}
                                                type='minus-circle'
                                                theme='twoTone'
                                                onClick={() => subtractItem(item.id)}
                                            />
                                    }
                                </div>;
                            })
                        }
                    </div>
                </div>
            </div>
        </Modal >
    );
};

AddGoodsModal.propTypes = {
    closeMode: PropTypes.func,//关闭弹框参数
    getFreights: PropTypes.func,
    freights: PropTypes.array,
    getExchangeGoodsPayTypeEnum: PropTypes.func,
    exchangeGoodsPayTypeEnum: PropTypes.array,
};

export default connect(({ redemption }) => ({
    freights: redemption.freights,
    exchangeGoodsPayTypeEnum: redemption.getExchangeGoodsPayTypeEnum
}), {
    ...model.actions
}
)(AddGoodsModal);