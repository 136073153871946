/**
 * 商户须知分类
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import CategoryTable from './components/CategoryTable';
import CategoryModal from './modal/CategoryModal';
import { XInput, XOKButton, XCancelButton, XSelect, showConfirm } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/xqstrategy/noticecategory/model';
import { SearchBox, SearchItem } from '@/components/Layout';


class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noticeName: '',//分类名称
            status: {},//状态
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
        };
    }

    componentDidMount() {
        this.props.getNoticeState();//状态
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, noticeName, status } = this.state;
        this.searchCache = {
            state: status.code,//状态
            className: noticeName,//分类名称
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getCategoryTableList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ noticeName: '', status: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建编辑
    _newHandle = () => {
        this.setState({
            currentOpenMode: 'CREAT'
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getCategoryTableList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作 
    _tableAction = (item, type, value) => {
        switch (type) {
            case 'edite'://编辑
                this.setState({
                    currentOpenMode: 'EDITE',
                    openModeParam: value,
                });
                break;
            case 'delete'://删除
                showConfirm('删除后，分类下的标题及问题将一并删除。', '', () => {
                    http('/instructionClass/delInstructionClass', { id: item }, 'POST')
                        .then(() => {
                            message.success('删除成功。');
                            this.props.getCategoryTableList(this.searchCache);
                        }).catch((reject = {}) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'switch'://状态
                http('/instructionClass/updateInstructionClass', {
                    id: item.id,
                    state: value === false ? '0' : '1'
                }, 'POST')
                    .then(() => {
                        message.success('状态修改成功。');
                        this.props.getCategoryTableList(this.searchCache);
                    }).catch((reject = {}) => {
                        message.error(reject.message);
                    });
                break;
            case 'sort'://排序
                http('/instructionClass/updateClassSortNo', {
                    id: item.id,
                    sortNo: value
                }, 'POST')
                    .then(() => {
                        message.success('排序修改成功。');
                        this.props.getCategoryTableList(this.searchCache);
                    }).catch((reject = {}) => {
                        message.error(reject.message);
                    });
                break;
        }
    }

    renderModal = () => {
        switch (this.state.currentOpenMode) {
            case 'CREAT':
                return <CategoryModal title='新建分类' visible={true} closeMode={this._closeMode} />;
            case 'EDITE':
                return <CategoryModal title='编辑分类' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }
    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getCategoryTableList(this.searchCache);
        }
    }

    render() {
        const { noticeName, status } = this.state;
        const { tableList, states } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='分类名称'
                                placeholder='请输入分类名称'
                                value={noticeName}
                                bindThis={this}
                                bindName='noticeName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={states}
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                                bindThis={this}
                                bindName='status'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: 'auto' }}
                            label='新建分类'
                            onClick={this._newHandle}
                        />
                    </div>
                    <CategoryTable renderData={tableList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this.renderModal()}
                </div>
            </KeepAlive>
        );
    }
}


Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    tableList: PropTypes.object,
    getCategoryTableList: PropTypes.func,//表格数据(action)
    states: PropTypes.array,
    getNoticeState: PropTypes.func,//状态数据(action)
};

const mapStateToProps = (state) => ({
    tableList: state.noticecategory.categoryTableList, // 表格数据
    states: state.noticecategory.noticeState, // 状态
});

export default connect(mapStateToProps, { ...model.actions })(Main);
