import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '活动时间',
            dataIndex: 'startTime',
            key: 'startTime',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => tooltip(<span>{item.startTime}<br />{item.endTime}</span>)
        },
        {
            title: '活动名称',
            dataIndex: 'activityTitle',
            key: 'activityTitle',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动类型',
            dataIndex: 'activityTypeValue',
            key: 'activityTypeValue',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '营销成本(元)',
            dataIndex: 'marketingCosts',
            key: 'marketingCosts',
            width: '10%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '销售收入(元)',
            dataIndex: 'saleIncome',
            key: 'saleIncome',
            width: '10%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '投入产出比',
            dataIndex: 'inputOutputRatio',
            key: 'inputOutputRatio',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
    ]);

    _takeDataSource = (dataList = []) => {
        return dataList;
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;