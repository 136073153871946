/**
 * 开通关闭
 */
import React, { useState } from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XTextArea } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

const OpenOrClose = ({
    data: {
        title = '',
        cityIds = [],
        cityOpenType = '',
        url = '',
        isClose = false
    } = {},
    closeMode
}) => {

    const [loading, setLoading] = useState(false);
    const [reson, setReson] = useState('');

    const onSubmit = () => {
        if (isClose && !reson) {
            message.warn('请输入关闭原因');
            return;
        }
        if (reson && reson.length < 5) {
            message.warn('请输入至少五个字符');
            return;
        }
        setLoading(true);
        http(url, { cityOpenType, cityIds, reson }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    return <Modal
        centered
        title='操作说明'
        visible={true}
        confirmLoading={loading}
        onOk={onSubmit}
        onCancel={() => closeMode(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div className={styles.container}>
            <XTextArea
                inputStyle={{ width: '360px' }}
                label={title}
                placeholder='请输入至少五个字符'
                bindThis={setReson}
                bindName='reson'
                value={reson}
                isRequired={isClose}
            />
        </div>
    </Modal>;
};
OpenOrClose.propTypes = {
    data: PropTypes.object, // 弹框入参
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};
export default OpenOrClose;