import * as T from './actiontypes';

//敏感词库表
export const sensitiveWordInfo = (state = {}, action) => {
    switch(action.type){
        case T.SET_SENSITIVEWORD_INFO: 
            return action.payload.result;
        default:
            return state;
    }
};

//敏感词库表19/11/12
export const sensitiveWordInfos = (state = [], action) => {
    switch(action.type){
        case T.SET_SENSITIVEWORD_INFOS: 
            return action.payload.result;
        default:
            return state;
    }
};
