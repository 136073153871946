import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table, Switch } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, } from '@/components/TableCell';


class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '文章ID',
            dataIndex: 'id',
            key: 'id',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '文章标题',
            dataIndex: 'title',
            key: 'title',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '使用位置',
            dataIndex: 'useScene.value',
            key: 'useScene.value',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'state.code',
            key: 'state.code',
            width: '15%',
            align: 'center',
            render: (text, record) => this.switchCell(text, record)
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: '25%',
            align: 'center',
            render: (text, record) => this._renderActionCell(record.id, record)
        }
    ]);

    _renderActionCell = (key, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction('edit', record)}>编辑</div>
            </div>
        );
    }

    switchCell = (text, record) => {
        const { tableAction } = this.props;
        let checked = true;
        if (text == '0') {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checkedChildren="开"
                    unCheckedChildren="关"
                    checked={checked}
                    onChange={(value) => {
                        tableAction('state', record, value);
                    }}
                />
            </div>
        );
    };


    _takeDataSource = (dataList = []) => {
        return dataList;
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    tableAction: PropTypes.func, // 对表格的操作
};

export default TableContent;