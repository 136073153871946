/**
 *  秀场管理 -氛围管理
 * */ 
import React from 'react';
import PropTypes from 'prop-types'; 
import Main from './pages/main';

class Atmospheremanagement extends React.Component {

    render(){
        return(
            <Main /> 
        );
    }
}

Atmospheremanagement.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
};



export default Atmospheremanagement;