import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './NavPosition.module.scss';
import Breadcrumb from '@/components/Breadcrumb';

class NavPosition extends React.Component {

    render() {
        const { breadcrumbPaths, history } = this.props;
  
        return (
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.column_line}></div>
                    <div className={styles.module_name}>{breadcrumbPaths.length > 1 ? breadcrumbPaths[1].title : '欢迎回来！'}</div>
                </div>
                <div className={styles.right}>
                    <img className={styles.icon} src={require('@/assets/image/dingwei.png')} />
                    <div className={styles.icon_label}>位置：</div>
                    <Breadcrumb renderData={breadcrumbPaths} history={history} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    breadcrumbPaths: state.home.breadcrumbPaths
});

NavPosition.propTypes = {
    breadcrumbPaths: PropTypes.array,
    history: PropTypes.object,
    menuList: PropTypes.array,
};

export default connect(mapStateToProps, null)(NavPosition);