import * as SUGGESTIONS from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
export const suggestionsList = (state = initData, action) => {
    switch (action.type) {
        case SUGGESTIONS.SET_SUGGESTIONS_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const suggestionsDetail = (state = {}, action) => {
    switch (action.type) {
        case SUGGESTIONS.SET_SUGGESTIONS_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};
export const suggestionsType = (state = [], action) => {
    switch (action.type) {
        case SUGGESTIONS.SET_SUGGESTIONS_TYPE:
            return action.payload.result;
        default:
            return state;
    }
};
export const suggestionsPort = (state = [], action) => {
    switch (action.type) {
        case SUGGESTIONS.SET_SUGGESTIONS_PORT:
            return action.payload.result;
        default:
            return state;
    }
};