import React, { Component } from 'react';
import { Table, Switch } from 'antd';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, tooltipClick } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';

export default class ServiceTable extends Component {
    constructor(props) {
        super(props);
    }
    _columns = () => ([
        {
            title: '服务编码',
            dataIndex: 'serviceNo',
            key: 'serviceNo',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => tooltipClick(text, () => this.props.tableAction(item.id, 'servicesNum', item))
        },
        {
            title: '服务名称',
            dataIndex: 'serviceName',
            key: 'serviceName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '市场价(元)',
            dataIndex: 'marketPriceVlaue',
            key: 'marketPriceVlaue',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '销售价(元)',
            dataIndex: 'salePriceVlaue',
            key: 'salePriceVlaue',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '已售',
            dataIndex: 'saleNum',
            key: 'saleNum',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '最后更新时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '推送端口',
            dataIndex: 'issuePortName',
            key: 'issuePortName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '启用/禁用',
            dataIndex: 'serviceStatusName',
            key: 'serviceStatusName',
            width: '10%',
            align: 'center',
            render: (text, item) => (this.SwitchCell(text, item, 'state'))
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '15%',
            render: (id, record) => this._TableAction(id, record)
        }
    ]);

    // 解析表格数据，使符合columns格式要求 
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                item.marketPriceVlaue = regFenToYuan(item.marketPrice);
                item.salePriceVlaue = regFenToYuan(item.salePrice);
                item.issuePortName = item.issuePort && Object.keys(item.issuePort).length > 0 ? item.issuePort.value : '';
                item.serviceStatusName = item.serviceStatus && Object.keys(item.serviceStatus).length > 0 ? item.serviceStatus.code : '';
                return item;
            });
        }
    }

    // 表格项内容为开关
    SwitchCell = (sw, item, type) => {
        let checked = true;
        if (sw == 0) {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    key={item.id}
                    checkedChildren='ON'
                    unCheckedChildren='OFF'
                    checked={checked}
                    onChange={(value) => {
                        this.props.tableAction(item, type, value);
                    }}
                />
            </div>
        );
    };

    _TableAction = (id, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, 'detail', record)}>详情</div>
                <div className={styles.item} onClick={() => tableAction(id, 'edit', record)}>编辑</div>
                <div className={styles.item} onClick={() => tableAction(id, 'feeSetting', record)}>费用比例设置</div>
            </div>
        );
    };

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{height:'100%'}}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

ServiceTable.propTypes = {
    renderData: PropTypes.object, // 表格数据源
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func, // 分页
};