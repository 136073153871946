export function DateFormat(date, format) {
    var o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        'S': date.getMilliseconds() //毫秒   
    };
    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp('(' + k + ')').test(format))
            format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
    return format;
}
export function timeToMinute(times) {
    let result = '00:00:00';
    let hour, minute, second;
    if (times > 0) {
        hour = Math.floor(times / 3600);
        if (hour < 10) {
            hour = '0' + hour;
        }
        minute = Math.floor((times - 3600 * hour) / 60);
        if (minute < 10) {
            minute = '0' + minute;
        }
        second = Math.floor((times - 3600 * hour - 60 * minute) % 60);
        if (second < 10) {
            second = '0' + second;
        }
        if (hour == '00') {
            result = minute + ':' + second;
        }
        else if (minute == '00') {
            result = hour + ':' + minute + ':' + second;
        }
        else {
            result = second;
        }
    }
    return result;
}
export function moneyFormat(value, opts = { fractionDigits: 2 }) {
    return typeof value === 'number' ? (value / 100).toFixed(opts.fractionDigits) : (opts.empty || '???');
}

export function numberSplit(value, begin, after, spliter = ' ') {
    return typeof value === 'string' ? value.substring(0, begin) + spliter + value.substring(begin, value.length).replace(new RegExp('(\\d{' + after + '})(?=\\d)', 'g'), '$1' + spliter) : '-';
}