/**
 * 新建/编辑须知列表
 */
import React from 'react';
import { Modal, } from 'antd';
import { XInput, } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './NoticeTableModal.module.scss';
import { message, Radio } from 'antd';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;

class Category extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        titleName: this.props.data !== undefined ? this.props.data.titleName : '',//标题名称
        radioValue: this.props.data !== undefined ? this.props.data.state : undefined,//状态
    }

    componentDidMount() {

    }

    _handleOk = () => {
        const { titleName, radioValue } = this.state;
        const { data, curTabID } = this.props;
        if (titleName == '') return message.warn('请输入标题名称。');
        if (radioValue === undefined) return message.warn('请选择状态。');
        let submitURL = data === undefined ? 'addInstructionTitle' : 'updateInstructionTitle';
        let submitData = {};
        if (data === undefined) {
            submitData = {
                classId: curTabID,
                titleName: titleName,
                state: radioValue,
            };
        } else {
            submitData = {
                id: data.id,
                titleName: titleName,
                state: radioValue,
            };
        }
        this.setState({ confirmLoading: true }, () => {
            http(`/instructionTitle/${submitURL}`, submitData, 'POST')
                .then(() => {
                    data === undefined ? message.success('新建成功。') : message.success('更新成功。');
                    this.setState({
                        visible: false,
                        confirmLoading: false
                    });
                    this.props.closeMode(true);
                }).catch((reject = {}) => {
                    message.error(reject.message);
                    this.setState({ confirmLoading: false });
                });
        });
    }
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // Radio状态改变时触发
    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }

    //验证-标题名称
    titleChange = (value) => {
        if (value.length > 12) {
            this.setState({
                titleName: value.substring(0, 12)
            });
        } else {
            this.setState({
                titleName: value
            });
        }
    }

    render() {
        const { visible, title, confirmLoading, titleName, radioValue, } = this.state;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                onOk={this._handleOk}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                okText='确定'
                cancelText='取消'
            >

                <div className={styles.container}>
                    <div className={`${styles.layout} ${styles.dataTop}`}>
                        <XInput
                            inputStyle={{ width: '270px' }}
                            label='标题名称'
                            placeholder='请输入标题名称'
                            value={titleName}
                            isRequired={true}
                            onChange={this.titleChange}
                            suffix={titleName.length < '13' && `(${titleName.length}/12)`}
                        />
                    </div>
                    <div className={styles.radio}>
                        <div className={styles.label}><i>*</i>状态</div>
                        <RadioGroup style={{ marginLeft: '38px' }} onChange={this._radioValueChangeHandle} value={radioValue}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                </div>
            </Modal>
        );
    }
}

Category.propTypes = {
    title: PropTypes.string,// 弹框标题
    data: PropTypes.object, //数据
    curTabID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),//新建标题分类id
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default (Category);