import { createModel } from '@/store/tools';

const model = {
    namespace: 'saler',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getSalersList': T('getSalersList'),
            'getSalerInfo': T('getSalerInfo'),
            'getSaleOrder': T('getSaleOrder'),
            'getSaleGoods': T('getSaleGoods'),
            'getBalance': T('getBalance'),
            'getCostList': T('getCostList'),
            'getSaleCostEnum': T('getSaleCostEnum'),
            'getSourceTypeEnum': T('getSourceTypeEnum'),
            'getPlatDividendIncomeList': T('getPlatDividendIncomeList'),
            'getClassifyEnum': T('getClassifyEnum'),
            'getSourceEnum': T('getSourceEnum'),
            'getSalesmanStateEnum': T('getSalesmanStateEnum'),

            'getSalesmanPoints': T('getSalesmanPoints'),
        },
        actions: {
            'getSalersList': A('getSalersList'), // 获取销售员列表
            'getSalerInfo': A('getSalerInfo'), // 获取销售员信息
            'getSaleOrder': A('getSaleOrder'), // 获取销售订单
            'getSaleGoods': A('getSaleGoods'), // 获取销售商品
            'getBalance': A('getBalance'), // 获取销售员收益
            'getCostList': A('getCostList'), // 获取销售员收益
            'getSaleCostEnum': A('getSaleCostEnum'), // 获取销售员费用枚举
            'getSourceTypeEnum': A('getSourceTypeEnum'), // 获取来源类型枚举
            'getPlatDividendIncomeList': A('getPlatDividendIncomeList'),
            'getClassifyEnum': A('getClassifyEnum'),
            'getSourceEnum': A('getSourceEnum'), // 获取来源类型枚举
            'getSalesmanStateEnum': A('getSalesmanStateEnum'), // 获取状态类型枚举

            'getSalesmanPoints': A('getSalesmanPoints'), // 获取积分余额
        },
        sagas: {
            'getSalersList': S('getSalersList', '/user/account/listSalesmanInfo'),
            'getSalerInfo': S('getSalerInfo', '/user/account/getSalesmanInfo', 'POST', 'id'),
            'getSaleOrder': S('getSaleOrder', '/boss/order/getSalesManDistributionProfitOrderList'),
            'getSaleGoods': S('getSaleGoods', '/goods/wholesale/findSalesmanShopGoodsOprLog'),
            'getBalance': S('getBalance', 'settle/accountBalance/salesman', 'POST', 'id'),
            'getCostList': S('getCostList', 'settle/reconciliationSource/listCostDetail'),
            'getSaleCostEnum': S('getSaleCostEnum', '/enum/SalesmanCostSettingEnum'),
            'getSourceTypeEnum': S('getSourceTypeEnum', '/enum/SalesmanReferrerRoleEnum'),
            'getPlatDividendIncomeList': S('getPlatDividendIncomeList', '/settle/balanceChange/queryPlatformIncome'),
            'getClassifyEnum': S('getClassifyEnum', '/enum/dividendsIncomeType'),
            'getSourceEnum': S('getSourceEnum', '/enum/SalesmanReferrerRoleExpEnum'),
            'getSalesmanStateEnum': S('getSalesmanStateEnum', '/enum/SalesmanStateEnum'),

            'getSalesmanPoints': S('getSalesmanPoints', '/settle/accountBalance/salesmanPoints', 'POST', 'id'),
        },
        reducers: {
            'getSalersList': R('getSalersList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getSalerInfo': R('getSalerInfo', {}),
            'getSaleOrder': R('getSaleOrder', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getSaleGoods': R('getSaleGoods', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getBalance': R('getBalance', {}),
            'getCostList': R('getCostList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getSaleCostEnum': R('getSaleCostEnum', []),
            'sourceTypeEnum': R('getSourceTypeEnum', []),
            'platDividendIncomeList': R('getPlatDividendIncomeList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'classifyEnum': R('getClassifyEnum', []),
            'sourceEnum': R('getSourceEnum', []),
            'salesmanStateEnum': R('getSalesmanStateEnum', []),

            'getSalesmanPoints': R('getSalesmanPoints', {}),
        }
    })
};
export default createModel(model);