export const GET_INVOICELIST = 'INVOICELIST/GET_INVOICELIST';
export const SET_INVOICELIST = 'INVOICELIST/SET_INVOICELIST';

// 运营中心对账列表
export const GET_OPERATINGCENTERRECONCILIATION_TABLESOURCE = 'OPERATINGCENTERRECONCILIATION/GET_OPERATINGCENTERRECONCILIATION_TABLESOURCE';
export const SET_OPERATINGCENTERRECONCILIATION_TABLESOURCE = 'OPERATINGCENTERRECONCILIATION/SET_OPERATINGCENTERRECONCILIATION_TABLESOURCE';
// 运营中心下拉
export const GET_OPERATINGCENTERRECONCILIATION_LIST = 'OPERATINGCENTERRECONCILIATION/GET_OPERATINGCENTERRECONCILIATION_LIST';
export const SET_OPERATINGCENTERRECONCILIATION_LIST = 'OPERATINGCENTERRECONCILIATION/SET_OPERATINGCENTERRECONCILIATION_LIST';
// 运营对账详情
export const GET_OPERATINGCENTERRECONCILIATION_DETAIL = 'OPERATINGCENTERRECONCILIATION/GET_OPERATINGCENTERRECONCILIATION_DETAIL';
export const SET_OPERATINGCENTERRECONCILIATION_DETAIL = 'OPERATINGCENTERRECONCILIATION/SET_OPERATINGCENTERRECONCILIATION_DETAIL';