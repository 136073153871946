import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

// 获取费用名称列表
export const getCostNameList = function* () {
    yield takeEvery(T.GET_COSTNAME_LIST, function* requestData(action) {
        try {
            let result = yield http('/costSetting/list', action.payload, 'POST');
            yield put({ type: T.SET_COSTNAME_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

