/**
 * 【会员管理页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { message } from 'antd';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import { AreaModal, SetIntegral, LwMember, LwMemberChangeArea } from '../../modal';
import { connect } from 'react-redux';
import model from '@/reducers/usermanage/member/model';
import TableContent from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { autoPageNum, autopageSize } from '@/assets/config';
import { isPlat } from '@/assets/js/authType';
import { getLocalStorage } from '@/assets/js/storage';
import { SearchBox, SearchItem } from '@/components/Layout';
import userList from '@/reducers/systemmanage/merchantpermission/model';

const { getMerchants } = userList.actions;

// 弹框枚举类型
const Mode = {
    AREA_CHANGE: 'AREA_CHANGE', // 修改区域弹框
    AREA_CHANGE_SINGLE: 'AREA_CHANGE_SINGLE', // 修改单个区域弹框
    LW_MEMBER: 'LW_MEMBER', // 服务天使添加
    LW_MEMBER_AREA_CHANGE: 'LW_MEMBER_AREA_CHANGE', // 服务天使修改区域
    SetIntegral: 'SetIntegral'
};

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            curMode: '', // 当前弹框类型
            modeParam: [], // 弹框传参
            telephone: '', // 会员账号
            nickname: '', // 昵称
            source: {}, // 用户来源
            OCSelect: {}, // 归属运营中心
            RESelect: {}, // 归属区域公司
            selectedRowKeys: [], // 复选框勾选
            selectedRows: [], // 复选框勾选记录
            accountType: {},
        };
    }

    componentDidMount() {
        const { getLwUserSource, getOperationCenter, getLwAccountTypeEnum } = this.props;
        getLwUserSource();
        getOperationCenter();
        getLwAccountTypeEnum();

        const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
        if (userInfo != null) {
            this.props.getAreaCompany({ operationsId: userInfo.companyId });
        }
    }

    // 表格操作
    _tableAction = (id, type, item) => {
        if (type === 'CHANGE_AREA') {
            this.setState({ curMode: Mode.LW_MEMBER_AREA_CHANGE, modeParam: { ids: [id], areaCompanyType: item.areaCompanyType, source: item.source } }); // 修改区域弹框
        } else {
            this.props.updateContent(id, type); // 渲染其它页面
        }
    }

    // 打开弹框
    _openMode = (type) => {
        if (this.state.selectedRowKeys.length > 0 || type === Mode.STARSCORE_CHANGE || type === Mode.SetIntegral) {
            const accountIds = this.state.selectedRowKeys;
            this.setState({ curMode: type, modeParam: accountIds });
        } else {
            message.warning('请先选中一个或多个会员！');
        }
    }

    _openChangeArea = (type, param, title) => {
        const { selectedRows, selectedRowKeys } = this.state;

        if (type === Mode.LW_MEMBER_AREA_CHANGE) {
            if (selectedRowKeys.length === 0) {
                message.warning('请先选中一个或多个会员！');
                return;
            }

            this.setState({
                curMode: Mode.LW_MEMBER_AREA_CHANGE,
                modeParam: { ids: selectedRowKeys, areaCompanyType: selectedRows[0].areaCompanyType, source: 'AGENT' }
            });
        } else {
            this.setState({ curMode: Mode.LW_MEMBER, modeParam: { param, title } });
        }
    }

    // 渲染弹框
    _renderMode = () => {
        const { modeParam } = this.state;
        switch (this.state.curMode) {
            case Mode.AREA_CHANGE_SINGLE:
                return <AreaModal visible={true} data={modeParam} closeMode={this._closeMode} />;
            case Mode.LW_MEMBER:
                return <LwMember visible={true} data={modeParam} closeMode={this._closeMode} />;
            case Mode.LW_MEMBER_AREA_CHANGE:
                return <LwMemberChangeArea visible={true} data={modeParam} closeMode={this._closeMode} />;
            case Mode.SetIntegral:
                return <SetIntegral visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ curMode: '', selectedRowKeys: [] });
        if (update) {
            this.props.getLwMemberList(this.searchCache);
        }
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { telephone, nickname, source, pageNum, pageSize, OCSelect, RESelect, accountType } = this.state;
        this.searchCache = {
            nickname,
            tel: telephone,
            source: source.code,
            operationsCenterId: OCSelect.id,
            areaCompanyId: RESelect.id,
            accountType: accountType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getLwMemberList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ telephone: '', nickname: '', source: {}, OCSelect: {}, RESelect: {}, accountType: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 每页pageSize条，当前第pageNum页
    _paginationChange = (pageSize, pageNum) => {
        // 方法一
        this.searchCache = { ...this.searchCache, isAnchor: 0, pageSize, pageNum };
        this.props.getLwMemberList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 渲染运营中心下拉列
    _renderOCSelect = () => {
        if (isPlat()) {
            return (
                <XSelect
                    label='归属运营中心'
                    placeholder='请选择运营中心'
                    renderData={this.props.OCSelectList}
                    dataIndex='companyName'
                    keyIndex='id'
                    value={this.state.OCSelect.companyName}
                    onChange={this._OCSelectChangeHandle}
                    bindThis={this}
                    bindName='OCSelect'
                    showSearch={true}
                />
            );
        }
    }

    // 运营中心发生变化
    _OCSelectChangeHandle = (ocItem) => {
        this.props.getAreaCompany({ operationsId: ocItem.id });
    }

    // 渲染区域公司下拉列
    _renderRESelect = () => {
        if (isPlat()) {
            return (
                <XSelect
                    label='归属区域公司'
                    placeholder='请选择区域公司'
                    renderData={this.props.RESelectList}
                    dataIndex='companyName'
                    keyIndex='id'
                    value={this.state.RESelect.companyName}
                    bindThis={this}
                    bindName='RESelect'
                    showSearch={true}
                />
            );
        }
    }

    render() {
        const { telephone, nickname, source, selectedRowKeys, selectedRows, accountType } = this.state;
        const { lwMemberList, lwUserSource, lwAccountTypeEnum } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='会员账号'
                                placeholder='请输入会员账号'
                                value={telephone}
                                bindThis={this}
                                bindName='telephone'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='会员昵称'
                                placeholder='请输入会员昵称'
                                value={nickname}
                                bindThis={this}
                                bindName='nickname'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='会员类型'
                                placeholder='请选择'
                                renderData={lwAccountTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={accountType.value}
                                bindThis={this}
                                bindName='accountType'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='来源'
                                placeholder='请选择会员来源'
                                renderData={lwUserSource}
                                dataIndex='value'
                                keyIndex='code'
                                value={source.value}
                                bindThis={this}
                                bindName='source'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            {this._renderOCSelect()}
                        </SearchItem>
                        <SearchItem>
                            {this._renderRESelect()}
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <ButtonPerssion code='usermanage/giftmember/pxjlwhy'>
                            <XOKButton style={{ marginLeft: '0px', marginRight: '20px', width: '140px' }} label='新建服务天使' onClick={() => this._openChangeArea(Mode.LW_MEMBER, '/user/account/addAgentAccount', '新建服务天使')} />
                        </ButtonPerssion>
                        <ButtonPerssion code='usermanage/giftmember/plxgqy'>
                            <XOKButton style={{ marginRight: '20px', width: '120px' }} label='批量修改区域' onClick={() => this._openChangeArea(Mode.LW_MEMBER_AREA_CHANGE)} />
                        </ButtonPerssion>
                        <ButtonPerssion code='usermanage/giftmember/plzstqz'>
                            <XOKButton style={{ width: '140px' }} label='批量赠送特权值' onClick={() => this._openMode(Mode.SetIntegral)} />
                        </ButtonPerssion>
                    </div>
                    <TableContent
                        renderData={lwMemberList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                        selectedRowKeys={selectedRowKeys}
                        selectedRows={selectedRows}
                        selectedRowKeysChange={(selectedRowKeys, selectedRows) => { this.setState({ selectedRowKeys, selectedRows }); }}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    getListAreaByParentId: PropTypes.func, // 请求地区信息
    getLwUserSource: PropTypes.func, // 获取用户来源
    lwUserSource: PropTypes.array, // 用户来源
    getOperationCenter: PropTypes.func, // 获取归属运营中心
    OCSelectList: PropTypes.array, // 归属运营中心下拉列
    getAreaCompany: PropTypes.func, // 获取归属区域公司
    RESelectList: PropTypes.array, // 归属区域公司下拉列

    getLwAccountTypeEnum: PropTypes.func, // 获取用户类型
    lwAccountTypeEnum: PropTypes.array, // 用户类型

    getLwMemberList: PropTypes.func, // 获取服务天使
    lwMemberList: PropTypes.array, // 服务天使
};

const mapStateToProps = (state) => ({
    lwUserSource: state.member.lwUserSource,
    address: state.member.address,
    OCSelectList: state.member.getOperationCenter,
    RESelectList: state.member.getAreaCompany,
    merchants: state.merchantpermission.merchants,
    lwAccountTypeEnum: state.member.lwAccountTypeEnum,

    lwMemberList: state.member.lwMemberList,
});

export default connect(mapStateToProps, { ...model.actions, getMerchants })(Main);