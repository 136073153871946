/**
 * 审核详情弹框
 */
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import styles from './DetailModal.module.scss';
import { getVideoAuditDetail } from '@/reducers/auditmanage/videoAudit/actions';
import TableContent from '../conpontents/TableContent';
import { XMediaFn } from '@/components/xqxc_ui';

class DetailModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        videoDetail: { state: {} },
        status: '',
    }

    componentDidMount() {
        const { audit } = this.props;
        this.props.getVideoAuditDetail({ accountId: audit.accountId, feedId: audit.id, showType: audit.showType });
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { auditDetail } = newProps;
        this.setState({ videoDetail: auditDetail });
    }

    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }


    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _state = (state) => {
        if (state === 'BANNED') {
            return '禁播';
        }
        if (state === 'APPLY') {
            return '审批中';
        }
        if (state === 'APPROVAL') {
            return '审批通过';
        }
        if (state === 'REJECT') {
            return '审批不通过';
        }
    }

    render() {
        const { visible, confirmLoading, videoDetail } = this.state;
        const { goodsList } = videoDetail;
        return (
            <Modal
                width={1000}
                bodyStyle={{ paddingRight: 10 }}
                centered
                title='视频详情'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.memberID}>
                        <span>会员账号：</span>
                        <span>{videoDetail.accountName}</span>
                    </div>
                    <div className={styles.cover}>
                        <p className={styles.label}>提交封面：</p>
                        <XMediaFn dataSource={videoDetail.coverPage} />
                    </div>
                    <div className={styles.video}>
                        <p className={styles.label}>提交视频：</p>
                        <XMediaFn
                            dataType='video'
                            dataSource={videoDetail.fileName}
                        />
                    </div>
                    <div className={styles.describe}>
                        <span>视频标题：</span>
                        <span>{videoDetail.feedTitle}</span>
                    </div>
                    <div className={styles.describe}>
                        <span>视频分类：</span>
                        <span>{videoDetail.className}</span>
                    </div>
                    <div className={styles.tables}>
                        <TableContent renderData={goodsList || []} />
                    </div>
                    <div className={styles.status}>
                        <span>状&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;态：</span>
                        <span>{videoDetail.state.value}</span>
                    </div>
                    <div className={styles.reason}>
                        <span>原&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;因：</span>
                        <span>{videoDetail.remark}</span>
                    </div>
                </div>
            </Modal>
        );
    }
}

DetailModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getVideoAuditDetail: PropTypes.func,
    auditDetail: PropTypes.object,
    audit: PropTypes.object,
    getvideoQueryDetail: PropTypes.func, // 通过Saga异步请求社区详情
};


const mapStateToProps = (state) => ({
    auditDetail: state.videoAudit.videoAuditDetail
});

export default connect(mapStateToProps, { getVideoAuditDetail })(DetailModal);