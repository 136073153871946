//魅力等级设置列表
export const GET_CHARMGRADE_LIST = 'SYSTEMMANAGE/GET_CHARMGRADE_LIST';
export const SET_CHARMGRADE_LIST = 'SYSTEMMANAGE/SET_CHARMGRADE_LIST';

//魅力等级设置详情
export const GET_CHARMGRADE_DETAIL= 'SYSTEMMANAGE/GET_CHARMGRADE_DETAIL';
export const SET_CHARMGRADE_DETAIL = 'SYSTEMMANAGE/SET_CHARMGRADE_DETAIL';

//魅力等级设置优惠券下拉
export const GET_CHARMGRADE_COUPONSELE= 'SYSTEMMANAGE/GET_CHARMGRADE_COUPONSELE';
export const SET_CHARMGRADE_COUPONSELE= 'SYSTEMMANAGE/SET_CHARMGRADE_COUPONSELE';

//魅力等级设置单选框
export const GET_CHARMGRADE_RADIO= 'SYSTEMMANAGE/GET_CHARMGRADE_RADIO';
export const SET_CHARMGRADE_RADIO= 'SYSTEMMANAGE/SET_CHARMGRADE_RADIO';