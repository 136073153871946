import * as T from './actiontypes';

export const getShowSettingList = requestProps => ({
    type: T.GET_SHOWSETTING_LIST,
    payload: requestProps
});

export const getLiveDurationRechargeRulesTableSource = requestProps => ({
    type: T.GET_LIVEDURATIONRECHARGERULES_TABLESOURCE,
    payload: requestProps
});

export const getToupRulesTableSource = requestProps => ({
    type: T.GET_TOUPRULES_TABLESOURCE,
    payload: requestProps
});
//兑换设置
/** 
 * 赠送好友人气值限额(saga)
 * @param {array} paramCodes 赠送好友人气值限额
 */
export const getchangOtherData = (type) => ({
    type: T.GET_CHANGOTHERDATA_DATA,
    payload: {
        type,
    }
});
/** 
 * 兑换设置数据源(saga)
 * @param {array} paramCodes 无参数则请求全部
 */
export const getchangData = (paramCodes) => ({
    type: T.GET_CHANGDATA_DATA,
    payload: {
        paramCodes,
    }
});

//快速回复
/** 
 * 快速回复列表数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} content 模板名称	
 * @param {string} state 状态
 */

export const getreplyList = (dataload) => ({
    type: T.GET_REPLYLIST_DATA,
    payload: {
        ...dataload,
    }
});
//人气规则
/** 
 *人气规则列表数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} virtualName 虚拟规则名称	
 * @param {string} state 状态
 * @param {string}  频道分类
 */

export const getpopularList = (dataload) => ({
    type: T.GET_POPULARLIST_DATA,
    payload: {
        ...dataload,
    }
});

/** 
 *人气规则详情数据源(saga)
 * @param {number} id id对应的详情
 */

export const getpopularDetail = (id) => ({
    type: T.GET_POPULARDETAIL_DATA,
    payload: {
        id,
    }
});

//推荐规则
/** 
 *推荐规则列表数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} ruleName 规则名称	
 * @param {string} startTime 下单时间开始  
 * @param {string} endTime 下单时间结束
 * @param {string} classTypeCode 频道分类
 */

export const getrecommendList = (dataload) => ({
    type: T.GET_RECOMMENDLIST_DATA,
    payload: {
        ...dataload,
    }
});

/** 
 *推荐规则频道分类(saga)  
 * @param 暂无
 */

export const getrecommendSelect = (id) => ({
    type: T.GET_RECOMMENDSELECT_DATA,
    payload: {
        id,
    }
});

/** 
 *推荐规则--推荐规则下拉内容(saga)  
 * @param 暂无
 */
export const getrecommendContent = (id) => ({
    type: T.GET_RECOMMENDCENTENT_DATA,
    payload: {
        id,
    }
});
/** 
 *推荐规则详情数据源(saga)
 * @param {number} id id对应的详情
 */

export const getrecommendDetail = (id) => ({
    type: T.GET_RECOMMENDDETAIL_DATA,
    payload: {
        id,
    }
});
