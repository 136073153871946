import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import { Modal, Table, Button } from 'antd';
import { priceFenToYuanCell, tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';
import model from '@/reducers/starvalueshop/redemption/model';
import { XInfoLabel } from '@/components/xqxc_ui';

const PutawayModal = ({
    closeMode,
    modeParams: {
        exchangeGoodsId = ''
    } = {},
    getSkuInfoList,
    skuInfoList: {
        costDetailNames = [],
        exchangeGoodsSkuList = [],
        freightTemplateName = '',
        goodsName = ''
    },
    clearSkuInfoList
}) => {

    const [columns, setColumns] = useState([
        {
            title: '商品图片',
            dataIndex: 'imgUrl',
            key: 'imgUrl',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: ImgCell
        },
        {
            title: 'SKU编码',
            dataIndex: 'skuNo',
            key: 'skuNo',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '规格属性',
            dataIndex: 'spec',
            key: 'spec',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '销售价(元)',
            dataIndex: 'salePrice',
            key: 'salePrice',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '券抵用',
            dataIndex: 'exchangePloyValue',
            key: 'exchangePloyValue',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: text => priceFenToYuanCell(-text)
        },
        {
            title: '税费(元)',
            dataIndex: 'taxPrice',
            key: 'taxPrice',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '运费(元)',
            dataIndex: 'freightValue',
            key: 'freightValue',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        }
    ]);

    useEffect(() => {
        getSkuInfoList({ exchangeGoodsId });
        return () => {
            clearSkuInfoList();
        };
    }, []);

    const deWeight = (arr) => {
        let obj = {};
        return arr.reduce(function (item, next) {
            obj[next.key] ? '' : obj[next.key] = true && item.push(next);
            return item;
        }, []);
    };

    useEffect(() => {
        if (costDetailNames.length) {
            let list = [];
            costDetailNames.forEach((item, index) => {
                let ele = {
                    title: item,
                    dataIndex: `attachValue${index + 1}`,
                    key: `attachValue${index + 1}`,
                    width: '8%',
                    align: 'center',
                    onCell: tooltipStyle,
                    render: priceFenToYuanCell
                };
                list.push(ele);
            });
            setColumns(deWeight([...columns, ...list]));
        }
    }, [costDetailNames]);

    return (
        <Modal
            width={1200}
            centered
            title={goodsName}
            bodyStyle={{ paddingTop: '10px', paddingBottom: '10px' }}
            visible={true}
            confirmLoading={false}
            onCancel={() => closeMode(false)}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            footer={false}
        >
            <div className={styles.container}>
                <XInfoLabel label='运费模板：' value={freightTemplateName} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={[...columns, {
                        title: '实付金额(元)',
                        dataIndex: 'payment',
                        key: 'payment',
                        width: '8%',
                        align: 'center',
                        onCell: tooltipStyle,
                        render: priceFenToYuanCell
                    }]}
                    dataSource={exchangeGoodsSkuList}
                    pagination={false}
                    scroll={{ y: 500 }}
                />
                <div className={styles.buttonBox}>
                    <Button onClick={() => closeMode(false)}>取消</Button>
                </div>
            </div>
        </Modal >
    );
};

PutawayModal.propTypes = {
    closeMode: PropTypes.func,
    modeParams: PropTypes.object,
    getSkuInfoList: PropTypes.func,
    skuInfoList: PropTypes.object,
    clearSkuInfoList: PropTypes.func
};
export default connect(({ redemption }) => ({
    skuInfoList: redemption.skuInfoList,
}), {
    ...model.actions
}
)(PutawayModal);