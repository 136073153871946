import * as T from './actiontypes';

/**
 * 获取员工列表
 */
export const getEmplList = (value = {}) => ({
    type: T.GET_EMPL_LIST,
    payload: value
});

/** 
 * 获取员工详情
 */
export const getEmplDetail = (value = {}) => ({
    type: T.GET_EMPL_DETAIL,
    payload: value
});

/**
 * 获取部门下拉列表
 */
export const getDeptSelect = (value = {}) => ({
    type: T.GET_DEPT_SELECT,
    payload: value
});

/**
 * 获取员工状态
 */
export const getEmplState = (value = {}) => ({
    type: T.GET_EMPL_STATE,
    payload: value
});

/**
 * 获取员工所属分类
 */
export const getEmplType = (value = {}) => ({
    type: T.GET_EMPL_TYPE,
    payload: value
});




