import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import KeepAlive from '@/routes/KeepAlive';
import { connect } from 'react-redux';
import { autoPageNum, autopageSize } from '@/assets/config';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import model from '@/reducers/storehousefinancialmanage/channel/model';

const { getAdvanceTableData } = model.actions;

const TableComponent = ({
    renderData: {
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0,
        dataList = []
    } = {},
    paginationChange,
    tableAction,
}) => {
    const tableOperate = (id, r) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => tableAction(r, 'detail')}>明细</div>
        </div >;
    };
    const columns = [
        {
            title: '渠道账号',
            dataIndex: 'accountName',
            key: 'accountName',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '预付款余额',
            dataIndex: 'balance',
            key: 'balance',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '预付款冻结金额',
            dataIndex: 'frozenBalance',
            key: 'frozenBalance',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '预付款总金额',
            dataIndex: 'totalBalance',
            key: 'totalBalance',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '预付款总支出金额',
            dataIndex: 'userBalance',
            key: 'userBalance',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '操作',
            dataIndex: 'subjectId',
            key: 'subjectId',
            align: 'center',
            onCell: tooltipStyle,
            render: tableOperate
        }
    ];

    return <div className={styles.tableContainer}>
        <Table
            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
            rowKey='subjectId'
            columns={columns}
            dataSource={dataList}
            pagination={false}
            scroll={{ y: true }}
        />
        <XPagination
            resultTotal={resultTotal}
            pageSize={pageSize}
            pageNum={pageNum}
            onChange={paginationChange}
        />
    </div>;
};
TableComponent.propTypes = {
    history: PropTypes.object,
    renderData: PropTypes.object,
    paginationChange: PropTypes.func,
    tableAction: PropTypes.func,
};

const ChannelImprest = ({
    history,
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getAdvanceTableData,
    tableData,
}) => {

    const [accountName, setAccountName] = useState();
    const [pageNum, setPageNum] = useState(autoPageNum);
    const [pageSize, setPageSize] = useState(autopageSize);

    useEffect(() => {
        keepSecondNavBreadcrumb();
    }, []);

    const getTableData = (params = {}) => {
        getAdvanceTableData({
            accountName: params.accountName,
            pageNum: params.pageNum, pageSize: params.pageSize
        });
        KeepAlive.saveCache(params);
    };

    const paginationChange = (ps, pn) => {
        setPageNum(pn);
        setPageSize(ps);
        getTableData({ accountName, pageNum: pn, pageSize: ps });
    };

    const resetSearch = () => {
        setAccountName();
        KeepAlive.saveResetFunc(() => getTableData({ accountName, pageNum, pageSize }));
    };

    const tableAction = (key, type) => {
        const baseUrl = '/home/storehousefinancialmanage/channelimprest';
        const jumpPage = (path, title) => {
            addOneBreadcrumbPath({ title, path: `${baseUrl}${path}` });
            history.push({ pathname: `${baseUrl}${path}` });
        };
        const eventObject = {
            detail: () => jumpPage(`/detaillist/${key.subjectId}/${key.accountName}`, '明细列表'),
        };
        eventObject[type]();
    };

    const onChange = (p = {}) => {
        if (p.accountName) setAccountName(p.accountName);
        if (p.pageNum != autoPageNum) setPageNum(p.pageNum);
        if (p.pageSize != autopageSize) setPageSize(p.pageSize);
        getTableData(p);
    };

    return <KeepAlive render={onChange}>
        <div className={styles.flexBoxContainer}>
            <SearchBox>
                <SearchItem>
                    <XInput
                        label='渠道账号'
                        placeholder='请输入'
                        value={accountName}
                        bindThis={setAccountName}
                        bindName='accountName'
                    />
                </SearchItem>
                <SearchItem>
                    <XOKButton
                        label='查询'
                        onClick={() => getTableData({ accountName, pageNum, pageSize })}
                    />
                    <XCancelButton
                        label='重置'
                        onClick={resetSearch}
                    />
                </SearchItem>
            </SearchBox>
            <TableComponent renderData={tableData} tableAction={tableAction} paginationChange={paginationChange} />
        </div>
    </KeepAlive>;
};
ChannelImprest.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getAdvanceTableData: PropTypes.func,
    tableData: PropTypes.object,
};
export default connect(state => ({
    tableData: state.financechannel.advanceTableData
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getAdvanceTableData
})(ChannelImprest);