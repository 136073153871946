/**
 * 活动商品审核-未审核
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import BatchAudit from '../../modal/BatchAudit';
import { XSelect, XOKButton, XCancelButton, XInput, XScrollSelect } from '@/components/xqxc_ui';
import TableContent from '../../components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getActiveProductAuditList, getActiveProductAuditStatus, getActiveProductAuditShopSele } from '@/reducers/auditmanage/activeProductAudit/actions';
import { message } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';

class NoAudit extends React.Component {

    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
        activityName: '', // 活动名称
        activeType: {}, // 活动类型
        productNo: '',//商品货号
        batch: [],//批量数据
        shopObj: {},//店铺下拉对象
    }

    componentDidMount() {
        this.props.getActiveProductAuditStatus();
        // this.props.getActiveProductAuditShopSele();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { activityName, activeType, productNo, shopObj, pageNum, pageSize } = this.state;
        this.searchCache = {
            auditState: 'PENDING', activityTitle: activityName, activityType: activeType.key, goodsNo: productNo, shopId: shopObj.shopId,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getActiveProductAuditList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ activeType: {}, activityName: '', productNo: '', shopObj: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        const { updateContent } = this.props;
        const { activityId, activityGoodsId, goodsId } = params;
        switch (type) {
            case 'audit':
                updateContent('活动商品审核信息', '/audit/', { activityId, activityGoodsId, curTabID: 2, goodsId, id: params.id });
                break;
            case 'detail':
                updateContent('活动商品详情信息', '/detail/', { activityId, activityGoodsId, curTabID: 2 });
                break;
            case 'batch':
                this.setState({ batch: params });
                break;
        }
    }


    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 打开弹窗
    _renderMode = () => {
        const { currentOpenMode, batch } = this.state;
        switch (currentOpenMode) {
            // case 'inventory':
            //     return <InventoryModal data={openModeParam} title='调整库存' visible={true} closeMode={this._closeMode} />;
            case 'audit':
                return <BatchAudit batch={batch} visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '', openModeParam: {} });
        status && this._searchHandle('useCache');
    }

    //批量审核
    _batchAudit = () => {
        if (this.state.batch.length > 0) {
            this.setState({ currentOpenMode: 'audit' });
        } else {
            message.warning('请勾选商品');
        }
    }

    render() {
        const { activeType, activityName, productNo, shopObj } = this.state;
        const { activeProductList, activeProductStatus, activeProductAuditShopSele = {} } = this.props;
        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='活动名称'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='activityName'
                                value={activityName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='活动类型'
                                placeholder='请选择'
                                renderData={activeProductStatus}
                                bindThis={this}
                                bindName='activeType'
                                dataIndex='value'
                                keyIndex='key'
                                value={activeType.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='商品货号'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='productNo'
                                value={productNo}
                            />
                        </SearchItem>
                        {/* <SearchItem>
                            <XSelect
                                label='店铺名称'
                                placeholder='请选择'
                                renderData={activeProductAuditShopSele.infoListResps}
                                bindThis={this}
                                bindName='shopObj'
                                dataIndex='shopName'
                                keyIndex='id'
                                value={shopObj.shopName}
                            />
                        </SearchItem> */}
                        <SearchItem>
                            <div style={{ marginRight: 20 }}>
                                <span>店铺名称</span>
                                <XScrollSelect
                                    style={{ width: 200, marginLeft: 10 }}
                                    url='/shop/baseListByPage'
                                    placeholder='请选择店铺名称'
                                    showSearch
                                    mainKey='shopName'
                                    selectID='shopId'
                                    selectName='shopName'
                                    value={shopObj}
                                    listLabel='dataList'
                                    isOptionBindID={true}
                                    onChange={(value, item) => this.setState({ shopObj: item.props['data-option'] })}
                                />
                            </div>
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton
                            style={{ width: '120px' }}
                            label='批量审核'
                            onClick={this._batchAudit}
                        />
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={activeProductList} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

NoAudit.propTypes = {
    updateContent: PropTypes.func,
    getActiveProductAuditList: PropTypes.func,
    getActiveProductAuditStatus: PropTypes.func,
    getActiveProductAuditShopSele: PropTypes.func,

    activeProductList: PropTypes.object,
    activeProductStatus: PropTypes.array,
    activeProductAuditShopSele: PropTypes.object
};

const mapStateToProps = (state) => ({
    activeProductList: state.activeProductAudit.activeProductAuditList,
    activeProductStatus: state.activeProductAudit.activeProductAuditStatus,
    activeProductAuditShopSele: state.activeProductAudit.activeProductAuditShopSele
});

export default connect(mapStateToProps, { getActiveProductAuditList, getActiveProductAuditStatus, getActiveProductAuditShopSele })(NoAudit);