import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

const TableContent = ({ renderData, paginationChange }) => {

    const columns = () => ([
        {
            title: '用户账号',
            dataIndex: 'tel',
            key: 'tel',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '用户类型',
            dataIndex: 'subjectTypeDesc',
            key: 'subjectTypeDesc',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '提现到账账号',
            dataIndex: 'bindAccountNo',
            key: 'bindAccountNo',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '绑定账户类型',
            dataIndex: 'bindAccountTypeDesc',
            key: 'bindAccountTypeDesc',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '提现渠道',
            dataIndex: 'accessTypeDesc',
            key: 'accessTypeDesc',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '提现状态',
            dataIndex: 'payStatusDesc',
            key: 'payStatusDesc',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '提现时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '失败原因',
            dataIndex: 'reamark',
            key: 'remark',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
    ]);

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.withdrawalId}
                columns={columns()}
                dataSource={renderData.dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;