/**
 * 结算明细
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import BeanTable from './components/BeanTable';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker, XInputNum } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/showbeanmanage/beanDetails/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tradingOrder: '',//交易订单号 
            orderNum: '',//关联订单号 
            merchantsName: {},//商户
            status: {},//状态
            startTime: null,//业务日期-开始时间
            endTime: null,//业务日期-结束时间
            accountName: '',//用户账号
            accountType: { code: 'USER', value: '会员' },//用户类型
        };
    }

    componentDidMount() {
        const { getStateType, getMerchantList, getUserAccountType } = this.props;
        getStateType();
        // getMerchantList();
        getUserAccountType();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, tradingOrder, merchantsName, startTime, endTime, status, orderNum, accountType, accountName } = this.state;
        this.searchCache = {
            // merchantId: merchantsName.merchantId,//商户
            id: tradingOrder,//交易订单号
            feeType: status.code,//状态
            orderId: orderNum,//关联订单号
            targetType: accountType.code,//用户类型
            loginAccount: accountName,//用户账号
            startDate: startTime != null ? startTime.format('YYYY-MM-DD HH:mm:ss') : null, //开始时间
            endDate: endTime != null ? endTime.format('YYYY-MM-DD HH:mm:ss') : null, // 结束时间
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getBillingDetail(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ tradingOrder: '', accountName: '', accountType: { code: 'USER', value: '会员' }, merchantsName: {}, startTime: null, endTime: null, orderNum: '', status: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getBillingDetail(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { tradingOrder, merchantsName, startTime, endTime, status, orderNum, accountType, accountName } = this.state;
        const { billingList, stateType, merchantList = {}, acountTypes } = this.props;

        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <SearchBox>
                            <SearchItem>
                                <XInputNum
                                    label='交易订单号'
                                    placeholder='请输入'
                                    value={tradingOrder}
                                    bindThis={this}
                                    bindName='tradingOrder'
                                    min={0}
                                    precision={0}
                                    maxLength={16}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XDatePicker
                                    label='业务日期'
                                    value={startTime}
                                    bindThis={this}
                                    bindName='startTime'
                                />
                                <XDatePicker
                                    style={{ marginRight: '20px' }}
                                    label=''
                                    value={endTime}
                                    bindThis={this}
                                    bindName='endTime'
                                />
                            </SearchItem>
                            {/* <SearchItem>
                                <XSelect
                                    label='商户'
                                    placeholder='请选择'
                                    renderData={merchantList.merchantIdAndNames || []}
                                    dataIndex='merchantName'
                                    keyIndex='merchantId'
                                    value={merchantsName.merchantName}
                                    showSearch={true}
                                    bindThis={this}
                                    bindName='merchantsName'
                                    isOptionBindID={true}
                                />
                            </SearchItem> */}
                            <SearchItem>
                                <XSelect
                                    label='状态'
                                    placeholder='请选择'
                                    renderData={stateType}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={status.value}
                                    showSearch={true}
                                    bindThis={this}
                                    bindName='status'
                                    isOptionBindID={true}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='关联订单号'
                                    placeholder='请输入'
                                    value={orderNum}
                                    bindThis={this}
                                    bindName='orderNum'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='用户账号'
                                    placeholder='请输入'
                                    value={accountName}
                                    bindThis={this}
                                    bindName='accountName'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='用户类型'
                                    placeholder='请选择'
                                    renderData={acountTypes}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={accountType.value}
                                    showSearch={true}
                                    bindThis={this}
                                    bindName='accountType'
                                    isOptionBindID={true}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <BeanTable renderData={billingList} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}


Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    updateContent: PropTypes.func, // 路由跳转

    billingList: PropTypes.object,
    getBillingDetail: PropTypes.func,//列表数据(action)
    stateType: PropTypes.array,
    getStateType: PropTypes.func,//状态(action)
    getMerchantList: PropTypes.func,//商户数据(action)
    merchantList: PropTypes.object,
    acountTypes: PropTypes.array,
    getUserAccountType: PropTypes.func,//用户类型(action)
};

const mapStateToProps = (state) => ({
    billingList: state.beanDetails.billingTableList, // 列表数据
    stateType: state.beanDetails.stateType, // 状态
    merchantList: state.beanDetails.merchantList, // 商户
    acountTypes: state.beanDetails.acountType, // 用户类型
});

export default connect(mapStateToProps, { ...model.actions })(Main);
