/**
 * 审核管理 - 视频审核列表异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Audit from './actiontypes';



export const getCommodityReviewList = function* () {
    yield takeEvery(Audit.GET_COMMODITYREVIEW_LIST, function* requestData(action) {
        try {
            let result = yield http('/marketing/scoreAudit/list', action.payload, 'POST'); // action.payload为传入的参数对象
            console.log('--------------------------------------------->',result.result);
            yield put({ type: Audit.SET_COMMODITYREVIEW_LIST, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};





export const getCommodityReviewDetail = function* () {
    yield takeEvery(Audit.GET_COMMODITYREVIEW_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/video/videoReviewDetails', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_COMMODITYREVIEW_DETAIL, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getCommodityReviewSele = function* () {
    yield takeEvery(Audit.GET_COMMODITYREVIEW_SELE, function* requestData(action) {
        try {
            let result = yield http('/enum/FeedStateEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_COMMODITYREVIEW_SELE, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getCommodityReviewType = function* () {
    yield takeEvery(Audit.GET_COMMODITYREVIEW_SYSTEMTYPE, function* requestData(action) {
        try {
            let result = yield http('/enum/SystemTypeEnum', action.payload, 'POST');
            yield put({ type: Audit.SET_COMMODITYREVIEW_SYSTEMTYPE, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};