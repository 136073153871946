/**
 * [系统确认-比例改变二次确认]
 * @author: Fu Xiaochun
 * @date: 2022-10-25 
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './modalStyle.module.scss';
import http from '@/assets/api/http';
import { Modal, Input, InputNumber, message } from 'antd';

function ChangeConfirm(props){

    const onSubmit = ()=>{
        props.onOk();
    };

    const onClose = ()=>{
        props.onClose();
    };

    return <Modal title='系统确认' width={600} centered visible={true} bodyStyle={{ padding: '10px' }} onOk={onSubmit} onCancel={onClose} >
        <div className={styles.changeConfirmContainer}>
            <div className={styles.merchantInfo}>
                <p>商户账号：{props.data.loginName}</p>
                <p>商户名称：{props.data.merchantName}</p>
            </div>
            <div className={styles.changeInfo}>
                <p>即将变更的比例为：</p>
                <p>商品支付结算比例（%）：<span>{props.data.goodsPaySettleRate}%</span></p>
                <p>每提供1元服务费，获得：<span>{props.data.pointsSettleRate || 0}积分</span></p>
                <p>您是否确认，向当前商户发起商务合作协议</p>
            </div>
        </div>
    </Modal>;
}

ChangeConfirm.propTypes = {
    data: PropTypes.object,
    onOk: PropTypes.func,
    onClose: PropTypes.func,
};

export default ChangeConfirm;