import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Switch, } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import ButtonPerssion from '@/routes/ButtonPerssion';

const columns = (TableAction, actionClickHandle) => ([
    {
        title: '攻略ID',
        dataIndex: 'stratrgid',
        key: 'stratrgid',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '攻略标题',
        dataIndex: 'title',
        key: 'title',
        width: '15%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '攻略分类',
        dataIndex: 'className',
        key: 'className',
        align: 'center',
        width: '15%',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '攻略类型',
        dataIndex: 'strategyValue',
        key: 'strategyValue',
        align: 'center',
        width: '10%',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '应用终端',
        dataIndex: 'useScene.value',
        key: 'useScene.value',
        align: 'center',
        width: '10%',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '推荐',
        dataIndex: 'isReco',
        key: 'isReco',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle, 'isReco'))
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle, 'state'))
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '15%',
        render: (id, record) => TableAction(id, actionClickHandle, record)
    }
]);

const TableAction = (id, actionClickHandle, record) => {
    return record.isInit == '1' ?
        < div className={styles.action} >
            <ButtonPerssion code='xqstrategy/list/xqgllbbj'>
                <div className={styles.item} onClick={() => actionClickHandle(id, 'editor')}>编辑</div>
            </ButtonPerssion>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'detail')}>详情</div>
            <div style={{ width: '24px', cursor: 'default' }} className={styles.item}></div>
        </div > : < div className={styles.action} >
            <ButtonPerssion code='xqstrategy/list/xqgllbbj'>
                <div className={styles.item} onClick={() => actionClickHandle(id, 'editor')}>编辑</div>
            </ButtonPerssion>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'detail')}>详情</div>
            <ButtonPerssion code='xqstrategy/list/xqgllbsc'>
                <div className={styles.item} onClick={() => actionClickHandle(id, 'delete')}>删除</div>
            </ButtonPerssion>
        </div >;
};

// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle, category) => {
    let checked = true;
    if (sw == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={(value) => {
                    actionClickHandle(item, category, value);
                }}
            />
        </div>
    );
};


class TypeTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data.map(item => {
            item.stratrgid = item.id;
            //攻略类型
            if (item.strategyType.value) item.strategyValue = item.strategyType.value;
            return item;
        });
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }
    render() {
        const { renderData, paginationChange, paginations } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => record.id}
                    columns={columns(TableAction, this._actionHandle)}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={paginations.resultTotal}
                    pageSize={paginations.pageSize}
                    pageNum={paginations.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    paginations: PropTypes.object,//分页数据
};

export default TypeTable;