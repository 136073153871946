import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Modal, Form, message, Input, Checkbox, Typography, Row, Col } from 'antd';
import http from '@/assets/api/http';

const EditModal = ({
    form: {
        getFieldDecorator,
        validateFieldsAndScroll
    } = {},
    setRenderModal,
    modalParams: {
        id = '',
        ruleName = '',
        salesafterCode = '',
        salaAfterTypeEnum = []
    } = {}
}) => {
    const [loading, setLoading] = useState(false);

    const onSubmit = () => {
        validateFieldsAndScroll((err, values) => {
            const params = { ...values, id, salesafterCode: values.salesafterCode.join(',') };
            if (!err) {
                setLoading(true);
                http('/goods/afterRule/update', params, 'POST')
                    .then(res => {
                        message.success(res.message);
                        setLoading(false);
                        setRenderModal(false);
                    })
                    .catch(err => {
                        message.error(err.message);
                        setLoading(false);
                    });
            }
        });
    };

    return <Modal
        width={480}
        centered
        title='编辑售后规则'
        visible={true}
        confirmLoading={loading}
        onOk={onSubmit}
        onCancel={() => setRenderModal(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div className={styles.container}>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                <Form.Item label='规则名称'>
                    {getFieldDecorator('ruleName', {
                        initialValue: ruleName,
                    })(<Input disabled={true} placeholder='请输入规则名称' />)}
                    <span>请输入名称：最少2个汉字，最多22个汉字</span>
                </Form.Item>
                <Form.Item label='售后类型' style={{ marginBottom: 0 }}>
                    {getFieldDecorator('salesafterCode', {
                        initialValue: salesafterCode.split(','),
                    })(<Checkbox.Group placeholder='请选择售后类型'>
                        {
                            salaAfterTypeEnum.map(item => {
                                return <Checkbox key={item.code} value={item.code}>{item.value}</Checkbox>;
                            })
                        }
                    </Checkbox.Group>)}
                </Form.Item>
                <Row>
                    <Col offset={4}><Typography.Text type='danger'>（当未选择时，默认活动商品不支持售后）</Typography.Text></Col>
                </Row>
            </Form>
        </div>
    </Modal>;
};
EditModal.propTypes = {
    form: PropTypes.object,
    modalParams: PropTypes.object,
    setRenderModal: PropTypes.func
};
export default Form.create({})(EditModal);