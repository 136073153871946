import React, { Component } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './GroupTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell, switchCell } from '@/components/TableCell';

export default class GroupTable extends Component {

    //初始化
    constructor(props) {
        super(props);
        this.state = {};
    }

    //props校验
    static propTypes = {
        renderData: PropTypes.object.isRequired, // 表格数据源
        tableAction: PropTypes.func.isRequired, // 表格操作
        paginationChange: PropTypes.func.isRequired, // 分页操作
    };

    //表头
    _columns = () => [
        {
            title: '编号',
            dataIndex: 'hotelLevelId',
            key: 'hotelLevelId',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '星级名称',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '图标',
            dataIndex: 'iconUrl',
            key: 'iconUrl',
            width: '30%',
            align: 'center',
            render: ImgCell
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: '20%',
            align: 'center',
            render: (text, item) => switchCell(text, value => this.props.tableAction(value, 'status', item))
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: '20%',
            render: (id, record) => this._tableAction(record.hotelLevelId, record)
        },
    ];

    //处理返回数据
    _takeDataSource = (data) => {
        return data ? data : [];
    }

    //表格操作
    _tableAction = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'editInfo', item)}>编辑</div>
                {/* <div className={styles.item} onClick={() => tableAction(key, 'deleteInfo')}>删除</div> */}
            </div>
        );
    };

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}