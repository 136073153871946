export const GET_STUDIOROOM_TABELSOURCE = 'CHANNELCLASSIFICATION/GET_STUDIOROOM_TABELSOURCE';
export const SET_STUDIOROOM_TABELSOURCE = 'CHANNELCLASSIFICATION/SET_STUDIOROOM_TABELSOURCE';
export const UPDATE_STUDIOROOM_TABELSOURCE_STATE = 'CHANNELCLASSIFICATION/UPDATE_STUDIOROOM_TABELSOURCE_STATE';

export const GET_LIVE_SORT = 'CHANNELCLASSIFICATION/GET_LIVE_SORT';
export const SET_LIVE_SORT = 'CHANNELCLASSIFICATION/SET_LIVE_SORT';

export const GET_LIVE_STATUS = 'CHANNELCLASSIFICATION/GET_LIVE_STATUS';
export const SET_LIVE_STATUS = 'CHANNELCLASSIFICATION/SET_LIVE_STATUS';
export const INIT_LIVE_STATUS = 'CHANNELCLASSIFICATION/INIT_LIVE_STATUS';
export const UPDATE_LIVE_STATUS_SUCCESS = 'CHANNELCLASSIFICATION/UPDATE_LIVE_STATUS_SUCCESS';
export const UPDATE_LIVE_STATUS_FAIL = 'CHANNELCLASSIFICATION/UPDATE_LIVE_STATUS_FAIL';

export const GET_LIVE_CLASSIFYSTATUS = 'CHANNELCLASSIFICATION/GET_LIVE_CLASSIFYSTATUS';
export const SET_LIVE_CLASSIFYSTATUS = 'CHANNELCLASSIFICATION/SET_LIVE_CLASSIFYSTATUS';

export const GET_VIDEO_DATA = 'CHANNELCLASSIFICATION/GET_VIDEO_DATA';
export const SET_VIDEO_DATA = 'CHANNELCLASSIFICATION/SET_VIDEO_DATA';
export const UPDATE_VIDEO_TABELSOURCE_STATE = 'CHANNELCLASSIFICATION/UPDATE_VIDEO_TABELSOURCE_STATE';


export const GET_COMMUNITY_DATA = 'CHANNELCLASSIFICATION/GET_COMMUNITY_DATA';
export const SET_COMMUNITY_DATA = 'CHANNELCLASSIFICATION/SET_COMMUNITY_DATA';
export const UPDATE_COMMUNITY_TABELSOURCE_STATE = 'CHANNELCLASSIFICATION/UPDATE_COMMUNITY_TABELSOURCE_STATE';

