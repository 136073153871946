import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, } from 'antd';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell, cellWidth } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';

class TypeTable extends Component {

    state = {
        selectedRowKeys: [],
    };

    //props校验
    static propTypes = {
        renderData: PropTypes.object.isRequired, // 渲染数据源(双向绑定)
        tableAction: PropTypes.func.isRequired, // 对表格的操作
        paginationChange: PropTypes.func.isRequired,//分页
        scrollY: PropTypes.string,
        cleanKeys: PropTypes.bool,//清空表格复选框
    };
    //表头
    _columns = () => [
        {
            title: '商品图片',
            dataIndex: 'mainImgUrl',
            key: 'mainImgUrl',
            align: 'center',
            width: cellWidth.normal,
            render: ImgCell
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '上架时间(天)',
            dataIndex: 'autoOffPeriod',
            key: 'autoOffPeriod',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户',
            dataIndex: 'merchantName',
            key: 'merchantName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品分类',
            dataIndex: 'categoryFullName',
            key: 'categoryFullName',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '分类类型',
            dataIndex: 'categorySubTypeName',
            key: 'categorySubTypeName',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            key: 'brandName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '业务类型',
            dataIndex: 'goodsType.value',
            key: 'goodsType.value',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品库存',
            dataIndex: 'totalQuantity',
            key: 'totalQuantity',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '销售价(元)',
            dataIndex: 'conversionSalePrice',
            key: 'conversionSalePrice',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结算价(元)',
            dataIndex: 'conversionWholesalePrice',
            key: 'conversionWholesalePrice',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '审核状态',
            dataIndex: 'statusValue',
            key: 'statusValue',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'goodsId',
            key: 'goodsId',
            align: 'center',
            fixed: 'right',
            width: cellWidth.normal,
            render: (text, record) => this._tableAction(text, record)
        }
    ];

    //操作表格 
    _tableAction = (key, record) => {
        const { tableAction } = this.props;
        return (<Fragment>
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
                <div style={{ width: '48px' }} className={styles.item} onClick={() => tableAction(key, 'audit', record)}>审核</div>
            </div>
        </Fragment>
        );
    };


    // 解析表格数据
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                //销售价、批发价价格区间处理
                if (item.minSalePrice == item.maxSalePrice) {
                    item.conversionSalePrice = regFenToYuan(item.minSalePrice);
                } else if (item.minSalePrice != item.maxSalePrice) {
                    item.conversionSalePrice = `${regFenToYuan(item.minSalePrice)} ~ ${regFenToYuan(item.maxSalePrice)}`;
                }
                if (item.minWholesalePrice == item.maxWholesalePrice) {
                    item.conversionWholesalePrice = regFenToYuan(item.minWholesalePrice);
                } else if (item.minWholesalePrice != item.maxWholesalePrice) {
                    item.conversionWholesalePrice = `${regFenToYuan(item.minWholesalePrice)} ~ ${regFenToYuan(item.maxWholesalePrice)}`;
                }
                return item;
            });
        }
    }


    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys }, () => {
                this.props.tableAction(null, 'batch', selectedRowKeys);
            });
        }
    };


    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] }, () => {
            this.props.tableAction(null, 'batch', []);
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.cleanKeys !== prevProps.cleanKeys) {
            this._cleanAllSelected();
        }
    }

    render() {
        const { paginationChange } = this.props;
        const { dataList, resultTotal, pageSize, pageNum } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={record => record.goodsId}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}
export default XTableHOC(TypeTable, 40);