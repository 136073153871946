/**
 * 客服管理 Saga集合
 */
import { getCustomerList, getCustomerDetail, getMerchantIdAndName} from './customerList/saga';
import { getSuggestionsList, getSuggestionsDetail, getSuggestionsType,getSuggestionsPort} from './suggestions/saga';
import { getFeedbackTypeList, getFeedbackTypeDetail} from './feedbackType/saga';
export default [
    getCustomerList,
    getCustomerDetail,
    getMerchantIdAndName,
    getSuggestionsList,
    getSuggestionsDetail,
    getSuggestionsType,
    getSuggestionsPort,
    getFeedbackTypeList,
    getFeedbackTypeDetail
];