import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TableBox.module.scss';
import { Table } from 'antd';
import { XPagination, XTableAdapter } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell } from '@/components/TableCell';

const TableBox = ({
	renderData: {
		dataList,
		pageNum,
		pageSize,
		resultTotal
	},
	paginationChange,
}) => {

	const [scrollY, setScroll] = useState('');

	const columns = () => {
		return [
			{
				title: '充值单号',
				dataIndex: 'id',
				key: 'id',
				align: 'center',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '第三方单号',
				dataIndex: 'outId',
				key: 'outId',
				align: 'center',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '充值金额',
				dataIndex: 'rechargeAmout',
				key: 'rechargeAmout',
				width: cellWidth.normal,
				align: 'center',
				onCell: tooltipStyle,
				render: priceFenToYuanCell
			}, {
				title: '当前账户余额',
				dataIndex: 'curBalance',
				key: 'curBalance',
				align: 'center',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: priceFenToYuanCell
			}, {
				title: '状态',
				dataIndex: 'status',
				key: 'status',
				align: 'center',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: text => tooltip(text ? text.value : '')
			}, {
				title: '备注',
				dataIndex: 'comment',
				key: 'comment',
				align: 'center',
				width: cellWidth.big,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '操作人',
				dataIndex: 'createUser',
				key: 'createUser',
				align: 'center',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '充值时间',
				dataIndex: 'gmtCreate',
				key: 'gmtCreate',
				align: 'center',
				width: cellWidth.time,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '成功时间',
				dataIndex: 'gmtModify',
				key: 'gmtModify',
				align: 'center',
				width: cellWidth.time,
				onCell: tooltipStyle,
				render: tooltip
			}];
	};

	return (
		<XTableAdapter render={screenY => setScroll(screenY)}>
			<div className={styles.tableContainer} style={{ height: '100%' }}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey={record => record.id}
					columns={columns()}
					pagination={false}
					dataSource={dataList}
					scroll={{ x: 'max-content', y: scrollY }} />
				<XPagination
					resultTotal={resultTotal}
					pageSize={pageSize}
					pageNum={pageNum}
					onChange={paginationChange} />
			</div>
		</XTableAdapter>
	);
};

TableBox.propTypes = {
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
	batchKeys: PropTypes.array,
	tableAction: PropTypes.func
};

export default TableBox;