/**
 * 运营中心审核
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Audit from './actionTypes';

//审核列表
export const getOperationAuditList = function* () {
    yield takeEvery(Audit.GET_OPERATIONAUDIT_LIST, function* requestData(action) {
        try {
            let result = yield http('/admin/company/listOperationsAuditCompany', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_OPERATIONAUDIT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//状态
export const getOperationAuditStatus = function* () {
    yield takeEvery(Audit.GET_OPERATIONAUDIT_STATUS, function* requestData(action) {
        try {
            let result = yield http('/enum/OperationsStateEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_OPERATIONAUDIT_STATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//详情
export const getOperationAuditDetail = function* () {
    yield takeEvery(Audit.GET_OPERATIONAUDIT_DETAIL, function* requestData(action) {
        try {
            let result = yield http(`/admin/company/getCompanyInfoById/${action.payload.id}`, action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_OPERATIONAUDIT_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};