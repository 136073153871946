import { createModel } from '@/store/tools';


const model = {
    namespace: 'lecturers',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getLecturersList': T('getLecturersList'),
            'getLecturersState': T('getLecturersState'),
            'getLecturersTable': T('getLecturersTable'),
            'getCourseList': T('getCourseList'),
            'getCourseTable': T('getCourseTable'),
            'getCourseStudentList': T('getCourseStudentList'),
            'getCourseStudentState': T('getCourseStudentState'),
            'getCourseStatus': T('getCourseStatus'),
        },
        actions: {
            'getLecturersList': A('getLecturersList'), // 讲师列表数据 
            'getLecturersState': A('getLecturersState'), // 讲师列表-状态
            'getLecturersTable': A('getLecturersTable'), // 讲师管理新建-列表数据
            'getCourseList': A('getCourseList'), // 课程管理列表
            'getCourseTable': A('getCourseTable'), // 课程管理新建与编辑-列表数据、学员管理下拉组别
            'getCourseStudentList': A('getCourseStudentList'), // 学员管理列表
            'getCourseStudentState': A('getCourseStudentState'), // 学员管理-是否主播，是否服务商
            'getCourseStatus': A('getCourseStatus'), // 课程管理-课程状态下拉
        },
        sagas: {
            'getLecturersList': S('getLecturersList', '/show/anchor/listAnchor'),
            'getLecturersState': S('getLecturersState', '/enum/StateEnum'),
            'getLecturersTable': S('getLecturersTable', '/show/anchor/listAccountAnchor'),
            'getCourseList': S('getCourseList', '/show/course/listCourse'),
            'getCourseTable': S('getCourseTable', '/show/course/listGroupItem'),
            'getCourseStudentList': S('getCourseStudentList', '/show/course/listCourseAccount'),
            'getCourseStudentState': S('getCourseStudentState', '/enum/FlagEnum'),
            'getCourseStatus': S('getCourseStatus', '/enum/BusniessPlayEnum'),
        },
        reducers: {
            'tableList': R('getLecturersList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'lecturersState': R('getLecturersState', []),
            'tableSouce': R('getLecturersTable', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'courseList': R('getCourseList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'courseSouce': R('getCourseTable', []),
            'courseStudents': R('getCourseStudentList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'studentState': R('getCourseStudentState', []),
            'status': R('getCourseStatus', []),
        }
    })
};
export default createModel(model);