import * as CUSTOMER from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
export const customerList = (state = initData, action) => {
    switch (action.type) {
        case CUSTOMER.SET_CUSTOMER_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const customerDetail = (state = {}, action) => {
    switch (action.type) {
        case CUSTOMER.SET_CUSTOMER_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};
export const merchantIdAndName = (state = {}, action) => {
    switch (action.type) {
        case CUSTOMER.SET_MERCHANTIDANDNAME:
            return action.payload.result;
        default:
            return state;
    }
};