import React from 'react';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import Strategy from '../strategy';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getclassificationList, } from '@/reducers/xqstrategy/classification/actions';

const Mode = {
    CREAT: 'CREAT',//新建
    EDITOR: 'EDITOR',//编辑 
    DATAIL: 'DATAIL',//详情
};
//tabs数据处理
const _tabsFuture = (data) => {
    let datadeal = [];
    let obj = {};
    for (let i = 0; i < data.length; i++) {
        obj = {
            id: data[i].id,
            label: data[i].className,
        };
        datadeal.push(obj);
    }
    return datadeal;
};
class Main extends React.Component {//atmosphere

    state = {
        tabs: [],
        curt: 0,//tab默认
        populedata: true,//初始化默认数据 作缓存仅作判断
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getclassificationList } = this.props;
        keepSecondNavBreadcrumb();
        getclassificationList({ belongSystem: 'DAILY_TALK' });//列表数据
    }
    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case Mode.CREAT:
                addOneBreadcrumbPath({
                    title: '新建攻略',
                    path: '/home/xqstrategy/list/createInfo' + '/DAILY_TALK'
                });
                history.push({
                    pathname: '/home/xqstrategy/list/createInfo' + '/DAILY_TALK',
                });
                break;
            case Mode.EDITOR:
                addOneBreadcrumbPath({
                    title: '编辑攻略',
                    path: '/home/xqstrategy/list/editInfo/' + value + '/DAILY_TALK',
                });
                history.push({
                    pathname: '/home/xqstrategy/list/editInfo/' + value + '/DAILY_TALK',
                });
                break;
            case Mode.DATAIL:
                addOneBreadcrumbPath({
                    title: '攻略详情',
                    path: '/home/xqstrategy/list/detailInfo/' + value + '/DAILY_TALK',
                });
                history.push({
                    pathname: '/home/xqstrategy/list/detailInfo/' + value + '/DAILY_TALK',
                });
                break;
        }
    }
    // 导航切换回调
    _anchorClickHandle = (id) => {
        this.setState({
            curt: id,
        });
    }

    // 导航切换
    _anchorMoveHandle = () => {
        const { curt } = this.state;
        return (
            this.state.tabs.map((item, index) => {
                if (index == 0) {
                    return (
                        <span key={index} onClick={() => this._anchorClickHandle(0)} className={curt == index ? styles.curt : ''}>全部攻略</span>
                    );
                } else {
                    return (
                        <span key={item.id} onClick={() => this._anchorClickHandle(item.id)} className={curt == item.id ? styles.curt : ''}>{item.label}</span>
                    );
                }
            })
        );
    }

    render() {
        const { tableSource, } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
                <div className={`${styles.flexBoxContainer}`}>
                    <Strategy selectDataSource={_tabsFuture(tableSource)} strategyId={this.state.curt} updateContent={this._updateContent} history={this.props.history} />
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息

    tableSource: PropTypes.array, // 星球攻略分类表格数据源 >攻略类别
    getclassificationList: PropTypes.func, // 星球攻略分类表格数据源的Action >攻略类别
};

const mapStateToProps = (state) => {
    return {
        tableSource: state.classification.classificationDate.tableSource,
    };
};

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getclassificationList, })(Main);
