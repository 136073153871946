import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message } from 'antd';
import { XOKButton } from '@/components/xqxc_ui';

class Export extends React.Component {

    _exportHandle = () => {
        const { reqParams } = this.props;
        // http('/boss/order/exportOrderList', reqParams, 'POST')
        //     .then(() => {
        //         message.success('导出成功!');
        //     }).catch((reject) => {
        //         message.error(reject.message);
        //     });
        console.log('导出');
    }

    render(){
        return(
            <XOKButton 
                style={{width:'100px', marginTop:'20px'}} 
                label='导出Excel'
                onClick={this._exportHandle} 
            />
        );
    }
}
Export.propTypes = {
    reqParams: PropTypes.object,
};
export default Export;