import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TableBox.module.scss';
import { Table } from 'antd';
import { XPagination, XTableAdapter } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';

const TableBox = ({
	renderData: {
		dataList,
		pageNum,
		pageSize,
		resultTotal
	},
	paginationChange,
}) => {

	const [scrollY, setScrollY] = useState('');

	const columns = () => {
		return [
			{
				title: '货号',
				dataIndex: 'goodsNo',
				key: 'goodsNo',
				align: 'center',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '商品名称',
				dataIndex: 'goodsName',
				key: 'goodsName',
				align: 'center',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '操作',
				dataIndex: 'operateType',
				key: 'operateType',
				width: cellWidth.normal,
				align: 'center',
				render: tooltip
			}, {
				title: '操作描述',
				dataIndex: 'operateDesc',
				key: 'operateDesc',
				align: 'center',
				width: cellWidth.big,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: 'IP',
				dataIndex: 'operateIp',
				key: 'operateIp',
				align: 'center',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '操作人',
				dataIndex: 'createUserName',
				key: 'createUserName',
				align: 'center',
				width: cellWidth.normal,
				onCell: tooltipStyle,
				render: tooltip
			}, {
				title: '操作时间',
				dataIndex: 'gmtCreate',
				key: 'gmtCreate',
				align: 'center',
				width: cellWidth.big,
				onCell: tooltipStyle,
				render: tooltip
			}];
	};

	return (
		<XTableAdapter render={screenY => setScrollY(screenY)}>
			<div className={styles.tableContainer} style={{ height: '100%' }}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey={record => record.id}
					columns={columns()}
					pagination={false}
					dataSource={dataList}
					scroll={{ x: 'max-content', y: scrollY }} />
				<XPagination
					resultTotal={resultTotal}
					pageSize={pageSize}
					pageNum={pageNum}
					onChange={paginationChange} />
			</div>
		</XTableAdapter>
	);
};

TableBox.propTypes = {
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
	batchKeys: PropTypes.array,
	tableAction: PropTypes.func
};

export default TableBox;