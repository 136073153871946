import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getserviceReadonlyLevel = (T) => createSaga(T('getserviceReadonlyLevel'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            console.log(action, 'action');
            try {
                let result = yield http('/service/level/generateLevel', {}, 'POST');//+ action.payload
                yield put({ type: T('level'), payload: result });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const serviceReadonlyLevel = (T) => createReducer((autostate) => {
    return (state = autostate, action) => {
        switch (action.type) {
            case T('level'):
                return { ...state, level: action.payload.result };
            default:
                return state;
        }
    };
}, { level: null });



const model = {
    namespace: 'decoratemanage',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getDecorateManageList': T('getDecorateManageList'),
            'getDecorateClass': T('getDecorateClass'),
            'getDecorateTables': T('getDecorateTables'),
            // 'getserviceLevelCode': T('getserviceLevelCode'),
        },
        actions: {
            'getDecorateManageList': A('getDecorateManageList'),
            'getDecorateClass': A('getDecorateClass'),
            'getDecorateTables': A('getDecorateTables'),
            // 'getserviceLevelCode': A('getserviceLevelCode'),
        },
        sagas: {
            'getDecorateManageList': S('getDecorateManageList', '/admin/templatemanage/listTemplate'),
            'getDecorateClass': S('getDecorateClass', '/admin/customtemplateclass/listCustomTemplatePullDownClass'),
            'getDecorateTables': S('getDecorateTables', '/admin/templatemanage/listAvailableCustomTemplate'),
            // 'getserviceLevelCode': S('getserviceLevelCode', '/enum/GatherBeansParamEnum'),
            // 'getserviceReadonlyLevel': getserviceReadonlyLevel(T),
        },
        reducers: {
            'decorateManageList': R('getDecorateManageList', { dataList: [], pageNum: 1, pageSize: 30, resultTotle: 0 }),
            'decorateClass': R('getDecorateClass', []),
            'decorateTables': R('getDecorateTables', { dataList: [], pageNum: 1, pageSize: 30, resultTotle: 0 }),
            // 'readonlyLevel': serviceReadonlyLevel(T),
        }
    })
};
export default createModel(model);

