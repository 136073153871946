import * as T from './actiontypes';

//获取供应商审核列表
export const getSupplierAuditList = (params) => ({
    type: T.GET_SUPPLIERAUDIT_LIST,
    payload: params
});

//获取供应商审核下拉
export const getSupplierAuditSele = () => ({
    type: T.GET_SUPPLIERAUDIT_SELE,
    payload: {}
});

//获取供应商审核状态下拉
export const getSupplierAuditStatus = () => ({
    type: T.GET_SUPPLIERAUDIT_STATUS,
    payload: {}
});

//获取供应商审核详情
export const getSupplierAuditDetail = (id) => ({
    type: T.GET_SUPPLIERAUDIT_DETAIL,
    payload: { id }
});