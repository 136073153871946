//异步获取数据
export const GET_STRATEG_LIST = 'ADVERT/GET_STRATEG_LIST'; 
export const GET_STRATEG_ADD = 'ADVERT/GET_STRATEG_ADD'; 
export const GET_STRATEG_EDIT = 'ADVERT/GET_STRATEG_EDIT'; 
export const GET_STRATEG_DETAIL = 'ADVERT/GET_STRATEG_DETAIL';
export const GET_STRATEG_DELETE = 'ADVERT/GET_STRATEG_DELETE';
export const GET_STRATEG_STARTANDSTOP = 'ADVERT/GET_STRATEG_STARTANDSTOP';
export const GET_STRATEG_ADSLIST = 'ADVERT/GET_STRATEG_ADSLIST';

// //保存数据到store.state
export const SET_STRATEG_LIST = 'ADVERT/SET_STRATEG_LIST'; 
export const SET_STRATEG_ADD = 'ADVERT/SET_STRATEG_ADD'; 
export const SET_STRATEG_EDIT = 'ADVERT/SET_STRATEG_EDIT'; 
export const SET_STRATEG_DETAIL = 'ADVERT/SET_STRATEG_DETAIL';
export const SET_STRATEG_DELETE = 'ADVERT/SET_STRATEG_DELETE';
export const SET_STRATEG_STARTANDSTOP = 'ADVERT/SET_STRATEG_STARTANDSTOP';
export const SET_STRATEG_ADSLIST = 'ADVERT/SET_STRATEG_ADSLIST'; 