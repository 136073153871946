import { createModel } from '@/store/tools';

const model = {
    namespace: 'enterpriseAnchor',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getEnterpriseAnchors': T('getEnterpriseAnchors'),
            'getEnterpriseAnchorInfo': T('getEnterpriseAnchorInfo'),
        },
        actions: {
            'getEnterpriseAnchors': A('getEnterpriseAnchors'),
            'getEnterpriseAnchorInfo': A('getEnterpriseAnchorInfo'),
        },
        sagas: {
            'getEnterpriseAnchors': S('getEnterpriseAnchors', '/anchorReview/getAnchorReviewList'),
            'getEnterpriseAnchorInfo': S('getEnterpriseAnchorInfo', '/anchorReview/getAnchorReviewInfo'),
        },
        reducers: {
            'enterpriseAnchors': R('getEnterpriseAnchors', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'enterpriseAnchorInfo': R('getEnterpriseAnchorInfo', {}),
        }
    })
};
export default createModel(model);