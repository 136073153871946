/**
 *  售后原因统计
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './main';

const SaleReasion = ({ history }) => {
    return <Main history={history} />;
};

SaleReasion.propTypes = {
    history: PropTypes.object
};

export default SaleReasion;