import React from 'react';
import styles from './DeptTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';

class DeptTable extends React.Component {

    _takeColumns = () => ([
        {
            title: '组织架构层级',
            dataIndex: 'deptName',
            key: 'deptName',
            width: '25%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '分类',
            dataIndex: 'accountTypeName',
            key: 'accountTypeName',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '主负责人',
            dataIndex: 'principal',
            key: 'principal',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '电话',
            dataIndex: 'mobile',
            key: 'mobile',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '人数',
            dataIndex: 'emplCount',
            key: 'emplCount',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '状态',
            dataIndex: 'stateCode',
            key: 'stateCode',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderStatusCell(text))
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: '15%',
            render: (text, item) => this._renderActionCell(item.id, item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList.map((item) => {
                item.accountTypeName = item.accountType.value;
                item.stateCode = item.state.code;
                return item;
            });
        } else {
            return [];
        }
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderStatusCell = (text) => {
        text = parseInt(text);
        var statusArr = [0, 1];
        var statusText = ['禁用', '正常'];
        if (statusArr.indexOf(text) !== -1) {
            return (
                <span>{statusText[text]}</span>
            );
        } else {
            return null;
        }
    }

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        let element = null;
        if (item.state.code == 1) {
            element = <div className={styles.item} onClick={() => tableAction(key, 'forbid')}>禁用</div>;
        } else {
            element = <div className={styles.item} onClick={() => tableAction(key, 'enable')}>启用</div>;
        }
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>
                {element}
                <div className={styles.item} onClick={() => tableAction(key, 'del')}>删除</div>
            </div>
        );
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

DeptTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default DeptTable;