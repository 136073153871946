import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const TableContent = ({ renderData, paginationChange, tableAction }) => {

    const operateCell = (id, item) => {
        return (
            <div className={styles.action}>
                {
                    !!item.agreementUrl &&
                    <div className={styles.item} onClick={() => tableAction(item, 'licenses')}>查看协议</div>
                }
            </div>
        );
    };

    const columns = () => ([
        {
            title: '账号',
            dataIndex: 'tel',
            key: 'tel',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '服务商名称',
            dataIndex: 'name',
            key: 'name ',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '运营中心名称',
            dataIndex: 'operationsCenterName',
            key: 'operationsCenterName ',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '身份证号',
            dataIndex: 'idCard',
            key: 'idCard',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        //     title: '数据来源',
        //     dataIndex: 'source',
        //     key: 'source',
        //     align: 'center',
        //     width: '10%',
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        // {
        //     title: '创建时间',
        //     dataIndex: 'gmtCreate',
        //     key: 'gmtCreate',
        //     align: 'center',
        //     width: '10%',
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        {
            title: '注册时间',
            dataIndex: 'gmtRegistration',
            key: 'gmtRegistration',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '10%',
            render: operateCell
        }
    ]);


    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.id}
                columns={columns()}
                dataSource={renderData.dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    tableAction: PropTypes.func
};

export default TableContent;