// 财务参数
export const GET_FINANCEARG_DETAIL = 'FINANCEARG/GET_FINANCEARG_DETAIL';
export const SET_FINANCEARG_DETAIL = 'FINANCEARG/SET_FINANCEARG_DETAIL';

//财务信息列表
export const GET_FINANCEINFO_LIST = 'FINANCEARG/GET_FINANCEINFO_LIST';
export const SET_FINANCEINFO_LIST = 'FINANCEARG/SET_FINANCEINFO_LIST';

//财务信息详情
export const GET_FINANCEINFO_DETAIL = 'FINANCEARG/GET_FINANCEINFO_DETAIL';
export const SET_FINANCEINFO_DETAIL = 'FINANCEARG/SET_FINANCEINFO_DETAIL';