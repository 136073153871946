import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Tabs, message } from 'antd';
import { FloatConversion } from '@/util/conversion';
import { ChartCard, Field, Bar } from 'ant-design-pro/lib/Charts';
import http from '@/assets/api/http';
import styles from './index.module.less';


const { TabPane } = Tabs;

export default class main extends Component {

    state = {
        salesData: [],//用户趋势
        loading: true,
        todayAdd: '', // 今日新增
        totalAdd: '', // 累积新增用户
        dayActive: '', //日活跃用户
        monthActive: '', //月活跃用户
    }
    floatConversion = new FloatConversion();

    componentDidMount() {
        this._getUserData();
    }

    //会员分析数据
    _getUserData = () => {
        http('/report/queryReportDataForAudit', {}, 'POST').then((response) => {
            if (response.status == 200) {
                let { todayAdd, totalAdd, dayActive, monthActive, monthAdd = [] } = response.result || {};
                let list = monthAdd.map(v => {
                    return { x: v.month ? `${v.month}月` : '', y: v.monthAddCount ? this.floatConversion.divide(v.monthAddCount, 10000) : 0 };
                });
                this.setState({
                    todayAdd,
                    totalAdd,
                    dayActive,
                    monthActive,
                    salesData: list,
                    loading: list.length > 0 ? false : true
                });
            }
        }).catch((e = {}) => {
            message.error(e.message);
        });
    }

    render() {
        const { todayAdd, totalAdd, dayActive, monthActive, salesData, loading, } = this.state;
        const topColResponsiveProps = {
            xs: 12,
            sm: 12,
            md: 14,
            lg: 18,
            xl: 24,
            style: { marginBottom: 20 }
        };
        return (
            <div className={styles.container}>
                <Row style={{ display: 'flex', flexFlow: 'row wrap' }}>
                    <Col {...topColResponsiveProps}>
                        <ChartCard
                            className={styles.ChartCardStyle}
                            title='今日新增'
                            total={<span title={todayAdd}>{todayAdd}</span>}
                            contentHeight={46}
                            footer={
                                <Field />
                            }
                        >
                            <div style={{ height: 46 }}></div>
                        </ChartCard>
                        <ChartCard
                            className={styles.ChartCardStyle}
                            title='累计用户'
                            total={<span title={totalAdd}>{totalAdd}</span>}
                            footer={
                                <Field />
                            }
                            contentHeight={46}
                        >
                            <div style={{ height: 46 }}></div>
                        </ChartCard>
                        <ChartCard
                            className={styles.ChartCardStyle}
                            title='日活跃'
                            contentHeight={46}
                            total={<span title={dayActive}>{dayActive}</span>}
                            footer={
                                <Field />
                            }
                        >
                            <div style={{ height: 46 }}></div>
                        </ChartCard>
                        <ChartCard
                            className={styles.ChartCardStyle}
                            title='月活跃'
                            total={<span title={monthActive}>{monthActive}</span>}
                            footer={
                                <Field />
                            }
                            contentHeight={46}
                        >
                            <div style={{ height: 46 }}></div>
                        </ChartCard>
                    </Col>
                </Row>

                <Card
                    loading={loading}
                    bordered={false}
                    bodyStyle={{ padding: 0 }}
                >
                    <div className={styles.salesCard}>
                        <Tabs size='large'>
                            <TabPane tab='用户趋势' key='user'>
                                <Row>
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <div className={styles.salesBar}>
                                            <Bar
                                                height={295}
                                                title='用户趋势(万)'
                                                data={salesData}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </Card>
            </div>
        );
    }
}
main.propTypes = {
    dispatch: PropTypes.func,
    total: PropTypes.number,
    history: PropTypes.object
};