import React from 'react';
import PreShowItem from './PreShowItem';
import PropTypes from 'prop-types';
import styles from './Content.module.scss';
import { Divider, Typography } from 'antd';
import { LoadMore, RadioGroup } from '@/components/xqxc_ui.js';

const { Text } = Typography;

const Content = ({ onPageJumps, category, decorateClass, dataList, onClassChange, customTemplateClassId, onRemove, onScrollLoad, onPreTemple }) => {

    const onAddTemplete = () => {
        onPageJumps('模板列表', '/templatelist/' + category);
    };

    return <div className={styles.container}>
        <RadioGroup
            customTemplateClassId={customTemplateClassId}
            dataSource={decorateClass}
            onClassChange={onClassChange}
        />
        <Divider />
        <LoadMore
            category={category}
            customTemplateClassId={customTemplateClassId}
            style={{ height: 'calc(100% - 90px)' }}
            onChange={value => onScrollLoad(value)}
        >
            <div className={styles.contantBox}>
                <div
                    className={styles.defaultItem}
                    onClick={onAddTemplete}
                >
                    <Text type='secondary'>+新增模板</Text>
                </div>
                {
                    dataList.map(item => {
                        return <PreShowItem
                            onPreTemple={onPreTemple}
                            key={item.id}
                            item={item}
                            onRemove={onRemove} />;
                    })
                }
            </div>
        </LoadMore>
    </div>;
};

Content.propTypes = {
    onPageJumps: PropTypes.func,
    onClassChange: PropTypes.func,
    dataList: PropTypes.array,
    decorateClass: PropTypes.array,
    onRemove: PropTypes.func,
    onScrollLoad: PropTypes.func,
    onPreTemple: PropTypes.func,
    category: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    customTemplateClassId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

export default Content;