import * as T from './actiontypes';

export const getContractListTableSource = requestProps => ({
    type: T.GET_CONTRACTLIST_TABLESOURCE,
    payload: requestProps
});

export const getContractNames = requestProps => ({
    type: T.GET_CONTRACTNAMES,
    payload: requestProps
});

export const getContractStates = requestProps => ({
    type: T.GET_CONTRACTSTATES,
    payload: requestProps
});