/**
 * 新建/编辑须知分类
 */
import React from 'react';
import { Modal, Button } from 'antd';
import { XInput, } from '@/components/xqxc_ui';
import CusotmUpload, { generateFileList } from '@/components/NewUpload';
import PropTypes from 'prop-types';
import styles from './PackModal.module.scss';
import { message, } from 'antd';
import http from '@/assets/api/http';

class Pack extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.title,
            visible: props.visible,
            confirmLoading: false,
            packName: props.data.name || '',//语音名称
            singleImages: generateFileList(props.data.url) || [],//语音包 
            languageDuration: {}//语音包属性
        };
    }

    componentDidMount() { }

    _handleOk = () => {
        const { singleImages, packName, languageDuration } = this.state;
        const { id, } = this.props.data;
        if (packName == '') return message.warning('请输入语音名称。');
        if (singleImages.length === 0) return message.warning('请上传语音包。');
        if ((languageDuration.size / 1024 / 1024 > 2) && (languageDuration.duration > 30)) {
            return message.warning('语音不能超过2M且时长不能超过30秒。');
        }

        let submitData = {};
        submitData = {
            id,
            name: packName,
            url: singleImages[0].response ? singleImages[0].response.key : singleImages[0].url,
        };

        this.setState({ confirmLoading: true }, () => {
            http('/hotel/voicePacket/editVoicePacket', submitData, 'POST')
                .then(() => {
                    message.success('更新成功。');
                    this.setState({
                        visible: false,
                    });
                    this.props.closeMode(true);
                }).catch((e = {}) => {
                    this.setState({ confirmLoading: false });
                    message.error(e.message);
                });
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    //验证-分类名称
    packChange = (value) => {
        if (value.length > 20) {
            this.setState({
                packName: value.substring(0, 20)
            });
        } else {
            this.setState({
                packName: value
            });
        }
    }

    render() {
        const { visible, title, confirmLoading, packName, singleImages } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                onOk={this._handleOk}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                okText='确定'
                cancelText='取消'
            >

                <div className={styles.container}>
                    <div className={`${styles.layout} ${styles.dataTop}`}>
                        <XInput
                            inputStyle={{ width: '270px' }}
                            label='语音名称'
                            placeholder='请输入语音名称'
                            value={packName}
                            isRequired={true}
                            onChange={this.packChange}
                            suffix={packName.length < '21' && `(${packName.length}/20)`}
                        />
                    </div>
                    <div className={`${styles.Upload} ${styles.layout}`}>
                        <span className={styles.icon}><i>*</i>语音包</span>
                        <CusotmUpload
                            accept='.mp3,.wav,.pcm,.m4a,.wma'
                            fileList={singleImages}
                            returnAudioDuration
                            wallMode="single"
                            qiniuUploadProps={{
                                uploadType: 'image'
                            }}
                            onChange={(info) => {
                                const pack = info.fileList.length > 1 ? [info.fileList.pop()] : info.fileList;
                                this.setState({
                                    singleImages: pack
                                });
                            }}
                            onFileListChange={(info, customFile) => {//audio/x-m4a,audio/x-mp3  "audio/mp3"
                                if (customFile && customFile.status === 'success') {
                                    const { duration = {}, size } = customFile;
                                    let properties = {
                                        duration: duration.duration,//时长/秒
                                        size//大小
                                    };
                                    this.setState({ languageDuration: properties });
                                }
                                if (!customFile || (customFile && customFile.status === 'error')) {
                                    this.setState({ languageDuration: {} });
                                }
                            }}
                        // beforeUpload={(file) => {
                        //     console.log(file, 'file');
                        //     const isType = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
                        //     const isLt3M = file.size / 1024 / 1024 < 2;
                        //     if (!isType.includes(file.type)) {
                        //         message.warn('只能上传png,jpg, gif格式的图片!');
                        //         return false;
                        //     } else if (!isLt3M) {
                        //         message.warn('图片必须小于 3MB!');
                        //         return false;
                        //     } else {
                        //         return isType && isLt3M;
                        //     }
                        // }}
                        >
                            <Button icon="upload">上传语音包</Button>
                        </CusotmUpload>
                    </div>
                    <p className={styles.ps}>当前仅支持mp3、wav、pcm、m4a、wma格式,单条音频不能超过2M且时长不能超过30秒。</p>
                </div>
            </Modal>
        );
    }
}

Pack.propTypes = {
    title: PropTypes.string,// 弹框标题
    data: PropTypes.object, //数据
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default Pack;