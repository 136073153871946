import { createModel } from '@/store/tools';

const model = {
    namespace: 'HotelOrderSys',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getHotelList': T('getHotelList'),
        },
        actions: {
            'getHotelList': A('getHotelList'), // 内容数据
        },
        sagas: {
            'getHotelList': S('getHotelList', '/admin/param/findParamObjectByType','POST','type'),
        },
        reducers: {
            'hotelList': R('getHotelList', {}),
        }
    })
};
export default createModel(model);