/**
 *  审核管理 - 运营中心发票审核
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class OperationInvoice extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case 'FIRAUDIT':
                addOneBreadcrumbPath({
                    title: '运营中心发票初审',
                    path: '/home/auditmanage/operationInvoice/firstAudit/'
                });
                history.push({
                    pathname: '/home/auditmanage/operationInvoice/firstAudit/' + value
                });
                break;
            case 'SECAUDIT':
                addOneBreadcrumbPath({
                    title: '运营中心发票复审',
                    path: '/home/auditmanage/operationInvoice/secondAudit/'
                });
                history.push({
                    pathname: '/home/auditmanage/operationInvoice/secondAudit/' + value
                });
                break;
            case 'DETAIL':
                addOneBreadcrumbPath({
                    title: '运营中心发票详情',
                    path: '/home/auditmanage/operationInvoice/detail'
                });
                history.push({
                    pathname: '/home/auditmanage/operationInvoice/detail/' + value
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

OperationInvoice.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(OperationInvoice);