/**
 * 售后管理 Saga集合
 */
import { getSaleReasonList } from './salereasons/saga';
import { getSaleList, getSaleStatus, getSaleType, getSaleOnlyMoneyDetail, getSaleGoodsandmoneyDetail, getSaleChangeGoodsDetail, getProcessedDetail } from './salelist/saga';
import wholesale from './wholesale/model';
export default [
    getSaleReasonList,
    getSaleList,
    getSaleStatus,
    getSaleType,
    getSaleOnlyMoneyDetail,
    getSaleGoodsandmoneyDetail,
    getSaleChangeGoodsDetail,
    getProcessedDetail,
    ...Object.values(wholesale.sagas),
];