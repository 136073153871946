import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

const dataList = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };


/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getTimeLabelEnum = (T) => createSaga(T('getTimeLabelEnum'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/enum/TimeLabelEnum', { ...action.payload }, 'POST');
                let timeLabelEnum = result.result.map(item => {
                    return { ...item, active: false };
                });
                yield put({ type: T('originalData'), payload: timeLabelEnum });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const timeLabelEnum = (T) => createReducer((autostate) => {
    return (state = autostate, { type, payload }) => {
        switch (type) {
            case T('originalData'):
                return [...payload];
            case T('timeLabelChange/GET'):
                return [...state.map(item => {
                    if (payload === item.code) {
                        return { ...item, active: true };
                    }
                    return { ...item, active: false };
                })];
            case T('resetTimeLabel/GET'):
                return [...state.map(item => {
                    return { ...item, active: false };
                })];
            default:
                return state;
        }
    };
}, []);

const model = {
    namespace: 'paybill',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getApplyInvoiceList': T('getApplyInvoiceList'), // 薪人开票列表
            'getPayrollerApplyInvoiceStatus': T('getPayrollerApplyInvoiceStatus'), // 状态
            'getApplyInvoiceInfo': T('getApplyInvoiceInfo'), // 获取薪人开票提交的信息
        },
        actions: {
            'getApplyInvoiceList': A('getApplyInvoiceList'),
            'getPayrollerApplyInvoiceStatus': A('getPayrollerApplyInvoiceStatus'),
            'getApplyInvoiceInfo': A('getApplyInvoiceInfo'),
        },
        sagas: {
            'getApplyInvoiceList': S('getApplyInvoiceList', '/settle/payroller/getApplyInvoiceList'),
            'getPayrollerApplyInvoiceStatus': S('getPayrollerApplyInvoiceStatus', '/enum/PayrollerApplyInvoiceStatus'),
            'getApplyInvoiceInfo': S('getApplyInvoiceInfo', '/settle/payroller/getApplyInvoiceInfo', 'GET'),
        },
        reducers: {
            'ApplyInvoiceList': R('getApplyInvoiceList', dataList),
            'PayrollerApplyInvoiceStatus': R('getPayrollerApplyInvoiceStatus', []),
            'ApplyInvoiceInfo': R('getApplyInvoiceInfo', {}),
        }
    })
};
export default createModel(model);