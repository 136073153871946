//任务列表
export const GET_TASKMANAGE_TASKLIST = 'TASKMANAGE/GET_TASKMANAGE_TASKLIST';
export const SET_TASKMANAGE_TASKLIST = 'TASKMANAGE/SET_TASKMANAGE_TASKLIST';

//任务详情
export const GET_TASKMANAGE_TASKDETAIL = 'TASKMANAGE/GET_TASKMANAGE_TASKDETAIL';
export const SET_TASKMANAGE_TASKDETAIL = 'TASKMANAGE/SET_TASKMANAGE_TASKDETAIL';
export const CLEAN_TASKDETAIL = 'TASKMANAGE/CLEAN_TASKDETAIL';

//任务分类下拉
export const GET_TASKMANAGE_TASKCLASSIFYSELE = 'TASKMANAGE/GET_TASKMANAGE_TASKCLASSIFYSELE';
export const SET_TASKMANAGE_TASKCLASSIFYSELE = 'TASKMANAGE/SET_TASKMANAGE_TASKCLASSIFYSELE';

//任务类型下拉
export const GET_TASKMANAGE_TASKTYPESELE = 'TASKMANAGE/GET_TASKMANAGE_TASKTYPESELE';
export const SET_TASKMANAGE_TASKTYPESELE = 'TASKMANAGE/SET_TASKMANAGE_TASKTYPESELE';

//任务推送平台下拉
export const GET_TASKMANAGE_TASKPLATSELE = 'TASKMANAGE/GET_TASKMANAGE_TASKPLATSELE';
export const SET_TASKMANAGE_TASKPLATSELE = 'TASKMANAGE/SET_TASKMANAGE_TASKPLATSELE';

//任务信息下拉
export const GET_TASKMANAGE_TASKINFOSELE = 'TASKMANAGE/GET_TASKMANAGE_TASKINFOSELE';
export const SET_TASKMANAGE_TASKINFOSELE = 'TASKMANAGE/SET_TASKMANAGE_TASKINFOSELE';

//任务要素下拉
export const GET_TASKMANAGE_TASKFACTORSELE = 'TASKMANAGE/GET_TASKMANAGE_TASKFACTORSELE';
export const SET_TASKMANAGE_TASKFACTORSELE = 'TASKMANAGE/SET_TASKMANAGE_TASKFACTORSELE';

//任务奖励下拉
export const GET_TASKMANAGE_TASKAWARDSELE = 'TASKMANAGE/GET_TASKMANAGE_TASKAWARDSELE';
export const SET_TASKMANAGE_TASKAWARDSELE = 'TASKMANAGE/SET_TASKMANAGE_TASKAWARDSELE';

//任务奖励会员等级
export const GET_TASKMANAGE_TASKGRADE = 'TASKMANAGE/GET_TASKMANAGE_TASKGRADE';
export const SET_TASKMANAGE_TASKGRADE = 'TASKMANAGE/SET_TASKMANAGE_TASKGRADE';
