/** 
 * 异步请求总控中心
 * put相当于redux的dispatch的作用
 * */
import { fork, all } from 'redux-saga/effects';
import { getMenuList } from '@/reducers/home/saga';
import commonSagas from '@/reducers/common/combineSaga';
import operationManageSages from '@/reducers/operationManage/combineSaga';
import showmanageSagas from '@/reducers/showmanage/combineSaga';
import commentmanageSagas from '@/reducers/commentmanage/combineSaga';
import usermanageSagas from '@/reducers/usermanage/combineSaga';
import auditMageSagas from '@/reducers/auditmanage/combineSaga';
import productMageSagas from '@/reducers/productmanage/combineSaga';
import merchantListSagas from '@/reducers/businessmanage/combineSaga';
import systemmanageSgags from '@/reducers/systemmanage/combineSaga';
import marketingmanageSagas from '@/reducers/marketingmanage/combineSaga';
import storemanageSagas from '@/reducers/storemanage/combineSaga';
import advertmanageSagas from '@/reducers/advertmanage/combineSaga';
import ordermanageSagas from '@/reducers/ordermanage/combineSaga';
import reportmanageSagas from '@/reducers/reportmanage/combineSaga';
import starvalueshopSagas from '@/reducers/starvalueshop/combineSaga';
import permissionsSagas from '@/reducers/permissionsmanage/combineSaga';
import contractSagas from '@/reducers/contractmanage/combineSaga';
import messageSagas from '@/reducers/messagemanage/combineSaga';
import customerSagas from '@/reducers/customermanage/combineSaga';
import storehousefinancialmanage from '@/reducers/storehousefinancialmanage/combineSaga';
import taskManageSagas from '@/reducers/taskManage/combineSaga';
import saleManageSagas from '@/reducers/salemanage/combineSaga';
import xqstrategySagas from '@/reducers/xqstrategy/combineSaga';
import graphicsmanageSagas from '@/reducers/graphicsmanage/combineSaga';
import suppliermanageSagas from '@/reducers/suppliermanage/combineSaga';
import servicelistingmanageSagas from '@/reducers/servicelistingmanage/combineSaga';
import statistical from '@/reducers/statistical/combineSaga';
import statisticsofdistributor from '@/reducers/statisticsofdistributor/combineSaga';
import commercialcollege from '@/reducers/commercialcollege/combineSaga';
import knowledgepay from '@/reducers/knowledgepay/combineSaga';
import statementmanage from '@/reducers/statementmanage/combineSaga';
import showbeanmanageSagas from '@/reducers/showbeanmanage/combineSaga';
import servicemanage from '@/reducers/servicemanage/combineSaga';
import accruedServiceSagas from '@/reducers/accruedService/combineSaga';
import receiveordermanageSage from '@/reducers/receiveordermanage/combineSaga';
import houseresourcemanage from '@/reducers/houseresourcemanage/combineSaga';
import hotelmanageSaga from '@/reducers/hotelmanage/combineSaga';
import channelmanage from '@/reducers/channelmanage/combineSaga';
import statisticsinfo from '@/reducers/statisticsinfo/combineSaga';
import operationcenter from '@/reducers/operationcenter/combineSaga';

const combineSaga = (sagas, modules) => {
    let result = [...sagas];
    for (let i = 0; i < modules.length; i++) {
        const module = modules[i];
        result = result.concat(module.map((item) => {
            return fork(item);
        }));
    }
    return result;
};
const modules = [
    commonSagas,
    operationManageSages,
    showmanageSagas,
    commentmanageSagas,
    usermanageSagas,
    auditMageSagas,
    productMageSagas,
    merchantListSagas,
    systemmanageSgags,
    marketingmanageSagas,
    storemanageSagas,
    advertmanageSagas,
    reportmanageSagas,
    starvalueshopSagas,
    permissionsSagas,
    ordermanageSagas,
    contractSagas,
    messageSagas,
    customerSagas,
    storehousefinancialmanage,
    taskManageSagas,
    saleManageSagas,
    xqstrategySagas,
    graphicsmanageSagas,
    suppliermanageSagas,
    servicelistingmanageSagas,
    statistical,
    statisticsofdistributor,
    commercialcollege,
    knowledgepay,
    statementmanage,
    showbeanmanageSagas,
    servicemanage,
    accruedServiceSagas,
    receiveordermanageSage,
    houseresourcemanage,
    hotelmanageSaga,
    channelmanage,
    statisticsinfo,
    operationcenter
];

let sagas = [].concat(fork(getMenuList));
sagas = combineSaga(sagas, modules);
export default function* rootSaga() {
    yield all(sagas);
}