/**
 *  人气值商城 - 兑换记录
 * */ 
import React from 'react';
import PropTypes from 'prop-types'; 
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class ForRecord extends React.Component {
    componentDidMount(){
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }
    render(){
        return(
            <Main  updateContent={this._updateContent} history = {this.props.history} /> 
        );
    }
}

ForRecord.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(ForRecord);