/**
 * 临时税务登记证管理
 */
import React, { Fragment } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav, XInput, XSelect, XOKButton, XCancelButton, showConfirm, XInputNum ,XDatePicker} from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/taxcertificate/model';
import TableContent from './components/TableContent';
import ApplyInvoice from './modal/applyInvoice';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';
import { SearchBox, SearchItem } from '@/components/Layout';
import { regFenToYuan } from '@/util/money';
import { message, Modal, Input, DatePicker, Row, Col,Checkbox  } from 'antd';

const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.batchData = [];
    }

    state = {
        payStatus: [], // 状态枚举下拉列
        openModeParam: null, // 弹框入参
        status: {}, // 状态选择
        currentOpenMode: '',
        batchData: [],
        modalVisible:false, //弹窗开关
        rejModalVisible:false,
        phones: '',
        endDate: '',
        plainOptions:[{ label: '委托书', value: 'FUZZY_SIGNATURE' },  { label: '身份证', value: 'ID_CARD' }],
        rejectType:[],
        rejPhones:'', //未通过审核手机号列表
        auditOpinion:'' //审核意见

    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // 获取状态枚举下拉列
        http('/enum/TaxCommissionStateEnum').then((res = {}) => {
            if (res.code === 200) {
                this.setState({ payStatus: res.result });
            }
        });
    }
    handleOk = async (e) => {
        console.log('-------------->', e, this.state.openParam);
        let phones = this.state.phones.split(',').filter(function (s) {
            return s && s.trim();
        });
        let endDate = this.state.endDate;
        if (endDate !== '' && phones.length !== 0 && phones !== '') {
            let res = await http('/settle/taxcommissionrecord/taxCommissionAuditApprovalBatch', {
                expDate: endDate,
                mobiles: phones
            });
            console.log('---------', res);
        } else {
            message.warning('请填写完整信息');
            return;
        }


        this.setState({
            endDate:'',
            phones:'',
            modalVisible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            modalVisible: false,
            endDate:'',
            phones:''
        });

    };


    rejhandleOk = async (e) => {
        let phones = this.state.rejPhones.split(',').filter(function (s) {
            return s && s.trim();
        });
        let rejectType = this.state.rejectType;
        let auditOpinion  = this.state.auditOpinion;

        if (rejectType !== '' && rejectType.length !== 0 &&   phones.length !== 0 && phones !== '' && auditOpinion !== '') {
            let res = await http('/settle/taxcommissionrecord/taxCommissionAuditRejectBatch', {
                auditOpinion: auditOpinion,
                mobiles: phones,
                rejectType:rejectType.join(',')
            });
            console.log('---------', res);
        } else {
            message.warning('请填写完整信息');
            return;
        }


        this.setState({
            rejPhones:'',
            rejectType:[],
            auditOpinion:'',
            rejModalVisible: false,
        });
    };

    rejhandleCancel = () => {
        this.setState({
            rejPhones:'',
            rejectType:[],
            auditOpinion:'',
            rejModalVisible: false,
        });
    };


    input_change(e) {
        this.setState({
            phones: e.target.value,
        });
    }


    rejInput_change(e) {
        this.setState({
            rejPhones: e.target.value,
        });
    }
    rejCheck_change(e) {
        this.setState({
            rejectType: e,
        });
    }
    rejAudit_change(e){
        this.setState({
            auditOpinion: e.target.value,
        });
    }


    // 查询
    _searchHandle = (useCache) => {
        const { tel, id, status, pageNum, pageSize } = this.state;
        this.searchCache = {
            accountSubjectType: 'MERCHANT',
            tel,
            id,
            taxCommissionStatus: status.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };

        this.props.getlistTaxCommissionRecord(this.searchCache); // 发起请求
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ tel: '', id: '', status: {}, pageNum: 1, pageSize: 20 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }
    // 导出
    export = (type) => {
        const { batchData, tel, id, status, pageNum, pageSize } = this.state;
        const param = {
            ids: batchData,
            accountSubjectType:'MERCHANT',
            // taxCommissionStatus: status.code,
            // tel,
            // id,
            // pageNum,
            // pageSize
        };
        var url = '/settle/taxcommissionrecord/exportTaxCommissionRecordExcel';
        if (type == 'File') {
            url = '/settle/taxcommissionrecord/exportFile';
        }
        http(url, param, 'POST').then((res) => {
            if (res.result) {
                const regular = /(http|https):\/\/([\w.]+\/?)\S*/;
                regular.test(res.result) ? (location.href = res.result, message.success('导出成功')) : message.warning('导出路径无效');
                // this.ficateRef._cleanAllSelected();
            } else {
                message.warning('暂无数据');
            }
        }).catch((res = {}) => {
            message.error(res.message);
        });

    }
    // 批量开通
    openHandle = () => {
        this.setState({
            modalVisible: true,
        });
        // const { batchData } = this.state;
        // if (batchData.length > 0) {
        //     showConfirm('确认为用户开通临时税务登记证吗?', '你还要继续吗？', () => {
        //         http('/settle/taxcommissionrecord/taxCommissionAuditApproval', { ids: batchData }, 'POST')
        //             .then(() => {
        //                 message.success('开通成功。');
        //                 this.ficateRef._cleanAllSelected();
        //                 this._searchHandle('useCache');
        //             }).catch((reject) => {
        //                 message.error(reject.message);
        //             });
        //     });
        // } else {
        //     message.warn('请选择需开通的项!');
        //     return;
        // }
    }
    _tableAction = (id, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '登记证详情',
                    path: `/home/storehousefinancialmanage/taxcertificate/detail/${id}`
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/taxcertificate/detail/${id}`
                });
                break;
            case 'batchData':
                this.setState({ batchData: id });
                break;
            default:
                return null;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'reject':
                return <ApplyInvoice data={this.state.openModeParam} title='驳回原因' refsh={() => this._searchHandle('useCache')} visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', openModeParam: null });
        if (update) {
            this.setState({ batchData: [] }, () => {
                this._searchHandle('useCache');
                this.ficateRef._cleanAllSelected();
            });
        }
    }
    //判断勾选数据是否统一状态
    _isUnitedState = (type, batchData) => {
        let statuses = batchData.filter(item => item.payStatus === 'APPLY') || [];
        return statuses.length > 0 ? false : true;
    }

    render() {
        const { id, tel, status, payStatus } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                <KeepAlive id='2' render={(state) => {
                    if (!this.state.__aliveInit) {
                        this.setState({ ...state, __aliveInit: true }, () => {
                            this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                        });
                    }
                }}>
                    <>
                        <SearchBox>
                            <SearchItem>
                                <XInputNum
                                    label='协议编号'
                                    placeholder='请输入协议编号'
                                    value={id}
                                    bindThis={this}
                                    bindName='id'
                                    min={0}
                                    precision={0}
                                    maxLength={16}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInputNum
                                    label='手机号码'
                                    placeholder='请输入手机号码'
                                    value={tel}
                                    bindThis={this}
                                    bindName='tel'
                                    min={0}
                                    precision={0}
                                    maxLength={16}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='状态'
                                    placeholder='请选择'
                                    renderData={payStatus}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={status.value}
                                    bindThis={this}
                                    bindName='status'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                        <div className={styles.row2}>
                            <XOKButton
                                label='导出信息'
                                style={{ width: 90, marginRight: 20 }}
                                onClick={() => this.export('Excel')}
                            />
                            <XOKButton
                                label='导出材料'
                                style={{ width: 90, marginRight: 20 }}
                                onClick={() => this.export('File')}
                            />
                            <XOKButton
                                label='批量开通'
                                style={{ width: 90, marginRight: 20 }}
                                onClick={() => this.openHandle()}
                            />
                            <XOKButton
                                label='批量驳回'
                                style={{ width: 90, marginRight: 20 }}
                                onClick={() => {
                                    // if (this.state.batchData.length > 0) {
                                    //     this.setState({ currentOpenMode: 'reject', openModeParam: this.state.batchData });
                                    // } else {
                                    //     message.warn('请选择需要驳回的项!');
                                    // }
                                    this.setState({rejModalVisible:true}); 
                                }}
                            />
                        </div>
                        <div className={styles.row2}>
                            说明：只导出状态为申请中的会员文件及材料
                        </div>
                        <TableContent
                            ref={(ficateRef) => this.ficateRef = ficateRef}
                            renderData={this.props.listTaxCommissionRecord}
                            tableAction={this._tableAction}
                            paginationChange={this._paginationChange}
                        />
                        {this._renderMode()}
                    </>
                    <Modal
                        title="批量临时税务登记"
                        visible={this.state.modalVisible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <div className={styles.m_m}>


                            <Row>
                                <Col span={4}>手机号码：</Col>
                                <Col span={20}>
                                    <TextArea
                                        placeholder="最高支持200人，输入会员手机号，以文逗号隔开"
                                        autoSize={{ minRows: 4, maxRows: 10 }}
                                        value={this.state.phones}
                                        onChange={this.input_change.bind(this)}
                                    />
                                </Col>
                            </Row>

                        </div>
                        <div className={styles.m_m}>
                            <XDatePicker
                                label='到期时间：'
                                value={this.state.endDate}
                                bindThis={this}
                                bindName='endDate'
                                showTime={false}
                                isFormat={true}
                            />
                        </div>
                    </Modal>


                    <Modal
                        title="批量驳回临时税务登记"
                        visible={this.state.rejModalVisible}
                        onOk={this.rejhandleOk}
                        onCancel={this.rejhandleCancel}
                    >
                        <div className={styles.m_m}>
                            <Row>
                                <Col span={4}>手机号码：</Col>
                                <Col span={20}>
                                    <TextArea
                                        placeholder="最高支持200人，输入会员手机号，以文逗号隔开"
                                        autoSize={{ minRows: 4, maxRows: 10 }}
                                        value={this.state.rejPhones}
                                        onChange={this.rejInput_change.bind(this)}
                                    />
                                </Col>
                            </Row>

                        </div>

                        <div className={styles.m_m}>
                            <Row>
                                <Col span={4}>需要重新提交材料</Col>
                                <Col span={20}>
                                    <CheckboxGroup
                                        options={this.state.plainOptions}
                                        value={this.state.rejectType}
                                        onChange={this.rejCheck_change.bind(this)}
                                    />
                                </Col>
                            </Row>

                        </div>

                        <div className={styles.m_m}>
                            <Row>
                                <Col span={4}>驳回原因</Col>
                                <Col span={20}>
                                    <TextArea
                                        placeholder="限制100个字"
                                        autoSize={{ minRows: 4, maxRows: 10 }}
                                        value={this.state.auditOpinion}
                                        onChange={this.rejAudit_change.bind(this)}
                                    />
                                </Col>
                            </Row>

                        </div>
                    </Modal>
                </KeepAlive>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    listTaxCommissionRecord: PropTypes.object,
    getlistTaxCommissionRecord: PropTypes.func
};
const mapStateToProps = (state) => ({
    listTaxCommissionRecord: state.taxcertificate.listTaxCommissionRecord
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);