import React from 'react';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import styles from './ActivityDateTable.module.scss';
import PropTypes from 'prop-types';
import { Table, message } from 'antd';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/marketingmanage/cardticket/model';
import http from '@/assets/api/http';

class ActivityDateTable extends React.Component {

    columns = () => ([
        {
            title: '会员昵称',
            dataIndex: 'nickName',
            key: 'nickName',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员账号',
            dataIndex: 'userTel',
            key: 'userTel',
            align: 'center',
            width: '20%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '发放时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '过期时间',
            dataIndex: 'overdueTime',
            key: 'overdueTime',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'status.value',
            key: 'status.value',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
    ]);

    state = {
        tableDataSource: [], // 表格数据源
        selectedRowKeys: [],//对应的key数据
        nickname: '', // 会员昵称
        useStatus: {}, // 使用状态
        accountName: '', // 会员账号
        exportLoading: false,
        pageNum: 1,
        pageSize: 30,
    }

    componentDidUpdate(prevProps) {
        //处理tab左右切换只请求两次
        if (this.props.activeKeyDiff !== prevProps.activeKeyDiff) {
            if (this.props.activeKeyDiff == '1') {
                // console.log('执行了1');
                this.props.getCardticketPublishTable(this.searchCache);// 重新渲染更新列表
            }
        }
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, nickname, useStatus, accountName } = this.state;
        const { data = {} } = this.props;
        this.searchCache = {
            nickName: nickname,
            status: useStatus.code,
            userTel: accountName,
            couponId: data.id,
            type: 1,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getCardticketPublishTable(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ nickname: undefined, useStatus: {}, accountName: undefined, }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getCardticketPublishTable(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data;
    }

    // 表格复选框操作回调
    onSelectChange = (selectedRowKeys) => {
        // console.log(selectedRowKeys, 'selectedRowKeys左');
        this.setState({ selectedRowKeys });
    };

    //导出
    _exportHandle = () => {
        const { selectedRowKeys, nickname, useStatus, accountName } = this.state;
        if (selectedRowKeys.length == 0) {
            message.warn('至少选中一条信息');
        } else {
            const { data = {} } = this.props;
            http('/coupon/coupon/exportFindCouponData', {
                nickName: nickname,
                status: useStatus.code,
                userTel: accountName,
                couponId: data.id,
                type: 1,
                idList: selectedRowKeys,
            }, 'POST').then((res = {}) => {
                if (res.result) {
                    const regular = /(http|https):\/\/([\w.]+\/?)\S*/;
                    regular.test(res.result) ? (location.href = res.result, message.success('导出成功')) : message.warning('导出路径无效');
                } else {
                    message.warning('暂无数据');
                }
                this.setState({ selectedRowKeys: [] });
                this.props.getCardticketPublishTable(this.searchCache);// 重新渲染更新列表
            }).catch((reject = {}) => {
                message.error(reject.message);
            });
        }
    }

    render() {
        const { cardticketPublishTable, cardticketUseEnum } = this.props;

        const { pageNum, pageSize, resultTotal, dataList } = cardticketPublishTable;
        const { selectedRowKeys, nickname, accountName, useStatus, exportLoading } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.container}>
                    <div className={styles.pro_commont}>
                        <XInput
                            inputStyle={{ width: '150px' }}
                            label='会员昵称'
                            placeholder='请输入'
                            value={nickname}
                            bindThis={this}
                            bindName='nickname'
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '150px' }}
                            label='会员账号'
                            placeholder='请输入'
                            value={accountName}
                            bindThis={this}
                            bindName='accountName'
                        />
                        <XSelect
                            style={{ marginLeft: '30px', width: '216px' }}
                            selectStyle={{ width: '150px' }}
                            label='使用状态'
                            placeholder='请选择'
                            renderData={cardticketUseEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={useStatus.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='useStatus'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.export}>
                        <XOKButton loading={exportLoading} style={{ width: 'auto' }} onClick={this._exportHandle} label='导出' />
                    </div>
                    <div className={styles.table_box}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowSelection={rowSelection}
                            rowKey={(record, index) => record.id}
                            columns={this.columns()}
                            dataSource={this._takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ y: 350 }}
                        />
                    </div>
                    <div className={styles.pagination_box}>
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange} />
                    </div>
                </div>
            </KeepAlive>
        );
    }
}

ActivityDateTable.propTypes = {
    data: PropTypes.object,
    cardticketPublishTable: PropTypes.object,
    getCardticketPublishTable: PropTypes.func,
    cardticketUseEnum: PropTypes.array,
    getCardticketUseEnum: PropTypes.func,
    activeKeyDiff: PropTypes.string,//处理tab左右切换只请求两次
};

const mapStateToProps = (state) => ({
    cardticketUseEnum: state.cardticket.cardticketUseEnum,
    cardticketPublishTable: state.cardticket.cardticketPublishTable
});

export default connect(mapStateToProps, { ...model.actions })(ActivityDateTable);