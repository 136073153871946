/**
 * 活动修改时间
 */
import React, { Fragment } from 'react';
import { Modal, message, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './ActivityTime.module.scss';
import http from '@/assets/api/http';
import { DatePicker } from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
import model from '@/reducers/marketingmanage/activities/model';
import { connect } from 'react-redux';
import { Date } from 'core-js';

const { TextArea } = Input;

class ActivityTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,

            activityStartTime: moment(this.props.data.modifyTime.entryStartTime) || null, // 活动报名开始时间
            startPickerDisabled: new Date() > new Date(this.props.data.modifyTime.entryStartTime), // 开始时间不可选
            activityEndTime: moment(this.props.data.modifyTime.entryEndTime) || null, // 活动报名开始时间
            endPickerDisabled: false, //结束时间不可选

            launchStartTime: moment(this.props.data.modifyTime.startTime) || null, // 活动发布开始时间
            launchTimePickerDisabled: false, //活动发布开始时间不可选
            launchEndTime: moment(this.props.data.modifyTime.endTime) || null, // 活动发布结束时间
            launchendPickerDisabled: false, //活动发布结束时间不可选

            areaValue: '',//延长理由
            datePickerType: this.props.data.type, //修改与发布区分
        };

    }

    componentDidMount() {
        //在抢拍类型如果是修改发布时间获取当前服务器时间
        const { modifyTime: { activityTypeCode } = {} } = this.props.data;
        if (activityTypeCode == 'QP_ACTIVITY' && this.state.datePickerType == 'releaseTime') {
            this.props.getNowDatetime();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.nowDatetime !== prevProps.nowDatetime) {
            const { nowDatetime } = this.props;
            const { launchStartTime } = this.state;
            if (!nowDatetime) return;
            if (moment(nowDatetime).valueOf() >= moment(launchStartTime).valueOf()) {
                this.setState({ launchTimePickerDisabled: true });
            }
        }
    }

    componentWillUnmount() {
        const { modifyTime: { activityTypeCode } = {} } = this.props.data;
        if (activityTypeCode == 'QP_ACTIVITY' && this.state.datePickerType == 'releaseTime') {
            this.props.getCleanNowDatetime();
        }
    }

    _areaChangeHandle = (e) => {
        this.setState({ areaValue: e.target.value });
    }
    disabledDate = (current) => {
        return current < moment().startOf('day');
    };
    disabledEedDate = (current) => {
        const { activityStartTime, launchStartTime, datePickerType, } = this.state;
        return current < moment(datePickerType == 'signTime' ? activityStartTime : launchStartTime).startOf('second');
    };
    //活动报名时间  
    _activityStartTime = (value) => {
        this.setState({
            activityStartTime: value,
        });
    }
    _activityEndTime = (value) => {
        this.setState({
            activityEndTime: value,
        });
    }
    //活动发布时间  
    _launchStartTime = (value) => {
        this.setState({
            launchStartTime: value,
        });
    }
    _launchEndTime = (value) => {
        this.setState({
            launchEndTime: value,
        });
    }

    //修改与发布区分
    ActivityDatePicker = () => {
        const { activityStartTime, startPickerDisabled, activityEndTime,
            endPickerDisabled, launchStartTime, launchTimePickerDisabled, launchEndTime, launchendPickerDisabled, } = this.state;
        if (this.state.datePickerType == 'signTime') {
            return (
                <Fragment>
                    <div className={styles.contentItem}>
                        <span className={styles.label}>活动报名开始时间:</span>
                        <span className={styles.text}>
                            <DatePicker
                                locale={locale}
                                disabledDate={this.disabledDate}
                                format="YYYY-MM-DD HH:mm:ss"
                                disabled={startPickerDisabled}
                                value={activityStartTime}
                                onChange={this._activityStartTime}
                                showTime
                                placeholder="活动报名开始时间"
                            />
                        </span>
                    </div>
                    <div className={styles.contentItem}>
                        <span className={styles.label}>活动报名结束时间:</span>
                        <span className={styles.text}>
                            <DatePicker
                                locale={locale}
                                disabledDate={this.disabledEedDate}
                                format="YYYY-MM-DD HH:mm:ss"
                                disabled={endPickerDisabled}
                                value={activityEndTime}
                                onChange={this._activityEndTime}
                                showTime
                                placeholder="活动报名结束时间"
                            />
                        </span>
                    </div>

                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <div className={styles.contentItem}>
                        <span className={styles.label}>活动发布开始时间:</span>
                        <span className={styles.text}>
                            <DatePicker
                                locale={locale}
                                disabledDate={this.disabledDate}
                                format="YYYY-MM-DD HH:mm:ss"
                                disabled={launchTimePickerDisabled}
                                value={launchStartTime}
                                onChange={this._launchStartTime}
                                showTime
                                placeholder="活动发布开始时间"
                            />
                        </span>
                    </div>
                    <div className={styles.contentItem}>
                        <label className={styles.label}>活动发布结束时间:</label>
                        <span className={styles.text}>
                            <DatePicker
                                locale={locale}
                                disabledDate={this.disabledEedDate}
                                format="YYYY-MM-DD HH:mm:ss"
                                disabled={launchendPickerDisabled}
                                value={launchEndTime}
                                onChange={this._launchEndTime}
                                showTime
                                placeholder="活动发布结束时间"
                            />
                        </span>
                    </div>
                </Fragment>
            );
        }
    }

    _handleOk = () => {
        const { data } = this.props;
        const { activityStartTime, activityEndTime, datePickerType, areaValue, launchEndTime, launchStartTime } = this.state;
        let paramData = {};
        let method = (datePickerType == 'signTime' ? 'updateEntryEndTime' : 'updateStartTime');
        if (areaValue == '') return message.warn('请填写延长理由');
        if (datePickerType == 'signTime') {
            if (activityStartTime === null || activityEndTime === null) {
                return message.warn('请填写报名时间');
            }
            if (activityStartTime.valueOf() > activityEndTime.valueOf()) {
                return message.warn('开始时间必须小于结束时间');
            }
            paramData.id = data.modifyTime.id;
            paramData.entryStartTime = activityStartTime.format('YYYY-MM-DD HH:mm:ss');
            paramData.entryEndTime = activityEndTime.format('YYYY-MM-DD HH:mm:ss');
            paramData.reason = areaValue;
        } else if (datePickerType == 'releaseTime') {
            if (launchStartTime === null || launchEndTime === null) {
                return message.warn('请填写发布时间');
            }
            if (launchStartTime.valueOf() > launchEndTime.valueOf()) {
                return message.warn('开始时间必须小于结束时间');
            }
            paramData.id = data.modifyTime.id;
            paramData.startTime = launchStartTime.format('YYYY-MM-DD HH:mm:ss');
            paramData.endTime = launchEndTime.format('YYYY-MM-DD HH:mm:ss');
            paramData.reason = areaValue;
        }

        http(`/marketing/activityInfo/${method}`, {
            ...paramData,
        }, 'POST').then(() => {
            message.success('修改成功');
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch((reject) => {
            message.error(reject.message);
        });

    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title, areaValue, } = this.state;
        const { data, } = this.props;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.label}>
                        <label>活动名称</label>
                        <span>{data.modifyTime.activityTitle || ''}</span>
                    </div>
                    <div className={styles.label}>
                        <label>活动标签</label>
                        <span>{data.modifyTime.activityType || ''}</span>
                    </div>
                    {this.ActivityDatePicker()}
                    <div className={styles.result}>
                        <span>延长理由</span>
                        <p>
                            <TextArea
                                className={styles.textArea}
                                placeholder='系统推送消息给商家'
                                rows={4}
                                onChange={this._areaChangeHandle}
                                value={areaValue}
                            />
                        </p>
                    </div>
                </div>
            </Modal>
        );

    }
}

ActivityTime.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题

    getNowDatetime: PropTypes.func,//当前服务器时间
    nowDatetime: PropTypes.string, // 当前服务器时间
    getCleanNowDatetime: PropTypes.func,//清除当前服务器时间
};

const mapStateToProps = (state) => {
    return {
        nowDatetime: state.activities.nowDatetime,
    };
};

export default connect(mapStateToProps, { ...model.actions, })(ActivityTime);