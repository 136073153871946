/**
 * 【订单交易明细页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DatePicker } from 'antd';
import moment from 'moment';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getTranscationServiceList } from '@/reducers/ordermanage/transcation/actions';
import model from '@/reducers/ordermanage/valueServiceOrder/model';
import { XInput, XSelect, XOKButton, XCancelButton, XInputNum, XDatePicker } from '@/components/xqxc_ui';
import TableServiceContent from './components/TableServiceContent';
import http from '@/assets/api/http';
import { message } from 'antd';
import styles from './index.module.scss';


import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payType: {},
            payTimeStart: null,
            payTimeEnd: null,
            serviceName: '',
            serviceNo: '',
            pageNum: 1,
            pageSize: 10,

        };
        this.searchCache = { pageNum: 1, pageSize: 20 }; // 查询缓存
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // 支付方式
        this.props.getPayType();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { payTimeStart, payTimeEnd, id, serviceName, payType, serviceNo, pageNum, pageSize, buyAccount } = this.state;
        this.searchCache = {
            id,
            buyAccount,
            payTimeStart,
            payTimeEnd,
            serviceName,
            serviceNo,
            payType: payType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getTranscationServiceList(this.searchCache);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            payTimeStart: null,
            payTimeEnd: null,
            id: '',
            serviceName: null,
            serviceNo: null,
            payType: {},
            buyAccount: null,
            pageNum: 1,
            pageSize: 10,
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        }, () => {
            this._searchHandle('useCache');
        });
    }
    // 下载导入模板
    _exportHandle = () => {
        const { payTimeStart, payTimeEnd, id, serviceName, payType, serviceNo, pageNum, pageSize, buyAccount } = this.state;
        const params = {
            id,
            buyAccount,
            payTimeStart,
            payTimeEnd,
            serviceName,
            serviceNo,
            payType: payType.code,
        };
        http('/settle/reconciliationSource/exportSettleOrderDetail', params, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result == '') {
                    message.error('没有订单数据');
                } else {
                    window.location.href = response.result;
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }
    render() {
        const { id, payTimeStart, payTimeEnd, serviceName, serviceNo, buyAccount, payType } = this.state;
        const { transcationServiceList = {}, history, payTypeArray } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInputNum
                                label='交易订单号'
                                placeholder='请输入交易订单号'
                                value={id}
                                bindThis={this}
                                bindName='id'
                                min={0}
                                precision={0}
                                maxLength={16}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='用户账号'
                                placeholder='请输入用户账号'
                                value={buyAccount}
                                bindThis={this}
                                bindName='buyAccount'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='服务名称'
                                placeholder='请输入服务名称'
                                value={serviceName}
                                bindThis={this}
                                bindName='serviceName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='服务编码'
                                placeholder='请输入服务编码'
                                value={serviceNo}
                                bindThis={this}
                                bindName='serviceNo'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='付款渠道'
                                placeholder='请选择付款渠道'
                                renderData={payTypeArray}
                                dataIndex='value'
                                keyIndex='code'
                                value={payType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='payType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='付款日期'
                                value={payTimeStart}
                                bindThis={this}
                                bindName='payTimeStart'
                                isFormat
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                label=''
                                value={payTimeEnd}
                                bindThis={this}
                                bindName='payTimeEnd'
                                isFormat
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton label='查询' onClick={this._searchHandle} />
                            <XCancelButton label='重置' onClick={this._resetHandle} />
                        </SearchItem>
                    </SearchBox>
                    {/* <div className={styles.export}>
                        <XOKButton label='导出' onClick={this._exportHandle} />
                    </div> */}
                    <TableServiceContent history={history} renderData={transcationServiceList || {}} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getTranscationServiceList: PropTypes.func,
    transcationServiceList: PropTypes.object,
    getPayType: PropTypes.func,
    payTypeArray: PropTypes.array,
};
const mapStateToProps = (state) => {
    return ({
        transcationServiceList: state.Transcation.transcationServiceList,
        payTypeArray: state.valueServiceOrder.payTypeArray,
    });
};
export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath, keepSecondNavBreadcrumb, getTranscationServiceList })(Main);