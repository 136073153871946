import React from 'react';
import styles from './AdvertTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table, Switch, Input, Icon } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class AdvertTable extends React.Component {

    _takeColumns = (actionClickHandle) => ([
        {
            title: '原因标题',
            dataIndex: 'reportTypeName',
            key: 'reportTypeName',
            width: '30%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作人',
            dataIndex: 'modifyUserName',
            key: 'modifyUserName',
            width: '30%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: '15%',
            align: 'center',
            render: (text, item) => (this._SwitchCellUpdate(text, item, actionClickHandle))
        },
        {
            title: '排序',
            dataIndex: 'sortNo',
            key: 'sortNo',
            width: '15%',
            align: 'center',
            render: (text, item) => (this._SortCellUpdate(text, item, actionClickHandle))
        },
    ]);


    // 表格项内容为可编辑
    _SortCellUpdate = (value, item, actionClickHandle) => {
        const label = React.createRef();
        const box = React.createRef();
        const input = React.createRef();

        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.sort_box}>
                    <div className={styles.label} ref={label}>{value}</div>
                    <div className={styles.input} ref={box}>
                        <Input
                            key={item.id}
                            style={{ width: '50px' }}
                            ref={input}
                            defaultValue={value}
                            onBlur={(e) => {
                                box.current.style.display = 'none';
                                label.current.style.display = 'block';
                                if (value != e.target.value) {
                                    actionClickHandle(item, 'sort', e.target.value);
                                }
                            }}
                        />
                    </div>
                    <div
                        className={styles.icon}
                        onClick={() => {
                            label.current.style.display = 'none';
                            box.current.style.display = 'block';
                            input.current.focus();
                        }}
                    >
                        <Icon type="edit" style={{ fontSize: '18px' }} />
                    </div>
                </div>
            </div>
        );
    };

    // 表格项内容为开关
    _SwitchCellUpdate = (sw, item, actionClickHandle) => {
        let checked = true;
        if (sw == 0) {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={checked}
                    onChange={(value) => {
                        actionClickHandle(item, 'switch', value);
                    }}
                />
            </div>
        );
    };
    // 操作表格
    _actionHandle = (id, type, value) => {
        this.props.tableAction(id, type, value);
    }

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }


    render() {
        const { paginationChange, paginations, renderData, } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => record.id}
                    columns={this._takeColumns(this._actionHandle)}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={paginations.resultTotal}
                    pageSize={paginations.pageSize}
                    pageNum={paginations.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    curTabID: PropTypes.number, // 当前TabID
    renderData: PropTypes.array.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    paginations: PropTypes.object,//分页数据
};

export default AdvertTable;