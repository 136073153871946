import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';

export default class TableData extends Component {

	static propTypes = {
		tableAction: PropTypes.func, // 对表格的操作
		renderData: PropTypes.array
	}

	_columns = [
		{
			title: '消费者权益说明',
			dataIndex: 'name',
			key: 'name',
			align: 'left',
			width: 300,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '描述',
			dataIndex: 'desc',
			key: 'desc',
			align: 'left',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'id',
			key: 'id',
			align: 'left',
			width: 200,
			render: (id) => this._tableAction(id)
		},
	]

	_tableAction = (key) => {
		const { tableAction } = this.props;
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>
				<div className={styles.item} onClick={() => tableAction(key, 'delete')}>删除</div>
			</div>
		);
	};


	render() {
		const { renderData } = this.props;

		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey='id'
					columns={this._columns}
					dataSource={renderData}
					pagination={false}
					scroll={{ y: true }}
				/>
			</div>
		);
	}
}