/**
 * 【区域公司列表】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XSelect, XOKButton, XCancelButton, showConfirm, XDatePicker, XScrollSelect } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import {
    getRegionalCompanyReconciliationTableSource, getRegionalCompanyLists
} from '@/reducers/storehousefinancialmanage/regionalcompanyreconciliation/actions';
import { getStateSelectSource } from '@/reducers/storehousefinancialmanage/merchantreconciliation/actions';
import AdvertTable from './components/AdvertTable';
import ConfirBill from './modal/ConfirBill';
import ManualPay from './modal/ManualPay';
import { isPlat } from '@/assets/js/authType'; // 总控
import GenerateStatement from './modal/GenerateStatement';
import { message } from 'antd';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends React.Component {

    state = {
        status: {},
        reconciliationId: undefined,
        selectedRowKeys: [],
        selectedRows: [],
        chargeValue: [],
        targetId: {},
        startDate: null,
        endDate: null,
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // 状态
        this.props.getStateSelectSource();
        // 区域公司
        this.props.getRegionalCompanyLists();
    }
    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }
    // 查询
    _searchHandle = (useCache) => {
        const { reconciliationId, status, targetId, startDate, endDate, pageNum, pageSize } = this.state;
        this.searchCache = {
            targetId: targetId.id,
            status: status.value,
            reconciliationId,
            startDate,
            endDate,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getRegionalCompanyReconciliationTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ chargeValue: [], reconciliationId: undefined, status: {}, pageNum: 1, pageSize: 10, targetId: {}, startDate: null, endDate: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const { reconciliationId, status, targetId, startDate, endDate } = this.state;
        this.searchCache = {
            targetId: targetId.id,
            status: status.value,
            reconciliationId,
            startDate,
            endDate,
            pageNum,
            pageSize
        };
        this.props.getRegionalCompanyReconciliationTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 表格操作
    _tableAction = (id, type, item) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                addOneBreadcrumbPath({
                    title: '对账详情',
                    path: '/home/storehousefinancialmanage/regionalcompanyreconciliation/detail/' + id,
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/regionalcompanyreconciliation/detail/' + id
                });
                break;
            case 'createinvoice':
                addOneBreadcrumbPath({
                    title: '新建发票',
                    path: '/home/storehousefinancialmanage/regionalcompanyreconciliation/createinvoice'
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/regionalcompanyreconciliation/createinvoice',
                    state: {
                        selectedRowKeys: [item.id],
                        selectedRows: [{
                            ...item,
                            managementFee: item.regionalCompanyManagementFee,
                            reconciliationAmount: item.amount,
                            invoiceAmount: item.settleAmount,
                            unInvoiceAmount: item.unSettleAmount,
                            reconciliationId: item.id
                        }]
                    }
                });
                break;
            case 'confir':
                this.setState({ currentOpenMode: 'confir', openModeParam: { id, type } });
                break;
            case 'send':
                showConfirm('是否确定发起对账？', '', () => {
                    http('/settle/reconciliationInfo/reconciliationLaunch', { reconciliationId: id }, 'POST')
                        .then(() => {
                            message.success('发起成功!');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'delete':
                showConfirm('是否确定删除对账？', '', () => {
                    http('/settle/reconciliationInfo/delReconciliation', { reconciliationIds: [id] }, 'POST')
                        .then(() => {
                            message.success('删除成功!');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'manualPay':
                this.setState({ currentOpenMode: 'manualPay', openModeParam: { id } });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'confir':
                return <ConfirBill title='确认对账单' refsh={this._searchHandle} visible={true} closeMode={this._closeMode} data={this.state.openModeParam} />;
            case 'generate':
                return <GenerateStatement title='生成对账' refsh={this._searchHandle} merchantList={[]} visible={true} closeMode={this._closeMode} />;
            case 'manualPay':
                return <ManualPay title='手工汇款' refsh={this._searchHandle} visible={true} closeMode={this._closeMode} data={this.state.openModeParam} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' }, () => {
            if (update) {
                this._searchHandle();
            }
        });
    }

    _generate = () => {
        this.setState({ currentOpenMode: 'generate', openModeParam: {} });
    }
    render() {
        const { status, reconciliationId, startDate, endDate, targetId } = this.state;
        const { regionalCompanyList: { result = [] } } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='对账单号'
                                placeholder='请输入'
                                value={reconciliationId}
                                bindThis={this}
                                bindName='reconciliationId'
                            />
                        </SearchItem>
                        {isPlat() &&
                            <SearchItem>
                                <div style={{ marginRight: 20 }}>
                                    <span>区域公司</span>
                                    <XScrollSelect
                                        style={{ width: 150, marginLeft: 10 }}
                                        url='/admin/company/listAreaCompanyName'
                                        placeholder='请选择'
                                        showSearch
                                        mainKey='companyName'
                                        selectID='id'
                                        selectName='companyName'
                                        value={targetId}
                                        listLabel='result'
                                        onChange={(value, item) => this.setState({ targetId: item.props['data-option'] })}
                                    />
                                </div>
                            </SearchItem>
                        }
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={this.props.stateSelectSource}
                                dataIndex='name'
                                keyIndex='value'
                                value={status.name}
                                bindThis={this}
                                bindName='status'
                            />
                        </SearchItem>
                        {isPlat() &&
                            <SearchItem>
                                <XDatePicker
                                    placeholder='请选择开始时间'
                                    label='对账周期'
                                    value={startDate}
                                    bindThis={this}
                                    bindName='startDate'
                                    isFormat={true}
                                />
                                <XDatePicker
                                    placeholder='请选择结束时间'
                                    style={{ marginRight: '20px' }}
                                    value={endDate}
                                    bindThis={this}
                                    bindName='endDate'
                                    isFormat={true}
                                />
                            </SearchItem>
                        }
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.row2}>
                        {isPlat() && <XOKButton
                            style={{ width: '90px', marginRight: '20px' }}
                            label='生成对账'
                            onClick={this._generate}
                        />}
                        {/*
                            <XOKButton
                                style={{ width: '90px' }}
                                label='新建发票'
                                onClick={() => this._tableAction('', 'createinvoice')}
                            />
                         */}
                        {/* 中联信对账，暂时移除 */}
                        {/* {isRE() ? <WalletButton showWallet={isRE()} /> : null} */}
                    </div>
                    <AdvertTable onSelection={(selectedRowKeys, selectedRows) => {
                        this.setState({ selectedRowKeys, selectedRows });
                    }} renderData={this.props.regionalCompanyReconciliationTableSource} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    stateSelectSource: PropTypes.array,
    getStateSelectSource: PropTypes.func,
    getRegionalCompanyLists: PropTypes.func,
    regionalCompanyList: PropTypes.object,
    getRegionalCompanyReconciliationTableSource: PropTypes.func,
    regionalCompanyReconciliationTableSource: PropTypes.object
};

const mapStateToProps = (state) => ({
    stateSelectSource: state.merchantreconciliation.stateSelectSource,
    regionalCompanyList: state.regionalcompanyreconciliation.regionalCompanyList,
    regionalCompanyReconciliationTableSource: state.regionalcompanyreconciliation.regionalCompanyReconciliationTableSource
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getStateSelectSource,
    getRegionalCompanyReconciliationTableSource,
    getRegionalCompanyLists
})(Main);