//异步获取数据
export const GET_COMMODITYREVIEW_LIST = 'COMMODITYREVIEW/GET_COMMODITYREVIEW_LIST';
export const GET_COMMODITYREVIEW_DETAIL = 'COMMODITYREVIEW/GET_COMMODITYREVIEW_DETAIL';
export const GET_COMMODITYREVIEW_SELE = 'COMMODITYREVIEW/GET_COMMODITYREVIEW_SELE';
export const GET_COMMODITYREVIEW_SYSTEMTYPE = 'GET_COMMODITYREVIEW_SYSTEMTYPE';

//保存数据到store.state
export const SET_COMMODITYREVIEW_LIST = 'COMMODITYREVIEW/SET_COMMODITYREVIEW_LIST';
export const SET_COMMODITYREVIEW_DETAIL = 'COMMODITYREVIEW/SET_COMMODITYREVIEW_DETAIL';
export const SET_COMMODITYREVIEW_SELE = 'COMMODITYREVIEW/SET_COMMODITYREVIEW_SELE';
export const SET_COMMODITYREVIEW_SYSTEMTYPE = 'SET_COMMODITYREVIEW_SYSTEMTYPE';

