export const GET_MERCHANT_DATA = 'MERCHANTLIST/GET_MERCHANT_DATA'; // 异步获取'商户列表表格'数据源
export const SET_MERCHANT_DATA = 'MERCHANTLIST/SET_MERCHANT_DATA'; // 将'商户列表表格'存储至store.state中

export const GET_STORE_DATA = 'MERCHANTLIST/GET_STORE_DATA'; // 异步获取'商品店铺'数据源
export const SET_STORE_DATA = 'MERCHANTLIST/SET_STORE_DATA'; // 将'商品店铺'存储至store.state中

export const GET_STOREDETAIL_DATA = 'MERCHANTLIST/GET_STOREDETAIL_DATA'; // 异步获取'商户详情与商户编辑'数据源
export const SET_STOREDETAIL_DATA = 'MERCHANTLIST/SET_STOREDETAIL_DATA'; // 将'商户详情与商户编辑'存储至store.state中


export const GET_MERCHANTRADIUS_DATA = 'MERCHANTLIST/GET_MERCHANTRADIUS_DATA'; // 异步获取'新建商户类型'数据源
export const SET_MERCHANTRADIUS_DATA = 'MERCHANTLIST/SET_MERCHANTRADIUS_DATA'; // 将'新建商户类型'存储至store.state中

export const GET_MERCHANTSELECT_DATA = 'MERCHANTLIST/GET_MERCHANTSELECT_DATA'; // 异步获取'商户列表下拉选项'数据源
export const SET_MERCHANTSELECT_DATA = 'MERCHANTLIST/SET_MERCHANTSELECT_DATA'; // 将'商户列表下拉选项'存储至store.state中

export const GET_MERCHANTSERVICE_DATA = 'MERCHANTLIST/GET_MERCHANTSERVICE_DATA'; // 异步获取'所属服务商下拉选项'数据源
export const SET_MERCHANTSERVICE_DATA = 'MERCHANTLIST/SET_MERCHANTSERVICE_DATA'; // 将'所属服务商下拉选项'存储至store.state中

export const GET_MERCHANTSERVICECENTER_DATA = 'MERCHANTLIST/GET_MERCHANTSERVICECENTER_DATA'; //运营中心
export const SET_MERCHANTSERVICECENTER_DATA = 'MERCHANTLIST/SET_MERCHANTSERVICECENTER_DATA'; // 运营中心

export const GET_MERCHANTSERVICECOMPANY_DATA = 'MERCHANTLIST/GET_MERCHANTSERVICECOMPANY_DATA'; //区域公司
export const SET_MERCHANTSERVICECOMPANY_DATA = 'MERCHANTLIST/SET_MERCHANTSERVICECOMPANY_DATA'; // 区域公司

export const GET_NETWORKINFO_DATA = 'MERCHANTLIST/GET_NETWORKINFO_DATA'; //入网功能
export const SET_NETWORKINFO_DATA = 'MERCHANTLIST/SET_NETWORKINFO_DATA'; //入网功能

export const GET_PROPTION_DATA = 'MERCHANTLIST/GET_PROPTION_DATA'; // 异步获取'新建商户基础费用模式'数据源
export const SET_PROPTION_DATA = 'MERCHANTLIST/SET_PROPTION_DATA'; // 将'新建商户基础费用模式'存储至store.state中


// 获取地域：省市区
export const GET_PROVINCE = 'MEMBER/GET_PROVINCE';
export const SET_PROVINCE = 'MEMBER/SET_PROVINCE';
export const SET_CITY = 'MEMBER/SET_CITY';
export const SET_STREET = 'MEMBER/SET_STREET';


//商户类型在实体加盟中运营中心、区域、服务商（不可以选）数据依据地址的市，区参数=》服务商
export const GET_RADIOTYPE_DATA = 'MERCHANTLIST/GET_RADIOTYPE_DATA';
export const SET_RADIOTYPE_DATA = 'MERCHANTLIST/SET_RADIOTYPE_DATA';//公共的
export const SET_OPERRATION_DATA = 'MERCHANTLIST/SET_OPERRATION_DATA';//运营中心
export const SET_AREA_DATA = 'MERCHANTLIST/SET_AREA_DATA';//区域公司
