import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import { SearchBox, SearchItem } from '@/components/Layout';
import { autoPageNum, autopageSize } from '@/assets/config';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker, XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell } from '@/components/TableCell';
import { getSessionStorage, removeSessionStorage, setSessionStorage } from '@/assets/js/storage';
import KeepAlive from '@/routes/KeepAlive';

const getStatusEnum = () => {
    return http('/enum/OutAccountWithdrawStatusEnum', {}, 'POST')
        .then(res => { return res.result; });
};

const getAccessTypeEnum = () => {
    return http('/enum/getPaymentAccessTypeEnum', {}, 'POST')
        .then(res => { return res.result; });
};

const getTableData = (params = {}) => {
    const { startTime = null, entTime = null, accessTypeItem = {}, statusItem = {}, ...other } = params;
    const query = {
        startTime: startTime && startTime.format('YYYY-MM-DD 00:00:00'),
        entTime: entTime && entTime.format('YYYY-MM-DD 23:59:59'), subjectType: 'MERCHANT_SUPPLIER',
        accessType: accessTypeItem.code, status: statusItem.code, ...other
    };
    return http('/merchant/withdraw/yums/withdrawList', query, 'POST')
        .then(res => { return res.result; });
};

const defaultQuery = {
    id: null, name: null, accessTypeItem: {}, statusItem: {}, startTime: null,
    entTime: null, pageNum: autoPageNum, pageSize: autopageSize
};

const SupplierWithdraw = ({ history }) => {

    const [requery, setRequery] = useState(defaultQuery);
    const [statusEnum, setStatusEnum] = useState([]);
    const [accessTypeEnum, setAccessTypeEnum] = useState([]);
    const [tableData, setTableData] = useState({ dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 });

    useEffect(() => {
        (async () => {
            const senum = await getStatusEnum();
            const aenum = await getAccessTypeEnum();
            setStatusEnum(senum); setAccessTypeEnum(aenum);
        })();
    }, []);

    const onSearch = async (params = {}) => {
        const result = await getTableData({ ...requery, ...params });
        setTableData(result);
        KeepAlive.saveCache({ ...requery, ...params });
    };

    const onFormChange = (params = {}) => {
        const callback = pre => {
            return { ...pre, ...params };
        };
        setRequery(callback);
    };

    const operateCell = (t, { id, }) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => {
                setSessionStorage('merchantwithdrawalrecords', 'Supplier');
                history.push({
                    pathname: `/home/storehousefinancialmanage/merchantwithdrawalrecords/supplierwithdrawinfo/detail/${id}`
                });
            }}>提现明细</div>
        </div>;
    };

    const columns = [
        {
            title: '提现单号', dataIndex: 'id', key: 'id',
            width: cellWidth.normal, align: 'left', onCell: tooltipStyle, render: tooltip
        },
        {
            title: '供应商名称', dataIndex: 'subjectName', key: 'subjectName',
            width: 200, align: 'left', onCell: tooltipStyle, render: tooltip
        },
        {
            title: '提现金额（元）', dataIndex: 'amount', key: 'amount',
            width: cellWidth.small, align: 'left', onCell: tooltipStyle, render: priceFenToYuanCell
        },
        {
            title: '手续费（元）', dataIndex: 'fee', key: 'fee',
            width: cellWidth.small, align: 'left', onCell: tooltipStyle, render: priceFenToYuanCell
        },
        {
            title: '银行卡', dataIndex: 'bankCardNo', key: 'bankCardNo',
            width: cellWidth.normal, align: 'left', onCell: tooltipStyle, render: tooltip
        },
        {
            title: '提现通道', dataIndex: 'accessType.value', key: 'accessType.value',
            width: cellWidth.small, align: 'left', onCell: tooltipStyle, render: tooltip
        },
        {
            title: '状态', dataIndex: 'statusName', key: 'statusName',
            width: cellWidth.small, align: 'left', onCell: tooltipStyle, render: tooltip
        },

        {
            title: '备注', dataIndex: 'remark', key: 'remark',
            width: cellWidth.small, align: 'left', onCell: tooltipStyle, render: tooltip
        },
        {
            title: '提现时间', dataIndex: 'gmtCreate', key: 'gmtCreate',
            width: cellWidth.time, align: 'left', onCell: tooltipStyle, render: tooltip
        },
        {
            title: '操作', dataIndex: 'key', key: 'key', align: 'left',
            width: cellWidth.small, render: operateCell
        }
    ];

    const onChange = (param = {}) => {
        onSearch(param);
        onFormChange(param);
    };

    return <KeepAlive id='2' render={onChange}><div className={styles.flexBoxContainer}>
        <SearchBox>
            <SearchItem>
                <XInput label='提现单号' placeholder='请输入提现单号' style={{ marginRight: '20px' }}
                    value={requery.id} onChange={id => onFormChange({ id })}
                />
            </SearchItem>
            <SearchItem>
                <XInput label='供应商名称' placeholder='请输入供应商名称' style={{ marginRight: '20px' }}
                    value={requery.name} onChange={name => onFormChange({ name })}
                />
            </SearchItem>
            <SearchItem>
                <XSelect label='提现通道' showSearch placeholder='请选择提现通道' style={{ marginRight: '20px' }} selectStyle={{ width: '150px' }}
                    isOptionBindID dataIndex='value' keyIndex='code' value={requery.accessTypeItem && requery.accessTypeItem.value} renderData={accessTypeEnum}
                    onChange={accessTypeItem => onFormChange({ accessTypeItem })}
                />
            </SearchItem>
            <SearchItem>
                <XSelect label='状态' showSearch placeholder='请选择状态' style={{ marginRight: '20px' }} selectStyle={{ width: '150px' }}
                    isOptionBindID dataIndex='value' keyIndex='code' value={requery.statusItem && requery.statusItem.value} renderData={statusEnum}
                    onChange={statusItem => onFormChange({ statusItem })}
                />
            </SearchItem>
            <SearchItem>
                <XDatePicker label='提现日期' placeholder='请选择开始日期' value={requery.startTime}
                    showTime={false} onChange={startTime => onFormChange({ startTime })}
                />
                <XDatePicker style={{ marginRight: '20px' }} label='' placeholder='请选择结束日期'
                    value={requery.entTime} showTime={false} onChange={entTime => onFormChange({ entTime })}
                />
            </SearchItem>
            <SearchItem>
                <XOKButton label='查询' style={{ marginRight: '20px' }} onClick={() => onSearch({})} />
                <XCancelButton label='重置' onClick={() => onFormChange(defaultQuery)} />
            </SearchItem>
        </SearchBox>
        <div className={styles.tableContainer} style={{ height: '100%' }}>
            <Table rowClassName={(r, i) => i % 2 === 0 ? '' : styles.tdcolor} rowKey={r => r.id}
                columns={columns} dataSource={tableData.dataList} pagination={false} scroll={{ x: 'max-content', y: true }} />
            <XPagination resultTotal={tableData.resultTotal} pageSize={tableData.pageSize}
                pageNum={tableData.pageNum} onChange={(pageSize, pageNum) => onSearch({ pageNum, pageSize })}
            />
        </div>
    </div></KeepAlive>;
};
SupplierWithdraw.propTypes = {
    history: PropTypes.object
};
export default SupplierWithdraw;