/**
 * 操作日志
 */
import React, { useEffect, useState } from 'react';
import { Modal, Table, } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { XOKButton, XPagination } from '@/components/xqxc_ui';
import { Divider } from 'antd';
import { connect } from 'react-redux';
import model from '@/reducers/systemmanage/merchantpermission/model';

const OperateLog = ({ closeMode,
    modeParams: { merchantId = '' } = {},
    getOperateLogData,
    operateLogData: {
        pageNum,
        pageSize,
        dataList,
        resultTotal
    }
}) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState(30);

    useEffect(() => {
        getOperateLogData({ merchantId, pageNum: currentPage, pageSize: pageNumber });
    }, [currentPage, pageNumber]);

    const columns = [
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '操作',
            dataIndex: 'logAction',
            key: 'logAction',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '操作记录',
            dataIndex: 'logContent',
            key: 'logContent',
            width: '50%',
            align: 'center',
            render: tooltip
        }, {
            title: '操作时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '操作员',
            dataIndex: 'gmtCreateName',
            key: 'gmtCreateName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    const paginationChange = (pageSize, pageNum) => {
        setCurrentPage(pageNum);
        setPageNumber(pageSize);
    };

    return (
        <Modal
            width={1200}
            centered
            title='操作日志'
            visible={true}
            confirmLoading={false}
            onCancel={() => closeMode(false)}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            footer={false}
            bodyStyle={{ paddingBottom: 10 }}
        >
            <div>
                <Table
                    rowKey={(record, index) => index}
                    columns={columns}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ y: 400 }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
                <Divider />
                <XOKButton
                    style={{ width: 90, margin: '0 auto' }}
                    label='返回'
                    onClick={() => closeMode()}
                />
            </div>
        </Modal>
    );
};
OperateLog.propTypes = {
    modeParams: PropTypes.object, // 弹框入参
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getOperateLogData: PropTypes.func,
    operateLogData: PropTypes.object
};
OperateLog.defaultProps = {
    operateLogData: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
};
export default connect(state => ({
    operateLogData: state.merchantpermission.operateLogData,
}), {
    ...model.actions
})(OperateLog);