import React from 'react';
import { Card, Switch, Icon, Typography, Tooltip, Empty } from 'antd';
import styles from './PreShowItem.module.scss';
import PropTypes from 'prop-types';
import { showConfirm } from '@/components/xqxc_ui';

const { Text } = Typography;
const { Meta } = Card;

const PreShowItem = ({ onRemove, item, onPreTemple }) => {
    return <Card
        bordered
        hoverable
        style={{ width: 300, marginBottom: 20, marginLeft: 10 }}
        cover={
            <div className={styles.imgBoz} onClick={() => onPreTemple(item.customTemplateId)}>
                {item.templateImg
                    ? <img width='100%' src={item.templateImg} />
                    : <Empty style={{ marginTop: 90 }} image={Empty.PRESENTED_IMAGE_SIMPLE} description='暂无预览图片' />
                }
            </div>
        }
    >
        <Meta description={
            <div className={styles.operateBox}>
                <Tooltip placement='top' title={item.templateName} arrowPointAtCenter>
                    <Text strong ellipsis>{item.templateName}</Text>
                </Tooltip>
                <div className={styles.operation}>
                    <Switch
                        onChange={checked => onRemove('/admin/templatemanage/updateTemplateRecoState', { id: item.id, isReco: checked ? 1 : 0 })}
                        size='small'
                        defaultChecked={item.isReco.code != 0 ? true : false}
                    />
                    <Icon
                        onClick={() => {
                            showConfirm('确定要删除此信息？', '您是否确认删除？', () => {
                                onRemove(`/admin/templatemanage/delTemplate/${item.id}`, {});
                            });
                        }}
                        type="delete"
                        style={{ fontSize: 16, paddingLeft: 10 }}
                    />
                </div>
            </div>
        } />
    </Card>;
};
PreShowItem.propTypes = {
    onPreTemple: PropTypes.func,
    onRemove: PropTypes.func,
    item: PropTypes.object,
};
export default PreShowItem;