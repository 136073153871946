/**
 * 规则创建与编辑
 */
import React from 'react';
import { Modal, Radio, message, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import styles from './ConverRulesModal.module.scss';
import { XInput } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { FloatConversion } from '@/util/conversion';

const toPercent = (num) => {
    return (num + '00').replace(/\.([\d]{2})/, '$1.') * 1;
};

const RadioGroup = Radio.Group;
class DurationRulesModal extends React.Component {
    constructor(props) {
        super(props);
        this.floatConversion = new FloatConversion();
        this.state = {
            title: props.title,
            visible: props.visible,
            confirmLoading: false,
            showMsg: props.data.id !== undefined ? props.data.ruleName : '',//规则名称
            state: props.data.id !== undefined ? props.data.status : '1',//状态
            poundage: props.data.id !== undefined ? (this.floatConversion.divide(props.data.chargeRatio, 100)) : null,//人气值手续费
            securityNum: props.data.id !== undefined ? (this.floatConversion.divide(props.data.limitCycleRate, 100)) : null,//安全值
            securityNumR: props.data.id !== undefined ? (this.floatConversion.divide(props.data.safetyValue, 100)) : null,//安全值
            counted: props.data.id !== undefined ? props.data.roundMode : 'ROUND_DOWN',//兑换结果取数
            defaultState: props.data.id !== undefined ? props.data.defaulted : '0',//默认项
        };
    }

    _handleOk = () => {
        const { data } = this.props;
        const { showMsg, state, poundage, securityNum, securityNumR, counted, defaultState } = this.state;
        if (showMsg == '') {
            return message.warn('请输入规则名称。');
        } else if (poundage === null) {
            return message.warn('请输入人气值手续费。');
        } else if (securityNum === null) {
            return message.warn('请输入限制环比。');
        } else if (securityNumR === null) {
            return message.warn('请输入安全值。');
        }
        const method = data.id === undefined ? 'createRule' : 'updateRule';
        let submitDate = {
            ruleName: showMsg,
            status: state,
            chargeRatio: toPercent(poundage),
            limitCycleRate: toPercent(securityNum),
            safetyValue: toPercent(securityNumR),
            roundMode: counted,
            defaulted: defaultState,
            ruleId: data.id === undefined ? undefined : data.id
        };

        this.setState({ confirmLoading: true }, () => {
            http(`/settle/starlightExchange/${method}`, submitDate, 'POST').then((res = {}) => {
                if (res.code === 500) {
                    message.error(res.message);
                } else {
                    data.id === undefined ? message.success('创建成功。') : message.success('更新成功。');
                    this.setState({
                        visible: false,
                        confirmLoading: false
                    });
                    this.props.closeMode(true);
                }
            }).catch((res = {}) => {
                message.error(res.message || '请求错误');
                this.setState({
                    confirmLoading: false
                });
            });
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // Radio状态改变时触发 
    _radioValueChangeHandle = (e) => {
        this.setState({ state: e.target.value });
    }
    _defaultChangeHandle = (e) => {
        this.setState({ defaultState: e.target.value });
    }
    _countedChangeHandle = (e) => {
        this.setState({ counted: e.target.value });
    }

    render() {
        const { visible, confirmLoading, title, showMsg, state, poundage, securityNum, securityNumR, counted, defaultState } = this.state;

        return (
            <Modal
                width={500}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '408px' }}
                        inputStyle={{ width: '323px', marginLeft: '27px' }}
                        label='规则名称'
                        placeholder='请输入规则名称'
                        value={showMsg}
                        bindThis={this}
                        bindName='showMsg'
                    />
                    <div className={styles.radio}>
                        <div className={styles.label}>状态</div>
                        <RadioGroup style={{ marginLeft: '10px' }} onChange={this._radioValueChangeHandle} value={state}>
                            <Radio value={'1'}>启用</Radio>
                            <Radio value={'0'}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.poundage}>
                        <label className={styles.label}>人气值手续费</label>
                        <InputNumber
                            style={{ width: '100px', margin: '0 5px 0 12px' }}
                            min={0}
                            max={100}
                            placeholder='请输入'
                            value={poundage}
                            precision={1}
                            step={0.1}
                            onChange={(e) => {
                                this.setState({ poundage: e });
                            }}
                        />
                        <span>%</span>
                    </div>
                    <div className={styles.security}>
                        <p>安全值：</p>
                        <div>
                            <span>平台销售额昨日环比正负达到</span>
                            <InputNumber
                                style={{ width: '100px', margin: '0 5px 0 10px' }}
                                min={0}
                                max={100}
                                placeholder='请输入'
                                value={securityNum}
                                precision={1}
                                step={0.1}
                                onChange={(e) => {
                                    this.setState({ securityNum: e });
                                }}
                            />
                            <span>%&nbsp;以上，统一按</span>
                            <InputNumber
                                style={{ width: '100px', margin: '5px 5px 5px 0' }}
                                min={0}
                                max={100}
                                placeholder='请输入'
                                value={securityNumR}
                                precision={1}
                                step={0.1}
                                onChange={(e) => {
                                    this.setState({ securityNumR: e });
                                }}
                            />
                            <span>%</span>
                        </div>
                        <p>未触发安全值的按昨日人气值兑换魅力值比率*(1+-销售额昨日环比涨跌)</p>
                    </div>

                    <div className={styles.radio}>
                        <div className={styles.label}>兑换结果取数</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._countedChangeHandle} value={counted}>
                            <Radio value={'ROUND_DOWN'}>向下取整</Radio>
                            <Radio value={'ROUND_HALF_UP'}>四舍五入</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.radio}>
                        <div className={styles.label}>默认项</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._defaultChangeHandle} value={defaultState}>
                            <Radio value={'1'}>启用</Radio>
                            <Radio value={'0'}>禁用</Radio>
                        </RadioGroup>
                    </div>
                </div>
            </Modal>
        );

    }
}

DurationRulesModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default DurationRulesModal;