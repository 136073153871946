import { createModel } from '@/store/tools';

const model = {
    namespace: 'payment',
    build: (T, A, S, R) => ({
        actiontypes: {  
            'getReport': T('getReport'),
            'getMerchantSelect': T('getMerchantSelect'),
        },
        actions: {
            'getReport': A('getReport'),
            'getMerchantSelect': A('getMerchantSelect'),
        },
        sagas: {
            'getReport': S('getReport', '/report/platPayTypeStatistics'),
            'getMerchantSelect': S('getMerchantSelect', '/merchant/info/listMerchantIdAndName'),
        },
        reducers: {
            'getReport': R('getReport', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getMerchantSelect': R('getMerchantSelect', { merchantIdAndNames: [] }),
        }
    })
};
export default createModel(model);