/**
 *  评论管理 - 敏感词
 * */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { takeColumns, takeDataSource } from './tableContents/TableData';
import EditModal from './modal/EditModal';
import { Table } from 'antd';
import { getSensitiveWordInfos } from '@/reducers/commentmanage/sensitiveWord/actions';

class SensitiveWord extends Component {
	constructor(props) {
		super(props);
		const that = this;
		this.state = {
			prefix: ''
		};

		this.file = {};
	}

	componentDidMount() {
		const prefix = `${process.env.HOST}`;

		// if (process.env.NODE_ENV === 'production') {
		// 	prefix = `${process.env.HOST}`;
		// } else {
		// 	prefix = 'http://47.99.184.52:10089';
		// }

		this.setState({ prefix });
		this._searchHandle();
		// this.file = {
		// 	name: 'file',
		// 	accept: '.txt',
		// 	action: `${prefix}/sysConfig/sensitiveWord/upload.txt`,
		// 	headers: {
		// 		authorization: 'authorization-text',
		// 		token: JSON.parse(getLocalStorage('auth')).token
		// 	},
		// 	onChange(info) {
		// 		if (info.file.status === 'done') {
		// 			if (info.file.response.code === 200) {
		// 				message.success('上传成功。');
		// 				that.props.getSensitiveWordInfo();
		// 			} else {
		// 				message.error(info.file.response.message);
		// 			}
		// 		} else if (info.file.status === 'error') {
		// 			message.error(`${info.file.name}文件上传失败！`);
		// 		}
		// 	},
		// };
	}
	_searchHandle = () => {
		this.props.getSensitiveWordInfos();
	}
    _actionClickHandle = (record, type) => {
		if (type === 'dowload') {
         this._downLoad(record);
		} else if (type === 'edit') {
			this.setState({
				currentOpenMode: 'edit',
				currentOpenId: record.id,
				openModeParam: {
					id: record.id,
					wordType: record.wordType,
					keyWord: record.keyWord,
				}
			});
		}
	}
	_downLoad = (record) => {
		const { prefix } = this.state;
		window.open(`${prefix}/sysConfig/sensitiveWord/download/${record.id}`);
	}
	// 打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'edit':
                return <EditModal title='编辑' refresh={this._searchHandle} visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

	render() {
		const { sensitiveWordInfos } = this.props;
		return (
			<div className={styles.flexBoxContainer}>
				<div className={styles.tableContainer} style={{ overflow: 'auto' }}>
					{/* <div className={styles.sensitiveItem}>
						<Upload {...this.file}>
							<Button>
								<Icon type="upload" />上传敏感词文件
							</Button>
						</Upload>
						<XOKButton
							style={{ marginLeft: '20px', width: '150px' }}
							label='下载敏感词文件'
							onClick={this._downLoad}
						/>
					</div> */}
					{/* <div className={styles.infoBox}>
						<div className={styles.infoTitle}>最近操作信息</div>
						<div className={styles.infoContent}>
							<p>
								<span className={styles.infoLabel}>操作人：</span>
								<span className={styles.infoText}>{sensitiveWordInfo.modifyUser}</span>
							</p>
							<p>
								<span className={styles.infoLabel}>操作时间：</span>
								<span className={styles.infoText}>{sensitiveWordInfo.gmtModified}</span>
							</p>
						</div>
					</div> */}
					<div>
						<div className={styles.title}>词库记录</div>
						<Table
							rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
							rowKey={(record, index) => index}
							columns={takeColumns(this._actionClickHandle)}
							dataSource={takeDataSource(sensitiveWordInfos)}
							pagination={false}
							style={{height: 'auto'}}
							scroll={{ y: true }}
						/>
						{/* <XPagination
							resultTotal={sensitiveWordList.resultTotal}
							pageSize={sensitiveWordList.pageSize}
							pageNum={sensitiveWordList.pageNum}
							onChange={this._paginationChange}
						/> */}
					</div>
					{/* <div className={styles.infoBox}>
						<div className={styles.infoTitle}>操作说明：</div>
						<div style={{ paddingLeft: '20px' }}>
							<div>1.上传格式为TXT，编码为utf-8</div>
							<div>2.文件中敏感词之间使用 # 间隔，举个栗子：蒙逼#傻逼#逗逼</div>
							<div>
								<div>
									3.敏感词前端使用位置：
								</div>
								<ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
									<li>商品评论</li>
									<li>秀场发布的标题和内容</li>
									<li>秀场评论</li>
									<li>意见反馈和举报</li>
								</ul>
							</div>
						</div>
					</div> */}
				</div>
				{this._renderMode()}
			</div >
		);
	}
}

SensitiveWord.propTypes = {
	history: PropTypes.object, // 路由history
	getSensitiveWordInfos: PropTypes.func,
	sensitiveWordInfos: PropTypes.array,
};

const mapStateToProps = (state) => {
	return {
		sensitiveWordInfos: state.sensitiveWord.sensitiveWordInfos // 表格数据源
	};
};

export default connect(mapStateToProps, { getSensitiveWordInfos })(SensitiveWord);