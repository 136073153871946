/**
 * 【分销系统】
 */
import React from 'react';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker, XInputNum } from '@/components/xqxc_ui';
import OrderTable from './components/OrderTable';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getProductOrderStatus, getPaymentAccessTypeEnum } from '@/reducers/ordermanage/productOrder/actions';
import model from '@/reducers/ordermanage/valueServiceOrder/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { message } from 'antd';

class Main extends React.Component {
    state = {
        pageNum: 1,
        pageSize: 20,
        userState: {},
        payType: {}, // 支付方式
        payAccessType: {}, // 支付通道
        stateItem: {},
        recommendType: {},
        refereesPhone: null,
        batchData: [],
    }
    // domRef = React.createRef();

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getPayType, getBusinessAnchorReferrerRoleEnum, getProductOrderStatus } = this.props;
        keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        getPayType();
        getBusinessAnchorReferrerRoleEnum();//推荐人类型
        // getOrderStateEnum();
        getProductOrderStatus();
        // 支付通道
        this.props.getPaymentAccessTypeEnum();
        // 支付方式
        // this.props.getPaymentTypeEnum();
    }

    // 表格操作
    _tableAction = (key, type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            // 查看详情
            case 'info':
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: {
                        pathname: '/home/ordermanage/valueserviceorder/distributionorder/orderdetail',
                        search: `?orderId=${record.orderId}&&buyerId=${record.buyerId}&&curTab=3`,
                    }
                });
                history.push({
                    pathname: '/home/ordermanage/valueserviceorder/distributionorder/orderdetail',
                    search: `?orderId=${record.orderId}&&buyerId=${record.buyerId}&&curTab=3`,
                });
                break;
            case 'batch':
                this.setState({ batchData: record });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }
    // 重置
    _resetHandle = () => {
        this.setState({ payAccessType: {}, payTimeF: undefined, payTimeT: undefined, accountTel: '', orderId: '', promoterAccountName: null, payType: {}, stateItem: {}, recommendType: {}, pageNum: 1, pageSize: 20 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    _searchHandle = (useCache) => {
        const { payTimeF, payTimeT, orderId, accountTel, payType, payAccessType, stateItem, promoterAccountName, recommendType, pageNum, pageSize } = this.state;
        this.searchCache = {
            payTimeF,
            payTimeT,
            orderId,
            accountTel,
            promoterAccountName,
            statusList: stateItem.code ? [stateItem.code] : undefined,
            payType: payType.code,
            payAccessType: payAccessType.code,
            promoterSource: recommendType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getSalesManOrderListPage(this.searchCache);
    }

    //导出操作
    _onExport = () => {
        const { payTimeF, payTimeT, orderId, accountTel, payType, payAccessType, stateItem, promoterAccountName, batchData, recommendType } = this.state;
        const reqParams = {
            payTimeF,
            payTimeT,
            orderId,
            accountTel,
            promoterAccountName,
            statusList: stateItem.code ? [stateItem.code] : null,
            payType: payType.code,
            payAccessType: payAccessType.code,
            promoterSource: recommendType.code,
            orderIdList: batchData
        };
        http('/boss/order/getSalesManOrderExport', { ...reqParams }, 'POST')
            .then((res) => {
                if (res.code === 200) {
                    window.open(res.result);
                }
            })
            .catch(e => {
                message.error(e.message);
            });
    }
    render() {
        const { payTypeArray, SalesManOrderListPage, productOrderStatus, BusinessAnchorReferrerRoleEnum, paymentAccessTypeEnum } = this.props;
        const { payTimeF, payTimeT, orderId, accountTel, payType, payAccessType, stateItem, promoterAccountName, recommendType } = this.state;
        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='开通帐号'
                                placeholder='请输入开通帐号'
                                value={accountTel}
                                bindThis={this}
                                bindName='accountTel'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInputNum
                                label='订单编号'
                                placeholder='请输入订单编号'
                                value={orderId}
                                bindThis={this}
                                bindName='orderId'
                                min={0}
                                precision={0}
                                maxLength={16}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='订单状态'
                                placeholder='请选择'
                                renderData={productOrderStatus}
                                dataIndex='value'
                                keyIndex='code'
                                value={stateItem.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='stateItem'
                            />
                        </SearchItem>
                        {/* <SearchItem>
                                <XSelect
                                    label='用户类型'
                                    placeholder='请选择用户类型'
                                    renderData={userTypeArray || []}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={userState.value}
                                    showSearch={true}
                                    bindThis={this}
                                    bindName='userState'
                                />
                            </SearchItem> */}
                        <SearchItem>
                            <XSelect
                                label='支付通道'
                                placeholder='请选择支付通道'
                                renderData={paymentAccessTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={payAccessType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='payAccessType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='支付方式'
                                placeholder='请选择支付方式'
                                renderData={payTypeArray}
                                dataIndex='value'
                                keyIndex='code'
                                value={payType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='payType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='推广人账号'
                                placeholder='请输入推广人账号'
                                value={promoterAccountName}
                                bindThis={this}
                                bindName='promoterAccountName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='推荐人类型'
                                placeholder='请选择推荐人类型'
                                renderData={BusinessAnchorReferrerRoleEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={recommendType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='recommendType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='付款时间'
                                value={payTimeF}
                                bindThis={this}
                                bindName='payTimeF'
                                isFormat
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <div className={styles.timeLine} />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                label=''
                                value={payTimeT}
                                bindThis={this}
                                bindName='payTimeT'
                                isFormat
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    {/* <ButtonPerssion code='ordermanage/valueserviceorder/dc'> */}
                            <div className={styles.row3}>
                                <XOKButton
                                    style={{ width: '80px' }}
                                    label='导出'
                                    onClick={this._onExport}
                                />
                            </div>
                        {/* </ButtonPerssion> */}
                    <OrderTable renderData={SalesManOrderListPage} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getPayType: PropTypes.func,
    getUserType: PropTypes.func,
    payTypeArray: PropTypes.array,
    userTypeArray: PropTypes.array,
    getSalesManOrderListPage: PropTypes.func,
    SalesManOrderListPage: PropTypes.object,
    getOrderStateEnum: PropTypes.func,
    orderStateEnum: PropTypes.array,
    getRecommendTypeEnum: PropTypes.func,
    RecommendTypeEnum: PropTypes.array,
    getBusinessAnchorReferrerRoleEnum: PropTypes.func,
    BusinessAnchorReferrerRoleEnum: PropTypes.array,
    getProductOrderStatus: PropTypes.func,
    productOrderStatus: PropTypes.array,
    getPaymentAccessTypeEnum: PropTypes.func,
    paymentAccessTypeEnum: PropTypes.array,
};

Main.default = {
    orderStateEnum: []
};

const mapStateToProps = (state) => ({
    productOrderStatus: state.productOrder.productOrderStatus,
    userTypeArray: state.valueServiceOrder.userTypeArray,
    payTypeArray: state.valueServiceOrder.payTypeArray,
    SalesManOrderListPage: state.valueServiceOrder.SalesManOrderListPage,
    orderStateEnum: state.valueServiceOrder.orderStateEnum,
    RecommendTypeEnum: state.valueServiceOrder.RecommendTypeEnum,
    BusinessAnchorReferrerRoleEnum: state.valueServiceOrder.BusinessAnchorReferrerRoleEnum,
    paymentAccessTypeEnum: state.productOrder.paymentAccessTypeEnum,
});

export default connect(mapStateToProps, { ...model.actions, getProductOrderStatus, getPaymentAccessTypeEnum, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);
