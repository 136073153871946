/**
 * 【用户管理页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import model from '@/reducers/usermanage/member/model';
import TableContent from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { isPlat, isOC } from '@/assets/js/authType';
import { getLocalStorage } from '@/assets/js/storage';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            curMode: '', // 当前弹框类型
            modeParam: [], // 弹框传参
            selectedRowKeys: [], // 表格复选key
            selectedRows: [], // 表格复选对象
            telephone: '', // 会员账号
            nickname: '', // 昵称
            level: {}, // 用户等级
            province: {}, // 省份
            city: {}, // 城市
            street: {}, // 区域
            source: {}, // 用户来源
            OCSelect: {}, // 归属运营中心
            RESelect: {}, // 归属区域公司
            cardNum: '', // 身份证号
        };
    }

    componentDidMount() {
        const { getAccountLevel, getListAreaByParentId, getUserSource, getOperationCenter } = this.props;
        getAccountLevel();
        getUserSource();
        getListAreaByParentId({ id: 100000, type: 'province' });
        getOperationCenter();

        // 当为运营中心时，初始化归属区域公司的下拉列
        if (isOC()) {
            const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
            if (userInfo != null) {
                this.props.getAreaCompany({ operationsId: userInfo.companyId });
            }
        }
    }

    // 省份输入
    _provinceChangeHandle = (selectedItem) => {
        this.setState({ province: selectedItem, city: {}, street: {} });
        this.props.getListAreaByParentId({ id: selectedItem.id, type: 'city' });
        this.props.cleanStreet();
    }

    // 城市输入
    _cityChangeHandle = (selectedItem) => {
        this.setState({ city: selectedItem, street: {} });
        this.props.getListAreaByParentId({ id: selectedItem.id, type: 'street' });
    }

    // 区域输入 
    _streetChangeHandle = (selectedItem) => {
        this.setState({ street: selectedItem });
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { telephone, nickname, cardNum, level, province, city, street, source, pageNum, pageSize, OCSelect, RESelect } = this.state;
        this.searchCache = {
            tels: telephone == '' ? [] : [telephone],
            nicknames: nickname == '' ? [] : [nickname],
            cardNum: cardNum,
            accountLevelId: level.id,
            provinceId: province.id,
            cityId: city.id,
            areaId: street.id,
            source: source.code,
            operationsCenterId: OCSelect.id,
            areaCompanyId: RESelect.id,
            isAnchor: 0,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAccountList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ telephone: '', nickname: '', cardNum: '', level: {}, province: {}, city: {}, street: {}, selectedRows: [], selectedRowKeys: [], source: {}, OCSelect: {}, RESelect: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 每页pageSize条，当前第pageNum页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, isAnchor: 0, pageSize, pageNum };
        this.props.getAccountList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 渲染运营中心下拉列
    _renderOCSelect = () => {
        if (isPlat()) {
            return (
                <XSelect
                    label='归属运营中心'
                    placeholder='请选择运营中心'
                    renderData={this.props.OCSelectList}
                    dataIndex='companyName'
                    keyIndex='id'
                    value={this.state.OCSelect.companyName}
                    onChange={this._OCSelectChangeHandle}
                    bindThis={this}
                    bindName='OCSelect'
                />
            );
        } else {
            return null;
        }
    }

    // 运营中心发生变化
    _OCSelectChangeHandle = (ocItem) => {
        this.props.getAreaCompany({ operationsId: ocItem.id });
    }

    // 渲染区域公司下拉列
    _renderRESelect = () => {
        if (isPlat() || isOC()) {
            return (
                <XSelect
                    label='归属区域公司'
                    placeholder='请选择区域公司'
                    renderData={this.props.RESelectList}
                    dataIndex='companyName'
                    keyIndex='id'
                    value={this.state.RESelect.companyName}
                    bindThis={this}
                    bindName='RESelect'
                />
            );
        } else {
            return null;
        }
    }

    //表格操作
    _tableAction = (id, type) => {
        switch (type) {
            case 'MEMBER_INFO':
                this.props.updateContent(id, 'MEMBER_INFO');
                break;
        }
    }

    render() {
        const { telephone, nickname, cardNum, level, source } = this.state;
        const { accountLevel, accountList, address, userSource } = this.props;
        const { province, city, street } = address;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='会员账号'
                                placeholder='请输入会员账号'
                                value={telephone}
                                bindThis={this}
                                bindName='telephone'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='用户昵称'
                                placeholder='请输入用户昵称'
                                value={nickname}
                                bindThis={this}
                                bindName='nickname'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='用户等级'
                                placeholder='请选择用户等级'
                                renderData={accountLevel}
                                dataIndex='levelName'
                                keyIndex='id'
                                value={level.levelName}
                                bindThis={this}
                                bindName='level'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='来源'
                                placeholder='请选择用户来源'
                                renderData={userSource}
                                dataIndex='value'
                                keyIndex='code'
                                value={source.value}
                                bindThis={this}
                                bindName='source'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='身份证号'
                                placeholder='请输入身份证号'
                                value={cardNum}
                                bindThis={this}
                                bindName='cardNum'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                style={{ marginRight: '0px' }}
                                label='归属区域'
                                placeholder='省份'
                                renderData={province}
                                onChange={this._provinceChangeHandle}
                                dataIndex='areaName'
                                keyIndex='id'
                                showSearch={true}
                                value={this.state.province.areaName}
                            />
                            <XSelect
                                style={{ marginRight: '0px' }}
                                placeholder='城市'
                                renderData={city}
                                onChange={this._cityChangeHandle}
                                dataIndex='areaName'
                                keyIndex='id'
                                showSearch={true}
                                value={this.state.city.areaName}
                            />
                            <XSelect
                                placeholder='区域'
                                renderData={street}
                                onChange={this._streetChangeHandle}
                                dataIndex='areaName'
                                keyIndex='id'
                                showSearch={true}
                                value={this.state.street.areaName}
                            />
                        </SearchItem>
                        <SearchItem>
                            {this._renderOCSelect()}
                        </SearchItem>
                        <SearchItem>
                            {this._renderRESelect()}
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent
                        renderData={accountList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                        rowSelection={(selectedRowKeys, selectedRows) => {
                            this.setState({ selectedRows, selectedRowKeys });
                        }}
                    />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    getAccountLevel: PropTypes.func, // 请求用户等级
    getAccountList: PropTypes.func, // 请求用户列表
    getListAreaByParentId: PropTypes.func, // 请求地区信息
    accountLevel: PropTypes.array, // 用户等级
    accountList: PropTypes.object, // 用户列表
    address: PropTypes.object, // 省市区
    getUserSource: PropTypes.func, // 获取用户来源
    userSource: PropTypes.array, // 用户来源
    getOperationCenter: PropTypes.func, // 获取归属运营中心
    OCSelectList: PropTypes.array, // 归属运营中心下拉列
    getAreaCompany: PropTypes.func, // 获取归属区域公司
    RESelectList: PropTypes.array, // 归属区域公司下拉列
    cleanStreet: PropTypes.func, // 清理区域搜索结果
};

const mapStateToProps = (state) => ({
    userSource: state.member.getUserSource,
    accountLevel: state.member.accountLevel,
    accountList: state.member.accountList,
    address: state.member.address,
    OCSelectList: state.member.getOperationCenter,
    RESelectList: state.member.getAreaCompany
});

export default connect(mapStateToProps, { ...model.actions })(Main);