import { combineReducers } from 'redux';
import {
    messageClassifyList,
    messageClassifyDetail,
    messageClassifyLists,
    messagePushPort
} from './reducer';

export default combineReducers({
    messageClassifyList,
    messageClassifyDetail,
    messageClassifyLists,
    messagePushPort,
});