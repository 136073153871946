/**
 * 审核管理 - 视频审核列表异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Audit from './actiontypes';

export const getPornographyList = function* (){
    yield takeEvery(Audit.GET_PORNOGRAPHY_LIST, function* requestData(action){
        try{
            let result = yield http('/show/law/list', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Audit.SET_PORNOGRAPHY_LIST, payload: result.result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

export const getPornographyType= function* (){
    yield takeEvery(Audit.GET_PORNOGRAPHY_TYPE, function* requestData(action){
        try{
            let result = yield http('/show/reporttype/listReportTypes', action.payload, 'POST');
            yield put({type:Audit.SET_PORNOGRAPHY_TYPE, payload: result.result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};
