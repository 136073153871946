/**
 * 商品状态下拉搜索框（枚举类型）
 */
import React from 'react';
import PropTypes from 'prop-types';
import { XTagsSelect } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

class GoodsTags extends React.Component {

    state = {
        renderData: [], // 数据源
    }

    componentDidMount() {
        http('/goods/tag/listTagItem', { pageNum: 1, pageSize: 10000 }, 'POST').then((response) => {
            if (response.status == 200) {
                this.setState({ renderData: response.result.dataList });
            } else {
                console.log('/goods/tag/listTagItem请求失败');
            }
        }).catch(() => {
            console.log('/goods/tag/listTagItem请求失败');
        });
    }

    _onChange = (value) => {
        const { bindThis, bindName } = this.props;

        if (bindThis && bindName) {
            bindThis.setState({ [bindName]: value });
        }

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const { renderData } = this.state;
        const { style, selectStyle, label, placeholder, isRequired, value } = this.props;

        return (
            <XTagsSelect
                style={style}
                selectStyle={selectStyle}
                label={label}
                placeholder={placeholder}
                renderData={renderData}
                value={value}
                dataIndex='tagName'
                keyIndex='id'
                onChange={this._onChange}
                isRequired={isRequired}
            />
        );
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }
}

GoodsTags.defaultProps = {
    style: { width: '218px' },
    selectStyle: { width: '150px' },
};

GoodsTags.propTypes = {
    bindThis: PropTypes.object, // 父级this
    bindName: PropTypes.string, // 双向绑定的属性名称
    value: PropTypes.array,
    style: PropTypes.object,
    selectStyle: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    defaultValue: PropTypes.object,
};

export default GoodsTags;