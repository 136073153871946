/**
 * 主播封面审核页
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import TableMain from './conponents/TableMain';
import AuditModal from '../../modal/AuditModal';
import DetailModal from '../../modal/DetailModal';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getAnchorCoverList, getAnchorCoverSelect } from '@/reducers/auditmanage/anchorCover/actions';
import { SearchBox, SearchItem } from '@/components/Layout';

const Mode = {
	AUDIT_MODAL: 'AUDIT_MODAL',
	DETAIL_MODAL: 'DETAIL_MODAL',
};

class Main extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tel: null,
			nickName: null,
			selectItem: {},
			selectDataSource: [], // 下拉列数据源
			modifyUserId: '',
			currentOpenMode: '',
			audit: {}
		};
	}

	componentDidMount() {
		this.props.getAnchorCoverSelect();
	}


	// 查询触发
	_searchHandle = (useCache) => {
		const { tel, nickName, selectItem, pageSize, pageNum } = this.state;
		this.searchCache = {
			tel, nickName, state: selectItem.code,
			pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
			pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
		};
		this.props.getAnchorCoverList(this.searchCache);
		KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
	}

	// 重置触发
	_resetHandle = () => {
		this.setState({ tel: null, nickName: null, selectItem: {}, state: null }, () => {
			KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
		});
	}

	// 页面发生变化
	_paginationChange = (pageSize, pageNum) => {
		this.setState({ pageSize, pageNum }, () => {
			this._searchHandle('useCache');
		});
	}

	_tableAction = (id, record, type) => {
		switch (type) {
			case 'audit':
				this.setState({ currentOpenMode: Mode.AUDIT_MODAL, audit: record });
				break;
			case 'detail':
				this.setState({ currentOpenMode: Mode.DETAIL_MODAL, audit: record });
				break;
		}
	}

	_renderMode = () => {
		switch (this.state.currentOpenMode) {
			case Mode.AUDIT_MODAL:
				return <AuditModal visible={true} audit={this.state.audit} closeMode={this._closeMode} />;
			case Mode.DETAIL_MODAL:
				return <DetailModal visible={true} audit={this.state.audit} closeMode={this._closeMode} />;
			default:
				return null;
		}
	}

	// 关闭弹窗
	_closeMode = () => {
		this.setState({ currentOpenMode: '' }, () => {
			this._searchHandle('useCache');
		});
	}

	render() {
		const { tel, nickName } = this.state;
		return (
			<KeepAlive id='1' render={(state) => {
				if (!this.state.__aliveInit) {
					this.setState({ ...state, __aliveInit: true }, () => {
						this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
					});
				}
			}}>
				<div className={styles.flexBoxContainer}>
					<SearchBox>
						<SearchItem>
							<XInput
								label='手机号码'
								placeholder='请输入手机号码'
								bindThis={this}
								bindName='tel'
								value={tel}
							/>
						</SearchItem>
						<SearchItem>
							<XInput
								label='主播昵称'
								placeholder='请输入主播昵称'
								bindThis={this}
								bindName='nickName'
								value={nickName}
							/>
						</SearchItem>
						<SearchItem>
							<XSelect
								label='审核状态'
								placeholder='请选择审核状态'
								renderData={this.props.selectDataSource}
								bindThis={this}
								bindName='selectItem'
								dataIndex='value'
								keyIndex='code'
								value={this.state.selectItem.value}
								showSearch={true}
							/>
						</SearchItem>
						<SearchItem>
							<XOKButton
								label='查询'
								onClick={this._searchHandle}
							/>
							<XCancelButton
								label='重置'
								onClick={this._resetHandle}
							/>
						</SearchItem>
					</SearchBox>
					<TableMain paginationChange={this._paginationChange} renderData={this.props.anchorCover} tableAction={this._tableAction} />
					{this._renderMode()}
				</div>
			</KeepAlive>
		);
	}
}

Main.propTypes = {
	getAnchorCoverList: PropTypes.func, // 通过Saga异步请求表格数据源的Action
	getAnchorCoverSelect: PropTypes.func, // 通过Saga异步请求表格数据源的Action
	anchorCover: PropTypes.object,
	selectDataSource: PropTypes.array
};

const mapStateToProps = (state) => ({
	anchorCover: state.anchorCover.anchorCoverList,
	selectDataSource: state.anchorCover.anchorCoverSelect
});

export default connect(mapStateToProps, { getAnchorCoverList, getAnchorCoverSelect })(Main);