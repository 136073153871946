import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Switch, Icon, Input } from 'antd';
import { XPagination, XMediaFn, } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const columns = (TableAction, actionClickHandle) => [
    {
        title: '分类ID',
        dataIndex: 'merchantKey',
        key: 'merchantKey',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '分类名称',
        dataIndex: 'className',
        key: 'className ',
        width: '20%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '分类图标',
        dataIndex: 'img',
        key: 'img',
        align: 'center',
        width: '10%',
        render: (text, item) => (ImgCell(text, item))
    },
    {
        title: '排序',
        dataIndex: 'sortNo',
        key: 'sortNo',
        width: '10%',
        align: 'center',
        render: (text, item) => (SortCell(text, item, actionClickHandle))
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle))
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '10%',
        render: (id, record) => TableAction(id, record, actionClickHandle)
    }
];

const TableAction = (id, record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'edit', record)}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'delete', record)}>删除</div>
        </div>
    );
};

// 表格项内容为图片
const ImgCell = (url, item) => {
    return (
        <div style={{ display: 'inline-flex' }}>
            <XMediaFn
                style={{ width: '50px', height: '50px', marginRight: '0' }}
                dataType='image'
                dataSource={url}
                key={item.id}
            />
        </div>
    );
};

// 表格项内容为可编辑
const SortCell = (value, item, actionClickHandle) => {
    const label = React.createRef();
    const box = React.createRef();
    const input = React.createRef();

    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <div className={styles.sort_box}>
                <div className={styles.label} ref={label}>{value}</div>
                <div className={styles.input} ref={box}>
                    <Input
                        key={item.id}
                        style={{ width: '50px' }}
                        ref={input}
                        defaultValue={value}
                        onBlur={(e) => {
                            box.current.style.display = 'none';
                            label.current.style.display = 'block';
                            if (value != e.target.value) {
                                actionClickHandle(item, 'sort', e.target.value);
                            }
                        }}
                    />
                </div>
                <div
                    className={styles.icon}
                    onClick={() => {
                        label.current.style.display = 'none';
                        box.current.style.display = 'block';
                        input.current.focus();
                    }}
                >
                    <Icon type="edit" style={{ fontSize: '18px' }} />
                </div>
            </div>
        </div>
    );
};

// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
    let checked = true;
    if (sw == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={(value) => {
                    actionClickHandle(item, 'switch', value);
                }}
            />
        </div>
    );
};

class TypeTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data && data.map((item) => {
            item.merchantKey = item.id;//操作key
            return item;
        });
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { renderData, pagiNation, _paginationChange } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => record.id}
                    columns={columns(TableAction, this._actionHandle)}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={pagiNation.resultTotal}
                    pageSize={pagiNation.pageSize}
                    pageNum={pagiNation.pageNum}
                    onChange={_paginationChange}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    pagiNation: PropTypes.object, // 分页数据
    _paginationChange: PropTypes.func,// 页面发生变化
};

export default TypeTable;