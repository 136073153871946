import React from 'react';
import styles from './AdvertTable.module.scss';
import { tooltipStyle, tooltip, priceFenToYuanCell, cellWidth } from '@/components/TableCell';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table, Alert } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';

class AdvertTable extends React.Component {
    state = {
        account: '',
        selectedRowKeys: [],
    }

    _takeColumns = () => ([
        {
            title: '提现单号',
            dataIndex: 'id',
            key: 'id',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户名称',
            dataIndex: 'subjectName',
            key: 'subjectName',
            width: 200,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '提现金额（元）',
            dataIndex: 'amount',
            key: 'amount',
            width: cellWidth.small,
            align: 'left',
            render: priceFenToYuanCell
        },
        {
            title: '手续费（元）',
            dataIndex: 'fee',
            key: 'fee',
            width: cellWidth.small,
            align: 'left',
            render: priceFenToYuanCell
        },
        {
            title: '银行卡',
            dataIndex: 'bankCardNo',
            key: 'bankCardNo',
            width: cellWidth.time,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '提现通道',
            dataIndex: 'accessType.value',
            key: 'accessType.value',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'statusName',
            key: 'statusName',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '提现时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.time,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            fixed: 'right',
            render: (id, item) => this._renderActionCell(item)
        },
    ]);

    _renderActionCell = (item) => {
        const { tableAction } = this.props;
        return (<div className={styles.action}>
            <div className={styles.item} onClick={() => tableAction(item.id, 'withdrawallist', item)}>订单明细</div>
            {item.accessType.code === 'THIRD_UNION_PAY' && item.status === 'FAIL' && <div className={styles.item} onClick={() => tableAction(item.id, 'withdrawal', item)}>确认打款</div>}
        </div>
        );
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Alert message="商户配置手动提现权限后，可以在商户后台操作手动提现" banner style={{ marginBottom: 10 }} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    scrollY: PropTypes.number,
    settlementStatus: PropTypes.array,
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default XTableHOC(AdvertTable, 40);