/**
 * 客服列表
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { message, } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import AdvertTable from './components/AdvertTable';
import { getMerchantIdAndName, getCustomerList } from '@/reducers/customermanage/customerList/actions';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {

    state = {
        merchantId: {},//商户
        pageNum: 1,
        pageSize: 10,
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getMerchantIdAndName();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, merchantId } = this.state;
        this.searchCache = {
            merchantId: merchantId.merchantId,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getCustomerList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            merchantId: {},
            pageNum: 1,
            pageSize: 10,
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建用户
    _newHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建客服列表',
            path: '/home/customermanage/customerList/add'
        });
        history.push({
            pathname: '/home/customermanage/customerList/add'
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const { merchantId } = this.state;
        this.searchCache = {
            merchantId: merchantId.merchantId,
            pageSize,
            pageNum
        };
        this.props.getCustomerList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 状态转换
    _companyStatusChange = e => {
        this.setState({
            merchantId: e,
        });
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        console.log(id, type, value);
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'edit':
                addOneBreadcrumbPath({
                    title: '编辑客服列表',
                    path: `/home/customermanage/customerList/edit/${id}`
                });
                history.push({
                    pathname: `/home/customermanage/customerList/edit/${id}`
                });
                break;
            case 'del':
                showConfirm('是否确定删除？', '', () => {
                    http('/admin/customer/deleteByPrimaryKey', { id }, 'POST')
                        .then(() => {
                            message.success('删除成功!');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
        }
    }

    render() {
        const { merchantId } = this.state;
        const { customerList = {}, merchantIdAndName: { merchantIdAndNames = [] } } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XSelect
                                label='商户名称'
                                placeholder='请选择'
                                renderData={merchantIdAndNames || []}
                                onChange={this._companyStatusChange}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                showSearch={true}
                                value={merchantId.merchantName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.row2}>
                        <XOKButton
                            style={{ width: '90px' }}
                            label='新建'
                            onClick={this._newHandle}
                        />
                    </div>
                    <AdvertTable renderData={customerList} tableAction={this._tableAction} paginationChange={this._paginationChange}
                    />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    paginations: PropTypes.object, // 分页数据
    getCustomerList: PropTypes.func,
    getMerchantIdAndName: PropTypes.func,
    customerList: PropTypes.object,
    merchantIdAndName: PropTypes.object,
};
const mapStateToProps = (state) => ({
    customerList: state.CustomerList.customerList,
    merchantIdAndName: state.CustomerList.merchantIdAndName,
});

export default connect(mapStateToProps, { getMerchantIdAndName, getCustomerList, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);
