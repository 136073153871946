/**
 * 直播间分类
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import styles from './LVCStyle.module.scss';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import modal from '@/reducers/knowledgepay/channelclassify/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import CreateClassify from './modal/CreateClassify';
import EditClassify from './modal/EditClassify';
import { SearchBox, SearchItem } from '@/components/Layout';

class LiveClassify extends React.Component {

    state = {
        states: {}, // 分类状态
        className: '', // 分类名称
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: '' // 当前需要打开的弹窗的入参
    }

    // 创建直播间分类
    _createStudioRoom = () => {
        this.setState({ currentOpenMode: 'create' });
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { states, className, pageNum, pageSize } = this.state;
        this.searchCache = {
            className, state: states.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getKLClassifyList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ states: {}, className: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    //发送请求
    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST')
            .then(() => {
                this._searchHandle('useCache');
                message.success(winTxt);
            }).catch((reject) => {
                message.error(reject.message);
            });
    }

    // 表格操作
    _tableAction = (id, activeType, params) => {
        switch (activeType) {
            case 'edit':
                this.setState({ currentOpenMode: 'edit', openModeParam: id });
                break;
            case 'switch':
                this._sendReq('/show/showClass/updateShowClassState', { id, state: params.state }, '分类状态改变成功。');
                break;
            case 'delete':
                showConfirm('您确定删除当前数据？', '', () => {
                    this._sendReq('/show/showClass/deleteChargeLiveClass/' + id, {}, '删除分类成功');
                });
                break;
            case 'sort':
                this._sendReq('/show/showClass/updateShowClassSortNo', { id, sortNo: params.sortNo, classTypeCode: params.classTypeCode }, '排序操作成功。');
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { currentOpenMode, openModeParam } = this.state;
        switch (currentOpenMode) {
            case 'create':
                return <CreateClassify title='创建分类' visible={true}
                    url='/show/showClass/createChargeLiveBoradcasClass'
                    closeMode={this._closeMode} />;
            case 'edit':
                return <EditClassify title='编辑分类' visible={true}
                    openModeParam={openModeParam}
                    closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' }, () => {
            update && this._searchHandle('useCache');
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { states, className } = this.state;
        const { kLClassifyList, classifyState } = this.props;

        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div style={{ marginTop: '10px' }}>
                        <SearchBox>
                            <SearchItem>
                                <XSelect
                                    label='分类状态'
                                    placeholder='请选择分类状态'
                                    renderData={classifyState}
                                    bindName='states'
                                    bindThis={this}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={states.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='分类名称'
                                    placeholder='请输入分类名称'
                                    bindName='className'
                                    bindThis={this}
                                    value={className}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '150px' }} label='创建直播间分类' onClick={this._createStudioRoom} />
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={kLClassifyList} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

LiveClassify.propTypes = {
    getKLClassifyList: PropTypes.func,//分类列表
    kLClassifyList: PropTypes.object,//列表数据
    classifyState: PropTypes.array//下拉数据
};

const mapStateToProps = (state) => ({
    kLClassifyList: state.kclassify.kLClassifyList,
});

export default connect(mapStateToProps, { ...modal.actions })(LiveClassify);