export const GET_ATMOSPG_DATA = 'ATMOSP/GET_ATMOSPG_DATA'; // 氛围管理-氛围分类'数据源
export const SET_ATMOSPG_DATA = 'ATMOSP/SET_ATMOSPG_DATA'; // 氛围管理-氛围分类列表'存储至store.state中

export const GET_GIFTSTATE_DATA = 'ATMOSP/GET_GIFTSTATE_DATA'; // 氛围管理-氛围分类状态'数据源
export const SET_GIFTSTATE_DATA = 'ATMOSP/SET_GIFTSTATE_DATA'; // 氛围管理-氛围分类状态'存储至store.state中

export const GET_ATMOBUY_DATA = 'ATMOSP/GET_ATMOBUY_DATA'; // 氛围管理-购买记录'数据源
export const SET_ATMOBUY_DATA = 'ATMOSP/SET_ATMOBUY_DATA'; // 氛围管理-购买记录'存储至store.state中

//getatmosphereSource 氛围管理-氛围分类  getatmoslistSource  getatmospherebuySource
 


export const GET_MOSLIST_DATA = 'ATMOSP/GET_MOSLIST_DATA'; // 氛围管理-氛围列表'数据源
export const SET_MOSLIST_DATA = 'ATMOSP/SET_MOSLIST_DATA'; // 氛围管理-氛围列表'存储至store.state中