/**
 * 礼物管理删除
 */
import React from 'react';
import { Modal, Radio, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './DeleteModal.module.scss';
import http from '@/assets/api/http';

class DeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
        };
    }
    _handleOk = () => {
        const { id } = this.props.data;
        http('/user/level/updateAccountLevelState', { levelId: id, levelType: 'CHARM_LEVEL' }, 'POST').then(() => {
            message.success('禁用成功。');
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch((error) => {
            message.error(error.message);
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    render() {
        const { visible, confirmLoading, title, } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <h4>本操作涉及系统敏感操作，您确定是否禁用此会员卡等级？</h4>
                    <h5 style={{ color: '#ff0000', margin: '20px' }}>（当前操作会影响到用户会员卡等级显示无法恢复，请慎重使用）</h5>
                </div>
            </Modal>
        );

    }
}

DeleteModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default DeleteModal;