import { createModel } from '@/store/tools';

const model = {
    namespace: 'merchantreconciliation',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getHotelReconciliationList': T('getHotelReconciliationList'),
            'getHotelReconciliationDetail': T('getHotelReconciliationDetail'),
        },
        actions: {
            'getHotelReconciliationList': A('getHotelReconciliationList'),
            'getHotelReconciliationDetail': A('getHotelReconciliationDetail'),
        },
        sagas: {
            'getHotelReconciliationList': S('getHotelReconciliationList', '/settle/reconciliationInfo/queryHotelMerchantReconciliations'),
            'getHotelReconciliationDetail': S('getHotelReconciliationDetail', '/settle/reconciliationInfo/getHotelReconciliation'),
        },
        reducers: {
            'hotelReconciliationList': R('getHotelReconciliationList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'hotelReconciliationDetail': R('getHotelReconciliationDetail', {}),
        }
    })
};
export default createModel(model);