import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table, Tooltip } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class TableContent extends Component {

    _columns = () => [
        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId',
            align: 'left',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'left',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '合同号',
            dataIndex: 'ctrId',
            key: 'ctrId',
            align: 'left',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '供应商',
            dataIndex: 'supplierName',
            key: 'supplierName',
            align: 'left',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员账号',
            dataIndex: 'accountId',
            key: 'accountId',
            align: 'left',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员昵称',
            dataIndex: 'accountName',
            key: 'accountName',
            align: 'left',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '购买时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'left',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '兑换方式',
            dataIndex: 'convertMode.value',
            key: 'convertMode.value',
            align: 'left',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单状态',
            dataIndex: 'orderStatus.value',
            key: 'orderStatus.value',
            align: 'left',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'left',
            width: '13%',
            render: (key, item) => this._TableAction(key, item)
        },
    ];

    _TableAction = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'detail', item)}>查看详情</div>
            </div>
        );
    };

    //处理表格数据
    _processingData = (data = []) => {
        return data;
    }

    render() {
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey='id'
                    columns={this._columns()}
                    pagination={false}
                    dataSource={this._processingData(dataList)}
                    scroll={{ y: true }} />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={this.props.paginationChange} />
            </div>
        );
    }
}

TableContent.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
};

export default TableContent;