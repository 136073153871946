/**
 * 营销活动审核设置
 */
import React from 'react';
import PropTypes from 'prop-types';
import Marketing from '@/views/systemmanage/marketing';

class MarketingSetting extends React.Component {

    render() {
        const { history, match } = this.props;
        return (
            <Marketing history={history} match={match} />
        );
    }
}

MarketingSetting.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
};

export default MarketingSetting;