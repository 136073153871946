import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class TypeTable extends React.Component {

    _columns = () => [
        {
            title: '任务标题',
            dataIndex: 'taskTitle',
            key: 'taskTitle',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
			render: tooltip
        },
        {
            title: '任务分类',
            dataIndex: 'taskCategoryTitle',
            key: 'taskCategoryTitle ',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
			render: tooltip
        },
        {
            title: '任务类型',
            dataIndex: 'taskType',
            key: 'taskType',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
			render: tooltip
        },
        {
            title: '定向事件',
            dataIndex: 'eventCount',
            key: 'eventCount',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
			render: tooltip
        },
        {
            title: '推送平台',
            dataIndex: 'pushPlat',
            key: 'pushPlat',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
			render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
			render: tooltip
        },
        {
            title: '开始时间',
            dataIndex: 'startTime',
            key: 'startTime',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
			render: tooltip
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
            key: 'endTime',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
			render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '10%',
            render: (key, record) => this._tableAction(key, record)
        }
    ];

    _tableAction = (key, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
                {
                    record.state === '已禁用' ?
                        <div className={styles.item} onClick={() => tableAction(key, 'use', record)}>启用</div> :
                        <div className={styles.item} onClick={() => tableAction(key, 'ban', record)}>禁用</div>
                }
            </div>
        );
    };

    _takeDataSource = (data) => {
        return data;
    }

    render() {
        const { renderData } = this.props;

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={record => record.id}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(renderData.dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={this.props.paginationChange}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

export default TypeTable;