import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const getMarketingCampaign = function* () {
    yield takeEvery(T.GET_MARKETINGCAMPAIGN_List, function* requestData(action) {
        try {
            let result = yield http('/merchantAuditSetting/getMerchantAuditSettingPageList', action.payload, 'POST');
            yield put({ type: T.SET_MARKETINGCAMPAIGN_List, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getMarketingCampaignUser = function* () {
    yield takeEvery(T.GET_MARKETINGCAMPAIGN_USERList, function* requestData(action) {
        try {
            let result = yield http('/merchant/info/listMerchant', action.payload, 'POST');
            yield put({ type: T.SET_MARKETINGCAMPAIGN_USERList, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getWalletMerchantList = function* () {
    yield takeEvery(T.GET_WALLET_MERCHANTLIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/settleManualSetting/getAll', action.payload, 'POST');
            yield put({ type: T.SET_WALLET_MERCHANTLIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
