import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table, message } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { ImgCell } from '@/components/TableCell';

class TableData extends Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
    };

    _takeColumns = () => ([
        {
            title: '分类名称',
            dataIndex: 'categoryName',
            key: 'categoryName',
            width: '35%',
            align: 'center',
            render: (text, item) => (this._renderInputCell(text, item)),
        },
        {
            title: '分类图片',
            dataIndex: 'imgUrl',
            key: 'imgUrl',
            width: '15%',
            align: 'center',
            render: ImgCell
        },
        {
            title: '技术服务费率(%)',
            dataIndex: 'platRate',
            key: 'platRate',
            width: '15%',
            align: 'center',
            render: (text, item) => (this._renderRateCell(text, item, 'platRate'))
        },
        {
            title: '平台服务费率(%)',
            dataIndex: 'agentRate',
            key: 'agentRate',
            width: '15%',
            align: 'center',
            render: (text, item) => (this._renderRateCell(text, item, 'agentRate'))
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
    ]);

    // 数据源处理
    _takeDataSource = (dataList, treeOpen) => {
        let list = [];
        if (dataList) {
            for (let i = 0; i < dataList.length; i++) {
                dataList[i].visible = true;
                dataList[i].depth = 1;
                list.push(dataList[i]);
                let secondChildren = dataList[i].categoryInfoList;
                if (secondChildren && secondChildren.length > 0) {
                    for (let m = 0; m < secondChildren.length; m++) {
                        secondChildren[m].visible = treeOpen;
                        secondChildren[m].depth = 2;
                        list.push(secondChildren[m]);
                        let thirdChildren = secondChildren[m].categoryInfoList;
                        if (thirdChildren && thirdChildren.length > 0) {
                            for (let n = 0; n < thirdChildren.length; n++) {
                                thirdChildren[n].visible = treeOpen;
                                thirdChildren[n].depth = 3;
                                list.push(thirdChildren[n]);
                            }
                        }
                    }
                }
            }
            // 过滤掉visible:false的内容
            list = list.filter((item) => {
                return item.visible;
            });
            return list;
        } else {
            return list;
        }
    }

    // 渲染表格单元格（分类名称）
    _renderInputCell = (text, item) => {
        const depth = item.depth;
        let left = '0px';
        if (depth === 2 || depth === 'secondAdd') {
            left = '40px';
        } else if (depth === 3 || depth === 'thirdAdd') {
            left = '100px';
        }
        if (depth === 1) {
            return (
                <div className={styles.textCell}>
                    <div>
                        {text}
                    </div>
                </div>
            );
        } else if (depth === 2 || depth === 3) {
            return (
                <div className={styles.textCell}>
                    <div style={{ marginLeft: left }} className={styles.icon}></div>
                    <div>
                        {text}
                    </div>
                </div>
            );
        } else if (depth === 'thirdAdd') {
            return (
                <div className={styles.textCell}>
                    <div style={{ marginLeft: left }} className={styles.icon}></div>
                    <div
                        className={styles.add}
                        onClick={() => {
                            item.parentId == '' ? message.info('请先保存上级分类！') : this._addNullCell(item);
                        }
                        }>
                        添加三级分类
                        </div>
                </div>
            );
        } else if (depth === 'secondAdd') {
            return (
                <div className={styles.textCell}>
                    <div style={{ marginLeft: left }} className={styles.icon}></div>
                    <div
                        className={styles.add}
                        onClick={() => {
                            item.parentId == '' ? message.info('请先保存上级分类！') : this._addNullCell(item);
                        }
                        }>
                        添加二级分类
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    // 表格内新增一行（添加分类）
    _addNullCell = (item) => {
        this.props.tableAction(item.id, 'addNullCell', item);
    }

    // 渲染表格单元格（技术服务费和平台服务费）
    _renderRateCell = (text, item, type) => {
        return (
            <div className={styles.rateCell}>
                {text}
            </div>
        );
    }

    // 渲染表格单元（创建时间）
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    // 渲染表格单元（展现、上传分类图标）
    _renderImgCell = (url) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <img style={{ width: '36px', height: '36px' }} src={url}></img>
            </div>
        );
    };

    // 渲染表格单元（排序）
    _renderSortCell = (text, item) => {
        if (item.depth === 'secondAdd' || item.depth === 'thirdAdd' || (!item.id && item.dotype === 'save')) {
            return null;
        } else {
            return (
                <div className={styles.sortCell}>
                    <img
                        className={styles.up}
                        src={require('@/assets/image/up.png')}
                        onClick={() => {
                            let curIDSortList = this._searchCurIDSortList(item.id, this.props.renderData.dataList);
                            let fromItem = item;
                            let toItem = this._searchToItem(item.id, curIDSortList, 'up');
                            this.props.tableAction(item.id, 'sort', { from: fromItem, to: toItem, type: 'up' });
                        }}
                    />
                    <img
                        className={styles.down}
                        src={require('@/assets/image/down.png')}
                        onClick={() => {
                            let curIDSortList = this._searchCurIDSortList(item.id, this.props.renderData.dataList);
                            let fromItem = item;
                            let toItem = this._searchToItem(item.id, curIDSortList, 'down');
                            this.props.tableAction(item.id, 'sort', { from: fromItem, to: toItem, type: 'down' });
                        }}
                    />
                </div>
            );
        }
    };

    // 找到当前ID所在的排序列表
    _searchCurIDSortList = (id, list) => {
        let curIDSortList = [];
        for (let i = 0; i < list.length; i++) {
            if (id === list[i].id) {
                curIDSortList = list;
                break;
            } else {
                if (list[i].categoryInfoList && list[i].categoryInfoList.length > 0) {
                    let clist = this._searchCurIDSortList(id, list[i].categoryInfoList);
                    if (clist.length > 0) {
                        curIDSortList = clist;
                        break;
                    }
                }
            }
        }
        return curIDSortList;
    }

    // 查找变换至目标排序的分类对象
    _searchToItem = (id, list, type) => {
        let toItem = null;
        for (let i = 0; i < list.length; i++) {
            if (id === list[i].id) {
                if (type === 'up') {
                    toItem = list[i - 1];
                    break;
                } else {
                    toItem = list[i + 1];
                    break;
                }
            }
        }
        return toItem;
    }

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    // 表格复选勾选
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys });
            console.log(selectedRowKeys, selectedRows);
        }
    };

    render() {
        const { selectedRowKeys } = this.state;
        const { paginationChange, treeOpen } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        console.log(this.props.renderData);
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList, treeOpen)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableData.defaultProps = {
    treeOpen: true
};

TableData.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    treeOpen: PropTypes.bool, // 表格树形结构状态（展开：true，收起：false）
};

export default TableData;