import { combineReducers } from 'redux';
import {
	receiveRecordList,
	receiveRecordDetail,
	waitReceiveList,
	waitReceiveDetail,
	receiveRecordState
} from './reduce';

export default combineReducers({
	receiveRecordList,
	receiveRecordDetail,
	waitReceiveList,
	waitReceiveDetail,
	receiveRecordState
});