import React from 'react';
import { Tooltip, Switch, Dropdown, Menu } from 'antd';
import styles from './index.module.scss';
import { regFenToYuan } from '@/util/money';
import { XMediaFn } from '@/components/xqxc_ui';
import { isEmpty } from '@/assets/js/utils';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { isButtonPerssion } from '@/assets/js/buttonPerssion';
import { FloatConversion } from '@/util/conversion';

export const tooltipStyle = () => {
    return {
        style: {
            maxWidth: 100,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        }
    };
};



const titleStyles = {
    display: 'inline-block',
    whiteSpace: 'pre-wrap',
};

const preStylesClick = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    color: '#40a9ff',
    cursor: 'pointer',
};

const preStylesClickLeft = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    color: '#40a9ff',
    cursor: 'pointer',
    textAlign: 'left',
    paddingLeft: '20px'
};

const preStyles = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
};

const preStylesLeft = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    textAlign: 'left',
    paddingLeft: '20px'
};

export { titleStyles };
export { preStyles };
export { preStylesLeft };

/**
 * 文本项
 * value：文本内容
 * <pre/>预格式化标签，避免所有连续的空格或空行（换行）都会被显示为一个空格
 */
export const tooltip = (value, align) => {
    return <Tooltip placement="top" title={<pre style={titleStyles}>{value}</pre>}><pre style={align == 'left' ? preStylesLeft : preStyles}>{isEmpty(value) ? '-' : value}</pre></Tooltip>;
};

/**
 * 文本项 - 可点击
 * value：文本内容
 * <pre/>预格式化标签，避免所有连续的空格或空行（换行）都会被显示为一个空格
 */
export const tooltipClick = (value, callback, align) => {
    return <Tooltip placement="top" title={<pre style={titleStyles}>{value}</pre>}><pre style={align == 'left' ? preStylesClickLeft : preStylesClick} onClick={callback}>{isEmpty(value) ? '-' : value}</pre></Tooltip>;
};

/**
 * 带单位的文本项
 * value：文本内容
 */
export const tooltipWithUnit = (value, unit) => {
    return <Tooltip placement="top" title={<pre style={titleStyles}>{value}</pre>}><pre style={preStyles}>{value}{unit}</pre></Tooltip>;
};

/**
 * 开关项
 * value：0关、1开
 */
export const switchCell = (value, callback) => {
    let checked = true;
    if (value == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                checkedChildren="开"
                unCheckedChildren="关"
                checked={checked}
                onChange={(value) => {
                    const result = value ? 1 : 0;
                    callback(result);
                }}
            />
        </div>
    );
};

/**
 * 金额(分转元)
 */
export const priceFenToYuanCell = (value) => {
    const price = regFenToYuan(value);
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {price}
        </div>
    );
};

/**
 * 金额(分转元)
 */
export const priceFenToYuanCellWithColor = (value, tip, bgcolor = null) => {
    const price = regFenToYuan(value);
    return (
        <Tooltip placement="top" title={<pre style={titleStyles}>{tip}</pre>}>
            <pre style={preStyles}>
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-all', background: bgcolor }}>
                    {price}
                </div>
            </pre>
        </Tooltip>
    );
};

/**
 * 比率(千分转百分)
 */
export const rateCell = (value) => {
    const floatConversion = new FloatConversion();

    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {tooltip(floatConversion.divide(Number(value), 10))}
        </div>
    );
};

/**
 * 图片项
 * @param {*} url 
 */
export const ImgCell = (url) => {
    return (
        <div style={{ display: 'inline-flex' }}>
            <XMediaFn
                style={{ width: '48px', height: '48px', marginRight: '0' }}
                dataType='image'
                dataSource={url}
                key={url}
            />
        </div>
    );
};
/**
 * 更多项
 * @param 
 *  arr = [
            { key: 'MEMBER_TICKET', name: '卡券管理', onClick: () => tableAction(key, 'MEMBER_TICKET'), perssionCode: 'usermanage/member/kqgl' },
        ];
        perssionCode: 配置权限
 */
export const MoreCell = (arr = []) => {
    const cellItem = () => {
        let boolean = false;
        arr.map(i => {
            if ((i.perssionCode && isButtonPerssion(i.perssionCode)) || !i.perssionCode) {
                boolean = true;
            }
        });
        return boolean;
    };
    const _menu = (
        <Menu>
            {arr.map((i, index) => {
                return ((i.perssionCode && isButtonPerssion(i.perssionCode)) || !i.perssionCode) &&
                    <Menu.Item className={styles.moreStyles} key={i.key || index}>
                        {i.perssionCode ?
                            <ButtonPerssion code={i.perssionCode}>
                                <div onClick={i.onClick}>{i.name}</div>
                            </ButtonPerssion> :
                            <div onClick={i.onClick}>{i.name}</div>
                        }
                    </Menu.Item>;
            })}
        </Menu>
    );
    return (
        cellItem() && <Dropdown overlay={_menu} trigger={['click']} >
            <span style={{ cursor: 'pointer', color: '#40a9ff' }}>更多操作</span>
        </Dropdown>
    );
};

/**
 * 表格宽度3中初始规范
 */
export const cellWidth = {
    small: 100,
    normal: 150,
    time: 180,
    big: 300
};