import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const columns = (TableAction, actionClickHandle) => [
    {
        title: '分组ID',
        dataIndex: 'id',
        key: 'id',
        width: '15%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '分组名称',
        dataIndex: 'groupingName',
        key: 'groupingName ',
        width: '15%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate ',
        width: '15%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'key',
        key: 'key',
        align: 'center',
        render: (text, record) => TableAction(record.id, record, actionClickHandle)
    }
];

const TableAction = (key, record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(key, 'edit', record)}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(key, 'delete')}>删除</div>
        </div>
    );

};

class TypeTable extends React.Component {
    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { renderData } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={record => record.id}
                    columns={columns(TableAction, this._actionHandle)}
                    dataSource={renderData}
                    pagination={false}
                    scroll={{ y: true }}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

export default TypeTable;