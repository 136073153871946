/**
 *  财务参数 
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Radio, Table, message, Checkbox, Icon, Tooltip } from 'antd';
import http from '@/assets/api/http';
import DeleteModal from '../../modal/DeleteModal';
import CreateModal from '../../modal/CreateModal';
import { XInput, XOKButton, showConfirm, XInputNum } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import { getFinanceArgDetail, getFinanceInfoList } from '@/reducers/systemmanage/financeArg/actions';
import { regFenToYuan, regYuanToFen } from '@/util/money';

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedRowKeys: [],//表格选择和操作
			goodsDataSource: [],// 模拟表格数据
			currentOpenMode: '', // 当前需要打开的弹窗
			openModeParam: {}, // 当前需要打开的弹窗的入参

			taxRatio: 0,//个税比例
			transferType: '',//转账手续费
			transferValue: 0,//转账手续费值
			statementGenerTime: 0,//商户区域天数   
			memberMinWithdraw: 0,//会员提现低
			memberMaxWithdraw: 0,//会员提现高
			serviceMinWithdraw: 0,//服务商提现低  
			serviceMaxWithdraw: 0,//服务商提现高
			merchantMaxWithdraw: 0,//商户提现高
			merchantMinWithdraw: 0,//商户提现低
			salemanMaxWithdraw: 0,// 云仓提现高
			salemanMinWithdraw: 0,// 云仓提现低
			transferAccessType: '',//代付通道

			pageSize: 20,//默认
			pageNum: 1,//默认
			loading: false,
			merchantTaxRatio: 1,//商户会员销售收益支取个税收取
			starLightWithdrawFrequencyType: 1,
			weekValue: [],
			timeFlag: false,
			procedureRatio: 0,
			marketingApplyAmountLimit: 0,//营销补贴申请提现金额设置
			pointsTransferType: '',//积分转赠手续费类型
			pointsFee: 0,//积分转赠手续费
			pointsMin: 0, //积分转赠最小值
			pointsMax: 0, //积分转赠最大值
			consumerFundsTransferType: 'SINGLE',
			consumerFundsFee: 0, // 转消费金手续费
		};
	}

	componentDidMount() {
		const { pageNum, pageSize } = this.state;
		this.props.getFinanceArgDetail();
		this.props.getFinanceInfoList({ pageNum, pageSize });
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		const { financeArgDetail } = newProps;
		let memberMinWithdraw = regFenToYuan(financeArgDetail.memberMinWithdraw);
		let memberMaxWithdraw = regFenToYuan(financeArgDetail.memberMaxWithdraw);
		let serviceMinWithdraw = regFenToYuan(financeArgDetail.serviceMinWithdraw);
		let serviceMaxWithdraw = regFenToYuan(financeArgDetail.serviceMaxWithdraw);
		let merchantMaxWithdraw = regFenToYuan(financeArgDetail.merchantMaxWithdraw);
		let merchantMinWithdraw = regFenToYuan(financeArgDetail.merchantMinWithdraw);
		let salemanMaxWithdraw = regFenToYuan(financeArgDetail.salemanMaxWithdraw);
		let salemanMinWithdraw = regFenToYuan(financeArgDetail.salemanMinWithdraw);
		let merchantTaxRatio = financeArgDetail.merchantTaxRatio;
		let marketingApplyAmountLimit = regFenToYuan(financeArgDetail.marketingApplyAmountLimit);
		let pointsFee = regFenToYuan(financeArgDetail.pointsFee);
		let pointsMin = regFenToYuan(financeArgDetail.pointsMin);
		let pointsMax = regFenToYuan(financeArgDetail.pointsMax);
		
		let consumerFundsFee = financeArgDetail.consumerFundsFee;

		let taxRatio = financeArgDetail.taxRatio;
		let transferValue = financeArgDetail.transferValue;
		this.setState({
			taxRatio,//个税比例
			transferValue,
			transferType: financeArgDetail.transferType,//转账手续费
			statementGenerTime: financeArgDetail.statementGenerTime,//商户区域天数   
			memberMinWithdraw,//会员提现低
			memberMaxWithdraw,//会员提现高
			serviceMinWithdraw,//服务商提现低  
			serviceMaxWithdraw,//服务商提现高
			merchantMaxWithdraw,//商户提现高
			merchantMinWithdraw,//商户提现低
			salemanMaxWithdraw,//云仓提现高
			salemanMinWithdraw,//云仓提现低
			merchantTaxRatio,//商户会员销售收益支取个税收取
			startLightTaxRatio: financeArgDetail.startLightTaxRatio,
			starLightWithdrawAmount: financeArgDetail.starLightWithdrawAmount,
			starLightWithdrawFrequencyType: financeArgDetail.starLightWithdrawFrequencyType ? financeArgDetail.starLightWithdrawFrequencyType : 1,
			starLightWithdrawFrequency: financeArgDetail.starLightWithdrawFrequency,
			weekValue: financeArgDetail.starLightWithdrawFrequencyType == 1 ? financeArgDetail.starLightWithdrawFrequency && financeArgDetail.starLightWithdrawFrequency.split(',') : [],
			monthValue: financeArgDetail.starLightWithdrawFrequencyType == 2 ? financeArgDetail.starLightWithdrawFrequency : '',
			timeFlag: financeArgDetail.starLightWithdrawFrequencyType == 1 ? false : financeArgDetail.starLightWithdrawFrequencyType == 2 ? true : null,
			transferAccessType: financeArgDetail.transferAccessType,//代付通道
			procedureRatio: financeArgDetail.procedureRatio,
			marketingApplyAmountLimit, //营销补贴申请提现金额设置
			pointsTransferType: financeArgDetail.pointsTransferType, pointsFee, //积分转赠手续费类型、积分转赠手续费
			pointsMin, pointsMax,//积分转赠最小值、积分转赠最大值
			consumerFundsTransferType: financeArgDetail.consumerFundsTransferType, consumerFundsFee, // 转消费金手续费
		});
	}

	// 转账手续费
	_transferType = (val) => {
		this.setState({ transferType: val.target.value });
	}
	// 转账手续费
	_transferAccessType = (val) => {
		this.setState({ transferAccessType: val.target.value });
	}
	// 魅力值提现频率
	_transferTime = (val) => {
		this.setState({
			starLightWithdrawFrequencyType: val.target.value,
			timeFlag: !this.state.timeFlag
		});
	}
	// 新建按钮被点击
	_newCreateHandle = () => {
		// debugger;
		console.log('新建');
		this.setState({
			currentOpenMode: 'NEWCREATE',
		});

	}
	// 表格操作功能回调   //EDIT DISABLE  NEWCREATE
	_actionClickHandle = (id, type, params) => {
		const { pageNum, pageSize } = this.state;
		console.log(id, type);
		switch (type) {
			case 'EDIT'://编辑
				console.log('编辑');
				this.setState({
					currentOpenMode: 'EDIT',
					openModeParam: params
				});
				break;
			case 'DELETE'://禁用
				console.log('删除');
				showConfirm('您确定删除该条财务信息？', '', () => {
					http('/plat/financeAccount/delete/' + id, {}, 'POST').then(() => {
						this.props.getFinanceInfoList({ pageNum, pageSize });
						message.success('删除财务信息成功。');
					}).catch((error) => {
						message.warn(error.message);
					});
				});
				break;
			default: return null;
		}

	}

	// 打开弹窗
	_renderMode = () => {
		switch (this.state.currentOpenMode) {
			case 'EDIT':
				return <CreateModal title='编辑财务信息' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
			case 'NEWCREATE':
				return <CreateModal title='新建财务信息' visible={true} closeMode={this._closeMode} />;
			case 'DISABLE':
				return <DeleteModal title='禁用' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
			default:
				return null;
		}
	}

	// 关闭弹框
	_closeMode = (status) => {
		this.setState({ currentOpenMode: '' });
		const { pageNum, pageSize } = this.state;
		status ? this.props.getFinanceInfoList({ pageNum, pageSize }) : null;
	}
	//重置
	resethandle = () => {
		this.setState({
			taxRatio: 0,//个税比例
			transferType: '',//转账手续费
			transferValue: 0,//转账手续费值
			statementGenerTime: 0,//商户区域天数   
			memberMinWithdraw: 0,//会员提现低
			memberMaxWithdraw: 0,//会员提现高
			serviceMinWithdraw: 0,//服务商提现低  
			serviceMaxWithdraw: 0,//服务商提现高
			merchantMaxWithdraw: 0,//商户提现高
			merchantMinWithdraw: 0,//商户提现低
			salemanMaxWithdraw:0, 
			salemanMinWithdraw:0,
			merchantTaxRatio: 1,//商户会员销售收益支取个税收取
			startLightTaxRatio: 0,
			starLightWithdrawAmount: 0,
			starLightWithdrawFrequencyType: 1,
			weekValue: [],
			monthValue: '',
			timeFlag: false,
			transferAccessType: '',//代付通道
			procedureRatio: 0,
			marketingApplyAmountLimit: 0,//营销补贴申请提现金额设置
			pointsTransferType: null, pointsFee: 0, pointsMin: 0, pointsMax: 0, //积分转赠手续费类型、积分转赠手续费、积分转赠最小值、积分转赠最大值
			consumerFundsTransferType: 'SINGLE', consumerFundsFee: 0,  // 转消费金手续费
		});
	}
	_sendReq = (url, data, winText) => {
		http(url, data, 'POST')
			.then(() => {
				message.success(winText);
				this.setState({ loading: false });
			})
			.catch((error) => {
				this.setState({ loading: false });
				message.error(error.message);
			});
	}
	// 每周
	onweekChange = (val) => {
		this.setState({ weekValue: val });
	}
	starLightWithdrawAmountChange = e => {
		let val = e.target.value;
		if (val <= 0 || val % 100 !== 0) {
			message.warn('仅支持100的倍数!');
		} else {
			this.setState({ starLightWithdrawAmount: val });
		}
	}
	submithandle = () => {
		const {
			taxRatio, transferType, transferValue, memberMinWithdraw, memberMaxWithdraw, serviceMinWithdraw, startLightTaxRatio,
			starLightWithdrawAmount, starLightWithdrawFrequencyType, serviceMaxWithdraw, statementGenerTime, weekValue, monthValue,
			merchantMaxWithdraw, merchantMinWithdraw, salemanMaxWithdraw, salemanMinWithdraw, merchantTaxRatio, transferAccessType, procedureRatio, marketingApplyAmountLimit,
			pointsFee, pointsTransferType, pointsMin, pointsMax, consumerFundsFee, consumerFundsTransferType
		} = this.state;
		if (Number(merchantTaxRatio) < 1 || Number(merchantTaxRatio) > 100) {
			message.warning('商户会员销售收益支取个税收取，请输入1-100之间');
		}
		const reqParams = {};
		reqParams.memberMinWithdraw = regYuanToFen(memberMinWithdraw);
		reqParams.memberMaxWithdraw = regYuanToFen(memberMaxWithdraw);
		reqParams.serviceMinWithdraw = regYuanToFen(serviceMinWithdraw);
		reqParams.serviceMaxWithdraw = regYuanToFen(serviceMaxWithdraw);
		reqParams.merchantMaxWithdraw = regYuanToFen(merchantMaxWithdraw);
		reqParams.merchantMinWithdraw = regYuanToFen(merchantMinWithdraw);
		reqParams.salemanMaxWithdraw = regYuanToFen(salemanMaxWithdraw);
		reqParams.salemanMinWithdraw = regYuanToFen(salemanMinWithdraw);
		reqParams.marketingApplyAmountLimit = regYuanToFen(marketingApplyAmountLimit);
		reqParams.pointsFee = regYuanToFen(pointsFee);
		reqParams.pointsMin = regYuanToFen(pointsMin);
		reqParams.pointsMax = regYuanToFen(pointsMax);
		let starLightWithdrawFrequency;
		if (starLightWithdrawAmount <= 0 || starLightWithdrawAmount % 100 !== 0) {
			message.warn('魅力值提现数量仅支持100的倍数!');
			return;
		}
		if (starLightWithdrawFrequencyType == 1 && weekValue.length == 0) {
			message.warn('魅力值提现频率每周必选！');
			return;
		}
		if (starLightWithdrawFrequencyType == 2 && !monthValue) {
			message.warn('魅力值提现频率每月必输！');
			return;
		}
		starLightWithdrawFrequencyType == 1 ? starLightWithdrawFrequency = weekValue && weekValue.sort() && weekValue.join(',') : starLightWithdrawFrequency = monthValue && monthValue.split(',').sort().join(',');
		this.setState({ loading: true }, () => {
			this._sendReq('/plat/financial/setting', {
				...reqParams, startLightTaxRatio, starLightWithdrawAmount, starLightWithdrawFrequencyType, starLightWithdrawFrequency, taxRatio, transferType,
				transferValue, statementGenerTime, transferAccessType, procedureRatio, merchantTaxRatio, pointsTransferType, consumerFundsFee, consumerFundsTransferType
			}, '提交成功。');
		});
	}
	_renderCost = () => {
		const { transferType, transferValue } = this.state;
		switch (transferType) {
			case 'SINGLE':
				return (
					<div style={{ display: 'flex' }}>
						<XInput
							style={{ width: 'auto' }}
							inputStyle={{ width: '160px', }}
							placeholder='请输入0-1000'
							isRequired={false}
							value={transferValue}
							bindThis={this}
							bindName='transferValue'
						/>
						<b style={{ padding: '3px 10px' }}>元</b>
					</div>
				);
			case 'RATIO':
				return (
					<div style={{ display: 'flex' }}>
						<XInput
							style={{ width: 'auto' }}
							inputStyle={{ width: '160px', }}
							placeholder='请输入0-100'
							isRequired={false}
							value={transferValue}
							bindThis={this}
							bindName='transferValue'
						/>
						<b style={{ padding: '3px 10px' }}>%</b>
					</div>
				);
		}
	}

	render() {
		const { financeInfoList } = this.props;
		const {
			taxRatio, transferType, statementGenerTime, memberMinWithdraw, memberMaxWithdraw, serviceMinWithdraw, serviceMaxWithdraw, merchantTaxRatio,
			startLightTaxRatio, starLightWithdrawAmount, monthValue, weekValue, starLightWithdrawFrequencyType, merchantMaxWithdraw, merchantMinWithdraw,
			salemanMaxWithdraw, salemanMinWithdraw,
			loading, timeFlag, transferAccessType, procedureRatio, marketingApplyAmountLimit, pointsFee, pointsTransferType, pointsMin, pointsMax, 
			consumerFundsFee, consumerFundsTransferType
		} = this.state;
		const plainOptions = [
			{ label: '周一', value: '1' },
			{ label: '周二', value: '2' },
			{ label: '周三', value: '3' },
			{ label: '周四', value: '4' },
			{ label: '周五', value: '5' },
		];

		const xiaofeijinTips = <div>
			用户将收益账户和补贴账户的金额转入到消费金账户<br/>
			①：按单笔计算，则固定扣取设置的手续费 ，例如：设置按单笔手续费为：2元 ，用户申请收益转入金额10元，扣除单笔手续费2元，实际到账8元；<br/>
			②：按比例计算，则手续费为用户申请收益转入金额*比例。例如：设置按按比例为：10% ，用户申请收益转入金额10元，扣除单笔手续费10*10%=1元，实际到账9元
		</div>;
		return (
			<div className={styles.flexBoxContainer}>
				<div className={`${styles.tableContainer}`}>
					<div className={styles.tableContainer} style={{ overflow: 'auto' }}>
						<div className={styles.financial}>
							<div className={styles.top_title}>
								<span>财务参数</span>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<span>个税比率：</span>
								<XInputNum
									style={{ width: '260px' }}
									inputStyle={{ width: '240px', }}
									placeholder='请输入1-100，支持1位小数'
									min={1}
									max={100}
									precision={1}
									isRequired={false}
									value={taxRatio}
									bindThis={this}
									bindName='taxRatio'
								/>
								<b>%</b>
								<span style={{ color: '#999', padding: '0 10px' }}>现金收益提现个税比例</span>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`} style={{ marginLeft: 70 }}>
								{/* <span>个税比率：</span> */}
								<XInputNum
									style={{ width: '260px' }}
									inputStyle={{ width: '240px', }}
									placeholder='请输入0-100，支持1位小数'
									min={0}
									max={100}
									precision={1}
									isRequired={false}
									value={startLightTaxRatio}
									bindThis={this}
									bindName='startLightTaxRatio'
								/>
								<b>%</b>
								<span style={{ color: '#999', padding: '0 10px' }}>魅力值收益提现个税比例</span>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<XInputNum
									style={{ width: '260px', marginLeft: 70 }}
									inputStyle={{ width: '240px', }}
									placeholder='请输入1-100，支持1位小数'
									min={1}
									max={100}
									precision={1}
									isRequired={false}
									value={merchantTaxRatio}
									bindThis={this}
									bindName='merchantTaxRatio'
								/>
								<b>%</b>
								<span style={{ color: '#999', padding: '0 10px' }}>商户会员销售收益支取个税收取</span>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<span>魅力值提现：魅力值数量达到</span>
								<XInputNum
									style={{ width: '260px' }}
									inputStyle={{ width: '240px', }}
									placeholder='请输入'
									min={1}
									precision={0}
									step={100}
									onBlur={this.starLightWithdrawAmountChange}
									isRequired={false}
									value={starLightWithdrawAmount}
									bindThis={this}
									bindName='starLightWithdrawAmount'
								/>
								<b>个，</b>
								<span style={{ color: '#999', padding: '0 10px' }}>系统自动提现到用户账户，仅支持100的倍数</span>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<span style={{ marginTop: -50 }}>魅力值提现频率：</span>
								<Radio.Group name="rate" value={starLightWithdrawFrequencyType} className={styles.curt} onChange={this._transferTime}>
									<Radio value={1}>每周</Radio>
									<span style={{ color: '#999', padding: '0 10px' }}>
										<Checkbox.Group disabled={timeFlag} options={plainOptions} value={weekValue} onChange={this.onweekChange} />
									</span><br /><span style={{ marginTop: 20, display: 'block' }}></span>
									<Radio value={2}>每月</Radio>
									<XInput
										style={{ width: '180px', display: 'inline-block' }}
										inputStyle={{ width: '160px', }}
										placeholder='请输入'
										isRequired={false}
										value={monthValue}
										bindThis={this}
										bindName='monthValue'
										disabled={!timeFlag}
									/>
									<span style={{ color: '#999', padding: '0 10px' }}>请填写日期，多个日期使用逗号间隔，日期范围：1-28号，例子：1,4,5,9</span>
								</Radio.Group>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<span>转账手续费：</span>
								<Radio.Group name="transferType" value={transferType} className={styles.curt} onChange={this._transferType}>
									<Radio value='SINGLE'>按单笔多少元</Radio>
									<Radio value='RATIO'>按比率计算</Radio>
								</Radio.Group>
								{
									this._renderCost()
								}
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<span>商户、区域公司和运营中心对账单生成时点，每月第</span>
								<XInput
									style={{ width: 'auto' }}
									inputStyle={{ width: '160px', }}
									placeholder='请输入1-28'
									isRequired={false}
									value={statementGenerTime}
									bindThis={this}
									bindName='statementGenerTime'
								/>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<span>会员提现：</span>
								<p>单笔最高</p>
								<XInput
									style={{ width: 'auto' }}
									inputStyle={{ width: '160px', marginRight: '10px' }}
									placeholder='请输入'
									isRequired={false}
									value={memberMaxWithdraw}
									maxLength={8}
									showLength={false}
									bindThis={this}
									bindName='memberMaxWithdraw'
								/>
								<b>元</b>
								<p>单笔最低</p>
								<XInput
									style={{ width: 'auto' }}
									inputStyle={{ width: '160px', marginRight: '10px' }}
									placeholder='请输入'
									isRequired={false}
									value={memberMinWithdraw}
									maxLength={8}
									showLength={false}
									bindThis={this}
									bindName='memberMinWithdraw'
								/>
								<b>元</b>
								<p className={styles.tip}>(建议最低金额不要低于10元)</p>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<span>服务商提现：</span>
								<p>单笔最高</p>
								<XInput
									style={{ width: 'auto' }}
									inputStyle={{ width: '160px', marginRight: '10px' }}
									placeholder='请输入'
									isRequired={false}
									value={serviceMaxWithdraw}
									maxLength={8}
									showLength={false}
									bindThis={this}
									bindName='serviceMaxWithdraw'
								/>
								<b>元</b>
								<p>单笔最低</p>
								<XInput
									style={{ width: 'auto' }}
									inputStyle={{ width: '160px', marginRight: '10px' }}
									placeholder='请输入'
									isRequired={false}
									value={serviceMinWithdraw}
									maxLength={8}
									showLength={false}
									bindThis={this}
									bindName='serviceMinWithdraw'
								/>
								<b>元</b>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<span>商户提现：</span>
								<p>单笔最高</p>
								<XInput
									style={{ width: 'auto' }}
									inputStyle={{ width: '160px', marginRight: '10px' }}
									placeholder='请输入'
									isRequired={false}
									value={merchantMaxWithdraw}
									bindThis={this}
									maxLength={8}
									showLength={false}
									bindName='merchantMaxWithdraw'
								/>
								<b>元</b>
								<p>单笔最低</p>
								<XInput
									style={{ width: 'auto' }}
									inputStyle={{ width: '160px', marginRight: '10px' }}
									placeholder='请输入'
									isRequired={false}
									value={merchantMinWithdraw}
									bindThis={this}
									maxLength={8}
									showLength={false}
									bindName='merchantMinWithdraw'
								/>
								<b>元</b>
								<p className={styles.tip}>(建议最低金额不要低于10元)</p>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<span>云仓提现：</span>
								<p>单笔最高</p>
								<XInput
									style={{ width: 'auto' }}
									inputStyle={{ width: '160px', marginRight: '10px' }}
									placeholder='请输入'
									isRequired={false}
									value={salemanMaxWithdraw}
									bindThis={this}
									maxLength={8}
									showLength={false}
									bindName='salemanMaxWithdraw'
								/>
								<b>元</b>
								<p>单笔最低</p>
								<XInput
									style={{ width: 'auto' }}
									inputStyle={{ width: '160px', marginRight: '10px' }}
									placeholder='请输入'
									isRequired={false}
									value={salemanMinWithdraw}
									bindThis={this}
									maxLength={8}
									showLength={false}
									bindName='salemanMinWithdraw'
								/>
								<b>元</b>
								<p className={styles.tip}>(建议最低金额不要低于10元)</p>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<span>股票溢价收益手续费：</span>
								<XInputNum
									style={{ width: '260px' }}
									inputStyle={{ width: '240px', }}
									placeholder='请输入1-100，支持2位小数'
									min={0}
									max={100}
									precision={2}
									isRequired={false}
									value={procedureRatio}
									bindThis={this}
									bindName='procedureRatio'
								/>
								<b>%</b>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<span>代付通道：</span>
								<Radio.Group value={transferAccessType} className={styles.curt} onChange={this._transferAccessType}>
									<Radio value='UNION_PAY'>银联</Radio>
									<Radio value='LL_PAY'>连连</Radio>
									<Radio value='ALI_PAY'>原生支付宝</Radio>
									<Radio value='YOU_FU_PAY'>身边云</Radio>
								</Radio.Group>
								<Tooltip placement='right' title='充值付款资金账户选择，选定提交后即执行。'>
									<Icon type='question-circle' />
								</Tooltip>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<span>营销补贴申请提现金额设置：</span>
								<XInputNum
									style={{ width: '260px' }}
									inputStyle={{ width: '240px', }}
									placeholder='请输入营销补贴申请提现金额'
									min={0}
									max={999999.99}
									precision={2}
									isRequired={false}
									value={marketingApplyAmountLimit}
									bindThis={this}
									bindName='marketingApplyAmountLimit'
								/>
								<b>（元）</b>
								<Tooltip placement='right' title='营销补贴达到金额后，用户才能申请提现到收益中'>
									<Icon type='question-circle' />
								</Tooltip>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<span>积分转赠手续费：</span>
								<Radio.Group value={pointsTransferType} onChange={e => this.setState({ pointsTransferType: e.target.value })}>
									<Radio value='SINGLE'>按单笔多少积分</Radio>
									<Radio value='RATIO'>按比率计算</Radio>
								</Radio.Group>
								<XInputNum style={{ marginRight: '10px' }} min={0} max={pointsTransferType === 'SINGLE' ? 999999.99 : 100}
									precision={pointsTransferType === 'SINGLE' ? 2 : 0} isRequired={false} value={pointsFee} bindThis={this} bindName='pointsFee' />
								<b>{pointsTransferType === 'SINGLE' ? '/笔' : '%'}（用户转增积分时，按每笔收取手续费）</b>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<span>积分转赠：</span>
								<span>每天最高</span>
								<XInputNum style={{ marginRight: '10px' }} min={pointsMin} max={999999.99} precision={2}
									isRequired={false} value={pointsMax} bindThis={this} bindName='pointsMax' />
								<span>积分，单笔最低</span>
								<XInputNum style={{ marginRight: '10px' }} min={0} max={pointsMax} precision={2}
									isRequired={false} value={pointsMin} bindThis={this} bindName='pointsMin' />
								<span>积分</span>
							</div>
							<div className={`${styles.layout} ${styles.paramersTiems}`}>
								<span>转入消费金手续费设置：</span>
								<Radio.Group value={consumerFundsTransferType} onChange={e => this.setState({ consumerFundsTransferType: e.target.value })}>
									<Radio value='SINGLE'>按单笔金额</Radio>
									<Radio value='RATIO'>按单笔比例</Radio>
								</Radio.Group>
								<XInputNum style={{ marginRight: '10px' }} min={0} max={consumerFundsTransferType === 'SINGLE' ? 999999.99 : 100}
									precision={consumerFundsTransferType === 'SINGLE' ? 2 : 0} isRequired={false} value={consumerFundsFee} bindThis={this} bindName='consumerFundsFee' />
								<b style={{marginRight: '10px'}}>{consumerFundsTransferType === 'SINGLE' ? '(元)' : '%'}</b>
								<Tooltip placement='right' overlayStyle={{maxWidth: '400px'}} title={xiaofeijinTips}>
									<Icon type='question-circle' />
								</Tooltip>
							</div>
						</div>
						<div style={{ margin: '20px' }} className={styles.layout}>
							<XOKButton onClick={this.resethandle} disabled={loading} style={{ width: '100px', marginLeft: '30px' }} label='重置' />
							<XOKButton onClick={this.submithandle} loading={loading} style={{ width: '120px', marginLeft: '30px' }} label='立即提交' />
						</div>
						<div className={styles.financial}>
							<div className={styles.top_title}>
								<span>平台财务信息</span>
							</div>
							<div className={`${styles.refundtable}`}>
								<div className={styles.flex}>
									<Table
										rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
										rowKey={(record, index) => index}
										columns={takeColumns(this._actionClickHandle)}
										dataSource={takeDataSource(financeInfoList)}
										pagination={false}
										scroll={{ x: 2600 }}
									/>
								</div>
							</div>
							<p className={styles.creatdash} onClick={this._newCreateHandle}>新增</p>
						</div>
						{this._renderMode()}
					</div>
				</div>
			</div>
		);
	}
}
Main.propTypes = {
	updateContent: PropTypes.func, // 路由跳转
	history: PropTypes.object, // 路由history
	addOneBreadcrumbPath: PropTypes.func,
	keepSecondNavBreadcrumb: PropTypes.func,
	getFinanceArgDetail: PropTypes.func,
	getFinanceInfoList: PropTypes.func,
	financeArgDetail: PropTypes.object,
	financeInfoList: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		financeArgDetail: state.financeArg.financeArgDetail,
		financeInfoList: state.financeArg.financeInfoList
	};
};

export default connect(mapStateToProps, { getFinanceArgDetail, getFinanceInfoList })(Main);