import * as Audit from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

const initDetail = {
    invoices: [],
    auditLogs: [],
    invoiceBuyer: {},
    invoiceSeller: {},
    invoiceDelivery: {}
};

const payInfo = {

};

export const operationInvoiceList = (state = initData, action) => {
    switch (action.type) {
        case Audit.SET_OPERATIONINVOICE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

export const operationInvoiceStatus = (state = [], action) => {
    switch (action.type) {
        case Audit.SET_OPERATIONINVOICE_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};

export const operationInvoiceDetail = (state = initDetail, action) => {
    switch (action.type) {
        case Audit.SET_OPERATIONINVOICE_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

export const operationInvoiceName = (state = [], action) => {
    switch (action.type) {
        case Audit.SET_OPERATIONINVOICE_NAME:
            return action.payload.result;
        default:
            return state;
    }
};

export const operationInvoicePay = (state = payInfo, action) => {
    switch (action.type) {
        case Audit.SET_OPERATIONINVOICE_PAY:
            return action.payload;
        default:
            return state;
    }
};