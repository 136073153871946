import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell, ImgCell } from '@/components/TableCell';

class TableContent extends Component {
	state = {
		selectedRowKeys: [],
	};

	_columns = () => [
		{
			title: '商品图片',
			dataIndex: 'mainImgUrl',
			key: 'mainImgUrl',
			align: 'center',
			width: '10%',
			render: ImgCell
		},
		{
			title: '商品标题',
			dataIndex: 'goodsName',
			key: 'goodsName',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '货号',
			dataIndex: 'goodsNo',
			key: 'goodsNo',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '善品分类',
			dataIndex: 'categoryName',
			key: 'categoryName',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '分类类型',
			dataIndex: 'categorySubTypeName',
			key: 'categorySubTypeName',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '商户名称',
			dataIndex: 'merchantName',
			key: 'merchantName',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '金额(元)',
			dataIndex: 'salePrice',
			key: 'salePrice',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: priceFenToYuanCell
		},
		{
			title: '人气值',
			dataIndex: 'starValue',
			key: 'starValue',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '更新时间',
			dataIndex: 'gmtCreate',
			key: 'gmtCreate',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'goodsId',
			key: 'goodsId',
			align: 'center',
			width: '10%',
			render: (id) => this._tableAction(id)
		},
	];

	_imgCell = (text) => {
		return <img src={text} width='50px' height='50px'></img>;
	}

	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys) => {
			this.setState({ selectedRowKeys });
			this.props.tableAction(selectedRowKeys, 'batchBan');
		}
	};

	// 清空表格复选框
	_cleanAllSelected = () => {
		this.setState({ selectedRowKeys: [] }, () => {
			this.props.tableAction([], 'batchBan');
		});
	}

	_takeDataSource = (data = []) => {
		return data;
	}

	_tableAction = (key) => {
		const { tableAction } = this.props;
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => tableAction(key, 'detail')}>详情</div>
				<div className={styles.item} onClick={() => tableAction(key, 'ban')}>移除</div>
			</div>
		);
	};

	render() {
		const { paginationChange } = this.props;
		const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

		const { selectedRowKeys } = this.state;
		this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
			<div className={styles.tableContainer}>
				<XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowSelection={this._rowSelection}
					rowKey={(record) => record.goodsId}
					columns={this._columns()}
					pagination={false}
					dataSource={this._takeDataSource(dataList)}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={resultTotal}
					pageSize={pageSize}
					pageNum={pageNum}
					onChange={paginationChange}
				/>
			</div>
		);
	}
}

TableContent.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
};

export default TableContent;