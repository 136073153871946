import { createModel } from '@/store/tools';

const model = {
    namespace: 'productmanage',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getProductExplain': T('getProductExplain'),
            'getProductExplainInfo': T('getProductExplainInfo'),
            'getSalaAfterTypeEnum': T('getSalaAfterTypeEnum'),
        },
        actions: {
            'getProductExplain': A('getProductExplain'),
            'getProductExplainInfo': A('getProductExplainInfo'),
            'getSalaAfterTypeEnum': A('getSalaAfterTypeEnum'),
        },
        sagas: {
            'getProductExplain': S('getProductExplain', '/goods/service/listService'),
            'getProductExplainInfo': S('getProductExplainInfo', '/goods/service/infoService'),
            'getSalaAfterTypeEnum': S('getSalaAfterTypeEnum', '/enum/GoodsSalesAfterTypeEnum'),
        },
        reducers: {
            'productExplain': R('getProductExplain', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'productExplainInfo': R('getProductExplainInfo', {}),
            'salaAfterTypeEnum': R('getSalaAfterTypeEnum', []),
        }
    })
};
export default createModel(model);