import { combineReducers } from 'redux';
import common_enum from './common/enum';
import auth from './auth';
import home from './home';

// 运营中心管理
import operationManageData from './operationManage';

// 市场管理
import salesman from './operationcenter/salesman';
import liwu from './operationcenter/liwu';

// 商户管理 
import merchantlist from './businessmanage/merchantlist';
import merchantsHotel from './businessmanage/merchantsHotel';
import merchantUnion from './businessmanage/merchantunion';
import citybeautyopen from './businessmanage/citybeautyopen';
import businessclasslist from './businessmanage/classlist';

// 商品管理
import productBrand from './productmanage/brand';
import productLabel from './productmanage/labellist';
import productExplain from './productmanage/explainlist';
import productListData from './productmanage/list';
import classify from './productmanage/classify';
import standardProduct from './productmanage/standardproduct';
import wholesaleGoods from './productmanage/wholesalegoods';
import operateRecord from './productmanage/operaterecord';
import consumerknows from './productmanage/consumerknows';

// 订单管理
import productOrder from './ordermanage/productOrder';
import hotelOrder from './ordermanage/hotelOrder';
import StarOrder from './ordermanage/starOrder';
import ShopOrder from './ordermanage/shopOrder';
import SubOrder from './ordermanage/subscriptionOrder';
import FreeOrder from './ordermanage/freeOrder';
import Transcation from './ordermanage/transcation';
import serviceOrder from './ordermanage/serviceOrder';
import stockearOrder from './ordermanage/stockearorder';
import valueServiceOrder from './ordermanage/valueServiceOrder';
import platformOrder from './ordermanage/platformOrder';
import cityOrder from './ordermanage/cityOrder';
import thirdOrder from './ordermanage/thirdorder';

// 营销管理
import appcustom from './marketingmanage/appcustom';
import activities from './marketingmanage/activities';
import advocacytask from './marketingmanage/advocacytask';
import cardticket from './marketingmanage/cardticket';
import mall from './marketingmanage/mall';
import xiubei from './marketingmanage/xiubei';
import marketCommon from './marketingmanage/common';
import fightrecord from './marketingmanage/fightrecord';
import productgroup from './marketingmanage/productgroup';

//店铺管理
import stores from './storemanage/list';
import hotelShop from './storemanage/hotelShop';
import cityOpen from './storemanage/cityOpen';

// 任务管理
import taskList from './taskManage/taskList';
import taskClassify from './taskManage/taskClassify';

// 售后管理
import SaleReasons from './salemanage/salereasons';
import SaleList from './salemanage/salelist';
import wholesale from './salemanage/wholesale';

// 审核管理
import autonym from './auditmanage/autonym';
import anchorCover from './auditmanage/anchorCover';
import pornography from './auditmanage/pornography';
import videoAudit from './auditmanage/videoAudit';
import commodityReview from './auditmanage/commodityReview';

import communityAuditData from './auditmanage/community';
import productAudit from './auditmanage/product';
import businessAudit from './auditmanage/businessAudit';
import businessCategory from './auditmanage/businessCategory';
import brandAudit from './auditmanage/brandAudit';
import operationAudit from './auditmanage/operationAudit';
import companyAudit from './auditmanage/companyAudit';
import activeProductAudit from './auditmanage/activeProductAudit';
import facilitatorAudit from './auditmanage/facilitatorAudit';
import illegalAudit from './auditmanage/illegalAudit';
import withdrawAudit from './auditmanage/withdraw';
import supplierAudit from './auditmanage/supplierAudit';
import companyInvoice from './auditmanage/companyInvoice';
import operationInvoice from './auditmanage/operationInvoice';
import advocacyTaskAudit from './auditmanage/advocacytask';
import payorder from './auditmanage/payorder';
import enterpriseAnchor from './auditmanage/enterpriseAnchor';
import palatGoodsAudit from './auditmanage/palatGoodsAudit';
import stockright from './auditmanage/stockright';
import previewincomeaudit from './auditmanage/previewincomeaudit';
import memberupgrade from './auditmanage/memberupgrade';
import channelaudit from './auditmanage/channelaudit';

// 客服管理
import CustomerList from './customermanage/customerList';
import Suggestions from './customermanage/suggestions';
import FeedbackType from './customermanage/feedbackType';

// 财务管理
import bankAccount from './storehousefinancialmanage/bankAccount';
import invoicemanage from './storehousefinancialmanage/invoicemanage';
import paymanage from './storehousefinancialmanage/paymanage';
import platformreconciliation from './storehousefinancialmanage/platformreconciliation';
import merchantreconciliation from './storehousefinancialmanage/merchantreconciliation';
import merchantinvoicemanage from './storehousefinancialmanage/merchantinvoicemanage';
import regionalcompanyreconciliation from './storehousefinancialmanage/regionalcompanyreconciliation';
import regionalcompanyinvoicemanage from './storehousefinancialmanage/regionalcompanyinvoicemanage';
import operatingcenterreconciliation from './storehousefinancialmanage/operatingcenterreconciliation';
import operatingcenterinvoicemanage from './storehousefinancialmanage/operatingcenterinvoicemanage';
import taxdeclaration from './storehousefinancialmanage/taxdeclaration';
import billQuery from './storehousefinancialmanage/billquery';
import exchangemanage from './storehousefinancialmanage/exchangemanage';
import reconciliationdata from './storehousefinancialmanage/reconciliationdata';
import dailystatement2 from './storehousefinancialmanage/dailystatement2';
import tonlyaccountpay from './storehousefinancialmanage/tonlyaccountpay';
import paybill from './storehousefinancialmanage/paybill';
import otherpay from './storehousefinancialmanage/otherpay';
import freeorderrefund from './storehousefinancialmanage/freeorderrefund';
import taxcertificate from './storehousefinancialmanage/taxcertificate';
import dataCenter from './storehousefinancialmanage/datacenter';
import transactionreconciliation from './storehousefinancialmanage/transactionreconciliation';
import thirdreconciliation from './storehousefinancialmanage/thirdreconciliation';
import invoicemaintain from './storehousefinancialmanage/invoicemaintain';
import cashmaintain from './storehousefinancialmanage/cashmaintain';
import marketingamountmanage from './storehousefinancialmanage/marketingamountmanage';
import previewcollectionmanage from './storehousefinancialmanage/previewcollectionmanage';
import platdividendincome from './storehousefinancialmanage/platdividendincome';
import financechannel from './storehousefinancialmanage/channel';
import subsidiesincome from './storehousefinancialmanage/subsidiesincome';
import signRecord from './storehousefinancialmanage/signRecord';
import withdrawRecord from './storehousefinancialmanage/withdrawRecord';

// 统计分析
import operationorder from './statistical/operationorder';
import companyorder from './statistical/companyorder';
import operationmember from './statistical/operationmember';
import merchantbusiness from './statistical/merchantbusiness';
import payment from './statistical/payment';
import platservicefee from './statistical/platservicefee';
import techservicefee from './statistical/techservicefee';
import showmonthly from './statistical/showmonthly';
import activitycost from './statistical/activitycost';
import showstarlight from './statistical/showstarlight';
import salereasion from './statistical/salereasion';
import goodscategory from './statistical/goodscategory';
import companycanvas from './statistical/companycanvas';
import companyincome from './statistical/companyincome';
import goodstotalcase from './statistical/goodstotalcase';
import statistishow from './statistical/statistishow';
import servicegrade from './statistical/servicegrade';

// 分销统计
import distributionsale from './statisticsofdistributor/distributionsale';
import operatingcenterdistributionsalepurchase from './statisticsofdistributor/operatingcenterdistributionsalepurchase';
import regionalcompanydistributionsalepurchase from './statisticsofdistributor/regionalcompanydistributionsalepurchase';
import serviceproviderdistributionrank from './statisticsofdistributor/serviceproviderdistributionrank';
import operationshare from './statisticsofdistributor/operationshare';
import companyshare from './statisticsofdistributor/companyshare';
import serviceshare from './statisticsofdistributor/serviceshare';
import goodshare from './statisticsofdistributor/goodshare';

// 合同管理
import contractlist from './contractmanage/contractlist';
import offlineList from './contractmanage/offlinelist';
import contracttemplate from './contractmanage/contracttemplate';

// 用户管理
import blacklist from './usermanage/blacklist';
import member from './usermanage/member';
import membermlx from './usermanage/membermlx';
import tvanchor from './usermanage/tvanchor';
import service from './usermanage/service';
import recordconversion from './usermanage/recordconversion';
import corporateanchor from './usermanage/corporateanchor';
import saler from './usermanage/saler';
import org from './usermanage/org';

// 权限管理
import structure from './permissionsmanage/structure'; // 架构管理
import employee from './permissionsmanage/employeelist'; // 员工管理
import assignauthority from './permissionsmanage/assignauthority'; // 角色管理
import menuconfigure from './permissionsmanage/menuconfigure'; // 菜单管理

// 秀场管理
import music from './showmanage/music';
import stickypaper from './showmanage/stickypaper';
import giftcomment from './showmanage/propsmanage';
import channellist from './showmanage/channellist';
import atmospherecomment from './showmanage/atmosphere';
import presentcomment from './showmanage/present';
import forbitrecord from './showmanage/forbitrecord';
import channelclassification from './showmanage/channelclassification';

// 评论管理
import productcomment from './commentmanage/productcomment';
import communitycomment from './commentmanage/communitycomment';
import videocomment from './commentmanage/videocomment';
import sensitiveWord from './commentmanage/sensitiveWord';
import hotelcomment from './commentmanage/hotelcomment';

// 举报管理
import userReport from './reportmanage/userReport'; // 用户举报
import reportReason from './reportmanage/reportReason'; // 举报原因
import showReport from './reportmanage/showReport'; // 秀场举报
import productReport from './reportmanage/productReport'; // 商品举报

// 广告管理
import advertPlacement from './advertmanage/advertPlacement'; // 广告投放
import advertStrateg from './advertmanage/advertStrateg'; // 广告策略
import advertAdsense from './advertmanage/advertAdsense'; // 广告位
import advertComment from './advertmanage/advertComment'; // 广告评论
import advertService from './advertmanage/advertService'; // 服务商广告

// 人气值商城
import starShop from './starvalueshop/starshop';
import merchantDrawApply from './starvalueshop/merchantDrawApply';
import stockDividends from './starvalueshop/stockDividends';
import redemption from './starvalueshop/redemption';

// 消息管理
import MessageCenter from './messagemanage/messageCenter'; // 消息中心
import MessageClassify from './messagemanage/messageClassify'; // 消息分类
import MessageMove from './messagemanage/messageMove'; // 消息推送

// 星球攻略
import classification from './xqstrategy/classification';
import noticecategory from './xqstrategy/noticecategory';
import noticelist from './xqstrategy/noticelists';
import initdesclist from './xqstrategy/initdesclist';

// 图文管理
import graphicscategory from './graphicsmanage/graphicscategory';
import graphicslist from './graphicsmanage/graphicslist';

// 供应商管理
import supplierlist from './suppliermanage/supplierlists';
import purchaseContract from './suppliermanage/purchasecontract';

// 系统设置
import customfitting from './systemmanage/customfitting';
import materialcategory from './systemmanage/materialcategory';
import materiallist from './systemmanage/materiallist';
import showset from './systemmanage/showset';
import memberGrade from './systemmanage/membersgrade';
import memberGradeMlx from './systemmanage/membersgrademlx';
import charmGrade from './systemmanage/charmgrade';
import mallSetting from './systemmanage/mallsetting';
import logistics from './systemmanage/logistics';
import Allocation from './systemmanage/allocation';
import costName from './systemmanage/costname';
import starShopSet from './systemmanage/starShopSet';
import marketingCampaign from './systemmanage/marketingCampaign';
import financeArg from './systemmanage/financeArg';
import release from './systemmanage/release';
import watermark from './systemmanage/watermark';
import convertiblepro from './systemmanage/convertiblepro';
import merchantpermission from './systemmanage/merchantpermission';
import riskcontrol from './systemmanage/riskcontrol';
import servicesetting from './systemmanage/servicesetting';
import templatemanage from './systemmanage/template';
import financialsetting from './systemmanage/financialsetting';
import hotCity from './systemmanage/hotCity';
import HotelOrderSys from './systemmanage/hotelOrder';
import languagePack from './systemmanage/languagePack';
import paymentChannel from './systemmanage/paymentChannel';
import premiumyield from './systemmanage/premiumyield';
import suppliesset from './systemmanage/suppliesset';
import printersetup from './systemmanage/printersetup';

//服务清单管理
import servicehistory from './servicelistingmanage/servicehistory';

//星球商学院
import groupmanage from './commercialcollege/groupmanage';
import lecturers from './commercialcollege/lecturersmanage';
import livelist from './commercialcollege/livelist';

//知识付费管理
import kclassify from './knowledgepay/channelclassify';
import klist from './knowledgepay/channellist';

// 报表管理
import template from './statementmanage/template';
import records from './statementmanage/records';

// 服务管理
import serviceProduct from './servicemanage/servicelist';

// 人气值管理
import beanDetails from './showbeanmanage/beanDetails';

// 增值服务 
import serviceAccrued from './accruedService/serviceList';

// 房源管理
import houseList from './houseresourcemanage/houselist';
import houseclassify from './houseresourcemanage/houseclassify';

//装修模板管理
import decorateManage from './systemmanage/decoratemanage';

// 待接订单列表
import receiveorderlist from './receiveordermanage/receiveorderlist';

// 酒店管理
import facilitymanage from './hotelmanage/facilitymanage';
import homestaylayout from './hotelmanage/homestaylayout';
import starlevelmanage from './hotelmanage/starlevelmanage';
import cancelreasons from './hotelmanage/cancelreasons';

// 渠道管理
import channelmanage from './channelmanage/list';

//统计信息
import allStatisticsinfo from './statisticsinfo/allStatisticsinfo';

export default combineReducers({
    common_enum, auth, home,

    // 运营中心管理
    operationManageData,

    // 市场管理
    salesman, liwu,

    // 商户管理
    merchantlist, merchantsHotel, merchantUnion, citybeautyopen, businessclasslist,

    // 商品管理
    productBrand, productLabel, productExplain, productListData, classify, wholesaleGoods, operateRecord, standardProduct, consumerknows,

    // 订单管理
    productOrder, StarOrder, ShopOrder, SubOrder, FreeOrder, Transcation, serviceOrder, stockearOrder, hotelOrder,
    valueServiceOrder, platformOrder, cityOrder, thirdOrder,

    // 营销管理
    appcustom, activities, advocacytask, cardticket, mall, xiubei, marketCommon, fightrecord, productgroup,

    //店铺管理
    stores, hotelShop, cityOpen,

    // 任务管理
    taskList, taskClassify,

    // 售后管理
    SaleReasons, SaleList, wholesale,

    // 审核管理
    autonym, anchorCover, pornography, videoAudit, commodityReview, productAudit, businessAudit, brandAudit, businessCategory,
    operationAudit, companyAudit, activeProductAudit, facilitatorAudit, communityAuditData,
    illegalAudit, withdrawAudit, supplierAudit, companyInvoice, operationInvoice, advocacyTaskAudit,
    payorder, enterpriseAnchor, palatGoodsAudit, stockright, previewincomeaudit, memberupgrade, channelaudit,

    // 客服管理
    CustomerList, Suggestions, FeedbackType,

    // 财务管理
    bankAccount, invoicemanage, paymanage, platformreconciliation, taxdeclaration, merchantinvoicemanage,
    merchantreconciliation, regionalcompanyreconciliation, regionalcompanyinvoicemanage, operatingcenterinvoicemanage,
    operatingcenterreconciliation, billQuery, exchangemanage, reconciliationdata, tonlyaccountpay, paybill, otherpay, freeorderrefund,
    taxcertificate, dataCenter, dailystatement2, transactionreconciliation, thirdreconciliation, invoicemaintain, cashmaintain,
    marketingamountmanage, previewcollectionmanage, platdividendincome, financechannel, subsidiesincome, signRecord, withdrawRecord,

    // 统计分析
    operationorder, companyorder, operationmember, merchantbusiness, payment, platservicefee, techservicefee,
    showmonthly, activitycost, showstarlight, salereasion, goodscategory, companycanvas, companyincome, goodstotalcase, statistishow, servicegrade,

    // 分销统计
    distributionsale, operationshare, companyshare, serviceshare, goodshare,
    operatingcenterdistributionsalepurchase,
    regionalcompanydistributionsalepurchase,
    serviceproviderdistributionrank,

    // 合同管理
    contractlist, offlineList, contracttemplate,

    // 用户管理
    blacklist, member, membermlx, tvanchor, service, recordconversion, corporateanchor, saler, org,

    // 权限管理
    structure, employee, assignauthority, menuconfigure,

    // 秀场管理
    music, stickypaper, giftcomment, atmospherecomment,
    presentcomment, forbitrecord, channellist, channelclassification,

    // 评论管理
    productcomment, communitycomment, videocomment, sensitiveWord,
    hotelcomment,

    // 举报管理
    userReport, reportReason, showReport, productReport,

    // 广告管理
    advertPlacement, advertStrateg, advertAdsense, advertComment, advertService,

    // 人气值商城
    starShop,
    merchantDrawApply,
    stockDividends,
    redemption,

    // 消息管理
    MessageCenter, MessageClassify, MessageMove,

    // 星球攻略
    classification, noticecategory, noticelist, initdesclist,

    // 图文
    graphicscategory,
    graphicslist,

    // 供应商
    supplierlist, purchaseContract,

    // 系统设置
    customfitting, materialcategory, materiallist, showset, memberGrade, memberGradeMlx, financeArg, release,
    charmGrade, mallSetting, logistics, Allocation, starShopSet, marketingCampaign, costName,
    watermark, convertiblepro, merchantpermission, riskcontrol, servicesetting,
    financialsetting, templatemanage, hotCity, HotelOrderSys, languagePack, paymentChannel, premiumyield,
    suppliesset, printersetup,

    //服务清单
    servicehistory,

    //星球商学院
    groupmanage,
    lecturers,
    livelist,

    //知识付费管理
    kclassify,
    klist,

    //报表管理
    template,
    records,

    // 人气值管理
    beanDetails,

    // 服务管理
    serviceProduct,

    // 增值服务 
    serviceAccrued,

    //装修模板管理
    decorateManage,

    //房源管理
    houseList,
    houseclassify,

    // 待接订单列表
    receiveorderlist,

    // 酒店管理
    facilitymanage,
    homestaylayout,
    starlevelmanage,
    cancelreasons,

    // 渠道管理
    channelmanage,

    //统计信息
    allStatisticsinfo
});