/**
 *  会员等级设置
 * */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import DailyTalkSetting from './dailytalksetting/main';
import MlxSetting from './mlxsetting/main';
import GiftSetting from './giftsetting';
import { connect } from 'react-redux';
import { XTabNav } from '@/components/xqxc_ui';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class Membersgrade extends React.Component {

    state = {
        tab: [
            { id: 1, label: '每日说会员设置' },
            { id: 2, label: '服务天使设置' },
            { id: 3, label: '牧老鲜会员设置' }
        ],
        curTabID: 1,  // 默认显示的tab
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    render() {
        const { curTabID } = this.state;

        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={styles.tableContainer}>
                    {curTabID == 1 && <DailyTalkSetting history={this.props.history} match={this.props.match} />}
                    {curTabID == 2 && <GiftSetting history={this.props.history} match={this.props.match} />}
                    {curTabID == 3 && <MlxSetting history={this.props.history} match={this.props.match} />}
                </div>
            </div>
        );
    }
}

Membersgrade.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Membersgrade);