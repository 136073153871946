import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table, } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';
import { Mode } from '../../_utils';

class TableContent extends Component {
    state = {
    };

    _columns = () => [
        {
            title: '申请单号',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支取信用值数',
            dataIndex: 'starValueNum',
            key: 'starValueNum',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '服务费（粒）',
            dataIndex: 'serviceFee',
            key: 'serviceFee',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '总信用值数',
            dataIndex: 'allStarValueNum',
            key: 'allStarValueNum',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请金额（元）',
            dataIndex: 'applyFee',
            key: 'applyFee',
            align: 'center',
            width: '8%',
            render: priceFenToYuanCell
        },
        {
            title: '兑换规则',
            dataIndex: 'ruleName',
            key: 'ruleName',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'state.value',
            key: 'state.value',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: '15%',
            render: (id, record) => this._tableAction(record.id, record)
        }
    ];

    // 表格操作
    _tableAction = (key, record) => {

        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => this._actionHandle(key, Mode.Detail, record)}>详情</div>
            </div>
        );
    };

    // 操作表格
    _actionHandle = (id, type, value) => {
        this.props.tableAction(id, type, value);
    }

    render() {
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    columns={this._columns()}
                    pagination={false}
                    dataSource={dataList}
                    scroll={{ y: true }} />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={this.props.paginationChange} />
            </div>
        );
    }
}


TableContent.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
};



export default TableContent;