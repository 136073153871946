/**
 * 运营中心管理
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Operation from './actionTypes';

export const getOperationListData = function* () {
    yield takeEvery(Operation.GET_OPERATION_LISTDATA, function* requestData(action) {
        try {
            let result = yield http('/admin/company/listOperationsCompany', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Operation.SET_OPERATION_LISTDATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getCompanyListData = function* () {
    yield takeEvery(Operation.GET_COMPANY_LISTDATA, function* requestData(action) {
        try {
            let result = yield http('/admin/company/listAreaCompany', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Operation.SET_COMPANY_LISTDATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getOperationStatus = function* () {
    yield takeEvery(Operation.GET_OPERATION_STATUS, function* requestData(action) {
        try {
            let result = yield http('/enum/OperationsStateEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Operation.SET_OPERATION_STATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getOperationDetail = function* () {
    yield takeEvery(Operation.GET_OPERATION_DETAIL, function* requestData(action) {
        try {
            let result = yield http(`/admin/company/getCompanyInfoById/${action.payload.id}`, action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Operation.SET_OPERATION_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getOperationCenterSele = function* () {
    yield takeEvery(Operation.GET_OPERATIONCENTER_SELE, function* requestData(action) {
        try {
            let result = yield http('/admin/company/listOperationsIdAndName', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Operation.SET_OPERATIONCENTER_SELE, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getSettleUnitTypeSele = function* () {
    yield takeEvery(Operation.GET_SETTLEUNITTYPE_SELE, function* requestData(action) {
        try {
            let result = yield http('/enum/SettleUnitTypeEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Operation.SET_SETTLEUNITTYPE_SELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getSettleUnitSele = function* () {
    yield takeEvery(Operation.GET_SETTLEUNIT_SELE, function* requestData(action) {
        try {
            let result = yield http('/admin/company/listOperationsIdAndName', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Operation.SET_SETTLEUNIT_SELE, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getOperationProvince = function* () {
    yield takeEvery(Operation.GET_OPERATION_PROVINCE, function* requestData(action) {
        try {
            let result = yield http('/user/area/listProvince', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Operation.SET_OPERATION_PROVINCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getOperationCity = function* () {
    yield takeEvery(Operation.GET_OPERATION_CITY, function* requestData(action) {
        try {
            let result = yield http(`/user/area/listAreaByParentId/${action.payload.parentId}`, action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Operation.SET_OPERATION_CITY, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getOperationArea = function* () {
    yield takeEvery(Operation.GET_OPERATION_AREA, function* requestData(action) {
        try {
            let result = yield http(`/user/area/listAreaByParentId/${action.payload.parentId}`, action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Operation.SET_OPERATION_AREA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProvinceSele = function* () {
    yield takeEvery(Operation.GET_PROVINCE_SELE, function* requestData(action) {
        try {
            let result = yield http('/area/provinceCtiyLinkageList', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Operation.SET_PROVINCE_SELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getCitySele = function* () {
    yield takeEvery(Operation.GET_CITY_SELE, function* requestData(action) {
        try {
            let result = yield http('/area/provinceCtiyLinkageList', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Operation.SET_CITY_SELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getTypeEnum = function* () {
    yield takeEvery(Operation.GET_TYPE_ENUM, function* requestData(action) {
        try {
            let result = yield http('/enum/TypeEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Operation.SET_TYPE_ENUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};