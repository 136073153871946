import React from 'react';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import AtmosphereGift from '../atmospheregift';
import AtmosphereGlist from '../atmosphereglist';
import AtmosphereBuy from '../atmospherebuy';

class Main extends React.Component {//atmosphere

    state = {
        tab: [
            { id: 1, label: '氛围分类' },
            { id: 2, label: '氛围列表' },
            { id: 3, label: '购买记录' },
        ],
        curTabID: 1
    }

    // Tab导航切换操作回调 
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        switch (this.state.curTabID) {
            case 1:
                return <AtmosphereGift curTabID={this.state.curTabID} />; // 氛围分类
            case 2:
                return <AtmosphereGlist curTabID={this.state.curTabID} />; // 氛围列表
            case 3:
                return <AtmosphereBuy curTabID={this.state.curTabID} />; // 购买记录
            default:
                return <AtmosphereGift curTabID={this.state.curTabID} />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={`${styles.flexBoxContainer} ${styles.page}`}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

export default Main;