/**
 *  商户审核
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import ClosedModal from '../../modal/ClosedModal';
import { XInput, XOKButton, XCancelButton, XPagination, XSelect } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import { merchantListTableSource, } from '@/reducers/auditmanage/businessAudit/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { getmerchantradius } from '@/reducers/businessmanage/merchantlist/actions';

const Mode = {
    MER_DETAIL: 'MER_DETAIL',//详情
    MER_AUDIT: 'MER_AUDIT',//审核
    MER_STOR: 'MER_STOR',//店铺
};

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            saleState: {}, // 状态
            saleNames: '', // 商户名称,
            selectedRowKeys: [], // 选中的单元行
            merchantype: {},//商户类型
            loginName: '',//商户账号
        };
    }

    componentDidMount() {
        this.props.getmerchantradius();
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.merchantListTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 状态
    _saleChangeHandle = (value) => {
        this.setState({ saleState: value });
    }
    // 商户名称
    _saleItemChangeHandle = (value) => {
        this.setState({ saleNames: value });
    }
    // 查询触发  
    _saleSearchHandle = (useCache) => {
        const { saleNames, merchantype, pageNum, pageSize, loginName } = this.state;
        this.searchCache = {
            loginName,
            merchantName: saleNames,//商户名称  
            merchantState: 'AUDIT',//状态  
            requestType: 'AUDIT',// 默认
            merchantType: merchantype.code,//商户类型
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.merchantListTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _saleresetHandle = () => {
        this.setState({ saleNames: '', merchantype: {}, loginName: '' }, () => {
            KeepAlive.saveResetFunc(this._saleSearchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作功能回调
    _actionClickHandle = (id, type, params) => {
        const { updateContent } = this.props;
        switch (type) {
            case Mode.MER_AUDIT://审核
                updateContent('MER_AUDIT', id);
                break;
            case Mode.MER_STOR://店铺
                updateContent('MER_STOR', id);
                break;
            case Mode.MER_DETAIL://查看详情
                updateContent('MER_DETAIL', id, params.accessType);
                break;
        }

    }


    // 打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.MER_CLOSED:
                return <ClosedModal title='商户停业确认' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    render() {
        const { tableSource, paginations, radiusData } = this.props;
        const { saleNames, merchantype, selectedRowKeys, loginName } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._saleSearchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商户账号'
                                placeholder='请输入商户账号'
                                bindName='loginName'
                                bindThis={this}
                                value={loginName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='商户名称'
                                placeholder='请输入商户名称'
                                onChange={this._saleItemChangeHandle}
                                value={saleNames}
                            />
                            {/* <XSelect
                        style={{ marginLeft: '30px', width: '216px' }}
                        selectStyle={{ width: '150px' }}
                        label='状态'
                        placeholder='请选择'
                        renderData={selectData}
                        onChange={this._saleChangeHandle}
                        dataIndex='value'
                        keyIndex='code'
                        value={saleState.value}
                        showSearch={true}
                    /> */}
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户类型'
                                placeholder='请选择'
                                renderData={radiusData ? radiusData : []}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='merchantype'
                                value={merchantype.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._saleSearchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._saleresetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    {/* 
                    <div className={styles.operate_box}>
                        <XOKButton disabled style={{ width: '80px' }} label='导出' onClick={this._excelHandle} />
                    </div> 
                */}
                    <div className={styles.tableContainer}>
                        {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            // rowSelection={this._rowSelection}
                            rowKey={(record, index) => index}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(tableSource)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={paginations.resultTotal}
                            pageSize={paginations.pageSize}
                            pageNum={paginations.pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    merchantListTableSource: PropTypes.func, // 通过Saga异步请求表格数据源的Action
    tableSource: PropTypes.array,
    paginations: PropTypes.object,
    getmerchantradius: PropTypes.func,
    radiusData: PropTypes.array,
};

const mapStateToProps = (state) => {
    return {
        tableSource: state.businessAudit.merchantAuditDate.tableSource, // 表格数据源
        paginations: state.businessAudit.merchantAuditDate.paginations, // 分页数据
        radiusData: state.merchantlist.merchantDate.radiusData, // 商户类型
    };
};


export default connect(mapStateToProps, { merchantListTableSource, getmerchantradius })(Main);