//异步获取数据
export const GET_WITHDRAW_LIST = 'WITHDRAW/GET_WITHDRAW_LIST'; 
export const GET_WITHDRAW_DETAIL = 'WITHDRAW/GET_WITHDRAW_DETAIL'; 
export const GET_WITHDRAW_SELE = 'WITHDRAW/GET_WITHDRAW_SELE'; 
export const GET_PAY_DETAIL = 'WITHDRAW/GET_PAY_DETAIL'; 
export const GET_SERVICE_TYPE_ENUM = 'WITHDRAW/GET_SERVICE_TYPE_ENUM'; 

//保存数据到store.state
export const SET_WITHDRAW_LIST = 'WITHDRAW/SET_WITHDRAW_LIST';
export const SET_WITHDRAW_DETAIL = 'WITHDRAW/SET_WITHDRAW_DETAIL'; 
export const SET_WITHDRAW_SELE = 'WITHDRAW/SET_WITHDRAW_SELE'; 
export const SET_PAY_DETAIL = 'WITHDRAW/SET_PAY_DETAIL'; 
export const SET_SERVICE_TYPE_ENUM = 'WITHDRAW/SET_SERVICE_TYPE_ENUM'; 