/**
 * 【架构列表】
 */
import React from 'react';
import styles from './tab.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import CompanyTable from './components/CompanyTable';
import EditModal from './modal/EditModal';
import DeleteModal from './modal/DeleteModal';
import ForbidModal from './modal/ForbidModal';
import { getCompanyList } from '@/reducers/permissionsmanage/structure/actions';
import http from '@/assets/api/http';
import { message } from 'antd';

class Operation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前弹框
            openModeParam: '', // 弹框入参 
        };
        this.searchCache = { companyType: 'RE', pageNum: 1, pageSize: 20 }; // 查询缓存
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getCompanyList(this.searchCache);
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getCompanyList(this.searchCache);
    }

    // 表格操作
    _tableAction = (id, type) => {
        switch (type) {
            case 'edit':
                this.setState({ openModeParam: id, currentOpenMode: 'edit' });
                break;
            case 'forbid':
                this.setState({ openModeParam: id, currentOpenMode: 'forbid' });
                break;
            case 'enable':
                http('/admin/dept/updateCompanyEnable', { id }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('启用成功');
                        this._closeMode(true);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
            case 'del':
                this.setState({ openModeParam: id, currentOpenMode: 'del' });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'edit':
                return <EditModal title='编辑' visible={true} closeMode={this._closeMode} data={{ id: this.state.openModeParam, type: 'Operation' }} />;
            case 'del':
                return <DeleteModal title='删除' visible={true} closeMode={this._closeMode} data={{ id: this.state.openModeParam, type: 'Operation' }} />;
            case 'forbid':
                return <ForbidModal title='封禁' visible={true} closeMode={this._closeMode} data={{ id: this.state.openModeParam, type: 'Operation' }} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (updateSuccess) => {
        this.setState({ currentOpenMode: '' });
        if (updateSuccess) {
            this.props.getCompanyList(this.searchCache);
        }
    }

    render() {
        console.log(this.props.ocList);
        return (
            <div className={styles.flexBoxContainer}>
                <CompanyTable
                    renderData={this.props.ocList}
                    tableAction={this._tableAction}
                    paginationChange={this._paginationChange}
                />
                {this._renderMode()}
            </div>
        );
    }
}

Operation.propTypes = {
    history: PropTypes.object, // history router 
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getCompanyList: PropTypes.func, // 获取部门列表
    ocList: PropTypes.object, // 运营中心列表
};

const mapStateToProps = (state) => ({
    ocList: state.structure.getCompanyList,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getCompanyList })(Operation);