import { createModel } from '@/store/tools';

const model = {
    namespace: 'serviceOrder',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getOrderList': T('getOrderList'),
        },
        actions: {
            'getOrderList': A('getOrderList'),
        },
        sagas: {
            'getOrderList': S('getOrderList', '/boss/order/getServiceOrderList'),
        },
        reducers: {
            'getOrderList': R('getOrderList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);