/**
 * 视频禁播弹框
 */
import React from 'react';
import { Modal, Input, Radio, message } from 'antd';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import styles from './Unable.module.scss';


const RadioGroup = Radio.Group;
const { TextArea } = Input;


class VideoUnable extends React.Component {
    constructor(props) {
        super(props);
        const communityRadio = this.props.communityParams != undefined ?
            this.props.communityParams.reasonable[0].id : '';
        const reasonRadio = this.props.typeList != undefined ? this.props.typeList[0].id : '';
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
            reasonRadio,//视频推荐
            communityRadio,//社区推荐
            areaValue: '',//禁播理由
        };

    }


    _handleOk = () => {
        const { reasonRadio, areaValue, communityRadio } = this.state;
        // 区分封禁是否社区列表或者视频列表
        const { communityParams, } = this.props;
        if (communityParams) {
            if (areaValue == '') {
                message.warn('请输入禁播理由');
            } else {
                console.log('.........communityBanned');
                this.setState({ confirmLoading: true }, () => {
                    http('/show/feed/disableFeed', {
                        feedId: communityParams.id, //主键id
                        reportTypeId: communityRadio,
                        reason: areaValue,
                    }, 'POST').then(() => {
                        message.success('禁播成功。');
                        this.setState({
                            visible: false,
                            confirmLoading: false
                        });
                        this.props.closeMode(true);
                    }).catch((e) => {
                        message.warn(e.message);
                        this.setState({
                            visible: false,
                            confirmLoading: false
                        });
                        // this.props.closeMode(true);
                    });
                });
            }
        } else {
            if (areaValue == '') {
                message.warn('请输入禁播理由');
            } else {
                this.setState({ confirmLoading: true }, () => {
                    http('/show/feed/disableFeed', {
                        feedId: this.props.data, //主键id
                        reportTypeId: reasonRadio,
                        reason: areaValue,
                    }, 'POST').then(() => {
                        message.success('禁播成功。');
                        this.setState({
                            visible: false,
                            confirmLoading: false
                        });
                        this.props.closeMode(true);
                    }).catch(() => {
                        message.error('请求错误!');
                        this.setState({
                            visible: false,
                            confirmLoading: false
                        });
                        this.props.closeMode(false);
                    });
                });
            }
        }

    }

    _handleCancel = () => {
        this.setState({
            visible: false,
            confirmLoading: false
        });
        this.props.closeMode(false, this.props.data);
    }

    _areaChangeHandle = (e) => {
        this.setState({ areaValue: e.target.value });
    }

    //视频与社区切换 
    _switchHanddle = () => {
        const { communityParams } = this.props;
        const { reasonRadio, communityRadio } = this.state;
        if (communityParams) {
            return (
                <RadioGroup style={{ marginLeft: '0px', marginTop: '20px' }} onChange={this._communityRadioChangeHandle} value={communityRadio}>
                    {communityParams.reasonable.map((item, index) => {
                        return (<Radio key={item.id} value={item.id}>{item.reportTypeName}</Radio>);
                    })}
                </RadioGroup>
            );
        } else {
            return (
                <RadioGroup style={{ marginLeft: '0px', marginTop: '20px' }} onChange={this._reasonRadioChangeHandle} value={reasonRadio}>
                    {this.props.typeList.map((item, index) => {
                        return (<Radio key={item.id} value={item.id}>{item.reportTypeName}</Radio>);
                    })}
                </RadioGroup>
            );
        }
    }

    // 封禁原因单选框操作回调
    _reasonRadioChangeHandle = (e) => {
        this.setState({ reasonRadio: e.target.value });
    }
    _communityRadioChangeHandle = (e) => {
        console.log(e);
        this.setState({ communityRadio: e.target.value });
    }

    _renderRadio = (list) => {
        return list.map((item) => {
            return (<Radio key={item.code} value={item.code}>{item.value}</Radio>);
        });
    }

    render() {
        const { visible, confirmLoading, areaValue, reasonRadio } = this.state;
        // debugger;
        // console.log(this.props.typeList[0].code);
        return (
            <Modal
                width={470}
                centered
                title='禁播'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.up}>
                        <div>禁播理由：</div>
                        {this._switchHanddle()}
                    </div>
                    <div className={styles.down}>
                        <TextArea
                            className={styles.textArea}
                            placeholder='请输入禁播理由'
                            rows={4}
                            onChange={this._areaChangeHandle}
                            value={areaValue}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

VideoUnable.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]), // 需要解禁的用户ID
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    typeList: PropTypes.array,
    communityParams: PropTypes.object,// 禁播(区别视频，社区与参数ID值)
};



export default VideoUnable;