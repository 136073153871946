/**
 * 上传
 */
import React from 'react';
import { connect } from 'react-redux';
import { Modal, Radio, Form, Input, Select } from 'antd';
import { XSelect, XUpload } from '@/components/xqxc_ui';
import CusotmUpload, { generateFileList } from '@/components/NewUpload';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message, Icon } from 'antd';
import model from '@/reducers/contractmanage/contracttemplate/model';
import styles from './UploadModal.module.scss';

const { Option } = Select;
const RadioGroup = Radio.Group;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};
class SalerModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        contractName: {},
        createImgs: [],
    }
    componentDidMount() {
        this.props.getContractName();
    }
    _handleOk = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields({ force: true }, (err, formDatas) => {
            if (!err) {
                const param = {
                    ...formDatas,
                    url: formDatas.url[0].url
                };
                this.setState({ confirmLoading: true }, () => {
                    http('/contract/fadada/uploadTemplate', param, 'POST')
                        .then(() => {
                            message.success('上传成功!');
                            this.setState({
                                visible: false,
                                confirmLoading: false
                            });
                            this.props.closeMode();
                            this.props.refresh();
                        }).catch((reject) => {
                            message.error(reject.message);
                            this.setState({
                                visible: false,
                                confirmLoading: false
                            });
                            this.props.closeMode();
                        });
                });
            }
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    //上传事件
    _normFile = (e) => {
        //避免先上传不符合图片，立即提交from验证无效
        for (let i = 0; i < e.fileList.length; i++) {
            const isType = ['application/pdf'];
            const isLt3M = parseInt(e.fileList[i].size) / 1024 / 1024 < 3;
            if (e.fileList[i].size) { //在编辑页面，有默认值情况下加判断
                if (!isLt3M || !isType.includes(e.fileList[i].type)) {
                    e.fileList.splice(i, 1);
                    i--;
                }
            }
        }
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    render() {
        const { visible, confirmLoading, title, createImgs } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={600}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon={false}
                            htmlFor='code'
                            label={<span>模板名称</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('code', {
                                rules: [{ required: true, message: '请选择模板名称' }],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: 340 }}
                                    placeholder="请选择模板名称"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.props.ContractName.map(i => {
                                        return <Option key={i.constantKey} value={i.constantKey}>{i.constantName}</Option>;
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='version'
                            label={<span>版本号</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('version', {
                                initialValue: '',
                                rules: [{ required: true, message: '请输入版本号' }, { max: 20, message: '最大长度20' }],
                            })(
                                <Input placeholder='请输入' style={{ width: 340 }} />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='url'
                            label={<span>上传模板</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('url', {
                                getValueFromEvent: (e) => this._normFile(e),
                                rules: [{ required: true, message: '请上传模板' }],
                            })(
                                <CusotmUpload
                                    accept='.pdf'
                                    listType='picture-card'
                                    wallMode='single'
                                    fileList={this.state.img}
                                    qiniuUploadProps={{
                                        uploadType: 'image'
                                    }}
                                    onChange={(info) => {
                                        this.setState({ img: info.fileList });
                                    }}
                                    beforeUpload={(file) => {
                                        const isType = ['application/pdf'];
                                        const isLt3M = file.size / 1024 / 1024 < 3;
                                        if (!isType.includes(file.type)) {
                                            message.warn('建议上传pdf格式的文件!');
                                            return false;
                                        } else if (!isLt3M) {
                                            message.warn('图片必须小于 3MB!');
                                            return false;
                                        } else {
                                            return isType && isLt3M;
                                        }
                                    }}
                                >
                                    <div style={{ color: '#999', fontSize: '12px' }}>
                                        <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                        <p>上传模板</p>
                                    </div>
                                </CusotmUpload>
                                // <XUpload
                                //     uploadButton="singleImg"
                                //     handleChange={(file) => {
                                //         if (file.type !== 'application/pdf') {
                                //             message.error('请传入pdf！');
                                //             return;
                                //         }
                                //         if (file.status === 'done' && file.type == 'application/pdf') {
                                //             this.setState({
                                //                 img: file.qiniuInfo.key,
                                //             });
                                //         } //保存保存
                                //         if (file.status === 'removed') {
                                //             this.setState({
                                //                 img: '',
                                //                 createImgs: []
                                //             });
                                //         } //移除逻辑
                                //     }}
                                //     antdUploadProps={{
                                //         accept: '.pdf',
                                //         listType: 'picture-card',
                                //         showUploadList: false,
                                //         // 这个是初始化值
                                //         defaultFileList: createImgs
                                //     }}
                                // />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

SalerModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    refresh: PropTypes.func,
    ContractName: PropTypes.array,
    getContractName: PropTypes.func,
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    reasonType: PropTypes.string,
};
const mapStateToProps = (state) => ({
    ContractName: state.contracttemplate.ContractName
});
const ForgotProducDetails = Form.create({ name: 'create-form' })(SalerModal);// 校验
export default connect(mapStateToProps, { ...model.actions })(ForgotProducDetails);