
import { createAction } from 'redux-actions';
import * as T from './actiontypes';

// 存储面包屑导航路径
export const setBreadcrumbPaths = (breadcrumbPaths) => {
    const action = createAction(T.SET_BREADCRUMB_PATHS, (paths) => paths);
    return action(breadcrumbPaths);
};

// 在最后添加一级面包屑路径
export const addOneBreadcrumbPath = (path) => {
    const action = createAction(T.ADD_ONE_BREADCRUMB_PATH, (path) => path);
    return action(path);
};

// 仅保留前二级面包屑路径
export const keepSecondNavBreadcrumb = () => {
    const action = createAction(T.KEEP_SECOND_NAV_BREADCRUMB);
    return action();
};

// 保留前三级面包屑路径
export const keepThirdNavBreadcrumb = () => {
    const action = createAction(T.KEEP_THIRD_NAV_BREADCRUMB);
    return action();
};

// 保留前四级面包屑路径
export const keepFourNavBreadcrumb = () => {
    const action = createAction(T.KEEP_FOUR_NAV_BREADCRUMB);
    return action();
};

// 存储当前活动的一级导航路径
export const setActivedCardPath = (activedCardPath) => {
    const action = createAction(T.SET_ACTIVED_CARD_PATH, (path) => path);
    return action(activedCardPath);
};

// 存储当前活动的二级导航路径
export const setActivedRoutePath = (activedRoutePath) => {
    const action = createAction(T.SET_ACTIVED_ROUTE_PATH, (path) => path);
    return action(activedRoutePath);
};

// 存储当前导航栏的伸展状态
export const setStretchOpen = (openBoolean) => {
    const action = createAction(T.SET_STRETCHOPEN, (boolean) => boolean);
    return action(openBoolean);
};

// 获取导航页数据
export const getMenuList = (value = {}) => ({
    type: T.GET_MENUS_LIST,
    payload: value
});