import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XSelect, XDatePicker, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import model from '@/reducers/auditmanage/previewincomeaudit/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: [], // 当前需要打开的弹窗的入参

            supplierName: '',
            account: '',
            auditStatus: {},
            startDate: null,
            endDate: null
        };
    }

    componentDidMount() {
        this.props.getStateEnum();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { supplierName, account, auditStatus, startDate, endDate, pageNum, pageSize } = this.state;
        this.searchCache = {
            startDate,
            endDate,
            supplierName,
            account: account,
            auditState: auditStatus.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getPreviewIncomeAuditList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ supplierName: '', account: '', auditStatus: {}, startDate: null, endDate: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (id, type, item) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'audit':
                addOneBreadcrumbPath({
                    title: '商品审核',
                    path: '/home/auditmanage/previewincomeaudit/audit/' + id
                });
                history.push({
                    pathname: '/home/auditmanage/previewincomeaudit/audit/' + id
                });
                break;
            case 'detail':
                addOneBreadcrumbPath({
                    title: '审核详情',
                    path: '/home/auditmanage/previewincomeaudit/detail/' + id
                });
                history.push({
                    pathname: '/home/auditmanage/previewincomeaudit/detail/' + id
                });
                break;
        }
    }

    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { previewIncomeAuditList, stateEnum } = this.props;
        const { supplierName, account, auditStatus, startDate, endDate } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商户账号'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='supplierName'
                                value={supplierName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='转账账号'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='account'
                                value={account}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='申请时间'
                                isFormat
                                bindThis={this}
                                bindName='startDate'
                                value={startDate}
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                isFormat
                                bindThis={this}
                                bindName='endDate'
                                value={endDate}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={stateEnum}
                                bindThis={this}
                                bindName='auditStatus'
                                dataIndex='value'
                                keyIndex='code'
                                value={auditStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent paginationChange={this._paginationChange} renderData={previewIncomeAuditList} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    getPreviewIncomeAuditList: PropTypes.func,
    previewIncomeAuditList: PropTypes.object,
    getStateEnum: PropTypes.func,
    stateEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
    previewIncomeAuditList: state.previewincomeaudit.previewIncomeAuditList,
    stateEnum: state.previewincomeaudit.stateEnum
});
export default connect(mapStateToProps, { ...model.actions })(Main);