import * as T from './actiontypes';

export const getInvoiceList = (value) => ({
    type: T.GET_INVOICELIST,
    payload: value
});

export const getMerchantSelectSource = (value) => ({
    type: T.GET_MERCHANT_SELECTSOURCE,
    payload: value
});

export const getMerchantSelectSourceV1 = (value) => ({
    type: T.GET_MERCHANT_SELECTSOURCE_V1,
    payload: value
});

export const getMerchantReconciliationTableSource = (value) => ({
    type: T.GET_MERCHANTRECONCILIATION_TABLESOURCE,
    payload: value
});

export const getStateSelectSource = (value) => ({
    type: T.GET_STATE_SELECTSOURCE,
    payload: value
});

export const getDetail = (value) => ({
    type: T.GET_DETAIL,
    payload: value
});

export const getDetailTableSource = (value) => ({
    type: T.GET_DETAIL_TABLESOURCE,
    payload: value
});
// 商户对账单详情
export const getMerchantDetail = (value) => ({
    type: T.GET_MERCHANT_DETAIL,
    payload: value
});
//对账单详情中订单交易明细查询
export const getMerchantTradeDetail = (value) => ({
    type: T.GET_MERCHANT_TRADE_DETAIL,
    payload: value
});