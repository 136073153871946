/**
 * 支付场景
 */
import React from 'react';
import styles from './index.module.scss';
import TableContent from './components/TableContent';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getGiftTableSource, getGlistTableSource, } from '@/reducers/showmanage/propsmanage/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';


class Scene extends React.PureComponent {
    state = {

    }
    render() {
        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        // this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.flexBoxContainer}>
                        <TableContent />
                    </div>
                </div>
            </KeepAlive>
        );
    }
}

export default connect(null, null)(Scene);
