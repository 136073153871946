import React from 'react';
import styles from './TableData.module.scss';
import { Switch, } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
    {
        title: '名称',
        dataIndex: 'showMsg',
        key: 'showMsg',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '人气值',
        dataIndex: 'starValue',
        key: 'starValue',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '直播时长(分)',
        dataIndex: 'seconds',
        key: 'seconds',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: (second) => (tooltip(Math.round(parseInt(second) / 60)))
    },
    // {
    //     title: '充值次数',
    //     dataIndex: 'c7',
    //     key: 'c7',
    //     width: '9%',
    //     align: 'center',
    //     render: (text) => (TableCell(text))
    // },
    {
        title: '操作人',
        dataIndex: 'createUserId',
        key: 'createUserId',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        width: '12%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '9%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle))
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: '13%',
        render: (id, record) => TableAction(record, actionClickHandle)
    },
];

export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    return data;
};
// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
    let checked = true;
    if (sw == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                key={item.id + '_' + checked}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={(value) => {
                    actionClickHandle(item, 'switch', value);
                }}
            />
        </div>
    );
};


export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};

const TableCell = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text}
        </div>
    );
};

const TableAction = (record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(record, 'EDIT')}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(record, 'DELETE')}>删除</div>
        </div>
    );
};