import { createModel } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 };

const model = {
    namespace: 'platdividendincome',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getPlatDividendIncomeData': T('getPlatDividendIncomeData'),
            'getClassifyEnum': T('getClassifyEnum'),
            'getPlatDividendIncomeDetail': T('getPlatDividendIncomeDetail'),
        },
        actions: {
            'getPlatDividendIncomeData': A('getPlatDividendIncomeData'),
            'getClassifyEnum': A('getClassifyEnum'),
            'getPlatDividendIncomeDetail': A('getPlatDividendIncomeDetail'),
        },
        sagas: {
            'getPlatDividendIncomeData': S('getPlatDividendIncomeData', '/settle/financialManagement/findPlatformDividendList'),
            'getClassifyEnum': S('getClassifyEnum', '/enum/dividendsIncomeType'),
            'getPlatDividendIncomeDetail': S('getPlatDividendIncomeDetail', '/settle/financialManagement/findParticipantsList'),
        },
        reducers: {
            'platDividendIncomeData': R('getPlatDividendIncomeData', dataList),
            'classifyEnum': R('getClassifyEnum', []),
            'platDividendIncomeDetail': R('getPlatDividendIncomeDetail', dataList),
        }
    })
};
export default createModel(model);