import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import KeepAlive from '@/routes/KeepAlive';
import TypeModal from '../../modal/TypeModal';
import DeleteModal from '../../modal/DeleteModal';
import { SearchBox, SearchItem } from '@/components/Layout';
import { autoPageNum, autopageSize } from '@/assets/config';
import { Table, Switch, Icon, Input } from 'antd';
import { XSelect, XOKButton, XCancelButton, XInput, XPagination } from '@/components/xqxc_ui';
import { getCategoryTableSource } from '@/reducers/systemmanage/materialcategory/actions';
import http from '@/assets/api/http';

const Mode = {
    CREAT: 'CREAT',
    EDITE: 'EDITE',
    DELETE: 'DELETE'
};

const columns = (TableAction, actionClickHandle, type) => [
    {
        title: '分类ID',
        dataIndex: type + 'CategoryId',
        key: type + 'CategoryId',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '分类名称',
        dataIndex: type + 'CategoryName',
        key: type + 'CategoryName ',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '排序',
        dataIndex: 'sort',
        key: 'sort',
        width: '10%',
        align: 'center',
        render: (text, item) => (SortCell(text, item, actionClickHandle, type))
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle, type))
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: '10%',
        render: (id, item) => TableAction(item[type + 'CategoryId'], actionClickHandle, item)
    }
];

const TableAction = (id, actionClickHandle, record) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'edit', record)}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'delete')}>删除</div>
        </div>
    );
};

// 表格项内容为文本
const TableCell = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text}
        </div>
    );
};

// 表格项内容为可编辑
const SortCell = (value, item, actionClickHandle, type) => {
    const label = React.createRef();
    const box = React.createRef();
    const input = React.createRef();
    const id = item[type + 'CategoryId'];

    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <div className={styles.sort_box}>
                <div className={styles.label} ref={label}>{value}</div>
                <div className={styles.input} ref={box}>
                    <Input
                        key={id}
                        style={{ width: '50px' }}
                        ref={input}
                        defaultValue={value}
                        onBlur={(e) => {
                            box.current.style.display = 'none';
                            label.current.style.display = 'block';
                            if (value != e.target.value) {
                                actionClickHandle(id, 'sort', e.target.value, item);
                            }
                        }}
                    />
                </div>
                <div
                    className={styles.icon}
                    onClick={() => {
                        label.current.style.display = 'none';
                        box.current.style.display = 'block';
                        input.current.focus();
                    }}
                >
                    <Icon type="edit" style={{ fontSize: '18px' }} />
                </div>
            </div>
        </div>
    );
};

// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle, type) => {
    let checked = sw == 0 ? false : true;
    const id = item[type + 'CategoryId'];
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                key={id + item['state']}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                defaultChecked={checked}
                onChange={(value) => actionClickHandle(id, 'switch', value)}
            />
        </div>
    );
};


class MusicType extends React.PureComponent {

    state = {
        state: {},
        className: undefined,
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
    }

    // 创建直播间分类
    _createStudioRoom = () => {
        this.setState({ currentOpenMode: Mode.CREAT });
    }

    getCache = () => ({
        state: this.state.state.code,
        className: this.state.className
    });

    _searchHandle = (useCache) => {
        const { pageSize, pageNum, state, className } = this.state;
        this.searchCache = {
            ...this.getCache(),
            type: this.props.curTabID,
            isExcludeLocalUpload: '1',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getCategoryTableSource(this.searchCache); // 发起请求
        KeepAlive.saveCache({ state, className, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            state: {},
            className: undefined
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'edit':
                this.setState({
                    currentOpenMode: Mode.EDITE,
                    openModeParam: { type: this.props.curTabID, ...value }
                });
                break;
            case 'delete':
                this.setState({
                    currentOpenMode: Mode.DELETE,
                    openModeParam: { id, type: this.props.curTabID }
                });
                break;
            case 'switch':
                this.updateVals(id, { state: value === true ? 1 : 0 });
                break;
            case 'sort':
                this.updateVals(id, { sort: value });
                break;
        }
    }

    updateVals = (id, vals) => {
        const { curTabID } = this.props;
        http(`/custom/${curTabID}Category/save`, { [curTabID + 'CategoryId']: id, ...vals }).then(response => this._searchHandle('useCache'));
    }

    // 操作表格
    _actionHandle = (id, type, value, item) => {
        this._tableAction(id, type, value, item);
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.CREAT:
                return <TypeModal title={'创建' + this.props.title} visible={true} data={{ state: '1', type: this.props.curTabID }} closeMode={this._closeMode} />;
            case Mode.EDITE:
                return <TypeModal title={'编辑' + this.props.title} visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case Mode.DELETE:
                return <DeleteModal title="删除提示" visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    _closeMode = (reload) => {
        this.setState({ currentOpenMode: '' });
        if (reload) this._searchHandle();
    }

    render() {
        const { dataList, pageNum, pageSize, resultTotal } = this.props.categoryTable;
        const { state, className } = this.state;
        const { curTabID } = this.props;

        return (
            <KeepAlive id={curTabID} key={curTabID} render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XSelect
                                label='分类状态'
                                style={{ width: '216px' }}
                                selectStyle={{ width: '150px' }}
                                placeholder='请选择分类状态'
                                renderData={[{ value: '启用', code: '1' }, { value: '禁用', code: '0' }]}
                                dataIndex='value'
                                keyIndex='code'
                                value={state.value}
                                bindName='state'
                                bindThis={this}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                style={{ marginLeft: '30px', width: '216px' }}
                                inputStyle={{ width: '150px' }}
                                label='分类名称'
                                placeholder='请输入分类名称'
                                value={className}
                                bindName='className'
                                bindThis={this}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }} label={'创建' + this.props.title} onClick={this._createStudioRoom} />
                    </div>
                    <div className={styles.tableContainer}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowSelection={this._rowSelection}
                            columns={columns(TableAction, this._tableAction, this.props.curTabID)}
                            dataSource={dataList}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

MusicType.propTypes = {
    curTabID: PropTypes.string,
    title: PropTypes.string,
    tableAction: PropTypes.func,
    getCategoryTableSource: PropTypes.func,
    categoryTable: PropTypes.object
};

const mapStateToProps = (state) => ({
    categoryTable: state.materialcategory.categoryTable
});

export default connect(mapStateToProps, {
    getCategoryTableSource
})(MusicType);

