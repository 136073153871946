/**
 *  兑换设置
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput } from '@/components/xqxc_ui';
import { getchangData, } from '@/reducers/systemmanage/showset/actions';

class Change extends Component {
    constructor(props) {
        super(props);
        this.state = {
            starlight: '1',//魅力值充值比例
            conversion: '1',//人气值兑换比例
            deduction: '1',//人气值抵用比例

            startLimit: 1,//赠送好友人气值限额
            startNum: '',//赠送好友人气值限额整形数字
            paramCode: [],//固定请求参数，无参数则请求全部
        };
    }

    componentDidMount() {
        const { paramCode } = this.state;
        const { getchangData, } = this.props;
        getchangData(paramCode);
    }
    // 赠送好友人气值限额
    _startLimit = (val) => {
        this.setState({ startLimit: val.target.value });
    }
    //数据处理 
    _changeValue = (code) => {
        const { changData, } = this.props;
        let textVal = '';
        for (let i = 0; i < changData.length; i++) {
            if (code == changData[i].paramCode) {
                textVal = changData[i].paramValue;
            }
        }
        return textVal;
    }

    render() {

        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.tableContainer} style={{ overflow: 'auto' }}>
                    <div className={styles.paramers}>
                        <div className={`${styles.layout} ${styles.paramersTiems}`}>
                            <span>魅力值充值比例<i>充值1元，获得</i></span>
                            <XInput
                                style={{ width: '120px' }}
                                inputStyle={{ width: '90px', }}
                                placeholder={this._changeValue('STARLIGHT_RECHARGE_RATIO') ? '' : '暂无数据'}
                                readOnly={true}
                                isRequired={false}
                                value={this._changeValue('STARLIGHT_RECHARGE_RATIO')}
                                bindThis={this}
                                bindName='starlight'
                            />
                            <p>魅力值</p>
                            <b>系统初始化时创建，这边只读</b>
                        </div>
                        <div className={`${styles.layout} ${styles.paramersTiems}`}>
                            <span>人气值兑换比例<i>1人气值兑换</i></span>
                            <XInput
                                style={{ width: '120px' }}
                                inputStyle={{ width: '90px', }}
                                placeholder={1 / this._changeValue('STARLIGHT_EXCHANGE_STAR_VALUE_RATIO') ? '' : '暂无数据'}
                                readOnly={true}
                                isRequired={false}
                                value={1 / this._changeValue('STARLIGHT_EXCHANGE_STAR_VALUE_RATIO')}
                                bindThis={this}
                                bindName='conversion'
                            />
                            <p>魅力值</p>
                            <b>系统初始化时创建，这边只读</b>
                        </div>
                        <div className={`${styles.layout} ${styles.paramersTiems}`}>
                            <span>人气值抵用比例<i>1人气值抵用</i></span>
                            <XInput
                                style={{ width: '120px' }}
                                inputStyle={{ width: '90px', }}
                                placeholder={1 / this._changeValue('STARVALUE_RECHARGE_RATIO') ? '' : '暂无数据'}
                                readOnly={true}
                                isRequired={false}
                                value={1 / (this._changeValue('STARVALUE_RECHARGE_RATIO'))}
                                bindThis={this}
                                bindName='deduction'
                            />
                            <p>人民币（元）</p>
                            <b>系统初始化时创建，这边只读</b>
                        </div>

                        {/* <div className={`${styles.layout} ${styles.paramersTiems}`}>
                                <span style={{ marginRight: '17px', width: 'auto' }}>赠送好友人气值限额</span>
                                <Radio.Group name="startLimit" value={startLimit} className={styles.curt} onChange={this._startLimit}>
                                    <Radio value={1}>不限</Radio>
                                    <Radio value={2}>每日可送</Radio>
                                </Radio.Group>
                                <XInput
                                    style={{ width: '100px', marginRight: '20px' }}
                                    inputStyle={{ width: '90px', }}
                                    labelStyle={{}}
                                    // label='推荐数'
                                    placeholder='整形数字'
                                    isRequired={false}
                                    value={startNum}
                                    bindThis={this}
                                    bindName='startNum'
                                />
                                <b>1 - 10000   好友人气值赠送不包含秀场人气值礼物打赏</b>
                            </div> */}


                    </div>
                </div>
            </div>
        );
    }
}

Change.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,

    changData: PropTypes.array, // 数据
    getchangData: PropTypes.func,// 数据方法Action
};

const mapStateToProps = (state) => {
    return {
        changData: state.showset.showsetDate.changData,
    };
};


export default connect(mapStateToProps, { getchangData, })(Change);
