/**
 * 仓库管理saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as T from './actiontypes';
import http from '@/assets/api/http';

// 获取已分配出库单列表
export const getInvoiceList = function* () {
    yield takeEvery(T.GET_INVOICELIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/distributionOutboundOrderList', action.payload, 'POST');
            yield put({ type: T.SET_INVOICELIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// export const getDealingUnitSelectSource = function* () {
//     yield takeEvery(T.GET_DEALING_UNIT_SELECTSOURCE, function* requestData(action) {
//         try {
//             let result = yield http('/merchant/order/distributionOutboundOrderList', action.payload, 'POST');
//             yield put({ type: T.SET_DEALING_UNIT_SELECTSOURCE, payload: result });
//         } catch (e) {
//             yield put({ type: 'SAGA_ERROR', payload: { error: e } });
//         }
//     });
// };

export const getStateSelectSource = function* () {
    yield takeEvery(T.GET_STATE_SELECTSOURCE, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/distributionOutboundOrderList', action.payload, 'POST');
            yield put({ type: T.SET_STATE_SELECTSOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getPlatformReconciliationTableSource = function* () {
    yield takeEvery(T.GET_PLATFORMRECONCILIATION_TABLESOURCE, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationInfo/listPlatReconciliation', action.payload, 'POST');
            yield put({ type: T.SET_PLATFORMRECONCILIATION_TABLESOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getDetail = function* () {
    yield takeEvery(T.GET_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/reconciliation/getReconciliation', action.payload, 'POST');
            yield put({ type: T.GET_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getDetailTableSource = function* () {
    yield takeEvery(T.GET_DETAIL_TABLESOURCE, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationInfo/queryTrades', action.payload, 'POST');
            yield put({ type: T.SET_DETAIL_TABLESOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
