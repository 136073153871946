import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import c from './config';
import * as qiniu from 'qiniu-js';
import { callFn, DateFormat } from '@/assets/js/utils';
import CustomUpload from './CustomUpload';

export default class XUpload extends React.PureComponent {
    state = {
        loading: false,
        total: {
            loaded: 0,
            total: 0,
            percent: 0
        },
        uploadState: 'normal'
    };

    generateFileName = (fileName) => {
        const fileSplits = fileName.split('.');
        //return DateFormat(new Date(), `${fileSplits[0]}_yyyyMMddhhmmssS.${fileSplits[1] || ''}`);
        //return DateFormat(new Date(), `file_yyyyMMddhhmmssS.${fileSplits[1] || ''}`);
        return DateFormat(new Date(), 'file_yyyyMMddhhmmssS');
    }

    upload = (file, callback) => {
        const { onNext, onError, onComplete } = this.props.QiNiuUploadSetting || {},
            fileName = this.generateFileName(file.name),
            _this = this;
        http(this.props.type === 'media' ? (c.mediaTokenUrl + fileName) : c.imageTokenUrl).then(({ result }) => {
            let observable = qiniu.upload(file, fileName, result);
            let subscription = observable.subscribe({
                next({ total }) {
                    total.percent = Number(total.percent.toFixed(2));
                    callFn(onNext, total);
                    _this.setState({ total, uploadState: 'active' });
                    file.status = 'uploading';
                },
                error(err) {
                    file.status = 'error';
                    callFn(onError, err);
                    _this.setState({ uploadState: 'exception' });
                },
                complete(res) {
                    file.status = 'done';
                    if (_this.props.type === 'media') {
                        http(c.getFileUrl, { fileName }).then(response => {
                            callFn(onComplete, response.result, res);
                            callback({ realUrl: response.result, info: res });
                        });
                    } else {
                        http(c.getImageUrl + res.key).then(response => {
                            callFn(onComplete, response.result, res);
                            callback({ realUrl: response.result, info: res });
                        });
                    }
                }
            });
        });
    }

    render() {
        return (
            // 图片墙
            // <CustomUpload
            //     upload={this.upload}
            //     uploadButton="picture"
            //     antdUploadProps={{listType:'picture-card'}}
            //     maxFileList={2}
            //     {...this.state.total} {...this.props}
            // />

            // 单图片上传
            // <CustomUpload
            //     upload={this.upload}
            //     uploadButton="singleImg"
            //     antdUploadProps={{listType:'picture-card',showUploadList:false}}
            //     {...this.state.total} {...this.props}
            // />

            // 按钮上传，限制上传数量
            // <CustomUpload
            //     upload={this.upload}
            //     uploadButton="button"
            //     maxFileList={2}
            //     removeOverMax
            //     //antdUploadProps={{showUploadList:false}}
            //     {...this.state.total} {...this.props}
            // />

            <CustomUpload
                {...this.props}
                //{...this.state.total}
                total={{ ...this.state.total }}
                upload={this.upload}
            />
        );
    }

}

XUpload.propTypes = {
    type: PropTypes.string,
    QiNiuUploadSetting: PropTypes.object,
};