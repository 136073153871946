import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import Showpareme from '../showpareme';//秀场参数 
import Touprules from '../touprules';//充值规则
import Change from '../change';//兑换设置
import Reply from '../reply';//快速回复  
import Recommend from '../recommend';//推荐规则
import Popular from '../popular';//人气规则
import LiveDurationRechargeRules from '../livedurationrechargerules';//直播时长充值规则
import PaySettings from '../paySettings';//知识付费设置
import http from '@/assets/api/http';
import ConvertibleRules from '../convertiblerules';//魅力值兑换规则
import ShowBeanRules from '../showbeanrules';//人气值兑换规则
import TaskViolation from '../taskViolation';//代言任务违规机制

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '秀场参数' },
            { id: 2, label: '充值规则' },
            { id: 3, label: '兑换设置' },
            { id: 4, label: '快速回复' },
            { id: 5, label: '人气规则' },
            { id: 6, label: '推荐规则' },
            { id: 7, label: '知识付费设置' },
            // { id: 8, label: '直播时长充值规则' },
            { id: 10, label: '魅力值兑换规则' },
            // { id: 11, label: '信用值兑换规则' },
            { id: 12, label: '代言任务违规机制' },
        ],
        curTabID: 1,  // 默认显示的tab
        isUpdata: undefined,//由秀场参数页面-购买直播时长控制直播时长充值规则tabs显隐
    }

    componentDidMount() {
        const { history } = this.props;
        if (history.location.state && history.location.state) {
            this.setState({ curTabID: history.location.state }); // 如果路由过来的state参数带tabID值，则渲染该tabID值下的内容
        }
        this._changeAppear();//由秀场参数页面-购买直播时长控制直播时长充值规则tabs显隐
    }

    componentDidUpdate(prevProps, prevState) {
        const { isUpdata, tab } = this.state;
        if (isUpdata !== prevState.isUpdata) {
            if (isUpdata == '1') {
                tab.splice(7, 0, { id: 8, label: '直播时长充值规则' });
                this.setState({ tab });
            } else if (isUpdata == '0') {
                tab.splice(7, 1);
                this.setState({ tab });
            }
        }
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 控制直播时长充值规则tabs显隐
    _changeAppear = () => {
        const { tab } = this.state;
        http(`/admin/param/getParamValueByCode/${'IS_PURCHASE_LIVE_TIME'}`, {}, 'POST').then((res) => {
            if (res.status == '200') {
                if (res.result == '1') {
                    tab.splice(7, 0, { id: 8, label: '直播时长充值规则' });
                    this.setState({ tab });
                }
            }
        }).catch(() => { });
    }
    isPurchaseUpdata = (value) => {
        this.setState({ isUpdata: value });
    }

    // 渲染相应Tab导航下的内容 
    _renderPage = () => {
        const { curTabID } = this.state;
        switch (curTabID) {
            case 1:
                return <Showpareme curTabID={this.state.curTabID} history={this.props.history} updateContent={this.props.updateContent} isPurchaseUpdata={this.isPurchaseUpdata} />;
            case 2:
                return <Touprules curTabID={this.state.curTabID} history={this.props.history} updateContent={this.props.updateContent} />;
            case 3:
                return <Change curTabID={this.state.curTabID} history={this.props.history} updateContent={this.props.updateContent} />;
            case 4:
                return <Reply curTabID={this.state.curTabID} history={this.props.history} updateContent={this.props.updateContent} />;
            case 5:
                return <Popular curTabID={this.state.curTabID} history={this.props.history} updateContent={this.props.updateContent} />;
            case 6:
                return <Recommend curTabID={this.state.curTabID} history={this.props.history} updateContent={this.props.updateContent} />;
            case 7:
                return <PaySettings curTabID={this.state.curTabID} history={this.props.history} />;
            case 8:
                return <LiveDurationRechargeRules curTabID={this.state.curTabID} history={this.props.history} updateContent={this.props.updateContent} />;
            case 10:
                return <ConvertibleRules curTabID={this.state.curTabID} history={this.props.history} />;
            case 11:
                return <ShowBeanRules curTabID={this.state.curTabID} history={this.props.history} />;
            case 12:
                return <TaskViolation curTabID={this.state.curTabID} history={this.props.history} updateContent={this.props.updateContent} />;
            default:
                return <Showpareme curTabID={this.state.curTabID} history={this.props.history} updateContent={this.props.updateContent} />;

        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={styles.tableContainer}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 跳转其它路由页面
};

export default Main;