import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getUnionInfo = (T) => createSaga(T('getUnionInfo'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/unionUserInfoQuery/getUnionAccountInfo', { ...action.payload }, 'POST');
                let detail = {};
                if (result.result) {
                    const { auditInfoResp = {} } = result.result;
                    const commitUnionImgList = (auditInfoResp.commitUnionImgList || []).map(item => {
                        return { ...item, isFail: false, isOver: false };
                    });
                    detail = { ...result.result, auditInfoResp: { ...auditInfoResp, commitUnionImgList } };
                }
                yield put({ type: T('originalData'), payload: detail });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const unionInfo = (T) => createReducer((init) => {
    return (state = init, { type = '', payload = {} }) => {
        switch (type) {
            case T('originalData'):
                return { ...payload };
            case T('uploadImg/GET'):
                return {
                    ...state,
                    auditInfoResp: {
                        ...state.auditInfoResp,
                        commitUnionImgList: (state.auditInfoResp.commitUnionImgList || []).map(item => {
                            if (item.type === payload.type) {
                                return { ...item, ...payload };
                            }
                            return item;
                        })
                    }
                };
            default:
                return state;
        }
    };
}, { auditInfoResp: { commitUnionImgList: [] } });

const model = {
    namespace: 'merchantunion',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getUnionUserList': T('getUnionUserList'),
            'getMerchantType': T('getMerchantType'),
            'getMerchantState': T('getMerchantState'),
            'getUnionInfo': T('getUnionInfo'),
            'getDownloadList': T('getDownloadList'),
            'uploadImg': T('uploadImg'),
            'getLianlianPayData': T('getLianlianPayData'),
            'getLianlianStatusEnum': T('getLianlianStatusEnum'),

            'getHuiFuPayList': T('getHuiFuPayList'),
            'getPayStatusList': T('getPayStatusList'),
            'getMerchantInfo': T('getMerchantInfo'),
        },
        actions: {
            'getUnionUserList': A('getUnionUserList'), // 银联用户入网列表
            'getMerchantType': A('getMerchantType'), // 获取商户类型
            'getMerchantState': A('getMerchantState'), // 获取商户状态
            'getUnionInfo': A('getUnionInfo'), // 获取银联用户详情
            'getDownloadList': A('getDownloadList'), // 查看导出的协议
            'uploadImg': A('uploadImg'),
            'getLianlianPayData': A('getLianlianPayData'),
            'clearnUnionInfo': C('getUnionInfo'),
            'getLianlianStatusEnum': A('getLianlianStatusEnum'),

            'getHuiFuPayList': A('getHuiFuPayList'),
            'getPayStatusList': A('getPayStatusList'),
            'getMerchantInfo': A('getMerchantInfo'),
            'clearnMerchantInfo': C('clearnMerchantInfo'),
        },
        sagas: {
            'getUnionUserList': S('getUnionUserList', '/unionUserInfoQuery/listUnionUserInfoQuery'),
            'getMerchantType': S('getMerchantType', '/enum/MerchantTypeEnum'),
            'getMerchantState': S('getMerchantState', '/enum/UnionUserInfoLoadStatusEnum'),
            'getUnionInfo': getUnionInfo(T),
            'getDownloadList': S('getDownloadList', '/unionUserInfoQuery/exportAgreementQuery'),
            'getLianlianPayData': S('getLianlianPayData', '/llpayUserInfoQuery/listLLPayUserInfoQuery'),
            'getLianlianStatusEnum': S('getLianlianStatusEnum', '/enum/LLPayUserStatusEnum'),

            'getHuiFuPayList': S('getHuiFuPayList', '/outPayaccountQuery/listOutPayAccountInfo'),
            'getPayStatusList': S('getPayStatusList', '/enum/OutPayStatusEnum'),
            'getMerchantInfo': S('getMerchantInfo', '/merchant/query/info'),
        },
        reducers: {
            'getUnionUserList': R('getUnionUserList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getMerchantType': R('getMerchantType', []),
            'getMerchantState': R('getMerchantState', []),
            'unionInfo': unionInfo(T),
            'getDownloadList': R('getDownloadList', []),
            'lianlianPayData': R('getLianlianPayData', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'lianlianStatusEnum': R('getLianlianStatusEnum', []),

            'huiFuPayList': R('getHuiFuPayList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'payStatusList': R('getPayStatusList', []),
            'merchantInfo': R('getMerchantInfo', {}),
        }
    })
};
export default createModel(model);