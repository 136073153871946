/**
 * 仓库管理saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as T from './actiontypes';
import http from '@/assets/api/http';

// 获取已分配出库单列表
export const getInvoiceList = function* () {
    yield takeEvery(T.GET_INVOICELIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/distributionOutboundOrderList', action.payload, 'POST');
            yield put({ type: T.SET_INVOICELIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getMerchantSelectSource = function* () {
    yield takeEvery(T.GET_MERCHANT_SELECTSOURCE, function* requestData(action) {
        try {
            let result = yield http('/merchant/info/listMerchantIdAndName', action.payload, 'POST');
            yield put({ type: T.SET_MERCHANT_SELECTSOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getMerchantSelectSourceV1 = function* () {
    yield takeEvery(T.GET_MERCHANT_SELECTSOURCE_V1, function* requestData(action) {
        try {
            let result = yield http('/merchant/info/listMerchantIdAndNameByMerchantType', action.payload, 'POST');
            yield put({ type: T.SET_MERCHANT_SELECTSOURCE_V1, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getStateSelectSource = function* () {
    yield takeEvery(T.GET_STATE_SELECTSOURCE, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationInfo/getPlatReconciliationStatus', action.payload, 'POST');
            yield put({ type: T.SET_STATE_SELECTSOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getMerchantReconciliationTableSource = function* () {
    yield takeEvery(T.GET_MERCHANTRECONCILIATION_TABLESOURCE, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationInfo/queryMerchantReconciliations', action.payload, 'POST');
            yield put({ type: T.SET_MERCHANTRECONCILIATION_TABLESOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getDetail = function* () {
    yield takeEvery(T.GET_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/reconciliation/getReconciliation', action.payload, 'POST');
            yield put({ type: T.GET_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getDetailTableSource = function* () {
    yield takeEvery(T.GET_DETAIL_TABLESOURCE, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationInfo/queryTrades', action.payload, 'POST');
            yield put({ type: T.SET_DETAIL_TABLESOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 商户对账单详情
export const getMerchantDetail = function* () {
    yield takeEvery(T.GET_MERCHANT_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationInfo/getReconciliation', action.payload, 'POST');
            yield put({ type: T.SET_MERCHANT_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//对账单详情中订单交易明细查询
export const getMerchantTradeDetail = function* () {
    yield takeEvery(T.GET_MERCHANT_TRADE_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationSource/queryTrades', action.payload, 'POST');
            yield put({ type: T.SET_MERCHANT_TRADE_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};