import React, { Component } from 'react';
import { Table, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import styles from './PayTable.module.scss';
import { XPagination, XOKButton } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import moment from 'moment';

export default class PayTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],//对应的key数据
            selectedRows: [],//对应的key行数据
        };
    }
    _columns = () => [
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户类型',
            dataIndex: 'merchantType.value',
            key: 'merchantType.value',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '银联通道状态',
            dataIndex: 'unionState',
            key: 'unionState',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付通道',
            dataIndex: 'accessType',
            key: 'accessType',
            width: '12%',
            align: 'center',
            render: (text, item) => this.switchCell(text, item)
        },
        {
            title: '连连开通时间',
            dataIndex: 'registTime',
            key: 'registTime',
            width: '12%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '银联入网最晚时间',
            dataIndex: 'lastTime',
            key: 'lastTime',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: this._lastTimeCell
        },
        {
            title: '最后操作时间',
            dataIndex: 'gmtModify',
            key: 'gmtModify',
            width: '12%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: this._operateCell
        }
    ];

    //表格操作
    _operateCell = (id, item) => {
        return <div className={styles.action}>
            {
                item.status == 0 ?
                    <div className={styles.item} onClick={() => this.props.tableAction('openAndBan', { status: 1, merchantIds: [item.merchantId], accessType: item.accessType })}>启用</div> :
                    <div className={styles.item} onClick={() => this.props.tableAction('openAndBan', { status: 0, merchantIds: [item.merchantId], accessType: item.accessType })}>禁用</div>
            }
        </div>;
    }

    // 解析表格数据，使符合columns格式要求
    _takeDataSource = (data) => {
        if (data) {
            return data.map((item) => {
                item.unionState = item.unionEnable == 1 ? '可用' : '待接入';
                return item;
            });
        } else {
            return [];
        }
    }

    // 通道切换
    switchCell = (text, item) => {
        return (
            <div className={styles.switchCell}>
                <div className={styles.text}>{this.paywaylFilter(text)}</div>
                <XOKButton
                    type="dashed"
                    style={{ width: 'auto' }}
                    label='切换'
                    onClick={() => {
                        this.props.tableAction('tdswitch', item);
                    }}
                />
            </div>
        );
    };

    //过滤支付通道
    paywaylFilter = (show) => {
        const { paywayList } = this.props;
        if (paywayList.length === 0) {
            return tooltip('');
        }
        let data = paywayList.filter(v => v.code == show);
        return data[0] ? tooltip(data[0].value) : tooltip('');
    }

    // 表格单选输入
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
        this.props.selectedRowsHandle(selectedRowKeys, selectedRows);
    };

    //清除RowKeys，keys
    onClearSelctRows = () => {
        this.setState({
            selectedRowKeys: [],
            selectedRows: []
        });
    };

    //银联入网最晚时间
    _lastTimeCell = (text, item) => {
        if (item.unionEnable == 0) {
            return <div style={{ display: 'flex' }}>
                <DatePicker
                    style={{ width: '180PX' }}
                    defaultValue={text ? moment(text, 'YYYY-MM-DD HH:mm:ss') : null}
                    showTime
                    allowClear={false}
                    placeholder='请选择时间'
                    onOk={date => this.props.tableAction('timeChange', { lastTime: moment(date).format('YYYY-MM-DD HH:mm:SS'), merchantIds: [item.merchantId], accessType: item.accessType })} />
            </div>;
        }
        return '--';
    }

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.merchantId}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                    rowSelection={rowSelection}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

PayTable.propTypes = {
    renderData: PropTypes.object, // 表格数据源
    tableAction: PropTypes.func, // 表格
    paginationChange: PropTypes.func, // 分页
    selectedRowsHandle: PropTypes.func,//获取选中数据
    paywayList: PropTypes.array,//支付通道(通联、银联)数据
};