/**
 * 视频评论列表 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const videoSource = function* (){
    yield takeEvery(T.GET_VIDEO_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象
        try{
            let result = yield http('/show/comment/listFeedComments', action.payload,'POST');
            yield put({type:T.SET_VIDEO_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};


/**
 * 视频评论详情 Saga
 */
export const videoDetails = function* (){
    yield takeEvery(T.GET_VIDEODETAILS_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象 
        try{
            let result = yield http('/show/comment/getFeedCommentReply', action.payload,'POST');
            yield put({type:T.SET_VIDEODETAILS_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};


/**
 * 视频评论下拉选项数据方法 Saga
 */
export const videoSelectData = function* (){
    yield takeEvery(T.GET_VIDEOSELECT_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象 
        try{
            let result = yield http('/show/showClass/listShortVideoClass', action.payload,'POST');
            yield put({type:T.SET_VIDEOSELECT_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

/**
 * 视频评论列表查询 Saga
 */
export const videoSelectSource = function* (){
    yield takeEvery(T.GET_VIDEOSELECTSOURCE_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象 
        try{
            let result = yield http('/show/comment/listFeedComments', action.payload,'POST');
            yield put({type:T.SET_VIDEO_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};
