import React from 'react';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import Gift from '../gift';
import Glist from '../glist';

class Main extends React.Component {

    state = {
        tab:[
            {id: 1, label: '道具分类'},
            {id: 2, label: '道具列表'},
        ],
        curTabID: 1
    }
    
    // Tab导航切换操作回调 
    _tabChangeHandle = (tabID) => {
        this.setState({curTabID:tabID});
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        switch(this.state.curTabID){
            case 1:
                return <Gift curTabID={this.state.curTabID} />; // 道具分类
            case 2:
                return <Glist curTabID={this.state.curTabID} />; // 道具列表
            default:
                return <Gift curTabID={this.state.curTabID} />;
        }
    }

    render(){
        return(
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={`${styles.flexBoxContainer} ${styles.page}`}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

export default Main;