/**
 * 服务商审核
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import TableMain from './components/TableMain';
import model from '@/reducers/auditmanage/autonym/model';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';

class Facilitator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mobilePhone: '',
            stateItem: {}
        };
    }

    componentDidMount() {
        this.props.getApprovenum();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { mobilePhone, stateItem, pageNum, pageSize } = this.state;
        this.searchCache = {
            mobilePhone, auditState: stateItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getFacilitators(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ mobilePhone: '', stateItem: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }


    //表格操作
    _tableAction = (id, type) => {
        switch (type) {
            case 'detail':
                this._updateContent('认证详情', '/facilitatorinfo/', id);
                break;
            case 'audit':
                this._updateContent('认证审核', /facilitatoraudit/, id);
                break;
            default:
                break;
        }
    }

    // 路由跳转
    _updateContent = (title, url, id) => {
        const { addOneBreadcrumbPath, history } = this.props;
        addOneBreadcrumbPath({
            title,
            path: `/home/auditmanage/nameauthentication${url}`
        });
        history.push({
            pathname: `/home/auditmanage/nameauthentication${url}${id}`
        });
    }


    //认证状态搜索条件已删除
    render() {
        const { facilitators, approvenum } = this.props;
        const { mobilePhone, stateItem } = this.state;
        return (
            <KeepAlive id='facilitator' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='手机号码'
                            placeholder='请输入手机号码'
                            bindThis={this}
                            bindName='mobilePhone'
                            value={mobilePhone}
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='状态'
                            placeholder='请选择状态'
                            renderData={approvenum}
                            bindThis={this}
                            bindName='stateItem'
                            dataIndex='value'
                            keyIndex='code'
                            value={stateItem.value}
                            showSearch={true}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <TableMain
                        paginationChange={this._paginationChange}
                        renderData={facilitators}
                        tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}

Facilitator.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    getFacilitators: PropTypes.func,
    facilitators: PropTypes.object,
    getApprovenum: PropTypes.func,
    approvenum: PropTypes.array
};

const mapStateToProps = (state) => ({
    facilitators: state.autonym.facilitators,
    approvenum: state.autonym.approvenum,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, ...model.actions })(Facilitator);