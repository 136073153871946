/**
 *  计费分配比例设置
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XSelect, XOKButton, XCancelButton, XPagination } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import { getAllocationList, getSettingCode } from '@/reducers/systemmanage/allocation/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			setting: {},
		};
	}
	componentDidMount() {
		this.props.getSettingCode();
	}

	// 分页
	_paginationChange = (pageSize, pageNum) => {
		this.props.getAllocationList({ ...this.searchCache, pageSize, pageNum });
		KeepAlive.saveCache({ ...this.state, pageNum, pageSize }); // 【第3步，缓存查询状态】
	}

	// 查询触发
	_searchHandle = (useCache) => {
		const { setting, pageSize, pageNum } = this.state;
		this.searchCache = {
			status: 'CONFIRM_ING',
			name: setting.name,
			pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
			pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
		};
		this.props.getAllocationList(this.searchCache);
		KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
	}

	// 重置触发  
	_saleresetHandle = () => {
		this.setState({ setting: {} }, () => {
			KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
		});
	}

	// 表格操作功能回调
	_actionClickHandle = (id, type) => {
		switch (type) {
			case 'audit'://审核
				this.props.updateContent('audit', id);
				break;
			default:
				return null;
		}
	}

	render() {
		const { setting } = this.state;
		const { settingCode: { ratioSettingCodes = [] }, allocationList: { dataList, resultTotal, pageSize, pageNum } } = this.props;
		return (
			<KeepAlive render={(state) => {
				if (!this.state.__aliveInit) {
					this.setState({ ...state, __aliveInit: true }, () => {
						this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
					});
				}
			}}>
				<div className={styles.flexBoxContainer}>
					<SearchBox>
						<SearchItem>
							<XSelect
								label='设置项'
								placeholder='请选择'
								renderData={ratioSettingCodes}
								dataIndex='name'
								keyIndex='code'
								value={setting.name}
								showSearch={true}
								bindThis={this}
								bindName='setting'
							/>
						</SearchItem>
						<SearchItem>
							<XOKButton
								label='查询'
								onClick={this._searchHandle}
							/>
							<XCancelButton
								label='重置'
								onClick={this._saleresetHandle}
							/>
						</SearchItem>
					</SearchBox>
					<div className={styles.tableContainer}>
						<Table
							rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
							rowSelection={this._rowSelection}
							rowKey={(record, index) => index}
							columns={takeColumns(this._actionClickHandle)}
							dataSource={takeDataSource(dataList)}
							pagination={false}
							scroll={{ y: true }}
						/>
						<XPagination
							resultTotal={resultTotal}
							pageSize={pageSize}
							pageNum={pageNum}
							onChange={this._paginationChange}
						/>
					</div>
				</div>
			</KeepAlive>
		);
	}
}
Main.propTypes = {
	updateContent: PropTypes.func, // 路由跳转
	history: PropTypes.object, // 路由history
	getAllocationList: PropTypes.func,
	allocationList: PropTypes.object,
	getSettingCode: PropTypes.func,
	settingCode: PropTypes.object,
};

const mapStateToProps = (state) => {
	return {
		allocationList: state.Allocation.allocationList,
		settingCode: state.Allocation.settingCode,
	};
};

export default connect(mapStateToProps, { getAllocationList, getSettingCode })(Main);