/**
 * 【物流管理页】
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { message } from 'antd';
import CreateModal from '../../modal/CreateModal';
import LogisticsTable from './components/LogisticsTable';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { getLogisticsState, getLogisticsList } from '@/reducers/systemmanage/logistics/actions';
import http from '@/assets/api/http';
import { SearchBox, SearchItem } from '@/components/Layout';

const Mode = {
    NEWCREATE: 'NEWCREATE', // 新建物流公司
};

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expressName: '',
            expressState: {},
            selectedRows: [],
            currentOpenMode: '',
            openModeParam: {},
        };
        this.searchCache = { pageNum: 1, pageSize: 25 }; // 查询缓存
        this.tableComp = React.createRef();
    }

    componentDidMount() {
        this.props.getLogisticsState();
        this.props.getLogisticsList(this.searchCache);
    }

    // 查询触发
    _searchHandle = () => {
        const { expressName, expressState } = this.state;

        this.searchCache = {
            expressName,
            expressState: expressState.code,
            pageNum: 1,
            pageSize: 25
        };
        this.props.getLogisticsList(this.searchCache);
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            expressName: '',
            expressState: {},
            selectedRows: []
        });
        this.searchCache = { pageNum: 1, pageSize: 25 };
        this.tableComp.current._cleanAllSelected();
        this.props.getLogisticsList(this.searchCache);
    }

    // 新建按钮被点击
    _newCreateHandle = () => {
        this.setState({ currentOpenMode: Mode.NEWCREATE, });
    }

    // 表格操作功能回调
    _tableAction = (key, type) => {
        switch (type) {
            case 'openService':
                http('/fastpass/update/state', { id: key, expressState: 1 }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('开通成功');
                        this.props.getLogisticsList(this.searchCache);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
            case 'closeService':
                http('/fastpass/update/state', { id: key, expressState: 0 }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('停止成功');
                        this.props.getLogisticsList(this.searchCache);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getLogisticsList(this.searchCache);
    }

    // 打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.NEWCREATE:
                return <CreateModal title='新建物流' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' });
        if (update) {
            this.props.getLogisticsList(this.searchCache);
        }
    }

    // 批量复选框
    _rowSelection = (selectedRows) => {
        this.setState({ selectedRows });
    }

    // 批量停止服务
    _stopServiceHandle = () => {
        const { selectedRows } = this.state;
        if (selectedRows.length == 0) {
            message.error('请选择物流公司');
            return;
        }
        http('/fastpass/update/batchState', { expressIdList: selectedRows, expressState: 0 }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('批量停止成功');
                this.props.getLogisticsList(this.searchCache);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    render() {
        const { expressName, expressState } = this.state;
        const { logisticsState, logisticsList } = this.props;

        return (
            <div className={styles.flexBoxContainer}>
                <SearchBox>
                    <SearchItem>
                        <XInput
                            label='物流公司'
                            placeholder='请输入物流公司'
                            value={expressName}
                            bindThis={this}
                            bindName='expressName'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='状态'
                            placeholder='请选择状态'
                            renderData={logisticsState}
                            dataIndex='value'
                            keyIndex='code'
                            value={expressState.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='expressState'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </SearchItem>
                </SearchBox>
                <div style={{ display: 'flex', marginTop: '10px' }}>
                    <XOKButton
                        style={{ width: '80px' }}
                        label='新建'
                        onClick={this._newCreateHandle}
                    />
                    <XOKButton
                        style={{ width: '120px', marginLeft: '20px' }}
                        label='批量停止服务'
                        onClick={this._stopServiceHandle}
                    />
                </div>
                <LogisticsTable
                    ref={this.tableComp}
                    renderData={logisticsList}
                    tableAction={this._tableAction}
                    paginationChange={this._paginationChange}
                    onRowSelection={this._rowSelection}
                />
                {this._renderMode()}
            </div>
        );
    }
}
Main.propTypes = {
    getLogisticsState: PropTypes.func,
    getLogisticsList: PropTypes.func,
    logisticsState: PropTypes.array,
    logisticsList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    logisticsState: state.logistics.getLogisticsState,
    logisticsList: state.logistics.getLogisticsList,
});

export default connect(mapStateToProps, { getLogisticsState, getLogisticsList })(Main);