import React from 'react';
import moment from 'moment';
import styles from './OrderSearch.module.scss';
import PropTypes from 'prop-types';
import { SearchBox, SearchItem } from '@/components/Layout';
import { connect } from 'react-redux';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker } from '@/components/xqxc_ui';
import { getSuggestionsType, getSuggestionsPort } from '@/reducers/customermanage/suggestions/actions';

const dealStatus = [{ id: '', value: '全部' }, { id: 0, value: '待处理' }, { id: 1, value: '已处理' }];
class OrderSearch extends React.Component {

    state = {
        accountTel: '', // 会员手机
        accountNickname: '', // 会员昵称
        processState: {}, // 状态
        ideaBackCode: {},
        starTime: null, // 充值开始日期
        endTime: null, // 充值结束日期
        suggestionsPort: {}, //  反馈端口
    }
    componentDidMount() {
        const { getSuggestionsPort, getSuggestionsType } = this.props;
        getSuggestionsType(); // 意见反馈类型
        getSuggestionsPort();//反馈端口
    }
    // 查询
    _searchHandle = () => {
        const { accountTel, accountNickname, ideaBackCode, processState, starTime, endTime, suggestionsPort } = this.state;
        const param = {
            accountTel,
            accountNickname,
            ideaBackCode: ideaBackCode.ideaBackCode,
            processState: processState.id,
            starTime,
            endTime,
            appType: suggestionsPort.code
        };
        this.props.searchParam(param);
        // console.log(this.state);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            accountTel: '',
            accountNickname: '',
            ideaBackCode: {},
            processState: {},
            starTime: null,
            endTime: null,
            suggestionsPort: {},
        });
        this.props.searchParam({});
    }

    render() {
        const { accountNickname, accountTel, ideaBackCode, processState, starTime, endTime, suggestionsPort } = this.state;
        const { suggestionsType, suggestionsPorts } = this.props;
        return (
            <div className={styles.container}>
                <SearchBox>
                    <SearchItem>
                        <XInput
                            label='会员昵称'
                            placeholder='请输入会员昵称'
                            value={accountNickname}
                            bindThis={this}
                            bindName='accountNickname'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            label='手机号'
                            placeholder='请输入手机号'
                            value={accountTel}
                            bindThis={this}
                            bindName='accountTel'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='反馈类型'
                            placeholder='请选择反馈类型'
                            renderData={suggestionsType}
                            dataIndex='ideaBackName'
                            keyIndex='ideaBackCode'
                            value={ideaBackCode.ideaBackName}
                            showSearch={true}
                            bindThis={this}
                            bindName='ideaBackCode'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='处理状态'
                            placeholder='请选择处理状态'
                            renderData={dealStatus}
                            dataIndex='value'
                            keyIndex='id'
                            value={processState.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='processState'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XDatePicker
                            label='反馈时间'
                            value={starTime}
                            bindThis={this}
                            bindName='starTime'
                            isFormat
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            style={{ marginRight: 20 }}
                            label=''
                            value={endTime}
                            bindThis={this}
                            bindName='endTime'
                            isFormat
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='反馈端口'
                            placeholder='请选择反馈端口'
                            renderData={suggestionsPorts}
                            dataIndex='value'
                            keyIndex='code'
                            value={suggestionsPort.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='suggestionsPort'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </SearchItem>
                </SearchBox>
            </div>
        );
    }
}

OrderSearch.propTypes = {
    orderState: PropTypes.number,
    searchParam: PropTypes.func,
    getSuggestionsType: PropTypes.func,
    suggestionsType: PropTypes.array,
    getSuggestionsPort: PropTypes.func,
    suggestionsPorts: PropTypes.array,

};
const mapStateToProps = (state) => ({
    suggestionsType: state.Suggestions.suggestionsType,
    suggestionsPorts: state.Suggestions.suggestionsPort,
});
export default connect(mapStateToProps, { getSuggestionsType, getSuggestionsPort })(OrderSearch);