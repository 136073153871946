/**
 * 自定义预览
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Input } from 'antd';
import { getLocalStorage } from '@/assets/js/storage';
import './CustomLinkSelect.module.scss';

const AppCustomLandingUrl = `${process.env.AppCustomLandingUrl}/LinkSelect`;
const platform = `${process.env.Platform}`;

class PreviewModal extends React.PureComponent {

    state = {
        visible: false
    }

    constructor(props) {
        super(props);
        window.addEventListener('message', (e) => {
            const { mode, value } = e.data;
            mode === 'Ok' && props.onChange && props.onChange(value);
            this.setState({ visible: false });
            this.props.onClose && this.props.onClose();
        });
    }

    contentWindow

    onLoad = () => {
        const { token, userInfo } = JSON.parse(getLocalStorage('auth'));
        this.contentWindow = document.getElementById('appcustomIframe').contentWindow;
        this.contentWindow.postMessage({
            category: this.props.category,
            mode: 'init',
            platform,
            token,
            userInfo,
            parentUrl: window.location.href.split('#')[0]
        }, AppCustomLandingUrl);
    }

    onChange = (e) => {
        if (e.target.value === '') {
            this.props.onChange && this.props.onChange();
            this.contentWindow.postMessage({
                mode: 'clear',
                parentUrl: window.location.href.split('#')[0]
            }, AppCustomLandingUrl);
        }
        e.stopPropagation();
    }

    onClick = (e) => {
        const { onClick } = this.props.inputProps || {};
        this.setState({ visible: true });
        onClick && onClick(e);
    }

    render() {
        const { visible } = this.state;
        const { modalProps, inputProps, value = {} } = this.props;

        return (
            <>
                <Modal forceRender closable={false} visible={visible} title='选择链接' width={1268} bodyStyle={{ padding: 0 }} {...modalProps} footer={null}>
                    <iframe
                        id="appcustomIframe"
                        style={{ width: '1268px', height: '715px', border: 'none' }}
                        src={AppCustomLandingUrl}
                        onLoad={this.onLoad}>
                    </iframe>
                </Modal>
                {this.props.startInput !== false && <Input value={value.title} readOnly allowClear {...inputProps} onClick={this.onClick} onChange={this.onChange} />}
            </>
        );
    }
}

PreviewModal.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    modalProps: PropTypes.object,
    inputProps: PropTypes.object,
    startInput: PropTypes.bool,
    category: PropTypes.string,
};

export default PreviewModal;