/** 区域公司开通仓主 */
import React from 'react';
import { Modal } from 'antd';
import QRCode from 'qrcode.react';
import http from '@/assets/api/http';
import { isRE } from '@/assets/js/authType';
import styles from './ReQRCodeModal.module.scss';

class ReQRCodeModal extends React.Component {

    state = {
        qrcodeUrl: '',
        visible: false
    }

    componentDidMount() {
        isRE() && http('/user/account/isSalesmanPlus', {}).then(res => {
            if (res.code == 200) {
                if (!res.result) {
                    http('/user/account/getOpenSalesmanUrl/1', {}).then(res1 => {
                        if (res1.code == 200) {
                            this.setState({ qrcodeUrl: res1.result, visible: true });
                        }
                    });
                }
            }
        });
    }

    render() {

        return (
            <Modal
                width={470}
                keyboard
                centered
                destroyOnClose
                title='开通每日云仓'
                visible={this.state.visible}
                onCancel={() => this.setState({ visible: false })}
                footer={null}
            >
                <div className={styles.container}>
                    {this.state.qrcodeUrl && <QRCode
                        className={styles.qrcode}
                        value={this.state.qrcodeUrl}
                        level='Q'
                        size={250}
                    />}
                </div>
            </Modal>
        );

    }
}

export default ReQRCodeModal;