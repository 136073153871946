
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './ModalStyles.module.scss';
import { Modal, Input, InputNumber, Form, message } from 'antd';
import CustomUpload, { generateFileList } from '@/components/NewUpload';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};

const EditClassify = ({ form: { validateFields, getFieldDecorator }, closeMode, openModeParam }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [classifyIcons, setClassifyIcons] = useState(generateFileList(openModeParam.imgUrl));

    // 提交
    const _handleSubmit = (e) => {
        if (e) e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                setConfirmLoading(true);
                let data = {
                    categoryName: values.categoryName,
                    imgUrl: values.classifyIcons[0].url,
                    platRate: values.platRate,
                    agentRate: values.agentRate,
                    id: openModeParam.id,
                    categoryGrade: openModeParam.categoryGrade
                };
                setConfirmLoading(true);
                http('/goods/category/update', { categoryType: 'VIRTUAL', ...data }, 'POST')
                    .then((response) => {
                        if (response.status == 200) {
                            message.success('编辑分类操作成功');
                            closeMode(true);
                            setConfirmLoading(false);
                        }
                    }).catch((e) => {
                        message.error(e.message);
                        setConfirmLoading(false);
                    });
            }
        });
    };

    const _normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <Modal
            width={480}
            centered
            title='编辑分类'
            visible={true}
            bodyStyle={{ paddingBottom: '10px' }}
            confirmLoading={confirmLoading}
            onOk={_handleSubmit}
            onCancel={() => closeMode(false)}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <div className={styles.container}>
                <Form id='edit-classify' {...formItemLayout} onSubmit={_handleSubmit} colon={false}>
                    <Form.Item label='分类名称' >
                        {getFieldDecorator('categoryName', {
                            initialValue: openModeParam.categoryName,
                            rules: [{
                                required: true, message: '请输入分类名称'
                            }, {

                            }],
                        })(<Input placeholder='请输入分类名称' style={{ width: '280px' }} />)}
                    </Form.Item>
                    <Form.Item label="分类图标">
                        {getFieldDecorator('classifyIcons', {
                            initialValue: classifyIcons,
                            getValueFromEvent: _normFile,
                            rules: [
                                { type: 'array', required: true, message: '请上传分类图标' }
                            ],
                        })(<CustomUpload
                            accept='.png, .jpg, .jpeg, .gif'
                            listType="picture-card"
                            wallMode="single"
                            fileList={classifyIcons}
                            qiniuUploadProps={{ uploadType: 'image' }}
                            onChange={(info) => {
                                const posterUrls = info.fileList;
                                setClassifyIcons(posterUrls);
                            }}
                            startPreviewImage
                        >
                            <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                        </CustomUpload>)}
                    </Form.Item>
                    <Form.Item label='平台服务费' >
                        {getFieldDecorator('platRate', {
                            initialValue: openModeParam.platRate,
                            rules: [{
                                required: true, type: 'number', message: '请输入平台服务费'
                            }],
                        })(<InputNumber placeholder='请输入平台服务费' precision={2} style={{ width: '250px' }} />)}
                        <span className={styles.unit}>%</span>
                    </Form.Item>
                    <Form.Item label='分销服务费' >
                        {getFieldDecorator('agentRate', {
                            initialValue: openModeParam.agentRate,
                            rules: [{
                                required: true, type: 'number', message: '请输入分销服务费'
                            }],
                        })(<InputNumber placeholder='请输入分销服务费' precision={2} style={{ width: '250px' }} />)}
                        <span className={styles.unit}>%</span>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

EditClassify.propTypes = {
    form: PropTypes.object.isRequired, // 表单验证
    closeMode: PropTypes.func.isRequired,
    openModeParam: PropTypes.object.isRequired
};
const EditClassifyForm = Form.create({ name: 'edit-classify' })(EditClassify);// 校验
export default EditClassifyForm;