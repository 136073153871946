import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import BannedLimit from './pages/main/index';
import Intercept from './pages/intercept';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const tabList = [
    { id: '1', label: '封禁限制' },
    { id: '2', label: '注册拦截' }
];

const Mode = {
    History: 'History',
};
class BlackList extends React.Component {

    state = {
        curTabID: '1'
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, pageProps) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case Mode.History: // 会员信息
                addOneBreadcrumbPath({
                    title: '历史记录',
                    path: '/home/usermanage/blacklist/history'
                });
                history.push({
                    pathname: '/home/usermanage/blacklist/history',
                    state: pageProps
                });
                break;
        }
    }

    render() {
        const { curTabID, } = this.state;
        const { history } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={tabList} onChange={tab => this.setState({ curTabID: tab })} activedID={curTabID} />
                {
                    curTabID == 1 ?
                        <BannedLimit history={history} updateContent={this._updateContent} isRequestPageData={history.location.state !== 0} /> :
                        <Intercept curTabID={curTabID} history={history} />
                }
            </div>
        );
    }
}

BlackList.propTypes = {
    history: PropTypes.object, // router history对象
    match: PropTypes.object, // router match对象
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(BlackList);