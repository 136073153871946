import { Input, message, Modal, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { regFenToYuan } from '@/util/money';

const stateEnum = [{ code: 'COMPLETE', value: '审核通过' }, { code: 'NOT_PASSED', value: '审核不通过' }];

const WithdrawalAudit = ({ closeModal, withdrawalIds = [] }) => {

    const modalProps = {
        width: 460, centered: true, title: '批量初审', visible: true, destroyOnClose: true,
        keyboard: true, maskClosable: false, okText: '确认', cancelText: '取消', bodyStyle: { paddingTop: 0 }
    };

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [postStatus, setPostStatus] = useState();
    const [comment, setComment] = useState();

    useEffect(() => {
        http('/plat/withdrawal/batch/audit/query', { withdrawalIds }, 'POST')
            .then(res => {
                const { totalAmount = 0, taxAmount = 0, finalMoney = 0, size = 0 } = res.result;
                setData({ totalAmount: regFenToYuan(totalAmount), taxAmount: regFenToYuan(taxAmount), finalMoney: regFenToYuan(finalMoney), size });
            });
    }, []);

    const onSubmit = () => {
        if (!postStatus) return message.warn('请选择审核状态');
        if (!comment) return message.warn('请输入审核意见');
        setLoading(true);
        http('/plat/withdrawal/batchAudit', { postStatus, comment, targetIds: withdrawalIds }, 'POST')
            .then(res => { message.success(res.message); setLoading(false); closeModal(true); })
            .catch(err => { message.error(err.message); setLoading(true); });
    };

    return <Modal {...modalProps} confirmLoading={loading} onOk={onSubmit} onCancel={() => closeModal(false)}>
        <div className={styles.withdrawal}>
            <div className={styles.itemBox}>
                <span className={styles.label}>已选择申请单号数量：</span>
                <span className={styles.value}>{data.size}（单）</span>
            </div>
            <div className={styles.itemBox}>
                <span className={styles.label}>申请总金额：</span>
                <span className={styles.value}>{data.totalAmount}（元）</span>
            </div>
            <div className={styles.itemBox}>
                <span className={styles.label}>总个税：</span>
                <span className={styles.value}>{data.taxAmount}（元）</span>
            </div>
            <div className={styles.itemBox}>
                <span className={styles.label}>总实际金额：</span>
                <span className={styles.value}>{data.finalMoney}（元）</span>
            </div>
            <div className={styles.itemBox}>
                <span className={styles.label}>审核状态：</span>
                <Radio.Group value={postStatus} onChange={e => setPostStatus(e.target.value)}>
                    {stateEnum.map(i => { return <Radio key={i.code} value={i.code}>{i.value}</Radio>; })}
                </Radio.Group>
            </div>
            <div className={styles.itemBox}>
                <span className={styles.label}>审核意见：</span>
                <Input.TextArea className={styles.textArea} placeholder='请输入审核意见' value={comment} onChange={e => setComment(e.target.value)} />
            </div>
        </div>
    </Modal>;
};
WithdrawalAudit.propTypes = {
    closeModal: PropTypes.func,
    withdrawalIds: PropTypes.array
};
export default WithdrawalAudit;