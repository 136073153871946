/**
 * 讲师新增
 */
import React from 'react';
import { Modal, } from 'antd';
import { connect } from 'react-redux';
import { XInput, XOKButton, XCancelButton, } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './LeturesModal.module.scss';
import { message, Radio } from 'antd';
import LeturesCreate from '../components/LeturesCreate';
import model from '@/reducers/commercialcollege/lecturersmanage/model';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;

class Letures extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        selectedRowKeys: [],//对应的key数据
        selectedRows: [],//选中商品数据

        lecture: '',//讲师名称
        host: '',//主播账号
        hostName: '',//主播昵称
        radioValue: 1,//状态
        pageNum: 1,
        pageSize: 10,
        rowKeys: [],//选中的keys
    }

    componentDidMount() {
        const { pageNum, pageSize, } = this.state;
        this.props.getLecturersTable({ pageNum, pageSize });
    }

    _handleOk = () => {
        const { rowKeys, lecture, radioValue } = this.state;
        const [key] = rowKeys;
        if (lecture.length === 0) return message.warn('请输入讲师名称。');
        if (rowKeys.length === 0) return message.warn('请选择主播。');

        this.setState({ confirmLoading: true }, () => {
            http('/show/anchor/addAccountBlack', {
                accountId: key,
                anchorName: lecture,
                state: radioValue
            }, 'POST')
                .then(() => {
                    message.success('新建成功。');
                    this.setState({
                        visible: false,
                        confirmLoading: false
                    });
                    this.props.closeMode(true);
                }).catch((reject = {}) => {
                    this.setState({ confirmLoading: false });
                    message.error(reject.message);
                });
        });
    }
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = {
            ...this.searchCache,
            pageSize, pageNum
        };
        this.setState({
            pageSize, pageNum
        });
        this.props.getLecturersTable(this.searchCache);
    }

    // 查询触发
    _searchHandle = () => {
        const { pageNum, pageSize, host, hostName, } = this.state;
        this.searchCache = {
            nickname: hostName,//主播昵称
            tel: host,//主播账号
            pageNum,
            pageSize,
        };
        this.props.getLecturersTable(this.searchCache);
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ host: '', hostName: '' }, () => {
            const { host, hostName } = this.state;
            this.searchCache = { ...this.searchCache, nickname: host, tel: hostName };
        });
    }
    // Radio状态改变时触发
    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }
    //验证-匹配讲师名称
    lectureChange = (value) => {
        if (value.length > 20) {
            this.setState({
                lecture: value.substring(0, 20)
            });
        } else {
            this.setState({
                lecture: value
            });
        }
    }

    //选中的keys
    rowKeys = (data) => {
        this.setState({
            rowKeys: data
        });
    }
    render() {
        const { visible, title, confirmLoading, lecture, host, hostName, radioValue } = this.state;
        const { tableSouce } = this.props;
        return (
            <Modal
                width={860}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                onOk={this._handleOk}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                okText='确定'
                cancelText='取消'
            >

                <div className={styles.container}>
                    <div className={`${styles.layout} ${styles.dataTop}`}>
                        <XInput
                            inputStyle={{ width: '729px' }}
                            label='讲师名称'
                            placeholder='请输入讲师名称'
                            value={lecture}
                            isRequired={true}
                            onChange={this.lectureChange}
                            suffix={lecture.length < '21' && `(${lecture.length}/20)`}
                        />
                    </div>
                    <div className={styles.host}><i>*</i>选择主播：<span>请从下面表格选择主播</span></div>
                    <div className={`${styles.layout} ${styles.paddingLeft}`}>
                        <XInput
                            label='主播昵称'
                            placeholder='请输入主播昵称'
                            value={hostName}
                            bindThis={this}
                            bindName='hostName'
                        />
                        <XInput
                            label='主播账号'
                            placeholder='请选择主播账号'
                            value={host}
                            bindThis={this}
                            bindName='host'
                        />
                        <XOKButton
                            style={{ width: '60px' }}
                            label='搜索'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <LeturesCreate paginationChange={this._paginationChange} renderData={tableSouce} rowKeys={this.rowKeys} />
                    <div className={styles.radio}>
                        <div className={styles.label}><i>*</i>状态</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={radioValue}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                </div>
            </Modal>
        );
    }
}

Letures.propTypes = {
    title: PropTypes.string,// 弹框标题
    data: PropTypes.object, //数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数

    tableSouce: PropTypes.object,
    getLecturersTable: PropTypes.func,//表格数据(action)
};

const mapStateToProps = (state) => ({
    tableSouce: state.lecturers.tableSouce, // 表格数据
});

export default connect(mapStateToProps, { ...model.actions })(Letures);