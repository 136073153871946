/**
 *任务分类编辑弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { InputNumber, Modal, Radio, message } from 'antd';
import styles from './CreateOrEdit.module.scss';
import { XInput } from '@/components/xqxc_ui';
import CustomUpload, { generateFileList } from '@/components/NewUpload';

const RadioGroup = Radio.Group;
class CreateOrEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // title: this.props.title,
            // visible: this.props.visible,
            confirmLoading: false,

            categoryTitle: '',
            state: '',
            classifyImgs: [],
        };
    }
    componentDidMount() {
        const { data, title, visible } = this.props;
        this.setState({
            title,
            visible,
            categoryTitle: data.tableItem.categoryTitle,
            state: data.tableItem.state,
            classifyImgs: generateFileList(data.tableItem.categoryImg),
        });
    }

    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST').then(() => {
            message.success(winTxt);
            this.setState({
                visible: false,
                confirmLoading: false
            });
            this.props.closeMode(true);
        }).catch((error) => {
            message.error(error.message);
            this.setState({
                confirmLoading: false
            });
        });
    }
    // 点击确认
    _handleOk = () => {
        const { url, action, tableItem } = this.props.data;
        const { categoryTitle, state, classifyImgs } = this.state;
        switch (action) {
            case 'create':
                this.setState({ confirmLoading: true }, () => {
                    this._sendReq(url, { categoryTitle, state, categoryImg: (classifyImgs[0] || {}).url }, '创建任务分类成功。');
                });
                break;
            case 'edit':
                this.setState({ confirmLoading: true }, () => {
                    this._sendReq(url, { categoryTitle, state, categoryImg: (classifyImgs[0] || {}).url, id: tableItem.id }, '编辑任务分类成功。');
                });
                break;
        }
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    // 状态启用或禁用单选框操作回调
    _stateChange = (e) => {
        this.setState({ state: e.target.value });
    }

    _inputChange = (value, type) => {
        switch (type) {
            case 'categoryTitle':
                this.setState({ categoryTitle: value });
                break;
            default:
                break;
        }
    }

    render() {
        const { visible, confirmLoading, title, categoryTitle, state, classifyImgs } = this.state;

        return (
            <Modal
                width={500}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '408px' }}
                        inputStyle={{ width: '340px' }}
                        label='分类名称'
                        placeholder='请输入'
                        onChange={(value) => this._inputChange(value, 'categoryTitle')}
                        value={categoryTitle}
                        maxLength={64}
                    />
                    <div className={styles.radio}>
                        <div className={styles.label}>状态</div>
                        <RadioGroup onChange={this._stateChange} value={state}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.upload}>
                        <div className={styles.label}>分类图标</div>
                        <div className={styles.icon}>
                            <CustomUpload
                                accept='.png, .jpg, .jpeg'
                                listType="picture-card"
                                wallMode="single"
                                fileList={classifyImgs}
                                qiniuUploadProps={{
                                    uploadType: 'image'
                                }}
                                onChange={(info) => {
                                    this.setState({ classifyImgs: info.fileList });
                                }}
                            >
                                <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                            </CustomUpload>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

CreateOrEdit.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

export default CreateOrEdit;