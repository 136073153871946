
/**
 * 人气值兑换魅力值比率
 */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import styles from './index.module.scss';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { connect } from 'react-redux';

class Conversion extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <Main history={this.props.history} match={this.props.match} />
            </div>
        );
    }
}

Conversion.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};


export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Conversion);