import * as T from './actiontypes';

/**
 * 获取物流公司状态
 */
export const getLogisticsState = (state = [], action) => {
    switch (action.type) {
        case T.SET_LOGISTICS_STATE:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取物流公司列表
 */
const logisticsListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const getLogisticsList = (state = logisticsListInital, action) => {
    switch (action.type) {
        case T.SET_LOGISTICS_LIST:
            return action.payload.result;
        default:
            return state;
    }
};