/**
 * 路由配置
 */
import Login from '../views/login';
import Home from '../views/home';
import Welcome from '../views/home/welcome';
import Setting from '../views/setting';
import PayFail from '../views/systemmanage/financialsetting/pages/AllPay/create/components/PayFail';
import PaySucess from '../views/systemmanage/financialsetting/pages/AllPay/create/components/PaySucess';
import AsyncComponent from './asyncComponent.js';
import { DataGraph, DataFinancial } from '../views/workbench';
import { OperationList, CompanyList, Salesman, Liwu } from '../views/operationcenter';
import { merchantlist, merchantsHotel, merchantUnion, CityBeautyOpen, BusinessClassList } from '../views/businessmanage';
import { storeList, hotelList, CityOpen, StoreUnion } from '../views/storemanage';
import { Servicehistory } from '../views/servicelistingmanage';
import { ProductBrand, ProductClassify, ProductList, ProductExplainlist, ProductLabellist, WholesaleGoods, OperateRecord, StandardProduct, ConsumerKnows } from '../views/productmanage';
import { ProductOrder, StarOrder, ShopOrder, Transaction, FreeSheet, SubscriptionOrder, ServiceOrder, Stockearorder, ValueServiceOrder, Platformorder, HotelOrder, Cityorder, ThirdOrder } from '../views/ordermanage';
import { AppCustom, Activities, ActiveStarMall, ActiveShowMall, CardTicket, Advocacytask, XiuBei, Fightrecord, ProductGroup, Consignments } from '../views/marketingmanage';
import { TaskList, TaskClassify } from '../views/taskmanage';
import { salelist, salereasons, wholesale } from '../views/salemanage';
import { Offlinelist, Onlinelist, Contracttemplate } from '../views/contractmanagement';
import { StarValueClassify, StarValueList, ForRecord, StockDividends, MerchantDrawApply, Redemption, FranchiseValue } from '../views/starValueShop';
import { showReport, productReport, reportReason, userReport } from '../views/reportmanage';
import { messageClassify, messageCenter, messageMove } from '../views/messagemanage';
import { advertPlacement, advertStrateg, advertList, advertComment, advertClassify, advertService } from '../views/advertmanage';
import { Member, MemberMlx, TVAnchor, Service, Blacklist, Consumer, ConversionRe, Corporateanchor, Saler, Org, GiftMember } from '../views/usermanage';
import { supplierlist, PurchaseContract, Access } from '../views/suppliermanage';
import { EmployeeList, AssignAuthority, menuConfigure, structureManage } from '../views/permissionsmanage';
import { ChannelClassification, ChannelList, PropsManage, StickyPaper, Music, Presentmanagement, Atmospheremanagement, Forbitrecord } from '../views/showmanage';
import { ProductComment, VideoComment, CommunityComment, Sensitivewords, Hotelcomment } from '../views/commentmanage';
import { Classification, List, Noticecategory, Noticelists, Initdesclist } from '../views/xqstrategy';
import { GraphicsList, GraphicsCategory } from '../views/graphicsmanage';
import {
    NicknameAvatar, NameAuthentication, TVAnchorAudit, PornographyAudit, VideoAudit, CommunityAudit, ProductAudit, BusinessAudit,
    ActivityProductAudit, BrandAudit, CompanyInvoice, OperationInvoice, MemberWithdrawal, MarketAudit,
    MemberStar, ServiceWithdrawal, Illegal, SupplierAudit, AllocationAudit, AdvertPlacementAudit, PurchasePactAudit, AdvocacyTask,
    PayOrder, EnterpriseAnchor, SalesWithdrawal, PlatGoodsAudit, CommodityReview, StockRight, PreviewIncomeAudit, UpgradeGiftMember, UpgradeAreaCompany, ChannelAudit,
    businessCategory
} from '../views/auditmanage';
import { workOrder, feedbackType, customerList, suggestions } from '../views/customermanage';
import {
    bankAccount, paymanage, merchantreconciliation, merchantinvoicemanage, regionalcompanyreconciliation, regionalcompanyinvoicemanage,
    operatingcenterreconciliation, operatingcenterinvoicemanage, taxdeclaration, BillQuery, Exchangemanage, Merchantwithdrawalrecords,
    ReconciliationData, DailyStatement, DailyStatement2, TonlyAccountPay, payrollbillinglist, FreeOrderRefund, PlatformDrawalRecords,
    Taxcertificate, DataCenter, ThirdReconciliation, Invoicemaintain, Cashmaintain, MarketingAmountManage, PreviewIncomeManage,
    PreviewCollectionManage, PlatDividendIncome, ChannelImprest, ChannelCredit, SubsidiesIncome, BudgetList, SignRecord, WithdrawRecord, Otherpay
} from '../views/storehousefinancialmanage';
import {
    OperationMember, OperationOrder, CompanyOrder, MerchantBusiness, Payment, PlatServiceFee, TechServiceFee,
    ShowMonthly, ActivityCost, ShowstarLight, SaleReasion, GoodsCategory, CompanyCanvas, CompanyIncome, GoodsTotalCase, StatistiShow, ServiceGrade,
} from '../views/statistical';
import {
    AppFitting, CustomFitting, MaterialCategory, MaterialList, StoreFitting, Logistics, Charmgrade, Membersgrade, Showset,
    Financial, Marketing, Starmarketing, CostName, Mallsetting, Riskcontrol, Allocation, Financialsetting, Version, Watermark,
    ConvertiblePro, MerchantPermission, Servicesetting, ShowBeanRules, Templateclassify, Template, ShowDecorate, ShopDecorate,
    LanguagePack, HotCity, HotelOrderSys, PaymentChannel, PremiumYield, SuppliesSet, PrinterSetup
} from '../views/systemmanage';
import { GroupManage, LecturerManage } from '../views/commercialcollege';
import { LiveList } from '../views/commercialcollege';
import { KChannelClassify, KChannellList } from '../views/knowledgepay';
import { TemplateForm, RecordsForm } from '../views/statementmanage';
import { FacilityManage, HomestayLayout, StarLevelManage, CancelReasons } from '../views/hotelmanage';
import { ServiceListAccrued } from '../views/accruedService';
import { BeanDetail } from '../views/showbeanmanage';
import {
    DistributionSale, OperatingCenterDistributionSalePurchase, RegionalCompanyDistributionSalePurchase, ServiceProviderDistributionRank,
    Operationshare, Companyshare, Serviceshare, Goodshare
} from '../views/statisticsofdistributor';
import { HouseList, HouseClassify } from '../views/houseresourcemanage';
import { ReceiveOrderList } from '../views/receiveordermanage';
import { ServiceBill, UnitBill, ServiceMerchantOrder, PlatOrderDetail, MemberData, ServiceData, SaleAfter } from '../views/storehousefinancialmanage/datacenter';
import { ChannelManageList, LevelSetting } from '../views/channelmanage';
import { QuantityStatistics, ActivityTypeStatistics, StatisSaleOrder, StatisTopGoods, StatisStorehouseSaleData, StatisStorehouseAfterSaleData, MarketingActivityStatistics, OrderStatistics } from '../views/statisticsinfo';


// 工作台
const workbench = [
    { path: '/datagraph', title: '数据统计', component: DataGraph },
    { path: '/datafinancial', title: '会员分析', component: DataFinancial },
];

// 运营中心管理
const operationcenterRoutes = [
    { path: '/operationList', title: '运营中心列表', component: OperationList },
    { path: '/operationList/create', title: '新建运营中心', component: AsyncComponent(() => import('@/views/operationcenter/operationlist/pages/create')), show: 'none' },
    { path: '/operationList/edit/:id', title: '编辑运营中心', component: AsyncComponent(() => import('@/views/operationcenter/operationlist/pages/edit')), show: 'none' },
    { path: '/operationList/detail/:id', title: '运营中心详情', component: AsyncComponent(() => import('@/views/operationcenter/operationlist/pages/detail')), show: 'none' },
    { path: '/operationList/budget/:id', title: '运营中心收支明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/budgetlist')), show: 'none' },
    { path: '/companyList', title: '区域公司列表', component: CompanyList },
    { path: '/companyList/create', title: '新建区域公司', component: AsyncComponent(() => import('@/views/operationcenter/companylist/pages/create')), show: 'none' },
    { path: '/companyList/edit/:id', title: '编辑区域公司', component: AsyncComponent(() => import('@/views/operationcenter/companylist/pages/edit')), show: 'none' },
    { path: '/companyList/detail/:id', title: '区域公司详情', component: AsyncComponent(() => import('@/views/operationcenter/companylist/pages/detail')), show: 'none' },
    { path: '/companyList/budget/:id', title: '区域公司收支明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/budgetlist')), show: 'none' },
    { path: '/servicelist', title: '增值服务', component: ServiceListAccrued },
    { path: '/servicelist/detailInfo/:id', title: '增值服务详情', component: AsyncComponent(() => import('@/views/accruedService/serviceList/pages/detailInfo')), show: 'none' },
    { path: '/servicelist/editInfo/:id', title: '编辑增值服务', component: AsyncComponent(() => import('@/views/accruedService/serviceList/pages/editInfo')), show: 'none' },
    { path: '/servicelist/create', title: '新建服务', component: AsyncComponent(() => import('@/views/servicemanage/servicelist/pages/create')), show: 'none' },//新建
    { path: '/servicelist/edit/:id', title: '编辑服务', component: AsyncComponent(() => import('@/views/servicemanage/servicelist/pages/edit')), show: 'none' },//编辑
    { path: '/servicelist/detail/:id', title: '服务详情', component: AsyncComponent(() => import('@/views/servicemanage/servicelist/pages/detail')), show: 'none' },//详情
    { path: '/servicelist/ratemodification', title: '上架确认服务清单', component: AsyncComponent(() => import('@/views/servicemanage/servicelist/pages/main/components/BatchPutAway')), show: 'none' },
    { path: '/salesman', title: '业务账号', component: Salesman },
    { path: '/liwu', title: '礼屋业绩', component: Liwu },
    { path: '/liwu/detail/:id', title: '核对单详情', component: AsyncComponent(() => import('@/views/operationcenter/liwu/detail')), show: 'none' },
    { path: '/liwu/orders/:id/:areaCompanyId', title: '核验明细', component: AsyncComponent(() => import('@/views/operationcenter/liwu/orders')), show: 'none' },
];

// 商户管理  
const businessmanageRoutes = [
    { path: '/merchantlist', title: '商户列表', component: merchantlist },
    { path: '/merchantlist/detailInfo/:id', title: '商户详情', component: AsyncComponent(() => import('@/views/businessmanage/merchantlist/detailInfo')), show: 'none' },
    { path: '/merchantlist/editorInfo/:id', title: '编辑商户', component: AsyncComponent(() => import('@/views/businessmanage/merchantlist/editorInfo')), show: 'none' },
    { path: '/merchantlist/createInfo', title: '新建商户', component: AsyncComponent(() => import('@/views/businessmanage/merchantlist/createInfo')), show: 'none' },
    { path: '/merchantlist/shopInfo/:id', title: '店铺列表', component: AsyncComponent(() => import('@/views/businessmanage/merchantlist/shopInfo')), show: 'none' },
    { path: '/merchantlist/memberlist', title: '商户推荐会员列表', component: AsyncComponent(() => import('@/views/businessmanage/merchantlist/memberlist')), show: 'none' },
    { path: '/merchantlist/earninfo', title: '商户会员收益明细', component: AsyncComponent(() => import('@/views/businessmanage/merchantlist/returnsinfo')), show: 'none' },
    { path: '/merchantlist/settlementChangeLogs', title: '商户结算修改记录', component: AsyncComponent(() => import('@/views/businessmanage/merchantlist/settlementChangeLogs')), show: 'none' },
    { path: '/merchantlist/settlementChangeLogs/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/businessmanage/merchantlist/settlementChangeLogs/detail')), show: 'none' },

    { path: '/merchantsHotel', title: '酒店商户', component: merchantsHotel },
    { path: '/merchantsHotel/detailInfo/:merchantId', title: '酒店详情', component: AsyncComponent(() => import('@/views/businessmanage/merchantsHotel/detailInfo')), show: 'none' },
    { path: '/merchantsHotel/editorInfo/:merchantId', title: '编辑酒店商户', component: AsyncComponent(() => import('@/views/businessmanage/merchantsHotel/editorInfo')), show: 'none' },
    { path: '/merchantsHotel/createInfo', title: '新建酒店商户', component: AsyncComponent(() => import('@/views/businessmanage/merchantsHotel/createInfo')), show: 'none' },
    { path: '/merchantsHotel/shopInfo/:id', title: '店铺列表', component: AsyncComponent(() => import('@/views/businessmanage/merchantsHotel/shopInfo')), show: 'none' },
    { path: '/merchantsHotel/memberlist/:type', title: '商户推荐会员列表', component: AsyncComponent(() => import('@/views/businessmanage/merchantlist/memberlist')), show: 'none' },
    { path: '/merchantsHotel/earninfo', title: '商户会员收益明细', component: AsyncComponent(() => import('@/views/businessmanage/merchantlist/returnsinfo')), show: 'none' },
    { path: '/merchantUnion', title: '入网查询', component: merchantUnion },
    { path: '/merchantUnion/detail/:id/:accessType', title: '查看详情', component: AsyncComponent(() => import('@/views/businessmanage/merchantunion/pages/detail')), show: 'none' },
    { path: '/merchantUnion/huifupaydetail/:id/:accessType', title: '查看详情', component: AsyncComponent(() => import('@/views/businessmanage/merchantunion/huifupay/detail')), show: 'none' },
    { path: '/citybeautyopen', title: '同城/美居开城配置', component: CityBeautyOpen },
    { path: '/citybeautyopen/record/:title/:cityOpenType/:cityId', title: '操作日志', component: AsyncComponent(() => import('@/views/businessmanage/citybeautyopen/recordlist')), show: 'none' },
    { path: '/businessclasslist', title: '经营类目', component: BusinessClassList },
];

// 商品管理
const productmanageRoutes = [
    { path: '/productlist', title: '商品列表', component: ProductList },
    { path: '/productlist/detail/:id', title: '商品详情', component: AsyncComponent(() => import('@/views/productmanage/list/pages/detail')), show: 'none' },
    { path: '/productlist/recommend', title: '推荐列表', component: AsyncComponent(() => import('@/views/productmanage/list/pages/recommend')), show: 'none' },
    { path: '/productbrand', title: '商品品牌', component: ProductBrand },
    { path: '/standardproduct', title: '标准商品库', component: StandardProduct },
    { path: '/standardproduct/detail/:id', title: '标准商品详情', component: AsyncComponent(() => import('@/views/productmanage/standardproduct/pages/standardDetail')), show: 'none' },
    { path: '/standardproduct/create', title: '新建标准商品', component: AsyncComponent(() => import('@/views/productmanage/standardproduct/pages/standardAdd')), show: 'none' },
    { path: '/standardproduct/edit/:id', title: '编辑标准商品', component: AsyncComponent(() => import('@/views/productmanage/standardproduct/pages/standardUpdate')), show: 'none' },

    { path: '/productclassify', title: '商品分类设置', component: ProductClassify },
    { path: '/productexplainlist', title: '服务说明列表', component: ProductExplainlist },
    { path: '/productlabellist', title: '商品标签列表', component: ProductLabellist },
    { path: '/wholesalegoods', title: '平台批发商品列表', component: WholesaleGoods },
    { path: '/wholesalegoods/distribution', title: '查看所有分销SKU', component: AsyncComponent(() => import('@/views/productmanage/wholesalegoods/distribution')), show: 'none' },
    { path: '/wholesalegoods/recommend', title: '分销推荐列表', component: AsyncComponent(() => import('@/views/productmanage/wholesalegoods/recommend')), show: 'none' },
    { path: '/wholesalegoods/goodsinfo/:id', title: '商品详情', component: AsyncComponent(() => import('@/views/productmanage/wholesalegoods/goodsinfo')), show: 'none' },
    { path: '/operaterecord', title: '分销商品操作日志', component: OperateRecord },
    { path: '/consumerknows', title: '消费者权益管理', component: ConsumerKnows },
];

// 订单管理
const ordermanageRoutes = [
    { path: '/productorder', title: '商品订单', component: ProductOrder },
    { path: '/productorder/orderhistory', title: '操作记录', component: AsyncComponent(() => import('@/views/ordermanage/productorder/pages/orderhistory')), show: 'none' },
    { path: '/productorder/workorder', title: '新建工单', component: AsyncComponent(() => import('@/views/ordermanage/productorder/pages/workorder')), show: 'none' },
    { path: '/productorder/orderdetail', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/productorder/pages/orderdetail')), show: 'none' },
    { path: '/productorder/costDetail/:id', title: '费用明细', component: AsyncComponent(() => import('@/views/ordermanage/transaction/pages/detail/AdvertTable')), show: 'none' },
    { path: '/starorder', title: '魅力值订单', component: StarOrder },
    { path: '/shoporder', title: '兑换商城订单', component: ShopOrder },
    { path: '/shoporder/orderhistory', title: '操作记录', component: AsyncComponent(() => import('@/views/ordermanage/shoporder/pages/orderhistory')), show: 'none' },
    { path: '/shoporder/orderdetail', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/shoporder/pages/orderdetail')), show: 'none' },
    { path: '/transaction', title: '订单交易明细', component: Transaction },
    { path: '/transaction/contentInfo/:id/:saleType', title: '售后详情', component: AsyncComponent(() => import('@/views/salemanage/salelist/pages/contentInfo')), show: 'none' },
    { path: '/transaction/costDetail/:id', title: '费用明细', component: AsyncComponent(() => import('@/views/ordermanage/transaction/pages/detail/AdvertTable')), show: 'none' },
    { path: '/freesheet', title: '免单看板', component: FreeSheet },
    { path: '/freesheet/freeKanban', title: '免单商品', component: AsyncComponent(() => import('@/views/ordermanage/freesheet/pages/freeKanban')), show: 'none' },
    { path: '/subscriptionorder', title: '集采订单', component: SubscriptionOrder },
    { path: '/subscriptionorder/detail', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/subscriptionorder/pages/detail')), show: 'none' },
    { path: '/hotelorder', title: '酒店订单', component: HotelOrder },
    { path: '/hotelorder/orderhistory', title: '操作记录', component: AsyncComponent(() => import('@/views/ordermanage/hotelorder/pages/orderhistory')), show: 'none' },
    { path: '/hotelorder/orderdetail', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/hotelorder/pages/orderdetail')), show: 'none' },
    { path: '/stockearorder', title: '股票收益订单', component: Stockearorder },
    { path: '/valueserviceorder', title: '增值服务订单', component: ValueServiceOrder },
    { path: '/valueserviceorder/orderdetail', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/valueserviceorder/pages/orderdetail')), show: 'none' },
    { path: '/valueserviceorder/costDetail/:id', title: '费用明细', component: AsyncComponent(() => import('@/views/ordermanage/transaction/pages/servicedetail/AdvertTable')), show: 'none' },
    { path: '/valueserviceorder/serviceorder/orderdetail', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/productorder/pages/orderdetail')), show: 'none' },
    { path: '/valueserviceorder/distributionorder/orderdetail', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/valueserviceorder/pages/distributionorderdetail')), show: 'none' },
    { path: '/valueserviceorder/orderhistory', title: '历史操作记录', component: AsyncComponent(() => import('@/views/ordermanage/productorder/pages/orderhistory')), show: 'none' },
    { path: '/platformorder', title: '平台批发订单', component: Platformorder },
    { path: '/platformorder/orderhistory', title: '操作记录', component: AsyncComponent(() => import('@/views/ordermanage/platformorder/pages/orderhistory')), show: 'none' },
    { path: '/platformorder/orderdetail', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/platformorder/pages/orderdetail')), show: 'none' },
    { path: '/cityorder', title: '同城订单', component: Cityorder },
    { path: '/cityorder/orderhistory', title: '操作记录', component: AsyncComponent(() => import('@/views/ordermanage/cityorder/pages/orderhistory')), show: 'none' },
    { path: '/cityorder/orderdetail', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/cityorder/pages/orderdetail')), show: 'none' },
    { path: '/servicemerchantorder', title: '服务商订单', component: ServiceMerchantOrder },
    { path: '/platorderdetail', title: '平台订单明细', component: PlatOrderDetail },
    { path: '/platorderdetail/orderdetail/:sourceOrderId/:source', title: '查看详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/datacenter/tab5/orderdetail')), show: 'none' },
    { path: '/platorderdetail/orderhistory/:sourceOrderId/:source', title: '操作记录', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/datacenter/tab5/orderhistory')), show: 'none' },
    { path: '/saleafter', title: '售后数据', component: SaleAfter },
    { path: '/thirdorder', title: '第三方订单', component: ThirdOrder },
    { path: '/thirdorder/detail/:id', title: '订单详情', component: AsyncComponent(() => import('@/views/ordermanage/thirdorder/orderdetail')), show: 'none' },
];

// 营销管理 
const marketingmanageRoutes = [
    { path: '/appcustom', title: '每日说界面管理', component: AppCustom },
    { path: '/appcustom/customentrance/', title: 'APP模版选择', component: AsyncComponent(() => import('@/views/marketingmanage/appcustom/pages/customentrance')), show: 'none' },
    { path: '/appcustom/customlanding/', title: 'APP自定义编辑', component: AsyncComponent(() => import('@/views/marketingmanage/appcustom/pages/customlanding')), show: 'none' },
    { path: '/appcustom/customlist/', title: 'APP自定义列表', component: AsyncComponent(() => import('@/views/marketingmanage/appcustom/pages/customlist')), show: 'none' },
    { path: '/appcustom/customlist/advert', title: '广告界面自定义列表', component: AsyncComponent(() => import('@/views/marketingmanage/appcustom/pages/customlist/custom/advert')), show: 'none' },
    { path: '/appcustom/customlist/custom/:type', title: '自定义列表', component: AsyncComponent(() => import('@/views/marketingmanage/appcustom/pages/customlist/custom')), show: 'none' },
    { path: '/appcustom/popupcustom', title: '首页弹框设置', component: AsyncComponent(() => import('@/views/marketingmanage/appcustom/pages/popupcustom')), show: 'none' },
    { path: '/activities', title: '营销活动', component: Activities },
    { path: '/activities/createInfo', title: '新建营销活动', component: AsyncComponent(() => import('@/views/marketingmanage/activities/pages/createInfo')), show: 'none' },
    { path: '/activities/detailInfo/:id', title: '营销活动详情', component: AsyncComponent(() => import('@/views/marketingmanage/activities/pages/detailInfo')), show: 'none' },
    { path: '/activities/treasureinfo/:id', title: '营销活动详情', component: AsyncComponent(() => import('@/views/marketingmanage/activities/pages/lotteryinfo')), show: 'none' },
    { path: '/activities/editorInfo/:id', title: '营销活动编辑', component: AsyncComponent(() => import('@/views/marketingmanage/activities/pages/editorInfo')), show: 'none' },
    { path: '/activities/edittreasure/:id', title: '营销活动编辑', component: AsyncComponent(() => import('@/views/marketingmanage/activities/pages/updatelottery')), show: 'none' },
    { path: '/activities/detailInfo/listdata/:id', title: '活动商品列表详情', component: AsyncComponent(() => import('@/views/marketingmanage/activities/pages/detailInfo/detailpages/listdata/')), show: 'none' },
    { path: '/productgroup', title: '营销活动商品分组', component: ProductGroup },
    { path: '/cardticket', title: '卡券管理', component: CardTicket },
    { path: '/cardticket/createInfo', title: '新建卡券', component: AsyncComponent(() => import('@/views/marketingmanage/cardticket/pages/createInfo')), show: 'none' },
    { path: '/cardticket/detailInfo/:id', title: '卡券详情', component: AsyncComponent(() => import('@/views/marketingmanage/cardticket/pages/detailInfo')), show: 'none' },
    { path: '/cardticket/editorInfo/:id', title: '卡券编辑', component: AsyncComponent(() => import('@/views/marketingmanage/cardticket/pages/editorInfo')), show: 'none' },
    { path: '/cardticket/activitydate/:id', title: '数据', component: AsyncComponent(() => import('@/views/marketingmanage/cardticket/pages/activitydate')), show: 'none' },
    { path: '/advocacytask', title: '代言任务', component: Advocacytask },
    { path: '/advocacytask/createInfo', title: '新建代言任务', component: AsyncComponent(() => import('@/views/marketingmanage/advocacytask/pages/createInfo')), show: 'none' },
    { path: '/advocacytask/detailInfo/:id', title: '代言任务详情', component: AsyncComponent(() => import('@/views/marketingmanage/advocacytask/pages/detailInfo')), show: 'none' },
    { path: '/advocacytask/editorInfo/:id', title: '代言任务编辑', component: AsyncComponent(() => import('@/views/marketingmanage/advocacytask/pages/editorInfo')), show: 'none' },
    { path: '/activeStarMall', title: '人气值商品', component: ActiveStarMall },
    { path: '/activeStarMall/detail/:id', title: '人气值商品详情', component: AsyncComponent(() => import('@/views/marketingmanage/activeStarMall/pages/detail')), show: 'none' },
    { path: '/activeShowMall', title: '秀场商品', component: ActiveShowMall },
    { path: '/activeShowMall/detail/:id', title: '秀场商品详情', component: AsyncComponent(() => import('@/views/marketingmanage/activeShowMall/pages/detail')), show: 'none' },
    { path: '/xiubei', title: '贡献值管理', component: XiuBei },
    { path: '/xiubei/create', title: '新建贡献值', component: AsyncComponent(() => import('@/views/marketingmanage/xiubei/pages/create')), show: 'none' },
    { path: '/xiubei/edit/:id', title: '编辑贡献值', component: AsyncComponent(() => import('@/views/marketingmanage/xiubei/pages/edit')), show: 'none' },
    { path: '/xiubei/detail/:id', title: '贡献值详情', component: AsyncComponent(() => import('@/views/marketingmanage/xiubei/pages/detail')), show: 'none' },
    { path: '/fightrecord', title: '抢拍记录', component: Fightrecord },
    { path: '/consignments', title: '寄售券管理', component: Consignments },
];

// 店铺管理
const storemanageRoutes = [
    { path: '/list', title: '店铺列表', component: storeList },
    { path: '/list/detailInfo/:id', title: '详情', component: AsyncComponent(() => import('@/views/storemanage/list/pages/detailInfo')), show: 'none' },
    { path: '/list/recommenlist', title: '店铺推荐列表', component: AsyncComponent(() => import('@/views/storemanage/list/pages/recommenlist')), show: 'none' },
    { path: '/list/banInfo/:id', title: '封禁', component: AsyncComponent(() => import('@/views/storemanage/list/pages/banInfo')), show: 'none' },
    { path: '/hotelShop', title: '酒店店铺', component: hotelList },
    { path: '/hotelShop/detailInfo/:id', title: '详情', component: AsyncComponent(() => import('@/views/storemanage/hotelShop/pages/detailInfo')), show: 'none' },
    { path: '/hotelShop/banInfo/:id', title: '封禁', component: AsyncComponent(() => import('@/views/storemanage/hotelShop/pages/banInfo')), show: 'none' },
    { path: '/cityOpen', title: '开城设置', component: CityOpen },
    { path: '/cityOpen/record/:title/:cityId', title: '操作日志', component: AsyncComponent(() => import('@/views/storemanage/cityOpen/recordlist')), show: 'none' },
    { path: '/cityOpen/storeList', title: '已入驻门店', component: AsyncComponent(() => import('@/views/storemanage/cityOpen/storeList')), show: 'none' },
    { path: '/storeUnion', title: '入网查询', component: StoreUnion },
    { path: '/storeUnion/lianlianpaydetail/:id/:accessType', title: '查看详情', component: AsyncComponent(() => import('@/views/storemanage/storeUnion/lianlianpay/detail')), show: 'none' },
    { path: '/storeUnion/huifupaydetail/:id/:accessType', title: '查看详情', component: AsyncComponent(() => import('@/views/storemanage/storeUnion/huifupay/detail')), show: 'none' },
];

// 服务清单
const servicelistingRoutes = [
    { path: '/servicehistory', title: '服务清单历史记录', component: Servicehistory },
    { path: '/servicehistory/detailInfo/:id', title: '详情', component: AsyncComponent(() => import('@/views/servicelistingmanage/servicehistory/pages/detailInfo')), show: 'none' },
    { path: '/servicehistory/theSales/:id', title: '销售情况', component: AsyncComponent(() => import('@/views/servicelistingmanage/servicehistory/pages/theSales')), show: 'none' },
];

//任务管理
const taskmanageRoutes = [
    { path: '/taskList', title: '任务列表', component: TaskList },
    { path: '/taskList/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/taskmanage/taskList/pages/detail')), show: 'none' },
    { path: '/taskList/create', title: '创建', component: AsyncComponent(() => import('@/views/taskmanage/taskList/pages/createTask')), show: 'none' },
    { path: '/taskClassify', title: '任务分类', component: TaskClassify },
];

// 售后管理
const salemanageRoutes = [
    { path: '/salelist', title: '售后列表', component: salelist },
    { path: '/salelist/contentInfo/:id/:saleType', title: '售后详情', component: AsyncComponent(() => import('@/views/salemanage/salelist/pages/contentInfo')), show: 'none' },
    { path: '/salereasons', title: '售后原因说明', component: salereasons },
    { path: '/wholesalelist', title: '批发订单售后列表', component: wholesale },
    { path: '/wholesalelist/contentInfo/:id/:saleType', title: '售后详情', component: AsyncComponent(() => import('@/views/salemanage/wholesale/pages/contentInfo')), show: 'none' },
];

// 审核管理
const auditmanageRoutes = [
    { path: '/nicknameAvatar', title: '会员昵称头像审核', component: NicknameAvatar },
    { path: '/nameauthentication', title: '实名认证', component: NameAuthentication },
    { path: '/nameauthentication/detail/:id', title: '认证详情', component: AsyncComponent(() => import('@/views/auditmanage/nameauthentication/pages/detail')), show: 'none' },
    { path: '/nameauthentication/facilitatoraudit/:id', title: '服务商认证审核', component: AsyncComponent(() => import('@/views/auditmanage/nameauthentication/pages/audit')), show: 'none' },
    { path: '/nameauthentication/facilitatorinfo/:id', title: '服务商认证详情', component: AsyncComponent(() => import('@/views/auditmanage/nameauthentication/pages/facilitator/detail')), show: 'none' },
    { path: '/tvanchoraudit', title: '主播封面审核', component: TVAnchorAudit },
    { path: '/pornographyaudit', title: '鉴黄审核', component: PornographyAudit },
    { path: '/videoaudit', title: '视频审核', component: VideoAudit },
    { path: '/communityaudit', title: '社区审核', component: CommunityAudit },
    { path: '/productaudit', title: '商品审核', component: ProductAudit },
    { path: '/productaudit/productdetail/:id', title: '商品审核详情', component: AsyncComponent(() => import('@/views/auditmanage/productaudit/pages/normal/detail')), show: 'none' },
    { path: '/productaudit/productaudit/:id', title: '商品审核', component: AsyncComponent(() => import('@/views/auditmanage/productaudit/pages/normal/audit')), show: 'none' },
    { path: '/productaudit/ratedetail/:id', title: '费率审核详情', component: AsyncComponent(() => import('@/views/auditmanage/productaudit/pages/productRate/detail')), show: 'none' },
    { path: '/productaudit/rateaudit/:id', title: '费率审核', component: AsyncComponent(() => import('@/views/auditmanage/productaudit/pages/productRate/audit')), show: 'none' },
    { path: '/businessaudit', title: '商户审核', component: BusinessAudit },
    { path: '/businessaudit/detailInfo/:id/:accessType', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/businessaudit/detailInfo')), show: 'none' },
    { path: '/businessaudit/auditInfo/:id', title: '审核', component: AsyncComponent(() => import('@/views/auditmanage/businessaudit/auditInfo')), show: 'none' },
    { path: '/businessaudit/shopInfo/:id', title: '店铺', component: AsyncComponent(() => import('@/views/auditmanage/businessaudit/shopInfo')), show: 'none' },
    { path: '/supplierAudit', title: '供应商审核', component: SupplierAudit },
    { path: '/supplierAudit/detail/:id', title: '供应商审核详情', component: AsyncComponent(() => import('@/views/auditmanage/supplierAudit/pages/detail')), show: 'none' },
    { path: '/supplierAudit/audit/:id', title: '供应商审核操作', component: AsyncComponent(() => import('@/views/auditmanage/supplierAudit/pages/audit')), show: 'none' },
    // { path: '/operationCenterAudit', title: '运营中心审核', component: OperationCenterAudit },
    { path: '/marketaudit/operationCenterAudit/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/operationCenter/pages/detail')), show: 'none' },
    { path: '/marketaudit/operationCenterAudit/audit/:id', title: '审核', component: AsyncComponent(() => import('@/views/auditmanage/operationCenter/pages/audit')), show: 'none' },
    // { path: '/areaCompanyAudit', title: '区域公司审核', component: AreaCompanyAudit },
    { path: '/marketaudit/areaCompanyAudit/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/areaCompany/pages/detail')), show: 'none' },
    { path: '/marketaudit/areaCompanyAudit/audit/:id', title: '审核', component: AsyncComponent(() => import('@/views/auditmanage/areaCompany/pages/audit')), show: 'none' },
    { path: '/activityProductAudit', title: '活动商品审核', component: ActivityProductAudit },
    { path: '/activityProductAudit/detail/:activityId/:activityGoodsId', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/activityProduct/pages/detail')), show: 'none' },
    { path: '/activityProductAudit/audit/:activityId/:activityGoodsId/:goodsId/:id', title: '审核', component: AsyncComponent(() => import('@/views/auditmanage/activityProduct/pages/audit')), show: 'none' },
    { path: '/activityProductAudit/exitdetail/:activityId/:activityGoodsId/:withdrawId', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/activityProduct/pages/exitApply/detail')), show: 'none' },
    { path: '/activityProductAudit/exitaudit/:activityId/:activityGoodsId/:withdrawId', title: '审核', component: AsyncComponent(() => import('@/views/auditmanage/activityProduct/pages/exitApply/audit')), show: 'none' },
    { path: '/palatGoodsAudit', title: '平台批发商品审核', component: PlatGoodsAudit },
    { path: '/palatGoodsAudit/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/palatGoodsAudit/pages/detail')), show: 'none' },
    { path: '/palatGoodsAudit/audit/:id', title: '审核', component: AsyncComponent(() => import('@/views/auditmanage/palatGoodsAudit/pages/audit')), show: 'none' },
    { path: '/brandAudit', title: '品牌审核', component: BrandAudit },
    { path: '/brandAudit/detail/:id/:merchantId', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/brandAudit/pages/detail')), show: 'none' },
    { path: '/brandAudit/audit/:id/:merchantId', title: '审核', component: AsyncComponent(() => import('@/views/auditmanage/brandAudit/pages/audit')), show: 'none' },
    // { path: '/serviceAudit', title: '服务商审核', component: ServiceAudit },
    { path: '/marketaudit/serviceAudit/audit/:id', title: '审核', component: AsyncComponent(() => import('@/views/auditmanage/serviceaudit/pages/audit')), show: 'none' },
    { path: '/marketaudit/serviceAudit/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/serviceaudit/pages/detail')), show: 'none' },
    { path: '/serviceWithdrawal', title: '服务商支取审核', component: ServiceWithdrawal },
    { path: '/serviceWithdrawal/firstAudit/:id', title: '初审', component: AsyncComponent(() => import('@/views/auditmanage/serviceWithdrawal/pages/firAudit')), show: 'none' },
    { path: '/serviceWithdrawal/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/serviceWithdrawal/pages/detail')), show: 'none' },
    { path: '/serviceWithdrawal/secondAudit/:id', title: '财务复审', component: AsyncComponent(() => import('@/views/auditmanage/serviceWithdrawal/pages/secAudit')), show: 'none' },
    { path: '/companyInvoice', title: '区域公司发票审核', component: CompanyInvoice },
    { path: '/companyInvoice/firstAudit/:id', title: '初审', component: AsyncComponent(() => import('@/views/auditmanage/companyInvoice/pages/firAudit')), show: 'none' },
    { path: '/companyInvoice/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/companyInvoice/pages/detail')), show: 'none' },
    { path: '/companyInvoice/secondAudit/:id', title: '财务复审', component: AsyncComponent(() => import('@/views/auditmanage/companyInvoice/pages/secAudit')), show: 'none' },
    { path: '/operationInvoice', title: '运营中心发票审核', component: OperationInvoice },
    { path: '/operationInvoice/firstAudit/:id', title: '初审', component: AsyncComponent(() => import('@/views/auditmanage/operationInvoice/pages/firAudit')), show: 'none' },
    { path: '/operationInvoice/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/operationInvoice/pages/detail')), show: 'none' },
    { path: '/operationInvoice/secondAudit/:id', title: '财务复审', component: AsyncComponent(() => import('@/views/auditmanage/operationInvoice/pages/secAudit')), show: 'none' },
    { path: '/memberWithdrawal', title: '会员支取申请审核', component: MemberWithdrawal },
    { path: '/memberWithdrawal/firstAudit/:id', title: '初审', component: AsyncComponent(() => import('@/views/auditmanage/memberWithdrawal/pages/firAudit')), show: 'none' },
    { path: '/memberWithdrawal/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/memberWithdrawal/pages/detail')), show: 'none' },
    { path: '/memberWithdrawal/secondAudit/:id', title: '财务复审', component: AsyncComponent(() => import('@/views/auditmanage/memberWithdrawal/pages/secAudit')), show: 'none' },
    { path: '/memberStar', title: '会员魅力值支取审核', component: MemberStar },
    { path: '/memberStar/firstAudit/:id', title: '初审', component: AsyncComponent(() => import('@/views/auditmanage/memberStar/pages/firAudit')), show: 'none' },
    { path: '/memberStar/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/memberStar/pages/detail')), show: 'none' },
    { path: '/memberStar/secondAudit/:id', title: '财务复审', component: AsyncComponent(() => import('@/views/auditmanage/memberStar/pages/secAudit')), show: 'none' },
    { path: '/salesWithdrawal', title: '分销业务', component: SalesWithdrawal },
    { path: '/salesWithdrawal/firstAudit/:id', title: '初审', component: AsyncComponent(() => import('@/views/auditmanage/salesWithdrawal/pages/firAudit')), show: 'none' },
    { path: '/salesWithdrawal/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/salesWithdrawal/pages/detail')), show: 'none' },
    { path: '/salesWithdrawal/secondAudit/:id', title: '财务复审', component: AsyncComponent(() => import('@/views/auditmanage/salesWithdrawal/pages/secAudit')), show: 'none' },
    { path: '/illegal', title: '违规审核', component: Illegal },
    { path: '/illegal/shopDetail/:id', title: '违规店铺详情', component: AsyncComponent(() => import('@/views/auditmanage/illegal/pages/shopIllegal/detail')), show: 'none' },
    { path: '/illegal/shopAudit/:id', title: '违规店铺审核', component: AsyncComponent(() => import('@/views/auditmanage/illegal/pages/shopIllegal/audit')), show: 'none' },
    { path: '/illegal/productDetail/:id', title: '违规善品详情', component: AsyncComponent(() => import('@/views/auditmanage/illegal/pages/productIllegal/detail')), show: 'none' },
    { path: '/illegal/productAudit/:id', title: '违规商品审核', component: AsyncComponent(() => import('@/views/auditmanage/illegal/pages/productIllegal/audit')), show: 'none' },
    { path: '/allocationaudit', title: '计费分配比例审核', component: AllocationAudit },
    { path: '/allocationaudit/auditInfo/:id', title: '计费分配比例审核', component: AsyncComponent(() => import('@/views/auditmanage/allocation/pages/auditInfo')), show: 'none' },
    { path: '/advertPlacementAudit', title: '广告投放审核', component: AdvertPlacementAudit },
    { path: '/advertPlacementAudit/audit/:id', title: '广告投放审核', component: AsyncComponent(() => import('@/views/auditmanage/advertPlacement/pages/audit')), show: 'none' },
    { path: '/purchasepact', title: '采购合同审核', component: PurchasePactAudit },
    { path: '/purchasepact/detailinfo/:id', title: '采购合同审核详情', component: AsyncComponent(() => import('@/views/auditmanage/purchasepactaudit/pages/purchaseInfo')), show: 'none' },
    { path: '/purchasepact/auditinfo/:id', title: '采购合同审核操作', component: AsyncComponent(() => import('@/views/auditmanage/purchasepactaudit/pages/auditpurchase')), show: 'none' },
    { path: '/advocacytask', title: '代言任务审核', component: AdvocacyTask },
    { path: '/advocacytask/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/advocacytask/detail')), show: 'none' },
    { path: '/advocacytask/audit/:id', title: '审核', component: AsyncComponent(() => import('@/views/auditmanage/advocacytask/audit')), show: 'none' },
    { path: '/payorder', title: '支付凭证审核', component: PayOrder },
    { path: '/payorder/payorderaudit/:id', title: '支付凭证审核信息', component: AsyncComponent(() => import('@/views/auditmanage/payorder/audit')), show: 'none' },
    { path: '/payorder/payorderinfo/:id', title: '支付凭证详情信息', component: AsyncComponent(() => import('@/views/auditmanage/payorder/detail')), show: 'none' },
    { path: '/payorder/channelinfo/:id', title: '渠道支付凭证详情', component: AsyncComponent(() => import('@/views/auditmanage/payorder/channelinfo')), show: 'none' },
    { path: '/payorder/clientDetail/:id', title: '支付凭证详情', component: AsyncComponent(() => import('@/views/auditmanage/payorder/ClientDetail')), show: 'none' },
    { path: '/payorder/clientAudit/:id', title: '支付凭证审核', component: AsyncComponent(() => import('@/views/auditmanage/payorder/ClientAudit')), show: 'none' },


    { path: '/enterpriseanchor', title: '企业主播审核', component: EnterpriseAnchor },
    { path: '/enterpriseanchor/auditinfo/:id', title: '企业主播审核信息', component: AsyncComponent(() => import('@/views/auditmanage/enterpriseAnchor/auditInfo')), show: 'none' },
    { path: '/enterpriseanchor/detailinfo/:id', title: '企业主播详情信息', component: AsyncComponent(() => import('@/views/auditmanage/enterpriseAnchor/detailInfo')), show: 'none' },
    { path: '/marketaudit', title: '市场审核', component: MarketAudit },
    { path: '/commodityReview', title: '商品评论审核', component: CommodityReview },
    { path: '/stockRight', title: '会员福利审核', component: StockRight },
    { path: '/stockRight/audit/:id', title: '审核', component: AsyncComponent(() => import('@/views/auditmanage/stockright/audit')), show: 'none' },
    { path: '/stockRight/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/stockright/detail')), show: 'none' },
    { path: '/previewincomeaudit', title: '预收益审核', component: PreviewIncomeAudit },
    { path: '/previewincomeaudit/:type/:id', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/previewincomeaudit/pages/detail')), show: 'none' },
    { path: '/upgradegiftmember', title: '普通会员升级服务天使', component: UpgradeGiftMember },
    { path: '/upgradegiftmember/detail/:id', title: '普通会员升级服务天使详情', component: AsyncComponent(() => import('@/views/auditmanage/upgradegiftmember/detail')), show: 'none' },
    { path: '/upgradegiftmember/audit/:id', title: '普通会员升级服务天使审核', component: AsyncComponent(() => import('@/views/auditmanage/upgradegiftmember/detail')), show: 'none' },
    { path: '/upgradegiftmember/detail/member/:id', title: '推荐会员列表', component: AsyncComponent(() => import('@/views/auditmanage/upgradegiftmember/recomment')), show: 'none' },
    { path: '/upgradegiftmember/detail/market/:id', title: '销售额', component: AsyncComponent(() => import('@/views/auditmanage/upgradegiftmember/order')), show: 'none' },
    { path: '/upgradegiftmember/detail/selfbuy/:id', title: '自购金额', component: AsyncComponent(() => import('@/views/auditmanage/upgradegiftmember/order')), show: 'none' },
    { path: '/upgradegiftmember/detail/market/info/:id', title: '订单详情', component: AsyncComponent(() => import('@/views/ordermanage/productorder/pages/orderdetail')), show: 'none' },
    { path: '/upgradeareacompany', title: '服务天使升级区域公司', component: UpgradeAreaCompany },
    { path: '/upgradeareacompany/:type/:id', title: '服务天使升级区域公司详情', component: AsyncComponent(() => import('@/views/auditmanage/upgradeareacompany/detail')), show: 'none' },
    { path: '/upgradeareacompany/:type/:id', title: '服务天使升级区域公司审核', component: AsyncComponent(() => import('@/views/auditmanage/upgradeareacompany/detail')), show: 'none' },
    { path: '/upgradeareacompany/upgrade/create/:id', title: '升级区域公司', component: AsyncComponent(() => import('@/views/auditmanage/upgradeareacompany/upgradecreate')), show: 'none' },
    { path: '/upgradeareacompany/detail/member/:id', title: '推荐会员列表', component: AsyncComponent(() => import('@/views/auditmanage/upgradeareacompany/recomment')), show: 'none' },
    { path: '/upgradeareacompany/detail/team/:id', title: '团队会员列表', component: AsyncComponent(() => import('@/views/auditmanage/upgradeareacompany/recomment')), show: 'none' },
    { path: '/upgradeareacompany/detail/market/:id', title: '销售额', component: AsyncComponent(() => import('@/views/auditmanage/upgradegiftmember/order')), show: 'none' },
    { path: '/upgradeareacompany/detail/selfbuy/:id', title: '自购金额', component: AsyncComponent(() => import('@/views/auditmanage/upgradeareacompany/order')), show: 'none' },
    { path: '/upgradeareacompany/detail/market/info/:id', title: '订单详情', component: AsyncComponent(() => import('@/views/ordermanage/productorder/pages/orderdetail')), show: 'none' },
    { path: '/channelaudit', title: '渠道审核', component: ChannelAudit },
    { path: '/channelaudit/positivedetail/:id', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/channelaudit/positivedetail')), show: 'none' },
    { path: '/channelaudit/positiveaudit/:id', title: '审核', component: AsyncComponent(() => import('@/views/auditmanage/channelaudit/positivedetail')), show: 'none' },
    { path: '/channelaudit/craditaudit/:id', title: '审核', component: AsyncComponent(() => import('@/views/auditmanage/channelaudit/creditdetail')), show: 'none' },
    { path: '/businessCategory', title: '经营品类审核', component: businessCategory },
    { path: '/businessCategory/applyDetail/:type/:id/:merchantId', title: '详情', component: AsyncComponent(() => import('@/views/auditmanage/businessCategory/pages/applyDetail')), show: 'none' },
];

// 客服管理
const customermanageRoutes = [
    { path: '/workOrder', title: '工单管理', component: workOrder, show: 'none' },
    { path: '/workOrder/detail/:id', title: '工单详情', component: AsyncComponent(() => import('@/views/customermanage/workOrder/pages/orderdetail')), show: 'none' },
    { path: '/workOrder/add', title: '新建工单', component: AsyncComponent(() => import('@/views/customermanage/workOrder/pages/add')), show: 'none' },
    { path: '/customerList', title: '客服列表', component: customerList },
    { path: '/customerList/add', title: '新建客服列表', component: AsyncComponent(() => import('@/views/customermanage/customerList/pages/add')), show: 'none' },
    { path: '/customerList/edit/:id', title: '编辑客服列表', component: AsyncComponent(() => import('@/views/customermanage/customerList/pages/edit')), show: 'none' },
    { path: '/suggestions', title: '意见反馈', component: suggestions },
    { path: '/feedbackType', title: '反馈类型', component: feedbackType },
];

// 财务管理
const storehousefinancialmanageRoutes = [
    { path: '/bankAccount', title: '账户明细', component: bankAccount },
    { path: '/bankAccount/detail/:id/:type', title: '费用明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/bankAccount/pages/detail')), show: 'none' },
    { path: '/merchantreconciliation', title: '商户对账', component: merchantreconciliation },
    { path: '/merchantreconciliation/createinvoice', title: '新建发票', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/merchantinvoicemanage/pages/create')), show: 'none' },
    { path: '/merchantreconciliation/detail/:id', title: '对账详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/merchantreconciliation/pages/merchantreconciliation/info')), show: 'none' },
    { path: '/merchantreconciliation/hotel/detail/:id', title: '对账详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/merchantreconciliation/pages/hotelreconciliation/info')), show: 'none' },
    { path: '/merchantinvoicemanage', title: '商户发票管理', component: merchantinvoicemanage },
    { path: '/merchantinvoicemanage/createinvoice', title: '新建发票', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/merchantinvoicemanage/pages/create')), show: 'none' },
    { path: '/merchantinvoicemanage/createinvoice/:id', title: '编辑发票', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/merchantinvoicemanage/pages/create')), show: 'none' },
    { path: '/merchantinvoicemanage/detail/:id', title: '发票详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/merchantinvoicemanage/pages/detail')), show: 'none' },
    { path: '/regionalcompanyreconciliation', title: '对账管理', component: regionalcompanyreconciliation },
    { path: '/regionalcompanyreconciliation/detail/:id', title: '对账详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/regionalcompanyreconciliation/pages/info')), show: 'none' },
    { path: '/regionalcompanyreconciliation/account/detail/:id', title: '费用明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/regionalcompanyreconciliation/pages/info/AdvertTable')), show: 'none' },
    { path: '/regionalcompanyreconciliation/servicemanagefee/detail/:id', title: '费用明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/regionalcompanyreconciliation/pages/info/ServiceManageFeeTable')), show: 'none' },
    { path: '/regionalcompanyreconciliation/createinvoice', title: '新建发票', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/regionalcompanyinvoicemanage/pages/create')), show: 'none' },
    { path: '/regionalcompanyreconciliation/exchangemanage/:type/:params', title: '兑换凭证管理', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/exchangemanage/pages')), show: 'none' },
    { path: '/regionalcompanyinvoicemanage', title: '发票管理', component: regionalcompanyinvoicemanage },
    { path: '/regionalcompanyinvoicemanage/createinvoice', title: '新建发票', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/regionalcompanyinvoicemanage/pages/create')), show: 'none' },
    { path: '/regionalcompanyinvoicemanage/createinvoice/:id', title: '编辑发票', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/regionalcompanyinvoicemanage/pages/create')), show: 'none' },
    { path: '/regionalcompanyinvoicemanage/detail/:id', title: '发票详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/regionalcompanyinvoicemanage/pages/detail')), show: 'none' },
    { path: '/operatingcenterreconciliation', title: '运营中心对账', component: operatingcenterreconciliation },
    { path: '/operatingcenterreconciliation/detail/:id', title: '对账详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/operatingcenterreconciliation/pages/info')), show: 'none' },
    { path: '/operatingcenterreconciliation/createinvoice', title: '新建发票', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/operatingcenterinvoicemanage/pages/create')), show: 'none' },
    { path: '/operatingcenterreconciliation/account/detail/:id', title: '费用明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/operatingcenterreconciliation/pages/info/AdvertTable')), show: 'none' },
    { path: '/operatingcenterreconciliation/servicemanagefee/detail/:id', title: '费用明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/operatingcenterreconciliation/pages/info/ServiceManageFeeTable')), show: 'none' },
    { path: '/operatingcenterreconciliation/exchangemanage/:type/:params', title: '兑换凭证管理', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/exchangemanage/pages')), show: 'none' },
    { path: '/operatingcenterinvoicemanage', title: '运营中心发票管理', component: operatingcenterinvoicemanage },
    { path: '/operatingcenterinvoicemanage/createinvoice', title: '新建发票', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/operatingcenterinvoicemanage/pages/create')), show: 'none' },
    { path: '/operatingcenterinvoicemanage/createinvoice/:id', title: '编辑发票', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/operatingcenterinvoicemanage/pages/create')), show: 'none' },
    { path: '/operatingcenterinvoicemanage/detail/:id', title: '发票详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/operatingcenterinvoicemanage/pages/detail')), show: 'none' },
    { path: '/paymanage', title: '付款管理', component: paymanage },
    { path: '/paymanage/detail/:id/:payStatus', title: '付款详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/paymanage/pages/info')), show: 'none' },
    { path: '/paymanage/merchant/detail/:id', title: '商户付款详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/paymanage/pages/merchant/info')), show: 'none' },
    { path: '/paymanage/merchant/audit/:id', title: '商户付款审核', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/paymanage/pages/merchant/info')), show: 'none' },
    { path: '/paymanage/dispatchquery/:id', title: '派单查询', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/paymanage/pages/dispatchQuery')), show: 'none' },
    { path: '/paymanage/dispatchquery/detail/:dispatchId/:outDispatchId', title: '派单明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/paymanage/pages/dispatchQuery/info')), show: 'none' },
    { path: '/payrollbillinglist', title: '薪人开票列表', component: payrollbillinglist },
    { path: '/taxdeclaration', title: '个税申报', component: taxdeclaration },
    { path: '/billquery', title: '账单查询', component: BillQuery },
    { path: '/billquery/walletinfo/:finishDate/:targetId', title: '钱包对账明细表', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/billquery/walletinfo')), show: 'none' },
    { path: '/exchangemanage', title: '兑换凭证管理', component: Exchangemanage },
    { path: '/exchangemanage/:type', title: '兑换凭证管理', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/exchangemanage/pages')), show: 'none' },
    { path: '/exchangemanage/:type/:params', title: '兑换凭证管理', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/exchangemanage/pages')), show: 'none' },
    { path: '/exchangemanage/servicestockexchange/detail/:id', title: '兑换凭证详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/exchangemanage/pages/servicestockexchange/detail')), show: 'none' },
    { path: '/exchangemanage/servicestockprofit/detail/:id', title: '股权溢价收益权', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/exchangemanage/pages/servicestockprofit/detail')), show: 'none' },
    { path: '/merchantwithdrawalrecords', title: '提现记录', component: Merchantwithdrawalrecords },
    { path: '/merchantwithdrawalrecords/list', title: '手动代提现', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/merchantwithdrawalrecords/pages/manualWithdrawal')), show: 'none' },
    { path: '/merchantwithdrawalrecords/withdrawallist/tl/:merchantId/:id', title: '提现订单明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/merchantwithdrawalrecords/pages/withdrawallist/tllist')), show: 'none' },
    { path: '/merchantwithdrawalrecords/withdrawallist/yl/:merchantId/:id', title: '提现订单明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/merchantwithdrawalrecords/pages/withdrawallist/yllist')), show: 'none' },
    { path: '/merchantwithdrawalrecords/withdrawallist/ll/:merchantId/:id', title: '提现订单明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/merchantwithdrawalrecords/pages/withdrawallist/lllist')), show: 'none' },
    { path: '/merchantwithdrawalrecords/withdrawallist/orderlist/:merchantId/:id', title: '提现订单明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/merchantwithdrawalrecords/pages/withdrawallist/orderlist')), show: 'none' },
    { path: '/merchantwithdrawalrecords/supplierwithdrawinfo/detail/:id', title: '供应商提现明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/merchantwithdrawalrecords/withdrawinfo')), show: 'none' },
    { path: '/reconciliationdata', title: '交易明细查询（银行）', component: ReconciliationData },
    { path: '/reconciliationdata/detail', title: '商户对账明细查询', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/reconciliationdata/pages/detail')), show: 'none' },
    { path: '/dailystatement', title: '商户日交易及结算对账单', component: DailyStatement },
    { path: '/dailystatement/detail/:id/:merchantId/:gmtAccounting/:channelType', title: '交易流水详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/dailystatement/pages/detail')), show: 'none' },
    { path: '/dailystatement/unionpaydetail/:id/:merchantId/:gmtAccounting', title: '对账单详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/dailystatement2/pages/detail')), show: 'none' },
    { path: '/dailystatement/lianliandetail/:id/:merchantId/:gmtAccounting', title: '对账单详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/dailystatement/detail')), show: 'none' },
    { path: '/dailystatement/huifudetail/:id/:merchantId/:gmtAccounting', title: '对账单详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/dailystatement/huifu/detail')), show: 'none' },
    { path: '/dailystatement/advanceinfo/:id/:merchantId/:billingCycle', title: '对账单详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/dailystatement/advanceinfo')), show: 'none' },
    // { path: '/dailystatement2', title: '银联日结对账单', component: DailyStatement2 },
    // { path: '/dailystatement2/detail/:ids', title: '对账单详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/dailystatement2/pages/detail')), show: 'none' },

    { path: '/otherpay', title: '其它付款管理', component: Otherpay },
    { path: '/otherpay/detail/:id', title: '付款记录详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/otherpay/pay/detail')), show: 'none' },


    { path: '/tonlyaccountpay', title: '资金账户管理', component: TonlyAccountPay },
    { path: '/freeorderrefund', title: '免单商户退款', component: FreeOrderRefund },
    { path: '/platformdrawalrecords', title: '平台提现记录', component: PlatformDrawalRecords },
    { path: '/taxcertificate', title: '临时税务登记证', component: Taxcertificate },
    { path: '/taxcertificate/detail/:id', title: '登记证详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/taxcertificate/pages/detail')), show: 'none' },
    { path: '/datacenter', title: '数据中心', component: DataCenter },
    { path: '/datacenter/orderhistory/:orderId', title: '操作记录', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/datacenter/tab5/orderhistory')), show: 'none' },
    { path: '/datacenter/orderdetail/:orderId', title: '订单详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/datacenter/tab5/orderdetail')), show: 'none' },
    { path: '/servicebill', title: '服务商对账', component: ServiceBill },
    { path: '/unitbill', title: '机构对账', component: UnitBill },
    { path: '/thirdreconciliation', title: '第三方对账单', component: ThirdReconciliation },
    { path: '/thirdreconciliation/orderdetail/:id', title: '帐单详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/thirdreconciliation/reconciliationdetail')), show: 'none' },
    { path: '/thirdreconciliation/orderdetail/detail/:id', title: '订单详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/thirdreconciliation/reconciliationdetail/orderdetail')), show: 'none' },
    { path: '/invoicemaintain', title: '发票付款维护', component: Invoicemaintain },
    { path: '/cashmaintain', title: '提现维护', component: Cashmaintain },
    { path: '/marketingamountmanage', title: '特权值管理', component: MarketingAmountManage },
    { path: '/marketingamountmanage/marketingincomelist/:subjectType/:subjectId', title: '特权值管理', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/marketingamountmanage/pages/marketingincomelist/main')), show: 'none' },
    { path: '/marketingamountmanage/marketingincomedetail/:companyId', title: '特权值收益明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/marketingamountmanage/pages/marketingincomedetail/main')), show: 'none' },
    { path: '/previewcollectionmanage', title: '预收款管理', component: PreviewCollectionManage },
    { path: '/previewcollectionmanage/detail/:id/:account/:name', title: '预收款详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/previewcollectionmanage/pages/previewcollectiondetail')), show: 'none' },
    { path: '/platdividendincome', title: '平台分红收益列表', component: PlatDividendIncome },
    { path: '/platdividendincome/detaillist/:recordId', title: '平台分红收益明细列表', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/platdividendincome/detaillist')), show: 'none' },
    { path: '/platdividendincome/order', title: '商品订单', component: ProductOrder, show: 'none' },
    { path: '/channelimprest', title: '渠道预付款管理', component: ChannelImprest },
    { path: '/channelimprest/detaillist/:subjectId/:accountName', title: '预付款明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/channelimprest/detaillist')), show: 'none' },
    { path: '/channelcredit', title: '渠道信用额度管理', component: ChannelCredit },
    { path: '/channelcredit/detaillist/:subjectId/:accountName', title: '信用额度明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/channelcredit/detaillist')), show: 'none' },
    { path: '/subsidiesincome', title: '补贴收益管理', component: SubsidiesIncome },
    { path: '/operatebudget', title: '运营中心收益明细', component: BudgetList },
    { path: '/companybudget', title: '区域公司收益明细', component: BudgetList },
    { path: '/signRecord', title: '签约记录', component: SignRecord },
    { path: '/withdrawRecord', title: '提现记录', component: WithdrawRecord },
];

// 统计分析
const statisticalRoutes = [
    { path: '/companycanvas', title: '区域公司统计', component: CompanyCanvas },
    { path: '/companycanvas/income/:id', title: '销售金额', component: AsyncComponent(() => import('@/views/statistical/companyincome')), show: 'none' },
    { path: '/companyincome/:id', title: '区域公司订单收入统计', component: CompanyIncome },
    { path: '/operationmember', title: '会员统计报表', component: OperationMember },
    { path: '/operationorder', title: '运营中心订单销售统计', component: OperationOrder },
    { path: '/companyorder', title: '区域公司订单销售统计', component: CompanyOrder },
    { path: '/merchantbusiness', title: '商户业务统计', component: MerchantBusiness },
    { path: '/payment', title: '平台收款渠道汇总', component: Payment },
    { path: '/platservicefee', title: '平台服务费收入明细', component: PlatServiceFee },
    { path: '/techservicefee', title: '技术服务费收入明细', component: TechServiceFee },
    { path: '/showmonthly', title: '秀场充值月度汇总', component: ShowMonthly },
    { path: '/showstarlight', title: '秀场魅力值统计分析', component: ShowstarLight },
    { path: '/activitycost', title: '营销活动成本分析', component: ActivityCost },
    { path: '/salereasion', title: '售后原因分析', component: SaleReasion },
    { path: '/goodscategory', title: '商品大类毛利分析', component: GoodsCategory },
    { path: '/goodstotalcase', title: '商品信息总况', component: GoodsTotalCase },
    { path: '/statistishow', title: '贡献值发放统计', component: StatistiShow },
    { path: '/servicegrade', title: '服务商业绩统计', component: ServiceGrade }
];

// 分销业务统计 
const statisticsofdistributorRoutes = [
    { path: '/operationshare', title: '运营中心分销分享销售情况', component: Operationshare },
    { path: '/companyshare', title: '区域公司分销分享销售情况', component: Companyshare },
    { path: '/serviceshare', title: '服务商分销分享排行', component: Serviceshare },
    { path: '/goodshare', title: '分销销售商品排行', component: Goodshare },
    { path: '/distributionsale', title: '分销销售数据分析', component: DistributionSale },
    { path: '/operatingcenterdistributionsalepurchase', title: '运营中心分销自购销售情况', component: OperatingCenterDistributionSalePurchase },
    { path: '/regionalcompanydistributionsalepurchase', title: '区域公司分销自购销售情况', component: RegionalCompanyDistributionSalePurchase },
    { path: '/serviceproviderdistributionrank', title: '服务商分销自购排行', component: ServiceProviderDistributionRank }
];

// 合同管理 
const contractmanagementRoutes = [
    { path: '/onlinelist', title: '线上合同', component: Onlinelist },
    { path: '/offlinelist', title: '线下合同', component: Offlinelist },
    { path: '/contracttemplate', title: '合同模板', component: Contracttemplate },
    { path: '/contracttemplate/checkContract', title: '查看模板', component: AsyncComponent(() => import('@/views/contractmanagement/contracttemplate/pages/detail')), show: 'none' },
    { path: '/contracttemplate/signedmerchant', title: '已签署商户', component: AsyncComponent(() => import('@/views/contractmanagement/signedmerchant/pages/main')), show: 'none' },
];

// 用户管理   
const usermanagerRoutes = [
    { path: '/saler', title: '销售员档案', component: Saler },
    { path: '/saler/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/usermanage/saler/detail')), show: 'none' },
    { path: '/saler/income/:id', title: '收益明细', component: AsyncComponent(() => import('@/views/usermanage/saler/income')), show: 'none' },
    { path: '/consumer', title: '用户列表', component: Consumer },
    { path: '/consumer/info/:id', title: '用户详情', component: AsyncComponent(() => import('@/views/usermanage/consumer/pages/info')), show: 'none' },
    { path: '/member', title: '会员管理', component: Member },
    { path: '/member/info/:id', title: '会员信息', component: AsyncComponent(() => import('@/views/usermanage/member/pages/info')), show: 'none' },
    { path: '/member/recommend/:id/:type', title: '推荐明细', component: AsyncComponent(() => import('@/views/usermanage/member/pages/recommend/tab')), show: 'none' },
    { path: '/member/randp/:id', title: '收支明细', component: AsyncComponent(() => import('@/views/usermanage/member/pages/randp')), show: 'none' },
    { path: '/member/ticket/:id', title: '卡券管理', component: AsyncComponent(() => import('@/views/usermanage/member/pages/ticket')), show: 'none' },
    { path: '/member/charm/:id', title: '成长值明细', component: AsyncComponent(() => import('@/views/usermanage/member/pages/charm')), show: 'none' },
    { path: '/member/prohibit/:id', title: '封禁解封', component: AsyncComponent(() => import('@/views/usermanage/member/pages/prohibit')), show: 'none' },
    { path: '/membermlx', title: '牧佬鲜会员管理', component: MemberMlx },
    { path: '/membermlx/info/:id', title: '会员信息', component: AsyncComponent(() => import('@/views/usermanage/membermlx/pages/info')), show: 'none' },
    { path: '/membermlx/recommend/:id/:type', title: '推荐明细', component: AsyncComponent(() => import('@/views/usermanage/membermlx/pages/recommend/tab')), show: 'none' },
    { path: '/membermlx/randp/:id', title: '收支明细', component: AsyncComponent(() => import('@/views/usermanage/membermlx/pages/randp')), show: 'none' },
    { path: '/membermlx/ticket/:id', title: '卡券管理', component: AsyncComponent(() => import('@/views/usermanage/membermlx/pages/ticket')), show: 'none' },
    { path: '/membermlx/charm/:id', title: '成长值明细', component: AsyncComponent(() => import('@/views/usermanage/membermlx/pages/charm')), show: 'none' },
    { path: '/membermlx/prohibit/:id', title: '封禁解封', component: AsyncComponent(() => import('@/views/usermanage/membermlx/pages/prohibit')), show: 'none' },
    { path: '/giftmember', title: '服务天使', component: GiftMember },
    { path: '/giftmember/info/:accountId/:id', title: '会员信息', component: AsyncComponent(() => import('@/views/usermanage/giftmember/pages/info')), show: 'none' },
    { path: '/giftmember/recommend/:id/:type', title: '推荐明细', component: AsyncComponent(() => import('@/views/usermanage/giftmember/pages/recommend/tab')), show: 'none' },
    { path: '/giftmember/randp/:id', title: '收支明细', component: AsyncComponent(() => import('@/views/usermanage/giftmember/pages/randp')), show: 'none' },
    { path: '/tvanchor', title: '主播管理', component: TVAnchor },
    { path: '/tvanchor/info/:id', title: '主播信息', component: AsyncComponent(() => import('@/views/usermanage/tvanchor/pages/info')), show: 'none' },
    { path: '/tvanchor/randp/:id', title: '收支明细', component: AsyncComponent(() => import('@/views/usermanage/tvanchor/pages/randp')), show: 'none' },
    { path: '/tvanchor/ticket/:id', title: '卡券管理', component: AsyncComponent(() => import('@/views/usermanage/tvanchor/pages/ticket')), show: 'none' },
    { path: '/tvanchor/prohibit/:id', title: '封禁解封', component: AsyncComponent(() => import('@/views/usermanage/tvanchor/pages/prohibit')), show: 'none' },
    { path: '/service', title: '服务商管理', component: Service },
    { path: '/service/create', title: '新建服务商', component: AsyncComponent(() => import('@/views/usermanage/service/pages/create')), show: 'none' },
    { path: '/service/edit/:id', title: '编辑服务商', component: AsyncComponent(() => import('@/views/usermanage/service/pages/edit')), show: 'none' },
    { path: '/service/info/:id/:type', title: '服务商详情', component: AsyncComponent(() => import('@/views/usermanage/service/pages/info')), show: 'none' },
    { path: '/service/info/supplierlist/:id', title: '供应商详情', component: AsyncComponent(() => import('@/views/usermanage/service/pages/info/detail')), show: 'none' },
    { path: '/service/randp/:id', title: '收支明细', component: AsyncComponent(() => import('@/views/usermanage/service/pages/randp')), show: 'none' },
    { path: '/service/suggest/:id', title: '推荐明细', component: AsyncComponent(() => import('@/views/usermanage/service/pages/suggest')), show: 'none' },
    { path: '/blacklist', title: '黑名单', component: Blacklist },
    { path: '/blacklist/history/:id', title: '历史记录', component: AsyncComponent(() => import('@/views/usermanage/blacklist/pages/history')), show: 'none' },
    // { path: '/recordconversion', title: '人气值兑换魅力值记录', component: ConversionRe },
    { path: '/corporateanchor', title: '企业主播列表', component: Corporateanchor },
    { path: '/corporateanchor/add', title: '新建申请', component: AsyncComponent(() => import('@/views/usermanage/corporateanchor/pages/add')), show: 'none' },
    { path: '/corporateanchor/detail/:id', title: '企业主播详情', component: AsyncComponent(() => import('@/views/usermanage/corporateanchor/pages/detail')), show: 'none' },
    { path: '/corporateanchor/anchor/detail/:id', title: '主播详情', component: AsyncComponent(() => import('@/views/usermanage/corporateanchor/pages/info')), show: 'none' },
    { path: '/corporateanchor/pay/:id', title: '企业申请提交支付', component: AsyncComponent(() => import('@/views/usermanage/corporateanchor/pages/result')), show: 'none' },
    { path: '/memberdata', title: '会员数据', component: MemberData },
    { path: '/servicedata', title: '服务商数据', component: ServiceData },
    { path: '/org', title: '机构数据', component: Org },
];

// 权限管理
const privilegemanageRoutes = [
    { path: '/structureManage', title: '架构管理', component: structureManage },
    { path: '/employeelist', title: '员工列表', component: EmployeeList },
    { path: '/employeelist/add', title: '新建员工', component: AsyncComponent(() => import('@/views/permissionsmanage/employeelist/pages/add')), show: 'none' },
    { path: '/employeelist/edit/:id', title: '编辑员工', component: AsyncComponent(() => import('@/views/permissionsmanage/employeelist/pages/edit')), show: 'none' },
    { path: '/assignauthority', title: '分配权限', component: AssignAuthority },
    { path: '/menuConfigure', title: '菜单配置', component: menuConfigure },
];

// 秀场管理
const showmanageRoutes = [
    { path: '/channelclassification', title: '频道分类', component: ChannelClassification },
    { path: '/channellist', title: '频道列表', component: ChannelList },
    { path: '/channellist/history/', title: '直播间历史记录', component: AsyncComponent(() => import('@/views/showmanage/channellist/pages/srhistory')), show: 'none' },
    { path: '/channellist/recommend/:name', title: '直播间推荐', component: AsyncComponent(() => import('@/views/showmanage/channellist/components/recommend')), show: 'none' },
    { path: '/channellist/prohibit/:accountId', title: '直播间封禁', component: AsyncComponent(() => import('@/views/showmanage/channellist/pages/prohibit')), show: 'none' },
    { path: '/channellist/violation', title: '本场违规', component: AsyncComponent(() => import('@/views/showmanage/channellist/pages/srlist/violation')), show: 'none' },
    { path: '/channellist/starLight/:id', title: '直播间魅力值收入', component: AsyncComponent(() => import('@/views/showmanage/channellist/pages/srlist/starLight')), show: 'none' },
    { path: '/channellist/starValue/:id', title: '直播间人气值收入', component: AsyncComponent(() => import('@/views/showmanage/channellist/pages/srlist/starValue')), show: 'none' },
    { path: '/channellist/sell/:id', title: '直播间销售分红', component: AsyncComponent(() => import('@/views/showmanage/channellist/pages/srlist/sell')), show: 'none' },
    { path: '/channellist/videocreate/', title: '创建视频', component: AsyncComponent(() => import('@/views/showmanage/channellist/pages/videocreate')), show: 'none' },
    { path: '/channellist/videoinfo/:id', title: '视频详情', component: AsyncComponent(() => import('@/views/showmanage/channellist/pages/videoinfo')), show: 'none' },
    { path: '/channellist/communitycreate/', title: '创建社区', component: AsyncComponent(() => import('@/views/showmanage/channellist/pages/communitycreate')), show: 'none' },
    { path: '/channellist/communityinfo/:id', title: '社区详情', component: AsyncComponent(() => import('@/views/showmanage/channellist/pages/communityinfo')), show: 'none' },
    { path: '/propsmanage', title: '道具管理', component: PropsManage },
    { path: '/stickypaper', title: '贴纸管理', component: StickyPaper },
    { path: '/music', title: '音乐管理', component: Music },
    { path: '/presentmanagement', title: '礼物管理', component: Presentmanagement },
    { path: '/atmospheremanagement', title: '氛围管理', component: Atmospheremanagement },
    { path: '/forbitrecord', title: '禁封记录', component: Forbitrecord },
];

// 评论管理
const commentmanageRoutes = [
    { path: '/productcomment', title: '商品评论', component: ProductComment },
    { path: '/productcomment/contentInfo/:goodsId/:goodsScoreId', title: '商品评论详情', component: AsyncComponent(() => import('@/views/commentmanage/productcomment/contentInfo')), show: 'none' },
    { path: '/videocomment', title: '视频评论', component: VideoComment },
    { path: '/videocomment/contentInfo/:id', title: '评视频评论详情', component: AsyncComponent(() => import('@/views/commentmanage/videocomment/contentInfo')), show: 'none' },
    { path: '/communitycomment', title: '社区评论', component: CommunityComment },
    { path: '/communitycomment/contentInfo/:id', title: '社区评论详情', component: AsyncComponent(() => import('@/views/commentmanage/communitycomment/contentInfo')), show: 'none' },
    { path: '/sensitivewords', title: '敏感词库上传', component: Sensitivewords },
    { path: '/hotelcomment', title: '酒店评论', component: Hotelcomment },
    { path: '/hotelcomment/contentInfo/:goodsId/:goodsScoreId', title: '评论详情', component: AsyncComponent(() => import('@/views/commentmanage/hotelcomment/contentInfo')), show: 'none' },
];

// 举报管理
const reportmanageRoutes = [
    { path: '/userReport', title: '用户举报', component: userReport },
    { path: '/userReport/detail/:id', title: '举报详情', component: AsyncComponent(() => import('@/views/reportmanage/userReport/pages/info')), show: 'none' },
    { path: '/userReport/deal/:id', title: '举报处理', component: AsyncComponent(() => import('@/views/reportmanage/userReport/pages/deal')), show: 'none' },
    { path: '/showReport', title: '秀场举报', component: showReport },
    { path: '/showReport/detail/:id', title: '举报详情', component: AsyncComponent(() => import('@/views/reportmanage/showReport/pages/info')), show: 'none' },
    { path: '/showReport/deal/:id', title: '举报处理', component: AsyncComponent(() => import('@/views/reportmanage/showReport/pages/deal')), show: 'none' },
    { path: '/showReport/communityinfo/:id', title: '图文详情', component: AsyncComponent(() => import('@/views/reportmanage/showReport/pages/communityinfo')), show: 'none' },
    { path: '/productReport', title: '商品举报', component: productReport },
    { path: '/productReport/detail/:id', title: '举报详情', component: AsyncComponent(() => import('@/views/reportmanage/productReport/pages/info')), show: 'none' },
    { path: '/productReport/deal/:id', title: '举报处理', component: AsyncComponent(() => import('@/views/reportmanage/productReport/pages/deal')), show: 'none' },
    { path: '/reportReason', title: '举报原因', component: reportReason },
];

// 广告管理
const advertmanageRoutes = [
    { path: '/advertPlacement', title: '广告投放', component: advertPlacement },
    { path: '/advertPlacement/add/', title: '新建广告投放', component: AsyncComponent(() => import('@/views/advertmanage/advertPlacement/pages/add')), show: 'none' },
    { path: '/advertPlacement/detail/:id', title: '新建广告投放详情', component: AsyncComponent(() => import('@/views/advertmanage/advertPlacement/pages/info')), show: 'none' },
    { path: '/advertPlacement/audit/:id', title: '新建广告投放审核', component: AsyncComponent(() => import('@/views/advertmanage/advertPlacement/pages/audit')), show: 'none' },
    { path: '/advertPlacement/edit/:id', title: '广告投放编辑', component: AsyncComponent(() => import('@/views/advertmanage/advertPlacement/pages/edit')), show: 'none' },
    { path: '/advertStrateg', title: '广告策略', component: advertStrateg },
    { path: '/advertStrateg/add/', title: '创建投放策略', component: AsyncComponent(() => import('@/views/advertmanage/advertStrateg/pages/add')), show: 'none' },
    { path: '/advertStrateg/detail/:id', title: '投放策略详情', component: AsyncComponent(() => import('@/views/advertmanage/advertStrateg/pages/info')), show: 'none' },
    { path: '/advertList', title: '广告位列表', component: advertList },
    { path: '/advertList/add', title: '创建广告位列表', component: AsyncComponent(() => import('@/views/advertmanage/advertList/pages/add')), show: 'none' },
    { path: '/advertList/edit/:id', title: '广告位列表编辑', component: AsyncComponent(() => import('@/views/advertmanage/advertList/pages/edit')), show: 'none' },
    { path: '/advertComment', title: '广告秀评论设置', component: advertComment },
    { path: '/advertClassify', title: '广告分类', component: advertClassify },
    { path: '/advertService', title: '轮播图广告管理', component: advertService },
    { path: '/advertService/add', title: '新增轮播图', component: AsyncComponent(() => import('@/views/advertmanage/advertService/pages/createInfo')), show: 'none' },
    { path: '/advertService/productlist/detail/:id', title: '商品详情', component: AsyncComponent(() => import('@/views/productmanage/list/pages/detail')), show: 'none' },
];

// 兑换商城
const starValueShopRoutes = [
    { path: '/starValueClassify', title: '分类列表', component: StarValueClassify },
    { path: '/starValueList', title: '兑换商品', component: StarValueList },
    { path: '/starValueList/detail', title: '人气值商品详情', component: AsyncComponent(() => import('@/views/starValueShop/starValueList/pages/detail')), show: 'none' },
    { path: '/starValueList/create/', title: '创建人气值商品', component: AsyncComponent(() => import('@/views/starValueShop/starValueList/pages/createOrEdit')), show: 'none' },
    { path: '/starValueList/editInfo/:id', title: '编辑人气值商品', component: AsyncComponent(() => import('@/views/starValueShop/starValueList/pages/editInfo')), show: 'none' },
    { path: '/forRecord', title: '兑换记录', component: ForRecord },
    { path: '/forRecord/orderdetail', title: '查看详情', component: AsyncComponent(() => import('@/views/starValueShop/forRecord/pages/orderdetail')), show: 'none' },
    { path: '/redemption', title: '提货券专区', component: Redemption },
    { path: '/redemption/detail/:id', title: '查看详情', component: AsyncComponent(() => import('@/views/starValueShop/redemption/detail')), show: 'none' },
    { path: '/franchisevalue', title: '特权值专区', component: FranchiseValue },
    { path: '/franchisevalue/detail/:id', title: '查看详情', component: AsyncComponent(() => import('@/views/starValueShop/redemption/detail')), show: 'none' },
];

// 消息管理
const messagemanageRoutes = [
    { path: '/messageClassify', title: '消息分类', component: messageClassify },
    { path: '/messageCenter', title: '消息中心', component: messageCenter },
    { path: '/messageMove', title: '消息推送', component: messageMove },
    { path: '/messageMove/add', title: '新建消息', component: AsyncComponent(() => import('@/views/messagemanage/messageMove/pages/add')), show: 'none' },
    { path: '/messageMove/detail/:id', title: '消息详情', component: AsyncComponent(() => import('@/views/messagemanage/messageMove/pages/detail')), show: 'none' },
    { path: '/messageMove/edit/:id', title: '编辑消息', component: AsyncComponent(() => import('@/views/messagemanage/messageMove/pages/edit')), show: 'none' },
];

// 星球攻略  
const strategymmanageRoutes = [
    { path: '/classification', title: '星球攻略分类', component: Classification },
    { path: '/classification/createInfo/', title: '分类', component: AsyncComponent(() => import('@/views/xqstrategy/classification/pages/createInfo/')), show: 'none' },
    { path: '/list', title: '星球攻略列表', component: List },
    { path: '/list/detailInfo/:id/:belongSystem', title: '攻略详情', component: AsyncComponent(() => import('@/views/xqstrategy/list/pages/detailInfo')), show: 'none' },
    { path: '/list/editInfo/:id/:belongSystem', title: '编辑攻略', component: AsyncComponent(() => import('@/views/xqstrategy/list/pages/editInfo')), show: 'none' },
    { path: '/list/createInfo/:belongSystem', title: '新建攻略', component: AsyncComponent(() => import('@/views/xqstrategy/list/pages/createInfo')), show: 'none' },
    { path: '/noticecategory', title: '商户须知分类', component: Noticecategory },
    { path: '/noticelists', title: '商户须知列表', component: Noticelists },
    { path: '/noticelists/problem', title: '问题管理', component: AsyncComponent(() => import('@/views/xqstrategy/noticelists/pages/problem')), show: 'none' },
    { path: '/noticelists/newEditor', title: '问题', component: AsyncComponent(() => import('@/views/xqstrategy/noticelists/pages/problem/newEditor')), show: 'none' },
    { path: '/noticelists/detail', title: '问题详情', component: AsyncComponent(() => import('@/views/xqstrategy/noticelists/pages/problem/detail')), show: 'none' },
    { path: '/initdesclist', title: '初始说明列表', component: Initdesclist },
    { path: '/initdesclist/edit/:id', title: '初始说明编辑', component: AsyncComponent(() => import('@/views/xqstrategy/initdesclist/pages/all/edit')), show: 'none' },
];

// 图文管理  
const graphicsmanageRoutes = [
    { path: '/list', title: '图文列表', component: GraphicsList },
    { path: '/list/:type/:id', title: '图文详情', component: AsyncComponent(() => import('@/views/graphicsmanage/graphicslist/pages/graphicscreate')), show: 'none' },
    { path: '/category', title: '图文分类管理', component: GraphicsCategory },
];

// 供应商管理
const suppliermanageRoutes = [
    { path: '/supplierlist', title: '供应商', component: supplierlist },
    { path: '/supplierlist/newshop/:id', title: '新建店铺', component: AsyncComponent(() => import('@/views/suppliermanage/supplierlist/pages/shop')), show: 'none' },
    { path: '/supplierlist/detail/:id', title: '查看详情', component: AsyncComponent(() => import('@/views/suppliermanage/supplierlist/pages/detail')), show: 'none' },
    { path: '/supplierlist/create', title: '新建供应商', component: AsyncComponent(() => import('@/views/suppliermanage/supplierlist/pages/create')), show: 'none' },
    { path: '/purchasecontract', title: '采购合同', component: PurchaseContract },
    { path: '/purchasecontract/createinfo', title: '新建采购合同', component: AsyncComponent(() => import('@/views/suppliermanage/purchasecontract/pages/createInfo')), show: 'none' },
    { path: '/purchasecontract/editinfo/:id', title: '编辑采购合同', component: AsyncComponent(() => import('@/views/suppliermanage/purchasecontract/pages/editInfo')), show: 'none' },
    { path: '/purchasecontract/detailinfo/:id', title: '采购合同详情', component: AsyncComponent(() => import('@/views/suppliermanage/purchasecontract/pages/detailInfo')), show: 'none' },
    { path: '/access', title: '入网查询', component: Access },
    { path: '/access/detail/:id/:accessType/:subjectType', title: '查看详情', component: AsyncComponent(() => import('@/views/businessmanage/merchantunion/pages/detail')), show: 'none' },
    { path: '/access/huifuinfo/:id/:accessType/:subjectType', title: '查看详情', component: AsyncComponent(() => import('@/views/businessmanage/merchantunion/huifupay/detail')), show: 'none' },
];

// 系统设置    
const systemmanageRoutes = [
    { path: '/appfitting', title: '用户APP组件管理', component: AppFitting },
    { path: '/storefitting', title: '店铺装修组件管理', component: StoreFitting },
    { path: '/appfitting/add', title: '添加组件', component: AsyncComponent(() => import('@/views/systemmanage/customfitting/pages/main')), show: 'none' },
    { path: '/customfitting', title: '自定义组件管理', component: CustomFitting },
    { path: '/materialcategory', title: '素材分类管理', component: MaterialCategory },
    { path: '/materiallist', title: '素材列表管理', component: MaterialList },
    { path: '/logistics', title: '物流管理', component: Logistics },
    { path: '/logistics/runningerrandsdetail/:id', title: '配送详情', component: AsyncComponent(() => import('@/views/systemmanage/logistics/pages/runningErrandsDetail')), show: 'none' },
    { path: '/charmgrade', title: '魅力等级设置', component: Charmgrade },
    { path: '/charmgrade/createInfo', title: '魅力等级新增', component: AsyncComponent(() => import('@/views/systemmanage/charmgrade/pages/createInfo')), show: 'none' },
    { path: '/charmgrade/editorInfo/:id', title: '魅力等级编辑', component: AsyncComponent(() => import('@/views/systemmanage/charmgrade/pages/editorInfo')), show: 'none' },
    { path: '/membersgrade', title: '会员等级设置', component: Membersgrade },
    { path: '/membersgrade/dailytalksetting/createInfo', title: '会员等级新增', component: AsyncComponent(() => import('@/views/systemmanage/membersgrade/dailytalksetting/createInfo')), show: 'none' },
    { path: '/membersgrade/dailytalksetting/editorInfo/:id', title: '会员等级编辑', component: AsyncComponent(() => import('@/views/systemmanage/membersgrade/dailytalksetting/editorInfo')), show: 'none' },
    { path: '/membersgrade/mlxsetting/editorInfo/:id', title: '牧佬鲜会员等级编辑', component: AsyncComponent(() => import('@/views/systemmanage/membersgrade/mlxsetting/editorInfo')), show: 'none' },
    { path: '/showset', title: '秀场设置', component: Showset },
    { path: '/showset/popularcreate', title: '人气规则创建', component: AsyncComponent(() => import('@/views/systemmanage/showset/pages/popular/popularcreate')), show: 'none' },
    { path: '/showset/popularedit/:id', title: '人气规则编辑', component: AsyncComponent(() => import('@/views/systemmanage/showset/pages/popular/popularedit')), show: 'none' },
    { path: '/showset/populardefaultedit/:id', title: '人气规则编辑', component: AsyncComponent(() => import('@/views/systemmanage/showset/pages/popular/populardefaultedit')), show: 'none' },
    { path: '/showset/robotList', title: '机器人列表', component: AsyncComponent(() => import('@/views/systemmanage/showset/pages/popular/robotList')), show: 'none' },
    { path: '/showset/popularMessage', title: '消息生成', component: AsyncComponent(() => import('@/views/systemmanage/showset/pages/popular/popularMessage')), show: 'none' },
    { path: '/showset/createInfo', title: '推荐规则创建', component: AsyncComponent(() => import('@/views/systemmanage/showset/pages/recommend/createInfo')), show: 'none' },
    { path: '/showset/editInfo/:id', title: '推荐规则编辑', component: AsyncComponent(() => import('@/views/systemmanage/showset/pages/recommend/editInfo')), show: 'none' },
    { path: '/showset/cyclerecord/:id', title: '周期记录表', component: AsyncComponent(() => import('@/views/systemmanage/showset/pages/showbeanrules/cyclerecord')), show: 'none' },
    { path: '/showset/allcyclerecord', title: '全部周期记录表', component: AsyncComponent(() => import('@/views/systemmanage/showset/pages/showbeanrules/allcyclerecord')), show: 'none' },
    { path: '/showset/vionlation', title: '周期记录表', component: AsyncComponent(() => import('@/views/systemmanage/showset/pages/taskViolation/vionlation')), show: 'none' },
    { path: '/financial', title: '财务参数设置', component: Financial },
    { path: '/marketing', title: '营销活动规则设置', component: Marketing },
    { path: '/starmarketing', title: '兑换商城设置', component: Starmarketing },
    { path: '/costname', title: '费用名称', component: CostName },
    { path: '/mallsetting', title: '商城设置', component: Mallsetting },
    { path: '/mallsetting/createInfo', title: '推荐规则创建', component: AsyncComponent(() => import('@/views/systemmanage/mallsetting/pages/recommend/createInfo')), show: 'none' },
    { path: '/mallsetting/editInfo/:id', title: '推荐规则编辑', component: AsyncComponent(() => import('@/views/systemmanage/mallsetting/pages/recommend/editInfo')), show: 'none' },
    { path: '/riskcontrol', title: '风控体系设置', component: Riskcontrol },
    { path: '/allocation', title: '计费分配比例设置', component: Allocation },
    { path: '/allocation/auditInfo/:id', title: '计费分配比例审核', component: AsyncComponent(() => import('@/views/systemmanage/allocation/pages/auditInfo')), show: 'none' },
    { path: '/allocation/detailInfo/:id', title: '计费分配比例详情', component: AsyncComponent(() => import('@/views/systemmanage/allocation/pages/detailInfo')), show: 'none' },
    { path: '/financialsetting', title: '财务设置', component: Financialsetting },
    { path: '/version', title: '版本管理', component: Version },
    { path: '/version/createInfo', title: '版本管理创建', component: AsyncComponent(() => import('@/views/systemmanage/version/pages/createInfo')), show: 'none' },
    { path: '/version/editInfo/:id', title: '版本管理编辑', component: AsyncComponent(() => import('@/views/systemmanage/version/pages/editInfo')), show: 'none' },
    { path: '/watermark', title: '水印管理', component: Watermark },
    { path: '/convertiblepro', title: '人气值兑换魅力值比率', component: ConvertiblePro },
    { path: '/merchantpermission', title: '商户权限设置', component: MerchantPermission },
    { path: '/showbeanrules', title: '信用值兑换规则', component: ShowBeanRules },
    // { path: '/languagePack', title: '语音包', component: LanguagePack }, // BUG10819:隐藏语音包
    { path: '/hotCity', title: '热门城市设置', component: HotCity },
    { path: '/hotelOrderSys', title: '酒店订单设置', component: HotelOrderSys },
    { path: '/servicesetting', title: '服务商等级设置', component: Servicesetting },
    { path: '/servicesetting/createInfo', title: '服务商等级新增', component: AsyncComponent(() => import('@/views/systemmanage/servicesetting/pages/createInfo')), show: 'none' },
    { path: '/servicesetting/editorInfo/:id', title: '服务商等级编辑', component: AsyncComponent(() => import('@/views/systemmanage/servicesetting/pages/editorInfo')), show: 'none' },
    { path: '/showdecorate', title: '每日说装修模板管理', component: ShowDecorate },
    { path: '/showdecorate/templatelist/:category', title: '模板列表', component: AsyncComponent(() => import('@/views/systemmanage/showdecorate/templateList')), show: 'none' },
    { path: '/shopdecorate', title: '店铺装修模板管理', component: ShopDecorate },
    { path: '/shopdecorate/templatelist/:category', title: '模板列表', component: AsyncComponent(() => import('@/views/systemmanage/shopdecorate/templateList')), show: 'none' },
    { path: '/templateclassify', title: '自定义模板分类管理', component: Templateclassify },
    { path: '/template', title: '自定义模板管理', component: Template },
    { path: '/paymentChannel', title: '支付通道设置', component: PaymentChannel },
    { path: '/premiumyield', title: '股权溢价收益权设置', component: PremiumYield },
    { path: '/suppliesset', title: '供应链设置', component: SuppliesSet },
    { path: '/printersetup', title: '打印机设置', component: PrinterSetup },
    { path: '/printersetup/cloudPrinter/modelsEdit/:id', title: '编辑打印机信息', component: AsyncComponent(() => import('@/views/systemmanage/printersetup/cloudPrinter/modelsEdit')), show: 'none' },
];

//星球商学院
const commercialcollegeRoutes = [
    { path: '/groupmanage', title: '组别管理', component: GroupManage },//组别管理
    { path: '/groupmanage/viewstudent/:id', title: '查看学员', component: AsyncComponent(() => import('@/views/commercialcollege/groupmanage/pages/viewstudent')), show: 'none' },//查看学员
    { path: '/groupmanage/viewstudent/:id/:groupName', title: '查看学员', component: AsyncComponent(() => import('@/views/commercialcollege/groupmanage/pages/viewstudent')), show: 'none' },//查看学员
    { path: '/lecturersmanage', title: '讲师管理', component: LecturerManage },
    { path: '/lecturersmanage/course/:id', title: '课程管理', component: AsyncComponent(() => import('@/views/commercialcollege/lecturersmanage/pages/course')), show: 'none' },
    { path: '/lecturersmanage/students/:id', title: '学员管理', component: AsyncComponent(() => import('@/views/commercialcollege/lecturersmanage/pages/course/students')), show: 'none' },
    { path: '/livelist', title: '直播列表', component: LiveList },//直播列表
    { path: '/livelist/signlist/:id', title: '签到查看', component: AsyncComponent(() => import('@/views/commercialcollege/livelist/pages/signlist')), show: 'none' },
    { path: '/livelist/starLight/:id', title: '直播间魅力值收入', component: AsyncComponent(() => import('@/views/commercialcollege/livelist/pages/srlist/starLight')), show: 'none' },
    { path: '/livelist/starValue/:id', title: '直播间人气值收入', component: AsyncComponent(() => import('@/views/commercialcollege/livelist/pages/srlist/starValue')), show: 'none' },
    { path: '/livelist/violation', title: '本场违规', component: AsyncComponent(() => import('@/views/commercialcollege/livelist/pages/violation')), show: 'none' },
    { path: '/livelist/prohibit/:accountId', title: '直播间封禁', component: AsyncComponent(() => import('@/views/commercialcollege/livelist/pages/prohibit')), show: 'none' },
];

//知识付费管理
const knowledgepayRoutes = [
    { path: '/channelclassifiy', title: '频道分类', component: KChannelClassify },
    { path: '/channellist', title: '频道列表', component: KChannellList },
    { path: '/channellist/liverecommend', title: '直播间推荐列表', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/livelist/Recommend')), show: 'none' },
    { path: '/channellist/livehistory', title: '直播间历史记录', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/livelist/History')), show: 'none' },
    { path: '/channellist/violation', title: '本场违规', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/livelist/violation')), show: 'none' },
    { path: '/channellist/liveprohibit/:accountId', title: '直播间封禁', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/livelist/Prohibit')), show: 'none' },
    { path: '/channellist/livebeans/:id', title: '直播间魅力值收入', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/livelist/Beans')), show: 'none' },
    { path: '/channellist/livesell/:id', title: '直播间销售商品分红', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/livelist/Sell')), show: 'none' },
    { path: '/channellist/liveknowledge/:id', title: '直播间知识付费收益', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/livelist/Knowledge')), show: 'none' },

    { path: '/channellist/videorecommend', title: '视频推荐列表', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/videolist/Recommend')), show: 'none' },
    { path: '/channellist/createvideo', title: '创建视频', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/videolist/Create')), show: 'none' },
    { path: '/channellist/videodetail/:id', title: '视频详情', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/videolist/Detail')), show: 'none' },
    { path: '/channellist/videobeans/:id', title: '视频魅力值收入', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/videolist/Beans')), show: 'none' },
    { path: '/channellist/videosell/:id', title: '视频销售商品分红', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/videolist/Sell')), show: 'none' },
    { path: '/channellist/videoknowledge/:id', title: '视频知识付费收益', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/videolist/Knowledge')), show: 'none' },

    { path: '/channellist/communityrecommend', title: '社区推荐列表', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/communitylist/Recommend')), show: 'none' },
    { path: '/channellist/communitydetail/:id', title: '社区详情', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/communitylist/Detail')), show: 'none' },
    { path: '/channellist/communitybeans/:id', title: '社区魅力值收入', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/communitylist/Beans')), show: 'none' },
    { path: '/channellist/communitysell/:id', title: '社区销售商品分红', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/communitylist/Sell')), show: 'none' },
    { path: '/channellist/communityknowledge/:id', title: '社区知识付费收益', component: AsyncComponent(() => import('@/views/knowledgepay/channellist/communitylist/Knowledge')), show: 'none' },
];

//报表管理
const statementmanageRoutes = [
    { path: '/template', title: '报表模板管理', component: TemplateForm },//模板管理
    { path: '/template/detailInfo', title: '详情', component: AsyncComponent(() => import('@/views/statementmanage/template/pages/detailInfo')), show: 'none' },//详情
    { path: '/records', title: '报表生成记录', component: RecordsForm },//报表生成记录
    { path: '/records/recordInfo', title: '生成报表', component: AsyncComponent(() => import('@/views/statementmanage/records/pages/recordInfo')), show: 'none' },
];

//信用值管理
const showbeanmanageRoutes = [
    { path: '/beanDetails', title: '信用值明细', component: BeanDetail },
    { path: '/stockDividends', title: '股票收益', component: StockDividends },
    { path: '/stockDividends/:type', title: '股票收益信息', component: AsyncComponent(() => import('@/views/starValueShop/stockDividends/pages/detail')), show: 'none' },
    { path: '/stockDividends/:type/:id', title: '股票收益信息', component: AsyncComponent(() => import('@/views/starValueShop/stockDividends/pages/detail')), show: 'none' },
    { path: '/merchantDrawApply', title: '商户信用值支取申请', component: MerchantDrawApply },
    { path: '/merchantDrawApply/detail/:id', title: '支取详情', component: AsyncComponent(() => import('@/views/starValueShop/merchantDrawApply/pages/detail')), show: 'none' },
];

//酒店管理
const hotelmanageRoutes = [
    { path: '/facilitymanage', title: '设施管理', component: FacilityManage },
    { path: '/starlevelmanage', title: '酒店星级', component: StarLevelManage },
    { path: '/homestaylayout', title: '民宿户型', component: HomestayLayout },
    { path: '/cancelreasons', title: '取消原因', component: CancelReasons },
];

// 房源管理
const houseresourcemanageRoutes = [
    { path: '/houselist', title: '房源列表', component: HouseList },
    { path: '/houselist/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/houseresourcemanage/houselist/detail')), show: 'none' },
    { path: '/houseclassif', title: '房源分类', component: HouseClassify },
];

// 接单管理
const receiveOrderRoutes = [
    { path: '/receiveorderlist', title: '接单列表', component: ReceiveOrderList },
    { path: '/receiveorderlist/waitreceive/detail/:id', title: '待接单详情', component: AsyncComponent(() => import('@/views/receiveordermanage/receiveorderlist/pages/waitreceive/detail')), show: 'none' },
    { path: '/receiveorderlist/receiverecord/detail/:id', title: '接单记录详情', component: AsyncComponent(() => import('@/views/receiveordermanage/receiveorderlist/pages/receiverecord/detail')), show: 'none' }
];

// 渠道管理
const channelmanageRoutes = [
    { path: '/channellist', title: '渠道列表', component: ChannelManageList },
    { path: '/channellist/channeldetail/:id', title: '详情', component: AsyncComponent(() => import('@/views/channelmanage/channellist/detail/index')), show: 'none' },
    { path: '/levelsetting', title: '渠道会员等级设置', component: LevelSetting },
    { path: '/levelsetting/edit/:id', title: '会员等级编辑', component: AsyncComponent(() => import('@/views/channelmanage/levelSetting/edit')), show: 'none' },
];

// 数据统计
const statisticsinfoRoutes = [
    { path: '/quantitystatistics', title: '统计信息', component: QuantityStatistics },
    { path: '/activityTypeStatistics', title: '活动类型统计', component: ActivityTypeStatistics },
    { path: '/marketingActivityStatistics', title: '营销活动统计', component: MarketingActivityStatistics },
    { path: '/statisSaleOrder', title: '平台商品销量量统计', component: StatisSaleOrder },
    { path: '/statisTopGoods', title: '平台top20商品统计', component: StatisTopGoods },
    { path: '/statisStorehouseSaleData', title: '仓库销售数据统计', component: StatisStorehouseSaleData },
    { path: '/statisStorehouseAfterSaleData', title: '仓库售后数据统计', component: StatisStorehouseAfterSaleData },
    { path: '/orderStatistics', title: '运营中心订单统计', component: OrderStatistics },
];

// 路由配置
const routes = [
    { path: '/', component: Login, exact: true },
    { path: '/login', component: Login },
    { path: '/payfail', component: PayFail },
    { path: '/paysucess', component: PaySucess },
    {
        path: '/home', component: Home, routes: [
            { path: '/', title: '首页', component: Welcome, exact: true },
            { path: '/setting', title: '个人设置', component: Setting },
            { path: '/workbench', icon: require('@/assets/image/xitongguanli.png'), title: '工作台', childrenNode: workbench },
            { path: '/operationcenter', icon: require('@/assets/image/yunying.png'), title: '市场管理', childrenNode: operationcenterRoutes },
            { path: '/usermanage', icon: require('@/assets/image/user.png'), title: '用户管理', childrenNode: usermanagerRoutes },
            { path: '/businessmanage', icon: require('@/assets/image/shanghu.png'), title: '商户管理', childrenNode: businessmanageRoutes },
            { path: '/productmanage', icon: require('@/assets/image/shangpin.png'), title: '商品管理', childrenNode: productmanageRoutes },
            { path: '/ordermanage', icon: require('@/assets/image/dingdang.png'), title: '订单管理', childrenNode: ordermanageRoutes },
            { path: '/marketingmanage', icon: require('@/assets/image/yingxiao.png'), title: '营销管理', childrenNode: marketingmanageRoutes },
            { path: '/storemanage', icon: require('@/assets/image/stores.png'), title: '店铺管理', childrenNode: storemanageRoutes },
            { path: '/taskmanage', icon: require('@/assets/image/renwu.png'), title: '任务管理', childrenNode: taskmanageRoutes },
            { path: '/salemanage', icon: require('@/assets/image/shouhou.png'), title: '售后管理', childrenNode: salemanageRoutes },
            { path: '/auditmanage', icon: require('@/assets/image/shenhe.png'), title: '审核管理', childrenNode: auditmanageRoutes },
            { path: '/customermanage', icon: require('@/assets/image/kefu.png'), title: '客服管理', childrenNode: customermanageRoutes },
            { path: '/storehousefinancialmanage', icon: require('@/assets/image/caiwu.png'), title: '财务管理', childrenNode: storehousefinancialmanageRoutes },
            { path: '/showbeanmanage', icon: require('@/assets/image/xzsc.png'), title: '信用值管理', childrenNode: showbeanmanageRoutes },
            { path: '/statistical', icon: require('@/assets/image/tongji.png'), title: '统计分析', childrenNode: statisticalRoutes },
            { path: '/statisticsofdistributor', icon: require('@/assets/image/tongji.png'), title: '分销业务统计', childrenNode: statisticsofdistributorRoutes },
            { path: '/servicelistingmanage', icon: require('@/assets/image/servicehistory.png'), title: '服务清单', childrenNode: servicelistingRoutes },
            { path: '/commercialcollege', icon: require('@/assets/image/xqsxy.png'), title: '星球商学院', childrenNode: commercialcollegeRoutes },
            { path: '/suppliermanage', icon: require('@/assets/image/kefu.png'), title: '供应商管理', childrenNode: suppliermanageRoutes },
            { path: '/contractmanagement', icon: require('@/assets/image/hetong.png'), title: '合同管理', childrenNode: contractmanagementRoutes },
            { path: '/privilegemanage', icon: require('@/assets/image/quanxian.png'), title: '权限管理', childrenNode: privilegemanageRoutes },
            { path: '/showmanage', icon: require('@/assets/image/xiuchang.png'), title: '秀场管理', childrenNode: showmanageRoutes },
            { path: '/commentmanage', icon: require('@/assets/image/pinglun.png'), title: '评论管理', childrenNode: commentmanageRoutes },
            { path: '/reportmanage', icon: require('@/assets/image/jubao.png'), title: '举报管理', childrenNode: reportmanageRoutes },
            { path: '/advertmanage', icon: require('@/assets/image/guanggao.png'), title: '广告管理', childrenNode: advertmanageRoutes },
            { path: '/starValueShop', icon: require('@/assets/image/xzsc.png'), title: '兑换商城', childrenNode: starValueShopRoutes },
            { path: '/messagemanage', icon: require('@/assets/image/xiaoxx.png'), title: '消息管理', childrenNode: messagemanageRoutes },
            { path: '/xqstrategy', icon: require('@/assets/image/gonglue.png'), title: '星球攻略', childrenNode: strategymmanageRoutes },
            { path: '/graphicsmanage', icon: require('@/assets/image/gonglue.png'), title: '图文管理', childrenNode: graphicsmanageRoutes },
            { path: '/systemmanage', icon: require('@/assets/image/xitongguanli.png'), title: '系统管理', childrenNode: systemmanageRoutes },
            { path: '/knowledgepay', icon: require('@/assets/image/xitongguanli.png'), title: '知识付费管理', childrenNode: knowledgepayRoutes },
            { path: '/statementmanage', icon: require('@/assets/image/xqsxy.png'), title: '报表管理', childrenNode: statementmanageRoutes },
            { path: '/hotelmanage', icon: require('@/assets/image/xqsxy.png'), title: '酒店管理', childrenNode: hotelmanageRoutes },
            { path: '/receiveordermanage', icon: require('@/assets/image/xqsxy.png'), title: '接单管理', childrenNode: receiveOrderRoutes },
            { path: '/houseresourcemanage', icon: require('@/assets/image/xqsxy.png'), title: '房源管理', childrenNode: houseresourcemanageRoutes },
            { path: '/channelmanage', icon: require('@/assets/image/xqsxy.png'), title: '渠道管理', childrenNode: channelmanageRoutes },
            { path: '/statisticsinfo', icon: require('@/assets/image/tongji.png'), title: '统计信息', childrenNode: statisticsinfoRoutes },
        ]
    },
];

export default routes;