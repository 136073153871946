/**
 * 商品列表-正常商品
 */
import React from 'react';
import { connect } from 'react-redux';
import { message, Spin, Progress } from 'antd';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XInputNum, XSelect, XOKButton, XCancelButton, XUploadExcel, XTextArea } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import Ban from './modal/Ban';
import VirtualSales from './modal/VirtualSales';
import RiceBean from './modal/RiceBean';
import ImportFail from './modal/ImportFail';
import { GoodsClassify, GDCGoodsState } from '@/vcomps';
import * as actions from '@/reducers/productmanage/list/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/productmanage/list/model';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { SearchBox, SearchItem } from '@/components/Layout';
import EditName from './modal/EditName';
import { setSessionStorage } from '@/assets/js/storage';
import SettleSetModal from '../modal/SettleSetModal';
import Equity from './modal/Equity';

const Mode = {
    BAN: 'BAN',
    LIST_DETAIL: 'LIST_DETAIL',
};
class Normal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '',
            openModeParam: null,
            goodsName: '',
            username: {},
            goodsNos: '',//货号
            status: {},
            professionType: {},
            label: {},
            categoryId: '', // 商品联级分类
            batchData: [],
            loading: false,
            minShowRiceNum: undefined,
            maxShowRiceNum: undefined,
            marketObj: {},
            virtualFlag: {},
            percent: 0,
            exporting: false,
            failList: [],
            exportState: 'normal',
            minPopularityNum: undefined,
            maxPopularityNum: undefined, settleTypeItem: {}
        };
        this.cleanRef = null;
    }
    componentDidMount() {
        const { getProductStatus, getProductBusinessType, getProductUsername, getProductLabelSele, getMarketSele } = this.props;
        getProductStatus();
        getProductBusinessType();
        getProductUsername();
        getProductLabelSele();
        getMarketSele();
    }
    // 查询触发
    _searchHandle = (useCache) => {
        const {
            goodsName, goodsNos, username, professionType,
            categoryId, pageNum, pageSize, status, label,
            minShowRiceNum, maxShowRiceNum, marketObj,
            virtualFlag, minPopularityNum, maxPopularityNum, settleTypeItem
        } = this.state;
        const tagIds = label.id ? [label.id] : [];
        //使用缓存后刷新页面 goodsNos成了数组
        let nos;
        if (Array.isArray(goodsNos)) {
            nos = goodsNos;
        } else {
            nos = goodsNos ? goodsNos.replace(/，/g, ',').split(',') : [];
        }
        this.searchCache = {
            goodsName,
            goodsNos: nos,//货号
            categoryId,
            tagIds, minShowRiceNum, maxShowRiceNum,
            minPopularityNum, maxPopularityNum,
            isJoinMarketing: marketObj.code,
            virtualFlag: virtualFlag.code,
            goodsState: status.code,
            goodsType: professionType.code,
            merchantId: username.merchantId,
            categorySubType: 'ONLINE', rateMode: settleTypeItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getProductListData(this.searchCache);
        KeepAlive.saveCache(this.searchCache); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            goodsName: '',
            goodsNos: '',
            username: {},
            professionType: {},
            categoryId: '',
            label: {},
            status: {},
            minShowRiceNum: undefined,
            maxShowRiceNum: undefined,
            marketObj: {},
            virtualFlag: {},
            minPopularityNum: undefined,
            maxPopularityNum: undefined, settleTypeItem: {}

        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        this.cleanRef.clean();
    }

    _handleChange = labelCheck => {
        this.setState({ labelCheck });
    };
    //发送请求
    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST').then(() => {
            message.success(winTxt);
            this._searchHandle('useCache');
        }).catch((reject) => {
            message.error(reject.message);
        });
    }
    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'setSales':
                this.setState({ currentOpenMode: 'setSales', openModeParam: id });
                break;
            case 'detail':
                setSessionStorage('goodsmanagegoodslisttabid', 'ONLINE');
                this.props.updateContent('detail', id, 'GOODSINFO');
                break;
            case 'ban':
                this.setState({ currentOpenMode: Mode.BAN, openModeParam: id });
                break;
            case 'batchBan':
                this.setState({ batchData: value });
                break;
            case 'switch_home':
                if (value.isHomeReco == 0) {
                    this._sendReq('/goods/goods/upGoodsRecommend', { categorySubType: 'ONLINE', goodsId: value.id, recommendFlag: '1', type: 'HOME' }, '首页推荐成功');
                } else {
                    this._sendReq('/goods/goods/upGoodsRecommend', { categorySubType: 'ONLINE', goodsId: value.id, recommendFlag: '0', type: 'HOME' }, '首页推取消成功');
                }
                break;
            case 'switch_channel':
                if (value.isCategoryReco == 0) {
                    this._sendReq('/goods/goods/upGoodsRecommend', { categorySubType: 'ONLINE', goodsId: value.id, recommendFlag: '1', type: 'CATEGORY' }, '分类推荐成功');
                } else {
                    this._sendReq('/goods/goods/upGoodsRecommend', { categorySubType: 'ONLINE', goodsId: value.id, recommendFlag: '0', type: 'CATEGORY' }, '分类推荐取消成功');
                }
                break;
            case 'riceBean':
                this.setState({ currentOpenMode: 'riceBean', openModeParam: { id, type: value } });
                break;
            case 'goodsName':
                this.setState({ currentOpenMode: 'goodsName', openModeParam: { id, goodsName: value.goodsName } });
                break;
            case 'settle':
                this.setState({ currentOpenMode: 'settle', openModeParam: { goodsId: id } });
                break;
        }
    }
    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 渲染弹框
    _renderMode = () => {
        const { currentOpenMode, openModeParam, failList } = this.state;
        switch (currentOpenMode) {
            case Mode.BAN:
                return <Ban visible={true} data={openModeParam} closeMode={this._closeMode} />;
            case 'setSales':
                return <VirtualSales visible={true} data={openModeParam} closeMode={this._closeMode} />;
            case 'riceBean':
                return <RiceBean closeMode={this._closeMode} data={openModeParam} />;
            case 'importFail':
                return <ImportFail closeMode={this._closeMode} failList={failList} />;
            case 'goodsName':
                return <EditName data={openModeParam} closeMode={this._closeMode} />;
            case 'settle':
                return <SettleSetModal data={openModeParam} closeMode={this._closeMode} />;
            case 'equity':
                return <Equity data={openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }
    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', percent: 0 }, () => {
            update && this._searchHandle('useCache');
        });
    }
    _recommentList = () => {
        setSessionStorage('goodsmanagegoodslisttabid', 'ONLINE');
        this.props.updateContent('recommend');
    }
    _equity = () => {
        const { batchData } = this.state;
        if (batchData.length === 0) {
            message.error('请选择需要关联的商品');
            return;
        }
        this.setState({ currentOpenMode: 'equity', openModeParam: { ids: batchData } });
    }
    _batchBan = () => {
        const { batchData } = this.state;
        if (batchData.length === 0) {
            message.error('禁用商品不能为空');
            return;
        }
        http('/goods/goods/disableGoods', { batchData, categorySubType: 'ONLINE', }, 'POST').then(() => {
            message.success('禁用成功');
            this._searchHandle('useCache');
        }).catch((e) => {
            message.error(e.message);
        });
    }

    //一键导出
    _onExport = (url) => {
        const { goodsName, goodsNos, username, professionType, categoryId,
            status, label, minShowRiceNum, maxShowRiceNum, marketObj, virtualFlag,
            batchData, minPopularityNum, maxPopularityNum, settleTypeItem } = this.state;
        const tagIds = label.id ? [label.id] : [];
        //使用缓存后刷新页面 goodsNos成了数组
        let nos;
        if (Array.isArray(goodsNos)) {
            nos = goodsNos;
        } else {
            nos = goodsNos ? goodsNos.replace(/，/g, ',').split(',') : [];
        }
        const data = {
            goodsName,
            goodsNos: nos,//货号
            categoryId, tagIds,
            goodsIds: batchData,
            minShowRiceNum, maxShowRiceNum,
            minPopularityNum, maxPopularityNum,
            goodsState: status.code, searchType: 1,
            goodsType: professionType.code,
            merchantId: username.merchantId,
            isJoinMarketing: marketObj.code,
            virtualFlag: virtualFlag.code,
            categorySubType: 'ONLINE', rateMode: settleTypeItem.code
        };
        this.setState({ percent: 0, exportState: 'normal', exporting: false }, () => {
            http(url, data, 'POST')
                .then((response) => {
                    if (response.status == 200) {
                        this.setState({ exporting: true }, () => {
                            this.timer = setInterval(() => {
                                http(url, { searchType: 3, downloadCode: response.result }, 'POST')
                                    .then((response2) => {
                                        if (response2.result < 0) {
                                            message.error('导出为空');
                                            this.setState({ exportState: 'exception' }, () => {
                                                this.setState({ exporting: false });
                                            });
                                            clearTimeout(this.timer);
                                        } else if (response2.result < 100 && response2.result > 0) {
                                            const percent = Number(response2.result);
                                            this.setState({ percent, exportState: 'active' });
                                        } else {
                                            clearTimeout(this.timer);
                                            this.setState({ percent: 100 }, () => {
                                                http(url, { searchType: 2, downloadCode: response.result }, 'POST')
                                                    .then((info) => {
                                                        this.setState({ exporting: false, exportState: 'success' });
                                                        window.location.href = info.result;
                                                    }).catch((e) => {
                                                        message.error(e.message);
                                                    });
                                            });
                                        }
                                    }).catch((e) => {
                                        this.setState({ exporting: false, exportState: 'exception' });
                                        clearTimeout(this.timer);
                                        message.error(e.message);
                                    });
                            }, 1000);
                        });
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
        });
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        const {
            goodsName, goodsNos, status, professionType, username, label,
            minShowRiceNum, maxShowRiceNum, marketObj, virtualFlag, percent,
            exporting, exportState, minPopularityNum, maxPopularityNum, settleTypeItem
        } = this.state;
        const { productListData, productStatus, productBusinessType, productUsername, productLabelSele, marketSele } = this.props;

        return (
            <KeepAlive id='normal' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <Spin spinning={exporting} indicator={<Progress type="circle" width={100} percent={percent} status={exportState} />}>
                    <div className={styles.flexBoxContainer}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='商品名称'
                                    placeholder='请输入商品名称'
                                    bindThis={this}
                                    bindName='goodsName'
                                    value={goodsName}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='商户名称'
                                    placeholder='请选择商户'
                                    renderData={productUsername}
                                    bindThis={this}
                                    bindName='username'
                                    dataIndex='merchantName'
                                    keyIndex='merchantId'
                                    showSearch={true}
                                    value={username.merchantName}
                                />
                            </SearchItem>
                            {/* <SearchItem>
                                <XInput
                                    label='商品货号'
                                    placeholder='请输入商品货号'
                                    bindThis={this}
                                    bindName='goodsNo'
                                    value={goodsNo}
                                />
                            </SearchItem> */}
                            <SearchItem>
                                <XSelect
                                    label='参加营销服务费'
                                    placeholder='请选择'
                                    renderData={marketSele}
                                    bindThis={this}
                                    bindName='marketObj'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={marketObj.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='商品状态'
                                    placeholder='请选择商品状态'
                                    renderData={productStatus}
                                    bindThis={this}
                                    bindName='status'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={status.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='结算设置'
                                    placeholder='请选择结算设置'
                                    renderData={[{ code: 'PLATFORM_SETTLE', value: '平台结算' }, { code: 'PRODUCT_CUSTOM', value: '商品结算' }]}
                                    bindThis={this}
                                    bindName='settleTypeItem'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={settleTypeItem.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='业务类型'
                                    placeholder='请选择业务类型'
                                    renderData={productBusinessType}
                                    bindThis={this}
                                    bindName='professionType'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={professionType.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <GoodsClassify
                                    style={{ marginRight: '20px' }}
                                    label='商品分类'
                                    categorySubType='ONLINE'
                                    ref={ele => this.cleanRef = ele}
                                    onClassifyChange={value => this.setState({ categoryId: value })}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='商品标签'
                                    placeholder='请选择商品标签'
                                    renderData={productLabelSele}
                                    bindThis={this}
                                    bindName='label'
                                    dataIndex='tagName'
                                    keyIndex='id'
                                    value={label.tagName}
                                />
                            </SearchItem>
                            <SearchItem>
                                <GDCGoodsState
                                    style={{ marginRight: '20px' }}
                                    label='是否虚拟商品'
                                    placeholder='请请选择'
                                    bindThis={this}
                                    bindName='virtualFlag'
                                    value={virtualFlag}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInputNum
                                    style={{ marginRight: 0 }}
                                    min={0}
                                    max={9999}
                                    precision={0}
                                    label='赠送信用值'
                                    placeholder='请输入信用值最小数量'
                                    onBlur={e => {
                                        if (maxShowRiceNum && e.target.value > maxShowRiceNum) {
                                            this.setState({ minShowRiceNum: maxShowRiceNum });
                                        } else {
                                            if (e.target.value) {
                                                this.setState({ minShowRiceNum: e.target.value });
                                            }
                                        }
                                    }}
                                    bindThis={this}
                                    bindName='minShowRiceNum'
                                    value={minShowRiceNum}
                                />
                                <XInputNum
                                    min={0}
                                    max={9999}
                                    placeholder='请输入信用值最大数量'
                                    precision={0}
                                    onBlur={e => {
                                        if (minShowRiceNum && e.target.value < minShowRiceNum) {
                                            this.setState({ maxShowRiceNum: minShowRiceNum });
                                        } else {
                                            if (e.target.value) {
                                                this.setState({ maxShowRiceNum: e.target.value });
                                            }
                                        }
                                    }}
                                    bindThis={this}
                                    bindName='maxShowRiceNum'
                                    value={maxShowRiceNum}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInputNum
                                    style={{ marginRight: 0 }}
                                    min={0}
                                    max={9999}
                                    precision={0}
                                    label='赠送人气值'
                                    placeholder='请输入人气值最小数量'
                                    onBlur={e => {
                                        if (maxPopularityNum && e.target.value > maxPopularityNum) {
                                            this.setState({ minPopularityNum: maxPopularityNum });
                                        } else {
                                            if (e.target.value) {
                                                this.setState({ minPopularityNum: e.target.value });
                                            }
                                        }
                                    }}
                                    bindThis={this}
                                    bindName='minPopularityNum'
                                    value={minPopularityNum}
                                />
                                <XInputNum
                                    min={0}
                                    max={9999}
                                    precision={0}
                                    placeholder='请输入人气值最大数量'
                                    onBlur={e => {
                                        if (minPopularityNum && e.target.value < minPopularityNum) {
                                            this.setState({ maxPopularityNum: minPopularityNum });
                                        } else {
                                            if (e.target.value) {
                                                this.setState({ maxPopularityNum: e.target.value });
                                            }
                                        }
                                    }}
                                    bindThis={this}
                                    bindName='maxPopularityNum'
                                    value={maxPopularityNum}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XTextArea
                                    label='商品货号'
                                    style={{ marginRight: 20 }}
                                    inputStyle={{ width: 250, height: 30 }}
                                    placeholder='请输入货号，多个用英文逗号隔开，最多100条'
                                    value={Array.isArray(goodsNos) ? goodsNos.join() : goodsNos}
                                    onChange={value => {
                                        if (value.replace(/，/g, ',').split(',').length < 101) {
                                            this.setState({ goodsNos: value });
                                        } else {
                                            message.warning('商品货号最多100条');
                                        }
                                    }}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                        <div className={styles.operate_box}>
                            {/* <XOKButton style={{ width: '60px' }} label='导出' onClick={this._export} /> */}
                            {/* <XOKButton style={{ width: '120px', marginLeft: '20px' }} label='批量禁用' onClick={this._batchBan} /> */}
                            <XOKButton style={{ width: '100px', marginRight: '20px' }} label='推荐列表' onClick={this._recommentList} />
                            <XOKButton style={{ width: '180px', marginRight: '20px' }} label='商品关联消费者权益' onClick={this._equity} />
                            <ButtonPerssion code='productmanage/productlist/drzsxm'>
                                <XUploadExcel
                                    style={{ width: '140px', marginRight: '20px' }}
                                    label='导入赠送信用值'
                                    apiurl='goods/xiumi/importXiumiSKUList'
                                    failHandle={(info) => {
                                        this.setState({
                                            failList: info.file.response.result,
                                            currentOpenMode: 'importFail',
                                        });
                                    }}
                                    successHandle={() => {
                                        message.success('导入成功');
                                    }}
                                />
                            </ButtonPerssion>
                            <ButtonPerssion code='productmanage/productlist/dczsxm'>
                                <XOKButton
                                    style={{ width: '140px', marginRight: '20px', marginLeft: '20px' }}
                                    label='导出赠送信用值'
                                    onClick={() => this._onExport('/goods/xiumi/xiumiSKUListExport')} />
                            </ButtonPerssion>
                            <ButtonPerssion code='productmanage/productlist/importShowBean'>
                                <XUploadExcel
                                    style={{ width: '140px' }}
                                    label='导入赠送人气值'
                                    apiurl='goods/popularity/importPopularitySKUList'
                                    failHandle={(info) => {
                                        this.setState({
                                            failList: info.file.response.result,
                                            currentOpenMode: 'importFail',
                                        });
                                    }}
                                    successHandle={() => message.success('导入操作成功')}
                                />
                            </ButtonPerssion>
                            <ButtonPerssion code='productmanage/productlist/exportShowBean'>
                                <XOKButton
                                    style={{ width: '140px', marginLeft: '20px' }}
                                    label='导出赠送人气值'
                                    onClick={() => this._onExport('/goods/popularity/popularitySKUListExport')} />
                            </ButtonPerssion>
                        </div>
                        <TableContent paginationChange={this._paginationChange} renderData={productListData} tableAction={this._tableAction} />
                        {this._renderMode()}
                    </div>
                </Spin>
            </KeepAlive>
        );
    }
}

Normal.propTypes = {
    updateContent: PropTypes.func,
    getProductListData: PropTypes.func,
    getProductStatus: PropTypes.func,
    getProductBusinessType: PropTypes.func,
    getProductUsername: PropTypes.func,
    getProductLabelSele: PropTypes.func,
    productListData: PropTypes.object,
    productStatus: PropTypes.array,
    productBusinessType: PropTypes.array,
    productUsername: PropTypes.array,
    productLabelSele: PropTypes.array,
    getMarketSele: PropTypes.func,
    marketSele: PropTypes.array,
};

const mapStateToProps = (state) => ({
    productListData: state.productListData.productListData,
    productStatus: state.productListData.productStatus,
    productBusinessType: state.productListData.productBusinessType,
    productUsername: state.productListData.productUsername.merchantIdAndNames || [],
    productLabelSele: state.productListData.productLabelSele.dataList || [],
    marketSele: state.productListData.marketSele,
});

export default connect(mapStateToProps, { ...actions, ...model.actions })(Normal);