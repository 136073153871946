/**
 * 【商户对账列表】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import MerchantReconciliation from '../merchantreconciliation/main';
import HotelReconciliation from '../hotelreconciliation/main';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '线上商户' },
            { id: 2, label: '酒店商户' }
        ],
        curTabID: 1
    }

    _tabChangeHandle = (curTabID) => {
        this.setState({ curTabID });
    }

    _renderTab = () => {
        switch (this.state.curTabID) {
            case 1:
                return <MerchantReconciliation addOneBreadcrumbPath={addOneBreadcrumbPath} keepSecondNavBreadcrumb={keepSecondNavBreadcrumb} {...this.props} />;
            case 2:
                return <HotelReconciliation addOneBreadcrumbPath={addOneBreadcrumbPath} keepSecondNavBreadcrumb={keepSecondNavBreadcrumb} {...this.props} />;
            default:
                return null;
        }
    }

    render() {

        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                {this._renderTab()}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
};

export default Main;