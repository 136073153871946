import { createModel } from '@/store/tools';


const model = {
    namespace: 'servicehistory',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getProductList': T('getProductList'),
            'getListTableSource': T('getListTableSource'),
            'getHistoryDetail': T('getHistoryDetail'),
            'getHistoryList': T('getHistoryList'),
            'getStoreSelect': T('getStoreSelect'),
            'getTheSales': T('getTheSales'),
        },
        actions: {//服务清单详情
            'getProductList': A('getProductList'), // 商户下拉列表   
            'getListTableSource': A('getListTableSource'), // 列表数据  
            'getHistoryDetail': A('getHistoryDetail'), // 服务清单详情  
            'getHistoryList': A('getHistoryList'), // 服务清单详情---商品列表 
            'getStoreSelect': A('getStoreSelect'), // 销售情况---店铺下拉   
            'getTheSales': A('getTheSales'), // 销售情况列表   
        },
        sagas: {
            'getProductList': S('getProductList', '/merchant/info/listMerchantIdAndName'),
            'getListTableSource': S('getListTableSource', '/goods/goodsrate/listGoodsRate'),
            'getHistoryDetail': S('getHistoryDetail', '/goods/goodsrate/goodsRateInfo', 'POST', 'id'),
            'getHistoryList': S('getHistoryList', '/goods/goodsrate/listRateItem'),
            'getStoreSelect': S('getStoreSelect', '/shop/listAll'),
            'getTheSales': S('getTheSales', 'settle/reconciliationSource/settleOrderDetail'),
        },
        reducers: {
            'getProductList': R('getProductList', { merchantIdAndNames: [] }),
            'tableList': R('getListTableSource', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'historyDetail': R('getHistoryDetail', {}),
            'historyList': R('getHistoryList', []),
            'getStoreList': R('getStoreSelect', { infoListResps: [] }),
            'theSalesList': R('getTheSales', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
        }
    })
};
export default createModel(model);