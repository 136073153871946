//获取/保存意见反馈列表
export const GET_SUGGESTIONS_LIST = 'SUGGESTIONS/GET_SUGGESTIONS_LIST';
export const SET_SUGGESTIONS_LIST = 'SUGGESTIONS/SET_SUGGESTIONS_LIST';
//获取/保存意见反馈详情
export const GET_SUGGESTIONS_DETAIL = 'SUGGESTIONS/GET_SUGGESTIONS_DETAIL';
export const SET_SUGGESTIONS_DETAIL = 'SUGGESTIONS/SET_SUGGESTIONS_DETAIL';
//获取/保存意见反馈类型
export const GET_SUGGESTIONS_TYPE = 'SUGGESTIONS/GET_SUGGESTIONS_TYPE';
export const SET_SUGGESTIONS_TYPE = 'SUGGESTIONS/SET_SUGGESTIONS_TYPE';
//获取/保存反馈端口
export const GET_SUGGESTIONS_PORT = 'SUGGESTIONS/GET_SUGGESTIONS_PORT';
export const SET_SUGGESTIONS_PORT = 'SUGGESTIONS/SET_SUGGESTIONS_PORT';
