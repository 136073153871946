/**
 * 【投放策略页】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { DatePicker, message } from 'antd';
import http from '@/assets/api/http';
import { XInput, XOKButton, XCancelButton, XSelect, showConfirm, XDatePicker } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import AdvertTable from './components/AdvertTable';
import DeleteModal from './modal/DeleteModal';
import { getAdvertStrategList, getAdvertStrategAdsList, getAdvertStrategStartAndStop } from '@/reducers/advertmanage/advertStrateg/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

const { RangePicker } = DatePicker;
class Main extends React.Component {

    state = {
        Status: [{
            id: 1,
            value: '启用'
        }, {
            id: 0,
            value: '禁用',
        }],
        adsense: {},
        companyStatus: {},
        strategyName: '', // 策略名称
        value: '',
        startTime: '',
        endTime: '',
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getAdvertStrategAdsList();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { strategyName, startTime, endTime, adsense, companyStatus, pageNum, pageSize } = this.state;
        this.searchCache = {
            strategyName,
            startTime,
            endTime,
            adSpaceId: adsense.id,
            state: companyStatus.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAdvertStrategList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ strategyName: '', startTime: '', endTime: '', value: '', adsense: {}, pageNum: 1, pageSize: 10, companyStatus: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建用户
    _newHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '创建投放策略',
            path: '/home/advertmanage/advertStrateg/add/'
        });
        history.push({
            pathname: '/home/advertmanage/advertStrateg/add/'
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const { strategyName, startTime, endTime, adsense, companyStatus } = this.state;
        this.setState({
            pageSize,
            pageNum,
        });
        this.searchCache = {
            strategyName,
            startTime,
            endTime,
            adSpaceId: adsense.id,
            state: companyStatus.id,
            pageNum,
            pageSize
        };
        this.props.getAdvertStrategList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (id, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                addOneBreadcrumbPath({
                    title: '投放策略详情',
                    path: `/home/advertmanage/advertStrateg/detail/${id}`
                });
                history.push({
                    pathname: `/home/advertmanage/advertStrateg/detail/${id}`
                });
                break;
            case 'del':
                showConfirm('是否确定删除？', '', () => {
                    http('/adStrategy/delete', { id }, 'POST')
                        .then(() => {
                            message.success('删除成功。');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                // this.setState({ currentOpenMode: 'del', openModeParam: id });
                break;
        }
    }
    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'del':
                return <DeleteModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

    // 状态
    _companyStatusChange = e => {
        this.setState({
            companyStatus: e,
        });
    }

    // 广告位
    _adsenseChange = e => {
        this.setState({
            adsense: e,
        });
    }

    _onChange = e => {
        this.setState({
            value: e,
        });
    }

    // 状态
    _actionHandle = (params) => {
        http('/adStrategy/updateState', params, 'POST')
            .then(() => {
                message.success('状态更新成功!');
                this._searchHandle();
            }).catch((reject) => {
                message.error(reject.message);
            });
        // this.props.getAdvertStrategStartAndStop(params);
    }

    render() {
        const { strategyName, companyStatus, Status, adsense, startTime, endTime } = this.state;
        const { advertStrategList, advertStrategAdsList } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='策略名称'
                                placeholder='请输入'
                                value={strategyName}
                                bindThis={this}
                                bindName='strategyName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='投放时间'
                                value={startTime}
                                bindThis={this}
                                bindName='startTime'
                                isFormat
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                label=''
                                value={endTime}
                                bindThis={this}
                                bindName='endTime'
                                isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='广告位'
                                placeholder='请选择'
                                renderData={advertStrategAdsList}
                                onChange={this._adsenseChange}
                                dataIndex='name'
                                keyIndex='id'
                                value={adsense.name}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={Status}
                                onChange={this._companyStatusChange}
                                dataIndex='value'
                                keyIndex='id'
                                value={companyStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.row2}>
                        <XOKButton
                            style={{ width: '120px' }}
                            label='创建投放策略'
                            onClick={this._newHandle}
                        />
                    </div>
                    <AdvertTable renderData={advertStrategList} actionHandle={this._actionHandle} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getAdvertStrategList: PropTypes.func,
    advertStrategList: PropTypes.object,
    getAdvertStrategAdsList: PropTypes.func,
    advertStrategAdsList: PropTypes.array,
    getAdvertStrategStartAndStop: PropTypes.func
};
const mapStateToProps = (state) => ({
    advertStrategList: state.advertStrateg.advertStrategList,
    advertStrategAdsList: state.advertStrateg.advertStrategAdsList,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getAdvertStrategList, getAdvertStrategAdsList, getAdvertStrategStartAndStop })(Main);