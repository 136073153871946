import { createModel } from '@/store/tools';

const model = {
    namespace: 'beanDetails',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getPaymentTableList': T('getPaymentTableList'),
            'getMerchantList': T('getMerchantList'),
            'getTradeType': T('getTradeType'),
            'getFreezeDetail': T('getFreezeDetail'),
            'getBillingDetail': T('getBillingDetail'),
            'getStateType': T('getStateType'),
            'getUserAccountType': T('getUserAccountType'),
        },
        actions: {
            'getPaymentTableList': A('getPaymentTableList'), // 收支明细列表数据
            'getMerchantList': A('getMerchantList'), // 商户
            'getTradeType': A('getTradeType'), // 交易类型 
            'getFreezeDetail': A('getFreezeDetail'), // 冻结明细列表数据
            'getBillingDetail': A('getBillingDetail'), // 结算明细列表数据
            'getStateType': A('getStateType'), // 状态
            'getUserAccountType': A('getUserAccountType'), // 用户类型
        },
        sagas: {
            'getPaymentTableList': S('getPaymentTableList', '/settle/inoutdetail/listInOutDetail'),
            'getMerchantList': S('getMerchantList', '/merchant/info/listMerchantIdAndName'),
            'getTradeType': S('getTradeType', '/enum/TradeTypeEnum'),
            'getFreezeDetail': S('getFreezeDetail', '/settle/balancelock/listBalanceLock'),
            'getBillingDetail': S('getBillingDetail', '/settle/reconciliationSource/queryStarValueSettle'),
            'getStateType': S('getStateType', '/enum/FeeTypeEnum'),
            'getUserAccountType': S('getUserAccountType', '/enum/UserAccountType'),
        },
        reducers: {
            'paymentTableList': R('getPaymentTableList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'merchantList': R('getMerchantList', { merchantIdAndNames: [] }),
            'tradeType': R('getTradeType', []),
            'freezeTableList': R('getFreezeDetail', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'billingTableList': R('getBillingDetail', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'stateType': R('getStateType', []),
            'acountType': R('getUserAccountType', []),
        }
    })
};
export default createModel(model);