import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import CreatePay from '../../modal/CreatePay';
import * as actions from '@/reducers/auditmanage/withdraw/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { SearchBox, SearchItem } from '@/components/Layout';
import WithdrawalAudit from '../../../commonmodal/WithdrawalAudit';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: [], // 当前需要打开的弹窗的入参
            telephone: '',
            companyStatus: {},
            withdrawalIds: [],
            batchRows: [],
            withdrawalId: '',
            oddId: null,
            endDate: null,
            startDate: null
        };
    }
    static propTypes = {
        updateContent: PropTypes.func,
        getWithdrawList: PropTypes.func,
        getWithdrawSele: PropTypes.func,
        withdrawList: PropTypes.object,
        withdrawSele: PropTypes.array
    };
    componentDidMount() {
        this.props.getWithdrawSele();
    }
    // 查询
    _searchHandle = (useCache) => {
        const { telephone, oddId, companyStatus, endDate, startDate, pageNum, pageSize } = this.state;
        this.searchCache = {
            ownerPhone: telephone, withdrawalId: oddId, status: companyStatus.code, type: 'MONEY', ownerType: 'SALES_MAN', endDate, startDate,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getWithdrawList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, type: 'MONEY', ownerType: 'SALES_MAN', pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置
    _resetHandle = () => {
        this.setState({ telephone: '', oddId: null, companyStatus: {}, endDate: null, startDate: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    //新建批量付款
    _createpay = () => {
        const { withdrawalIds } = this.state;
        if (withdrawalIds.length === 0) {
            message.error('销售员提现申请至少选择一个！');
        } else {
            this.setState({ currentOpenMode: 'BATCHCREATE' });
        }
    }
    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'detail':
                console.log(id, type, params);
                this.props.updateContent('DETAIL', id);
                break;
            case 'firAudit':
                console.log(id, type, params);
                this.props.updateContent('FIRAUDIT', id);
                break;
            case 'secAudit':
                console.log(id, type, params);
                this.props.updateContent('SECAUDIT', id);
                break;
            case 'createPay':
                console.log(id, type, params);
                this.setState({ currentOpenMode: 'SINGLECREATE', withdrawalId: id });
                break;
            case 'batch':
                console.log(id, type, params);
                this.setState({ withdrawalIds: params });
                break;
            case 'batchRows':
                console.log(id, type, params);
                this.setState({ batchRows: params });
                break;
        }
    }
    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        const { telephone, oddId, companyStatus, endDate, startDate } = this.state;
        this.props.getWithdrawList({ ownerPhone: telephone, withdrawalId: oddId, status: companyStatus.code, type: 'MONEY', ownerType: 'SALES_MAN', endDate, startDate, pageSize, pageNum });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    //打开弹窗
    _renderMode = () => {
        const { withdrawalId, withdrawalIds, batchRows } = this.state;
        switch (this.state.currentOpenMode) {
            case 'SINGLECREATE':
                return <CreatePay title='新建付款' data={{ type: 'single', withdrawalId }} visible={true} closeMode={this._closeMode} />;
            case 'BATCHCREATE':
                if (batchRows.filter(item => item.status.code !== 'COMPLETE').length !== 0) {
                    message.error('只能新建审核通过的付款！');
                    this.setState({ currentOpenMode: '' });
                } else if (batchRows.filter(item => item.paymentId !== '').length > 0) {
                    message.error('存在已经新建付款选项！');
                    this.setState({ currentOpenMode: '' });
                } else {
                    return <CreatePay title='批量新建付款' data={{ type: 'batch', withdrawalIds }} tableAction={this._tableAction} visible={true} closeMode={this._closeMode} />;
                }
                break;
            case 'WithdrawalAudit':
                return <WithdrawalAudit closeModal={this._closeMode} withdrawalIds={withdrawalIds} />;
            default:
                return null;
        }
    }
    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', withdrawalIds: [] }, () => {
            update && this._searchHandle('useCache');
        });
    }
    //批量初审
    _batchAudit = () => {
        const { withdrawalIds, batchRows } = this.state;
        if (!withdrawalIds.length) return message.warn('请勾选数据');
        if (batchRows.filter(item => item.status.code !== 'COMMIT').length !== 0) return message.error('只能审核提交状态的数据！');
        this.setState({ currentOpenMode: 'WithdrawalAudit' });
    }
    render() {
        const { withdrawList, withdrawSele } = this.props;
        const { telephone, oddId, companyStatus, endDate, startDate, withdrawalIds } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='销售员手机'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='telephone'
                                value={telephone}
                            />
                        </SearchItem>
                        {/* <SearchItem>
                            <XInputNum
                                label='申请单号'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='oddId'
                                value={oddId}
                                min={0}
                                precision={0}
                                maxLength={16}
                            />
                        </SearchItem> */}
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={withdrawSele}
                                bindThis={this}
                                bindName='companyStatus'
                                dataIndex='value'
                                keyIndex='code'
                                value={companyStatus.value}
                            />
                        </SearchItem>
                        {/* <SearchItem>
                            <XDatePicker
                                label='新建日期'
                                bindThis={this}
                                bindName='endDate'
                                value={endDate}
                                isFormat={true}
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                bindThis={this}
                                bindName='startDate'
                                value={startDate}
                                isFormat={true}
                            />
                        </SearchItem> */}
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <ButtonPerssion code='auditmanage/salesWithdrawal/xjplfk'>
                            <XOKButton style={{ width: '120px', marginRight: '20px' }} onClick={this._createpay} label='新建批量付款' />
                        </ButtonPerssion>
                        <ButtonPerssion code='auditmanage/salesWithdrawal/cs'>
                            <XOKButton style={{ width: '100px' }} onClick={this._batchAudit} label='批量初审' />
                        </ButtonPerssion>
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={withdrawList} tableAction={this._tableAction} withdrawalIds={withdrawalIds} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}
const mapStateToProps = (state) => ({
    withdrawList: state.withdrawAudit.withdrawList,
    withdrawSele: state.withdrawAudit.withdrawSele
});
export default connect(mapStateToProps, actions)(Main);