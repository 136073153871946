/**
 *  商品列表规则参数
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import DeleteModal from '../../modal/DeleteModal';
import { XInput, XOKButton, XCancelButton, XPagination, XDatePicker, showConfirm, } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import { getmallRecommendList, } from '@/reducers/systemmanage/mallsetting/actions';
import { GoodsClassify } from '@/vcomps';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Recommend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderStartTime: null, // 生效开始时间
            startPickerDisabled: false, // 生效开始时间不可选
            orderEndTime: null, // 生效结束时间
            endPickerDisabled: false, // 生效结束时间不可选
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参

            rulesNum: '',//规则名称
            categoryId: '', // 商品联级分类
        };
    }

    componentDidMount() {

    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getmallRecommendList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 查询触发
    _fullSearchHandle = (useCache) => {
        const { rulesNum, orderStartTime, orderEndTime, pageNum, pageSize, categoryId } = this.state;
        let startTime = orderStartTime != null ? orderStartTime.format('YYYY-MM-DD HH:mm:ss') : null;
        let endTime = orderEndTime != null ? orderEndTime.format('YYYY-MM-DD HH:mm:ss') : null;

        this.searchCache = {
            ruleName: rulesNum,
            startTimeStart: startTime,
            startTimeEnd: endTime,
            categoryId,
            categorySubType: 'ONLINE',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getmallRecommendList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _fullresetHandle = () => {
        this.setState({ rulesNum: '', categoryId: '', orderEndTime: null, orderStartTime: null, }, () => {
            KeepAlive.saveResetFunc(this._fullSearchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        this.cleanRef.clean();
    }

    // 表格操作功能回调
    _actionClickHandle = (item, type, value) => {
        const { updateContent, } = this.props;
        switch (type) {
            case 'editor'://编辑
                updateContent('reditor', item);
                break;
            case 'delete':
                showConfirm('您是否要删除内容', '', () => {
                    http('/goods/recorule/delete', { id: item }, 'POST').then(() => {
                        message.success('删除成功');
                        this.props.getmallRecommendList(this.searchCache);
                    }).catch(() => {
                    });
                }, () => { });
                break;
            case 'switch':
                http('/goods/recorule/updateState', {
                    id: item.id,
                    state: (value === false) ? 0 : 1,
                }, 'POST').then(() => {
                    message.success('状态修改成功');
                    this.props.getmallRecommendList(this.searchCache);
                }).catch((reject) => {
                    if (reject.code == '500')
                        message.error(reject.message);
                });
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'delete':
                return <DeleteModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

    // 创建规则
    _createRules = () => {
        this.props.updateContent('rcreateRules');
    }

    render() {
        const { tableSource, paginations } = this.props;
        const { rulesNum, orderStartTime, startPickerDisabled, orderEndTime, endPickerDisabled, } = this.state;
        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._fullSearchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.pro_commont}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='规则名称'
                                    placeholder='请输入'
                                    value={rulesNum}
                                    bindThis={this}
                                    bindName='rulesNum'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XDatePicker
                                    label='生效时间'
                                    value={orderStartTime}
                                    disabled={startPickerDisabled}
                                    bindThis={this}
                                    bindName='orderStartTime'
                                />
                                <XDatePicker
                                    style={{ marginRight: '20px' }}
                                    value={orderEndTime}
                                    disabled={endPickerDisabled}
                                    bindThis={this}
                                    bindName='orderEndTime'
                                />
                            </SearchItem>
                            <SearchItem>
                                <GoodsClassify
                                    style={{ marginRight: '20px' }}
                                    label='商品分类'
                                    categorySubType= 'ONLINE'
                                    ref={ele => this.cleanRef = ele}
                                    onClassifyChange={value => this.setState({ categoryId: value })}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._fullSearchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._fullresetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }} label='创建列表规则' onClick={this._createRules} />
                    </div>
                    <div className={`${styles.tableContainer}`}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record, index) => record.id}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(tableSource)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={paginations.resultTotal}
                            pageSize={paginations.pageSize}
                            pageNum={paginations.pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    <div>{this._renderMode()}</div>
                </div>
            </KeepAlive>
        );
    }
}

Recommend.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    // getCommunitySource: PropTypes.func, // 通过Saga异步请求表格数据源的Action
    // getCommunityselectDataSource: PropTypes.func,// 通过Saga异步请求下拉选项数据方法Action
    getmallRecommendList: PropTypes.func,// 通过Saga异步请求列表查询方法Action
    tableSource: PropTypes.array,
    paginations: PropTypes.object,
};
// paramelist: state.mallSetting.mallsettingDate.paramelist, //商城参数
const mapStateToProps = (state) => {
    return {
        tableSource: state.mallSetting.mallsettingDate.tableSource, // 表格数据源
        paginations: state.mallSetting.mallsettingDate.paginations, // 分页数据
    };
};


export default connect(mapStateToProps, { getmallRecommendList, })(Recommend);