/**
 * 创建
 */
import React, { useEffect, useState } from 'react';
import { Modal, message, Input, InputNumber, DatePicker } from 'antd';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import styles from './Create.module.scss';
import { regFenToYuan, regYuanToFen } from '@/util/money';
import { showConfirm } from '@/components/xqxc_ui';

import moment from 'moment';

const Create = ({ closeMode, curTabID }) => {

    const [loading, setLoading] = useState(false);
    const [stockPremium, setStockPremium] = useState(0);
    const [startTime, setStartTime] = useState(null);
    const [execution, setExecution] = useState('');
    const [oldValue, setOldValue] = useState(0);

    useEffect(() => {
        let url = curTabID == 1 ? '/admin/stockPremium/findLastStockPremium' : '/admin/exchangeRate/findLastCnyByHkd';
        http(url, {}, 'POST')
            .then(({ result = {} }) => {
                if (result.stockPremium) {
                    setOldValue(result.stockPremium);
                    setExecution(regFenToYuan(result.stockPremium));
                } else {
                    setOldValue(result.cny);
                    setExecution(regFenToYuan(result.cny));
                }
            })
            .catch(err => {
                message.error(err.message);
            });
    }, []);

    const request = (url, params) => {
        setLoading(true);
        http(url, { ...params, startTime: startTime.format('YYYY-MM-DD 00:00:00') }, 'POST')
            .then((res = {}) => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    const onSubmit = () => {
        if (!stockPremium) {
            message.warn(curTabID == 1 ? '请设置股权溢价' : '请设置RMB兑换港元比例');
            return;
        }
        if (!startTime) {
            message.warn('请选择执行日期');
            return;
        }
        let url = curTabID == 1 ? '/admin/stockPremium/add' : '/admin/exchangeRate/add';
        let params = curTabID == 1 ? { stockPremium: regYuanToFen(stockPremium) } : { cny: regYuanToFen(stockPremium) };
        if (oldValue * 1.3 < regYuanToFen(stockPremium)) {
            showConfirm(curTabID == 1 ?
                '您当前设置的股票溢价已超出正在执行中的股票溢价的30%，是否要继续设置' :
                '您当前设置的RMB兑换港元比例已超出正在执行中的 比例的30%，是否要继续设置', '',
                () => {
                    request(url, params);
                });
        } else {
            request(url, params);
        }
    };

    return <Modal
        width={480}
        bodyStyle={{ paddingTop: '10px' }}
        centered
        title={curTabID == 1 ? '设置股权溢价收益' : '设置RMB兑换港元比例'}
        visible={true}
        onOk={onSubmit}
        onCancel={() => closeMode()}
        confirmLoading={loading}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div className={styles.container}>
            <div className={styles.item}>
                <div className={styles.label}>{curTabID == 1 ? '执行中的股权溢价：' : '执行中的比例：'}</div>
                <Input style={{ width: '200px' }} disabled={true} value={execution} />
                <div className={styles.unit}>{curTabID == 2 && ':1'}港元</div>
            </div>
            <div className={styles.item}>
                <div className={styles.label}>{curTabID == 1 ? '设置股票溢价：' : '设置RMB兑换港元比例'}</div>
                <InputNumber min={0.00} max={99999.99} precision={2} style={{ width: '200px' }} onChange={value => setStockPremium(value)} value={stockPremium} addonAfter='元' />
                <div className={styles.unit}>{curTabID == 2 && ':1'}港元</div>
            </div>
            <div className={styles.item}>
                <div className={styles.label}>执行时间： </div>
                <DatePicker
                    disabledDate={current => current && current < moment().startOf('day')}
                    style={{ width: '200px' }}
                    value={startTime}
                    onChange={startTime => setStartTime(startTime)} />
            </div>
            <div className={styles.item}>{
                curTabID == 1 ?
                    '新建的收益，如果已有规则冲突则，使用新建的溢价规则执行' :
                    '新建的RMB兑换港元比例，如果已有规则冲突，则使用新建的RMB兑换港元比例规则执行'
            }</div>
        </div>
    </Modal>;
};

Create.propTypes = {
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    curTabID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
};

export default Create;