import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { Modal, message, Radio, InputNumber } from 'antd';
import { XInputNum, XTextArea } from '@/components/xqxc_ui';
import model from '@/reducers/starvalueshop/redemption/model';

const AddGoodsModal = ({
    closeMode,
    getFreights,
    getExchangeGoodsPayTypeEnum,
}) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [toUse, setToUse] = useState('100');
    const [limitNumber, setLimitNumber] = useState(null);
    const [goodsNos, setGoodsNos] = useState('');
    const [skuNos, setSkuNos] = useState('');
    const [isGoodsNos, setIsGoodsNos] = useState(true);
    const [isSkuNos, setIsSkuNos] = useState(false);
    const [customRate, setCustomRate] = useState(null);

    useEffect(() => {
        getFreights();
        getExchangeGoodsPayTypeEnum();
    }, []);

    // 确认
    const handleOk = () => {
        if (!goodsNos && !skuNos) {
            message.warning('请输入商品货号或者SKU编码');
            return;
        }
        if (toUse === 'custom' && !customRate) {
            message.warning('请输入自定义比例');
            return;
        }
        setConfirmLoading(true);
        http('/goods/exchangeGoods/batchAddExchangeGoods', {
            goodsNos,
            skuNos,
            exchangePloy: toUse === 'custom' ? customRate : 100,
            limitNumber,
            payTypeList: ['INTEGRAL']
        }, 'POST')
            .then(res => {
                message.success(res.result.tip);
                setConfirmLoading(false);
                closeMode(true);
            }).catch((e) => {
                setConfirmLoading(false);
                message.error(e.message);
            });
    };

    // 取消
    const handleCancel = () => {
        closeMode(false);
    };

    return (
        <Modal
            width={800}
            centered
            title='添加商品'
            visible={true}
            confirmLoading={confirmLoading}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <div className={styles.container}>
                <XTextArea
                    label={<Radio
                        checked={isGoodsNos}
                        onChange={e => {
                            if (isSkuNos) {
                                setIsSkuNos(false);
                            }
                            setSkuNos('');
                            setIsGoodsNos(e.target.checked);
                        }}
                    >{isGoodsNos && <span style={{ color: '#ff0000' }}>*</span>}商品货号：</Radio>}
                    style={{ marginBottom: '10px' }}
                    inputStyle={{ width: '500px' }}
                    placeholder='请输入商品货号，多个逗号隔开,最多100个'
                    value={goodsNos}
                    rows={3}
                    disabled={isSkuNos}
                    onChange={value => {
                        if (value.replace(/，/g, ',').split(',').length < 100) {
                            setGoodsNos(value);
                        } else {
                            message.warning('货号集合最多100个');
                        }
                    }}
                />
                <div className={styles.tip}>只能输入供应链商品列表的商品货号，非供应链商品无法添加</div>
                <div className={styles.component}>
                    <span className={styles.star} style={{ display: 'block' }}>*</span>
                    <div className={styles.label}>设置提货券抵用：</div>
                    <div className={styles.inputbox}>
                        <Radio.Group
                            onChange={({ target: { value = '' } = {} }) => setToUse(value)}
                            value={toUse}
                        >
                            <Radio value='100' key='100'>抵用100%</Radio>
                            <Radio value='custom' key='custom'>
                                自定义抵用{
                                    toUse === 'custom' &&
                                    <Fragment>
                                        ：<InputNumber style={{ marginRight: '10px', width: '120px' }} precision={0} placeholder='请输入比例' min={1} max={100} onChange={value => setCustomRate(value)} />%
                                    </Fragment>
                                }
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className={styles.desc}>
                    <p>特权值与RMB的比例为2:1</p>
                    <p>最高抵用的特权值=商品售价*抵用比例*特权值与RMB的比例 </p>
                </div>
                <XInputNum
                    label='设置每人限购数：'
                    placeholder='请输入限购数'
                    style={{ marginBottom: '20px' }}
                    max={99}
                    min={0}
                    precision={0}
                    bindName='limitNumber'
                    bindThis={setLimitNumber}
                    value={limitNumber}
                />
            </div>
        </Modal >
    );
};

AddGoodsModal.propTypes = {
    closeMode: PropTypes.func,//关闭弹框参数
    getFreights: PropTypes.func,
    freights: PropTypes.array,
    getExchangeGoodsPayTypeEnum: PropTypes.func,
    exchangeGoodsPayTypeEnum: PropTypes.array,
};

export default connect(({ redemption }) => ({
    freights: redemption.freights,
    exchangeGoodsPayTypeEnum: redemption.getExchangeGoodsPayTypeEnum
}), {
    ...model.actions
}
)(AddGoodsModal);