/**
 * 付款确认 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, Input, Alert, Form, Select, message } from 'antd';
import numSwitch from '@/util/numSwitch';
import { regFenToYuan } from '@/util/money';
import styles from './AddRole.module.scss';
import md5 from 'js-md5';

const { TextArea } = Input;
const { Option } = Select;

class NoLogin extends React.Component {

    state = {
        confirmLoading: false,
        status: '',
        reason: '',
        paymentAmount: 0,//付款合计
        ids: '',//id
        serviceList: []
    }

    componentDidMount() {
        const { data } = this.props;
        if (data instanceof Array) {
            let paymentAmount = 0;
            let idList = [];
            (data || []).forEach(item => {
                paymentAmount += item.paymentAmount;
                idList.push(item.id);
            });
            this.setState({
                paymentAmount: regFenToYuan(paymentAmount),
                ids: idList.join('，'),
            });
        } else if (data instanceof Object) {
            let { id, paymentAmount } = data || {};
            this.setState({
                paymentAmount: regFenToYuan(paymentAmount),
                ids: id,
            });
        }
        http('/settle/paymentApplication/getPayChannelMap', {}, 'POST').then(({ result = {} } = {}) => {
            let serviceList = [];
            const map = new Map(new Map(Object.entries(result)));
            Object.keys(result).forEach(i => {
                serviceList.push({ id: i, name: map.get(i) });
            });
            this.setState({ serviceList });
        });
    }

    // 点击确认
    _handleOk = () => {
        const { form: { getFieldsValue } = {}, closeMode, data } = this.props;
        const { ids } = this.state;
        this.setState({ confirmLoading: true });
        let url = '/settle/paymentApplication/confirm';
        let params = { paymentApplicationId: ids };
        if (data instanceof Array) {
            url = '/settle/paymentApplication/batchConfirm';
            params = {
                paymentApplicationIds: data.map(item => {
                    return item.id;
                })
            };
        }

        let formData = getFieldsValue();
        formData.password = md5(formData.password).toUpperCase();

        http(url, { ...params, ...formData }, 'POST')
            .then(() => { message.success('确认付款成功'); closeMode(true); })
            .catch((res = {}) => { message.error('确认付款失败，原因：' + res.message); })
            .finally(() => { this.setState({ visible: false, confirmLoading: false }); closeMode(); });
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({ visible: false });
        this.props.closeMode();
    }

    render() {
        const { confirmLoading, ids, paymentAmount, serviceList } = this.state;
        const { title = '', form: { getFieldDecorator } = {}, visible = false } = this.props;

        return (
            <Modal
                width={600}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Alert message="确认后,付款完成" type="info" showIcon style={{ textAlign: 'left' }} />
                    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item colon label='付款单号' style={{ textAlign: 'left' }} >{ids}</Form.Item>
                        <Form.Item colon label='合计转账金额' style={{ textAlign: 'left' }}>
                            {paymentAmount} ({paymentAmount ? numSwitch(paymentAmount) : ''})
                        </Form.Item>
                        <Form.Item colon label='服务商' style={{ textAlign: 'left' }} >
                            {getFieldDecorator('channelId', { rules: [{ message: '请输入' }] })(
                                <Select placeholder='请选择'>
                                    {serviceList.map(i => {
                                        return <Option key={i.id} value={i.id}>{i.name}</Option>;
                                    })}
                                </Select>)}
                        </Form.Item>
                        <Form.Item colon label='财务密码' style={{ textAlign: 'left' }}>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '请输入' }],
                            })(<Input.Password placeholder="请输入" />)}
                        </Form.Item>
                        <Form.Item colon label='备注'>
                            {getFieldDecorator('remark', { rules: [{ message: '请输入策略名称' }] })(<TextArea rows={4} />)}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

NoLogin.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]), // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(NoLogin);// 校验
export default connect(null, null)(ForgotProducDetails);