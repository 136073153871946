import { createModel } from '@/store/tools';

const model = {
    namespace: 'productOrder',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getSettleTypeEnum': T('getSettleTypeEnum'),
        },
        actions: {
            'getSettleTypeEnum': A('getSettleTypeEnum'),
        },
        sagas: {
            'getSettleTypeEnum': S('getSettleTypeEnum', '/enum/OrderRateEnum'),
        },
        reducers: {
            'settleTypeEnum': R('getSettleTypeEnum', []),
        }
    })
};
export default createModel(model);