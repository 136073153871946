import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const getCustomListTableSource = function* () {
    yield takeEvery(T.GET_CUSTOMLIST_TABLESOURCE, function* ({ payload }) {
        try {
            let { result } = yield http('/custom/appHomePage/getList', payload.requestProps);
            yield put({
                type: T.SET_CUSTOMLIST_TABLESOURCE,
                payload: result || { dataList: [] },
                requestProps: payload.requestProps
            });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// export const getCustomListHomeTableSource = function* () {
//     yield takeEvery(T.GET_CUSTOMLIST_HOME_TABLESOURCE, function* ({ payload }) {
//         try {
//             let result = yield http('/show/showClass/listMusicClass', payload.requestProps, 'POST'); // action.payload为传入的参数对象
//             yield put({
//                 type: T.SET_CUSTOMLIST_HOME_TABLESOURCE,
//                 payload: result.result || {},
//                 requestProps: payload.requestProps
//             });
//         } catch (e) {
//             yield put({ type: 'SAGA_ERROR', payload: { error: e } });
//         }
//     });
// };

// export const getCustomListMallTableSource = function* () {
//     yield takeEvery(T.GET_CUSTOMLIST_MALL_TABLESOURCE, function* ({ payload }) {
//         try {
//             let result = yield http('/show/showClass/listMusicClass', payload.requestProps, 'POST'); // action.payload为传入的参数对象
//             yield put({
//                 type: T.SET_CUSTOMLIST_MALL_TABLESOURCE,
//                 payload: result.result || {},
//                 requestProps: payload.requestProps
//             });
//         } catch (e) {
//             yield put({ type: 'SAGA_ERROR', payload: { error: e } });
//         }
//     });
// };

// export const getCustomListSubjectTableSource = function* () {
//     yield takeEvery(T.GET_CUSTOMLIST_SUBJECT_TABLESOURCE, function* ({ payload }) {
//         try {
//             let result = yield http('/show/showClass/listMusicClass', payload.requestProps, 'POST'); // action.payload为传入的参数对象
//             yield put({
//                 type: T.SET_CUSTOMLIST_SUBJECT_TABLESOURCE,
//                 payload: result.result || {},
//                 requestProps: payload.requestProps
//             });
//         } catch (e) {
//             yield put({ type: 'SAGA_ERROR', payload: { error: e } });
//         }
//     });
// };

export const getCustomListAdvertTableSource = function* () {
    yield takeEvery(T.GET_CUSTOMLIST_ADVERT_TABLESOURCE, function* ({ payload }) {
        try {
            let { result } = yield http('/custom/appAdPage/getList', payload.requestProps, 'POST'); // action.payload为传入的参数对象
            yield put({
                type: T.SET_CUSTOMLIST_ADVERT_TABLESOURCE,
                payload: result || { dataList: [] },
                requestProps: payload.requestProps
            });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};