import React from 'react';
import styles from './BackGround.module.scss';

const BackGround = () => {
    return(
        <div className={styles.bg}>
            <div className={styles.top_park}>
                <img className={styles.beijing_img} src= {require('@/assets/image/beijing.png')} />
                <img className={styles.beijing_txt} src= {require('@/assets/image/bgtxt.png')} />
            </div>
            {/* <div className={styles.bottom_park}>
                <div className={styles.companyname}>星球秀场（厦门）科技有限公司版权所有</div>
            </div> */}
        </div>
    );
};

export default BackGround;