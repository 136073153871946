/**
 * 调整库存
 */
import React, { useState, } from 'react';
import { Modal, message, Input, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import styles from './InventoryModal.module.scss';
import http from '@/assets/api/http';
import { tuple } from 'antd/lib/_util/type';
const InventoryModal = (props) => {
    const [title] = useState(props.title);
    const [visible, setVisible] = useState(props.visible);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [count, setCount] = useState(props.data.stock);//活动库存数据
    const [platStock, setPlatStock] = useState(props.data.platStock);//平台库存数据
    const [sellerStock, setSellerStock] = useState(props.data.sellerStock);//服务商库存数据 
    const [activityGoodsId] = useState(props.data.activityGoodsId);//活动商品id 

    const _handleOk = () => {
        let inventoryObj = false;//提交开关
        if (platStock === null) return message.warn('请输入平台库存（整数）。');
        if (sellerStock === null) return message.warn('请输入服务商库存（整数）。');
        if (platStock + sellerStock == count) {
            inventoryObj = true;
        } else {
            message.warn('平台与服务商库存之和等于活动库存。');
        }
        if (inventoryObj) {
            setConfirmLoading(true);
            http('/goodsAudit/adjustStock', { activityGoodsId, platStock, sellerStock, }, 'POST').then(() => {
                message.success('库存更新成功');
                setConfirmLoading(false);
                setVisible(false);
                props.closeMode(true);
            }).catch((res) => {
                setConfirmLoading(false);
                message.warn(res.message);
            });
        }
    };

    const _handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false);
        props.closeMode();
    };

    //活动库存一定是服务商库存+平台库存，如果不等于就不通过
    const handleParams = (value, type) => {
        if (type == 'platStock') {
            // setPlatStock(value);
            if (value >= count) {
                setPlatStock(count);
                setSellerStock(0);
            } else {
                setPlatStock(value);
                setSellerStock(count - value);
            }
        } else if (type == 'sellerStock') {
            // setSellerStock(value);
            if (value >= count) {
                setSellerStock(count);
                setPlatStock(0);
            } else {
                setSellerStock(value);
                setPlatStock(count - value);
            }
        }
    };

    return (
        <Modal
            {...props}
            width={470}
            centered
            title={title}
            visible={visible}
            confirmLoading={confirmLoading}
            onOk={_handleOk}
            onCancel={_handleCancel}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <div className={styles.container}>
                <div className={styles.layout}>
                    <label className={styles.label}>活动库存</label>
                    <InputNumber
                        disabled={true}
                        style={{ width: '350px' }}
                        min={0}
                        value={count}
                        precision={0}
                        onChange={(e) => {
                            setCount(e);
                        }}
                    />
                </div>
                <div className={styles.layout}>
                    <label className={styles.label}>平台库存</label>
                    <InputNumber
                        placeholder='请输入'
                        style={{ width: '350px' }}
                        min={0}
                        value={platStock}
                        precision={0}
                        onChange={(e) => {
                            handleParams(e, 'platStock');
                        }}
                    />
                </div>
                <div className={styles.layout}>
                    <label className={styles.label}>服务商库存</label>
                    <InputNumber
                        placeholder='请输入'
                        style={{ width: '350px' }}
                        min={0}
                        value={sellerStock}
                        precision={0}
                        onChange={(e) => {
                            handleParams(e, 'sellerStock');
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};
InventoryModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default InventoryModal;