import * as T from './actiontypes';

/**
 * 获取一级、二级菜单列表
 */
export const getMenuList = (value = {}) => ({
    type: T.GET_MENU_LIST,
    payload: value
});

/**
 * 获取全部菜单列表
 */
export const getAllMenuList = (value = {}) => ({
    type: T.GET_MENU_ALL_LIST,
    payload: value
});

/**
 * 获取菜单详情
 */
export const getMenuDetail = (value = {}) => ({
    type: T.GET_MENU_DETAIL,
    payload: value
});

/**
 * 获取一级菜单下拉列表
 */
export const getMenuSelect = (value = {}) => ({
    type: T.GET_MENU_SELECT,
    payload: value
});

/**
 * 获取二级菜单下拉列表
 */
export const getSecondMenuSelect = (value = {}) => ({
    type: T.GET_SECOND_MENU_SELECT,
    payload: value
});

/**
 * 清理二级菜单下拉列表
 */
export const cleanSecondMenuSelect = () => ({
    type: T.CLEAN_SECOND_MENU_SELECT
});

/**
 * 获取Tab菜单下拉列表
 */
export const getTabSelect = (value = {}) => ({
    type: T.GET_TAB_SELECT,
    payload: value
});

/**
 * 清理Tab菜单下拉列表
 */
export const cleanTabSelect = () => ({
    type: T.CLEAN_TAB_SELECT
});

/**
 * 获取总控菜单终端类型
 */
export const getAffType = (value = {}) => ({
    type: T.GET_AFF_TYPE,
    payload: value
});