import * as T from './actiontypes';

const defaultState = {
    tableSource: [],//列表数据源
    selectData: [],// 封禁状态
    paginations: {},//分页对象
    infomation: {},//封禁信息
    storeDetails: {},//店铺详情
    shopBannedType: [],// 封禁----封禁理由 单选框
    accountBlack: {},//店铺封禁日志列表
    storeType: [],//详情----店铺类型
    recommendation: {},//店铺推荐列表

    storeData: [],//新建店铺类型下拉
    mereditor: {},//店铺编辑

};


/**
 * 存储'店铺列表'数据源
 * @param {*} state 
 * @param {*} action 
 */
export const storeDate = (state = defaultState, action) => {
    let newTableSource, storeData, newSelectData, newPaginations, infomation,
        storeDetails, shopBannedType, accountBlack, storeType, recommendation;
    switch (action.type) {
        case T.SET_STORELIST_DATA: //存储'店铺列表'数据源
            newTableSource = action.payload.result.dataList || [];
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_STOSELECT_DATA: //存储'封禁状态'数据源
            newSelectData = action.payload.result || [];
            return { ...state, selectData: newSelectData };
        case T.SET_RADUIS_DATA: //封禁----封禁理由 单选框
            shopBannedType = action.payload.result || [];
            return { ...state, shopBannedType };
        case T.SET_DETAILTYPE_DATA: //详情----店铺类型
            storeType = action.payload.result || [];
            return { ...state, storeType };
        case T.SET_INFOMATION_DATA: //封禁信息
            infomation = action.payload.result || {};
            return { ...state, infomation };
        case T.SET_STOREDETAILS_DATA: //店铺详情
            storeDetails = action.payload.result || {};
            return { ...state, storeDetails };
        case T.SET_STORETAKELOG_DATA: //店铺封禁日志列表
            accountBlack = action.payload || {};
            return { ...state, accountBlack, };
        case T.SET_RECOMMENDATION_DATA: //店铺推荐列表
            recommendation = action.payload.result || {};
            return { ...state, recommendation, };
        default:
            return state;
    }
};

