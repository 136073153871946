import React from 'react';
import styles from './AdvertTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';

class AdvertTable extends React.Component {

    _takeColumns = () => ([
        {
            title: '客服类型',
            dataIndex: 'customerType',
            key: 'customerType',
            width: '10%',
            align: 'center',
            render: (text) => {
                return <span>{text.value}</span>;
            }
        },
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '客服账号',
            dataIndex: 'outerLoginName',
            key: 'outerLoginName',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '初始密码',
            dataIndex: 'outerLoginPwd',
            key: 'outerLoginPwd',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '操作',
            dataIndex: 'c3',
            key: 'c3',
            width: '10%',
            align: 'center',
            render: (text,item) => (this._renderActionCell(item.id))
        },
    ]);

    // 操作表格
    _renderActionCell = (key) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>
                <div className={styles.item} onClick={() => tableAction(key, 'del')}>删除</div>           
            </div>
        );
    }
    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    render() {
        const { paginationChange} = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    curTabID: PropTypes.number, // 当前TabID
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    paginations: PropTypes.object,//分页数据
};

export default AdvertTable;