import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '日期',
            dataIndex: 'statisticsTime',
            key: 'statisticsTime',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '打赏魅力值',
            dataIndex: 'presentStarlight',
            key: 'presentStarlight',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '打赏魅力值价值(元)',
            dataIndex: 'presentStarlightValue',
            key: 'presentStarlightValue',
            width: '10%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '魅力值服务费比例',
            dataIndex: 'starlightServiceRatioPercent',
            key: 'starlightServiceRatioPercent',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '魅力值提现金额(元)',
            dataIndex: 'starlightWithdrawedAmount',
            key: 'starlightWithdrawedAmount',
            width: '10%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '平台预期收益(元)',
            dataIndex: 'platExpectedProfit',
            key: 'platExpectedProfit',
            width: '10%',
            align: 'center',
            render: priceFenToYuanCell
        },
    ]);

    _takeDataSource = (dataList = []) => {
        return dataList;
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;