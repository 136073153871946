export const GET_STARLIST_DATA = 'STARMALL/GET_STARLIST_DATA'; // 人气值商城分类 列表
export const SET_STARLIST_DATA = 'STARMALL/SET_STARLIST_DATA'; // 人气值商城分类 列表

export const GET_LISTSTATE_DATA = 'STARMALL/GET_LISTSTATE_DATA'; // 人气值商城分类 列表状态
export const SET_LISTSTATE_DATA = 'STARMALL/SET_LISTSTATE_DATA'; // 人气值商城分类 列表状态


export const GET_STARVALUE_DATA = 'STARMALL/GET_STARVALUE_DATA'; // 人气值商品 列表
export const SET_STARVALUE_DATA = 'STARMALL/SET_STARVALUE_DATA'; // 人气值商品 列表

export const GET_VALUESTATE_DATA = 'STARMALL/GET_VALUESTATE_DATA'; // 人气值商品分类状态
export const SET_VALUESTATE_DATA = 'STARMALL/SET_VALUESTATE_DATA'; // 人气值商品分类状态

export const GET_VALUEDETAIL_DATA = 'STARMALL/GET_VALUEDETAIL_DATA'; // 人气值商品详情
export const SET_VALUEDETAIL_DATA = 'STARMALL/SET_VALUEDETAIL_DATA'; // 人气值商品详情

export const GET_VALUEDHARING_DATA = 'STARMALL/GET_VALUEDHARING_DATA'; // 人气值商品新建优惠劵和会员等级共用
export const SET_VALUEDHARING_DATA = 'STARMALL/SET_VALUEDHARING_DATA'; // 人气值商品新建优惠劵和会员等级共用


export const GET_RECORD_DATA = 'STARMALL/GET_RECORD_DATA'; // 兑换记录数据源
export const SET_RECORD_DATA = 'STARMALL/SET_RECORD_DATA'; // 兑换记录数据源

export const GET_RECORDSTATE_DATA = 'STARMALL/GET_RECORDSTATE_DATA'; // 兑换记录分类状态
export const SET_RECORDSTATE_DATA = 'STARMALL/SET_RECORDSTATE_DATA'; // 兑换记录分类状态

