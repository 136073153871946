import React from 'react';
import PropTypes from 'prop-types';
import styles from './Table.module.scss';
import { Table } from 'antd';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

export default function PayAmountTable({ renderData }) {

    //表头
    const _columns = () => {
        return [
            {
                title: '商品名称',
                dataIndex: 'goodsName',
                key: 'goodsName',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '商品分类',
                dataIndex: 'goodsFullCategoryName',
                key: 'goodsFullCategoryName',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '商品品牌',
                dataIndex: 'brandName',
                key: 'brandName',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '浏览量',
                dataIndex: 'browseNum',
                key: 'browseNum',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '收藏量',
                dataIndex: 'collectNum',
                key: 'collectNum',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '销售量',
                dataIndex: 'saleNum',
                key: 'saleNum',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '销售金额（元）',
                dataIndex: 'saleMoneyTotal',
                key: 'saleMoneyTotal',
                width: 140,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '营销活动金额（元）',
                dataIndex: 'saleMoneyMarketing',
                key: 'saleMoneyMarketing',
                width: 140,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '营销活动占比（%）',
                dataIndex: 'marketingRatio',
                key: 'marketingRatio',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '秀场销售金额（元）',
                dataIndex: 'saleMoneyShow',
                key: 'saleMoneyShow',
                width: 140,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '秀场占比（%）',
                dataIndex: 'showRatio',
                key: 'showRatio',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '评论量',
                dataIndex: 'commentNum',
                key: 'commentNum',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '售后量',
                dataIndex: 'saleAfterNum',
                key: 'saleAfterNum',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '成功退款金额（元）',
                dataIndex: 'refundMoney',
                key: 'refundMoney',
                width: 140,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '售后占比（%）',
                dataIndex: 'saleAfterRatio',
                key: 'saleAfterRatio',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            }
        ];
    };

    //渲染
    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                columns={_columns()}
                dataSource={renderData}
                pagination={false}
                scroll={{ x: 2100, y: true }}
            />
        </div>
    );
}

//props校验
PayAmountTable.propTypes = {
    renderData: PropTypes.array.isRequired,//表格数据元
};