import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table, Input, Icon, InputNumber } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class TableData extends Component {

    _takeColumns = () => ([
        {
            title: '房型名称',
            dataIndex: 'categoryName',
            key: 'categoryName',
            width: '20%',
            align: 'center',
            render: (text, item) => (this._renderInputCell(text, item))
        },
        {
            title: '技术服务费率(%)',
            dataIndex: 'platRate',
            key: 'platRate',
            width: '16%',
            align: 'center',
            render: (text, item) => (this._renderRateCell(text, item, 'platRate'))
        },
        {
            title: '平台服务费率(%)',
            dataIndex: 'agentRate',
            key: 'agentRate',
            width: '16%',
            align: 'center',
            render: (text, item) => (this._renderRateCell(text, item, 'agentRate'))
        },
        {
            title: '排序',
            dataIndex: 'sortNo',
            key: 'sortNo',
            width: '16%',
            align: 'center',
            render: (text, item) => this._renderSortCell(text, item)
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '16%',
            render: (text, item) => this._renderActionCell(item.id, item)
        }
    ]);

    // 渲染表格单元格（房型名称）
    _renderInputCell = (text, item) => {
        return (
            <div className={styles.textCell} key={item.id}>
                <div>
                    <Input
                        className={styles.input}
                        defaultValue={text}
                        maxLength={64}
                        onChange={(e) => {
                            item.dotype = 'save';
                            item.categoryName = e.target.value;
                            this.forceUpdate();
                        }}
                    />
                </div>
            </div>
        );
    };

    // 渲染表格单元格（技术服务费和平台服务费）
    _renderRateCell = (text, item, type) => {
        return (
            <div className={styles.rateCell} key={item.id}>
                <InputNumber
                    min={0}
                    max={100}
                    className={styles.rateInput}
                    defaultValue={text}
                    onChange={(value) => {
                        item.dotype = 'save';
                        if (type === 'platRate') {
                            item.platRate = value;
                        } else {
                            item.agentRate = value;
                        }
                        this.forceUpdate();
                    }}
                />&nbsp;&nbsp;%
            </div>
        );
    }

    // 渲染表格单元（排序）
    _renderSortCell = (text, item) => {
        const { tableAction } = this.props;
        const label = React.createRef();
        const box = React.createRef();
        const input = React.createRef();
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.sortBox}>
                    <div className={styles.label} ref={label}>{text}</div>
                    <div className={styles.input} ref={box}>
                        <Input
                            key={item.id}
                            style={{ width: '50px' }}
                            ref={input}
                            defaultValue={text}
                            onBlur={(e) => {
                                box.current.style.display = 'none';
                                label.current.style.display = 'block';
                                if (text != e.target.value) {
                                    tableAction(item.id, 'sort', { ...item, sortNo: e.target.value });
                                }
                            }}
                        />
                    </div>
                    {
                        item.type === 'edit' && <div
                            className={styles.icon}
                            onClick={() => {
                                label.current.style.display = 'none';
                                box.current.style.display = 'block';
                                input.current.focus();
                            }}
                        >
                            <Icon type="edit" style={{ fontSize: '18px', color: '#1078ff' }} />
                        </div>
                    }
                </div>
            </div>
        );
    };

    //渲染表格操作列
    _renderActionCell = (id, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                {
                    item.type === 'create' ?
                        <div className={styles.item} onClick={() => tableAction(id, 'create', item)}>保存</div> :
                        <div className={styles.item} onClick={() => tableAction(id, 'update', item)}>更新</div>
                }
                <div className={styles.item} onClick={() => tableAction(id, 'delete', item)}>删除</div>
            </div>
        );
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableData.propTypes = {
    renderData: PropTypes.array.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableData;