import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

// 直播时长充值规则
export const getLiveDurationRechargeRulesTableSource = function* () {
    yield takeEvery(T.GET_LIVEDURATIONRECHARGERULES_TABLESOURCE, function* requestData(action) {
        try {
            let result = yield http('/show/timesConfig/list', action.payload);
            yield put({ type: T.SET_LIVEDURATIONRECHARGERULES_TABLESOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getToupRulesTableSource = function* () {
    yield takeEvery(T.GET_TOUPRULES_TABLESOURCE, function* requestData(action) {
        try {
            let result = yield http('/show/starLightConfig/list', action.payload);
            yield put({ type: T.SET_TOUPRULES_TABLESOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getShowSettingList = function* () {
    yield takeEvery(T.GET_SHOWSETTING_LIST, function* requestData(action) {
        try {
            let result = yield http('/show/recoconfig/list', action.payload);
            yield put({ type: T.SET_SHOWSETTING_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


//兑换设置
/**
 * 兑换设置数据源 Saga  
 */
export const changData = function* () {
    yield takeEvery(T.GET_CHANGDATA_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('paymentParam/list', action.payload, 'POST');
            yield put({ type: T.SET_CHANGDATA_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 赠送好友人气值限额 Saga  
 */
export const changOtherData = function* () {
    yield takeEvery(T.GET_CHANGOTHERDATA_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http(`/admin/param/listAdminParam/${action.payload.type}`, action.payload, 'POST');
            yield put({ type: T.SET_CHANGOTHERDATA_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//快速回复
/**
 * 列表 Saga  
 */
export const replyList = function* () {
    yield takeEvery(T.GET_REPLYLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/sysConfig/snippetsInfo/pageSnippetsInfo', action.payload, 'POST');
            yield put({ type: T.SET_REPLYLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//人气规则
/**
 * 列表 Saga  
 */
export const popularList = function* () {
    yield takeEvery(T.GET_POPULARLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/system/popularity/list', action.payload, 'POST');
            yield put({ type: T.SET_POPULARLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 详情 Saga  
 */
export const popularDetail = function* () {
    yield takeEvery(T.GET_POPULARDETAIL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/system/popularity/getById', action.payload, 'POST');
            yield put({ type: T.SET_POPULARDETAIL_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//推荐规则
/**
 * 列表 Saga  
 */
export const recommendList = function* () {
    yield takeEvery(T.GET_RECOMMENDLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/system/promotion/list', action.payload, 'POST');
            yield put({ type: T.SET_RECOMMENDLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 推荐规则频道分类 Saga  
 */
export const recommendSelect = function* () {
    yield takeEvery(T.GET_RECOMMENDSELECT_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/enum/RewardSourceTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_RECOMMENDSELECT_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 推荐规则下拉内容 Saga  
 */
export const recommendContent = function* () {
    yield takeEvery(T.GET_RECOMMENDCENTENT_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let video = yield http('/enum/VideoPromotinoTypeEnum', action.payload, 'POST');
            let community = yield http('/enum/GraphicsPromotinoTypeEnum', action.payload, 'POST');
            let live = yield http('/enum/PromotinoTypeEnum', action.payload, 'POST');

            video.community = community.result;
            video.live = live.result;

            yield put({ type: T.SET_RECOMMENDCENTENT_DATA, payload: video });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 推荐规则详情 Saga  
 */
export const recommendDetail = function* () {
    yield takeEvery(T.GET_RECOMMENDDETAIL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/system/promotion/getById', action.payload, 'POST');
            yield put({ type: T.SET_RECOMMENDDETAIL_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

