/**
 * 商品订单
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Order from './actionTypes';

//商品订单列表
export const getProductOrderList = function* () {
    yield takeEvery(Order.GET_PRODUCTORDER_LIST, function* requestData(action) {
        try {
            let result = yield http('/boss/order/getOrderList', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PRODUCTORDER_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//商品订单类型
export const getProductOrderType = function* () {
    yield takeEvery(Order.GET_PRODUCTORDER_TYPE, function* requestData(action) {
        try {
            let result = yield http('/enum/OrderTypeEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PRODUCTORDER_TYPE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//商品订单状态
export const getProductOrderStatus = function* () {
    yield takeEvery(Order.GET_PRODUCTORDER_STATUS, function* requestData(action) {
        try {
            let result = yield http('/enum/OrderStatusEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PRODUCTORDER_STATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//认购订单状态
export const getProductSubOrderStatus = function* () {
    yield takeEvery(Order.GET_PRODUCTSUBORDER_STATUS, function* requestData(action) {
        try {
            let result = yield http('/enum/OrderCrowdfundingStatusEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PRODUCTSUBORDER_STATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//商品是否开票
export const getProductOrderFlagEnum = function* () {
    yield takeEvery(Order.GET_PRODUCTORDER_FLAGENUM, function* requestData(action) {
        try {
            let result = yield http('/enum/FlagEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PRODUCTORDER_FLAGENUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//兑换方式
export const getProductConvertWays = function* () {
    yield takeEvery(Order.GET_PRODUCTORDER_CONVERWAYA, function* requestData(action) {
        try {
            let result = yield http('/enum/StarGoodsConvertModeEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PRODUCTORDER_CONVERWAYA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//商品是否批发
export const getDistributionFlagEnum = function* () {
    yield takeEvery(Order.GET_PRODUCTPIFA_FLAGENUM, function* requestData(action) {
        try {
            let result = yield http('/enum/DistributionFlagEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PRODUCTPIFA_FLAGENUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 支付通道枚举
export const getPaymentAccessTypeEnum = function* () {
    yield takeEvery(Order.GET_PAYMENTACCESSTYPEENUM, function* requestData(action) {
        try {
            let result = yield http('/enum/PaymentAccessType', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PAYMENTACCESSTYPEENUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 支付方式枚举
export const getPaymentTypeEnum = function* () {
    yield takeEvery(Order.GET_PAYMENTTYPEENUM, function* requestData(action) {
        try {
            let result = yield http('/enum/PaymentChannelType', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PAYMENTTYPEENUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 订单来源枚举
export const getOrderSourceEnum = function* () {
    yield takeEvery(Order.GET_ORDER_SOURCE_ENUM, function* requestData(action) {
        try {
            let result = yield http('/enum/OrderSourceChannelEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_ORDER_SOURCE_ENUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

