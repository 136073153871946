import * as Advert from './actiontypes';

/**
 * 存储'广告评论'数据源
 * @param {*} state 
 * @param {*} action 
 */

 const init = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
 };

export const advertCommentList = (state = init, action) => {
    switch(action.type){
        case Advert.SET_COMMENT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};


