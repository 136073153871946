import * as T from './actiontypes';

// 接单记录
export const getReceiveRecordList = (payload) => ({
    type: T.GET_RECEIVE_RECORD_LIST,
    payload
});

// 接单记录详情
export const getReceiveRecordDetail = (payload) => ({
    type: T.GET_RECEIVE_RECORD_DETAIL,
    payload
});

// 待接单记录
export const getWaitReceiveList = (payload) => ({
    type: T.GET_WAIT_RECEIVE_LIST,
    payload
});

// 待接单记录详情
export const getWaitReceiveDetail = (payload) => ({
    type: T.GET_WAIT_RECEIVE_DETAIL,
    payload
});

// 待接单记录状态
export const getReceiveRecordState = (payload) => ({
    type: T.GET_RECEIVE_RECORD_STATE,
    payload
});
