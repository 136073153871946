/**
 *  【广告投放】入口页
 * */ 
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const Advertmanage = ({history}) => {
    return <Main history={history} />;
};

Advertmanage.propTypes = { 
    history: PropTypes.object
};

export default Advertmanage;