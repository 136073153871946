import { createModel } from '@/store/tools';


const model = {
    namespace: 'merchantDrawApply',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getStateList': T('getStateList'),
            'getMerchantList': T('getMerchantList'),
            'getApplyList': T('getApplyList'),
            'getApplyDetail': T('getApplyDetail'),
        },
        actions: {
            'getStateList': A('getStateList'),
            'getMerchantList': A('getMerchantList'),
            'getApplyList': A('getApplyList'),
            'getApplyDetail': A('getApplyDetail'),
        },
        sagas: {
            'getStateList': S('getStateList', '/enum/WithdrawXiudouStateEnum'),
            'getMerchantList': S('getMerchantList', '/merchant/info/listMerchantIdAndName'),
            'getApplyList': S('getApplyList', '/xiudou/listXiudouWithdraw'),
            'getApplyDetail': S('getApplyDetail', '/xiudou/getWithdrawXiudouDetail/', 'POST', ['id']),
        },
        reducers: {
            'stateList': R('getStateList', []),
            'merchantList': R('getMerchantList', { merchantIdAndNames: [] }),
            'applyList': R('getApplyList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'applyDetail': R('getApplyDetail', {}),
        }
    })
};
export default createModel(model);