import React, { Component } from 'react';
import { Table, Switch } from 'antd';
import PropTypes from 'prop-types';
import styles from './CategoryTable.module.scss';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';

export default class CategoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    _columns = () => [
        {
            title: '分类名称',
            dataIndex: 'className',
            key: 'className',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: cellWidth.normal,
            align: 'center',
            render: (text, item) => (this.SwitchCell(text, item))
        },
        {
            title: '修改人',
            dataIndex: 'modifyUserName',
            key: 'modifyUserName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '修改时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: cellWidth.time,
            render: (id, item) => this._tableAction(id, item)
        },
    ];

    // 表格项内容为开关
    SwitchCell = (sw, item) => {
        let checked = true;
        if (sw == 0) {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checked={checked}
                    onChange={(value) => {
                        this.props.tableAction(item, 'switch', value);
                    }}
                />
            </div>
        );
    };

    //表格操作
    _tableAction = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'edite', item)}>编辑</div>
            </div>
        );
    };

    //渲染组件
    render() {
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    columns={this._columns()}
                    dataSource={this.props.renderData}
                    pagination={false}
                    scroll={{ y: true }}
                />
            </div>
        );
    }
}

CategoryTable.propTypes = {
    renderData: PropTypes.object, // 表格数据源
    tableAction: PropTypes.func, // 表格
    paginationChange: PropTypes.func, // 分页
};