/**
 * [第三方品牌]
 * @author: Fu Xiaochun
 * @date: 2021-05-31 
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { message, Modal } from 'antd';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import TableData from './components/TableData';
import { getThirdPartyProductBrand } from '@/reducers/productmanage/brand/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class ThirdPartyBrand extends Component {
    constructor(props){
        super(props);
        this.state = {
            brandName: '',
            syncStatus: {},
            batchIds: [],
        };
        this.syncStatusOptions = [
            {text: '未同步', value: '0'},
            {text: '已同步', value: '1'}
        ];
    }

    // 查询
    _searchHandle = (useCache) => {
        const { brandName, syncStatus, pageNum, pageSize } = this.state;
        this.searchCache = {
            brandName,
            isSync: syncStatus.value,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getThirdPartyProductBrand(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置
    _resetHandle = () => {
        this.setState({ brandName: '', syncStatus: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'select':
                this.setState({
                    batchIds: [...params]
                });
                break;
            case 'delete':
                showConfirm('您确定要删除该品牌？', '', () => {
                    http('/goods/baseProduct/delBaseGoodsBrand/'+id, null, 'POST')
                        .then(() => {
                            message.success('删除成功。');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
        }
    }
    
    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 批量同步
    _batchSyncBrand = ()=>{
        let ids = this.state.batchIds;
        if(ids.length == 0){
            Modal.error({
                title: '你还未选择要同步的品牌',
                content: '请先选择要同步的品牌，再执行批量同步操作'
            });
            return false;
        }
        showConfirm('这是进行一项操作时必须了解的重要信息', '确定要同步商品品牌，你还要继续吗？', () => {
            http('/goods/baseProduct/batchAddBaseGoodsBrandToPlat', { ids }, 'POST')
                .then(() => {
                    message.success('同步成功。');
                    this._flushTable();
                }).catch((reject) => {
                    message.error(reject.message);
                });
        });
    };

    // 清除选择项后重新加载列表
    _flushTable = ()=>{
        this.setState({
            batchIds: []
        }, ()=>{
            this._searchHandle('useCache');
        });
    }

    render() {
        const { brandName, syncStatus, batchIds } = this.state;
        const { thirdPartyBrand } = this.props;
        return (
            <KeepAlive id="thirdparty" render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='品牌名称'
                                placeholder='请输入品牌名称'
                                bindThis={this}
                                bindName='brandName'
                                value={brandName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={this.syncStatusOptions}
                                bindThis={this}
                                bindName='syncStatus'
                                dataIndex='text'
                                keyIndex='value'
                                isOptionBindID={true}
                                value={syncStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '100px' }} label='批量同步' onClick={this._batchSyncBrand}/>
                    </div>
                    <TableData selectedRowKeys={batchIds} paginationChange={this._paginationChange} renderData={thirdPartyBrand} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}

ThirdPartyBrand.propTypes = {
    getThirdPartyProductBrand: PropTypes.func,
    thirdPartyBrand: PropTypes.object
};

const mapStateToProps = (state) => ({
    thirdPartyBrand: state.productBrand.thirdPartyProductBrand
});

export default connect(mapStateToProps, { getThirdPartyProductBrand })(ThirdPartyBrand);