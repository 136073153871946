import * as T from './actiontypes';
import { setLocalStorage, getLocalStorage } from '@/assets/js/storage';

/**
 * 存储面包屑导航路径 
 * @param {Array} state  
 *  [{
 *      title: '运营中心管理', // 导航名称
 *      path: '/home/operationcenter' // 导航路径
 *  }]
 */
let breadcrumbPathsInital = getLocalStorage('breadcrumbPaths') ? JSON.parse(getLocalStorage('breadcrumbPaths')).data : [];

export const breadcrumbPaths = (state = breadcrumbPathsInital, action) => {
    let newState = [];
    switch (action.type) {
        case T.SET_BREADCRUMB_PATHS: // 设置面包屑路径
            setLocalStorage('breadcrumbPaths', { data: action.payload });
            return action.payload;
        case T.ADD_ONE_BREADCRUMB_PATH: // 增加一级子面包屑路径
            newState = [...state];
            newState.push(action.payload);
            setLocalStorage('breadcrumbPaths', { data: newState });
            return newState;
        case T.KEEP_SECOND_NAV_BREADCRUMB: // 仅保留两级面包屑路径
            newState = [...state].slice(0, 2);
            setLocalStorage('breadcrumbPaths', { data: newState });
            return newState;
        case T.KEEP_THIRD_NAV_BREADCRUMB: // 保留三级面包屑路径
            newState = [...state].slice(0, 3);
            setLocalStorage('breadcrumbPaths', { data: newState });
            return newState;
        case T.KEEP_FOUR_NAV_BREADCRUMB: // 保留四级面包屑路径
            newState = [...state].slice(0, 4);
            setLocalStorage('breadcrumbPaths', { data: newState });
            return newState;
        default:
            return state;
    }
};

/**
 * 存储当前活动的一级导航路径
 */
let activedCardPathInital = getLocalStorage('activedCardPath');

export const activedCardPath = (state = activedCardPathInital, action) => {
    switch (action.type) {
        case T.SET_ACTIVED_CARD_PATH:
            setLocalStorage('activedCardPath', action.payload);
            return action.payload;
        default:
            return state;
    }
};

/**
 * 存储当前活动的二级导航路径
 */
let activedRoutePathInital = getLocalStorage('activedRoutePath');

export const activedRoutePath = (state = activedRoutePathInital, action) => {
    switch (action.type) {
        case T.SET_ACTIVED_ROUTE_PATH:
            setLocalStorage('activedRoutePath', action.payload);
            return action.payload;
        default:
            return state;
    }
};

/**
 * 存储当前导航栏的伸展状态
 * @param {Boolean} state false关闭；true打开
 */
export const stretchOpen = (state = true, action) => {
    switch (action.type) {
        case T.SET_STRETCHOPEN:
            return action.payload;
        default:
            return state;
    }
};

/**
 * 获取登录时菜单
 */
export const getMenuList = (state = [], action) => {
    switch (action.type) {
        case T.SET_MENUS_LIST:
            return action.payload.result;
        default:
            return state;
    }
};