import { upload } from 'qiniu-js';
import config from './config';
import { generateFileName } from './_util';
export default function useQiniuUpload(props = {}, uploadOptions) {
    const { uploadType = 'image', options } = props;
    function qiniuUpload(file, resolve, reject) {
        const fileName = generateFileName(file.name || uploadType);
        return config[uploadType].getToken({ fileName }).then(({ result }) => {
            const observable = upload(file, fileName, result, {}, {});
            let subscription = observable.subscribe({
                next(res) {
                    if (options)
                        options.next(res);
                    uploadOptions.next(res);
                },
                error(res) {
                    if (options)
                        options.error(res);
                    uploadOptions.error(res);
                    reject({ type: 'error', error: res });
                },
                complete(res) {
                    if (options)
                        options.complete(res);
                    uploadOptions.complete(res);
                    config[uploadType].getFile({ key: res.key, fileName }).then((fileRes) => {
                        resolve({ fileUrl: fileRes.result });
                    }).catch((error) => reject({ type: 'complete', error }));
                }
            });
            return { observable, subscription };
        });
    }
    return {
        qiniuUpload
    };
}