/**
 * 审核详情弹框
 */
import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { XMediaFn } from '@/components/xqxc_ui';
import styles from './DetailModal.module.scss';
import { getCommunityAuditDetail } from '@/reducers/auditmanage/community/actions';
import ProductTable from '../conponents/ProductTable';

class DetailModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
    }

    componentDidMount() {
        const { accountId, feedId, showType } = this.props;
        this.props.getCommunityAuditDetail({ accountId, feedId, showType });
    }

    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _imageDeal = (images) => {
        return <XMediaFn
            style={{ marginRight: '0' }}
            dataType='image'
            dataSource={images}
        />;
    }

    render() {
        const { communityAuditDetail } = this.props;
        const { visible, confirmLoading } = this.state;
        const { goodsList } = communityAuditDetail;

        return (
            <Modal
                width={1000}
                bodyStyle={{ paddingRight: 10 }}
                centered
                title='社区详情'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.phone}>
                        <span>手机号码：</span>
                        <span>{communityAuditDetail.tel}</span>
                    </div>
                    <div className={styles.memberName}>
                        <span>会员昵称：</span>
                        <span>{communityAuditDetail.nickname}</span>
                    </div>
                    <div className={styles.title}>
                        <span>社区标题：</span>
                        <span>{communityAuditDetail.feedTitle}</span>
                    </div>
                    <div className={styles.title}>
                        <span>社区分类：</span>
                        <span>{communityAuditDetail.className}</span>
                    </div>

                    <div className={styles.content}>
                        <div className={styles.label}>社区内容：</div>
                        <div className={styles.feedInfoBox}>
                            <div className={styles.image} >
                                {
                                    this._imageDeal(communityAuditDetail.fileNames)
                                }
                            </div>
                            <div className={styles.text}>{communityAuditDetail.content}</div>
                        </div>
                    </div>
                    <div className={styles.tables}>
                        <ProductTable renderData={goodsList || []} />
                    </div>
                    <div className={styles.status}>
                        <span>状&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;态：</span>
                        <span>{communityAuditDetail.state.value}</span>
                    </div>
                    <div className={styles.reason}>
                        <span>审核意见：</span>
                        <span>{communityAuditDetail.remark}</span>
                    </div>
                </div>
            </Modal>
        );
    }
}

DetailModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    accountId: PropTypes.number,
    feedId: PropTypes.number,
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    SinglePatch: PropTypes.bool, // 权限：单个修改还是批量修改，当为true时表示单个修改
    getCommunityAuditDetail: PropTypes.func,
    communityAuditDetail: PropTypes.object,
    showType: PropTypes.string
};


const mapStateToProps = (state) => ({
    communityAuditDetail: state.communityAuditData.communityAuditDetail
});

export default connect(mapStateToProps, { getCommunityAuditDetail })(DetailModal);