/**
 * 在线播放弹框
 */
import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import styles from './OnlinePlayModal.module.scss';


class OnlinePlayModal extends React.Component {
    
    state = {
        visible: this.props.visible,
        confirmLoading: false,
        status: '',
    }

    componentDidMount(){
        console.log (this.props.audit);
    }
    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }


    _handleCancel  = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render(){
        const { visible, confirmLoading } = this.state;
        
        return(
            <Modal
                centered
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='关闭'
                okText='确认'
            >
                <div className={styles.container}>
                    <video width="100%" height="300"src="" poster="" muted controls></video>
                </div>
                
            </Modal>
        );
    }
}

OnlinePlayModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    audit:PropTypes.object
};

export default OnlinePlayModal;