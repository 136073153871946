import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

class DataGraph extends React.Component {

    render() {
        return (
            <Main history={this.props.history} />
        );
    }
}

DataGraph.propTypes = {
    history: PropTypes.object
};

export default DataGraph;

