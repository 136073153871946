
//接单记录
export const GET_RECEIVE_RECORD_LIST = 'RECEIVEORDER/GET_RECEIVE_RECORD_LIST';
export const SET_RECEIVE_RECORD_LIST = 'RECEIVEORDER/SET_RECEIVE_RECORD_LIST';

//接单记录详情
export const GET_RECEIVE_RECORD_DETAIL = 'RECEIVEORDER/GET_RECEIVE_RECORD_DETAIL';
export const SET_RECEIVE_RECORD_DETAIL = 'RECEIVEORDER/SET_RECEIVE_RECORD_DETAIL';

//待接单记录
export const GET_WAIT_RECEIVE_LIST = 'RECEIVEORDER/GET_WAIT_RECEIVE_LIST';
export const SET_WAIT_RECEIVE_LIST = 'RECEIVEORDER/SET_WAIT_RECEIVE_LIST';

//待接单记录详情
export const GET_WAIT_RECEIVE_DETAIL = 'RECEIVEORDER/GET_WAIT_RECEIVE_DETAIL';
export const SET_WAIT_RECEIVE_DETAIL = 'RECEIVEORDER/SET_WAIT_RECEIVE_DETAIL';

//接单记录状态
export const GET_RECEIVE_RECORD_STATE = 'RECEIVEORDER/GET_RECEIVE_RECORD_STATE';
export const SET_RECEIVE_RECORD_STATE = 'RECEIVEORDER/SET_RECEIVE_RECORD_STATE';

