/**
 * 合同线上
 */
import React from 'react';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import ContractModal from '../../modal/ContractModal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message } from 'antd';
import { getContractListTableSource, getContractStates, getContractNames } from '@/reducers/contractmanage/contractlist/actions';
import { SearchBox, SearchItem } from '@/components/Layout';
import Preview from '../../modal/Preview';
import ButtonPerssion from '@/routes/ButtonPerssion';

const Mode = {
    CREAT: 'CREAT',
    EDITE: 'EDITE',
    DELETE: 'DELETE',
    VIEW: 'VIEW',
};

class Onlinelist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orderStartTime: null, // 合同开始时间
            orderEndTime: null, // 合同结束时间
            type: {}, // 合同类型
            typeName: {}, // 合同名称
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
        };
        this.searchCache = { pageNum: 1, pageSize: 15 }; // 查询缓存
    }

    componentDidMount() {
        this.props.getContractListTableSource(this.searchCache);
        this.props.getContractStates();
        this.props.getContractNames();
    }

    // 下拉选项数据渲染
    _giftSelectData(data) {
        return data.map(item => ({
            id: item.code,
            name: item.value,
        }));
    }
    // 合同开始时间
    _orderStartTimeChangeHandle = (time) => {
        this.setState({ orderStartTime: time });
    }

    // 合同结束时间
    _orderEndTimeChangeHandle = (time) => {
        this.setState({ orderEndTime: time });
    }

    // 分类状态下拉列改变时触发
    _typeChangeHandle = (selectedItem) => {
        this.setState({ type: selectedItem });
    }

    _typeNameChangeHandle = (selectedItem) => {
        this.setState({ typeName: selectedItem });
    }

    // 创建礼物分类
    _createStudioRoom = () => {
        this.setState({ currentOpenMode: Mode.CREAT, openModeParam: {} });
    }

    // 查询
    _searchHandle = () => {
        const { typeName, orderStartTime, orderEndTime, type } = this.state;
        this.searchCache = {
            contractName: typeName.constantKey,
            contractState: type.code,
            startDate: orderStartTime,
            endDate: orderEndTime,
            pageNum: 1,
            pageSize: 15
        };
        this.props.getContractListTableSource(this.searchCache);
    }

    // 重置触发 
    _resetHandle = () => {
        this.setState({
            orderStartTime: null, // 合同开始时间
            orderEndTime: null, // 合同结束时间
            type: {}, // 合同类型
            typeName: {}, // 合同名称
        });
        this.searchCache = { pageNum: 1, pageSize: 15 };
        this.props.getContractListTableSource(this.searchCache);
    }

    // 表格操作 
    _tableAction = (id, type, record) => {
        switch (type) {
            case 'view': // 查看
            case 'contractName':
                this.setState({
                    currentOpenMode: Mode.VIEW,
                    openModeParam: record
                });
                break;
            case 'disable': // 禁用
                http('/contract/updateContract/state', { id: record.id, contractState: 'DISABLE', contractName: record.contractName.code }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('禁用成功');
                        this.props.getContractListTableSource(this.searchCache);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
            case 'enable': // 启用
                http('/contract/updateContract/state', { id: record.id, contractState: 'PERFORM', contractName: record.contractName.code }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('启用成功');
                        this.props.getContractListTableSource(this.searchCache);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
            case 'preview': //预览
                this.setState({ currentOpenMode: 'preview' });
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.CREAT:
                return <ContractModal title='新建合同' visible={true} readOnly={false} contraceNames={this.props.contractNames} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case Mode.VIEW:
                return <ContractModal title='查看合同' visible={true} readOnly={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case Mode.EDITE:
                return <ContractModal title='编辑合同' visible={true} readOnly={false} contraceNames={this.props.contractNames} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'preview':
                return <Preview closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '', openModeParam: {} });
        if (status) this.props.getContractListTableSource(this.searchCache);
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getContractListTableSource(this.searchCache);
    }

    render() {
        const { type, typeName, orderStartTime, orderEndTime } = this.state;
        const { contractListTableSource, contractStates, contractNames } = this.props;

        return (
            <div className={styles.flexBoxContainer} >
                <SearchBox>
                    <SearchItem>
                        <XSelect
                            label='合同名称'
                            placeholder='请选择合同名称'
                            renderData={contractNames}
                            onChange={this._typeNameChangeHandle}
                            dataIndex='constantName'
                            keyIndex='constantKey'
                            value={typeName.constantName}
                            bindThis={this}
                            bindName='typeName'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XDatePicker
                            label='创建时间'
                            onChange={this._orderStartTimeChangeHandle}
                            value={orderStartTime}
                            showTime={false}
                            defaultTime='00:00:00'
                            isFormat
                        />
                        <XDatePicker
                            style={{ marginRight: '20px' }}
                            onChange={this._orderEndTimeChangeHandle}
                            value={orderEndTime}
                            showTime={false}
                            defaultTime='23:59:59'
                            isFormat
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='合同状态'
                            placeholder='请选择分类状态'
                            renderData={contractStates}
                            dataIndex='value'
                            keyIndex='code'
                            value={type.value}
                            bindThis={this}
                            bindName='type'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </SearchItem>
                </SearchBox>
                {
                    <ButtonPerssion code='contractmanagement/onlinelist/xshtxjht'>
                        <div className={styles.operate_box}>
                            <XOKButton style={{ width: '140px' }} label='新建合同' onClick={this._createStudioRoom} />
                        </div>
                    </ButtonPerssion>
                }
                <TableContent renderData={contractListTableSource} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                {this._renderMode()}
            </div >
        );
    }
}

Onlinelist.propTypes = {
    history: PropTypes.object, // 路由history对象

    contractListTableSource: PropTypes.object,
    getContractListTableSource: PropTypes.func,

    contractStates: PropTypes.array,
    getContractStates: PropTypes.func,

    contractNames: PropTypes.array,
    getContractNames: PropTypes.func

};

const mapStateToProps = (state) => ({
    contractListTableSource: state.contractlist.contractListTableSource,
    contractStates: state.contractlist.contractStates,
    contractNames: state.contractlist.contractNames
});

export default connect(mapStateToProps, { getContractListTableSource, getContractStates, getContractNames })(Onlinelist);
