import { createModel } from '@/store/tools';

const model = {
    namespace: 'cardticket',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getShopMembersList': T('getShopMembersList'),
            'getCreateAcountList': T('getCreateAcountList'),
            'getCardticketInfo': T('getCardticketInfo'),
            'getCardticketPublishTable': T('getCardticketPublishTable'),
            'getCardticketUseEnum': T('getCardticketUseEnum'),
        },
        actions: {
            'getShopMembersList': A('getShopMembersList'), // 代言类型为兑换券--新增商品列表数据
            'getCreateAcountList': A('getCreateAcountList'), // 代言类型为兑换券--新增会员列表数据
            'getCardticketInfo': A('getCardticketInfo'),
            'getCardticketPublishTable': A('getCardticketPublishTable'),
            'getCleanPublishTable': C('getCardticketPublishTable'),
            'getCardticketUseEnum': A('getCardticketUseEnum'),
        },
        sagas: {
            'getShopMembersList': S('getShopMembersList', '/goods/query/queryGoodsItem'),
            'getCreateAcountList': S('getCreateAcountList', '/user/account/listAllAccountBaseInfo'),
            'getCardticketInfo': S('getCardticketInfo', '/coupon/coupon/countCouponData', 'POST', 'couponId'),
            'getCardticketPublishTable': S('getCardticketPublishTable', '/coupon/coupon/findCouponData'),
            'getCardticketUseEnum': S('getCardticketUseEnum', '/enum/CouponCustomerStatusEnum'),
        },
        reducers: {
            'shopMembersList': R('getShopMembersList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'createAcountList': R('getCreateAcountList', { dataList: [], pageNum: 1, pageSize: 10, resultTotal: 0 }),
            'cardticketInfo': R('getCardticketInfo', {}),
            'cardticketPublishTable': R('getCardticketPublishTable', { dataList: [], pageNum: 1, pageSize: 10, resultTotal: 0 }),
            'cardticketUseEnum': R('getCardticketUseEnum', []),
        }
    })
};
export default createModel(model);