/*
*【商品信息总况】入口页
*/
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages';

const GoodsTotalCase = ({ history }) => {
    return <Main history={history}></Main>;
};

GoodsTotalCase.propTypes = {
    history: PropTypes.object.isRequired//路由对象
};

export default GoodsTotalCase;