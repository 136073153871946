/**
 * 确认按钮组件
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Button } from 'antd';

class OKButtonModule extends React.Component {
    render() {
        const { style, label, ...otherProps } = this.props;
        return (
            <div className={styles.container} style={style}>
                <Button type="primary" {...otherProps}>{label}</Button>
            </div>
        );
    }
}

OKButtonModule.defaultProps = {
    style: { width: '60px', marginRight: '20px' }
};

OKButtonModule.propTypes = {
    style: PropTypes.object, // 按钮总样式
    label: PropTypes.string, // 按钮名称
    onClick: PropTypes.func, // 按钮点击时触发的回调函数
    disabled: PropTypes.bool // 按钮失效
};

export default OKButtonModule;