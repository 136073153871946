import React from 'react';
import styles from './OrderSearch.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import moment from 'moment';
import { getProductOrderType, getProductOrderStatus, getProductOrderFlagEnum, getProductConvertWays, getPaymentAccessTypeEnum, getPaymentTypeEnum } from '@/reducers/ordermanage/productOrder/actions';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker } from '@/components/xqxc_ui';

// const orderStateSelect = [{id:1, name:'待付款'},{id:2, name:'待发款'}];
// const orderTypeSelect = [{id:1, name:'普通订单'},{id:2, name:'报税订单'}];
// const invoiceSelect = [{id:1, name:'是'},{id:2, name:'否'}];

class OrderSearch extends React.Component {

    state = {
        telephone: '', // 会员手机
        orderCode: '', // 订单编号
        orderStartTime: null, // 下单时间开始日期
        orderEndTime: null, // 下单结束日期
        orderState: {}, // 订单状态
        orderType: {}, // 订单类型
        invoice: {}, // 是否开票
        productName: '', // 商品名称
        productCode: '', // 商品货号
        convertWays: {}, // 兑换方式
        payType: {}, // 支付方式
        payAccessType: {}, // 支付通道
    }


    componentDidMount() {
        this.props.getProductOrderType();
        this.props.getProductOrderStatus();
        this.props.getProductOrderFlagEnum();
        this.props.getProductConvertWays();//兑换方式
         // 支付通道
         this.props.getPaymentAccessTypeEnum();
         // 支付方式
         this.props.getPaymentTypeEnum();
    }
    _renderOrderStateSelect = (orderState) => {
        const { productOrderStatus } = this.props;
        if (this.props.orderState == 1) {
            return (
                <XSelect
                    label='订单状态'
                    placeholder='请选择订单状态'
                    renderData={productOrderStatus}
                    dataIndex='value'
                    keyIndex='code'
                    value={orderState.value}
                    showSearch={true}
                    bindThis={this}
                    bindName='orderState'
                />
            );
        } else {
            return null;
        }
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState(newProps.searchState.param);
    }
    // 查询
    _searchHandle = () => {
        const { payType, payAccessType, telephone, orderCode, orderState, orderType, invoice, productName, productCode, orderStartTime, orderEndTime, convertWays } = this.state;
        this.searchCache = {
            telephone,
            orderCode,
            orderState,
            orderType,
            invoice,
            productName,
            productCode,
            orderStartTime,
            orderEndTime,
            convertWays,
            payType,
            payAccessType
        };
        let orderStartTimes = '';
        let orderEndTimes = '';
        if (orderStartTime) {
            orderStartTimes = orderStartTime;
        }
        if (orderEndTime) {
            orderEndTimes = orderEndTime;
        }
        const statusList = orderState.code ? [orderState.code] : [];
        const isInvoice = invoice.code ? invoice.code : '';

        //兑换方式
        const convertMode = convertWays.code ? convertWays.code : '';
        this.props.searchOrder({
            accountTel: telephone, gmtCreateF: orderStartTimes, gmtCreateT: orderEndTimes, orderId: orderCode,
            goodsName: productName, isInvoice, itemNo: productCode, orderType: orderType.code, statusList, convertMode,
            payType: payType.code, payAccessType: payAccessType.code
        }, this.searchCache);
        // if (!orderStartTimes && !orderEndTimes) {
        //     this.props.searchOrder({
        //         accountTel: telephone, orderId: orderCode,
        //         goodsName: productName, isInvoice, itemNo: productCode, orderType: orderType.code, statusList, convertMode
        //     }, this.searchCache);
        // } else if (orderStartTimes && orderEndTimes) {
        //     this.props.searchOrder({
        //         accountTel: telephone, gmtCreateF: orderStartTimes, gmtCreateT: orderEndTimes, orderId: orderCode,
        //         goodsName: productName, isInvoice, itemNo: productCode, orderType: orderType.code, statusList, convertMode
        //     }, this.searchCache);
        // } else if (!orderStartTimes || !orderEndTimes) {
        //     message.error('时间必须输入完整！');
        // }
        // console.log(this.state);
    }


    // 重置
    _resetHandle = () => {
        this.setState({
            telephone: '',
            orderCode: '',
            orderStartTime: null,
            orderEndTime: null,
            orderState: {},
            orderType: {},
            invoice: {},
            productName: '',
            productCode: '',
            convertWays: {},
            payType: {},
            payAccessType: {}
        });
        this.searchCache = {
            telephone: '',
            orderCode: '',
            orderStartTime: null,
            orderEndTime: null,
            orderState: {},
            orderType: {},
            invoice: {},
            productName: '',
            productCode: '',
            convertWays: {},
            payType: {},
            payAccessType: {}
        };
        this.props.searchOrder({
        }, this.searchCache, true);
    }


    render() {
        const { payType, payAccessType, telephone, orderCode, orderStartTime, orderEndTime, orderState, orderType, invoice, productName, productCode, convertWays } = this.state;
        const { productOrderType, productOrderFlagEnum, productConvertWays, paymentAccessTypeEnum, paymentTypeEnum } = this.props;
        return (
            <div className={styles.container} style={{ marginTop: 10 }}>
                <SearchBox>
                    <SearchItem>
                        <XInput
                            label='会员手机'
                            placeholder='请输入手机号码'
                            value={telephone}
                            bindThis={this}
                            bindName='telephone'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            label='订单编号'
                            placeholder='请输入订单编号'
                            value={orderCode}
                            bindThis={this}
                            bindName='orderCode'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XDatePicker
                            label='下单时间'
                            value={orderStartTime}
                            bindThis={this}
                            bindName='orderStartTime'
                            isFormat
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            style={{ marginRight: 20 }}
                            label=''
                            value={orderEndTime}
                            bindThis={this}
                            bindName='orderEndTime'
                            isFormat
                        />
                    </SearchItem>
                    <SearchItem>
                        {this._renderOrderStateSelect(orderState)}
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='订单类型'
                            placeholder='请选择订单类型'
                            renderData={productOrderType}
                            dataIndex='value'
                            keyIndex='code'
                            value={orderType.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='orderType'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='是否开票'
                            placeholder='请选择是否开票'
                            renderData={productOrderFlagEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={invoice.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='invoice'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            label='商品名称'
                            placeholder='请输入商品名称'
                            value={productName}
                            bindThis={this}
                            bindName='productName'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            label='商品货号'
                            placeholder='请输入商品货号'
                            value={productCode}
                            bindThis={this}
                            bindName='productCode'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='兑换方式'
                            placeholder='请选择兑换方式'
                            renderData={productConvertWays}
                            dataIndex='value'
                            keyIndex='code'
                            value={convertWays.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='convertWays'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='支付通道'
                            placeholder='请选择支付通道'
                            renderData={paymentAccessTypeEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={payAccessType.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='payAccessType'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='支付方式'
                            placeholder='请选择支付方式'
                            renderData={paymentTypeEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={payType.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='payType'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </SearchItem>
                </SearchBox>
            </div>
        );
    }
}

OrderSearch.propTypes = {
    orderState: PropTypes.number,
    searchOrder: PropTypes.func,
    getProductOrderType: PropTypes.func,
    getProductOrderStatus: PropTypes.func,
    getProductOrderFlagEnum: PropTypes.func,
    productOrderType: PropTypes.array,
    productOrderStatus: PropTypes.array,
    productOrderFlagEnum: PropTypes.array,
    getProductConvertWays: PropTypes.func,
    productConvertWays: PropTypes.array,
    getPaymentAccessTypeEnum: PropTypes.func,
    paymentAccessTypeEnum: PropTypes.array,
    getPaymentTypeEnum: PropTypes.func,
    paymentTypeEnum: PropTypes.array
};
const mapStateToProps = (state) => ({
    productOrderType: state.productOrder.productOrderType,
    productOrderStatus: state.productOrder.productOrderStatus,
    productOrderFlagEnum: state.productOrder.productOrderFlagEnum,
    productConvertWays: state.productOrder.productConvertWays,
    paymentAccessTypeEnum: state.productOrder.paymentAccessTypeEnum,
    paymentTypeEnum: state.productOrder.paymentTypeEnum
});
export default connect(mapStateToProps, { getProductOrderType, getProductOrderStatus, getProductOrderFlagEnum, getProductConvertWays, getPaymentAccessTypeEnum, getPaymentTypeEnum })(OrderSearch);