import * as T from './actiontypes';

/**
 * 获取角色列表
 */
export const getRolesList = (state = {}, action) => {
    switch (action.type) {
        case T.SET_ROLES_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取角色详情
 */
export const getRolesDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_ROLES_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取本公司超级管理员
 */
const adminEmplListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const getAdminEmpl = (state = adminEmplListInital, action) => {
    switch (action.type) {
        case T.SET_ADMIN_EMPL:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取平台、运营中心、区域中心、商户某个角色下面得所有员工
 */
const emplListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const getEmplsByRole = (state = emplListInital, action) => {
    switch (action.type) {
        case T.SET_EMPL_BY_ROLE:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取未分类角色员工
 */
const unemplListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const getUnEmpl = (state = unemplListInital, action) => {
    switch (action.type) {
        case T.SET_UN_EMPL:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取角色下拉列表(复制角色权限以及权限分配时均可用)
 */
export const getRoleSelect = (state = [], action) => {
    switch (action.type) {
        case T.SET_ROLE_SELECT:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取某角色权限的菜单
 */
export const getMenuByRole = (state = [], action) => {
    switch (action.type) {
        case T.SET_MENU_BY_ROLE:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取员工下拉列表（在新建员工角色时用到）
 */
export const getDownEmpls = (state = [], action) => {
    switch (action.type) {
        case T.SET_DOWNEMPLS:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 根据员工获取角色列表（对已关联得角色做记号）
 */
export const getListRoleByEmpl = (state = [], action) => {
    switch (action.type) {
        case T.SET_ROLE_BY_EMPL:
            return action.payload.result;
        default:
            return state;
    }
};

