// 后去用户信息和类型
import http from '@/assets/api/http';

const getMemberInfo = () => {
    let type = '';
    let data = {};
    return new Promise((resolve, reject) => {
        http('/company/info/yums/getMemberType', {}).then((response) => {
            if (response.status == 200) {
                if (response.result == '2') {
                    type = 'BUSINESS';
                    http('/company/info/yums/getCompanyInfo', {}).then((info) => {
                        data = info.result;
                        resolve({ type, data });
                    }).catch((e) => reject(e));
                } else {
                    type = '';
                    resolve({ type, data });
                }
            }
        }).catch((e) => {
            reject(e);
        });
    });
};

export default getMemberInfo;