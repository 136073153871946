import React from 'react';
import styles from './TableData.module.scss';
import { tooltipStyle, tooltip } from '@/components/TableCell';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
    {
        title: '编号ID',
        dataIndex: 'merchantKey',
        key: 'merchantKey',
        width: '12%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '店铺名称',
        dataIndex: 'shopName',
        key: 'shopName',
        width: '20%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '归属商户',
        dataIndex: 'merchantName',
        key: 'merchantName',
        width: '15%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '联系人电话',
        dataIndex: 'contactTel',
        key: 'contactTel',
        width: '12%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '店铺状态',
        dataIndex: 'status',
        key: 'status',
        width: '12%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '10%',
        render: (id, item) => TableAction(id, item, actionClickHandle)
    },
];

export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    return data && data.map((item) => {
        item.merchantKey = item.id;//操作key
        item.status = item.shopState == '1' ? '营业中' : '已停业';//店铺状态
        return item;
    });
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};


const TableAction = (id, item, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, item, 'DELETE')}>详情</div>
            {item.shopBanned == '0' ? <div className={styles.item} onClick={() => actionClickHandle(id, item, 'BANNED')}>封禁</div> :
                <div className={styles.item} onClick={() => actionClickHandle(id, item, 'LIFTING')}>解封</div>
            }
        </div>
    );
};