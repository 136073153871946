import React from 'react';
import styles from './TableData.module.scss';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
  {
    title: '服务单号',
    dataIndex: 'id',
    key: 'id',
    width: cellWidth.normal,
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    key: 'orderId',
    width: cellWidth.normal,
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '会员账号',
    dataIndex: 'accountInfo',
    key: 'accountInfo',
    width: cellWidth.normal,
    align: 'center',
    render: (text) => {
      return <span>{text && text.accountName}</span>;
    }
  },
  {
    title: '售后类型',
    dataIndex: 'salesTypeValue',
    key: 'salesTypeValue',
    width: cellWidth.normal,
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '消费类型',
    dataIndex: 'consumeType',
    key: 'consumeType',
    width: cellWidth.normal,
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '申请数量',
    dataIndex: 'number',
    key: 'number',
    width: cellWidth.normal,
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '退款金额(元)',
    dataIndex: 'refundAmount',
    key: 'refundAmount',
    width: cellWidth.normal,
    align: 'center',
    render: (text) => (_renderTextMoneyCell(text))
  },
  {
    title: '售后原因',
    dataIndex: 'salesReason',
    key: 'salesReason',
    width: cellWidth.normal,
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '售后状态',
    dataIndex: 'process',
    key: 'process',
    width: cellWidth.normal,
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '处理状态',
    dataIndex: 'nodeName',
    key: 'nodeName',
    width: cellWidth.normal,
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '创建时间',
    dataIndex: 'gmtCreate',
    key: 'gmtCreate',
    width: cellWidth.normal,
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },

  {
    title: '操作',
    dataIndex: 'c4',
    key: 'c4',
    align: 'center',
    fixed: 'right',
    width: cellWidth.normal,
    render: (text, item) => TableAction(item, actionClickHandle)
  },
];

export const takeDataSource = (data) => {
  // 解析表格数据，使符合columns格式要求
  // let tableSource = data.map((item)=>{

  //   return tableCell;
  // });
  return data;
};
const _renderTextMoneyCell = (text) => {
  return (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
      {regFenToYuan(text)}
    </div>
  );
};


export const takeColumns = (actionClickHandle) => {
  return columns(actionClickHandle);
};

const TableCell = (text) => {
  return (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
      {text}
    </div>
  );
};

const TableAction = (record, actionClickHandle) => {
  return (
    <div className={styles.action}>
      {/* <div className={styles.item} onClick={()=>actionClickHandle(record, 'ACCEPT')}>审核</div> */}
      {/* <div className={styles.item} onClick={()=>actionClickHandle(record, 'RESULT')}>处理结果</div> */}
      <div className={styles.item} onClick={() => actionClickHandle(record, 'DETAIL')}>查看详情</div>
    </div>
  );
};