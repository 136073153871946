/**
 * 禁用弹框
 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, message, Form, Input, Radio } from 'antd';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import model from '@/reducers/knowledgepay/channellist/model';

const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    }
};
const radioData = [
    {
        code: 1,
        value: '启用'
    },
    {
        code: 0,
        value: '禁用'
    }
];

const BanModal = (props) => {

    const [visible, setVisible] = useState(props.visible);
    const [confirmLoading, setConfirmLoading] = useState(false);

    useEffect(() => {
        props.getKBanReason({ pageNum: 1, pageSize: 100 });
    }, []);

    //确认
    const _handleOk = () => {
        _handleSubmit();
    };

    //取消
    const _handleCancel = () => {
        setVisible(false);
        props.closeMode(false);
    };

    //提交
    const _handleSubmit = e => {
        e && e.preventDefault();
        props.form.validateFieldsAndScroll((err, { reason, reportTypeId }) => {
            if (!err) {
                setConfirmLoading(true);
                http('/show/chargefeed/disableCjargeFeed', { chargeFeedId: props.modeParams.id, reason, reportTypeId }, 'POST').then(() => {
                    message.success('禁用成功。');
                    setVisible(false);
                    setConfirmLoading(false);
                    props.closeMode(true);
                }).catch((error) => {
                    setConfirmLoading(false);
                    message.error(error.message);
                });
            }
        });
    };

    const { getFieldDecorator } = props.form;
    return (
        <Modal
            width={600}
            centered
            visible={visible}
            confirmLoading={confirmLoading}
            onOk={_handleOk}
            onCancel={_handleCancel}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <Form id='communityBan' {...formItemLayout} onSubmit={_handleSubmit}>
                <Form.Item label="禁用理由">
                    {getFieldDecorator('reportTypeId', {
                        rules: [
                            {
                                required: true,
                                message: '请选择禁用理由',
                            },
                        ],
                    })(<Radio.Group>
                        {
                            props.kBanReason.map(item => {
                                return <Radio key={item.id} value={item.id}>{item.reportTypeName}</Radio>;
                            })
                        }
                    </Radio.Group>)}
                </Form.Item>
                <Form.Item label=' ' colon={false}>
                    {getFieldDecorator('reason', {
                        rules: [
                            {
                                required: true,
                                message: '请输入其他原因'
                            },
                        ],
                    })(<TextArea
                        placeholder='请输入其他原因'
                        autoSize={{ minRows: 3, maxRows: 5 }}
                    />)}
                </Form.Item>
            </Form>
        </Modal>
    );
};

BanModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    form: PropTypes.object.isRequired,//form对象
    modeParams: PropTypes.object,
    getKBanReason: PropTypes.func,
    kBanReason: PropTypes.array
};

const mapStateToProps = (state) => ({
    kBanReason: state.klist.kBanReason.dataList,
});

const BanModalForm = Form.create({ name: 'communityBan' })(BanModal);
export default connect(mapStateToProps, { ...model.actions })(BanModalForm);
