/**
 * [第三方商品分类]
 * @author: Fu Xiaochun
 * @date: 2021-06-02 
 */


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { message } from 'antd';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import TableData from './components/TableData';
import { getProductThirdPartyClassify } from '@/reducers/productmanage/classify/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import SyncClassify from './modal/syncClassify';

class ThirdParty extends Component {

    constructor(props){
        super(props);
        this.state = {
            openModal: false,
            categoryName: '',
            status: {},
            curId: 0,
        };
        this.statusOptions = [
            {code: '0', text: '未同步'},
            {code: '1', text: '已同步'},
        ];
    }

    // 查询
    _searchHandle = (useCache) => {
        const { categoryName, status, pageNum, pageSize } = this.state;
        
        this.searchCache = {
            categoryName,
            isSync: status.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getProductThirdPartyClassify(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置
    _resetHandle = () => {
        this.setState({ categoryName: '', status: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 表格操作
    _tableAction = (id) => {
        this.setState({curId: id});
        this._openModal();
    }
    
    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 打开弹窗
    _openModal = ()=>{
        this.setState({
            openModal: true
        });
    }

    // 关闭弹窗
    _closeModal = (data) => {
        this.setState({
            openModal: false
        });
        this.setState({ currentOpenMode: '', openModeParam: null }, () => {
            if (data) {
                this._searchHandle('useCache');
            }
        });
    }

    _modalOk = (platCategoryParentId)=>{

        // 发起同步分类的请求
        // to do
        let id = this.state.curId;
        http('/goods/baseProduct/batchAddBaseGoodsCategoryToPlat', { id, platCategoryParentId }, 'POST')
            .then(() => {
                message.success('同步成功。');
                this._searchHandle('useCache');
            })
            .catch((err) => {
                message.error(err.message);
            });

        this._closeModal(platCategoryParentId);
    }

    render() {
        const { categoryName, status } = this.state;
        const { thirdPartyClassify } = this.props;
        return (
            <KeepAlive id="thirdpartyclassify" render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='分类名称'
                                placeholder='请输入分类名称'
                                bindThis={this}
                                bindName='categoryName'
                                value={categoryName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={this.statusOptions}
                                bindThis={this}
                                bindName='status'
                                dataIndex='text'
                                keyIndex='code'
                                isOptionBindID={true}
                                value={status.code}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableData paginationChange={this._paginationChange} renderData={thirdPartyClassify} tableAction={this._tableAction} />
                    {this.state.openModal ? <SyncClassify onClose={this._closeModal} onOk={this._modalOk} /> : null}
                </div>
            </KeepAlive>
        );
    }
}

ThirdParty.propTypes = {
    getProductThirdPartyClassify: PropTypes.func,
    thirdPartyClassify: PropTypes.object
};


const mapStateToProps = (state) => ({
    thirdPartyClassify: state.classify.productThirdPartyClassify
});

export default connect(mapStateToProps, { getProductThirdPartyClassify })(ThirdParty);