import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import * as actions from '@/reducers/auditmanage/brandAudit/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: [], // 当前需要打开的弹窗的入参
            userName: {},
            auditStatus: {}
        };
    }
    static propTypes = {
        updateContent: PropTypes.func,
        getBrandAuditList: PropTypes.func,
        getBrandAuditUsername: PropTypes.func,
        getBrandAuditState: PropTypes.func,
        brandAuditList: PropTypes.object,
        brandAuditUsername: PropTypes.array,
        brandAuditState: PropTypes.array
    };
    componentDidMount() {
        this.props.getBrandAuditUsername();
        this.props.getBrandAuditState();
    }
    // 查询
    _searchHandle = (useCache) => {
        const { userName, auditStatus, pageNum, pageSize } = this.state;
        this.searchCache = {
            merchantId: userName.merchantId, auditState: auditStatus.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getBrandAuditList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置
    _resetHandle = () => {
        this.setState({ userName: {}, auditStatus: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'audit':
                this.props.updateContent('AUDIT', params);
                break;
            case 'detail':
                this.props.updateContent('DETAIL', params);
                break;
        }
    }
    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        const { userName, auditStatus } = this.state;
        this.props.getBrandAuditList({ merchantId: userName.merchantId, auditState: auditStatus.code, pageNum, pageSize });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    render() {
        const { brandAuditList, brandAuditUsername, brandAuditState } = this.props;
        const { userName, auditStatus } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XSelect
                                label='申请商户'
                                placeholder='请选择'
                                renderData={brandAuditUsername}
                                bindThis={this}
                                bindName='userName'
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                value={userName.merchantName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={brandAuditState}
                                bindThis={this}
                                bindName='auditStatus'
                                dataIndex='value'
                                keyIndex='code'
                                value={auditStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent paginationChange={this._paginationChange} renderData={brandAuditList} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }

}
const mapStateToProps = (state) => ({
    brandAuditList: state.brandAudit.brandAuditList,
    brandAuditUsername: state.brandAudit.brandAuditUsername.merchantIdAndNames || [],
    brandAuditState: state.brandAudit.brandAuditState || [],
});
export default connect(mapStateToProps, actions)(Main);