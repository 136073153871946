import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';

const TableContent = ({ tableAction, renderData: {
    resultTotal = 0,
    pageNum = 1,
    pageSize = 30,
    dataList = []
} = {}, paginationChange, curTabID }) => {

    const _columns = () => [
        {
            title: curTabID == 1 ? '股权溢价(港元)' : 'RMB兑换港元比例',
            dataIndex: curTabID == 1 ? 'stockPremium' : 'cny',
            key: curTabID == 1 ? 'stockPremium' : 'cny',
            align: 'center',
            width: '16%',
            onCell: tooltipStyle,
            render: t => curTabID == 1 ? priceFenToYuanCell(t) : `${regFenToYuan(t)} : 1 `
        },
        {
            title: '执行时间',
            dataIndex: 'startTime',
            key: 'startTime',
            align: 'center',
            width: '20%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModify',
            key: 'gmtModify',
            align: 'center',
            width: '16%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作人',
            dataIndex: 'modifyUserName',
            key: 'modifyUserName',
            align: 'center',
            width: '16%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            width: '16%',
            onCell: tooltipStyle,
            render: t => t ? tooltip('开启') : tooltip('禁用')
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '16%',
            render: _tableAction
        }
    ];

    //实名认证审核操作在阿里云审核，因此先注释，请勿删除文件
    const _tableAction = (id, { state }) => {
        return (
            state && <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, 'blockup')}>停用</div>
            </div>
        );
    };

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                columns={_columns()}
                dataSource={dataList}
                rowKey={record => record.id}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange} />
        </div>
    );

};

TableContent.propTypes = {
    renderData: PropTypes.object, // 渲染数据源
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func,//分页操作
    curTabID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
};
export default TableContent;