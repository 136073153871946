/**
 * 角色删除
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './DeleteModal.module.scss';
import http from '@/assets/api/http';

class DeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
        };
    }

    _handleOk = () => {
        const { data } = this.props;
        http('/admin/role/deleteRole', { id: data.id }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('删除成功！');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title, } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    删除此角色分类， 其下角色将
                    自动分类到“未分类角色”里，是否继续？
                </div>
            </Modal>
        );

    }
}

DeleteModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default DeleteModal;