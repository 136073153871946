/**
 *  审核管理 - 供应商审核
 * */ 
import React from 'react';
import PropTypes from 'prop-types'; 
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class SupplierAudit extends React.Component {

    componentDidMount(){
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch(mode){
            case 'AUDIT': 
                addOneBreadcrumbPath({
                    title:'供应商审核',
                    path:'/home/auditmanage/supplierAudit/audit' 
                });
                history.push({
                    pathname: '/home/auditmanage/supplierAudit/audit/' + value
                });
                break;
            case 'DETAIL': 
                addOneBreadcrumbPath({
                    title:'供应商审核详情',
                    path:'/home/auditmanage/supplierAudit/detail' 
                });
                history.push({
                    pathname: '/home/auditmanage/supplierAudit/detail/' + value
                });
                break;
        }
    }
    
    render(){
        return(
            <Main updateContent={this._updateContent} history = {this.props.history} /> 
        );
    }
}

SupplierAudit.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(SupplierAudit);