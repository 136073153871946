import * as T from './actiontypes';

/**
 * 获取员工列表
 */
const emplListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const getEmplList = (state = emplListInital, action) => {
    switch (action.type) {
        case T.SET_EMPL_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取员工详情
 */
export const getEmplDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_EMPL_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取部门下拉列表
 */
export const getDeptSelect = (state = [], action) => {
    switch (action.type) {
        case T.SET_DEPT_SELECT:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取员工状态
 */
export const getEmplState = (state = [], action) => {
    switch (action.type) {
        case T.SET_EMPL_STATE:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取员工所属分类
 */
export const getEmplType = (state = [], action) => {
    switch (action.type) {
        case T.SET_EMPL_TYPE:
            return action.payload.result;
        default:
            return state;
    }
};