import React from 'react';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import Marketing from '../marketing';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const Mode = {
    CREAT: 'CREAT',//新建
    DELETE: 'DELETE',//详情
    EDIT: 'EDIT',//编辑
};

class Main extends React.Component {

    state = {
        tabs: [
            { id: 1, label: '全部活动' },
            { id: 2, label: '未开始' },
            { id: 3, label: '进行中' },
            { id: 4, label: '已结束' },
        ],
        curt: 1,//tab默认
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }
    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case Mode.CREAT:
                addOneBreadcrumbPath({
                    title: '新建卡券',
                    path: '/home/marketingmanage/cardticket/createInfo/'
                });
                history.push({
                    pathname: '/home/marketingmanage/cardticket/createInfo/',
                });
                break;
            case Mode.DELETE:
                addOneBreadcrumbPath({
                    title: '卡券详情',
                    path: '/home/marketingmanage/cardticket/detailInfo/' + value,
                });
                history.push({
                    pathname: '/home/marketingmanage/cardticket/detailInfo/' + value,
                });
                break;
            case Mode.EDIT:
                addOneBreadcrumbPath({
                    title: '卡券编辑',
                    path: '/home/marketingmanage/cardticket/editorInfo/' + value,
                });
                history.push({
                    pathname: '/home/marketingmanage/cardticket/editorInfo/' + value,
                });
                break;
            case 'getDetail':
                addOneBreadcrumbPath({
                    title: '数据',
                    path: '/home/marketingmanage/cardticket/activitydate/' + value,
                });
                history.push({
                    pathname: '/home/marketingmanage/cardticket/activitydate/' + value,
                });
                break;
        }
    }


    // 导航切换回调
    _anchorClickHandle = (id) => {
        this.setState({
            curt: id,
        });
    }

    // 导航切换
    _anchorMoveHandle = () => {
        const { curt } = this.state;
        return (
            this.state.tabs.map((item, index) => {
                if (index == 0) {
                    return (
                        <span key={item.id} onClick={() => this._anchorClickHandle(item.id)} className={curt == item.id ? styles.curt : ''}>{item.label}</span>
                    );
                } else {
                    return (
                        <span key={item.id} onClick={() => this._anchorClickHandle(item.id)} className={curt == item.id ? styles.curt : ''}>{item.label}</span>
                    );
                }
            })
        );
    }


    render() {
        return (
            <div className={styles.flexBoxContainer}>
                {/* <div className={styles.top_title}>
                    {this._anchorMoveHandle()}
                </div> */}
                <div className={`${styles.flexBoxContainer}`}>
                    <Marketing updateContent={this._updateContent} history={this.props.history} match={this.props.match} />
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};


export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);