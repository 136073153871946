/**
 * Saga集合
 */
import distributionsale from './distributionsale/model';
import operatingcenterdistributionsalepurchase from './operatingcenterdistributionsalepurchase/model';
import regionalcompanydistributionsalepurchase from './regionalcompanydistributionsalepurchase/model';
import serviceproviderdistributionrank from './serviceproviderdistributionrank/model';
import operationshare from './operationshare/model';
import companyshare from './companyshare/model';
import serviceshare from './serviceshare/model';
import goodshare from './goodshare/model';

export default [
    ...Object.values(distributionsale.sagas),
    ...Object.values(operatingcenterdistributionsalepurchase.sagas),
    ...Object.values(regionalcompanydistributionsalepurchase.sagas),
    ...Object.values(serviceproviderdistributionrank.sagas),
    ...Object.values(operationshare.sagas),
    ...Object.values(companyshare.sagas),
    ...Object.values(serviceshare.sagas),
    ...Object.values(goodshare.sagas),
];
