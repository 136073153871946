/**
 * 创建组别弹框
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, Form, message } from 'antd';

const FormItem = Form.Item;//表单校验
const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 20 },
    },
};

class CreateGroup extends React.Component {

    //初始化
    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible,
            confirmLoading: false,
            name: props.modeParams.name || '',
        };
    }

    componentDidMount() {
        const { setFieldsValue } = this.props.form;
        setFieldsValue({
            name: this.state.name,
        });
    }

    //校验props
    static propTypes = {
        visible: PropTypes.bool.isRequired, // 是否显示弹框
        closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
        form: PropTypes.object.isRequired,//表单校验
        title: PropTypes.string,
        modeParams: PropTypes.object
    };

    // 点击确认
    _handleOk = () => {
        this.handleSubmit();
    }

    //提交
    handleSubmit = e => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ visible: false });
                this.props.closeMode(true, { name: values.name });
            }
        });
    };

    // 点击取消
    _handleCancel = () => {
        this.setState({ visible: false });
        this.props.closeMode(false);
    }

    _normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    //渲染组件
    render() {
        const { visible, confirmLoading, name } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={600}
                centered
                className='HotelManageModal'
                title={this.props.title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认' >
                <Form autoComplete='off' {...formItemLayout}>
                    <FormItem
                        htmlFor='name'
                        label={<span>户型名称：</span>}
                    >
                        {getFieldDecorator('name', {
                            rules: [
                                { required: true, message: '请输入户型名称' },
                                {
                                    validator: (rule, value = '', callback) => {
                                        if (value.length > 8) {
                                            callback('户型名称不超过8个字');
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ]
                        })(<Input
                            placeholder='请输入户型名称'
                            suffix={`${name.length}/8`}
                            maxLength={8}
                            onChange={e => { this.setState({ name: e.target.value }); }}
                        />)}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

//暴露组件
const CreateGroupForm = Form.create({ name: 'createGroup' })(CreateGroup);
export default CreateGroupForm;