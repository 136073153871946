/**
 * 店铺预览
 */
import React from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import ShopList from './shoplist';
import GoodsDetail from './goodsdetail';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

class Preview extends React.Component {

    state = {
        loading: false, // 点击下载预览页加载中
        step: 1, // 1:店铺列表  2.商品详情
        shopId: this.props.data.id, // 店铺ID
        goodsId: '', // 商品ID
    }

    // 取消
    _handleCancel = () => {
        this.props.closeMode();
    }

    // 点击选中某商品
    _selectedGoods = (goodsId) => {
        this.setState({ goodsId });
    }

    // 下载截图
    _downloadPage = () => {
        this.setState({ loading: true });
        domtoimage.toBlob(document.getElementById('blobPage'))
            .then(function (blob) {
                saveAs(blob, '店铺.png');
            }).finally(() => {
                this.setState({ loading: false });
            });
    }

    // 选择商品
    _goodsSelect = (goodsId) => {
        this.setState({ goodsId, step: 2 });
    }

    // 返回店铺列表页
    _goback = () => {
        this.setState({ goodsId: '', step: 1 });
    }

    render() {
        const { loading, step, shopId, goodsId } = this.state;
        return (
            <Modal
                width={470}
                centered
                title='店铺预览'
                visible={true}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                closable={true}
                footer={null}
            >
                <div className={styles.container}>
                    <div id='blobPage'>
                        {step == 1 ? <ShopList shopId={shopId} goodsSelect={this._goodsSelect} /> : <GoodsDetail shopId={shopId} goodsId={goodsId} onGoback={this._goback} />}
                    </div>
                    <Button type='primary' style={{ width: '100%', marginTop: '16px' }} loading={loading} onClick={this._downloadPage}>点击下载预览页</Button>
                </div>
            </Modal>
        );
    }
}

Preview.propTypes = {
    closeMode: PropTypes.func.isRequired, // 关闭弹框
    data: PropTypes.object.isRequired, // 入参数据
};

export default Preview;