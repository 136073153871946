/**
 * 设置账户密码弹框
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './UserPwdModal.module.scss';
import { XInputPassword } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import md5 from 'js-md5';

class UserPwdModal extends React.Component {

    state = {
        visible: this.props.visible,
        oldLoginPwd: '', // 旧密码
        newLoginPwd: '', // 新密码
        ensureLoginPwd: '', // 确认密码
    }

    // 确认
    _handleOk = () => {
        const { oldLoginPwd, newLoginPwd, ensureLoginPwd } = this.state;
        if (newLoginPwd != ensureLoginPwd) {
            message.success('两次输入的新密码不一致！');
            return;
        }

        const reg = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,16}$/;
        if (!newLoginPwd.match(reg)) {
            message.success('密码长度8-16位，必须包含大写字母、小写字母、数字及特殊符号');
            return;
        }

        const data = {
            oldLoginPwd: md5(oldLoginPwd).toUpperCase(),
            newLoginPwd: md5(newLoginPwd).toUpperCase(),
        };
        http('/admin/empl/updatePassword', data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('更新成功！');
                this._handleCancel();
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, oldLoginPwd, newLoginPwd, ensureLoginPwd } = this.state;

        return (
            <Modal
                width={460}
                centered
                title='账户密码设置'
                visible={visible}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInputPassword
                        style={{ marginLeft: '28px' }}
                        inputStyle={{ width: '300px' }}
                        label='旧密码'
                        placeholder='请输入旧密码'
                        value={oldLoginPwd}
                        bindThis={this}
                        bindName='oldLoginPwd'
                        isRequired={true}
                    />
                    <XInputPassword
                        style={{ marginLeft: '28px', marginTop: '30px' }}
                        inputStyle={{ width: '300px' }}
                        label='新密码'
                        placeholder='请输入新密码'
                        value={newLoginPwd}
                        bindThis={this}
                        bindName='newLoginPwd'
                        isRequired={true}
                    />
                    <XInputPassword
                        style={{ marginTop: '30px' }}
                        inputStyle={{ width: '300px' }}
                        label='确认新密码'
                        placeholder='请输入新密码'
                        value={ensureLoginPwd}
                        bindThis={this}
                        bindName='ensureLoginPwd'
                        isRequired={true}
                    />
                </div>
            </Modal>
        );
    }
}

UserPwdModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default UserPwdModal;