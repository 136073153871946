/**
 * 同城/美居开城配置
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import TableContent from './components/TableContent';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import model from '@/reducers/businessmanage/citybeautyopen/model';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XOKButton, XCancelButton, XSelect, XDatePicker, XTabNav } from '@/components/xqxc_ui';
import OpenOrClose from './modal/OpenOrClose';
import { setSessionStorage, getSessionStorage, removeSessionStorage } from '@/assets/js/storage';

const tabList = [
    { id: 'CE', label: '同城' },
    { id: 'HOTEL', label: '美居' },
];

class CityBeautyOpen extends PureComponent {

    static propTypes = {
        history: PropTypes.object, // router history
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
        getOpenCityData: PropTypes.func,//获取房源列表
        openCityData: PropTypes.object,//房源列表
        getProvinceSele: PropTypes.func,//获取省份下拉
        provinceSele: PropTypes.array,//省份下啦
        getCitySele: PropTypes.func,//获取城市
        citySele: PropTypes.array,//城市下啦
        getStatusEnum: PropTypes.func,//获取状态枚举
        statusEnum: PropTypes.array,//状态枚举
    }

    static defaultProps = {
        openCityData: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
        provinceSele: [],
        citySele: [],
        statusEnum: []
    }

    state = {
        curTabID: 'CE',
        renderModal: false,
        modalParams: '',
        cprovinceItem: {},
        ccityItem: {},
        cstateItem: {},
        cstartDate: undefined,
        cendDate: undefined,
        cbatchKeys: [],
        hprovinceItem: {},
        hcityItem: {},
        hstateItem: {},
        hstartDate: undefined,
        hendDate: undefined,
        hbatchKeys: [],
        cpageNum: 1,
        cpageSize: 30,
        hpageNum: 1,
        hpageSize: 30,
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getProvinceSele, getStatusEnum } = this.props;
        getProvinceSele();
        keepSecondNavBreadcrumb();
        getStatusEnum();
        if (getSessionStorage('cityBeautyOpenCurrentTab')) {
            this.setState({ curTabID: getSessionStorage('cityBeautyOpenCurrentTab') });
            removeSessionStorage('cityBeautyOpenCurrentTab');
        }
    }

    componentDidUpdate(preProps, preState) {
        const { getCitySele } = this.props;
        const { curTabID, cprovinceItem, hprovinceItem } = this.state;
        if (preState.curTabID != curTabID) {
            this._searchHandle('useCache');
            if (curTabID === 'CE') {
                if (cprovinceItem.id) {
                    getCitySele({ parentId: cprovinceItem.id });
                } else {
                    getCitySele({ parentId: '1' });
                }
            } else {
                if (hprovinceItem.id) {
                    getCitySele({ parentId: hprovinceItem.id });
                } else {
                    getCitySele({ parentId: '1' });
                }
            }
        }
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const {
            cprovinceItem, ccityItem, cstateItem, cstartDate, cendDate, cpageNum, cpageSize,
            hprovinceItem, hcityItem, hstateItem, hstartDate, hendDate, hpageNum, hpageSize,
            curTabID
        } = this.state;
        this.searchCache = {
            cityOpenType: curTabID,
            provinceId: curTabID === 'CE' ? cprovinceItem.id : hprovinceItem.id,
            cityId: curTabID === 'CE' ? ccityItem.id : hcityItem.id,
            state: curTabID === 'CE' ? cstateItem.code : hstateItem.code,
            startOpenTime: curTabID === 'CE' ? cstartDate : hstartDate,
            endOpenTime: curTabID === 'CE' ? cendDate : hendDate,
            pageNum: curTabID === 'CE' ? useCache == 'useCache' ? cpageNum : autoPageNum : useCache == 'useCache' ? hpageNum : autoPageNum,
            pageSize: curTabID === 'CE' ? useCache == 'useCache' ? cpageSize : autopageSize : useCache == 'useCache' ? hpageSize : autopageSize,
        };
        this.props.getOpenCityData(this.searchCache);
        KeepAlive.saveCache(this.searchCache);
    }

    // 重置触发
    _resetHandle = () => {
        if (this.state.curTabID === 'CE') {
            this.setState({
                cprovinceItem: {}, ccityItem: {}, cstateItem: {}, cstartDate: undefined, cendDate: undefined,
            }, () => {
                this.props.getCitySele({ parentId: '1' });
                KeepAlive.saveResetFunc(this._searchHandle);
            });
        } else {
            this.setState({
                hprovinceItem: {}, hcityItem: {}, hstateItem: {}, hstartDate: undefined, hendDate: undefined
            }, () => {
                this.props.getCitySele({ parentId: '1' });
                KeepAlive.saveResetFunc(this._searchHandle);
            });
        }
    }

    //页面跳转
    _jumpPage = (title, url, params) => {
        const { addOneBreadcrumbPath, history } = this.props;
        const baseUrl = '/home/businessmanage';
        addOneBreadcrumbPath({ title, path: `${baseUrl}${url}` });
        history.push({ pathname: `${baseUrl}${url}`, query: params });
    }

    // 表格操作
    _tableOperate = (type, item) => {
        const { curTabID } = this.state;
        switch (type) {
            case 'record':
                setSessionStorage('cityBeautyOpenCurrentTab', curTabID);
                this._jumpPage('历史操作记录', `/citybeautyopen/record/${item.cityName}/${curTabID}/${item.cityId}`);
                break;
            case 'entered':
                this._jumpPage('商户列表', '/merchantlist', {
                    provinceItem: { id: item.provinceId, areaName: item.provinceName },
                    cityItem: { id: item.cityId, areaName: item.cityName },
                    state: 'APPROVED',
                    type: item.cityOpenType
                });
                break;
            case 'state':
                this.setState({
                    renderModal: true,
                    modalParams: {
                        title: item.state == '1' ? '关闭原因：' : '开启说明：',
                        cityIds: item.cityId ? [item.cityId] : [],
                        cityOpenType: curTabID,
                        url: item.state == '1' ? '/cityOpen/closeCity' : '/cityOpen/openCity',
                        isClose: item.state == '1' ? true : false
                    }
                });
                break;
            case 'batch':
                curTabID === 'CE' ?
                    this.setState({ cbatchKeys: item }) :
                    this.setState({ hbatchKeys: item });
                break;
        }
    }

    // 分页发生变化
    _paginationChange = (pageSize, pageNum) => {
        if (this.state.curTabID === 'CE') {
            this.setState({ cpageSize: pageSize, cpageNum: pageNum }, () => {
                this._searchHandle('useCache');
            });
        } else {
            this.setState({ hpageSize: pageSize, hpageNum: pageNum }, () => {
                this._searchHandle('useCache');
            });
        }
    }

    //关闭弹窗
    _closeMode = (update) => {
        this.setState({ renderModal: false, modalParams: '', cbatchKeys: [], hbatchKeys: [] }, () => {
            update && this._searchHandle('useCache');
        });
    }

    //批量操作
    _betchOperate = (type) => {
        const { cbatchKeys, hbatchKeys, curTabID } = this.state;
        if ((curTabID === 'CE' && !cbatchKeys.length) || (curTabID === 'HOTEL' && !hbatchKeys.length)) {
            message.warn('请勾选城市');
            return;
        }
        this.setState({
            renderModal: true,
            modalParams: {
                title: type == 'open' ? '开启说明：' : '关闭原因：',
                cityIds: curTabID === 'CE' ? cbatchKeys : hbatchKeys,
                cityOpenType: curTabID,
                url: type == 'open' ? '/cityOpen/openCity' : '/cityOpen/closeCity',
                isClose: type == 'open' ? false : true
            }
        });
    }

    //省份改变
    _areaChange = ({ id = '' }) => {
        this.setState({ cityItem: {} });
        this.props.getCitySele({ parentId: id });
    }

    render() {
        const { openCityData, provinceSele, citySele, statusEnum } = this.props;
        const {
            curTabID, renderModal, modalParams,
            cprovinceItem, ccityItem, cstateItem, cstartDate, cendDate, cbatchKeys,
            hprovinceItem, hcityItem, hstateItem, hstartDate, hendDate, hbatchKeys,
        } = this.state;
        return <KeepAlive id={curTabID} render={(state) => {
            if (!this.state.__aliveInit) {
                this.setState({ ...state, __aliveInit: true }, () => {
                    this._searchHandle('useCache');
                });
            }
        }}>
            <div className={styles.flexBoxContainer}>
                <XTabNav
                    renderData={tabList}
                    onChange={tab => this.setState({ curTabID: tab })}
                    activedID={curTabID}
                />
                <SearchBox style={{ marginTop: '10px' }}>
                    <SearchItem>
                        <XSelect
                            label='省份'
                            placeholder='请选择省份'
                            renderData={provinceSele}
                            bindName={curTabID === 'CE' ? 'cprovinceItem' : 'hprovinceItem'}
                            bindThis={this}
                            dataIndex='areaName'
                            keyIndex='id'
                            onChange={this._areaChange}
                            value={curTabID === 'CE' ? cprovinceItem.areaName : hprovinceItem.areaName}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='城市'
                            placeholder='请选择城市'
                            renderData={citySele}
                            dataIndex='areaName'
                            keyIndex='id'
                            bindName={curTabID === 'CE' ? 'ccityItem' : 'hcityItem'}
                            bindThis={this}
                            value={curTabID === 'CE' ? ccityItem.areaName : hcityItem.areaName}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='状态'
                            placeholder='请选择状态'
                            renderData={statusEnum}
                            dataIndex='value'
                            keyIndex='code'
                            bindName={curTabID === 'CE' ? 'cstateItem' : 'hstateItem'}
                            bindThis={this}
                            value={curTabID === 'CE' ? cstateItem.value : hstateItem.value}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XDatePicker
                            label='开通时间'
                            placeholder='请选择开始日期'
                            bindThis={this}
                            bindName={curTabID === 'CE' ? 'cstartDate' : 'hstartDate'}
                            value={curTabID === 'CE' ? cstartDate : hstartDate}
                        />
                        <XDatePicker
                            style={{ marginRight: '20px' }}
                            label=''
                            placeholder='请选择结束日期'
                            bindThis={this}
                            bindName={curTabID === 'CE' ? 'cendDate' : 'hendDate'}
                            value={curTabID === 'CE' ? cendDate : hendDate}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </SearchItem>
                </SearchBox>
                <div className={styles.operateBox}>
                    <XOKButton
                        style={{ width: '90px', marginRight: '20px' }}
                        label='批量开通'
                        onClick={() => this._betchOperate('open')}
                    />
                    <XOKButton
                        style={{ width: '90px' }}
                        label='批量关闭'
                        onClick={() => this._betchOperate('close')}
                    />
                </div>
                <TableContent
                    paginationChange={this._paginationChange}
                    renderData={openCityData}
                    tableAction={this._tableOperate}
                    batchKeys={curTabID === 'CE' ? cbatchKeys : hbatchKeys}
                />
                {
                    renderModal &&
                    <OpenOrClose data={modalParams} closeMode={this._closeMode} />
                }
            </div>
        </KeepAlive>;
    }
}
export default connect(state => ({
    openCityData: state.citybeautyopen.openCityData,
    provinceSele: state.citybeautyopen.provinceSele,
    citySele: state.citybeautyopen.citySele,
    statusEnum: state.citybeautyopen.statusEnum.map(item => {
        if (item.code == '1') {
            return { ...item, value: '已开通' };
        }
        return { ...item, value: '未开通' };
    }),
}), {
    keepSecondNavBreadcrumb,
    addOneBreadcrumbPath,
    ...model.actions
})(CityBeautyOpen);