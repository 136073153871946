/**
 * 店铺管理  Saga集合
 */
import {
    storeListTableSource,
    storeselectData,
    bannedinfomation,
    storeDetails,
    storeTakelog,
    storeRecommendation,
} from './list/saga';
import hotelShop from './hotelShop/model';
import cityOpen from './cityOpen/model';

export default [
    storeListTableSource,//店铺列表
    storeselectData,//封禁状态
    bannedinfomation,//封禁信息
    storeDetails,//店铺详情
    storeTakelog,//店铺封禁日志
    storeRecommendation,//店铺推荐列表
    ...Object.values(hotelShop.sagas), //酒店店铺
    ...Object.values(cityOpen.sagas), //开城设置
];

