import * as T from './actiontypes';
const defaultState = {
    tableSource: [],// 道具分类列表数据源
    SelectData: [],// 道具分类状态下拉选项
    paginations: {}, //道具分类分页对象

    listTableSource: [],// 道具列表数据源
    listPaginations: {}, // 道具列表分页对象
};



/**
 * 存储'秀场管理 -道具管理-'数据源
 * @param {*} state 
 * @param {*} action 
 */
export const giftDate = (state = defaultState, action) => {
    let newTableSource, newSelectData, newSelectQuery, newPaginations;
    switch (action.type) {
        case T.SET_PROPSMANAGE_DATA: //获取'秀场管理 -道具管理- 道具分类
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };

        case T.SET_GIFTSTATE_DATA: //获取'秀场管理 -道具管理- 道具分类-分类状态下拉
            newSelectData = action.payload.result;
            return { ...state, SelectData: newSelectData };

        case T.SET_PROPSGLIST_DATA: //存储'道具列表数据源'数据源
            newSelectQuery = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, listTableSource: newSelectQuery, listPaginations: newPaginations };
        default:
            return state;
    }
};


