import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const getFinanceArgDetail = function* () {
    yield takeEvery(T.GET_FINANCEARG_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/plat/financial/query', action.payload, 'POST');
            yield put({ type: T.SET_FINANCEARG_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getFinanceInfoList = function* () {
    yield takeEvery(T.GET_FINANCEINFO_LIST, function* requestData(action) {
        try {
            let result = yield http('/plat/financeAccount/list', action.payload, 'POST');
            yield put({ type: T.SET_FINANCEINFO_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
