import * as T from './actiontypes';
// 商城设置
/** 
 * 商城参数-数据源(saga)
 * @param 暂无
 */
export const getshowpareme = () => ({
    type: T.GET_SHOWPAREME_DATA,
});

/** 
 * 商品列表规则参数(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} ruleName 规则名称	
  * @param {string} startTimeStart 生效时间的开始时间
 * @param {string} startTimeEnd 生效时间的结束时间
 * @param {string} categoryId 分类id
 */

export const getmallRecommendList = (datalaod) => ({
    type: T.GET_MALLRECOMMENDLIST_DATA,
    payload: {
        ...datalaod,
    }
});

/**
 * 商城设置推荐规则下拉框
 * @param 暂无
 */
export const getmallrulesDropDown = () => ({
    type: T.GET_MALLRULES_DATA,
});

/**
 * 商城设置商品联级分类'数据源(saga)
 * @param {string} categoryGrade  树形等级(默认 1 )  分为三级 firstCategory|secondCategory|thirdCategory
* @param {number} parentId  商户分类上级ID(最上级ID 默认为 0)
 */
export const getcategoryLinkage = (datalaod) => ({
    type: T.GET_CATEGORYLINKAGE_DATA,
    payload: {
        ...datalaod,
    }
});
//task-3097
export const getSalaAfterRuleList = (payload = {}) => ({
    type: T.GET_SALAAFTER_RULELIST,
    payload
});
export const getSalaAfterTypeEnum = (payload = {}) => ({
    type: T.GET_SALAAFTER_TYPEENUM,
    payload
});
