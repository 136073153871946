import { createModel, } from '@/store/tools';

const model = {
    namespace: 'offlineList',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getOfflineList': T('getOfflineList'),// 线下合同列表
        },
        actions: {
            'getOfflineList': A('getOfflineList'), // 线下合同列表
        },
        sagas: {
            'getOfflineList': S('getOfflineList', '/contract/list/offline'),
        },
        reducers: {
            'lineList': R('getOfflineList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
        }
    })
};
export default createModel(model);