/**
 * 审核管理 - 主播封面异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Audit from './actiontypes';

export const getAnchorCoverList = function* (){
    yield takeEvery(Audit.GET_ANCHORCOVER_LIST, function* requestData(action){
        try{
            let result = yield http('/frontcover/listCoverApproval', action.payload,'POST'); // action.payload为传入的参数对象
            console.log(result.result);
            yield put({type:Audit.SET_ANCHORCOVER_LIST, payload: result.result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

export const getAnchorCoverSelect = function* (){
    yield takeEvery(Audit.GET_ANCHORCOVER_SELECT, function* requestData(action){
        try{
            let result = yield http('/enum/ApprovalState', action.payload,'POST'); // action.payload为传入的参数对象
            yield put({type:Audit.SET_ANCHORCOVER_SELECT, payload: result.result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};