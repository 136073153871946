import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Switch } from 'antd';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { priceFenToYuanCell, tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';

const Mode = {
    INFO: 'INFO',
    UNABLE: 'UNABLE',
    HOMESTATUS: 'HOMESTATUS',//首页推荐
    CHANNEL: 'CHANNEL',//频道推荐
    DELETE: 'DELETE',//删除
};

class CommunityListTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    _columns = () => [
        {
            title: '手机号码',
            dataIndex: 'tel',
            key: 'tel',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '标题',
            dataIndex: 'feedTitle',
            key: 'feedTitle',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '魅力值收入',
            dataIndex: 'starlightNumber',
            key: 'starlightNumber',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '销售商品分红',
            dataIndex: 'saleBonus',
            key: 'saleBonus',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '点赞数',
            dataIndex: 'praiseNumber',
            key: 'praiseNumber',
            sorter: true,
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所属分类',
            dataIndex: 'feedClassName',
            key: 'feedClassName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '发布时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '首页推荐',
            dataIndex: 'isHomeReco',
            key: 'isHomeReco',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => this._switchCell(text, Mode.HOMESTATUS, item)
        },
        {
            title: '频道推荐',
            dataIndex: 'isChannelReco',
            key: 'isChannelReco',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => this._switchCell(text, Mode.CHANNEL, item)
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            fixed: 'right',
            render: (id) => this._tableAction(id)
        }
    ];

    _tableAction = (id) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, Mode.INFO)}>查看详情</div>
                <div className={styles.item} onClick={() => tableAction(id, Mode.UNABLE)}>封禁</div>
                <div className={styles.item} onClick={() => tableAction(id, Mode.DELETE)}>删除</div>
            </div>
        );
    };

    // 表格项内容为开关
    _switchCell = (sw, status, item) => {
        const { tableAction } = this.props;
        let checked = true;
        if (sw == 0) {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={checked}
                    onChange={(value) => {
                        tableAction(item.id, status, value);
                    }}
                />
            </div>
        );
    };

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data;
    }

    _sortChange = (pagination, filters, sorter) => {
        console.log(sorter);
        this.props.tableAction(null, 'sortNo', sorter);
    }

    render() {
        const { renderData, pagiNation, _paginationChange, scrollY } = this.props;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    onChange={this._sortChange}
                    scroll={{ x: 'max-content', y: scrollY }}
                />
                <XPagination
                    resultTotal={pagiNation.resultTotal}
                    pageSize={pagiNation.pageSize}
                    pageNum={pagiNation.pageNum}
                    onChange={_paginationChange}
                />
            </div>
        );
    }
}

CommunityListTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    pagiNation: PropTypes.object, // 分页数据
    _paginationChange: PropTypes.func,// 页面发生变化
    scrollY: PropTypes.string
};

export default XTableHOC(CommunityListTable);