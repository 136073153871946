import React from 'react';
import styles from './AdvertTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table, Switch } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


class AdvertTable extends React.Component {

    _takeColumns = () => ([
        {
            title: '策略名称',
            dataIndex: 'strategyName',
            key: 'strategyName',
            width: '15%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '投放端口',
            dataIndex: 'useScene.value',
            key: 'useScene.value',
            width: '15%',
            align: 'center',
            render: (text) => (this._renderTextCell(text ? text : ''))
        },
        {
            title: '广告位',
            dataIndex: 'adSpaceName',
            key: 'adSpaceName',
            width: '15%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '策略优先级',
            dataIndex: 'priority',
            key: 'priority',
            width: '15%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '投放时间',
            dataIndex: 'startTime',
            key: 'startTime',
            width: '15%',
            align: 'center',
            render: (text, item) => (<span>
                {text}<br /> {item.endTime}
            </span>)
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: '10%',
            align: 'center',
            render: (text, item) => (this._switchCell(text, item, 'recommend'))
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            width: '15%',
            render: (text, item) => this._renderActionCell(item.id)
        }
    ]);
    //表格项内容为开关
    _switchCell = (key, item, type) => {
        // 1启用，0禁用
        const { actionHandle } = this.props;
        let checked = true;
        if (key == 0) {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={checked}
                    key={item.id}
                    onChange={(value) => {
                        var state;
                        if (value) {
                            state = 1;
                        } else {
                            state = 0;
                        }
                        if (type == 'recommend') {
                            actionHandle({ state, id: item.id });
                        }
                    }}
                />
            </div>
        );
    };

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    _renderActionCell = (key) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'info')}>详情</div>
                <div className={styles.item} onClick={() => tableAction(key, 'del')}>删除</div>
            </div>
        );
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    getAdvertStrategStartAndStop: PropTypes.func,
    actionHandle: PropTypes.func,
};
export default connect(null, null)(AdvertTable);