/**
 *  【日结账单】入口页
 * */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import Main from './pages/main';
import { XTabNav } from '@/components/xqxc_ui';
import Unionpay from '../dailystatement2';
import Lianlina from './lianlina';
import Huifu from './huifu';
import Advancetable from './advancetable';
import { getSessionStorage, removeSessionStorage } from '@/assets/js/storage';

const tablist = [
    { id: '1', label: '日交易流水' },
    { id: '2', label: '银联日结对账单' },
    { id: '3', label: '连连日结对账单' },
    { id: '5', label: '预付款对账单' },
    { id: '4', label: '汇付日结对账单' },
];

const storehouselist = ({ history }) => {

    const [curTabID, setCurTabID] = useState('1');

    useState(() => {
        if (getSessionStorage('cwglshrjyjjsdzdcurtabid')) {
            setCurTabID(getSessionStorage('cwglshrjyjjsdzdcurtabid'));
            removeSessionStorage('cwglshrjyjjsdzdcurtabid');
        }
    }, []);

    const renderPage = () => {
        switch (curTabID) {
            case '1':
                return <Main history={history} />;
            case '2':
                return <Unionpay history={history} />;
            case '3':
                return <Lianlina history={history} />;
            case '4':
                return <Huifu history={history} />;
            case '5':
                return <Advancetable history={history} />;
            default:
                return <Main history={history} />;
        }
    };

    return <div className={styles.flexBoxContainer}>
        <XTabNav renderData={tablist} onChange={tab => setCurTabID(tab.toString())} activedID={curTabID} />
        <div style={{ margin: '5px 0' }}></div>
        {renderPage()}
    </div>;
};

storehouselist.propTypes = {
    history: PropTypes.object
};

export default storehouselist;