/**
 * 封禁
 */
import React from 'react';
import { Modal, Radio, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './ForbidModal.module.scss';
import { XTextArea } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;
class CreateModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        bannedType: 'CLOSE', // 封禁类别
        bannedReason: '', // 封禁原因
    };

    _request = (url, data) => {
        http(url, data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('封禁成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _handleOk = () => {
        const { id, type } = this.props.data;
        const { bannedType, bannedReason } = this.state;
        if (type == 'DEPT') {
            this._request('/admin/dept/updateDeptState', { id, bannedType, bannedReason });
        } else {
            this._request('/admin/dept/updateCompanyDisable', { id, bannedType, bannedReason });
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // 封禁原因单选
    _reasonRadioChangeHandle = (e) => {
        this.setState({ bannedType: e.target.value });
    }

    render() {
        const { visible, confirmLoading, bannedType, bannedReason } = this.state;
        return (
            <Modal
                width={600}
                centered
                title='封禁'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.reason}>
                        <div>封禁理由<span style={{ color: 'red' }}>*</span></div>
                        <RadioGroup style={{ marginLeft: '8px' }} onChange={this._reasonRadioChangeHandle} value={bannedType}>
                            <Radio key={'CLOSE'} value={'CLOSE'}>部门关闭</Radio>
                            <Radio key={'VIOLATIONS'} value={'VIOLATIONS'}>部门违规</Radio>
                            <Radio key={'OTHERS'} value={'OTHERS'}>其他</Radio>
                        </RadioGroup>
                    </div>
                    <XTextArea
                        style={{ width: '385px', marginTop: '10px', marginLeft: '32px' }}
                        inputStyle={{ width: '335px', resize: 'none' }}
                        label='备注'
                        placeholder='请输入备注'
                        value={bannedReason}
                        bindThis={this}
                        bindName='bannedReason'
                    />
                </div>
            </Modal>
        );
    }
}

CreateModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default CreateModal;
