import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { message } from 'antd';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import TableData from './components/TableData';
import CreateBrand from '../../modal/CreateBrand';
import EditBrand from '../../modal/EditBrand';
import { getProductBrand } from '@/reducers/productmanage/brand/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class ProductBrand extends Component {

    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: '', // 当前需要打开的弹窗的入参
        brandName: '',
    }

    // 查询
    _searchHandle = (useCache) => {
        const { brandName, pageNum, pageSize } = this.state;
        this.searchCache = {
            brandName,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getProductBrand(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ brandName: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    //创建品牌
    _openCreateModal = () => {
        this.setState({ currentOpenMode: 'CREATE_BRAND' });
    }

    // 表格操作
    _tableAction = (id, type) => {
        switch (type) {
            case 'edit':
                this.setState({ currentOpenMode: 'EDIT_BRAND', openModeParam: id });
                break;
            case 'delete':
                showConfirm('您确定要删除该品牌？', '', () => {
                    http('/goods/brand/delBrand', { brandId: id }, 'POST')
                        .then(() => {
                            message.success('删除成功。');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { currentOpenMode, openModeParam } = this.state;
        switch (currentOpenMode) {
            case 'CREATE_BRAND':
                return <CreateBrand title='创建商品品牌' visible={true} closeMode={this._closeMode} />;
            case 'EDIT_BRAND':
                return <EditBrand title='编辑商品品牌' visible={true} openModeParam={openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }
    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', openModeParam: '' }, () => {
            if (update) this._searchHandle('useCache');
        });

    }
    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }
    render() {
        const { brandName } = this.state;
        const { productBrand } = this.props;
        return (
            <KeepAlive id="main" render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='品牌名称'
                                placeholder='请输入品牌名称'
                                bindThis={this}
                                bindName='brandName'
                                value={brandName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '100px' }} label='创建品牌' onClick={this._openCreateModal} />
                        {/* <XOKButton style={{ marginLeft: '20px', width: '90px' }} label='导出Excel' /> */}
                    </div>
                    <TableData paginationChange={this._paginationChange} renderData={productBrand} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

ProductBrand.propTypes = {
    getProductBrand: PropTypes.func,
    productBrand: PropTypes.object
};

const mapStateToProps = (state) => ({
    productBrand: state.productBrand.productBrand
});

export default connect(mapStateToProps, { getProductBrand })(ProductBrand);