/**
 *  系统管理 - 系统设置
 * */ 
import React from 'react';

class SysSet extends React.Component {

    render(){
        return(
            <div>
                系统设置
            </div>
        );
    }
}

export default SysSet;