import React, { Component } from 'react';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import KeepAlive from '@/routes/KeepAlive';
import TableContent from './components/TableContent';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/storehousefinancialmanage/thirdreconciliation/model';
import { XOKButton, XCancelButton, XInput, XDatePicker, XSelect } from '@/components/xqxc_ui';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { SearchBox, SearchItem } from '@/components/Layout';
import { Alert } from 'antd';
import http from '@/assets/api/http';
import { regFenToYuan } from '@/util/money';

class TabContent extends Component {

    state = {
        goodsNo: '', // 货号
        orderId: '', // 订单号
        reBeginTm: undefined, // 账单结算日创建时间
        reEndTm: undefined, // 账单结算日结束时间
        gmtBeginTm: undefined, // 创建时间
        gmtEndTm: undefined, // 结束时间
        source: {},

        serviceFeeMoney: 0,
        orderMoney: 0
    }

    componentDidMount() {
        this.props.getSourceList();
        this._statistics('useCache');
    }

    _statistics = (useCache) => {
        const { orderId, reBeginTm, reEndTm, gmtBeginTm, gmtEndTm, source, pageNum, pageSize } = this.state;
        const query = {
            id: orderId,
            createStartDate: gmtBeginTm,
            createEndDate: gmtEndTm,
            startDate: reBeginTm,
            endDate: reEndTm,
            channelSource: source.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        http('/shengxin/order/statistics', query).then(res => {
            if (res.code === 200) {
                this.setState({
                    serviceFeeMoney: regFenToYuan(res.result.allotAmtFen),
                    orderMoney: regFenToYuan(res.result.paymentOrderAmount)
                });
            }
        });
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { orderId, reBeginTm, reEndTm, gmtBeginTm, gmtEndTm, source, pageNum, pageSize } = this.state;
        const query = {
            id: orderId,
            createStartDate: gmtBeginTm,
            createEndDate: gmtEndTm,
            startDate: reBeginTm,
            endDate: reEndTm,
            channelSource: source.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getTableData(query);
        this._statistics(useCache);
        KeepAlive.saveCache(query);
    }
    tableAction = (id, type, record) => {
        const { history } = this.props;
        switch (type) {
            // 查看详情
            case 'detail':
                addOneBreadcrumbPath({
                    title: '订单详情',
                    path: {
                        pathname: `/home/storehousefinancialmanage/thirdreconciliation/orderdetail/${record.id}_${record.channelSource.code}_${record.settleDay}`,
                    }
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/thirdreconciliation/orderdetail/${record.id}_${record.channelSource.code}_${record.settleDay}`
                });
                break;
        }
    }

    // 关闭查看物流弹框
    _closeMode = () => {
        this.setState({ renderMode: '' });
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ orderId: '', gmtBeginTm: undefined, gmtEndTm: undefined, reBeginTm: undefined, reEndTm: undefined, source: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { orderId, reBeginTm, reEndTm, gmtBeginTm, gmtEndTm, source, serviceFeeMoney, orderMoney } = this.state;
        const { tableData, sourceList = [] } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='账单编号'
                                placeholder='请输入'
                                value={orderId}
                                bindThis={this}
                                bindName='orderId'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='账单结算日'
                                value={reBeginTm}
                                bindThis={this}
                                bindName='reBeginTm'
                                isFormat
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                label=''
                                value={reEndTm}
                                bindThis={this}
                                bindName='reEndTm'
                                isFormat
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='创建时间'
                                value={gmtBeginTm}
                                bindThis={this}
                                bindName='gmtBeginTm'
                                isFormat
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                label=''
                                value={gmtEndTm}
                                bindThis={this}
                                bindName='gmtEndTm'
                                isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='来源渠道'
                                placeholder='请选择'
                                renderData={sourceList}
                                dataIndex='value'
                                keyIndex='code'
                                value={source.value}
                                bindThis={this}
                                bindName='source'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div style={{ marginTop: 10 }}>
                        <Alert message={`服务费金额：${serviceFeeMoney}（元） 订单金额：${orderMoney}（元）`} type='info' banner={true} />
                    </div>
                    <TableContent
                        renderData={tableData}
                        paginationChange={this._paginationChange}
                        tableAction={this.tableAction}
                    />
                </div>
            </KeepAlive>
        );
    }
}
TabContent.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func,
    getTableData: PropTypes.func,
    tableData: PropTypes.object,

    getSourceList: PropTypes.func,
    sourceList: PropTypes.array,
};
export default connect(state => ({ ...state.thirdreconciliation }), { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(TabContent);