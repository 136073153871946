/**
 * 设为自控
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './SetcontrolModal.module.scss';
import http from '@/assets/api/http';

class SetcontrolModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
    }

    componentDidMount() { }

    // 确认
    _handleOk = () => {
        const { data } = this.props;
        this.setState({ confirmLoading: true },()=>{
            http(`/user/account/setIsAutoBind/${data}`,{}, 'POST').then((response={}) => {
                if (response.status == 200) {
                    message.success('修改成功');
                    this.setState({
                        visible: false,
                    });
                    this.props.closeMode(true);
                }
            }).catch((e = {}) => {
                message.error(e.message);
            }).finally(() => {
                this.setState({ confirmLoading: false });
            });
        });
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, } = this.state;

        return (
            <Modal
                width={470}
                centered
                title='设为自控'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.lable}>
                        主播被标记为“自控”对象后，在直播时一旦被识别为涉嫌直播违规内容则系统触发自动封禁处理，且为永久封禁，需手工解除该主播封禁。您确定设为“自控”对象么？
                    </div>
                </div>
            </Modal>
        );
    }
}

SetcontrolModal.propTypes = {
    data:  PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // 外部传递参数
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default SetcontrolModal;