import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell, cellWidth } from '@/components/TableCell';

const TableContent = ({ renderData, paginationChange, tableAction }) => {

    const columns = () => ([
        {
            title: '订单编号',
            dataIndex: 'paymentOrderId',
            key: 'paymentOrderId',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单交易号',
            dataIndex: 'tradeNo',
            key: 'tradeNo',
            align: 'center',
            width: cellWidth.big,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '用户昵称',
            dataIndex: 'nickName',
            key: 'nickName',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            key: 'mobile',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '实际订单金额',
            dataIndex: 'paymentOrderAmount',
            key: 'paymentOrderAmount',
            align: 'center',
            width: cellWidth.normal,
            render: priceFenToYuanCell
        },
        {
            title: '订单类型',
            dataIndex: 'orderType.value',
            key: 'orderType.value',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '退款状态',
            dataIndex: 'refundStatus.value',
            key: 'refundStatus.value',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '分账状态',
            dataIndex: 'status.value',
            key: 'status.value',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '服务费（元）',
            dataIndex: 'allotAmtFen',
            key: 'allotAmtFen',
            align: 'center',
            width: cellWidth.normal,
            render: priceFenToYuanCell
        },
        {
            title: '来源渠道',
            dataIndex: 'channelSource.value',
            key: 'channelSource.value',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            width: cellWidth.time,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'key',
            align: 'center',
            width: cellWidth.normal,
            render: (text, item) => _renderActionCell(item)
        }
    ]);
    const _renderActionCell = (item) => {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(item.tradeNo, 'detail', item)}>查看</div>
            </div>
        );
    };
    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.id}
                columns={columns()}
                dataSource={renderData.dataList}
                pagination={false}
                scroll={{ x: '100%', y: true }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    tableAction: PropTypes.func
};

export default TableContent;