/**
 * 消息推送管理
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as MESSAGE from './actionTypes';

export const getMessageMoveList = function* () {
    yield takeEvery(MESSAGE.GET_MESSAGEMOVE_LIST, function* requestData(action) {
        try {
            let result = yield http('/user/messageinfo/listMessageInfo', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: MESSAGE.SET_MESSAGEMOVE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
export const getMessageMoveDetail = function* () {
    yield takeEvery(MESSAGE.GET_MESSAGEMOVE_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/user/messageinfo/getMessageInfo', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: MESSAGE.SET_MESSAGEMOVE_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 消息状态
export const getMessageStatus = function* () {
    yield takeEvery(MESSAGE.GET_MESSAGESTATUS, function* requestData(action) {
        try {
            let result = yield http('/enum/MessageStateEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: MESSAGE.SET_MESSAGESTATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 消息推送方式
export const getMessageWays = function* () {
    yield takeEvery(MESSAGE.GET_MESSAGEWAYS, function* requestData(action) {
        try {
            let result = yield http('/enum/MessagePushModeEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: MESSAGE.SET_MESSAGEWAYS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 跳转方式
export const getJumpWays = function* () {
    yield takeEvery(MESSAGE.GET_JUMPWAYS, function* requestData(action) {
        try {
            let result = yield http('/enum/MessageJumpTypeEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: MESSAGE.SET_JUMPWAYS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 推送人群
export const getPushPeople = function* () {
    yield takeEvery(MESSAGE.GET_PUSHPEOPLE, function* requestData(action) {
        try {
            let result = yield http('/enum/MessageSystemTypeEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            try {
                // const MessageTypeLimit = ['USER_APP', 'SERVICE_APP', 'HOTEL_MERCHANT']; // 暂时隐藏酒店商户类型
                const MessageTypeLimit = ['USER_APP', 'SERVICE_APP'];
                result.result = result.result.filter(v => MessageTypeLimit.indexOf(v.code) > -1);
            } catch{ result = { result: [] }; }
            yield put({ type: MESSAGE.SET_PUSHPEOPLE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
