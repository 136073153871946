import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table, Switch } from 'antd';
import { XPagination, XMediaFn, } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class TableContent extends Component {
    state = {
    };

    _columns = () => [
        {
            title: '分类ID',
            dataIndex: 'id',
            key: 'merchantKey',
            align: 'center',
            width: '15%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '分类名称',
            dataIndex: 'categoryName',
            key: 'categoryName',
            align: 'center',
            width: '16%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '分类图标',
            dataIndex: 'imgUrl',
            key: 'imgUrl',
            align: 'center',
            width: '15%',
            render: (text, item) => (this._ImgCell(text, item))
        },
        {
            title: '推荐',
            dataIndex: 'isReco',
            key: 'isReco',
            align: 'center',
            width: '12%',
            render: (text, item) => (this._switchCell(text, item, 'recommend'))
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            width: '12%',
            render: (text, item) => (this._switchCell(text, item, 'condition'))
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: '15%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '15%',
            render: (id, record) => this._tableAction(id, record)
        },
    ];

    // 表格项内容为图片
    _ImgCell = (url, item) => {
        return (
            <div style={{ display: 'inline-flex' }}>
                <XMediaFn
                    style={{ width: '50px', height: '50px', marginRight: '0' }}
                    dataType='image'
                    dataSource={url}
                    key={item.id}
                />
            </div>
        );
    };

    //表格项内容为开关
    _switchCell = (key, item, type) => {
        let checked = true;
        if (key == 0) {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={checked}
                    onChange={(value) => {
                        if (type == 'recommend') {
                            this._actionHandle(item, 'recommend', value);
                        } else {
                            this._actionHandle(item, 'condition', value);
                        }
                    }}
                />
            </div>
        );
    };

    // 表格操作
    _tableAction = (key, record) => {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => this._actionHandle(key, 'edit', record)}>编辑</div>
                <div className={styles.item} onClick={() => this._actionHandle(key, 'delete')}>删除</div>
            </div>
        );
    };

    // 操作表格
    _actionHandle = (id, type, value) => {
        this.props.tableAction(id, type, value);
    }

    //处理表格数据
    _processingData = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data && data.map((item) => {
            let key = 0;
            item.merchantKey = key++;//操作key
            return item;
        });
    }

    render() {
        const { renderData, paginations } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._columns()}
                    pagination={false}
                    dataSource={this._processingData(renderData)}
                    scroll={{ y: true }} />
                <XPagination
                    resultTotal={paginations.resultTotal}
                    pageSize={paginations.pageSize}
                    pageNum={paginations.pageNum}
                    onChange={this.props.paginationChange} />
            </div>
        );
    }
}


TableContent.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.array,
    paginations: PropTypes.object,
};



export default TableContent;