/**
 * 星球商学院 Saga集合
 */
import houseList from './houselist/model';
import houseClassify from './houseclassify/model';

export default [
    ...Object.values(houseList.sagas),
    ...Object.values(houseClassify.sagas),
];
