/**
 * 店铺列表 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';
/**
 * 获取'店铺列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {sting} shopName  店铺名称   
 * @param {sting} shopState  封禁状态
 * @param {sting} isReco 店铺推荐
 * @param {number} merchantName 归属商户 
 */

export const storeListTableSource = function* () {
    yield takeEvery(T.GET_STORELIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/shop/recommList/page', action.payload, 'POST');
            yield put({ type: T.SET_STORELIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 店铺列表封禁状态 Saga 
 */
export const storeselectData = function* () {
    yield takeEvery(T.GET_STOSELECT_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象 
        try {
            let result, storeType;
            if (action.payload.type == 'banned') {
                result = yield http('/shop/shopBannedType', action.payload, 'POST');
                yield put({ type: T.SET_RADUIS_DATA, payload: result }); // 封禁----封禁理由 单选框
            } else if (action.payload.type == 'detail') {
                storeType = yield http('/enum/MerchantTypeEnum', action.payload, 'POST');
                yield put({ type: T.SET_DETAILTYPE_DATA, payload: storeType }); // 详情----店铺类型
            } else {
                result = yield http('/enum/StateEnum', action.payload, 'POST');
                yield put({ type: T.SET_STOSELECT_DATA, payload: result });//
            }
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 封禁信息 Saga
 */
export const bannedinfomation = function* () {
    yield takeEvery(T.GET_INFOMATION_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象 
        try {
            let result = yield http('/shop/shopBannedInfo', action.payload, 'POST');
            yield put({ type: T.SET_INFOMATION_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 店铺详情 Saga
 */
export const storeDetails = function* () {
    yield takeEvery(T.GET_STOREDETAILS_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象 
        try {
            let result = yield http('/shop/getShopDetails', action.payload, 'POST');
            yield put({ type: T.SET_STOREDETAILS_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 *店铺封禁日志 Saga
 */
export const storeTakelog = function* () {
    yield takeEvery(T.GET_STORETAKELOG_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象 
        try {
            let result = yield http('/shop/shopBannedInfoList', action.payload, 'POST');
            yield put({ type: T.SET_STORETAKELOG_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 *店铺推荐列表 Saga
 */
export const storeRecommendation = function* () {
    yield takeEvery(T.GET_RECOMMENDATION_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象 
        try {
            let result = yield http('/shop/recommendSortingList', action.payload, 'POST');
            yield put({ type: T.SET_RECOMMENDATION_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


