import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Switch, Icon, Input } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

const TableContent = ({ renderData, tableAction, paginationChange }) => {

    const _columns = () => {
        return [
            {
                title: '分类ID',
                dataIndex: 'id',
                key: 'id',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '分类名称',
                dataIndex: 'className',
                key: 'className ',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '分类图标',
                dataIndex: 'img',
                key: 'img',
                align: 'center',
                width: '10%',
                render: ImgCell
            },
            {
                title: '排序',
                dataIndex: 'sortNo',
                key: 'sortNo',
                width: '10%',
                align: 'center',
                render: (text, item) => (_sortCell(text, item))
            },
            {
                title: '状态',
                dataIndex: 'state',
                key: 'state',
                width: '10%',
                align: 'center',
                render: (text, item) => (_switchCell(text, item))
            },
            {
                title: '操作',
                dataIndex: 'key',
                key: 'key',
                align: 'center',
                width: '10%',
                render: (text, item) => _tableAction(item.id, item)
            }
        ];
    };

    const _tableAction = (key, item) => {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'edit', item)}>编辑</div>
                <div className={styles.item} onClick={() => tableAction(key, 'delete')}>删除</div>
            </div>
        );
    };

    // 表格项内容为可编辑
    const _sortCell = (value, item) => {
        const label = React.createRef();
        const box = React.createRef();
        const input = React.createRef();
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.sort_box}>
                    <div className={styles.label} ref={label}>{value}</div>
                    <div className={styles.input} ref={box}>
                        <Input
                            key={item.c1}
                            style={{ width: '50px' }}
                            ref={input}
                            defaultValue={value}
                            onBlur={(e) => {
                                box.current.style.display = 'none';
                                label.current.style.display = 'block';
                                if (value != e.target.value) {
                                    tableAction(item.id, 'sort', { sortNo: e.target.value, classTypeCode: item.classTypeCode });
                                }
                            }}
                        />
                    </div>
                    <div
                        className={styles.icon}
                        onClick={() => {
                            label.current.style.display = 'none';
                            box.current.style.display = 'block';
                            input.current.focus();
                        }}
                    >
                        <Icon type="edit" style={{ fontSize: '18px', color: '#1078ff' }} />
                    </div>
                </div>
            </div>
        );
    };

    // 表格项内容为开关
    const _switchCell = (sw, item) => {
        let checked = true;
        if (sw == 0) {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={checked}
                    onChange={(value) => {
                        tableAction(item.id, 'switch', { state: value ? '1' : '0' });
                    }}
                />
            </div>
        );
    };

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                columns={_columns()}
                dataSource={renderData.dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

export default TableContent;