import { combineReducers } from 'redux';
import { invoiceList, payManageTableSource, payManageDetail, payChannelEnum } from './reducer';
import merchantModel from './merchantModel';

export default combineReducers({
	invoiceList,
	payManageTableSource,
	payManageDetail,
	payChannelEnum,
	...merchantModel.reducers,
});