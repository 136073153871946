import * as T from './actiontypes';

/**
 * 人气值攻略分类 列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} className 攻略名称
 * @param {number} state 状态
 */
export const getclassificationList = (dataload) => ({
    type: T.GET_CLASSIFICATIONLIST_DATA,
    payload: {
        ...dataload,
    }
});

export const cleanClassificationList = () => ({
    type: T.CLEAN_CLASSIFICATIONLIST_DATA
});

/**
 * 人气值攻略分类状态'数据源(saga)
 *  @param 暂无
 */
export const getclassificationState = () => ({
    type: T.GET_CLASSIFICATIONSTATE_DATA,
});


/**
 *人气值攻略列表 列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} title 攻略标题名称
 * @param {number} strategyClassId 攻略分类id 
 * @param {number} useScene 未定
 */
export const getstrategyList = (dataload) => ({
    type: T.GET_STRATEGYLIST_DATA,
    payload: {
        ...dataload,
    }
});

/**
 * 人气值攻略列表新建应用端口'数据源(saga)
 *  @param 暂无
 */
export const getstrategyAppState = () => ({
    type: T.GET_STRATEGYAPPLIST_DATA,
});

/**
 * 人气值攻略列表详情'数据源(saga)
 *  @param id 对应的id
 */
export const getstrategyDetail = (id) => ({
    type: T.GET_STRATEGYDETAIL_DATA,
    payload: {
        id,
    }
});

/**
 * 人气值攻略类型枚举
 */
export const getstrategyType = (value = {}) => ({
    type: T.GET_STRATEGY_TYPE,
    payload: value
});



