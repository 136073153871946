import * as MESSAGE from './actionTypes';

//获取消息推送列表
export const getMessageMoveList = (param) => ({
    type: MESSAGE.GET_MESSAGEMOVE_LIST,
    payload: param
});
//获取消息推送详情
export const getMessageMoveDetail = (param) => ({
    type: MESSAGE.GET_MESSAGEMOVE_DETAIL,
    payload: param
});
//获取消息状态
export const getMessageStatus = (param) => ({
    type: MESSAGE.GET_MESSAGESTATUS,
    payload: param
});
//获取消息推送方式
export const getMessageWays = (param) => ({
    type: MESSAGE.GET_MESSAGEWAYS,
    payload: param
});
// 跳转类型
export const getJumpWays = (param) => ({
    type: MESSAGE.GET_JUMPWAYS,
    payload: param
});
// 获取推送人群
export const getPushPeople = (param) => ({
    type: MESSAGE.GET_PUSHPEOPLE,
    payload: param
});
