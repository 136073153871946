/**
 * 增值服务支付配置
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message, Radio } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import PayTable from './components/PayTable';
import PayModal from './modal/PayModal';
import { XOKButton, XCancelButton, XInput } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/systemmanage/paymentChannel/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            renderModal: false, // 当前需要打开的弹窗
            modalParams: {}, // 当前需要打开的弹窗的入参
            merchantName: ''//商户名称 
        };
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, merchantName } = this.state;
        this.searchCache = {
            merchantName,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getChannelList(this.searchCache);
        KeepAlive.saveCache(this.searchCache); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ merchantName: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    //表格switch请求
    httpSwitchSum = (url, data) => {
        http(url, data, 'POST')
            .then((res = {}) => {

            }).catch((reject = {}) => {
                message.error(reject.message);
            });
    }

    render() {
        const { merchantName, renderModal } = this.state;
        const { channelList } = this.props;

        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='服务名称'
                                    placeholder='请选择'
                                    value={merchantName}
                                    bindThis={this}
                                    bindName='merchantName'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <PayTable
                        renderData={channelList}
                        tableAction={() => this.setState({ renderModal: true })}
                        paginationChange={this._paginationChange}
                    />
                    {
                        renderModal && <PayModal closeMode={() => this.setState({ renderModal: false })} />
                    }
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    getChannelList: PropTypes.func,//支付通道列表(action)
    channelList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    channelList: state.paymentChannel.channelList, // 支付通道列表
});

export default connect(mapStateToProps, { ...model.actions })(Main);
