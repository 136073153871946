/**
 *  秀场管理 - 音乐管理
 * */ 
import React from 'react';
import PropTypes from 'prop-types'; 
import Main from './pages/main';

class Music extends React.Component {

    render(){
        return(
                <Main/> 
        );
    }
}

Music.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
};

export default Music;