import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import * as actions from '@/reducers/auditmanage/supplierAudit/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: [], // 当前需要打开的弹窗的入参
            supplierName: '',
            supplierClassify: {},
            auditStatus: {}
        };
    }

    static propTypes = {
        updateContent: PropTypes.func,
        getSupplierAuditList: PropTypes.func,
        getSupplierAuditSele: PropTypes.func,
        getSupplierAuditStatus: PropTypes.func,
        supplierAuditList: PropTypes.object,
        supplierAuditSele: PropTypes.array,
        supplierAuditStatus: PropTypes.array
    };

    componentDidMount() {
        this.props.getSupplierAuditSele();
        this.props.getSupplierAuditStatus();
    }

    // 查询
    _searchHandle = (useCache) => {
        this.setState({ isCheck: true });
        const { supplierName, supplierClassify, auditStatus, pageNum, pageSize } = this.state;
        this.searchCache = {
            supplierName, categoryId: supplierClassify.id, auditState: auditStatus.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getSupplierAuditList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ supplierName: '', supplierClassify: {}, auditStatus: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'audit':
                console.log(id, type, params);
                this.props.updateContent('AUDIT', id);
                break;
            case 'detail':
                console.log(id, type, params);
                this.props.updateContent('DETAIL', id);
                break;
        }
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { supplierAuditList, supplierAuditSele, supplierAuditStatus } = this.props;
        const { supplierName, supplierClassify, auditStatus } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='供应商名称'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='supplierName'
                                value={supplierName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='经营品类'
                                placeholder='请选择'
                                renderData={supplierAuditSele}
                                bindThis={this}
                                bindName='supplierClassify'
                                dataIndex='categoryName'
                                keyIndex='id'
                                value={supplierClassify.categoryName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='审核状态'
                                placeholder='请选择'
                                renderData={supplierAuditStatus}
                                bindThis={this}
                                bindName='auditStatus'
                                dataIndex='value'
                                keyIndex='code'
                                value={auditStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent paginationChange={this._paginationChange} renderData={supplierAuditList} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }

}
const mapStateToProps = (state) => ({
    supplierAuditList: state.supplierAudit.supplierAuditList,
    supplierAuditSele: state.supplierAudit.supplierAuditSele,
    supplierAuditStatus: state.supplierAudit.supplierAuditStatus
});
export default connect(mapStateToProps, actions)(Main);