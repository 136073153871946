import * as T from './actiontypes';
/**
 * 【秀场举报】数据源
 */
const defaultState = {
    tableSource: [],//分类列表数据源
    selectData: [],// 分类下拉
    paginations: {}, //分页对象
    banned: {},//举报禁播，封禁类型和封禁理由
    userDetail: {},//详情
};

/**
 * @param {*} state 
 * @param {*} action 
 */
export const showReortDate = (state = defaultState, action) => {
    let newTableSource, newSelectData, newPaginations, newbanned, newDetail;
    switch (action.type) {
        case T.SET_SHOWLIST_DATA: //列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_SHOWRESTOP_DATA: //举报禁播，封禁类型和封禁理由数据源(saga)
            newbanned = action.payload;
            return { ...state, banned: newbanned, };

        case T.SET_SHOWREORTSTATE_DATA: //分类下拉
            newSelectData = action.payload.result;
            return { ...state, selectData: newSelectData };

        case T.SET_SHOWREDETAIL_DATA: //秀场分类详情
            newDetail = action.payload.result;
            return { ...state, userDetail: newDetail, };
        default:
            return state;
    }
};