import React from 'react';
import styles from './TableData.module.scss';
import { Switch } from 'antd';
/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
	{
		title: '单位名称',
		dataIndex: 'financeCompany',
		key: 'financeCompany',
		width: 300,
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '纳税人识别码',
		dataIndex: 'taxpayerNo',
		key: 'taxpayerNo',
		width: 200,
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '注册地址',
		dataIndex: 'registrationAddr',
		key: 'registrationAddr',
		width: 300,
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '注册电话',
		dataIndex: 'registrationPhone',
		key: 'registrationPhone',
		width: 200,
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '开户银行',
		dataIndex: 'bankName',
		key: 'bankName',
		width: 200,
		align: 'center',
		render: (text, item) => (TableCell(text))
	},
	{
		title: '银行账号',
		dataIndex: 'bankAccount',
		key: 'bankAccount',
		width: 200,
		align: 'center',
		render: (text) => (TableCell(text))
	},

	{
		title: '法人姓名',
		dataIndex: 'legalName',
		key: 'legalName',
		width: 100,
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '法人手机',
		dataIndex: 'legalTel',
		key: 'legalTel',
		width: 100,
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '法人身份证',
		dataIndex: 'legalIdentity',
		key: 'legalIdentity',
		width: 150,
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '邮箱',
		dataIndex: 'contactsMail',
		key: 'contactsMail',
		width: 200,
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '收票人',
		dataIndex: 'contactsName',
		key: 'contactsName',
		width: 100,
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '收票人手机',
		dataIndex: 'contactsPhone',
		key: 'contactsPhone',
		width: 150,
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '收件地址',
		dataIndex: 'contactsAddr',
		key: 'contactsAddr',
		width: 300,
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '操作',
		dataIndex: 'id',
		key: 'id',
		align: 'center',
		width: 150,
		fixed: 'right',
		render: (id, record) => TableAction(id, record, actionClickHandle)
	},
];

export const takeDataSource = (data) => {
	// 解析表格数据，使符合columns格式要求
	return data.dataList;
};

// 表格项内容为图片
const ImgCell = (url) => {
	return (
		<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
			<div className={styles.imgbox}>
				<img className={styles.img} src={url} />
			</div>
		</div>
	);
};


export const takeColumns = (actionClickHandle) => {
	return columns(actionClickHandle);
};

const TableCell = (text) => {
	return (
		<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
			{text}
		</div>
	);
};

const TableAction = (id, record, actionClickHandle) => {
	return (
		<div className={styles.action}>
			<div className={styles.item} onClick={() => actionClickHandle(id, 'EDIT', record)}>编辑</div>
			<div className={styles.item} onClick={() => actionClickHandle(id, 'DELETE')}>删除</div>
		</div>
	);
};