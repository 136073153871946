import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import LiveTable from './components/LiveTable';
import OnlinePlayModal from '../../modal/OnlinePlayModal';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/commercialcollege/livelist/model';
import { XInput, XSelect, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import { setSessionStorage } from '@/assets/js/storage';
import { SearchBox, SearchItem } from '@/components/Layout';
import styles from './index.module.scss';
import knowmodel from '@/reducers/knowledgepay/channellist/model';
import CreateLive from '../../modal/CreateLive';
import PreviewLive from '../../modal/PreviewLive';

class Main extends Component {

    state = {
        renderMode: '', // 渲染弹框
        modeParams: {}, // 弹框入参
        nickname: undefined, // 昵称
        tel: undefined, // 手机号
        broadcasTitle: undefined,
        courseName: undefined, // 课程名称
        isDelete: {}, // 上下架
        liveState: {}, // 直播状态
        violationType: {}, // 违规状态
    };

    //挂载后
    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getLiveState();
        this.props.getDeleteState();
        this.props.getViolationType(); // 违规状态
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, tel, nickname, broadcasTitle, courseName, liveState, isDelete, violationType } = this.state;
        const searchCache = {
            tel,
            nickname,
            liveBusinessTitle: broadcasTitle,
            courseName,
            liveState: liveState.code,
            isDelete: isDelete.code,
            isDetectRead: violationType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };

        this.props.getLiveList(searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: searchCache.pageNum, pageSize: searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            nickname: undefined, // 昵称
            tel: undefined, // 手机号
            courseName: undefined, // 课程名称
            broadcasTitle: undefined,
            isDelete: {}, // 上下架
            liveState: {}, // 直播状态
            violationType: {}, // 违规状态
        }, () => KeepAlive.saveResetFunc(this._searchHandle)); // 【第4步：将是否调用重置交给KeepAlive控制】
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => { this._searchHandle('useCache'); });
    }

    // 表格操作
    _tableAction = (liveId, type, value) => {
        const { history, addOneBreadcrumbPath } = this.props;

        const liveTopInfo = {
            tel: value.tel,
            nickname: value.nickname,
            broadcasTitle: value.broadcasTitle,
            broadcasClass: value.broadcasClass
        };

        switch (type) {
            case 'play':
                this.setState({ renderMode: 'play', modeParams: { ...value } });
                break;
            case 'sign':
                addOneBreadcrumbPath({
                    title: '签到查看',
                    path: '/home/commercialcollege/livelist/signlist/' + liveId
                });
                history.push({ pathname: '/home/commercialcollege/livelist/signlist/' + liveId });
                break;
            case 'upperlower':
                http('/show/anchor/deleteLiveBusniess', { liveId, isDelete: value.isDelete.code == '1' ? 0 : 1 }).then((res) => {
                    if (res.code === 200) {
                        message.success(res.message);
                        this._searchHandle('useCache');
                    } else { message.error(res.message); }
                });
                break;
            case 'reco':
                http('/show/anchor/recoLiveBusiness', { liveId, isReco: value.isReco == '1' ? 0 : 1 }).then((res) => {
                    if (res.code === 200) {
                        message.success(res.message);
                        this._searchHandle('useCache');
                    } else { message.error(res.message); }
                });
                break;
            case 'goto':
                setSessionStorage('liveTopInfo', liveTopInfo);
                addOneBreadcrumbPath({
                    title: liveId === 'startLight' ? '魅力值收入' : '人气值收入',
                    path: `/home/commercialcollege/livelist/${liveId}/` + value.liveId
                });
                history.push({ pathname: `/home/commercialcollege/livelist/${liveId}/` + value.liveId });
                break;
            case 'violation':
                addOneBreadcrumbPath({
                    title: '本场违规',
                    path: {
                        pathname: '/home/commercialcollege/livelist/violation',
                        search: `broadcasTitle=${value.broadcasTitle}&&nickname=${value.nickname}&&liveId=${value.liveId}&&accountId=${value.accountId}`,
                    }
                });
                history.push({
                    pathname: '/home/commercialcollege/livelist/violation',
                    search: `broadcasTitle=${value.broadcasTitle}&&nickname=${value.nickname}&&liveId=${value.liveId}&&accountId=${value.accountId}`,
                });
                break;
            case 'delete': // 删除直播间（PC操作）
                showConfirm('删除提醒', '确认要删除当前直播间吗？', () => {
                    http('/liveBroad/deleteLiveBroadcast', { id: value.liveId, broadcastClassId: value.broadcasClassId, broadcastClass: value.broadcasClassCode }, 'POST').then((response) => {
                        this._searchHandle('useCache');
                        message.success('删除成功');
                    }).catch(e => message.error(e.message));
                });
                break;
            case 'start': // 开启直播间（PC操作）
                this.setState({ renderMode: 'previewLive', modeParams: { id: value.liveId, broadcastClassId: value.broadcasClassId, broadcastClass: value.broadcasClassCode } });
                break;
            case 'stop': // 停止直播间（PC操作）
                showConfirm('停止直播提示', '停止直播后，当前直播间将断开直播，用户不再获取直播画面，确定要停止直播吗？', () => {
                    http('/liveBroad/stopLiveBroadcast', { id: value.liveId, broadcastClassId: value.broadcasClassId, broadcastClass: value.broadcasClassCode }, 'POST').then((response) => {
                        this._searchHandle('useCache');
                        message.success('直播已停止');
                    }).catch((e) => {
                        message.error(e.message);
                    });
                });
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { modeParams, renderMode } = this.state;
        switch (renderMode) {
            case 'play':
                if (modeParams.liveState.code == 'LIVE') {
                    return <OnlinePlayModal visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
                } else if (modeParams.liveState.code == 'END') {
                    if (modeParams.playbackUrl) {
                        return <OnlinePlayModal visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
                    } else {
                        message.error('视频播放路径为空');
                        return null;
                    }
                } else return null;
            case 'createLive':
                return <CreateLive close={this._closeMode} closeAndOpenNewMode={this._closeAndOpenNewMode} />;
            case 'previewLive':
                return <PreviewLive params={modeParams} close={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ renderMode: '', modeParams: {} }, () => {
            this._searchHandle('useCache');
        });
    }

    // 关闭弹窗并打开新弹窗
    _closeAndOpenNewMode = (modeName, param) => {
        this.setState({ renderMode: modeName, modeParams: { ...param } });
    }

    // 发起直播
    _createLive = () => {
        this.setState({ renderMode: 'createLive', modeParams: {} });
    }

    //渲染组件
    render() {
        const {
            tel,
            nickname,
            courseName,
            broadcasTitle,
            isDelete,
            liveState,
            violationType
        } = this.state;

        const { violationStatus } = this.props;

        let liveStateList = [{ value: '全部', code: '' }].concat(this.props.liveStateList);
        let deleteStateList = [{ value: '全部', code: '' }].concat(this.props.deleteStateList);

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='手机名称'
                                placeholder='请输入手机名称'
                                value={tel}
                                bindThis={this}
                                bindName='tel'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='主播昵称'
                                placeholder='请输入主播昵称'
                                value={nickname}
                                bindThis={this}
                                bindName='nickname'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='直播标题'
                                placeholder='请输入直播标题'
                                value={broadcasTitle}
                                bindThis={this}
                                bindName='broadcasTitle'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='课程名称'
                                placeholder='请输入课程名称'
                                value={courseName}
                                bindThis={this}
                                bindName='courseName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='直播状态'
                                placeholder='请选择直播状态'
                                renderData={liveStateList}
                                dataIndex='value'
                                keyIndex='code'
                                value={liveState.value}
                                bindThis={this}
                                bindName='liveState'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='回放状态'
                                placeholder='请选择回放状态'
                                renderData={deleteStateList}
                                dataIndex='value'
                                keyIndex='code'
                                value={isDelete.value}
                                bindThis={this}
                                bindName='isDelete'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='违规状态'
                                placeholder='请选择'
                                renderData={violationStatus}
                                bindName='violationType'
                                bindThis={this}
                                dataIndex='value'
                                keyIndex='code'
                                value={violationType.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                            <XOKButton
                                style={{ width: '100px', marginLeft: '20px' }}
                                label='发起直播'
                                onClick={this._createLive}
                            />
                        </SearchItem>
                    </SearchBox>
                    <LiveTable renderData={this.props.liveList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由对象
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getLiveList: PropTypes.func, // 获取列表
    liveList: PropTypes.object, // 列表数据
    getLiveState: PropTypes.func,
    liveStateList: PropTypes.array,
    getDeleteState: PropTypes.func,
    deleteStateList: PropTypes.array,
    getViolationType: PropTypes.func,//违规状态action
    violationStatus: PropTypes.array,//违规状态数据
};

//映射数据源
const mapStateToProps = (state) => ({
    liveList: state.livelist.liveList,
    liveStateList: state.livelist.liveStateList,
    deleteStateList: state.livelist.deleteStateList,

    violationStatus: state.klist.violationStatus,//违规状态

});

//暴露组件
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions, ...knowmodel.actions })(Main);
