import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';
import ButtonPerssion from '@/routes/ButtonPerssion';

export default class TableContent extends Component {
	state = {
		selectedRowKeys: [],
	};
	static propTypes = {
		tableAction: PropTypes.func,
		paginationChange: PropTypes.func,
		renderData: PropTypes.object,
	};
	_columns = () => [
		{
			title: '区域编号',
			dataIndex: 'ACA1',
			key: 'ACA1',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '公司简称',
			dataIndex: 'ACA2',
			key: 'ACA2',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '区域',
			dataIndex: 'ACA3',
			key: 'ACA3',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '联系人',
			dataIndex: 'ACA4',
			key: 'ACA4',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '手机号',
			dataIndex: 'ACA5',
			key: 'ACA5',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '类型',
			dataIndex: 'ACA10',
			key: 'ACA10',
			align: 'center',
			width: cellWidth.small,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '员工数',
			dataIndex: 'ACA6',
			key: 'ACA6',
			align: 'center',
			width: cellWidth.small,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '状态',
			dataIndex: 'ACA7',
			key: 'ACA7',
			align: 'center',
			width: cellWidth.small,
			render: (text) => (this._stateCell(text))
		},
		{
			title: '有效期',
			dataIndex: 'ACA8',
			key: 'ACA8',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作人',
			dataIndex: 'ACA9',
			key: 'ACA9',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'key',
			key: 'key',
			align: 'center',
			width: cellWidth.normal,
			render: (id, record) => this._tableAction(id, record)
		},
	];
	_stateCell = (text) => {
		return tooltip(text ? text.value : '');
	}

	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		const { ACA7: { code = '' } = {} } = record;
		if (code === 'AUDIT') {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'detail')}>详情</div>
					<ButtonPerssion code='auditmanage/areaCompany/cs'>
						<div className={styles.item} onClick={() => tableAction(key, 'audit')}>初审</div>
					</ButtonPerssion>
				</div>
			);
		} else if (code === 'FIRST_APPROVAL') {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'detail')}>详情</div>
					<ButtonPerssion code='auditmanage/areaCompany/fs'>
						<div className={styles.item} onClick={() => tableAction(key, 'audit')}>复审</div>
					</ButtonPerssion>
				</div>
			);
		} else {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'detail')}>详情</div>
				</div>
			);
		}
	};
	_tableData = (data) => {
		let newData = [];
		data.map(item => {
			let tableItem = {};
			tableItem.key = item.id;
			tableItem.ACA1 = item.companyCode;
			tableItem.ACA2 = item.companyName;
			tableItem.ACA3 = item.areaName;
			tableItem.ACA4 = item.contactName;
			tableItem.ACA5 = item.contactTel;
			tableItem.ACA6 = item.emplCount;
			tableItem.ACA7 = item.companyState;
			tableItem.ACA8 = `${item.agrtStartTime} ~ ${item.agrtEndTime}`;
			tableItem.ACA9 = item.operation;
			tableItem.ACA10 = item.type.value;
			return newData.push(tableItem);
		});
		return newData;
	}
	render() {
		const { renderData, paginationChange } = this.props;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey={record => record.key}
					columns={this._columns()}
					pagination={false}
					dataSource={this._tableData(renderData.dataList)}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}