/**
 * 秀场管理 - 频道分类异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

// 获取黑名单列表
export const getBlackListTableSource=function*(){
    yield takeEvery(T.GET_BLACKLIST_TABELSOURCE,function* ({payload}){
        try{
            let result = yield http('/show/accountBlack/listAccountBlack', payload.requestProps, 'POST');
            yield put({
                type:T.SET_BLACKLIST_TABELSOURCE, 
                payload: result.result,
                requestProps:payload.requestProps
            });
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

// 获取历史记录
export const getHistoryTableSource=function*(){
    yield takeEvery(T.GET_HISTORY_TABELSOURCE,function* ({payload}){
        try{
            let result = yield http('/show/accountBlack/listAccountBlack', payload.requestProps, 'POST'); // action.payload为传入的参数对象
            yield put({
                type:T.SET_HISTORY_TABELSOURCE, 
                payload: result.result,
                requestProps:payload.requestProps
            });
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};