/**
 * AntDesign 全局配置
 */
import { message } from 'antd';

const antdConfig = () => {
    message.config({
        top: 420,
        duration: 1.5,
        maxCount: 1
    });
};

export default antdConfig;