import React, { Fragment } from 'react';
import styles from './TableData.module.scss';
import { tooltipStyle, tooltip, cellWidth, tooltipClick } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';
import ButtonPerssion from '@/routes/ButtonPerssion';

const columns = (actionClickHandle) => ([
    {
        title: '商户账号',
        dataIndex: 'loginName',
        key: 'loginName',
        width: cellWidth.normal,
        align: 'center',
        render: (text, item) => tooltipClick(text, () => actionClickHandle(item.id, 'MER_DETAIL'))
    },
    // {
    //     title: '商户号',
    //     dataIndex: 'merchantNo',
    //     key: 'merchantNo',
    //     width: cellWidth.normal,
    //     align: 'center',
    //     onCell: tooltipStyle,
    //     render: tooltip
    // },
    {
        title: '商户名称',
        dataIndex: 'merchantName',
        key: 'merchantName',
        width: cellWidth.normal,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '商户类型',
        dataIndex: 'merchantTypeValue',
        key: 'merchantTypeValue',
        width: cellWidth.normal,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '合作模式',
        dataIndex: 'cooperationModeName',
        key: 'cooperationModeName',
        width: cellWidth.normal,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    // {
    //     title: '商户地址',
    //     dataIndex: 'addrDetail',
    //     key: 'addrDetail',
    //     width: cellWidth.normal,
    //     align: 'center',
    //     onCell: tooltipStyle,
    //     render: tooltip
    // },
    {
        title: '运营中心',
        dataIndex: 'operationCenterName',
        key: 'operationCenterName',
        width: cellWidth.normal,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '区域公司',
        dataIndex: 'areaCompanyName',
        key: 'areaCompanyName',
        width: cellWidth.normal,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '服务商',
        dataIndex: 'serviceName',
        key: 'serviceName',
        width: cellWidth.normal,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '特权值比例',
        dataIndex: 'integralRate',
        key: 'integralRate',
        width: cellWidth.normal,
        align: 'center',
        onCell: tooltipStyle,
        render: (t) => t ? tooltip(`${t} : 1`) : '-'
    },
    {
        title: '客服座席',
        dataIndex: 'openCustomerServiceState.value',
        key: 'openCustomerServiceState.value',
        width: cellWidth.normal,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '新建时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        width: cellWidth.normal,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '收益金额',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        width: cellWidth.normal,
        align: 'center',
        // onCell: tooltipStyle,
        // render: priceFenToYuanCell
        render: (text, item) => tooltipClick(regFenToYuan(text), () => actionClickHandle(item.id, 'EARNING', item.merchantName))
    },
    {
        title: '状态',
        dataIndex: 'merchantStateValue',
        key: 'merchantStateValue',
        width: cellWidth.normal,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 300,
        fixed: 'right',
        render: (id, record) => TableAction(id, record, actionClickHandle)
    },
]);


export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    return data.map((item) => {
        item.merchantStateValue = item.merchantState && item.merchantState.value;//状态
        item.merchantStateCode = item.merchantState && item.merchantState.code;//状态
        item.merchantTypeValue = item.merchantType && item.merchantType.value;//分类
        item.merchantRateValue = item.rateMode && item.rateMode.value;//模式
        return item;
    });
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};

const TableAction = (id, item, actionClickHandle) => {
    const { merchantStateCode, merchantName, merchantType: { code = '' } = {} } = item;
    const hasEdit = ['NEW', 'NOT_APPROVED'].includes(merchantStateCode);
    return <div className={styles.action}>
        {merchantStateCode !== 'APPROVED' && <div className={styles.item} onClick={() => actionClickHandle(id, 'MER_DETAIL')}>详情</div>}
        {hasEdit && <div className={styles.item} onClick={() => actionClickHandle(id, 'MER_EDITOR')}>编辑</div>}
        {merchantStateCode === 'APPROVED' && <Fragment>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'MER_STOR')}>店铺</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'MEMBER', merchantName)}>会员列表</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'setPrivilegeRate', item)}>设置特权值比例</div>
            {code === 'CE' && <ButtonPerssion code='businessmanage/merchantlist/szjsbi'>
                <div className={styles.item} onClick={() => actionClickHandle(id, 'SetSettleRate', item)}>设置结算比例</div>
            </ButtonPerssion>}
        </Fragment>}
        <div className={styles.item} onClick={() => actionClickHandle(id, 'setBusinessCategory', item)}>设置经营品类</div>
    </div>;
};