import * as appcustomSaga from './appcustom/saga'; // 用户自定义界面管理
import * as activitiesSaga from './activities/saga'; // 营销活动
import * as advocacytaskSaga from './advocacytask/saga'; // 代言任务
import * as cardticketSaga from './cardticket/saga'; // 卡劵管理
import * as mallSaga from './mall/saga'; // 活动商品
import advocacyCreate from './advocacytask/model';//新增代言任务
import xiubei from './xiubei/model'; //贡献值
import cardticketCreate from './cardticket/model';//新增卡劵管理
import marketCommon from './common/model';//新增卡劵管理
import secondsKillActivity from './activities/model';//秒杀活动
import fightrecordSaga from './fightrecord/model';//抢拍活动
import productgroup from './productgroup/model';//营销活动商品分组

export default [
    ...Object.values(appcustomSaga),
    ...Object.values(activitiesSaga),
    ...Object.values(advocacytaskSaga),
    ...Object.values(cardticketSaga),
    ...Object.values(mallSaga),
    ...Object.values(advocacyCreate.sagas),
    ...Object.values(xiubei.sagas),
    ...Object.values(cardticketCreate.sagas),
    ...Object.values(marketCommon.sagas),
    ...Object.values(secondsKillActivity.sagas),
    ...Object.values(fightrecordSaga.sagas),
    ...Object.values(productgroup.sagas),
];
