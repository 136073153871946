/**
 * 审核弹框
 */
import React from 'react';
import { Modal, Input, DatePicker,message } from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './UnlockModal.module.scss';
const { TextArea } = Input;

class UnlockModal extends React.Component {
    
    state = {
        visible: this.props.visible,
        confirmLoading: false,
        expectedEndTime:undefined,
        openBlackReason:''
    }

    _handleOk = () => {
        const {expectedEndTime,openBlackReason}=this.state;
        if(expectedEndTime&&openBlackReason!==''){
            this.setState({
                visible: false,
            });
            http('/show/accountBlack/deAccountBlack',{
                id:this.props.data.id,
                expectedEndTime,
                openBlackReason
            },'POST').then(response=>{
                message.success('解封成功');
                this.props.closeMode(true);
            }).catch(e=>this.props.closeMode());
        }else{
            message.warn('请填写完整信息');
        }
    }


    _handleCancel  = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render(){
        const { visible, confirmLoading, expectedEndTime,openBlackReason } = this.state;
        
        return(
            <Modal
                width={470}
                centered
                title='解封'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.memberID}>
                        <span>会员账号：</span>
                        <span>{this.props.data.accountId||'会员账号异常'}</span>
                    </div>
                    <div className={styles.cover}>
                        <span>解封时间：</span>
                        <span>
                            <DatePicker showTime placeholder='请选择时间' locale={locale} onChange={(v,expectedEndTime)=>this.setState({expectedEndTime})}/>
                        </span>
                    </div>
                    <div className={styles.reason}>
                        <TextArea
                            placeholder='请输入解封原因'
                            autosize={{ minRows: 3, maxRows: 6 }}
                            value={openBlackReason}
                            onChange={v=>this.setState({openBlackReason:v.target.value})}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

UnlockModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    data:PropTypes.object
};

export default UnlockModal;