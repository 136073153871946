/**
 *  【预收款管理】入口页
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/previewcollectionlist';

const storehouselist = ({ history }) => {
    return <Main history={history} />;
};

storehouselist.propTypes = {
    history: PropTypes.object
};

export default storehouselist;