import * as T from './actiontypes';

/**
 * 运营中心
 */
const plistInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
export const operatingCenterReconciliationTableSource = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_OPERATINGCENTERRECONCILIATION_TABLESOURCE:
            return payload.result;
        default:
            return state;
    }
};

export const listOperationsIdAndName = (state = {}, { type, payload }) => {
    switch (type) {
        case T.SET_OPERATINGCENTERRECONCILIATION_LIST:
            return payload.result;
        default:
            return state;
    }
};
export const OperationCenterDetail = (state = {}, { type, payload }) => {
    switch (type) {
        case T.SET_OPERATINGCENTERRECONCILIATION_DETAIL:
            return payload.result;
        default:
            return state;
    }
};