import * as Audit from './actiontypes';

/**
 * 存储'主播封面审核列表'数据源
 * @param {*} state 
 * @param {*} action 
 */

 const init = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
 };

export const brandAuditList = (state = init, action) => {
    switch(action.type){
        case Audit.SET_BRANDAUDIT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const brandAuditUsername = (state = {}, action) => {
    switch(action.type){
        case Audit.SET_BRANDAUDIT_USERNAME:
            return action.payload.result;
        default:
            return state;
    }
};
export const brandAuditState = (state = [], action) => {
    switch(action.type){
        case Audit.SET_BRANDAUDIT_STATE:
            return action.payload.result;
        default:
            return state;
    }
};
export const brandAuditDetail = (state = {}, action) => {
    switch(action.type){
        case Audit.SET_BRANDAUDIT_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};
export const brandAuditBrand = (state = [], action) => {
    switch(action.type){
        case Audit.SET_BRANDAUDIT_BRAND:
            return action.payload.result;
        default:
            return state;
    }
};