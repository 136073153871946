import * as Advert from './actiontypes';

/**
 * 获取'广告投放'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 */
export const getAdvertPlacementList = ({adName, state, pageSize, pageNum}) => ({
    type: Advert.GET_PLACEMENT_LIST,
    payload: {
        adName, state, pageSize, pageNum
    }
});
export const getAdvertPlacementAudit = ({adId}) => ({
    type: Advert.GET_PLACEMENT_AUDIT,
    payload: {adId}
});
export const getAdvertPlacementStartAndStop = ({id, state}) => ({
    type: Advert.GET_PLACEMENT_STARTANDSTOP,
    payload: {id, state}
});

export const getAdvertPlacementDetail = ({id}) => ({
    type: Advert.GET_PLACEMENT_DETAIL,
    payload: {
        id
    }
});
export const getAdvertPlacementAdd = (param) => {
    return ({
        type: Advert.GET_PLACEMENT_ADD,
        payload: {
            ...param
        }
    });
};
export const getAdvertPlacementEdit = (param) => {
    return ({
        type: Advert.GET_PLACEMENT_EDIT,
        payload: {
            ...param
        }
    });
};
export const getAdvertPlacementDelete = (id) => ({
    type: Advert.GET_PLACEMENT_DELETE,
    payload: {
        id
    }
});
// 广告界面下拉列表
export const getAdvertList = () => ({
    type: Advert.GET_PLACEMENT_ADVERTLIST,
    payload: {}
});
// 广告策略下拉列表
export const getAdvertStrategyList = () => ({
    type: Advert.GET_PLACEMENT_ADVERTSTRATEGYLIST,
    payload: {}
});
// 广告投放状态下拉
export const getAdvertPlacementStatus = () => {
    return ({
        type: Advert.GET_PLACEMENT_ADVERTSTATUS,
        payload: {}
    });
};