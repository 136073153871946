import { createModel } from '@/store/tools';

const model = {
    namespace: 'salereasion',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getReport': T('getReport'),
        },
        actions: {
            'getReport': A('getReport'),
        },
        sagas: {
            'getReport': S('getReport', '/report/saleReasion'),
        },
        reducers: {
            'getReport': R('getReport', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);