import { createModel } from '@/store/tools';

const model = {
    namespace: 'dataCenter',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getDataFromTab1': T('getDataFromTab1'),
            'getDataFromTab2': T('getDataFromTab2'),
            'getDataFromTab3': T('getDataFromTab3'),
            'getDataFromTab4': T('getDataFromTab4'),
            'getDataFromTab5': T('getDataFromTab5'),
            'getDataFromTab6': T('getDataFromTab6'),
            'getDataFromTab7': T('getDataFromTab7'),
            'getDataFromTab8': T('getDataFromTab8'),
        },
        actions: {
            'getDataFromTab1': A('getDataFromTab1'),
            'getDataFromTab2': A('getDataFromTab2'),
            'getDataFromTab3': A('getDataFromTab3'),
            'getDataFromTab4': A('getDataFromTab4'),
            'getDataFromTab5': A('getDataFromTab5'),
            'getDataFromTab6': A('getDataFromTab6'),
            'getDataFromTab7': A('getDataFromTab7'),
            'getDataFromTab8': A('getDataFromTab8'),
        },
        sagas: {
            'getDataFromTab1': S('getDataFromTab1', '/dataCenter/getPlatRateReport'),
            'getDataFromTab2': S('getDataFromTab2', '/dataCenter/bill'),
            'getDataFromTab3': S('getDataFromTab3', '/dataCenter/bill'),
            'getDataFromTab4': S('getDataFromTab4', '/dataCenter/serviceProviderOrder'),
            'getDataFromTab5': S('getDataFromTab5', '/dataCenter/order'),
            'getDataFromTab6': S('getDataFromTab6', '/dataCenter/user'),
            'getDataFromTab7': S('getDataFromTab7', '/dataCenter/service/provider'),
            'getDataFromTab8': S('getDataFromTab8', '/dataCenter/after/order'),
        },
        reducers: {
            'dataFromTab1': R('getDataFromTab1', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'dataFromTab2': R('getDataFromTab2', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'dataFromTab3': R('getDataFromTab3', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'dataFromTab4': R('getDataFromTab4', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'dataFromTab5': R('getDataFromTab5', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'dataFromTab6': R('getDataFromTab6', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'dataFromTab7': R('getDataFromTab7', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'dataFromTab8': R('getDataFromTab8', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);