/**
 * [带标题的输入框组件]
 * @author: Fu Xiaochun
 * @date: 2021-11-09 
 * 
 <RangePicker
    label='查询时间'
    placeholder='请输入查询时间'
    bindThis={this}
    bindName='transferTime'
    value={this.state.transferTime}
    format="YYYY-MM-DD"
 />
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';

class RangePicker extends React.Component {

    _onChange = (date, dateStr) => {
        const { bindThis, bindName, onChange } = this.props;

        if (onChange) {
            onChange(date, dateStr);
        }
        if (bindThis && bindName) {
            if (typeof bindThis === 'function') {
                bindThis(dateStr);
            } else {
                bindThis.setState({ [bindName]: dateStr });
            }
        }
    }

    
    _transDate = ()=>{        
        const { value, format } = this.props;
        if(Array.isArray(value) && value[0] && value[1]){
            return [moment(value[0], format), moment(value[1], format)];
        }
        return null;
    };

    render() {
        const { label, style, labelStyle, inputStyle, isRequired, bindThis, bindName, ...otherProps } = this.props;
        return (
            <div style={style} className={styles.container}>
                {isRequired ? <span className={styles.star}>*</span> : null}
                {label ? <div style={labelStyle} className={styles.label}>{label.replace(/[:：：\s]+$/, '')}</div> : null}
                <DatePicker.RangePicker 
                    allowClear={true}
                    {...otherProps}
                    style={inputStyle}
                    value={this._transDate()}
                    onChange={this._onChange}
                />
            </div>
        );
    }
}

RangePicker.defaultProps = {
    isRequired: false,
    inputStyle: { width: '230px' },
    format: 'YYYY-MM-DD'
};

RangePicker.propTypes = {
    label: PropTypes.string, // 标题名称
    style: PropTypes.object, // 组件样式
    labelStyle: PropTypes.object, // label样式
    inputStyle: PropTypes.object, // 输入框样式
    isRequired: PropTypes.bool, // 当为true时样式添加红星
    bindThis: PropTypes.oneOfType([PropTypes.func, PropTypes.object]), // 父级this
    bindName: PropTypes.string, // 双向绑定的属性名称
    onChange: PropTypes.func, // 输入框内容变化时的回调函数
    format: PropTypes.string,  // 格式化
    value: PropTypes.array  // 日期时间
};

export default RangePicker;