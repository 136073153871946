import { combineReducers } from 'redux';
import {
    customerDetail,
    customerList,
    merchantIdAndName
} from './reducer';

export default combineReducers({
    customerDetail,
    customerList,
    merchantIdAndName
});