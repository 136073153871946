import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table, Switch } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

class TableData extends Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
    };

    _takeColumns = () => ([
        {
            title: '分类名称',
            dataIndex: 'categoryName',
            key: 'categoryName',
            width: '20%',
            align: 'left'
        },
        {
            title: '分类图片',
            dataIndex: 'imgUrl',
            key: 'imgUrl',
            width: '10%',
            align: 'center',
            render: ImgCell
        },
        {
            title: '技术服务费率(%)',
            dataIndex: 'platRate',
            key: 'platRate',
            width: '12%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '平台服务费率(%)',
            dataIndex: 'agentRate',
            key: 'agentRate',
            width: '12%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '排序',
            dataIndex: 'sortNo',
            key: 'sortNo',
            width: '10%',
            align: 'center',
            render: (text, item) => this._renderSortCell(text, item)
        },
        {
            title: '状态',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            align: 'center',
            render: (text, item) => this._renderStatusCell(item.id, item)
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '12%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'action',
            align: 'center',
            width: '14%',
            render: (text, item) => this._renderActionCell(item.id, item)
        }
    ]);

    // 渲染表格单元（排序）
    _renderSortCell = (text, item) => {
        if (item.depth === 'secondAdd' || item.depth === 'thirdAdd' || (!item.id && item.dotype === 'save')) {
            return null;
        } else {
            return (
                <div className={styles.sortCell} key={item.id}>
                    <img
                        className={styles.up}
                        src={require('@/assets/image/up.png')}
                        onClick={() => {
                            let curIDSortList = this._searchCurIDSortList(item.id, this.props.renderData.dataList);
                            let fromItem = item;
                            let toItem = this._searchToItem(item.id, curIDSortList, 'up');
                            this.props.tableAction(item.id, 'sort', { from: fromItem, to: toItem, type: 'up' });
                        }}
                    />
                    <img
                        className={styles.down}
                        src={require('@/assets/image/down.png')}
                        onClick={() => {
                            let curIDSortList = this._searchCurIDSortList(item.id, this.props.renderData.dataList);
                            let fromItem = item;
                            let toItem = this._searchToItem(item.id, curIDSortList, 'down');
                            this.props.tableAction(item.id, 'sort', { from: fromItem, to: toItem, type: 'down' });
                        }}
                    />
                </div>
            );
        }
    };

    // 找到当前ID所在的排序列表
    _searchCurIDSortList = (id, list) => {
        let curIDSortList = [];
        for (let i = 0; i < list.length; i++) {
            if (id === list[i].id) {
                curIDSortList = list;
                break;
            } else {
                if (list[i].categoryInfoList && list[i].categoryInfoList.length > 0) {
                    let clist = this._searchCurIDSortList(id, list[i].categoryInfoList);
                    if (clist.length > 0) {
                        curIDSortList = clist;
                        break;
                    }
                }
            }
        }
        return curIDSortList;
    }

    // 查找变换至目标排序的分类对象
    _searchToItem = (id, list, type) => {
        let toItem = null;
        for (let i = 0; i < list.length; i++) {
            if (id === list[i].id) {
                if (type === 'up') {
                    toItem = list[i - 1];
                    break;
                } else {
                    toItem = list[i + 1];
                    break;
                }
            }
        }
        return toItem;
    }

    // 启用禁用分类
    _renderStatusCell = (id, item) => {
        const { tableAction } = this.props;
        let checked = item.state == 1;
        let depth = item.depth;
        if (depth == 'secondAdd' || depth == 'thirdAdd') {
            return null;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    key={item.id}
                    checkedChildren="开"
                    unCheckedChildren="关"
                    checked={checked}
                    onChange={(value) => {
                        tableAction(id, 'status-switch', { item, value });
                    }}
                />
            </div>
        );
    };

    // 操作表格
    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                {
                    item.categoryGrade != 3 && <div className={styles.item} onClick={() => tableAction(key, 'addSublevel', item)}>新增子级分类</div>
                }
                <div className={styles.item} onClick={() => tableAction(key, 'edit', item)}>编辑</div>
                <div className={styles.item} onClick={() => tableAction(key, 'delete', item)}>删除</div>
            </div>
        );
    };

    render() {
        const { renderData: {
            pageNum = 1,
            pageSize = 10,
            resultTotal = 0,
            dataList = []
        } = {},
            paginationChange,
            expandedKeys,
            handleOnExpand } = this.props;

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    childrenColumnName='categoryInfoList'
                    defaultExpandAllRows
                    expandedRowKeys={expandedKeys}
                    onExpand={handleOnExpand}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableData.propTypes = {
    tableAction: PropTypes.func.isRequired,//表格操作
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    expandedKeys: PropTypes.array.isRequired,
    handleOnExpand: PropTypes.func.isRequired
};

export default TableData;