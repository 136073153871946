/**
 * 修改会员等级
 */
import React from 'react';
import { Modal, Input, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './LevelModal.module.scss';
import { XSelect } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import model from '@/reducers/usermanage/member/model';
import http from '@/assets/api/http';

const { TextArea } = Input;
class LevelModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        selectItem: {},
        reason: ''
    }

    componentDidMount() {
        this.props.getAccountLevel();
    }

    // 确认
    _handleOk = () => {
        const { selectItem, reason } = this.state;
        const { id } = this.props.data;
        const data = {
            id,
            accountLevelId: selectItem.id,
            levelType: selectItem.levelType,
            reason
        };
        this.setState({ confirmLoading: true });
        http('/user/account/updateAccountLevel', data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('修改成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        }).finally(() => {
            this.setState({ confirmLoading: false });
        });
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // textarea输入框
    _areaChangeHandle = (e) => {
        this.setState({ reason: e.target.value });
    }

    // 当修改权限为单个对象时渲染
    _renderSinglePatch = () => {
        const { SinglePatch } = this.props;
        const { tel, userName, levelName } = this.props.data;
        if (SinglePatch) {
            return (
                <div className={styles.single_patch}>
                    <div className={styles.account}>会员账号：{tel}</div>
                    <div className={styles.name}>姓名：{userName}</div>
                    <div className={styles.charm}>当前等级：{levelName}</div>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        const { visible, confirmLoading, reason, selectItem } = this.state;
        const { accountLevel } = this.props;

        return (
            <Modal
                width={470}
                centered
                title='修改会员等级'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    {this._renderSinglePatch()}
                    <div className={styles.up}>
                        <XSelect
                            style={{ width: '372px', marginLeft: '40px' }}
                            selectStyle={{ width: '304px' }}
                            label='调整等级'
                            placeholder='请选择'
                            renderData={accountLevel}
                            onChange={this._selectChange}
                            dataIndex='levelName'
                            keyIndex='id'
                            value={selectItem.levelName}
                            bindThis={this}
                            bindName='selectItem'
                        />
                    </div>
                    <div className={styles.down}>
                        <div className={styles.downlabel}>系统消息</div>
                        <TextArea
                            className={styles.textArea}
                            rows={4}
                            onChange={this._areaChangeHandle}
                            value={reason}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

LevelModal.propTypes = {
    data: PropTypes.object, // 外部传递参数
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    SinglePatch: PropTypes.bool, // 权限：单个修改还是批量修改，当为true时表示单个修改
    getAccountLevel: PropTypes.func, // 请求会员等级
    accountLevel: PropTypes.array, // 会员等级
};

const mapStateToProps = (state) => ({
    accountLevel: state.member.accountLevel
});

export default connect(mapStateToProps, { ...model.actions })(LevelModal);