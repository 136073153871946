import React from 'react';
import styles from './TableData.module.scss';
import { Switch, } from 'antd';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
    {
        title: '分类ID',
        dataIndex: 'id',
        key: 'id',
        width: '25%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '分类名称',
        dataIndex: 'className',
        key: 'className',
        width: '25%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '25%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle))
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: '25%',
        render: (id, record) => TableAction(record, actionClickHandle)
    },
];

export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    return data;
};
// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
    let def = sw && sw.code;
    let checked = true;
    if (def == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                key={item.id + '_' + checked}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={(value) => {
                    actionClickHandle(item, 'switch', value);
                }}
            />
        </div>
    );
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};

const TableAction = (record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(record, 'edit')}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(record, 'delete')}>删除</div>
        </div>
    );
};