/**
 * 讲师管理
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import LectureTable from './components/LectureTable';
import LeturesModal from './modal/LeturesModal';
import { XInput, XDatePicker, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/commercialcollege/lecturersmanage/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',//真实姓名
            lecture: '',//讲师名称
            host: '',//主播账号 
            startTime: null,//开始时间
            endTime: null,//结束时间
            status: {},//状态
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
        };
    }

    componentDidMount() {
        this.props.getLecturersState();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, name, lecture, host, status, startTime, endTime } = this.state;
        this.searchCache = {
            state: status.code,//状态
            realName: name,//真实姓名
            anchorName: lecture,//讲师名称
            tel: host,//主播账号
            startDate: startTime != null ? startTime.format('YYYY-MM-DD HH:mm:ss') : null, //开始时间
            endDate: endTime != null ? endTime.format('YYYY-MM-DD HH:mm:ss') : null, // 结束时间
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getLecturersList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ name: '', lecture: '', host: '', status: {}, startTime: null, endTime: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建组别
    _newHandle = () => {
        this.setState({
            currentOpenMode: 'CREAT'
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getLecturersList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (item, type, value) => {
        switch (type) {
            case 'course'://课程管理
                this.props.updateContent('course', item, value.anchorName);
                break;
            case 'switch'://状态
                http('/show/anchor/disableAnchor', {
                    anchorId: item.anchorId,
                    state: value === false ? '0' : '1'
                }, 'POST')
                    .then(() => {
                        message.success('状态修改成功。');
                        this.props.getLecturersList(this.searchCache);
                    }).catch((reject = {}) => {
                        message.error(reject.message);
                    });
                break;
        }
    }

    renderModal = () => {
        switch (this.state.currentOpenMode) {
            case 'CREAT':
                return <LeturesModal title='新建讲师' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }
    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getLecturersList(this.searchCache);
        }
    }


    render() {
        const { name, lecture, host, startTime, endTime, status } = this.state;
        const { tableList, states } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='真实姓名'
                                placeholder='请输入真实姓名'
                                value={name}
                                bindThis={this}
                                bindName='name'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='讲师名称'
                                placeholder='请输入讲师名称'
                                value={lecture}
                                bindThis={this}
                                bindName='lecture'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='主播账号'
                                placeholder='请输入主播账号'
                                value={host}
                                bindThis={this}
                                bindName='host'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='创建时间'
                                value={startTime}
                                bindThis={this}
                                bindName='startTime'
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                label=''
                                value={endTime}
                                bindThis={this}
                                bindName='endTime'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={states}
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                                bindThis={this}
                                bindName='status'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: '90px' }}
                            label='新建讲师'
                            onClick={this._newHandle}
                        />
                    </div>
                    <LectureTable renderData={tableList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this.renderModal()}
                </div>
            </KeepAlive>
        );
    }
}


Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    updateContent: PropTypes.func, // 路由跳转

    tableList: PropTypes.object,
    getLecturersList: PropTypes.func,//表格数据(action)
    states: PropTypes.array,
    getLecturersState: PropTypes.func,//状态数据(action)
};

const mapStateToProps = (state) => ({
    tableList: state.lecturers.tableList, // 表格数据
    states: state.lecturers.lecturersState, // 状态
});

export default connect(mapStateToProps, { ...model.actions })(Main);
