/**
 *  人气值商城 - 列表
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class StarValueList extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }
    // 更新模块
    _updateContent = (mode, params) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case 'DETAIL':
                addOneBreadcrumbPath({
                    title: '人气值商品详情',
                    path: '/home/starValueShop/starValueList/detail/'
                });
                history.push({
                    pathname: '/home/starValueShop/starValueList/detail',
                    search: `?ctrId=${params.ctrId}&&goodsId=${params.goodsId}`,
                });
                break;
            case 'CREATE':
                addOneBreadcrumbPath({
                    title: '创建人气值商品',
                    path: '/home/starValueShop/starValueList/create/'
                });
                history.push({
                    pathname: '/home/starValueShop/starValueList/create/',
                    state: { keepAlive: true }
                });
                break;
            case 'EDITOR':
                addOneBreadcrumbPath({
                    title: '编辑人气值商品',
                    path: '/home/starValueShop/starValueList/editInfo/'
                });
                history.push({
                    pathname: '/home/starValueShop/starValueList/editInfo/' + params.id,
                    state: { keepAlive: true }
                });
                break;
        }

    }
    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

StarValueList.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(StarValueList);