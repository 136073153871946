import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, tooltipClick, cellWidth } from '@/components/TableCell';

export default class TableContent extends Component {

	static propTypes = {
		tableAction: PropTypes.func,
		paginationChange: PropTypes.func,
		renderData: PropTypes.object,
	};

	_columns = () => [
		{
			title: '商户账号',
			dataIndex: 'loginName',
			key: 'loginName',
			align: 'center',
			width: cellWidth.time,
			onCell: tooltipStyle,
			render: (text, item) => tooltipClick(text, () => this.props.tableAction(item.id, 'detail'))
		},
		{
			title: '商户名称',
			dataIndex: 'merchantName',
			key: 'merchantName',
			align: 'center',
			width: cellWidth.time,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '转账账号',
			dataIndex: 'cardNo',
			key: 'cardNo',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '申请日期',
			dataIndex: 'gmtCreate',
			key: 'gmtCreate',
			align: 'center',
			width: cellWidth.time,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '状态',
			dataIndex: 'status.value',
			key: 'status.value',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作人',
			dataIndex: 'modifyUserName',
			key: 'modifyUserName',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			render: (id, record) => this._tableAction(id, record)
		}
	];

	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
				{record.status.code === 'WAIT_AUDIT' && <div className={styles.item} onClick={() => tableAction(key, 'audit', record)}>审核</div>}
			</div>
		);
	};

	render() {
		const { renderData, paginationChange } = this.props;

		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey={record => record.id}
					columns={this._columns()}
					pagination={false}
					dataSource={renderData.dataList}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}