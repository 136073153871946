//获取并保存商品列表
export const GET_PRODUCT_PRODUCTAUDITDATA = 'PRODUCTAUDIT/GET_PRODUCT_PRODUCTAUDITDATA';
export const SET_PRODUCT_PRODUCTAUDITDATA = 'PRODUCTAUDIT/SET_PRODUCT_PRODUCTAUDITDATA';

//获取并保存商品费率列表
export const GET_PRODUCTAUDIT_RATELIST = 'PRODUCTAUDIT/GET_PRODUCTAUDIT_RATELIST';
export const SET_PRODUCTAUDIT_RATELIST = 'PRODUCTAUDIT/SET_PRODUCTAUDIT_RATELIST';

//获取并保存商品状态下拉
export const GET_PRODUCT_STATUS = 'PRODUCTAUDIT/GET_PRODUCT_STATUS';
export const SET_PRODUCT_STATUS = 'PRODUCTAUDIT/SET_PRODUCT_STATUS';

//获取并保存商品商户下拉
export const GET_PRODUCT_USERNAME = 'PRODUCTAUDIT/GET_PRODUCT_USERNAME';
export const SET_PRODUCT_USERNAME = 'PRODUCTAUDIT/SET_PRODUCT_USERNAME';

//获取并保存商品业务类型下拉
export const GET_PRODUCT_BUSINESSTYPE = 'PRODUCTAUDIT/GET_PRODUCT_BUSINESSTYPE';
export const SET_PRODUCT_BUSINESSTYPE = 'PRODUCTAUDIT/SET_PRODUCT_BUSINESSTYPE';

//获取并保存商品标签下拉
export const GET_PRODUCT_LABELSELE = 'PRODUCTAUDIT/GET_PRODUCT_LABELSELE';
export const SET_PRODUCT_LABELSELE = 'PRODUCTAUDIT/SET_PRODUCT_LABELSELE';

//获取并保存商品分类三级联动
export const GET_PRODUCT_CLASSIFYSELE = 'PRODUCTAUDIT/GET_PRODUCT_CLASSIFYSELE';
export const SET_PRODUCT_CLASSIFYSELE = 'PRODUCTAUDIT/SET_PRODUCT_CLASSIFYSELE';

//获取并保存商品详情
export const GET_PRODUCTAUDIT_DETAIL = 'PRODUCTAUDIT/GET_PRODUCTAUDIT_DETAIL';
export const SET_PRODUCTAUDIT_DETAIL = 'PRODUCTAUDIT/SET_PRODUCTAUDIT_DETAIL';

//获取并保存商品详情
export const GET_PRODUCTAUDIT_RATEDETAIL = 'PRODUCTAUDIT/GET_PRODUCTAUDIT_RATEDETAIL';
export const SET_PRODUCTAUDIT_RATEDETAIL = 'PRODUCTAUDIT/SET_PRODUCTAUDIT_RATEDETAIL';

//获取并保存商品审核日志
export const GET_PRODUCT_AUDITLOG = 'PRODUCTAUDIT/GET_PRODUCT_AUDITLOG';
export const SET_PRODUCT_AUDITLOG = 'PRODUCTAUDIT/SET_PRODUCT_AUDITLOG';

//获取并保存商品费率审核日志
export const GET_PRODUCT_RATEAUDITLOG = 'PRODUCTAUDIT/GET_PRODUCT_RATEAUDITLOG';
export const SET_PRODUCT_RATEAUDITLOG = 'PRODUCTAUDIT/SET_PRODUCT_RATEAUDITLOG';
