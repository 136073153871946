//获取/保存区域公司发票列表
export const GET_COMPANYINVOICE_LIST = 'COMPANYINVOICE/GET_COMPANYINVOICE_LIST';
export const SET_COMPANYINVOICE_LIST = 'COMPANYINVOICE/SET_COMPANYINVOICE_LIST';

//获取/保存状态下拉
export const GET_COMPANYINVOICE_STATUS = 'COMPANYINVOICE/GET_COMPANYINVOICE_STATUS';
export const SET_COMPANYINVOICE_STATUS = 'COMPANYINVOICE/SET_COMPANYINVOICE_STATUS';

//获取/保存公司发票详情
export const GET_COMPANYINVOICE_DETAIL = 'COMPANYINVOICE/GET_COMPANYINVOICE_DETAIL';
export const SET_COMPANYINVOICE_DETAIL = 'COMPANYINVOICE/SET_COMPANYINVOICE_DETAIL';

//获取/保存公司下拉菜单
export const GET_COMPANYINVOICE_NAME = 'COMPANYINVOICE/GET_COMPANYINVOICE_NAME';
export const SET_COMPANYINVOICE_NAME = 'COMPANYINVOICE/SET_COMPANYINVOICE_NAME';

//新建付款金额详情
export const GET_COMPANYINVOICE_PAY = 'COMPANYINVOICE/GET_COMPANYINVOICE_PAY'; 
export const SET_COMPANYINVOICE_PAY = 'COMPANYINVOICE/SET_COMPANYINVOICE_PAY'; 