/**
 *  商户体系设置
 * */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { Switch, message, Input } from 'antd';
import { XOKButton, XInputNum, XTextArea } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
const { TextArea } = Input;
class Main extends Component {
    state = {
        list: [],
        submitparam: [],//提交的数据
        flag: true,
        flagMessage: '',
    };

    componentDidMount() {
        // 商户体系设置
        http('/admin/param/listAdminParam/MERCHANT', {}, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result && response.result.length > 0) {

                    let merList = response.result.map((item) => {
                        const { paramAttr, paramType, remark, ...restParams } = item;
                        if (item.paramCode == 'SERVICE_WHOLESALE_GOODS_SUB_ACCOUNT_RATIO') {
                            return { ...restParams, paramValue: (Number(item.paramValue) / 10).toFixed(1) };
                        }
                        return { ...restParams, msgValidation: paramAttr };
                    });

                    let list = response.result.map(item => {
                        if (item.paramCode == 'SERVICE_WHOLESALE_GOODS_SUB_ACCOUNT_RATIO') {
                            return { ...item, paramValue: (Number(item.paramValue) / 10).toFixed(1) };
                        }
                        return item;
                    });

                    this.setState({
                        list,
                        submitparam: merList
                    });
                }
            }
        }).catch((e = {}) => {
            message.error(e.message);
        });
    }

    //保存
    _savehandle = () => {
        const { submitparam, flag, flagMessage } = this.state;
        for (let i = 0; i < submitparam.length; i++) {
            if (submitparam[i].paramValue === null || submitparam[i].paramValue == '') {
                return message.warning(`请输入${submitparam[i].msgValidation}`);
            }
        }
        if (!flag) {
            message.warn(flagMessage);
            return;
        }
        let submitData = submitparam.map((item) => {
            const { msgValidation, ...restParams } = item;
            return restParams;
        });

        for (let item of submitData) {
            if (item.paramCode == 'SERVICE_WHOLESALE_GOODS_SUB_ACCOUNT_RATIO' &&
                item.paramValue === null) {
                message.warning('服务商分销商品分账比例不能为空');
                return;
            }
        }

        this.setState({ loading: true }, () => {
            http('/admin/param/batchUpdateParam', submitData.map(item => {
                if (item.paramCode == 'SERVICE_WHOLESALE_GOODS_SUB_ACCOUNT_RATIO') {
                    return { ...item, paramValue: item.paramValue * 10 };
                }
                return item;
            }), 'POST').then((response) => {
                if (response.status == 200) {
                    message.success('保存成功');
                    this.setState({ loading: false });
                }
            }).catch((e = {}) => {
                this.setState({ loading: false });
                message.error(e.message);
            });
        });
    }

    _renderList = (list) => {
        return list.map((item) => {
            if (item.paramCode == 'MERCHANT_IS_OPEN_MANUALLY_REVIEW') {//是否开启商户人工审核
                return (
                    <div className={styles.item} key={item.paramCode}>
                        <h4>{item.paramAttr}</h4>
                        <p>{item.remark}</p>
                        <div>
                            <Switch
                                checkedChildren="开"
                                unCheckedChildren="关"
                                checked={item.paramValue == 0 ? false : true}
                                onChange={(value) => {
                                    this._countList(item.paramCode, value);
                                }} />
                        </div>
                    </div>
                );
            } else if (item.paramCode == 'WHOLESALE_GOODS_AUTO_PULL_OFF_MIN_DAY') {//批发商品自动下架最小天数
                return (
                    <div className={styles.item} key={item.paramCode}>
                        <h4>{item.paramAttr}</h4>
                        <p>{`${item.remark}（单位:天）`}</p>
                        <div>
                            <XInputNum
                                style={{ width: '100px', marginLeft: '117px' }}
                                inputStyle={{ width: '93px' }}
                                placeholder='请输入'
                                value={item.paramValue}
                                precision={0}
                                min={0}
                                max={999}
                                onChange={(value) => {
                                    this._countList(item.paramCode, value);
                                }}
                            />
                        </div>
                    </div>
                );

            } else if (item.paramCode == 'WHOLESALE_GOODS_SOLD_OUT_AUTO_PULL_OFF_TIME') {//批发商品售罄
                return (
                    <div className={styles.item} key={item.paramCode}>
                        <h4>{item.paramAttr}</h4>
                        <p>{`${item.remark}（单位:小时）`}</p>
                        <div>
                            <XInputNum
                                style={{ width: '100px', marginLeft: '117px' }}
                                inputStyle={{ width: '93px' }}
                                placeholder='请输入'
                                value={item.paramValue}
                                precision={0}
                                min={1}
                                max={72}
                                onChange={(value) => {
                                    this._countList(item.paramCode, value);
                                }}
                            />
                        </div>
                    </div>
                );
            } else if (item.paramCode == 'SERVICE_WHOLESALE_GOODS_SUB_ACCOUNT_RATIO') {//服务商分销商品分账比例
                return (
                    <div className={styles.item} key={item.paramCode}>
                        <h4>{item.paramAttr}</h4>
                        <p>{item.remark}</p>
                        <div>
                            <XInputNum
                                style={{ width: '100px', marginLeft: '117px' }}
                                inputStyle={{ width: '93px' }}
                                placeholder='请输入'
                                value={item.paramValue}
                                precision={1}
                                min={0.0}
                                max={100.0}
                                onChange={(value) => {
                                    this._countList(item.paramCode, value);
                                }}
                            />
                        </div>
                    </div>
                );
            } else if (item.paramCode == 'FENLAISHOP_ACCOUNT_BALANCE_NOTIFICATION_PHONE_LIST') {
                // 纷来渠道账户余额预警通知
                return (
                    <div className={styles.item} key={item.paramCode}>
                        <h4>{item.paramAttr}</h4>
                        <p>{item.remark}</p>
                        <div>
                            <TextArea
                                style={{ width: '200px' }}
                                placeholder='请输入'
                                value={item.paramValue}
                                onBlur={(val) => {
                                    let flag = true;
                                    let reg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
                                    let strs = val.target.value && val.target.value.split(',');
                                    let arr = [];
                                    if (strs && strs.length > 0) {
                                        for (let i = 0; i < strs.length; i++) {
                                            if (!reg.test(strs[i])) {
                                                flag = false;
                                                this.setState({ flag: false });
                                                arr.push(`异常手机号${i + 1}`);
                                            }
                                        }
                                    }
                                    if (flag && strs.length > 10) {
                                        flag = false;
                                        this.setState({ flag: false, flagMessage: '支持最多10个手机号' });
                                        message.warn('支持最多10个手机号');
                                    } else if (!flag) {
                                        this.setState({ flagMessage: arr.join(',') + ',请输入有效手机号。' });
                                        message.warn(arr.join(',') + ',请输入有效手机号。');
                                    } else {
                                        this.setState({ flag: true, flagMessage: '' });
                                    }
                                    console.log(val.target.value);
                                }}
                                onChange={(val) => {
                                    if (val.target.value == ',') val.target.value = '';
                                    let vals = val.target.value.replace(/[^\r\n0-9,]/g, '');
                                    this._countList(item.paramCode, vals);
                                }}
                            />
                        </div>
                    </div>
                );
            } else if (item.paramCode == 'HOUNIAO_ACCOUNT_BALANCE_NOTIFICATION_PHONE_LIST') {//候鸟供应链余额账号预警通知
                return (
                    <div className={styles.item} key={item.paramCode}>
                        <h4>{item.paramAttr}</h4>
                        <p>{item.remark}</p>
                        <div>
                            <TextArea
                                style={{ width: '200px' }}
                                placeholder='请输入'
                                value={item.paramValue}
                                onBlur={(val) => {
                                    let flag = true;
                                    let reg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
                                    let strs = val.target.value && val.target.value.split(',');
                                    let arr = [];
                                    if (strs && strs.length > 0) {
                                        for (let i = 0; i < strs.length; i++) {
                                            if (!reg.test(strs[i])) {
                                                flag = false;
                                                this.setState({ flag: false });
                                                arr.push(`异常手机号${i + 1}`);
                                            }
                                        }
                                    }
                                    if (flag && strs.length > 10) {
                                        flag = false;
                                        this.setState({ flag: false, flagMessage: '支持最多10个手机号' });
                                        message.warn('支持最多10个手机号');
                                    } else if (!flag) {
                                        this.setState({ flagMessage: arr.join(',') + ',请输入有效手机号。' });
                                        message.warn(arr.join(',') + ',请输入有效手机号。');
                                    } else {
                                        this.setState({ flag: true, flagMessage: '' });
                                    }
                                    console.log(val.target.value);
                                }}
                                onChange={(val) => {
                                    if (val.target.value == ',') val.target.value = '';
                                    let vals = val.target.value.replace(/[^\r\n0-9,]/g, '');
                                    this._countList(item.paramCode, vals);
                                }}
                            />
                        </div>
                    </div>
                );
            }
        });
    }

    _countList = (code, value) => {
        const { list } = this.state;
        let submitparam = [];//提交的数据
        let arrList = list.map((item) => {
            if (code == 'MERCHANT_IS_OPEN_MANUALLY_REVIEW' && item.paramCode == code) {
                item.paramValue = value ? 1 : 0;
            } else if (item.paramCode == code) {
                item.paramValue = value;
            }
            const { paramAttr, paramType, remark, ...restParams } = item;
            submitparam.push({ ...restParams, msgValidation: paramAttr });
            return item;

        });
        this.setState({
            list: arrList,
            submitparam
        });
    }

    render() {
        const { list, loading } = this.state;

        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.tableContainer} style={{ overflow: 'auto' }}>
                    <div className={styles.riskcontent}>
                        {this._renderList(list, true)}
                    </div>
                </div>
                <div className={styles.layout}>
                    <XOKButton loading={loading} onClick={this._savehandle} style={{ width: '90px', marginTop: '30px' }} label='保存' />
                </div>
            </div>
        );
    }
}

Main.propTypes = {};

export default connect(null, null)(Main);