/**
 * 设置分销价
 */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './modalStyle.module.scss';
import { Modal, message, Table, InputNumber, Typography, Checkbox, Radio, Select } from 'antd';
import { tooltip, ImgCell, priceFenToYuanCell, cellWidth, tooltipStyle } from '@/components/TableCell';
import { regYuanToFen, regFenToYuan } from '@/util/money';
import model from '@/reducers/productmanage/wholesalegoods/model';
import { connect } from 'react-redux';

const { getChannelEnum, getOnChannelDistributionMethodsEnum, getLimitNumberTypeEnum } = model.actions;
const { Text } = Typography;

const SetPrice = ({
    closeMode,
    modalParams: {
        wholesaleGoodsId,
        goodsSkuList = [],
        goodsName = '',
        onChannel = '',
        channelDistributionMethods = { code: '' },
        limitNumber,
        limitNumberByDaily,
        limitNumberType,
        leftOnDay,
        autoOffPeriod
    } = {},
    getChannelEnum,
    channelEnum,
    getOnChannelDistributionMethodsEnum,
    channelDistributionMethodsEnum,
    getLimitNumberTypeEnum,
    limitNumberTypeEnum,
}) => {

    const [loading, setLoading] = useState(false);
    const [onChannels, setOnChannels] = useState(onChannel.split(',')); // 分销渠道
    const [onMethods, setOnMethods] = useState(channelDistributionMethods.code); // 分销方式
    const [isLimit, setIsLimit] = useState(limitNumberType); // 限购设置
    const [limitNum, setLimitNum] = useState(limitNumberType == 'EVERYBODY_DAILY' ? limitNumberByDaily : (limitNumberType == 'EVERYBODY' ? limitNumber : undefined)); // 限购数量
    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        getChannelEnum();
        getOnChannelDistributionMethodsEnum();
        getLimitNumberTypeEnum();
        setDataList(goodsSkuList.map(item => {
            return { ...item, retailPrice: regFenToYuan(item.retailPrice), distributionIntegralReward: item.distributionIntegralReward, shareRate: regFenToYuan(item.shareRate) };
        }));
    }, []);

    const onSubmit = () => {
        const skuInfoList = dataList.map(item => {
            return { wholesaleSkuId: item.wholesaleSkuId, retailPrice: regYuanToFen(item.retailPrice), distributionIntegralReward: item.distributionIntegralReward, shareRate: regYuanToFen(item.shareRate) };
        });

        const query = {
            wholesaleGoodsId,
            limitNumberType: isLimit,
            limitNumber: (isLimit == 'EVERYBODY' || isLimit == 'EVERYBODY_DAILY') ? limitNum : undefined,
            onChannels,
            channelDistributionMethods: onMethods,
            skuInfoList
        };

        setLoading(true);
        http('/goods/wholesale/updateDistribution', query, 'POST')
            .then(() => {
                message.success('保存成功');
                setLoading(false);
                closeMode(true);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    const priceCell = (text, rowValue) => {
        return <InputNumber
            value={text}
            precision={2}
            min={0}
            onChange={value => {
                setDataList(dataList.map(item => {
                    if (item.skuId === rowValue.skuId) {
                        return { ...item, retailPrice: value };
                    }
                    return { ...item };
                }));
            }}
        />;
    };


    const rewardCell = (text, rowValue) => {
        return <InputNumber
            value={text}
            precision={0}
            min={0}
            onChange={value => {
                setDataList(dataList.map(item => {
                    if (item.skuId === rowValue.skuId) {
                        return { ...item, distributionIntegralReward: value };
                    }
                    return { ...item };
                }));
            }}
        />;
    };

    const shareRateCell = (text, rowValue) => {
        return <InputNumber
            value={text}
            precision={2}
            min={0}
            max={100}
            onChange={value => {
                setDataList(dataList.map(item => {
                    if (item.skuId === rowValue.skuId) {
                        return { ...item, shareRate: value };
                    }
                    return { ...item };
                }));
            }}
        />;
    };

    const columns = () => {
        let cols = [
            {
                title: '商品图片',
                dataIndex: 'imgUrl',
                key: 'imgUrl',
                align: 'left',
                width: cellWidth.small,
                render: ImgCell
            }, {
                title: '规格属性',
                dataIndex: 'spec',
                key: 'spec',
                align: 'left',
                width: cellWidth.time,
                onCell: tooltipStyle,
                render: tooltip
            }, {
                title: '结算价（元）',
                dataIndex: 'wholesalePrice',
                key: 'wholesalePrice',
                align: 'left',
                width: cellWidth.small,
                render: priceFenToYuanCell
            }, {
                title: '销售价（元）',
                dataIndex: 'salePrice',
                key: 'salePrice',
                align: 'left',
                width: cellWidth.small,
                render: priceFenToYuanCell
            }, {
                title: '渠道价（元）',
                dataIndex: 'retailPrice',
                key: '`retailPrice`',
                align: 'left',
                width: cellWidth.normal,
                render: priceCell
            }];

        if (onMethods == 'DISTRIBUTION_GET_INTEGRAL') {
            cols.push({
                title: '积分奖励',
                dataIndex: 'distributionIntegralReward',
                key: '`distributionIntegralReward`',
                align: 'left',
                width: cellWidth.normal,
                render: rewardCell
            });
            cols.push({
                title: '分佣比例',
                dataIndex: 'shareRate',
                key: '`shareRate`',
                align: 'left',
                width: cellWidth.normal,
                render: shareRateCell
            });
        }

        return cols;
    };

    // 样式
    const boxStyle = {
        display: 'flex',
        alignItems: 'center',
        marginTop: '15px'
    };

    return <Modal
        width={1000}
        centered
        bodyStyle={{ padding: '20px' }}
        title={goodsName}
        visible={true}
        confirmLoading={loading}
        onOk={onSubmit}
        onCancel={() => closeMode(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div>
            <div>
                <div style={boxStyle}>
                    <div><Text>上架时间：</Text></div>
                    <div><Text>商家设置当前商品上架 {autoOffPeriod} 天，剩余{leftOnDay}天</Text></div>
                </div>
                <div style={boxStyle}>
                    <div><Text>分销渠道：</Text></div>
                    <div>
                        <Checkbox.Group value={onChannels} onChange={list => setOnChannels(list)}>
                            {
                                channelEnum.map(i => {
                                    return <Checkbox key={i.code} value={i.code}>{i.value}</Checkbox>;
                                })
                            }
                        </Checkbox.Group>
                    </div>
                </div>
                <div style={boxStyle}>
                    <div><Text>分销方式：</Text></div>
                    <div>

                        <Radio.Group value={onMethods} onChange={e => setOnMethods(e.target.value)}>
                            {
                                channelDistributionMethodsEnum.map(i => {
                                    return <Radio key={i.code} value={i.code}>{i.value}</Radio>;
                                })
                            }
                        </Radio.Group>
                    </div>
                </div>
                <div style={boxStyle}>
                    <div><Text>限购设置：</Text></div>
                    <div>
                        <Select style={{ width: '140px' }} value={isLimit} onChange={value => setIsLimit(value)}>
                            {
                                limitNumberTypeEnum.map(i => {
                                    return <Select.Option key={i.code} value={i.code}>{i.value}</Select.Option>;
                                })
                            }
                        </Select>
                        {
                            (isLimit == 'EVERYBODY' || isLimit == 'EVERYBODY_DAILY') && <Fragment>
                                <InputNumber
                                    style={{ width: '140px', margin: '0 10px' }}
                                    placeholder='请输入限购数量'
                                    value={limitNum}
                                    min={0}
                                    max={999}
                                    precision={0}
                                    onChange={value => setLimitNum(value)} />
                                <Text>件</Text>
                            </Fragment>
                        }</div>
                </div>
            </div>
            <div style={{ marginBottom: '20px' }}></div>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.wholesaleSkuId}
                columns={columns()}
                pagination={false}
                dataSource={dataList}
                scroll={{ y: 500 }}
            />
        </div>
    </Modal>;
};

SetPrice.propTypes = {
    closeMode: PropTypes.func,
    modalParams: PropTypes.object,
    getChannelEnum: PropTypes.func,
    channelEnum: PropTypes.array,
    getOnChannelDistributionMethodsEnum: PropTypes.func,
    channelDistributionMethodsEnum: PropTypes.array,
    getLimitNumberTypeEnum: PropTypes.func,
    limitNumberTypeEnum: PropTypes.array,
};

export default connect(state => ({
    channelEnum: state.wholesaleGoods.channelEnum,
    channelDistributionMethodsEnum: state.wholesaleGoods.channelDistributionMethodsEnum,
    limitNumberTypeEnum: state.wholesaleGoods.limitNumberTypeEnum,
}), { getChannelEnum, getOnChannelDistributionMethodsEnum, getLimitNumberTypeEnum })(SetPrice);