/**
 * 商品管理 - 标签异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';


export const getProductListData = function* () {
    yield takeEvery(T.GET_PRODUCT_LISTDATA, function* requestData(action) {
        try {
            let result = yield http('/goods/goods/adminGoodsList', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_LISTDATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductStatus = function* () {
    yield takeEvery(T.GET_PRODUCT_STATUS, function* requestData(action) {
        try {
            let result = yield http('/enum/GoodsStateEnum', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_STATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductUsername = function* () {
    yield takeEvery(T.GET_PRODUCT_USERNAME, function* requestData(action) {
        try {
            let result = yield http('/merchant/info/listMerchantIdAndName', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_USERNAME, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductBusinessType = function* () {
    yield takeEvery(T.GET_PRODUCT_BUSINESSTYPE, function* requestData(action) {
        try {
            let result = yield http('/enum/GoodsTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_BUSINESSTYPE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductLabelSele = function* () {
    yield takeEvery(T.GET_PRODUCT_LABELSELE, function* requestData(action) {
        try {
            let result = yield http('/goods/tag/listTagItem', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_LABELSELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductClassifySele = function* () {
    yield takeEvery(T.GET_PRODUCT_CLASSIFYSELE, function* requestData(action) {
        try {
            let result = yield http('/goods/category/linkage', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_CLASSIFYSELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductDetail = function* () {
    yield takeEvery(T.GET_PRODUCT_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/goods/goods/infoGoods', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCT_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductRecommend = function* () {
    yield takeEvery(T.GET_PRODUCT_RECOMMEND, function* requestData(action) {
        try {
            let result = yield http('/goods/goods/findRecommendGoods', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_PRODUCT_RECOMMEND, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


