import React from 'react';
import PropTypes from 'prop-types';
import styles from './Table.module.scss';
import { Table } from 'antd';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

export default function PayAmountTable({ renderData }) {

    //表头
    const _columns = () => {
        return [
            {
                title: '商品名称',
                dataIndex: 'goodsName',
                key: 'goodsName',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '商品分类',
                dataIndex: 'goodsFullCategoryName',
                key: 'goodsFullCategoryName',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '商品品牌',
                dataIndex: 'brandName',
                key: 'brandName',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '秀场销售量',
                dataIndex: 'saleNumShow',
                key: 'saleNumShow',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '秀场销售金额（元）',
                dataIndex: 'saleMoneyShow',
                key: 'saleMoneyShow',
                width: 140,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '直播销售金额（元）',
                dataIndex: 'saleMoneyBroadcast',
                key: 'saleMoneyBroadcast',
                width: 140,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '直播占比（%）',
                dataIndex: 'broadcastRatio',
                key: 'broadcastRatio',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '视频销售金额（元）',
                dataIndex: 'saleMoneyVideo',
                key: 'saleMoneyVideo',
                width: 140,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '视频占比（%）',
                dataIndex: 'videoRatio',
                key: 'videoRatio',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '图文销售金额（元）',
                dataIndex: 'saleMoneyArticle',
                key: 'saleMoneyArticle',
                width: 140,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '图文占比（%）',
                dataIndex: 'articleRatio',
                key: 'articleRatio',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '成功退款金额（元）',
                dataIndex: 'refundMoney',
                key: 'refundMoney',
                width: 140,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '售后占比（%）',
                dataIndex: 'saleAfterRatio',
                key: 'saleAfterRatio',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            }
        ];
    };

    //渲染
    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                columns={_columns()}
                dataSource={renderData}
                pagination={false}
                scroll={{ x: 1820, y: true }}
            />
        </div>
    );
}

//props校验
PayAmountTable.propTypes = {
    renderData: PropTypes.array.isRequired,//表格数据元
};