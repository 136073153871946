import * as Audit from './actionTypes';

export const getOperationAuditList = (params) => ({
    type: Audit.GET_OPERATIONAUDIT_LIST,
    payload: params
});

export const getOperationAuditStatus = () => ({
    type: Audit.GET_OPERATIONAUDIT_STATUS,
    payload: {}
});

export const getOperationAuditDetail = (id) => ({
    type: Audit.GET_OPERATIONAUDIT_DETAIL,
    payload: { id }
});