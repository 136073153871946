import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker, XInputNum } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/starvalueshop/starshop/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
        orderNumber: '', // 订单号
        contractNumber: '', // 合同号
        memberNumber: '', // 会员账号
        supplier: {}, // 供应商
        startDate: null, // 开始时间
        endDate: null, // 结束时间
        convertMode: {}, // 兑换方式
        orderStatus:{}, // 订单状态
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getSupplierList, getConvertWays, getStarGoodsConvertModeEnum, getOrderStatusEnum } = this.props;
        keepSecondNavBreadcrumb();
        getSupplierList();
        getConvertWays();
        getStarGoodsConvertModeEnum();
        getOrderStatusEnum();
    }

    // 查询
    _searchHandle = (useCache) => {
        this.setState({ isCheck: true });
        const { pageNum, pageSize, orderNumber, memberNumber, contractNumber, supplier, startDate, endDate, convertMode, orderStatus } = this.state;
        this.searchCache = {
            orderId: orderNumber, // 订单号
            ctrId: contractNumber, // 合同号
            userId: memberNumber, // 会员账号
            supplierId: supplier.supplierId, // 供应商
            startDate, // 开始时间
            endDate, // 结束时间
            convertMode: convertMode.code, // 兑换方式
            orderState: orderStatus.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getRecordList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置 
    _resetHandle = () => {
        this.setState({ orderNumber: '', memberNumber: '', contractNumber: '', supplier: {}, startDate: null, endDate: null, convertMode: {}, orderStatus:{} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getRecordList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 页面操作
    _tableAction = (key, type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: {
                        pathname: '/home/starValueShop/forRecord/orderdetail',
                        search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                    }
                });
                history.push({
                    pathname: '/home/starValueShop/forRecord/orderdetail',
                    search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                });
                break;
        }
    }

    render() {
        const { recordList, supplierList, convertWayList, starGoodsConvertModeEnum, orderStatusEnum } = this.props;
        const { orderNumber, memberNumber, contractNumber, supplier, startDate, endDate, convertMode, orderStatus } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInputNum
                                label='订单号'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='orderNumber'
                                value={orderNumber}
                                min={0}
                                precision={0}
                                maxLength={16}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInputNum
                                label='合同号'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='contractNumber'
                                value={contractNumber}
                                min={0}
                                precision={0}
                                maxLength={16}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInputNum
                                label='会员账号'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='memberNumber'
                                value={memberNumber}
                                min={0}
                                precision={0}
                                maxLength={16}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='供应商'
                                placeholder='请选择'
                                renderData={supplierList}
                                dataIndex='supplierName'
                                keyIndex='supplierId'
                                bindThis={this}
                                bindName='supplier'
                                value={supplier.supplierName}
                                showSearch={true}
                                isOptionBindID={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='购买日期'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat={true}
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='兑换方式'
                                placeholder='请选择'
                                renderData={starGoodsConvertModeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='convertMode'
                                value={convertMode.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='订单状态'
                                placeholder='请选择'
                                renderData={orderStatusEnum}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='orderStatus'
                                value={orderStatus.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent
                        paginationChange={this._paginationChange}
                        renderData={recordList}
                        tableAction={this._tableAction}
                    />
                </div>
            </KeepAlive>
        );
    }

}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getRecordList: PropTypes.func, // 表格数据源的Action
    recordList: PropTypes.object, // 兑换记录列表数据源
    getSupplierList: PropTypes.func, // 供应商下拉Action
    supplierList: PropTypes.array, // 供应商下拉
    getConvertWays: PropTypes.func, // 兑换方式下拉Action
    convertWayList: PropTypes.array, // 兑换方式
    getStarGoodsConvertModeEnum: PropTypes.func,
    starGoodsConvertModeEnum: PropTypes.array,
    getOrderStatusEnum: PropTypes.func,
    orderStatusEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
    recordList: state.starShop.recordList,
    supplierList: state.starShop.supplierList,
    convertWayList: state.starShop.convertMode,
    starGoodsConvertModeEnum: state.starShop.getStarGoodsConvertModeEnum,
    orderStatusEnum: state.starShop.getOrderStatusEnum
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions, })(Main);