import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, tooltipClick } from '@/components/TableCell';
import ButtonPerssion from '@/routes/ButtonPerssion';

export default class TableContent extends Component {

	static propTypes = {
		tableAction: PropTypes.func,
		paginationChange: PropTypes.func,
		renderData: PropTypes.object,
	};

	static defaultProps = {
		renderData: { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 }
	}

	_columns = () => [
		{
			title: '手机号码',
			dataIndex: 'mobilePhone',
			key: 'mobilePhone',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: (text, record) => tooltipClick(text, () => {
				this.props.tableAction(record.id, 'detail', record);
			})
		},
		{
			title: '服务商类型',
			dataIndex: 'providerRole.value',
			key: 'providerRole.value',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '所属区域',
			dataIndex: 'belongsArea',
			key: 'belongsArea',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '真实姓名',
			dataIndex: 'realName',
			key: 'realName',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '状态',
			dataIndex: 'auditType',
			key: 'auditType',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: (text) => tooltip(text.value)
		},
		{
			title: '申请日期',
			dataIndex: 'gmtModified',
			key: 'gmtModified',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作人',
			dataIndex: 'operationName',
			key: 'operationName',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			width: '10%',
			render: (id, record) => this._tableAction(id, record)
		},
	];

	// 表格操作
	_tableAction = (id, record) => {
		const { tableAction } = this.props;
		const { auditType: { code = '' } = {} } = record;
		if (code === 'APPLY' ||
			code === 'CREATE' ||
			code === 'REGISTERED' ||
			code === 'VERIFIED_REAL_NAME' ||
			code === 'SIGNED_AGREEMENT' ||
			code === 'NOT_EMAIL') {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(id, 'detail', record)}>详情</div>
					<ButtonPerssion code='auditmanage/serviceaudit/cs'>
						<div className={styles.item} onClick={() => tableAction(id, 'audit', record)}>初审</div>
					</ButtonPerssion>
				</div>
			);
		} else if (code === 'FIRST_APPROVAL') {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(id, 'detail', record)}>详情</div>
					<ButtonPerssion code='auditmanage/serviceaudit/fs'>
						<div className={styles.item} onClick={() => tableAction(id, 'audit', record)}>复审</div>
					</ButtonPerssion>
				</div>
			);
		} else {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(id, 'detail', record)}>详情</div>
				</div>
			);
		}

	};

	render() {
		const { renderData, paginationChange } = this.props;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey={record => record.id}
					columns={this._columns()}
					pagination={false}
					dataSource={renderData.dataList}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}