import { combineReducers } from 'redux';
import {
    activeProductAuditList, activeProductAuditStatus,
    activeProductAuditDetail, activeProductAuditInfo,
    activeProductAuditShopSele, activeProductAuditExitList,
    activeProductAuditExitInfo
} from './reducer';

export default combineReducers({
    activeProductAuditList,
    activeProductAuditStatus,
    activeProductAuditDetail,
    activeProductAuditInfo,
    activeProductAuditShopSele,
    activeProductAuditExitList,
    activeProductAuditExitInfo
});