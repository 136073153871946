import * as Audit from './actiontypes';

/**
 * 存储'主播封面审核列表'数据源
 * @param {*} state 
 * @param {*} action 
 */

 const anchorCoverListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
 };

export const anchorCoverList = (state = anchorCoverListInital, action) => {
    switch(action.type){
        case Audit.SET_ANCHORCOVER_LIST:
            return action.payload;
        default:
            return state;
    }
};
export const anchorCoverSelect = (state = [], action) => {
    switch(action.type){
        case Audit.SET_ANCHORCOVER_SELECT:
            return action.payload;
        default:
            return state;
    }
};