//获取/保存订单列表
export const GET_TRANSCATION_LIST = 'ORDERMANAGE/GET_TRANSCATION_LIST';
export const SET_TRANSCATION_LIST = 'ORDERMANAGE/SET_TRANSCATION_LIST';

//获取/保存订单列表
export const GET_TRANSCATIONSERVICE_LIST = 'ORDERMANAGE/GET_TRANSCATIONSERVICE_LIST';
export const SET_TRANSCATIONSERVICE_LIST = 'ORDERMANAGE/SET_TRANSCATIONSERVICE_LIST';
//获取/保存费用明细
export const GET_COSTSERVICE_LIST = 'ORDERMANAGE/GET_COSTSERVICE_LIST';
export const SET_COSTSERVICE_LIST = 'ORDERMANAGE/SET_COSTSERVICE_LIST';
// 获取商户
export const GET_MERCHANT_LIST = 'ORDERMANAGE/GET_MERCHANT_LIST';
export const SET_MERCHANT_LIST = 'ORDERMANAGE/SET_MERCHANT_LIST';
