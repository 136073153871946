/**
 * 任务2072，魅力值打赏设置（仅做页面，暂不联调接口）
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Radio, InputNumber, Checkbox, Button, message } from 'antd';
import { setLocalStorage, getLocalStorage } from '@/assets/js/storage';

class RewardConfig extends React.Component {

    state = {
        radioValue: 3,
        number1: undefined,
        number2: undefined,
        number3: undefined,
        number4: undefined,
        number5: undefined,
        number6: undefined,
        checkbox1: false,
        checkbox2: false,
    }

    componentDidMount() {
        let localState = getLocalStorage('RewardConfig') && JSON.parse(getLocalStorage('RewardConfig'));
        this.setState({ ...localState });
    }

    _saveHandle = () => {
        message.success('保存成功');
        setLocalStorage('RewardConfig', this.state);
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.box}>
                    <div className={styles.title}>充值账户实名</div>
                    <div className={styles.config}>
                        <Radio.Group onChange={(e) => this.setState({ radioValue: e.target.value })} value={this.state.radioValue}>
                            <Radio style={{ marginRight: '30px' }} value={1}>部分开启</Radio>
                            <Radio style={{ marginRight: '30px' }} value={2}>全部开启</Radio>
                            <Radio style={{ marginRight: '30px' }} value={3}>关闭</Radio>
                        </Radio.Group>
                    </div>
                    <div className={styles.ps}>PS：部分开启时，需到会员列表设置开启对象，全部开启时，所有魅力值充值用户强制实名认证，未实名认证用户不可充值</div>
                </div>
                <div className={styles.box}>
                    <div className={styles.title}>礼物限制设置</div>
                    <div className={styles.config}>
                        单个礼物最低设置不小于等于<InputNumber
                            className={styles.inputNumber}
                            min={0}
                            max={100}
                            onChange={(value) => this.setState({ number1: value })}
                            value={this.state.number1}
                        />魅力值，最高设置不大于等于<InputNumber
                            className={styles.inputNumber}
                            min={0}
                            max={20000}
                            onChange={(value) => this.setState({ number2: value })}
                            value={this.state.number2}
                        />魅力值
                    </div>
                    <div className={styles.ps}>PS：该设置影响后台创建礼物的配置，未填写或设置为0时等于不做限制</div>
                    <div className={styles.config}>
                        每个账户每日最多可打赏给别人不大于等于<InputNumber
                            className={styles.inputNumber}
                            min={0}
                            max={999999}
                            onChange={(value) => this.setState({ number3: value })}
                            value={this.state.number3}
                        />魅力值，每月最多打赏给别人不大于等于<InputNumber
                            className={styles.inputNumber}
                            min={0}
                            max={99999999}
                            onChange={(value) => this.setState({ number4: value })}
                            value={this.state.number4}
                        />魅力值
                    </div>
                    <div className={styles.ps}>PS：该设置影响用户每天、每月最多可以使用多少魅力值帐户，超过限制时不可再进行打赏，APP相应提示，未填写或设置为0时等于不做限制</div>
                    <div className={styles.config}>
                        当用户每日的打赏达到日限制的<InputNumber
                            className={styles.inputNumber}
                            min={0}
                            max={100}
                            onChange={(value) => this.setState({ number5: value })}
                            value={this.state.number5}
                        />%，当用户每月的打赏达到月限制的<InputNumber
                            className={styles.inputNumber}
                            min={0}
                            max={100}
                            onChange={(value) => this.setState({ number6: value })}
                            value={this.state.number6}
                        />%，以短信验证方式解除限制
                    </div>
                    <div className={styles.ps}>PS：该设置未填写或为0时不提醒，当打赏数量超过设置，再次打赏则以需短信提醒验证后方可再次打赏</div>
                </div>
                <div className={styles.box}>
                    <div className={styles.title}>魅力值返还设置</div>
                    <div className={styles.config}>
                        <Checkbox
                            style={{ marginBottom: '10px' }}
                            checked={this.state.checkbox1}
                            onChange={(e) => this.setState({ checkbox1: e.target.checked })}
                        >当主播被判为违规封禁时，返还用户本场打赏的礼物</Checkbox>
                        <br />
                        <Checkbox
                            style={{ marginBottom: '10px' }}
                            checked={this.state.checkbox2}
                            onChange={(e) => this.setState({ checkbox2: e.target.checked })}
                        >当主播被判为违规封禁时，返还用户所有打赏的礼物</Checkbox>
                    </div>
                    <div className={styles.ps}>PS：选中时，当主播被封禁，用户打赏的礼物将原路返回</div>
                </div>
                <Button type='primary' style={{ width: '100px' }} onClick={this._saveHandle} >保存</Button>
            </div>
        );
    }
}

RewardConfig.propTypes = {

};

export default RewardConfig;