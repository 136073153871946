//获取/保存售后列表
export const GET_SALELIST_LIST = 'SALE/GET_SALELIST_LIST';
export const SET_SALELIST_LIST = 'SALE/SET_SALELIST_LIST';
//获取/保存售后详情仅退款
export const GET_SALELIST_DETAIL_ONLYMONEY = 'SALE/GET_SALELIST_DETAIL_ONLYMONEY';
export const SET_SALELIST_DETAIL_ONLYMONEY = 'SALE/SET_SALELIST_DETAIL_ONLYMONEY';
//获取/保存售后详情退货退款
export const GET_SALELIST_DETAIL_GOODSMONEY = 'SALE/GET_SALELIST_DETAIL_GOODSMONEY';
export const SET_SALELIST_DETAIL_GOODSMONEY = 'SALE/SET_SALELIST_DETAIL_GOODSMONEY';
//获取/保存售后详情换货
export const GET_SALELIST_DETAIL_CHANGEGOODS = 'SALE/GET_SALELIST_DETAIL_CHANGEGOODS';
export const SET_SALELIST_DETAIL_CHANGEGOODS = 'SALE/SET_SALELIST_DETAIL_CHANGEGOODS';
// 受理详情
export const GET_PROCESS_DETAIL = 'SALE/GET_PROCESS_DETAIL';
export const SET_PROCESS_DETAIL = 'SALE/SET_PROCESS_DETAIL';
// 售后状态
export const GET_SALESTATUS = 'SALE/GET_SALESTATUS';
export const SET_SALESTATUS = 'SALE/SET_SALESTATUS';
// 售后类型
export const GET_SALETYPE = 'SALE/GET_SALETYPE';
export const SET_SALETYPE = 'SALE/SET_SALETYPE';
