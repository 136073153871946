/**
 * 重置密码弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, Input, Form, message } from 'antd';
import styles from './ResetPassword.module.scss';
import md5 from 'js-md5';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

class ResetPassword extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
    }

    // 点击确认
    _handleOk = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const { id } = this.props;
                const data = {
                    loginPwd: md5(fieldsValue.password).toUpperCase(),
                    id
                };
                http('/admin/empl/updateEmplPassword', data, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('修改成功！');
                        this.setState({
                            visible: false,
                        });
                        this.props.closeMode(true);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
            }
        });
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('两次输入的密码不一致!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    render() {
        const { visible, confirmLoading } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={600}
                centered
                title='修改密码'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon={false}
                            htmlFor='password'
                            label={<span>新密码</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '请输入密码' },
                                {
                                    validator: this.validateToNextPassword,
                                }],
                            })(
                                <Input.Password placeholder='请输入密码' style={{ marginLeft: 6 }} />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='confirm'
                            label={<span>确认新密码</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('confirm', {
                                rules: [{ required: true, message: '请输入确认密码' },
                                {
                                    validator: this.compareToFirstPassword,
                                }],
                            })(
                                <Input.Password placeholder='请再次输入密码' style={{ marginLeft: 6 }} />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

ResetPassword.propTypes = {
    id: PropTypes.number, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(ResetPassword);// 校验
export default connect(null, null)(ForgotProducDetails);