import React, { useEffect, useState } from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';

const TableContent = ({
    renderData: {
        dataList = [],
        pageNum = 1,
        pageSize = 30,
        resultTotal = 30
    } = {},
    paginationChange,
    scrollY = '',
    tableAction,
    batchKeys = []
}) => {

    const [selectedkeys, setSelectedKeys] = useState([]);

    useEffect(() => {
        setSelectedKeys(batchKeys);
    }, [batchKeys]);

    const tableHandle = (key, item) => {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction('detail', item)}>查看详情</div>
            </div>
        );
    };

    const columns = [
        {
            title: '门店账号',
            dataIndex: 'merchantLoginName',
            key: 'merchantLoginName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '门店名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '营业执照名称',
            dataIndex: 'businessLicenseName',
            key: 'businessLicenseName',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '企业类型',
            dataIndex: 'userType.value',
            key: 'userType.value',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '门店类型',
            dataIndex: 'merchantType.value',
            key: 'merchantType.value',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '提交时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'status.value',
            key: 'status.value',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '备注说明',
            dataIndex: 'remark',
            key: 'remark',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'merchantId',
            key: 'merchantId',
            align: 'center',
            width: cellWidth.normal,
            fixed: 'right',
            render: tableHandle
        },
    ];

    // 表格复选输入
    const rowSelection = {
        selectedRowKeys: selectedkeys,
        onChange: (keys) => {
            tableAction('batch', keys);
        },
    };

    // 清空表格复选框
    const cleanAllSelected = () => {
        tableAction('batch', []);
    };

    return <div className={styles.tableContainer} style={{ height: '100%' }}>
        <XTableSelectionCount selectedNum={selectedkeys.length} onClean={cleanAllSelected} />
        <Table
            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
            rowSelection={rowSelection}
            rowKey={r => r.merchantId}
            columns={columns}
            dataSource={dataList}
            pagination={false}
            scroll={{ x: 'max-content', y: scrollY }}
        />
        <XPagination
            resultTotal={resultTotal}
            pageSize={pageSize}
            pageNum={pageNum}
            onChange={paginationChange}
        />
    </div>;
};

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string,
    batchKeys: PropTypes.array
};
export default TableContent;

