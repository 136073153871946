import React from 'react';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell, tooltipClick } from '@/components/TableCell';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './OrderTable.module.scss';
import { isPlat } from '@/assets/js/authType';

class OrderTable extends React.Component {
    state = {
        selectedRowKeys: [],
    }

    _takeColumns = () => {
        let cols = [
            {
                title: '兑换凭证',
                dataIndex: 'id',
                key: 'id',
                width: cellWidth.normal,
                align: 'center',
                render: (text) => tooltipClick(text, () => this.props.tableAction(text, 'info'))
            },
            {
                title: '账号',
                dataIndex: 'accountTel',
                key: 'accountTel',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '来源',
                dataIndex: 'source.value',
                key: 'source.value',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '兑换特权值',
                dataIndex: 'exchangeIntegral',
                key: 'exchangeIntegral',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '特权值与港元比例',
                dataIndex: 'proportionRmb',
                key: 'proportionRmb',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: (text, item) => tooltip(`${item.currClosingPrice / 100}:${text}`)
            },
            {
                title: '生成凭证价格',
                dataIndex: 'currClosingPrice',
                key: 'currClosingPrice',
                width: cellWidth.normal,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '兑换股数',
                dataIndex: 'exchangeShares',
                key: 'exchangeShares',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '兑换收盘价',
                dataIndex: 'closingPrice',
                key: 'closingPrice',
                width: cellWidth.normal,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: 'RMB与港元比例',
                dataIndex: 'rmbRatioHkd',
                key: 'rmbRatioHkd',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: (text, item) => tooltip(item.rmbRatioHkd ? `${item.rmbRatioHkd / 100}:1` : '-')
            },
            {
                title: '手续费比例(%)',
                dataIndex: 'procedureRatio',
                key: 'procedureRatio',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: priceFenToYuanCell
            },
            {
                title: '手续费(元)',
                dataIndex: 'procedureFee',
                key: 'procedureFee',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: priceFenToYuanCell
            },
            {
                title: '股权溢价收益',
                dataIndex: 'premiumPrice',
                key: 'premiumPrice',
                width: cellWidth.normal,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '状态',
                dataIndex: 'voucherStatus.value',
                key: 'voucherStatus.value',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '凭证生成时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: cellWidth.time,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '兑换收益时间',
                dataIndex: 'premiumDate',
                key: 'premiumDate',
                width: cellWidth.time,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作人',
                dataIndex: 'operateName',
                key: 'operateName',
                width: cellWidth.time,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作',
                dataIndex: 'key',
                key: 'action',
                align: 'center',
                fixed: 'right',
                width: cellWidth.time,
                render: (text, item) => this._renderActionCell(item.id, item)
            }
        ];

        if (!isPlat()) {
            cols.splice(0, 1, {
                title: '兑换凭证',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: tooltip
            });
            cols.pop();
        }
        return cols;
    }

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'info')}>查看详情</div>
                {
                    item.voucherStatus.code === 'WAIT_AUDIT' &&
                    <div className={styles.item} onClick={() => tableAction(key, 'audit', item)}>审核</div>
                }
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys) => {
            this.setState({
                selectedRowKeys,
            });
            this.props.tableAction(null, 'batchKeys', selectedRowKeys);
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
        this.props.tableAction(null, 'batchKeys', []);
    };

    render() {
        const { selectedRowKeys } = this.state;
        const { paginationChange, scrollY } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ x: 'max-content', y: scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default XTableHOC(OrderTable, 40);
