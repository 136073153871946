/**
 * 合同协议弹框
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Table, Alert } from 'antd';
import styles from './Fail.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import model from '@/reducers/suppliermanage/purchasecontract/model';

class Fail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
        };
        this.reqParams = { pageNum: 1, pageSize: 20 };
    }

    static propTypes = {
        visible: PropTypes.bool.isRequired, // 是否显示弹框
        closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
        modeParams: PropTypes.object.isRequired,//数据
        getPurchaseFail: PropTypes.func,//获取审核详情
        purchaseFail: PropTypes.object,//详情数据
    }

    componentDidMount() {
        const { modeParams, getPurchaseFail } = this.props;
        getPurchaseFail({ ...this.reqParams, id: modeParams.id });
    }

    _paginationChange = (pageSize, pageNum) => {
        const { modeParams, getPurchaseFail } = this.props;
        getPurchaseFail({ ...this.reqParams, pageSize, pageNum, id: modeParams.id });
    }

    //表头
    _columns = () => {
        return [
            {
                title: '商品货号',
                dataIndex: 'goodsNo',
                key: 'goodsNo',
                width: '20%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '商品编码（SKU）',
                dataIndex: 'skuId',
                key: 'skuId',
                width: '20%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '异常原因',
                dataIndex: 'desc',
                key: 'desc',
                width: '60%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            }
        ];
    }

    //确定取消
    _backHandle = () => {
        this.setState({ visible: false });
        this.props.closeMode(false);
    }

    render() {
        const { purchaseFail } = this.props;
        const { visible, confirmLoading } = this.state;
        return (
            <Modal
                width={1200}
                centered
                title='审核异常'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._backHandle}
                onCancel={this._backHandle}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                footer={false}
            >
                <div className={styles.flexBoxContainer} >
                    <Alert type='error' message='亲，以下商品存在异常，请前往人气值商城操作下架; ' showIcon ></Alert>
                    <div className={`${styles.tableContainer} ${styles.container}`}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            columns={this._columns()}
                            pagination={false}
                            rowKey={record => record.ctrId}
                            dataSource={purchaseFail.dataList}
                            scroll={{ y: true }} />
                        <XPagination
                            resultTotal={purchaseFail.resultTotal}
                            pageSize={purchaseFail.pageSize}
                            pageNum={purchaseFail.pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

//映射数据源
const mapStateToProps = (state) => ({
    purchaseFail: state.purchaseContract.purchaseFail,
});

export default connect(mapStateToProps, { ...model.actions })(Fail);
