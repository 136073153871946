import React from 'react';
import styles from './TableData.module.scss';
import { Tooltip } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';
/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
    {
        title: '历史文件编号',
        dataIndex: 'id',
        key: 'id',
        width: '15%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '商户名称',
        dataIndex: 'merchantName',
        key: 'merchantName',
        width: '15%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '服务说明',
        dataIndex: 'remark',
        key: 'remark',
        width: '20%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '确认人',
        dataIndex: 'modifyUserName',
        key: 'modifyUserName',
        width: '15%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '确认日期',
        dataIndex: 'gmtModified',
        key: 'gmtModified',
        width: '15%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'history',
        key: 'history',
        align: 'center',
        width: '20%',
        render: (id, item) => TableAction(id, item, actionClickHandle)
    },
];

export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    return (data || []).map((v) => {
        v.history = v.id;
        return v;
    });
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};

const TableAction = (id, item, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, item, 'DELETE')}>详情</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, item, 'LISTING')}>查看清单</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, item, 'THESALES')}>销售情况</div>
        </div>
    );
};