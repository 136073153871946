/**
 * 举报管理  Saga集合
 */
import { 
    listUserReportSource,
    reportState,
    reportStop,
    userReportDetails,
} from './userReport/saga';//用户举报
import { 
    reportReasonsList,
    reportShopList,
} from './reportReason/saga';//举报原因
import { 
    showReortList,
    showReortState,
    showReortDetails,
    showReportStop,
} from './showReport/saga';//秀场举报
import { 
    listproductReportSource,
    productReportDetails,
} from './productReport/saga';//商品举报
export default [
    listUserReportSource,//列表
    reportState,//状态
    reportStop,//用户举报禁播，封禁类型和封禁理由数据源(saga)
    userReportDetails,//详情

    reportReasonsList,//用户/秀场举报列表
    reportShopList,//商品举报列表

    showReortList,//秀场举报列表
    showReortState,//秀场分类
    showReortDetails,//秀场分类详情
    showReportStop,//秀场举报禁播，封禁类型和封禁理由数据源(saga)

    listproductReportSource,//商品举报列表
    productReportDetails,//商品举报详情
];
