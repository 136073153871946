import { createModel } from '@/store/tools';

const model = {
    namespace: 'hotCity',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getHotCityList': T('getHotCityList'),
            'getHotCitySelect': T('getHotCitySelect'),
        },
        actions: {
            'getHotCityList': A('getHotCityList'), // 列表
            'getHotCitySelect': A('getHotCitySelect'), // 城市下拉数据
        },
        sagas: {
            'getHotCityList': S('getHotCityList', '/hotel/hotCity/hotCityList'),
            'getHotCitySelect': S('getHotCitySelect', '/hotel/hotCity/searchCity'),
        },
        reducers: {
            'hotCityList': R('getHotCityList', []),
            'citySelect': R('getHotCitySelect', []),
        }
    })
};
export default createModel(model);