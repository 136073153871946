import * as T from './actiontypes';
/** 
 * 社区、视频列表数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {number} tel  手机号码 
 * @param {string} accountNickname 用户昵称
 * @param {number} feedClassId 所属分类id
 * @param {string} feedType 社区类型（图文：GRAPHICS，视频：VIDEO）
 */

export const getGraphicVideoList = (dataload) => ({
    type: T.GET_GRAPHICVIDEOLIST_DATA,
    payload: {
        ...dataload,
    }
});
/** 
 * 直播列表数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {number} tel  手机号码 
 * @param {string} accountNickname 用户昵称
 * @param {number} broadcasClassId 所属分类id
 */

export const getLiveList = (dataload) => ({
    type: T.GET_LIVELIST_DATA,
    payload: {
        ...dataload,
    }
});

/**
 * 封禁类型下拉框'数据源(saga)-直播、视频、社区
 * @param 暂无
 */
export const getBannedtypeDropDown = (dataload) => ({
    type: T.GET_BANNEDTYPE_DATA,
    payload: {
        ...dataload,
    }
});