import * as Audit from './actionTypes';

export const getActiveProductAuditList = (params = {}) => ({
    type: Audit.GET_ACTIVEPRODUCTAUDIT_LIST,
    payload: params
});

export const getActiveProductAuditStatus = () => ({
    type: Audit.GET_ACTIVEPRODUCTAUDIT_STATUS,
    payload: {}
});

export const getActiveProductAuditDetail = (params = {}) => ({
    type: Audit.GET_ACTIVEPRODUCTAUDIT_DETAIL,
    payload: params
});

export const getActiveProductAuditInfo = (params = {}) => ({
    type: Audit.GET_ACTIVEPRODUCTAUDIT_INFO,
    payload: params
});

export const getActiveProductAuditShopSele = (params = {}) => ({
    type: Audit.GET_ACTIVEPRODUCTAUDIT_SHOPSELE,
    payload: params
});

export const getActiveProductAuditExitList = (params = {}) => ({
    type: Audit.GET_ACTIVEPRODUCTAUDIT_EXITLIST,
    payload: params
});

export const getActiveProductAuditExitInfo = (params = {}) => ({
    type: Audit.GET_ACTIVEPRODUCTAUDIT_EXITINFO,
    payload: params
});


