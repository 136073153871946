import { createStore, applyMiddleware } from 'redux';
// import createLogger from 'redux-logger';
import promiseMiddleware from 'redux-promise';
import reducers from '../reducers';
import createSagaMiddleware from 'redux-saga';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootSaga from './sagas';

// 设置redux状态缓存
const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2
};
const myPersistReducer = persistReducer(persistConfig, reducers);
// 设置saga中间件
const sagaMiddleware = createSagaMiddleware();
// 配置中间件
const middleware = [promiseMiddleware, sagaMiddleware]; // createLogger
const store = createStore(myPersistReducer, applyMiddleware(...middleware));
// 执行saga文件
sagaMiddleware.run(rootSaga);

export default store;