/**
 * 【免单看板页】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { message } from 'antd';
import http from '@/assets/api/http';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getMerchantList } from '@/reducers/ordermanage/transcation/actions';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker, showConfirm, XTabNav, XInputNum } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import PayDetail from './modal/PayDetail';
import NewModal from './modal/NewModal';
import EditModal from './modal/EditModal';
import FreeTableContent from './components/FreeTableContent';
import AutoTableContent from './components/AutoTableContent';
import { getFreeOrderList, getFreeOrderStatus, getFreeProductList, getAutoFreeOrderList, getAutoFreeOrderStatus } from '@/reducers/ordermanage/freeOrder/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { bind } from 'lodash-decorators/utils';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expandBoolean: false, // 是否展开
            tel: null, // 会员手机号
            orderId: null, // 订单号
            orderState: {}, // 打款状态
            State: {}, // 状态
            shopName: null, //所属店铺
            startTime: null, // 下单时间开始日期
            endTime: null, // 下单结束日期
            goodsName: null, // 商品名称
            goodsNo: null, // 商品货号
            activityTitle: null, // 活动名称
            ruleName: null, // 规则名称
            startFreeOrderTime: null, // 免单开始时间
            endFreeOrderTime: null, // 免单结束时间
            pageNum: 1,
            pageSize: 10,
            tab: [
                { id: 1, label: '全部免单' },
                { id: 2, label: '免单商品' },
                { id: 3, label: '免单自动打款规则' },
            ],
            curTabID: 1,  // 当前显示的Tab页ID
            userState: {},
            payId: '',
        };
        this.searchCache = { pageNum: 1, pageSize: 20 }; // 查询缓存
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getFreeOrderList({ pageNum, pageSize });
        // 打款状态
        this.props.getFreeOrderStatus();
        // 商户
        this.props.getMerchantList();
        // 打款状态
        this.props.getAutoFreeOrderStatus();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { ruleName, State, userState, activityTitle, curTabID, tel, orderId, orderState, startTime, endTime, goodsName, goodsNo, pageNum, pageSize, shopName, startFreeOrderTime, endFreeOrderTime } = this.state;
        if (curTabID === 1) {
            this.searchCache = {
                tel,
                orderId,
                goodsName,
                goodsNo,
                merchantId: userState.merchantId,
                startFreeOrderTime,
                endFreeOrderTime,
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                state: orderState.key,
                startTime,
                endTime,
            };
            this.props.getFreeOrderList(this.searchCache);
        } else if (curTabID === 2) {
            this.searchCache = {
                goodsName,
                goodsNo,
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                shopName,
                activityTitle,
            };
            this.props.getFreeProductList(this.searchCache);
        } else {
            this.searchCache = {
                ruleName,
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                effectiveState: State.key,
            };
            this.props.getAutoFreeOrderList(this.searchCache);
        }

        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            tel: '', // 会员手机号
            orderId: '', // 订单号
            orderState: {}, // 打款状态
            State: {}, // 状态
            startTime: null, // 下单时间开始日期
            endTime: null, // 下单结束日期
            goodsName: '', // 商品名称
            goodsNo: '', // 商品货号
            ruleName: null, // 规则名称
            startFreeOrderTime: null, // 免单开始时间
            endFreeOrderTime: null, // 免单结束时间
            pageNum: 1,
            pageSize: 10,
            shopName: '',
            activityTitle: '',
            userState: {},
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        // this.props.getFreeOrderList({ pageNum: 1, pageSize: 10 });
    }

    _tableAction = (key, type, record) => {
        const { payId } = this.state;
        if (type === 'pay' && payId !== record.id) {
            showConfirm('是否进行免单打款! 确认打款后，系统将金额原路返回.', '', () => {
                const { tel, orderId, orderState, startTime, endTime, goodsName, goodsNo, pageNum, pageSize } = this.state;
                const param = {
                    tel,
                    orderId,
                    goodsName,
                    goodsNo,
                    pageNum,
                    pageSize,
                    state: orderState.key,
                    startTime,
                    endTime,
                };
                this.setState({ payId: record.id });
                http('/marketing/activityInfo/pay', { id: record.id }, 'POST')
                    .then(() => {
                        message.success('打款成功。');
                        this.props.getFreeOrderList(param);
                    }).catch((reject) => {
                        message.error(reject.message);
                        this.props.getFreeOrderList(param);
                        this.setState({ payId: '' });
                    });
            });
        } else if (type === 'pay' && payId === record.id) {
            message.warn('不可重复打款!');
            return false;
        } else if (type === 'payDetail') {
            this.setState({ currentOpenMode: 'payDetail', openModeParam: record });
        } else if (type === 'edit') {
            this.setState({ currentOpenMode: 'edit', openModeParam: record });
        } else if (type === 'switch') {
            http('/marketing/activityInfo/updateFreeOrderRuleState', { freeOrderRuleId: key, effectiveState: record ? 'ON' : 'OFF' }, 'POST')
                .then(() => {
                    message.success('修改成功!');
                    this._searchHandle('useCache');
                }).catch((reject) => {
                    message.error(reject.message);
                });
        } else if (type === 'del') {
            showConfirm('是否确定删除', '', () => {
                http('/marketing/activityInfo/delFreeOrderRule', { freeOrderRuleId: record.freeOrderRuleId }, 'POST')
                    .then(() => {
                        message.success('删除成功!');
                        this._searchHandle('useCache');
                    }).catch((reject) => {
                        message.error(reject.message);
                    });
            }, () => { });
        }

    }
    //打开弹窗
    _renderMode = () => {
        const { openModeParam } = this.state;
        switch (this.state.currentOpenMode) {
            case 'payDetail':
                return <PayDetail title='打款详情' visible={true}
                    data={openModeParam}
                    closeMode={this._closeMode} />;
            case 'create':
                return <NewModal title='免单自动打款规则' visible={true} refresh={() => this._searchHandle('useCache')}
                    closeMode={this._closeMode} />;
            case 'edit':
                return <EditModal title='免单自动打款规则' visible={true} data={openModeParam || {}} refresh={() => this._searchHandle('useCache')}
                    closeMode={this._closeMode} />;
            default:
                return null;
        }
    }
    // 新建
    _createMode = () => {
        this.setState({ currentOpenMode: 'create' });
    }
    // 关闭弹窗
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }
    // 分页
    _paginationChange = (pageSize, pageNum) => {
        // this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.setState({
            pageSize,
            pageNum,
        }, () => {
            this._searchHandle('useCache');
        });
    }
    //tab切换
    _tabChangeHandle = tabID => {
        this.setState({ curTabID: tabID }, () => {
            this._searchHandle('useCache');
        });
    }
    render() {
        const { tel, orderId, orderState, State, startTime, endTime, goodsName, goodsNo, activityTitle, shopName, userState, ruleName, startFreeOrderTime, endFreeOrderTime } = this.state;
        const { autoFreeOrderStatus, freeOrderList, freeProductList, autoFreeOrderList, merchantList: { merchantIdAndNames } } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div style={{ marginBottom: 10 }}>
                        <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    </div>
                    {this.state.curTabID === 1 ?
                        <div className={styles.flexBoxContainer}>
                            <SearchBox>
                                <SearchItem>
                                    <XInput
                                        label='会员手机号'
                                        placeholder='请输入会员手机号'
                                        value={tel}
                                        bindThis={this}
                                        bindName='tel'
                                    />
                                </SearchItem>
                                <SearchItem>
                                    <XInputNum
                                        label='订单编号'
                                        placeholder='请输入订单编号'
                                        value={orderId}
                                        bindThis={this}
                                        bindName='orderId'
                                        min={0}
                                        precision={0}
                                        maxLength={16}
                                    />
                                </SearchItem>
                                <SearchItem>
                                    <XSelect
                                        label='打款状态'
                                        placeholder='请选择打款状态'
                                        renderData={this.props.freeOrderStatus}
                                        dataIndex='value'
                                        keyIndex='key'
                                        value={orderState.value}
                                        showSearch={true}
                                        bindThis={this}
                                        bindName='orderState'
                                    />
                                </SearchItem>
                                <SearchItem>
                                    <XInput
                                        label='商品名称'
                                        placeholder='请输入商品名称'
                                        value={goodsName}
                                        bindThis={this}
                                        bindName='goodsName'
                                    />
                                </SearchItem>
                                <SearchItem>
                                    <XInput
                                        label='商品货号'
                                        placeholder='请输入商品货号'
                                        value={goodsNo}
                                        bindThis={this}
                                        bindName='goodsNo'
                                    />
                                </SearchItem>
                                <SearchItem>
                                    <XSelect
                                        label='商户'
                                        placeholder='请选择商户'
                                        renderData={merchantIdAndNames || []}
                                        dataIndex='merchantName'
                                        keyIndex='merchantId'
                                        value={userState.merchantName}
                                        showSearch={true}
                                        bindThis={this}
                                        bindName='userState'
                                        isOptionBindID={true}
                                    />
                                </SearchItem>
                                <SearchItem>
                                    <XDatePicker
                                        label='下单时间'
                                        value={startTime}
                                        bindThis={this}
                                        bindName='startTime'
                                        isFormat
                                    />
                                    <XDatePicker
                                        style={{ marginRight: 20 }}
                                        label=''
                                        value={endTime}
                                        bindThis={this}
                                        bindName='endTime'
                                        isFormat
                                    />
                                </SearchItem>
                                <SearchItem>
                                    <XDatePicker
                                        label='免单时间'
                                        value={startFreeOrderTime}
                                        bindThis={this}
                                        bindName='startFreeOrderTime'
                                        isFormat
                                    />
                                    <XDatePicker
                                        style={{ marginRight: 20 }}
                                        label=''
                                        value={endFreeOrderTime}
                                        bindThis={this}
                                        bindName='endFreeOrderTime'
                                        isFormat
                                    />
                                </SearchItem>
                                <SearchItem>
                                    <XOKButton label='查询' onClick={this._searchHandle} />
                                    <XCancelButton label='重置' onClick={this._resetHandle} />
                                </SearchItem>
                            </SearchBox>
                            {/* <div className={styles.export}>
                                    <XOKButton label='导出' onClick={this._exportHandle} />
                                </div> */}
                            <TableContent payId={this.state.payId} renderData={freeOrderList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                        </div> : this.state.curTabID === 2 ?
                            <>
                                <SearchBox>
                                    <SearchItem>
                                        <XInput
                                            label='活动名称'
                                            placeholder='请输入活动名称'
                                            value={activityTitle}
                                            bindThis={this}
                                            bindName='activityTitle'
                                        />
                                    </SearchItem>
                                    <SearchItem>
                                        <XInput
                                            label='商品名称'
                                            placeholder='请输入商品名称'
                                            value={goodsName}
                                            bindThis={this}
                                            bindName='goodsName'
                                        />
                                    </SearchItem>
                                    <SearchItem>
                                        <XInput
                                            label='商品货号'
                                            placeholder='请输入商品货号'
                                            value={goodsNo}
                                            bindThis={this}
                                            bindName='goodsNo'
                                        />
                                    </SearchItem>
                                    <SearchItem>
                                        <XInput
                                            label='所属店铺'
                                            placeholder='请输入店铺名称'
                                            value={shopName}
                                            bindThis={this}
                                            bindName='shopName'
                                        />
                                    </SearchItem>
                                    <SearchItem>
                                        <XOKButton label='查询' onClick={this._searchHandle} />
                                        <XCancelButton label='重置' onClick={this._resetHandle} />
                                    </SearchItem>
                                </SearchBox>
                                <FreeTableContent history={this.props.history} renderData={freeProductList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                            </> : <>
                                <SearchBox>
                                    <SearchItem>
                                        <XInput
                                            label='规则名称'
                                            placeholder='请输入规则名称'
                                            value={ruleName}
                                            bindThis={this}
                                            bindName='ruleName'
                                        />
                                    </SearchItem>
                                    <SearchItem>
                                        <XSelect
                                            label='状态'
                                            placeholder='请选择状态'
                                            renderData={autoFreeOrderStatus || []}
                                            dataIndex='value'
                                            keyIndex='key'
                                            value={State.value}
                                            showSearch={true}
                                            bindThis={this}
                                            bindName='State'
                                        />
                                    </SearchItem>
                                    <SearchItem>
                                        <XOKButton label='查询' onClick={this._searchHandle} />
                                        <XCancelButton label='重置' onClick={this._resetHandle} />
                                    </SearchItem>
                                </SearchBox>
                                <XOKButton style={{ width: 90, marginTop: 10 }} label='新建' onClick={this._createMode} />
                                <AutoTableContent history={this.props.history} renderData={autoFreeOrderList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                            </>
                    }
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getFreeOrderList: PropTypes.func,
    freeOrderList: PropTypes.object,
    getFreeOrderStatus: PropTypes.func,
    freeOrderStatus: PropTypes.array,
    getFreeProductList: PropTypes.func,
    freeProductList: PropTypes.object,
    getMerchantList: PropTypes.func,
    merchantList: PropTypes.object,
    getAutoFreeOrderList: PropTypes.func,
    autoFreeOrderList: PropTypes.object,
    getAutoFreeOrderStatus: PropTypes.func,
    autoFreeOrderStatus: PropTypes.array,
};
const mapStateToProps = (state) => ({
    freeOrderList: state.FreeOrder.freeOrderList,
    freeProductList: state.FreeOrder.freeProductList,
    freeOrderStatus: state.FreeOrder.freeOrderStatus,
    merchantList: state.Transcation.merchantList,
    autoFreeOrderList: state.FreeOrder.autoFreeOrderList,
    autoFreeOrderStatus: state.FreeOrder.autoFreeOrderStatus,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getAutoFreeOrderList, getFreeOrderList, getFreeOrderStatus, getFreeProductList, getMerchantList, getAutoFreeOrderStatus })(Main);