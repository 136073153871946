import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import KeepAlive from '@/routes/KeepAlive';
import { connect } from 'react-redux';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm,XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { message, Switch, Table } from 'antd';
import http from '@/assets/api/http';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getstrategyList, getclassificationList, getstrategyType } from '@/reducers/xqstrategy/classification/actions';

const TableComponent = ({
    renderData: {
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0,
        dataList = []
    } = {},
    paginationChange,
    tableAction,
}) => {
    const switchCell = (t, r, f, url) => {
        return <Switch key={r.id} checked={t == 1 ? true : false} checkedChildren='ON' unCheckedChildren='OFF'
            onChange={p => tableAction('switchChange', { id: r.id, [f]: p ? 1 : 0 }, url)} />;
    };

    const tableOperate = (t, r) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => tableAction('edit', r.id)}>编辑</div>
            <div className={styles.item} onClick={() => tableAction('delete', { id: r.id })}>删除</div>
        </div>;
    };

    const columns = [
        {
            title: '攻略ID',
            dataIndex: 'stratrgid',
            key: 'stratrgid',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: (t, r) => tooltip(r.id)
        },
        {
            title: '攻略标题',
            dataIndex: 'title',
            key: 'title',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '攻略类型',
            dataIndex: 'strategyType.value',
            key: 'strategyType.value',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '应用终端',
            dataIndex: 'useScene.value',
            key: 'useScene.value',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '推荐',
            dataIndex: 'isReco',
            key: 'isReco',
            align: 'center',
            onCell: tooltipStyle,
            render: (t, r) => switchCell(t, r, 'isReco', 'updateStrategyReco')
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            onCell: tooltipStyle,
            render: (t, r) => switchCell(t, r, 'state', 'updateStrategyState')
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            onCell: tooltipStyle,
            render: tableOperate
        }
    ];

    return <div className={styles.tableContainer}>
        <Table
            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
            rowKey='id'
            columns={columns}
            dataSource={dataList}
            pagination={false}
            scroll={{ y: true }}
        />
        <XPagination
            resultTotal={resultTotal}
            pageSize={pageSize}
            pageNum={pageNum}
            onChange={paginationChange}
        />
    </div>;
};
TableComponent.propTypes = {
    renderData: PropTypes.object,
    paginationChange: PropTypes.func,
    tableAction: PropTypes.func,
};

const SupplyStrategy = ({
    history,
    getstrategyList,
    tableData,
    getclassificationList,
    classifyEnum,
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getstrategyType,
    typeEnum,
    strategyPaginations
}) => {

    const [title, setTitle] = useState('');
    const [classItem, setClassItem] = useState({});
    const [typeItem, setTypeItem] = useState({});
    const [pageNum, setPageNum] = useState(autoPageNum);
    const [pageSize, setPageSize] = useState(autopageSize);

    useEffect(() => {
        getclassificationList({ belongSystem: 'SUPPLIER' });
        getstrategyType();
        keepSecondNavBreadcrumb();
    }, []);

    const getTableData = (params = {}) => {
        getstrategyList({
            title: params.title, belongSystem: 'SUPPLIER',
            strategyClassId: params.classItem && params.classItem.id,
            strategyType: params.typeItem && params.typeItem.code,
            pageNum: params.pageNum, pageSize: params.pageSize
        });
        KeepAlive.saveCache(params);
    };

    const paginationChange = (pageSize, pageNum) => {
        setPageNum(pageNum);
        setPageSize(pageSize);
        getTableData({ title, typeItem, classItem, pageNum, pageSize });
    };

    const resetSearch = () => {
        setTitle('');
        setClassItem({});
        setTypeItem({});
        KeepAlive.saveResetFunc(getTableData);
    };

    const tableAction = (type, params, other) => {
        const jumpage = (title, url) => {
            addOneBreadcrumbPath({ title, path: `/home/xqstrategy/list${url}` });
            history.push({ pathname: `/home/xqstrategy/list${url}` });
        };
        const eventObject = {
            create: p => jumpage('新建攻略', '/createInfo/SUPPLIER'),
            edit: p => jumpage('编辑攻略', `/editInfo/${p}/SUPPLIER`),
            delete: p => {
                showConfirm('您是否删除当前攻略', '删除后数据无法恢复，您是否确认“删除”', () => {
                    http('/show/strategy/deleteStrategyInfo', { ...p }, 'POST').then(res => {
                        message.success(res.message);
                        getTableData({ title, typeItem, classItem, pageNum, pageSize });
                    }).catch(err => message.error(err.message));
                });
            },
            switchChange: p => {
                http(`/show/strategy/${other}`, { ...p }, 'POST').then(res => {
                    message.success(res.message);
                    getTableData({ title, typeItem, classItem, pageNum, pageSize });
                }).catch(err => message.error(err.message));
            }
        };
        eventObject[type](params);
    };

    const onChange = (p = {}) => {
        if (p.title) setTitle(p.title);
        if (p.typeItem) setTypeItem(p.typeItem);
        if (p.classItem) setClassItem(p.classItem);
        if (p.pageNum != autoPageNum) setPageNum(p.pageNum);
        if (p.pageSize != autopageSize) setPageSize(p.pageSize);
        getTableData(p);
    };

    return <KeepAlive render={onChange}>
        <div className={styles.flexBoxContainer}>
            <SearchBox>
                <SearchItem>
                    <XInput
                        label='攻略名称'
                        placeholder='请输入攻略名称'
                        value={title}
                        bindThis={setTitle}
                        bindName='title'
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        label='攻略类别'
                        showSearch
                        placeholder='请选择攻略类别'
                        renderData={classifyEnum}
                        dataIndex='className'
                        keyIndex='id'
                        value={classItem.className}
                        bindThis={setClassItem}
                        bindName='classItem'
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        label='攻略类型'
                        showSearch
                        placeholder='请选择攻略类型'
                        renderData={typeEnum.filter(i => i.code === 'HELP_CENTE')}
                        dataIndex='value'
                        keyIndex='code'
                        value={typeItem.value}
                        bindThis={setTypeItem}
                        bindName='typeItem'
                    />
                </SearchItem>
                <SearchItem>
                    <XOKButton
                        label='查询'
                        onClick={() => getTableData({ classItem, title, typeItem, pageNum, pageSize })}
                    />
                    <XCancelButton
                        label='重置'
                        onClick={resetSearch}
                    />
                </SearchItem>
            </SearchBox>
            <div><XOKButton label='新建攻略' style={{ width: '90px' }} onClick={() => tableAction('create')} /></div>
            <TableComponent renderData={{ dataList: tableData, ...strategyPaginations }} tableAction={tableAction} paginationChange={paginationChange} />
        </div>
    </KeepAlive>;
};
SupplyStrategy.propTypes = {
    history: PropTypes.object, // 路由history对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息
    getstrategyList: PropTypes.func,
    tableData: PropTypes.array,
    getclassificationList: PropTypes.func,
    classifyEnum: PropTypes.array,
    getstrategyType: PropTypes.func,
    typeEnum: PropTypes.array,
    strategyPaginations: PropTypes.object
};
export default connect(state => {
    return {
        tableData: state.classification.classificationDate.strategyTable,
        classifyEnum: state.classification.classificationDate.tableSource,
        typeEnum: state.classification.getstrategyType,
        strategyPaginations: state.classification.classificationDate.strategyPaginations,
    };
}, {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getstrategyList,
    getclassificationList,
    getstrategyType
})(SupplyStrategy);