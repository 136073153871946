//会员列表
export const GET_ACCOUNT_LIST = 'ACCOUNT/GET_ACCOUNT_LIST';
export const SET_ACCOUNT_LIST = 'ACCOUNT/SET_ACCOUNT_LIST';
// 服务商
export const GET_SERVICE_LIST = 'ACCOUNT/GET_SERVICE_LIST';
export const SET_SERVICE_LIST = 'ACCOUNT/SET_SERVICE_LIST';
// 商户
export const GET_MERCHANT_LIST = 'ACCOUNT/GET_MERCHANT_LIST';
export const SET_MERCHANT_LIST = 'ACCOUNT/SET_MERCHANT_LIST';
// 区域公司
export const GET_COMPANY_LIST = 'ACCOUNT/GET_COMPANY_LIST';
export const SET_COMPANY_LIST = 'ACCOUNT/SET_COMPANY_LIST';
// 运营中心
export const GET_OPERATIONCENTER_LIST = 'ACCOUNT/GET_OPERATIONCENTER_LIST';
export const SET_OPERATIONCENTER_LIST = 'ACCOUNT/SET_OPERATIONCENTER_LIST';
// 平台中心
export const GET_PLATFORM_LIST = 'ACCOUNT/GET_PLATFORM_LIST';
export const SET_PLATFORM_LIST = 'ACCOUNT/SET_PLATFORM_LIST';
// 账户费用明细
export const GET_COSTDETAIL_LIST = 'ACCOUNT/GET_COSTDETAIL_LIST';
export const SET_COSTDETAIL_LIST = 'ACCOUNT/SET_COSTDETAIL_LIST';
// 账户费用明细2
export const GET_COSTDETAIL_LISTS = 'ACCOUNT/GET_COSTDETAIL_LISTS';
export const SET_COSTDETAIL_LISTS = 'ACCOUNT/SET_COSTDETAIL_LISTS';
// 订单费用明细
export const GET_ORDERCOSTDETAIL_LIST = 'ACCOUNT/GET_ORDERCOSTDETAIL_LIST';
export const SET_ORDERCOSTDETAIL_LIST = 'ACCOUNT/SET_ORDERCOSTDETAIL_LIST';
// 服务订单明细
export const GET_SERVICEORDER_LIST = 'ACCOUNT/GET_SERVICEORDER_LIST';
export const SET_SERVICEORDER_LIST = 'ACCOUNT/SET_SERVICEORDER_LIST';
// 费用名称
export const GET_COSTNAME_LIST = 'ACCOUNT/GET_COSTNAME_LIST';
export const SET_COSTNAME_LIST = 'ACCOUNT/SET_COSTNAME_LIST';
// 服务管理收益明细
export const GET_SERVICEMANAGEFEE_LIST = 'ACCOUNT/GET_SERVICEMANAGEFEE_LIST';
export const SET_SERVICEMANAGEFEE_LIST = 'ACCOUNT/SET_SERVICEMANAGEFEE_LIST';
// 区域公司--激活状态
export const GET_COMPANY_ACTIVE = 'ACCOUNT/GET_COMPANY_ACTIVE';
export const SET_COMPANY_ACTIVE = 'ACCOUNT/SET_COMPANY_ACTIVE';
