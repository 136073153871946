import React from 'react';
import moment from 'moment';
import styles from './OrderSearch.module.scss';
import PropTypes from 'prop-types';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker, XExpandOrContract } from '@/components/xqxc_ui';
import { getMerchantList } from '@/reducers/ordermanage/transcation/actions';
import { connect } from 'react-redux';
import { getHotelPayWays } from '@/reducers/ordermanage/hotelOrder/actions';
import { getProductOrderType, getProductOrderStatus, getProductOrderFlagEnum, getDistributionFlagEnum, getPaymentAccessTypeEnum, getPaymentTypeEnum } from '@/reducers/ordermanage/productOrder/actions';
import { message } from 'antd';

// const orderStateSelect = [{ id: 1, name: '待付款' }, { id: 2, name: '待发款' }];
// const orderTypeSelect = [{ id: 1, name: '普通订单' }, { id: 2, name: '报税订单' }];
// const invoiceSelect = [{ id: 1, name: '是' }, { id: 2, name: '否' }];

class OrderSearch extends React.Component {

    state = {
        expandBoolean: false, // 是否展开
        telephone: '', // 会员手机
        orderCode: '', // 订单编号
        orderStartTime: null, // 下单时间开始日期
        orderEndTime: null, // 下单结束日期
        userState: {}, // 商户
        payItemId: '', // 支付流水号
        payType: {}, // 支付方式
        payAccessType: {}, // 支付通道
        ismerchantSponsorFlag: { code: 2, value: '全部' }, // 是否引流
    }

    componentDidMount() {
        // this.props.getProductOrderType();
        // this.props.getProductOrderStatus();
        // this.props.getProductOrderFlagEnum();
        // 商户
        this.props.getMerchantList();
        // 支付通道
        this.props.getPaymentAccessTypeEnum();
        // 支付方式
        this.props.getPaymentTypeEnum();
        // this.props.getHotelPayWays();
        // 引流
        this.props.getDistributionFlagEnum();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState(newProps.searchState.param);
    }

    // 查询
    _searchHandle = () => {
        const { payType, payAccessType, telephone, orderCode, payItemId, userState, orderStartTime, orderEndTime, ismerchantSponsorFlag } = this.state;
        this.searchCache = {
            telephone,
            orderCode,
            payType,
            userState,
            orderStartTime,
            orderEndTime,
            ismerchantSponsorFlag,
            payAccessType
        };
        this.props.searchOrder({
            buyerTel: telephone, startTime: orderStartTime, endTime: orderEndTime,
            id: orderCode, payItemId,
            channelType: payType.code,
            sellerId: userState.merchantId,
            isSelfSponsor: ismerchantSponsorFlag.code ? ismerchantSponsorFlag.code : '',
            accessType: payAccessType.code,
        }, this.searchCache);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            telephone: '',
            orderCode: '',
            orderStartTime: null,
            orderEndTime: null,
            payType: {},
            payAccessType: {},
            pageNum: 1,
            pageSize: 50,
            userState: {},
            sellerId: '',
            ismerchantSponsorFlag: { code: 2, value: '全部' },
        });
        this.searchCache = {
            telephone: '',
            orderCode: '',
            orderStartTime: null,
            orderEndTime: null,
            pageNum: 1,
            pageSize: 50,
            userState: {},
            sellerId: '',
            payType: {},
            payAccessType: {},
            ismerchantSponsorFlag: { code: 2, value: '全部' },
        };
        this.props.searchOrder({}, this.searchCache, true);
    }

    render() {
        const { payType, payAccessType, payItemId, telephone, orderCode, orderStartTime, orderEndTime, userState, ismerchantSponsorFlag } = this.state;
        const { paymentAccessTypeEnum, paymentTypeEnum, payWaysList = [], merchantList: { merchantIdAndNames }, DistributionFlagEnum } = this.props;
        return (
            <div className={styles.container}>
                <SearchBox>
                    <SearchItem>
                        <XInput
                            label='会员账号'
                            placeholder='请输入'
                            value={telephone}
                            bindThis={this}
                            bindName='telephone'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            label='订单号'
                            placeholder='请输入'
                            value={orderCode}
                            bindThis={this}
                            bindName='orderCode'
                        />
                    </SearchItem>

                    <SearchItem>
                        <XDatePicker
                            label='下单时间'
                            value={orderStartTime}
                            bindThis={this}
                            bindName='orderStartTime'
                            isFormat
                        />
                        <XDatePicker
                            style={{ marginRight: '20px' }}
                            label=''
                            value={orderEndTime}
                            bindThis={this}
                            bindName='orderEndTime'
                            isFormat
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='归属商户'
                            placeholder='请选择商户'
                            renderData={merchantIdAndNames || []}
                            dataIndex='merchantName'
                            keyIndex='merchantId'
                            value={userState.merchantName}
                            showSearch={true}
                            isOptionBindID
                            bindThis={this}
                            bindName='userState'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            label='支付流水号'
                            placeholder='请输入'
                            value={payItemId}
                            bindThis={this}
                            bindName='payItemId'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='支付通道'
                            placeholder='请选择支付通道'
                            renderData={paymentAccessTypeEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={payAccessType.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='payAccessType'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='支付方式'
                            placeholder='请选择支付方式'
                            renderData={paymentTypeEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={payType.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='payType'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='商户引流'
                            placeholder='请选择'
                            renderData={DistributionFlagEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={ismerchantSponsorFlag.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='ismerchantSponsorFlag'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </SearchItem>
                </SearchBox>
            </div>
        );
    }
}

OrderSearch.propTypes = {
    orderState: PropTypes.number,
    searchOrder: PropTypes.func,
    getProductOrderType: PropTypes.func,
    getProductOrderStatus: PropTypes.func,
    getProductOrderFlagEnum: PropTypes.func,
    getMerchantList: PropTypes.func,
    productOrderType: PropTypes.array,
    productOrderStatus: PropTypes.array,
    productOrderFlagEnum: PropTypes.array,
    merchantList: PropTypes.object,
    getHotelPayWays: PropTypes.func,
    payWaysList: PropTypes.array,
    getDistributionFlagEnum: PropTypes.func,
    DistributionFlagEnum: PropTypes.array,
    getPaymentAccessTypeEnum: PropTypes.func,
    paymentAccessTypeEnum: PropTypes.array,
    getPaymentTypeEnum: PropTypes.func,
    paymentTypeEnum: PropTypes.array
};

const mapStateToProps = (state) => ({
    productOrderType: state.productOrder.productOrderType,
    productOrderStatus: state.productOrder.productOrderStatus,
    productOrderFlagEnum: state.productOrder.productOrderFlagEnum,
    merchantList: state.Transcation.merchantList,
    payWaysList: state.hotelOrder.payWaysList,
    DistributionFlagEnum: state.productOrder.DistributionFlagEnum,
    paymentAccessTypeEnum: state.productOrder.paymentAccessTypeEnum,
    paymentTypeEnum: state.productOrder.paymentTypeEnum
});

export default connect(mapStateToProps, { getHotelPayWays, getProductOrderType, getProductOrderStatus, getProductOrderFlagEnum, getMerchantList, getDistributionFlagEnum, getPaymentAccessTypeEnum, getPaymentTypeEnum })(OrderSearch);