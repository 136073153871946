/**
 * 贴纸列表创建或编辑弹框
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Radio, Icon } from 'antd';
import { XInput, XSelect } from '@/components/xqxc_ui';
import CusotmUpload, { generateFileList } from '@/components/NewUpload';
import { formValids } from '@/assets/js/utils';
import http from '@/assets/api/http';
import styles from './ListModal.module.scss';

const RadioGroup = Radio.Group;

class ListModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,

        stickerName: this.props.data.stickerName,
        stickerClassId: this.props.data.stickerClassId,
        __stickerClassId: this.props.data.className,
        state: this.props.data.state,
        isReco: this.props.data.isReco,

        selectDataSource: this.props.selectDataSource,
        stickerImgs: [],
    }

    constructor(props) {
        super(props);
        this.state.stickerImgs = generateFileList(props.data.stickerImg);
    }

    // 确认
    _handleOk = () => {

        const { stickerImgs } = this.state;

        let fields = {
            stickerName: this.state.stickerName,
            stickerClassId: this.state.stickerClassId,
            state: this.state.state,
            isReco: this.state.isReco,
            stickerImg: stickerImgs.length ? (stickerImgs[0].response ? stickerImgs[0].response.key : stickerImgs[0].url) : '',
        };

        if (formValids(fields, {
            stickerName: '请输入贴纸名称',
            stickerClassId: '请选择贴纸分类',
            stickerImg: '请上传贴纸图片',
        })) {
            this.setState({ confirmLoading: true });
            const method = this.props.data.id === undefined ? 'createSticker' : 'updateStickerInfo';
            http(`/show/sticker/${method}`, {
                id: this.props.data.id,
                ...fields
            }, 'POST').finally(() => {
                this.setState({ confirmLoading: false, visible: true });
                this.props.closeMode(true);
            });
        }
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _stateRadioValueValueChangeHandle = (e) => {
        this.setState({ state: e.target.value });
    }

    // 分类状态下拉列改变时触发
    _typeChangeHandle = (selectedItem) => {
        this.setState({ stickerClassId: selectedItem });
    }

    // 礼物名称输入改变时触发
    _nameInputChangeHandle = (value) => {
        this.setState({ stickerName: value });
    }

    // 所需魅力值改变时触发
    _starInputChangeHandle = (value) => {
        this.setState({ starInput: value });
    }

    // 推荐发生改变时触发
    _recommendRadioValueChangeHandle = (e) => {
        this.setState({ isReco: e.target.value });
    }

    render() {
        const { visible, confirmLoading, title, state, __stickerClassId, isReco, stickerImgs } = this.state;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '408px' }}
                        inputStyle={{ width: '340px' }}
                        label='贴纸名称'
                        placeholder='请输入礼物名称'
                        onChange={this._nameInputChangeHandle}
                        value={this.state.stickerName}
                    />
                    <XSelect
                        style={{ marginTop: '30px', width: '410px' }}
                        selectStyle={{ width: '342px' }}
                        label='贴纸分类'
                        placeholder='请选择贴纸分类'
                        renderData={this.props.selectDataSource || []}
                        onChange={(v) => this.setState({ stickerClassId: v.id, __stickerClassId: v.className })}
                        dataIndex='className'
                        keyIndex='id'
                        value={__stickerClassId}
                    />
                    <div className={styles.radio}>
                        <div className={styles.label}>推荐</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._recommendRadioValueChangeHandle} value={isReco}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.radio}>
                        <div className={styles.label}>状态</div>
                        <RadioGroup style={{ marginLeft: '12px' }} value={state} onChange={this._stateRadioValueValueChangeHandle}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.upload}>
                        <div className={styles.label}>贴纸图</div>
                        <div className={styles.icon}>
                            <CusotmUpload
                                accept='.png, .jpg, .jpeg'
                                fileList={stickerImgs}
                                listType="picture-card"
                                wallMode="single"
                                qiniuUploadProps={{
                                    uploadType: 'image'
                                }}
                                onChange={(info) => {
                                    this.setState({ stickerImgs: info.fileList });
                                }}
                            >
                                <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                            </CusotmUpload>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

ListModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    stickypaperListSelect: PropTypes.array,
    selectDataSource: PropTypes.array
};

export default connect(state => ({
    stickypaperListSelect: state.stickypaper.stickypaperListSelect,
}))(ListModal);