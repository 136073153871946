import * as FEEDBACKTYPE from './actionTypes';

//获取反馈类型列表
export const getFeedbackTypeList = (param) => ({
    type: FEEDBACKTYPE.GET_FEEDBACKTYPE_LIST,
    payload: param
});
//获取意见反馈详情
export const getFeedbackTypeDetail = (param) => ({
    type: FEEDBACKTYPE.GET_FEEDBACKTYPE_DETAIL,
    payload: param
});
