/**
 * 启用服务商弹框
 */
import React from 'react';
import { Modal, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './UnableModal.module.scss';
import http from '@/assets/api/http';
import { message } from 'antd';

const { TextArea } = Input;
class UnableModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        areaValue: '',
    }

    _handleOk = () => {
        const { data } = this.props;
        this.setState({ confirmLoading: true });
        http('/service/provider/updateControlStart', { serviceId: data, disableReason: this.state.areaValue, state: 0 }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('封禁成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode('Refresh');
            }
        }).catch((e) => {
            message.error(e.message);
        }).finally(() => {
            this.setState({ confirmLoading: false });
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // 禁用说明
    _areaChangeHandle = (e) => {
        this.setState({ areaValue: e.target.value });
    }

    render() {
        const { visible, confirmLoading, areaValue } = this.state;
        return (
            <Modal
                width={590}
                centered
                title='禁用'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div>禁用理由：</div>
                    <TextArea
                        className={styles.textArea}
                        rows={4}
                        onChange={this._areaChangeHandle}
                        value={areaValue}
                    />
                </div>
            </Modal>
        );
    }
}

UnableModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    data: PropTypes.string, // 封禁或解封的ID
};

export default UnableModal;