
//获取/保存频道列表的直播间列表
export const GET_LIST_LIVEDATA = 'CHANNELLIST/GET_LIST_LIVEDATA';
export const SET_LIST_LIVEDATA = 'CHANNELLIST/SET_LIST_LIVEDATA';
export const CHANGE_ISHOMERECO = 'CHANNELLIST/CHANGE_ISHOMERECO';
export const CHANGE_ISCHANNELRECO = 'CHANNELLIST/CHANGE_ISCHANNELRECO';


//获取/保存频道列表的直播间状态下拉
export const GET_LIST_LIVESTATUS = 'CHANNELLIST/GET_LIST_LIVESTATUS';
export const SET_LIST_LIVESTATUS = 'CHANNELLIST/SET_LIST_LIVESTATUS';

//获取/保存频道列表的直播间下拉
export const GET_LIST_LIVESELECT = 'CHANNELLIST/GET_LIST_LIVESELECT';
export const SET_LIST_LIVESELECT = 'CHANNELLIST/SET_LIST_LIVESELECT';

//获取/保存频道列表的视频列表
export const GET_LIST_VIDEODATA = 'CHANNELLIST/GET_LIST_VIDEODATA';
export const SET_LIST_VIDEODATA = 'CHANNELLIST/SET_LIST_VIDEODATA';

//获取/保存频道列表的视频下拉
export const GET_LIST_VIDEOSELECT = 'CHANNELLIST/GET_LIST_VIDEOSELECT';
export const SET_LIST_VIDEOSELECT = 'CHANNELLIST/SET_LIST_VIDEOSELECT';

//获取/保存频道列表的直播间历史
export const GET_LIST_LIVEHISTORY = 'CHANNELLIST/GET_LIST_LIVEHISTORY';
export const SET_LIST_LIVEHISTORY = 'CHANNELLIST/SET_LIST_LIVEHISTORY';


//获取/保存频道列表的直播间首页推荐或频道推荐
export const GET_LIST_LIVERECOMMEND = 'CHANNELLIST/GET_LIST_LIVERECOMMEND';
export const SET_LIST_LIVERECOMMEND = 'CHANNELLIST/SET_LIST_LIVERECOMMEND';

//获取/保存频道列表的视频社区首页推荐或频道推荐
export const GET_LIST_VCRECOMMEND = 'CHANNELLIST/GET_LIST_VCRECOMMEND';
export const SET_LIST_VCRECOMMEND = 'CHANNELLIST/SET_LIST_VCRECOMMEND';


//获取/保存频道列表的视频视频列表详情
export const GET_LIST_VIDEODETAIL = 'CHANNELLIST/GET_LIST_VIDEODETAIL';
export const SET_LIST_VIDEODETAIL = 'CHANNELLIST/SET_LIST_VIDEODETAIL';

//getcommunityTableSource  getcommunityState  getcommunityQueryDetail  

//社区列表  获取'秀场管理 -频道列表- 社区列表-首页与频道公用分类下拉'数据源(saga) getcommunityRecoState
export const GET_TABLE_DATA = 'CHANNELLIST/GET_TABLE_DATA'; // 异步获取'秀场管理 -频道列表- 社区列表'数据源
export const SET_TABLE_DATA = 'CHANNELLIST/SET_TABLE_DATA'; // 将'秀场管理 -频道列表- 社区列表'存储至store.state中

export const GET_COMSTATE_DATA = 'CHANNELLIST/GET_COMSTATE_DATA'; // 异步获取'秀场管理 -频道列表- 社区分类下拉'数据源
export const SET_COMSTATE_DATA = 'CHANNELLIST/SET_COMSTATE_DATA'; // 将'秀场管理 -频道列表- 社区分类下拉'存储至store.state中


export const GET_COMDETAIL_DATA = 'CHANNELLIST/GET_COMDETAIL_DATA'; // 异步获取'秀场管理 -频道列表- 社区分类查看详情'数据源
export const SET_COMDETAIL_DATA = 'CHANNELLIST/SET_COMDETAIL_DATA'; // 将'秀场管理 -频道列表- 社区分类查看详情'存储至store.state中

//获取/保存频道列表的视频列表绑定用户
export const GET_LIST_VIDEOUSERNAME = 'CHANNELLIST/GET_LIST_VIDEOUSERNAME';
export const SET_LIST_VIDEOUSERNAME = 'CHANNELLIST/SET_LIST_VIDEOUSERNAME';

//获取/保存频道列表的商品列表绑定用户
export const GET_LIST_VIDEOPRODUCT = 'CHANNELLIST/GET_LIST_VIDEOPRODUCT';
export const GET_VIDEODETAIL_DATA = 'CHANNELLIST/GET_VIDEODETAIL_DATA'; // 异步获取'秀场管理 -频道列表- 视频查看详情'数据源
export const SET_VIDEODETAIL_DATA = 'CHANNELLIST/SET_VIDEODETAIL_DATA'; // 将'秀场管理 -频道列表- 视频查看详情'存储至store.state中


export const GET_COMRECO_DATA = 'CHANNELLIST/GET_COMRECO_DATA'; // 异步获取'秀场管理 -频道列表- 首页与频道公用分类下拉'数据源
export const SET_COMRECO_DATA = 'CHANNELLIST/SET_COMRECO_DATA'; // 将'秀场管理 -频道列表- 首页与频道公用分类下拉'存储至store.state中

export const SET_LIST_VIDEOPRODUCT = 'CHANNELLIST/SET_LIST_VIDEOPRODUCT';


//获取/保存直播间星收入
export const GET_LIST_LIVESTAR = 'CHANNELLIST/GET_LIST_LIVESTAR';
export const SET_LIST_LIVESTAR = 'CHANNELLIST/SET_LIST_LIVESTAR';


//获取/保存直播间收入
export const GET_LIST_LIVESELL = 'CHANNELLIST/GET_LIST_LIVESELL';
export const SET_LIST_LIVESELL = 'CHANNELLIST/SET_LIST_LIVESELL';

//获取主播类型枚举
export const GET_ANCHOR_TYPE_ENUM = 'CHANNELLIST/GET_ANCHOR_TYPE_ENUM';
export const SET_ANCHOR_TYPE_ENUM = 'CHANNELLIST/SET_ANCHOR_TYPE_ENUM';