/**
 * 意见反馈管理
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as SUGGESTIONS from './actionTypes';

export const getSuggestionsList = function* () {
    yield takeEvery(SUGGESTIONS.GET_SUGGESTIONS_LIST, function* requestData(action) {
        try {
            let result = yield http('/ideaBack/listIdeaBack', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SUGGESTIONS.SET_SUGGESTIONS_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
export const getSuggestionsDetail = function* () {
    yield takeEvery(SUGGESTIONS.GET_SUGGESTIONS_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/ideaBack/ideaBackInfo', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SUGGESTIONS.SET_SUGGESTIONS_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
export const getSuggestionsType = function* () {
    yield takeEvery(SUGGESTIONS.GET_SUGGESTIONS_TYPE, function* requestData(action) {
        try {
            let result = yield http('/ideaBack/findIdeaBackTypeItem', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SUGGESTIONS.SET_SUGGESTIONS_TYPE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//反馈端口
export const getSuggestionsPort = function* () {
    yield takeEvery(SUGGESTIONS.GET_SUGGESTIONS_PORT, function* requestData(action) {
        try {
            let result = yield http('/enum/AppTypeEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SUGGESTIONS.SET_SUGGESTIONS_PORT, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};