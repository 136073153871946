import { getCustomFittingTableSource } from './customfitting/saga';
import { getCategoryTableSource } from './materialcategory/saga';
import { getMaterialTableSource } from './materiallist/saga';
import {
    getLiveDurationRechargeRulesTableSource, getShowSettingList, getToupRulesTableSource,
    popularList, replyList, popularDetail, recommendList, recommendSelect, changData, recommendContent, recommendDetail, changOtherData,
} from './showset/saga';
import { getMemberGradeList, getMemberGradeDetail, getMemberGradeCouponSele, getMemberGradeRadio } from './membersgrade/saga';
import { getMemberGradeListMlx, getMemberGradeDetailMlx, getMemberGradeCouponSeleMlx, getMemberGradeRadioMlx } from './membersgrademlx/saga';
import { getCharmGradeList, getCharmGradeDetail, getCharmGradeCouponSele, getCharmGradeRadio } from './charmgrade/saga';
import { showpareme, mallRecommendList, mallrulesDropDown, categoryLinkage, getSalaAfterRuleList, getSalaAfterTypeEnum } from './mallsetting/saga';
import { getLogisticsState, getLogisticsList } from './logistics/saga';
import { getAllocationList, getSettingCode, getAllocationDetail } from './allocation/saga';
import { getCostNameList } from './costname/saga';
import { getUserNameSeleList, getUserNameDefault } from './starShopSet/saga';
import { getMarketingCampaign, getMarketingCampaignUser, getWalletMerchantList } from './marketingCampaign/saga';
import { getFinanceArgDetail, getFinanceInfoList } from './financeArg/saga';
import { getReleaseList, getReleaseDetail, getReleaseTerminal, getReleaseState, getReleaseSystem, getReleaseUpdate } from './release/saga';//版本管理
import payment from './showset/model';
import watermark from './watermark/model';
import convertiblepro from './convertiblepro/model';
import merchantpermission from './merchantpermission/model';
import riskcontrol from './riskcontrol/model';
import servicesetting from './servicesetting/model';
import financialsetting from './financialsetting/model';
import template from './template/model';
import decorateManage from './decoratemanage/model';
import allocation from './allocation/model';
import hotCity from './hotCity/model';
import hotelOrder from './hotelOrder/model';
import languagePack from './languagePack/model';
import paymentChannel from './paymentChannel/model';
import logistics from './logistics/model';
import premiumyield from './premiumyield/model';
import suppliesset from './suppliesset/model';
import printersetup from './printersetup/model';

export default [
    // 计费比例设置
    getAllocationList,
    getSettingCode,
    getAllocationDetail,
    // 组件管理
    getCustomFittingTableSource,

    // 素材管理
    getCategoryTableSource,
    getMaterialTableSource,

    //秀场设置
    // 秀场设置列表
    getShowSettingList,
    // 直播时长充值规则
    getLiveDurationRechargeRulesTableSource,
    getToupRulesTableSource,

    replyList,//快速回复列表
    popularList,//人气规则列表
    popularDetail,//人气规则详情
    recommendList,//推荐规则列表
    recommendSelect,//推荐规则频道分类
    changData,//兑换设置
    recommendContent,//推荐规则下拉内容
    recommendDetail,//推荐规则详情
    changOtherData,//赠送好友人气值限额等等

    //会员等级设置
    getMemberGradeList,
    getMemberGradeDetail,
    getMemberGradeCouponSele,
    getMemberGradeRadio,


    //牧佬鲜会员等级设置
    getMemberGradeListMlx,
    getMemberGradeDetailMlx,
    getMemberGradeCouponSeleMlx,
    getMemberGradeRadioMlx,

    //魅力等级设置
    getCharmGradeList,
    getCharmGradeDetail,
    getCharmGradeCouponSele,
    getCharmGradeRadio,

    //商城设置
    showpareme,//商城参数
    mallRecommendList,//商品列表规则参数列表
    mallrulesDropDown,//商城设置推荐规则下拉框
    categoryLinkage,//商城设置商品联级分类
    getSalaAfterRuleList,//售后规则设置列表
    getSalaAfterTypeEnum,//售后类型枚举

    // 物流设置
    getLogisticsState,
    getLogisticsList,
    ...Object.values(logistics.sagas),

    // 费用名称
    getCostNameList,

    //人气值商城设置
    getUserNameSeleList,
    getUserNameDefault,

    //营销活动规则
    getMarketingCampaign,
    getMarketingCampaignUser,
    getWalletMerchantList,

    //财务参数设置
    getFinanceArgDetail,
    getFinanceInfoList,

    //版本管理
    getReleaseList,
    getReleaseDetail,
    getReleaseTerminal,
    getReleaseState,
    getReleaseSystem,
    getReleaseUpdate,

    //知识付费&魅力值兑换规则
    ...Object.values(payment.sagas),
    //水印管理
    ...Object.values(watermark.sagas),
    //人气值兑换魅力值比率
    ...Object.values(convertiblepro.sagas),
    //商户权限设置
    ...Object.values(merchantpermission.sagas),
    // 风控体系设置
    ...Object.values(riskcontrol.sagas),
    //服务商等级设置
    ...Object.values(servicesetting.sagas),
    // 财务设置
    ...Object.values(financialsetting.sagas),
    // 自定义模板分类
    ...Object.values(template.sagas),
    //服务商等级设置
    ...Object.values(servicesetting.sagas),
    // 自定义模板分类
    ...Object.values(template.sagas),
    //装修模板管理
    ...Object.values(decorateManage.sagas),
    //热门城市 
    ...Object.values(hotCity.sagas),
    //酒店订单
    ...Object.values(hotelOrder.sagas),
    //酒店语音包
    ...Object.values(languagePack.sagas),
    //计费分配比例设置
    ...Object.values(allocation.sagas),
    //支付通道设置 
    ...Object.values(paymentChannel.sagas),
    //股权溢价收益权设置
    ...Object.values(premiumyield.sagas),
    // 供应链设置
    ...Object.values(suppliesset.sagas),
    // 打印机设置
    ...Object.values(printersetup.sagas),
];