import { createModel } from '@/store/tools';

const model = {
    namespace: 'invoicemaintain',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getList': T('getList'),
        },
        actions: {
            'getList': A('getList'),
        },
        sagas: {
            'getList': S('getList', '/company/invoice/companyInvoiceList'),
        },
        reducers: {
            'getList': R('getList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);