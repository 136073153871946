/**
 * 删除与批量删除
 */
import React from 'react';
import { Modal, message, Icon, } from 'antd';
import PropTypes from 'prop-types';
import styles from './DeletefirmModal.module.scss';
import http from '@/assets/api/http';

class DeletefirmModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            // title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
        };
    }
    _handleOk = () => {
        const { ids = [], } = this.props.data;
        this.setState({ confirmLoading: true }, () => {
            http('/merchant/wholesale/updateMerchantWholesaleDelete', {
                ids,
            }, 'POST').then(() => {
                message.success('删除成功');
                this.setState({
                    visible: false,
                    confirmLoading: false,
                });
                this.props.closeMode(true);
            }).catch((reject = {}) => {
                message.error(reject.message);
                this.setState({
                    visible: false,
                    confirmLoading: false,
                });
                this.props.closeMode();
            });
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, } = this.state;
        const { merchantNames = [], } = this.props.data;
        return (
            <Modal
                width={435}
                centered
                // title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='继续'
            >
                <div className={styles.container}>
                    <div className={styles.title}>
                        <Icon type="exclamation-circle" style={{ fontSize: '23px' }} />
                        <h4>确认删除商户批发商品免审核吗？</h4>
                    </div>
                    <div className={`${styles.layout} ${styles.labels}`}>
                        <label>商户名称:</label>
                        <span>{merchantNames.join('，')}</span>
                    </div>
                </div>
            </Modal >
        );

    }
}

DeletefirmModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default DeletefirmModal;