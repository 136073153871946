import React from 'react';
import styles from './TableContent.module.scss';
import { XMediaFn } from '@/components/xqxc_ui';
import { XPagination, XTableHOC, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table, Badge } from 'antd';
import PropTypes from 'prop-types';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { tooltipStyle, tooltip, priceFenToYuanCell, cellWidth, tooltipClick } from '@/components/TableCell';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [],
    }
    _takeColumns = () => {
        const cols = [
            {
                title: '协议编号',
                dataIndex: 'id',
                key: 'id',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '手机号码',
                dataIndex: 'tel',
                key: 'tel',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip,
            },
            {
                title: '姓名',
                dataIndex: 'realName',
                key: 'realName',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '身份证正反面',
                dataIndex: 'cardBack',
                key: 'cardBack',
                width: '15%',
                align: 'center',
                onCell: tooltipStyle,
                render: (text, record) => {
                    return <div style={{ display: 'inline-flex' }}>
                        <XMediaFn
                            style={{ width: '48px', height: '48px', marginRight: 2 }}
                            dataType='image'
                            dataSource={record.cardFront}
                            key={record.cardFront + '正'}
                        />
                        <XMediaFn
                            style={{ width: '48px', height: '48px', marginRight: '0' }}
                            dataType='image'
                            dataSource={record.cardBack}
                            key={record.cardBack + '反'}
                        />
                    </div>;
                }
            },
            {
                title: '委托书',
                dataIndex: 'taxCommissionUrl',
                key: 'taxCommissionUrl',
                align: 'center',
                width: '10%',
                onCell: tooltipStyle,
                render: (text, record) => {
                    return <div style={{ display: 'inline-flex' }}>
                        <XMediaFn
                            style={{ width: '48px', height: '48px', marginRight: 2 }}
                            dataType='pdf'
                            dataSource={text}
                            key={text}
                        />
                    </div>;
                }
            },
            {
                title: '申请时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                align: 'center',
                width: '10%',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '开通时间',
                dataIndex: 'approvalTime',
                key: 'approvalTime',
                align: 'center',
                width: '15%',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '状态',
                dataIndex: 'taxCommissionStatus.value',
                key: 'taxCommissionStatus.value',
                align: 'center',
                width: '10%',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                // fixed: 'right',
                width: '10%',
                onCell: tooltipStyle,
                render: (text, record) => {
                    return <div className={styles.action}>
                        <span className={styles.item} onClick={() => this.props.tableAction(record.id, 'detail')}>查看详情</span>
                        </div>;
                }
            },
        ];
        return cols;
    };

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            // let arr = [...this.state.selectedRows, ...selectedRows];
            // let tempData = arr.filter(item => selectedRowKeys.includes(item.id));
            // let DataNew = this.duplicateFun(tempData);
            this.setState({ selectedRowKeys });
            this.props.tableAction(selectedRowKeys, 'batchData');
        },
        getCheckboxProps: record => ({
            disabled: record.taxCommissionStatus.code !== 'APPLY', // Column configuration not to be checked
        }),
    };

    // 数组对象去重
    duplicateFun = (buZhouList) => {
        let object = {};
        let open = buZhouList;
        return buZhouList = open.reduce((cur, next) => {
            object[next.id] ? '' : object[next.id] = true && cur.push(next);
            return cur;
        }, []);
    }

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
        this.props.tableAction([], 'batchData');
    }

    render() {
        const { selectedRowKeys } = this.state;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    rowSelection={this._rowSelection}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string,
    batchKeys: PropTypes.array
};

export default TableContent;