/**
 * 【兑换凭证管理】
 */
import React from 'react';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/marketingamountmanage/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';
import { isOC, isRE } from '@/assets/js/authType';
import { getLocalStorage } from '@/assets/js/storage';

class Main extends React.Component {
    state = {
        companyEarningsSummary: {
            expectIntegral: 0
        },
        subjectId: '',
        subjectType: ''
    }

    constructor(props) {
        super(props);
        const { userInfo } = JSON.parse(getLocalStorage('auth'));

        this.state.subjectId = userInfo.companyId;

        if (isOC()) {
            this.state.subjectType = 'OP_CENTER';
        }

        if (isRE()) {
            this.state.subjectType = 'REGION_COMPANY';
        }
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        http('/admin/company/getCompanyEarningsSummary', {
            subjectId: this.state.subjectId,
            subjectType: this.state.subjectType
        }).then(res => {
            if (res.code === 200) {
                this.setState({
                    companyEarningsSummary: res.result
                });
            }
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, stateItem } = this.state;
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAwaitSettlementList({
            ...this.searchCache,
            subjectId: this.state.subjectId,
            subjectType: this.state.subjectType,
        });
        KeepAlive.saveCache({ ...this.searchCache, stateItem }); // 【第3步，缓存查询状态】
    }

    render() {
        const { awaitSettlementList } = this.props;
        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.count_box}>
                        <div>待结算特权值：{this.state.companyEarningsSummary.expectIntegral}</div>
                    </div>
                    <OrderTable renderData={awaitSettlementList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getAwaitSettlementList: PropTypes.func,
    awaitSettlementList: PropTypes.object
};
const mapStateToProps = (state) => ({
    awaitSettlementList: state.marketingamountmanage.awaitSettlementList
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
