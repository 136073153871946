import React from 'react';
import styles from './AdvertTable.module.scss';
import { connect } from 'react-redux';
import { XPagination, XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { getMerchantList } from '@/reducers/storehousefinancialmanage/bankAccount/actions';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class AdvertTable extends React.Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         pageSize: this.props.renderData.pageSize,
    //         pageNum: this.props.renderData.pageNum,
    //     };
    //   }
    state = {
        merchantName: ''
    }

    _takeColumns = () => ([
        {
            title: '商户编号',
            dataIndex: 'merchantId',
            key: 'merchantId',
            width: '15%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            width: '30%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '销售金额(元)',
            dataIndex: 'salesAmount',
            key: 'salesAmount',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '营销服务费(元)',
            dataIndex: 'marketServiceFee',
            key: 'marketServiceFee',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '营销服务收益(元)',
            dataIndex: 'marketingAmount',
            key: 'marketingAmount',
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        }
    ]);
    _renderActionCell = (id, type, code, text) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, type, code, 'info')}>{regFenToYuan(text)}</div>
            </div>
        );
    };
    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    // 查询
    _searchHandle = (useCache) => {
        const { merchantName, pageNum, pageSize } = this.state;
        this.searchCache = {
            merchantName,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getMerchantList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 分页
    paginationChange = (pageSize, pageNum) => {
        const { merchantName } = this.state;
        this.setState({ pageNum, pageSize });
        this.searchCache = {
            merchantName,
            pageNum,
            pageSize,
        };
        this.props.getMerchantList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 重置
    _resetHandle = () => {
        this.setState({
            merchantName: '',
            pageSize: autopageSize,
            pageNum: autoPageNum
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        // this.props.getMerchantList();
    }
    render() {
        const { merchantName } = this.state;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <KeepAlive id='3' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商户名称'
                                placeholder='请输入'
                                value={merchantName}
                                bindThis={this}
                                bindName='merchantName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={() => this._searchHandle('useCache')}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.tableContainer}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            // rowSelection={this._rowSelection} 
                            rowKey={(record, index) => index}
                            columns={this._takeColumns()}
                            dataSource={this._takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this.paginationChange}
                        />
                    </div>
                </div>
            </KeepAlive>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    getMerchantList: PropTypes.func,
    merchantList: PropTypes.object,
    tableAction: PropTypes.func.isRequired, // 表格操作
    // paginationChange: PropTypes.func.isRequired, // 分页操作
};
const mapStateToProps = (state) => ({
    merchantList: state.bankAccount.merchantList,
});
export default connect(mapStateToProps, { getMerchantList })(AdvertTable);