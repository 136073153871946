/**
 * 修改虚拟销量
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message } from 'antd';
import styles from './Virtual.module.scss';
import { XInput } from '@/components/xqxc_ui';

class Inventory extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        inventory: ''

    }

    // 点击确认
    _handleOk = () => {
        const { data } = this.props;
        const { inventory, } = this.state;
        let inventoryReg = /^(0|[1-9]\d*)$/;
        if (inventory == '' || !inventoryReg.test(inventory)) {
            message.warn('请输入虚拟销量（整数）。');
        } else {
            http('/xiudou/earnings/updateVirtualSales', { id: data.id, virtualSales: inventory }, 'POST').then(() => {
                message.success('虚拟销量更新成功');
                this.setState({ visible: false });
                this.props.closeMode(true);
            }).catch((e) => {
                message.error(e.message || '虚拟销量更新失败');
                this.setState({
                    visible: false,
                });
                this.props.closeMode();
            });
        }
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _inventoryChange = (e) => {
        this.setState({ inventory: e });
    }

    render() {
        const { visible, confirmLoading, title, inventory } = this.state;
        const { data } = this.props;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div style={{ marginBottom: 15 }}>当前实际销量：{data.soldShares} 股</div>
                    <div style={{ marginBottom: 15 }}>当前虚拟销量：{data.fictitiousSale} 股</div>
                    <div className={styles.name}>
                        <XInput
                            style={{ width: '380px', marginRight: 5 }}
                            inputStyle={{ width: '300px', marginLeft: '14px' }}
                            label='虚拟销量'
                            onChange={this._inventoryChange}
                            value={inventory}
                            placeholder='请输入虚拟销量'
                        />股
                    </div>
                    <div style={{ color: '#808080', fontSize: 13 }}>虚拟销量将在实际销量上叠加</div>
                </div>
            </Modal>
        );
    }
}


Inventory.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

export default Inventory;