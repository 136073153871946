import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import http from '@/assets/api/http';
import { Table } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import { autoPageNum, autopageSize } from '@/assets/config';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker, XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { getSessionStorage, setSessionStorage, removeSessionStorage } from '@/assets/js/storage';

const getStatusEnum = async () => {
    return http('/enum/LLPayUserStatusEnum', {}, 'POST')
        .then(res => { return res.result; });
};

const getTableData = async (params = {}) => {
    const { statusItem = {}, startDate, endDate, ...other } = params;
    setSessionStorage('suppliermanage_access_lianlian_params', params);
    return http('/outPayaccountQuery/listOutPayAccountInfo', {
        accessType: 'LL_PAY',
        ...other, status: statusItem.code, subjectType: 'MERCHANT_SUPPLIER',
        startDate: startDate && startDate.format('YYYY-MM-DD 00:00:00'),
        endDate: endDate && endDate.format('YYYY-MM-DD 23:59:59')
    }, 'POST').then(res => { return res.result; });
};

const defaultProps = { merchantName: null, businessLicenseName: null, merchantLoginName: null, statusItem: {}, startDate: null, endDate: null, pageNum: autoPageNum, pageSize: autopageSize };

const Lianlian = ({ history }) => {

    const [statusEnum, setStatusEnum] = useState([]);
    const [requery, setRequery] = useState(defaultProps);
    const [tableData, setTableData] = useState({ dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 });

    useEffect(() => {
        (async () => {
            const list = await getStatusEnum();
            setStatusEnum(list);
        })();
        const params = getSessionStorage('suppliermanage_access_lianlian_params');
        onSearch(params ? JSON.parse(params) : requery);
        removeSessionStorage('suppliermanage_access_lianlian_params');
    }, []);

    const onSearch = async (params = {}) => {
        const list = await getTableData({ ...requery, ...params });
        setTableData(list);
    };

    const onFormChange = (params = {}) => {
        const callback = pre => {
            return { ...pre, ...params };
        };
        setRequery(callback);
    };

    const operateCell = (id, { accessType }) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => {
                history.push({ pathname: `/home/suppliermanage/access/huifuinfo/${id}/${accessType}/MERCHANT_SUPPLIER` });
            }}>查看详情</div>
        </div>;
    };

    const columns = [
        { title: '供应商账号', dataIndex: 'merchantLoginName', key: 'merchantLoginName', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '供应商名称', dataIndex: 'merchantName', key: 'merchantName', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '营业执照名称', dataIndex: 'businessLicenseName', key: 'businessLicenseName', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '提交时间', dataIndex: 'gmtCreate', key: 'gmtCreate', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '更新时间', dataIndex: 'gmtModified', key: 'gmtModified', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '开户状态', dataIndex: 'status.value', key: 'status.value', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '备注说明', dataIndex: 'remark', key: 'remark', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '操作', dataIndex: 'merchantId', key: 'merchantId', align: 'center', onCell: tooltipStyle, render: operateCell },
    ];

    return <div className={styles.flexBoxContainer}>
        <SearchBox>
            <SearchItem>
                <XInput label='供应商名称' placeholder='请输入供应商名称' value={requery.merchantName} onChange={merchantName => onFormChange({ merchantName })} />
            </SearchItem>
            <SearchItem>
                <XInput label='营业执照' placeholder='请输入营业执照' value={requery.businessLicenseName} onChange={businessLicenseName => onFormChange({ businessLicenseName })} />
            </SearchItem>
            <SearchItem>
                <XInput label='供应商账号' placeholder='请输入供应商账号' value={requery.merchantLoginName} onChange={merchantLoginName => onFormChange({ merchantLoginName })} />
            </SearchItem>
            <SearchItem>
                <XSelect label='开户状态' placeholder='请选择开户状态' renderData={statusEnum} dataIndex='value' keyIndex='code'
                    value={requery.statusItem && requery.statusItem.value} onChange={statusItem => onFormChange({ statusItem })}
                />
            </SearchItem>
            <SearchItem>
                <XDatePicker label='提交时间' placeholder='请选择开始日期' value={requery.startDate}
                    isFormat={true} showTime={false} onChange={startDate => onFormChange({ startDate })}
                />
                <XDatePicker style={{ marginRight: '20px' }} placeholder='请选择结束日期' value={requery.endDate}
                    isFormat={true} showTime={false} onChange={endDate => onFormChange({ endDate })}
                />
            </SearchItem>
            <SearchItem>
                <XOKButton label='查询' onClick={() => onSearch(requery)} />
                <XCancelButton label='重置' onClick={() => setRequery(defaultProps)} />
            </SearchItem>
        </SearchBox>
        <div className={styles.tableContainer} style={{ height: '100%' }}>
            <Table rowClassName={(r, i) => i % 2 === 0 ? '' : styles.tdcolor} rowKey={r => r.merchantId} columns={columns}
                dataSource={tableData.dataList} pagination={false} scroll={{ y: true }}
            />
            <XPagination resultTotal={tableData.resultTotal} pageSize={tableData.pageSize}
                pageNum={tableData.pageNum} onChange={(pageSize, pageNum) => onSearch({ pageSize, pageNum })}
            />
        </div>
    </div>;
};
Lianlian.propTypes = {
    history: PropTypes.object
};
export default Lianlian;