import React from 'react';
import styles from './AdvertTable.module.scss';
import { connect } from 'react-redux';
import { XPagination, XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import { getRegionalCompanyList, getActiveCompanyState } from '@/reducers/storehousefinancialmanage/bankAccount/actions';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class AdvertTable extends React.Component {
    state = {
        regionCompanyName: '',
        activeState: {},// 激活状态
    }

    _takeColumns = () => ([
        {
            title: '区域公司编号',
            dataIndex: 'companyId',
            key: 'companyId',
            width: 150,
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '公司简称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 220,
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '账户状态',
            dataIndex: 'state',
            key: 'state',
            width: 140,
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '营销服务收益(元)',
            dataIndex: 'marketingAmount',
            key: 'marketingAmount',
            width: 140,
            align: 'center',
            render: (text, item) => (this._renderActionCell(item.subjectId, item.subjectType, item.marketingAmountCost, text))
        },
        {
            title: '特权值',
            dataIndex: 'integral',
            key: 'integral',
            width: 140,
            align: 'center',
            render: (text, item) => (this._rendeIntegralAmountCell(item.subjectId, item.subjectType, item.marketingAmountCost, text, 'marketamountincomelist'))
        },
        {
            title: '特权值收益(元)',
            dataIndex: 'integralAmount',
            key: 'integralAmount',
            width: 140,
            align: 'center',
            render: (text, item) => (this._renderActionCell(item.companyId, item.subjectType, item.marketingAmountCost, text, 'marketamountincomedetail'))
        },
        {
            title: '系统服务费(元)',
            dataIndex: 'systemServiceRevenue',
            key: 'systemServiceRevenue',
            width: 140,
            align: 'center',
            render: (text, item) => (this._renderActionCell(item.subjectId, item.subjectType, item.systemServiceRevenueCode, text))
        },
        {
            title: '系统广告推广收益(元)',
            dataIndex: 'systemAdvertisingRevenue',
            key: 'systemAdvertisingRevenue',
            width: 140,
            align: 'center',
            render: (text, item) => (this._renderActionCell(item.subjectId, item.subjectType, item.systemAdvertisingRevenueCode, text))
        },
        {
            title: '心选推荐收益(元)',
            dataIndex: 'heartSelectionRecommendationIncome',
            key: 'heartSelectionRecommendationIncome',
            width: 140,
            align: 'center',
            render: (text, item) => (this._renderActionCell(item.subjectId, item.subjectType, item.heartSelectionRecommendationIncomeCode, text))
        },
        {
            title: '推荐商户收益(元)',
            dataIndex: 'serviceMerchantIncome',
            key: 'serviceMerchantIncome',
            width: 140,
            align: 'center',
            render: (text, item) => (this._renderActionCell(item.subjectId, item.subjectType, item.serviceMerchantIncomeCode, text))
        },
        {
            title: '区域公司管理费(元)',
            dataIndex: 'regionalCompanyManagementFee',
            key: 'regionalCompanyManagementFee',
            width: 140,
            align: 'center',
            render: (text, item) => (this._renderActionCell(item.subjectId, item.subjectType, item.regionalCompanyManagementFeeCode, text))
        },
        {
            title: '经营额度收益(元)',
            dataIndex: 'regionalBusinessIncome',
            key: 'regionalBusinessIncome',
            width: 140,
            align: 'center',
            render: (text, item) => (this._renderActionCell(item.subjectId, item.subjectType, item.regionalBusinessIncomeCode, text))
        },
        {
            title: '季年度奖金(元)',
            dataIndex: 'regionalAnnualQuarterBonus',
            key: 'regionalAnnualQuarterBonus',
            width: 140,
            align: 'center',
            render: (text, item) => (this._renderActionCell(item.subjectId, item.subjectType, item.regionalAnnualQuarterBonusCode, text))
        },
        {
            title: '技术推广服务(元)',
            dataIndex: 'regionalCompanyServiceManagementProfit',
            key: 'regionalCompanyServiceManagementProfit',
            width: 140,
            align: 'center',
            render: (text, item) => (this._renderActionCell(item.subjectId, item.subjectType, item.regionalCompanyServiceManagementProfitCode, text, 'orderInfo'))
        },
    ]);

    _renderActionCell = (id, type, code, text, goto = 'info') => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, type, code, goto)}>{regFenToYuan(text)}</div>
            </div>
        );
    };

    _rendeIntegralAmountCell = (id, type, code, text, goto = 'info') => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, type, code, goto)}>{text}</div>
            </div>
        );
    };

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };

    componentDidMount() {
        this.props.getActiveCompanyState();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { regionCompanyName, pageNum, pageSize, activeState } = this.state;
        this.searchCache = {
            regionCompanyName,
            state: activeState.code,// 激活状态
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getRegionalCompanyList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 分页
    paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            regionCompanyName: '',
            pageSize: autopageSize,
            pageNum: autoPageNum
        });
    }

    render() {
        const { regionCompanyName, activeState } = this.state;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        const { activeCompanyState } = this.props;

        return (
            <KeepAlive id='4' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='公司简称'
                                placeholder='请输入'
                                value={regionCompanyName}
                                bindThis={this}
                                bindName='regionCompanyName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='激活状态'
                                placeholder='请选择'
                                renderData={activeCompanyState}
                                dataIndex='value'
                                keyIndex='code'
                                value={activeState.value}
                                bindThis={this}
                                bindName='activeState'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    {/* <div className={styles.row1}>
                            <XCancelButton
                                style={{ width: '60px' }}
                                label='导出'
                            />
                        </div> */}
                    <div className={styles.tableContainer}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            // rowSelection={this._rowSelection} 
                            rowKey={(record, index) => index}
                            columns={this._takeColumns()}
                            dataSource={this._takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ x: 'max-content', y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this.paginationChange}
                        />
                    </div>
                </div>
            </KeepAlive>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    getRegionalCompanyList: PropTypes.func,
    regionalCompanyList: PropTypes.object,
    tableAction: PropTypes.func.isRequired, // 表格操作
    getActiveCompanyState: PropTypes.func,
    activeCompanyState: PropTypes.array,

};
const mapStateToProps = (state) => ({
    regionalCompanyList: state.bankAccount.regionalCompanyList,
    activeCompanyState: state.bankAccount.activeCompanyState,
});
export default connect(mapStateToProps, { getRegionalCompanyList, getActiveCompanyState })(AdvertTable);