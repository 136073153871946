/**
 * 人气值攻略 Saga集合
 */
import { classificationList, classificationState, strategyList, strategyAppState, strategyDetail, getstrategyType } from './classification/saga';
import noticecategory from './noticecategory/model';
import noticelist from './noticelists/model';
import initdesclist from './initdesclist/model';

export default [
    classificationList,// 人气值攻略分类列表
    classificationState,// 人气值攻略分类状态
    strategyList,// 人气值攻略列表
    strategyAppState,// 人气值攻略列表新建应用端口
    strategyDetail,// 人气值攻略列表详情
    getstrategyType, // 攻略类型
    ...Object.values(noticecategory.sagas), // 商户须知分类
    ...Object.values(noticelist.sagas), // 商户须知列表
    ...Object.values(initdesclist.sagas), // 初始说明列表
];
