import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, message, Radio, Table, Modal, Typography } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker } from '@/components/xqxc_ui';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import http from '@/assets/api/http';
import model from '@/reducers/auditmanage/payorder/model';

const { getPayOrderList, getStateEnum } = model.actions;
const { Text } = Typography;

const AuditModal = ({ closeModal, modalParams: { id } = {} }) => {

    const [auditStatus, setAuditStatus] = useState();
    const [auditOpinion, setAuditOpinion] = useState();
    const [loading, setLoading] = useState(false);

    const onSubmit = () => {
        if (!auditStatus) return message.warn('请选择审核状态');
        if (!auditOpinion) return message.warn('请输入审核意见');
        setLoading(true);
        http('/earningsServicePayVoucher/audit', {
            id, auditStatus, auditOpinion
        }, 'POST').then(res => {
            setLoading(false);
            message.success(res.message);
            closeModal(true);
        }).catch(err => {
            setLoading(false);
            message.error(err.message);
        });
    };

    return <Modal
        width={460}
        centered
        title='审核'
        visible={true}
        confirmLoading={loading}
        onOk={onSubmit}
        onCancel={() => closeModal(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div className={styles.modalContainer}>
            <div className={styles.modalItem} style={{ marginTop: 0 }}>
                <div className={styles.modalLabel}><Text type='danger'>*</Text>审核状态：</div>
                <div className={styles.modalContent}>
                    <Radio.Group value={auditStatus} onChange={e => setAuditStatus(e.target.value)}>
                        <Radio value='PASS_AUDIT'>审核通过</Radio>
                        <Radio value='FAIL_PASS'>审核不通过</Radio>
                    </Radio.Group>
                </div>
            </div>
            <div className={styles.modalItem}>
                <div className={styles.modalLabel}><Text type='danger'>*</Text>审核意见：</div>
                <div className={styles.modalContent}>
                    <Input.TextArea
                        maxLength={100}
                        value={auditOpinion}
                        onChange={e => setAuditOpinion(e.target.value)}
                        placeholder='请输入意见'
                        autoSize={{ minRows: 3, maxRows: 5 }} />
                </div>
            </div>
        </div>
    </Modal>;
};
AuditModal.propTypes = {
    closeModal: PropTypes.func,
    modalParams: PropTypes.object
};

const Channel = ({
    history,
    getPayOrderList,
    tableData: {
        dataList = [],
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0
    } = {},
    getStateEnum,
    stateEnum,
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb
}) => {

    const [mobile, setMobile] = useState();
    const [payVoucherNo, setPayVoucherNo] = useState();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [stateItem, setStateItem] = useState({});
    const [cPageNum, setCPageNum] = useState(autoPageNum);
    const [cPageSize, setCPageSize] = useState(autopageSize);
    const [openModal, setOpenModal] = useState(false);
    const [modalParams, setModalParams] = useState({});

    useEffect(() => {
        keepSecondNavBreadcrumb();
        getStateEnum();
    }, []);

    const jumpPage = (path, title) => {
        const baseUrl = '/home/auditmanage/payorder';
        addOneBreadcrumbPath({ title, path: `${baseUrl}${path}` });
        history.push({ pathname: `${baseUrl}${path}` });
    };

    const tableOperate = (id, { auditStatus: { code = '' } = {} } = {}) => {
        return <div className={styles.action}>
            {code === 'NOT_AUDIT' && <div className={styles.item} onClick={() => { setOpenModal(true); setModalParams({ id }); }}>审核</div>}
            <div className={styles.item} onClick={() => jumpPage(`/channelinfo/${id}`, '详情')}>查看详情</div>
        </div>;
    };

    const columns = [
        {
            title: '渠道号码',
            dataIndex: 'mobile',
            key: 'mobile',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '转账账号',
            dataIndex: 'payVoucherNo',
            key: 'payVoucherNo',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'auditStatus',
            key: 'auditStatus',
            align: 'center',
            onCell: tooltipStyle,
            render: ({ value = '' }) => tooltip(value)
        },
        {
            title: '操作人',
            dataIndex: 'auditUserName',
            key: 'auditUserName',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            onCell: tooltipStyle,
            render: tableOperate
        }
    ];

    const getTableData = (params = {}) => {
        getPayOrderList({
            mobile: params.mobile,
            payVoucherNo: params.payVoucherNo,
            providerRole: 'SUPPLIER',
            startDate: params.startDate,
            endDate: params.endDate,
            auditStatus: params.stateItem && params.stateItem.code,
            pageNum: params.pageNum, pageSize: params.pageSize
        });
        KeepAlive.saveCache(params);
    };

    const resetSearch = () => {
        setMobile();
        setPayVoucherNo();
        setStartDate(null);
        setEndDate(null);
        setStateItem({});
        KeepAlive.saveResetFunc(() => getTableData({ mobile, payVoucherNo, startDate, endDate, stateItem, pageNum: cPageNum, pageSize: cPageSize }));
    };

    const paginationChange = (ps, pn) => {
        setCPageNum(pn);
        setCPageSize(ps);
        getTableData({ mobile, payVoucherNo, startDate, endDate, stateItem, pageNum: pn, pageSize: ps });
    };

    const closeModal = (update) => {
        setOpenModal(false);
        setModalParams(false);
        update && getTableData({ mobile, payVoucherNo, startDate, endDate, stateItem, pageNum: cPageNum, pageSize: cPageSize });
    };

    const onChange = (p = {}) => {
        if (p.mobile) setMobile(p.mobile);
        if (p.payVoucherNo) setPayVoucherNo(p.payVoucherNo);
        if (p.startDate) setStartDate(p.startDate);
        if (p.endDate) setEndDate(p.endDate);
        if (p.stateItem) setStateItem(p.stateItem);
        if (p.pageNum != autoPageNum) setCPageNum(p.pageNum);
        if (p.pageSize != autopageSize) setCPageSize(p.pageSize);
        getTableData(p);
    };

    return <KeepAlive id='3' render={onChange}>
        <div className={styles.flexBoxContainer}>
            <SearchBox>
                <SearchItem>
                    <XInput
                        label='渠道账号'
                        placeholder='请输入渠道账号'
                        bindThis={setMobile}
                        bindName='mobile'
                        value={mobile}
                    />
                </SearchItem>
                <SearchItem>
                    <XInput
                        label='转账账号'
                        placeholder='请输入转账账号'
                        bindThis={setPayVoucherNo}
                        bindName='payVoucherNo'
                        value={payVoucherNo}
                    />
                </SearchItem>
                <SearchItem>
                    <XDatePicker
                        label='申请时间'
                        isFormat
                        bindThis={setStartDate}
                        bindName='startDate'
                        value={startDate}
                    />
                    <XDatePicker
                        style={{ marginRight: '20px' }}
                        isFormat
                        bindThis={setEndDate}
                        bindName='endDate'
                        value={endDate}
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        label='状态'
                        placeholder='请选择状态'
                        renderData={stateEnum}
                        bindThis={setStateItem}
                        bindName='stateItem'
                        dataIndex='value'
                        keyIndex='code'
                        value={stateItem.value}
                    />
                </SearchItem>
                <SearchItem>
                    <XOKButton
                        label='查询'
                        onClick={() => getTableData({ mobile, payVoucherNo, startDate, endDate, stateItem, pageNum: cPageNum, pageSize: cPageSize })}
                    />
                    <XCancelButton
                        label='重置'
                        onClick={resetSearch}
                    />
                </SearchItem>
            </SearchBox>
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey='id'
                    columns={columns}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
            {openModal && <AuditModal closeModal={closeModal} modalParams={modalParams} />}
        </div>
    </KeepAlive>;
};

Channel.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getPayOrderList: PropTypes.func,
    tableData: PropTypes.object,
    getStateEnum: PropTypes.func,
    stateEnum: PropTypes.array
};

export default connect(state => ({
    tableData: state.payorder.payOrderList,
    stateEnum: state.payorder.stateEnum
}), { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getPayOrderList, getStateEnum })(Channel);