import * as SALEREASON from './actionTypes';


const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
export const saleReasonList = (state = initData, action) => {
    switch (action.type) {
        case SALEREASON.SET_SALEREASON_LIST:
            return action.payload.result;
        default:
            return state;
    }
};