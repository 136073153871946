import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal } from 'antd';
import CCascader from '@/components/CCascader';
import http from '@/assets/api/http';
import { nonEmptyCall } from '@/util/utils';
import { LoopTree, WrapArray } from '@/util/DataStructure/Tree/NTree';
import './index.module.scss';

function CCGoods(props) {

    const [options, setOptions] = useState([]);
    const [searchValues, setSearchValues] = useState([undefined, undefined, undefined]);
    const [value, setValue] = useState();
    const checkeds = useRef(new Set());

    function getOptions() {
        http('/goods/category/linkageMerge', {}).then((res = { result: {} }) => {
            let newData = { list: res.result, id: -1 };
            LoopTree(newData, (current, parent) => {
                if (current.children === undefined && current.list) current.children = [];
                if (current.id !== -1) {
                    let index = current.categoryGrade - 1;
                    if (searchValues[index]) {
                        if ((current.categoryName || '').includes(searchValues[index])) {
                            parent.children.push(current);
                            if (checkeds.current.has(current.id)) current.checked = true;
                        }
                    } else {
                        parent.children.push(current);
                        if (checkeds.current.has(current.id)) current.checked = true;
                    }
                }
                return current;
            }, 'list');
            setTimeout(() => setOptions(newData.children), 300);
        });
    }

    function updateSearchValues(index, value) {
        const newValues = [...searchValues];
        newValues[index] = value;
        setSearchValues(newValues);
    }

    function onOk() {
        let result = [], resultValue = [];
        options.forEach(op => {
            if (op.checked || op.halfChecked) {
                let item = [op, [], []];
                (op.list || []).forEach(op1 => {
                    if (op1.checked || op1.halfChecked) {
                        item[1].push(op1);
                        (op1.list || []).forEach(op2 => {
                            if (op2.checked) item[2].push(op2);
                        });
                    }
                });
                result.push(item);
            }
        });
        result.forEach(d => { resultValue = resultValue.concat(d[2].map(v => v.id)); });

        nonEmptyCall(props.onOk, resultValue, result);
    }

    useEffect(getOptions, []);

    return (
        <Modal title="选择分类" {...props} width={755} bodyStyle={{ padding: 0 }} okText="确定" cancelText="取消" onOk={() => onOk()}>
            <Input.Group>
                <Input.Search value={searchValues[0]} allowClear onChange={(e) => updateSearchValues(0, e.target.value)} onSearch={() => getOptions()} placeholder="请输入一级分类" style={{ width: 250 }} />
                <Input.Search value={searchValues[1]} allowClear onChange={(e) => updateSearchValues(1, e.target.value)} onSearch={() => getOptions()} placeholder="请输入二级分类" style={{ width: 250 }} />
                <Input.Search value={searchValues[2]} allowClear onChange={(e) => updateSearchValues(2, e.target.value)} onSearch={() => getOptions()} placeholder="请输入三级分类" style={{ width: 250 }} />
            </Input.Group>
            <div style={{ height: 300 }} className="lz-ccascader-modal-body">
                <CCascader
                    popupClassName="lz-ccascader-modal--ccascader"
                    popupVisible
                    value={value}
                    onChange={(value) => setValue(value)}
                    onCheck={(selection) => {
                        LoopTree(selection, (current) => {
                            if (current.checked) checkeds.current.add(current.id);
                            else checkeds.current.delete(current.id);
                        }, 'children');
                    }}
                    fieldNames={{ label: 'label', value: 'id', children: 'children' }}
                    selectMode='multiple'
                    placeholder="Please select"
                    style={{ width: 400 }}
                    renderLabel={(item) => <span style={{ display: 'inline-block', width: 132 }}>{item.categoryName}</span>}
                    notFoundContent="暂无数据"
                    options={options}
                ><a></a></CCascader>
            </div>
        </Modal>
    );
}

CCGoods.propTypes = {
    onOk: PropTypes.func,
    visible: PropTypes.bool,
};

export default CCGoods;