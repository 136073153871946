/**
 * 商户权限设置
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const MerchantPermission = ({ history }) => {
    return <Main history={history} />;
};

MerchantPermission.propTypes = {
    history: PropTypes.object
};

export default MerchantPermission;