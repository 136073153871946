import * as T from './actiontypes';

/**
 * 获取'黑名单表格'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 */
export const getBlackListTableSource = (requestProps, settings) => ({
    type: T.GET_BLACKLIST_TABELSOURCE,
    payload: {
        requestProps,
        settings
    }
});

export const getHistoryTableSource = (requestProps, settings) => ({
    type: T.GET_HISTORY_TABELSOURCE,
    payload: {
        requestProps,
        settings
    }
});