import * as Audit from './actiontypes';

/**
 * 获取'视频审核表格'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 */
export const getVideoAuditList = (dataload) => ({
    type: Audit.GET_VIDEOAUDIT_LIST,
    payload: dataload
});

export const getVideoAuditDetail = ({ accountId, feedId, showType }) => ({
    type: Audit.GET_VIDEOAUDIT_DETAIL,
    payload: {
        accountId, feedId, showType
    },
});

export const getVideoAuditSele = () => ({
    type: Audit.GET_VIDEOAUDIT_SELE,
    payload: {}
});

export const getEnumSystemType = () => ({
    type: Audit.GET_ENUM_SYSTEMTYPE,
    payload: {}
});