/**
 * 审核弹框
 */
import React from 'react';
import { Modal, Input, Radio, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './AuditModal.module.scss';
import http from '@/assets/api/http';
import { XMediaFn } from '@/components/xqxc_ui';

const RadioGroup = Radio.Group;
const { TextArea } = Input;

class AuditModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        accountId: this.props.audit.accountId,
        coverId: this.props.audit.id,
        result: '',
        reason: '',
    }

    _sendReq = (url, data, winTxt) => {
        this.setState({ confirmLoading: true }, () => {
            http(url, data, 'POST').then(() => {
                message.success(winTxt);
                this.setState({ visible: false, confirmLoading: false });
                this.props.closeMode(true);
            }).catch((error) => {
                message.error(error.message);
                this.setState({ confirmLoading: false });
            });
        });
    }

    _handleOk = () => {
        const { accountId, result, coverId, reason } = this.state;
        console.log(this.props);
        if (!result) {
            message.warn('请输入状态');
        } else {
            if (result == 'APPROVAL') {
                this._sendReq('/frontcover/reviewCover', { accountId, coverId, state: result, reason }, '审核成功。');
            } else if (result == 'REJECT') {
                if (reason) {
                    this._sendReq('/frontcover/reviewCover', { accountId, coverId, state: result, reason }, '审核成功。');
                } else {
                    message.warn('请输入不通过原因');
                }

            }
        }
    }


    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    _reason = (e) => {
        this.setState({
            reason: e.target.value
        });
    }

    _result = (e) => {
        this.setState({
            result: e.target.value
        });
    }

    render() {
        const { visible, confirmLoading, reason } = this.state;
        const { audit } = this.props;
        return (
            <Modal
                width={470}
                centered
                title='主播封面审核'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.memberID}>
                        <span>会员账号：</span>
                        <span>{audit.accountName}</span>
                    </div>
                    <div className={styles.cover}>
                        <p className={styles.label}>提交封面：</p>
                        <XMediaFn dataSource={audit.coverPagePath} />
                    </div>
                    <div className={styles.status}>
                        <span>状&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;态：</span>
                        <span>
                            <RadioGroup onChange={this._result} style={{ marginLeft: '10px' }}>
                                <Radio value={'APPROVAL'}>通过</Radio>
                                <Radio value={'REJECT'}>不通过</Radio>
                            </RadioGroup>
                        </span>
                    </div>
                    <div className={styles.reason}>
                        <TextArea
                            onChange={this._reason}
                            value={reason}
                            placeholder="请输入不通过原因"
                            autosize={{ minRows: 3, maxRows: 6 }}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

AuditModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    data: PropTypes.number, // 需要审批的用户ID
    audit: PropTypes.object
};

export default AuditModal;