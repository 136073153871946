//获取/保存消息分类列表
export const GET_MESSAGECLASSIFY_LIST = 'MESSAGE/GET_MESSAGECLASSIFY_LIST';
export const SET_MESSAGECLASSIFY_LIST = 'MESSAGE/SET_MESSAGECLASSIFY_LIST';
//获取/保存消息分类详情
export const GET_MESSAGECLASSIFY_DETAIL = 'MESSAGE/GET_MESSAGECLASSIFY_DETAIL';
export const SET_MESSAGECLASSIFY_DETAIL = 'MESSAGE/SET_MESSAGECLASSIFY_DETAIL';
// 消息分类下拉
export const GET_MESSAGECLASSIFY_LISTS = 'MESSAGE/GET_MESSAGECLASSIFY_LISTS';
export const SET_MESSAGECLASSIFY_LISTS = 'MESSAGE/SET_MESSAGECLASSIFY_LISTS';
// 消息推送端口
export const GET_MESSAGEPUSHPORT = 'MESSAGE/GET_MESSAGEPUSHPORT';
export const SET_MESSAGEPUSHPORT = 'MESSAGE/SET_MESSAGEPUSHPORT';