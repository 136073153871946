/**
 * 启用与停用
 */
import React from 'react';
import { Modal, message, Icon, } from 'antd';
import PropTypes from 'prop-types';
import styles from './PopconfirmModal.module.scss';
import http from '@/assets/api/http';

class DeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
        };
    }
    _handleOk = () => {
        const { data } = this.props;

        this.setState({ confirmLoading: true }, () => {
            http('/marketing/activityInfo/updateState', {
                id: data.id,
                state: data.isOpen == '1' ? '0' : '1',
            }, 'POST').then(() => {
                message.success('提交成功');
                this.setState({
                    visible: false,
                    confirmLoading: false
                });
                this.props.closeMode(true);
            }).catch((e = {}) => {
                message.success(e.message || '提交异常');
                this.setState({
                    visible: false,
                    confirmLoading: false
                });
                this.props.closeMode();
            });
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, } = this.state;
        const { data, } = this.props;
        return (
            <Modal
                width={270}
                centered
                // title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='继续'
            >
                <div className={styles.container}>
                    <div className={styles.title}>
                        <Icon type="exclamation-circle" style={{ fontSize: '23px' }} />
                        <h4>确定要{data.isOpen == '1' ? '暂停' : '开启'}此活动吗？</h4>
                    </div>
                    <span>你还要继续吗？</span>
                </div>
            </Modal>
        );

    }
}

DeleteModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default DeleteModal;