/**
 * 薪人开票管理
 */
import React, { Fragment } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav, XInput, XSelect, XOKButton, XCancelButton, XDatePicker, XInputNum } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/paybill/model';
import TableContent from './components/TableContent';
import ApplyInvoice from './modal/applyInvoice';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';
import { SearchBox, SearchItem } from '@/components/Layout';
import { regFenToYuan } from '@/util/money';
import { message } from 'antd';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.batchData = [];
    }

    state = {
        payStatus: [], // 状态枚举下拉列
        openModeParam: null, // 弹框入参
        orderId: undefined, // 付款单号
        status: {}, // 状态选择
        currentOpenMode: '',
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // 获取状态枚举下拉列
        http('/enum/PayrollerApplyInvoiceStatus').then((res = {}) => {
            if (res.code === 200) {
                this.setState({ payStatus: res.result });
            }
        });
    }


    // 查询
    _searchHandle = (useCache) => {
        const { applyInvoiceIds, startTime, endTime, status, pageNum, pageSize } = this.state;
        this.searchCache = {
            applyInvoiceIds: applyInvoiceIds ? [applyInvoiceIds] : [],
            status: status.code,
            startTime,
            endTime,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };

        this.props.getApplyInvoiceList(this.searchCache); // 发起请求
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ applyInvoiceIds: undefined, startTime: undefined, endTime: undefined, status: {}, pageNum: 1, pageSize: 20 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    // 导出
    export = () => {
        const { batchData, endTime, startTime, status, pageNum, pageSize } = this.state;
        const param = {
            applyInvoiceIds: batchData,
            status: status.code,
            startTime,
            endTime,
            pageNum,
            pageSize
        };
        http('/settle/payroller/exportApplyInvoiceList', param, 'POST').then((res) => {
            if (res.result) {
                const regular = /(http|https):\/\/([\w.]+\/?)\S*/;
                regular.test(res.result) ? (location.href = res.result, message.success('导出成功')) : message.warning('导出路径无效');
            } else {
                message.warning('暂无数据');
            }
        }).catch((res = {}) => {
            message.error(res.message);
        });

    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'applyinvoice':
                return <ApplyInvoice data={this.state.openModeParam} title='申请开票-开票信息' visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', openModeParam: null }, () => {
            if (update) {
                this.setState({ batchData: [] }, () => {
                    this._searchHandle('useCache');
                });
            }
        });
    }
    _tableAction = (selectedRowKeys, type, selectedRows) => {
        if (type == 'batchData') {
            this.setState({ batchData: selectedRowKeys });
        }
    }
    render() {
        const { applyInvoiceIds, status, startTime, endTime, payStatus } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                <KeepAlive render={(state) => {
                    if (!this.state.__aliveInit) {
                        this.setState({ ...state, __aliveInit: true }, () => {
                            this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                        });
                    }
                }}>
                    <>
                        <SearchBox>
                            <SearchItem>
                                <XInputNum
                                    label='发票编号'
                                    placeholder='请输入发票编号'
                                    value={applyInvoiceIds}
                                    bindThis={this}
                                    bindName='applyInvoiceIds'
                                    min={0}
                                    precision={0}
                                    maxLength={16} 
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='状态'
                                    placeholder='请选择'
                                    renderData={payStatus}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={status.value}
                                    bindThis={this}
                                    bindName='status'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XDatePicker
                                    label='申请日期'
                                    value={startTime}
                                    bindThis={this}
                                    bindName='startTime'
                                    isFormat
                                />
                                <XDatePicker
                                    style={{ marginRight: '20px' }}
                                    onChange={this._orderEndTimeChangeHandle}
                                    value={endTime}
                                    bindThis={this}
                                    bindName='endTime'
                                    isFormat
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                        <div className={styles.row2}>
                            <XOKButton
                                label='导出'
                                style={{ width: 90, marginRight: 20 }}
                                onClick={() => this.export()}
                            />
                            <XOKButton
                                label='申请开票'
                                style={{ width: 90, marginRight: 20 }}
                                onClick={() => {
                                    this.setState({ currentOpenMode: 'applyinvoice' });
                                }}
                            />
                        </div>
                        <TableContent
                            renderData={this.props.ApplyInvoiceList}
                            tableAction={this._tableAction}
                            paginationChange={this._paginationChange}
                        />
                        {this._renderMode()}
                    </>
                </KeepAlive>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    ApplyInvoiceList: PropTypes.object,
    getApplyInvoiceList: PropTypes.func
};
const mapStateToProps = (state) => ({
    ApplyInvoiceList: state.paybill.ApplyInvoiceList
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);