export const GET_INVOICELIST = 'INVOICELIST/GET_INVOICELIST';
export const SET_INVOICELIST = 'INVOICELIST/SET_INVOICELIST';

// 发票管理列表
// export const GET_INVOICEMANAGE

export const GET_INVOICEMANAGE_TABLESOURCE = 'INVOICELIST/GET_INVOICELIST';
export const SET_INVOICEMANAGE_TABLESOURCE = 'INVOICELIST/SET_INVOICELIST';

export const GET_INVOICEMANAGE_UNIT_SELECTSOURCE = 'INVOICELIST/GET_INVOICELIST';
export const SET_INVOICEMANAGE_UNIT_SELECTSOURCE = 'INVOICELIST/SET_INVOICELIST';


export const GET_REGIONALCOMPANYINVOICE_TABLESOURCE = 'REGIONALCOMPANYINVOICE/GET_REGIONALCOMPANYINVOICE_TABLESOURCE';
export const SET_REGIONALCOMPANYINVOICE_TABLESOURCE = 'REGIONALCOMPANYINVOICE/SET_REGIONALCOMPANYINVOICE_TABLESOURCE';

export const GET_REGIONALCOMPANYINVOICE_DETAIL = 'REGIONALCOMPANYINVOICE/GET_REGIONALCOMPANYINVOICE_DETAIL';
export const SET_REGIONALCOMPANYINVOICE_DETAIL = 'REGIONALCOMPANYINVOICE/SET_REGIONALCOMPANYINVOICE_DETAIL';