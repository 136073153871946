/**
 * 客服列表
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message, Tabs } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import GroupTable from './components/GroupTable';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/hotelmanage/facilitymanage/model';
import { XOKButton, showConfirm } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import CreateGroup from '../../modal/CreateGroup';

class Main extends Component {

    //初始化
    constructor(props) {
        super(props);
        this.state = {
            renderMode: '',//渲染弹框
            modeParams: {},//弹框入参
            tableType: 'HOTEL'
        };
    }

    //props校验
    static propTypes = {
        history: PropTypes.object,//路由对象
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getHotelFacilityList: PropTypes.func,//获取列表
        hotelFacilityList: PropTypes.object//列表数据
    }

    //挂载后
    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, groupName, gmtStartCreate, gmtEndCreate, tableType } = this.state;
        this.searchCache = {
            groupName, gmtStartCreate, gmtEndCreate,
            type: tableType,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getHotelFacilityList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ groupName: '', gmtStartCreate: null, gmtEndCreate: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建组别
    _addGroup = () => {
        this.setState({ renderMode: 'createGroup', modeParams: {} });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (id, type, value) => {

        switch (type) {
            case 'editInfo':
                this.setState({ renderMode: 'editGroup', modeParams: { ...value } });
                break;
            case 'deleteInfo':
                showConfirm('是否删除设施', '', () => {
                    http('hotel/installation/delHotelFacility/' + id, {}, 'POST')
                        .then(() => {
                            message.success('删除成功!');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { modeParams, renderMode, tableType } = this.state;

        let typeTitle = {
            HOTEL: '酒店设施',
            ROOM: '房间设施'
        }[tableType] || '';

        switch (renderMode) {
            case 'createGroup':
                return <CreateGroup
                    title={'新建' + typeTitle}
                    visible={true}
                    modeParams={modeParams}
                    closeMode={this._closeMode} />;
            case 'editGroup':
                return <CreateGroup
                    title={'编辑' + typeTitle}
                    visible={true}
                    modeParams={modeParams}
                    closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update, values) => {
        let { facilityId } = this.state.modeParams;
        let type = this.state.tableType;
        if (update) {
            http('/hotel/installation/editHotelFacility', { ...values, facilityId, type }).then((res) => {
                if (res.code === 200) {
                    update && this._searchHandle('useCache');
                    message.success('更新成功');
                }
            }).catch(e => message.error(e.message || '更新异常'));
        }
        this.setState({ renderMode: '', modeParams: {} });
    }

    _onChangeTab = (tableType) => {
        this.setState({ tableType }, () => this._searchHandle());
    }

    //渲染组件
    render() {
        const { tableType } = this.state;
        const { hotelFacilityList } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }
            }>
                <div className={styles.flexBoxContainer}>
                    <div>
                        <Tabs activeKey={tableType} onChange={this._onChangeTab}>
                            <Tabs.TabPane key='HOTEL' tab='酒店设施'></Tabs.TabPane>
                            <Tabs.TabPane key='ROOM' tab='房间设施'></Tabs.TabPane>
                        </Tabs>
                    </div>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: '120px' }}
                            label='新建设施'
                            onClick={this._addGroup}
                        />
                    </div>
                    <GroupTable tableType={tableType} renderData={hotelFacilityList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive >
        );
    }
}

//映射数据源
const mapStateToProps = (state) => ({
    hotelFacilityList: state.facilitymanage.hotelFacilityList,
});

//暴露组件
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
