import * as ACCOUNT from './actionTypes';

// 会员列表
export const getAccountList = (param) => ({
    type: ACCOUNT.GET_ACCOUNT_LIST,
    payload: param
});
// 服务商列表
export const getServiceList = (param) => ({
    type: ACCOUNT.GET_SERVICE_LIST,
    payload: param
});
// 商户
export const getMerchantList = (param) => ({
    type: ACCOUNT.GET_MERCHANT_LIST,
    payload: param
});
// 区域公司
export const getRegionalCompanyList = (param) => ({
    type: ACCOUNT.GET_COMPANY_LIST,
    payload: param
});
// 运营中心
export const getOperationCenterList = (param) => ({
    type: ACCOUNT.GET_OPERATIONCENTER_LIST,
    payload: param
});
// 平台
export const getPlatFormList = (param) => ({
    type: ACCOUNT.GET_PLATFORM_LIST,
    payload: param
});
// 账户费用明细
export const getCostDetailList = (param) => ({
    type: ACCOUNT.GET_COSTDETAIL_LIST,
    payload: param
});
// 账户费用明细
export const getCostDetailLists = (param) => ({
    type: ACCOUNT.GET_COSTDETAIL_LISTS,
    payload: param
});
// 订单费用明细
export const getOrderCostDetailList = (param) => ({
    type: ACCOUNT.GET_ORDERCOSTDETAIL_LIST,
    payload: param
});
// 服务订单明细
export const getServiceOrderList = (param) => ({
    type: ACCOUNT.GET_SERVICEORDER_LIST,
    payload: param
});
// 费用名称
export const getCostNameList = (param) => ({
    type: ACCOUNT.GET_COSTNAME_LIST,
    payload: param
});
// 服务管理收益明细
export const getServiceManageFeeList = (param) => ({
    type: ACCOUNT.GET_SERVICEMANAGEFEE_LIST,
    payload: param
});
// 区域公司--激活状态
export const getActiveCompanyState = (param) => ({
    type: ACCOUNT.GET_COMPANY_ACTIVE,
    payload: param
});