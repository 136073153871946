import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Button, Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';

export default class TableContent extends Component {

	static propTypes = {
		history: PropTypes.object,
		paginationChange: PropTypes.func,
		renderData: PropTypes.object,
		addOneBreadcrumbPath: PropTypes.func,
	}

	//页面跳转
	_jumpPage = (title, url) => {
		const { history, addOneBreadcrumbPath } = this.props;
		const baseUrl = '/home/auditmanage/upgradeareacompany';
		addOneBreadcrumbPath({
			title,
			path: `${baseUrl}${url}`,
		});
		history.push({
			pathname: `${baseUrl}${url}`
		});
	}

	// 表格操作
	_tableAction = (t, r) => {
		return (
			<div className={styles.action}>
				<div className={styles.item}
					onClick={() => this._jumpPage('普通会员升级服务天使详情', `/detail/${t}`)}
				>详情</div>
				{
					r.auditState.code === 'APPLY' && <div className={styles.item}
						onClick={() => this._jumpPage('普通会员升级服务天使审核', `/audit/${t}`)}
					>审核</div>
				}
				{
					r.auditState.code === 'APPROVAL' && <div className={styles.item}
						onClick={() => this._jumpPage('升级区域公司', `/upgrade/create/${t}`)}
					>升级区域公司</div>
				}
			</div>
		);
	}

	_conditionCell = (t, r) => {
		return <Fragment>
			<p>
				<span>推荐会员数：</span>
				<Button style={{ paddingLeft: 0 }} type='link'
					onClick={() => this._jumpPage('推荐会员数', `/detail/member/${r.giftId}`)}
				>{r.recommendUserNum}</Button>
			</p>
			<p>
				<span>团队会员数：</span>
				<Button style={{ paddingLeft: 0 }} type='link'
					onClick={() => this._jumpPage('团队会员数', `/detail/team/${r.giftId}`)}
				>{r.teamUserNum}</Button>
			</p>
			<p>
				<span>团队销售金额（元）：</span>
				<Button style={{ paddingLeft: 0 }} type='link'
				// onClick={() => this._jumpPage('销售金额（元）', `/detail/market/${r.giftId}`)}
				>{regFenToYuan(r.teamUserTurnover)}</Button>
			</p>
			<p>
				<span>自购金额（元）</span>
				<Button style={{ paddingLeft: 0 }} type='link'
					onClick={() => this._jumpPage('自购金额（元）', `/detail/selfbuy/${r.accountName}`)}
				>{regFenToYuan(r.userMaxAmount)}</Button>
			</p>
		</Fragment>;
	}

	_columns = [
		{
			title: '会员账号',
			dataIndex: 'accountName',
			key: 'accountName',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '会员昵称',
			dataIndex: 'nickname',
			key: 'nickname',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '所属区域',
			dataIndex: 'areaCompanyName',
			key: 'areaCompanyName',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '升级条件',
			dataIndex: 'contact',
			key: 'contact',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: this._conditionCell
		},
		{
			title: '申请时间',
			dataIndex: 'applyUpgradeTime',
			key: 'applyUpgradeTime',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '状态',
			dataIndex: 'auditState.value',
			key: 'auditState.value',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作人',
			dataIndex: 'operatorUser',
			key: 'operatorUser',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: this._tableAction
		},
	]

	render() {
		const {
			renderData: {
				dataList = [],
				pageNum = 1,
				pageSize = 30,
				resultTotal = 0
			} = {},
			paginationChange } = this.props;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey={record => record.id}
					columns={this._columns}
					pagination={false}
					dataSource={dataList}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={resultTotal}
					pageSize={pageSize}
					pageNum={pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}