/**
 * 贡献值发放统计
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import ShowTable from './components/ShowTable';
import { XInput, XDatePicker, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/statistical/statistishow/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            telNumber: '',//手机号
            showTitle: '',//贡献值副标题
            rewardType: {},//奖励类型
            startTime: null,//开始时间
            endTime: null,//结束时间
        };
    }

    componentDidMount() {
        this.props.getRewardType();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, telNumber, showTitle, rewardType, startTime, endTime } = this.state;
        this.searchCache = {
            telephone: telNumber,
            xiubeiSubtitle: showTitle,
            xiubeiRewardType: rewardType.code,
            rewardStartTime: startTime != null ? startTime.format('YYYY-MM-DD HH:mm:ss') : null, //开始时间
            rewardEndTime: endTime != null ? endTime.format('YYYY-MM-DD HH:mm:ss') : null, // 结束时间
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getStatistiShow(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ telNumber: '', showTitle: '', rewardType: {}, startTime: null, endTime: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getStatistiShow(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { telNumber, showTitle, rewardType, startTime, endTime, } = this.state;
        const { showList, rewardTypeList } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='手机号'
                                placeholder='请输入手机号'
                                value={telNumber}
                                bindThis={this}
                                bindName='telNumber'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='贡献值副标题'
                                placeholder='请输入贡献值副标题'
                                value={showTitle}
                                bindThis={this}
                                bindName='showTitle'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='奖励类型'
                                placeholder='请选择'
                                renderData={rewardTypeList}
                                dataIndex='value'
                                keyIndex='code'
                                value={rewardType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='rewardType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='发放时间'
                                value={startTime}
                                bindThis={this}
                                bindName='startTime'
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                label=''
                                value={endTime}
                                bindThis={this}
                                bindName='endTime'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <ShowTable renderData={showList} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象

    showList: PropTypes.object,
    getStatistiShow: PropTypes.func,//表格数据(action)
    rewardTypeList: PropTypes.array,
    getRewardType: PropTypes.func,//奖励类型(action)
};

const mapStateToProps = (state) => ({
    showList: state.statistishow.showList, // 表格数据
    rewardTypeList: state.statistishow.rewardType, // 奖励类型
});

export default connect(mapStateToProps, { ...model.actions })(Main);
