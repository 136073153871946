/**
 *  服务清单 - 服务清单历史记录列表
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, message, } from 'antd';
import http from '@/assets/api/http';
import { XInput, XOKButton, XCancelButton, XPagination, XTableSelectionCount, XSelect, XInputNum } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import PDF from '../../modal/PDF';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/servicelistingmanage/servicehistory/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            number: '', // 文件编号
            username: {},//商户

            selectedRowKeys: [],//对应的key数据
            selectedRows: [],//对应的key行数据
        };
    }

    componentDidMount() {
        this.props.getProductList();//商户下拉acton
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getListTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 查询触发
    _saleSearchHandle = (useCache) => {
        const { number, username, pageSize, pageNum, } = this.state;
        this.searchCache = {
            id: number,//文件编号  
            merchantId: username.merchantId,//商户  
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getListTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _saleresetHandle = () => {
        this.setState({ number: '', username: {} }, () => {
            KeepAlive.saveResetFunc(this._saleSearchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //导出PDF
    _exportHandle = () => {
        const { selectedRowKeys, selectedRows } = this.state;
        if (selectedRowKeys.length == 0) {
            message.warn('至少选中一条清单信息');
        } else {
            const [data] = selectedRows;
            if (data.contractContent) {
                const regular = /(http|https):\/\/([\w.]+\/?)\S*/;
                regular.test(data.contractContent) ? (window.location.href = data.contractContent) : message.warning('路径无效');
            } else {
                message.warn('暂无数据');
            }
        }
    }

    // 表格操作功能回调
    _actionClickHandle = (id, item, type) => {
        const { updateContent } = this.props;
        switch (type) {
            case 'DELETE': //详情
                updateContent('DELETE', item);
                break;
            case 'LISTING': //查看清单 
                this.setState({
                    currentOpenMode: 'LISTING',
                    openModeParam: item,
                });
                break;
            case 'THESALES': //销售情况  
                updateContent('THESALES', id);
                break;
        }
    }

    // 打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'LISTING':
                return <PDF title='查看清单' data={this.state.openModeParam} visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [], selectedRows: [] });
    }
    // 表格单选输入
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
    };

    render() {
        const { productUsername } = this.props;
        const { number, username, selectedRowKeys } = this.state;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.tableList;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio'
        };
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._saleSearchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInputNum
                                label='文件编号'
                                placeholder='请输入'
                                value={number}
                                bindThis={this}
                                bindName='number'
                                min={0}
                                precision={0}
                                maxLength={16}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户'
                                placeholder='请选择商户'
                                renderData={productUsername || []}
                                bindThis={this}
                                bindName='username'
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                value={username.merchantName}
                                showSearch={true}
                                isOptionBindID={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._saleSearchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._saleresetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: 'auto' }} label='导出PDF' onClick={this._exportHandle} />
                    </div>
                    <div className={`${styles.tableContainer}`}>
                        <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record) => record.id}
                            rowSelection={rowSelection}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,

    getListTableSource: PropTypes.func, // 通过Saga异步请求表格数据源的Action
    tableList: PropTypes.object,
    productUsername: PropTypes.array,//商户下拉
    getProductList: PropTypes.func,//商户下拉acton
};


const mapStateToProps = (state) => {
    return {
        tableList: state.servicehistory.tableList, // 表格数据
        productUsername: state.servicehistory.getProductList.merchantIdAndNames,
    };
};

export default connect(mapStateToProps, { ...model.actions })(Main);