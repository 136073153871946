import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { message } from 'antd';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import TableData from './components/TableData';
import CreateEdit from '../../modal/CreateEdit';
import model from '@/reducers/productmanage/explainlist/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class ProductExplainlist extends Component {

    static propTypes = {
        getProductExplain: PropTypes.func,
        productExplain: PropTypes.object
    }

    state = {
        currentOpenMode: false, // 当前需要打开的弹窗
        explainItem: {}, // 当前需要打开的弹窗的入参
        serviceName: '',
    }

    // 查询
    _searchHandle = (useCache) => {
        const { serviceName, pageNum, pageSize } = this.state;
        this.searchCache = {
            serviceName,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getProductExplain(this.searchCache);
        KeepAlive.saveCache(this.searchCache); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ serviceName: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (id, type) => {
        switch (type) {
            case 'edit':
                this.setState({ currentOpenMode: true, explainItem: { url: '/goods/service/editService', id } });
                break;
            case 'delete':
                showConfirm('确定要删除此信息吗？', '您还要继续吗？', () => {
                    http('/goods/service/delService', { id }, 'POST')
                        .then(res => {
                            message.success(res.message);
                            this._searchHandle('useCache');
                        }).catch(err => { message.error(err.message); });
                });
                break;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: false, explainItem: {} }, () => {
            if (update) this._searchHandle('useCache');
        });
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { productExplain } = this.props;
        const { serviceName, currentOpenMode, explainItem } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='服务说明'
                                placeholder='请输入服务说明'
                                bindThis={this}
                                bindName='serviceName'
                                value={serviceName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }}
                            label='创建服务说明'
                            onClick={() => this.setState({ currentOpenMode: true, explainItem: { url: '/goods/service/addService' } })} />
                    </div>
                    <TableData paginationChange={this._paginationChange} renderData={productExplain} tableAction={this._tableAction} />
                    {
                        currentOpenMode &&
                        <CreateEdit explainItem={explainItem} closeMode={this._closeMode} />
                    }
                </div>
            </KeepAlive>
        );
    }

}
export default connect(state => ({
    productExplain: state.productExplain.productExplain
}), { ...model.actions })(ProductExplainlist);