/**
 * 编辑消息分类
 */
import React from 'react';
import { Modal, Input, Form, Radio, Icon, Checkbox, message } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CustomUpload, { generateFileList } from '@/components/NewUpload';
import { getMessageClassifyDetail, getMessagePushPort } from '@/reducers/messagemanage/messageClassify/actions';
import styles from './CreateModal.module.scss';
import http from '@/assets/api/http';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};
class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            imgs: generateFileList(props.data.img),
            confirmLoading: false,
        };
    }
    componentDidMount() {
        const { data: { messageTypeCode } } = this.props;
        this.props.getMessagePushPort();
        this.props.getMessageClassifyDetail({ messageTypeCode });
    }
    // className  img  state 
    _handleOk = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                console.log('提交', fieldsValue);
                const { imgs } = this.state;
                var imgUrl = imgs && imgs.map(i => i.url).toString();
                const param = {
                    ...fieldsValue,
                    img: imgUrl,
                    isStation: fieldsValue.isStation ? 1 : 0,
                };
                this.setState({ confirmLoading: true }, () => {
                    http('/user/messageType/updateMessageType', param, 'POST')
                        .then(() => {
                            message.success('编辑成功!');
                            this.setState({
                                visible: false,
                                confirmLoading: false,
                            });
                            this.props.refsh();
                        }).catch((reject) => {
                            message.error(reject.message);
                            this.props.closeMode(true);
                            this.setState({
                                visible: false,
                                confirmLoading: false,
                            });
                        });
                });
            }
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { messageClassifyDetail, messagePushPort } = this.props;
        return (
            <Modal
                width={600}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} className="create-form">
                        <Form.Item
                            colon={false}
                            style={{ textAlign: 'left' }}
                            htmlFor='messageTypeCode'
                            label={<span>分类编码</span>}
                        >
                            {getFieldDecorator('messageTypeCode', {
                                rules: [{ required: true, message: '请输入' }],
                                initialValue: messageClassifyDetail.messageTypeCode,
                            })(<Input placeholder='请输入' style={{ width: 220 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            style={{ textAlign: 'left' }}
                            htmlFor='messageTypeName'
                            label={<span>分类名称</span>}
                        >
                            {getFieldDecorator('messageTypeName', {
                                rules: [{ required: true, message: '请输入' }],
                                initialValue: messageClassifyDetail.messageTypeName,
                            })(<Input placeholder='请输入' style={{ width: 220 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='img'
                            style={{ textAlign: 'left' }}
                            label={<span>图片</span>}
                        >
                            {getFieldDecorator('img', {
                                initialValue: this.state.imgs,
                                rules: [{ required: true, message: '请输入' }],
                            })(
                                <CustomUpload
                                    accept='.png'
                                    fileList={this.state.imgs}
                                    listType="picture-card"
                                    wallMode="single"
                                    qiniuUploadProps={{
                                        uploadType: 'image'
                                    }}
                                    onChange={(info) => {
                                        const imgs = info.fileList.length > 1 ? [info.fileList.pop()] : info.fileList;
                                        this.setState({ imgs });
                                    }}
                                    beforeUpload={(file, fileList) => {
                                        const isPng = file.type === 'image/png';
                                        if (!isPng) {
                                            message.error('只能上传png格式的图片!');
                                        }
                                        const isLt1M = file.size / 1024 / 1024 < 1;
                                        if (!isLt1M) {
                                            message.error('图片必须小于 1MB!');
                                        }
                                        return isPng && isLt1M;
                                    }}
                                >
                                    <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                </CustomUpload>
                            )}
                            <span>支持PNG格式</span>
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='state'
                            label={<span>状态</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('state', {
                                rules: [{ required: true, message: '请输入' }],
                                initialValue: messageClassifyDetail.state,
                            })(
                                <Radio.Group style={{ marginLeft: 10 }}>
                                    <Radio value={1}>启用</Radio>
                                    <Radio value={0}>禁用</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='isStation'
                            style={{ textAlign: 'left' }}
                            label={<span style={{ marginLeft: 10 }}></span>}
                        >
                            {getFieldDecorator('isStation', {
                                rules: [{ required: true, message: '请选择' }],
                                valuePropName: 'checked',
                                initialValue: messageClassifyDetail.isStation,
                            })(<Checkbox style={{ marginLeft: 8 }}>是否前端显示</Checkbox>)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='systemTypes'
                            style={{ textAlign: 'left' }}
                            label={<span>推送端口</span>}
                        >
                            {getFieldDecorator('systemTypes', {
                                rules: [{ required: true, message: '请选择' }],
                                initialValue: messageClassifyDetail.systemTypes || [],
                            })(<Checkbox.Group
                                style={{ textAlign: 'left' }}
                            >
                                {messagePushPort.map(i => {
                                    return <Checkbox style={{ marginLeft: 8, width: 100 }} value={i.code} key={i.code}>{i.value}</Checkbox>;
                                })}
                            </Checkbox.Group>)}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );

    }
}

CreateModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
    getMessageClassifyDetail: PropTypes.func,
    getMessagePushPort: PropTypes.func,
    messagePushPort: PropTypes.array,
    messageClassifyDetail: PropTypes.object,
    refsh: PropTypes.func,
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(CreateModal);// 校验
const mapStateToProps = (state) => ({
    messageClassifyDetail: state.MessageClassify.messageClassifyDetail,
    messagePushPort: state.MessageClassify.messagePushPort,
});
export default connect(mapStateToProps, { getMessageClassifyDetail, getMessagePushPort })(ForgotProducDetails);
