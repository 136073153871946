/**
 * 卡券活动数据
 */
import React from 'react';
import { Modal, Tabs, } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './ActivityDate.module.scss';
import ActivityDateTable from '../components/ActivityDateTable';
import OrderDateTable from '../components/OrderDateTable';
import model from '@/reducers/marketingmanage/cardticket/model';

const { TabPane } = Tabs;

class ActivityDate extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        activeKeyDiff: undefined,//处理tab左右切换只请求两次
    }

    componentDidMount() {
        let couponId = (this.props.data || {}).id;
        this.props.getCardticketInfo({ couponId });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }


    TabsCallback = (activeKey) => {
        console.log(activeKey);
        //处理tab左右切换只请求两次
        this.setState({ activeKeyDiff: activeKey });
    }


    render() {
        const { visible, title, confirmLoading, activeKeyDiff } = this.state;
        const { cardticketInfo, data } = this.props;
        const { countAll = '-', countUsed = '-' } = cardticketInfo || {};


        return (
            <Modal
                width={960}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                footer={null}
            >
                <div className={styles.container}>
                    <div className={`${styles.layout} ${styles.dataTop}`}>
                        <div className={`${styles.layout}`}>
                            <div><h4>总发行：</h4><span>{countAll}张</span></div>
                            <div><h4>已使用：</h4><span>{countUsed}张</span></div>
                        </div>
                    </div>
                    <Tabs defaultActiveKey="1" onChange={this.TabsCallback}>
                        <TabPane tab="发放详情" key="1">
                            <div className={styles.table}>
                                <ActivityDateTable data={data} activeKeyDiff={activeKeyDiff} />
                            </div>
                        </TabPane>
                        <TabPane tab="使用详情" key="2">
                            <div className={styles.table}>
                                <OrderDateTable data={data} activeKeyDiff={activeKeyDiff} />
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </Modal >
        );
    }
}

ActivityDate.propTypes = {
    title: PropTypes.object,// 弹框标题
    data: PropTypes.object, // 需要修改魅力值的会员对象数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数

    cardticketInfo: PropTypes.object,
    getCardticketInfo: PropTypes.func
};

const mapStateToProps = (state) => ({
    cardticketInfo: state.cardticket.cardticketInfo,
});

export default connect(mapStateToProps, { ...model.actions })(ActivityDate);