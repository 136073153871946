export const GET_USERLIDT_DATA = 'USERREPORT/GET_USERLIDT_DATA'; // 用户举报列表
export const SET_USERLIDT_DATA = 'USERREPORT/SET_ATMOSPG_DATA'; // 用户举报列表

export const GET_REPORTSTATE_DATA = 'USERREPORT/GET_REPORTSTATE_DATA'; // 举报状态数据源
export const SET_REPORTSTATE_DATA = 'USERREPORT/SET_REPORTSTATE_DATA'; //举报状态数据源

export const GET_REPORTSTOP_DATA = 'USERREPORT/GET_REPORTSTOP_DATA'; // 用户举报禁播，封禁类型和封禁理由数据源
export const SET_REPORTSTOP_DATA = 'USERREPORT/SET_REPORTSTOP_DATA'; //用户举报禁播，封禁类型和封禁理由数据源

export const GET_REPORTDETAIL_DATA = 'USERREPORT/GET_REPORTDETAIL_DATA'; // 举报详情
export const SET_REPORTDETAIL_DATA = 'USERREPORT/SET_REPORTDETAIL_DATA'; //举报详情

//listUserReport  getreportState
