import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import { Button, InputNumber, Typography, message, Divider } from 'antd';
import model from '@/reducers/systemmanage/servicesetting/model';
import http from '@/assets/api/http';

const { getPrivilegeParam, updateParams } = model.actions;

const { Text } = Typography;

const Franchise = ({
    getPrivilegeParam,
    privilegeParam = [],
    updateParams
}) => {

    const [loading, setLoading] = useState(false);

    const listData = [
        {
            id: 1,
            title: '（开通服务编号SJ0001）服务商推荐服务商 【区域公司类型=直营】',
            recommendType: 'SERVICE_APP', // SERVICE_APP：服务商推荐、TALKER：说客推荐
            buyerType: 'SERVICE_APP', // SERVICE_APP: 服务商购买、TALKER：说客购买
            regionCompanyType: 'DIRECT', // DIRECT: 直营、AGENCY：代理
        },
        {
            id: 2,
            title: '（开通服务编号SJ0001）服务商推荐服务商 【区域公司类型=代理】',
            recommendType: 'SERVICE_APP', // SERVICE_APP：服务商推荐、TALKER：说客推荐
            buyerType: 'SERVICE_APP', // SERVICE_APP: 服务商购买、TALKER：说客购买
            regionCompanyType: 'AGENCY', // DIRECT: 直营、AGENCY：代理
        },
        {
            id: 3,
            title: '（开通服务编号SJ0002）服务商推荐服务商 【区域公司类型=直营】',
            recommendType: 'SERVICE_APP',
            buyerType: 'SERVICE_APP',
            regionCompanyType: 'DIRECT',
        },
        {
            id: 4,
            title: '（开通服务编号SJ0002）服务商推荐服务商 【区域公司类型=代理】',
            recommendType: 'SERVICE_APP',
            buyerType: 'SERVICE_APP',
            regionCompanyType: 'AGENCY',
        },
        {
            id: 5,
            title: '（开通服务编号SJ0003）服务商推荐说客 【区域公司类型=直营】',
            recommendType: 'SERVICE_APP',
            buyerType: 'TALKER',
            regionCompanyType: 'DIRECT',
        },
        {
            id: 6,
            title: '（开通服务编号SJ0003）服务商推荐说客 【区域公司类型=代理】',
            recommendType: 'SERVICE_APP',
            buyerType: 'TALKER',
            regionCompanyType: 'AGENCY',
        },
        {
            id: 7,
            title: '（开通服务编号xy04）服务商推荐每日优仓 【区域公司类型=直营】',
            recommendType: 'SERVICE_APP',
            regionCompanyType: 'DIRECT',
        },
        {
            id: 8,
            title: '（开通服务编号xy04）服务商推荐每日优仓 【区域公司类型=代理】',
            recommendType: 'SERVICE_APP',
            regionCompanyType: 'AGENCY',
        },
        {
            id: 9,
            title: '（开通服务编号SJ0001）说客推荐服务商 【区域公司类型=直营】',
            recommendType: 'TALKER',
            buyerType: 'SERVICE_APP',
            regionCompanyType: 'DIRECT',
        },
        {
            id: 10,
            title: '（开通服务编号SJ0001）说客推荐服务商 【区域公司类型=代理】',
            recommendType: 'TALKER',
            buyerType: 'SERVICE_APP',
            regionCompanyType: 'AGENCY',
        },
        {
            id: 11,
            title: '（开通服务编号SJ0002）说客推荐服务商 【区域公司类型=直营】',
            recommendType: 'TALKER',
            buyerType: 'SERVICE_APP',
            regionCompanyType: 'DIRECT',
        },
        {
            id: 12,
            title: '（开通服务编号SJ0002）说客推荐服务商 【区域公司类型=代理】',
            recommendType: 'TALKER',
            buyerType: 'SERVICE_APP',
            regionCompanyType: 'AGENCY',
        },
        {
            id: 13,
            title: '（开通服务编号SJ0003）说客推荐说客 【区域公司类型=直营】',
            recommendType: 'TALKER',
            buyerType: 'TALKER',
            regionCompanyType: 'DIRECT',
        },
        {
            id: 14,
            title: '（开通服务编号SJ0003）说客推荐说客 【区域公司类型=代理】',
            recommendType: 'TALKER',
            buyerType: 'TALKER',
            regionCompanyType: 'AGENCY',
        },
        {
            id: 15,
            title: '（开通服务编号xy04）说客推荐每日优仓 【区域公司类型=直营】',
            recommendType: 'TALKER',
            regionCompanyType: 'DIRECT',
        },
        {
            id: 16,
            title: '（开通服务编号xy04）说客推荐每日优仓 【区域公司类型=代理】',
            recommendType: 'TALKER',
            regionCompanyType: 'AGENCY',
        }
    ];

    useEffect(() => {
        getPrivilegeParam();
    }, []);

    const subnitParams = () => {
        setLoading(true);
        http('/gatherbeans/updatePrivilegeParam', { privilegeParamList: privilegeParam.flat() }, 'POST')
            .then(res => {
                message.success(res.message);
                getPrivilegeParam();
                setLoading(false);
            }).catch((e = {}) => {
                message.error(e.message);
                setLoading(false);
            });
    };

    debugger;
    console.log(privilegeParam);
    return <div className={styles.container}>
        <div className={styles.inner}>
            {
                Array.isArray(privilegeParam) && privilegeParam.length > 0 && listData.map((item, index) => {
                    return <div className={styles.innerItem} key={item.id}>
                        <div className={styles.itmeTitle}>{item.title}</div>
                        <div className={styles.itmeMain}>
                            {
                                item.recommendType == 'SERVICE_APP' ?
                                    < div className={styles.mainItem}>
                                        <Text>推荐服务商获得</Text>
                                        <InputNumber
                                            className={styles.lroffet}
                                            placeholder='请输入'
                                            min={0}
                                            max={99999}
                                            precision={0}
                                            value={privilegeParam[index] ? privilegeParam[index][0].value : null}
                                            onChange={value => value != privilegeParam[index][0].value && updateParams({ index, self: 0, value })}
                                        />
                                        <Text>特权值</Text>
                                    </div> :
                                    <div className={styles.mainItem}>
                                        <Text>推荐说客获得</Text>
                                        <InputNumber
                                            className={styles.lroffet}
                                            placeholder='请输入'
                                            min={0}
                                            max={99999}
                                            precision={0}
                                            value={privilegeParam[index] ? privilegeParam[index][0].value : null}
                                            onChange={value => value != privilegeParam[index][0].value && updateParams({ index, self: 0, value })}
                                        />
                                        <Text>特权值</Text>
                                    </div>
                            }
                            {
                                item.buyerType === 'SERVICE_APP' &&
                                <div className={styles.mainItem}>
                                    <Text>开通的服务商，获得</Text>
                                    <InputNumber
                                        className={styles.lroffet}
                                        placeholder='请输入'
                                        min={0}
                                        max={99999}
                                        precision={0}
                                        value={privilegeParam[index] ? privilegeParam[index][1].value : null}
                                        onChange={value => value != privilegeParam[index][1].value && updateParams({ index, self: 1, value })}
                                    />
                                    <Text>特权值</Text>
                                </div>
                            }
                            {
                                item.buyerType === 'TALKER' &&
                                <div className={styles.mainItem}>
                                    <Text>开通的说客，获得</Text>
                                    <InputNumber
                                        className={styles.lroffet}
                                        placeholder='请输入'
                                        min={0}
                                        max={99999}
                                        precision={0}
                                        value={privilegeParam[index] ? privilegeParam[index][1].value : null}
                                        onChange={value => value != privilegeParam[index][1].value && updateParams({ index, self: 1, value })}
                                    />
                                    <Text>特权值</Text>
                                </div>
                            }
                            <div className={styles.mainItem}>
                                <Text>区域公司，获得</Text>
                                <InputNumber
                                    className={styles.lroffet}
                                    placeholder='请输入'
                                    min={0}
                                    max={99999}
                                    precision={0}
                                    value={
                                        privilegeParam[index] ? ((index === 6 || index === 7 || index === 14 || index === 15) ? privilegeParam[index][1].value : privilegeParam[index][2].value) : null
                                    }
                                    onChange={value => {
                                        if ((index === 6 || index === 7 || index === 14 || index === 15)) {
                                            value != privilegeParam[index][1].value && updateParams({ index, self: 1, value });
                                        } else {
                                            value != privilegeParam[index][2].value && updateParams({ index, self: 2, value });
                                        }
                                    }}
                                />
                                <Text>特权值</Text>
                            </div>
                            <div className={styles.mainItem}>
                                <Text>运营中心，获得</Text>
                                <InputNumber
                                    className={styles.lroffet}
                                    placeholder='请输入'
                                    min={0}
                                    max={99999}
                                    precision={0}
                                    value={
                                        privilegeParam[index] ? ((index === 6 || index === 7 || index === 14 || index === 15) ? privilegeParam[index][2].value : privilegeParam[index][3].value) : null
                                    }
                                    onChange={value => {
                                        if ((index === 6 || index === 7 || index === 14 || index === 15)) {
                                            value != privilegeParam[index][2].value && updateParams({ index, self: 2, value });
                                        } else {
                                            value != privilegeParam[index][3].value && updateParams({ index, self: 3, value });
                                        }
                                    }}
                                />
                                <Text>特权值</Text>
                            </div>
                        </div>
                        {(index % 2) != 0 ? <Divider /> : null}
                    </div>;
                })
            }
        </div>
        <div className={styles.button}>
            <Button type='primary' style={{ marginTop: '10px' }} loading={loading} onClick={subnitParams}>保存</Button>
        </div>
    </div >;
};
Franchise.propTypes = {
    history: PropTypes.object,
    getPrivilegeParam: PropTypes.func,
    privilegeParam: PropTypes.array,
    updateParams: PropTypes.func
};
export default connect(state => ({
    privilegeParam: state.servicesetting.privilegeParam,
}), { getPrivilegeParam, updateParams })(Franchise);