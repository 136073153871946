/**
 *  秀场管理 - 频道列表
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const Mode = {
    CHANNEL_LIST_HISTORY: 'CHANNEL_LIST_HISTORY', // 直播间历史记录
    CHANNEL_LIST_RECOMMEND: 'CHANNEL_LIST_RECOMMEND', // 直播间推荐
    CHANNEL_LIST_UNABLE: 'CHANNEL_LIST_UNABLE', // 直播间禁播
    STAR_LIGHT: 'STAR_LIGHT', // 直播间魅力值
    STAR_VALUE: 'STAR_VALUE', // 直播间人气值
    SELL: 'SELL', // 直播间销售分红
    VIDEO_LIST_CREATE: 'VIDEO_LIST_CREATE', // 创建视频
    VIDEO_LIST_RECOMMEND: 'VIDEO_LIST_RECOMMEND', // 视频推荐
    VIDEO_LIST_INFO: 'VIDEO_LIST_INFO', // 查看视频详情
    COMMUNITY_LIST_CREATE: 'COMMUNITY_LIST_CREATE', // 创建社区
    COMMUNITY_LIST_INFO: 'COMMUNITY_LIST_INFO', // 查看社区详情
    OMMUNITYY_LIST_RECOMMEND: 'OMMUNITYY_LIST_RECOMMEND', // 视频推荐
    SELL_VIOLATION: 'SELL_VIOLATION', // 直播间-本场违规
};

class ChannelList extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case Mode.CHANNEL_LIST_HISTORY: // 直播间列表 - 直播间历史记录
                addOneBreadcrumbPath({
                    title: '直播间历史记录',
                    path: '/home/showmanage/channellist/history/'
                });
                history.push({
                    pathname: '/home/showmanage/channellist/history/'
                });
                break;
            case Mode.CHANNEL_LIST_RECOMMEND: // 直播间列表 - 直播间推荐
                addOneBreadcrumbPath({
                    title: '推荐',
                    path: '/home/showmanage/channellist/recommend/'
                });
                history.push({
                    pathname: '/home/showmanage/channellist/recommend/' + value
                });
                break;
            case Mode.CHANNEL_LIST_UNABLE: // 直播间列表 - 禁播
                addOneBreadcrumbPath({
                    title: '禁播',
                    path: '/home/showmanage/channellist/prohibit/'
                });
                history.push({
                    pathname: '/home/showmanage/channellist/prohibit/' + value.accountId
                });
                break;
            case Mode.STAR_LIGHT: // 直播间列表 - 魅力值收入
                addOneBreadcrumbPath({
                    title: '魅力值收入',
                    path: '/home/showmanage/channellist/starLight/'
                });
                history.push({
                    pathname: '/home/showmanage/channellist/starLight/' + value
                });
                break;
            case Mode.STAR_VALUE: // 直播间列表 - 人气值收入
                addOneBreadcrumbPath({
                    title: '人气值收入',
                    path: '/home/showmanage/channellist/starValue/'
                });
                history.push({
                    pathname: '/home/showmanage/channellist/starValue/' + value
                });
                break;
            case Mode.SELL: // 直播间列表 - 销售分红
                addOneBreadcrumbPath({
                    title: '销售分红',
                    path: '/home/showmanage/channellist/sell/'
                });
                history.push({
                    pathname: '/home/showmanage/channellist/sell/' + value
                });
                break;
            case Mode.VIDEO_LIST_CREATE: // 视频列表 - 创建视频
                addOneBreadcrumbPath({
                    title: '创建视频',
                    path: '/home/showmanage/channellist/videocreate/'
                });
                history.push({
                    pathname: '/home/showmanage/channellist/videocreate/',
                });
                break;
            case Mode.VIDEO_LIST_RECOMMEND: // 视频列表 - 视频推荐 
                addOneBreadcrumbPath({
                    title: '推荐',
                    path: '/home/showmanage/channellist/recommend/'
                });
                history.push({
                    pathname: '/home/showmanage/channellist/recommend/' + value
                });
                break;
            case Mode.VIDEO_LIST_INFO: // 视频列表 - 查看视频详情
                addOneBreadcrumbPath({
                    title: '视频详情',
                    path: '/home/showmanage/channellist/videoinfo/'
                });
                history.push({
                    pathname: '/home/showmanage/channellist/videoinfo/' + value
                });
                break;
            case Mode.COMMUNITY_LIST_CREATE: // 社区列表 - 创建社区
                addOneBreadcrumbPath({
                    title: '创建社区',
                    path: '/home/showmanage/channellist/communitycreate/'
                });
                history.push({
                    pathname: '/home/showmanage/channellist/communitycreate/',
                });
                break;
            case Mode.OMMUNITYY_LIST_RECOMMEND: // 社区列表 - 社区推荐 
                addOneBreadcrumbPath({
                    title: '推荐',
                    path: '/home/showmanage/channellist/recommend/'
                });
                history.push({
                    pathname: '/home/showmanage/channellist/recommend/' + value
                });
                break;
            case Mode.COMMUNITY_LIST_INFO: // 社区列表 - 查看社区详情
                addOneBreadcrumbPath({
                    title: '社区详情',
                    path: '/home/showmanage/channellist/communityinfo/'
                });
                history.push({
                    pathname: '/home/showmanage/channellist/communityinfo/' + value
                });
                break;
            case Mode.SELL_VIOLATION: // 直播间-本场违规
                addOneBreadcrumbPath({
                    title: '本场违规',
                    path: {
                        pathname: '/home/showmanage/channellist/violation',
                        search: `broadcasTitle=${value.broadcasTitle}&&nickname=${value.nickname}&&liveId=${value.id}&&accountId=${value.accountId}`,
                    }
                });
                history.push({
                    pathname: '/home/showmanage/channellist/violation',
                    search: `broadcasTitle=${value.broadcasTitle}&&nickname=${value.nickname}&&liveId=${value.id}&&accountId=${value.accountId}`,
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} match={this.props.match} />
        );
    }
}

ChannelList.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(ChannelList);