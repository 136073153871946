import { createModel } from '@/store/tools';

const model = {
    namespace: 'fightrecord',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getFightrecordList': T('getFightrecordList'),
            'getState': T('getState'),
            'getFightData': T('getFightData'),
        },
        actions: {
            'getFightrecordList': A('getFightrecordList'), //抢拍记录列表数据
            'getState': A('getState'), //抢拍状态            
            'getFightData': A('getFightData'), //抢拍记录统计
        },
        sagas: {
            'getFightrecordList': S('getFightrecordList', '/qiangPai/findQiangPaiRecord'),
            'getState': S('getState', '/enum/QiangPaiRecordStateEnum'),
            'getFightData': S('getFightData', '/qiangPai/findQiangPaiStatistic'),
        },
        reducers: {
            'fightrecordList': R('getFightrecordList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'stateList': R('getState', []),
            'fightData': R('getFightData', {}),
        }
    })
};
export default createModel(model);