import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Radio, Input, message, InputNumber, Typography, Checkbox } from 'antd';
import { XInput } from '@/components/xqxc_ui';
import { getShowSettingList, getchangOtherData, } from '@/reducers/systemmanage/showset/actions';
import http from '@/assets/api/http';
import model from '@/reducers/systemmanage/showset/model';
import { regFenToYuan, regYuanToFen } from '@/util/money';

const { Text } = Typography;

class Showpareme extends Component {

    static defaultProps = {
        enterpriseAnchorSet: []
    }

    constructor(props) {
        super(props);

        this.state = {
            homefew: '',//首页推荐数
            homefewTiem: '',//首页推荐时长
            livefew: '',//直播列表推荐数
            livefewTiem: '',//直播列表推荐时长
            videofew: '',//视频列表推荐数
            videofewTiem: '',//视频列表推荐时长
            communityfew: '',//社区列表推荐数
            communityfewTiem: '',//社区列表推荐时长

            showTiem: 1,//开播时长设置
            host: 1,//成为主播条件设置
            paramValue: '',//直播弹幕售价
            room: 1,//直播间号码规则字母
            roomValue: '',//直播间号码规则整数
            startLimit: 1,//赠送好友人气值限额
            startNum: '',//赠送好友人气值限额整形数字

            populedata: true,//初始化默认数据 作缓存仅作判断
            isPurchase: '',//购买直播时长
            isLive: '',//开播时长限制
            isAnchor: '', //关注主播弹窗
        };
        this.minNum = 0;
        this.maxNum = 0;
    }

    componentDidMount() {
        this.props.getShowSettingList();
        this.props.getchangOtherData('SHOW_PARAM');
        this.props.getEnterpriseAnchorSet({ reqCode: 'COMPANY_PARAM' });
    }

    componentDidUpdate(preProps) {
        for (let item of this.props.enterpriseAnchorSet) {
            if (item.paramCode == 'COMPANY_ANCHOR_START_PEOPLE') {
                this.minNum = item.paramValue;
            } else if (item.paramCode == 'COMPANY_ANCHOR_END_PEOPLE') {
                this.maxNum = item.paramValue;
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.populedata) {
            let changOther = nextProps.changOther;
            let isPurchase = ''; //购买直播时长
            let isLive = ''; //开播时长限制
            let isAnchor = ''; // 主播弹窗
            if (changOther.length > 0) {
                prevState.populedata = false;
                changOther.map((v) => {
                    v.paramCode == 'IS_PURCHASE_LIVE_TIME' ? isPurchase = v.paramValue : '';
                    v.paramCode == 'IS_LIVE_TIME_LIMIT' ? isLive = v.paramValue : '';
                    v.paramCode == 'REMIND_FOLLOW_TIME' ? isAnchor = v.paramValue : '';
                });
                return {
                    isPurchase,
                    isLive,
                    isAnchor,
                };
            }
        }
        return null;
    }

    // 开播时长设置
    _showTiem = (val) => {
        this.setState({ showTiem: val.target.value });
    }

    // 成为主播条件设置
    _host = (val) => {
        this.setState({ host: val.target.value });
    }

    // 赠送好友人气值限额
    _startLimit = (val) => {
        this.setState({ startLimit: val.target.value });
    }

    submithandle = () => {
        const { otherList = [{}] } = this.props.showSettingList || {};
        const { paramValue } = this.state;
        http('/show/recoconfig/update', { list: [{ paramValue, id: otherList[0].id, gmtModifiedOld: otherList[0].gmtModified }] }).then((res) => {
            if (res.code === 200) message.success('保存成功');
            else message.error('保存异常');
        });
    }

    clear = () => {
        this.setState({
            homefew: '',//首页推荐数
            homefewTiem: '',//首页推荐时长
            livefew: '',//直播列表推荐数
            livefewTiem: '',//直播列表推荐时长
            videofew: '',//视频列表推荐数
            videofewTiem: '',//视频列表推荐时长
            communityfew: '',//社区列表推荐数
            communityfewTiem: '',//社区列表推荐时长

            showTiem: 1,//开播时长设置
            host: 1,//成为主播条件设置
            paramValue: '',//直播弹幕售价
            room: 1,//直播间号码规则字母
            roomValue: '',//直播间号码规则整数
            startLimit: 1,//赠送好友人气值限额
            startNum: '',//赠送好友人气值限额整形数字
        });
    }

    updateChangeReco = (record, value) => {
        const { id, gmtModified, recoNumber, recoTimes } = record;
        http('/show/recoconfig/update', { list: [{ id, gmtModifiedOld: gmtModified, recoNumber, recoTimes, ...value, }] }).then((res = {}) => {
            if (res.code == '200') {
                message.success('修改成功');
                this.props.getShowSettingList();
            }
        }).catch((reject) => {
            message.warn(`${reject.message},请刷新页面。`);
        });
    }

    //直播间号码规则
    _Broadcasting = () => {
        let broadData = '';
        this.props.changOther.map((item) => {
            if (item.paramCode == 'LIVE_ROOM_NO_LENGTH') {
                broadData = item.paramValue;
            }
        });
        return broadData;
    }

    //赠送好友人气值限额
    _giveLimit = (data) => {
        return data == '0' ? '0' : '1';
    }

    //购买直播时长
    isPurchase = (e) => {
        this.setState({ isPurchase: e.target.value });
        http('/admin/param/batchUpdateParam', [{
            paramCode: 'IS_PURCHASE_LIVE_TIME',
            paramValue: e.target.value,
        }], 'POST').then((res) => {
            message.success(res.message);
            this.props.isPurchaseUpdata(e.target.value);
        }).catch((reject) => {
            message.success(reject.message);
            this.setState({ isPurchase: e.target.value == 0 ? 1 : 0 });
        });
    }

    //开播时长限制
    isLive = (e) => {
        this.setState({ isLive: e.target.value });
        http('/admin/param/batchUpdateParam', [{
            paramCode: 'IS_LIVE_TIME_LIMIT',
            paramValue: e.target.value,
        }], 'POST').then((res) => {
            message.success(res.message);
        }).catch((reject) => {
            message.success(reject.message);
            this.setState({ isLive: e.target.value == 0 ? 1 : 0 });
        });
    }
    // 关注主播弹窗
    isAnchor = (e) => {
        var isAnchorNum = e;
        if (Number(e) < 5 && Number(e) !== 0) {
            isAnchorNum = 5;
        }
        this.setState({ isAnchor: isAnchorNum });
    }
    // 关注主播弹窗
    isAnchorModal = e => {
        var isAnchorNum = e.target.value;
        if (Number(e.target.value) < 5 && Number(e.target.value) !== 0) {
            isAnchorNum = 5;
        }
        http('/admin/param/batchUpdateParam', [{
            paramCode: 'REMIND_FOLLOW_TIME',
            paramValue: isAnchorNum,
        }], 'POST').then((res) => {
            message.success(res.message);
        }).catch((reject = {}) => {
            message.warning(reject.message);
        });
    }

    // 直播机审（数美）
    isViolationChange = (e, code) => {
        http('/admin/param/batchUpdateParam', [{
            paramCode: code,
            paramValue: e.target.value,
        }], 'POST').then((res) => {
            message.success(res.message);
        }).catch((reject = {}) => {
            message.warning(reject.message);
        });
    }


    //请求函数
    onRequest = (data) => {
        http('/admin/param/batchUpdateParam', data, 'POST')
            .then((res) => {
                if (res.code === 200) message.success(res.message);
                else message.error(res.message);
                this.props.getEnterpriseAnchorSet({ reqCode: 'COMPANY_PARAM' });
            })
            .catch(error => {
                message.error(error.message);
            });
    }

    //渲染企业主播设置
    renderAnchor = (list) => {
        return list.map(item => {
            if (item.paramCode === 'OPEN_COMPANY_ANCHOR_CONFIG') {
                const tempArr = item.paramValue && JSON.parse(item.paramValue) || [];
                let checkedArr = [];
                tempArr.map(j => {
                    if (j.isOpen == 1) {
                        checkedArr.push(j.code);
                    }
                });
                return <div className={styles.anchorSet} key={item.paramCode}>
                    <Text strong>{item.paramAttr}：</Text>
                    <Checkbox.Group
                        value={checkedArr}
                        onChange={(e) => {
                            checkedArr = e;
                            tempArr.map(i => {
                                const index = checkedArr.findIndex(j => j == i.code);
                                return index !== -1 ? i.isOpen = 1 : i.isOpen = 0;
                            });
                            this.onRequest([
                                {
                                    paramCode: 'OPEN_COMPANY_ANCHOR_CONFIG',
                                    paramValue: JSON.stringify(tempArr)
                                }
                            ]);
                        }}
                    >
                        {tempArr.map(i => {
                            return <Checkbox value={i.code} key={i.code}>{i.name}</Checkbox>;
                        })}
                    </Checkbox.Group>
                    {/* <Radio.Group
                        className={styles.curt}
                        value={item.paramValue}
                        onChange={(e) => {
                            this.onRequest([
                                {
                                    paramCode: 'IS_OPEN_COMPANY_ANCHOR',
                                    paramValue: e.target.value
                                }
                            ]);
                        }}
                    >
                        <Radio value={'1'}>是</Radio>
                        <Radio value={'0'}>否</Radio>
                    </Radio.Group> */}
                </div>;
            }
            if (item.paramCode === 'COMPANY_ANCHOR_OPEN_MONEY') {
                return <div className={styles.anchorSet} key={item.paramCode}>
                    <Text strong>{item.paramAttr}：</Text>
                    <InputNumber
                        defaultValue={regFenToYuan(item.paramValue)}
                        precision={2}
                        maxLength={4}
                        min={1}
                        max={9999}
                        onBlur={(e) => {
                            if (!e.target.value) {
                                message.warning('企业主播开通单价最小值为1');
                            } else {
                                this.onRequest([
                                    {
                                        paramCode: 'COMPANY_ANCHOR_OPEN_MONEY',
                                        paramValue: regYuanToFen(e.target.value)
                                    }
                                ]);
                            }
                        }}
                    />
                    <Text strong style={{ paddingLeft: '10px' }}>元</Text>
                </div>;
            }
            if (item.paramCode == 'COMPANY_ANCHOR_START_PEOPLE') {
                return <Fragment key={item.paramCode}>
                    <Text strong style={{ paddingLeft: '60px' }}>企业主播开通人数：</Text>
                    <InputNumber
                        defaultValue={item.paramValue}
                        key={item.paramCode}
                        precision={0}
                        max={999}
                        min={2}
                        maxLength={3}
                        onBlur={(e) => {
                            if (Number(e.target.value) > Number(this.maxNum)) {
                                message.warning('最小人数不大于最大人数');
                            } else {
                                if (Number(e.target.value) < 2) {
                                    message.warning('最小人数不小于2');
                                } else {
                                    this.onRequest([
                                        {
                                            paramCode: 'COMPANY_ANCHOR_START_PEOPLE',
                                            paramValue: e.target.value
                                        }
                                    ]);
                                }
                            }
                        }} />
                    <Text strong style={{ padding: '0 5px' }}>至</Text>
                </Fragment>;
            }
            if (item.paramCode == 'COMPANY_ANCHOR_END_PEOPLE') {
                return <InputNumber
                    defaultValue={item.paramValue}
                    precision={0}
                    max={999}
                    min={2}
                    key={item.paramCode}
                    maxLength={3}
                    onBlur={(e) => {
                        if (Number(e.target.value) < Number(this.minNum)) {
                            message.warning('最大人数不小于最小人数');
                        } else {
                            if (Number(e.target.value) < 2) {
                                message.warning('最小人数不小于2');
                            } else {
                                this.onRequest([
                                    {
                                        paramCode: 'COMPANY_ANCHOR_END_PEOPLE',
                                        paramValue: e.target.value
                                    }
                                ]);
                            }
                        }
                    }} />;
            }
        });
    }

    render() {
        const { showRecoConfigList = [], otherList = [{}] } = this.props.showSettingList || {};
        const { changOther, enterpriseAnchorSet = [] } = this.props;

        return (
            <div style={{ overflowY: 'auto' }}>
                <div>
                    <div className={styles.paramers}>
                        <div className={styles.top_title} style={{ marginTop: 0 }}>
                            <span>秀场推荐时长</span>
                        </div>
                        {
                            showRecoConfigList.map((record) => {
                                let texts = record.remark.split(/{.+?}/);
                                return (
                                    <div key={record.id} className={`${styles.layout} ${styles.paramersTiems}`}>
                                        <span style={{ width: 'max-content' }}>{texts[0]}</span>&nbsp;
                                        <Input key={record.recoNumber} style={{ width: '90px' }} defaultValue={record.recoNumber} onBlur={(e) => this.updateChangeReco(record, { recoNumber: e.target.value })} />&nbsp;
                                        <span style={{ width: 'max-content' }}>{texts[1]}</span>&nbsp;
                                        {texts[2] !== undefined ? <><Input key={record.recoTimes} style={{ width: '90px' }} defaultValue={(record.recoTimes / 60)} onBlur={(e) => this.updateChangeReco(record, { recoTimes: e.target.value * 60 })} /> &nbsp;
                                            <b>{`分钟${texts[2]}`}</b></> : null}
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className={styles.paramerset}>
                        <div className={styles.top_title}>
                            <span>秀场其它设置</span>
                        </div>
                        <div className={`${styles.layout} ${styles.paramersTiems}`}>
                            <span>购买直播时长：</span>
                            <Radio.Group className={styles.curt} value={this.state.isPurchase} onChange={this.isPurchase}>
                                <Radio value={'1'}>开启</Radio>
                                <Radio value={'0'}>关闭</Radio>
                            </Radio.Group>
                            <b>开启购买直播时长</b>
                        </div>
                        <div className={`${styles.layout} ${styles.paramersTiems}`}>
                            <span>开播时长限制：</span>
                            <Radio.Group className={styles.curt} value={this.state.isLive} onChange={this.isLive}>
                                <Radio value={'1'}>是</Radio>
                                <Radio value={'0'}>否</Radio>
                            </Radio.Group>
                            <b>控制主播开播是否有时长限制</b>
                        </div>
                        {/* <div className={`${styles.layout} ${styles.paramersTiems}`}>
                                {
                                    changOther.map((item) => {
                                        if (item.paramCode == 'CONDITION_APPLY_FOR_ANCHOR') {
                                            return (<Fragment key={item.paramCode}>
                                                <span>成为主播条件设置：</span>
                                                <Radio.Group className={styles.curt} value={item.paramValue}>
                                                    <Radio readOnly={true} value={'ALL'}>所有人</Radio>
                                                    <Radio readOnly={true} value={'VIP'}>VIP会员</Radio>
                                                </Radio.Group>
                                                <b>选择成为主播条件，选择相关角色，前端开放相对入口</b>
                                            </Fragment>
                                            );
                                        }
                                    })
                                }
                            </div> */}
                        <div className={`${styles.layout} ${styles.paramersTiems}`}>
                            {
                                changOther.map((item) => {
                                    if (item.paramCode == 'LIVE_BARRAGE_STARLIGHT_NUM') {
                                        return (<Fragment key={item.paramCode}>
                                            <span>直播弹幕售价：</span>
                                            <Input readOnly={true} key={item.paramValue} style={{ width: '90px', marginLeft: '10px' }} defaultValue={item.paramValue} onChange={(e) => this.setState({ paramValue: e.target.value })} />
                                            <p>个魅力值</p>
                                            <b>每使用一次系统定义的弹幕需要消耗的魅力值</b>
                                        </Fragment>
                                        );
                                    }
                                })
                            }
                        </div>
                        <div className={`${styles.layout} ${styles.paramersTiems}`}>
                            {
                                changOther.map((item) => {
                                    if (item.paramCode == 'LIVE_ROOM_NO_PREFIX') {
                                        return (<Fragment key={item.paramCode}>
                                            <span>直播间号码规则：</span>
                                            <Input readOnly={true} key={item.paramValue} style={{ width: '90px', marginLeft: '10px' }} defaultValue={item.paramValue} />
                                            <p>+</p>
                                            <Input readOnly={true} style={{ width: '90px', marginLeft: '10px' }} value={this._Broadcasting()} />
                                            <p>位数字</p>
                                            <b>后台定义直播间号码生成规则</b>
                                        </Fragment>
                                        );
                                    }
                                })
                            }
                        </div>
                        {
                            changOther.map((item) => {
                                let texts = item.remark.split(/{.+?}/);
                                if (item.paramCode == 'PRESENT_STAR_VALUE_DAILY_LIMIT') {
                                    return (
                                        <div key={item.paramCode} className={`${styles.layout} ${styles.paramersTiems}`}>
                                            <span>{texts[0]}</span>
                                            <Radio.Group value={this._giveLimit(item.paramValue)} className={styles.curt} onChange={this._startLimit}>
                                                <Radio readOnly={true} value={'0'}>不限</Radio>
                                                <Radio readOnly={true} value={'1'}>每日可送</Radio>
                                            </Radio.Group>
                                            {item.paramValue != '0' && <XInput
                                                style={{ width: '100px', marginRight: '2px' }}
                                                inputStyle={{ width: '90px', }}
                                                labelStyle={{}}
                                                placeholder='（只读）'
                                                isRequired={false}
                                                value={item.paramValue}
                                                readOnly={true}
                                                bindThis={this}
                                                bindName='startNum'
                                            />}
                                            <b>{texts[1]}</b>
                                        </div>
                                    );
                                }
                            })
                        }
                        <div className={`${styles.layout} ${styles.paramersTiems}`}>
                            {
                                changOther.map((item) => {
                                    if (item.paramCode == 'REMIND_FOLLOW_TIME') {
                                        return (<Fragment key={item.paramCode}>
                                            <span>关注主播弹窗设置：</span>
                                            <InputNumber value={this.state.isAnchor} onBlur={e => this.isAnchorModal(e)} onChange={e => this.isAnchor(e)} max={60} precision={0} key={item.paramValue} style={{ width: '90px', marginLeft: '10px' }} defaultValue={item.paramValue} />
                                            <p>观众进入直播间后的时间，最小5，最大60，输入0则不弹窗</p>
                                        </Fragment>
                                        );
                                    }
                                })
                            }
                        </div>
                        <div className={`${styles.layout} ${styles.paramersTiems}`}>
                            {
                                changOther.map((item) => {
                                    if (item.paramCode == 'LIVE_MONITOR_SWITCH') {  //isViolation 
                                        return (<Fragment key={item.paramCode}>
                                            <span>直播机审（数美）：</span>
                                            <Radio.Group className={styles.curt} defaultValue={item.paramValue} onChange={(e) => { this.isViolationChange(e, item.paramCode); }}>
                                                <Radio value={'1'}>开启</Radio>
                                                <Radio value={'0'}>关闭</Radio>
                                            </Radio.Group>
                                        </Fragment>
                                        );
                                    }
                                })
                            }
                        </div>
                    </div>
                    {/* <div className={styles.layout}>
                            <XOKButton onClick={this.submithandle} style={{ width: '100px', margin: ' 0 0 20px 48px' }} label='保存' />
                            <Button onClick={this.clear} style={{ width: 100, marginLeft: 30 }}>重置</Button>
                        </div> */}
                </div>
                <div className={styles.anchorSetBox}>
                    <div className={styles.top_title}>
                        <span>企业主播设置</span>
                    </div>
                    {this.renderAnchor(enterpriseAnchorSet)}
                </div>
            </div>
        );
    }
}

Showpareme.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    showSettingList: PropTypes.object,
    getShowSettingList: PropTypes.func,
    changOther: PropTypes.array, // 赠送好友人气值限额等等
    getchangOtherData: PropTypes.func,// 赠送好友人气值限额等等数据方法Action
    isPurchaseUpdata: PropTypes.func,//控制直播时长充值规则tabs显隐
    getEnterpriseAnchorSet: PropTypes.func,
    enterpriseAnchorSet: PropTypes.array
};

const mapStateToProps = (state) => ({
    showSettingList: state.showset.showSettingList,
    changOther: state.showset.showsetDate.changOther,
    enterpriseAnchorSet: state.showset.enterpriseAnchorSet,
});


export default connect(mapStateToProps, { getShowSettingList, getchangOtherData, ...model.actions })(Showpareme);