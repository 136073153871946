/**
 * 用于快速建立整套saga流程的工具类
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

/**
 * 创建一个model对象，该对象封装了整套type+action+saga+reducer的流程，即异步请求和状态变更流程
 * @param {Model} model对象初始参数
 */
export const createModel = (model) => {
    let r = { namespace: model.namespace, actiontypes: {} };
    const T = (type) => createType(model.namespace, type);
    const A = (type) => createSagaAction(createType(model.namespace, type));
    const S = (type, sagaUrl, method = 'POST', IDName) => createSaga(createType(model.namespace, type), sagaUrl, method, IDName);
    const R = (type, state) => createReducer(createType(model.namespace, type), state);
    const C = (type, mark) => createReducerAction(createType(model.namespace, type), mark);
    r = {
        ...r,
        actiontypes: model.build(T, A, S, R, C).actiontypes
    };
    r = {
        ...r,
        actions: model.build(T, A, S, R, C).actions,
        sagas: model.build(T, A, S, R, C).sagas,
        reducers: model.build(T, A, S, R, C).reducers
    };
    return r;
};

/**
 * 创建类型
 * @param {String} namespace 类型名称 
 * @param {String} type 动作类型，用于标识该类型的性质，一般为SET、GET两种
 */
export const createType = (namespace, type = '') => {
    return namespace + '/' + type;
};

/**
 * 创建Saga动作
 * @param {String} typeName 类型名称
 */
export const createSagaAction = (typeName) => {
    const action = (value = {}) => ({
        type: createType(typeName, 'GET'),
        payload: value
    });
    return action;
};

/**
 * 创建Reducer动作，这类动作不会触发Saga流程，会直达Reducer触发
 * @param {String} typeName 类型名称
 * @param {String} mark 标记
 */
export const createReducerAction = (typeName, mark = 'CLEAN') => {
    const action = (value = {}) => ({
        type: createType(typeName, mark),
        payload: value
    });
    return action;
};

/**
 * 创建Saga
 * @param {String} typeName 类型名称
 * @param {String|Function} sagaUrl Saga实现，包括两种格式入参：异步请求地址|通过依赖注入的具体实现函数
 */
export const createSaga = (typeName, sagaUrl, method = 'POST', IDName) => {
    if (typeof sagaUrl === 'function') {
        const type = createType(typeName, 'GET');
        return sagaUrl(type);
    }
    return function* () {
        yield takeEvery(createType(typeName, 'GET'), function* requestData(action) {
            try {
                let result;
                if (IDName) {
                    if (Array.isArray(IDName)) {
                        let suffixStr = '';
                        for (let i = 0; i < IDName.length; i++) {
                            suffixStr += '/' + action.payload[IDName[i]];
                        }
                        result = yield http(sagaUrl + suffixStr, action.payload, method);
                    } else {
                        result = yield http(sagaUrl + '/' + action.payload[IDName], action.payload, method);
                    }
                } else {
                    result = yield http(sagaUrl, action.payload, method);
                }
                yield put({ type: createType(typeName, 'SET'), payload: result });
            } catch (e) {
                yield put({ type: createType(typeName, 'CLEAN'), payload: { error: e } });
            }
        });
    };
};

/**
 * 创建Reducer
 * @param {String|Function} typeName Reducer实现，包括两种格式入参：类型名称|通过依赖注入的具体实现函数
 * @param {String} autostate 默认状态
 */
export const createReducer = (typeName, autostate) => {
    let reducer;
    if (typeof typeName === 'function') {
        reducer = typeName(autostate);
    } else {
        reducer = (state = autostate, action) => {
            switch (action.type) {
                case createType(typeName, 'SET'):
                    if (action.payload.result && action.payload.result.result) {
                        return action.payload.result.result;
                    } else {
                        return action.payload.result;
                    }
                case createType(typeName, 'CLEAN'):
                    return autostate;
                default:
                    return state;
            }
        };
    }
    return reducer;
};


