/**
 * 评论管理  Saga集合
 */
import { communitySource, communityDetails, communitySelectData, communitySelectSource } from './communitycomment/saga';
import { getCommentList, getCommentDetail, getProductClassify } from './productcomment/saga';
import { getHotelCommentList, getHotelCommentDetail } from './hotelcomment/saga';
import { videoSource, videoDetails, videoSelectData, videoSelectSource } from './videocomment/saga';
import { getSensitiveWordInfo, getSensitiveWordInfos } from './sensitiveWord/saga';

export default [
    getCommentList,//商品评论列表
    getCommentDetail,// 商品评论详情
    getProductClassify, // 商品分类
    communitySource,//社区评论列表
    communityDetails,//社区评论详情
    communitySelectData,//社区评论下拉选项
    communitySelectSource,//社区评论查询
    videoSource,//视频评论列表
    videoDetails,//视频评论详情
    videoSelectData,//视频评论下拉选项
    videoSelectSource,//视频评论查询
    getSensitiveWordInfo,
    getSensitiveWordInfos,
    getHotelCommentDetail,
    getHotelCommentList
];