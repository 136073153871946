/**
 * 查看绑定用户
 */
import React from 'react';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { XInput, XOKButton } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './AddProduct.module.scss';
import AddProductTable from '../pages/videocreate/components/AddProductTable';
import { getListVideoProduct } from '@/reducers/showmanage/channellist/actions';

class AddProductModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        keyword: '',
        pageNum: 1,
        pageSize: 10,
        goodsList: []
    }

    componentDidMount() {
        const { pageNum, pageSize } = this.state;
        this.props.getListVideoProduct({ pageNum, pageSize });
    }

    _repetition = (arr) => {
        let obj = {};
        return arr.reduce(function (item, next) {
            obj[next.goodsId] ? '' : obj[next.goodsId] = true && item.push(next);
            return item;
        }, []);
    }

    _tableAction = (data) => {
        console.log(data);
        this.setState({
            goodsList: data
        });
    }

    _handleOk = () => {
        this.props.tableAction(null, 'product', this.state.goodsList);
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _inputChangeHandle = (value) => {
        this.setState({ keyword: value });
    }

    _searchHandle = () => {
        console.log('查看');
        const { pageNum, pageSize, keyword } = this.state;
        this.props.getListVideoProduct({ keyword, pageNum, pageSize });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        const { keyword, isCheck } = this.state;
        if (!isCheck) {
            this.props.getListVideoProduct({ keyword: '', pageNum, pageSize });
        } else {
            this.props.getListVideoProduct({ keyword, pageNum, pageSize });
        }
    }

    render() {
        const { visible, confirmLoading, keyword } = this.state;

        return (
            <Modal
                width={860}
                centered
                title='添加商品'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.input}>
                        <XInput
                            style={{ width: '214px', marginLeft: '-10px' }}
                            inputStyle={{ width: '192px' }}
                            label=''
                            placeholder='商品名称、货号'
                            onChange={this._inputChangeHandle}
                            value={keyword}
                        />
                        <XOKButton style={{ width: '104px' }} label='查看' onClick={this._searchHandle} />
                    </div>
                    <div className={styles.table}>
                        <AddProductTable tableAction={this._tableAction} paginationChange={this._paginationChange} renderData={this.props.listVideoProduct} />
                    </div>
                </div>
            </Modal>
        );
    }
}

AddProductModal.propTypes = {
    data: PropTypes.array, // 需要修改魅力值的会员对象数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    tableAction: PropTypes.func,

    getListVideoProduct: PropTypes.func,
    listVideoProduct: PropTypes.object
};

const mapStateToProps = (state) => ({
    listVideoProduct: state.channellist.listVideoProduct
});

export default connect(mapStateToProps, { getListVideoProduct })(AddProductModal);