import React from 'react';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import Marketing from '../marketing';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class Main extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }
    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case 'CREAT':
                addOneBreadcrumbPath({
                    title: '新建代言任务',
                    path: '/home/marketingmanage/advocacytask/createInfo'
                });
                history.push({
                    pathname: '/home/marketingmanage/advocacytask/createInfo',
                });
                break;
            case 'DETAIL':
                addOneBreadcrumbPath({
                    title: '代言任务详情',
                    path: '/home/marketingmanage/advocacytask/detailInfo/' + value,
                });
                history.push({
                    pathname: '/home/marketingmanage/advocacytask/detailInfo/' + value,
                });
                break;
            case 'EDIT':
                addOneBreadcrumbPath({
                    title: '代言任务编辑',
                    path: '/home/marketingmanage/advocacytask/editorInfo/' + value,
                });
                history.push({
                    pathname: '/home/marketingmanage/advocacytask/editorInfo/' + value,
                });
                break;
        }
    }


    render() {
        return (
            <div className={`${styles.flexBoxContainer}`}>
                <Marketing updateContent={this._updateContent} history={this.props.history} />
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};


export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);