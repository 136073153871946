import React from 'react';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import PropTypes from 'prop-types';

class AdvertTable extends React.Component {

    _takeColumns = () => {

        let cols = [
            {
                title: '菜单名称',
                dataIndex: 'menuName',
                key: 'menuName',
                width: '15%',
                align: 'left',
            },
            {
                title: '终端',
                dataIndex: 'affiliationType.value',
                key: 'affiliationType.value',
                width: '10%',
                align: 'center',
            },
            {
                title: '菜单分类',
                dataIndex: 'menuType',
                key: 'menuType',
                width: '10%',
                align: 'center',
            },
            {
                title: 'URL',
                dataIndex: 'menuUrl',
                key: 'menuUrl',
                width: '15%',
                align: 'center',
                render: (text) => (this._renderTextCell(text))
            },
            {
                title: '菜单ID',
                dataIndex: 'id',
                key: 'id',
                width: '10%',
                align: 'center',
                render: (text) => (this._renderTextCell(text))
            },
            {
                title: '状态',
                dataIndex: 'state',
                key: 'state',
                width: '5%',
                align: 'center',
                render: (text) => (this._renderStatusCell(text))
            },
            {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: '15%',
                align: 'center',
                render: (text) => (this._renderTextCell(text))
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: '20%',
                render: (text, item) => this._renderActionCell(item.id, item)
            }
        ];
        if(this.props.curTabID == 'MERCHANT'){
            cols.splice(1,1);
        }
        return cols;
    };

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderStatusCell = (text) => {
        var statusArr = [0, 1];
        var statusText = ['禁用', '正常'];
        if (statusArr.indexOf(text) !== -1) {
            return (
                <span>{statusText[text]}</span>
            );
        } else {
            return null;
        }
    }

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, item, 'edit')}>编辑</div>
                {item.state === 1 && <div className={styles.item} onClick={() => tableAction(key, item, 'forbid')}>禁用</div>}
                {item.state === 0 && <div className={styles.item} onClick={() => tableAction(key, item, 'start')}>启用</div>}
                <div className={styles.item} onClick={() => tableAction(key, item, 'del')}>删除</div>
            </div>
        );
    }

    render() {
        const { renderData } = this.props;

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.array, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    curTabID: PropTypes.string, // 当前tab类型
};

export default AdvertTable;