export const GET_MERCHANTINVOICE_TABLESOURCE = 'MERCHANTINVOICEMANAGE/GET_MERCHANTINVOICE_TABLESOURCE';
export const SET_MERCHANTINVOICE_TABLESOURCE = 'MERCHANTINVOICEMANAGE/SET_MERCHANTINVOICE_TABLESOURCE';

// 发票管理列表
// export const GET_INVOICEMANAGE
// 发票详情
export const GET_MERCHANTINVOICE_DETAIL = 'MERCHANTINVOICEMANAGE/GET_MERCHANTINVOICE_DETAIL';
export const SET_MERCHANTINVOICE_DETAIL = 'MERCHANTINVOICEMANAGE/SET_MERCHANTINVOICE_DETAIL';
// 结算单状态
export const GET_SETTLEMENT_STATUS = 'MERCHANTINVOICEMANAGE/GET_SETTLEMENT_STATUS';
export const SET_SETTLEMENT_STATUS = 'MERCHANTINVOICEMANAGE/SET_SETTLEMENT_STATUS';