import React from 'react';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import Config from '../config';
import ShopConfig from '../shopConfig';
import Scene from '../scene';
import Service from '../service';
class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '支付配置' },
            { id: 2, label: '支付场景' },
            // { id: 3, label: '增值服务支付配置' },
            { id: 4, label: '门店支付配置' },
        ],
        curTabID: 1
    }

    // Tab导航切换操作回调 
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        switch (this.state.curTabID) {
            case 1:
                return <Config curTabID={this.state.curTabID} />; // 支付配置
            case 2:
                return <Scene curTabID={this.state.curTabID} />; // 支付场景
            case 3:
                return <Service curTabID={this.state.curTabID} />; // 支付场景
            case 4:
                return <ShopConfig curTabID={this.state.curTabID} />; // 门店支付配置
            default:
                return <Config curTabID={this.state.curTabID} />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div style={{margin:'5px 0'}}></div>
                {this._renderPage()}
            </div>
        );
    }
}

export default Main;