import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, message } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import CreateModal from '../modal/CreateModal';
import EditModal from '../modal/EditModal';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, showConfirm } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/systemmanage/template/model';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class templateClassify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            className: '',//名称
            State: {},//状态
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: null, // 当前需要打开的弹窗的入参
        };
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
        this.props.getStateEnum();
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { className, State, pageNum, pageSize } = this.state;
        this.searchCache = {
            className,
            state: State.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getlistCustomTemplateClass(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发 
    _resetHandle = () => {
        this.setState({ className: '', State: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作功能回调 
    _actionClickHandle = (record, type, value) => {
        switch (type) {
            case 'edit'://编辑
                this.setState({ currentOpenMode: 'edit', openModeParam: record });
                break;
            case 'switch'://状态
                if (record.state && record.state.code == 1) {
                    showConfirm('当前分类模板被使用中，您是否确定关闭当前分类?', '', () => {
                        this.updateContent(record, value);
                    });
                } else if (record.state && record.state.code == 0) {
                    this.updateContent(record, value);
                }
                break;
            case 'delete'://删除
                showConfirm('删除后无法恢复，您是否确定删除?', '', () => {
                    http(`/admin/customtemplateclass/delCustomTemplateClass/${record.id}`, {}, 'POST')
                        .then(() => {
                            this._searchHandle('useCache');
                            message.success('删除成功。');
                        }).catch((reject = {}) => {
                            message.error(reject.message);
                        });
                });
                break;
            default: return null;
        }
    }

    updateContent = (record, value) => {
        http('/admin/customtemplateclass/updateCustomTemplateClassState', {
            state: value ? 1 : 0,
            id: record.id
        }, 'POST')
            .then(() => {
                this._searchHandle('useCache');
                message.success('更新成功。');
            }).catch((reject = {}) => {
                message.error(reject.message);
            });
    }
    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { currentOpenMode, openModeParam } = this.state;
        switch (currentOpenMode) {
            case 'create':
                return <CreateModal visible={true} title='新建分类' refsh={() => this._searchHandle('useCache')} closeMode={this._closeMode} />;
            case 'edit':
                return <EditModal visible={true} data={openModeParam} refsh={() => this._searchHandle('useCache')} title='编辑分类' closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentOpenMode: '', openModeParam: {} });
    }

    render() {
        const { StateEnum } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.listCustomTemplateClass;
        const { className, State } = this.state;
        return (
            <KeepAlive id='11' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XSelect
                                label='分类状态'
                                placeholder='请选择'
                                renderData={StateEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={State.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='State'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='分类名称'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='className'
                                value={className}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }} label='创建模板分类' onClick={() => {
                            this.setState({ currentOpenMode: 'create' });
                        }} />
                    </div>
                    <div className={`${styles.tableContainer}`}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record) => record.id}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    <div>{this._renderMode()}</div>
                </div>
            </KeepAlive>
        );
    }
}

templateClassify.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息
    getlistCustomTemplateClass: PropTypes.func,//表格数据(action)
    listCustomTemplateClass: PropTypes.object,
    getStateEnum: PropTypes.func,
    StateEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
    listCustomTemplateClass: state.templatemanage.listCustomTemplateClass || {}, // 表格数据
    StateEnum: state.templatemanage.StateEnum,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(templateClassify);
