/**
 * 【商品分类页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableData from './components/TableData';
import { getProductClassify } from '@/reducers/productmanage/classify/actions';

class ProductClassify extends React.Component {
    state = {
        classifyName: '', // 分类名称
        treeOpen: true,  // 表格树形结构状态（展开：true，收起：false）
        pageNum: 1,
        pageSize: 5
    }

    componentDidMount() {
        const { classifyName, pageNum, pageSize } = this.state;
        this.props.getProductClassify({ categoryName: classifyName, pageNum, pageSize }); // 获取商品分类
    }

    // 查询
    _searchHandle = () => {
        const { classifyName, pageNum, pageSize } = this.state;
        console.log(classifyName);
        this.props.getProductClassify({ categoryName: classifyName, pageNum, pageSize }); // 获取商品分类
    }

    // 重置
    _resetHandle = () => {
        this.setState({ classifyName: '' });
        this.props.getProductClassify({ categoryName: '', pageNum: 1, pageSize: 20 }); // 获取商品分类

    }

    // 展开分类
    _unfold = () => {
        this.setState({ treeOpen: true });
    }

    // 收起分类
    _packUp = () => {
        this.setState({ treeOpen: false });
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        const { classifyName } = this.state;
        this.props.getProductClassify({ categoryName: classifyName, pageNum, pageSize }); // 获取商品分类
    }

    render() {
        const { classifyName, treeOpen } = this.state;
        const { tableSource } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.search_box}>
                    <XInput
                        style={{ width: '266px' }}
                        inputStyle={{ width: '200px' }}
                        label='一级分类名称'
                        placeholder='请输入分类名称'
                        value={classifyName}
                        bindThis={this}
                        bindName='classifyName'
                    />
                    <XOKButton
                        style={{ marginLeft: '40px', width: '60px' }}
                        label='查询'
                        onClick={this._searchHandle}
                    />
                    <XCancelButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='重置'
                        onClick={this._resetHandle}
                    />
                </div>
                <div className={styles.operate_box}>
                    <XOKButton style={{ width: '100px' }} label='全部展开' onClick={this._unfold} />
                    <XOKButton style={{ marginLeft: '20px', width: '100px' }} label='全部收起' onClick={this._packUp} />
                </div>
                <TableData treeOpen={treeOpen} renderData={tableSource} paginationChange={this._paginationChange} tableAction={this._tableAction} />
            </div>
        );
    }
}

ProductClassify.propTypes = {
    updateContent: PropTypes.func,
    history: PropTypes.object,
    tableSource: PropTypes.object,
    getProductClassify: PropTypes.func,
};

const mapStateToProps = (state) => ({
    tableSource: state.classify.productClassifyList, // 商品分类
});

export default connect(mapStateToProps, { getProductClassify })(ProductClassify);