/**
 * 【商户发票管理列表】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm, XDatePicker, XTabNav } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getMerchantInvoiceTableSource, getSettlementStatus } from '@/reducers/storehousefinancialmanage/merchantinvoicemanage/actions';
import { getMerchantSelectSource, getStateSelectSource } from '@/reducers/storehousefinancialmanage/merchantreconciliation/actions';
import AdvertTable from './components/AdvertTable';
import DeleteModal from './modal/DeleteModal';
import SendInvoice from './modal/SendInvoice';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends React.Component {

    state = {
        companyStatus: {},
        status: {},
        chargeValue: [],
        tab: [
            { id: 1, label: '线上商户' },
            // { id: 2, label: '酒店商户' }
        ],
        curTabID: 1,  // 当前显示的Tab页ID
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getMerchantSelectSource();
        // 状态
        this.props.getSettlementStatus();
    }
    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }
    // 查询
    _searchHandle = (useCache) => {
        const { startDate, endDate, settlementId, pageNum, pageSize, companyStatus, status } = this.state;
        this.searchCache = {
            startDate,
            endDate,
            settlementId,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            companyId: companyStatus.merchantId,
            status: status.code,
        };
        this.props.getMerchantInvoiceTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ chargeValue: [], startDate: undefined, endDate: undefined, settlementId: '', pageNum: 1, pageSize: 10, companyStatus: {}, status: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        });
        const { startDate, endDate, settlementId, companyStatus, status } = this.state;
        this.searchCache = {
            startDate,
            endDate,
            settlementId,
            pageNum,
            pageSize,
            companyId: companyStatus.merchantId,
            status: status.code,
        };
        this.props.getMerchantInvoiceTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    _newHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建发票',
            path: '/home/storehousefinancialmanage/invoicemanage/createinvoice'
        });
        history.push({
            pathname: '/home/storehousefinancialmanage/invoicemanage/createinvoice'
        });
    };
    // 表格操作
    _tableAction = (id, type, provider) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'edit':
                addOneBreadcrumbPath({
                    title: '编辑发票',
                    path: '/home/storehousefinancialmanage/merchantinvoicemanage/createinvoice/' + id
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/merchantinvoicemanage/createinvoice/' + id,
                });
                break;
            case 'detail':
                addOneBreadcrumbPath({
                    title: '发票详情',
                    path: '/home/storehousefinancialmanage/merchantinvoicemanage/detail/' + id
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/merchantinvoicemanage/detail/' + id
                });
                break;
            case 'delete':
                showConfirm('是否确定删除？', '', () => {
                    http('/settle/settle/del', { settlementId: id }, 'POST')
                        .then(() => {
                            message.success('删除成功!');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'send':
                this.setState({ currentOpenMode: 'send', openModeParam: id });
                break;

        }
    }
    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'delete':
                return <DeleteModal title='删除发票' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'send':
                return <SendInvoice title='寄送发票' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

    _tabChangeHandle = () => { }

    render() {
        const { settlementId, companyStatus, status, startDate, endDate } = this.state;
        const { merchantInvoiceTableSource, settlementStatus } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={styles.flexBoxContainer} style={{ marginTop: 15 }}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='开票单号'
                                placeholder='请输入开票单号'
                                value={settlementId}
                                bindThis={this}
                                bindName='settlementId'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                showSearch
                                label='商户'
                                placeholder='请选择商户'
                                renderData={this.props.merchantSelectSource}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                value={companyStatus.merchantName}
                                bindThis={this}
                                bindName='companyStatus'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={settlementStatus}
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                                bindThis={this}
                                bindName='status'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                placeholder='请选择开始时间'
                                label='创建周期'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat={true}
                            />
                            <XDatePicker
                                placeholder='请选择结束时间'
                                style={{ marginRight: '20px' }}
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <AdvertTable settlementStatus={settlementStatus || []} renderData={merchantInvoiceTableSource} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getMerchantInvoiceTableSource: PropTypes.func,
    merchantInvoiceTableSource: PropTypes.object,
    getMerchantSelectSource: PropTypes.func,
    getStateSelectSource: PropTypes.func,
    merchantSelectSource: PropTypes.array,
    stateSelectSource: PropTypes.array,
    getSettlementStatus: PropTypes.func,
    settlementStatus: PropTypes.array,
};

const mapStateToProps = (state) => ({
    merchantInvoiceTableSource: state.merchantinvoicemanage.merchantInvoiceTableSource,
    merchantSelectSource: state.merchantreconciliation.merchantSelectSource,
    settlementStatus: state.merchantinvoicemanage.settlementStatus
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath, keepSecondNavBreadcrumb,
    getMerchantInvoiceTableSource,
    getMerchantSelectSource,
    getStateSelectSource,
    getSettlementStatus,
})(Main);