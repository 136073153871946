import * as T from './actiontypes';

export const getInvoiceList = (value) => ({
    type: T.GET_INVOICELIST,
    payload: value
});

export const getRegionalCompanyInvoiceTableSource = (value) => ({
    type: T.GET_REGIONALCOMPANYINVOICE_TABLESOURCE,
    payload: value
});

export const getInvoiceManageUnitSelectSource = (value) => ({
    type: T.GET_INVOICEMANAGE_UNIT_SELECTSOURCE,
    payload: value
});
export const getRegionalCompanyInvoiceDetail = (value) => ({
    type: T.GET_REGIONALCOMPANYINVOICE_DETAIL,
    payload: value
});


