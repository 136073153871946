/**
 *  【商品分类】入口页
 * */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XTabNav } from '@/components/xqxc_ui';
import Entity from './pages/main';
import Virtual from './pages/virtual';
import ThirdParty from './pages/thirdParty';
import { getLocalStorage } from '@/assets/js/storage';
// import http from '@/assets/api/http';
// import { message } from 'antd';
import IntraCity from './pages/intracity';

class ProductClassify extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                { id: 1, label: '线上商品分类', code: 'productmanage/productclassify/entitytab' },
                { id: 2, label: '虚拟商品分类', code: 'productmanage/productclassify/virtualtab' },
                { id: 4, label: '同城商品分类', code: 'productmanage/productclassify/intracitytab' },
                { id: 3, label: '第三方商品分类', code: 'productmanage/productclassify/thirdparty' },
            ],
            curTabID: 1,
            showTab: []
        };
    }

    // initPermission = () => {
    //     let btnPremission = JSON.parse(getLocalStorage('btnPremission')) || [];
    //     if (btnPremission.length == 0) {
    //         http('/admin/menu/listMenus', {}, 'POST').then((response) => {
    //             if (response.status == 200) {
    //                 btnPremission = this._getButtonUrlForChildren(response.result);
    //                 if (btnPremission.includes('productmanage/productclassify/thirdparty')) {
    //                     let tabs = this.state.tabs;
    //                     this.setState({ tabs: [...tabs, { id: 3, label: '第三方商品分类' }] });
    //                 }
    //             }
    //         }).catch((e) => {
    //             message.error(e.message);
    //         });
    //     } else {
    //         if (btnPremission.includes('productmanage/productclassify/thirdparty')) {
    //             let tabs = this.state.tabs;
    //             this.setState({ tabs: [...tabs, { id: 3, label: '第三方商品分类' }] });
    //         }
    //     }
    // }

    // _getButtonUrlForChildren = (treeData) => {
    //     let childrenEventKeys = [];
    //     for (let i = 0; i < treeData.length; i++) {
    //         if (treeData[i].menuType == '2' || treeData[i].menuType == '3') {
    //             childrenEventKeys.push(treeData[i].menuUrl);
    //         }
    //         if (treeData[i].childrens && treeData[i].childrens.length > 0) {
    //             const array = this._getButtonUrlForChildren(treeData[i].childrens);
    //             childrenEventKeys = Array.from(new Set([...childrenEventKeys, ...array]));
    //         }
    //     }
    //     return childrenEventKeys;
    // };

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
        // this.initPermission();
        this._getShowTab(); // Tab菜单权限管理
    }

    _getShowTab = () => {
        const { tabs } = this.state;
        let btnPremission = JSON.parse(getLocalStorage('btnPremission')) || [];
        setTimeout(() => { this._getShowTab(); }, 1000);
        let showTab = tabs.filter(i => btnPremission.includes(i.code) || !i.code);
        this.setState({ showTab });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        const { curTabID } = this.state;
        const { history } = this.props;
        switch (curTabID) {
            case 1:
                return <Entity curTabID={curTabID} history={history} />;
            case 2:
                return <Virtual curTabID={curTabID} history={history} />;
            case 3:
                return <ThirdParty curTabID={curTabID} history={history} />;
            case 4:
                return <IntraCity curTabID={curTabID} history={history} />;
            default:
                return <Entity curTabID={curTabID} history={history} />;
        }
    };

    render() {
        const { showTab, curTabID } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                {!!showTab.length && <XTabNav renderData={showTab} onChange={tab => {
                    this.setState({ curTabID: tab });
                }} activedID={curTabID} />}
                <div className={styles.flexBoxContainer} style={{ marginTop: '10px' }}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

ProductClassify.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { keepSecondNavBreadcrumb })(ProductClassify);