import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import DurationRulesModal from '../../modal/DurationRulesModal';
import DeleteModal from '../../modal/DeleteModal';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import { getLiveDurationRechargeRulesTableSource } from '@/reducers/systemmanage/showset/actions';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

// 模拟下拉列数据
const selectDataSource = [{ id: 1, name: '开启' }, { id: 0, name: '关闭' }];

class Touprules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rulesNum: '',//规则名称
            ruleState: {},//规则状态
            selectDataSource: [],//模拟下拉列数据
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
        };
    }

    componentDidMount() {

    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getLiveDurationRechargeRulesTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    //创建规则
    _createRules = () => {
        this.setState({
            currentOpenMode: 'creat',
            openModeParam: { state: 1 }
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { rulesNum, ruleState, pageNum, pageSize } = this.state;
        this.searchCache = {
            showMsg: rulesNum,
            state: ruleState.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getLiveDurationRechargeRulesTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发 
    _ruleresetHandle = () => {
        this.setState({ rulesNum: '', ruleState: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作功能回调 
    _actionClickHandle = (record, type, value) => {
        switch (type) {
            case 'EDIT'://编辑
                this.setState({
                    currentOpenMode: 'EDIT',
                    openModeParam: record
                });
                break;
            case 'switch'://状态
                http(`/show/timesConfig/${record.id}/${value ? 1 : 0}`).then(() => { this.props.getLiveDurationRechargeRulesTableSource(this.searchCache); });
                break;
            case 'DELETE'://删除
                this.setState({
                    currentOpenMode: 'DELETE',
                    openModeParam: record
                });
                break;
            default: return null;
        }
    }


    // 打开弹窗（创建/编辑
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'creat':
                return <DurationRulesModal title='创建规则' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'DELETE':
                return <DeleteModal title='删除' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'EDIT':
                return <DurationRulesModal title='编辑' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        if (status === 'delete') {
            http('show/timesConfig/delete/' + this.state.openModeParam.id).then(() => { this.props.getLiveDurationRechargeRulesTableSource(this.searchCache); });
        }
        if (status === true) {
            this.props.getLiveDurationRechargeRulesTableSource(this.searchCache);
        }
        this.setState({ currentOpenMode: '', openModeParam: {} });
    }

    render() {
        const { pageNum, pageSize, resultTotal, dataList } = this.props.liveDurationRechargeRulesTableSource;
        const { rulesNum, ruleState, } = this.state;
        return (
            <KeepAlive id='8' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.pro_commont}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='规则名称'
                                    placeholder='请输入'
                                    bindThis={this}
                                    bindName='rulesNum'
                                    value={rulesNum}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='规则状态'
                                    placeholder='请选择'
                                    renderData={selectDataSource}
                                    dataIndex='name'
                                    keyIndex='id'
                                    value={ruleState.name}
                                    showSearch={true}
                                    bindThis={this}
                                    bindName='ruleState'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._ruleresetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '90px' }} label='创建规则' onClick={this._createRules} />
                    </div>
                    <div className={`${styles.tableContainer}`}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record, index) => record.id}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    <div>{this._renderMode()}</div>
                </div>
            </KeepAlive>
        );
    }
}

Touprules.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    liveDurationRechargeRulesTableSource: PropTypes.object,
    getLiveDurationRechargeRulesTableSource: PropTypes.func,
};

const mapStateToProps = (state) => ({ liveDurationRechargeRulesTableSource: state.showset.liveDurationRechargeRulesTableSource });

export default connect(mapStateToProps, { getLiveDurationRechargeRulesTableSource })(Touprules);