import React, { useState } from 'react';
import PropType from 'prop-types';
import classNames from 'classnames';
import { Upload, message, Modal, Empty } from 'antd';
import useQiniuUpload from './QiniuUpload';
import { nonEmptyCall } from '@/util/utils';
import { getAudioDuration } from './_util';
import FileDragSort from './FileDragSort';
export default function CustomUpload(props) {
    const { wallMode, removeError, qiniuUploadProps, useQiniu, returnAudioDuration, className, onFileListChange, onChange, children, startSelectCard, forwardedRef,
        // 拖拽功能
        startDragSort, onDragSortChange,
        // 预览功能
        startPreviewImage, previewModalProps, previewEmptyProps, ...otherProps } = props;
    const [singleHidden, setSingleHidden] = useState(wallMode === 'single' && otherProps.listType === 'picture-card' && (props.fileList || props.defaultFileList || []).length > 0);
    const [previewImage, setPreviewImage] = useState();
    const [previewVisible, setPreviewVisible] = useState(false);
    if (wallMode === 'single') {
        let tempList = (props.fileList || props.defaultFileList || []).filter((f) => f.status !== 'removed' && f.status !== 'error');
        if (!singleHidden && tempList.length > 0) {
            setSingleHidden(true);
        }
        if (singleHidden && tempList.length === 0) {
            setSingleHidden(false);
        }
    }
    const [percent, setPercent] = useState(0);
    let customFileInfo;
    let customFile;
    const { qiniuUpload } = useQiniuUpload(qiniuUploadProps, {
        next(res) {
            if (customFile) {
                customFile.status = 'uploading';
                customFile.percent = res.total.percent;
                customFile.response = res;
                nonEmptyCall(onFileListChange, customFileInfo, customFile);
                setPercent(customFile.percent);
            }
        },
        error(res) {
            if (customFile) {
                customFile.status = 'error';
                customFile.percent = 0;
                customFile.response = res;
                nonEmptyCall(onFileListChange, customFileInfo, customFile);
                setPercent(customFile.percent);
            }
        },
        complete(res) {
            if (customFile) {
                customFile.status = 'done';
                customFile.size = res.size;
                customFile.percent = 100;
                customFile.response = res;
                nonEmptyCall(onFileListChange, customFileInfo, customFile);
                setPercent(customFile.percent);
            }
        }
    });
    //添加取消上传功能
    function commonChangeHandle(info) {
        if (removeError !== false)
            info.fileList = info.fileList.filter(f => f.status !== 'error');
        if (wallMode === 'single' && otherProps.listType === 'picture-card') {
            setSingleHidden(info.fileList.length > 0);
        }
        nonEmptyCall(onChange, info);
        nonEmptyCall(onFileListChange, info, customFile);
    }
    function onCustomChange(info) {
        customFileInfo = info;
        customFile = undefined;
        if (info.file.status !== 'removed') {
            customFileInfo.fileList.forEach((f) => {
                if (f.uid === customFileInfo.file.uid) {
                    customFile = f;
                }
            });
        }
        commonChangeHandle(info);
    }
    function uploadCatch(error) {
        console.log(error);
        message.error('文件上传异常，请重新上传');
        if (customFile) {
            customFile.status = 'error';
            customFile.response = '上传异常';
            commonChangeHandle(customFileInfo);
        }
    }
    function customBeforeUpload(file, fileList) {
        if (file.name.trim().split(/.+ +.+/).length > 1) {
            message.warning('文件名含有空字符串，可能导致上传异常');
        }
        const isUpload = nonEmptyCall(props.beforeUpload, file, fileList);
        if (useQiniu !== false && isUpload !== false) {
            new Promise((resolve, reject) => qiniuUpload(file, resolve, reject).catch((error) => uploadCatch(error))).then(({ fileUrl }) => {
                if (customFile) {
                    customFile.status = 'success';
                    customFile.url = fileUrl;
                    if (returnAudioDuration && file.type.startsWith('audio')) {
                        getAudioDuration(file).then((duration) => {
                            if (customFile) {
                                customFile.duration = duration;
                                nonEmptyCall(onFileListChange, customFileInfo, customFile);
                            }
                        });
                    }
                    else {
                        nonEmptyCall(onFileListChange, customFileInfo, customFile);
                    }
                }
            }).catch((error) => uploadCatch(error));
            return false;
        }
        return isUpload;
    }

    let previewProps = startPreviewImage
        ? {
            onPreview: (file) => {
                setPreviewImage(file.url);
                setPreviewVisible(true);
            }
        }
        : undefined;

    return (React.createElement(React.Fragment, null,
        React.createElement(Upload, Object.assign({ ref: forwardedRef }, previewProps, otherProps, { beforeUpload: customBeforeUpload, onChange: onCustomChange, className: classNames('lz-custom-upload', className, wallMode, { singleHidden, startSelectCard }) }),
            children,
            startDragSort ? React.createElement(FileDragSort, { fileList: (props.fileList || props.defaultFileList), onDragEnd: onDragSortChange, listType: otherProps.listType }) : null,
            React.createElement('span', { style: { display: 'none' } }, percent)),
        startPreviewImage &&
        React.createElement(Modal, Object.assign({ width: 'max-content', visible: previewVisible, footer: null, onCancel: () => setPreviewVisible(false) }, previewModalProps), previewImage
            ? React.createElement('img', { style: { width: '100%' }, src: previewImage })
            : React.createElement(Empty, Object.assign({ description: '\u65E0\u9884\u89C8\u56FE\u7247' }, previewEmptyProps)))));
}
CustomUpload.propTypes = {
    forwardedRef: PropType.func,
    removeError: PropType.bool,
    fileList: PropType.array,
    defaultFileList: PropType.array,
    beforeUpload: PropType.func,
    wallMode: PropType.string,
    startSelectCard: PropType.bool,
    qiniuUploadProps: PropType.object,
    useQiniu: PropType.object,
    returnAudioDuration: PropType.bool,
    className: PropType.string,
    onFileListChange: PropType.func,
    onChange: PropType.func,
    startDragSort: PropType.bool,
    onDragSortChange: PropType.func,
    startPreviewImage: PropType.bool,
    previewModalProps: PropType.object,
    previewEmptyProps: PropType.object,
    children: PropType.object
};