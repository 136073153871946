/**
 * 积分兑换
 */
import React from 'react';
import { message } from 'antd';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import http from '@/assets/api/http';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, XDatePicker } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';
import { Table } from 'antd';
import PointsAudit from './modal/PointsAudit';

class PointsTab extends React.Component {
    state = {
        id: '', // 申请单号
        realName: '', // 业务员
        mobilePhone: '', // 手机号码
        accountNo: '', // 业务账号
        auditType: {}, // 审核状态
        auditTypeEnum: [], // 状态枚举
        startDate: '', // 开始日期
        endDate: '', // 结束日期
        pageNum: 1,
        pageSize: 30,
        renderData: {
            dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0
        },
        modal: '',
        param: {}
    };

    componentDidMount() {
        this._getAuditTypeEnum();
        this._searchHandle();
    }

    _getAuditTypeEnum = () => {
        http('/enum/StockBuyerAuditTypeEnum').then((response) => {
            this.setState({ auditTypeEnum: response.result });
        }).catch((error) => {
            message.error(error.message);
        });
    }

    _searchHandle = () => {
        const { id, realName, mobilePhone, accountNo, auditType, startDate, endDate, pageNum, pageSize } = this.state;
        const query = {
            subjectType: 'SALES_MAN',
            id,
            realName,
            mobilePhone,
            accountNo,
            auditType: auditType.code,
            startDate,
            endDate,
            pageNum,
            pageSize
        };

        http('/settle/stockExchange/stockExchangeRecordList', query).then((response) => {
            this.setState({ renderData: response.result });
        }).catch((error) => {
            message.error(error.message);
        });
    }

    _resetHandle = () => {
        this.setState({
            id: '',
            realName: '',
            mobilePhone: '',
            accountNo: '',
            auditType: '',
            startDate: '',
            endDate: '',
            pageNum: 1,
            pageSize: 30,
        });
    }

    _takeDataSource = (dataList = []) => {
        return dataList;
    }

    _takeColumns = () => ([
        {
            title: '申请单号',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '业务员',
            dataIndex: 'realName',
            key: 'realName',
            width: '10%',
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '手机号码',
            dataIndex: 'mobilePhone',
            key: 'mobilePhone',
            width: '10%',
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '兑换积分',
            dataIndex: 'integral',
            key: 'integral',
            width: '10%',
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '兑换金额',
            dataIndex: 'amount',
            key: 'amount',
            width: '10%',
            align: 'left',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '兑换至账户',
            dataIndex: 'accountNo',
            key: 'accountNo',
            width: '10%',
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'auditType.value',
            key: 'auditType.value',
            width: '10%',
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '兑换时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '10%',
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'key',
            align: 'left',
            width: '10%',
            render: (key, item) => this._tableAction(item.id, item)
        },
    ]);

    // 表格操作
    _tableAction = (id, item) => {
        return (
            <div className={styles.action}>
                {
                    item.auditType && item.auditType.code == 'NEW_AUDIT' ? <div className={styles.item} onClick={() => {
                        this.setState({ modal: 'PointsAudit', param: item });
                    }}>
                        审核
                    </div> : null
                }
            </div>
        );
    };

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle();
        });
    }

    render() {
        const { id, realName, mobilePhone, accountNo, auditTypeEnum, auditType, startDate, endDate, renderData, modal, param } = this.state;
        const { pageNum, pageSize, resultTotal, dataList } = renderData;

        return (
            <div className={styles.pointsTab}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='申请单号'
                                placeholder='请输入16位数字编码'
                                bindThis={this}
                                bindName='id'
                                value={id}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='业务员'
                                placeholder='请输入30位以内汉字'
                                bindThis={this}
                                bindName='realName'
                                value={realName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='手机号码'
                                placeholder='请输入11位手机号码'
                                bindThis={this}
                                bindName='mobilePhone'
                                value={mobilePhone}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='业务账号'
                                placeholder='请输入8位数字'
                                bindThis={this}
                                bindName='accountNo'
                                value={accountNo}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={auditTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={auditType.value}
                                bindThis={this}
                                bindName='auditType'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='兑换日期'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                label=''
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.tableContainer}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record, index) => index}
                            columns={this._takeColumns()}
                            dataSource={this._takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ x: 'max-content', y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                </div>
                {
                    modal == 'PointsAudit' ? <PointsAudit
                        param={param}
                        close={(update) => {
                            if (update) this._searchHandle();
                            this.setState({ modal: '' });
                        }} /> : null
                }
            </div>
        );
    }
}

PointsTab.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
};

export default PointsTab;