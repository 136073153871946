/**
 * 视频审核页
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import TableMain from './conponents/TableMain';
import { connect } from 'react-redux';
import OnlinePlayModal from '../../modal/OnlinePlayModal';
import DetailModal from '../../modal/DetailModal';
import AdvocacyInfo from '../../modal/AdvocacyInfo';
import AuditModal from '../../modal/AuditModal';
import AdvocacyAudit from '../../modal/AdvocacyAudit';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getVideoAuditList, getVideoAuditSele, getEnumSystemType } from '@/reducers/auditmanage/videoAudit/actions';
import { getListVideoSelect } from '@/reducers/showmanage/channellist/actions';
import { SearchBox, SearchItem } from '@/components/Layout';

const Mode = {
    AUDIT_MODAL: 'AUDIT_MODAL',
    ONLINE_MODAL: 'ONLINE_MODAL',
    DETAIL_MODAL: 'DETAIL_MODAL'
};

const videoTypes = [
    { key: '0', value: '普通视频' },
    { key: '1', value: '代言任务' },
    { key: '2', value: '知识付费' }
];

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tel: undefined,
            nickName: undefined,
            status: {},
            videoType: {},
            feedClass: {}, // 视频分类
            systemType: {}
        };
    }

    componentDidMount() {
        this.props.getEnumSystemType();
        this.props.getVideoAuditSele();
        this.props.getListVideoSelect(); //视频分类
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, tel, nickName, status, videoType, feedClass, systemType } = this.state;
        this.searchCache = {
            tel: tel, nickName, state: status.code, taskType: videoType.key, systemType: systemType.code,
            feedClassId: feedClass.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getVideoAuditList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ tel: undefined, nickName: undefined, status: {}, videoType: {}, feedClass: {}, systemType: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    _tableAction = (id, record, type) => {
        switch (type) {
            case 'audit':
                this.setState({ openModeParam: id, currentOpenMode: Mode.AUDIT_MODAL, audit: record });
                break;
            case 'detail':
                this.setState({ openModeParam: id, currentOpenMode: Mode.DETAIL_MODAL, audit: record });
                break;
            case 'online':
                this.setState({ openModeParam: id, currentOpenMode: Mode.ONLINE_MODAL, audit: record });
                break;
        }
    }

    _renderMode = () => {
        const { currentOpenMode, audit } = this.state;
        switch (currentOpenMode) {
            case Mode.AUDIT_MODAL:
                // 是否有代言(1是;0否)
                if (audit.isTask) {
                    return <AdvocacyAudit visible={true} data={this.state.openModeParam} audit={audit} closeMode={this._closeMode} />;
                } else {
                    return <AuditModal visible={true} data={this.state.openModeParam} audit={audit} closeMode={this._closeMode} />;
                }
            case Mode.ONLINE_MODAL:
                return <OnlinePlayModal visible={true} data={this.state.openModeParam} audit={audit} closeMode={this._closeMode} />;
            case Mode.DETAIL_MODAL:
                // 是否有代言(1是;0否)
                if (audit.isTask) {
                    return <AdvocacyInfo visible={true} data={this.state.openModeParam} audit={audit} closeMode={this._closeMode} />;
                } else {
                    return <DetailModal visible={true} data={this.state.openModeParam} audit={audit} closeMode={this._closeMode} />;
                }
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', audit: {} }, () => {
            if (update) {
                this._searchHandle('useCache');
            }
        });
    }

    render() {
        const { tel, nickName, status, videoType, feedClass, systemType } = this.state;
        const { videoData, selectDataSource, listVideoSelect, systemTypeSelect } = this.props;

        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='手机号码'
                                placeholder='请输入手机号码'
                                bindThis={this}
                                bindName='tel'
                                value={tel}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='会员昵称'
                                placeholder='请输入会员昵称'
                                bindThis={this}
                                bindName='nickName'
                                value={nickName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='视频类型'
                                placeholder='请选择类型'
                                renderData={videoTypes}
                                bindThis={this}
                                bindName='videoType'
                                dataIndex='value'
                                keyIndex='key'
                                value={videoType.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='视频分类'
                                placeholder='请选择视频分类'
                                renderData={listVideoSelect && listVideoSelect.dataList ? listVideoSelect.dataList : []}
                                dataIndex='className'
                                keyIndex='id'
                                bindThis={this}
                                bindName='feedClass'
                                value={feedClass.className}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='审核状态'
                                placeholder='请选择状态'
                                renderData={selectDataSource}
                                bindThis={this}
                                bindName='status'
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='任务归属'
                                placeholder='请选择任务归属'
                                renderData={systemTypeSelect}
                                bindThis={this}
                                bindName='systemType'
                                dataIndex='value'
                                keyIndex='code'
                                value={systemType.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableMain paginationChange={this._paginationChange} renderData={videoData} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}


Main.propTypes = {
    getVideoAuditList: PropTypes.func, // 通过Saga异步请求表格数据源的Action
    getAnchorCoverSelect: PropTypes.func,
    getVideoAuditSele: PropTypes.func,
    videoData: PropTypes.object,
    selectDataSource: PropTypes.array,
    getListVideoSelect: PropTypes.func, //视频分类
    listVideoSelect: PropTypes.object,
    getEnumSystemType: PropTypes.func,
    systemTypeSelect: PropTypes.array,
};

const mapStateToProps = (state) => ({
    videoData: state.videoAudit.videoAuditList,
    selectDataSource: state.videoAudit.auditStatusSelect,
    listVideoSelect: state.channellist.listVideoSelect,
    systemTypeSelect: state.videoAudit.getEnumSystemType,
});

export default connect(mapStateToProps, { getVideoAuditList, getVideoAuditSele, getListVideoSelect, getEnumSystemType })(Main);