/**
 *  能量值设置 
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { message, Typography, InputNumber } from 'antd';
import http from '@/assets/api/http';
import { XInputNum, XOKButton } from '@/components/xqxc_ui';
import ButtonPerssion from '@/routes/ButtonPerssion';
import model from '@/reducers/systemmanage/servicesetting/model';
import { FloatConversion } from '@/util/conversion';
import { regFenToYuan } from '@/util/money';

const { Text } = Typography;

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmit: false,//防抖 
            consumptionPrice: 1,//消费赠送能量值比例-价格
            consumptionBean: 0,//消费赠送能量值比例-个数
            recommendBean: 0,//推荐用户能量值
            recommendUser: 0,//推荐用户
            hostBean: 0,//推荐主播能量值
            recommendvip: 0,//推荐Vip
            recommendservice: 0,//推荐服务商
            recommendservicehf: 0,//推荐服务商H5
            systemaway: 0,//系统后台创建服务商是否赠送
            planFeeOne: regFenToYuan(0),//技术服务费y一类
            planFeeTwo: regFenToYuan(0),//技术服务费y二类
            SRSOne: 0,//服务商推荐服务商一类
            SRSTwo: 0,//服务商推荐服务商二类
            SRSThree: 0,//服务商推荐服务商三类
            H5OpenOne: 0,//服务商推荐服务商一类
            H5OpenTwo: 0,//服务商推荐服务商二类
        };

        this.floatConversion = new FloatConversion();
    }

    componentDidMount() {
        this.props.getserviceLevelCode();
        this.props.getBeanInfo();
    }

    componentDidUpdate(prevProps) {
        if (this.props.beanInfo.length !== prevProps.beanInfo.length) {
            this.props.beanInfo.map((v) => {
                const { gbParamCode: { code } = {}, gbParamValue } = v;
                switch (code) {
                    case 'CONSUMPTION':
                        this.setState({ consumptionPrice: this.floatConversion.divide(gbParamValue, 100) });
                        break;
                    case 'CONSUMPTION_GIVING':
                        this.setState({ consumptionBean: gbParamValue });
                        break;
                    case 'RECOMMEND_ACCOUNT':
                        this.setState({ recommendBean: gbParamValue });
                        break;
                    case 'RECOMMEND_ACCOUNT_LIMIT':
                        this.setState({ recommendUser: gbParamValue });
                        break;
                    case 'RECOMMEND_ANCHOR':
                        this.setState({ hostBean: gbParamValue });
                        break;
                    case 'RECOMMEND_ACCOUNT_VIP':
                        this.setState({ recommendvip: gbParamValue });
                        break;
                    case 'RECOMMEND_SERVICE':
                        this.setState({ recommendservice: gbParamValue });
                        break;
                    case 'H5_OPEN_SERVICE':
                        this.setState({ recommendservicehf: gbParamValue });
                        break;
                    case 'SYSTEM_OPEN_SERVICE':
                        this.setState({ systemaway: gbParamValue });
                        break;
                    case 'SJ0001':
                        this.setState({ planFeeOne: gbParamValue });
                        break;
                    case 'SJ0002':
                        this.setState({ planFeeTwo: gbParamValue });
                        break;
                    case 'RECOMMEND_SERVICE_SJ0001':
                        this.setState({ SRSOne: gbParamValue });
                        break;
                    case 'RECOMMEND_SERVICE_SJ0002':
                        this.setState({ SRSTwo: gbParamValue });
                        break;
                    case 'RECOMMEND_SERVICE_SJ0003':
                        this.setState({ SRSThree: gbParamValue });
                        break;
                    case 'H5_OPEN_SERVICE_SJ0001':
                        this.setState({ H5OpenOne: gbParamValue });
                        break;
                    case 'H5_OPEN_SERVICE_SJ0002':
                        this.setState({ H5OpenTwo: gbParamValue });
                        break;
                    default: return null;
                }
            });
        }
    }

    componentWillUnmount() {
        this.props.getCleanBeanInfo();//清除能量值设置默认数据
    }


    //保存
    _saveHandle = () => {
        const { consumptionPrice, consumptionBean, recommendBean,
            recommendUser, hostBean, recommendvip, recommendservice,
            recommendservicehf, systemaway, SRSOne, SRSTwo, H5OpenOne, H5OpenTwo, SRSThree } = this.state;
        let submit = [
            { gbParamCode: 'CONSUMPTION', gbParamValue: this.floatConversion.multiply(consumptionPrice, 100) },
            { gbParamCode: 'CONSUMPTION_GIVING', gbParamValue: consumptionBean },
            { gbParamCode: 'RECOMMEND_ACCOUNT', gbParamValue: recommendBean },
            { gbParamCode: 'RECOMMEND_ACCOUNT_LIMIT', gbParamValue: recommendUser },
            { gbParamCode: 'RECOMMEND_ANCHOR', gbParamValue: hostBean },
            { gbParamCode: 'RECOMMEND_ACCOUNT_VIP', gbParamValue: recommendvip },
            { gbParamCode: 'RECOMMEND_SERVICE', gbParamValue: recommendservice },
            { gbParamCode: 'H5_OPEN_SERVICE', gbParamValue: recommendservicehf },
            { gbParamCode: 'SYSTEM_OPEN_SERVICE', gbParamValue: systemaway },
            { gbParamCode: 'RECOMMEND_SERVICE_SJ0001', gbParamValue: SRSOne },
            { gbParamCode: 'RECOMMEND_SERVICE_SJ0002', gbParamValue: SRSTwo },
            { gbParamCode: 'RECOMMEND_SERVICE_SJ0003', gbParamValue: SRSThree },
            { gbParamCode: 'H5_OPEN_SERVICE_SJ0001', gbParamValue: H5OpenOne },
            { gbParamCode: 'H5_OPEN_SERVICE_SJ0002', gbParamValue: H5OpenTwo },
        ];
        this.setState({ isSubmit: true }, () => {
            http('/gatherbeans/saveUpdatesList', {
                gatherBeansList: submit
            }, 'POST').then(() => {
                message.success('保存成功');
                this.setState({ isSubmit: false });
            }).catch((e = {}) => {
                message.error(e.message);
                this.setState({ isSubmit: false });
            });
        });
    }

    render() {
        const { isSubmit, consumptionPrice, consumptionBean,
            recommendBean, recommendUser, hostBean, recommendvip,
            recommendservice, recommendservicehf, systemaway,
            planFeeTwo, planFeeOne, SRSOne, SRSTwo, H5OpenOne, H5OpenTwo, SRSThree } = this.state;

        return (
            <div className={styles.servergrowth}>
                <div className={styles.top_input}>
                    <div className={`${styles.layout} ${styles.item}`}>
                        <span>消费赠送能量值比例：每消费</span>
                        <XInputNum
                            style={{ marginRight: '8px' }}
                            inputStyle={{ width: '100px', }}
                            placeholder='请输入'
                            value={consumptionPrice === null ? 1 : consumptionPrice}
                            bindThis={this}
                            bindName='consumptionPrice'
                            precision={0}
                            min={1}
                            max={99999}
                        />
                        <span>元，赠送</span>
                        <XInputNum
                            style={{ marginRight: '8px' }}
                            inputStyle={{ width: '100px', }}
                            placeholder='请输入'
                            value={consumptionBean === null ? 0 : consumptionBean}
                            bindThis={this}
                            bindName='consumptionBean'
                            precision={0}
                            min={0}
                            max={99999}
                        />
                        <span>个能量值，取整数值，<Text type='danger'>税费和</Text>邮费不计算在内</span>
                    </div>
                </div>
                <div className={styles.top_input}>
                    <div className={`${styles.layout} ${styles.item}`}>
                        <span>推荐用户：每推荐一名用户赠送</span>
                        <XInputNum
                            style={{ marginRight: '8px' }}
                            inputStyle={{ width: '100px', }}
                            placeholder='请输入'
                            value={recommendBean === null ? 0 : recommendBean}
                            bindThis={this}
                            bindName='recommendBean'
                            precision={0}
                            min={0}
                            max={99999}
                        />
                        <span>个能量值，每天推荐上限</span>
                        <XInputNum
                            style={{ marginRight: '8px' }}
                            inputStyle={{ width: '100px', }}
                            placeholder='请输入'
                            value={recommendUser === null ? 0 : recommendUser}
                            bindThis={this}
                            bindName='recommendUser'
                            precision={0}
                            min={0}
                            max={99999}
                        />
                        <span>名用户，<Text type='danger'>超出后不再赠送</Text></span>
                    </div>
                </div>
                <div className={styles.top_input}>
                    <div className={`${styles.layout} ${styles.item}`}>
                        <span>推荐主播：每推荐一名用户成为主播赠送</span>
                        <XInputNum
                            style={{ marginRight: '8px' }}
                            inputStyle={{ width: '100px', }}
                            placeholder='请输入'
                            value={hostBean === null ? 0 : hostBean}
                            bindThis={this}
                            bindName='hostBean'
                            precision={0}
                            min={0}
                            max={99999}
                        />
                        <span>个能量值</span>
                    </div>
                </div>
                <div className={styles.top_input}>
                    <div className={`${styles.layout} ${styles.item}`}>
                        <span>推荐Vip：每推荐一名用户成为Vip赠送</span>
                        <XInputNum
                            style={{ marginRight: '8px' }}
                            inputStyle={{ width: '100px', }}
                            placeholder='请输入'
                            value={recommendvip === null ? 0 : recommendvip}
                            bindThis={this}
                            bindName='recommendvip'
                            precision={0}
                            min={0}
                            max={99999}
                        />
                        <span>个能量值</span>
                    </div>
                </div>
                {/* <div className={styles.top_input}>
                    <div className={`${styles.layout} ${styles.item}`}>
                        <span>推荐服务商：每推荐一名服务商赠送</span>
                        <XInputNum
                            style={{ marginRight: '8px' }}
                            inputStyle={{ width: '100px', }}
                            placeholder='请输入'
                            value={recommendservice === null ? 0 : recommendservice}
                            bindThis={this}
                            bindName='recommendservice'
                            precision={0}
                            min={0}
                            max={99999}
                        />
                        <span>个能量值</span>
                    </div>
                </div> */}
                {/* <div className={styles.top_input}>
                    <div className={`${styles.layout} ${styles.item}`}>
                        <span>H5开通服务商：成为服务商赠送</span>
                        <XInputNum
                            style={{ marginRight: '8px' }}
                            inputStyle={{ width: '100px', }}
                            placeholder='请输入'
                            value={recommendservicehf === null ? 0 : recommendservicehf}
                            bindThis={this}
                            bindName='recommendservicehf'
                            precision={0}
                            min={0}
                            max={99999}
                        />
                        <span>个能量值</span>
                    </div>
                </div> */}
                <div className={styles.recommend}>
                    <p className={styles.title}><Text type='danger'>服务商推荐服务商：</Text></p>
                    <div className={styles.content}>
                        <span className={styles.dotIcon} />
                        <Text type='danger'>开通技术服务编号SJ0001服务商，推荐人赠送</Text>
                        <InputNumber
                            precision={0}
                            style={{ margin: '0 10px' }}
                            value={SRSOne}
                            onChange={value => this.setState({ SRSOne: value })}
                        />
                        <Text>个能量值</Text>
                    </div>
                    <div className={styles.content}>
                        <span className={styles.dotIcon} />
                        <Text type='danger'>开通技术服务编号SJ0002服务商，推荐人赠送</Text>
                        <InputNumber
                            precision={0}
                            style={{ margin: '0 10px' }}
                            value={SRSTwo}
                            onChange={value => this.setState({ SRSTwo: value })}
                        />
                        <Text>个能量值</Text>
                    </div>
                    <div className={styles.content}>
                        <span className={styles.dotIcon} />
                        <Text type='danger'>开通技术服务编号SJ0003每日说客，推荐人赠送</Text>
                        <InputNumber
                            precision={0}
                            style={{ margin: '0 10px' }}
                            value={SRSThree}
                            onChange={value => this.setState({ SRSThree: value })}
                        />
                        <Text>个能量值</Text>
                    </div>
                    <p className={styles.title}><Text type='danger'>H5开通成为服务商</Text>:</p>
                    <div className={styles.content}>
                        <span className={styles.dotIcon} />
                        <Text type='danger'>开通技术服务编号SJ0001服务商，开通人赠送</Text>
                        <InputNumber
                            precision={0}
                            style={{ margin: '0 10px' }}
                            value={H5OpenOne}
                            onChange={value => this.setState({ H5OpenOne: value })}
                        />
                        <Text>个能量值</Text>
                    </div>
                    <div className={styles.content}>
                        <span className={styles.dotIcon} />
                        <Text type='danger'>开通技术服务编号SJ0002服务商，开通人赠送</Text>
                        <InputNumber
                            precision={0}
                            style={{ margin: '0 10px' }}
                            value={H5OpenTwo}
                            onChange={value => this.setState({ H5OpenTwo: value })}
                        />
                        <Text>个能量值</Text>
                    </div>
                </div>
                <div className={styles.top_input}>
                    <div className={`${styles.layout} ${styles.item}`}>
                        <span>系统后台创建服务商赠送</span>
                        <XInputNum
                            style={{ marginRight: '8px' }}
                            inputStyle={{ width: '100px', }}
                            placeholder='请输入'
                            value={systemaway === null ? 0 : systemaway}
                            bindThis={this}
                            bindName='systemaway'
                            precision={0}
                            min={0}
                            max={99999}
                        />
                        <span>个能量值</span>
                    </div>
                </div>
                <ButtonPerssion code='systemmanage/servicesetting/bc'>
                    <XOKButton style={{ width: '90px' }} loading={isSubmit} label='保存' onClick={this._saveHandle} />
                </ButtonPerssion>
            </div>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    match: PropTypes.object, // 路由match对象
    history: PropTypes.object, // 路由history

    getLevelCode: PropTypes.array,
    getserviceLevelCode: PropTypes.func,//服务商等级action
    beanInfo: PropTypes.array,
    getBeanInfo: PropTypes.func,//获取能量值设置默认数据action
    getCleanBeanInfo: PropTypes.func,//清除能量值设置默认数据action
};

const mapStateToProps = (state) => ({
    getLevelCode: state.servicesetting.getLevelCode,
    beanInfo: state.servicesetting.beanInfo,
});

export default connect(mapStateToProps, { ...model.actions })(Main);