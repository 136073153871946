import React from 'react';
import { XOKButton } from '@/components/xqxc_ui';

class Export extends React.Component {

    _exportHandle = () => {
        console.log('导出');
    }

    render(){
        return(
            <XOKButton 
                style={{width:'100px', marginTop:'20px'}} 
                label='导出Excel'
                onClick={this._exportHandle} 
            />
        );
    }
}

export default Export;