/**
 * 监护人管理
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as T from './actiontypes';
import http from '@/assets/api/http';

// 获取活动商品列表
export const getActiveGoods = function* () {
    yield takeEvery(T.GET_ACTIVE_GOODS, function* requestData(action) {
        try {
            let result = yield http('/goods/goods/findMarketingGoods', action.payload, 'POST');
            yield put({ type: T.SET_ACTIVE_GOODS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取活动商品详情
export const getProductDetail = function* () {
    yield takeEvery(T.GET_GOOD_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/goods/goods/infoGoods', action.payload, 'POST');
            yield put({ type: T.SET_GOOD_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取参与活动的商户下拉列
export const getMerchants = function* () {
    yield takeEvery(T.GET_MERCHANTS, function* requestData(action) {
        try {
            let result = yield http('/merchant/info/listMerchantIdAndName', action.payload, 'POST');
            yield put({ type: T.SET_MERCHANTS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

