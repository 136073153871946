/**
 *  风控体系设置
*   IS_CASH_OUT_SMS_VERIFY: { paramCode: 'IS_CASH_OUT_SMS_VERIFY', paramAttr: '1', remark: '1', paramValue: 0 }, // 提现验证
    FREE_ORDER_LIMIT_NUM: { paramCode: 'FREE_ORDER_LIMIT_NUM', paramAttr: '', remark: '', paramValue: '' }, // 免单限购
    IS_FREE_ORDER_AUTO_REMIT: { paramCode: 'IS_FREE_ORDER_AUTO_REMIT', paramAttr: '', remark: '', paramValue: 0 }, // 免单自动打款
    REG_ACCOUNT_LIMIT_NUM: { paramCode: 'REG_ACCOUNT_LIMIT_NUM', paramAttr: '', remark: '', paramValue: '' }, // 设备限制注册
    REWARD_INVITE_REG_LIMIT_NUM: { paramCode: 'REWARD_INVITE_REG_LIMIT_NUM', paramAttr: '', remark: '', paramValue: '' }, // 邀请奖励、
    INVITE_REWARD_LOGIN_TIME_LIMIT: { paramCode: 'INVITE_REWARD_LOGIN_TIME_LIMIT', paramAttr: '', remark: '', paramValue: '' }, // 注册任务奖励
    SMS_RECEIVE_LIMIT_NUM: { paramCode: 'SMS_RECEIVE_LIMIT_NUM', paramAttr: '', remark: '', paramValue: '' }, // 短信限制
    IS_LIVE_TIME_LIMIT: { paramCode: 'IS_LIVE_TIME_LIMIT', paramAttr: '', remark: '', paramValue: 0 }, // 开播时长限制
    IS_PURCHASE_LIVE_TIME: { paramCode: 'IS_PURCHASE_LIVE_TIME', paramAttr: '', remark: '', paramValue: 0 }, // 购买直播时长
    CASH_OUT_MIN_AMOUNT: { paramCode: 'CASH_OUT_MIN_AMOUNT', paramAttr: '', remark: '', paramValue: '' }, // 提现金额最小值
    CASH_OUT_MAX_AMOUNT: { paramCode: 'CASH_OUT_MAX_AMOUNT', paramAttr: '', remark: '', paramValue: '' }, // 提现金额最大值
 * */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { Switch, message, InputNumber } from 'antd';
import { XInput, XOKButton, showConfirm, XInputNum } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import model from '@/reducers/systemmanage/riskcontrol/model';
import PropTypes from 'prop-types';
import SetXiuDouModal from './modal/SetXiuDouModal';
import { regFenToYuan, regYuanToFen } from '@/util/money';

class Main extends Component {
    state = {
        list: [],
        listTwo: [],
        curMode: '', // 当前弹框
        loading: false
    };

    componentDidMount() {
        // 风控体系设置
        http('/admin/param/listAdminParam/RISK', {}, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result && response.result.length > 0) {
                    const listTwo = response.result.map((item) => {
                        // 开启会员和服务商限额自动打款，参数传递单位（分）
                        if (item.paramCode == 'MERCHANT_ADVANCES_RECEIVED_REMIND') {
                            item.paramValue = regFenToYuan(item.paramValue);
                        }
                        return item;
                    });
                    this.setState({ list: listTwo });
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });

        // 魅力值充值
        http('/admin/param/listAdminParam/STARLIGHT', {}, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result && response.result.length > 0) {
                    const listTwo = response.result.map((item) => {
                        // 开启会员和服务商限额自动打款，参数传递单位（分）
                        if (item.paramCode == 'STARLIGHT_AUTOMATIC_PAY_AMOUNT') {
                            item.paramValue = regFenToYuan(item.paramValue);
                        }
                        return item;
                    });
                    this.setState({ listTwo });
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });

        // 人气值设置详细
        this.props.getXiuDouSetting();
    }

    //保存
    _savehandle = () => {
        const { listTwo, list } = this.state;
        const result = [...listTwo, ...list].map((item) => {
            const obj = { ...item };
            // 开启会员和服务商限额自动打款，参数传递单位（分）
            if (obj.paramCode == 'STARLIGHT_AUTOMATIC_PAY_AMOUNT' || item.paramCode == 'MERCHANT_ADVANCES_RECEIVED_REMIND') {
                obj.paramValue = regYuanToFen(obj.paramValue);
            }
            return obj;
        });
        for (let item of result) {
            if (item.paramCode == 'SHOW_SHELL_CONVERT_STOCK_NUM' && !item.paramValue) {
                message.warning('请输入服务商贡献值兑换股票');
                return;
            }
        }
        this.setState({ loading: true }, () => {
            http('/admin/param/batchUpdateParam', result, 'POST').then((response) => {
                if (response.status == 200) {
                    message.success('保存成功');
                    this.setState({ loading: false });
                }
            }).catch((e = {}) => {
                this.setState({ loading: false });
                message.error(e.message);
            });
        });
    }

    // 设置人气值赠送
    _setXiuDouHandle = () => {
        this.setState({ curMode: 'setXiuDouModal' });
    }

    // 打开弹窗
    _renderMode = () => {
        switch (this.state.curMode) {
            case 'setXiuDouModal':
                return <SetXiuDouModal visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ curMode: '' });
    }

    // 切换人气值赠送
    _changeXiuDouHandle = (code, value, flag) => {
        const { xiuDouSetting } = this.props;
        if (value && xiuDouSetting.id == '') {// 打开
            message.warning('您当前还未设置人气值赠送规则，请先设置规则后再开启人气值风控功能');
        } else if (!value) {// 关闭
            showConfirm('当前操作为特殊场景控制，您确定是否关闭？（请慎重执行）', '', () => {
                this._countList(code, value, flag);
            });
        } else {
            this._countList(code, value, flag);
        }
    }

    _renderList = (list, flag) => {
        return list.map((item) => {
            if (
                item.paramCode == 'STARLIGHT_RISK_SWITCH' ||
                item.paramCode == 'IS_CASH_OUT_SMS_VERIFY' ||
                item.paramCode == 'IS_FREE_ORDER_AUTO_REMIT' ||
                item.paramCode == 'IS_LIVE_TIME_LIMIT' ||
                item.paramCode == 'IS_PURCHASE_LIVE_TIME' ||
                item.paramCode == 'QIANG_PAI_TOPIC_PAGE_SWITCH' ||
                item.paramCode == 'QIANG_PAI_INVENTORY_SWITCH' ||
                item.paramCode == 'IS_RATING_GOODS_REWARD_AUDIT' ||
                item.paramCode == 'PLATFORM_INCOME_AUDIT_FREE'
            ) {
                return (
                    <div className={styles.item} key={item.paramCode}>
                        <h4>{item.paramAttr}</h4>
                        <p>{item.remark}</p>
                        <div>
                            <Switch
                                checkedChildren="开"
                                unCheckedChildren="关"
                                checked={item.paramValue == 0 ? false : true}
                                onChange={(value) => {
                                    this._countList(item.paramCode, value, flag);
                                }} />
                        </div>
                    </div>
                );
            }
            else if (item.paramCode == 'STARVALUE_PRESENT') {
                return (
                    <div className={styles.item} key={item.paramCode}>
                        <h4>{item.paramAttr}</h4>
                        <p>{item.remark}</p>
                        <div className={styles.giveXiuDouBox}>
                            <XOKButton
                                onClick={this._setXiuDouHandle}
                                style={{ width: '60px', marginRight: '20px', borderRadius: '20px' }}
                                label='设置'
                            />
                            <Switch
                                checkedChildren="开"
                                unCheckedChildren="关"
                                checked={item.paramValue == 0 ? false : true}
                                onChange={(value) => {
                                    this._changeXiuDouHandle(item.paramCode, value, flag);
                                }} />
                        </div>
                    </div>
                );
            } else if (item.paramCode == 'STARLIGHT_AUTOMATIC_PAY_AMOUNT' || item.paramCode == 'MERCHANT_ADVANCES_RECEIVED_REMIND') {
                return (
                    <div className={styles.item} key={item.paramCode}>
                        <h4>{item.paramAttr}</h4>
                        <p>{item.remark}</p>
                        <div>
                            <XInputNum
                                style={{ width: '100px', marginLeft: '117px' }}
                                inputStyle={{ width: '93px' }}
                                placeholder='请输入'
                                value={item.paramValue}
                                precision={2}
                                onChange={(value) => {
                                    this._countList(item.paramCode, value, flag);
                                }}
                            />
                        </div>
                    </div>
                );
            } else if (item.paramCode == 'SHOW_SHELL_CONVERT_STOCK_NUM') {
                return (
                    <div className={styles.item} key={item.paramCode}>
                        <h4>{item.paramAttr}</h4>
                        <p>{item.remark}</p>
                        <div>
                            <XInputNum
                                style={{ width: '100px', marginLeft: '117px' }}
                                inputStyle={{ width: '93px' }}
                                placeholder='请输入'
                                value={item.paramValue}
                                precision={0}
                                max={99999}
                                min={100}
                                onChange={(value) => {
                                    this._countList(item.paramCode, value, flag);
                                }}
                            />
                        </div>
                    </div>
                );
            } else if (item.paramCode == 'FRANCHISE_VALUE_RATE' || item.paramCode == 'HKD_VALUE_RATE') {
                return <div className={styles.item} key={item.paramCode}>
                    <h4>{item.paramAttr}</h4>
                    <p>{item.remark}</p>
                    <div className={styles.itemSon1}>
                        <InputNumber
                            style={{ marginRight: '15px' }}
                            value={item.paramValue}
                            precision={0} min={1} max={99999}
                            onChange={(value) => {
                                this._countList(item.paramCode, value, flag);
                            }} />
                        <div className={styles.label}>：1{item.paramCode == 'HKD_VALUE_RATE' ? '港元' : '元'}</div>
                    </div>
                </div>;
            } else if (item.paramCode == 'STOCK_RETURNS_EXCHANGE') {
                return <div className={styles.item} key={item.paramCode}>
                    <h4>{item.paramAttr}</h4>
                    <p>{item.remark}</p>
                    <div className={styles.itemSon}>
                        <div className={styles.label}>最低</div>
                        <InputNumber
                            value={item.paramValue.split(':')[0]}
                            precision={0} min={1} max={99999}
                            onChange={(value) => {
                                let newList = list.map(ele => {
                                    if (ele.paramCode === 'STOCK_RETURNS_EXCHANGE') {
                                        let paramValue = [value, item.paramValue.split(':')[1]].join(':');
                                        return { ...ele, paramValue };
                                    }
                                    return { ...ele };
                                });
                                this.setState({ list: newList });
                            }} />
                        <div className={styles.label}>股起&nbsp;&nbsp;最高</div>
                        <InputNumber
                            value={item.paramValue.split(':')[1]}
                            precision={0} min={1} max={99999}
                            onChange={(value) => {
                                let newList = list.map(ele => {
                                    if (ele.paramCode === 'STOCK_RETURNS_EXCHANGE') {
                                        let paramValue = [item.paramValue.split(':')[0], value].join(':');
                                        return { ...ele, paramValue };
                                    }
                                    return { ...ele };
                                });
                                this.setState({ list: newList });
                            }} />
                        <div className={styles.label}>股</div>
                    </div>
                </div>;
            } else if (item.paramCode == 'EARNINGS_SERVICE_RANDOM_DISCOUNT') {
                return <div className={styles.item} key={item.paramCode}>
                    <h4>{item.paramAttr}</h4>
                    <p>{item.remark}</p>
                    <div className={styles.itemSon}>
                        <div className={styles.label}>最低</div>
                        <InputNumber
                            value={regFenToYuan(item.paramValue.split(':')[0])}
                            precision={2} min={0} max={9999}
                            onChange={(value) => {
                                let newList = list.map(ele => {
                                    if (ele.paramCode === 'EARNINGS_SERVICE_RANDOM_DISCOUNT') {
                                        let paramValue = [regYuanToFen(value), item.paramValue.split(':')[1]].join(':');
                                        return { ...ele, paramValue };
                                    }
                                    return { ...ele };
                                });
                                this.setState({ list: newList });
                            }} />
                        <div className={styles.label}>元起&nbsp;&nbsp;最高</div>
                        <InputNumber
                            value={regFenToYuan(item.paramValue.split(':')[1])}
                            precision={2} min={0} max={9999}
                            onChange={(value) => {
                                let newList = list.map(ele => {
                                    if (ele.paramCode === 'EARNINGS_SERVICE_RANDOM_DISCOUNT') {
                                        let paramValue = [item.paramValue.split(':')[0], regYuanToFen(value)].join(':');
                                        return { ...ele, paramValue };
                                    }
                                    return { ...ele };
                                });
                                this.setState({ list: newList });
                            }} />
                        <div className={styles.label}>元</div>
                    </div>
                </div>;
            } else if (item.paramCode == 'INTEGRAL_RMB_RATE') {
                return (
                    <div className={styles.item} key={item.paramCode}>
                        <h4>{item.paramAttr}</h4>
                        <p>{item.remark}</p>
                        <div className={styles.itemSon1}>
                            <div className={styles.label}>1积分：</div>
                            <XInputNum placeholder='请输入' value={item.paramValue}
                                precision={2} max={1} min={0}
                                // onChange={(value) => {
                                //     this._countList(item.paramCode, value, flag);
                                // }}
                            />
                            <div className={styles.label}>（元）</div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={styles.item} key={item.paramCode}>
                        <h4>{item.paramAttr}</h4>
                        <p>{item.remark}</p>
                        <div>
                            <XInput
                                style={{ width: '100px', marginLeft: '117px' }}
                                inputStyle={{ width: '93px' }}
                                placeholder='请输入'
                                value={item.paramValue}
                                onChange={(value) => {
                                    this._countList(item.paramCode, value, flag);
                                }}
                            />
                        </div>
                    </div>
                );
            }
        });
    }

    _countList = (code, value, flag) => {
        const { list, listTwo } = this.state;
        let newList = [];
        if (flag) {
            newList = [...list];
        } else {
            newList = [...listTwo];
        }
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].paramCode == code) {
                if (value === true) {
                    newList[i].paramValue = 1;
                } else if (value === false) {
                    newList[i].paramValue = 0;
                } else {
                    newList[i].paramValue = value;
                }
                break;
            }
        }
        if (flag) {
            this.setState({ list: newList });
        } else {
            this.setState({ listTwo: newList });
        }
    }

    render() {
        const { list, listTwo, loading } = this.state;
        console.log(list);

        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.tableContainer} style={{ overflow: 'auto' }}>
                    <div className={styles.riskcontrol}>
                        <div className={styles.top_title}>
                            <span>风控体系设置</span>
                        </div>
                    </div>
                    <div className={styles.riskcontent}>
                        {this._renderList(list, true)}
                    </div>

                    <div className={styles.riskcontrol}>
                        <div className={styles.top_title}>
                            <span>魅力值充值</span>
                        </div>
                    </div>
                    <div className={styles.riskcontent}>
                        {this._renderList(listTwo, false)}
                    </div>
                </div>
                <div className={styles.layout}>
                    <XOKButton loading={loading} onClick={this._savehandle} style={{ width: '90px', marginTop: '30px' }} label='保存' />
                </div>
                {this._renderMode()}
            </div>
        );
    }
}

Main.propTypes = {
    getXiuDouSetting: PropTypes.func, // 获取人气值设置请求
    xiuDouSetting: PropTypes.object, // 人气值设置详细
};

const mapStateToProps = (state) => ({
    xiuDouSetting: state.riskcontrol.getXiuDouSetting
});

export default connect(mapStateToProps, { ...model.actions })(Main);