import { createModel } from '@/store/tools';

const model = {
    namespace: 'statistishow',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getStatistiShow': T('getStatistiShow'),
            'getRewardType': T('getRewardType'),
        },
        actions: {
            'getStatistiShow': A('getStatistiShow'),//列表
            'getRewardType': A('getRewardType'),//奖励类型
        },
        sagas: {
            'getStatistiShow': S('getStatistiShow', '/boss/xiubei/xiubeiProvideStatistics'),
            'getRewardType': S('getRewardType', '/boss/xiubei/xiubeiRewardStateSelect'),
        },
        reducers: {
            'showList': R('getStatistiShow', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'rewardType': R('getRewardType', []),
        }
    })
};
export default createModel(model);