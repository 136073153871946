/**
 *  费用名称
 * */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import ChooseModal from '../../modal/ChooseModal';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { getCostNameList } from '@/reducers/systemmanage/costname/actions';
import TableContent from './components/TableContent';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            costName: '',
            selectedRows: [],
            openModeParam: {},
            currentOpenMode: ''
        };
        this.searchCache = { pageNum: 1, pageSize: 30 }; // 查询缓存
    }

    componentDidMount() {
        this.props.getCostNameList(this.searchCache);
    }

    // 表格操作功能回调  
    _tableAction = (item, type) => {
        switch (type) {
            case 'edit':
                this.setState({
                    currentOpenMode: 'edit',
                });
                this.setState({ openModeParam: item });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getCostNameList(this.searchCache);
    }

    // 打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'edit': // 编辑
                return <ChooseModal title='编辑费用名称' type='edit' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'create': // 新建
                return <ChooseModal title='新建费用名称' type='new' visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' });
        if (update) {
            this.props.getCostNameList(this.searchCache);
        }
    }

    //新建
    _createhandle = () => {
        this.setState({
            currentOpenMode: 'create',
        });
    }

    // 查询触发
    _searchHandle = () => {
        const { costName } = this.state;
        this.searchCache = {
            costName,
            pageNum: 1,
            pageSize: 30
        };
        this.props.getCostNameList(this.searchCache);
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            costName: '',
            selectedRows: [],
            openModeParam: {},
            currentOpenMode: ''
        });
        this.searchCache = { pageNum: 1, pageSize: 30 };
        this.props.getCostNameList(this.searchCache);
    }

    // 批量复选框
    _rowSelection = (selectedRows) => {
        this.setState({ selectedRows });
    }

    render() {
        const { costName } = this.state;
        const { costNameList } = this.props;

        return (
            <div className={styles.flexBoxContainer}>
                <SearchBox>
                    <SearchItem>
                        <XInput
                            label='费用名称'
                            placeholder='请输入费用名称'
                            value={costName}
                            bindThis={this}
                            bindName='costName'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </SearchItem>
                    {/* 
                        <div className={styles.operate_box}>
                            <XOKButton onClick={this._createhandle} style={{ width: '90px', }} label='新建' />
                            <XOKButton onClick={this._exporthandle} style={{ width: '90px', marginLeft: '20px', }} label='导出' />
                        </div> 
                    */}
                </SearchBox>
                <TableContent
                    renderData={costNameList}
                    tableAction={this._tableAction}
                    paginationChange={this._paginationChange}
                    onRowSelection={this._rowSelection}
                />
                {this._renderMode()}
            </div>
        );
    }
}

Main.propTypes = {
    getCostNameList: PropTypes.func,
    costNameList: PropTypes.object
};

const mapStateToProps = (state) => {
    return {
        costNameList: state.costName.getCostNameList
    };
};

export default connect(mapStateToProps, { getCostNameList })(Main);