/**
 *  评论管理 - 视频评论
 * */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './tableContents/TableData';
import { getVideoTableSource, getVideoselectDataSource, getVideoSelectSource } from '@/reducers/commentmanage/videocomment/actions';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class ProductComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paginationCache: {}, // 分页缓存对象，实现的功能是点击分页操作时不带条件搜索
      videos: {}, //视频分类
      videosNames: '',//视频名称,
      videosPerson: '',//评论人
      videoContent: '',//评论内容
      resultTotal: 0, // 分页总条数
      pageSize: 20, // 每页显示多少条
      pageNum: 1, // 当前第几页
      feedType: 'VIDEO', //固定为图文标识字符串
    };
  }

  componentDidMount() {
    const { history, keepSecondNavBreadcrumb, getVideoselectDataSource, getVideoTableSource } = this.props;

    if (history.location.state && history.location.state.keepAlive) {
      console.log('使用缓存记录');
    } else {
      keepSecondNavBreadcrumb();//面包屑
      // getVideoTableSource(20, 1, 'VIDEO');// 列表数据方法
      getVideoselectDataSource();// 下拉选项数据方法
    }
  }

  // 分页
  _paginationChange = (pageSize, pageNum) => {
    this.setState({ pageNum, pageSize });
    this.searchCache = { ...this.state.paginationCache, pageSize, pageNum, feedType: 'VIDEO' };
    this.props.getVideoSelectSource(this.searchCache);
    KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
  }

  // 视频名称
  _videoNamesChangeHandle = (value) => {
    this.setState({ videosNames: value });
  }

  // 评论内容
  _videoContentChangeHandle = (value) => {
    this.setState({ videoContent: value });
  }

  // 评论人
  _videoPersonChangeHandle = (value) => {
    this.setState({ videosPerson: value });
  }

  // 视频分类
  _videoChangeHandle = (selectedItem) => {
    this.setState({ videos: selectedItem });
  }

  // 查询触发
  _searchHandle = (useCache) => {
    const { videos, videosNames, videosPerson, videoContent, pageNum, pageSize } = this.state;
    const paginationCache = {
      pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
      pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
      feedClassId: videos.id,
      feedType: 'VIDEO',
      feedTitle: videosNames,
      watchAccountName: videosPerson,
      content: videoContent,
    };
    this.searchCache = paginationCache;
    this.setState({ paginationCache });
    this.props.getVideoSelectSource(this.searchCache);
    KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
  }

  // 重置触发
  _videoSresetHandle = () => {
    this.setState({ paginationCache: {}, videos: {}, videosNames: '', videosPerson: '', videoContent: '' }, () => {
      KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
    });
  }

  // 表格操作
  _actionClickHandle = (id, type) => {
    const { history, addOneBreadcrumbPath } = this.props;
    if (type === 'DETAILS_INFO') {
      addOneBreadcrumbPath({
        title: '视频评论详情',
        path: '/home/commentmanage/videocomment/contentInfo/' + id
      });
      history.push({
        pathname: '/home/commentmanage/videocomment/contentInfo/' + id
      });
    }
  }

  render() {
    const { tableSource, selectData, paginations } = this.props;
    const { videos, videosNames, videosPerson, videoContent } = this.state;

    return (
      <KeepAlive render={(state) => {
        if (!this.state.__aliveInit) {
          this.setState({ ...state, __aliveInit: true }, () => {
            this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
          });
        }
      }}>
        <div className={styles.flexBoxContainer}>
          <SearchBox>
            <SearchItem>
              <XSelect
                label='视频分类'
                placeholder='请选择'
                renderData={selectData}
                onChange={this._videoChangeHandle}
                dataIndex='className'
                keyIndex='id'
                value={videos.className}
                showSearch={true}
              />
            </SearchItem>
            <SearchItem>
              <XInput
                label='视频名称'
                placeholder='视频名称'
                onChange={this._videoNamesChangeHandle}
                value={videosNames}
              />
            </SearchItem>
            <SearchItem>
              <XInput
                label='评论人'
                placeholder='评论人'
                onChange={this._videoPersonChangeHandle}
                value={videosPerson}
              />
            </SearchItem>
            <SearchItem>
              <XInput
                label='评论内容'
                placeholder='评论内容'
                onChange={this._videoContentChangeHandle}
                value={videoContent}
              />
            </SearchItem>
            <SearchItem>
              <XOKButton
                label='查询'
                onClick={this._searchHandle}
              />
              <XCancelButton
                label='重置'
                onClick={this._videoSresetHandle}
              />
            </SearchItem>
          </SearchBox>
          <div className={styles.tableContainer}>
            <Table
              rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
              rowKey={(record, index) => index}
              columns={takeColumns(this._actionClickHandle)}
              dataSource={takeDataSource(tableSource)}
              pagination={false}
              scroll={{ y: true }}
            />
            <XPagination
              resultTotal={paginations.resultTotal}
              pageSize={paginations.pageSize}
              pageNum={paginations.pageNum}
              onChange={this._paginationChange}
            />
          </div>
          {/* <div className={styles.pagination_box}>
                <XPagination 
                    resultTotal={paginations.resultTotal} 
                    pageSize={paginations.pageSize} 
                    pageNum={paginations.pageNum} 
                    onChange={this._paginationChange}
                />
            </div> */}
        </div>
      </KeepAlive>
    );
  }
}

ProductComment.propTypes = {
  history: PropTypes.object, // 路由history
  addOneBreadcrumbPath: PropTypes.func,
  keepSecondNavBreadcrumb: PropTypes.func,
  getVideoTableSource: PropTypes.func,// 通过Saga异步请求表格数据源的Action
  getVideoselectDataSource: PropTypes.func,// 通过Saga异步请求下拉选项数据方法Action
  getVideoSelectSource: PropTypes.func,// 通过Saga异步请求列表查询方法Action
  tableSource: PropTypes.array,
  selectData: PropTypes.array,
  paginations: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    tableSource: state.videocomment.videoDate.tableSource, // 表格数据源
    selectData: state.videocomment.videoDate.SelectData, // 视频评论下拉选项
    paginations: state.videocomment.videoDate.paginations, // 分页数据
  };
};

export default connect(mapStateToProps, { getVideoTableSource, getVideoselectDataSource, getVideoSelectSource, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(ProductComment);