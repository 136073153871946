import React from 'react';
import styles from './LogisticsTable.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, tooltipClick, ImgCell } from '@/components/TableCell';
import { Table } from 'antd';
import PropTypes from 'prop-types';

class LogisticsTable extends React.Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
    };

    _takeColumns = () => ([
        {
            title: 'LOGO',
            dataIndex: 'logo',
            key: 'logo',
            width: '10%',
            align: 'center',
            render: ImgCell
        },
        {
            title: '配送平台',
            dataIndex: 'name',
            key: 'name',
            width: '10%',
            align: 'center',
            render: (text, item) => text === '达达'
                ? tooltipClick(text, () => this.props.tableAction(item.id, 'detail', item))
                : tooltip(text)
        },
        {
            title: '城市开通数',
            dataIndex: 'supportCityNum',
            key: 'supportCityNum',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '描述',
            dataIndex: 'remark',
            key: 'remark',
            width: '30%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '启用时间',
            dataIndex: 'gmtOpen',
            key: 'gmtOpen',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'state.value',
            key: 'state.value',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作人',
            dataIndex: 'operator',
            key: 'operator',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: '10%',
            render: (text, item) => this._renderActionCell(item.id, item)
        }
    ]);

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        let element = null;
        if (item.state !== undefined && item.state.code == 0) {
            element = <div className={styles.item} onClick={() => tableAction(key, 'openService')}>开通服务</div>;
        } else {
            element = <div className={styles.item} onClick={() => tableAction(key, 'closeService')}>停止服务</div>;
        }
        return (
            <div className={styles.action}>
                {element}
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys });
            const rows = selectedRows.map((item) => {
                return item.id;
            });
            this.props.onRowSelection(rows);
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

LogisticsTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    onRowSelection: PropTypes.func, // 复选框事件
};

export default LogisticsTable;