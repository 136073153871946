/**
 * 删除
 */
import React, { useState } from 'react';
import { Modal, message, Icon, } from 'antd';
import PropTypes from 'prop-types';
import styles from './modeStyle.module.scss';
import http from '@/assets/api/http';

const Delete = ({ closeMode, modeParams: { merchantIds = [], merchantName = '' } }) => {

    const [loading, setLoading] = useState(false);

    const sumbit = () => {
        setLoading(true);
        http('/merchant/merchantSetting/delMerchantSetting', { merchantIds }, 'POST')
            .then(() => {
                message.success('删除成功');
                setLoading(false);
                closeMode(true);
            }).catch(res => {
                message.error(res.message);
                setLoading(false);
                closeMode();
            });
    };

    return (
        <Modal
            width={400}
            centered
            visible={true}
            confirmLoading={loading}
            onOk={sumbit}
            onCancel={() => closeMode(false)}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='继续'
        >
            <div className={styles.container}>
                <div className={styles.title}>
                    <Icon type="exclamation-circle" style={{ fontSize: 23 }} />
                    <h4>确认删除商户吗？</h4>
                </div>
                {
                    merchantName && <div className={`${styles.layout} ${styles.labels}`}>
                        <label>商户名称:</label>
                        <p>{merchantName}</p>
                    </div>
                }
            </div>
        </Modal>
    );
};
Delete.propTypes = {
    modeParams: PropTypes.object.isRequired, // 弹框入参
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};
export default Delete;