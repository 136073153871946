import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table, Button } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '付款时间',
            dataIndex: 'payTime',
            key: 'payTime',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: '12%',
            align: 'center',
            render: (text, record) => this._incomeCell(text, record)
        },
        {
            title: '实收金额(元)',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
            width: '8%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '平台服务费(元)',
            dataIndex: 'serviceFee',
            key: 'serviceFee',
            width: '8%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '应付会员金额(元)',
            dataIndex: 'memberFee',
            key: 'memberFee',
            width: '8%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '应付服务商金额(元)',
            dataIndex: 'serverFee',
            key: 'serverFee',
            width: '8%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '应付区域公司金额(元)',
            dataIndex: 'regionalFee',
            key: 'regionalFee',
            width: '8%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '应付运营中心金额(元)',
            dataIndex: 'operationCenterFee',
            key: 'operationCenterFee',
            width: '8%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '平台自然流量服务费收益(元)',
            dataIndex: 'naturalFlowServiceFee',
            key: 'naturalFlowServiceFee',
            width: '8%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '平台净收益(元)',
            dataIndex: 'platNetIncome',
            key: 'platNetIncome',
            width: '8%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '平台净收益率',
            dataIndex: 'netRatio',
            key: 'netRatio',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ]);

    _incomeCell = (text, record) => {
        const { tableAction } = this.props;
        return <Button type='link' onClick={() => tableAction('sale', record)}>{text}</Button>;
    }

    _takeDataSource = (dataList = []) => {
        return dataList;
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    tableAction: PropTypes.func, // 对表格的操作
};

export default TableContent;