/**
 * 协议下载列表 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import model from '@/reducers/businessmanage/merchantunion/model';
import { Modal, message, Table } from 'antd';
import styles from './downloadList.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { autoPageNum, autopageSize } from '@/assets/config';

class DownloadList extends React.Component {

    state = {
        confirmLoading: false,
    }

    _takeColumns = () => ([
        {
            title: '文件名称',
            dataIndex: 'fileName',
            key: 'fileName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '创建时间',
            dataIndex: 'startTime',
            key: 'startTime',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'urlState',
            key: 'urlState',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作人',
            dataIndex: 'userName',
            key: 'userName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: '10%',
            align: 'center',
            render: (text, record) => this._tableAction(text, record)
        },
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList.map((item) => {
                item.urlState = item.status == 1 ? '导出完成' : '导出中';
                return item;
            });
        } else {
            return [];
        }
    }

    _tableAction = (text, record) => {
        return (
            <div className={styles.action}>
                { record.status == 1 ? <div className={styles.item} onClick={() => { window.open(record.url); }}>下载</div> : null}
            </div>
        );
    };

    componentDidMount() {
        this._searchHandle();
    }

    // 点击取消
    _handleCancel = () => {
        this.props.closeMode();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize } = this.state;
        const query = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getDownloadList(query);
    }

    // 分页
    paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { confirmLoading } = this.state;
        const { downloadList } = this.props;
        // const { pageNum, pageSize, resultTotal, dataList } = downloadList;

        return (
            <Modal
                width={1000}
                centered
                title='协议下载列表'
                visible={true}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                footer={null}
            >
                <div className={styles.tableContainer} style={{ height: '600px' }}>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        rowKey={(record, index) => index}
                        columns={this._takeColumns()}
                        dataSource={this._takeDataSource(downloadList)}
                        pagination={false}
                        scroll={{ y: true }}
                    />
                    {/* <XPagination
                        resultTotal={resultTotal}
                        pageSize={pageSize}
                        pageNum={pageNum}
                        onChange={this.paginationChange}
                    /> */}
                </div>
            </Modal>
        );
    }
}

DownloadList.propTypes = {
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getDownloadList: PropTypes.func,
    downloadList: PropTypes.array
};
const mapStateToProps = (state) => ({
    downloadList: state.merchantUnion.getDownloadList
});
export default connect(mapStateToProps, { ...model.actions })(DownloadList);