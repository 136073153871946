import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

export default class TableData extends Component {

	static propTypes = {
		tableAction: PropTypes.func, // 对表格的操作
		paginationChange: PropTypes.func,
		renderData: PropTypes.object
	}

	_columns = [
		{
			title: '服务说明',
			dataIndex: 'serviceName',
			key: 'serviceName',
			align: 'center',
			width: 300,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '描述',
			dataIndex: 'serviceDesc',
			key: 'serviceDesc',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '售后类型',
			dataIndex: 'salesafterType.value',
			key: 'salesafterType.value',
			align: 'center',
			width: 200,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			width: 200,
			render: (id) => this._tableAction(id)
		},
	]

	_tableAction = (key) => {
		const { tableAction } = this.props;
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>
				<div className={styles.item} onClick={() => tableAction(key, 'delete')}>删除</div>
			</div>
		);
	};


	render() {
		const {
			paginationChange,
			renderData: {
				dataList = [],
				pageNum = 1,
				pageSize = 30,
				resultTotal = 0
			} = {}
		} = this.props;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey='id'
					columns={this._columns}
					dataSource={dataList}
					pagination={false}
					scroll={{ y: true }}
				/>
				<XPagination
					resultTotal={resultTotal}
					pageSize={pageSize}
					pageNum={pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}