import * as T from './actiontypes';

export const getListLiveData = (params = {}) => ({
    type: T.GET_LIST_LIVEDATA,
    payload: params
});

export const getListLiveStatus = () => ({
    type: T.GET_LIST_LIVESTATUS,
    payload: {}
});

export const changeIsHomeRecoAction = (params = {}) => ({
    type: T.CHANGE_ISHOMERECO,
    payload: params
});

export const changeIsChannelRecoAction = (params = {}) => ({
    type: T.CHANGE_ISCHANNELRECO,
    payload: params
});


export const getListLiveSelect = (state, className) => ({
    type: T.GET_LIST_LIVESELECT,
    payload: {
        state, className
    }
});

export const getListVideoData = (params = {}) => ({
    type: T.GET_LIST_VIDEODATA,
    payload: params
});

export const getListVideoSelect = (state, className) => ({
    type: T.GET_LIST_VIDEOSELECT,
    payload: {
        state: 1, pageSize: 1000, pageNum: 1, className
    }
});

export const getListLiveHistory = (params = {}) => ({
    type: T.GET_LIST_LIVEHISTORY,
    payload: params
});

export const getListLiveRecommend = (params = {}) => ({
    type: T.GET_LIST_LIVERECOMMEND,
    payload: params
});

export const getListVCRecommend = (feedType, isChannelReco, isHomeReco, feedClassId, pageNum, pageSize) => ({
    type: T.GET_LIST_VCRECOMMEND,
    payload: {
        feedType, isChannelReco, isHomeReco, feedClassId, pageNum, pageSize
    }

});

export const getListVideoDetail = (tel, feedTitle, accountId, feedClassId, isHomeReco, isChannelReco) => ({
    type: T.GET_LIST_VCRECOMMEND,
    payload: {
        tel, feedTitle, accountId, feedClassId, isHomeReco, isChannelReco
    }
});

export const getListVideoUsername = (params = {}) => ({
    type: T.GET_LIST_VIDEOUSERNAME,
    payload: params
});

export const getListVideoProduct = (params = {}) => ({
    type: T.GET_LIST_VIDEOPRODUCT,
    payload: params
});

export const getListLiveStar = (params) => ({
    type: T.GET_LIST_LIVESTAR,
    payload: params
});

export const getListLiveSell = (params) => ({
    type: T.GET_LIST_LIVESELL,
    payload: params
});

//主播类型枚举
export const getAnchorTypeEnum = (params = {}) => ({
    type: T.GET_ANCHOR_TYPE_ENUM,
    payload: params
});


/**
 * 获取'秀场管理 -频道列表- 社区列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} feedClassId 社区分类
 * @param {string} feedTitle 社区标题
 **@param {string} tel 手机号（跨库暂不测）
 * @param {string} areaId 区域ID(暂不测)
 * @param {string} provinceId 所属省(暂不测)
 * @param {string} cityId 所属区(暂不测)
 *  @param {string} isHomeReco 首页推荐
 * @param {string} isChannelReco  频道推荐
 */
export const getcommunityTableSource = (params = {}) => ({
    type: T.GET_TABLE_DATA,
    payload: params
});
/**
 * 获取'秀场管理 -频道列表- 社区列表-社区所属分类下拉'数据源(saga)
 * @param 暂无
 */
export const getcommunityState = () => ({
    type: T.GET_COMSTATE_DATA,
});

/**
 * 获取'秀场管理 -频道列表- 社区列表-首页与频道公用分类下拉'数据源(saga)
 * @param 暂无
 */
export const getcommunityRecoState = () => ({
    type: T.GET_COMRECO_DATA,
});

/**details
 * 获取'秀场管理 -频道列表- 社区查看详情'数据源(saga)
 * @param {number} id 社区查看详情对应id 
 * 
 */
export const getcommunityQueryDetail = (id) => ({
    type: T.GET_COMDETAIL_DATA,
    payload: {
        id
    }
});

/**details getvideoQueryDetail
 * 获取'秀场管理 -频道列表- 视频查看详情'数据源(saga)
 * @param {number} id 社区查看详情对应id 
 * 
 */
export const getvideoQueryDetail = (id) => ({
    type: T.GET_VIDEODETAIL_DATA,
    payload: {
        id
    }
});

