import { createModel } from '@/store/tools';

const model = {
    namespace: 'financialsetting',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getYunstAuthTypeEnum': T('getYunstAuthTypeEnum'),
            'getBankNameList': T('getBankNameList'),
            'getBankProviceList': T('getBankProviceList'),
            'getBankAreaList': T('getBankAreaList'),
        },
        actions: {
            'getYunstAuthTypeEnum': A('getYunstAuthTypeEnum'), // 获取认证类型枚举
            'getBankNameList': A('getBankNameList'), // 获取银行卡列表
            'getBankProviceList': A('getBankProviceList'), // 获取银行的省列表
            'getBankAreaList': A('getBankAreaList'), // 获取银行的地区列表
        },
        sagas: {
            'getYunstAuthTypeEnum': S('getYunstAuthTypeEnum', '/enum/YunstAuthTypeEnum'),
            'getBankNameList': S('getBankNameList', '/company/info/yums/bankNameList'),
            'getBankProviceList': S('getBankProviceList', '/company/info/yums/listProvice'),
            'getBankAreaList': S('getBankAreaList', '/company/info/yums/listBankAreaCode'),
        },
        reducers: {
            'getYunstAuthTypeEnum': R('getYunstAuthTypeEnum', []),
            'getBankNameList': R('getBankNameList', []),
            'getBankProviceList': R('getBankProviceList', []),
            'getBankAreaList': R('getBankAreaList', []),
        }
    })
};
export default createModel(model);