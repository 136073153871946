import * as Audit from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

const initDetail={
    accountId: 0,
    approvalUserName:'',
    className:'',
    content:'',
    coverPage:'',
    feedTitle:'',
    fileNames: [],
    getSpokesmanTaskResp: {
        demoUrl:'',
        goodsName:'',
        id: 0,
        mainImgUrl:'',
        rewardNum: 0,
        rewardType:'',
        taskDesc:'',
        taskName:''
    },
    gmtCreate:'',
    gmtModified:'',
    id: 0,
    modifyUserId: 0,
    musicId: 0,
    musicName:'',
    nickname:'',
    remark:'',
    state:'',
    tel:''
};

//社区审核列表
export const communityAuditList = (state = initData, action) => {
    switch (action.type) {
        case Audit.SET_COMMUNITYAUDIT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

//获取社区审核列表详情
export const communityAuditDetail = (state = initDetail, action) => {
    switch (action.type) {
        case Audit.SET_COMMUNITYAUDIT_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};