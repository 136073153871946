//获取/保存运营中心列表
export const GET_OPERATIONAUDIT_LIST = 'OPERATIONAUDIT/GET_OPERATIONAUDIT_LIST';
export const SET_OPERATIONAUDIT_LIST = 'OPERATIONAUDIT/SET_OPERATIONAUDIT_LIST';

//获取/保存状态下拉
export const GET_OPERATIONAUDIT_STATUS = 'OPERATIONAUDIT/GET_OPERATIONAUDIT_STATUS';
export const SET_OPERATIONAUDIT_STATUS = 'OPERATIONAUDIT/SET_OPERATIONAUDIT_STATUS';

//获取/保存公司详情
export const GET_OPERATIONAUDIT_DETAIL = 'OPERATIONAUDIT/GET_OPERATIONAUDIT_DETAIL';
export const SET_OPERATIONAUDIT_DETAIL = 'OPERATIONAUDIT/SET_OPERATIONAUDIT_DETAIL';