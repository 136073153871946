import React from 'react';
import styles from './ActivityDateTable.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class ActivityDateTable extends React.Component {
    _columns = () => ([
        {
            title: '领取人',
            dataIndex: 'userName',
            key: 'userName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '领取时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '超时时间',
            dataIndex: 'overTime',
            key: 'overTime',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'stateName',
            key: 'stateName',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '完成时间',
            dataIndex: 'finishedTime',
            key: 'finishedTime',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '作品',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '10%',
            render: (id, record) => this._TableAction(id, record)
        }
    ]);

    // 解析表格数据，使符合columns格式要求
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                return item;
            });
        }
    }

    _TableAction = (id, record) => {
        const { tableAction } = this.props;
        const { state, checkState } = record;
        return (
            <div className={styles.action}>
                {(state == 'OVERTIME' || (state == 'WORK' && checkState == '0') || state == 'CANCEL') ? null : <div className={styles.item} onClick={() => tableAction(id, 'works', record)}>查看作品</div>}
            </div >
        );
    };


    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.container}>
                <div className={styles.table_box}>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        rowKey={(record) => record.id}
                        columns={this._columns()}
                        dataSource={this._takeDataSource(dataList)}
                        pagination={false}
                        scroll={{ y: 350 }}
                    />
                </div>
                <div className={styles.pagination_box}>
                    <XPagination
                        resultTotal={resultTotal}
                        pageSize={pageSize}
                        pageNum={pageNum}
                        onChange={paginationChange} />
                </div>
            </div>
        );
    }
}

ActivityDateTable.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

export default ActivityDateTable;