import { createModel } from '@/store/tools';

const model = {
    namespace: 'knowledgeclassify',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getKLClassifyList': T('getKLClassifyList'),

            'getKVClassifyList': T('getKVClassifyList'),

            'getKCClassifyList': T('getKCClassifyList'),

            'getKClassifyState': T('getKClassifyState'),
            'getKClassifyDetail': T('getKClassifyDetail'),
        },
        actions: {
            'getKLClassifyList': A('getKLClassifyList'),

            'getKVClassifyList': A('getKVClassifyList'),

            'getKCClassifyList': A('getKCClassifyList'),

            'getKClassifyState': A('getKClassifyState'),
            'getKClassifyDetail': A('getKClassifyDetail'),
        },
        sagas: {
            'getKLClassifyList': S('getKLClassifyList', '/show/showClass/listChargeLiveBoradcasClass'),

            'getKVClassifyList': S('getKVClassifyList', '/show/showClass/listChargeShortVideoClass'),

            'getKCClassifyList': S('getKCClassifyList', '/show/showClass/listChargeGraphicsClass'),

            'getKClassifyState': S('getKClassifyState', '/enum/StateEnum'),
            'getKClassifyDetail': S('getKClassifyDetail', '/show/showClass/getShowClassInfo', 'POST', 'id'),
        },
        reducers: {
            'kLClassifyList': R('getKLClassifyList', { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 }),

            'kVClassifyList': R('getKVClassifyList', { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 }),

            'kCClassifyList': R('getKCClassifyList', { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 }),

            'kClassifyState': R('getKClassifyState', []),
            'kClassifyDetail': R('getKClassifyDetail', {}),
        }
    })
};
export default createModel(model);