/**
 * 广告管理 Saga集合
 */
import { getAdvertPlacementEdit, getAdvertPlacementList, getAdvertPlacementAudit, getAdvertPlacementStartAndStop, getAdvertPlacementDetail, getAdvertPlacementDelete, getAdvertPlacementAdd, getAdvertList, getAdvertStrategyList, getAdvertPlacementStatus} from './advertPlacement/saga';
import { getAdvertStrategAdsList, getAdvertStrategEdit, getAdvertStrategList, getAdvertStrategStartAndStop, getAdvertStrategDetail, getAdvertStrategDelete, getAdvertStrategAdd } from './advertStrateg/saga';
import { getAdvertAdsenseList, getAdvertAdsenseDetail } from './advertAdsense/saga';
import { getAdvertCommentList } from './advertComment/saga';
import advertService from './advertService/model';


export default [
    getAdvertPlacementList,
    getAdvertPlacementAudit,
    getAdvertPlacementStartAndStop,
    getAdvertPlacementDetail,
    getAdvertPlacementDelete,
    getAdvertPlacementAdd,
    getAdvertPlacementEdit,
    getAdvertList,
    getAdvertStrategyList,
    getAdvertStrategEdit,
    getAdvertStrategList,
    getAdvertStrategStartAndStop,
    getAdvertStrategDetail,
    getAdvertStrategDelete,
    getAdvertStrategAdd,
    getAdvertStrategAdsList,
    getAdvertAdsenseList,
    getAdvertAdsenseDetail,
    getAdvertCommentList,
    getAdvertPlacementStatus,
    ...Object.values(advertService.sagas)
];
