import React from 'react';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './OrderTable.module.scss';

class OrderTable extends React.Component {

    _takeColumns = () => ([
        {
            title: '订单号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '特权值来源',
            dataIndex: 'changeTypeName',
            key: 'changeTypeName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '获得特权值',
            dataIndex: 'changeBalance',
            key: 'changeBalance',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'operateTypeName',
            key: 'operateTypeName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ]);

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'info')}>查看详情</div>
                {
                    item.exchangeState.code == 'UNCHANGED' &&
                    <div className={styles.item} onClick={() => tableAction(key, 'delivery', item)}>交割</div>
                }
                {
                    item.exchangeState.code == 'UNCHANGED' &&
                    <div className={styles.item} onClick={() => tableAction(key, 'cancellation', item)}>作废</div>
                }
            </div>
        );
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default OrderTable;
