import * as SUGGESTIONS from './actionTypes';

//获取意见反馈列表
export const getSuggestionsList = (param) => ({
    type: SUGGESTIONS.GET_SUGGESTIONS_LIST,
    payload: param
});
//获取意见反馈详情
export const getSuggestionsDetail = (param) => ({
    type: SUGGESTIONS.GET_SUGGESTIONS_DETAIL,
    payload: param
});
// 获取意见反馈下拉
export const getSuggestionsType = (param) => ({
    type: SUGGESTIONS.GET_SUGGESTIONS_TYPE,
    payload: param
});
// 获取意见反馈端口下拉
export const getSuggestionsPort = (param) => ({
    type: SUGGESTIONS.GET_SUGGESTIONS_PORT,
    payload: param
});
