import { createModel } from '@/store/tools';

const initList = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };
const initDetail = {
    ctrGoodsList: { dataList: [] },
    ctrReplyList: [{ replyReason: {} }],
    state: {}
};

const model = {
    namespace: 'purchaseContract',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getPurchaseContractList': T('getPurchaseContractList'),
            'getPurchaseContractInfo': T('getPurchaseContractInfo'),
            'getPurchaseSupplierSele': T('getPurchaseSupplierSele'),
            'getPurchaseCompanySele': T('getPurchaseCompanySele'),
            'getPurchaseStateSele': T('getPurchaseStateSele'),
            'getPurchaseModeGoods': T('getPurchaseModeGoods'),
            'getPurchaseModeBrands': T('getPurchaseModeBrands'),
            'getPurchaseEditTable': T('getPurchaseEditTable'),
            'getPurchaseStatusEnum': T('getPurchaseStatusEnum'),
            'getPurchaseFail': T('getPurchaseFail')
        },
        actions: {
            'getPurchaseContractList': A('getPurchaseContractList'),
            'getPurchaseContractInfo': A('getPurchaseContractInfo'),
            'getPurchaseSupplierSele': A('getPurchaseSupplierSele'),
            'getPurchaseCompanySele': A('getPurchaseCompanySele'),
            'getPurchaseStateSele': A('getPurchaseStateSele'),
            'getPurchaseModeGoods': A('getPurchaseModeGoods'),
            'cleanPurchaseModeGoods': C('getPurchaseModeGoods'),
            'getPurchaseModeBrands': A('getPurchaseModeBrands'),
            'cleanDetailInfo': C('getPurchaseContractInfo'),
            'getPurchaseEditTable': A('getPurchaseEditTable'),
            'getPurchaseStatusEnum': A('getPurchaseStatusEnum'),
            'getPurchaseFail': A('getPurchaseFail')
        },
        sagas: {
            'getPurchaseContractList': S('getPurchaseContractList', '/purchase/ctr/list'),
            'getPurchaseContractInfo': S('getPurchaseContractInfo', '/purchase/ctr/', 'POST', 'id'),
            'getPurchaseSupplierSele': S('getPurchaseSupplierSele', '/operating/supplier/supplierDropDown'),
            'getPurchaseCompanySele': S('getPurchaseCompanySele', '/purchase/ctr/option/list'),
            'getPurchaseStateSele': S('getPurchaseStateSele', '/purchase/ctr/option/list'),
            'getPurchaseModeGoods': S('getPurchaseModeGoods', '/purchase/ctr/supplier/goods'),
            'getPurchaseModeBrands': S('getPurchaseModeBrands', '/goods/brand/listBrands'),
            'getPurchaseEditTable': S('getPurchaseEditTable', '/purchase/ctr/goods/list'),
            'getPurchaseStatusEnum': S('getPurchaseStatusEnum', '/enum/PurchaseCtrStateEnum'),
            'getPurchaseFail': S('getPurchaseFail', '/purchase/ctr/reply/exp')
        },
        reducers: {
            'purchaseContractList': R('getPurchaseContractList', initList),
            'purchaseContractInfo': R('getPurchaseContractInfo', initDetail),
            'purchaseSupplierSele': R('getPurchaseSupplierSele', []),
            'purchaseCompanySele': R('getPurchaseCompanySele', []),
            'purchaseStateSele': R('getPurchaseStateSele', []),
            'purchaseModeGoods': R('getPurchaseModeGoods', initList),
            'purchaseModeBrands': R('getPurchaseModeBrands', initList),
            'purchaseEditTable': R('getPurchaseEditTable', initList),
            'purchaseStatusEnum': R('getPurchaseStatusEnum', []),
            'purchaseFail': R('getPurchaseFail', initList),
        }
    })
};
export default createModel(model);