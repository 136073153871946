import React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { XPagination, XInput, XOKButton, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table, Switch } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';
import styles from './TableContent.module.scss';

class FreeTableContent extends React.Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
    };

    _takeColumns = () => ([
        {
            title: '规则名称',
            dataIndex: 'ruleName',
            key: 'ruleName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '指定打款用户',
            dataIndex: 'operatorName',
            key: 'operatorName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '免单金额（元）',
            dataIndex: 'freeOrderAmount',
            key: 'freeOrderAmount',
            width: '8%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '有效期',
            dataIndex: 'effectiveStartTime',
            key: 'effectiveStartTime',
            width: '12%',
            align: 'center',
            render: (text, item) => this._renderTextCell(text, item)
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'effectiveState',
            key: 'effectiveState',
            width: '8%',
            align: 'center',
            render: (text, item) => (this.SwitchCell(text, item, this.props.tableAction))
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            width: '8%',
            render: (text, item) => this._renderActionCell(text, item)
        }
    ]);
    // 表格项内容为开关
    SwitchCell = (sw, item, tableAction) => {
        let checked = true;
        if(sw == 'OFF'){
            checked = false;
        }
        return(
            <div style={{wordWrap:'break-word',wordBreak:'break-all'}}>
                <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    key={item.freeOrderRuleId + item['effectiveState']}
                    checked={checked}
                    onChange={(value)=>{
                        tableAction(item.freeOrderRuleId, 'switch', value);
                    }}
                />
            </div>
        );
    };
    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _renderImgCell = (url) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.imgbox}>
                    <img className={styles.img} src={url} />
                </div>
            </div>
        );
    };
    _renderTextCell = (text, item) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {item.effectiveStartTime}<br/>
                {item.effectiveEndTime}
            </div>
        );
    };

    _renderNumberCell = (text) => {
        const price = parseInt(text) / 100;
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {price}
            </div>
        );
    };

    _renderActionCell = (key, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'edit', record)}>编辑</div>
                <div className={styles.item} onClick={() => tableAction(key, 'del', record)}>删除</div>
            </div>
        );
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys });
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    render() {
        const { paginationChange } = this.props;
        const { selectedRowKeys } = this.state;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                 {/*<XOKButton style={{ marginBottom: '20px', width: '120px' }} label='新建' />*/}
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => record.freeOrderRuleId}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

FreeTableContent.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};
export default connect(null, { addOneBreadcrumbPath })(FreeTableContent);