import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell, cellWidth } from '@/components/TableCell';
import ButtonPerssion from '@/routes/ButtonPerssion';


const TableContent = ({
  renderData,
  tableAction,
  paginationChange,
  page
}) => {
  const columns = [
    {
      title: '申请编号',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: cellWidth.normal,
      onCell: tooltipStyle,
      render: tooltip
    },
    {
      title: '商家名称',
      dataIndex: 'merchantName',
      key: 'merchantName',
      align: 'center',
      width: cellWidth.normal,
      onCell: tooltipStyle,
      render: tooltip
    },
    {
      title: '商家类型',
      dataIndex: 'merchantType',
      key: 'merchantType',
      align: 'center',
      width: cellWidth.normal,
      onCell: tooltipStyle,
      render: (text) => stateCell(text)
    },
    {
      title: '申请三级经营品类数',
      dataIndex: 'categoryNum',
      key: 'categoryNum',
      align: 'center',
      width: cellWidth.normal,
      onCell: tooltipStyle,
      render: tooltip
    },
    {
      title: '状态',
      dataIndex: 'auditState',
      key: 'auditState',
      align: 'center',
      width: cellWidth.normal,
      render: (text) => stateCell(text)
    },
    {
      title: '提交时间',
      dataIndex: 'gmtCreate',
      key: 'gmtCreate',
      align: 'center',
      width: cellWidth.normal,
      onCell: tooltipStyle,
      render: tooltip
    },
    {
      title: '操作',
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      width: cellWidth.normal,
      fixed: 'right',
      render: (key, record) => _tableAction(record.id, record)
    },
  ];

  const stateCell = (text) => {
    return tooltip(text ? text.value : '');
  };

  // 表格操作
  const _tableAction = (key, record) => {
    return (
      <div className={styles.action}>
        <div className={styles.item} onClick={() => tableAction(key, 'detail', record, '查看详情')}>查看</div>
        {
          record.auditState.code === 'APPLY' ? <ButtonPerssion code='auditmanage/businessCategory/sh'>
            <div className={styles.item} onClick={() => tableAction(key, 'audit', record, '审核详情')}>审核</div>
          </ButtonPerssion> : null
        }
      </div>
    );
  };

  return (
    <div className={styles.tableContainer} style={{ height: '100%' }}>
      <Table
        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
        rowKey={record => record.id}
        columns={columns}
        pagination={false}
        dataSource={renderData.dataList}
        scroll={{ x: 'max-content', y: scrollY }} />
      <XPagination
        resultTotal={renderData.resultTotal}
        pageSize={page.pageSize}
        pageNum={page.pageNum}
        onChange={paginationChange} />
    </div>
  );
};

TableContent.propTypes = {
  tableAction: PropTypes.func,
  paginationChange: PropTypes.func,
  renderData: PropTypes.object,
  page: PropTypes.object,
  scrollY: PropTypes.string,
  withdrawalIds: PropTypes.array
};
export default XTableHOC(TableContent, 40);