/**
 * 营销商品分组
 */
import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import { XOKButton, XCancelButton, XInput, showConfirm } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import CreateOrEdit from '../../modal/CreateOrEdit';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/marketingmanage/productgroup/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            categoryTitle: '', // 分组名称
        };
    }

    // 创建分组
    _createClassify = () => {
        this.setState({ currentOpenMode: 'CREAT', openModeParam: {} });
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { categoryTitle, pageNum, pageSize } = this.state;
        this.searchCache = {
            groupingName: categoryTitle,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getProductGroupList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ categoryTitle: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST').then(() => {
            message.success(winTxt);
            this._searchHandle('useCache');
        }).catch((error) => {
            message.error(error.message);
        });
    }

    // 表格操作
    _tableAction = (id, activeType, params) => {
        switch (activeType) {
            case 'edit':
                this.setState({ currentOpenMode: 'EDITE', openModeParam: params });
                break;
            case 'delete':
                showConfirm('确定要删除该任务分组？', '', () => {
                    this._sendReq('/marketing/activityGoodsGrouping/delete', { id }, '任务分组删除成功。');
                });
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'CREAT':
                return <CreateOrEdit title='创建分组' visible={true}
                    data={{ action: 'create', url: '/marketing/activityGoodsGrouping/save' }}
                    closeMode={this._closeMode} />;
            case 'EDITE':
                return <CreateOrEdit title='编辑分组' visible={true}
                    data={{ action: 'edit', url: '/marketing/activityGoodsGrouping/save', id: this.state.openModeParam.id, categoryTitle: this.state.openModeParam.groupingName }} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', openModeParam: {} }, () => {
            if (update) this._searchHandle('useCache');
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { categoryTitle } = this.state;

        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='分组名称'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='categoryTitle'
                                value={categoryTitle}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '100px' }} label='创建分组' onClick={this._createClassify} />
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={this.props.productGroupList} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    getProductGroupList: PropTypes.func,
    productGroupList: PropTypes.object
};

const mapStateToProps = (state) => ({
    productGroupList: state.productgroup.productGroupList
});

export default connect(mapStateToProps, { ...model.actions })(Main);