/**
 * 在线播放弹框
 */
import React from 'react';
import { Modal, Input, Radio } from 'antd';
import PropTypes from 'prop-types';
import styles from './OnlinePlayModal.module.scss';

class OnlinePlayModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        status: '',
    }
    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }


    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading } = this.state;

        return (
            <Modal
                width={470}
                centered
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <video width="400" height="300" src={this.props.audit.broadcasUrl} poster="" muted controls></video>
                </div>
            </Modal>
        );
    }
}

OnlinePlayModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    SinglePatch: PropTypes.bool,// 权限：单个修改还是批量修改，当为true时表示单个修改
    audit: PropTypes.object
};

export default OnlinePlayModal;