/**
 * 在线播放弹框
 */
import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import styles from './OnlinePlayModal.module.scss';
import { XMediaFn } from '@/components/xqxc_ui';


class OnlinePlayModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        status: '',
        videoError: true,//请求错误
    }

    componentDidMount() {
        // console.log(this.props.data);
    }

    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    //请求响应错误
    _handleImageErrored = () => {
        this.setState({
            videoError: false,
        });
    }

    render() {
        const { visible, confirmLoading, videoError, } = this.state;
        const { data, } = this.props;
        return (
            <Modal
                centered
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='关闭'
                okText='确认'
            >
                <div className={styles.container}>
                    {videoError && <video width="100%" height="100%" src={data} controls preload='auto' onError={this._handleImageErrored.bind(this)}></video>}
                    {(!videoError || data == '') && <div className={styles.error}><img src={require('@/assets/image/nodata/xingqiuNodataM.png')} /><p>暂无数据！</p></div>}
                </div>

            </Modal>
        );
    }
}

OnlinePlayModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    data: PropTypes.string,//入参数据
};

export default OnlinePlayModal;