import { createModel } from '@/store/tools';

const model = {
    namespace: 'paymentChannel',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getPremiumYieldData': T('getPremiumYieldData'),
            'getRMBCashData': T('getRMBCashData'),
        },
        actions: {
            'getPremiumYieldData': A('getPremiumYieldData'),
            'getRMBCashData': A('getRMBCashData'),
        },
        sagas: {
            'getPremiumYieldData': S('getPremiumYieldData', '/admin/stockPremium/findPage'),
            'getRMBCashData': S('getRMBCashData', '/admin/exchangeRate/findPage'),
        },
        reducers: {
            'premiumYieldData': R('getPremiumYieldData', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'rmbCashData': R('getRMBCashData', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);