/**
 * 免单看板
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Order from './actionTypes';

//免单看板列表
export const getFreeOrderList = function* () {
    yield takeEvery(Order.GET_FREEORDER_LIST, function* requestData(action) {
        try {
            let result = yield http('/marketing/activityInfo/freeOrderDisplayBoard', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_FREEORDER_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//免单自动打款列表
export const getAutoFreeOrderList = function* () {
    yield takeEvery(Order.GET_AUTOFREEORDER_LIST, function* requestData(action) {
        try {
            let result = yield http('/marketing/activityInfo/listFreeOrderRule', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_AUTOFREEORDER_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 免单看板 打款状态
export const getFreeOrderStatus = function* () {
    yield takeEvery(Order.GET_FREEORDER_STATUS, function* requestData(action) {
        try {
            let result = yield http('/marketing/activityInfo/selectPayState', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_FREEORDER_STATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 免单看板 自动打款状态
export const getAutoFreeOrderStatus = function* () {
    yield takeEvery(Order.GET_AUTOFREEORDER_STATUS, function* requestData(action) {
        try {
            let result = yield http('/marketing/activityInfo/effectiveState', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_AUTOFREEORDER_STATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//免单看板列表
export const getFreeProductList = function* () {
    yield takeEvery(Order.GET_FREEPRODUCT_LIST, function* requestData(action) {
        try {
            let result = yield http('/marketing/activityGoods/getFreeOrderGoodsList', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_FREEPRODUCT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//免单看板详情列表
export const getFreeOrderKanBanList = function* () {
    yield takeEvery(Order.GET_FREEORDERKANBAN_LIST, function* requestData(action) {
        try {
            let result = yield http('/marketing/activityGoods/getFreeOrderBoardList', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_FREEORDERKANBAN_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};