/**
 * 【消息列表】
 */
import React from 'react';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XOKButton, XSelect, XCancelButton, XDatePicker, XInput, XTabNav, showConfirm } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { message } from 'antd';
import { connect } from 'react-redux';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getMessageMoveList, getMessageStatus, getPushPeople } from '@/reducers/messagemanage/messageMove/actions';
import { getMessageClassifyLists } from '@/reducers/messagemanage/messageClassify/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {
    state = {
        tab: [
            { id: 1, label: '消息列表' },
            // { id: 2, label: '已读' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
        orderID: '', // 当前操作的订单ID 
        systemType: {},
        messageState: {},
        pushTimeStart: null,
        pushTimeEnd: null,
        messageTitle: '',
        pageNum: 1,
        pageSize: 10,
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getMessageMoveList();
        this.props.getMessageClassifyLists();
        this.props.getMessageStatus();
        this.props.getPushPeople();
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }
    // 查询
    _searchHandle = (useCache) => {
        const { systemType, messageState, pushTimeStart, pushTimeEnd, messageTitle, pageNum, pageSize } = this.state;
        this.searchCache = {
            systemType: systemType.code,
            messageState: messageState.code,
            messageTitle,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pushTimeStart,
            pushTimeEnd,
        };
        this.props.getMessageMoveList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置
    _resetHandle = () => {
        this.setState({
            systemType: {},
            messageState: {},
            pushTimeStart: null,
            pushTimeEnd: null,
            messageTitle: '',
            pageNum: 1,
            pageSize: 10,
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        // this.props.getMessageMoveList();
    }
    // 表格操作
    _tableAction = (id, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'send':
                // this.setState({ currentOpenMode: 'send', openModeParam: id });
                showConfirm('是否确定立即发送？', '', () => {
                    http('/user/messageinfo/sendMessageInstant', { id }, 'POST')
                        .then(() => {
                            message.success('发送成功!');
                            this._searchHandle();
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'close':
                showConfirm('是否确定关闭？', '', () => {
                    http('/user/messageinfo/closeMessageInfo', { id }, 'POST')
                        .then(() => {
                            message.success('关闭成功!');
                            this._searchHandle();
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'info':
                addOneBreadcrumbPath({
                    title: '消息详情',
                    path: `/home/messagemanage/messageMove/detail/${id}`
                });
                history.push({
                    pathname: `/home/messagemanage/messageMove/detail/${id}`
                });
                break;
            case 'edit':
                addOneBreadcrumbPath({
                    title: '编辑消息',
                    path: `/home/messagemanage/messageMove/edit/${id}`
                });
                history.push({
                    pathname: `/home/messagemanage/messageMove/edit/${id}`
                });
                break;
            case 'delete':
                showConfirm('是否确定删除？', '', () => {
                    http('/user/messageinfo/delete', { id }, 'POST')
                        .then(() => {
                            message.success('删除成功!');
                            this._searchHandle();
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageSize,
            pageNum,
        });
        const { systemType, messageState, pushTimeStart, pushTimeEnd, messageTitle } = this.state;
        this.searchCache = {
            systemType: systemType.code,
            messageState: messageState.code,
            messageTitle,
            pageNum,
            pageSize,
            pushTimeStart,
            pushTimeEnd,
        };
        this.props.getMessageMoveList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 消息类型
    _messageTypeChange = (e) => {
        this.setState({
            systemType: e,
        });
    }
    // 消息状态
    _messageStatusChange = (e) => {
        this.setState({
            messageState: e,
        });
    }
    // 新建
    _newHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建消息',
            path: '/home/messagemanage/messageMove/add'
        });
        history.push({
            pathname: '/home/messagemanage/messageMove/add'
        });
    }
    render() {
        const { curTabID, messageTitle, pushTimeEnd, pushTimeStart, systemType, messageState } = this.state;
        const { messageMoveList, pushPeople, messageStatus } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='主题名称'
                                    placeholder='请输入'
                                    value={messageTitle}
                                    bindThis={this}
                                    bindName='messageTitle'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='消息状态'
                                    placeholder='请选择'
                                    renderData={messageStatus}
                                    onChange={this._messageStatusChange}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={messageState.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='推送人群'
                                    placeholder='请选择'
                                    renderData={pushPeople}
                                    onChange={this._messageTypeChange}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={systemType.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XDatePicker
                                    label='推送时间'
                                    value={pushTimeStart}
                                    bindThis={this}
                                    bindName='pushTimeStart'
                                    isFormat
                                />
                                <XDatePicker
                                    style={{ marginRight: 20 }}
                                    label=''
                                    value={pushTimeEnd}
                                    bindThis={this}
                                    bindName='pushTimeEnd'
                                    isFormat
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <XOKButton
                        style={{ width: 90 }}
                        label='新建消息'
                        onClick={this._newHandle}
                    />
                    <OrderTable renderData={messageMoveList} tableAction={this._tableAction} paginationChange={this._paginationChange} curTabID={curTabID} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getMessageMoveList: PropTypes.func,
    messageMoveList: PropTypes.object,
    getMessageStatus: PropTypes.func,
    messageStatus: PropTypes.array,
    getMessageClassifyLists: PropTypes.func,
    messageClassifyLists: PropTypes.array,
    getPushPeople: PropTypes.func,
    pushPeople: PropTypes.array,
};
const mapStateToProps = (state) => ({
    messageMoveList: state.MessageMove.messageMoveList,
    messageClassifyLists: state.MessageClassify.messageClassifyLists,
    messageStatus: state.MessageMove.messageStatus,
    pushPeople: state.MessageMove.pushPeople,
});
export default connect(mapStateToProps, { getMessageStatus, addOneBreadcrumbPath, keepSecondNavBreadcrumb, getMessageMoveList, getMessageClassifyLists, getPushPeople })(Main);
