/**
 * 设置结算比例
 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './modalStyle.module.scss';
import http from '@/assets/api/http';
import { Modal, Input, Typography, message, Divider, Tooltip, Icon, InputNumber, Button } from 'antd';
import { XInfoLabel } from '@/components/xqxc_ui';
import BigNumber from 'bignumber.js';
import SetMarketingServiceFee from './SetMarketingServiceFee';
import ChangeConfirm from './ChangeConfirm';

const { TextArea } = Input;
const { Text } = Typography;

const SetPrivilegeRate = ({ closeMode, openModeParam: { id, loginName = '', merchantName = '', merchantTypeValue = '' } = {} }) => {

    const [oldData, setOldData] = useState({});
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState({});
    const isConfirm = useRef(0);

    const [marketingServiceFeeVisible, setMarketingServiceFeeVisible] = useState(false);
    const [changeConfirmVisible, setChangeConfirmVisible] = useState(false);

    useEffect(() => {
        http(`/merchant/settle/info/${id}`, {}, 'POST')
            .then(res => {
                const { goodsPaySettleRate, pointsSettleRate, agentRate, platRate, bonusPoolRate, marketingServiceRate, marketingServiceRateInTotal } = res.result;
                setOldData(res.result);
                setQuery({ goodsPaySettleRate, pointsSettleRate, agentRate, platRate, bonusPoolRate, marketingServiceRate, marketingServiceRateInTotal });
            });
    }, []);

    const onsubmit = () => {
        const { agentRate = 0, platRate = 0, bonusPoolRate = 0, goodsPaySettleRate = 0, marketingServiceRateInTotal = 0 } = query;
        if (!goodsPaySettleRate) return message.warn('商品支付结算比例必须大于0');
        if (new BigNumber(agentRate).plus(new BigNumber(platRate)).plus(new BigNumber(bonusPoolRate)).plus(new BigNumber(marketingServiceRateInTotal)).toNumber() > 100) {
            return message.warn('平台服务费、技术服务费、营销服务费、补贴账户比例之和不超过100%');
        }

        // 二次确认
        if(isConfirm.current == 0){
            if(oldData.goodsPaySettleRate !== query.goodsPaySettleRate || oldData.pointsSettleRate !== query.pointsSettleRate){
                setChangeConfirmVisible(true);
                return false;
            }
        }
        let formData = {
            goodsPaySettleRate: query.goodsPaySettleRate || 0,
            pointsSettleRate:query.pointsSettleRate || 0,
            agentRate:query.agentRate || 0,
            platRate:query.platRate || 0,
            bonusPoolRate:query.bonusPoolRate || 0,
            marketingServiceRate: query.marketingServiceRate || {},
            marketingServiceRateInTotal:query.marketingServiceRateInTotal | 0,
        };

        setLoading(true);
        http('/merchant/settle/save', { merchantId: id, ...formData }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            }).catch((e = {}) => {
                message.error(e.message);
                setLoading(false);
            });
    };

    const onMarketingServiceSetOk = (data)=>{
        setQuery({...query, marketingServiceRate: {...data}});
        setMarketingServiceFeeVisible(false);
    };
    
    const onChangeConfirmOk = (data)=>{
        isConfirm.current = 1;
        setChangeConfirmVisible(false);
        onsubmit();
    };

    return <Modal width={660} centered title='设置结算比例' visible={true} confirmLoading={loading} onOk={onsubmit}
        onCancel={() => closeMode()} destroyOnClose={true} keyboard={true} maskClosable={false} cancelText='取消' okText='确认'
    >
        <div className={styles.container}>
            <div className={styles.row}>
                <XInfoLabel style={{ marginRight: '20px' }} label='商户账号：' value={loginName} />
                <XInfoLabel style={{ marginRight: '20px' }} label='商户名称：' value={merchantName} />
                <XInfoLabel label='商户类型：' value={merchantTypeValue} />
            </div>
            <Divider />
            <div className={styles.itemBox}>
                <Text>设置结算比例</Text><Text type='secondary'>（用户通过平台购买商品后，结算给商家的货款比例）</Text>
                <Tooltip placement='topRight'
                    title='1）设置后当前商户所有在平台销售的商品，按商品实付金额*商品支付结算比例=商品货款结算价（手续费由平台出）2）商品实付金额-商品货款结算价=可分配的服务费'>
                    <Icon style={{ fontSize: '16px' }} type='question-circle' theme='filled' />
                </Tooltip>
            </div>
            <div className={styles.itemBox}>
                <Text>商品支付结算比例（%）</Text>
                <InputNumber style={{ width: '240px' }} min={0} max={100} precision={2}
                    value={query.goodsPaySettleRate} placeholder='请输入' onChange={v => setQuery({ ...query, goodsPaySettleRate: v })} />
            </div>
            <div className={styles.itemBox}><Text type='danger'>注：请和商户商议，根据合约执行。如和商户约定8:2结算，则设置为80%</Text></div>
            <div className={styles.itemBox}>
                <Text>设置积分结算比例</Text><Text type='secondary'>（根据商户出的服务费，按下列的比例进行给商户结算积分）</Text>
                <Tooltip placement='topRight' title='可分配的服务费*积分比例=商户积分积分支持小数点后2位，如超出向下取整'>
                    <Icon style={{ fontSize: '16px' }} type='question-circle' theme='filled' />
                </Tooltip>
            </div>
            <div className={styles.itemBox}>
                <Text>每提供1元，获得</Text>
                <InputNumber style={{ width: '240px', margin: '0 10px' }} min={0} max={100} precision={2}
                    value={query.pointsSettleRate} placeholder='请输入' onChange={v => setQuery({ ...query, pointsSettleRate: v })} />
                <Text>个积分</Text>
            </div>
            <div className={styles.itemBox}><Text type='danger'>注：根据商户出的服务费，按设置的比例给商户发放积分</Text></div>
            <div className={styles.itemBox}>
                <Text>设置分润比例</Text><Text type='secondary'>（商品实付金额-商品货款结算价=可分配的服务费）</Text>
            </div>
            <div className={styles.itemBox}>
                <Text>平台服务费比例（%）</Text>
                <InputNumber style={{ width: '240px' }} min={0} max={100} precision={2}
                    value={query.agentRate} placeholder='请输入' onChange={v => setQuery({ ...query, agentRate: v })} />
            </div>
            <div className={styles.itemBox}>
                <Text>技术服务费比例（%）</Text>
                <InputNumber style={{ width: '240px' }} min={0} max={100} precision={2}
                    value={query.platRate} placeholder='请输入' onChange={v => setQuery({ ...query, platRate: v })} />
            </div>
            <div className={styles.itemBox}>
                <div>
                    <Text>营销服务费比例（%）</Text>
                    <InputNumber style={{ width: '240px' }} min={0} max={100} precision={2}
                        value={query.marketingServiceRateInTotal} placeholder='请输入' onChange={v => setQuery({ ...query, marketingServiceRateInTotal: v})} />
                    <Button type="link" onClick={()=>{setMarketingServiceFeeVisible(true);}}>设置</Button>
                </div>
                <div className={styles.tips}>营销服务费=可分配的服务费*营销服务费比例</div>
            </div>
            <div className={styles.itemBox}>
                <Text>补贴账户比例（%）</Text>
                <InputNumber style={{ width: '240px' }} min={0} max={100} precision={2}
                    value={query.bonusPoolRate} placeholder='请输入' onChange={v => setQuery({ ...query, bonusPoolRate: v })} />
            </div>
            <div className={styles.tipBox}><Text type='danger'>注：设置各项比例相加不能大于100%，设置不满100%部分归平台获得</Text></div>

            {marketingServiceFeeVisible && <SetMarketingServiceFee data={query} onOk={onMarketingServiceSetOk} onClose={()=>{setMarketingServiceFeeVisible(false);}} />}
            {changeConfirmVisible && <ChangeConfirm data={{...query, loginName, merchantName}} onOk={onChangeConfirmOk} onClose={()=>{setChangeConfirmVisible(false);}} />}
        </div>
    </Modal>;
};

SetPrivilegeRate.propTypes = {
    closeMode: PropTypes.func.isRequired,
    openModeParam: PropTypes.object
};

export default SetPrivilegeRate;