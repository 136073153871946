import * as T from './actiontypes';

// 魅力等级设置列表
export const getCharmGradeList = ({ pageNum, pageSize }) => ({
    type: T.GET_CHARMGRADE_LIST,
    payload: {
        levelType: 'CHARM_LEVEL', pageNum, pageSize
    }
});

// 魅力等级设置详情
export const getCharmGradeDetail = (id) => ({
    type: T.GET_CHARMGRADE_DETAIL,
    payload: { id }
});

// 魅力等级设置优惠券下拉
export const getCharmGradeCouponSele = (couponName) => ({
    type: T.GET_CHARMGRADE_COUPONSELE,
    payload: {
        couponName
    }
});

// 魅力等级设置单选框
export const getCharmGradeRadio = () => ({
    type: T.GET_CHARMGRADE_RADIO,
    payload: {}
});