/**
 * 营销服务费
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { XOKButton, XCancelButton, XInputNum, showConfirm, XInput } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import model from '@/reducers/systemmanage/merchantpermission/model';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { regFenToYuan, regYuanToFen } from '@/util/money';
import ChooseModal from './modal/ChooseModal';
import GiveXiudouModal from './modal/GiveXiudouModal';

class SaleServiceFee extends React.Component {

    state = {
        starValueRatio: '',
        selectedRowKeys: [], // 批量赠送信用值选中的商户
        currentOpenMode: '', // choose选择商户、
        openModeParam: {}, // 打开弹窗传递的参数
        merchantName: '', // 商户名称
        pageNum: 1,
        pageSize: 20,
    }

    componentDidMount() {
        const { pageSize, pageNum } = this.state;
        this.props.queryMarketExchangeStarValueRatio();
        this.props.queryMarketList({ pageNum, pageSize });
    }

    UNSAFE_componentWillReceiveProps(props) {
        // 初始化营销费用兑换信用值比例
        if (props.starValueRatio && this.state.starValueRatio == '') {
            this.setState({ starValueRatio: regFenToYuan(props.starValueRatio) });
        }
    }

    // 兑换信用值比例设置保存
    _exchange = () => {
        const { starValueRatio } = this.state;
        if (!starValueRatio) {
            message.warning('兑换比例不可为空');
            return;
        }
        http('/paymentParam/updateMarketExchangeStarValueRatio', { paramValue: regYuanToFen(starValueRatio) }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('保存成功');
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 选择商户
    _chooseMerchant = () => {
        this.setState({ currentOpenMode: 'choose' });
    }

    // 批量赠送信用值  
    _giveXiudou = () => {
        const { selectedRowKeys } = this.state;
        if (selectedRowKeys.length == 0) {
            message.warning('请选择商户');
        } else {
            this.setState({ currentOpenMode: 'give', openModeParam: { merchantIds: selectedRowKeys } });
        }
    }

    // 表格操作
    _tableAction = (type, item) => {
        const { id, isMarketCode, marketingRate, merchantId, merchantNo } = item;
        const { pageNum, pageSize } = this.state;
        switch (type) {
            case 'delete':
                this._confirmRequest('/marketing/delete', { id }, '删除成功。', '您是否要删除该商户？');
                break;
            case 'give':
                this.setState({ currentOpenMode: 'give', openModeParam: { merchantIds: [item.merchantId] } });
                break;
            case 'isable':
                this._confirmRequest('/marketing/saveUpdate', { marketingUpdateReqList: [{ id, isMarketing: isMarketCode == 0 ? 1 : 0, marketingRate, merchantId, merchantNo }] }, isMarketCode == 0 ? '启用成功' : '禁用成功', isMarketCode == 0 ? '是否启用该商户' : '是否禁用该商户');
                break;
            case 'rate':
                http('/marketing/saveUpdate', { marketingUpdateReqList: [{ id, isMarketing: isMarketCode, marketingRate, merchantId, merchantNo }] }, 'POST').then(() => {
                    message.success('最小营销服务费修改成功');
                    this.props.queryMarketList({ pageNum, pageSize });
                }).catch((error) => {
                    message.error(error.message);
                });
                break;
        }
    }

    _confirmRequest = (url, data, winText, tipText) => {
        const { pageNum, pageSize } = this.state;
        showConfirm(tipText, '', () => {
            http(url, data, 'POST').then(() => {
                message.success(winText);
                this.props.queryMarketList({ pageNum, pageSize });
            }).catch((error) => {
                message.error(error.message);
            });
        });
    }

    // 每页pageSize条，当前第pageNum页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this.props.queryMarketList({ pageNum, pageSize });
        });
    }

    // 渲染弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'choose'://启用
                return <ChooseModal title='选择商户' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'give':
                return <GiveXiudouModal title='赠送信用值' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        const { pageSize, pageNum } = this.state;
        this.setState({ currentOpenMode: '' });
        if (update) {
            this.props.queryMarketList({ pageSize, pageNum });
        }
    }

    // 查询
    _searchHandle = () => {
        const { pageSize, merchantName } = this.state;
        this.props.queryMarketList({ pageNum: 1, pageSize, merchantName });
    }

    // 重置
    _resetHandle = () => {
        this.setState({ merchantName: '', pageNum: 1, pageSize: 20 }, () => {
            this.props.queryMarketList({ pageNum: 1, pageSize: 20 });
        });
    }

    render() {
        const { starValueRatio, selectedRowKeys, merchantName } = this.state;
        const { marketList } = this.props;

        return (
            <div id="autoheight" className={styles.flexBoxContainer}>
                <div className={styles.top_title}>
                    <span>营销服务费执行设置</span>
                </div>
                <div className={styles.row1}>
                    营销费用兑换信用值比例
                    <XInputNum
                        inputStyle={{ width: '100px' }}
                        min={0.01}
                        max={999999}
                        precision={2}
                        value={starValueRatio}
                        bindThis={this}
                        bindName='starValueRatio'
                    />&nbsp;元 = 1信用值
                    <XOKButton onClick={this._exchange} style={{ width: '100px', marginLeft: '30px' }} label='保存' />
                </div>
                <p className={styles.p}>选择商户是否开通营销服务费</p>
                <div className={styles.row2}>
                    <XOKButton onClick={this._chooseMerchant} style={{ width: '100px' }} label='选择商户' />
                    <XOKButton onClick={this._giveXiudou} style={{ width: '140px', marginLeft: '20px', marginRight: '20px' }} label='批量赠送信用值' />
                    |
                    <XInput
                        style={{ marginLeft: '10px' }}
                        inputStyle={{ width: '200px' }}
                        label='商户名称'
                        placeholder='请输入商户名称'
                        value={merchantName}
                        bindThis={this}
                        bindName='merchantName'
                    />
                    <XOKButton onClick={this._searchHandle} style={{ marginLeft: '20px', width: '60px' }} label='查询' />
                    <XCancelButton onClick={this._resetHandle} style={{ marginLeft: '20px', width: '60px' }} label='重置' />
                </div>
                <TableContent
                    renderData={marketList}
                    tableAction={this._tableAction}
                    selectedRowKeys={selectedRowKeys}
                    selectedRowKeysChange={(selectedRowKeys) => { this.setState({ selectedRowKeys }); }}
                    paginationChange={this._paginationChange}
                />
                {this._renderMode()}
            </div>
        );
    }
}

SaleServiceFee.propTypes = {
    history: PropTypes.object,
    queryMarketExchangeStarValueRatio: PropTypes.func,
    starValueRatio: PropTypes.string,
    queryMarketList: PropTypes.func,
    marketList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    starValueRatio: state.merchantpermission.queryMarketExchangeStarValueRatio,
    marketList: state.merchantpermission.queryMarketList
});

export default connect(mapStateToProps, { ...model.actions })(SaleServiceFee);