/**
 *  代言任务  
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

class Advocacytask extends React.Component {

    render() {
        return (
            <Main history={this.props.history} match={this.props.match}/>
        );
    }
}

Advocacytask.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
};



export default Advocacytask;