import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Button, Checkbox, Icon, InputNumber, message, Modal, Radio, Table, Tooltip } from 'antd';
import styles from './index.module.scss';
import { ImgCell, priceFenToYuanCell, tooltip } from '@/components/TableCell';
import { showConfirm } from '@/components/xqxc_ui';
import { BigNumber } from 'bignumber.js';
import MarketModal from './MarketModal';

const radioList = [{ code: 'PLATFORM_SETTLE', value: '平台结算' }, { code: 'PRODUCT_CUSTOM', value: '商品结算' }];
const checkList = [{ code: 'CASH', value: '现金' }, { code: 'POINTS', value: '积分' }];

const SettleSetModal = ({ data: { goodsId } = {}, closeMode }) => {

    const [renderModal, setRenderModal] = useState(false);
    const [modalParams, setModalParams] = useState({});
    const [loading, setLoading] = useState(false);
    const [skuSettingResps, setSkuSettingResps] = useState([]);
    const [rateMode, setRateMode] = useState();
    const [payMode, setPayMode] = useState([]);
    const [keys, setKeys] = useState([]);
    const [rootDataList, setRootDataList] = useState([]);

    useEffect(() => {
        http('/goods/goods/findSkuSetting', { goodsId }, 'POST')
            .then(({ result: { rateMode, payMode, skuSettingResps = [] } = {} } = {}) => {
                const countMarket = (salePrice, settlePrice = 0) => {
                    if (!salePrice) return 0;
                    return new BigNumber(salePrice).multipliedBy(new BigNumber(0.994)).minus(new BigNumber(settlePrice)).toNumber();
                };
                const list = skuSettingResps.map(i => {
                    return { ...i, availableServiceAmount: countMarket(i.salePrice, i.settlePrice) };
                });
                setSkuSettingResps(list); setRateMode(rateMode || 'PRODUCT_CUSTOM');
                setPayMode(payMode); setRootDataList(list);
            });
    }, []);

    const onSubmit = () => {
        if (rateMode === 'PLATFORM_SETTLE' && !keys.length) return message.warn('请勾选需要设置的SKU');
        for (let i of skuSettingResps) {
            if (i.salePrice < i.settlePrice) return message.warn('商品销售价设置异常（要求大于结算价）请重新设置');
        }
        setLoading(true);
        http('/goods/goods/updateSkuSetting', {
            goodsId, payMode, rateMode,
            skuSettingResps: [...skuSettingResps.filter(i => keys.includes(i.skuId)), ...rootDataList.filter(i => !keys.includes(i.skuId))]
        }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    const pointTitle = <div><span>赠送积分</span><Tooltip placement='right'
        title='当购买者使用现金支付时，购买商品支付成功后，可获得的积分数量；注：销售价格-结算价=可设置的积分数量，如需超出赠送请联系技术人员'>
        <Icon type='question-circle' theme='filled' /></Tooltip></div>;

    const operateTitle = <div><span>操作</span><Tooltip placement='right'
        title='同步设置，可以将当前商品设置的营销服务费（积分）/营销服务费（现金）设置的分润比例，按同比例；进行同步给前商品所有的SKU'>
        <Icon type='question-circle' theme='filled' /></Tooltip></div>;

    const onFormChang = ({ skuId, attributeKey, attributeValue, settlePrice = 0 } = {}) => {
        const count = (v) => {
            if (!v) return 0;
            return new BigNumber(v).multipliedBy(new BigNumber(0.994)).minus(new BigNumber(settlePrice)).toNumber();
        };

        const makeObject = ({ serviceChargeRate = {}, serviceChargeAmount = {}, settingMode, ...other } = {}, baseValue) => {
            const { platRate = 0, referrerRate = 0, buyerServicerRate = 0, buyerRegionRate = 0, buyerCenterRate = 0, merchantServicerRate = 0 } = serviceChargeRate;
            const { platAmount = 0, referrerAmount = 0, buyerServicerAmount = 0, buyerRegionAmount = 0, buyerCenterAmount = 0, merchantServicerAmount = 0 } = serviceChargeAmount;

            const countAmount = (r) => {
                if (!r || !baseValue) return 0;
                return new BigNumber(r).multipliedBy(baseValue).toNumber();
            };

            const countRate = (f) => {
                if (!f || !baseValue) return 0;
                return new BigNumber(f).dividedBy(baseValue).toNumber();
            };
            if (settingMode === 'RATE') return {
                ...other, serviceChargeAmount: {
                    platAmount: countAmount(platRate),
                    referrerAmount: countAmount(referrerRate),
                    buyerServicerAmount: countAmount(buyerServicerRate),
                    buyerRegionAmount: countAmount(buyerRegionRate),
                    buyerCenterAmount: countAmount(buyerCenterRate),
                    merchantServicerAmount: countAmount(merchantServicerRate),
                }, serviceChargeRate, settingMode
            };

            if (settingMode === 'AMOUNT') return {
                ...other, serviceChargeRate: {
                    platRate: countRate(platAmount),
                    referrerRate: countRate(referrerAmount),
                    buyerServicerRate: countRate(buyerServicerAmount),
                    buyerRegionRate: countRate(buyerRegionAmount),
                    buyerCenterRate: countRate(buyerCenterAmount),
                    merchantServicerRate: countRate(merchantServicerAmount),
                }, serviceChargeAmount, settingMode
            };
        };
        const list = skuSettingResps.map(i => {
            if (skuId === i.skuId) {
                if (attributeKey === 'salePrice') {
                    return {
                        ...i, [attributeKey]: attributeValue,
                        availableServiceAmount: count(attributeValue),
                        serviceChargeCash: makeObject(i.serviceChargeCash, count(attributeValue)),
                        serviceChargePoints: makeObject(i.serviceChargePoints, count(attributeValue))
                    };
                }
                return { ...i, [attributeKey]: attributeValue };
            }
            return i;
        });
        setSkuSettingResps(list);
    };

    const formCell = ({ skuId, attributeKey, attributeValue, settlePrice } = {}) => {
        return <InputNumber min={0} max={999999.99} precision={2} value={new BigNumber(attributeValue || 0).dividedBy(100).toFixed(2)}
            onChange={v => onFormChang({ skuId, attributeValue: new BigNumber(v || 0).multipliedBy(100).toNumber(), settlePrice, attributeKey })} />;
    };

    const operateCell = (t, r) => {
        const onSyncSet = ({ serviceChargePoints = {}, serviceChargeCash = {}, salePrice, pointsNum, availableServiceAmount } = {}) => {
            showConfirm('您是否同步当前SKU设置到当前商品所有SKU设置一致', '', () => {
                const list = skuSettingResps.map(i => {
                    return { ...i, serviceChargePoints, serviceChargeCash, salePrice, pointsNum, availableServiceAmount };
                });
                setSkuSettingResps(list);
            }, () => { });
        };

        return <div className={styles.action}>
            <Button type='link' onClick={() => onSyncSet(r)}>同步设置</Button>
        </div>;
    };

    const setCostCell = (r = {}) => {
        return <div className={styles.action}>
            <Button type='link' onClick={() => { setRenderModal(true); setModalParams(r); }}>设置</Button>
        </div>;
    };

    const columns = [
        { title: '商品图片', dataIndex: 'imgUrl', key: 'imgUrl', width: 100, align: 'center', render: ImgCell },
        { title: 'SKU编码', dataIndex: 'skuNo', key: 'skuNo', width: 100, align: 'center', render: tooltip },
        { title: '规格属性', dataIndex: 'spec', key: 'spec', width: 100, align: 'center', render: tooltip },
        {
            title: '销售价(元)', dataIndex: 'salePrice', key: 'salePrice', width: 100, align: 'center',
            render: (t, r) => formCell({ skuId: r.skuId, attributeKey: 'salePrice', attributeValue: t, settlePrice: r.settlePrice })
        },
        { title: '结算价(元)', dataIndex: 'settlePrice', key: 'settlePrice', width: 100, align: 'center', render: priceFenToYuanCell },
        { title: '库存', dataIndex: 'invQuantity', width: 100, align: 'center', render: tooltip },
        {
            title: pointTitle, dataIndex: 'pointsNum', key: 'pointsNum', width: 100, align: 'center',
            render: (t, r) => formCell({ skuId: r.skuId, attributeKey: 'pointsNum', attributeValue: t, settlePrice: r.settlePrice })
        },
        {
            title: '营销服务费(积分)', dataIndex: 'serviceChargePoints', key: 'serviceChargePoints', width: 120, align: 'center',
            render: (t, r) => setCostCell({ skuId: r.skuId, totalAmount: r.availableServiceAmount, serviceCharge: r.serviceChargePoints, attributeKey: 'serviceChargePoints' })
        },
        {
            title: '营销服务费(现金)', dataIndex: 'serviceChargeCash', key: 'serviceChargeCash', width: 120, align: 'center',
            render: (t, r) => setCostCell({ skuId: r.skuId, totalAmount: r.availableServiceAmount, serviceCharge: r.serviceChargeCash, attributeKey: 'serviceChargeCash' })
        },
        { title: operateTitle, dataIndex: 'skuId', key: 'skuId', width: 100, align: 'center', render: operateCell }
    ];

    const rowSelection = {
        selectedRowKeys: keys,
        onChange: keys => { setKeys(keys); }
    };

    return <Modal width={1260} centered destroyOnClose={true} keyboard={true} maskClosable={false} visible={true}
        confirmLoading={loading} title='结算设置' onOk={onSubmit} onCancel={closeMode} cancelText='取消' okText='确认'>
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.label}>结算模式：</div>
                <div className={styles.content}>
                    <Radio.Group value={rateMode} onChange={e => setRateMode(e.target.value)}>
                        {radioList.map(i => {
                            return <Radio key={i.code} value={i.code}>{i.value}</Radio>;
                        })}
                    </Radio.Group>
                </div>
                <div className={styles.suffix}>
                    <Tooltip placement='right' title='平台结算：当前商品由平台定价销售（平台和商户进行结算，平台定价）商品结算：当前商品由商户定价销售（走原流程，商户自行处理）'>
                        <Icon type='question-circle' theme='filled' /></Tooltip>
                </div>
            </div>
            {rateMode === 'PLATFORM_SETTLE' && <Fragment>
                <div className={styles.row}>
                    <div className={styles.label}>支付方式：</div>
                    <div className={styles.content}>
                        <Checkbox.Group value={payMode} onChange={list => setPayMode(list)}>
                            {checkList.map(i => {
                                return <Checkbox key={i.code} value={i.code}>{i.value}</Checkbox>;
                            })}
                        </Checkbox.Group>
                    </div>
                    <div className={styles.suffix}>
                        <Tooltip placement='right' title='现金：当设置此方式时，商品支持现金支付积分：当设置此方式时，商品支持积分支付当两种类型都选择，则当前商品都支持'>
                            <Icon type='question-circle' theme='filled' /></Tooltip>
                    </div>
                </div>
                <div className={styles.table}>
                    <Table rowSelection={rowSelection} rowKey={r => r.skuId} columns={columns}
                        dataSource={skuSettingResps} pagination={false} scroll={{ y: 480 }} />
                </div>
            </Fragment>}
            <MarketModal visible={renderModal} operates={{ setRenderModal, setModalParams, onFormChang }} data={modalParams} />
        </div>
    </Modal>;
};
SettleSetModal.propTypes = {
    data: PropTypes.object,
    closeMode: PropTypes.func
};
export default SettleSetModal;