/**
 *  每日说会员设置 
 * */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Radio, Table, message, Modal, } from 'antd';
import http from '@/assets/api/http';
import DeleteModal from '../modal/DeleteModal';
import { takeDataSource, takeColumns } from './components/TableData';
import { getMemberGradeListMlx, getMemberGradeRadioMlx } from '@/reducers/systemmanage/membersgrademlx/actions';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const { confirm } = Modal;

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedRowKeys: [],//表格选择和操作
			currentOpenMode: '', // 当前需要打开的弹窗
			openModeParam: {}, // 当前需要打开的弹窗的入参

			pageSize: 20,//默认
			pageNum: 1,//默认
			growValue: '',
			gradeValue: '',
			charmValue: '',
			gradeValueEnum: []
		};
	}

	componentDidMount() {
		const { pageNum, pageSize } = this.state;
		this.props.getMemberGradeListMlx({ pageNum, pageSize });
		this.props.getMemberGradeRadioMlx();
		http('/enum/UserAddLevelSourceEnum', {}, 'POST').then(res => {
			this.setState({ gradeValueEnum: res.result.slice(0, 1) });
		}).catch(err => message.error(err.message));
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		const { growValue, gradeValue, charmValue } = newProps.radioValue;
		this.setState({ growValue, gradeValue, charmValue });
	}

	// 新建按钮被点击
	_newCreateHandle = () => {
		this.props.addOneBreadcrumbPath({
			title: '会员等级新增',
			path: '/home/systemmanage/membersgrade/dailytalksetting/createInfo/'
		});
		this.props.history.push({
			pathname: '/home/systemmanage/membersgrade/dailytalksetting/createInfo/',
		});
	}
	//成长值依据  
	_growRadio = (val) => {
		this.setState({ growValue: val.target.value });
		this.showConfirm(val, '成长值依据', 'MLX_LEVEL_ADD_SOURCE');
	}

	//等级有效期  
	_gradeRadio = (val) => {
		this.setState({ gradeValue: val.target.value });
		this.showConfirm(val, '等级有效期', 'LEVEL_PERIOD');
	}

	//魅力等级
	_charmRadio = (val) => {
		this.setState({ charmValue: val.target.value });
		this.showConfirm(val, '开启魅力等级设置', 'IS_OPEN_CHARM_LEVEL');
	}

	showConfirm = (value, titleText, code) => {
		confirm({
			title: titleText,
			content: '是否保存当前状态',
			centered: true,
			okText: '确认',
			cancelText: '取消',
			onOk() {
				http('/admin/param/batchUpdateParam', [
					{
						paramCode: code,
						paramValue: value.target.value
					}
				], 'POST').then(() => {
					message.success('修改成功。');
					window.location.reload(true);
				}).catch((error) => {
					message.error(error.message);
				});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	// 表格操作功能回调   //EDIT DISABLE 
	_actionClickHandle = (id, type, record) => {
		switch (type) {
			case 'EDIT'://编辑
				addOneBreadcrumbPath({
					title: '会员等级编辑',
					path: '/home/systemmanage/membersgrade/mlxsetting/editorInfo/'
				});
				this.props.history.push({
					pathname: `${'/home/systemmanage/membersgrade/mlxsetting/editorInfo/'}${id}`,
				});
				break;
			case 'DISABLE'://禁用
				console.log('禁用');
				this.setState({
					currentOpenMode: 'DISABLE',
					openModeParam: record
				});
				break;
			case 'USE'://启用
				console.log('启用');
				this.setState({
					currentOpenMode: 'USE',
				});
				break;
			default: return null;
		}

	}

	// 打开弹窗
	_renderMode = () => {
		switch (this.state.currentOpenMode) {
			case 'DISABLE':
				return <DeleteModal title='禁用' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
			case 'USE':
				return <DeleteModal title='启用' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
			default:
				return null;
		}
	}

	// 关闭弹框
	_closeMode = (update) => {
		if (update) {
			const { pageNum, pageSize } = this.state;
			this.props.getMemberGradeListMlx({ pageNum, pageSize });
			this.setState({ currentOpenMode: '' });
		} else {
			this.setState({ currentOpenMode: '' });
		}
	}

	render() {
		// const { tableSource, paginations, selectData } = this.props;
		const { growValue, gradeValue, charmValue, gradeValueEnum } = this.state;
		const { memberGradeList } = this.props;
		return (
			<div className={styles.flexBoxContainer}>
				<div className={`${styles.tableContainer}`}>
					<div className={styles.tableContainer} style={{ overflow: 'auto' }}>
						<div className={styles.growth}>
							<div className={styles.top_title}>
								<span>成长值依据</span>
							</div>
							<Radio.Group className={`${styles.layout} ${styles.curt}`} value={growValue} onChange={this._growRadio}>
								{
									gradeValueEnum.map(i => {
										return <Radio key={i.code} value={i.code}>{i.value}</Radio>;
									})
								}
							</Radio.Group>
						</div>
						{/* <div className={styles.growth}>
							<div className={styles.top_title}>
								<span>等级有效期</span>
							</div>
							<Radio.Group className={`${styles.layout} ${styles.curt}`} value={gradeValue} onChange={this._gradeRadio}>
								<Radio value='PERPETUAL'>永久有效（等级永久保留）</Radio>
								<Radio value='MONTH'>每个自然月扣除成长值（扣除数值在设置等级中设置）</Radio>
								<Radio value='YEAR'>每个自然年扣除成长值（扣除数值在设置等级中设置）</Radio>
							</Radio.Group>
						</div> */}
						{/* <div className={styles.growth}>
							<div className={styles.top_title}>
								<span>魅力等级依据</span>
							</div>
							<Radio.Group className={`${styles.layout} ${styles.curt}`} value={charmValue} onChange={this._charmRadio}>
								<Radio value='1'>开启魅力等级设置</Radio>
								<Radio value='0'>关闭魅力等级设置</Radio>
							</Radio.Group>
						</div> */}
						<div className={styles.growth}>
							<div className={styles.top_title}>
								<span>会员等级设置</span>
							</div>
							<div className={`${styles.refundtable}`}>
								<div className={styles.flex}>
									<Table
										rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
										rowKey={(record, index) => index}
										columns={takeColumns(this._actionClickHandle)}
										dataSource={takeDataSource(memberGradeList.dataList)}
										pagination={false}
										scroll={{ y: true }}
									/>
								</div>
							</div>
							{/* <p className={styles.creatdash} onClick={this._newCreateHandle}>新增</p> */}
						</div>
						{this._renderMode()}
					</div>
				</div>

			</div>
		);
	}
}
Main.propTypes = {
	addOneBreadcrumbPath: PropTypes.func,
	keepSecondNavBreadcrumb: PropTypes.func,
	updateContent: PropTypes.func, // 路由跳转
	match: PropTypes.object, // 路由match对象
	history: PropTypes.object, // 路由history
	getMemberGradeListMlx: PropTypes.func,
	getMemberGradeRadioMlx: PropTypes.func,

	memberGradeList: PropTypes.object,
	radioValue: PropTypes.object
};

const mapStateToProps = (state) => ({
	memberGradeList: state.memberGradeMlx.memberGradeList,
	radioValue: state.memberGradeMlx.radioValue,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getMemberGradeListMlx, getMemberGradeRadioMlx })(Main);