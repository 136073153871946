import React, { useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Input, InputNumber, message, Modal, Tabs } from 'antd';
import { XInfoLabel } from '@/components/xqxc_ui';
import BigNumber from 'bignumber.js';

const { TabPane } = Tabs;

const SetModal = ({
    tableAction,
    modalParmas: { creditBalanceValue, id, accountName } = {}
}) => {

    const [loading, setLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState('CREDIT');
    const [applyAmount, setApplyAmount] = useState();
    const [reason, setReason] = useState();

    const handleOk = () => {
        if (!reason) return message.warn('请输入申请原因');
        setLoading(true);
        http('/settle/creditLimitReview/createCreditLimitRecords', {
            reason,
            applyAmount: new BigNumber(applyAmount || 0).multipliedBy(100).toNumber(),
            channelId: id,
            channelTel: accountName
        }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                tableAction(true, 'closeModal');
            })
            .catch(err => {
                setLoading(false);
                message.error(err.message);
            });
    };

    return <Modal
        width={420}
        centered
        title='渠道设置'
        bodyStyle={{ padding: '10px 20px' }}
        visible={true}
        confirmLoading={loading}
        onOk={handleOk}
        onCancel={() => tableAction(false, 'closeModal')}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确定'
    >
        <div className={styles.modalContainer}>
            <Tabs activeKey={currentTab} onChange={v => setCurrentTab(v)}>
                <TabPane tab='信用额度' key='CREDIT'>
                    <div className={styles.setModal}>
                        <XInfoLabel label='当前信用额度：' value={`${new BigNumber(creditBalanceValue || 0).dividedBy(100).toFixed(2)}元`} />
                        <div className={styles.setModalItem}>
                            <div className={styles.setModalItemLabel}>申请信用额度：</div>
                            <InputNumber
                                style={{ width: '250px', marginRight: '5px' }}
                                placeholder='请设置额度'
                                precision={0}
                                min={0}
                                max={999999}
                                value={applyAmount}
                                onChange={v => setApplyAmount(v)}
                            />
                            <div className={styles.setModalItemLabel}>元</div>
                        </div>
                        <div className={styles.setModalItem}>
                            <div className={styles.setModalItemLabel}>申请原因：</div>
                            <Input.TextArea
                                style={{ width: '250px' }}
                                placeholder='请输入'
                                value={reason}
                                onChange={({ target: { value } = {} }) => setReason(value)}
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                maxLength={100}
                            />
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    </Modal>;
};
SetModal.propTypes = {
    tableAction: PropTypes.func,
    modalParmas: PropTypes.object
};
export default SetModal;