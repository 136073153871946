import * as T from './actiontypes';
/**
 * 代言任务数据源
 */
const defaultState = {
    tableSource: [],//分类列表数据源
    selectData: {},// 状态下拉选项
    paginations: {}, //分页对象
    advocacyDetails: {},//代言任务编辑、详情

    RaiseAll: [],//代言任务新建--全选复选
    RaiseSelect: [],//代言任务新建-选择商品下拉框
    RaiseTable: [],//代言任务新建-选择商品表格数据
    RaisePagination: {},//代言任务新建-选择商品表格数据分页
    Reward: [],//代言任务新建-奖励类型
    Coupon: [],//代言任务新建-优惠劵名称
    execution: [],//任务执行时间
};

/**
 * @param {*} state 
 * @param {*} action 
 */
export const advocacytaskDate = (state = defaultState, action) => {
    let newTableSource, newSelectData, newPaginations, newDetail, RaiseAll,
        RaiseSelect, RaiseTable, newRaisePaginations, Reward, Coupon, execution;
    switch (action.type) {
        case T.SET_ADVOCACYLIST_DATA: //列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_ADVOCACYDETAILS_DATA: //代言任务编辑、详情获取
            newDetail = action.payload.result;
            return { ...state, advocacyDetails: newDetail, };
        case T.SET_DROPDIWN_DATA: //代言任务下拉框
            newSelectData = action.payload;
            return { ...state, selectData: newSelectData, };

        case T.SET_RAISEDATA_DATA: //代言任务新建-选择商品下拉框
            RaiseSelect = action.payload.result.merchantIdAndNames;
            return { ...state, RaiseSelect, };
        case T.SET_ALL_DATA: //代言任务新建--全选复选
            RaiseAll = action.payload.result;
            return { ...state, RaiseAll, };
        case T.SET_REWARD_DATA: //代言任务新建-奖励类型
            Reward = action.payload.result;
            return { ...state, Reward, };
        case T.SET_EXECUTION_DATA: //代言任务新建-任务执行时间
            execution = action.payload.result;
            return { ...state, execution, };
        case T.SET_COUPONNAME_DATA: //代言任务新建-优惠劵名称
            Coupon = action.payload.result;
            return { ...state, Coupon, };
        case T.SET_RAISETABALE_DATA: //代言任务新建-选择商品表格数据
            RaiseTable = action.payload.result.dataList;
            newRaisePaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, RaiseTable, RaisePagination: newRaisePaginations };
        default:
            return state;
    }
};