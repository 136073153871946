import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { Modal, message, Typography, Table, InputNumber } from 'antd';
import model from '@/reducers/accruedService/serviceList/model';
import BigNumber from 'bignumber.js';

const { Text } = Typography;

class FeeRateSet extends Component {

    static propTypes = {
        data: PropTypes.object,
        closeMode: PropTypes.func,
        getFeeRateSetList: PropTypes.func,
        feeRateSetList: PropTypes.object,
        setFeeRate: PropTypes.func
    }

    state = {
        loading: false,
    }

    componentDidMount() {
        const { getFeeRateSetList, data: { earningsServiceId = '' } = {} } = this.props;
        getFeeRateSetList({ earningsServiceId });
    }

    //合并行
    _renderType = (index, type) => {
        if (type === 'SERVICE_APP') {
            let serviceApp = {
                children: '服务商推广',
                props: { rowSpan: 0 },
            };
            if (index === 0) serviceApp.props.rowSpan = 3;
            return serviceApp;
        } else if (type === 'TALKER') {
            let talker = {
                children: '每日说客推广',
                props: { rowSpan: 0 },
            };
            if (index === 0) talker.props.rowSpan = 3;
            return talker;
        } else if (type === 'USER') {
            let user = {
                children: '每日特卖推广',
                props: { rowSpan: 0 },
            };
            if (index === 0) user.props.rowSpan = 3;
            return user;
        } else if (type === 'NATURE') {
            let nature = {
                children: '自然流量',
                props: { rowSpan: 0 },
            };
            if (index === 0) nature.props.rowSpan = 2;
            return nature;
        } else if (type === 'SALES_MAN') {
            let salesman = {
                children: '智仓优选推广',
                props: { rowSpan: 0 },
            };
            if (index === 0) salesman.props.rowSpan = 6;
            return salesman;
        } else if (type === 'REGION_COMPANY') {
            let salesman = {
                children: '区域公司推广',
                props: { rowSpan: 0 },
            };
            if (index === 0) salesman.props.rowSpan = 2;
            return salesman;
        }
    }

    //设置费用
    _renderRate = (item) => {
        return <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <InputNumber
                key={item.id}
                value={item.earningsRatio}
                precision={1}
                placeholder='请输入'
                min={0}
                max={100}
                onChange={value => this.props.setFeeRate({ id: item.id, earningsRatio: value })}
            />
        </div>;
    }

    _renderCell = (text, index, type, row) => {
        if (type === 'SALES_MAN' && index === 3) {
            let salesman = {
                children: <Typography.Text type='danger'>{text}</Typography.Text>,
                props: { colSpan: 0 },
            };
            if (row) salesman.props.colSpan = 3;
            return salesman;
        }
        if (row) return this._renderRate(row);
        return text;
    }

    //表头
    _columns = (type) => {
        return [
            {
                title: '业务类型',
                dataIndex: 'recommendTypeName',
                key: 'recommendTypeName',
                align: 'center',
                width: 180,
                render: (value, row, index) => this._renderType(index, type)
            },
            {
                title: '收益对象',
                dataIndex: 'earningsObject',
                key: 'earningsObject',
                align: 'center',
                width: 180,
                render: (value, row, index) => this._renderCell(value, index, type)
            },
            {
                title: '费用名称',
                dataIndex: 'costName',
                key: 'costName',
                align: 'center',
                width: 180,
                render: (value, row, index) => this._renderCell(value, index, type)
            },
            {
                title: '比率(%)',
                dataIndex: 'earningsRatioValue',
                key: 'earningsRatioValue',
                align: 'center',
                width: 180,
                render: (value, row, index) => this._renderCell(value, index, type, row)
            }
        ];
    }

    //提交
    _saveData = () => {
        const { feeRateSetList: { earningsServiceItemList = [] } = {}, data: { earningsServiceId = '' } = {} } = this.props;
        let addUp1 = 0;
        let addUp2 = 0;
        let addUp3 = 0;
        let addUp4 = 0;
        let addUp5 = 0;
        let service = 0;
        let talker = 0;
        earningsServiceItemList.forEach(element => {
            if (element.recommendType === 'SERVICE_APP') {
                addUp1 = new BigNumber(addUp1 || 0).plus(element.earningsRatio || 0).toNumber();
            } else if (element.recommendType === 'TALKER') {
                addUp2 = new BigNumber(addUp2 || 0).plus(element.earningsRatio || 0).toNumber();
            } else if (element.recommendType === 'USER') {
                addUp3 = new BigNumber(addUp3 || 0).plus(element.earningsRatio || 0).toNumber();
            } else if (element.recommendType === 'NATURE') {
                addUp4 = new BigNumber(addUp4 || 0).plus(element.earningsRatio || 0).toNumber();
            } else if (element.recommendType === 'SALES_MAN') {
                if (element.earningsCode === 'SERVICE_PROVIDER') service = element.earningsRatio;
                if (element.earningsCode === 'TALKER') talker = element.earningsRatio;
                if (element.earningsCode !== 'SERVICE_PROVIDER' && element.earningsCode !== 'TALKER') {
                    addUp5 = new BigNumber(addUp5 || 0).plus(element.earningsRatio || 0).toNumber();
                }
            }
        });
        if (addUp1 > 100) return message.warn('服务商推广比例之和不能超过100%');
        if (addUp2 > 100) return message.warn('每日说客推广比例之和不能超过100%');
        if (addUp3 > 100) return message.warn('每日特卖推广比例之和不能超过100%');
        if (addUp4 > 100) return message.warn('自然流量比例之和不能超过100%');
        if (new BigNumber(addUp5 || 0).plus(service).toNumber() > 100 ||
            new BigNumber(addUp5 || 0).plus(talker).toNumber() > 100) return message.warn('智仓优选推广比例之和不能超过100%');
        this.setState({ loading: true }, () => {
            const updateEarningsServiceItemReqs = earningsServiceItemList.map(item => {
                const earningsRatio = new BigNumber(item.earningsRatio).multipliedBy(10).toNumber();
                return { earningsRatio, id: item.id };
            });
            http('/earningsServiceItem/batchUpdate', { earningsServiceId, updateEarningsServiceItemReqs }, 'POST')
                .then(() => {
                    message.success('费用比例设置成功。');
                    this.setState({ loading: false });
                    this.props.closeMode(true);
                })
                .catch(err => {
                    this.setState({ loading: false });
                    message.error(err.message);
                });
        });
    }

    render() {
        const { closeMode, feeRateSetList: { earningsServiceItemList = [], regionCompanyRemark } = {}, data: { serviceName = '' } = {} } = this.props;
        const { loading } = this.state;

        return <Modal
            width={780}
            bodyStyle={{ paddingRight: '10px' }}
            centered
            title='费用比例设置'
            visible={true}
            confirmLoading={loading}
            onOk={this._saveData}
            onCancel={() => closeMode()}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <div style={{ height: '600px', overflowY: 'auto' }}>
                <div><Text>服务名称：{serviceName}</Text></div>
                <div>
                    <Text type='secondary'>说明：服务商、每日说客、每日特卖推广、自然流量、销售员服务系统收益；收益金额=服务商品销售价*比率（%）、区域公司推广</Text>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <Table
                        rowKey={(record) => record.id}
                        columns={this._columns('SERVICE_APP')}
                        dataSource={earningsServiceItemList.slice(0, 3)}
                        pagination={false}
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <Table
                        showHeader={false}
                        rowKey={(record) => record.id}
                        columns={this._columns('TALKER')}
                        dataSource={earningsServiceItemList.slice(3, 6)}
                        pagination={false}
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <Table
                        showHeader={false}
                        rowKey={(record) => record.id}
                        columns={this._columns('USER')}
                        dataSource={earningsServiceItemList.slice(6, 9)}
                        pagination={false}
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <Table
                        showHeader={false}
                        rowKey={(record) => record.id}
                        columns={this._columns('NATURE')}
                        dataSource={earningsServiceItemList.slice(9, 11)}
                        pagination={false}
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <Table
                        showHeader={false}
                        rowKey={(record) => record.id}
                        columns={this._columns('SALES_MAN')}
                        dataSource={[...earningsServiceItemList.slice(11, 14),
                        { earningsRatioValue: '（服务商与说客，同时设置时取最大比例，与其它项相加不能超过总比例的100%）', id: 'user-defined' },
                        ...earningsServiceItemList.slice(14, 16)]}
                        pagination={false}
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <Table
                        showHeader={false}
                        rowKey={(record) => record.id}
                        columns={this._columns('REGION_COMPANY')}
                        dataSource={earningsServiceItemList.slice(16, 18)}
                        pagination={false}
                        footer={() => <div style={{ textAlign: 'right', color: '#f5222d' }}>{regionCompanyRemark}</div>}
                    />
                </div>
            </div>
        </Modal>;
    }
}
export default connect(state => ({
    feeRateSetList: state.serviceAccrued.feeRateSetList,
}), { ...model.actions })(FeeRateSet);