/**
 * 【商品举报】 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

/**
 * 列表 Saga
 */
export const listproductReportSource = function* () {
    yield takeEvery(T.GET_PRODUCTLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/goods/goodsReport/listGoodsReport', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCTLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 商品举报详情 Saga
 */
export const productReportDetails = function* () {
    yield takeEvery(T.GET_PRODUCTDETAIL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http(`/goods/goodsReport/getGoodsReportInfo/${action.payload.id}`, action.payload, 'POST');
            yield put({ type: T.SET_PRODUCTDETAIL_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};