import React from 'react';
import styles from './BindUserTable.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';

const columns = () => [
    {
        title: '会员账号',
        dataIndex: 'tel',
        key: 'tel',
        width: '25%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '姓名',
        dataIndex: 'userName',
        key: 'userName ',
        // width: '25%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '会员类型',
        dataIndex: 'levelName',
        key: 'levelName',
        width: '25%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '所在区域',
        dataIndex: 'areaName',
        key: 'areaName',
        align: 'center',
        width: '25%',
        render: (text) => (TableCell(text))
    }
];

// 表格项内容为文本
const TableCell = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text}
        </div>
    );
};

class BindUserTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        console.log(data);
        return data.dataList;
    }

    componentDidMount() {
        console.log(this.props.renderData);
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    // 表格复选框操作回调
    _rowSelection = {
        type: 'radio',
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRowKeys,selectedRows);
            this._actionHandle(selectedRowKeys,'username',selectedRows);
            this.setState({ openModeParam: selectedRows });
        }
    };

    render() {
        const { renderData, paginationChange } = this.props;

        return (
            <div className={styles.container}>
                <div className={styles.table_box}>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        rowSelection={this._rowSelection}
                        rowKey='accountId'
                        columns={columns()}
                        dataSource={this._takeDataSource(renderData)}
                        pagination={false}
                        scroll={{ y: 350 }}
                    />
                </div>
                <div className={styles.pagination_box}>
                    <XPagination
                        resultTotal={renderData.resultTotal}
                        pageSize={renderData.pageSize}
                        pageNum={renderData.pageNum}
                        onChange={paginationChange} />
                </div>
            </div>
        );
    }
}

BindUserTable.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

export default BindUserTable;