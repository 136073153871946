/**
 * 删除提示
 */
import React from 'react';
import { Modal, message, Alert } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';

class DeleteHint extends React.Component {

	state = {
		visible: this.props.visible,
        confirmLoading: false,
	}

	//发送请求
	_sendReq = (url, data, winTxt, fallTxt) => {
		http(url, data, 'POST').then(() => {
			message.success(winTxt);
			this.setState({
				visible: false,
			});
			this.props.closeMode(true);
		}).catch(() => {
			message.error(fallTxt);
			this.setState({
				visible: false,
			});
			this.props.closeMode(false);
		});
	}
	// 点击确认
	_handleOk = () => {
		this.setState({
			visible: false,
		});
		this.props.closeMode(true);
	}
	// 点击取消
	_handleCancel = () => {
		this.setState({
			visible: false,
		});
		this.props.closeMode(false);
	}

	render() {
		const { visible, confirmLoading } = this.state;
		return (
			<Modal
				width={500}
				centered
				visible={visible}
				confirmLoading={confirmLoading}
				onOk={this._handleOk}
				onCancel={this._handleCancel}
				destroyOnClose={true}
				keyboard={true}
				maskClosable={false}
				cancelText='取消'
				okText='确认'>
				<Alert
					message="确定要启用/禁用此任务吗？"
					description="你还要继续吗？"
					type="warning"
					showIcon
				/>
			</Modal>
		);
	}
}

DeleteHint.propTypes = {
	visible: PropTypes.bool.isRequired, // 是否显示弹框
	closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default DeleteHint;