/**
 * 处理结果
 */
import React from 'react';
import { Modal, Input, message } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import styles from './ResultModal.module.scss';
import { getProcessDetail } from '@/reducers/salemanage/salelist/actions';


const { TextArea } = Input;

class ResultModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      visible: this.props.visible,
      data: this.props.data,
      nodeContent: '',
    };
  }

  componentDidMount() {
    const { data: { id } } = this.state;
    this.props.getProcessDetail({ orderSaleId: id });
  }
  _handleOk = () => {
    this.setState({
      visible: false,
    });
    const { nodeContent, data: { id, sellerId } } = this.state;
    const param = {
      salesStatus: 'JUDGE_DEARWITH',
      nodeContent,
      orderSaleId: id,
      sellerId
    };
    http('/boss/order/giveAdvice', param, 'POST')
      .then(() => {
        message.success('处理成功!');
        this.props.refresh();
      }).catch((reject) => {
        message.error(reject.message);
      });
    this.props.closeMode();
  }

  _handleCancel = () => {
    this.setState({
      visible: false,
    });
    this.props.closeMode();
  }

  _radioValueChangeHandle = (e) => {
    this.setState({ radioValue: e.target.value });
  }
  _renderImages = (attachList) => {
    return (
      attachList.map((item, index) => {
        return (
          <img key={index} src={item} alt='凭证图片' />
        );

      })
    );
  }
  _renderNOaccept = () => {
    const { nodeContent } = this.state;
    return (
      <TextArea
        className={styles.textArea}
        placeholder='请输入不受理原因'
        rows={4}
        onChange={this._areaChangeHandle}
        value={nodeContent}
      />
    );
  }
  _areaChangeHandle = (e) => {
    this.setState({ nodeContent: e.target.value });
  }

  render() {
    const { visible, confirmLoading, title } = this.state;
    const { processDetail, processDetail: { attachList = [] } } = this.props;
    return (
      <Modal
        width={470}
        centered
        title={title}
        visible={visible}
        confirmLoading={confirmLoading}
        onOk={this._handleOk}
        onCancel={this._handleCancel}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
      >
        <div className={styles.container}>
          <div className={styles.top}>
            <span>申请原因</span>
            <p>{processDetail.salesReason}</p>
          </div>
          <div className={styles.top}>
            <span>问题描述</span>
            <p>{processDetail.salesExplain}</p>
          </div>
          <div className={styles.items}>
            <p>凭证图片</p>
            <div className={styles.imgs}>
              {this._renderImages(attachList)}
            </div>
          </div>
          <div className={styles.result}>
            <span>受理结果</span>
            <p>{this._renderNOaccept()}</p>
          </div>
        </div>
      </Modal>
    );
  }
}

ResultModal.propTypes = {
  data: PropTypes.object, // 弹框入参
  visible: PropTypes.bool.isRequired, // 是否显示弹框
  closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
  title: PropTypes.string, // 弹框标题
  getProcessDetail: PropTypes.func,
  refresh: PropTypes.func,
  processDetail: PropTypes.object,
};
const mapStateToProps = (state) => {
  return {
    processDetail: state.SaleList.processDetail,
  };
};
export default connect(mapStateToProps, { getProcessDetail })(ResultModal);