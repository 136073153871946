/**
 * 【物流管理页】
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { message } from 'antd';
import LogisticsTable from './components/LogisticsTable';
import model from '@/reducers/systemmanage/logistics/model';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';
import http from '@/assets/api/http';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expressName: '',
            expressState: {},
            selectedRows: [],
        };
        this.searchCache = { pageNum: 1, pageSize: 25 }; // 查询缓存
    }

    componentDidMount() {
        this.props.getRunningErrandsList(this.searchCache);
    }

    // 表格操作功能回调
    _tableAction = (key, type, record) => {
        switch (type) {
            case 'openService':
                http('/delivery/updateState', { id: key, state: 1 }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('开通成功');
                        this.props.getRunningErrandsList(this.searchCache);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
            case 'closeService':
                http('/delivery/updateState', { id: key, state: 0 }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('停止成功');
                        this.props.getRunningErrandsList(this.searchCache);
                    }
                }).catch((e) => message.error(e.message));
                break;
            case 'detail':
                if (record.name === '达达') {
                    this.props.addOneBreadcrumbPath({
                        title: '配送明细',
                        path: `/home/systemmanage/logistics/runningerrandsdetail/${key}`
                    });
                    this.props.history.push({
                        pathname: `/home/systemmanage/logistics/runningerrandsdetail/${key}`
                    });
                }
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getRunningErrandsList(this.searchCache);
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' });
        if (update) {
            this.props.getRunningErrandsList(this.searchCache);
        }
    }

    // 批量复选框
    _rowSelection = (selectedRows) => {
        this.setState({ selectedRows });
    }

    // 批量停止服务
    _stopServiceHandle = () => {
        const { selectedRows } = this.state;
        if (selectedRows.length == 0) {
            message.error('请选择物流公司');
            return;
        }
        http('/fastpass/update/batchState', { expressIdList: selectedRows, expressState: 0 }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('批量停止成功');
                this.props.getRunningErrandsList(this.searchCache);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    render() {
        const { runningErrandsList } = this.props;

        return (
            <div className={styles.flexBoxContainer}>
                <LogisticsTable
                    renderData={runningErrandsList}
                    tableAction={this._tableAction}
                    paginationChange={this._paginationChange}
                    onRowSelection={this._rowSelection}
                />
            </div>
        );
    }
}
Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    getLogisticsState: PropTypes.func,
    getRunningErrandsList: PropTypes.func,
    logisticsState: PropTypes.array,
    runningErrandsList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    logisticsState: state.logistics.getLogisticsState,
    runningErrandsList: state.logistics.runningErrandsList
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, ...model.actions })(Main);