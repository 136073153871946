/**
 * 直播间列表
 */
import React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { setSessionStorage } from '@/assets/js/storage';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getListLiveData, getListLiveStatus, changeIsHomeRecoAction, changeIsChannelRecoAction, getAnchorTypeEnum } from '@/reducers/showmanage/channellist/actions';
import model from '@/reducers/knowledgepay/channellist/model';
import { SearchBox, SearchItem } from '@/components/Layout';
import OnlinePlayModal from './modal/OnlinePlayModal';
import Virtual from './modal/Virtual';
import CreateLive from './modal/CreateLive';
import PreviewLive from './modal/PreviewLive';

class StudioRoomList extends React.Component {

    state = {
        currentOpenMode: '',
        modeParams: {},
        tel: '', // 手机号码
        nickname: '', // 首页推荐
        liveStatus: {}, // 直播间状态
        viewerNumSort: '', // 实时人数排序
        totalSalesSort: '', // 总销售额排序
        liveTitle: '', // 直播标题 
        userType: {}, // 主播类型
        violationType: {}, // 违规状态
    }

    componentDidMount() {
        const { getAnchorTypeEnum, getListLiveStatus, getViolationType } = this.props;
        getListLiveStatus();
        getAnchorTypeEnum();
        getViolationType(); // 违规状态
    }

    // 直播间历史记录
    _studioRoomHistory = () => {
        const { updateContent } = this.props;
        updateContent('CHANNEL_LIST_HISTORY');
    }

    // 推荐按钮被点击
    _recommendHandle = () => {
        const { updateContent } = this.props;
        updateContent('CHANNEL_LIST_RECOMMEND', 'live');
    }

    // 发起直播
    _createLive = () => {
        this.setState({ currentOpenMode: 'createLive', modeParams: {} });
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, liveStatus, nickname, tel, viewerNumSort, totalSalesSort, liveTitle, userType, violationType } = this.state;
        const searchCache = {
            liveState: liveStatus.code, nickname, tel, viewerNumSort, totalSalesSort,
            liveTitle, anchorType: userType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
            isDetectRead: violationType.code,//违规状态
        };
        this.props.getListLiveData(searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: searchCache.pageNum, pageSize: searchCache.pageSize });
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ tel: '', nickname: '', liveStatus: {}, liveTitle: '', userType: {}, violationType: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    //发送请求
    _sendReq = (url, data, winTxt, cbAction) => {
        http(url, data, 'POST').then(() => {
            message.success(winTxt);
        }).catch((e) => {
            message.error(e.message);
            cbAction && cbAction();
        });
    }

    // 表格操作
    _tableAction = (id, activeType, value) => {
        const liveTopInfo = {
            tel: value.tel,
            nickname: value.nickname,
            broadcasTitle: value.broadcasTitle,
            broadcasClass: value.broadcasClass
        };
        const { updateContent } = this.props;
        let param = {};

        switch (activeType) {
            case 'able': // 播放
                if (value.liveState != '直播中' && value.playbackUrl == '') {
                    message.error('视频播放路径为空');
                } else {
                    this.setState({ currentOpenMode: 'able', modeParams: value });
                }
                break;
            case 'unable': // 禁播
                this.props.updateContent('CHANNEL_LIST_UNABLE', { 'accountId': value.accountId });
                break;
            case 'switch_home':
                param = { id: value.id, isHomeReco: value.isHomeReco == 0 ? 1 : 0 };
                if (value.isHomeReco == 0) {
                    this.props.changeIsHomeRecoAction(param);
                    this._sendReq('/show/live/recoAnchor', { accountId: value.accountId, recoType: 'HOME' }, '首页推荐成功。');
                } else {
                    this.props.changeIsHomeRecoAction(param);
                    this._sendReq('/show/live/unRecoAnchor', { accountId: value.accountId, recoType: 'HOME' }, '取消首页推荐成功。', () => this.props.changeIsHomeRecoAction(param));
                }
                break;
            case 'switch_channel': // 首页推荐
                param = { id: value.id, isChannelReco: value.isChannelReco == 0 ? 1 : 0 };
                if (value.isChannelReco == 0) {
                    this.props.changeIsChannelRecoAction(param);
                    this._sendReq('/show/live/recoAnchor', { accountId: value.accountId, broadcasClassId: value.broadcasClassId, recoType: 'CHANNEL' }, '频道推荐成功。');
                } else {
                    this.props.changeIsChannelRecoAction(param);
                    this._sendReq('/show/live/unRecoAnchor', { accountId: value.accountId, broadcasClassId: value.broadcasClassId, recoType: 'CHANNEL' }, '取消频道推荐成功。', () => this.props.changeIsChannelRecoAction(param));
                }
                break;
            case 'sortNo': // 列表排序
                if (value.field === 'realOnlineNumber') {
                    if (value.order === 'ascend') {
                        this.setState({
                            viewerNumSort: 'ASC',
                            totalSalesSort: ''
                        }, () => {
                            this._searchHandle('useCache');
                        });
                    } else if (value.order === 'descend') {
                        this.setState({
                            viewerNumSort: 'DESC',
                            totalSalesSort: ''
                        }, () => {
                            this._searchHandle('useCache');
                        });
                    } else {
                        this.setState({
                            viewerNumSort: '',
                            totalSalesSort: ''
                        }, () => {
                            this._searchHandle('useCache');
                        });
                    }
                } else if (value.field === 'totalSales') {
                    if (value.order === 'ascend') {
                        this.setState({
                            viewerNumSort: '',
                            totalSalesSort: 'ASC'
                        }, () => {
                            this._searchHandle('useCache');
                        });
                    } else if (value.order === 'descend') {
                        this.setState({
                            viewerNumSort: '',
                            totalSalesSort: 'DESC'
                        }, () => {
                            this._searchHandle('useCache');
                        });
                    } else {
                        this.setState({
                            viewerNumSort: '',
                            totalSalesSort: ''
                        }, () => {
                            this._searchHandle('useCache');
                        });
                    }
                }
                break;
            case 'starLight': // 魅力值收入路由链接
                setSessionStorage('liveTopInfo', liveTopInfo);
                updateContent('STAR_LIGHT', id);
                break;
            case 'starValue': // 人气值收入路由链接
                setSessionStorage('liveTopInfo', liveTopInfo);
                updateContent('STAR_VALUE', id);
                break;
            case 'sell': // 秀场收益
                setSessionStorage('liveTopInfo', liveTopInfo);
                updateContent('SELL', id);
                break;
            case 'virtual': // 虚拟人气
                http('/show/popularity/listVirtualPopularitys/' + id, {}, 'POST')
                    .then(res => {
                        let isAdd = true;
                        if (res.result.length) isAdd = false;
                        this.setState({ currentOpenMode: 'virtual', modeParams: { list: res.result, isAdd, id } });
                    }).catch((e) => {
                        message.error(e.message);
                    });
                break;
            case 'violation': // 本场违规
                updateContent('SELL_VIOLATION', value);
                break;
            case 'delete': // 删除直播间（PC操作）
                showConfirm('删除提醒', '确认要删除当前直播间吗？', () => {
                    http('/liveBroad/deleteLiveBroadcast', { id: value.id, broadcastClassId: value.broadcasClassId, broadcastClass: value.broadcasClassCode }, 'POST').then((response) => {
                        this._searchHandle('useCache');
                        message.success('删除成功');
                    }).catch(e => message.error(e.message));
                });
                break;
            case 'start': // 开启直播间（PC操作）
                this.setState({ currentOpenMode: 'previewLive', modeParams: { id: value.id, broadcastClassId: value.broadcasClassId, broadcastClass: value.broadcasClassCode } });
                break;
            case 'stop': // 停止直播间（PC操作）
                showConfirm('停止直播提示', '停止直播后，当前直播间将断开直播，用户不再获取直播画面，确定要停止直播吗？', () => {
                    http('/liveBroad/stopLiveBroadcast', { id: value.id, broadcastClassId: value.broadcasClassId, broadcastClass: value.broadcasClassCode }, 'POST').then((response) => {
                        this._searchHandle('useCache');
                        message.success('直播已停止');
                    }).catch((e) => {
                        message.error(e.message);
                    });
                });
                break;
        }
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 渲染弹框
    _renderMode = () => {
        const { currentOpenMode, modeParams } = this.state;
        switch (currentOpenMode) {
            case 'able':
                if (modeParams.liveState == '直播中') {
                    return <OnlinePlayModal visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
                } else if (modeParams.liveState == '回放' || modeParams.liveState == '未开播') {
                    return <OnlinePlayModal visible={true} url={modeParams.playbackUrl} closeMode={this._closeMode} />;
                } else {
                    message.error('直播间状态错误，请联系管理员！');
                }
                break;
            case 'virtual':
                return <Virtual modeParams={modeParams} closeMode={this._closeMode} />;
            case 'createLive':
                return <CreateLive close={this._closeMode} closeAndOpenNewMode={this._closeAndOpenNewMode} />;
            case 'previewLive':
                return <PreviewLive params={modeParams} close={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentOpenMode: '', modeParams: {} }, () => {
            this._searchHandle('useCache');
        });
    }

    // 关闭弹窗并打开新弹窗
    _closeAndOpenNewMode = (modeName, param) => {
        this.setState({ currentOpenMode: modeName, modeParams: { ...param } });
    }

    render() {
        const { tel, nickname, liveStatus, liveTitle, userType, violationType } = this.state;
        const { listLiveData, listLiveStatus, anchorTypeEnum, violationStatus } = this.props;

        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='主播账号'
                                placeholder='请输入主播账号'
                                bindName='tel'
                                bindThis={this}
                                value={tel}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='主播昵称'
                                placeholder='请输入主播昵称'
                                bindName='nickname'
                                bindThis={this}
                                value={nickname}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='直播标题'
                                placeholder='请输入直播标题'
                                bindName='liveTitle'
                                bindThis={this}
                                value={liveTitle}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='直播间状态'
                                placeholder='请选择'
                                renderData={listLiveStatus}
                                bindName='liveStatus'
                                bindThis={this}
                                dataIndex='value'
                                keyIndex='code'
                                value={liveStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='主播类型'
                                placeholder='请选择'
                                renderData={anchorTypeEnum}
                                bindName='userType'
                                bindThis={this}
                                dataIndex='value'
                                keyIndex='code'
                                value={userType.value}
                            />
                        </SearchItem>
                        {/* <SearchItem>
                            <XSelect
                            style={{ marginLeft: '30px', width: '232px' }}
                            selectStyle={{ width: '150px' }}
                            label='首页推荐'
                            placeholder='请选择'
                            renderData={[]}
                            onChange={value => this._searchChange(value, 'homeClass')}
                            dataIndex='homeValue'
                            keyIndex='id'
                        />
                        <XSelect
                            style={{ marginLeft: '30px', width: '232px' }}
                            selectStyle={{ width: '150px' }}
                            label='频道推荐'
                            placeholder='请选择'
                            renderData={[]}
                            onChange={value => this._searchChange(value, 'channelClass')}
                            dataIndex='className'
                            keyIndex='id'
                        />
                        </SearchItem> */}
                        <SearchItem>
                            <XSelect
                                label='违规状态'
                                placeholder='请选择'
                                renderData={violationStatus}
                                bindName='violationType'
                                bindThis={this}
                                dataIndex='value'
                                keyIndex='code'
                                value={violationType.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '150px' }} label='直播间历史记录' onClick={this._studioRoomHistory} />
                        <XOKButton style={{ width: '100px', marginLeft: '30px' }} label='推 荐' onClick={this._recommendHandle} />
                        <XOKButton style={{ width: '100px', marginLeft: '30px' }} label='发起直播' onClick={this._createLive} />
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={listLiveData} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

StudioRoomList.propTypes = {
    updateContent: PropTypes.func,
    getListLiveData: PropTypes.func,
    getListLiveStatus: PropTypes.func,
    listLiveData: PropTypes.object,
    listLiveStatus: PropTypes.array,
    changeIsHomeRecoAction: PropTypes.func,
    changeIsChannelRecoAction: PropTypes.func,
    getAnchorTypeEnum: PropTypes.func,
    anchorTypeEnum: PropTypes.array,
    getViolationType: PropTypes.func, // 违规状态action
    violationStatus: PropTypes.array, // 违规状态数据
};

StudioRoomList.defaultProps = {
    listLiveData: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
    listLiveStatus: [],
    anchorTypeEnum: []
};

const mapStateToProps = (state) => ({
    listLiveData: state.channellist.listLiveData || {},
    listLiveStatus: state.channellist.listLiveStatus || [],
    anchorTypeEnum: state.channellist.anchorTypeEnum,
    violationStatus: state.klist.violationStatus, // 违规状态
});

export default connect(mapStateToProps, {
    getListLiveData,
    getListLiveStatus,
    changeIsHomeRecoAction,
    changeIsChannelRecoAction,
    getAnchorTypeEnum,
    ...model.actions
})(StudioRoomList);