import React, { useState, useEffect, Fragment } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, message, Modal, Switch, Table, Radio, Button, Icon } from 'antd';
import { autoPageNum, autopageSize } from '@/assets/config';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { XTitle } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import JumpLink from './JumpLink';
import CustomUpload, { generateFileList } from '@/components/NewUpload';
import model from '@/reducers/systemmanage/suppliesset/model';
import BigNumber from 'bignumber.js';

const { getClassSetTableData } = model.actions;

//编辑弹框
const InfoOperateModal = ({
    tableAction,
    data: { paramValue, isOpenAdvert, isSingleImg, id, isRecommend, linkList = [] } = {},
    form: { validateFields, getFieldDecorator, getFieldValue, setFieldsValue } = {}
}) => {
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [curType, setCurType] = useState();
    const [firTempLink, setFirTempLink] = useState(linkList[0]);
    const [secTempLink, setSecTempLink] = useState(linkList[1]);

    const onSubmit = (e) => {
        e.preventDefault();
        validateFields((err, { isOpenAdvert, isSingleImg, firImg, secImg } = {}) => {
            if (!err) {
                if (isSingleImg === '1' && firImg.length === 0) return message.warn('请上传图片');
                if (isSingleImg === '0' && (firImg.length === 0 || secImg.length === 0)) return message.warn('请上传多张图片');
                let firTempItem = {};
                let secTempItem = {};
                firTempItem = firTempLink ? {
                    linkType: typeof (firTempLink.linkType) === 'object' ? firTempLink.linkType.code : firTempLink.linkType,
                    categoryId: firTempLink.categoryId, categoryName: firTempLink.categoryName,
                    goodsId: firTempLink.goodsId, goodsName: firTempLink.goodsName, shopId: firTempLink.shopId,
                    strategyId: firTempLink.strategyId, strategyName: firTempLink.strategyName, menuName: firTempLink.menuName
                } : {};
                firTempItem.imgUrl = firImg ? (firImg[0] || {}).url : '';
                secTempItem = (secTempLink && isSingleImg == 0) ? {
                    linkType: typeof (secTempLink.linkType) === 'object' ? secTempLink.linkType.code : secTempLink.linkType,
                    imgUrl: secImg ? (secImg[0] || {}).url : '',
                    categoryId: secTempLink.categoryId, categoryName: secTempLink.categoryName,
                    goodsId: secTempLink.goodsId, goodsName: secTempLink.goodsName, shopId: secTempLink.shopId,
                    strategyId: secTempLink.strategyId, strategyName: secTempLink.strategyName, menuName: secTempLink.menuName
                } : {};
                secTempItem.imgUrl = (secImg && isSingleImg == 0) ? (secImg[0] || {}).url : '';
                let linkList = [];
                if (firTempItem.imgUrl) linkList.push(firTempItem);
                if (secTempItem.imgUrl) linkList.push(secTempItem);
                setLoading(true);
                http('/supplier/config/category/update', {
                    id, paramValue, isOpenAdvert: isOpenAdvert ? 1 : 0,
                    isRecommend, isSingleImg: Number(isSingleImg), linkList
                }, 'POST').then(res => {
                    message.success(res.message);
                    setLoading(false);
                    tableAction('closeModal', true);
                }).catch(err => {
                    setLoading(false);
                    message.error(err.message);
                });
            }
        });
    };

    const beforeUpload = (file, field) => {
        const imgTypes = ['image/png', 'image/jpg', 'image/jpeg'];
        if (!imgTypes.includes(file.type)) {
            message.warn('请上传png,jpg格式的图片!');
            setFieldsValue({ [field]: [] });
            return false;
        }
        if (new BigNumber(file.size || 0).dividedBy(1024).dividedBy(1024).toNumber() > 3) {
            message.warn('图片必须小于 3MB!');
            setFieldsValue({ [field]: [] });
            return false;
        }
    };

    const onCheckLink = (params = []) => {
        if (params[0]) {
            if (params[0].curType === 'FIRST') {
                setFirTempLink(params[0]);
                setFieldsValue({ firLinkName: params[0].title });
            } else if (params[0].curType === 'SECOND') {
                setSecTempLink(params[0]);
                setFieldsValue({ secLinkName: params[0].title });
            }
        }
        setOpenModal(false);
    };

    let firLinkName = '';
    let secLinkName = '';
    for (let i = 0; i < [0, 1].length; i++) {
        if (linkList[i]) {
            const { categoryName, goodsName, strategyName, menuName, linkType: { code } = {} } = linkList[i];
            const keyValue = {
                CATEGORY: categoryName, GOODS_INFO: goodsName,
                STRATEGY_CLASS: strategyName, STRATEGY_INFO: strategyName,
                HELP_CENTER: menuName, PERSON_CENTER: menuName,
            };
            if (i === 0) firLinkName = keyValue[code];
            if (i === 1) secLinkName = keyValue[code];
        }
    }

    return <Modal
        width={660}
        centered
        title='分类广告设置'
        visible={true}
        bodyStyle={{ padding: '20px 20px 10px 20px' }}
        confirmLoading={false}
        onCancel={() => tableAction('closeModal')}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        footer={false}
    >
        <Form
            onSubmit={onSubmit}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
        >
            <Form.Item label='当前分类'>
                {getFieldDecorator('paramValue', { initialValue: paramValue })(<Input style={{ width: '300px' }} disabled={true} />)}
            </Form.Item>
            <Form.Item label='是否开启广告'>
                {getFieldDecorator('isOpenAdvert', { initialValue: isOpenAdvert == 1, valuePropName: 'checked' })(
                    <Switch checkedChildren='ON' unCheckedChildren='OFF' />)}
            </Form.Item>
            {
                getFieldValue('isOpenAdvert') && <Fragment>
                    <Form.Item label='广告类型'>
                        {getFieldDecorator('isSingleImg', { initialValue: isSingleImg.toString() })(
                            <Radio.Group>
                                {
                                    [{ code: '1', value: '单图' }, { code: '0', value: '多图' }].map(i => {
                                        return <Radio key={i.code} value={i.code}>{i.value}</Radio>;
                                    })
                                }
                            </Radio.Group>
                        )}
                        <Form.Item style={{ marginBottom: '0' }}>
                            <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 48px)', marginBottom: '0', marginRight: '20px' }}>
                                <div>图一</div>
                                {getFieldDecorator('firImg', {
                                    valuePropName: 'fileList',
                                    getValueFromEvent: e => Array.isArray(e) ? e : (e && e.fileList),
                                    initialValue: generateFileList(linkList[0] ? linkList[0].imgUrl : ''),
                                })(
                                    <CustomUpload
                                        className={styles.modalImgBox}
                                        style={{ width: '200px', hight: '120px' }}
                                        accept='.png, .jpg, .jpeg'
                                        listType="picture-card"
                                        wallMode="single"
                                        qiniuUploadProps={{ uploadType: 'image' }}
                                        beforeUpload={file => beforeUpload(file, 'firImg')}
                                    >
                                        <div> <Icon type='plus' style={{ fontSize: 32, color: '#999' }} /></div>
                                    </CustomUpload>)}
                                <div>请上传JPG、PNG，文件小于3M的图片</div>
                            </Form.Item>
                            {getFieldValue('isSingleImg') == '0' && <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 48px)', marginBottom: '0' }}>
                                <div>图二</div>
                                {getFieldDecorator('secImg', {
                                    valuePropName: 'fileList',
                                    getValueFromEvent: e => Array.isArray(e) ? e : (e && e.fileList),
                                    initialValue: generateFileList(linkList[1] ? linkList[1].imgUrl : ''),
                                })(
                                    <CustomUpload
                                        className={styles.modalImgBox}
                                        style={{ width: '200px', hight: '120px' }}
                                        accept='.png, .jpg, .jpeg'
                                        listType="picture-card"
                                        wallMode="single"
                                        qiniuUploadProps={{ uploadType: 'image' }}
                                        beforeUpload={file => beforeUpload(file, 'secImg')}
                                    >
                                        <div> <Icon type='plus' style={{ fontSize: 32, color: '#999' }} /></div>
                                    </CustomUpload>)}
                                <div>请上传JPG、PNG，文件小于3M的图片</div>
                            </Form.Item>}
                        </Form.Item>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }} label='跳转链接'>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                            {getFieldDecorator('firLinkName', { initialValue: firLinkName })(
                                <Input type='text' allowClear placeholder='请选择'
                                    onFocus={() => { setOpenModal(true); setCurType('FIRST'); }}
                                    onChange={() => { setFieldsValue({ firLinkName: '' }); setFirTempLink({}); }}
                                />
                            )}
                        </Form.Item>
                        {
                            getFieldValue('isSingleImg') == '0' && <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 12px)', marginLeft: '5px' }}>
                                {getFieldDecorator('secLinkName', { initialValue: secLinkName })(
                                    <Input type='text' allowClear placeholder='请选择'
                                        onFocus={() => { setOpenModal(true); setCurType('SECOND'); }}
                                        onChange={() => { setFieldsValue({ secLink: '' }); setSecTempLink(); }}
                                    />
                                )}
                            </Form.Item>
                        }
                    </Form.Item>
                </Fragment>
            }
            <div className={styles.modalFooter}>
                <Button style={{ marginRight: '20px' }} onClick={() => tableAction('closeModal')}>取消</Button>
                <Button type='primary' loading={loading} htmlType='submit'>确定</Button>
            </div>
            {openModal && <JumpLink curType={curType} onCheckLink={onCheckLink} closeMode={() => setOpenModal(false)} />}
        </Form >
    </Modal >;
};
InfoOperateModal.propTypes = {
    form: PropTypes.object,
    data: PropTypes.object,
    tableAction: PropTypes.func
};
const InfoOperateForm = Form.create()(InfoOperateModal);

const ClassSet = ({
    getClassSetTableData,
    tableData: {
        dataList = [],
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0
    } = {}
}) => {

    const [openModal, setOpenModal] = useState(false);
    const [modalParams, setModalParams] = useState();
    const [cPageNum, setCPageNum] = useState(autoPageNum);
    const [cPageSize, setCPageSize] = useState(autopageSize);

    useEffect(() => {
        getClassSetTableData({ paramType: 'SUPPLIER_CATEGORY', pageNum: cPageNum, pageSize: cPageSize });
    }, [cPageNum, cPageSize]);

    const tableOperate = (t, r) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => operateFunc('openModal', r)}>编辑</div>
        </div>;
    };

    const sortNoCell = (t, r) => {
        return <Switch
            checkedChildren='ON' unCheckedChildren='OFF' checked={t == 1}
            onChange={checked => operateFunc('sortNo', { id: r.id, type: checked ? 1 : 0 })}
        />;
    };

    const columns = [
        {
            title: '分类名词',
            dataIndex: 'paramValue',
            key: 'paramValue',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '推荐',
            dataIndex: 'isRecommend',
            key: 'isRecommend',
            align: 'center',
            onCell: tooltipStyle,
            render: sortNoCell
        },
        {
            title: '操作人',
            dataIndex: 'modifyUserName',
            key: 'modifyUserName',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            onCell: tooltipStyle,
            render: tableOperate
        }
    ];

    const operateFunc = (type, params) => {
        const eventObject = {
            sortNo: p => {
                http('/supplier/config/recommend', p, 'POST')
                    .then(res => {
                        message.success(res.message);
                        getClassSetTableData({ paramType: 'SUPPLIER_CATEGORY', pageNum: cPageNum, pageSize: cPageSize });
                    }).catch(err => message.error(err.message));
            },
            openModal: p => {
                setOpenModal(true);
                setModalParams(p);
            },
            closeModal: p => {
                setOpenModal(false);
                setModalParams();
                p && getClassSetTableData({ paramType: 'SUPPLIER_CATEGORY', pageNum: cPageNum, pageSize: cPageSize });
            }
        };
        eventObject[type](params);
    };

    const paginationChange = (ps, pn) => {
        setCPageNum(pn);
        setCPageSize(ps);
    };

    return <div className={styles.flexBoxContainer}>
        <XTitle label='首页分类设置' />
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey='id'
                columns={columns}
                dataSource={dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
        {openModal && <InfoOperateForm data={modalParams} tableAction={operateFunc} />}
    </div>;
};

ClassSet.propTypes = {
    history: PropTypes.object,
    getClassSetTableData: PropTypes.func,
    tableData: PropTypes.object
};

export default connect(state => ({
    tableData: state.suppliesset.classSetTableData
}), { getClassSetTableData })(ClassSet);