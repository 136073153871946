/**
 * 服务商审核
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Audit from './actionTypes';

//审核列表
export const getFacilitatorAuditList = function* () {
    yield takeEvery(Audit.GET_FACILITATORAUDIT_LIST, function* requestData(action) {
        try {
            let result = yield http('/service/provider/infoList', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_FACILITATORAUDIT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//状态
export const getFacilitatorAuditStatus = function* () {
    yield takeEvery(Audit.GET_FACILITATORAUDIT_STATUS, function* requestData(action) {
        try {
            let result = yield http('/enum/ServiceProviderApplyTypeEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_FACILITATORAUDIT_STATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//详情
export const getFacilitatorAuditDetail = function* () {
    yield takeEvery(Audit.GET_FACILITATORAUDIT_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/service/provider/info', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_FACILITATORAUDIT_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//日志
export const getFacilitatorAuditLog = function* () {
    yield takeEvery(Audit.GET_FACILITATORAUDIT_AUDITLOG, function* requestData(action) {
        try {
            let result = yield http('/service/provider/auditInfo', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_FACILITATORAUDIT_AUDITLOG, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};