import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/auditmanage/channelaudit/model';

const { getCreditAuditData, getStatusEnum } = model.actions;

const CreditAudit = ({
    history,
    getCreditAuditData,
    tableData: {
        dataList = [],
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0
    } = {},
    getStatusEnum,
    statusEnum,
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb
}) => {

    const jumpPage = (path, title) => {
        const baseUrl = '/home/auditmanage/channelaudit';
        addOneBreadcrumbPath({ title, path: `${baseUrl}${path}` });
        history.push({ pathname: `${baseUrl}${path}` });
    };

    const tableOperate = (id, { status }) => {
        return <div className={styles.action}>
            {status === 'WAIT_AUDIT' && <div className={styles.item} onClick={() => jumpPage(`/craditaudit/${id}`, '审核')}>审核</div>}
        </div>;
    };

    const columns = [
        {
            title: '渠道账号',
            dataIndex: 'channelTel',
            key: 'channelTel',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '当前信用额度',
            dataIndex: 'currentQuota',
            key: 'currentQuota',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '申请变更信用额度',
            dataIndex: 'changeQuota',
            key: 'changeQuota',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '审核人',
            dataIndex: 'approver',
            key: 'approver',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '审核时间',
            dataIndex: 'applyDate',
            key: 'applyDate',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请原因',
            dataIndex: 'applyReason',
            key: 'applyReason',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            onCell: tooltipStyle,
            render: tableOperate
        }
    ];

    const [channelTel, setChannelTel] = useState();
    const [stateItem, setStateItem] = useState({});
    const [cPageNum, setCPageNum] = useState(autoPageNum);
    const [cPageSize, setCPageSize] = useState(autopageSize);

    useEffect(() => {
        keepSecondNavBreadcrumb();
        getStatusEnum();
    }, []);

    const getTableData = (params = {}) => {
        getCreditAuditData({
            channelTel: params.channelTel,
            status: params.stateItem && params.stateItem.code,
            pageNum: params.pageNum, pageSize: params.pageSize
        });
        KeepAlive.saveCache(params);
    };

    const resetSearch = () => {
        setChannelTel();
        setStateItem({});
        KeepAlive.saveResetFunc(() => getTableData({ channelTel, stateItem, pageNum: cPageNum, pageSize: cPageSize }));
    };


    const paginationChange = (ps, pn) => {
        setCPageNum(pn);
        setCPageSize(ps);
        getTableData({ channelTel, stateItem, pageNum: pn, pageSize: ps });
    };

    const onChange = (p = {}) => {
        if (p.channelTel) setChannelTel(p.channelTel);
        if (p.stateItem) setStateItem(p.stateItem);
        if (p.pageNum != autoPageNum) setCPageNum(p.pageNum);
        if (p.pageSize != autopageSize) setCPageSize(p.pageSize);
        getTableData(p);
    };

    
    return <KeepAlive id='creditAudit' render={onChange}>
        <div className={styles.flexBoxContainer}>
            <SearchBox>
                <SearchItem>
                    <XInput
                        label='渠道账号'
                        placeholder='请输入渠道账号'
                        bindThis={setChannelTel}
                        bindName='channelTel'
                        value={channelTel}
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        label='状态'
                        placeholder='请选择状态'
                        renderData={statusEnum}
                        bindThis={setStateItem}
                        bindName='stateItem'
                        dataIndex='value'
                        keyIndex='code'
                        value={stateItem.value}
                    />
                </SearchItem>
                <SearchItem>
                    <XOKButton
                        label='查询'
                        onClick={() => getTableData({ channelTel, stateItem, pageNum: cPageNum, pageSize: cPageSize })}
                    />
                    <XCancelButton
                        label='重置'
                        onClick={resetSearch}
                    />
                </SearchItem>
            </SearchBox>
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey='id'
                    columns={columns}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        </div>
    </KeepAlive>;
};

CreditAudit.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getCreditAuditData: PropTypes.func,
    tableData: PropTypes.object,
    getStatusEnum: PropTypes.func,
    statusEnum: PropTypes.array
};

export default connect(state => ({
    tableData: state.channelaudit.creditAuditData,
    statusEnum: state.channelaudit.statusEnum,
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getCreditAuditData,
    getStatusEnum
})(CreditAudit);