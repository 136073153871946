export class FloatConversion {
    /**
         * FloatConversion 包含加减乘除四个方法，能确保浮点数运算不丢失精度（author's source:blogger）
         * ** method **
         *  add / subtract / multiply /divide
         * ** explame **
         *  0.1 + 0.2 == 0.30000000000000004 （多了 0.00000000000004）
         *  0.2 + 0.4 == 0.6000000000000001  （多了 0.0000000000001）
         *  19.9 * 100 == 1989.9999999999998 （少了 0.0000000000002）
         *
         * FloatConversion.add(0.1, 0.2) >> 0.3
         * FloatConversion.multiply(19.9, 100) >> 1990
    */
    constructor() { }

    //判断obj是否为一个整数
    isInteger(obj) {
        return Math.floor(obj) === obj;
    }

    //强类型转换nubmer型，处理在除 divide()方式可能会有精确度问题，
    //只限整数（字符带小数，整形带小数没问题），number整数6、6.0（无问题）; string整数'6'、'6.0'（有问题）;
    isNubmer(obj) {
        if (typeof (obj) === 'string' && !this.isInteger(obj)) {//是否字符串
            return Number(obj);
        } else {
            return obj;
        }
    }

    /*
    * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
    * @param floatNum {number} 小数
    * @return {object}
    *   {times:100, num: 314}
    */
    toInteger(floatNum) {
        let ret = { times: 1, num: 0 };
        if (this.isInteger(floatNum)) {
            ret.num = floatNum;
            return ret;
        }
        let strfi = floatNum + '';
        let dotPos = strfi.indexOf('.');
        let len = strfi.substr(dotPos + 1).length;
        let times = Math.pow(10, len);
        let intNum = parseInt(floatNum * times + 0.5, 10);
        ret.times = times;
        ret.num = intNum;
        return ret;
    }

    /*
     * 核心方法，实现加减乘除运算，确保不丢失精度
     * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
     *
     * @param a {number} 运算数1
     * @param b {number} 运算数2
     * @param digits {number} 精度，保留的小数点数，比如 2, 即保留为两位小数（暂时没有该功能）
     * @param op {string} 运算类型，有加减乘除（add/subtract/multiply/divide）
     *
     */
    operation(a, b, digits, op) {
        var o1 = this.toInteger(a);
        var o2 = this.toInteger(b);
        var n1 = o1.num;
        var n2 = o2.num;
        var t1 = o1.times;
        var t2 = o2.times;
        var max = t1 > t2 ? t1 : t2;
        var result = null;
        switch (op) {
            case 'add':
                if (t1 === t2) { // 两个小数位数相同
                    result = n1 + n2;
                } else if (t1 > t2) { // o1 小数位 大于 o2
                    result = n1 + n2 * (t1 / t2);
                } else { // o1 小数位 小于 o2
                    result = n1 * (t2 / t1) + n2;
                }
                return result / max;
            case 'subtract':
                if (t1 === t2) {
                    result = n1 - n2;
                } else if (t1 > t2) {
                    result = n1 - n2 * (t1 / t2);
                } else {
                    result = n1 * (t2 / t1) - n2;
                }
                return result / max;
            case 'multiply':
                result = (n1 * n2) / (t1 * t2);
                return result;
            case 'divide':
                result = (n1 / n2) * (t2 / t1);
                return result;
        }
    }

    // 加减乘除
    add(a, b, digits) {
        return this.operation(a, b, digits, 'add');
    }
    subtract(a, b, digits) {
        return this.operation(a, b, digits, 'subtract');
    }
    multiply(a, b, digits) {
        return this.operation(a, b, digits, 'multiply');
    }
    divide(a, b, digits) {
        return this.operation(this.isNubmer(a), b, digits, 'divide');
    }
}
/*
   * 四舍五入并保留n位小数
   * @param num {number|string} 入参值 1、1.00
   * @param n {number} 保留的小数位(正整数)
   * @param integer {boolean} 整数时是否补0 该参数仅在整数情况有效  7、7.00
   */
export const fomatFloat = (num, n = 2, integer = true) => {
    let f = parseFloat(num);
    if (isNaN(f)) {
        return false;
    }
    f = Math.round(num * Math.pow(10, n)) / Math.pow(10, n); // n 幂   
    let s = f.toString();
    let int = s.indexOf('.');//整数时是否补0标识
    let rs = s.indexOf('.');
    //判定如果是整数，增加小数点再补0
    if (rs < 0) {
        rs = s.length;
        s += '.';
    }
    while (s.length <= rs + n) {
        s += '0';
    }
    return (!integer && int < 0) ? Math.abs(s) : s;
};


//点击下载图片canvas.toDataURL([type, encoderOptions]); encoderOptions 
//为image/jpeg或image/webp类型的图片设置图片质量，取值0-1，超出则以默认值0.92替代
export const downloadImage = (imgsrc, name, type = 'image/png') => {
    let image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute('crossOrigin', 'anonymous');
    //将资源链接赋值过去，才能触发image.onload 事件
    image.src = imgsrc;
    image.onload = function () {
        let canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL(type); //得到图片的base64编码数据
        let a = document.createElement('a'); // 生成一个a元素
        let event = new MouseEvent('click'); // 创建一个单击事件
        a.download = name || 'photo'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
    };
};