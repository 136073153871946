import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import AllProduct from '../allProduct';
import NoAudit from '../noAudit';
import AuditFall from '../AuditFall';
import ExitApply from '../exitApply';

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '全部商品' },
            { id: 2, label: '待审核' },
            { id: 3, label: '审核失败' },
            { id: 4, label: '全部退出申请' }
        ],
        curTabID: 1
    }

    componentDidMount() {
        const { curTabID = 1 } = this.props.history.location;
        if (curTabID) {
            this.setState({ curTabID });
        }
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        const { updateContent, history } = this.props;
        switch (this.state.curTabID) {
            case 1:
                return <AllProduct updateContent={updateContent} history={history} />;
            case 2:
                return <NoAudit updateContent={updateContent} history={history} />;
            case 3:
                return <AuditFall updateContent={updateContent} history={history} />;
            case 4:
                return <ExitApply updateContent={updateContent} history={history} />;
            default:
                return <AllProduct updateContent={updateContent} history={history} />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} history={this.props.history} activedID={this.state.curTabID} />
                <div className={`${styles.flexBoxContainer} ${styles.page}`}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func
};

export default Main;