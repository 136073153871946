export function secToHms(value) {
    let result = parseInt(value);
    let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
    let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
    let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
    result = `${h}:${m}:${s}`;
    return result;
}

export function hmsDay(value,num) {
    let day = value ? new Date(value) : new Date();
    let result;
    num > 0 && day.setDate(day.getDate() - num);
    let y = day.getFullYear() < 10 ? '0' + day.getFullYear() : day.getFullYear();
    let m = (day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1);
    let d = day.getDate() < 10 ? '0' + day.getDate() : day.getDate();
    result = `${y}-${m}-${d} ${num ? '00:00:00' : '23:59:59'}`;
    return result;
}