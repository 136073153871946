/**
 *  售后管理 - 售后原因说明
 * */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import http from '@/assets/api/http';
import { message } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import SalerModal from '../../modal/SalerModal';
import SalerEditModal from '../../modal/SalerEditModal';
import { getSaleReasonList } from '@/reducers/salemanage/salereasons/actions';
import { XInput, XOKButton, XCancelButton, XPagination, XTabNav, showConfirm } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';


const Mode = {
    REASON: 'REASON',
};


class Reason extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
        tab: [
            { id: 'AFTER_SALE_REASON', label: '售后原因' },
            { id: 'CANCEL_REASON', label: '取消原因' },
            { id: 'UNRECEIVED_REASON', label: '未收到货' },
        ],
        curTabID: 'AFTER_SALE_REASON',  // 默认显示的tab
        reason: '',
        reasonType: 'AFTER_SALE_REASON',
        pageNum: 1,
        pageSize: 10,
    }
    componentDidMount() {
        // this.props.getSaleReasonList({ reasonType: 'AFTER_SALE_REASON', pageNum: 1, pageSize: 10 });
    }
    _tabChangeHandle = (e) => {
        this.setState({
            curTabID: e,
        }, () => {
            this._searchHandle('useCache');
        });
        // this.props.getSaleReasonList({ reasonType: e, pageNum: 1, pageSize: 10 });
    }
    // 查询触发
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, reason, curTabID } = this.state;
        this.searchCache = {
            reasonType: curTabID,
            reason,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getSaleReasonList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _saleresetHandle = () => {
        // const { curTabID } = this.state;
        this.setState({ pageNum: 1, pageSize: 10, reason: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        // this.props.getSaleReasonList({ reasonType: curTabID, pageNum: 1, pageSize: 10 });
    }

    //  // 表格复选框
    //  _rowSelection = {
    //   onChange: (selectedRowKeys, selectedRows) => {
    //     // this.setState({openModeParam: selectedRows});
    //   }
    // };

    // 新建按钮被点击
    _newreasonHandle = () => {
        this.setState({ currentOpenMode: Mode.REASON });
    }

    // 表格操作功能回调
    _actionClickHandle = (id, type, value) => {
        var param = {};
        switch (type) {
            case 'sort': //排序
                param = {
                    id,
                    reasonSort: value,
                    reasonType: this.state.curTabID,
                };
                http('/boss/order/updateAfterSaleReasonSortNo', param, 'POST')
                    .then(() => {
                        message.success('修改成功!');
                        this._searchHandle('useCache');
                    }).catch((reject) => {
                        message.error(reject.message);
                    });
                break;
            case 'switch': //状态 
                param = {
                    id,
                    reasonState: value === false ? 0 : 1,
                    reasonType: this.state.curTabID,
                };
                this.updateContent(type, value, param);
                break;
            case 'del': //删除  
                showConfirm('确认是否删除?', '', () => {
                    http('/boss/order/deleteAfterSaleReasonsById', { id }, 'POST')
                        .then(() => {
                            message.success('删除成功!');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'edit': //编辑
                this.setState({ currentOpenMode: 'edit', openModeParam: { id } });
                break;
        }

    }
    updateContent = (type, value, param) => {
        http('/boss/order/updateAfterSaleReason', param, 'POST')
            .then(() => {
                message.success(`${type === 'switch' && value === true ? '已启用' : type === 'switch' && value === false ? '已禁用' : '修改成功!'}`);
                this._searchHandle('useCache');
            }).catch((reject) => {
                message.error(reject.message);
            });
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.REASON:
                return <SalerModal title='创建原因' reasonType={this.state.curTabID} visible={true} refresh={this._searchHandle} closeMode={this._closeMode} />;
            case 'edit':
                return <SalerEditModal title='编辑原因' openModeParam={this.state.openModeParam} reasonType={this.state.curTabID} visible={true} refresh={this._searchHandle} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        const { reason, curTabID } = this.state;
        this.setState({ pageNum, pageSize });
        this.searchCache = {
            reasonType: curTabID,
            pageNum,
            pageSize,
            reason
        };
        this.props.getSaleReasonList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { saleReasonList: { dataList = [], resultTotal, pageSize, pageNum } } = this.props;
        const { reason } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='原因标题'
                                placeholder='请输入'
                                value={reason}
                                bindThis={this}
                                bindName='reason'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._saleresetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: 90 }} label='新建' onClick={this._newreasonHandle} />
                    </div>
                    <div className={styles.tableContainer}>
                        <Table
                            // bordered
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            // rowSelection={this._rowSelection} 
                            rowKey={(record, index) => index}
                            columns={takeColumns(this._actionClickHandle, this.state.curTabID)}
                            dataSource={takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    <div>{this._renderMode()}</div>
                </div>
            </KeepAlive>
        );
    }
}

Reason.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getSaleReasonList: PropTypes.func,
    saleReasonList: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        saleReasonList: state.SaleReasons.saleReasonList,
    };
};


export default connect(mapStateToProps, { getSaleReasonList })(Reason);