/**
 * 商品品牌编辑
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, message, Radio, Input, Form } from 'antd';
import CustomUpload, { generateFileList } from '@/components/NewUpload';
import { getProductBrandDetail } from '@/reducers/productmanage/brand/actions';

const RadioGroup = Radio.Group;
const { TextArea } = Input;
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
    },
};

class EditBrand extends Component {
    //初始数据
    constructor(props) {
        super(props);
        this.state = {
            title: props.title,
            visible: props.visible,
            confirmLoading: false,
            logos: [],
            bigUrls: [],
            update: true
        };
    }
    //props校验
    static propTypes = {
        visible: PropTypes.bool.isRequired, // 是否显示弹框
        closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
        title: PropTypes.string.isRequired, // 弹框标题
        form: PropTypes.object.isRequired,//form表单
        openModeParam: PropTypes.number.isRequired,//弹框参数
        getProductBrandDetail: PropTypes.func,//请求详情函数
        productBrandDetail: PropTypes.object.isRequired//品牌详情
    };
    //异步请求
    componentDidMount() {
        const { getProductBrandDetail, openModeParam } = this.props;
        getProductBrandDetail(openModeParam);
    }
    //更新数据
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.update) {
            const logos = generateFileList(nextProps.productBrandDetail.logoUrl);
            const bigUrls = generateFileList(nextProps.productBrandDetail.bigUrl);
            this.setState({ logos, bigUrls, update: false });
        }

    }
    //请求函数体
    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST').then(() => {
            message.success(winTxt);
            this.setState({
                visible: false,
                confirmLoading: false
            });
            this.props.closeMode(true);
        }).catch((e) => {
            message.error(e.message);
            this.setState({ confirmLoading: false });
        });
    }
    // 点击确认
    _handleOk = () => {
        this.handleSubmit();
    }
    //提交
    handleSubmit = e => {
        if (e) e.preventDefault();
        const { form, openModeParam } = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                const logoUrl = [];
                const bigUrl = [];
                const query = {};
                values.logoUrl.map(item => {
                    logoUrl.push(item.url);
                });
                values.bigUrl.map(item => {
                    bigUrl.push(item.url);
                });
                query.logoUrl = logoUrl.join(',');
                query.bigUrl = bigUrl.join(',');
                query.brandName = values.brandName;
                query.firstLetter = values.firstLetter;
                query.isShow = values.isShow;
                query.brandDesc = values.brandDesc;
                query.id = openModeParam;
                this.setState({ confirmLoading: true }, () => {
                    this._sendReq('/goods/brand/udtBrands', query, '编辑品牌成功。');
                });
            }
        });
    };
    // 点击取消
    _handleCancel = () => {
        this.setState({ visible: false });
        this.props.closeMode(false);
    }
    render() {
        const { visible, confirmLoading, title, bigUrls, logos } = this.state;
        const { productBrandDetail } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={500}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <Form {...formItemLayout} autoComplete='off' onSubmit={this.handleSubmit} colon={false}>
                    <FormItem label="品牌名称">
                        {getFieldDecorator('brandName', {
                            initialValue: productBrandDetail.brandName,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入品牌名称',
                                },
                            ],
                        })(<Input placeholder='请输入品牌名称' />)}
                    </FormItem>
                    <FormItem label="品牌首字母">
                        {getFieldDecorator('firstLetter', {
                            initialValue: productBrandDetail.firstLetter,
                            rules: [
                                {
                                    required: true,
                                    len: 1,
                                    pattern: /[a-zA-Z]/,
                                    message: '请输入品牌首字母!',
                                },
                            ],
                        })(<Input placeholder='请输入26位字母之一' />)}
                    </FormItem>
                    <FormItem label="品牌编码">
                        {getFieldDecorator('brandCode', {
                            initialValue: productBrandDetail.brandCode,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入品牌编码!',
                                },
                            ],
                        })(<Input placeholder='请输入品牌编码' />)}
                    </FormItem>
                    <FormItem label="品牌LOGO">
                        {getFieldDecorator('logoUrl', {
                            validateTrigger: 'onSubmit',
                            initialValue: logos,
                            rules: [
                                {
                                    type: 'array',
                                    required: true,
                                    message: '请上传品牌LOGO',
                                },
                            ],
                        })(
                            <CustomUpload
                                accept='.png, .jpg, .jpeg'
                                listType="picture-card"
                                wallMode="single"
                                fileList={logos}
                                qiniuUploadProps={{
                                    uploadType: 'image'
                                }}
                                onFileListChange={(info, customFile) => {
                                    if (customFile && customFile.status === 'done') {
                                        this.props.form.resetFields('logoUrl', []);
                                    }
                                    if (customFile && customFile.status === 'removed') {
                                        this.props.form.resetFields('logoUrl', []);
                                        this.setState({ logos: [] });
                                    }
                                }}
                                onChange={(info) => {
                                    const logos = info.fileList;
                                    logos.length == '0' && this.props.form.resetFields('logoUrl', []);
                                    this.setState({ logos });
                                }}
                            >
                                <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                            </CustomUpload>
                        )}
                    </FormItem>
                    <FormItem label="品牌专区背景">
                        {getFieldDecorator('bigUrl', {
                            validateTrigger: 'onSubmit',
                            initialValue: bigUrls,
                            rules: [
                                { type: 'array', message: '请上传品牌专区背景', },
                            ],
                        })(
                            <CustomUpload
                                accept='.png, .jpg, .jpeg'
                                listType="picture-card"
                                wallMode="single"
                                fileList={bigUrls}
                                qiniuUploadProps={{
                                    uploadType: 'image'
                                }}
                                onFileListChange={(info, customFile) => {
                                    if (customFile && customFile.status === 'done') {
                                        this.props.form.resetFields('bigUrl', []);
                                    }
                                    if (customFile && customFile.status === 'removed') {
                                        this.props.form.resetFields('bigUrl', []);
                                        this.setState({ bigUrls: [], });
                                    }
                                }}
                                onChange={(info) => {
                                    const bigUrls = info.fileList;
                                    bigUrls.length === 0 && this.props.form.resetFields('bigUrl', []);
                                    this.setState({ bigUrls });
                                }}
                            >
                                <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                            </CustomUpload>
                        )}
                    </FormItem>
                    <FormItem label='品牌故事'>
                        {getFieldDecorator('brandDesc', {
                            initialValue: productBrandDetail.brandDesc,
                            rules: [
                                { required: true, message: '请输入品牌故事' }
                            ],
                        })(<TextArea placeholder='请输入品牌故事' autosize={{ minRows: 2, maxRows: 6 }} />)}
                    </FormItem>
                    <FormItem label="是否显示">
                        {getFieldDecorator('isShow', {
                            initialValue: productBrandDetail.isShow,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择是否显示'
                                },
                            ],
                        })(
                            <RadioGroup>
                                <Radio value={0}>是</Radio>
                                <Radio value={1}>否</Radio>
                            </RadioGroup>)}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}
//映射state到props
const mapStateToProps = (state) => ({
    productBrandDetail: state.productBrand.productBrandDetail
});
const EditBrandForm = Form.create({ name: 'editBrand' })(EditBrand);
export default connect(mapStateToProps, { getProductBrandDetail })(EditBrandForm);
