/**
 * 频道分类
 */
import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import LiveClassify from './LiveClassify';
import VideoClassify from './VideoClassify';
import CommunityClassify from './CommunityClassify';
import modal from '@/reducers/knowledgepay/channelclassify/model';

const tabPages = [
    { id: 1, label: '直播间分类' },
    { id: 2, label: '视频分类' },
    { id: 3, label: '社区分类' },
    // { id: 4, label: '音频分类' }
];

const ChannelClassify = ({ history, getKClassifyState, kClassifyState }) => {

    const [curTabID, setCurTabID] = useState(1);

    useEffect(() => {
        getKClassifyState();
    }, []);

    // 渲染相应Tab导航下的内容
    const _renderPage = () => {
        switch (curTabID) {
            case 1:
                return <LiveClassify classifyState={kClassifyState} curTabID={curTabID} history={history} />;
            case 2:
                return <VideoClassify classifyState={kClassifyState} curTabID={curTabID} history={history} />;
            case 3:
                return <CommunityClassify classifyState={kClassifyState} curTabID={curTabID} history={history} />;
            default:
                return <LiveClassify classifyState={kClassifyState} curTabID={curTabID} history={history} />;
        }
    };

    return (
        <div className={styles.flexBoxContainer}>
            <XTabNav renderData={tabPages} onChange={(tabID) => setCurTabID(tabID)} activedID={curTabID} />
            {_renderPage()}
        </div>
    );
};

ChannelClassify.propTypes = {
    history: PropTypes.object, // 路由history对象
    getKClassifyState: PropTypes.func,//分类列表
    kClassifyState: PropTypes.array,//列表数据
};

const mapStateToProps = (state) => ({
    kClassifyState: state.kclassify.kClassifyState,
});

export default connect(mapStateToProps, { ...modal.actions })(ChannelClassify);