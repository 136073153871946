/**
 * 语音包
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import PackTable from './components/PackTable';
import PackModal from './modal/PackModal';
import styles from './index.module.scss';
import model from '@/reducers/systemmanage/languagePack/model';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
        };
    }

    componentDidMount() { }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, } = this.state;
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getLanguageList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 表格操作 
    _tableAction = (item, type, value) => {
        // console.log(item, type, value);
        switch (type) {
            case 'edite'://编辑
                this.setState({
                    currentOpenMode: 'EDITE',
                    openModeParam: value || {},
                });
                break;
            case 'switch'://状态
                http(`/hotel/voicePacket/switchVoicePacketStatus/${item.id}`, {}, 'POST')
                    .then(() => {
                        message.success('状态修改成功。');
                        this.props.getLanguageList(this.searchCache);
                    }).catch((reject) => {
                        message.error(reject.message);
                    });
                break;
        }
    }

    renderModal = () => {
        switch (this.state.currentOpenMode) {
            case 'EDITE':
                return <PackModal title='编辑语音' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }
    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getLanguageList(this.searchCache);
        }
    }

    render() {
        const { tableList } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <PackTable renderData={tableList} tableAction={this._tableAction} />
                    {this.renderModal()}
                </div>
            </KeepAlive>
        );
    }
}


Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    tableList: PropTypes.array,
    getLanguageList: PropTypes.func,//表格数据(action)
};

const mapStateToProps = (state) => ({
    tableList: state.languagePack.languageList, // 表格数据
});

export default connect(mapStateToProps, { ...model.actions })(Main);
