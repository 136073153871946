import { createModel } from '@/store/tools';

const model = {
    namespace: 'companyorder',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getSaleReport': T('getSaleReport'),
        },
        actions: {
            'getSaleReport': A('getSaleReport'),
        },
        sagas: {
            'getSaleReport': S('getSaleReport', '/report/saleReportRC'),
        },
        reducers: {
            'getSaleReport': R('getSaleReport', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);