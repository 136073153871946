/**
 * 人气值礼物列表
 */
import React from 'react';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm, } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import GlistModal from '../../modal/GlistModal';
import DeleteModal from '../../modal/DeleteModal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message } from 'antd';
import { getstarsgiftSource, getstarsglistSource, } from '@/reducers/showmanage/present/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';


const Mode = {
    CREAT: 'CREAT',
    EDITE: 'EDITE',
    DELETE: 'DELETE',
};

class ValueGlist extends React.Component {

    state = {
        selectDataSource: [],
        type: {},
        typeName: '',
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
    }

    componentDidMount() {
        const { getstarsgiftSource } = this.props;
        getstarsgiftSource({ classTypeCode: 'STAR_GIFT_CLASS' });//列表数据分类状态

    }

    // 下拉选项数据渲染
    _glistSelectData(data) {
        let selectArray = [];
        data.forEach((item) => {
            let object = {
                key: item.id,
                id: item.id,
                name: item.className,
            };
            selectArray.push(object);
        });
        return selectArray;
    }



    // 分类状态下拉列改变时触发
    _typeChangeHandle = (selectedItem) => {
        this.setState({ type: selectedItem });
    }

    // 分类名称输出框改变时触发
    _typeNameInputChangeHandle = (value) => {
        this.setState({ typeName: value });
    }

    // 创建直播间分类
    _createStudioRoom = () => {
        this.setState({ currentOpenMode: Mode.CREAT });
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { type, typeName, pageNum, pageSize } = this.state;
        this.searchCache = {
            giftName: typeName,//礼物名称  
            giftClassId: type.id,//礼物分类
            giftCostType: 'STARVALUE',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getstarsglistSource(this.searchCache);//查询方法
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置触发
    _resetHandle = () => {
        this.setState({ type: {}, typeName: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作

    _tableAction = (item, type, value) => {
        switch (type) {
            case 'edit':
                http(`/show/giftInfo/${item}`, {}, 'GET').then((res) => {
                    this.setState({
                        currentOpenMode: Mode.EDITE,
                        openModeParam: res.result
                    });
                }).catch(() => {
                    message.error('请求错误');
                });
                break;
            case 'delete':
                showConfirm('您是否要删除该商品', '', () => {
                    http(`/show/giftInfo/delete/${value.id}`, {}, 'POST').then(() => {
                        message.success('删除成功');
                        this.props.getstarsglistSource(this.searchCache);//查询方法
                    }).catch((res) => {
                        if (res.code == '500') {
                            message.error(res.message);
                        }
                    });
                }, () => { });
                break;
            case 'isReco':
                http(`/show/giftInfo/updateGiftReco/${item.id}/${(value === false) ? 0 : 1}`, {//推荐
                    //   id:item.id, 
                    //   isReco: (value===false) ? 0 : 1 ,
                }, 'POST').then(() => {
                    message.success('推荐修改成功');
                    this.props.getstarsglistSource(this.searchCache);//查询方法
                }).catch((res = {}) => {
                    message.error(res.message);
                });
                break;
            case 'state':
                http(`/show/giftInfo/${item.id}/${(value === false) ? 0 : 1}`, {//状态
                    //   id:item.id, 
                    //   state: (value===false) ? 0 : 1 ,
                }, 'POST').then(() => {
                    message.success('状态修改成功');
                    this.props.getstarsglistSource(this.searchCache);//查询方法
                }).catch((res = {}) => {
                    message.error(res.message);
                });
                break;
        }

    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.CREAT:
                return <GlistModal title='创建人气值礼物列表' stratInit='STARVALUE' selectData={this.props.selectData} modetext='startValue' visible={true} closeMode={this._closeMode} />;
            case Mode.EDITE:
                return <GlistModal title='编辑人气值礼物列表' modetext='startValue' selectData={this.props.selectData} visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case Mode.DELETE:
                return <DeleteModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status == 'delete') {
            http(`/show/giftInfo/delete/${this.state.openModeParam.id}`, {}, 'POST').then(() => {
                message.success('删除成功');
                this.props.getstarsglistSource(this.searchCache);// 创建后重新渲染更新列表
            }).catch((res) => {
                if (res.code == '500') {
                    message.error(res.message);
                }
            });
        } else if (status) {
            this.props.getstarsglistSource(this.searchCache);// 创建后重新渲染更新列表
        }
    }

    // 下拉选项数据处理渲染
    _giftSelectData(data) {
        return data && data.map(item => ({
            id: item.id,
            name: item.className,
        }));
    }
    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getstarsglistSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { type, typeName } = this.state;
        const { tableSource, paginations, selectData, } = this.props;
        return (
            <KeepAlive id='4' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <SearchBox>
                            <SearchItem>
                                <XSelect
                                    label='礼物分类'
                                    placeholder='请选择礼物分类'
                                    renderData={this._giftSelectData(selectData)}
                                    onChange={this._typeChangeHandle}
                                    dataIndex='name'
                                    keyIndex='id'
                                    value={type.name}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='礼物名称'
                                    placeholder='请输入礼物名称'
                                    onChange={this._typeNameInputChangeHandle}
                                    value={typeName}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '160px' }} label='创建人气值礼物列表' onClick={this._createStudioRoom} />
                    </div>
                    <div className={styles.flexBoxContainer}>
                        <TableContent _paginationChange={this._paginationChange} renderData={tableSource} tableAction={this._tableAction} pagiNation={paginations} />
                    </div>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}


ValueGlist.propTypes = {
    getstarsgiftSource: PropTypes.func,// 礼物分类列表数据数据源的Action =》分类下拉选项

    tableSource: PropTypes.array, // 礼物礼物-表格数据源
    selectData: PropTypes.array, // 分类状态下拉选项
    paginations: PropTypes.object, // 分页数据
    getstarsglistSource: PropTypes.func, // 通过Saga异步请求表格数据源的Action
};

const mapStateToProps = (state) => {
    return {
        tableSource: state.presentcomment.presrntDate.listTableSource,
        selectData: state.presentcomment.presrntDate.tableSource,// 礼物分类列表数据数据源的Action =》分类下拉选项
        paginations: state.presentcomment.presrntDate.listPaginations,
    };
};

export default connect(mapStateToProps, { getstarsgiftSource, getstarsglistSource, })(ValueGlist);