/**
 *  合同管理 -线上
 * */ 
import React from 'react';
import PropTypes from 'prop-types'; 
import Main from './pages/main';

class Onlinelist extends React.Component {

    render(){
        return(
            <Main /> 
        );
    }
}

Onlinelist.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
};



export default Onlinelist;