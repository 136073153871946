import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, message } from 'antd';
import ConverRulesModal from '../../modal/ConverRulesModal';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, showConfirm } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/systemmanage/showset/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class ConvertibleRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rulesNum: '',//规则名称
            ruleState: {},//规则状态
            selectDataSource: [],//模拟下拉列数据
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
        };
    }

    componentDidMount() {
        this.props.getPayState();//状态
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getConvertibleList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    //创建规则
    _createRules = () => {
        this.setState({
            currentOpenMode: 'creat',
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { rulesNum, ruleState, pageNum, pageSize } = this.state;
        this.searchCache = {
            ruleName: rulesNum,
            status: ruleState.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getConvertibleList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发 
    _ruleresetHandle = () => {
        this.setState({ rulesNum: '', ruleState: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作功能回调 
    _actionClickHandle = (record, type, value) => {
        switch (type) {
            case 'edit'://编辑
                this.setState({
                    currentOpenMode: 'edit',
                    openModeParam: record
                });
                break;
            case 'switch'://状态
                http('/settle/starlightExchange/ruleSwitch', {
                    status: value === false ? 0 : 1,
                    ruleId: record.id
                }, 'POST')
                    .then(() => {
                        this.props.getConvertibleList(this.searchCache);
                        message.success('更新成功。');
                    }).catch((reject = {}) => {
                        message.error(reject.message);
                    });
                break;
            case 'delete'://删除
                showConfirm('确定要删除此规则吗？', '你还要继续吗？', () => {
                    http('/settle/starlightExchange/deleteRule', {
                        ruleIds: [record.id]
                    }, 'POST')
                        .then(() => {
                            this.props.getConvertibleList(this.searchCache);
                            message.success('删除成功。');
                        }).catch((reject = {}) => {
                            message.error(reject.message);
                        });
                });
                break;
            default: return null;
        }
    }


    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'creat':
                return <ConverRulesModal title='创建魅力值兑换规则' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'edit':
                return <ConverRulesModal title='编辑魅力值兑换规则' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        if (status === true) {
            this.props.getConvertibleList(this.searchCache);
        }
        this.setState({ currentOpenMode: '', openModeParam: {} });
    }

    render() {
        const { pageNum, pageSize, resultTotal, dataList } = this.props.tableList;
        const { rulesNum, ruleState, } = this.state;
        return (
            <KeepAlive id='10' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.pro_commont}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='规则名称'
                                    placeholder='请输入'
                                    bindThis={this}
                                    bindName='rulesNum'
                                    value={rulesNum}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='规则状态'
                                    placeholder='请选择'
                                    renderData={this.props.states}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={ruleState.value}
                                    showSearch={true}
                                    bindThis={this}
                                    bindName='ruleState'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._ruleresetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '145px' }} label='创建魅力值兑换规则' onClick={this._createRules} />
                    </div>
                    <div className={`${styles.tableContainer}`}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record) => record.id}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    <div>{this._renderMode()}</div>
                </div>
            </KeepAlive>
        );
    }
}

ConvertibleRules.propTypes = {
    history: PropTypes.object, // 路由history

    tableList: PropTypes.object,
    getConvertibleList: PropTypes.func,//表格数据(action)
    states: PropTypes.array,
    getPayState: PropTypes.func,//状态数据(action)

};

const mapStateToProps = (state) => ({
    tableList: state.showset.convertibleList, // 表格数据
    states: state.showset.payState, // 状态
});

export default connect(mapStateToProps, { ...model.actions })(ConvertibleRules);
