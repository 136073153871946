/**
 * 区域公司订单收入统计
 */
import React from 'react';
import { Tag, message } from 'antd';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XDatePicker, XOKButton, XCancelButton, XSelect, XInput, XInputNum } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import TableContent from './components/TableContent';
import model from '@/reducers/statistical/companyincome/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { isPlat, isRE } from '@/assets/js/authType';
import { SearchBox, SearchItem } from '@/components/Layout';

const tags = [{ id: 1, color: '#f50', value: '本周' }, { id: 2, color: '#2db7f5', value: '本月' }, { id: 3, color: '#87d068', value: '本季' }, { id: 4, color: '#10D5e9', value: '全年' }, { id: 5, color: '#108ee9', value: '全部' }];

class Main extends React.Component {

    state = {
        startDate: undefined,
        endDate: undefined,
        curTagID: 5,
        period: 'all',
        operation: {},
        company: {},
        orderId: '',
    };

    componentDidMount() {
        const { match } = this.props;
        const companyId = match.params.id;
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getOperationSelect(); // 获取运营中心下拉列
        this.props.getCompanySelect(); // 获取区域公司下拉列
        if (companyId && companyId != ':id') {
            this.setState({ company: { id: parseInt(companyId) } });
        }
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, period, startDate, endDate, operation, company, orderId } = this.state;
        let query = {};
        if (startDate && endDate) {
            query = {
                startDate,
                endDate,
                id: orderId,
                operationsCenterId: operation.id,
                areaCompanyId: company.id,
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            this.setState({ period: undefined, curTagID: 0 });
        } else if (period) {
            query = {
                period,
                id: orderId,
                operationsCenterId: operation.id,
                areaCompanyId: company.id,
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            this.setState({ startDate: undefined, endDate: undefined });
        } else {
            message.error('请选择付款日期范围');
        }
        this.props.getReport(query);
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ startDate: undefined, endDate: undefined, curTagID: 4, period: 'year', operation: {}, company: {}, orderId: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 渲染Tags
    _renderTags = () => {
        const { curTagID } = this.state;
        return (
            <div style={{ marginLeft: '20px' }}>
                {tags.map((item, index) => {
                    let color = curTagID == item.id ? item.color : '';
                    return <Tag style={{ width: '50px', textAlign: 'center', cursor: 'pointer' }} key={index} color={color} onClick={() => { this._tagClick(item.id); }}>{item.value}</Tag>;
                })}
            </div>
        );
    }

    // 点击Tags
    _tagClick = (id) => {
        this.setState({ curTagID: id, startDate: undefined, endDate: undefined }, () => {
            switch (id) {
                case 1:
                    this.setState({ period: 'week' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 2:
                    this.setState({ period: 'month' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 3:
                    this.setState({ period: 'quarter' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 4:
                    this.setState({ period: 'year' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 5:
                    this.setState({ period: 'all' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
            }
        });
    }

    // 表格操作
    _tableAction = (type, record) => {
        const { history } = this.props;
        switch (type) {
            case 'detail':
                history.push({
                    pathname: '/home/ordermanage/productorder/orderdetail',
                    search: `?buyerId=${record.buyerId}&&orderId=${record.id}`,
                });
                break;
        }
    }

    render() {
        const { startDate, endDate, operation, company, orderId } = this.state;
        const { report, operationSelect, companySelect } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            {isPlat() ? <XSelect
                                label='归属运营中心'
                                placeholder='请选择归属运营中心'
                                renderData={operationSelect}
                                dataIndex='companyName'
                                keyIndex='id'
                                value={operation.companyName}
                                bindThis={this}
                                bindName='operation'
                                showSearch={true}
                            /> : null}
                        </SearchItem>
                        <SearchItem>
                            {!isRE() ? <XSelect
                                label='归属区域公司'
                                placeholder='请选择归属区域公司'
                                renderData={companySelect}
                                dataIndex='companyName'
                                keyIndex='id'
                                value={company.id}
                                bindThis={this}
                                bindName='company'
                                showSearch={true}
                                isOptionBindID={true}
                            /> : null}
                        </SearchItem>
                        <SearchItem>
                            <XInputNum
                                label='订单号'
                                placeholder='请输入订单号'
                                value={orderId}
                                bindThis={this}
                                bindName='orderId'
                                min={0}
                                precision={0}
                                maxLength={16}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='付款日期'
                                placeholder='请选择开始日期'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                label=''
                                placeholder='请选择结束日期'
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            {this._renderTags()}
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent renderData={report} paginationChange={this._paginationChange} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getReport: PropTypes.func,
    report: PropTypes.object,
    getOperationSelect: PropTypes.func,
    operationSelect: PropTypes.array,
    getCompanySelect: PropTypes.func,
    companySelect: PropTypes.array,
};
const mapStateToProps = (state) => ({
    report: state.companyincome.getReport,
    operationSelect: state.companyincome.getOperationSelect,
    companySelect: state.companyincome.getCompanySelect,
});
export default connect(mapStateToProps, { ...model.actions, keepSecondNavBreadcrumb })(Main);