import { createModel } from '@/store/tools';

const model = {
    namespace: 'goodshare',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getGoodshareList': T('getGoodshareList'),
        },
        actions: {
            'getGoodshareList': A('getGoodshareList'),
        },
        sagas: {
            'getGoodshareList': S('getGoodshareList', '/boss/order/distribution/distributionGoodsRankExportList'),//分销销售商品排行
        },
        reducers: {
            'goodshareList': R('getGoodshareList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);