import * as Audit from './actiontypes';

/**
 * 存储'视频审核列表'数据源
 * @param {*} state 
 * @param {*} action 
 */

const autonymListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
const videoAuditInfo = {
    state: {

    },
    getSpokesmanTaskResp: {
        rewardType: {

        }
    },
    goodsList: []
};
export const videoAuditList = (state = autonymListInital, action) => {
    switch (action.type) {
        case Audit.SET_VIDEOAUDIT_LIST:
            return action.payload;
        default:
            return state;
    }
};

export const videoAuditDetail = (state = videoAuditInfo, action) => {
    switch (action.type) {
        case Audit.SET_VIDEOAUDIT_DETAIL:
            return action.payload;
        default:
            return state;
    }
};

export const auditStatusSelect = (state = [], action) => {
    switch (action.type) {
        case Audit.SET_VIDEOAUDIT_SELE:
            return action.payload;
        default:
            return state;
    }
};

export const getEnumSystemType = (state = [], action) => {
    switch (action.type) {
        case Audit.SET_ENUM_SYSTEMTYPE:
            return action.payload;
        default:
            return state;
    }
};