/**
 * 付款记录
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, XInput, XDatePicker } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import model from '@/reducers/storehousefinancialmanage/otherpay/model';
import KeepAlive from '@/routes/KeepAlive';
import TableContent from './components/TableContent';
import DeleteModal from './components/DeleteModal';
import BatchPay from './components/BatchPay';
import SinglePay from './components/SinglePay';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            batchNo: '', // 付款批次号
            receiptName: '', // 收款人
            receiptPhone: '', // 收款人手机号
            receiptAccountNo: '', // 收款账号

            gsItem: {}, // 签约公司
            tdItem: {}, // 签约通道

            ztItem: {}, // 付款状态
            jyItem: {}, // 打款校验
            beginRemitTime: undefined, // 开始时间
            endRemitTime: undefined, // 结束时间

            showDelete: false, // 显示删除
            showBatchPay: false, // 显示上传支付
            showSinglePay: false, // 显示密码支付
            param: null, // 删除、付款传递的参数

            pageNum: autoPageNum,
            pageSize: autopageSize
        };

        const { type, item } = props.searchPrama;

        if (type == 'batchNo') {
            this.state.batchNo = item.batchNo;
        } else if (type == 'failedStatusCount') {
            this.state.batchNo = item.batchNo;
            this.state.ztItem = {
                code: 'FAILED',
                value: '汇款失败'
            };
        } else if (type == 'unsignedVerifyStatusCount') {
            this.state.batchNo = item.batchNo;
            this.state.jyItem = {
                code: 'UNSIGNED',
                value: '未签约'
            };
        } else if (type == 'maxAmountVerifyStatusCount') {
            this.state.batchNo = item.batchNo;
            this.state.jyItem = {
                code: 'MAX_AMOUNT',
                value: '当月已超限额'
            };
        } else if (type == 'successVerifyStatusCount') {
            this.state.batchNo = item.batchNo;
            this.state.jyItem = {
                code: 'SUCCESS',
                value: '校验通过'
            };
        }
    }


    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getYouFuPayVerifyStatusEnum();
        this.props.getRemitStatusEnum();
        this.props.getYouFuPayMerEnum();
        this.props.getYouFuPayOutServiceNameEnum();
    }

    // 字符串转数组
    _strToArr = (arg) => {
        let arr = [];
        let str = arg;
        if (Array.isArray(str)) {
            if (str.length > 0 && str[0]) {
                arr = str;
            }
        } else if (str) {
            str = str.replace('，', ',');
            arr = str.split(',');
        }
        return arr;
    }

    // 查询
    _searchHandle = (useCache) => {
        const { batchNo, receiptName, receiptPhone, receiptAccountNo, ztItem, jyItem, gsItem, tdItem, beginRemitTime, endRemitTime, pageNum, pageSize } = this.state;
        const query = {
            batchNo,
            receiptName,
            receiptPhone,
            receiptAccountNo,
            status: ztItem.code,
            verifyStatus: jyItem.code,
            platCompanyId: gsItem.code,
            levyId: tdItem.code,
            beginRemitTime,
            endRemitTime,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };

        this.props.getQueryOutRemitList(query);
        KeepAlive.saveCache({ batchNo, receiptName, receiptPhone, receiptAccountNo, ztItem, jyItem, gsItem, tdItem, beginRemitTime, endRemitTime, pageNum, pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ batchNo: '', receiptName: '', receiptPhone: '', receiptAccountNo: '', ztItem: {}, jyItem: {}, gsItem: {}, tdItem: {}, beginRemitTime: undefined, endRemitTime: undefined, pageNum: autoPageNum, pageSize: autopageSize }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (type, item) => {
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '付款记录详情',
                    path: '/home/storehousefinancialmanage/otherpay/detail'
                });
                this.props.history.push({
                    pathname: '/home/storehousefinancialmanage/otherpay/detail/' + item.id
                });
                break;
            case 'delete':
                this.setState({ showDelete: true, param: item });
                break;
            case 'pay':
                this.setState({ showSinglePay: true, param: item });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    // 批量支付
    _batchPayHandle = () => {
        this.setState({ showBatchPay: true });
    }

    render() {
        const { batchNo, receiptName, receiptPhone, receiptAccountNo, jyItem = {}, ztItem = {}, gsItem = {}, tdItem = {}, beginRemitTime, endRemitTime, param, showDelete, showBatchPay, showSinglePay } = this.state;
        const { remitStatusEnum, youFuPayVerifyStatusEnum, youFuPayMerEnum, youFuPayOutServiceNameEnum, queryOutRemitList } = this.props;
        return (
            <KeepAlive id='pay' render={(state) => {
                if (!this.state.__aliveInit) {
                    const { type } = this.props.searchPrama;
                    if (!type) {
                        this.setState({ ...state });
                    }
                    this.setState({ __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='付款批次号'
                                placeholder='请输入16位数字服务单号'
                                value={batchNo}
                                bindThis={this}
                                bindName='batchNo'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='收款人'
                                placeholder='请输入20字以内收款方姓名'
                                value={receiptName}
                                bindThis={this}
                                bindName='receiptName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='收款人手机号'
                                placeholder='请输入11位手机号'
                                value={receiptPhone}
                                bindThis={this}
                                bindName='receiptPhone'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='收款账号'
                                placeholder='请输入收款人的银行账号'
                                value={receiptAccountNo}
                                bindThis={this}
                                bindName='receiptAccountNo'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='付款公司'
                                showSearch
                                placeholder='请选择'
                                renderData={youFuPayMerEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={gsItem.value}
                                bindThis={this}
                                bindName='gsItem'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='付款通道'
                                showSearch
                                placeholder='请选择'
                                renderData={youFuPayOutServiceNameEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={tdItem.value}
                                bindThis={this}
                                bindName='tdItem'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='付款状态'
                                showSearch
                                placeholder='请选择'
                                renderData={remitStatusEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={ztItem.value}
                                bindThis={this}
                                bindName='ztItem'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='打款校验'
                                showSearch
                                placeholder='请选择'
                                renderData={youFuPayVerifyStatusEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={jyItem.value}
                                bindThis={this}
                                bindName='jyItem'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                placeholder='请选择开始时间'
                                label='打款日期'
                                value={beginRemitTime}
                                bindThis={this}
                                bindName='beginRemitTime'
                                isFormat={true}
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                placeholder='请选择结束时间'
                                value={endRemitTime}
                                bindThis={this}
                                bindName='endRemitTime'
                                isFormat={true}
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent renderData={queryOutRemitList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {
                        showDelete ? <DeleteModal param={param} close={(type) => {
                            if (type == 'update') {
                                this._searchHandle('useCache');
                            }
                            this.setState({ showDelete: false });
                        }} /> : null
                    }
                    {
                        showBatchPay ? <BatchPay param={param} close={(type) => {
                            if (type == 'update') {
                                this._searchHandle('useCache');
                            }
                            this.setState({ showBatchPay: false });
                        }} /> : null
                    }
                    {
                        showSinglePay ? <SinglePay param={param} close={(type) => {
                            if (type == 'update') {
                                this._searchHandle('useCache');
                            }
                            this.setState({ showSinglePay: false });
                        }} /> : null
                    }
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getYouFuPayMerEnum: PropTypes.func, // 枚举：签约公司
    youFuPayMerEnum: PropTypes.array,
    getYouFuPayOutServiceNameEnum: PropTypes.func, // 枚举：签约通道
    youFuPayOutServiceNameEnum: PropTypes.array,
    getRemitStatusEnum: PropTypes.func, // 枚举：付款状态
    remitStatusEnum: PropTypes.array,
    getYouFuPayVerifyStatusEnum: PropTypes.func, // 枚举：打款校验
    youFuPayVerifyStatusEnum: PropTypes.array,
    getQueryOutRemitList: PropTypes.func, // 列表：打款记录
    queryOutRemitList: PropTypes.object,
    searchPrama: PropTypes.object,
};

const mapStateToProps = (state) => ({
    remitStatusEnum: state.otherpay.getRemitStatusEnum,
    youFuPayVerifyStatusEnum: state.otherpay.getYouFuPayVerifyStatusEnum,
    queryOutRemitList: state.otherpay.getQueryOutRemitList,
    youFuPayMerEnum: state.otherpay.getYouFuPayMerEnum,
    youFuPayOutServiceNameEnum: state.otherpay.getYouFuPayOutServiceNameEnum,
});

export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);