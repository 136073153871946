import * as Audit from './actiontypes';

/**
 * 获取'视频审核表格'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 */
export const getPornographyList = (params) => ({
    type: Audit.GET_PORNOGRAPHY_LIST,
    payload: params
});
export const getPornographyType = () => ({
    type: Audit.GET_PORNOGRAPHY_TYPE,
    payload: {
        pageNum: 1,
        pageSize: 100
    }
});