import React from 'react';
import styles from './OrderTable.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip, tooltipClick, cellWidth } from '@/components/TableCell';
import { isPlat } from '@/assets/js/authType';
import { encryptedTelNumber } from '@/assets/js/utils';

class OrderTable extends React.Component {

    state = {
        selectedRowKeys: []
    }

    franchiseCell = (t, r) => {
        if (!t) return '-';
        if (isPlat()) {
            return <Tooltip
                title={<div>
                    {!!r.recommendedIntegralNumber && <p>推荐人：{r.recommendedIntegralNumber}</p>}
                    {!!r.buyerIntegralNumber && <p>购买人：{r.buyerIntegralNumber}</p>}
                    {!!r.areaIntegralNumber && <p>区域公司：{r.areaIntegralNumber}</p>}
                    {!!r.operaIntegralNumber && <p>运营中心：{r.operaIntegralNumber}</p>}
                </div>}>
                <span className={styles.text}>{t ? t : '-'}</span>
            </Tooltip>;
        }
        return tooltip(t);
    }

    _takeColumns = () => ([
        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: cellWidth.normal,
            align: 'center',
            render: (text, item) => tooltipClick(text, () => {
                this.props.tableAction(item.orderId, 'info', item);
            })
        },
        {
            title: '服务名称',
            dataIndex: 'serverName',
            key: 'serverName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '开通帐号',
            dataIndex: 'openAccountName',
            key: 'openAccountName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (content) => {
                return isPlat() ? content : encryptedTelNumber(content);
            }
        },
        {
            title: '业务推广人',
            dataIndex: 'businessPromoterName',
            key: 'businessPromoterName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '推广人帐号',
            dataIndex: 'promoterAccountName',
            key: 'promoterAccountName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (content) => {
                return isPlat() ? content : encryptedTelNumber(content);
            }
        },
        {
            title: '推荐人类型',
            dataIndex: 'promoterSource',
            key: 'promoterSource',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
            // render: (text) => {
            //     return <div>{text && text.value}</div>;
            // }
        },
        // {
        //     title: '账户类型',
        //     dataIndex: 'sourceType',
        //     key: 'sourceType',
        //     width: cellWidth.normal,
        //     align: 'center',
        //     render: (text) => {
        //         return <div>{text && text.value}</div>;
        //     }
        // },
        {
            title: '赠送特权值',
            dataIndex: 'totalIntegralNumber',
            key: 'totalIntegralNumber',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: this.franchiseCell
        },
        {
            title: '订单金额(元)',
            dataIndex: 'totalOrderFee',
            key: 'totalOrderFee',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '实付金额(元)',
            dataIndex: 'payFee',
            key: 'payFee',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '订单状态',
            dataIndex: 'statusStr',
            key: 'statusStr',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
            // render: (text) => {
            //     return <div>{text && text.value}</div>;
            // }
        },
        {
            title: '支付通道',
            dataIndex: 'payAccessType',
            key: 'payAccessType',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付方式',
            dataIndex: 'payType',
            key: 'payType',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
            // render: (text) => {
            //     return <div>{text && text.value}</div>;
            // }
        },
        {
            title: '付款时间',
            dataIndex: 'payTime',
            key: 'payTime',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        //     title: '操作',
        //     dataIndex: 'key',
        //     key: 'key',
        //     align: 'center',
        //     width: cellWidth.normal,
        //     fixed: 'right',
        //     render: (text, record) => this._renderActionCell(record.id, record)
        // }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    _renderActionCell = (key, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = () => {
        const { selectedRowKeys } = this.state;
        return {
            selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.setState({ selectedRowKeys });
                this.props.tableAction(null, 'batch', [...selectedRowKeys]);
            }
        };
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
        this.props.tableAction(null, 'batch', []);
    }

    render() {
        const { selectedRowKeys } = this.state;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection()}
                    rowKey={record => record.orderId}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string
};

export default OrderTable;
