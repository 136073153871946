import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { XInput, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import CreateClassify from './modal/CreateClassify';
import EditClassify from './modal/EditClassify';
import { message } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import { getProductClassify } from '@/reducers/productmanage/classify/actions';

class IntraCityClassify extends Component {

    static propTypes = {
        history: PropTypes.object,
        getProductClassify: PropTypes.func,
        tableSource: PropTypes.object
    }

    state = {
        currentOpenMode: '', // 当前打开的弹框
        openModeParam: null, // 弹框传参
        categoryName: '',
        expandedKeys: [],
        keys: []
    };


    // 查询
    _searchHandle = (useCache) => {
        const { categoryName, pageNum, pageSize } = this.state;
        this.searchCache = {
            categoryName, categorySubType: 'CITY',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getProductClassify(this.searchCache);
        KeepAlive.saveCache(this.searchCache); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ categoryName: '', }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //打开弹窗
    _renderMode = () => {
        const { currentOpenMode, openModeParam } = this.state;
        switch (currentOpenMode) {
            case 'create':
                return <CreateClassify closeMode={this._closeMode} openModeParam={openModeParam} />;
            case 'edit':
                return <EditClassify closeMode={this._closeMode} openModeParam={openModeParam} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', openModeParam: null }, () => {
            if (update) {
                this._searchHandle('useCache');
            }
        });
    }

    //请求函数
    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST')
            .then(() => {
                setTimeout(() => {
                    this._searchHandle('useCache');
                    message.success(winTxt);
                }, 1000);
            })
            .catch(e => {
                message.error(e.message);
            });
    }

    //表格操作
    _tableOperate = (id, type, rowValue) => {
        switch (type) {
            case 'addSublevel':
                this.setState({ currentOpenMode: 'create', openModeParam: { id: rowValue.id, levelNum: rowValue.categoryGrade } });
                break;
            case 'edit':
                this.setState({ currentOpenMode: 'edit', openModeParam: { ...rowValue } });
                break;
            case 'delete':
                showConfirm('您确定删除该分类？', '', () => {
                    this._sendReq('/goods/category/delCategory', { id, grade: rowValue.categoryGrade, categorySubType: 'CITY', categoryType: 'MALL' }, '删除分类操作成功');
                });
                break;
            case 'sort': // 分类排序
                var fromItem = rowValue.from;
                var toItem = rowValue.to;
                var sortType = rowValue.type;
                if (!toItem) {
                    if (sortType == 'up') {
                        message.info('已经在第一级了');
                    } else {
                        message.info('已经在最后一级了');
                    }
                } else {
                    this._sendReq('/goods/category/sort', {
                        sortList: [{
                            categoryGradeNum: toItem.sortNo,
                            categoryId: fromItem.id
                        },
                        {
                            categoryGradeNum: fromItem.sortNo,
                            categoryId: toItem.id
                        }]
                    }, '排序操作成功');
                }
        }
    }

    //添加一级分类
    _createNumOne = () => {
        this.setState({ currentOpenMode: 'create', openModeParam: { levelNum: 1, id: 0 } });
    }

    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    //keys
    _expandAll = () => {
        const { tableSource: { dataList } } = this.props;
        let expandedKeys = [];
        for (let item of dataList) {
            expandedKeys.push(item.id);
            if (item.categoryInfoList) {
                for (let cc of item.categoryInfoList) {
                    expandedKeys.push(cc.id);
                }
            }
        }
        this.setState({ expandedKeys });
    }

    _closeAll = () => {
        this.setState({ expandedKeys: [] });
    }

    _handleOnExpand = (expanded, record) => {
        console.log(expanded);
        const { expandedKeys } = this.state;
        if (expanded) {
            expandedKeys.push(record.id);
            this.setState({ expandedKeys });
        } else {
            this.setState({ expandedKeys: expandedKeys.filter(item => item != record.id) });
        }
    }

    render() {
        const { tableSource } = this.props;
        const { categoryName, expandedKeys } = this.state;
        return (
            <KeepAlive id='intraCityClassify' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='一级分类名称'
                                placeholder='请输入分类名称'
                                bindThis={this}
                                bindName='categoryName'
                                value={categoryName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '90px' }} label='全部展开' onClick={this._expandAll} />
                        <XOKButton style={{ width: '90px', marginLeft: '20px' }} label='全部收起' onClick={this._closeAll} />
                        <XOKButton style={{ width: '120px', marginLeft: '20px' }} label='添加一级分类' onClick={this._createNumOne} />
                    </div>
                    <TableContent
                        paginationChange={this._paginationChange}
                        renderData={tableSource}
                        tableAction={this._tableOperate}
                        handleOnExpand={this._handleOnExpand}
                        expandedKeys={expandedKeys} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}
export default connect(state => ({
    tableSource: state.classify.productClassifyList, // 商品分类
}), { getProductClassify })(IntraCityClassify);