/**
 * 银联入网查询
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import Main from './pages/main';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XTabNav } from '@/components/xqxc_ui';
import LianlianPay from './lianlianpay';
import HuiFuPay from './huifupay';
import { setSessionStorage, getSessionStorage, removeSessionStorage } from '@/assets/js/storage';

const tabList = [
    { id: '2', label: '连连支付' },
    { id: '3', label: '汇付入网' },
    { id: '1', label: '银联入网' },
];
class merchantUnion extends React.Component {

    state = {
        currentTab: '2',
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航只保留到2级
        if (getSessionStorage('shglrwcxlbtabId')) {
            this.setState({ currentTab: getSessionStorage('shglrwcxlbtabId') });
            removeSessionStorage('shglrwcxlbtabId');
        }
    }

    render() {
        const { currentTab } = this.state;
        const { history } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav
                    activedID={currentTab}
                    renderData={tabList}
                    onChange={teb => {
                        setSessionStorage('shglrwcxlbtabId', teb);
                        this.setState({ currentTab: teb });
                    }} />
                {currentTab == 1 && <Main history={history} />}
                {currentTab == 2 && <LianlianPay history={history} />}
                {currentTab == 3 && <HuiFuPay history={history} />}
            </div>
        );
    }
}

merchantUnion.propTypes = {
    history: PropTypes.object, // router history
    keepSecondNavBreadcrumb: PropTypes.func // 保持两级面包屑导航
};

export default connect(null, { keepSecondNavBreadcrumb })(merchantUnion);