/**
 * 秀场管理 - 频道分类异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

// 获取'音乐分类'表格
export const getCustomFittingTableSource = function* () {
    yield takeEvery(T.GET_TABLESOURCE, function* ({ payload }) {
        try {
            let result = yield http('/custom/baseComponent/getList', payload.requestProps); // action.payload为传入的参数对象
            yield put({
                type: T.SET_TABLESOURCE,
                payload: result.result || {},
                requestProps: payload.requestProps
            });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};