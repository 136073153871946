/**
 * 【日结账单】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, XInput, XDatePicker } from '@/components/xqxc_ui';
import { Select, Modal, Form, Input, Button, message } from 'antd';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/reconciliationdata/model';
import AdvertTable from './components/AdvertTable';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import http from '@/assets/api/http';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { regFenToYuan,regYuanToFen, priceUppercase } from '@/util/money';

const { Option } = Select;

class Main extends React.Component {

    state = {
        status: {},
        payItem: [],
        payValue: 'LL_PAY',
        balance: null,
        modalVisible: false,
        upperCase: ''
    }

    formRef = React.createRef(null);

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getUnionMerchantSettleStatusEnum();
        this._getAccessType();
    }

    _getAccessType = async () => {
        try {
            let data = await http('/unionPlatWithdrawal/getAccessType');
            if(data.result) {
                this.setState({
                    ...this.state,
                    payItem: data.result
                }, () => {
                    console.log('result:', this.state.payItem);
                });
            }  
        } catch(err) {
            console.log(err);
        } 
    }
    // 查询
    _searchHandle = (useCache) => {
        const { startDate, endDate, id, pageNum, pageSize, status } = this.state;
        const query = {
            startDate,
            endDate,
            id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            status: status.code,
        };
        this.props.getPlatformDrawalList(query);
        KeepAlive.saveCache({ status, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ startDate: undefined, endDate: undefined, id: '', pageNum: 1, pageSize: 10, merchant: {}, status:{} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    _tableAction = (id, type, item) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '对账详情',
                    path: {
                        pathname: '/home/storehousefinancialmanage/dailystatement/detail',
                        search: `?gmtAccounting=${item.gmtAccounting}&&merchantId=${item.merchantId}`
                    }
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/dailystatement/detail',
                    search: `?gmtAccounting=${item.gmtAccounting}&&merchantId=${item.merchantId}`
                });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        }, () => {
            this._searchHandle('useCache');
        });
    }
    _queryBalance = async () => {
        try {
            let data = await http('/unionPlatWithdrawal/getPlatBalance', {
                accessType: this.state.payValue
            });
            this.setState({
                balance: data.result
            });
        }catch(err) {
            console.log(err);
        }
        
    }

    _handleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if(!err) {
                http('/unionPlatWithdrawal/withdrawal', {
                    accessType: this.state.payValue,
                    password: fieldsValue.password,
                    amount: regYuanToFen(fieldsValue.amount)
                }).then(() => {
                    this.setState({
                        modalVisible: false
                    });
                    message.success('提现成功!');
                }).catch(err => {
                    message.error(err.message);
                });
            }
        });
        // this.formRef.current.validateFields().then((fields) => {
        //     console.log(fields);
        //     http('/unionPlatWithdrawal/withdrawal', {
        //         accessType: this.state.payValue,
        //         password: fields.password,
        //         amount: fields.amount
        //     }).then(res => {
        //         this.setState({
        //             visible: false
        //         });
        //         // this.props.closeMode();
        //         // message.success('操作成功');
        //     });
            
        // }).catch(err => {
        //     //console.log(err);
        //     Promise.resolve(err);
        // });
    }

    render() {
        const { id, startDate, endDate, status = {} } = this.state;
        const { unionMerchantSettleStatusEnum, platformDrawalList } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='提现单号'
                                placeholder='请输入'
                                value={id}
                                bindThis={this}
                                bindName='id'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                showSearch
                                placeholder='请选择状态'
                                renderData={unionMerchantSettleStatusEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                                bindThis={this}
                                bindName='status'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                placeholder='请选择开始时间'
                                label='提现日期'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat={true}
                            />
                            <XDatePicker
                                placeholder='请选择结束时间'
                                style={{ marginRight: 20 }}
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                        <SearchItem>
                            {/*<XSelect
                                style={{ marginLeft:'20px'}}
                                label="支付通道"
                                showSearch
                                placeholder='请选择'
                                renderData={payItem}
                                dataIndex='value'
                                keyIndex='code'
                                value={[]}
                                bindThis={this}
                                bindName='payItem'
                                isOptionBindID
                            />*/}
                            <div style={{marginLeft:'20px', color:'#252525'}}>支付通道</div>
                            <Select 
                                defaultValue="LL_PAY"
                                onChange={(val) => this.setState({
                                    payValue: val
                                })}
                                placeholder="请选择" 
                                style={{width:'150px', margin:'5px 0 0 10px'}}
                            >
                            {
                                this.state.payItem.length && this.state.payItem.map(item => <Option value={item.code} key={item.code}>{item.value}</Option>)
                            }
                            </Select>
                            <XOKButton
                                style={{width:'auto', marginLeft:'10px'}}
                                label='查询余额'
                                onClick={this._queryBalance}
                            />
                            {this.state.balance !== null ? <span style={{marginLeft:'15px'}}>余额：{regFenToYuan(this.state.balance)}元</span> : null}
                            <ButtonPerssion code='storehousefinancialmanage/platformdrawalrecords/pttx'>
                                <XOKButton 
                                    style={{marginLeft:'20px'}} 
                                    onClick={() => this.setState({
                                        modalVisible: true
                                    })} 
                                    label='提现' 
                                />
                            </ButtonPerssion>
                        </SearchItem>
                    </SearchBox>
                    <AdvertTable renderData={platformDrawalList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
                <Modal
                    width={450}
                    centered
                    title="提现"
                    visible={this.state.modalVisible}
                    onOk={this._handleOk}
                    onCancel={() => this.setState({modalVisible: false, upperCase: ''})}
                    destroyOnClose={true}
                    keyboard={true}
                    maskClosable={false}
                    footer={null}
                >
                    <div className={styles.container}>
                        <Form
                           labelCol={{ span: 6 }}
                           wrapperCol={{ span: 16 }}
                           ref={this.formRef}
                           onSubmit={this._handleOk}
                        >
                            <Form.Item
                                label="支付金额："
                                colon={false}
                                style={{marginBottom: 0}}
                              >
                              {getFieldDecorator('amount', {
                                    rules:[{
                                        required: true,
                                        message: '请输入支付金额',
                                    },
                                    {
                                        pattern: /^\d+?(\.\d+?)?$/,
                                        message: '金额只能为数字类型'
                                    }]
                                }
                            )(
                            <Input autoComplete="off" onChange={({target}) => {
                                if(target.value && /^\d+?(\.\d+?)?$/.test(target.value)) {
                                   this.setState({
                                     upperCase: priceUppercase(target.value)
                                   }); 
                                }
                                else {
                                   this.setState({
                                     upperCase: ''
                                   });  
                                }
                                
                            }} />
                        )}
                                
                            </Form.Item>
                            <Form.Item
                                label="大写金额："
                                colon={false}
                                style={{marginBottom: 0}}
                            >
                                <p style={{ lineHeight:1.5, margin:'8px 0'}}>{this.state.upperCase}</p>
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                label="支付密码："
                              >
                                {getFieldDecorator('password', {
                                    rules: [{
                                        required: true,
                                        message: '请输入支付密码',
                                    }],
                            })(
                            <Input.Password autoComplete="new-password" />
                        )}
                            </Form.Item>
                            <div style={{ textAlign: 'right'}}>
                                <Button type='cancel' style={{marginRight:10}} onClick={() => this.setState({
                                    modalVisible: false,
                                    upperCase: ''
                                })}>取消</Button>
                                <Button type='primary' onClick={this._handleOk}>保存</Button>
                            </div>
                        </Form>
                    </div>
                </Modal>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    form: PropTypes.object.isRequired, // 校验
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getUnionMerchantSettleStatusEnum: PropTypes.func,
    unionMerchantSettleStatusEnum: PropTypes.array,
    getPlatformDrawalList: PropTypes.func,
    platformDrawalList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    unionMerchantSettleStatusEnum: state.reconciliationdata.unionMerchantSettleStatusEnum,
    platformDrawalList: state.reconciliationdata.platformDrawalList,
});

const Platformdrawalrecords = Form.create({ name: 'create-form' })(Main);

export default connect(mapStateToProps, {
    addOneBreadcrumbPath, keepSecondNavBreadcrumb,
    ...model.actions
})(Platformdrawalrecords);