/**
 *  实名认证审核
 * */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
// import { XTabNav } from '@/components/xqxc_ui';
import Member from './pages/main';
import Facilitator from './pages/facilitator';

class Nameauthentication extends Component {

    state = {
        tabs: [
            { id: 1, label: '会员' },
            { id: 2, label: '服务商' },
        ],
        curTabID: 1
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, history: { location } } = this.props;
        keepSecondNavBreadcrumb();
        if (location.curTabID) {
            this.setState({ curTabID: Number(location.curTabID) });
        }
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        const { curTabID } = this.state;
        const { history } = this.props;
        switch (curTabID) {
            case 1:
                return <Member curTabID={curTabID} history={history} />;
            case 2:
                return <Facilitator curTabID={curTabID} history={history} />;
            default:
                return <Member curTabID={curTabID} history={history} />;
        }
    };

    render() {
        // const { tabs, curTabID } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                {/* <XTabNav renderData={tabs} onChange={tab => {
                    this.setState({ curTabID: tab });
                }} activedID={curTabID} /> */}
                {/* <div className={styles.flexBoxContainer} style={{ marginTop: '20px' }}> */}
                    {this._renderPage()}
                {/* </div> */}
            </div>
        );
    }
}

Nameauthentication.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { keepSecondNavBreadcrumb })(Nameauthentication);