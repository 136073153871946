/**
 * 社区分类
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, XInput } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { SelectedProduct } from '../../modal';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { message } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import model from '@/reducers/graphicsmanage/graphicslist/model';
import { Mode } from '../../index';

class CommunityList extends React.Component {

    state = {
        feedTitle: '', // 社区标题
        home: {}, // 社区分类
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: '', // 当前需要打开的弹窗的入参
        pageNum: 1,//默认表格当前第几页
        pageSize: 20,//默认表格每页显示多少条
        communityParams: {
            id: '',
            communityBanned: 'communityBanned',
        },//禁播(区别视频，社区与参数ID值)
    }

    componentDidMount() {
        this.props.getCategoryList({ state: 1 });
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { feedTitle, home, pageNum, pageSize } = this.state;
        this.searchCache = {
            feedTitle,
            feedClassId: home.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getStatisticsTableList(this.searchCache);// 社区分类列表查询方法	
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ feedTitle: '', home: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case Mode.SELECTED:
                this.setState({ currentOpenMode: Mode.SELECTED, openModeParam: value.goodsList });
                break;
        }
    }

    // 渲染弹框
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.SELECTED:
                return <SelectedProduct visible data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status == 'delete') {
            http('/show/feed/deleteImageText', { id: this.state.openModeParam }, 'POST').then(() => {
                message.success('删除成功');
                this._searchHandle('useCache');
            }).catch((e) => {
                message.error(e.message);
            });
        } else if (status) {
            this._searchHandle('useCache');
        }

    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { feedTitle, home } = this.state;
        const { statisticsTableList, categoryList } = this.props;
        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='标题名称'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='feedTitle'
                                value={feedTitle}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='所属分类'
                                placeholder='请选择'
                                renderData={categoryList}
                                bindThis={this}
                                bindName='home'
                                dataIndex='className'
                                keyIndex='id'
                                value={home.className}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent paginationChange={this._paginationChange} renderData={statisticsTableList} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}


CommunityList.propTypes = {
    // history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func,

    categoryList: PropTypes.array,
    getCategoryList: PropTypes.func,

    getStatisticsTableList: PropTypes.func,
    statisticsTableList: PropTypes.array, // 表格数据源
};

const mapStateToProps = (state) => {
    return {
        categoryList: state.graphicslist.categoryList,
        statisticsTableList: state.graphicslist.statisticsTableList, // 表格数据源
    };
};

export default connect(mapStateToProps, { ...model.actions })(CommunityList);
