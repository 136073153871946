
import React from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
const Preview = ({ closeMode }) => {

    const footerStyle = {
        width: '100%',
        textAlign: 'right'
    };

    const footer = <div style={footerStyle}>
        <Button type='primary' onClick={() => closeMode(false)}>返货</Button>
    </div>;

    const container = {
        width:'100%',
        height:'600px',
        overflowY:'auto'
    };

    return <Modal
        width={960}
        centered
        title='预览模板'
        visible={true}
        confirmLoading={false}
        onOk={() => closeMode(false)}
        onCancel={() => closeMode(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        footer={footer}
    >
        <div style={container}></div>
    </Modal>;
};
Preview.propTypes = {
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};
export default Preview;