import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Switch } from 'antd';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { priceFenToYuanCell, ImgCell, tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';

class TypeTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    _columns = () => [
        {
            title: '手机号码',
            dataIndex: 'c1',
            key: 'c1',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '发布者',
            dataIndex: 'c2',
            key: 'c2 ',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '视频主题',
            dataIndex: 'c3',
            key: 'c3',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '封面',
            dataIndex: 'c4',
            key: 'c4',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: ImgCell
        },
        {
            title: '魅力值收入',
            dataIndex: 'c5',
            key: 'c5',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '销售商品分红',
            dataIndex: 'c6',
            key: 'c6',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '视频分类',
            dataIndex: 'c7',
            key: 'c7',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '观看人数',
            dataIndex: 'c8',
            key: 'c8',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '点赞数',
            dataIndex: 'c9',
            key: 'c9',
            sorter: true,
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '发布时间',
            dataIndex: 'c10',
            key: 'c10',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '首页推荐',
            dataIndex: 'c11',
            key: 'c11',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => this._switchCell(text, item, 'home')
        },
        {
            title: '频道推荐',
            dataIndex: 'c12',
            key: 'c12',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => this._switchCell(text, item, 'channel')
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'key',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            fixed: 'right',
            render: (id, record) => this._ableAction(id, record)
        }
    ];

    _ableAction = (id, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(record.key, 'INFO')}>查看详情</div>
                <div className={styles.item} onClick={() => tableAction(record.key, 'ABLE', record)}>播放</div>
                <div className={styles.item} onClick={() => tableAction(record.key, 'UNABLE')}>禁封</div>
                <div className={styles.item} onClick={() => tableAction(record.key, 'DELETE')}>删除</div>
            </div>
        );
    };

    // 表格项内容为开关
    _switchCell = (sw, item, type) => {
        const { tableAction } = this.props;
        let checked = true;
        if (sw == 0) {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={checked}
                    onChange={(value) => {
                        if (type == 'home') {
                            tableAction(item.key, 'switch_home', value);
                        } else {
                            tableAction(item.key, 'switch_channel', value);
                        }
                    }}
                />
            </div>
        );
    };

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        let tableSource = data.dataList.map((item) => {
            let tableCell = {};
            tableCell.key = item.id;
            tableCell.accountId = item.accountId;
            tableCell.playUrl = item.playUrl;
            tableCell.c1 = item.tel;
            tableCell.c2 = item.nickname;
            tableCell.c3 = item.feedTitle;
            tableCell.c4 = item.coverPage;
            tableCell.c5 = item.starlightNumber;
            tableCell.c6 = item.saleBonus;
            tableCell.c7 = item.feedClassName;
            tableCell.c8 = item.viewNumber;
            tableCell.c9 = item.praiseNumber;
            tableCell.c10 = item.gmtCreate;
            tableCell.c11 = item.isHomeReco;
            tableCell.c12 = item.isChannelReco;
            return tableCell;
        });
        return tableSource;
    }

    _sortChange = (pagination, filters, sorter) => {
        console.log(sorter);
        this.props.tableAction(null, 'sortNo', sorter);
    }

    render() {
        const { renderData, paginationChange, scrollY } = this.props;
        const { resultTotal, pageSize, pageNum } = this.props.renderData || {};

        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={record => record.key}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    onChange={this._sortChange}
                    scroll={{ x: 'max-content', y: scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange} />

            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func,
    scrollY: PropTypes.string
};

export default XTableHOC(TypeTable);