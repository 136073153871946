import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XDatePicker, XOKButton, XCancelButton, XInput } from '@/components/xqxc_ui';
import TableBox from './components/TableBox';
import UpdateRule from './modal/UpdateRule';
import model from '@/reducers/systemmanage/servicesetting/model';

class ExchangeRule extends Component {

    static defaultProps = {
        exchangeRules: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }
    }

    static propTypes = {
        history: PropTypes.object, // 路由history对象
        curTabID: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]),
        getExchangeRules: PropTypes.func,
        exchangeRules: PropTypes.object
    }

    state = {
        renderMode: '',
        modeParams: {},
        ruleName: '',
        startTime: null,
        endTime: null,
        pageNum: 1,
        pageSize: 30
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { ruleName, startTime, endTime, pageNum, pageSize } = this.state;
        this.searchCache = {
            ruleName, startTime, endTime,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getExchangeRules(this.searchCache);
        KeepAlive.saveCache({ ... this.searchCache }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            ruleName: '',
            startTime: null,
            endTime: null,
            pageNum: 1,
            pageSize: 30
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    //表格操作
    _tableOperae = ({ renderMode = '', modeParams = {} }) => {
        this.setState({ renderMode, modeParams });
    }

    // 渲染弹框
    _renderMode = () => {
        const { renderMode, modeParams } = this.state;
        switch (renderMode) {
            case 'update':
                return <UpdateRule
                    modeParams={modeParams}
                    closeMode={this._closeMode}
                />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ renderMode: '', modeParams: {} }, () => {
            update && this._searchHandle('useCache');
        });
    }

    render() {
        const { exchangeRules } = this.props;
        const { ruleName, startTime, endTime } = this.state;
        return (
            <KeepAlive id='showruleset' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer} style={{ marginTop: 10 }}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='规则名称'
                                placeholder='请输入规则名称'
                                bindThis={this}
                                bindName='ruleName'
                                value={ruleName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                allowClear
                                label='生效日期'
                                value={startTime}
                                bindThis={this}
                                bindName='startTime'
                                isFormat
                            />
                            <XDatePicker
                                allowClear
                                style={{ marginRight: 20 }}
                                value={endTime}
                                bindThis={this}
                                bindName='endTime'
                                isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: 160 }}
                            label='创建贡献值兑换规则'
                            onClick={() => this.setState({ renderMode: 'update' })}
                        />
                    </div>
                    <TableBox
                        renderMode={this._tableOperae}
                        renderData={exchangeRules}
                        paginationChange={this._paginationChange}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}
export default connect(state => ({
    exchangeRules: state.servicesetting.exchangeRules
}), {
    ...model.actions
})(ExchangeRule);