/**
 * 运营权回收
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, Input, message, Icon, Typography } from 'antd';
import styles from './UseOrBan.module.scss';

const { Text } = Typography;

const Recycle = ({ closeMode, openModeParam: { key = '' } }) => {

    const [onsub, setOnsub] = useState(false);

    const title = <><Icon type='warning' theme='filled' style={{ color: '#f00', fontSize: 24 }} /><Text style={{ paddingLeft: 10 }}>运营权回收</Text></>;

    // 点击确认
    const handleOk = () => {
        setOnsub(true);
        http('/admin/company/resetRegionalCompany/' + key, {}, 'GET')
            .then(res => {
                message.success(res.message);
                setOnsub(false);
                closeMode(true);
            })
            .catch(err => {
                message.error(err.message);
                setOnsub(false);
            });
    };

    return (
        <Modal
            width={480}
            centered
            title={title}
            visible={true}
            confirmLoading={onsub}
            onOk={handleOk}
            onCancel={() => closeMode(false)}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <div className={styles.container}>
                <p style={{fontSize:16}}>
                    &emsp;&emsp;运营权收回后，原运营账号只能查看到原运营数据，原服务商及会员关系将转至新的同名区域，未结账单仍可正常完成对账结算；运营权重新分配，请在确定后重新启用该区域。
                </p>
            </div>
        </Modal>
    );
};

Recycle.propTypes = {
    closeMode: PropTypes.func.isRequired,
    openModeParam: PropTypes.object.isRequired
};

export default Recycle;