/**
 * 【菜单列表】
 */
import React from 'react';
import styles from './index.module.scss';
import { message } from 'antd';
import PropTypes from 'prop-types';
import { XOKButton, XTabNav } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import TableContent from './components/TableContent';
import CreateModal from './modal/CreateModal';
import EditModal from './modal/EditModal';
import DeleteModal from './modal/DeleteModal';
import { getAllMenuList } from '@/reducers/permissionsmanage/menuconfigure/actions';
import http from '@/assets/api/http';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: [
                { id: 'PLAT', label: '总控后台菜单配置' },
                { id: 'MERCHANT', label: '商户后台菜单配置' },
            ],
            curTabID: 'PLAT',  // 当前显示的Tab页ID
            currentOpenMode: '', // 当前打开的弹框
            openModeParam: {}, // 弹框传参
        };
        this.searchCache = { systemType: 'PLAT' }; // 查询缓存
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getAllMenuList(this.searchCache);
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
        this.searchCache = { ...this.searchCache, systemType: tabID };
        this.props.getAllMenuList(this.searchCache);
    }

    // 表格操作
    _tableAction = (id, record, type) => {
        switch (type) {
            case 'edit':
                this.setState({ openModeParam: { id }, currentOpenMode: 'edit', audit: record });
                break;
            case 'forbid':
                http('/admin/menu/updateMenuState', { id, state: 0 }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('禁用成功！');
                        this.props.getAllMenuList(this.searchCache);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
            case 'start':
                http('/admin/menu/updateMenuState', { id, state: 1 }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('启用成功！');
                        this.props.getAllMenuList(this.searchCache);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
            case 'del':
                this.setState({ openModeParam: { id }, currentOpenMode: 'del', audit: record });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'new':
                return <CreateModal title='新建菜单' visible={true} closeMode={this._closeMode} systemType={this.state.curTabID} />;
            case 'edit':
                return <EditModal title='编辑菜单' visible={true} closeMode={this._closeMode} systemType={this.state.curTabID} id={this.state.openModeParam.id} />;
            case 'del':
                return <DeleteModal title='删除菜单' visible={true} closeMode={this._closeMode} systemType={this.state.curTabID} id={this.state.openModeParam.id} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' });
        if (update) {
            this.props.getAllMenuList(this.searchCache);
        }
    }

    // 新建菜单
    _newHandle = () => {
        this.setState({ currentOpenMode: 'new' });
    };

    render() {
        const { allMenuList } = this.props;

        return (
            <div id='autoheight' className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={styles.row2}>
                    <XOKButton
                        style={{ width: '100px' }}
                        label='新建菜单'
                        onClick={this._newHandle}
                    />
                </div>
                <TableContent curTabID={this.state.curTabID} renderData={allMenuList} tableAction={this._tableAction} />
                {this._renderMode()}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getAllMenuList: PropTypes.func, // 获取全部菜单列表
    allMenuList: PropTypes.array, // 全部菜单列表
};

const mapStateToProps = (state) => ({
    allMenuList: state.menuconfigure.getAllMenuList,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getAllMenuList })(Main);