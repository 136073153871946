import { combineReducers } from 'redux';
import {
    operationList,
    companyList,
    operationStatus,
    operationDetail,
    operationProvince,
    operationCity,
    operationArea,
    operationCenterSele,
    settleUnitSele,
    settleUnitTypeSele,
    provinceSele,
    citySele,
    typeEnum
} from './reducer';

export default combineReducers({
    operationList,
    companyList,
    operationStatus,
    operationDetail,
    operationProvince,
    operationCity,
    operationArea,
    operationCenterSele,
    settleUnitSele,
    settleUnitTypeSele,
    provinceSele,
    citySele,
    typeEnum
});