import * as T from './actiontypes';

//获取商品列表
export const getProductListData = (params = {}) => ({
    type: T.GET_PRODUCT_LISTDATA,
    payload: params
});

export const getProductStatus = () => ({
    type: T.GET_PRODUCT_STATUS,
    payload: {}
});

export const getProductUsername = () => ({
    type: T.GET_PRODUCT_USERNAME,
    payload: {}
});

export const getProductBusinessType = () => ({
    type: T.GET_PRODUCT_BUSINESSTYPE,
    payload: {}
});

export const getProductLabelSele = () => ({
    type: T.GET_PRODUCT_LABELSELE,
    payload: {}
});

export const getProductClassifySele = () => ({
    type: T.GET_PRODUCT_CLASSIFYSELE,
    payload: {}
});

export const getProductDetail = (id) => ({
    type: T.GET_PRODUCT_DETAIL,
    payload: { id }
});

export const getProductRecommend = (params = {}) => ({
    type: T.GET_PRODUCT_RECOMMEND,
    payload: params
});


