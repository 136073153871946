// 会员等级设置列表
export const GET_MEMBERGRADE_LIST = 'SYSTEMMANAGE/GET_MEMBERGRADE_LIST_MLY';
export const SET_MEMBERGRADE_LIST = 'SYSTEMMANAGE/SET_MEMBERGRADE_LIST_MLY';

// 会员等级设置详情
export const GET_MEMBERGRADE_DETAIL= 'SYSTEMMANAGE/GET_MEMBERGRADE_DETAIL_MLY';
export const SET_MEMBERGRADE_DETAIL = 'SYSTEMMANAGE/SET_MEMBERGRADE_DETAIL_MLY';

//会员等级设置优惠券下拉
export const GET_MEMBERGRADE_COUPONSELE= 'SYSTEMMANAGE/GET_MEMBERGRADE_COUPONSELE_MLY';
export const SET_MEMBERGRADE_COUPONSELE= 'SYSTEMMANAGE/SET_MEMBERGRADE_COUPONSELE_MLY';

//会员等级设置单选框
export const GET_MEMBERGRADE_RADIO= 'SYSTEMMANAGE/GET_MEMBERGRADE_RADIO_MLY';
export const SET_MEMBERGRADE_RADIO= 'SYSTEMMANAGE/SET_MEMBERGRADE_RADIO_MLY';