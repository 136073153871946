import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table, Switch } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell, tooltipWithUnit, ImgCell } from '@/components/TableCell';

class TableContent extends Component {
	state = {
		selectedRowKeys: [],
	};

	_columns = () => [
		{
			title: '贡献值',
			dataIndex: 'xiubeiName',
			key: 'xiubeiName',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '贡献值副标题',
			dataIndex: 'xiubeiSubtitle',
			key: 'xiubeiSubtitle',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '状态',
			dataIndex: 'listXiubeiState',
			key: 'listXiubeiState',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: (record) => {
				return <div>{record && record.value}</div>;
			}
		},
		{
			title: '发放时间',
			dataIndex: 'provideStartTime',
			key: 'provideStartTime',
			align: 'center',
			width: '10%',
			render: (text, record) => {
				return <div>
					<div>{text}</div>
					<div>{record.provideEndTime}</div>
				</div>;
			}
		},
		{
			title: '周期（天）',
			dataIndex: 'period',
			key: 'period',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '统计条件',
			dataIndex: 'statisticalConditions',
			key: 'statisticalConditions',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: (record) => {
				return <div>{record && record.value}</div>;
			}
		},
		{
			title: '周期总额外奖金（元）',
			dataIndex: 'extraReward',
			key: 'extraReward',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: priceFenToYuanCell
		},
		{
			title: '分配方式',
			dataIndex: 'distributionMode',
			key: 'distributionMode',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: (record) => {
				return <div>{record && record.value}</div>;
			}
		},
		{
			title: '可用状态',
			dataIndex: 'state',
			key: 'state',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: (text, item) => this._switchCell(text && text.code, item, 'state')
		},
		{
			title: '操作',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			width: '10%',
			render: (id, record) => this._tableAction(id, record)
		},
	];

	_imgCell = (text) => {
		return <img src={text} width='50px' height='50px'></img>;
	}

	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys) => {
			this.setState({ selectedRowKeys });
			this.props.tableAction(selectedRowKeys, 'batchBan');
		}
	};
	// 表格项内容为开关
	_switchCell = (switchStatus, item, type) => {
		const { tableAction } = this.props;
		let checked = true;
		if (switchStatus == 0) {
			checked = false;
		}
		return (
			<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
				<Switch
					checkedChildren="ON"
					unCheckedChildren="OFF"
					checked={checked}
					onChange={() => {
						if (type == 'state') {
							tableAction(switchStatus, 'state', item); // 状态 
						}
					}}
				/>
			</div>
		);
	}
	// 清空表格复选框
	_cleanAllSelected = () => {
		this.setState({ selectedRowKeys: [] }, () => {
			this.props.tableAction([], 'batchBan');
		});
	}

	_takeDataSource = (data = []) => {
		return data;
	}

	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => tableAction(key, 'view', record)}>预览</div>
				<div className={styles.item} onClick={() => tableAction(key, 'detail')}>详情</div>
				{record.listXiubeiState.code == 'NOT_STARTED' && <div className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>}
				{/* <div className={styles.item} onClick={() => tableAction(key, 'stop')}>暂停</div>
				<div className={styles.item} onClick={() => tableAction(key, 'start')}>启动</div> */}
			</div>
		);
	};

	render() {
		const { paginationChange } = this.props;
		const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

		// const { selectedRowKeys } = this.state;
		// this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
			<div className={styles.tableContainer}>
				{/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					// rowSelection={this._rowSelection}
					rowKey={(record) => record.id}
					columns={this._columns()}
					pagination={false}
					dataSource={this._takeDataSource(dataList)}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={resultTotal}
					pageSize={pageSize}
					pageNum={pageNum}
					onChange={paginationChange}
				/>
			</div>
		);
	}
}

TableContent.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
};

export default TableContent;