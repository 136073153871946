import React from 'react';
import styles from './OrderTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';

class OrderTable extends React.Component {
    _takeColumns = () => ([
        {
            title: '工单编号',
            dataIndex: 'c1',
            key: 'c1',
            width: '8%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '工单标题',
            dataIndex: 'c2',
            key: 'c2',
            width: '15%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '反馈类型',
            dataIndex: 'c3',
            key: 'c3',
            width: '8%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '问题描述',
            dataIndex: 'c4',
            key: 'c4',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '创建时间',
            dataIndex: 'c5',
            key: 'c5',
            width: '8%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '创建人',
            dataIndex: 'c6',
            key: 'c6',
            width: '8%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '受理人',
            dataIndex: 'c7',
            key: 'c7',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '工单状态',
            dataIndex: 'c8',
            key: 'c8',
            width: '8%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '优先级',
            dataIndex: 'c9',
            key: 'c9',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            width: '10%',
            render: (text, item) => this._renderActionCell(item.key, item.c11)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderActionCell = (key, orderState) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'info')}>查看详情</div>
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
        }
    };

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default OrderTable;

//   onCell: () => {
//     return {
//       style: {
//         maxWidth: 0,
//         overflow: 'hidden',
//         whiteSpace: 'nowrap',
//         textOverflow:'ellipsis',
//         cursor:'pointer'
//       }
//     };
//   },