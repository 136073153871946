import * as T from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const marketingCampaign = (state = initData, action) => {
    switch (action.type) {
        case T.SET_MARKETINGCAMPAIGN_List:
            return action.payload.result;
        default:
            return state;
    }
};

export const marketingCampaignUser = (state = initData, action) => {
    switch (action.type) {
        case T.SET_MARKETINGCAMPAIGN_USERList:
            return action.payload.result;
        default:
            return state;
    }
};

export const walletMerchantList = (state = initData, action) => {
    switch (action.type) {
        case T.SET_WALLET_MERCHANTLIST:
            return action.payload.result;
        default:
            return state;
    }
};