import { createModel, createSaga, createReducer } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };

const model = {
    namespace: 'reconciliationdata',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getReconciliationData': T('getReconciliationData'),
            'getReconciliationDetail': T('getReconciliationDetail'),
            'getReconciliationNewData': T('getReconciliationNewData'),
            'getReconciliationDailyData': T('getReconciliationDailyData'),
            'getwithdrawListData': T('getwithdrawListData'),
            'getAccountingStatusEnum': T('getAccountingStatusEnum'),
            'getFinancialTypeEnum': T('getFinancialTypeEnum'),
            'getYunstPayTypeEnum': T('getYunstPayTypeEnum'),
            'getOutAccountWithdrawStatusEnum': T('getOutAccountWithdrawStatusEnum'),
            'getManualWithdrawalList': T('getManualWithdrawalList'),
            'getPaymentAccessTypeEnum': T('getPaymentAccessTypeEnum'),
            'getPlatformDrawalList': T('getPlatformDrawalList'),
            'getUnionMerchantSettleStatusEnum': T('getUnionMerchantSettleStatusEnum'),

            'getDetailYLData': T('getDetailYLData'),
            'getDetailData': T('getDetailData'),
            'getLLTableData': T('getLLTableData'),

            'getStandardPayList': T('getStandardPayList'),
        },
        actions: {
            'getReconciliationData': A('getReconciliationData'),
            'getReconciliationDetail': A('getReconciliationDetail'),
            'getReconciliationNewData': A('getReconciliationNewData'),
            'getReconciliationDailyData': A('getReconciliationDailyData'),
            'getwithdrawListData': A('getwithdrawListData'),
            'getAccountingStatusEnum': A('getAccountingStatusEnum'),
            'getFinancialTypeEnum': A('getFinancialTypeEnum'),
            'getYunstPayTypeEnum': A('getYunstPayTypeEnum'),
            'getOutAccountWithdrawStatusEnum': A('getOutAccountWithdrawStatusEnum'),
            'getManualWithdrawalList': A('getManualWithdrawalList'),
            'getPaymentAccessTypeEnum': A('getPaymentAccessTypeEnum'),
            'getPlatformDrawalList': A('getPlatformDrawalList'),
            'getUnionMerchantSettleStatusEnum': A('getUnionMerchantSettleStatusEnum'),

            'getDetailYLData': A('getDetailYLData'),
            'getDetailData': A('getDetailData'),
            'getLLTableData': A('getLLTableData'),

            'getStandardPayList': A('getStandardPayList'),
        },
        sagas: {
            'getReconciliationData': S('getReconciliationData', '/reconciliation/list'), //列表
            'getReconciliationDetail': S('getReconciliationDetail', '/reconciliation/detail/list'), //明细
            'getReconciliationNewData': S('getReconciliationNewData', '/reconciliation/new/record'), //新数据
            'getReconciliationDailyData': S('getReconciliationDailyData', '/reconciliation/list/settlement'), //日结对账
            'getwithdrawListData': S('getwithdrawListData', '/merchant/withdraw/yums/withdrawList'), //商户提现记录
            'getAccountingStatusEnum': S('getAccountingStatusEnum', '/enum/AccountingStatusEnum'), //核算状态
            'getFinancialTypeEnum': S('getFinancialTypeEnum', '/enum/FinancialTypeEnum'), //财务类型枚举
            'getYunstPayTypeEnum': S('getYunstPayTypeEnum', '/enum/YunstPayTypeEnum'), //支付方式枚举
            'getOutAccountWithdrawStatusEnum': S('getOutAccountWithdrawStatusEnum', '/enum/OutAccountWithdrawStatusEnum'), //状态枚举 
            'getManualWithdrawalList': S('getManualWithdrawalList', '/merchant/settleManual', 'POST', 'action'),
            'getPaymentAccessTypeEnum': S('getPaymentAccessTypeEnum', '/enum/getPaymentAccessTypeEnum'),
            'getPlatformDrawalList': S('getPlatformDrawalList', '/unionPlatWithdrawal/query'),
            'getUnionMerchantSettleStatusEnum': S('getUnionMerchantSettleStatusEnum', '/enum/UnionMerchantSettleStatusEnum'),

            'getDetailYLData': S('getDetailYLData', '/merchant/withdraw/yums/order/unionList'),//提现订单明细（银联）
            'getDetailData': S('getDetailData', '/merchant/withdraw/yums/order/list'), //提现订单明细（通联）
            'getLLTableData': S('getLLTableData', '/merchant/withdraw/yums/order/llPayList'), //提现订单明细（通联）

            'getStandardPayList': S('getStandardPayList','/merchant/withdraw/yums/order/standardPayList'),
        },
        reducers: {
            'ReconciliationData': R('getReconciliationData', dataList),
            'ReconciliationDetail': R('getReconciliationDetail', {}),
            'ReconciliationNewData': R('getReconciliationNewData', dataList),
            'ReconciliationDailyData': R('getReconciliationDailyData', dataList),
            'withdrawListData': R('getwithdrawListData', dataList),
            'AccountingStatusEnum': R('getAccountingStatusEnum', []),
            'FinancialTypeEnum': R('getFinancialTypeEnum', []),
            'YunstPayTypeEnum': R('getYunstPayTypeEnum', []),
            'OutAccountWithdrawStatusEnum': R('getOutAccountWithdrawStatusEnum', []),
            'manualWithdrawalList': R('getManualWithdrawalList', dataList),
            'paymentAccessTypeEnum': R('getPaymentAccessTypeEnum', []),
            'platformDrawalList': R('getPlatformDrawalList', dataList),
            'unionMerchantSettleStatusEnum': R('getUnionMerchantSettleStatusEnum', []),

            'detailYLData': R('getDetailYLData', dataList),
            'detailData': R('getDetailData', dataList),
            'lLTableData': R('getLLTableData', dataList),
            
            'standardPayList': R('getStandardPayList','/merchant/withdraw/yums/order/standardPayList'),
        }
    })
};
export default createModel(model);