import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination, XMediaFn, } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const columns = () => [
    {
        title: '氛围名称',
        dataIndex: 'giftName',
        key: 'giftName',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '氛围分类',
        dataIndex: 'className',
        key: 'className ',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '使用魅力值',
        dataIndex: 'giftCost',
        key: 'giftCost',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '氛围图片',
        dataIndex: 'giftImg',
        key: 'giftImg',
        align: 'center',
        width: '10%',
        render: (text, item, index) => (ImgCell(text, item, index))
    },
    {
        title: '会员账号',
        dataIndex: 'tel',
        key: 'tel',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '打赏主播',
        dataIndex: 'nickname',
        key: 'nickname',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '购买时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        align: 'center',
        width: '10%',
        onCell: tooltipStyle,
        render: tooltip
    },
];

// 表格项内容为图片
const ImgCell = (url, item, index) => {
    return (
        <div style={{ display: 'inline-flex' }}>
            <XMediaFn
                style={{ width: '50px', height: '50px', marginRight: '0' }}
                dataType='image'
                dataSource={url}
                key={index}
            />
        </div>
    );
};

class BuyTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data;
    }

    render() {
        const { renderData, pagiNation, _paginationChange, } = this.props;

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    rowSelection={this._rowSelection}
                    columns={columns()}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={pagiNation.resultTotal}
                    pageSize={pagiNation.pageSize}
                    pageNum={pagiNation.pageNum}
                    onChange={_paginationChange}
                />
            </div>

        );
    }
}

BuyTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    pagiNation: PropTypes.object, // 分页数据
    _paginationChange: PropTypes.func,// 页面发生变化
};

export default BuyTable;