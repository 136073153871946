/**
 * 服务列表
 * */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import Main from './pages/main';
import LiveSystem from '@/views/servicemanage/servicelist';

const tabPages = [
    { id: 1, label: '服务系统' },
    { id: 2, label: '直播系统' },
];

class List extends React.Component {

    state = {
        curTabID: 1
    }

    componentDidMount() {
        const { history: { location: { curTabID = 1 } } } = this.props;
        this.setState({ curTabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        const { curTabID } = this.state;
        const { match, history } = this.props;
        switch (curTabID) {
            case 1:
                return <Main match={match} history={history} />;
            case 2:
                return <LiveSystem match={match} history={history} />;
            default:
                return <Main match={match} history={history} />;
        }
    }

    render() {
        const { curTabID } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={tabPages} onChange={curTabID => this.setState({ curTabID })} activedID={curTabID} />
                <div style={{ marginTop: '10px', flex: 1 }}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

List.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
};

export default List;