import { createModel } from '@/store/tools';

const model = {
    namespace: 'template',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getTemplateTableList': T('getTemplateTableList'),
            'getTemplateState': T('getTemplateState'),
            'getTemplateDetail': T('getTemplateDetail'),
        },
        actions: {
            'getTemplateTableList': A('getTemplateTableList'), // 列表数据
            'getTemplateState': A('getTemplateState'), // 状态
            'getCleanDetail': C('getTemplateDetail'), // 清除详情数据
            'getTemplateDetail': A('getTemplateDetail'), // 详情
        },
        sagas: {
            'getTemplateTableList': S('getTemplateTableList', '/report/template/list'),
            'getTemplateState': S('getTemplateState', '/enum/StateEnum'),
            'getTemplateDetail': S('getTemplateDetail', '/report/template/info'),
        },
        reducers: {
            'templateList': R('getTemplateTableList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'templateState': R('getTemplateState', []),
            'templateDetail': R('getTemplateDetail', {}),
        }
    })
};
export default createModel(model);