/**
 * 创建分类
 */
import React from 'react';
import { Modal, Input, Form, Radio, Icon, Checkbox, message } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { XInput } from '@/components/xqxc_ui';
import styles from './CreateModal.module.scss';
import http from '@/assets/api/http';

const FormItem = Form.Item;//表单校验
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};
class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
        };
    }
    componentDidMount() {
    }
    _handleSubmit = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.setState({ confirmLoading: true }, () => {
                    http('/admin/customtemplateclass/saveCustomTemplateClass', { ...fieldsValue }, 'POST')
                        .then(() => {
                            message.success('创建成功!');
                            this.props.refsh();
                            this.props.closeMode(true);
                            this.setState({
                                visible: false,
                                confirmLoading: false,
                            });
                        }).catch((reject) => {
                            message.error(reject.message);
                            this.setState({
                                confirmLoading: false,
                            });
                        });
                });
            }
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    render() {
        const { visible, confirmLoading, title, } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={550}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleSubmit}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form style={{ textAlign: 'left' }} {...formItemLayout} className="create-form" onSubmit={this._handleSubmit}>
                        <FormItem
                            htmlFor='className'
                            label={<span>分类名称</span>}
                        >
                            {getFieldDecorator('className', {
                                rules: [{ required: true, message: '请输入分类名称' },
                                { max: 15, message: '最多15个字' },
                                ],
                            })(<Input placeholder='请输入分类名称' />
                            )}
                            <span className={styles.spanLeft}>作为模板类型名称，最多15个字</span>
                        </FormItem>
                        <FormItem
                            htmlFor='state'
                            label={<span>状态</span>}
                        >
                            {getFieldDecorator('state', {
                                initialValue: 1,
                                rules: [{ required: true, message: '请选择状态' }
                                ],
                            })(<Radio.Group>
                                <Radio value={1}>启用</Radio>
                                <Radio value={0}>禁用</Radio>
                            </Radio.Group>
                            )}
                        </FormItem>
                    </Form>
                </div>
            </Modal>
        );

    }
}

CreateModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
    refsh: PropTypes.func,
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(CreateModal);// 校验
const mapStateToProps = (state) => ({
    messagePushPort: state.MessageClassify.messagePushPort,
});
export default connect(mapStateToProps, null)(ForgotProducDetails);
