/**
 * 【手动提现】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message, Modal, Input } from 'antd';
import md5 from 'js-md5';

class Shop extends React.Component {
    state = {
        password: undefined,
        visible: this.props.visible,
        loading: false,
    }

    _handleOk = () => {
        let { password } = this.state;
        if ((password + '').trim()) {
            this.setState({ loading: true });
            password = md5(password).toUpperCase();
            http('/pay/transfer/rePayment', { password, platformTransferIds: this.props.data }, 'POST')
                .then((res) => {
                    message.success('操作成功');
                    this.setState({ loading: false, visible: false });
                    this.props.closeMode(true);
                }).catch((reject = {}) => {
                    message.error(reject.message || '操作异常');
                    this.setState({ loading: false });
                });
        } else {
            message.warning('请输入密码');
        }
    }

    _handleCancel = () => {
        this.setState({ visible: false });
        this.props.closeMode();
    }


    render() {
        const { visible, loading } = this.state;
        return (
            <Modal
                width={450}
                centered
                title={this.props.title}
                visible={visible}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                confirmLoading={loading}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <span>财务密码：</span>
                    <Input.Password onChange={(v) => {
                        this.setState({ password: v.target.value });
                    }} />
                </div>
            </Modal>
        );
    }
}

Shop.propTypes = {
    history: PropTypes.object,
    data: PropTypes.array, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    refsh: PropTypes.func,
};

export default Shop;
