/**
 * 服务商管理
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import { XInput, XOKButton, XCancelButton, XSelect, showConfirm } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import ServiceTable from './components/ServiceTable';
import model from '@/reducers/usermanage/member/model';
import AbleModal from '../../modal/AbleModal';
import UnableModal from '../../modal/UnableModal';
import ConnectMemberModal from '../../modal/ConnectMemberModal';
import UpdateJiDouModal from '../../modal/UpdateJiDouModal';
import UpdateXiubeiModal from '../../modal/UpdateXiubeiModal';
import { getServiceState, getServiceList, getAreaCompany, getAssoTypeList, getRecommentTypeEnum, getStateEnum } from '@/reducers/usermanage/service/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { Button, Progress, message, Tabs } from 'antd';
import http from '@/assets/api/http';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { CompanyList } from '@/vcomps';
import { SearchBox, SearchItem } from '@/components/Layout';
import levelMode from '@/reducers/systemmanage/servicesetting/model';
import SetFranchiseValue from '../../modal/SetFranchiseValue';
import BatchOpenEquity from '../../../../operationcenter/modal/BatchOpenEquity';

const { TabPane } = Tabs;

const { getserviceLevelList } = levelMode.actions;

const Mode = {
    able: 'able',
    unable: 'unable',
    connect: 'connect',
    jidou: 'jidou',
    xiubei: 'xiubei'
};

class Main extends React.Component {
    state = {
        sname: '', // 服务商名称
        connnectStatus: {},
        operation: {}, // 运营中心
        company: {}, // 区域公司
        mobilePhone: '', // 手机号
        openMode: '', // 当前打开的弹框枚举
        modeParam: '',  // 传递给弹框的入参
        connectMemeberPercent: 0,
        showConnectMemeberProcess: false,
        selectedRowKeys: [],
        numstate: {},//账号状态
        recsource: {},//推荐人来源
        referees: '',//推荐人
        refereesAccount: '',//推荐人账号
        levelItem: {},//服务商等级
        province: {}, // 省份
        city: {}, // 城市
        street: {}, // 区域
        tabKey: 'a',
        isDestory: {}, isOpenRights: {}
    };

    componentDidMount() {
        const {
            getServiceState,
            getAreaCompany,
            getAssoTypeList,
            getOperationCenter,
            keepSecondNavBreadcrumb,
            getRecommentTypeEnum,
            getStateEnum,
            getserviceLevelList,
            getListAreaByParentId,
            getIsDestoryEnum
        } = this.props;
        this.setState({
            tabKey: this.getCurrentTab() !== 'offline' ? 'a' : 'b'
        });

        getListAreaByParentId({ id: 100000, type: 'province' });
        keepSecondNavBreadcrumb();
        getServiceState();
        getAreaCompany();
        getAssoTypeList();
        getOperationCenter();
        getRecommentTypeEnum();
        getStateEnum();
        getserviceLevelList();
        getIsDestoryEnum();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, sname, operation, company, mobilePhone, connnectStatus, numstate, recsource, referees, refereesAccount, levelItem, isDestory, isOpenRights } = this.state;
        const currentTab = this.getCurrentTab();
        let query = {
            providerState: numstate.code,
            recommendType: recsource.code,
            referees,
            refereesAccount,
            serviceName: sname,
            operationsId: operation.id,
            areaCompanyId: company.id,
            mobilePhone,
            levelId: levelItem.id,
            sourceConditions: 'DETAILS',
            assoType: connnectStatus.code,
            isDestory: isDestory.code, isOpenRights: isOpenRights.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        query = currentTab !== 'offline' ? query : {
            ...query,
            isOffline: 'true',
            provinceId: this.state.province.id,
            cityId: this.state.city.id,
            areaId: this.state.street.id,
        };
        this.props.getServiceList(query); // 发起请求
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            sname: '',
            connnectStatus: {},
            operation: {},
            company: {},
            mobilePhone: '',
            openMode: '',
            modeParam: '',
            numstate: {},
            recsource: {},
            referees: '',
            refereesAccount: '',
            levelItem: {},
            province: {},
            street: {},
            city: {},
            pageNum: 1,
            isDestory: {}, isOpenRights: {}
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建服务商
    _newHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建服务商',
            path: '/home/usermanage/service/create'
        });
        history.push({
            pathname: '/home/usermanage/service/create'
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格操作
    _tableAction = (key, type, code) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: '/home/usermanage/service/info/' + key + '/' + code
                });
                history.push({
                    pathname: '/home/usermanage/service/info/' + key + '/' + code
                });
                break;
            case 'edit':
                addOneBreadcrumbPath({
                    title: '编辑服务商',
                    path: '/home/usermanage/service/edit/' + key
                });
                history.push({
                    pathname: '/home/usermanage/service/edit/' + key
                });
                break;
            case 'able':
                this.setState({ openMode: Mode.able, modeParam: key });
                break;
            case 'unable':
                this.setState({ openMode: Mode.unable, modeParam: key });
                break;
            case 'randp':
                addOneBreadcrumbPath({
                    title: '收支明细',
                    path: '/home/usermanage/service/randp/' + key
                });
                history.push({
                    pathname: '/home/usermanage/service/randp/' + key
                });
                break;
            case 'suggest':
                addOneBreadcrumbPath({
                    title: '推荐明细',
                    path: '/home/usermanage/service/suggest/' + key
                });
                history.push({
                    pathname: '/home/usermanage/service/suggest/' + key
                });
                break;
            case 'isOpenRights':
                {
                    const { requrl, tip, ...other } = code;
                    showConfirm('温馨提示', `确定要给当前服务商${tip}权益吗？ 
                    （此处的权益是指：开通【每日抢拍】小程序 - 每日补贴活动，服务商获得的补贴权益
                    开通后，该服务商才能获得服务商补贴。
                    未开通，服务商不能获得服务商补贴）`, () => {
                        http(`/openRights/${requrl}`, { ...other }, 'POST').then(res => { message.success(res.message); this._searchHandle('useCache'); });
                    });
                }
                break;
        }
    }

    // 渲染弹框
    _renderMode = () => {
        switch (this.state.openMode) {
            case Mode.able:
                return <AbleModal visible={true} data={this.state.modeParam} closeMode={this._closeMode} />;
            case Mode.unable:
                return <UnableModal visible={true} data={this.state.modeParam} closeMode={this._closeMode} />;
            case Mode.connect:
                return <ConnectMemberModal visible={true} data={this.state.modeParam} closeMode={this._closeMode} />;
            case Mode.jidou:
                return <UpdateJiDouModal visible={true} data={this.state.selectedRowKeys} closeMode={this._closeMode} />;
            case Mode.xiubei:
                return <UpdateXiubeiModal visible={true} data={this.state.selectedRowKeys} closeMode={this._closeMode} />;
            case 'Franchise':
                return <SetFranchiseValue visible={true} closeMode={this._closeMode} />;
            case 'BatchOpenEquity':
                return <BatchOpenEquity subjectSystem='SERVICE_RIGHTS' closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (cb) => {
        this.setState({ openMode: '' });
        if (cb == 'Refresh') {
            this._searchHandle('useCache');
        }
        if (cb == 'clear') {
            this.setState({ selectedRowKeys: [] });
            this._searchHandle('useCache');
        }
    }

    startConnectMemberProcess = () => {
        if (!this.connectMemberProcess) {
            this.setState({ showConnectMemeberProcess: true });

            http('/service/provider/serviceMatchingMember', { serviceIdList: this.state.selectedRowKeys }).then((res) => {
                if (res.code === 200) {
                    this.connectMemberProcess = setInterval(() => {
                        http('/service/provider/numberPercentage', { numberProgress: Number(res.result), serviceIdList: this.state.selectedRowKeys }).then((res1) => {
                            this.setState({ connectMemeberPercent: res1.result });
                            if (this.state.connectMemeberPercent >= 100) {
                                clearInterval(this.connectMemberProcess);
                                this.setState({ connectMemeberPercent: 100, openMode: Mode.connect, modeParam: { serviceIdList: this.state.selectedRowKeys } }, () => {
                                    setTimeout(() => {
                                        this.setState({ connectMemeberPercent: 0, showConnectMemeberProcess: false }, () => {
                                            this.connectMemberProcess = undefined;
                                        });
                                    }, 500);
                                });
                            }
                        });
                    }, 1000);

                } else message.error('关联异常');
            }).catch(() => message.error('关联异常'));

        } else {
            message.info('关联会员账号中，请稍后再试');
        }
    }

    selectedRowKeysChange = (keys) => {
        this.setState({ selectedRowKeys: keys });
    }

    //是否渲染新建按钮
    _renderButton = () => {
        return <div className={styles.row2}>
            <ButtonPerssion code='usermanage/service/xjfws'>
                <XOKButton
                    style={{ width: '100px', marginRight: '15px' }}
                    label='新建服务商'
                    onClick={this._newHandle}
                />
            </ButtonPerssion>
            <ButtonPerssion code='usermanage/service/plxgjd'>
                <XOKButton
                    style={{ width: '150px', marginRight: 15 }}
                    label='批量修改能量值'
                    onClick={() => {
                        if (this.state.selectedRowKeys.length <= 0) {
                            message.warning('请先选中一个或多个服务商');
                            return;
                        } else if (this.state.selectedRowKeys.length > 30) {
                            message.warning('一次最多只能修改30个服务商');
                            return;
                        }
                        this.setState({ openMode: Mode.jidou });
                    }}
                />
            </ButtonPerssion>
            <ButtonPerssion code='usermanage/service/plxgxb'>
                <XOKButton
                    style={{ width: '150px', marginRight: 15 }}
                    label='批量修改贡献值'
                    onClick={() => {
                        if (this.state.selectedRowKeys.length <= 0) {
                            message.warning('请先选中一个或多个服务商');
                            return;
                        } else if (this.state.selectedRowKeys.length > 30) {
                            message.warning('一次最多只能修改30个服务商');
                            return;
                        }
                        this.setState({ openMode: Mode.xiubei });
                    }}
                />
            </ButtonPerssion>
            {this.state.tabKey === 'a' && <ButtonPerssion code='usermanage/service/plzstqz'>
                <XOKButton
                    style={{ width: '150px', marginRight: '15px' }}
                    label='批量赠送特权值'
                    onClick={() => {
                        this.setState({ openMode: 'Franchise' });
                    }}
                />
            </ButtonPerssion>}
            <XOKButton style={{ width: '120px', marginRight: '15px' }} label='批量开通权益' onClick={() => this.setState({ openMode: 'BatchOpenEquity' })} />
            <ButtonPerssion code='usermanage/service/glhyzh'>
                <Button type='primary' className='lz-button-loading' onClick={this.startConnectMemberProcess} style={{ marginRight: 15 }}>关联会员账号</Button>
                <Progress className='custom-loading' type="circle" percent={this.state.connectMemeberPercent} width={30} style={{ opacity: this.state.showConnectMemeberProcess ? 1 : 0 }} />
            </ButtonPerssion>
        </div>;
    }

    handleSwitchTab = (tabKey) => {
        let { history } = this.props;
        if (tabKey === this.state.tabKey) {
            return false;
        }
        this.setState({
            ...this.state,
            tabKey
        }, () => {
            if (this.state.tabKey === 'b') {
                history.push('/home/usermanage/service?tab=offline');
            }
            else {
                history.push('/home/usermanage/service');
            }
        });

    }

    getCurrentTab = () => {
        let { location } = this.props;
        return parse(location.search).tab || '';
    }
    _areaDataChange = (data) => {
        this.setState({ areaData: data }, () => {
            console.log(data);
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            if (this.getCurrentTab() === 'offline') {
                this.props.cleanCity();
            }
            this.setState({
                ...this.state,
                pageNum: 1,
                tabKey: this.getCurrentTab() !== 'offline' ? 'a' : 'b'
            });
            this._resetHandle();
            this.selectedRowKeysChange([]);
            setTimeout(() => {
                this._searchHandle('useCache');
            }, 1);
        }
    }

    _provinceChangeHandle = (selectedItem) => {
        this.setState({ province: selectedItem, city: {}, street: {} });
        this.props.getListAreaByParentId({ id: selectedItem.id, type: 'city' });
        this.props.cleanStreet();
    }

    _cityChangeHandle = (selectedItem) => {
        this.setState({ city: selectedItem, street: {} });
        this.props.getListAreaByParentId({ id: selectedItem.id, type: 'street' });
    }

    _streetChangeHandle = (selectedItem) => {
        this.setState({ street: selectedItem });
    }
    render() {
        const {
            sname, company, mobilePhone, selectedRowKeys, connnectStatus, operation,
            numstate, recsource, referees, refereesAccount, levelItem, isDestory, isOpenRights
        } = this.state;
        const { serviceList,
            assoTypeList,
            OCSelectList,
            recommentTypeEnum,
            stateEnum,
            serviceLevel,
            isDestoryEnum
        } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>

                    <SearchBox>
                        <Tabs
                            activeKey={this.state.tabKey}
                            onTabClick={this.handleSwitchTab}
                        >
                            <TabPane tab="线上服务" key="a" />
                            <TabPane tab="线下服务" key="b" />
                        </Tabs>

                        <SearchItem>
                            <XInput
                                label='服务商账号'
                                placeholder='请输入服务商账号'
                                value={mobilePhone}
                                bindThis={this}
                                bindName='mobilePhone'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='服务商姓名'
                                placeholder='请输入服务商姓名'
                                value={sname}
                                bindThis={this}
                                bindName='sname'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='所属运营中心'
                                placeholder='请选择运营中心'
                                renderData={OCSelectList}
                                dataIndex='companyName'
                                keyIndex='id'
                                value={operation.companyName}
                                bindThis={this}
                                bindName='operation'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <CompanyList
                                style={{ width: 240, marginRight: 20 }}
                                label='所属区域公司'
                                placeholder='请选择区域公司'
                                bindThis={this}
                                bindName='company'
                                value={company}
                            />
                        </SearchItem>
                        {/* <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={serviceState}
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                                bindThis={this}
                                bindName='status'
                            />
                        </SearchItem> */}

                        <SearchItem>
                            <XSelect
                                label='关联状态'
                                placeholder='请选关联状态'
                                renderData={assoTypeList.map(v => ({ value: { 0: '未关联', 1: '已关联' }[v.code], code: v.code }))}
                                dataIndex='value'
                                keyIndex='code'
                                value={connnectStatus.value}
                                bindThis={this}
                                bindName='connnectStatus'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='账号状态'
                                placeholder='请选择账号状态'
                                renderData={stateEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={numstate.value}
                                bindThis={this}
                                bindName='numstate'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='推荐人来源'
                                placeholder='请选择推荐人来源'
                                renderData={recommentTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={recsource.value}
                                bindThis={this}
                                bindName='recsource'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='推荐人'
                                placeholder='请输入推荐人'
                                value={referees}
                                bindThis={this}
                                bindName='referees'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='推荐人账号'
                                placeholder='请输入推荐人账号'
                                value={refereesAccount}
                                bindThis={this}
                                bindName='refereesAccount'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='服务商等级'
                                placeholder='请选择服务商等级'
                                renderData={serviceLevel}
                                dataIndex='levelName'
                                keyIndex='id'
                                value={levelItem.levelName}
                                bindThis={this}
                                bindName='levelItem'
                            />
                        </SearchItem>
                        {
                            this.getCurrentTab() === 'offline' &&
                            (<SearchItem>
                                <XSelect
                                    style={{ marginRight: '0px' }}
                                    label='所在地区'
                                    placeholder='省份'
                                    renderData={this.props.address.province}
                                    onChange={this._provinceChangeHandle}
                                    dataIndex='areaName'
                                    keyIndex='id'
                                    showSearch={true}
                                    value={this.state.province.areaName}
                                />
                                <XSelect
                                    style={{ marginRight: '0px' }}
                                    placeholder='城市'
                                    renderData={this.props.address.city}
                                    onChange={this._cityChangeHandle}
                                    dataIndex='areaName'
                                    keyIndex='id'
                                    showSearch={true}
                                    value={this.state.city.areaName}
                                />
                                <XSelect
                                    placeholder='区域'
                                    renderData={this.props.address.street}
                                    onChange={this._streetChangeHandle}
                                    dataIndex='areaName'
                                    keyIndex='id'
                                    showSearch={true}
                                    value={this.state.street.areaName}
                                />
                            </SearchItem>
                            )

                        }
                        <SearchItem>
                            <XSelect
                                label='是否注销'
                                placeholder='请选择是否注销'
                                renderData={isDestoryEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={isDestory.value}
                                bindThis={this}
                                bindName='isDestory'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect label='是否开通权益' placeholder='请选择是否开通权益'
                                renderData={isDestoryEnum} dataIndex='value' keyIndex='code'
                                value={isOpenRights.value} bindThis={this} bindName='isOpenRights'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    {
                        this._renderButton()
                    }
                    <ServiceTable
                        selectedRowKeys={selectedRowKeys}
                        selectedRowKeysChange={this.selectedRowKeysChange}
                        renderData={serviceList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                        isOffLine={this.getCurrentTab() === 'offline'}
                        currentPageNum={this.state.pageNum}
                        tabKey={this.state.tabKey}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getListAreaByParentId: PropTypes.func, // 请求地区信息
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getServiceState: PropTypes.func, // 获取服务商审核状态
    getServiceList: PropTypes.func, // 获取服务商列表
    serviceState: PropTypes.array, // 服务商审核状态
    serviceList: PropTypes.object, // 服务商列表
    getAreaCompany: PropTypes.func, // 获取区域公司下拉列
    areaCompany: PropTypes.array, // 区域公司下拉列
    getAssoTypeList: PropTypes.func, // 获取关联状态下拉列
    assoTypeList: PropTypes.array, // 关联状态下拉列
    getOperationCenter: PropTypes.func, // 获取归属运营中心
    OCSelectList: PropTypes.array, // 归属运营中心下拉列
    getRecommentTypeEnum: PropTypes.func, // 获取推荐人来源枚举
    recommentTypeEnum: PropTypes.array, // 推荐人来源枚举
    getStateEnum: PropTypes.func, // 获取账号状态枚举
    stateEnum: PropTypes.array, // 账号状态枚举
    getserviceLevelList: PropTypes.func,//服务商等级action
    serviceLevel: PropTypes.array,
    cleanStreet: PropTypes.func, // 清理区域搜索结果
    cleanCity: PropTypes.func, // 清理城市搜索结果
    address: PropTypes.object, // 省市区
    getIsDestoryEnum: PropTypes.func,
    isDestoryEnum: PropTypes.array
};

const mapStateToProps = (state) => ({
    serviceState: state.service.getServiceState,
    serviceList: state.service.getServiceList,
    areaCompany: state.service.getAreaCompany,
    assoTypeList: state.service.getAssoTypeList,
    OCSelectList: state.member.getOperationCenter,
    recommentTypeEnum: state.service.recommentTypeEnum,
    stateEnum: state.service.stateEnum,
    serviceLevel: state.servicesetting.serviceLevel,
    address: state.member.address,
    isDestoryEnum: state.member.isDestoryEnum,
});

export default connect(mapStateToProps, {
    ...model.actions,
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getServiceState,
    getServiceList,
    getAreaCompany,
    getAssoTypeList,
    getRecommentTypeEnum,
    getStateEnum,
    getserviceLevelList,
})(withRouter(Main));