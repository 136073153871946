import React from 'react';
import styles from './ServiceTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const Mode = {
    Unlock: 'Unlock',
    History: 'History'
};

const TableAction = (record, actionClickHandle) => {
    return (
        <div className={styles.action} key={record.tels}>
            {['BANNED_IN', 'PERMANENTLY_BANNED'].includes(record.blackState.code) ? <div className={styles.item} id="anchorAudit" onClick={() => actionClickHandle(record, Mode.Unlock)}>解封</div> : null}
            <div className={styles.item} id="anchorDetail" onClick={() => actionClickHandle(record, Mode.History)}>历史记录</div>
        </div>
    );
};

class ServiceTable extends React.Component {

    _takeColumns = (actionClickHandle) => {
        let cols = [
            {
                title: '封禁类型',
                dataIndex: 'blackType.value',
                key: 'blackType.value',
                align: 'center',
                width: '10%',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '会员账号',
                dataIndex: 'tel',
                key: 'tel',
                align: 'center',
                width: '10%',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '昵称',
                dataIndex: 'nickname',
                key: 'nickname',
                align: 'center',
                width: '10%',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '状态',
                dataIndex: 'blackState',
                key: 'blackState',
                align: 'center',
                width: '10%',
                render: (text) => (text || {}).value || '无'
            },
            {
                title: '封禁开始时间',
                dataIndex: 'startTime',
                key: 'startTime',
                align: 'center',
                width: '10%',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '处罚到期时间',
                dataIndex: 'expectedEndTime',
                key: 'expectedEndTime',
                align: 'center',
                width: '10%',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作人',
                dataIndex: 'prohibitUser',
                key: 'prohibitUser',
                align: 'center',
                width: '10%',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '理由',
                dataIndex: 'reason',
                key: 'reason',
                align: 'center',
                width: '20%',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: '10%',
                render: (id, record) => TableAction(record, actionClickHandle)
            }
        ];

        return cols;
    };

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };


    // 表格复选输入
    _rowSelection = () => {
        return {
            selectedRowKeys: this.props.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.props.selectedRowKeysChange
                    && this.props.selectedRowKeysChange(selectedRowKeys, selectedRows);
            }
        };
    };

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    rowSelection={this._rowSelection()}
                    columns={this._takeColumns(this.props.tableAction)}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

ServiceTable.propTypes = {
    selectedRowKeys: PropTypes.array,
    selectedRowKeysChange: PropTypes.func,
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default ServiceTable;