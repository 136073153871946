import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getRecommendList = (T) => createSaga(T('getRecommendList'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/goods/wholesale/findRecommendWholesaleGoodsPage', { ...action.payload }, 'POST');
                let dataList = result.result.dataList.map(item => {
                    return { ...item, active: false };
                });
                yield put({ type: T('originalData'), payload: { ...result.result, dataList } });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const recommendList = (T) => createReducer((autostate) => {
    return (state = autostate, { type, payload }) => {
        switch (type) {
            case T('originalData'):
                return payload;
            case T('showHideEdit/GET'):
                return {
                    ...state, dataList: state.dataList.map(item => {
                        if (item.goodsId === payload.goodsId) {
                            return { ...item, active: payload.bool };
                        }
                        return { ...item };
                    })
                };
            default:
                return state;
        }
    };
}, { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 });

const model = {
    namespace: 'wholesaleGoods',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getWholesaleGoodsList': T('getWholesaleGoodsList'),
            'getUserSele': T('getUserSele'),
            'getTypeSele': T('getTypeSele'),
            'getRecommendList': T('getRecommendList'),
            'showHideEdit': T('showHideEdit'),
            'getChannelEnum': T('getChannelEnum'),
            'getOnChannelDistributionMethodsEnum': T('getOnChannelDistributionMethodsEnum'),
            'getLimitNumberTypeEnum': T('getLimitNumberTypeEnum'),
        },
        actions: {
            'getWholesaleGoodsList': A('getWholesaleGoodsList'),
            'getUserSele': A('getUserSele'),
            'getTypeSele': A('getTypeSele'),
            'getRecommendList': A('getRecommendList'),
            'showHideEdit': A('showHideEdit'),
            'getChannelEnum': A('getChannelEnum'),
            'getOnChannelDistributionMethodsEnum': A('getOnChannelDistributionMethodsEnum'),
            'getLimitNumberTypeEnum': A('getLimitNumberTypeEnum'),
        },
        sagas: {
            'getWholesaleGoodsList': S('getWholesaleGoodsList', '/goods/wholesale/findWholesaleGoodsPage'),
            'getUserSele': S('getUserSele', '/merchant/info/listMerchantIdAndName'),
            'getTypeSele': S('getTypeSele', '/enum/GoodsTypeEnum'),
            'getRecommendList': getRecommendList(T),
            'getChannelEnum': S('getChannelEnum', '/enum/OnChannelEnum'),
            'getOnChannelDistributionMethodsEnum': S('getOnChannelDistributionMethodsEnum', '/enum/OnChannelDistributionMethodsEnum'),
            'getLimitNumberTypeEnum': S('getLimitNumberTypeEnum', '/enum/LimitNumberTypeEnum'),
        },
        reducers: {
            'wholesaleGoodsList': R('getWholesaleGoodsList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'userSele': R('getUserSele', { merchantIdAndNames: [] }),
            'typeSele': R('getTypeSele', []),
            'recommendList': recommendList(T),
            'channelEnum': R('getChannelEnum', []),
            'channelDistributionMethodsEnum': R('getOnChannelDistributionMethodsEnum', []),
            'limitNumberTypeEnum': R('getLimitNumberTypeEnum', []),
        }
    })
};
export default createModel(model);