/**
 * 审核弹框
 */
import React from 'react';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { Modal, Input, Radio, message } from 'antd';
import PropTypes from 'prop-types';
import { XMediaFn } from '@/components/xqxc_ui';
import styles from './AuditModal.module.scss';
import { getCommunityAuditDetail } from '@/reducers/auditmanage/community/actions';
import ProductTable from '../conponents/ProductTable';

const RadioGroup = Radio.Group;
const { TextArea } = Input;

class AuditModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        status: '',
        reason: ''
    }

    componentDidMount() {
        const { accountId, feedId, showType } = this.props;
        this.props.getCommunityAuditDetail({ accountId, feedId, showType });
    }

    _sendReq = (url, data) => {
        this.setState({ confirmLoading: true }, () => {
            http(url, data, 'POST')
                .then(() => {
                    message.success('审核操作成功。');
                    this.setState({
                        visible: false,
                        confirmLoading: false
                    });
                    this.props.closeMode(true);
                }).catch((reject) => {
                    message.error(reject.message);
                    this.setState({
                        confirmLoading: false
                    });
                });
        });
    }

    _handleOk = () => {
        const { accountId, feedId, showType } = this.props;
        const { status, reason } = this.state;
        if (status === 'APPROVAL') {
            this._sendReq('/graphics/graphicsReview', { accountId, feedId, result: 'APPROVAL', reason, showType });
        } else {
            if (reason) {
                this._sendReq('/graphics/graphicsReview', { accountId, feedId, result: 'REJECT', reason, showType });
            } else {
                message.error('请输入不通过原因！');
            }
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _stateChange = (e) => {
        console.log(e);
        this.setState({ status: e.target.value });
    }

    _reasonChange = (e) => {
        this.setState({ reason: e.target.value });
    }

    _imageDeal = (images) => {
        return <XMediaFn
            style={{ marginRight: '0' }}
            dataType='image'
            dataSource={images}
        />;
    }

    render() {
        const { visible, confirmLoading, reason, status } = this.state;
        const { communityAuditDetail } = this.props;
        const { goodsList } = communityAuditDetail;

        return (
            <Modal
                width={1000}
                bodyStyle={{ paddingRight: 10 }}
                centered
                title='社区审核'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.phone}>
                        <span>手机号码：</span>
                        <span>{communityAuditDetail.tel}</span>
                    </div>
                    <div className={styles.memberName}>
                        <span>会员昵称：</span>
                        <span>{communityAuditDetail.nickname}</span>
                    </div>
                    <div className={styles.title}>
                        <span>社区标题：</span>
                        <span>{communityAuditDetail.feedTitle}</span>
                    </div>
                    <div className={styles.title}>
                        <span>社区分类：</span>
                        <span>{communityAuditDetail.className}</span>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.label}>社区内容：</div>
                        <div className={styles.feedInfoBox}>
                            <div className={styles.image} >
                                {
                                    this._imageDeal(communityAuditDetail.fileNames)
                                }
                            </div>
                            <div className={styles.text}>{communityAuditDetail.content}</div>
                        </div>
                    </div>
                    <div className={styles.tables}>
                        <ProductTable renderData={goodsList || []} />
                    </div>
                    <div className={styles.status}>
                        <span>状&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;态：</span>
                        <span>
                            <RadioGroup onChange={this._stateChange} value={status}>
                                <Radio value='APPROVAL'>通过</Radio>
                                <Radio value='REJECT'>不通过</Radio>
                            </RadioGroup>
                        </span>
                    </div>
                    <div className={styles.reason}>
                        <TextArea style={{ width: '320px', marginLeft: '80px' }}
                            placeholder="请输入不通过原因"
                            onChange={this._reasonChange}
                            value={reason}
                            autosize={{ minRows: 3, maxRows: 6 }}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

AuditModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    data: PropTypes.string, // 需要审批的用户ID
    accountId: PropTypes.number,
    feedId: PropTypes.number,
    getCommunityAuditDetail: PropTypes.func,
    communityAuditDetail: PropTypes.object,
    showType: PropTypes.string
};
const mapStateToProps = (state) => ({
    communityAuditDetail: state.communityAuditData.communityAuditDetail,
});

export default connect(mapStateToProps, { getCommunityAuditDetail })(AuditModal);