import React from 'react';
import styles from './AdvertTable.module.scss';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell } from '@/components/TableCell';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';

class AdvertTable extends React.Component {
    state = {
        account: '',
        selectedRowKeys: [],
    }

    _takeColumns = () => ([
        {
            title: '对账单号',
            dataIndex: 'id',
            key: 'id',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '对账日期',
            dataIndex: 'gmtAccounting',
            key: 'gmtAccounting',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '完成交易金额（元）',
            dataIndex: 'finishOrderAmount',
            key: 'finishOrderAmount',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '收单手续费金额（元）',
            dataIndex: 'finishChargeAmount',
            key: 'finishChargeAmount',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '服务费（元）',
            dataIndex: 'finishShareAmount',
            key: 'finishShareAmount',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '应收金额（元）',
            dataIndex: 'finishTransferAmount',
            key: 'finishTransferAmount',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '订单数',
            dataIndex: 'finishOrderNum',
            key: 'finishOrderNum',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '生成时间',
            dataIndex: 'gmtModify',
            key: 'gmtModify',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'key',
            align: 'center',
            width: cellWidth.normal,
            fixed: 'right',
            render: (text, item) => this._renderActionCell(item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderActionCell = (item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(item.id, 'detail', item)}>对账明细</div>
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys, selectedRows });
            this.props.tableAction(selectedRowKeys, 'selection');
        },
    };

    render() {
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        const { paginationChange, scrollY } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    settlementStatus: PropTypes.array,
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default XTableHOC(AdvertTable);