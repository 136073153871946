import React, { PureComponent } from 'react';
import styles from './DetailModal.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { Modal, Input, Form, message } from 'antd';
import { XOKButton, XMediaFn } from '@/components/xqxc_ui';
import { getSuggestionsDetail } from '@/reducers/customermanage/suggestions/actions';

const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 1 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};

class ProducDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
        };
    }

    componentDidMount() {
        this.props.getSuggestionsDetail({ id: this.props.data.id });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _handleOk = () => {
        const { appType } = this.props.data;
        if (appType && appType.code == 'SMAORAGE_YOUXUAN') {
            message.warn('暂不支持小程序意见反馈处理!');
        } else {
            this.props.form.validateFields((err, fieldsValue) => {
                if (!err) {
                    const { suggestionsDetail: { id, processState } } = this.props;
                    const param = {
                        id,
                        ...fieldsValue,
                    };
                    if (processState === '已处理') {
                        message.warn('已处理不可再处理!');
                    } else {
                        http('/ideaBack/executeIdeaBack', param, 'POST')
                            .then(() => {
                                message.success('处理成功。');
                                this.setState({
                                    visible: false,
                                });
                                this.props.closeMode(true);
                                this.props.refresh();
                            }).catch((reject) => {
                                message.error(reject.message);
                            });
                    }
                }
            });
        }
    }

    render() {
        const { visible, confirmLoading, title } = this.state;
        const { suggestionsDetail } = this.props;
        const { appType = {} } = this.props.suggestionsDetail;
        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                width={800}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.orderInfo} >
                    <div className={`${styles.items} ${styles.layout}`}>
                        <div className={styles.flex}>
                            <p><label>会员昵称:</label><span>{suggestionsDetail.accountNickname}</span></p>
                            <p><label>反馈类型:</label><span>{suggestionsDetail.ideaBackCode}</span></p>
                            <p><label>手机号:</label><span>{suggestionsDetail.consigneeMobile}</span></p>
                        </div>
                        <div className={styles.flex}>
                            <p><label>会员账号:</label><span>{suggestionsDetail.accountTel}</span></p>
                            <p><label>提交时间:</label><span>{suggestionsDetail.gmtCreate}</span></p>
                            <p><label>反馈端口:</label><span>{appType.value}</span></p>
                        </div>
                    </div>
                </div>
                <div className={styles.title}>问题描述</div>
                <div>{suggestionsDetail.questionDesc}</div>
                <div className={styles.title}>图片</div>
                {suggestionsDetail.imageList && suggestionsDetail.imageList.map((i, index) => {
                    return <XMediaFn
                        key={index}
                        dataType='image'
                        dataSource={i}
                    />;
                })}
                <div style={{ clear: 'both' }}></div>
                <div className={styles.title}>提交视频</div>
                <XMediaFn
                    dataType='video'
                    dataSource={suggestionsDetail.videoList}
                />
                <div style={{ clear: 'both' }}></div>
                <div className={styles.title}>处理状态: <span>{suggestionsDetail.processState}</span></div>
                <div className={styles.title}>处理意见</div>
                <div>
                    <Form {...formItemLayout} className="create-form">
                        <Form.Item
                            colon={false}
                            htmlFor='processOpinion'
                            label={<span></span>}
                        >
                            {getFieldDecorator('processOpinion', {
                                initialValue: suggestionsDetail.processOpinion,
                            })(<TextArea rows={4} />)}
                        </Form.Item>
                    </Form>

                </div>
            </Modal>
        );
    }
}

ProducDetails.propTypes = {
    title: PropTypes.string,// 弹框标题
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    history: PropTypes.object, // 路由history
    match: PropTypes.object,// 路由history
    data: PropTypes.object,
    suggestionsDetail: PropTypes.object,
    getSuggestionsDetail: PropTypes.func,
    refresh: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        suggestionsDetail: state.Suggestions.suggestionsDetail,
    };

};
const ForgotProducDetails = Form.create({ name: 'create-form' })(ProducDetails);// 校验
export default connect(mapStateToProps, { getSuggestionsDetail })(ForgotProducDetails);