import * as T from './actiontypes';

/**
 * 出库单列表
 */
const plistInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
// 发票管理
export const merchantInvoiceTableSource = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_MERCHANTINVOICE_TABLESOURCE:
            return payload.result;
        default:
            return state;
    }
};
// 发票详情
export const merchantInvoiceDetail = (state = {}, { type, payload }) => {
    switch (type) {
        case T.SET_MERCHANTINVOICE_DETAIL:
            return payload.result;
        default:
            return state;
    }
};
// 结算单状态
export const settlementStatus = (state = [], { type, payload }) => {
    switch (type) {
        case T.SET_SETTLEMENT_STATUS:
            return payload.result;
        default:
            return state;
    }
};