import * as MESSAGE from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
export const messageClassifyList = (state = [], action) => {
    switch (action.type) {
        case MESSAGE.SET_MESSAGECLASSIFY_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const messageClassifyDetail = (state = {}, action) => {
    switch (action.type) {
        case MESSAGE.SET_MESSAGECLASSIFY_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};
export const messageClassifyLists = (state = [], action) => {
    switch (action.type) {
        case MESSAGE.SET_MESSAGECLASSIFY_LISTS:
            return action.payload.result;
        default:
            return state;
    }
};
export const messagePushPort = (state = [], action) => {
    switch (action.type) {
        case MESSAGE.SET_MESSAGEPUSHPORT:
            return action.payload.result;
        default:
            return state;
    }
};