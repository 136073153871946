import * as T from './actiontypes';


const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    resultTotal: 0
};

const recInit = {
    recoLiveResp: {
        dataList: [],
        pageNum: 1,
        pageSize: 20,
        resultTotal: 0
    },
    recoNumber: 0,
    recoTimes: 0
};

const feedInit = {
    feedRecoResp: {
        dataList: [],
        pageNum: 1,
        pageSize: 20,
        resultTotal: 0
    },
    recoNumber: 0,
    recoTimes: 0
};

export const listLiveData = (state = initData, action) => {
    let dataList = [];
    switch (action.type) {
        case T.SET_LIST_LIVEDATA:
            return action.payload.result;
        case T.CHANGE_ISHOMERECO: // 首页推荐状态改变
            dataList = state.dataList.map((item) => {
                if (item.id == action.payload.id) {
                    item.isHomeReco = action.payload.isHomeReco;
                }
                return item;
            });
            return { ...state, dataList };
        case T.CHANGE_ISCHANNELRECO: // 频道推荐状态改变
            dataList = state.dataList.map((item) => {
                if (item.id == action.payload.id) {
                    item.isChannelReco = action.payload.isChannelReco;
                }
                return item;
            });
            return { ...state, dataList };
        default:
            return state;
    }
};

export const listLiveStatus = (state = [], action) => {
    switch (action.type) {
        case T.SET_LIST_LIVESTATUS:
            return action.payload.result;
        default:
            return state;
    }
};

export const listLiveSelect = (state = initData, action) => {
    switch (action.type) {
        case T.SET_LIST_LIVESELECT:
            return action.payload;
        default:
            return state;
    }
};

export const listVideoData = (state = initData, action) => {
    switch (action.type) {
        case T.SET_LIST_VIDEODATA:
            return action.payload;
        default:
            return state;
    }
};

export const listVideoSelect = (state = initData, action) => {
    switch (action.type) {
        case T.SET_LIST_VIDEOSELECT:
            return action.payload;
        default:
            return state;
    }
};

export const listVideoUsername = (state = initData, action) => {
    switch (action.type) {
        case T.SET_LIST_VIDEOUSERNAME:
            return action.payload.result;
        default:
            return state;
    }
};

export const listVideoProduct = (state = initData, action) => {
    switch (action.type) {
        case T.SET_LIST_VIDEOPRODUCT:
            return action.payload.result;
        default:
            return state;
    }
};

export const listVideoDetail = (state = initData, action) => {
    switch (action.type) {
        case T.SET_LIST_VIDEODETAIL:
            return action.payload;
        default:
            return state;
    }
};

export const listLiveHistory = (state = initData, action) => {
    switch (action.type) {
        case T.SET_LIST_LIVEHISTORY:
            return action.payload;
        default:
            return state;
    }

};

export const listLiveRecommend = (state = recInit, action) => {
    switch (action.type) {
        case T.SET_LIST_LIVERECOMMEND:
            return action.payload;
        default:
            return state;
    }
};

export const listLiveStar = (state = initData, action) => {
    switch (action.type) {
        case T.SET_LIST_LIVESTAR:
            return action.payload.result;
        default:
            return state;
    }

};

export const listLiveSell = (state = initData, action) => {
    switch (action.type) {
        case T.SET_LIST_LIVESELL:
            return action.payload.result;
        default:
            return state;
    }
};


export const listVCRecommend = (state = feedInit, action) => {
    switch (action.type) {
        case T.SET_LIST_VCRECOMMEND:
            return action.payload;
        default:
            return state;
    }
};

//主播类型枚举
export const anchorTypeEnum = (state = [], action) => {
    switch (action.type) {
        case T.SET_ANCHOR_TYPE_ENUM:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 存储'- 社区列表'数据源
 * @param {*} state 
 * @param {*} action 
 */
const defaultState = {
    tableSource: [],// 社区列表数据源
    ListDetail: [],// 礼物分类详情数据源
    SelectData: [],// 分类状态下拉选项
    SelectRecoData: [],//首页与频道公用分类下拉
    paginations: {}, //分页对象

    commutySourceDetail: {},// 社区查看详情列表数据
    paginationDetail: {}, //社区查看详情列表分页对象

    videoSourceDetail: {},// 视频查看详情列表数据
    videopaginationDetail: {}, //视频查看详情列表分页对象
};


export const communityDate = (state = defaultState, action) => {
    let newTableSource, newSelectData, newDetailQuery, newPaginations;
    switch (action.type) {
        case T.SET_TABLE_DATA: //秀场管理 -频道列表- 社区列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };

        case T.SET_COMSTATE_DATA: //获取'秀场管理 -道具管理- 礼物分类-分类状态下拉
            newSelectData = action.payload.result.dataList;
            return { ...state, SelectData: newSelectData };
        case T.SET_COMRECO_DATA: //获取'秀场管理 -道具管理- 礼物分类-首页与频道公用分类下拉
            newSelectData = action.payload.result;
            return { ...state, SelectRecoData: newSelectData };

        case T.SET_COMDETAIL_DATA: //存储'社区分类查看详情'数据源
            newDetailQuery = action.payload.result || {};
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, commutySourceDetail: newDetailQuery, paginationDetail: newPaginations };

        case T.SET_VIDEODETAIL_DATA: //存储'视频分类查看详情'数据源
            newDetailQuery = action.payload.result || {};
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, videoSourceDetail: newDetailQuery, videopaginationDetail: newPaginations };
        default:
            return state;
    }

}; 