import * as T from './actiontypes';
/**
 * 【商品举报】数据源
 */
const defaultState = {
    tableSource: [],//分类列表数据源
    // selectData: [],// 状态下拉选项
    paginations: {}, //分页对象
    // banned: {},//举报禁播，封禁类型和封禁理由
    userDetail: {},//详情
};

/**
 * @param {*} state 
 * @param {*} action 
 */
export const productReportDate = (state = defaultState, action) => {
    let newTableSource, newSelectData, newPaginations, newbanned,newDetail;
    switch (action.type) {
        case T.SET_PRODUCTLIST_DATA: //列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_PRODUCTDETAIL_DATA: //商品举报详情
            newDetail = action.payload.result;
            return { ...state, userDetail: newDetail, };
        default:
            return state;
    }
};