import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table, Switch, } from 'antd';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';

class TableContent extends Component {
    state = {
    };

    _columns = () => [
        {
            title: '商品货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            align: 'left',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'left',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '供应商',
            dataIndex: 'supplierName',
            key: 'supplierName',
            align: 'left',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品分类',
            dataIndex: 'categoryFullName',
            key: 'categoryFullName',
            align: 'left',
            width: cellWidth.time,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '分类类型',
            dataIndex: 'categorySubTypeName',
            key: 'categorySubTypeName',
            align: 'left',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '采购数量(件)',
            dataIndex: 'inventoryQuanity',
            key: 'inventoryQuanity',
            align: 'left',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '剩余数量(件)',
            dataIndex: 'remainInvQuanity',
            key: 'remainInvQuanity',
            align: 'left',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商城数量(件)',
            dataIndex: 'mallInvQuanity',
            key: 'mallInvQuanity',
            align: 'left',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '兑换方式',
            dataIndex: 'convertMode.value',
            key: 'convertMode.value',
            align: 'left',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '推荐',
            dataIndex: 'isHomeReco',
            key: 'isHomeReco',
            align: 'left',
            width: cellWidth.normal,
            render: (text, item) => (this._switchCell(text, item, 'recommend'))
        },
        {
            title: '状态',
            dataIndex: 'stateCode',
            key: 'stateCode',
            align: 'left',
            width: cellWidth.normal,
            render: (text, item) => (this._switchCell(text, item, 'condition'))
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'left',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'left',
            fixed: 'right',
            width: cellWidth.normal,
            render: (id, record) => this._tableAction(id, record)
        },
    ];

    //表格项内容为开关 
    _switchCell = (key, item, type) => {
        let checked = true;
        if (type == 'condition') {
            if (key == 'PULL_OFF_SHELVES') checked = false;
        } else if (type == 'recommend') {
            if (key == 0) checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checkedChildren={type == 'condition' ? '上架' : 'ON'}
                    unCheckedChildren={type == 'condition' ? '下架' : 'OFF'}
                    checked={checked}
                    onChange={(value) => {
                        if (type == 'recommend') {
                            this._actionHandle(item, 'recommend', value);
                        } else {
                            this._actionHandle(item, 'condition', value);
                        }
                    }}
                />
            </div>
        );
    };

    // 表格操作
    _tableAction = (key, record) => {
        return (
            <div className={styles.action}>
                {/* <div className={styles.item} onClick={() => this._actionHandle(key, 'editor')}>编辑</div> */}
                <div className={styles.item} onClick={() => this._actionHandle(key, 'detail', record)}>详情</div>
                {/* <div className={styles.item} onClick={() => this._actionHandle(key, 'inventory', record)}>库存</div>
                <div className={styles.item} onClick={() => this._actionHandle(key, 'delete')}>删除</div> */}
                <div className={styles.item} onClick={() => this._actionHandle(key, 'terms', record)}>设置兑换条件</div>
            </div>
        );
    };

    // 操作表格
    _actionHandle = (id, type, value) => {
        this.props.tableAction(id, type, value);
    }

    //处理表格数据
    _processingData = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data && data.map((item) => {
            item.stateCode = item.state.code ? item.state.code : '';
            item.supportVouchersVlu = item.supportVouchers == '1' ? '支持' : '不支持';
            return item;
        });
    }

    render() {
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    columns={this._columns()}
                    pagination={false}
                    dataSource={this._processingData(dataList)}
                    scroll={{ x: 'max-content', y: this.props.scrollY }} />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={this.props.paginationChange} />
            </div>
        );
    }
}


TableContent.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
    scrollY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};



export default XTableHOC(TableContent);