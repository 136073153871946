/**
 * 审核详情弹框
 */
import React from 'react';
import { connect } from 'react-redux';
import { Modal, Input, Radio, Alert, message } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { XMediaFn } from '@/components/xqxc_ui';
import styles from './AdvocacyAudit.module.scss';
import ProductTable from '../conponents/ProductTable';
import { getCommunityAuditDetail } from '@/reducers/auditmanage/community/actions';

const RadioGroup = Radio.Group;
const { TextArea } = Input;

class AdvocacyAudit extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        updata: true,
        reason: '',
        result: '',
        communityAuditDetail: { state: {}, getSpokesmanTaskResp: { rewardType: {} }, goodsList: [] }
    }

    componentDidMount() {
        const { accountId, feedId, showType } = this.props;
        this.props.getCommunityAuditDetail({ accountId, feedId, showType });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.state.updata && this.setState({
            updata: false, communityAuditDetail: nextProps.communityAuditDetail
        });
    }

    _sendReq = (utl, data) => {
        this.setState({ confirmLoading: true }, () => {
            http(utl, data, 'POST').then(() => {
                message.success('社区审核操作成功。');
                this.setState({ visible: false, confirmLoading: false });
                this.props.closeMode(true);
            }).catch((error) => {
                message.error(error.message);
                this.setState({ confirmLoading: false });
            });
        });
    }

    _handleOk = () => {
        const { accountId, feedId, showType } = this.props;
        const { result, reason } = this.state;
        if (result == 'APPROVAL') {
            this._sendReq('/graphics/graphicsReview', { accountId, feedId, result: 'APPROVAL', reason, showType });
        } else {
            if (reason) {
                this._sendReq('/graphics/graphicsReview', { accountId, feedId, result: 'REJECT', reason, showType });
            } else {
                message.error('请输入不通过原因！');
            }
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    _reason = (e) => {
        this.setState({
            reason: e.target.value
        });
    }

    _result = (e) => {
        this.setState({
            result: e.target.value
        });
    }

    render() {
        const { visible, confirmLoading, communityAuditDetail, reason } = this.state;
        return (
            <Modal
                width={1000}
                bodyStyle={{ paddingRight: 10 }}
                centered
                title='社区审核'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <header>
                        <Alert message="审核通过后奖金将发放给用户" banner={true} type="warning" showIcon />
                    </header>
                    <main>
                        <article className={styles.taskInfo}>
                            <div className={styles.textInfo}>
                                <div className={styles.infoBox}>
                                    <p className={styles.label}>会员账号：</p>
                                    <p className={styles.text}>{communityAuditDetail.tel}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.label}>任务名称：</p>
                                    <p className={styles.text}>{communityAuditDetail.getSpokesmanTaskResp.taskName}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.label}>任务等级：</p>
                                    <p className={styles.text}>{communityAuditDetail.getSpokesmanTaskResp.targetUsersDesc}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.label}>是否专场：</p>
                                    <p className={styles.text}>{communityAuditDetail.getSpokesmanTaskResp.isSpecial == '1' ? '是' : '否'}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.label}>任务要求：</p>
                                    <p className={styles.text}>{communityAuditDetail.getSpokesmanTaskResp.taskLengthDesc}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.label}>任务奖励：</p>
                                    <p className={styles.text}>{communityAuditDetail.getSpokesmanTaskResp.rewardNum}{communityAuditDetail.getSpokesmanTaskResp.rewardTypeDesc}</p>
                                </div>
                                <div className={styles.explan}>
                                    <p className={styles.label}>任务说明：</p>
                                    <p className={styles.remark}>{communityAuditDetail.getSpokesmanTaskResp.taskDesc}</p>
                                </div>
                            </div>
                            <div className={styles.imgInfo}>
                                <div className={styles.image}>
                                    <XMediaFn
                                        dataType='image'
                                        dataSource={communityAuditDetail.getSpokesmanTaskResp.mainImgUrl}
                                    />
                                    <p className={styles.imgText}>{communityAuditDetail.getSpokesmanTaskResp.goodsName}</p>
                                </div>
                                <div className={styles.image}>
                                    <XMediaFn
                                        dataType='video'
                                        dataSource={communityAuditDetail.getSpokesmanTaskResp.demoUrl}
                                    />
                                    <p className={styles.imgText}>示例</p>
                                </div>
                            </div>
                        </article>
                        <div className={styles.line}></div>
                        <article className={styles.auditInfo}>
                            <div className={styles.infoBox} style={{ display: 'flex' }}>
                                <p className={styles.title}>社区标题：</p>
                                <p className={styles.text}>{communityAuditDetail.feedTitle}</p>
                            </div>
                            <div className={styles.infoBox} style={{ display: 'flex' }}>
                                <p className={styles.title}>社区分类：</p>
                                <p className={styles.text}>{communityAuditDetail.className}</p>
                            </div>
                            <div className={styles.imgInfo}>
                                <p className={styles.title}>社区内容：</p>
                                <div className={styles.infoBox}>
                                    <div className={styles.imgs}>
                                        <XMediaFn dataType='image' style={{ marginTop: '10px' }} dataSource={communityAuditDetail.fileNames} />
                                    </div>
                                    <div className={styles.content}>
                                        <div className={styles, top}>
                                            {communityAuditDetail.content}
                                        </div>
                                        <div className={styles.bottom}>
                                            {communityAuditDetail.content ? communityAuditDetail.content.length : 0}个字
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.handelInfo}>
                                <div className={styles.infoBox}>
                                    <p className={styles.title}>审核状态：</p>
                                    <RadioGroup onChange={this._result} style={{ marginLeft: '10px', marginTop: '10px' }}>
                                        <Radio value={'APPROVAL'}>通过</Radio>
                                        <Radio value={'REJECT'}>不通过</Radio>
                                    </RadioGroup>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.title}>审核意见：</p>
                                    <TextArea
                                        onChange={this._reason}
                                        value={reason}
                                        style={{ marginTop: '10px' }}
                                        placeholder="请输入不通过原因"
                                        autosize={{ minRows: 3, maxRows: 6 }}
                                    />
                                </div>
                            </div>
                        </article>
                        <article className={styles.goodsInfo}>
                            <ProductTable renderData={communityAuditDetail.goodsList} />
                        </article>
                    </main>
                </div>
            </Modal>
        );
    }
}

AdvocacyAudit.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getCommunityAuditDetail: PropTypes.func.isRequired,
    communityAuditDetail: PropTypes.object,
    accountId: PropTypes.number,
    feedId: PropTypes.number,
    showType: PropTypes.string
};

const mapStateToProps = (state) => ({
    communityAuditDetail: state.communityAuditData.communityAuditDetail,
});

export default connect(mapStateToProps, { getCommunityAuditDetail })(AdvocacyAudit);