/**
 * 秀场管理 Saga集合
 */
import { getMusicClassSelectSource, getMusicTypeTableSource, getMusicListTableSource } from './music/saga';
import { getStickypaperTypeTableSource, getStickypaperListTableSource, getStickypaperClassSelectSource } from './stickypaper/saga';

import { giftSource, giftSourceState, glistSource, } from './propsmanage/saga';//道具管理
import { graphicVideoList, liveList, bannedtypeDropDown, } from './forbitrecord/saga';//封禁记录
import { atmosphereSource, atmoslistSource, atmosphereState, atmosbuySource, } from './atmosphere/saga';//氛围管理
import { startgiftSource, startgiftState, startglistSource, starsbuySource, starsvaluebuySource, } from './present/saga';//礼物管理

import { getStudioRoomTableSource, getLiveSort, getLiveStatus, getLiveClassifyStatus, getVideoData, getCommunityData } from './channelclassification/saga';

import {
    getListLiveData,
    getListLiveStatus,
    getListLiveSelect,
    getListLiveStar,
    getListLiveSell,
    getListVideoData,
    getListVideoSelect,
    getListLiveHistory,
    getListLiveRecommend,
    getListVideoDetail,
    getListVideoUsername,
    getListVideoProduct,
    getListVCRecommend,
    communityTableSource,
    communitySelectSource,
    communityDetailSource,
    communitySelectRecoSource,
    videoDetailSource,
    getAnchorTypeEnum,
} from './channellist/saga';

export default [
    // 音乐管理
    getMusicClassSelectSource,
    getMusicTypeTableSource,
    getMusicListTableSource,

    // 贴纸管理
    getStickypaperTypeTableSource,
    getStickypaperListTableSource,
    getStickypaperClassSelectSource,

    //频道分类
    //直播间列表
    getStudioRoomTableSource,
    getLiveSort,
    getLiveStatus,
    getLiveClassifyStatus,
    //视频列表
    getVideoData,
    getListVideoDetail,
    //社区列表
    getCommunityData,

    //频道列表
    //直播间列表
    getListLiveData,
    getListLiveStatus,
    getListLiveSelect,
    getListLiveHistory,
    getListVideoUsername,
    getListVideoProduct,
    getListLiveRecommend,
    getListLiveStar,
    getListLiveSell,
    getAnchorTypeEnum,

    //视频列表
    getListVideoData,
    getListVCRecommend,
    getListVideoSelect,
    videoDetailSource,//获取'秀场管理 -频道列表- 视频分类查看详情'

    communityTableSource,//获取'秀场管理 -频道列表- 社区列表'
    communitySelectSource,//获取'秀场管理 -频道列表- 社区分类下拉'
    communityDetailSource,//获取'秀场管理 -频道列表- 社区分类查看详情'
    communitySelectRecoSource,//首页与频道公用分类下拉

    giftSource,//获取'秀场管理 -道具管理- 道具分类列表
    giftSourceState,//获取'秀场管理 -道具管理- 道具分类-分类状态下拉
    glistSource,//道具列表数据

    atmosphereSource,//氛围管理-氛围分类
    atmoslistSource,//氛围管理-氛围列表
    atmosphereState,//氛围管理-氛围分类下拉
    atmosbuySource,//氛围管理-购买记录

    startgiftSource,//魅力值、人气值礼物分类列表
    startgiftState,//魅力值、人气值礼物分类状态  
    startglistSource,//魅力值、人气值礼物列表
    starsbuySource,//魅力值购买记录
    starsvaluebuySource,//人气值购买记录

    graphicVideoList,//社区、视频列表
    liveList,//直播列表
    bannedtypeDropDown,//封禁类型下拉框
];
