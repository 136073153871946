//获取/保存服务商列表
export const GET_FACILITATORAUDIT_LIST = 'FACILITATORAUDIT/GET_FACILITATORAUDIT_LIST';
export const SET_FACILITATORAUDIT_LIST = 'FACILITATORAUDIT/SET_FACILITATORAUDIT_LIST';

//获取/保存状态下拉
export const GET_FACILITATORAUDIT_STATUS = 'FACILITATORAUDIT/GET_FACILITATORAUDIT_STATUS';
export const SET_FACILITATORAUDIT_STATUS = 'FACILITATORAUDIT/SET_FACILITATORAUDIT_STATUS';

//获取/保存服务商详情
export const GET_FACILITATORAUDIT_DETAIL = 'FACILITATORAUDIT/GET_FACILITATORAUDIT_DETAIL';
export const SET_FACILITATORAUDIT_DETAIL = 'FACILITATORAUDIT/SET_FACILITATORAUDIT_DETAIL';

//获取/保存服务商审核日志
export const GET_FACILITATORAUDIT_AUDITLOG = 'FACILITATORAUDIT/GET_FACILITATORAUDIT_AUDITLOG';
export const SET_FACILITATORAUDIT_AUDITLOG = 'FACILITATORAUDIT/SET_FACILITATORAUDIT_AUDITLOG';