/**
 *  商户管理 - 酒店商户
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class merchantsHotel extends React.Component {
    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value, params) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case 'MER_STOR':  //店铺列表
                addOneBreadcrumbPath({
                    title: '店铺列表',
                    path: '/home/businessmanage/merchantsHotel/shopInfo/' + value,
                });
                history.push({
                    pathname: '/home/businessmanage/merchantsHotel/shopInfo/' + value,
                });
                break;
            case 'MER_DETAIL': //详情
                addOneBreadcrumbPath({
                    title: '酒店详情',
                    path: '/home/businessmanage/merchantsHotel/detailInfo/' + value,
                });
                history.push({
                    pathname: '/home/businessmanage/merchantsHotel/detailInfo/' + value,
                });
                break;
            case 'NEWCREATE':
                addOneBreadcrumbPath({
                    title: '新建酒店商户',
                    path: '/home/businessmanage/merchantsHotel/createInfo'
                });
                history.push({
                    pathname: '/home/businessmanage/merchantsHotel/createInfo',
                });
                break;
            case 'MER_EDITOR':
                addOneBreadcrumbPath({
                    title: '编辑酒店商户',
                    path: '/home/businessmanage/merchantsHotel/editorInfo/' + value,
                });
                history.push({
                    pathname: '/home/businessmanage/merchantsHotel/editorInfo/' + value,
                });
                break;
            case 'MEMBER':
                addOneBreadcrumbPath({
                    title: '商户推荐会员列表',
                    path: '/home/businessmanage/merchantsHotel/memberlist'
                });
                history.push({
                    pathname: '/home/businessmanage/merchantsHotel/memberlist/' + 'hotel',
                    userItem: {
                        merchantId: value,
                        merchantName: params
                    }
                });
                break;
            case 'EARNING':
                addOneBreadcrumbPath({
                    title: '商户会员收益明细',
                    path: '/home/businessmanage/merchantsHotel/earninfo'
                });
                history.push({
                    pathname: '/home/businessmanage/merchantsHotel/earninfo',
                    userItem: {
                        merchantId: value,
                        merchantName: params
                    }
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

merchantsHotel.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(merchantsHotel);