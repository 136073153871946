export const GET_CLASSIFICATIONLIST_DATA = 'CLASSIFICATION/GET_CLASSIFICATIONLIST_DATA'; // 人气值攻略分类-列表
export const SET_CLASSIFICATIONLIST_DATA = 'CLASSIFICATION/SET_CLASSIFICATIONLIST_DATA'; // 人气值攻略分类-列表
export const CLEAN_CLASSIFICATIONLIST_DATA = 'CLASSIFICATION/CLEAN_CLASSIFICATIONLIST_DATA'; // // 人气值攻略分类-列表

export const GET_CLASSIFICATIONSTATE_DATA = 'CLASSIFICATION/GET_CLASSIFICATIONSTATE_DATA'; // 人气值攻略分类状态
export const SET_CLASSIFICATIONSTATE_DATA = 'CLASSIFICATION/SET_CLASSIFICATIONSTATE_DATA'; // 人气值攻略分类状态

export const GET_STRATEGYLIST_DATA = 'CLASSIFICATION/GET_STRATEGYLIST_DATA'; // 人气值攻略列表
export const SET_STRATEGYLIST_DATA = 'CLASSIFICATION/SET_STRATEGYLIST_DATA'; //人气值攻略列表

export const GET_STRATEGYAPPLIST_DATA = 'CLASSIFICATION/GET_STRATEGYAPPLIST_DATA'; // 人气值攻略列表新建应用端口
export const SET_STRATEGYAPPLIST_DATA = 'CLASSIFICATION/SET_STRATEGYAPPLIST_DATA'; // 人气值攻略列表新建应用端口

export const GET_STRATEGYDETAIL_DATA = 'CLASSIFICATION/GET_STRATEGYDETAIL_DATA'; // 人气值攻略列表详情
export const SET_STRATEGYDETAIL_DATA = 'CLASSIFICATION/SET_STRATEGYDETAIL_DATA'; // 人气值攻略列表详情

export const GET_STRATEGY_TYPE = 'CLASSIFICATION/GET_STRATEGY_TYPE'; // 攻略类型枚举
export const SET_STRATEGY_TYPE = 'CLASSIFICATION/SET_STRATEGY_TYPE'; // 攻略类型枚举
