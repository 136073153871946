import * as T from './actiontypes';

export const getInvoiceList = (value) => ({
    type: T.GET_INVOICELIST,
    payload: value
});
// 运营中心列表
export const getOperatingCenterReconciliationTableSource = (value) => ({
    type: T.GET_OPERATINGCENTERRECONCILIATION_TABLESOURCE,
    payload: value
});
// 运营中心下拉
export const getlistOperationsIdAndName = (value) => ({
    type: T.GET_OPERATINGCENTERRECONCILIATION_LIST,
    payload: value
});
// 运营详情
export const getOperationCenterDetail = (value) => ({
    type: T.GET_OPERATINGCENTERRECONCILIATION_DETAIL,
    payload: value
});
