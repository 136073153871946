/**
 * 商品标签创建或编辑弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message, Input } from 'antd';
import styles from './CreateEdit.module.scss';
import { XInput, XUpload } from '@/components/xqxc_ui';

const { TextArea } = Input;

class CreateEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
            tagName: '',
            tagDesc: '',
            imgUrl: '',
            labelImgs: []
        };

        if ((this.props.data.labelItem || {}).imgUrl) {
            this.state.labelImgs = [{
                uid: '0',
                name: 'default',
                status: 'done',
                url: props.data.labelItem.imgUrl,
            }];
        }
    }

    componentDidMount() {
        this.setState({
            tagName: (this.props.data.labelItem).tagName,
            tagDesc: (this.props.data.labelItem).tagDesc,
            imgUrl: (this.props.data.labelItem).imgUrl,
        });
    }

    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST').then(() => {
            message.success(winTxt);
            this.setState({
                visible: false,
                confirmLoading: false
            });
            this.props.closeMode(true);
        }).catch((e) => {
            message.error(e.message);
            this.setState({ confirmLoading: false });
        });
    }

    // 点击确认
    _handleOk = () => {
        const { tagName, tagDesc, labelImgs, imgUrl } = this.state;
        const { url, action, labelItem } = this.props.data;
        switch (action) {
            case 'create':
                if (!tagName || !labelImgs || !imgUrl) {
                    message.warn('请补全信息！');
                    return;
                } else {
                    this.setState({ confirmLoading: true }, () => {
                        this._sendReq(url, { tagName, tagDesc, imgUrl }, '添加成功。');
                    });
                }
                break;
            case 'edit':
                if (!tagName || !imgUrl) {
                    message.warn('请补全信息！');
                    return;
                } else {
                    this.setState({ confirmLoading: true }, () => {
                        this._sendReq(url, { id: labelItem.id, tagName, tagDesc, imgUrl }, '编辑成功。');
                    });
                }
                break;
        }
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _tagDescChange = (e) => {
        this.setState({ tagDesc: e.target.value });
    }

    render() {
        const { visible, confirmLoading, title, tagName, tagDesc, labelImgs } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.tagName}>
                        <XInput
                            inputStyle={{ width: '320px' }}
                            label='商品标签'
                            placeholder='请输入商品标签'
                            value={tagName}
                            bindThis={this}
                            bindName='tagName'
                            maxLength={50}
                        />
                    </div>
                    <div className={styles.tagDesc}>
                        <div className={styles.label}>标签描述</div>
                        <TextArea
                            style={{ width: '320px', marginLeft: '10px' }}
                            placeholder='请输入标签描述'
                            value={tagDesc}
                            onChange={this._tagDescChange}
                            autosize={{ minRows: 2, maxRows: 6 }}
                            maxLength={1024}
                        />
                    </div>
                    <div className={styles.upload}>
                        <div className={styles.label}>上传图片</div>
                        <div className={styles.icon}>
                            <XUpload
                                uploadButton="singleImg"
                                handleChange={(file) => {
                                    if (file.status === 'done') {
                                        this.setState({
                                            imgUrl: file.qiniuInfo.key,
                                        });
                                    } //保存保存
                                    if (file.status === 'removed') {
                                        this.setState({
                                            imgUrl: '',
                                        });
                                    } //移除逻辑
                                }}
                                antdUploadProps={{
                                    listType: 'picture-card',
                                    showUploadList: false,
                                    // 这个是初始化值
                                    defaultFileList: labelImgs
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}


CreateEdit.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

export default CreateEdit;