import { createModel } from '@/store/tools';

const model = {
    namespace: 'org',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getOrgList': T('getOrgList'),
        },
        actions: {
            'getOrgList': A('getOrgList'), // 获取机构列表
        },
        sagas: {
            'getOrgList': S('getOrgList', '/dataCenter/company/list'),
        },
        reducers: {
            'getOrgList': R('getOrgList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);