import { combineReducers } from 'redux';
import { advertPlacementEdit, advertPlacementDelete, advertPlacementDetail, advertPlacementStartAndStop, advertPlacementAudit, advertPlacementList, advertPlacementAdd, advertList, advertStrategyList, advertPlacementStatus } from './reducer';

export default combineReducers({
	advertPlacementList,
	advertPlacementAudit,
	advertPlacementStartAndStop,
	advertPlacementDetail,
	advertPlacementDelete,
	advertPlacementAdd,
	advertPlacementEdit,
	advertList,
	advertStrategyList,
	advertPlacementStatus
});