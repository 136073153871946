/**
 * 服务商广告
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton, XSelect, showConfirm } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { message, } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import AdvertTable from './components/AdvertTable';
import model from '@/reducers/advertmanage/advertService/model';
import http from '@/assets/api/http';


class Main extends React.Component {

    state = {
        StatusObj: {},
        title: '',//广告名称
        goodsName: '', //商品名称
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getAdvertState(); // 状态
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, title, StatusObj, goodsName } = this.state;
        this.searchCache = {
            title,
            state: StatusObj.code,
            goodsName,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAdvertServiceList(this.searchCache);

        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }


    // 重置
    _resetHandle = () => {
        this.setState({ title: '', StatusObj: {}, goodsName: '', pageNum: 1, pageSize: 10 }, () => KeepAlive.saveResetFunc(this._searchHandle));
    }

    // 新建
    _newHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新增服务商广告',
            path: '/home/advertmanage/advertService/add',
        });
        history.push({
            pathname: '/home/advertmanage/advertService/add'
        });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => this._searchHandle('useCache'));
    }
    // 表格操作
    _tableAction = (item, type, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'edit':
                addOneBreadcrumbPath({
                    title: '编辑服务商广告',
                    path: {
                        pathname: '/home/advertmanage/advertService/add',
                        search: `?id=${item.id}`
                    },
                });
                history.push({
                    pathname: '/home/advertmanage/advertService/add',
                    search: `?id=${item.id}`
                });
                break;
            case 'delete':
                showConfirm('确认是否删除?', '', () => {
                    http('/ad/merchant/delete', { id: item.id }, 'POST')
                        .then(() => {
                            message.success('删除成功!');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'switch':
                http('/ad/merchant/changeState', {
                    id: item.id
                }, 'POST').then(() => {
                    message.success('状态修改成功');
                    this._searchHandle('useCache');
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
            case 'sort':
                http('/ad/merchant/reSort', {
                    id: item.id,
                    sortNum: value,
                }, 'POST').then(() => {
                    message.success('排序成功');
                    this._searchHandle('useCache');
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
        }
    }

    render() {
        const { title, goodsName, StatusObj } = this.state;
        const { AdvertServiceList, AdvertState } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='广告名称'
                                placeholder='请输入'
                                value={title}
                                bindThis={this}
                                bindName='title'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                showSearch
                                renderData={AdvertState}
                                dataIndex='value'
                                keyIndex='code'
                                value={StatusObj.value}
                                bindThis={this}
                                bindName='StatusObj'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='商品名称'
                                placeholder='请输入'
                                value={goodsName}
                                bindThis={this}
                                bindName='goodsName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.row2}>
                        <XOKButton
                            style={{ width: '90px' }}
                            label='新增'
                            onClick={this._newHandle}
                        />
                    </div>
                    <AdvertTable renderData={AdvertServiceList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getAdvertServiceList: PropTypes.func,
    AdvertServiceList: PropTypes.object,
    getAdvertState: PropTypes.func,
    AdvertState: PropTypes.array,
};
const mapStateToProps = (state) => ({
    AdvertServiceList: state.advertService.AdvertServiceList,
    AdvertState: state.advertService.AdvertState,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
