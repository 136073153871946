/**
 * 股票收益订单
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import StockTable from './components/StockTable';
import { XInput, XOKButton, XCancelButton, XTabNav, XDatePicker, XSelect } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/ordermanage/stockearorder/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockOrder: '',//股票名称 
            merchantsName: {},//商户
            state: {},//状态
            accountName: '',//用户账号
            accountType: { code: 'MEMBERS', value: '会员' },//用户类型
            tab: [
                { id: 1, label: '全部' },
                { id: 2, label: '已结算' },
                { id: 3, label: '未结算' },
            ],
            curTabID: 1,  // 当前显示的Tab页ID
            orderStartTime: null,//下单日期-开始时间
            orderEndTime: null,//下单日期-结束时间
            settlementStartTime: null,//结算日期-开始时间
            settlementEndTime: null,//结算日期-结束时间
        };
    }

    componentDidMount() {
        const { getMerchantList, getStockState, getUserAccountType } = this.props;
        getMerchantList();
        getStockState();
        getUserAccountType();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, stockOrder, merchantsName, orderStartTime, orderEndTime, settlementStartTime, settlementEndTime, curTabID, state, accountType, accountName } = this.state;
        let curType;//区分全部、已结算，未结算等
        if (curTabID == '1') {
            if (Object.keys(state).length > 0) {
                curType = [state.code];//状态
            } else {
                curType = [];
            }
        } else if (curTabID == '2') {
            curType = ['FINISHED'];
        } else if (curTabID == '3') {
            curType = ['INVOICING', 'PAIED'];
        }
        this.searchCache = {
            statusList: curType,//结算状态
            // merchantId: merchantsName.merchantId,//商户
            stockName: stockOrder,//股票名称
            accountType: accountType.code,//用户类型
            accountNo: accountName,//用户账号
            createStartTime: orderStartTime != null ? orderStartTime.format('YYYY-MM-DD HH:mm:ss') : null, //下单日期
            createEndTime: orderEndTime != null ? orderEndTime.format('YYYY-MM-DD HH:mm:ss') : null, // 下单日期
            settleStartTime: settlementStartTime != null ? settlementStartTime.format('YYYY-MM-DD HH:mm:ss') : null, //结算日期
            settleEndTime: settlementEndTime != null ? settlementEndTime.format('YYYY-MM-DD HH:mm:ss') : null, // 结算日期
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getStockTableList(this.searchCache);//商户须知列表
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ stockOrder: '', accountName: '', accountType: { code: 'MEMBERS', value: '会员' }, merchantsName: {}, orderStartTime: null, orderEndTime: null, settlementStartTime: null, settlementEndTime: null, state: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getStockTableList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getStockTableList(this.searchCache);
        }
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({
            curTabID: tabID,
            stockOrder: '',
            orderEndTime: null,
            orderStartTime: null,
            settlementStartTime: null,
            settlementEndTime: null,
            merchantsName: {},
            state: {},
            accountName: '',
            accountType: { code: 'MEMBERS', value: '会员' },
        }, () => {
            this._searchHandle();// 重新渲染更新列表
        });
    }

    render() {
        const { stockOrder, merchantsName, orderStartTime, curTabID, orderEndTime, settlementStartTime, settlementEndTime, state, accountType, accountName } = this.state;
        const { stockList, merchantList, stockState, acountTypes } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <div className={styles.searchBox}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='股票名称'
                                    placeholder='请输入'
                                    value={stockOrder}
                                    bindThis={this}
                                    bindName='stockOrder'
                                />
                            </SearchItem>
                            {/* <SearchItem>
                                <XSelect
                                label='商户'
                                placeholder='请选择'
                                renderData={merchantList.merchantIdAndNames || []}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                value={merchantsName.merchantName}
                                showSearch={true}
                                bindThis={this}
                                bindName='merchantsName'
                                isOptionBindID={true}
                            />
                            </SearchItem> */}
                            <SearchItem>
                                <XDatePicker
                                    label='下单日期'
                                    value={orderStartTime}
                                    bindThis={this}
                                    bindName='orderStartTime'
                                />
                                <XDatePicker
                                    style={{ marginRight: '20px' }}
                                    label=''
                                    value={orderEndTime}
                                    bindThis={this}
                                    bindName='orderEndTime'
                                />
                            </SearchItem>
                            {curTabID == '1' && <SearchItem>
                                <XSelect
                                    label='状态'
                                    placeholder='请选择'
                                    renderData={stockState}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={state.value}
                                    bindThis={this}
                                    bindName='state'
                                    showSearch={true}
                                    isOptionBindID={true}
                                />
                            </SearchItem>}
                            <SearchItem>
                                <XInput
                                    label='用户账号'
                                    placeholder='请输入'
                                    value={accountName}
                                    bindThis={this}
                                    bindName='accountName'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='用户类型'
                                    placeholder='请选择'
                                    renderData={acountTypes}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={accountType.value}
                                    showSearch={true}
                                    bindThis={this}
                                    bindName='accountType'
                                    isOptionBindID={true}
                                />
                            </SearchItem>
                            {curTabID == '1' && <SearchItem>
                                <XDatePicker
                                    label='结算日期'
                                    value={settlementStartTime}
                                    bindThis={this}
                                    bindName='settlementStartTime'
                                />
                                <XDatePicker
                                    style={{ marginRight: '20px' }}
                                    label=''
                                    value={settlementEndTime}
                                    bindThis={this}
                                    bindName='settlementEndTime'
                                />
                            </SearchItem>}
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <StockTable renderData={stockList} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}


Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象

    getMerchantList: PropTypes.func,//商户数据(action)
    merchantList: PropTypes.object,
    getStockTableList: PropTypes.func,//列表数据(action)
    stockList: PropTypes.object,
    getStockState: PropTypes.func,//状态数据(action)
    stockState: PropTypes.array,
    acountTypes: PropTypes.array,
    getUserAccountType: PropTypes.func,//用户类型(action)
};

const mapStateToProps = (state) => ({
    merchantList: state.stockearOrder.merchantList, // 商户
    stockList: state.stockearOrder.stockTableList, // 列表
    stockState: state.stockearOrder.stockState, // 状态
    acountTypes: state.stockearOrder.acountType, // 用户类型
});

export default connect(mapStateToProps, { ...model.actions })(Main);
