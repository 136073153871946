import { createModel } from '@/store/tools';


const model = {
    namespace: 'homestaylayout',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getHomestayList': T('getHomestayList'),
        },
        actions: {
            'getHomestayList': A('getHomestayList'),
        },
        sagas: {
            'getHomestayList': S('getHomestayList', '/hotel/homestayLevel/homestayList'),
        },
        reducers: {
            'homestayList': R('getHomestayList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
        }
    })
};
export default createModel(model);