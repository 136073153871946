/**
 * 修改优惠券
 */
import React from 'react';
import { Modal, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './CouponModal.module.scss';
import { XSelect } from '@/components/xqxc_ui';
import { message } from 'antd';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import model from '@/reducers/usermanage/member/model';

const { TextArea } = Input;
class CouponModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        cType: {},
        cName: {},
        remarks: '',
        haveCoupon: 0,
        wordLength: 0, // 文本框字数
    }

    componentDidMount() {
        const { getCouponType, SinglePatch, data } = this.props;
        if (SinglePatch) {
            http('coupon/coupon/getUserUsableCouponNum', {
                userId: data[0]
            }, 'POST').then((response) => {
                this.setState({ haveCoupon: response.result });
            }).catch((e) => {
                message.error(e.message);
            });
        }
        getCouponType();
    }

    // 确认
    _handleOk = () => {
        const { cName, remarks } = this.state;
        const couponId = cName.id;
        const data = {
            couponId,
            userIdsList: this.props.data,
            remarks
        };
        this.setState({ confirmLoading: true });
        http('/coupon/coupon/batchReceiveCoupon', data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('赠送成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        }).finally(() => {
            this.setState({ confirmLoading: false });
        });
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // textarea输入框
    _areaChangeHandle = (e) => {
        this.setState({ remarks: e.target.value, wordLength: e.target.value.length });
    }

    // 当修改权限为单个对象时渲染
    _renderSinglePatch = () => {
        const { SinglePatch } = this.props;
        const { haveCoupon } = this.state;

        if (SinglePatch) {
            return (
                <div className={styles.single_patch}>
                    <div className={styles.cur_coupon}>当前拥有优惠券：{haveCoupon}张</div>
                </div>
            );
        } else {
            return null;
        }
    }

    // 优惠券类型下拉
    _selectChange = (item) => {
        this.props.getCouponName({ couponType: item.code });
        this.setState({ cName: {} });
    }

    render() {
        const { visible, confirmLoading, cType, cName, remarks, wordLength } = this.state;
        const { couponType, couponName } = this.props;

        return (
            <Modal
                width={470}
                centered
                title='赠送优惠券'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    {this._renderSinglePatch()}
                    <div className={styles.up}>
                        <XSelect
                            style={{}}
                            selectStyle={{ width: '100px' }}
                            label='赠送优惠券'
                            placeholder='请选择'
                            renderData={couponType}
                            onChange={this._selectChange}
                            dataIndex='name'
                            keyIndex='code'
                            value={cType.name}
                            bindThis={this}
                            bindName='cType'
                        />
                        <XSelect
                            style={{}}
                            selectStyle={{ width: '220px' }}
                            placeholder='优惠券名称'
                            renderData={couponName}
                            dataIndex='couponName'
                            keyIndex='id'
                            value={cName.couponName}
                            bindThis={this}
                            bindName='cName'
                        />
                    </div>
                    <div className={styles.down}>
                        <div className={styles.downlabel}>系统消息</div>
                        <TextArea
                            className={styles.textArea}
                            rows={4}
                            onChange={this._areaChangeHandle}
                            value={remarks}
                            maxLength={200}
                        />
                    </div>
                    <div style={{ float: 'right', marginRight: '22px' }}>字数：{wordLength}/200</div>
                </div>
            </Modal>
        );
    }
}

CouponModal.propTypes = {
    data: PropTypes.array, // 需要修改魅力值的对象
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    SinglePatch: PropTypes.bool, // 权限：单个修改还是批量修改，当为true时表示单个修改
    getCouponType: PropTypes.func,
    getCouponName: PropTypes.func,
    couponType: PropTypes.array,
    couponName: PropTypes.array,
};

const mapStateToProps = (state) => ({
    couponType: state.member.getCouponType,
    couponName: state.member.getCouponName,
});

export default connect(mapStateToProps, { ...model.actions })(CouponModal);