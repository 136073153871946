import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [],
    };

    _columns = () => [
        {
            title: '商户账号',
            dataIndex: 'merchantLoginName',
            key: 'merchantLoginName',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '营业执照名称',
            dataIndex: 'businessLicenseName',
            key: 'businessLicenseName',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '企业类型',
            dataIndex: 'subjectType.value',
            key: 'subjectType.value',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户类型',
            dataIndex: 'merchantType.value',
            key: 'merchantType.value',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '提交时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'status.value',
            key: 'status.value',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '备注说明',
            dataIndex: 'remark',
            key: 'remark',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: cellWidth.big,
            fixed: 'right',
            render: (id, item) => this._TableAction(item.merchantId, item)
        },
    ];

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _TableAction = (key, item) => {
        const { tableAction } = this.props;
        const visible = item.status.code == 'COMMIT_INFO_SUCCESS' ? true : false;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'update', item)}>刷新</div>
                <div className={styles.item} onClick={() => tableAction(key, 'detail', item)}>查看详情</div>
                {/* {visible ? <div className={styles.item} onClick={() => tableAction(key, 'reset')}>驳回</div> : null}
                <div className={styles.item} onClick={() => tableAction(key, 'APPImg', item)}>APP截图生成</div> */}
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys });
            this.props.tableAction(selectedRowKeys, 'batchData');
        },
        // getCheckboxProps: record => ({
        //     disabled: record.status.code !== 'COMMIT_INFO_SUCCESS',
        // }),
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
        this.props.tableAction([], 'batchData');
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;

        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => record.merchantId}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string
};
export default XTableHOC(TableContent, 40);

