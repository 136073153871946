/**
 * 兑换审核
 */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './PointsAudit.module.scss';
import { Modal, message, Typography, Radio } from 'antd';
import { regYuanToFen, regFenToYuan } from '@/util/money';
import { XTextArea } from '@/components/xqxc_ui';

const { Text } = Typography;

const PointsAudit = ({ close, param }) => {

    const [loading, setLoading] = useState(false);
    const [pass, setPass] = useState(''); // 审核
    const [remark, setRemark] = useState(''); // 留言
    const [auditTypeEnum, setAuditTypeEnum] = useState([{ code: 'PASS_AUDIT', value: '同意兑换' }, { code: 'FAIL_AUDIT', value: '不同意兑换' }]);
    const [desc, setDesc] = useState('');

    useEffect(() => {
        const { id } = param;

        http('/settle/stockExchange/stockExchangeRecordList', { id }).then((response) => {
            if (response.result && Array.isArray(response.result.dataList) && response.result.dataList.length > 0) {
                setDesc(response.result.dataList[0].desc);
            }
        }).catch((error) => {
            message.error(error.message);
        });
    }, []);

    const onSubmit = () => {
        const { id } = param;

        const query = {
            id,
            auditType: pass,
            remark
        };

        setLoading(true);
        http('/settle/stockExchange/stockExchangeRecordAudit', query, 'POST')
            .then(() => {
                message.success('操作成功');
                setLoading(false);
                close(true);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    const { realName, mobilePhone, integral, accountNo } = param;

    return <Modal
        width={600}
        centered
        title='兑换审核'
        visible={true}
        confirmLoading={loading}
        onOk={onSubmit}
        onCancel={() => close(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div className={styles.container}>
            <div className={styles.item}>
                <div><Text>业务员：</Text></div>
                <div><Text>{realName}</Text></div>
            </div>
            <div className={styles.item}>
                <div><Text>手机号码：</Text></div>
                <div><Text>{mobilePhone}</Text></div>
            </div>
            <div className={styles.item}>
                <div><Text>兑换积分：</Text></div>
                <div><Text>{integral}</Text></div>
            </div>
            <div className={styles.item}>
                <div><Text>兑换至账户：</Text></div>
                <div><Text>{accountNo}</Text></div>
            </div>
            <div className={styles.item}>
                <div><Text>账户详情：</Text></div>
                <div><Text>{desc}</Text></div>
            </div>
            <div className={styles.item}>
                <div><Text>审核结果：</Text></div>
                <div>
                    <Radio.Group value={pass} onChange={e => setPass(e.target.value)}>
                        {
                            auditTypeEnum.map(i => {
                                return <Radio key={i.code} value={i.code}>{i.value}</Radio>;
                            })
                        }
                    </Radio.Group>
                </div>
            </div>
            {
                pass == 'FAIL_AUDIT' ? <div className={styles.item}>
                    <div><Text>备注原因：</Text></div>
                    <XTextArea
                        inputStyle={{ width: 320 }}
                        label=''
                        bindThis={setRemark}
                        maxLength={100}
                        bindName='remark'
                        value={remark}
                    />
                </div> : null
            }
        </div>
    </Modal>;
};

PointsAudit.propTypes = {
    close: PropTypes.func,
    param: PropTypes.object,
};

export default PointsAudit;