import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import quotemodel from '@/reducers/usermanage/member/model';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/auditmanage/memberupgrade/model';

const { getAreaCompany } = quotemodel.actions;

class UpgradeAreaCompany extends Component {

    static propTypes = {
        history: PropTypes.object,
        getAreaCompany: PropTypes.func, // 获取归属区域公司
        RESelectList: PropTypes.array, // 归属区域公司下拉列
        getMemberUpgradeareaTable: PropTypes.func,
        renderData: PropTypes.object,
        keepSecondNavBreadcrumb: PropTypes.func,
        addOneBreadcrumbPath: PropTypes.func,
        getStateEnum: PropTypes.func,
        stateEnum: PropTypes.array
    }

    static defaultProps = {
        stateEnum: [],
        RESelectList: [],
        renderData: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getAreaCompany, getStateEnum } = this.props;
        keepSecondNavBreadcrumb();
        getAreaCompany();
        getStateEnum();
    }

    state = {
        tel: '',
        companyItem: {},
        startTime: null,
        endTime: null,
        auditStatus: {},
        pageNum: 1,
        pageSize: 30
    }

    // 查询
    _searchHandle = (useCache) => {
        const { tel, companyItem, startTime, endTime, auditStatus, pageNum, pageSize } = this.state;
        this.searchCache = {
            tel, startTime, endTime, upgrade: 2,
            areaCompanyId: companyItem.id, auditState: auditStatus.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getMemberUpgradeareaTable(this.searchCache);
        KeepAlive.saveCache({ ...this.searchCache, companyItem, auditStatus });
    }

    // 重置
    _resetHandle = () => {
        this.setState({ tel: '', companyItem: {}, startTime: null, endTime: null, auditStatus: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { tel, companyItem, startTime, endTime, auditStatus } = this.state;
        const { history, renderData, RESelectList, addOneBreadcrumbPath, stateEnum } = this.props;
        return <KeepAlive id='UpgradeAreaCompany' render={(state) => {
            if (!this.state.__aliveInit) {
                this.setState({ ...state, __aliveInit: true }, () => {
                    this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                });
            }
        }}>
            <div className={styles.flexBoxContainer}>
                <SearchBox>
                    <SearchItem>
                        <XInput
                            label='会员账号'
                            placeholder='请输入会员账号'
                            bindThis={this}
                            bindName='tel'
                            value={tel}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='归属区域公司'
                            placeholder='请选择归属区域公司'
                            renderData={RESelectList}
                            bindThis={this}
                            bindName='companyItem'
                            dataIndex='companyName'
                            keyIndex='id'
                            value={companyItem.companyName}
                            showSearch={true}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XDatePicker
                            label='申请时间'
                            placeholder='请选择时间'
                            value={startTime}
                            bindThis={this}
                            bindName='startTime'
                            isFormat
                        />
                        <XDatePicker
                            label=''
                            style={{ marginRight: '20px' }}
                            placeholder='请选择时间'
                            value={endTime}
                            bindThis={this}
                            bindName='endTime'
                            isFormat
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='状态'
                            placeholder='请选择状态'
                            renderData={stateEnum}
                            bindThis={this}
                            bindName='auditStatus'
                            dataIndex='value'
                            keyIndex='code'
                            value={auditStatus.value}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </SearchItem>
                </SearchBox>
                <TableContent addOneBreadcrumbPath={addOneBreadcrumbPath} paginationChange={this._paginationChange} history={history} renderData={renderData} />
            </div>
        </KeepAlive>;
    }
}

export default connect(state => ({
    RESelectList: state.member.getAreaCompany,
    stateEnum: state.memberupgrade.stateEnum,
    renderData: state.memberupgrade.memberUpgradeareaTable,
}), { getAreaCompany, keepSecondNavBreadcrumb, addOneBreadcrumbPath, ...model.actions })(UpgradeAreaCompany);
