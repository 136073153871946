import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';

class TableContent extends React.Component {

    _columns = () => [
        {
            title: '会员账号',
            dataIndex: 'tel',
            key: 'tel',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所属区域公司',
            dataIndex: 'areaCompanyName',
            key: 'areaCompanyName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所属区域',
            dataIndex: 'address',
            key: 'address',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员等级',
            dataIndex: 'levelName',
            key: 'levelName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '来源',
            dataIndex: 'sourceName',
            key: 'sourceName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '总魅力值',
            dataIndex: 'starlightNumber',
            key: 'starlightNumber',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '总人气值',
            dataIndex: 'starNumber',
            key: 'starNumber',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '总信用值',
            dataIndex: 'availableShowRice',
            key: 'availableShowRice',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '可提现金(元)',
            dataIndex: 'money',
            key: 'money',
            width: cellWidth.small,
            align: 'center',
            render: (text) => (this._MoneyCell(text))
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: cellWidth.normal,
            fixed: 'right',
            render: (id, item) => this._TableAction(item.accountId)
        },
    ];

    _takeDataSource = (data) => {
        return data.map((item) => {
            item.address = '' + this._resolveAreaName(item.provinceName) + this._resolveAreaName(item.cityName) + this._resolveAreaName(item.areaName);
            item.status = item.state == 1 ? '正常' : '禁止';
            return item;
        });
    }

    _resolveAreaName = (areaName) => {
        if (areaName) {
            return areaName;
        } else {
            return '';
        }
    }

    _MoneyCell = (text) => {
        const money = regFenToYuan(parseInt(text));
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {money}
            </div>
        );
    };

    _TableAction = (key) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'MEMBER_INFO')}>用户信息</div>
            </div>
        );
    };

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.accountId}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    rowSelection: PropTypes.func, // 表格复选
    scrollY: PropTypes.string
};

export default XTableHOC(TableContent);

