/**
 * 审核管理 - 社区审核异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Audit from './actiontypes';

export const getCommunityAuditList = function* () {
    yield takeEvery(Audit.GET_COMMUNITYAUDIT_LIST, function* requestData(action) {
        try {
            let result = yield http('/graphics/listGraphicsReview', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_COMMUNITYAUDIT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getCommunityAuditDetail = function* () {
    yield takeEvery(Audit.GET_COMMUNITYAUDIT_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/graphics/graphicsReviewDetails', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_COMMUNITYAUDIT_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

