/**
 * 举报原因列表
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton, XTabNav } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { message, } from 'antd';
import AdvertTable from './components/AdvertTable';
import CreateModal from './modal/CreateModal';
import *as actions from '@/reducers/reportmanage/reportReason/actions';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';


class Main extends React.Component {

    state = {
        reasonTitle: '',//原因标题
        tab: [
            { id: 1, label: '用户/秀场举报' },
            { id: 2, label: '商品举报' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
    }

    componentDidMount() {

    }

    // 查询
    _searchHandle = (useCache) => {
        const { getReportReasonsList, getReportShopList, } = this.props;
        const { curTabID, pageSize, pageNum, reasonTitle, } = this.state;
        if (curTabID == '1') {
            this.searchCache = {
                reportTypeName: reasonTitle,//举报类型名称  
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            getReportReasonsList(this.searchCache);
        } else {
            this.searchCache = {
                reportTypeName: reasonTitle,//举报类型名称  
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            getReportShopList(this.searchCache);
        }
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ reasonTitle: '', }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建用户
    _newHandle = () => {
        this.setState({ currentOpenMode: 'create' });
    }
    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({
            curTabID: tabID,
            reasonTitle: '',
        }, () => {
            this._searchHandle();// 重新渲染更新列表
        });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const { curTabID } = this.state;
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        if (curTabID == 1) {
            this.props.getReportReasonsList(this.searchCache);//默认用户、秀场列表数据
        } else {
            this.props.getReportShopList(this.searchCache);//默认商品列表数据
        }
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 表格操作
    _tableAction = (item, type, value) => {
        const { curTabID, } = this.state;
        const { getReportReasonsList, getReportShopList, } = this.props;
        switch (type) {
            case 'switch':
                {
                    let method = curTabID == 1 ? '/show' : '/goods';
                    http(`${method}/reporttype/updateReportTypeState`, {
                        id: item.id,
                        state: (value === false) ? 0 : 1,
                    }, 'POST').then(() => {
                        message.success('状态修改成功');
                        curTabID == 1 ? getReportReasonsList(this.searchCache) : getReportShopList(this.searchCache);// 重新渲染更新列表
                    }).catch(() => {
                        message.error('状态修改异常');
                    });
                }
                break;
            case 'sort':
                {
                    let method = curTabID == 1 ? '/show' : '/goods';
                    http(`${method}/reporttype/updateReportTypeSortNo`, {
                        id: item.id,
                        sortNo: value,
                        classTypeCode: item.classTypeCode,
                    }, 'POST').then(() => {
                        message.success('排序成功');
                        curTabID == 1 ? getReportReasonsList(this.searchCache) : getReportShopList(this.searchCache);// 重新渲染更新列表
                    }).catch(() => {
                        message.error('排序异常');
                    });
                }
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'create':
                return <CreateModal title='创建原因' visible={true} curTabID={this.state.curTabID} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        const { curTabID, } = this.state;
        const { getReportReasonsList, getReportShopList, } = this.props;
        if (status) curTabID == 1 ? getReportReasonsList(this.searchCache) : getReportShopList(this.searchCache);// 重新渲染更新列表
    }

    render() {
        const { reasonTitle, curTabID } = this.state;
        const { tableSource, paginations, tableSourceShop, paginationsShop, } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <div className={styles.row1}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='原因标题'
                                    placeholder='请输入'
                                    value={reasonTitle}
                                    bindThis={this}
                                    bindName='reasonTitle'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.row2}>
                        <XOKButton
                            style={{ width: '90px', }}
                            label='新建'
                            onClick={this._newHandle}
                        />
                    </div>
                    <AdvertTable renderData={curTabID == 1 ? tableSource : tableSourceShop} tableAction={this._tableAction} paginationChange={this._paginationChange} curTabID={curTabID}
                        paginations={curTabID == 1 ? paginations : paginationsShop} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    getReportReasonsList: PropTypes.func, // 用户/秀场举报表格数据源的Action
    getReportShopList: PropTypes.func, // 用户/秀场举报表格数据源的Action
    tableSource: PropTypes.array, // 表格数据源
    paginations: PropTypes.object, // 分页数据
    tableSourceShop: PropTypes.array, // 商品举报列表
    paginationsShop: PropTypes.object, // 商品举报分页对象
};
const mapStateToProps = (state) => ({
    tableSource: state.reportReason.reportReasonsListDate.tableSource,
    paginations: state.reportReason.reportReasonsListDate.paginations,
    tableSourceShop: state.reportReason.reportReasonsListDate.tableSourceShop,
    paginationsShop: state.reportReason.reportReasonsListDate.paginationsShop,
});

export default connect(mapStateToProps, actions)(Main);
