/**
 * 派单确认 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { XInput, XOKButton, XCancelButton, XPagination } from '@/components/xqxc_ui';
import { Modal, Input, Alert, Form, Select, message, Table } from 'antd';
import numSwitch from '@/util/numSwitch';
import { autoPageNum, autopageSize } from '@/assets/config';
import merchantModel from '@/reducers/storehousefinancialmanage/paymanage/merchantModel';
import { tooltipStyle, tooltip, priceFenToYuanCell, cellWidth, tooltipClick } from '@/components/TableCell';
import { SearchBox, SearchItem } from '@/components/Layout';
import KeepAlive from '@/routes/KeepAlive';
import { regFenToYuan } from '@/util/money';
import styles from './AddRole.module.scss';
import md5 from 'js-md5';

const { TextArea } = Input;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
    // labelAlign: 'left',
};
const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
    // labelAlign: 'left',
};
class confirmate extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        status: '',
        reason: '',
        paymentAmount: 0,//付款合计
        ids: '',//id
        selectedRowKeys: [],
    }

    componentDidMount() {
        const { data } = this.props;
        const param = {
            pageNum: 1,
            pageSize: 30,
            paymnetApplictionIds: data
        };
        this.props.getPreDispatchList(param);
        // 费率
        this.props.getStarlightWithdrawEpibolyServiceFee();
    }

    // 点击确认
    _handleOk = () => {
        const { form: { validateFields } = {}, closeMode } = this.props;
        const { selectedRowKeys } = this.state;
        if(selectedRowKeys.length == 0) {
            message.warn('请选择需派单的项!');
            return;
        }
        validateFields((err, fieldsValue) => {
            if (!err) {
                this.setState({ confirmLoading: true });
                fieldsValue.password = md5(fieldsValue.password).toUpperCase();
                http('/settle/paymentApplication/starlightDispatch', { ...fieldsValue, paymentApplicationIds: selectedRowKeys }, 'POST').then((res) => {
                    if (res.code === '200') {
                        message.success('派单成功！');
                    }
                    this.setState({ confirmLoading: false });
                    closeMode(true);
                }).catch((res = {}) => {
                    message.error('派单失败，原因：' + res.message);
                    this.setState({ confirmLoading: false });
                });
            }
        });
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
     // 表格复选输入
     _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys, selectedRows });
            let tempData = [];
            let duplicateData = [];
            const { selectedRowsArr = [] } = this.state;
            selectedRowKeys.map(i => {
                selectedRows.map(j => {
                    if (i == j.paymentApplicationId) {
                        tempData.push(j);  
                    }
                });
            });
            // 数组去重
            duplicateData = this.duplicateFun([...selectedRowsArr,...tempData]); 
            let newData = [];
            selectedRowKeys.map(i=> {
                // newData = duplicateData.filter(j => j.paymentApplicationId == i);
                duplicateData.map(j => {
                    if (i == j.paymentApplicationId) {
                        newData.push(j);
                    }
                });
            });
            var total = newData.reduce((cur, next) => {
                return Number(cur||0)+Number(next.starLightAmount || 0);
            }, 0);
            this.setState({ selectedRowsArr: newData, total });
            console.log(newData, 'newData', total, 'total');
        },
    };
    // 数组对象去重
    duplicateFun = (buZhouList) => {
        let object= {};
        let open = buZhouList; 
        return buZhouList = open.reduce((cur,next) => {
          object[next.paymentApplicationId] ? '' : object[next.paymentApplicationId] = true && cur.push(next);
          return cur;
        },[]);
    }
    // 查询
    _searchHandle = (useCache) => {
        const { data } = this.props;
        const { pageNum, pageSize, subjectPhone, subjectName } = this.state;
        this.searchCache = {
            subjectPhone,
            subjectName,
            paymnetApplictionIds: data,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };

        this.props.getPreDispatchList(this.searchCache); // 发起请求
        KeepAlive.saveCache({ subjectPhone, subjectName, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】

    }
    // 重置
    _resetHandle = () => {
        this.setState({ subjectPhone: undefined, subjectName: undefined,  pageNum: 1, pageSize: 30}, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const {  subjectPhone, subjectName } = this.state;
        const { data } = this.props;
        this.setState({ pageNum, pageSize });
        this.searchCache = {
            subjectPhone,
            subjectName,
            paymnetApplictionIds: data,
            pageNum,
            pageSize
        };
        this.props.getPreDispatchList(this.searchCache); // 发起请求
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }
    _takeColumns = () => {
        const cols = [
            {
                title: '会员账号',
                dataIndex: 'subjectPhone',
                key: 'subjectPhone',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '姓名',
                dataIndex: 'subjectName',
                key: 'subjectName',
                width: cellWidth.normal,
                align: 'center',
                render: tooltip,
            },
            {
                title: '魅力值数量',
                dataIndex: 'starLightNumber',
                key: 'starLightNumber',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '提现金额',
                dataIndex: 'starLightAmount',
                key: 'starLightAmount',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: priceFenToYuanCell,
            },
        ];
        return cols;
    };

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    render() {
        const { selectedRowKeys = [], subjectName, subjectPhone, total } = this.state;
        const { visible, confirmLoading, title, ids, paymentAmount } = this.state;
        const { getFieldDecorator } = this.props.form;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        const { PreDispatchList: { dataList, pageNum, pageSize, resultTotal  }, StarlightWithdrawEpibolyServiceFee } = this.props;
        // 提现金额
        let totalMoney = Number(regFenToYuan(total));
        // 服务费
        let serviceMoney = Number(totalMoney * StarlightWithdrawEpibolyServiceFee/10000).toFixed(2);
        // 转账金额
        let transferMoney = (Number(totalMoney) + Number(serviceMoney)).toFixed(2);
        return (
            <Modal
                width={1100}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div>
                    <div style={{ width: 728, display: 'inline-block', marginRight: '2%' }}>
                        <div className={styles.flexBoxContainer}>
                            <SearchBox>
                                <SearchItem>
                                    <XInput
                                        label='会员账号'
                                        placeholder='请输入会员账号'
                                        value={subjectPhone}
                                        bindThis={this}
                                        bindName='subjectPhone'
                                    />
                                </SearchItem>
                                <SearchItem>
                                    <XInput
                                        label='姓名'
                                        placeholder='请输入姓名'
                                        value={subjectName}
                                        bindThis={this}
                                        bindName='subjectName'
                                    />
                                </SearchItem>
                                <SearchItem>
                                    <XOKButton
                                        label='查询'
                                        onClick={this._searchHandle}
                                    />
                                    <XCancelButton
                                        label='重置'
                                        onClick={this._resetHandle}
                                    />
                                </SearchItem>
                            </SearchBox>
                            <div className={styles.tableContainer} style={{ height: 380 }}>
                                <Table
                                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                                    rowKey={record => record.paymentApplicationId}
                                    rowSelection={this._rowSelection}
                                    columns={this._takeColumns()}
                                    dataSource={this._takeDataSource(dataList)}
                                    pagination={false}
                                    scroll={{ y: true }}
                                />
                                <XPagination
                                    resultTotal={resultTotal}
                                    pageSize={pageSize}
                                    pageNum={pageNum}
                                    onChange={this._paginationChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: 280, display: 'inline-block' }}>
                        <Alert message="说明：请先绑定用户，确认派单后，请及时打款到第三方；" type="info" showIcon style={{ textAlign: 'left' }} />
                        <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                            <Form.Item
                                colon
                                htmlFor='type'
                                label={<span>合计</span>}
                                style={{ textAlign: 'left', marginBottom: 0 }}
                            >
                                {selectedRowKeys.length} 笔
                            </Form.Item>
                            <Form.Item
                                colon
                                htmlFor='type'
                                label={<span>提现金额</span>}
                                style={{ textAlign: 'left', marginBottom: 0 }}
                            >
                                {totalMoney}
                            </Form.Item>
                            <Form.Item
                                colon
                                htmlFor='type'
                                label={<span>服务费(费率{StarlightWithdrawEpibolyServiceFee/100}%)</span>}
                                style={{ textAlign: 'left', marginBottom: 0 }}
                            >
                                {serviceMoney}
                            </Form.Item>
                            <Form.Item
                                colon
                                htmlFor='type'
                                label={<span>转账总金额</span>}
                                style={{ textAlign: 'left', marginBottom: 0 }}
                            >
                                {transferMoney} ({transferMoney ? numSwitch(transferMoney) : '零元'})
                        </Form.Item>
                            <Form.Item
                                colon
                                htmlFor='password'
                                label={<span>财务密码</span>}
                                style={{ textAlign: 'left', marginBottom: 0 }}
                            >
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: '请输入' }],
                                })(
                                    <Input.Password placeholder="请输入" />
                                )}
                            </Form.Item>
                            <Form.Item
                                colon
                                htmlFor='remark'
                                label={<span>备注</span>}
                            >
                                {getFieldDecorator('remark', {
                                    // rules: [{ required: true, message: '请输入策略名称' }],
                                })(
                                    <TextArea rows={4} />
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        );
    }
}

confirmate.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]), // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    getPreDispatchList: PropTypes.func,
    PreDispatchList: PropTypes.object,
    getStarlightWithdrawEpibolyServiceFee: PropTypes.func,
    StarlightWithdrawEpibolyServiceFee: PropTypes.number
};
const mapStateToProps = (state) => ({
    PreDispatchList: state.paymanage.PreDispatchList,
    StarlightWithdrawEpibolyServiceFee: state.paymanage.StarlightWithdrawEpibolyServiceFee,
});
const ForgotProducDetails = Form.create({ name: 'create-form' })(confirmate);// 校验
export default connect(mapStateToProps, {...merchantModel.actions})(ForgotProducDetails);