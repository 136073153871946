import * as Advert from './actiontypes';

/**
 * 获取'广告策略'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 */
export const getAdvertStrategList = ({strategyName, startTime, endTime, adSpaceId, state, pageSize, pageNum}) => ({
    type: Advert.GET_STRATEG_LIST,
    payload: {
        strategyName, startTime, endTime, adSpaceId, state, pageSize, pageNum
    }
});
export const getAdvertStrategStartAndStop = ({id, state}) => ({
    type: Advert.GET_STRATEG_STARTANDSTOP,
    payload: {id, state}
});

export const getAdvertStrategDetail = ({adStrategyId}) => ({
    type: Advert.GET_STRATEG_DETAIL,
    payload: {
        adStrategyId
    }
});
export const getAdvertStrategAdd = (param) => {
    return ({
        type: Advert.GET_STRATEG_ADD,
        payload: {
            ...param
        }
    });
};
export const getAdvertStrategEdit = (param) => {
    return ({
        type: Advert.GET_STRATEG_EDIT,
        payload: {
            ...param
        }
    });
};
export const getAdvertStrategDelete = (id) => ({
    type: Advert.GET_STRATEG_DELETE,
    payload: {
        id
    }
});
// 广告位下拉
export const getAdvertStrategAdsList = (id) => ({
    type: Advert.GET_STRATEG_ADSLIST,
    payload: {
        id
    }
});