import * as T from './actiontypes';

/**
 * 获取'魅力值、人气值礼物分类 列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} className 分类名称  
 * @param {number}  state 状态
 * @param {number}  classTypeCode 分类编码  魅力值、人气值分类编码(魅力值：STARLIGHT_GIFT_CLASS，人气值：STAR_GIFT_CLASS)
 */
export const getstarsgiftSource = (dataload) => ({
    type: T.GET_STARTGIFT_DATA,
    payload: {
        ...dataload
    }
});
/**
 * 获取'魅力值、人气值礼物分类列表- 分类状态'数据源(saga)
 *  @param 暂无
 */
export const getstartState = () => ({
    type: T.GET_GIFTSTATE_DATA,
});

/**
 * 获取'魅力值、人气值礼物列表 列表'数据源(saga) 
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} giftName 分类名称  
 * @param {number}  giftClassId 状态
 * @param {number}  giftCostType 分类编码  魅力值、人气值分类编码(魅力值：STARLIGHT，人气值：STARVALUE)
 */
export const getstarsglistSource = (dataload) => ({
    type: T.GET_STARTSGLIST_DATA,
    payload: {
        ...dataload
    }
});

/**
 * 获取'魅力值购买记录 列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * *@param {string} giftName  氛围名称
 * @param {number} classId  氛围分类
 */
export const getstarsbuySource = (dataload) => ({
    type: T.GET_STARTBUY_DATA,
    payload: {
      ...dataload
    }
});
/**
 * 获取'人气值购买记录 列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * *@param {string} giftName  氛围名称
 * @param {number} classId  氛围分类
 */
export const getstarsvaluebuySource = (dataload) => ({
    type: T.GET_STARTVALUEBUY_DATA,
    payload: {
        ...dataload
    }
});
