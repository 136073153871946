import React from 'react';
import styles from './TableContent.module.scss';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell, priceFenToYuanCellWithColor } from '@/components/TableCell';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';

class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '姓名',
            dataIndex: 'holderName',
            key: 'holderName',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '身份证号码',
            dataIndex: 'holderId',
            key: 'holderId',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '本月累计打款（元）',
            dataIndex: 'totalMonthPrice',
            key: 'totalMonthPrice',
            width: cellWidth.normal,
            align: 'left',
            render: (text, item) => {
                if(item.tip){
                    const txt = JSON.parse(item.tip);
                    return priceFenToYuanCellWithColor(text, txt.tip, txt.colorValue);
                }else{
                    return priceFenToYuanCell(text);
                }
            }
        },
        {
            title: '签约后累计打款（元）',
            dataIndex: 'totalAllPrice',
            key: 'totalAllPrice',
            width: cellWidth.normal,
            align: 'left',
            render: priceFenToYuanCell
        },
        {
            title: '签约时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    render() {
        const { paginationChange, scrollY } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default XTableHOC(TableContent, 40);