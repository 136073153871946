import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import GraphicsTable from '../graphicstable';
import StatisticsTable from '../statisticstable';

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '图文列表' },
            { id: 2, label: '图文统计' },
        ],
        curTabID: 1,
    }

    componentDidMount() {
        const { history } = this.props;
        if (history.location.state && history.location.state.tabID) {
            this.setState({ curTabID: history.location.state.tabID }); // 如果路由过来的state参数带tabID值，则渲染该tabID值下的内容
        }
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (curTabID) => {
        this.setState({ curTabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        switch (this.state.curTabID) {
            case 1:
                return <GraphicsTable curTabID={this.state.curTabID} updateContent={this.props.updateContent} />;
            case 2:
                return <StatisticsTable curTabID={this.state.curTabID} updateContent={this.props.updateContent} />;
            default:
                return <GraphicsTable curTabID={this.state.curTabID} updateContent={this.props.updateContent} />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={`${styles.page} ${styles.flexBoxContainer}`}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 跳转其它路由页面
};

export default Main;