/**
 * 监管账户
 */
import React from 'react';
import { XOKButton } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { message, Modal, Button, Row, Col, Input, Select, Form, Table, DatePicker } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { regFenToYuan } from '@/util/money';
import { priceFenToYuanCell } from '@/components/TableCell';
import styles from './index.module.scss';

const Option = Select.Option, RangePicker = DatePicker.RangePicker;

/** 分页配置 */
const PagionationConfig = {
    pageSize: 15,
    pageNum: 1
};

/** 通道类型 */
const AccessType = {
    /** 中联信 */
    ZLX_PAY: 'ZLX_PAY'
};

const columns = [
    {
        title: '完成时间',
        dataIndex: 'changeTime',
        width: 160,
        align: 'center'
    },
    {
        title: '业务流水号',
        dataIndex: 'businessNo',
        width: 180,
        align: 'center'
    },
    {
        title: '关联订单号',
        dataIndex: 'outBusinessNo',
        width: 180,
        align: 'center'
    },
    {
        title: '交易类型',
        dataIndex: 'tradeType',
        width: 150,
        align: 'center'
    },
    {
        title: '进出类型',
        dataIndex: 'changeType',
        width: 150,
        align: 'center'
    },
    {
        title: '金额',
        dataIndex: 'amount',
        width: 150,
        align: 'center',
        render: priceFenToYuanCell
    },
    {
        title: '余额',
        dataIndex: 'balance',
        align: 'center',
        render: priceFenToYuanCell
    },
];

class SettleAccount extends React.PureComponent {

    state = {
        visible: false,
        title: '标题',

        //common
        platOutAccountType: 'SETTLE_ACCOUNT',
        tradeBusinessTypeSelect: [],
        changeTypeSelect: [],
        timeRange: undefined,

        // conditions
        businessNo: undefined,//业务流水号
        outBusinessNo: undefined,//关联流水号
        tradeBusinessType: undefined,//交易业务类型
        changeType: undefined,//进出类型
        startTime: undefined,//开始时间
        endTime: undefined,//结束时间

        // table
        dataList: [],
        pageSize: 15,
        pageNum: 1,

        // info
        outAccountId: undefined,
        totalAmount: undefined,
        freezeAmount: undefined,
        balance: undefined
    };

    componentDidMount() {
        // 交易类型枚举
        http('/payment/tradeBusinessTypeQuery').then((res) => {
            if (res.code === 200) {
                this.setState({ tradeBusinessTypeSelect: res.result });
            }
        });

        // 进出类型枚举
        http('/payment/balanceChangeTypeQuery').then((res) => {
            if (res.code === 200) {
                this.setState({ changeTypeSelect: res.result });
            }
        });
    }

    open = (title) => {
        this.setState({ visible: true, title }, () => {
            this.getInfo();
            this.getTable();
        });
    }

    getInfo = () => {
        http('/pay/outAccount/balanceQuery', { accessType: AccessType.ZLX_PAY, platOutAccountType: this.state.platOutAccountType }).then((res = {}) => {
            const { outAccountId, totalAmount, freezeAmount, balance } = res.result;
            if (res.code === 200) {
                this.setState({ outAccountId, totalAmount, freezeAmount, balance });
            }
        }).catch((res) => {
            message.error(res.message);
        });
    }

    getTable = (pageSize = PagionationConfig.pageSize, pageNum = PagionationConfig.pageNum, conditions) => {
        http('/pay/outAccount/detailsQuery', { accessType: AccessType.ZLX_PAY, platOutAccountType: this.state.platOutAccountType, pageNum, pageSize, ...conditions }).then((res = {}) => {
            if (res.code === 200) {
                let { dataList, pageSize, pageNum } = res.result;
                this.setState({ dataList, pageSize, pageNum });
            }
        }).catch((res) => {
            message.error(res.message);
        });
    }

    paginationChange = (pageSize, pageNum) => {
        this.getTable(pageSize, pageNum, {});
    }

    search = () => {
        let conditions = {
            businessNo: this.state.businessNo,
            outBusinessNo: this.state.outBusinessNo,
            tradeBusinessType: this.state.tradeBusinessType,
            changeType: this.state.changeType,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
        };
        this.getTable(PagionationConfig.pageSize, PagionationConfig.pageNum, conditions);
    }

    reset = () => {
        this.setState({
            businessNo: undefined,
            outBusinessNo: undefined,
            tradeBusinessType: undefined,
            changeType: undefined,
            startTime: undefined,
            endTime: undefined
        });
    }

    close = () => {
        this.setState({ visible: false });
    }

    render() {

        const { visible, title, tradeBusinessTypeSelect, changeTypeSelect, pageSize, pageNum, outAccountId, dataList } = this.state;
        const { businessNo, outBusinessNo, tradeBusinessType, changeType, timeRange } = this.state;
        const totalAmount = regFenToYuan(this.state.totalAmount),
            freezeAmount = regFenToYuan(this.state.freezeAmount),
            balance = regFenToYuan(this.state.balance);

        return (
            <>
                <XOKButton
                    style={{ marginRight: '15px' }}
                    label='监管账户'
                    onClick={() => {
                        this.open('监管账户');
                    }}
                />
                <Modal
                    width={1200}
                    centered
                    visible={visible}
                    title={title}
                    onCancel={this.close}
                    destroyOnClose={true}
                    keyboard={true}
                    maskClosable={false}
                    footer={null}
                >
                    <div className={styles.info}>
                        <div>账号：{outAccountId}</div>
                        <div>总金额：￥{totalAmount}</div>
                        <div>冻结金额：￥{freezeAmount}</div>
                        <div>可用金额：￥{balance}</div>
                    </div>
                    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                        <Row gutter={24} className={styles.conditions}>
                            <Col span={8}>
                                <Form.Item label='业务流水号'><Input allowClear placeholder='请输入业务流水号' value={businessNo} onChange={(e) => this.setState({ businessNo: e.target.value })} /></Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='关联流水号'><Input allowClear placeholder='关联流水号' value={outBusinessNo} onChange={(e) => this.setState({ outBusinessNo: e.target.value })} /></Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='业务时间'>
                                    <RangePicker placeholder='请选择业务时间范围' value={timeRange} onChange={(e, [startTime, endTime]) => this.setState({ timeRange: e, startTime: startTime ? startTime + ' 00:00:00' : undefined, endTime: endTime ? endTime + ' 23:59:59' : undefined })} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='交易类型'>
                                    <Select placeholder='请输入交易类型' allowClear showSearch value={tradeBusinessType} onChange={(tradeBusinessType) => this.setState({ tradeBusinessType })}>
                                        {tradeBusinessTypeSelect.map((op) => <Option key={op.code}>{op.value}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='进出类型'>
                                    <Select placeholder='请选择业务类型' allowClear showSearch value={changeType} onChange={(changeType) => this.setState({ changeType })}>
                                        {changeTypeSelect.map((op) => <Option key={op.code}>{op.value}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                                <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 22 }}>
                                    <Button type='primary' htmlType='submit' onClick={this.search}>搜索</Button>
                                    <Button style={{ marginLeft: 8 }} onClick={this.reset}>重置</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Table rowKey={(r, i) => i} columns={columns} dataSource={dataList} scroll={{ y: 430 }} pagination={false} style={{ minHeight: 430 }} />
                    <XPagination
                        pageSize={pageSize}
                        pageNum={pageNum}
                        onChange={this.paginationChange}
                    />
                </Modal>
            </>
        );
    }
}

export default SettleAccount;