import { createModel } from '@/store/tools';

const model = {
    namespace: 'template',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getlistCustomTemplateClass': T('getlistCustomTemplateClass'), //获取自定义模板分类
            'getStateEnum': T('getStateEnum'), // 分类状态
            'getlistCustomTemplatePullDownClass': T('getlistCustomTemplatePullDownClass'), // 模板类型
            'getlistCustomTemplate': T('getlistCustomTemplate'), // 模板列表
        },
        actions: {
            'getlistCustomTemplateClass': A('getlistCustomTemplateClass'), // 获取自定义模板分类
            'getStateEnum': A('getStateEnum'), // 分类状态
            'getlistCustomTemplatePullDownClass': A('getlistCustomTemplatePullDownClass'), // 模板类型
            'getlistCustomTemplate': A('getlistCustomTemplate'), // 模板列表
        },
        sagas: {
            'getlistCustomTemplateClass': S('getlistCustomTemplateClass', '/admin/customtemplateclass/listCustomTemplateClass'),
            'getStateEnum': S('getStateEnum', '/enum/StateEnum'),
            'getlistCustomTemplatePullDownClass': S('getlistCustomTemplatePullDownClass', '/admin/customtemplateclass/listCustomTemplatePullDownClass'),
            'getlistCustomTemplate': S('getlistCustomTemplate', '/admin/customtemplate/listCustomTemplate'),
        },
        reducers: {
            'listCustomTemplateClass': R('getlistCustomTemplateClass', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'StateEnum': R('getStateEnum', []),
            'listCustomTemplatePullDownClass': R('getlistCustomTemplatePullDownClass', []),
            'listCustomTemplate': R('getlistCustomTemplate', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);