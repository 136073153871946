import React from 'react';
import PropTypes from 'prop-types';
import styles from './PurchaseContractTable.module.scss';
import { Table, Button } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

export default function PurchaseContractTable({ tableData, tableOperate, paginationChange }) {

    //表头
    const _columns = () => {
        return [
            {
                title: '合同号',
                dataIndex: 'id',
                key: 'id',
                width: '15%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '供应商',
                dataIndex: 'supplierName',
                key: 'supplierName',
                width: '15%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '采购总数量（件）',
                dataIndex: 'totalPurchaseNum',
                key: 'totalPurchaseNum',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '采购总金额（元）',
                dataIndex: 'totalPurchaseAmount',
                key: 'totalPurchaseAmount',
                width: '10%',
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '修改时间',
                dataIndex: 'gmtModified',
                key: 'gmtModified',
                width: '15%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '合同状态',
                dataIndex: 'state',
                key: 'state',
                width: '10%',
                align: 'center',
                render: (text) => text.value
            },
            {
                title: '操作',
                dataIndex: 'key',
                key: 'key',
                align: 'center',
                width: '25%',
                render: (key, record) => _tableOperate(record.id, record)
            },
        ];
    };

    //操作栏
    const _tableOperate = (key, { state, errorNum }) => {
        if (state.code === 'APPROVAL' || state.code === 'APPLY') {
            return <div className={styles.action}>
                <div className={styles.item} onClick={() => tableOperate(key, 'detailInfo')}>查看详情</div>
            </div>;
        } else if (state.code === 'NEW' || state.code === 'REJECT') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableOperate(key, 'editInfo')}>编辑</div>
                    <div className={styles.item} onClick={() => tableOperate(key, 'detailInfo')}>查看详情</div>
                    <div className={styles.item} onClick={() => tableOperate(key, 'commitAudit')}>提交审核</div>
                    <div className={styles.item} onClick={() => tableOperate(key, 'deleteInfo')}>删除</div>
                    {state.code === 'REJECT' &&
                        errorNum !== 0 &&
                        <div className={styles.item} onClick={() => tableOperate(key, 'fail')}>查看异常情况</div>
                    }
                </div>
            );
        }
    };

    //渲染
    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.id}
                columns={_columns()}
                dataSource={tableData.dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={tableData.resultTotal}
                pageSize={tableData.pageSize}
                pageNum={tableData.pageNum}
                onChange={paginationChange}
            />
        </div>
    );
}

//props校验
PurchaseContractTable.propTypes = {
    tableData: PropTypes.object,//表格数据元
    paginationChange: PropTypes.func,//分页逻辑
    tableOperate: PropTypes.func,//表格操作
};