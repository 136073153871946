import React, { useState, useEffect, Fragment } from 'react';
import styles from './TableStyle.module.scss';
import PropTypes from 'prop-types';
import { InputNumber, Table, Icon, Typography } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell, cellWidth, tooltipClick } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';

const { Text } = Typography;

const TableContent = ({
    renderData: {
        dataList = [],
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0
    } = {},
    tableAction,
    paginationChange,
    batchKeys = [],
    updateRecoSort,
    scrollY = 0
}) => {

    const [keys, setKeys] = useState([]);

    useEffect(() => { setKeys(batchKeys); }, [batchKeys]);

    const recommendCell = (text = 0, item) => {
        return <div>
            {
                item.isEdit ?
                    <InputNumber
                        precision={0}
                        value={text}
                        min={0}
                        onChange={value => updateRecoSort({ exchangeGoodsId: item.exchangeGoodsId, recoSort: value })}
                        onBlur={e => tableAction('updateReco', { exchangeGoodsId: item.exchangeGoodsId, recoSort: e.target.value })}
                    /> :
                    <Text>{text}</Text>
            }
            <Icon
                type='edit'
                theme='twoTone'
                style={{ marginLeft: '10px' }}
                onClick={() => updateRecoSort({ exchangeGoodsId: item.exchangeGoodsId, isEdit: !item.isEdit })}
            />
        </div>;
    };

    //表格操作
    const operateCell = (id, item) => {
        const { exchangeGoodsStatus: { code = '' } = {} } = item;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction('detail', item)}>详情</div>
                {
                    code === 'PUT_ON_SALE' ?
                        <div className={styles.item} onClick={() => tableAction('soldOut', [item.exchangeGoodsId])}>下架</div> :
                        <div className={styles.item} onClick={() => tableAction('putaway', [item.exchangeGoodsId])}>上架</div>
                }
            </div>
        );
    };

    const priceCell = (min, max) => {
        if (min == max) {
            return <Text>{regFenToYuan(max)}</Text>;
        }
        return <Text>{`${regFenToYuan(min)} - ${regFenToYuan(max)}`}</Text>;
    };

    //表头
    const columns = [
        {
            title: '商品图片',
            dataIndex: 'mainImgUrl',
            key: 'mainImgUrl',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: ImgCell
        },
        {
            title: '货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品主状态',
            dataIndex: 'goodsState.value',
            key: 'goodsState.value',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'exchangeGoodsStatus.value',
            key: 'exchangeGoodsStatus.value',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: 'SKU数',
            dataIndex: 'skuNumber',
            key: 'skuNumber',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => tooltipClick(text, () => {
                tableAction('sku', item);
            })
        },
        {
            title: '库存数量',
            dataIndex: 'invQuantity',
            key: 'invQuantity',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结算价（元）',
            dataIndex: 'minSetPrice',
            key: 'minSetPrice',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => priceCell(text, item.maxSetPrice)
        },
        {
            title: '销售价（元）',
            dataIndex: 'minSalePrice',
            key: 'minSalePrice',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => priceCell(text, item.maxSalePrice)
        },
        {
            title: '限购（件）',
            dataIndex: 'limitNumber',
            key: 'limitNumber',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: text => tooltip(text == '0' ? '不限' : text)
        },
        {
            title: '推荐',
            dataIndex: 'recoSort',
            key: 'recoSort',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: recommendCell
        },
        {
            title: '操作',
            dataIndex: 'exchangeGoodsId',
            key: 'exchangeGoodsId',
            width: cellWidth.normal,
            align: 'center',
            fixed: 'right',
            onCell: tooltipStyle,
            render: operateCell
        }
    ];

    // 表格复选输入
    const rowSelection = {
        selectedRowKeys: keys,
        onChange: (selectedRowKeys) => {
            tableAction('batch', selectedRowKeys);
        },
        getCheckboxProps: record => ({
            disabled: record.exchangeGoodsStatus.code === 'PULL_OFF_SHELVES',
        }),
    };

    // 清空表格复选框
    const cleanAllSelected = () => {
        tableAction('batch', []);
    };

    return (
        <div className={styles.tableContainer} style={{ height: '100%' }}>
            <XTableSelectionCount selectedNum={keys.length} onClean={cleanAllSelected} />
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.exchangeGoodsId}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataList}
                pagination={false}
                scroll={{ x: 'max-content', y: scrollY }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func,
    scrollY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    batchKeys: PropTypes.array,
    updateRecoSort: PropTypes.func
};

export default TableContent;