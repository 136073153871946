import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Switch, Tooltip } from 'antd';
import { XPagination, XMediaFn, } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const columns = (TableAction, actionClickHandle) => [
    {
        title: '分类ID',
        dataIndex: 'merchantKey',
        key: 'merchantKey',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '分类名称',
        dataIndex: 'className',
        key: 'className',
        width: '20%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '分类图标',
        dataIndex: 'imgUrl',
        key: 'imgUrl',
        align: 'center',
        width: '10%',
        render: (text, item) => (ImgCell(text, item))
    },
    {
        title: '攻略类型',
        dataIndex: 'strategyValue',
        key: 'strategyValue',
        align: 'center',
        width: '10%',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '推荐',
        dataIndex: 'isReco',
        key: 'isReco',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle, 'isReco'))
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle, 'state'))
    },
    {
        title: '界面自定义显示',
        dataIndex: 'isCustom',
        key: 'isCustom',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle, 'isCustom'))
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '10%',
        render: (id, record) => TableAction(id, actionClickHandle, record)
    }
];

const TableAction = (id, actionClickHandle, record) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'edit', record)}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'delete')}>删除</div>
        </div>
    );
};

// 表格项内容为图片
const ImgCell = (url, item) => {
    return (
        <div style={{ display: 'inline-flex' }}>
            <XMediaFn
                style={{ width: '50px', height: '50px', marginRight: '0' }}
                dataType='image'
                dataSource={url}
                key={item.id}
            />
        </div>
    );
};

// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle, type) => {
    let checked = true;
    if (sw == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={(value) => {
                    actionClickHandle(item, type, value);
                }}
            />
        </div>
    );
};

class TypeTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data && data.map((item) => {
            item.merchantKey = item.id;//操作key
            //攻略类型
            if (item.strategyType.value) item.strategyValue = item.strategyType.value;
            return item;
        });
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }
    render() {
        const { renderData, _paginationChange, pagiNation, } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => record.id}
                    columns={columns(TableAction, this._actionHandle)}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={pagiNation.resultTotal}
                    pageSize={pagiNation.pageSize}
                    pageNum={pagiNation.pageNum}
                    onChange={_paginationChange}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    pagiNation: PropTypes.object, // 分页数据
    _paginationChange: PropTypes.func,// 页面发生变化
};

export default TypeTable;