import React from 'react';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import BaseSetting from '../basesetting';
import CompanySetting from '../companysetting';
import SafeSetting from '../safesetting';
import { isPlat, isOC, isDance } from '@/assets/js/authType';

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '基本设置' },
            { id: 2, label: '安全设置' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
    };

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        switch (this.state.curTabID) {
            case 1:
                return (isPlat() || isOC() || isDance()) ? <BaseSetting curTabID={this.state.curTabID} /> : <CompanySetting />;
            case 2:
                return <SafeSetting curTabID={this.state.curTabID} />;
            default:
                return <BaseSetting curTabID={this.state.curTabID} />;
        }
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.left}>
                    <XTabNav
                        renderData={this.state.tab}
                        onChange={this._tabChangeHandle}
                        activedID={this.state.curTabID}
                        tabPosition='left'
                    />
                </div>
                <div className={styles.right}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

export default Main;