/**
 * 查看绑定用户
 */
import React from 'react';
import { Modal } from 'antd';
import { XInput, XOKButton } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './BindUserComModal.module.scss';
import BindUserTable from '../pages/communitycreate/components/BindUserTable';

// 模拟表格数据
const tableDataSource = [];
for(let i=0;i<20;i++){
  tableDataSource.push({
    key: 'key'+ i,
    c1: 'XD0012',
    c2: '张平',
    c4: '普通会员',
    c3: '福建省厦门思明区',
  });
}

class BindUserModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        input: '', 
    }

    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _handleCancel  = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _inputChangeHandle = (value) => {
        this.setState({input:value});
    }

    _searchHandle = () => {
        console.log('查看');
    }

    render(){
        const { visible, confirmLoading, input } = this.state;
        
        return(
            <Modal
                width={860}
                centered
                title='会员查询列表'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.input}>
                        <XInput 
                            style={{width:'214px', marginLeft: '-10px'}} 
                            inputStyle={{width:'192px'}}
                            label='' 
                            placeholder='会员账号、姓名' 
                            onChange={this._inputChangeHandle} 
                            value={input}
                        />
                        <XOKButton style={{width:'104px'}} label='会员查询列表' onClick={this._searchHandle} />
                    </div>
                    <div className={styles.table}>
                        <BindUserTable renderData={tableDataSource} />
                    </div>
                </div>
            </Modal>
        );
    }
}

BindUserModal.propTypes = {
    data: PropTypes.array, // 需要修改魅力值的会员对象数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default BindUserModal;