import { createModel } from '@/store/tools';

const model = {
    namespace: 'allStatisticsinfo',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getDateFormatEnum': T('getDateFormatEnum'),
            'getOrderSourceChannelEnum': T('getOrderSourceChannelEnum'),
            'getMerchantNameEnum': T('getMerchantNameEnum'),
            'getActivityTitle': T('getActivityTitle'),
            'getSelectActivityType': T('getSelectActivityType'),
            'getStatisSaleOrder': T('getStatisSaleOrder'),
            'getStatisTopGoodsOrder': T('getStatisTopGoodsOrder'),
            'getStatisWarehouseOrder': T('getStatisWarehouseOrder'),
            'getStatisAfterSaleOrder': T('getStatisAfterSaleOrder'),
            'getStatisOrderActivityType': T('getStatisOrderActivityType '),
            'getStatisOrderActivityInstance': T('getStatisOrderActivityInstance '),
        },
        actions: {
            'getDateFormatEnum': A('getDateFormatEnum'),
            'getOrderSourceChannelEnum': A('getOrderSourceChannelEnum'),
            'getMerchantNameEnum': A('getMerchantNameEnum'),
            'getActivityTitle': A('getActivityTitle'),
            'getSelectActivityType': A('getSelectActivityType'),
            'getStatisSaleOrder': A('getStatisSaleOrder'),
            'getStatisTopGoodsOrder': A('getStatisTopGoodsOrder'),
            'getStatisWarehouseOrder': A('getStatisWarehouseOrder'),
            'getStatisAfterSaleOrder': A('getStatisAfterSaleOrder'),
            'getStatisOrderActivityType': A('getStatisOrderActivityType'),
            'getStatisOrderActivityInstance': A('getStatisOrderActivityInstance'),
        },
        sagas: {
            'getDateFormatEnum': S('getDateFormatEnum', '/enum/DateFormatEnum'),
            'getOrderSourceChannelEnum': S('getOrderSourceChannelEnum', '/enum/OrderSourceChannelEnum'),
            'getMerchantNameEnum': S('getMerchantNameEnum', '/report/merchantName'),
            'getActivityTitle': S('getActivityTitle', '/report/activityTitle'),
            'getSelectActivityType': S('getSelectActivityType', '/marketing/activityInfo/selectActivityType'),
            'getStatisSaleOrder': S('getStatisSaleOrder', '/report/statisSaleOrder'),
            'getStatisTopGoodsOrder': S('getStatisTopGoodsOrder', '/report/statisTopGoodsOrder'),
            'getStatisWarehouseOrder': S('getStatisWarehouseOrder', '/report/statisWarehouseOrder'),
            'getStatisAfterSaleOrder': S('getStatisAfterSaleOrder', '/report/statisAfterSaleOrder'),
            'getStatisOrderActivityType': S('getStatisOrderActivityType', '/report/statisOrderActivityType'),
            'getStatisOrderActivityInstance': S('getStatisOrderActivityInstance', '/report/statisOrderActivityInstance'),
        },
        reducers: {
            'dateFormatEnum': R('getDateFormatEnum', []),
            'orderSourceChannelEnum': R('getOrderSourceChannelEnum', []),
            'merchantNameEnum': R('getMerchantNameEnum', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'activityTitle': R('getActivityTitle', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'selectActivityType': R('getSelectActivityType', []),
            'statisSaleOrder': R('getStatisSaleOrder', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'statisTopGoodsOrder': R('getStatisTopGoodsOrder', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'statisWarehouseOrder': R('getStatisWarehouseOrder', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'statisAfterSaleOrder': R('getStatisAfterSaleOrder', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'statisOrderActivityType': R('getStatisOrderActivityType', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'statisOrderActivityInstance': R('getStatisOrderActivityInstance', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);