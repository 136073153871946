import * as T from './actiontypes';

/**
 * 获取'秀场管理 -氛围管理-氛围分类 列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} className 氛围名称
 * @param {number} state 状态
 */
export const getatmosphereSource = (dataload) => ({
    type: T.GET_ATMOSPG_DATA,
    payload: {
        ...dataload
    }
});

/**
 * 获取'秀场管理 -氛围管理-氛围分类态'数据源(saga)
 *  @param 暂无
 */
export const getatmosphereState = () => ({
    type: T.GET_GIFTSTATE_DATA,
});


/**
 * 获取'秀场管理 -氛围管理-氛围列表 列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} atmosphereName 氛围名称
 * @param {number} showClassId 状态 
 */
export const getatmoslistSource = (dataload) => ({
    type: T.GET_MOSLIST_DATA,
    payload: {
        ...dataload,
    }
});

/**
 * 获取'秀场管理 -氛围管理-购买记录 列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * *@param {string} giftName  氛围名称
 * @param {number} classId  氛围分类
 */
export const getatmospherebuySource = (dataload) => ({
    type: T.GET_ATMOBUY_DATA,
    payload: {
        ...dataload
    }
});

