/**
 * 音乐列表的创建、编辑弹框
 */
import React from 'react';
import { Modal, Radio, Icon, Button, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './ListModal.module.scss';
import { XInput, XSelect } from '@/components/xqxc_ui';
import CusotmUpload, { generateFileList, generateDuration } from '@/components/NewUpload';
import { formValids } from '@/assets/js/utils';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;

class ListModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        musicName: this.props.data.musicName,
        singerName: this.props.data.singerName,
        state: this.props.data.state,
        isReco: this.props.data.isReco,
        isAccompany: this.props.data.isAccompany,
        selectDataSource: this.props.selectDataSource,
        musicClassId: this.props.data.musicClassId,
        __musicClassId: this.props.data.className,
        //musicDuration: { duration: this.props.data.musicDuration },

        musicFileNames: [],
        musicImgs: [],
        musicLyric: []
    }

    constructor(props) {
        super(props);
        this.state.musicFileNames = generateFileList(props.data.musicFileName);
        this.state.musicImgs = generateFileList(props.data.musicImg);
        this.state.musicLyric = generateFileList(props.data.musicLyric);
        this.state.musicDuration = generateDuration(props.data.musicDuration);
    }

    // 确认
    _handleOk = () => {
        try {
            const { musicFileNames, musicImgs, musicClassId, state, isReco, isAccompany, musicDuration, singerName, musicName, musicLyric } = this.state;

            let fields = {
                musicClassId,
                state,
                isReco,
                isAccompany,
                musicName,
                singerName,
                musicDuration: musicDuration.duration,
                musicImg: musicImgs.length ? (musicImgs[0].response ? musicImgs[0].response.key : musicImgs[0].url) : '',
                musicFileName: musicFileNames.length ? (musicFileNames[0].response ? musicFileNames[0].response.key : musicFileNames[0].url) : '',
                musicLyric: musicLyric.length ? (musicLyric[0].response ? musicLyric[0].response.key : musicLyric[0].url) : '',
            };
            if (formValids(fields, {
                musicName: '请输入音乐名称',
                singerName: '请输入演唱者',
                musicClassId: '请选择音乐分类',
                musicFileName: '请上传音乐文件',
                isAccompany: '请选择音乐类型',
                musicImg: '请上传音乐封面图片'
            })) {
                this.setState({ confirmLoading: true });
                const method = this.props.data.id === undefined ? 'createMusic' : 'updateMusicInfo';
                http(`/show/music/${method}`,
                    {
                        id: this.props.data.id,
                        ...fields
                    }, 'POST').then((response) => {
                        if (response.status == 200) {
                            message.success('操作成功');
                        }
                    }).catch((e) => {
                        message.error(e.message);
                    }).finally(() => {
                        this.setState({ confirmLoading: false, visible: false });
                        this.props.closeMode(true);
                    });
            }
        } catch (e) {
            message.error('数据保存异常');
        }
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _stateRadioValueValueChangeHandle = (e) => {
        this.setState({ state: e.target.value });
    }

    // 分类状态下拉列改变时触发
    _typeChangeHandle = (selectedItem) => {
        this.setState({ musicClassId: selectedItem.id, __musicClassId: selectedItem.className });
    }

    // 礼物名称输入改变时触发
    _nameInputChangeHandle = (value) => {
        this.setState({ musicName: value });
    }

    // 歌手姓名输入改变时触发
    _singerInputChangeHandle = (value) => {
        this.setState({ singerName: value });
    }

    // 推荐发生改变时触发
    _recommendRadioValueChangeHandle = (e) => {
        this.setState({ isReco: e.target.value });
    }

    _accompanyRadioValueChangeHandle = (e) => {
        this.setState({ isAccompany: e.target.value });
    }

    render() {
        const { visible, confirmLoading, title, state, __musicClassId, isReco, isAccompany, musicFileNames, musicImgs, musicDuration, musicLyric } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.zip}>
                        <div className={styles.label}>音乐</div>
                        <div className={styles.upload}>
                            <CusotmUpload
                                accept='.mp3'
                                fileList={musicFileNames}
                                returnAudioDuration
                                qiniuUploadProps={{
                                    uploadType: 'file'
                                }}
                                onChange={(info) => {
                                    const musicFileNames = info.fileList.length > 1 ? [info.fileList.pop()] : info.fileList,
                                        musicName = this.state.musicName ? this.state.musicName : (info.fileList[0] || {}).name;
                                    this.setState({ musicFileNames, musicName });
                                }}
                                onFileListChange={(info, customFile) => {
                                    if (customFile && customFile.status === 'success') {
                                        this.setState({ musicDuration: customFile.duration });
                                    }
                                    if (!customFile || (customFile && customFile.status === 'error')) {
                                        this.setState({ musicDuration: { duration: 0, durationStr: '00:00' } });
                                    }
                                }}
                            >
                                <Button icon="upload">上传音乐</Button>
                            </CusotmUpload>
                        </div>
                    </div>
                    <XInput
                        style={{ marginTop: '30px' }}
                        inputStyle={{ width: '340px' }}
                        label='音乐名称'
                        placeholder='请输入音乐名称'
                        onChange={this._nameInputChangeHandle}
                        value={this.state.musicName}
                    />
                    <XInput
                        style={{ width: '408px', marginTop: '30px', marginLeft: '14px' }}
                        inputStyle={{ width: '340px' }}
                        label='演唱者'
                        placeholder='请输入歌手名称'
                        onChange={this._singerInputChangeHandle}
                        value={this.state.singerName}
                    />
                    <XSelect
                        style={{ marginTop: '30px', width: '410px' }}
                        selectStyle={{ width: '342px' }}
                        label='音乐分类'
                        placeholder='请选择音乐分类'
                        renderData={this.props.selectDataSource}
                        onChange={this._typeChangeHandle}
                        dataIndex='className'
                        keyIndex='id'
                        value={__musicClassId}
                    />
                    <div className={styles.radio}>
                        <div className={styles.label}>推荐</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._recommendRadioValueChangeHandle} value={isReco}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.radio}>
                        <div className={styles.label}>状态</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._stateRadioValueValueChangeHandle} value={state}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.zip} style={{ marginTop: '30px' }}>
                        <div className={styles.label} style={{ marginRight: 15, marginLeft: 2 }}>时长</div>
                        <span style={{ fontSize: 20 }}>{musicDuration.durationStr}</span>
                    </div>
                    <div className={styles.upload}>
                        <div className={styles.label}>音乐类型</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._accompanyRadioValueChangeHandle} value={isAccompany}>
                            <Radio value={0}>原创版</Radio>
                            <Radio value={1}>伴唱版</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.zip} style={{ marginTop: '30px' }}>
                        <div className={styles.label}>歌词</div>
                        <div className={styles.btn}>
                            <CusotmUpload
                                accept='.lrc,.txt'
                                fileList={musicLyric}
                                returnAudioDuration
                                qiniuUploadProps={{
                                    uploadType: 'file'
                                }}
                                onChange={(info) => {
                                    const musicLyric = info.fileList.length > 1 ? [info.fileList.pop()] : info.fileList;
                                    this.setState({ musicLyric });
                                }}
                            >
                                <Button icon="upload">上传歌词</Button>
                            </CusotmUpload>
                        </div>
                    </div>
                    <div className={styles.upload}>
                        <div className={styles.label}>音乐封面</div>
                        <div className={styles.icon}>
                            <CusotmUpload
                                accept='.png, .jpg, .jpeg'
                                fileList={musicImgs}
                                listType="picture-card"
                                wallMode="single"
                                removeError
                                qiniuUploadProps={{
                                    uploadType: 'image'
                                }}
                                onChange={(info) => {
                                    const musicImgs = info.fileList.length > 1 ? [info.fileList.pop()] : info.fileList;
                                    this.setState({ musicImgs });
                                }}
                            >
                                <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                            </CusotmUpload>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

ListModal.propTypes = {
    selectDataSource: PropTypes.array,
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string // 弹框标题
};

export default ListModal;