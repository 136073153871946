import React from 'react';
import styles from './TableData.module.scss';
import { Switch, } from 'antd';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

/* eslint-disable react/display-name */

const conversion = (text) => {
    return text ? text / 100 : 0;
};

const cycletime = (text, item) => {
    return `每${item.period}天${item.time}次`;
};

const columns = (actionClickHandle) => [
    {
        title: '规则名称',
        dataIndex: 'ruleName',
        key: 'ruleName',
        width: 150,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '周期和次数',
        dataIndex: 'safetyValueDesc',
        key: 'safetyValueDesc',
        width: 150,
        align: 'center',
        onCell: tooltipStyle,
        render: cycletime
    },
    {
        title: '兑换门槛（信用值）',
        dataIndex: 'sill',
        key: 'sill',
        width: 150,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '每次可用比率（%）',
        dataIndex: 'availableRatio',
        key: 'availableRatio',
        width: 150,
        align: 'center',
        onCell: tooltipStyle,
        render: conversion
    },
    {
        title: '兑换比率（元）',
        dataIndex: 'convertRatio',
        key: 'convertRatio',
        width: 150,
        align: 'center',
        onCell: tooltipStyle,
        render: (text) => `1信用值兑换${conversion(text)}元`
    },
    {
        title: '服务费（%）',
        dataIndex: 'serviceCharge',
        key: 'serviceCharge',
        width: 150,
        align: 'center',
        onCell: tooltipStyle,
        render: conversion
    },
    {
        title: '所得税（%）',
        dataIndex: 'incomeTax',
        key: 'incomeTax',
        width: 150,
        align: 'center',
        onCell: tooltipStyle,
        render: conversion
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: 150,
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle))
    },
    {
        title: '兑换结果取数',
        dataIndex: 'roundMode',
        key: 'roundMode',
        width: 150,
        align: 'center',
        onCell: tooltipStyle,
        render: (text) => text.value
    },
    {
        title: '发行端口',
        dataIndex: 'useScene',
        key: 'useScene',
        width: 150,
        align: 'center',
        onCell: tooltipStyle,
        render: (text) => text.value
    },
    {
        title: '操作人',
        dataIndex: 'modifyUserName',
        key: 'modifyUserName',
        width: 150,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        width: 150,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '生效时间',
        dataIndex: 'gmtModified',
        key: 'gmtModified',
        width: 150,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 200,
        render: (id, record) => TableAction(record, actionClickHandle)
    },
];

export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    return data;
};
// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
    let checked = true;
    if (sw == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                key={item.id + '_' + checked}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={(value) => {
                    actionClickHandle(item, 'switch', value);
                }}
            />
        </div>
    );
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};

const TableAction = (record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(record, 'record')}>周期记录</div>
            <div className={styles.item} onClick={() => actionClickHandle(record, 'edit')}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(record, 'countimg')}>计算周期</div>
            {/* <div className={styles.item} onClick={() => actionClickHandle(record, 'delete')}>删除</div> */}
        </div>
    );
};