/**
 * 审核详情弹框
 */
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import styles from './DetailModal.module.scss';
import { getVideoAuditDetail } from '@/reducers/auditmanage/videoAudit/actions';
import TableContent from '../conpontents/TableContent';
import { XMediaFn } from '@/components/xqxc_ui';

class DetailModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        videoDetail: { state: {} },
        status: '',
    }

    componentDidMount() {
        const { audit } = this.props;
        console.log('--------------->',this.props );
        this.setState({
            videoDetail:{
                ...audit
            }
        });
        // this.props.getVideoAuditDetail({ accountId: audit.accountId, feedId: audit.id, showType: audit.showType });
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { auditDetail } = newProps;
        this.setState({ videoDetail: auditDetail });
    }

    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }


    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _state = (state) => {
        if (state === 'BANNED') {
            return '禁播';
        }
        if (state === 'APPLY') {
            return '审批中';
        }
        if (state === 'APPROVAL') {
            return '审批通过';
        }
        if (state === 'REJECT') {
            return '审批不通过';
        }
    }

    render() {
        const { visible, confirmLoading, videoDetail } = this.state;
        const { goodsList } = videoDetail;
        const commStatus = this.state.videoDetail.status?this.state.videoDetail.status.value :'-';
        return (
            <Modal
                width={1000}
                bodyStyle={{ paddingRight: 10 }}
                centered
                title='商品评论详情'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >


                <div className={styles.container}>
                     <div className={styles.describe}>
                        <span>商品名称：</span>
                        <span>{videoDetail.goodsName}</span>
                    </div>
                    <div className={styles.cover}>
                        <p className={styles.label}>商品图片：</p>
                        <XMediaFn dataSource={videoDetail.mainImgUrl} />
                    </div>


                    <div className={styles.memberID}>
                        <span>会员账号：</span>
                        <span>{videoDetail.accountName}</span>
                    </div>

                    <div className={styles.describe}>
                        <span>评论日期：</span>
                        <span>{videoDetail.scoreTime}</span>
                    </div>

                    <div className={styles.describe}>
                        <span>评论内容：</span>
                        <span>{videoDetail.content}</span>
                    </div>
                   
                    <div className={styles.cover}>
                        <p className={styles.label}>评论图片：</p>
                        <XMediaFn dataSource={videoDetail.scoreImgUrls} />
                    </div>


                    <div className={styles.describe}>
                        <span>赠送人气值：</span>
                        <span>{videoDetail.star}</span>
                    </div>

                    
                    <div className={styles.describe}>
                        <span>赠送状态：</span>
                        <span>{commStatus}</span>
                    </div>

                </div>
            </Modal>
        );
    }
}

DetailModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getVideoAuditDetail: PropTypes.func,
    auditDetail: PropTypes.object,
    audit: PropTypes.object,
    getvideoQueryDetail: PropTypes.func, // 通过Saga异步请求社区详情
};


const mapStateToProps = (state) => ({
    auditDetail: state.videoAudit.videoAuditDetail
});

export default connect(mapStateToProps, { getVideoAuditDetail })(DetailModal);