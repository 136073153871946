/**
 * 秀场管理 - 频道分类异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

// 获取'音乐分类'表格
export const getCategoryTableSource = function* () {
    yield takeEvery(T.GET_CATEGORY_TABLESOURCE, function* ({ payload }) {
        try {
            const { type, className, ...otherProps } = payload.requestProps || {};
            
            let result = yield http(`/custom/${type}Category/getPageList`, { ...otherProps, [type + 'CategoryName']: className });

            yield put({
                type: T.SET_CATEGORY_TABLESOURCE,
                payload: result.result || {},
                requestProps: payload.requestProps
            });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};