import React from 'react';
import styles from './AdvertTable.module.scss';
import { XTableSelectionCount, XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table, Badge } from 'antd';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';

class AdvertTable extends React.Component {
    state = {
        account: '',
        selectedRowKeys: [],
    }

    _takeColumns = () => ([
        {
            title: '对账单号',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '商户',
            dataIndex: 'targetName',
            key: 'targetName',
            width: 100,
            align: 'center',
            render: (text) => {
                return <Ellipsis tooltip={text} lines={1}>{text}</Ellipsis>;
            }
        },
        {
            title: '对账周期',
            dataIndex: 'rangeDate',
            key: 'rangeDate',
            width: 230,
            align: 'center',
            render: (text, record) => (
                <div>
                    <div>{record.startDate}</div>
                    <div>{record.endDate}</div>
                </div>
            )
        },
        {
            title: '技术服务费(元)',
            dataIndex: 'platformServiceFee',
            key: 'platformServiceFee',
            width: 120,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '平台服务费(元)',
            dataIndex: 'distributionFee',
            key: 'distributionFee',
            width: 120,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '秀场服务费(元)',
            dataIndex: 'showServiceFee',
            key: 'showServiceFee',
            width: 120,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '心选推荐费(元)',
            dataIndex: 'heartSelectionRecommendationFee',
            key: 'heartSelectionRecommendationFee',
            width: 120,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '免单均摊金额(元)',
            dataIndex: 'freeOfCharge',
            key: 'freeOfCharge',
            width: 130,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '其他费用(元)',
            dataIndex: 'otherFee',
            key: 'otherFee',
            width: 100,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '应收(元)',
            dataIndex: 'revenue',
            key: 'revenue',
            width: 100,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '开票金额(元)',
            dataIndex: 'settleAmount',
            key: 'settleAmount',
            width: 100,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '结算金额(元)',
            dataIndex: 'amount',
            key: 'amount',
            width: 100,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '未票金额(元)',
            dataIndex: 'unSettleAmount',
            key: 'unSettleAmount',
            width: 100,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '营销服务费(元)',
            dataIndex: 'marketServiceFee',
            key: 'marketServiceFee',
            width: 120,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '营销服务收益(元)',
            dataIndex: 'marketingAmount',
            key: 'marketingAmount',
            width: 120,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '生成时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 160,
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '状态',
            dataIndex: 'statusDescript',
            key: 'statusDescript',
            width: 150,
            align: 'center',
            render: (text, item) => (this._renderStatusCell(text, item.status))
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 180,
            align: 'center',
            fixed: 'right',
            render: (text, item) => this._renderActionCell(item.id, item)
        },
    ]);
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderStatusCell = (text, status) => {
        var statusArr = ['INIT', 'TO_CONFIRM', 'CONFIRM_SUCCESS', 'CONFIRM_FAIL'];
        // var statusText = ['新建', '待确认', '确认成功', '确认失败'];
        var statusBadge = ['processing', 'processing', 'processing', 'error'];
        const index = statusArr.indexOf(status);
        return index === -1 ? null : <Badge status={statusBadge[index]} text={text} />;
    }
    _renderActionCell = (id, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, 'info', item)}>详情</div>
                {item.status === 'INIT' ? <div className={styles.item} onClick={() => tableAction(id, 'confir', item)}>发起对账</div> : null}
                {/* <div className={styles.item} onClick={() => tableAction(id, 'create', item)}>新建发票</div> */}
                {item.status === 'CONFIRM_FAIL' ? <div className={styles.item} onClick={() => tableAction(id, 'delete', item)}>删除</div> : null}
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys, selectedRows });
            this.props.onSelectionChange(selectedRowKeys, selectedRows);
        },
        getCheckboxProps: record => ({
            disabled: record.unSettleAmount === 0 || record.status !== 'CONFIRM_SUCCESS',
        })
    };
    onChange = (pagination, filters, sorter) => {
        console.log('params', pagination, filters, sorter);
    };
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }
    render() {
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record) => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    // onChange={this.onChange}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    scrollY: PropTypes.any,
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    onSelectionChange: PropTypes.func
};

export default XTableHOC(AdvertTable, 40);