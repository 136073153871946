import React from 'react';
import styles from './OrderSearch.module.scss';
import PropTypes from 'prop-types';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker, XInputNum } from '@/components/xqxc_ui';
import { getMerchantList } from '@/reducers/ordermanage/transcation/actions';
import { connect } from 'react-redux';
import { getProductOrderStatus, getProductOrderFlagEnum, getPaymentAccessTypeEnum, getPaymentTypeEnum } from '@/reducers/ordermanage/productOrder/actions';
import ConsumeTypes from '@/vcomps/ConsumeTypes';

class OrderSearch extends React.Component {

    state = {
        expandBoolean: false, // 是否展开
        telephone: '', // 会员手机
        orderCode: '', // 订单编号
        orderStartTime: null, // 下单时间开始日期
        orderEndTime: null, // 下单结束日期
        orderState: {}, // 订单状态
        invoice: {}, // 是否开票
        userState: {}, // 商户
        isAppointmentTimeOut: {}, // 是否超时
        payType: {}, // 支付方式
        payAccessType: {}, // 支付通道
        consumeTypeItem: {},//消费类型
    }

    componentDidMount() {
        this.props.getProductOrderStatus();
        this.props.getProductOrderFlagEnum();
        // 商户
        this.props.getMerchantList();
        // 支付通道
        this.props.getPaymentAccessTypeEnum();
        // 支付方式
        this.props.getPaymentTypeEnum();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState(newProps.searchState.param);
    }

    _renderOrderStateSelect = (orderState) => {
        const { productOrderStatus } = this.props;
        if (this.props.orderState == 1) {
            return (
                <XSelect
                    label='订单状态'
                    placeholder='请选择订单状态'
                    renderData={productOrderStatus}
                    dataIndex='value'
                    keyIndex='code'
                    value={orderState.value}
                    showSearch={true}
                    bindThis={this}
                    bindName='orderState'
                />
            );
        } else {
            return null;
        }
    }
    // 展开或收缩搜索条件
    _expandChangeHandle = (expandBoolean) => {
        this.setState({ expandBoolean });
    }
    // 查询
    _searchHandle = () => {
        const { isAppointmentTimeOut, telephone, orderCode, orderState, payType, payAccessType, invoice, userState, orderStartTime, orderEndTime, consumeTypeItem } = this.state;
        this.searchCache = {
            telephone,
            orderCode,
            orderState,
            invoice,
            userState,
            orderStartTime,
            orderEndTime,
            isAppointmentTimeOut,
            payType,
            payAccessType, consumeTypeItem
        };
        this.props.searchOrder({
            accountTel: telephone, payTimeF: orderStartTime, payTimeT: orderEndTime, orderId: orderCode,
            isInvoice: invoice.code ? invoice.code : '',
            isAppointmentTimeOut: isAppointmentTimeOut.code ? isAppointmentTimeOut.code : '',
            statusList: orderState.code ? [orderState.code] : [],
            sellerId: userState.merchantId,
            payType: payType.code,
            payAccessType: payAccessType.code, consumeType: consumeTypeItem.code
        }, this.searchCache);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            telephone: '',
            orderCode: '',
            orderStartTime: null,
            orderEndTime: null,
            orderState: {},
            invoice: {},
            pageNum: 1,
            pageSize: 50,
            userState: {},
            sellerId: '',
            payType: {},
            payAccessType: {},
            isAppointmentTimeOut: {}, consumeTypeItem: {}
        });
        this.searchCache = {
            telephone: '',
            orderCode: '',
            orderStartTime: null,
            orderEndTime: null,
            orderState: {},
            invoice: {},
            pageNum: 1,
            pageSize: 50,
            userState: {},
            sellerId: '',
            payType: {},
            payAccessType: {},
            isAppointmentTimeOut: {}, consumeTypeItem: {}
        };
        this.props.searchOrder({}, this.searchCache, true);
    }

    render() {
        const { payType, payAccessType, isAppointmentTimeOut, telephone, orderCode, orderStartTime, orderEndTime, orderState, invoice, userState, consumeTypeItem } = this.state;
        const { productOrderFlagEnum, paymentAccessTypeEnum, paymentTypeEnum, merchantList: { merchantIdAndNames } } = this.props;
        return (
            <div className={styles.container} style={{ marginTop: 10 }}>
                <SearchBox>
                    <SearchItem>
                        <XInputNum
                            label='订单编号'
                            placeholder='请输入订单编号'
                            value={orderCode}
                            bindThis={this}
                            bindName='orderCode'
                            min={0}
                            precision={0}
                            maxLength={16}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='商户'
                            placeholder='请选择商户'
                            renderData={merchantIdAndNames || []}
                            dataIndex='merchantName'
                            keyIndex='merchantId'
                            value={userState.merchantName}
                            showSearch={true}
                            bindThis={this}
                            bindName='userState'
                            isOptionBindID
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            label='会员手机'
                            placeholder='请输入手机号码'
                            value={telephone}
                            bindThis={this}
                            bindName='telephone'
                        />
                    </SearchItem>
                    <SearchItem>
                        {this._renderOrderStateSelect(orderState)}
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='是否超时'
                            placeholder='请选择是否超时'
                            renderData={productOrderFlagEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={isAppointmentTimeOut.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='isAppointmentTimeOut'
                        />
                    </SearchItem>

                    <SearchItem>
                        <XSelect
                            label='是否开票'
                            placeholder='请选择是否开票'
                            renderData={productOrderFlagEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={invoice.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='invoice'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='支付通道'
                            placeholder='请选择支付通道'
                            renderData={paymentAccessTypeEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={payAccessType.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='payAccessType'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='支付方式'
                            placeholder='请选择支付方式'
                            renderData={paymentTypeEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={payType.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='payType'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XDatePicker
                            label='付款时间'
                            value={orderStartTime}
                            bindThis={this}
                            bindName='orderStartTime'
                            isFormat
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            style={{ marginRight: 20 }}
                            label=''
                            value={orderEndTime}
                            bindThis={this}
                            bindName='orderEndTime'
                            isFormat
                        />
                    </SearchItem>
                    <SearchItem>
                        <div style={{ marginRight: 20 }}>
                            <ConsumeTypes
                                label='消费类型'
                                placeholder='请选消费类型'
                                value={consumeTypeItem}
                                showSearch={true}
                                bindThis={this}
                                bindName='consumeTypeItem'
                            />
                        </div>
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </SearchItem>
                </SearchBox>
            </div>
        );
    }
}

OrderSearch.propTypes = {
    orderState: PropTypes.number,
    searchOrder: PropTypes.func,
    getProductOrderStatus: PropTypes.func,
    getProductOrderFlagEnum: PropTypes.func,
    getMerchantList: PropTypes.func,
    productOrderStatus: PropTypes.array,
    productOrderFlagEnum: PropTypes.array,
    merchantList: PropTypes.object,
    getPaymentAccessTypeEnum: PropTypes.func,
    paymentAccessTypeEnum: PropTypes.array,
    getPaymentTypeEnum: PropTypes.func,
    paymentTypeEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
    productOrderStatus: state.productOrder.productOrderStatus,
    productOrderFlagEnum: state.productOrder.productOrderFlagEnum,
    merchantList: state.Transcation.merchantList,
    paymentAccessTypeEnum: state.productOrder.paymentAccessTypeEnum,
    paymentTypeEnum: state.productOrder.paymentTypeEnum,
});

export default connect(mapStateToProps, { getProductOrderStatus, getProductOrderFlagEnum, getMerchantList, getPaymentAccessTypeEnum, getPaymentTypeEnum })(OrderSearch);