/* eslint-disable react/display-name */
import React, { Component } from 'react';

const TableHOC = (component = null, offsetY = 0) => {
    return class extends Component {
        constructor() {
            super();
            this.state = {
                clientTop: 0,
                scrollY: '',
                component,
                offset: 116,

            };
            window.addEventListener('resize', this._forceUpdateEvent);
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this._forceUpdateEvent);
        }

        _forceUpdateEvent = () => {
            this.forceUpdate();
        }

        render() {
            const C = this.state.component;
            const offset = this.state.offset;

            return C ?
                <span
                    ref={(ele) => {
                        const clientRect = ele && ele.getBoundingClientRect();
                        if (clientRect && this.state.clientTop != clientRect.top + offset + offsetY) {
                            this.setState({ clientTop: clientRect.top + offset + offsetY, scrollY: 'calc(100vh - ' + (clientRect.top + offset + offsetY) + 'px)' });
                        }
                    }}>
                    <C {...this.props} scrollY={this.state.scrollY} />
                </span> : null;
        }
    };
};

export default TableHOC;