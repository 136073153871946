import * as Audit from './actiontypes';

export const getCompanyInvoiceList = (params) => ({
    type: Audit.GET_COMPANYINVOICE_LIST,
    payload: params
});

export const getCompanyInvoiceStatus = () => ({
    type: Audit.GET_COMPANYINVOICE_STATUS,
    payload: {}
});

export const getCompanyInvoiceName = () => ({
    type: Audit.GET_COMPANYINVOICE_NAME,
    payload: {}
});

export const getCompanyInvoiceDetail = (settlementId) => ({
    type: Audit.GET_COMPANYINVOICE_DETAIL,
    payload: { settlementId }
});

export const getCompanyInvoicePay = ({ ids }) => ({
    type: Audit.GET_COMPANYINVOICE_PAY,
    payload: { ids }
});