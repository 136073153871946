/**
 * 数据中心
 */
import React from 'react';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import Tab1 from './tab1';
import Tab2 from './tab2';
import Tab3 from './tab3';
import Tab4 from './tab4';
import Tab5 from './tab5';
import Tab6 from './tab6';
import Tab7 from './tab7';
import Tab8 from './tab8';
import { connect } from 'react-redux';

class DataCenter extends React.Component {

    state = {
        tab: [
            // { id: 1, label: '分类费率报表' },
            { id: 2, label: '服务商账单' },
            { id: 3, label: '机构账单' },
            { id: 4, label: '服务商订单' },
            { id: 5, label: '平台订单明细' },
            { id: 6, label: '会员数据' },
            { id: 7, label: '服务商数据' },
            { id: 8, label: '售后数据' },
        ],
        curTabID: 2,  // 当前显示的Tab页ID
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染内容
    _renderContent = () => {
        const { params } = this.props.match;
        const { curTabID } = this.state;
        switch (curTabID) {
            case 1:
                return (<Tab1 history={this.props.history} riderID={params.id} />);
            case 2:
                return (<Tab2 history={this.props.history} riderID={params.id} />);
            case 3:
                return (<Tab3 history={this.props.history} riderID={params.id} />);
            case 4:
                return (<Tab4 history={this.props.history} riderID={params.id} />);
            case 5:
                return (<Tab5 history={this.props.history} riderID={params.id} />);
            case 6:
                return (<Tab6 history={this.props.history} riderID={params.id} />);
            case 7:
                return (<Tab7 history={this.props.history} riderID={params.id} />);
            case 8:
                return (<Tab8 history={this.props.history} riderID={params.id} />);
            default:
                return (<Tab2 history={this.props.history} riderID={params.id} />);
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div style={{ height: 10 }}></div>
                {this._renderContent()}
            </div>
        );
    }
}

DataCenter.propTypes = {
    history: PropTypes.object, // router history
    match: PropTypes.object, // router match
};

export default connect(null, {})(DataCenter);

import CategoryFeeReport from './tab1';
import ServiceBill from './tab2';
import UnitBill from './tab3';
import ServiceMerchantOrder from './tab4';
import PlatOrderDetail from './tab5';
import MemberData from './tab6';
import ServiceData from './tab7';
import SaleAfter from './tab8';

export {
    CategoryFeeReport,
    ServiceBill,
    UnitBill,
    ServiceMerchantOrder,
    PlatOrderDetail,
    MemberData,
    ServiceData,
    SaleAfter
};
