export const GET_STARTGIFT_DATA = 'PRESENT/GET_STARTGIFT_DATA'; // 魅力值、人气值礼物分类'数据源
export const SET_STARTGIFT_DATA = 'PRESENT/SET_STARTGIFT_DATA'; //魅力值、人气值礼物分类存储至store.state中

export const GET_GIFTSTATE_DATA = 'PRESENT/GET_GIFTSTATE_DATA'; // 魅力值、人气值礼物分类下拉'数据源
export const SET_GIFTSTATE_DATA = 'PRESENT/SET_GIFTSTATE_DATA'; // 魅力值、人气值礼物分类下拉'存储至store.state中

export const GET_STARTSGLIST_DATA = 'PRESENT/GET_STARTSGLIST_DATA'; // 魅力值、人气值礼物列表'数据源
export const SET_STARTSGLIST_DATA = 'PRESENT/SET_STARTSGLIST_DATA'; // 魅力值、人气值礼物列表'存储至store.state中

//getstarsgiftSource 魅力值礼物分类   礼物管理-魅力值礼物分类列表- 分类状态 Saga startgiftState
//获取'魅力值礼物列表 列表'数据源(saga) getstarsglistSource  星购买记录 getstarsbuySource starsvaluebuy
 
export const GET_STARTBUY_DATA = 'PRESENT/GET_STARTBUY_DATA'; // 魅力值购买记录'数据源
export const SET_STARTBUY_DATA = 'PRESENT/SET_STARTBUY_DATA'; // 魅力值购买记录'存储至store.state中

export const GET_STARTVALUEBUY_DATA = 'PRESENT/GET_STARTVALUEBUY_DATA'; // 人气值购买记录'数据源
export const SET_STARTVALUEBUY_DATA = 'PRESENT/SET_STARTVALUEBUY_DATA'; // 人气值购买记录'存储至store.state中