import React from 'react';
import { Tooltip, } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import styles from './TableData.module.scss';
import { callbackify } from 'util';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => ([
  {
    title: '文件类型',
    dataIndex: 'wordType',
    key: 'wordType',
    width: '10%',
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '词库内容',
    dataIndex: 'keyWord',
    key: 'keyWord ',
    align: 'center',
    width: 360,
    render: (text) => (TableTipCell(text))
  },
  {
    title: '使用位置',
    dataIndex: 'remark',
    key: 'remark ',
    width: '20%',
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '操作人',
    dataIndex: 'modifyUser',
    key: 'modifyUser',
    width: '10%',
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '更新时间',
    dataIndex: 'gmtModified',
    key: 'gmtModified',
    width: '15%',
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '操作',
    dataIndex: 'c6',
    key: 'c6',
    // width: '10%',
    align: 'center',
    render: (text, item) => TableAction(item, actionClickHandle)
  },
]);


export const takeDataSource = (data) => {
  return data;
};

export const takeColumns = (actionClickHandle) => {
  return columns(actionClickHandle);
};

// 表格项内容为文本
const TableTipCell = (text) => {
  return (
      <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: 340, textAlign: 'center' }} title={text}>{text}</div>
  );
};
const TableAction = (record, actionClickHandle) => {
  return (
    <div className={styles.action}>
      <div className={styles.item} onClick={() => actionClickHandle(record, 'dowload')}>下载</div>
      <div className={styles.item} onClick={() => actionClickHandle(record, 'edit')}>编辑</div>
    </div>
  );
};