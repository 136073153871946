import { createModel } from '@/store/tools';

const model = {
    namespace: 'operationmember',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getUserReport': T('getUserReport'),
        },
        actions: {
            'getUserReport': A('getUserReport'),
        },
        sagas: {
            'getUserReport': S('getUserReport', '/report/userReport'),
        },
        reducers: {
            'getUserReport': R('getUserReport', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);