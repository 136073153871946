import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip, priceFenToYuanCell, cellWidth, tooltipClick } from '@/components/TableCell';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { isPlat } from '@/assets/js/authType';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [],
        selectedRows: []
    };

    static getDerivedStateFromProps(props, state) {
        if (props.selectedRowKeys !== state.selectedRowKeys) {
            return {
                selectedRowKeys: props.selectedRowKeys,
                selectedRows: props.selectedRows
            };
        }
        return null;
    }

    _columns = () => {
        let cols = [
            {
                title: '会员账号',
                dataIndex: 'tel',
                key: 'tel',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: (text, item) => tooltipClick(text, () => {
                    this.props.tableAction(item.accountId + '/' + item.id, 'MEMBER_INFO');
                })
            },
            {
                title: '昵称',
                dataIndex: 'nickname',
                key: 'nickname',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '所属服务天使',
                dataIndex: 'parentName',
                key: 'parentName',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '所属区域',
                dataIndex: 'areaCompanyName',
                key: 'areaCompanyName',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '会员类型',
                dataIndex: 'accountType.value',
                key: 'accountType.value',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '来源',
                dataIndex: 'source.value',
                key: 'source.value',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '可用特权值',
                dataIndex: 'availableIntegral',
                key: 'availableIntegral',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '可提现金额',
                dataIndex: 'withdrawalAmount',
                key: 'withdrawalAmount',
                width: cellWidth.small,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '状态',
                dataIndex: 'stateName',
                key: 'stateName',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '注册时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: cellWidth.time,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            }
        ];

        if (isPlat()) {
            cols.push({
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: cellWidth.big,
                fixed: 'right',
                render: (id, item) => this._TableAction(item.accountId, item)
            });
        }
        return cols;
    }

    _resolveAreaName = (areaName) => {
        if (areaName) {
            return areaName;
        } else {
            return '';
        }
    }

    _MoneyCell = (text) => {
        const money = regFenToYuan(parseInt(text));
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {money}
            </div>
        );
    };

    _TableAction = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <ButtonPerssion code='usermanage/giftmember/tjmx'>
                    <div className={styles.item} onClick={() => tableAction(item.id, 'MEMBER_RECOMMEND')}>推荐明细</div>
                </ButtonPerssion>
                <ButtonPerssion code='usermanage/giftmember/szmx'>
                    <div className={styles.item} onClick={() => tableAction(key, 'MEMBER_RANDP')}>收支明细</div>
                </ButtonPerssion>
                <ButtonPerssion code='usermanage/giftmember/xgqy'>
                    <div className={styles.item} onClick={() => tableAction(key, 'CHANGE_AREA', item)}>修改区域</div>
                </ButtonPerssion>
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys, selectedRows });
                this.props.selectedRowKeysChange(selectedRowKeys, selectedRows);
            }
        };
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [], selectedRows: [] }, () => {
            this.props.selectedRowKeysChange([], []);
        });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <XTableSelectionCount selectedNum={this.state.selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection()}
                    rowKey='accountId'
                    columns={this._columns()}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    selectedRowKeys: PropTypes.array,
    selectedRows: PropTypes.array,
    selectedRowKeysChange: PropTypes.func,
    scrollY: PropTypes.string
};
export default XTableHOC(TableContent, 40);

