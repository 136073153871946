/**
 * 启用服务商弹框
 */
import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import styles from './AbleModal.module.scss';
import { message } from 'antd';
import http from '@/assets/api/http';

class AbleModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
    }

    _handleOk = () => {
        const { data } = this.props;
        this.setState({ confirmLoading: true });
        http('/service/provider/updateControlStart', { serviceId: data, state: 1 }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('启用成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode('Refresh');
            }
        }).catch((e) => {
            message.error(e.message);
        }).finally(() => {
            this.setState({ confirmLoading: false });
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading } = this.state;
        return (
            <Modal
                width={590}
                centered
                title='启用'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    即将开启该服务商
                </div>
            </Modal>
        );
    }
}

AbleModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    data: PropTypes.string, // 封禁或解封的ID
};

export default AbleModal;