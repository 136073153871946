import * as Order from './actionTypes';

//商品订单列表
export const getShopOrderList = (param) => ({
    type: Order.GET_SHOPORDER_LIST,
    payload: param,
});
//快递公司列表
export const getExpressCompanyList = (param) => ({
    type: Order.GET_EXPRESSCOMPANY_LIST,
    payload: param,
});
