/**
 * 事件关闭
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { DatePicker, message } from 'antd';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import TableContent from '../../components/TableContent';
import DeleteHint from '../../modal/DeleteHint';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getTaskList, getTaskClassifySele, getTaskTypeSele, getTaskPlatSele } from '@/reducers/taskManage/taskList/actions';
import { SearchBox, SearchItem } from '@/components/Layout';

class TaskList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            taskTitle: '',
            taskClassify: {},
            taskType: {},
            pushPlat: {},
            startTime: null,
            endTime: null,
        };
    }

    // 创建任务
    _createTask = () => {
        this.props.updateContent('CREATE');
    }

    //日期改变
    _dateChange = (date, dateString, type) => {
        switch (type) {
            case 'startTime':
                console.log(dateString);
                this.setState({ startTime: dateString });
                break;
            case 'endTime':
                console.log(dateString);
                this.setState({ endTime: dateString });
                break;
        }
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { taskTitle, taskClassify, taskType, pushPlat, startTime, endTime, pageNum, pageSize } = this.state;
        this.searchCache = {
            taskTitle,
            taskCategoryId: taskClassify.key,
            taskType: taskType.key,
            pushPlat: pushPlat.key,
            startTime,
            endTime, state: '2',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getTaskList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            taskTitle: '',
            taskClassify: {},
            taskType: {},
            pushPlat: {},
            startTime: null,
            endTime: null,
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //发送请求
    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST').then((result) => {
            if (result.code === 200 && result.status === 200) {
                message.success(winTxt);
                this._searchHandle('useCache');
            }
        }).catch((error) => {
            message.error(error.message);
        });
    }

    // 表格操作
    _tableAction = (id, activeType, params) => {
        switch (activeType) {
            case 'detail':
                this.props.updateContent('DETAIL', id, 3);
                break;
            case 'ban':
                // this.setState({ currentOpenMode: 'BAN' });
                showConfirm('你确定要禁用该任务？', '', () => {
                    this._sendReq('/taskInfo/updateState', { id, state: '0' }, '禁用成功。');
                });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'BAN':
                return <DeleteHint visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' }, () => {
            if (update) this._searchHandle('useCache');
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { taskList, taskClassifySele, taskTypeSele, taskPlatSele } = this.props;
        const { taskTitle, taskClassify, taskType, pushPlat, startTime, endTime } = this.state;
        return (
            <KeepAlive id='3' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='任务标题：'
                                placeholder='请输入任务标题'
                                bindThis={this}
                                bindName='taskTitle'
                                value={taskTitle}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='任务分类：'
                                placeholder='请选择任务分类'
                                renderData={taskClassifySele}
                                bindThis={this}
                                bindName='taskClassify'
                                dataIndex='value'
                                keyIndex='key'
                                value={taskClassify.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='任务类型：'
                                placeholder='请选择事件类型'
                                renderData={taskTypeSele}
                                bindThis={this}
                                bindName='taskType'
                                dataIndex='value'
                                keyIndex='key'
                                value={taskType.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='推送平台：'
                                placeholder='请选择推动平台'
                                renderData={taskPlatSele}
                                bindThis={this}
                                bindName='pushPlat'
                                dataIndex='value'
                                keyIndex='key'
                                value={pushPlat.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <div className={styles.date}>
                                <span className={styles.label}>事件时间：</span>
                                <DatePicker
                                    placeholder='开始时间'
                                    locale={locale}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    value={startTime ? moment(startTime, 'YYYY-MM-DD HH:mm:ss') : startTime}
                                    onChange={(date, dateString) => this._dateChange(date, dateString, 'startTime')} />
                                <DatePicker
                                    placeholder='结束时间'
                                    style={{ marginLeft: '10px', marginRight: '20px' }}
                                    locale={locale}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    value={endTime ? moment(endTime, 'YYYY-MM-DD HH:mm:ss') : endTime}
                                    onChange={(date, dateString) => this._dateChange(date, dateString, 'endTime')} />
                            </div>
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }} label='新建任务' onClick={this._createTask} />
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={taskList} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive >
        );
    }
}

TaskList.propTypes = {
    updateContent: PropTypes.func,
    getTaskList: PropTypes.func,
    getTaskClassifySele: PropTypes.func,
    getTaskPlatSele: PropTypes.func,
    getTaskTypeSele: PropTypes.func,

    taskList: PropTypes.object,
    taskClassifySele: PropTypes.array,
    taskPlatSele: PropTypes.array,
    taskTypeSele: PropTypes.array
};

const mapStateToProps = (state) => ({
    taskList: state.taskList.taskList,
    taskClassifySele: state.taskList.taskClassifySele,
    taskPlatSele: state.taskList.taskPlatSele,
    taskTypeSele: state.taskList.taskTypeSele
});

export default connect(mapStateToProps, { getTaskList, getTaskClassifySele, getTaskPlatSele, getTaskTypeSele })(TaskList);