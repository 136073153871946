import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { Table } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import EditModal from './EditModal';
import { getSalaAfterRuleList, getSalaAfterTypeEnum } from '@/reducers/systemmanage/mallsetting/actions';

const SalaAfter = ({
    getSalaAfterRuleList,
    dataSource = [],
    getSalaAfterTypeEnum,
    salaAfterTypeEnum = []
}) => {

    const [renderModal, setRenderModal] = useState(false);
    const [modalParams, setModalParams] = useState({});

    useEffect(() => {
        getSalaAfterTypeEnum();
    }, []);

    useEffect(() => {
        !renderModal && getSalaAfterRuleList();
    }, [renderModal]);

    const tableAction = (r) => {
        setRenderModal(true);
        setModalParams({ ...r, salaAfterTypeEnum });
    };

    const tableActionCell = (t, r) => {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(r)}>编辑</div>
            </div>
        );
    };

    const columns = [
        {
            title: '售后规则名称',
            dataIndex: 'ruleName',
            key: 'ruleName',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '规则分类',
            dataIndex: 'ruleCategory.value',
            key: 'ruleCategory.value',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render: tableActionCell
        },
    ];

    return <div className={styles.flexBoxContainer}>
        <div className={`${styles.tableContainer}`}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={r => r.id}
                columns={(columns)}
                dataSource={dataSource}
                pagination={false}
                scroll={{ y: true }}
            />
        </div>
        {renderModal && <EditModal modalParams={modalParams} setRenderModal={setRenderModal} />}
    </div>;
};
SalaAfter.propTypes = {
    getSalaAfterRuleList: PropTypes.func,
    dataSource: PropTypes.array,
    getSalaAfterTypeEnum: PropTypes.func,
    salaAfterTypeEnum: PropTypes.array,
};
export default connect(state => ({
    dataSource: state.mallSetting.mallsettingDate.salaAfterRuleList,
    salaAfterTypeEnum: state.mallSetting.mallsettingDate.salaAfterTypeEnum,
}), { getSalaAfterRuleList, getSalaAfterTypeEnum })(SalaAfter);