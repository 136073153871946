/**
 *  服务清单 - 列表
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const Mode = {
    DELETE: 'DELETE',//详情
    LISTING: 'LISTING',//查看清单
    THESALES: 'THESALES'//销售情况
};

class List extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case Mode.LISTING:  //查看清单
                addOneBreadcrumbPath({
                    title: '查看清单',
                    path: '/home/servicelistingmanage/servicehistory/listing/' + value,
                });
                history.push({
                    pathname: '/home/servicelistingmanage/servicehistory/listing/' + value,
                });
                break;
            case Mode.DELETE: //详情
                addOneBreadcrumbPath({
                    title: '详情',
                    path: {
                        pathname: '/home/servicelistingmanage/servicehistory/detailInfo/' + value.id,
                        search: 'merchantType=' + value.merchantType
                    }
                });
                history.push({
                    pathname: '/home/servicelistingmanage/servicehistory/detailInfo/' + value.id,
                    search: 'merchantType=' + value.merchantType
                });
                break;
            case Mode.THESALES://销售情况
                addOneBreadcrumbPath({
                    title: '销售情况',
                    path: '/home/servicelistingmanage/servicehistory/theSales/' + value,
                });
                history.push({
                    pathname: '/home/servicelistingmanage/servicehistory/theSales/' + value,
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

List.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(List);