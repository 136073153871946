/**
 *  服务商等级 
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, message } from 'antd';
import http from '@/assets/api/http';
import { takeDataSource, takeColumns } from './components/TableData';
import { showConfirm, XTabNav } from '@/components/xqxc_ui';
import ButtonPerssion from '@/routes/ButtonPerssion';
import model from '@/reducers/systemmanage/servicesetting/model';
import Bean from '../bean';
import Show from '../show';
import ExchangeRule from '../exchangerule';
import Franchise from '../franchise';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: [
                { id: 1, label: '能量值设置' },
                { id: 2, label: '贡献值设置' },
                { id: 3, label: '贡献值兑换规则' },
                { id: 4, label: '特权值设置' }
            ],
            curTabID: 1,  // 默认显示的tab
        };

    }

    componentDidMount() {
        this.props.getserviceLevelList();
    }

    // 表格操作功能回调 
    _actionClickHandle = (id, type, record) => {
        const { updateContent } = this.props;
        switch (type) {
            case 'EDIT'://编辑
                updateContent('EDIT', id);
                break;
            case 'DISABLE'://删除
                showConfirm('确定要删除这条数据吗？', '', () => {
                    http(`/service/level/delete/${id}`, {}, 'GET').then(() => {
                        message.success('删除成功');
                        this.props.getserviceLevelList();
                    }).catch((e = {}) => {
                        message.error(e.message);
                    });
                }, () => { });
                break;
            default: return null;
        }

    }

    // 新建按钮被点击
    _newCreateHandle = () => {
        this.props.updateContent('NEWCREATE');
    }


    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容 
    _renderPage = () => {
        const { curTabID } = this.state;
        switch (curTabID) {
            case 1:
                return <Bean curTabID={1} />;
            case 2:
                return <Show curTabID={2} />;
            default:
                return null;

        }
    }

    render() {
        const { serviceLevel, history } = this.props;
        const { curTabID } = this.state;

        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                {
                    curTabID == 3 ?
                        <ExchangeRule history={history} curTabID={3} /> :
                        curTabID == 4 ?
                            <Franchise curTabID={4} /> :
                            <div className={styles.tableContainer}>
                                <div style={{ overflow: 'auto', height: '100%' }}>
                                    {this._renderPage()}
                                    {this.state.curTabID == '1' && <div className={styles.servergrowth}>
                                        <div className={styles.top_title}>
                                            <span>服务商等级</span>
                                        </div>
                                        <div className={`${styles.refundtable}`}>
                                            <div className={styles.flex}>
                                                <Table
                                                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                                                    rowKey={(record, index) => index}
                                                    columns={takeColumns(this._actionClickHandle)}
                                                    dataSource={takeDataSource(serviceLevel)}
                                                    pagination={false}
                                                    scroll={{ y: true }}
                                                />
                                            </div>
                                        </div>
                                        <ButtonPerssion code='systemmanage/servicesetting/xz'>
                                            <p className={styles.creatdash} onClick={this._newCreateHandle}>新增</p>
                                        </ButtonPerssion>
                                    </div>}
                                </div>
                            </div>
                }
            </div>
        );
    }
}


Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    match: PropTypes.object, // 路由match对象
    history: PropTypes.object, // 路由history

    serviceLevel: PropTypes.array,
    getserviceLevelList: PropTypes.func,//服务商等级action
};

const mapStateToProps = (state) => ({
    serviceLevel: state.servicesetting.serviceLevel,
});

export default connect(mapStateToProps, { ...model.actions })(Main);