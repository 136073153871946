/**
 * 广告列表启用停用弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal,Input,message,Radio } from 'antd';
import styles from './StartOrStop.module.scss';

const { TextArea } = Input;

class StartOrStop extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        status:'',
        reason:''
    }
    // 点击确认
    _handleOk = () => {
        const { data } = this.props;
        const goodsIds = [];
        goodsIds.push(data);
        http('/goods/goods/disableGoods',{goodsIds},'POST').then(()=>{
            message.success('停用成功');
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch(()=>{
            message.error('停用失败');
            this.setState({
                visible: false,
            });
            this.props.closeMode();
        });
    }

    // 点击取消
    _handleCancel  = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _statusChange = (e) => {
        this.setState({status:e.target.value});
    }

    _reasonChange = (e) => {
        this.setState({reason:e.target.value});
    }

    render(){
        const { visible, confirmLoading,title,reason} = this.state;

        return(
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    您确定启用/停用当前数据？
                    {/* <div className={styles.handle}>
                        <div className={styles.label}>操作：</div>
                        <div className={styles.content}>
                            <Radio.Group onChange={this._statusChange} value={this.state.value}>
                                <Radio value={1}>启用</Radio>
                                <Radio value={2}>停用</Radio>
                            </Radio.Group>
                        </div>
                     
                    </div>
                    <div className={styles.reason}>
                        <div className={styles.label}>原因：</div>
                        <div className={styles.content}>
                            <TextArea
                                style={{width:'360px'}} 
                                placeholder='请输入原因'
                                value={reason}
                                onChange={this._reasonChange}
                                autosize={{ minRows: 2, maxRows: 4 }}
                            />
                        </div>
                    </div> */}
                </div>
            </Modal>
        );
    }
}

StartOrStop.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

export default StartOrStop;