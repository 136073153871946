/**
 * 收支明细页
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import IncomeTab from './IncomeTab';
import PointsTab from './PointsTab';

class Page extends React.Component {

    state = {
        curTabID: 1,
        tabList: [
            { id: 1, label: '收益支取' },
            { id: 2, label: '积分兑换' },
        ],
    }

    componentDidMount() {
    }

    render() {
        const { tabList, curTabID } = this.state;
        const { history, match } = this.props;

        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={tabList} onChange={curTabID => this.setState({ curTabID })} activedID={curTabID} />
                <div style={{ height: 10 }}></div>
                {
                    curTabID == '1' ? <IncomeTab history={history} match={match} /> : null
                }
                {
                    curTabID == '2' ? <PointsTab history={history} match={match} /> : null
                }
            </div>
        );
    }
}

Page.propTypes = {
    history: PropTypes.object, // router history对象
    match: PropTypes.object, // router match对象
};

export default Page;