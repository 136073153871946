/**
 * 商品列表虚拟弹框 
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, InputNumber, message, Form } from 'antd';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
    }
};

const VirtualSales = (props) => {

    const [visible, setVisible] = useState(props.visible);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const _handleSubmit = e => {
        e && e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setConfirmLoading(true);
                http('/goods/goods/updateGoodsVirtualSales', { goodsId: props.data, virtualSales: values.virtualSales }, 'POST').then(() => {
                    message.success('设置虚拟销量成功。');
                    setConfirmLoading(false);
                    _handleCancel(true);
                }).catch((error) => {
                    message.error(error.message);
                    setConfirmLoading(false);
                });
            }
        });
    };

    const _handleOk = () => {
        _handleSubmit();
    };

    // 点击取消
    const _handleCancel = (data) => {
        setVisible(false);
        props.closeMode(data);
    };

    const { getFieldDecorator } = props.form;
    return (
        <Modal
            width={470}
            centered
            title='设置虚拟销量'
            visible={visible}
            confirmLoading={confirmLoading}
            onOk={_handleOk}
            onCancel={_handleCancel}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <Form id='virtualSales' {...formItemLayout} onSubmit={_handleSubmit}>
                <Form.Item label="虚拟销量">
                    {getFieldDecorator('virtualSales', {
                        rules: [{ required: true, message: '请输入虚拟销量' }],
                    })(<InputNumber style={{ width: '300px' }} placeholder='请输入虚拟销量' precision={0} min={0} max={999999999} />)}
                </Form.Item>
            </Form>
        </Modal>
    );
};

VirtualSales.propTypes = {
    data: PropTypes.number.isRequired, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    form: PropTypes.object.isRequired//表单对象
};

export default Form.create({ name: 'virtualSales' })(VirtualSales);




