/**
 * 社区分类
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, XInput } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { Unable, DeleteModal } from '../../modal';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { message } from 'antd';
import { getcommunityTableSource, getcommunityState, getcommunityRecoState } from '@/reducers/showmanage/channellist/actions';
import { SearchBox, SearchItem } from '@/components/Layout';

const Mode = {
    INFO: 'INFO',
    UNABLE: 'UNABLE',
    HOMESTATUS: 'HOMESTATUS',//首页推荐
    CHANNEL: 'CHANNEL',//频道推荐
    DELETE: 'DELETE',//删除
};

class CommunityList extends React.Component {

    state = {
        tel: '', // 手机号码
        feedTitle: '', // 社区标题
        home: {}, // 社区分类
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: '', // 当前需要打开的弹窗的入参
        pageNum: 1,//默认表格当前第几页
        pageSize: 20,//默认表格每页显示多少条
        communityParams: {
            id: '',
            communityBanned: 'communityBanned',
        },//禁播(区别视频，社区与参数ID值)

        channelReco: {},//频道推荐
        homeReco: {},//首页推荐
        sort: ''
    }

    componentDidMount() {
        const { getcommunityState, getcommunityRecoState, } = this.props;
        getcommunityState();//社区分类
        getcommunityRecoState();//首页、频道分类
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { feedTitle, tel, home, homeReco, channelReco, pageNum, pageSize, sort } = this.state;
        const { getcommunityTableSource, } = this.props;
        this.searchCache = {
            feedTitle,
            tel, sort,
            feedClassId: home.id,
            isHomeReco: homeReco.code,
            isChannelReco: channelReco.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        getcommunityTableSource(this.searchCache);// 社区分类列表查询方法	
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ tel: '', feedTitle: '', home: {}, channelReco: {}, homeReco: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 推荐按钮被点击
    _recommendHandle = () => {
        const { updateContent } = this.props;
        updateContent('OMMUNITYY_LIST_RECOMMEND', 'community');
    }


    // 创建社区
    _communityCreate = () => {
        const { updateContent } = this.props;
        updateContent('COMMUNITY_LIST_CREATE');
    }

    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST')
            .then(() => {
                message.success(winTxt);
                this._searchHandle('useCache');
            })
            .catch((e) => { message.error(e.message); });
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        const { updateContent } = this.props;
        const { communityParams } = this.state;
        switch (type) {
            case Mode.INFO:
                updateContent('COMMUNITY_LIST_INFO', id);
                break;
            case Mode.UNABLE:// 获取禁播理由选项
                {
                    http('/show/reporttype/listReportTypes', {}, 'POST').then((res) => {
                        let paramsID = communityParams;
                        paramsID.id = id;
                        paramsID.reasonable = res.result.dataList;
                        this.setState({
                            communityParams: paramsID,
                            currentOpenMode: Mode.UNABLE,
                        });
                    });
                }
                break;
            case Mode.DELETE:
                this.setState({
                    currentOpenMode: Mode.DELETE,
                    openModeParam: id,
                });
                break;
            case Mode.HOMESTATUS://首页
                if (value) {
                    this._sendReq('/show/feed/updateFeedImageTextHomeReco', { id: id, isHomeReco: 1 }, '首页推荐成功。');
                } else {
                    this._sendReq('/show/feed/updateFeedImageTextHomeReco', { id: id, isHomeReco: 0 }, '取消首页推荐成功。');
                }

                break;
            case Mode.CHANNEL://频道
                if (value) {
                    this._sendReq('/show/feed/updateFeedImageTextChannelReco', { id: id, isChannelReco: 1 }, '频道推荐成功。');
                } else {
                    this._sendReq('/show/feed/updateFeedImageTextChannelReco', { id: id, isChannelReco: 0 }, '取消频道推荐成功。');
                }
                break;
            case 'sortNo':
                if (value.field === 'praiseNumber') {
                    if (value.order === 'ascend') {
                        this.setState({
                            sort: 'ASC',
                        }, () => {
                            this._searchHandle('useCache');
                        });
                    } else if (value.order === 'descend') {
                        this.setState({
                            sort: 'DESC',
                        }, () => {
                            this._searchHandle('useCache');
                        });
                    } else {
                        this.setState({
                            sort: '',
                        }, () => {
                            this._searchHandle('useCache');
                        });
                    }
                }
                break;
        }
    }

    // 渲染弹框
    _renderMode = () => {
        const { communityParams } = this.state;
        switch (this.state.currentOpenMode) {
            case Mode.UNABLE:
                return <Unable visible={true} communityParams={communityParams} closeMode={this._closeMode} />;
            case Mode.DELETE:
                return <DeleteModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        console.log('++++++++++++++' + status);
        this.setState({ currentOpenMode: '' });
        if (status == 'delete') {
            http('/show/feed/deleteImageText', { id: this.state.openModeParam }, 'POST').then(() => {
                message.success('删除成功');
                this._searchHandle('useCache');
            }).catch((e) => {
                message.error(e.message);
            });
        } else if (status) {
            this._searchHandle('useCache');
        }

    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }
    render() {
        const { tel, feedTitle, home, channelReco, homeReco } = this.state;
        const { tableSource, paginations, selectData, selectRecoData, } = this.props;
        return (
            <KeepAlive id='3' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='社区标题'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='feedTitle'
                                value={feedTitle}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='手机号码'
                                placeholder='手机号码'
                                bindThis={this}
                                bindName='tel'
                                value={tel}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='社区分类'
                                placeholder='请选择所属分类'
                                renderData={selectData}
                                bindThis={this}
                                bindName='home'
                                dataIndex='className'
                                keyIndex='id'
                                value={home.className}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='首页推荐'
                                placeholder='请选择所属分类'
                                renderData={selectRecoData}
                                bindThis={this}
                                bindName='homeReco'
                                dataIndex='value'
                                keyIndex='code'
                                value={homeReco.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='频道推荐'
                                placeholder='请选择所属分类'
                                renderData={selectRecoData}
                                bindThis={this}
                                bindName='channelReco'
                                dataIndex='value'
                                keyIndex='code'
                                value={channelReco.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        {/* <XOKButton style={{ width: '120px' }} label='创建社区' onClick={this._communityCreate} selectData={selectData} /> */}
                        <XOKButton style={{ width: '60px', }} label='推荐' onClick={this._recommendHandle} />
                    </div>
                    <TableContent _paginationChange={this._paginationChange} renderData={tableSource} tableAction={this._tableAction} pagiNation={paginations} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}


CommunityList.propTypes = {
    // history: PropTypes.object, // 路由history对象
    getcommunityRecoState: PropTypes.func,// 首页。频道下拉
    updateContent: PropTypes.func,
    getcommunityState: PropTypes.func,// 通过Saga异步请求下拉选项数据方法Action
    selectRecoData: PropTypes.array,// 社区列表首页、频道-推荐下拉数据
    tableSource: PropTypes.array, // 表格数据源
    selectData: PropTypes.array, // 分类状态下拉选项
    paginations: PropTypes.object, // 分页数据
    getcommunityTableSource: PropTypes.func, // 通过Saga异步请求表格数据源的Action
};

const mapStateToProps = (state) => {
    return {
        tableSource: state.channellist.communityDate.tableSource,
        selectData: state.channellist.communityDate.SelectData,
        selectRecoData: state.channellist.communityDate.SelectRecoData,
        paginations: state.channellist.communityDate.paginations,
    };
};

export default connect(mapStateToProps, { getcommunityTableSource, getcommunityState, getcommunityRecoState, })(CommunityList);
