/**
 * 秀场管理 -礼物管理 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';


/**
 * 礼物管理-魅力值、人气值礼物分类列表 Saga
 */

export const startgiftSource = function* (){//获取'魅力值礼物分类
    yield takeEvery(T.GET_STARTGIFT_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象
        try{
            let result = yield http('/show/showClassData/list', action.payload,'POST');
            yield put({type:T.SET_STARTGIFT_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

/**
 * 礼物管理-魅力值、人气值礼物分类下拉- 分类状态 Saga
 */

export const startgiftState = function* (){//获取'魅力值礼物分类列表
    yield takeEvery(T.GET_GIFTSTATE_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象
        try{
            let result = yield http('/enum/StateEnum', action.payload,'POST');
            yield put({type:T.SET_GIFTSTATE_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

/**
 * 礼物管理-魅力值、人气值礼物列表-列表 Saga 
 */
export const startglistSource = function* (){
    yield takeEvery(T.GET_STARTSGLIST_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象
        try{
            let result = yield http('/show/giftInfo/list', action.payload,'POST');
            yield put({type:T.SET_STARTSGLIST_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

/**
 * 魅力值购买记录 Saga  
 */
export const starsbuySource = function* (){//购买记录
    yield takeEvery(T.GET_STARTBUY_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象
        try{
            let result = yield http('/show/rewardOrder/listRewardOrder4StarLight', action.payload,'POST');
            yield put({type:T.SET_STARTBUY_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

/**
 * 人气值购买记录 Saga  
 */
export const starsvaluebuySource = function* (){//购买记录
    yield takeEvery(T.GET_STARTVALUEBUY_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象
        try{
            let result = yield http('/show/rewardOrder/listRewardOrder4StarValue', action.payload,'POST');
            yield put({type:T.SET_STARTVALUEBUY_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};
 