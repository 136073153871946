/**
 * 用户管理 - 主播管理异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

// 获取主播列表
export const getTVAnchorList = function* (){
    yield takeEvery(T.GET_TVANCHOR_LIST, function* requestData(action){
        try{
            let result = yield http('/user/account/listAccount', action.payload, 'POST');
            yield put({type:T.SET_TVANCHOR_LIST, payload: result });
        }catch(e){
            yield put({type:T.INIT_TVANCHOR_LIST});
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};
// 获取主播类型
export const getTVAnchorTypeList = function* (){
    yield takeEvery(T.GET_TVANCHORTYPE_LIST, function* requestData(action){
        try{
            let result = yield http('/enum/AnchorTypeEnum', action.payload, 'POST');
            yield put({type:T.SET_TVANCHORTYPE_LIST, payload: result });
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};
