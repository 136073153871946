/**
 * 广告投放
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Advert from './actiontypes';

export const getAdvertPlacementList = function* () {
    yield takeEvery(Advert.GET_PLACEMENT_LIST, function* requestData(action) {
        try {
            let result = yield http('/ad/send/list', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Advert.SET_PLACEMENT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getAdvertPlacementAudit = function* () {
    yield takeEvery(Advert.GET_PLACEMENT_AUDIT, function* requestData(action) {
        try {
            let result = yield http('/ad/check/record/list', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Advert.SET_PLACEMENT_AUDIT, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getAdvertPlacementStartAndStop = function* () {
    yield takeEvery(Advert.GET_PLACEMENT_STARTANDSTOP, function* requestData(action) {
        try {
            let result = yield http('/ad/send/updateState', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Advert.SET_PLACEMENT_STARTANDSTOP, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getAdvertPlacementDetail = function* () {
    yield takeEvery(Advert.GET_PLACEMENT_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/ad/send/detail', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Advert.SET_PLACEMENT_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getAdvertPlacementDelete = function* () {
    yield takeEvery(Advert.GET_PLACEMENT_DELETE, function* requestData(action) {
        try {
            let result = yield http('/ad/send/delete', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Advert.SET_PLACEMENT_DELETE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
export const getAdvertPlacementAdd = function* () {
    yield takeEvery(Advert.GET_PLACEMENT_ADD, function* requestData(action) {
        try {
            let result = yield http('/ad/send/add', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Advert.SET_PLACEMENT_ADD, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
export const getAdvertPlacementEdit = function* () {
    yield takeEvery(Advert.GET_PLACEMENT_EDIT, function* requestData(action) {
        try {
            let result = yield http('/ad/send/update', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Advert.SET_PLACEMENT_EDIT, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
export const getAdvertList = function* () {
    yield takeEvery(Advert.GET_PLACEMENT_ADVERTLIST, function* requestData(action) {
        try {
            let result = yield http('/ad/page/list', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Advert.SET_PLACEMENT_ADVERTLIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
export const getAdvertStrategyList = function* () {
    yield takeEvery(Advert.GET_PLACEMENT_ADVERTSTRATEGYLIST, function* requestData(action) {
        try {
            let result = yield http('/adStrategy/list', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Advert.SET_PLACEMENT_ADVERTSTRATEGYLIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 广告投放状态
export const getAdvertPlacementStatus = function* () {
    yield takeEvery(Advert.GET_PLACEMENT_ADVERTSTATUS, function* requestData(action) {
        try {
            let result = yield http('/ad/send/listState', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Advert.SET_PLACEMENT_ADVERTSTATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};