import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/auditmanage/enterpriseAnchor/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class EnterpriseAnchor extends Component {

    static propTypes = {
        history: PropTypes.object,
        addOneBreadcrumbPath: PropTypes.func,
        keepSecondNavBreadcrumb: PropTypes.func,
        getEnterpriseAnchors: PropTypes.func,
        enterpriseAnchors: PropTypes.object,
    }

    static defaultProps = {
        enterpriseAnchors: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
    }

    state = {
        businessCompanyName: '',
        mainTel: '',
        beginTime: null,
        endTime: null,
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, businessCompanyName, mainTel, beginTime, endTime } = this.state;
        this.searchCache = {
            businessCompanyName, mainTel, beginTime, endTime,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getEnterpriseAnchors(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ businessCompanyName: '', mainTel: '', beginTime: '', endTime: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 路由跳转
    _updateContent = (title, url, id) => {
        const { addOneBreadcrumbPath, history } = this.props;
        addOneBreadcrumbPath({
            title,
            path: `/home/auditmanage/enterpriseanchor${url}`
        });
        history.push({
            pathname: `/home/auditmanage/enterpriseanchor${url}${id}`
        });
    }

    //表格操作
    _tableAction = (id, type) => {
        switch (type) {
            case 'detail':
                this._updateContent('企业主播详情信息', '/detailinfo/', id);
                break;
            case 'audit':
                this._updateContent('企业主播审核信息', /auditinfo/, id);
                break;
            default:
                break;
        }
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { enterpriseAnchors } = this.props;
        const { businessCompanyName, mainTel, beginTime, endTime } = this.state;
        return (
            <KeepAlive id='enterpriseAnchor' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='企业名称'
                                placeholder='请输入企业名称'
                                bindThis={this}
                                bindName='businessCompanyName'
                                value={businessCompanyName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='主账号手机'
                                placeholder='请输入主账号手机'
                                bindThis={this}
                                bindName='mainTel'
                                value={mainTel}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='申请时间'
                                isFormat
                                bindThis={this}
                                bindName='beginTime'
                                value={beginTime}
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                isFormat
                                bindThis={this}
                                bindName='endTime'
                                value={endTime}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent
                        paginationChange={this._paginationChange}
                        renderData={enterpriseAnchors}
                        tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}

export default connect(state => ({
    enterpriseAnchors: state.enterpriseAnchor.enterpriseAnchors,
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(EnterpriseAnchor);