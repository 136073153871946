/**
 * 封禁记录 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

/**
 * 社区、视频列表 Saga
 */
export const graphicVideoList = function* () {
    yield takeEvery(T.GET_GRAPHICVIDEOLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/show/feed/listBannedFeedInfo', action.payload, 'POST');
            yield put({ type: T.SET_GRAPHICVIDEOLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 直播列表 Saga
 */
export const liveList = function* () {
    yield takeEvery(T.GET_LIVELIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/show/live/listBannedLiveInfo', action.payload, 'POST');
            yield put({ type: T.SET_LIVELIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 封禁类型下拉框 Saga
 */
export const bannedtypeDropDown = function* () {
    yield takeEvery(T.GET_BANNEDTYPE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let live = yield http('/show/showClass/listLiveBoradcasClass', action.payload, 'POST');//活动类型
            let video = yield http('/show/showClass/listShortVideoClass', action.payload, 'POST');//推送平台
            let activity = yield http('/show/showClass/listFeedClass', action.payload, 'POST');//活动状态
            live.result.video = video.result.dataList;
            live.result.activity = activity.result.dataList;
            yield put({ type: T.SET_BANNEDTYPE_DATA, payload: live });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
