import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Switch, Icon, Input } from 'antd';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth, tooltipClick } from '@/components/TableCell';
import { Mode } from '../../../index';

class TypeTable extends React.Component {

    _columns = () => [
        {
            title: '排序',
            dataIndex: 'sort',
            key: 'sort',
            width: cellWidth.small,
            align: 'center',
            render: (text, item) => (this._SortCell(text, item))
        },
        {
            title: '标题名称',
            dataIndex: 'feedTitle',
            key: 'feedTitle',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '关联商品数',
            dataIndex: 'goodsList',
            key: 'goodsList',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => tooltipClick(item.goodsList.length, () =>
                this.props.tableAction(item.id, Mode.SELECTED, item)
            )
        },
        {
            title: '所属分类',
            dataIndex: 'feedClassName',
            key: 'feedClassName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => this._switchCell(text, item)
        },
        {
            title: '修改人',
            dataIndex: 'modifyUserName',
            key: 'modifyUserName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '修改时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'key',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            fixed: 'right',
            render: (id, record) => this._tableAction(id, record)
        }
    ];

    _tableAction = (id, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(record.id, Mode.DETAIL)}>详情</div>
                <div className={styles.item} onClick={() => tableAction(record.id, Mode.EDIT, record)}>编辑</div>
                <div className={styles.item} onClick={() => tableAction(record.id, Mode.DELETE)}>删除</div>
                <div className={styles.item} onClick={() => tableAction(record.id, Mode.COPY, record)}>一键复制</div>
            </div>
        );
    };

    _SortCell = (value, item) => {
        const label = React.createRef();
        const box = React.createRef();
        const input = React.createRef();
        const { tableAction } = this.props;
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.sort_box}>
                    <div className={styles.label} ref={label}>{value}</div>
                    <div className={styles.input} ref={box}>
                        <Input
                            style={{ width: '50px' }}
                            ref={input}
                            key={item.id}
                            defaultValue={value}
                            onBlur={(e) => {
                                box.current.style.display = 'none';
                                label.current.style.display = 'block';
                                if (value != e.target.value) {
                                    tableAction(item.id, Mode.SORT, e.target.value);
                                }
                            }}
                        />
                    </div>
                    <div
                        className={styles.icon}
                        onClick={() => {
                            label.current.style.display = 'none';
                            box.current.style.display = 'block';
                            input.current.focus();
                        }}
                    >
                        <Icon type="edit" style={{ fontSize: '18px' }} />
                    </div>
                </div>
            </div>
        );
    };

    // 表格项内容为开关
    _switchCell = (sw, item) => {
        const { tableAction } = this.props;
        let checked = false;
        if (sw == 'APPROVAL') {
            checked = true;
        }

        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checked={checked}
                    onChange={(value) => {
                        tableAction(item.id, Mode.STOP, value ? 1 : 0);
                    }}
                />
            </div>
        );
    };

    onSelectChange = selectedRowKeys => {
        this.props.onSelectChange(selectedRowKeys);
    }

    render() {
        const { paginationChange, scrollY } = this.props;
        const { resultTotal, pageSize, pageNum, dataList } = this.props.renderData || {};

        const rowSelection = {
            selectedRowKeys: this.props.selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    selectedRowKeys={this.props.selectedRowKeys}
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={rowSelection}
                    rowKey={record => record.id}
                    columns={this._columns()}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ x: 'max-content', y: scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange} />
            </div>
        );
    }
}

TypeTable.propTypes = {
    onSelectChange: PropTypes.func,
    selectedRowKeys: PropTypes.array,
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func,
    scrollY: PropTypes.string
};

export default XTableHOC(TypeTable);