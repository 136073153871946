
import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table, Button } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

const TableContent = ({ renderData: { pageNum, pageSize, resultTotal, dataList }, paginationChange }) => {

    const _periodCell = (record) => {
        const periodTime = `${record.periodStartTime} ~ ${record.periodEndTime}`;
        return tooltip(periodTime);
    };

    const _columns = () => ([
        {
            title: '手机号',
            dataIndex: 'telephone',
            key: 'telephone',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '服务商名称',
            dataIndex: 'serviceProviderName',
            key: 'serviceProviderName',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '批发业绩统计(元)',
            dataIndex: 'performanceStatistics',
            key: 'performanceStatistics',
            width: 150,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '平台排名',
            dataIndex: 'ranking',
            key: 'ranking',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '统计时间',
            dataIndex: 'statisticsTime',
            key: 'statisticsTime',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '周期时间',
            dataIndex: 'period',
            key: 'period',
            width: 300,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, record) => _periodCell(record)
        },
        {
            title: '运营中心',
            dataIndex: 'operatingCenterName',
            key: 'operatingCenterName',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '区域公司',
            dataIndex: 'regionalCompanyName',
            key: 'regionalCompanyName',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '贡献值名称',
            dataIndex: 'xiubeiName',
            key: 'xiubeiName',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '统计条件',
            dataIndex: 'statisticalConditions',
            key: 'statisticalConditions',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: (text) => tooltip(text ? text.value : '')
        },
        {
            title: '贡献值副标题',
            dataIndex: 'xiubeiSubtitle',
            key: 'xiubeiSubtitle',
            width: 200,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
    ]);

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.id}
                columns={_columns()}
                dataSource={dataList}
                pagination={false}
                scroll={{ x: 1850, y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

TableContent.defaultProps = {
    renderData: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }
};

export default TableContent;