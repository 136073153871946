import { combineReducers } from 'redux';
import {
	//dealingUnitSelectSource,
	platformReconciliationTableSource,
	stateSelectSource,
	detail,
	detailTableSource
} from './reducer';

export default combineReducers({
	//dealingUnitSelectSource,
	platformReconciliationTableSource,
	stateSelectSource,
	detail,
	detailTableSource
});