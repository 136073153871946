import React, { Component } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './StockTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';
import { FloatConversion } from '@/util/conversion';

export default class StockTable extends Component {
    constructor(props) {
        super(props);
        this.FloatConversion = new FloatConversion();
    }
    _columns = () => ([
        {
            title: '交易单号',
            dataIndex: 'id',
            key: 'id',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '股票名称',
            dataIndex: 'stockName',
            key: 'stockName',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '用户账号',
            dataIndex: 'accountNo',
            key: 'accountNo',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '用户类型',
            dataIndex: 'accountTypeValue',
            key: 'accountTypeValue',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        //     title: '商户名称',
        //     dataIndex: 'merchantName',
        //     key: 'merchantName',
        //     width: 140,
        //     align: 'center',
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        {
            title: '数量(股)',
            dataIndex: 'number',
            key: 'number',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '信用值数',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '服务费',
            dataIndex: 'serviceFee',
            key: 'serviceFee',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '总信用值数',
            dataIndex: 'totalPayFee',
            key: 'totalPayFee',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '预计收益(%)',
            dataIndex: 'incomeRangeVlaue',
            key: 'incomeRangeVlaue',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结算收益(%)',
            dataIndex: 'settleRatioVlaue',
            key: 'settleRatioVlaue',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结算总收益(元)',
            dataIndex: 'totalSettleFeeVlaue',
            key: 'totalSettleFeeVlaue',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '到期状态',
            dataIndex: 'expireStatusValue',
            key: 'expireStatusValue',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结算状态',
            dataIndex: 'statusValue',
            key: 'statusValue',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '下单时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结算时间',
            dataIndex: 'settleTime',
            key: 'settleTime',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '兑换规则',
            dataIndex: 'ruleName',
            key: 'ruleName',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ]);

    // 解析表格数据，使符合columns格式要求 
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                item.totalSettleFeeVlaue = regFenToYuan(item.totalSettleFee);
                item.expireStatusValue = item.expireStatus && (Object.keys(item.expireStatus).length > 0 ? item.expireStatus.value : '');
                item.statusValue = item.status && (Object.keys(item.status).length > 0 ? item.status.value : '');
                item.settleRatioVlaue = this.FloatConversion.divide(item.settleRatio, 100);
                item.incomeRangeVlaue = `${this.FloatConversion.divide(item.incomeRangeStart, 100)} ~ ${this.FloatConversion.divide(item.incomeRangeEnd, 100)}`;
                item.accountTypeValue = item.accountType && (Object.keys(item.accountType).length > 0 ? item.accountType.value : '');
                return item;
            });
        }
    }

    //渲染组件
    render() {
        const { paginationChange } = this.props;

        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.id}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

StockTable.propTypes = {
    renderData: PropTypes.object, // 表格数据源
    paginationChange: PropTypes.func, // 分页
};