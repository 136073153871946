import { combineReducers } from 'redux';
import { studioRoomTable,classifyStatus,videoData,communityData,updateLiveStatus} from './reducer';

export default combineReducers({
	studioRoomTable, // 存储'直播间分类表格'数据源
	classifyStatus,

	//视频
	videoData,
	communityData,

	// 
	updateLiveStatus
});