import { createModel } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 };

const model = {
    namespace: 'taxcertificate',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getlistTaxCommissionRecord': T('getlistTaxCommissionRecord'),
            'getTaxCommissionRecordInfo': T('getTaxCommissionRecordInfo'),
        },
        actions: {
            'getlistTaxCommissionRecord': A('getlistTaxCommissionRecord'),
            'getTaxCommissionRecordInfo': A('getTaxCommissionRecordInfo'),
        },
        sagas: {
            'getlistTaxCommissionRecord': S('getlistTaxCommissionRecord', '/settle/taxcommissionrecord/listTaxCommissionRecord'),
            'getTaxCommissionRecordInfo': S('getTaxCommissionRecordInfo', '/settle/taxcommissionrecord/getTaxCommissionRecordInfo', 'POST', 'id'),
        },
        reducers: {
            'listTaxCommissionRecord': R('getlistTaxCommissionRecord', dataList),
            'TaxCommissionRecordInfo': R('getTaxCommissionRecordInfo', {}),
        }
    })
};
export default createModel(model);