/**
 * 星球商学院 Saga集合
 */
import kClassify from './channelclassify/model';
import KChannel from './channellist/model';

export default [
    ...Object.values(kClassify.sagas),
    ...Object.values(KChannel.sagas),
];
