/**
 * 卡劵管理 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

/**
 * 卡劵管理列表 Saga  
 */
export const cardticketList = function* () {
    yield takeEvery(T.GET_CARDTICKETLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/coupon/coupon/couponList', action.payload, 'POST');
            yield put({ type: T.SET_CARDTICKETLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 卡劵管理编辑、详情获取数据 Saga
 */
export const cardtickDetails = function* () {
    yield takeEvery(T.GET_CARDTICKDETAILS_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/coupon/coupon/infoCoupon', action.payload, 'POST');
            yield put({ type: T.SET_CARDTICKDETAILS_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 卡劵管理下拉框 Saga 
 */
export const cardticketDropDown = function* () {
    yield takeEvery(T.GET_CARDTICKDROPDIWN_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let couponType, couponState;
            if (action.payload.only == 'only') {
                couponType = yield http('/coupon/coupon/getCouponType', action.payload, 'POST');//卡劵类型
            } else {
                couponType = yield http('/coupon/coupon/getCouponType', action.payload, 'POST');//卡劵类型
                couponState = yield http('/coupon/coupon/getCouponStatus', action.payload, 'POST');//卡劵状态
                couponType.couponState = couponState.result;
            }
            yield put({ type: T.SET_CARDTICKDROPDIWN_DATA, payload: couponType });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};



/**
 * 卡劵管理新建-使用门店、和全选复选、商品范围、有限期固定时长Saga
 */
export const cardticketRaiseAll = function* () {
    yield takeEvery(T.GET_RAISEALL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/coupon/coupon/getCouponScopeType', action.payload, 'POST');//卡劵管理新建使用门店
            let checkall = yield http('/user/account/listAccountLevel', action.payload, 'POST');//卡劵管理新建-全选复选
            let goods = yield http('/coupon/coupon/getCouponCategory', action.payload, 'POST');//卡劵管理新建-商品范围
            let fixedtime = yield http('/coupon/coupon/getCouponTermValidity', action.payload, 'POST');//卡劵管理新建-有限期
            let deadline = yield http('/coupon/coupon/getCouponDuration', action.payload, 'POST');//卡劵管理新建-有限期固定时长
            yield put({ type: T.SET_REWARD_DATA, payload: goods });
            yield put({ type: T.SET_RAISEDATA_DATA, payload: result });
            yield put({ type: T.SET_ALL_DATA, payload: checkall });
            yield put({ type: T.SET_COUPONNAME_DATA, payload: fixedtime });
            yield put({ type: T.SET_COUPONNAMESELECT_DATA, payload: deadline });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 代言任务新建-选择商品表格Saga
 */
export const cardtickRaiseTable = function* () {
    yield takeEvery(T.GET_RAISETABALE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            // 原接口：'/goods/goods/simpleGoodsHasStoreList'
            let table = yield http('/goods/query/queryGoodsItem', { ...action.payload, queryCode: 'QUERY_CODE_COUPON_HAS_SHOP' }, 'POST');//代言任务新建选择商品表格数据
            yield put({ type: T.SET_RAISETABALE_DATA, payload: table });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
export const cardtickAllGoods = function* () {
    yield takeEvery(T.GET_CARDTICKALLGOODS_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            // 原接口：/goods/goods/simpleGoodsList
            let result = yield http('/goods/query/queryGoodsItem', { ...action.payload, queryCode: 'QUERY_CODE_MERCHANT_COUPONS' }, 'POST');
            yield put({ type: T.SET_CARDTICKALLGOODS_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const cardtickShopMode = function* () {
    yield takeEvery(T.GET_CARDTICKSHOPMODE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let table = yield http('/shop/listAll', action.payload, 'POST');//代言任务新建选择商品表格数据
            yield put({ type: T.SET_CARDTICKSHOPMODE_DATA, payload: table });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
