import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import styles from './index.module.scss';
import { XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/systemmanage/premiumyield/model';
import { SearchBox, SearchItem } from '@/components/Layout';
import Create from './modal/Create';
import BlockUp from './modal/BlockUp';

class PremiumYield extends Component {

    static propTypes = {
        curTabID: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        history: PropTypes.object,
        addOneBreadcrumbPath: PropTypes.func,
        keepSecondNavBreadcrumb: PropTypes.func,
        getRMBCashData: PropTypes.func,
        rmbCashData: PropTypes.object,
    }

    static defaultProps = {
        rmbCashData: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
    }

    state = {
        startTime: null,
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, startTime } = this.state;
        this.searchCache = {
            startTime,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getRMBCashData(this.searchCache);
        KeepAlive.saveCache(this.searchCache); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ startTime: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }


    // 表格操作
    _tableAction = (id, type) => {
        if (type === 'blockup') {
            this.setState({
                currentOpenMode: 'blockup',
                openModeParam: { id },
            });
        }
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 打开弹窗
    _renderMode = () => {
        const { currentOpenMode, openModeParam } = this.state;
        if (currentOpenMode === 'create') {
            return <Create data={openModeParam} closeMode={this._closeMode} curTabID={this.props.curTabID} />;
        }
        if (this.state.currentOpenMode === 'blockup') {
            return <BlockUp data={openModeParam} closeMode={this._closeMode} curTabID={this.props.curTabID} />;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' }, () => {
            if (update) {
                this._searchHandle('useCache');
            }
        });
    }

    _createHandle = () => {
        this.setState({ currentOpenMode: 'create' });
    }

    render() {
        const { rmbCashData, curTabID } = this.props;
        const { startTime } = this.state;
        return (
            <KeepAlive id='rmbcash' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                label='执行时间'
                                bindThis={this}
                                bindName='startTime'
                                value={startTime}
                                showTime={false}
                                defaultTime="00:00:00"
                            />

                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <XOKButton
                        label='新建比例'
                        style={{ width: '100px' }}
                        onClick={this._createHandle}
                    />
                    <TableContent
                        paginationChange={this._paginationChange}
                        renderData={rmbCashData}
                        tableAction={this._tableAction}
                        curTabID={curTabID}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

const mapStateToProps = state => ({
    rmbCashData: state.premiumyield.rmbCashData,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(PremiumYield);