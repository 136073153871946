/**
 * 新建热门城市设置
 */
import React from 'react';
import { Modal, } from 'antd';
import { XSelect, } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './HotModal.module.scss';
import { message, } from 'antd';
import http from '@/assets/api/http';

class Hot extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        cityName: {},//选择城市
    }

    _handleOk = () => {
        const { cityName, } = this.state;
        if (Object.keys(cityName).length === 0) return message.warn('请选择城市。');
        this.setState({ confirmLoading: true }, () => {
            http(`/hotel/hotCity/addHotCity/${cityName.hotCityId}`, {}, 'POST')
                .then(() => {
                    message.success('新建成功。');
                    this.setState({
                        visible: false,
                    });
                    this.props.closeMode(true);
                }).catch((reject = {}) => {
                    this.setState({ confirmLoading: false });
                    message.error(reject.message);
                });
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, title, confirmLoading, cityName } = this.state;
        const { data } = this.props;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                onOk={this._handleOk}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                okText='确定'
                cancelText='取消'
            >

                <div className={styles.container}>
                    <div className={`${styles.layout} ${styles.dataTop}`}>
                        <XSelect
                            selectStyle={{ width: '330px' }}
                            isRequired
                            label='选择城市'
                            placeholder='请选择城市'
                            renderData={data}
                            dataIndex='name'
                            keyIndex='hotCityId'
                            value={cityName.name}
                            showSearch={true}
                            bindThis={this}
                            bindName='cityName'
                            isOptionBindID={true}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

Hot.propTypes = {
    title: PropTypes.string,// 弹框标题
    data: PropTypes.array, //数据
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default Hot;