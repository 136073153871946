/**
 * 音乐分类的创建、编辑弹框
 */
import React from 'react';
import { Modal, Radio, message, Icon, Button } from 'antd';
import PropTypes from 'prop-types';
import styles from './TypeModal.module.scss';
import { XInput, XSelect } from '@/components/xqxc_ui';
import CustomUpload, { generateFileList } from '@/components/NewUpload';
import http from '@/assets/api/http';
import { formValids } from '@/assets/js/utils';

const uploadProps = {
    icon: {
        accept: '.png, .jpg, .jpeg, .gif',
        listType: 'picture-card',
        wallMode: 'single',
    },
    pic: {
        accept: '.png, .jpg, .jpeg, .gif',
        listType: 'picture-card',
        wallMode: 'single',
    },
    video: {
        accept: '.mp4',
    }
};

const RadioGroup = Radio.Group;
class TypeModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        radioValue: this.props.data.state,
        recommendValue: this.props.data.recommend,
        imgs: []
    }

    constructor(props) {
        super(props);
        const { type } = props.data || {};
        this.state.imgs = generateFileList(props.data[type + 'Url']);
        this.state.typeInput = props.data[type + 'Name'];
        this.state[type + 'CategoryId'] = this.props.data[type + 'CategoryId'];
        this.state.CategoryName = this.props.data[type + 'CategoryName'];
    }

    _handleOk = () => {

        try {
            const { imgs } = this.state;
            const { type } = this.props.data || {};
            const id = type + 'Id',
                className = type + 'Names',
                categoryId = type + 'CategoryId',
                img = type + 'Urls';

            let classNames = [this.state.typeInput].filter(v => !!v);
            let fields = {
                [id]: this.props.data[id],
                [categoryId]: this.state[categoryId],
                [className]: classNames.length ? classNames : undefined,
                [img]: imgs.length ? [imgs[0].response ? imgs[0].response.key : imgs[0].url] : '',
                state: this.state.radioValue,
                recommend: this.state.recommendValue
            };

            if (formValids(fields, {
                [className]: '请输入素材名称',
                [categoryId]: '请选择素材分类',
                [img]: '请上传素材'
            })) {
                this.setState({ confirmLoading: true });
                http('/custom/' + type + 'List/save', {
                    ...fields
                }, 'POST').catch((res) => message.error('保存数据异常')).finally(() => {
                    this.setState({ confirmLoading: false, visible: false });
                    this.props.closeMode(true);
                });
            }
        } catch (e) {
            message.error('数据保存异常');
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }

    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }

    render() {
        const { visible, confirmLoading, title, radioValue, recommendValue, imgs, typeInput, CategoryName } = this.state;
        const { type } = this.props.data || {};
        const categoryId = type + 'CategoryId',
            categoryName = type + 'CategoryName';
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container} key={type}>
                    <XInput
                        style={{ width: '408px' }}
                        inputStyle={{ width: '340px' }}
                        label='素材名称'
                        placeholder='请输入素材名称'
                        onChange={typeInput => this.setState({ typeInput })}
                        value={typeInput}
                    />
                    <XSelect
                        style={{ marginTop: '30px', width: '410px' }}
                        selectStyle={{ width: '342px' }}
                        label='素材分类'
                        placeholder='请选择素材分类'
                        renderData={this.props.selectDataSource || []}
                        onChange={(v) => this.setState({ [categoryId]: v[categoryId], CategoryName: v[categoryName] })}
                        dataIndex={categoryName}
                        keyIndex={categoryId}
                        value={CategoryName}
                    />
                    <div className={styles.radio}>
                        <div className={styles.label}>推荐</div>
                        <RadioGroup style={{ marginLeft: '12px' }} value={recommendValue} onChange={(e) => this.setState({ recommendValue: e.target.value })}>
                            <Radio value="1">启用</Radio>
                            <Radio value="0">禁用</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.radio}>
                        <div className={styles.label}>状态</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={radioValue}>
                            <Radio value="1">启用</Radio>
                            <Radio value="0">禁用</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.upload}>
                        <div className={styles.label}>上传素材</div>
                        <div className={type !== 'video' && styles.icon}>
                            <CustomUpload
                                {...uploadProps[type]}
                                fileList={imgs}
                                qiniuUploadProps={{
                                    uploadType: 'image'
                                }}
                                onChange={(info) => {
                                    const newImgs = info.fileList.length > 1 ? [info.fileList.pop()] : info.fileList;
                                    this.setState({ imgs: newImgs });
                                }}
                            >
                                {type === 'video'
                                    ? <Button style={{ marginLeft: 15 }}>上传</Button>
                                    : <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                }
                            </CustomUpload>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

TypeModal.propTypes = {
    selectDataSource: PropTypes.array,
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    form: PropTypes.object
};

export default TypeModal;