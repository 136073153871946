import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import Showpareme from '../showpareme';//商城参数  
import Recommend from '../recommend';//商品列表规则参数
import SalaAfter from '../salaafter';

class Main extends React.Component {

    state = {
        tab: [
            // { id: 1, label: '商城参数' },
            { id: 2, label: '商品列表规则参数' },
            { id: 3, label: '商品售后规则设置' },
        ],
        curTabID: 2,  // 默认显示的tab
    }

    componentDidMount() {
        const { history } = this.props;
        if (history.location.state && history.location.state) {
            this.setState({ curTabID: history.location.state }); // 如果路由过来的state参数带tabID值，则渲染该tabID值下的内容
        }
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容 
    _renderPage = () => {
        switch (this.state.curTabID) {
            case 1:
                return <Showpareme curTabID={this.state.curTabID} history={this.props.history} updateContent={this.props.updateContent} />;
            case 2:
                return <Recommend curTabID={this.state.curTabID} updateContent={this.props.updateContent} history={this.props.history} />;
            case 3:
                return <SalaAfter />;
            default:
                return <Showpareme curTabID={this.state.curTabID} history={this.props.history} updateContent={this.props.updateContent} />;

        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={styles.line}></div>
                <div className={styles.flexBoxContainer}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 跳转其它路由页面
};

export default Main;