import { createModel } from '@/store/tools';

const list = { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 };

const model = {
    namespace: 'logistics',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getRunningErrandsList': T('getRunningErrandsList'),
            'getRunningErrandsDetailList': T('getRunningErrandsDetailList'),
            'getCainiaoTableData': T('getCainiaoTableData'),
            'getWareHouseSele': T('getWareHouseSele'),
            'getMerchantSele': T('getMerchantSele'),
        },
        actions: {
            'getRunningErrandsList': A('getRunningErrandsList'),
            'getRunningErrandsDetailList': A('getRunningErrandsDetailList'),
            'getCainiaoTableData': A('getCainiaoTableData'),
            'getWareHouseSele': A('getWareHouseSele'),
            'getMerchantSele': A('getMerchantSele'), // 商户名称下拉
        },
        sagas: {
            'getRunningErrandsList': S('getRunningErrandsList', '/delivery/list'),
            'getRunningErrandsDetailList': S('getRunningErrandsDetailList', '/deliveryArea/list'),
            'getCainiaoTableData': S('getCainiaoTableData', '/cainiao/logistics'),
            'getWareHouseSele': S('getWareHouseSele', '/warehouse/info/dropDown'),
            'getMerchantSele': S('getMerchantSele', '/merchant/info/listMerchantIdAndName'),
        },
        reducers: {
            'runningErrandsList': R('getRunningErrandsList', list),
            'runningErrandsDetailList': R('getRunningErrandsDetailList', list),
            'cainiaoTableData': R('getCainiaoTableData', list),
            'wareHouseSele': R('getWareHouseSele', []),
            'merchantSele': R('getMerchantSele', list),
        }
    })
};
export default createModel(model);