/**
 *  营销活动规则设置
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const Mode = {
    EDIT: 'EDIT',//编辑
    NEWCREATE: 'NEWCREATE'//新建
};

class Marketing extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case Mode.NEWCREATE:
                addOneBreadcrumbPath({
                    title: '魅力等级新增',
                    path: '/home/systemmanage/charmgrade/pages/createInfo/'
                });
                history.push({
                    pathname: '/home/systemmanage/charmgrade/pages/createInfo/',
                });
                break;
            case Mode.EDIT:
                addOneBreadcrumbPath({
                    title: '魅力等级编辑',
                    path: '/home/systemmanage/charmgrade/pages/editorInfo/'
                });
                history.push({
                    pathname: `${'/home/systemmanage/charmgrade/pages/editorInfo/'}${value}`,
                });
                break;
        }
    }


    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

Marketing.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Marketing);