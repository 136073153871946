import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { priceFenToYuanCell, tooltipStyle, tooltip, cellWidth, tooltipClick } from '@/components/TableCell';
import { Mode } from '../../../index';

class CommunityListTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    _columns = () => [
        {
            title: '标题名称',
            dataIndex: 'feedTitle',
            key: 'feedTitle',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '关联商品数',
            dataIndex: 'goodsList',
            key: 'goodsList',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => tooltipClick(item.goodsList.length, () =>
                this.props.tableAction(item.id, Mode.SELECTED, item)
            )
        },
        {
            title: '所属分类',
            dataIndex: 'feedClassName',
            key: 'feedClassName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '分享次数',
            dataIndex: 'totalShare',
            key: 'totalShare',
            width: cellWidth.normal,
            align: 'center'
        },
        {
            title: '订单数',
            dataIndex: 'totalOrder',
            key: 'totalOrder',
            width: cellWidth.normal,
            align: 'center'
        }
    ];

    render() {
        const { scrollY, paginationChange } = this.props;
        const { dataList, resultTotal, pageSize, pageNum } = this.props.renderData || {};
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._columns()}
                    dataSource={dataList}
                    pagination={false}
                    onChange={this._sortChange}
                    scroll={{ x: 'max-content', y: scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

CommunityListTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func,// 页面发生变化
    scrollY: PropTypes.string
};

export default XTableHOC(CommunityListTable);