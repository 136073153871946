import React, { Component } from 'react';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import TableContent from './components/TableContent';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XOKButton, XCancelButton, XInput, XSelect } from '@/components/xqxc_ui';
import model from '@/reducers/storehousefinancialmanage/withdrawRecord/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class TabContent extends Component {

    static propTypes = {
        history: PropTypes.object,
        keepSecondNavBreadcrumb: PropTypes.func,
        getWithRecordList: PropTypes.func,
        withRecordList: PropTypes.object
    }

    static defaultProps = {
        withRecordList: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
    }

    state = {
        status: [{
            id: 'USER',
            value: '用户',
        }, {
            id: 'SERVICE_PROVIDER',
            value: '服务商',
        }],
        subjectType: {},
        tel: '',
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, tel, subjectType } = this.state;
        const query = {
            tel,
            subjectType: subjectType.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getWithRecordList(query);
        KeepAlive.saveCache(query);
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            tel: '', subjectType: {}
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 状态
    _subjectTypeChange = e => {
        this.setState({
            subjectType: e,
        });
    }

    render() {
        const { status, tel, subjectType } = this.state;

        return (
            <KeepAlive id='withdrawRecord' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        // this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='用户账号'
                                placeholder='请输入用户账号'
                                bindThis={this}
                                bindName='tel'
                                value={tel}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='绑定账户类型'
                                placeholder='请选择'
                                renderData={status}
                                onChange={this._subjectTypeChange}
                                dataIndex='value'
                                keyIndex='id'
                                value={subjectType.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                // style={{ marginLeft: '20px', width: '60px' }}
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                // style={{ marginLeft: '20px', width: '60px' }}
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent
                        renderData={this.props.withRecordList}
                        paginationChange={this._paginationChange}
                    />
                </div>
            </KeepAlive>
        );
    }
}

export default connect(state => ({ ...state.withdrawRecord }), { keepSecondNavBreadcrumb, ...model.actions })(TabContent);