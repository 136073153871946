/**
 *   禁封记录
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XSelect, XOKButton, XCancelButton, } from '@/components/xqxc_ui';
import ProductTable from './components/TableData';
import { getGraphicVideoList, getLiveList, getBannedtypeDropDown, } from '@/reducers/showmanage/forbitrecord/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Forbitrecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tel: '',//手机号码
            nickname: '',//主播昵称
            attributionState: {},//状态
            goodsDataSource: [],// 模拟表格数据
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参

            pageNum: 1,//默认表格当前第几页
            pageSize: 20,//默认表格每页显示多少条
            tabs: [
                { id: 1, label: '直播' },
                { id: 2, label: '视频' },
                { id: 3, label: '社区' },
            ],
            curt: 1,//tab默认
            textVdieoType: '',//区分社区和视频表格
        };
    }

    componentDidMount() {
        const { getBannedtypeDropDown, } = this.props;
        getBannedtypeDropDown({ pageSize: '100', pageNum: '1', state: '1' });//所属分类
    }
    // 导航切换回调
    _anchorClickHandle = (id) => {
        this.setState({ tel: '', nickname: '', attributionState: {}, curt: id, }, () => {
            this._ruleSearchHandle();// 重新渲染更新列表
        });
    }
    // 导航切换
    _anchorMoveHandle = () => {
        const { curt } = this.state;
        return (
            this.state.tabs.map((item, index) => {
                if (index == 0) {
                    return (
                        <span key={item.id} onClick={() => this._anchorClickHandle(item.id)} className={curt == item.id ? styles.curt : ''}>{item.label}</span>
                    );
                } else {
                    return (
                        <span key={item.id} onClick={() => this._anchorClickHandle(item.id)} className={curt == item.id ? styles.curt : ''}>{item.label}</span>
                    );
                }
            })
        );
    }

    // 查询触发
    _ruleSearchHandle = (useCache) => {
        const { getLiveList, getGraphicVideoList, } = this.props;
        const { tel, nickname, attributionState, pageSize, pageNum, curt, } = this.state;
        let curType;//活动时间状态
        if (curt == '1') {
            this.searchCache = {
                tel,//手机号码  
                accountNickname: nickname,//主播昵称  
                broadcasClassId: attributionState.id,//状态  
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            getLiveList(this.searchCache);
        } else {
            if (curt == '2') {
                curType = 'VIDEO';
            } else if (curt == '3') {
                curType = 'GRAPHICS';
            }
            this.searchCache = {
                tel,//手机号码  
                accountNickname: nickname,//主播昵称  
                feedClassId: attributionState.id,//状态  
                feedType: curType,//区分图文、视频
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            getGraphicVideoList(this.searchCache);
        }
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发 
    _ruleresetHandle = () => {
        this.setState({ tel: '', nickname: '', attributionState: {} }, () => {
            KeepAlive.saveResetFunc(this._ruleSearchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        const { getLiveList, getGraphicVideoList, } = this.props;
        const { curt, } = this.state;
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        if (curt == 1) {
            getLiveList(this.searchCache);//默认直播列表数据
        } else {
            getGraphicVideoList(this.searchCache);
        }
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作功能回调 
    _actionClickHandle = (id, type) => {
        console.log(id, type);
    }
    //所属分类数据处理
    _dropDownData = (data) => {
        const { curt, } = this.state;
        let dataSelect = [];
        if (data) {
            if (curt == 1) {
                dataSelect = this._dropDown(data.dataList);
            } else if (curt == 2) {
                dataSelect = this._dropDown(data.video);
            } else if (curt == 3) {
                dataSelect = this._dropDown(data.activity);
            }
        }
        return dataSelect;
    }
    _dropDown = (data = []) => {
        let text = [];
        let obj = {};
        for (let i = 0; i < data.length; i++) {
            obj = {
                id: data[i].id,
                name: data[i].className,
            };
            text.push(obj);
        }
        return text;
    }

    render() {
        const { tableSource, paginations, livetableSource, livepaginations, selectData, } = this.props;
        const { tel, nickname, attributionState, curt, textVdieoType, } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._ruleSearchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.top_title}>
                        {this._anchorMoveHandle()}
                    </div>
                    <div className={styles.pro_commont}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='手机号码'
                                    placeholder='请输入'
                                    bindThis={this}
                                    bindName='tel'
                                    value={tel}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='用户昵称'
                                    placeholder='请输入'
                                    bindThis={this}
                                    bindName='nickname'
                                    value={nickname}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='所属分类'
                                    placeholder='请选择'
                                    renderData={this._dropDownData(selectData)}
                                    dataIndex='name'
                                    keyIndex='id'
                                    value={attributionState.name}
                                    showSearch={true}
                                    bindThis={this}
                                    bindName='attributionState'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._ruleSearchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._ruleresetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.flexBoxContainer}>
                        <ProductTable renderData={curt == 1 ? livetableSource : tableSource} paginationChange={this._paginationChange} textVdieoType={textVdieoType} curTabID={curt}
                            paginations={curt == 1 ? livepaginations : paginations} />
                    </div>
                </div>
            </KeepAlive>
        );
    }
}

Forbitrecord.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getGraphicVideoList: PropTypes.func, // 社区、视频的Action
    getLiveList: PropTypes.func, //直播的Action
    getBannedtypeDropDown: PropTypes.func,// 通过Saga异步请求下拉选项数据方法Action
    selectData: PropTypes.object,
    tableSource: PropTypes.array,// 社区、视频列表
    paginations: PropTypes.object,// 社区、视频列表
    livetableSource: PropTypes.array,// 直播列表分类列表
    livepaginations: PropTypes.object,// 直播分页对象
};

const mapStateToProps = (state) => {
    return {
        tableSource: state.forbitrecord.forbitrecordDate.tableSource, // 表格数据源
        paginations: state.forbitrecord.forbitrecordDate.paginations, // 分页数据
        livetableSource: state.forbitrecord.forbitrecordDate.livetableSource, // 直播列表分类列表
        livepaginations: state.forbitrecord.forbitrecordDate.livepaginations, // 直播分页对象

        selectData: state.forbitrecord.forbitrecordDate.selectData, // 封禁类型下拉选项
    };
};

export default connect(mapStateToProps, { getGraphicVideoList, getLiveList, getBannedtypeDropDown, })(Forbitrecord);
