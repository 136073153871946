import { createModel } from '@/store/tools';

const model = {
    namespace: 'payorder',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getPayOrderList': T('getPayOrderList'),
            'getPayOrderInfo': T('getPayOrderInfo'),
            'getStateEnum': T('getStateEnum'),
        },
        actions: {
            'getPayOrderList': A('getPayOrderList'),
            'getPayOrderInfo': A('getPayOrderInfo'),
            'getStateEnum': A('getStateEnum'),
        },
        sagas: {
            'getPayOrderList': S('getPayOrderList', '/earningsServicePayVoucher/list'),
            'getPayOrderInfo': S('getPayOrderInfo', '/earningsServicePayVoucher', 'POST', 'id'),
            'getStateEnum': S('getStateEnum', '/enum/AuditStatusEnum'),
        },
        reducers: {
            'payOrderList': R('getPayOrderList', { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 }),
            'payOrderInfo': R('getPayOrderInfo', { auditStatus: {} }),
            'stateEnum': R('getStateEnum', []),
        }
    })
};
export default createModel(model);