/**
 *  平台服务费收入明细
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './main';

const PlatServiceFee = ({ history }) => {
    return <Main history={history} />;
};

PlatServiceFee.propTypes = {
    history: PropTypes.object
};

export default PlatServiceFee;