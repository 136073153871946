import React from 'react';
import styles from './AdvertTable.module.scss';
import { connect } from 'react-redux';
import { XPagination } from '@/components/xqxc_ui';
import { getPlatFormList } from '@/reducers/storehousefinancialmanage/bankAccount/actions';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import IncomeWallet from '@/vcomps/WalletButton/IncomeWallet';
import SettleAccount from '@/vcomps/WalletButton/SettleAccount';
import ChargeAccount from '@/vcomps/WalletButton/ChargeAccount';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { SearchBox, SearchItem } from '@/components/Layout';

class AdvertTable extends React.Component {

    state = {}

    _takeColumns = () => ([
        {
            title: '销售金额(元)',
            dataIndex: 'salesAmount',
            key: 'salesAmount',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '技术服务费(元)',
            dataIndex: 'platformServiceFee',
            key: 'platformServiceFee',
            width: '15%',
            align: 'center',
            render: (text, item) => (this._renderActionCell(item.subjectId, item.subjectType, item.platformServiceFeeCode, text))
        },
        {
            title: '平台服务费(元)',
            dataIndex: 'distributionFee',
            key: 'distributionFee',
            width: '15%',
            align: 'center',
            render: (text, item) => (this._renderActionCell(item.subjectId, item.subjectType, item.distributionFeeCode, text))
        },
        {
            title: '秀场服务费(元)',
            dataIndex: 'showServiceFee',
            key: 'showServiceFee',
            width: '15%',
            align: 'center',
            render: (text, item) => (this._renderActionCell(item.subjectId, item.subjectType, item.showServiceFeeCode, text))
        },
        {
            title: '心选推荐费(元)',
            dataIndex: 'heartSelectionRecommendationFee',
            key: 'heartSelectionRecommendationFee',
            width: '15%',
            align: 'center',
            render: (text, item) => (this._renderActionCell(item.subjectId, item.subjectType, item.heartSelectionRecommendationFeeCode, text))
        },
        {
            title: '增值服务费(元)',
            dataIndex: 'platServiceManagementProfit',
            key: 'platServiceManagementProfit',
            width: '15%',
            align: 'center',
            render: (text, item) => (this._renderActionCell(item.subjectId, item.subjectType, item.platServiceManagementProfitCode, text, 'orderInfo'))
        },
        {
            title: '营销费用(元)',
            dataIndex: 'marketingFee',
            key: 'marketingFee',
            width: '15%',
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
    ]);

    _renderActionCell = (id, type, code, text, goto = 'info') => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, type, code, goto)}>{regFenToYuan(text)}</div>
            </div>
        );
    };

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize } = this.state;
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getPlatFormList(this.searchCach);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 分页
    paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize });
        this.searchCache = {
            pageNum,
            pageSize
        };
        this.props.getPlatFormList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <KeepAlive id='6' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            {/* <WalletButton showWallet showMonitor /> */}
                            {/* 收益钱包 */}
                            <ButtonPerssion code='storehousefinancialmanage/bankAccount/syqb'>
                                <IncomeWallet />
                            </ButtonPerssion>
                            {/* 监管账户 */}
                            <ButtonPerssion code='storehousefinancialmanage/bankAccount/jgzh'>
                                <SettleAccount />
                            </ButtonPerssion>
                            {/* 手续费账户 */}
                            <ButtonPerssion code='storehousefinancialmanage/bankAccount/sxfzh'>
                                <ChargeAccount />
                            </ButtonPerssion>
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.tableContainer}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record, index) => index}
                            columns={this._takeColumns()}
                            dataSource={this._takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this.paginationChange}
                        />
                    </div>
                </div>
            </KeepAlive>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    getPlatFormList: PropTypes.func,
    platFormList: PropTypes.object,
    tableAction: PropTypes.func.isRequired, // 表格操作
};

const mapStateToProps = (state) => ({
    platFormList: state.bankAccount.platFormList, // 平台
});
export default connect(mapStateToProps, { getPlatFormList })(AdvertTable);