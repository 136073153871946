import { combineReducers } from 'redux';
import { freeOrderList, freeOrderStatus, freeProductList, freeOrderKanBanList, autoFreeOrderList, autoFreeOrderStatus } from './reducer';

export default combineReducers({
    freeOrderList,
    freeOrderStatus,
    freeProductList,
    freeOrderKanBanList,
    autoFreeOrderList,
    autoFreeOrderStatus
});