/**
 * [机型管理]
 * @author: Fu Xiaochun
 * @date: 2022-08-12 
 */

import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Table, message, Switch, } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import http from '@/assets/api/http';
import model from '@/reducers/systemmanage/printersetup/model';

function ModelManage(props){

    const history  = useHistory();
    
    useEffect(()=>{
        props.keepSecondNavBreadcrumb();
        getList();
    }, []);

    // 获取打印机列表；
    const getList = ()=>{
        props.getPrinterModels();
        // http('/printer/listAllPrinterBrandInfo', {}, 'POST').then(res=>{
        //     setDataList(res);
        // }).catch(err=>{
        //     message.error(err.message);
        // });
    };

    const onEdit = (item)=>{
        props.addOneBreadcrumbPath({
            title: '编辑打印机信息',
            path: `/home/systemmanage/printersetup/cloudPrinter/modelsEdit/${item.id}`
        });
        history.push(`/home/systemmanage/printersetup/cloudPrinter/modelsEdit/${item.id}`);
    };

    const statusSwitchChange=(item)=>{
        let params = {
            id: item.id,
            status: item.status === 'ON' ? 'OFF' : 'ON'
        };
        http('/printer/switchPrinterBrandStatus', params).then(res=>{
            getList();
        }).catch(err=>{
            message.error(err.message);
        });
    };

    const renderStatus = (val, item)=>{
        return <Switch checked={item.status === 'ON'} onChange={()=>{statusSwitchChange(item);}} />;
    };

    const renderAction = (val, item)=>{
        return <a onClick={()=>{onEdit(item);}} style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>更改</a>;
    };

    const columns = [
        {
            title: '品牌名称',
            dataIndex: 'brand',
            key: 'brand',
            align: 'left',
        },
        {
            title: '品牌状态',
            dataIndex: 'status',
            key: 'status',
            align: 'left',
            render: renderStatus,
        },
        {
            title: '是否需要填写密钥',
            dataIndex: 'keyStatusDesc',
            key: 'keyStatusDesc',
            align: 'left',
            render: tooltip
        },
        {
            title: '排序',
            dataIndex: 'seq',
            key: 'seq',
            align: 'left',
            render: tooltip
        },
        
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'left',
            render: renderAction,
        },
    ];

    return(
        <div className={styles.tableList}>
            <Table
                rowKey='id'
                columns={columns}
                dataSource={props.printerModelsList}
                pagination={false}
                scroll={{ y: true }}
            />
        </div>
    );
}

ModelManage.propTypes = {
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息

    printerModelsList: PropTypes.array,
    getPrinterModels: PropTypes.func,
};

const mapStateToProps = (state) => ({
    printerModelsList: state.printersetup.cloudPrinterModels,
});

export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(ModelManage);