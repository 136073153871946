import { createModel } from '@/store/tools';

const model = {
    namespace: 'valueServiceOrder',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getValueServiceOrderList': T('getValueServiceOrderList'),
            'getValueServiceOrderDetails': T('getValueServiceOrderDetails'),
            'getPayType': T('getPayType'),
            'getUserType': T('getUserType'),
            'getOrderStateEnum': T('getOrderStateEnum'),
            'getRecommendTypeEnum': T('getRecommendTypeEnum'),
            'getSalesManOrderListPage': T('getSalesManOrderListPage'), //分销系统
            'getOrderDetails': T('getOrderDetails'), //分销系统详情
            'getBusinessAnchorReferrerRoleEnum': T('getBusinessAnchorReferrerRoleEnum'), //推荐人类型
        },
        actions: {
            'getValueServiceOrderList': A('getValueServiceOrderList'),
            'getValueServiceOrderDetails': A('getValueServiceOrderDetails'),
            'getPayType': A('getPayType'),
            'getUserType': A('getUserType'),
            'getOrderStateEnum': A('getOrderStateEnum'),
            'getRecommendTypeEnum': A('getRecommendTypeEnum'),
            'getSalesManOrderListPage': A('getSalesManOrderListPage'),
            'getOrderDetails': A('getOrderDetails'),
            'getBusinessAnchorReferrerRoleEnum': A('getBusinessAnchorReferrerRoleEnum'),
        },
        sagas: {
            'getValueServiceOrderList': S('getValueServiceOrderList', '/earnings/serviceOrderList'),
            'getValueServiceOrderDetails': S('getValueServiceOrderDetails', '/earnings/serviceOrderDetails'),
            'getPayType': S('getPayType', '/enum/PaymentChannelType'),
            'getUserType': S('getUserType', '/enum/UserTypeEnum'),
            'getOrderStateEnum': S('getOrderStateEnum', '/enum/EarningsOrderStatusEnum'),
            'getRecommendTypeEnum': S('getRecommendTypeEnum', '/enum/RecommendTypeEnum'),
            'getSalesManOrderListPage': S('getSalesManOrderListPage', '/boss/order/getSalesManOrderListPage'),
            'getOrderDetails': S('getOrderDetails', '/boss/order/getOrderDetails'),
            'getBusinessAnchorReferrerRoleEnum': S('getBusinessAnchorReferrerRoleEnum', '/enum/SalesmanReferrerRoleEnum'),
        },
        reducers: {
            'ValueServiceOrderList': R('getValueServiceOrderList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'ValueServiceOrderDetails': R('getValueServiceOrderDetails', {}),
            'payTypeArray': R('getPayType', []),
            'userTypeArray': R('getUserType', []),
            'orderStateEnum': R('getOrderStateEnum', []),
            'RecommendTypeEnum': R('getRecommendTypeEnum', []),
            'SalesManOrderListPage': R('getSalesManOrderListPage', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'OrderDetails': R('getOrderDetails', {}),
            'BusinessAnchorReferrerRoleEnum': R('getBusinessAnchorReferrerRoleEnum', []),
        }
    })
};
export default createModel(model);