import { createModel } from '@/store/tools';

const model = {
    namespace: 'companycanvas',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getReport': T('getReport'),
            'getOperationSelect': T('getOperationSelect'),
            'getCompanySelect': T('getCompanySelect'),
        },
        actions: {
            'getReport': A('getReport'),
            'getOperationSelect': A('getOperationSelect'),
            'getCompanySelect': A('getCompanySelect'),
        },
        sagas: {
            'getReport': S('getReport', '/report/companySaleStatistics'),
            'getOperationSelect': S('getOperationSelect', '/admin/company/listOperationsIdAndName'),
            'getCompanySelect': S('getCompanySelect', '/admin/company/listAreaCompanyIdAndName'),
        },
        reducers: {
            'getReport': R('getReport', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getOperationSelect': R('getOperationSelect', []),
            'getCompanySelect': R('getCompanySelect', []),
        }
    })
};
export default createModel(model);