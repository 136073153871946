import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Radio, Icon } from 'antd';

const RadioGroup = ({ customTemplateClassId, dataSource, onClassChange }) => {

    const [lCursor, setLCursor] = useState('not-allowed');
    const [lColor, setLColor] = useState('#eee');
    const [RCursor, setRCursor] = useState('pointer');
    const [RColor, setRColor] = useState('#000');
    const [scrLeft, setScrLeft] = useState(0);
    const [getWidth, setWidth] = useState(1402);
    const [isFirst, setIsFirst] = useState(true);

    let radioRef = useRef(null);

    useEffect(() => {
        if (isFirst) return;
        if (scrLeft > 0) {
            setLCursor('pointer');
            setLColor('#000');
        } else {
            setLCursor('not-allowed');
            setLColor('#eee');
        }

        if (getWidth - scrLeft == radioRef.clientWidth) {
            setRCursor('not-allowed');
            setRColor('#eee');
        } else {
            setRCursor('pointer');
            setRColor('#000');
        }
    }, [scrLeft]);

    const onLeftOperate = () => {
        if (radioRef.scrollLeft > 0) {
            radioRef.scrollLeft = radioRef.scrollLeft - 200;
            setScrLeft(radioRef.scrollLeft);
            radioRef.scrollTo({
                left: radioRef.scrollLeft - 200,
                behavior: 'smooth'
            });
        } else {
            setScrLeft(0);
        }
    };

    const onRightOperate = () => {
        radioRef.scrollLeft = radioRef.scrollLeft + 200;
        radioRef.scrollTo({
            left: radioRef.scrollLeft + 200,
            behavior: 'smooth'
        });
        setIsFirst(false);
    };

    return <div className={styles.serchBox}>
        <div className={styles.label}>模板类型：</div>
        <div className={styles.tagBox}>
            <Radio.Group
                size='small'
                className={styles.radioGroup}
                value={customTemplateClassId}
                buttonStyle='solid'
                onChange={onClassChange}>
                <div className={styles.fixationItem}>
                    <Radio.Button
                        key={0}
                        value={0}
                    > 全部</Radio.Button>
                </div>
                <Icon
                    type='caret-left'
                    theme='filled'
                    className={styles.iconStyle}
                    style={{
                        cursor: lCursor,
                        color: lColor
                    }}
                    onClick={onLeftOperate}
                />
                <div
                    className={styles.otherItems}
                    ref={ele => radioRef = ele}
                    onScroll={e => {
                        setWidth(e.target.scrollWidth);
                        setScrLeft(e.target.scrollLeft);
                    }}
                >
                    {
                        dataSource.map(item => {
                            return <Radio.Button
                                style={{ marginLeft: '10px' }}
                                key={item.id}
                                value={item.id}>
                                {item.className}
                            </Radio.Button>;
                        })
                    }
                </div>
                <Icon
                    type='caret-right'
                    theme='filled'
                    className={styles.iconStyle}
                    style={{
                        cursor: RCursor,
                        color: RColor
                    }}
                    onClick={onRightOperate}
                />
            </Radio.Group>
        </div>
    </div>;
};

RadioGroup.defaultProps = {
    style: {},
    dataSource: []
};

RadioGroup.propTypes = {
    onClassChange: PropTypes.func,
    customTemplateClassId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    dataSource: PropTypes.array
};

export default RadioGroup;