import * as T from './actiontypes';

//获取标准商品列表
export const getStandardProduct = (params = {}) => ({
    type: T.GET_STANDARDPRODUCT_LIST,
    payload: params
});
//获取标准商品详情
export const getStandardProductDetail = (id) => ({
    type: T.GET_STANDARDPRODUCT_DETAIL,
    payload: { id }
});

//新增标准商品
export const addStandardProduct = (params = {}) => ({
    type: T.ADD_STANDARDPRODUCT,
    payload: params
});

//修改标准商品
export const updateStandardProduct = (params = {}) => ({
    type: T.UPDATE_STANDARDPRODUCT,
    payload: params
});

//删除标准商品
export const delStandardProduct = (id) => ({
    type: T.DEL_STANDARDPRODUCT,
    payload: { id }
});