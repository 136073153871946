/**
 * 复制链接
 */
import React from 'react';
import { Modal, message, } from 'antd';
import PropTypes from 'prop-types';
import styles from './Linkcopy.module.scss';
import http from '@/assets/api/http';
import { XInput, XOKButton, } from '@/components/xqxc_ui';
import copy from 'copy-to-clipboard';

class Linkcopy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            copyName: this.props.data.activityUrl || '',
        };
    }

    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    _copyHandle = (e) => {//复制功能
        if (copy(this.state.copyName)) {
            message.success('已经复制好了！，可以粘贴啦。。。');
        } else {
            message.warning('复制失败，请手动复制！');
        }

    }
    readyOnlyUrl = (e) => {
        this.setState({
            copyName: e.target.value,
        });
    }

    render() {
        const { visible, confirmLoading, title, copyName, } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '330px', marginBottom: '20px' }}
                        inputStyle={{ width: '320px' }}
                        // label=''
                        // placeholder=''
                        readOnly={true}
                        value={copyName}
                        bindThis={this}
                        bindName='typeName'
                    />
                    <XOKButton
                        style={{ marginLeft: '20px', width: '60px', height: '30px' }}
                        label='复制'
                        onClick={this._copyHandle}
                    />
                </div>
            </Modal>
        );

    }
}

Linkcopy.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default Linkcopy;