/**
 * 区域公司列表
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { message } from 'antd';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import UseOrBan from '../../modal/UseOrBan';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { getCompanyListData, getOperationStatus, getOperationCenterSele, getProvinceSele, getCitySele, resetCitySele, getTypeEnum } from '@/reducers/operationManage/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import Recycle from '../../modal/Recycle';
import BatchOpenEquity from '../../../modal/BatchOpenEquity';

class Main extends Component {
    state = {
        currentOpenMode: '', // 当前弹窗
        openModeParam: {}, // 当前弹窗入参
        companyName: '',
        companyStatus: {},
        companyItem: {},
        operation: {},
        province: {},//省份
        city: {},//城市
        typeItem: {}, isOpenRights: {}
    }

    componentDidMount() {
        const { getOperationStatus, getOperationCenterSele, getProvinceSele, getTypeEnum } = this.props;
        getOperationStatus();
        getOperationCenterSele();
        getProvinceSele();
        getTypeEnum();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { companyName, companyStatus, province, city, pageNum, pageSize, operation, typeItem, isOpenRights } = this.state;
        this.searchCache = {
            provinceId: province.id,
            cityId: city.id,
            type: typeItem.code, isOpenRights: isOpenRights.code,
            companyName, companyState: companyStatus.code, operationsId: operation.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        console.log(this.searchCache);
        this.props.getCompanyListData(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize, typeItem }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ companyName: '', companyStatus: {}, operation: {}, province: {}, city: {}, typeItem: {}, isOpenRights: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        this.props.resetCitySele();
    }

    // 创建区域公司
    _createAreaCompany = () => {
        this.props.updateContent('CREATE');
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        const that = this;
        const { updateContent } = that.props;
        const operates = {
            detail: () => updateContent('DETAIL', id),
            edit: () => updateContent('EDIT', id),
            delete: () => {
                showConfirm('您确定要删除该区域公司？', '', () => {
                    http('/admin/company/deleteCompany/' + id, 'POST')
                        .then(() => {
                            that._searchHandle('useCache');
                            message.success('删除成功。');
                        }).catch((error) => {
                            message.error(error.message);
                        });
                });
            },
            use: () => that.setState({ currentOpenMode: 'USE', openModeParam: params }),
            ban: () => that.setState({ currentOpenMode: 'BAN', openModeParam: params }),
            recycle: () => that.setState({ currentOpenMode: 'recycle', openModeParam: params }),
            budget: () => updateContent('budget', id, params),
            isOpenRights: () => {
                const { requrl, tip, ...other } = params;
                showConfirm('温馨提示', `确定要给当前区域公司${tip}权益吗？
                （此处的权益是指：开通【每日抢拍】小程序 - 每日补贴活动，区域公司获得的补贴权益
                开通后，该区域公司才能获得区域公司补贴。
                未开通，区域公司不能获得区域公司补贴）`, () => {
                    http(`/openRights/${requrl}`, { ...other }, 'POST').then(res => { message.success(res.message); this._searchHandle('useCache'); });
                });
            }
        };
        operates[type]();
    }

    //打开弹窗
    _renderMode = () => {
        const { openModeParam } = this.state;
        switch (this.state.currentOpenMode) {
            case 'USE':
                return <UseOrBan title='启用区域公司' visible={true}
                    data={{ action: 'use', url: '/admin/company/updateCompanyState', openModeParam }}
                    closeMode={this._closeMode} />;
            case 'BAN':
                return <UseOrBan title='禁用区域公司' visible={true}
                    data={{ action: 'ban', url: '/admin/company/updateCompanyState', openModeParam }}
                    closeMode={this._closeMode} />;
            case 'recycle':
                return <Recycle closeMode={this._closeMode} openModeParam={openModeParam} />;
            case 'BatchOpenEquity':
                return <BatchOpenEquity subjectSystem='AREA_COMPANY_RIGHTS' closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', openModeParam: {} }, () => {
            if (update) {
                this._searchHandle('useCache');
            }
        });
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { companyList, operationStatus, operationCenterSele, provinceSele, citySele, getCitySele, typeEnum } = this.props;
        const { companyName, companyStatus, operation, province, city, typeItem, isOpenRights } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <ButtonPerssion code='operationcenter/companyList/cjqygs'>
                                <XOKButton style={{ width: 120, marginRight: 20 }} label='创建区域公司' onClick={this._createAreaCompany} />
                            </ButtonPerssion>
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='公司名称'
                                placeholder='请输入公司名称'
                                bindThis={this}
                                bindName='companyName'
                                value={companyName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='所属运营中心'
                                placeholder='请选择所属运营中心'
                                renderData={operationCenterSele}
                                bindThis={this}
                                bindName='operation'
                                dataIndex='companyName'
                                keyIndex='id'
                                showSearch
                                value={operation.companyName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='公司状态'
                                placeholder='请选择公司状态'
                                renderData={operationStatus}
                                bindThis={this}
                                bindName='companyStatus'
                                dataIndex='value'
                                keyIndex='code'
                                value={companyStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='省份'
                                placeholder='请选择省份'
                                renderData={provinceSele}
                                bindThis={this}
                                bindName='province'
                                dataIndex='name'
                                keyIndex='id'
                                value={province.name}
                                onChange={value => {
                                    getCitySele(value.id);
                                }}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='城市'
                                placeholder='请选择城市'
                                renderData={citySele}
                                bindThis={this}
                                bindName='city'
                                dataIndex='name'
                                keyIndex='id'
                                value={city.name}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='类型'
                                placeholder='请选择'
                                renderData={typeEnum}
                                bindThis={this}
                                bindName='typeItem'
                                dataIndex='value'
                                keyIndex='code'
                                value={typeItem.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect label='是否开通权益' placeholder='请选择是否开通权益'
                                renderData={[{ code: 1, value: '是' }, { code: 0, value: '否' }]} dataIndex='value' keyIndex='code'
                                value={isOpenRights.value} bindThis={this} bindName='isOpenRights'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div>
                        <XOKButton style={{ width: '120px', marginRight: '15px' }} label='批量开通权益' onClick={() => this.setState({ currentOpenMode: 'BatchOpenEquity' })} />
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={companyList} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }

}
Main.propTypes = {
    updateContent: PropTypes.func,
    getCompanyListData: PropTypes.func,
    getOperationStatus: PropTypes.func,
    getOperationCenterSele: PropTypes.func,
    companyList: PropTypes.object,
    operationStatus: PropTypes.array,
    operationCenterSele: PropTypes.array,
    getProvinceSele: PropTypes.func,
    provinceSele: PropTypes.array,
    getCitySele: PropTypes.func,
    citySele: PropTypes.array,
    resetCitySele: PropTypes.func,
    getTypeEnum: PropTypes.func,
    typeEnum: PropTypes.array
};
Main.defaultProps = {
    companyList: {
        dataList: [],
        pageNum: 1,
        pageSize: 30,
        resultTotal: 0
    },
    provinceSele: [],
    citySele: [],
    operationCenterSele: [],
    operationStatus: []
};
const mapStateToProps = (state) => ({
    operationCenterSele: state.operationManageData.operationCenterSele,
    companyList: state.operationManageData.companyList,
    operationStatus: state.operationManageData.operationStatus,
    provinceSele: state.operationManageData.provinceSele,
    citySele: state.operationManageData.citySele,
    typeEnum: state.operationManageData.typeEnum,
});
export default connect(mapStateToProps, { getCompanyListData, getOperationStatus, getOperationCenterSele, getProvinceSele, getCitySele, resetCitySele, getTypeEnum })(Main);