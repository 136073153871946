import * as T from './actiontypes';

const initData = {
    dataList: [],
	pageNum: 1,
	pageSize: 20,
	pages: 0,
	resultTotal: 0
 };

export const productListData = (state = initData, action) => {
    switch(action.type){
        case T.SET_PRODUCT_LISTDATA:
            return action.payload.result;
        default:
            return state;
    }
};
export const productStatus = (state = [], action) => {
    switch(action.type){
        case T.SET_PRODUCT_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};


export const productUsername = (state = {}, action) => {
    switch(action.type){
        case T.SET_PRODUCT_USERNAME:
            return action.payload.result;
        default:
            return state;
    }
};

export const productClassify = (state = [], action) => {
    switch(action.type){
        case T.SET_PRODUCT_CLASSIFYSELE:
            return action.payload.result;
        default:
            return state;
    }
};

export const productBusinessType = (state = [], action) => {
    switch(action.type){
        case T.SET_PRODUCT_BUSINESSTYPE:
            return action.payload.result;
        default:
            return state;
    }
};

export const productLabelSele = (state = {}, action) => {
    switch(action.type){
        case T.SET_PRODUCT_LABELSELE:
            return action.payload.result;
        default:
            return state;
    }
};

export const productDetail= (state = {}, action) => {
    switch(action.type){
        case T.SET_PRODUCT_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

export const productRecomment= (state = initData, action) => {
    switch(action.type){
        case T.SET_PRODUCT_RECOMMEND:
            return action.payload.result;
        default:
            return state;
    }
};
