/**
 *  【系统右上角的个人设置】
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const Setting = ({ history }) => {
    return <Main history={history} />;
};

Setting.propTypes = {
    history: PropTypes.object
};

export default Setting;