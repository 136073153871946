import * as Advert from './actiontypes';

/**
 * 获取'广告位'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 */
export const getAdvertAdsenseList = ({adSpaceCode, adSpaceName, adSpaceState, pageSize, pageNum}) => ({
    type: Advert.GET_ADSENSE_LIST,
    payload: {
        adSpaceCode, adSpaceName, adSpaceState, pageSize, pageNum
    }
});
export const getAdvertAdsenseDetail = ({ id }) => ({
    type: Advert.GET_ADSENSE_DETAIL,
    payload: {
        id
    }
});
