import * as Order from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

//订单列表数据
export const productOrderList = (state = initData, action) => {
    switch (action.type) {
        case Order.SET_PRODUCTORDER_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

//商品订单类型
export const productOrderType = (state = [], action) => {
    switch (action.type) {
        case Order.SET_PRODUCTORDER_TYPE:
            return action.payload.result;
        default:
            return state;
    }
};

//商品订单状态
export const productOrderStatus = (state = [], action) => {
    switch (action.type) {
        case Order.SET_PRODUCTORDER_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};
//认购订单状态
export const productSubOrderStatus = (state = [], action) => {
    switch (action.type) {
        case Order.SET_PRODUCTSUBORDER_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};

//商品是否开票
export const productOrderFlagEnum = (state = [], action) => {
    switch (action.type) {
        case Order.SET_PRODUCTORDER_FLAGENUM:
            return action.payload.result;
        default:
            return state;
    }
};

//兑换方式
export const productConvertWays = (state = [], action) => {
    switch (action.type) {
        case Order.SET_PRODUCTORDER_CONVERWAYA:
            return action.payload.result;
        default:
            return state;
    }
};
//商品是否批发
export const DistributionFlagEnum = (state = [], action) => {
    switch (action.type) {
        case Order.SET_PRODUCTPIFA_FLAGENUM:
            return action.payload.result;
        default:
            return state;
    }
};

//支付通道
export const paymentAccessTypeEnum = (state = [], action) => {
    switch (action.type) {
        case Order.SET_PAYMENTACCESSTYPEENUM:
            return action.payload.result;
        default:
            return state;
    }
};
//支付方式
export const paymentTypeEnum = (state = [], action) => {
    switch (action.type) {
        case Order.SET_PAYMENTTYPEENUM:
            return action.payload.result;
        default:
            return state;
    }
};
//订单来源
export const orderSourceEnum = (state = [], action) => {
    switch (action.type) {
        case Order.SET_ORDER_SOURCE_ENUM:
            return action.payload.result;
        default:
            return state;
    }
};