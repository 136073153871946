export const Mode = {
    /** 详情 */
    Detail: 'detail',

    /** 虚拟销量 */
    Virtual: 'Virtual',

    /** 新建 */
    Create: 'create',

    /** 编辑 */
    Edit: 'edit',

    /** 删除 */
    Delete: 'Delete',

    /** 增加发行量 */
    AddCirculation: 'AddCirculation',

    /** 结算 */
    Settlement: 'Settlement',

    /** 启用禁用 */
    StockState: 'StockState'
};