import * as T from './actiontypes';

/**
 * 获取会员列表
 */
const tvAnchorListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const tvAnchorList = (state = tvAnchorListInital, action) => {
    switch(action.type){
        case T.SET_TVANCHOR_LIST:
            return action.payload.result;
        case T.INIT_TVANCHOR_LIST:
            return tvAnchorListInital;
        default:
            return state;
    }
};
export const tvAnchorTypeList = (state = [], action) => {
    switch(action.type){
        case T.SET_TVANCHORTYPE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};



