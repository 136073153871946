/**
 * 秀场管理 - 频道分类异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

// 获取'贴纸分类'下拉
export const getStickypaperClassSelectSource=function*(){
    yield takeEvery(T.GET_STICKYPAPERCLASS_SELECTSOURCE,function* (){
        try{
            let result = yield http('/show/showClass/listStickerClass', {pageSize:9999,pageNum:1}, 'POST'); // action.payload为传入的参数对象
            yield put({
                type:T.SET_STICKYPAPERCLASS_SELECTSOURCE, 
                payload: result.result||{}
            });
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

// 获取'贴纸分类'表格
export const getStickypaperTypeTableSource=function*(){
    yield takeEvery(T.GET_STICKYPAPERTYPE_TABELSOURCE,function* ({payload}){
        try{
            let result = yield http('/show/showClass/listStickerClass', payload.requestProps, 'POST'); // action.payload为传入的参数对象
            console.log(result);
            yield put({
                type:T.SET_STICKYPAPERTYPE_TABELSOURCE, 
                payload: result.result||{},
                requestProps:payload.requestProps
            });
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

// 获取'贴纸列表'表格
export const getStickypaperListTableSource=function*(){
    yield takeEvery(T.GET_STICKYPAPERLIST_TABELSOURCE,function* ({payload}){
        try{
            let result = yield http('/show/sticker/listSticker', payload.requestProps, 'POST'); // action.payload为传入的参数对象
            yield put({
                type:T.SET_STICKYPAPERLIST_TABELSOURCE, 
                payload: result.result||{},
                requestProps:payload.requestProps
            });
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};