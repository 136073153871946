//获取并保存商品列表
export const GET_PRODUCT_LISTDATA = 'LIST/GET_PRODUCT_LISTDATA';
export const SET_PRODUCT_LISTDATA = 'LIST/SET_PRODUCT_LISTDATA';

//获取并保存商品状态下拉
export const GET_PRODUCT_STATUS = 'LIST/GET_PRODUCT_STATUS';
export const SET_PRODUCT_STATUS = 'LIST/SET_PRODUCT_STATUS';

//获取并保存商品商户下拉
export const GET_PRODUCT_USERNAME = 'LIST/GET_PRODUCT_USERNAME';
export const SET_PRODUCT_USERNAME = 'LIST/SET_PRODUCT_USERNAME';

//获取并保存商品业务类型下拉
export const GET_PRODUCT_BUSINESSTYPE = 'LIST/GET_PRODUCT_BUSINESSTYPE';
export const SET_PRODUCT_BUSINESSTYPE = 'LIST/SET_PRODUCT_BUSINESSTYPE';

//获取并保存商品标签下拉
export const GET_PRODUCT_LABELSELE = 'LIST/GET_PRODUCT_LABELSELE';
export const SET_PRODUCT_LABELSELE = 'LIST/SET_PRODUCT_LABELSELE';

//获取并保存商品分类三级联动
export const GET_PRODUCT_CLASSIFYSELE = 'LIST/GET_PRODUCT_CLASSIFYSELE';
export const SET_PRODUCT_CLASSIFYSELE = 'LIST/SET_PRODUCT_CLASSIFYSELE';


//获取并保存商品详情
export const GET_PRODUCT_DETAIL = 'LIST/GET_PRODUCT_DETAIL';
export const SET_PRODUCT_DETAIL = 'LIST/SET_PRODUCT_DETAIL';


//获取/保存商品首页推荐或频道推荐
export const GET_PRODUCT_RECOMMEND = 'CHANNELLIST/GET_PRODUCT_RECOMMEND';
export const SET_PRODUCT_RECOMMEND = 'CHANNELLIST/SET_PRODUCT_RECOMMEND';