/**
 * 上传打款文件
 */
import React from 'react';
import { Modal, message, Icon } from 'antd';
import PropTypes from 'prop-types';
import styles from './BatchPay.module.scss';
import { connect } from 'react-redux';
import model from '@/reducers/storehousefinancialmanage/otherpay/model';
import { XSelect } from '@/components/xqxc_ui';
import CusotmUpload from '@/components/NewUpload';
import http from '@/assets/api/http';
import { regFenToYuan } from '@/util/money';

class BatchPay extends React.Component {
    state = {
        confirmLoading: false,
        excelUrl: '',
        excelList: [],
        gsItem: {}, // 付款公司
        tdItem: {}, // 付款通道
        amount: 0, // 剩余金额
    };

    componentDidMount() {
        this.props.getYouFuPayMerEnum();
        this.props.getYouFuPayOutServiceNameEnum();
    }

    _handleOk = () => {
        const { gsItem, tdItem, excelUrl } = this.state;
        this.setState({ confirmLoading: true });
        if (!gsItem.code) {
            message.error('请选择付款公司');
            this.setState({ confirmLoading: false });
            return;
        }
        if (!tdItem.code) {
            message.error('请选择付款通道');
            this.setState({ confirmLoading: false });
            return;
        }

        const query = {
            platCompanyId: gsItem.code,
            levyId: tdItem.code,
            nameListUrl: excelUrl
        };

        http('/youfuOut/uploadPay', { ...query }).then((response) => {
            this.setState({ confirmLoading: false });
            this.props.close('update');
        }).catch((error) => {
            message.error(error.message);
            this.setState({ confirmLoading: false });
        });
    }

    _handleCancel = () => {
        this.props.close();
    }

    _getAmount = () => {
        const { gsItem, tdItem } = this.state;
        if (!gsItem.code) {
            return;
        }
        if (!tdItem.code) {
            return;
        }

        http('/youfuOut/queryBalance', { platCompanyId: gsItem.code, levyId: tdItem.code }).then((response) => {
            const amount = regFenToYuan(response.result);
            this.setState({ amount });
        }).catch((error) => {
            message.error(error.message);
        });
    }

    render() {
        const { confirmLoading, gsItem, tdItem, excelList, amount } = this.state;
        const { youFuPayMerEnum, youFuPayOutServiceNameEnum } = this.props;

        return (
            <Modal
                width={470}
                centered
                title='上传打款文件'
                visible={true}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.ps}>
                        <div>1.仅支持银行卡付款方式，请下载对应模板完成付款；</div>
                        <div>2.单批次最大支持10000条；</div>
                        <div>3.文件名会作为批次号保存，文件名格式建议为「日期+编号」，例如：2016090901</div>
                        <div>4.备注统一为TD；</div>
                        <div>5.银行卡账户只能是一类卡储蓄账户；</div>
                    </div>
                    <div className={styles.space}></div>
                    <div className={styles.load}>
                        模板：<a href='https://online-avatar.daily-talk.com/%E6%96%87%E4%BB%B6%E4%B8%8A%E4%BC%A0%E6%A8%A1%E7%89%88.zip'>点击下载模板</a>
                    </div>
                    <div className={styles.space}></div>
                    <XSelect
                        style={{ width: '400px' }}
                        selectStyle={{ width: '200px' }}
                        label='付款公司：'
                        showSearch
                        placeholder='请选择'
                        renderData={youFuPayMerEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={gsItem.value}
                        bindThis={this}
                        bindName='gsItem'
                        isOptionBindID
                        onChange={() => {
                            setTimeout(() => {
                                this._getAmount();
                            }, 500);
                        }}
                    />
                    <div className={styles.space}></div>
                    <XSelect
                        style={{ width: '400px' }}
                        selectStyle={{ width: '200px' }}
                        label='付款通道：'
                        showSearch
                        placeholder='请选择'
                        renderData={youFuPayOutServiceNameEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={tdItem.value}
                        bindThis={this}
                        bindName='tdItem'
                        isOptionBindID
                        onChange={() => {
                            setTimeout(() => {
                                this._getAmount();
                            }, 500);
                        }}
                    />
                    <div className={styles.amount}>
                        {amount}（元）
                    </div>
                    <div className={styles.space}></div>
                    <div className={styles.upload}>
                        <div className={styles.name}>付款文件：</div>
                        <CusotmUpload
                            accept='.xlsx'
                            fileList={excelList}
                            listType="picture-card"
                            wallMode="single"
                            qiniuUploadProps={{
                                uploadType: 'image'
                            }}
                            onChange={(info) => {
                                this.setState({ excelList: info.fileList });
                            }}
                            onFileListChange={(info, customFile) => {
                                if (customFile && customFile.status === 'success') {
                                    const excelUrl = info.fileList[0] ? info.fileList[0].url : '';
                                    this.setState({ excelUrl });
                                    if (excelUrl == '') {
                                        this.setState({ excelList: [] });
                                        message.error('上传失败，请稍后重新上传');
                                        return;
                                    }
                                }
                            }}
                        >
                            <div style={{ color: '#999', fontSize: '12px' }}>
                                <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                <p>上传.xlsx文件</p>
                            </div>
                        </CusotmUpload>
                    </div>
                </div>
            </Modal>
        );

    }
}

BatchPay.propTypes = {
    close: PropTypes.func.isRequired, // 关闭弹框的回调函数
    param: PropTypes.object,
    getYouFuPayMerEnum: PropTypes.func, // 枚举：签约公司
    youFuPayMerEnum: PropTypes.array,
    getYouFuPayOutServiceNameEnum: PropTypes.func, // 枚举：签约通道
    youFuPayOutServiceNameEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
    youFuPayMerEnum: state.otherpay.getYouFuPayMerEnum,
    youFuPayOutServiceNameEnum: state.otherpay.getYouFuPayOutServiceNameEnum,
});

export default connect(mapStateToProps, { ...model.actions })(BatchPay);