import * as T from './actiontypes';

/**
 * 出库单列表
 */
const plistInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
// 已分配出库单
// export const invoiceList = (state = plistInital, { type, payload }) => {
//     switch (type) {
//         case T.SET_INVOICELIST:
//             return payload.result;
//         default:
//             return state;
//     }
// };

export const regionalCompanyInvoiceTableSource = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_REGIONALCOMPANYINVOICE_TABLESOURCE:
            return payload.result;
        default:
            return state;
    }
};

export const invoiceManageUnitSelectSource = (state = {}, { type, payload }) => {
    switch (type) {
        case T.SET_INVOICEMANAGE_UNIT_SELECTSOURCE:
            return payload.result;
        default:
            return state;
    }
};
export const regionalCompanyInvoiceDetail = (state = {}, { type, payload }) => {
    switch (type) {
        case T.SET_REGIONALCOMPANYINVOICE_DETAIL:
            return payload.result;
        default:
            return state;
    }
};
