import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table, Tooltip } from 'antd';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell, ImgCell, cellWidth } from '@/components/TableCell';

class TableContent extends Component {
	state = {
		selectedRowKeys: [],
	};

	static propTypes = {
		tableAction: PropTypes.func,
		paginationChange: PropTypes.func,
		renderData: PropTypes.object,
		scrollY: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		])
	};

	_columns = () => [
		{
			title: '商品图片',
			dataIndex: 'mainImgUrl',
			key: 'mainImgUrl',
			align: 'center',
			width: cellWidth.normal,
			render: ImgCell
		},
		{
			title: '商品标题',
			dataIndex: 'goodsName',
			key: 'goodsName',
			align: 'center',
			width: cellWidth.normal,
			onCell: () => this._styleCell(),
			render: (text) => this._tableTipCell(text)
		},
		{
			title: '货号',
			dataIndex: 'goodsNo',
			key: 'goodsNo',
			align: 'center',
			width: cellWidth.normal,
			onCell: () => this._styleCell(),
			render: (text) => this._tableTipCell(text)
		},
		{
			title: '商品分类',
			dataIndex: 'categoryName',
			key: 'categoryName',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '分类类型',
			dataIndex: 'categorySubTypeName',
			key: 'categorySubTypeName',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '商户名称',
			dataIndex: 'merchantName',
			key: 'merchantName',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '销售价格（元）',
			dataIndex: 'salePrice',
			key: 'salePrice',
			align: 'center',
			width: cellWidth.normal,
			render: priceFenToYuanCell
		},
		{
			title: '服务费比例（%）',
			dataIndex: 'platRate',
			key: 'platRate',
			align: 'center',
			width: cellWidth.normal,
		},
		{
			title: '分销比例（%）',
			dataIndex: 'agentRate',
			key: 'agentRate',
			align: 'center',
			width: cellWidth.normal,
		},
		{
			title: '更新时间',
			dataIndex: 'gmtModified',
			key: 'gmtModified',
			align: 'center',
			width: cellWidth.normal,
		},
		{
			title: '操作',
			dataIndex: 'goodsId',
			key: 'goodsId',
			align: 'center',
			width: cellWidth.normal,
			fixed: 'right',
			render: (id, record) => this._tableAction(id, record)
		},
	];

	_styleCell = () => {
		return {
			style: {
				maxWidth: 100,
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				cursor: 'pointer'
			}
		};
	}

	_tableTipCell = (text) => {
		return (
			<Tooltip placement='topLeft' title={text}>{text}</Tooltip>
		);
	};

	// 表格项内容为图片
	_imgCell = (url) => {
		return (
			<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
				<div className={styles.imgbox}>
					<img className={styles.img} style={{ width: '50px', height: '50px' }} src={url} />
				</div>
			</div>
		);
	};

	_moneyCell = (text) => {
		return (
			<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
				{text / 100}
			</div>
		);
	};

	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		if (record.auditState === '待审核') {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'audit')}>审核</div>
				</div>
			);
		} else {
			return '无操作';
		}

	};

	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log(selectedRows);
			this.setState({ selectedRowKeys });
			this.props.tableAction(null, 'batchBan', selectedRowKeys);
		}
	};

	// 清空表格复选框
	_cleanAllSelected = () => {
		this.setState({ selectedRowKeys: [] });
	}

	_tableData = (data) => {
		return data ? data : [];
	}

	render() {
		const { renderData, paginationChange, scrollY } = this.props;
		const { selectedRowKeys } = this.state;
		this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
			<div className={styles.tableContainer} style={{ height: '100%' }}>
				{/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					// rowSelection={this._rowSelection}
					rowKey='goodsId'
					columns={this._columns()}
					pagination={false}
					dataSource={this._tableData(renderData.dataList)}
					scroll={{ x: 'max-content', y: scrollY }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}
export default XTableHOC(TableContent);