/**
 *  会员管理弹框集合
 * */
export { default as AreaModal } from './AreaModal'; // 修改区域弹框
export { default as SetIntegral } from './SetIntegral'; // 批量设置赠送特权值
export { default as LwMember } from './LwMember'; // 添加服务天使
export { default as LwMemberChangeArea } from './LwMemberChangeArea'; // 服务天使修改区域



