import React from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell, switchCell } from '@/components/TableCell';

const TableContent = ({ renderData, paginationChange, tableAction, expandedKeys, handleOnExpand }) => {

    const _takeColumns = () => {
        return [{
            title: '分类名称',
            dataIndex: 'categoryName',
            key: 'categoryName',
            width: '12%',
            align: 'left'
        }, {
            title: '分类图片',
            dataIndex: 'imgUrl',
            key: 'imgUrl',
            width: '12%',
            align: 'center',
            render: ImgCell
        }, {
            title: '技术服务费率(%)',
            dataIndex: 'cityPlatRate',
            key: 'cityPlatRate',
            width: '12%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '平台服务费率(%)',
            dataIndex: 'cityAgentRate',
            key: 'cityAgentRate',
            width: '12%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '排序',
            dataIndex: 'sortNo',
            key: 'sortNo',
            width: '12%',
            align: 'center',
            render: (text, item) => _renderSortCell(text, item)
        }, {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: '12%',
            align: 'center',
            onCell: tooltipStyle,
            render: (t, r) => switchCell(t, () => {
                console.log(t, r);
            })
        }, {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '12%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '12%',
            render: _renderActionCell
        }];
    };

    // 找到当前ID所在的排序列表
    const _searchCurIDSortList = (id, list) => {
        let curIDSortList = [];
        for (let i = 0; i < list.length; i++) {
            if (id === list[i].id) {
                curIDSortList = list;
                break;
            } else {
                if (list[i].childCategory && list[i].childCategory.length > 0) {
                    let clist = _searchCurIDSortList(id, list[i].childCategory);
                    if (clist.length > 0) {
                        curIDSortList = clist;
                        break;
                    }
                }
            }
        }
        return curIDSortList;
    };

    // 查找变换至目标排序的分类对象
    const _searchToItem = (id, list, type) => {
        let toItem = null;
        for (let i = 0; i < list.length; i++) {
            if (id === list[i].id) {
                if (type === 'up') {
                    toItem = list[i - 1];
                    break;
                } else {
                    toItem = list[i + 1];
                    break;
                }
            }
        }
        return toItem;
    };

    // 渲染表格单元（排序）
    const _renderSortCell = (text, item) => {
        return (
            <div className={styles.sortCell}>
                <img
                    className={styles.up}
                    src={require('@/assets/image/up.png')}
                    onClick={() => {
                        let curIDSortList = _searchCurIDSortList(item.id, renderData.dataList);
                        let fromItem = item;
                        let toItem = _searchToItem(item.id, curIDSortList, 'up');
                        tableAction(item.id, 'sort', { from: fromItem, to: toItem, type: 'up' });
                    }}
                />
                <img
                    className={styles.down}
                    src={require('@/assets/image/down.png')}
                    onClick={() => {
                        let curIDSortList = _searchCurIDSortList(item.id, renderData.dataList);
                        let fromItem = item;
                        let toItem = _searchToItem(item.id, curIDSortList, 'down');
                        tableAction(item.id, 'sort', { from: fromItem, to: toItem, type: 'down' });
                    }}
                />
            </div>
        );
    };

    // 操作表格
    const _renderActionCell = (key, item) => {
        return (
            <div className={styles.action}>
                {
                    item.categoryGrade != 3 && <div className={styles.item} onClick={() => tableAction(key, 'addSublevel', item)}>新增子级分类</div>
                }
                <div className={styles.item} onClick={() => tableAction(key, 'edit', item)}>编辑</div>
                <div className={styles.item} onClick={() => tableAction(key, 'delete', item)}>删除</div>
            </div>
        );
    };

    return (<div className={styles.tableContainer}>
        <Table
            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
            rowKey={record => record.id}
            columns={_takeColumns()}
            childrenColumnName='categoryInfoList'
            defaultExpandAllRows
            expandedRowKeys={expandedKeys}
            onExpand={handleOnExpand}
            dataSource={renderData.dataList}
            pagination={false}
            scroll={{ y: true }}
        />
        <XPagination
            resultTotal={renderData.resultTotal}
            pageSize={renderData.pageSize}
            pageNum={renderData.pageNum}
            onChange={paginationChange}
        />
    </div>);
};

TableContent.defaultProps = {
    renderData: { dataList: [{ childCategory: [{ childCategory: [] }] }], pageNum: 1, pageSize: 20, resultTotal: 0 },
};

TableContent.propTypes = {
    tableAction: PropTypes.func.isRequired,//表格操作
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    expandedKeys: PropTypes.array.isRequired,
    handleOnExpand: PropTypes.func.isRequired
};

export default TableContent;