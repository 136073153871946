/**
 * 修改区域弹框
 */
import React from 'react';
import { Modal, Input, Tabs, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './AreaModal.module.scss';
import { connect } from 'react-redux';
import model from '@/reducers/usermanage/member/model';
import http from '@/assets/api/http';

const TabPane = Tabs.TabPane;
class AreaModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        inputVal: '',
        isCity: true,
        isStreet: true,
        tabKey: '1',
        province: {}, // 省份
        city: {}, // 城市
        street: {}, // 区域
    }

    componentDidMount() {
        this.props.getListAreaByParentId({ id: 100000, type: 'province' });
    }

    _handleOk = () => {
        const { province, city, street } = this.state;
        const data = {
            accountIds: this.props.data,
            provinceId: province.id,
            cityId: city.id,
            areaId: street.id
        };
        this.setState({ confirmLoading: true });
        http('/user/account/updateAccountArea', data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('修改成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        }).finally(() => {
            this.setState({ confirmLoading: false });
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _getCity = (selectedItem) => {
        this.setState({
            inputVal: selectedItem.areaName,
            province: selectedItem,
            isCity: false,
            tabKey: '2'
        });
        this.props.getListAreaByParentId({ id: selectedItem.id, type: 'city' });
    }

    _getStreet = (selectedItem) => {
        this.setState({
            inputVal: this.state.province.areaName + '/' + selectedItem.areaName,
            city: selectedItem,
            isStreet: false,
            tabKey: '3'
        });
        this.props.getListAreaByParentId({ id: selectedItem.id, type: 'street' });
    }

    _getStreetInfo = (selectedItem) => {
        this.setState({
            inputVal: this.state.province.areaName + '/' + this.state.city.areaName + '/' + selectedItem.areaName,
            street: selectedItem
        });
    }

    _reset = (key) => {
        if (key == 1) {
            this.setState({
                inputVal: '',
                isCity: true,
                isStreet: true,
                tabKey: '1'
            });
        } else if (key == 2) {
            let newVal = this.state.inputVal.split('/');
            if (newVal.length >= 2) {
                this.setState({
                    inputVal: newVal[0] + '/' + newVal[1],
                    isStreet: true,
                    tabKey: '2'
                });
            }
        }
    }

    render() {
        const { visible, confirmLoading } = this.state;
        const { address } = this.props;
        const { province, city, street } = address;

        return (
            <Modal
                width={800}
                centered
                title='修改区域'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.hint}>请选择区域</div>
                    <div className={styles.up}>
                        <Input placeholder="省份/城市/区县" value={this.state.inputVal} />
                    </div>
                    <div className={styles.down}>
                        <Tabs type="card" onChange={this._reset} activeKey={this.state.tabKey}>
                            <TabPane tab="省份" key="1">
                                <ul className={styles.area}>
                                    {
                                        province.map((item) => {
                                            return <li className={styles.areaItem} key={item.id} onClick={() => { this._getCity(item); }}>{item.areaName}</li>;
                                        })
                                    }
                                </ul>
                            </TabPane>
                            <TabPane tab="城市" key="2" disabled={this.state.isCity}>
                                <ul className={styles.area}>
                                    {
                                        city.map((item) => {
                                            return <li className={styles.areaItem} key={item.id} onClick={() => { this._getStreet(item); }}>{item.areaName}</li>;
                                        })
                                    }

                                </ul>
                            </TabPane>
                            <TabPane tab="区/县" key="3" disabled={this.state.isStreet}>
                                <ul className={styles.area}>
                                    {
                                        street.map((item) => {
                                            return <li className={styles.areaItem} key={item.id} onClick={() => this._getStreetInfo(item)}>{item.areaName}</li>;
                                        })
                                    }
                                </ul>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </Modal>
        );
    }
}

AreaModal.propTypes = {
    data: PropTypes.array, // 需要修改成长值的会员对象数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    SinglePatch: PropTypes.bool, // 权限：单个修改还是批量修改，当为true时表示单个修改
    getListAreaByParentId: PropTypes.func,
    address: PropTypes.object, // 省市区
};

const mapStateToProps = (state) => ({
    address: state.member.address,
});

export default connect(mapStateToProps, { ...model.actions })(AreaModal);

