/**
 * 编辑敏感词
 */
import React from 'react';
import { Modal, Input, Form, message } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import http from '@/assets/api/http';

const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};
class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
            settingCode: {},
            ratio: null,
            allocationDetail: {},
        };
    }
    _handleOk = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const {  data } = this.props;
                const param = {
                  id: data.id,
                  ...fieldsValue,
                };
                this.setState({ confirmLoading: true }, () => {
                    http('/sysConfig/sensitiveWord/upSensitive', param, 'POST').then(() => {
                        message.success('编辑成功');
                        this.props.refresh();
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                        this.props.closeMode();
                    }).catch((reject) => {
                        message.error(reject.message);
                        this.setState({
                            confirmLoading: false,
                        });
                    });
                });
            }
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title } = this.state;
        const {  data } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={600}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon={false}
                            htmlFor='wordType'
                            label={<span>文件类型</span>}
                        >
                            <span>{data.wordType}</span>
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='keyWord'
                            label={<span>词库内容</span>}
                        >
                            {getFieldDecorator('keyWord', {
                                rules: [{ required: true, message: '请输入' }],
                                initialValue: data.keyWord,
                            })(
                                <TextArea
                                    placeholder="请输入"
                                    autosize={{ minRows: 4, maxRows: 16 }}
                                    style={{ width: 400 }}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

CreateModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    refresh: PropTypes.func,
    form: PropTypes.object.isRequired, // 校验
};

const ForgotProducDetails = Form.create({ name: 'create-form' })(CreateModal);// 校验
export default connect(null, { })(ForgotProducDetails);
