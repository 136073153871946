/**
 *  售后管理 - 售后列表
 * */ 
import React from 'react';
import PropTypes from 'prop-types'; 
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const Mode = {
        DETAIL: 'DETAIL',//查看详情
};

class merchantlist extends React.Component {

    componentDidMount(){
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch(mode){
            case Mode.DETAIL: 
                addOneBreadcrumbPath({
                    title:'售后详情',
                    path:'/home/salemanage/salelist/contentInfo'
                });
                history.push({
                    pathname: '/home/salemanage/salelist/contentInfo/'
                });
                break;
        }
    }
    
    render(){
        return(
                <Main updateContent={this._updateContent} history = {this.props.history} /> 
        );
    }
}

merchantlist.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(merchantlist);