import { createModel } from '@/store/tools';

const model = {
    namespace: 'previewincomeaudit',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getPreviewIncomeAuditList': T('getPreviewIncomeAuditList'),
            'getPreviewIncomeAuditDetail': T('getPreviewIncomeAuditDetail'),
            'getStateEnum': T('getStateEnum'),
        },
        actions: {
            'getPreviewIncomeAuditList': A('getPreviewIncomeAuditList'),
            'getPreviewIncomeAuditDetail': A('getPreviewIncomeAuditDetail'),
            'getStateEnum': A('getStateEnum'),
        },
        sagas: {
            'getPreviewIncomeAuditList': S('getPreviewIncomeAuditList', '/settle/advances/received/audit/findPage'),
            'getPreviewIncomeAuditDetail': S('getPreviewIncomeAuditDetail', '/settle/advances/received/audit/find'),
            'getStateEnum': S('getStateEnum', '/enum/AdvancesReceivedCertificateStateEnum'),
        },
        reducers: {
            'previewIncomeAuditList': R('getPreviewIncomeAuditList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'previewIncomeAuditDetail': R('getPreviewIncomeAuditDetail', { status: {} }),
            'stateEnum': R('getStateEnum', []),
        }
    })
};
export default createModel(model);