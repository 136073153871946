import * as T from './actiontypes';
/**
 * 营销活动数据源
 */
const defaultState = {
    tableSource: [],//分类列表数据源
    selectData: {},// 状态下拉选项
    paginations: {}, //分页对象
    marketingDetails: {},//营销活动编辑获取新建详情

    RaiseAll: [],//营销活动新建-全选复选
    RaiseSelect: [],//营销活动新建-分类（众筹）选择商品下拉框
    RaiseTable: [],//营销活动新建-分类（众筹）选择商品表格数据
    RaisePagination: {},//营销活动新建-分类（众筹）选择商品表格数据分页

    marketingProduc: [],//营销活动》详情》活动商品列表
    marketingPaginations: {},//营销活动》详情》活动商品列表分页对象
    marketingProductDetails: {},//营销活动》详情》活动商品列表 > 详情
};

/**
 * @param {*} state 
 * @param {*} action 
 */
export const markingactiviesDate = (state = defaultState, action) => {
    let newTableSource, newSelectData, newPaginations, marketingDetails, RaiseAll,
        RaiseSelect, RaiseTable, newRaisePaginations, marketingProduc, marketingPaginations,marketingProductDetails;
    switch (action.type) {
        case T.SET_MARKINGLIST_DATA: //列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_MARKETINGDETAIL_DATA: //营销活动编辑获取新建详情
            marketingDetails = action.payload.result;
            return { ...state, marketingDetails, };
        case T.SET_DROPDIWN_DATA: //营销活动下拉框
            newSelectData = action.payload;
            return { ...state, selectData: newSelectData, };

        case T.SET_RAISEDATA_DATA: //营销活动新建-分类（众筹）选择商品下拉框
            RaiseSelect = action.payload.result.merchantIdAndNames;
            return { ...state, RaiseSelect, };
        case T.SET_ALL_DATA: //营销活动新建-全选复选
            RaiseAll = action.payload.result;
            return { ...state, RaiseAll, };
        case T.SET_RAISETABALE_DATA: //营销活动新建-分类（众筹）选择商品表格数据
            RaiseTable = action.payload.result.dataList;
            newRaisePaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, RaiseTable, RaisePagination: newRaisePaginations };
        case T.SET_MARKINGPRODUCTLIST_DATA: //营销活动》详情》活动商品列表
            marketingProduc = action.payload.result.dataList;
            marketingPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, marketingProduc, marketingPaginations, };
        case T.SET_MARKINGPRODUCTDETAIL_DATA: //营销活动》详情》活动商品列表 > 详情
            marketingProductDetails = action.payload.result;
            return { ...state, marketingProductDetails, };
        default:
            return state;
    }
};