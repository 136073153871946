import React, { Component } from 'react';
import { Table, Tag } from 'antd';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth, tooltipClick, priceFenToYuanCell } from '@/components/TableCell';


export default class Records extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    _columns = () => [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            width: '120px',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, record) => tooltipClick(text, () => {
                if (text == '') return;
                this.props.tableAction('detail', record);
            })
        },
        {
            title: '会员账号',
            dataIndex: 'userTel',
            key: 'userTel',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '爆品券价值',
            dataIndex: 'consignValue',
            key: 'consignValue',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '关联订单编号',
            dataIndex: 'matchOrderId',
            key: 'matchOrderId',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'consignState',
            key: 'consignState',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (key, record)=>(record.consignState.value)
        },
        {
            title: '自提状态',
            dataIndex: 'completedType.value',
            key: 'completedType.value',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '完成时间',
            dataIndex: 'matchOrderFinishedTime',
            key: 'matchOrderFinishedTime',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
    ];

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._columns()}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ x: 'max-content', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

Records.propTypes = {
    renderData: PropTypes.object, // 表格数据源
    paginationChange: PropTypes.func, // 分页
    tableAction: PropTypes.func, // 对表格的操作
};