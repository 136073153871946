/**
 * 活动人气值列表
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getActiveGoods, getMerchants } from '@/reducers/marketingmanage/mall/actions';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            goodsName: '', // 商品名称
            merchant: {}, // 商户
            marketingCode: 'SHOW', // 活动商品类型：STAR星值活动 SHOW秀场活动
        };
        this.ids = []; // 复选框选中的商品ID数组
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        this.props.getMerchants();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { goodsName, merchant, marketingCode, pageNum, pageSize } = this.state;
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
            goodsName,
            merchantId: merchant.merchantId,
            marketingCode
        };
        this.props.getActiveGoods(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置
    _resetHandle = () => {
        this.setState({ goodsName: '', merchant: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getActiveGoods(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum });
    }

    // 表格操作
    _tableAction = (id, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '商品详情',
                    path: '/home/marketingmanage/activeShowMall/detail' + id,
                });
                history.push({
                    pathname: '/home/marketingmanage/activeShowMall/detail/' + id,
                });
                break;
            case 'ban':
                http('/goods/goods/delMarketingGoods', {
                    goodsIds: [id],
                    marketingCode: this.state.marketingCode
                }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('移除成功！');
                        this._searchHandle();
                    }
                }).catch((e = {}) => {
                    message.error(e.message);
                });
                break;
            case 'batchBan':
                this.ids = id;
                break;
        }
    }

    // 批量移除
    _batchBan = () => {
        if (this.ids.length > 0) {
            http('/goods/goods/delMarketingGoods', {
                goodsIds: this.ids,
                marketingCode: this.state.marketingCode
            }, 'POST').then((response) => {
                if (response.status == 200) {
                    message.success('移除成功！');
                    this._searchHandle();
                    this.tableRef.current._cleanAllSelected();
                }
            }).catch((e = {}) => {
                message.error(e.message);
            });
        } else {
            message.error('请勾选商品');
        }
    }

    render() {
        const { goodsName, merchant } = this.state;
        const { activeGoods, merchants } = this.props;
        const { merchantIdAndNames } = merchants;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商品名称'
                                placeholder='请输入机构名称'
                                value={goodsName}
                                bindThis={this}
                                bindName='goodsName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户'
                                placeholder='请选择商户'
                                renderData={merchantIdAndNames}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                value={merchant.merchantName}
                                bindThis={this}
                                bindName='merchant'
                                isOptionBindID={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: 'auto' }} label='批量移除' onClick={this._batchBan} />
                    </div>
                    <TableContent
                        ref={this.tableRef}
                        renderData={activeGoods}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                    />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    getActiveGoods: PropTypes.func, // 获取活动商品列表
    activeGoods: PropTypes.object, // 活动商品列表
    getMerchants: PropTypes.func, // 获取商户下拉列
    merchants: PropTypes.object, // 商户下拉列
};
const mapStateToProps = (state) => ({
    activeGoods: state.mall.activeGoods,
    merchants: state.mall.merchants,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, getActiveGoods, getMerchants })(Main);