/**
 * 签约记录
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, XInput, XDatePicker } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import model from '@/reducers/storehousefinancialmanage/otherpay/model';
import KeepAlive from '@/routes/KeepAlive';
import TableContent from './components/TableContent';
import SignModal from './components/SignModal';
import DetailModal from './components/DetailModal';


class Main extends React.Component {

    state = {
        nameList: '', // 姓名
        idNoList: '', // 身份证
        tdItem: {}, // 签约公司
        dxItem: {}, // 签约通道
        ztItem: {}, // 签约状态
        bankCardList: '', // 银行卡号
        startDate: undefined, // 开始时间
        endDate: undefined, // 结束时间
        showSign: false, // 显示批量签约
        showDetail: false, // 显示签约详情
        param: null, // 签约详情传递的参数
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getYouFuPayOutServiceNameEnum();
        this.props.getYouFuPaySignStateEnum();
        this.props.getYouFuPayMerEnum();
    }

    // 字符串转数组
    _strToArr = (arg) => {
        let arr = [];
        let str = arg;
        if (Array.isArray(str)) {
            if (str.length > 0 && str[0]) {
                arr = str;
            }
        } else if (str) {
            str = str.replace('，', ',');
            arr = str.split(',');
        }
        return arr;
    }

    // 查询
    _searchHandle = (useCache) => {
        const { nameList, idNoList, dxItem, ztItem, tdItem, bankCardList, startDate, endDate, pageNum, pageSize } = this.state;
        const query = {
            nameList: this._strToArr(nameList),
            idNoList: this._strToArr(idNoList),
            serviceId: dxItem.code,
            state: ztItem.code,
            companyId: tdItem.code,
            bankCardList: this._strToArr(bankCardList),
            startDate,
            endDate,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };

        this.props.getListSignPage(query);
        KeepAlive.saveCache({ nameList, idNoList, dxItem, ztItem, tdItem, bankCardList, startDate, endDate, pageNum, pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ nameList: '', idNoList: '', bankCardList: '', dxItem: {}, ztItem: {}, tdItem: {}, startDate: undefined, endDate: undefined, pageNum: autoPageNum, pageSize: autopageSize }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (type, item) => {
        switch (type) {
            case 'detail':
                this.setState({ showDetail: true, param: item });
                break;
            case 'sign':
                this.setState({ showSign: true });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    // 批量签约
    _signHandle = () => {
        this.setState({ showSign: true });
    }

    render() {
        const { nameList, idNoList, dxItem = {}, ztItem = {}, tdItem = {}, bankCardList, startDate, endDate, showSign, showDetail, param } = this.state;
        const { youFuPayOutServiceNameEnum, youFuPaySignStateEnum, youFuPayMerEnum, listSignPage } = this.props;
        return (
            <KeepAlive id='sign' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='姓名'
                                placeholder='最多输入20个姓名，用逗号隔开'
                                value={nameList}
                                bindThis={this}
                                bindName='nameList'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='身份证'
                                placeholder='最多输入20个身份证，用逗号隔开'
                                value={idNoList}
                                bindThis={this}
                                bindName='idNoList'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='签约公司'
                                showSearch
                                placeholder='请选择'
                                renderData={youFuPayMerEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={tdItem.value}
                                bindThis={this}
                                bindName='tdItem'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='签约通道'
                                showSearch
                                placeholder='请选择'
                                renderData={youFuPayOutServiceNameEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={dxItem.value}
                                bindThis={this}
                                bindName='dxItem'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='签约状态'
                                showSearch
                                placeholder='请选择'
                                renderData={youFuPaySignStateEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={ztItem.value}
                                bindThis={this}
                                bindName='ztItem'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='银行卡号'
                                placeholder='最多输入20个银行卡号，用逗号隔开'
                                value={bankCardList}
                                bindThis={this}
                                bindName='bankCardList'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                placeholder='请选择开始时间'
                                label='签约日期'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                placeholder='请选择结束时间'
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton style={{ marginLeft: '20px' }} label='批量上传' onClick={this._signHandle} />
                        </SearchItem>
                    </SearchBox>
                    <TableContent renderData={listSignPage} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {showSign ? <SignModal close={(type) => {
                        if (type == 'update') {
                            this._searchHandle('useCache');
                        }
                        this.setState({ showSign: false });
                    }} /> : null}
                    {showDetail ? <DetailModal param={param} close={() => this.setState({ showDetail: false })} /> : null}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getYouFuPayOutServiceNameEnum: PropTypes.func, // 枚举：签约通道
    youFuPayOutServiceNameEnum: PropTypes.array,
    getYouFuPaySignStateEnum: PropTypes.func, // 枚举：签约状态
    youFuPaySignStateEnum: PropTypes.array,
    getYouFuPayMerEnum: PropTypes.func, // 枚举：签约公司
    youFuPayMerEnum: PropTypes.array,
    getListSignPage: PropTypes.func, // 列表：签约记录
    listSignPage: PropTypes.object,
};

const mapStateToProps = (state) => ({
    youFuPayOutServiceNameEnum: state.otherpay.getYouFuPayOutServiceNameEnum,
    youFuPaySignStateEnum: state.otherpay.getYouFuPaySignStateEnum,
    youFuPayMerEnum: state.otherpay.getYouFuPayMerEnum,
    listSignPage: state.otherpay.getListSignPage,
});

export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);