/**
 * 人气值兑换魅力值记录
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import ConversionTable from './components/ConversionTable';
import { XInput, XDatePicker, XOKButton, XCancelButton, XInputNum } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/usermanage/recordconversion/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderNumber: '',//订单号
            accountNumber: '',//会员账号
            startTime: null,//开始时间
            endTime: null,//结束时间
        };
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, orderNumber, accountNumber, startTime, endTime } = this.state;
        this.searchCache = {
            recordId: orderNumber,
            subjectPhone: accountNumber,
            gmtCreateStart: startTime != null ? startTime.format('YYYY-MM-DD HH:mm:ss') : null, //开始时间
            gmtCreateEnd: endTime != null ? endTime.format('YYYY-MM-DD HH:mm:ss') : null, // 结束时间
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getConversionList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ orderNumber: '', accountNumber: '', startTime: null, endTime: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getConversionList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { orderNumber, accountNumber, startTime, endTime, } = this.state;
        const { tableList } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={`${styles.flexBoxContainer} ${styles.container}`}>
                    <SearchBox>
                        <SearchItem>
                            <XInputNum
                                label='订单号'
                                placeholder='请输入16位订单号'
                                value={orderNumber}
                                bindThis={this}
                                bindName='orderNumber'
                                precision={0}
                                min={0}
                                maxLength={16}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='会员账号'
                                placeholder='请输入会员账号'
                                value={accountNumber}
                                bindThis={this}
                                bindName='accountNumber'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='购买日期'
                                value={startTime}
                                bindThis={this}
                                bindName='startTime'
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                label=''
                                value={endTime}
                                bindThis={this}
                                bindName='endTime'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <ConversionTable renderData={tableList} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive >
        );
    }
}


Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象

    tableList: PropTypes.object,
    getConversionList: PropTypes.func,//表格数据(action)
};

const mapStateToProps = (state) => ({
    tableList: state.recordconversion.conversionList, // 表格数据
});

export default connect(mapStateToProps, { ...model.actions })(Main);
