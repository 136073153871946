/**
 *  区域订单销售统计
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './main';

const CompanyOrder = ({ history }) => {
    return <Main history={history} />;
};

CompanyOrder.propTypes = {
    history: PropTypes.object
};

export default CompanyOrder;