import { createModel } from '@/store/tools';

const model = {
    namespace: 'salesman',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getStockBuyerAuditTypeEnum': T('getStockBuyerAuditTypeEnum'),
            'getStockBuyerInfoList': T('getStockBuyerInfoList'),
        },
        actions: {
            'getStockBuyerAuditTypeEnum': A('getStockBuyerAuditTypeEnum'),
            'getStockBuyerInfoList': A('getStockBuyerInfoList'),
        },
        sagas: {
            'getStockBuyerAuditTypeEnum': S('getStockBuyerAuditTypeEnum', '/enum/StockBuyerAuditTypeEnum'), // 枚举：业务员状态
            'getStockBuyerInfoList': S('getStockBuyerInfoList', '/settle/stock/getStockBuyerInfoList'), // 列表：业务员列表
        },
        reducers: {
            'getStockBuyerAuditTypeEnum': R('getStockBuyerAuditTypeEnum', []),
            'getStockBuyerInfoList': R('getStockBuyerInfoList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);