import * as Audit from './actiontypes';

/**
 * 获取'主播封面审核表格'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 */
export const getAnchorCoverList = (params = {}) => ({
    type: Audit.GET_ANCHORCOVER_LIST,
    payload: params
});
export const getAnchorCoverSelect = () => ({
    type: Audit.GET_ANCHORCOVER_SELECT,
    payload: {}
});