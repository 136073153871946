/**
 * 确认对账单 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, Input, Form, Radio, message } from 'antd';
import styles from './ConfirBill.module.scss';

const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

class NoLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
        };
    }

    // 点击确认
    _handleOk = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                http('/reconciliation/confirmReconciliation', { reconciliationId: this.props.data.id, ...fieldsValue }).then(res => {
                    this.props.closeMode(true);
                }).catch(() => {
                    this.props.closeMode();
                    message.error('请求异常');
                });
            }
        });

    };

    // 点击取消
    _handleCancel = () => {
        this.setState({ visible: false });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={800}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div style={{ textAlign: 'left', paddingLeft: 54, color: 'rgba(0, 0, 0, 0.85)' }}>对账单号：{this.props.data.id}</div>
                    <div style={{ textAlign: 'left', paddingLeft: 54, color: 'rgba(0, 0, 0, 0.85)', marginTop: 8 }}>对账金额：{this.props.data.finalAmount}</div>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon
                            htmlFor='toStatus'
                            label={<span>对账确认</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('toStatus', {
                                initialValue: 'confirm_success'
                            })(
                                <Radio.Group>
                                    <Radio value="confirm_success">通过</Radio>
                                    <Radio value="confirm_fail">不通过</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='comment'
                            label={<span>对账说明</span>}
                        >
                            {getFieldDecorator('comment', {
                            })(
                                <TextArea rows={4} />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

NoLogin.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    getListAreaByParentId: PropTypes.func, // 请求地区信息
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(NoLogin);// 校验
export default connect(null, null)(ForgotProducDetails);