import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

//会员等级设置列表
export const getMemberGradeListMlx = function* () {
    yield takeEvery(T.GET_MEMBERGRADE_LIST, function* requestData(action) {
        try {
            let result = yield http('/mlx/user/level/listMlxAccountLevel', action.payload); // action.payload为传入的参数对象
            yield put({ type: T.SET_MEMBERGRADE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//会员等级设置详情
export const getMemberGradeDetailMlx = function* () {
    yield takeEvery(T.GET_MEMBERGRADE_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/mlx/user/level/getMlxAccountLevelInfo', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: T.SET_MEMBERGRADE_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//会员等级设置优惠券下拉
export const getMemberGradeCouponSeleMlx = function* () {
    yield takeEvery(T.GET_MEMBERGRADE_COUPONSELE, function* requestData(action) {
        try {
            let result = yield http('/coupon/coupon/couponAvailableListByName', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: T.SET_MEMBERGRADE_COUPONSELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//会员等级设置单选框
export const getMemberGradeRadioMlx = function* () {
    yield takeEvery(T.GET_MEMBERGRADE_RADIO, function* requestData(action) {
        try {
            let grow = yield http('/admin/param/getParamValueByCode/MLX_LEVEL_ADD_SOURCE', {}, 'POST'); // action.payload为传入的参数对象
            let grade = yield http('/admin/param/getParamValueByCode/LEVEL_PERIOD', {}, 'POST'); // action.payload为传入的参数对象
            let charm = yield http('/admin/param/getParamValueByCode/IS_OPEN_CHARM_LEVEL', {}, 'POST'); // action.payload为传入的参数对象
            yield put({ type: T.SET_MEMBERGRADE_RADIO, payload: { growValue:grow.result, gradeValue:grade.result, charmValue:charm.result } });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};