/**
 * 【商品订单页】
 */
import React from 'react';
import { XTabNav } from '@/components/xqxc_ui';
import OrderSearch from './components/OrderSearch';
import Export from './components/Export';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import LogisticsModal from '../../modal/LogisticsModal';
import LeaveMsg from '../../modal/LeaveMsg';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getProductOrderList } from '@/reducers/ordermanage/productOrder/actions';
import { getLocalStorage, removeLocalStorage } from '@/assets/js/storage';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { XOKButton } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import Chargeback from '../../modal/Chargeback';
import ButtonPerssion from '@/routes/ButtonPerssion';

class Main extends React.Component {
    state = {
        tab: [
            { id: 1, label: '全部订单', code: 'ordermanage/productorder/tabqbdd' },
            { id: 2, label: '待付款', code: 'ordermanage/productorder/tabdfk' },
            { id: 3, label: '待收货', code: 'ordermanage/productorder/tabdsk' },
            { id: 4, label: '已完成', code: 'ordermanage/productorder/tabywc' },
            { id: 5, label: '已取消', code: 'ordermanage/productorder/tabyqx' },
        ], // tab配置
        showTab: [], // 显示的tab
        curTabID: 1,  // 当前显示的Tab页ID
        renderMode: '', // 是否显示弹框（查看物流）
        modeParams: {}, // 弹框入参
        orderID: '', // 当前操作的订单ID 
        pageNum: 1,
        pageSize: 50,
        searchData: {},
        chargebackTitle: '退单', autoRefundTime: '0小时', totalFee: 0
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this._getShowTab(); // Tab菜单权限管理
        if (getLocalStorage('platIncomeQuery')) {
            this._tabChangeHandle(4);
        }
    }

    _getShowTab = () => {
        const _that = this;
        let btnPremission = JSON.parse(getLocalStorage('btnPremission')) || [];
        if (btnPremission.length == 0) {
            setTimeout(() => { _that._getShowTab(); }, 500);
            return;
        }
        const { tab } = this.state;
        let showTab = [];
        for (let i = 0; i < btnPremission.length; i++) {
            const code = btnPremission[i];
            for (let m = 0; m < tab.length; m++) {
                if (tab[m].code == code) {
                    showTab.push(tab[m]);
                    break;
                }
            }
        }
        this.setState({ showTab });
    }

    // 表格操作
    _tableAction = (key, type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'cost':
                addOneBreadcrumbPath({
                    title: '费用明细',
                    path: `/home/ordermanage/productorder/costDetail/${record.orderId}`
                });
                history.push({
                    pathname: `/home/ordermanage/productorder/costDetail/${record.orderId}`,
                });
                break;
            // 查看详情
            case 'info':
                if (record.consumeType.includes('同城')) {
                    addOneBreadcrumbPath({
                        title: '查看详情',
                        path: {
                            pathname: '/home/ordermanage/cityorder/orderdetail',
                            search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                        }
                    });
                    history.push({
                        pathname: '/home/ordermanage/cityorder/orderdetail',
                        search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                    });
                } else {
                    if (record.isDistribution == '是') {
                        addOneBreadcrumbPath({
                            title: '查看详情',
                            path: {
                                pathname: '/home/ordermanage/platformorder/orderdetail',
                                search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                            }
                        });
                        history.push({
                            pathname: '/home/ordermanage/platformorder/orderdetail',
                            search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                        });
                    } else {
                        addOneBreadcrumbPath({
                            title: '查看详情',
                            path: {
                                pathname: '/home/ordermanage/productorder/orderdetail',
                                search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                            }
                        });
                        history.push({
                            pathname: '/home/ordermanage/productorder/orderdetail',
                            search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                        });
                    }
                }
                break;
            // 查看物流
            case 'logistics':
                this.setState({ renderMode: 'logistics', orderID: key });
                break;
            // 联系商户
            case 'business':
                addOneBreadcrumbPath({
                    title: '新建工单',
                    path: '/home/ordermanage/productorder/workorder/'
                });
                history.push({
                    pathname: '/home/ordermanage/productorder/workorder/'
                });
                break;
            // 操作记录
            case 'history':
                addOneBreadcrumbPath({
                    title: '操作记录',
                    path: {
                        pathname: '/home/ordermanage/productorder/orderhistory',
                        search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                    }
                });
                history.push({
                    pathname: '/home/ordermanage/productorder/orderhistory',
                    search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                });
                break;
            case 'leaveMsg':
                this.setState({ renderMode: 'leaveMsg', modeParams: record });
                break;
            case 'chargeback':
                this.setState({ renderMode: 'Chargeback', orderID: key, chargebackTitle: '退单', autoRefundTime: record });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const { searchData } = this.state;
        const reqParams = { ...searchData, pageNum, pageSize };
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.setState({ pageSize, pageNum, reqParams });
        this.props.getProductOrderList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 打开查看物流弹框
    _renderMode = () => {
        const { renderMode, modeParams, chargebackTitle, orderID, autoRefundTime } = this.state;
        switch (renderMode) {
            case 'logistics':
                return <LogisticsModal visible={true} data={this.state.orderID} closeMode={this._closeMode} />;
            case 'leaveMsg':
                return <LeaveMsg visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
            case 'Chargeback':
                return <Chargeback title={chargebackTitle} autoRefundTime={autoRefundTime} orderId={orderID} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭查看物流弹框
    _closeMode = (update) => {
        this.setState({ renderMode: '', orderID: '', chargebackTitle: '退单' }, () => {
            if (update === 'update') this._searchHandle('useCache');
        });
    }

    _searchHandle = (useCache) => {
        const { searchData, pageNum, pageSize } = this.state;
        this.searchCache = {
            ...searchData,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        http('/boss/order/getOrderTotalFee', { ...this.searchCache }, 'POST')
            .then(res => this.setState({ totalFee: res.result }));
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getProductOrderList(this.searchCache);
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        const { searchData, param } = this.state;
        this.setState({ curTabID: tabID });
        const platIncomeQuery = getLocalStorage('platIncomeQuery') && JSON.parse(getLocalStorage('platIncomeQuery'));
        let params = {};
        switch (tabID) {
            case 1:
                this.setState({
                    curTabIDStatus: [],
                    pageNum: 1,
                    searchData: {
                        ...searchData,
                        statusList: [],
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 2:
                this.setState({
                    curTabIDStatus: ['PAYING'],
                    pageNum: 1,
                    searchData: {
                        ...searchData,
                        statusList: ['PAYING'],
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 3:
                this.setState({
                    curTabIDStatus: ['PAIED', 'PART_GOODS_SENT', 'GOODS_SENT'],
                    pageNum: 1,
                    searchData: {
                        ...searchData,
                        statusList: ['PAIED', 'PART_GOODS_SENT', 'GOODS_SENT'],
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 4:
                if (platIncomeQuery) {
                    if (platIncomeQuery.code === 'VIP_BENEFITS') params.consumeType = { code: 'VIP_BENEFITS', value: '新人专区' };
                    if (platIncomeQuery.code === 'VIP_EXCLUSIVE') params.consumeType = { code: 'VIP_EXCLUSIVE', value: '会员尊享' };
                    params.finishedTimeF = platIncomeQuery.finishedTimeF;
                    params.finishedTimeT = platIncomeQuery.finishedTimeT;
                    removeLocalStorage('platIncomeQuery');
                }
                this.setState({
                    curTabIDStatus: ['FINISHED', 'GOODS_CONFIRMED'],
                    pageNum: 1,
                    searchData: {
                        ...searchData,
                        consumeType: params.consumeType ? params.consumeType.code : '',
                        finishedTimeF: params.finishedTimeF ? params.finishedTimeF : '',
                        finishedTimeT: params.finishedTimeT ? params.finishedTimeT : '',
                        statusList: ['FINISHED', 'GOODS_CONFIRMED'],
                    },
                    param: params.consumeType ? params : this.state.param
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 5:
                this.setState({
                    curTabIDStatus: ['CANCELLED'],
                    pageNum: 1,
                    searchData: {
                        ...searchData,
                        statusList: ['CANCELLED'],
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
        }
    }

    _searchOrder = (searchData, param, flag) => {
        const { pageNum, pageSize, curTabID, curTabIDStatus } = this.state;
        let reqParams = {};
        if (curTabID === 1) {
            reqParams = { ...searchData, pageNum: 1, pageSize };
        } else {
            reqParams = { ...searchData, pageNum: 1, pageSize, statusList: curTabIDStatus };
        }
        this.setState({ searchData: reqParams, reqParams, param, pageNum: 1 }, () => {
            if (flag) {
                // 重置
                KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
            } else {
                this._searchHandle('useCache');
            }
        });
    }

    render() {
        const { productOrderList } = this.props;
        const { curTabID, reqParams, totalFee } = this.state;
        // 全部订单 ordermanage/productorder/tabqbdd
        // 待付款 ordermanage/productorder/tabdfk
        // 待收货 ordermanage/productorder/tabdsk
        // 已完成 ordermanage/productorder/tabywc
        // 已取消 ordermanage/productorder/tabyqx

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    {
                        this.state.showTab && this.state.showTab.length > 0 ? <>
                            <XTabNav renderData={this.state.showTab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                            <OrderSearch totalFee={totalFee} searchState={this.state} orderState={this.state.curTabID} searchOrder={this._searchOrder} />
                            {/* <Export reqParams={reqParams} /> */}
                            <ButtonPerssion code='ordermanage/productorder/td'>
                                <div style={{ display: 'flex', marginTop: '5px' }}>
                                    <XOKButton label='批量退单' style={{ width: '100px' }} onClick={() => this.setState({
                                        renderMode: 'Chargeback', chargebackTitle: '批量退单',
                                        autoRefundTime: (productOrderList.dataList || [])[0].autoRefundTime
                                    })} />
                                </div>
                            </ButtonPerssion>
                            <OrderTable renderData={productOrderList} tableAction={this._tableAction} paginationChange={this._paginationChange} curTabID={curTabID} />
                            {this._renderMode()}
                        </> : '您没有该页面权限，请联系管理员'
                    }
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getProductOrderList: PropTypes.func,
    productOrderList: PropTypes.object
};

const mapStateToProps = (state) => ({
    productOrderList: state.productOrder.productOrderList,
});

export default connect(mapStateToProps, { getProductOrderList, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);
