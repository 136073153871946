/**
 * 驳回原因 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import model from '@/reducers/storehousefinancialmanage/paybill/model';
import { Modal, Input, Alert, Form, Select, message, Table, Checkbox } from 'antd';
import styles from './index.module.scss';

const { TextArea } = Input;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
    // labelAlign: 'left',
};
class NoLogin extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
    }

    componentDidMount() {
    }

    // 点击确认
    _handleOk = () => {
        const { closeMode } = this.props;
        let url = '/settle/taxcommissionrecord/taxCommissionAuditReject';
        this.props.form.validateFields((errors, values) => {
            if (!errors) {
                this.setState({ confirmLoading: true });
                http(url, { ...values, rejectType: values.rejectType.join(','), ids: this.props.data }, 'POST').then((res) => {
                    message.success(res.message);
                    closeMode(true);
                    // this.props.refsh();
                }).catch((res = {}) => {
                    message.error('驳回失败，原因：' + res.message);
                }).finally(() => this.setState({ visible: false, confirmLoading: false }));
                this.setState({ visible: false });
            }
        });
        
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={600}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon
                            htmlFor='rejectType'
                            label={<span>需要重新提交材料</span>}
                            style={{ textAlign: 'left', marginBottom: 0 }}
                        >
                            {getFieldDecorator('rejectType', {
                                rules: [{ required: true, message: '请选择需要重新提交材料' }],
                            })(
                                <Checkbox.Group
                                    options={[{label: '委托书', value: 'FUZZY_SIGNATURE'}, {label: '身份证', value: 'ID_CARD'}]}
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='auditOpinion'
                            label={<span>驳回原因</span>}
                            style={{ textAlign: 'left', marginBottom: 0 }}
                        >
                            {getFieldDecorator('auditOpinion', {
                                rules: [{ required: true, message: '请输入驳回原因' },
                                { max: 150, message: '长度150' }
                                ],
                            })(
                                <TextArea rows={4} />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

NoLogin.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]), // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    getApplyInvoiceInfo: PropTypes.func,
    ApplyInvoiceInfo: PropTypes.object,
    refsh: PropTypes.func,
};
const mapStateToProps = (state) => ({
    ApplyInvoiceInfo: state.paybill.ApplyInvoiceInfo
});
const ForgotProducDetails = Form.create({ name: 'create-form' })(NoLogin);// 校验
export default connect(mapStateToProps, { ...model.actions })(ForgotProducDetails);