import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, message } from 'antd';
import PreviewModal from '@/vcomps/CustomPreview';
import CreateModal from '../modal/CreateModal';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, RadioGroup, XOKButton, XCancelButton, XPagination, showConfirm, XTabNav, XTableSelectionCount } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/systemmanage/template/model';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class templateClassify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],//选中行
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: null, // 当前需要打开的弹窗的入参
            tab: [
                { id: 0, label: '全部' },
            ],
            curTabID: 0,
            rowKey: 1,
        };
    }

    componentDidMount() {
        keepSecondNavBreadcrumb();
        this.props.getlistCustomTemplatePullDownClass();
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { curTabID, templateName, pageNum, pageSize } = this.state;
        this.searchCache = {
            customTemplateClassId: curTabID == 0 ? null : curTabID,
            templateName,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getlistCustomTemplate(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发 
    _resetHandle = () => {
        this.setState({ templateName: '', pageSize: 10, pageNum: 1 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作功能回调 
    _actionClickHandle = (record, type, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'edit'://编辑
                addOneBreadcrumbPath({
                    title: 'APP自定义',
                    path: {
                        pathname: '/home/marketingmanage/appcustom/customlanding/',
                        state: {
                            category: 'PRESETTEMPLATE',
                            mode: 'control',
                            id: record.id
                        }
                    }
                });
                this.props.history.push({
                    pathname: '/home/marketingmanage/appcustom/customlanding/',
                    state: {
                        category: 'PRESETTEMPLATE',
                        mode: 'control',
                        id: record.id
                    }
                });
                break;
            case 'preview':
                this.setState({ currentOpenMode: 'preview', openModeParam: record });
                break;
            case 'editTemplateName': // 修改模板名称
                http('/admin/customtemplate/updateCustomTemplate', { id: record.id, templateName: value }, 'POST')
                    .then(() => {
                        this._searchHandle('useCache');
                        message.success('更新成功。');
                    }).catch((reject = {}) => {
                        message.error(reject.message);
                    });
                break;
            case 'delete'://删除
                showConfirm('确定要删除此选中的信息吗？', '删除后无法恢复，您是否确认删除？', () => {
                    http(`/admin/customtemplate/delCustomTemplate/${record.id}`, {}, 'POST')
                        .then(() => {
                            this._searchHandle('useCache');
                            message.success('删除成功。');
                        }).catch((reject = {}) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'start':
                showConfirm('确定要启用此选中的信息吗？', '', () => {
                    http('/admin/customtemplate/updateCustomTemplateState', {
                        id: record.id,
                        state: 1,
                    }, 'POST')
                        .then(() => {
                            this._searchHandle('useCache');
                            message.success('启用成功。');
                        }).catch((reject = {}) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'stop':
                showConfirm('确定要禁用此选中的信息吗？', '', () => {
                    http('/admin/customtemplate/updateCustomTemplateState', {
                        id: record.id,
                        state: 0,
                    }, 'POST')
                        .then(() => {
                            this._searchHandle('useCache');
                            message.success('禁用成功。');
                        }).catch((reject = {}) => {
                            message.error(reject.message);
                        });
                });
                break;
            default: return null;
        }
    }
    // 切换分类
    _tabChangeHandle = e => {
        this.setState({ curTabID: e.target.value }, () => {
            this._searchHandle('useCache');
        });
    }
    // 批量启用
    startHandle = () => {
        const { selectedRowKeys } = this.state;
        if (selectedRowKeys.length == 0) {
            message.warn('您还未选择模板，请选择后再操作!');
            return false;
        }
        showConfirm('确定要启用此选中的信息吗？', `您是否启用选中的${selectedRowKeys.length}条模板`, () => {
            http('/admin/customtemplate/batchUpdateCustomTemplateState', {
                ids: selectedRowKeys.join(','),
                state: 1,
            }, 'POST')
                .then(() => {
                    this.setState({ selectedRowKeys: [] }, () => {
                        this._searchHandle('useCache');
                    });
                    message.success('启用成功。');
                }).catch((reject = {}) => {
                    message.error(reject.message);
                });
        });

    }
    // 批量禁用
    stopHandle = () => {
        const { selectedRowKeys } = this.state;
        if (selectedRowKeys.length == 0) {
            message.warn('您还未选择模板，请选择后再操作!');
            return false;
        }
        showConfirm('确定要禁用此选中的信息吗？', `您是否禁用选中的${selectedRowKeys.length}条模板`, () => {
            http('/admin/customtemplate/batchUpdateCustomTemplateState', {
                ids: selectedRowKeys.join(','),
                state: 0,
            }, 'POST')
                .then(() => {
                    this.setState({ selectedRowKeys: [] }, () => {
                        this._searchHandle('useCache');
                    });
                    message.success('禁用成功。');
                }).catch((reject = {}) => {
                    message.error(reject.message);
                });
        });
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { currentOpenMode, openModeParam } = this.state;
        switch (currentOpenMode) {
            case 'create':
                return <CreateModal history={this.props.history} refsh={() => this._searchHandle('useCache')} visible={true} title='新建模板' closeMode={this._closeMode} />;
            case 'preview': // 预览
                return <PreviewModal
                    id={openModeParam.id}
                    category='PRESETTEMPLATE'
                    modalProps={{
                        visible: true,
                        onCancel: () => this.setState({ currentOpenMode: undefined, openModeParam: undefined })
                    }} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentOpenMode: '', openModeParam: {} });
    }
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    render() {
        const { listCustomTemplatePullDownClass } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.listCustomTemplate;
        const { templateName, selectedRowKeys } = this.state;
        // 表格复选输入
        const _rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys, selectedRows });
            }
        };
        _rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <KeepAlive id='11' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <RadioGroup
                        customTemplateClassId={this.state.curTabID}
                        dataSource={listCustomTemplatePullDownClass}
                        onClassChange={this._tabChangeHandle}
                    />
                    <div className={styles.search_box}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='模板名称'
                                    placeholder='请输入'
                                    bindThis={this}
                                    bindName='templateName'
                                    value={templateName}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                   label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px', marginRight: 10 }} label='批量启用' onClick={() => this.startHandle()} />
                        <XOKButton style={{ width: '120px', marginRight: 10 }} label='批量禁用' onClick={() => this.stopHandle()} />
                        <XOKButton style={{ width: '120px' }} label='新建模板' onClick={() => {
                            this.setState({ currentOpenMode: 'create' });
                        }} />
                    </div>
                    <div className={`${styles.tableContainer}`}>
                        <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record) => record.id}
                            rowSelection={_rowSelection}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    <div>{this._renderMode()}</div>
                </div>
            </KeepAlive>
        );
    }
}

templateClassify.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息
    listCustomTemplatePullDownClass: PropTypes.array,
    getlistCustomTemplatePullDownClass: PropTypes.func, //模板分类
    getlistCustomTemplate: PropTypes.func,
    listCustomTemplate: PropTypes.object,
};

const mapStateToProps = (state) => ({
    listCustomTemplatePullDownClass: state.templatemanage.listCustomTemplatePullDownClass, // 模板分类
    listCustomTemplate: state.templatemanage.listCustomTemplate, // 模板列表
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(templateClassify);
