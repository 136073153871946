import React from 'react';
import styles from './ChooseCreate.module.scss';
import PropTypes from 'prop-types';
import { Table, Tooltip } from 'antd';
import { XPagination, XTableSelectionCount, } from '@/components/xqxc_ui';

const columns = (actionClickHandle) => ([
    {
        title: '商户编号',
        dataIndex: 'merchantNo',
        key: 'merchantNo',
        width: '20%',
        align: 'center',
    },
    {
        title: '商户名称',
        dataIndex: 'merchantName',
        key: 'merchantName ',
        width: '30%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
    },
    {
        title: '商户类型',
        dataIndex: 'merchantType',
        key: 'merchantType',
        align: 'center',
        width: '20%',
        render: (text) => (TypeCell(text))
    },
    {
        title: '商户地址',
        dataIndex: 'addrDetail',
        key: 'addrDetail',
        align: 'center',
        width: '30%',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
    },
]);

const TypeCell = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text.value}
        </div>
    );
};

class RaisingCreate extends React.Component {

    state = {
        selectedRowKeys: [],
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data.dataList;
    }

    // 表格复选框操作回调
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.props.tableAction(selectedRowKeys, selectedRows);
        }
    };

    // 操作表格
    _actionHandle = (id, type, value) => {
        this.props.tableAction(id, type, value);
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
            this._actionHandle(null, 'batch', selectedRowKeys);
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
        this._actionHandle(null, 'batch', []);
    }


    render() {
        const { renderData, paginationChange } = this.props;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;

        return (
            <div className={styles.container}>
                <div className={styles.table_box}>
                    <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        rowSelection={this._rowSelection}
                        rowKey={record => record.id}
                        columns={columns(this._actionHandle)}
                        dataSource={this._takeDataSource(renderData)}
                        pagination={false}
                        scroll={{ y: 350 }}
                    />
                </div>
                <div className={styles.pagination_box}>
                    <XPagination
                        resultTotal={renderData.resultTotal}
                        pageSize={renderData.pageSize}
                        pageNum={renderData.pageNum}
                        onChange={paginationChange} />
                </div>
            </div>
        );
    }
}

RaisingCreate.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

export default RaisingCreate;