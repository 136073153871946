/**
 * 设置特权值比例
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './modalStyle.module.scss';
import http from '@/assets/api/http';
import { Modal, Input, InputNumber, message } from 'antd';

const { TextArea } = Input;

const SetPrivilegeRate = ({
    closeMode,
    openModeParam: {
        privilegaRate = 0,
        id
    } = {}
}) => {

    const [loading, setLoading] = useState(false);
    const [integralRate, setIntegralRate] = useState(null);
    const [remark, setRemark] = useState('');

    const onsubmit = () => {
        if (integralRate === null || integralRate === undefined || integralRate === '') return message.warn('请设置比例');
        if (integralRate === 0) return message.warn('设置比例不能为零');
        if (remark === '') return message.warn('请输入备注');
        setLoading(true);
        http('/merchant/info/updateIntegralRate', { integralRate, remark, id }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            }).catch((e = {}) => {
                message.error(e.message);
                setLoading(false);
            });
    };

    return <Modal
        width={460}
        centered
        bodyStyle={{ paddingBottom: '10px' }}
        title='设置特权值比例'
        visible={true}
        confirmLoading={loading}
        onOk={onsubmit}
        onCancel={() => closeMode()}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.label}>当前特权值与商品价格比例：</div>
                <div className={styles.content}>{privilegaRate ? `${privilegaRate} : 1` : '未设置'}（特权值 : 商品价格）</div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>设置比例：</div>
                <div className={styles.content}>
                    <InputNumber
                        style={{ marginRight: '10px', width: '120px' }}
                        placeholder='请输入特权值'
                        precision={2}
                        min={0}
                        max={99999}
                        value={integralRate}
                        onChange={value => setIntegralRate(value)}
                    />: 1元（特权值 : 商品价格）
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>备注：</div>
                <div className={styles.content}>
                    <TextArea
                        placeholder='请输入备注'
                        style={{ width: '200px' }}
                        rows={4}
                        onChange={({ target: { value = '' } = {} }) => setRemark(value)}
                        value={remark}
                    />
                </div>
            </div>
        </div>
    </Modal>;
};

SetPrivilegeRate.propTypes = {
    closeMode: PropTypes.func.isRequired,
    openModeParam: PropTypes.object
};

export default SetPrivilegeRate;