/**
 * 费用名称
 */
import React from 'react';
import { Modal, Icon, message } from 'antd';
import { XInput, XTextArea } from '@/components/xqxc_ui';
import CustomUpload, { generateFileList } from '@/components/NewUpload';
import PropTypes from 'prop-types';
import styles from './ChooseModal.module.scss';
import http from '@/assets/api/http';

class ChooseModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            type: this.props.type,
            confirmLoading: false,
            costName: '',// 费用名称
            costCode: '',// 费用代码
            costUrl: [],// 图标
            remark: '',// 备注说明
        };
    }

    componentDidMount() {
        const { data } = this.props;
        const img = generateFileList(data.costUrl);
        this.setState({
            costName: data.costName,
            costCode: data.costCode,
            remark: data.remark,
            costUrl: img
        });
    }

    _handleOk = () => {
        const { costName, costCode, costUrl, remark } = this.state;
        const { data } = this.props;
        let key = undefined;
        if (costUrl.length > 0) {
            key = costUrl[0].response ? costUrl[0].response.key : costUrl[0].url;
        }

        http('costSetting/update', { id: data.id, costName, costCode, remark, costUrl: key }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('更新成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        });

    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, title, confirmLoading, costName, costCode, costUrl, remark, type } = this.state;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                onOk={this._handleOk}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '408px', marginBottom: '30px' }}
                        inputStyle={{ width: '340px' }}
                        label='费用编码'
                        placeholder='请输入费用编码'
                        value={costCode}
                        bindThis={this}
                        bindName='costCode'
                        isRequired={true}
                        disabled={type != 'edit' ? false : true}
                    />
                    <XInput
                        style={{ width: '408px' }}
                        inputStyle={{ width: '340px' }}
                        label='费用名称'
                        placeholder='请输入费用名称'
                        value={costName}
                        bindThis={this}
                        bindName='costName'
                        isRequired={true}
                        disabled={type != 'edit' ? false : true}
                    />
                    <XTextArea
                        style={{ width: '382px', marginLeft: '32px', marginTop: '30px' }}
                        inputStyle={{ width: '340px', resize: 'none' }}
                        label='备注'
                        placeholder='请输入备注'
                        value={remark}
                        bindThis={this}
                        bindName='remark'
                    />
                    <div className={styles.upload}>
                        <div className={styles.label}>上传图片</div>
                        <div className={styles.icon}>
                            <CustomUpload
                                accept='.png, .jpg, .jpeg'
                                listType="picture-card"
                                wallMode="single"
                                fileList={costUrl}
                                qiniuUploadProps={{
                                    uploadType: 'image'
                                }}
                                onChange={(info) => {
                                    this.setState({ costUrl: info.fileList });
                                }}
                            >
                                <div>
                                    <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                    <div style={{ color: '#999999', fontSize: '12px' }}>上传图片</div>
                                </div>
                            </CustomUpload>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

ChooseModal.propTypes = {
    title: PropTypes.string,// 弹框标题
    data: PropTypes.object, // 需要修改魅力值的会员对象数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    type: PropTypes.string, // 弹框类型
};

export default ChooseModal;
