import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/starvalueshop/merchantDrawApply/model';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';
import { SearchBox, SearchItem } from '@/components/Layout';
import { Mode } from '../_utils';

class Main extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参

        merchant: {},
        state: {},
        startDate: undefined,
        endDate: undefined,
    }
    goodsCategory = React.createRef();

    componentDidMount() {
        this.props.getStateList();
        this.props.getMerchantList();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, state, startDate, endDate, merchant } = this.state;
        this.searchCache = {
            state: state.code,
            merchantId: merchant.merchantId,
            startDate,
            endDate,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getApplyList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ startDate: undefined, endDate: undefined, state: {}, merchant: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (key, type, params) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case Mode.Detail:
                addOneBreadcrumbPath({
                    title: '商户支取申请详情',
                    path: '/home/showbeanmanage/merchantDrawApply/detail' + key
                });
                history.push({
                    pathname: '/home/showbeanmanage/merchantDrawApply/detail/' + key
                });
                break;
        }
    }
    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getApplyList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { applyList, stateList, merchantList } = this.props;
        const { merchant, state, startDate, endDate } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XSelect
                                style={{ marginRight: '20px' }}
                                selectStyle={{ width: '150px' }}
                                label='商户名称'
                                placeholder='请选择'
                                renderData={merchantList.merchantIdAndNames}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                bindThis={this}
                                bindName='merchant'
                                value={merchant.merchantName}
                                showSearch={true}
                                isOptionBindID={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='申请日期'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat={true}
                            />
                            <div className={styles.timeLine} />
                            <XDatePicker
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                style={{ marginLeft: '20px' }}
                                selectStyle={{ width: '150px' }}
                                label='状态'
                                placeholder='请选择'
                                renderData={stateList}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='state'
                                value={state.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent paginationChange={this._paginationChange} renderData={applyList} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }

}

Main.propTypes = {
    history: PropTypes.object,
    updateContent: PropTypes.func,
    getApplyList: PropTypes.func,
    getStateList: PropTypes.func,
    getMerchantList: PropTypes.func,

    applyList: PropTypes.object,
    stateList: PropTypes.array,
    merchantList: PropTypes.array,
    addOneBreadcrumbPath: PropTypes.func
};

const mapStateToProps = (state) => ({
    applyList: state.merchantDrawApply.applyList,
    merchantList: state.merchantDrawApply.merchantList,
    stateList: state.merchantDrawApply.stateList
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, ...model.actions })(Main);