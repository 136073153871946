import * as T from './actiontypes';
//人气值商城数据源(saga)


/**
 * 人气值商城分类 列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} categoryName 分类名称
 * @param {number} state 状态
 */
export const getstarmallList = (dataload) => ({
    type: T.GET_STARLIST_DATA,
    payload: {
        ...dataload,
    }
});
/**
 * 人气值商城分类状态'数据源(saga)
 *  @param 暂无
 */
export const getstarmallListState = () => ({
    type: T.GET_LISTSTATE_DATA,
});


/**
 * 人气值商品 列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} goodsName 商品名称
 * @param {number} goodsType 状态  
 */
export const getstarvalueList = (dataload) => ({
    type: T.GET_STARVALUE_DATA,
    payload: {
        ...dataload,
    }
});
/**
 * 人气值商品分类状态'数据源(saga)
 *  @param 暂无
 */
export const getstarvalueState = () => ({
    type: T.GET_VALUESTATE_DATA,
});

/**
 * 人气值商品详情'数据源(saga)
 *  @param id
 */
export const getstarvalueDetail = (id) => ({
    type: T.GET_VALUEDETAIL_DATA,
    payload: {
        id,
    }
});
/**
 * 兑换记录数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} goodsName  商品名称	
 * @param {string} goodType 商品类型
 */
export const getstarRecord = (dataload) => ({
    type: T.GET_RECORD_DATA,
    payload: {
        ...dataload,
    }
});
/**
 * 兑换记录分类状态'数据源(saga)
 *  @param 暂无
 */
export const getstarRecordState = () => ({
    type: T.GET_RECORDSTATE_DATA,
});

/**
 * 人气值商品新建优惠劵和会员等级共用'数据源(saga)
 *  @param 会员等级暂无参数
 *  @param couponType 获取平台优惠券（固定参数COUPON）
 */
export const getStarsharing = (couponType) => ({
    type: T.GET_VALUEDHARING_DATA,
    payload: {
        couponType,
    }
});
