/**
 * 导入失败弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './ImportFail.module.scss';
import http from '@/assets/api/http';
import { Modal, Table, Divider, Button, Alert } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class ImportFail extends React.Component {

    static propTypes = {
        closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
        failList: PropTypes.array,
    }

    static defaultProps = {
        failList: [],
    }

    _columns = () => [
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
            width: '20%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            align: 'center',
            width: '20%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: 'SKU编码',
            dataIndex: 'skuNo',
            key: 'skuNo',
            align: 'center',
            width: '20%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品属性',
            dataIndex: 'spec',
            key: 'spec',
            align: 'center',
            width: '20%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '失败原因',
            dataIndex: 'errorMsg',
            key: 'errorMsg',
            align: 'center',
            width: '20%',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    _onCloseMode = () => {
        this.props.closeMode();
    }

    render() {
        const { failList } = this.props;

        return (
            <Modal
                width={1000}
                centered
                title='导入完成'
                visible={true}
                bodyStyle={{ paddingBottom: '20px' }}
                onCancel={this._onCloseMode}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                footer={false}
            >
                <div className={styles.container}>
                    <Alert message='以下商品导入失败请重新导入' type='info' showIcon />
                    <Table
                        style={{ marginTop: '10px' }}
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        rowKey={record => record.skuId}
                        columns={this._columns()}
                        pagination={false}
                        dataSource={failList}
                        scroll={{ y: 400 }}
                    />
                    <Divider />
                    <div className={styles.btnBox}>
                        <Button type='primary' onClick={this._onCloseMode}>确定</Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ImportFail;