/**
 * 魅力值订单
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Order from './actionTypes';

//魅力值订单列表
export const getStarOrderList = function* () {
    yield takeEvery(Order.GET_STARORDER_LIST, function* requestData(action) {
        try {
            let result = yield http('/boss/order/listStarLightOrder', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_STARORDER_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 支付失败订单
export const getFailStarOrderList = function* () {
    yield takeEvery(Order.GET_FAILORDER_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/rechargeRisk/getPaymentCheckList', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_FAILORDER_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 预警用户
export const getRiskUserList = function* () {
    yield takeEvery(Order.GET_RISKUSER_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/rechargeRisk/getRiskListInfo', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_RISKUSER_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 支付方式
export const getPayWays = function* () {
    yield takeEvery(Order.GET_PAY_WAYS, function* requestData(action) {
        try {
            let result = yield http('/payment/rechargeChannelQuery', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PAY_WAYS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 异常类型
export const getRiskType = function* () {
    yield takeEvery(Order.GET_RISK_TYPE, function* requestData(action) {
        try {
            let result = yield http('/settle/rechargeRisk/riskTypeQuery', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_RISK_TYPE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 状态
export const getHandleStatus = function* () {
    yield takeEvery(Order.GET_STATUS_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/rechargeRisk/handleStatusQuery', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_STATUS_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 预警等级
export const getRiskLevel = function* () {
    yield takeEvery(Order.GET_RISKLEVEL_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/rechargeRisk/riskLevelQuery', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_RISKLEVEL_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 充值来源
export const getDeviceTypeList = function* () {
    yield takeEvery(Order.GET_DEVTYPE_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/rechargeRisk/deviceTypeQuery', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_DEVTYPE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};