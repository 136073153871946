import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XDatePicker, XPagination, XSelect } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell, tooltipClick } from '@/components/TableCell';
import model from '@/reducers/storehousefinancialmanage/subsidiesincome/model';
import http from '@/assets/api/http';

const getTableData = async (params = {}) => {
    const { stateItem = {}, orderPayTimeStart, orderPayTimeEnd, orderId, userTel, merchantName, subsidyStates = [], ...other } = params;
    return http('/marketing/orderSubsidy/findPagedActivitySubsidy', {
        ...other, stateItem: undefined, subsidyStates: stateItem.code ? [stateItem.code] : subsidyStates,
        orderId: orderId ? orderId : null, userTel: userTel ? userTel : null, merchantName: merchantName ? merchantName : null,
        orderPayTimeStart: orderPayTimeStart && orderPayTimeStart.format('YYYY-MM-DD HH:mm:ss'),
        orderPayTimeEnd: orderPayTimeEnd && orderPayTimeEnd.format('YYYY-MM-DD HH:mm:ss'),
    }, 'POST').then(res => { return res.result; });
};

const defaultParams = {
    orderId: null, userTel: null, merchantName: null, orderPayTimeStart: null, orderPayTimeEnd: null, stateItem: {}
};

const Settling = ({ stateEnum = [], getStateEnum, poolType = '', history, subsidyStates = [] }) => {

    const [query, setQuery] = useState({
        ...defaultParams, subsidyStates, pageNum: autoPageNum, pageSize: autopageSize, poolType
    });
    const [tableData, setTableData] = useState({ dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 });

    useEffect(() => { getStateEnum(); }, []);
    useEffect(() => {
        setQuery({ ...query, poolType });
        onSearch({ ...query, poolType });
    }, [poolType]);

    const onSearch = async (params = {}) => {
        const result = await getTableData(params);
        setTableData(result);
    };

    const onReset = () => {
        const callback = pre => {
            return { ...pre, ...defaultParams, pageNum: autoPageNum, pageSize: autopageSize };
        };
        setQuery(callback);
    };

    const takeColumns = () => {
        const pathnames = {
            SUBSIDY_MEMBER: '/home/ordermanage/productorder/orderdetail',
            SUBSIDY_LARGE: '/home/ordermanage/productorder/orderdetail',
            SUBSIDY_MERCHANT: '/home/ordermanage/cityorder/orderdetail',
            SUBSIDY_MERCHANT_MEMBER: '/home/ordermanage/cityorder/orderdetail',
            DAILY_SUBSIDY_MEMBER: '/home/ordermanage/productorder/orderdetail',
            DAILY_SUBSIDY_REFERRAL: '/home/ordermanage/cityorder/orderdetail',
            DAILY_SUBSIDY_SERVICER: '/home/ordermanage/productorder/orderdetail'
        };
        let columns = [
            {
                title: '订单编号',
                dataIndex: 'orderId',
                key: 'orderId',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: (t, r) => tooltipClick(t, () => {
                    history.push({
                        pathname: pathnames[poolType],
                        search: `?orderId=${r.orderId}`,
                    });
                })
            }, {
                title: '会员账号',
                dataIndex: 'userTel',
                key: 'userTel',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            }, {
                title: '商户名称',
                dataIndex: 'merchantName',
                key: 'merchantName',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: tooltip
            }, {
                title: '商户积分',
                dataIndex: 'merchantWelfare',
                key: 'merchantWelfare',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: priceFenToYuanCell
            }, {
                title: '订单金额(元)',
                dataIndex: 'orderGoodsAmount',
                key: 'orderGoodsAmount',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: priceFenToYuanCell
            }, {
                title: '补贴金额(元)',
                dataIndex: 'orderSubsidyAmount',
                key: 'orderSubsidyAmount',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: priceFenToYuanCell
            }, {
                title: '状态',
                dataIndex: 'subsidyState.value',
                key: 'subsidyState.value',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: tooltip
            }, {
                title: '订单支付时间',
                dataIndex: 'orderPayTime',
                key: 'orderPayTime',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: tooltip
            }
        ];
        const isMerchant = ['SUBSIDY_MERCHANT', 'SUBSIDY_MERCHANT_MEMBER'].includes(poolType);
        const filters = ['subsidyAmount', 'merchantWelfare', 'merchantName'];
        if (!isMerchant || poolType.includes('DAILY')) columns = columns.filter(i => !filters.includes(i.key));
        if (poolType === 'SUBSIDY_MERCHANT_MEMBER') columns = columns.filter(i => i.key !== 'merchantName');
        return columns;
    };

    const onPageChange = (pageSize, pageNum) => {
        const callback = pre => {
            return { ...pre, pageSize, pageNum };
        };
        setQuery(callback);
        onSearch({ ...query, pageSize, pageNum });
    };

    return <div className={styles.flexBoxContainer} style={{ marginTop: '10px' }}>
        <SearchBox>
            <SearchItem>
                <XInput label='订单编号' placeholder='请输入订单编号'
                    value={query.orderId} onChange={orderId => setQuery({ ...query, orderId })}
                />
            </SearchItem>
            <SearchItem>
                <XInput label='会员账号' placeholder='请输入会员账号'
                    value={query.userTel} onChange={userTel => setQuery({ ...query, userTel })}
                />
            </SearchItem>
            {poolType === 'SUBSIDY_MERCHANT' &&
                <SearchItem>
                    <XInput label='商户名称' placeholder='请输入商户名称'
                        value={query.merchantName} onChange={merchantName => setQuery({ ...query, merchantName })}
                    />
                </SearchItem>}
            <SearchItem>
                <XDatePicker label='支付时间' value={query.orderPayTimeStart}
                    onChange={orderPayTimeStart => setQuery({ ...query, orderPayTimeStart })}
                />
                <XDatePicker style={{ marginRight: '20px' }} value={query.orderPayTimeEnd}
                    onChange={orderPayTimeEnd => setQuery({ ...query, orderPayTimeEnd })}
                />
            </SearchItem>
            <SearchItem>
                <XSelect label='状态' placeholder='请选择状态' renderData={(stateEnum || []).filter(i => i.code === 'WAITING_SETTLE' || i.code === 'REFUNDED')}
                    dataIndex='value' onChange={stateItem => setQuery({ ...query, stateItem })} keyIndex='code' value={query.stateItem && query.stateItem.value}
                />
            </SearchItem>
            <SearchItem>
                <XOKButton label='查询' onClick={() => onSearch(query)} />
                <XCancelButton label='重置' onClick={onReset} />
            </SearchItem>
        </SearchBox>
        <div className={styles.tableContainer} style={{ height: '100%' }}>
            <Table
                rowClassName={(r, i) => i % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.id}
                columns={takeColumns()}
                pagination={false}
                dataSource={tableData.dataList}
                scroll={{ y: true }} />
            <XPagination
                resultTotal={tableData.resultTotal}
                pageSize={tableData.pageSize}
                pageNum={tableData.pageNum}
                onChange={onPageChange} />
        </div>
    </div>;
};
Settling.propTypes = {
    history: PropTypes.object,
    getSubsidiesIncome: PropTypes.func,
    tableData: PropTypes.object,
    getStateEnum: PropTypes.func,
    stateEnum: PropTypes.array,
    poolType: PropTypes.string,
    subsidyStates: PropTypes.array
};
export default connect(state => ({
    tableData: state.subsidiesincome.subsidiesIncome,
    stateEnum: state.subsidiesincome.stateEnum
}), { ...model.actions })(Settling);