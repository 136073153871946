import { createModel } from '@/store/tools';

const model = {
    namespace: 'watermark',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getWatermarkInfo': T('getWatermarkInfo'),
        },
        actions: {
            'getWatermarkInfo': A('getWatermarkInfo'), // 获取水印
        },
        sagas: {
            'getWatermarkInfo': S('getWatermarkInfo', '/admin/param/getParamValueByCode', 'POST', 'code'),
        },
        reducers: {
            'getWatermarkInfo': R('getWatermarkInfo', ''),
        }
    })
};
export default createModel(model);