import * as T from './actiontypes';

// 获取'音乐分类'下拉数据
export const getMusicClassSelectSource = (requestProps, settings) => ({
    type: T.GET_MUSICCLASS_SELECTSOURCE,
    payload: {
        requestProps,
        settings
    }
});

// 获取'音乐分类'表格
export const getMusicTypeTableSource = (requestProps) => ({
    type: T.GET_MUSICTYPE_TABELSOURCE,
    payload: {
        requestProps
    }
});

// 获取'音乐列表'表格
export const getMusicListTableSource = (requestProps) => ({
    type: T.GET_MUSICLIST_TABELSOURCE,
    payload: {
        requestProps
    }
});
