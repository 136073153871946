/**
 * 【人气值商城订单页】
 */
import React from 'react';
import { XTabNav, showConfirm } from '@/components/xqxc_ui';
import OrderSearch from './components/OrderSearch';
import Export from './components/Export';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import { message } from 'antd';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import LogisticsModal from '../../modal/LogisticsModal';
import DeliveryModal from '../../modal/DeliveryModal';
import LeaveMsg from '../../modal/LeaveMsg';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getShopOrderList } from '@/reducers/ordermanage/shopOrder/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {
    state = {
        tab: [
            { id: 1, label: '全部订单' },
            { id: 2, label: '待付款' },
            { id: 3, label: '待收货' },
            { id: 4, label: '待发货' },
            { id: 5, label: '已完成' },
            { id: 6, label: '已取消' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
        orderID: '', // 当前操作的订单ID 
        pageNum: 1,
        pageSize: 50,
        reqParams: {
            pageNum: 1,
            pageSize: 50,
        },
        curTabIDStatus: [],
        currentOpenMode: '',
        modeParams: {},
        recordInfo: {},
    }

    componentDidMount() {
        // const { pageNum, pageSize } = this.state;
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getShopOrderList({ pageNum, pageSize });
    }
    _searchHandle = (useCache) => {
        const { reqParams, pageNum, pageSize } = this.state;
        this.searchCache = {
            ...reqParams,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getShopOrderList(this.searchCache);
    }
    // Tab页切换
    _tabChangeHandle = (tabID) => {
        const { reqParams } = this.state;
        this.setState({ curTabID: tabID });
        // PAYING：待付款

        // PAIED：已付款

        // PART_GOODS_SENT：部分发货

        // GOODS_SENT：已发货

        // GOODS_CONFIRMED：已收货

        // CANCELLED：已取消

        // FINISHED：已完成
        // ------------------------------------
        //         { id: 1, label: '全部订单' },
        //         { id: 2, label: '待付款' },
        //         { id: 3, label: '待收货' },
        //         { id: 4, label: '待发货' },
        //         { id: 5, label: '已完成' },
        //         { id: 6, label: '已取消' },
        switch (tabID) {
            case 1:
                this.setState({
                    curTabIDStatus: [],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: [],
                    }
                }, () => {
                    this._searchHandle();
                });
                break;
            case 2:
                this.setState({
                    curTabIDStatus: ['PAYING'],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: ['PAYING'],
                    }
                }, () => {
                    this._searchHandle();
                });
                break;
            case 3:
                this.setState({
                    curTabIDStatus: ['PAIED', 'PART_GOODS_SENT', 'GOODS_SENT'],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: ['PAIED', 'PART_GOODS_SENT', 'GOODS_SENT'],
                    }
                }, () => {
                    this._searchHandle();
                });
                break;
            case 4:
                this.setState({
                    curTabIDStatus: ['PAIED'],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: ['PAIED'],
                    }
                }, () => {
                    this._searchHandle();
                });
                break;
            case 5:
                this.setState({
                    curTabIDStatus: ['FINISHED', 'GOODS_CONFIRMED'],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: ['FINISHED', 'GOODS_CONFIRMED'],
                    }
                }, () => {
                    this._searchHandle();
                });
                break;
            case 6:
                this.setState({
                    curTabIDStatus: ['CANCELLED'],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: ['CANCELLED'],
                    }
                }, () => {
                    this._searchHandle();
                });
                break;
        }
    }
    // 刷新
    refresh = () => {
        this._searchHandle();
        // const { reqParams } = this.state;
        // this.props.getShopOrderList(reqParams);
    }
    // 表格操作
    _tableAction = (key, type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        const { reqParams } = this.state;
        this.setState({
            recordInfo: {
                buyerId: record.buyerId,
                orderId: record.orderId,
            },
        });
        switch (type) {
            // 查看详情
            case 'info':
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: {
                        pathname: '/home/ordermanage/shoporder/orderdetail',
                        search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                    }
                });
                history.push({
                    pathname: '/home/ordermanage/shoporder/orderdetail',
                    search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                });
                break;
            // 查看物流
            case 'logistics':
                this.setState({ currentOpenMode: 'logistics', orderID: key });
                break;
            // 发货
            case 'send':
                this.setState({ currentOpenMode: 'send', orderID: key });
                break;
            // 取消
            case 'cancel':
                showConfirm('是否确定取消？', '', () => {
                    http('/boss/order/cancelStarValueOrderShipment', { buyerId: record.buyerId, orderId: record.orderId, }, 'POST')
                        .then(() => {
                            message.success('取消成功。');
                            this.props.getShopOrderList(reqParams);
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            // 操作记录
            case 'history':
                addOneBreadcrumbPath({
                    title: '操作记录',
                    path: {
                        pathname: '/home/ordermanage/shoporder/orderhistory',
                        search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                    }
                });
                history.push({
                    pathname: '/home/ordermanage/shoporder/orderhistory',
                    search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                });
                break;
            case 'leaveMsg':
                this.setState({ currentOpenMode: 'leaveMsg', modeParams: record });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const { searchData } = this.state;
        const reqParams = { ...searchData, pageNum, pageSize };
        this.searchCache = {
            ...searchData, pageNum, pageSize
        };
        this.setState({ pageSize, pageNum, reqParams });
        this.props.getShopOrderList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 打开查看弹框
    _renderMode = () => {
        const { currentOpenMode, recordInfo, modeParams } = this.state;
        switch (currentOpenMode) {
            case 'logistics':
                return <LogisticsModal visible={true} data={this.state.orderID} closeMode={this._closeMode} />;
            case 'send':
                return <DeliveryModal title='确认发货' refresh={this.refresh} recordInfo={recordInfo} visible={true} data={this.state.orderID} closeMode={this._closeMode} />;
            case 'leaveMsg':
                return <LeaveMsg visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }
    _searchOrder = (searchData, param, flag) => {
        const { pageNum, pageSize, curTabIDStatus, curTabID } = this.state;
        let reqParams = {};
        if (curTabID === 1) {
            reqParams = { ...searchData, pageNum, pageSize };
        } else {
            reqParams = { ...searchData, pageNum, pageSize, statusList: curTabIDStatus };
        }
        this.setState({ searchData, reqParams, param }, () => {
            if (flag) {
                // 重置
                KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
            } else {
                this._searchHandle('useCache');
            }
        });
    }
    render() {
        const { curTabID } = this.state;
        const { shopOrderList } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <OrderSearch searchState={this.state} orderState={this.state.curTabID} searchOrder={this._searchOrder} />
                    {/* <Export /> */}
                    <OrderTable renderData={shopOrderList} tableAction={this._tableAction} paginationChange={this._paginationChange} curTabID={curTabID} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getShopOrderList: PropTypes.func,
    shopOrderList: PropTypes.object,
};
const mapStateToProps = (state) => ({
    shopOrderList: state.ShopOrder.shopOrderList,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getShopOrderList })(Main);
