/**
 *  【人气值商城订单】入口页
 * */ 
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const ShopOrder = ({history}) => {
    return <Main history={history} />;
};

ShopOrder.propTypes = { 
    history: PropTypes.object
};

export default ShopOrder;