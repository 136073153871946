// 营销活动列表
export const GET_MARKETINGCAMPAIGN_List = 'MARKETINGCAMPAIGN/GET_MARKETINGCAMPAIGN_List';
export const SET_MARKETINGCAMPAIGN_List = 'MARKETINGCAMPAIGN/SET_MARKETINGCAMPAIGN_List';

//获取商户
export const GET_MARKETINGCAMPAIGN_USERList = 'MARKETINGCAMPAIGN/GET_MARKETINGCAMPAIGN_USERList';
export const SET_MARKETINGCAMPAIGN_USERList = 'MARKETINGCAMPAIGN/SET_MARKETINGCAMPAIGN_USERList';

//获取商户
export const GET_WALLET_MERCHANTLIST = 'MARKETINGCAMPAIGN/GET_WALLET_MERCHANTLIST';
export const SET_WALLET_MERCHANTLIST = 'MARKETINGCAMPAIGN/SET_WALLET_MERCHANTLIST';
