import React, { useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Form, Input, message, Modal, Radio } from 'antd';

const CEModal = ({
    closeModal, data: { visible = false, id = '', classEnum = [], type = {}, name = '' } = {},
    form: { getFieldDecorator, validateFields } = {},
}) => {

    const [loading, setLoading] = useState(false);

    const onSubmit = () => {
        validateFields((err, values) => {
            if (!err) {
                setLoading(true);
                http(id ? '/merchant/industryCategory/modify' : '/merchant/industryCategory/add', { ...values, id }, 'POST')
                    .then(res => { message.success(res.message); setLoading(false); closeModal(true); })
                    .catch(err => { setLoading(false); message.error(err.message); });
            }
        });
    };

    return <Modal width={470} centered title={id ? '编辑类目' : '创建类目'} visible={visible}
        confirmLoading={loading} onOk={onSubmit} onCancel={() => closeModal(false)}
        destroyOnClose={true} keyboard={true} maskClosable={false} cancelText='取消' okText='确定'  >
        <div className={styles.modalContainer}>
            <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                <Form.Item label='类目分类'>
                    {getFieldDecorator('type', {
                        initialValue: type.code,
                        rules: [{ required: true, message: '请选择类目分类' }]
                    })(<Radio.Group>
                        {classEnum.map(i => { return <Radio key={i.code} value={i.code}>{i.value}</Radio>; })}
                    </Radio.Group>)}
                </Form.Item>
                <Form.Item label='类目名称'>
                    {getFieldDecorator('name', {
                        initialValue: name,
                        rules: [
                            { required: true, message: '请输入类目名称' },
                            { type: 'string', mim: 2, max: 10, message: '类目名称长度最小为2，最大为10' }
                        ]
                    })(<Input placeholder='请输入类目名称' />)}
                </Form.Item>
            </Form>
        </div>
    </Modal>;
};

CEModal.propTypes = {
    data: PropTypes.object,
    closeModal: PropTypes.func,
    form: PropTypes.object,
};

export default Form.create({ name: 'CreateUpdate' })(CEModal);