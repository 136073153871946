import * as MESSAGE from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
export const messageMoveList = (state = initData, action) => {
    switch (action.type) {
        case MESSAGE.SET_MESSAGEMOVE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const messageMoveDetail = (state = {}, action) => {
    switch (action.type) {
        case MESSAGE.SET_MESSAGEMOVE_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};
// 消息状态
export const messageStatus = (state = [], action) => {
    switch (action.type) {
        case MESSAGE.SET_MESSAGESTATUS:
            return action.payload.result;
        default:
            return state;
    }
};
// 消息推送方式
export const messageWays = (state = [], action) => {
    switch (action.type) {
        case MESSAGE.SET_MESSAGEWAYS:
            return action.payload.result;
        default:
            return state;
    }
};
// 消息跳转方式
export const jumpWays = (state = [], action) => {
    switch (action.type) {
        case MESSAGE.SET_JUMPWAYS:
            return action.payload.result;
        default:
            return state;
    }
};
// 推送人群
export const pushPeople = (state = [], action) => {
    switch (action.type) {
        case MESSAGE.SET_PUSHPEOPLE:
            return action.payload.result;
        default:
            return state;
    }
};