export const GET_SHOWPAREME_DATA = 'MALLSETTING/GET_SHOWPAREME_DATA'; // 商城参数
export const SET_SHOWPAREME_DATA = 'MALLSETTING/SET_SHOWPAREME_DATA'; // 商城参数

export const GET_MALLRECOMMENDLIST_DATA = 'MALLSETTING/GET_MALLRECOMMENDLIST_DATA'; // 商品列表规则参数列表
export const SET_MALLRECOMMENDLIST_DATA = 'MALLSETTING/SET_MALLRECOMMENDLIST_DATA'; // 商品列表规则参数列表

export const GET_MALLRULES_DATA = 'MALLSETTING/GET_MALLRULES_DATA'; // 商城设置推荐规则下拉框
export const SET_MALLRULES_DATA = 'MALLSETTING/SET_MALLRULES_DATA'; //商城设置推荐规则下拉框


export const GET_CATEGORYLINKAGE_DATA = 'MALLSETTING/GET_CATEGORYLINKAGE_DATA'; // 商城设置商品联级分类
export const SET_FIRSTCATEGORY = 'MALLSETTING/SET_FIRSTCATEGORY'; //商城设置商品联级分类1
export const SET_SECONDCATEGORY = 'MALLSETTING/SET_SECONDCATEGORY'; //商城设置商品联级分类2
export const SET_THIRDCATEGORY = 'MALLSETTING/SET_THIRDCATEGORY'; //商城设置商品联级分类3

//task-3097
export const GET_SALAAFTER_RULELIST = 'MALLSETTING/GET_SALAAFTER_RULELIST';//获取售后规则设置列表
export const SET_SALAAFTER_RULELIST = 'MALLSETTING/SET_SALAAFTER_RULELIST';//保存售后规则设置列表
export const GET_SALAAFTER_TYPEENUM = 'MALLSETTING/GET_SALAAFTER_TYPEENUM';//获取售后规则设置枚举
export const SET_SALAAFTER_TYPEENUM = 'MALLSETTING/SET_SALAAFTER_TYPEENUM';//保存售后规则设置枚举