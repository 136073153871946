import * as T from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const showSettingList = (state = {}, action) => {
    switch (action.type) {
        case T.SET_SHOWSETTING_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

export const toupRulesTableSource = (state = { ...initData }, action) => {
    switch (action.type) {
        case T.SET_TOUPRULES_TABLESOURCE:
            return action.payload.result;
        default:
            return state;
    }
};

export const liveDurationRechargeRulesTableSource = (state = { ...initData }, action) => {
    switch (action.type) {
        case T.SET_LIVEDURATIONRECHARGERULES_TABLESOURCE:
            return action.payload.result;
        default:
            return state;
    }
};


/**
 * 秀场设置数据源
 */
const defaultState = {
    tableSource: [],//快速回复列表数据源
    selectData: [],// 快速回复状态下拉选项
    paginations: {}, //快速回复分页对象

    populartableSource: [],//人气规则列表数据源
    popularselectData: [],// 人气规则状态下拉选项
    popularpaginations: {}, //人气规则分页对象
    popularDetail: {},//人气规则详情


    recommendtableSource: [],//推荐规则列表数据源
    recommendselectData: [],// 推荐规则状态下拉选项
    recommendpaginations: {}, //推荐规则分页对象

    changData: [],//兑换设置
    recommendContent: {},//推荐规则下拉内容
    recommendDetail: {},//推荐规则详情
    changOther: [],//赠送好友人气值限额等等
};
/**
 * @param {*} state 
 * @param {*} action 
 */
export const showsetDate = (state = defaultState, action) => {
    let newTableSource, newSelectData, newPaginations, newDetail, changData, recommendContent, recommendDetail, changOther;
    switch (action.type) {
        case T.SET_REPLYLIST_DATA: //快速回复列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_POPULARLIST_DATA: //人气规则列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, populartableSource: newTableSource, popularpaginations: newPaginations };
        case T.SET_POPULARDETAIL_DATA: //人气规则详情
            newDetail = action.payload.result;
            return { ...state, popularDetail: newDetail, };
        case T.SET_RECOMMENDLIST_DATA: //推荐规则
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, recommendtableSource: newTableSource, recommendpaginations: newPaginations };
        case T.SET_RECOMMENDSELECT_DATA: //推荐规则频道分类
            newSelectData = action.payload.result;
            return { ...state, recommendselectData: newSelectData, };
        case T.SET_CHANGDATA_DATA: //兑换设置
            changData = action.payload.result;
            return { ...state, changData, };
        case T.SET_CHANGOTHERDATA_DATA: //赠送好友人气值限额等等
            changOther = action.payload.result;
            return { ...state, changOther, };
        case T.SET_RECOMMENDCENTENT_DATA: //推荐规则下拉内容
            recommendContent = action.payload;
            return { ...state, recommendContent, };
        case T.SET_RECOMMENDDETAIL_DATA: //推荐规则详情
            recommendDetail = action.payload.result;
            return { ...state, recommendDetail, };
        default:
            return state;
    }
};