import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getIllegalShopList, getIllegalSele } from '@/reducers/auditmanage/illegalAudit/actions';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
        merchantName: '',
        shopStatus: {}
    }

    componentDidMount() {
        this.props.getIllegalSele();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { merchantName, shopStatus, pageNum, pageSize } = this.state;
        this.searchCache = {
            violationState: shopStatus.code, merchantName,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getIllegalShopList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ merchantName: '', shopStatus: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'audit':
                console.log(id, type, params);
                this.props.updateContent('shopAudit', params.id);
                break;
            case 'detail':
                console.log(id, type, params);
                this.props.updateContent('shopDetail', params.id);
                break;
        }
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        const { merchantName, shopStatus } = this.state;
        this.props.getIllegalShopList({ violationState: shopStatus.code, merchantName, pageNum, pageSize });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { illegalShopList, illegalSele } = this.props;
        const { merchantName, shopStatus } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商户'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='merchantName'
                                value={merchantName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={illegalSele}
                                bindThis={this}
                                bindName='shopStatus'
                                dataIndex='value'
                                keyIndex='code'
                                value={shopStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent paginationChange={this._paginationChange} renderData={illegalShopList} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }

}

Main.propTypes = {
    updateContent: PropTypes.func,
    getIllegalShopList: PropTypes.func,
    getIllegalSele: PropTypes.func,

    illegalShopList: PropTypes.object,
    illegalSele: PropTypes.array
};

const mapStateToProps = (state) => ({
    illegalShopList: state.illegalAudit.illegalShopList,
    illegalSele: state.illegalAudit.illegalSele
});

export default connect(mapStateToProps, { getIllegalShopList, getIllegalSele })(Main);