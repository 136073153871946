/**
 * 删除
 */
import React from 'react';
import { Modal, Radio, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './DeleteModal.module.scss';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;
class DeleteModal extends React.Component {
    state = {
        visible: this.props.visible,
        confirmLoading: false,
        delType: 0,
    };

    _request = (url, data) => {
        http(url, data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('删除成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _handleOk = () => {
        const { id, type } = this.props.data;
        const { delType } = this.state;

        if (type == 'DEPT') {
            this._request('/admin/dept/deleteDept', { id, delType });
        } else {
            this._request('/admin/dept/deleteCompany', { id, delType });
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _radioChangeHandle = (e) => {
        this.setState({ delType: e.target.value });
    }

    render() {
        const { visible, confirmLoading, delType } = this.state;
        const { type } = this.props.data;
        const label = type == 'DEPT' ? '部门' : '公司';

        return (
            <Modal
                width={470}
                centered
                title='删除'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.reason}>
                        <div>删除{label}：</div>
                        <RadioGroup style={{ marginLeft: '8px' }} onChange={this._radioChangeHandle} value={delType}>
                            <Radio key={0} value={0}>{label}不包括员工</Radio>
                            <Radio key={1} value={1}>{label}包括员工</Radio>
                        </RadioGroup>
                    </div>
                    {/* <div className={styles.txt}>（删除该部门如包含下级部门将一并删除）</div> */}
                </div>
            </Modal>
        );

    }
}

DeleteModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default DeleteModal;
