import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Switch, } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const columns = (TableAction, actionClickHandle) => ([
    {
        title: '名称',
        dataIndex: 'virtualName',
        key: 'virtualName',
        width: '20%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '触发条件',
        dataIndex: 'virtualTypeDsc',
        key: 'virtualTypeDsc',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '人气数',
        dataIndex: 'peopleMultiple',
        key: 'peopleMultiple',
        align: 'center',
        width: '10%',
        onCell: tooltipStyle,
        render: tooltip
    },
    // {
    //     title: '点赞数',
    //     dataIndex: 'c4',
    //     key: 'c4 ',
    //     width: '10%',
    //     align: 'center',
    //     render: (text) => (TableCell(text))
    // },
    {
        title: '操作人',
        dataIndex: 'modifyUsername',
        key: 'modifyUsername',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    // {
    //     title: '使用次数',
    //     dataIndex: 'c7',
    //     key: 'c7',
    //     width: '10%',
    //     align: 'center',
    //     render: (text) => (TableCell(text))
    // },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle))
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '10%',
        render: (id, record) => TableAction(id, record, actionClickHandle)
    }
]);

const TableAction = (id, record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            {record.isDefault == '0' ? <div className={styles.item} onClick={() => actionClickHandle(id, 'editor', record)}>编辑</div> :
                <div className={styles.item} onClick={() => actionClickHandle(id, 'editorDefault', record)}>编辑</div>
            }
            {record.isDefault == '0' && <div className={styles.item} onClick={() => actionClickHandle(id, 'delete', record)}>删除</div>}
        </div >
    );
};


let defaultCellState = false;//默认开关数据处理
// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
    let checked = true;
    if (sw == '0') checked = false;
    if (item.isDefault == '1') {
        sw == '0' ? defaultCellState = false : defaultCellState = true;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                disabled={item.isDefault == '1' ? false : (defaultCellState ? true : false)}
                onChange={(value) => {
                    actionClickHandle(item, 'switch', value);
                }}
            />
        </div>
    );
};


class TypeTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data;
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { renderData, pagiNation, _paginationChange, } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => record.id}
                    columns={columns(TableAction, this._actionHandle)}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={pagiNation.resultTotal}
                    pageSize={pagiNation.pageSize}
                    pageNum={pagiNation.pageNum}
                    onChange={_paginationChange}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    pagiNation: PropTypes.object, // 分页数据
    _paginationChange: PropTypes.func,// 页面发生变化
};

export default TypeTable;