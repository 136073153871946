/**
 * 秀场管理 - 频道分类异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const getStudioRoomTableSource = function* (){
    yield takeEvery(T.GET_STUDIOROOM_TABELSOURCE, function* requestData(action){
        try{
            let result = yield http('/show/showClass/listLiveBoradcasClass', action.payload); // action.payload为传入的参数对象
            yield put({type:T.INIT_LIVE_STATUS});
            yield put({type:T.SET_STUDIOROOM_TABELSOURCE, payload: result.result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

export const getLiveSort = function* (){
    yield takeEvery(T.GET_LIVE_SORT, function* requestData(action){
        try{
            let result = yield http('/show/showClass/updateShowClassSortNo', action.payload); // action.payload为传入的参数对象
            yield put({type:T.SET_LIVE_SORT, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

export const getLiveStatus = function* (){
    yield takeEvery(T.GET_LIVE_STATUS, function* requestData(action){
        try{
            let response = yield http('/show/showClass/updateShowClassState', action.payload); // action.payload为传入的参数对象
            if(response.status == '200'){
                yield put({type:T.UPDATE_LIVE_STATUS_SUCCESS});
                switch(action.payload.classTypeCode){
                    case 'LIVE_BORADCAS_CLASS':
                        yield put({type:T.UPDATE_STUDIOROOM_TABELSOURCE_STATE, payload: action.payload});
                        break;
                    case 'SHORT_VIDEO_CLASS':
                        yield put({type:T.UPDATE_VIDEO_TABELSOURCE_STATE, payload: action.payload});
                        break;
                    case 'GRAPHICS_CLASS':
                        yield put({type:T.UPDATE_COMMUNITY_TABELSOURCE_STATE, payload: action.payload});
                        break;
                }
                
            }else{
                yield put({type:T.UPDATE_LIVE_STATUS_FAIL});
            }
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

export const getLiveClassifyStatus = function* (){
    yield takeEvery(T.GET_LIVE_CLASSIFYSTATUS, function* requestData(action){
        try{
            let result = yield http('/enum/StateEnum', action.payload); // action.payload为传入的参数对象
            yield put({type:T.SET_LIVE_CLASSIFYSTATUS, payload: result.result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};


export const getVideoData = function* (){
    yield takeEvery(T.GET_VIDEO_DATA, function* requestData(action){
        try{
            let result = yield http('/show/showClass/listShortVideoClass', action.payload); // action.payload为传入的参数对象
            yield put({type:T.SET_VIDEO_DATA, payload: result.result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};


export const getCommunityData = function* (){
    yield takeEvery(T.GET_COMMUNITY_DATA, function* requestData(action){
        try{
            let result = yield http('/show/showClass/listFeedClass', action.payload); // action.payload为传入的参数对象
            console.log(result.result);
            yield put({type:T.SET_COMMUNITY_DATA, payload: result.result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};