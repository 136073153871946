/**
 * 受理
 */
import React from 'react';
import { Modal, Radio, Input, message } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import styles from './AcceptModal.module.scss';
import { getProcessDetail } from '@/reducers/salemanage/salelist/actions';
import { regFenToYuan } from '@/util/money';

const RadioGroup = Radio.Group;
const { TextArea } = Input;

class AcceptModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      visible: this.props.visible,
      data: this.props.data,
      salesStatus: '',
      nodeContent: '',
    };
  }

  componentDidMount() {
    const { data: { id } } = this.state;
    this.props.getProcessDetail({ orderSaleId: id });
  }
  _handleOk = () => {
    this.setState({
      visible: false,
    });
    const { salesStatus, nodeContent, data: { id, sellerId, version } } = this.state;
    const param = {
      salesStatus,
      nodeContent,
      orderSaleId: id,
      sellerId,
      version
    };
    http('/boss/order/reviewOrderAfterSale', param, 'POST')
      .then(() => {
        message.success('受理成功!');
        this.props.refresh();
      }).catch((reject) => {
        message.error(reject.message);
      });
    this.props.closeMode();
  }

  _handleCancel = () => {
    this.setState({
      visible: false,
    });
    this.props.closeMode();
  }

  _radioValueChangeHandle = (e) => {
    this.setState({ salesStatus: e.target.value });
  }

  _renderImages = (attachList) => {
    return (
      attachList.map((item, index) => {
        return (
          <img key={index} src={item} alt='凭证图片' />
        );

      })
    );
  }
  _renderNOaccept = () => {
    const { salesStatus, nodeContent } = this.state;
    if (salesStatus == 'PLATFORM_UNAGREE') {
      return (
        <TextArea
          className={styles.textArea}
          placeholder='请输入不受理原因'
          rows={4}
          onChange={this._areaChangeHandle}
          value={nodeContent}
        />
      );

    }
  }
  _areaChangeHandle = (e) => {
    this.setState({ nodeContent: e.target.value });
  }

  render() {
    const { visible, confirmLoading, title, salesStatus } = this.state;
    const { processDetail, processDetail: { attachList = [] } } = this.props;
    return (
      <Modal
        width={470}
        centered
        title={title}
        visible={visible}
        confirmLoading={confirmLoading}
        onOk={this._handleOk}
        onCancel={this._handleCancel}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
      >
        <div className={styles.container}>
          <div className={styles.top}>
            <span>售后类型</span>
            <p>{processDetail.salesType}</p>
          </div>
          <div className={styles.top}>
            <span>数&nbsp;&nbsp;量</span>
            <p>{processDetail.goodsCount}</p>
          </div>
          <div className={styles.top}>
            <span>退款金额</span>
            <p>{regFenToYuan(processDetail.refundAmount)}元</p>
          </div>
          <div className={styles.top}>
            <span>售后原因</span>
            <p>{processDetail.salesReason}</p>
          </div>
          <div className={styles.top}>
            <span>售后说明</span>
            <p>{processDetail.salesExplain}</p>
          </div>
          <div className={styles.items}>
            <p>凭证图片</p>
            <div className={styles.imgs}>
              {this._renderImages(attachList)}
            </div>
          </div>
          <div className={styles.radio}>
            <div className={styles.label}>状态</div>
            <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={salesStatus}>
              <Radio value={'PLATFORM_AGREE'}>受理</Radio>
              <Radio value={'PLATFORM_UNAGREE'}>不受理</Radio>
            </RadioGroup>
          </div>
          {this._renderNOaccept()}
        </div>
      </Modal>
    );
  }
}

AcceptModal.propTypes = {
  data: PropTypes.object, // 弹框入参
  refresh: PropTypes.func,
  visible: PropTypes.bool.isRequired, // 是否显示弹框
  closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
  title: PropTypes.string, // 弹框标题
  getProcessDetail: PropTypes.func,
  processDetail: PropTypes.object,
};
const mapStateToProps = (state) => {
  return {
    processDetail: state.SaleList.processDetail,
  };
};
export default connect(mapStateToProps, { getProcessDetail })(AcceptModal);