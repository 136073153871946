import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, message, Select, InputNumber } from 'antd';
import { XInfoLabel } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import BigNumber from 'bignumber.js';

const SetMoney = ({
    modalParams = [],
    closeModal
}) => {

    const {
        subjectId = '',
        subjectName = '',
        loginName = '',
        merchantType = {},
        balance = 0
    } = modalParams[0] || {};

    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(null);
    const [adjustType, setAdjustType] = useState();
    const [changeBalance, setChangeBalance] = useState(new BigNumber(balance || 0).dividedBy(100).toFixed(2));
    const adjustTypeEnum = [{ code: 0, value: '减少' }, { code: 1, value: '增加' }];

    const onSubmit = () => {
        let url = '/settle/advances/received/sysAdd';
        if (!adjustType) url = '/settle/advances/received/sysDeduct';
        setLoading(true);
        http(url, { amount: new BigNumber(amount || 0).multipliedBy(100).toNumber(), merchantId: subjectId, merchantName: subjectName }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeModal(true);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    const setItemStyle = {
        display: 'flex',
        alignItems: 'center'
    };

    const itemStyle = {
        color: '#999999'
    };

    const inputStyle = {
        width: '180px',
        margin: '10px 10px 10px 0'
    };

    const adjustTypeChange = (value) => {
        if (value) {
            setChangeBalance(pre => {
                return new BigNumber(pre || 0).plus(amount || 0).plus(amount || 0).toFixed(2);
            });
        } else {
            setChangeBalance(pre => {
                return new BigNumber(pre || 0).minus(amount || 0).minus(amount || 0).toFixed(2);
            });
        }
        setAdjustType(value);
    };

    const amountChange = (value) => {
        if (adjustType) {
            setChangeBalance(pre => {
                return new BigNumber(pre || 0).minus(amount || 0).plus(value || 0).toFixed(2);
            });
        } else {
            setChangeBalance(pre => {
                return new BigNumber(pre || 0).plus(amount || 0).minus(value || 0).toFixed(2);
            });
        }
        setAmount(value);
    };

    return <Modal
        width={600}
        centered
        title='设置预收款金额'
        visible={true}
        confirmLoading={loading}
        onOk={onSubmit}
        onCancel={() => closeModal(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div>
            <XInfoLabel label='商户账户：' value={loginName} />
            <XInfoLabel label='商户名称：' value={subjectName} />
            <XInfoLabel label='商户类型：' value={merchantType.value} />
            <div style={setItemStyle}>
                <div style={itemStyle}>设置金额：</div>
                <Select
                    style={inputStyle}
                    placeholder='请选择金额调整类型'
                    value={adjustType}
                    onChange={adjustTypeChange}>
                    {
                        adjustTypeEnum.map(i => {
                            return <Select.Option key={i.code} value={i.code}>{i.value}</Select.Option>;
                        })
                    }
                </Select>
                <InputNumber
                    precision={2}
                    min={0}
                    max={99999.99}
                    disabled={adjustType === undefined}
                    style={inputStyle}
                    placeholder='请输入调整金额'
                    value={amount}
                    onChange={amountChange} />
                （元）
            </div>
            <XInfoLabel label='调整后的余额为：' value={`${changeBalance} 元`} />
        </div>
    </Modal>;
};
SetMoney.propTypes = {
    modalParams: PropTypes.array,
    closeModal: PropTypes.func
};
export default SetMoney;