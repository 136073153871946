/**
 * 秀场管理 - 频道分类异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const getEnumSelectSource = function* () {
    yield takeEvery(T.GET_ENUM_SELECTSOURCE, function* () {
        try {
            let result = yield http('/enum/StateEnum');
            yield put({ type: T.SET_ENUM_SELECTSOURCE, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
export const getEnumFlagSource = function* () {
    yield takeEvery(T.GET_ENUM_FLAGENUM, function* () {
        try {
            let result = yield http('/enum/OpenEnum');
            yield put({ type: T.SET_ENUM_FLAGENUM, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
export const getEnumCustomerSource = function* () {
    yield takeEvery(T.GET_ENUM_OPENCUSTOMER, function* () {
        try {
            let result = yield http('/enum/OpenCustomerServiceStateEnum');
            yield put({ type: T.SET_ENUM_OPENCUSTOMER, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};