import * as T from './actiontypes';

/**
 * 活动商品列表
 */
export const activeGoods = (state = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
}, { type, payload }) => {
    switch (type) {
        case T.SET_ACTIVE_GOODS:
            return payload.result;
        default:
            return state;
    }
};

/**
 * 活动商品详情
 */
export const productDetail = (state = {}, { type, payload }) => {
    switch (type) {
        case T.SET_GOOD_DETAIL:
            return payload.result;
        default:
            return state;
    }
};

/**
 * 参与活动的商户下拉列
 */
export const merchants = (state = { merchantIdAndNames: [] }, { type, payload }) => {
    switch (type) {
        case T.SET_MERCHANTS:
            return payload.result;
        default:
            return state;
    }
};



