import * as T from './actiontypes';

/**
 * 获取商品分类列表
 */
export const getProductClassify = (params = {}) => ({
    type: T.GET_PRODUCT_CLASSIFY,
    payload: params
});

export const addNullCell = (value) => ({
    type: T.ADD_PRODUCT_NULLCELL,
    payload: value
});

export const deleteNullCell = (value) => ({
    type: T.DELETE_PRODUCT_NULLCELL,
    payload: value
});

// 获取第三方商品分类列表
export const getProductThirdPartyClassify = (params = {}) => ({
    type: T.GET_PRODUCT_THIRDPARTYCLASSIFY,
    payload: params
});


