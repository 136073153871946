/**
 * 股票溢价收益
 */
import React from 'react';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XOKButton, XDatePicker, XCancelButton, XInput, XSelect } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/exchangemanage/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import Delivery from './modal/Delivery';

class Main extends React.Component {
    state = {
        renderMode: '',
        modeParams: {},
        curTabID: 1,  // 当前显示的Tab页ID
        payStartTime: null,
        payEndTime: null,
        beginAuditPassTime: null,
        endAuditPassTime: null,
        stateItem: {},
        source: {},
        batchKeys: [],
        accountType: [],
        serviceTel: '',
        accountId: ''
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getProfitStatusEnum();
        this.props.getVoucherSourceEnum();
        if (this.props.match) {
            let { params } = this.props.match.params || {};
            if (params) {
                let [accountType, tel, accountId, voucherStatus, beginAuditPassTime, endAuditPassTime] = params.split('&');
                this.setState({
                    serviceTel: tel,
                    accountTypeList: accountType.split(',').filter(v => v),
                    
                    accountId,
                    stateItem: { code: voucherStatus },
                    beginAuditPassTime,
                    endAuditPassTime,
                });
            }
        }
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                addOneBreadcrumbPath({
                    title: '股权溢价收益详情',
                    path: `/home/storehousefinancialmanage/exchangemanage/servicestockprofit/detail/${id}`
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/exchangemanage/servicestockprofit/detail/${id}`
                });
                break;
            case 'audit':
                this.setState({ renderMode: 'audit', modeParams: params });
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { serviceTel, id, accountId, payStartTime, payEndTime, beginAuditPassTime, endAuditPassTime, pageNum, pageSize, stateItem, source, accountTypeList } = this.state;
        this.searchCache = {
            accountTel: serviceTel,
            id,
            beginDate: payStartTime,
            endDate: payEndTime,
            voucherStatus: stateItem.code,
            source: source.code,
            accountTypeList,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getExchangeProfitList({
            ...this.searchCache,
            accountId,
            beginAuditPassTime,
            endAuditPassTime,
        });
        KeepAlive.saveCache({ ...this.searchCache, stateItem }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            serviceTel: '',
            id: '',
            payStartTime: null,
            payEndTime: null,
            pageNum: 1,
            pageSize: 10,
            stateItem: {},
            source: {}
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 渲染弹框
    _renderMode = () => {
        const { renderMode, modeParams } = this.state;
        switch (renderMode) {
            case 'audit':
                return <Delivery modeParams={modeParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ renderMode: '', modeParams: {} }, () => {
            update && this._searchHandle('useCache');
        });
    }


    render() {
        const { curTabID, serviceTel, id, payStartTime, payEndTime, stateItem, source } = this.state;
        const { exchangeProfitList, profitStatusEnum, voucherSourceEnum } = this.props;

        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div style={{ marginTop: 10 }}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='账号查询'
                                    placeholder='账号查询'
                                    value={serviceTel}
                                    bindThis={this}
                                    bindName='serviceTel'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='状态'
                                    placeholder='请选择'
                                    renderData={profitStatusEnum}
                                    bindThis={this}
                                    bindName='stateItem'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={stateItem.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='业务来源'
                                    placeholder='请选择'
                                    renderData={voucherSourceEnum}
                                    bindThis={this}
                                    bindName='source'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={source.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='兑换凭证'
                                    placeholder='请输入兑换凭证'
                                    value={id}
                                    bindThis={this}
                                    bindName='id'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XDatePicker
                                    allowClear
                                    label='凭证生成时间'
                                    value={payStartTime}
                                    bindThis={this}
                                    bindName='payStartTime'
                                    isFormat
                                />
                                <XDatePicker
                                    allowClear
                                    style={{ marginRight: 20 }}
                                    value={payEndTime}
                                    bindThis={this}
                                    bindName='payEndTime'
                                    isFormat
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <OrderTable renderData={exchangeProfitList} tableAction={this._tableAction} paginationChange={this._paginationChange} curTabID={curTabID} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getExchangeProfitList: PropTypes.func,
    exchangeProfitList: PropTypes.object,
    getProfitStatusEnum: PropTypes.func,
    profitStatusEnum: PropTypes.array,
    getVoucherSourceEnum: PropTypes.func,
    voucherSourceEnum: PropTypes.array,
};
const mapStateToProps = (state) => ({
    exchangeProfitList: state.exchangemanage.exchangeProfitList,
    profitStatusEnum: state.exchangemanage.profitStatusEnum,
    voucherSourceEnum: state.exchangemanage.voucherSourceEnum,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
