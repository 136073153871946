import * as T from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};


//存储'黑名单表格'
export const blackListTable = (state = initData, { type, payload, requestProps = {} }) => {
    switch (type) {
        case T.SET_BLACKLIST_TABELSOURCE:
            return { ...requestProps, ...payload };
        default:
            return state;
    }
};

export const historyTable = (state = initData, { type, payload }) => {
    switch (type) {
        case T.SET_HISTORY_TABELSOURCE:
            return payload;
        default:
            return state;
    }
};