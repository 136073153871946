/**
 * 销售员表格
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';
import { isPlat } from '@/assets/js/authType';
import { encryptedTelNumber } from '@/assets/js/utils';
import ButtonPerssion from '@/routes/ButtonPerssion';

class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '真实姓名',
            dataIndex: 'realName',
            key: 'realName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '手机号码',
            dataIndex: 'tel',
            key: 'tel',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: (content) => {
                return isPlat() ? content : encryptedTelNumber(content);
            }
        },
        {
            title: '推荐人',
            dataIndex: 'recommendName',
            key: 'recommendName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '用户类型',
            dataIndex: 'userTypeText',
            key: 'userTypeText',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '来源类型',
            dataIndex: 'recommendTypeText',
            key: 'recommendTypeText',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态类型',
            dataIndex: 'stateText',
            key: 'stateText',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '待结算收益(元)',
            dataIndex: 'preIncome',
            key: 'preIncome',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '已结算收益(元)',
            dataIndex: 'income',
            key: 'income',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '开通时间',
            dataIndex: 'payTime',
            key: 'payTime',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            width: '15%',
            align: 'center',
            render: (id, item) => this._TableAction(item.id, item)
        },
    ]);

    _takeDataSource = (dataList) => {
        if (dataList && Array.isArray(dataList)) {
            return dataList;
        } else {
            return [];
        }
    }

    _TableAction = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'detail')}>查看详情</div>
                {isPlat() ? <div className={styles.item} onClick={() => tableAction(key, 'income')}>收益明细</div> : null}
                {
                    item.state == 'SIGNED_AGREEMENT' ?
                        <ButtonPerssion code='usermanage/saler/pass'>
                            <div className={styles.item} onClick={() => tableAction(item.tel, 'pass')}>审核通过</div>
                        </ButtonPerssion>
                        : null
                }
            </div>
        );
    };

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object, // 表格数据源
    tableAction: PropTypes.func, // 表格操作
    paginationChange: PropTypes.func, // 分页操作
};

export default TableContent;