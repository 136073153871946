import * as T from './actiontypes';

/**
 * 获取'店铺列表'数据源(action)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {sting} shopName  店铺名称   
 * @param {sting} shopState  封禁状态
 * @param {sting} isReco 店铺推荐
 * @param {number} merchantName 归属商户 
 */
export const getStoreListTableSource = (dataload) => ({
    type: T.GET_STORELIST_DATA,
    payload: {
        ...dataload,
    }
});

/**
 * 获取'店铺列表封禁状态,封禁----封禁理由 单选框'数据源(action)
 * @param 暂无
 * 
 */
export const getstoreselectData = (dataload) => ({
    type: T.GET_STOSELECT_DATA,
    payload: {
        ...dataload,
    }
});

/**
 * 获取'封禁信息'数据源(action)
 * @param {number} id 店铺id
 * 
 */
export const getBannedinfomation = (dataload) => ({
    type: T.GET_INFOMATION_DATA,
    payload: {
        ...dataload,
    }
});

/**
 * 获取'店铺详情'数据源(action)
 * @param {number} id 店铺id
 * 
 */
export const getStoreDetails = (dataload) => ({
    type: T.GET_STOREDETAILS_DATA,
    payload: {
        ...dataload,
    }
});

/**
 * 获取'店铺封禁日志'数据源(action)
 * @param {number} id 店铺id
 * 
 */
export const getstoreTakelog = (dataload) => ({
    type: T.GET_STORETAKELOG_DATA,
    payload: {
        ...dataload,
    }
});

/**
 * 店铺推荐列表(action)
 */
export const getStoreRecommendation  = (dataload) => ({
    type: T.GET_RECOMMENDATION_DATA,
    payload: {
        ...dataload,
    }
});


