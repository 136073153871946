import * as Audit from './actiontypes';

export const getOperationInvoiceList = ({ settlementId, companyId, status, startDate, endDate, pageNum, pageSize }) => ({
    type: Audit.GET_OPERATIONINVOICE_LIST,
    payload: { settlementId, companyId, status, startDate, endDate, pageNum, pageSize }
});

export const getOperationInvoiceStatus = () => ({
    type: Audit.GET_OPERATIONINVOICE_STATUS,
    payload: {}
});

export const getOperationInvoiceName = () => ({
    type: Audit.GET_OPERATIONINVOICE_NAME,
    payload: {}
});

export const getOperationInvoiceDetail = (settlementId) => ({
    type: Audit.GET_OPERATIONINVOICE_DETAIL,
    payload: { settlementId }
});

export const getOperationInvoicePay = ({ ids }) => ({
    type: Audit.GET_OPERATIONINVOICE_PAY,
    payload: { ids }
});