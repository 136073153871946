import { Input, message, Modal, Row, Select, Col } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';

const typeEnum = [{ code: 'USER', value: '用户' }, { code: 'SERVICE_PROVIDER', value: '服务商' }];

const BatchSignModal = ({ closeMode }) => {

    const [loading, setLoading] = useState(false);
    const [subjectType, setSubjectType] = useState();
    const [telList, settelList] = useState();

    const onSubmit = () => {
        if (telList.replace(/，/g, ',').split(',').length > 200) return message.warn('最多支持200个会员');
        http('/pay/outAccount/signOk', { subjectType, telList: telList.replace(/，/g, ',').split(',') }, 'POST')
            .then(res => { message.success(res.message); setLoading(false); closeMode(true); })
            .catch(err => { message.error(err.message); setLoading(false); });
    };

    return <Modal width={560} centered title='批量签约' visible={true} destroyOnClose={true} keyboard={true} maskClosable={false}
        confirmLoading={loading} onOk={onSubmit} onCancel={() => closeMode(false)} cancelText='取消' okText='确认'>
        <Row gutter={[0, 16]}>
            <Col span={4}><div style={{ paddingTop: '4px' }}>会员类型：</div></Col>
            <Col span={20}><Select style={{ width: '150px' }} placeholder='请选择会员类型' value={subjectType} onChange={v => setSubjectType(v)}>
                {typeEnum.map(i => {
                    return <Select.Option key={i.code} value={i.code}>{i.value}</Select.Option>;
                })}
            </Select></Col>
            <Col span={4}><div style={{ paddingTop: '4px' }}>会员账号：</div></Col>
            <Col span={20}><Input.TextArea placeholder='请输入会员账号，多个使用英文逗号隔开，最高支持200个' rows={4}
                value={telList} onChange={e => settelList(e.target.value)} /></Col>
        </Row>
    </Modal>;
};
BatchSignModal.propTypes = {
    closeMode: PropTypes.func
};
export default BatchSignModal;