import { put, takeEvery } from 'redux-saga/effects';
import * as T from './actiontypes';
import http from '@/assets/api/http';

// 获取已分配出库单列表
export const getInvoiceList = function* () {
    yield takeEvery(T.GET_INVOICELIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/distributionOutboundOrderList', action.payload, 'POST');
            yield put({ type: T.SET_INVOICELIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 发货清单
export const getDeliverListTableSource = function* () {
    yield takeEvery(T.GET_DELIVERLIST_TABLESOURCE, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationSource/queryShipmentList', action.payload, 'POST');
            yield put({ type: T.SET_DELIVERLIST_TABLESOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 对账单列表
export const getValidateStatementTableSource = function* () {
    yield takeEvery(T.GET_VALIDATESTATEMENT_TABLESOURCE, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationInfo/listReconciliation', action.payload, 'POST');
            yield put({ type: T.SET_VALIDATESTATEMENT_TABLESOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 合同管理列表
export const getContractManageTableSource = function* () {
    yield takeEvery(T.GET_CONTRACTMANAGE_TABLESOURCE, function* requestData(action) {
        try {
            let result = yield http('/merchant/contract/listContract', action.payload, 'POST');
            yield put({ type: T.SET_CONTRACTMANAGE_TABLESOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getContractDetail = function* () {
    yield takeEvery(T.GET_CONTRACTDETAIL, function* requestData(action) {
        try {
            let result = yield http('/merchant/contract/listContract', action.payload, 'POST');
            yield put({ type: T.SET_CONTRACTDETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getContractStates = function* () {
    yield takeEvery(T.GET_CONTRACTSTATES, function* requestData(action) {
        try {
            let result = yield http('/enum/ContractAuditTypeEnum/', action.payload, 'POST');
            yield put({ type: T.SET_CONTRACTSTATES, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// // 结算状态
// export const getSettlementStatesSelectSource = function* () {
//     yield takeEvery(T.GET_SETTLEMENTSTATES_SELECTSOURCE, function* requestData(action) {
//         try {
//             let result = yield http('/merchant/order/distributionOutboundOrderList', action.payload, 'POST');
//             yield put({ type: T.SET_SETTLEMENTSTATES_SELECTSOURCE, payload: result });
//         } catch (e) {
//             yield put({ type: 'SAGA_ERROR', payload: { error: e } });
//         }
//     });
// };
