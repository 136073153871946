import * as ActiveProduct from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const activeProductAuditList = (state = initData, action) => {
    switch (action.type) {
        case ActiveProduct.SET_ACTIVEPRODUCTAUDIT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

export const activeProductAuditStatus = (state = [], action) => {
    switch (action.type) {
        case ActiveProduct.SET_ACTIVEPRODUCTAUDIT_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};

export const activeProductAuditDetail = (state = {}, action) => {
    switch (action.type) {
        case ActiveProduct.SET_ACTIVEPRODUCTAUDIT_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

export const activeProductAuditInfo = (state = {}, action) => {
    switch (action.type) {
        case ActiveProduct.SET_ACTIVEPRODUCTAUDIT_INFO:
            return action.payload.result;
        default:
            return state;
    }
};

export const activeProductAuditShopSele = (state = { infoListResps: [] }, action) => {
    switch (action.type) {
        case ActiveProduct.SET_ACTIVEPRODUCTAUDIT_SHOPSELE:
            return action.payload.result;
        default:
            return state;
    }
};

export const activeProductAuditExitList = (state = initData, action) => {
    switch (action.type) {
        case ActiveProduct.SET_ACTIVEPRODUCTAUDIT_EXITLIST:
            return action.payload.result;
        default:
            return state;
    }
};

export const activeProductAuditExitInfo = (state = {}, action) => {
    switch (action.type) {
        case ActiveProduct.SET_ACTIVEPRODUCTAUDIT_EXITINFO:
            return action.payload.result;
        default:
            return state;
    }
};