/**
 *  权限管理 - 菜单配置
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const Menumanage = ({history}) => {
    return <Main history={history} />;
};

Menumanage.propTypes = { 
    history: PropTypes.object
};

export default Menumanage;