import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip, priceFenToYuanCell, MoreCell, cellWidth, tooltipClick } from '@/components/TableCell';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { isPlat, isRE } from '@/assets/js/authType';
import { getLocalStorage } from '@/assets/js/storage';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [],
        selectedRows: []
    };

    static getDerivedStateFromProps(props, state) {
        if (props.selectedRowKeys !== state.selectedRowKeys) {
            return {
                selectedRowKeys: props.selectedRowKeys,
                selectedRows: props.selectedRows
            };
        }
        return null;
    }

    _columns = () => {
        let cols = [
            {
                title: '会员账号',
                dataIndex: 'tel',
                key: 'tel',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '昵称',
                dataIndex: 'nickname',
                key: 'nickname',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '性别',
                dataIndex: 'sex',
                key: 'sex',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            // {
            //     title: '年龄', dataIndex: 'age', key: 'age', width: cellWidth.small, align: 'center', onCell: tooltipStyle, render: tooltip
            // },
            {
                title: '所属服务商',
                dataIndex: 'serviceName',
                key: 'serviceName',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '所属区域',
                dataIndex: 'address',
                key: 'address',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '会员等级',
                dataIndex: 'levelName',
                key: 'levelName',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '会员类型',
                dataIndex: 'accountTypeName',
                key: 'accountTypeName',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '代理权益',
                dataIndex: 'userOpenRightsDesc',
                key: 'userOpenRightsDesc',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '来源',
                dataIndex: 'sourceName',
                key: 'sourceName',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '总魅力值',
                dataIndex: 'starlightNumber',
                key: 'starlightNumber',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '总人气值',
                dataIndex: 'starNumber',
                key: 'starNumber',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '总信用值',
                dataIndex: 'availableShowRice',
                key: 'availableShowRice',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '可用特权值',
                dataIndex: 'availableIntegral',
                key: 'availableIntegral',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '可提现金',
                dataIndex: 'money',
                key: 'money',
                width: cellWidth.small,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '总积分',
                dataIndex: 'totalPoints',
                key: 'totalPoints',
                width: cellWidth.small,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '注销状态',
                dataIndex: 'loginState.value',
                key: 'loginState.value',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '推荐小B',
                dataIndex: 'salesmanNum',
                key: 'salesmanNum',
                width: cellWidth.small,
                align: 'center',
                onCell: tooltipStyle,
                render: (text, item) => tooltipClick(text, () => {
                    this.props.tableAction(item.accountId, 'MEMBER_SUGGEST');
                })
            },
            {
                title: '注册时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: cellWidth.time,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: cellWidth.big,
                fixed: 'right',
                render: (id, item) => this._TableAction(item.accountId, item)
            },
        ];
        const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
        if (userInfo) {
            const { type } = userInfo;
            if (type == 'AGENCY') {
                cols.splice(3, 2);
            }
        }
        return cols;
    }

    _takeDataSource = (data) => {
        return data.map((item) => {
            item.address = '' + this._resolveAreaName(item.provinceName) + this._resolveAreaName(item.cityName) + this._resolveAreaName(item.areaName);
            item.status = item.state == 1 ? '正常' : '禁止';
            return item;
        });
    }

    _resolveAreaName = (areaName) => {
        if (areaName) {
            return areaName;
        } else {
            return '';
        }
    }

    _MoneyCell = (text) => {
        const money = regFenToYuan(parseInt(text));
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {money}
            </div>
        );
    };

    _TableAction = (key, item) => {
        const { tableAction } = this.props;
        const arr = [
            { name: '卡券管理', onClick: () => tableAction(key, 'MEMBER_TICKET', item), perssionCode: 'usermanage/member/kqgl' },
            { name: '修改区域', onClick: () => tableAction(key, 'CHANGE_AREA', item), perssionCode: 'usermanage/member/xgqy' },
            { name: '成长值明细', onClick: () => tableAction(key, 'MEMBER_CHARM', item), perssionCode: 'usermanage/member/czzmx' },
            { name: '封禁/解封', onClick: () => tableAction(key, 'MEMBER_PROHIBIT', item), perssionCode: 'usermanage/member/fjjf' }
        ];
        return (
            <div className={styles.action}>
                <ButtonPerssion code='usermanage/member/hyxx'>
                    <div className={styles.item} onClick={() => tableAction(key, 'MEMBER_INFO')}>会员信息</div>
                </ButtonPerssion>
                <ButtonPerssion code='usermanage/member/tjmx'>
                    <div className={styles.item} onClick={() => tableAction(key, 'MEMBER_RECOMMEND')}>推荐明细</div>
                </ButtonPerssion>
                <ButtonPerssion code='usermanage/member/szmx'>
                    <div className={styles.item} onClick={() => tableAction(key, 'MEMBER_RANDP')}>收支明细</div>
                </ButtonPerssion>
                {MoreCell(arr)}
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys, selectedRows });
                this.props.selectedRowKeysChange(selectedRowKeys, selectedRows);
            }
        };
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [], selectedRows: [] }, () => {
            this.props.selectedRowKeysChange([], []);
        });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <XTableSelectionCount selectedNum={this.state.selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection()}
                    rowKey='accountId'
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    selectedRowKeys: PropTypes.array,
    selectedRows: PropTypes.array,
    selectedRowKeysChange: PropTypes.func,
    scrollY: PropTypes.string
};
export default XTableHOC(TableContent, 40);

