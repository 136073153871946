import * as Order from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

//订单列表数据 商城订单
export const transcationList = (state = initData, action) => {
    switch (action.type) {
        case Order.SET_TRANSCATION_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
//订单列表数据 服务订单
export const transcationServiceList = (state = initData, action) => {
    switch (action.type) {
        case Order.SET_TRANSCATIONSERVICE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
//费用明细
export const costServiceList = (state = initData, action) => {
    switch (action.type) {
        case Order.SET_COSTSERVICE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
//商户列表数据
export const merchantList = (state = {}, action) => {
    switch (action.type) {
        case Order.SET_MERCHANT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
