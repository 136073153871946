/**
 * 处理
 */
import React from 'react';
import { Modal, Input, Form, message } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './DealModal.module.scss';
import http from '@/assets/api/http';

const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 1 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};
class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
        };
    }

    // className  img  state 
    _handleOk = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                console.log('提交', fieldsValue);
                const { id } = this.props.data;
                const param = {
                    id,
                    ...fieldsValue,
                };
                this.setState({ confirmLoading: true }, () => {
                http('/ideaBack/executeIdeaBack', param, 'POST')
                    .then(() => {
                        message.success('处理成功。');
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                        this.props.closeMode(true);
                        this.props.refresh();
                    }).catch((reject) => {
                        message.error(reject.message);
                        this.setState({ confirmLoading: false });
                    });
                });
            }
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} className="create-form">
                        <Form.Item
                            colon={false}
                            htmlFor='processOpinion'
                            label={<span></span>}
                        >
                            {getFieldDecorator('processOpinion', {
                                // rules: [{ required: true, message: '请输入策略名称' }],
                            })(<TextArea placeholder='请输入' rows={4} style={{width: '100%'}} />)}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );

    }
}

CreateModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
    refresh: PropTypes.func,
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(CreateModal);// 校验

export default connect(null, null)(ForgotProducDetails);
