import * as facilitator from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

const initDetail = {
    applyReason: '',
    areafacilitatorId: 0,
    auditType: '',
    gmtModified: '',
    id: 0,
    imgAttachList: [{}]
};

export const facilitatorAuditList = (state = initData, action) => {
    switch (action.type) {
        case facilitator.SET_FACILITATORAUDIT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

export const facilitatorAuditStatus = (state = [], action) => {
    switch (action.type) {
        case facilitator.SET_FACILITATORAUDIT_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};

export const facilitatorAuditDetail = (state = initDetail, action) => {
    switch (action.type) {
        case facilitator.SET_FACILITATORAUDIT_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

export const facilitatorAuditLog = (state = [], action) => {
    switch (action.type) {
        case facilitator.SET_FACILITATORAUDIT_AUDITLOG:
            return action.payload.result;
        default:
            return state;
    }
};