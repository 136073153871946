/**
 * 销售员档案
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { message } from 'antd';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XInput, XInputNum, XOKButton, XCancelButton, XSelect, showConfirm } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import TableContent from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import model from '@/reducers/usermanage/saler/model';
import http from '@/assets/api/http';

class Saler extends React.Component {

    state = {
        tel: '', // 手机号
        recommendName: '', // 推荐人
        sourceTypeItem: {}, // 来源类型
        stateTypeItem: {}, // 状态类型
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getSourceEnum, getSalesmanStateEnum } = this.props;
        keepSecondNavBreadcrumb();
        getSourceEnum();
        getSalesmanStateEnum();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, tel, recommendName, sourceTypeItem, stateTypeItem } = this.state;
        const query = {
            tel,
            recommendName,
            recommendType: sourceTypeItem.code,
            state: stateTypeItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getSalersList(query); // 发起请求
        KeepAlive.saveCache({ ...this.state, sourceTypeItem });
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            tel: '',
            recommendName: '',
            sourceTypeItem: {},
            stateTypeItem: {},
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 表格操作
    _tableAction = (key, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: '/home/usermanage/saler/detail/' + key
                });
                history.push({
                    pathname: '/home/usermanage/saler/detail/' + key
                });
                break;
            case 'income':
                addOneBreadcrumbPath({
                    title: '收益明细',
                    path: '/home/usermanage/saler/income/' + key
                });
                history.push({
                    pathname: '/home/usermanage/saler/income/' + key
                });
                break;
            case 'pass':
                showConfirm('是否确定 ' + key + ' 审核通过？', '', () => {
                    http('/user/routine/task/toSalesManPlus', { tel: key }).then(() => {
                        message.success('审核成功');
                        this._searchHandle('useCache');
                    });
                });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { tel, recommendName, sourceTypeItem, stateTypeItem } = this.state;
        const { salersList, sourceEnum, salesmanStateEnum } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInputNum
                                label='手机号'
                                placeholder='请输入手机号'
                                bindThis={this}
                                bindName='tel'
                                value={tel}
                                min={0}
                                precision={0}
                                maxLength={11}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='推荐人'
                                placeholder='请输入推荐人'
                                value={recommendName}
                                bindThis={this}
                                bindName='recommendName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='来源类型'
                                placeholder='请选择来源类型'
                                renderData={sourceEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={sourceTypeItem.value}
                                bindThis={this}
                                bindName='sourceTypeItem'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态类型'
                                placeholder='请选择来源类型'
                                renderData={salesmanStateEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={stateTypeItem.value}
                                bindThis={this}
                                bindName='stateTypeItem'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent renderData={salersList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Saler.propTypes = {
    history: PropTypes.object, // router history
    match: PropTypes.object, // router match
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getSalersList: PropTypes.func,
    salersList: PropTypes.object,
    getSourceEnum: PropTypes.func,
    sourceEnum: PropTypes.array,
    getSalesmanStateEnum: PropTypes.func,
    salesmanStateEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
    salersList: state.saler.getSalersList,
    sourceEnum: state.saler.sourceEnum,
    salesmanStateEnum: state.saler.salesmanStateEnum,
});

export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Saler);