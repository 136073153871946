import * as Audit from './actiontypes';

/**
 * 获取'实名人正表格'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 */
export const getAutonymList = (params) => ({
    type: Audit.GET_AUTONYM_LIST,
    payload: params
});
export const getAutonymDetail = (id) => ({
    type: Audit.GET_AUTONYM_DETAIL,
    payload: { id }
});