/**
 * 新建原因
 */
import React from 'react';
import { connect } from 'react-redux';
import { Modal, Radio, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message } from 'antd';
import styles from './SalerModal.module.scss';

const RadioGroup = Radio.Group;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};
class SalerModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        typeInput: '',
        reasonState: 1,
        reason: '',
        reasonLength: 0,
    }

    _handleOk = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields({ force: true }, (err, formDatas) => {
            if (!err) {
                const { reasonType } = this.props;
                const param = {
                    ...formDatas,
                    reasonType
                };
                this.setState({ confirmLoading: true }, () => {
                http('/boss/order/createAfterSaleReason', param, 'POST')
                    .then(() => {
                        message.success('创建成功!');
                        this.props.refresh();
                        this.setState({
                            visible: false,
                            confirmLoading: false
                        });
                        this.props.closeMode();
                    }).catch((reject) => {
                        message.error(reject.message);
                        this.setState({
                            visible: false,
                            confirmLoading: false
                        });
                        this.props.closeMode();
                    });
                });
            }
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    setMsg = ({ target: { value } }) => {
        this.setState({ reasonLength: value.length });
    }
    render() {
        const { visible, confirmLoading, title, reasonLength } = this.state;
        const { reasonType } = this.props;
        const { getFieldDecorator } = this.props.form;
        const _lengthVerify = (rule, value, callback) => {
            if (value && value.length > 22) {
                callback('原因不超过22个字符！');
            } else {
                callback();
            }
        };
        return (
            <Modal
                width={600}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon={false}
                            htmlFor='reason'
                            label={<span>原因名称</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('reason', {
                                rules: [
                                    { required: true, message: '请输入原因' },
                                    {
                                        validator: _lengthVerify
                                    }
                                ],
                            })(
                                <Input placeholder='请输入' style={{ width: 250 }} onChange={this.setMsg} />
                            )}
                            <span style={{ marginLeft: 10 }}>{reasonLength}/22</span>
                        </Form.Item>
                        {reasonType !== 'CANCEL_REASON' && <Form.Item
                            colon={false}
                            htmlFor='responsibility'
                            label={<span>售后责任</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('responsibility', {
                                initialValue: 'MERCHANT_RESPONSIBILITY',
                                rules: [{ required: true, message: '请选择' }],
                            })(
                                <RadioGroup style={{ marginLeft: '12px' }}>
                                    <Radio value={'MERCHANT_RESPONSIBILITY'}>商家责任</Radio>
                                    <Radio value={'MEMBER_RESPONSIBILITY'}>买家责任</Radio>
                                </RadioGroup>
                            )}
                            <p style={{ color: '#b3abab' }}>商家责任，买家最大可退款金额将包含运费</p>
                        </Form.Item>}
                        <Form.Item
                            colon={false}
                            htmlFor='reasonState'
                            label={<span>状态</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('reasonState', {
                                initialValue: 1,
                                rules: [{ required: true, message: '请选择' }],
                            })(
                                <RadioGroup style={{ marginLeft: '12px' }}>
                                    <Radio value={1}>启用</Radio>
                                    <Radio value={0}>禁用</Radio>
                                </RadioGroup>
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

SalerModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    refresh: PropTypes.func,
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    reasonType: PropTypes.string,
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(SalerModal);// 校验
export default connect(null, null)(ForgotProducDetails);