/**
 * 提交审核
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './DeleteModal.module.scss';
import http from '@/assets/api/http';

class DeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
        };
    }

    // className  img  state 
    _handleOk = () => {
        const { data, closeMode } = this.props;
        let url = '/settle/paymentApplication/start';
        let params = { paymentApplicationId: data };
        if (data instanceof Array) {
            url = '/settle/paymentApplication/batchStart';
            params = {
                paymentApplicationIds: data.map(item => {
                    return item.id;
                })
            };
        }
        http(url, params, 'POST').then((res) => {
            message.success(res.message);
            this.setState({
                visible: false,
            });
            closeMode(true);
        }).catch((res = {}) => {
            message.error(res.message);
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title, } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    是否确定提交审核？
                </div>
            </Modal>
        );

    }
}

DeleteModal.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]), // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default DeleteModal;