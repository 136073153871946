/**
 *  【通联财务设置】入口页
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Create from './create';
import Edit from './edit';
import getMemberInfo from './create/components/getMemberInfo';

class AllPay extends React.Component {

    state = {
        type: '', // create财务创建、edit财务编辑
        curStep: 0, // 当前指引步骤数
    }

    componentDidMount() {
        getMemberInfo().then((response) => {
            const { data } = response;
            // identityChecked是否实名，phoneChecked是否绑定手机，signContract是否签署协议
            const { status, phoneChecked, signContract } = data;
            const identityChecked = status == '2' ? true : false;
            if (!identityChecked) {
                // 未实名认证
                this.setState({ type: 'create', curStep: 1 });
            } else if (identityChecked && !phoneChecked) {
                // 未绑定手机
                this.setState({ type: 'create', curStep: 2 });
            } else if (phoneChecked && !signContract) {
                // 未签署协议
                this.setState({ type: 'create', curStep: 2 });
            } else if (signContract) {
                // 已经签署协议
                this.setState({ type: 'edit' });
            }
            this.setState({ show: true });
        });
    }

    _renderContent = () => {
        const { type, curStep } = this.state;
        switch (type) {
            case 'create':
                return <Create history={this.props.history} curStep={curStep} signSuccess={() => { this.setState({ type: 'edit' }); }} />;
            case 'edit':
                return <Edit history={this.props.history} />;
            default:
                return null;
        }
    }

    render() {
        return (
            <>
                {this._renderContent()}
            </>
        );
    }
}

AllPay.propTypes = {
    history: PropTypes.object
};

export default AllPay;