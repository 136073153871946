/**
 * 赠送人气值弹窗
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './GiveXiudouModal.module.scss';
import { XInputNum } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

class GiveXiudouModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        num: ''
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    // 确定
    _OKHandle = () => {
        const { num } = this.state;
        const { data } = this.props;

        if (num < 1 || num > 999999999) {
            message.warning('赠送人气值的范围在1到999999999之间');
            return;
        }

        const query = {
            merchantIds: data.merchantIds,
            amount: num,
        };
        http('/settle/accountBalance/sendStarValue', query, 'POST').then(() => {
            message.success('赠送成功');
            this.setState({ visible: false });
            this.props.closeMode(true);
        }).catch((error) => {
            message.error(error.message);
        });
    }


    render() {
        const { visible, title, confirmLoading, num } = this.state;
        console.log(this.props.data);

        return (
            <Modal
                width={400}
                centered
                title={title}
                visible={visible}
                onOk={this._OKHandle}
                onCancel={this._handleCancel}
                confirmLoading={confirmLoading}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInputNum
                        label='赠送信用值'
                        inputStyle={{ width: '250px' }}
                        min={1}
                        max={999999999}
                        precision={0}
                        value={num}
                        bindThis={this}
                        bindName='num'
                    />
                </div>
            </Modal>
        );
    }
}

GiveXiudouModal.propTypes = {
    title: PropTypes.string,// 弹框标题
    data: PropTypes.object, // 入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};



export default GiveXiudouModal;