import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';


export default class TableContent extends Component {
	state = {
		selectedRowKeys: [],
	};
	static propTypes = {
		tableAction: PropTypes.func,
		paginationChange: PropTypes.func,
		renderData: PropTypes.object,
	};
	_columns = () => [
		{
			title: '流水号',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			width: '12%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '商户',
			dataIndex: 'merchantName',
			key: 'merchantName',
			align: 'center',
			width: '12%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '申请品牌',
			dataIndex: 'soucreName',
			key: 'soucreName',
			align: 'center',
			width: '12%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '申请理由',
			dataIndex: 'applyReason',
			key: 'applyReason',
			align: 'center',
			width: '17%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '审核意见',
			dataIndex: 'auditReason',
			key: 'auditReason',
			align: 'center',
			width: '17%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '状态',
			dataIndex: 'auditState',
			key: 'auditState',
			align: 'center',
			width: '10%',
			render: (text) => (this._stateCell(text))
		},
		{
			title: '更新时间',
			dataIndex: 'gmtModified',
			key: 'gmtModified',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'action',
			key: 'action',
			align: 'center',
			width: '10%',
			render: (id, record) => this._tableAction(record.id, record)
		},
	];

	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		if (record.auditState.code == 'NEW' || record.auditState.code == 'APPLY') {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
					<div className={styles.item} onClick={() => tableAction(key, 'audit', record)}>审核</div>
				</div>
			);
		} else {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
				</div>
			);
		}
	};
	_tableData = (data) => {
		return data ? data : [];
	}
	_stateCell = (text) => {
		return tooltip(text.value);
	}
	render() {
		const { renderData, paginationChange } = this.props;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey={(record) => record.id}
					columns={this._columns()}
					pagination={false}
					dataSource={this._tableData(renderData.dataList)}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}