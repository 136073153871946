import { createModel } from '@/store/tools';

const model = {
    namespace: 'paymentChannel',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getChannelList': T('getChannelList'),
            'getSavaPayment': T('getSavaPayment'),
            'getPaywayList': T('getPaywayList'),
            'getMerchantType': T('getMerchantType'),
            'getMerchantSelect': T('getMerchantSelect'),
        },
        actions: {
            'getChannelList': A('getChannelList'), // 支付通道列表
            'getSavaPayment': A('getSavaPayment'), // 获取支付通道，大额交易初始默认数据
            'getPaywayList': A('getPaywayList'), // 支付通道(通联、银联)
            'getMerchantType': A('getMerchantType'), // 商户类型
            'getMerchantSelect': A('getMerchantSelect'), // 商户名称下拉
        },
        sagas: {
            'getChannelList': S('getChannelList', '/payment/config/listMerchantPaymentAccessTypeConfig'),
            'getSavaPayment': S('getSavaPayment', '/payment/config/queryGlobalAccessTypeConfig'),
            'getPaywayList': S('getPaywayList', '/enum/getPaymentAccessTypeEnum'),
            'getMerchantType': S('getMerchantType', '/enum/MerchantTypeEnum'),
            'getMerchantSelect': S('getMerchantSelect', '/merchant/info/listMerchantIdAndName'),
        },
        reducers: {
            'channelList': R('getChannelList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'savaPayment': R('getSavaPayment', {}),
            'paywayList': R('getPaywayList', []),
            'merchantTypeList': R('getMerchantType', []),
            'merchantSelect': R('getMerchantSelect', { merchantIdAndNames: [] }),
        }
    })
};
export default createModel(model);