/**
 * 【兑换凭证管理】
 */
import React from 'react';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XOKButton, XDatePicker, XCancelButton, XInput, XSelect } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/exchangemanage/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';
import { message } from 'antd';
import Delivery from './modal/Delivery';
import Cancellation from './modal/Cancellation';

class Main extends React.Component {
    state = {
        renderMode: '',
        modeParams: {},
        curTabID: 1,  // 当前显示的Tab页ID
        payStartTime: null,
        payEndTime: null,
        stateItem: {},
        batchKeys: []
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getStatusEnum();
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                addOneBreadcrumbPath({
                    title: '股权兑换详情',
                    path: `/home/storehousefinancialmanage/exchangemanage/servicestockexchange/detail/${id}`
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/exchangemanage/servicestockexchange/detail/${id}`
                });
                break;
            case 'delivery':
                this.setState({ renderMode: 'delivery', modeParams: params });
                break;
            case 'cancellation':
                this.setState({ renderMode: 'cancellation', modeParams: params });
                break;
            case 'batchKeys':
                this.setState({ batchKeys: params });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { serviceTel, id, ownerName, payStartTime, payEndTime, pageNum, pageSize, stateItem } = this.state;
        this.searchCache = {
            serviceTel,
            id,
            ownerName,
            payStartTime,
            payEndTime,
            exchangeState: stateItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getExchangeOrderList(this.searchCache);
        KeepAlive.saveCache({ ...this.searchCache, stateItem }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            serviceTel: '',
            id: '',
            ownerName: '',
            payStartTime: null,
            payEndTime: null,
            pageNum: 1,
            pageSize: 10,
            stateItem: {}
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //生成报表
    _makeReport = () => {
        const { batchKeys, serviceTel, id, ownerName, payStartTime, payEndTime, stateItem } = this.state;
        let queryCriteriaList = [];
        if (serviceTel) {
            queryCriteriaList.push({ condition: '服务商', conditionFiled: 'serviceTel', required: false, type: 'TEXTBOX', useGoal: '查询', value: serviceTel, apiUrl: '' });
        }
        if (id) {
            queryCriteriaList.push({ condition: '兑换凭证', conditionFiled: 'id', required: false, type: 'TEXTBOX', useGoal: '查询', value: id, apiUrl: '' });
        }
        if (ownerName) {
            queryCriteriaList.push({ condition: '兑换人', conditionFiled: 'ownerName', required: false, type: 'TEXTBOX', useGoal: '查询', value: ownerName, apiUrl: '' });
        }
        if (payStartTime) {
            queryCriteriaList.push({ condition: '下单开始时间', conditionFiled: 'payStartTime', required: false, type: 'DATEBOX', useGoal: '时间', value: payStartTime, apiUrl: '' });
        }
        if (payEndTime) {
            queryCriteriaList.push({ condition: '下单结束时间', conditionFiled: 'payEndTime', required: false, type: 'DATEBOX', useGoal: '时间', value: payEndTime, apiUrl: '' });
        }
        if (stateItem.code) {
            queryCriteriaList.push({ condition: '状态', conditionFiled: 'exchangeState', required: false, type: 'SELECTBOX', useGoal: '下拉', value: stateItem, apiUrl: '/enum/ExchangeStateEnum' });
        }
        if (batchKeys.length) {
            queryCriteriaList = [{ condition: '兑换凭证', conditionFiled: 'ids', required: false, type: 'TEXTBOX', useGoal: '批量', value: batchKeys, apiUrl: '' }];
        }
        http('/report/record/addRecord', {
            templateId: '110',
            purpose: '兑换凭证管理',
            queryCriteriaList
        }, 'POST')
            .then(res => {
                message.success(res.message);
                this.props.history.push({
                    pathname: '/home/statementmanage/records'
                });
            })
            .catch(err => {
                message.error(err.message);
            });
    }

    // 渲染弹框
    _renderMode = () => {
        const { renderMode, modeParams } = this.state;
        switch (renderMode) {
            case 'delivery':
                return <Delivery
                    modeParams={modeParams}
                    closeMode={this._closeMode}
                />;
            case 'cancellation':
                return <Cancellation
                    modeParams={modeParams}
                    closeMode={this._closeMode}
                />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ renderMode: '', modeParams: {} }, () => {
            update && this._searchHandle('useCache');
        });
    }


    render() {
        const { curTabID, serviceTel, id, ownerName, payStartTime, payEndTime, stateItem } = this.state;
        const { ExchangeOrderList, statusEnum } = this.props;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div style={{ marginTop: 10 }}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='服务商'
                                    placeholder='请输入服务商'
                                    value={serviceTel}
                                    bindThis={this}
                                    bindName='serviceTel'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='兑换凭证'
                                    placeholder='请输入兑换凭证'
                                    value={id}
                                    bindThis={this}
                                    bindName='id'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='兑换人'
                                    placeholder='请输入兑换人'
                                    value={ownerName}
                                    bindThis={this}
                                    bindName='ownerName'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XDatePicker
                                    allowClear
                                    label='下单时间'
                                    value={payStartTime}
                                    bindThis={this}
                                    bindName='payStartTime'
                                    isFormat
                                />
                                <XDatePicker
                                    allowClear
                                    style={{ marginRight: 20 }}
                                    value={payEndTime}
                                    bindThis={this}
                                    bindName='payEndTime'
                                    isFormat
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='状态'
                                    placeholder='请选择'
                                    renderData={statusEnum}
                                    bindThis={this}
                                    bindName='stateItem'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={stateItem.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: 100 }}
                            label='生成报表'
                            onClick={this._makeReport}
                        />
                    </div>
                    <OrderTable renderData={ExchangeOrderList} tableAction={this._tableAction} paginationChange={this._paginationChange} curTabID={curTabID} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getExchangeOrderList: PropTypes.func,
    getMessageClassifyLists: PropTypes.func,
    ExchangeOrderList: PropTypes.object,
    getStatusEnum: PropTypes.func,
    statusEnum: PropTypes.array
};
const mapStateToProps = (state) => ({
    ExchangeOrderList: state.exchangemanage.ExchangeOrderList,
    statusEnum: state.exchangemanage.statusEnum,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
