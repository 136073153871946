import * as T from './actiontypes';

/**
 * 获取费用名称列表
 */

//财务参数
export const getFinanceArgDetail = () => ({
    type: T.GET_FINANCEARG_DETAIL,
    payload: {}
});

//财务信息列表
export const getFinanceInfoList = ({ taxpayerNo, pageNum, pageSize }) => ({
    type: T.GET_FINANCEINFO_LIST,
    payload: {
        taxpayerNo, pageNum, pageSize
    }
});

// //财务信息详情
// export const getFinanceInfoDetail = () => ({
//     type: T.GET_FINANCEINFO_DETAIL,
//     payload: {}
// });
