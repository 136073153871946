/**
 * 营销活动
 */
import React from 'react';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton, XInput, XTabNav } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import ActivityTime from '../../modal/ActivityTime';
import DeleteModal from '../../modal/DeleteModal';
import Linkcopy from '../../modal/Linkcopy';
import PopconfirmModal from '../../modal/PopconfirmModal';
import ActivityDate from '../../modal/ActivityDate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getMarketingDropDown, getMarketingList, } from '@/reducers/marketingmanage/activities/actions';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

const Mode = {
    EDITE: 'EDITE',//删除
    DELETE: 'DELETE',//详情
    EDIT: 'EDIT',//编辑
};

class Marketing extends React.Component {

    state = {
        selectDataSource: [],
        tableDataSource: [],
        type: {},//活动类型
        push: {},//推送平台
        state: {},//活动状态
        typeName: '',//活动名称  
        currentOpenMode: '', // 当前需要打开的弹窗signTime
        openModeParam: {}, // 当前需要打开的弹窗的入参
        popconParam: {},//启用停用入参
        typeTitle: '',//活动副标题

        tabs: [
            { id: 1, label: '全部活动' },
            { id: 2, label: '未开始' },
            { id: 3, label: '进行中' },
            { id: 4, label: '已结束' },
        ],
        curt: 1,//tab默认
    }

    componentDidMount() {
        const { getMarketingDropDown, keepSecondNavBreadcrumb } = this.props;
        getMarketingDropDown();//分类状态
        keepSecondNavBreadcrumb();
    }

    // Tab导航切换操作回调 
    _tabChangeHandle = (tabID) => {
        this.setState({ type: {}, push: {}, state: {}, typeName: '', typeTitle: '', curt: tabID }, () => {
            this._searchHandle();// 重新渲染更新列表
        });
    }

    // 新建攻略
    _createStudioRoom = () => {
        this.props.updateContent('CREAT');
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { typeName, typeTitle, type, push, state, pageSize, pageNum, curt, } = this.state;
        let curType;//活动时间状态
        if (curt == '1') {
            curType = '';
        } else if (curt == '2') {
            curType = '1';
        } else if (curt == '3') {
            curType = '2';
        } else if (curt == '4') {
            curType = '3';
        }
        this.searchCache = {
            activitySubtitle: typeTitle,//活动副标题
            activityTitle: typeName,//活动名称  
            activityType: type.key,//活动类型  
            targetPlat: push.key,//推送平台  
            state: state.key,//活动状态  
            activityState: curType,//活动时间状态
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getMarketingList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置触发
    _resetHandle = () => {
        this.setState({ type: {}, push: {}, state: {}, typeName: '', typeTitle: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getMarketingList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作  
    _tableAction = (id, type, value) => {
        const { addOneBreadcrumbPath, updateContent, history } = this.props;
        switch (type) {
            case 'detail':
                if (value === 'POINTS_TREASURE') updateContent('TREASURE_DETAIL', id);
                else updateContent(Mode.DELETE, id);
                break;
            case 'stateType':// 开启-停用
                this.setState({
                    currentOpenMode: 'stateType',
                    popconParam: value,
                });
                break;
            case 'signTime':
                {
                    let typeText = {};
                    typeText.type = type;
                    typeText.modifyTime = value;
                    this.setState({
                        currentOpenMode: 'signTime',
                        openModeParam: typeText,
                    });
                }
                break;
            case 'releaseTime':
                {
                    let typeText = {};
                    typeText.type = type;
                    typeText.modifyTime = value;
                    this.setState({
                        currentOpenMode: 'releaseTime',
                        openModeParam: typeText,
                    });
                }
                break;
            case 'edit':
                if (value === 'POINTS_TREASURE') updateContent('EDIT_TREASURE', id);
                else updateContent(Mode.EDIT, id);
                break;
            case 'link'://链接
                this.setState({
                    currentOpenMode: 'link',
                    openModeParam: value,
                });
                break;
            case 'data':
                this.setState({
                    currentOpenMode: 'data',
                });
                break;
            case 'custom':
                addOneBreadcrumbPath({
                    title: '专题活动列表',
                    path: '/home/marketingmanage/appcustom/customlist'
                });
                history.push({
                    pathname: '/home/marketingmanage/appcustom/customlist',
                    state: { category: 'SUBJECT', mode: 'control', formMode: 'subject' }
                });
                break;
            case 'fight'://抢拍记录
                updateContent('fight', value);
                break;
        }
    }

    // 打开弹窗  
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.DELETE:
                return <DeleteModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            case 'stateType':
                return <PopconfirmModal data={this.state.popconParam} visible={true} closeMode={this._closeMode} />;
            case 'signTime':
                return <ActivityTime title='修改报名时间' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'releaseTime':
                return <ActivityTime title='修改发布时间' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'link':
                return <Linkcopy title='链接' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'data':
                return <ActivityDate title={<p>这里是活动名称<span style={{ marginLeft: '20px' }}>多人拼单</span></p>} visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getMarketingList(this.searchCache);
        }
    }

    render() {
        const { type, typeName, push, state, typeTitle, tabs, curt } = this.state;
        const { selectData, tableSource, paginations, } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={`${styles.top_title}`}>
                        <XTabNav renderData={tabs} onChange={this._tabChangeHandle} activedID={curt} />
                    </div>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='活动副标题'
                                placeholder='请输入活动标题'
                                value={typeTitle}
                                bindThis={this}
                                bindName='typeTitle'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='活动名称'
                                placeholder='请输入活动名称'
                                value={typeName}
                                bindThis={this}
                                bindName='typeName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='活动类型'
                                placeholder='请选择活动类型'
                                renderData={selectData.result || []}
                                dataIndex='value'
                                keyIndex='key'
                                value={type.value}
                                bindThis={this}
                                bindName='type'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='推送平台'
                                placeholder='请选择推送平台'
                                renderData={selectData.platform || []}
                                dataIndex='value'
                                keyIndex='key'
                                value={push.value}
                                bindThis={this}
                                bindName='push'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='活动状态'
                                placeholder='请选择活动状态'
                                renderData={selectData.activeState || []}
                                dataIndex='value'
                                keyIndex='key'
                                value={state.value}
                                bindThis={this}
                                bindName='state'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: 'auto' }} label='新建活动' onClick={this._createStudioRoom} />
                    </div>
                    <TableContent key={curt} renderData={tableSource} tableAction={this._tableAction} pagiNation={paginations} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Marketing.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 路由跳转
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getMarketingDropDown: PropTypes.func,// 通过Saga异步请求下拉选项数据方法Action 活动类型、推送平台、活动状态
    getMarketingList: PropTypes.func,// 通过Saga异步请求列表查询方法Action
    tableSource: PropTypes.array, // 表格数据源
    selectData: PropTypes.object, // 分类状态下拉选项 活动类型、推送平台、活动状态
    paginations: PropTypes.object, // 分页数据
};

const mapStateToProps = (state) => {
    return {
        tableSource: state.activities.markingactiviesDate.tableSource,
        selectData: state.activities.markingactiviesDate.selectData,
        paginations: state.activities.markingactiviesDate.paginations,
    };
};
export default connect(mapStateToProps, { getMarketingDropDown, getMarketingList, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Marketing);
