/**
 * 【用户举报】 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';


/**
 * 列表 Saga
 */
export const listUserReportSource = function* () {
    yield takeEvery(T.GET_USERLIDT_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/user/userReport/listUserReport', action.payload, 'POST');
            yield put({ type: T.SET_USERLIDT_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 举报状态数据源 Saga
 */

export const reportState = function* () {
    yield takeEvery(T.GET_REPORTSTATE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/enum/ReportStateEnum', action.payload, 'POST');
            yield put({ type: T.SET_REPORTSTATE_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 用户举报禁播，封禁类型和封禁理由数据源(saga)
 */

export const reportStop = function* () {
    yield takeEvery(T.GET_REPORTSTOP_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        let pages = {//封禁理由分页
            pageSize: 50,
            pageNum: 1,
        };
        try {
            let response = yield http('/enum/BlackTypeEnum', action.payload, 'POST');
            let bannedReason = yield http('/show/reporttype/listReportTypes', pages, 'POST');//封禁理由
            response.bannedReason = bannedReason.result.dataList;
            // debugger;
            yield put({ type: T.SET_REPORTSTOP_DATA, payload: response });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 用户举报详情 Saga
 */
export const userReportDetails = function* () {
    yield takeEvery(T.GET_REPORTDETAIL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http(`/user/userReport/getUserReportInfo/${action.payload.id}`, action.payload, 'POST');
            yield put({ type: T.SET_REPORTDETAIL_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
