/**
 *  审核管理- 审核
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const Mode = {
    AUDIT_DETAIL: 'AUDIT_DETAIL',
    AUDIT_CONTENT: 'AUDIT_CONTENT',
    RATE_DETAIL: 'RATE_DETAIL',
    RATE_AUDIT: 'RATE_AUDIT'
};

class ProductAudit extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case Mode.AUDIT_DETAIL:
                addOneBreadcrumbPath({
                    title: '商品审核详情',
                    path: '/home/auditmanage/productaudit/productdetail'
                });
                history.push({
                    pathname: '/home/auditmanage/productaudit/productdetail/' + value
                });
                break;
            case Mode.AUDIT_CONTENT:
                addOneBreadcrumbPath({
                    title: '商品审核',
                    path: '/home/auditmanage/productaudit/productaudit'
                });
                history.push({
                    pathname: '/home/auditmanage/productaudit/productaudit/' + value
                });
                break;
            case Mode.RATE_DETAIL:
                addOneBreadcrumbPath({
                    title: '费率审核详情',
                    path: '/home/auditmanage/productaudit/ratedetail'
                });
                history.push({
                    pathname: '/home/auditmanage/productaudit/ratedetail/' + value
                });
                break;
            case Mode.RATE_AUDIT:
                addOneBreadcrumbPath({
                    title: '费率审核',
                    path: '/home/auditmanage/productaudit/rateaudit'
                });
                history.push({
                    pathname: '/home/auditmanage/productaudit/rateaudit/' + value
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} match={this.props.match} />
        );
    }
}

ProductAudit.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(ProductAudit);