import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { Modal, message, Typography, Table, InputNumber, Checkbox, DatePicker } from 'antd';
import { tooltipStyle, tooltip, } from '@/components/TableCell';
import model from '@/reducers/accruedService/serviceList/model';
import moment from 'moment';
import BigNumber from 'bignumber.js';

const { Text } = Typography;

class FeeRateSet extends Component {

    static propTypes = {
        data: PropTypes.object,
        closeMode: PropTypes.func,
        getFeeRateSetList: PropTypes.func,
        feeRateSetList: PropTypes.object,
        setFeeRate: PropTypes.func,
        setGiveQuota: PropTypes.func
    }

    state = {
        loading: false,
        isAccordDate: false,
        currentDate: '',
    }

    componentDidMount() {
        const { getFeeRateSetList, data: { earningsServiceId = '' } = {} } = this.props;
        getFeeRateSetList({ earningsServiceId });
    }


    //合并行
    _renderType = (index, type) => {
        const { currentDate } = this.state;
        if (type === 'SERVICE_APP') {
            let serviceApp = {
                children: '服务商推广',
                props: { rowSpan: 0 },
            };
            if (index === 0) serviceApp.props.rowSpan = 3;
            return serviceApp;
        } else if (type === 'REGION_COMPANY') {
            let regionCompany = {
                children: '区域公司推广',
                props: { rowSpan: 0 },
            };
            if (index === 0) regionCompany.props.rowSpan = 2;
            return regionCompany;
        } else if (type === 'OP_CENTER') {
            let opCenter = {
                children: '运营中心推广',
                props: { rowSpan: 0 },
            };
            if (index === 0) opCenter.props.rowSpan = 1;
            return opCenter;
        } else if (type === 'TALKER') {
            let talker = {
                children: '说客推广',
                props: { rowSpan: 0 },
            };
            if (index === 0) talker.props.rowSpan = 3;
            return talker;
        } else if (type === 'custom') {
            let custom = {
                children: <div>
                    <Text>截止</Text>
                    <DatePicker
                        style={{ margin: '0 10px' }}
                        value={currentDate ? moment(currentDate, 'YYYY-MM-DD') : ''}
                        onChange={date => this.setState({ currentDate: date.format('YYYY-MM-DD') })}
                    />
                    <Text>前成立的区域公司推广</Text>
                </div>,
                props: { rowSpan: 0 },
            };
            if (index === 0) custom.props.rowSpan = 2;
            if (index === 2) {
                custom = {
                    children: <Text>前成立的区域公司推广</Text>,
                    props: { rowSpan: 2 },
                };
            }
            return custom;
        }
    }

    //设置费用
    _renderRate = (item) => {
        return <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <InputNumber
                key={item.id}
                value={item.earningsRatio}
                precision={1}
                placeholder='请输入'
                min={0}
                max={100}
                onChange={value => this.props.setFeeRate({ id: item.id, earningsRatio: value })}
            />
        </div>;
    }

    //表头
    _columns = (type) => {
        return [
            {
                title: '业务类型',
                dataIndex: 'recommendTypeName',
                key: 'recommendTypeName',
                width: '40%',
                align: 'center',
                onCell: tooltipStyle,
                render: (value, row, index) => this._renderType(index, type)
            },
            {
                title: '收益对象',
                dataIndex: 'earningsObject',
                key: 'earningsObject',
                width: '20%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '费用名称',
                dataIndex: 'costName',
                key: 'costName',
                width: '20%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '比率(%)',
                dataIndex: 'earningsRatioValue',
                key: 'earningsRatioValue',
                width: '20%',
                align: 'center',
                render: (value, row) => this._renderRate(row, type)
            }
        ];
    }

    //提交
    _saveData = () => {
        const { feeRateSetList: { earningsServiceItemList = [], giveSalesmanByServiceProvider, giveSalesmanByTalker } = {}, data: { earningsServiceId = '' } = {} } = this.props;
        let addUp1 = 0;
        let addUp2 = 0;
        let addUp3 = 0;
        let addUp4 = 0;
        earningsServiceItemList.forEach(element => {
            if (element.recommendType === 'SERVICE_APP') {
                addUp1 = new BigNumber(addUp1 || 0).plus(element.earningsRatio || 0).toNumber();
            } else if (element.recommendType === 'REGION_COMPANY') {
                addUp2 = new BigNumber(addUp2 || 0).plus(element.earningsRatio || 0).toNumber();
            } else if (element.recommendType === 'OP_CENTER') {
                addUp3 = new BigNumber(addUp3 || 0).plus(element.earningsRatio || 0).toNumber();
            } else if (element.recommendType === 'TALKER') {
                addUp4 = new BigNumber(addUp4 || 0).plus(element.earningsRatio || 0).toNumber();
            }
        });
        if (addUp1 > 100) return message.warn('服务商推广比例之和不能超过100%');
        if (addUp2 > 100) return message.warn('区域公司推广比例之和不能超过100%');
        if (addUp3 > 100) return message.warn('运营中心推广比例之和不能超过100%');
        if (addUp4 > 100) return message.warn('说客推广比例之和不能超过100%');
        this.setState({ loading: true }, () => {
            const updateEarningsServiceItemReqs = earningsServiceItemList.map(item => {
                return { earningsRatio: item.earningsRatio * 10, id: item.id };
            });
            http('/earningsServiceItem/batchUpdate', { earningsServiceId, updateEarningsServiceItemReqs, giveSalesmanByServiceProvider, giveSalesmanByTalker }, 'POST')
                .then(() => {
                    message.success('费用比例设置成功。');
                    this.setState({ loading: false });
                    this.props.closeMode(true);
                })
                .catch(err => {
                    this.setState({ loading: false });
                    message.error(err.message);
                });
        });
    }

    checkNode = <div style={{ padding: '10px' }}>
        <Checkbox
            checked={this.state.isAccordDate}
            onChange={e => this.setState({ isAccordDate: e.target.checked })}
        >按区域公司的成立日期（以审核通过时间为准）分设技术推广收益，修改前已产生的订单不会进行重算</Checkbox>
    </div>;

    _serviceFooter = (field) => {
        const { setGiveQuota, feeRateSetList } = this.props;
        return <div style={{ width: '100%', textAlign: 'right' }}>
            <Typography.Text>免费赠送开通</Typography.Text>
            <InputNumber
                style={{ margin: '0 10px' }}
                placeholder='请输入'
                mix={0}
                max={99}
                value={feeRateSetList[field]}
                onChange={value => {
                    let nv = value;
                    if (value < 0) nv = 0;
                    if (value > 99) nv = 99;
                    setGiveQuota({ [field]: nv });
                }}
            />
            <Typography.Text>个优仓名额</Typography.Text>
        </div>;
    }

    render() {
        const { closeMode, feeRateSetList: {
            earningsServiceItemList = []
        } = {}, data: { serviceName = '' } = {} } = this.props;
        const { loading, isAccordDate } = this.state;

        return <Modal
            width={1000}
            centered
            title='费用比例设置'
            visible={true}
            confirmLoading={loading}
            onOk={this._saveData}
            onCancel={() => closeMode()}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <div>
                <div><Text>服务名称：{serviceName}</Text></div>
                <div>
                    <Text type='secondary'>说明：服务商、区域公司、运营中心推广服务系统收益；收益金额=服务商品销售价*比率（%）</Text>
                </div>
                <Table
                    style={{ marginTop: '20px' }}
                    rowKey={(record) => record.id}
                    columns={this._columns('SERVICE_APP')}
                    dataSource={earningsServiceItemList.slice(0, 3)}
                    pagination={false}
                    footer={() => this._serviceFooter('giveSalesmanByServiceProvider')}
                />
                {
                    isAccordDate ?
                        <Table
                            showHeader={false}
                            rowKey={(record) => record.id} custom
                            columns={this._columns('custom')}
                            dataSource={earningsServiceItemList.slice(3, 5)}
                            pagination={false}
                        /> :
                        <Table
                            style={{ marginTop: '20px' }}
                            showHeader={false}
                            rowKey={(record) => record.id}
                            columns={this._columns('REGION_COMPANY')}
                            dataSource={earningsServiceItemList.slice(3, 5)}
                            pagination={false}
                        />
                }
                <Table
                    style={{ marginTop: '20px' }}
                    showHeader={false}
                    rowKey={(record) => record.id}
                    columns={this._columns('OP_CENTER')}
                    dataSource={earningsServiceItemList.slice(5, 6)}
                    pagination={false}
                />
                <Table
                    style={{ marginTop: '20px' }}
                    showHeader={false}
                    rowKey={(record) => record.id}
                    columns={this._columns('TALKER')}
                    dataSource={earningsServiceItemList.slice(6, 9)}
                    pagination={false}
                    footer={() => this._serviceFooter('giveSalesmanByTalker')}
                />
            </div>
        </Modal>;
    }
}
export default connect(state => ({
    feeRateSetList: state.serviceAccrued.feeRateSetList,
}), { ...model.actions })(FeeRateSet);