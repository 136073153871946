import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';


const TableContent = ({
    renderData: { resultTotal, pageSize, pageNum, dataList },
    paginationChange
}) => {
    const takeColumns = () => ([
        {
            title: '日期',
            dataIndex: 'date',
            key: 'date',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '仓库名称',
            dataIndex: 'warehouseName',
            key: 'warehouseName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '售后订单量',
            dataIndex: 'afterSaleOrderNum',
            key: 'afterSaleOrderNum',
            width: '10%',
            align: 'center',
            render: tooltip
        },
        {
            title: '退款订单量',
            dataIndex: 'onlyRefundOrderNum',
            key: 'onlyRefundOrderNum',
            width: '10%',
            align: 'center',
            render: tooltip
        },
        {
            title: '退货退款订单量',
            dataIndex: 'refundOrderNum',
            key: 'refundOrderNum',
            width: '10%',
            align: 'center',
            render: tooltip
        },
        {
            title: '换货订单量',
            dataIndex: 'exchangeGoodsOrderNum',
            key: 'exchangeGoodsOrderNum',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
    ]);

    const formatHandle = (text) => {
        return (
            <div>{text.code}</div>
        );
    };
    
    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                columns={takeColumns()}
                dataSource={dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};


TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;