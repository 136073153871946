import { combineReducers } from 'redux';
import { getRolesList, getRolesDetail, getEmplsByRole, getMenuByRole, getAdminEmpl, getUnEmpl, getRoleSelect, getDownEmpls, getListRoleByEmpl } from './reducer';

export default combineReducers({
	getRolesList, // 获取角色列表
	getRolesDetail, // 获取角色详情
	getAdminEmpl, // 获取本公司超级管理员
	getUnEmpl, // 获取本公司未分类角色员工
	getEmplsByRole, // 获取平台、运营中心、区域中心、商户某个角色下面得所有员工
	getMenuByRole, // 获取某角色权限的菜单
	getRoleSelect, // 获取角色下拉列表(复制角色权限以及权限分配时均可用)
	getDownEmpls, // 获取员工下拉列表（在新建员工角色时用到）
	getListRoleByEmpl, // 根据员工获取角色列表（对已关联得角色做记号）
});

