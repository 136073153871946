import { combineReducers } from 'redux';
import {
	productAuditData,
	productAuditRateList,
	productAuditDetail,
	productAuditRateDetail,
	productAuditLog,
	productRateAuditLog,
	productStatus,
	productUsername,
	productBusinessType,
	productLabelSele
} from './reducer';

export default combineReducers({
	productAuditData,
	productAuditRateList,
	productAuditDetail,
	productAuditRateDetail,
	productAuditLog,
	productStatus,
	productUsername,
	productBusinessType,
	productLabelSele,
	productRateAuditLog
});