/**
 *  图文管理 - 图文列表
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

export const Mode = {
    CREATE: 'create',
    SORT: 'sort',
    UNABLE: 'unable',
    PRODUCT: 'product',
    SELECTED: 'select',
    EDIT: 'edit',
    DETAIL: 'detail',
    STOP: 'stop',
    DELETE: 'delete',
    COPY: 'copy'
};

class ChannelList extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        let path = '';
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            // 图文 - 创建、编辑、详情
            case Mode.CREATE:
            case Mode.EDIT:
            case Mode.DETAIL:
                path = `/home/graphicsmanage/list/${mode}/${value}`;
                addOneBreadcrumbPath({ title: '创建图文', path });
                history.push({ pathname: path });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} match={this.props.match} />
        );
    }
}

ChannelList.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(ChannelList);