import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker, XInputNum } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import CreatePay from '../../modal/CreatePay';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getOperationInvoiceList, getOperationInvoiceStatus, getOperationInvoiceName } from '@/reducers/auditmanage/operationInvoice/actions';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: [], // 当前需要打开的弹窗的入参
            batch: [],
            batchRows: [],
            statusSele: [
                {
                    code: 'INIT',
                    value: '新建'
                },
                {
                    code: 'FIRST_CONFIRM_SUCCESS',
                    value: '初审成功'
                },
                {
                    code: 'FIRST_CONFIRM_FAIL',
                    value: '初审失败'
                },
                {
                    code: 'SECOND_CONFIRM_SUCCESS',
                    value: '复审成功'
                },
                {
                    code: 'SECOND_CONFIRM_FAIL',
                    value: '复审失败'
                }
            ],
            companyName: {},
            settlementId: '',
            companyStatus: {},
            startTime: null,
            endTime: null
        };
    }

    componentDidMount() {
        const { getOperationInvoiceStatus, getOperationInvoiceName } = this.props;
        getOperationInvoiceStatus();
        getOperationInvoiceName();
    }

    //搜索条件改变
    _formChange = (type, value) => {
        this.setState({ isCheck: true });
        switch (type) {
            case 'companyName':
                this.setState({ companyName: value });
                break;
            case 'settlementId':
                this.setState({ settlementId: value });
                break;
            case 'companyStatus':
                this.setState({ companyStatus: value });
                break;
            case 'startTime':
                break;
            case 'endTime':
                break;
        }
    }

    // 查询
    _searchHandle = (useCache) => {
        const { companyName, settlementId, companyStatus, startTime, endTime, pageNum, pageSize } = this.state;
        this.searchCache = {
            companyId: companyName.id, settlementId, status: companyStatus.code, startDate: startTime, endDate: endTime,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getOperationInvoiceList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置
    _resetHandle = () => {
        this.setState({ companyName: {}, settlementId: '', startTime: null, endTime: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //新建批量付款
    _createpay = () => {
        const { batch } = this.state;
        if (batch.length === 0) {
            message.error('批量新建时至少选择一个！');
            this.setState({ currentOpenMode: '' });
        } else {
            this.setState({ currentOpenMode: 'BATCHCREATE' });
        }
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'detail':
                this.props.updateContent('DETAIL', id);
                break;
            case 'firAudit':
                this.props.updateContent('FIRAUDIT', id);
                break;
            case 'secAudit':
                this.props.updateContent('SECAUDIT', id);
                break;
            case 'createPay':
                this.setState({ currentOpenMode: 'SINGLECREATE', batch: [id] });
                break;
            case 'batch':
                this.setState({ batch: params });
                break;
            case 'batchRows':
                this.setState({ batchRows: params });
                break;
        }
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        const { companyName, settlementId, companyStatus, startTime, endTime } = this.state;
        this.props.getOperationInvoiceList({ companyId: companyName.id, settlementId, status: companyStatus.code, startDate: startTime, endDate: endTime, pageSize, pageNum });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    //打开弹窗
    _renderMode = () => {
        const { batchRows, batch } = this.state;
        switch (this.state.currentOpenMode) {
            case 'SINGLECREATE':
                return <CreatePay
                    title='新建付款'
                    data={{ type: 'single', batch }}
                    tableAction={this._tableAction}
                    visible={true}
                    closeMode={this._closeMode} />;
            case 'BATCHCREATE':
                if (batchRows.filter(item => item.status !== 'SECOND_CONFIRM_SUCCESS').length !== 0) {
                    message.error('只能新建财务审核通过的付款！');
                    this.setState({ currentOpenMode: '' });
                } else {
                    return <CreatePay
                        title='批量新建付款'
                        data={{ type: 'batch', batch }}
                        tableAction={this._tableAction}
                        visible={true}
                        closeMode={this._closeMode} />;
                }
                break;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        const { companyName, settlementId, companyStatus, startTime, endTime, pageSize, pageNum } = this.state;
        update ?
            this.props.getOperationInvoiceList({
                companyId: companyName.id,
                settlementId,
                status: companyStatus.code,
                startDate: startTime,
                endDate: endTime,
                pageSize, pageNum
            })
            : null;
        this.setState({ currentOpenMode: '' });
    }

    render() {
        const { operationInvoiceList, operationInvoiceStatus, operationInvoiceName } = this.props;
        const { companyName, companyStatus, settlementId, startTime, endTime } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XSelect
                                label='公司名称'
                                placeholder='请选择'
                                renderData={operationInvoiceName}
                                onChange={(value) => this._formChange('companyName', value)}
                                dataIndex='companyName'
                                keyIndex='id'
                                value={companyName.companyName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInputNum
                                label='开票单号'
                                placeholder='请输入'
                                onChange={(value) => this._formChange('settlementId', value)}
                                value={settlementId}
                                min={0}
                                precision={0}
                                maxLength={16}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='审核状态'
                                placeholder='请选择'
                                renderData={operationInvoiceStatus}
                                onChange={(value) => this._formChange('companyStatus', value)}
                                dataIndex='value'
                                keyIndex='code'
                                value={companyStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='新建日期'
                                onChange={(value) => this._formChange('startTime', value)}
                                bindThis={this}
                                bindName='startTime'
                                value={startTime}
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                onChange={(value) => this._formChange('endTime', value)}
                                bindThis={this}
                                bindName='endTime'
                                value={endTime}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    {/* <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }} onClick={this._createpay} label='新建批量付款' />
                    </div> */}
                    <TableContent paginationChange={this._paginationChange} renderData={operationInvoiceList} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }

}

Main.propTypes = {
    updateContent: PropTypes.func,
    getOperationInvoiceList: PropTypes.func,
    getOperationInvoiceStatus: PropTypes.func,
    getOperationInvoiceName: PropTypes.func,
    operationInvoiceList: PropTypes.object,
    operationInvoiceName: PropTypes.array,
    operationInvoiceStatus: PropTypes.array,
};

const mapStateToProps = (state) => ({
    operationInvoiceList: state.operationInvoice.operationInvoiceList,
    operationInvoiceName: state.operationInvoice.operationInvoiceName,
    operationInvoiceStatus: state.operationInvoice.operationInvoiceStatus
});

export default connect(mapStateToProps, { getOperationInvoiceList, getOperationInvoiceStatus, getOperationInvoiceName })(Main);