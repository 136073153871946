import React, { Component } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import TableContent from './components/TableContent';
import model from '@/reducers/usermanage/blacklist/model';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { message } from 'antd';
class Intercept extends Component {

    static defaultProps = {
        registerIntercepts: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
        discernTypes: []
    }

    static propTypes = {
        history: PropTypes.object,
        addOneBreadcrumbPath: PropTypes.func,
        keepSecondNavBreadcrumb: PropTypes.func,
        getRegisterIntercepts: PropTypes.func,
        registerIntercepts: PropTypes.object,
        getDiscernTypes: PropTypes.func,
        discernTypes: PropTypes.array
    }

    state = {
        tel: ''
    };

    componentDidMount() {
        this.props.getDiscernTypes();
        this.props.keepSecondNavBreadcrumb();
    }

    _searchHandle = (useCache) => {
        const { pageSize, pageNum, tel } = this.state;
        this.searchCache = {
            tel,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getRegisterIntercepts(this.searchCache); // 发起请求
        KeepAlive.saveCache(this.searchCache); // 【第3步，缓存查询状态】
    }

    _resetHandle = () => {
        this.setState({ tel: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    _request = (url, data, tip) => {
        http(url, data, 'POST')
            .then(() => {
                message.success(tip);
                this._searchHandle('useCache');
            })
            .catch(err => message.error(err.message));
    }

    render() {
        const { tel } = this.state;
        return (
            <KeepAlive id='intercept' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer} style={{ marginTop: '10px' }}>
                    <div className={styles.row1}>
                        <XInput
                            inputStyle={{ width: '180px' }}
                            label='手机号码'
                            placeholder='请输入手机号码'
                            value={tel}
                            bindThis={this}
                            bindName='tel'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <TableContent
                        request={this._request}
                        renderData={this.props.registerIntercepts}
                        paginationChange={this._paginationChange}
                        discernTypes={this.props.discernTypes}
                    />
                </div>
            </KeepAlive>
        );
    }
}
export default connect(state => ({
    registerIntercepts: state.blacklist.registerIntercepts,
    discernTypes: state.blacklist.discernTypes
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(Intercept);