import { createModel } from '@/store/tools';

const houses = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };
const houseDetail = { installationList: [], goodsImagesList: [] };

const model = {
    namespace: 'houseresourcemanage',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getHouseTypeSele': T('getHouseTypeSele'),
            'getMerchantSele': T('getMerchantSele'),
            'getHouseStateSele': T('getHouseStateSele'),
            'getHouseResources': T('getHouseResources'),
            'getHouseDetail': T('getHouseDetail'),
        },
        actions: {
            'getHouseTypeSele': A('getHouseTypeSele'),
            'getMerchantSele': A('getMerchantSele'),
            'getHouseStateSele': A('getHouseStateSele'),
            'getHouseResources': A('getHouseResources'),
            'getHouseDetail': A('getHouseDetail'),
        },
        sagas: {
            'getHouseTypeSele': S('getHouseTypeSele', '/goods/category/findHotelCategoryInfoListItem'),
            'getMerchantSele': S('getMerchantSele', '/merchant/info/listMerchantIdAndName'),
            'getHouseStateSele': S('getHouseStateSele', '/enum/HotelGoodsStateEnum'),
            'getHouseResources': S('getHouseResources', '/hotel/houseList'),
            'getHouseDetail': S('getHouseDetail', '/hotel/houseDtl', 'POST', 'id'),
        },
        reducers: {
            'houseTypeSele': R('getHouseTypeSele', []),
            'merchantSele': R('getMerchantSele', { merchantIdAndNames: [] }),
            'houseStateSele': R('getHouseStateSele', []),
            'houseResources': R('getHouseResources', houses),
            'houseDetail': R('getHouseDetail', houseDetail),
        }
    })
};
export default createModel(model);