/**
 * 商品列表禁用弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, Alert, Input, message } from 'antd';
import styles from './Ban.module.scss';

const { TextArea } = Input;

class Ban extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        reason: '',
    }
    // 点击确认
    _handleOk = () => {
        const { data } = this.props;
        const { reason } = this.state;
        // debugger;
        // const goodsIds = [];
        // goodsIds.push(data);
        this.setState({ confirmLoading: true }, () => {
            http('/goods/goods/disableGoods', { goodsId: data, violationReason: reason }, 'POST').then(() => {
                message.success('禁用成功');
                this.setState({
                    visible: false,
                    confirmLoading: false
                });
                this.props.closeMode(true);
            }).catch((error) => {
                message.error(error.message);
                this.setState({ confirmLoading: false });
            });
        });
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _labelNameChange = (value) => {
        this.setState({ labelName: value });
    }

    _reasonChange = (e) => {
        this.setState({ reason: e.target.value });
    }

    render() {
        const { visible, confirmLoading, reason } = this.state;

        return (
            <Modal
                width={470}
                centered
                title='商品禁用'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >

                <div className={styles.container}>
                    <Alert
                        banner="true"
                        message="警告"
                        description="禁用后前端APP无法搜索到该商户的商品。"
                        type="warning"
                        showIcon
                    />
                    <div className={styles.reason}>
                        <div className={styles.label}>原因</div>
                        <TextArea
                            style={{ width: '360px', marginLeft: '20px' }}
                            placeholder='请输入原因'
                            value={reason}
                            onChange={this._reasonChange}
                            autosize={{ minRows: 4, maxRows: 6 }}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

Ban.propTypes = {
    data: PropTypes.number, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

export default Ban;