import { combineReducers } from 'redux';
import { getMenuList, getAllMenuList, getMenuDetail, getMenuSelect, getSecondMenuSelect, getTabSelect, getAffType } from './reducer';

export default combineReducers({
	getMenuList, // 获取一级、二级菜单列表
	getAllMenuList, // 获取全部菜单列表
	getMenuDetail, // 获取菜单详情
	getMenuSelect, // 获取一级菜单下拉列表
	getSecondMenuSelect, // 获取二级菜单下拉列表
	getTabSelect, // 获取Tab菜单下拉列表
	getAffType, // 获取总控菜单终端类型
});

