import { createModel } from '@/store/tools';

const model = {
    namespace: 'convertiblepro',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getConversionList': T('getConversionList'),
        },
        actions: {
            'getConversionList': A('getConversionList'), // 人气值兑换魅力值比率列表数据
        },
        sagas: {
            'getConversionList': S('getConversionList', '/settle/starlightExchange/listRatioHistory'),
        },
        reducers: {
            'conversionList': R('getConversionList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
        }
    })
};
export default createModel(model);