import React from 'react';
import styles from './OrderSearch.module.scss';
import PropTypes from 'prop-types';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker, XInputNum, XScrollSelect } from '@/components/xqxc_ui';
import ConsumeTypes from '@/vcomps/ConsumeTypes';
import { getMerchantList } from '@/reducers/ordermanage/transcation/actions';
import { connect } from 'react-redux';
import {
    getProductOrderType, getProductOrderStatus, getProductOrderFlagEnum, getDistributionFlagEnum, getPaymentAccessTypeEnum, getPaymentTypeEnum, getOrderSourceEnum
} from '@/reducers/ordermanage/productOrder/actions';
import model from '@/reducers/ordermanage/productOrder/model';
import http from '@/assets/api/http';
import { isRE } from '@/assets/js/authType';
import { regFenToYuan } from '@/util/money';

const { getSettleTypeEnum } = model.actions;

// const orderStateSelect = [{ id: 1, name: '待付款' }, { id: 2, name: '待发款' }];
// const orderTypeSelect = [{ id: 1, name: '普通订单' }, { id: 2, name: '报税订单' }];
// const invoiceSelect = [{ id: 1, name: '是' }, { id: 2, name: '否' }];
const getServiceEnum = async (params = {}) => {
    return http('/service/provider/listServiceProviderByAreaCompanyId', { ...params, pageSize: 10000000 })
        .then(res => { return res.result.dataList; });
};

class OrderSearch extends React.Component {

    state = {
        expandBoolean: false, // 是否展开
        telephone: '', // 会员手机
        orderCode: '', // 订单编号
        gmtCreateF: null, // 下单时间开始日期
        gmtCreateT: null, // 下单结束日期
        orderState: {}, // 订单状态
        orderType: {}, // 订单类型
        invoice: {}, // 是否开票
        isDistribution: { code: 2, value: '全部' }, // 是否批发
        ismerchantSponsorFlag: { code: 2, value: '全部' }, // 是否引流
        payType: {}, // 支付方式
        payAccessType: {}, // 支付通道
        productName: '', // 商品名称
        productCode: '', // 商品货号
        userState: {}, // 商户
        consumeType: {},
        expressNo: '',
        // payItemId: '', // 支付流水号
        sourceItem: {},//订单来源
        finishedTimeF: null,
        finishedTimeT: null, settleTypeItme: {},
        centerItem: {}, serviceItem: {}, serviceEnum: []
    }

    componentDidMount() {
        this.props.getProductOrderType();
        this.props.getProductOrderStatus();
        this.props.getProductOrderFlagEnum();
        // 商户
        this.props.getMerchantList();
        // 是否批发
        this.props.getDistributionFlagEnum();
        // 支付通道
        this.props.getPaymentAccessTypeEnum();
        // 支付方式
        this.props.getPaymentTypeEnum();
        //订单来源
        this.props.getOrderSourceEnum();
        //结算设置
        this.props.getSettleTypeEnum();
        isRE() && (async () => {
            const serviceEnum = await getServiceEnum({ pageNum: 1 });
            this.setState({ serviceEnum });
        })();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        console.log(newProps.searchState.param);
        this.setState(newProps.searchState.param);
    }

    _renderOrderStateSelect = (orderState) => {
        const { productOrderStatus } = this.props;
        if (this.props.orderState == 1) {
            return (
                <XSelect
                    label='订单状态'
                    placeholder='请选择订单状态'
                    renderData={[...productOrderStatus]}
                    dataIndex='value'
                    keyIndex='code'
                    value={orderState.value}
                    showSearch={true}
                    bindThis={this}
                    bindName='orderState'
                />
            );
        } else {
            return null;
        }
    }
    // 展开或收缩搜索条件
    _expandChangeHandle = (expandBoolean) => {
        this.setState({ expandBoolean });
    }
    // 查询
    _searchHandle = () => {
        const { expressNo, telephone, orderCode, orderState, orderType, invoice, isDistribution, payType, payAccessType, ismerchantSponsorFlag, productName, productCode,
            userState, consumeType, gmtCreateF, gmtCreateT, sourceItem, finishedTimeF, finishedTimeT, settleTypeItme, centerItem, serviceItem } = this.state;
        this.searchCache = {
            telephone,
            orderCode,
            orderState,
            orderType,
            invoice,
            productName,
            productCode,
            userState,
            consumeType,
            gmtCreateF,
            gmtCreateT,
            expressNo,
            isDistribution,
            ismerchantSponsorFlag,
            payType,
            payAccessType,
            sourceItem,
            finishedTimeF,
            finishedTimeT, settleTypeItme, centerItem, serviceItem
        };
        this.props.searchOrder({
            accountTel: telephone, gmtCreateF, gmtCreateT, orderId: orderCode,
            goodsName: productName,
            isInvoice: invoice.code ? invoice.code : '',
            isDistribution: isDistribution.code ? isDistribution.code : '',
            sourceChannel: sourceItem.code ? sourceItem.code : '',
            merchantSponsorFlag: ismerchantSponsorFlag.code ? ismerchantSponsorFlag.code == 2 ? '' : ismerchantSponsorFlag.code : '',
            payAccessType: payAccessType.code,
            payType: payType.code,
            itemNo: productCode, orderType: orderType.code, statusList: orderState.code ? [orderState.code] : [],
            sellerId: userState.merchantId, consumeType: consumeType.code, expressNo,
            finishedTimeF, finishedTimeT, rateMode: settleTypeItme.code, areaCompanyId: centerItem.id, serviceId: serviceItem.id
        }, this.searchCache);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            telephone: '',
            orderCode: '',
            gmtCreateF: null,
            gmtCreateT: null,
            orderState: {},
            orderType: {},
            invoice: {},
            isDistribution: { code: 2, value: '全部' },
            ismerchantSponsorFlag: { code: 2, value: '全部' },
            payType: {},
            payAccessType: {},
            productName: '',
            productCode: '',
            pageNum: 1,
            pageSize: 50,
            userState: {},
            consumeType: {},
            sellerId: '',
            expressNo: '',
            sourceItem: {},
            finishedTimeF: null,
            finishedTimeT: null, settleTypeItme: {}, centerItem: {}, serviceItem: {}, serviceEnum: []
        });
        this.searchCache = {
            telephone: '',
            orderCode: '',
            gmtCreateF: null,
            gmtCreateT: null,
            orderState: {},
            orderType: {},
            invoice: {},
            isDistribution: { code: 2, value: '全部' },
            ismerchantSponsorFlag: { code: 2, value: '全部' },
            payType: {},
            payAccessType: {},
            productName: '',
            productCode: '',
            pageNum: 1,
            pageSize: 50,
            userState: {},
            consumeType: {},
            sellerId: '',
            expressNo: '',
            sourceItem: {},
            finishedTimeF: null,
            finishedTimeT: null, settleTypeItme: {}, centerItem: {}, serviceItem: {}
        };
        this.props.searchOrder({}, this.searchCache, true);
    }

    _centerChange = async (v, item) => {
        const serviceEnum = await getServiceEnum({ pageNum: 1, areaCompanyId: item.key });
        this.setState({ centerItem: item.props['data-option'], serviceEnum });
    };

    render() {
        const { isDistribution, payType, payAccessType, ismerchantSponsorFlag, telephone, orderCode, gmtCreateF, gmtCreateT, orderState, orderType, invoice, productName,
            productCode, userState, consumeType, expressNo, sourceItem, finishedTimeF, finishedTimeT, settleTypeItme, centerItem, serviceItem, serviceEnum } = this.state;
        const { productOrderType, productOrderFlagEnum, paymentAccessTypeEnum, paymentTypeEnum, DistributionFlagEnum,
            merchantList: { merchantIdAndNames }, orderSourceEnum, settleTypeEnum, totalFee } = this.props;
        return (
            <div className={styles.container} style={{ marginTop: 10 }}>
                <SearchBox>
                    <SearchItem>
                        <XInputNum
                            label='订单编号'
                            placeholder='请输入订单编号'
                            value={orderCode}
                            bindThis={this}
                            bindName='orderCode'
                            min={0}
                            precision={0}
                            maxLength={16}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            label='物流单号'
                            placeholder='请输入物流单号'
                            value={expressNo}
                            bindThis={this}
                            bindName='expressNo'
                        />
                    </SearchItem>
                    <SearchItem>
                        {this._renderOrderStateSelect(orderState)}
                    </SearchItem>
                    {/* <SearchItem>
                        <XSelect
                            label='商户'
                            placeholder='请选择商户'
                            renderData={merchantIdAndNames || []}
                            dataIndex='merchantName'
                            keyIndex='merchantId'
                            value={userState.merchantName}
                            showSearch={true}
                            bindThis={this}
                            bindName='userState'
                        />
                    </SearchItem> */}
                    <SearchItem>
                        <XInput
                            label='会员手机'
                            placeholder='请输入手机号码'
                            value={telephone}
                            bindThis={this}
                            bindName='telephone'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='是否开票'
                            placeholder='请选择是否开票'
                            renderData={productOrderFlagEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={invoice.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='invoice'
                        />
                    </SearchItem>
                    {/* <XInput
                            inputStyle={{ width: '180px' }}
                            label='支付流水号'
                            placeholder='请输入支付流水号'
                            value={payItemId}
                            bindThis={this}
                            bindName='payItemId'
                        /> */}
                    <SearchItem>
                        <XInput
                            label='商品名称'
                            placeholder='请输入商品名称'
                            value={productName}
                            bindThis={this}
                            bindName='productName'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            label='商品货号'
                            placeholder='请输入商品货号'
                            value={productCode}
                            bindThis={this}
                            bindName='productCode'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='订单类型'
                            placeholder='请选择订单类型'
                            renderData={productOrderType}
                            dataIndex='value'
                            keyIndex='code'
                            value={orderType.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='orderType'
                        />
                    </SearchItem>
                    <SearchItem>
                        <div style={{ marginRight: 20 }}>
                            <ConsumeTypes
                                label='消费类型'
                                placeholder='请选消费类型'
                                value={consumeType}
                                showSearch={true}
                                bindThis={this}
                                bindName='consumeType'
                            />
                        </div>
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='是否批发'
                            placeholder='请选择是否批发'
                            renderData={DistributionFlagEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={isDistribution.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='isDistribution'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='商户引流'
                            placeholder='请选择'
                            renderData={DistributionFlagEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={ismerchantSponsorFlag.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='ismerchantSponsorFlag'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='支付通道'
                            placeholder='请选择支付通道'
                            renderData={paymentAccessTypeEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={payAccessType.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='payAccessType'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='支付方式'
                            placeholder='请选择支付方式'
                            renderData={paymentTypeEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={payType.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='payType'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='订单来源'
                            placeholder='请选择订单来源'
                            renderData={orderSourceEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={sourceItem.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='sourceItem'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XDatePicker
                            label='完成时间'
                            value={finishedTimeF}
                            bindThis={this}
                            bindName='finishedTimeF'
                            showTime={false}
                            isFormat={true}
                            defaultTime='00:00:00'
                        />
                        <XDatePicker
                            style={{ marginRight: 20 }}
                            label=''
                            value={finishedTimeT}
                            bindThis={this}
                            startTime={finishedTimeF}
                            showTime={false}
                            bindName='finishedTimeT'
                            isFormat={true}
                            defaultTime='23:59:59'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            label='结算设置'
                            placeholder='请选择结算设置'
                            renderData={settleTypeEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={settleTypeItme.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='settleTypeItme'
                        />
                    </SearchItem>
                    {!isRE() && <SearchItem>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                            <div style={{ marginRight: '10px' }}>区域公司</div>
                            <XScrollSelect style={{ width: '150px' }} url='/admin/company/listAreaCompanyIdAndNamePager'
                                placeholder='请选择区域公司' showSearch mainKey='companyName' selectID='id' selectName='companyName'
                                value={centerItem} listLabel='dataList' isOptionBindID={true}
                                onChange={this._centerChange}
                            />
                        </div>
                    </SearchItem>}
                    <SearchItem>
                        <XSelect label='云仓' placeholder='请选择云仓' renderData={serviceEnum}
                            dataIndex='companyName' keyIndex='id' value={serviceItem.companyName}
                            showSearch={true} bindThis={this} bindName='serviceItem' isOptionBindID={true}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XDatePicker
                            label='下单时间'
                            value={gmtCreateF}
                            bindThis={this}
                            bindName='gmtCreateF'
                            showTime={false}
                            isFormat={true}
                            defaultTime='00:00:00'
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            style={{ marginRight: 20 }}
                            label=''
                            value={gmtCreateT}
                            bindThis={this}
                            startTime={gmtCreateF}
                            showTime={false}
                            bindName='gmtCreateT'
                            isFormat={true}
                            defaultTime='23:59:59'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                        {/* <SearchItem>
                            <div style={{ marginLeft: '20px' }}>订单总金额：{regFenToYuan(totalFee)} 元</div>
                        </SearchItem> */}
                    </SearchItem>
                </SearchBox>
            </div>
        );
    }
}

OrderSearch.propTypes = {
    orderState: PropTypes.number,
    searchOrder: PropTypes.func,
    getProductOrderType: PropTypes.func,
    getProductOrderStatus: PropTypes.func,
    getProductOrderFlagEnum: PropTypes.func,
    getMerchantList: PropTypes.func,
    productOrderType: PropTypes.array,
    productOrderStatus: PropTypes.array,
    productOrderFlagEnum: PropTypes.array,
    merchantList: PropTypes.object,
    getDistributionFlagEnum: PropTypes.func,
    DistributionFlagEnum: PropTypes.array,
    getPaymentAccessTypeEnum: PropTypes.func,
    paymentAccessTypeEnum: PropTypes.array,
    getPaymentTypeEnum: PropTypes.func,
    paymentTypeEnum: PropTypes.array,
    getOrderSourceEnum: PropTypes.func,
    orderSourceEnum: PropTypes.array,
    getSettleTypeEnum: PropTypes.func,
    settleTypeEnum: PropTypes.array,
    totalFee: PropTypes.oneOfType([
        PropTypes.string, PropTypes.number
    ])
};

const mapStateToProps = (state) => ({
    productOrderType: state.productOrder.productOrderType,
    productOrderStatus: state.productOrder.productOrderStatus,
    productOrderFlagEnum: state.productOrder.productOrderFlagEnum,
    DistributionFlagEnum: state.productOrder.DistributionFlagEnum,
    merchantList: state.Transcation.merchantList,
    paymentAccessTypeEnum: state.productOrder.paymentAccessTypeEnum,
    paymentTypeEnum: state.productOrder.paymentTypeEnum,
    orderSourceEnum: state.productOrder.orderSourceEnum,
    settleTypeEnum: state.productOrder.settleTypeEnum
});

export default connect(mapStateToProps, {
    getProductOrderType, getProductOrderStatus, getProductOrderFlagEnum, getMerchantList, getDistributionFlagEnum,
    getPaymentAccessTypeEnum, getPaymentTypeEnum, getOrderSourceEnum, getSettleTypeEnum
})(OrderSearch);