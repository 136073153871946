import React from 'react';
import styles from './LogisticsTable.module.scss';
import { XPagination, XTableSelectionCount, XMediaFn } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';

class LogisticsTable extends React.Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
    };

    _takeColumns = () => ([
        {
            title: 'LOGO',
            dataIndex: 'expressLogo',
            key: 'expressLogo',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderImgCell(text))
        },
        {
            title: '物流公司',
            dataIndex: 'expressName',
            key: 'expressName',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '物流公司编号',
            dataIndex: 'expressCode',
            key: 'expressCode',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '描述',
            dataIndex: 'remark',
            key: 'remark',
            width: '30%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '菜鸟物流公司编码',
            dataIndex: 'cainiaoCode',
            key: 'cainiaoCode',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '状态',
            dataIndex: 'expressState.value',
            key: 'expressState.value',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: '10%',
            render: (text, item) => this._renderActionCell(item.id, item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderImgCell = (url) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.imgbox}>
                    {/* <img className={styles.img} src={url} /> */}
                    <XMediaFn
                        style={{ width: '36px', height: '36px' }}
                        dataType='image'
                        dataSource={url}
                    />
                </div>
            </div>
        );
    };

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        let element = null;
        if (item.expressState && item.expressState.code == 0) {
            element = <div className={styles.item} onClick={() => tableAction(key, 'openService')}>开通服务</div>;
        } else {
            element = <div className={styles.item} onClick={() => tableAction(key, 'closeService')}>停止服务</div>;
        }
        return (
            <div className={styles.action}>
                {element}
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys });
            const rows = selectedRows.map((item) => {
                return item.id;
            });
            this.props.onRowSelection(rows);
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

LogisticsTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    onRowSelection: PropTypes.func, // 复选框事件
};

export default LogisticsTable;