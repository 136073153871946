/**
 * 商户管理  Saga集合
 */
import {
    merchantListSource,
    merchantSelectData,
    merchantstoreSource,
    storeDetails,
    merchantradiusData,
    getListAreaByParentId,
    getmerchantportionSource,
    merchantServicearea,
    merchantServicecenter,
    merchantServicecompany,
    radiotype,
    getNetworkInfo
} from './merchantlist/saga';
import merchantsHotel from './merchantsHotel/model'; // 酒店商户
import merchantsInvite from './merchantlist/model'; // 商户邀请会员
import merchantUnion from './merchantunion/model'; // 银联商户
import citybeautyopen from './citybeautyopen/model'; // 同城/美居开城配置
import classlist from './classlist/model'; // 经营类目列表

export default [
    merchantListSource,//商品列表
    merchantstoreSource,//店铺
    storeDetails,//详情
    merchantSelectData,//下拉选项
    merchantradiusData, //新建商户类型
    getListAreaByParentId,//省市区
    getmerchantportionSource,//新建商户基础费用模式
    merchantServicearea,//所属服务商
    merchantServicecenter,//运营中心
    merchantServicecompany,//区域公司
    getNetworkInfo,// 入网功能
    radiotype,//商户类型在实体加盟中运营中心、区域、服务商（不可以选）数据依据地址的市，区参数
    ...Object.values(merchantsHotel.sagas), //酒店商户
    ...Object.values(merchantsInvite.sagas), //商户邀请会员
    ...Object.values(merchantUnion.sagas), //银联商户
    ...Object.values(citybeautyopen.sagas), //同城/美居开城配置
    ...Object.values(classlist.sagas), //经营类目列表
];
