import { combineReducers } from 'redux';
import { liveDurationRechargeRulesTableSource, showSettingList, toupRulesTableSource, showsetDate, } from './reducer';
import model from './model';

export default combineReducers({
    liveDurationRechargeRulesTableSource,
    showSettingList,
    toupRulesTableSource,
    showsetDate,
    ...model.reducers,
});