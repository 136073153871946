import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { message } from 'antd';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, showConfirm, } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import CreateEdit from '../../modal/CreateEdit';
import *as actions from '@/reducers/starvalueshop/starshop/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
        classifyName: '',
        isCheck: false,
        starClassifyItem: {},
        condition: {}
    }

    componentDidMount() {
        const { getstarmallListState } = this.props;
        getstarmallListState();
    }

    //名称改变
    _nameChange = (value) => {
        this.setState({ classifyName: value, isCheck: false });
    }

    //分类状态改变
    _conditionChange = (selectedItem) => {
        this.setState({ condition: selectedItem, isCheck: false });
    }

    // 查询
    _searchHandle = (useCache) => {
        this.setState({ isCheck: true });
        const { pageNum, pageSize, classifyName, condition, } = this.state;
        this.searchCache = {
            categoryName: classifyName,//分类名称  
            state: condition.code,//状态  
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getstarmallList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ classifyName: '', isCheck: false, condition: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //创建
    _openCreateModal = () => {
        this.setState({ currentOpenMode: 'CREAT' });
    }


    // 表格操作
    _tableAction = (item, type, params) => {
        const { getstarmallList, } = this.props;
        switch (type) {
            case 'edit':
                this.setState({
                    currentOpenMode: 'EDIT',
                    openModeParam: params,
                });
                break;
            case 'delete':
                showConfirm('您是否要删除该分类', '', () => {
                    http('/starmall/category/delete', { id: item }, 'POST').then(() => {
                        message.success('删除成功');
                        getstarmallList(this.searchCache);// 重新渲染更新列表
                    }).catch((res) => {
                        if (res.code == '500') {
                            message.error(res.message);
                        }
                    });
                }, () => { });
                break;
            case 'recommend':
                http('/starmall/category/updateReco', {
                    id: item.id,
                    isReco: (params === false) ? 0 : 1,
                }, 'POST').then(() => {
                    message.success('推荐修改成功');
                    getstarmallList(this.searchCache);// 重新渲染更新列表
                }).catch((e = {}) => {
                    message.error(e.message || '推荐修改异常');
                });
                break;
            case 'condition':
                http('/starmall/category/updateState', {
                    id: item.id,
                    state: (params === false) ? 0 : 1,
                }, 'POST').then(() => {
                    message.success('状态修改成功');
                    getstarmallList(this.searchCache);// 重新渲染更新列表
                }).catch((e = {}) => {
                    message.error(e.message || '状态修改异常');
                });
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'CREAT':
                return <CreateEdit title='创建人气值分类' visible={true} closeMode={this._closeMode} />;
            case 'EDIT':
                return <CreateEdit title='编辑人气值分类' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getstarmallList(this.searchCache);// 创建后重新渲染更新列表
        }
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getstarmallList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {

        const { classifyName, condition } = this.state;
        const { tableSource, paginations, selectData, } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='分类名称'
                                placeholder='请输入'
                                onChange={this._nameChange}
                                value={classifyName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='分类状态'
                                placeholder='请选择'
                                renderData={selectData}
                                onChange={this._conditionChange}
                                dataIndex='value'
                                keyIndex='code'
                                value={condition.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '90px' }} label='新增分类' onClick={this._openCreateModal} />
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={tableSource} tableAction={this._tableAction} paginations={paginations} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }

}


Main.propTypes = {
    getstarmallList: PropTypes.func, // 表格数据源的Action
    getstarmallListState: PropTypes.func, // 表格数据源的状态下拉Action
    tableSource: PropTypes.array, // 表格数据源
    selectData: PropTypes.array, // 分类状态下拉选项
    paginations: PropTypes.object, // 分页数据
};


const mapStateToProps = (state) => ({
    tableSource: state.starShop.starmallListDate.tableSource,
    selectData: state.starShop.starmallListDate.selectData,
    paginations: state.starShop.starmallListDate.paginations,
});

export default connect(mapStateToProps, actions)(Main);