import * as Advert from './actiontypes';

/**
 * 存储'广告策略'数据源
 * @param {*} state 
 * @param {*} action 
 */

 const init = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
 };

export const advertStrategList = (state = init, action) => {
    switch(action.type){
        case Advert.SET_STRATEG_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const advertStrategStartAndStop = (state = [], action) => {
    switch(action.type){
        case Advert.SET_STRATEG_STARTANDSTOP:
            return action.payload.result;
        default:
            return state;
    }
};
export const advertStrategDetail = (state = {}, action) => {
    switch(action.type){
        case Advert.SET_STRATEG_DETAIL:
            return action.payload.result || {};
        default:
            return state;
    }
};
export const advertStrategDelete = (state = [], action) => {
    switch(action.type){
        case Advert.SET_STRATEG_DELETE:
            return action.payload.result;
        default:
            return state;
    }
};
export const advertStrategAdd = (state = [], action) => {
    switch(action.type){
        case Advert.SET_STRATEG_ADD:
            return action.payload.result;
        default:
            return state;
    }
};
export const advertStrategEdit = (state = [], action) => {
    switch(action.type){
        case Advert.SET_STRATEG_EDIT:
            return action.payload.result;
        default:
            return state;
    }
};
export const advertStrategAdsList = (state = [], action) => {
    switch(action.type){
        case Advert.SET_STRATEG_ADSLIST:
            return action.payload.result;
        default:
            return state;
    }
};
