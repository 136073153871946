/**
 * 礼屋业绩
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { isPlat, isOC } from '@/assets/js/authType';
import { XSelect, XOKButton, XCancelButton, XDatePicker, XInput } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import liwu_model from '@/reducers/operationcenter/liwu/model';
import member_model from '@/reducers/usermanage/member/model';
import KeepAlive from '@/routes/KeepAlive';
import TableContent from './components/TableContent';
import DeleteModal from './components/DeleteModal';
import NewCheckModal from './components/NewCheckModal';
import CheckFinish from './components/CheckFinishModal';
import ButtonPerssion from '@/routes/ButtonPerssion';

class Main extends React.Component {

    state = {
        stockReconOrderId: '', // 订单ID
        orderId: '', // 对账单ID
        OCSelect: {}, // 运营中心
        RESelect: {}, // 区域公司
        ztItem: {}, // 状态
        lyItem:{}, // 来源
        date: undefined, // 生成时间
        showDelete: false, // 显示删除
        showNew: false, // 显示审核
        showFinish: false, // 显示完成
        param: null, // 弹框传递参数
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getOperationCenter();
        this.props.getAreaCompany();
        this.props.getStockReconStatusEnum();
        this.props.getStockReconSourceTypeEnum();
    }


    // 查询
    _searchHandle = (useCache) => {
        const { OCSelect, RESelect, ztItem, date, pageNum, pageSize, orderId, stockReconOrderId, lyItem } = this.state;
        const query = {
            stockReconOrderId,
            id: orderId,
            operationsId: OCSelect.id,
            areaCompanyId: RESelect.id,
            status: ztItem.code,
            sourceType: lyItem.code,
            date,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };

        this.props.getStockReconList(query);
        KeepAlive.saveCache({ stockReconOrderId, orderId, OCSelect, RESelect, ztItem, date, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ stockReconOrderId: '', orderId: '', OCSelect: {}, RESelect: {}, ztItem: {}, lyItem:{}, date: undefined, pageNum: autoPageNum, pageSize: autopageSize }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (type, item) => {
        switch (type) {
            case 'finish':
                this.setState({ showFinish: true, param: item });
                break;
            case 'detail':
                addOneBreadcrumbPath({
                    title: '核对单详情',
                    path: '/home/operationcenter/liwu/detail'
                });
                this.props.history.push({
                    pathname: '/home/operationcenter/liwu/detail/' + item.id
                });
                break;
            case 'delete':
                this.setState({ showDelete: true, param: item });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    // 新建核对
    _newHandle = () => {
        this.setState({ showNew: true });
    }

    // 渲染运营中心下拉列
    _renderOCSelect = () => {
        if (isPlat()) {
            return (
                <XSelect
                    label='运营中心'
                    placeholder='请选择运营中心'
                    renderData={this.props.OCSelectList}
                    dataIndex='companyName'
                    keyIndex='id'
                    value={this.state.OCSelect.companyName}
                    onChange={this._OCSelectChangeHandle}
                    bindThis={this}
                    bindName='OCSelect'
                    showSearch={true}
                />
            );
        } else {
            return null;
        }
    }

    // 运营中心发生变化
    _OCSelectChangeHandle = (ocItem) => {
        this.props.getAreaCompany({ operationsId: ocItem.id });
    }

    // 渲染区域公司下拉列
    _renderRESelect = () => {
        if (isPlat() || isOC()) {
            return (
                <XSelect
                    label='区域公司'
                    placeholder='请选择区域公司'
                    renderData={this.props.RESelectList}
                    dataIndex='companyName'
                    keyIndex='id'
                    value={this.state.RESelect.companyName}
                    bindThis={this}
                    bindName='RESelect'
                    showSearch={true}
                />
            );
        } else {
            return null;
        }
    }

    render() {
        const { ztItem = {}, date, showDelete, showNew, showFinish, param, orderId, stockReconOrderId, lyItem } = this.state;
        const { stockReconStatusEnum, stockReconList, stockReconSourceTypeEnum } = this.props;
        return (
            <KeepAlive id='liwu' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='订单ID'
                                placeholder='请输入'
                                value={stockReconOrderId}
                                bindThis={this}
                                bindName='stockReconOrderId'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='对账单号'
                                placeholder='请输入'
                                value={orderId}
                                bindThis={this}
                                bindName='orderId'
                            />
                        </SearchItem>
                        <SearchItem>
                            {this._renderOCSelect()}
                        </SearchItem>
                        <SearchItem>
                            {this._renderRESelect()}
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                showSearch
                                placeholder='请选择'
                                renderData={stockReconStatusEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={ztItem.value}
                                bindThis={this}
                                bindName='ztItem'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                label='生成日期'
                                placeholder='请选择结束时间'
                                value={date}
                                bindThis={this}
                                bindName='date'
                                isFormat={true}
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='来源类型'
                                showSearch
                                placeholder='请选择'
                                renderData={stockReconSourceTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={lyItem.value}
                                bindThis={this}
                                bindName='lyItem'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                        <SearchItem>
                            <ButtonPerssion code='operationcenter/liwu/schdd'>
                                <XOKButton style={{ marginLeft: '20px' }} label='生成核对单' onClick={this._newHandle} />
                            </ButtonPerssion>
                        </SearchItem>
                    </SearchBox>
                    <TableContent renderData={stockReconList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {
                        showDelete ? <DeleteModal param={param} close={(type) => {
                            if (type == 'update') {
                                this._searchHandle('useCache');
                            }
                            this.setState({ showDelete: false });
                        }} /> : null
                    }
                    {
                        showNew ? <NewCheckModal param={param} close={(type) => {
                            if (type == 'update') {
                                this._searchHandle('useCache');
                            }
                            this.setState({ showNew: false });
                        }} /> : null
                    }
                    {
                        showFinish ? <CheckFinish param={param} close={(type) => {
                            if (type == 'update') {
                                this._searchHandle('useCache');
                            }
                            this.setState({ showFinish: false });
                        }} /> : null

                    }
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getOperationCenter: PropTypes.func, // 获取归属运营中心
    OCSelectList: PropTypes.array, // 归属运营中心下拉列
    getAreaCompany: PropTypes.func, // 获取归属区域公司
    RESelectList: PropTypes.array, // 归属区域公司下拉列
    getStockReconStatusEnum: PropTypes.func, // 获取状态
    stockReconStatusEnum: PropTypes.array, // 状态下拉列
    getStockReconList: PropTypes.func, // 获取业绩列表
    stockReconList: PropTypes.object, // 业绩列表
    getStockReconSourceTypeEnum: PropTypes.func, // 获取业绩列表
    stockReconSourceTypeEnum: PropTypes.array, // 业绩列表
};

const mapStateToProps = (state) => ({
    OCSelectList: state.member.getOperationCenter,
    RESelectList: state.member.getAreaCompany,
    stockReconStatusEnum: state.liwu.getStockReconStatusEnum,
    stockReconList: state.liwu.getStockReconList,
    stockReconSourceTypeEnum: state.liwu.getStockReconSourceTypeEnum,
});

export default connect(mapStateToProps, { ...member_model.actions, ...liwu_model.actions, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);