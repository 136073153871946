import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table, Tooltip } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

let dataSource = [];
for (let i = 1; i < 4; i++) {
	dataSource.push({
		key: i,
		mainImgUrl: '无语',
		goodsName: '无语',
		goodsNo: '无语',
		categoryName: '无语',
		merchantName: '无语',
		goodsState: '无语',
		gmtModified: '无语',
	});
}

class TableContent extends Component {
	state = {
		selectedRowKeys: [],
	};

	_columns = () => [
		{
			title: '流水号',
			dataIndex: 'violationId',
			key: 'violationId',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '商户',
			dataIndex: 'merchantName',
			key: 'merchantName',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '商品图片',
			dataIndex: 'mainImgUrl',
			key: 'mainImgUrl',
			align: 'center',
			width: '10%',
			render: ImgCell
		},
		{
			title: '货号',
			dataIndex: 'goodsNo',
			key: 'goodsNo',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '是否批发',
			dataIndex: 'isWholesale',
			key: 'isWholesale',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: (text) => {
			return <span>{text && text.value}</span>;
			}
		},
		{
			title: '商品名称',
			dataIndex: 'goodsName',
			key: 'goodsName',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		// {
		// 	title: '行为',
		// 	dataIndex: 'categoryName',
		// 	key: 'categoryName',
		// 	align: 'center',
		// 	width: '10%',
		// },
		{
			title: '平台意见',
			dataIndex: 'violationReason',
			key: 'violationReason',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '状态',
			dataIndex: 'violationState',
			key: 'violationState',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '新建时间',
			dataIndex: 'gmtCreate',
			key: 'gmtCreate',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'keyId',
			key: 'keyId',
			align: 'center',
			width: '10%',
			render: (text, record) => this._tableAction(text, record)
		},
	];

	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
				{ record.violationState !== '平台解禁' && <div className={styles.item} onClick={() => tableAction(key, 'audit', record)}>审核</div>}
			</div>
		);
	};

	_tableData = (data) => {
		return data;
	}

	render() {
		const { renderData } = this.props;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey={record => record.violationId}
					columns={this._columns()}
					pagination={false}
					dataSource={this._tableData(renderData.dataList)}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={this.props.paginationChange} />
			</div>
		);
	}
}


TableContent.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
};

export default TableContent;