/**
 * 订单交易明细
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Order from './actionTypes';

//订单交易明细列表 商城订单
export const getTranscationList = function* () {
    yield takeEvery(Order.GET_TRANSCATION_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationSource/settleOrderDetail', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_TRANSCATION_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//订单交易明细列表 服务订单
export const getTranscationServiceList = function* () {
    yield takeEvery(Order.GET_TRANSCATIONSERVICE_LIST, function* requestData(action) {
        try {
            let result = yield http('/earnings/orderDetailsList', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_TRANSCATIONSERVICE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//费用明细列表 服务订单
export const getCostServiceList = function* () {
    yield takeEvery(Order.GET_COSTSERVICE_LIST, function* requestData(action) {
        try {
            let result = yield http('/earnings/costDetailList', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_COSTSERVICE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//商户列表
export const getMerchantList = function* () {
    yield takeEvery(Order.GET_MERCHANT_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/info/listMerchantIdAndName', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_MERCHANT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
