//供应商审核列表
export const GET_SUPPLIERAUDIT_LIST = 'AUDITMANAGE/GET_SUPPLIERAUDIT_LIST';
export const SET_SUPPLIERAUDIT_LIST = 'AUDITMANAGE/SET_SUPPLIERAUDIT_LIST';

//经营品类
export const GET_SUPPLIERAUDIT_SELE = 'AUDITMANAGE/GET_SUPPLIERAUDIT_SELE';
export const SET_SUPPLIERAUDIT_SELE = 'AUDITMANAGE/SET_SUPPLIERAUDIT_SELE';

//审核状态
export const GET_SUPPLIERAUDIT_STATUS = 'AUDITMANAGE/GET_SUPPLIERAUDIT_STATUS';
export const SET_SUPPLIERAUDIT_STATUS = 'AUDITMANAGE/SET_SUPPLIERAUDIT_STATUS';

//供应商审核详情
export const GET_SUPPLIERAUDIT_DETAIL = 'AUDITMANAGE/GET_SUPPLIERAUDIT_DETAIL';
export const SET_SUPPLIERAUDIT_DETAIL = 'AUDITMANAGE/SET_SUPPLIERAUDIT_DETAIL';
