/**
 * 【预收益管理】
 */
import React from 'react';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/previewcollectionmanage/model';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XOKButton, XCancelButton, XInput, XSelect } from '@/components/xqxc_ui';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import SetMoney from './modal/SetMoney';
import { message } from 'antd';
import ButtonPerssion from '@/routes/ButtonPerssion';

class Main extends React.Component {

    state = {
        merchantId: '',
        merchantType: {},
        renderModal: false,
        modalParams: [],
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getMerchantTypeEnum();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { merchantId, merchantType, pageNum, pageSize } = this.state;
        this.searchCache = {
            loginName: merchantId,
            merchantType: merchantType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getPreviewCollectionList(this.searchCache);
        KeepAlive.saveCache({ ...this.searchCache, merchantType }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ merchantId: '', merchantType: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    _tableAction = (id, type, item) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '对账详情',
                    path: `/home/storehousefinancialmanage/previewcollectionmanage/detail/${item.subjectId}/${item.loginName}/${item.subjectName}`,
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/previewcollectionmanage/detail/${item.subjectId}/${item.loginName}/${item.subjectName}`
                });
                break;
        }
    }

    //设置金额
    _setMoney = () => {
        if (this.state.modalParams.length !== 1) return message.warn('请选择商户，只支持针对单个商户进行设置金额');
        this.setState({ renderModal: true });
    }
    //设置弹框参数
    _setModalParams = (list = []) => this.setState({ modalParams: list })
    //关闭弹框
    _closeModal = (update) => {
        this.setState({ renderModal: false, modalParams: [] }, () => {
            update && this._searchHandle('useCache');
        });
    }

    render() {
        const { previewCollectionList, merchantTypeEnum } = this.props;
        const { merchantId, merchantType, modalParams, renderModal } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div style={{ marginTop: 10 }}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='商户账号'
                                    placeholder='请输入'
                                    value={merchantId}
                                    bindThis={this}
                                    bindName='merchantId'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='商户类型'
                                    placeholder='请选择'
                                    renderData={merchantTypeEnum}
                                    bindThis={this}
                                    bindName='merchantType'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={merchantType.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <ButtonPerssion code='storehousefinancialmanage/previewcollectionmanage/setsum'>
                                    <XOKButton
                                        style={{ width: '100px', marginRight: '20px' }}
                                        label='设置金额'
                                        onClick={this._setMoney}
                                    />
                                </ButtonPerssion>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <OrderTable
                        renderData={previewCollectionList}
                        setModalParams={this._setModalParams}
                        modalParams={modalParams}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                    />
                    {renderModal && <SetMoney modalParams={modalParams} closeModal={this._closeModal} />}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    getPreviewCollectionList: PropTypes.func,
    previewCollectionList: PropTypes.object,

    getMerchantTypeEnum: PropTypes.func,
    merchantTypeEnum: PropTypes.array,

    getIncomeTypeEnum: PropTypes.func,
    incomeTypeEnum: PropTypes.array
};
const mapStateToProps = (state) => ({
    previewCollectionList: state.previewcollectionmanage.previewCollectionList,
    merchantTypeEnum: state.previewcollectionmanage.merchantTypeEnum,
    incomeTypeEnum: state.previewcollectionmanage.incomeTypeEnum
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
