/**
 * 鉴黄查询页
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import TableMain from './conponents/TableMain';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import OnlinePlayModal from '../../modal/OnlinePlayModal';
import BanPlayModal from '../../modal/BanPlayModal';
import { getPornographyList, getPornographyType } from '@/reducers/auditmanage/pornography/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

const Mode = {
	AUDIT_MODAL: 'AUDIT_MODAL',
	ONLINE_MODAL: 'ONLINE_MODAL',
};

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tel: '',
			nickneme: '',
			broadcasTitle: '',
			currentOpenMode: '',
			openModeParam: '',
			audit: {}
		};
	}
	static propTypes = {
		getPornographyList: PropTypes.func.isRequired,
		pornographyData: PropTypes.object.isRequired,// 表格数据源
		getPornographyType: PropTypes.func.isRequired,
		typeList: PropTypes.object.isRequired
	};
	componentDidMount() {
		this.props.getPornographyType();
	}
	// 查询触发
	_searchHandle = (useCache) => {
		const { tel, nickneme, broadcasTitle, pageSize, pageNum } = this.state;
		this.searchCache = {
			tel, nickneme, broadcasTitle,
			pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
			pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
		};
		this.props.getPornographyList(this.searchCache);
		KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
	}
	// 重置触发
	_resetHandle = () => {
		this.setState({ tel: '', nickneme: '', broadcasTitle: '' }, () => {
			KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
		});
	}
	// 页面发生变化
	_paginationChange = (pageSize, pageNum) => {
		this.setState({ pageSize, pageNum }, () => {
			this._searchHandle('useCache');
		});
	}
	_tableAction = (id, record, type) => {
		switch (type) {
			case 'ban':
				this.setState({ openModeParam: id, currentOpenMode: Mode.AUDIT_MODAL, audit: record });
				break;
			case 'online':
				this.setState({ openModeParam: id, currentOpenMode: Mode.ONLINE_MODAL, audit: record });
				break;
		}

	}
	_renderMode = () => {
		switch (this.state.currentOpenMode) {
			case Mode.AUDIT_MODAL:
				return <BanPlayModal visible={true} typeList={this.props.typeList} audit={this.state.audit} closeMode={this._closeMode} />;
			case Mode.ONLINE_MODAL:
				return <OnlinePlayModal visible={true} audit={this.state.audit} closeMode={this._closeMode} />;
			default:
				return null;
		}
	}

	// 关闭弹窗
	_closeMode = (update) => {
		this.setState({ currentOpenMode: '', audit: {} }, () => {
			if (update) {
				this._searchHandle('useCache');
			}
		});
	}
	render() {
		const { tel, nickneme, broadcasTitle } = this.state;
		const { pornographyData, typeList } = this.props;
		return (
			<KeepAlive id='1' render={(state) => {
				if (!this.state.__aliveInit) {
					this.setState({ ...state, __aliveInit: true }, () => {
						this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
					});
				}
			}}>
				<div className={styles.flexBoxContainer}>
					<SearchBox>
						<SearchItem>
							<XInput
								label='手机号码'
								placeholder='请输入手机号码'
								bindThis={this}
								bindName='tel'
								value={tel}
							/>
						</SearchItem>
						<SearchItem>
							<XInput
								label='主播昵称'
								placeholder='请输入主播昵称'
								bindThis={this}
								bindName='nickneme'
								value={nickneme}
							/>
						</SearchItem>
						<SearchItem>
							<XInput
								label='直播标题'
								placeholder='请输入直播标题'
								bindThis={this}
								bindName='broadcasTitle'
								value={broadcasTitle}
							/>
						</SearchItem>
						<SearchItem>
							<XOKButton
								label='查询'
								onClick={this._searchHandle}
							/>
							<XCancelButton
								label='重置'
								onClick={this._resetHandle}
							/>
						</SearchItem>
					</SearchBox>
					{/* <div className={styles.operate_box}>
						<XOKButton style={{ width: '90px' }} label='导出Excel' />
					</div> */}
					<TableMain paginationChange={this._paginationChange} typeList={typeList} renderData={pornographyData} tableAction={this._tableAction} />
					{this._renderMode()}
				</div>
			</KeepAlive>
		);
	}
}
const mapStateToProps = (state) => ({
	pornographyData: state.pornography.pornographyList,
	typeList: state.pornography.pornographyType
});
export default connect(mapStateToProps, { getPornographyList, getPornographyType })(Main);