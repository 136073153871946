import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XInput, XSelect, XDatePicker, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import PurchaseContractTable from './components/PurchaseContractTable';
import model from '@/reducers/suppliermanage/purchasecontract/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import Fail from '../../modal/Fail';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            renderMode: '',
            modeParams: {},
            id: '',//合同号
            suppliers: {}, //供应商
            contractStatus: {},//合同状态
            startDate: null,//开始时间
            endDate: null//结束时间
        };
    }

    static propTypes = {
        history: PropTypes.object,//路由对象
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getPurchaseContractList: PropTypes.func,//采购合同列表
        purchaseContractList: PropTypes.object,//采购列表数据源
        getPurchaseSupplierSele: PropTypes.func,//获取供应商下拉
        purchaseSupplierSele: PropTypes.array,//供应商下拉
        getPurchaseStatusEnum: PropTypes.func,//获取枚举
        purchaseStatusEnum: PropTypes.array//枚举值
    };

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getPurchaseSupplierSele, getPurchaseStatusEnum } = this.props;
        getPurchaseSupplierSele();
        getPurchaseStatusEnum();
        keepSecondNavBreadcrumb();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, id, suppliers, contractStatus, startDate, endDate } = this.state;
        this.searchCache = {
            id, supplierId: suppliers.supplierId, state: contractStatus.code, startDate, endDate,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getPurchaseContractList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ id: '', suppliers: {}, contractStatus: {}, startDate: null, endDate: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //新建合同
    _createContract = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建采购合同',
            path: '/home/suppliermanage/purchasecontract/createinfo'
        });
        history.push({
            pathname: '/home/suppliermanage/purchasecontract/createinfo'
        });
    }

    _reqFunc = (url, data, tip) => {
        http(url, { ...data }, 'POST').then(() => {
            message.success(tip);
            this._searchHandle('useCache');
        }).catch((error) => {
            message.error(error.message);
        });
    }

    //表格操作
    _tableOperate = (id, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'editInfo':
                addOneBreadcrumbPath({
                    title: '采购合同编辑',
                    path: '/home/suppliermanage/purchasecontract/editinfo'
                });
                history.push({
                    pathname: '/home/suppliermanage/purchasecontract/editinfo/' + id
                });
                break;
            case 'detailInfo':
                addOneBreadcrumbPath({
                    title: '采购合同详情',
                    path: '/home/suppliermanage/purchasecontract/detailinfo'
                });
                history.push({
                    pathname: '/home/suppliermanage/purchasecontract/detailinfo/' + id
                });
                break;
            case 'commitAudit':
                this._reqFunc('/purchase/ctr/edit', { ctrId: id, state: 'APPLY' }, '采购合同提交审核成功。');
                break;
            case 'deleteInfo':
                showConfirm('您确定删除该采购合同？', '', () => {
                    this._reqFunc('/purchase/ctr/del/' + id, {}, '删除该采购合同成功。');
                });
                break;
            case 'fail':
                this.setState({ renderMode: 'fail', modeParams: { id } });
                break;
            default:
                break;
        }
    };

    // 渲染弹框
    _renderMode = () => {
        const { renderMode, modeParams } = this.state;
        switch (renderMode) {
            case 'fail':
                return <Fail visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    };
    // 关闭弹框
    _closeMode = () => {
        this.setState({ renderMode: '' });
    };

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { purchaseContractList, purchaseSupplierSele, purchaseStatusEnum } = this.props;
        const { id, suppliers, contractStatus, startDate, endDate } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='合同号'
                                placeholder='请输入合同号'
                                value={id}
                                bindThis={this}
                                bindName='id'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='供应商'
                                placeholder='请选择供应商'
                                renderData={purchaseSupplierSele}
                                bindThis={this}
                                bindName='suppliers'
                                dataIndex='supplierName'
                                keyIndex='supplierId'
                                value={suppliers.supplierName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='合同状态'
                                placeholder='请选择合同状态'
                                renderData={purchaseStatusEnum}
                                bindThis={this}
                                bindName='contractStatus'
                                dataIndex='value'
                                keyIndex='code'
                                value={contractStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                placeholder='选择开始时间'
                                label='创建时间'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                placeholder='选择结束时间'
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: '120px' }}
                            label='新建合同'
                            onClick={this._createContract}
                        />
                    </div>
                    <PurchaseContractTable
                        paginationChange={this._paginationChange}
                        tableOperate={this._tableOperate}
                        tableData={purchaseContractList}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

//映射数据源
const mapStateToProps = (state) => ({
    purchaseContractList: state.purchaseContract.purchaseContractList,
    purchaseSupplierSele: state.purchaseContract.purchaseSupplierSele,
    purchaseStatusEnum: state.purchaseContract.purchaseStatusEnum
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);