/**
 * 底部横线+返回按钮组合组件
 */
import React from 'react';
import styles from './index.module.scss';
import OKButton from '../OKButton';
import PropTypes from 'prop-types';

class LineBackBtn extends React.Component {
    render() {
        const { style, cancelHandle, label } = this.props;
        return (
            <div className={styles.container} style={style}>
                <div className={styles.line}></div>
                <div className={styles.button}>
                    <OKButton style={{ width: '90px' }} label={label} onClick={cancelHandle} />
                </div>
            </div>
        );
    }
}

LineBackBtn.defaultProps = {
    label: '返回'
};

LineBackBtn.propTypes = {
    style: PropTypes.object, // 总样式
    cancelHandle: PropTypes.func, // 点击取消按钮时的回调函数
    label: PropTypes.string, // 文本标签
};

export default LineBackBtn;