/**
 * 商户批发商品免审核设置商户选择
 */
import React from 'react';
import { Modal, message } from 'antd';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './ChooseModal.module.scss';
import ChooseCreate from '../components/ChooseCreate';
import model from '@/reducers/systemmanage/merchantpermission/model';

class ChooseModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        pageNum: 1,
        pageSize: 30,
        merchantName: '',//商户名称
        batch: []
    }

    componentDidMount() {
        const { pageNum, pageSize } = this.state;
        this.props.getSeMerchantList({ pageNum, pageSize });
    }


    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }


    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        const { merchantName, } = this.state;
        this.props.getSeMerchantList({ merchantName, pageNum, pageSize });
    }


    // 查询触发
    _searchHandle = () => {
        const { pageNum, pageSize, merchantName } = this.state;
        this.props.getSeMerchantList({ merchantName, pageNum, pageSize });
    }


    //重置
    _resetHandle = () => {
        this.setState({ merchantName: '', pageNum: 1, pageSize: 30 }, () => {
            this.props.getSeMerchantList({ pageNum: 1, pageSize: 30 });
        });
    }


    _sendReq = (data) => {
        http('/merchant/merchantSetting/batchAddMerchantSetting', { merchantIds: data }, 'POST')
            .then((res = {}) => {
                message.success(res.message);
                this.setState({
                    visible: false,
                    confirmLoading: false
                });
                this.props.closeMode(true);
            }).catch((error = {}) => {
                message.error(error.message);
                this.setState({
                    confirmLoading: false
                });
            });
    }


    // 确定
    _OKHandle = () => {
        const { batch } = this.state;
        if (batch.length === 0) {
            return message.warning('请选择商户');
        }
        this.setState({ confirmLoading: true }, () => {
            this._sendReq(batch);
        });
    }

    //获取选中商品
    _tableAction = (data) => {
        this.setState({ batch: data });
    }


    render() {
        const { seMerchantList } = this.props;
        const { visible, title, confirmLoading, merchantName, } = this.state;

        return (
            <Modal
                width={860}
                bodyStyle={{ paddingBottom: 0 }}
                centered
                title={title}
                visible={visible}
                onOk={this._OKHandle}
                onCancel={this._handleCancel}
                confirmLoading={confirmLoading}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={`${styles.layout} ${styles.dataTop}`}>
                        <XInput
                            style={{ width: '216px' }}
                            inputStyle={{ width: '150px' }}
                            label='商户名称'
                            placeholder='请输入商户名称'
                            value={merchantName}
                            bindThis={this}
                            bindName='merchantName'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '100px' }}
                            label='确定'
                            loading={confirmLoading}
                            onClick={this._OKHandle}
                        />
                    </div>
                    <ChooseCreate
                        ref={(tableRef) => this.tableRef = tableRef}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                        renderData={seMerchantList}
                    />
                </div>
            </Modal>
        );
    }
}

ChooseModal.propTypes = {
    title: PropTypes.string,// 弹框标题
    data: PropTypes.object, // 需要修改魅力值的会员对象数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数

    getSeMerchantList: PropTypes.func,
    seMerchantList: PropTypes.object
};

const mapStateToProps = (state) => ({
    seMerchantList: state.merchantpermission.seMerchantList
});

export default connect(mapStateToProps, { ...model.actions })(ChooseModal);