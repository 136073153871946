import * as CUSTOMER from './actionTypes';

//获取客服列表
export const getCustomerList = (param) => ({
    type: CUSTOMER.GET_CUSTOMER_LIST,
    payload: param
});
//获取客服列表详情
export const getCustomerDetail = (param) => ({
    type: CUSTOMER.GET_CUSTOMER_DETAIL,
    payload: param
});
// 获取商户
export const getMerchantIdAndName = (param) => ({
    type: CUSTOMER.GET_MERCHANTIDANDNAME,
    payload: param
});
