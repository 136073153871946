import React from 'react';
import styles from './TableData.module.scss';
import { Switch, Icon, Input, message } from 'antd';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
    {
        title: '模板名称',
        dataIndex: 'templateName',
        key: 'templateName',
        width: '25%',
        align: 'center',
        render: (text, item) => _sortCell(text, item, actionClickHandle)
    },
    {
        title: '模板类型',
        dataIndex: 'className',
        key: 'className',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: (text, record) => {
            return <span>{text && text.value}</span>;
        }
    },
    {
        title: '操作人',
        dataIndex: 'modifyUserName',
        key: 'modifyUserName',
        width: '15%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '修改时间',
        dataIndex: 'gmtModified',
        key: 'gmtModified',
        width: '15%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        width: '20%',
        render: (id, record) => TableAction(record, actionClickHandle)
    },
];

export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    return data;
};
// 表格项内容为可编辑
const _sortCell = (value, item, actionClickHandle) => {
    const label = React.createRef();
    const box = React.createRef();
    const input = React.createRef(); 
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <div className={styles.sort_box}>
                <div className={styles.label} ref={label}>{value}</div>
                <div className={styles.input} ref={box}>
                    <Input
                        style={{ width: '150px' }}
                        ref={input}
                        defaultValue={value}
                        onBlur={(e) => {
                            box.current.style.display = 'none';
                            label.current.style.display = 'block';
                            if (value != e.target.value) {
                               if (e.target.value && e.target.value.length > 15) {
                                    message.warn('最多15个字');
                                    return;
                                } else if (e.target.value == null || e.target.value == undefined || e.target.value == '') {
                                    message.warn('您还未设置模板名称，请输入模板名称!');
                                    return;
                                } else {
                                    actionClickHandle(item, 'editTemplateName', e.target.value);
                                }
                            }
                        }}
                    />
                </div>
                <div
                    className={styles.icon}
                    onClick={() => {
                        label.current.style.display = 'none';
                        box.current.style.display = 'block';
                        input.current.focus();
                    }}
                >
                    <Icon type="edit" style={{ fontSize: '18px', color: '#1078ff' }} />
                </div>
            </div>
        </div>
    );

};
// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
    let checked = true;
    if (sw == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                key={item.id + '_' + checked}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={(value) => {
                    actionClickHandle(item, 'switch', value);
                }}
            />
        </div>
    );
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};

const TableAction = (record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(record, 'preview')}>预览</div>
            <div className={styles.item} onClick={() => actionClickHandle(record, 'edit')}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(record, 'delete')}>删除</div>
            {record.state && record.state.code == 1 ?
                <div className={styles.item} onClick={() => actionClickHandle(record, 'stop')}>禁用</div> :
                <div className={styles.item} onClick={() => actionClickHandle(record, 'start')}>启用</div>
            }
        </div>
    );
};