import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { Input, message, Modal, Typography, Form, Checkbox, Icon, Button } from 'antd';
import CusotmUpload from '@/components/NewUpload';
import model from '@/reducers/channelmanage/list/model';

const { Text } = Typography;
const { getClassList } = model.actions;

const ApplyModal = ({
    tableAction,
    modalParmas: { accountName, id } = {},
    form: { getFieldDecorator, validateFields } = {},
    getClassList,
    classList
}) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getClassList({ pageNum: 1, pageSize: 10000, paramType: 'SUPPLIER_CATEGORY' });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                setLoading(true);
                http('/user/supplierAccountInfo/applySupplierAccountInfo', {
                    ...values, bizLicenseUrl: values.bizLicenseUrl[0].url, id
                }, 'POST')
                    .then(res => {
                        message.success(res.message);
                        setLoading(false);
                        tableAction(true, 'closeModal');
                    })
                    .catch(err => {
                        setLoading(false);
                        message.error(err.message);
                    });
            }
        });
    };

    return <Modal
        width={600}
        centered
        title='认证申请'
        visible={true}
        bodyStyle={{ paddingBottom: '15px' }}
        confirmLoading={false}
        onCancel={() => tableAction(false, 'closeModal')}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        footer={false}
    >
        <div className={styles.applyContainer}>
            <Form
                onSubmit={onSubmit}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
            >
                <Form.Item label='渠道账号'>
                    {getFieldDecorator('accountName', {
                        initialValue: accountName,
                        rules: [{ required: true, message: '请输入渠道账号' }],
                    })(<Input disabled={true} placeholder='请输入渠道账号' />)}
                </Form.Item>
                <Form.Item label='联系邮箱'>
                    {getFieldDecorator('contactMail', {
                        rules: [
                            { type: 'email', message: '请输入正确的邮箱' },
                            { required: true, message: '请输入邮箱' }
                        ],
                    })(<Input placeholder='请输入联系邮箱' />)}
                </Form.Item>
                <Form.Item label='联系人'>
                    {getFieldDecorator('contact', {
                        rules: [
                            { required: true, message: '请输入联系人' },
                            { type: 'string', max: 10, message: '联系人最多支持10个汉字' }
                        ],
                    })(<Input placeholder='请输入联系人' />)}
                </Form.Item>
                <Form.Item label='联系电话'>
                    {getFieldDecorator('contactTel', {
                        rules: [
                            { required: true, message: '请输入联系电话' },
                            { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号' }
                        ],
                    })(<Input placeholder='请输入联系电话' />)}
                </Form.Item>
                <Form.Item label='营业执照' style={{ margingBottom: '10px', height: '105px' }}>
                    {getFieldDecorator('bizLicenseUrl', {
                        valuePropName: 'fileList',
                        getValueFromEvent: e => {
                            if (Array.isArray(e)) return e;
                            return e && e.fileList;
                        },
                        rules: [
                            { type: 'array', required: true, message: '请上传营业执照' }
                        ],
                    })(
                        <CusotmUpload
                            accept='.png, .jpg, .jpeg'
                            listType="picture-card"
                            wallMode="single"
                            removeError
                            qiniuUploadProps={{
                                uploadType: 'image'
                            }}
                            beforeUpload={(file) => {
                                const isType = ['image/png', 'image/jpeg', 'image/jpg'];
                                if (!isType.includes(file.type)) {
                                    message.warning('只能上传png,jpg,jpeg格式的图片!');
                                    file.status = 'error';
                                    return false;
                                } else {
                                    return isType;
                                }
                            }}
                        >
                            <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                        </CusotmUpload>)}
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 20, offset: 4 }}
                    label={<Text><Text>经营类目：</Text><Text type='secondary'>（请根据合作情况、申请人的经营品类勾选）</Text></Text>}>
                    {getFieldDecorator('bizScopes', {
                        rules: [
                            { type: 'array', required: true, message: '请选择经营类目' }
                        ],
                    })(
                        <Checkbox.Group>
                            {
                                classList.map(i => {
                                    return <Checkbox key={i.categoryId} value={i.categoryId}>{i.paramValue}</Checkbox>;
                                })
                            }
                        </Checkbox.Group>
                    )}
                </Form.Item>
                <div className={styles.footerBOx}>
                    <Button disabled={loading} onClick={() => tableAction(false, 'closeModal')} style={{ marginRight: '20px' }}>取消</Button>
                    <Button loading={loading} htmlType='submit' type='primary'>确定</Button>
                </div>
            </Form>
        </div>
    </Modal >;
};
ApplyModal.propTypes = {
    form: PropTypes.object,
    tableAction: PropTypes.func,
    modalParmas: PropTypes.object,
    getClassList: PropTypes.func,
    classList: PropTypes.array
};
export default connect(state => ({
    classList: state.channelmanage.classList,
}), { getClassList })(Form.create()(ApplyModal));