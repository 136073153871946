/**
 * 【消息中心】
 */
import React from 'react';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XOKButton, XSelect, XCancelButton, XInput, XTabNav, showConfirm } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message, } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getMessageClassifyLists } from '@/reducers/messagemanage/messageClassify/actions';
import { getMessageCenterUnReadList, getMessageCenterReadList } from '@/reducers/messagemanage/messageCenter/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {
    state = {
        tab: [
            { id: 1, label: '未读' },
            { id: 2, label: '已读' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
        orderID: '', // 当前操作的订单ID 
        messageTypeObj: {},
        messageName: '',
        pageSize: 10,
        pageNum: 1,
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // 未读
        // this.props.getMessageCenterUnReadList({ pageNum: 1, pageSize: 10 });
        // 已读
        // this.props.getMessageCenterReadList({ pageNum: 1, pageSize: 10 });
        // 获取消息类型
        this.props.getMessageClassifyLists();
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格操作
    _tableAction = (id, type) => {
        switch (type) {
            case 'del':
                showConfirm('是否确定删除？', '', () => {
                    http('/user/messageinfomis/deleteMessage', { id }, 'POST')
                        .then(() => {
                            message.success('删除成功!');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const { messageTitle, messageTypeObj, curTabID } = this.state;
        this.setState({
            pageSize,
            pageNum,
        });
        this.searchCache = {
            messageTitle,
            pageNum,
            pageSize,
            messageTypeId: messageTypeObj.id,
        };
        if (curTabID === 1) {
            this.props.getMessageCenterUnReadList(this.searchCache);
        } else {
            this.props.getMessageCenterReadList(this.searchCache);
        }
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 查询
    _searchHandle = (useCache) => {
        const { messageTitle, messageTypeObj, curTabID, pageNum, pageSize } = this.state;
        this.searchCache = {
            messageTitle,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            messageTypeId: messageTypeObj.id,
        };
        if (curTabID === 1) {
            this.props.getMessageCenterUnReadList(this.searchCache);
        } else {
            this.props.getMessageCenterReadList(this.searchCache);
        }
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置
    _resetHandle = () => {
        // const { curTabID } = this.state;
        this.setState({
            messageTypeObj: {},
            messageTitle: '',
            pageNum: 1,
            pageSize: 10,
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        // if (curTabID === 1) {
        //     this.props.getMessageCenterUnReadList({ pageNum: 1, pageSize: 10 });
        // } else {
        //     this.props.getMessageCenterReadList({ pageNum: 1, pageSize: 10 });
        // }
    }
    // 消息类型
    _messageTypeChange = (e) => {
        this.setState({
            messageTypeObj: e,
        });
    }
    render() {
        const { curTabID, messageTitle, messageTypeObj } = this.state;
        const { messageCenterUnReadList, messageCenterReadList, messageClassifyLists } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <div style={{ marginTop: 10 }}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='消息名称'
                                    placeholder='请输入'
                                    value={messageTitle}
                                    bindThis={this}
                                    bindName='messageTitle'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='消息类型'
                                    placeholder='请选择'
                                    renderData={messageClassifyLists}
                                    onChange={this._messageTypeChange}
                                    dataIndex='messageTypeName'
                                    keyIndex='id'
                                    value={messageTypeObj.messageTypeName}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <OrderTable renderData={curTabID === 1 ? messageCenterUnReadList : messageCenterReadList} tableAction={this._tableAction} paginationChange={this._paginationChange} curTabID={curTabID} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getMessageCenterUnReadList: PropTypes.func,
    getMessageClassifyLists: PropTypes.func,
    messageClassifyLists: PropTypes.array,
    messageCenterUnReadList: PropTypes.object,
    getMessageCenterReadList: PropTypes.func,
    messageCenterReadList: PropTypes.object,
};
const mapStateToProps = (state) => ({
    messageCenterUnReadList: state.MessageCenter.messageCenterUnReadList,
    messageCenterReadList: state.MessageCenter.messageCenterReadList,
    messageClassifyLists: state.MessageClassify.messageClassifyLists,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getMessageCenterUnReadList, getMessageCenterReadList, getMessageClassifyLists })(Main);
