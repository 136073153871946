import React from 'react';
import styles from './OrderSearch.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker } from '@/components/xqxc_ui';

const orderStateSelect = [{id:1, name:'待付款'},{id:2, name:'待发款'}];
const orderTypeSelect = [{id:1, name:'普通订单'},{id:2, name:'报税订单'}];
const invoiceSelect = [{id:1, name:'是'},{id:2, name:'否'}];

class OrderSearch extends React.Component {

    state = {
        telephone: '', // 会员手机
        orderCode: '', // 订单编号
        orderStartTime: null, // 下单时间开始日期
        orderEndTime: null, // 下单结束日期
        orderState: {}, // 订单状态
        orderType: {}, // 订单类型
        invoice: {}, // 是否开票
        productName: '', // 商品名称
        productCode: '', // 商品货号
    }

    _renderOrderStateSelect = (orderStateSelect, orderState) => {
        if(this.props.orderState == 1){
            return(
                <XSelect 
                    style={{marginLeft:'20px', width: '218px'}} 
                    selectStyle={{width: '150px'}}
                    label='订单状态' 
                    placeholder='请选择订单状态' 
                    renderData={orderStateSelect}
                    dataIndex='name'
                    keyIndex='id'
                    value={orderState.name}
                    showSearch={true}
                    bindThis={this}
                    bindName='orderState'
                />
            );
        }else{
            return null;
        }
    }

    // 查询
    _searchHandle = () => {
        console.log(this.state);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            telephone: '', 
            orderCode: '', 
            orderStartTime: null, 
            orderEndTime: null, 
            orderState: {}, 
            orderType: {}, 
            invoice: {}, 
            productName: '', 
            productCode: '', 
        });
    }

    render(){
        const { telephone, orderCode, orderStartTime, orderEndTime, orderState, orderType, invoice, productName, productCode } = this.state;
        
        return(
            <div className={styles.container}>
                <div className={styles.row1}>
                    <XInput 
                        style={{width:'248px'}}
                        inputStyle={{width:'180px'}} 
                        label='工单号' 
                        placeholder='请输入工单号' 
                        value={telephone}
                        bindThis={this}
                        bindName='telephone'
                    />
                    <XDatePicker 
                        style={{width:'264px', marginLeft:'20px'}} 
                        label='提交时间' 
                        value={orderStartTime} 
                        bindThis={this}
                        bindName='orderStartTime'
                    />
                    <div className={styles.timeLine} />
                    <XDatePicker  
                        label=''
                        value={orderEndTime} 
                        bindThis={this}
                        bindName='orderEndTime'
                    />
                    {this._renderOrderStateSelect(orderStateSelect, orderState)}
                    <XSelect 
                        style={{marginLeft:'20px', width: '218px'}} 
                        selectStyle={{width: '150px'}}
                        label='工单状态' 
                        placeholder='请选择工单状态' 
                        renderData={orderTypeSelect}
                        dataIndex='name'
                        keyIndex='id'
                        value={orderType.name}
                        showSearch={true}
                        bindThis={this}
                        bindName='orderType'
                    />
                    <XSelect 
                        style={{width: '248px', marginLeft:'20px'}} 
                        selectStyle={{width: '180px'}}
                        label='反馈类型' 
                        placeholder='请选择反馈类型' 
                        renderData={invoiceSelect}
                        dataIndex='name'
                        keyIndex='id'
                        value={invoice.name}
                        showSearch={true}
                        bindThis={this}
                        bindName='invoice'
                    />
                </div>
                <div className={styles.row2}>
                    <XSelect 
                        style={{width: '248px' }} 
                        selectStyle={{width: '180px'}}
                        label='优先级' 
                        placeholder='请选择优先级' 
                        renderData={invoiceSelect}
                        dataIndex='name'
                        keyIndex='id'
                        value={invoice.name}
                        showSearch={true}
                        bindThis={this}
                        bindName='invoice'
                    />
                    <XOKButton 
                        style={{marginLeft:'20px', width:'60px'}} 
                        label='查询'
                        onClick={this._searchHandle} 
                    />
                    <XCancelButton 
                        style={{marginLeft:'20px', width:'60px'}} 
                        label='重置' 
                        onClick={this._resetHandle} 
                    />
                </div>
            </div>
        );
    }
}

OrderSearch.propTypes = {
    orderState: PropTypes.number
};

export default OrderSearch;