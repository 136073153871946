/**
 * 图文管理 Saga集合
 */
import graphicscategory from './graphicscategory/model';
import graphicslist from './graphicslist/model';

export default [
    ...Object.values(graphicscategory.sagas), // 图文分类
    ...Object.values(graphicslist.sagas), // 图文列表
];
