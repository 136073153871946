import { combineReducers } from 'redux';
import { getEmplList, getEmplDetail, getDeptSelect, getEmplState, getEmplType } from './reducer';

export default combineReducers({
	getEmplList, // 获取员工列表
	getEmplDetail, // 获取员工详情
	getDeptSelect, // 获取部门下拉列表
	getEmplState, // 获取员工状态
	getEmplType, // 获取员工所属分类
});

