import { createModel } from '@/store/tools';

const model = {
    namespace: 'operaterecord',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getOperateRecordList': T('getOperateRecordList'),
        },
        actions: {
            'getOperateRecordList': A('getOperateRecordList'),
        },
        sagas: {
            'getOperateRecordList': S('getOperateRecordList', '/wholesalegoods/operatelog/listWholesaleGoodsOperateLog'),
        },
        reducers: {
            'operateRecordList': R('getOperateRecordList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);