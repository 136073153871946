/**
 * 设置特权值比例
 */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './modalStyle.module.scss';
import http from '@/assets/api/http';
import { Modal, Input, InputNumber, message, Tree } from 'antd';
import { XTabNav } from '@/components/xqxc_ui';

const { TextArea } = Input;
const { TreeNode } = Tree;

const SetBusinessCategory = ({
    closeMode,
    openModeParam: {
        id
    } = {}
}) => {

    const [loading, setLoading] = useState(false);
    const [curTabID, setCurTabID] = useState(1);
    const [onLineTreeData, setOnLineTreeData] = useState([]);
    const [cityTreeData, setCityTreeData] = useState([]);
    const [treeList, setTreeList] = useState([]);
    const checkedKeys = useRef([]);
    const defaultList = useRef([]);
    const onLineList = useRef([]);
    const cityList = useRef([]);
    const tab = [
        { id: 1, label: '线上商品分类' },
        // { id: 2, label: '同城商品分类' },
    ];


    useEffect(() => {
        getData();
    }, []);

    const tabChangeHandle = (req) => {
        setCurTabID(req);
    };

    const getData = () => {
        tab.forEach(val => {
            http('/goods/category/listCategoryItemWithMerchant', {
                merchantId: id,
                categorySubType: val.id == 1 ? 'ONLINE' : 'CITY',
            }, 'POST')
                .then(res => {
                    renderData(res.result);
                    val.id == 1 ? setOnLineTreeData(res.result) : setCityTreeData(res.result);
                    // getRenderParentId(res.result);
                }).catch((e = {}) => {
                    message.error(e.message);
                });
        });
    };

    const renderData = (data) => {
        if (!data) return;
        data.forEach(item => {
            if (item.categoryInfoList) {
                if (item.categoryInfoList.some(val => val.selected == true)) {
                    item.parentId && (defaultList.current = [...defaultList.current, String(item.parentId), String(item.id)]);
                }
                renderData(item.categoryInfoList);
            } else if (item.selected) {
                checkedKeys.current = [...checkedKeys.current, String(item.id)];
                defaultList.current = [...defaultList.current, String(item.id)];
            }
        });
    };

    const renderTreeNodes = (data) => {
        if (!data) return;
        let list = data.map(item => {
            if (item.categoryInfoList) {
                return (
                    <TreeNode title={item.categoryName} key={item.id} dataRef={item}>
                        {renderTreeNodes(item.categoryInfoList)}
                    </TreeNode>
                );
            }
            return <TreeNode title={item.categoryName} key={item.id} />;
        });
        return list;
    };

    const onCheck = (selectedKeys, info) => {
        let arr = [];
        defaultList.current = [];
        arr = info.checkedNodes.map(val => val.key);
        if (curTabID == 1) {
            onLineList.current = arr.concat(info.halfCheckedKeys);
        } else {
            cityList.current = arr.concat(info.halfCheckedKeys);
        }
        setTreeList([...onLineList.current, ...cityList.current]);
        checkedKeys.current = [...selectedKeys];
    };

    const onsubmit = () => {
        console.log(checkedKeys.current);
        console.log(treeList);
        console.log(defaultList.current);
        let arr = [...treeList, ...defaultList.current];
        if (arr.length == 0) {
            return message.error('请选择分类');
        }
        setLoading(true);
        http('/goods/category/saveMerchantCategory', {
            merchantId: id,
            categoryIdList: arr,
        }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            }).catch((e = {}) => {
                setLoading(false);
                message.error(e.message);
            });
    };

    return <Modal
        width={860}
        centered
        bodyStyle={{ paddingBottom: '10px' }}
        title='设置经营品类'
        visible={true}
        confirmLoading={loading}
        onOk={onsubmit}
        onCancel={() => closeMode()}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='提交'
    >
        <div className={styles.container}>
            <XTabNav renderData={tab} onChange={tabChangeHandle} activedID={curTabID} />
            <div className={curTabID == 1 ? styles.onLineTreeItem : styles.treeItem}>
                {onLineTreeData && onLineTreeData.length > 0 ? <Tree
                    checkable
                    onCheck={onCheck}
                    checkedKeys={checkedKeys.current}
                >
                    {renderTreeNodes(onLineTreeData)}
                </Tree> : <div className={styles.noData}>暂无分类数据</div>}
            </div>
            <div className={curTabID == 2 ? styles.cityTreeItem : styles.treeItem}>
                {cityTreeData && cityTreeData.length > 0 ? <Tree
                    checkable
                    onCheck={onCheck}
                    checkedKeys={checkedKeys.current}
                >
                    {renderTreeNodes(cityTreeData)}
                </Tree> : <div className={styles.noData}>暂无分类数据</div>}
            </div>
        </div>
    </Modal>;
};

SetBusinessCategory.propTypes = {
    closeMode: PropTypes.func.isRequired,
    openModeParam: PropTypes.object
};

export default SetBusinessCategory;