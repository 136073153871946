/**
 * 频道列表
 */
import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { XTabNav } from '@/components/xqxc_ui';
import LiveList from './livelist';
import VideoList from './videolist';
import CommunityList from './communitylist';
import model from '@/reducers/knowledgepay/channellist/model';

const tabPages = [
    { id: 1, label: '直播间列表' },
    { id: 2, label: '视频列表' },
    { id: 3, label: '社区列表' },
    // { id: 4, label: '音频列表' },
];

const ChannelList = ({ history, getKWhetherRecSele, kWhetherRecSele }) => {

    const [curTabID, setCurTabID] = useState(1);

    useEffect(() => {
        getKWhetherRecSele();
        if (history.location.state && history.location.state.tabID) {
            setCurTabID(history.location.state.tabID);
        }
    }, []);

    // 渲染相应Tab导航下的内容
    const _renderPage = () => {
        switch (curTabID) {
            case 1:
                return <LiveList curTabID={curTabID} recEnum={kWhetherRecSele} history={history} />;
            case 2:
                return <VideoList curTabID={curTabID} recEnum={kWhetherRecSele} history={history} />;
            case 3:
                return <CommunityList curTabID={curTabID} recEnum={kWhetherRecSele} history={history} />;
            default:
                return <LiveList curTabID={curTabID} recEnum={kWhetherRecSele} history={history} />;
        }
    };

    return (
        <div className={styles.flexBoxContainer}>
            <XTabNav renderData={tabPages} onChange={(tabID) => setCurTabID(tabID)} activedID={curTabID} />
            {_renderPage()}
        </div>
    );
};

ChannelList.propTypes = {
    history: PropTypes.object, // 路由history对象
    getKWhetherRecSele: PropTypes.func,
    kWhetherRecSele: PropTypes.array
};

const mapStateToProps = (state) => ({
    kWhetherRecSele: state.klist.kWhetherRecSele,
});

export default connect(mapStateToProps, { ...model.actions })(ChannelList);