import { createModel } from '@/store/tools';

const model = {
    namespace: 'otherpay',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getQueryOutRemitList': T('getQueryOutRemitList'),
            'getRemitStatusEnum': T('getRemitStatusEnum'),
            'getYouFuPayVerifyStatusEnum': T('getYouFuPayVerifyStatusEnum'),
            'getYouFuPayOutServiceNameEnum': T('getYouFuPayOutServiceNameEnum'),
            'getYouFuPaySignStateEnum': T('getYouFuPaySignStateEnum'),
            'getYouFuPayMerEnum': T('getYouFuPayMerEnum'),
            'getListSignPage': T('getListSignPage'),
            'getSignDetail': T('getSignDetail'),
            'getListStatistics': T('getListStatistics'),
            'getQueryOutRemitBatchList': T('getQueryOutRemitBatchList'),
        },
        actions: {
            'getQueryOutRemitList': A('getQueryOutRemitList'),
            'getRemitStatusEnum': A('getRemitStatusEnum'),
            'getYouFuPayVerifyStatusEnum': A('getYouFuPayVerifyStatusEnum'),
            'getYouFuPayOutServiceNameEnum': A('getYouFuPayOutServiceNameEnum'),
            'getYouFuPaySignStateEnum': A('getYouFuPaySignStateEnum'),
            'getYouFuPayMerEnum': A('getYouFuPayMerEnum'),
            'getListSignPage': A('getListSignPage'),
            'getSignDetail': A('getSignDetail'),
            'getListStatistics': A('getListStatistics'),
            'getQueryOutRemitBatchList': A('getQueryOutRemitBatchList'),
        },
        sagas: {
            'getQueryOutRemitList': S('getQueryOutRemitList', '/youfuOut/queryOutRemitList'), // 列表：付款记录
            'getRemitStatusEnum': S('getRemitStatusEnum', '/enum/RemitStatus'), // 枚举：付款状态
            'getYouFuPayVerifyStatusEnum': S('getYouFuPayVerifyStatusEnum', '/enum/YouFuPayVerifyStatusEnum'), // 枚举：打款校验
            'getYouFuPayOutServiceNameEnum': S('getYouFuPayOutServiceNameEnum', '/enum/YouFuPayOutServiceNameEnum'), // 枚举：签约对象
            'getYouFuPaySignStateEnum': S('getYouFuPaySignStateEnum', '/enum/YouFuPaySignStateEnum'), // 枚举：签约状态
            'getYouFuPayMerEnum': S('getYouFuPayMerEnum', '/enum/YouFuPayMerEnum'), // 枚举：签约通道
            'getListSignPage': S('getListSignPage', '/youfuOut/listSignPage'), // 列表：签约记录
            'getSignDetail': S('getSignDetail', '/youfuOut/signDetail'), // 详情：签约记录
            'getListStatistics': S('getListStatistics', '/youfuOut/listStatistics'), // 列表：月度统计
            'getQueryOutRemitBatchList': S('getQueryOutRemitBatchList', '/youfuOut/queryOutRemitBatchList'), // 列表：批量付款
        },
        reducers: {
            'getQueryOutRemitList': R('getQueryOutRemitList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getRemitStatusEnum': R('getRemitStatusEnum', []),
            'getYouFuPayVerifyStatusEnum': R('getYouFuPayVerifyStatusEnum', []),
            'getYouFuPayOutServiceNameEnum': R('getYouFuPayOutServiceNameEnum', []),
            'getYouFuPaySignStateEnum': R('getYouFuPaySignStateEnum', []),
            'getYouFuPayMerEnum': R('getYouFuPayMerEnum', []),
            'getListSignPage': R('getListSignPage', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getSignDetail': R('getSignDetail', {}),
            'getListStatistics': R('getListStatistics', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getQueryOutRemitBatchList': R('getQueryOutRemitBatchList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);