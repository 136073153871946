/**
 * 水印管理
 */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class Org extends React.Component {

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航只保留到2级
    }

    render() {
        return (
            <Main history={this.props.history} />
        );
    }
}

Org.propTypes = {
    history: PropTypes.object, // router history
    keepSecondNavBreadcrumb: PropTypes.func // 保持两级面包屑导航
};

export default connect(null, { keepSecondNavBreadcrumb })(Org);