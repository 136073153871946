/**
 * 视频审核页
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker } from '@/components/xqxc_ui';
import TableMain from './conponents/TableMain';
import { connect } from 'react-redux';
import { GoodsClassify } from '@/vcomps';
import http from '@/assets/api/http';

import OnlinePlayModal from '../../modal/OnlinePlayModal';
import DetailModal from '../../modal/DetailModal';
import AdvocacyInfo from '../../modal/AdvocacyInfo';
import AuditModal from '../../modal/AuditModal';
import AdvocacyAudit from '../../modal/AdvocacyAudit';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getCommodityReviewList, getCommodityReviewSele, getCommodityReviewType } from '@/reducers/auditmanage/commodityReview/actions';
import { getListVideoSelect } from '@/reducers/showmanage/channellist/actions';
import { SearchBox, SearchItem } from '@/components/Layout';

import { Button, Modal, message } from 'antd';
const { confirm } = Modal;

const Mode = {
    AUDIT_MODAL: 'AUDIT_MODAL',
    ONLINE_MODAL: 'ONLINE_MODAL',
    DETAIL_MODAL: 'DETAIL_MODAL'
};


const CommodityReviewTypes = [
    { key: 'GIVEN', value: '已赠送' },
    { key: 'NOT_GIVE', value: '未赠送' },
];


class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tel: undefined,
            nickName: undefined,
            status: {},
            videoType: {},
            feedClass: {}, // 视频分类
            systemType: {},
            categoryId: '', // 商品联级分类
            startDate: '',
            endDate: '',
            selectedRowKeys: []
        };
        this.cleanRef = null;
    }

    componentDidMount() {
        this.props.getCommodityReviewType();
        this.props.getCommodityReviewSele();
        this.props.getListVideoSelect(); //视频分类
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, categoryId, goodsName, scoreBeginTime, scoreEndTime, status, goodsNo, feedClass, systemType } = this.state;
        this.searchCache = {
            categoryId, goodsName, status: status.key, scoreBeginTime, scoreEndTime, goodsNo,
            feedClassId: feedClass.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getCommodityReviewList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            categoryId: '',
            goodsName: '',
            scoreBeginTime: '',
            scoreEndTime: '',
            goodsNo: '',
            status: {},
            videoType: {},
            feedClass: {},
            systemType: {}
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        this.cleanRef.clean();
    }


    _showConfirm = () => {
        let that = this;
        let ids = that.state.selectedRowKeys;
        if (ids.length !== 0) {
            confirm({
                title: '这是进行一项操作时必须了解的重要信息',
                content: (
                    <div><span style={{ color: 'red' }}>确定要赠送人气值</span><span>，你还要继续吗？</span></div>
                ),
                onOk() {
                    http('/marketing/scoreAudit/batchAudit', {
                        ids: ids
                    }).then((res = {}) => {
                        if (res.code === 200) {
                            // this.setState({ payStatus: res.result });
                            console.log('-------------执行成功', res.result);
                            that.setState({
                                selectedRowKeys: []
                            });
                            that._searchHandle('useCache');
                        }
                    });
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } else {
            message.warning('请勾选商品');
        }

    }

    _tableAction = (id, record, type) => {
        switch (type) {
            case 'audit':
                this.setState({ openModeParam: id, currentOpenMode: Mode.AUDIT_MODAL, audit: record });
                break;
            case 'detail':
                this.setState({ openModeParam: id, currentOpenMode: Mode.DETAIL_MODAL, audit: record });
                break;
            case 'online':
                this.setState({ openModeParam: id, currentOpenMode: Mode.ONLINE_MODAL, audit: record });
                break;
        }
    }

    _renderMode = () => {
        const { currentOpenMode, audit } = this.state;
        switch (currentOpenMode) {
            case Mode.AUDIT_MODAL:
                // 是否有代言(1是;0否)
                if (audit.isTask) {
                    return <AdvocacyAudit visible={true} data={this.state.openModeParam} audit={audit} closeMode={this._closeMode} />;
                } else {
                    return <AuditModal visible={true} data={this.state.openModeParam} audit={audit} closeMode={this._closeMode} />;
                }
            case Mode.ONLINE_MODAL:
                return <OnlinePlayModal visible={true} data={this.state.openModeParam} audit={audit} closeMode={this._closeMode} />;
            case Mode.DETAIL_MODAL:
                // 是否有代言(1是;0否)
                if (audit.isTask) {
                    return <AdvocacyInfo visible={true} data={this.state.openModeParam} audit={audit} closeMode={this._closeMode} />;
                } else {
                    return <DetailModal visible={true} data={this.state.openModeParam} audit={audit} closeMode={this._closeMode} />;
                }
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', audit: {} }, () => {
            if (update) {
                this._searchHandle('useCache');
            }
        });
    }

    callbackHandle = (key) => {
        this.setState({
            selectedRowKeys: key
        });
        console.log('-------------------......>>>', this.state.selectedRowKeys);
    }

    render() {
        const { goodsName, goodsNo, categoryId, scoreBeginTime, scoreEndTime, systemType, endDate, startDate, status, op } = this.state;
        const { videoData, selectDataSource, listVideoSelect, systemTypeSelect } = this.props;

        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商品名称'
                                placeholder='请输入商品名称'
                                bindThis={this}
                                bindName='goodsName'
                                value={goodsName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='商品货号'
                                placeholder='请输入商品货号'
                                bindThis={this}
                                bindName='goodsNo'
                                value={goodsNo}
                            />
                        </SearchItem>
                        <SearchItem>
                            <GoodsClassify
                                style={{ marginRight: '20px' }}
                                label='商品分类'
                                ref={ele => this.cleanRef = ele}
                                onClassifyChange={value => this.setState({ categoryId: value })}
                            />
                        </SearchItem>
                        <SearchItem>
                            {console.log('----------??', systemTypeSelect)}
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={CommodityReviewTypes}
                                bindThis={this}
                                bindName='status'
                                dataIndex='value'
                                keyIndex='key'
                                value={status.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='评论时间'
                                bindThis={this}
                                bindName='scoreBeginTime'
                                value={scoreBeginTime}
                                isFormat={true}
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                bindThis={this}
                                bindName='scoreEndTime'
                                value={scoreEndTime}
                                isFormat={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div>
                        <SearchItem>
                            <Button type="primary" onClick={this._showConfirm}>批量赠送</Button>
                        </SearchItem>
                    </div>
                    <TableMain selectedRowKeys={this.state.selectedRowKeys} callback={this.callbackHandle} paginationChange={this._paginationChange} renderData={videoData} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}


Main.propTypes = {
    getCommodityReviewList: PropTypes.func, // 通过Saga异步请求表格数据源的Action
    getAnchorCoverSelect: PropTypes.func,
    getCommodityReviewSele: PropTypes.func,
    videoData: PropTypes.object,
    selectDataSource: PropTypes.array,
    getListVideoSelect: PropTypes.func, //视频分类
    listVideoSelect: PropTypes.object,
    getCommodityReviewType: PropTypes.func,
    systemTypeSelect: PropTypes.array,
};

const mapStateToProps = (state) => {
    return {
        videoData: state.commodityReview.commodityReviewList,
        selectDataSource: state.commodityReview.auditStatusSelect,
        listVideoSelect: state.channellist.listVideoSelect,
        systemTypeSelect: state.commodityReview.getEnumSystemType,
    };
};

export default connect(mapStateToProps, { getCommodityReviewList, getCommodityReviewSele, getListVideoSelect, getCommodityReviewType })(Main);