/**
 * 反馈类型列表
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { message, } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';
// import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import AdvertTable from './components/AdvertTable';
import CreateModal from './modal/CreateModal';
import { getFeedbackTypeList } from '@/reducers/customermanage/feedbackType/actions';
import http from '@/assets/api/http';


class Main extends React.Component {

    state = {
        ideaBackName: '',//原因标题
    }

    componentDidMount() {
        // this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getFeedbackTypeList();
    }

    // 查询
    _searchHandle = () => {
        const { ideaBackName } = this.state;
        this.props.getFeedbackTypeList({ ideaBackName });
    }

    // 重置
    _resetHandle = () => {
        this.setState({ ideaBackName: '' });
        this.props.getFeedbackTypeList();
    }

    // 新建用户
    _newHandle = () => {
        this.setState({ currentOpenMode: 'create' });
    }
    // 表格操作
    _tableAction = (item, type, value) => {
        console.log(item, type, value);
        const { ideaBackName } = this.state;
        switch (type) {
            case 'switch':
                {
                    http('/ideaBack/editIdeaBackType', {
                        ideaBackCode: item.ideaBackCode,
                        state: (value === false) ? 0 : 1,
                    }, 'POST').then(() => {
                        message.success('状态修改成功');
                        this.props.getFeedbackTypeList({ ideaBackName });
                    }).catch(() => {
                        message.error('状态修改异常');
                    });
                }
                break;
            case 'sort':
                {
                    http('/ideaBack/editIdeaBackType', {
                        ideaBackCode: item.ideaBackCode,
                        sortNo: value,
                    }, 'POST').then(() => {
                        message.success('排序成功');
                        this.props.getFeedbackTypeList({ ideaBackName });
                    }).catch(() => {
                        message.error('排序异常');
                    });
                }
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'create':
                return <CreateModal title='创建反馈类型' visible={true} refsh={this._searchHandle} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

    render() {
        const { ideaBackName } = this.state;
        const { feedbackTypeList } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
                <SearchBox>
                    <SearchItem>
                        <XInput
                            label='原因标题'
                            placeholder='请输入'
                            value={ideaBackName}
                            bindThis={this}
                            bindName='ideaBackName'
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </SearchItem>
                </SearchBox>
                <div className={styles.row2}>
                    <XOKButton
                        style={{ width: '90px' }}
                        label='新建'
                        onClick={this._newHandle}
                    />
                </div>
                <AdvertTable renderData={feedbackTypeList} tableAction={this._tableAction} />
                {this._renderMode()}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    // addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    // keepSecondNavBreadcrumb: PropTypes.func // 保持两级面包屑导航
    getFeedbackTypeList: PropTypes.func,
    feedbackTypeList: PropTypes.array,
};
const mapStateToProps = (state) => ({
    feedbackTypeList: state.FeedbackType.feedbackTypeList,
});

export default connect(mapStateToProps, { getFeedbackTypeList })(Main);
