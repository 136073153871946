import React from 'react';
import styles from './TableContent.module.scss';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell } from '@/components/TableCell';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { isPlat } from '@/assets/js/authType';

class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '对账单号',
            dataIndex: 'id',
            key: 'id',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '截止日期',
            dataIndex: 'reconDate',
            key: 'reconDate',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '运营中心',
            dataIndex: 'operationsName',
            key: 'operationsName',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '区域公司',
            dataIndex: 'areaCompanyName',
            key: 'areaCompanyName',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '来源类型',
            dataIndex: 'sourceType.value',
            key: 'sourceType.value',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单数',
            dataIndex: 'orderQty',
            key: 'orderQty',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '已完成业绩(元)',
            dataIndex: 'orderAmount',
            key: 'orderAmount',
            width: cellWidth.normal,
            align: 'left',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '状态',
            dataIndex: 'status.value',
            key: 'status.value',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '核定时间',
            dataIndex: 'checkTime',
            key: 'checkTime',
            width: cellWidth.time,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '完成时间',
            dataIndex: 'finishTime',
            key: 'finishTime',
            width: cellWidth.time,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'left',
            fixed: 'right',
            width: cellWidth.time,
            render: (text, item) => this._renderActionCell(item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderActionCell = (item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction('detail', item)}>详情</div>
                <ButtonPerssion code='operationcenter/liwu/sh'>
                    {item.status && (item.status.code == 'WAIT_CHECK' || item.status.code == 'STOP_CHECK') && isPlat() ? <div className={styles.item} onClick={() => tableAction('delete', item)}>删除</div> : null}
                </ButtonPerssion>
                <ButtonPerssion code='operationcenter/liwu/hdwj'>
                    {item.status && item.status.code == 'STOP_CHECK' && isPlat() ? <div className={styles.item} onClick={() => tableAction('finish', item)}>核对完结</div> : null}
                </ButtonPerssion>
            </div>
        );
    }

    render() {
        const { paginationChange, scrollY } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default XTableHOC(TableContent, 40);