import React from 'react';
import styles from './AdvertTable.module.scss';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table, Badge } from 'antd';
import { isPlat } from '@/assets/js/authType'; // 总控
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, priceFenToYuanCell, cellWidth } from '@/components/TableCell';

class AdvertTable extends React.Component {
    state = {
        account: '',
        selectedRowKeys: [],
        userRole: '', // 用户角色
    }

    _takeColumns = () => ([
        {
            title: '对账单号',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '运营中心',
            dataIndex: 'targetName',
            key: 'targetName',
            align: 'center',
            width: cellWidth.time,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '对账周期',
            dataIndex: 'startDate',
            key: 'startDate',
            align: 'center',
            width: cellWidth.time,
            render: (text, item) => {
                return <div>
                    <div>{text}</div>
                    <div>{item.endDate}</div>
                </div>;
            }
        },
        {
            title: '对账金额（元）',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center',
            width: cellWidth.normal,
            render: priceFenToYuanCell
        },
        {
            title: '营销服务收益（元）',
            dataIndex: 'marketingAmount',
            key: 'marketingAmount',
            align: 'center',
            width: cellWidth.normal,
            render: priceFenToYuanCell
        },
        {
            title: '特权值收益(元)',
            dataIndex: 'integralAmount',
            key: 'integralAmount',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '管理费（元）',
            dataIndex: 'operationCenterManagementFee',
            key: 'operationCenterManagementFee',
            align: 'center',
            width: cellWidth.normal,
            render: priceFenToYuanCell
        },
        {
            title: '管理津贴收益（元）',
            dataIndex: 'manageAllowanceIncome',
            key: 'manageAllowanceIncome',
            align: 'center',
            width: cellWidth.normal,
            render: priceFenToYuanCell
        },
        {
            title: '自然流量服务费（元）',
            dataIndex: 'naturalFlowServiceFee',
            key: 'naturalFlowServiceFee',
            align: 'center',
            width: cellWidth.normal,
            render: priceFenToYuanCell
        },
        {
            title: '企业主播收益（元）',
            dataIndex: 'anchorManageFee',
            key: 'anchorManageFee',
            align: 'center',
            width: cellWidth.normal,
            render: priceFenToYuanCell
        },
        {
            title: '服务管理收益（元）',
            dataIndex: 'serviceManageFee',
            key: 'serviceManageFee',
            align: 'center',
            width: cellWidth.normal,
            render: priceFenToYuanCell
        },
        {
            title: '补贴收益（元）',
            dataIndex: 'subsidyIncome',
            key: 'subsidyIncome',
            align: 'center',
            width: cellWidth.normal,
            render: priceFenToYuanCell
        },
        {
            title: '开票金额（元）',
            dataIndex: 'settleAmount',
            key: 'settleAmount',
            align: 'center',
            width: cellWidth.normal,
            render: priceFenToYuanCell
        },
        {
            title: '未票金额（元）',
            dataIndex: 'unSettleAmount',
            key: 'unSettleAmount',
            align: 'center',
            width: cellWidth.normal,
            render: priceFenToYuanCell
        },
        {
            title: '已付金额（元）',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
            align: 'center',
            width: cellWidth.normal,
            render: priceFenToYuanCell
        },
        {
            title: '未付金额（元）',
            dataIndex: 'unpaidAmount',
            key: 'unpaidAmount',
            align: 'center',
            width: cellWidth.normal,
            render: priceFenToYuanCell
        },
        {
            title: '生成时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: cellWidth.time,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'statusDescript',
            key: 'statusDescript',
            width: cellWidth.normal,
            align: 'center',
            render: (text, item) => (this._renderStatusCell(text, item.status))
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            fixed: 'right',
            width: 250,
            render: (id, item) => this._renderActionCell(item.id, item)
        },
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderStatusCell = (text, status) => {
        var statusArr = ['INIT', 'TO_CONFIRM', 'CONFIRM_SUCCESS', 'CONFIRM_FAIL', 'REMIT_SUCCESS', 'REMIT_FAIL'];
        var statusBadge = ['processing', 'processing', 'processing', 'error', 'success', 'error'];
        const index = statusArr.indexOf(status);
        return index === -1 ? null : <Badge status={statusBadge[index]} text={text} />;
    }

    _renderActionCell = (id, item) => {
        const { tableAction } = this.props;
        return (
            isPlat()
                ? <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'info', item)}>详情</div>
                    {item.status === 'INIT' && <div className={styles.item} onClick={() => tableAction(id, 'send', item)}>发起对账</div>}
                    {(item.status === 'INIT' || item.status === 'CONFIRM_FAIL') && <div className={styles.item} onClick={() => tableAction(id, 'delete', item)}>删除</div>}
                    {['CONFIRM_SUCCESS', 'REMIT_SUCCESS', 'REMIT_FAIL'].includes(item.status) && <div className={styles.item} onClick={() => tableAction(id, 'manualPay', item)}>手工汇款</div>}
                </div>
                : <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'info', item)}>详情</div>
                    {
                        item.status === 'TO_CONFIRM'
                            ? <div className={styles.item} onClick={() => tableAction(id, 'confir', item)}>确认对账</div>
                            : item.allowCreateInvoice
                                ? <div className={styles.item} onClick={() => tableAction(id, 'createinvoice', item)}>新建发票管理</div>
                                : null
                    }
                </div>
        );
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys, selectedRows });
            this.props.onSelection(selectedRowKeys, selectedRows);
        },
    };

    onChange = (pagination, filters, sorter) => {
        console.log('params', pagination, filters, sorter);
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    render() {
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                {/* <XTableSelectionCount selectedNum={.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    //rowSelection={this._rowSelection}
                    rowKey={(record) => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    scrollY: PropTypes.any,
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    onSelection: PropTypes.func
};

export default XTableHOC(AdvertTable);