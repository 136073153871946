/**
 *  商户管理 - 商户列表
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const Mode = {
    MER_EDITOR: 'MER_EDITOR',//编辑
    MER_DETAIL: 'MER_DETAIL',//详情
    MER_STOR: 'MER_STOR',//店铺
    NEWCREATE: 'NEWCREATE'//新建
};

class merchantlist extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value, params) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case Mode.MER_STOR:  //店铺列表
                addOneBreadcrumbPath({
                    title: '店铺列表',
                    path: '/home/businessmanage/merchantlist/shopInfo/' + value,
                });
                history.push({
                    pathname: '/home/businessmanage/merchantlist/shopInfo/' + value,
                });
                break;
            case Mode.MER_DETAIL: //商户详情
                addOneBreadcrumbPath({
                    title: '商户详情',
                    path: '/home/businessmanage/merchantlist/detailInfo/' + value,
                });
                history.push({
                    pathname: '/home/businessmanage/merchantlist/detailInfo/' + value,
                });
                break;
            case Mode.NEWCREATE:
                addOneBreadcrumbPath({
                    title: '新建商户',
                    path: '/home/businessmanage/merchantlist/createInfo'
                });
                history.push({
                    pathname: '/home/businessmanage/merchantlist/createInfo',
                });
                break;
            case Mode.MER_EDITOR:
                addOneBreadcrumbPath({
                    title: '编辑商户',
                    path: '/home/businessmanage/merchantlist/editorInfo/' + value
                });
                history.push({
                    pathname: '/home/businessmanage/merchantlist/editorInfo/' + value,
                });
                break;
            case 'MEMBER':
                addOneBreadcrumbPath({
                    title: '商户推荐会员列表',
                    path: '/home/businessmanage/merchantlist/memberlist'
                });
                history.push({
                    pathname: '/home/businessmanage/merchantlist/memberlist',
                    userItem: {
                        merchantId: value,
                        merchantName: params
                    }
                });
                break;
            case 'EARNING':
                addOneBreadcrumbPath({
                    title: '商户会员收益明细',
                    path: '/home/businessmanage/merchantlist/earninfo'
                });
                history.push({
                    pathname: '/home/businessmanage/merchantlist/earninfo',
                    userItem: {
                        merchantId: value,
                        merchantName: params
                    }
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

merchantlist.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(merchantlist);