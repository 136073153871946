import * as T from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    resultTotal: 0
};

export const productAuditData = (state = initData, action) => {
    switch (action.type) {
        case T.SET_PRODUCT_PRODUCTAUDITDATA:
            return action.payload.result;
        default:
            return state;
    }
};

export const productAuditRateList = (state = initData, action) => {
    switch (action.type) {
        case T.SET_PRODUCTAUDIT_RATELIST:
            return action.payload.result;
        default:
            return state;
    }
};

export const productStatus = (state = [], action) => {
    switch (action.type) {
        case T.SET_PRODUCT_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};


export const productUsername = (state = {}, action) => {
    switch (action.type) {
        case T.SET_PRODUCT_USERNAME:
            return action.payload.result;
        default:
            return state;
    }
};

export const productClassifyOne = (state = [], action) => {
    switch (action.type) {
        case T.SET_PRODUCT_CLASSIFYSELE:
            return action.payload.result;
        default:
            return state;
    }
};
export const productClassifyTwo = (state = [], action) => {
    switch (action.type) {
        case T.SET_PRODUCT_CLASSIFYSELE:
            return action.payload.result;
        default:
            return state;
    }
};
export const productClassifyThree = (state = [], action) => {
    switch (action.type) {
        case T.SET_PRODUCT_CLASSIFYSELE:
            return action.payload.result;
        default:
            return state;
    }
};

export const productBusinessType = (state = [], action) => {
    switch (action.type) {
        case T.SET_PRODUCT_BUSINESSTYPE:
            return action.payload.result;
        default:
            return state;
    }
};

export const productLabelSele = (state = {}, action) => {
    switch (action.type) {
        case T.SET_PRODUCT_LABELSELE:
            return action.payload.result;
        default:
            return state;
    }
};

export const productAuditDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_PRODUCTAUDIT_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

export const productAuditRateDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_PRODUCTAUDIT_RATEDETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

export const productAuditLog = (state = initData, action) => {
    switch (action.type) {
        case T.SET_PRODUCT_AUDITLOG:
            return action.payload.result;
        default:
            return state;
    }
};

export const productRateAuditLog = (state = initData, action) => {
    switch (action.type) {
        case T.SET_PRODUCT_RATEAUDITLOG:
            return action.payload.result;
        default:
            return state;
    }
};

