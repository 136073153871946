import { combineReducers } from 'redux';
import {
	productListData, productStatus, productUsername, productBusinessType, productLabelSele,
	productClassify,
	productDetail, productRecomment
} from './reducer';
import model from './model';

export default combineReducers({
	productListData,
	productStatus,
	productUsername,
	productBusinessType,
	productLabelSele,
	productDetail,
	productClassify,
	productRecomment,
	...model.reducers
});