import * as Audit from './actionTypes';

export const getFacilitatorAuditList = (params) => ({
    type: Audit.GET_FACILITATORAUDIT_LIST,
    payload: params
});

export const getFacilitatorAuditStatus = () => ({
    type: Audit.GET_FACILITATORAUDIT_STATUS,
    payload: {}
});

export const getFacilitatorAuditDetail = (serviceId) => ({
    type: Audit.GET_FACILITATORAUDIT_DETAIL,
    payload: { serviceId }
});

export const getFacilitatorAuditLog = (serviceId) => ({
    type: Audit.GET_FACILITATORAUDIT_AUDITLOG,
    payload: { serviceId }
});