import * as T from './actiontypes';

const defaultState = {
    tableSource: [],//列表数据源
    ListDetail: {}, //详情
    paginations: {},//分页对象
    storeListTable: {},//店铺
    newStorePaginations: {},//店铺分页
};


/**
 * 存储'商户列表'数据源
 * @param {*} state 
 * @param {*} action 
 */
export const merchantAuditDate = (state = defaultState, action) => {
    let newTableSource, newListDetail, newstoreList, newStorePaginations, newPaginations;
    switch (action.type) {
        case T.SET_MERCHANT_DATAS: //存储'商户列表'数据源
            newTableSource = action.payload.result.dataList || [];
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_STOREDETAIL_DATAS: //存储'审核'数据源
            newListDetail = action.payload.result;
            return { ...state, ListDetail: newListDetail };
        case T.SET_STORE_DATAS: //存储'审核店铺'数据源
            newstoreList = action.payload.result;
            newStorePaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, storeListTable: newstoreList, newStorePaginations };
        default:
            return state;
    }
};

