/**
 * 商品列表禁用弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, Input, message, Radio } from 'antd';
import styles from './UseOrBan.module.scss';

const { TextArea } = Input;

class UseOrBan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
            reason: ''
        };
    }

    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST').then(() => {
            message.success(winTxt);
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch((error) => {
            message.error(error.message);
        });
    }

    // 点击确认
    _handleOk = () => {
        const { action, url, openModeParam } = this.props.data;
        const {  reason } = this.state;
        switch (action) {
            case 'use':
                if (reason) {
                    this._sendReq(url, { id: openModeParam.key, state: 1, reason }, '启用成功。');
                } else {
                    message.error('请输入原因！');
                }
                break;
            case 'ban':
                if (reason) {
                    this._sendReq(url, { id: openModeParam.key, state: 0, reason }, '禁用成功。');
                } else {
                    message.error('请输入原因！');
                }
                break;
        }

    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _reasonChange = (e) => {
        this.setState({ reason: e.target.value });
    }

    render() {
        const { visible, confirmLoading, reason, title } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.reason}>
                        <div className={styles.label}>原因：</div>
                        <div className={styles.content}>
                            <TextArea
                                style={{ width: '360px' }}
                                placeholder='请输入原因'
                                value={reason}
                                onChange={this._reasonChange}
                                autosize={{ minRows: 2, maxRows: 4 }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

UseOrBan.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

export default UseOrBan;