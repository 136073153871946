import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { XSelect, XOKButton, XCancelButton, XInput } from '@/components/xqxc_ui';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import TableContent from './components/TableContent';
import BanHouse from './modal/BanHouse';
import model from '@/reducers/houseresourcemanage/houselist/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class HouseList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            renderMode: '',
            modeParams: null,
            goodsName: '',
            houseTypes: {},
            merchants: {},
            houseStates: {},
        };
        this.pageSize = 20;
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getHouseTypeSele, getMerchantSele, getHouseStateSele } = this.props;
        keepSecondNavBreadcrumb();
        getHouseTypeSele();
        getMerchantSele({ pageNum: 1, pageSize: this.pageSize });
        getHouseStateSele();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { goodsName, houseTypes, merchants, houseStates, pageNum, pageSize } = this.state;
        this.searchCache = {
            goodsName,
            categoryId: houseTypes.id,
            merchantId: merchants.merchantId,
            goodsState: houseStates.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getHouseResources(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ goodsName: '', houseTypes: {}, merchants: {}, houseStates: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 分页发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { modeParams, renderMode } = this.state;
        switch (renderMode) {
            case 'banHouse':
                return <BanHouse closeMode={this._closeMode} modeParams={modeParams} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ renderMode: '', modeParams: null }, () => {
            update && this._searchHandle('useCache');
        });
    }

    //表格操作
    _tableOperate = (id, active, value) => {
        const { history } = this.props;
        switch (active) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '房源新详情',
                    path: '/home/houseresourcemanage/houselist/detail'
                });
                history.push({
                    pathname: '/home/houseresourcemanage/houselist/detail/' + id
                });
                break;
            case 'banHouse':
                this.setState({ renderMode: 'banHouse', modeParams: { id } });
                break;
            default:
                break;
        }
    }

    _companyScroll = ({ persist, target }) => {
        persist();
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            const nextPageSize = this.pageSize + 10;
            this.props.getMerchantSele({ pageNum: 1, pageSize: nextPageSize });
        }
    };

    render() {
        const { houseTypeSele, merchantSele: { merchantIdAndNames = [] }, houseStateSele, houseResources } = this.props;
        const { goodsName, houseTypes, merchants, houseStates } = this.state;
        return (
            <KeepAlive id='houseList' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='房源名称'
                                placeholder='请输入房源名称'
                                bindName='goodsName'
                                bindThis={this}
                                value={goodsName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='房源房型'
                                placeholder='请选择'
                                renderData={houseTypeSele}
                                bindName='houseTypes'
                                bindThis={this}
                                dataIndex='categoryName'
                                keyIndex='id'
                                value={houseTypes.categoryName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='所属商户'
                                placeholder='请选择'
                                renderData={merchantIdAndNames}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                bindName='merchants'
                                bindThis={this}
                                // onPopupScroll={this._companyScroll}
                                value={merchants.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={houseStateSele}
                                dataIndex='value'
                                keyIndex='code'
                                bindName='houseStates'
                                bindThis={this}
                                value={houseStates.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent paginationChange={this._paginationChange} renderData={houseResources} tableAction={this._tableOperate} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }

}

HouseList.propTypes = {
    history: PropTypes.object, // 路由history对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息
    getHouseTypeSele: PropTypes.func,//获取房源房型下拉
    houseTypeSele: PropTypes.array,//房源房型
    getMerchantSele: PropTypes.func,//获取所属商户下拉
    merchantSele: PropTypes.object,//所属商户
    getHouseStateSele: PropTypes.func,//获取房源状态下拉
    houseStateSele: PropTypes.array,//房源状态
    getHouseResources: PropTypes.func,//获取房源列表
    houseResources: PropTypes.object,//房源列表
};

HouseList.defaultProps = {
    houseResources: { dataList: [], pageNum: 1, pageSize: 10, resultTotal: 0 },
    merchantSele: { merchantIdAndNames: [] },
    houseStateSele: [],
    houseTypeSele: []
};

const mapStateToProps = ({ houseList }) => ({
    houseTypeSele: houseList.houseTypeSele,
    merchantSele: houseList.merchantSele,
    houseStateSele: houseList.houseStateSele,
    houseResources: houseList.houseResources,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(HouseList);

