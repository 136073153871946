// 获取一级、二级菜单列表
export const GET_MENU_LIST = 'MENUCONFIGURE/GET_MENU_LIST';
export const SET_MENU_LIST = 'MENUCONFIGURE/SET_MENU_LIST';

// 获取全部菜单列表
export const GET_MENU_ALL_LIST = 'MENUCONFIGURE/GET_MENU_ALL_LIST';
export const SET_MENU_ALL_LIST = 'MENUCONFIGURE/SET_MENU_ALL_LIST';

// 获取菜单详情
export const GET_MENU_DETAIL = 'MENUCONFIGURE/GET_MENU_DETAIL';
export const SET_MENU_DETAIL = 'MENUCONFIGURE/SET_MENU_DETAIL';

// 获取一级菜单下拉列表
export const GET_MENU_SELECT = 'MENUCONFIGURE/GET_MENU_SELECT';
export const SET_MENU_SELECT = 'MENUCONFIGURE/SET_MENU_SELECT';

// 获取二级菜单下拉列表
export const GET_SECOND_MENU_SELECT = 'MENUCONFIGURE/GET_SECOND_MENU_SELECT';
export const SET_SECOND_MENU_SELECT = 'MENUCONFIGURE/SET_SECOND_MENU_SELECT';
export const CLEAN_SECOND_MENU_SELECT = 'MENUCONFIGURE/CLEAN_SECOND_MENU_SELECT';

// 获取Tab菜单下拉列
export const GET_TAB_SELECT = 'MENUCONFIGURE/GET_TAB_SELECT';
export const SET_TAB_SELECT = 'MENUCONFIGURE/SET_TAB_SELECT';
export const CLEAN_TAB_SELECT = 'MENUCONFIGURE/CLEAN_TAB_SELECT';

// 获取总控菜单终端类型
export const GET_AFF_TYPE = 'MENUCONFIGURE/GET_AFF_TYPE';
export const SET_AFF_TYPE = 'MENUCONFIGURE/SET_AFF_TYPE';