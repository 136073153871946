/**
 * 人气值商城 Saga集合
 */
import {
    starmallList,
    starmallListState,
    starvalueList,
    starvalueListState,
    starvalueListDetail,
    starsharing,
    starRecord,
    starRecordState,
} from './starshop/saga';
import starshop from './starshop/model';
import merchantDrawApply from './merchantDrawApply/model';
import stockDividends from './stockDividends/model';
import redemption from './redemption/model';

export default [
    starmallList,//人气值商城分类 列表
    starmallListState,//人气值商城分类状态
    starvalueList,//人气值商品 列表
    starvalueListState,//人气值商品分类状态
    starvalueListDetail,//人气值商品详情
    starsharing,//人气值商品新建优惠劵和会员等级共用
    starRecord,//兑换记录数据源
    starRecordState,//兑换记录分类状态
    ...Object.values(starshop.sagas),
    ...Object.values(merchantDrawApply.sagas),// 商户人气值支取申请
    ...Object.values(stockDividends.sagas),//股票收益
    ...Object.values(redemption.sagas),//提货券
];
