import React, { Component } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableBox from './components/TableBox';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/invoicemaintain/model';
import { Upload, message, Button, DatePicker } from 'antd';
import moment from 'moment';
const { MonthPicker } = DatePicker;
const monthFormat = 'YYYY-MM';

class invoicemaintain extends Component {

    static propTypes = {
        history: PropTypes.object,
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getList: PropTypes.func,
        list: PropTypes.object,
    }

    static defaultProps = {
        list: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }
    }

    state = {
        areaCompanyName: '',
        months: '',
    };

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { areaCompanyName, pageNum, pageSize, months } = this.state;
        const monthList = months ? months.split('-') : [];
        const query = {
            areaCompanyName,
            yearMonth: monthList.length ? `${monthList[0]}${monthList[1]}` : undefined,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getList(query);
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ areaCompanyName: '', months: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { list } = this.props;
        const { areaCompanyName, months } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='机构名称'
                                placeholder='请输入机构名称'
                                bindThis={this}
                                bindName='areaCompanyName'
                                value={areaCompanyName}
                            />
                            <div className={styles.searchBox}>
                                <div className={styles.label}>查询月份：</div>
                                <MonthPicker
                                    value={months ? moment(months, monthFormat) : null}
                                    format={monthFormat}
                                    allowClear={false}
                                    onChange={(_, dateString) => this.setState({ months: dateString })}
                                    placeholder='请选择月份' />
                            </div>
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.upload}>
                        <Upload onChange={() => {
                            message.success('文件上传中...');
                        }}>
                            <Button type="primary">导入</Button>
                        </Upload>
                    </div>
                    <TableBox
                        paginationChange={this._paginationChange}
                        renderData={list}
                    />
                </div>
            </KeepAlive>
        );
    }
}

export default connect(state => ({ list: state.invoicemaintain.getList }), { keepSecondNavBreadcrumb, ...model.actions })(invoicemaintain);