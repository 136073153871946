import { Modal } from 'antd';

const { confirm } = Modal;

const showConfirm = (title, content, okcb, cancelcb, cancelText = '取消', okText = '确认') => {
    confirm({
        title: title,
        content: content,
        cancelText,
        okText,
        onOk() {
            if (okcb)
                okcb();
        },
        onCancel() {
            if (cancelcb)
                cancelcb();
        },
    });
};

export default showConfirm;