/**
 * 【秀场举报】 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

/**
 * 列表 Saga 
 */
export const showReortList = function* () {
    yield takeEvery(T.GET_SHOWLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/show/showReport/listShowReport', action.payload, 'POST');
            yield put({ type: T.SET_SHOWLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


/**
 * 秀场分类数据源 Saga
 */

export const showReortState = function* () {
    yield takeEvery(T.GET_SHOWREORTSTATE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/enum/ShowClassChannelEnum', action.payload, 'POST');
            yield put({ type: T.SET_SHOWREORTSTATE_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


/**
 *  秀场举报禁播，封禁类型和封禁理由数据源(saga)
 */

export const showReportStop = function* () {
    yield takeEvery(T.GET_SHOWRESTOP_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        let pages = {//封禁理由分页
            pageSize: 50,
            pageNum: 1,
        };
        try {
            let response = yield http('/enum/BlackTypeEnum', action.payload, 'POST');
            let bannedReason = yield http('/show/reporttype/listReportTypes', pages, 'POST');//封禁理由
            response.bannedReason = bannedReason.result.dataList;
            // debugger;
            yield put({ type: T.SET_SHOWRESTOP_DATA, payload: response });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 秀场分类详情 Saga
 */
export const showReortDetails = function* () {
    yield takeEvery(T.GET_SHOWREDETAIL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http(`/show/showReport/getShowReportInfo/${action.payload.id}`, action.payload, 'POST');
            yield put({ type: T.SET_SHOWREDETAIL_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
