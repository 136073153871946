/**
 * 跳转链接
 */
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Input, Modal, Radio, Select, Spin, Tabs, Typography } from 'antd';
import PropTypes from 'prop-types';
import styles from './JumpLink.module.scss';
import http from '@/assets/api/http';

const { TabPane } = Tabs;
const { Text } = Typography;

const ClassList = ({ classList, type, onClickActive, onCheckLink, funcList, onLoadMore, queryId }) => {

    return <div className={styles.classItemBox}
        onScroll={e => onLoadMore(e, queryId)}>
        {
            classList.map(j => {
                return <div className={`${styles.itemBox} ${j.active && styles.itemBoxActive}`} key={j.id}
                    onClick={() => onClickActive({
                        id: j.id, active: !j.active, funcList,
                        categoryGrade: j.categoryGrade ? j.categoryGrade : 4
                    })} >
                    {
                        type === 'GOODS' ?
                            <div>
                                <div><Text>{j.title}</Text></div>
                                <div><Text type='secondary'>归属：{j.shopName}</Text></div>
                            </div> :
                            <div><Text>{j.title}</Text></div>
                    }
                    <Button type='link' onClick={e => {
                        e.stopPropagation();
                        onCheckLink({
                            id: j.id, checked: !j.checked, funcList,
                            categoryGrade: j.categoryGrade ? j.categoryGrade : 4
                        });
                    }}>{j.checked ? '已选择' : '选择链接'}</Button>
                </div>;
            })
        }
    </div>;
};
ClassList.propTypes = {
    classList: PropTypes.array,
    type: PropTypes.string,
    onClickActive: PropTypes.func,
    onCheckLink: PropTypes.func,
    funcList: PropTypes.array,
    onLoadMore: PropTypes.func,
    queryId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

const JumpLink = ({ closeMode, onCheckLink, curType }) => {

    const searchTypes = [
        { code: 'GOODS', value: '商品' },
        { code: 'STRATEGY', value: '攻略' }
    ];

    const listTypes = [
        { code: 'CLASS', value: '分类' },
        { code: 'OTHER', value: '其他' }
    ];

    const [searchType, setSearchType] = useState('GOODS');
    const [searchValue, setSearchValue] = useState();
    const [listType, setListType] = useState('CLASS');
    const [classTab, setClassTab] = useState('GOODSCLASS');
    const [otherTab, setOtherTab] = useState('FUNCTIONLIST');
    const [showResult, setShowResult] = useState(false);
    const [searchResult, setSearchResult] = useState([]);

    const [GCFirList, setGCFirList] = useState([]);
    const [GCSecList, setGCSecList] = useState([]);
    const [GCThiList, setGCThiList] = useState([]);
    const [GCFouList, setGCFouList] = useState([]);

    const [SCFirList, setSCFirList] = useState([]);
    const [SCSecList, setSCSecList] = useState([]);

    const [OFirList, setOFirList] = useState([]);

    const [categoryId, setCategoryId] = useState();
    const [GPageNum, setGPageNum] = useState(1);
    const [GRPageNum, setGRPageNum] = useState(1);


    const [searchPrimaryKey, setSearchPrimaryKey] = useState();
    const [SCPageNum, setSCPageNum] = useState(1);
    const [SPageNum, setSPageNum] = useState(1);

    //商品分类列表
    const getGoodsClassList = ({ categoryGrade, parentId, func }) => {
        http('/supplier/category/linkage', { categorySubType: 'ONLINE', categoryGrade, categoryType: 'MALL', parentId }, 'POST').then(res => {
            func(res.result.map(i => ({ ...i, title: i.categoryName, categoryId: i.id, active: false, checked: false, linkType: 'CATEGORY' })));
        });
    };

    //商品列表
    const getGoodsList = ({ pageNum, categoryId, name }, func, isFirst) => {
        http('/supplier/goods/linkage', { pageNum, pageSize: 10, categoryId, name }, 'POST').then(res => {
            const list = res.result.dataList.map(i => ({ ...i, title: i.goodsName, shopName: i.shopName, active: false, checked: false, linkType: 'GOODS_INFO' }));
            if (!func) {
                setCategoryId(categoryId);
                setGPageNum(res.result.pageNum);
                setGCFouList([...GCFouList, ...list]);
            } else {
                setGRPageNum(res.result.pageNum);
                isFirst ? setSearchResult(list) : setSearchResult([...searchResult, ...list]);
            }
        });
    };

    //攻略分类列表
    const getStrategyClassList = ({ pageNum, reset }) => {
        http('/custom/dataItem/getDataItem', {
            type: 'STRATEGY_CLASS',
            sourceType: 'SUPPLIER',
            pageNum
        }, 'POST').then(res => {
            const list = res.result.dataList.map(i => ({
                ...i, title: i.className, strategyId: i.id,
                strategyName: i.className, categoryGrade: 1,
                active: false, checked: false, linkType: 'STRATEGY_CLASS'
            }));
            setSCPageNum(res.result.pageNum);
            reset ? setSCFirList(list) : setSCFirList([...SCFirList, ...list]);
        });
    };

    //攻略列表
    const getStrategyList = ({ pageNum, searchPrimaryKey }) => {
        http('/custom/dataItem/getDataItem', {
            type: 'STRATEGY',
            searchPrimaryKey,
            sourceType: 'SUPPLIER',
            pageNum, pageSize: 10
        }, 'POST').then(res => {
            const list = res.result.dataList.map(i => ({
                ...i, categoryGrade: 2, active: false, checked: false,
                linkType: 'STRATEGY_INFO', strategyId: i.id, strategyName: i.title,
            }));
            setSPageNum(res.result.pageNum);
            setSearchPrimaryKey(searchPrimaryKey);
            setSCSecList(list);
        });
    };

    //功能列表
    const getFeatureList = () => {
        http('/custom/itemType/getItemOther', { systemType: 'SUPPLY_CHAIN' }, 'POST').then(res => {
            setOFirList(res.result.map(i => ({ ...i, title: i.value, id: i.code, menuName: i.value, linkType: i.code, categoryGrade: 1, active: false, checked: false })));
        });
    };

    useEffect(() => {
        getGoodsClassList({ categoryGrade: 1, parentId: 0, func: setGCFirList });
    }, []);

    //弹框确认
    const onSubmit = () => {
        let checkedList = [...GCFirList, ...GCSecList, ...GCThiList, ...GCFouList, ...SCFirList, ...SCSecList, ...OFirList, ...searchResult].filter(i => i.checked);
        onCheckLink(checkedList.map(i => { return { ...i, curType }; }));
    };

    //头部搜索
    const onSearch = value => {
        if (value) {
            getGoodsList({ pageNum: GRPageNum, name: searchValue }, setSearchResult, true);
        } else {
            setSearchResult([]);
        }
    };

    //列表类型切换
    const listTyesChange = (v) => {
        setListType(v);
        if (v === 'CLASS') {
            getGoodsClassList({ categoryGrade: 1, parentId: 0, func: setGCFirList });
            getStrategyClassList({ pageNum: SCPageNum, reset: true });
        } else if (v === 'OTHER') {
            getFeatureList();
            setGCSecList([]); setGCThiList([]); setGCFouList([]); setSCSecList([]); setSCSecList([]);
        }
    };

    //tab页面变化
    const classTabChange = (v) => {
        setClassTab(v);
        if (v === 'GOODSCLASS') {
            getGoodsClassList({ categoryGrade: 1, parentId: 0, func: setGCFirList });
            setSCSecList([]);
        } else if (v === 'STRATEGYCLASS') {
            getStrategyClassList({ pageNum: SCPageNum, reset: true });
            setGCSecList([]); setGCThiList([]); setGCFouList([]); setSCSecList([]);
        }
    };

    const otherTabChange = (v) => {
        setOtherTab(v);
    };

    //点击搜索下一级列表表
    const onClickActive = ({ categoryGrade, id, active, funcList }) => {

        if (funcList.length === 1) return;

        if ((funcList.length === 2 && categoryGrade < 2) ||
            (funcList.length === 4 && categoryGrade < 4)) {
            funcList[categoryGrade - 1](pre => {
                return pre.map(i => {
                    if (i.id === id) return { ...i, active, checked: active ? false : i.checked };
                    return { ...i, active: false };
                });
            });
        }

        if (active) {
            if (classTab === 'GOODSCLASS') {
                categoryGrade < 3 && getGoodsClassList({ categoryGrade: categoryGrade + 1, parentId: id, func: funcList[categoryGrade] });
                categoryGrade === 3 && getGoodsList({ pageNum: 1, categoryId: id });
            } else if (classTab === 'STRATEGYCLASS') {
                categoryGrade < 2 && getStrategyList({ searchPrimaryKey: id, pageNum: SPageNum });
            }
        }
        for (let f = categoryGrade; f < funcList.length; f++)   funcList[f]([]);
    };

    //选择链接
    const onSelectLink = ({ categoryGrade, id, checked, funcList }) => {
        for (let f = 0; f < funcList.filter((_, i) => i != [categoryGrade - 1]).length; f++) {
            funcList.filter((_, i) => i != [categoryGrade - 1])[f](pre => {
                return pre.map(i => {
                    return { ...i, checked: false };
                });
            });
        }
        for (let f = categoryGrade; f < funcList.length; f++)   funcList[f]([]);
        funcList[categoryGrade - 1](pre => {
            return pre.map(i => {
                if (i.id === id) return { ...i, checked, active: checked ? false : i.active };
                return { ...i, checked: false, active: false };
            });
        });
        setSearchResult(pre => {
            return pre.map(j => {
                return { ...j, checked: false };
            });
        });
    };

    const GCDataList = [
        { code: 'GCFIR', dataList: GCFirList },
        { code: 'GCSEC', dataList: GCSecList },
        { code: 'GCTHI', dataList: GCThiList },
        { code: 'GCFOU', dataList: GCFouList, type: 'GOODS' }
    ];

    const SCDataList = [
        { code: 'GCFIR', dataList: SCFirList, categoryGrade: 1, type: 'STRATEGY_CLASS' },
        { code: 'GCSEC', dataList: SCSecList, categoryGrade: 2, type: 'STRATEGY_INFO' },
    ];

    const OFDataList = [
        { code: 'OFFIR', dataList: OFirList, categoryGrade: 1 },
    ];

    const GCFuncList = [setGCFirList, setGCSecList, setGCThiList, setGCFouList];
    const SCFuncList = [setSCFirList, setSCSecList];
    const OFFuncList = [setOFirList];

    const onLoadMoreGoods = (e, id) => {
        if (e.target.scrollHeight - e.target.scrollTop == e.target.clientHeight) {
            if (GPageNum * 10 > GCFouList.length) return;
            getGoodsList({ pageNum: GPageNum + 1, categoryId: id });
        }
    };

    const onLoadMoreStrategy = (e, id) => {
        if (e.target.scrollHeight - e.target.scrollTop == e.target.clientHeight) {
            if (!id && SCPageNum * 10 > SCFirList.length) return;
            if (id && SCPageNum * 10 > SCSecList.length) return;
            !id && getStrategyClassList({ pageNum: SCPageNum + 1 });
            id && getStrategyList({ pageNum: SPageNum + 1, searchPrimaryKey: id });
        }
    };

    const onSelectResultLink = (id) => {
        const list = searchResult.map(i => {
            if (id == i.id) return { ...i, checked: !i.checked };
            return i;
        });
        const otherList = [...GCFuncList, ...SCFuncList, ...OFFuncList];
        for (let i = 0; i < otherList.length; i++) {
            const element = otherList[i];
            element(pre => {
                return pre.map(j => {
                    return { ...j, checked: false };
                });
            });
        }
        setSearchResult(list);
    };

    const onResultLoadMore = (e) => {
        if (e.target.scrollHeight - e.target.scrollTop == e.target.clientHeight) {
            if (GRPageNum * 10 > searchResult.length) return;
            getGoodsList({ pageNum: GRPageNum + 1, name: searchValue }, setSearchResult);
        }
    };

    const resultObject = searchResult.filter(i => i.checked);

    return <Modal
        width={1200}
        centered
        title='链接'
        bodyStyle={{ paddingBottom: '10px' }}
        visible={true}
        confirmLoading={false}
        onCancel={() => closeMode()}
        onOk={onSubmit}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        okText='确定'
        cancelText='取消'
    >
        <div className={styles.container}>
            <div className={styles.searchBox}>
                <Input.Group compact>
                    <Select
                        size='large'
                        value={searchType}
                        style={{ width: '80px' }}
                        onChange={v => setSearchType(v)}>
                        {searchTypes.map(i => (<Select.Option key={i.code} value={i.code}>{i.value}</Select.Option>))}
                    </Select>
                    <Input.Search
                        value={searchValue}
                        style={{ width: '980px' }}
                        size='large'
                        placeholder='请输入商品名称、货号等'
                        onSearch={onSearch}
                        onClick={() => setShowResult(!showResult)}
                        onChange={v => setSearchValue(v.target.value)}
                        onBlur={() => !searchValue && setShowResult(false)}
                    />
                    <Button size='large' style={{ width: '80px' }} onClick={() => setSearchValue()}>清空</Button>
                </Input.Group>
                {
                    showResult && <div className={styles.resultBox}>
                        <div>
                            <div className={styles.resultHeader}>
                                <div className={styles.titleText}>标题</div>
                                <div className={styles.titleText}>信息</div>
                            </div>
                            <div className={styles.resultItems} onScroll={onResultLoadMore}>
                                {
                                    searchResult.map(i => {
                                        return <div key={i.id} className={styles.resultItem}>
                                            <div className={styles.titleInfo}>
                                                <div className={styles.text}>
                                                    <div className={styles.title}>{i.title}</div>
                                                    <div onClick={() => onSelectResultLink(i.id)} className={styles.button}>{`${i.shopName}${i.checked ? '（已选中）' : ''}`}</div>
                                                </div>
                                            </div>
                                            <div className={styles.otherInfo}>
                                                <div className={styles.className}>分类：{i.categoryFullName}</div>
                                                <div className={styles.brandName}>品牌：{i.brandName}</div>
                                            </div>
                                        </div>;
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                resultObject.length > 0 ?
                    <div className={styles.searchResult}>
                        <div className={styles.searchResultTitle}>选中链接</div>
                        <div className={styles.resultItem}>
                            <div className={styles.titleInfo}>
                                <div className={styles.text}>
                                    <div className={styles.title}>{(resultObject[0] || {}).title}</div>
                                    <div className={styles.button} onClick={() => setSearchResult(pre => {
                                        return pre.map(j => {
                                            return { ...j, checked: false };
                                        });
                                    })}>{`${(resultObject[0] || {}).shopName}${(resultObject[0] || {}).checked && '(已选中)'}`}</div>
                                </div>
                            </div>
                            <div className={styles.otherInfo}>
                                <div className={styles.className}>分类：{(resultObject[0] || {}).title}</div>
                                <div className={styles.brandName}>品牌：{(resultObject[0] || {}).title}</div>
                            </div>
                        </div>
                    </div> :
                    <Fragment>
                        <div className={styles.otherBox}>
                            <div className={styles.label}>列表类型：</div>
                            <Radio.Group value={listType} onChange={v => listTyesChange(v.target.value)}>
                                {
                                    listTypes.map(i => {
                                        return <Radio key={i.code} value={i.code}>{i.value}</Radio>;
                                    })
                                }
                            </Radio.Group>
                        </div>
                        <div className={styles.tabContainer}>
                            {
                                listType === 'CLASS' ? <div className={styles.tabInner}>
                                    <Tabs activeKey={classTab} onChange={v => classTabChange(v)}>
                                        <TabPane tab='商品分类' key='GOODSCLASS'>
                                            <div className={styles.classListBox}>
                                                {GCDataList.map(i => {
                                                    return <ClassList key={i.code} onClickActive={onClickActive} onCheckLink={onSelectLink} classList={i.dataList}
                                                        funcList={GCFuncList} type={i.type} queryId={categoryId} onLoadMore={i.code === 'GCFOU' ? onLoadMoreGoods : () => { }} />;
                                                })}
                                            </div>
                                        </TabPane>
                                        <TabPane tab='攻略分类' key='STRATEGYCLASS'>
                                            <div className={styles.classListBox}>
                                                {SCDataList.map(i => {
                                                    return <ClassList key={i.code} onClickActive={onClickActive} onCheckLink={onSelectLink}
                                                        classList={i.dataList} queryId={searchPrimaryKey} funcList={SCFuncList} onLoadMore={onLoadMoreStrategy} />;
                                                })}
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </div> : <div className={styles.tabInner}>
                                    <Tabs activeKey={otherTab} onChange={v => otherTabChange(v)}>
                                        <TabPane tab='功能列表' key='FUNCTIONLIST'>
                                            <div className={styles.classListBox}>
                                                {
                                                    OFDataList.map(i => {
                                                        return <ClassList key={i.code} onClickActive={onClickActive} onCheckLink={onSelectLink}
                                                            classList={OFirList} funcList={OFFuncList} />;
                                                    })
                                                }
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </div>
                            }
                        </div>
                    </Fragment>
            }
        </div>
    </Modal>;
};
JumpLink.propTypes = {
    closeMode: PropTypes.func.isRequired,
    onCheckLink: PropTypes.func,
    curType: PropTypes.string
};
export default JumpLink;
