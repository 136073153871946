/**
 * 【商户提现记录列表】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { XSelect, XOKButton, XCancelButton, XInput, XDatePicker, showConfirm } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import WidthDrawal from '../modal/withdrawal';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getMerchantSelectSource } from '@/reducers/storehousefinancialmanage/merchantreconciliation/actions';
import model from '@/reducers/storehousefinancialmanage/reconciliationdata/model';
import AdvertTable from './components/AdvertTable';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import http from '@/assets/api/http';
import { getSessionStorage, removeSessionStorage, setSessionStorage } from '@/assets/js/storage';

class Main extends React.Component {

    state = {
        merchant: {},
        paymentAccessType: {},
        status: {},
        currentOpenMode: '',
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getMerchantSelectSource();
        this.props.getOutAccountWithdrawStatusEnum();
        this.props.getPaymentAccessTypeEnum();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { startTime, entTime, id, pageNum, pageSize, merchant, paymentAccessType, status } = this.state;
        this.searchCache = {
            startTime,
            entTime,
            id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            merchantId: merchant.merchantId,
            status: status.code,
            subjectType: 'MERCHANT',
            accessType: paymentAccessType.code
        };
        this.props.getwithdrawListData(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ startTime: undefined, entTime: undefined, id: '', pageNum: autoPageNum, pageSize: autopageSize, merchant: {}, paymentAccessType: {}, status: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        }, () => {
            this._searchHandle('useCache');
        });
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'open':
                return <WidthDrawal title='商户提现' refsh={this._searchHandle} visible={true} closeMode={this._closeMode} data={this.state.openModeParam} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

    WidthDrawalHandle = () => {
        addOneBreadcrumbPath({
            title: '商户对账明细查询',
            path: '/home/storehousefinancialmanage/merchantwithdrawalrecords/list'
        });
        this.props.history.push({
            pathname: '/home/storehousefinancialmanage/merchantwithdrawalrecords/list'
        });
    }

    _tableAction = (id, type, record) => {
        let payType = 'yl';
        if (record.accountTypeCode == 'LL_PAY') {
            payType = 'll';
        } else if (record.accountTypeCode == 'THIRD_UNION_PAY') {
            payType = 'yl';
        } else if (record.accessTypeCode == 'TONLY_PAY') {
            payType = 'tl';
        } else {
            payType = 'orderlist';
        }
        switch (type) {
            case 'withdrawal':
                showConfirm('是否确认打款', '', () => {
                    http('/merchant/withdraw/yums/confirmWithdraw', { id }, 'POST')
                        .then((res) => {
                            message.success('操作成功');
                            this._searchHandle('useCache');
                        }).catch((reject = {}) => {
                            message.error(reject.message || '操作异常');
                        });
                });
                break;
            case 'withdrawallist':
                setSessionStorage('storehousefinancialmanage_merchantwithdrawalrecords_tabid', 'MERCHANT');
                addOneBreadcrumbPath({
                    title: '提现订单明细',
                    path: `/home/storehousefinancialmanage/merchantwithdrawalrecords/withdrawallist/${payType}/${record.subjectId}/${id}`
                });
                this.props.history.push({
                    pathname: `/home/storehousefinancialmanage/merchantwithdrawalrecords/withdrawallist/${payType}/${record.subjectId}/${id}`
                });
                break;
        }
    }

    render() {
        const { id, status, startTime, entTime, merchant = {}, paymentAccessType = {} } = this.state;
        const { merchantSelectSource, withdrawListData, OutAccountWithdrawStatusEnum, paymentAccessTypeEnum } = this.props;
        return (
            <KeepAlive id="1" render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='提现单号'
                                placeholder='请输入提现单号'
                                value={id}
                                bindThis={this}
                                bindName='id'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户'
                                showSearch
                                placeholder='请选择商户'
                                renderData={merchantSelectSource}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                value={merchant.merchantName}
                                bindThis={this}
                                bindName='merchant'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='提现通道'
                                showSearch
                                placeholder='请选择提现通道'
                                renderData={paymentAccessTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={paymentAccessType.value}
                                bindThis={this}
                                bindName='paymentAccessType'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                showSearch
                                label='状态'
                                placeholder='请选择状态'
                                renderData={OutAccountWithdrawStatusEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                                bindThis={this}
                                bindName='status'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='提现日期'
                                value={startTime}
                                bindThis={this}
                                bindName='startTime'
                                isFormat={true}
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                value={entTime}
                                bindThis={this}
                                bindName='entTime'
                                isFormat={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.shortcutBox}>
                        <XOKButton
                            style={{ width: 90 }}
                            label='手动打款'
                            onClick={() => this.WidthDrawalHandle()}
                        />
                    </div>
                    <AdvertTable tableAction={this._tableAction} renderData={withdrawListData} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getMerchantSelectSource: PropTypes.func,
    merchantSelectSource: PropTypes.array,
    getwithdrawListData: PropTypes.func,
    withdrawListData: PropTypes.object,
    getOutAccountWithdrawStatusEnum: PropTypes.func,
    OutAccountWithdrawStatusEnum: PropTypes.array,
    getPaymentAccessTypeEnum: PropTypes.func,
    paymentAccessTypeEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
    merchantSelectSource: state.merchantreconciliation.merchantSelectSource,
    withdrawListData: state.reconciliationdata.withdrawListData,
    OutAccountWithdrawStatusEnum: state.reconciliationdata.OutAccountWithdrawStatusEnum,
    paymentAccessTypeEnum: state.reconciliationdata.paymentAccessTypeEnum,
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath, keepSecondNavBreadcrumb,
    getMerchantSelectSource, ...model.actions
})(Main);