/**
 * 商户须知列表
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message, Tag, Empty } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import NoticeTable from './components/NoticeTable';
import NoticeTableModal from './modal/NoticeTableModal';
import { XInput, XOKButton, XCancelButton, XSelect, XTabNav, showConfirm } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import categoryModel from '@/reducers/xqstrategy/noticecategory/model';
import model from '@/reducers/xqstrategy/noticelists/model';
import { SearchBox, SearchItem } from '@/components/Layout';
import ButtonPerssion from '@/routes/ButtonPerssion';

const tagStyle = {//自定义标签样式
    position: 'absolute',
    top: '1%',
    color: '#2db7f5',
};

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titleName: '',//标题名称 
            status: {},//状态
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            curTabID: undefined,//当前显示的Tab页ID
            isFalg: true,// 当前显示的Tab页ID
            tabPageNum: 1,// Tab页码
            tabPageSize: 1000,// Tab页数
        };
    }

    componentDidMount() {
        const { tabPageNum, tabPageSize, } = this.state;
        this.props.getNoticeState();//状态
        this.props.getCategoryTableList({ pageNum: tabPageNum, pageSize: tabPageSize });//商户须知分类
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { dataList } = nextProps.categoryList;
        if (prevState.isFalg) {
            if (dataList.length > 0) {
                prevState.isFalg = false;
                //当前显示的Tab页ID和是否禁用
                let fristID;
                dataList.map((v, i) => {
                    if (i === 0) fristID = v.id;
                });
                return {
                    curTabID: fristID,//当前显示的Tab页ID
                };
            }
        }
        return null;
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, titleName, status, curTabID } = this.state;
        this.searchCache = {
            state: status.code,//状态
            titleName: titleName,//标题名称
            classId: curTabID,//tabs分类id
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getNoticeTableList(this.searchCache);//商户须知列表
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ titleName: '', status: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建标题
    _newHandle = () => {
        this.setState({
            currentOpenMode: 'CREAT',

        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getNoticeTableList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (item, type, value) => {
        // console.log(item, type, value);
        switch (type) {
            case 'edite'://编辑
                this.setState({
                    currentOpenMode: 'EDITE',
                    openModeParam: value,
                });
                break;
            case 'delete'://删除
                showConfirm('删除后，标题下的全部问题将一并删除。', '', () => {
                    http('/instructionTitle/delInstructionTitle', { id: item }, 'POST')
                        .then(() => {
                            message.success('删除成功。');
                            this.props.getNoticeTableList(this.searchCache);
                        }).catch((reject = {}) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'problem'://问题管理(分类id,标题id，标题名称)
                this.props.updateContent('problem', value, this.state.curTabID);
                break;
            case 'switch'://状态
                http('/instructionTitle/updateInstructionTitle', {
                    id: item.id,
                    state: value === false ? '0' : '1'
                }, 'POST')
                    .then(() => {
                        message.success('状态修改成功。');
                        this.props.getNoticeTableList(this.searchCache);
                    }).catch((reject = {}) => {
                        message.error(reject.message);
                    });
                break;
            case 'sort'://排序
                http('/instructionTitle/updateTitleSortNo', {
                    id: item.id,
                    sortNo: value
                }, 'POST')
                    .then(() => {
                        message.success('排序修改成功。');
                        this.props.getNoticeTableList(this.searchCache);
                    }).catch((reject = {}) => {
                        message.error(reject.message);
                    });
                break;
        }
    }

    renderModal = () => {
        switch (this.state.currentOpenMode) {
            case 'CREAT':
                return <NoticeTableModal title='新建标题' visible={true} curTabID={this.state.curTabID} closeMode={this._closeMode} />;
            case 'EDITE':
                return <NoticeTableModal title='编辑标题' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }
    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getNoticeTableList(this.searchCache);
        }
    }
    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({
            curTabID: tabID,
            titleName: '',
            status: {},
        }, () => {
            this._searchHandle();// 重新渲染更新列表
        });
    }

    // 获取Tab数据
    _tabsData = () => {
        const { dataList } = this.props.categoryList;
        let tabs = [];
        dataList.map((v) => {
            let datas = {
                id: v.id,
                label: v.className,
                isState: v.state,
            };
            tabs.push(datas);
        });
        return tabs || [];
    }

    render() {
        const { titleName, status } = this.state;
        const { noticeList, states } = this.props;
        const { dataList } = this.props.categoryList;

        return (
            dataList.length > 0 ? <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav
                        renderData={this._tabsData()}
                        onChange={this._tabChangeHandle}
                        typeNode={true}
                        isTagNodePro='0'
                        optionalNode={<Tag style={tagStyle}>禁</Tag>}
                        activedID={this.state.curTabID}
                    />
                    <div className={styles.operateBox}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='标题名称'
                                    placeholder='请输入标题名称'
                                    value={titleName}
                                    bindThis={this}
                                    bindName='titleName'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='状态'
                                    placeholder='请选择状态'
                                    renderData={states}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={status.value}
                                    bindThis={this}
                                    bindName='status'
                                    showSearch={true}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    style={{ marginLeft: '20px', width: '60px' }}
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    style={{ marginLeft: '20px', width: '60px' }}
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <ButtonPerssion code='xqstrategy/noticelists/shxzlbxzbt'>
                        <div className={styles.operateBox}>
                            <XOKButton
                                style={{ width: 'auto' }}
                                label='新建标题'
                                onClick={this._newHandle}
                            />
                        </div>
                    </ButtonPerssion>
                    <NoticeTable renderData={noticeList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this.renderModal()}
                </div>
            </KeepAlive> : <div className={styles.noDatas}><Empty description='请先新建商户须知分类。' image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
        );
    }
}


Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    updateContent: PropTypes.func, // 路由跳转

    categoryList: PropTypes.object,
    getCategoryTableList: PropTypes.func,//商户须知数据(action)
    states: PropTypes.array,
    getNoticeState: PropTypes.func,//状态数据(action)
    getNoticeTableList: PropTypes.func,//商户须知列表数据(action)
    noticeList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    categoryList: state.noticecategory.categoryTableList, // 商户须知数据
    states: state.noticecategory.noticeState, // 状态
    noticeList: state.noticelist.noticeTableList, // 商户须知列表数据
});

export default connect(mapStateToProps, { ...model.actions, ...categoryModel.actions })(Main);
