import React from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

export default function TableContent({ tableData, tableOperate, paginationChange }) {

    //表头
    const _columns = () => {
        return [
            {
                title: '任务单号',
                dataIndex: 'spokesmanTaskConfigId',
                key: 'spokesmanTaskConfigId',
                width: '12%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '任务名称',
                dataIndex: 'taskName',
                key: 'taskName',
                width: '12%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '任务归属',
                dataIndex: 'taskSource',
                key: 'taskSource',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '任务总量/单',
                dataIndex: 'publishNum',
                key: 'publishNum',
                width: '12%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '奖励总量',
                dataIndex: 'rewardNum',
                key: 'rewardNum',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '状态',
                dataIndex: 'auditStateValue',
                key: 'auditStateValue',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作人',
                dataIndex: 'operatorName',
                key: 'operatorName',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '更新时间',
                dataIndex: 'gmtModify',
                key: 'gmtModify',
                width: '12%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作',
                dataIndex: 'key',
                key: 'key',
                align: 'center',
                width: '12%',
                render: (key, record) => _tableOperate(record.id, record)
            },
        ];
    };

    //操作栏
    const _tableOperate = (key, item) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => tableOperate(item.spokesmanTaskConfigId, 'detail')}>详情</div>
            {
                (item.auditStateCode == 'CHECK_WORK') ?
                    <div className={styles.item} onClick={() => tableOperate(item.spokesmanTaskConfigId, 'audit')}>审核</div> :
                    null
            }
        </div>;
    };

    //渲染
    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.spokesmanTaskConfigId}
                columns={_columns()}
                dataSource={tableData.dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={tableData.resultTotal}
                pageSize={tableData.pageSize}
                pageNum={tableData.pageNum}
                onChange={paginationChange}
            />
        </div>
    );
}

//props校验
TableContent.propTypes = {
    tableData: PropTypes.object,//表格数据元
    paginationChange: PropTypes.func,//分页逻辑
    tableOperate: PropTypes.func,//表格操作
};