/**
 * 【广告投放页】
 */
import React from 'react';
import { message } from 'antd';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { XInput, XOKButton, XCancelButton, XSelect, showConfirm } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getAdvertPlacementList, getAdvertPlacementStatus } from '@/reducers/advertmanage/advertPlacement/actions';
import { getAdvertStrategDetail } from '@/reducers/advertmanage/advertStrateg/actions';
import AdvertTable from './components/AdvertTable';
import StartOrStop from './modal/StartOrStop';
import DeleteModal from './modal/DeleteModal';
import SubmitModal from './modal/SubmitModal';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {

    state = {
        companyStatus: {},
        schedule: '',
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getAdvertPlacementStatus({});
    }

    // 查询
    _searchHandle = (useCache) => {
        const { schedule, companyStatus, pageSize, pageNum } = this.state;
        this.searchCache = {
            adName: schedule,
            state: companyStatus && companyStatus.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAdvertPlacementList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ schedule: '', pageNum: 1, pageSize: 10, companyStatus: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 状态切换
    _companyStatusChange = (value) => {
        this.setState({
            companyStatus: value,
        });
    }

    // 新建用户
    _newHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建广告投放',
            path: '/home/advertmanage/advertPlacement/add/'
        });
        history.push({
            pathname: '/home/advertmanage/advertPlacement/add/'
        });
        // 清空投放策略详情
        this.props.getAdvertStrategDetail({});
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const { schedule, companyStatus } = this.state;
        this.setState({
            pageSize,
            pageNum,
        });
        this.searchCache = {
            adName: schedule,
            state: companyStatus && companyStatus.code,
            pageSize,
            pageNum
        };
        this.props.getAdvertPlacementList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (id, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                addOneBreadcrumbPath({
                    title: '新建广告投放详情',
                    path: `/home/advertmanage/advertPlacement/detail/${id}`
                });
                history.push({
                    pathname: `/home/advertmanage/advertPlacement/detail/${id}`
                });
                break;
            case 'edit':
                addOneBreadcrumbPath({
                    title: '广告投放编辑',
                    path: `/home/advertmanage/advertPlacement/edit/${id}`
                });
                history.push({
                    pathname: `/home/advertmanage/advertPlacement/edit/${id}`
                });
                break;
            case 'stop':
                // this.setState({ currentOpenMode: 'stop', openModeParam: id });
                showConfirm('是否确定停用？', '', () => {
                    http('/ad/send/updateState', { id, state: 0 }, 'POST')
                        .then(() => {
                            message.success('停用成功。');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'start':
                // this.setState({ currentOpenMode: 'start', openModeParam: id });
                showConfirm('是否确定启用？', '', () => {
                    http('/ad/send/updateState', { id, state: 1 }, 'POST')
                        .then(() => {
                            message.success('启用成功。');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'del':
                // this.setState({ currentOpenMode: 'del', openModeParam: id });
                showConfirm('是否确定删除？', '', () => {
                    http('/ad/send/delete', { id }, 'POST')
                        .then(() => {
                            message.success('删除成功。');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'auditSubmit':
                // this.setState({ currentOpenMode: 'auditSubmit', openModeParam: id });
                showConfirm('是否确定提交审核？', '', () => {
                    http('/ad/check/record/add', { adId: id, checkType: 'COMMIT' }, 'POST')
                        .then(() => {
                            message.success('提交审核成功。');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'audit':
                addOneBreadcrumbPath({
                    title: '新建广告投放审核',
                    path: `/home/advertmanage/advertPlacement/audit/${id}`
                });
                history.push({
                    pathname: `/home/advertmanage/advertPlacement/audit/${id}`
                });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        const { starProductItem } = this.state;
        switch (this.state.currentOpenMode) {
            case 'start':
                return <StartOrStop title='启用' visible={true}
                    data={{ action: 'start', url: '', starProductItem }}
                    closeMode={this._closeMode} />;
            case 'stop':
                return <StartOrStop title='停用' visible={true}
                    data={{ action: 'stop', url: '', starProductItem }}
                    closeMode={this._closeMode} />;
            case 'del':
                return <DeleteModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            case 'auditSubmit':
                return <SubmitModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

    render() {
        const { advertPlacementList, advertPlacementStatus = [] } = this.props;
        const { schedule, companyStatus } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='档期'
                                placeholder='请输入'
                                value={schedule}
                                bindThis={this}
                                bindName='schedule'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={advertPlacementStatus}
                                onChange={this._companyStatusChange}
                                dataIndex='value'
                                keyIndex='code'
                                value={companyStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.row2}>
                        <XOKButton
                            style={{ width: '100px' }}
                            label='新建'
                            onClick={this._newHandle}
                        />
                    </div>
                    <AdvertTable renderData={advertPlacementList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getAdvertPlacementList: PropTypes.func,
    advertPlacementList: PropTypes.object,
    getAdvertStrategDetail: PropTypes.func,
    getAdvertPlacementStatus: PropTypes.func,
    advertPlacementStatus: PropTypes.array
};

const mapStateToProps = (state) => ({
    advertPlacementList: state.advertPlacement.advertPlacementList,
    advertPlacementStatus: state.advertPlacement.advertPlacementStatus,
    advertStrategDetail: state.advertStrateg.advertStrategDetail,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getAdvertPlacementList, getAdvertStrategDetail, getAdvertPlacementStatus })(Main);