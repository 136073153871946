/**
 * 打款详情弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { regFenToYuan } from '@/util/money';
import { Modal, Input, message, Radio } from 'antd';
import { XInfoLabel } from '@/components/xqxc_ui';
import styles from './index.module.scss';


class UseOrBan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
            data: this.props.data,
            payDetail: {},
        };
    }
    componentDidMount() {
        const { data: { id } } = this.props;
        http('/marketing/activityInfo/payDetail', { id }, 'POST')
            .then((res) => {
             this.setState({ payDetail: res.result || {} });
            }).catch((reject) => {
                message.error(reject.message);
            });

    }
    // 点击确认
    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();

    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title, payDetail } = this.state;

        return (
            <Modal
                width={550}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.col}>
                        <div className={styles.col1}>
                            <XInfoLabel style={{}} labelWith={70} label="打款金额：" value={regFenToYuan(payDetail.refundFee)} />
                            <XInfoLabel style={{ marginTop: '20px' }} labelWith={70} label="收款方式：" value={payDetail.channelType} />
                            <XInfoLabel style={{ marginTop: '20px', marginBottom: 20 }} labelWith={70} label="支付流水号：" value={payDetail.refundId} />
                            <XInfoLabel style={{ marginTop: '20px', marginBottom: 20 }} labelWith={70} label="支付单号：" value={payDetail.outRefundId} />
                            <XInfoLabel style={{ marginTop: '20px', marginBottom: 20 }} labelWith={70} label="操作人：" value={payDetail.operatorName} />
                        </div>
                        <div className={styles.col2}>
                            <XInfoLabel style={{ marginLeft: '10px' }} labelWith={70} label="支付状态：" value={payDetail.refundStatus} />
                            <XInfoLabel style={{ marginLeft: '10px', marginTop: '20px' }} labelWith={70} label="会员账号：" value={payDetail.tel} />
                            <XInfoLabel style={{ marginLeft: '10px', marginTop: '20px' }} labelWith={70} label="支付时间：" value={payDetail.refundFinishTime} />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

UseOrBan.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

export default UseOrBan;