import { createModel } from '@/store/tools';

const model = {
    namespace: 'blacklist',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getRegisterIntercepts': T('getRegisterIntercepts'),
            'getDiscernTypes': T('getDiscernTypes'),
        },
        actions: {
            'getRegisterIntercepts': A('getRegisterIntercepts'),
            'getDiscernTypes': A('getDiscernTypes'),
        },
        sagas: {
            'getRegisterIntercepts': S('getRegisterIntercepts', '/user/account/listWoolCheckRecord'),
            'getDiscernTypes': S('getDiscernTypes', '/enum/WoolCheckResultEnum'),
        },
        reducers: {
            'registerIntercepts': R('getRegisterIntercepts', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'discernTypes': R('getDiscernTypes', []),
        }
    })
};
export default createModel(model);