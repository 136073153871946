/**
 *  店铺管理 - 酒店店铺
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';


class hotelList extends React.Component {
    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case 'MER_BANNED':  //封禁
                addOneBreadcrumbPath({
                    title: '店铺封禁',
                    path: '/home/storemanage/hotelShop/pages/banInfo',
                });
                history.push({
                    pathname: '/home/storemanage/hotelShop/banInfo/' + value,
                });
                break;
            case 'MER_DELETE': //详情
                addOneBreadcrumbPath({
                    title: '店铺详情',
                    path: '/home/storemanage/hotelShop/pages/detailInfo',
                });
                history.push({
                    pathname: '/home/storemanage/hotelShop/detailInfo/' + value,
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

hotelList.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(hotelList);