import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import AllProduct from '../allProduct';


class Main extends React.Component {

    state = {}

    componentDidMount() { }

    render() {
        const { updateContent, history } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
                <div className={`${styles.flexBoxContainer} ${styles.page}`}>
                    <AllProduct updateContent={updateContent} history={history} />
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func
};

export default Main;