import React from 'react';
import styles from './TableData.module.scss';
import { Switch, } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { FloatConversion } from '@/util/conversion';
/* eslint-disable react/display-name */
const floatConversion = new FloatConversion();
const columns = (actionClickHandle) => [
    {
        title: '名称',
        dataIndex: 'ruleName',
        key: 'ruleName',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '安全值',
        dataIndex: 'safetyValueDesc',
        key: 'safetyValueDesc',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '手续费(%)',
        dataIndex: 'chargePercent',
        key: 'chargePercent',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        width: '7%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle))
    },
    {
        title: '是否默认',
        dataIndex: 'defaultedDesc',
        key: 'defaultedDesc',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '兑换结果取数',
        dataIndex: 'roundModeDesc',
        key: 'roundModeDesc',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作人',
        dataIndex: 'modifyUserName',
        key: 'modifyUserName',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '10%',
        render: (id, record) => TableAction(record, actionClickHandle)
    },
];

export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    if (data) {
        return data.map(item => {
            item.chargePercent = floatConversion.divide(item.chargeRatio, 100);
            return item;
        });
    }
};
// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
    let checked = true;
    if (sw == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                key={item.id + '_' + checked}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={(value) => {
                    actionClickHandle(item, 'switch', value);
                }}
            />
        </div>
    );
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};

const TableAction = (record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(record, 'edit')}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(record, 'delete')}>删除</div>
        </div>
    );
};