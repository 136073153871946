export const GET_INVOICELIST = 'INVOICELIST/GET_INVOICELIST';
export const SET_INVOICELIST = 'INVOICELIST/SET_INVOICELIST';

// export const GET_DEALING_UNIT_SELECTSOURCE = 'MERCHANTRECONCILIATION/GET_DEALING_UNIT_SELECTSOURCE';
// export const SET_DEALING_UNIT_SELECTSOURCE = 'MERCHANTRECONCILIATION/SET_DEALING_UNIT_SELECTSOURCE';

export const GET_MERCHANT_SELECTSOURCE = 'MERCHANTRECONCILIATION/GET_MERCHANT_SELECTSOURCE';
export const SET_MERCHANT_SELECTSOURCE = 'MERCHANTRECONCILIATION/SET_MERCHANT_SELECTSOURCE';

export const GET_MERCHANT_SELECTSOURCE_V1 = 'MERCHANTRECONCILIATION/GET_MERCHANT_SELECTSOURCE_V1';
export const SET_MERCHANT_SELECTSOURCE_V1 = 'MERCHANTRECONCILIATION/SET_MERCHANT_SELECTSOURCE_V1';

export const GET_MERCHANTRECONCILIATION_TABLESOURCE = 'MERCHANTRECONCILIATION/GET_MERCHANTRECONCILIATION_TABLESOURCE';
export const SET_MERCHANTRECONCILIATION_TABLESOURCE = 'MERCHANTRECONCILIATION/SET_MERCHANTRECONCILIATION_TABLESOURCE';

export const GET_STATE_SELECTSOURCE = 'MERCHANTRECONCILIATION/GET_STATE_SELECTSOURCE';
export const SET_STATE_SELECTSOURCE = 'MERCHANTRECONCILIATION/SET_STATE_SELECTSOURCE';

// 详情相关
export const GET_DETAIL = 'MERCHANTRECONCILIATION/GET_DETAIL';
export const SET_DETAIL = 'MERCHANTRECONCILIATION/SET_DETAIL';

export const GET_DETAIL_TABLESOURCE = 'MERCHANTRECONCILIATION/GET_DETAIL_TABLESOURCE';
export const SET_DETAIL_TABLESOURCE = 'MERCHANTRECONCILIATION/SET_DETAIL_TABLESOURCE';
// 商户对账单详情
export const GET_MERCHANT_DETAIL = 'MERCHANTRECONCILIATION/GET_MERCHANT_DETAIL';
export const SET_MERCHANT_DETAIL = 'MERCHANTRECONCILIATION/SET_MERCHANT_DETAIL';
//对账单详情中订单交易明细查询
export const GET_MERCHANT_TRADE_DETAIL = 'MERCHANTRECONCILIATION/GET_MERCHANT_TRADE_DETAIL';
export const SET_MERCHANT_TRADE_DETAIL = 'MERCHANTRECONCILIATION/SET_MERCHANT_TRADE_DETAIL';