export const GET_INVOICELIST = 'PAYMANAGE/GET_INVOICELIST';
export const SET_INVOICELIST = 'PAYMANAGE/SET_INVOICELIST';

// 付款管理表格
export const GET_PAYMANAGETABLESOURCE = 'PAYMANAGE/GET_PAYMANAGETABLESOURCE';
export const SET_PAYMANAGETABLESOURCE = 'PAYMANAGE/SET_PAYMANAGETABLESOURCE';

export const GET_PAYMANAG_EDETAIL = 'PAYMANAGE/GET_PAYMANAG_EDETAIL';
export const SET_PAYMANAG_EDETAIL = 'PAYMANAGE/SET_PAYMANAG_EDETAIL';
//付款通道
export const GET_PAY_CHANNEL = 'PAYMANAGE/GET_PAY_CHANNEL';
export const SET_PAY_CHANNEL = 'PAYMANAGE/SET_PAY_CHANNEL';