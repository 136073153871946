/**
 *  秀场魅力值统计分析
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './main';

const ShowstarLight = ({ history }) => {
    return <Main history={history} />;
};

ShowstarLight.propTypes = {
    history: PropTypes.object
};

export default ShowstarLight;