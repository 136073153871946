/**
 * 直播、视频、社区分类创建或编辑弹框 
 */
import React from 'react';
import { Modal, Radio, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './ChannelActionModal.module.scss';
import { XInput, XUpload } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;
class ChannelActionModal extends React.Component {


    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,

        typeInput: this.props.data.tableItem.className,
        radioValue: this.props.data.tableItem.state,
        img: this.props.data.tableItem.img,
        createImgs: [],
    }
    constructor(props) {
        super(props);
        if ((props.data.tableItem || {}).img) {
            this.state.createImgs = [{
                uid: '0',
                name: 'default',
                status: 'done',
                url: props.data.tableItem.img,
            }];
        }
    }
    componentDidMount() {
        console.log(this.props);
    }

    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST').then(() => {
            message.success(winTxt);
            this.setState({
                visible: false,
                confirmLoading: false
            });
            this.props.closeMode(true);
        }).catch((error) => {
            this.setState({
                confirmLoading: false
            });
            message.error(error.message);
        });
    }
    // 点击确认
    _handleOk = () => {
        const { typeInput, radioValue, img } = this.state;
        const { url, action, tableItem } = this.props.data;
        switch (action) {
            case 'create':
                if (!typeInput || !img) {
                    message.warning('请补全信息!');
                } else {
                    if (radioValue === 0 || radioValue === 1) {
                        if (typeInput.length < 2 || typeInput.length > 16) {
                            message.warning('分类名称只能是2-16个字符！');
                            return;
                        } else {
                            this.setState({ confirmLoading: true }, () => {
                                this._sendReq(url, { className: typeInput, state: radioValue, img }, '创建成功。');
                            });
                        }
                    } else {
                        message.warning('请补全信息!');
                        return;
                    }
                }
                break;
            case 'edit':
                if (tableItem && typeInput && img) {
                    if (typeInput.length < 2 || typeInput.length > 16) {
                        message.warning('分类名称只能是2-16个字符！');
                    }
                    this.setState({ confirmLoading: true }, () => {
                        this._sendReq(url, { className: typeInput, id: tableItem.id, classTypeCode: tableItem.classTypeCode, state: radioValue, img }, '编辑成功。');
                    });
                } else {
                    message.warning('请补全信息!');
                }
                break;
        }
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
            confirmLoading:false
        });
        this.props.closeMode(false);
    }

    // 状态启用或禁用单选框操作回调
    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }

    _inputChangeHandle = (value) => {
        if (value.length > 16) {
            message.warning('分类名称不能超过16个字符！');
        } else {
            this.setState({ typeInput: value });
        }
    }

    render() {
        const { visible, confirmLoading, title, radioValue, createImgs } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '408px' }}
                        inputStyle={{ width: '340px' }}
                        label='分类名称'
                        placeholder='请输入分类名称'
                        onChange={this._inputChangeHandle}
                        value={this.state.typeInput}
                    />
                    <div className={styles.radio}>
                        <div className={styles.label}>状态</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={radioValue}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.upload}>
                        <div className={styles.label}>分类图标</div>
                        <div className={styles.icon}>
                            <XUpload
                                uploadButton="singleImg"
                                handleChange={(file) => {
                                    if (file.status === 'done') {
                                        this.setState({
                                            img: file.qiniuInfo.key,
                                        });
                                    } //保存保存
                                    if (file.status === 'removed') {
                                        this.setState({
                                            img: '',
                                            createImgs: []
                                        });
                                    } //移除逻辑
                                }}
                                antdUploadProps={{
                                    listType: 'picture-card',
                                    showUploadList: false,
                                    // 这个是初始化值
                                    defaultFileList: createImgs
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

ChannelActionModal.defaultProps = {
    data: {
        className: '',
        state: '',
        img: ''
    }
};


ChannelActionModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

export default ChannelActionModal;