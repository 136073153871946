/**
 * 在线播放弹框
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './OnlinePlayModal.module.scss';

class OnlinePlayModal extends React.Component {
    state = {
        visible: this.props.visible,
        confirmLoading: false,
    }

    componentDidMount() {
        const { data } = this.props;
        if (data.executionType == 'LIVE') this._playM3U8(data.result);
    }

    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _playM3U8 = (url) => {
        if (url == '') {
            message.error('视频播放路径为空');
            return;
        }
        setTimeout(() => {
            // 支持MP4或M3U8格式
            // eslint-disable-next-line no-undef
            new QPlayer({
                url,
                container: this.player,
                autoplay: true,
            });
        }, 100);
    }

    render() {
        const { visible, confirmLoading } = this.state;
        const { data } = this.props;

        return (
            <Modal
                width={900}
                centered
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
                footer={null}
            >
                <div className={styles.container}>
                    {data.executionType == 'VIDEO' && <video style={{ width: '800px', height: '600px' }} src={data.result} poster="" muted controls></video>}
                    {data.executionType == 'LIVE' && <div ref={(player) => { this.player = player; }} style={{ width: '800px', height: '600px' }} />}
                </div>
            </Modal>
        );
    }
}

OnlinePlayModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    data: PropTypes.object
};

export default OnlinePlayModal;