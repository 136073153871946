
/**
 * 关联消费者权益说明
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { Modal, message, Form, Checkbox, Tooltip } from 'antd';
import styles from './Equity.module.scss';
import model from '@/reducers/productmanage/consumerknows/model';

const CheckboxGroup = Checkbox.Group;
class Equity extends React.Component {

    static propTypes = {
        data: PropTypes.object,
        closeMode: PropTypes.func,
        getlistGoodsComsumerRights: PropTypes.func,
        listGoodsComsumerRights: PropTypes.array,
    }

    state = {
        confirmLoading: false,
        comsumerRights: []
    }

    componentDidMount() {
        this.props.getlistGoodsComsumerRights();
    }

    // 点击确认
    _handleOk = () => {
        const { data: { ids }, closeMode } = this.props;
        const { comsumerRights } = this.state;
        this.setState({ confirmLoading: true });
        const params = {
            goodsIds: ids,
            goodsConsumerRightsIds: comsumerRights
        };
        http('/goods/comsumerRights/goodsAddComsumerRights', params, 'POST').then(res => {
            message.success(res.message);
            this.setState({ confirmLoading: false });
            closeMode(true);
        }).catch(err => {
            message.error(err.message);
            this.setState({ confirmLoading: false });
            closeMode();
        });
    }

    _onChange = (value) => {
        this.setState({ comsumerRights: value });
    }

    render() {
        const { confirmLoading } = this.state;
        const { listGoodsComsumerRights } = this.props;

        return (
            <Modal
                width={480}
                centered
                title='关联消费者权益'
                visible={true}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={() => this.props.closeMode()}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <CheckboxGroup onChange={this._onChange}>
                        {listGoodsComsumerRights.map((item) => {
                            return (
                                <div key={item.id} style={{ marginBottom: '10px' }}>
                                    <Tooltip placement="topLeft" title={item.name}>
                                        <Checkbox value={item.id}>{item.name}</Checkbox>
                                    </Tooltip>
                                </div>
                            );
                        })}
                    </CheckboxGroup>
                </div>
            </Modal>
        );
    }
}
export default connect(state => ({
    listGoodsComsumerRights: state.consumerknows.getlistGoodsComsumerRights,
}), { ...model.actions })(Form.create({})(Equity));