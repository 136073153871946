/**
 *  审核管理 - 销售员支取申请审核
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class IncomeTab extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case 'FIRAUDIT':
                addOneBreadcrumbPath({
                    title: '销售员支取申请初审',
                    path: '/home/auditmanage/salesWithdrawal/firstAudit/'
                });
                history.push({
                    pathname: '/home/auditmanage/salesWithdrawal/firstAudit/' + value
                });
                break;
            case 'SECAUDIT':
                addOneBreadcrumbPath({
                    title: '销售员支取申请复审',
                    path: '/home/auditmanage/salesWithdrawal/secondAudit/'
                });
                history.push({
                    pathname: '/home/auditmanage/salesWithdrawal/secondAudit/' + value
                });
                break;
            case 'DETAIL':
                addOneBreadcrumbPath({
                    title: '销售员支取申请详情',
                    path: '/home/auditmanage/salesWithdrawal/detail'
                });
                history.push({
                    pathname: '/home/auditmanage/salesWithdrawal/detail/' + value
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

IncomeTab.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(IncomeTab);