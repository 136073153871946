import * as System from './actiontypes';

// 版本列表
export const getReleaseList = ({ newVersion, appType, state, pageNum, pageSize }) => ({
    type: System.GET_RELEASE_LIST,
    payload: { newVersion, appType, state, pageNum, pageSize },
});
// 版本详情
export const getReleaseDetail = (id) => ({
    type: System.GET_RELEASE_DETAIL,
    payload: { id },
});
// 版本终端
export const getReleaseTerminal = () => ({
    type: System.GET_RELEASE_TERMINAL,
    payload: {},
});
// 版本系统
export const getReleaseSystem = () => ({
    type: System.GET_RELEASE_SYSTEM,
    payload: {},
});
// 版本状态
export const getReleaseState = () => ({
    type: System.GET_RELEASE_STATE,
    payload: {},
});
// 版本更新
export const getReleaseUpdate = () => ({
    type: System.GET_RELEASE_UPDATE,
    payload: {},
});
