import * as Order from './actionTypes';

//订单交易明细列表 商城订单
export const getTranscationList = (param) => ({
    type: Order.GET_TRANSCATION_LIST,
    payload: param,
});
//订单交易明细列表 服务订单
export const getTranscationServiceList = (param) => ({
    type: Order.GET_TRANSCATIONSERVICE_LIST,
    payload: param,
});
//费用明细列表 服务订单
export const getCostServiceList = (param) => ({
    type: Order.GET_COSTSERVICE_LIST,
    payload: param,
});
//获取商户
export const getMerchantList = (param) => ({
    type: Order.GET_MERCHANT_LIST,
    payload: param,
});
