/**
 *  任务管理 - 任务列表
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class TaskList extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value, curTabID) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case 'CREATE':
                addOneBreadcrumbPath({
                    title: '新建任务',
                    path: '/home/taskmanage/taskList/create/'
                });
                history.push({
                    pathname: '/home/taskmanage/taskList/create/'
                });
                break;
            case 'DETAIL':
                addOneBreadcrumbPath({
                    title: '任务详情',
                    path: '/home/taskmanage/taskList/detail/',
                });
                history.push({
                    pathname: '/home/taskmanage/taskList/detail/' + value,
                    curTabID: curTabID
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

TaskList.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(TaskList);