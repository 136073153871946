import * as T from './actiontypes';

export const stickypaperClassSelect=(state = [], {type,payload})=>{
    switch(type){
        case T.SET_STICKYPAPERCLASS_SELECTSOURCE:
            return (payload||{dataList:[]}).dataList.filter(v=>Number(v.state)===1);
        default:
            return state;
    }
};

export const stickypaperTypeTable = (state = {}, {type,payload,requestProps={}}) => {
    switch(type){
        case T.SET_STICKYPAPERTYPE_TABELSOURCE:
            return {...requestProps,...payload};
        default:
            return state;
    }
};

export const stickypaperListTable = (state = {}, {type,payload}) => {
    switch(type){
        case T.SET_STICKYPAPERLIST_TABELSOURCE:
            return payload;
        default:
            return state;
    }
};