import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import Normal from '../normal';
import Citygoods from '../citygoods';
import { getSessionStorage, getLocalStorage, removeSessionStorage } from '@/assets/js/storage';

class Main extends React.Component {

    state = {
        tabs: [
            { id: 'ONLINE', label: '线上商品' },
            { id: 'CITY', label: '同城商品', code: 'productmanage/productlist/citygoodstab' },
        ],
        showTab: [],
        curTabID: 'ONLINE'
    }

    componentDidMount() {
        this._getShowTab(); // Tab菜单权限管理
        if (getSessionStorage('goodsmanagegoodslisttabid')) {
            this.setState({ curTabID: getSessionStorage('goodsmanagegoodslisttabid') }); // 如果路由过来的state参数带tabID值，则渲染该tabID值下的内容
            removeSessionStorage('goodsmanagegoodslisttabid');
        }
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        const { curTabID } = this.state;
        const { updateContent } = this.props;
        switch (curTabID) {
            case 'ONLINE':
                return <Normal curTabID={curTabID} updateContent={updateContent} />;
            case 'CITY':
                return <Citygoods curTabID={curTabID} updateContent={updateContent} />;
            default:
                return <Normal curTabID={curTabID} updateContent={updateContent} />;
        }
    }

    _getShowTab = () => {
        const { tabs } = this.state;
        let btnPremission = JSON.parse(getLocalStorage('btnPremission')) || [];
        setTimeout(() => { this._getShowTab(); }, 1000);
        let showTab = tabs.filter(i => btnPremission.includes(i.code) || !i.code);
        this.setState({ showTab });
    }

    render() {
        const { showTab } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                {!!showTab.length && <XTabNav renderData={showTab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />}
                <div className={`${styles.flexBoxContainer} ${styles.page}`}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 跳转其它路由页面
};

export default Main;