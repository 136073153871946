/**
 * 规则编辑
 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Radio, message, InputNumber, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import model from '@/reducers/systemmanage/showset/model';

const Edit = ({ id, form, closeMode, getShowBeanRuleInfo, showBeanRuleInfo }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isSill, setIsSill] = useState(1);

    useEffect(() => {
        getShowBeanRuleInfo({ id });
    }, []);

    useEffect(() => {
        setIsSill(showBeanRuleInfo.isDeductSill);
    }, [showBeanRuleInfo.isDeductSill]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 19 },
        },
    };

    const nmberSwitch = (text) => {
        return text * 100;
    };

    const reqNmb = (text) => {
        return text ? text / 100 : 0;
    };

    const _handleSubmit = e => {
        if (e) e.preventDefault();
        setConfirmLoading(true);
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                http('/xiudou/rule/updateXiudouRule', {
                    ...values, id,
                    availableRatio: nmberSwitch(values.availableRatio),
                    incomeTax: nmberSwitch(values.incomeTax),
                    serviceCharge: nmberSwitch(values.serviceCharge),
                    convertRatio: nmberSwitch(values.convertRatio)
                }, 'POST')
                    .then(() => {
                        message.success('编辑信用值兑换规则成功');
                        setConfirmLoading(false);
                        closeMode(true);
                    }).catch((error = {}) => {
                        setConfirmLoading(false);
                        message.error(error.message);
                    });
            }
        });
    };

    const _handleOk = () => {
        _handleSubmit();
    };

    const _handleCancel = () => {
        setConfirmLoading(false);
        closeMode('');
    };

    const { getFieldDecorator, setFieldsValue, resetFields } = form;

    return (
        <Modal
            width={650}
            centered
            title='编辑信用值兑换规则'
            visible={true}
            confirmLoading={confirmLoading}
            onOk={_handleOk}
            onCancel={_handleCancel}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <div style={{ height: '500px', overflowY: 'scroll' }}>
                <Form id='edit' {...formItemLayout} onSubmit={_handleSubmit}>
                    <Form.Item label='规则名称'>
                        {getFieldDecorator('ruleName', {
                            initialValue: showBeanRuleInfo.ruleName,
                            rules: [
                                { required: true, message: '请输入规则名称', },
                                { max: 16, message: '规则名称不超过16个字', },
                            ]
                        })(<Input style={{ width: '240px' }} placeholder='请输入规则名称' />)}
                    </Form.Item>

                    <Form.Item label='使用门槛'>
                        {getFieldDecorator('sill', {
                            initialValue: showBeanRuleInfo.sill,
                            rules: [
                                { required: true, message: '请输入使用门槛' },
                                { type: 'number', min: 0, message: '使用门槛最小值为0', },
                            ]
                        })(<InputNumber style={{ width: '240px' }} disabled={isSill ? false : true} placeholder='请输入使用门槛' />)}
                        <span style={{ paddingLeft: '10px' }}>以上信用值（含），可以为0</span>
                    </Form.Item>

                    <Form.Item label='额度计算'>
                        {getFieldDecorator('isDeductSill', {
                            initialValue: showBeanRuleInfo.isDeductSill,
                            rules: [
                                { type: 'number' },
                            ]
                        })(<Radio.Group onChange={e => {
                            if (e.target.value) {
                                resetFields('sill');
                            } else {
                                setFieldsValue({ sill: 0 });
                            }
                            setIsSill(e.target.value);
                        }}>
                            <Radio value={0}>不扣除门槛</Radio>
                            <Radio value={1}>扣除门槛</Radio>
                        </Radio.Group>)}
                    </Form.Item>

                    <Form.Item label='总可用比率'>
                        {getFieldDecorator('availableRatio', {
                            initialValue: reqNmb(showBeanRuleInfo.availableRatio),
                            rules: [
                                { required: true, message: '请输入总可用比率' },
                                { type: 'number', min: 0, message: '总可用比率最小值为0' },
                                { type: 'number', max: 100, message: '总可用比率最大值为100' },
                            ]
                        })(<InputNumber style={{ width: '240px' }} placeholder='请输入总可用比率' precision={1} />)}
                        <span style={{ paddingLeft: '10px' }}>%</span>
                    </Form.Item>

                    <Form.Item label='周期和可用次数' style={{ marginBottom: 0 }}>
                        <Form.Item style={{ display: 'inline-block', width: '40%' }}>
                            <span style={{ padding: '0 10px' }}>每</span>
                            {getFieldDecorator('period', {
                                initialValue: showBeanRuleInfo.period,
                                rules: [
                                    { required: true, message: '请输入周期' },
                                    { type: 'number', min: 0, message: '周期最小值为0' },
                                ]
                            })(<InputNumber style={{ width: '100px' }} placeholder='请输入天' precision={0} />)}
                            <span style={{ paddingLeft: '10px' }}>天</span>
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: '50%' }}>
                            {getFieldDecorator('time', {
                                initialValue: showBeanRuleInfo.time,
                                rules: [
                                    { required: true, message: '请输入次数' },
                                    { type: 'number', min: 0, message: '次数最小值为0' },
                                ]
                            })(<InputNumber style={{ width: '100px' }} placeholder='请输入次数' precision={0} />)}
                            <span style={{ paddingLeft: '10px' }}>次</span>
                        </Form.Item>
                    </Form.Item>

                    <Form.Item label='1信用值兑换'>
                        {getFieldDecorator('convertRatio', {
                            initialValue: reqNmb(showBeanRuleInfo.convertRatio),
                            rules: [
                                { required: true, message: '请输入1信用值兑换' },
                                { type: 'number', min: 0.01, message: '1信用值兑换最小值为0.01' },
                                { type: 'number', max: 99.99, message: '1信用值兑换最大值为99.99' },
                            ]
                        })(<InputNumber style={{ width: '240px' }} placeholder='请输入1信用值兑换' precision={2} />)}
                        <span style={{ paddingLeft: '10px' }}>元</span>
                    </Form.Item>

                    <Form.Item label='信用值服务费'>
                        {getFieldDecorator('serviceCharge', {
                            initialValue: reqNmb(showBeanRuleInfo.serviceCharge),
                            rules: [
                                { required: true, message: '请输入信用值服务费' },
                                { type: 'number', min: 0, message: '信用值服务费最小值为0' },
                                { type: 'number', max: 100, message: '信用值服务费最大值为100' },
                            ]
                        })(<InputNumber style={{ width: '240px' }} placeholder='请输入信用值服务费' precision={1} />)}
                        <span style={{ paddingLeft: '10px' }}>%</span>
                    </Form.Item>

                    <Form.Item label='所得税'>
                        {getFieldDecorator('incomeTax', {
                            initialValue: reqNmb(showBeanRuleInfo.incomeTax),
                            rules: [
                                { required: true, message: '请输入所得税比例' },
                                { type: 'number', min: 0, message: '所得税比例最小值为0' },
                                { type: 'number', max: 100, message: '所得税比例最大值为100' },
                            ]
                        })(<InputNumber style={{ width: '240px' }} placeholder='请输入所得税比例' precision={1} />)}
                        <span style={{ paddingLeft: '10px' }}>%</span>
                    </Form.Item>

                    <Form.Item label='兑换结果取数'>
                        {getFieldDecorator('roundMode', {
                            initialValue: showBeanRuleInfo.roundMode.code,
                            rules: []
                        })(<Radio.Group>
                            <Radio value='ROUND_DOWN'>向下取整</Radio>
                            <Radio value='ROUND_HALF_UP'>四舍五入</Radio>
                        </Radio.Group>)}
                    </Form.Item>

                    {/* <Form.Item label='状态'>
                        {getFieldDecorator('state', {
                            initialValue: 1,
                            rules: [
                                { type: 'number' }
                            ]
                        })(<Radio.Group>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </Radio.Group>)}
                    </Form.Item> */}

                    <Form.Item label='发行端口'>
                        {getFieldDecorator('useScene', {
                            initialValue: showBeanRuleInfo.useScene.code,
                            rules: []
                        })(<Radio.Group>
                            <Radio value='MERCHANT'>商户</Radio>
                            <Radio value='USER'>用户APP</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                </Form>
            </div>
        </Modal >
    );
};

Edit.propTypes = {
    id: PropTypes.number.isRequired,
    form: PropTypes.object.isRequired,//表单
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getShowBeanRuleInfo: PropTypes.func,
    showBeanRuleInfo: PropTypes.object,
};

const mapStateToProps = (state) => ({
    showBeanRuleInfo: state.showset.showBeanRuleInfo
});
const EditForm = Form.create({ name: 'edit' })(Edit);
export default connect(mapStateToProps, { ...model.actions })(EditForm);