/**
 * 图文表格
 */
import React from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { AddProduct, SelectedProduct } from '../../modal';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import http from '@/assets/api/http';
import { message } from 'antd';
import { Mode } from '../../index';
import model from '@/reducers/graphicsmanage/graphicslist/model';


class VideoList extends React.Component {

    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: '', // 当前需要打开的弹窗的入参
        selectDataSource: [],
        feedTitle: '', // 视频主题
        feedClass: {}, // 视频分类
        typeList: [],//禁播理由
        selectedRowKeys: [],
        startDate: undefined, // 新建开始日期
        endDate: undefined, // 新建开始日期
        status: {},
    }

    componentDidMount() {
        this.props.getNoticeState();//状态
        this.props.getCategoryList({ state: 1 });
    }

    // 创建视频被点击
    _graphicsCreate = () => {
        this.props.updateContent(Mode.CREATE, 0);
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { feedTitle, feedClass, status, startDate, endDate, pageNum, pageSize } = this.state;
        this.searchCache = {
            startDate,
            endDate,
            feedTitle,
            status: status.code,
            feedClassId: feedClass.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getTableList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ feedTitle: '', feedClass: {}, status: {}, startDate: undefined, endDate: undefined }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    onSelectChange = (key) => {
        this.setState({ selectedRowKeys: key });
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        const { updateContent } = this.props;
        switch (type) {
            case Mode.SORT:
                http('/show/platFeedInfo/platFeedUpdateSore', { id, sort: value }, 'POST')
                    .then(() => message.success('排序修改成功。'), this._searchHandle())
                    .catch((reject = {}) => message.error(reject.message));
                break;
            case Mode.PRODUCT:
                this.setState({ currentOpenMode: Mode.PRODUCT });
                break;
            case Mode.SELECTED:
                this.setState({ currentOpenMode: Mode.SELECTED, openModeParam: value.goodsList });
                break;
            case Mode.DETAIL:
                updateContent(Mode.DETAIL, id);
                break;
            case Mode.EDIT:
                updateContent(Mode.EDIT, id);
                break;
            case Mode.STOP:
                http('/show/platFeedInfo/updatePlatFeedInfoState', { id, state: value }, 'POST')
                    .then(() => message.success('操作成功'), this._searchHandle())
                    .catch((reject = {}) => message.error(reject.message));
                break;
            case Mode.DELETE:
                showConfirm('确定要删除该图文吗？', '', () => {
                    http('/show/platFeedInfo/deletePlatFeedInfo', { idList: [id] }, 'POST')
                        .then(() => message.success('操作成功'), setTimeout(() => this._searchHandle(), 300))
                        .catch((reject = {}) => message.error(reject.message));
                });
                break;
            case Mode.COPY:
                this._copyItem(value);
                break;
        }
    }

    // 一键复制
    _copyItem = (item)=>{
        http('/show/platFeedInfo/copyPlatFeedInfo', { id: item.id }, 'POST')
        .then(res=>{
            this._searchHandle();
        })
        .catch((reject = {}) => message.error(reject.message));
    }

    _batchDelete = () => {
        if (this.state.selectedRowKeys.length === 0) {
            return message.warning('请选择一条记录');
        }

        showConfirm('确定要删除该图文吗？', '', () => {
            http('/show/platFeedInfo/deletePlatFeedInfo', { idList: this.state.selectedRowKeys }, 'POST')
                .then(() => message.success('操作成功'), this._searchHandle())
                .catch((reject = {}) => message.error(reject.message));
        });
    }

    // 渲染弹框
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.PRODUCT:
                return <AddProduct visible tableAction={this._tableAction} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case Mode.SELECTED:
                return <SelectedProduct visible data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', openModeParam: '' }, () => {
            if (update) this._searchHandle('useCache');
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { feedTitle, feedClass, status, startDate, endDate } = this.state;
        const { categoryList, states, tableList } = this.props;

        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='标题名称'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='feedTitle'
                                value={feedTitle}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='所属分类'
                                placeholder='请选择'
                                renderData={categoryList}
                                dataIndex='className'
                                keyIndex='id'
                                bindThis={this}
                                bindName='feedClass'
                                value={feedClass.className}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={states}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='status'
                                value={status.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='修改时间'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                showTime={false}
                                defaultTime='00:00:00'
                                isFormat
                            />
                            <XDatePicker
                                style={{ marginRight: '15px' }}
                                onChange={this._orderEndTimeChangeHandle}
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                showTime={false}
                                defaultTime='23:59:59'
                                isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }} label='创建图文' onClick={this._graphicsCreate} />
                        <XOKButton style={{ width: '120px', marginLeft: '10px' }} label='批量删除' onClick={this._batchDelete} />
                    </div>
                    <TableContent onSelectChange={this.onSelectChange} selectedRowKeys={this.state.selectedRowKeys} paginationChange={this._paginationChange} renderData={tableList} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

VideoList.propTypes = {
    updateContent: PropTypes.func,
    states: PropTypes.array,
    getNoticeState: PropTypes.func,

    categoryList: PropTypes.array,
    getCategoryList: PropTypes.func,

    getTableList: PropTypes.func,
    tableList: PropTypes.object, // 表格数据源
};

const mapStateToProps = (state) => ({
    states: state.graphicslist.noticeState, // 状态
    categoryList: state.graphicslist.categoryList,
    tableList: state.graphicslist.tableList, // 表格数据源
});

export default connect(mapStateToProps, { ...model.actions })(VideoList);