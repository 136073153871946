/**
 * 贴纸列表
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Switch } from 'antd';
import { XSelect, XOKButton, XCancelButton, XInput, XPagination, showConfirm } from '@/components/xqxc_ui';
import ListModal from '../../modal/ListModal';
import { getStickypaperListTableSource, getStickypaperClassSelectSource } from '@/reducers/showmanage/stickypaper/actions';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { ImgCell } from '@/components/TableCell';
import { SearchBox, SearchItem } from '@/components/Layout';

const Mode = {
    CREAT: 'CREAT',
    EDITE: 'EDITE',
};

const columns = (TableAction, actionClickHandle) => [
    {
        title: '贴纸名称',
        dataIndex: 'stickerName',
        key: 'stickerName',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '贴纸分类',
        dataIndex: 'className',
        key: 'className ',
        width: '10%',
        align: 'center'
    },
    {
        title: '贴纸图片',
        dataIndex: 'stickerImg',
        key: 'stickerImg',
        align: 'center',
        width: '10%',
        render: ImgCell
    },
    {
        title: '使用次数',
        dataIndex: 'useNum',
        key: 'useNum',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '推荐',
        dataIndex: 'isReco',
        key: 'isReco',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell('isReco', item, actionClickHandle))
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell('state', item, actionClickHandle))
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: '10%',
        render: (id, record) => TableAction(record.id, actionClickHandle, record)
    }
];

const TableAction = (id, actionClickHandle, record) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'edit', record)}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'delete')}>删除</div>
        </div>
    );
};

// 表格项内容为文本
const TableCell = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text}
        </div>
    );
};

// 表格项内容为开关
const SwitchCell = (field, item, actionClickHandle) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                key={item.id + item[field]}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                defaultChecked={item[field] == 1}
                onChange={(value) => {
                    actionClickHandle(item.id, field, value);
                }}
            />
        </div>
    );
};

class StickyList extends React.Component {

    state = {
        stickerClass: {},
        stickerName: undefined,
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
    }

    componentDidMount() {
        this.props.getStickypaperClassSelectSource();
    }

    getCache = () => ({
        stickerClassId: this.state.stickerClass.id,
        stickerName: this.state.stickerName
    });

    _searchHandle = (useCache) => {
        const { pageSize, pageNum, stickerClass, stickerName } = this.state;
        this.searchCache = {
            ...this.getCache(),
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getStickypaperListTableSource(this.searchCache); // 发起请求
        KeepAlive.saveCache({ stickerClass, stickerName, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            stickerClass: {},
            stickerName: undefined
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 创建直播间分类
    _createSticky = () => {
        this.setState({ currentOpenMode: Mode.CREAT });
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'edit':
                this.setState({ currentOpenMode: Mode.EDITE, openModeParam: value });
                break;
            case 'delete':
                showConfirm('您确定删除该记录？', '', () => {
                    http('/show/sticker/deleteStickerInfo', { id }, 'POST')
                        .then(res => this._searchHandle('useCache'));
                });
                break;
            case 'isReco':
                http('/show/sticker/updateStickerReco', { id, isReco: value ? 1 : 0 }, 'POST')
                    .then(res => this._searchHandle('useCache'));
                break;
            case 'state':
                http('/show/sticker/updateStickerState', { id, state: value ? 1 : 0 }, 'POST')
                    .then(res => this._searchHandle('useCache'));
                break;
        }
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        this._tableAction(id, type, value);
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.CREAT:
                return <ListModal title='创建贴纸' visible={true} data={{ state: 1, isReco: 1 }} selectDataSource={this.props.stickypaperClassSelect} closeMode={this._closeMode} />;
            case Mode.EDITE:
                return <ListModal title='编辑贴纸' visible={true} data={this.state.openModeParam} selectDataSource={this.props.stickypaperClassSelect} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    _closeMode = (isReload) => {
        this.setState({ currentOpenMode: '' }, () => {
            isReload && this._searchHandle();
        });
    }

    render() {
        const { dataList, pageNum, pageSize, resultTotal } = this.props.stickypaperListTable;
        const { stickerClass, stickerName } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XSelect
                                label='贴纸分类'
                                placeholder='请选择贴纸分类'
                                renderData={this.props.stickypaperClassSelect || []}
                                dataIndex='className'
                                keyIndex='id'
                                bindName='stickerClass'
                                bindThis={this}
                                value={stickerClass.className}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='贴纸名称'
                                placeholder='请输入贴纸名称'
                                value={stickerName}
                                bindName='stickerName'
                                bindThis={this}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }} label='创建贴纸' onClick={this._createSticky} />
                    </div>
                    <div className={styles.tableContainer}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowSelection={this._rowSelection}
                            columns={columns(TableAction, this._actionHandle)}
                            dataSource={dataList}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

StickyList.propTypes = {
    getStickypaperListTableSource: PropTypes.func,
    stickypaperListTable: PropTypes.object,

    getStickypaperClassSelectSource: PropTypes.func,
    stickypaperClassSelect: PropTypes.array,
};

const mapStateToProps = (state) => ({
    stickypaperListTable: state.stickypaper.stickypaperListTable,
    stickypaperClassSelect: state.stickypaper.stickypaperClassSelect,
});

export default connect(mapStateToProps, {
    getStickypaperListTableSource,
    getStickypaperClassSelectSource
})(StickyList);
