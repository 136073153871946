/**
 * 时间范围
 */
import React from 'react';
import { Modal, Form, message, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './DeliveryModal.module.scss';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};

class DeliveryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
            startTime: null
        };
    }

    // 提交 
    _handleOk = () => {
        this.props.form.validateFields((err, { from, to }) => {
            if (!err) {
                this.setState({ confirmLoading: true }, () => {
                    http('/shengxin/order/sync', {
                        from: from.format('YYYY-MM-DD 00:00:00'),
                        to: to.format('YYYY-MM-DD 23:59:59')
                    }).then(() => {
                        message.success('同步订单成功');
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                        this.props.closeMode(true);
                    }).catch((reject) => {
                        message.error(reject.message);
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                    });
                });
            }
        });
    }

    _handleCancel = () => {
        this.setState({ visible: false });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <Form {...formItemLayout} className="create-form">
                    <Form.Item label='开始时间'>
                        {getFieldDecorator('from', {
                            rules: [{ required: true, message: '选择开始时间' }]
                        })(<DatePicker showTime={false} placeholder='开始'
                            onChange={(date) => {
                                this.setState({ startTime: date });
                            }} />
                        )}
                    </Form.Item>
                    <Form.Item label='结束时间'>
                        {getFieldDecorator('to', {
                            rules: [{ required: true, message: '选择结束时间' }]
                        })(<DatePicker showTime={false} placeholder='结束'
                            disabledDate={(current) => {
                                if (this.state.startTime && current) {
                                    if (current > this.state.startTime.clone().add(7, 'days').endOf('day')) {
                                        return true;
                                    }
                                    if (current < this.state.startTime.clone().add(-7, 'days').endOf('day')) {
                                        return true;
                                    }
                                }
                            }} />
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

DeliveryModal.propTypes = {
    data: PropTypes.number, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
    recordInfo: PropTypes.object,
};

const ForgotProducDetails = Form.create({ name: 'create-form' })(DeliveryModal);// 校验
export default ForgotProducDetails;
