/**
 * 商品管理 Saga集合
 */
import { getProductBrand, getProductBrandDetail, getThirdPartyProductBrand } from './brand/saga';//品牌
import { getAccountLevel, getProductThirdPartyClassify } from './classify/saga';//分类
import {
    getProductListData,
    getProductStatus,
    getProductBusinessType,
    getProductUsername,
    getProductLabelSele,
    getProductDetail,
    getProductClassifySele,
    getProductRecommend
} from './list/saga';//列表
import goodsExplainList from './explainlist/model';//说明列表
import { getProductLabel } from './labellist/saga';//标签列表
import goodsList from './list/model';
import goodsClassify from './classify/model';
import wholesaleGoods from './wholesalegoods/model';
import operateRecord from './operaterecord/model';
import consumerknows from './consumerknows/model';

import { getStandardProduct, getStandardProductDetail } from './standardproduct/saga';

export default [
    getProductBrand,
    getProductBrandDetail,
    getThirdPartyProductBrand,
    getAccountLevel,
    getProductListData,
    getProductStatus,
    getProductBusinessType,
    getProductUsername,
    getProductLabelSele,
    getProductDetail,
    getProductClassifySele,
    getProductRecommend,

    getProductThirdPartyClassify,
    getStandardProduct,
    getStandardProductDetail,

    getProductLabel,
    ...Object.values(goodsExplainList.sagas),
    ...Object.values(goodsList.sagas),
    ...Object.values(goodsClassify.sagas),
    ...Object.values(wholesaleGoods.sagas),
    ...Object.values(operateRecord.sagas),
    ...Object.values(consumerknows.sagas),
];
