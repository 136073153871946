/**
 *  审核管理 - 鉴黄审查
 * */ 
import React from 'react';
import PropTypes from 'prop-types'; 
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';



class Pornographyaudit extends React.Component {
    
    state = { 
        mode: '' 
    }

    componentDidMount(){
        this.props.keepSecondNavBreadcrumb();
    }
    
     // 更新模块
     _updateContent = () => {
    }

    render(){
        return(
            <Main  updateContent={this._updateContent}/> 
        );
    }
}

Pornographyaudit.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Pornographyaudit);