import { createModel } from '@/store/tools';

/**
 *  知识付费&魅力值兑换规则&人气值兑换&代言任务违规机制
 * */

const model = {
    namespace: 'payment',
    build: (T, A, S, R) => ({
        actiontypes: {
            //知识付费
            'getPayState': T('getPayState'),
            'getPayList': T('getPayList'),
            'getPayType': T('getPayType'),
            'getLevelList': T('getLevelList'),
            'getWhiteListLeft': T('getWhiteListLeft'),
            'getWhiteListRight': T('getWhiteListRight'),
            'getCleanList': T('getCleanList'),
            //&魅力值兑换规则
            'getConvertibleList': T('getConvertibleList'),
            //人气值兑换规则
            'getShowBeanRules': T('getShowBeanRules'),
            'getShowBeanRuleInfo': T('getShowBeanRuleInfo'),
            'getShowBeanCycles': T('getShowBeanCycles'),
            'getIssuend': T('getIssuend'),
            //人气规则
            'getRobotsList': T('getRobotsList'),
            'getMessageLists': T('getMessageLists'),
            'getMessageType': T('getMessageType'),
            'getMessageScene': T('getMessageScene'),
            //代言任务违规机制
            'getViolationType': T('getViolationType'),
            'getViolationTime': T('getViolationTime'),
            'getViolationList': T('getViolationList'),
            'getViolationData': T('getViolationData'),
            //企业主播设置
            'getEnterpriseAnchorSet': T('getEnterpriseAnchorSet'),
        },
        actions: {
            'getPayState': A('getPayState'), // 状态-启禁用
            'getPayType': A('getPayType'), // 权限类型
            'getPayList': A('getPayList'), // 知识付费列表
            'getLevelList': A('getLevelList'), // 会员等级
            'getWhiteListLeft': A('getWhiteListLeft'), // 新建白名单左边列表数据
            'getWhiteListRight': A('getWhiteListRight'), // 新建白名单右边列表数据
            'getCleanList': A('getCleanList'), // 清除操作人创建的所有临时数据

            'getConvertibleList': A('getConvertibleList'), // 魅力值兑换规则列表
            'getShowBeanRules': A('getShowBeanRules'), // 人气值兑换规则列表
            'getShowBeanRuleInfo': A('getShowBeanRuleInfo'), // 人气值兑换规则详情
            'getShowBeanCycles': A('getShowBeanCycles'), // 周期列表
            'getIssuend': A('getIssuend'), // 发行端下拉

            'getRobotsList': A('getRobotsList'), // 人气规则-机器人列表
            'getMessageLists': A('getMessageLists'), // 人气规则-消息生成列表
            'getMessageType': A('getMessageType'), // 人气规则-消息生成列表类别
            'getMessageScene': A('getMessageScene'), // 人气规则-消息生成列表应用场景

            'getViolationType': A('getViolationType'), // 违约执行类型
            'getViolationTime': A('getViolationTime'), // 时间单位类型
            'getViolationList': A('getViolationList'), // 周期记录表
            'getViolationData': A('getViolationData'), // 违约数据初始化数据
            //企业主播设置
            'getEnterpriseAnchorSet': A('getEnterpriseAnchorSet'),
        },
        sagas: {
            'getPayState': S('getPayState', '/enum/StateEnum'),
            'getPayType': S('getPayType', '/enum/UserChargeShowTypeEnum'),
            'getPayList': S('getPayList', '/user/userChargeShow/getAllUserChargeShowList'),
            'getLevelList': S('getLevelList', '/user/account/listAccountLevel'),
            'getWhiteListLeft': S('getWhiteListLeft', '/user/userChargeShow/getAccountListForUserChargeShow'),
            'getWhiteListRight': S('getWhiteListRight', '/user/userChargeShow/getTempUserChargeShowList'),
            'getCleanList': S('getCleanList', '/user/userChargeShow/deleteTempAll'),

            'getConvertibleList': S('getConvertibleList', '/settle/starlightExchange/listRule'),
            'getShowBeanRules': S('getShowBeanRules', '/xiudou/rule/listXiudouRule'),
            'getShowBeanRuleInfo': S('getShowBeanRuleInfo', '/xiudou/rule/getXiudouRuleInfo', 'POST', 'id'),
            'getShowBeanCycles': S('getShowBeanCycles', '/xiudou/ruleperiodrecord/listXiudouRulePeriodRecord'),
            'getIssuend': S('getIssuend', '/enum/XiudouRuleUseSceneEnum'),

            'getRobotsList': S('getRobotsList', '/show/virtualChatMessage/listByAccountType'),
            'getMessageLists': S('getMessageLists', '/show/virtualChatMessage/virtualChatMessageList'),
            'getMessageType': S('getMessageType', '/enum/VirtualChatMessageTypeEnum'),
            'getMessageScene': S('getMessageScene', '/enum/VirtualChatMessageSceneEnum'),

            'getViolationType': S('getViolationType', '/defaultSetting/getActionType'),
            'getViolationTime': S('getViolationTime', '/defaultSetting/getTimeUnit'),
            'getViolationList': S('getViolationList', '/defaultSetting/list'),
            'getViolationData': S('getViolationData', '/defaultSetting/getNewest'),
            //企业主播设置
            'getEnterpriseAnchorSet': S('getEnterpriseAnchorSet', '/admin/param/listAdminParam', 'POST', 'reqCode'),
        },
        reducers: {
            'paymentList': R('getPayList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'payState': R('getPayState', []),
            'payType': R('getPayType', []),
            'levelList': R('getLevelList', []),
            'whiteListLeft': R('getWhiteListLeft', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'whiteListRight': R('getWhiteListRight', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'CleanList': R('getCleanList', {}),

            'convertibleList': R('getConvertibleList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'showBeanRules': R('getShowBeanRules', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'showBeanRuleInfo': R('getShowBeanRuleInfo', { roundMode: {}, useScene: {} }),
            'showBeanCycles': R('getShowBeanCycles', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'issuend': R('getIssuend', []),

            'robotsList': R('getRobotsList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'messageLists': R('getMessageLists', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'messageType': R('getMessageType', []),
            'messageScene': R('getMessageScene', []),

            'violationType': R('getViolationType', []),
            'violationTime': R('getViolationTime', []),
            'violationList': R('getViolationList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'violationData': R('getViolationData', {}),
            //企业主播设置
            'enterpriseAnchorSet': R('getEnterpriseAnchorSet', []),
        }
    })
};
export default createModel(model);