/**
 * 意见反馈管理
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as FEEDBACKTYPE from './actionTypes';

export const getFeedbackTypeList = function* () {
    yield takeEvery(FEEDBACKTYPE.GET_FEEDBACKTYPE_LIST, function* requestData(action) {
        try {
            let result = yield http('/ideaBack/listIdeaBackType', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: FEEDBACKTYPE.SET_FEEDBACKTYPE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
export const getFeedbackTypeDetail = function* () {
    yield takeEvery(FEEDBACKTYPE.GET_FEEDBACKTYPE_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/ideaBack/IdeaBackTypeInfo', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: FEEDBACKTYPE.SET_FEEDBACKTYPE_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

