import React from 'react';
import styles from './TableData.module.scss';

const columns = (actionClickHandle) => [
	{
		title: '会员等级',
		dataIndex: 'levelValue',
		key: 'levelValue',
		width: '10%',
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '身份称谓',
		dataIndex: 'levelName',
		key: 'levelName',
		width: '12%',
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '会员等级图标',
		dataIndex: 'img',
		key: 'img',
		width: '15%',
		align: 'center',
		render: (text) => (ImgCell(text))
	},
	{
		title: '所需成长值',
		dataIndex: 'growthValue',
		key: 'growthValue',
		width: '15%',
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '升级礼包',
		dataIndex: 'starValueAmountTitle',
		key: 'starValueAmountTitle',
		width: '15%',
		align: 'center',
		render: (text, item) => (TableCell(text))
	},
	{
		title: '会员权益',
		dataIndex: 'starValueRateTitle',
		key: 'starValueRateTitle',
		width: '20%',
		align: 'center',
		render: (text) => (TableCell(text))
	},
	{
		title: '操作',
		dataIndex: 'id',
		key: 'id',
		align: 'center',
		width: '15%',
		render: (id, record) => TableAction(id, record, actionClickHandle)
	},
];

export const takeDataSource = (data) => {
	// 解析表格数据，使符合columns格式要求
	return data && data.map((item) => {
		let key = 0;
		item.merchantKey = key++;//操作key
		return item;
	});
};

// 表格项内容为图片
const ImgCell = (url) => {
	return (
		<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
			<div className={styles.imgbox}>
				<img className={styles.img} src={url} />
			</div>
		</div>
	);
};


export const takeColumns = (actionClickHandle) => {
	return columns(actionClickHandle);
};

const TableCell = (text) => {
	return (
		<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
			{text}
		</div>
	);
};

const TableAction = (id, record, actionClickHandle) => {
	if (record.isInit == '1') {
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => actionClickHandle(id, 'EDIT', record)}>编辑</div>
			</div>
		);
	} else {
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => actionClickHandle(id, 'EDIT', record)}>编辑</div>
				<div className={styles.item} onClick={() => actionClickHandle(id, 'DISABLE', record)}>删除</div>
				{/* <div className={styles.item} onClick={() => actionClickHandle(id, 'USE')}>启用</div> */}
			</div>
		);
	}
};