/**
 * 修改登录账号
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './LoginChangeModal.module.scss';
import { XInputNum } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

class LoginChangeModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        accountName: ''
    }

    // 确认
    _handleOk = () => {
        const { accountName } = this.state;
        const { id } = this.props.data;
        const data = {
            accountId: id,
            accountName
        };
        this.setState({ confirmLoading: true });
        http('/user/account/changeMemberAccount', data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('修改成功');
                this.setState({ visible: false });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        }).finally(() => {
            this.setState({ confirmLoading: false });
        });
    }

    // 取消
    _handleCancel = () => {
        this.setState({ visible: false });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, accountName } = this.state;
        const { data } = this.props;

        console.log(data);

        return (
            <Modal
                width={400}
                centered
                title='修改登录账号'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.up}>
                        <div className={styles.label}>原登录账号：</div>{data.tel}
                    </div>
                    <div className={styles.down}>
                        <XInputNum
                            inputStyle={{ width: '192px', marginLeft: '-8px' }}
                            label='请输入新账号：'
                            isRequired={true}
                            bindThis={this}
                            bindName='accountName'
                            placeholder='请输入11位手机号码'
                            value={accountName}
                            maxLength={11}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

LoginChangeModal.propTypes = {
    data: PropTypes.object, // 外部传递参数
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default LoginChangeModal;