/**
 * 商城设置 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

/**
 * 商城参数 Saga  
 */
export const showpareme = function* () {
    yield takeEvery(T.GET_SHOWPAREME_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/goods/recoconfig/list', action.payload, 'POST');
            yield put({ type: T.SET_SHOWPAREME_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 商品列表规则参数列表 Saga  
 */
export const mallRecommendList = function* () {
    yield takeEvery(T.GET_MALLRECOMMENDLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/goods/recorule/list', action.payload, 'POST');
            yield put({ type: T.SET_MALLRECOMMENDLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 商城设置推荐规则下拉框 Saga
 */
export const mallrulesDropDown = function* () {
    yield takeEvery(T.GET_MALLRULES_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/enum/GoodsRecoRuleEnum', action.payload, 'POST');
            yield put({ type: T.SET_MALLRULES_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 商城设置商品联级分类 Saga  @param   categoryGrade  分为三级 firstCategory|secondCategory|thirdCategory
 */
export const categoryLinkage = function* () {
    yield takeEvery(T.GET_CATEGORYLINKAGE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/goods/category/linkage', action.payload, 'POST');
            switch (action.payload.type) {
                case 'firstCategory':
                    yield put({ type: T.SET_FIRSTCATEGORY, payload: result });
                    break;
                case 'secondCategory':
                    yield put({ type: T.SET_SECONDCATEGORY, payload: result });
                    break;
                case 'thirdCategory':
                    yield put({ type: T.SET_THIRDCATEGORY, payload: result });
                    break;
            }
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//task-3097
export const getSalaAfterRuleList = function* () {
    yield takeEvery(T.GET_SALAAFTER_RULELIST, function* requestData(action) {
        try {
            let result = yield http('/goods/afterRule/listAll', action.payload, 'POST');
            yield put({ type: T.SET_SALAAFTER_RULELIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
export const getSalaAfterTypeEnum = function* () {
    yield takeEvery(T.GET_SALAAFTER_TYPEENUM, function* requestData(action) {
        try {
            let result = yield http('/enum/SalesAfterTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_SALAAFTER_TYPEENUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
