import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { XTabNav, XPagination, XInput, XDatePicker, XSelect, XOKButton, XCancelButton, XTableHOC, XTableAdapter, XInputNum } from '@/components/xqxc_ui';
import { Table, DatePicker } from 'antd';
import moment from 'moment';
import { SearchBox, SearchItem } from '@/components/Layout';
import { takeDataSource, takeColumns } from './components/TableData';
import AcceptModal from '../../modal/AcceptModal';
import ResultModal from '../../modal/ResultModal';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';
import { getDistributionFlagEnum } from '@/reducers/ordermanage/productOrder/actions';
import { getSaleList, getSaleStatus, getSaleType } from '@/reducers/salemanage/salelist/actions';

import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '全部售后订单' },
            { id: 'WAITE_MERCHANT', label: '待处理' },
            { id: 'PROCESSING', label: '处理中' },
            { id: 'COMPLETE', label: '已完成' },
            { id: 'CLOSE', label: '已关闭' },
        ],
        curTabID: 1,  // 默认显示的tab，1：全部售后订单 2：待处理 3：处理中 4：已完成 5：已关闭
        pageNum: 1,
        pageSize: 10,
        tel: null,
        orderSaleId: null,
        startDate: null,
        endDate: null,
        chargeValue: [],
        salesType: {},
        process: {},
        goodsName: null,
        itemNo: null,
        shopName: null,
        orderId: null,
        scrollY: 0,
        isWholesale: { code: 2, value: '全部'},
    }

    componentDidMount() {
        // this.props.getSaleList({ pageNum: 1, pageSize: 10 });
        this.props.getSaleStatus();
        this.props.getSaleType();
        this.props.getDistributionFlagEnum();
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        const { pageNum, pageSize, tel, orderSaleId, startDate,isWholesale,
            endDate,
            salesType,
            goodsName,
            itemNo,
            orderId,
            shopName } = this.state;
        this.searchCache = {
            pageNum,
            pageSize,
            tel,
            orderSaleId,
            startDate,
            endDate,
            salesType: salesType.code,
            isWholesale: isWholesale.code,
            goodsName,
            itemNo,
            shopName,
            orderId,
            process: tabID === 1 ? '' : tabID,
        };
        // this.props.getSaleList(this.searchCache);
        // CLOSE 已关闭, COMPLETE 已完成, WAITE_MERCHANT 待处理, PROCESSING 处理中
        this.setState({ curTabID: tabID, pageNum: 1 }, () => {
            this._searchHandle('useCache');
        });
    }
    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, tel, orderSaleId, startDate, curTabID, isWholesale,
            endDate,
            salesType,
            goodsName,
            itemNo,
            process,
            orderId,
            shopName } = this.state;
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            tel,
            orderSaleId,
            startDate,
            endDate,
            salesType: salesType.code,
            isWholesale: isWholesale.code,
            goodsName,
            itemNo,
            shopName,
            orderId,
            process: curTabID === 1 ? process.code : curTabID,
        };
        this.props.getSaleList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置
    _resetHandle = () => {
        this.setState({
            pageNum: 1,
            pageSize: 10,
            tel: null,
            orderSaleId: null,
            startDate: null,
            endDate: null,
            salesType: {},
            isWholesale: { code: 2, value: '全部'},
            goodsName: null,
            itemNo: null,
            shopName: null,
            process: {},
            orderId: null,
            chargeValue: [],
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        // this.props.getSaleList({ pageNum: 1, pageSize: 10 });
    }
    // 分页  
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize });
        const { tel, orderSaleId, startDate, isWholesale,
            endDate,
            salesType,
            goodsName,
            itemNo,
            process,
            orderId,
            shopName } = this.state;
        this.searchCache = {
            ...this.searchCache,
            pageNum,
            pageSize,
            tel,
            orderSaleId,
            startDate,
            endDate,
            salesType: salesType.code,
            isWholesale: isWholesale.code,
            goodsName,
            itemNo,
            shopName,
            orderId,
            process: process.code,
        };
        this.props.getSaleList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 售后类型
    _saleChangeHandle = e => {
        this.setState({
            salesType: e,
        });
    }
    // 处理状态
    _dealChangeHandle = e => {
        this.setState({
            process: e,
        });
    }
    // 操作
    _actionClickHandle = (record, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'DETAIL':
                addOneBreadcrumbPath({
                    title: '售后详情',
                    path: `/home/salemanage/salelist/contentInfo/${record.id}/${record.salesType}`
                });
                history.push({
                    pathname: `/home/salemanage/salelist/contentInfo/${record.id}/${record.salesType}`
                });
                break;
            case 'ACCEPT':
                this.setState({
                    currentOpenModal: 'ACCEPT',
                    currentOpenData: {
                        id: record.id,
                        sellerId: record.sellerId,
                    }
                });
                break;
            case 'RESULT':
                this.setState({
                    currentOpenModal: 'RESULT',
                    currentOpenData: {
                        id: record.id,
                        sellerId: record.sellerId,
                    }
                });
                break;

        }
    }
    // 打开受理弹框
    _renderMode = () => {
        if (this.state.currentOpenModal === 'ACCEPT') {
            return <AcceptModal visible={true} refresh={this._SearchHandle} data={this.state.currentOpenData} closeMode={this._closeMode} />;
        } else if (this.state.currentOpenModal === 'RESULT') {
            return <ResultModal visible={true} refresh={this._SearchHandle} data={this.state.currentOpenData} closeMode={this._closeMode} />;
        } else {
            return null;
        }
    }
    // 关闭受理弹框
    _closeMode = () => {
        this.setState({ currentOpenModal: '' });
    }
    render() {
        const { DistributionFlagEnum, saleStatus, saleType, saleList: { dataList = [], resultTotal, pageSize, pageNum } } = this.props;
        const { startDate, endDate, curTabID, tel, orderSaleId, salesType, process, goodsName, itemNo, shopName, orderId, isWholesale } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={`${styles.flexBoxContainer} ${styles.orderContainer}`}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <div style={{ marginTop: 10 }}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='会员手机号'
                                    placeholder='请输入'
                                    value={tel}
                                    bindThis={this}
                                    bindName='tel'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInputNum
                                    label='服务单号'
                                    placeholder='请输入'
                                    value={orderSaleId}
                                    bindThis={this}
                                    bindName='orderSaleId'
                                    min={0}
                                    precision={0}
                                    maxLength={16}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInputNum
                                    label='订单号'
                                    placeholder='请输入'
                                    value={orderId}
                                    bindThis={this}
                                    bindName='orderId'
                                    min={0}
                                    precision={0}
                                    maxLength={16}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='售后类型'
                                    placeholder='请选择'
                                    renderData={saleType}
                                    onChange={this._saleChangeHandle}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={salesType.value}
                                    showSearch={true}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='商品货号'
                                    placeholder='请输入'
                                    value={itemNo}
                                    bindThis={this}
                                    bindName='itemNo'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='商品名称 '
                                    placeholder='请输入'
                                    value={goodsName}
                                    bindThis={this}
                                    bindName='goodsName'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='店铺名称'
                                    placeholder='请输入'
                                    value={shopName}
                                    bindThis={this}
                                    bindName='shopName'
                                />
                            </SearchItem>

                            {curTabID === 1 ? <SearchItem><XSelect
                                label='处理状态'
                                placeholder='请选择'
                                renderData={saleStatus}
                                onChange={this._dealChangeHandle}
                                dataIndex='value'
                                keyIndex='code'
                                value={process.value}
                                showSearch={true}
                            /></SearchItem> : null}
                            <SearchItem>
                                <XSelect
                                    label='是否批发'
                                    placeholder='请选择'
                                    renderData={DistributionFlagEnum}
                                    dataIndex='value'
                                    keyIndex='code'
                                    bindThis={this}
                                    bindName='isWholesale'
                                    value={isWholesale.value}
                                    showSearch={true}
                                /></SearchItem>
                            <SearchItem>
                                <XDatePicker
                                    allowClear
                                    label='下单时间'
                                    value={startDate}
                                    bindThis={this}
                                    bindName='startDate'
                                    isFormat
                                />
                                <XDatePicker
                                    allowClear
                                    style={{ marginRight: 20 }}
                                    value={endDate}
                                    bindThis={this}
                                    bindName='endDate'
                                    isFormat
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <XTableAdapter render={scrollY => { this.setState({ scrollY }); }}>
                        <div className={styles.tableContainer} style={{ height: '100%' }}>
                            <Table
                                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                                rowKey={(record) => record.id}
                                columns={takeColumns(this._actionClickHandle)}
                                dataSource={takeDataSource(dataList)}
                                pagination={false}
                                scroll={{ x: 'max-content', y: this.state.scrollY }}
                            />
                            <XPagination
                                resultTotal={resultTotal}
                                pageSize={pageSize}
                                pageNum={pageNum}
                                onChange={this._paginationChange}
                            />
                        </div>
                    </XTableAdapter>

                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 跳转其它路由页面
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    getSaleList: PropTypes.func,
    getSaleStatus: PropTypes.func,
    getSaleType: PropTypes.func,
    getDistributionFlagEnum: PropTypes.func,
    saleList: PropTypes.object,
    saleStatus: PropTypes.array,
    saleType: PropTypes.array,
    DistributionFlagEnum: PropTypes.array
};
const mapStateToProps = (state) => {
    return {
        saleList: state.SaleList.saleList,
        saleStatus: state.SaleList.saleStatus,
        saleType: state.SaleList.saleType,
        DistributionFlagEnum: state.productOrder.DistributionFlagEnum
    };
};
export default connect(mapStateToProps, { addOneBreadcrumbPath, getSaleList, getSaleStatus, getSaleType, getDistributionFlagEnum })(Main);