import * as T from './actiontypes';
/** 
 * 【秀场举报】列表数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} accountTel 发布人手机号
 * @param {string} reportClass 频道分类 
 * @param {string} reportAccountTel 举报人手机号
 * @param {string} contentTitle 举报标题
 */

export const getshowReort = (dataload) => ({
    type: T.GET_SHOWLIST_DATA,
    payload: {
        ...dataload,
    }
});

/**
 * 秀场分类数据源(saga)
 *  @param 暂无
 */
export const getshowReortState = () => ({
    type: T.GET_SHOWREORTSTATE_DATA,
});

/**
 *  秀场举报禁播，封禁类型和封禁理由数据源(saga)
 *  @param 暂无
 */
export const getreportStop = () => ({
    type: T.GET_SHOWRESTOP_DATA,
});

/**
 * 秀场举报详情'数据源(saga)
 * @param {number} id  获取某个用户情对应的id
 */
export const getshowReortDetails = (id) => ({
    type: T.GET_SHOWREDETAIL_DATA,
    payload: {
        id,
    }
});
