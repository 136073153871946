import React from 'react';
import PropTypes from 'prop-types';

class SearchBox extends React.Component {

    render() {
        return (
            <ul style={this.props.style}>
                {this.props.children}
            </ul>
        );
    }
}

SearchBox.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
    style: PropTypes.object
};

export default SearchBox;