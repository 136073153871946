/**
 *  【其它付款管理】入口页
 * */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import Batch from './batch';
import Pay from './pay';
import Sign from './sign';
import Monthly from './monthly';
import { getSessionStorage, removeSessionStorage } from '@/assets/js/storage';

const tablist = [
    { id: '1', label: '批量打款' },
    { id: '2', label: '付款记录' },
    { id: '3', label: '签约记录' },
    { id: '4', label: '月度统计' },
];

const otherpay = ({ history }) => {

    const [curTabID, setCurTabID] = useState('1');
    const [param, setParam] = useState({});

    useState(() => {
        const tab = getSessionStorage('otherpay');
        if (tab != '') {
            setCurTabID(tab);
            removeSessionStorage('otherpay');
        }
    }, []);

    const jumpToTab = (type, item) => {
        setParam({ type, item });
        setCurTabID('2');
        setTimeout(() => {
            setParam({});
        }, 1000);
    };

    const renderPage = () => {
        switch (curTabID) {
            case '1':
                return <Batch history={history} jumpToTab={jumpToTab} />;
            case '2':
                return <Pay history={history} searchPrama={param} />;
            case '3':
                return <Sign history={history} />;
            case '4':
                return <Monthly history={history} />;
            default:
                return <Sign history={history} />;
        }
    };

    return <div className={styles.flexBoxContainer}>
        <XTabNav renderData={tablist} onChange={tab => setCurTabID(tab.toString())} activedID={curTabID} />
        <div style={{ margin: '5px 0' }}></div>
        {renderPage()}
    </div>;
};

otherpay.propTypes = {
    history: PropTypes.object
};

export default otherpay;