import * as T from './actiontypes';

/**
 * 获取角色列表
 */
export const getRolesList = (value = {}) => ({
    type: T.GET_ROLES_LIST,
    payload: value
});


/**
 * 获取角色详情
 */
export const getRolesDetail = (value = {}) => ({
    type: T.GET_ROLES_DETAIL,
    payload: value
});

/**
 * 获取本公司超级管理员
 */
export const getAdminEmpl = (value = {}) => ({
    type: T.GET_ADMIN_EMPL,
    payload: value
});

/**
 * 获取平台、运营中心、区域中心、商户某个角色下面得所有员工
 */
export const getEmplsByRole = (value = {}) => ({
    type: T.GET_EMPL_BY_ROLE,
    payload: value
});

/**
 * 获取未分类角色的员工
 */
export const getUnEmpl = (value = {}) => ({
    type: T.GET_UN_EMPL,
    payload: value
});

/**
 * 获取角色下拉列表(复制角色权限以及权限分配时均可用)
 */
export const getRoleSelect = (value = {}) => ({
    type: T.GET_ROLE_SELECT,
    payload: value
});

/**
 * 获取某角色权限的菜单
 */
export const getMenuByRole = (value = {}) => ({
    type: T.GET_MENU_BY_ROLE,
    payload: value
});

/**
 * 获取员工下拉列表（在新建员工角色时用到）
 */
export const getDownEmpls = (value = {}) => ({
    type: T.GET_DOWNEMPLS,
    payload: value
});

/**
 * 获取员工下拉列表（在新建员工角色时用到）
 */
export const getListRoleByEmpl = (value = {}) => ({
    type: T.GET_ROLE_BY_EMPL,
    payload: value
});




