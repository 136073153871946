import React from 'react';
import { Modal, message, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import { XTextArea } from '@/components/xqxc_ui';
import OperationList from '@/vcomps/OperationList';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { formValids } from '@/assets/js/utils';
import http from '@/assets/api/http';
import styles from './GenerateStatement.module.scss';

class AcceptModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,

        remark: '',
        merchant: {}
    }

    _handleOk = () => {
        const { merchant, startDate, endDate, remark } = this.state;
        const saveProps = {
            targetId: merchant.id,
            targetName: merchant.companyName,
            targetType: 'OP_CENTER',
            startDate: startDate ? startDate.format('YYYY-MM-DD 00:00:00') : undefined,
            endDate: endDate ? endDate.format('YYYY-MM-DD 23:59:59') : undefined,
            remark
        };

        if (formValids(saveProps, {
            targetId: '请选择运营中心',
            startDate: '请选择对账周期的时间范围',
            endDate: '请选择对账周期的时间范围'
        })) {
            this.setState({ confirmLoading: true });
            http('/settle/reconciliationInfo/addReconciliation', saveProps).then((res) => {
                message.success('发起对账成功!');
                this.setState({ visible: false, confirmLoading: false });
                this.props.closeMode();
                this.props.refsh();
            }).catch((res) => {
                message.error(res.message);
                this.setState({ confirmLoading: false });
            });
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }

    _areaChangeHandle = (e) => {
        this.setState({ areaValue: e.target.value });
    }

    render() {
        const { visible, confirmLoading, title, remark, merchant } = this.state;

        return (
            <Modal
                width={520}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.top}>
                        <span>运营中心：</span>
                        <OperationList
                            selectStyle={{ width: '380px' }}
                            placeholder='请选择运营中心'
                            value={merchant}
                            bindThis={this}
                            bindName='merchant'
                        />
                    </div>
                    <div className={styles.top}>
                        <span>对账周期：</span>

                        <DatePicker.RangePicker locale={locale} style={{ width: '380px', marginLeft: 10 }} format='YYYY-MM-DD'
                            onChange={(e) => {
                                if (e.length) this.setState({ startDate: e[0], endDate: e[1] });
                                else this.setState({ startDate: undefined, endDate: undefined });
                            }}
                        />

                    </div>
                    <div className={styles.top}>
                        <span>备注：</span>

                        <XTextArea
                            style={{ width: '100%' }}
                            inputStyle={{ width: '380px' }}
                            value={remark}
                            bindThis={this}
                            bindName='remark'
                        />

                    </div>
                </div>
            </Modal>
        );
    }
}

AcceptModal.propTypes = {
    merchantList: PropTypes.array,
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    refsh: PropTypes.func,
};

export default AcceptModal;