import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import Friendbean from '../friendbean';//魅力值订单
import Failorder from '../failorder';//支付失败订单
import Abnormalorder from '../abnormalorder';//
import Warnuser from '../warnuser';//预警用户

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '魅力值订单' },
            { id: 2, label: '支付失败订单' },
            { id: 3, label: '异常订单' },
            { id: 4, label: '预警用户' },
        ],
        curTabID: 1,  //
    }

    componentDidMount() {
        const { history } = this.props;
        if (history.location.state && history.location.state.tabID) {
            this.setState({
                curTabID: history.location.state.tabID,
                orderId: history.location.state.orderId,
            }); // 如果路由过来的state参数带tabID值，则渲染该tabID值下的内容
        }
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }
    // 获取跳转的手机号
    _getTel = (tel) => {
        this.setState({ tel });
    }
    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        const { orderId } = this.state;
        switch (this.state.curTabID) {
            case 1:
                return <Friendbean />;
            case 2:
                return <Failorder />;
            case 3:
                return <Abnormalorder telephone={this.state.tel} />;
            case 4:
                return <Warnuser tabChangeHandle={this._tabChangeHandle} telHandle={this._getTel} />;
            default:
                return <Friendbean />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={styles.tableContainer}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 跳转其它路由页面
};

export default Main;