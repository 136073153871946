export const GET_ADVOCACYLIST_DATA = 'ADVOCACY/GET_ADVOCACYLIST_DATA'; // 代言任务列表
export const SET_ADVOCACYLIST_DATA = 'ADVOCACY/SET_ADVOCACYLIST_DATA'; // 代言任务列表

export const GET_ADVOCACYDETAILS_DATA = 'ADVOCACY/GET_ADVOCACYDETAILS_DATA'; // 代言任务编辑、详情获取数据
export const SET_ADVOCACYDETAILS_DATA = 'ADVOCACY/SET_ADVOCACYDETAILS_DATA'; //代言任务编辑、详情获取数据

export const GET_DROPDIWN_DATA = 'ADVOCACY/GET_DROPDIWN_DATA'; // 代言任务下拉框
export const SET_DROPDIWN_DATA = 'ADVOCACY/SET_DROPDIWN_DATA'; //代言任务下拉框

export const GET_RAISEALL_DATA = 'ADVOCACY/GET_RAISEALL_DATA'; // 代言任务新建-选择商品下拉框、和全选复选
export const SET_RAISEDATA_DATA = 'ADVOCACY/SET_RAISEDATA_DATA'; //代言任务新建-选择商品下拉框
export const SET_ALL_DATA = 'ADVOCACY/SET_ALL_DATA'; //代言任务新建-全选复选
export const SET_REWARD_DATA = 'ADVOCACY/SET_REWARD_DATA'; //代言任务新建-奖励类型
export const SET_COUPONNAME_DATA = 'ADVOCACY/SET_COUPONNAME_DATA'; //代言任务新建-优惠劵名称  
export const SET_EXECUTION_DATA = 'ADVOCACY/SET_EXECUTION_DATA'; //任务执行时间
 
export const SET_RAISETABALE_DATA = 'ADVOCACY/SET_RAISETABALE_DATA'; //代言任务新建选择商品表格数据
export const GET_RAISETABALE_DATA = 'ADVOCACY/GET_RAISETABALE_DATA'; //代言任务新建选择商品表格数据
