// 供应商列表
export const GET_SUPPLIERLISTS_TABLESOURCE = 'SUPPLIERLISTS/GET_SUPPLIERLISTS_TABLESOURCE';
export const SET_SUPPLIERLISTS_TABLESOURCE = 'SUPPLIERLISTS/SET_SUPPLIERLISTS_TABLESOURCE';

// 店铺类型
export const GET_SHOPTYPES = 'SUPPLIERLISTS/GET_SHOPTYPES';
export const SET_SHOPTYPES = 'SUPPLIERLISTS/SET_SHOPTYPES';

// 仓库类型
export const GET_WAREHOUSE_TYPE = 'SUPPLIERLISTS/GET_WAREHOUSE_TYPE';
export const SET_WAREHOUSE_TYPE = 'SUPPLIERLISTS/SET_WAREHOUSE_TYPE';

// 获取地域：省市区
export const GET_ACCOUNT_AREA = 'SUPPLIERLISTS/GET_ACCOUNT_AREA';
export const SET_PROVINCE = 'SUPPLIERLISTS/SET_PROVINCE';
export const SET_CITY = 'SUPPLIERLISTS/SET_CITY';
export const SET_STREET = 'SUPPLIERLISTS/SET_STREET';

// 经营品类
export const GET_GOODSTYPES = 'SUPPLIERLISTS/GET_GOODSTYPES';
export const SET_GOODSTYPES = 'SUPPLIERLISTS/SET_GOODSTYPES';


// 服务商下拉列
export const GET_SUPPLIER_SELECT = 'SUPPLIERLIST/GET_SUPPLIER_SELECT';
export const SET_SUPPLIER_SELECT = 'SUPPLIERLIST/SET_SUPPLIER_SELECT';
