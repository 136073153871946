import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';


const TableContent = ({
    renderData: { resultTotal, pageSize, pageNum, dataList },
    paginationChange
}) => {
    const takeColumns = () => ([
        {
            title: '日期',
            dataIndex: 'date',
            key: 'date',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动类型',
            dataIndex: 'activityType',
            key: 'activityType',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: (text) => formatHandle(text)
        },
        {
            title: '活动名称',
            dataIndex: 'activityTitle',
            key: 'activityTitle',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '动销商品数',
            dataIndex: 'turnoverQty',
            key: 'turnoverQty',
            width: '10%',
            align: 'center',
            render: tooltip
        },
        {
            title: '商品销售量',
            dataIndex: 'salesQty',
            key: 'salesQty',
            width: '10%',
            align: 'center',
            render: tooltip
        },
        {
            title: '购买人数',
            dataIndex: 'buyersNum',
            key: 'buyersNum',
            width: '10%',
            align: 'center',
            render: tooltip
        },
        {
            title: '新用户购买人数',
            dataIndex: 'newBuyersNum',
            key: 'newBuyersNum',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付订单量',
            dataIndex: 'paymentOrderNum',
            key: 'paymentOrderNum',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付订单金额',
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '取消订单量',
            dataIndex: 'cancelOrderNum',
            key: 'cancelOrderNum',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '取消订单金额',
            dataIndex: 'cancelAmount',
            key: 'cancelAmount',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
    ]);

    const formatHandle = (text) => {
        return (
            <div>{text.value}</div>
        );
    };

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                columns={takeColumns()}
                dataSource={dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};


TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;