import * as T from './actiontypes';
/** 
 * 【商品举报】列表数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} goodsName 被举报商品名称
 * @param {string} reportAccountTel 举报人手机号
 * @param {string} reportState 举报状态 未处理：UNPROCESSED，已驳回：REJECT，已封禁：BANNED
 */

export const getproductReport = (dataload) => ({
    type: T.GET_PRODUCTLIST_DATA,
    payload: {
        ...dataload
    }
});

/**
 * 商品举报详情'数据源(saga)
 * @param {number} id  获取某个用户情对应的id
 */
export const getproductReportDetails = (id) => ({
    type: T.GET_PRODUCTDETAIL_DATA,
    payload: {
        id,
    }
});
