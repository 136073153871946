/**
 *  商品大类毛利分析
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './main';

const GoodsCategory = ({ history }) => {
    return <Main history={history} />;
};

GoodsCategory.propTypes = {
    history: PropTypes.object
};

export default GoodsCategory;