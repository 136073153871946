/**
 * 新建角色 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, Input, message, Form, Checkbox, Select } from 'antd';
import styles from './AddRole.module.scss';
import { getRoleSelect } from '@/reducers/permissionsmanage/assignauthority/actions';


const { TextArea } = Input;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

class AddRole extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
    }

    componentDidMount() {
        this.props.getRoleSelect();
    }

    // 点击确认
    _handleOk = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const data = { ...fieldsValue, copyRoleId: fieldsValue.copy ? fieldsValue.copyRoleId : '' };
                http('/admin/role/createRole', data, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('新建成功！');
                        this.setState({
                            visible: false,
                        });
                        this.props.closeMode(true);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
            }
        });
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // 渲染角色下拉列
    _renderOption = (list) => {
        return list.map((item) => {
            return <Option key={item.id} value={item.id}>{item.roleName}</Option>;
        });
    }

    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { roleSelect } = this.props;

        return (
            <Modal
                width={900}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        {/* <Form.Item
                            colon
                            htmlFor='roleCode'
                            label={<span>角色编码</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('roleCode', {
                                rules: [{ required: true, message: '请输入角色编码' }],
                            })(
                                <Input placeholder="请输入角色编码" />
                            )}
                        </Form.Item> */}
                        <Form.Item
                            colon
                            htmlFor='roleName'
                            label={<span>角色名称</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('roleName', {
                                rules: [{ required: true, message: '请输入角色名称' }],
                            })(
                                <Input placeholder="请输入角色名称" />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='remark'
                            label={<span>角色描述</span>}
                        >
                            {getFieldDecorator('remark', {
                                // rules: [{ required: true, message: '请输入策略名称' }],
                            })(
                                <TextArea rows={4} placeholder="请输入角色描述" />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='copy'
                            label={<span></span>}
                            style={{ textAlign: 'right' }}
                        >
                            {getFieldDecorator('copy', {
                                // rules: [{ required: true, message: '请输入策略名称' }],
                            })(
                                <Checkbox>复制</Checkbox>
                            )}
                            {getFieldDecorator('copyRoleId', {
                                // rules: [{ required: true, message: '请选择角色' }],
                            })(
                                <Select
                                style={{ width: 180, marginRight: 10 }}
                                placeholder='请选择'
                                >
                                    {this._renderOption(roleSelect)}
                                </Select>
                            )}
                            <span>的角色权限</span>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

AddRole.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    getRoleSelect: PropTypes.func, // 获取角色下拉列
    roleSelect: PropTypes.array, // 角色下拉列
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(AddRole);// 校验

const mapStateToProps = (state) => ({
    rolesList: state.assignauthority.getRolesList,
    emplsByRole: state.assignauthority.getEmplsByRole,
    adminEmpl: state.assignauthority.getAdminEmpl,
    unempl: state.assignauthority.getUnEmpl,
    roleSelect: state.assignauthority.getRoleSelect
});

export default connect(mapStateToProps, { getRoleSelect })(ForgotProducDetails);