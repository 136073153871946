/**
 * 视频审核弹框
 */
import React from 'react';
import { Modal, Input, Radio, message } from 'antd';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import styles from './AuditModal.module.scss';
import TableContent from '../conpontents/TableContent';
import { getVideoAuditDetail } from '@/reducers/auditmanage/videoAudit/actions';
import { XMediaFn } from '@/components/xqxc_ui';

const RadioGroup = Radio.Group;
const { TextArea } = Input;

class AuditModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        videoDetail: {},
        result: '',
        reason: '',
    }

    componentDidMount() {
        const { audit } = this.props;
        this.props.getVideoAuditDetail({ accountId: audit.accountId, feedId: audit.id, showType: audit.showType });
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { auditDetail } = newProps;
        this.setState({ videoDetail: auditDetail });
    }

    _sendReq = (utl, data, winTxt) => {
        this.setState({ confirmLoading: true }, () => {
            http(utl, data, 'POST').then(() => {
                message.success(winTxt);
                this.setState({ visible: false, confirmLoading: false });
                this.props.closeMode(true);
            }).catch((error) => {
                message.error(error.message);
                this.setState({ confirmLoading: false });
            });
        });
    }

    _handleOk = () => {
        const { result, reason } = this.state;
        const { accountId, id, showType } = this.props.audit;
        if (result == '') {
            message.warn('请输入状态');
        }
        if (result === 'APPROVAL') {
            this._sendReq('/video/videoReview', { accountId: accountId, result: result, reason, feedId: id, showType }, '审核成功。');
        }
        if (result === 'REJECT') {
            if (reason != '') {
                this._sendReq('/video/videoReview', { accountId: accountId, result: result, reason, feedId: id, showType }, '审核成功。');
            } else {
                message.warn('请输入不通过原因');
            }
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    _reason = (e) => {
        this.setState({
            reason: e.target.value
        });
    }

    _result = (e) => {
        this.setState({
            result: e.target.value
        });
    }

    render() {
        const { visible, confirmLoading, reason, videoDetail } = this.state;
        const { goodsList } = videoDetail;
        return (
            <Modal
                width={1000}
                bodyStyle={{ paddingRight: 10 }}
                centered
                title='视频审核'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.memberID}>
                        <span>会员账号：</span>
                        <span>{videoDetail.accountName}</span>
                    </div>
                    <div className={styles.cover}>
                        <p className={styles.label}>提交封面：</p>
                        <XMediaFn dataSource={videoDetail.coverPage} />
                    </div>
                    <div className={styles.video}>
                        <p className={styles.label}>提交视频：</p>
                        <XMediaFn
                            dataType='video'
                            dataSource={videoDetail.fileName}
                        />
                    </div>
                    <div className={styles.describe}>
                        <span>视频标题：</span>
                        <span>{videoDetail.feedTitle}</span>
                    </div>
                    <div className={styles.describe}>
                        <span>视频分类：</span>
                        <span>{videoDetail.className}</span>
                    </div>
                    <div className={styles.tables}>
                        <TableContent renderData={goodsList || []} />
                    </div>
                    <div className={styles.status}>
                        <span>状&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;态：</span>
                        <span>
                            <RadioGroup onChange={this._result} style={{ marginLeft: '10px' }}>
                                <Radio value={'APPROVAL'}>通过</Radio>
                                <Radio value={'REJECT'}>不通过</Radio>
                            </RadioGroup>
                        </span>
                    </div>
                    <div className={styles.reason}>
                        <TextArea
                            onChange={this._reason}
                            value={reason}
                            placeholder="请输入不通过原因"
                            autosize={{ minRows: 3, maxRows: 6 }}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

AuditModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    data: PropTypes.number, // 需要审批的用户ID
    audit: PropTypes.object,
    getVideoAuditDetail: PropTypes.func,
    auditDetail: PropTypes.object,
    SourceDetail: PropTypes.object, //表格数据源
};

const mapStateToProps = (state) => ({
    auditDetail: state.videoAudit.videoAuditDetail
});

export default connect(mapStateToProps, { getVideoAuditDetail })(AuditModal);