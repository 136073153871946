/**
 *  星球攻略 -列表
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { XTabNav } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import SupplyStrategy from './SupplyStrategy';
import { getLocalStorage, removeLocalStorage } from '@/assets/js/storage';

const tabList = [
    { id: 'DAILY_TALK', label: '每日说攻略' },
    { id: 'SUPPLIER', label: '供应链攻略' },
];

class List extends React.Component {

    state = {
        curTabID: 'DAILY_TALK'
    }

    componentDidMount() {
        if (getLocalStorage('xqstrategyListCurTabID')) {
            this.setState({ curTabID: getLocalStorage('xqstrategyListCurTabID') });
            removeLocalStorage('xqstrategyListCurTabID');
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <div style={{ marginBottom: '5px' }}> <XTabNav renderData={tabList} onChange={curTabID => this.setState({ curTabID })} activedID={this.state.curTabID} /></div>
                {this.state.curTabID == 'DAILY_TALK' ?
                    <Main history={this.props.history} match={this.props.match} /> :
                    <SupplyStrategy history={this.props.history} match={this.props.match} />
                }
            </div>
        );
    }
}

List.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
};



export default List;