import * as T from './actiontypes';
/**
 * 商城设置数据源
 */
const defaultState = {
    tableSource: [],//分类列表数据源
    selectData: [],// 状态下拉选项
    paginations: {}, //分页对象
    userDetail: {},//详情

    paramelist: [],//商城参数
    firstCategory: [],//商城设置商品联级分类1
    secondCategory: [],//商城设置商品联级分类2
    thirdCategory: [],//商城设置商品联级分类3
};

/**
 * @param {*} state 
 * @param {*} action 
 */
export const mallsettingDate = (state = defaultState, action) => {
    let newTableSource, newSelectData, newPaginations, paramelist, firstCategory, secondCategory, thirdCategory;
    switch (action.type) {
        case T.SET_MALLRECOMMENDLIST_DATA: //商品列表规则参数列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_FIRSTCATEGORY: //商城设置商品联级分类 firstCategory
            firstCategory = action.payload.result;
            return { ...state, firstCategory, };
        case T.SET_SECONDCATEGORY: //商城设置商品联级分类  secondCategory
            secondCategory = action.payload.result;
            return { ...state, secondCategory, };
        case T.SET_THIRDCATEGORY: //商城设置商品联级分类 thirdCategory
            thirdCategory = action.payload.result;
            return { ...state, thirdCategory, };
        case T.SET_MALLRULES_DATA: //商城设置推荐规则下拉框
            newSelectData = action.payload.result;
            return { ...state, selectData: newSelectData, };
        case T.SET_SHOWPAREME_DATA: //商城参数
            paramelist = action.payload.result;
            return { ...state, paramelist, };
        case T.SET_SALAAFTER_RULELIST: //售后规则列表
            return { ...state, salaAfterRuleList: action.payload.result || [] };
        case T.SET_SALAAFTER_TYPEENUM: //售后类型枚举
            return { ...state, salaAfterTypeEnum: action.payload.result || [] };
        default:
            return state;
    }
};