import { createModel } from '@/store/tools';

const model = {
    namespace: 'palatGoodsAudit',
    build: (T, A, S, R) => ({
        actiontypes: {
            'palatGoodsAuditList': T('palatGoodsAuditList'),
            'getMerchantName': T('getMerchantName'),
            'getActiveTypeEnum': T('getActiveTypeEnum'),
            'getpalatGoodsDetails': T('getpalatGoodsDetails'),
        },
        actions: {
            'palatGoodsAuditList': A('palatGoodsAuditList'),//列表数据
            'getMerchantName': A('getMerchantName'),//商户下拉数据
            'getActiveTypeEnum': A('getActiveTypeEnum'),//业务类型下拉数据
            'getpalatGoodsDetails': A('getpalatGoodsDetails'),//详情数据
        },
        sagas: {
            'palatGoodsAuditList': S('palatGoodsAuditList', '/goods/wholesale/findWholesaleGoodsPage'),
            'getMerchantName': S('getMerchantName', '/merchant/info/listMerchantIdAndName'),
            'getActiveTypeEnum': S('getActiveTypeEnum', '/enum/GoodsTypeEnum'),
            'getpalatGoodsDetails': S('getpalatGoodsDetails', '/goods/wholesale/wholesaleGoodsInfo', 'POST', 'goodsId'),
        },
        reducers: {
            'goodsList': R('palatGoodsAuditList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'merchantName': R('getMerchantName', { merchantIdAndNames: [] }),
            'activeTypeEnum': R('getActiveTypeEnum', []),
            'palatDetails': R('getpalatGoodsDetails', { infoGoodsResp: {}, wholesaleGoodsInfoMerchantInfo: {} }),
        }
    })
};
export default createModel(model);