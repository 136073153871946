import * as T from './actiontypes';

/**
 * 获取物流公司状态
 */
export const getLogisticsState = (value = {}) => ({
    type: T.GET_LOGISTICS_STATE,
    payload: value
});

/**
 * 获取物流公司列表
 */
export const getLogisticsList = (value = {}) => ({
    type: T.GET_LOGISTICS_LIST,
    payload: value
});
