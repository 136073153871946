/**
 * 新建白名单
 */
import React from 'react';
import { Modal, Checkbox } from 'antd';
import { XInput, XOKButton, XCancelButton, } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './PayModal.module.scss';
import { message, Radio, Icon } from 'antd';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import model from '@/reducers/systemmanage/showset/model';
import PayLists from '../components/PayLists';

const CheckboxGroup = Checkbox.Group;

class Pay extends React.PureComponent {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        account: '',//会员账号
        nickName: '',//会员昵称
        selectedRowKeysL: [],//对应的key数据左
        selectedRowKeysR: [],//对应的key数据右
        selectedCheckboxesL: [],//会员等级全选左
        selectedCheckboxesR: [],//会员等级全选右
        pageNum: 1,
        pageSize: 25,
        pageNumR: 1,
        pageSizeR: 25,
        //开通类型
        isOpenLiveCharge: 0,
        isOpenVideoCharge: 0,
        isOpenGraphicsCharge: 0,
        cleanselectedL: false,//清除选中selectedkey
        cleanselectedR: false,//清除选中selectedkey
        loading: false,
    }

    componentDidMount() {
        const { getPayType, getLevelList, getCleanList } = this.props;
        getCleanList();//清除操作人创建的所有临时数据
        getPayType();//类型
        this._searchHandle();//左边列表数据
        this._searchHandleR();//右边列表数据
        getLevelList();//会员等级
    }

    componentWillUnmount() {
        this.props.getCleanList();//清除操作人创建的所有临时数据
    }

    componentDidUpdate(prevProps) {
        if (this.props.whiteListLeft !== prevProps.whiteListLeft) {
            this.setState({ loading: false });
        }
    }

    _handleOk = () => {
        const { confirmLoading } = this.state;
        if (!confirmLoading) {
            this.setState({ confirmLoading: true }, () => {
                http('/user/userChargeShow/tempSaveToUserChargeShow', {}, 'POST')
                    .then((res) => {
                        if (res.result > 0) {
                            message.success(`成功新建${res.result}条数据。`);
                            this.setState({
                                visible: false,
                            }, () => { this.props.closeMode(true); });
                        } else {
                            this.setState({ confirmLoading: false });
                            message.warn('请批量新建数据。');
                        }
                    }).catch((reject={}) => {
                        this.setState({ confirmLoading: false });
                        message.error(reject.message);
                    });
            });
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // 开通类型
    checkChange = (value) => {
        if (value.length === 0) {
            this.setState({
                isOpenLiveCharge: 0,
                isOpenVideoCharge: 0,
                isOpenGraphicsCharge: 0
            });
        } else {
            value.map(() => {
                this.setState({
                    isOpenLiveCharge: value.includes('LIVE') ? 1 : 0,
                    isOpenVideoCharge: value.includes('VIDEO') ? 1 : 0,
                    isOpenGraphicsCharge: value.includes('GRAPHICS') ? 1 : 0
                });
            });
        }
    }

    //开通类型数据
    payOptions = (data) => {
        let arr = [];
        data.map((item) => {
            let obj = {
                label: item.value,
                value: item.code,
            };
            arr.push(obj);
        });
        return arr;
    }

    // 查询
    _searchHandle = () => {
        const { pageNum, pageSize, account, nickName, selectedCheckboxesL } = this.state;
        this.searchCache = {
            accountName: account,//会员账号
            nickName: nickName,//会员昵称
            accountLevelIdList: selectedCheckboxesL,//会员等级
            pageNum,
            pageSize,
        };
        this.props.getWhiteListLeft(this.searchCache);
        this.setState({ loading: true });
    }

    // 右列表查询
    _searchHandleR = () => {
        const { pageNumR, pageSizeR, selectedCheckboxesR } = this.state;
        this.searchCacheR = {
            accountLevelIdList: selectedCheckboxesR,//会员等级
            pageNum: pageNumR,
            pageSize: pageSizeR,
        };
        this.props.getWhiteListRight(this.searchCacheR);
    }

    // 重置
    _resetHandle = () => {
        this.setState({ account: '', nickName: '', }, () => {
            const { account, nickName, } = this.state;
            this.searchCache = { ...this.searchCache, accountName: account, nickName: nickName };
        });
    }

    //左右分页
    _paginationChange = (pageSize, pageNum, type) => {
        if (type == 'left') {
            if (this.state.loading) {
                message.warn('数据加载完成后再操作。');
            } else {
                this.setState({ pageSize, pageNum }, () => {
                    this._searchHandle();
                });
            }
        } else if (type == 'right') {
            this.setState({ pageSizeR: pageSize, pageNumR: pageNum }, () => {
                this._searchHandleR();
            });
        }
    }

    //左右选择数据  
    _dealHandleRight = () => {
        const { selectedRowKeysL, isOpenLiveCharge, isOpenVideoCharge, isOpenGraphicsCharge } = this.state;
        if (selectedRowKeysL.length === 0) {
            message.warn('请选择列表数据。');
        } else {
            const datas = {
                accountIdList: selectedRowKeysL,//会员id的列表[]
                isOpenLiveCharge,
                isOpenVideoCharge,
                isOpenGraphicsCharge,
            };
            http('/user/userChargeShow/saveTempUserChargeShow', datas, 'POST')
                .then(() => {
                    this._searchHandleR();//右边列表数据
                    this._searchHandle();//左边列表数据
                    message.success('选择成功。');
                    this.setState({
                        isOpenLiveCharge: 0,
                        isOpenVideoCharge: 0,
                        isOpenGraphicsCharge: 0,
                        selectedRowKeysL: [],
                        cleanselectedL: !this.state.cleanselectedL,//清除选中selectedkey
                    });
                }).catch((reject={}) => {
                    message.error(reject.message);
                });
        }
    }
    _dealHandleLeft = () => {
        const { selectedRowKeysR, } = this.state;
        if (selectedRowKeysR.length === 0) {
            message.warn('请选择列表数据。');
        } else {
            const datas = {
                ids: selectedRowKeysR,//会员id的列表[]
            };
            http('/user/userChargeShow/deleteTempUserChargeShowByIds', datas, 'POST')
                .then(() => {
                    this._searchHandleR();//右边列表数据
                    this._searchHandle();//左边列表数据
                    message.success('选择成功。');
                    this.setState({
                        selectedRowKeysR: [],
                        cleanselectedR: !this.state.cleanselectedR,//清除选中selectedkey
                    });
                }).catch((reject={}) => {
                    message.error(reject.message);
                });
        }
    }

    //会员等级全选/单选数据
    checkboxes = (arrData, type) => {
        if (type == 'left') {
            this.setState({ selectedCheckboxesL: arrData }, () => {
                this._searchHandle();
            });
        } else if (type == 'right') {
            this.setState({ selectedCheckboxesR: arrData }, () => {
                this._searchHandleR();
            });
        }
    }

    //获取选中数据 
    selectedRowsHandle = (selectedRowKeys, type) => {
        if (type == 'left') {
            this.setState({ selectedRowKeysL: selectedRowKeys });

        } else if (type == 'right') {
            this.setState({ selectedRowKeysR: selectedRowKeys });
        }
    }


    render() {
        const { visible, title, confirmLoading, account, nickName, cleanselectedL, cleanselectedR } = this.state;
        const { payType, whiteListLeft, whiteListRight, levelList } = this.props;
        return (
            <Modal
                bodyStyle={{ padding: '5px 30px 30px 30px' }}
                width={1024}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                onOk={this._handleOk}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                okText='确定'
                cancelText='取消'
            >

                <div className={styles.container}>
                    <div className={styles.payTop}>
                        <div className={styles.top_title}>
                            <span>基础设置</span>
                        </div>
                        <div className={styles.topcontent}>
                            <div className={styles.layout}>
                                <span>开通类型：</span>
                                <CheckboxGroup
                                    options={this.payOptions(payType)}
                                    onChange={this.checkChange}
                                />
                            </div>
                            <p>勾选相关复选框，开通相关的知识付费功能</p>
                        </div>
                        <div className={styles.top_title}>
                            <span>添加会员</span>
                        </div>
                    </div>
                    <div className={styles.dataTop}>
                        <div className={styles.searchBox}>
                            <XInput
                                inputStyle={{ width: '150px' }}
                                label='会员账号'
                                placeholder='请输入会员账号'
                                value={account}
                                bindThis={this}
                                bindName='account'
                            />
                            <XInput
                                style={{ marginLeft: '20px' }}
                                inputStyle={{ width: '150px' }}
                                label='会员昵称'
                                placeholder='请输入会员昵称'
                                value={nickName}
                                bindThis={this}
                                bindName='nickName'
                            />
                            <XOKButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </div>
                        <div className={`${styles.layout} ${styles.paybot}`}>
                            <div className={styles.left}>
                                <PayLists
                                    levelList={levelList}
                                    checkboxes={(el) => { this.checkboxes(el, 'left'); }}
                                    renderData={whiteListLeft}
                                    paginationChange={(pageSize, pageNum) => { this._paginationChange(pageSize, pageNum, 'left'); }}
                                    selectedRowsHandle={(el) => { this.selectedRowsHandle(el, 'left'); }}
                                    cleanselected={cleanselectedL}//清除选中selectedkey
                                    loading={this.state.loading}
                                />
                            </div>
                            <div className={styles.dealMid}>
                                <div className={styles.dealdata} title='选择数据' onClick={this._dealHandleRight}> <Icon type="right" /></div>
                                <div className={styles.dealdata} title='选择数据' onClick={this._dealHandleLeft}> <Icon type="left" /></div>
                            </div>
                            <div className={styles.right}>
                                <PayLists
                                    levelList={levelList}
                                    checkboxes={(el) => { this.checkboxes(el, 'right'); }}
                                    renderData={whiteListRight}
                                    paginationChange={(pageSize, pageNum) => { this._paginationChange(pageSize, pageNum, 'right'); }}
                                    selectedRowsHandle={(el) => { this.selectedRowsHandle(el, 'right'); }}
                                    cleanselected={cleanselectedR}//清除选中selectedkey
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

Pay.propTypes = {
    title: PropTypes.string,// 弹框标题
    data: PropTypes.object, //数据
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    payType: PropTypes.array,
    getPayType: PropTypes.func,//类型数据(action)
    levelList: PropTypes.array,
    getLevelList: PropTypes.func,//类型数据(action)
    whiteListLeft: PropTypes.object,//左边列表数据
    getWhiteListLeft: PropTypes.func,//新建白名单左边列表数据(action)
    whiteListRight: PropTypes.object,//右边列表数据
    getWhiteListRight: PropTypes.func,//新建白名单右边列表数据(action)
    getCleanList: PropTypes.func,//清除操作人创建的所有临时数据
};

const mapStateToProps = (state) => ({
    payType: state.showset.payType, // 类型
    whiteListLeft: state.showset.whiteListLeft, // 左边列表数据
    whiteListRight: state.showset.whiteListRight, // 右边列表数据
    levelList: state.showset.levelList, // 会员等级
});

export default connect(mapStateToProps, { ...model.actions })(Pay);