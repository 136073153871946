import * as T from './actiontypes';
const defaultState = {
    tableSource: [],//人气值商城分类 列表
    selectData: [],// 人气值商城分类 状态下拉选项
    paginations: {}, //人气值商城分类 列表分页对象

    tablevalSource: [],//人气值商品 列表
    selectvalData: [],// 人气值商品 状态下拉选项
    paginationval: {}, //人气值商品 列表分页对象
    starDetail: {},//人气值商品详情
    starsharing: [],//人气值商品新建优惠劵
    membership: [],//会员等级

    recordTableSource: [],//兑换记录列表数据源
    recordPaginations: {}, // 兑换记录分页对象
    selectrecordData: [],//兑换记录 状态下拉选项
};


/**
 * 存储'秀场管理 -道具管理-'数据源
 * @param {*} state 
 * @param {*} action 
 */
export const starmallListDate = (state = defaultState, action) => {
    let newTableSource, newSelectData, newPaginations, recordSelect,
        newvalTable, newvalPaginations, newvalSelect, starDetail, starsharing, membership, recordTableSource, recordPaginations;
    switch (action.type) {
        case T.SET_STARLIST_DATA: //人气值商城分类 列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_LISTSTATE_DATA: //人气值商城分类 状态下拉选项
            newSelectData = action.payload.result;
            return { ...state, selectData: newSelectData };
        case T.SET_STARVALUE_DATA: //人气值商品 列表
            newvalTable = action.payload.result.dataList;
            newvalPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tablevalSource: newvalTable, paginationval: newvalPaginations };
        case T.SET_VALUESTATE_DATA: //人气值商品 状态下拉选项
            newvalSelect = action.payload.result;
            return { ...state, selectvalData: newvalSelect };
        case T.SET_RECORDSTATE_DATA: //兑换记录分类状态
            recordSelect = action.payload.result;
            return { ...state, selectrecordData: recordSelect };
        case T.SET_VALUEDETAIL_DATA: //人气值商品详情
            starDetail = action.payload.result;
            return { ...state, starDetail };
        case T.SET_VALUEDHARING_DATA: //人气值商品新建优惠劵和会员等级共用 
            starsharing = action.payload.result.dataList;
            membership = action.payload.result.membership;
            return { ...state, starsharing, membership, };
        case T.SET_RECORD_DATA: //兑换记录数据源
            recordTableSource = action.payload.result.dataList;
            recordPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, recordTableSource, recordPaginations };
        default:
            return state;
    }
};
