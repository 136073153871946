// 服务商审核状态
export const GET_SERVICE_STATE = 'SERVICE/GET_ACCOUNT_LEVEL';
export const SET_SERVICE_STATE = 'SERVICE/SET_SERVICE_STATE';

// 区域公司下拉列
export const GET_AREA_COMPANY = 'SERVICE/GET_AREA_COMPANY';
export const SET_AREA_COMPANY = 'SERVICE/SET_AREA_COMPANY';

// 关联状态下拉列
export const GET_ASSOTYPE_LIST = 'SERVICE/GET_ASSOTYPE_LIST';
export const SET_ASSOTYPE_LIST = 'SERVICE/SET_ASSOTYPE_LIST';

// 服务商列表
export const GET_SERVICE_LIST = 'SERVICE/GET_SERVICE_LIST';
export const SET_SERVICE_LIST = 'SERVICE/SET_SERVICE_LIST';

// 服务商详情
export const GET_SERVICE_DETAIL = 'SERVICE/GET_SERVICE_DETAIL';
export const SET_SERVICE_DETAIL = 'SERVICE/SET_SERVICE_DETAIL';
export const CLEAN_SERVICE_DETAIL = 'SERVICE/CLEAN_SERVICE_DETAIL';

// 推荐会员列表
export const GET_SACCOUNT_LIST = 'SERVICE/GET_SACCOUNT_LIST';
export const SET_SACCOUNT_LIST = 'SERVICE/SET_SACCOUNT_LIST';

// 心选推荐列表
export const GET_SRECOMMEND_LIST = 'SERVICE/GET_SRECOMMEND_LIST';
export const SET_SRECOMMEND_LIST = 'SERVICE/SET_SRECOMMEND_LIST';

// 推荐商户列表 
export const GET_SMERCHANT_LIST = 'SERVICE/GET_SMERCHANT_LIST';
export const SET_SMERCHANT_LIST = 'SERVICE/SET_SMERCHANT_LIST';

// 获取分销商品列表
export const GET_GOODS_LIST = 'SERVICE/GET_GOODS_LIST';
export const SET_GOODS_LIST = 'SERVICE/SET_GOODS_LIST';

// 获取推荐服务商列表
export const GET_RECOMMENDED_LIST = 'SERVICE/GET_RECOMMENDED_LIST';
export const SET_RECOMMENDED_LIST = 'SERVICE/SET_RECOMMENDED_LIST';

// 获取服务商收益汇总
export const GET_SERVICE_ICOME_TOTAL = 'SERVICE/GET_SERVICE_ICOME_TOTAL';
export const SET_SERVICE_ICOME_TOTAL = 'SERVICE/SET_SERVICE_ICOME_TOTAL';

// 获取服务商收益明细列表
export const GET_SERVICE_ICOME_TABLE = 'SERVICE/GET_SERVICE_ICOME_TABLE';
export const SET_SERVICE_ICOME_TABLE = 'SERVICE/SET_SERVICE_ICOME_TABLE';

// 获取收支明细中费用名称下拉列
export const GET_COST_NAME = 'SERVICE/GET_COST_NAME';
export const SET_COST_NAME = 'SERVICE/SET_COST_NAME';

// 获取能量值信息
export const GET_JIBEAN_INFO = 'SERVICE/GET_JIBEAN_INFO';
export const SET_JIBEAN_INFO = 'SERVICE/SET_JIBEAN_INFO';

// 获取能量值列表
export const GET_JIBEAN_LIST = 'SERVICE/GET_JIBEAN_LIST';
export const SET_JIBEAN_LIST = 'SERVICE/SET_JIBEAN_LIST';

// 获取贡献值信息
export const GET_XIUBEI_INFO = 'SERVICE/GET_XIUBEI_INFO';
export const SET_XIUBEI_INFO = 'SERVICE/SET_XIUBEI_INFO';

// 获取贡献值列表
export const GET_XIUBEI_LIST = 'SERVICE/GET_XIUBEI_LIST';
export const SET_XIUBEI_LIST = 'SERVICE/SET_XIUBEI_LIST';

// 获取服务商推荐赠送贡献值信息列表
export const GET_SERVICE_XIUBEI_CODELIST = 'SERVICE/GET_SERVICE_XIUBEI_CODELIST';
export const SET_SERVICE_XIUBEI_CODELIST = 'SERVICE/SET_SERVICE_XIUBEI_CODELIST';

//推荐人来源枚举
export const GET_RECOMMENT_TYPE_ENUM = 'SERVICE/GET_RECOMMENT_TYPE_ENUM';
export const SET_RECOMMENT_TYPE_ENUM = 'SERVICE/SET_RECOMMENT_TYPE_ENUM';

//账号状态枚举
export const GET_STATE_ENUM = 'SERVICE/GET_STATE_ENUM';
export const SET_STATE_ENUM = 'SERVICE/SET_STATE_ENUM';

// 获取推荐小B列表
export const GET_SALERS_LIST = 'SERVICE/GET_SALERS_LIST';
export const SET_SALERS_LIST = 'SERVICE/SET_SALERS_LIST';

// 获取特权值明细
export const GET_FRANCHISE_LIST = 'SERVICE/GET_FRANCHISE_LIST';
export const SET_FRANCHISE_LIST = 'SERVICE/SET_FRANCHISE_LIST';
