import * as T from './actiontypes';
/**
 * 【用户举报】数据源
 */
const defaultState = {
    tableSource: [],//分类列表数据源
    selectData: [],// 状态下拉选项
    paginations: {}, //分页对象
    banned: {},//举报禁播，封禁类型和封禁理由
    userDetail: {},//详情
};

/**
 * 存储'秀场管理 -道具管理-'数据源
 * @param {*} state 
 * @param {*} action 
 */
export const reportDate = (state = defaultState, action) => {
    let newTableSource, newSelectData, newPaginations, newbanned,newDetail;
    switch (action.type) {
        case T.SET_USERLIDT_DATA: //列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_REPORTSTOP_DATA: //用户举报禁播，封禁类型和封禁理由数据源(saga)
            newbanned = action.payload;
            return { ...state, banned: newbanned, };

        case T.SET_REPORTSTATE_DATA: //状态下拉
            newSelectData = action.payload.result;
            return { ...state, selectData: newSelectData };

        case T.SET_REPORTDETAIL_DATA: //用户举报详情
            newDetail = action.payload.result;
            return { ...state, userDetail: newDetail, };
        default:
            return state;
    }
};
