/**
 * 业务员表
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { isPlat, isOC } from '@/assets/js/authType';
import { XSelect, XOKButton, XCancelButton, XInput, XDatePicker } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import salesman_model from '@/reducers/operationcenter/salesman/model';
import member_model from '@/reducers/usermanage/member/model';
import KeepAlive from '@/routes/KeepAlive';
import TableContent from './components/TableContent';
import EditModal from './components/EditModal';
import AuditModal from './components/AuditModal';
import ButtonPerssion from '@/routes/ButtonPerssion';

class Main extends React.Component {

    state = {
        OCSelect: {}, // 运营中心
        RESelect: {}, // 区域公司
        ztItem: {}, // 状态
        realName: '', // 姓名
        mobilePhone: '', // 手机号
        idCard: '', // 身份证
        startTime: undefined, // 开始时间
        endTime: undefined, // 结束时间
        showEdit: false, // 显示新建/编辑
        showAudit: false, // 显示审核
        param: null, // 弹框传递参数
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getOperationCenter();
        this.props.getAreaCompany();
        this.props.getStockBuyerAuditTypeEnum();
    }


    // 查询
    _searchHandle = (useCache) => {
        const { OCSelect, RESelect, ztItem, realName, mobilePhone, idCard, startTime, endTime, pageNum, pageSize } = this.state;
        const query = {
            operationsId: OCSelect.id,
            areaCompanyId: RESelect.id,
            auditType: ztItem.code,
            realName,
            mobilePhone,
            idCard,
            startTime,
            endTime,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };

        this.props.getStockBuyerInfoList(query);
        KeepAlive.saveCache({ OCSelect, RESelect, ztItem, realName, mobilePhone, idCard, startTime, endTime, pageNum, pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ OCSelect: {}, RESelect: {}, ztItem: {}, realName: '', mobilePhone: '', idCard: '', startTime: undefined, endTime: undefined, pageNum: autoPageNum, pageSize: autopageSize }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (type, item) => {
        switch (type) {
            case 'audit':
                this.setState({ showAudit: true, param: item });
                break;
            case 'edit':
                this.setState({ showEdit: true, param: { type: 'edit', ...item } });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    // 批量签约
    _newHandle = () => {
        this.setState({ showEdit: true, param: { type: 'new' } });
    }

    // 渲染运营中心下拉列
    _renderOCSelect = () => {
        if (isPlat()) {
            return (
                <XSelect
                    label='归属运营中心'
                    placeholder='请选择运营中心'
                    renderData={this.props.OCSelectList}
                    dataIndex='companyName'
                    keyIndex='id'
                    value={this.state.OCSelect.companyName}
                    onChange={this._OCSelectChangeHandle}
                    bindThis={this}
                    bindName='OCSelect'
                    showSearch={true}
                />
            );
        } else {
            return null;
        }
    }

    // 运营中心发生变化
    _OCSelectChangeHandle = (ocItem) => {
        this.props.getAreaCompany({ operationsId: ocItem.id });
    }

    // 渲染区域公司下拉列
    _renderRESelect = () => {
        if (isPlat() || isOC()) {
            return (
                <XSelect
                    label='归属区域公司'
                    placeholder='请选择区域公司'
                    renderData={this.props.RESelectList}
                    dataIndex='companyName'
                    keyIndex='id'
                    value={this.state.RESelect.companyName}
                    bindThis={this}
                    bindName='RESelect'
                    showSearch={true}
                />
            );
        } else {
            return null;
        }
    }

    render() {
        const { realName, mobilePhone, idCard, ztItem = {}, startTime, endTime, showEdit, showAudit, param } = this.state;
        const { stockBuyerAuditTypeEnum, stockBuyerInfoList } = this.props;
        return (
            <KeepAlive id='salesman' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            {this._renderOCSelect()}
                        </SearchItem>
                        <SearchItem>
                            {this._renderRESelect()}
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                showSearch
                                placeholder='请选择'
                                renderData={stockBuyerAuditTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={ztItem.value}
                                bindThis={this}
                                bindName='ztItem'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='姓名'
                                placeholder='请输入'
                                value={realName}
                                bindThis={this}
                                bindName='realName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='手机号'
                                placeholder='请输入'
                                value={mobilePhone}
                                bindThis={this}
                                bindName='mobilePhone'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='身份证'
                                placeholder='请输入'
                                value={idCard}
                                bindThis={this}
                                bindName='idCard'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                placeholder='请选择开始时间'
                                label='创建日期'
                                value={startTime}
                                bindThis={this}
                                bindName='startTime'
                                isFormat={true}
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                placeholder='请选择结束时间'
                                value={endTime}
                                bindThis={this}
                                bindName='endTime'
                                isFormat={true}
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                        <SearchItem>
                            <ButtonPerssion code='operationcenter/salesman/xjywy'>
                                <XOKButton style={{ marginLeft: '20px' }} label='新建业务员' onClick={this._newHandle} />
                            </ButtonPerssion>
                        </SearchItem>
                    </SearchBox>
                    <TableContent renderData={stockBuyerInfoList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {
                        showEdit ? <EditModal param={param} close={(type) => {
                            if (type == 'update') {
                                this._searchHandle('useCache');
                            }
                            this.setState({ showEdit: false });
                        }} /> : null
                    }
                    {
                        showAudit ? <AuditModal param={param} close={(type) => {
                            if (type == 'update') {
                                this._searchHandle('useCache');
                            }
                            this.setState({ showAudit: false });
                        }} /> : null
                    }
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getOperationCenter: PropTypes.func, // 获取归属运营中心
    OCSelectList: PropTypes.array, // 归属运营中心下拉列
    getAreaCompany: PropTypes.func, // 获取归属区域公司
    RESelectList: PropTypes.array, // 归属区域公司下拉列
    getStockBuyerAuditTypeEnum: PropTypes.func, // 获取业务员状态
    stockBuyerAuditTypeEnum: PropTypes.array, // 业务员状态下拉列
    getStockBuyerInfoList: PropTypes.func, // 获取业务员列表
    stockBuyerInfoList: PropTypes.object, // 业务员列表
};

const mapStateToProps = (state) => ({
    OCSelectList: state.member.getOperationCenter,
    RESelectList: state.member.getAreaCompany,
    stockBuyerAuditTypeEnum: state.salesman.getStockBuyerAuditTypeEnum,
    stockBuyerInfoList: state.salesman.getStockBuyerInfoList,
});

export default connect(mapStateToProps, { ...member_model.actions, ...salesman_model.actions, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);