/**
 * 【账户明细】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getAccountList, getServiceList, getMerchantList, getRegionalCompanyList, getOperationCenterList, getPlatFormList } from '@/reducers/storehousefinancialmanage/bankAccount/actions';
import VipTable from './components/VipTable';
import ServiceTable from './components/ServiceTable';
import BusinessTable from './components/BusinessTable';
import AreaCompanyTable from './components/AreaCompanyTable';
import OperaCenterTable from './components/OperaCenterTable';
import PlatformTable from './components/PlatformTable';

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '会员' },
            { id: 2, label: '服务商' },
            { id: 3, label: '商户' },
            { id: 4, label: '区域公司' },
            { id: 5, label: '运营中心' },
            { id: 6, label: '平台' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
        companyStatus: {},
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        let bankAccountTabId = sessionStorage.getItem('bankAccountTabId');
        if (bankAccountTabId) {
            this.setState({ curTabID: +bankAccountTabId });
        }
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        sessionStorage.setItem('bankAccountTabId', tabID);
        this.setState({ curTabID: tabID });
    }
    _tableAction = (id, type, code, types) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (types) {
            case 'info':
                addOneBreadcrumbPath({
                    title: '商城订单明细',
                    path: `/home/storehousefinancialmanage/bankAccount/detail/${id}/${types}`
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/bankAccount/detail/${id}/${types}`,
                    search: `?type=${type}&&code=${code}`,
                });
                break;
            case 'orderInfo':
                addOneBreadcrumbPath({
                    title: '服务订单明细',
                    path: `/home/storehousefinancialmanage/bankAccount/detail/${id}/${types}`
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/bankAccount/detail/${id}/${types}`,
                    search: `?type=${type}&&code=${code}`,
                });
                break;
            case 'stockProfit':
                addOneBreadcrumbPath({
                    title: '股票溢价收益权',
                    path: `/home/storehousefinancialmanage/exchangemanage/2/${type}&${id}`
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/exchangemanage/2/${type}&${id}`
                });
                break;
            case 'marketamountincomelist':
                addOneBreadcrumbPath({
                    title: '特权值收支明细',
                    path: `/home/storehousefinancialmanage/marketingamountmanage/marketingincomelist/${type}/${id}`
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/marketingamountmanage/marketingincomelist/${type}/${id}`
                });
                break;
            case 'marketamountincomedetail':
                addOneBreadcrumbPath({
                    title: '特权值收支明细',
                    path: `/home/storehousefinancialmanage/marketingamountmanage/marketingincomedetail/${id}`
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/marketingamountmanage/marketingincomedetail/${id}`
                });
                break;
        }
    }
    render() {
        const { curTabID } = this.state;
        const { platFormList, operationCenterList, regionalCompanyList, merchantList, serviceList, accountList } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={`${styles.flexBoxContainer} ${styles.pages}`}>
                    {/* 会员 */}
                    {curTabID === 1 && <VipTable renderData={accountList} curTabID={curTabID} tableAction={this._tableAction} />}
                    {/* 服务商 */}
                    {curTabID === 2 && <ServiceTable renderData={serviceList} curTabID={curTabID} tableAction={this._tableAction} />}
                    {/* 商户 */}
                    {curTabID === 3 && <BusinessTable renderData={merchantList} curTabID={curTabID} tableAction={this._tableAction} />}
                    {/* 区域公司 */}
                    {curTabID === 4 && <AreaCompanyTable renderData={regionalCompanyList} curTabID={curTabID} tableAction={this._tableAction} />}
                    {/* 运营中心 */}
                    {curTabID === 5 && <OperaCenterTable renderData={operationCenterList} curTabID={curTabID} tableAction={this._tableAction} />}
                    {/* 平台 */}
                    {curTabID === 6 && <PlatformTable renderData={platFormList} curTabID={curTabID} tableAction={this._tableAction} />}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getAccountList: PropTypes.func,
    getServiceList: PropTypes.func,
    getMerchantList: PropTypes.func,
    getRegionalCompanyList: PropTypes.func,
    getOperationCenterList: PropTypes.func,
    getPlatFormList: PropTypes.func,
    platFormList: PropTypes.object,
    operationCenterList: PropTypes.object,
    regionalCompanyList: PropTypes.object,
    merchantList: PropTypes.object,
    serviceList: PropTypes.object,
    accountList: PropTypes.object,
};
const mapStateToProps = (state) => ({
    platFormList: state.bankAccount.platFormList, // 平台
    operationCenterList: state.bankAccount.operationCenterList,
    regionalCompanyList: state.bankAccount.regionalCompanyList,
    merchantList: state.bankAccount.merchantList,
    serviceList: state.bankAccount.serviceList,
    accountList: state.bankAccount.accountList,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getAccountList, getServiceList, getMerchantList, getRegionalCompanyList, getOperationCenterList, getPlatFormList })(Main);