import * as T from './actiontypes';
import { setLocalStorage, getLocalStorage } from '@/assets/js/storage';

/**
 * 存储用户验证信息
 */
const authInital = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')) : { userInfo: {}, token: '', loginName: '', loginPwd: '', keepPwd: false };

export const auth = (state = authInital, { type, payload }) => {
    let newState = null;
    switch (type) {
        case T.SET_AUTH:
            newState = { ...state, userInfo: payload.userInfo, token: payload.token, loginName: payload.loginName, loginPwd: payload.loginPwd, keepPwd: payload.keepPwd };
            setLocalStorage('auth', newState);
            return newState;
        case T.INIT_AUTH:
            newState = { ...state, userInfo: {}, token: '', loginName: '', loginPwd: '', keepPwd: false };
            setLocalStorage('auth', newState);
            return newState;
        default:
            return state;
    }
};