/**
 * 批量打款
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, XInput, XDatePicker } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import model from '@/reducers/storehousefinancialmanage/otherpay/model';
import KeepAlive from '@/routes/KeepAlive';
import TableContent from './components/TableContent';
import BatchPay from './components/BatchPay';
import UploadPayExcel from './components/UploadPayExcel';
import ButtonPerssion from '@/routes/ButtonPerssion';
import http from '@/assets/api/http';
import { message } from 'antd';

class Main extends React.Component {

    state = {
        batchNo: '', // 付款批次号
        gsItem: {}, // 签约公司
        tdItem: {}, // 签约通道
        beginRemitTime: undefined, // 开始时间
        endRemitTime: undefined, // 结束时间

        showBatchPay: false, // 显示密码支付
        showUploadPayExcel: false, // 显示上传文件
        param: null, // 删除、付款传递的参数
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getYouFuPayMerEnum();
        this.props.getYouFuPayOutServiceNameEnum();
    }

    // 字符串转数组
    _strToArr = (arg) => {
        let arr = [];
        let str = arg;
        if (Array.isArray(str)) {
            if (str.length > 0 && str[0]) {
                arr = str;
            }
        } else if (str) {
            str = str.replace('，', ',');
            arr = str.split(',');
        }
        return arr;
    }

    // 查询
    _searchHandle = (useCache) => {
        const { batchNo, gsItem, tdItem, beginRemitTime, endRemitTime, pageNum, pageSize } = this.state;
        const query = {
            batchNo,
            platCompanyId: gsItem.code,
            levyId: tdItem.code,
            beginRemitTime,
            endRemitTime,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };

        this.props.getQueryOutRemitBatchList(query);
        KeepAlive.saveCache({ batchNo, gsItem, tdItem, beginRemitTime, endRemitTime, pageNum, pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ batchNo: '', receiptPhone: '', receiptAccountNo: '', ztItem: {}, jyItem: {}, gsItem: {}, tdItem: {}, beginRemitTime: undefined, endRemitTime: undefined, pageNum: autoPageNum, pageSize: autopageSize }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (type, item) => {
        switch (type) {
            case 'batchNo':
            case 'failedStatusCount':
            case 'unsignedVerifyStatusCount':
            case 'maxAmountVerifyStatusCount':
            case 'successVerifyStatusCount':
                this.props.jumpToTab(type, item);
                break;
            case 'update':
                http('/youfuOut/refreshSign', { batchNo: item.batchNo }).then(() => {
                    this._searchHandle('useCache');
                    message.success('刷新成功');
                }).catch((error) => {
                    message.error(error.message);
                });
                break;
            case 'pay':
                this.setState({ showBatchPay: true, param: item });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    // 批量支付
    _showUploadPayExcelHandle = () => {
        this.setState({ showUploadPayExcel: true });
    }

    render() {
        const { batchNo, gsItem = {}, tdItem = {}, beginRemitTime, endRemitTime, param, showBatchPay, showUploadPayExcel } = this.state;
        const { youFuPayMerEnum, youFuPayOutServiceNameEnum, queryOutRemitBatchList } = this.props;
        return (
            <KeepAlive id='batch' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='付款批次号'
                                placeholder='请输入16位数字服务单号'
                                value={batchNo}
                                bindThis={this}
                                bindName='batchNo'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='付款公司'
                                showSearch
                                placeholder='请选择'
                                renderData={youFuPayMerEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={gsItem.value}
                                bindThis={this}
                                bindName='gsItem'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='付款通道'
                                showSearch
                                placeholder='请选择'
                                renderData={youFuPayOutServiceNameEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={tdItem.value}
                                bindThis={this}
                                bindName='tdItem'
                                isOptionBindID
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                placeholder='请选择开始时间'
                                label='打款日期'
                                value={beginRemitTime}
                                bindThis={this}
                                bindName='beginRemitTime'
                                isFormat={true}
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                placeholder='请选择结束时间'
                                value={endRemitTime}
                                bindThis={this}
                                bindName='endRemitTime'
                                isFormat={true}
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                        <SearchItem>
                            <ButtonPerssion code='storehousefinancialmanage/otherpay/scfk'>
                                <XOKButton style={{ marginLeft: '20px' }} label='上传打款文件' onClick={this._showUploadPayExcelHandle} />
                            </ButtonPerssion>
                        </SearchItem>
                    </SearchBox>
                    <TableContent renderData={queryOutRemitBatchList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {
                        showBatchPay ? <BatchPay param={param} close={(type) => {
                            if (type == 'update') {
                                this._searchHandle('useCache');
                            }
                            this.setState({ showBatchPay: false });
                        }} /> : null
                    }
                    {
                        showUploadPayExcel ? <UploadPayExcel close={(type) => {
                            if (type == 'update') {
                                this._searchHandle('useCache');
                            }
                            this.setState({ showUploadPayExcel: false });
                        }} /> : null
                    }
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getYouFuPayMerEnum: PropTypes.func, // 枚举：签约公司
    youFuPayMerEnum: PropTypes.array,
    getYouFuPayOutServiceNameEnum: PropTypes.func, // 枚举：签约通道
    youFuPayOutServiceNameEnum: PropTypes.array,
    getQueryOutRemitBatchList: PropTypes.func, // 列表：打款记录
    queryOutRemitBatchList: PropTypes.object,
    jumpToTab: PropTypes.func, // 跳转其它Tab
};

const mapStateToProps = (state) => ({
    queryOutRemitBatchList: state.otherpay.getQueryOutRemitBatchList,
    youFuPayMerEnum: state.otherpay.getYouFuPayMerEnum,
    youFuPayOutServiceNameEnum: state.otherpay.getYouFuPayOutServiceNameEnum,
});

export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);