import * as T from './actiontypes';

/**
 * 获取'商户列表列表'数据源
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {sting} merchantName
 * @param {sting} merchantState 
 */
export const merchantListTableSource = (dataload) => ({
    type: T.GET_MERCHANT_DATA,
    payload: {
        ...dataload,
    }
});

/**
 * 获取'商户详情与商户编辑'数据源
 * @param {number} merchantId  获取某个商户详情与商户编辑对应的id
 * @param {string} editor //是否在编辑页面
 * @param {string} detail //是否是详情页
 */
export const getmerchantDetails = (merchantId, status) => ({
    type: T.GET_STOREDETAIL_DATA,
    payload: {
        merchantId,
        status,
    }
});

export const getNetworkInfo = (merchantId) => ({
    type: T.GET_NETWORKINFO_DATA,
    payload: { merchantId }
});

/**
 * 获取'商品店铺'数据源
 * @param {number} merchantId  对应的id
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页",
 */
export const getStore = (dataload) => ({
    type: T.GET_STORE_DATA,
    payload: dataload
});

/**
 * 获取'商户列表下拉列数据源'数据源
 * @param 暂无
 * 
 */
export const getmerchantselectData = () => ({
    type: T.GET_MERCHANTSELECT_DATA,
});


/**
 * 获取'服务商 '数据源
 * @param operationsId 运营中心ID
 * @param areaCompanyId 区域公司
 * 
 */
export const getmerchantServicearea = (operationsId, areaCompanyId) => ({
    type: T.GET_MERCHANTSERVICE_DATA,
    payload: {
        operationsId,
        areaCompanyId,
    }
});

/**
 * 获取'区域公司'数据源
 * @param operationsId 运营中心ID
 * 
 */
export const getmerchantServiceCompany = (operationsId) => ({
    type: T.GET_MERCHANTSERVICECOMPANY_DATA,
    payload: {
        operationsId,
    }
});

/**
 * 获取'运营中心'数据源
 * @param 暂无
 * 
 */
export const getmerchantServicecenter = () => ({
    type: T.GET_MERCHANTSERVICECENTER_DATA,
});
/**

/**
 * 
 * 获取'新建商户商户类型'数据
 * @param 暂无
 * 
 */
export const getmerchantradius = () => ({
    type: T.GET_MERCHANTRADIUS_DATA,
});

/**
 * 获取'新建商户基础费用模式'数据源
 * @param 暂无
 * 
 */
export const getmerchantportion = () => ({
    type: T.GET_PROPTION_DATA,
});


/**新建商户
 * 获取区域：省市区详细信息
 * @param {*} value {id, type} 
 * id: 子级区域ID
 * type: 省province|市city|区street
 */
export const getListAreaByParentId = (value = {}) => ({
    type: T.GET_PROVINCE,
    payload: value
});


/**
 * 商户类型在实体加盟中运营中心、区域、服务商（不可以选）数据依据地址的市，区参数=》服务商
 * @param cityId 市级
 * @param areaId 区域级
 */
export const getradiotype = (dataload) => ({
    type: T.GET_RADIOTYPE_DATA,
    payload: dataload
});
