/**
 * 申请开票-开票信息 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import model from '@/reducers/storehousefinancialmanage/paybill/model';
import { Modal, Input, Alert, Form, Select, message, Table } from 'antd';
import styles from './index.module.scss';

const { TextArea } = Input;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
    // labelAlign: 'left',
};
class NoLogin extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
    }

    componentDidMount() {
        // 获取薪人开票提交的信息
        this.props.getApplyInvoiceInfo();
    }

    // 点击确认
    _handleOk = () => {
        const { closeMode } = this.props;
        let url = '/settle/payroller/applyInvoice';
        this.props.form.validateFields((errors, values) => {
            if (!errors) {
                this.setState({ confirmLoading: true });
                http(url, { ...values }, 'POST').then((res) => {
                    message.success(res.message);
                    closeMode(true);
                }).catch((res = {}) => {
                    message.error('开票失败，原因：' + res.message);
                }).finally(() => this.setState({ visible: false, confirmLoading: false }));
                this.setState({ visible: false });
            }
        });

    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { ApplyInvoiceInfo } = this.props;
        return (
            <Modal
                width={600}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon
                            htmlFor='type'
                            label={<span>​发票类型</span>}
                            style={{ textAlign: 'left', marginBottom: 0 }}
                        >
                            增值税专用发票
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='compayAddress'
                            label={<span>公司地址</span>}
                            style={{ textAlign: 'left', marginBottom: 0 }}
                        >
                            {getFieldDecorator('compayAddress', {
                                initialValue: ApplyInvoiceInfo.compayAddress,
                                rules: [{ required: true, message: '请输入公司地址' }],
                            })(
                                <Input placeholder="请输入" />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='telephone'
                            label={<span>电话</span>}
                            style={{ textAlign: 'left', marginBottom: 0 }}
                        >
                            {getFieldDecorator('telephone', {
                                initialValue: ApplyInvoiceInfo.telephone,
                                rules: [
                                    { required: true, message: '请输入电话' },
                                    {
                                        validator: (rule, value = '', callback) => {
                                            var RegExp = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/;
                                            if (value == '') {
                                                callback();
                                            } else if (RegExp.test(value) == false) {
                                                callback('请输入正确的固话（格式：区号+号码,如010-1234567）或正确的手机号码');
                                            } else {
                                                callback();
                                            }
                                        }
                                    }
                                ],
                            })(
                                <Input placeholder="请输入" />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='addresseeAddress'
                            label={<span>收件地址</span>}
                            style={{ textAlign: 'left', marginBottom: 0 }}
                        >
                            {getFieldDecorator('addresseeAddress', {
                                initialValue: ApplyInvoiceInfo.addresseeAddress,
                                rules: [{ required: true, message: '请输入收件地址' },
                                { max: 100, message: '长度100' }
                                ],
                            })(
                                <Input placeholder="请输入" />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='addressee'
                            label={<span>收件人</span>}
                            style={{ textAlign: 'left', marginBottom: 0 }}
                        >
                            {getFieldDecorator('addressee', {
                                initialValue: ApplyInvoiceInfo.addressee,
                                rules: [{ required: true, message: '请输入收件人' }],
                            })(
                                <Input placeholder="请输入" />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='addresseePhone'
                            label={<span>收件人手机</span>}
                            style={{ textAlign: 'left', marginBottom: 0 }}
                        >
                            {getFieldDecorator('addresseePhone', {
                                 initialValue: ApplyInvoiceInfo.addresseePhone,
                                rules: [{ required: true, message: '请输入收件人手机', whitespace: true },
                                {
                                    message: '联系电话格式错误',
                                    pattern: /(^1[3|4|5|7|8]\d{9}$)|(^09\d{8}$)/,
                                }],
                            })(
                                <Input placeholder="请输入" />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

NoLogin.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]), // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    getApplyInvoiceInfo: PropTypes.func,
    ApplyInvoiceInfo: PropTypes.object
};
const mapStateToProps = (state) => ({
    ApplyInvoiceInfo: state.paybill.ApplyInvoiceInfo
});
const ForgotProducDetails = Form.create({ name: 'create-form' })(NoLogin);// 校验
export default connect(mapStateToProps, {...model.actions})(ForgotProducDetails);