export class ImageLoad {
    /**
    LmageLoad 图片分辨率预览拉伸问题
    */
    constructor() { }

    /*
    * 获取图片真实尺寸以及容器尺寸
    * @param img Dom对象
    * @param naturalWidth/naturalHeight 真实图片原始宽高 必须onload之后，否则为0
    * @callback func
    */
    setImgSize(img, callback) {
        if (img.naturalWidth && img.naturalHeight) {//html5
            if (typeof callback === 'function') callback(img);
            else {
                return null;
            }
        } else { // IE 6 7 8
            let imgae = new Image();
            imgae.src = img.src;
            imgae.onload = () => {
                if (typeof callback === 'function') callback(img);
                else {
                    return null;
                }
            };
        }
    }

    /**
    判断浏览器及其版本
   */
    browserNavigator() {
        let u = navigator.userAgent, app = navigator.appVersion;
        // (navigator.browserLanguage || navigator.language).toLowerCase() //language
        return {
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1, //opera内核
            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,//火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, //是否iPad
            webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
            weixin: u.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
            qq: u.match(/\sQQ/i) == 'qq' //是否QQ
        };
    }
}