/**
 * 设置商品属性
 */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message, InputNumber, Typography, Checkbox, Select } from 'antd';
import model from '@/reducers/productmanage/wholesalegoods/model';
import { connect } from 'react-redux';

const { getChannelEnum } = model.actions;
const { Text } = Typography;
const limitEnum = [{ code: '0', value: '否' }, { code: '1', value: '是' }];

const SetGoods = ({
    closeMode,
    modalParams: { wholesaleGoodsId, onChannel = '', limitNumber, leftOnDay } = {},
    getChannelEnum,
    channelEnum
}) => {

    const [loading, setLoading] = useState(false);
    const [isLimit, setIsLimit] = useState(Number(limitNumber) ? '1' : '0');
    const [onChannels, setOnChannels] = useState(onChannel.split(','));
    const [limitNum, setLimitNum] = useState(Number(limitNumber) ? limitNumber : null);

    useEffect(() => {
        getChannelEnum();
    }, []);

    const onSubmit = () => {
        if (isLimit === '1' && !limitNum) return message.warn('请输入限购数量');
        setLoading(true);
        http('/goods/wholesale/update', { wholesaleGoodsId, limitNumber: (isLimit === '1' && limitNum != 0) ? limitNum : 0, onChannels }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    const boxStyle = {
        display: 'flex',
        alignItems: 'center',
        marginTop: '15px'
    };

    return <Modal
        width={380}
        centered
        title='商品属性设置'
        bodyStyle={{ paddingTop: '15px' }}
        visible={true}
        confirmLoading={loading}
        onOk={onSubmit}
        onCancel={() => closeMode(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div>
            <div style={boxStyle}>
                <div><Text>上架剩余时间：</Text></div>
                <div><Text>当前商品剩余上架{leftOnDay}天</Text></div>
            </div>
            <div style={boxStyle}>
                <div><Text>是否限购：</Text></div>
                <div>
                    <Select style={{ width: '60px' }} value={isLimit} onChange={value => setIsLimit(value)}>
                        {
                            limitEnum.map(i => {
                                return <Select.Option key={i.code} value={i.code}>{i.value}</Select.Option>;
                            })
                        }
                    </Select>
                    {
                        isLimit === '1' && <Fragment>
                            <InputNumber
                                style={{ width: '140px', margin: '0 10px' }}
                                placeholder='请输入限购数量'
                                value={limitNum}
                                min={0}
                                max={999}
                                precision={0}
                                onChange={value => setLimitNum(value)} />
                            <Text>件</Text>
                        </Fragment>
                    }</div>
            </div>
            <div style={boxStyle}>
                <div><Text>发布端口：</Text></div>
                <div>
                    <Checkbox.Group value={onChannels} onChange={list => setOnChannels(list)}>
                        {
                            channelEnum.map(i => {
                                return <Checkbox key={i.code} value={i.code}>{i.value}</Checkbox>;
                            })
                        }
                    </Checkbox.Group>
                </div>
            </div>
        </div>
    </Modal>;
};
SetGoods.propTypes = {
    closeMode: PropTypes.func,
    modalParams: PropTypes.object,
    getChannelEnum: PropTypes.func,
    channelEnum: PropTypes.array,
};
export default connect(state => ({
    channelEnum: state.wholesaleGoods.channelEnum,
}), { getChannelEnum })(SetGoods);