import * as Audit from './actiontypes';

//获取社区审核列表
export const getCommunityAuditList = (dataload) => ({
    type: Audit.GET_COMMUNITYAUDIT_LIST,
    payload: dataload
});

//获取社区审核列表详情
export const getCommunityAuditDetail = ({ accountId, feedId, showType }) => ({
    type: Audit.GET_COMMUNITYAUDIT_DETAIL,
    payload: {
        accountId, feedId, showType
    }
});