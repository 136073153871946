/**
 *  商户管理 - 酒店商户
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XSelect, XOKButton, XCancelButton, } from '@/components/xqxc_ui';
import HotelTable from './components/TableData';
import merchantsHotelModel from '@/reducers/businessmanage/merchantsHotel/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            saleState: {}, // 状态
            servicecenter: {}, // 运营中心
            servicearea: {}, // 区域公司
            service: {}, // 服务商
            saleNames: '', // 商户名称,
            merchantId: '',//停业商户ID
            loginName: '',//商户账号
        };
    }

    componentDidMount() {
        const { getMerchantState, } = this.props;
        getMerchantState();
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getMerchantsHotelList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 查询触发  
    _saleSearchHandle = (useCache) => {
        const { saleNames, saleState, pageSize, pageNum, servicecenter, servicearea, service, loginName } = this.state;
        this.searchCache = {
            loginName,
            merchantName: saleNames,//商户名称  
            merchantState: saleState.code,//状态  
            // operationCenterId: servicecenter.code,//运营中心  
            // areaCompanyId: servicearea.code,//区域公司  
            // serviceId: service.code,//服务商  
            merchantType: 'HOTEL',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getMerchantsHotelList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _saleresetHandle = () => {
        this.setState({ saleState: {}, servicecenter: {}, servicearea: {}, service: {}, saleNames: '', loginName: '' }, () => {
            KeepAlive.saveResetFunc(this._saleSearchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建按钮被点击
    _newCreateHandle = () => {
        this.props.updateContent('NEWCREATE');
    }

    // 表格操作功能回调
    _tableAction = (id, type, params) => {
        const { updateContent, } = this.props;
        switch (type) {
            case 'MER_EDITOR'://编辑
                updateContent('MER_EDITOR', id);
                break;
            case 'MER_STOR'://店铺
                updateContent('MER_STOR', id);
                break;
            case 'MER_DETAIL'://查看详情
                updateContent('MER_DETAIL', id);
                break;
            case 'MEMBER'://会员列表
                updateContent('MEMBER', id, params);
                break;
            case 'EARNING'://收益明细
                updateContent('EARNING', id, params);
                break;
        }

    }

    render() {
        const { merchantsHotelList, merchantState } = this.props;
        const { saleState, saleNames, loginName } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._saleSearchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商户账号'
                                placeholder='请输入商户账号'
                                bindName='loginName'
                                bindThis={this}
                                value={loginName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='商户名称'
                                placeholder='请输入商户名称'
                                value={saleNames}
                                bindThis={this}
                                bindName='saleNames'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={merchantState}
                                dataIndex='value'
                                keyIndex='code'
                                value={saleState.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='saleState'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._saleSearchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._saleresetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    {/* <XSelect
                            style={{ marginLeft: '20px', }}
                            selectStyle={{ width: '150px' }}
                            label='运营中心'
                            placeholder='请选择'
                            renderData={[]}
                            dataIndex='value'
                            keyIndex='code'
                            value={servicecenter.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='servicecenter'
                        />
                        <XSelect
                            style={{ marginLeft: '20px', }}
                            selectStyle={{ width: '150px' }}
                            label='区域公司'
                            placeholder='请选择'
                            renderData={[]}
                            dataIndex='value'
                            keyIndex='code'
                            value={servicearea.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='servicearea'
                        />
                        <XSelect
                            style={{ marginLeft: '20px', }}
                            selectStyle={{ width: '150px' }}
                            label='服务商'
                            placeholder='请选择'
                            renderData={[]}
                            dataIndex='value'
                            keyIndex='code'
                            value={service.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='service'
                        /> */}
                        {/* 任务2492 */}
                    {/* <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }} label='创建酒店商户' onClick={this._newCreateHandle} />
                    </div> */}
                    <HotelTable renderData={merchantsHotelList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    merchantListTableSource: PropTypes.func, // 通过Saga异步请求表格数据源的Action
    getmerchantselectData: PropTypes.func,// 通过Saga异步请求下拉选项数据方法Action
    tableSource: PropTypes.array,

    paginations: PropTypes.object,
    storeListTable: PropTypes.object,//店铺数据和停业公用
    getStore: PropTypes.func, // 店铺数据和停业公用数据源的Action

    getMerchantsHotelList: PropTypes.func,//酒店商户列表action
    merchantsHotelList: PropTypes.object,//酒店商户列表
    merchantState: PropTypes.array,
    getMerchantState: PropTypes.func,//状态Action
};

const mapStateToProps = (state) => {
    return {
        // tableSource: state.merchantlist.merchantDate.tableSource, // 表格数据源
        // selectData: state.merchantlist.merchantDate.SelectData, // 状态下拉选项
        // paginations: state.merchantlist.merchantDate.paginations, // 分页数据
        merchantState: state.merchantsHotel.merchantState, // 状态
        merchantsHotelList: state.merchantsHotel.merchantsHotelList, // 酒店商户列表
    };
};


export default connect(mapStateToProps, { ...merchantsHotelModel.actions })(Main);