/**
 *  人气值商城设置
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';


class Starmarketing extends React.Component {
    
    render() {
        return (
            <Main />
        );
    }
}

Starmarketing.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Starmarketing);