import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import arrayMove from 'array-move';
import { Tabs, Card, Avatar, Icon, Tag, Tooltip, Popconfirm, message } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import http from '@/assets/api/http';
import PreviewModal from '@/vcomps/CustomPreview';
import { tryJSONParse } from '@/assets/js/utils';
import './index.module.scss';
import PopconfirmModal from '../../modal/PopconfirmModal';

const categories = [{ name: '全部', code: '' }, { name: '基础组件', code: '1' }, { name: '营销组件', code: '2' }];

const DragHandle = SortableHandle(() => <Tooltip title="点击长按拖拽">排序</Tooltip>);

const SortableItem = SortableElement(({ component, onDeleteComponent, onPreview }) => (
    <Card
        className="appfitting-component"
        key={component.id}
        style={{ width: 287 }}
        hoverable
        actions={[
            <DragHandle key='drag' />,
            <span key="preview" onClick={() => onPreview(component)}>预览</span>,
            // <Popconfirm key='del' title="是否删除该组件？" okText='是' cancelText='否' onConfirm={() => onDeleteComponent(component.id)}>删除</Popconfirm>
            <div key='del' onClick={() => onDeleteComponent(component)}>删除</div>
        ]}
    >
        <Card.Meta
            avatar={<Avatar src={component.componentIcon} />}
            title={component.componentName || '暂无名称'}
            description={<div className="component-title">{component.componentDesc || '暂无描述'}</div>}
        />
    </Card>
));

const SortableList = SortableContainer(({ components, onAddComponent, onDeleteComponent, onPreview }) => {
    return (
        <div className="appfitting-components">
            <Card className="appfitting-component" hoverable onClick={onAddComponent}>
                <div><Icon type='plus' /></div>
                <div>新增组件</div>
            </Card>
            {components.map((component, index) => (
                <SortableItem key={component.id + ''} index={index} component={component} onDeleteComponent={onDeleteComponent} onPreview={onPreview} />
            ))}
        </div>
    );
});

class Main extends React.Component {

    state = {
        tabKey: 'SHOP',
        components: [],
        componentKey: '',
        componentType: '',
        previewVisible: false,
        currentOpenMode: '',
        popconParam: null,
    }

    componentDidMount() {
        this.getComponentList('');
        const { componentLocation } = (this.props.history.location.state || {});
        componentLocation && this.setState({ tabKey: componentLocation });
    }

    onTabChange = (tabKey) => {
        this.setState({ tabKey }, () => {
            this.getComponentList('');
        });
    }

    onAddComponent = () => {
        this.props.addOneBreadcrumbPath({
            title: '自定义组件管理',
            path: '/home/systemmanage/appfitting/add'
        });
        this.props.history.push({
            pathname: '/home/systemmanage/appfitting/add',
            state: { componentLocation: this.state.tabKey, platType: 'MERCHANT' }
        });
    }

    getComponentList = (componentType) => {
        this.setState({ componentType });
        http('/custom/userAppComponents/getList', {
            componentLocation: this.state.tabKey,
            componentType
        }).then(({ result = [] } = {}) => {
            this.setState({ components: result });
        }).catch(() => {
            message.error('获取数据异常');
            this.setState({ components: [] });
        });
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { components } = this.state;
        http('/custom/userAppComponents/update', { id: components[oldIndex].id, sort: newIndex + 1 });
        this.setState({ components: arrayMove(components, oldIndex, newIndex) });
    };

    onDeleteComponent = (componentData) => {
        this.setState({
            currentOpenMode: 'popconfirm',
            popconParam: { componentId: componentData.componentId, category: this.state.tabKey, id: componentData.id }
        });
    }

    onPreview = (component) => {
        const Key = tryJSONParse(component.componentConfigParams, { Key: '' }).Key;
        this.setState({ previewVisible: true, componentKey: Key });
    }

    // 打开弹窗  
    _renderMode = () => {
        if (this.state.currentOpenMode == 'popconfirm') {
            return <PopconfirmModal data={this.state.popconParam} visible={true} closeMode={this._closeMode} />;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) this.getComponentList(this.state.componentType);
    }

    render() {
        const { tabKey, components, componentKey, componentType, previewVisible } = this.state;

        return (
            <>
                <Tabs activeKey={tabKey} onChange={this.onTabChange}>
                    <Tabs.TabPane tab="店铺组件" key="SHOP"></Tabs.TabPane>
                    <Tabs.TabPane tab="专题组件" key="SUBJECT_MT"></Tabs.TabPane>

                </Tabs>
                <div className="condition">
                    <div className="label">所属类目：</div>
                    <div className="content customfitting-tags">
                        {categories.map((cate) => <Tag.CheckableTag checked={componentType === cate.code} onChange={() => this.getComponentList(cate.code)} key={cate.code}>{cate.name}</Tag.CheckableTag>)}
                    </div>
                </div>
                <SortableList useDragHandle axis='xy' helperClass="appfitting-drag-source" components={components} onSortEnd={this.onSortEnd} onAddComponent={this.onAddComponent} onDeleteComponent={this.onDeleteComponent} onPreview={this.onPreview} />
                <PreviewModal
                    key={componentKey}
                    category='ComponentPreview'
                    componentKey={componentKey}
                    modalProps={{ visible: previewVisible, onCancel: () => this.setState({ previewVisible: false }) }}
                />
                {this._renderMode()}
            </>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func // 保持两级面包屑导航
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);