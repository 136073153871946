import * as T from './actiontypes';

/**
 * 获取部门列表
 */
const deptListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const getDeptList = (state = deptListInital, action) => {
    switch (action.type) {
        case T.SET_DEPT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取部门详情
 */
export const getDeptDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_DEPT_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取公司、运营中心列表
 */
const companyListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const getCompanyList = (state = companyListInital, action) => {
    switch (action.type) {
        case T.SET_COMPANY_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取公司、运营中心详情
 */
export const getCompanyDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_COMPANY_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};
