import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const getReceiveRecordList = function* () {
    yield takeEvery(T.GET_RECEIVE_RECORD_LIST, function* requestData(action) {
        try {
            let result = yield http('/hotel/requirement/listRecord', action.payload, 'POST');
            yield put({ type: T.SET_RECEIVE_RECORD_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getReceiveRecordDetail = function* () {
    yield takeEvery(T.GET_RECEIVE_RECORD_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/hotel/requirement/recordDetail', action.payload, 'POST');
            yield put({ type: T.SET_RECEIVE_RECORD_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getWaitReceiveList = function* () {
    yield takeEvery(T.GET_WAIT_RECEIVE_LIST, function* requestData(action) {
        try {
            let result = yield http('/hotel/requirement/list', action.payload, 'POST');
            yield put({ type: T.SET_WAIT_RECEIVE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getWaitReceiveDetail = function* () {
    yield takeEvery(T.GET_WAIT_RECEIVE_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/hotel/requirement/detail', action.payload, 'POST');
            yield put({ type: T.SET_WAIT_RECEIVE_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getReceiveRecordState = function* () {
    yield takeEvery(T.GET_RECEIVE_RECORD_STATE, function* requestData(action) {
        try {
            let result = yield http('/enum/HotelRequirementStateEnum', action.payload, 'POST');
            yield put({ type: T.SET_RECEIVE_RECORD_STATE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};