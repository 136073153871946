/**
 * 选择商品分类弹框 
 * */
import React from 'react';
import { Modal, Button, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './ProductClassifyModal.module.scss';
import { GoodsCategory } from '@/vcomps';

class ProductClassifyModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
            prodCascade: '', // 商品联级分类
            prodCascadeDetail: [], // 商品联级分类详细信息
        };
    }

    // 确认
    _handleOk = () => {
        const { prodCascadeDetail } = this.state;
        const trueList = prodCascadeDetail.filter((item) => {
            return item.id == '' || item.id == undefined || item.id == null;
        });
        if (prodCascadeDetail.length == 0 || trueList.length > 0) {
            message.warning('需选择三级分类');
            return;
        }
        this.setState({
            visible: false,
        });
        this.props.closeMode('ok', this.state.prodCascadeDetail);
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode('cancel');
    }

    // 分类搜索
    _onCascadeSearch = (value) => {
        console.log(value);
        this.setState({ prodCascade: value });
    }

    render() {
        const { visible, confirmLoading } = this.state;

        return (
            <Modal
                width={706}
                centered
                title='商品分类'
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                footer={[
                    <Button key="back" type="primary" onClick={this._handleOk}>
                        确认
                    </Button>
                ]}
            >
                <div className={styles.container}>
                    <div className={styles.title}>请选择商品的所属分类：</div>
                    <GoodsCategory
                        style={{ marginTop: '0px' }}
                        categoryType={'SERVICE'}
                        onChange={(value) => this.setState({ prodCascade: value })}
                        onGetItems={(value) => this.setState({ prodCascadeDetail: value })}
                    />
                </div>
            </Modal>
        );
    }
}

ProductClassifyModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    closeMode: PropTypes.func.isRequired,
};

export default ProductClassifyModal;