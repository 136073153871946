/**
 *  审核管理 - 视频审查
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class CommodityReview extends React.Component {

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    render() {
        return (
            <Main />
        );
    }
}

CommodityReview.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(CommodityReview);