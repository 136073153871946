import * as C from './actiontypes';

// 评论列表
export const getCommentList = (param) => ({
    type: C.GET_COMMENT_LIST,
    payload: param
});
// 评论详情
export const getCommentDetail = (param) => ({
    type: C.GET_COMMENT_DETAIL,
    payload: param
});
// 商品分类
export const getProductClassify = (param) => ({
    type: C.GET_PRODUCT_CLASSIFY,
    payload: param
});
