/**
 * 星球商学院 Saga集合
 */
import facilitymanage from './facilitymanage/model';
import homestaylayout from './homestaylayout/model';
import starlevelmanage from './starlevelmanage/model';
import cancelreasons from './cancelreasons/model';


export default [
    ...Object.values(facilitymanage.sagas),
    ...Object.values(homestaylayout.sagas),
    ...Object.values(starlevelmanage.sagas),
    ...Object.values(cancelreasons.sagas)
];
