import React, { Component } from 'react';
import { Table, Tag } from 'antd';
import PropTypes from 'prop-types';
import styles from './FightTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth, tooltipClick, priceFenToYuanCell } from '@/components/TableCell';

const rowSpanStyle = () => {
    return {
        style: {
            // maxWidth: 100,
            // overflow: 'hidden',
            // whiteSpace: 'nowrap',
            // textOverflow: 'ellipsis',
            // background: 'aliceblue'
        }
    };
};

export default class Records extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    _columns = () => [
        {
            title: '活动副标题',
            dataIndex: 'activitySubtitle',
            key: 'activitySubtitle',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员手机号',
            dataIndex: 'userTel',
            key: 'userTel',
            width: cellWidth.normal,
            align: 'center',
            render: (text, record) => this.tooltipTagHandle(text, record)
        },
        {
            title: '会员昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, record) => this.tooltipNoHandle(text, record)
        },
        {
            title: '抢拍类型',
            dataIndex: 'qpType.value',
            key: 'qpType.value',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '抢拍基数',
            dataIndex: 'userNum',
            key: 'userNum',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '成团时间',
            dataIndex: 'sysDrawTime',
            key: 'sysDrawTime',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, record) => tooltipClick(text, () => {
                if (text == '') return;
                this.props.tableAction('detail', record);
            })
        },
        {
            title: '红包金额(元)',
            dataIndex: 'redPocket',
            key: 'redPocket',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '抢拍状态',
            dataIndex: 'state.value',
            key: 'state.value',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: 'SKU',
            dataIndex: 'skuSpec',
            key: 'skuSpec',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    // 解析表格数据，使符合columns格式要求
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                return item;
            });
        }
    }

    //会员中拍
    tooltipTagHandle = (text, record) => {
        let { userTel, winnerTel, } = record;
        if (typeof (userTel) !== 'string') return;
        let list = userTel.split(',').map((item, index) => {
            return (<div key={index} className={styles.laycard}>
                {item == winnerTel && <Tag className={styles.curt} color="#1890ff">中</Tag>}
                <div>{tooltip(item)}</div>
            </div>);
        });
        return list || '';
    }

    //会员昵称
    tooltipNoHandle = (text, record) => {
        let { nickname } = record;
        if (typeof (nickname) !== 'string') return;
        let list = nickname.split(',').map((item, index) => {
            return (<div key={index} className={styles.laycard}>
                <div>{tooltip(item)}</div>
            </div>);
        });
        return list || '';
    }

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

Records.propTypes = {
    renderData: PropTypes.object, // 表格数据源
    paginationChange: PropTypes.func, // 分页
    tableAction: PropTypes.func, // 对表格的操作
};