/**
 * 星球攻略列表
 */
import React from 'react';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import DeleteModal from '../../modal/DeleteModal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message } from 'antd';
import { getstrategyList, getstrategyType, getstrategyAppState } from '@/reducers/xqstrategy/classification/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import ButtonPerssion from '@/routes/ButtonPerssion';

const Mode = {
    EDITE: 'EDITE',//删除
    EDITOR: 'EDITOR',//编辑
    DATAIL: 'DATAIL',//详情
};

class AtmosphereGift extends React.Component {

    state = {
        type: {},//攻略类别
        typeName: '',//攻略名称
        strategystate: {},//攻略类型
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
        aPort: {},// 应用端口
        belongSystem: 'DAILY_TALK'
    }

    componentDidMount() {
        const { getstrategyType, getstrategyAppState } = this.props;
        getstrategyType();//攻略类型
        getstrategyAppState();//应用端口
    }

    // 分类状态下拉列改变时触发
    _typeChangeHandle = (selectedItem) => {
        this.setState({ type: selectedItem });
    }

    // 分类名称输出框改变时触发
    _typeNameInputChangeHandle = (value) => {
        this.setState({ typeName: value });
    }

    // 新建攻略
    _createStudioRoom = () => {
        this.props.updateContent('CREAT');
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, typeName, type, strategystate, aPort, belongSystem } = this.state;
        const { getstrategyList, } = this.props;
        this.searchCache = {
            title: typeName,//攻略名称  
            strategyClassId: type.id,//攻略类别  
            strategyType: strategystate.code,//攻略类型
            useScene: aPort.code,//应用端口
            belongSystem,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        getstrategyList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ type: {}, typeName: '', strategystate: {}, aPort: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格操作
    _tableAction = (item, tabletype, value) => {
        const { getstrategyList, } = this.props;
        switch (tabletype) {
            case 'editor':
                this.props.updateContent(Mode.EDITOR, item);
                break;
            case 'detail':
                this.props.updateContent(Mode.DATAIL, item);
                break;
            case 'delete':
                showConfirm('您是否要删除该商品', '', () => {
                    http('/show/strategy/deleteStrategyInfo', { id: item }, 'POST').then((response) => {
                        if (response.status == 200) {
                            message.success('删除成功');
                            getstrategyList(this.searchCache);//列表数据
                        }
                    }).catch((res) => {
                        if (res.code == '500') {
                            message.warn(res.message);
                        }
                    });
                }, () => { });
                break;
            case 'isReco':
                http('/show/strategy/updateStrategyReco', {//推荐
                    id: item.id,
                    isReco: (value === false) ? 0 : 1,
                }, 'POST').then(() => {
                    message.success('推荐修改成功');
                    getstrategyList(this.searchCache);//列表数据
                }).catch((reject = {}) => {
                    message.error(reject.message);
                });
                break;
            case 'state':
                http('/show/strategy/updateStrategyState', {//状态
                    id: item.id,
                    state: (value === false) ? 0 : 1,
                }, 'POST').then(() => {
                    message.success('状态修改成功');
                    getstrategyList(this.searchCache);//列表数据
                }).catch((reject = {}) => {
                    message.error(reject.message);
                });
                break;
        }
    }

    // 打开弹窗（删除）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.DELETE:
                return <DeleteModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }
    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getstrategyList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { type, typeName, strategystate, aPort } = this.state;
        const { selectDataSource, strategyPaginations, strategyTable, strategyTypeSelect, strategyAppState } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='攻略名称'
                                placeholder='请输入攻略名称'
                                onChange={this._typeNameInputChangeHandle}
                                value={typeName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='攻略类别'
                                placeholder='请选择攻略类别'
                                renderData={selectDataSource}
                                onChange={this._typeChangeHandle}
                                dataIndex='label'
                                keyIndex='id'
                                value={type.label}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='攻略类型'
                                placeholder='请选择攻略类型'
                                renderData={strategyTypeSelect.filter(i => i.code !== 'HELP_CENTE')}
                                dataIndex='value'
                                keyIndex='code'
                                value={strategystate.value}
                                bindThis={this}
                                bindName='strategystate'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='应用端口'
                                placeholder='请选择'
                                renderData={strategyAppState.filter(i => i.code !== 'SUPPLIER_PC')}
                                dataIndex='value'
                                keyIndex='code'
                                value={aPort.value}
                                bindThis={this}
                                bindName='aPort'
                                showSearch
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    {
                        <ButtonPerssion code='xqstrategy/list/xqgllbxzgl'>
                            <div className={styles.operate_box}>
                                <XOKButton style={{ width: 'auto' }} label='新建攻略' onClick={this._createStudioRoom} />
                            </div>
                        </ButtonPerssion>
                    }
                    <TableContent renderData={strategyTable} tableAction={this._tableAction} paginationChange={this._paginationChange} paginations={strategyPaginations} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

AtmosphereGift.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 路由跳转
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    strategyTable: PropTypes.array, // 表格数据源
    // strategyId: PropTypes.number,//tabs切换对应的id
    selectDataSource: PropTypes.array, // 攻略分类下拉选项
    strategyPaginations: PropTypes.object, // 分页数据
    getstrategyList: PropTypes.func, // 通过Saga异步请求表格数据源的Action
    getstrategyType: PropTypes.func,//攻略类型
    strategyTypeSelect: PropTypes.array,

    strategyAppState: PropTypes.array, // 人气值攻略列表新建应用端口
    getstrategyAppState: PropTypes.func, // 人气值攻略列表新建应用端口
};

const mapStateToProps = (state) => {
    return {
        strategyTable: state.classification.classificationDate.strategyTable,
        strategyPaginations: state.classification.classificationDate.strategyPaginations,
        strategyTypeSelect: state.classification.getstrategyType,
        strategyAppState: state.classification.classificationDate.strategyAppState,
    };
};
export default connect(mapStateToProps, { getstrategyList, getstrategyType, getstrategyAppState, })(AtmosphereGift);
