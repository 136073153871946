import React from 'react';
import styles from './OrderTable.module.scss';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table, Tooltip, Popover } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip, cellWidth, tooltipClick } from '@/components/TableCell';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { isPlat } from '@/assets/js/authType';

class OrderTable extends React.Component {

    _franchiseCell = (t, r) => {
        if (r.totalIntegralNumber) {
            return <Tooltip
                title={<div>
                    <p>会员：{r.integralNumber}</p>
                    {r.serviceProviderRole === 'SERVICE_PROVIDER' && <p>服务商：{r.serviceIntegralNumber}</p>}
                    {r.serviceProviderRole === 'TALKER' && <p>说客：{r.talkerIntegralNumber}</p>}
                </div>}>
                <span className={styles.text}>{t}</span>
            </Tooltip>;
        }
        return '-';

    }

    _takeColumns = () => ([
        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: 'right', display: 'flex' }}>
                        {record.merchantSponsorFlag == 1 ? <img src={require('@/assets/image/drainage.png')} style={{ verticalAlign: 'top' }} /> : <div style={{ width: '16px' }}></div>}
                        {['待付款', '已付款', '部分发货', '已发货', '已收货', '已完成', '已取消'].includes(record.status) || record.consumeType !== '同城实体店'
                            ? tooltipClick(text, () => this.props.tableAction(record.id, 'info', record))
                            : <span>{text}</span>
                        }
                    </div>
                );
            }
        },
        {
            title: '会员账号',
            dataIndex: 'accountTel',
            key: 'accountTel',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单金额(元)',
            dataIndex: 'payFee',
            key: 'payFee',
            width: cellWidth.small,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '人气值',
            dataIndex: 'starValue',
            key: 'starValue',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '赠送信用值',
            dataIndex: 'xiumiNumber',
            key: 'xiumiNumber',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '税费金额(元)',
            dataIndex: 'taxation',
            key: 'taxation',
            width: cellWidth.small,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '归属商户',
            dataIndex: 'sellerName',
            key: 'sellerName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '赠送特权值',
            dataIndex: 'totalIntegralNumber',
            key: 'totalIntegralNumber',
            width: cellWidth.normal,
            align: 'center',
            // onCell: tooltipStyle,
            render: this._renderOrderIntegralRoleResps
        },
        {
            title: '支付通道',
            dataIndex: 'payAccessType',
            key: 'payAccessType',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付方式',
            dataIndex: 'payType',
            key: 'payType',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单来源',
            dataIndex: 'sourceChannel',
            key: 'sourceChannel',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单状态',
            dataIndex: 'status',
            key: 'status',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单类型',
            dataIndex: 'orderType',
            key: 'orderType',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '消费类型',
            dataIndex: 'consumeType',
            key: 'consumeType',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '下单时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否开票',
            dataIndex: 'isInvoice',
            key: 'isInvoice',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否批发',
            dataIndex: 'isDistribution',
            key: 'isDistribution',
            width: cellWidth.small,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付流水号',
            dataIndex: 'payItemId',
            key: 'payItemId',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '完成时间',
            dataIndex: 'finishedTime',
            key: 'finishedTime',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'orderId',
            key: 'id',
            align: 'center',
            width: 260,
            fixed: 'right',
            render: (text, record) => this._renderActionCell(text, record)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderOrderIntegralRoleResps = (text, item) => {

        return (
            <Popover trigger="hover" destroyTooltipOnHide content={
                <div>
                    {item.orderIntegralRoleResps.map(v => <div key={v.roleName}>{v.roleName}：{v.integralNumber}</div>)}
                </div>
            }>
                <a>{text || '-'}</a>
            </Popover>

        );
    }

    _renderActionCell = (key, record) => {
        const { tableAction } = this.props;
        // ****注意
        // 待付款：查看详情 操作历史 取消
        // 已付款（待发货）：查看详情 操作历史 发货 取消
        // 部分发货：查看详情 操作历史 查看物流 取消
        // 已发货(待收货)：查看详情 操作历史 查看物流 取消
        // 已收货：查看详情 操作历史 查看物流 取消
        // 已完成：查看详情 操作历史 查看物流
        // 已取消：查看详情 操作历史

        switch (record.status) {
            case '待付款':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        <ButtonPerssion code='ordermanage/productorder/mjly'>
                            <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                        </ButtonPerssion>
                    </div>
                );
            case '已付款':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        {isPlat() ? <div className={styles.item} onClick={() => tableAction(key, 'cost', record)}>费用明细</div> : null}
                        <ButtonPerssion code='ordermanage/productorder/mjly'>
                            <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                        </ButtonPerssion>
                        <ButtonPerssion code='ordermanage/productorder/td'>
                            <div className={styles.item} onClick={() => tableAction(record.orderId, 'chargeback', record.autoRefundTime)}>退单</div>
                        </ButtonPerssion>
                    </div>
                );
            case '部分发货':
                return (
                    <div className={styles.action}>
                        {record.consumeType !== '同城实体店' && <div className={styles.item} onClick={() => tableAction(key, 'logistics', record)}>查看物流</div>}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        {isPlat() ? <div className={styles.item} onClick={() => tableAction(key, 'cost', record)}>费用明细</div> : null}
                        <ButtonPerssion code='ordermanage/productorder/mjly'>
                            <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                        </ButtonPerssion>
                    </div>
                );
            case '已发货':
                return (
                    <div className={styles.action}>
                        {record.consumeType !== '同城实体店' && <div className={styles.item} onClick={() => tableAction(key, 'logistics', record)}>查看物流</div>}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        {isPlat() ? <div className={styles.item} onClick={() => tableAction(key, 'cost', record)}>费用明细</div> : null}
                        <ButtonPerssion code='ordermanage/productorder/mjly'>
                            <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                        </ButtonPerssion>
                    </div>
                );
            case '已收货':
                return (
                    <div className={styles.action}>
                        {record.consumeType !== '同城实体店' && <div className={styles.item} onClick={() => tableAction(key, 'logistics', record)}>查看物流</div>}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        {isPlat() ? <div className={styles.item} onClick={() => tableAction(key, 'cost', record)}>费用明细</div> : null}
                        <ButtonPerssion code='ordermanage/productorder/mjly'>
                            <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                        </ButtonPerssion>
                    </div>
                );
            case '已完成':
                return (
                    <div className={styles.action}>
                        {record.consumeType !== '同城实体店' && <div className={styles.item} onClick={() => tableAction(key, 'logistics', record)}>查看物流</div>}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        {isPlat() ? <div className={styles.item} onClick={() => tableAction(key, 'cost', record)}>费用明细</div> : null}
                        <ButtonPerssion code='ordermanage/productorder/mjly'>
                            <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                        </ButtonPerssion>
                    </div>
                );
            case '已取消':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        <ButtonPerssion code='ordermanage/productorder/mjly'>
                            <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                        </ButtonPerssion>
                    </div>
                );
            default:
                return (
                    <div className={styles.action}>
                        {isPlat() ? <div className={styles.item} onClick={() => tableAction(key, 'cost', record)}>费用明细</div> : null}
                        <ButtonPerssion code='ordermanage/productorder/mjly'>
                            <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                        </ButtonPerssion>
                    </div>
                );
        }
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
        }
    };

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={record => record.orderId}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string
};
export default XTableHOC(OrderTable);

//   onCell: () => {
//     return {
//       style: {
//         maxWidth: 0,
//         overflow: 'hidden',
//         whiteSpace: 'nowrap',
//         textOverflow:'ellipsis',
//         cursor:'pointer'
//       }
//     };
//   },