import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, Tooltip, message } from 'antd';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell, ImgCell, cellWidth } from '@/components/TableCell';
import ButtonPerssion from '@/routes/ButtonPerssion';

class TypeTable extends Component {

    state = {
        selectedRowKeys: [],
    };

    //props校验
    static propTypes = {
        renderData: PropTypes.object.isRequired, // 渲染数据源(双向绑定)
        tableAction: PropTypes.func.isRequired, // 对表格的操作
        paginationChange: PropTypes.func.isRequired,//分页
        scrollY: PropTypes.string
    };
    //表头
    _columns = () => [
        {
            title: '商品图片',
            dataIndex: 'skuImg',
            key: 'skuImg',
            align: 'center',
            width: cellWidth.normal,
            render: ImgCell
        },
        {
            title: '商品货号',
            dataIndex: 'itemNo',
            key: 'itemNo',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动名称',
            dataIndex: 'activityTitle',
            key: 'activityTitle',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动标签',
            dataIndex: 'activityTypeValue',
            key: 'activityTypeValue',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所属店铺',
            dataIndex: 'shopName',
            key: 'shopName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '销售价格（元）',
            dataIndex: 'retailPrice',
            key: 'retailPrice',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '活动价格（元）',
            dataIndex: 'goodsPrice',
            key: 'goodsPrice',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '技术服务费（%）',
            dataIndex: 'serviceFee',
            key: 'serviceFee',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => this._rateCell(text)
        },
        {
            title: '平台服务费（%）',
            dataIndex: 'distributionFee',
            key: 'distributionFee',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => this._rateCell(text)
        },
        {
            title: '活动总库存',
            dataIndex: 'stock',
            key: 'stock',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '平台库存',
            dataIndex: 'platStock',
            key: 'platStock',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '服务商库存',
            dataIndex: 'sellerStock',
            key: 'sellerStock',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品状态',
            dataIndex: 'stateValue',
            key: 'stateValue',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '审核意见',
            dataIndex: 'opinion',
            key: 'opinion',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'activityGoodsId',
            key: 'activityGoodsId',
            align: 'center',
            fixed: 'right',
            width: cellWidth.normal,
            render: (text, record) => this._tableAction(text, record)
        }
    ];
    //文字超出
    _tableTipCell = (text) => {
        return <Tooltip placement='topLeft' title={text}>{text}</Tooltip>;
    }

    //费率装换
    _rateCell = (text) => {
        return text / 10;
    }
    //操作表格 
    _tableAction = (key, record) => {
        const { tableAction } = this.props;
        return (<Fragment>
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
                {record.state == 'FAIL' && <div style={{ width: '53px', cursor: 'default' }}></div>}
                {/* {record.state == 'PASS' && <div style={{ width: '48px' }} className={styles.item} onClick={() => tableAction(key, 'inventory', record)}>调整库存</div>} */}
                {
                    record.state == 'PENDING' &&
                    <ButtonPerssion code='auditmanage/activityProductAudit/firstAudit'>
                        <div style={{ width: '48px' }} className={styles.item} onClick={() => tableAction(key, 'audit', record)}>初审</div>
                    </ButtonPerssion>
                }
                {
                    record.state == 'FIRST_PASS' && <ButtonPerssion code='auditmanage/activityProductAudit/secondAudit'>
                        <div style={{ width: '48px' }} className={styles.item} onClick={() => tableAction(key, 'audit', record)}>复审</div>
                    </ButtonPerssion>
                }
            </div>
        </Fragment>
        );
    };
    // 解析表格数据
    _takeDataSource = (data) => {
        return data;
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            let goodsIds = [];
            for (let i = 0; i < selectedRows.length; i++) {
                goodsIds.push(selectedRows[i].itemNo);
            }
            goodsIds = [...new Set(goodsIds)];
            if (goodsIds.length > 1) {
                message.warning('只能勾选同一商品');
            } else {
                let rows = selectedRows.filter(v => v.state == 'PENDING');
                this.setState({ selectedRowKeys: rows.map(v => v.activityGoodsId) });
                this.props.tableAction(null, 'batch', rows);
            }
        },
        getCheckboxProps: record => {
            const verify = ['INTEGRAL'].includes(record.activityType);
            return { disabled: verify || record.state === 'PASS' };
        },
    };
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
        this.props.tableAction(null, 'batch', []);
    }

    render() {
        const { renderData, paginationChange } = this.props;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={record => record.activityGoodsId}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(renderData.dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: this.props.scrollY }}
                />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}
export default XTableHOC(TypeTable, 40);