import { createModel } from '@/store/tools';

const model = {
    namespace: 'servicegrade',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getServicegradeList': T('getServicegradeList'),
            'getOperationCenters': T('getOperationCenters'),
            'getAreaCompanys': T('getAreaCompanys'),
            'getServicerNames': T('getServicerNames'),
        },
        actions: {
            'getServicegradeList': A('getServicegradeList'),
            'getOperationCenters': A('getOperationCenters'),
            'getAreaCompanys': A('getAreaCompanys'),
            'getServicerNames': A('getServicerNames'),
            'cleanAreaCompanys': C('getAreaCompanys'),
            'cleanServicerNames': C('getServicerNames'),
        },
        sagas: {
            'getServicegradeList': S('getServicegradeList', '/boss/xiuBeiPerformanceStatistics/list'),
            'getOperationCenters': S('getOperationCenters', '/admin/company/listOperationsIdAndName'),
            'getAreaCompanys': S('getAreaCompanys', '/admin/company/listAreaCompanyIdAndName'),
            'getServicerNames': S('getServicerNames', '/service/provider/listServiceProviderIdAndName'),
        },
        reducers: {
            'servicegradeList': R('getServicegradeList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'operationCenters': R('getOperationCenters', []),
            'areaCompanys': R('getAreaCompanys', []),
            'servicerNames': R('getServicerNames', []),
        }
    })
};
export default createModel(model);