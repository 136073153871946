import React from 'react';
import styles from './TableData.module.scss';
import { tooltipStyle, tooltip } from '@/components/TableCell';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => ([
    {
        title: 'APP类型',
        dataIndex: 'appType',
        key: 'appType',
        width: '8%',
        align: 'center',
        render: (text) => (ObjectCell(text))
    },
    {
        title: 'APP名称',
        dataIndex: 'appName',
        key: 'appName',
        width: '8%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '系统类型',
        dataIndex: 'systemType',
        key: 'systemType',
        width: '8%',
        align: 'center',
        render: (text) => (ObjectCell(text))
    },
    {
        title: '版本号',
        dataIndex: 'newVersion',
        key: 'newVersion',
        width: '8%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '最小支持版本号',
        dataIndex: 'minVersion',
        key: 'minVersion',
        width: '8%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: 'APK下载地址',
        dataIndex: 'apkUrl',
        key: 'apkUrl',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '更新备注',
        dataIndex: 'updateDescription',
        key: 'updateDescription',
        width: '8%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '更新类型',
        dataIndex: 'updateMode',
        key: 'updateMode',
        width: '8%',
        align: 'center',
        render: (text) => (ObjectCell(text))
    },
    {
        title: '热更新key',
        dataIndex: 'hotUpdateKey',
        key: 'hotUpdateKey',
        width: '8%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '修改人',
        dataIndex: 'modifyUserName',
        key: 'modifyUserName',
        width: '8%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '修改时间',
        dataIndex: 'gmtModified',
        key: 'gmtModified',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '8%',
        render: (id, record) => TableAction(id, record, actionClickHandle)
    },
]);


const ObjectCell = (text) => {
    return text.value;
};
export const takeDataSource = (data) => {
    return data;
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};

const TableAction = (id, record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'editor', record)}>编辑</div>
            <div className={record.state.code == '0' ? styles.item : styles.hide} onClick={() => actionClickHandle(id, 'start', record)}>启用</div>
            <div className={record.state.code == '1' ? styles.item : styles.hide} onClick={() => actionClickHandle(id, 'ban', record)}>禁用</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'delete', record)}>删除</div>
        </div>
    );
};