import * as A from './actiontypes';

// 获取计费分配比例表格
export const getAllocationList = (param) => ({
    type: A.GET_ALLOCATION_LIST,
    payload: param,
});
// 获取计费分配比例详情
export const getAllocationDetail = (param) => ({
    type: A.GET_ALLOCATION_DETAIL,
    payload: param,
});
// 获取设置项
export const getSettingCode = (param) => ({
    type: A.GET_SETTING_CODE,
    payload: param,
});
