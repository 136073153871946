import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { message, List, Switch, InputNumber, Button } from 'antd';
import http from '@/assets/api/http';
import BigNumber from 'bignumber.js';

const Channel = () => {

    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => { getData(); }, []);

    const getData = () => {
        http('/admin/param/listAdminParam/SUPPLIER', {}, 'POST').then(res => {
            const list = res.result.map(i => {
                if (i.paramValue)
                    if (i.paramCode === 'SUPPLIER_CREDIT_SYSTEM_BALANCE_VALUE') {
                        if (i.paramValue != 0) return {
                            ...i,
                            checked: true,
                            title: '是否开启渠道信用额度',
                            remark: '设置用户成为正式渠道后，平台给予的信用额度初始值',
                            paramValue: new BigNumber(i.paramValue || 0).dividedBy(100).toNumber()
                        };
                        return {
                            ...i,
                            checked: false,
                            title: '是否开启渠道信用额度',
                            remark: '设置用户成为正式渠道后，平台给予的信用额度初始值',
                            paramValue: null
                        };
                    }
                return i;
            });
            setTableData(list);
        });
    };

    const onSubmit = () => {
        setLoading(true);
        http('/admin/param/batchUpdateParam', tableData.map(i => {
            return { ...i, paramValue: new BigNumber(i.paramValue || 0).multipliedBy(100).toNumber() };
        }), 'POST').then(res => {
            if (res.status == 200) {
                setLoading(false);
                getData();
                message.success('保存成功');
            }
        }).catch(err => {
            setLoading(false);
            message.error(err.message);
        });
    };

    return <div className={styles.container}>
        <div className={styles.inner}>
            <List
                bordered
                dataSource={tableData}
                renderItem={i => (
                    <List.Item style={{ padding: '10px' }}>
                        <div className={styles.innerItem}>
                            <div className={styles.innerTitle}>{i.title}</div>
                            <div className={styles.innerRemark}>{i.remark}</div>
                            <div className={styles.innerValue}>
                                <Switch onChange={checked => {
                                    setTableData(tableData.map(j => {
                                        if (i.paramCode === j.paramCode) {
                                            if (checked) return { ...j, checked, paramValue: null };
                                            return { ...j, checked, paramValue: 0 };
                                        }
                                        return j;
                                    }));
                                }} checkedChildren='开' unCheckedChildren='关' checked={i.checked} />
                                {i.checked && <InputNumber style={{ width: '150px' }}
                                    value={i.paramValue}
                                    min={0} max={99999.99}
                                    precision={2} placeholder='请输入初始金额' onChange={paramValue => {
                                        setTableData(tableData.map(j => {
                                            if (i.paramCode === j.paramCode) return { ...j, paramValue };
                                        }));
                                    }} />}
                            </div>
                        </div>
                    </List.Item>
                )}
            />
        </div>
        <div className={styles.footerBox}>
            <Button loading={loading} type='primary' onClick={onSubmit}>保存</Button>
        </div>
    </div>;
};
export default Channel;