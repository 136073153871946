import * as MESSAGE from './actionTypes';

//获取消息分类列表
export const getMessageClassifyList = (param) => ({
    type: MESSAGE.GET_MESSAGECLASSIFY_LIST,
    payload: param
});
//获取消息分类详情
export const getMessageClassifyDetail = (param) => ({
    type: MESSAGE.GET_MESSAGECLASSIFY_DETAIL,
    payload: param
});
//获取消息分类下拉
export const getMessageClassifyLists = (param) => ({
    type: MESSAGE.GET_MESSAGECLASSIFY_LISTS,
    payload: param
});
//获取推送端口
export const getMessagePushPort = (param) => ({
    type: MESSAGE.GET_MESSAGEPUSHPORT,
    payload: param
});