import { createModel, } from '@/store/tools';

const model = {
    namespace: 'contracttemplate',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getSelectContractList': T('getSelectContractList'),// 模板列表
            'getContractName': T('getContractName'),// 模板名称
            'getMerchantInfoListOfFadadaAsPage': T('getMerchantInfoListOfFadadaAsPage'),// 已签署商户列表
            'getFadadaAsPageStatus': T('getFadadaAsPageStatus'),// 状态
        },
        actions: {
            'getSelectContractList': A('getSelectContractList'), // 模板列表
            'getContractName': A('getContractName'), // 模板名称
            'getMerchantInfoListOfFadadaAsPage': A('getMerchantInfoListOfFadadaAsPage'), // 已签署商户列表
            'getFadadaAsPageStatus': A('getFadadaAsPageStatus'), // 状态
        },
        sagas: {
            'getSelectContractList': S('getSelectContractList', '/contract/fadada/selectContractList'),
            'getContractName': S('getContractName', '/contract/fadada/downList'),
            'getMerchantInfoListOfFadadaAsPage': S('getMerchantInfoListOfFadadaAsPage', '/contract/fadada/merchantInfoListOfFadadaAsPage'),
            'getFadadaAsPageStatus': S('getFadadaAsPageStatus', '/enum/MerchantStateEnum'),
        },
        reducers: {
            'SelectContractList': R('getSelectContractList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'ContractName': R('getContractName', []),
            'MerchantInfoListOfFadadaAsPage': R('getMerchantInfoListOfFadadaAsPage', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'FadadaAsPageStatus': R('getFadadaAsPageStatus', []),
        }
    })
};
export default createModel(model);