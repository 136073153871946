/**
 * 表情包组件
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popover, Button } from 'antd';
import styles from './index.module.scss';
import { expressionIMData } from './imdata';//表情包数据

const ExpressionIM = ({
    getExpressionIM,
    optionalNode,
    typeNode,
    ...props
}) => {

    const [faceText, setFaceText] = useState('');//对应的转码文本数据
    const [count, setCount] = useState(0);//计数

    //表情内容数据
    const content = () => {
        return (
            <div className={styles.imCurtContent}>
                {expressionIMData.map(item =>
                    <div key={item.face_id} className={styles.imImgs}>
                        <img date-imtext={item.face_name} onClick={() => iMHandleClick(item.face_name)} src={require(`@/assets/image/imImages/${item.img}`)}></img>
                    </div>
                )}
            </div>
        );
    };

    //获取表情内容对应的转码文本数据-[faceText]，导致不能连续选择同一个表情
    useEffect(() => {
        getExpressionIM(faceText);
    }, [count]);

    //转码文本数据: 栗子如果是多选是，拼接字符文本（这边不需要）
    const iMHandleClick = (text) => {
        setFaceText(text);
        setCount(count + 1);
    };

    //渲染关联节点，默认为button,或者自定义-optionalNode
    const renderNode = () => {
        const buttonNode = (<Button type='primary' icon='smile'>表情</Button>);//默认
        return (typeNode ? optionalNode : buttonNode);
    };

    return (
        <div className={styles.container}>
            <Popover
                autoAdjustOverflow={true}
                getPopupContainer={triggerNode => triggerNode.parentElement}
                content={content()}
                {...props}>
                {renderNode()}
            </Popover>
        </div>
    );
};

ExpressionIM.defaultProps = {
    optionalNode: null,
    typeNode: false,
};

ExpressionIM.propTypes = {
    getExpressionIM: PropTypes.func,//获取表情内容对应的转码文本数据
    optionalNode: PropTypes.node,//PropTypes.element 是否可为子节点
    typeNode: PropTypes.bool,//是否自定义tag标签
};


export default ExpressionIM;
