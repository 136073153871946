import React from 'react';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './OrderTable.module.scss';

class OrderTable extends React.Component {

    _takeColumns = () => ([
        {
            title: '商户账户',
            dataIndex: 'loginName',
            key: 'loginName',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户名称',
            dataIndex: 'subjectName',
            key: 'subjectName',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户类型',
            dataIndex: 'merchantType.value',
            key: 'merchantType.value',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '预收款余额',
            dataIndex: 'balance',
            key: 'balance',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '总充值金额',
            dataIndex: 'totalBalance',
            key: 'totalBalance',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '总支出金额',
            dataIndex: 'withdrawedBalance',
            key: 'withdrawedBalance',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: this._renderActionCell
        }
    ]);

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'detail', item)}>明细</div>
            </div>
        );
    }

    _rowSelection = {

    };

    render() {
        const { paginationChange, modalParams, setModalParams } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={{
                        selectedRowKeys: modalParams.map(i => i.id),
                        onChange: (keys, rows) => setModalParams(rows)
                    }}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    setModalParams: PropTypes.func,
    modalParams: PropTypes.array
};

export default OrderTable;
