/**
 * 报表管理 Saga集合
 */
import template from './template/model';
import records from './records/model';

export default [
    ...Object.values(template.sagas),
    ...Object.values(records.sagas),
];
