/**
 * 会员解禁弹框
 */
import React from 'react';
import { Modal, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './ReleaseProhibitModal.module.scss';
import { XDatePicker } from '@/components/xqxc_ui';
import moment from 'moment';
import http from '@/assets/api/http';
import { message } from 'antd';

const { TextArea } = Input;
class ReleaseProhibitModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        prohibitEndTime: moment(),
        endPickerDisabled: false,
        areaValue: ''
    }

    // 确认
    _handleOk = () => {
        const { prohibitEndTime, areaValue } = this.state;
        const { id, startTime } = this.props.data;
        if (moment(prohibitEndTime).valueOf() < moment(startTime).valueOf()) {
            message.warning('解禁时间不能小于封禁时间!');
            return;
        }

        let endTime = null;
        if (prohibitEndTime) {
            endTime = moment(moment(prohibitEndTime).format()).format('YYYY-MM-DD HH:mm:ss');
        }
        // 解封请求
        this.setState({ confirmLoading: true });
        http('/show/accountBlack/deAccountBlack', {
            id,
            expectedEndTime: endTime,
            openBlackReason: areaValue
        }, 'POST').then((response) => {
            if (response.status == 200) {
                this.setState({ visible: false, confirmLoading: false });
                this.props.closeMode();
                message.success('解禁修改成功！');
            } else {
                this.setState({ confirmLoading: false });
                message.error('请求失败！');
            }
        }).catch(() => {
            this.setState({ confirmLoading: false });
            message.error('请求失败！');
        });
    }

    // 取消
    _handleCancel = () => {
        this.setState({ visible: false, });
        this.props.closeMode();
    }

    // 解禁说明
    _areaChangeHandle = (e) => {
        this.setState({ areaValue: e.target.value });
    }

    // 封禁结束时间改变时触发
    _prohibitEndTimeChangeHandle = (time) => {
        var timeformat = moment(moment(time).format()).format('YYYY-MM-DD HH:mm:ss');
        console.log(timeformat); // 数据库要的时间格式
        this.setState({ prohibitEndTime: time });
    }

    render() {
        const { visible, confirmLoading, areaValue, prohibitEndTime, endPickerDisabled } = this.state;

        return (
            <Modal
                width={470}
                centered
                title='解禁'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.up}>
                        <XDatePicker
                            label='解禁时间'
                            onChange={this._prohibitEndTimeChangeHandle}
                            value={prohibitEndTime}
                            disabled={endPickerDisabled}
                            isRequired={true}
                            disabledDate={(current) => {
                                const { startTime } = this.props.data;
                                return current && current < moment(startTime);
                            }}
                        />
                    </div>
                    <div className={styles.down}>
                        <div className={styles.downlabel}>解禁说明<span style={{ color: 'red' }}>*</span></div>
                        <TextArea
                            className={styles.textArea}
                            rows={4}
                            onChange={this._areaChangeHandle}
                            value={areaValue}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

ReleaseProhibitModal.propTypes = {
    data: PropTypes.object, // 弹窗传递参数
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default ReleaseProhibitModal;