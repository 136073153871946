//异步获取数据
export const GET_PLACEMENT_LIST = 'ADVERT/GET_PLACEMENT_LIST'; 
export const GET_PLACEMENT_ADD = 'ADVERT/GET_PLACEMENT_ADD'; 
export const GET_PLACEMENT_EDIT = 'ADVERT/GET_PLACEMENT_EDIT'; 
export const GET_PLACEMENT_DETAIL = 'ADVERT/GET_PLACEMENT_DETAIL';
export const GET_PLACEMENT_DELETE = 'ADVERT/GET_PLACEMENT_DELETE';
export const GET_PLACEMENT_STARTANDSTOP = 'ADVERT/GET_PLACEMENT_STARTANDSTOP';  
export const GET_PLACEMENT_AUDIT = 'ADVERT/GET_PLACEMENT_AUDIT';
export const GET_PLACEMENT_ADVERTLIST = 'ADVERT/GET_PLACEMENT_ADVERTLIST'; // 广告界面下拉列表
export const GET_PLACEMENT_ADVERTSTRATEGYLIST = 'ADVERT/GET_PLACEMENT_ADVERTSTRATEGYLIST'; // 广告策略下拉列表
export const GET_PLACEMENT_ADVERTSTATUS = 'ADVERT/GET_PLACEMENT_ADVERTSTATUS'; // 广告投放状态下拉

// //保存数据到store.state
export const SET_PLACEMENT_LIST = 'ADVERT/SET_PLACEMENT_LIST'; 
export const SET_PLACEMENT_ADD = 'ADVERT/SET_PLACEMENT_ADD'; 
export const SET_PLACEMENT_EDIT = 'ADVERT/SET_PLACEMENT_EDIT'; 
export const SET_PLACEMENT_DETAIL = 'ADVERT/SET_PLACEMENT_DETAIL';
export const SET_PLACEMENT_DELETE = 'ADVERT/SET_PLACEMENT_DELETE';
export const SET_PLACEMENT_STARTANDSTOP = 'ADVERT/SET_PLACEMENT_STARTANDSTOP';  
export const SET_PLACEMENT_AUDIT = 'ADVERT/SET_PLACEMENT_AUDIT';
export const SET_PLACEMENT_ADVERTLIST = 'ADVERT/SET_PLACEMENT_ADVERTLIST'; // 广告界面下拉列表
export const SET_PLACEMENT_ADVERTSTRATEGYLIST = 'ADVERT/SET_PLACEMENT_ADVERTSTRATEGYLIST'; // 广告策略下拉列表
export const SET_PLACEMENT_ADVERTSTATUS = 'ADVERT/SET_PLACEMENT_ADVERTSTATUS'; // 广告投放状态下拉