import React, { Component } from 'react';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import TableContent from './components/TableContent';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XOKButton, XCancelButton, XInput, XInputNum } from '@/components/xqxc_ui';
import model from '@/reducers/storehousefinancialmanage/datacenter/model';
import { SearchBox, SearchItem } from '@/components/Layout';
import { DatePicker } from 'antd';
import moment from 'moment';
import Licenses from './modal/Licenses';

const { MonthPicker } = DatePicker;
const monthFormat = 'YYYY-MM';

class TabContent extends Component {

    static propTypes = {
        history: PropTypes.object,
        keepSecondNavBreadcrumb: PropTypes.func,
        getDataFromTab7: PropTypes.func,
        dataFromTab7: PropTypes.object
    }

    static defaultProps = {
        dataFromTab7: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
    }

    state = {
        tel: '',
        serviceProviderName: '',
        months: '',
        renderModal: '',
        modalParams: ''
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { tel, serviceProviderName, months, pageNum, pageSize } = this.state;
        const monthList = months ? months.split('-') : [];
        const query = {
            tel, serviceProviderName,
            gmtRegistration: monthList.length ? `${monthList[0]}${monthList[1]}` : '',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getDataFromTab7(query);
        KeepAlive.saveCache(query);
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ tel: '', serviceProviderName: '', months: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    //表格操作
    _tableAction = (item, type) => {
        if (type === 'licenses') {
            this.setState({ renderModal: 'licenses', modalParams: item.agreementUrl }); // 修改区域弹框
        }
    }


    // 渲染弹框
    _renderMode = () => {
        const { renderModal, modalParams } = this.state;
        switch (renderModal) {
            case 'licenses':
                return <Licenses data={modalParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ renderModal: '', modalParams: '' });
    }

    render() {
        const { tel, serviceProviderName, months } = this.state;
        return (
            <KeepAlive id='dataCenterTab7' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInputNum
                                placeholder='请输入账号'
                                label='账号'
                                bindThis={this}
                                bindName='tel'
                                value={tel}
                            />
                            <XInput
                                placeholder='请输入昵称'
                                label='服务商名称'
                                bindThis={this}
                                bindName='serviceProviderName'
                                value={serviceProviderName}
                            />
                            <div className={styles.searchBox}>
                                <div className={styles.label}>注册时间：</div>
                                <MonthPicker
                                    value={months ? moment(months, monthFormat) : null}
                                    format={monthFormat}
                                    allowClear={false}
                                    onChange={(_, dateString) => this.setState({ months: dateString })}
                                    placeholder='请选择注册时间' />
                            </div>
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent
                        renderData={this.props.dataFromTab7}
                        paginationChange={this._paginationChange}
                        tableAction={this._tableAction}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

export default connect(state => ({ ...state.dataCenter }), { keepSecondNavBreadcrumb, ...model.actions })(TabContent);