import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table, InputNumber, message } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [],
    };

    static getDerivedStateFromProps(props, state) {
        if (props.selectedRowKeys !== state.selectedRowKeys) {
            return {
                selectedRowKeys: props.selectedRowKeys
            };
        }
        return null;
    }

    _columns = () => [
        {
            title: '商户编号',
            dataIndex: 'merchantNo',
            key: 'merchantNo',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户类型',
            dataIndex: 'merchantTypeValue',
            key: 'merchantTypeValue',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户地址',
            dataIndex: 'addrDetail',
            key: 'addrDetail',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'isMarketingValue',
            key: 'isMarketingValue',
            width: '5%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '最小营销服务费(%)',
            dataIndex: 'marketingRate',
            key: 'marketingRate',
            width: '14%',
            align: 'center',
            render: (marketingRate, item) => (this.InputNumCell(marketingRate, item))
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作人',
            dataIndex: 'loginUserName',
            key: 'loginUserName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: '15%',
            render: (id, item) => this._TableAction(item)
        },
    ];

    InputNumCell = (marketingRate, item) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <InputNumber
                    defaultValue={marketingRate}
                    min={0.1}
                    max={100}
                    precision={1}
                    onBlur={(e) => {
                        const value = e.target.value;
                        if (value == '') {
                            message.warning('请输入最小营销服务费');
                        } else {
                            const newItem = { ...item, marketingRate: value };
                            this.props.tableAction('rate', newItem);
                        }
                    }}
                    onPressEnter={(e) => {
                        const value = e.target.value;
                        if (value == '') {
                            message.warning('请输入最小营销服务费');
                        } else {
                            const newItem = { ...item, marketingRate: value };
                            this.props.tableAction('rate', newItem);
                        }
                    }}
                />
            </div>
        );
    };

    _takeDataSource = (data) => {
        if (data) {
            return data.map((item) => {
                item.merchantTypeValue = item.merchantType && item.merchantType.value ? item.merchantType.value : '';
                item.isMarketingValue = item.isMarketing && item.isMarketing.value ? item.isMarketing.value : '';
                item.isMarketCode = item.isMarketing && item.isMarketing.code ? item.isMarketing.code : '';
                return item;
            });
        }
        return [];
    }

    _resolveAreaName = (areaName) => {
        if (areaName) {
            return areaName;
        } else {
            return '';
        }
    }

    _MoneyCell = (text) => {
        const money = regFenToYuan(parseInt(text));
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {money}
            </div>
        );
    };

    _TableAction = (item) => {
        const { tableAction } = this.props;
        // const isableText = item.isMarketCode == 0 ? '启用' : '禁用';
        return (
            <div className={styles.action}>
                {/* <div className={styles.item} onClick={() => tableAction('isable', item)}>{isableText}</div> */}
                <div className={styles.item} onClick={() => tableAction('give', item)}>赠送信用值</div>
                <div className={styles.item} onClick={() => tableAction('delete', item)}>删除</div>
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.setState({ selectedRowKeys });
                this.props.selectedRowKeysChange(selectedRowKeys);
            }
        };
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] }, () => {
            this.props.selectedRowKeysChange([]);
        });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={this.state.selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection()}
                    rowKey='merchantId'
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    selectedRowKeys: PropTypes.array,
    selectedRowKeysChange: PropTypes.func
};

export default TableContent;

