import { createModel } from '@/store/tools';

const initDataList = { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 };

const model = {
    namespace: 'suppliesset',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getHotWordTableData': T('getHotWordTableData'),
            'getSearchTypeEnum': T('getSearchTypeEnum'),
            'getClassSetTableData': T('getClassSetTableData'),
            'getAnnouncementData': T('getAnnouncementData'),
        },
        actions: {
            'getHotWordTableData': A('getHotWordTableData'),
            'getSearchTypeEnum': A('getSearchTypeEnum'),
            'getClassSetTableData': A('getClassSetTableData'),
            'getAnnouncementData': A('getAnnouncementData'),
        },
        sagas: {
            'getHotWordTableData': S('getHotWordTableData', '/supplier/config/topSearch/list'),
            'getSearchTypeEnum': S('getSearchTypeEnum', '/hotel/hotCity/searchCity'),
            'getClassSetTableData': S('getClassSetTableData', '/supplier/config/category/list'),
            'getAnnouncementData': S('getAnnouncementData', '/supplier/config/notice/list'),
        },
        reducers: {
            'hotWordTableData': R('getHotWordTableData', initDataList),
            'searchTypeEnum': R('getSearchTypeEnum', []),
            'classSetTableData': R('getClassSetTableData', initDataList),
            'announcementData': R('getAnnouncementData', initDataList),
        }
    })
};
export default createModel(model);