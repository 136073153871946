import React from 'react';
import PropTypes from 'prop-types';
import { Upload, Button, Icon, Modal, Progress } from 'antd';
import { callFn } from '@/assets/js/utils';
import { getAudioDuration } from './_utils';
import styles from './index.module.scss';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export default class CustomUpload extends React.Component {
    state = {
        previewVisible: false,
        previewImage: '',
        file: '',
        fileList: [],
        imageUrl: '',
        inited: false
    };

    constructor(props) {
        super(props);
        if (this.props.fileList) {
            this.state.fileList = this.props.fileList;
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.antdUploadProps.defaultFileList && props.antdUploadProps.defaultFileList.length > 0 && !state.inited) {
            return { fileList: props.antdUploadProps.defaultFileList, inited: true };
        }
        return null;
    }

    componentDidMount() {
        const { antdUploadProps = {} } = this.props;
        const { defaultFileList = [], showUploadFileList } = antdUploadProps;
        let state = {};
        if (showUploadFileList !== false) state = { fileList: defaultFileList };
        if (!showUploadFileList && defaultFileList.length > 0) {
            state['imageUrl'] = defaultFileList[0].url;
        }
        this.setState(state);
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = ({ file, fileList }) => {
        const { maxFileList, removeOverMax } = this.props;
        if (removeOverMax && fileList.length > maxFileList) {
            fileList = fileList.slice(1);
        }
        this.setState({ fileList }, () => {
            callFn(this.props.handleChange, file, fileList);
        });
    };

    changeSingleImg = (imageUrl) => {
        if (imageUrl === '') {
            let file = { status: 'removed' };
            this.handleChange({ file, fileList: [file] });
        }
        this.setState({ imageUrl, fileList: [] });
    }

    uploadAction = file => {
        new Promise((resolve) => {
            this.props.upload(file, resolve);
        }).then(({ realUrl, info }) => {
            if (this.props.uploadButton === 'singleImg') this.setState({ imageUrl: realUrl });
            file.url = realUrl;
            file.qiniuInfo = info;
            // 判断是否需要获取 音频时长
            if (this.props.hasAudioDuration && (file.type.startsWith('audio') || file.type.startsWith('video'))) {
                getAudioDuration(file).then((duration) => {
                    file.duration = duration;
                    callFn(this.props.handleChange, file, this.state.fileList);
                });
            } else {
                callFn(this.props.handleChange, file, this.state.fileList);
            }
        });
        return false;
    }

    // 是否开启预览
    renderPreviewModal = () => {
        const { antdUploadProps = {} } = this.props;
        if (antdUploadProps.listType === 'picture-card') {
            return (
                <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>);
        }
        return null;
    }

    // 选人上传按钮
    renderUploadButton = () => {
        const { uploadButton, maxFileList, removeOverMax } = this.props;
        const { defaultFileList = [] } = this.props.antdUploadProps;

        //初始化问题排查
        let imageUrl = this.state.imageUrl;
        if (!imageUrl && defaultFileList.length) {
            imageUrl = defaultFileList[0].url;
        }

        let buttonFn = {
            picture: () => <div><Icon type="plus" style={{ fontSize: 25 }} />{this.props.children}</div>,
            button: () => <Button><Icon type="upload" />上传</Button>,
            singleImg: () =>
                imageUrl ?
                    <div className={styles.singleImg}>
                        {/* <Icon type='close' onClick={(e) => { this.changeSingleImg(''); e.stopPropagation(); }} /> */}
                        <img src={imageUrl} />
                    </div> : buttonFn.picture()
        };
        let button = buttonFn[uploadButton || 'button']();
        if (maxFileList && this.state.fileList.length >= maxFileList && !removeOverMax) button = null;
        return button;
    }

    render() {
        return (
            <div className={styles.XUploadContainer}>
                {
                    [0, 100].includes(this.props.total.percent) ?
                        <Upload
                            {...this.props.antdUploadProps}
                            fileList={this.state.fileList}
                            onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            beforeUpload={this.uploadAction}
                        >
                            {this.renderUploadButton()}
                        </Upload> :
                        <Progress percent={this.props.total.percent} status={this.props.uploadState} size="small" style={{ minWidth: 88 }} />}
                {this.renderPreviewModal()}
            </div>
        );
    }
}

CustomUpload.propTypes = {
    uploadState: PropTypes.bool,
    total: PropTypes.object,
    hasAudioDuration: PropTypes.bool,
    upload: PropTypes.func,
    antdUploadProps: PropTypes.object,
    uploadButton: PropTypes.any,
    maxFileList: PropTypes.number,
    removeOverMax: PropTypes.bool,
    handleChange: PropTypes.func,
    fileList: PropTypes.array,
    children: PropTypes.object
};