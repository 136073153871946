export const GET_INVOICELIST = 'INVOICELIST/GET_INVOICELIST';
export const SET_INVOICELIST = 'INVOICELIST/SET_INVOICELIST';

// export const GET_DEALING_UNIT_SELECTSOURCE = 'PLATFORMRECONCILIATION/GET_DEALING_UNIT_SELECTSOURCE';
// export const SET_DEALING_UNIT_SELECTSOURCE = 'PLATFORMRECONCILIATION/SET_DEALING_UNIT_SELECTSOURCE';

export const GET_PLATFORMRECONCILIATION_TABLESOURCE = 'PLATFORMRECONCILIATION/GET_PLATFORMRECONCILIATION_TABLESOURCE';
export const SET_PLATFORMRECONCILIATION_TABLESOURCE = 'PLATFORMRECONCILIATION/SET_PLATFORMRECONCILIATION_TABLESOURCE';

export const GET_STATE_SELECTSOURCE = 'PLATFORMRECONCILIATION/GET_STATE_SELECTSOURCE';
export const SET_STATE_SELECTSOURCE = 'PLATFORMRECONCILIATION/SET_STATE_SELECTSOURCE';

// 详情相关
export const GET_DETAIL = 'PLATFORMRECONCILIATION/GET_DETAIL';
export const SET_DETAIL = 'PLATFORMRECONCILIATION/SET_DETAIL';

export const GET_DETAIL_TABLESOURCE = 'PLATFORMRECONCILIATION/GET_DETAIL_TABLESOURCE';
export const SET_DETAIL_TABLESOURCE = 'PLATFORMRECONCILIATION/SET_DETAIL_TABLESOURCE';