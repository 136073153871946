
/**
 * 服务说明创建或编辑弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { Modal, message, Input, Form, Select } from 'antd';
import styles from './CreateEdit.module.scss';
import model from '@/reducers/productmanage/explainlist/model';

const { getProductExplainInfo, getSalaAfterTypeEnum } = model.actions;

class CreateEdit extends React.Component {

    static propTypes = {
        form: PropTypes.object,
        explainItem: PropTypes.object,
        closeMode: PropTypes.func,
        getProductExplainInfo: PropTypes.func,
        productExplainInfo: PropTypes.object,
        getSalaAfterTypeEnum: PropTypes.func,
        salaAfterTypeEnum: PropTypes.array
    }

    state = {
        confirmLoading: false,
    }

    componentDidMount() {
        const { explainItem: { id = '' } = {}, getProductExplainInfo, getSalaAfterTypeEnum } = this.props;
        getSalaAfterTypeEnum();
        if (id) getProductExplainInfo({ id });
    }
    // 点击确认
    _handleOk = () => {
        const { closeMode, explainItem: { url = '', id = '' } = {}, form: { validateFieldsAndScroll } = {} } = this.props;
        validateFieldsAndScroll((err, values) => {
            const params = { id, ...values };
            if (!err) {
                this.setState({ confirmLoading: true });
                http(url, params, 'POST')
                    .then(res => {
                        message.success(res.message);
                        this.setState({ confirmLoading: false });
                        closeMode(true);
                    })
                    .catch(err => {
                        message.error(err.message);
                        this.setState({ confirmLoading: false });
                        closeMode();
                    });
            }
        });
    }

    render() {
        const { confirmLoading } = this.state;
        const {
            form: { getFieldDecorator } = {},
            explainItem: { id = '' } = {},
            closeMode,
            productExplainInfo: {
                serviceName,
                salesafterType: { code } = {},
                serviceDesc
            } = {},
            salaAfterTypeEnum = []
        } = this.props;

        return (
            <Modal
                width={480}
                centered
                title={id ? '编辑服务说明' : '创建服务说明'}
                visible={true}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={() => closeMode()}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                        <Form.Item label='服务说明'>
                            {getFieldDecorator('serviceName', {
                                initialValue: id && serviceName,
                                rules: [
                                    { required: true, message: '请输入服务说明' },
                                    { type: 'string', max: 22, mix: 2, message: '服务说明长度最小2，最大22' }
                                ],
                            })(<Input placeholder='请输入服务说明' />)}
                            <span>请输入服务说明:最少2个汉字，最多22个汉字</span>
                        </Form.Item>
                        <Form.Item label='售后类型'>
                            {getFieldDecorator('salesafterType', {
                                initialValue: id ? code : undefined,
                                rules: [{ required: true, message: '请选择售后类型' }],
                            })(<Select placeholder='请选择售后类型'>
                                {
                                    salaAfterTypeEnum.map(item => {
                                        return <Select.Option key={item.code} value={item.code}>{item.value}</Select.Option>;
                                    })
                                }
                            </Select>)}
                        </Form.Item>
                        <Form.Item label='说明描述'>
                            {getFieldDecorator('serviceDesc', {
                                initialValue: id && serviceDesc,
                                rules: [
                                    { required: true, message: '请输入说明描述' },
                                    { type: 'string', max: 500, message: '说明描述长度最大500' }
                                ],
                            })(<Input.TextArea placeholder='请输入说明描述' autoSize={{ minRows: 3, maxRows: 5 }} />)}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}
export default connect(state => ({
    productExplainInfo: state.productExplain.productExplainInfo,
    salaAfterTypeEnum: state.productExplain.salaAfterTypeEnum
}), { getProductExplainInfo, getSalaAfterTypeEnum })(Form.create({})(CreateEdit));