import React from 'react';
import styles from './AddProductTable.module.scss';
import PropTypes from 'prop-types';
import { message, Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { priceFenToYuanCell, ImgCell, tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';

const columns = () => [
    {
        title: '商品图片',
        dataIndex: 'mainImgUrl',
        key: 'mainImgUrl',
        width: cellWidth.small,
        align: 'center',
        render: ImgCell
    },
    {
        title: '商品名称',
        dataIndex: 'goodsName',
        key: 'goodsName ',
        width: cellWidth.big,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '商品品牌',
        dataIndex: 'brandName',
        key: 'brandName',
        width: cellWidth.normal,
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '货号',
        dataIndex: 'goodsNo',
        key: 'goodsNo',
        align: 'center',
        width: cellWidth.normal,
        render: (text) => (TableCell(text))
    },
    {
        title: '零售价',
        dataIndex: 'salePrice',
        key: 'salePrice',
        width: cellWidth.normal,
        align: 'center',
        render: priceFenToYuanCell
    }
];

// 表格项内容为文本
const TableCell = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text}
        </div>
    );
};

class AddProductTable extends React.Component {

    // 表格复选框操作回调
    _rowSelection = {
        onChange: (selectedkeys, selectedRows) => {
            if (selectedRows.length > 5) return message.warning('最多选择50个商品');
            this.props.tableAction(selectedRows);
        }
    };

    render() {
        const { paginationChange } = this.props;
        let { resultTotal, pageSize, pageNum, dataList } = this.props.renderData;

        dataList = dataList.map(v => ({ ...v, key: v.goodsId + ',' + v.shopId }));

        return (
            <div className={styles.container}>
                <div className={styles.table_box}>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        rowSelection={this._rowSelection}
                        rowKey={record => record.key}
                        columns={columns()}
                        dataSource={dataList}
                        pagination={false}
                        scroll={{ x: 'max-content', y: 350 }}
                    />
                </div>
                <div className={styles.pagination_box}>
                    <XPagination
                        resultTotal={resultTotal}
                        pageSize={pageSize}
                        pageNum={pageNum}
                        onChange={paginationChange} />
                </div>
            </div>
        );
    }
}

AddProductTable.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

export default AddProductTable;