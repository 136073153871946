import React, { Fragment, useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import KeepAlive from '@/routes/KeepAlive';
import { connect } from 'react-redux';
import { autoPageNum, autopageSize } from '@/assets/config';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XSelect, XOKButton, XCancelButton, XDatePicker, XInfoLabel, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth, tooltipClick } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { Alert, message, Modal, Table } from 'antd';
import model from '@/reducers/storehousefinancialmanage/platdividendincome/model';
import http from '@/assets/api/http';
import BigNumber from 'bignumber.js';
import { setLocalStorage } from '@/assets/js/storage';
import moment from 'moment';

const TableComponent = ({
    renderData: {
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0,
        dataList = []
    } = {},
    paginationChange,
    tableAction,
    keys = []
}) => {
    const tableOperate = (id, { status = '', ...other }) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => tableAction(id, 'detail')}>明细</div>
            {status != 'FINISH' && <div className={styles.item} onClick={() => tableAction(other, 'audit')}>审核</div>}
        </div >;
    };
    const columns = [
        {
            title: '类目名称',
            dataIndex: 'incomeTypeDesc',
            key: 'incomeTypeDesc',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '总营业额',
            dataIndex: 'totalTurnover',
            key: 'totalTurnover',
            onCell: tooltipStyle,
            render: (t, r) => tooltipClick(t, () => tableAction(r.incomeType, 'total', r.billableTime))
        },
        {
            title: '执行收益比例',
            dataIndex: 'settlementRatio',
            width: cellWidth.normal,
            key: 'settlementRatio',
            onCell: tooltipStyle,
            render: t => tooltip(`${new BigNumber(t || 0).multipliedBy(100).toNumber()}%`)
        },
        {
            title: '执行周期',
            dataIndex: 'billableTime',
            key: 'billableTime',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '应结金额',
            dataIndex: 'settlementAmount',
            key: 'settlementAmount',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '执行人数',
            dataIndex: 'participantsNumber',
            key: 'participantsNumber',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '人均金额',
            dataIndex: 'avgAmount',
            key: 'avgAmount',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'desc',
            key: 'desc',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作人',
            dataIndex: 'operator',
            key: 'operator',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作时间',
            dataIndex: 'operatorTime',
            key: 'operatorTime',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            onCell: tooltipStyle,
            render: tableOperate
        }
    ];
    const rowSelection = {
        selectedRowKeys: keys,
        onChange: keys => {
            tableAction(keys, 'batch');
        },
        getCheckboxProps: r => ({
            disabled: r.status === 'FINISH',
        })
    };
    return <div className={styles.tableContainer}>
        <XTableSelectionCount selectedNum={keys.length} onClean={() => tableAction([], 'batch')} />
        <Table
            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
            rowSelection={rowSelection}
            rowKey='id'
            columns={columns}
            dataSource={dataList}
            pagination={false}
            scroll={{ y: true }}
        />
        <XPagination
            resultTotal={resultTotal}
            pageSize={pageSize}
            pageNum={pageNum}
            onChange={paginationChange}
        />
    </div>;
};
TableComponent.propTypes = {
    history: PropTypes.object,
    renderData: PropTypes.object,
    paginationChange: PropTypes.func,
    tableAction: PropTypes.func,
    keys: PropTypes.array
};

const AuditModal = ({
    visible,
    tableAction,
    modalParmas: {
        id,
        recordIds,
        totalTurnover,
        settlementAmount,
        participantsNumber,
        avgAmount
    } = {}
}) => {

    const [loading, setLoading] = useState(false);

    const handleOk = () => {
        setLoading(true);
        http('/settle/financialManagement/confirmPayment', { recordIds }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                tableAction(true, 'close');
            })
            .catch(err => {
                setLoading(false);
                message.error(err.message);
            });
    };

    return <Modal
        width={460}
        centered
        title='财务审核'
        visible={visible}
        confirmLoading={loading}
        onOk={handleOk}
        onCancel={() => tableAction(false, 'close')}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='通过'
    >
        <div className={styles.container}>
            {
                !id ?
                    <Alert message={
                        <div>
                            当前已选择“数量”收益，请认真核对金额是否有误，<br />
                            如有疑问请联系技术人员。审核通过后，系统将金额发放给对应销售员钱包
                        </div>} type='info' showIcon
                    /> :
                    <Fragment>
                        <Alert message={
                            <div>
                                请认真核对金额是否有误，如有疑问请联系技术人员。<br />
                                审核通过后，系统将金额发放给对应销售员钱包
                            </div>} type='info' showIcon
                        />
                        <XInfoLabel label='总销售收益：' value={totalTurnover} />
                        <XInfoLabel label='应付金额：' value={settlementAmount} />
                        <XInfoLabel label='执行人数：' value={participantsNumber} />
                        <XInfoLabel label='人均金额：' value={avgAmount} />
                    </Fragment>
            }
        </div>
    </Modal>;
};
AuditModal.propTypes = {
    visible: PropTypes.bool,
    tableAction: PropTypes.func,
    modalParmas: PropTypes.object
};

const PlatDividendIncome = ({
    history,
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getPlatDividendIncomeData,
    tableData,
    getClassifyEnum,
    classifyEnum
}) => {

    const [incomeType, setIncomeType] = useState({});
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [pageNum, setPageNum] = useState(autoPageNum);
    const [pageSize, setPageSize] = useState(autopageSize);
    const [openModal, setOpenModal] = useState(false);
    const [modalParmas, setModalParmas] = useState({});
    const [keys, setKeys] = useState([]);

    useEffect(() => {
        keepSecondNavBreadcrumb();
        getClassifyEnum();
    }, []);

    const getTableData = (params = {}) => {
        getPlatDividendIncomeData({
            startTime: params.startTime && params.startTime.format('YYYY-MM-DD HH:mm:ss'),
            endTime: params.endTime && params.endTime.format('YYYY-MM-DD HH:mm:ss'),
            incomeType: params.incomeType && params.incomeType.code,
            pageNum: params.pageNum, pageSize: params.pageSize
        });
        KeepAlive.saveCache(params);
    };

    const paginationChange = (pageSize, pageNum) => {
        setPageNum(pageNum);
        setPageSize(pageSize);
        getTableData({ startTime, endTime, incomeType, pageNum, pageSize });
    };

    const resetSearch = () => {
        setIncomeType({});
        setStartTime(null);
        setEndTime(null);
        KeepAlive.saveResetFunc(getTableData);
    };

    const tableAction = (key, type, time) => {
        const baseUrl = '/home/storehousefinancialmanage/platdividendincome';
        const jumpPage = (path, title) => {
            addOneBreadcrumbPath({ title, path: `${baseUrl}${path}` });
            history.push({ pathname: `${baseUrl}${path}` });
        };
        let platIncomeQuery = {};
        if (key === 'NEWCOMER_AREA') platIncomeQuery.code = 'VIP_BENEFITS';
        if (key === 'MEMBERS_ONLY') platIncomeQuery.code = 'VIP_EXCLUSIVE';
        if (time) {
            platIncomeQuery.finishedTimeF = moment(time).format('YYYY-MM-DD 00:00:00');
            platIncomeQuery.finishedTimeT = moment(time).format('YYYY-MM-DD 23:59:59');
        }
        const eventObject = {
            total: () => {
                if (time) {
                    setLocalStorage('platIncomeQuery', platIncomeQuery);
                }
                jumpPage('/order', '订单列表');
            },
            detail: () => jumpPage(`/detaillist/${key}`, '明细列表'),
            audit: () => {
                let recordIds;
                if (key instanceof Object) recordIds = [key.id];
                if (key instanceof Array) {
                    if (!key.length) return message.warn('请勾选批量审核数据');
                    recordIds = key;
                }
                setOpenModal(true);
                setModalParmas({ ...key, recordIds });
            },
            close: () => {
                setOpenModal(false);
                setModalParmas({});
                if (key) {
                    setKeys([]);
                    getTableData({ startTime, endTime, incomeType, pageNum, pageSize });
                }
            },
            batch: () => setKeys(key)
        };
        eventObject[type]();
    };

    const onChange = (p = {}) => {
        if (p.startTime) setStartTime(p.startTime);
        if (p.endTime) setEndTime(p.endTime);
        if (p.incomeType) setIncomeType(p.incomeType);
        if (p.pageNum != autoPageNum) setPageNum(p.pageNum);
        if (p.pageSize != autopageSize) setPageSize(p.pageSize);
        getTableData(p);
    };

    return <KeepAlive render={onChange}>
        <div className={styles.flexBoxContainer}>
            <SearchBox>
                <SearchItem>
                    <XSelect
                        label='类目名称'
                        showSearch
                        placeholder='请选择类目名称'
                        renderData={classifyEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={incomeType.value}
                        bindThis={setIncomeType}
                        bindName='incomeType'
                    />
                </SearchItem>
                <SearchItem>
                    <XDatePicker
                        placeholder='请选择开始时间'
                        label='执行周期'
                        value={startTime}
                        bindThis={setStartTime}
                        bindName='startTime'
                    />
                    <XDatePicker
                        placeholder='请选择结束时间'
                        style={{ marginRight: '20px' }}
                        value={endTime}
                        bindThis={setEndTime}
                        bindName='endTime'
                    />
                </SearchItem>
                <SearchItem>
                    <XOKButton
                        label='查询'
                        onClick={() => getTableData({ incomeType, startTime, endTime, pageNum, pageSize })}
                    />
                    <XCancelButton
                        label='重置'
                        onClick={resetSearch}
                    />
                </SearchItem>
            </SearchBox>
            <div><XOKButton label='批量审核' style={{ width: '90px' }} onClick={() => tableAction(keys, 'audit')} /></div>
            <TableComponent renderData={tableData} keys={keys} tableAction={tableAction} paginationChange={paginationChange} />
            <AuditModal visible={openModal} modalParmas={modalParmas} tableAction={tableAction} />
        </div>
    </KeepAlive>;
};
PlatDividendIncome.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getPlatDividendIncomeData: PropTypes.func,
    tableData: PropTypes.object,
    getClassifyEnum: PropTypes.func,
    classifyEnum: PropTypes.array,
};
export default connect(state => {
    return {
        tableData: state.platdividendincome.platDividendIncomeData,
        classifyEnum: state.platdividendincome.classifyEnum
    };
}, {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(PlatDividendIncome);