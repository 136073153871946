/**
 * 交割
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message, Radio } from 'antd';
import styles from './modeStyle.module.scss';
import { XTextArea, XInfoLabel, showConfirm } from '@/components/xqxc_ui';

const Cancellation = ({
    modeParams: {
        id = 0,
        ownerName = '',
        ownerTel = '',
        idCardNo = '',
        payAmount = 0
    },
    closeMode
}) => {

    const [loading, setLoading] = useState(false);
    const [backFlag, setBackFlag] = useState('1');
    const [remark, setRemark] = useState('');

    //确定取消
    const onSumbit = () => {
        if (!backFlag) {
            message.warning('请输入交割手数');
            return;
        }
        if (!remark) {
            message.warning('请输入备注');
            return;
        }
        const request = () => {
            setLoading(true);
            http('/exchangeOrder/invalid', { remark, id, backFlag }, 'POST')
                .then(res => {
                    message.success(res.message);
                    setLoading(false);
                    closeMode(true);
                }).catch(err => {
                    message.error(err.message);
                    setLoading(false);
                });
        };
        if (backFlag === '0') {
            showConfirm('重要操作请再次确认', <span style={{ color: '#F00' }}>作废{payAmount}贡献值，不会退回服务商贡献值账号。你还要继续吗？</span>, request);
        } else {
            request();
        }
    };

    return (
        <Modal
            width={400}
            centered
            title='作废兑换凭证'
            visible={true}
            confirmLoading={loading}
            onOk={onSumbit}
            onCancel={() => closeMode(false)}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'>
            <div className={styles.container}>
                <XInfoLabel
                    style={{ marginBottom: 20 }}
                    label='兑换人：'
                    value={ownerName}
                />
                <XInfoLabel
                    style={{ marginBottom: 20 }}
                    label='兑换人电话：'
                    value={ownerTel}
                />
                <XInfoLabel
                    style={{ marginBottom: 20 }}
                    label='身份证号：'
                    value={idCardNo}
                />
                <XInfoLabel
                    style={{ marginBottom: 20 }}
                    label='兑换金额（贡献值）：'
                    value={payAmount}
                />
                <div className={styles.type}>
                    <span className={styles.label}>作废操作：</span>
                    <Radio.Group
                        value={backFlag}
                        onChange={e => setBackFlag(e.target.value)}>
                        <Radio value='0'>作废（不退回贡献值）</Radio>
                        <Radio value='1'>退回贡献值</Radio>
                    </Radio.Group>
                </div>
                <XTextArea
                    isRequired
                    inputStyle={{ width: 220, marginTop: 20 }}
                    label='备注：'
                    bindThis={setRemark}
                    maxLength={100}
                    bindName='remark'
                    value={remark}
                />
                <p style={{ color: '#999999', paddingLeft: 50 }}>要求填写原因系统将发消息通知服务商。</p>
            </div>
        </Modal>
    );
};
Cancellation.propTypes = {
    modeParams: PropTypes.object.isRequired,
    closeMode: PropTypes.func.isRequired
};
export default Cancellation;