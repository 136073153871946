/**
 * 创建模板
 */
import React from 'react';
import { Modal, Input, Form, Select, message } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { XInput, XSelect } from '@/components/xqxc_ui';
import model from '@/reducers/systemmanage/template/model';
import styles from './CreateModal.module.scss';
import http from '@/assets/api/http';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';

const FormItem = Form.Item;//表单校验
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};
class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            customTemplateClassObj: {},
            templateNameFlag: true,
        };
    }
    componentDidMount() {
        this.props.getlistCustomTemplatePullDownClass();
    }
    _handleSubmit = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.setState({ confirmLoading: true }, () => {
                    http('/admin/customtemplate/selectByTemplateName', { customTemplateClassId: fieldsValue.classId, templateName: fieldsValue.templateName }, 'POST')
                        .then(() => {
                            addOneBreadcrumbPath({
                                title: 'APP自定义',
                                path: '/home/marketingmanage/appcustom/customlanding/'
                            });
                            this.props.history.push({
                                pathname: '/home/marketingmanage/appcustom/customlanding/',
                                state: {
                                    category: 'PRESETTEMPLATE',
                                    mode: 'control',
                                    templateName: fieldsValue.templateName,
                                    customTemplateClassId: fieldsValue.classId,
                                }
                            });
                            this.props.closeMode(true);
                            this.setState({
                                visible: false,
                                confirmLoading: false,
                            });
                        }).catch((reject) => {
                            message.error(reject.message);
                            this.setState({
                                confirmLoading: false,
                            });
                        });
                });
            }
        });
    }
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    render() {
        const { visible, confirmLoading, title, } = this.state;
        const { listCustomTemplatePullDownClass } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={550}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleSubmit}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='继续'
            >
                <div className={styles.container}>
                    <Form style={{ textAlign: 'left' }} {...formItemLayout} className="create-form" onSubmit={this._handleSubmit}>
                        <FormItem
                            htmlFor='templateName'
                            label={<span>模板名称</span>}
                        >
                            {getFieldDecorator('templateName', {
                                rules: [{ required: true, message: '请输入模板名称' },
                                { max: 15, message: '最多15个字' },
                                ],
                            })(<Input placeholder='请输入模板名称' />
                            )}
                            <span className={styles.spanLeft}>作为模板名称，最多15个字</span>
                        </FormItem>
                        <FormItem
                            htmlFor='classId'
                            label={<span>模板类型</span>}
                        >
                            {getFieldDecorator('classId', {
                                rules: [{ required: true, message: '请选择模板类型' }
                                ],
                            })(<Select placeholder='请选择模板类型'>
                                {listCustomTemplatePullDownClass.map(i => {
                                    return <Option key={i.id} value={i.id}>{i.className}</Option>;
                                })}

                            </Select>
                            )}
                        </FormItem>
                    </Form>
                </div>
            </Modal>
        );

    }
}

CreateModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    history: PropTypes.object, // 路由history对象
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
    listCustomTemplatePullDownClass: PropTypes.array,
    getlistCustomTemplatePullDownClass: PropTypes.func, //模板分类
    refsh: PropTypes.func,
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(CreateModal);// 校验
const mapStateToProps = (state) => ({
    listCustomTemplatePullDownClass: state.templatemanage.listCustomTemplatePullDownClass, // 模板分类
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, ...model.actions })(ForgotProducDetails);
