/**
 * 【个税申报列表】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { XInput, XDatePicker, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import AdvertTable from './components/AdvertTable';
import { message } from 'antd';
import { getTaxDeclarationList } from '@/reducers/storehousefinancialmanage/taxdeclaration/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends React.Component {

    state = {
        ownerName: '',
        startDate: null,
        endDate: null,
        selectedRowKeys: [],
        gsLoading: false,
        ryLoading: false
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    // 查询
    _searchHandle = (useCache) => {
        const { ownerName, startDate, endDate, pageNum, pageSize } = this.state;
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            ownerName,
            startDate: startDate,
            endDate: endDate,
        };
        this.props.getTaxDeclarationList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ ownerName: '', startDate: null, endDate: null, pageNum: 1, pageSize: 10, }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        }, () => {
            this._searchHandle('useCache');
        });

    }

    getSelectedRowKeysFun = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    }

    // 导出
    _exportHandle = (type) => {
        const { ownerName, startDate, endDate, selectedRowKeys } = this.state;
        const params = {
            ownerName,
            startDate,
            endDate,
            exportType: type,
            withdrawalIds: selectedRowKeys
        };

        if (type === 0) {
            this.setState({ ryLoading: true });
        }
        if (type === 1) {
            this.setState({ gsLoading: true });
        }

        http('/plat/withdrawal/exportTaxDeclaration', params, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result == '') {
                    message.error('没有数据');
                } else {
                    window.location.href = response.result;
                }
            }
        }).catch((e) => {
            message.error(e.message);
        }).finally(() => {
            if (type === 0) {
                this.setState({ ryLoading: false });
            }
            if (type === 1) {
                this.setState({ gsLoading: false });
            }
        });
    }

    render() {
        const { ownerName, startDate, endDate, gsLoading, ryLoading } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='姓名'
                                placeholder='请输入姓名'
                                value={ownerName}
                                bindThis={this}
                                bindName='ownerName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                placeholder='请输入开始时间'
                                label='所得期间'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat={true}
                            />
                            <XDatePicker
                                placeholder='请输入结束时间'
                                style={{ marginRight: '20px' }}
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.row}>
                        {/* <XOKButton
                            style={{ width: '90px', marginRight: 15 }}
                            label='新建'
                            onClick={this._newHandle}
                        /> */}
                        <XOKButton
                            loading={gsLoading}
                            style={{ width: '120px' }}
                            label='导出个税信息'
                            onClick={() => this._exportHandle(1)}
                        />
                        <XOKButton
                            loading={ryLoading}
                            style={{ width: '120px', marginLeft: 20 }}
                            label='导出人员信息'
                            onClick={() => this._exportHandle(0)}
                        />
                    </div>
                    <AdvertTable getSelectedRowKeysFun={this.getSelectedRowKeysFun} renderData={this.props.taxDeclarationList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    taxDeclarationList: PropTypes.object,
    getTaxDeclarationList: PropTypes.func
};

const mapStateToProps = (state) => ({
    taxDeclarationList: state.taxdeclaration.taxDeclarationList
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getTaxDeclarationList })(Main);