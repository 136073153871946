/**
 *  分销销售商品排行
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class Goodshare extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case 'company':
                addOneBreadcrumbPath({
                    title: '区域公司分销分享销售情况',
                    path: '/home/statisticsofdistributor/companyshare'
                });
                history.push({
                    pathname: '/home/statisticsofdistributor/companyshare'
                });
                break;
            case 'service':
                addOneBreadcrumbPath({
                    title: '服务商分销分享排行',
                    path: '/home/statisticsofdistributor/serviceshare'
                });
                history.push({
                    pathname: '/home/statisticsofdistributor/serviceshare',
                });
                break;
            case 'operation':
                addOneBreadcrumbPath({
                    title: '运营中心分销分享销售情况',
                    path: '/home/statisticsofdistributor/operationshare'
                });
                history.push({
                    pathname: '/home/statisticsofdistributor/operationshare',
                });
                break;
            case 'buycompany':
                addOneBreadcrumbPath({
                    title: '区域公司分销自购销售情况',
                    path: '/home/statisticsofdistributor/regionalcompanydistributionsalepurchase'
                });
                history.push({
                    pathname: '/home/statisticsofdistributor/regionalcompanydistributionsalepurchase'
                });
                break;
            case 'buyservice':
                addOneBreadcrumbPath({
                    title: '服务商分销自购排行',
                    path: '/home/statisticsofdistributor/serviceproviderdistributionrank'
                });
                history.push({
                    pathname: '/home/statisticsofdistributor/serviceproviderdistributionrank',
                });
                break;
            case 'buyoperation':
                addOneBreadcrumbPath({
                    title: '运营中心分销自购销售情况',
                    path: '/home/statisticsofdistributor/operatingcenterdistributionsalepurchase'
                });
                history.push({
                    pathname: '/home/statisticsofdistributor/operatingcenterdistributionsalepurchase',
                });
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

Goodshare.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Goodshare);