import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

const model = {
    namespace: 'corporateanchor',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getlistBusinieeAnchor': T('getlistBusinieeAnchor'), // 企业主播列表
            'getbusinessAnchorInfo': T('getbusinessAnchorInfo'), // 企业主播信息
            'getlistBusinessAnchorAccount': T('getlistBusinessAnchorAccount'), // 企业主播账号列表
            'getBusinessAnchorOrderPayInfo': T('getBusinessAnchorOrderPayInfo'), // 提交支付信息
        },
        actions: {
            'getlistBusinieeAnchor': A('getlistBusinieeAnchor'), // 企业主播列表
            'getbusinessAnchorInfo': A('getbusinessAnchorInfo'), // 企业主播信息
            'getlistBusinessAnchorAccount': A('getlistBusinessAnchorAccount'), // 企业主播账号列表
            'getBusinessAnchorOrderPayInfo': A('getBusinessAnchorOrderPayInfo'), // 提交支付信息
        },
        sagas: {
            'getlistBusinieeAnchor': S('getlistBusinieeAnchor', '/businessanchor/listBusinieeAnchor'),
            'getbusinessAnchorInfo': S('getbusinessAnchorInfo', '/businessanchor/businessAnchorInfo', 'POST', 'id'),
            'getlistBusinessAnchorAccount': S('getlistBusinessAnchorAccount', '/businessanchor/listBusinessAnchorAccount'),
            'getBusinessAnchorOrderPayInfo': S('getBusinessAnchorOrderPayInfo', '/boss/order/getBusinessAnchorOrderPayInfo'),
        },
        reducers: {
            'listBusinieeAnchor': R('getlistBusinieeAnchor', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'businessAnchorInfo': R('getbusinessAnchorInfo', {}),
            'listBusinessAnchorAccount': R('getlistBusinessAnchorAccount', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'BusinessAnchorOrderPayInfo': R('getBusinessAnchorOrderPayInfo', {}),
        }
    })
};
export default createModel(model);