import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table, Switch, Icon, Tooltip } from 'antd';
import { XPagination, XTableSelectionCount, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell, cellWidth } from '@/components/TableCell';
import ButtonPerssion from '@/routes/ButtonPerssion';
import labelIcon from '@/assets/image/icon-active.png';
class TableContent extends Component {
    state = {
        selectedRowKeys: [],
    };

    _beanRiceTip = (text) => {
        return <span>
            赠送{text}
            <Tooltip placement="top" title={`用户购买时可获得的${text}数量，按购买数量累加赠送`} >
                <Icon style={{ marginLeft: '10px' }} type="question-circle" />
            </Tooltip>
        </span>;
    };

    _titleCell = (text, item) => {
        return <Tooltip placement="top" title={text}>
            {!!item.isJoinActivity && <img style={{ width: '16px', height: '16px', verticalAlign: 'text-top' }} src={labelIcon} alt='' />}
            <span style={{ display: 'inline-block', width: '150px', overflow: 'hidden', margin: '0 5px' }}>{text}</span>
            <ButtonPerssion code='productmanage/productlist/splbbjspbt'>
                <Icon type='edit' style={{ fontSize: '18px', color: '#1078ff', cursor: 'pointer' }} onClick={() => this.props.tableAction(item.id, 'goodsName', item)} />
            </ButtonPerssion>
        </Tooltip>;
    }

    _columns = () => {
        return [{
            title: '商品图片',
            dataIndex: 'mainImgUrl',
            key: 'mainImgUrl',
            align: 'center',
            width: cellWidth.normal,
            render: ImgCell
        },
        {
            title: '商品标题',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
            width: cellWidth.big,
            onCell: tooltipStyle,
            render: this._titleCell
        },
        {
            title: '货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否虚拟商品',
            dataIndex: 'virtualFlag.value',
            key: 'virtualFlag.value',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否批发',
            dataIndex: 'isWholesale',
            key: 'isWholesale',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: (text, record) => tooltip(text == '1' ? '是' : '否')
        },
        {
            title: '是否分销',
            dataIndex: 'isRetail',
            key: 'isRetail',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: (text, record) => tooltip(text == '1' ? '是' : '否')
        },
        {
            title: '商品分类',
            dataIndex: 'categoryName',
            key: 'categoryName',
            align: 'center',
            width: cellWidth.big,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '分类类型',
            dataIndex: 'categorySubTypeName',
            key: 'categorySubTypeName',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '首页推荐',
            dataIndex: 'isHomeReco',
            key: 'isHomeReco',
            width: cellWidth.normal,
            align: 'center',
            render: (text, item) => {
                return <ButtonPerssion code='productmanage/productlist/sytj' render={(hasPerssion) => {
                    return this._switchCell(text, item, 'home', hasPerssion);
                }} />;
            }
        },
        {
            title: '分类推荐',
            dataIndex: 'isCategoryReco',
            key: 'isCategoryReco',
            align: 'center',
            width: cellWidth.normal,
            render: (text, item) => {
                return <ButtonPerssion code='productmanage/productlist/fltj' render={(hasPerssion) => {
                    return this._switchCell(text, item, 'channel', hasPerssion);
                }} />;
            }
        },
        {
            title: '参与营销服务费',
            dataIndex: 'hasMarketRate',
            key: 'hasMarketRate',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text) => text ? '是' : '否'
        },
        {
            title: this._beanRiceTip('信用值'),
            dataIndex: 'showRiceNum',
            key: 'showRiceNum',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: (text, record) => this._getRiceBean(record, 'minShowRiceNum', 'maxShowRiceNum', 'productmanage/productlist/szxm', 'rice')
        },
        {
            title: this._beanRiceTip('人气值'),
            dataIndex: 'showBeanNum',
            key: 'showBeanNum',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: (text, record) => this._popularCell(record)
        },
        {
            title: '状态',
            dataIndex: 'goodsState',
            key: 'goodsState',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            align: 'center',
            width: cellWidth.time,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            fixed: 'right',
            width: cellWidth.big,
            render: this._tableAction
        }];
    }

    _popularCell = (record) => {
        const { goodsSource: { code = '' } = {} } = record;
        if (code != 'CITY_GOODS') {
            return this._getRiceBean(record, 'minPopularityNum', 'maxPopularityNum', 'productmanage/productlist/editbean', 'bean');
        }
        return '-';
    }

    // 表格项内容为开关
    _switchCell = (switchStatus, item, type, hasPerssion) => {
        const { tableAction } = this.props;
        let checked = true;
        if (switchStatus == 0) {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    disabled={!hasPerssion}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={checked}
                    onChange={() => {
                        if (type == 'home') {
                            tableAction(item.id, 'switch_home', item); // 首页推荐 
                        } else {
                            tableAction(item.id, 'switch_channel', item); // 频道推荐
                        }
                    }}
                />
            </div>
        );
    }

    //设置信用值
    _getRiceBean = (item, min, max, url, type) => {
        const { tableAction } = this.props;
        const C =
            <ButtonPerssion code={url}>
                <Icon style={{ paddingLeft: '10px' }} onClick={() => tableAction(item.id, 'riceBean', type)} type="edit" theme="twoTone" />
            </ButtonPerssion>;

        if (item[min] === item[max]) {
            const riceNode = <span>
                {item[max]}
                {C}
            </span>;
            return riceNode;
        } else {
            const riceNode = <span>
                {`${item[min]} ~ ${item[max]}`}
                {C}
            </span>;
            return riceNode;
        }
    };

    // 表格操作
    _tableAction = (key, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <ButtonPerssion code='productmanage/productlist/szxnxl'>
                    <div className={styles.item} onClick={() => tableAction(key, 'setSales')}>设置虚拟销量</div>
                </ButtonPerssion>
                <div className={styles.item} onClick={() => tableAction(key, 'detail')}>详情</div>
                <div className={(record.goodsState == '禁用') ? styles.hide : styles.item} onClick={() => tableAction(key, 'ban')}>禁用</div>
                {record.goodsState == '上架' && <div className={styles.item} onClick={() => tableAction(key, 'changeState', 'pullOffShelvesGoods')}>下架</div>}
                {record.goodsState == '下架' && <Fragment>
                    {/* <div className={styles.item} onClick={() => tableAction(key, 'settle')}>结算设置</div> */}
                    <div className={styles.item} onClick={() => tableAction(key, 'changeState', 'putOnSaleGoods')}>上架</div>
                </Fragment>}
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys) => {
            const { tableAction } = this.props;
            this.setState({ selectedRowKeys });
            tableAction(null, 'batchBan', selectedRowKeys);
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        const { tableAction } = this.props;
        this.setState({ selectedRowKeys: [] });
        tableAction(null, 'batchBan', []);
    }

    render() {
        const { renderData, scrollY, paginationChange } = this.props;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    rowSelection={this._rowSelection}
                    columns={this._columns()}
                    pagination={false}
                    dataSource={renderData.dataList}
                    scroll={{ x: 'max-content', y: scrollY }} />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={paginationChange} />
            </div>
        );
    }
}


TableContent.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
    scrollY: PropTypes.string
};

export default XTableHOC(TableContent, 40);