import * as T from './actiontypes';

// 获取'枚举下拉'数据源
export const enumSelectSource = (state = [], { type, payload }) => {
    switch (type) {
        case T.SET_ENUM_SELECTSOURCE:
            return payload || [];
        default:
            return state;
    }
};
// 获取'枚举下拉'数据源 店铺客服坐席
export const enumFlagSource = (state = [], { type, payload }) => {
    switch (type) {
        case T.SET_ENUM_FLAGENUM:
            return payload || [];
        default:
            return state;
    }
};
// 获取'枚举下拉'数据源 商户客服坐席
export const enumCustomerSource = (state = [], { type, payload }) => {
    switch (type) {
        case T.SET_ENUM_OPENCUSTOMER:
            return payload || [];
        default:
            return state;
    }
};