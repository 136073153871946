import React from 'react';
import styles from './TableData.module.scss';
import { Switch, Tooltip } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const columns = (actionClickHandle) => [
    {
        title: '编号ID',
        dataIndex: 'merchantKey',
        key: 'merchantKey',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '店铺名称',
        dataIndex: 'shopName',
        key: 'shopName',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '归属商户',
        dataIndex: 'merchantName',
        key: 'merchantName',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    { title: '经营类目分类', dataIndex: 'category', key: 'category', align: 'center', onCell: tooltipStyle, render: tooltip },
    { title: '类目名称', dataIndex: 'subcategory', key: 'subcategory', align: 'center', onCell: tooltipStyle, render: tooltip },
    {
        title: '店铺账号',
        dataIndex: 'loginName',
        key: 'loginName',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '联系人电话',
        dataIndex: 'contactTel',
        key: 'contactTel',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '店铺推荐',
        dataIndex: 'isReco',
        key: 'isReco',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle))
    },
    {
        title: '店铺状态',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '客服座席',
        dataIndex: 'isOpenCustomerService.value',
        key: 'isOpenCustomerService.value',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (id, item) => TableAction(id, item, actionClickHandle)
    },
];

export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    return data && data.map((item) => {
        item.merchantKey = item.id; // 操作key  封禁是1
        item.status = item.shopState == '1' ? '营业中' : '已停业'; // 店铺状态
        return item;
    });
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};

// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
    let checked = true;
    if (sw == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                key={item.id}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={(value) => {
                    actionClickHandle(value, item, 'switch');
                }}
            />
        </div>
    );
};
const TableAction = (id, item, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, item, 'PREVIEW')}>预览</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, item, 'DELETE')}>详情</div>
            {item.shopBanned == '0' ? <div className={styles.item} onClick={() => actionClickHandle(id, item, 'BANNED')}>封禁</div> :
                <div className={styles.item} onClick={() => actionClickHandle(id, item, 'LIFTING')}>解封</div>
            }
        </div>
    );
};