import { createModel } from '@/store/tools';

const model = {
    namespace: 'merchantpermission',
    build: (T, A, S, R) => ({
        actiontypes: {
            'queryMarketExchangeStarValueRatio': T('queryMarketExchangeStarValueRatio'),
            'queryMarketList': T('queryMarketList'),
            'queryMerchantList': T('queryMerchantList'),

            'getGoodsAuditList': T('getGoodsAuditList'),
            'getSeMerchantList': T('getSeMerchantList'),
            'getMerchants': T('getMerchants'),
            'getMerchantListData': T('getMerchantListData'),
            'getOperateLogData': T('getOperateLogData'),
            'getMerchantInfo': T('getMerchantInfo'),
            'getEditEnum': T('getEditEnum'),
            'getMerchantTypes': T('getMerchantTypes'),
        },
        actions: {
            'queryMarketExchangeStarValueRatio': A('queryMarketExchangeStarValueRatio'), // 查询营销服务费兑换人气值比率
            'queryMarketList': A('queryMarketList'), // 查询商户营销服务费列表
            'queryMerchantList': A('queryMerchantList'), // 查询未设置营销服务费商户列表


            'getGoodsAuditList': A('getGoodsAuditList'), // 商户批发商品免审核列表
            'getSeMerchantList': A('getSeMerchantList'), // 选择商户数据
            'getMerchants': A('getMerchants'), // 商户下拉列数据
            'getMerchantListData': A('getMerchantListData'), // 商户权限设置白名单列表
            'getOperateLogData': A('getOperateLogData'),//操作日志
            'getMerchantInfo': A('getMerchantInfo'),//编辑
            'getEditEnum': A('getEditEnum'),//编辑弹框枚举
            'getMerchantTypes': A('getMerchantTypes'),//商户类型
        },
        sagas: {
            'queryMarketExchangeStarValueRatio': S('queryMarketExchangeStarValueRatio', '/paymentParam/queryMarketExchangeStarValueRatio'),
            'queryMarketList': S('queryMarketList', '/marketing/infoList'),
            'queryMerchantList': S('queryMerchantList', '/marketing/merchantList'),

            'getGoodsAuditList': S('getGoodsAuditList', '/merchant/wholesale/listMerchantWholesale'),
            'getSeMerchantList': S('getSeMerchantList', '/merchant/wholesale/selectByMerchantInfo'),
            'getMerchants': S('getMerchants', '/merchant/info/listMerchantIdAndName'),
            'getMerchantListData': S('getMerchantListData', '/merchant/merchantSetting/findMerchantSettingPage'),
            'getOperateLogData': S('getOperateLogData', '/merchant/merchantSetting/findMerchantSettingLogPage'),
            'getMerchantInfo': S('getMerchantInfo', '/merchant/merchantSetting/findMerchantSettingRelieveFee', 'POST', 'merchantId'),
            'getEditEnum': S('getEditEnum', '/settle/param/setting/plat/config', 'GET'),
            'getMerchantTypes': S('getMerchantTypes', '/enum/MerchantTypeEnum'),
        },
        reducers: {
            'queryMarketExchangeStarValueRatio': R('queryMarketExchangeStarValueRatio', ''),
            'queryMarketList': R('queryMarketList', {}),
            'queryMerchantList': R('queryMerchantList', {}),

            'goodsAuditList': R('getGoodsAuditList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'seMerchantList': R('getSeMerchantList', { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 }),
            'merchants': R('getMerchants', { merchantIdAndNames: [] }),
            'merchantListData': R('getMerchantListData', { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 }),
            'operateLogData': R('getOperateLogData', { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 }),
            'merchantInfo': R('getMerchantInfo', {}),
            'editEnum': R('getEditEnum', []),
            'merchantTypes': R('getMerchantTypes', []),
        }
    })
};
export default createModel(model);