import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Icon, Table, Tooltip } from 'antd';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth, tooltipClick } from '@/components/TableCell';
import ButtonPerssion from '@/routes/ButtonPerssion';

class TableContent extends Component {

	_columns = () => [
		{
			title: '公司名称',
			dataIndex: 'oc2',
			key: 'oc2',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: (text, item) => tooltipClick(text, () => {
				this.props.tableAction(item.key, 'detail');
			}, 'left')
		},
		{
			title: '省份',
			dataIndex: 'provinceName',
			key: 'provinceName',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '城市',
			dataIndex: 'cityName',
			key: 'cityName',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '联系人',
			dataIndex: 'oc3',
			key: 'oc3',
			align: 'center',
			width: cellWidth.time,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '联系电话',
			dataIndex: 'oc4',
			key: 'oc4',
			align: 'center',
			width: cellWidth.time,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '有效期',
			dataIndex: 'oc5',
			key: 'oc5',
			align: 'center',
			width: cellWidth.big,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '类型',
			dataIndex: 'type.value',
			key: 'type.value',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '状态',
			dataIndex: 'oc6',
			key: 'oc6',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: <soan>是否开通权益
				<Tooltip placement="top" title={`
				此处的权益是指：开通【每日抢拍】小程序 - 每日补贴活动，运营中心获得的补贴权益
				开通后，该运营中心才能获得运营中心补贴。
				未开通，运营中心不能获得运营中心补贴
				`}>
					<Icon type="question-circle" style={{ cursor: 'pointer', fontSize: '16px', marginLeft: '5px' }} />
				</Tooltip></soan>,
			dataIndex: 'isOpenRights.value', key: 'isOpenRights.value',
			width: cellWidth.normal, align: 'center', onCell: tooltipStyle, render: tooltip
		},
		{
			title: '操作人',
			dataIndex: 'oc7',
			key: 'oc7',
			align: 'center',
			width: cellWidth.time,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'key',
			key: 'key',
			align: 'center',
			width: 240,
			fixed: 'right',
			render: (key, record) => this._tableAction(key, record)
		},
	];

	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		const { oc66, oc8, oc2, isOpenRights = {}, id, companyName } = record;
		return <div className={styles.action}>
			{oc66 === 'AUDIT' && <div className={styles.item} onClick={() => tableAction(key, 'detail')}>详情</div>}
			{((oc66 == 'APPROVED' && oc8 === 1) || oc66 !== 'AUDIT') && <div className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>}
			{(oc66 == 'NOTAPPROVED' || oc66 == 'NEW') && <div className={styles.item} onClick={() => tableAction(key, 'delete')}>删除</div>}
			{oc66 == 'APPROVED' && oc8 === 1 && <div className={styles.item} onClick={() => tableAction(key, 'ban', record)}>禁用</div>}
			{oc66 == 'APPROVED' && oc8 === 0 && <div className={styles.item} onClick={() => tableAction(key, 'use', record)}>启用</div>}
			<ButtonPerssion code='operationcenter/operationList/szmx'>
				<div className={styles.item} onClick={() => tableAction(key, 'budget', oc2)}>收支明细</div>
			</ButtonPerssion>
			<div className={styles.item} onClick={() => tableAction(key, 'isOpenRights', {
				requrl: isOpenRights.code == '1' ? 'delOpenRights' : 'addOpenRights',
				tip: isOpenRights.code == '1' ? '关闭' : '开通',
				subjects: isOpenRights.code != '1' ? [companyName] : undefined,
				subjectIds: isOpenRights.code == '1' ? [id] : undefined,
				subjectSystem: 'OPERATIONS_CENTER_RIGHTS', isSendSms: 1
			})}>{isOpenRights.code == '1' ? '关闭权益' : '开通权益'}</div>
		</div>;
	};

	_tableData = (data) => {
		let newData = [];
		data.map(item => {
			let tableItem = { ...item };
			tableItem.key = item.id;
			tableItem.oc1 = item.companyCode;
			tableItem.oc2 = item.companyName;
			tableItem.oc3 = item.contactName;
			tableItem.oc4 = item.contactTel;
			tableItem.oc5 = `${item.agrtStartTime} 至 ${item.agrtEndTime}`;
			tableItem.oc6 = (item.companyState || {}).value;
			tableItem.oc66 = (item.companyState || {}).code;
			tableItem.oc7 = item.operation;
			tableItem.oc8 = item.state;
			tableItem.cityName = item.cityName;
			tableItem.provinceName = item.provinceName;
			tableItem.type = item.type;
			return newData.push(tableItem);
		});
		return newData;
	}

	render() {
		const { renderData, scrollY, paginationChange } = this.props;
		return (
			<div className={styles.tableContainer} style={{ height: '100%' }}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey={record => record.key}
					columns={this._columns()}
					pagination={false}
					dataSource={this._tableData(renderData.dataList)}
					scroll={{ x: 'max-content', y: scrollY }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}

TableContent.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
	scrollY: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	])
};

export default XTableHOC(TableContent);