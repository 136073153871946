/**
 * 修改区域
 */
import React from 'react';
import { Modal, message, Select } from 'antd';
import PropTypes from 'prop-types';
import styles from './LwMemberChangeArea.module.scss';
import http from '@/assets/api/http';
import { XScrollSelect } from '@/components/xqxc_ui';
import OperationList from '@/vcomps/OperationList';

class StarRice extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        validAccount: false,

        operation: {},
        region: {}
    }

    _handleOk = () => {

        const { operation, region } = this.state;

        if (!operation.id) {
            message.warning('请选择运营中心');
            return;
        }

        if (!region.id) {
            message.warning('请选择区域公司');
            return;
        }

        this.setState({ confirmLoading: true });
        http('/user/giftAccount/updateGiftAccountArea', {
            accountIds: this.props.data.ids,
            operationsCenterId: operation.id,
            areaCompanyId: region.id
        }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('添加成功');
                this.setState({ visible: false });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        }).finally(() => {
            this.setState({ confirmLoading: false });
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, operation, region } = this.state;

        return (
            <Modal
                width={466}
                centered
                title='修改区域'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div>
                        <labe>所属运营中心：</labe>
                        <OperationList
                            reqParams={{ type: this.props.data.areaCompanyType }}
                            selectStyle={{ width: 380, marginLeft: -10 }}
                            placeholder='请选择运营中心'
                            value={operation}
                            bindThis={this}
                            bindName='operation'
                        />
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <labe>所属区域公司：</labe>
                        {!operation.id && <Select style={{ width: 380 }} placeholder='请选择区域公司'></Select>}
                        {operation.id &&
                            <XScrollSelect
                                key={operation.id}
                                style={{ width: 380 }}
                                url='/admin/company/listAreaCompany'
                                reqParams={{ operationsId: operation.id, type: this.props.data.areaCompanyType }}
                                placeholder='请选择区域公司'
                                showSearch
                                mainKey='companyName'
                                selectID='id'
                                selectName='companyName'
                                value={region}
                                onChange={(value, item) => this.setState({ region: item.props['data-option'] })}
                            />
                        }
                    </div>
                </div>
            </Modal>
        );
    }
}

StarRice.propTypes = {
    curValue: PropTypes.number, //  当前信用值
    data: PropTypes.array.isRequired, // 需要修改魅力值的会员对象数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default StarRice;