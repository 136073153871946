import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table, Switch } from 'antd';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell, cellWidth } from '@/components/TableCell';
import ButtonPermission from '@/routes/ButtonPerssion';

import { getLocalStorage } from '@/assets/js/storage';
import http from '@/assets/api/http';
import { message } from 'antd';

const columns = (TableAction, actionClickHandle) => [
	{
		title: '商品图片',
		dataIndex: 'mainImage',
		key: 'mainImage',
		align: 'center',
		width: cellWidth.small,
		render: ImgCell
	},
	{
		title: '商品名称',
		dataIndex: 'productName',
		key: 'productName',
		align: 'center',
		width: cellWidth.big,
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '一级类目',
		dataIndex: 'categoryName1',
		key: 'categoryName1',
		align: 'center',
		width: cellWidth.normal,
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '二级类目',
		dataIndex: 'categoryName2',
		key: 'categoryName2',
		align: 'center',
		width: cellWidth.normal,
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '三级类目',
		dataIndex: 'categoryName3',
		key: 'categoryName3',
		align: 'center',
		width: cellWidth.normal,
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '品牌名称',
		dataIndex: 'brandName',
		key: 'brandName',
		align: 'center',
		width: cellWidth.normal,
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '状态',
		dataIndex: 'status',
		key: 'status',
		align: 'center',
		width: cellWidth.normal,
		onCell: tooltipStyle,
		render: (text, item) => renderStatusCell(item, actionClickHandle)
	},
	{
		title: '同城分类',
		dataIndex: 'platCategoryFullName',
		key: 'platCategoryFullName',
		align: 'center',
		width: cellWidth.normal,
		onCell: tooltipStyle,
		render: (val) => (val ? val : '--')
	},
	{
		title: '操作',
		dataIndex: 'productId',
		key: 'productId',
		align: 'center',
		fixed: 'right',
		width: cellWidth.normal,
		render: (productId) => TableAction(productId, actionClickHandle)
	},
];

// 启用禁用分类
const renderStatusCell = (item, actionClickHandle) => {
	let checked = item.status == '1';
	return (
		<ButtonPermission code="productmanage/standardproduct/statusswitch" render={(hasPermission) => {
			if (!hasPermission) {
				return (<div>-</div>);
			}
			return (
				<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
					<Switch
						key={item.productId}
						checkedChildren="开"
						unCheckedChildren="关"
						checked={checked}
						onChange={(value) => {
							actionClickHandle(item.productId, 'status-switch', value);
						}}
					/>
				</div>
			);
		}}>

		</ButtonPermission>
	);
};

// 表格操作
const TableAction = (key, actionClickHandle) => {
	return (
		<div className={styles.action}>
			<div className={styles.item} onClick={() => actionClickHandle(key, 'detail')}>详情</div>
			<ButtonPermission code="productmanage/standardproduct/edit">
				<div className={styles.item} onClick={() => actionClickHandle(key, 'edit')}>编辑</div>
			</ButtonPermission>
		</div>
	);
};

class TableData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gotBtnPermissions: false,
			hasStatusPermission: false,
			hasEditPermission: false,
		};
	}

	componentDidMount() {
		this.initBtnPermission();
	}

	checkBtnPermission = (btnPremissions) => {
		let hasStatusPermission = btnPremissions.includes('productmanage/standardproduct/statusswitch');
		let hasEditPermission = btnPremissions.includes('productmanage/standardproduct/edit');
		this.setState({
			gotBtnPermissions: true,
			hasStatusPermission,
			hasEditPermission
		});
	}

	initBtnPermission = () => {
		let btnPremission = JSON.parse(getLocalStorage('btnPremission')) || [];
		if (btnPremission.length == 0) {
			http('/admin/menu/listMenus', {}, 'POST').then((response) => {
				if (response.status == 200) {
					btnPremission = this._getButtonUrlForChildren(response.result);
					this.checkBtnPermission(btnPremission);
				}
			}).catch((e) => {
				message.error(e.message);
			});
		} else {
			this.checkBtnPermission(btnPremission);
		}
	}

	_getButtonUrlForChildren = (treeData) => {
		let childrenEventKeys = [];
		for (let i = 0; i < treeData.length; i++) {
			if (treeData[i].menuType == '2' || treeData[i].menuType == '3') {
				childrenEventKeys.push(treeData[i].menuUrl);
			}
			if (treeData[i].childrens && treeData[i].childrens.length > 0) {
				const array = this._getButtonUrlForChildren(treeData[i].childrens);
				childrenEventKeys = Array.from(new Set([...childrenEventKeys, ...array]));
			}
		}
		return childrenEventKeys;
	};

	// 操作表格
	_actionHandle = (id, type, value) => {
		const { tableAction } = this.props;
		tableAction(id, type, value);
	}

	//处理表格数据
	_processingData = (data) => {
		return data;
	}

	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys) => {
			this._actionHandle(null, 'select', selectedRowKeys);
		}
	};

	render() {
		const { renderData, selectedRowKeys, scrollY } = this.props;
		this._rowSelection.selectedRowKeys = selectedRowKeys;
		if (!this.state.gotBtnPermissions) {
			return null;
		}
		return (
			<div className={styles.tableContainer} style={{ height: '100%' }}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey='productId'
					fixed={true}
					rowSelection={this._rowSelection}
					columns={columns(TableAction, this._actionHandle)}
					pagination={false}
					dataSource={this._processingData(renderData.dataList)}
					scroll={{ x: 'max-content', y: scrollY }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={this.props.paginationChange} />
			</div>
		);
	}
}


TableData.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
	selectedRowKeys: PropTypes.array,
	scrollY: PropTypes.string
};



export default XTableHOC(TableData);