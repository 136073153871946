import React, { useEffect, useState } from 'react';
import styles from './TableStyle.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, switchCell } from '@/components/TableCell';

const TableContent = ({
    renderData: {
        dataList = [],
        pageNum = 1,
        pageSize = 30,
        resultTotal = 1
    } = {},
    tableAction,
    paginationChange,
    batchKeys = []
}) => {

    const [keys, setKeys] = useState([]);

    useEffect(() => setKeys(batchKeys), [batchKeys]);

    const operateCell = (text, item) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => tableAction('record', item)}>操作日志</div>
            <div className={styles.item} onClick={() => tableAction('entered', item)}>已入住商户</div>
        </div>;
    };

    //表头
    const columns = [
        {
            title: '省份',
            dataIndex: 'provinceName',
            key: 'provinceName',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '城市',
            dataIndex: 'cityName',
            key: 'cityName ',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '入驻商家数',
            dataIndex: 'merchantEntryCount',
            key: 'merchantEntryCount',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '最后开通时间',
            dataIndex: 'openTime',
            key: 'openTime',
            align: 'center',
            width: '16%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => switchCell(text, () => tableAction('state', item))
        },
        {
            title: '操作',
            dataIndex: 'cityId',
            key: 'cityId',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: operateCell
        }
    ];

    // 表格复选输入
    const rowSelection = {
        selectedRowKeys: keys,
        onChange: keys => tableAction('batch', keys)
    };

    return (
        <div className={styles.tableContainer}>
            <XTableSelectionCount selectedNum={keys.length} onClean={() => tableAction('batch', [])} />
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowSelection={rowSelection}
                rowKey={record => record.cityId}
                columns={columns}
                dataSource={dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange} />
        </div>
    );
};
TableContent.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func,
    batchKeys: PropTypes.array
};
export default TableContent;