import React from 'react';
import styles from './ChooseCreate.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount, } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const columns = () => ([
    {
        title: '商户编号',
        dataIndex: 'merchantNo',
        key: 'merchantNo',
        width: '20%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '商户名称',
        dataIndex: 'merchantName',
        key: 'merchantName ',
        width: '30%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '商户类型',
        dataIndex: 'merchantType.value',
        key: 'merchantType.value',
        align: 'center',
        width: '20%',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '商户地址',
        dataIndex: 'addrDetail',
        key: 'addrDetail',
        align: 'center',
        width: '30%',
        onCell: tooltipStyle,
        render: tooltip
    },
]);


class RaisingCreate extends React.Component {

    state = {
        selectedRowKeys: [],
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data;
    }

    // 表格复选框操作回调
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys });
            this.props.tableAction(selectedRowKeys);
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] }, () => {
            this.props.tableAction(this.state.selectedRowKeys);
        });
    }


    render() {
        const { paginationChange } = this.props;
        const { dataList, pageNum, pageSize, resultTotal } = this.props.renderData;

        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;

        return (
            <div className={styles.container}>
                <div className={styles.table_box}>
                    <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        rowSelection={this._rowSelection}
                        rowKey={record => record.merchantId}
                        columns={columns()}
                        dataSource={this._takeDataSource(dataList)}
                        pagination={false}
                        scroll={{ y: 350 }}
                    />
                </div>
                <div className={styles.pagination_box}>
                    <XPagination
                        resultTotal={resultTotal}
                        pageSize={pageSize}
                        pageNum={pageNum}
                        onChange={paginationChange} />
                </div>
            </div>
        );
    }
}

RaisingCreate.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func,
};

export default RaisingCreate;