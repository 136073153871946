/**
 * 查看绑定用户
 */
import React from 'react';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { XInput, XOKButton } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './BindUserModal.module.scss';
import BindUserTable from '../pages/videocreate/components/BindUserTable';
import { getListVideoUsername } from '@/reducers/showmanage/channellist/actions';
// 模拟表格数据
const tableDataSource = [];
for (let i = 0; i < 20; i++) {
    tableDataSource.push({
        key: 'key' + i,
        c1: 'XD0012',
        c2: '张平',
        c4: '普通会员',
        c3: '福建省厦门思明区',
    });
}

class BindUserModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        keyword: '',
        pageNum: 1,
        pageSize: 10,
        isMajia: 1, // 是否为马甲号查询 0否、1是
    }

    componentDidMount() {
        const { pageNum, pageSize, isMajia } = this.state;
        this.props.getListVideoUsername({ pageNum, pageSize, isMajia });
    }

    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _inputChangeHandle = (value) => {
        this.setState({ keyword: value });
    }

    _searchHandle = () => {
        console.log('查看');
        const { pageNum, pageSize, keyword, isMajia } = this.state;
        this.props.getListVideoUsername({ keyword, pageNum, pageSize, isMajia });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        const { keyword, isCheck, isMajia } = this.state;
        if (!isCheck) {
            this.props.getListVideoUsername({ keyword: '', pageNum, pageSize, isMajia });
        } else {
            this.props.getListVideoUsername({ keyword, pageNum, pageSize, isMajia });
        }
    }

    render() {
        const { visible, confirmLoading, keyword } = this.state;

        return (
            <Modal
                width={860}
                centered
                title='会员查询列表'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.input}>
                        <XInput
                            style={{ width: '214px', marginLeft: '-10px' }}
                            inputStyle={{ width: '192px' }}
                            label=''
                            placeholder='会员账号、姓名'
                            onChange={this._inputChangeHandle}
                            value={keyword}
                        />
                        <XOKButton style={{ width: '120px' }} label='会员查询列表' onClick={() => {
                            this.setState({ isMajia: 0 }, () => {
                                this._searchHandle();
                            });
                        }} />
                        <XOKButton style={{ width: '120px', marginLeft: '20px' }} label='马甲号查询' onClick={() => {
                            this.setState({ isMajia: 1 }, () => {
                                this._searchHandle();
                            });
                        }} />
                    </div>
                    <div className={styles.table}>
                        <BindUserTable tableAction={this.props.tableAction} paginationChange={this._paginationChange} renderData={this.props.listVideoUsername} />
                    </div>
                </div>
            </Modal>
        );
    }
}

BindUserModal.propTypes = {
    data: PropTypes.array, // 需要修改魅力值的会员对象数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    tableAction: PropTypes.func,

    getListVideoUsername: PropTypes.func,
    listVideoUsername: PropTypes.object
};

const mapStateToProps = (state) => ({
    listVideoUsername: state.channellist.listVideoUsername
});

export default connect(mapStateToProps, { getListVideoUsername })(BindUserModal);