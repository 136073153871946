// 版本列表
export const GET_RELEASE_LIST = 'SYSTEMMANAGE/GET_ALLOCATION_LIST';
export const SET_RELEASE_LIST = 'SYSTEMMANAGE/SET_RELEASE_LIST';
// 版本详情
export const GET_RELEASE_DETAIL = 'SYSTEMMANAGE/GET_RELEASE_DETAIL';
export const SET_RELEASE_DETAIL = 'SYSTEMMANAGE/SET_RELEASE_DETAIL';
// 版本终端
export const GET_RELEASE_TERMINAL = 'SYSTEMMANAGE/GET_RELEASE_TERMINAL';
export const SET_RELEASE_TERMINAL = 'SYSTEMMANAGE/SET_RELEASE_TERMINAL';
// 版本系统
export const GET_RELEASE_SYSTEM = 'SYSTEMMANAGE/GET_RELEASE_SYSTEM';
export const SET_RELEASE_SYSTEM = 'SYSTEMMANAGE/SET_RELEASE_SYSTEM';
// 版本终端
export const GET_RELEASE_STATE = 'SYSTEMMANAGE/GET_RELEASE_STATE';
export const SET_RELEASE_STATE = 'SYSTEMMANAGE/SET_RELEASE_STATE';
// 版本更新
export const GET_RELEASE_UPDATE = 'SYSTEMMANAGE/GET_RELEASE_UPDATE';
export const SET_RELEASE_UPDATE = 'SYSTEMMANAGE/SET_RELEASE_UPDATE';