/**
 *  商户业务统计
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './main';

const MerchantBusiness = ({ history }) => {
    return <Main history={history} />;
};

MerchantBusiness.propTypes = {
    history: PropTypes.object
};

export default MerchantBusiness;