/**
 * 商品列表信用值设置弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './ShowRice.module.scss';
import http from '@/assets/api/http';
import { Modal, Table, message, InputNumber } from 'antd';
import { XTableSelectionCount, XInputNum, XOKButton, XInfoLabel } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';
import model from '@/reducers/productmanage/list/model';
import { connect } from 'react-redux';

class ShowRice extends React.Component {

    static propTypes = {
        closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
        getGoodsShowRices: PropTypes.func,
        updateList: PropTypes.func,
        singleUpdate: PropTypes.func,
        goodsShowRices: PropTypes.object,
        data: PropTypes.object,
        getGoodsShowBeans: PropTypes.func,
        updateBeabList: PropTypes.func,
        singleUpdateBean: PropTypes.func,
        goodsShowBeans: PropTypes.object,
    }

    state = {
        selectedRowKeys: [],
        setAllNum: 0,
        tableData: [],
        confirmLoading: false
    }

    _columns = () => [
        {
            title: 'SKU编码',
            dataIndex: 'skuNo',
            key: 'skuNo',
            align: 'center',
            width: '20%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品属性',
            dataIndex: 'spec',
            key: 'spec',
            align: 'center',
            width: '20%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '销售价（元）',
            dataIndex: 'salePrice',
            key: 'salePrice',
            width: '20%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '结算价（元）',
            dataIndex: 'settlePrice',
            key: 'settlePrice',
            width: '20%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: this._renderInputHeader(),
            dataIndex: 'riceBeanNum',
            key: 'riceBeanNum',
            align: 'center',
            width: '20%',
            render: this._renderInputNum
        },
    ];

    componentDidMount() {
        const { getGoodsShowRices, getGoodsShowBeans, data: { id = '', type = '' } = {} } = this.props;
        switch (type) {
            case 'rice':
                getGoodsShowRices({ goodsId: id });
                break;
            case 'bean':
                getGoodsShowBeans({ goodsId: id });
                break;
            default:
                break;
        }
    }

    //渲染输入框
    _renderInputNum = (text, item) => {
        const { singleUpdate, singleUpdateBean, data: { type = '' } = {} } = this.props;
        const { selectedRowKeys } = this.state;
        let keyName = '';
        let funcName;
        switch (type) {
            case 'rice':
                keyName = 'showRiceNum';
                funcName = singleUpdate;
                break;
            case 'bean':
                keyName = 'popularityNum';
                funcName = singleUpdateBean;
                break;
            default:
                keyName = 'showRiceNum';
                funcName = singleUpdate;
                break;
        }
        const riceNode = <InputNumber
            value={item[keyName]}
            min={0}
            max={9999}
            onChange={(value) => {
                if (typeof (value) === 'number') {
                    const keys = [...selectedRowKeys, item.skuId];
                    const newKeys = new Set(keys);
                    this.setState({
                        selectedRowKeys: [...newKeys]
                    });
                    funcName({ skuId: item.skuId, [keyName]: parseInt(value) });
                } else {
                    message.info('请输入数字');
                }
            }}
        />;
        return riceNode;
    }

    // 返回操作
    _cancelHandle = () => {
        this.props.closeMode(false);
    }

    // 表格复选输入
    _rowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.setState({ selectedRowKeys });
            }
        };
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    //批量设置
    _onSetAll = () => {
        const { goodsShowRices, goodsShowBeans, data: { type = '' }, updateList, updateBeabList = {} } = this.props;
        const { setAllNum } = this.state;
        let keys = [];
        switch (type) {
            case 'rice':
                keys = goodsShowRices.skuList.map(item => {
                    return item.skuId;
                });
                break;
            case 'bean':
                keys = goodsShowBeans.skuList.map(item => {
                    return item.skuId;
                });
                break;
            default:
                keys = goodsShowRices.skuList.map(item => {
                    return item.skuId;
                });
                break;
        }
        this.setState({ selectedRowKeys: keys }, () => {
            switch (type) {
                case 'rice':
                    updateList(setAllNum);
                    break;
                case 'bean':
                    updateBeabList(setAllNum);
                    break;
                default:
                    updateList(setAllNum);
                    break;
            }
        });
    }

    _updateReq = () => {
        const { selectedRowKeys } = this.state;
        const { goodsShowRices, goodsShowBeans, data: { id = '', type = '' } = {}, closeMode } = this.props;
        let reqList = [];
        let url = '';
        let tip = '';
        if (!selectedRowKeys.length) {
            message.warning('请勾选商品');
            return;
        }

        switch (type) {
            case 'rice':
                reqList = (goodsShowRices.skuList || []).map(item => {
                    return { showRiceNum: item.showRiceNum, skuId: item.skuId };
                });
                url = '/goods/xiumi/upSKUXiumi';
                tip = '更新SKU信用值数量操作成功';
                break;
            case 'bean':
                reqList = (goodsShowBeans.skuList || []).map(item => {
                    return { popularityNum: item.popularityNum, skuId: item.skuId };
                });
                url = '/goods/popularity/upSKUPopularity';
                tip = '更新SKU人气值数量操作成功';
                break;
            default:
                reqList = (goodsShowRices.skuList || []).map(item => {
                    return { showRiceNum: item.showRiceNum, skuId: item.skuId };
                });
                url = '/goods/xiumi/upSKUXiumi';
                tip = '更新SKU信用值数量操作成功';
                break;
        }

        this.setState({ confirmLoading: true }, () => {
            http(url, { goodsId: id, skuList: reqList }, 'POST')
                .then(() => {
                    message.success(tip);
                    this.setState({ confirmLoading: false });
                    closeMode(true);
                }).catch((error) => {
                    message.error(error.message);
                    this.setState({ confirmLoading: false });
                });
        });
    }

    _renderInputHeader = () => {
        const { data: { type = '' } = {} } = this.props;
        switch (type) {
            case 'rice':
                return '赠送信用值';
            case 'bean':
                return '赠送人气值';
            default:
                return '赠送信用值';
        }
    }

    render() {
        const { goodsShowRices, goodsShowBeans, data: { type = '' } = {} } = this.props;
        const { selectedRowKeys, setAllNum, confirmLoading } = this.state;
        let dataSource = [];
        let modeTitle = '';
        switch (type) {
            case 'rice':
                dataSource = goodsShowRices.skuList;
                modeTitle = '设置赠送信用值';
                break;
            case 'bean':
                dataSource = goodsShowBeans.skuList;
                modeTitle = '设置赠送人气值';
                break;
            default:
                dataSource = goodsShowRices.skuList;
                modeTitle = '设置赠送信用值';
                break;
        }
        return (
            <Modal
                width={1000}
                centered
                title={modeTitle}
                visible={true}
                bodyStyle={{ paddingBottom: 20 }}
                confirmLoading={confirmLoading}
                onOk={this._updateReq}
                onCancel={this._cancelHandle}
                destroyOnClose={true}
                okText='确定'
                cancelText='取消'
                keyboard={true}
                maskClosable={false}
            >
                <div className={styles.container}>
                    <XInfoLabel label='商品名称：' labelWith={80} style={{ marginBottom: '10px' }} value={goodsShowRices.goodsName} />
                    <XInfoLabel label='商品货号：' labelWith={80} style={{ marginBottom: '10px' }} value={goodsShowRices.goodsNo} />
                    <div className={styles.showRiceSet}>
                        <XInputNum
                            inputStyle={{ width: '200px' }}
                            label='批量设置：'
                            placeholder='请输入'
                            min={0}
                            max={9999}
                            onChange={(e) => {
                                if (e > 0) {
                                    this.setState({ setAllNum: parseInt(e) });
                                } else {
                                    this.setState({ setAllNum: 0 });
                                }
                            }}
                            value={setAllNum}
                        />
                        <XOKButton
                            style={{ width: '90px' }}
                            label='设置'
                            onClick={this._onSetAll}
                        />
                    </div>
                    <div className={styles.tableBox}>
                        <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={record => record.skuId}
                            rowSelection={this._rowSelection()}
                            columns={this._columns()}
                            pagination={false}
                            dataSource={dataSource}
                            scroll={{ y: 400 }}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        goodsShowRices: state.productListData.goodsShowRices,
        goodsShowBeans: state.productListData.goodsShowBeans,
    };
};
export default connect(mapStateToProps, { ...model.actions })(ShowRice);