/**
 * 删除
 */
import React from 'react';
import { Modal, message, Icon, } from 'antd';
import PropTypes from 'prop-types';
import styles from './PopconfirmModal.module.scss';
import http from '@/assets/api/http';

class DeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // title: this.props.title,
            visible: this.props.visible,
            inconText: '',
        };
    }

    componentDidMount() {
        //删除组件时做模板使用情况的校验和提醒
        const { componentId, category } = this.props.data;
        http('custom/userAppComponents/isExistUseComponentId', { componentId, category }).then((res = {}) => {
            if (res.result == '1') {
                this.setState({ inconText: '当前组件被模板使用，删除会影响模板使用' });
            }
        });
    }

    _handleOk = () => {
        const { id, } = this.props.data;
        http('/custom/userAppComponents/del', { id }).then((res) => {
            message.success('删除成功');
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch((e = {}) => {
            message.error(e.message);
            this.setState({
                visible: false,
            });
            this.props.closeMode();
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, inconText } = this.state;
        return (
            <Modal
                width={390}
                centered
                // title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='继续'
            >
                <div className={styles.container}>
                    <div className={styles.title}>
                        <Icon type="exclamation-circle" style={{ fontSize: '23px' }} />
                        <h4>确定要移除此组件吗？</h4>
                    </div>
                    {inconText == '' ? null : <span>{inconText}</span>}
                </div>
            </Modal>
        );

    }
}

DeleteModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default DeleteModal;