/**
 *  评论管理 - 商品评论
 * */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, Cascader, message } from 'antd';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import http from '@/assets/api/http';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XPagination } from '@/components/xqxc_ui';
import { takeDataSource } from './tableContents/TableData';
import { getCommentList, getProductClassify } from '@/reducers/commentmanage/productcomment/actions';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

const TableAction = (id, actionClickHandle) => {
  return (
    <div className={styles.action}>
      <a className={styles.item} onClick={() => actionClickHandle(id, 'DETAILS_INFO')}>查看</a>
    </div>
  );
};
class ProductComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: null,
      goodsName: null,
      content: null,
      orderNum: null,
      options: [],
      valueId: [],
      pageNum: 1,
      pageSize: 10,
    };
  }
  componentDidMount() {
    this.props.keepSecondNavBreadcrumb();
    // this.props.getCommentList({pageNum: 1, pageSize: 10});
    this._getProductClassify({ categoryGrade: 1, parentId: 0 });
  }
  // 获取三级联动 一级
  _getProductClassify = (param, targetOption) => {
    http('/goods/category/linkage', param, 'POST')
      .then((res) => {
        var arr = res.result.map(i => {
          return {
            ...i,
            isLeaf: i.categoryGrade !== 3 ? false : true,
          };
        });
        if (param.categoryGrade === 1) {
          this.setState({ options: arr || [] });
        } else {
          targetOption.categoryInfoList = arr || [];
          this.setState({
            options: [
              ...this.state.options,
              // targetOption,
            ],
          }, () => {
            console.log(this.state.options);
          });
        }

      }).catch((reject) => {
        message.error(reject.message);
      });
  }
  loadData = selectedOptions => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    if (targetOption.categoryGrade !== 3) {
      targetOption.loading = true;
      var param = {
        categoryGrade: Number(targetOption.categoryGrade) + 1,
        parentId: targetOption.id,
      };
      setTimeout(() => {
        targetOption.loading = false;
        this._getProductClassify(param, targetOption);
      }, 1000);
    }
  };
  // 查询触发
  _searchHandle = (useCache) => {
    const { categoryId, goodsName, content, orderNum, pageNum, pageSize } = this.state;
    this.searchCache = {
      categoryId,
      goodsName,
      content,
      orderNum,
      pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
      pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
    };
    this.props.getCommentList(this.searchCache);
    KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
  }
  // 分页
  paginationChange = (pageSize, pageNum) => {
    this.setState({ pageSize, pageNum });
    const { categoryId, goodsName, content, orderNum } = this.state;
    this.searchCache = {
      categoryId,
      goodsName,
      content,
      orderNum,
      pageNum,
      pageSize
    };
    this.props.getCommentList(this.searchCache);
    KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
  }
  // 商品分类
  onChange = e => {
    var id = null;
    if (e.length > 0) {
      id = e[e.length - 1].toString();
    }
    this.setState({
      categoryId: id,
      valueId: e,
    });
  }
  // 重置触发
  _goodSresetHandle = () => {
    this.setState({ valueId: [], categoryId: null, goodsName: '', orderNum: '', content: '' }, () => {
      KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
    });
    // this.props.getCommentList();
  }

  // 表格操作功能回调
  _actionClickHandle = (record, type) => {
    const { history, addOneBreadcrumbPath } = this.props;
    if (type === 'DETAILS_INFO') {
      addOneBreadcrumbPath({
        title: '商品评论详情',
        path: `/home/commentmanage/productcomment/contentInfo/${record.goodsId}/${record.id}`
      });
      history.push({
        pathname: `/home/commentmanage/productcomment/contentInfo/${record.goodsId}/${record.id}`
      });
    }
  }
  _takeColumns = () => ([
    {
      title: '商品分类',
      dataIndex: 'categoryName',
      key: 'categoryName',
      width: '10%',
      align: 'center',
      render: (text) => (this._renderTextCell(text))
    },
    {
      title: '商品名称',
      dataIndex: 'goodsName',
      key: 'goodsName',
      width: '15%',
      align: 'center',
      render: (text) => {
        return <Ellipsis tooltip={text} lines={1}>{text}</Ellipsis>;
      }
    },
    {
      title: '评论内容',
      dataIndex: 'content',
      key: 'content',
      width: '20%',
      align: 'center',
      render: (text) => {
        return <Ellipsis tooltip={text} lines={1}>{text}</Ellipsis>;
      }
    },
    {
      title: '关联订单号',
      dataIndex: 'sourceId',
      key: 'sourceId',
      width: '15%',
      align: 'center',
      render: (text) => (this._renderTextCell(text))
    },
    {
      title: '评论人',
      dataIndex: 'accountName',
      key: 'accountName',
      width: '10%',
      align: 'center',
      render: (text) => (this._renderTextCell(text))
    },
    {
      title: '评论时间',
      dataIndex: 'gmtCreate',
      key: 'gmtCreate',
      width: '15%',
      align: 'center',
      render: (text) => (this._renderTextCell(text))
    },
    {
      title: '操作',
      dataIndex: 'key',
      key: 'action',
      align: 'center',
      width: '10%',
      render: (text, item) => TableAction(item, this._actionClickHandle)
    }
  ]);
  _renderTextCell = (text) => {
    return (
      <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
        {text}
      </div>
    );
  };
  render() {
    // const { columnsData, listData, tableData, tableColumns } = this.props;
    const { valueId, options, goodsName, content, orderNum } = this.state;
    const { pageNum, pageSize, resultTotal, dataList } = this.props.commentList;
    return (
      <KeepAlive render={(state) => {
        if (!this.state.__aliveInit) {
          this.setState({ ...state, __aliveInit: true }, () => {
            this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
          });
        }
      }}>
        <div className={styles.flexBoxContainer}>
          <SearchBox>
            <SearchItem>
              <div style={{ display: 'inlne-block', width: 320, marginRight: 20 }}>
                <span style={{ marginRight: 10 }}>商品分类</span>
                <Cascader
                  style={{ width: 250 }}
                  options={options || []}
                  loadData={this.loadData}
                  onChange={this.onChange}
                  fieldNames={{ label: 'categoryName', value: 'id', children: 'categoryInfoList' }}
                  changeOnSelect
                  value={valueId}
                  placeholder="请选择"
                />
              </div>
            </SearchItem>
            <SearchItem>
              <XInput
                label='商品名称'
                placeholder='商品名称'
                value={goodsName}
                bindThis={this}
                bindName='goodsName'
              />
            </SearchItem>
            <SearchItem>
              <XInput
                label='评论内容'
                placeholder='评论内容'
                value={content}
                bindThis={this}
                bindName='content'
              />
            </SearchItem>
            <SearchItem>
              <XInput
                label='订单编号'
                placeholder='订单编号'
                value={orderNum}
                bindThis={this}
                bindName='orderNum'
              />
            </SearchItem>
            <SearchItem>
              <XOKButton
                label='查询'
                onClick={this._searchHandle}
              />
              <XCancelButton
                label='重置'
                onClick={this._goodSresetHandle}
              />
            </SearchItem>
          </SearchBox>
          <div className={styles.tableContainer}>
            <Table
              rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
              rowKey={(record, index) => index}
              columns={this._takeColumns()}
              dataSource={takeDataSource(dataList)}
              pagination={false}
              scroll={{ y: true }}
            />
            <XPagination
              resultTotal={resultTotal}
              pageSize={pageSize}
              pageNum={pageNum}
              onChange={this.paginationChange}
            />
          </div>
        </div>
      </KeepAlive>
    );
  }
}

ProductComment.propTypes = {
  history: PropTypes.object, // 路由history对象
  addOneBreadcrumbPath: PropTypes.func,
  keepSecondNavBreadcrumb: PropTypes.func,
  getCommentList: PropTypes.func,
  commentList: PropTypes.object,
  productClassify: PropTypes.any,
  getProductClassify: PropTypes.func,
};

const mapStateToProps = (state) => ({
  commentList: state.productcomment.commentList,
  productClassify: state.productcomment.productClassify,
});
export default connect(mapStateToProps, { getCommentList, addOneBreadcrumbPath, keepSecondNavBreadcrumb, getProductClassify })(ProductComment);
