export const GET_CARDTICKETLIST_DATA = 'CARDTICK/GET_CARDTICKETLIST_DATA'; // 卡劵管理列表
export const SET_CARDTICKETLIST_DATA = 'CARDTICK/SET_CARDTICKETLIST_DATA'; // 卡劵管理列表

export const GET_CARDTICKDETAILS_DATA = 'CARDTICK/GET_CARDTICKDETAILS_DATA'; // 代言任务编辑、详情获取数据
export const SET_CARDTICKDETAILS_DATA = 'CARDTICK/SET_CARDTICKDETAILS_DATA'; //代言任务编辑、详情获取数据

export const GET_CARDTICKDROPDIWN_DATA = 'CARDTICK/GET_CARDTICKDROPDIWN_DATA'; // 卡劵管理下拉框
export const SET_CARDTICKDROPDIWN_DATA = 'CARDTICK/SET_CARDTICKDROPDIWN_DATA'; //卡劵管理下拉框

export const GET_RAISEALL_DATA = 'CARDTICK/GET_RAISEALL_DATA'; // 卡劵管理新建-使用门店、和全选复选、商品范围、有限期固定时长
export const SET_RAISEDATA_DATA = 'CARDTICK/SET_RAISEDATA_DATA'; //卡劵管理新建使用门店
export const SET_ALL_DATA = 'CARDTICK/SET_ALL_DATA'; //卡劵管理新建-全选复选
export const SET_REWARD_DATA = 'CARDTICK/SET_REWARD_DATA'; //卡劵管理新建-商品范围
export const SET_COUPONNAME_DATA = 'CARDTICK/SET_COUPONNAME_DATA'; //卡劵管理新建-有限期
export const SET_COUPONNAMESELECT_DATA = 'CARDTICK/SET_COUPONNAMESELECT_DATA'; //卡劵管理新建-有限期固定时长

export const SET_RAISETABALE_DATA = 'CARDTICK/SET_RAISETABALE_DATA'; //代言任务新建选择商品表格数据
export const GET_RAISETABALE_DATA = 'CARDTICK/GET_RAISETABALE_DATA'; //代言任务新建选择商品表格数据

export const GET_CARDTICKSHOPMODE_DATA = 'CARDTICK/GET_CARDTICKSHOPMODE_DATA'; //代言任务新建选择商品表格数据
export const SET_CARDTICKSHOPMODE_DATA = 'CARDTICK/SET_CARDTICKSHOPMODE_DATA'; //代言任务新建选择商品表格数据

export const GET_CARDTICKALLGOODS_DATA = 'CARDTICK/GET_CARDTICKALLGOODS_DATA'; //所有门店商品表格数据
export const SET_CARDTICKALLGOODS_DATA = 'CARDTICK/SET_CARDTICKALLGOODS_DATA'; //所有门店商品表格数据