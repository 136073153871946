import { createModel } from '@/store/tools';

const model = {
    namespace: 'livelist',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getLiveList': T('getLiveList'),
            'getSignList': T('getSignList'),
            'getLiveState': T('getLiveState'),
            'getDeleteState': T('getDeleteState')
        },
        actions: {
            'getLiveList': A('getLiveList'),
            'getSignList': A('getSignList'),
            'getLiveState': A('getLiveState'),
            'getDeleteState': A('getDeleteState'),
        },
        sagas: {
            'getLiveList': S('getLiveList', '/show/anchor/listLiveBusniess'),
            'getSignList': S('getSignList', '/show/anchor/listLiveAccountSignUp'),
            'getLiveState': S('getLiveState', '/enum/BuniessLiveStateEnum'),
            'getDeleteState': S('getDeleteState', '/enum/LiveBuniessDeleteStateEnum')
        },
        reducers: {
            'liveList': R('getLiveList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'signList': R('getSignList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'liveStateList': R('getLiveState', []),
            'deleteStateList': R('getDeleteState', []),
        }
    })
};
export default createModel(model);