/**
 * 活动时间
 */
import React, { Fragment } from 'react';
import { Modal, Radio, Icon, message, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './ActivityTime.module.scss';
import { XInput, XUpload, XDatePicker, } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;
const { TextArea } = Input;
class ActivityTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,

            typeName: '',//活动名称
            inputlabel: '',//活动标签
            activityStartTime: null, // 活动报名开始时间
            startPickerDisabled: false, // 开始时间不可选
            activityEndTime: null, // 活动报名开始时间
            endPickerDisabled: false, //结束时间不可选
            launchTime: null, // 活动发布开始时间
            launchTimePickerDisabled: false, //活动发布开始时间不可选

            areaValue: '',//延长理由
            DatePicker: this.props.data.type, //修改与发布区分
        };

    }

    componentDidMount() {
       
    }

    _areaChangeHandle = (e) => {
        this.setState({ areaValue: e.target.value });
    }

    //修改与发布区分
    ActivityDatePicker = () => {
        const { activityStartTime, startPickerDisabled, activityEndTime,
            endPickerDisabled, launchTime, launchTimePickerDisabled } = this.state;
        if (this.state.DatePicker == 'signTime') {
            return (
                <Fragment>
                    <XDatePicker
                        style={{ marginBottom: '20px', color: '#252525' }}
                        label='活动报名开始时间'
                        value={activityStartTime}
                        disabled={startPickerDisabled}
                        bindThis={this}
                        bindName='activityStartTime'
                    />
                    <XDatePicker
                        style={{ marginBottom: '20px', color: '#252525' }}
                        label='活动报名结束时间'
                        value={activityEndTime}
                        disabled={endPickerDisabled}
                        bindThis={this}
                        bindName='activityEndTime'
                    />
                </Fragment>
            );
        } else {
            return (
                <XDatePicker
                    style={{ marginBottom: '20px', color: '#252525' }}
                    label='活动发布开始时间'
                    value={launchTime}
                    disabled={launchTimePickerDisabled}
                    bindThis={this}
                    bindName='launchTime'
                />
            );
        }
    }

    _handleOk = () => {
        const { data } = this.props;
        this.setState({
            visible: false,
        });
        this.props.closeMode();
        // http(`/show/showClass/${method}`, {

        // }, 'POST').then(() => {

        // }).catch(() => {
        //     message.warn('请求错误');
        // });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title, typeName, inputlabel, areaValue, } = this.state;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '408px', marginBottom: '20px' }}
                        inputStyle={{ width: '340px' }}
                        label='活动名称'
                        placeholder='请输入活动名称'
                        value={typeName}
                        bindThis={this}
                        bindName='typeName'
                    />
                    <XInput
                        style={{ width: '382px', marginBottom: '20px' }}
                        inputStyle={{ width: '340px' }}
                        label='活动标签'
                        placeholder='请输入活动标签'
                        value={inputlabel}
                        bindThis={this}
                        bindName='inputlabel'
                    />
                    {this.ActivityDatePicker()}
                    <div className={styles.result}>
                        <span>延长理由</span>
                        <p>
                            <TextArea
                                className={styles.textArea}
                                placeholder='系统推送消息给商家'
                                rows={4}
                                onChange={this._areaChangeHandle}
                                value={areaValue}
                            />
                        </p>
                    </div>
                </div>
            </Modal>
        );

    }
}

ActivityTime.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default ActivityTime;