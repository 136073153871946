/**
 * 【订单交易明细页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import ShopOrder from './shoporder';
import ServiceOrder from './serviceorder';
import { XTabNav } from '@/components/xqxc_ui';
import styles from './index.module.scss';


import KeepAlive from '@/routes/KeepAlive';

class Main extends React.Component {
    state = {
        tab: [
            { id: 1, label: '商城订单' },
            { id: 2, label: '服务订单' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
    }

    componentDidMount() {
    }
    _tabChangeHandle = e => {
        this.setState({ curTabID: e });
    }
    render() {
        const { curTabID } = this.state;
        const { history } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={styles.tableContainer}>
                    {curTabID == 1 ? <ShopOrder history={history}></ShopOrder> : <ServiceOrder history={history}></ServiceOrder>}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
};
const mapStateToProps = (state) => {
    return ({
    });
};
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);