import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import TionGift from '../tiongift';
import { XTabNav } from '@/components/xqxc_ui';
import SupplyClass from './SupplyClass';

class Main extends React.Component {
    state = {
        tab: [
            { id: 1, label: '人气值攻略分类' },
            { id: 2, label: '供应链分类' },
        ],
        curTabID: 1
    }

    // Tab导航切换操作回调 
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        switch (this.state.curTabID) {
            case 1:
                return <TionGift curTabID={this.state.curTabID} />; // 人气值攻略分类
            case 2:
                return <SupplyClass curTabID={this.state.curTabID} />;
            default:
                return <TionGift curTabID={this.state.curTabID} />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                {/* <div className={styles.tabscurt}>人气值攻略分类</div> */}
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={styles.flexBoxContainer} style={{ marginTop: '10px' }}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
};


export default Main;
