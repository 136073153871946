import React from 'react';
import PropTypes from 'prop-types';
import styles from './TableMain.module.scss';
import { Table } from 'antd';
import { XPagination, XMediaFn, } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

class TableMain extends React.Component {

    _columns = () => [
        {
            title: '手机号码',
            dataIndex: 'accountName',
            key: 'accountName',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '主播昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '封面',
            dataIndex: 'coverPagePath',
            key: 'coverPagePath',
            align: 'center',
            width: '10%',
            render: ImgCell
        },
        {
            title: '审核状态',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '提交时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '审核人',
            dataIndex: 'operator',
            key: 'operator',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '审核时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '10%',
            render: (id, record) => this._tableAction(id, record)
        }
    ];

    _tableAction = (id, record) => {
        if (record.state === '审批中') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => this.props.tableAction(id, record, 'audit')}>审核</div>
                </div>
            );
        } else {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => this.props.tableAction(id, record, 'detail')}>查看详情</div>
                </div>
            );
        }
    };

    _takeDataSource = (data) => {
        if (data && data.dataList) {
            return data.dataList;
        } else {
            return [];
        }
    }

    render() {
        const { renderData, paginationChange } = this.props;

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(renderData)}
                    rowKey={record => record.id}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={paginationChange} />
            </div>
        );
    }
}

TableMain.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

export default TableMain;