/**
 * [头像昵称详情]
 * @author: Fu Xiaochun
 * @date: 2023-03-16 
 */

import React, { useEffect, useState } from 'react';
import styles from './InfoModal.module.scss';
import PropTypes from 'prop-types';
import { Button, Input, InputNumber, message, Modal, Radio } from 'antd';
import { XRangePicker, XMediaFn } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

function InfoModal(props){

    const id = props.data.id;
    const [detail, setDetail] = useState({});
    const [avatarCheck, setAvatarCheck] = useState('NOT_AUDIT');
    const [nicknameCheck, setNicknameCheck] = useState('NOT_AUDIT');
    const [auditOpinion, setAuditOpinion] = useState();  // 审核意见

    const title = {
        detail: '详情',
        audit: '审核详情',
    };

    useEffect(()=>{
        getDetail();
    }, []);

    const getDetail = ()=>{
        http('/user/userAvatarNicknameAudit/detail/'+id).then(res=>{
            setDetail(res.result);
            initData(res.result);
        }).catch(err=>{
            message.error(err.message);
        });
    };

    const initData = (data)=>{
        setAuditOpinion(data.auditOpinion);
        setAvatarCheck(data.avatarAuditState);
        setNicknameCheck(data.nicknameAuditState);
    };

    const onAuditOpinionChange = (e)=>{
        let val = e.target.value || '';
        setAuditOpinion(val.trim());
    };

    const validate = ()=>{
        if(detail.auditType == 'USER_AVATAR_NICKNAME' && (avatarCheck == 'NOT_AUDIT' || nicknameCheck == 'NOT_AUDIT')){
            message.error('请选择头像或昵称的审核结果');
            return false;
        }
        if(detail.auditType == 'USER_AVATAR' && avatarCheck == 'NOT_AUDIT'){
            message.error('请选择头像的审核结果');
            return false;
        }
        if(detail.auditType == 'USER_NICKNAME' && nicknameCheck == 'NOT_AUDIT'){
            message.error('请选择昵称的审核结果');
            return false;
        }
        if(detail.auditType == 'USER_AVATAR_NICKNAME' && (avatarCheck == 'FAIL_PASS' || nicknameCheck == 'FAIL_PASS') && !auditOpinion){
            message.error('请输入审核原因！');
            return false;
        }
        if(detail.auditType == 'USER_AVATAR' && avatarCheck == 'FAIL_PASS' && !auditOpinion){
            message.error('请输入审核原因！');
            return false;
        }
        if(detail.auditType == 'USER_NICKNAME' && avatarCheck == 'FAIL_PASS' && !auditOpinion){
            message.error('请输入审核原因！');
            return false;
        }
        return true;
    };

    const onOk = ()=>{
        if(!validate()){
            return false;
        }
        let formData = {
            id: props.type == 'create' ? undefined : props.data.id,
            avatarAuditState: avatarCheck,
            nicknameAuditState: nicknameCheck,
            auditOpinion: auditOpinion,
            nickname: detail.nickname,
            avatar: detail.avatar
        };

        if(detail.auditType == 'USER_AVATAR'){
            formData.nicknameAuditState = avatarCheck;
        }
        if(detail.auditType == 'USER_NICKNAME'){
            formData.avatarAuditState = nicknameCheck;
        }

        http('/user/userAvatarNicknameAudit/audit', formData).then(res=>{
            message.success('操作成功！');
            props.onOk();
        }).catch(err=>{
            message.error(err.message);
        });
    };

    const onClose = ()=>{
        props.onClose();
    };

    const renderFooter = ()=>{
        if(props.type == 'detail'){
            return [
                <Button type="primary" key='0' onClick={onClose}>关闭</Button>
            ];
        }
        return [
            <Button type="default" key='0' onClick={onClose}>取消</Button>,
            <Button type="primary" key='1' onClick={onOk}>确定</Button>,
        ];
    };

    const onRaidoChange = (e, type)=>{
        let val = e.target.value;
        if(type == 'avatar'){
            setAvatarCheck(val);
        }else if(type == 'nickname'){
            setNicknameCheck(val);
        }
    };

    return (
        <Modal title={title[props.type]} visible={true} centered width="800px" footer={renderFooter()} onCancel={onClose} >
            <div className={styles.formBox}>
                <div className={styles.formItem}>
                    <div className={styles.label}>会员账号：</div>
                    <div className={styles.info}>{detail.accountName}</div>
                </div>
                {['USER_AVATAR_NICKNAME', 'USER_NICKNAME'].includes(detail.auditType) 
                && <div className={styles.formItem}>
                    <div className={styles.label}>昵称：</div>
                    <div className={styles.info}>{detail.nickname}</div>
                </div>}
                
                {['USER_AVATAR_NICKNAME', 'USER_AVATAR'].includes(detail.auditType) 
                && <div className={styles.formItem}>
                    <div className={styles.label}>头像：</div>
                    <div className={styles.info}>
                        <XMediaFn dataType='image' dataSource={detail.avatar} />
                    </div>
                </div>}
                
                <div className={styles.formItem}>
                    <div className={styles.label}>修改类型：</div>
                    <div className={styles.info}>{detail.auditTypeDesc}</div>
                </div>
                
                {['USER_AVATAR_NICKNAME', 'USER_NICKNAME'].includes(detail.auditType) && <div className={styles.formItem}>
                    <div className={styles.label}>昵称审核：</div>
                    <div className={styles.info}>
                        <Radio.Group onChange={e=>{onRaidoChange(e, 'nickname');}} value={nicknameCheck} disabled={props.type == 'detail'}>
                            <Radio className={styles.radio} value='PASS_AUDIT'>通过</Radio>
                            <Radio className={styles.radio} value='FAIL_PASS'>不通过</Radio>
                        </Radio.Group>
                    </div>
                </div>}

                {['USER_AVATAR_NICKNAME', 'USER_AVATAR'].includes(detail.auditType) && <div className={styles.formItem}>
                    <div className={styles.label}>头像审核：</div>
                    <div className={styles.info}>
                        <Radio.Group onChange={e=>{onRaidoChange(e, 'avatar');}} value={avatarCheck} disabled={props.type == 'detail'}>
                            <Radio className={styles.radio} value='PASS_AUDIT'>通过</Radio>
                            <Radio className={styles.radio} value='FAIL_PASS'>不通过</Radio>
                        </Radio.Group>
                    </div>
                </div>}
                
                <div className={styles.formItem}>
                    <div className={styles.label}>审核原因：</div>
                    <div className={styles.info}>
                        <Input.TextArea disabled={props.type == 'detail'} className={styles.txts} value={auditOpinion} onChange={onAuditOpinionChange} />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

InfoModal.propTypes = {
    data: PropTypes.object,
    type: PropTypes.string,
    onOk: PropTypes.func,
    onClose: PropTypes.func,
};

export default InfoModal;