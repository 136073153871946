import * as T from './actiontypes';

/**
 * 获取'视频评论列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {sting} pageNum 固定为视频"VIDEO"
 */
export const getVideoTableSource = (pageSize, pageNum, feedType) => ({
    type: T.GET_VIDEO_DATA,
    payload: {
        pageSize,
        pageNum,
        feedType
    }
});

/**
 * 获取'视频评论详情'数据源(saga)
 * @param {number} feedCommentId  获取某个评论的回复详情对应的id
 */
export const getVideoDetails = (feedCommentId, pageNum, pageSize) => ({
    type: T.GET_VIDEODETAILS_DATA,
    payload: {
        feedCommentId,
        pageNum, 
        pageSize
    }
});

/**
 * 获取'视频评论列表下拉列数据源'数据源(saga)
 * @param 暂无
 * 
 */
export const getVideoselectDataSource = () => ({
    type: T.GET_VIDEOSELECT_DATA,
});

/**
 * 获取'视频评论列表查询'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {number} feedClassId 下拉主键id
 * @param {sting} feedType 固定为图文标识字符串 "VIDEO"
 * @param {sting} feedTitle 视频名称
 * @param {sting} watchAccountName 评论人
 * @param {sting} content 评论内容
 * 
 */
export const getVideoSelectSource = (value) => ({
    type: T.GET_VIDEOSELECTSOURCE_DATA,
    payload: value
});
