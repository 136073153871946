/**
 * 店铺商品列表
 */
import React from 'react';
import { Avatar, Icon, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './shoplist.module.scss';
import { regFenToYuan } from '@/util/money';
import http from '@/assets/api/http';

class ShopList extends React.Component {

    state = {
        shopLogo:'',
        shopName:'',
        goodsList: []
    }

    componentDidMount() {
        http('/shop/shopBaseIndex', {
            shopId: this.props.shopId,
        }, 'POST').then((response) => {
            if (response.status == 200) {
                const { shopLogo, shopName, goodsList } = response.result;
                this.setState({ shopLogo, shopName, goodsList });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _saleGood = ({ list = [] }) => {
        return (
            <div className={styles.card}>
                <ul className={styles.ul}>
                    {list.map((item) => {
                        const { goodsId, mainImgUrl, goodsName, salePrice, marketPrice } = item;
                        return this._saleGoodItem(goodsId, mainImgUrl, goodsName, salePrice, marketPrice);
                    })}
                </ul>
            </div>
        );
    }

    _saleGoodItem = (goodsId, mainImgUrl, goodsName, salePrice, marketPrice) => {
        return (
            <li key={goodsId} className={styles.li} onClick={() => this.props.goodsSelect(goodsId)}>
                <div className={styles.good}>
                    <img className={styles.img} src={mainImgUrl} />
                    <div className={styles.desp}>
                        <p className={styles.p1}>{goodsName}</p>
                    </div>
                    <div className={styles.price}>
                        <span className={styles.label}><span className={styles.rmb}>¥</span><span className={styles.money}>{regFenToYuan(salePrice)}</span></span>
                        {marketPrice ? <span className={styles.delete}><span className={styles.rmb}>¥</span><span className={styles.money}>{regFenToYuan(marketPrice)}</span></span> : null}
                    </div>
                </div>
            </li>
        );
    }

    render() {
        const { shopLogo, shopName, goodsList } = this.state;
        return (
            <div className={styles.container}>
                <div className={styles.head}>
                    <div className={styles.shop}>
                        <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} src={shopLogo} size={60}>logo</Avatar>
                        <div className={styles.title}>{shopName}</div>
                    </div>
                    <div className={styles.license}>营业执照</div>
                </div>
                <div className={styles.content}>
                    <div className={styles.box}>
                        {this._saleGood({ list: goodsList })}
                    </div>
                </div>
            </div >
        );
    }
}

ShopList.propTypes = {
    shopId: PropTypes.number,
    goodsSelect: PropTypes.func,
};

export default ShopList;