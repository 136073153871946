/**
 * 确认对账单 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { XSelect } from '@/components/xqxc_ui';
import { Modal, Input, Form, Select, Radio, Icon, DatePicker, message } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import styles from './ConfirBill.module.scss';

const { TextArea } = Input;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
    // labelAlign: 'left',
};
class NoLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
        };
    }
    state = {
    }
    // 点击确认
    _handleOk = () => {
        // const { id } = this.props.data;
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                http('/settle/settle/deliveryInvoice', { ...fieldsValue, deliverTime: fieldsValue.deliverTime.format('YYYY-MM-DD HH:mm:ss'), settlementId: this.props.data }).then((res = {}) => {
                    if (res.code === 200) message.success(res.message);
                });
                this.props.closeMode();
            }
        });

    };
    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={800}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon
                            htmlFor='expressCompany'
                            label={<span>快递公司</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('expressCompany', {
                                rules: [{ required: true, message: '请输入快递公司' }],
                            })(
                                <Input placeholder='请输入快递公司' />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='expressNumber'
                            label={<span>快递单号</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('expressNumber', {
                                rules: [{ required: true, message: '请输入快递单号选择' }],
                            })(
                                <Input placeholder='请输入快递单号' />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='deliverTime'
                            label={<span>寄送日期</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('deliverTime', {
                                rules: [{ required: true, message: '请选择寄送日期' }],
                            })(
                                <DatePicker locale={locale} placeholder='请选择寄送日期' style={{ width: '100%' }} />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='receiveName'
                            label={<span>收票人</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('receiveName', {
                                rules: [{ required: true, message: '请输入收票人姓名' }],
                            })(
                                <Input placeholder='请输入收票人姓名' />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='receivePhone'
                            label={<span>收票人电话</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('receivePhone', {
                                rules: [{ required: true, message: '请输入收票人电话' }],
                            })(
                                <Input placeholder='请输入收票人电话' />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='remark'
                            label={<span>备注</span>}
                        >
                            {getFieldDecorator('remark', {
                                // rules: [{ required: true, message: '请输入策略名称' }],
                            })(
                                <TextArea rows={4} />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

NoLogin.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    getListAreaByParentId: PropTypes.func, // 请求地区信息
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(NoLogin);// 校验
export default connect(null, null)(ForgotProducDetails);