export const GET_INVOICELIST = 'INVOICELIST/GET_INVOICELIST';
export const SET_INVOICELIST = 'INVOICELIST/SET_INVOICELIST';

// 发票管理列表
// export const GET_INVOICEMANAGE

export const GET_INVOICEMANAGE_TABLESOURCE = 'INVOICELIST/GET_INVOICELIST';
export const SET_INVOICEMANAGE_TABLESOURCE = 'INVOICELIST/SET_INVOICELIST';

export const GET_INVOICEMANAGE_UNIT_SELECTSOURCE = 'INVOICELIST/GET_INVOICELIST';
export const SET_INVOICEMANAGE_UNIT_SELECTSOURCE = 'INVOICELIST/SET_INVOICELIST';



export const GET_OPERATINGCENTERRECONCILIATION_TABLESOURCE = 'OPERATINGCENTERINVOICEMANAGE/GET_OPERATINGCENTERINVOICEMANAGE_TABLESOURCE';
export const SET_OPERATINGCENTERRECONCILIATION_TABLESOURCE = 'OPERATINGCENTERINVOICEMANAGE/SET_OPERATINGCENTERINVOICEMANAGE_TABLESOURCE';

export const GET_OPERATINGCENTERRECONCILIATION_UNIT_SELECTSOURCE = 'OPERATINGCENTERINVOICEMANAGE/GET_OPERATINGCENTERRECONCILIATION_UNIT_SELECTSOURCE';
export const SET_OPERATINGCENTERRECONCILIATION_UNIT_SELECTSOURCE = 'OPERATINGCENTERINVOICEMANAGE/SET_OPERATINGCENTERRECONCILIATION_UNIT_SELECTSOURCE';

export const GET_OPERATINGCENTERRECONCILIATION_DETAIL = 'OPERATINGCENTERINVOICEMANAGE/GET_OPERATINGCENTERRECONCILIATION_DETAIL';
export const SET_OPERATINGCENTERRECONCILIATION_DETAIL = 'OPERATINGCENTERINVOICEMANAGE/SET_OPERATINGCENTERRECONCILIATION_DETAIL';

