import React, { useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import BaseSet from './BaseSet';
import HotSet from './HotSet';
import ClassSet from './ClassSet';
import AnnouncementSet from './AnnouncementSet';

const ChannelAudit = ({ history }) => {

    const tabList = [
        { id: 'baseSet', label: '基础设置' },
        { id: 'hotSet', label: '热搜词设置' },
        { id: 'classSet', label: '首页分类设置' },
        { id: 'announcementSet', label: '公告设置' },
    ];

    const [currentId, setCurrentId] = useState('baseSet');

    const components = {
        baseSet: <BaseSet history={history} />,
        hotSet: <HotSet history={history} />,
        classSet: <ClassSet history={history} />,
        announcementSet: <AnnouncementSet history={history} />,
    };

    return <div className={styles.flexBoxContainer}>
        <XTabNav renderData={tabList} onChange={tabId => setCurrentId(tabId)} activedID={currentId} />
        <div className={styles.flexBoxContainer} style={{ marginTop: '10px' }}>{components[currentId]}</div>
    </div>;
};
ChannelAudit.propTypes = {
    history: PropTypes.object
};
export default ChannelAudit;