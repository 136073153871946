/**
 * 【工单页】
 */
import React from 'react';
import { XTabNav } from '@/components/xqxc_ui';
import OrderSearch from './components/OrderSearch';
import Export from './components/Export';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XOKButton } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

let tableSource = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0,
};

for (var i = 0; i < 10; i++) {
    tableSource.dataList.push({
        key: i,
        c1: '110000',
        c2: 'huangzp',
        c3: 3000,
        c4: 3000,
        c5: 3000,
        c6: '阿里云',
        c7: '待发货',
        c8: '普通订单',
        c9: '2019-06-12',
        c10: '是',
        c11: '3', // 订单状态，依据不同状态表格中显示不同的操作按钮
    });
}

class Main extends React.Component {
    state = {
        tab: [
            { id: 1, label: '全部工单' },
            { id: 2, label: '我提交的' },
            { id: 3, label: '我受理的' },
            { id: 4, label: '已关闭' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
        orderID: '', // 当前操作的订单ID 
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 表格操作
    _tableAction = (key, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            // 查看详情
            case 'info':
                addOneBreadcrumbPath({
                    title: '工单详情',
                    path: '/home/customermanage/workOrder/detail/'
                });
                history.push({
                    pathname: `/home/customermanage/workOrder/detail/${key}`
                });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {

    }
    // 新建工单
    addWorkOrder = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建工单',
            path: '/home/customermanage/workOrder/add/'
        });
        history.push({
            pathname: '/home/customermanage/workOrder/add/'
        });
    };
    render() {
        const { curTabID } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <OrderSearch orderState={this.state.curTabID} />
                <div className={styles.row2}>
                    <Export /><XOKButton label='新建工单' style={{ marginLeft: '40px', width: '100px' }} onClick={() => this.addWorkOrder()} />
                </div>
                <OrderTable renderData={tableSource} tableAction={this._tableAction} paginationChange={this._paginationChange} curTabID={curTabID} />
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func // 保持两级面包屑导航
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);
