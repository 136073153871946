/**
 * 设置分销价
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './modalStyle.module.scss';
import { Modal, Table, Button } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const Fail = ({ closeMode, modalParams: { errorList = [], errorFileUrl } }) => {

    const columns = () => {
        return [
            {
                title: '商品名称',
                dataIndex: 'goodsName',
                key: 'goodsName',
                align: 'center',
                width: '25%',
                onCell: tooltipStyle,
                render: tooltip
            }, {
                title: 'SKU编码',
                dataIndex: 'skuNo',
                key: 'skuNo',
                align: 'center',
                width: '25%',
                onCell: tooltipStyle,
                render: tooltip
            }, {
                title: '异常描述',
                dataIndex: 'errorMsg',
                key: 'errorMsg',
                align: 'center',
                width: '50%',
                onCell: tooltipStyle,
                render: tooltip
            }];
    };


    return <Modal
        width={800}
        centered
        title='分销商品导入异常'
        visible={true}
        confirmLoading={false}
        onCancel={() => closeMode(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        footer={false}
    >
        <div className={styles.container}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                columns={columns()}
                pagination={false}
                dataSource={errorList}
                scroll={{ y: 500 }}
            />
            <div className={styles.buttonBox}>
                <Button style={{ marginRight: 20 }} onClick={closeMode} > 取消</Button>
                <Button
                    style={{ marginRight: 20 }}
                    onClick={() => {
                        window.open(errorFileUrl);
                    }}
                >
                    {`一键导出异常SKU（${errorList.length}）`}
                </Button>
                <Button type='primary' onClick={closeMode} >确定</Button>
            </div>
        </div>
    </Modal>;
};
Fail.propTypes = {
    closeMode: PropTypes.func,
    modalParams: PropTypes.object
};
export default Fail;