import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import KeepAlive from '@/routes/KeepAlive';
import { connect } from 'react-redux';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { Form, message, Modal, Radio, Select, Switch, Table, Typography, Input, Button } from 'antd';
import http from '@/assets/api/http';
import { getclassificationList, getclassificationState, getstrategyType } from '@/reducers/xqstrategy/classification/actions';

const { Text } = Typography;

const TableComponent = ({
    renderData: {
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0,
        dataList = []
    } = {},
    paginationChange,
    tableAction,
}) => {

    const switchCell = (t, r, f, url) => {
        return <Switch key={r.id} checked={t == 1 ? true : false} checkedChildren='ON' unCheckedChildren='OFF'
            onChange={p => tableAction('switchChange', { id: r.id, [f]: p ? 1 : 0 }, url)} />;
    };

    const tableOperate = (t, r) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => tableAction('infoperate', { ...r, title: '编辑分类' })}>编辑</div>
            <div className={styles.item} onClick={() => tableAction('delete', { ...r, title: '删除提示' })}>删除</div>
        </div>;
    };

    const columns = [
        {
            title: '分类ID',
            dataIndex: 'classId',
            key: 'classId',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: (t, r) => tooltip(r.id)
        },
        {
            title: '分类名称',
            dataIndex: 'className',
            key: 'className',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '攻略类型',
            dataIndex: 'strategyType.value',
            key: 'strategyType.value',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '推荐',
            dataIndex: 'isReco',
            key: 'isReco',
            align: 'center',
            onCell: tooltipStyle,
            render: (t, r) => switchCell(t, r, 'isReco', '/show/strategyClass/updateStrategyClassReco')
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            onCell: tooltipStyle,
            render: (t, r) => switchCell(t, r, 'state', '/show/strategyClass/updateStrategyClassState')
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            onCell: tooltipStyle,
            render: tableOperate
        }
    ];

    return <div className={styles.tableContainer}>
        <Table
            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
            rowKey='id'
            columns={columns}
            dataSource={dataList}
            pagination={false}
            scroll={{ y: true }}
        />
        <XPagination
            resultTotal={resultTotal}
            pageSize={pageSize}
            pageNum={pageNum}
            onChange={paginationChange}
        />
    </div>;
};
TableComponent.propTypes = {
    renderData: PropTypes.object,
    paginationChange: PropTypes.func,
    tableAction: PropTypes.func,
};

const InfoperateModal = ({
    tableAction,
    data: {
        id = '',
        title,
        typeEnum = [],
        className,
        strategyType = {},
        state
    } = {},
    form: {
        validateFields,
        getFieldDecorator
    } = {}
}) => {

    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                setLoading(true);
                http(`/show/strategyClass/${id ? 'updateStrategyClass' : 'createStrategyClass'}`, { ...values, belongSystem: 'SUPPLIER', id, isCustom: 1 }, 'POST')
                    .then(res => {
                        message.success(res.message);
                        setLoading(false);
                        tableAction('closeModal', true);
                    })
                    .catch(err => {
                        setLoading(false);
                        message.error(err.message);
                    });
            }
        });
    };

    return <Modal
        width={480}
        centered
        title={title}
        visible={true}
        bodyStyle={{ padding: '20px 20px 10px 20px' }}
        confirmLoading={false}
        onCancel={() => tableAction('closeModal')}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        footer={false}
    >
        <Form
            className={styles.modalContainer}
            onSubmit={handleSubmit}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
        >
            <Form.Item label='分类名称'>
                {getFieldDecorator('className', {
                    initialValue: className,
                    rules: [
                        { required: true, message: '请输入分类名称' },
                        { type: 'string', max: 10, message: '分类名称最多10个汉字' }
                    ],
                })(<Input style={{ width: '150px' }} placeholder='请输入' />)}
                <Text style={{ marginLeft: '10px' }}>最多只能输入10个汉字</Text>
            </Form.Item>
            <Form.Item label='攻略类型'>
                {getFieldDecorator('strategyType', {
                    initialValue: strategyType.code,
                    rules: [
                        { required: true, message: '请选择攻略类型' },
                        { type: 'string', max: 10, message: '分类名称最多10个汉字' }
                    ],
                })(<Select style={{ width: '150px' }} placeholder='请选择攻略类型'>
                    {
                        typeEnum.filter(i => i.code === 'HELP_CENTE').map(i => {
                            return <Select.Option key={i.code} value={i.code}>{i.value}</Select.Option>;
                        })
                    }
                </Select>)}
            </Form.Item>
            <Form.Item label='状态'>
                {getFieldDecorator('state', {
                    initialValue: state,
                    rules: [
                        { required: true, message: '请选择状态' },
                    ],
                })(
                    <Radio.Group>
                        {
                            [{ code: 1, value: '启用' }, { code: 0, value: '禁用' }].map(i => {
                                return <Radio key={i.code} value={i.code}>{i.value}</Radio>;
                            })
                        }
                    </Radio.Group>
                )}
            </Form.Item>
            <div className={styles.footerBOx}>
                <Button disabled={loading} onClick={() => tableAction('closeModal')} style={{ marginRight: '20px' }}>取消</Button>
                <Button loading={loading} htmlType='submit' type='primary'>确定</Button>
            </div>
        </Form>
    </Modal>;
};
InfoperateModal.propTypes = {
    form: PropTypes.object,
    tableAction: PropTypes.func,
    data: PropTypes.object
};
const FormInfoperate = Form.create()(InfoperateModal);

const SupplyClass = ({
    getclassificationList,
    tableData,
    getclassificationState,
    stateEnum,
    getstrategyType,
    typeEnum,
    paginations
}) => {

    const [className, setClassName] = useState('');
    const [stateItem, setStateItem] = useState({});
    const [typeItem, setTypeItem] = useState({});
    const [pageNum, setPageNum] = useState(autoPageNum);
    const [pageSize, setPageSize] = useState(autopageSize);
    const [openModal, setOpenModal] = useState('');
    const [modalParmas, setModalParmas] = useState();

    useEffect(() => {
        getclassificationState();
        getstrategyType();
    }, []);

    const getTableData = (params = {}) => {
        getclassificationList({
            className: params.className, belongSystem: 'SUPPLIER',
            state: params.stateItem && params.stateItem.code,
            strategyType: params.typeItem && params.typeItem.code,
            pageNum: params.pageNum, pageSize: params.pageSize
        });
        KeepAlive.saveCache(params);
    };

    const paginationChange = (pageSize, pageNum) => {
        setPageNum(pageNum);
        setPageSize(pageSize);
        getTableData({ className, typeItem, stateItem, pageNum, pageSize });
    };

    const resetSearch = () => {
        setClassName('');
        setStateItem({});
        setTypeItem({});
        KeepAlive.saveResetFunc(() => getTableData({ className, typeItem, stateItem, pageNum, pageSize }));
    };

    const tableAction = (type, params, url) => {
        const eventObject = {
            infoperate: p => {
                setOpenModal('infoperate');
                setModalParmas(p);
            },
            delete: p => {
                showConfirm('您是否删除当前分类', '删除后数据无法恢复，您是否确认“删除”', () => {
                    http(`/show/strategyClass/deleteStrategyClass/${p.id}`, {}, 'POST').then(() => {
                        message.success('删除成功');
                        getTableData({ className, typeItem, stateItem, pageNum, pageSize });
                    }).catch(err => message.error(err.message));
                }, () => { });
            },
            switchChange: p => {
                console.log(p);
                http(url, { ...p }, 'POST').then(res => {
                    message.success(res.message);
                    getTableData({ className, typeItem, stateItem, pageNum, pageSize });
                }).catch(err => message.error(err.message));
            },
            closeModal: p => {
                setOpenModal('');
                setModalParmas();
                p && getTableData({ className, typeItem, stateItem, pageNum, pageSize });
            }
        };
        eventObject[type](params);
    };

    const onChange = (p = {}) => {
        if (p.className) setClassName(p.className);
        if (p.typeItem) setTypeItem(p.typeItem);
        if (p.stateItem) setStateItem(p.stateItem);
        if (p.pageNum != autoPageNum) setPageNum(p.pageNum);
        if (p.pageSize != autopageSize) setPageSize(p.pageSize);
        getTableData(p);
    };

    const modals = {
        infoperate: <FormInfoperate data={{ ...modalParmas, typeEnum }} tableAction={tableAction} />,
    };

    return <KeepAlive render={onChange}>
        <div className={styles.flexBoxContainer}>
            <SearchBox>
                <SearchItem>
                    <XInput
                        label='分类名称'
                        placeholder='请输入分类名称'
                        value={className}
                        bindThis={setClassName}
                        bindName='className'
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        label='分类状态'
                        showSearch
                        placeholder='请选择分类状态'
                        renderData={stateEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={stateItem.value}
                        bindThis={setStateItem}
                        bindName='stateItem'
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        label='攻略类型'
                        showSearch
                        placeholder='请选择攻略类型'
                        renderData={typeEnum.filter(i => i.code === 'HELP_CENTE')}
                        dataIndex='value'
                        keyIndex='code'
                        value={typeItem.value}
                        bindThis={setTypeItem}
                        bindName='typeItem'
                    />
                </SearchItem>
                <SearchItem>
                    <XOKButton
                        label='查询'
                        onClick={() => getTableData({ stateItem, className, typeItem, pageNum, pageSize })}
                    />
                    <XCancelButton
                        label='重置'
                        onClick={resetSearch}
                    />
                </SearchItem>
            </SearchBox>
            <div><XOKButton label='新建分类' style={{ width: '90px' }} onClick={() => tableAction('infoperate', { title: '新建分类' })} /></div>
            <TableComponent renderData={{ dataList: tableData, ...paginations }} tableAction={tableAction} paginationChange={paginationChange} />
            {openModal && modals[openModal]}
        </div>
    </KeepAlive>;
};
SupplyClass.propTypes = {
    getclassificationList: PropTypes.func,
    tableData: PropTypes.array,
    getclassificationState: PropTypes.func,
    stateEnum: PropTypes.array,
    getstrategyType: PropTypes.func,
    typeEnum: PropTypes.array,
    paginations: PropTypes.object
};
export default connect(state => {
    return {
        tableData: state.classification.classificationDate.tableSource,
        stateEnum: state.classification.classificationDate.selectData,
        typeEnum: state.classification.getstrategyType,
        paginations: state.classification.classificationDate.paginations,
    };
}, {
    getclassificationList,
    getclassificationState,
    getstrategyType
})(SupplyClass);