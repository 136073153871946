import * as T from './actiontypes';
/** 
 * 【用户举报】列表数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} accountTel 被举报人手机号
 * @param {string} reportState 举报状态 未处理：UNPROCESSED，已驳回：REJECT，已封禁：BANNED
 * @param {string} reportAccountTel 举报人手机号
 * @param {string} contentTitle 举报标题
 */

export const getListUserReport = (dataload) => ({
    type: T.GET_USERLIDT_DATA,
    payload: {
        ...dataload,
    }
});

/**
 * 举报状态数据源(saga)
 *  @param 暂无
 */
export const getreportState = () => ({
    type: T.GET_REPORTSTATE_DATA,
});

/**
 * 用户举报禁播，封禁类型和封禁理由数据源(saga)
 *  @param 暂无
 */
export const getreportStop = () => ({
    type: T.GET_REPORTSTOP_DATA,
});

/**
 * 用户详情'数据源(saga)
 * @param {number} id  获取某个用户情对应的id
 */
export const getUserReportDetails = (id) => ({
    type: T.GET_REPORTDETAIL_DATA,
    payload: {
       id,
    }
});
