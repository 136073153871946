import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/xqstrategy/initdesclist/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { XOKButton, XCancelButton, XSelect, XInput } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import http from '@/assets/api/http';
import { message } from 'antd';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends React.Component {

    state = {
        title: '', // 标题
        useScene: {}, // 文章位置
    }

    componentDidMount() {
        const { getStrategyList, getUseSceneSelect, keepSecondNavBreadcrumb } = this.props;
        getStrategyList({ pageSize: autopageSize, pageNum: autoPageNum });
        getUseSceneSelect();
        keepSecondNavBreadcrumb();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, title, useScene } = this.state;
        const query = {
            title,
            useScene: useScene.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getStrategyList(query);
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize });
    }


    // 重置
    _resetHandle = () => {
        this.setState({ title: '', useScene: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格操作
    _tableAction = (type, record, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'state':
                http('/system/strategy/edit', { id: record.id, state: value === false ? 0 : 1 }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('修改成功');
                        this._searchHandle('useCache');
                    }
                }).catch((e={}) => {
                    message.error(e.message);
                });
                break;
            case 'edit':
                addOneBreadcrumbPath({
                    title: '说明编辑',
                    path: '/home/xqstrategy/initdesclist/edit/' + record.id
                });
                history.push({
                    pathname: '/home/xqstrategy/initdesclist/edit/' + record.id,
                });
                break;
        }
    }

    render() {
        const { useSceneSelect, strategyList } = this.props;
        const { title, useScene } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.row1}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='文章标题'
                                    placeholder='请输入文章标题'
                                    value={title}
                                    bindThis={this}
                                    bindName='title'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='文章位置'
                                    placeholder='请选择文章位置'
                                    renderData={useSceneSelect}
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={useScene.value}
                                    bindThis={this}
                                    bindName='useScene'
                                    showSearch={true}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <TableContent renderData={strategyList} paginationChange={this._paginationChange} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func,
    addOneBreadcrumbPath: PropTypes.func,
    getStrategyList: PropTypes.func,
    strategyList: PropTypes.object,
    getUseSceneSelect: PropTypes.func,
    useSceneSelect: PropTypes.array
};

const mapStateToProps = (state) => ({
    strategyList: state.initdesclist.getStrategyList,
    useSceneSelect: state.initdesclist.getUseSceneSelect
});

export default connect(mapStateToProps, { ...model.actions, keepSecondNavBreadcrumb, addOneBreadcrumbPath })(Main);