/**
 * 贴纸分类创建或编辑弹框
 */
import React from 'react';
import { Modal, Radio, message, Icon } from 'antd';
import PropTypes from 'prop-types';
import styles from './TypeModal.module.scss';
import { XInput } from '@/components/xqxc_ui';
import CustomUpload, { generateFileList } from '@/components/NewUpload';
import http from '@/assets/api/http';
import { formValids } from '@/assets/js/utils';

const RadioGroup = Radio.Group;
class TypeModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        typeInput: this.props.data.className,
        radioValue: this.props.data.state,
        imgs: []
    }

    constructor(props) {
        super(props);
        this.state.imgs = generateFileList(props.data.img);
    }

    _handleOk = () => {
        try {
            const { imgs } = this.state;
            let fields = {
                state: this.state.radioValue,
                className: this.state.typeInput,
                img: imgs.length ? (imgs[0].response ? imgs[0].response.key : imgs[0].url) : ''
            };

            if (formValids(fields, {
                className: '请输入分类名称',
                state: '请选择状态是否开启',
                img: '请上传分类图片'
            })) {
                this.setState({ confirmLoading: true });
                const method = this.props.data.id === undefined ? 'createStickerClass' : 'updateShowClass';
                http(`/show/showClass/${method}`, {
                    id: this.props.data.id,
                    classTypeCode: this.props.data.classTypeCode,
                    ...fields
                }, 'POST').finally(() => {
                    this.setState({ visible: false, confirmLoading: false });
                    this.props.closeMode(true);
                });
            }
        } catch (e) {
            message.error('数据保存异常');
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }

    render() {
        const { visible, confirmLoading, radioValue, typeInput } = this.state;
        return (
            <Modal
                width={470}
                centered
                title={this.props.title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '408px' }}
                        inputStyle={{ width: '340px' }}
                        label='分类名称'
                        placeholder='请输入分类名称'
                        onChange={typeInput => this.setState({ typeInput })}
                        value={typeInput}
                    />
                    <div className={styles.radio}>
                        <div className={styles.label}>状态</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={radioValue}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.upload}>
                        <div className={styles.label}>分类图标</div>
                        <div className={styles.icon}>
                            <CustomUpload
                                accept='.png, .jpg, .jpeg'
                                fileList={this.state.imgs}
                                listType="picture-card"
                                wallMode="single"
                                qiniuUploadProps={{
                                    uploadType: 'image'
                                }}
                                onChange={(info) => {
                                    const imgs = info.fileList.length > 1 ? [info.fileList.pop()] : info.fileList;
                                    this.setState({ imgs });
                                }}
                            >
                                <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                            </CustomUpload>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

TypeModal.propTypes = {
    data: PropTypes.array, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string // 弹框标题
};

export default TypeModal;