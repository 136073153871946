import * as T from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    resultTotal: 0
};

export const productBrand = (state = initData, action) => {
    switch (action.type) {
        case T.SET_PRODUCTBRAND_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
export const productBrandDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_PRODUCTBRAND_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

export const thirdPartyProductBrand = (state = initData, action) => {
    switch (action.type) {
        case T.SET_THIRDPARTY_PRODUCTBRAND_LIST:
            return action.payload.result;
        default:
            return state;
    }
};