import * as T from './actiontypes';
const defaultState = {
    tableSource: [],//人气值攻略分类列表数据源
    selectData: [],// 攻略分类状态下拉选项
    paginations: {}, //攻略分类分页对象


    strategyTable: [],// 人气值攻略列表
    strategyPaginations: {}, // 人气值攻略列表分页对象

    strategyAppState: [],//人气值攻略列表新建应用端口
    strategyDetail: {},//人气值攻略列表详情
};


/**
 * 存储人气值攻略数据源
 * @param {*} state 
 * @param {*} action 
 */
export const classificationDate = (state = defaultState, action) => {
    let newTableSource, strategyAppState, newSelectData, newPaginations, strategyDetail;
    switch (action.type) {
        case T.SET_CLASSIFICATIONLIST_DATA: //人气值攻略列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_STRATEGYLIST_DATA: //人气值攻略列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, strategyTable: newTableSource, strategyPaginations: newPaginations };

        case T.SET_CLASSIFICATIONSTATE_DATA: //人气值攻略列表分类状态下拉
            newSelectData = action.payload.result;
            return { ...state, selectData: newSelectData };

        case T.SET_STRATEGYAPPLIST_DATA: //人气值攻略列表新建应用端口
            strategyAppState = action.payload.result;
            return { ...state, strategyAppState, };
        case T.SET_STRATEGYDETAIL_DATA: //人气值攻略列表详情
            strategyDetail = action.payload.result;
            return { ...state, strategyDetail, };
        case T.CLEAN_CLASSIFICATIONLIST_DATA:
            return { ...state, tableSource: [] };
        default:
            return state;
    }
};

/**
 * 攻略类型 
 */
export const getstrategyType = (state = [], { type, payload }) => {
    switch (type) {
        case T.SET_STRATEGY_TYPE:
            return payload.result;
        default:
            return state;
    }
};
