/**
 * [标准商品库]
 * @author: Fu Xiaochun
 * @date: 2021-06-03 
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XTabNav } from '@/components/xqxc_ui';
import StandardProductList from './pages/standard';

function ProductBrand(props){
    const tabs = [
        { id: 1, label: '标准商品库' },
    ];
    const [curTabID, setCurTabID] = useState(1);

    useEffect(()=>{
        props.keepSecondNavBreadcrumb();
    }, []);

    const _renderTabContent = ()=>{
        switch (curTabID) {
            case 1:
                return <StandardProductList curTabID={curTabID} history={props.history} />;
            default:
                return <StandardProductList curTabID={curTabID} history={props.history} />;
        }
    };

    return (
        <div className={styles.flexBoxContainer}>
            <XTabNav renderData={tabs} onChange={tabId => { setCurTabID(tabId); }} activedID={curTabID} />
            <div className={styles.flexBoxContainer} style={{ marginTop: '10px' }}>
                { _renderTabContent() }
            </div>
        </div>
    );
}
  
ProductBrand.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { keepSecondNavBreadcrumb })(ProductBrand);