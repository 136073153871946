import * as T from './actiontypes';

const defaultState = {
    tableSource: [],// 视频评论列表数据源
    ListDetail: {
        avatar: '',
        content: '',
        gmtCreate:'',
        data: {
            dataList: []
        }
    }, // 视频评论详情数据源
    SelectData: [],// 视频评论下拉选项
    paginations: {} //分页对象
};


/**
 * 存储'视频评论列表'数据源
 * @param {*} state 
 * @param {*} action 
 */
export const videoDate = (state = defaultState, action) => {
    let newTableSource,newListDetail,newSelectData,newSelectQuery,newPaginations;
    switch(action.type){
        case T.SET_VIDEO_DATA: //存储'视频评论列表'数据源
            newTableSource =  action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return {...state, tableSource:newTableSource, paginations:newPaginations};
        case T.SET_VIDEODETAILS_DATA: //存储'视频评论详情'数据源
            newListDetail =  action.payload.result;
            return {...state, ListDetail:newListDetail};
        case T.SET_VIDEOSELECT_DATA: //存储'视频评论下拉选项'数据源
            newSelectData =  action.payload.result.dataList;
            return {...state, SelectData:newSelectData};
        default:
            return state;
    }
};


