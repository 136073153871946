/**
 *  秀场设置
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';


class Showset extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case 'ProbotList':
                addOneBreadcrumbPath({
                    title: '机器人列表',//人气规则-机器人列表
                    path: '/home/systemmanage/showset/robotList'
                });
                history.push({
                    pathname: '/home/systemmanage/showset/robotList'
                });
                break;
            case 'Pmessage'://人气规则-消息生成
                addOneBreadcrumbPath({
                    title: '消息生成',
                    path: '/home/systemmanage/showset/popularMessage'
                });
                history.push({
                    pathname: '/home/systemmanage/showset/popularMessage'
                });
                break;
            case 'pcreateRules':
                addOneBreadcrumbPath({
                    title: '人气规则创建',
                    path: '/home/systemmanage/showset/popularcreate'
                });
                history.push({
                    pathname: '/home/systemmanage/showset/popularcreate'
                });
                break;
            case 'peditor':
                addOneBreadcrumbPath({
                    title: '人气规则编辑',
                    path: '/home/systemmanage/showset/popularedit/' + value,
                });
                history.push({
                    pathname: '/home/systemmanage/showset/popularedit/' + value,
                });
                break;
                case 'editorDefault':
                    addOneBreadcrumbPath({
                        title: '人气规则编辑',
                        path: '/home/systemmanage/showset/populardefaultedit/' + value,
                    });
                    history.push({
                        pathname: '/home/systemmanage/showset/populardefaultedit/' + value,
                    });
                    break;    
            case 'rcreateRules':
                addOneBreadcrumbPath({
                    title: '推荐规则创建',
                    path: '/home/systemmanage/showset/createInfo'
                });
                history.push({
                    pathname: '/home/systemmanage/showset/createInfo',
                });
                break;
            case 'reditor':
                addOneBreadcrumbPath({
                    title: '推荐规则编辑',
                    path: '/home/systemmanage/showset/editInfo/' + value,
                });
                history.push({
                    pathname: '/home/systemmanage/showset/editInfo/' + value,
                });
                break;
            case 'vionlation'://代言任务违规机制-周期记录表
                addOneBreadcrumbPath({
                    title: '周期记录表',
                    path: '/home/systemmanage/showset/vionlation'
                });
                history.push({
                    pathname: '/home/systemmanage/showset/vionlation',
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

Showset.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Showset);