/**
 * 终止合作弹框
 * */
import React from 'react';
import { Modal } from 'antd';
import { XTextArea } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './StopContract.module.scss';
import { message } from 'antd';
import http from '@/assets/api/http';

class StopContract extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
            reason: ''
        };
    }

    // 确认
    _handleOk = () => {
        const { modeParam } = this.props;
        const { reason } = this.state;
        const data = {
            id: modeParam,
            reason
        };

        http('/merchant/supplier/endSupplier', data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('终止成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode();
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, reason } = this.state;

        return (
            <Modal
                width={450}
                centered
                title='终止'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.title}>提交终止合作</div>
                    <XTextArea
                        style={{ width: '385px', marginTop: '10px' }}
                        inputStyle={{ width: '335px', resize: 'none' }}
                        label='说明'
                        placeholder='请输入终止说明'
                        value={reason}
                        bindThis={this}
                        bindName='reason'
                    />
                </div>
            </Modal>
        );
    }
}

StopContract.propTypes = {
    visible: PropTypes.bool.isRequired,
    closeMode: PropTypes.func.isRequired,
    modeParam: PropTypes.string,
};

export default StopContract;