import React from 'react';
import styles from './TableMain.module.scss';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { XPagination } from '@/components/xqxc_ui';
export default class TableMain extends React.Component {
    state = {
        tableDataSource: [], // 表格数据源        
    }
    static propTypes = {
        renderData: PropTypes.object.isRequired, // 渲染数据源(双向绑定)
        typeList: PropTypes.object.isRequired,
        tableAction: PropTypes.func.isRequired, // 对表格的操作
        paginationChange: PropTypes.func.isRequired
    };
    _columns = () => [
        {
            title: '流ID',
            dataIndex: 'outerStreamId',
            key: 'outerStreamId',
            align: 'center',
            width: '8%',
        },
        {
            title: '手机号码',
            dataIndex: 'tel',
            key: 'tel',
            align: 'center',
            width: '8%',
        },
        {
            title: '主播昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            align: 'center',
            width: '8%',
        },
        {
            title: '封面',
            dataIndex: 'coverPage',
            key: 'coverPage',
            align: 'center',
            width: '8%',
            render: (text) => (this._imgCell(text))
        },
        {
            title: '直播标题',
            dataIndex: 'broadcasTitle',
            key: 'broadcasTitle',
            align: 'center',
            width: '8%',
        },
        {
            title: '鉴定概率值',
            dataIndex: 'outerRate',
            key: 'outerRate',
            align: 'center',
            width: '8%',
        },
        {
            title: '鉴定标签',
            dataIndex: 'outerLabel',
            key: 'outerLabel',
            align: 'center',
            width: '8%',
        },
        {
            title: '鉴定结果',
            dataIndex: 'operateResult',
            key: 'operateResult',
            align: 'center',
            width: '8%',
        },
        {
            title: '预警时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: '8%',
        },
        {
            title: '操作人',
            dataIndex: 'operatePerson',
            key: 'operatePerson',
            align: 'center',
            width: '8%',
        },
        {
            title: '操作时间',
            dataIndex: 'operateTime',
            key: 'operateTime',
            align: 'center',
            width: '8%',
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '8%',
            render: (id, record) => this._tableAction(id, record)
        }
    ];
    // 表格项内容为图片
    _imgCell = (url) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.imgbox}>
                    <img className={styles.img} src={url} />
                </div>
            </div>
        );
    };
    _tableAction = (id, record) => {
        if (record.operateResult === '禁播') {
            return (
                <div className={styles.action}>
                    <div>已禁播</div>
                </div>
            );
        } else {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => this.props.tableAction(id, record, 'ban')}>禁播</div>
                    <div className={styles.item} onClick={() => this.props.tableAction(id, record, 'online')}>在线播放</div>
                </div>
            );
        }

    };
    _takeDataSource = (data) => {
        return data ? data : [];
    }
    render() {
        const { renderData, paginationChange } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(renderData.dataList)}
                    rowKey={record => record.id}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={paginationChange} />
            </div>
        );
    }
}