import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';

class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '员工账号',
            dataIndex: 'loginName',
            key: 'loginName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '员工姓名',
            dataIndex: 'emplName',
            key: 'emplName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '联系电话',
            dataIndex: 'tel',
            key: 'tel',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所属分类',
            dataIndex: 'companyName',
            key: 'companyName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所属部门',
            dataIndex: 'deptName',
            key: 'deptName',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '职位',
            dataIndex: 'position',
            key: 'position',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '角色',
            dataIndex: 'roleNames',
            key: 'roleNames',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => (this._renderStatusCell(text))
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: cellWidth.big,
            fixed: 'right',
            render: (text, item) => this._renderActionCell(item.id, item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderStatusCell = (text) => {
        const stateText = text == 1 ? '启用' : '禁用';
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {stateText}
            </div>
        );
    }

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        const stateText = item.state == 0 ? '启用' : '禁用';
        const state = item.state == 0 ? 'able' : 'disable';
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>
                <div className={styles.item} onClick={() => tableAction(key, state)}>{stateText}</div>
                <div className={styles.item} onClick={() => tableAction(key, 'reset')}>修改密码</div>
                {/* <div className={styles.item} onClick={() => tableAction(key, 'del')}>删除</div> */}
            </div>
        );
    }

    render() {
        const { paginationChange, scrollY } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string
};

export default XTableHOC(TableContent);