import React from 'react';
import PropTypes from 'prop-types';
import styles from './SearchItem.module.scss';

class SearchItem extends React.Component {

    render() {
        return (
            <li className={`${styles.search_item} ${styles.floatLeft}`}>
                {this.props.children}
            </li>
        );
    }
}

SearchItem.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
};

export default SearchItem;