/**
 * 设置分销价
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './modalStyle.module.scss';
import { Modal, message, Icon, Typography, Input, Row, Col } from 'antd';

const { Text } = Typography;
const { TextArea } = Input;

const Ban = ({ closeMode, modalParams: { id = 0 } }) => {

    const [loading, setLoading] = useState(false);
    const [violationReason, setViolationReason] = useState('');

    const onSubmit = () => {
        const data = { violationReason, goodsId: id };
        setLoading(true);
        http('/goods/wholesale/disableGoods', data, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    return <Modal
        width={600}
        centered
        title='批发商品禁用'
        visible={true}
        confirmLoading={loading}
        onOk={onSubmit}
        onCancel={() => closeMode(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div className={styles.container}>
            <Row>
                <Col span={24}>
                    <div className={styles.tipBox}>
                        <Icon
                            type='info-circle'
                            theme='filled'
                            style={{ color: '#f00', fontSize: 50 }}
                        />
                    </div>
                </Col>
                <Col span={24}>
                    <div className={styles.tipBox}>
                        <Typography.Text
                            type='secondary'
                            style={{ fontSize: 20 }}
                        >禁用后B端无法搜索到该批发商品。</Typography.Text>
                    </div>
                </Col>
                <Col span={24}>
                    <div className={styles.reason}>
                        <Text type='danger' strong>*</Text>
                        <Text strong>原因：</Text>
                        <TextArea
                            style={{ marginTop: 10 }}
                            value={violationReason}
                            onChange={e => setViolationReason(e.target.value)}
                            placeholder='请输入备注'
                            autoSize={{ minRows: 3, maxRows: 6 }}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    </Modal>;
};
Ban.propTypes = {
    closeMode: PropTypes.func,
    modalParams: PropTypes.object
};
export default Ban;