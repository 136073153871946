import { createModel } from '@/store/tools';

const dataSource = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    resultTotal: 0
};

const model = {
    namespace: 'autonym',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getFacilitators': T('getFacilitators'),
            'getApprovenum': T('getApprovenum'),
            'getApproveTypeEnum': T('getApproveTypeEnum'),
            'getFacilitatorInfo': T('getFacilitatorInfo'),
        },
        actions: {
            'getFacilitators': A('getFacilitators'),
            'getApprovenum': A('getApprovenum'),
            'getApproveTypeEnum': A('getApproveTypeEnum'),
            'getFacilitatorInfo': A('getFacilitatorInfo'),
        },
        sagas: {
            'getFacilitators': S('getFacilitators', '/service/provider/verify/listServiceProviderVerify'),
            'getApprovenum': S('getApprovenum', '/enum/ServiceVerifiedInfoAuditStateEnum'),
            'getApproveTypeEnum': S('getApproveTypeEnum', '/enum/RealNameTypeEnum'),
            'getFacilitatorInfo': S('getFacilitatorInfo', '/service/provider/verify/serviceProviderVerifyInfo'),
        },
        reducers: {
            'facilitators': R('getFacilitators', dataSource),
            'approvenum': R('getApprovenum', []),
            'approveTypeEnum': R('getApproveTypeEnum', []),
            'facilitatorInfo': R('getFacilitatorInfo', { auditState: {} }),
        }
    })
};
export default createModel(model);