import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XInput, XSelect, XDatePicker, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import PurchaseAuditTable from './components/PurchaseAuditTable';
import model from '@/reducers/suppliermanage/purchasecontract/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',//合同号
            suppliers: {}, //供应商
            startDate: null,//开始时间
            endDate: null//结束时间
        };
    }

    static propTypes = {
        history: PropTypes.object,//路由对象
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getPurchaseContractList: PropTypes.func,//采购审核列表
        purchaseContractList: PropTypes.object,//采购列表数据源
        getPurchaseSupplierSele: PropTypes.func,//获取供应商下拉
        purchaseSupplierSele: PropTypes.array//供应商下拉
    };

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, id, suppliers, startDate, endDate } = this.state;
        this.searchCache = {
            id, suppliers, supplierId: suppliers.supplierId, startDate, endDate, state: 'APPLY',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getPurchaseContractList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ id: '', suppliers: {}, startDate: null, endDate: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //表格操作
    _tableOperate = (id, type, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detailInfo':
                addOneBreadcrumbPath({
                    title: '采购审核详情',
                    path: '/home/auditmanage/purchasepact/detailinfo'
                });
                history.push({
                    pathname: '/home/auditmanage/purchasepact/detailinfo/' + id,
                });
                break;
            case 'auditInfo':
                addOneBreadcrumbPath({
                    title: '采购审核操作',
                    path: '/home/auditmanage/purchasepact/auditinfo'
                });
                history.push({
                    pathname: '/home/auditmanage/purchasepact/auditinfo/' + id,
                });
                break;
            default:
                break;
        }
    };

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { purchaseContractList, purchaseSupplierSele } = this.props;
        const { id, suppliers, startDate, endDate } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='合同号'
                                placeholder='请输入合同号'
                                value={id}
                                bindThis={this}
                                bindName='id'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='供应商'
                                placeholder='请选择供应商'
                                renderData={purchaseSupplierSele}
                                bindThis={this}
                                bindName='suppliers'
                                dataIndex='supplierName'
                                keyIndex='supplierId'
                                value={suppliers.supplierName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                placeholder='选择开始时间'
                                label='修改日期'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat
                            />
                            <XDatePicker
                                placeholder='选择结束时间'
                                style={{ marginRight: '20px' }}
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <PurchaseAuditTable
                        paginationChange={this._paginationChange}
                        tableOperate={this._tableOperate}
                        tableData={purchaseContractList}
                    />
                </div>
            </KeepAlive>
        );
    }
}

//映射数据源
const mapStateToProps = (state) => ({
    purchaseContractList: state.purchaseContract.purchaseContractList,
    purchaseSupplierSele: state.purchaseContract.purchaseSupplierSele
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);