import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, message } from 'antd';
import Create from './modal/Create';
import Edit from './modal/Edit';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, showConfirm } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/systemmanage/showset/model';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { SearchBox, SearchItem } from '@/components/Layout';

class ShowBeanRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rulesNum: '',//规则名称
            ruleState: {},//规则状态
            port: {},//发行端
            selectDataSource: [],//模拟下拉列数据
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: null, // 当前需要打开的弹窗的入参
        };
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getPayState, getIssuend } = this.props;
        keepSecondNavBreadcrumb();
        getPayState();//状态
        getIssuend();//发行端
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { rulesNum, ruleState, port, pageNum, pageSize } = this.state;
        this.searchCache = {
            ruleName: rulesNum,
            state: ruleState.code,
            useScene: port.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getShowBeanRules(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发 
    _ruleresetHandle = () => {
        this.setState({ rulesNum: '', ruleState: {}, port: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作功能回调 
    _actionClickHandle = (record, type, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'edit'://编辑
                this.setState({ currentOpenMode: 'edit', openModeParam: record.id });
                break;
            case 'countimg'://计算周期
                showConfirm('确定要重新计算此规则吗？', '', () => {
                    http('/xiudou/rule/recalculatePeriod/' + record.id, {}, 'POST')
                        .then(() => {
                            this._searchHandle('useCache');
                            message.success('计算周期成功');
                        }).catch((reject = {}) => {
                            message.error(reject.message);
                        });
                });

                break;
            case 'switch'://状态
                if (!record.state) {
                    http('/xiudou/rule/activeXiudouRule', {
                        useScene: record.useScene.code,
                        id: record.id
                    }, 'POST')
                        .then(() => {
                            this._searchHandle('useCache');
                            message.success('更新成功。');
                        }).catch((reject = {}) => {
                            message.error(reject.message);
                        });
                }
                break;
            case 'delete'://删除
                showConfirm('确定要删除此规则吗？', '你还要继续吗？', () => {
                    http('/settle/starlightExchange/deleteRule', {
                        ruleIds: [record.id]
                    }, 'POST')
                        .then(() => {
                            this._searchHandle('useCache');
                            message.success('删除成功。');
                        }).catch((reject = {}) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'record'://周期记录
                addOneBreadcrumbPath({
                    title: '周期记录',
                    path: '/home/systemmanage/showset/cyclerecord/' + record.id
                });
                history.push({
                    pathname: '/home/systemmanage/showset/cyclerecord/' + record.id
                });
                break;
            default: return null;
        }
    }


    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { currentOpenMode, openModeParam } = this.state;
        switch (currentOpenMode) {
            case 'create':
                return <Create closeMode={this._closeMode} />;
            case 'edit':
                return <Edit id={openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        if (status === true) {
            this.props.getShowBeanRules(this.searchCache);
        }
        this.setState({ currentOpenMode: '', openModeParam: {} });
    }

    render() {
        const { history, addOneBreadcrumbPath, states, issuend } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.tableList;
        const { rulesNum, ruleState, port } = this.state;
        return (
            <KeepAlive id='11' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='规则名称'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='rulesNum'
                                value={rulesNum}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='规则状态'
                                placeholder='请选择'
                                renderData={states}
                                dataIndex='value'
                                keyIndex='code'
                                value={ruleState.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='ruleState'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='发行端口'
                                placeholder='请选择'
                                renderData={issuend}
                                dataIndex='value'
                                keyIndex='code'
                                value={port.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='port'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._ruleresetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '160px' }} label='创建信用值兑换规则' onClick={() => {
                            this.setState({ currentOpenMode: 'create' });
                        }} />
                        <XOKButton style={{ width: '120px', marginLeft: '20px' }} label='全部周期记录' onClick={() => {
                            addOneBreadcrumbPath({
                                title: '全部周期记录',
                                path: '/home/systemmanage/showset/allcyclerecord'
                            });
                            history.push({
                                pathname: '/home/systemmanage/showset/allcyclerecord'
                            });
                        }} />
                    </div>
                    <div className={`${styles.tableContainer}`}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record) => record.id}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(dataList)}
                            pagination={false}
                            scroll={{ x: 2150, y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    <div>{this._renderMode()}</div>
                </div>
            </KeepAlive>
        );
    }
}

ShowBeanRules.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息
    tableList: PropTypes.object,
    getShowBeanRules: PropTypes.func,//表格数据(action)
    states: PropTypes.array,
    getPayState: PropTypes.func,//状态数据(action)
    getIssuend: PropTypes.func,//发行端
    issuend: PropTypes.array,
};

const mapStateToProps = (state) => ({
    tableList: state.showset.showBeanRules, // 表格数据
    states: state.showset.payState, // 状态
    issuend: state.showset.issuend, // 状态
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(ShowBeanRules);
