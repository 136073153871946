/**
 * 社区审核页
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import TableMain from './conponents/TableMain';
import AuditModal from '../../modal/AuditModal';
import AdvocacyAudit from '../../modal/AdvocacyAudit';
import DetailModal from '../../modal/DetailModal';
import AdvocacyInfo from '../../modal/AdvocacyInfo';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getCommunityAuditList } from '@/reducers/auditmanage/community/actions';
import { getVideoAuditSele } from '@/reducers/auditmanage/videoAudit/actions';
import { getcommunityState } from '@/reducers/showmanage/channellist/actions';
import { SearchBox, SearchItem } from '@/components/Layout';

const imgTxtTypes = [
    { key: '0', value: '普通图文' },
    { key: '1', value: '代言任务' },
    { key: '2', value: '知识付费' }
];

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '',
            openModeParam: '',
            telephone: '',
            nickname: '',
            auditAtatus: {},
            imgTxtType: {},
            accountId: 0,
            feedId: 0,
            taskType: 0,
            showType: '', // 用于区分是知识付费还是秀场的社区
            home: {}, // 社区分类
        };
    }

    componentDidMount() {
        this.props.getVideoAuditSele();
        this.props.getcommunityState(); //社区分类
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { telephone, nickname, home, auditAtatus, imgTxtType, pageNum, pageSize } = this.state;
        this.searchCache = {
            tel: telephone, nickName: nickname, state: auditAtatus.code, taskType: imgTxtType.key,
            feedClassId: home.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getCommunityAuditList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ telephone: '', nickname: '', auditAtatus: {}, imgTxtType: {}, home: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'detail':
                this.setState({ currentOpenMode: 'detail', accountId: params.accountId, feedId: id, taskType: params.isTask, showType: params.showType });
                break;
            case 'audit':
                this.setState({ currentOpenMode: 'audit', accountId: params.accountId, feedId: id, taskType: params.isTask, showType: params.showType });
                break;
        }
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 打开弹窗
    _renderMode = () => {
        const { accountId, feedId, taskType, showType } = this.state;
        switch (this.state.currentOpenMode) {
            case 'audit':
                // 是否有代言(1是;0否)
                if (taskType) {
                    return <AdvocacyAudit visible={true} accountId={accountId} feedId={feedId} showType={showType} closeMode={this._closeMode} />;
                } else {
                    return <AuditModal visible={true} accountId={accountId} feedId={feedId} showType={showType} closeMode={this._closeMode} />;
                }
            case 'detail':
                if (taskType) {
                    // 是否有代言(1是;0否)
                    return <AdvocacyInfo visible={true} accountId={accountId} feedId={feedId} showType={showType} closeMode={this._closeMode} />;
                } else {
                    return <DetailModal visible={true} accountId={accountId} feedId={feedId} showType={showType} closeMode={this._closeMode} />;
                }
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({
            currentOpenMode: '',
            accountId: 0,
            feedId: 0,
            taskType: 0,
            showType: ''
        }, () => {
            if (update) {
                this._searchHandle('useCache');
            }
        });
    }

    render() {
        const { communityAuditList, selectDataSource, selectData } = this.props;
        const { telephone, nickname, auditAtatus, imgTxtType, home } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='手机号码'
                                placeholder='请输入手机号码'
                                bindThis={this}
                                bindName='telephone'
                                value={telephone}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='会员昵称'
                                placeholder='请输入会员昵称'
                                bindThis={this}
                                bindName='nickname'
                                value={nickname}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='图文类型'
                                placeholder='请选择类型'
                                renderData={imgTxtTypes}
                                bindThis={this}
                                bindName='imgTxtType'
                                dataIndex='value'
                                keyIndex='key'
                                value={imgTxtType.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='社区分类'
                                placeholder='请选择所属分类'
                                renderData={selectData || []}
                                dataIndex='className'
                                keyIndex='id'
                                bindThis={this}
                                bindName='home'
                                value={home.className}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='审核状态'
                                placeholder='请选择审核状态'
                                renderData={selectDataSource}
                                bindThis={this}
                                bindName='auditAtatus'
                                dataIndex='value'
                                keyIndex='code'
                                value={auditAtatus.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableMain tableAction={this._tableAction} paginationChange={this._paginationChange} renderData={communityAuditList} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func,
    getCommunityAuditList: PropTypes.func,
    getVideoAuditSele: PropTypes.func,
    communityAuditList: PropTypes.object,
    selectDataSource: PropTypes.array,
    selectData: PropTypes.array, // 分类状态下拉选项
    getcommunityState: PropTypes.func,// 通过Saga异步请求下拉选项数据方法Action
};

const mapStateToProps = (state) => ({
    communityAuditList: state.communityAuditData.communityAuditList,
    selectDataSource: state.videoAudit.auditStatusSelect,
    selectData: state.channellist.communityDate.SelectData,
});

export default connect(mapStateToProps, { getCommunityAuditList, getVideoAuditSele, getcommunityState })(Main);