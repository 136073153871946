import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import { FloatConversion } from '@/util/conversion';

const swiNum = new FloatConversion();

/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getBusinessInfo = (T) => createSaga(T('getBusinessInfo'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/merchantEntering/info/merchantEnteringAuditInfo', { ...action.payload }, 'POST');
                let detail = {};
                if (result.result) {
                    const commitUnionImgList = (result.result.commitUnionImgList || []).map(item => {
                        return { ...item, isFail: false, isOver: false };
                    });
                    detail = { ...result.result, commitUnionImgList };
                }
                yield put({ type: T('originDetail'), payload: detail });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const businessInfo = (T) => createReducer((init) => {
    return (state = init, { type = '', payload = {} }) => {
        switch (type) {
            case T('originDetail'):
                return { ...payload };
            case T('uploadImg/GET'):
                return {
                    ...state,
                    commitUnionImgList: (state.commitUnionImgList || []).map(item => {
                        if (item.type === payload.type) {
                            return { ...item, ...payload };
                        }
                        return item;
                    })
                };
            default:
                return state;
        }
    };
}, {});

/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getBusinessClass = (T) => createSaga(T('getBusinessClass'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/merchantEntering/info/categoryList', { ...action.payload }, 'POST');
                const takeData = (list) => {
                    return list.map(item => {
                        let ele = {};
                        ele.title = item.categoryName;
                        ele.value = item.id ? item.id : parseInt(Math.random() * 100000000).toString();
                        ele.type = item.categoryType;
                        if (item.subList) {
                            ele.children = takeData(item.subList);
                        }
                        return ele;
                    });
                };
                yield put({ type: T('originalData'), payload: takeData(result.result) });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const businessClass = (T) => createReducer((initList) => {
    return (state = initList, { type = '', payload = {} }) => {
        switch (type) {
            case T('originalData'):
                return [...payload];
            default:
                return state;
        }
    };
}, []);

const model = {
    namespace: 'businessAudit',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getBusinessInfo': T('getBusinessInfo'),
            'getBusinessClass': T('getBusinessClass'),
            'getBusinessGoods': T('getBusinessGoods'),
            'uploadImg': T('uploadImg'),
        },
        actions: {
            'getBusinessInfo': A('getBusinessInfo'),
            'getBusinessClass': A('getBusinessClass'),
            'getBusinessGoods': A('getBusinessGoods'),
            'uploadImg': A('uploadImg'),
        },
        sagas: {
            'getBusinessInfo': getBusinessInfo(T),
            'getBusinessClass': getBusinessClass(T),
            'getBusinessGoods': S('getBusinessGoods', '/merchantEntering/info/categoryGoodsList'),
        },
        reducers: {
            'businessInfo': businessInfo(T),
            'businessClass': businessClass(T),
            'businessGoods': R('getBusinessGoods', []),
        }
    })
};
export default createModel(model);