import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getIllegalProductList, getIllegalProduct } from '@/reducers/auditmanage/illegalAudit/actions';
import { SearchBox, SearchItem } from '@/components/Layout';

class productIllegal extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
        productName: '',
        productNo: '',
        productStatus: {}
    }

    componentDidMount() {
        this.props.getIllegalProduct();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { productName, productStatus, productNo, pageNum, pageSize } = this.state;
        this.searchCache = {
            goodsName: productName, goodsNo: productNo, violationState: productStatus.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getIllegalProductList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ productName: '', productStatus: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'audit':
                console.log(id, type, params);
                this.props.updateContent('productAudit', params.violationId);
                break;
            case 'detail':
                console.log(id, type, params);
                this.props.updateContent('productDetail', params.violationId);
                break;
        }
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        const { productName, productStatus, productNo } = this.state;
        this.props.getIllegalProductList({ goodsName: productName, goodsNo: productNo, violationState: productStatus.code, pageNum, pageSize });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }


    render() {
        const { illegalProductList, illegalProduct } = this.props;
        const { productName, productStatus, productNo } = this.state;
        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商品名称'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='productName'
                                value={productName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='商品货号'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='productNo'
                                onChange={this._productNoChange}
                                value={productNo}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={illegalProduct}
                                bindThis={this}
                                bindName='productStatus'
                                dataIndex='value'
                                keyIndex='code'
                                value={productStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent paginationChange={this._paginationChange} renderData={illegalProductList} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }

}

productIllegal.propTypes = {
    updateContent: PropTypes.func,
    getIllegalProductList: PropTypes.func,
    getIllegalProduct: PropTypes.func,
    illegalProductList: PropTypes.object,
    illegalProduct: PropTypes.array
};

const mapStateToProps = (state) => ({
    illegalProductList: state.illegalAudit.illegalProductList,
    illegalProduct: state.illegalAudit.illegalProduct
});

export default connect(mapStateToProps, { getIllegalProductList, getIllegalProduct })(productIllegal);