/**
 * 【物流管理页】
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import RunningErrands from '../runningErrands';
import Logistics from '../logisticsErrands';
import CaiNiao from '../cainiao';

class Main extends Component {
    state = {
        tab: [
            { id: 1, label: '物流快送' },
            { id: 2, label: '跑腿配送' },
            { id: 3, label: '开通菜鸟物流' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
    };

    constructor(props) {
        super(props);
        if (props.history.location.state) {
            this.state.curTabID = props.history.location.state.curTabID == '2' ? 2 : 1;
        }
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    render() {
        const { curTabID } = this.state;

        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                {curTabID === 1 && <Logistics {...this.props} />}
                {curTabID === 2 && <RunningErrands {...this.props} />}
                {curTabID === 3 && <CaiNiao {...this.props} />}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object
};

export default Main;