import * as T from './actiontypes';

/**
 * 获取'社区评论列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {sting} feedType 固定为图文标识字符串 "GRAPHICS"
 *  
 */
export const getCommunitySource = (pageSize, pageNum,feedType) => ({
    type: T.GET_COMMUNIT_DATA,
    payload: {
        pageSize,
        pageNum,
        feedType
    }
});
 
/**
 * 获取'社区评论详情'数据源(saga)
 * @param {number} feedCommentId  获取某个评论的回复详情对应的id
 * 
 */
export const getCommunityDetails = (feedCommentId, pageNum, pageSize) => ({
    type: T.GET_COMMUNITDETAILS_DATA,
    payload: {
        feedCommentId,
        pageNum,
        pageSize
    }
});

/**
 * 获取'社区评论列表下拉列数据源'数据源(saga)
 * @param 暂无
 * 
 */
export const getCommunityselectDataSource = () => ({
    type: T.GET_COMMUNITSELECT_DATA,
});

/**
 * 获取'社区评论列表查询'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {number} feedClassId 下拉主键id
 * @param {sting} feedType 固定为图文标识字符串 "GRAPHICS"
 * @param {sting} feedTitle 主题名称
 * @param {sting} watchAccountName 评论人
 * @param {sting} content 评论内容
 * 
 */
export const getCommunitySelectSource = (value) => ({
    type: T.GET_COMMUNITSELECTSOURCE_DATA,
    payload: value
});


