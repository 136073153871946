import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import KeepAlive from '@/routes/KeepAlive';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { autoPageNum, autopageSize } from '@/assets/config';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { SearchBox, SearchItem } from '@/components/Layout';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';
import { XSelect, XOKButton, XCancelButton, XInput, XDatePicker, XPagination, showConfirm, XTableAdapter } from '@/components/xqxc_ui';
import { Button, Form, Icon, Input, message, Modal, Radio, Select, Table, DatePicker } from 'antd';
import model from '@/reducers/storehousefinancialmanage/transactionreconciliation/model';
import { getMerchantSelectSource } from '@/reducers/storehousefinancialmanage/merchantreconciliation/actions';
import CustomUpload from '@/components/NewUpload';
import BigNumber from 'bignumber.js';

const { RangePicker } = DatePicker;

const { getAdvanceTableData } = model.actions;

const Generate = ({
    tableAction,
    data: { merchantSele } = {},
    form: { getFieldDecorator, validateFields } = {}
}) => {

    const [loading, setLodaing] = useState(false);

    const onSubmit = e => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                const { targetId, billingCycle, remark } = values;
                setLodaing(true);
                http('/settle/reconciliationInfo/prepaid/addPrepaidReconciliation', {
                    targetId, remark, targetType: 'MERCHANT',
                    startDate: billingCycle[0].format('YYYY-MM-DD 00:00:00'),
                    endDate: billingCycle[1].format('YYYY-MM-DD 23:59:59'),
                    targetName: (merchantSele.filter(i => i.merchantId == targetId)[0] || {}).merchantName,
                }, 'POST').then(res => {
                    message.success(res.message);
                    setLodaing(false);
                    tableAction(true, 'closeModal');
                }).catch(err => {
                    message.error(err.message);
                    setLodaing(false);
                });
            }
        });
    };

    return <Modal
        width={460}
        bodyStyle={{ paddingBottom: 0 }}
        centered
        visible={true}
        confirmLoading={loading}
        title='生成对账单'
        onCancel={() => tableAction(false, 'closeModal')}
        onOk={onSubmit}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
    >
        <div className={styles.modalContainer}>
            <Form
                onSubmit={onSubmit}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
            >
                <Form.Item label='商户'>
                    {getFieldDecorator('targetId', {
                        rules: [{ required: true, message: '请选择商户' }],
                    })(
                        <Select placeholder='请选择商户'>{
                            merchantSele.map(i => (<Select.Option key={i.merchantId} value={i.merchantId}>{i.merchantName}</Select.Option>))
                        }</Select>
                    )}
                </Form.Item>
                <Form.Item label='对账周期'>
                    {getFieldDecorator('billingCycle', {
                        rules: [{ type: 'array', required: true, message: '请选择对账周期' }],
                    })(<RangePicker />)}
                </Form.Item>
                <Form.Item label='备注'>
                    {getFieldDecorator('remark', {
                        rules: [
                            { type: 'string', max: 100, message: '备注最大长度是100' }
                        ],
                    })(<Input.TextArea placeholder='请输入备注' autoSize={{ minRows: 3, maxRows: 5 }} />)}
                </Form.Item>
            </Form>
        </div>
    </Modal>;
};
Generate.propTypes = {
    form: PropTypes.object,
    tableAction: PropTypes.func,
    data: PropTypes.object
};
const GenerateForm = Form.create()(Generate);

const Remittance = ({
    tableAction, data: { id } = {},
    form: { getFieldDecorator, setFieldsValue, validateFields } = {}
}) => {

    const [loading, setLodaing] = useState(false);

    const onSubmit = e => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                const { remark, linkList } = values;
                setLodaing(true);
                http('/settle/reconciliationInfo/prepaid/updateReconciliationStatus', {
                    recordId: id, operationType: 'MANUAL_REMITTANCE', link: (linkList[0] || {}).url, remark
                }, 'POST').then(res => {
                    message.success(res.message);
                    setLodaing(false);
                    tableAction(true, 'closeModal');
                }).catch(err => {
                    message.error(err.message);
                    setLodaing(false);
                });
            }
        });
    };

    return <Modal
        width={460}
        bodyStyle={{ paddingBottom: 0 }}
        centered
        visible={true}
        title='手动汇款'
        onCancel={() => tableAction(false, 'closeModal')}
        confirmLoading={loading}
        onOk={onSubmit}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
    >
        <div className={styles.modalContainer}>
            <Form
                onSubmit={onSubmit}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
            >
                <Form.Item label='汇款状态'>
                    {getFieldDecorator('isRecommend', {
                        initialValue: 'SUCCESS',
                        rules: [{ required: true, message: '请选择汇款状态' }],
                    })(
                        <Radio.Group> {
                            [{ code: 'SUCCESS', value: '汇款成功' }].map(i => (<Radio key={i.code} value={i.code}>{i.value}</Radio>))
                        }</Radio.Group>
                    )}
                </Form.Item>
                <Form.Item label='上传凭证'>
                    {getFieldDecorator('linkList', {
                        valuePropName: 'fileList',
                        getValueFromEvent: e => Array.isArray(e) ? e : (e && e.fileList),
                    })(
                        <CustomUpload
                            style={{ height: '104px' }}
                            accept='.png, .jpg, .jpeg'
                            listType="picture-card"
                            wallMode="single"
                            qiniuUploadProps={{ uploadType: 'image' }}
                            beforeUpload={file => {
                                const imgTypes = ['image/png', 'image/jpg', 'image/jpeg'];
                                if (!imgTypes.includes(file.type)) {
                                    message.warn('请上传png,jpg格式的图片!');
                                    setFieldsValue({ linkList: [] });
                                    return false;
                                }
                                if (new BigNumber(file.size || 0).dividedBy(1024).dividedBy(1024).toNumber() > 3) {
                                    message.warn('图片必须小于 3MB!');
                                    setFieldsValue({ linkList: [] });
                                    return false;
                                }
                            }}
                        >
                            <div> <Icon type='plus' style={{ fontSize: 32, color: '#999' }} /></div>
                        </CustomUpload>)}
                </Form.Item>
                <Form.Item label='备注'>
                    {getFieldDecorator('remark', {
                        rules: [
                            { required: true, message: '请输入备注' },
                            { type: 'string', max: 100, message: '备注最大长度是100' }
                        ],
                    })(<Input.TextArea placeholder='请输入备注' autoSize={{ minRows: 3, maxRows: 5 }} />)}
                </Form.Item>
            </Form>
        </div>
    </Modal>;
};
Remittance.propTypes = {
    form: PropTypes.object,
    tableAction: PropTypes.func,
    data: PropTypes.object
};
const RemittanceForm = Form.create()(Remittance);

const TableComponent = ({
    renderData: {
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0,
        dataList = []
    } = {},
    paginationChange,
    tableAction,
    scrollY
}) => {
    const tableOperate = (id, r) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => tableAction(r, 'detail')}>对账详情</div>
            {r.status === 'NEW' && <div className={styles.item} onClick={() => tableAction(r, 'reconciliation')}>发起对账</div>}
            {r.status === 'CONFIRM_SUCCESS' && <div className={styles.item} onClick={() => tableAction(r, 'remittance')}>手动汇款</div>}
            {(r.status === 'NEW' || r.status === 'MERCHANT_REJECTED') && <div className={styles.item} onClick={() => tableAction(r, 'delete')}>删除</div>}
        </div>;
    };
    const columns = [
        {
            title: '对账单号',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '对账周期',
            dataIndex: 'billingCycle',
            key: 'billingCycle',
            align: 'center',
            width: 300,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            align: 'center',
            width: 240,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '完成交易金额(元)',
            dataIndex: 'finishOrderAmount',
            key: 'finishOrderAmount',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '收单手续费金额(元)',
            dataIndex: 'finishHandlingAmount',
            key: 'finishHandlingAmount',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '服务费(元)',
            dataIndex: 'finishServiceAmount',
            key: 'finishServiceAmount',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '应收金额(元)',
            dataIndex: 'finishTransferAmount',
            key: 'finishTransferAmount',
            align: 'center',
            width: 100,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '订单数',
            dataIndex: 'finishOrderNum',
            key: 'finishOrderNum',
            align: 'center',
            width: 100,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '生成时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            align: 'center',
            width: 100,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'subjectId',
            key: 'subjectId',
            align: 'center',
            fixed: 'right',
            width: 180,
            onCell: tooltipStyle,
            render: tableOperate
        }
    ];

    return <div className={styles.tableContainer} style={{ height: '100%' }}>
        <Table
            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
            rowKey='id'
            columns={columns}
            dataSource={dataList}
            pagination={false}
            scroll={{ x: 'max-content', y: scrollY }}
        />
        <XPagination
            resultTotal={resultTotal}
            pageSize={pageSize}
            pageNum={pageNum}
            onChange={paginationChange}
        />
    </div>;
};
TableComponent.propTypes = {
    history: PropTypes.object,
    renderData: PropTypes.object,
    paginationChange: PropTypes.func,
    tableAction: PropTypes.func,
    scrollY: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};

const AdvanceTable = ({
    history,
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getAdvanceTableData,
    tableData,
    getMerchantSelectSource,
    merchantSele
}) => {

    const [id, setId] = useState();
    const [merchantItem, setMerchantItem] = useState({});
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [pageNum, setPageNum] = useState(autoPageNum);
    const [pageSize, setPageSize] = useState(autopageSize);
    const [renderModal, setRenderModal] = useState('');
    const [modalParams, setModalParams] = useState();
    const [scrollY, setScrollY] = useState('');

    useEffect(() => {
        keepSecondNavBreadcrumb();
        getMerchantSelectSource();
    }, []);

    const getTableData = (params = {}) => {
        getAdvanceTableData({
            id, merchantId: params.merchantItem && params.merchantItem.merchantId,
            startTime: params.startTime, endTime: params.endTime,
            pageNum: params.pageNum, pageSize: params.pageSize
        });
        KeepAlive.saveCache(params);
    };

    const paginationChange = (ps, pn) => {
        setPageNum(pn);
        setPageSize(ps);
        getTableData({ id, merchantItem, startTime, endTime, pageNum: pn, pageSize: ps });
    };

    const resetSearch = () => {
        setId(); setMerchantItem({}); setStartTime(); setEndTime();
        KeepAlive.saveResetFunc(() => getTableData({ id, merchantItem, startTime, endTime, pageNum, pageSize }));
    };

    const tableAction = (key, type) => {
        const baseUrl = '/home/storehousefinancialmanage/dailystatement';
        const jumpPage = (path, title) => {
            addOneBreadcrumbPath({ title, path: `${baseUrl}${path}` });
            history.push({ pathname: `${baseUrl}${path}` });
        };
        const showConfirmEvent = ({ title, tip, url, params }) => {
            showConfirm(title, tip, () => {
                http(url, params, 'POST').then(res => {
                    message.success(res.message);
                    getTableData({ id, merchantItem, startTime, endTime, pageNum, pageSize });
                });
            });
        };
        const eventObject = {
            detail: p => jumpPage(`/advanceinfo/${p.id}/${p.merchantId}/${p.billingCycle}`, '明细列表'),
            delete: p => showConfirmEvent({
                title: '删除提示！', tip: '您是否删除当前对账',
                params: { recordId: p.id, operationType: 'DELETE' },
                url: '/settle/reconciliationInfo/prepaid/updateReconciliationStatus'
            }),
            reconciliation: p => showConfirmEvent({
                title: '对账提示！', tip: '您是否确定发起对账，发起对账后对账信息将同步商户',
                params: { recordId: p.id, operationType: 'INITIATE_RECONCILIATION' },
                url: '/settle/reconciliationInfo/prepaid/updateReconciliationStatus',
            }),
            remittance: p => {
                setRenderModal('remittance');
                setModalParams(p);
            },
            closeModal: p => {
                setRenderModal('');
                setModalParams();
                p && getTableData({ id, merchantItem, startTime, endTime, pageNum, pageSize });
            },
            generate: p => {
                setRenderModal('generate');
                setModalParams({ ...p, merchantSele });
            },
        };
        eventObject[type](key);
    };

    const onChange = (p = {}) => {
        if (p.id) setId(p.id);
        if (p.merchantItem) setMerchantItem(p.merchantItem);
        if (p.startTime) setStartTime(p.startTime);
        if (p.endTime) setEndTime(p.endTime);
        if (p.pageNum != autoPageNum) setPageNum(p.pageNum);
        if (p.pageSize != autopageSize) setPageSize(p.pageSize);
        getTableData(p);
    };

    const modals = { remittance: <RemittanceForm tableAction={tableAction} data={modalParams} />, generate: <GenerateForm tableAction={tableAction} data={modalParams} /> };

    return <KeepAlive render={onChange}>
        <div className={styles.flexBoxContainer}>
            <SearchBox>
                <SearchItem>
                    <XInput
                        label='对账单号'
                        placeholder='请输入'
                        value={id}
                        bindThis={setId}
                        bindName='id'
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        label='商户'
                        showSearch
                        placeholder='请选择商户'
                        renderData={merchantSele}
                        dataIndex='merchantName'
                        keyIndex='merchantId'
                        value={merchantItem.merchantName}
                        bindThis={setMerchantItem}
                        bindName='merchant'
                        isOptionBindID
                    />
                </SearchItem>
                <SearchItem>
                    <XDatePicker
                        placeholder='请选择开始时间'
                        label='对账日期'
                        value={startTime}
                        bindThis={setStartTime}
                        bindName='startTime'
                        isFormat={true}
                        showTime={false}
                        defaultTime='00:00:00'
                    />
                    <XDatePicker
                        placeholder='请选择结束时间'
                        style={{ marginRight: 20 }}
                        value={endTime}
                        bindThis={setEndTime}
                        bindName='endTime'
                        isFormat={true}
                        showTime={false}
                        defaultTime='23:59:59'
                    />
                </SearchItem>
                <SearchItem>
                    <XOKButton
                        label='查询'
                        onClick={() => getTableData({ id, merchantItem, startTime, endTime, pageNum, pageSize })}
                    />
                    <XCancelButton
                        label='重置'
                        onClick={resetSearch}
                    />
                </SearchItem>
            </SearchBox>
            <div><XOKButton
                label='生成对账单'
                style={{ width: '100px' }}
                onClick={() => tableAction('', 'generate')}
            /></div>
            <XTableAdapter render={v => setScrollY(v)}>
                <TableComponent renderData={tableData} scrollY={scrollY} tableAction={tableAction} paginationChange={paginationChange} />
            </XTableAdapter>
            {modals[renderModal]}
        </div>
    </KeepAlive>;
};
AdvanceTable.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getAdvanceTableData: PropTypes.func,
    tableData: PropTypes.object,
    getMerchantSelectSource: PropTypes.func,
    merchantSele: PropTypes.array
};
export default connect(state => ({
    tableData: state.transactionreconciliation.advanceTableData,
    merchantSele: state.merchantreconciliation.merchantSelectSource,
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getAdvanceTableData,
    getMerchantSelectSource
})(AdvanceTable);