import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount, XTableHOC } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell, cellWidth } from '@/components/TableCell';

class TableContent extends Component {
	state = {
		selectedRowKeys: [],
	};
	static propTypes = {
		tableAction: PropTypes.func,
		paginationChange: PropTypes.func,
		renderData: PropTypes.object,
		scrollY: PropTypes.string
	};
	_columns = () => [
		{
			title: '开票单号',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '公司简称',
			dataIndex: 'companyName',
			key: 'companyName',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '联系人',
			dataIndex: 'contactName',
			key: 'contactName',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '手机号',
			dataIndex: 'contactTel',
			key: 'contactTel',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '开票金额(元)',
			dataIndex: 'settleAmount',
			key: 'settleAmount',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: priceFenToYuanCell
		},
		{
			title: '已付金额(元)',
			dataIndex: 'paidAmount',
			key: 'paidAmount',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: priceFenToYuanCell
		},
		{
			title: '未付金额(元)',
			dataIndex: 'unpaidAmount',
			key: 'unpaidAmount',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: priceFenToYuanCell
		},
		{
			title: '状态',
			dataIndex: 'statusDescript',
			key: 'statusDescript',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: (text) => this._stateCell(text)
		},
		{
			title: '新建时间',
			dataIndex: 'gmtCreate',
			key: 'gmtCreate',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '寄送快递单号',
			dataIndex: 'expressNumber',
			key: 'expressNumber',
			align: 'center',
			width: cellWidth.normal,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'key',
			key: 'key',
			align: 'center',
			width: cellWidth.normal,
			fixed: 'right',
			render: (key, record) => this._tableAction(record.id, record)
		},
	];
	_moneyCell = (text) => {
		return text ? text / 100 : 0;
	}
	_stateCell = (text) => {
		return text;
	}
	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log(selectedRows);
			this.setState({ selectedRowKeys });
			this.props.tableAction(null, 'batch', selectedRowKeys);
			this.props.tableAction(null, 'batchRows', selectedRows);
		}
	};
	// 清空表格复选框
	_cleanAllSelected = () => {
		this.setState({ selectedRowKeys: [] });
		this.props.tableAction(null, 'batch', []);
		this.props.tableAction(null, 'batchRows', []);
	}
	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => tableAction(record.id, 'detail', record)}>详情</div>
				<div className={(record.status == 'INIT' && record.delivery) ? styles.item : styles.hide} onClick={() => tableAction(record.id, 'firAudit', record)}>初审</div>
				<div className={(record.status == 'FIRST_CONFIRM_SUCCESS' && record.delivery) ? styles.item : styles.hide} onClick={() => tableAction(record.id, 'secAudit', record)}>财务复审</div>
				<div className={record.allowCreatePayment ? styles.item : styles.hide} onClick={() => tableAction(record.id, 'createPay')}>新建付款</div>
			</div>
		);
	};
	_tableData = (data) => {
		return data ? data : [];
	}
	render() {
		const { renderData, paginationChange } = this.props;
		const { selectedRowKeys } = this.state;
		this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
				<XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowSelection={this._rowSelection}
					rowKey={record => record.id}
					columns={this._columns()}
					pagination={false}
					dataSource={this._tableData(renderData.dataList)}
					scroll={{ x: 'max-content', y: this.props.scrollY }}
				/>
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}
export default XTableHOC(TableContent, 40);