import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableHOC, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table, Badge } from 'antd';
import PropTypes from 'prop-types';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { tooltipStyle, tooltip, priceFenToYuanCell, cellWidth, tooltipClick } from '@/components/TableCell';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [],
    }
    _takeColumns = () => {
        const cols = [
            {
                title: '发票编号',
                dataIndex: 'id',
                key: 'id',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '付款金额(元)',
                dataIndex: 'amount',
                key: 'amount',
                width: cellWidth.normal,
                align: 'center',
                render: priceFenToYuanCell,
            },
            {
                title: '发票类型',
                dataIndex: 'type',
                key: 'type',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '发票状态',
                dataIndex: 'statusDesc',
                key: 'statusDesc',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '发票申请时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: tooltip
            },
        ];
        return cols;
    };

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys, selectedRows });
            this.props.tableAction(selectedRowKeys, 'batchData', selectedRows);
        },
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
        this.props.tableAction([], 'batchData', []);
    }

    render() {
        const { selectedRowKeys } = this.state;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    rowSelection={this._rowSelection}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string,
    batchKeys: PropTypes.array
};

export default TableContent;