import { createModel } from '@/store/tools';

const model = {
    namespace: 'advocacytask',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getAdvocacyTableList': T('getAdvocacyTableList'),
            'getAdvocacyType': T('getAdvocacyType'),
            'getAdvocacyState': T('getAdvocacyState'),
            'getReceiveDetail': T('getReceiveDetail'),
            'getReceiveState': T('getReceiveState'),

        },
        actions: {
            'getAdvocacyTableList': A('getAdvocacyTableList'), // 列表数据
            'getAdvocacyType': A('getAdvocacyType'), // 代言类型数据
            'getAdvocacyState': A('getAdvocacyState'), // 代言任务归属
            'getReceiveDetail': A('getReceiveDetail'), // 领取详情列表数据
            'getReceiveState': A('getReceiveState'), // 领取详情列表查询状态数据
        },
        sagas: {
            'getAdvocacyTableList': S('getAdvocacyTableList', '/spokesmantask/config/spokesmanTaskConfigList'),
            'getAdvocacyType': S('getAdvocacyType', '/spokesmantask/task/getSpokesmanTaskType'),
            'getAdvocacyState': S('getAdvocacyState', '/enum/SystemTypeEnum'),
            'getReceiveDetail': S('getReceiveDetail', '/spokesmantask/config/getUserSpokesmanTaskList'),
            'getReceiveState': S('getReceiveState', '/spokesmantask/task/getSpokesmanTaskUserStateEnum'),
        },
        reducers: {
            'advocacyTableList': R('getAdvocacyTableList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'advocacyType': R('getAdvocacyType', []),
            'advocacyState': R('getAdvocacyState', []),
            'receiveDetail': R('getReceiveDetail', { dataList: [], pageNum: 1, pageSize: 10, resultTotal: 0 }),
            'receiveState': R('getReceiveState', []),
        }
    })
};
export default createModel(model);