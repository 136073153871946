//获取/保存活动商品审核列表
export const GET_ACTIVEPRODUCTAUDIT_LIST = 'ACTIVEPRODUCTAUDIT/GET_ACTIVEPRODUCTAUDIT_LIST';
export const SET_ACTIVEPRODUCTAUDIT_LIST = 'ACTIVEPRODUCTAUDIT/SET_ACTIVEPRODUCTAUDIT_LIST';

//获取/保存状态下拉
export const GET_ACTIVEPRODUCTAUDIT_STATUS = 'ACTIVEPRODUCTAUDIT/GET_ACTIVEPRODUCTAUDIT_STATUS';
export const SET_ACTIVEPRODUCTAUDIT_STATUS = 'ACTIVEPRODUCTAUDIT/SET_ACTIVEPRODUCTAUDIT_STATUS';

//获取/保存活动商品审核详情
export const GET_ACTIVEPRODUCTAUDIT_DETAIL = 'ACTIVEPRODUCTAUDIT/GET_ACTIVEPRODUCTAUDIT_DETAIL';
export const SET_ACTIVEPRODUCTAUDIT_DETAIL = 'ACTIVEPRODUCTAUDIT/SET_ACTIVEPRODUCTAUDIT_DETAIL';

//获取/保存活动商品审核信息
export const GET_ACTIVEPRODUCTAUDIT_INFO = 'ACTIVEPRODUCTAUDIT/GET_ACTIVEPRODUCTAUDIT_INFO';
export const SET_ACTIVEPRODUCTAUDIT_INFO = 'ACTIVEPRODUCTAUDIT/SET_ACTIVEPRODUCTAUDIT_INFO';

//获取/保存店铺下拉
export const GET_ACTIVEPRODUCTAUDIT_SHOPSELE = 'ACTIVEPRODUCTAUDIT/GET_ACTIVEPRODUCTAUDIT_SHOPSELE';
export const SET_ACTIVEPRODUCTAUDIT_SHOPSELE = 'ACTIVEPRODUCTAUDIT/SET_ACTIVEPRODUCTAUDIT_SHOPSELE';

//获取/保存活动商品退出申请列表
export const GET_ACTIVEPRODUCTAUDIT_EXITLIST = 'ACTIVEPRODUCTAUDIT/GET_ACTIVEPRODUCTAUDIT_EXITLIST';
export const SET_ACTIVEPRODUCTAUDIT_EXITLIST = 'ACTIVEPRODUCTAUDIT/SET_ACTIVEPRODUCTAUDIT_EXITLIST';

//获取/保存活动商品退出申请详情
export const GET_ACTIVEPRODUCTAUDIT_EXITINFO = 'ACTIVEPRODUCTAUDIT/GET_ACTIVEPRODUCTAUDIT_EXITINFO';
export const SET_ACTIVEPRODUCTAUDIT_EXITINFO = 'ACTIVEPRODUCTAUDIT/SET_ACTIVEPRODUCTAUDIT_EXITINFO';