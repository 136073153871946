/**
 *  系统管理 - 支付通道设置
 * */ 
import React from 'react';
import PropTypes from 'prop-types'; 
import Main from './pages/main';

class Payment extends React.PureComponent {

    render(){
        return(
            <Main /> 
        );
    }
}

Payment.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
};


export default Payment;