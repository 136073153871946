import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const LoadMore = ({ children, style, onChange, customTemplateClassId, category }) => {

    let ladoMore = useRef();

    useEffect(() => {
        ladoMore.scrollTop = 0;
    }, [customTemplateClassId, category]);

    const onScroll = () => {
        if ((ladoMore.scrollHeight - ladoMore.clientHeight) <= ladoMore.scrollTop) {
            onChange(true);
        }
    };

    return <div
        style={{ overflowY: 'auto', ...style }}
        ref={ele => ladoMore = ele}
        onScroll={onScroll}>
        {children}
    </div>;
};

LoadMore.defaultProps = {
    style: {}
};

LoadMore.propTypes = {
    children: PropTypes.element.isRequired,
    style: PropTypes.object,
    onChange: PropTypes.func,
    customTemplateClassId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    category: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

export default LoadMore;