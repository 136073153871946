/**
 * 【魅力值订单页】
 */
import React from 'react';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker } from '@/components/xqxc_ui';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getStarOrderList, getPayWays, getDeviceTypeList } from '@/reducers/ordermanage/starOrder/actions';
import { getPaymentAccessTypeEnum } from '@/reducers/ordermanage/productOrder/actions';

import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
class Main extends React.Component {

    state = {
        tel: '', // 会员手机
        nick: '', // 会员昵称
        payType: {}, // 支付方式
        payAccessType: {}, // 支付通道
        deviceType: {}, //充值方式
        startDate: null, // 充值开始日期
        endDate: null, // 充值结束日期
        payId: '',
        outPayId: '',
        pageNum: 1,
        pageSize: 50
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getStarOrderList(this.state.parm);
        // 支付方式
        this.props.getPayWays();
         // 支付通道
         this.props.getPaymentAccessTypeEnum();
        // 充值来源
        this.props.getDeviceTypeList();
    }
    _searchHandle = (useCache) => {
        const { tel, outPayId, nickname, payType, payAccessType, deviceType, startDate, endDate, pageNum, pageSize, payId } = this.state;
        this.searchCache = {
            tel,
            nickname,
            channelType: payType.code,
            payAccessType: payAccessType.code,
            deviceType: deviceType.code,
            startDate,
            endDate,
            payId,
            outPayId,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getStarOrderList(this.searchCache);
    }
    // 重置
    _resetHandle = () => {
        this.setState({
            tel: '',
            nickname: '',
            payType: {},
            payAccessType: {},
            deviceType: {},
            startDate: null,
            endDate: null,
            pageNum: 1,
            pageSize: 50,
            payId: '',
            outPayId: '',
        }, () => {
            this._searchHandle('useCache');
        });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageSize,
            pageNum,
        }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { starOrderList, deviceTypeList, paymentAccessTypeEnum } = this.props;
        const { tel, nickname, payType, payAccessType, outPayId, startDate, endDate, payId, deviceType } = this.state;
        const { paymentChannels = [] } = this.props.payWaysList;
        return (
            <KeepAlive id='3' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='会员手机'
                                placeholder='请输入手机号码'
                                value={tel}
                                bindThis={this}
                                bindName='tel'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='支付单号'
                                placeholder='请输入支付单号'
                                value={payId}
                                bindThis={this}
                                bindName='payId'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='第三方单号'
                                placeholder='请输入第三方单号'
                                value={outPayId}
                                bindThis={this}
                                bindName='outPayId'
                            />
                        </SearchItem>
                        <SearchItem>
                            {/* <XInput
                        style={{ width: '248px',  }}
                        inputStyle={{ width: '180px' }}
                        label='会员昵称'
                        placeholder='请输入会员昵称'
                        value={nickname}
                        bindThis={this}
                        bindName='nickname'
                    /> */}
                            <XSelect
                                label='支付方式'
                                placeholder='请选择支付方式'
                                renderData={paymentChannels}
                                dataIndex='value'
                                keyIndex='code'
                                value={payType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='payType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='支付通道'
                                placeholder='请选择支付通道'
                                renderData={paymentAccessTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={payAccessType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='payAccessType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='充值来源'
                                placeholder='请选择充值来源'
                                renderData={deviceTypeList}
                                dataIndex='value'
                                keyIndex='code'
                                value={deviceType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='deviceType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='充值时间'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat
                            />
                            <div className={styles.timeLine} />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                label=''
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <OrderTable renderData={starOrderList} paginationChange={this._paginationChange} />
                </>
            </KeepAlive >
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getStarOrderList: PropTypes.func,
    starOrderList: PropTypes.object,
    getPayWays: PropTypes.func,
    payWaysList: PropTypes.object,
    getDeviceTypeList: PropTypes.func,
    deviceTypeList: PropTypes.array,
    getPaymentAccessTypeEnum: PropTypes.func,
    paymentAccessTypeEnum: PropTypes.array,
};
const mapStateToProps = (state) => ({
    starOrderList: state.StarOrder.starOrderList,
    payWaysList: state.StarOrder.payWaysList,
    deviceTypeList: state.StarOrder.deviceTypeList,
    paymentAccessTypeEnum: state.productOrder.paymentAccessTypeEnum,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getStarOrderList, getPayWays, getPaymentAccessTypeEnum, getDeviceTypeList })(Main);
