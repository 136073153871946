/**
 * 代言任务 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

/**
 * 列表 Saga  
 */
export const advocacytaskList = function* () {
    yield takeEvery(T.GET_ADVOCACYLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/spokesmantask/config/spokesmanTaskConfigList', action.payload, 'POST');
            yield put({ type: T.SET_ADVOCACYLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 代言任务编辑、详情获取数据 Saga
 */
export const advocacyDetails = function* () {
    yield takeEvery(T.GET_ADVOCACYDETAILS_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/spokesmantask/config/infoSpokesmanTaskConfig', action.payload, 'POST');
            yield put({ type: T.SET_ADVOCACYDETAILS_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 代言任务下拉框 Saga
 */
export const advocacyDropDown = function* () {
    yield takeEvery(T.GET_DROPDIWN_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let activity = yield http('/spokesmantask/task/getSpokesmanTaskType', action.payload, 'POST');//代言类型
            let activeState = yield http('/spokesmantask/task/getSpokesmanTaskStatus', action.payload, 'POST');//代言状态
            activity.activeState = activeState.result;
            yield put({ type: T.SET_DROPDIWN_DATA, payload: activity });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 代言任务新建-选择商品下拉框、和全选复选、优惠劵名称、奖励类型、任务执行时间Saga
 */
export const advocacyRaiseAll = function* () {
    yield takeEvery(T.GET_RAISEALL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象  
        try {
            let result = yield http('/merchant/info/listMerchantIdAndName', action.payload, 'POST');//代言任务新建选择商品下拉框
            let checkall = yield http('/user/account/listAccountLevel', action.payload, 'POST');//代言任务新建-全选复选
            let reward = yield http('/taskInfo/recordType', action.payload, 'POST');//代言任务新建-奖励类型
            let coupon = yield http('/coupon/coupon/couponAvailableListByName', action.payload, 'POST');//代言任务新建-优惠劵名称
            let execution = yield http('/spokesmantask/task/getSpokesmanExecutionType', action.payload, 'POST');//任务执行时间
            yield put({ type: T.SET_REWARD_DATA, payload: reward });
            yield put({ type: T.SET_RAISEDATA_DATA, payload: result });
            yield put({ type: T.SET_ALL_DATA, payload: checkall });
            yield put({ type: T.SET_COUPONNAME_DATA, payload: coupon });
            yield put({ type: T.SET_EXECUTION_DATA, payload: execution });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 代言任务新建-选择商品表格Saga
 */
export const advocacyRaiseTable = function* () {
    yield takeEvery(T.GET_RAISETABALE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let table = yield http('/goods/goods/showGoodsList', action.payload, 'POST');//代言任务新建选择商品表格数据
            yield put({ type: T.SET_RAISETABALE_DATA, payload: table });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
