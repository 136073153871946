import React from 'react';
import styles from './TableStyle.module.scss';
import PropTypes from 'prop-types';
import { Table, Switch, Tooltip } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { ImgCell, tooltipStyle, tooltip, cellWidth, tooltipClick, priceFenToYuanCell } from '@/components/TableCell';
import { secToHms } from '@/util/timeSwitch';
import { isEmpty } from '@/assets/js/utils';
import http from '@/assets/api/http';

const TableContent = ({ renderData, tableAction, paginationChange, scrollY }) => {

    //表头
    const _columns = () => {
        return [
            {
                title: '手机号码',
                dataIndex: 'tel',
                key: 'tel',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '主播昵称',
                dataIndex: 'nickname',
                key: 'nickname ',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '封面',
                dataIndex: 'coverPage',
                key: 'coverPage',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: ImgCell
            },
            {
                title: '直播间标题',
                dataIndex: 'broadcasTitle',
                key: 'broadcasTitle',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '所属分类',
                dataIndex: 'chargeClassName',
                key: 'chargeClassName',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '直播间状态',
                dataIndex: 'liveState',
                key: 'liveState',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '观看人数',
                dataIndex: 'realOnlineNumber',
                key: 'realOnlineNumber',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '魅力值收入',
                dataIndex: 'starLightNumber',
                key: 'starLightNumber',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: (text, item) => tooltipClick(text, () => tableAction(item.liveChargeId, 'beans', item))
            },
            {
                title: '销售商品分红（元）',
                dataIndex: 'saleBonus',
                key: 'saleBonus',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: (text, item) => tooltipClick(priceFenToYuanCell(text), () => tableAction(item.liveChargeId, 'sell', item))
            },
            {
                title: '知识付费收益（元）',
                dataIndex: 'chargeBonus',
                key: 'chargeBonus',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: (text, item) => tooltipClick(priceFenToYuanCell(text), () => tableAction(item.liveChargeId, 'knowledge', item))
            },
            {
                title: '本场违规',
                dataIndex: 'detectNum',
                key: 'detectNum',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: (text, record) => _violation(text, record)
            },
            {
                title: '直播时长',
                dataIndex: 'liveTimes',
                key: 'liveTimes',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: (text) => secToHms(text)
            },
            {
                title: '首页推荐',
                dataIndex: 'isAllReco',
                key: 'isAllReco',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: (text, item) => _switchCell(text, item, 'home')
            },
            {
                title: '频道推荐',
                dataIndex: 'isChannelReco',
                key: 'isChannelReco',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: (text, item) => _switchCell(text, item, 'channel')
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                width: cellWidth.normal,
                align: 'center',
                fixed: 'right',
                onCell: tooltipStyle,
                render: (id, item) => _tableAction(item.accountId, item)
            }
        ];
    };

    const _tableAction = (id, item) => {
        if (item.liveState === '回放') {
            return (<div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, 'playback', item)}>回放</div>
                <div className={styles.item} onClick={() => tableAction(id, 'prohibit', item)}>禁播</div>
            </div>);
        } else {
            return (<div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, 'live', item)}>播放</div>
                <div className={styles.item} onClick={() => tableAction(id, 'prohibit', item)}>禁播</div>
            </div>);
        }
    };
    //本场违规
    const _violation = (text, record = {}) => {
        return (
            <div className={styles.violation}>
                <div className={styles.item} onClick={() => { ViolationClickHandle(record); }}>
                    {_vtooltip(text, record)}
                </div>
            </div>
        );
    };
    const _vtooltip = (value, record) => {
        return <Tooltip placement="top" title={<pre className={styles.VTitleStyles}>{value}</pre>}>
            <div className={styles.layout}>
                <pre className={styles.VPreStyles}>
                    {isEmpty(value) ? '-' : value}
                </pre>
                {record.isDetectRead == '0' && <span className={styles.curt}>未读</span>}
            </div>
        </Tooltip>;
    };

    //更新直播间为已读违规记录
    const ViolationClickHandle = (record) => {
        tableAction(record.accountId, 'violation', record);
        if (record.isDetectRead == '0') {
            http('/show/live/readLiveDetectRecord', {
                liveType: 'CHARGE_LIVE_BORADCAS_CLASS',//知识付费直播
                liveId: record.liveChargeId,
            }, 'POST').then(res => { });
        }
    };

    // 表格项内容为开关
    const _switchCell = (switchStatus, item, type) => {
        let checked = true;
        if (switchStatus == 0) {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={checked}
                    onChange={(e) => {
                        if (type == 'home') {
                            tableAction(item.id, 'home', { state: e, accountId: item.accountId }); // 首页推荐 
                        } else {
                            tableAction(item.id, 'channel', { state: e, accountId: item.accountId, chargeClassId: item.chargeClassId }); // 频道推荐
                        }
                    }}
                />
            </div>
        );
    };

    return (
        <div className={styles.tableContainer} style={{ height: '100%' }}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                columns={_columns()}
                dataSource={renderData.dataList}
                pagination={false}
                scroll={{ x: 'max-content', y: scrollY }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange} />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func,
    scrollY: PropTypes.string
};

export default TableContent;