import React from 'react';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import MusicType from '../musictype';
import MusicList from '../musiclist';

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '音乐分类' },
            { id: 2, label: '音乐列表' }
        ],
        curTabID: 1
    }

    isReload = new Set();

    componentDidMount() {
        this.isReload.add(1);
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        const { curTabID } = this.state;
        const isRequest = !this.isReload.has(curTabID);

        switch (curTabID) {
            case 1: return <MusicType curTabID={curTabID} isRequestPageData={isRequest} initConditions={this.state.MusicTypeConditions} updateConditionCallBack={(MusicTypeConditions) => this.setState({ MusicTypeConditions })} />;
            case 2: return <MusicList curTabID={curTabID} isRequestPageData={isRequest} initConditions={this.state.MusicListConditions} updateConditionCallBack={(MusicListConditions) => this.setState({ MusicListConditions })} />;
            default: return <MusicType curTabID={curTabID} isRequestPageData={isRequest} initConditions={this.state.MusicTypeConditions} updateConditionCallBack={(MusicTypeConditions) => this.setState({ MusicTypeConditions })} />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={`${styles.page} ${styles.flexBoxContainer}`}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

export default Main;