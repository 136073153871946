import * as SALE from './actionTypes';


const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
// 售后列表
export const saleList = (state = initData, action) => {
    switch (action.type) {
        case SALE.SET_SALELIST_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
// 售后仅退款详情
export const saleOnlyMoneyDetail = (state = {}, action) => {
    switch (action.type) {
        case SALE.SET_SALELIST_DETAIL_ONLYMONEY:
            return action.payload.result;
        default:
            return state;
    }
};
// 售后退货退款详情
export const saleGoodsandmoneyDetail = (state = {}, action) => {
    switch (action.type) {
        case SALE.SET_SALELIST_DETAIL_GOODSMONEY:
            return action.payload.result;
        default:
            return state;
    }
};
// 售后换货详情
export const saleChangeGoodsDetail = (state = {}, action) => {
    switch (action.type) {
        case SALE.SET_SALELIST_DETAIL_CHANGEGOODS:
            return action.payload.result;
        default:
            return state;
    }
};
// 受理详情
export const processDetail = (state = {}, action) => {
    switch (action.type) {
        case SALE.SET_PROCESS_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};
// 售后状态
export const saleStatus = (state = [], action) => {
    switch (action.type) {
        case SALE.SET_SALESTATUS:
            return action.payload.result;
        default:
            return state;
    }
};
// 售后类型
export const saleType = (state = [], action) => {
    switch (action.type) {
        case SALE.SET_SALETYPE:
            return action.payload.result;
        default:
            return state;
    }
};