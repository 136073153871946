import { createModel } from '@/store/tools';

const model = {
    namespace: 'businessmanage',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getMemberListData': T('getMemberListData'),
            'getMemberSubtotal': T('getMemberSubtotal'),
            'getMemberInfoData': T('getMemberInfoData'),
            'getMemberInfoSubtotal': T('getMemberInfoSubtotal'),
            'getFranchiseLogs': T('getFranchiseLogs'),
        },
        actions: {
            'getMemberListData': A('getMemberListData'),
            'getMemberSubtotal': A('getMemberSubtotal'),
            'getMemberInfoData': A('getMemberInfoData'),
            'getMemberInfoSubtotal': A('getMemberInfoSubtotal'),
            'getFranchiseLogs': A('getFranchiseLogs'),
        },
        sagas: {
            'getMemberListData': S('getMemberListData', '/merchant/info/listSuccessMerchantInviteAccount'),
            'getMemberSubtotal': S('getMemberSubtotal', '/merchant/info/merchantInviteAccountInfo'),
            'getMemberInfoData': S('getMemberInfoData', '/settle/reconciliationSource/queryMerchantMemberInfo'),
            'getMemberInfoSubtotal': S('getMemberInfoSubtotal', '/settle/reconciliationSource/merchantInviteAccountInfo', 'POST', 'merchantId'),
            'getFranchiseLogs': S('getFranchiseLogs', '/merchant/info/integralRateLog'),
        },
        reducers: {
            'memberListData': R('getMemberListData', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'memberSubtotal': R('getMemberSubtotal', {}),
            'memberInfoData': R('getMemberInfoData', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'memberInfoSubtotal': R('getMemberInfoSubtotal', {}),
            'franchiseLogs': R('getFranchiseLogs', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);