/**
 * 审核管理 - 主播封面异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Audit from './actiontypes';

export const getWithdrawList = function* () {
    yield takeEvery(Audit.GET_WITHDRAW_LIST, function* requestData(action) {
        try {
            let result = yield http('/plat/withdrawal/list', action.payload, 'POST'); // action.payload为传入的参数对象
            console.log(result.result);
            yield put({ type: Audit.SET_WITHDRAW_LIST, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getWithdrawDetail = function* () {
    yield takeEvery(Audit.GET_WITHDRAW_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/plat/withdrawal/details/' + action.payload.id, action.payload, 'GET'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_WITHDRAW_DETAIL, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getWithdrawSele = function* () {
    yield takeEvery(Audit.GET_WITHDRAW_SELE, function* requestData(action) {
        try {
            let result = yield http('/enum/WithDrawalStatusEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            console.log(result.result);
            yield put({ type: Audit.SET_WITHDRAW_SELE, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getPayDetail = function* () {
    yield takeEvery(Audit.GET_PAY_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/plat/withdrawal/batch/query', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_PAY_DETAIL, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
export const getServiceTypeEnum = function* () {
    yield takeEvery(Audit.GET_SERVICE_TYPE_ENUM, function* requestData(action) {
        try {
            let result = yield http('/enum/ServiceProviderRoleEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Audit.SET_SERVICE_TYPE_ENUM, payload: result.result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

