/**
 * [表格数据列表]
 * @author: Fu Xiaochun
 * @date: 2021-11-09 
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Button, Table, Tooltip } from 'antd';
import XPagination from '@/components/Pagination';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell, priceDecimal2Cell, priceDecimal3Cell } from '@/components/TableCell';
import { XRangePicker, XMediaFn } from '@/components/xqxc_ui';

const renderAvatar = (key, item)=>{
    if(item.avatar == '-'){
        return <div className={styles.noAvatar}>--</div>;
    }
    return <XMediaFn style={{width: '120px', height: '120px'}} dataType='image' dataSource={item.avatar} />;
};

const renderOrigin = (key, item)=>{
    let val = item.isHistory ? <span>历史数据</span> : <span>新建数据</span>;
    return val;
};


const columns = (TableAction, actionClickHandle) => [
    {
        title: '头像',
        dataIndex: 'avatar',
        key: 'avatar',
        align: 'left',
        width: '120px',
        render: renderAvatar
    },
    {
        title: '昵称',
        dataIndex: 'nickname',
        key: 'nickname',
        align: 'left',
        width: '150px',
        render: tooltip
    },
    {
        title: '快速审核',
        dataIndex: '',
        align: 'left',
        width: '100px',
        render: (id, item) => fastCheck(item, actionClickHandle)
    },
    {
        title: '会员账号',
        dataIndex: 'accountName',
        key: 'accountName',
        align: 'left',
        width: '100px',
    },
    {
        title: '修改类型',
        dataIndex: 'auditTypeDesc',
        key: 'auditTypeDesc',
        align: 'left',
        width: '80px',
        render: tooltip
    },
    {
        title: '数据来源',
        dataIndex: 'isHistory',
        key: 'isHistory',
        align: 'left',
        width: '80px',
        render: renderOrigin
    },
    {
        title: '数据分组',
        dataIndex: 'group',
        key: 'group',
        align: 'left',
        width: '80px',
        render: tooltip
    },
    {
        title: '审核状态',
        dataIndex: 'auditStateDesc',
        key: 'auditStateDesc',
        align: 'left',
        width: '100px',
        render: tooltip
    },
    {
        title: '提交时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        align: 'left',
        width: '150px',
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'left',
        width: '130px',
        render: (id, item) => TableAction(item, actionClickHandle)
    }
];

// 快速审核
const fastCheck = (item, actionClickHandle)=>{
    if(item.auditState !== 'NOT_AUDIT'){
        return <div> / </div>;
    }
    return (
        <div><Button type="primary" onClick={() => actionClickHandle(item.id, 'fastpass', item)}>一键通过</Button></div>
    );
};

// 表格操作
const TableAction = (item, actionClickHandle) => {
    return (
        <div className={styles.tableActionCell}>
            <div className={styles.item} onClick={() => actionClickHandle(item.id, 'detail', item)}>详情</div>
            {item.auditState == 'NOT_AUDIT' && <div className={styles.item} onClick={() => actionClickHandle(item.id, 'audit', item)}>审核</div>}
            {/* {item.auditState == 'NOT_AUDIT' && <div className={styles.item} onClick={() => actionClickHandle(item.id, 'pass', item)}>通过</div>} */}
        </div>
    );
};

class TableData extends Component {
    constructor(props) {
        super(props);
    }

    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            let _list = selectedRows.map(v=>{
                return {
                    id: v.id,
                    avatarAuditState: 'PASS_AUDIT',
                    nicknameAuditState: 'PASS_AUDIT',
                    nickname: v.nickname,
                    avatar: v.avatar
                };
            });
            this.props.setRowSelections([..._list]);
        },
        getCheckboxProps: record => ({
            disabled: record.auditState !== 'NOT_AUDIT', // Column configuration not to be checked
        }),
    };

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { renderData, selectedRowKeys } = this.props;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowKey='id'
                    rowSelection={this._rowSelection}
                    columns={columns(TableAction, this._actionHandle)}
                    pagination={false}
                    dataSource={renderData.dataList}
                    scroll={{y: true}}
                />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={this.props.paginationChange}
                />
            </div>
        );
    }
}

TableData.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
    setRowSelections: PropTypes.func,
    selectedRowKeys: PropTypes.array
};

export default TableData;