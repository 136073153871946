import React, { useState, useEffect, Fragment } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, message, Modal, Switch, Table, Tooltip, Typography, DatePicker, Radio, Button } from 'antd';
import { autoPageNum, autopageSize } from '@/assets/config';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { XTitle, XOKButton, showConfirm } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { SketchPicker } from 'react-color';
import JumpLink from './JumpLink';
import model from '@/reducers/systemmanage/suppliesset/model';
import moment from 'moment';

const { getHotWordTableData } = model.actions;

const { Text } = Typography;

//编辑、创建、详情弹框
const InfoOperateModal = ({
    tableAction,
    data: { title, id, paramValue, colour, startTime, endTime, isRecommend, link = {}, readOnly } = {},
    form: { getFieldDecorator, validateFields, setFieldsValue } = {}
}) => {

    const [loading, setLoading] = useState(false);
    const [color, setColor] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [tempLink, setTempLink] = useState(link);

    useEffect(() => {
        setColor(colour);
        setFieldsValue({ colour });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                const { paramValue, colour, startTime, endTime, isRecommend } = values;
                let link = {};
                link.linkType = typeof (tempLink.linkType) === 'object' ? tempLink.linkType.code : tempLink.linkType;
                link.categoryId = tempLink.categoryId;
                link.categoryName = tempLink.categoryName;
                link.goodsId = tempLink.goodsId;
                link.goodsName = tempLink.goodsName;
                link.shopId = tempLink.shopId;
                link.strategyId = tempLink.strategyId;
                link.strategyName = tempLink.strategyName;
                link.menuName = tempLink.menuName;
                setLoading(true);
                http(!id ? '/supplier/config/topSearch/add' : '/supplier/config/topSearch/update', {
                    id, paramValue, colour, isRecommend, link,
                    startTime: startTime.format('YYYY-MM-DD HH:mm:00'),
                    endTime: endTime.format('YYYY-MM-DD HH:mm:00'), paramType: 'TOP_SEARCH'
                }, 'POST').then(res => {
                    message.success(res.message);
                    setLoading(false);
                    tableAction('closeModal', true);
                }).catch(err => {
                    setLoading(false);
                    message.error(err.message);
                });
            }
        });
    };

    const onCheckLink = (params = []) => {
        setFieldsValue({ linkName: (params[0] || {}).title });
        setTempLink(params[0] || {});
        setOpenModal(false);
    };

    let linkName = '';
    if (link.linkType) {
        const keyValue = {
            CATEGORY: link.categoryName,
            GOODS_INFO: link.goodsName,
            STRATEGY_CLASS: link.strategyName,
            STRATEGY_INFO: link.strategyName,
            HELP_CENTER: link.menuName,
            PERSON_CENTER: link.menuName,
        };
        linkName = keyValue[link.linkType.code];
    }

    return <Modal
        width={600}
        centered
        title={title}
        visible={true}
        bodyStyle={{ padding: '20px 20px 10px 20px' }}
        confirmLoading={false}
        onCancel={() => tableAction('closeModal')}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        footer={false}
    >
        <Form
            onSubmit={onSubmit}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
        >
            <Form.Item label='热搜词'>
                {getFieldDecorator('paramValue', {
                    initialValue: paramValue,
                    rules: [
                        { required: true, message: '请输入热搜词' },
                        { type: 'string', max: 10, message: '热搜词最多10个汉字' }
                    ],
                })(<Input disabled={readOnly} style={{ width: '150px' }} placeholder='请输入热搜词' />)}
                <Text style={{ marginLeft: '10px' }} type='secondary'>最多只能输入10个汉字</Text>
            </Form.Item>
            <Form.Item label='文字颜色' style={{ height: '42px' }}>
                {getFieldDecorator('colour')(
                    <Tooltip
                        overlayClassName={styles.toolTip}
                        placement='bottom'
                        title={
                            <SketchPicker
                                disableAlpha
                                width='220px'
                                color={color}
                                onChange={color => {
                                    if (readOnly) return;
                                    let hex = color.hex || '';
                                    let hexUpperCase = hex.toLocaleUpperCase();
                                    setColor(hexUpperCase);
                                    setFieldsValue({ colour: hexUpperCase });
                                }}
                            />
                        }><span style={{
                            display: 'inline-block',
                            backgroundColor: color,
                            border: '1px solid #cccccc',
                            color: '#cccccc',
                            width: '230px',
                            height: '32px',
                            lineHeight: '32px',
                            padding: '0 11px',
                            marginTop: '3px'
                        }}>{!color ? '请选择颜色' : ''}</span>
                    </Tooltip>)}
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }} label={<Text>
                <Text style={{ color: '#f5222d', fontSize: '14px', marginRight: '4px' }}>*</Text>设置执行时间</Text>}
            >
                <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 12px)' }} >
                    {getFieldDecorator('startTime', {
                        initialValue: startTime ? moment(startTime) : null,
                        rules: [{ type: 'object', required: true, message: '请选择' },]
                    })(<DatePicker disabled={readOnly} placeholder='请选择开始时间' showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />)}
                </Form.Item>
                <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 12px)', marginLeft: '5px' }}>
                    {getFieldDecorator('endTime', {
                        initialValue: endTime ? moment(endTime) : null,
                        rules: [{ type: 'object', required: true, message: '请选择' }],
                    })(<DatePicker disabled={readOnly} placeholder='请选择结束时间' showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />)}
                </Form.Item>
            </Form.Item>
            <Form.Item label='是否置顶'>
                {getFieldDecorator('isRecommend', {
                    initialValue: isRecommend !== undefined ? isRecommend.toString() : ''
                })(
                    <Radio.Group disabled={readOnly} >
                        {
                            [{ code: '1', value: '是' }, { code: '0', value: '否' }].map(i => {
                                return <Radio key={i.code} value={i.code}>{i.value}</Radio>;
                            })
                        }
                    </Radio.Group>
                )}
            </Form.Item>
            <Form.Item label='跳转链接'>
                {getFieldDecorator('linkName', {
                    initialValue: linkName
                })(
                    <Input disabled={readOnly} style={{ width: '360px' }} allowClear
                        onChange={() => { setFieldsValue({ linkName: '' }); setTempLink({ tempLink: {} }); }}
                        placeholder='请选择' onFocus={() => setOpenModal(true)} />
                )}
            </Form.Item>
            <div className={styles.modalFooter}>
                {
                    readOnly ? <Button type='primary' onClick={() => tableAction('closeModal')}>确定</Button> :
                        <Fragment>
                            <Button style={{ marginRight: '20px' }} onClick={() => tableAction('closeModal')}>取消</Button>
                            <Button type='primary' loading={loading} htmlType='submit'>确定</Button>
                        </Fragment>
                }
            </div>
            {openModal && <JumpLink onCheckLink={onCheckLink} closeMode={() => setOpenModal(false)} />}
        </Form>
    </Modal>;
};
InfoOperateModal.propTypes = {
    form: PropTypes.object,
    data: PropTypes.object,
    tableAction: PropTypes.func
};
const InfoOperateForm = Form.create()(InfoOperateModal);

const HotSet = ({
    getHotWordTableData,
    tableData: {
        dataList = [],
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0
    } = {}
}) => {

    const [openModal, setOpenModal] = useState(false);
    const [modalParams, setModalParams] = useState();
    const [cPageNum, setCPageNum] = useState(autoPageNum);
    const [cPageSize, setCPageSize] = useState(autopageSize);

    useEffect(() => {
        getHotWordTableData({ paramType: 'TOP_SEARCH', pageNum: cPageNum, pageSize: cPageSize });
    }, [cPageNum, cPageSize]);

    const tableOperate = (t, r) => {
        return <div className={styles.action}>
            {
                r.state == 1 ? <Fragment>
                    <div className={styles.item} onClick={() => operateFunc('openModal', { ...r, title: '热搜词详情', readOnly: true })}>详情</div>
                    <div className={styles.item}
                        onClick={() => operateFunc('openBan', { id: r.id, content: '您是否关闭当前热词，关闭后前端无法展示', state: 0 })}>禁用</div>
                </Fragment> : <Fragment>
                    <div className={styles.item} onClick={() => operateFunc('openModal', { ...r, title: '编辑热搜词' })}>编辑</div>
                    <div className={styles.item}
                        onClick={() => operateFunc('openBan', { id: r.id, content: '您是否开启当前热词，开启后前端将展示此热词', state: 1 })}>开启</div>
                </Fragment>
            }
        </div>;
    };

    const sortNoCell = (t, r) => {
        return <Switch disabled={r.state == 0 && t == 0}
            checkedChildren='ON' unCheckedChildren='OFF' checked={t == 1}
            onChange={checked => operateFunc('sortNo', { id: r.id, type: checked ? 1 : 0 })}
        />;
    };

    const columns = [
        {
            title: '热搜词',
            dataIndex: 'paramValue',
            key: 'paramValue',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '排序',
            dataIndex: 'isRecommend',
            key: 'isRecommend',
            align: 'center',
            onCell: tooltipStyle,
            render: sortNoCell
        },
        {
            title: '操作人',
            dataIndex: 'modifyUserName',
            key: 'modifyUserName',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '执行时间',
            dataIndex: 'time',
            key: 'time',
            width: 300,
            align: 'center',
            onCell: tooltipStyle,
            render: (t, r) => tooltip(`${r.startTime} - ${r.endTime}`)
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            onCell: tooltipStyle,
            render: t => tooltip(t == 1 ? '启用' : '禁用')
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            onCell: tooltipStyle,
            render: tableOperate
        }
    ];

    const operateFunc = (type, params) => {
        const eventObject = {
            sortNo: p => {
                http('/supplier/config/recommend', p, 'POST')
                    .then(res => {
                        message.success(res.message);
                        getHotWordTableData({ paramType: 'TOP_SEARCH', pageNum: cPageNum, pageSize: cPageSize });
                    }).catch(err => message.error(err.message));
            },
            openBan: ({ content, ...other }) => {
                showConfirm(content, '', () => {
                    http('/supplier/config/updateState', other, 'POST')
                        .then(res => {
                            message.success(res.message);
                            getHotWordTableData({ paramType: 'TOP_SEARCH', pageNum: cPageNum, pageSize: cPageSize });
                        }).catch(err => message.error(err.message));
                }, () => { }, '否', '是');
            },
            openModal: p => {
                setOpenModal(true);
                setModalParams(p);
            },
            closeModal: p => {
                setOpenModal(false);
                setModalParams();
                p && getHotWordTableData({ paramType: 'TOP_SEARCH', pageNum: cPageNum, pageSize: cPageSize });
            }
        };
        eventObject[type](params);
    };

    const paginationChange = (ps, pn) => {
        setCPageNum(pn);
        setCPageSize(ps);
    };

    return <div className={styles.flexBoxContainer}>
        <XTitle label='热搜词设置' />
        <XOKButton style={{ width: '120px', margin: '10px 0 5px 0' }} label='新建热搜词'
            onClick={() => operateFunc('openModal', { title: '新建热搜词', modalType: 'infoOperate' })} />
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey='id'
                columns={columns}
                dataSource={dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
        {openModal && <InfoOperateForm data={modalParams} tableAction={operateFunc} />}
    </div>;
};

HotSet.propTypes = {
    history: PropTypes.object,
    getHotWordTableData: PropTypes.func,
    tableData: PropTypes.object
};

export default connect(state => ({
    tableData: state.suppliesset.hotWordTableData
}), { getHotWordTableData })(HotSet);