/**
 * 权限管理 Saga集合
 */
import { getDeptList, getDeptDetail, getCompanyList, getCompanyDetail } from './structure/saga';
import { getEmplList, getEmplDetail, getDeptSelect, getEmplState, getEmplType } from './employeelist/saga';
import { getRolesList, getRolesDetail, getEmplsByRole, getMenuByRole, getAdminEmpl, getUnEmpl, getRoleSelect, getDownEmpls, getListRoleByEmpl } from './assignauthority/saga';
import { getMenuList, getAllMenuList, getMenuDetail, getMenuSelect, getSecondMenuSelect, getTabSelect, getAffType } from './menuconfigure/saga';

export default [
    getDeptList, // 获取部门列表
    getDeptDetail, // 获取部门详情
    getDeptSelect, // 获取部门下拉列表
    getCompanyList, // 获取公司、运营中心列表详情
    getCompanyDetail, // 获取公司、运营中心详情
    getEmplList, // 获取员工列表
    getEmplDetail, // 获取员工详情
    getEmplState, // 获取员工状态
    getEmplType, // 获取员工所属分类
    getRolesList, // 获取角色列表
    getRolesDetail, // 获取角色详情
    getAdminEmpl, // 获取本公司超级管理员
    getEmplsByRole, // 获取平台、运营中心、区域中心、商户某个角色下面得所有员工
    getUnEmpl, // 获取本公司未分类角色员工
    getDownEmpls, // 获取员工下拉列表（在新建员工角色时用到）
    getRoleSelect, // 获取角色下拉列表(复制角色权限以及权限分配时均可用)
    getMenuByRole, // 获取某角色权限的菜单
    getMenuList, // 获取一级、二级菜单列表
    getAllMenuList, // 获取全部菜单列表
    getMenuDetail, // 获取菜单详情
    getMenuSelect, // 获取一级菜单下拉列表
    getSecondMenuSelect, // 获取二级菜单下拉列表
    getTabSelect, // 获取Tab菜单下拉列表
    getAffType, // 获取总控菜单终端类型
    getListRoleByEmpl, // 根据员工获取角色列表（对已关联得角色做记号）
];
