/**
 * 【商品举报】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import AdvertTable from './components/AdvertTable';
import { getproductReport, } from '@/reducers/reportmanage/productReport/actions';
import { getreportState, } from '@/reducers/reportmanage/userReport/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends React.Component {

    state = {
        companyStatus: {},
        reportTel: '',
        productName: '',
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getreportState, } = this.props;
        keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        getreportState();//状态
    }
    //查询
    _searchHandle = (useCache) => {
        const { pageSize, pageNum, productName, reportTel, companyStatus, } = this.state;
        this.searchCache = {
            goodsName: productName,//被举报商品名称  
            reportState: companyStatus.code,//举报状态  
            reportAccountTel: reportTel,//举报人手机号  
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getproductReport(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            companyStatus: {},
            reportTel: '',
            productName: '',
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getproductReport(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (id, record, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                addOneBreadcrumbPath({
                    title: '举报详情',
                    path: `/home/reportmanage/productReport/detail/${id}`
                });
                history.push({
                    pathname: `/home/reportmanage/productReport/detail/${id}`
                });
                break;
            case 'deal':
                addOneBreadcrumbPath({
                    title: '举报处理',
                    path: `/home/reportmanage/productReport/deal/${id}`
                });
                history.push({
                    pathname: `/home/reportmanage/productReport/deal/${id}`
                });
                break;
        }
    }
    //打开弹窗
    _renderMode = () => {

    }

    // 关闭弹窗
    _closeMode = () => {

    }
    // 处理状态
    _companyStatusChange = (value) => {
        this.setState({
            companyStatus: value,
        });
    }

    render() {
        const { productName, companyStatus, reportTel } = this.state;
        const { selectData, tableSource, paginations, } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商品名称'
                                placeholder='请输入'
                                value={productName}
                                bindThis={this}
                                bindName='productName'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='举报人手机号'
                                placeholder='请输入'
                                value={reportTel}
                                bindThis={this}
                                bindName='reportTel'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='处理状态'
                                placeholder='请选择'
                                renderData={selectData}
                                showSearch={true}
                                onChange={this._companyStatusChange}
                                dataIndex='value'
                                keyIndex='code'
                                value={companyStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    {/* <div className={styles.row2}>
                    <XOKButton
                        style={{ width: '100px' }}
                        label='新建'
                        onClick={this._newHandle}
                    />
                </div> */}
                    <AdvertTable renderData={tableSource} tableAction={this._tableAction} pagiNation={paginations} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    tableSource: PropTypes.array, // 表格数据源
    selectData: PropTypes.array, // 分类状态下拉选项
    paginations: PropTypes.object, // 分页数据

    getreportState: PropTypes.func, // 通过Saga异步请求下拉数据源的Action
    getproductReport: PropTypes.func,//通过Saga异步请求表格数据源的Action

};

const mapStateToProps = (state) => {
    return {
        tableSource: state.productReport.productReportDate.tableSource,
        selectData: state.userReport.reportDate.selectData,
        paginations: state.productReport.productReportDate.paginations,
    };
};
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getproductReport, getreportState, })(Main);