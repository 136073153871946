import * as T from './actiontypes';

/**
 * 获取'秀场管理 -道具管理- 道具分类 列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} className 分类名称
 * @param {number} state 状态
 */
export const getGiftTableSource = (dataload) => ({
    type: T.GET_PROPSMANAGE_DATA,
    payload: {
        ...dataload,
    }
});

/**
 * 获取'秀场管理 -道具管理- 道具分类状态'数据源(saga)
 *  @param 暂无
 */
export const getGiftState = () => ({
    type: T.GET_GIFTSTATE_DATA,
});

/**
 * 获取'秀场管理 -道具管理- 道具列表 列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * *@param {string} propName 道具名称
 * @param {number} propClassId 道具分类
 */
export const getGlistTableSource = (dataload) => ({
    type: T.GET_PROPSGLIST_DATA,
    payload: {
        ...dataload,
    }
});

