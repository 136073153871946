/**
 * Tab导航组件。支持Tab头部切换回调，但不包括内容装载
 */
import React, { Fragment } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';


const { TabPane } = Tabs;
class TabNav extends React.Component {

    _tabClickHandle = (id) => {
        const item = this._getItemByID(id);
        if (parseInt(id) == id) {
            this.props.onChange(parseInt(id), item);
        } else {
            this.props.onChange(id, item);
        }
    }

    _getItemByID = (id) => {
        const { renderData, selfKey } = this.props;
        let result = {};

        for (let i = 0; i < renderData.length; i++) {
            if (renderData[i][selfKey] == id) {
                result = renderData[i];
                break;
            }
        }

        return result;
    }

    //渲染tag标签,判断是否渲染tag标签
    _childrenNode = (i) => {
        const { isTagNodePro, optionalNode, selfValue } = this.props;
        return (<Fragment>
            <span className={styles.spanNode}>{i[selfValue]}</span>
            {i.isState == isTagNodePro ? optionalNode : null}
        </Fragment>);
    }

    _renderTabNav = (tabs) => {
        const { activedID, tabPosition, typeNode, selfKey, selfValue } = this.props;

        const tabsNode = tabs.map(i => {//默认
            return < TabPane tab={'' + i[selfValue]} key={'' + i[selfKey]} ></TabPane>;
        });
        const elementNode = tabs.map(i => {//自定义tag标签
            return <TabPane tab={this._childrenNode(i)} key={i[selfKey]}></TabPane>;
        });
        return <Tabs
            className={styles.tab}
            activeKey={'' + activedID}
            tabPosition={tabPosition}
            style={(tabPosition == 'left' || tabPosition == 'right') ? { width: '120px', height: '100%' } : {}}
            onChange={this._tabClickHandle}
        >
            {typeNode ? elementNode : tabsNode}
        </Tabs>;
    }

    render() {
        const { renderData, tabPosition } = this.props;

        return (
            <div className={styles.container} style={(tabPosition == 'left' || tabPosition == 'right') ? { width: '120px', height: '100%' } : {}}>
                {this._renderTabNav(renderData)}
            </div>
        );
    }
}

TabNav.defaultProps = {
    activedID: '1',
    tabPosition: 'top',
    optionalNode: null,
    typeNode: false,
    selfValue: 'label',
    selfKey: 'id'
};

TabNav.propTypes = {
    activedID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]), // 当前活动的tabID，建议item从1开始排序，以此为1,2,3...，默认值为1(双向绑定)
    renderData: PropTypes.array, // 需要渲染的数据
    onChange: PropTypes.func, // tab改变时的回调函数
    tabPosition: PropTypes.string, // 页签位置，可选值有 top right bottom left
    optionalNode: PropTypes.node,//PropTypes.element 是否可为子节点
    typeNode: PropTypes.bool,//是否自定义tag标签
    isTagNodePro: PropTypes.string,//自定义为何值时，判断是否渲染tag标签 @isState-tag标签属性
    selfValue: PropTypes.string,
    selfKey: PropTypes.string,
};

export default TabNav;