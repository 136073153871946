/**
 * 主播管理
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { message } from 'antd';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import { AreaModal, SetcontrolModal } from '../../modal';
import { connect } from 'react-redux';
import model from '@/reducers/usermanage/member/model';
import { getTVAnchorList, getTVAnchorTypeList } from '@/reducers/usermanage/tvanchor/actions';
import TableContent from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import http from '@/assets/api/http';

// 弹框类型枚举
const Mode = {
    AREA_CHANGE: 'AREA_CHANGE', // 修改区域弹框
};

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            curMode: '', // 当前打开的弹框枚举类型
            modeParam: [], // 传递给弹框的入参
            selectedRowKeys: [],
            telephone: '', // 会员账号
            nickname: '', // 昵称
            level: {}, // 会员等级
            province: {}, // 省份
            city: {}, // 城市
            street: {}, // 区域
            anchorType: {}, // 主播类型
        };
    }

    componentDidMount() {
        const { getAccountLevel, getListAreaByParentId } = this.props;
        getAccountLevel(); // 请求会员等级
        this.props.getTVAnchorTypeList(); //主播类型
        getListAreaByParentId({ id: 100000, type: 'province' }); // 请求地域信息
    }

    // 省份下拉列
    _provinceChangeHandle = (selectedItem) => {
        this.setState({ province: selectedItem, city: {}, street: {} });
        this.props.getListAreaByParentId({ id: selectedItem.id, type: 'city' });
        this.props.cleanStreet();
    }

    // 城市下拉列
    _cityChangeHandle = (selectedItem) => {
        this.setState({ city: selectedItem, street: {} });
        this.props.getListAreaByParentId({ id: selectedItem.id, type: 'street' });
    }

    // 区域下拉列
    _streetChangeHandle = (selectedItem) => {
        this.setState({ street: selectedItem });
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        const pageSearch = { ...this.searchCache, pageSize, pageNum, isAnchor: 1 };
        this.props.getTVAnchorList(pageSearch);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【！！！！！！页面缓存第3步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (id, type, item) => {
        if (type == 'CHANGE_AREA') {
            this.setState({ curMode: Mode.AREA_CHANGE, modeParam: [id] });
        } else if (type == 'SET_CONTROL') {//设为自控
            if (item.isAutoBind != '0') {
                http(`/user/account/setIsAutoBind/${id}`, {}, 'POST').then((response={}) => {
                    if (response.status == 200) {
                        message.success('修改成功');
                        this.props.getTVAnchorList(this.searchCache);
                    }
                }).catch((e = {}) => {
                    message.error(e.message);
                });
            } else {
                this.setState({ curMode: Mode.SET_CONTROL, modeParam: id });
            }
        } else {
            this.props.updateContent(id, type);
        }
    }

    // 打开修改区域弹框
    _openAreaModal = () => {
        this._openMode(Mode.AREA_CHANGE);
    }

    // 打开弹框
    _openMode = (type) => {
        if (this.state.selectedRowKeys.length > 0) {
            const accountIds = this.state.selectedRowKeys;
            this.setState({ curMode: type, modeParam: accountIds });
        } else {
            message.warning('请先选中一个或多个会员！');
        }
    }

    // 渲染弹框
    _renderMode = () => {
        switch (this.state.curMode) {
            case Mode.AREA_CHANGE:
                return <AreaModal visible={true} data={this.state.modeParam} closeMode={this._closeMode} />;
            case Mode.SET_CONTROL:// 设为自控
                return <SetcontrolModal visible={true} data={this.state.modeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ curMode: '' });
        if (update) {
            this.props.getTVAnchorList(this.searchCache);
        }
    }

    // 查询操作
    _searchHandle = (useCache) => {
        const { anchorType, telephone, nickname, level, province, city, street, pageNum, pageSize } = this.state;
        const tels = telephone.split(',');
        this.searchCache = {
            tels: telephone == '' ? [] : tels,
            nicknames: nickname == '' ? [] : [nickname],
            accountLevelId: level.id,
            provinceId: province.id,
            cityId: city.id,
            areaId: street.id,
            isAnchor: 1,
            anchorType: anchorType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,  // 【！！！！！！查询加上这个属性判断】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【！！！！！！查询加上这个属性判断】
        };
        this.props.getTVAnchorList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【！！！！！！页面缓存第2步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ telephone: '', nickname: '', level: {}, province: {}, city: {}, street: {}, anchorType: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【！！！！！！页面缓存第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    render() {
        const { telephone, nickname, level, selectedRowKeys, anchorType } = this.state;
        const { accountLevel, tvAnchorList, address, tvAnchorTypeList } = this.props;
        const { province, city, street } = address;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【！！！！！！页面缓存第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='会员账号'
                                placeholder='请输入会员账号'
                                value={telephone}
                                bindThis={this}
                                bindName='telephone'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='会员昵称'
                                placeholder='请输入会员昵称'
                                value={nickname}
                                bindThis={this}
                                bindName='nickname'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='会员等级'
                                placeholder='请选择会员等级'
                                renderData={accountLevel}
                                dataIndex='levelName'
                                keyIndex='id'
                                value={level.levelName}
                                bindThis={this}
                                bindName='level'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='主播类型'
                                placeholder='请选择主播类型'
                                renderData={tvAnchorTypeList || []}
                                showSearch
                                dataIndex='value'
                                keyIndex='code'
                                value={anchorType.value}
                                bindThis={this}
                                bindName='anchorType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                style={{ marginRight: '0px' }}
                                label='归属区域'
                                placeholder='省份'
                                renderData={province}
                                onChange={this._provinceChangeHandle}
                                dataIndex='areaName'
                                keyIndex='id'
                                value={this.state.province.areaName}
                                showSearch={true}
                            />
                            <XSelect
                                style={{ marginRight: '0px' }}
                                placeholder='城市'
                                renderData={city}
                                onChange={this._cityChangeHandle}
                                dataIndex='areaName'
                                keyIndex='id'
                                value={this.state.city.areaName}
                                showSearch={true}
                            />
                            <XSelect
                                placeholder='区域'
                                renderData={street}
                                onChange={this._streetChangeHandle}
                                dataIndex='areaName'
                                keyIndex='id'
                                value={this.state.street.areaName}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent
                        renderData={tvAnchorList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                        selectedRowKeys={selectedRowKeys}
                        selectedRowKeysChange={(selectedRowKeys) => { this.setState({ selectedRowKeys }); }}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // router history
    getAccountLevel: PropTypes.func, // 请求会员等级
    getTVAnchorList: PropTypes.func, // 请求主播列表
    getListAreaByParentId: PropTypes.func, // 请求地区信息
    accountLevel: PropTypes.array, // 会员等级
    tvAnchorList: PropTypes.object, // 主播列表
    address: PropTypes.object, // 总条数
    getTVAnchorTypeList: PropTypes.func,
    tvAnchorTypeList: PropTypes.array,
    cleanStreet: PropTypes.func, // 清理区域搜索结果
};

const mapStateToProps = (state) => ({
    accountLevel: state.member.accountLevel,
    tvAnchorList: state.tvanchor.tvAnchorList,
    address: state.member.address,
    tvAnchorTypeList: state.tvanchor.tvAnchorTypeList,
});

export default connect(mapStateToProps, { ...model.actions, getTVAnchorList, getTVAnchorTypeList })(Main);