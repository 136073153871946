import { createModel } from '@/store/tools';

const model = {
    namespace: 'serviceshare',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getServiceshareList': T('getServiceshareList'),
        },
        actions: {
            'getServiceshareList': A('getServiceshareList'),
        },
        sagas: {
            'getServiceshareList': S('getServiceshareList', '/boss/order/distribution/distributionOrderForServiceExportList'),//服务商分销分享排行
        },
        reducers: {
            'serviceshareList': R('getServiceshareList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);