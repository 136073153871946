import React from 'react';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell } from '@/components/TableCell';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './OrderTable.module.scss';

class OrderTable extends React.Component {

    _takeColumns = () => ([
        {
            title: '凭证单号',
            dataIndex: 'id',
            key: 'id',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '公司名称',
            dataIndex: 'sharesName',
            key: 'sharesName',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '股票代码',
            dataIndex: 'sharesCode',
            key: 'sharesCode',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '兑换股数',
            dataIndex: 'exchangeShares',
            key: 'exchangeShares',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '生成凭证股价',
            dataIndex: 'currClosingPrice',
            key: 'currClosingPrice',
            width: cellWidth.normal,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '兑换股价',
            dataIndex: 'closingPrice',
            key: 'closingPrice',
            width: cellWidth.small,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: 'RMB兑换港元比例',
            dataIndex: 'rmbRatioHkd',
            key: 'rmbRatioHkd',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text) => tooltip(text ? (text / 100 + ':1') : '-')
        },
        {
            title: '手续费比例(%)',
            dataIndex: 'procedureRatio',
            key: 'procedureRatio',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '手续费(元)',
            dataIndex: 'procedureFee',
            key: 'procedureFee',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '预计收益（元）',
            dataIndex: 'premiumPrice',
            key: 'premiumPrice',
            width: cellWidth.normal,
            align: 'center',
            render: (text) => text == 0 ? '--' : priceFenToYuanCell(text)
        },
        {
            title: '兑换时间',
            dataIndex: 'premiumDate',
            key: 'premiumDate',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '审核状态',
            dataIndex: 'voucherStatus.value',
            key: 'voucherStatus.value',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '说明',
            dataIndex: 'auditOpinion',
            key: 'auditOpinion',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            fixed: 'right',
            width: cellWidth.time,
            render: (text, item) => this._renderActionCell(item.id, item)
        }
    ]);

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                {item.voucherStatus.code == 'AUDIT_FAIL' && <div className={styles.item} onClick={() => tableAction(key, 'exchange', item)}>重新兑换</div>}
            </div>
        );
    }

    render() {
        const { paginationChange, scrollY } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ x: 'max-content', y: scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default XTableHOC(OrderTable, 40);
