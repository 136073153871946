import React, { useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message, Modal, Typography } from 'antd';
import { XInfoLabel } from '@/components/xqxc_ui';

const UpdateModal = ({
    tableAction,
    modalParmas: {
        accountName,
        id
    } = {}
}) => {

    const [loading, setLoading] = useState(false);

    const handleOk = () => {
        setLoading(true);
        http('/user/supplierAccountInfo/updateSupplierPwd', { accountId: id, version: '1.0' }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                tableAction(true, 'closeModal');
            })
            .catch(err => {
                setLoading(false);
                message.error(err.message);
            });
    };

    return <Modal
        width={380}
        centered
        title='修改密码'
        bodyStyle={{ padding: '10px 20px' }}
        visible={true}
        confirmLoading={loading}
        onOk={handleOk}
        onCancel={() => tableAction(false, 'closeModal')}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确定'
    >
        <div className={styles.modalContainer}>
            <XInfoLabel style={{ marginLeft: '15px' }} label='渠道账号：' value={accountName} />
            <div style={{ textAlign: 'center' }}>
                <Typography.Text type='danger'>确认重置后，密码将与短信的形式发放给渠道账户</Typography.Text><br />
                <Typography.Text type='danger'>（注：每日只能重置一次）</Typography.Text>
            </div>
        </div>
    </Modal>;
};
UpdateModal.propTypes = {
    tableAction: PropTypes.func,
    modalParmas: PropTypes.object
};
export default UpdateModal;