/**
 * 运行中心对账saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as T from './actiontypes';
import http from '@/assets/api/http';
import { isPlat } from '@/assets/js/authType';

export const getOperatingCenterReconciliationTableSource = function* () {
    yield takeEvery(T.GET_OPERATINGCENTERRECONCILIATION_TABLESOURCE, function* requestData(action) {
        try {
            let url = '/settle/reconciliationInfo/queryOperationReconciliations';
            let result = yield http(url, action.payload, 'POST');
            yield put({ type: T.SET_OPERATINGCENTERRECONCILIATION_TABLESOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 运营中心下拉
export const getlistOperationsIdAndName = function* () {
    yield takeEvery(T.GET_OPERATINGCENTERRECONCILIATION_LIST, function* requestData(action) {
        try {
            let url = '/admin/company/listOperationsIdAndName';
            let result = yield http(url, action.payload, 'POST');
            yield put({ type: T.SET_OPERATINGCENTERRECONCILIATION_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 运营中心对账详情
export const getOperationCenterDetail = function* () {
    yield takeEvery(T.GET_OPERATINGCENTERRECONCILIATION_DETAIL, function* requestData(action) {
        try {
            let url = '/settle/reconciliationInfo/getOperationReconciliation';
            let result = yield http(url, action.payload, 'POST');
            yield put({ type: T.SET_OPERATINGCENTERRECONCILIATION_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
