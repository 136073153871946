//获取/保存消息分类列表
export const GET_MESSAGEMOVE_LIST = 'MESSAGE/GET_MESSAGEMOVE_LIST';
export const SET_MESSAGEMOVE_LIST = 'MESSAGE/SET_MESSAGEMOVE_LIST';
//获取/保存消息分类详情
export const GET_MESSAGEMOVE_DETAIL = 'MESSAGE/GET_MESSAGEMOVE_DETAIL';
export const SET_MESSAGEMOVE_DETAIL = 'MESSAGE/SET_MESSAGEMOVE_DETAIL';
//获取/保存消息状态
export const GET_MESSAGESTATUS = 'MESSAGE/GET_MESSAGESTATUS';
export const SET_MESSAGESTATUS = 'MESSAGE/SET_MESSAGESTATUS';
//获取/保存消息方式
export const GET_MESSAGEWAYS = 'MESSAGE/GET_MESSAGEWAYS';
export const SET_MESSAGEWAYS = 'MESSAGE/SET_MESSAGEWAYS';
//获取/保存跳转方式
export const GET_JUMPWAYS = 'MESSAGE/GET_JUMPWAYS';
export const SET_JUMPWAYS = 'MESSAGE/SET_JUMPWAYS';
//获取/保存推送人群
export const GET_PUSHPEOPLE = 'MESSAGE/GET_PUSHPEOPLE';
export const SET_PUSHPEOPLE = 'MESSAGE/SET_PUSHPEOPLE';