//获取/保存区域公司列表
export const GET_COMPANYAUDIT_LIST = 'COMPANYAUDIT/GET_COMPANYAUDIT_LIST';
export const SET_COMPANYAUDIT_LIST = 'COMPANYAUDIT/SET_COMPANYAUDIT_LIST';

//获取/保存状态下拉
export const GET_COMPANYAUDIT_STATUS = 'COMPANYAUDIT/GET_COMPANYAUDIT_STATUS';
export const SET_COMPANYAUDIT_STATUS = 'COMPANYAUDIT/SET_COMPANYAUDIT_STATUS';

//获取/保存公司详情
export const GET_COMPANYAUDIT_DETAIL = 'COMPANYAUDIT/GET_COMPANYAUDIT_DETAIL';
export const SET_COMPANYAUDIT_DETAIL = 'COMPANYAUDIT/SET_COMPANYAUDIT_DETAIL';