import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { message } from 'antd';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, showConfirm, } from '@/components/xqxc_ui';
import { GoodsClassify } from '@/vcomps';
import TableContent from './components/TableContent';
import Inventory from '../../modal/Inventory';
import Conditions from '../../modal/Conditions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/starvalueshop/starshop/model';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
        productName: '',// 商品名称
        commodityNO: '',// 商品货号
        supplier: {},// 供应商
        categoryId: '', // 商品联级分类
        convertMode: {},// 兑换方式
        categorySubTypeItem: {} // 商品分类
    }


    componentDidMount() {
        this.props.getSupplierList();
        this.props.getStarGoodsConvertModeEnum();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, productName, supplier, commodityNO, categoryId, convertMode, categorySubTypeItem } = this.state;
        this.searchCache = {
            goodsName: productName,//商品名称  
            categoryId,//商品分类
            supplierId: supplier.supplierId,//供应商  
            goodsNo: commodityNO,//商品货号  
            convertMode: convertMode.code,//卡券兑换
            categorySubType: categorySubTypeItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getstarvalueList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ productName: '', supplier: {}, commodityNO: '', categoryId: '', convertMode: {}, categorySubTypeItem: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        this.cleanRef.clean();
    }

    //创建人气值商品
    _createStarProduct = () => {
        this.props.updateContent('CREATE');
    }


    // 表格操作
    _tableAction = (item, type, params) => {
        const { getstarvalueList, } = this.props;
        switch (type) {
            case 'terms'://设置兑换条件
                http(`/star/good/${item}/convert`, { id: item }, 'POST').then((response) => {
                    this.setState({
                        currentOpenMode: 'terms',
                        openModeParam: { ...params, ...response.result },
                    });
                }).catch((reject = {}) => {
                    message.warn(reject.message);
                });
                break;
            case 'editor':
                this.props.updateContent('EDITOR', item);
                break;
            case 'detail':
                this.props.updateContent('DETAIL', params);
                break;
            case 'delete':
                showConfirm('您是否要删除该商品', '', () => {
                    http('/starmall/goods/delete', { id: item }, 'POST').then(() => {
                        message.success('删除成功');
                        getstarvalueList(this.searchCache);// 重新渲染更新列表
                    }).catch((reject) => {
                        if (reject.code == '500') {
                            message.warn(reject.message);
                        }
                    });
                }, () => { });
                break;
            case 'inventory':
                this.setState({
                    currentOpenMode: 'inventory',
                    openModeParam: params,
                });
                break;
            case 'recommend'://推荐
                http('/star/good/state/edit', {
                    type: 1,
                    ctrId: item.ctrId,
                    id: item.id,
                    status: (params === false) ? 0 : 1,
                }, 'POST').then(() => {
                    message.success('推荐修改成功');
                    getstarvalueList(this.searchCache);// 重新渲染更新列表
                }).catch((reject = {}) => {
                    message.error(reject.message);
                });
                break;
            case 'condition'://上下架
                http('/star/good/state/edit', {
                    type: 0,
                    ctrId: item.ctrId,
                    id: item.id,
                    status: (params === false) ? 0 : 1,
                }, 'POST').then(() => {
                    message.success('状态修改成功');
                    getstarvalueList(this.searchCache);// 重新渲染更新列表
                }).catch((reject = {}) => {
                    message.error(reject.message);
                });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'inventory':
                return <Inventory title='修改库存' visible={true}
                    data={this.state.openModeParam}
                    closeMode={this._closeMode} />;
            case 'terms':
                return <Conditions title='设置兑换条件' visible={true}
                    data={this.state.openModeParam}
                    closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) this.props.getstarvalueList(this.searchCache);// 重新渲染更新列表;// 重新渲染更新列表
    }


    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getstarvalueList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    //重置推荐
    _resetRecomment = () => {
        http('/star/good/resetRecoStarGoods', {}, 'POST')
            .then(() => {
                message.success('重置推荐操作成功');
                this._searchHandle('useCache');
            }).catch(err => message.error(err.message));
    }

    render() {
        const { starvalueList, supplierList, starGoodsConvertModeEnum } = this.props;
        const { productName, commodityNO, supplier, convertMode, categorySubTypeItem } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='商品名称'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='productName'
                                value={productName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='商品货号'
                                placeholder='请输入'
                                bindThis={this}
                                bindName='commodityNO'
                                value={commodityNO}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='供应商'
                                placeholder='请选择'
                                renderData={supplierList}
                                dataIndex='supplierName'
                                keyIndex='supplierId'
                                bindThis={this}
                                bindName='supplier'
                                value={supplier.supplierName}
                                showSearch={true}
                                isOptionBindID={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商品分类'
                                style={{ marginRight: '10px' }}
                                placeholder='请选择分类类型'
                                renderData={[
                                    { code: 'COMMON', value: '通用分类' },
                                    { code: 'ONLINE', value: '线上分类' },
                                    { code: 'CITY', value: '同城分类' }
                                ]}
                                bindThis={this}
                                bindName='categorySubTypeItem'
                                dataIndex='value'
                                keyIndex='code'
                                value={categorySubTypeItem.value}
                            />
                            <GoodsClassify
                                style={{ marginRight: '20px' }}
                                ref={ele => this.cleanRef = ele}
                                categorySubType={categorySubTypeItem.code}
                                onClassifyChange={value => this.setState({ categoryId: value })}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='兑换方式'
                                placeholder='请选择'
                                renderData={starGoodsConvertModeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                bindThis={this}
                                bindName='convertMode'
                                value={convertMode.value}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        {/* <XOKButton style={{ width: '116px' }} label='创建人气值商品' onClick={this._createStarProduct} /> */}
                        <XOKButton style={{ width: '106px' }} label='重置推荐' onClick={this._resetRecomment} />
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={starvalueList} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }

}

Main.propTypes = {
    updateContent: PropTypes.func,
    getstarvalueList: PropTypes.func, // 表格数据源的Action
    getSupplierList: PropTypes.func, //供应商下拉Action
    starvalueList: PropTypes.object, // 表格数据源
    supplierList: PropTypes.array, //供应商下拉
    getStarGoodsConvertModeEnum: PropTypes.func,
    starGoodsConvertModeEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
    starvalueList: state.starShop.starvalueList,
    supplierList: state.starShop.supplierList,
    starGoodsConvertModeEnum: state.starShop.getStarGoodsConvertModeEnum,
});

export default connect(mapStateToProps, { ...model.actions })(Main);