/**
 * 氛围列表创建或编辑
 */
import React from 'react';
import { Modal, Radio, Icon, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './GlistModal.module.scss';
import { XInput, XSelect, XUpload } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;

class GlistModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,

            type: {}, // 氛围分类
            nameInput: this.props.data === undefined ? '' : this.props.data.atmosphereName, // 氛围名称
            stateRadioValue: this.props.data === undefined ? '1' : this.props.data.state.code, // 状态（0：禁止 1：启用）
            id: this.props.data === undefined ? '' : this.props.data.id,//主键ID
            starInput: this.props.data === undefined ? '' : this.props.data.starlightNumber,// 魅力值数 
            recommendRadioValue: this.props.data === undefined ? 1 : this.props.data.isReco, // 是否推荐(0:否 1是) 
            singleImage: this.props.data === undefined ? '' : this.props.data.img, // 分类图标,判断图片是否有值 赋值url

            attachUrl: this.props.data === undefined ? '' : this.props.data.attachUrl,//代替判断初始化附件地址URL
            fileName: this.props.data === undefined ? '' : this.props.data.fileName,//资源包文件名称
            singleImages: [],//图片上传
            musicFileNames: [],//资源包
        };

        const { singleImage, attachUrl, fileName, } = this.state;

        if (singleImage) {//在编辑状态中提换default图标
            this.state.singleImages = [{
                uid: '0',
                name: 'default',
                status: 'done',
                url: singleImage,
            }];
        }
        if (attachUrl) {
            this.state.musicFileNames = [{
                uid: '0',
                name: fileName,
                status: 'done',
                url: attachUrl,
            }];
        }
    }


    componentDidMount() {
        const { selectData, data } = this.props;
        let newSource = selectData;
        let editegiftClassIdItem = {};
        if (data !== undefined) {
            for (let i = 0; i < newSource.length; i++) {
                if (newSource[i].id == data.showClassId) {
                    editegiftClassIdItem = newSource[i];
                    break;
                }
            }
            this.setState({ type: editegiftClassIdItem });
        }
    }

    // 确认
    _handleOk = () => {

        const { data } = this.props;
        const {
            nameInput,
            stateRadioValue,
            type,
            starInput,
            recommendRadioValue,
            id,
            singleImage,
            attachUrl,
            fileName,
        } = this.state;



        const method = data === undefined ? 'createAtmosphere' : 'updateAtmosphere';
        let starInputReg = /^[1-9]\d*$/;

        if (nameInput == '') {
            message.warn('请输入礼物名称。');
        } else if (Object.keys(type).length == 0) {
            message.warn('请输入礼物分类。');
        } else if (starInput == '' || !starInputReg.test(starInput)) {
            message.warn('请输入所需魅力值（整数）。');
            console.log(starInput);
        } else if (data === undefined ? singleImage === undefined || singleImage == '' : singleImage === undefined) {//   }else if(data === undefined ? singleImage ===undefined||singleImage=='':imgUrls==''){
            message.warn('请输入分类图标。');
        } else {
            this.setState({ confirmLoading: true }, () => {
                http(`/show/atmosphere/${method}`, {
                    atmosphereName: nameInput,
                    state: stateRadioValue,
                    showClassId: type.id,//下拉主键id
                    starlightNumber: starInput,
                    sortNo: recommendRadioValue,
                    isReco: recommendRadioValue,
                    img: singleImage,
                    id: id,
                    attachUrl: attachUrl,
                    fileName: fileName,
                }, 'POST').then(() => {
                    data === undefined ? message.success('创建成功。') : message.success('更新成功。');
                    this.setState({
                        visible: false,
                        confirmLoading: false
                    });
                    this.props.closeMode(true);
                }).catch((res) => {
                    this.setState({ confirmLoading: false });
                    if (res.code == '500') {
                        message.error(res.message);
                    }
                });
            });
        }

    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    //状态
    _stateRadioValueValueChangeHandle = (e) => {
        this.setState({ stateRadioValue: e.target.value });
    }

    // 礼物分类下拉列改变时触发
    _typeChangeHandle = (selectedItem) => {
        this.setState({ type: selectedItem });
    }

    // 礼物名称输入改变时触发
    _nameInputChangeHandle = (value) => {
        this.setState({ nameInput: value });
    }

    // 所需魅力值改变时触发
    _starInputChangeHandle = (value) => {
        this.setState({ starInput: value });
    }

    // 推荐发生改变时触发
    _recommendRadioValueChangeHandle = (e) => {
        this.setState({ recommendRadioValue: e.target.value });
    }
    // 下拉选项数据处理渲染
    _giftSelectData(data) {
        return data && data.map(item => ({
            id: item.id,
            className: item.className,
        }));
    }


    render() {
        const { selectData, } = this.props;
        const {
            visible,
            confirmLoading,
            title,
            stateRadioValue,
            type,
            recommendRadioValue,
            singleImages,
            musicFileNames,
        } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '408px' }}
                        inputStyle={{ width: '340px' }}
                        label='氛围名称'
                        placeholder='请输入氛围名称'
                        onChange={this._nameInputChangeHandle}
                        value={this.state.nameInput}
                    />
                    <div className={styles.radio}>
                        <div className={styles.label}>状态</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._stateRadioValueValueChangeHandle} value={stateRadioValue}>
                            <Radio value={'1'}>启用</Radio>
                            <Radio value={'0'}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <XSelect
                        style={{ marginTop: '30px', width: '410px' }}
                        selectStyle={{ width: '342px' }}
                        label='氛围分类'
                        placeholder='请选择氛围分类'
                        renderData={this._giftSelectData(selectData)}
                        onChange={this._typeChangeHandle}
                        dataIndex='className'
                        keyIndex='id'
                        value={type.className}
                    />
                    <XInput
                        style={{ marginTop: '30px', width: '408px' }}
                        inputStyle={{ width: '340px' }}
                        label='所需魅力值'
                        placeholder='请输入魅力值'
                        onChange={this._starInputChangeHandle}
                        value={this.state.starInput}
                    />
                    <div className={styles.radio}>
                        <div className={styles.label}>推荐</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._recommendRadioValueChangeHandle} value={recommendRadioValue}>
                            <Radio value={1}>启用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </RadioGroup>
                    </div>
                    <div className={styles.upload}>
                        <div className={styles.label}>氛围图片</div>
                        <div className={styles.icon}>
                            {/* <Icon type="plus-square" style={{fontSize: '50px', color:'#b7b7b7'}} /> */}
                            <XUpload
                                uploadButton="singleImg"
                                handleChange={(file) => {
                                    if (file.status === 'done') {
                                        this.setState({ singleImage: file.qiniuInfo.key });
                                    }
                                    if (file.status === 'removed') {
                                        this.setState({
                                            singleImage: undefined,
                                            singleImages: [],
                                        });
                                    }
                                }}
                                antdUploadProps={{
                                    listType: 'picture-card',
                                    showUploadList: false,
                                    defaultFileList: singleImages
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.zip}>
                        <div className={styles.label}>
                            资源包
                        </div>
                        <div className={styles.btn}>
                            <XUpload
                                // type='media'
                                type='image'
                                uploadButton="button"
                                // maxFileList={1}
                                // hasAudioDuration
                                // removeOverMax
                                handleChange={(file) => {
                                    if (file.status === 'done') {
                                        this.setState({
                                            attachUrl: file.qiniuInfo.key,
                                            fileName: file.name,
                                        });
                                    }
                                    if (file.status === 'removed') {
                                        this.setState({
                                            attachUrl: undefined,
                                            fileName: '',
                                            musicFileNames: [],
                                        });
                                    }
                                }}
                                antdUploadProps={{
                                    accept: '.svga, .png, .jpg',
                                    defaultFileList: musicFileNames
                                }}
                            />
                        </div>
                    </div>
                    <p className={styles.media}>资源包为svga, png, jpg格式。</p>
                </div>
            </Modal>
        );
    }
}


GlistModal.propTypes = {
    data: PropTypes.object, // 对应主键ID的信息
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    selectData: PropTypes.array,//分类下拉数据
};

export default GlistModal;