import * as Order from './actionTypes';

//商品订单列表
export const getProductOrderList = (param) => ({
    type: Order.GET_PRODUCTORDER_LIST,
    payload: param
});

//商品订单类型
export const getProductOrderType = () => ({
    type: Order.GET_PRODUCTORDER_TYPE,
    payload: {}
});

//商品订单状态
export const getProductOrderStatus = () => ({
    type: Order.GET_PRODUCTORDER_STATUS,
    payload: {}
});
//认购订单状态
export const getProductSubOrderStatus = () => ({
    type: Order.GET_PRODUCTSUBORDER_STATUS,
    payload: {}
});

//商品是否开票
export const getProductOrderFlagEnum = () => ({
    type: Order.GET_PRODUCTORDER_FLAGENUM,
    payload: {}
});

//兑换方式
export const getProductConvertWays = () => ({
    type: Order.GET_PRODUCTORDER_CONVERWAYA,
    payload: {}
});
// 是否批发
export const getDistributionFlagEnum = () => ({
    type: Order.GET_PRODUCTPIFA_FLAGENUM,
    payload: {}
});
// 支付通道
export const getPaymentAccessTypeEnum = () => ({
    type: Order.GET_PAYMENTACCESSTYPEENUM,
    payload: {}
});
// 支付方式
export const getPaymentTypeEnum = () => ({
    type: Order.GET_PAYMENTTYPEENUM,
    payload: {}
});
// 订单来源
export const getOrderSourceEnum = () => ({
    type: Order.GET_ORDER_SOURCE_ENUM,
    payload: {}
});