import React from 'react';
import styles from './TableContent.module.scss';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell, tooltipClick } from '@/components/TableCell';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import ButtonPerssion from '@/routes/ButtonPerssion';

class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '付款批次号',
            dataIndex: 'batchNo',
            key: 'batchNo',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: (text, item) => tooltipClick(text, () => this.props.tableAction('batchNo', item))
        },
        {
            title: '付款公司',
            dataIndex: 'platCompanyId.value',
            key: 'platCompanyId.value',
            width: cellWidth.time,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '付款通道',
            dataIndex: 'levyId.value',
            key: 'levyId.value',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.time,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '总条数',
            dataIndex: 'qty',
            key: 'qty',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '总金额',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '待打款',
            dataIndex: 'todoStatusCount',
            key: 'todoStatusCount',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '打款中',
            dataIndex: 'doingStatusCount',
            key: 'doingStatusCount',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '打款成功',
            dataIndex: 'successStatusCount',
            key: 'successStatusCount',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '打款失败',
            dataIndex: 'failedStatusCount',
            key: 'failedStatusCount',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: (text, item) => tooltipClick(text, () => this.props.tableAction('failedStatusCount', item))
        },
        {
            title: '未签约',
            dataIndex: 'unsignedVerifyStatusCount',
            key: 'unsignedVerifyStatusCount',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: (text, item) => tooltipClick(text, () => this.props.tableAction('unsignedVerifyStatusCount', item))
        },
        {
            title: '当月超额',
            dataIndex: 'maxAmountVerifyStatusCount',
            key: 'maxAmountVerifyStatusCount',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: (text, item) => tooltipClick(text, () => this.props.tableAction('maxAmountVerifyStatusCount', item))
        },
        {
            title: '校验通过',
            dataIndex: 'successVerifyStatusCount',
            key: 'successVerifyStatusCount',
            width: cellWidth.small,
            align: 'left',
            onCell: tooltipStyle,
            render: (text, item) => tooltipClick(text, () => this.props.tableAction('successVerifyStatusCount', item))
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'left',
            fixed: 'right',
            width: cellWidth.time,
            render: (text, item) => this._renderActionCell(item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderActionCell = (item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                {item.unsignedVerifyStatusCount > 0 ? <div className={styles.item} onClick={() => tableAction('update', item)}>刷新</div> : null}
                <ButtonPerssion code='storehousefinancialmanage/otherpay/plfk'>
                    {item.todoStatusCount > 0 ? <div className={styles.item} onClick={() => tableAction('pay', item)}>批量打款</div> : null}
                </ButtonPerssion>
            </div>
        );
    }

    render() {
        const { paginationChange, scrollY } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: scrollY }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default XTableHOC(TableContent, 40);