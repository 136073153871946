import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XInput, XOKButton, XCancelButton, XDatePicker, XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell, tooltipClick } from '@/components/TableCell';
import BigNumber from 'bignumber.js';
import http from '@/assets/api/http';

const getTableData = async (params = {}) => {
    const { orderFinishedTimeStart, orderFinishedTimeEnd, orderId, userTel, merchantName, ...other } = params;
    return http('/marketing/orderSubsidy/findPagedActivitySubsidy', {
        ...other, orderId: orderId ? orderId : null, userTel: userTel ? userTel : null, merchantName: merchantName ? merchantName : null,
        orderFinishedTimeStart: orderFinishedTimeStart ? orderFinishedTimeStart.format('YYYY-MM-DD HH:mm:ss') : null,
        orderFinishedTimeEnd: orderFinishedTimeEnd ? orderFinishedTimeEnd.format('YYYY-MM-DD HH:mm:ss') : null,
    }, 'POST').then(res => { return res.result; });
};

const defaultParams = {
    orderId: null, userTel: null, merchantName: null, orderFinishedTimeStart: null, orderFinishedTimeEnd: null,
};

const SubsidiesQueue = ({ poolType = null, history, subsidyStates = [] }) => {

    const [query, setQuery] = useState({
        ...defaultParams, subsidyStates, pageNum: autoPageNum, pageSize: autopageSize, poolType
    });
    const [tableData, setTableData] = useState({ dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 });

    const onSearch = async (params = {}) => {
        const result = await getTableData(params);
        setTableData(result);
    };

    useEffect(() => {
        setQuery({ ...query, poolType });
        onSearch({ ...query, poolType });
    }, [poolType]);

    const onReset = () => {
        const callback = pre => {
            return { ...pre, ...defaultParams, pageNum: autoPageNum, pageSize: autopageSize };
        };
        setQuery(callback);
    };

    const takeColumns = () => {
        const pathnames = {
            SUBSIDY_MEMBER: '/home/ordermanage/productorder/orderdetail',
            SUBSIDY_LARGE: '/home/ordermanage/productorder/orderdetail',
            SUBSIDY_MERCHANT: '/home/ordermanage/cityorder/orderdetail',
            SUBSIDY_MERCHANT_MEMBER: '/home/ordermanage/cityorder/orderdetail',
            DAILY_SUBSIDY_MEMBER: '/home/ordermanage/productorder/orderdetail',
            DAILY_SUBSIDY_REFERRAL: '/home/ordermanage/cityorder/orderdetail',
            DAILY_SUBSIDY_SERVICER: '/home/ordermanage/productorder/orderdetail'
        };
        let columns = [
            {
                title: '序号',
                dataIndex: 'ordering',
                key: 'ordering',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: tooltip
            }, {
                title: '订单编号',
                dataIndex: 'orderId',
                key: 'orderId',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: (t, r) => tooltipClick(t, () => {
                    history.push({
                        pathname: pathnames[poolType],
                        search: `?orderId=${r.orderId}`,
                    });
                })
            }, {
                title: '会员账号',
                dataIndex: 'userTel',
                key: 'userTel',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            }, {
                title: '商户名称',
                dataIndex: 'merchantName',
                key: 'merchantName',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: tooltip
            }, {
                title: '商户积分',
                dataIndex: 'merchantWelfare',
                key: 'merchantWelfare',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: priceFenToYuanCell
            }, {
                title: '订单金额(元)',
                dataIndex: 'orderGoodsAmount',
                key: 'orderGoodsAmount',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: priceFenToYuanCell
            }, {
                title: '执行补贴比例(%)',
                dataIndex: 'subsidyRatio',
                key: 'subsidyRatio',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: t => `${new BigNumber(t || 0).multipliedBy(100).toFixed(2)}%`
            }, {
                title: '状态',
                dataIndex: 'subsidyState.value',
                key: 'subsidyState.value',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: tooltip
            }, {
                title: '订单完成时间',
                dataIndex: 'orderFinishedTime',
                key: 'orderFinishedTime',
                align: 'center',
                width: cellWidth.normal,
                onCell: tooltipStyle,
                render: tooltip
            }
        ];
        const filters = ['merchantWelfare', 'merchantName'];
        const noMerchant = ['SUBSIDY_MEMBER', 'SUBSIDY_LARGE'].includes(poolType);
        if (noMerchant || poolType.includes('DAILY')) columns = columns.filter(i => !filters.includes(i.key));
        if (poolType === 'SUBSIDY_MERCHANT_MEMBER') columns = columns.filter(i => i.key != 'merchantName');
        return columns;
    };

    const onPageChange = (pageSize, pageNum) => {
        const callback = pre => {
            return { ...pre, pageSize, pageNum };
        };
        setQuery(callback);
        onSearch({ ...query, pageSize, pageNum });
    };

    return <div className={styles.flexBoxContainer} style={{ marginTop: '10px' }}>
        <SearchBox>
            <SearchItem>
                <XInput label='订单编号' placeholder='请输入订单编号'
                    value={query.orderId} onChange={orderId => setQuery({ ...query, orderId })}
                />
            </SearchItem>
            <SearchItem>
                <XInput label='会员账号' placeholder='请输入会员账号'
                    value={query.userTel} onChange={userTel => setQuery({ ...query, userTel })}
                />
            </SearchItem>
            {poolType === 'SUBSIDY_MERCHANT' &&
                <SearchItem>
                    <XInput label='商户名称' placeholder='请输入商户名称'
                        value={query.merchantName} onChange={merchantName => setQuery({ ...query, merchantName })}
                    />
                </SearchItem>}
            <SearchItem>
                <XDatePicker label='完成时间' value={query.orderFinishedTimeStart}
                    onChange={orderFinishedTimeStart => setQuery({ ...query, orderFinishedTimeStart })}
                />
                <XDatePicker style={{ marginRight: '20px' }}
                    value={query.orderFinishedTimeEnd} onChange={orderFinishedTimeEnd => setQuery({ ...query, orderFinishedTimeEnd })}
                />
            </SearchItem>
            <SearchItem>
                <XOKButton label='查询' onClick={() => onSearch(query)} />
                <XCancelButton label='重置' onClick={onReset} />
            </SearchItem>
        </SearchBox>
        <div className={styles.tableContainer} style={{ height: '100%' }}>
            <Table rowClassName={(r, i) => i % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.id} columns={takeColumns()}
                pagination={false} dataSource={tableData.dataList} scroll={{ y: true }} />
            <XPagination resultTotal={tableData.resultTotal} pageSize={tableData.pageSize} pageNum={tableData.pageNum} onChange={onPageChange} />
        </div>
    </div>;
};
SubsidiesQueue.propTypes = {
    history: PropTypes.object,
    poolType: PropTypes.string,
    subsidyStates: PropTypes.array
};
export default SubsidiesQueue;