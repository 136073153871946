/**
 * 秀场管理 - 频道分类异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const getMusicClassSelectSource=function*(){
    yield takeEvery(T.GET_MUSICCLASS_SELECTSOURCE,function* ({payload}){
        try{
            let result = yield http('/show/showClass/listMusicClass', {pageSize:9999,pageNum:1}, 'POST'); // action.payload为传入的参数对象
            yield put({
                type:T.SET_MUSICCLASS_SELECTSOURCE, 
                payload: result.result||{},
                requestProps:payload.requestProps
            });
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

// 获取'音乐分类'表格
export const getMusicTypeTableSource=function*(){
    yield takeEvery(T.GET_MUSICTYPE_TABELSOURCE,function* ({payload}){
        try{
            let result = yield http('/show/showClass/listMusicClass', payload.requestProps, 'POST'); // action.payload为传入的参数对象
            yield put({
                type:T.SET_MUSICTYPE_TABELSOURCE, 
                payload: result.result||{},
                requestProps:payload.requestProps
            });
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

// 获取'音乐列表'表格
export const getMusicListTableSource=function*(){
    yield takeEvery(T.GET_MUSICLIST_TABELSOURCE,function* ({payload}){
        try{
            let result = yield http('/show/music/listMusic', payload.requestProps, 'POST'); // action.payload为传入的参数对象
            yield put({
                type:T.SET_MUSICLIST_TABELSOURCE, 
                payload: result.result||{},
                requestProps:payload.requestProps
            });
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};