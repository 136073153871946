import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

const TableContent = ({ renderData, paginationChange }) => {

    const columns = () => ([
        {
            title: '日期',
            dataIndex: 'months',
            key: 'months',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品分类',
            dataIndex: 'goodsCategory',
            key: 'goodsCategory ',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单金额',
            dataIndex: 'orderFeeAmount',
            key: 'orderFeeAmount ',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '售后退款',
            dataIndex: 'afterFeeAmount',
            key: 'afterFeeAmount',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '交易净额',
            dataIndex: 'tradeAmoount',
            key: 'tradeAmoount',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '费率',
            dataIndex: 'rate',
            key: 'rate',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '服务费收入',
            dataIndex: 'serviceFeeTotal',
            key: 'serviceFeeTotal',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        }
    ]);

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.id}
                columns={columns()}
                dataSource={renderData.dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;