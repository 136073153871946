/**
 * 信息组件（标签：内容，如 姓名：张三）
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';

class InfoLabel extends React.Component {

    render() {
        const { style, label, value } = this.props;

        return (
            <div className={styles.container} style={style}>
                <div className={styles.label}>{label}</div>
                <div className={styles.value}>{value}</div>
            </div>
        );
    }
}

InfoLabel.propTypes = {
    style: PropTypes.object, // 总样式
    label: PropTypes.string, // 标题值
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]), // 内容值，为字符串或数字
};


export default InfoLabel;