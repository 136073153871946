/**
 * 修改实际结算比例
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message } from 'antd';
import styles from './Virtual.module.scss';
import { XInput } from '@/components/xqxc_ui';
import { regFenToYuan } from '@/util/money';

class Inventory extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        inventory: undefined,

        incomeRangeStart: 0,
        incomeRangeEnd: 0,
        soldAmount: 0
    }

    componentDidMount() {
        http('/xiudou/earnings/details', { id: this.props.data.id }).then((res) => {
            if (res.code === 200) {
                this.setState({
                    soldAmount: Number(res.result.soldAmount),
                    incomeRangeStart: parseFloat(res.result.incomeRangeStart) / 100,
                    incomeRangeEnd: parseFloat(res.result.incomeRangeEnd) / 100,
                });

            }
        }).catch(e => message.error(e.message || '获取数据异常'));
    }

    // 点击确认
    _handleOk = () => {
        const { data } = this.props;
        const { inventory, incomeRangeStart, incomeRangeEnd } = this.state;
        let val = parseFloat(inventory);
        if (!inventory || val.toString() === 'NaN') {
            message.warn('请输入有效实际结算比例');
        } else if (val < incomeRangeStart || val > incomeRangeEnd) {
            message.warn('超出结算比例区间');
        } else {
            http('/xiudou/earnings/settlementEarnings', { id: data.id, settlementRatio: inventory * 100 }, 'POST').then(() => {
                message.success('操作成功，请勿重复操作');
                this.setState({ visible: false });
                this.props.closeMode(true);
            }).catch((e) => {
                message.error(e.message || '操作异常');
                this.setState({
                    visible: false,
                });
                this.props.closeMode();
            });
        }
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _inventoryChange = (e) => {
        // let value = parseFloat(e);
        // const { incomeRangeStart, incomeRangeEnd } = this.state;
        // if (value < incomeRangeStart || value > incomeRangeEnd) {
        //     message.warning('输入值超出结算比例区间');
        // }
        this.setState({ inventory: e });
    }

    render() {
        const { visible, confirmLoading, title, inventory, soldAmount, incomeRangeEnd, incomeRangeStart } = this.state;

        let money = inventory ? soldAmount * (parseFloat(inventory) / 100 + 1) : '-';

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div style={{ marginBottom: 15, color: '#ff0000' }}>结算比例区间：{incomeRangeStart}% ~ {incomeRangeEnd}%</div>
                    <div className={styles.name}>
                        <XInput
                            style={{ width: '380px', marginRight: 5 }}
                            inputStyle={{ width: '300px', marginLeft: '14px' }}
                            label='实际结算比例'
                            onChange={this._inventoryChange}
                            value={inventory}
                            placeholder='请输入'
                            onBlur={() => {
                                this.setState({ inventory: inventory ? parseFloat(inventory).toFixed(2) : inventory });
                            }}
                        />股
                    </div>
                    <div>预计结算总额：¥ {regFenToYuan(money)}</div>
                    <div style={{ color: '#808080', fontSize: 12, marginTop: 5 }}>
                        提示：
                        <div style={{ paddingLeft: 15 }}>1. 操作结算后对应股票订单完成结算</div>
                        <div style={{ paddingLeft: 15 }}>2. 购买股票的用户钱包增加对应收益</div>
                        <div style={{ paddingLeft: 15 }}>3. 收益以实际订单结算为准</div>
                    </div>
                </div>
            </Modal>
        );
    }
}


Inventory.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

export default Inventory;