import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tag, message, Table, Button, Alert, Badge } from 'antd';
import { XOKButton, XCancelButton, XInput, XPagination } from '@/components/xqxc_ui';
import { getCustomFittingTableSource } from '@/reducers/systemmanage/customfitting/actions';
import http from '@/assets/api/http';
import PreviewModal from '@/vcomps/CustomPreview';
import KeepAlive from '@/routes/KeepAlive';
import { tryJSONParse } from '@/assets/js/utils';
import { SearchBox, SearchItem } from '@/components/Layout';
import { autoPageNum, autopageSize } from '@/assets/config';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import styles from './index.module.scss';

const categories = [{ name: '全部', code: '' }, { name: '基础组件', code: '1' }, { name: '营销组件', code: '2' }];

const columns = (TableAction, actionClickHandle, componentLocation) => [
    {
        title: '组件图标',
        dataIndex: 'componentIcon',
        key: 'componentIcon ',
        width: '20%',
        align: 'center',
        render: (text) => (ImgCell(text))
    },
    {
        title: '组件名称',
        dataIndex: 'componentName',
        key: 'componentName',
        width: '20%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '描述',
        dataIndex: 'componentDesc',
        key: 'componentDesc',
        align: 'center',
        width: '15%',
        render: (text) => (TableCell(text))
    },
    {
        title: '组件类型',
        dataIndex: 'componentType',
        key: 'componentType',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell({ 1: '基础组件', 2: '营销组' }[text] || '-'))
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '15%',
        align: 'center',
        render: (text) => (TableCellState(text))
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: '10%',
        render: (id, record) => TableAction(record.componentId, actionClickHandle, record, componentLocation)
    }
];

const TableAction = (id, actionClickHandle, record, componentLocation) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'preview', record)}>预览</div>
            {/* {
                componentLocation === undefined ?
                    <Popconfirm title='是否删除？' okText='是' cancelText='否' onConfirm={() => actionClickHandle(id, 'delete', record)}>
                        <div className={styles.item}>删除</div>
                    </Popconfirm> : null
            } */}
        </div>
    );
};

// 表格项内容为文本
const TableCell = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text}
        </div>
    );
};

const TableCellState = (text) => {

    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text === '1' ? <Badge status="processing" text='可用' /> : <Badge status='default' text='不可用' />}
        </div>
    );
};

// 表格项内容为图片
const ImgCell = (url) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <div className={styles.imgbox}>
                <img className={styles.img} src={url} />
            </div>
        </div>
    );
};

class CustomFitting extends React.Component {

    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
        selectedRowKeys: [],
        componentName: undefined,
        componentType: '',
        componentKey: '',
        previewVisible: false
    }

    componentDidMount() {
        const { componentLocation } = (this.props.history.location.state || {});
        if (this.props.history.location.pathname.endsWith('add') && !componentLocation) {
            this.props.history.push({ pathname: '/home/systemmanage/appfitting' });
        }

        this.props.keepSecondNavBreadcrumb();
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'preview':
                this.onPreview(value);
                break;
            case 'delete':
                http('/custom/baseComponent/del', { componentIds: [id] }).then((res) => {
                    if (res.code === 200) {
                        message.success('删除成功');
                        this._searchHandle('useCache');
                    }
                }).catch(e => message.error('删除异常'));
                break;
        }
    }

    _searchHandle = (useCache) => {
        const { componentLocation, platType = 'PLAT' } = (this.props.history.location.state || {});
        const { pageSize, pageNum, componentName, componentType } = this.state;
        this.searchCache = {
            componentName,
            componentType,
            componentLocation,
            platType,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getCustomFittingTableSource(this.searchCache); // 发起请求
        KeepAlive.saveCache({ componentName, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ componentName: undefined }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        this._tableAction(id, type, value);
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    onCategoryChange = (cate) => {
        this.setState({ componentType: cate.code }, () => {
            this._searchHandle();
        });
    }

    onBatchHandle = (state) => {
        if (this.state.selectedRowKeys.length) {
            http('/custom/baseComponent/batchUpdate', { state, componentIds: this.state.selectedRowKeys }).then((res) => {
                this._searchHandle('useCache');
                if (res.code === 200) message.success('更新成功');
            });
        } else {
            message.info('请选择一条记录');
        }
    }

    onBatchAdd = () => {
        if (this.state.selectedRowKeys.length) {
            const { componentLocation } = (this.props.history.location.state || {});
            http('/custom/userAppComponents/save', {
                componentLocation: componentLocation,
                componentIds: this.state.selectedRowKeys
            }).then(({ code, result } = {}) => {
                if (code === 200 && result) {
                    message.success('添加成功');
                    this._searchHandle();
                } else {
                    message.error('添加异常');
                }
                this.setState({ selectedRowKeys: [] });
            });
        } else {
            message.info('请选择一条记录');
        }
    }

    onPreview = (component) => {
        const Key = tryJSONParse(component.componentConfigParams, { Key: '' }).Key;
        this.setState({ previewVisible: true, componentKey: Key });
    }

    returnFn = () => {
        const { componentLocation, platType } = (this.props.history.location.state || {});
        platType === 'MERCHANT'
            ? this.props.history.push({ pathname: '/home/systemmanage/storefitting', state: { componentLocation } })
            : this.props.history.push({ pathname: '/home/systemmanage/appfitting', state: { componentLocation } });
    }

    render() {
        const { dataList, pageNum, pageSize, resultTotal } = this.props.customFittingTable;
        const { componentName } = this.state;

        const { selectedRowKeys, componentType, componentKey, previewVisible } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange
        };

        const { componentLocation, category = 'ComponentPreview' } = (this.props.history.location.state || {});

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className="condition">
                        <div className="label">所属类目：</div>
                        <div className="content customfitting-tags">
                            {categories.map((cate) => <Tag.CheckableTag checked={componentType === cate.code} onChange={() => this.onCategoryChange(cate)} key={cate.code}>{cate.name}</Tag.CheckableTag>)}
                        </div>
                    </div>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                style={{ width: '320px' }}
                                inputStyle={{ width: '250px' }}
                                label='组件名称'
                                placeholder='请输入组件名称'
                                value={componentName}
                                bindName='componentName'
                                bindThis={this}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        {componentLocation ?
                            <Button onClick={() => this.onBatchAdd()} type="primary">批量添加</Button> :
                            <>
                                <Button type="primary"
                                    onClick={() => this.onBatchHandle(1)}
                                >批量启用</Button>&nbsp;&nbsp;
                            <Button
                                    onClick={() => this.onBatchHandle(0)}
                                >批量停用</Button>
                            </>
                        }
                    </div>
                    <div className={styles.tableContainer}>
                        {selectedRowKeys.length ? <Alert message={'以选择' + selectedRowKeys.length + '条'} type="info" closable style={{ marginBottom: 15 }} /> : null}
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowSelection={rowSelection}
                            rowKey={(record) => record.componentId}
                            columns={columns(TableAction, this._actionHandle, componentLocation)}
                            dataSource={dataList}
                            pagination={false}
                            scroll={{ x: true }}
                        />
                    </div>
                    <XPagination
                        resultTotal={resultTotal}
                        pageSize={pageSize}
                        pageNum={pageNum}
                        onChange={this._paginationChange}
                    />
                    <PreviewModal
                        key={componentKey}
                        category={category}
                        componentKey={componentKey}
                        modalProps={{ visible: previewVisible, onCancel: () => this.setState({ previewVisible: false }) }}
                    />
                    {
                        this.props.history.location.pathname.endsWith('add') ?
                            <div>
                                <Button type='primary' style={{ width: 100, marginTop: 15 }} onClick={this.returnFn}>返回</Button>
                            </div>
                            : null
                    }
                </div>
            </KeepAlive>
        );
    }
}

CustomFitting.propTypes = {
    history: PropTypes.object,
    customFittingTable: PropTypes.array,
    keepSecondNavBreadcrumb: PropTypes.func,
    paginationProps: PropTypes.object,
    getCustomFittingTableSource: PropTypes.func
};

const mapStateToProps = (state) => ({
    customFittingTable: state.customfitting.customFittingTable
});

export default connect(mapStateToProps, {
    getCustomFittingTableSource,
    keepSecondNavBreadcrumb
})(CustomFitting);