/**
 *  【批发商品】入口页
 * */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import AllGoods from './AllGoods';
import TrailerGoods from './TrailerGoods';
import { setSessionStorage, removeSessionStorage } from '@/assets/js/storage';

const tabs = [
    { id: 1, label: '全部商品' },
    { id: 2, label: '预告中' },
];

const Wholesalegoods = ({
    history
}) => {

    const [curTabID, setCurTabID] = useState(1);

    useEffect(() => {
        removeSessionStorage('goodsManageWholesaleGoodsTabId');
        if (history.location.state) {
            setCurTabID(history.location.state.tabID);
        }
    }, []);

    return <div className={styles.flexBoxContainer}>
        <div style={{ marginBottom: '10px' }}>
            <XTabNav
                renderData={tabs}
                onChange={tab => {
                    setCurTabID(tab);
                    setSessionStorage('goodsManageWholesaleGoodsTabId', tab);
                }}
                activedID={curTabID}
            />
        </div>
        {
            curTabID == 1 ?
                <AllGoods history={history} /> :
                <TrailerGoods history={history} />
        }
    </div>;
};

Wholesalegoods.propTypes = {
    history: PropTypes.object,
};

export default Wholesalegoods;

