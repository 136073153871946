/**
 * 技术服务费收入明细
 */
import React from 'react';
import { Tag, message } from 'antd';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import TableContent from './components/TableContent';
import model from '@/reducers/statistical/showmonthly/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
import { SearchBox, SearchItem } from '@/components/Layout';

const { RangePicker } = DatePicker;
const tags = [{ id: 2, color: '#2db7f5', value: '本月' }, { id: 3, color: '#87d068', value: '本季' }, { id: 4, color: '#108ee9', value: '全年' }];
class Main extends React.Component {

    state = {
        mode: ['month', 'month'],
        dateRange: [],
        curTagID: 2,
        period: 'month',
    };

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, period, dateRange } = this.state;
        let query = {};
        let startDate = undefined;
        let endDate = undefined;
        if (dateRange.length > 0) {
            startDate = moment(moment(dateRange[0]).format()).format('YYYY-MM');
            endDate = moment(moment(dateRange[1]).format()).format('YYYY-MM');
        }
        if (startDate && endDate) {
            query = {
                startDate,
                endDate,
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            this.setState({ period: undefined, curTagID: 0 });
        } else if (period) {
            query = {
                period,
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            this.setState({ dateRange: [] });
        } else {
            message.error('请选择充值日期范围');
        }

        this.props.getReport(query);
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ dateRange: [], curTagID: 2, period: 'month' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 渲染Tags
    _renderTags = () => {
        const { curTagID } = this.state;
        return (
            <div style={{ marginLeft: '20px' }}>
                {tags.map((item, index) => {
                    let color = curTagID == item.id ? item.color : '';
                    return <Tag style={{ width: '50px', textAlign: 'center', cursor: 'pointer' }} key={index} color={color} onClick={() => { this._tagClick(item.id); }}>{item.value}</Tag>;
                })}
            </div>
        );
    }

    // 点击Tags
    _tagClick = (id) => {
        this.setState({ curTagID: id, dateRange: [] }, () => {
            switch (id) {
                case 2:
                    this.setState({ period: 'month' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 3:
                    this.setState({ period: 'quarter' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 4:
                    this.setState({ period: 'year' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
            }
        });
    }

    handleChange = dateRange => {
        this.setState({ dateRange });
    };

    handlePanelChange = (dateRange, mode) => {
        this.setState({
            dateRange,
            mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]],
        });
    };

    render() {
        const { dateRange, mode } = this.state;
        const { report } = this.props;
        const monthValue = [];

        if (dateRange.length > 0) {
            monthValue.push(moment(dateRange[0]));
            monthValue.push(moment(dateRange[1]));
        }

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <RangePicker
                                placeholder={['开始月份', '结束月份']}
                                format="YYYY-MM"
                                value={monthValue}
                                mode={mode}
                                onChange={this.handleChange}
                                onPanelChange={this.handlePanelChange}
                            />
                        </SearchItem>
                        <SearchItem>
                            {this._renderTags()}
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent renderData={report} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getReport: PropTypes.func,
    report: PropTypes.object,
};
const mapStateToProps = (state) => ({
    report: state.showmonthly.getReport,
});
export default connect(mapStateToProps, { ...model.actions, keepSecondNavBreadcrumb })(Main);