/**
 * 【会员管理页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { message } from 'antd';
import { XInput, XOKButton, XCancelButton, XSelect, XInputNum } from '@/components/xqxc_ui';
import { CharmModal, StarlightModal, StarScoreModal, CouponModal, AreaModal, StarRice, SetIntegral, GoldMemberArea, GoldMemberChangeArea } from '../../modal';
import { connect } from 'react-redux';
import model from '@/reducers/usermanage/member/model';
import TableContent from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { autoPageNum, autopageSize } from '@/assets/config';
import { isPlat, isOC } from '@/assets/js/authType';
import { getLocalStorage } from '@/assets/js/storage';
import { SearchBox, SearchItem } from '@/components/Layout';
import userList from '@/reducers/systemmanage/merchantpermission/model';
import BatchSignModal from './BatchSignModal';

const { getMerchants } = userList.actions;

// 弹框枚举类型
const Mode = {
    CHARM_CHANGE: 'CHARM_CHANGE', // 修改成长值弹框
    STARLIGHT_CHANGE: 'STARLIGHT_CHANGE', // 修改魅力值弹框
    STARSCORE_CHANGE: 'STARSCORE_CHANGE', // 修改人气值弹框
    STARRICE_CHANGE: 'STARRICE_CHANGE', // 修改信用值弹框
    AREA_CHANGE: 'AREA_CHANGE', // 修改区域弹框
    AREA_CHANGE_SINGLE: 'AREA_CHANGE_SINGLE', // 修改单个区域弹框
    COUPON_CHANGE: 'COUPON_CHANGE', // 赠送优惠券弹框
    GOLD_MEMBER: 'GOLD_MEMBER', // 黄金会员添加
    GOLD_MEMBER_AREA_CHANGE: 'GOLD_MEMBER_AREA_CHANGE', // 黄金会员修改区域
};

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            curMode: 'default', // 当前弹框类型
            modeParam: [], // 弹框传参
            telephone: '', // 会员账号
            nickname: '', // 昵称
            level: {}, // 会员等级
            province: {}, // 省份
            city: {}, // 城市
            street: {}, // 区域
            source: {}, // 用户来源
            OCSelect: {}, // 归属运营中心
            RESelect: {}, // 归属区域公司
            cardNum: '', // 身份证号
            selectedRowKeys: [], // 复选框勾选
            selectedRows: [], // 复选框勾选记录
            userItem: {},//商户名称信息
            accountType: {},
            loginState: {},
            openRights: {}, startAge: '', endAge: ''
        };
    }

    componentDidMount() {
        const { getAccountLevel, getListAreaByParentId, getUserSource, getOperationCenter, getMerchants, getAccountTypeEnum, getLoggedOutEnum, getMemberAgencyEnum } = this.props;
        getMemberAgencyEnum();
        getAccountLevel();
        getUserSource();
        getListAreaByParentId({ id: 100000, type: 'province' });
        getOperationCenter();
        getMerchants();
        getAccountTypeEnum();
        getLoggedOutEnum();

        // 当为运营中心时，初始化归属区域公司的下拉列
        if (isOC()) {
            const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
            if (userInfo != null) {
                this.props.getAreaCompany({ operationsId: userInfo.companyId });
            }
        }
    }

    // 省份输入
    _provinceChangeHandle = (selectedItem) => {
        this.setState({ province: selectedItem, city: {}, street: {} });
        this.props.getListAreaByParentId({ id: selectedItem.id, type: 'city' });
        this.props.cleanStreet();
    }

    // 城市输入
    _cityChangeHandle = (selectedItem) => {
        this.setState({ city: selectedItem, street: {} });
        this.props.getListAreaByParentId({ id: selectedItem.id, type: 'street' });
    }

    // 区域输入 
    _streetChangeHandle = (selectedItem) => {
        this.setState({ street: selectedItem });
    }

    // 表格操作
    _tableAction = (id, type, item) => {
        if (type == 'CHANGE_AREA') {
            if (item.source === 'AGENT' || item.source === 'DANCE') {
                this.setState({ curMode: Mode.GOLD_MEMBER_AREA_CHANGE, modeParam: { ids: [id], areaCompanyType: item.areaCompanyType, source: item.source } }); // 修改区域弹框
            } else {
                this.setState({ curMode: Mode.AREA_CHANGE_SINGLE, modeParam: [id] }); // 修改区域弹框
            }
        } else {
            this.props.updateContent(id, type); // 渲染其它页面
        }
    }

    // 打开弹框
    _openMode = (type) => {
        if (this.state.selectedRowKeys.length > 0 || type === Mode.STARSCORE_CHANGE || type === 'setIntegral') {
            const accountIds = this.state.selectedRowKeys;
            this.setState({ curMode: type, modeParam: accountIds });
        } else {
            message.warning('请先选中一个或多个会员！');
        }
    }

    _openChangeArea = (type, param, title) => {
        const { selectedRows, selectedRowKeys } = this.state;

        if (type === Mode.GOLD_MEMBER_AREA_CHANGE) {
            if (selectedRowKeys.length === 0) {
                message.warning('请先选中一个或多个会员！');
                return;
            }

            let hasAGENT = selectedRows.some(v => v.source === 'AGENT');
            let hasUnAGENTOrUnSEED = selectedRows.some(v => v.source !== 'AGENT' && v.source !== 'DANCE');
            let hasSEED = selectedRows.some(v => v.source === 'DANCE');

            if (hasAGENT && hasUnAGENTOrUnSEED) {
                message.warning('黄金会员无法和其他类型的会员同时修改所属区域');
                return;
            }

            if (hasUnAGENTOrUnSEED && hasSEED) {
                message.warning('种子会员无法和其他类型的会员同时修改所属区域');
                return;
            }

            if (hasAGENT && hasSEED) {
                message.warning('种子会员无法和黄金会员同时修改所属区域');
                return;
            }

            if (hasAGENT || hasSEED) {
                this.setState({
                    curMode: Mode.GOLD_MEMBER_AREA_CHANGE,
                    modeParam: { ids: selectedRowKeys, areaCompanyType: selectedRows[0].areaCompanyType, source: hasAGENT ? 'AGENT' : 'DANCE' }
                });
            }

            if (hasUnAGENTOrUnSEED) {
                this.setState({ curMode: Mode.AREA_CHANGE, modeParam: selectedRowKeys });
            }

        } else {
            this.setState({ curMode: Mode.GOLD_MEMBER, modeParam: { param, title } });
        }
    }

    // 渲染弹框
    _renderMode = () => {
        const that = this;
        const { modeParam, curMode } = this.state;
        const modalProps = { visible: true, data: modeParam, closeMode: that._closeMode };
        const modals = {
            AREA_CHANGE_SINGLE: () => <AreaModal {...modalProps} />,
            CHARM_CHANGE: () => <CharmModal {...modalProps} />,
            STARLIGHT_CHANGE: () => <StarlightModal {...modalProps} />,
            STARRICE_CHANGE: () => <StarRice {...modalProps} />,
            STARSCORE_CHANGE: () => <StarScoreModal {...modalProps} />,
            AREA_CHANGE: () => <AreaModal {...modalProps} />,
            COUPON_CHANGE: () => <CouponModal {...modalProps} />,
            GOLD_MEMBER: () => <GoldMemberArea {...modalProps} />,
            GOLD_MEMBER_AREA_CHANGE: () => <GoldMemberChangeArea {...modalProps} />,
            setIntegral: () => <SetIntegral {...modalProps} />,
            BatchSignModal: () => <BatchSignModal {...modalProps} />,
            default: () => null
        };
        return modals[curMode]();
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ curMode: 'default', selectedRowKeys: [], modeParam: {} });
        if (update) {
            this.props.getAccountList(this.searchCache);
        }
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { telephone, nickname, cardNum, level, province, city, street, source, pageNum, pageSize, OCSelect,
            RESelect, userItem, accountType, loginState, openRights, startAge, endAge } = this.state;
        this.searchCache = {
            merchantId: userItem.merchantId,
            tels: telephone ? telephone.replace(/，/g, ',').split(',') : [],
            nicknames: nickname == '' ? [] : [nickname],
            cardNum: cardNum,
            accountLevelId: level.id,
            provinceId: province.id,
            cityId: city.id,
            areaId: street.id,
            source: source.code,
            operationsCenterId: OCSelect.id,
            areaCompanyId: RESelect.id,
            isAnchor: 0,
            accountType: accountType.code,
            loginState: loginState.code,
            openRights: openRights.code, startAge, endAge,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAccountList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            telephone: '', nickname: '', cardNum: '', level: {}, province: {}, city: {}, street: {}, source: {}, OCSelect: {},
            RESelect: {}, userItem: {}, accountType: {}, loginState: {}, openRights: {}, startAge: '', endAge: ''
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 每页pageSize条，当前第pageNum页
    _paginationChange = (pageSize, pageNum) => {
        // 方法一
        this.searchCache = { ...this.searchCache, isAnchor: 0, pageSize, pageNum };
        this.props.getAccountList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 渲染运营中心下拉列
    _renderOCSelect = () => {
        if (isPlat()) {
            return (
                <XSelect
                    label='归属运营中心'
                    placeholder='请选择运营中心'
                    renderData={this.props.OCSelectList}
                    dataIndex='companyName'
                    keyIndex='id'
                    value={this.state.OCSelect.companyName}
                    onChange={this._OCSelectChangeHandle}
                    bindThis={this}
                    bindName='OCSelect'
                    showSearch={true}
                />
            );
        } else {
            return null;
        }
    }

    // 运营中心发生变化
    _OCSelectChangeHandle = (ocItem) => {
        this.props.getAreaCompany({ operationsId: ocItem.id });
    }

    // 渲染区域公司下拉列
    _renderRESelect = () => {
        if (isPlat() || isOC()) {
            return (
                <XSelect
                    label='归属区域公司'
                    placeholder='请选择区域公司'
                    renderData={this.props.RESelectList}
                    dataIndex='companyName'
                    keyIndex='id'
                    value={this.state.RESelect.companyName}
                    bindThis={this}
                    bindName='RESelect'
                    showSearch={true}
                />
            );
        } else {
            return null;
        }
    }

    render() {
        const { telephone, nickname, cardNum, level, source, selectedRowKeys, selectedRows,
            userItem, accountType, loginState, openRights, startAge, endAge } = this.state;
        const { accountLevel, accountList, address, userSource, merchants: { merchantIdAndNames = [] } = {}, accountTypeEnum, loggedOutEnum = [], memberAgencyEnum = [] } = this.props;
        const { province, city, street } = address;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            {/* <XTextArea
                                style={{ marginRight: '20px', width: '300px' }}
                                placeholder='请输入会员账号'
                                label='会员账号'
                                bindThis={this}
                                bindName='telephone'
                                rows={2}
                                value={telephone}
                                textLength={120}
                            /> */}
                            <XInput
                                label='会员账号'
                                placeholder='请输入会员账号'
                                value={telephone}
                                bindThis={this}
                                bindName='telephone'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='会员昵称'
                                placeholder='请输入会员昵称'
                                value={nickname}
                                bindThis={this}
                                bindName='nickname'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='会员等级'
                                placeholder='请选择会员等级'
                                renderData={accountLevel}
                                dataIndex='levelName'
                                keyIndex='id'
                                value={level.levelName}
                                bindThis={this}
                                bindName='level'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='会员类型'
                                placeholder='请选择'
                                renderData={accountTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={accountType.value}
                                bindThis={this}
                                bindName='accountType'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='来源'
                                placeholder='请选择会员来源'
                                renderData={userSource}
                                dataIndex='value'
                                keyIndex='code'
                                value={source.value}
                                bindThis={this}
                                bindName='source'
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='身份证号'
                                placeholder='请输入身份证号'
                                value={cardNum}
                                bindThis={this}
                                bindName='cardNum'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                style={{ marginRight: '0px' }}
                                label='归属区域'
                                placeholder='省份'
                                renderData={province}
                                onChange={this._provinceChangeHandle}
                                dataIndex='areaName'
                                keyIndex='id'
                                showSearch={true}
                                value={this.state.province.areaName}
                            />
                            <XSelect
                                style={{ marginRight: '0px' }}
                                placeholder='城市'
                                renderData={city}
                                onChange={this._cityChangeHandle}
                                dataIndex='areaName'
                                keyIndex='id'
                                showSearch={true}
                                value={this.state.city.areaName}
                            />
                            <XSelect
                                placeholder='区域'
                                renderData={street}
                                onChange={this._streetChangeHandle}
                                dataIndex='areaName'
                                keyIndex='id'
                                showSearch={true}
                                value={this.state.street.areaName}
                            />
                        </SearchItem>
                        <SearchItem>
                            {this._renderOCSelect()}
                        </SearchItem>
                        <SearchItem>
                            {this._renderRESelect()}
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='商户名称'
                                placeholder='请选择商户名称'
                                renderData={merchantIdAndNames}
                                dataIndex='merchantName'
                                keyIndex='merchantId'
                                bindThis={this}
                                bindName='userItem'
                                value={userItem.merchantName}
                                showSearch={true}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='注销状态'
                                placeholder='请选择注销状态'
                                renderData={loggedOutEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={loginState.value}
                                bindThis={this}
                                bindName='loginState'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='会员代理权益'
                                placeholder='请选择'
                                renderData={memberAgencyEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={openRights.value}
                                bindThis={this}
                                bindName='openRights'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInputNum label='年龄' placeholder='请输入最小年龄' max={startAge}
                                value={startAge} bindThis={this} bindName='startAge' />
                            <XInputNum placeholder='请输入最大年龄' min={endAge}
                                value={endAge} bindThis={this} bindName='endAge' />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <ButtonPerssion code='usermanage/member/pxjhjhy'>
                            <XOKButton style={{ marginLeft: '0px', marginRight: '20px', width: '140px' }} label='新建黄金会员' onClick={() => this._openChangeArea(Mode.GOLD_MEMBER, '/user/account/addAgentAccount', '新建黄金会员')} />
                        </ButtonPerssion>
                        <ButtonPerssion code='usermanage/member/xjzzhy'>
                            <XOKButton style={{ marginLeft: '0px', marginRight: '20px', width: '140px' }} label='新建种子会员' onClick={() => this._openChangeArea(Mode.GOLD_MEMBER, '/user/account/addSeedAccount', '新建种子会员')} />
                        </ButtonPerssion>
                        <ButtonPerssion code='usermanage/member/plxgyd'>
                            <XOKButton style={{ marginLeft: '0px', marginRight: '20px', width: '140px' }} label='批量修改魅力值' onClick={() => this._openMode(Mode.STARLIGHT_CHANGE)} />
                        </ButtonPerssion>
                        <ButtonPerssion code='usermanage/member/plxgxd'>
                            <XOKButton style={{ marginRight: '20px', width: '140px' }} label='批量修改人气值' onClick={() => this._openMode(Mode.STARSCORE_CHANGE)} />
                        </ButtonPerssion>
                        <ButtonPerssion code='usermanage/member/plxgxm'>
                            <XOKButton style={{ marginLeft: '0px', marginRight: '20px', width: '140px' }} label='批量修改信用值' onClick={() => this._openMode(Mode.STARRICE_CHANGE)} />
                        </ButtonPerssion>
                        <ButtonPerssion code='usermanage/member/plxgqy'>
                            <XOKButton style={{ marginRight: '20px', width: '120px' }} label='批量修改区域' onClick={() => this._openChangeArea(Mode.GOLD_MEMBER_AREA_CHANGE)} />
                        </ButtonPerssion>
                        <ButtonPerssion code='usermanage/member/plzsyhq'>
                            <XOKButton style={{ width: '140px' }} label='批量赠送优惠券' onClick={() => this._openMode(Mode.COUPON_CHANGE)} />
                        </ButtonPerssion>
                        <ButtonPerssion code='usermanage/member/plzstqz'>
                            <XOKButton style={{ width: '140px', marginLeft: '20px' }} label='批量赠送特权值' onClick={() => this._openMode('setIntegral')} />
                        </ButtonPerssion>
                        <ButtonPerssion code='usermanage/member/plqy'>
                            <XOKButton style={{ width: '100px', marginLeft: '20px' }} label='批量签约' onClick={() => this.setState({ curMode: 'BatchSignModal' })} />
                        </ButtonPerssion>
                    </div>
                    <TableContent
                        renderData={accountList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                        selectedRowKeys={selectedRowKeys}
                        selectedRows={selectedRows}
                        selectedRowKeysChange={(selectedRowKeys, selectedRows) => { this.setState({ selectedRowKeys, selectedRows }); }}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    getAccountLevel: PropTypes.func, // 请求会员等级
    getAccountList: PropTypes.func, // 请求会员列表
    getListAreaByParentId: PropTypes.func, // 请求地区信息
    accountLevel: PropTypes.array, // 会员等级
    accountList: PropTypes.object, // 会员列表
    address: PropTypes.object, // 省市区
    getUserSource: PropTypes.func, // 获取用户来源
    userSource: PropTypes.array, // 用户来源
    getOperationCenter: PropTypes.func, // 获取归属运营中心
    OCSelectList: PropTypes.array, // 归属运营中心下拉列
    getAreaCompany: PropTypes.func, // 获取归属区域公司
    RESelectList: PropTypes.array, // 归属区域公司下拉列
    cleanStreet: PropTypes.func, // 清理区域搜索结果
    getMerchants: PropTypes.func, // 获取商户下拉列
    merchants: PropTypes.object, // 商户下拉列

    getAccountTypeEnum: PropTypes.func, // 获取用户类型
    accountTypeEnum: PropTypes.array, // 用户类型
    getLoggedOutEnum: PropTypes.func,
    loggedOutEnum: PropTypes.array,
    getMemberAgencyEnum: PropTypes.func,
    memberAgencyEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
    userSource: state.member.getUserSource,
    accountLevel: state.member.accountLevel,
    accountList: state.member.accountList,
    address: state.member.address,
    OCSelectList: state.member.getOperationCenter,
    RESelectList: state.member.getAreaCompany,
    merchants: state.merchantpermission.merchants,
    accountTypeEnum: state.member.accountTypeEnum.filter(i => i.code === 'GENERAL_MEMBER' ||
        i.code === 'VIRTUAL_MEMBER' ||
        i.code === 'CHILD_ACCOUNT' ||
        i.code === 'MAIN_ACCOUNT'),
    loggedOutEnum: state.member.loggedOutEnum,
    memberAgencyEnum: state.member.memberAgencyEnum,
});

export default connect(mapStateToProps, { ...model.actions, getMerchants })(Main);