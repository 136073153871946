import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table, Tooltip } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

export default class TableContent extends Component {
	state = {
		selectedRowKeys: [],
	};
	static propTypes = {
		tableAction: PropTypes.func,
		paginationChange: PropTypes.func,
		renderData: PropTypes.object,
	};
	_columns = () => [
		{
			title: '商品图片',
			dataIndex: 'mainImgUrl',
			key: 'mainImgUrl',
			align: 'center',
			width: '10%',
			render: ImgCell
		},
		{
			title: '商品标题',
			dataIndex: 'goodsName',
			key: 'goodsName',
			align: 'center',
			width: '15%',
			onCell: () => this._styleCell(),
			render: (text) => this._tableTipCell(text)
		},
		{
			title: '货号',
			dataIndex: 'goodsNo',
			key: 'goodsNo',
			align: 'center',
			width: '15%',
			onCell: () => this._styleCell(),
			render: (text) => this._tableTipCell(text)
		},
		{
			title: '商品分类',
			dataIndex: 'categoryName',
			key: 'categoryName',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '分类类型',
			dataIndex: 'categorySubTypeName',
			key: 'categorySubTypeName',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '商户名称',
			dataIndex: 'merchantName',
			key: 'merchantName',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '状态',
			dataIndex: 'goodsState',
			key: 'goodsState',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '更新时间',
			dataIndex: 'gmtModified',
			key: 'gmtModified',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			width: '10%',
			render: (id, record) => this._tableAction(id, record)
		},
	];
	_styleCell = () => {
		return {
			style: {
				maxWidth: 100,
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				cursor: 'pointer'
			}
		};
	}
	_tableTipCell = (text) => {
		return (
			<Tooltip placement='topLeft' title={text}>{text}</Tooltip>
		);
	};
	// 表格项内容为图片
	_imgCell = (url) => {
		return (
			<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
				<div className={styles.imgbox}>
					<img className={styles.img} style={{ width: '50px', height: '50px' }} src={url} />
				</div>
			</div>
		);
	};
	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		if (record.goodsState === '待审核') {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'audit')}>审核</div>
					<div className={styles.item} onClick={() => tableAction(key, 'detail')}>详情</div>
				</div>
			);
		} else {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(key, 'detail')}>详情</div>
				</div>
			);
		}

	};
	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log(selectedRows);
			this.setState({ selectedRowKeys });
			this.props.tableAction(null, 'batchBan', selectedRowKeys);
		}
	};

	// 清空表格复选框
	_cleanAllSelected = () => {
		this.setState({ selectedRowKeys: [] });
	}
	_tableData = (data) => {
		return data ? data : [];
	}

	render() {
		const { renderData, paginationChange } = this.props;
		const { selectedRowKeys } = this.state;
		this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
			<div className={styles.tableContainer}>
				{/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					// rowSelection={this._rowSelection}
					rowKey={record => record.id}
					columns={this._columns()}
					pagination={false}
					dataSource={this._tableData(renderData.dataList)}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}