/**
 * 【用户管理】入口页
 */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const Mode = {
    MEMBER_INFO: 'MEMBER_INFO', // 会员信息
    MEMBER_RECOMMEND: 'MEMBER_RECOMMEND', // 推荐明细
    MEMBER_RANDP: 'MEMBER_RANDP', // 收支明细
    MEMBER_SUGGEST: 'MEMBER_SUGGEST', // 推荐明细（小B）
};

class Member extends React.Component {

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航只保留到2级
    }

    _updateContent = (id, mode) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case Mode.MEMBER_INFO: // 会员信息
                addOneBreadcrumbPath({
                    title: '会员信息',
                    path: '/home/usermanage/giftmember/info/' + id
                });
                history.push({
                    pathname: '/home/usermanage/giftmember/info/' + id
                });
                break;
            case Mode.MEMBER_RANDP: // 收支明细
                addOneBreadcrumbPath({
                    title: '收支明细',
                    path: '/home/usermanage/giftmember/randp/' + id
                });
                history.push({
                    pathname: '/home/usermanage/giftmember/randp/' + id
                });
                break;
            case Mode.MEMBER_RECOMMEND: // 推荐明细
                addOneBreadcrumbPath({
                    title: '推荐明细',
                    path: '/home/usermanage/giftmember/recommend/' + id + '/ACCOUNT'
                });
                history.push({
                    pathname: '/home/usermanage/giftmember/recommend/' + id + '/ACCOUNT'
                });
                break;
            case Mode.MEMBER_SUGGEST:
                addOneBreadcrumbPath({
                    title: '推荐明细',
                    path: '/home/usermanage/giftmember/recommend/' + id + '/ZCYX'
                });
                history.push({
                    pathname: '/home/usermanage/giftmember/recommend/' + id + '/ZCYX'
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

Member.propTypes = {
    history: PropTypes.object, // router history
    match: PropTypes.object, // router match
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func // 保持两级面包屑导航
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Member);