/**
 * 核对单删除
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './DeleteModal.module.scss';
import http from '@/assets/api/http';

class DeleteModal extends React.Component {
    state = {
        confirmLoading: false
    };

    _handleOk = () => {
        this.setState({ confirmLoading: true });
        const { id } = this.props.param;
        http('settle/stock/removeStockRecon', { id }).then((response) => {
            this.setState({ confirmLoading: false });
            this.props.close('update');
        }).catch((error) => {
            message.error(error.message);
            this.setState({ confirmLoading: false });
        });
    }

    _handleCancel = () => {
        this.props.close();
    }

    render() {
        const { confirmLoading } = this.state;

        return (
            <Modal
                width={470}
                centered
                title='删除记录'
                visible={true}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    确定删除当前核对单么？确定后您可重新生成！
                </div>
            </Modal>
        );

    }
}

DeleteModal.propTypes = {
    close: PropTypes.func.isRequired, // 关闭弹框的回调函数
    param: PropTypes.object
};

export default DeleteModal;