import * as T from './actiontypes';

/**
 * 商品分类列表
 */
const productClassifyListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0,
};

/**
 * 商品分类的数据格式
 */
const classify = {
    id: '',
    parentId: '',
    categoryName: '',
    sortNo: '',
    state: 0,
    gmtCreate: '',
    imgUrl: '',
    platRate: '',
    agentRate: ''
};

/**
 * 新增一个空白分类项
 */
const addNullCell = (list, parentID) => {
    if (parentID === 0) {
        let nullCell = { ...classify };
        // nullCell.id = RandomNumBoth(1, 100000); .
        nullCell.id = 'new' + RandomNumBoth(1, 100000);
        nullCell.parentId = parentID;
        nullCell.categoryInfoList = [];
        nullCell.dotype = 'save'; // 新增空白分类的操作类型(删除、保存)
        list.unshift(nullCell);
    } else {
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === parentID) {
                if (list[i].categoryInfoList) {
                    let nullCell = { ...classify };
                    nullCell.id = 'new' + RandomNumBoth(1, 100000);
                    nullCell.parentId = parentID;
                    nullCell.categoryInfoList = [];
                    nullCell.dotype = 'save'; // 新增空白分类的操作类型(删除、保存)
                    list[i].categoryInfoList.push(nullCell);
                    break;
                }
            } else {
                if (list[i].categoryInfoList && list[i].categoryInfoList.length > 0) {
                    addNullCell(list[i].categoryInfoList, parentID);
                } else {
                    continue;
                }
            }
        }
    }
    return list;
};

/**
 * 移除一个空白分类项
 */
const deleteNullCell = (list, id) => {
    list = list.filter((item) => {
        if (item.id == id) {
            return false;
        } else {
            if (item.categoryInfoList.length > 0) {
                item.categoryInfoList = deleteNullCell(item.categoryInfoList, id);
            }
            return true;
        }
    });
    return list;
};

// 生成一个随机数
const RandomNumBoth = (Min, Max) => {
    var Range = Max - Min;
    var Rand = Math.random();
    var num = Min + Math.round(Rand * Range); //四舍五入
    return num;
};

export const productClassifyList = (state = productClassifyListInital, action) => {
    let newState = { ...state };
    switch (action.type) {
        case T.SET_PRODUCT_CLASSIFY:
            return action.payload.result;
        case T.ADD_PRODUCT_NULLCELL:
            newState.dataList = addNullCell({ ...state }.dataList, action.payload.parentId);
            return newState;
        case T.DELETE_PRODUCT_NULLCELL:
            newState.dataList = deleteNullCell({ ...state }.dataList, action.payload.id);
            return newState;
        default:
            return state;
    }
};


/**
 * [第三方商品分类]
 * @author: Fu Xiaochun
 * @date: 2021-06-02 
 */

const productThirdPartyClassifyInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0,
};

export const productThirdPartyClassify = (state = productThirdPartyClassifyInital, action) => {
    switch (action.type){
        case T.SET_PRODUCT_THIRDPARTYCLASSIFY:
            return action.payload.result;
        default:
            return state;
    }
};

// const productClassifyListInital = {
//     dataList: [
//         {
//            id: 100,
//            parentId: 0,
//            categoryName:'鞋子',
//            sortNo: 1,
//            gmtCreate: '2019-06-20 10:00',
//            imgUrl: '',
//            platRate:'',
//            agentRate:'',
//            categoryInfoList: [
//                {
//                    id: 10,
//                    parentId: 100,
//                    categoryName:'男士鞋子',
//                    sortNo: 1,
//                    gmtCreate: '2019-06-20 10:00',
//                    imgUrl: '',
//                    platRate:'',
//                    agentRate:'',
//                    categoryInfoList: [
//                         {
//                             id: 100101,
//                             parentId: 10,
//                             categoryName:'运动鞋',
//                             sortNo: 1,
//                             gmtCreate: '2019-06-20 10:00',
//                             imgUrl: '',
//                             platRate:'',
//                             agentRate:'',
//                             categoryInfoList: null
//                         },
//                    ]
//                },
//                {
//                     id: 11,
//                     parentId: 100,
//                     categoryName:'女士鞋子',
//                     sortNo: 2,
//                     gmtCreate: '2019-06-20 10:00',
//                     imgUrl: '',
//                     platRate:'',
//                     agentRate:'',
//                     categoryInfoList: [
//                         {
//                             id: 1001101,
//                             parentId: 11,
//                             categoryName:'高跟鞋',
//                             sortNo: 1,
//                             gmtCreate: '2019-06-20 10:00',
//                             imgUrl: '',
//                             platRate:'',
//                             agentRate:'',
//                             categoryInfoList: null
//                         },
//                         {
//                             id: 1001102,
//                             parentId: 11,
//                             categoryName:'帆布鞋',
//                             sortNo: 2,
//                             gmtCreate: '2019-06-20 10:00',
//                             imgUrl: '',
//                             platRate:'',
//                             agentRate:'',
//                             categoryInfoList: null
//                         }
//                     ]
//                 },
//            ]
//         },
//         {
//             id: 101,
//             parentId: 0,
//             categoryName:'衣服',
//             sortNo: 2,
//             gmtCreate: '2019-06-20 10:00',
//             imgUrl: '',
//             platRate:'',
//             agentRate:'',
//             categoryInfoList: []
//         },
//         {
//             id: 102,
//             parentId: 0,
//             categoryName:'帽子',
//             sortNo: 3,
//             gmtCreate: '2019-06-20 10:00',
//             imgUrl: '',
//             platRate:'',
//             agentRate:'',
//             categoryInfoList: []
//         }
//     ],
//     pageNum: 1,
//     pageSize: 10,
//     resultTotal: 0,
// };

