/**
 * 【员工列表】
 */
import React from 'react';
import { message } from 'antd';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import TableContent from './components/TableContent';
import NoLogin from './modal/NoLogin';
import ResetPassword from './modal/ResetPassword';
import DeleteModal from './modal/DeleteModal';
import { getEmplList, getDeptSelect, getEmplState, getEmplType } from '@/reducers/permissionsmanage/employeelist/actions';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

class Main extends React.Component {

    state = {
        name: '', // 员工姓名
        tel: '', // 员工电话
        status: {}, // 状态
        type: {}, // 所属分类
        dept: {}, // 部门
        currentOpenMode: '', // 当前弹框
        openModeParam: '', // 弹框入参
    };

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getEmplState();
        this.props.getEmplType();
        this.props.getDeptSelect();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, name, status, type, dept, tel } = this.state;
        const query = {
            emplName: name,
            tel,
            companyType: type.code,
            state: status.code,
            deptId: dept.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getEmplList(query);
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ name: '', tel: '', status: {}, type: {}, dept: {}, openMode: '', modeParam: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格操作
    _tableAction = (id, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'edit':
                addOneBreadcrumbPath({
                    title: '编辑员工信息',
                    path: '/home/privilegemanage/employeelist/edit/'
                });
                history.push({
                    pathname: `/home/privilegemanage/employeelist/edit/${id}`
                });
                break;
            case 'disable': // 有两种情况：禁止和启用
                this.setState({ currentOpenMode: 'state', openModeParam: id });
                break;
            case 'able':
                http('/admin/empl/activeEmpl', { id }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('启用成功！');
                        this._searchHandle('useCache');
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
            case 'reset':
                this.setState({ currentOpenMode: 'reset', openModeParam: id });
                break;
            case 'del':
                this.setState({ currentOpenMode: 'del', openModeParam: id });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'state':
                return <NoLogin
                    title='禁止登录'
                    visible={true}
                    id={this.state.openModeParam}
                    closeMode={this._closeMode}
                />;
            case 'reset':
                return <ResetPassword
                    title='重置密码'
                    visible={true}
                    id={this.state.openModeParam}
                    closeMode={this._closeMode}
                />;
            case 'del':
                return <DeleteModal
                    title='系统提示'
                    visible={true}
                    id={this.state.openModeParam}
                    closeMode={this._closeMode}
                />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' });
        if (update) {
            this._searchHandle('useCache');
        }
    }

    // 新建员工
    _newHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建员工',
            path: '/home/privilegemanage/employeelist/add'
        });
        history.push({
            pathname: '/home/privilegemanage/employeelist/add'
        });
    }

    render() {
        const { name, tel, status, type, dept } = this.state;
        const { emplList, emplState, emplType, deptSelect } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='员工姓名'
                                placeholder='请输入员工姓名'
                                value={name}
                                bindThis={this}
                                bindName='name'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='联系电话'
                                placeholder='请输入联系电话'
                                value={tel}
                                bindThis={this}
                                bindName='tel'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={emplState}
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                                bindThis={this}
                                bindName='status'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='所属分类'
                                placeholder='请选择所属分类'
                                renderData={emplType}
                                dataIndex='value'
                                keyIndex='code'
                                value={type.value}
                                bindThis={this}
                                bindName='type'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='所属部门'
                                placeholder='请选择所属部门'
                                renderData={deptSelect}
                                dataIndex='deptName'
                                keyIndex='id'
                                value={dept.deptName}
                                bindThis={this}
                                bindName='dept'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.row3}>
                        <XOKButton
                            style={{ width: '100px' }}
                            label='新建员工'
                            onClick={this._newHandle}
                        />
                    </div>
                    <TableContent renderData={emplList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getEmplList: PropTypes.func, // 获取员工列表
    emplList: PropTypes.object, // 员工列表
    getDeptSelect: PropTypes.func, // 获取部门下拉列
    deptSelect: PropTypes.array, // 部门下拉列
    getEmplState: PropTypes.func,  // 获取员工状态
    emplState: PropTypes.array, // 员工状态
    getEmplType: PropTypes.func, // 获取所属分类
    emplType: PropTypes.array, // 所属分类
};

const mapStateToProps = (state) => ({
    emplList: state.employee.getEmplList,
    deptSelect: state.employee.getDeptSelect,
    emplState: state.employee.getEmplState,
    emplType: state.employee.getEmplType,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getEmplList, getDeptSelect, getEmplState, getEmplType })(Main);