import { createModel } from '@/store/tools';

const model = {
    namespace: 'thirdreconciliation',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getTableData': T('getTableData'),
            'getThirdReconciliationData': T('getThirdReconciliationData'),
            'getDistributionStatusList': T('getDistributionStatusList'),
            'getSourceList': T('getSourceList'),
            'getOrderTypeList': T('getOrderTypeList'),
        },
        actions: {
            'getTableData': A('getTableData'),
            'getThirdReconciliationData': A('getThirdReconciliationData'),
            'getDistributionStatusList': A('getDistributionStatusList'),
            'getSourceList': A('getSourceList'),
            'getOrderTypeList': A('getOrderTypeList'),
        },
        sagas: {
            'getTableData': S('getTableData', '/shengxin/order/reconciliation'),
            'getThirdReconciliationData': S('getThirdReconciliationData', '/shengxin/order/list'),
            'getDistributionStatusList': S('getDistributionStatusList', '/enum/AllotOrderStatusEnum'),
            'getSourceList': S('getSourceList', '/enum/AllotChannelSourceEnum'),
            'getOrderTypeList': S('getOrderTypeList', '/enum/AllotOrderTypeEnum'),
        },
        reducers: {
            'tableData': R('getTableData', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'thirdReconciliationData': R('getThirdReconciliationData', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'distributionStatusList': R('getDistributionStatusList', []),
            'sourceList': R('getSourceList', []),
            'orderTypeList': R('getOrderTypeList', []),
        }
    })
};
export default createModel(model);