/**
 * 礼物分类
 */
import React from 'react';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { Unable } from '../../modal';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getListVideoData, getListVideoSelect } from '@/reducers/showmanage/channellist/actions';
import { getPornographyType } from '@/reducers/auditmanage/pornography/actions';
import OnlinePlayModal from './modal/OnlinePlayModal';
import { SearchBox, SearchItem } from '@/components/Layout';

// 模拟下拉列数据
const selectDataSource = [];
for (let i = 0; i < 2; i++) {
    selectDataSource.push({
        classTypeCode: 'classTypeCode' + i,
        className: 'className' + i
    });
}
const Mode = {
    INFO: 'INFO',
    ABLE: 'ABLE',
    UNABLE: 'UNABLE',
    DELETE: 'DELETE'
};

class VideoList extends React.Component {

    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: '', // 当前需要打开的弹窗的入参
        selectDataSource: [],
        feedTitle: '', // 视频主题
        tel: '', // 会员账号
        feedClass: {}, // 视频分类
        typeList: [],//禁播理由
        playUrl: '',//视频地址
        sort: ''
    }

    componentDidMount() {
        this.props.getListVideoSelect();
        this.props.getPornographyType();
    }

    // 推荐按钮被点击
    _recommendHandle = () => {
        const { updateContent } = this.props;
        updateContent('VIDEO_LIST_RECOMMEND', 'video');
    }

    // 创建视频被点击
    _videoCreate = () => {
        const { updateContent } = this.props;
        updateContent('VIDEO_LIST_CREATE');
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { feedTitle, tel, feedClass, sort, pageNum, pageSize } = this.state;
        this.searchCache = {
            feedTitle, feedClassId: feedClass.id, tel, sort,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getListVideoData(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ feedTitle: '', tel: '', feedClass: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST')
            .then(() => {
                message.success(winTxt);
                this._searchHandle('useCache');
            })
            .catch((e) => { message.error(e.message); });
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        const { updateContent } = this.props;
        console.log(id);
        switch (type) {
            case 'switch_home':
                if (value) {
                    this._sendReq('/show/feed/updateFeedVideoHomeReco', { id: id, isHomeReco: 1 }, '首页推荐成功。');
                } else {
                    this._sendReq('/show/feed/updateFeedVideoHomeReco', { id: id, isHomeReco: 0 }, '取消首页推荐成功。');
                }
                break;
            case 'switch_channel':
                if (value) {
                    this._sendReq('/show/feed/updateFeedVideoChannelReco', { id: id, isChannelReco: 1 }, '频道推荐成功。');
                } else {
                    this._sendReq('/show/feed/updateFeedVideoChannelReco', { id: id, isChannelReco: 0 }, '取消频道推荐成功。');
                }
                break;
            case Mode.INFO:
                console.log('查看详情', id, type, value);
                updateContent('VIDEO_LIST_INFO', id);
                break;
            case Mode.ABLE:
                console.log('播放', id, type, value);
                this.setState({ currentOpenMode: Mode.ABLE, openModeParam: id, playUrl: value.playUrl });
                break;
            case Mode.UNABLE://typeList
                {
                    http('/show/reporttype/listReportTypes', {}, 'POST').then((res) => {
                        this.setState({
                            typeList: res.result.dataList,
                            openModeParam: id,
                            currentOpenMode: Mode.UNABLE,
                        });
                    });
                }
                console.log('禁播', id, type, value);
                break;
            case Mode.DELETE:
                console.log('删除', id, type, value);
                showConfirm('您确定要删除该视频？', '', () => {
                    this._sendReq('/show/feed/deleteFeed', { id }, '删除成功。');
                });
                break;
            case 'sortNo':
                if (value.field === 'c9') {
                    if (value.order === 'ascend') {
                        this.setState({
                            sort: 'ASC',
                        }, () => {
                            this._searchHandle('useCache');
                        });
                    } else if (value.order === 'descend') {
                        this.setState({
                            sort: 'DESC',
                        }, () => {
                            this._searchHandle('useCache');
                        });
                    } else {
                        this.setState({
                            sort: '',
                        }, () => {
                            this._searchHandle('useCache');
                        });
                    }
                }
                break;
        }
    }

    // 渲染弹框
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.ABLE:
                return <OnlinePlayModal visible={true} data={{ openModeParam: this.state.openModeParam, playUrl: this.state.playUrl }} closeMode={this._closeMode} />;
            case Mode.UNABLE:
                return <Unable visible={true} data={this.state.openModeParam} typeList={this.state.typeList} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '', openModeParam: '' }, () => {
            if (update) this._searchHandle('useCache');
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { selectDataSource, feedTitle, tel, feedClass } = this.state;
        const { listVideoData, listVideoSelect } = this.props;

        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            {/* <XInput
                            style={{ width: '266px' }}
                            inputStyle={{ width: '200px' }}
                            label='视频ID'
                            placeholder='请输入视频ID'
                            onChange={this._videoIDInputChangeHandle}
                            value={videoID}
                        /> */}
                            <XInput
                                label='视频主题'
                                placeholder='请输入视频主题'
                                bindThis={this}
                                bindName='feedTitle'
                                value={feedTitle}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='会员账号/手机号'
                                placeholder='请输入会员账号/手机号'
                                bindThis={this}
                                bindName='tel'
                                value={tel}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='视频分类'
                                placeholder='请选择视频分类'
                                renderData={listVideoSelect && listVideoSelect.dataList ? listVideoSelect.dataList : selectDataSource}
                                dataIndex='className'
                                keyIndex='id'
                                bindThis={this}
                                bindName='feedClass'
                                value={feedClass.className}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }} label='创建视频' onClick={this._videoCreate} />
                        <XOKButton style={{ width: '60px', marginLeft: '30px' }} label='推荐' onClick={this._recommendHandle} />
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={listVideoData} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

VideoList.propTypes = {
    updateContent: PropTypes.func,
    getListVideoData: PropTypes.func,
    getListVideoSelect: PropTypes.func,
    getPornographyType: PropTypes.func,
    listVideoData: PropTypes.object,
    listVideoSelect: PropTypes.object,
    // typeList: PropTypes.array
};

const mapStateToProps = (state) => ({
    listVideoData: state.channellist.listVideoData,
    listVideoSelect: state.channellist.listVideoSelect,
    // typeList: state.pornography.pornographyType,
});

export default connect(mapStateToProps, { getListVideoData, getListVideoSelect, getPornographyType })(VideoList);