export const GET_SHOWLIST_DATA = 'SHOWREPORT/GET_SHOWLIST_DATA'; // 秀场举报列表
export const SET_SHOWLIST_DATA = 'SHOWREPORT/SET_SHOWLIST_DATA'; // 秀场举报列表

export const GET_SHOWREORTSTATE_DATA = 'SHOWREPORT/GET_SHOWREORTSTATE_DATA'; // 秀场分类
export const SET_SHOWREORTSTATE_DATA = 'SHOWREPORT/SET_SHOWREORTSTATE_DATA'; //秀场分类

export const GET_SHOWRESTOP_DATA = 'SHOWREPORT/GET_SHOWRESTOP_DATA'; //  秀场户举报禁播，封禁类型和封禁理由数据源
export const SET_SHOWRESTOP_DATA = 'SHOWREPORT/SET_SHOWRESTOP_DATA'; // 秀场举报禁播，封禁类型和封禁理由数据源

export const GET_SHOWREDETAIL_DATA = 'SHOWREPORT/GET_SHOWREDETAIL_DATA'; // 举报详情
export const SET_SHOWREDETAIL_DATA = 'SHOWREPORT/SET_SHOWREDETAIL_DATA'; //举报详情
