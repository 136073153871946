import * as T from './actiontypes';

/**
 * 获取'商户审核列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {sting} merchantName 
 * @param {sting} merchantState 固定参数"AUDIT"
 */
export const merchantListTableSource = (dataload) => ({
    type: T.GET_MERCHANT_DATAS,
    payload: dataload,
});

/**
 * 获取'商户审核详情'数据源(saga)
 * @param {number} merchantId  获取某个商户详情与商户编辑对应的id
 */
export const getmerchantDetails = (merchantId) => ({
    type: T.GET_STOREDETAIL_DATAS,
    payload: {
        merchantId,
    }
});
/**
 * 获取'审核店铺'数据源(saga)
 * @param {number} merchantId  对应的id
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页"
 */
export const getStore = (pageSize, pageNum, merchantId, ) => ({
    type: T.GET_STORE_DATAS,
    payload: {
        pageSize,
        pageNum,
        merchantId,
    }
});

