/**
 * 会员统计报表
 */
import React from 'react';
import { Tag, message } from 'antd';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XDatePicker, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import TableContent from './components/TableContent';
import model from '@/reducers/statistical/operationmember/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

const tags = [{ id: 1, color: '#f50', value: '昨日' }, { id: 2, color: '#2db7f5', value: '本周' }, { id: 3, color: '#87d068', value: '本月' }, { id: 4, color: '#108ee9', value: '全年' }];

class Main extends React.Component {

    state = {
        startDate: undefined,
        endDate: undefined,
        curTagID: 1,
        period: 'yesterday'
    };

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, period, startDate, endDate } = this.state;
        let query = {};
        if (startDate && endDate) {
            query = {
                startDate,
                endDate,
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            this.setState({ period: undefined, curTagID: 0 });
        } else if (period) {
            query = {
                period,
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            this.setState({ startDate: undefined, endDate: undefined });
        } else {
            message.error('请选择付款日期范围');
        }
        this.props.getUserReport(query);
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ startDate: undefined, endDate: undefined, curTagID: 1, period: 'yesterday' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 渲染Tags
    _renderTags = () => {
        const { curTagID } = this.state;
        return (
            <div style={{ marginLeft: '20px' }}>
                {tags.map((item, index) => {
                    let color = curTagID == item.id ? item.color : '';
                    return <Tag style={{ width: '50px', textAlign: 'center', cursor: 'pointer' }} key={index} color={color} onClick={() => { this._tagClick(item.id); }}>{item.value}</Tag>;
                })}
            </div>
        );
    }

    // 点击Tags
    _tagClick = (id) => {
        this.setState({ curTagID: id, startDate: undefined, endDate: undefined }, () => {
            switch (id) {
                case 1:
                    this.setState({ period: 'yesterday' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 2:
                    this.setState({ period: 'week' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 3:
                    this.setState({ period: 'month' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 4:
                    this.setState({ period: 'year' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
            }
        });
    }

    render() {
        const { startDate, endDate } = this.state;
        const { userReport } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XDatePicker
                                label='选择日期'
                                placeholder='请选择开始日期'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='00:00:00'
                            />
                            <XDatePicker
                                placeholder='请选择结束日期'
                                label=''
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                                showTime={false}
                                defaultTime='23:59:59'
                            />
                        </SearchItem>
                        <SearchItem>
                            {this._renderTags()}
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <TableContent renderData={userReport} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getUserReport: PropTypes.func,
    userReport: PropTypes.object
};
const mapStateToProps = (state) => ({
    userReport: state.operationmember.getUserReport
});
export default connect(mapStateToProps, { ...model.actions, keepSecondNavBreadcrumb })(Main);