import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Button, Icon, message } from 'antd';
import http from '@/assets/api/http';
import { XTitle } from '@/components/xqxc_ui';
import CustomUpload, { generateFileList } from '@/components/NewUpload';
import BigNumber from 'bignumber.js';

const BaseSet = () => {

    const [logo, setLogo] = useState([]);
    const [loading, setLoading] = useState(false);

    const getLogoUrl = () => {
        http('/admin/param/getParamValueByCode/SUPPLIER_LOGO', {}, 'POST').then(res => {
            setLogo(generateFileList(res.result));
        }).catch(err => message.error(err.message));
    };

    useEffect(() => { getLogoUrl(); }, []);

    const onSubmit = () => {
        if (!logo.length) return message.warn('请上传供应链LOGO');
        setLoading(true);
        http('/admin/param/batchUpdateParam', [{
            paramCode: 'SUPPLIER_LOGO',
            paramValue: logo[0].url
        }], 'POST').then(res => {
            setLoading(false);
            getLogoUrl();
            message.success(res.message);
        }).catch(err => {
            setLoading(false);
            message.error(err.message);
        });
    };

    return <div className={styles.baseContainer}>
        <div className={styles.baseInner}>
            <XTitle label='供应链logo设置' />
            <div style={{ margin: '20px 0 0 20px' }}>
                <CustomUpload
                    accept='.png, .jpg, .jpeg'
                    listType="picture-card"
                    wallMode="single"
                    fileList={logo}
                    qiniuUploadProps={{ uploadType: 'image' }}
                    beforeUpload={file => {
                        const types = ['image/png', 'image/jpg', 'image/jpeg'];
                        if (!types.includes(file.type)) {
                            return message.error('请上传jpg、jpeg、png格式的图片!');
                        }
                        if (new BigNumber(file.size || 0).dividedBy(2048).toNumber() < 3) {
                            return message.error('图片必须小于 3MB!');
                        }
                    }}
                    onChange={(info) => setLogo(info.fileList)}
                >
                    <div><Icon type='plus' style={{ fontSize: 32, color: '#999' }} /></div>
                </CustomUpload>
            </div>
            <div style={{ margin: '20px 0 0 20px' }}>
                上传LOGO图片:<br />
                主图大小不能超过3MB；格式为PNG、JPG；200*200 以<br />
                上图片上传后，供应链LOGO全部显示此图片
            </div>
        </div>
        <div className={styles.baseFooter}>
            <Button type='primary' loading={loading} onClick={onSubmit}>保存</Button>
        </div>
    </div>;
};
export default BaseSet;