import * as T from './actiontypes';

export const getEnumSelectSource = () => ({
    type: T.GET_ENUM_SELECTSOURCE
});
export const getEnumFlagSource = () => ({
    type: T.GET_ENUM_FLAGENUM
});
export const getEnumCustomerSource = () => ({
    type: T.GET_ENUM_OPENCUSTOMER
});