import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

const initList = {
    goodsId: 0,
    goodsName: '',
    goodsNo: '',
    skuList: [
        {
            salePrice: 0,
            settlePrice: 0,
            showRiceNum: 0,
            skuId: 0,
            skuNo: '',
            spec: ''
        }
    ]
};

/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getGoodsShowRices = (T) => createSaga(T('getGoodsShowRices'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/goods/xiumi/findXiumiSKUList', { ...action.payload }, 'POST');
                yield put({ type: T('originalData'), payload: result });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const goodsShowRices = (T) => createReducer((autostate) => {
    let newlist = [];
    return (state = autostate, action) => {
        switch (action.type) {
            case T('originalData'):
                return { ...action.payload.result };
            case T('updateList/GET'):
                newlist = state.skuList.map(item => {
                    return { ...item, showRiceNum: action.payload };
                });
                return { ...state, skuList: newlist };
            case T('singleUpdate/GET'):
                newlist = state.skuList.map(item => {
                    if (item.skuId === action.payload.skuId) {
                        return { ...item, showRiceNum: action.payload.showRiceNum };
                    } else {
                        return item;
                    }
                });
                return { ...state, skuList: newlist };
            default:
                return state;
        }
    };
}, { ...initList });

/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getGoodsShowBeans = (T) => createSaga(T('getGoodsShowBeans'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/goods/popularity/findGoodsPopularitySKUList', { ...action.payload }, 'POST');
                yield put({ type: T('originalData'), payload: result });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const goodsShowBeans = (T) => createReducer((autostate) => {
    let newlist = [];
    return (state = autostate, action) => {
        switch (action.type) {
            case T('originalData'):
                return { ...action.payload.result };
            case T('updateBeabList/GET'):
                newlist = state.skuList.map(item => {
                    return { ...item, popularityNum: action.payload };
                });
                return { ...state, skuList: newlist };
            case T('singleUpdateBean/GET'):
                newlist = state.skuList.map(item => {
                    if (item.skuId === action.payload.skuId) {
                        return { ...item, popularityNum: action.payload.popularityNum };
                    } else {
                        return item;
                    }
                });
                return { ...state, skuList: newlist };
            default:
                return state;
        }
    };
}, { ...initList });

const model = {
    namespace: 'productmanage',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getGoodsShowRices': T('getGoodsShowRices'),
            'getMarketSele': T('getMarketSele'),
            'updateList': T('updateList'),
            'singleUpdate': T('singleUpdate'),
            'getGoodsShowBeans': T('getGoodsShowBeans'),
            'updateBeabList': T('updateBeabList'),
            'singleUpdateBean': T('singleUpdateBean'),
            'getServiceTypeEnum': T('getServiceTypeEnum'),
            'getSettleTypeEnum': T('getSettleTypeEnum'),
        },
        actions: {
            'getGoodsShowRices': A('getGoodsShowRices'),
            'getMarketSele': A('getMarketSele'),
            'updateList': A('updateList'),
            'singleUpdate': A('singleUpdate'),
            'getGoodsShowBeans': A('getGoodsShowBeans'),
            'updateBeabList': A('updateBeabList'),
            'singleUpdateBean': A('singleUpdateBean'),
            'getServiceTypeEnum': A('getServiceTypeEnum'),
            'getSettleTypeEnum': A('getSettleTypeEnum'),
        },
        sagas: {
            'getGoodsShowRices': getGoodsShowRices(T), // 自定义saga写法，使用依赖注入
            'getMarketSele': S('getMarketSele', '/enum/FlagEnum'),
            'getGoodsShowBeans': getGoodsShowBeans(T), // 自定义saga写法，使用依赖注入
            'getServiceTypeEnum': S('getServiceTypeEnum', '/goods/service/listServiceItem'),
            'getSettleTypeEnum': S('getSettleTypeEnum', ''),
        },
        reducers: {
            'marketSele': R('getMarketSele', []),
            'goodsShowRices': goodsShowRices(T), // 自定义reducer写法，使用依赖注入
            'goodsShowBeans': goodsShowBeans(T), // 自定义reducer写法，使用依赖注入
            'serviceTypeEnum': R('getServiceTypeEnum', []),
            'settleTypeEnum': R('getSettleTypeEnum', []),
        }
    })
};
export default createModel(model);