/**
 * [设置营销服务费比例]
 * @author: Fu Xiaochun
 * @date: 2022-10-25 
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './modalStyle.module.scss';
import http from '@/assets/api/http';
import { Modal, Input, InputNumber, message } from 'antd';

function SetMarketingServiceFee(props){
    let _marketingRate = props.data.marketingServiceRate || {};
    let defaultRate = {
        referrerRate: _marketingRate.referrerRate || 0,
        serviceProviderRate: _marketingRate.serviceProviderRate || 0,
        regionRate: _marketingRate.regionRate || 0,
        centerRate: _marketingRate.centerRate || 0,
    };
    const [marketingServiceRate, setMarketingServiceRate] = useState({...defaultRate});

    const onSubmit = ()=>{
        console.log(marketingServiceRate);
        let sum = marketingServiceRate.referrerRate + marketingServiceRate.serviceProviderRate + marketingServiceRate.regionRate + marketingServiceRate.centerRate;
        console.log(sum);
        if(sum > 100){
            message.error('营销服务收益比例总和不能超过100%，请重新输入');
            return false;
        }
        props.onOk(marketingServiceRate);
    };

    const onClose = ()=>{
        props.onClose();
    };

    const onInputChange = (key, val)=>{
        setMarketingServiceRate({...marketingServiceRate, [key]: val || 0});
    };

    return <Modal title='设置营销服务收益' width={800} centered visible={true} bodyStyle={{ padding: '10px' }} onOk={onSubmit} onCancel={onClose} >
        <div className={styles.marketingServiceFeecontainer}>
            <div className={styles.descBox}>
                <div className={styles.title}>营销服务费</div>
                <p>营销服务费=可分配的服务费*营销服务费比例：</p>
                <p>推荐人营销服务费=营销服务费 * 推荐人“营销服务收益”收益比例</p>
                <p>服务商营销服务费=营销服务费 * 服务商“营销服务收益”收益比例</p>
                <p>区域公司营销服务费=营销服务费 * 区域公司“营销服务收益”收益比例</p>
                <p>运营中心营销服务费=营销服务费 * 运营中心“营销服务收益”收益比例</p>
                <div className={styles.notice}>
                    注意：推荐人“营销服务收益”收益比例（%） + 服务商“营销服务收益”收益比例（%）+区域公司营销服务收益”收益比例（%） + 运营中心“营销服务收益”收益比例（%）不能超营销服务费的100%
                </div>
            </div>
            <div className={styles.formBox}>
                <div className={styles.formItem}>
                    <div className={styles.label}>推荐人“营销服务收益”收益比例（%）</div>
                    <div className={styles.val}><InputNumber min={0} max={100} precision={2} value={marketingServiceRate.referrerRate} onChange={val=>{onInputChange('referrerRate', val);}}></InputNumber></div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.label}>服务商“营销服务收益”收益比例（%）</div>
                    <div className={styles.val}><InputNumber min={0} max={100} precision={2} value={marketingServiceRate.serviceProviderRate} onChange={val=>{onInputChange('serviceProviderRate', val);}}></InputNumber></div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.label}>区域公司“营销服务收益”收益比例（%）</div>
                    <div className={styles.val}><InputNumber min={0} max={100} precision={2} value={marketingServiceRate.regionRate} onChange={val=>{onInputChange('regionRate', val);}}></InputNumber></div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.label}>运营中心“营销服务收益”收益比例（%）</div>
                    <div className={styles.val}><InputNumber min={0} max={100} precision={2} value={marketingServiceRate.centerRate} onChange={val=>{onInputChange('centerRate', val);}}></InputNumber></div>
                </div>
            </div>
        </div>
    </Modal>;
}

SetMarketingServiceFee.propTypes = {
    data: PropTypes.object,
    onOk: PropTypes.func,
    onClose: PropTypes.func,
};

export default SetMarketingServiceFee;