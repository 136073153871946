import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import Lianlian from './lianlian';
import Huifu from './huifu';

const tabList = [
    { id: 'LL_PAY', label: '连连支付' },
    { id: 'ADA_PAY', label: '汇付入网' },
];
const Access = ({ history }) => {

    const [tabId, setTabId] = useState('LL_PAY');

    const cprops = { history };
    const components = {
        LL_PAY: <Lianlian {...cprops} />, ADA_PAY: <Huifu {...cprops} />
    };

    return <div className={styles.flexBoxContainer}>
        <XTabNav activedID={tabId} renderData={tabList} onChange={v => setTabId(v)} />
        {components[tabId]}
    </div>;
};
Access.propTypes = {
    history: PropTypes.object
};
export default Access;