/**
 * 民宿Tab页
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { XOKButton, showConfirm } from '@/components/xqxc_ui';
import TableData from './components/TableData';
import model from '@/reducers/houseresourcemanage/houseclassify/model';

class ApartmentClassify extends React.Component {

    static propTypes = {
        history: PropTypes.object,
        gethHuseClassifyList: PropTypes.func,
        houseclassifyList: PropTypes.array,
        addClassify: PropTypes.func,
        deleteAdd: PropTypes.func
    };

    static defaultProps = {
        houseclassifyList: []
    };

    state = {}

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageNum, pageSize } = this.state;
        this.searchCache = {
            categoryType: 'HOMESTAY',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.gethHuseClassifyList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 分页发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 发送请求
    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST')
            .then(() => {
                message.success(winTxt);
                this._searchHandle('useCache');
            }).catch((e) => {
                message.error(e.message);
            });
    }

    // 表格操作
    _tableAction = (id, activeType, { categoryName, platRate, agentRate, sortNo, type }) => {
        switch (activeType) {
            case 'update':
                this._sendReq('/goods/category/update', { id, categoryName, platRate, agentRate, categoryGrade: 1, sortNo, categoryType: 'HOMESTAY' }, '更新房源分类成功');
                break;
            case 'create':
                this._sendReq('/goods/category/save', { categoryName, platRate, agentRate, sortNo, categoryGrade: 1, categoryType: 'HOMESTAY' }, '新建房源分类成功');
                break;
            case 'delete':
                if (type === 'create') {
                    this.props.deleteAdd({ type: 'create' });
                } else {
                    showConfirm('您确定删除房型？', '', () => {
                        this._sendReq('/goods/category/del', { id, categoryGrade: 1, categoryType: 'HOMESTAY' }, '删除房型操作成功');
                    });
                }
                break;
            case 'sort':
                this._sendReq('/goods/category/update', { id, categoryName, platRate, agentRate, categoryGrade: 1, sortNo, categoryType: 'HOMESTAY' }, '排序操作成功');
                break;
        }
    }

    render() {
        const { houseclassifyList, addClassify } = this.props;
        return (
            <KeepAlive id='apartment' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div id='apartment' className={styles.flexBoxContainer}>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }} label='新建房型' onClick={() => {
                            addClassify(
                                {
                                    categoryName: '',
                                    platRate: 0,
                                    agentRate: 0,
                                    sortNo: 0,
                                    gmtModified: '',
                                    type: 'create'
                                });
                        }} />
                    </div>
                    <TableData renderData={houseclassifyList} paginationChange={this._paginationChange} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}

const mapStateToProps = (state) => ({
    houseclassifyList: state.houseclassify.houseclassifyList,
});
export default connect(mapStateToProps, { ...model.actions })(ApartmentClassify);