// 部门列表
export const GET_DEPT_LIST = 'STRUCTURE/GET_DEPT_LIST';
export const SET_DEPT_LIST = 'STRUCTURE/SET_DEPT_LIST';

// 部门详情
export const GET_DEPT_DETAIL = 'STRUCTURE/GET_DEPT_DETAIL';
export const SET_DEPT_DETAIL = 'STRUCTURE/SET_DEPT_DETAIL';

// 公司或运营中心列表
export const GET_COMPANY_LIST = 'STRUCTURE/GET_COMPANY_LIST';
export const SET_COMPANY_LIST = 'STRUCTURE/SET_COMPANY_LIST';

// 公司或运营中心详情
export const GET_COMPANY_DETAIL = 'STRUCTURE/GET_COMPANY_DETAIL';
export const SET_COMPANY_DETAIL = 'STRUCTURE/SET_COMPANY_DETAIL';