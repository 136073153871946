/**
 *  版本管理
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Table, message } from 'antd';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, showConfirm, } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getReleaseList, getReleaseTerminal, getReleaseState } from '@/reducers/systemmanage/release/actions';
import { SearchBox, SearchItem } from '@/components/Layout';

class Release extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            newVersion: '',//版本号
            status: {},//状态
            terminal: {}, // 所属终端
        };
    }

    componentDidMount() {
        const { getReleaseState, getReleaseTerminal } = this.props;
        getReleaseState();
        getReleaseTerminal();
    }

    //分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { newVersion, status, terminal, pageSize, pageNum } = this.state;
        console.log(status, terminal);
        this.searchCache = {
            newVersion, state: status.code, appType: terminal.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getReleaseList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _fullresetHandle = () => {
        this.setState({ newVersion: '', status: {}, terminal: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作功能回调
    _actionClickHandle = (id, type, value) => {
        const { updateContent } = this.props;
        switch (type) {
            case 'editor'://编辑  
                updateContent('editor', id);
                break;
            case 'delete':
                showConfirm('您是否要删除内容', '', () => {
                    http('/admin/appversionmanage/deleteByPrimaryKey/' + id, {}, 'POST').then(() => {
                        this._searchHandle('useCache');
                        message.success('删除成功。');
                    }).catch((error) => {
                        message.error(error.message);
                    });
                }, () => { });
                break;
            case 'start':
                showConfirm('您是否要启用该版本？', '', () => {
                    http('/admin/appversionmanage/updateAppVersionManageState', { id, state: '1' }, 'POST').then(() => {
                        this._searchHandle('useCache');
                        message.success('启用版本成功。');
                    }).catch((error) => {
                        message.error(error.message);
                    });
                }, () => { });
                break;
            case 'ban':
                showConfirm('您是否要禁用该版本？', '', () => {
                    http('/admin/appversionmanage/updateAppVersionManageState', { id, state: '0' }, 'POST').then(() => {
                        this._searchHandle('useCache');
                        message.success('禁用版本成功。');
                    }).catch((error) => {
                        message.error(error.message);
                    });
                }, () => { });
                break;
        }

    }

    // 创建
    _createRules = () => {
        this.props.updateContent('create');
    }

    render() {
        const { releaseList, releaseState, releaseTerminal } = this.props;
        const { newVersion, terminal, status } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='版本号'
                                placeholder='请输入'
                                value={newVersion}
                                bindThis={this}
                                bindName='newVersion'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='所属终端'
                                placeholder='请选择'
                                renderData={releaseTerminal}
                                dataIndex='value'
                                keyIndex='code'
                                value={terminal.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='terminal'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择'
                                renderData={releaseState}
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='status'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._fullresetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <XOKButton style={{ width: '140px', marginTop: 10 }} label='新增版本' onClick={this._createRules} />
                    <div className={styles.tableContainer}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={record => record.id}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(releaseList.dataList)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={releaseList.resultTotal}
                            pageSize={releaseList.pageSize}
                            pageNum={releaseList.pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                </div>
            </KeepAlive>
        );
    }
}

Release.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getReleaseList: PropTypes.func,// 通过Saga异步请求列表查询方法Action
    getReleaseTerminal: PropTypes.func,// 通过Saga异步请求列表查询方法Action
    getReleaseState: PropTypes.func,// 通过Saga异步请求列表查询方法Action
    releaseList: PropTypes.object,
    releaseTerminal: PropTypes.array,
    releaseState: PropTypes.array, // 分类状态下拉选项
};

const mapStateToProps = (state) => {
    return {
        releaseList: state.release.releaseList,
        releaseTerminal: state.release.releaseTerminal,
        releaseState: state.release.releaseState,
    };
};

export default connect(mapStateToProps, { getReleaseList, getReleaseTerminal, getReleaseState })(Release);
