/**
 * 凭证详情
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Alert, message, Modal } from 'antd';
import styles from './ExchangeModal.module.scss';
import { XInfoLabel } from '@/components/xqxc_ui';
import { regFenToYuan } from '@/util/money';

const Delivery = ({
    modeParams: {
        id
    },
    closeMode
}) => {

    const [loading, setLoading] = useState(false);
    const [canChange, setCanChange] = useState(true);
    const [exchangeInfo, setExchangeInfo] = useState({});

    //确定取消
    const onSumbit = () => {
        Modal.confirm({
            title: '确定要兑换吗？',
            onOk: () => {
                setLoading(true);
                http('/integralExchangeVoucher/exchange', { id: exchangeInfo.id, historyId: exchangeInfo.historyId }).then(res => {
                    setLoading(false);
                    closeMode(true);
                }).catch(res => {
                    message.error(res.message);
                    setLoading(false);
                });
            }
        });
    };

    useEffect(() => {
        http('/integralExchangeVoucher/exchange/details', { id, isRestExchange: true }).then(res => {
            if (res.code === 200) {
                setExchangeInfo(res.result);
                setCanChange(res.result.state);
            }
        });
    }, []);

    return (
        <Modal
            width={800}
            centered
            title='兑换凭证'
            visible={true}
            confirmLoading={loading}
            onOk={onSumbit}
            onCancel={() => closeMode(false)}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='立即兑换'
            okButtonProps={{
                disabled: !canChange
            }}
        >
            <div className={styles.container}>
                {!exchangeInfo.state && <Alert type='warning' style={{ marginBottom: 15 }} message={'不可兑换原因：' + exchangeInfo.reason} />}
                <div className={styles.stockInfo}>
                    <img src={exchangeInfo.sharesUrl} />
                    <div>
                        <XInfoLabel
                            style={{ marginBottom: 20 }}
                            label=''
                            value={exchangeInfo.sharesName}
                        />
                        <XInfoLabel
                            style={{ marginBottom: 20 }}
                            label='股票代码：'
                            value={exchangeInfo.sharesCode}
                        />
                        <XInfoLabel
                            style={{ marginBottom: 20 }}
                            label='生成凭证股价：'
                            value={`${regFenToYuan(exchangeInfo.currClosingPrice)}（港元）/股`}
                        />
                    </div>
                </div>
                <XInfoLabel
                    style={{ marginTop: 20, marginBottom: 10 }}
                    label='凭证单号：'
                    value={exchangeInfo.id}
                />
                <XInfoLabel
                    style={{ marginBottom: 10 }}
                    label='兑换股数：'
                    value={`${exchangeInfo.exchangeShares}股（股票溢价收益）`}
                />
                <XInfoLabel
                    style={{ marginBottom: 30 }}
                    label='生成凭证时间：'
                    value={exchangeInfo.gmtCreate}
                />
                <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
                    <XInfoLabel
                        label='兑换股价：'
                        value={!exchangeInfo.isPrice ? '暂无数据' : `${regFenToYuan(exchangeInfo.closingPrice)}（港元）`}
                    />
                    <div style={{ color: '#808080' }}>（注意：兑换股价为昨日收盘价）</div>
                </div>
                <XInfoLabel
                    style={{ marginBottom: 10 }}
                    label='RMB与港元兑换比例：'
                    value={exchangeInfo.rmbRatioHkd == 0 ? '暂无数据' : (exchangeInfo.rmbRatioHkd / 100 + ':1')}
                />
                <div style={{ marginBottom: 10 }}>
                    <XInfoLabel
                        label='手续费：'
                        value={!exchangeInfo.procedureFee ? '暂无数据' : `${regFenToYuan(exchangeInfo.procedureFee)}（元）`}
                    />
                    <div style={{ color: '#808080', marginLeft: 15 }}>{exchangeInfo.procedureRatioText}</div>
                </div>
                <div style={{ marginBottom: 10 }}>
                    <XInfoLabel
                        label='预计收益：'
                        value={exchangeInfo.rmbRatioHkd == 0 || !exchangeInfo.isPrice ? '暂无数据' : `${regFenToYuan(exchangeInfo.premiumPrice)}（元）`}
                    />
                    <div style={{ color: '#808080', marginLeft: 15 }}>{exchangeInfo.premiumText}</div>
                </div>
            </div>
        </Modal>
    );
};
Delivery.propTypes = {
    modeParams: PropTypes.object.isRequired,
    closeMode: PropTypes.func.isRequired
};
export default Delivery;