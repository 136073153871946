import { createModel } from '@/store/tools';

const model = {
    namespace: 'showstarlight',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getReport': T('getReport'),
            'getSummary': T('getSummary'),
        },
        actions: {
            'getReport': A('getReport'),
            'getSummary': A('getSummary'),
        },
        sagas: {
            'getReport': S('getReport', '/report/starlightReport'),
            'getSummary': S('getSummary', '/report/starlightSummaryReport'),
        },
        reducers: {
            'getReport': R('getReport', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getSummary': R('getSummary', {}),
        }
    })
};
export default createModel(model);