/*
*【商品信息总况】主文件
*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import SaleNum from './salenum';
import PayAmount from './payamount';
import Show from './show';
import Marketing from './marketing';
import model from '@/reducers/statistical/goodstotalcase/model';

const Main = (props) => {

    const { history,
        getStatisticsBrands, statisticsBrands,
        getStatisticsMerchants, statisticsMerchants } = props;

    const [tab] = useState([
        { id: 1, label: 'TOP50销售数量' },
        { id: 2, label: 'TOP50支付金额' },
        { id: 3, label: 'TOP50秀场' },
        { id: 4, label: 'TOP50营销' }
    ]);
    const [curTabID, setCurTabID] = useState(1);

    useEffect(() => {
        getStatisticsBrands({ isApply: 0 });
        getStatisticsMerchants();
    }, []);

    // Tab导航切换操作回调
    const _tabChangeHandle = (tabID) => {
        setCurTabID(tabID);
    };
    // 渲染相应Tab导航下的内容
    const _renderPage = () => {
        switch (curTabID) {
            case 1:
                return <SaleNum history={history} brands={statisticsBrands} merchants={statisticsMerchants} />;
            case 2:
                return <PayAmount history={history} brands={statisticsBrands} merchants={statisticsMerchants} />;
            case 3:
                return <Show history={history} brands={statisticsBrands} merchants={statisticsMerchants} />;
            case 4:
                return <Marketing history={history} brands={statisticsBrands} merchants={statisticsMerchants} />;
            default:
                return <SaleNum history={history} brands={statisticsBrands} merchants={statisticsMerchants} />;
        }
    };

    return <div className={styles.flexBoxContainer}>
        <XTabNav renderData={tab} onChange={_tabChangeHandle} activedID={curTabID} />
        <div className={styles.flexBoxContainer} style={{ marginTop: '10px' }}>
            {_renderPage()}
        </div>
    </div>;
};

Main.propTypes = {
    history: PropTypes.object.isRequired,//路由对象
    getStatisticsBrands: PropTypes.func,
    statisticsBrands: PropTypes.array,
    getStatisticsMerchants: PropTypes.func,
    statisticsMerchants: PropTypes.array
};

const mapStateToProps = (state) => ({
    statisticsBrands: state.goodstotalcase.statisticsBrands,
    statisticsMerchants: state.goodstotalcase.statisticsMerchants.merchantIdAndNames || []
});

export default connect(mapStateToProps, { ...model.actions })(Main);