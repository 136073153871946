/**
 * 酒店订单设置
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, InputNumber, message, Empty } from 'antd';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import model from '@/reducers/systemmanage/hotelOrder/model';
import http from '@/assets/api/http';

class OrderDetail extends React.Component {
    state = {
        hotelList: [
            { typeCall: 'HOTEL_SETTINGS_PUSH_RANGE' },//酒店需求单推送范围
            { typeCall: 'HOTEL_SETTINGS_PUSH_TIME_LIMIT' },//酒店需求单推送时间
            { typeCall: 'HOTEL_SETTINGS_PUSH_RANGE_APPEND' },//酒店需求单推送范围增加
            { typeCall: 'HOTEL_SETTINGS_PUSH_ADD_MONEY' },//酒店需求单推送加价

            { typeCall: 'HOTEL_SETTINGS_PUSH_CANCEL_TIME' },//酒店需求单推取消时间
            { typeCall: 'HOTEL_SETTINGS_ORDER_CANCEL_PENDING' },//未支付订单自动取消
            { typeCall: 'HOTEL_SETTINGS_ORDER_CANCEL_AFFIRM' },//未确认订单自动取消
            { typeCall: 'HOTEL_SETTINGS_RESERVE_MAX' },//用户一个订单最多可预订房间
            { typeCall: 'HOTEL_SETTINGS_CONFIRM_TIME' },//用户预定后商家未确认自动取消
        ],//提交更新数据
    }

    componentDidMount() {
        this.props.getHotelList({ type: 'HOTEL_ORDER_SET' });
    }

    //提交更新数据
    submitData = () => {
        const { hotelList } = this.state;
        let dataHotel = [];
        for (let i = 0; i < hotelList.length; i++) {
            if (hotelList[i].val === null) return message.warning(hotelList[i].text);
            if (hotelList[i].val !== undefined) {
                dataHotel.push({ paramCode: hotelList[i].paramCode, paramValue: hotelList[i].val });
            }
        }
        if (dataHotel.length === 0) return message.warning('暂无修改数据。');
        http('/admin/param/batchUpdateParam', dataHotel, 'POST').then(() => {
            message.success('保存成功。');
        }).catch((e = {}) => {
            message.error(e.message);
        });
    }

    //获取修改数据
    hotelListHandle = (val, paramCode, text) => {
        const { hotelList } = this.state;
        const hotel = hotelList.map((item) => {
            if (item.typeCall == paramCode) {
                return { ...item, paramCode, val, text };
            }
            return item;
        });
        this.setState({ hotelList: hotel });
    }

    render() {
        const { hotelLists } = this.props;
        const {
            HOTEL_SETTINGS_PUSH_RANGE = {},//酒店需求单推送范围
            HOTEL_SETTINGS_PUSH_TIME_LIMIT = {},//酒店需求单推送时间
            HOTEL_SETTINGS_PUSH_RANGE_APPEND = {},//酒店需求单推送范围增加
            HOTEL_SETTINGS_PUSH_ADD_MONEY = {},//酒店需求单推送加价
            HOTEL_SETTINGS_PUSH_CANCEL_TIME = {},//酒店需求单推取消时间
            HOTEL_SETTINGS_ORDER_CANCEL_PENDING = {},//未支付订单自动取消
            HOTEL_SETTINGS_ORDER_CANCEL_AFFIRM = {},//未确认订单自动取消
            HOTEL_SETTINGS_RESERVE_MAX = {},//用户一个订单最多可预订房间
            HOTEL_SETTINGS_CONFIRM_TIME= {},//用户预定后商家未确认自动取消
        } = this.props.hotelLists;

        return Object.keys(hotelLists).length > 0 ?
            (<div className={styles.container}>
                <ul className={styles.lists}>
                    <li>
                        <span>需求订单发送后向附近</span>
                        <InputNumber
                            style={{ margin: '0 10px' }}
                            min={0.1}
                            max={100}
                            precision={1}
                            step={0.1}
                            placeholder='请输入'
                            defaultValue={HOTEL_SETTINGS_PUSH_RANGE.paramValue}
                            onChange={(val) => {
                                const { paramCode } = HOTEL_SETTINGS_PUSH_RANGE;
                                this.hotelListHandle(val, paramCode, '请补全订单推送范围。');
                            }}

                        />
                        <span>km范围内酒店推送，</span>
                        <InputNumber
                            style={{ margin: '0 10px' }}
                            min={1}
                            max={3600}
                            precision={0}
                            defaultValue={HOTEL_SETTINGS_PUSH_TIME_LIMIT.paramValue}
                            placeholder='请输入'
                            onChange={(val) => {
                                const { paramCode } = HOTEL_SETTINGS_PUSH_TIME_LIMIT;
                                this.hotelListHandle(val, paramCode, '请补全订单推送时间。');
                            }}
                        />
                        <span>分钟内没接单推送范围增加</span>
                        <InputNumber
                            style={{ margin: '0 10px' }}
                            min={0.1}
                            max={100}
                            precision={1}
                            step={0.1}
                            defaultValue={HOTEL_SETTINGS_PUSH_RANGE_APPEND.paramValue}
                            placeholder='请输入'
                            onChange={(val) => {
                                const { paramCode } = HOTEL_SETTINGS_PUSH_RANGE_APPEND;
                                this.hotelListHandle(val, paramCode, '请补全订单推送范围增加。');
                            }}
                        />
                        <span>km（在原推送范围内增加），</span>
                        <InputNumber
                            style={{ margin: '0 10px' }}
                            min={1}
                            max={3600}
                            precision={0}
                            defaultValue={HOTEL_SETTINGS_PUSH_ADD_MONEY.paramValue}
                            placeholder='请输入'
                            onChange={(val) => {
                                const { paramCode } = HOTEL_SETTINGS_PUSH_ADD_MONEY;
                                this.hotelListHandle(val, paramCode, '请补全订单推送加价。');
                            }}
                        />
                        <span>分钟内没接单提示用户是否加价；</span>
                    </li>
                    <li>
                        <span>没有酒店接单需求单</span>
                        <InputNumber
                            style={{ margin: '0 10px' }}
                            min={1}
                            max={3600}
                            precision={0}
                            defaultValue={HOTEL_SETTINGS_PUSH_CANCEL_TIME.paramValue}
                            placeholder='请输入'
                            onChange={(val) => {
                                const { paramCode } = HOTEL_SETTINGS_PUSH_CANCEL_TIME;
                                this.hotelListHandle(val, paramCode, '请补全订单推送取消时间。');
                            }}
                        />
                        <span>分钟内自动取消；</span>
                    </li>
                    <li>
                        <span>订单提交后用户</span>
                        <InputNumber
                            style={{ margin: '0 10px' }}
                            min={1}
                            max={3600}
                            precision={0}
                            defaultValue={HOTEL_SETTINGS_ORDER_CANCEL_PENDING.paramValue}
                            placeholder='请输入'
                            onChange={(val) => {
                                const { paramCode } = HOTEL_SETTINGS_ORDER_CANCEL_PENDING;
                                this.hotelListHandle(val, paramCode, '请补全未支付订单自动取消时间。');
                            }}
                        />
                        <span>分钟内未支付订单自动取消；</span>
                    </li>
                    <li>
                        <span>酒店接单后</span>
                        <InputNumber
                            style={{ margin: '0 10px' }}
                            min={1}
                            max={3600}
                            precision={0}
                            defaultValue={HOTEL_SETTINGS_ORDER_CANCEL_AFFIRM.paramValue}
                            placeholder='请输入'
                            onChange={(val) => {
                                const { paramCode } = HOTEL_SETTINGS_ORDER_CANCEL_AFFIRM;
                                this.hotelListHandle(val, paramCode, '请补全未确认订单自动取消时间。');
                            }}
                        />
                        <span>分钟内用户未确认订单自动取消；</span>
                    </li>
                    <li>
                        <span>用户一个订单最多可预订</span>
                        <InputNumber
                            style={{ margin: '0 10px' }}
                            min={1}
                            max={30}
                            precision={0}
                            defaultValue={HOTEL_SETTINGS_RESERVE_MAX.paramValue}
                            placeholder='请输入'
                            onChange={(val) => {
                                const { paramCode } = HOTEL_SETTINGS_RESERVE_MAX;
                                this.hotelListHandle(val, paramCode, '请补全订单最多可预订房间数。');
                            }}
                        />
                        <span>间房（1~30间内）；</span>
                    </li>
                    <li>
                        <span>用户预订后</span>
                        <InputNumber
                            style={{ margin: '0 10px' }}
                            min={1}
                            max={3600}
                            precision={0}
                            defaultValue={HOTEL_SETTINGS_CONFIRM_TIME.paramValue}
                            placeholder='请输入'
                            onChange={(val) => {
                                const { paramCode } = HOTEL_SETTINGS_CONFIRM_TIME;
                                this.hotelListHandle(val, paramCode, '请补全用户预订后多少分钟内商家未确认订单自动取消');
                            }}
                        />
                        <span>分钟内商家未确认订单自动取消</span>
                    </li>
                </ul>
                <div className={styles.line}></div>
                <Button style={{ width: '90px', marginTop: 10, display: 'inline-block' }} onClick={this.submitData} type='primary'>保存</Button>
            </div>) : <div className={styles.empty}><Empty /></div>;
    }
}

OrderDetail.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,

    hotelLists: PropTypes.object,
    getHotelList: PropTypes.func,
};

const mapStateToProps = (state) => ({
    hotelLists: state.HotelOrderSys.hotelList,//内容数据
});

export default connect(mapStateToProps, { ...model.actions })(OrderDetail);