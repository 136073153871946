/**
 *  【商品分类】入口页
 * */ 
import React,{Component} from 'react';
import PropTypes from 'prop-types'; 
import Main from './pages/main';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class Productcategory extends Component {

    componentDidMount(){
        this.props.keepSecondNavBreadcrumb();
    }

    render(){
        return(
            <Main history = {this.props.history} /> 
        );
    }
}

Productcategory.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { keepSecondNavBreadcrumb })(Productcategory);