import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, tooltipClick } from '@/components/TableCell';

const TableContent = ({ tableAction, renderData, paginationChange }) => {

    const _columns = () => [
        {
            title: '手机号码',
            dataIndex: 'mobilePhone',
            key: 'mobilePhone',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: (text, record) => tooltipClick(text, () => {
                tableAction(record.id, 'detail');
            })
        },
        {
            title: '姓名',
            dataIndex: 'realName',
            key: 'realName',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '服务商类型',
            dataIndex: 'subjectTypeName',
            key: 'subjectTypeName',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '转账账号',
            dataIndex: 'bankNo',
            key: 'bankNo',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请日期',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'statusName',
            key: 'statusName',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作人',
            dataIndex: 'auditName',
            key: 'auditName',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '10%',
            render: (text, record) => _tableAction(text, record)
        }
    ];

    //实名认证审核操作在阿里云审核，因此先注释，请勿删除文件
    const _tableAction = (id, record) => {
        const { status } = record;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, 'detail')}>查看详情</div>
                {
                    status === 'AUDIT' && <div className={styles.item} onClick={() => tableAction(id, 'audit')}>审核</div>
                }
            </div>
        );
    };

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                columns={_columns()}
                dataSource={renderData.dataList}
                rowKey={record => record.id}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange} />
        </div>
    );

};

TableContent.propTypes = {
    renderData: PropTypes.object, // 渲染数据源
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func,//分页操作
};
TableContent.defaultProps = {
    renderData: { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 }
};
export default TableContent;