import React, { Component } from 'react';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import TableContent from './components/TableContent';
import LogisticsModal from './modal/LogisticsModal';
import { XOKButton, XCancelButton, XInput, XDatePicker, XTableAdapter } from '@/components/xqxc_ui';
import model from '@/reducers/storehousefinancialmanage/datacenter/model';
import { SearchBox, SearchItem } from '@/components/Layout';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class TabContent extends Component {

    static propTypes = {
        history: PropTypes.object,
        keepSecondNavBreadcrumb: PropTypes.func,
        getDataFromTab5: PropTypes.func,
        dataFromTab5: PropTypes.object
    }

    static defaultProps = {
        dataFromTab5: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
    }

    state = {
        goodsNo: '', // 货号
        orderId: '', // 订单号
        gmtBeginTm: undefined, // 创建时间
        gmtEndTm: undefined, // 结束时间
        scrollY: '',
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { goodsNo, orderId, gmtBeginTm, gmtEndTm, pageNum, pageSize } = this.state;
        const query = {
            goodsNo,
            orderId,
            gmtBeginTm: gmtBeginTm ? gmtBeginTm : undefined,
            gmtEndTm: gmtEndTm ? gmtEndTm : undefined,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getDataFromTab5(query);
        KeepAlive.saveCache(query);
    }
    tableAction = (id, type, record) => {
        const { history } = this.props;
        switch (type) {
            // 查看详情
            case 'info':
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: {
                        pathname: `/home/ordermanage/platorderdetail/orderdetail/${record.sourceOrderId}/${record.source}`,
                    }
                });
                history.push({
                    pathname: `/home/ordermanage/platorderdetail/orderdetail/${record.sourceOrderId}/${record.source}`,
                });
                break;
            // 查看物流
            case 'logistics':
                this.setState({ renderMode: 'logistics', orderID: record.orderId });
                break;
            // 操作记录
            case 'history':
                addOneBreadcrumbPath({
                    title: '操作记录',
                    path: {
                        pathname: `/home/ordermanage/platorderdetail/orderhistory/${record.sourceOrderId}/${record.source}`,
                    }
                });
                history.push({
                    pathname: `/home/ordermanage/platorderdetail/orderhistory/${record.sourceOrderId}/${record.source}`,
                });
                break;
        }
    }
    // 打开查看物流弹框
    _renderMode = () => {
        const { renderMode } = this.state;
        switch (renderMode) {
            case 'logistics':
                return <LogisticsModal visible={true} data={this.state.orderID} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭查看物流弹框
    _closeMode = () => {
        this.setState({ renderMode: '' });
    }
    // 重置触发
    _resetHandle = () => {
        this.setState({ goodsNo: '', orderId: '', gmtBeginTm: undefined, gmtEndTm: undefined }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { goodsNo, orderId, gmtBeginTm, gmtEndTm, scrollY } = this.state;
        return (
            <KeepAlive id='dataCenterTab4' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='订单编号'
                                placeholder='请输入订单号'
                                value={orderId}
                                bindThis={this}
                                bindName='orderId'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='货号'
                                placeholder='请输入货号'
                                value={goodsNo}
                                bindThis={this}
                                bindName='goodsNo'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XDatePicker
                                label='创建时间'
                                value={gmtBeginTm}
                                bindThis={this}
                                bindName='gmtBeginTm'
                                isFormat
                            />
                            <XDatePicker
                                style={{ marginRight: 20 }}
                                label=''
                                value={gmtEndTm}
                                bindThis={this}
                                bindName='gmtEndTm'
                                isFormat
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <XTableAdapter render={scrollY => this.setState({ scrollY })}>
                        <TableContent
                            scrollY={scrollY}
                            renderData={this.props.dataFromTab5}
                            paginationChange={this._paginationChange}
                            tableAction={this.tableAction}
                        />
                    </XTableAdapter>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}
TabContent.propTypes = {
    history: PropTypes.object,
};
export default connect(state => ({ ...state.dataCenter }), { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(TabContent);