/**
 * 音乐分类
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { Table, Switch, Icon, Input, message } from 'antd';
import { XSelect, XOKButton, XCancelButton, XInput, XPagination, showConfirm } from '@/components/xqxc_ui';
import TypeModal from '../../modal/TypeModal';
import { getMusicTypeTableSource } from '@/reducers/showmanage/music/actions';
import { getEnumSelectSource } from '@/reducers/common/enum/actions';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { ImgCell } from '@/components/TableCell';
import { SearchBox, SearchItem } from '@/components/Layout';

const Mode = {
    CREAT: 'CREAT',
    EDITE: 'EDITE',
};

const columns = (TableAction, actionClickHandle) => [
    {
        title: '分类ID',
        dataIndex: 'id',
        key: 'id',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '分类名称',
        dataIndex: 'className',
        key: 'className ',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '分类图标',
        dataIndex: 'img',
        key: 'img',
        align: 'center',
        width: '10%',
        render: ImgCell
    },
    {
        title: '排序',
        dataIndex: 'sortNo',
        key: 'sortNo',
        width: '10%',
        align: 'center',
        render: (text, item) => (SortCell(text, item, actionClickHandle))
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle))
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: '10%',
        render: (id, item) => TableAction(item.id, actionClickHandle, item)
    }
];

const TableAction = (id, actionClickHandle, record) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'edit', record)}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'delete')}>删除</div>
        </div>
    );
};

// 表格项内容为文本
const TableCell = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text}
        </div>
    );
};

// 表格项内容为可编辑
const SortCell = (value, item, actionClickHandle) => {
    const label = React.createRef();
    const box = React.createRef();
    const input = React.createRef();

    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <div className={styles.sort_box}>
                <div className={styles.label} ref={label}>{value}</div>
                <div className={styles.input} ref={box}>
                    <Input
                        key={item.id}
                        style={{ width: '50px' }}
                        ref={input}
                        defaultValue={value}
                        onBlur={(e) => {
                            box.current.style.display = 'none';
                            label.current.style.display = 'block';
                            if (value != e.target.value) {
                                actionClickHandle(item.id, 'sort', e.target.value, item);
                            }
                        }}
                    />
                </div>
                <div
                    className={styles.icon}
                    onClick={() => {
                        label.current.style.display = 'none';
                        box.current.style.display = 'block';
                        input.current.focus();
                    }}
                >
                    <Icon type="edit" style={{ fontSize: '18px' }} />
                </div>
            </div>
        </div>
    );
};

// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
    let checked = sw == 0 ? false : true;
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                key={item.id + item['state']}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                defaultChecked={checked}
                onChange={(value) => actionClickHandle(item.id, 'switch', value)}
            />
        </div>
    );
};


class MusicType extends React.PureComponent {

    state = {
        state: {},
        className: undefined,
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
    }

    componentDidMount() {
        this.props.getEnumSelectSource();
    }

    _createMusicType = () => {
        this.setState({ currentOpenMode: Mode.CREAT });
    }

    getCache = () => ({
        state: this.state.state.code,
        className: this.state.className
    });

    _searchHandle = (useCache) => {
        const { pageSize, pageNum, state, className } = this.state;
        this.searchCache = {
            ...this.getCache(),
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getMusicTypeTableSource(this.searchCache); // 发起请求
        KeepAlive.saveCache({ state, className, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            state: {},
            className: undefined
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格操作
    _tableAction = (id, type, value, item) => {
        switch (type) {
            case 'edit':
                this.setState({
                    currentOpenMode: Mode.EDITE,
                    openModeParam: value
                });
                break;
            case 'delete':
                showConfirm('您确定删除该记录？', '', () => {
                    http('/show/showClass/deleteMusicClass/' + id, {}, 'POST').then(response => {
                        this._searchHandle('useCache');
                        message.success('删除成功');
                    }).catch(e => message.error('删除异常'));
                });
                break;
            case 'switch':
                http('/show/showClass/updateShowClassState', { id, state: value === true ? 1 : 0 }, 'POST')
                    .then(res => this._searchHandle('useCache'))
                    .catch(res => message.error('操作异常'));
                break;
            case 'sort':
                http('/show/showClass/updateShowClassSortNo', { id, sortNo: value, classTypeCode: item.classTypeCode }, 'POST')
                    .then(res => this._searchHandle('useCache'))
                    .catch(res => message.error('操作异常'));
                break;
        }
    }

    // 操作表格
    _actionHandle = (id, type, value, item) => {
        this._tableAction(id, type, value, item);
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.CREAT:
                return <TypeModal title='创建音乐分类' visible={true} data={{ state: 1 }} closeMode={this._closeMode} />;
            case Mode.EDITE:
                return <TypeModal title='编辑音乐分类' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    _closeMode = (reload) => {
        this.setState({ currentOpenMode: '' });
        reload && this._searchHandle();
    }

    render() {
        const { dataList, pageNum, pageSize, resultTotal } = this.props.musicTypeTable;
        const { state, className } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XSelect
                                selectStyle={{ width: '200px' }}
                                label='分类状态'
                                placeholder='请选择分类状态'
                                renderData={this.props.enumSelectSource}
                                dataIndex='value'
                                keyIndex='code'
                                bindName='state'
                                bindThis={this}
                                value={state.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                style={{ marginLeft: '20px' }}
                                inputStyle={{ width: '200px' }}
                                label='分类名称'
                                placeholder='请输入分类名称'
                                value={className}
                                bindName='className'
                                bindThis={this}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '120px' }} label='创建音乐分类' onClick={this._createMusicType} />
                    </div>
                    <div className={styles.tableContainer}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            columns={columns(TableAction, this._tableAction)}
                            rowKey={(record) => record.id}
                            dataSource={dataList}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

MusicType.propTypes = {
    getEnumSelectSource: PropTypes.func,
    enumSelectSource: PropTypes.array,
    musicTypeTable: PropTypes.object,
    getMusicTypeTableSource: PropTypes.func,
};

const mapStateToProps = (state) => ({
    musicTypeTable: state.music.musicTypeTable,
    enumSelectSource: state.common_enum.enumSelectSource,
});

export default connect(mapStateToProps, {
    getMusicTypeTableSource,
    getEnumSelectSource
})(MusicType);