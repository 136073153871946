import * as T from './actiontypes';

/**
 * 获取部门列表
 */
export const getDeptList = (value = {}) => ({
    type: T.GET_DEPT_LIST,
    payload: value
});

/**
 * 获取部门详情
 */
export const getDeptDetail = (value = {}) => ({
    type: T.GET_DEPT_DETAIL,
    payload: value
});

/**
 * 获取公司、运营中心列表
 */
export const getCompanyList = (value = {}) => ({
    type: T.GET_COMPANY_LIST,
    payload: value
});

/**
 * 获取公司、运营中心详情
 */
export const getCompanyDetail = (value = {}) => ({
    type: T.GET_COMPANY_DETAIL,
    payload: value
});


