import * as T from './actiontypes';

/**
 * 获取服务商审核状态
 */
export const getServiceState = (value = {}) => ({
    type: T.GET_SERVICE_STATE,
    payload: value
});

/**
 * 获取区域公司下拉列
 */
export const getAreaCompany = (value = {}) => ({
    type: T.GET_AREA_COMPANY,
    payload: value
});

/**
 * 获取关联状态下拉列
 */
export const getAssoTypeList = (value = {}) => ({
    type: T.GET_ASSOTYPE_LIST,
    payload: value
});

/**
 * 获取服务商列表
 */
export const getServiceList = (value = {}) => ({
    type: T.GET_SERVICE_LIST,
    payload: value
});

/**
 * 获取服务商详情
 */
export const getServiceDetail = (value = {}) => ({
    type: T.GET_SERVICE_DETAIL,
    payload: value
});

/**
 * 获取服务商详情
 */
export const cleanServiceDetail = () => ({
    type: T.CLEAN_SERVICE_DETAIL
});

/**
 * 获取推荐会员列表
 */
export const getSAccountList = (value = {}) => ({
    type: T.GET_SACCOUNT_LIST,
    payload: value
});

/**
 * 获取心选推荐列表
 */
export const getSRecommendList = (value = {}) => ({
    type: T.GET_SRECOMMEND_LIST,
    payload: value
});

/**
 * 获取推荐商户列表
 */
export const getSMerchantList = (value = {}) => ({
    type: T.GET_SMERCHANT_LIST,
    payload: value
});

/**
 * 获取分销商品列表
 */
export const getGoodsList = (value = {}) => ({
    type: T.GET_GOODS_LIST,
    payload: value
});

/**
 * 获取服务商推荐列表
 */
export const getRecommendedList = (value = {}) => ({
    type: T.GET_RECOMMENDED_LIST,
    payload: value
});

/**
 * 获取服务商收益汇总
 */
export const getServiceIcomeTotal = (value = {}) => ({
    type: T.GET_SERVICE_ICOME_TOTAL,
    payload: value
});

/**
 * 获取服务商收益明细列表
 */
export const getServiceIcomeTable = (value = {}) => ({
    type: T.GET_SERVICE_ICOME_TABLE,
    payload: value
});

/**
 * 获取收支明细中费用名称下拉列
 */
export const getCostNameSelect = (value = {}) => ({
    type: T.GET_COST_NAME,
    payload: value
});

/**
 * 获取能量值信息
 */
export const getJibeanInfo = (value = {}) => ({
    type: T.GET_JIBEAN_INFO,
    payload: value
});

/**
 * 获取能量值列表
 */
export const getJibeanList = (value = {}) => ({
    type: T.GET_JIBEAN_LIST,
    payload: value
});

/**
 * 获取贡献值信息
 */
export const getXiubeiInfo = (value = {}) => ({
    type: T.GET_XIUBEI_INFO,
    payload: value
});

/**
 * 获取贡献值列表
 */
export const getXiubeiList = (value = {}) => ({
    type: T.GET_XIUBEI_LIST,
    payload: value
});

/**
 * 获取服务商推荐赠送贡献值信息列表
 */
export const getServiceXiubeiCodeList = (value = {}) => ({
    type: T.GET_SERVICE_XIUBEI_CODELIST,
    payload: value
});

/**
 * 推荐人来源枚举
 */
export const getRecommentTypeEnum = (value = {}) => ({
    type: T.GET_RECOMMENT_TYPE_ENUM,
    payload: value
});

/**
 * 账号状态枚举
 */
export const getStateEnum = (value = {}) => ({
    type: T.GET_STATE_ENUM,
    payload: value
});
/**
 * 获取推荐小B列表
 */
export const getSalersList = (value = {}) => ({
    type: T.GET_SALERS_LIST,
    payload: value
});
/**
 * 获取特权值明细
 */
 export const getFranchiseList = (value = {}) => ({
    type: T.GET_FRANCHISE_LIST,
    payload: value
});

