/**
 * 财务管理
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as ACCOUNT from './actionTypes';

// 会员
export const getAccountList = function* () {
    yield takeEvery(ACCOUNT.GET_ACCOUNT_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/account/queryUserProfit', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: ACCOUNT.SET_ACCOUNT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 服务商
export const getServiceList = function* () {
    yield takeEvery(ACCOUNT.GET_SERVICE_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/account/queryServicersProfit', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: ACCOUNT.SET_SERVICE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 商户
export const getMerchantList = function* () {
    yield takeEvery(ACCOUNT.GET_MERCHANT_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/account/queryMerchantProfit', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: ACCOUNT.SET_MERCHANT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 区域公司
export const getRegionalCompanyList = function* () {
    yield takeEvery(ACCOUNT.GET_COMPANY_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/account/queryRegionCompanyProfit', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: ACCOUNT.SET_COMPANY_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 运营中心
export const getOperationCenterList = function* () {
    yield takeEvery(ACCOUNT.GET_OPERATIONCENTER_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/account/queryOPCenterProfit', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: ACCOUNT.SET_OPERATIONCENTER_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 平台
export const getPlatFormList = function* () {
    yield takeEvery(ACCOUNT.GET_PLATFORM_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/account/queryPlatformProfit', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: ACCOUNT.SET_PLATFORM_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 结算账户费用明细
export const getCostDetailList = function* () {
    yield takeEvery(ACCOUNT.GET_COSTDETAIL_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationSource/listCostDetail', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: ACCOUNT.SET_COSTDETAIL_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 订单交易明细费用明细
export const getOrderCostDetailList = function* () {
    yield takeEvery(ACCOUNT.GET_ORDERCOSTDETAIL_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationSource/listOrderCostDetail', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: ACCOUNT.SET_ORDERCOSTDETAIL_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 区域，运营中心账户费用明细
export const getCostDetailLists = function* () {
    yield takeEvery(ACCOUNT.GET_COSTDETAIL_LISTS, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationSourceItem/listReconciliationSourceItem', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: ACCOUNT.SET_COSTDETAIL_LISTS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 服务订单明细
export const getServiceOrderList = function* () {
    yield takeEvery(ACCOUNT.GET_SERVICEORDER_LIST, function* requestData(action) {
        try {
            let result = yield http('/earnings/cost/detail', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: ACCOUNT.SET_SERVICEORDER_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 费用名称
export const getCostNameList = function* () {
    yield takeEvery(ACCOUNT.GET_COSTNAME_LIST, function* requestData(action) {
        try {
            let result = yield http('/costSetting/list', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: ACCOUNT.SET_COSTNAME_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 服务订单明细
export const getServiceManageFeeList = function* () {
    yield takeEvery(ACCOUNT.GET_SERVICEMANAGEFEE_LIST, function* requestData(action) {
        try {
            let result = yield http('/earnings/cost/listEarningsCostItemDetail', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: ACCOUNT.SET_SERVICEMANAGEFEE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 区域公司--激活状态
export const getActiveCompanyState = function* () {
    yield takeEvery(ACCOUNT.GET_COMPANY_ACTIVE, function* requestData(action) {
        try {
            let result = yield http('/enum/ActivatedStateEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: ACCOUNT.SET_COMPANY_ACTIVE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};