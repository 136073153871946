/**
 * 重新注册信息提示框
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Icon, message } from 'antd';
import http from '@/assets/api/http';
import { regFenToYuan } from '@/util/money';

class RegisterInfo extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        type: -1, //-1余额查询中 0允许重新注册；1有余额不符合重新注册条件 2余额查询失败
        allAmount: 0, // 账户余额
    }

    componentDidMount() {
        http('/company/info/yums/queryBalance').then((response) => {
            if (response.code === 200) {
                let { allAmount } = response.result;
                if (allAmount == 0) {
                    this.setState({ type: 0 });
                } else {
                    this.setState({ type: 1, allAmount: regFenToYuan(allAmount) });
                }
            }
        }).catch(() => {
            this.setState({ type: 2 });
        });
    }

    _handleOk = () => {
        const { type } = this.state;
        if (type == 0) {
            http('/company/info/yums/lockMember').then((response) => {
                if (response.code === 200) {
                    this.props.history.push({ pathname: '/', state: { pageInfo: '' } });
                }
            }).catch((e) => {
                message.error(e.message);
            });
        } else {
            this.setState({ visible: false });
            this.props.close();
        }
    }

    _handleCancel = () => {
        this.setState({ visible: false });
        this.props.close();
    }

    _renderInfo = () => {
        const { type, allAmount } = this.state;
        switch (type) {
            case 0:
                return <div style={styles.text}><p>当前账户余额为 0，可以重新注册</p><p>请点击确认后重新登陆，完成注册流程</p></div>;
            case 1:
                return <div style={styles.text}><p>当前账户还有余额，不可重新注册</p><p>余额：¥{allAmount}</p></div>;
            case 2:
                return <div style={styles.text}><p>当前账户余额查询失败</p></div>;
            default:
                return null;
        }
    }

    render() {
        const { visible, confirmLoading, type } = this.state;

        return (
            <Modal
                width={500}
                centered
                title='重新注册'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
            >
                <div style={styles.container}>
                    {type != -1 ? <Icon type="info-circle" style={{ fontSize: 36, color: '#00d0ff', marginRight: 20 }} /> : <div style={styles.text}><p>当前账户余额查询中,请稍后……</p></div>}
                    {this._renderInfo()}
                </div>
            </Modal>
        );
    }
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    text: {
        fontSize: 16
    }
};

RegisterInfo.propTypes = {
    history: PropTypes.object,
    visible: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
};

export default RegisterInfo;
