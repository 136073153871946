import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const PurchasePactAudit = ({ history }) => {
    return <Main history={history} />;
};

PurchasePactAudit.propTypes = {
    history: PropTypes.object//路由对象
};

export default PurchasePactAudit;