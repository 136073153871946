import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getListAreaByParentId = (T) => createSaga(T('getListAreaByParentId'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/user/area/listAreaByParentId/' + action.payload.id, {}, 'POST');
                switch (action.payload.type) {
                    case 'province':
                        yield put({ type: T('province'), payload: result });
                        break;
                    case 'city':
                        yield put({ type: T('city'), payload: result });
                        break;
                    case 'street':
                        yield put({ type: T('street'), payload: result });
                        break;
                }
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const address = (T) => createReducer((autostate) => {
    return (state = autostate, action) => {
        switch (action.type) {
            case T('province'):
                return { ...state, province: action.payload.result };
            case T('city'):
                return { ...state, city: action.payload.result };
            case T('street'):
                return { ...state, street: action.payload.result };
            case T('cleanStreet/GET'):
                return { ...state, street: [] };
            case T('cleanCity/GET'):
                return { ...state, city: [] };
            default:
                return state;
        }
    };
}, { province: [], city: [], street: [] });

const model = {
    namespace: 'membermlx',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getAccountList': T('getAccountList'),
            'getAccountLevel': T('getAccountLevel'),
            'getAreaCompany': T('getAreaCompany'),
            'getOperationCenter': T('getOperationCenter'),
            'getAccountDetail': T('getAccountDetail'),
            'getListAccountBlack': T('getListAccountBlack'),
            'getSettlementDetail': T('getSettlementDetail'),
            'getStarLightDetail': T('getStarLightDetail'),
            'getStarValueDetail': T('getStarValueDetail'),
            'getIncomeDetail': T('getIncomeDetail'),
            'getShowRiceDetail': T('getShowRiceDetail'),
            'getUsableCoupon': T('getUsableCoupon'),
            'getUsedCoupon': T('getUsedCoupon'),
            'getOverdueCoupon': T('getOverdueCoupon'),
            'getCharmDetail': T('getCharmDetail'),
            'getCouponType': T('getCouponType'),
            'getCouponName': T('getCouponName'),
            'getServiceList': T('getServiceList'),
            'getUserSource': T('getUserSource'),
            'getListAreaByParentId': T('getListAreaByParentId'),
            'cleanStreet': T('cleanStreet'),
            'cleanCity': T('cleanCity'),
            'getProhibitType': T('getProhibitType'),
            'getTVAnchorProhibitType': T('getTVAnchorProhibitType'),
            'getIntegralList': T('getIntegralList'),
            'getAccountTypeEnum': T('getAccountTypeEnum'),
            'getLwMemberList': T('getLwMemberList'),
            'getLwAccountList': T('getLwAccountList'),
            'getLwUserSource': T('getUserSource'),
            'getLwAccountTypeEnum': T('getAccountTypeEnum'),
            'getGiftSponsorTypeEnum': T('getGiftSponsorTypeEnum'),
            'getServiceProviderRoleEnum': T('getServiceProviderRoleEnum'),
            'getLoggedOutEnum': T('getLoggedOutEnum'),
            'getIsDestoryEnum': T('getIsDestoryEnum'),
            'getRecommendBossList': T('getRecommendBossList'),
            'getMemberAgencyEnum': T('getMemberAgencyEnum'),
            'getMemberIncomeData': T('getMemberIncomeData'), //每日补贴会员收支明细
        },
        actions: {
            'getAccountList': A('getAccountList'), // 获取会员列表
            'getAccountLevel': A('getAccountLevel'), // 获取会员等级
            'getAreaCompany': A('getAreaCompany'), // 获取区域公司下拉列
            'getOperationCenter': A('getOperationCenter'), // 获取运营中心下拉列
            'getAccountDetail': A('getAccountDetail'), // 获取会员信息
            'getListAccountBlack': A('getListAccountBlack'), // 获取黑名单历史记录
            'getSettlementDetail': A('getSettlementDetail'),// 收支明细汇总
            'getStarLightDetail': A('getStarLightDetail'), // 魅力值明细
            'getStarValueDetail': A('getStarValueDetail'), // 人气值明细
            'getShowRiceDetail': A('getShowRiceDetail'), // 信用值明细
            'getIncomeDetail': A('getIncomeDetail'), // 收益明细
            'getUsableCoupon': A('getUsableCoupon'), // 未使用的卡券
            'getUsedCoupon': A('getUsedCoupon'), // 已使用的卡券
            'getOverdueCoupon': A('getOverdueCoupon'), // 已过期的卡券
            'getCharmDetail': A('getCharmDetail'), // 获取成长值明细
            'getCouponType': A('getCouponType'), // 获取优惠券类型
            'getCouponName': A('getCouponName'), // 获取优惠券名称
            'getServiceList': A('getServiceList'), // 获取服务商列表
            'getUserSource': A('getUserSource'), // 获取用户来源
            'getListAreaByParentId': A('getListAreaByParentId'), // 获取省市区
            'cleanStreet': A('cleanStreet'), // 清理区域信息,
            'cleanCity': A('cleanCity'), // 清理城市信息
            'getProhibitType': A('getProhibitType'), // 获取封禁类型枚举
            'getTVAnchorProhibitType': A('getTVAnchorProhibitType'), // 获取主播封禁类型枚举
            'getIntegralList': A('getIntegralList'), // 获取特权值明细
            'getAccountTypeEnum': A('getAccountTypeEnum'), // 会员类型
            'getLwMemberList': A('getLwMemberList'),
            'getLwAccountList': A('getLwAccountList'),
            'getLwUserSource': A('getLwUserSource'),
            'getLwAccountTypeEnum': A('getLwAccountTypeEnum'), // 服务天使类型
            'getGiftSponsorTypeEnum': A('getGiftSponsorTypeEnum'), // 直推间推枚举
            'getServiceProviderRoleEnum': A('getServiceProviderRoleEnum'),
            'getLoggedOutEnum': A('getLoggedOutEnum'),
            'getIsDestoryEnum': A('getIsDestoryEnum'),
            'getRecommendBossList': A('getRecommendBossList'),
            'getMemberAgencyEnum': A('getMemberAgencyEnum'),
            'getMemberIncomeData': A('getMemberIncomeData'),//每日补贴会员收支明细
        },
        sagas: {
            'getAccountList': S('getAccountList', '/user/mlxAccount/listMlxAccount'),
            'getAccountLevel': S('getAccountLevel', '/mlx/user/level/listMlxAccountLevel'),
            'getAreaCompany': S('getAreaCompany', '/admin/company/listAreaCompanyIdAndName'),
            'getOperationCenter': S('getOperationCenter', '/admin/company/listOperationsIdAndName'),
            'getAccountDetail': S('getAccountDetail', '/user/mlxAccount/detailMlxAccount'),
            'getListAccountBlack': S('getListAccountBlack', '/user/mlxAccountBlack/listAccountBlack'),
            'getSettlementDetail': S('getSettlementDetail', '/settle/account/details'),
            'getStarLightDetail': S('getStarLightDetail', '/user/account/listStarlightDetail'),
            'getStarValueDetail': S('getStarValueDetail', '/user/account/listStarvalueDetail'),
            'getShowRiceDetail': S('getShowRiceDetail', '/user/account/listShowRiceDetail'),
            'getIncomeDetail': S('getIncomeDetail', '/settle/account/listProfitDetail'),
            'getUsableCoupon': S('getUsableCoupon', '/coupon/coupon/listUserUsableCoupon'),
            'getUsedCoupon': S('getUsedCoupon', '/coupon/coupon/listUserUsedCoupon'),
            'getOverdueCoupon': S('getOverdueCoupon', '/coupon/coupon/listUserOverdueCoupon'),
            'getCharmDetail': S('getCharmDetail', '/user/account/listGrowthRecord'),
            'getCouponType': S('getCouponType', '/coupon/coupon/getCouponType'),
            'getCouponName': S('getCouponName', '/coupon/coupon/couponAvailableListByName'),
            'getServiceList': S('getServiceList', '/service/provider/selectProviderList'),
            'getUserSource': S('getUserSource', '/enum/MlxAccountSourceTypeEnum'),
            'getListAreaByParentId': getListAreaByParentId(T), // 自定义saga写法，使用依赖注入
            'getProhibitType': S('getProhibitType', '/enum/BlackTypeEnum'),
            'getTVAnchorProhibitType': S('getTVAnchorProhibitType', '/enum/AnchorBlackTypeEnum'),
            'getIntegralList': S('getIntegralList', '/user/account/listIntegralDetail'),
            'getAccountTypeEnum': S('getAccountTypeEnum', '/enum/AccountTypeEnum'),
            'getLwMemberList': S('getLwMemberList', '/user/giftAccount/giftAccountManageList'),
            'getLwAccountList': S('getLwAccountList', '/user/giftAccount/giftRecommendDetailList'),
            'getLwUserSource': S('getLwUserSource', '/enum/GiftAccountSourceTypeEnum'),
            'getLwAccountTypeEnum': S('getLwAccountTypeEnum', '/enum/GiftAccountTypeEnum'),
            'getGiftSponsorTypeEnum': S('getGiftSponsorTypeEnum', '/enum/GiftSponsorTypeEnum'),
            'getServiceProviderRoleEnum': S('getServiceProviderRoleEnum', '/enum/ServiceProviderRoleEnum'),
            'getLoggedOutEnum': S('getLoggedOutEnum', '/enum/LoginStateEnum'),
            'getIsDestoryEnum': S('getIsDestoryEnum', '/enum/FlagEnum'),
            'getRecommendBossList': S('getRecommendBossList', '/user/account/listRecommendBoss'),
            'getMemberAgencyEnum': S('getMemberAgencyEnum', '/enum/MlxUserOpenRightsEnum'),
            'getMemberIncomeData': S('getMemberIncomeData', '/user/account/accountIncomeAndExpenditureDetails'),//每日补贴会员收支明细
        },
        reducers: {
            'accountList': R('getAccountList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'accountLevel': R('getAccountLevel', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getAreaCompany': R('getAreaCompany', []),
            'getOperationCenter': R('getOperationCenter', []),
            'accountDetail': R('getAccountDetail', {}),
            'accountBlack': R('getListAccountBlack', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getSettlementDetail': R('getSettlementDetail', {}),
            'getStarLightDetail': R('getStarLightDetail', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getStarValueDetail': R('getStarValueDetail', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getIncomeDetail': R('getIncomeDetail', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getShowRiceDetail': R('getShowRiceDetail', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getUsableCoupon': R('getUsableCoupon', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getUsedCoupon': R('getUsedCoupon', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getOverdueCoupon': R('getOverdueCoupon', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getCharmDetail': R('getCharmDetail', { growthRecordResps: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 } }),
            'getCouponType': R('getCouponType', []),
            'getCouponName': R('getCouponName', []),
            'getServiceList': R('getServiceList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getUserSource': R('getUserSource', []),
            'address': address(T), // 自定义reducer写法，使用依赖注入
            'getProhibitType': R('getProhibitType', []),
            'getTVAnchorProhibitType': R('getTVAnchorProhibitType', []),
            'integralList': R('getIntegralList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'accountTypeEnum': R('getAccountTypeEnum', []),
            'lwMemberList': R('getLwMemberList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'lwAccountList': R('getLwAccountList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'lwUserSource': R('getLwUserSource', []),
            'lwAccountTypeEnum': R('getLwAccountTypeEnum', []),
            'getGiftSponsorTypeEnum': R('getGiftSponsorTypeEnum', []),
            'serviceProviderRoleEnum': R('getServiceProviderRoleEnum', []),
            'loggedOutEnum': R('getLoggedOutEnum', []),
            'isDestoryEnum': R('getIsDestoryEnum', []),
            'recommendBossList': R('getRecommendBossList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'memberAgencyEnum': R('getMemberAgencyEnum', []),
            'memberIncomeData': R('getMemberIncomeData', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),//每日补贴会员收支明细
        }
    })
};
export default createModel(model);