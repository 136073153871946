/**
 * 人气值攻略 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';


/**
 * 人气值攻略分类列表 Saga
 */

export const classificationList = function* () {
    yield takeEvery(T.GET_CLASSIFICATIONLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/show/strategyClass/listStrategyClass', action.payload, 'POST');
            yield put({ type: T.SET_CLASSIFICATIONLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 人气值攻略分类状态 Saga
 */

export const classificationState = function* () {
    yield takeEvery(T.GET_CLASSIFICATIONSTATE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/enum/StateEnum', action.payload, 'POST');
            yield put({ type: T.SET_CLASSIFICATIONSTATE_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


/**
 * 人气值攻略列表 Saga
 */

export const strategyList = function* () {
    yield takeEvery(T.GET_STRATEGYLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/show/strategy/listStrategy', action.payload, 'POST');
            yield put({ type: T.SET_STRATEGYLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 人气值攻略列表新建应用端口 Saga 
 */
export const strategyAppState = function* () {
    yield takeEvery(T.GET_STRATEGYAPPLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/enum/UseSceneEnum', action.payload, 'POST');
            yield put({ type: T.SET_STRATEGYAPPLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 * 人气值攻略列表详情 Saga 
 */
export const strategyDetail = function* () {
    yield takeEvery(T.GET_STRATEGYDETAIL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http(`/show/strategy/getStrategyInfo/${action.payload.id}`, action.payload, 'POST');
            yield put({ type: T.SET_STRATEGYDETAIL_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 人气值攻略类型 Saga
 */
export const getstrategyType = function* () {
    yield takeEvery(T.GET_STRATEGY_TYPE, function* requestData(action) {
        try {
            let result = yield http('/enum/StrategyTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_STRATEGY_TYPE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
