import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

const dataSource = {
    dataList: [],
    pageNum: 1,
    pageSize: 30,
    resultTotal: 0
};

/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getMarketingGoodsList = (T) => createSaga(T('getMarketingGoodsList'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/marketing/activityInfo/goodsList', { ...action.payload }, 'POST');
                yield put({ type: T('originalData'), payload: result.result });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const marketingGoodsList = (T) => createReducer((dataSource) => {
    return (state = dataSource, { type = '', payload = {} }) => {
        switch (type) {
            case T('originalData'):
                return { ...payload };
            case T('editGoodsInfo/GET'):
                return {
                    ...state, dataList: state.dataList.map(item => {
                        if (item.activityGoodsId == payload.activityGoodsId) {
                            return { ...item, [payload.type]: payload.value };
                        }
                        return { ...item };
                    })
                };
            default:
                return state;
        }
    };
}, dataSource);

/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getIotteryModalGoods = (T) => createSaga(T('getIotteryModalGoods'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/goods/query/queryGoodsItem', { ...action.payload }, 'POST');
                const { dataList = [] } = result.result;
                const list = dataList.map(i => {
                    if (action.payload.goodsId === i.goodsId) return { ...i, checked: true };
                    return { ...i, checked: false };
                });
                yield put({ type: T('rootModalGoods'), payload: { ...result.result, dataList: list } });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const iotteryModalGoods = (T) => createReducer((dataSource) => {
    return (state = dataSource, { type = '', payload = {} }) => {
        switch (type) {
            case T('rootModalGoods'):
                return { ...payload };
            case T('checkModalGoods/GET'):
                return {
                    ...state, dataList: state.dataList.map(i => {
                        if (i.id == payload.id) {
                            return { ...i, checked: payload.checked };
                        }
                        return { ...i, checked: false };
                    })
                };
            default:
                return state;
        }
    };
}, dataSource);

const model = {
    namespace: 'marketingmanage',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getMarketingGoodsList': T('getMarketingGoodsList'),
            'editGoodsInfo': T('editGoodsInfo'),
            'getNowDatetime': T('getNowDatetime'),
            'getIotteryUsers': T('getIotteryUsers'),
            'getIotteryUserInfo': T('getIotteryUserInfo'),
            'getIotteryWinRecord': T('getIotteryWinRecord'),
            'getIotteryChannels': T('getIotteryChannels'),
            'getIotteryStates': T('getIotteryStates'),
            'getIotteryGoods': T('getIotteryGoods'),
            'getIotteryAwards': T('getIotteryAwards'),
            'getIotteryFenRunEnum': T('getIotteryFenRunEnum'),
            'getIotteryModalGoods': T('getIotteryModalGoods'),
            'checkModalGoods': T('checkModalGoods'),
        },
        actions: {
            'getMarketingGoodsList': A('getMarketingGoodsList'),
            'editGoodsInfo': A('editGoodsInfo'),
            'getNowDatetime': A('getNowDatetime'),//当前服务器时间
            'getCleanNowDatetime': C('getCleanNowDatetime'),//清除当前服务器时间
            'getIotteryUsers': A('getIotteryUsers'),
            'getIotteryUserInfo': A('getIotteryUserInfo'),
            'getIotteryWinRecord': A('getIotteryWinRecord'),
            'getIotteryChannels': A('getIotteryChannels'),
            'getIotteryStates': A('getIotteryStates'),
            'getIotteryGoods': A('getIotteryGoods'),
            'getIotteryAwards': A('getIotteryAwards'),
            'getIotteryFenRunEnum': A('getIotteryFenRunEnum'),
            'getIotteryModalGoods': A('getIotteryModalGoods'),
            'checkModalGoods': A('checkModalGoods'),
        },
        sagas: {
            'getMarketingGoodsList': getMarketingGoodsList(T), // 自定义saga写法，使用依赖注入
            'getNowDatetime': S('getNowDatetime', '/marketing/activityInfo/getNowDatetime'),
            'getIotteryUsers': S('getIotteryUsers', '/integral/lottery/participantUserList'),
            'getIotteryUserInfo': S('getIotteryUserInfo', '/integral/lottery/participantUserHistory'),
            'getIotteryWinRecord': S('getIotteryWinRecord', '/integral/lottery/winningHistory'),
            'getIotteryChannels': S('getIotteryChannels', '/enum/ParticipateChannelEnum'),
            'getIotteryStates': S('getIotteryStates', '/enum/WinnerRecordStatusEnum'),
            'getIotteryGoods': S('getIotteryGoods', '/marketing/activityInfo/pointsTreasureGoods'),
            'getIotteryAwards': S('getIotteryAwards', '/integral/lottery/awardHistory'),
            'getIotteryFenRunEnum': S('getIotteryFenRunEnum', '/enum/ActivityUserTypeEnum'),
            'getIotteryModalGoods': getIotteryModalGoods(T),
        },
        reducers: {
            'marketingGoodsList': marketingGoodsList(T), // 自定义reducer写法，使用依赖注入
            'nowDatetime': R('getNowDatetime', ''),
            'iotteryUsers': R('getIotteryUsers', dataSource),
            'iotteryUserInfo': R('getIotteryUserInfo', []),
            'iotteryWinRecord': R('getIotteryWinRecord', dataSource),
            'iotteryChannels': R('getIotteryChannels', []),
            'iotteryStates': R('getIotteryStates', []),
            'iotteryGoods': R('getIotteryGoods', dataSource),
            'iotteryAwards': R('getIotteryAwards', dataSource),
            'iotteryFenRunEnum': R('getIotteryFenRunEnum', []),
            'iotteryModalGoods': iotteryModalGoods(T),
        }
    })
};
export default createModel(model);