import * as Audit from './actionTypes';

export const getCompanyAuditList = (params) => ({
    type: Audit.GET_COMPANYAUDIT_LIST,
    payload:params
});

export const getCompanyAuditStatus = () => ({
    type: Audit.GET_COMPANYAUDIT_STATUS,
    payload: {}
});

export const getCompanyAuditDetail = (id) => ({
    type: Audit.GET_COMPANYAUDIT_DETAIL,
    payload: { id }
});