/**
 * 编辑组别弹框
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, Input, message, Form } from 'antd';

const { TextArea } = Input;
const FormItem = Form.Item;//表单校验
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};

class EditGroup extends React.Component {

    //初始化
    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
            groupName: '',
            remark: ''
        };
    }

    //校验props
    static propTypes = {
        visible: PropTypes.bool.isRequired, // 是否显示弹框
        closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
        modeParams: PropTypes.object.isRequired,//弹框入参
        form: PropTypes.object.isRequired//表单校验
    };

    //挂载后
    componentDidMount() {
        const { groupName, remark } = this.props.modeParams;
        this.setState({
            groupName, remark
        });
    }

    // 点击确认
    _handleOk = () => {
        this.handleSubmit();
    }

    //提交
    handleSubmit = e => {
        const { modeParams, form } = this.props;
        if (e) e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ confirmLoading: true }, () => {
                    http('/show/traineegroup/updateTraineeGroup', { ...values, id: modeParams.id }, 'POST').then(() => {
                        message.success('编辑组别成功。');
                        this.setState({ visible: false, confirmLoading: false });
                        this.props.closeMode(true);
                    }).catch((error) => {
                        this.setState({ confirmLoading: false });
                        message.error(error.message);
                    });
                });
            }
        });
    };

    // 点击取消
    _handleCancel = () => {
        this.setState({ visible: false, confirmLoading: false });
        this.props.closeMode(false);
    }

    //渲染组件
    render() {
        const { visible, confirmLoading, groupName, remark } = this.state;
        const { modeParams } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={600}
                centered
                title='编辑组别'
                visible={visible}
                bodyStyle={{ paddingBottom: 0 }}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认' >
                <Form autoComplete='off' {...formItemLayout} onSubmit={this.handleSubmit}>
                    <FormItem
                        colon={false}
                        htmlFor='groupName'
                        label={<span>组别名称</span>}
                    >
                        {getFieldDecorator('groupName', {
                            initialValue: modeParams.groupName,
                            rules: [
                                { required: true, message: '请输入组别名称' },
                                {
                                    validator: (rule, value = '', callback) => {
                                        if (value.length > 24) {
                                            callback('组别名称不超过24个字');
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ]
                        })(<Input
                            placeholder='请输入组别名称'
                            suffix={`${groupName.length}/24`}
                            maxLength={24}
                            onChange={e => {
                                this.setState({
                                    groupName: e.target.value
                                });
                            }}
                        />)}
                    </FormItem>
                    <FormItem
                        colon={false}
                        htmlFor='remark'
                        label={<span>备注</span>}
                    >
                        {getFieldDecorator('remark', {
                            rules: [
                                { message: '请输入备注' },
                                {
                                    validator: (rule, value = '', callback) => {
                                        if (value.length > 30) {
                                            callback('备注不超过30个字');
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ]
                        })(
                            <div style={{ textAlign: 'right', height: '80px', overflow: 'hidden' }}>
                                <TextArea
                                    placeholder='请输入备注'
                                    maxLength={30}
                                    value={remark}
                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                    onChange={e => {
                                        this.setState({
                                            remark: e.target.value
                                        });
                                    }}
                                />
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: '50px', height: '32px',
                                        paddingRight: '10px', position: 'relative',
                                        bottom: '40px'
                                    }}>{
                                        `${remark.length}/30`
                                    }</span>
                            </div>
                        )}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

//暴露组件
const EditGroupForm = Form.create({ name: 'editGroup' })(EditGroup);
export default EditGroupForm;