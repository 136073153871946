/**
 * 人气规则
 */
import React from 'react';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm, } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message } from 'antd';
import { getpopularList, } from '@/reducers/systemmanage/showset/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';

//模拟下拉列数据
const selectDataSource = [
    { id: 0, name: '禁止' },
    { id: 1, name: '启用' },
];

class Popular extends React.Component {

    state = {
        type: {},//规则状态
        typeName: '',//规则名称
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
    }

    componentDidMount() { }

    // 创建规则
    _createRules = () => {
        this.props.updateContent('pcreateRules');
    }

    //机器人列表
    _createrobotList = () => {
        this.props.updateContent('ProbotList');
    }

    //消息生成
    _createMessage = () => {
        this.props.updateContent('Pmessage');
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { type, typeName, pageNum, pageSize, } = this.state;
        this.searchCache = {
            virtualName: typeName,
            state: type.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getpopularList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ type: {}, typeName: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (item, typeTable, value) => {
        switch (typeTable) {
            case 'editor':
                this.props.updateContent('peditor', item);
                break;
            case 'editorDefault':
                this.props.updateContent('editorDefault', item);
                break;
            case 'delete':
                showConfirm('您是否要删除内容', '', () => {
                    http('/system/popularity/delete', { id: item }, 'POST').then(() => {
                        message.success('删除成功');
                        this.props.getpopularList(this.searchCache);
                    }).catch((reject = {}) => {
                        message.error(reject.message);
                    });
                }, () => { });
                break;
            case 'switch':
                http('/system/popularity/updateState', {
                    id: item.id,
                    state: (value === false) ? 0 : 1,
                }, 'POST').then(() => {
                    message.success('状态修改成功');
                    this.props.getpopularList(this.searchCache);
                }).catch((e = {}) => {
                    message.error(e.message || '状态修改异常');
                });
                break;
        }
    }


    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getpopularList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { type, typeName, } = this.state;
        const { tableSource, paginations, } = this.props;

        return (
            <KeepAlive id='5' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <SearchBox>
                            <SearchItem>
                                <XInput
                                    label='规则名称'
                                    placeholder='请输入规则名称'
                                    value={typeName}
                                    bindThis={this}
                                    bindName='typeName'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XSelect
                                    label='规则状态'
                                    placeholder='请选择规则状态'
                                    renderData={selectDataSource}
                                    dataIndex='name'
                                    keyIndex='id'
                                    value={type.name}
                                    bindThis={this}
                                    bindName='type'
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '90px' }} label='创建规则' onClick={this._createRules} />
                        <XOKButton style={{ width: '102px', marginLeft: '20px' }} label='机器人列表' onClick={this._createrobotList} />
                        <XOKButton style={{ width: '90px', marginLeft: '20px' }} label='消息生成' onClick={this._createMessage} />
                    </div>
                    <TableContent _paginationChange={this._paginationChange} renderData={tableSource} tableAction={this._tableAction} pagiNation={paginations} />
                </div>
            </KeepAlive>
        );
    }
}

Popular.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func,//路由

    tableSource: PropTypes.array, // 表格数据源
    paginations: PropTypes.object, // 分页数据
    getpopularList: PropTypes.func, // 通过Saga异步请求表格数据源的Action
};

const mapStateToProps = (state) => {
    return {
        tableSource: state.showset.showsetDate.populartableSource,
        paginations: state.showset.showsetDate.popularpaginations,
    };
};

export default connect(mapStateToProps, { getpopularList, })(Popular);