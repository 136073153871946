/**
 * 星球商学院 Saga集合
 */
import lecturersmanage from './lecturersmanage/model';
import groupmanage from './groupmanage/model';
import livelist from './livelist/model';


export default [
    ...Object.values(lecturersmanage.sagas),
    ...Object.values(groupmanage.sagas),
    ...Object.values(livelist.sagas)
];
